import React, { Component } from 'react';
import Axios from 'axios';
import './cancel_case.scss';

// Internal Components
import Modal from '../../components/modal/modal';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { textFieldCheck, removeEmoji, isCaseDE } from '../../common/functions';
import { isAfterSmileDesignApproval } from '../../common/case/case_status';

class CancelCase extends Component {
  constructor(props) {
    super(props);

    this.state = { cancel_reason: '', doctor_note: '', cancel_charge_accpeted: false, displayCaseOnHoldMsgModal: false, loading: false };
  }

  /**
   * Display cancel case modal pre smile design
   * @function
   * @return {JSX} JSX element for pre smile design case cancel modal
   */
  displayPreSmileDesignModal = () => {
    const cancelMsg = 'Please specify reason for cancellation';
    return !this.state.displayCaseOnHoldMsgModal ? (
      <Modal
        preset="decision-dialog-hold-cancel"
        modal_class="ipp_case_cancel_dropdown"
        header_text={'Cancel Case - ' + this.props.patient_name}
        textarea_placeholder="Enter details for case cancellation..."
        textarea_warning_text="Please select reason for cancellation"
        doctornote_label={cancelMsg}
        doctornote_placeholder={cancelMsg}
        dropdown_error={cancelMsg}
        confirm_btn_text="Confirm"
        close_btn_text="Cancel"
        label_dropdown="Please select a reason for cancellation:"
        default_text="Select reason for cancellation"
        onConfirmButtonClick={this.cancelCase}
        onCloseButtonClick={this.props.closeModal}
        onDropdownChange={this.onCancelReasonChange}
        onTextAreaDoctorNoteChange={this.onDoctorNoteChange}
        theme="ipp"
        hold_cancel_reasons={[{ reason: 'Different modality chosen', category: 'Cancel' }, { reason: 'Patient cancellation' }, { reason: 'Other' }]}
        hide_internal_note={true}
        in_progress={this.state.loading}
        in_progress_text={'Canceling Case...'}
      />
    ) : null;
  };

  /**
   * Display cancel case modal post smile design
   * @function
   * @return {JSX} JSX element for post smile design case cancel modal
   */
  displayPostSmileDesignModal = () => {
    const cancelMsg = 'Please specify reason for cancellation';
    return !this.state.displayCaseOnHoldMsgModal ? (
      <Modal
        preset="decision-dialog-hold-cancel"
        modal_class="ipp_case_cancel_dropdown"
        header_text={'Cancel Case - ' + this.props.patient_name}
        textarea_placeholder="Enter details for case cancellation..."
        doctornote_label={cancelMsg}
        dropdown_error={cancelMsg}
        doctornote_placeholder={cancelMsg}
        textarea_warning_text={'Please select reason for cancellation'}
        confirm_btn_text="Accept"
        close_btn_text="Do Not Accept"
        label_dropdown="Please select a reason for cancellation:"
        extra_label_dropdown="Cases cancelled after Smile Design approval will incur a $250 cancellation fee. Are you sure you would like to cancel this case?"
        default_text="Select reason for cancellation"
        onConfirmButtonClick={this.cancelCase}
        confirm_btn_disabled={!this.state.cancel_charge_accpeted}
        onCloseButtonClick={this.props.closeModal}
        onRejectButtonClick={this.putCaseOnHold}
        onDropdownChange={this.onCancelReasonChange}
        onTextAreaDoctorNoteChange={this.onDoctorNoteChange}
        theme="ipp"
        hold_cancel_reasons={[{ reason: 'Different modality chosen', category: 'Cancel' }, { reason: 'Patient cancellation' }, { reason: 'Other' }]}
        hide_internal_note={true}
        post_smile_design_case_cancel={true}
        cancel_case_checkbox={true}
        toggleChange={this.toggleCheckbox}
        cancel_charge_accpeted={this.state.cancel_charge_accpeted}
        charge_fee_label="I authorize a $250 fee to be charged to my account"
        cancel_case_bottom_warning_text="If 'Do not Accept' is clicked, case will be put on hold and your Business Growth Consultant will be in contact within the next 1-2 business days to discuss further."
        in_progress={this.state.loading}
        in_progress_text={'Canceling Case...'}
      />
    ) : null;
  };

  /**
   * Display alert message for case putted on hold
   * @function
   * @return {JSX} JSX element for alert message modal
   */
  displayCaseOnHoldMsgModal = () => {
    return (
      <Modal
        close_btn_text="Close"
        header_text={'Case Placed on Hold - ' + this.props.patient_name}
        message_text="A hold has been placed on this case. A member of the BGC team will be in contact with you to resolve this issue."
        onCloseButtonClick={this.props.closeCancelCaseModalAndReload}
        theme="ipp"
      />
    );
  };

  /**
   * On toggle authorized to charge
   * @function
   */
  toggleCheckbox = () => {
    this.setState({ cancel_charge_accpeted: !this.state.cancel_charge_accpeted });
  };

  /**
   * On cancel case reason selection change
   * @function
   * @param {Object} e - Selection event object
   */
  onCancelReasonChange = (e) => {
    this.setState({ cancel_reason: e.target.value });
  };

  /**
   * On doctor note text change
   * @function
   * @param {Object} e - Text input event object
   */
  onDoctorNoteChange = (event) => {
    const doctor_note = removeEmoji(textFieldCheck(event.target.value));

    this.setState({
      doctor_note: doctor_note,
    });
  };

  /**
   * Change case status to cancel
   * @function
   */
  cancelCase = () => {
    const that = this;
    let form_data = new FormData();
    const post_smile_design_approval = isAfterSmileDesignApproval(this.props.case_status);
    const not_DE = !isCaseDE(this.props.case_id);
    const email_slugs =
      post_smile_design_approval && not_DE
        ? ['case-cancellation-3', 'case-cancellation-4', 'assigned-case-cancellation']
        : ['case-cancellation-1', 'assigned-case-cancellation'];
    const status_comment = that.state.doctor_note !== '' ? that.state.cancel_reason + ': ' + that.state.doctor_note : that.state.cancel_reason;
    form_data.append('status_comment', status_comment);
    form_data.append('reason_message', that.state.doctor_note);
    this.setState({ loading: true });
    Axios.post(`/apiV2/caseaction/${that.props.case_id}/cancel`, form_data)
      .then(() => {
        that.setState(
          {
            cancel_reason: '',
            doctor_note: '',
            loading: false,
          },
          () => {
            that.props.closeCancelCaseModalAndReload();
          }
        );
        that.sendEmails(email_slugs);
      })
      .catch((err) => {
        handleHttpRequestError(err, that);
        this.setState({ loading: false });
        if ((err && err.response && err.response.status === 409) || err.response.status === 401) {
          that.props.closeModal();
          this.props.refresh();
        }
      });
  };

  /**
   * Change case status to on hold
   * @function
   */
  putCaseOnHold = () => {
    const that = this;
    let form_data = new FormData();
    const email_slugs = ['case-on-hold-1', 'case-on-hold-2', 'assigned-case-on-hold'];
    form_data.append('status_comment', 'Post-Smile Design approval cancellation fee not accepted by doctor');
    form_data.append('reason_message', '');
    this.setState({ loading: true });
    Axios.post(`/apiV2/caseaction/${that.props.case_id}/hold`, form_data)
      .then(function () {
        that.setState({
          cancel_reason: '',
          doctor_note: '',
          displayCaseOnHoldMsgModal: true,
          loading: false,
        });

        that.sendEmails(email_slugs);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        this.setState({ loading: false });
        if ((err && err.response && err.response.status === 409) || err.response.status === 401) {
          that.props.closeModal();
          that.props.refresh();
        }
      });
  };

  /**
   * Send Emails
   * @function
   * @param {Array} slugs - Array of slugs to send emails
   */
  sendEmails = (slugs) => {
    const case_id = this.props.case_id;
    const post_smile_design_approval = isAfterSmileDesignApproval(this.props.case_status);
    const provider = window.location.origin;
    const doctor_id = this.props.doctor_id;

    slugs.forEach((slug) => {
      Axios.post(
        `/api/email/?slug=${slug}&caseId=${case_id}&method=standard&provider=${provider}&doctorId=${doctor_id}&post_smile_design_approval=${post_smile_design_approval}`
      );
    });
  };

  render() {
    return (
      <div>
        {isAfterSmileDesignApproval(this.props.case_status) && !isCaseDE(this.props.case_id)
          ? this.displayPostSmileDesignModal()
          : this.displayPreSmileDesignModal()}
        {this.state.displayCaseOnHoldMsgModal ? this.displayCaseOnHoldMsgModal() : ''}
      </div>
    );
  }
}

export default CancelCase;
