// Css
import './setup_viewer.scss';
// External Libs
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
// Internal Functions
import NotFound from '../../doctor/404/not_found';
import PanelMenu from './components/panels/menu/menu';
import PanelViewer from './components/panels/viewer/viewer';

// redux action
import { fetchCaseDetails } from '../../redux/actions/common/common_case_details';
import { getCaseDetails, getCaseDetailsError, getCaseDetailsLoading } from '../../redux/reducers/common/common_case_details';

import Shortcuts from './components/extras/shortcuts';
import { UserPermission } from '../../context/user_permission';
import { getCaseDetailByCaseId, SETUP_PREFIX } from './components/common/functions';
import { getBusinessRoleList, getCaseLastStatus, isCaseBlocked } from '../../common/helpers';
import { getCaseIdFromUrl, convertAliasToCaseId } from '../../common/case/case_id';
import CircleLoader from '../loader/circle_loader';
import { maxScale, minScale, zoomStep, initialState } from '../../doctor/components/record_viewer/image_viewer';
import { getRecordState, syncRecordStateDebounce } from '../../doctor/components/record_viewer/record_viewer_helpers';
import { fetchCaseFileData } from '../../redux/actions/ipp/case_details/case_files';
import { getCaseFileList, getCaseFileListLoading, getCaseFileError } from '../../redux/reducers/ipp/case_details/case_files';
import { getTempLink } from '../../common/dropbox';
import { setTokenHeader } from '../../common/functions';
import FeedbackForm from '../../components/feedback_rating_form_post_smile_design';
import { isPostApprovalStage } from '../../common/case/case_status';
import { CaseStatus } from '../../common/case/case_details.constants';

/**
 * Displays the entire Setup Viewer
 * @component
 * @alias SetupViewer
 */
class SetupViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      collapse_pane_1: true,
      collapse_pane_2: true,
      split: false,

      image_zoom: 75, // percentage
      image_zoom_rate: 100, // milliseconds
      image_zoom_pane: 1,
      image_zoom_timeout: undefined,

      image_records: [],
      scroll_area: 0,
      scroll_items: 5,
      scroll_options: [],
      models: [],
      default_record_states: [],
    };
  }

  componentDidMount() {
    this.loadCaseDetails();
  }

  UNSAFE_componentWillMount() {
    const pathname = this.props.location.pathname.split('/');
    const case_id = getCaseIdFromUrl(this.props.location.pathname);
    const sf_case_id_regex = /[A-Z0-9]{5,6}-DE$|[A-Z0-9]{5,6}-R$/;
    if (sf_case_id_regex.test(case_id)) {
      pathname.splice(2, 1);
      pathname.splice(2, 0, convertAliasToCaseId(case_id));
      this.props.history.replace(pathname.join('/'));
    }
  }

  /**
   * Loads case details
   * @function
   */
  loadCaseDetails = () => {
    let { case_id } = this.getURLParams();
    case_id = convertAliasToCaseId(case_id);

    if (case_id && this.isValidURLParams()) {
      this.getDefaultRecordState(case_id);
      if (this.props.case_details && this.props.case_file_list) {
        this.buildImageRecords();
        this.buildSetupHistoryList();
        this.buildModels();
      } else {
        this.props.fetchCaseDetails(case_id);
        this.props.fetchCaseFileData(case_id);
      }
    }
  };

  componentDidUpdate(prevProps) {
    const finishedFetchingCaseDetails = this.props.case_details && !this.props.case_details_loading && !this.props.case_details_error;
    const finishedFetchingCaseFiles = this.props.case_file_list && !this.props.case_file_list_loading && !this.props.case_file_list_error;
    const caseDetails = this.props.case_details !== prevProps.case_details || this.props.case_file_list !== prevProps.case_file_list;
    const recordsNotBuilt = !this.state.image_records.length && !this.state.scroll_options.length;
    const modelsNotBuilt = !this.state.models.length;
    const locationChanged = this.props.location !== prevProps.location;

    if (finishedFetchingCaseDetails && finishedFetchingCaseFiles && (recordsNotBuilt || caseDetails)) {
      this.buildImageRecords();
      const lastestSetup = this.buildSetupHistoryList();
      this.setDefaultURLPath(lastestSetup);
      if (modelsNotBuilt) this.buildModels();
    }
    if (locationChanged) {
      this.updateScrollOptionsOnRouteChange();
    }
  }

  /**
   * Checks whether the current URL params is valid
   * @function
   * @return {Boolean} Whether the current URL is valid or not
   */
  isValidURLParams() {
    const { pane_1, pane_2 } = this.getURLParams();
    return this.isValidPane(pane_1) && this.isValidPane(pane_2);
  }

  /**
   * Sets the URL path to the lastest setup
   * @function
   * @param {Number} lastestSetup - The latest setup number
   */
  setDefaultURLPath(lastestSetup) {
    const hasSetupHistory = lastestSetup != 0;
    if (hasSetupHistory) {
      const { case_id, pane_1, pane_2 } = this.getURLParams(lastestSetup);
      const is_wasm_viewer_enabled = this.getWasmViewerActivation(case_id);
      if (is_wasm_viewer_enabled) {
        window.location.replace(`/smile_design/${case_id}`);
      } else {
        this.props.history.replace(`/setup/${case_id}/${pane_1}/${pane_2}`);
      }
    }
  }

  /**
   * Get case wasm viewer activation status, True if wasm viewer is activated
   * @function
   * @param {String} case_id - Case id
   */
  getWasmViewerActivation(case_id) {
    if (this.props.case_details && case_id) {
      const current_case = this.props.case_details?.cases.filter((c) => {
        return c.case_id === case_id;
      });
      const is_wasm_viewer_enabled = current_case && current_case.length && current_case[0]?.is_wasm_viewer_enabled;
      return is_wasm_viewer_enabled;
    }
  }

  /**
   * Retrieves parameters from the URL
   * @function
   * @param {Number} [lastestSetup=0] - The latest setup number
   */
  getURLParams(lastestSetup = 0) {
    const { case_id, pane_1, pane_2 } = this.props.match && this.props.match.params;
    const default_pane = lastestSetup === 0 ? 'Records' : lastestSetup;
    return {
      case_id,
      pane_1: pane_1 ? pane_1 : default_pane,
      pane_2: pane_2 ? pane_2 : default_pane,
    };
  }

  /**
   * Whether the pane is valid or not
   * @function
   * @param {String} pane - The pane to validate
   */
  isValidPane(pane) {
    return !pane || pane === 'Records' || parseInt(pane);
  }

  // Builds state
  //----------------------------------------------------------------------------

  /**
   * Builds the initial empty list to store pdf and image records
   * @function
   */
  buildImageRecords() {
    const detail = this.getCaseFilesByCaseId(this.props.case_file_list, this.props.case_file_list.case_id);
    const submission_files = detail && detail.submission_process && detail.submission_process.files;

    if (submission_files) {
      const new_image_records = [this.buildTxPlanPdfRecord(), ...this.buildImageRecordsFromFiles(submission_files)];
      this.setState({ image_records: [...this.state.image_records, ...new_image_records] });
    }
  }

  getCaseFilesByCaseId(case_files, case_id) {
    if (case_files) return case_files[case_id] ?? {};
    return {};
  }

  /**
   * Builds the list of setup history
   * @function
   * @return {Number} Number of setups this case has
   */
  buildSetupHistoryList() {
    const case_file = this.getCaseFilesByCaseId(this.props.case_file_list, this.props.case_file_list.case_id);
    const detail = getCaseDetailByCaseId(this.props.case_details, this.props.case_details.case_id);
    if (case_file.setup_process) {
      const setups = this.buildSetupHistoryListFromCaseDetail(case_file, detail);
      const new_scroll_options = [...this.buildInitialScrollOptions(), ...setups];
      const latest_setup_num = setups[0]?.text?.replace('Smile Design ', '');
      this.setState({ scroll_options: new_scroll_options });
      return latest_setup_num;
    }
    return 0;
  }

  /**
   * Builds the initial empty object to store models
   * @function
   */
  buildModels() {
    const detail = this.getCaseFilesByCaseId(this.props.case_file_list, this.props.case_file_list.case_id);
    const setup_process = detail && detail.setup_process;
    if (setup_process) {
      const new_models = setup_process.map((setup) => ({
        setup_num: setup.setup_num,
        steps: null,
        ipr: null,
        loading: false,
        loading_percent: 0,
        is_inbrace_ifs_setup: setup.is_inbrace_ifs_setup,
        error: null,
        loaded: false,
      }));
      this.setState({ models: new_models });
    }
  }

  /**
   * Get files default record states
   * @function
   * @param {string} case_id - Case id
   */
  getDefaultRecordState(case_id) {
    setTokenHeader();
    let formData = new FormData();
    formData.append('get_default_record_state', true);
    const request = {
      url: `/apiv3/recordstate/complete/${case_id}`,
      method: 'GET',
    };
    Axios(request)
      .then((res) => {
        if (res.data && res.data.file_records) {
          this.setState({ default_record_states: res.data.file_records });
        }
      })
      .catch((err) => console.log(err));
  }

  /**
   * Builds the setup history list using input
   * @function
   * @param {List} setup_process - The list of setups
   * @param {List} manufacturing_process - The manufacturing process notes list
   * @return {List} The setup history list
   */
  buildSetupHistoryListFromCaseDetail(case_file, detail) {
    const { setup_process } = case_file;
    let setups = [];
    for (let i = setup_process.length - 1; i >= 0; i--) {
      const setup = setup_process[i];
      const isLatestSetup = setup.setup_num === setup_process.length;
      const latestCaseStatus = setup.log[setup.log.length - 1].status_code;
      const isIfs = setup.is_inbrace_ifs_setup && isLatestSetup && setup.setup_status != 'Released' && latestCaseStatus != 'Setup Ready for Release';
      const setupNum = isIfs ? `${setup.index} (InBrace IFS)` : setup.index.toString();
      if (setup && this.shouldShowSetup(setup)) {
        setups.push({
          text: `${SETUP_PREFIX}${setupNum}`,
          status: this.getSetupStatus(detail, i, isLatestSetup),
          // If a doctor rejected a setup, their comment is in the next setup
          inbrace_comment: this.getInbraceComment(setup, isIfs),
          doctor_comment: isLatestSetup ? '' : this.getDoctorComment(setup_process[i + 1]),
          doctor_name: isLatestSetup ? '' : this.getDoctorName(setup_process[i + 1]),
          is_inbrace_ifs_setup: setup.is_inbrace_ifs_setup,

          ...this.getActivePaneState(setupNum),
        });
      }
    }
    return setups;
  }

  /**
   * Returns the state for the active pane
   * @function
   * @param {Number} setup_number - The setup number
   * @return {Object} The active pane states
   */
  getActivePaneState(setup_number) {
    const { pane_1, pane_2 } = this.getURLParams();
    return {
      active_pane_1: pane_1 === setup_number,
      active_pane_2: pane_2 === setup_number,
    };
  }

  /**
   * Builds the initial scroll options
   * @function
   * @return {List} The initial scroll options
   */
  buildInitialScrollOptions() {
    const { pane_1, pane_2 } = this.getURLParams();
    return [
      {
        text: 'Records',
        active_pane_1: pane_1 === 'Records',
        active_pane_2: pane_2 === 'Records',
      },
    ];
  }

  /**
   * Checks to show the setup or not
   * @function
   * @param {Object} setup - A single setup
   * @return {Boolean} Whether to show or not
   */
  shouldShowSetup(setup) {
    const has_setup_log = setup.log && setup.log.length > 0;
    const has_access_to_converted_setup = getBusinessRoleList().includes(this.props.case_details.role);
    const release_status = ['Provider Edit Review', 'Setup Ready for Release', 'Final Design Ready for Release'];

    if (has_setup_log) {
      const latest_log_status = this.getLatestSetupStatus(setup);
      const is_ready_for_release = release_status.includes(latest_log_status);
      const is_approval_status = latest_log_status === 'STATUS_DOCTOR_APPROVAL';

      return (has_access_to_converted_setup && is_ready_for_release) || is_approval_status;
    }
    return false;
  }

  /**
   * Retrieves Inbrace's comment to doctor
   * @function
   * @param {Object} setup - A single setup
   * @return {String} Inbrace's comment
   */
  getInbraceComment(setup, isIfs) {
    const latestLog = setup.log[setup.log.length - 1];
    const hasValidLogText = latestLog.text && latestLog.text !== 'undefined';

    if (isIfs && setup.comment) return setup.comment;
    else if (hasValidLogText) return latestLog.text;
    else return '';
  }

  /**
   * Retrieves doctor's comment to Inbrace
   * @function
   * @param {Object} nextSetup - A single setup
   * @return {String} Doctor's comment
   */
  getDoctorComment(nextSetup) {
    const has_valid_log_text = nextSetup?.log && nextSetup.log.length > 0 && nextSetup.log[0].text && nextSetup.log[0].text !== 'undefined';

    return has_valid_log_text ? nextSetup.log[0].text : '';
  }

  /**
   * Retrieves the name of the doctor that created the comment
   * @function
   * @param {Object} nextSetup - A single setup
   * @return {String} Doctor's name
   */
  getDoctorName(nextSetup) {
    const inbrace_name = 'InBrace';
    const has_valid_log_name =
      nextSetup?.log && nextSetup.log.length > 0 && nextSetup.log[0].text_created_by_first_name && nextSetup.log[0].text_created_by_last_name;

    if (has_valid_log_name) {
      const first_name = nextSetup.log[0].text_created_by_first_name;
      const last_name = nextSetup.log[0].text_created_by_last_name;
      const role = nextSetup.log[0].role;

      const doctor_name = `Dr. ${last_name}`;
      const dso_name = `${first_name} ${last_name}`;

      if (role === 'Business') return inbrace_name;
      if (role === 'DSO') return dso_name;
      return doctor_name;
    }

    return inbrace_name;
  }

  /**
   * Finds the setup's status
   * @function
   * @param {Object} case_detail - The case's detail
   * @param {Number} i - The case's ith setup
   * @param {Boolean} is_latest_setup - True if is the latest setup
   * @return {String} The setup's status
   */
  getSetupStatus(case_detail, i, is_latest_setup) {
    const { manufacturing_process } = case_detail;
    const setup_process = this.getCaseFilesByCaseId(this.props.case_file_list, case_detail.case_id)?.setup_process ?? [];
    const case_status_code = case_detail.status_code;
    const lastStatusBeforeBlock = getCaseLastStatus(true, this.props.case_details, case_detail.case_id);
    const lastest_setup_status_code = this.getLatestSetupStatus(setup_process[i]);
    const in_final_design_process = isPostApprovalStage(case_status_code) || (isCaseBlocked(case_status_code) && isPostApprovalStage(lastStatusBeforeBlock));
    const is_case_approved = manufacturing_process?.log?.length > 0 || in_final_design_process;
    if (is_latest_setup) {
      if (is_case_approved) return 'Approved';
      if (case_status_code === CaseStatus.StatusHold) return 'On Hold';
      if (case_status_code === CaseStatus.Cancel) return 'Case Cancelled';
      if (lastest_setup_status_code === CaseStatus.SetupReleaseReady) return 'Uploaded';
      if (case_status_code === CaseStatus.DoctorProvideClarification) return 'Pending Doctor Clarification';
      return 'In Review';
    } else if (setup_process[i].is_inbrace_ifs_setup && setup_process[i].setup_status === 'Approved') {
      return 'Approved';
    } else {
      return 'Revised';
    }
  }

  /**
   * Retrieves the setup's latest status code
   * @function
   * @param {Object} setup - A single setup
   * @return {String} The setup's status code
   */
  getLatestSetupStatus(setup) {
    return setup.log[setup.log.length - 1]?.status_code;
  }

  /**
   * Builds image records list from files
   * @function
   * @param {List} submission_files - The list of submitted record files
   * @return {List} The empty image records list
   */
  buildImageRecordsFromFiles(submission_files) {
    let images = [];
    submission_files.map((file, index) => {
      const is_scan = file && file.upload_data && file.upload_data.includes('.stl') && this.isScan(file.file_type);
      const has_attributes = file && file.original_filename;

      const id = this.isScan(file.file_type) ? `scan-${index}` : file.id;
      file['index'] = index;

      const image = this.buildOneImageRecord(id, file);

      if (is_scan && has_attributes) {
        this.fetchFileURL(image);
      } else {
        images.push(image);
      }

      return image;
    });
    return images;
  }

  /**
   * fetches a temporary link to be stored
   * @function
   * @param {Object} file - file object
   */
  fetchFileURL = (file) => {
    let that = this;
    Promise.resolve(getTempLink(file.original_filename, file.upload_data)).then((url) => {
      that.updateFilesState(file, 'file_url', url);
    });
  };

  /**
   * Updates state with temporary link
   * @function
   * @param {Object} fetch_file - file object
   * @param {String} key - key to be replaced with url in file object
   * @param {String} url - Value of the stream url link
   */
  updateFilesState = (fetch_file, key, url) => {
    fetch_file[key] = url;
    const updated_records = [...this.state.image_records, fetch_file];
    this.setState({
      image_records: updated_records,
    });
    this.displayOcclusionView(updated_records);
  };

  /**
   * Adds occlusion view if scans exist
   *
   * @function
   * @param {Array} updated_records image records
   */
  displayOcclusionView(updated_records) {
    let scan_url_count = [];
    let last_index = 0;

    updated_records.map((file, index) => {
      if (file.file_url) {
        scan_url_count.push(file.file_url);
      }
      last_index = Math.max(last_index, index);
      return file;
    });

    if (scan_url_count.length === 2) {
      const id = `scan-${last_index + 1}`;
      const file = this.buildOneImageRecord(id, {
        index: last_index + 1,
        file_type: 'multi_scans',
        upload_data: scan_url_count[1],
        original_filename: 'occlusion_view',
        created_date: '',
        created_by_id: '',
        file_url: scan_url_count[0],
      });
      this.setState({
        image_records: [...this.state.image_records, file],
      });
    }
  }

  /**
   * Builds a single image record
   * @function
   * @param {String} id - The id of the image record
   * @param {Object} file - The file object
   * @return {Object} A single image record
   */
  buildOneImageRecord(id, file) {
    return {
      id: id,
      src: this.isScan(file.file_type) ? '/static/img/model.png' : '/static/img/no-image-white.png',
      loading: !this.isScan(file.file_type),
      type: file.file_type,
      image_url: file.upload_data,
      file_url: file.file_url,
      original_filename: file.original_filename,
      upload_data: file.upload_data,
      sort_order: file.index,
      pane_1: { active: false, ...getRecordState(file.record_state) },
      pane_2: { active: false, ...getRecordState(file.record_state) },
    };
  }

  isScan(file_type) {
    const scans = ['lowerscans', 'upperscans', 'scans', 'multi_scans'];
    return scans.includes(file_type);
  }

  /**
   * Builds a treatment plan pdf record
   * @function
   * @return {Object} A single tx plan pdf record
   */
  buildTxPlanPdfRecord() {
    return {
      id: 0,
      sort_order: -1,
      type: 'txplan',
      src: '/static/img/pdf.png',
      pane_1: { active: true },
      pane_2: { active: true },
    };
  }

  /**
   * Handles fetching the record image and storing
   * @function
   * @param {String} id - The image record's id
   * @param {String} image_url - The image record's image url
   */
  onFetchRecordImage = (id, image_url) => {
    let new_src = '/static/img/no-image-white.png';

    const request = {
      url: `/api/thumbnail/?file=${image_url}&size=lrg`,
      method: 'GET',
      responseType: 'blob',
    };
    Axios(request)
      .then((res) => {
        if (res.data.size !== 0) {
          new_src = window.URL.createObjectURL(new Blob([res.data]));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => this.updateRecordImageSrc(id, new_src));
  };

  /**
   * Updates the record image state
   * @function
   * @param {String} id - The image record's id
   * @param {String} new_src - The fetched image
   */
  updateRecordImageSrc(id, new_src) {
    const new_image_records = this.state.image_records.map((record) => {
      if (record.id !== id) return record;
      else {
        return {
          ...record,
          src: new_src,
          loading: false,
        };
      }
    });
    this.setState({ image_records: new_image_records });
  }

  /**
   * Updates the scroll option when the route change
   *
   * The route change can occur when pressing forward and backward
   *  broswer history
   *
   * @function
   */
  updateScrollOptionsOnRouteChange() {
    const { pane_1, pane_2 } = this.getURLParams();
    let hasChanged = false;
    const new_scroll_options = this.state.scroll_options.map((option) => {
      const is_active_pane_1 = option.text.replace(SETUP_PREFIX, '') === pane_1;
      const is_active_pane_2 = option.text.replace(SETUP_PREFIX, '') === pane_2;
      if (is_active_pane_1 !== option.active_pane_1 || is_active_pane_2 !== option.active_pane_2) hasChanged = true;
      return {
        ...option,
        active_pane_1: is_active_pane_1,
        active_pane_2: is_active_pane_2,
      };
    });
    if (hasChanged) this.setState({ scroll_options: new_scroll_options });
  }

  /**
   * Updates the model in the state to the new model
   *
   * @param {Number} setup_num - The corresponding setup's number to update
   * @param {Object} new_model - The new model to store
   * @function
   */
  updateModels = (setup_num, new_model) => {
    const new_models = this.state.models.map((model) => {
      if (model.setup_num === setup_num) {
        return {
          ...model,
          ...new_model,
        };
      } else {
        return { ...model };
      }
    });
    this.setState({ models: new_models });
  };

  // Toggle buttons
  // ---------------------------------------------------------------------------

  /**
   * Toggles menu collapse
   * @function
   */
  toggleCollapse = () => this.toggleState('collapse');

  /**
   * Toggles pane 1's menu
   * @function
   */
  toggleCollapsePane1 = () => this.toggleState('collapse_pane_1');

  /**
   * Toggles pane 2's menu
   * @function
   */
  toggleCollapsePane2 = () => this.toggleState('collapse_pane_2');

  /**
   * Toggles split screen
   * @function
   */
  toggleSplit = () => {
    this.setState({
      collapse: !this.state.split ? true : this.state.collapse,
      collapse_pane_1: this.state.split ? true : this.state.collapse_pane_1,
      collapse_pane_2: this.state.split ? true : this.state.collapse_pane_2,
      split: !this.state.split,
    });
  };

  /**
   * Toggles the state
   * @function
   * @param {String} state - State to toggle
   */
  toggleState = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    this.tooltipCleanUp();
  };

  // Horizontal Scroll Area
  // ---------------------------------------------------------------------------

  /**
   * Handles right arrow to next scroll area
   * @function
   */
  nextScrollArea = () => {
    this.setState({
      scroll_area: this.state.scroll_area + 1,
    });
  };

  /**
   * Handles left arrow to previous scroll area
   * @function
   */
  prevScrollArea = () => {
    this.setState({
      scroll_area: this.state.scroll_area - 1 >= 0 ? this.state.scroll_area - 1 : 0,
    });
  };

  /**
   * Handles clicking on selecting scroll area
   * @function
   * @param {Object} e - The click event
   */
  setScrollArea = (e) => {
    const val = e.target.dataset.value;

    this.setState({
      scroll_area: parseInt(val),
    });
  };

  /**
   * Handles clicking on scroll items
   * @function
   * @param {Object} e - The onclick event
   */
  onScrollOptionClick = (e) => {
    const text = e.currentTarget.dataset.text;
    const pane = e.currentTarget.dataset.pane;
    let item = 0;
    const newScrollOptions = this.state.scroll_options.map((option, index) => {
      option[`active_pane_${pane}`] = option.text === text;
      if (option.text === text) {
        item = index;
      }
      return option;
    });
    const newScrollArea = Math.floor(item / this.state.scroll_items);

    this.updateURLParams(text, pane);
    this.setState({
      scroll_options: newScrollOptions,
      scroll_area: newScrollArea,
      [`collapse_pane_${pane}`]: true,
    });
  };

  /**
   * Updates the URL using the text and pane
   * @function
   * @param {String} text - The param to update URL with
   * @param {String} pane - The target pane for text
   */
  updateURLParams = (text, pane) => {
    const { case_id, pane_1, pane_2 } = this.getURLParams();
    const new_param = text.replace(SETUP_PREFIX, '');
    const new_pane_1 = pane === '1' ? new_param : pane_1;
    const new_pane_2 = pane === '2' ? new_param : pane_2;
    this.props.history.push(`/setup/${case_id}/${new_pane_1}/${new_pane_2}`);
  };

  // Image Controller
  //----------------------------------------------------------------------------

  /**
   * Cleans up tooltip by hiding
   * @function
   */
  tooltipCleanUp = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Cleans up timeout in zooming image
   * @function
   */
  timeoutCleanUp = () => {
    clearTimeout(this.state.image_zoom_timeout);
  };

  /**
   * Retrieves the pane from dataset
   * @function
   * @param {Object} e - The click event
   */
  getPaneFromEvent = (e) => {
    return e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.pane ? e.currentTarget.dataset.pane : e;
  };

  /**
   * Handles the different controls on image pane event
   * @function
   * @param {Object} e - The click event
   * @param {String} field - The field to update
   * @param {Function} conditional - The function to get output
   */
  onImagePaneEvent = (e, field, conditional = undefined) => {
    let id = null;
    let old_state = null;
    let new_state = null;
    const pane = this.getPaneFromEvent(e) ? this.getPaneFromEvent(e) : this.state.image_zoom_pane;
    const new_image_records = this.state.image_records.map((record) => {
      const states = record[`pane_${pane}`];
      if (states.active) {
        id = record.id;
        old_state = { rotate: states.rotate, flipH: states.flipH, flipV: states.flipV };
        if (conditional) {
          states[field] = conditional(e, record);
        } else {
          states[field] = !states[field];
        }
        new_state = { scale: states.scale, rotate: states.rotate, flipH: states.flipH, flipV: states.flipV };
      }

      return record;
    });

    this.setState({ image_records: new_image_records });
    const has_changed =
      id &&
      new_state &&
      (old_state.scale !== new_state.scale ||
        old_state.rotate !== new_state.rotate ||
        old_state.flipH !== new_state.flipH ||
        old_state.flipV !== new_state.flipV);
    if (has_changed) syncRecordStateDebounce(id, new_state, 'complete');

    this.tooltipCleanUp();
    this.timeoutCleanUp();
  };

  /**
   * Get selected file's default record_state
   * @function
   * @param {Object} record - File info
   * @return {Object} - Selected file default record state
   */
  getFileDefaultRecordState = (record) => {
    const file_id = record.id;
    const selected_file = this.state.default_record_states.find((file) => file.id === file_id);
    const default_record_state = selected_file && selected_file.default_record_state ? JSON.parse(selected_file.default_record_state) : initialState;
    return default_record_state;
  };

  /**
   * Handle on image reset click
   * @function
   * @param {Object} e - Click event info
   * @param {Object} record - File info
   */
  onImageReset = (e, record) => {
    const default_record_state = this.getFileDefaultRecordState(record);
    this.onImagePaneEvent(e, 'scale', () => default_record_state.scale);
    this.onImagePaneEvent(e, 'rotate', () => default_record_state.rotate);
    this.onImagePaneEvent(e, 'flipH', () => default_record_state.flipH);
    this.onImagePaneEvent(e, 'flipV', () => default_record_state.flipV);
  };

  /**
   * Handles zooming in image
   * @function
   * @param {Object} e - The click event
   * @param {String} pane - The clicked pane
   */
  onImageZoomPlus = (e, pane) => {
    this.onImagePaneEvent(e, 'scale', (event, record) => {
      return Math.min(record[`pane_${pane}`].scale + zoomStep, maxScale);
    });
  };

  /**
   * Handles zooming out image
   * @function
   * @param {Object} e - The click event
   * @param {String} pane - The clicked pane
   */
  onImageZoomMinus = (e, pane) => {
    this.onImagePaneEvent(e, 'scale', (event, record) => {
      return Math.max(record[`pane_${pane}`].scale - zoomStep, minScale);
    });
  };

  /**
   * Handles pressing down the zoom in button
   * @function
   * @param {Object} e - The click event
   */
  onImageZoomPlusMouseDown = (e) => {
    const pane = this.getPaneFromEvent(e) ? this.getPaneFromEvent(e) : this.state.image_zoom_pane;
    this.onImageZoomPlus(e, pane);

    this.setState({
      image_zoom_timeout: setTimeout(this.onImageZoomPlusMouseDown, this.state.image_zoom_rate),
      image_zoom_rate: this.state.image_zoom_rate / 2,
      image_zoom_pane: pane,
    });
  };

  /**
   * Handles pressing down the zoom out button
   * @function
   * @param {Object} e - The click event
   */
  onImageZoomMinusMouseDown = (e) => {
    const pane = this.getPaneFromEvent(e) ? this.getPaneFromEvent(e) : this.state.image_zoom_pane;
    this.onImageZoomMinus(e, pane);

    this.setState({
      image_zoom_timeout: setTimeout(this.onImageZoomMinusMouseDown, this.state.image_zoom_rate),
      image_zoom_rate: this.state.image_zoom_rate / 2,
      image_zoom_pane: pane,
    });
  };

  /**
   * Handles releasing the zoom button
   * @function
   * @param {Object} e - The click event
   */
  onImageZoomMouseUp = (e) => {
    this.timeoutCleanUp();
    this.tooltipCleanUp();

    this.setState({
      image_zoom_rate: 100,
    });
  };

  /**
   * Handles chaning the zoom using the slider
   * @function
   * @param {Object} e - The click event
   */
  onImageZoomChange = (e) => {
    this.timeoutCleanUp();
    this.tooltipCleanUp();

    this.onImagePaneEvent(e, 'scale', (event, record) => {
      return e && e.target && e.target.value ? parseFloat(e.target.value) : 1;
    });
  };

  /**
   * Handles rotating the image
   * @function
   * @param {Object} e - The click event
   */
  onImageRotateClick = (e) => {
    this.onImagePaneEvent(e, 'rotate', (event, record) => {
      const pane = this.getPaneFromEvent(event);
      return record[`pane_${pane}`].rotate - 90 ? (record[`pane_${pane}`].rotate - 90) % 360 : 0;
    });
  };

  /**
   * Handles vertical flip
   * @function
   * @param {Object} e - The click event
   */
  onImageFlipUDClick = (e) => {
    this.onImagePaneEvent(e, 'flipV');
  };

  /**
   * Handles horizontal flip
   * @function
   * @param {Object} e - The click event
   */
  onImageFlipLRClick = (e) => {
    this.onImagePaneEvent(e, 'flipH');
  };

  /**
   * Handles changing image
   * @function
   * @param {Object} e - The click event
   */
  onImageSliderClick = (pane, index) => {
    const records = this.state.image_records.map((record, i) => {
      record[`pane_${pane}`].active = i === index;
      return record;
    });

    this.timeoutCleanUp();
    this.tooltipCleanUp();

    //TBD do not reset its props if the active is the previously active not original pane
    this.setState({
      image_records: records,
      image_zoom_rate: 100,
    });
  };

  //----------------------------------------------------------------------------

  /**
   * Handles clicking on the logo
   * @function
   */
  onLogoClick = (doctor_id) => {
    const { cases, case_id, role } = this.props.case_details;
    const hasAccessToBusinessPortal = getBusinessRoleList().includes(role);
    const latestCaseID = cases[cases.length - 1].case_id;

    if (hasAccessToBusinessPortal) {
      this.props.history.push(`/business/portal/case/${case_id}`);
    } else {
      this.props.history.push(`/portal/${doctor_id}/case/${latestCaseID}`);
    }
  };

  /**
   * Checks whether the URL's pane numbers are valid
   * @function
   * @return {Boolean} Whether the URL's pane numbers are valid
   */
  isURLPaneNumberValid() {
    const { pane_1, pane_2 } = this.props.match && this.props.match.params;
    const detail = this.props.case_file_list?.[this.getURLParams().case_id];
    const setup_process = detail?.setup_process ?? [];

    return this.isSetupNumberExist(pane_1, setup_process.length) && this.isSetupNumberExist(pane_2, setup_process.length);
  }

  /**
   * Checks if the pane number exists
   * @function
   * @param {String} pane - The pane number to check
   * @param {Number} number_of_setup - The number of setup this case has
   * @return {Boolean} Whether the pane number exists
   */
  isSetupNumberExist(pane, number_of_setup) {
    const setup_number = parseInt(pane);
    if (setup_number) {
      return setup_number >= 1 && setup_number <= number_of_setup;
    }
    return true;
  }

  /**
   * Checks if this case has setup
   * @function
   * @return {Boolean} Whether has setup or not
   */
  hasSetupProcess() {
    return this.state.scroll_options.length > 1;
  }

  /**
   * Retrive doctor program enrollment info from case details
   * @function
   * @return {Array} Array of objects containing doctor program enrollment info
   */
  getDoctorProgramEnrollment() {
    const enrolled_programs = this.props?.case_details?.doctor?.program_enrollment;
    return enrolled_programs;
  }

  render() {
    const displayLoading =
      (!this.props.case_details && this.props.case_details_loading) ||
      ((!this.props.case_file_list || _.isEmpty(this.props.case_file_list)) && this.props.case_file_list_loading);
    const hasError =
      this.props.case_details_error ||
      !this.props.case_details ||
      !this.props.case_file_list ||
      this.props.case_file_error ||
      !this.hasSetupProcess() ||
      !this.isURLPaneNumberValid();

    if (displayLoading) {
      return (
        <div className="background--dark">
          <Helmet>
            <title>Loading... | InBrace Smile Design™ Viewer</title>
          </Helmet>
          <div className="fill center">
            <CircleLoader />
          </div>
        </div>
      );
    } else if (hasError) {
      return <NotFound />;
    } else {
      return (
        <div className="background--dark">
          <UserPermission>
            <Shortcuts />
            <Helmet>
              <title>InBrace Smile Design™ Viewer</title>
            </Helmet>

            <PanelMenu
              history_list={this.state.scroll_options}
              collapse={this.state.collapse}
              split={this.state.split}
              onLogoClick={this.onLogoClick}
              toggleCollapse={this.toggleCollapse}
              toggleSplit={this.toggleSplit}
              onScrollOptionClick={this.onScrollOptionClick}
            />
            <PanelViewer
              case_file_list={this.props.case_file_list}
              case_details={this.props.case_details}
              collapse={this.state.collapse}
              collapse_pane_1={this.state.collapse_pane_1}
              collapse_pane_2={this.state.collapse_pane_2}
              split={this.state.split}
              scroll_options={this.state.scroll_options}
              scroll_area={this.state.scroll_area}
              scroll_items={this.state.scroll_items}
              nextScrollArea={this.nextScrollArea}
              prevScrollArea={this.prevScrollArea}
              setScrollArea={this.setScrollArea}
              onScrollOptionClick={this.onScrollOptionClick}
              toggleCollapsePane1={this.toggleCollapsePane1}
              toggleCollapsePane2={this.toggleCollapsePane2}
              image_records={this.state.image_records.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1))}
              onImageSliderClick={this.onImageSliderClick}
              onImageZoomPlus={this.onImageZoomPlus}
              onImageZoomMinus={this.onImageZoomMinus}
              onImageZoomPlusMouseDown={this.onImageZoomPlusMouseDown}
              onImageZoomMinusMouseDown={this.onImageZoomMinusMouseDown}
              onImageZoomMouseUp={this.onImageZoomMouseUp}
              onImageZoomChange={this.onImageZoomChange}
              onImageRotateClick={this.onImageRotateClick}
              onImageFlipUDClick={this.onImageFlipUDClick}
              onImageFlipLRClick={this.onImageFlipLRClick}
              onFetchRecordImage={this.onFetchRecordImage}
              onImageReset={this.onImageReset}
              models={this.state.models}
              updateModels={this.updateModels}
              case_program_enrollment={this.getDoctorProgramEnrollment()}
              hidePanel={false} //flag for testing new setup viewer, will be removed in the future 11/22/2022
            />
          </UserPermission>
          <FeedbackForm />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
    case_details_loading: getCaseDetailsLoading(state),
    case_details_error: getCaseDetailsError(state),
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
    case_file_error: getCaseFileError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseDetails: fetchCaseDetails,
      fetchCaseFileData: fetchCaseFileData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SetupViewer);
