import React, { Component } from 'react';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import TreatmentPlanContent from './initial_plan';
import EnhancementPlanContent from './de_plan';

class TreatmentPlan extends Component {
  componentDidMount() {
    setTimeout(function() {
      this.$('#treatmentPlan').modal('show');
    }, 0);
    this.downloadpdf.bind(this);
  }

  downloadpdf = e => {
    const el = document.getElementById('plandownload');
    let filename = 'TX-Case-' + this.props.caseId + '.pdf';

    if (this.props.caseId.indexOf('-') >= 0) {
      filename = filename.replace('TX-Case-', 'DE-Case-');
    }

    var opt = {
      filename: filename,
      margin: [3, 3, 3, 3],
      pagebreak: { mode: ['css', 'legacy'] }
    };
    html2pdf()
      .set(opt)
      .from(el)
      .toPdf()
      .save();
  };

  printpdf = e => {
    const element = document.getElementById('plandownload');
    var img = html2pdf()
      .from(element)
      .toImg();
    img.then(
      accept => {
        var isFirefox = typeof InstallTrigger !== 'undefined';

        if (isFirefox === true) {
          var canvas = img.prop.canvas;
          const imgData = canvas.toDataURL('image/png');
          var win1 = window.open();

          if (win1 !== null) {
            win1.document.write("<img style='display:block;' src = '" + imgData + "'/>");
            win1.print();
          }
        } else {
          var c = img.prop.img.src;

          printJS(c, 'image');
        }
      },
      reject => {}
    );
  };

  displayUpperMidlineToFacial() {
    let results = '';
    switch (this.props.caseData.dxtxSection1_upperMidline2Facial) {
      case 'correct':
        results = 'correct';
        break;
      case 'midline-right':
        results = `Deviate to the right ${this.props.caseData.dxtxSection1_upperMidline2FacialRight} mm`;
        break;
      case 'midline-left':
        results = `Deviate to the right ${this.props.caseData.dxtxSection1_upperMidline2FacialLeft} mm`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  overbiteMethod() {
    let result = 'NA';

    if (this.props.caseData && this.props.caseData.dxtxSection2_overbite) {
      switch (this.props.caseData.dxtxSection2_overbite) {
        case 'Maintain intial overbite':
          result = 'Maintain intial overbite';
          break;

        case 'Correct open bite':
          result = 'Correct open bite: ';
          break;

        case 'Correct deep bite':
          result = 'Correct deep bite: ';
          break;

        default:
      }
    }

    return result;
  }

  overBiteMethodStatus(upper, lower) {
    let result = '';

    if (upper === 'true' && lower === 'true') {
      result = 'Both';
    } else if (upper === 'true') {
      result = 'Upper';
    } else if (lower === 'true') {
      result = 'Lower';
    }

    return result;
  }

  overBiteMethodCorrection(method) {
    let result = '';
    let upper = '';
    let lower = '';

    if (this.props.caseData && this.props.caseData.dxtxSection2_overbite) {
      switch (this.props.caseData.dxtxSection2_overbite) {
        case 'Correct open bite':
          if (method === 'extrude') {
            upper = this.props.caseData.dxtxSection2_overbiteOpenExtrudeAnteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteOpenExtrudeAnteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Extrude anterior teeth: ' + this.overBiteMethodStatus(upper, lower);
            }
          } else {
            upper = this.props.caseData.dxtxSection2_overbiteOpenIntrudePosteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteOpenIntrudePosteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Intrude posterior teeth: ' + this.overBiteMethodStatus(upper, lower);
            }
          }
          break;

        case 'Correct deep bite':
          if (method === 'extrude') {
            upper = this.props.caseData.dxtxSection2_overbiteDeepExtrudePosteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteDeepExtrudePosteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Extrude posterior teeth: ' + this.overBiteMethodStatus(upper, lower);
            }
          } else {
            upper = this.props.caseData.dxtxSection2_overbiteDeepIntrudeAnteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteDeepIntrudeAnteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Intrude anterior teeth: ' + this.overBiteMethodStatus(upper, lower);
            }
          }

          break;

        default:
      }
    }

    return result;
  }

  teethToTreat() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_teeth) {
      case '5x5':
        results = '5x5 Arch';
        break;
      case '6x6':
        results = '6x6 Arch';
        break;
      case '7x7':
        results = '7x7 Arch';
        break;
      case 'customxcustom':
        results = `Specify location ${this.props.caseData.dxtxSection2_teethCustom}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  overjetDisplay() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_overjet) {
      case 'Maintain initial overjet':
        results = 'Maintain initial overjet';
        break;
      case 'Improve resulting overjet':
        results = 'Improve resulting overjet';
        break;
      case 'Achieve normal overjet':
        results = 'Achieve normal overjet';
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  posteriorCrossBite() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_posterior) {
      case 'Do not correct':
        results = 'Do not correct';
        break;
      case 'Correct (additional auxilary device may be needed)':
        results = 'Correct (additional auxilary device may be needed)';
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  antPostRelationship = function(left = '', right = '') {
    if (left === '' && right === '') {
      return 'Neither';
    } else if (left === 'true' && right === 'true') {
      return 'Both';
    } else if (left === 'true') {
      return 'Left';
    } else if (right === 'true') {
      return 'Right';
    } else {
      return 'NA';
    }
  };

  spacing() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_spacing) {
      case 'Close all the spaces':
        results = 'Close all the spaces';
        break;
      case 'custom':
        results = `Leave some specific spaces : ${this.props.caseData.dxtxSection2_spacingValue}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  midlineUpper() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_midlineUpper) {
      case 'Maintain':
        results = 'Maintain';
        break;
      case 'Move right':
        results = `Move to patient's right ${this.props.caseData.dxtxSection2_midlineUpperRight} mm`;
        break;
      case 'Move left':
        results = `Move to patient's left ${this.props.caseData.dxtxSection2_midlineUpperLeft} mm`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  midlineLower() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_midlineLower) {
      case 'Maintain':
        results = 'Maintain';
        break;
      case 'Move right':
        results = `Move to patient's right ${this.props.caseData.dxtxSection2_midlineLowerRight} mm`;
        break;
      case 'Move left':
        results = `Move to patient's left ${this.props.caseData.dxtxSection2_midlineLowerLeft} mm`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  render() {
    if (this.props.caseData === undefined) {
      return (
        <div
          className="modal fade"
          id="treatmentPlan"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="treatmentPlan"
          data-backdrop="false"
          data-keyboard="false"
          onClick={this.props.onModalClick}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button id="treatmentPlanDismiss" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onModalDismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">{this.props.title}</h4>
                <div className="modal-body">Sorry this patient doesn't have a dxtx plan data</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.caseId && this.props.caseId.indexOf('-') >= 0) {
      // DE_PLAN.JS HTML
      return (
        <div
          className="modal fade"
          id="treatmentPlan"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="treatmentPlan"
          data-backdrop="false"
          data-keyboard="false"
          onClick={this.props.onModalClick}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button id="treatmentPlanDismiss" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onModalDismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">Digital Enhancement Plan</h4>
              </div>
              <div className="modal-body page-break" id="plandownload">
                <EnhancementPlanContent
                  caseData={this.props.caseData}
                  arch={this.props.arch}
                  caseId={this.props.caseId}
                  selectedPatient={this.props.selectedPatient}
                  doctorfirstName={this.props.doctorfirstName}
                  doctorlastName={this.props.doctorlastName}
                />
              </div>
              <div id="my_MM" style={{ height: '1mm' }} />
              <div className="modal-footer">
                <button id="myMm" onClick={this.printpdf} name="print" type="button" className="btn btn-primary pull-left">
                  <i className="fa fa-print" aria-hidden="true" /> Print
                </button>
                <button id="myMm" onClick={this.downloadpdf} name="download" type="button" className="btn btn-primary pull-left">
                  <i className="fa fa-download" aria-hidden="true" /> Download
                </button>
                <button type="button" className="btn btn-primary pull-right" data-dismiss="modal" onClick={this.props.onModalDismiss}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="modal fade"
          id="treatmentPlan"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="treatmentPlan"
          data-backdrop="false"
          data-keyboard="false"
          onClick={this.props.onModalClick}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button id="treatmentPlanDismiss" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onModalDismiss}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title">{this.props.title}</h4>
              </div>
              <div className="modal-body page-break" id="plandownload">
                <TreatmentPlanContent
                  caseData={this.props.caseData}
                  arch={this.props.arch}
                  caseId={this.props.caseId}
                  selectedPatient={this.props.selectedPatient}
                  doctorfirstName={this.props.doctorfirstName}
                  doctorlastName={this.props.doctorlastName}
                />
              </div>
              <div id="my_MM" style={{ height: '1mm' }} />
              <div className="modal-footer">
                <button id="myMm" onClick={this.printpdf} name="print" type="button" className="btn btn-primary pull-left">
                  <i className="fa fa-print" aria-hidden="true" /> Print
                </button>
                <button id="myMm" onClick={this.downloadpdf} name="download" type="button" className="btn btn-primary pull-left">
                  <i className="fa fa-download" aria-hidden="true" /> Download
                </button>
                <button type="button" className="btn btn-primary pull-right" data-dismiss="modal" onClick={this.props.onModalDismiss}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TreatmentPlan;
