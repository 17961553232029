import React from 'react';
import { SingleControlBtn } from './wasm_control_btn';
import WasmToggleBtn from '../wasm_toggle_btn';
import classNames from 'classnames';

function WasmDropdownListItem(props) {
  const { text, id, onClick, checked, disabled, hasToggle, className } = props;
  const classnames = classNames('wasm-dropdown-list-item', {
    disabled: disabled,
    'no-toggle': !hasToggle,
    [className]: className,
  });

  const handleLabelClick = () => {
    if (disabled || hasToggle) return;
    onClick();
  };

  return (
    <label id={id} className={classnames} onClick={handleLabelClick}>
      <div>{text}</div>
      {hasToggle ? <WasmToggleBtn onClick={onClick} checked={checked} disabled={disabled} /> : null}
    </label>
  );
}

/**
 * Displays panel top tool bar
 * @component
 */
export default function WasmToolbarDropdown(props) {
  const { active, dropdown, dropdownIsHidden } = props.btn;
  const classnames = classNames({
    'wasm-toolbar-dropdown': true,
    'wasm-toolbar-dropdown--active': active,
  });

  return (
    <div className={classnames}>
      <SingleControlBtn {...props.btn} />
      {!dropdownIsHidden ? (
        <div className="wasm-toolbar-dropdown-list">
          {dropdown.map((item, index) => (
            <div key={index}>
              <WasmDropdownListItem {...item} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
