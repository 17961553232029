import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Logout from '../logout/logout';
import './customer_agreement.scss';

import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';

class Agreement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.onAgreeClick = this.onAgreeClick.bind(this);
  }

  componentDidMount() {
    let content = document.getElementById('content');
    if (content) {
      content.id = 'content-alt';
    }
  }

  onAgreeClick(event) {
    Axios({
      url: `/apiV2/agreement`,
      method: 'POST',
    }).then((response) => {
      window.location.reload();
    });
  }

  /**
   * Displays customer agreement message
   * @function
   * @param {boolean} is_new - Indicator for messageType === 'new'
   * @return {JSX} - JSX for message
   */
  displayMessage = (is_new) => {
    const message = is_new
      ? 'Please confirm that you agree to Swift Health Systems'
      : "'Swift Health Systems' Customer Agreement has been updated to include a Business Associate Agreement (BAA). Please confirm that you understand and agree to the updated";
    return (
      <>
        <p>
          {message}{' '}
          <a
            className="underlined"
            href={is_new ? `/agreement/${this.props.token}` : '/portal/agreement'}
            onClick={is_new ? this.props.onLinkClick : null}
            target="_blank"
            rel="noopener noreferrer"
          >
            Customer Agreement
          </a>{' '}
          and the{' '}
          <a className="underlined" href="https://www.inbrace.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </>
    );
  };

  render() {
    const is_new = this.props.messageType === 'new';
    return (
      <div className={is_new ? 'ipp-light background' : ''}>
        <Helmet>
          <title>Agreement | InBrace Smile Design Studio™</title>
        </Helmet>
        <div className="content--fullscreen center">
          <CardContainer className={`${is_new ? ' card--no-top-border' : 'card--customer-agreement'}`} type="message">
            {is_new ? (
              <>
                <h3>Welcome to the InBrace Smile Design Studio™</h3>
                {this.displayMessage(is_new)}
                <Button type="submit" onClick={this.props.onNewAgreeClick}>
                  I Agree
                </Button>
                <a href="/" type="submit">
                  <Button theme="secondary">Return</Button>
                </a>
              </>
            ) : (
              <>
                <h3>Customer Agreement and Privacy Policy</h3>
                {this.displayMessage(is_new)}
                <Button type="submit" onClick={this.onAgreeClick}>
                  Agree
                </Button>
                <Logout name="Cancel" />
              </>
            )}
          </CardContainer>
        </div>
      </div>
    );
  }
}

export default withRouter(Agreement);
