/**
 * File: browser_warning.js - display a message for browser that are not using
 * google chrome
 * Copyright: (c) Copyright October 2018 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { fab, faSalesforce } from '@fortawesome/free-brands-svg-icons';
import {
  faBriefcase,
  faBoxOpen,
  faBuilding,
  faChartLine,
  faCaretDown,
  faCaretUp,
  faCog,
  faClipboardList,
  faExternalLinkAlt,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileExcel,
  faFileMedical,
  faFilePdf,
  faFileVideo,
  faFolder,
  faFolderPlus,
  faHome,
  faIdCard,
  faImages,
  faPlusSquare,
  faPortrait,
  faQuestionCircle,
  faShieldAlt,
  faShippingFast,
  faSignOutAlt,
  faStickyNote,
  faTooth,
  faTrash,
  faTrashAlt,
  faTruck,
  faUser,
  faUserCircle,
  faUserCog,
  faUsers,
  faListUl,
  faLink,
  faSave,
  faTimes,
  faStore,
  faXmarkCircle,
  faXmark,
  faArrowRightLong,
  faBackward,
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { library } from '@fortawesome/fontawesome-svg-core';
// Internal Components
import Layout from './doctor/layout/layout';
import Account from './doctor/account_settings/account_settings';
import AccountList from './business/account_list/account_list';
import AddAccount from './doctor/account_details/add_account';
import PrivateRoute from './common/private_route';
import BrowserWarning from './components/browser_warning/browser_warning';
import BusinessCaseDetails from './business/case_details/case_details';
import BusinessCaseList from './business/case_list/case_list';
import BusinessHome from './business/home/home';
import BusinessIRList from './business/ir_list/ir_list';
import BusinessNavigation from './business/layout/navigation';
import BusinessPRList from './business/pr_list/pr_list';
import BusinessReports from './business/reports/reports';
import BusinessShippingList from './business/shipping_list/shipping_list';
import BusinessShippoReportsData from './business/reports/shippo_reports_data';
import BusinessSmileSimulationDetails from './business/smile_simulation_details/smile_simulation_details';
import BusinessSmileSimulationList from './business/smile_simulation_list/smile_simulation_list';
import CaseDetails from './doctor/case_details/case_details';
import CaseSubmission from './doctor/case_submission/case_submission';
import CsrRecord from './doctor/csr_submission/csr_submission';
import CustomerAgreementExternal from './doctor/customer_agreement/external_customer_agreement_viewer';
import CustomerAgreementInternal from './doctor/customer_agreement/internal_customer_agreement_viewer';
import Dashboard from './doctor/dashboard/dashboard';
import DeSubmission from './doctor/de_submission/de_submission';
import DsoAccountList from './doctor/account_list/dso_account_list';
import DxTxProto from './doctor/dxtx_proto/dxtx_proto';
import EditAccount from './doctor/edit_account/edit_account';
import EnvironmentBanner from './components/environment_banner/environment_banner';
import ItemRequest from './doctor/ir_submission/ir_submission';
import KnowledgeBase from './doctor/knowledge_base/knowledge_base';
import Login from './doctor/login/login';
import NewCase from './doctor/case_submission/new_case';
import NotFound from './doctor/404/not_found';
import PasswordRecovery from './doctor/password_recovery/password_recovery';
import PasswordReset from './doctor/password_reset/password_reset';
import Permission from './business/permission/permission';
import Portal from './doctor/case_list/case_list';
import Programs from './doctor/account_programs/account_programs';
import ProgressRecord from './doctor/pr_submission/pr_submission';
import ProspectAdd from './doctor/prospect_details/prospect_add';
import ProspectDetails from './doctor/prospect_details/prospect_details';
import ProviderTierDetails from './doctor/dashboard/provider_tier_details';
import RecordReupload from './doctor/record_reupload/record_reupload';
import RevertCase from './business/revert_case/revert_case';
import Settings from './business/settings/settings';
import SetupSmileViewer from './components/setup_smile_viewer/setup_smile_viewer';
import SetupViewer from './components/setup_viewer/setup_viewer';
import SmileDesignPreferences from './doctor/smile_design_preferences/smile_design_preferences';
import SmileSimulationDetails from './doctor/smile_simulation_details/smile_simulation_details';
import SmileSimulationReupload from './doctor/smile_simulation_reupload/smile_simulation_reupload';
import SmileSimulationSubmission from './doctor/smile_simulation_submission/smile_simulation_submission';
import StorefrontUnavailable from './doctor/404/storefront_unavailable';
import WasmViewer from './components/wasm_viewer/wasm_viewer';
import RecordViewerPopout from './components/popout/record_viewer_popout';
// Internal Functions
import { fakeAuth } from './doctor/login/login';
import { getDoctorsIdFromRoute } from './common/route';
import { isKnowledgeBaseRoute } from './common/helpers';
import { setBrowserTabId, storageMessageHandler } from './common/storage';
import { checkJWTToken } from './common/token';
// Context
import { UserPermission } from './context/user_permission';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchPrograms } from './redux/actions/programs';
// IPP redesign
import './bpp-dark.scss';
import ManualWire from './business/case_details/ir_production/manual_wire/manual_wire';

library.add(
  fab,
  faArrowRightLong,
  faBoxOpen,
  faBriefcase,
  faBuilding,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCog,
  faClipboardList,
  faExternalLinkAlt,
  faFileAlt,
  faFileArchive,
  faFileDownload,
  faFileExcel,
  faFileMedical,
  faFilePdf,
  faFileVideo,
  faFolder,
  faFolderPlus,
  faHome,
  faIdCard,
  faImages,
  faPlusSquare,
  faPortrait,
  faQuestionCircle,
  faSalesforce,
  faShieldAlt,
  faShippingFast,
  faSignOutAlt,
  faStickyNote,
  faTooth,
  faTrash,
  faTrashAlt,
  faTruck,
  faUser,
  faUserCircle,
  faUserCog,
  faUsers,
  faListUl,
  faLink,
  faSave,
  faTimes,
  faStore,
  faXmarkCircle,
  faXmark,
  faBackward
);

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

const PublicRoute = ({ ...rest }) => {
  const isAllowed = fakeAuth.isLoggedIn();

  return isAllowed ? (
    <Redirect to="/portal" />
  ) : (
    <React.Fragment>
      <BrowserWarning />
      <Route {...rest} />
    </React.Fragment>
  );
};
/**
 * Creates the Generic Doctor Layout
 */
const DoctorLayout = ({ ...rest }) => {
  let href = window.location.href;
  let ref = href.match(/portal\/\d+/);

  const knowledgebase_ref = isKnowledgeBaseRoute();
  const doctor_id = getDoctorsIdFromRoute();

  if (ref || knowledgebase_ref) {
    return (
      <>
        <Helmet title={'Loading... | InBrace Smile Design Studio™'} />
        <EnvironmentBanner />
        <Layout doctor_id={doctor_id} {...rest} />
      </>
    );
  } else {
    return (
      <div>
        <PrivateRoute {...rest} />
      </div>
    );
  }
};

const BusinessLayout = ({ ...rest }) => {
  let href = window.location.href;
  let ref = href.match(/business/);
  let ref_report_data = href.match(/production-planning-report/);
  let ref_shippo_report_data = href.match(/production-shippo-report/);

  // I'm putting the manual wire design title here because the multiple updates available in the page are breaking Helmet component
  if (ref) {
    return (
      <UserPermission>
        <div className="bpp-dark">
          <Helmet>
            <title>{href.match(/manual-wire/) ? 'Manual Wire Design' : 'Loading...'} | InBrace Smile Design Studio™</title>
          </Helmet>
          <BrowserWarning />
          {!ref_report_data && !ref_shippo_report_data ? <BusinessNavigation /> : null}
          <div className="sidenav-offset">
            <main>
              <div className="wrapper">
                <div className="content">
                  <PrivateRoute {...rest} />
                </div>
              </div>
            </main>
          </div>
        </div>
      </UserPermission>
    );
  }
};

class App extends Component {
  componentDidMount() {
    setBrowserTabId();
    window.addEventListener('storage', storageMessageHandler);
    if (checkJWTToken()) {
      this.props.fetchPrograms({ params: { tags: true, order_by: 'name' } });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', storageMessageHandler);
  }

  render() {
    return (
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PublicRoute exact path="/recovery" component={PasswordRecovery} />
          <PublicRoute exact path="/password/reset/:token" component={PasswordReset} />
          <PublicRoute exact path="/agreement/:token" component={CustomerAgreementExternal} />
          <PrivateRoute exact path="/portal/agreement" component={CustomerAgreementInternal} />
          <PrivateRoute exact path="/setup/:case_id/:pane_1?/:pane_2?" component={SetupViewer} />
          <PrivateRoute exact path="/smile_setup/:smile_id/:pane_1?" component={SetupSmileViewer} />
          <PrivateRoute exact path="/smile_design/:case_id" component={WasmViewer} />
          <PrivateRoute exact path="/popout/record_viewer/:case_id" component={RecordViewerPopout} />

          <BusinessLayout exact path="/business/portal/manual-wire/:case_id" component={ManualWire} />
          <BusinessLayout exact path="/business/portal/revert-case" component={RevertCase} />
          <BusinessLayout exact path="/business/portal/accounts/edit/:doctorid" component={AccountList} />
          <BusinessLayout exact path="/business/portal/accounts/add" component={AccountList} />
          <BusinessLayout exact path="/business/portal/accounts/profile" component={AccountList} />
          <BusinessLayout exact path="/business/portal/accounts/permission" component={AccountList} />
          <BusinessLayout exact path="/business/portal/accounts" component={AccountList} />
          <BusinessLayout exact path="/business/portal/case/:id" component={BusinessCaseDetails} />
          <BusinessLayout exact path="/business/portal/case/:id/item" component={BusinessCaseDetails} />
          <BusinessLayout exact path="/business/portal/case/:id/progress" component={BusinessCaseDetails} />
          <BusinessLayout exact path="/business/portal/cases" component={BusinessCaseList} />
          <BusinessLayout exact path="/business/portal" component={BusinessHome} />
          <BusinessLayout exact path="/business" component={BusinessHome} />
          <BusinessLayout exact path="/business/portal/item" component={BusinessIRList} />
          <BusinessLayout exact path="/business/portal/smile/:id" component={BusinessSmileSimulationDetails} />
          <BusinessLayout exact path="/business/portal/smiles" component={BusinessSmileSimulationList} />
          <BusinessLayout exact path="/business/portal/reports" component={BusinessReports} />
          <BusinessLayout exact path="/business/portal/production-shippo-report" component={BusinessShippoReportsData} />
          <BusinessLayout exact path="/business/portal/permission" component={Permission} />
          <BusinessLayout exact path="/business/portal/progress" component={BusinessPRList} />
          <BusinessLayout exact path="/business/portal/settings" component={Settings} />
          <BusinessLayout exact path="/business/portal/shipping" component={BusinessShippingList} />
          <DoctorLayout exact path="/portal/knowledgebase/:cat?/:extra*" component={KnowledgeBase} />
          <DoctorLayout exact path="/portal/:doctorid/dashboard" component={Dashboard} />
          <DoctorLayout exact path="/portal/:doctorid/tier-details" component={ProviderTierDetails} />
          <DoctorLayout exact path="/portal/:doctorid/submission/doctor" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/patient" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/dxtx" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/dxtx_proto" component={DxTxProto} />
          <DoctorLayout exact path="/portal/:doctorid/submission/photos" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/radiographs" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/scans" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/submit" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/submission/confirmation" component={CaseSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/upload/:caseid" component={RecordReupload} />
          <DoctorLayout exact path="/portal/:doctorid/digital/overview" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/digital/doctor" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/digital/enhancement" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/digital/photos" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/digital/radiographs" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/digital/scans" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/account/smile-design-preferences" component={SmileDesignPreferences} />
          <DoctorLayout exact path="/portal/:doctorid/digital/submit" component={DeSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/case/:caseid" component={CaseDetails} />
          <DoctorLayout exact path="/portal/:doctorid/item/:caseid" component={CaseDetails} />
          <DoctorLayout exact path="/portal/:doctorid/progress/:caseid" component={CaseDetails} />
          <DoctorLayout exact path="/portal/:doctorid/note/:caseid" component={CaseDetails} />
          <DoctorLayout exact path="/portal/:doctorid/account" component={Account} />
          <DoctorLayout exact path="/portal/:doctorid/account/settings" component={Account} />
          <DoctorLayout exact path="/portal/:doctorid/account/locator" component={Account} />
          <DoctorLayout exact path="/portal/:doctorid/account/itero" component={Account} />
          <DoctorLayout exact path="/portal/:doctorid/accounts" component={DsoAccountList} />
          <DoctorLayout exact path="/portal/:doctorid/accounts/edit/:dsodoctorid" component={EditAccount} />
          <DoctorLayout exact path="/portal/:doctorid/accounts/add" component={AddAccount} />
          <DoctorLayout exact path="/portal/:doctorid/itemrequest" component={ItemRequest} />
          <DoctorLayout exact path="/portal/:doctorid/prssubmit" component={ProgressRecord} />
          <DoctorLayout exact path="/portal/:doctorid/csqsubmit" component={ProgressRecord} />
          <DoctorLayout exact path="/portal/:doctorid/csrsubmit" component={CsrRecord} />
          <DoctorLayout exact path="/portal/:doctorid/programs" component={Programs} />
          <DoctorLayout exact path="/portal/:doctorid/prospect/add" component={ProspectAdd} />
          <DoctorLayout exact path="/portal/:doctorid/prospect/edit/:propspectid" component={ProspectDetails} />
          <DoctorLayout exact path="/portal/:doctorid/smile/detail/:smileid" component={SmileSimulationDetails} />
          <DoctorLayout exact path="/portal/:doctorid/smile/submission" component={SmileSimulationSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/smile/submission/:smileid" component={SmileSimulationSubmission} />
          <DoctorLayout exact path="/portal/:doctorid/smile/reupload/:smileid" component={SmileSimulationReupload} />
          <DoctorLayout exact path="/portal/:doctorid" component={Portal} />
          <DoctorLayout exact path="/portal/:doctorid/new_case" component={NewCase} />
          <DoctorLayout exact path="/portal/:doctorid/storefront_unavailable" component={StorefrontUnavailable} />

          <Route path="*" component={NotFound} />
          <Redirect from="*" to={'/'} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPrograms: fetchPrograms,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(App);
