import _ from 'lodash';

export const CLOSE_CASE_FILTER_PREFERENCES_MODAL = 'CLOSE_CASE_FILTER_PREFERENCES_MODAL';
export const OPEN_CASE_FILTER_PREFERENCES_MODAL = 'OPEN_CASE_FILTER_PREFERENCES_MODAL';
export const EDIT_CASE_FILTER_PREFERENCES_MODAL = 'EDIT_CASE_FILTER_PREFERENCES_MODAL';

export function closeCaseFilterPreferencesModal() {
  return {
    type: CLOSE_CASE_FILTER_PREFERENCES_MODAL,
  };
}

export function openCaseFilterPreferencesModal(case_filter_preferences_editable) {
  return {
    type: OPEN_CASE_FILTER_PREFERENCES_MODAL,
    case_filter_preferences_editable: case_filter_preferences_editable,
  };
}

export function editCaseFilterPreferencesModal(case_filter_preferences_editable) {
  return {
    type: EDIT_CASE_FILTER_PREFERENCES_MODAL,
    case_filter_preferences_editable: case_filter_preferences_editable,
  };
}

export function initializeCaseFilterPreferencesModal() {
  return (dispatch, getState) => {
    let { case_filter_preferences } = getState().caseFilterPreferencesReducer;
    const { case_filter_preferences_options } = getState().caseFilterPreferencesModalReducer;

    case_filter_preferences = JSON.stringify(case_filter_preferences) === '{}' ? case_filter_preferences_options : case_filter_preferences;
    case_filter_preferences = _.cloneDeep(case_filter_preferences);

    dispatch(openCaseFilterPreferencesModal(case_filter_preferences));
  };
}

export function onParentCheckboxClick(key) {
  return (dispatch, getState) => {
    const { case_filter_preferences_editable, case_filter_preferences_options } = getState().caseFilterPreferencesModalReducer;
    let case_filter_preferences_updates = _.cloneDeep(case_filter_preferences_editable);

    if (case_filter_preferences_editable[key]) {
      delete case_filter_preferences_updates[key];
    } else {
      case_filter_preferences_updates[key] = case_filter_preferences_options[key];
    }

    dispatch(editCaseFilterPreferencesModal(case_filter_preferences_updates));
  };
}

export function onChildCheckboxClick(key, value) {
  return (dispatch, getState) => {
    const { case_filter_preferences_editable, case_filter_preferences_options } = getState().caseFilterPreferencesModalReducer;
    let case_filter_preferences_updates = _.cloneDeep(case_filter_preferences_editable);

    if (case_filter_preferences_editable[key] && case_filter_preferences_editable[key][value]) {
      delete case_filter_preferences_updates[key][value];
    } else {
      if (!case_filter_preferences_updates[key]) {
        case_filter_preferences_updates[key] = {};
      }
      case_filter_preferences_updates[key][value] = case_filter_preferences_options[key][value];
    }

    if (_.isEmpty(case_filter_preferences_updates[key])) {
      delete case_filter_preferences_updates[key];
    }
    dispatch(editCaseFilterPreferencesModal(case_filter_preferences_updates));
  };
}

export function onInnerChildCheckboxClick(key, inner_key, inner_value) {
  return (dispatch, getState) => {
    const { case_filter_preferences_editable, case_filter_preferences_options } = getState().caseFilterPreferencesModalReducer;
    let case_filter_preferences_updates = _.cloneDeep(case_filter_preferences_editable);
    if (
      case_filter_preferences_editable[key] &&
      case_filter_preferences_editable[key][inner_key] &&
      case_filter_preferences_editable[key][inner_key].length > 0 &&
      _.intersection(case_filter_preferences_editable[key][inner_key], [inner_value]).length > 0
    ) {
      case_filter_preferences_updates[key][inner_key] = _.pull(case_filter_preferences_updates[key][inner_key], inner_value);
    } else if (case_filter_preferences_editable[key] && case_filter_preferences_editable[key][inner_key]) {
      case_filter_preferences_updates[key][inner_key].push(inner_value);
    } else if (!case_filter_preferences_updates[key]) {
      case_filter_preferences_updates[key] = { [inner_key]: [inner_value] };
    } else if (!case_filter_preferences_updates[key][inner_key]) {
      case_filter_preferences_updates[key][inner_key] = [inner_value];
    } else {
      case_filter_preferences_updates[key][inner_key] = case_filter_preferences_options[key][inner_key];
    }

    if (_.isEmpty(case_filter_preferences_updates[key][inner_key])) {
      delete case_filter_preferences_updates[key][inner_key];
    }

    if (_.isEmpty(case_filter_preferences_updates[key])) {
      delete case_filter_preferences_updates[key];
    }

    dispatch(editCaseFilterPreferencesModal(case_filter_preferences_updates));
  };
}
