//----------------------------------------------------------------------------
// Password Validation Constants
//----------------------------------------------------------------------------
const MIN_PASSWORD_LENGTH = 12;

//----------------------------------------------------------------------------
// Password Validation Functions
//----------------------------------------------------------------------------
const hasMatchingPassword = (password1, password2) => password1 === password2;
const hasMinimumLength = password => password && password.length >= MIN_PASSWORD_LENGTH;
const hasAtleastOneSpecialChar = password => runRegEx(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}]/, password);
const hasAtLeastOneNumberChar = password => runRegEx(/\d/, password);
const hasAtLeastOneUpperChar = password => runRegEx(/[A-Z]/, password);
const hasAtLeastOneLowerChar = password => runRegEx(/[a-z]/, password);
const hasMetAllPasswordCondition = password =>
  hasMinimumLength(password) &&
  hasAtleastOneSpecialChar(password) &&
  hasAtLeastOneNumberChar(password) &&
  hasAtLeastOneUpperChar(password) &&
  hasAtLeastOneLowerChar(password);

const hasAnyPasswordErrorMessage = password => {
  const validate_functions = [hasMinimumLength, hasAtleastOneSpecialChar, hasAtLeastOneNumberChar, hasAtLeastOneUpperChar, hasAtLeastOneLowerChar];
  const error_names = ['hasMinimumLength', 'hasAtleastOneSpecialChar', 'hasAtLeastOneNumberChar', 'hasAtLeastOneUpperChar', 'hasAtLeastOneLowerChar'];

  let error_message = '';

  for (let i = 0; i < validate_functions.length; i++) {
    error_message = !validate_functions[i](password) ? getErrorMessagesByFunction(error_names[i]) : '';

    if (error_message !== '') {
      break;
    }
  }

  return error_message;
};

//----------------------------------------------------------------------------
// Password Validation Helpers
//----------------------------------------------------------------------------
const runRegEx = (reg, val) => reg.test(val);

//----------------------------------------------------------------------------
// Password Validation Messages
//----------------------------------------------------------------------------
const getErrorMessagesByFunction = error => {
  let message = '';
  switch (error) {
    case 'hasMatchingPassword':
      message = 'Passwords do not match';
      break;
    case 'hasMinimumLength':
      message = `Password must be at least ${MIN_PASSWORD_LENGTH} characters in length`;
      break;
    case 'hasAtleastOneSpecialChar':
      message = `Password must have at least one special character: !"#$%&'()*+,-./\\:;<=>?@[]^_\`{|}`;
      break;
    case 'hasAtLeastOneNumberChar':
      message = `Password must have at least one number`;
      break;
    case 'hasAtLeastOneUpperChar':
      message = `Password must have at least one uppercase character`;
      break;
    case 'hasAtLeastOneLowerChar':
      message = `Password must have at least one lowercase character`;
      break;
    default:
  }

  return message;
};

const getErrorMessageForPasswordMisMatch = () => {
  return getErrorMessagesByFunction('hasMatchingPassword');
};

export {
  MIN_PASSWORD_LENGTH,
  hasMatchingPassword,
  hasMinimumLength,
  hasAtleastOneSpecialChar,
  hasAtLeastOneNumberChar,
  hasAtLeastOneUpperChar,
  hasAtLeastOneLowerChar,
  hasMetAllPasswordCondition,
  getErrorMessageForPasswordMisMatch,
  hasAnyPasswordErrorMessage
};
