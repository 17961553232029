import { prepareStateWithCaseData, TEETHS } from '../../../business/case_details/ir_production/manual_wire/manual_wire_utils';

import {
  SET_LOOP_TO_DEFAULT,
  SET_LOCKET_TO_DEFAULT,
  SET_MANUAL_WIRE_AUTO,
  SET_MANUAL_WIRE_ARCH,
  SET_MANUAL_WIRE_ARCH_OPTIONS,
  SET_MANUAL_WIRE_CASE_DATA,
  SET_MANUAL_WIRE_DEFAULT_CASE_DATA,
  SET_MANUAL_WIRE_LENGTH,
  SET_MANUAL_WIRE_LOCKET,
  SET_MANUAL_WIRE_LOOP,
  SET_MANUAL_WIRE_OFFSET_X,
  SET_MANUAL_WIRE_OFFSET_Y,
  SET_MANUAL_WIRE_PROGRAM,
  SET_MANUAL_WIRE_SMARTWIRE_OPTIONS,
  SET_MANUAL_WIRE_TEETH_DISABLED,
  SET_MANUAL_WIRE_TEETH_ERROR,
  REMOVE_MANUAL_WIRE_TEETH_ERROR,
  SET_MANUAL_WIRE_REFRESH,
  SET_MANUAL_WIRE_ERROR,
  ADD_MANUAL_WIRE_ERROR,
  SET_MANUAL_WIRE_TEETH_MISSING,
  SET_MANUAL_WIRE_TEETH_RANGE,
  SET_MANUAL_WIRE_WIRE,
  SET_MANUAL_WIRE_CUSTOM_DATA,
} from '../../actions/manual_wire/manual_wire';

const getDefaultTetthState = () => {
  return {
    loop: 'U',
    length: {
      initial: '',
      intermediate: '',
      final: '',
    },
    generatedLength: {
      initial: '',
      intermediate: '',
      final: '',
    },
    offset: {
      x: '',
      y: '',
    },
    locket: 'STD',
    malloclusionBracToBracDist: '',
    idealBracToBracDist: '',
    error: false,
    headerError: false,
    disabled: false,
    pairTeeth: '',
  }
}

const getDefaultL8State = () => {
  return {
    locket: 'STD',
    malloclusionBracToBracDist: '',
    idealBracToBracDist: '',
    error: false,
    headerError: false,
    disabled: false,
  }
}

const defaultState = {
  upper: {
    UR8: getDefaultTetthState(),
    UR7: getDefaultTetthState(),
    UR6: getDefaultTetthState(),
    UR5: getDefaultTetthState(),
    UR4: getDefaultTetthState(),
    UR3: getDefaultTetthState(),
    UR2: getDefaultTetthState(),
    UR1: getDefaultTetthState(),
    UL1: getDefaultTetthState(),
    UL2: getDefaultTetthState(),
    UL3: getDefaultTetthState(),
    UL4: getDefaultTetthState(),
    UL5: getDefaultTetthState(),
    UL6: getDefaultTetthState(),
    UL7: getDefaultTetthState(),
    UL8: getDefaultL8State(),
    range: [],
    missing: [],
    smartwireOptions: [],
  },
  lower: {
    LR8: getDefaultTetthState(),
    LR7: getDefaultTetthState(),
    LR6: getDefaultTetthState(),
    LR5: getDefaultTetthState(),
    LR4: getDefaultTetthState(),
    LR3: getDefaultTetthState(),
    LR2: getDefaultTetthState(),
    LR1: getDefaultTetthState(),
    LL1: getDefaultTetthState(),
    LL2: getDefaultTetthState(),
    LL3: getDefaultTetthState(),
    LL4: getDefaultTetthState(),
    LL5: getDefaultTetthState(),
    LL6: getDefaultTetthState(),
    LL7: getDefaultTetthState(),
    LL8: getDefaultL8State(),
    range: [],
    missing: [],
    smartwireOptions: [],
  },
  auto: false,
  arch: '',
  wire: '',
  program: 'preloaded',
  error: false,
  errorMessage: [],
  donwloadUrl: '',
  archOptions: [],
};

const initialState = defaultState;

export function manualWireReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOOP_TO_DEFAULT:
      const newStateLoop = { ...state };
      const archLoop = action.payload.arch;

      for (const key in defaultState[archLoop]) {
        if (defaultState[archLoop].hasOwnProperty(key) && newStateLoop[archLoop].hasOwnProperty(key)) {
          state[archLoop][key].loop = 'U';
        }
      }
    
      return newStateLoop;
    case SET_LOCKET_TO_DEFAULT:
      const newStateLocket = { ...state };
      const archLocket = action.payload.arch;

      for (const key in defaultState[archLocket]) {
        if (defaultState[archLocket].hasOwnProperty(key) && newStateLocket[archLocket].hasOwnProperty(key)) {
          state[archLocket][key].locket = 'STD';
        }
      }
    
      return newStateLocket;
    case SET_MANUAL_WIRE_ARCH:
      return {
        ...state,
        arch: action.payload.arch,
      };
    case SET_MANUAL_WIRE_AUTO:
      return {
        ...state,
        auto: action.payload.auto,
      };
    case SET_MANUAL_WIRE_WIRE:
      return {
        ...state,
        wire: action.payload.wire,
      };
    case SET_MANUAL_WIRE_PROGRAM:
      return {
        ...state,
        program: action.payload.program,
      };
    case SET_MANUAL_WIRE_LOCKET:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            locket: action.payload.locket,
          },
        },
      };
    case SET_MANUAL_WIRE_LOOP:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            loop: action.payload.loop,
          },
        },
      };
    case SET_MANUAL_WIRE_OFFSET_X:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            offset: {
              ...state[action.payload.arch][action.payload.teethLabel].offset,
              x: action.payload.offsetX,
            },
          },
        },
      };
    case SET_MANUAL_WIRE_OFFSET_Y:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            offset: {
              ...state[action.payload.arch][action.payload.teethLabel].offset,
              y: action.payload.offsetY,
            },
          },
        },
      };
    case SET_MANUAL_WIRE_LENGTH:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            length: {
              ...state[action.payload.arch][action.payload.teethLabel].length,
              [state.wire]: action.payload.length,
            },
          },
        },
      };
    case SET_MANUAL_WIRE_CASE_DATA:
      const { caseData, arch, filteredIndexes } = action.payload;
      const newState = prepareStateWithCaseData(state, caseData, arch, filteredIndexes);
      return newState;
    case SET_MANUAL_WIRE_DEFAULT_CASE_DATA:
      return {
        ...state,
        defaultCaseData: action.payload.caseData,
      };
    case SET_MANUAL_WIRE_TEETH_ERROR:
      if (state.arch === '') return state
      const newErrorState = { ...state };
      const teethLabel = action.payload.teethLabel;
      const errorMessages = action.payload.errorMessage;
      newErrorState[state.arch][teethLabel].error = true;
      newErrorState.errorMessage = (newErrorState.errorMessage || []).concat(errorMessages)
      newErrorState.error = true;
      return newErrorState;
    case REMOVE_MANUAL_WIRE_TEETH_ERROR:
      if (state.arch === '') return state
      const newStateToRemove = { ...state };
      const teethLabelToRemove = action.payload.teethLabel;
      const errorMessagesToRemove = action.payload.errorMessage;
      newStateToRemove.errorMessage = newStateToRemove.errorMessage.filter((message) => !errorMessagesToRemove.includes(message));
      newStateToRemove[state.arch][teethLabelToRemove].error = newStateToRemove.errorMessage.some((message) => message.includes(teethLabelToRemove));
      newStateToRemove.error = newStateToRemove.errorMessage?.length > 0;
      return newStateToRemove;
    case SET_MANUAL_WIRE_ERROR:
      const newManualErrorState = { ...state };
      newManualErrorState.errorMessage = action.payload.errorMessage;
      newManualErrorState.error = action.payload.error;
      if (action.payload.error) {
        newManualErrorState.errorMessage.forEach((errorMessage) => {
          const words = errorMessage.split(' ');
          const toothPair = words.find(word => word.includes('-') && word.length === 7 && ['U', 'L'].includes(word[0]));
          if (toothPair) {
            const [toothWithError, headerToothWithError] = toothPair.split('-');
            if (TEETHS[state.arch].includes(toothWithError))
              newManualErrorState[state.arch][toothWithError].error = true;
            if (TEETHS[state.arch].includes(headerToothWithError))
              newManualErrorState[state.arch][headerToothWithError].headerError = true;
          }
        });
      } else {
        for (const tooth in newManualErrorState[state.arch]) {
          newManualErrorState[state.arch][tooth].error = false;
          newManualErrorState[state.arch][tooth].headerError = false;
        }
      }
      return newManualErrorState;
    case ADD_MANUAL_WIRE_ERROR:
      return {
        ...state,
        error: true,
        errorMessage: (state.errorMessage || []).concat(action.payload.errorMessage),
      };
    case SET_MANUAL_WIRE_REFRESH:
      return {
        ...state,
        refresh: action.payload.refresh,
      };
    case SET_MANUAL_WIRE_TEETH_DISABLED:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          [action.payload.teethLabel]: {
            ...state[action.payload.arch][action.payload.teethLabel],
            disabled: action.payload.disabled,
          },
        },
      };
    case SET_MANUAL_WIRE_TEETH_RANGE:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          range: [...action.payload.range],
        },
      };
    case SET_MANUAL_WIRE_TEETH_MISSING:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          missing: [...action.payload.missingTeeths],
        },
      };
    case SET_MANUAL_WIRE_ARCH_OPTIONS:
      return {
        ...state,
        archOptions: action.payload.archOptions,
      };
    case SET_MANUAL_WIRE_SMARTWIRE_OPTIONS:
      return {
        ...state,
        [action.payload.arch]: {
          ...state[action.payload.arch],
          smartwireOptions: action.payload.smartwireOptions,
        },
      };
    case   SET_MANUAL_WIRE_CUSTOM_DATA:
      return {
        ...state,
        customData: action.payload.customData
      };
    default:
      return state;
  }
}

export const getHasErrors = (state) => {
  if (state.manualWireReducer.arch !== '') {
    const currentTeeths = state.manualWireReducer[state.manualWireReducer.arch];
    return Object.values(currentTeeths).some((teeth) => teeth.error);
  }
  return false;
};
