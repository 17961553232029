// Css
import '../settings.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';

// Internal
import { handleHttpRequestError } from '../../../common/error';
import { capitalizeFirstLetters } from '../../../common/helpers';
import SessionExpire from '../../../common/session_expire';
import { PILOT } from '../../../common/constants/programs';
import ProgramList from './program_list';
import ProgramDetails, { SETTINGS_INFO } from './program_details';
import { performSearch, displayNoResults } from '../search';
import Modal from '../../../components/modal/modal';

const DEFAULT_STATE = {
  refresh: false,
  programs: [],
  program_create: {},
  programs_update: [],
  programs_view: [],
  selected_program_id: null,
  edit_mode: false,
  create_mode: false,
  show_enable_disable_modal: false,
  show_upgrade_modal: false,
  search: '',
  search_display: '',
  invalid_settings: [],
  level_to_upgrade: '',
  should_upgrade_providers_to_pilot: true,
  in_progress: false,
};
const DEFAULT_PROGRAM = {
  name: '',
  description: '',
  program_type: '',
  tags: [],
  tags_on_case_creation: [],
  auto_enroll_doctor: true,
  levels: '{"levels": ["beta"], "current": "beta"}',
};

/**
 * Displays the settings for programs
 * @alias ProgramSettings
 * @component
 * @category BPP
 * @subcategory Program Settings
 */
class ProgramSettings extends Component {
  constructor(props) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  /**
   * Resets External Target Dates View
   */
  resetView = (program_id = null) => {
    this.setState({ in_progress: true });
    const that = this;
    Axios.get('/apiv3/programsettings?tags=true&order_by=name')
      .then(function (res) {
        let programs = that.getFormattedPrograms(res.data.programs);
        const state = _.cloneDeep(DEFAULT_STATE);
        state.programs = programs;
        state.programs_update = _.cloneDeep(programs);
        state.programs_view = programs;
        state.in_progress = false;
        if (!program_id) program_id = programs.length ? programs[0].program_id : null;
        state.selected_program_id = program_id;
        that.setState(state);
      })
      .catch(function (err) {
        that._handleHttpRequestError(err, that);
      });
  };

  /**
   * Format tags to be used in program multiselect field (tags)
   * @function
   * @param programs {array} - List of programs
   * @return programs {array} - List of programs with formatted tags
   */
  getFormattedPrograms = (programs) => {
    return programs.map((program) => {
      const temp_program = { ...program };
      temp_program.tags = program.tags
        .filter((tag) => tag.tag__tag_on_case_creation === false)
        .map((tag) => {
          return { value: tag.tag__tag_name, label: tag.tag__tag_name };
        });
      temp_program.tags_on_case_creation = program.tags
        .filter((tag) => tag.tag__tag_on_case_creation === true)
        .map((tag) => {
          return { value: tag.tag__tag_name, label: tag.tag__tag_name };
        });
      temp_program.levels = program?.levels ? JSON.parse(program.levels) : {};
      return temp_program;
    });
  };

  componentDidMount = () => {
    this.resetView();
  };

  /**
   * Handles when user clicks on a program and set the selected Program
   * @function
   * @param program_id {number} - Program id
   */
  onProgramClick = (program_id) => {
    this.setState({
      selected_program_id: program_id,
    });
  };

  /**
   * Toggles edit mode for setting
   * @function
   * @param edit_mode {boolean} - True or false
   */
  toggleEditMode = (edit_mode) => {
    this.setState({
      edit_mode: edit_mode,
    });
  };

  /**
   * Cancels edit mode
   * @function
   */
  onEditCancel = () => {
    this.toggleEditMode(false);
    this.setState({
      programs_update: _.cloneDeep(this.state.programs),
      invalid_settings: [],
    });
  };

  /**
   * returns the program to be updated
   * @return {object} - Updated programs
   */
  formatTagForRequest = (program) => {
    if (program.tags) {
      program.tags = program.tags.map((tag) => tag.value);
    }
    if (program.tags_on_case_creation) {
      program.tags_on_case_creation = program.tags_on_case_creation.map((tag) => tag.value);
    }
    return program;
  };

  /**
   * returns the program to be updated
   * @return {object} - Updated programs
   */
  getProgramToUpdate = () => {
    const updatedProgram = this.state.programs_update.find((program) => program.program_id === this.state.selected_program_id);
    return updatedProgram || null;
  };

  /**
   * Set the initial program state the program that will be updated
   * @function
   * @param program {object} - program to set its inital state
   */
  setInitialProgramState = (program) => {
    program.initial_program_state = { ...this.state.programs_view.find((initial_program) => initial_program.program_id === program.program_id) };
    program.initial_program_state.levels = JSON.stringify(program.initial_program_state.levels);
    return program;
  };

  /**
   * Update program and restore the state to display the settings
   * @function
   */
  onEditSave = () => {
    let program_to_update = _.cloneDeep(this.getProgramToUpdate());
    if (this.determineSettingsInputError(program_to_update)) return;

    this.setState({ in_progress: true, invalid_settings: [] });
    program_to_update = _.cloneDeep(this.formatTagForRequest(program_to_update));
    program_to_update.levels = JSON.stringify(program_to_update.levels);
    this.setInitialProgramState(program_to_update);
    const that = this;
    const url = `/apiv3/programsettings/${program_to_update.program_id}`;
    Axios.put(url, program_to_update)
      .then(function (res) {
        that.resetView(program_to_update.program_id);
      })
      .catch(function (err) {
        const msg = 'Error while updating program.';
        that._handleHttpRequestError(err, that, msg);
      });
  };

  /**
   * disable a program setting
   * @function
   */
  onEnableDisableProgram = () => {
    this.setState({ in_progress: true });
    let program_to_update = this.getProgramToUpdate();
    program_to_update = _.cloneDeep(this.formatTagForRequest(program_to_update));
    program_to_update.levels = JSON.stringify(program_to_update.levels);
    program_to_update.current_ind = !program_to_update.current_ind;
    this.setInitialProgramState(program_to_update);
    const that = this;
    const url = `/apiv3/programsettings/${program_to_update.program_id}`;
    Axios.put(url, program_to_update)
      .then(function (res) {
        that.resetView(program_to_update.program_id);
      })
      .catch(function (err) {
        const msg = 'Error while updating program.';
        that._handleHttpRequestError(err, that, msg);
      });
  };

  /**
   * Upgrade program setting
   * @function
   */
  onUpgradeProgram = () => {
    this.setState({ in_progress: true });
    const program = this.getProgramToUpdate();
    let program_to_update = _.cloneDeep(this.formatTagForRequest(program));
    program_to_update.levels.current = this.state.level_to_upgrade;
    if (!program_to_update.levels.levels.includes(this.state.level_to_upgrade)) {
      program_to_update.levels.levels.push(this.state.level_to_upgrade);
    }
    if (this.state.level_to_upgrade === PILOT) {
      program_to_update.should_upgrade_providers_to_pilot = this.state.should_upgrade_providers_to_pilot;
    }
    program_to_update.levels = JSON.stringify(program_to_update.levels);
    this.setInitialProgramState(program_to_update);
    const that = this;
    const url = `/apiv3/programsettings/${program_to_update.program_id}`;
    Axios.put(url, program_to_update)
      .then(function (res) {
        that.resetView(program_to_update.program_id);
      })
      .catch(function (err) {
        const msg = 'Error while updgrading program.';
        that._handleHttpRequestError(err, that, msg);
        that.setState({ in_progress: false });
      });
  };

  /**
   * Create a new program from state.program_create and restore the state to display the settings
   * @function
   */
  _handleHttpRequestError = (err, that, error_message = '') => {
    console.error('Error: ', err);
    handleHttpRequestError(err, that);
    const fields = ['name', 'tags', 'tags_on_case_creation'];
    let invalid_settings = [];
    for (const field of fields) {
      const fieldValue = err?.response?.data?.[field] ?? null;
      if (fieldValue !== null) {
        invalid_settings.push({ [field]: fieldValue });
      }
    }
    if (invalid_settings.length === 0) {
      if (err && err.response && err.response.status !== 409) {
        that.setState({
          in_progress: false,
          error_message: error_message,
        });
        setTimeout(() => {
          that.setState({ error_message: '' });
        }, 3000);
      }
    } else {
      that.setState({
        invalid_settings: invalid_settings,
        in_progress: false,
      });
    }
  };

  /**
   * Create a new program from state.program_create and restore the state to display the settings
   * @function
   */
  onCreateProgram = () => {
    this.setState({ invalid_settings: [] });
    let program = { ...this.state.program_create };
    program = this.formatTagForRequest(program);
    if (this.determineSettingsInputError(program)) return;
    const that = this;
    Axios.post(`/apiv3/programsettings`, program)
      .then(function (res) {
        that.resetView(res.data.program_id);
      })
      .catch(function (err) {
        const msg = 'Error while creating program.';
        that._handleHttpRequestError(err, that, msg);
      });
  };

  /**
   * Function exposed to child to update programs_update state related to the selected_program_id
   * @param fieldKey to be updated in programs_update for the selected_program_id
   * @param fieldValue new value for the fieldKey in programs_update for the selected_program_id
   * @function
   */
  setProgramsUpdateState = (fieldKey, fieldValue) => {
    const programs_update = [...this.state.programs_update];
    const index = programs_update.findIndex((item) => item.program_id === this.state.selected_program_id);
    programs_update[index][fieldKey] = fieldValue;
    this.setState({ programs_update: programs_update });
  };

  /**
   * Function exposed to child to update program_create state related to the selected_program_id
   * @param fieldKey to be updated in program_create
   * @param fieldValue new value for the fieldKey in program_create
   * @function
   */
  setProgramCreateState = (fieldKey, fieldValue) => {
    this.setState((prevState) => ({
      program_create: {
        ...prevState.program_create,
        [fieldKey]: fieldValue,
      },
    }));
  };

  /**
   * Determines if a field is empty
   * @function
   * @param program program to be evaluated
   * @param setting field to settings to be evaluated
   * @return {boolean} - True or false
   */
  isFieldEmpty = (program, setting) => {
    if (setting.type === 'auto_enroll_doctor') {
      if (program[setting.type] === undefined || program[setting.type] == null) return true;
    } else {
      return !program[setting.type];
    }
  };

  /**
   * Determines if there is an error with settings input
   * @function
   * @return {boolean} - True or false
   */
  determineSettingsInputError = (program) => {
    let settings_input_error = false;
    let invalid_settings = [];
    for (const setting of SETTINGS_INFO) {
      if (setting.required && this.isFieldEmpty(program, setting)) {
        invalid_settings.push({ [setting.type]: 'Required field' });
        settings_input_error = true;
      }
    }

    if (settings_input_error) {
      this.setState({ invalid_settings: invalid_settings });
    }
    return settings_input_error;
  };

  /**
   * Displays program search bar
   * @function
   * @return {JSX.Element} - JSX for search bar
   */
  displaySearchBar = () => {
    return (
      <>
        <input
          type="text"
          className="form-control search-bar-dark font-awesome program-search-input"
          placeholder="Search"
          aria-describedby="basic-addon1"
          onChange={this.onChangeSearchProgram}
          onKeyPress={this.onKeyPressSearchProgram}
          value={this.state.search}
          disabled={this.state.edit_mode}
        />
        <button className="btn btn-light-3" onClick={this.searchProgram} disabled={this.state.edit_mode}>
          <i className="fa fa-search" aria-hidden="true" />
        </button>
      </>
    );
  };

  /**
   * Handles event when user presses enter in search bar
   * @function
   * @param event {object} - Event object
   */
  onKeyPressSearchProgram = (event) => {
    if (event.key === 'Enter') {
      this.searchProgram();
    }
  };

  /**
   * Handles event when user searches program in search bar
   * @function
   * @param event {object} - Event object
   */
  onChangeSearchProgram = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  /**
   * Searches for program
   * @function
   */
  searchProgram = () => {
    const results = performSearch(this.state.search, this.state.programs, 'name');
    this.setState({
      programs_view: results,
      search_display: this.state.search,
      selected_program_id: results.length ? results[0].program_id : null,
    });
  };

  /**
   * Show Create Setting Modal
   * @return {JSX.Element} - JSX for program Details
   */
  getProgramDetails = () => {
    return (
      <>
        <ProgramDetails
          {...this.state}
          toggleEditMode={this.toggleEditMode}
          onEditCancel={this.onEditCancel}
          onEditSave={this.onEditSave}
          setProgramsUpdateState={this.setProgramsUpdateState}
          setProgramCreateState={this.setProgramCreateState}
          showEnableDisableModal={this.showEnableDisableModal}
          showUpgradeModal={(level) => this.showUpgradeModal(level)}
          has_program_edit_permission={this.props.has_program_edit_permission}
        />
        {this.state.error_message ? <div className="error-message-container">{this.state.error_message}</div> : null}
      </>
    );
  };

  /**
   * Hide Create Setting Modal
   */
  onHideCreateModal = () => {
    this.setState({
      create_mode: false,
      edit_mode: false,
      invalid_settings: [],
    });
  };

  /**
   * Show Create Setting Modal
   */
  showCreateModal = () => {
    this.setState({
      create_mode: true,
      edit_mode: true,
      program_create: DEFAULT_PROGRAM,
    });
  };

  /**
   * Show disable Setting Modal
   */
  showEnableDisableModal = () => {
    this.setState({
      show_enable_disable_modal: true,
    });
  };

  /**
   * Hide disable Setting Modal
   */
  onHideEnableDisableModal = () => {
    this.setState({
      show_enable_disable_modal: false,
    });
  };

  /**
   * Show Upgrade Setting Modal
   */
  showUpgradeModal = (level) => {
    this.setState({
      show_upgrade_modal: true,
      level_to_upgrade: level,
    });
  };

  /**
   * Hide Upgrade Setting Modal
   */
  onHideUpgradeModal = () => {
    this.setState({
      should_upgrade_providers_to_pilot: 'true',
      show_upgrade_modal: false,
      auto_enroll_doctor: true,
    });
  };

  /**
   * Get question for modal Upgrade from beta to pilot
   */
  handleOnChangeUpgradeQuestion = (event) => {
    this.setState({ should_upgrade_providers_to_pilot: event.target.value === 'true' });
  };

  /**
   * Get question for modal Upgrade from beta to pilot
   * @return {JSX.Element} - JSX for upgrade Question from beta to pilot
   */
  getUpgradeQuestion = () => {
    return (
      <div className="program-details-create">
        <label className="create">Auto-enrollment:</label>
        <div className="option-container">
          <label>
            <input
              type="radio"
              name="auto-enroll"
              value={'true'}
              checked={this.state.should_upgrade_providers_to_pilot}
              onChange={this.handleOnChangeUpgradeQuestion}
            />
            Move existing beta providers to pilot
          </label>
          <label>
            <input
              type="radio"
              name="do-not-auto-enroll"
              value={'false'}
              checked={!this.state.should_upgrade_providers_to_pilot}
              onChange={this.handleOnChangeUpgradeQuestion}
            />
            Do not move existing beta providers to pilot
          </label>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="main-content main-content-solo target-date">
        <div className="dark-options">
          <div className="page-heading">Program Settings</div>
          <div className="action-bar">
            <div className="search-bar">{this.displaySearchBar()}</div>
            {this.props.has_program_create_permission && (
              <button className="btn btn-light create-new" onClick={this.showCreateModal} disabled={this.state.edit_mode}>
                Create New
              </button>
            )}
          </div>
        </div>
        <div className="page-tab-content">
          <div className="page-tab-content-section">
            {this.state.programs_view.length === 0 ? (
              !this.state?.in_progress && displayNoResults(this.state.search)
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <ProgramList {...this.state} onProgramClick={this.onProgramClick} />
                </div>
                {!this.state.create_mode && <div className="col-lg-6">{this.getProgramDetails()}</div>}
              </div>
            )}
          </div>
        </div>
        {this.state.create_mode && (
          <Modal
            modal_body_class="modal-body-create"
            preset="decision-prevent-confirm-disable"
            header_text="Create New Program"
            onConfirmButtonClick={this.onCreateProgram}
            onCloseButtonClick={this.onHideCreateModal}
            modal_body={this.getProgramDetails()}
            confirm_btn_text="Create"
            close_btn_text="Cancel"
            theme="bpp"
            in_progress={this.state.in_progress}
            in_progress_text="Creating program..."
          />
        )}
        {this.state.show_enable_disable_modal && (
          <Modal
            theme="bpp"
            preset="decision-prevent-confirm-disable"
            header_text={`${this.getProgramToUpdate().current_ind ? 'Disable' : 'Enable'} Program`}
            confirm_btn_text={this.getProgramToUpdate().current_ind ? 'Disable' : 'Enable'}
            close_btn_text="Cancel"
            message_text={
              <div>
                <p className="disable-program-question">
                  {this.getProgramToUpdate().current_ind
                    ? 'Users opted into this program will be automatically opted out upon disabling.'
                    : 'Users originally opted into this program will be automatically opted back in upon enabling.'}
                </p>
                <p className="disable-program-question">
                  Are you sure you would like to {this.getProgramToUpdate().current_ind ? 'disable' : 'enable'} this program?
                </p>
              </div>
            }
            onConfirmButtonClick={this.onEnableDisableProgram}
            onCloseButtonClick={this.onHideEnableDisableModal}
            in_progress={this.state.in_progress}
            in_progress_text={`${this.getProgramToUpdate().current_ind ? 'Disabling' : 'Enabling'} program...`}
          />
        )}
        {this.state.show_upgrade_modal && (
          <Modal
            theme="bpp"
            preset="decision-prevent-confirm-disable"
            header_text={`Upgrade to ${capitalizeFirstLetters(this.state.level_to_upgrade)}`}
            confirm_btn_text="Upgrade"
            close_btn_text="Cancel"
            message_text={
              this.state.level_to_upgrade.includes(PILOT) ? '' : `Are you sure you would like to upgrade this program to ${this.state.level_to_upgrade}?`
            }
            modal_body={this.state.level_to_upgrade.includes(PILOT) ? this.getUpgradeQuestion() : ''}
            onConfirmButtonClick={this.onUpgradeProgram}
            onCloseButtonClick={this.onHideUpgradeModal}
            in_progress={this.state.in_progress}
            in_progress_text="Upgraging program..."
          />
        )}
        {this.state.refresh && <SessionExpire />}
      </div>
    );
  }
}

export default ProgramSettings;
