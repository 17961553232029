import './cad_process.scss';

// External Libs
import React, { Component } from 'react';
import Axios from 'axios';
// Internal Libs
import CadLabelSelection from './cad_label_selection';
import CadLabelGeneration from './cad_label_generation';
import CadLabelOverride from './cad_label_override';
import CadLabelSuccess from './cad_label_success';
import CadLabelFailure from './cad_label_failure';
import CadLabelProcessing from './cad_label_processing';
import Modal from '../../../components/modal/modal';
import { handleHttpRequestError } from '../../../common/error';
import { removeIreqIdInitialNumber } from '../../../common/functions';
import { onReloadPage } from '../../../common/helpers';

// nomenclature
import { getLookup } from '../../../common/nomenclature';

/**
 * Preparation Process component
 */
class CadProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      showCompleteModal: false,
      label_generation_status: '',
      label_generation_status_comment: '',
      label_selection: [],
      label_options: '',
      labels: [],
      mark_as_success: false,
      item_request: [],
      item_part_label_case: {
        ...getLookup(this.props.item_request.ireq_id, true), // get item part label lookup dictionary
        '6120-02': 'Molar Brackets',
        '6120-04': 'Standard Brackets',
        '6200-05': 'Lower Anterior Brackets',
      },
      item_part_label_de: {
        ...getLookup(this.props.item_request.ireq_id, true), // get item part label lookup dictionary
        '6120-02': 'Molar Brackets',
        '6120-04': 'Standard Brackets',
        '6200-05': 'Lower Anterior Brackets',
      },
      item_part_selection: {
        '6500-10': 'upper_smartwire_014_NiTi',
        '6502-03': 'upper_smartwire_014_NiTi',
        '6500-11': 'lower_smartwire_014_NiTi',
        '6502-04': 'lower_smartwire_014_NiTi',
        '6500-12': 'upper_smartwire_016_NiTi',
        '6501-01': 'upper_smartwire_016_NiTi',
        '6500-13': 'lower_smartwire_016_NiTi',
        '6501-02': 'lower_smartwire_016_NiTi',
        '6500-20': 'lower_smartwire_016_straight_NiTi',
        '6501-07': 'lower_smartwire_016_straight_NiTi',
        '6400-05': 'upper_idb',
        '6400-06': 'lower_idb',
        '6401-02': 'upper_idb_3dp',
        '6401-03': 'lower_idb_3dp',
        '6120-02': 'molar_brackets',
        '6120-04': 'standard_brackets',
        '6200-05': 'lower_anterior_brackets',
        80028: 'upper_smartwire_0',
        80027: 'lower_smartwire_0',
        80002: 'upper_smartwire_1',
        80001: 'lower_smartwire_1',
        80004: 'upper_smartwire_2',
        80003: 'lower_smartwire_2',
        80006: 'upper_smartwire_3',
        80005: 'lower_smartwire_3',
        80008: 'upper_idb_gen_2',
        80007: 'lower_idb_gen_2',
        80031: 'upper_de_smartwire_0',
        80032: 'lower_de_smartwire_0',
        80010: 'upper_de_smartwire_1',
        80009: 'lower_de_smartwire_1',
        80012: 'upper_de_smartwire_2',
        80011: 'lower_de_smartwire_2',
        80014: 'upper_de_smartwire_3',
        80013: 'lower_de_smartwire_3',
        80016: 'upper_de_idb',
        80015: 'lower_de_idb',
        80025: 'upper_single_tooth_idb',
        80026: 'lower_single_tooth_idb',
      },
    };
  }

  componentDidMount() {
    let selections = [];
    let label_options = '';
    let options = '';
    this.props.item_parts_quantity.forEach((ir_part) => {
      if (ir_part.ireq_id.includes('DE') || ir_part.ireq_id.includes('-R')) {
        selections.push({ label: this.state.item_part_label_de[ir_part.part_id], state: this.state.item_part_selection[ir_part.part_id] });
      } else {
        selections.push({ label: this.state.item_part_label_case[ir_part.part_id], state: this.state.item_part_selection[ir_part.part_id] });
      }
      if (!options) {
        options = this.state.item_part_selection[ir_part.part_id];
      } else {
        options = options + ',' + this.state.item_part_selection[ir_part.part_id];
      }
    });

    label_options = options;
    let label_generation_status = this.state.label_generation_status;
    let label_generation_status_comment = this.state.label_generation_status_comment;
    if (this.props.item_request.label_generation && this.props.item_request.label_generation.length > 0) {
      label_generation_status = this.props.item_request.label_generation[0].status;
      label_generation_status_comment = this.props.item_request.label_generation[0].status_comment;
    }

    this.setState({
      item_request: this.props.item_request,
      label_selection: selections,
      label_options: label_options,
      label_generation_status: label_generation_status,
      label_generation_status_comment: label_generation_status_comment,
    });
  }

  toggleCompleteModal = () => {
    this.setState({ showCompleteModal: !this.state.showCompleteModal });
  };

  completeModal = () => {
    const header_text = `Appliance Design Process - ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want mark the Appliance Design process as complete?"
        confirm_btn_text="Proceed"
        onConfirmButtonClick={this.startLabelGenerationProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelCompleteModal}
      />
    );
  };

  onProceedClick = (event) => {
    this.toggleCompleteModal();
  };

  onCancelCompleteModal = (event) => {
    this.toggleCompleteModal();
  };

  createLabelSelection = (item_request) => {
    let selection = [];
    if (item_request) {
    }
    return selection;
  };

  overrideLabelGeneration = (event) => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/override_process`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.restartLabelGeneration(false);
          that.props.reload_information();
        }
      })
      .catch(function (err) {
        that.props.restartLabelGeneration(false);
        handleHttpRequestError(err, that);
      });

    that.setState({
      label_generation_status: 'Label Override Process',
    });
  };

  overrideModal = () => {
    const header_text = `Override Process - ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to override process?"
        confirm_btn_text="Override"
        onConfirmButtonClick={this.overrideLabelGeneration}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelOverrideModal}
      />
    );
  };

  onCancelOverrideModal = (event) => {
    this.toggleOverrideModal();
  };

  startLabelGenerationProcess = () => {
    let that = this;
    this.setState({
      label_generation_status: 'Label Generation In Progress',
      mark_as_success: false,
    });
    let form_data = new FormData();
    form_data.append('label_options', this.state.label_options);
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/start_process`, form_data)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.props.reload_information(false, function () {
            that.setState({
              label_generation_status: res.data.status,
              label_generation_status_comment: res.data.status_comment,
            });
            that.props.restartLabelGeneration(false);
          });
        }
      })
      .catch(function (err) {
        that.props.restartLabelGeneration(false);
        that.props.reload_information();
        handleHttpRequestError(err, that);
      });
  };
  restartLabelGeneration = (event) => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/retry_process`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    this.props.restartLabelGeneration(true);

    that.setState({
      label_generation_status: 'Label Generation Retry Processing',
    });
  };
  toggleOverrideModal = () => {
    this.setState({ showOverrideModal: !this.state.showOverrideModal });
  };
  markGenerationAsSuccess = (event) => {
    this.setState({
      mark_as_success: true,
    });
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/force_success`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
            label_generation_status_comment: res.data.status_comment,
          });
          that.props.restartLabelGeneration(false);
        }
      })
      .catch(function (err) {
        that.props.restartLabelGeneration(false);
        handleHttpRequestError(err, that);
      });

    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };

  markGenerationAsFailed = (event) => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/force_failure`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
            label_generation_status_comment: res.data.status_comment,
          });
          that.props.restartLabelGeneration(false);
        }
      })
      .catch(function (err) {
        that.props.restartLabelGeneration(false);
        handleHttpRequestError(err, that);
      });

    that.setState({
      label_generation_status: 'Label Generation Failed',
      label_generation_status_comment: 'Loading Comment...',
    });
  };

  removePackageFile = (event) => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/remove_package_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };
  removeSmartwireFile = (event) => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/remove_smartwire_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };
  removeIdbtrayFile = (event) => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/remove_idbtray_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };
  /**
   * Removes single sheet label
   * @function
   */
  removeSingleSheetFile = () => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/remove_singlesheet_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };
  onUpload = (data) => {
    const that = this;
    const actions = {
      ireq_labels: 'upload_inner_package',
      ir_smartwire_label: 'upload_smartwire_label',
      ir_idb_label: 'upload_idb_label',
      ir_singlesheet_label: 'upload_singlesheet_label',
    };
    if (data && data[0] && data[0].folder in actions) {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));
      Axios.post(`/apiV2/cadaction/${this.props.item_request.ireq_id}/generate_label_ir/${actions[data[0].folder]}`, form_data)
        .then(function (res) {
          if (res && res.data && res.data.status) {
            this.props.reloadInformation();

            that.setState({
              label_generation_status: res.data.status,
            });
            // that.props.reload_information(false);
            // this.props.item_request.files = data;
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          that.props.reload_information(false);
        });
    }
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };
  renderLabelGenerationStep = () => {
    const status = this.state.label_generation_status;
    switch (status) {
      case '':
      case 'Label Generation Reprocess':
        return (
          <CadLabelSelection
            case_id={this.props.case_id}
            item_request={this.state.item_request}
            selections={this.state.label_selection}
            generateLabel={this.startLabelGenerationProcess}
            overrideLabelGeneration={this.overrideLabelGeneration}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
          />
        );
        // eslint-disable-next-line
        break;
      case 'Label Generation Process':
        return (
          <CadLabelSelection
            case_id={this.props.case_id}
            item_request={this.state.item_request}
            selections={this.state.label_selection}
            generateLabel={this.startLabelGenerationProcess}
            overrideLabelGeneration={this.overrideLabelGeneration}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
          />
        );
        // eslint-disable-next-line
        break;
      case 'Label Generation In Progress':
        return (
          <CadLabelGeneration
            case_id={this.props.case_id}
            item_request={this.state.item_request}
            selections={this.state.label_selection}
            labels={this.state.labels}
            markGenerationAsSuccess={this.markGenerationAsSuccess}
            markGenerationAsFailed={this.markGenerationAsFailed}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
          />
        );
        // eslint-disable-next-line
        break;
      case 'Label Override Process':
        return (
          <CadLabelOverride
            case_id={this.props.case_id}
            item_request={this.state.item_request}
            selections={this.state.label_selection}
            labels={this.state.labels}
            restartLabelGeneration={this.restartLabelGeneration}
            returnToSelection={true}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
            ireq_status={this.props.item_request.ireq_status}
            shipping_approved_ind={this.props.item_request.shipping_approved_ind}
          />
        );
        // eslint-disable-next-line
        break;

      case 'Label Generation Failed':
        return (
          <CadLabelFailure
            files={this.props.item_request.files}
            status_comment={this.state.label_generation_status_comment}
            item_request={this.state.item_request}
            restartLabelGeneration={this.restartLabelGeneration}
            ref_no={this.props.ref_no}
            onUpload={this.onUpload}
            update_wo={this.props.update_wo}
            selections={this.state.label_selection}
            gen_2={this.props.gen_2}
            patient_info={this.props.patient_info}
          />
        );
        // eslint-disable-next-line
        break;

      case 'Label Generation Success':
        return (
          <CadLabelSuccess
            files={this.props.item_request.files}
            item_request={this.props.item_request}
            restartLabelGeneration={this.restartLabelGeneration}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
            mark_as_success={this.state.mark_as_success}
            removePackageFile={this.removePackageFile}
            removeSmartwireFile={this.removeSmartwireFile}
            removeIdbtrayFile={this.removeIdbtrayFile}
            removeSingleSheetFile={this.removeSingleSheetFile}
            onUpload={this.onUpload}
            reloadInformation={this.props.reload_information}
            ireq_status={this.props.item_request.ireq_status}
            selections={this.state.label_selection}
            gen_2={this.props.gen_2}
            patient_info={this.props.patient_info}
            item_parts_quantity={this.props.item_parts_quantity}
          />
        );
        // eslint-disable-next-line
        break;

      case 'Label Manual Process':
        return (
          <CadLabelSuccess
            files={this.props.item_request.files}
            item_request={this.props.item_request}
            restartLabelGeneration={this.restartLabelGeneration}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
            mark_as_success={this.state.mark_as_success}
            removePackageFile={this.removePackageFile}
            removeSmartwireFile={this.removeSmartwireFile}
            removeIdbtrayFile={this.removeIdbtrayFile}
            removeSingleSheetFile={this.removeSingleSheetFile}
            onUpload={this.onUpload}
            reloadInformation={this.props.reload_information}
            selections={this.state.label_selection}
            gen_2={this.props.gen_2}
            patient_info={this.props.patient_info}
            item_parts_quantity={this.props.item_parts_quantity}
          />
        );
        // eslint-disable-next-line
        break;
      case 'Label Generation Retry Processing':
        return <CadLabelProcessing message={this.props.message} />;
      default:
        return (
          <CadLabelSelection
            case_id={this.props.case_id}
            item_request={this.state.item_request}
            selections={this.state.label_selection}
            generateLabel={this.startLabelGenerationProcess}
            overrideLabelGeneration={this.overrideLabelGeneration}
            ref_no={this.props.ref_no}
            update_wo={this.props.update_wo}
          />
        );
        // eslint-disable-next-line
        break;
    }
  };

  render() {
    return (
      <div className={'cad-process'}>
        {this.renderLabelGenerationStep()}
        {this.state.showCompleteModal ? this.completeModal() : null}
        {this.state.showOverrideModal ? this.overrideModal() : null}
        {this.state.refresh ? (
          <Modal
            theme="bpp"
            preset="action"
            x_btn={false}
            header_text="Session Expired"
            message_text="Sorry, your session has expired. Please refresh."
            confirm_btn_text="Refresh"
            onConfirmButtonClick={onReloadPage}
          />
        ) : null}
      </div>
    );
  }
}

export default CadProcess;
