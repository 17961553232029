import React from 'react';

/**
 * The sidebar area that displays the patient name vertically
 * @component
 * @alias SetupViewerPatientInfoHeadingVertical
 */
export default function PatientInfoHeadingVertical(props) {
  return (
    <div className={props.collapse ? 'fs-exclude patient-info-heading--vertical fade-in' : 'fs-exclude patient-info-heading--vertical invisible'}>
      {props.content}
    </div>
  );
}
