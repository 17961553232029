import './in_loader.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InLogo } from './in.svg';

const themes = ['primary', 'secondary'];

/**
 * Displays a InBrace progress bar
 * @component
 */
function InLoader(props) {
  const theme = themes.includes(props.theme) ? props.theme : themes[0];
  const className = `inloader-background inloader-background--${theme}`;
  const upper_bound = 87;
  const total_bound = 100;
  const scaled = props.percentage ? Math.ceil(props.percentage * (upper_bound / total_bound)) : 0;
  const percentage = upper_bound - scaled;

  const containerClassName = props.animate ? 'inloader-container inloader-animate' : 'inloader-container';

  return (
    <div className={containerClassName}>
      <div className="inloader-fill">
        <InLogo />
      </div>
      <div className={className} style={{ maxHeight: `${percentage}%` }}>
        <InLogo />
      </div>
    </div>
  );
}

InLoader.propTypes = {
  percentage: PropTypes.number,
  theme: PropTypes.string,
  animate: PropTypes.bool,
};

export default InLoader;
