import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lightbox from '../../../components/custom_lightbox/Lightbox';
import STLViewer from '../../../components/stl_viewer/STLViewer';
import ImageViewer, { initialPosition } from './image_viewer';
import RecordThumbnail from './record_thumbnail';
import RecordViewerControls from './record_viewer_controls';

const CONTAINER_HEIGHT = 300;

function RecordViewer(props) {
  const {
    records,
    onLoad,
    onClick,
    removeAllowed,
    disabled,
    onRemove,
    subtitle,
    // iTero integration
    onRemoveIteroScansClick,
    onRemoveIteroPhotosClick,
    removeIteroScansText = 'Remove iTero Scans',
    removeIteroPhotosText = 'Remove iTero Photos',
    showRemoveIteroScans = false,
    showRemoveIteroPhotos = false,
    iteroRemoveAllowed = false,
    showHeader = true,
    isLoadingIteroPhotos = false,
    isDeletingIteroPhotos = false,
  } = props;
  const [selected, setSelected] = useState(0);
  const [selectedOld, setSelectedOld] = useState(0);
  const [lightBox, setLightBox] = useState(false);
  const [position, setPosition] = useState(initialPosition);
  const [height, setHeight] = useState(CONTAINER_HEIGHT);
  const ref = useRef(null);
  const containerRef = useRef(null);
  const deletionInProcess = records ? records.filter((record) => record.hideDeleteIcon === true).length > 0 : false;
  const reset = useCallback(() => {
    setPosition(initialPosition);
    if (records) onClick(records[selected].id);
  }, [setPosition, onClick, selected, records]);

  const onResize = useCallback(
    _.debounce(() => {
      const SPACES_2_LEAVE = 120;
      if (containerRef && containerRef.current) {
        const { top } = containerRef.current.getBoundingClientRect();
        const win_height = window.innerHeight;
        const remaining_spaces = win_height - (top + height);
        if (remaining_spaces < SPACES_2_LEAVE) {
          const new_height = height - (SPACES_2_LEAVE - remaining_spaces);
          setHeight(new_height);
        }
      }
    }, 50),
    [containerRef, height]
  );

  const onSelect = useCallback(
    (id) => {
      setSelected(id);
      setSelectedOld(id);
      setPosition(initialPosition);
      ref.current.children[id].scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
      onResize();
    },
    [setSelected, setPosition, onResize]
  );

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip({ container: 'main' });
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    onResize();
    if (records && selected == -1) setSelected(0);
  }, [records, onResize]);

  useEffect(() => {
    if (lightBox === false) {
      setSelected(-1);
      setTimeout(() => {
        setSelected(selectedOld);
      }, 200);
    }
    // eslint-disable-next-line
  }, [lightBox]);

  if ((!records || records.length === 0) && showHeader) {
    return (
      <div className={'record-viewer-container'}>
        <div className="uploaded-records-header no-records">{showHeader && <span className="title">Uploaded Records</span>}</div>
        No Records Uploaded
      </div>
    );
  }

  if (selected >= records.length) {
    setSelected(selected - 1);
  }

  const record = records[selected];
  const record_exists = selected >= 0 && records && records[selected];
  const prev = (selected - 1 + records.length) % records.length;
  const next = (selected + 1) % records.length;
  const onRotate = () => onClick(record.id, 'rotate');
  const onFlipH = () => onClick(record.id, 'flipH');
  const onFlipV = () => onClick(record.id, 'flipV');
  const zoomIn = () => onClick(record.id, 'zoomIn');
  const zoomOut = () => onClick(record.id, 'zoomOut');
  const setScale = (scale) => onClick(record.id, scale);
  const viewerState = record && record.state ? { position, ...record.state } : {};
  const viewerFunc = { setPosition, setScale, zoomIn, zoomOut, onRotate, onFlipH, onFlipV, reset };
  const containerClassName = 'record-viewer-container';
  const containerStyle = record?.viewer ? null : { height: height };
  const tooltip_spacing = record?.tooltip ? 'record-thumbnail-tooltip-container' : '';

  return (
    <div>
      <div className="uploaded-records-header">
        {showHeader && <span className="title">Uploaded Records</span>}
        <div className="selected-itero-patient-info">
          {subtitle ? <span className="subtitle">{subtitle}</span> : null}{' '}
          {showRemoveIteroPhotos && !isDeletingIteroPhotos ? (
            <span className="remove-itero-files" onClick={onRemoveIteroPhotosClick}>
              {removeIteroPhotosText}
            </span>
          ) : null}
          {showRemoveIteroScans && !isLoadingIteroPhotos && !isDeletingIteroPhotos ? (
            <span className="remove-itero-files" onClick={onRemoveIteroScansClick}>
              {removeIteroScansText}
            </span>
          ) : null}
        </div>
      </div>
      <div className={tooltip_spacing}>
        <div className="record-thumbnail-container thumbnail-container" ref={ref}>
          {records.map((record, i) => (
            <RecordThumbnail
              key={record.id}
              tooltip={record.tooltip}
              removeAllowed={removeAllowed}
              iteroRemoveAllowed={iteroRemoveAllowed}
              isLoadingIteroPhotos={isLoadingIteroPhotos}
              isDeletingIteroPhotos={isDeletingIteroPhotos}
              disabled={disabled}
              deletionInProcess={deletionInProcess}
              record={record}
              onLoad={onLoad}
              onRemove={onRemove}
              selected={selected === i}
              onClick={() => onSelect(i)}
            />
          ))}
        </div>
        {record_exists ? (
          <div className={containerClassName} ref={containerRef} style={containerStyle}>
            {!record || record.loading || lightBox ? null : record.viewer ? (
              props.children
            ) : // iTero integration
            record.scan || record.iteroScan ? (
              <STLViewer
                key={record.id}
                model={record.src}
                urls={[record.src, record.alt]}
                width="100%"
                height="100%"
                modelColor="#bbbbbb"
                modelBackColor="#9bc6f2"
                backgroundColor="#e1e2e2"
                rotate={false}
                orbitControls={false}
                trackBallControls={true}
                disabledWindowResize={true}
              />
            ) : (
              <ImageViewer src={record.src} {...viewerState} {...viewerFunc} controlsDisabled={disabled} image={record} />
            )}
            {!record.loading && !lightBox && !deletionInProcess && !disabled && (
              <RecordViewerControls
                onClickPrev={() => onSelect(prev)}
                onClickNext={() => onSelect(next)}
                onOpenLightBox={() => setLightBox(true)}
                hasPrev={selected > 0}
                hasNext={selected < records.length - 1}
                fullscreenOnly={!record.image}
                {...viewerState}
                {...viewerFunc}
              />
            )}
          </div>
        ) : null}
      </div>
      <Lightbox
        images={records}
        isOpen={lightBox}
        onClickPrev={() => onSelect(prev)}
        onClickNext={() => onSelect(next)}
        currentImage={selected}
        onClose={() => setLightBox(false)}
      >
        {props.children}
      </Lightbox>
    </div>
  );
}

export default RecordViewer;
