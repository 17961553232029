function getAllowedFileType(type) {
  switch (type) {
    case 'both':
    case 'progress_records':
      return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle, image/jpeg, image/jpg, image/png, image/bmp,  image/tiff, image/gif';
    case 'model':
    case 'repaired_scans':
      return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle';
    case 'setup':
    case 'adjusted_setup':
    case 'ifu_upload':
    case 'ddm_upload':
      return 'application/zip, application/x-zip-compressed, multipart/x-zip, .zip';
    case 'ip_upload':
      return 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12, .xls, .xlsx, .xlsm';
    case 'additional':
    case 'ws_guide':
      return 'application/pdf';
    case 'ws_dxf':
      return '.dxf, application/dxf, image/vnd.dwg, image/x-dwg';
    case 'navigator':
      return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle, image/jpeg, image/jpg, image/png';
    default:
      return 'image/jpeg, image/jpg, image/png, image/bmp, image/tiff, image/gif';
  }
}

export { getAllowedFileType };
