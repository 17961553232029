import './feedback_rating_form_post_smile_design.scss';

import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ToothRating } from '../rating_form';
import { closeFeedbackRatingFormPostSmileDesign, submitFeedbackRatingFormPostSmileDesign } from '../../redux/actions/bpp/feedback_rating_form';
import {
  getShowFeedbackRatingFormPostSmileDesign,
  getSubmitFeedbackRatingFormPostSmileDesignError,
  getSubmitFeedbackRatingFormPostSmileDesignPending,
  getSubmitFeedbackRatingFormPostSmileDesignSuccess,
} from '../../redux/reducers/bpp/feedback_rating_form';
import { getCaseDetails } from '../../redux/reducers/common/common_case_details';
import { AlertMessage, AlertMessageContainer } from '../setup_viewer/components/alert_message/alert_message';
import { removeEmoji } from '../../common/functions';
import Button from '../setup_viewer/components/extras/button';
import Modal from '../setup_viewer/components/modal/modal';

const commentMaxLength = 5000;

const FeedbackRatingFormPostSmileDesign = ({
  caseDetails,
  close,
  show,
  submit,
  submitError,
  submitPending,
  submitSuccess,
  enableDoNotShowAgain = true,
  autoCloseAfterSubmit = false,
}) => {
  const [user, setUser] = React.useState({});
  const [rating, setRating] = React.useState(0);
  const [comment, setComment] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [doNotShowAgainPending, setDoNotShowAgainPending] = React.useState(false);
  const [doNotShowAgainSuccess, setDoNotShowAgainSuccess] = React.useState(false);
  const [doNotShowAgainError, setDoNotShowAgainError] = React.useState(null);

  const handleCommentChange = (event) => {
    const value = removeEmoji(event.target.value);
    setComment(value);
    if (value.length > commentMaxLength) {
      setErrors((prevErrors) => ({ ...prevErrors, comment: 'Character limit exceeded' }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.comment;
        return newErrors;
      });
    }
  };

  const fieldsAreDisabled = () => {
    return submitPending || submitSuccess;
  };

  const submitIsDisabled = () => {
    let result = rating === 0 || fieldsAreDisabled() || !validateForm();
    if (enableDoNotShowAgain) {
      result = result || doNotShowAgainPending;
    }
    return result;
  };

  const validateForm = () => {
    return Object.keys(errors).length === 0;
  };

  const doNotShowAgainIsDisabled = () => {
    return doNotShowAgainPending || doNotShowAgainSuccess || submitPending;
  };

  const shouldShowFeedback = React.useCallback(() => {
    if (user.role === 'Business') {
      return caseDetails.doctor.show_feedback;
    }
    return user.show_feedback;
  }, [user, caseDetails]);

  const handleDoNotShowAgainClick = () => {
    if (doNotShowAgainIsDisabled()) {
      return;
    }
    setDoNotShowAgainPending(true);
    setDoNotShowAgainError(false);
    let userId = user.account_id;
    if (user.role === 'Business') {
      userId = caseDetails.doctor.id;
    }
    Axios.put(`/apiv3/feedback/${userId}`)
      .then(() => {
        setDoNotShowAgainPending(false);
        setDoNotShowAgainSuccess(true);
        close();
      })
      .catch((error) => {
        setDoNotShowAgainPending(false);
        setDoNotShowAgainError(error);
      });
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const data = {
        type: 'Post Smile Design Approval',
        feedback_category: null,
        comment: comment,
        rating: rating,
        url: window.location.href,
        case_id: caseDetails.case_id,
      };

      let doctorId = user.account_id;
      if (user.role === 'Business') {
        doctorId = caseDetails.doctor.id;
      }

      submit(doctorId, data);
    }
  };

  React.useEffect(() => {
    Axios.get(`/apiV2/account_light`).then(function (res) {
      if (res.data) {
        setUser(res.data);
      }
    });
  }, []);

  React.useEffect(() => {
    if (submitSuccess && autoCloseAfterSubmit) {
      close();
    }
  }, [submitSuccess, close, autoCloseAfterSubmit]);

  return (
    <Modal show={show} className="feedback-rating-post-smile-design-modal" show_close_button={true} onCloseModal={() => close()}>
      <div className="modal-title">{submitSuccess ? 'Feedback Submitted' : 'Rate Your Experience'}</div>
      <div className="rating-form">
        {submitSuccess ? (
          <p className="submitted-message">Your feedback has been successfully submitted.</p>
        ) : (
          <>
            <ToothRating
              label={'Are you satisfied with your CoDesign process?'}
              showToothLabels={true}
              centered={true}
              rating={rating}
              onClick={(i) => setRating(i)}
              disabled={fieldsAreDisabled()}
            />
            <div className="rating-form__comment">
              <h4 className="comment__label">Tell us what can be improved?</h4>
              <textarea
                className={`form-control item-reason-comment text-input ${!!errors.comment ? 'has-error' : ''}`}
                placeholder={'Tell us what can be improved?'}
                value={comment}
                onChange={handleCommentChange}
                disabled={fieldsAreDisabled()}
              />
              <div className={`text-area__count text-area__count--bottom ${!!errors.comment ? 'text-input__count--error' : ''}`}>
                <span>
                  {comment.length}/{commentMaxLength}
                </span>
              </div>
            </div>
          </>
        )}
        <div className="modal-button-group">
          {submitSuccess ? (
            <Button theme="success" onClick={() => close()}>
              Close
            </Button>
          ) : (
            <>
              {enableDoNotShowAgain && shouldShowFeedback() ? (
                <span className={`do-not-show-again ${doNotShowAgainIsDisabled() ? 'disabled' : ''}`} onClick={handleDoNotShowAgainClick}>
                  Do not show again
                </span>
              ) : null}
              <Button theme="success" onClick={handleSubmit} disabled={submitIsDisabled()}>
                Submit
              </Button>
            </>
          )}
        </div>
        <div className="rating-form__alert-container">
          {submitError || doNotShowAgainError ? (
            <AlertMessageContainer theme={'error'} dismiss>
              <AlertMessage>An error has ocurred please try again</AlertMessage>
            </AlertMessageContainer>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    caseDetails: getCaseDetails(state),
    show: getShowFeedbackRatingFormPostSmileDesign(state),
    submitError: getSubmitFeedbackRatingFormPostSmileDesignError(state),
    submitPending: getSubmitFeedbackRatingFormPostSmileDesignPending(state),
    submitSuccess: getSubmitFeedbackRatingFormPostSmileDesignSuccess(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      submit: submitFeedbackRatingFormPostSmileDesign,
      close: closeFeedbackRatingFormPostSmileDesign,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackRatingFormPostSmileDesign);
