/**
 * File:ir_content.js - Component to list content of item request list
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './case_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';

import _ from 'lodash';
import Axios from 'axios';
import PaginationContainer from '../../components/pagination/pagination_container';
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

// Internal Components
import Modal from '../../components/modal/modal';

// Internal Functions
import { setTokenHeader } from '../../common/functions';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from '../../common/route';
import { onChangeSearch, searchErrorMessage, translateFilterStatus } from '../../common/search';
import { tableRowLink } from '../../common/table';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import { new_filter_words } from '../../common/nomenclature';

// Redux Actions
import { fetchCaseFilterPreferences, putCaseFilterPreferences } from '../../redux/actions/case_filter_preferences';
import { initializeCaseFilterPreferencesModal, closeCaseFilterPreferencesModal } from '../../redux/actions/case_filter_preferences_modal';

// Redux Reducers
import { getCaseFilterPreferences, getCaseFilterPreferencesPending } from '../../redux/reducers/case_filter_preferences';
import { getCaseFilterPreferencesModal } from '../../redux/reducers/case_filter_preferences_modal';
import CaseListSidebar from './case_list_sidebar';
import CaseListTopFilter from './case_list_top_filter';
import CaseListFilter from './case_list_filter';
import { fetchCaseFilterCount } from '../../redux/actions/bpp/case_list/case_list_filter';
import { getCaseListFilterCount, getCaseListFilterCountLoading } from '../../redux/reducers/bpp/case_list/case_list_filter';
import CaseListSearch from './case_list_search';
import CaseListTable from './case_list_table';
import { fetchCaseListTable } from '../../redux/actions/bpp/case_list/case_list_table';
import { getCaseListTableData, getCaseListTableLoading } from '../../redux/reducers/bpp/case_list/case_list_table';
import { getCaseListTableHeader } from './case_list_table_header';

/**
 * Displays the BPP case list
 * @component
 * @category BPP
 */
class CaseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      selected_case_type: 'all',
      filter: '',
      filter_key: '',
      dso_list: [],
      cases_sort_method: 'submission_date',
      cases_sort_order_ascending: {
        case_id: true,
        patient_name: true,
        doctor_name: true,
        submission_date: false,
        treatment: true,
        status: true,
        approval_date: false,
        doctor_action_date: true,
        doctor_new_record_date: true,
        doctor_setup_date: true,
        assigned_to: true,
        smile_design_target_date: true,
        post_approval_target_date: true,
        appliance_design_target_date: true,
        target_ship_date: true,
      },
      cases_row_size: 25,
      cases_active_page: 1,
      cases_page_range: 5,
      status_filter: '',
      current_status_filter: '',
      start_date_filter: '',
      end_date_filter: '',
      side_collapse: false,
      submission_date_error: false,
      is_searching: false,
    };

    this.onPatientRightClick = this.onPatientRightClick.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    window.$('[data-toggle="tooltip"]').tooltip();

    this.initStateFromRoute();
    this.fetchDSOList();
    this.fetchCaseFilterPreference();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
    const is_refresh = this.props.location.state?.refresh;
    if (is_refresh) {
      this.fetchDSOList();
      this.initStateFromRoute();
      this.props.history.replace({ state: undefined });
    }
  }

  /**
   * Fetches the filter count
   * @function
   */
  fetchFilterCount() {
    this.props.fetchCaseFilterCount(this.state.selected_case_type);
  }

  /**
   * Fetches the list of dso
   * @function
   */
  fetchDSOList() {
    Axios.get('/apiv3/casestatusdso').then((res) => this.setState({ dso_list: res.data.result }));
  }

  /**
   * Initialize state base on route
   * @function
   */
  initStateFromRoute() {
    const search = getBusinessSearchValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    const is_searching = Boolean(search || status || start_date || end_date);
    const { cases_sort_method, cases_sort_order_ascending } = this.getDefaultOrderBaseonFilter(is_searching ? status : filter);

    this.setState(
      {
        filter: is_searching ? '' : filter,
        search: search,
        status_filter: status,
        current_status_filter: status,
        start_date_filter: start_date,
        end_date_filter: end_date,
        is_searching: is_searching,
        cases_sort_method: cases_sort_method,
        cases_sort_order_ascending: cases_sort_order_ascending,
      },
      this.onChangeFilter
    );
  }

  /**
   * Updates `filter_key` when any filter is changed
   * @function
   */
  onChangeFilter = () => {
    this.setState({ filter_key: this.getFilterKey(), cases_active_page: 1 });
    this.fetchCaseListPage();
  };

  /**
   * Process the filters to get the filter key
   * @function
   * @returns {String} The filter key
   */
  getFilterKey() {
    const { selected_case_type, filter, search, status_filter, start_date_filter, end_date_filter, cases_sort_method, cases_sort_order_ascending } = this.state;

    const filters = [selected_case_type, cases_sort_method, cases_sort_order_ascending[cases_sort_method]];
    if (this.state.is_searching) {
      filters.push(search, status_filter, start_date_filter, end_date_filter);
    } else {
      filters.push(filter);
    }
    return filters.join('-');
  }

  /**
   * Fetch the case list page
   * @function
   * @param {Number} [page=1] - The page to fetch
   */
  fetchCaseListPage(page = 1) {
    const config = {
      key: this.getFilterKey(),
      type: this.state.selected_case_type,
      status: this.state.is_searching ? this.state.status_filter : this.state.filter,
      page: page,
      row_size: this.state.cases_row_size,
      search: this.state.is_searching ? this.state.search : '',
      start_date: this.state.is_searching ? this.state.start_date_filter : '',
      end_date: this.state.is_searching ? this.state.end_date_filter : '',
      values: this.getValuesList(),
      ...this.getSecondarySortMethodAndOrder(),
    };
    this.fetchFilterCount();
    this.props.fetchCaseListTable(config);
  }

  getSecondarySortMethodAndOrder() {
    const { cases_sort_method, cases_sort_order_ascending } = this.state;
    const sort_order = this.getSortValue(cases_sort_order_ascending[cases_sort_method]);

    switch (cases_sort_method) {
      case 'doctor_name':
        return {
          sort_method: [cases_sort_method, 'submission_date'],
          sort_asc: [sort_order, 'desc'],
        };

      case 'status':
        return {
          sort_method: [cases_sort_method, 'ship_date', 'submission_date'],
          sort_asc: [sort_order, sort_order, 'desc'],
        };

      case 'submission_date':
        return {
          sort_method: ['submission_date_raw'],
          sort_asc: [sort_order],
        };

      case 'target_ship_date':
        return this.getTargetShipDateSorting();

      case 'production_wo':
        return {
          sort_method: [cases_sort_method, 'submission_date'],
          sort_asc: [sort_order, 'asc'],
        };

      case 'smile_design_target_date':
        return {
          sort_method: [cases_sort_method, 'submission_date', 'patient_name'],
          sort_asc: [sort_order, 'asc', 'asc'],
        };

      case 'post_approval_target_date':
        return {
          sort_method: [cases_sort_method, 'submission_date', 'patient_name'],
          sort_asc: [sort_order, 'asc', 'asc'],
        };

      case 'appliance_design_target_date':
        return {
          sort_method: [cases_sort_method, 'approval_date', 'patient_name'],
          sort_asc: [sort_order, 'asc', 'asc'],
        };

      default:
        return {
          sort_method: [cases_sort_method],
          sort_asc: [sort_order],
        };
    }
  }

  getTargetShipDateSorting() {
    const { cases_sort_method, cases_sort_order_ascending, filter } = this.state;
    const sort_order = this.getSortValue(cases_sort_order_ascending[cases_sort_method]);
    if (filter === 'late') {
      return {
        sort_method: [cases_sort_method, 'approval_date', 'smile_design_target_date', 'post_approval_target_date', 'submission_date', 'doctor_name'],
        sort_asc: [sort_order, 'desc', 'asc', sort_order, 'asc', 'asc'],
      };
    }
    if (filter.includes('ws_') || filter.includes('wf_')) {
      return {
        sort_method: [cases_sort_method, 'patient_name'],
        sort_asc: [sort_order, 'asc'],
      };
    }
    return {
      sort_method: [cases_sort_method, 'approval_date', 'patient_name'],
      sort_asc: [sort_order, 'asc', 'asc'],
    };
  }

  /**
   * Retrieves the list of values base on the current filter
   * @function
   * @returns {Array} The list of values to fetch
   */
  getValuesList() {
    const values = new Set([
      'case_id',
      'submission_date',
      'doctor_name',
      'patient_name',
      'status',
      'gen_2',
      'epp',
      'ws_redo',
      'expedite_ind',
      'ship_date',
      'late_type',
      'late_date',
      'status_comment',
    ]);
    const header = getCaseListTableHeader(this.state.is_searching ? this.state.status_filter : this.state.filter);
    header.map((item) => values.add(item.category));

    return Array.from(values);
  }

  /**
   * Fetch the case filter preference if needed
   * @function
   */
  fetchCaseFilterPreference() {
    const isCaseFilterPreferenceEmpty = _.isEmpty(this.props.case_filter_preferences);
    if (isCaseFilterPreferenceEmpty) {
      this.props.fetchCaseFilterPreferences();
    }
  }

  /**
   * Handles clicking on case list row
   * @function
   * @param {Object} event - The onClick event object
   */
  onClickCaseListRow = (event) => {
    const pathname = `/business/portal/case/${event.currentTarget.dataset.caseid}`;
    tableRowLink(event, pathname, this.props);
  };

  /**
   * Right click handler opens context menu
   * @function
   */
  onPatientRightClick = (event) => {
    this.setState({
      newTabUrl: `/business/portal/case/${event.currentTarget.dataset.caseid}`,
    });
  };

  /**
   * Handles changing to different page
   * @function
   * @param {Number} new_page - The new page to change to
   */
  onChangePage = (new_page) => {
    const { filter_key } = this.state;
    const { case_list_data } = this.props;
    const isPageLoaded = filter_key in case_list_data && case_list_data[filter_key][new_page];
    if (!isPageLoaded) {
      this.fetchCaseListPage(new_page);
    }
    this.setState({ cases_active_page: new_page });
  };

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  /**
   * Obtains the current list's number of cases
   * @function
   * @returns {Number} The length of the current list
   */
  getCasesLength() {
    const { filter_key } = this.state;
    const { case_list_data } = this.props;
    return filter_key in case_list_data ? case_list_data[filter_key]['total'] : 0;
  }

  /**
   * Obtains the start of current list
   * @function
   * @returns {Number} The start of the current list
   */
  getStartRange() {
    return this.state.cases_active_page * this.state.cases_row_size - this.state.cases_row_size + 1;
  }

  /**
   * Obtains the end of current list
   * @function
   * @returns {Number} The end of the current list
   */
  getEndRange() {
    const cases_length = this.getCasesLength();
    const endRange = this.state.cases_active_page * this.state.cases_row_size;
    return Math.min(cases_length, endRange);
  }

  /**
   * Updates the search on user input
   * @function
   * @param {Object} event - The event Object
   */
  onChangeSearchKeyword = (event) => {
    onChangeSearch(this, event);
  };

  /**
   * Retrieves the search filter params
   * @function
   * @returns {Object} The object that contains the search filters
   */
  getSearchParams = () => {
    const { search, status_filter, start_date_filter, end_date_filter } = this.state;
    const params = {};
    if (search) params['search'] = search;
    if (status_filter) params['status'] = status_filter;
    if (start_date_filter) params['start'] = start_date_filter;
    if (end_date_filter) params['end'] = end_date_filter;
    return params;
  };

  /**
   * Checks if the date filters are invalid
   * @function
   * @returns {Boolean} Whether the date is invalid
   */
  hasInvalidSubmissionDate = () => {
    const { start_date_filter: start, end_date_filter: end } = this.state;
    return start && end && start > end;
  };

  /**
   * Handles clicking on the search button
   * @function
   */
  onClickSearchButton = () => {
    if (this.hasInvalidSubmissionDate()) {
      this.setState({ submission_date_error: true });
    } else {
      const params = this.getSearchParams();
      const paramsString = new URLSearchParams(params).toString();
      const route_base = this.props.history.location.pathname;
      const new_route = `${route_base}?${paramsString}`;
      const { cases_sort_method, cases_sort_order_ascending } = this.getDefaultOrderBaseonFilter(this.state.current_status_filter);
      this.props.history.replace(new_route);
      this.setState(
        {
          filter: '',
          submission_date_error: false,
          status_filter: this.state.current_status_filter,
          cases_sort_method: cases_sort_method,
          cases_sort_order_ascending: cases_sort_order_ascending,
          is_searching: true,
        },
        this.onChangeFilter
      );
    }
  };

  onChangeStatusFilter = (event) => {
    this.setState({ current_status_filter: event.target.value });
  };

  onChanegStartDate = (event) => {
    this.setState({ start_date_filter: event.target.value });
  };

  onChangeEndDate = (event) => {
    this.setState({ end_date_filter: event.target.value });
  };

  /**
   * Handles toggling sidebar collapse
   */
  onSideBarCollapseClick = () => {
    this.setState({ side_collapse: !this.state.side_collapse });
  };

  /**
   * Handles changing the filter
   * @function
   * @param {String} filter - The filter clicked on
   */
  onClickSubMenu = (filter) => {
    const { cases_sort_method, cases_sort_order_ascending } = this.getDefaultOrderBaseonFilter(filter);
    this.setState(
      {
        submission_date_error: false,
        filter: filter,
        search: '',
        status_filter: translateFilterStatus('', filter),
        current_status_filter: translateFilterStatus('', filter),
        start_date_filter: '',
        end_date_filter: '',
        is_searching: false,
        cases_sort_method: cases_sort_method,
        cases_sort_order_ascending: cases_sort_order_ascending,
      },
      this.onChangeFilter
    );
    this.props.history.replace(this.getNewFilterRoute(filter));
  };

  /**
   * Creates a new route base on the filter
   * @function
   * @param {String} filter - The new filter
   * @returns {String} The new route
   */
  getNewFilterRoute = (filter) => {
    const route_base = this.props.history.location.pathname;
    const filter_params = `?filter=${filter}`;
    return `${route_base}${filter_params}`;
  };

  /**
   * Get the default sorting method and ascending base on filter
   * @function
   * @param {String} filter - The filter for default ordering
   * @returns {Object} Object containing the sort method and ascending
   */
  getDefaultOrderBaseonFilter = (filter) => {
    let sort_method = 'submission_date';
    let sort_asc = false;
    switch (filter) {
      case 'segmentation_process':
      case 'checking':
      case 'prepare_new':
      case 'prepare_correction':
      case 'setup_review':
      case 'revise_setup':
      case 'setup_ready_upload':
      case 'setup_conversion':
      case 'setup_conversion_failed':
      case 'setup_ready_for_release':
        sort_method = 'smile_design_target_date';
        sort_asc = true;
        break;

      case 'final_design':
      case 'provider_edit':
      case 'final_design_upload':
      case 'final_design_conversion':
      case 'final_design_conversion_failed':
      case 'final_design_ready_for_release':
        sort_method = 'post_approval_target_date';
        sort_asc = true;
        break;

      case 'cad_process':
      case 'cad_stage_1':
      case 'cad_qc_stage_1':
      case 'cad_stage_2':
      case 'cad_stage_3':
      case 'cad_revision':
      case 'cad_stage_1_revision':
      case 'cad_qc_stage_1_revision':
      case 'cad_stage_2_revision':
      case 'cad_stage_3_revision':
        sort_method = 'appliance_design_target_date';
        sort_asc = true;
        break;

      case 'ws_guide':
      case 'ws_generation':
      case 'ws_review':
      case 'ws_failed':
      case 'ws_wo':
      case 'ws_wire_cutting':
      case 'ws_wire_pickup':
      case 'wf_review':
      case 'ws_labels':
        sort_method = 'target_ship_date';
        sort_asc = true;
        break;

      case 'qc_approval':
        sort_method = 'approval_date';
        sort_asc = false;
        break;

      case 'manufacture':
        sort_method = 'target_ship_date';
        sort_asc = true;
        break;

      case 'doctor_action':
        sort_method = 'doctor_action_date';
        sort_asc = false;
        break;

      case 'new':
        sort_method = 'doctor_new_date';
        sort_asc = false;
        break;

      case 'setup':
        sort_method = 'doctor_setup_date';
        sort_asc = false;
        break;

      case 'pendingexp':
      case 'activeexp':
        sort_method = 'target_ship_date';
        sort_asc = true;
        break;

      case 'late':
        sort_method = 'smile_design_target_date';
        sort_asc = true;
        break;

      default:
    }
    const new_sort_ascending = {
      ...this.state.cases_sort_order_ascending,
      [sort_method]: sort_asc,
    };
    return { cases_sort_method: sort_method, cases_sort_order_ascending: new_sort_ascending };
  };

  /**
   * Handles changing the case type
   * @function
   * @param {String} new_case_type - The new case type to change to
   */
  onChangeCaseType = (new_case_type) => {
    this.setState({ selected_case_type: new_case_type }, this.onChangeFilter);
    this.props.fetchCaseFilterCount(new_case_type);
  };

  /**
   * Returns the list data to display
   * @function
   * @returns {List} The case list table data
   */
  getCaseListTablePageData() {
    const { filter_key, cases_active_page } = this.state;
    const { case_list_data } = this.props;
    if (filter_key in case_list_data) {
      return case_list_data[filter_key][cases_active_page];
    }
    return null;
  }

  /**
   * Determines whether to show particular filter or not
   * @function
   * @param {Object|Array} filter_preference - User's filter preference
   * @param {String} text - The key in preference
   * @returns {Boolean} Whether should show or not
   */
  shouldShowFilter(filter_preference, text) {
    if (filter_preference) {
      if (Array.isArray(filter_preference)) return filter_preference.includes(text);
      else return text in filter_preference;
    }
    return false;
  }

  /**
   * Determines whether a filter has submenu or not
   * @function
   * @param {Object|Array} filter_preference - User's filter preference
   * @param {String} text - The key in preference
   * @returns {Boolean} Whether the filter has submenu
   */
  hasSubmenu(filter_preference, text) {
    if (filter_preference && text in filter_preference) {
      return !_.isEmpty(filter_preference[text]);
    }
    return false;
  }

  /**
   * Gets the count for a particular filter
   * @function
   * @returns {Number} The count for a filter
   */
  getCaseListFilterCount(filter_key) {
    const { selected_case_type } = this.state;
    const { case_filter_count } = this.props;
    return case_filter_count[selected_case_type] ? case_filter_count[selected_case_type][filter_key] : 0;
  }

  /**
   * Creates a single sidebar filter configuration
   * @function
   * @param {Object|Array} filter_preference - User's filter preference
   * @param {String} filter_key - The id of the filter
   * @param {String} text - The display text of the filter
   * @param {Array} [submenu=undefined] - The list of submenu under the filter
   * @returns {Object} A single filter config
   */
  buildSingleSideBarFilter(filter_preference, filter_key, text, submenu = undefined) {
    const { filter } = this.state;
    return {
      filter: filter_key,
      text: new_filter_words(text),
      count: this.getCaseListFilterCount(filter_key),
      active: filter === filter_key,
      submenu: this.hasSubmenu(filter_preference, text) ? submenu : undefined,
      hide: !this.shouldShowFilter(filter_preference, text),
      initialCollapse: submenu && submenu.some((item) => item.active || item.initialCollapse),
      onClick: () => this.onClickSubMenu(filter_key),
    };
  }

  /**
   * Creates the list of filters configurations of Inbrace action
   * @function
   * @returns {Array} The inbrace action menu filters
   */
  bulidInbraceActionSubmenu() {
    const { case_filter_preferences } = this.props;
    const pref = case_filter_preferences['INBRACE Action Required'];
    const cad_pref = pref && pref['CAD'];
    const cad_rev_pref = pref && pref['CAD Revision'];
    const fabrication_pref = pref && pref['Manufacture Case'];
    const post_approval_pref = pref && pref['Post Approval'];

    return [
      this.buildSingleSideBarFilter(pref, 'folder_generation', 'Folder Generation'),
      this.buildSingleSideBarFilter(pref, 'folder_generation_success_failed', 'Folder Success/Failed'),
      this.buildSingleSideBarFilter(pref, 'segmentation_process', 'Ready for Segmentation'),
      this.buildSingleSideBarFilter(pref, 'checking', 'Verify Records'),
      this.buildSingleSideBarFilter(pref, 'prepare_new', 'Create Initial Setup'),
      this.buildSingleSideBarFilter(pref, 'prepare_correction', 'Doctor Revision'),
      this.buildSingleSideBarFilter(pref, 'setup_review', 'Internal Setup Review'),
      this.buildSingleSideBarFilter(pref, 'revise_setup', 'Internal Setup Revision'),
      this.buildSingleSideBarFilter(pref, 'setup_ready_upload', 'Upload Setup'),
      this.buildSingleSideBarFilter(pref, 'setup_conversion', 'Setup in Conversion'),
      this.buildSingleSideBarFilter(pref, 'setup_conversion_failed', 'Conversion Failed'),
      this.buildSingleSideBarFilter(pref, 'setup_ready_for_release', 'Release Setup'),
      this.buildSingleSideBarFilter(pref, 'final_design', 'Post Approval', [
        this.buildSingleSideBarFilter(post_approval_pref, 'provider_edit', 'Provider Edit Review'),
        this.buildSingleSideBarFilter(post_approval_pref, 'final_design_upload', 'Final Design Upload'),
        this.buildSingleSideBarFilter(post_approval_pref, 'final_design_conversion', 'Final Design in Conversion'),
        this.buildSingleSideBarFilter(post_approval_pref, 'final_design_conversion_failed', 'Final Design Conversion Failed'),
        this.buildSingleSideBarFilter(post_approval_pref, 'final_design_ready_for_release', 'Final Design Ready for Release'),
      ]),
      this.buildSingleSideBarFilter(pref, 'cad_process', 'CAD', [
        this.buildSingleSideBarFilter(cad_pref, 'cad_stage_1', 'Stage I'),
        this.buildSingleSideBarFilter(cad_pref, 'cad_qc_stage_1', 'Quality Control Stage I'),
        this.buildSingleSideBarFilter(cad_pref, 'cad_stage_2', 'Stage II'),
        this.buildSingleSideBarFilter(cad_pref, 'cad_stage_3', 'Stage III'),
      ]),
      this.buildSingleSideBarFilter(pref, 'cad_revision', 'CAD Revision', [
        this.buildSingleSideBarFilter(cad_rev_pref, 'cad_stage_1_revision', 'Stage I'),
        this.buildSingleSideBarFilter(cad_rev_pref, 'cad_qc_stage_1_revision', 'Quality Control Stage I'),
        this.buildSingleSideBarFilter(cad_rev_pref, 'cad_stage_2_revision', 'Stage II'),
        this.buildSingleSideBarFilter(cad_rev_pref, 'cad_stage_3_revision', 'Stage III'),
      ]),
      this.buildSingleSideBarFilter(pref, 'qc_approval', 'QC Approval'),
      this.buildSingleSideBarFilter(pref, 'manufacture', 'Manufacture Case', [
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_generation', 'Wire Generation'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_review', 'Wire Selection'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_failed', 'Wire Generation Failed'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_guide', 'Wire Selection Guide'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_wo', 'Custom Wire WO Creation'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_wire_cutting', 'Wire Cutting'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_wire_pickup', 'Wire Awaiting Pickup'),
        this.buildSingleSideBarFilter(fabrication_pref, 'ws_labels', 'Labels'),
        this.buildSingleSideBarFilter(fabrication_pref, 'wf_review', 'Wire Fabrication Review'),
      ]),
    ];
  }

  /**
   * Creates the list of filters configurations
   * @function
   * @returns {Array} The result menu filters
   */
  buildSidebarFilters() {
    const pref = this.props.case_filter_preferences;
    const doctor_action_pref = pref['Doctor Action Required'];
    return [
      this.buildSingleSideBarFilter(pref, 'assigned_me', 'Cases Assigned to Me'),
      this.buildSingleSideBarFilter(pref, 'cases_unassigned', 'Unassigned Cases'),
      this.buildSingleSideBarFilter(pref, 'inbrace_action', 'INBRACE Action Required', this.bulidInbraceActionSubmenu()),
      this.buildSingleSideBarFilter(pref, 'doctor_action', 'Doctor Action Required', [
        this.buildSingleSideBarFilter(doctor_action_pref, 'new', 'Awaiting New Records'),
        this.buildSingleSideBarFilter(doctor_action_pref, 'setup', 'Awaiting Approval'),
        this.buildSingleSideBarFilter(doctor_action_pref, 'clarification', 'Awaiting Clarification'),
      ]),
      this.buildSingleSideBarFilter(pref, 'inbrace_active', 'Active Cases'),
      this.buildSingleSideBarFilter(pref, 'ship', 'Shipped Cases'),
      this.buildSingleSideBarFilter(pref, 'hold', 'Held Cases'),
      this.buildSingleSideBarFilter(pref, 'cancel', 'Cancelled Cases'),
      this.buildSingleSideBarFilter(pref, 'precheck', 'Case Precheck'),
      this.buildSingleSideBarFilter(pref, 'clinical', 'Clinical Review Cases'),
      this.buildSingleSideBarFilter(pref, 'activeexp', 'Active Expedited Cases'),
      this.buildSingleSideBarFilter(pref, 'pendingexp', 'Pending Expedited Request'),
      this.buildSingleSideBarFilter(pref, 'late', 'Late Cases'),
    ];
  }

  render() {
    const tableData = this.getCaseListTablePageData();
    const no_result = !this.props.case_list_table_loading && tableData?.length === 0;
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
            <div>
              <Helmet>
                <title>Case List | InBrace Business Portal</title>
              </Helmet>
              <CaseListSidebar side_collapse={this.state.side_collapse} onClickCollapse={this.onSideBarCollapseClick}>
                <CaseListTopFilter
                  onChangeCaseType={this.onChangeCaseType}
                  selectedFilter={this.state.selected_case_type}
                  dso_list={this.state.dso_list}
                  openFilterPreferenceModal={this.props.initializeCaseFilterPreferencesModal}
                />
                {this.props.case_filter_preferences && !this.props.case_filter_preferences_loading && <CaseListFilter filters={this.buildSidebarFilters()} />}
              </CaseListSidebar>
              <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
                <div className="main-content">
                  <CaseListSearch
                    search={this.state.search}
                    onChangeSearch={this.onChangeSearchKeyword}
                    status_filter={this.state.current_status_filter}
                    onChangeStatusFilter={this.onChangeStatusFilter}
                    start_date={this.state.start_date_filter}
                    onChangeStartDate={this.onChanegStartDate}
                    end_date={this.state.end_date_filter}
                    onChangeEndDate={this.onChangeEndDate}
                    date_error={this.state.submission_date_error}
                    onSearch={this.onClickSearchButton}
                  />
                  {no_result ? (
                    searchErrorMessage(this.state.search, 'cases')
                  ) : (
                    <>
                      <CaseListTable
                        sort_method={this.state.cases_sort_method}
                        sort_ascending={this.state.cases_sort_order_ascending[this.state.cases_sort_method]}
                        onClickSort={this.onClickSort}
                        filter={this.state.is_searching ? this.state.status_filter : this.state.filter}
                        isLoading={this.props.case_list_table_loading}
                        activePage={this.state.cases_active_page}
                        tableData={this.getCaseListTablePageData()}
                        onClickRow={this.onClickCaseListRow}
                        rowSize={this.state.cases_row_size}
                      />
                      <PaginationContainer
                        theme="bpp"
                        type="Cases"
                        activePage={this.state.cases_active_page}
                        itemsCountPerPage={this.state.cases_row_size}
                        totalItemsCount={this.getCasesLength()}
                        pageRangeDisplayed={this.state.cases_page_range}
                        onChange={this.onChangePage}
                        startRange={this.getStartRange()}
                        endRange={this.getEndRange()}
                      />
                    </>
                  )}
                </div>
              </div>
              {this.props.case_filter_preferences_modal ? (
                <Modal
                  preset="case-filter-preferences"
                  header_text="Filter Settings"
                  modal_size_class="modal-xlg modal-lg-pdf"
                  onCloseButtonClick={this.props.closeCaseFilterPreferencesModal}
                  onConfirmButtonClick={this.props.putCaseFilterPreferences}
                  theme="bpp"
                  confirm_btn_text="Apply"
                />
              ) : null}
            </div>
          ) : (
            <Redirect to="/business/portal" />
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }

  onClickSort = (category) => {
    const { cases_sort_order_ascending: sort_order, cases_sort_method: sort_method } = this.state;
    const new_sort_method = category;
    const is_same_sort_method = sort_method === new_sort_method;

    const new_sort_order = is_same_sort_method
      ? {
          ...sort_order,
          [new_sort_method]: !sort_order[sort_method],
        }
      : sort_order;

    this.setState(
      {
        cases_sort_method: new_sort_method,
        cases_sort_order_ascending: new_sort_order,
      },
      this.onChangeFilter
    );
  };
}

const mapStateToProps = (state) => {
  return {
    case_filter_preferences: getCaseFilterPreferences(state),
    case_filter_preferences_modal: getCaseFilterPreferencesModal(state),
    case_filter_preferences_loading: getCaseFilterPreferencesPending(state),
    case_filter_count: getCaseListFilterCount(state),
    case_filter_count_loading: getCaseListFilterCountLoading(state),
    case_list_data: getCaseListTableData(state),
    case_list_table_loading: getCaseListTableLoading(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseFilterPreferences: fetchCaseFilterPreferences,
      putCaseFilterPreferences: putCaseFilterPreferences,
      initializeCaseFilterPreferencesModal: initializeCaseFilterPreferencesModal,
      closeCaseFilterPreferencesModal: closeCaseFilterPreferencesModal,
      fetchCaseFilterCount: fetchCaseFilterCount,
      fetchCaseListTable: fetchCaseListTable,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseList));
