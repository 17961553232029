import React, { Component } from 'react';
import { getRootPath } from '../../common/functions';
import { withRouter } from 'react-router-dom';

import Badge from '../components/badge';
import FilterContainer from '../components/container/filter_container';
import FilterItem from '../components/filter_item';
import { getStatusFilterValueFromRoute, getDoctorsIdFromRoute } from '../../common/route';
import * as RS from 'reactstrap';
import { getAddressName, sortedAddressByName } from '../../common/helpers';

/**
 * This component is used to show the user the prospect list filter element on the prospect list
 * @component
 * @alias DoctorProspectFilter
 * @category IPP
 */
class SimulationFilter extends Component {
  constructor(props) {
    super(props);
    this.popRef = React.createRef();

    this.state = {
      status: '',
      doctor_role: '',
      doctor_id: '',
      simulation_list: [],
      simulation_list_doctor: [],
      simulation_list_location: [],
    };
  }

  componentDidMount() {
    this.updateStatusByURL();
    this.setState({ doctor_id: getDoctorsIdFromRoute() });
  }
  componentDidUpdate() {
    const status = getStatusFilterValueFromRoute();

    if (this.state.status !== status) {
      this.updateStatusByURL();
    }
  }
  updateStatusByURL = () => {
    const status = getStatusFilterValueFromRoute();
    this.setState({ status });
  };

  /**
   * Displays case count string
   * @param {int} Case Count
   * @returns
   */
  displayCount(count) {
    return `(${count})`;
  }

  /**
   * The the route information
   *
   * @function
   * @return {String} returns the url route information
   */
  getCasesRoute = () => {
    let caseListRoute = this.props.history.location.pathname.match(/portal\/\d+\//);
    let route = getRootPath(this.props.history.location.pathname);

    if (caseListRoute) {
      //Not in portal route
      if (this.state.role.indexOf('Business') < 0 || this.state.role.indexOf('Admin') < 0) {
        route = `/portal/${this.state.doctor_id}`;
      }
    } else {
      route = this.props.history.location.pathname;
    }

    return route;
  };
  /**
   * Creates Count string for filter and dropdown menus
   * @param {string || int} addressId
   * @returns String
   */
  displayLocationCount(addressId) {
    const count =
      addressId && this.props.simulationListLocation && this.props.simulationListLocation[addressId] ? this.props.simulationListLocation[addressId].length : 0;
    return `(${count})`;
  }
  /**
   * Sets state for when user hovers over doctor menu
   */
  byDoctorEnter = () => {
    this.setState({ byDoctorLinkEnter: true, byLocationLinkEnter: false, byLocationMenuOpen: false });
  };
  /**
   * Sets state for when doctor cursor leaves doctor menu
   */
  byDoctorLeave = () => {
    setTimeout(() => {
      if (!this.state.byDoctorMenuOpen) {
        this.setState({ byDoctorLinkEnter: false, byDoctorMenuOpen: false });
      }
    }, 200);
  };
  /**
   * Sets state for when cursor enters doctor submenu
   */
  byDoctorSubmenuEnter = () => {
    this.setState({ byDoctorMenuOpen: true });
  };
  /**
   * Sets state for when cursor leaves doctor submenu
   */
  byDoctorSubmenuLeave = () => {
    this.setState({ byDoctorMenuOpen: false, byDoctorLinkEnter: false });
  };
  /**
   * Sets state for when cursor over location menu
   */
  byLocationEnter = () => {
    this.setState({ byLocationLinkEnter: true, byDoctorLinkEnter: false, byDoctorMenuOpen: false });
  };
  /**
   * Sets state for when cursor leaves location menu
   */
  byLocationLeave = () => {
    setTimeout(() => {
      if (!this.state.byLocationMenuOpen) {
        this.setState({ byLocationLinkEnter: false, byLocationMenuOpen: false });
      }
    }, 200);
  };
  /**
   * Sets state for when cursor enters location submenu
   */
  byLocationSubmenuEnter = () => {
    this.setState({ byLocationMenuOpen: true });
  };
  /**
   * Sets state for when cursor leaves location submenu
   */
  byLocationSubmenuLeave = () => {
    this.setState({ byLocationMenuOpen: false, byLocationLinkEnter: false });
  };
  /**
   * Display Selected Doctor Text in Select Dropdown
   * @returns {string} Doctor cases menu string
   */
  getSelectedDoctorFilterText = () => {
    const selection = this.props.selection;
    const doctor_id = selection.substring(selection.indexOf('_') + 1);
    let doctor_name = '';
    if (doctor_id === 'unassigned') return 'Unassigned Cases';
    for (let dso_doctor of this.props.smileDoctors) {
      if (dso_doctor.id.toString() === doctor_id && dso_doctor.role !== 'DSO_Admin') {
        doctor_name = dso_doctor.user__last_name;
        break;
      }
    }
    return `Dr. ${doctor_name}'s Cases`;
  };

  /**
   * Displays Selected Address text in Select Dropdown
   * @returns {string} Location menu string
   */
  getSelectedAddressFilterText = () => {
    const selection = this.props.selection;
    const address_id = selection.substring(selection.indexOf('_') + 1);
    for (let dso_address of this.props.smileAddresses) {
      if (dso_address.id.toString() === address_id) {
        const label = dso_address.address_name ? dso_address.address_name : dso_address.city + ' Practice';
        return label;
      }
    }
    return '';
  };

  /**
   * Display total case count
   * @returns {string} Total Case Count
   */
  displayTotalCaseCount = () => {
    const num_cases = this.props.simulationRaw ? this.props.simulationRaw.length : 0;
    return `(${num_cases})`;
  };

  /**
   * Display Selected Doctor Case Count
   * @returns {String}
   */
  getSelectedDoctorCaseCount = () => {
    const selection = this.props.selection;
    const doctor_id = selection.substring(selection.indexOf('_') + 1);
    const num_cases = this.props.simulationListDoctor[doctor_id] ? this.props.simulationListDoctor[doctor_id].length : 0;
    return `(${num_cases})`;
  };

  /**
   * Display Selected Address Case Count
   * @returns {String}
   */
  getSelectedAddressCaseCount = () => {
    const selection = this.props.selection;
    const address_id = selection.substring(selection.indexOf('_') + 1);
    const label = this.props.simulationListLocation[address_id] ? this.props.simulationListLocation[address_id].length : 0;
    return `(${label})`;
  };

  /**
   * Update browser route to the correct the url
   *
   * @function
   * @param {String} name - the name of the selection value
   */
  appendSelectionToRoute = (name) => {
    let pathname = this.props.history.location.pathname;
    let rootpath = getRootPath(pathname);
    let that = this;
    let isHome = pathname === rootpath;

    let id = this.state.doctor_id;
    let role = this.props.doctorRole;

    if (id) {
      if (role && (role.indexOf('Business') >= 0 || role.indexOf('Admin') >= 0)) {
        if (isHome) {
          that.props.history.replace(`${rootpath}?filter=smile_simulation${name !== 'my_cases' ? `&selection=${name}` : ''}`);
        } else {
          that.props.history.push(`${rootpath}?filter=smile_simulation${name !== 'my_cases' ? `&selection=${name}` : ''}`);
        }
      } else {
        if (isHome) {
          that.props.history.replace(`/portal/${id}?filter=smile_simulation${name !== 'my_cases' ? `&selection=${name}` : ''}`);
        } else {
          that.props.history.push(`/portal/${id}?filter=smile_simulation${name !== 'my_cases' ? `&selection=${name}` : ''}`);
        }
      }
    }
  };

  /**
   * Update browser route to the correct the url
   *
   * @function
   * @param {String} name - the name of the filter value
   */
  appendStatusToRoute = (name) => {
    let rootpath = getRootPath(this.props.history.location.pathname);
    const ir_route = `?filter=smile_simulation`;
    const selection_string = this.props.selection ? `&selection=${this.props.selection}` : '';

    this.props.history.replace(`${rootpath}${ir_route}` + selection_string + (name !== '' ? `&status_filter=${name}` : ''));
  };
  /**
   * Update to the status in route and state
   *
   * @function
   * @param {Object} event - The event on the change of the status
   */
  onStatusClick = (event) => {
    this.setState({
      byDoctorMenuOpen: false,
      byDoctorLinkEnter: false,
      byLocationLinkEnter: false,
      byLocationMenuOpen: false,
    });

    if (event && event.currentTarget && event.currentTarget.dataset && (event.currentTarget.dataset.status || event.currentTarget.dataset.status === '')) {
      if (
        event.currentTarget.dataset.status &&
        (event.currentTarget.dataset.status === 'my_cases' ||
          event.currentTarget.dataset.status === 'all_cases' ||
          (event.currentTarget.dataset.status.includes('doctor') && !event.currentTarget.dataset.status.includes('doctor_action')) ||
          event.currentTarget.dataset.status.includes('location'))
      ) {
        if (
          event.currentTarget.dataset.status.includes('doctor') &&
          event.currentTarget.dataset.status.substring(event.currentTarget.dataset.status.indexOf('_') + 1) === getDoctorsIdFromRoute()
        ) {
          event.currentTarget.dataset.status = 'my_cases';
        }
        this.appendSelectionToRoute(event.currentTarget.dataset.status);
        this.setState({
          selection: event.currentTarget.dataset.status,
        });
        this.props.resetPage();
      } else {
        this.appendStatusToRoute(event.currentTarget.dataset.status);
        this.setState({
          filter: event.currentTarget.dataset.status,
        });
      }
    }
  };

  render() {
    return (
      <div className="statuses">
        {this.props.search ? this.props.search : null}
        {this.props.doctorRole.includes('DSO') ? (
          <FilterContainer className="tend tend-menu">
            <div className="item" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="button">
              {this.props.doctorRole !== 'DSO' && this.props.selection === '' ? (
                <span>
                  My Requests {`(${this.props.simulationListDoctor[this.state.doctor_id] ? this.props.simulationListDoctor[this.state.doctor_id].length : 0})`}
                </span>
              ) : this.props.selection === 'all_cases' || (this.props.doctorRole === 'DSO' && this.props.selection === '') ? (
                <span>All Requests {this.displayTotalCaseCount()}</span>
              ) : this.props.selection.includes('doctor') ? (
                <span>
                  {this.getSelectedDoctorFilterText()} {this.getSelectedDoctorCaseCount()}
                </span>
              ) : this.props.selection.includes('location') ? (
                <span>
                  {this.getSelectedAddressFilterText()} {this.getSelectedAddressCaseCount()}
                </span>
              ) : (
                <>
                  <span>My Requests</span>{' '}
                  <span>{`(${this.props.simulationListDoctor[this.state.doctor_id] ? this.props.simulationListDoctor[this.state.doctor_id].length : 0})`}</span>
                </>
              )}
              <i className="fa fa-chevron-down pull-right" aria-hidden="true" />
            </div>
            <ul className="dropdown-menu tend-dropdown-menu-round">
              {this.props.doctorRole !== 'DSO' ? (
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={this.onStatusClick} data-status="my_cases">
                    My Requests{' '}
                    {`(${this.props.simulationListDoctor[this.state.doctor_id] ? this.props.simulationListDoctor[this.state.doctor_id].length : 0})`}
                  </a>
                </li>
              ) : null}

              <li>
                {/* eslint-disable-next-line */}
                <a onClick={this.onStatusClick} data-status="all_cases">
                  All Requests {this.displayTotalCaseCount()}
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onMouseOver={this.byDoctorEnter} onMouseLeave={this.byDoctorLeave} className="no-alert">
                  By Doctor <i className="fa fa-chevron-right pull-right" aria-hidden="true" ref={this.popRef} />
                </a>
                {this.popRef.current ? (
                  <RS.Popover
                    placement="right"
                    isOpen={this.state.byDoctorLinkEnter || this.state.byDoctorMenuOpen}
                    target={this.popRef.current}
                    hideArrow={false}
                    className="tend-doctor-popover"
                    onMouseOver={this.byDoctorSubmenuEnter}
                    onMouseLeave={this.byDoctorSubmenuLeave}
                    modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                  >
                    <RS.PopoverBody>
                      <ul className="tend-submenu">
                        {this.props.smileDoctors &&
                        this.props.smileDoctors.length === 0 &&
                        this.props.simulationListDoctor &&
                        !this.props.simulationListDoctor['unassigned'] ? (
                          <li onClick={this.byDoctorSubmenuLeave}>
                            {/* eslint-disable-next-line */}
                            <a className="no-doctors">No doctors found</a>
                          </li>
                        ) : (
                          this.props.smileDoctors &&
                          this.props.smileDoctors.length > 0 &&
                          this.props.smileDoctors.map((smile_doctor, idx) => {
                            const case_count = this.props.simulationListDoctor[smile_doctor.id] ? this.props.simulationListDoctor[smile_doctor.id].length : 0;
                            return (
                              <li key={idx}>
                                {/* eslint-disable-next-line */}
                                <a onClick={this.onStatusClick} data-status={'doctor_' + smile_doctor.id}>
                                  {smile_doctor.user__first_name} {smile_doctor.user__last_name} {this.displayCount(case_count)}
                                </a>
                              </li>
                            );
                          })
                        )}
                        {this.props.simulationListDoctor && this.props.simulationListDoctor['unassigned'] && (
                          <li>
                            {/* eslint-disable-next-line */}
                            <a onClick={this.onStatusClick} data-status={'doctor_unassigned'}>
                              Unassigned {this.displayCount(this.props.simulationListDoctor['unassigned'].length)}
                            </a>
                          </li>
                        )}
                      </ul>
                    </RS.PopoverBody>
                  </RS.Popover>
                ) : null}
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onMouseOver={this.byLocationEnter} onMouseLeave={this.byLocationLeave} className="no-alert">
                  By Location <i className="fa fa-chevron-right pull-right" aria-hidden="true" ref={this.popRef} />
                </a>
                {this.popRef.current ? (
                  <RS.Popover
                    placement="right"
                    isOpen={this.state.byLocationLinkEnter || this.state.byLocationMenuOpen}
                    target={this.popRef.current}
                    hideArrow={false}
                    className="tend-location-popover"
                    onMouseOver={this.byLocationSubmenuEnter}
                    onMouseLeave={this.byLocationSubmenuLeave}
                    modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                  >
                    <RS.PopoverBody>
                      <ul className="tend-submenu">
                        {sortedAddressByName(this.props.smileAddresses).map((dso_address, idx) => {
                          return (
                            <li key={idx}>
                              {/* eslint-disable-next-line */}
                              <a onClick={this.onStatusClick} data-status={'location_' + dso_address.id} to={{ pathname: this.getCasesRoute() }}>
                                {getAddressName(dso_address)} {this.displayLocationCount(dso_address.id)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </RS.PopoverBody>
                  </RS.Popover>
                ) : null}
              </li>
            </ul>
          </FilterContainer>
        ) : (
          <FilterContainer className="hide-on-mobile">
            <FilterItem active={this.state.status === ''} data-status="" onClick={this.onStatusClick}>
              My Requests
              <Badge>{this.props.statuses.all}</Badge>
            </FilterItem>
          </FilterContainer>
        )}

        <FilterContainer className="hide-on-mobile">
          <FilterItem type="subfilter" active={this.state.status === 'action'} data-status="action" onClick={this.onStatusClick}>
            Action Required
            <Badge theme="primary">{this.props.statuses.action}</Badge>
          </FilterItem>
          <FilterItem active={this.state.status === 'doctor_action'} data-status="doctor_action" onClick={this.onStatusClick}>
            Upload New Toothprints
            <Badge theme="secondary">{this.props.statuses.doctor_action}</Badge>
          </FilterItem>
          <FilterItem
            active={this.state.status === 'released' || this.state.status === 'Resume Case Submission'}
            data-status="released"
            onClick={this.onStatusClick}
          >
            Begin Submission
            <Badge theme="secondary">{this.props.statuses.released}</Badge>
          </FilterItem>
        </FilterContainer>

        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'request_pending'} data-status="request_pending" onClick={this.onStatusClick}>
            Request Pending
            <Badge>{this.props.statuses.request_pending}</Badge>
          </FilterItem>
        </FilterContainer>

        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'Case Submitted'} data-status="submitted" onClick={this.onStatusClick}>
            Submitted
            <Badge>{this.props.statuses.submitted}</Badge>
          </FilterItem>
        </FilterContainer>

        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'Prospect Lost'} data-status="lost" onClick={this.onStatusClick}>
            Prospect Lost
            <Badge>{this.props.statuses.lost}</Badge>
          </FilterItem>
        </FilterContainer>

        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'cancelled'} data-status="cancelled" onClick={this.onStatusClick}>
            Cancelled
            <Badge>{this.props.statuses.cancelled}</Badge>
          </FilterItem>
        </FilterContainer>
      </div>
    );
  }
}

export default withRouter(SimulationFilter);
