import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { onDownload, convertDate } from '../../../common/functions';
import { getWireFabricationReviewStatus, getWireFabricationReviewStatusLog } from '../../../redux/reducers/bpp/wire_selection/wire_fabrication_review';

const GEN_2_LABELS = {
  smartwire: 'Smartwire and BFL',
  idb: 'IDB Tray',
  bracket: 'Brackets',
  outer_patient: 'Outer Patient Label',
  singlesheet: 'Single Sheet',
};

/**
 * Used to perform production steps during case manufacturing process
 * @component
 * @alias ProductionSummary
 * @category BPP
 */
class ProductionSummary extends Component {
  files = {
    smartwire: this.props.smartwire_label_file,
    idb: this.props.idb_label_file,
    bracket: this.props.bracket_label_file,
    outer_patient: this.props.outer_patient_label_file,
    singlesheet: this.props.singlesheet_label_file,
  };
  /**
   * Displays Labels summary for gen 2.0
   * @function
   * @return {JSX} - Labels jsx
   */
  displayGen2LabelsSummary() {
    return (
      <>
        {Object.keys(this.files).map((label_type, index) => {
          if (this.files[label_type]) {
            return (
              <div key={index}>
                <div className="underline-text viewable-text" onClick={() => this.props.onGen2LabelModalClick(label_type)}>
                  Label, {GEN_2_LABELS[label_type]} <i className="fa fa-file-pdf-o" aria-hidden="true" />
                </div>
                {this.files[label_type].file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }
  isGenerationSuccess() {
    if (!this.props.case_id.indexOf('-')) {
      this.files['udi'] = this.props.udi_label_file;
    }
    const cad_status = this.props.cases.cad_process.log;
    if (
      this.props.cases.cad_process.previous_status === 'Label Override Process' ||
      cad_status[cad_status.length - 1].status_code === 'Label Override Process' ||
      (cad_status[cad_status.length - 1].status_code === 'Labels Complete' && cad_status.length > 2 ? cad_status[cad_status.length - 2].status_code : false)
    ) {
      return false;
    } else {
      if (this.files.singlesheet) return true;
      const logs = cad_status.filter((log) => {
        return log.status_code === 'Label Generation Process';
      });
      if (logs.length > 0 && logs[logs.length - 1].text) {
        let status_comment = logs[logs.length - 1].text;
        for (var label_type in this.files) {
          if (status_comment.includes(label_type) && !this.files[label_type]) {
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * Retrieves wire fabrication review initiated/resolved dates
   * @function
   * @param {type} string - Initiated or resolved
   * @returns {string} - Date
   */
  getWireFabricationReviewDate = (type) => {
    const status_by_action = { initiated: 'WFR Process', resolved: 'WFR Complete' };
    for (const status of this.props.wire_fabrication_review_status_log) {
      if (status.status === status_by_action[type]) {
        return convertDate(status.date);
      }
    }
    return '';
  };

  /**
   * Determines if Initiate Review button should be displayed
   * @function
   * @returns {boolean} - True or false
   */
  shouldDisplayInitiateReview = () => {
    return (
      this.props.cases.gen_2 &&
      this.props.cases.ws_version &&
      this.props.cases.ws_version !== '1.0' &&
      this.props.get_case_status !== 'STATUS_SHIP' &&
      this.props.get_case_status !== 'STATUS_CANCEL' &&
      this.props.get_case_status !== 'STATUS_HOLD' &&
      !this.props.cases.shipping_approved_ind &&
      this.props.wire_selection_status === 'WS Complete'
    );
  };

  render() {
    const ip_loop_file = this.props.ip_loop_file;
    const recipe_file = this.props.recipe_file;
    const ip_loop_measurement_file = this.props.ip_loop_measurement_file;
    const has_status = _.isEmpty(this.props.inprocess_ipstatus);
    const label_inner_packaging_file = this.props.label_inner_packaging_file;
    const label_contents_file = this.props.label_contents_file;
    const udi_label_file = this.props.udi_label_file;
    const last_ip_status = this.props.last_ip_status;
    return (
      <div className="cad-container">
        <div className="bold-text business-dark-heading">
          Production
          {this.props.cases.ws_version && this.props.cases.ws_version !== '1.0' && this.props.cases.gen_2 && this.props.displayProductionLogIcon()}
        </div>
        <div className="business-dark-theme">
          <div>
            <span>Production WO Ref: {this.props.ref_no ? this.props.ref_no : 'N/A'}</span>
            {this.shouldDisplayInitiateReview() && (
              <div className="initiate-review">
                <span>
                  <button type="button" className="btn btn-light" onClick={() => this.props.toggleInitiateWireFabricationReviewModal(true)}>
                    Initiate Review
                  </button>
                </span>
              </div>
            )}
          </div>
          <div>
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) &&
                  this.props.get_case_status !== 'STATUS_SHIP' &&
                  this.props.get_case_status !== 'STATUS_CANCEL' &&
                  this.props.get_case_status !== 'STATUS_HOLD' &&
                  !this.props.cases.shipping_approved_ind ? (
                  <span>
                    {/* eslint-disable-next-line */}
                    <a className={this.props.return_clicked ? 'return-disabled' : 'process-return-ir link-button'} onClick={this.props.restartLabelGeneration}>
                      <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to label selection
                    </a>
                  </span>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>

            <div className="cad-title margin-top-20">Labels</div>
            {this.props.cases.gen_2 ? (
              <>
                {(this.props.label_status === 'Production Process Complete' || (this.props.case_status === 'STATUS_SHIP' && !this.props.label_status)) &&
                !this.isGenerationSuccess() ? (
                  label_inner_packaging_file || label_contents_file ? (
                    <>
                      {label_inner_packaging_file ? (
                        <div>
                          <div className="underline-text viewable-text" onClick={this.props.onInnerPackagingModalClick}>
                            Label, Inner Packaging <i className="fa fa-file-pdf-o" aria-hidden="true" />
                          </div>
                          {label_inner_packaging_file.file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
                        </div>
                      ) : null}
                      {label_contents_file ? (
                        <div>
                          <div className="underline-text viewable-text" onClick={this.props.onLabelContentsModalClick}>
                            Label, Contents <i className="fa fa-file-pdf-o" aria-hidden="true" />
                          </div>
                          {label_contents_file.file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div>Label generation has been overridden...</div>
                  )
                ) : (
                  this.displayGen2LabelsSummary()
                )}
              </>
            ) : (
              <>
                {this.props.label_status === 'Production Process Complete' && !label_inner_packaging_file && !label_contents_file && !udi_label_file ? (
                  <div>Label generation has been overridden...</div>
                ) : this.props.case_status === 'STATUS_SHIP' &&
                  !this.props.label_status &&
                  !label_inner_packaging_file &&
                  !label_contents_file &&
                  !udi_label_file ? (
                  <div>Label generation has been overridden...</div>
                ) : null}
                {label_inner_packaging_file ? (
                  <div>
                    <div className="underline-text viewable-text" onClick={this.props.onInnerPackagingModalClick}>
                      Label, Inner Packaging <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    </div>
                    {label_inner_packaging_file.file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
                  </div>
                ) : null}
                {label_contents_file ? (
                  <div>
                    <div className="underline-text viewable-text" onClick={this.props.onLabelContentsModalClick}>
                      Label, Contents <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    </div>
                    {label_contents_file.file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
                  </div>
                ) : null}
              </>
            )}
            {udi_label_file ? (
              <div>
                <div className="underline-text viewable-text" onClick={this.props.onUdiLabelModalClick}>
                  Label, UDI <i className="fa fa-file-pdf-o" aria-hidden="true" />
                </div>
                {udi_label_file.file_type === 'labels_manual' ? <span> (Manually Uploaded) </span> : null}
              </div>
            ) : null}
          </div>
          <div>
            {last_ip_status === 'ip_none' ? (
              <React.Fragment>
                <div className="cad-title margin-top-20">AWB</div>
                <div>Automated wire bending process has been overridden...</div>
              </React.Fragment>
            ) : last_ip_status === 'ip_files' ? (
              <React.Fragment>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('CASE_AWB_REWORK', user_roles_and_permissions.permissions) &&
                      this.props.selections &&
                      !has_status &&
                      this.props.inprocess_ipstatus &&
                      this.props.ref_no &&
                      this.props.get_case_status !== 'STATUS_SHIP' &&
                      this.props.get_case_status !== 'STATUS_CANCEL' &&
                      this.props.get_case_status !== 'STATUS_HOLD' &&
                      !this.props.cases.shipping_approved_ind ? (
                      <button className="process-return-ir link-button" onClick={this.props.onRewokModalClick}>
                        <i className="fa fa-repeat" aria-hidden="true" /> Rework
                      </button>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
                <div className="cad-title margin-top-20">AWB</div>
                <div>
                  {ip_loop_file && ip_loop_file.upload_data ? (
                    <a className="underline-text viewable-text view-upload" href={`/${ip_loop_file.upload_data}`} download onClick={onDownload}>
                      Input File <i className="fa fa-file-excel-o" aria-hidden="true" />
                    </a>
                  ) : null}
                </div>
                <div>
                  {recipe_file && recipe_file.upload_data ? (
                    <a className="underline-text viewable-text view-upload" href={`/${recipe_file.upload_data}`} download onClick={onDownload}>
                      Recipe File <i className="fa fa-file-excel-o" aria-hidden="true" />
                    </a>
                  ) : null}
                </div>
                <div>
                  {ip_loop_measurement_file && ip_loop_measurement_file.upload_data ? (
                    <div className="underline-text viewable-text view-upload" onClick={this.props.onIPLoopMeasurementModalClick}>
                      IP Loop Measurements <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </div>
          {this.props.cases.gen_2 && this.props.wire_fabrication_review_status === 'WFR Complete' && (
            <div className="margin-top-10">
              <span className="bold-text">Wire Fabrication Review Initiated</span> {this.getWireFabricationReviewDate('initiated')}{' '}
              <span className="bold-text">Resolved</span> {this.getWireFabricationReviewDate('resolved')}
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    wire_fabrication_review_status: getWireFabricationReviewStatus(state),
    wire_fabrication_review_status_log: getWireFabricationReviewStatusLog(state),
  };
};

export default connect(mapStateToProps, null)(ProductionSummary);
