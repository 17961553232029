import React from 'react';
import SelectionBarTop from './selection_bar/selection_bar_top';
import Pane from './pane';
import WasmViewerBtn from '../../extras/wasm_btn';

/**
 * Displays the viewer panels
 * @component
 */
export default function PanelViewer(props) {
  return (
    <div
      className={props.collapse ? 'panel-viewer--dark panel-viewer--dark--collapse' : props.hidePanel ? 'invisible' : 'panel-viewer--dark'}
      onContextMenu={(e) => e.preventDefault()}
    >
      <SelectionBarTop
        split={props.split}
        scroll_options={props.scroll_options}
        scroll_items={props.scroll_items}
        scroll_area={props.scroll_area}
        nextScrollArea={props.nextScrollArea}
        prevScrollArea={props.prevScrollArea}
        setScrollArea={props.setScrollArea}
        onScrollOptionClick={props.onScrollOptionClick}
      />
      <Pane {...props} />
      <WasmViewerBtn case_details={props.case_details}/>
    </div>
  );
}
