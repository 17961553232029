// External Libs
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';
import { downloadFile, removeIreqIdInitialNumber } from '../../../../common/functions';
import { getPartTranslation } from './wire_selection';

import Modal from '../../../../components/modal/modal';
import Upload from './upload';

//Redux
import { getDxfFileNameError, getWsGuideFileNameError } from '../../../../redux/reducers/bpp/wire_selection/wire_selection';
import { setDxfFileNameError, setWsGuideFileNameError } from '../../../../redux/actions/bpp/wire_selection/wire_selection';

class ConversionComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: {},
      modal_template: {},
    };
  }
  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });

    if ((Object.values(this.props.dxf_file_name_error).some((err) => err === true) || this.props.ws_guide_file_name_error) && !_.isEmpty(this.state.modal)) {
      this.setState({
        modal: {},
      });
    }
  }

  componentWillUnmount() {
    this.resetDxfFileNameError();
  }

  /**
   * Sets DXF file name error to false
   * @function
   */
  resetDxfFileNameError = () => {
    this.props.setDxfFileNameError(false, 'case_id');
    this.props.setDxfFileNameError(false, 'format');
    this.props.setWsGuideFileNameError(false);
  };

  // Checks if any manual upload exist for a wire
  filesExist = (sw) => {
    return this.isManualProcess() ? sw.ireq_manual_process_file_id : sw.ireq_file_id;
  };

  // Returns file location based on process
  constructLocation = (label, type) => {
    const is_manual = this.isManualProcess();

    let location = type === 'ws_dxf' ? 'ws_dxf_' + label.toLowerCase().replace('smartwire', '').replace(' ', '').replace(' ', '_').replace('de', '') : type;
    return is_manual ? location + '_mp' : location;
  };

  onUploadClick = (ireq_id, ws_id, label, type, wire_name = '') => {
    this.resetDxfFileNameError();
    const config = {
      header_text: `${label} - ${removeIreqIdInitialNumber(ireq_id)}`,
      ireq_id: ireq_id,
      type: type,
      onUpload: (data) => {
        this.onUpload(data, ireq_id, ws_id, label, type);
      },
      location: this.constructLocation(label, type),
      ws_id: ws_id,
      wire_name: wire_name,
    };

    this.setState({
      modal: config,
    });
  };

  onUpload = (data, ireq_id, ws_id, label, type) => {
    data[0]['ireq_wire_selection_id'] = ws_id;
    data[0]['mime_type'] = type === 'ws_dxf' ? 'application/dxf' : type === 'ws_guide' ? 'application/pdf' : 'unknown';
    data[0]['file_type'] = type;
    data[0]['manual_process'] = this.isManualProcess();

    this.props.wsUpload(data, ireq_id);
    this.setState({ modal: {} });
  };

  onTemplateClick = (item, label) => {
    this.resetDxfFileNameError();
    const { id, ireq_id, template } = item;

    const config = {
      header_text: `${label} - ${removeIreqIdInitialNumber(ireq_id)}`,
      message_text: `Please specify template wire for the ${label} for this case.`,
      ireq_id: ireq_id,
      ireq_wire_selection_id: id,
      template_selection: '',
      input: template ? template : '',
    };
    this.setState({
      modal_template: config,
    });
  };

  onTemplateRemove = (item) => {
    this.resetDxfFileNameError();
    const { id, ireq_id } = item;

    const data = {
      ireq_wire_selection_id: id,
      template_selection: '',
    };

    this.props.wsSetTemplate(data, ireq_id);
  };

  // Displays template selection modal
  onSetTemplate = (e) => {
    let template = _.cloneDeep(this.state.modal_template);
    template['template_selection'] = e.target.value;

    this.setState({
      modal_template: template,
    });
  };

  onTemplateConfirm = () => {
    const { modal_template } = this.state;

    this.props.wsSetTemplate(modal_template, modal_template.ireq_id);
    this.setState({
      modal_template: {},
    });
  };

  /**
   * Checks if selected wire is a template
   * @param {Object} sw - wire data
   * @function
   */
  isTemplate = (sw) => {
    if (this.props.state.case_ws.indexOf(sw.original_wire_name) === -1) return false;
    const result_json = this.props && this.props.state && this.props.state.result_json ? this.props.state.result_json : '';
    const original_template = this.props.getTemplateInfoFromResults(sw.ireq_details__ireq_parts__description, result_json);
    return (this.isManualProcess() && sw.template) || (original_template !== '' && sw.ireq_file__file_type == null);
  };

  /**
   * Renders Manual / Custom Wire Elements in Item Request
   * @param {Object} sw Wire Object
   * @param {Int} index Wire Index
   * @param {Boolean} has_generate_permission
   * @returns
   */
  displayFileOrUpload = (sw, index, has_generate_permission) => {
    const label = getPartTranslation(sw.ireq_details__ireq_parts__part_id, 'label');

    return this.isTemplate(sw) ? (
      this.isManualProcess() ? (
        <div key={index}>
          {sw.template ? (
            <span>{`${label} (Not Applicable)`}</span>
          ) : this.filesExist(sw) ? (
            <div>
              <span
                className="hyperlink-dark hyperlink-dark-bottom"
                onClick={() => {
                  this.onLinkClick(this.displayLoc([sw]));
                }}
              >
                {this.props.translateWire(sw)} {label} <i className="fa fa-file-archive-o" aria-hidden="true"></i>
              </span>
              {this.displayManualUpload([sw], has_generate_permission)}
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-bpp-upload btn-margin-bottom"
              onClick={() => {
                this.onUploadClick(sw.ireq_id, sw.id, label, 'ws_dxf', sw.original_wire_name);
              }}
            >
              {`Upload ${label}`}
            </button>
          )}
          {this.displayTemplateSelection([sw], label)}
          {this.isWire(label, this.props.dxf_file_name_error.wire_name) && (
            <>
              {this.props.dxf_file_name_error.case_id ? (
                <div className="dxf-error">Item Request ID Mismatch</div>
              ) : this.props.dxf_file_name_error.format ? (
                <div className="dxf-error">Incorrect File Naming Convention</div>
              ) : null}
            </>
          )}{' '}
        </div>
      ) : null
    ) : this.filesExist(sw) ? (
      <div key={index}>
        <span
          className="hyperlink-dark hyperlink-dark-bottom"
          onClick={() => {
            this.onLinkClick(this.displayLoc([sw]));
          }}
        >
          {this.props.translateWire(sw)} {label} <i className="fa fa-file-archive-o" aria-hidden="true"></i>
        </span>
        {this.displayManualUpload([sw], has_generate_permission)}
      </div>
    ) : (
      <div key={index}>
        <button
          type="button"
          className="btn btn-bpp-upload btn-margin-bottom"
          onClick={() => {
            this.onUploadClick(sw.ireq_id, sw.id, label, 'ws_dxf', sw.original_wire_name);
          }}
        >
          {`Upload ${label}`}
        </button>
        {this.displayError([sw])}
        {!sw.original_wire_name.includes('zero') && this.displayTemplateSelection([sw], label)}
        {this.isWire(label, this.props.dxf_file_name_error.wire_name) && (
          <>
            {this.props.dxf_file_name_error.case_id ? (
              <div className="dxf-error">Item Request ID Mismatch</div>
            ) : this.props.dxf_file_name_error.format ? (
              <div className="dxf-error">Incorrect File Naming Convention</div>
            ) : null}
          </>
        )}
      </div>
    );
  };

  /**
   * Displays manual upload files
   * @param {Object} sw - Custom wire data
   * @param {int} index - Wire Index
   * @function
   */
  displayFiles = (sw, index, has_generate_permission) => {
    const label = getPartTranslation(sw.ireq_details__ireq_parts__part_id, 'label');

    return this.filesExist(sw) ? (
      <div key={index}>
        <span
          className="hyperlink-dark hyperlink-dark-bottom"
          onClick={() => {
            this.onLinkClick(this.displayLoc([sw]));
          }}
        >
          {this.props.translateWire(sw)} {label} <i className="fa fa-file-archive-o" aria-hidden="true"></i>
        </span>
        {this.displayManualUpload([sw], has_generate_permission)}
      </div>
    ) : null;
  };

  isManualProcess = () => {
    return this.props.manual_process ? true : false;
  };

  isForceManual = () => {
    return this.props.state && this.props.state.status_comment && this.props.state.status_comment === 'Force Manual';
  };

  /**
   * Checks and returns wire selection guide id
   * @param {Object} guide - Wire selection guide info
   * @function
   * @return {String} returns wire selection guide id if exists
   */
  hasWsGuide = (guide) => {
    return this.isManualProcess()
      ? guide && guide.length > 0 && guide[0]['ireq_manual_process_file_id']
      : guide && guide.length > 0 && guide[0]['ireq_file_id'];
  };

  displayLoc = (guide) => {
    return this.isManualProcess() ? guide[0]['ireq_manual_process_file__upload_data'] : guide[0]['ireq_file__upload_data'];
  };

  getGuideInfo = (guide) => {
    return {
      modified_date: guide[0]['modified_date'],
      upload_data: this.isManualProcess() ? guide[0]['ireq_manual_process_file__upload_data'] : guide[0]['ireq_file__upload_data'],
    };
  };

  onLinkClick = (link) => {
    downloadFile(link);
  };

  /**
   * Check if any custom wire exist based on process
   * @param {Array} wires - list of selected wires
   * @function
   * @return {Boolean} returns true if any custom wire exist
   */
  hasCustomWires = (wires) => {
    if (wires) {
      const custom_wires = wires.filter((wire) =>
        this.isManualProcess() ? wire['ireq_manual_process_file__file_type'] : wire['ireq_file__file_type'] && wire['ireq_file__file_type'] !== 'ws_template'
      );
      return custom_wires.length > 0;
    }

    return false;
  };

  // Adds tag to manually uploaded wires
  displayManualUpload = (item, has_generate_permission) => {
    let data = this.isManualProcess()
      ? [{ ireq_wire_selection_id: item[0].id, ireq_file_id: item[0].ireq_manual_process_file_id }]
      : [{ ireq_wire_selection_id: item[0].id, ireq_file_id: item[0].ireq_file_id }];

    data[0]['manual_process'] = this.isManualProcess();

    return item[0]['manual_upload'] || this.isManualProcess() ? (
      <span>
        {' '}
        (Manual Upload){' '}
        {!this.props.isComplete() && has_generate_permission ? (
          <i
            className="fa fa-trash-o trash-color"
            aria-hidden="true"
            onClick={() => {
              this.props.setModal(item[0]['group'] === 'guide' ? 'remove_guide' : 'remove_wire', '', '', data);
            }}
          ></i>
        ) : null}
      </span>
    ) : null;
  };

  displayError = (item) => {
    return item && item[0] && item[0]['comment'] && !item[0]['manual_upload'] && !this.isManualProcess() ? (
      <i
        className="fa fa-exclamation-circle fa-exclamation-next-btn"
        aria-hidden="true"
        data-toggle="tooltip"
        data-placement="top"
        title={item[0]['comment']}
      ></i>
    ) : null;
  };

  translateTemplate = (template) => {
    let readable_text = '';

    switch (template) {
      case 'standard':
        readable_text = 'Standard';
        break;
      case 'no_4':
        readable_text = 'No 4s';
        break;
      case 'one_4':
        readable_text = 'One 4';
        break;
      case 'universal':
        readable_text = 'Universal';
        break;

      default:
    }

    return readable_text;
  };

  displayTemplateSelection = (item, label) => {
    const hasUploadedFile = item && item[0] && item[0]['ireq_manual_process_file_id'] && item[0]['ireq_manual_process_file_id'] !== null;
    const checked = item[0]['template'] !== '';

    return this.isManualProcess() && !hasUploadedFile ? (
      <span className="template">
        <input
          className="template-checkbox"
          type="checkbox"
          name="referral"
          checked={checked}
          onChange={() => {
            checked ? this.onTemplateRemove(item[0]) : this.onTemplateClick(item[0], label);
          }}
        />
        <span className="template-text">This is a template wire </span>
        {checked ? (
          <div>
            <span className="hyperlink-dark" onClick={() => this.onTemplateClick(item[0], label)}>
              Specify wire type:
            </span>{' '}
            {this.translateTemplate(item[0]['template'])}
          </div>
        ) : null}
      </span>
    ) : null;
  };

  /**
   * Determines if label matches the wire name
   * @function
   * @param {string} label - Wire label
   * @param {string} wire_name - Wire name
   * @return {boolean} - True or false
   */
  isWire = (label, wire_name) => {
    const nomenclature = {
      zero: '0',
      initial: '1',
      intermediate: '2',
      final: '3',
    };
    const separated_wire_name = wire_name.split('_');
    const arch = separated_wire_name[0];
    const wire = separated_wire_name[1];
    const has_correct_arch = label.toLowerCase().includes(arch);
    const has_correct_wire = label.includes(nomenclature[wire]);
    return has_correct_arch && has_correct_wire;
  };

  /**
   * Determines if item request has wires that were not present in initial case/DE
   * @function
   * @param {list} wires - Item request wires
   * @return {boolean} - True or false
   */
  hasNewWire = (wires) => {
    for (const wire of wires) {
      if (this.props.state.case_ws.indexOf(wire.original_wire_name) === -1) return true;
    }
    return false;
  };

  render() {
    const { modal, modal_template } = this.state;
    const { details, adjusted_bmf_file } = this.props.state;
    const guide = details && details.filter((detail) => detail['group'] === 'guide');
    const wires = details && details.filter((detail) => detail['group'] === 'smartwire');
    const isComplete = this.props.isComplete();

    return (
      <div>
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('IREQ_WIRE_SELECTION_GENERATE', user_roles_and_permissions.permissions) &&
              this.isManualProcess() &&
              !isComplete &&
              !this.isForceManual() ? (
              <div>
                {/* eslint-disable-next-line */}
                <a
                  className="float-right"
                  onClick={() => {
                    this.props.wsAction('gohome');
                  }}
                >
                  <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to Wire Selection
                </a>
              </div>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>

        {adjusted_bmf_file && (
          <div className="margin-bottom-10">
            <span
              className="underline-text viewable-text file-span"
              onClick={() => {
                this.props.setModal('bmf_pdf');
              }}
            >
              Adjusted Bracket Measurements Form <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </span>
          </div>
        )}
        <div className="bold-text">Wire Selection Guide</div>

        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const has_generate_permission = userHasPermission('IREQ_WIRE_SELECTION_GENERATE', user_roles_and_permissions.permissions);
            return isComplete || !has_generate_permission ? (
              this.hasWsGuide(guide) ? (
                <div>
                  <span
                    className="hyperlink-dark hyperlink-dark-bottom"
                    onClick={() => {
                      this.props.setModal('pdf', '', this.getGuideInfo(guide));
                    }}
                  >
                    Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                  </span>
                  {this.displayManualUpload(guide, has_generate_permission)}
                </div>
              ) : (
                <div>N/A</div>
              )
            ) : this.hasWsGuide(guide) ? (
              <div>
                <span
                  className="hyperlink-dark hyperlink-dark-bottom"
                  onClick={() => {
                    this.props.setModal('pdf', '', this.getGuideInfo(guide));
                  }}
                >
                  Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                </span>
                {this.displayManualUpload(guide, has_generate_permission)}
              </div>
            ) : guide && guide.length > 0 ? (
              <div>
                <button
                  type="button"
                  className="btn btn-bpp-upload btn-margin-bottom"
                  onClick={() => {
                    this.onUploadClick(`${guide[0].ireq_id}`, guide[0].id, 'Wire Selection Guide', 'ws_guide');
                  }}
                >
                  {`Upload Wire Selection Guide`}
                </button>
                <>
                  {this.props.ws_guide_file_name_error ? (
                    <div className="dxf-error">
                      Incorrect filename - Filename should be: {removeIreqIdInitialNumber(this.props?.props?.item_request?.ireq_id)} - Wire Selection Guide Form
                    </div>
                  ) : null}
                </>
                {this.displayError(guide)}
              </div>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>

        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const has_generate_permission = userHasPermission('IREQ_WIRE_SELECTION_GENERATE', user_roles_and_permissions.permissions);
            return (this.isManualProcess() && isComplete) || !has_generate_permission ? (
              this.hasCustomWires(wires) ? (
                <>
                  <div className="bold-text">Custom Wire</div>
                  {wires.map((sw, index) => {
                    return this.displayFiles(sw, index, has_generate_permission);
                  })}
                </>
              ) : null
            ) : this.hasCustomWires(wires) || this.isManualProcess() || this.hasNewWire(wires) ? (
              <>
                <div className="bold-text">Custom Wire</div>
                {wires.map((sw, index) => {
                  return this.displayFileOrUpload(sw, index, has_generate_permission);
                })}
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>

        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return (
              <div className="button-panel center-text">
                {this.isManualProcess() ||
                !userHasPermission('IREQ_WIRE_SELECTION_MANUAL_PROCESS', user_roles_and_permissions.permissions) ||
                isComplete ? null : (
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.props.setModal('manual');
                    }}
                  >
                    Manual Process
                  </button>
                )}
                {isComplete
                  ? null
                  : userHasPermission('IREQ_WIRE_SELECTION_OVERRIDE', user_roles_and_permissions.permissions) && (
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          this.props.setModal('override');
                        }}
                      >
                        Override
                      </button>
                    )}
              </div>
            );
          }}
        </UserPermissionsContext.Consumer>

        {modal && modal.header_text ? (
          <Upload
            {...modal}
            onClose={() => {
              this.setState({ modal: {} });
            }}
          />
        ) : null}

        {modal_template && modal_template.header_text ? (
          <Modal
            {...modal_template}
            preset="decision"
            confirm_btn_text="Save"
            close_btn_text="Cancel"
            onCloseButtonClick={() => {
              this.setState({ modal_template: {} });
            }}
            onConfirmButtonClick={this.onTemplateConfirm}
            theme="bpp"
          >
            <div className="template-radio">
              <span className="radio-spacing">
                <input
                  type="radio"
                  name="template"
                  value="standard"
                  onChange={this.onSetTemplate}
                  defaultChecked={this.state.modal_template.input === 'standard'}
                />{' '}
                Standard
              </span>
              <span className="radio-spacing">
                <input type="radio" name="template" value="no_4" onChange={this.onSetTemplate} defaultChecked={this.state.modal_template.input === 'no_4'} /> No
                4s
              </span>
              <span className="radio-spacing">
                <input type="radio" name="template" value="one_4" onChange={this.onSetTemplate} defaultChecked={this.state.modal_template.input === 'one_4'} />{' '}
                One 4
              </span>
              <span className="radio-spacing">
                <input
                  type="radio"
                  name="template"
                  value="universal"
                  disabled={!this.props.enabled_universal}
                  onChange={this.onSetTemplate}
                  defaultChecked={this.state.modal_template.input === 'universal'}
                />{' '}
                Universal
              </span>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

ConversionComplete.propTypes = {
  dxf_file_name_error: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dxf_file_name_error: getDxfFileNameError(state),
    ws_guide_file_name_error: getWsGuideFileNameError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDxfFileNameError: setDxfFileNameError,
      setWsGuideFileNameError: setWsGuideFileNameError,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConversionComplete);
