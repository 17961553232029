import React from 'react';
import { onZipClick } from '../../../../common/dropbox';
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

/**
 * Displays download link for Single-Tooth IDB Tray file
 * @function
 * @param {object} props - Props object
 * @param {string} file_type - File type (raw or final)
 * @returns {JSX} - JSX for component
 */
export const displaySingleToothIdbFile = (props, file_type) => {
  const files = {
    raw: props.single_tooth_idb_raw_file,
    final: props.single_tooth_idb_final_file,
  };
  return (
    <div className={!props.id.includes('-IR') ? 'single-tooth-idb__file' : ''}>
      <a href={'/' + files[file_type].upload_data} onClick={onZipClick} className="idb-link" download>
        <div className="underline-text viewable-text center-text">
          Single-Tooth IDB Tray {file_type.charAt(0).toUpperCase() + file_type.slice(1)} <i className="fa fa-file-zip-o" aria-hidden="true" />
        </div>
      </a>{' '}
      {files[file_type].upload_data.includes('_manual') && <span>(Manually Uploaded) </span>}
      {file_type === 'final' && !props.shipping_approved && (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const idbGenType = props.id.includes('-IR') ? 'IREQ' : 'CASE';
            const hasGeneratePermission = userHasPermission(`${idbGenType}_IDB_GENERATE`, user_roles_and_permissions.permissions);
            return (
              hasGeneratePermission && (
                <i
                  className="fa fa-trash"
                  onClick={() => {
                    props.setSingleToothIdbModal('remove');
                  }}
                />
              )
            );
          }}
        </UserPermissionsContext.Consumer>
      )}
    </div>
  );
};

/**
 * Renders the display for uploading a single tooth IDB file.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The rendered upload display.
 */
export const displaySingleToothIdbUpload = (props) => {
  return (
    <div>
      <button
        type="button"
        className={`btn btn-bpp-upload btn-margin-bottom${props.single_tooth_idb_raw_file ? ' margin-top-12' : ''}`}
        onClick={() => {
          props.setSingleToothIdbModal('upload');
        }}
      >
        Upload
      </button>
    </div>
  );
};

/**
 * Displays Single-Tooth IDB Tray generation success component
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - JSX for component
 */
function SingleToothIdbSuccess(props) {
  const isIreq = props.id.includes('-IR');
  const isShippingApproved = isIreq ? props.shipping_approved : false;
  return (
    <>
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const idbGenType = props.id.includes('-IR') ? 'IREQ' : 'CASE';
          const hasGeneratePermission = userHasPermission(`${idbGenType}_IDB_GENERATE`, user_roles_and_permissions.permissions);
          if (hasGeneratePermission) {
            return (
              <>
                {props.single_tooth_idb_raw_file && !isShippingApproved && <div>Generation Successful</div> && displaySingleToothIdbFile(props, 'raw')}
                {props.single_tooth_idb_final_file ? displaySingleToothIdbFile(props, 'final') : displaySingleToothIdbUpload(props)}
              </>
            );
          } else {
            if (props.single_tooth_idb_final_file) {
              return (
                <>
                  {props.single_tooth_idb_raw_file && !isShippingApproved && <div>Generation Successful</div> && displaySingleToothIdbFile(props, 'raw')}
                  {props.single_tooth_idb_final_file && displaySingleToothIdbFile(props, 'final')}
                </>
              );
            } else {
              return <div>Single-Tooth IDB Tray generation in progress...</div>;
            }
          }
        }}
      </UserPermissionsContext.Consumer>
    </>
  );
}

export default SingleToothIdbSuccess;
