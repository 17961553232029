import React from 'react';
import { isActiveAddressById } from '../../common/functions';
import { getShippingAddress, getShippingAddressName } from '../../common/helpers';
import './select_address.scss';
const MAX_SIZE_OF_SELECT = 8;
const MAX_ACTIVE_ADDRESS = 4;
/**
 * Used to change the shipping address selection. *
 *
 * @component
 * @alias SelectShippingAddress
 * @category BPP
 */

function SelectShippingAddress(props) {
  const active_address_length = props.shipping_addresses
    ? props.shipping_addresses.filter(function (a) {
        return a.is_active;
      }).length
    : 0;
  //This controls the UI, bootstrap uses the data-size attr to render larger or smaller selects. See https://developer.snapappointments.com/bootstrap-select/examples/#standard-select-boxes
  const selectSize = active_address_length < MAX_ACTIVE_ADDRESS ? active_address_length * 2 : MAX_SIZE_OF_SELECT;
  return (
    <React.Fragment>
      <div className="form-group select-address-m">
        <label className="inline-label">Shipping Address:</label>
        <div className="inline-input">
          <select
            data-width="341px"
            data-divider="true"
            data-dropup-auto="true"
            data-size={selectSize}
            data-border-radius="5px"
            className={
              active_address_length < MAX_ACTIVE_ADDRESS
                ? `select-address-modal select-address-modal-${active_address_length}`
                : 'select-address-modal select-address-modal-4'
            }
            defaultValue={props.selected_address_id}
            onChange={props.onSelectedAddress}
          >
            {!isActiveAddressById(props.shipping_addresses, props.selected_address_id) ? (
              <option
                data-content={'<strong> No Active Address Selected </strong><br/> --------------------------------------------------------'}
                value={props.selected_address_id}
              >
                --------------------------------------------------------
              </option>
            ) : null}

            {props.shipping_addresses
              .filter(function (a) {
                return a.is_active;
              })
              .map((address, address_index) => {
                return (
                  <option
                    data-content={'<strong>' + getShippingAddressName(address) + '</strong><br>' + getShippingAddress(address)}
                    key={address_index}
                    value={address.id}
                  >
                    {getShippingAddress(address)}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SelectShippingAddress;
