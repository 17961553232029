import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { removeEmoji } from '../../common/functions';

/**
 * A common rich text editor used to create notes and comments
 * @component
 * @category Common
 */
class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.value,
      editing_txt: '',
      editing: false,
      focus_called: false,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['link'],
          // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ],
      },
      formats: ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'link'],
    }; // You can also pass a Quill Delta here
    this.handleChange = this.handleChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null;
  }

  componentDidMount() {
    this.attachQuillRefs();
    this.setState({
      text: this.props.value,
    });
  }

  componentDidUpdate() {
    this.attachQuillRefs();
    if (this.props.value !== this.state.text) {
      this.setState({
        text: this.props.value,
      });
    }

    if (!this.props.edit_in_progress && this.state.editing && !this.state.focus_called) {
      this.onBlurCalled();
    }
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
    this.props.onLoad && this.props.onLoad(this.quillRef);
  };

  /**
   * Handles the change on the react quill side and calls the handler in the prop
   * @param {String} content - Contains the html version of the content
   * @param {Object} delta - Contains the change made since the last instance of react quill
   * @param {String} source - Identify who made the change, default is user
   * @param {Object} editor - Contains the editor object and its helper functions
   * @function
   */
  handleChange(content, delta, source, editor) {
    var limit = this.props.max_length ? this.props.max_length : 2500;
    var quill = this.quillRef;
    if (quill) {
      quill.on('text-change', function (delta, old, source) {
        if (quill.getLength() > limit) {
          quill.deleteText(limit, quill.getLength());
        }

        if (
          (delta.ops[0]['insert'] === ' ' && old.ops[0]['insert'] === '\n') ||
          (delta.ops[0]['insert'] === ' ' && quill.getLength() === 2) ||
          (quill.getText().length === 2 && quill.getText()[0] === ' ')
        ) {
          quill.deleteText(0, quill.getLength() - 1);
        }
        if (delta.ops[0]['insert'] === '\n' && old.ops[0]['insert'] === '\n') {
          quill.setContents('\n');
        }
      });
    }

    this.setState({
      editing: true,
      editing_txt: removeEmoji(content),
      focus_called: false,
    });

    this.props.handleChange(content, delta, source, editor);
  }

  onFocus(event) {
    this.setState({
      editing: true,
      editing_txt: this.state.text,
      focus_called: true,
    });

    this.props && this.props.onFocus && this.props.onFocus();
  }

  onBlurCalled() {
    const editing_txt = '';
    const text = this.state.text;
    this.setState({
      editing: false,
      text: text,
      editing_txt: editing_txt,
      focus_called: false,
    });
    var quill = this.quillRef;
    if (quill) {
      quill.blur();
    }
  }

  onBlur = (range, source) => {
    setTimeout(() => {
      var quill = this.quillRef;
      if (quill) {
        let fixRange = quill.getSelection();
        if (fixRange) {
          // get the editor instance.
          quill.setContents(quill.getContents());
          quill.setSelection(fixRange);
          quill.focus();
        }
        // it's true blur.
        else {
          this.onBlurCalled();
        }
      }
    }, 100); // 100ms is random...
  };

  render() {
    return (
      <div className={'text-editor' + (this.props.class ? ` ${this.props.class}` : '')}>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          value={this.state.editing ? this.state.editing_txt || '' : this.state.text || ''}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          theme="snow"
          modules={this.state.modules}
          formats={this.state.formats}
          readOnly={this.props.readOnly}
        />
      </div>
    );
  }
}

export default RichText;
