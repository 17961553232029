// Action Types
export const OPEN_INVALID_ADDRESS_MODAL = 'OPEN_INVALID_ADDRESS_MODAL';
export const CLOSE_INVALID_ADDRESS_MODAL = 'CLOSE_INVALID_ADDRESS_MODAL';
export const RESET_INVALID_ADDRESS_MODAL = 'RESET_INVALID_ADDRESS_MODAL';
export const SET_SELECTED_ADDRESS_ID = 'SET_SELECTED_ADDRESS_ID';
export const SET_ORIGINAL_ADDRESS_SELECTED = 'SET_ORIGINAL_ADDRESS_SELECTED';
export const OPEN_SMARTY_STREETS_ERROR_MODAL = 'OPEN_SMARTY_STREETS_ERROR_MODAL';
export const OPEN_REACTIVATION_MODAL = 'OPEN_REACTIVATION_MODAL';
export const OPEN_DEACTIVATION_MODAL = 'OPEN_DEACTIVATION_MODAL';
export const CLOSE_PRACTICE_ACTIVATION_MODAL = 'CLOSE_PRACTICE_ACTIVATION_MODAL';

// Action Creators
// -----------------------------------------------------------------------------

// Modals

/**
 * Opens invalid address modal
 * @function
 * @param {Object} original_address - Original address object
 * @param {Array} address_suggestions - Array of address suggestion objects
 * @param {Boolean} edit_address - True if editing an address, False if creating a new address
 * @return {Object} Action type, original address, address suggestions, and edit flag
 */
export function openInvalidAddressModal(original_address, address_suggestions, edit_address = false) {
  return {
    type: OPEN_INVALID_ADDRESS_MODAL,
    original_address: original_address,
    address_suggestions: address_suggestions,
    edit_address: edit_address,
  };
}

/**
 * Closes invalid address modal
 * @function
 * @return {Object} Action type
 */
export function closeInvalidAddressModal(reset = true) {
  return (dispatch) => {
    dispatch({
      type: CLOSE_INVALID_ADDRESS_MODAL,
    });
    if (reset) {
      dispatch({
        type: RESET_INVALID_ADDRESS_MODAL,
      });
    }
  };
}

/**
 * Opens modal when there is a SmartyStreets error
 * @function
 * @return {Object} Action type
 */
export function openSmartyStreetsErrorModal() {
  return {
    type: OPEN_SMARTY_STREETS_ERROR_MODAL,
  };
}

/**
 * Handles event when user selects a suggested address from invalid address modal
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type, selected address id
 */
export function onSuggestedAddressChange(event) {
  return {
    type: SET_SELECTED_ADDRESS_ID,
    selected_address_id: event.target.value,
  };
}

/**
 * Handles event when user selects the original address from invalid address modal
 * @function
 * @return {Object} Action type
 */
export function onOriginalAddressChange() {
  return {
    type: SET_ORIGINAL_ADDRESS_SELECTED,
  };
}

/**
 * Handles event when user opens reactivation/deactivation modal
 * @function
 * @param {Boolean} - Indicator for reactivating or deactivating practice
 * @return {Object} Action type
 */
export function openPracticeActivationModal(active) {
  return {
    type: active ? OPEN_REACTIVATION_MODAL : OPEN_DEACTIVATION_MODAL,
  };
}

/**
 * Handles event when user closes reactivation/deactivation modal
 * @function
 * @return {Object} Dispatch
 */
export function closePracticeActivationModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_PRACTICE_ACTIVATION_MODAL,
    });
    dispatch({
      type: RESET_INVALID_ADDRESS_MODAL,
    });
  };
}

/**
 * Filters roles that can be assigned to users
 * @function
 * @param {List} user_roles list of all roles
 * @return {List} list of filtered roles
 */
export function filterUserRoles(user_roles) {
  if (user_roles) {
    user_roles = user_roles.filter((role) => {
      const hidden_roles = ['admin', 'doctor', 'administrator'];
      role = role.toLowerCase();
      return !(hidden_roles.includes(role) || role.includes('dso'));
    });
  }
  return user_roles;
}
