import React, { Component } from 'react';
import _ from 'lodash';
import { configLightBox } from '../../common/functions';
// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

import Button from '../components/buttons/button';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';

class Submit extends Component {
  render() {
    let files = [];
    const photoUpload = this.props.photoUpload || [];
    const xrayUpload = this.props.xrayUpload || [];

    // iTero integration
    const scans = [];
    if (this.props.uploadMethod === 'itero' && this.props.selectedIteroScanRecords.length) {
      scans.push(...this.props.selectedIteroScanRecords);
    } else if (this.props.scanUpload.length) {
      scans.push(...this.props.scanUpload);
    }

    files = _.concat(photoUpload, xrayUpload, scans);

    const thumbnailComponentProps = {
      files: files,
      images: configLightBox(files),
      multinail: `_${this.props.case_id}`,
      rebuildOnFilePropsChange: true,
      isInSubmitSummary: true,
    };

    const SummaryComponent = this.props.summaryComponent;
    return (
      <div>
        {SummaryComponent ? <SummaryComponent {...this.props} {...this.props.summaryProps} thumbnailComponentProps={thumbnailComponentProps} /> : null}
        <p>Click "Submit" to complete the upload process</p>
        {!SummaryComponent ? <ThumbnailURLInterface {...thumbnailComponentProps} /> : null}
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const disabled = !userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
            return (
              <Button data-position={'submit'} data-description={'Submit'} onClick={this.props.onSubmitClick} disabled={disabled}>
                Submit
              </Button>
            );
          }}
        </UserPermissionsContext.Consumer>
      </div>
    );
  }
}

export default Submit;
