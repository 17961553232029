import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays selection bar right arrow
 * @component
 */
function SelectionBarPageRight(props) {
  const exist = props.scroll_area < Math.ceil(props.scroll_options.length / props.scroll_items) - 1;

  return (
    <div
      className={exist ? 'selection-bar-top-page-right fade-in' : 'selection-bar-top-page-right fade-out'}
      onClick={exist ? props.onClick : null}
      onDoubleClick={exist ? props.onClick : null}
    >
      <i className={!exist ? 'invisible' : 'fa fa-chevron-right'} aria-hidden="true" />
    </div>
  );
}

SelectionBarPageRight.propTypes = {
  scroll_area: PropTypes.number.isRequired,
  scroll_items: PropTypes.number.isRequired,
  scroll_options: PropTypes.array.isRequired,
};

export default SelectionBarPageRight;
