import './validate_referral_code.scss';
import React, { Component } from 'react';

/**
 * This component is used to validate a referral code data
 * @component
 * @alias DoctorValidateReferralCode
 * @category IPP
 */
class ValidateReferralCode extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 div-validate-referral-code">
            <label className="label-validate-referral-code">Patient Referral Code:</label>
            <input
              id="referralCode"
              type="text"
              className="form-control input-validate-referral-code"
              onChange={this.props.onInputChange_ReferralCode}
              value={this.props.editPatientReferralCode}
              onFocus={this.props.onReferralCodeFocus}
              onBlur={this.onInputBlur_ReferralCode}
              maxLength="30"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ValidateReferralCode;
