import React from 'react';
import PropTypes from 'prop-types';

const stepTranslations = {
  first: 'Malocclusion',
  last: 'Setup',
};

function translateStep(props, step) {
  if (props.isSmileSimulationSetup && step === 'last') return 'Smile Simulation';
  return stepTranslations[step] ? stepTranslations[step] : step;
}

/**
 * Returns the class names for the step
 * @function
 * @param {Object} props - Properties
 * @param {String} step - The step
 */
function getClassName(props, step) {
  if (props.superimpose) {
    if (step === 'first') {
      return props.opacity > 50 ? ' super-blue' : '';
    }
    if (step === 'last') {
      return props.opacity < 50 ? ' super-purple' : '';
    }
  }

  return props.active === step ? ' active' : '';
}

/**
 * Displays the step selection
 * @component
 */
function StepSelection(props) {
  const smileSimulationDisplay = props.isSmileSimulationSetup && props.split ? 'none' : '';
  return (
    <div className="step-selection-container" style={{ display: smileSimulationDisplay }}>
      {props.steps.map((step) => {
        const classNames = `step-selection-item${getClassName(props, step)}`;
        return (
          <div key={step} className={classNames}>
            <div onClick={() => props.onClick(step)}>{translateStep(props, step)}</div>
          </div>
        );
      })}
    </div>
  );
}

StepSelection.propTypes = {
  active: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  opacity: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired,
  superimpose: PropTypes.bool.isRequired,
};

export default StepSelection;
