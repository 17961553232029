import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import { convertDate } from '../../common/functions';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import Button from '../components/buttons/button';
/**
 * Display the correct read value
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorDisplayValue
 * @category IPP
 */
function DisplayValue(props) {
  function getInputListLabel(question) {
    let new_value = _.cloneDeep(question.value);

    if (question.input_list) {
      for (let i = 0; i < question.input_list.length; i++) {
        if (question.input_list[i]['value'] === question.value) {
          new_value = question.input_list[i]['label'];
          break;
        }
      }
    }

    return new_value;
  }

  if (props && props.question) {
    const input_type = props.question.input_type;
    let value = props.question.value ? props.question.value : 'N/A';
    let value_other = props.question.value_other ? props.question.value_other : '';
    let value_referral = props.question.value_referral ? props.question.value_referral : '';

    if (input_type === 'checkbox') {
      value = props.question.value ? 'Yes' : 'No';
    } else if (input_type === 'date') {
      value = props.question.value ? convertDate(props.question.value) : 'N/A';
    } else if (input_type === 'dropdown' && props.question.input_data_type === 'boolean') {
      value =
        props.question.value === '' || props.question.value === false || props.question.value === undefined || props.question.value === null
          ? 'No'
          : props.question.value === true
          ? 'Yes'
          : props.question.value;
    } else if (input_type === 'radio') {
      value = getInputListLabel(props.question);
    }

    if (value_other) {
      value = value !== 'N/A' ? value + ', Other: ' + value_other : 'Other: ' + value_other;
    }

    if (value_referral) {
      value = value !== 'N/A' ? value + ', Referral: ' + value_referral : 'Referral: ' + value_referral;
    }

    return <div className="prospect-display">{value}</div>;
  }

  return null;
}
/**
 * Display the text field edit abilities
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorTextField
 * @category IPP
 */
function TextField(props) {
  return (
    <input
      type="text"
      className={
        props.question.error && props.warning ? 'form-control prospect-input-field-text prospect-input-field-error' : 'form-control prospect-input-field-text'
      }
      value={props.question.value ? props.question.value : ''}
      data-field_mapping={props.question.field_mapping}
      placeholder={props.placeholder}
      maxLength={props.question.max}
      onChange={props.onChange}
      disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
      onBlur={props.onBlur}
      tabIndex={props.question.tabIndex}
    />
  );
}
/**
 * Display the checkbox field edit abilities
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorCheckBoxField
 * @category IPP
 */
function CheckBoxField(props) {
  return (
    <input
      type="checkbox"
      className="prospect-input-field-checkbox"
      data-field_mapping={props.question.field_mapping}
      onChange={props.onChange}
      checked={props.question.value ? props.question.value : false}
      disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
      tabIndex={props.question.tabIndex}
    />
  );
}
/**
 * Display the text area field edit abilities
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorTextAreaField
 * @category IPP
 */
function TextAreaField(props) {
  return (
    <React.Fragment>
      {props.other && props.value === 'Other' ? <div className="prospect-input-other">Other:</div> : null}
      <textarea
        className="form-control prospect-input-field-textarea"
        value={props.question.value ? props.question.value : ''}
        data-field_mapping={props.question.field_mapping}
        maxLength={props.question.max}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
        cols="22"
        tabIndex={props.question.tabIndex}
      />
    </React.Fragment>
  );
}
/**
 * Display the date field that is editabled
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorDateField
 * @category IPP
 */
function DateField(props) {
  return (
    <input
      type="date"
      className={
        props.question.error && props.warning ? 'form-control prospect-input-field-date prospect-input-field-error' : 'form-control prospect-input-field-date'
      }
      value={props.question.value ? Moment(props.question.value).format('YYYY-MM-DD') : ''}
      data-field_mapping={props.question.field_mapping}
      max={props.question.max}
      min={props.question.min}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
      tabIndex={props.question.tabIndex}
    />
  );
}
/**
 * Display the radio field that is editabled
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorRadioField
 * @category IPP
 */
function RadioField(props) {
  return props.question.input_list.map((radio, index) => {
    return (
      <div className="prospect-input-field-radio" key={index}>
        <input
          type="radio"
          name={props.field_mapping}
          className="prospect-input-field-radio-button"
          value={radio.value}
          checked={radio.value === props.question.value || (props.question.value && props.question.value[0] && radio.value === props.question.value[0])}
          data-field_mapping={props.question.field_mapping}
          onChange={props.onChange}
          disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
          tabIndex={radio.tabIndex}
        />
        <label className="prospect-input-field-radio-label" onClick={props.onChange} data-field_mapping={props.question.field_mapping} data-value={radio.value}>
          {radio.label}
        </label>
      </div>
    );
  });
}
/**
 * Display the dropdown field that is editabled
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorDropdownField
 * @category IPP
 */
function DropdownField(props) {
  const isOtherSelected = (value) => {
    if (value && value.length > 0 && value.indexOf('Other') >= 0) {
      return 'Other';
    }
    return value;
  };

  return (
    <div className="prospect-input-field-dropdown">
      <select
        className="prospect-input-field-dropdown-select"
        value={props.question.value ? isOtherSelected(props.question.value) : ''}
        onChange={props.onChange}
        data-field_mapping={props.question.field_mapping}
        disabled={!props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled}
        tabIndex={props.question.tabIndex}
      >
        <option value="">- Select Option -</option>
        {props.question.input_list.map((list, index) => {
          return (
            <option key={index} value={`${list.value}`}>
              {list.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
/**
 * Display the Multiply checkbox option field
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorMultiCheckboxField
 * @category IPP
 */
function MultiCheckboxFieldEdit(props) {
  let available_count = props.question.max - 'Other: '.length;
  const disabled = props.disabled;
  if (props.question.value_other.length > 0) {
    props.question.value = props.question.value + ', Other';
  }
  return (
    <div className="prospect-input-field-multi">
      {props.question.input_list.map((multi_field, index) => {
        available_count = available_count - multi_field.value.length;
        return (
          <div className="" key={index}>
            <input
              name={props.question.field_mapping}
              type="checkbox"
              className="prospect-input-field-checkbox"
              data-field_mapping={props.question.field_mapping}
              data-type="multi-checkbox"
              data-value={multi_field.value}
              onChange={props.onChange}
              checked={props.question.value ? _.intersection([multi_field.value], props.question.value.split(', ')).length > 0 : false}
              disabled={disabled}
            />
            <label
              className={disabled ? 'prospect-input-field-radioedit-label-disabled' : 'prospect-input-field-radioedit-label'}
              disabled={disabled}
              onClick={!disabled ? props.onChange : null}
              data-field_mapping={props.question.field_mapping}
              data-value={multi_field.value}
              data-type="multi-checkbox"
            >
              {multi_field.label}
            </label>
          </div>
        );
      })}
      {props.question.value && props.question.value.includes('Other') ? (
        <TextAreaField
          question={{
            value: props.question.value_other,
            field_mapping: props.question.field_mapping,
            max: available_count,
            editable: props.question.editable,
            editable_after_converted: props.question.editable_after_converted,
          }}
          onChange={props.onOtherTextChange}
          onBlur={props.onOtherTextBlur}
          is_converted={props.is_converted}
          dependency_met={props.dependency_met}
        />
      ) : null}
    </div>
  );
}
/**
 * Display the Multiply checkbox option field
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorMultiCheckboxField
 * @category IPP
 */
function MultiCheckboxField(props) {
  let available_count = props.question.max - 'Other: '.length;
  return (
    <div className="prospect-input-field-multi">
      {props.question.input_list.map((multi_field, index) => {
        available_count = available_count - multi_field.value.length;
        return (
          <>
            <div key={props.question.field_mapping + '_' + index}>
              <input
                name={props.question.field_mapping}
                type="checkbox"
                className="prospect-input-field-checkbox"
                data-field_mapping={props.question.field_mapping}
                data-type="multi-checkbox"
                data-value={multi_field.value}
                onChange={props.onChange}
                checked={props.question.value ? _.intersection([multi_field.value], props.question.value.split(', ')).length > 0 : false}
                disabled={
                  !props.question.editable || (props.is_converted && !props.question.editable_after_converted) || !props.dependency_met || props.disabled
                }
                tabIndex={multi_field.tabIndex}
              />
              <label
                className="prospect-input-field-radio-label"
                onClick={props.onChange}
                data-field_mapping={props.question.field_mapping}
                data-value={multi_field.value}
                data-type="multi-checkbox"
              >
                {multi_field.label}
              </label>
            </div>
            {props.question.value &&
            props.question.value.includes('Other') &&
            multi_field.value === 'Other' &&
            _.intersection([multi_field.value], props.question.value.split(', ')).length > 0 ? (
              <TextAreaField
                other={true}
                question={{
                  value: props.question.value_other,
                  field_mapping: props.question.field_mapping,
                  max: available_count,
                  editable: props.question.editable,
                  editable_after_converted: props.question.editable_after_converted,
                }}
                onChange={props.onOtherTextChange}
                onBlur={props.onOtherTextBlur}
                is_converted={props.is_converted}
                dependency_met={props.dependency_met}
                key={props.question.field_mapping + '_other'}
              />
            ) : null}
            {props.question.value &&
            props.question.value.includes('Referral') &&
            multi_field.value === 'Referral' &&
            _.intersection([multi_field.value], props.question.value.split(', ')).length > 0 ? (
              <TextField
                question={{
                  value: props.question.value_referral,
                  field_mapping: props.question.field_mapping,
                  max: 75,
                  editable: props.question.editable,
                  editable_after_converted: props.question.editable_after_converted,
                }}
                onChange={props.onReferralTextChange}
                onBlur={props.onOtherTextBlur}
                is_converted={props.is_converted}
                dependency_met={props.dependency_met}
                placeholder={'Referral Name'}
                key={props.question.field_mapping + '_referral'}
              />
            ) : null}
          </>
        );
      })}
    </div>
  );
}

/**
 * Contains the Prospect Details
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorActionBar
 * @category IPP
 */
function ActionBarEdit(props) {
  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) && !props.is_a_case && !props.is_converted ? (
          <div className="prospect-detail-footer">
            <>
              {!props.is_lost ? (
                <>
                  {props.is_held ? (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          props.displayModal('modal_unhold');
                        }}
                        disabled={props.disabled}
                      >
                        Reverse Hold
                      </Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          props.displayModal('modal_hold');
                        }}
                        disabled={props.is_editing || props.disabled}
                      >
                        Hold
                      </Button>
                    </React.Fragment>
                  )}
                </>
              ) : null}
              {props.is_lost ? (
                props.prospect_data.referred_lost_reason !== 'Automated Match Back' &&
                props.prospect_data.referred_lost_reason !== 'Transferred to Another Provider' ? (
                  <Button
                    onClick={() => {
                      props.displayModal('modal_reverse_lost');
                    }}
                    disabled={props.disabled}
                  >
                    Reverse Prospect Lost
                  </Button>
                ) : null
              ) : (
                <Button
                  onClick={() => {
                    props.displayModal('modal_referral');
                  }}
                  disabled={props.is_editing || props.disabled}
                >
                  {' '}
                  Prospect Lost
                </Button>
              )}
            </>
          </div>
        ) : null;
      }}
    </UserPermissionsContext.Consumer>
  );
}

/**
 * Contains the Prospect Details
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorActionBar
 * @category IPP
 */
function ActionBar(props) {
  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) ? (
          <div className="prospect-detail-footer-edit">
            {props.is_editing ? (
              <>
                <Button onClick={props.onSaveClick}>Save</Button>
                <Button theme="secondary" onClick={props.onCancelClick}>
                  Cancel
                </Button>
              </>
            ) : null}
          </div>
        ) : null;
      }}
    </UserPermissionsContext.Consumer>
  );
}
/**
 * Creating a new prospect
 * @param {Object} props - Props related to the incoming input field
 * @alias DoctorAddBar
 * @category IPP
 */
function AddBar(props) {
  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) ? (
          <div className="prospect-detail-footer">
            <Button onClick={props.onCreateClick} onDoubleClick={props.onCreateClick}>
              Create
            </Button>
            <Button theme="secondary" onClick={props.onCancelClick}>
              Cancel
            </Button>
          </div>
        ) : null;
      }}
    </UserPermissionsContext.Consumer>
  );
}

export {
  DisplayValue,
  AddBar,
  ActionBar,
  ActionBarEdit,
  MultiCheckboxField,
  MultiCheckboxFieldEdit,
  DropdownField,
  RadioField,
  DateField,
  TextAreaField,
  CheckBoxField,
  TextField,
};
