import React from 'react';

/**
 * Display the case list table loading placeholder
 * @component
 * @category IPP
 */
function DivTableShimmer(props) {
  const ignore_last_col = props.combineLastColumn;
  const row_size = props.rowSize;
  const columns = props.table_data.filter((data) => data.display);

  const combineWidthPrecentage = () => {
    const col_width =
      columns.length >= 3
        ? 100 -
          columns.reduce((past, now, index) => {
            return index <= columns.length - 3 ? past + parseInt(now.col_width) : past;
          }, 0)
        : 0;

    return `${col_width}%`;
  };

  const alternateWidth = (index) => {
    const col_width = index % 2 === 0 ? 60 : 80;
    return `${col_width}%`;
  };

  return [...Array(row_size)].map((e, i) => {
    return (
      <div key={i} className="div-table--content">
        {columns &&
          columns.map((heading, heading_index) => {
            const use_alt_width = ignore_last_col && columns.length - 2 === heading_index;

            if (columns.length - 1 === heading_index && ignore_last_col) {
              return null;
            }

            return (
              <div
                key={heading_index}
                className="div-table--item div-table--item-placeholder hide-on-mobile"
                style={{ width: use_alt_width ? combineWidthPrecentage() : heading.col_width }}
              >
                <div className="div-table--item-placeholder-animation" style={{ width: alternateWidth(i) }}></div>
              </div>
            );
          })}
      </div>
    );
  });
}

export default DivTableShimmer;
