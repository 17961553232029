import React from 'react';
import { connect } from 'react-redux';

import PatientInfoHeading from './patient_info_heading';
import PatientInfoHeadingVertical from './patient_info_heading_vertical';

import { getCaseDetails } from '../../../../../../redux/reducers/common/common_case_details';
import { removeCaseIdInitialNumber } from '../../../../../../common/functions';

/**
 * The container the patient info on the sidebar
 * @component
 * @alias SetupViewerPatientInfo
 */
function PatientInfo(props) {
  const doctor_name = getDoctorName(props.case_details);
  const patient_name = getPatientName(props.case_details);
  const case_id = getCaseID(props.case_details);

  return (
    <>
    <div className={props.collapse ? "": "patient-info-bar-top"} />
    <div className="patient-info">
      <PatientInfoHeading title="Doctor" content={doctor_name} collapse={props.collapse} />
      <PatientInfoHeading title="Patient" content={patient_name} collapse={props.collapse} exclude={true}/>
      <PatientInfoHeading title="Case ID" content={case_id} collapse={props.collapse} />
      <div className="patient-info-bar" />
      <PatientInfoHeadingVertical content={patient_name} collapse={props.collapse} />
    </div>
    </>
  );
}

/**
 * Retrieves the Doctor's name from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the Doctor's name where applicable
 */
function getDoctorName(case_details) {
  const doctor = case_details && case_details.doctor;
  if (doctor) {
    return `${doctor.first_name} ${doctor.last_name}`;
  } else {
    return '';
  }
}

/**
 * Retrieves the Patient name from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the Patient name where applicable
 */
function getPatientName(case_details) {
  const patient = case_details && case_details.patient;
  if (patient) {
    return `${patient.first_name} ${patient.last_name}`;
  } else {
    return '';
  }
}

/**
 * Retrieves the case id from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the case id name where applicable
 */
function getCaseID(case_details) {
  const case_id = case_details && case_details.case_id;
  if (case_id) {
    return removeCaseIdInitialNumber(case_id);
  } else {
    return '';
  }
}


const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
  };
};

export default connect(mapStateToProps)(PatientInfo);
