import './work_order_reference.scss';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';

class WorkOrderRef extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref_no: '',
      edit_ref_no: '',
      edit_in_progress: false,
    };
    this.onFocusRefNo.bind(this);
  }

  componentDidMount() {
    this.setState({ ref_no: this.props.ref_no ? this.props.ref_no : '' });
  }

  componentWillUnmount() {
    this.onEditRefNoDiscard();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  onFocusRefNo = (event) => {
    this.setState({
      edit_ref_no: this.state.ref_no,
      edit_in_progress: true,
    });
    if (this.props.edit_in_progress) {
      this.props.edit_in_progress(true);
    }
  };
  /**
   * Sets the state value of the Ref no
   * @param {Object} event - event of the DOM
   */
  onChangeRefNo = (event) => {
    let value = event.target.value.trim();

    this.setState({
      edit_ref_no: value.replace(/[^\w\s]/gi, ''),
    });
  };

  onEditRefNoConfirm = (event) => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    let ref_no = this.state.edit_ref_no;
    if (ref_no || !this.props.ref_no_required) {
      ref_no = ref_no.trimRight();
      if (this.props.ireqdetails_id) {
        this.props.update_wo(ref_no, this.props.ireqdetails_id);
      } else {
        this.props.update_wo(ref_no);
      }
      this.setState({
        ref_no: ref_no,
        edit_in_progress: false,
        missing_error: false,
        edit_ref_no: '',
      });
    } else {
      this.setState({
        missing_error: true,
      });
    }
  };

  onEditRefNoDiscard = (event) => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    this.setState({
      edit_in_progress: false,
      edit_ref_no: '',
      missing_error: false,
    });
    if (this.props.edit_in_progress) {
      this.props.edit_in_progress(false);
    }
  };

  onChangeRefNoKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={'work-ref-order ' + (this.props.classOverwrite ? this.props.classOverwrite : '')}>
          {this.props.label ? <div className="bold-text work-ref-label">{this.props.label}</div> : null}
          <div className="work-ref-input">
            {this.state.edit_in_progress === true ? (
              <input
                autoFocus
                name="ref_no"
                className={
                  'form-control work-order-ref-textbox ' +
                  (this.state.missing_error || this.state.limit_error ? 'profile-edit-input-error ' : '') +
                  (this.props.inputClassOverwrite ? this.props.inputClassOverwrite : '')
                }
                value={this.state.edit_ref_no}
                onChange={this.onChangeRefNo}
                onKeyDown={this.onChangeRefNoKeyPress}
                maxLength="20"
                placeholder={this.props.placeholder ? this.props.placeholder : 'WO1234567'}
              />
            ) : (
              <UserPermissionsContext.Consumer>
                {(user_roles_and_permissions) => {
                  return userHasPermission(this.props.permission, user_roles_and_permissions.permissions) &&
                    !this.props.disabled &&
                    this.props.status_code !== 'STATUS_CANCEL' &&
                    this.props.status_code !== 'STATUS_HOLD' &&
                    this.props.status_code !== 'Doctor Provide Clarification' &&
                    this.props.status_code !== 'Quality Control Review' &&
                    !this.props.shipping_approved_ind ? (
                    this.props.ireqdetails_id ? (
                      !this.props.edit_state && !this.props.shipping_approved_ind ? (
                        <div className="wo-ref-edit-section wo-ref-edit-window" onClick={this.onFocusRefNo} data-mode="ref_no" data-value={this.state.ref_no}>
                          <span>{this.state.ref_no}</span>
                        </div>
                      ) : (
                        <div className="wo-ref-edit-section wo-ref-edit-window-disabled" data-mode="ref_no" data-value={this.state.ref_no}>
                          <span>{this.state.ref_no}</span>
                        </div>
                      )
                    ) : (
                      <input
                        id="input-ref-no"
                        name="ref_no"
                        className={
                          'form-control work-order-ref-textbox ' +
                          (this.state.missing_error || this.state.limit_error ? 'profile-edit-input-error' : '') +
                          (this.props.inputClassOverwrite ? this.props.inputClassOverwrite : '')
                        }
                        value={this.state.ref_no}
                        onFocus={this.onFocusRefNo}
                        onChange={this.onChangeRefNo}
                        onKeyDown={this.onChangeRefNoKeyPress}
                        maxLength="20"
                        placeholder={this.props.placeholder ? this.props.placeholder : 'WO1234567'}
                      />
                    )
                  ) : this.state.ref_no ? (
                    <div className="unassigned-width">{this.state.ref_no}</div>
                  ) : (
                    <div className="unassigned-width">N/A</div>
                  );
                }}
              </UserPermissionsContext.Consumer>
            )}
          </div>

          <div className="work-ref-button">
            {this.state.edit_in_progress === true ? (
              <React.Fragment>
                <div
                  id="edit-confirm"
                  className={'btn btn-light btn-next ' + (this.state.in_progress ? 'btn-disabled' : '')}
                  onClick={this.onEditRefNoConfirm}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Save"
                >
                  <i className="fa fa-check" aria-hidden="true" />
                </div>
                <div
                  id="edit-discard"
                  className={'btn btn-light btn-next ' + (this.state.in_progress ? 'btn-disabled' : '')}
                  onClick={this.onEditRefNoDiscard}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Cancel"
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(WorkOrderRef);
