import React, { Component } from 'react';

class BackButton extends Component {
  render() {
    return (
      <div className="submit__back" data-description={this.props.description} data-position={this.props.position} onClick={this.props.onButtonClick}>
        <i className="fa fa-chevron-left" aria-hidden="true" />
        <p className="next-back-buttons">Back</p>
      </div>
    );
  }
}

export default BackButton;
