import './prospect_details.scss';

import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import { dateToFormatted, displayPSTDateTimeStamp } from '../../common/date';
import { Exc } from '../../doctor/assets/icons';
import {
  getDoctorIdFromPath,
  getRootPath,
  firstLetterCapital,
  modalClear,
  convertDate,
  setTokenHeader,
  formatPhoneNumber,
  removeDuplicatesFromString,
  removeEmoji,
} from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { Helmet } from 'react-helmet';
import { onReloadPage } from '../../common/helpers';
import { prospect_questions } from './prospect_config';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { withRouter, Prompt } from 'react-router-dom';
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import CreateEditProspect from './create_edit_prospect';
import CreatePatientNotes from '../patient_notes/create_patient_notes';
import DisplayProspectNotes from './display_prospect_notes';
import LockedIcon from '../../components/custom_lightbox/components/LockedIcon';
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import PatientInfo from './patient_info';
import ProspectProgress from './prospect_progress';
import {
  ActionBarEdit,
  CheckBoxField,
  DateField,
  DisplayValue,
  DropdownField,
  MultiCheckboxFieldEdit,
  RadioField,
  TextAreaField,
  TextField,
} from './helper_components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { Application, DatePicker } from 'react-rainbow-components';

//TODO: Move this to a more global location
const theme = {
  rainbow: {
    palette: {
      brand: '#489599',
    },
  },
};

/**
 * Contains the Prospect Details
 * @component
 * @alias DoctorProspectDetails
 * @category IPP
 */
class ProspectDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      edit_mode: false,
      add_mode: this.props.add_mode ? this.props.add_mode : false,
      doctor_id: '',
      draft_id: '',
      questions: [],
      prospect_data: {},
      prospect_id: '',
      prospect_status: '',
      modal_convert: false,
      modal_hold: false,
      modal_referral: false,
      modal_unhold: false,
      modal_status_comment: '',
      modal_dropdown_value: '',
      refresh: false,
      action: false,
      warning: false,
      warning_title: '',
      status_friendly_name: {
        Active: 'Schedule Consultation',
        'Complete Consultation': 'Complete Consultation',
        'Convert to Case': 'Begin Submission',
        Hold: 'On Hold',
        'Converted to Draft': 'Resume Submission',
        'Converted to Case': 'Submitted',
        'Prospect Lost': 'Prospect Lost',
      },
      prospect_notes: false,
      notes_id: '',
      notes_delete_modal: false,
      notes_subject: '',
      notes_note_text: '',
      notes_refresh: false,
      notes_content: false,
      showUnsaveModal: false,
      edit_in_progress: false,
    };
  }

  componentDidMount() {
    this.loadInformation();
  }

  loadInformation = () => {
    setTokenHeader();
    const that = this;
    const prospect_id = this.getProspectIdFromURL();
    const doctor_id = getDoctorIdFromPath(that.props.history.location.pathname);
    const questions = _.orderBy(prospect_questions, ['sort_order']);

    if (this.state.add_mode) {
      that.setState({
        loading: false,
        doctor_id: doctor_id,
        questions: that.attachValueToQuestion(questions, []),
        edit_mode: true,
        edit_in_progress: true,
      });
    } else {
      Axios.get(`/apiv3/prospect/${prospect_id}?doctor_id=${doctor_id}`)
        .then(function (res) {
          const prospect_data = res && res.data && res.data.prospects && res.data.prospects.length > 0 ? res.data.prospects[0] : [];
          const prospect_status = res && res.data && res.data.prospectstatus && res.data.prospectstatus.length > 0 ? res.data.prospectstatus[0] : [];
          const prospect_statuses = res && res.data && res.data.allprospectstatus && res.data.allprospectstatus.length > 0 ? res.data.allprospectstatus : [];
          const prospect_notes = res && res.data && res.data.prospectnotes && res.data.prospectnotes.length > 0 ? res.data.prospectnotes : [];
          const draft_id = res && res.data && res.data.draft_id ? res.data.draft_id : '';
          const case_id = res && res.data && res.data.case_id ? res.data.case_id : '';

          if (prospect_data.length === 0) {
            that.setState({
              error: true,
            });
          } else {
            that.setState({
              loading: false,
              doctor_id: doctor_id,
              draft_id: draft_id,
              case_id: case_id,
              questions: that.attachValueToQuestion(questions, prospect_data),
              prospect_data: prospect_data,
              prospect_id: prospect_id,
              prospect_status: prospect_status,
              prospect_statuses: prospect_statuses,
              notes: prospect_notes,
              edit_mode: prospect_id ? false : true,
              failure_reason: '',
              failed_to_create: false,
              edit_in_progress: false,
            });
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };
  /**
   * Get ths Prospect Id from the URL
   * @function
   */
  getProspectIdFromURL = () => {
    const pathname = this.props.history && this.props.history.location && this.props.history.location.pathname ? this.props.history.location.pathname : '';
    const last_slash_index = pathname.lastIndexOf('/');
    return last_slash_index >= 0 ? parseInt(pathname.substr(last_slash_index + 1)) : '';
  };
  /**
   * Attach the response value to the question by looking at the database field (field_mapping)
   * @function
   */
  attachValueToQuestion = (questions, data) => {
    let copied_questions = _.cloneDeep(questions);

    for (let key in data) {
      for (let i = 0; i < copied_questions.length; i++) {
        if (copied_questions[i].field_mapping === key) {
          if (
            (copied_questions[i].field_mapping === 'ortho_tx_needed' ||
              copied_questions[i].field_mapping === 'previous_tx_details' ||
              copied_questions[i].field_mapping === 'advertising_source') &&
            data[key]
          ) {
            let values = data[key].split(', ');
            let mapped_value = copied_questions[i].input_list.map((list) => {
              return list.value;
            });

            // Clean bad data
            values = values.filter((value) => {
              return value.trim() !== '';
            });

            // Capture "other" data
            let other_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Other:');
            });

            // Capture "referral" data
            let referral_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Referral:');
            });

            // Capture recognized_values
            let recognized_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length > 0;
            });

            if (other_values.length >= 1) {
              let other_value_key_index = 0;

              for (let i = 0; i < other_values.length; i++) {
                if (other_values[i].indexOf('Other:') >= 0) {
                  other_value_key_index = i;
                  other_values[i] = other_values[i].replace('Other:', '').trim();
                  break;
                }
              }

              if (other_value_key_index > 0) {
                let other_values_combined = other_values.slice(0, other_value_key_index);

                if (other_value_key_index !== other_values.length) {
                  other_values_combined.concat(other_values.slice(other_value_key_index + 1));
                }

                other_values_combined.unshift(other_values[other_value_key_index]);
                other_values = other_values_combined;
              }
            }

            if (referral_values.length >= 1) {
              let referral_value_key_index = 0;

              for (let i = 0; i < referral_values.length; i++) {
                if (referral_values[i].indexOf('Referral:') >= 0) {
                  referral_value_key_index = i;
                  referral_values[i] = referral_values[i].replace('Referral:', '').trim();
                  break;
                }
              }

              if (referral_value_key_index > 0) {
                let referral_values_combined = referral_values.slice(0, referral_value_key_index);

                if (referral_value_key_index !== referral_values.length) {
                  referral_values_combined.concat(referral_values.slice(referral_value_key_index + 1));
                }

                referral_values_combined.unshift(referral_values[referral_value_key_index]);
                referral_values = referral_values_combined;
              }
            }
            recognized_values = recognized_values.filter(function (value, index, arr) {
              return value !== 'Other' && value !== 'Referral';
            });
            values = recognized_values.join(', ');
            let other = other_values.join(', ');
            let referral = referral_values.join(', ');

            copied_questions[i].value_other_org = _.cloneDeep(other.trim());
            copied_questions[i].value_other = _.cloneDeep(other.trim());
            copied_questions[i].value_referral = _.cloneDeep(referral.trim());
            copied_questions[i].value_org = _.cloneDeep(values);
            copied_questions[i].value = _.cloneDeep(values);
          } else {
            copied_questions[i].value_org = _.cloneDeep(data[key]);
            copied_questions[i].value = _.cloneDeep(data[key]);
          }
          break;
        }
      }
    }

    return copied_questions;
  };

  displayScansSubmitted = (question, related_question, disabled) => {
    const has_error = this.state.anticipated_scan_date_error;
    return question.input_list.map((radio, index) => {
      const is_checked = radio.value === question.value || (question.value && question.value[0] && radio.value === question.value[0]);
      return (
        <div className="prospect-input-field-radioedit" key={index}>
          <input
            type="radio"
            name={question.field_mapping}
            className="prospect-input-field-radioedit-button"
            value={radio.value}
            checked={is_checked}
            data-field_mapping={question.field_mapping}
            onChange={this.onChangeTextField}
            disabled={disabled}
          />
          <label
            className={
              disabled
                ? 'prospect-input-field-radioedit-label-disabled'
                : has_error && is_checked
                ? 'prospect-input-field-radioedit-label-error'
                : 'prospect-input-field-radioedit-label'
            }
            disabled={disabled}
            onClick={!disabled ? this.onChangeTextField : null}
            data-field_mapping={question.field_mapping}
            data-value={radio.value}
          >
            {radio.label}
          </label>
          {is_checked && radio.show_field ? (
            <div className="prospect-sub-question">
              {this.selectDisplayInputUI(related_question, true, true, disabled)}
              {has_error ? <div className="prospect-input-label-error prospect-input-label-error--small">Invalid date</div> : ''}
            </div>
          ) : null}
        </div>
      );
    });
  };
  /**
   * Display the correct UI by input type or read
   * @function
   */
  selectDisplayInputUI = (question, dependency_met, is_editing, is_disabled) => {
    const is_converted = this.is_converted();

    if (!is_editing) {
      return <DisplayValue question={question} />;
    } else if (question && question.input_type === 'text') {
      return (
        <TextField
          question={question}
          onChange={this.onChangeTextField}
          onBlur={this.onBlurTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
        />
      );
    } else if (question && question.input_type === 'textarea') {
      return (
        <TextAreaField
          question={question}
          onChange={this.onChangeTextField}
          onBlur={this.onBlurTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
        />
      );
    } else if (question && question.input_type === 'date') {
      return (
        <DateField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={true}
        />
      );
    } else if (question && question.input_type === 'checkbox') {
      return (
        <CheckBoxField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
        />
      );
    } else if (question && question.input_type === 'radio') {
      return (
        <RadioField
          question={question}
          questions={this.state.questions}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
        />
      );
    } else if (question && question.input_type === 'dropdown') {
      return (
        <DropdownField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
        />
      );
    } else if (question && question.input_type === 'multi-checkbox-other') {
      return (
        <MultiCheckboxFieldEdit
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          onOtherTextChange={this.onOtherTextChange}
        />
      );
    }
  };
  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onChangeTextField = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const multi_checkbox = data.type === 'multi-checkbox' ? true : false;
    const field_mapping = data.field_mapping ? data.field_mapping : id;

    let value =
      e && e.currentTarget && e.currentTarget.value
        ? e.currentTarget.type === 'checkbox'
          ? multi_checkbox && data && data.value
            ? data.value
            : e.currentTarget.checked
          : e.currentTarget.value
        : data && data.value
        ? data.value
        : '';
    value = _.isString(value) ? removeEmoji(value) : value;
    if (multi_checkbox) {
      let current_value = this.getValueFromFieldMapping(field_mapping) ? this.getValueFromFieldMapping(field_mapping) : '';
      if (current_value !== '') {
        current_value = current_value.split(', ');
        current_value = [...new Set(current_value)];
        if (_.intersection(current_value, [value]).length > 0) {
          current_value = current_value.filter((cv) => {
            return cv !== value;
          });
        } else {
          current_value.push(value);
        }

        value = current_value.join(', ');

        if (value && !value.includes('Other')) {
          this.setQuestionOtherValue(field_mapping, '');
        }
        if (value && !value.includes('Referral')) {
          this.setQuestionReferralValue(field_mapping, '');
        }
      }
    }

    const set_error = false;
    if (field_mapping === 'records_submitted' || field_mapping === 'anticipated_scan_date') {
      this.setAnticipatedScanDateWarnings(set_error);
    }
    this.setQuestionValue(field_mapping, value, set_error);

    if (value === false || value === '') {
      this.clearAllDependencyResponse(field_mapping);
    }
  };

  /**
   * Clear warnings for anticipated scan date
   * @function
   */
  setAnticipatedScanDateWarnings = (warning) => {
    let anticipated_scan_date_index = this.state.questions.findIndex((question) => {
      return question.field_mapping === 'anticipated_scan_date';
    });
    let newQuestions = this.state.questions;
    newQuestions[anticipated_scan_date_index].error = warning;
    this.setState({ anticipated_scan_date_error: warning, qutions: newQuestions });
  };

  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onOtherTextChange = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const field_mapping = data.field_mapping ? data.field_mapping : id;
    const value = e && e.currentTarget && e.currentTarget.value ? removeEmoji(e.currentTarget.value.replace(',', '')) : '';
    this.setQuestionOtherValue(field_mapping, value);
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getValueFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let value = '';

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        value = question.value;
      }

      return '';
    });

    return value;
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getAllowableValueListFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let list = [];

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        list = question.field_mapping.input_list.map((input_list) => {
          return input_list.value;
        });
      }

      return '';
    });

    return list;
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getUniqueIdFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let unique_id = '';

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        unique_id = question.unique_id;
      }

      return '';
    });

    return unique_id;
  };
  /**
   * Make the value of all dependent question empty
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  clearAllDependencyResponse = (field_mapping) => {
    const questions = this.state.questions;
    const unique_id = this.getUniqueIdFromFieldMapping(field_mapping);

    questions.map((question) => {
      if (question.dependency_unique_id && question.dependency_unique_id === unique_id) {
        this.setQuestionValue(question.field_mapping, '', false);
      }

      return '';
    });
  };
  /**
   * When blur adjust the text input or show an error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onBlurTextField = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const field_mapping = data.field_mapping ? data.field_mapping : id;
    const set_error = true;
    let value = e && e.currentTarget && e.currentTarget.value ? (e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value) : '';
    value = value.trim();

    this.setQuestionValue(field_mapping, value, set_error);
  };
  /**
   * Set the question and value
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @param {Boolean} set_error - Decided wether or not to change the error state
   * @function
   */
  setQuestionValue = (field_mapping, value, set_error) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        if (questions[i].input_capitalize) {
          value = firstLetterCapital(value);
        }

        questions[i].value = value;

        if (set_error) {
          questions[i].error = questions[i].validation ? !questions[i].validation(value) : false;
        }

        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Set the question and value_other
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @function
   */
  setQuestionOtherValue = (field_mapping, value) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        questions[i].value_other = value;
        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Set the question and value_referral
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @function
   */
  setQuestionReferralValue = (field_mapping, value) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        questions[i].value_referral = value;
        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Undo changes by reverting to original value when user want to cancel changes
   * @function
   */
  onCancelClick = () => {
    let questions = _.cloneDeep(this.state.questions);

    for (let i = 0; i < questions.length; i++) {
      questions[i].value = _.cloneDeep(questions[i].value_org);
      questions[i].error = false;

      if (questions[i].value_other_org) {
        questions[i].value_other = _.cloneDeep(questions[i].value_other_org);
      }
    }

    this.setState({
      questions: questions,
      edit_mode: false,
      edit_in_progress: false,
    });
  };
  /**
   * Get all the question and map them to the correct field value for a post call
   * @function
   */
  gatherData = () => {
    let questions = _.cloneDeep(this.state.questions);
    let data = {};

    for (let i = 0; i < questions.length; i++) {
      data[questions[i].field_mapping] = questions[i].value;

      if (questions[i].value_other) {
        data[questions[i].field_mapping] =
          data[questions[i].field_mapping] && data[questions[i].field_mapping] !== null
            ? data[questions[i].field_mapping] + ', Other: ' + questions[i].value_other.replace(',', '')
            : 'Other: ' + questions[i].value_other.replace(',', '');
      }
      if (questions[i].input_type.includes('multi-checkbox-other')) {
        data[questions[i].field_mapping] = removeDuplicatesFromString(data[questions[i].field_mapping]);
      }
    }

    return data;
  };
  /**
   * Update original value to have the new value
   * @function
   */
  setOrgDataToCurrentValue = () => {
    let questions = _.cloneDeep(this.state.questions);
    for (let i = 0; i < questions.length; i++) {
      questions[i].value_org = _.cloneDeep(questions[i].value);
    }

    this.setState({
      questions: questions,
      edit_mode: false,
      action: false,
    });
  };
  /**
   * Save the changes for the current prospect record
   * @function
   */
  onSaveClick = () => {
    const data = this.gatherData();
    const that = this;
    const prospect_id = this.state.prospect_id;
    const prospect_data = this.state.prospect_data;
    that.setActionInProgress();

    Axios.put(`/apiv3/prospect/${prospect_id}`, data)
      .then(function (res) {
        if (res.data && res.data.failure_reason && res.data.failure_reason.includes('Lead already exists')) {
          const failure_reason = 'Account email already exists in the system and may not be assigned to you. Please try another email.';
          that.setState({ failure_reason: failure_reason, failed_to_create: true, action: false });
        } else {
          that.setOrgDataToCurrentValue();
          prospect_data.last_update_recorded = dateToFormatted();
          that.setState({ prospect_data: prospect_data });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };
  /**
   * Creates a brand new prospect and redirect the user
   * @function
   */
  onCreateClick = () => {
    const has_field_error = this.state.questions.filter((question) => {
      return question.error;
    });

    const met_all_required_field = this.state.questions.filter((question) => {
      return question.required && (question.value === '' || question.value === null);
    });
    if (has_field_error.length === 0 && !this.state.action && met_all_required_field.length === 0) {
      const data = this.gatherData();
      const rootPath = getRootPath(this.props.history.location.pathname);
      const that = this;
      that.setActionInProgress();
      data['doctor_id'] = this.state.doctor_id;

      Axios.post(`/apiv3/prospect`, data)
        .then(function (res) {
          const prospect_id = res && res.data && res.data.prospect_id ? res.data.prospect_id : '';
          let failure_reason = res && res.data && res.data.failure_reason ? res.data.failure_reason : '';
          if (res.data.failure_reason && res.data.failure_reason.includes('Lead already exists')) {
            failure_reason = 'Account email already exists in the system and may not be assigned to you. Please try another email.';
          }
          if (prospect_id) {
            const pathname = `${rootPath}/prospect/edit/${prospect_id}`;
            that.props.history.push({ pathname: pathname, state: { refreshInfo: 'true' } });
          } else if (failure_reason) {
            that.setState({ failure_reason: failure_reason, failed_to_create: true, action: false });
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul class="wizard-error-text"><li>Please complete required fields.</li></ul>';

        this.setState({
          warning: true,
          warning_title: 'Incomplete Fields',
        });
      }
    }
  };
  /**
   * Update the prospect status in the state
   * @param {String} status - Value of the new status to be changed to
   * @param {String} status_comment - Status comment value
   * @function
   */
  updateProspectStatus = (status, status_comment) => {
    let prospect_data = _.cloneDeep(this.state.prospect_data);
    let prospect_status = { status_comment: status_comment };
    prospect_data.prospect_status = status;
    prospect_data.last_update_recorded = dateToFormatted();

    this.setState({ prospect_data: prospect_data, prospect_status: prospect_status });
    this.onModalDismiss();
  };
  /**
   * Update the draft id in the state
   * @param {String} draft_id - Value of the draft id status needs to change to
   * @function
   */
  updateDraftId = (draft_id) => {
    this.setState({ draft_id: draft_id });
  };

  /**
   * Determines if selected date is after 1/1/2010
   * @function
   * @param {String} date - Selected date string
   * @return {Boolean} - True or False
   */
  isValidScheduleDate = (date) => {
    let is_valid = true;

    if (date) {
      const selected_date = Moment(new Date(date)).utc().add(1, 'days').startOf('day').valueOf(); // converted to utc its always 1 day behind
      const oldest_date = Moment(new Date(2010, 0, 1)).valueOf();

      is_valid = selected_date > oldest_date;
    }

    return is_valid;
  };

  /**
   * Call an api to update database with new status change
   * @param {String} status - Value of the new status to be changed to
   * @param {String} api_endpoint - The endpoint of the status change
   * @function
   */
  scheduleConsultationDate = () => {
    const valid_date = Date.parse(this.state.edit_office_consultation_date) > Date.parse('2010-01-01');
    if (valid_date) {
      this.setQuestionFieldValue(this.state.questions, 'office_consultation_date', this.state.edit_office_consultation_date);
      this.setState({ schedule_date_error: false, edit_scheduled_date: false, edit_office_consultation_date: '' });
      this.onClickUpdateStatus('Complete Consultation', 'completeconsultation');
    } else {
      this.setState({ schedule_date_error: true });
    }
  };
  /**
   * Call an api to update database with new status change
   * @param {String} status - Value of the new status to be changed to
   * @param {String} api_endpoint - The endpoint of the status change
   * @function
   */

  completeConsultation = () => {
    let anticipated_scan_date_index = this.state.questions.findIndex((question) => {
      return question.field_mapping === 'anticipated_scan_date';
    });
    let anticipated_scan_date = this.state.questions[anticipated_scan_date_index].value;
    let scans_submitted = this.state.questions.filter((question) => {
      return question.field_mapping === 'records_submitted';
    })[0];

    const valid_date = this.isValidScheduleDate(anticipated_scan_date);
    if (valid_date) {
      if (scans_submitted.value && (scans_submitted.value === 'To be scheduled' || scans_submitted.value === 'Scans Submitted') && anticipated_scan_date) {
        this.setQuestionFieldValue(this.state.questions, 'anticipated_scan_date', null);
      }
      this.onClickUpdateStatus('Convert to Case', 'converttocase');
      this.setState({ edit_consultation: false, anticipated_scan_date_error: false });
    } else {
      this.setAnticipatedScanDateWarnings(true);
    }
  };
  /**
   * Call an api to update database with new status change
   * @param {String} status - Value of the new status to be changed to
   * @param {String} api_endpoint - The endpoint of the status change
   * @function
   */
  onClickUpdateStatus = (status, api_endpoint) => {
    const that = this;
    const prospect_id = this.state.prospect_id;
    const doctor_id = this.state.doctor_id;
    const status_comment = this.state.modal_dropdown_value
      ? this.state.modal_dropdown_value === 'Other'
        ? `${this.state.modal_dropdown_value}: ${this.state.modal_status_comment}`
        : this.state.modal_dropdown_value
      : this.state.modal_status_comment;

    let data = { status_comment: status_comment };

    if (status === 'Converted to Draft') {
      let gatherData = this.gatherData();
      gatherData.status_comment = data.status_comment;
      gatherData.doctor_id = doctor_id;
      data = gatherData;
      data['create_draft'] = true;
    }

    if (status === 'Complete Consultation' || status === 'Convert to Case') {
      let gatherData = this.gatherData();
      data = gatherData;
    }

    Axios.put(`/apiv3/prospect/${prospect_id}/${api_endpoint}`, data)
      .then(function (res) {
        if (res.data.draft_id) {
          that.updateDraftId(res.data.draft_id);
        }

        if (api_endpoint === 'referrallost') {
          that.setQuestionValue('referred_lost_reason', status_comment, false);
          status = 'Prospect Lost';
        }

        that.setState({ edit_in_progress: false });

        if (status === 'Converted to Draft') {
          that.onDraftRedirect();
        } else {
          that.loadInformation();
          that.onModalDismiss();
        }

        // that.updateProspectStatus(status, status_comment);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };
  /**
   * Display the correct modal
   * @param {String} modal_name - The name of the modal
   * @function
   */
  displayModal = (modal_name) => {
    let modal = {};
    modal[modal_name] = true;

    this.setState(modal);
  };
  /**
   * Clear modal from screen
   * @function
   */
  onModalDismiss = () => {
    this.setState({
      modal_convert: false,
      modal_hold: false,
      modal_referral: false,
      modal_unhold: false,
      modal_reverse_lost: false,
      modal_status_comment: '',
      modal_dropdown_value: '',
    });

    modalClear();
  };
  /**
   * Update value of status comment
   * @param {Object} e - The event related to the status comment field
   * @function
   */
  setModalStatusComment = (e) => {
    this.setState({
      modal_status_comment: e.target.value,
    });
  };
  /**
   * An action is in progress so no other action should take place
   * @function
   */
  setActionInProgress = () => {
    this.setState({
      action: true,
    });
  };
  /**
   * Decide which modal configuration and display it
   * @function
   */
  onModalShow = () => {
    const that = this;
    let preset = 'decision';
    let modal_setting = {
      header_text: '',
      confirm_btn_text: '',
      close_btn_text: '',
      message_text: '',
      textarea_placeholder: '',
      onCloseButtonClick: that.onModalDismiss,
      onConfirmButtonClick: null,
    };

    if (this.state.modal_referral) {
      preset = 'decision-dialog';
      modal_setting.header_text = 'Prospect Lost';
      modal_setting.confirm_btn_text = 'Prospect Lost';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to mark prospect as a lost referral?';
      modal_setting.textarea_placeholder = 'Please specify prospect lost reason';
      modal_setting.onTextAreaChange = this.setModalStatusComment;
      modal_setting.onDropdownChange = (e) => {
        this.setState({ modal_dropdown_value: e.target.value });
      };
      modal_setting.dropdown = true;
      modal_setting.default_text = 'Select Reason';
      modal_setting.label_dropdown = 'Prospect Lost Reason:';
      modal_setting.dropdown_error = 'Please select a reason or select other with a reason description';
      modal_setting.textarea_required = false;
      modal_setting.hold_cancel_reasons = [
        //reused this code, but this just a list of value for the dropdown
        { category: 'Prospect Lost', reason: 'Cost' },
        { category: 'Prospect Lost', reason: 'Insurance Not Accepted' },
        { category: 'Prospect Lost', reason: 'Distance' },
        { category: 'Prospect Lost', reason: 'Personal' },
        { category: 'Prospect Lost', reason: 'Timing' },
        { category: 'Prospect Lost', reason: 'Not Treatable Candidate' },
        { category: 'Prospect Lost', reason: 'Patient Requested Alternate Treatment Option' },
        { category: 'Prospect Lost', reason: 'Other' },
      ];
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Referral Lost', 'referrallost');
      };
    } else if (this.state.modal_hold) {
      preset = 'decision-dialog';
      modal_setting.header_text = 'Hold Prospect';
      modal_setting.confirm_btn_text = 'Hold';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to put prospect on hold?';
      modal_setting.textarea_placeholder = 'Please specify prospect hold reason';
      modal_setting.onTextAreaChange = this.setModalStatusComment;
      modal_setting.onDropdownChange = (e) => {
        this.setState({ modal_dropdown_value: e.target.value });
      };
      modal_setting.dropdown = true;
      modal_setting.default_text = 'Select Reason';
      modal_setting.label_dropdown = 'Prospect Hold Reason:';
      modal_setting.dropdown_error = 'Please select a reason or select other with a reason description';
      modal_setting.textarea_required = false;
      modal_setting.hold_cancel_reasons = [
        //reused this code, but this just a list of value for the dropdown
        { category: 'Prospect Hold', reason: 'Other Treatment Needed' },
        { category: 'Prospect Hold', reason: 'Pending Patient Approval' },
        { category: 'Prospect Hold', reason: 'Other' },
      ];
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Hold', 'hold');
      };
    } else if (this.state.modal_convert) {
      modal_setting.header_text = 'Convert Prospect';
      modal_setting.confirm_btn_text = 'Convert';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = <div>Are you sure you would like to convert prospect? You will be redirected to the Submit a Case page</div>;
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Converted to Draft', 'convert');
      };
    } else if (this.state.modal_unhold) {
      modal_setting.header_text = 'Reverse Hold';
      modal_setting.confirm_btn_text = 'Reverse Hold';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to reverse hold?';
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Active', 'unhold');
      };
    } else if (this.state.modal_reverse_lost) {
      modal_setting.header_text = 'Reverse Prospect Lost';
      modal_setting.confirm_btn_text = 'Reverse Prospect Lost';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to reverse prospect lost?';
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Active', 'reverselost');
      };
    }

    return modal_setting.header_text ? <Modal preset={preset} {...modal_setting} theme="ipp" /> : null;
  };
  /**
   * Determine if prospect record can still be editable
   * @function
   */
  is_editable = () => {
    return (
      this.state.prospect_data &&
      this.state.prospect_data.prospect_status &&
      this.state.prospect_data.prospect_status !== 'Prospect Lost' &&
      this.state.prospect_data.prospect_status !== 'Converted to Case'
    );
  };
  /**
   * Determine if prospect record is held
   * @function
   */
  is_held = () => {
    return this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status === 'Hold';
  };
  /**
   * Determine if prospect record is held
   * @function
   */
  is_lost = () => {
    return this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status === 'Prospect Lost';
  };

  /**
   * Determine if prospect record is converted
   * @function
   */
  is_converted = () => {
    return (
      this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status.toLowerCase() === 'converted to draft'
    );
  };
  /**
   * Determine if prospect record is converted to a Case
   * @function
   */
  is_a_case = () => {
    return (
      this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status.toLowerCase() === 'converted to case'
    );
  };
  /**
   * Display the correct status heading
   * @param {String} status - The status of the prospect record
   * @function
   */
  displayStatus = (status) => {
    return status in this.state.status_friendly_name ? this.state.status_friendly_name[status] : status;
  };
  /**
   * Redirect user to draft page
   * @function
   */
  onDraftRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const draft_id = this.state.draft_id;
    const pathname = `${rootPath}/submission/patient?case_id=${draft_id}`;

    this.props.history.push(pathname);
  };
  /**
   * Redirect user to case details page
   * @function
   */
  onCaseRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const case_id = this.state.case_id;
    const pathname = `${rootPath}/case/${case_id}`;

    this.props.history.push(pathname);
  };
  /**
   * Redirect user to prospect list
   * @function
   */
  onListRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}?filter=prospect_list`;

    this.props.history.push(pathname);
  };
  /**
   * Check if dependency is met
   * @function
   */
  hasMetDependency = (questions, question) => {
    const dependency_unique_id = question.dependency_unique_id;
    const dependency_value = question.dependency_value;

    if (dependency_unique_id === undefined) {
      return true;
    }

    const acceptable_condition = questions.filter((question) => {
      if (dependency_value && dependency_value !== '') {
        return question.unique_id === dependency_unique_id && question.value && question.value === dependency_value;
      } else {
        return question.unique_id === dependency_unique_id && question.value && question.value !== '';
      }
    });

    return acceptable_condition.length > 0;
  };
  /**
   * A helper functions which will cause a click for check box label area
   * @param {Object} e - The event of the text object
   * @function
   */
  selectCheckbox = (e) => {
    const field_mapping = e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.field_mapping ? e.currentTarget.dataset.field_mapping : '';

    if (field_mapping) {
      const element = document.querySelector(`input[data-field_mapping=${field_mapping}]`);
      if (element) {
        element.click();
      }
    }
  };

  /**
   * Display Question Value
   * @param {Array} questions - Contains the questions
   * @param {Object} field_mapping - Contains the field mapping we are looking the answer for
   * @function
   */
  displayQuestionValue = (questions, field_mapping) => {
    const question = questions.filter((question) => question.field_mapping === field_mapping)[0];
    if (question && question.value) {
      return question.value;
    }
    return '';
  };

  /**
   * Display Question Other Value
   * @param {Array} questions - Contains the questions
   * @param {Object} field_mapping - Contains the field mapping we are looking the answer for
   * @function
   */
  displayQuestionOtherValue = (questions, field_mapping) => {
    const question = questions.filter((question) => question.field_mapping === field_mapping)[0];
    if (question && question.value_other) {
      return 'Other - ' + question.value_other;
    }
    return '';
  };

  /**
   * Display Question Referral Value
   * @param {Array} questions - Contains the questions
   * @param {Object} field_mapping - Contains the field mapping we are looking the answer for
   * @function
   */
  displayQuestionReferralValue = (questions, field_mapping) => {
    const question = questions.filter((question) => question.field_mapping === field_mapping)[0];
    if (question && question.value_referral) {
      return 'Referral - ' + question.value_referral;
    }
    return '';
  };

  /**
   * Display Question Value
   * @param {Array} questions - Contains the questions
   * @param {Object} field_mapping - Contains the field mapping we are looking the answer for
   * @function
   */
  setQuestionFieldValue = (questions, field_mapping, value) => {
    questions.filter((question) => question.field_mapping === field_mapping)[0].value = value;
    this.setState({ questions: questions });
  };

  /**
   * Closes modal for prospect failed
   * @function
   */
  onProspectFailedOkClicked = () => {
    this.setState({ failed_to_create: false });
    modalClear();
  };
  /**
   * Display Edit Btn or not
   * @function
   */
  displayEditBtn = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) && this.is_editable() ? (
            <div className="wall-right">
              <Button
                onClick={() => {
                  this.setState({ edit_mode: true });
                }}
              >
                Edit
              </Button>
            </div>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Gets initials from name fields for prospect
   * @function
   */
  getInitialsFromName = () => {
    let initials = '';
    const name_fields = this.getProspectNameFields();

    if (name_fields.length > 0) {
      const initial_list = name_fields.map((field) => {
        return field.value && field.value[0] ? field.value[0].toUpperCase() : '';
      });

      initials = initial_list.join('');
    }

    return initials;
  };

  /**
   * Gets name fields for prospect
   * @function
   */
  getProspectNameFields = () => {
    const name_fields = this.state.questions.filter((question) => {
      return question.field_mapping === 'first_name' || question.field_mapping === 'last_name';
    });

    return name_fields;
  };

  /**
   * Gets full name for prospect
   * @function
   */
  displayFullName = () => {
    let prospect_name = '';
    const name_fields = this.getProspectNameFields();

    if (name_fields.length > 0) {
      const values = name_fields.map((field) => {
        return field.value;
      });

      prospect_name = values.join(' ');
    }
    return prospect_name;
  };

  /**
   * Gets prospect parent name fields
   * @function
   */
  getProspectParentNameFields = () => {
    const name_fields = this.state.questions.filter((question) => {
      return question.field_mapping === 'parent_first_name' || question.field_mapping === 'parent_last_name';
    });

    return name_fields;
  };

  /**
   * Gets full name for parent
   * @function
   */
  displayParentFullName = () => {
    let prospect_name = '';
    const name_fields = this.getProspectParentNameFields();

    if (name_fields.length > 0) {
      const values = name_fields.map((field) => {
        return field.value;
      });
      prospect_name = values.join(' ') !== ' ' ? values.join(' ') : '';
    }
    return prospect_name;
  };

  /**
   * Displays User Status section for prospect detail
   * @function
   */
  displayUserStatus = () => {
    return (
      <div className="account__data">
        <div className="account__initials">{this.getInitialsFromName()}</div>
        <div className="account__info">
          <div className="prospect-detail-heading-item break-text">
            <h3 className="medium">{this.displayFullName()}</h3>
          </div>
          <div className="prospect-detail-heading-item">
            <span className="medium">Status:</span>
            <span>
              {this.state.prospect_data && this.state.prospect_data.prospect_status ? this.displayStatus(this.state.prospect_data.prospect_status) : 'N/A'}
            </span>
          </div>
          <div className="prospect-detail-heading-item">
            <span className="medium">Created Date:</span>
            <span>{this.state.prospect_data && this.state.prospect_data.created_date ? convertDate(this.state.prospect_data.created_date) : 'N/A'}</span>
          </div>
          <div className="prospect-datestamp">{`Last Updated Recorded: ${dateToFormatted(this.state.prospect_data.last_updated_time)}`}</div>
        </div>
      </div>
    );
  };

  getHoldStatus = () => {
    const prospect = this.state.prospect_data;
    if (prospect.prospect_status === 'Hold') {
      const prospect_status = this.state.prospect_status;
      const date = convertDate(prospect_status.created_date);
      return {
        isHold: true,
        actionRequired: false,
        status: 'Hold',
        date: date,
      };
    }
    return {};
  };

  /**
   * Gets prospect scheduling status for prospect
   * @function
   */
  getSchedulingStatus = () => {
    const prospect = this.state.prospect_data;
    let status_info = {};
    const is_scheduling_status_done =
      this.state.prospect_statuses.filter((status) => {
        return (
          status.prospect_status === 'Complete Consultation' ||
          status.prospect_status === 'Convert to Case' ||
          status.prospect_status === 'Converted to Draft' ||
          status.prospect_status === 'Converted to Case'
        );
      }).length > 0 ||
      prospect.prospect_status === 'Complete Consultation' ||
      prospect.prospect_status === 'Convert to Case' ||
      prospect.prospect_status === 'Converted to Draft' ||
      prospect.prospect_status === 'Converted to Case';
    if (
      (prospect.prospect_status === 'Active' || prospect.prospect_status === 'Hold' || prospect.prospect_status === 'Prospect Lost') &&
      !is_scheduling_status_done
    ) {
      status_info = {
        actionRequired: true,
        current: true,
        isCompleted: false,
        status: 'Schedule Consultation',
        date: '',
        note: '',
      };
    } else if (is_scheduling_status_done) {
      let date = '';
      let status_comment = '';
      let prospect_status = this.state.prospect_statuses.filter((status) => {
        return status.prospect_status === 'Complete Consultation';
      })[0];
      if (prospect_status && prospect_status.status_comment !== 'Automated Match Back') {
        date = convertDate(prospect_status.created_date);
      }
      status_info = {
        isCompleted: true,
        actionRequired: false,
        status: 'Scheduled',
        date: date,
        note: status_comment,
      };
    }
    return {
      title: 'Scheduling',
      ...status_info,
    };
  };

  /**
   * Gets prospect consultation status for prospect
   * @function
   */
  getConsultationStatus = () => {
    const prospect = this.state.prospect_data;
    let status_info = '';
    const is_consultation_step_done =
      this.state.prospect_statuses.filter((status) => {
        return (
          status.prospect_status === 'Convert to Case' || status.prospect_status === 'Converted to Draft' || status.prospect_status === 'Converted to Case'
        );
      }).length > 0 ||
      prospect.prospect_status === 'Convert to Case' ||
      prospect.prospect_status === 'Converted to Draft' ||
      prospect.prospect_status === 'Converted to Case';
    if (prospect.prospect_status === 'Complete Consultation' && !is_consultation_step_done) {
      let date = '';
      status_info = {
        actionRequired: true,
        current: true,
        isCompleted: false,
        status: 'Complete Consultation',
        date: date,
        note: '',
      };
    } else if (is_consultation_step_done) {
      let date = '';
      let status_comment = '';
      let prospect_status = this.state.prospect_statuses.filter((status) => {
        return status.prospect_status === 'Convert to Case';
      })[0];
      if (prospect_status && prospect_status.status_comment !== 'Automated Match Back') {
        date = convertDate(prospect_status.created_date);
      }
      status_info = {
        isCompleted: true,
        actionRequired: false,
        status: 'Completed',
        date: date,
        note: status_comment,
      };
    } else {
      status_info = {
        isCompleted: false,
        status: '',
        date: '',
        note: '',
      };
    }
    return {
      title: 'Consultation',
      ...status_info,
    };
  };

  /**
   * Gets prospect submission status for prospect
   * @function
   */
  getSubmissionStatus = () => {
    const prospect = this.state.prospect_data;
    let status_info = '';
    const is_submission_step_done =
      this.state.prospect_statuses.filter((status) => {
        return status.prospect_status === 'Converted to Case';
      }).length > 0 || prospect.prospect_status === 'Converted to Case';
    if ((prospect.prospect_status === 'Convert to Case' || prospect.prospect_status === 'Converted to Draft') && !is_submission_step_done) {
      let date = '';
      let status_comment = '';
      let status =
        prospect.prospect_status === 'Convert to Case'
          ? 'Begin Submission Process'
          : prospect.prospect_status === 'Converted to Draft'
          ? 'Resume Submission Process'
          : '';

      status_info = {
        status: status,
        actionRequired: true,
        current: true,
        isCompleted: false,
        date: date,
        note: status_comment,
      };
    } else if (is_submission_step_done) {
      const prospect_status = this.state.prospect_status;
      let date = '';
      let status_comment = '';
      if (prospect_status && prospect_status.status_comment !== 'Automated Match Back') {
        date = convertDate(prospect_status.created_date);
      }
      status_info = {
        status: 'Submitted',
        actionRequired: false,
        isCompleted: true,
        date: date,
        note: status_comment,
      };
    } else {
      status_info = {
        isCompleted: false,
        status: '',
        date: '',
        note: '',
      };
    }
    return {
      title: 'Submission',
      ...status_info,
    };
  };

  /**
   * Gets prospect lost status for prospect
   * @function
   */
  getLostStatus = () => {
    const prospect = this.state.prospect_data;
    if (prospect.prospect_status === 'Prospect Lost') {
      const prospect_status = this.state.prospect_status;
      let date = '';
      let status_comment = '';
      if (
        prospect_status &&
        prospect_status.status_comment !== 'Automated Match Back' &&
        prospect_status.status_comment !== 'Transferred to Another Provider'
      ) {
        date = convertDate(prospect_status.created_date);
      }
      return {
        isHold: true,
        actionRequired: false,
        status: 'Prospect Lost',
        date: date,
        note: status_comment,
      };
    }
    return {};
  };

  /**
   * Check is the status is current status of prospect
   * @param {string} status
   * @function
   */
  isStatusCurrent = (status) => {
    return this.state.prospect_data.prospect_status === status;
  };

  /**
   * Check is the status is present in prospect status array
   * @param {string} status
   * @function
   */
  isStatusPresent = (prospect_status) => {
    return (
      this.state.prospect_statuses.filter((status) => {
        return status.prospect_status === prospect_status;
      }).length > 0
    );
  };

  /**
   * Gets status detail for prospect
   * @param {string} status
   * @function
   */
  getStatusDetail = (status) => {
    return this.state.prospect_statuses.filter((status) => {
      return status.prospect_status === status;
    });
  };

  /**
   * Handel event when in-office consultation date change
   * @param {Object} event Object about the change event
   * @function
   */
  onConsultationDateChange = (value) => {
    const date = value;
    this.setQuestionFieldValue(this.state.questions, 'office_consultation_date', date);
    this.setState({ edit_office_consultation_date: date, schedule_date_error: false });
  };

  /**
   * Display scheduling step for prospect
   * @function
   */
  displayScheduling = () => {
    const is_a_case = this.isStatusCurrent('Converted to Case') || this.isStatusCurrent('Converted to Draft');
    let date_value =
      this.displayQuestionValue(this.state.questions, 'office_consultation_date') &&
      Moment(this.displayQuestionValue(this.state.questions, 'office_consultation_date')).format('YYYY-MM-DD') !== 'Invalid date'
        ? Moment(this.displayQuestionValue(this.state.questions, 'office_consultation_date')).format('YYYY-MM-DD')
        : '';
    if (this.state.edit_scheduled_date) {
      date_value = this.state.edit_office_consultation_date ? Moment(this.state.edit_office_consultation_date).format('YYYY-MM-DD') : '';
    }
    const is_prospect_lost = this.isStatusCurrent('Prospect Lost') || this.isStatusCurrent('Hold');
    const prospect_lost = this.isStatusCurrent('Prospect Lost');
    const held = this.isStatusCurrent('Hold');
    const is_scheduling_completed = this.isStatusPresent('Complete Consultation');
    if (prospect_lost && !is_scheduling_completed) {
      return <></>;
    } else if (held && !is_scheduling_completed) {
      return (
        <>
          <CardContainer type="top-border" className="case-view pad-lg">
            <h2 className="case-header">
              Scheduling {this.state.prospect_data.prospect_status === 'Active' ? <Exc className="alert--icon alert--icon__no-float" /> : null}
            </h2>
            <div className="case-detail__row case-detail__row--inner">
              <div className="row">
                <div className="col-md-12">Prospect on hold</div>
              </div>
            </div>
          </CardContainer>
        </>
      );
    }
    return (
      <>
        <CardContainer type="top-border" className="case-view pad-lg">
          <h2 className="case-header">
            Scheduling {this.state.prospect_data.prospect_status === 'Active' ? <Exc className="alert--icon alert--icon__no-float" /> : null}
          </h2>
          {this.state.prospect_data.prospect_status === 'Active' ? (
            <div className="prospect-scheduling-header">
              Congrats! After your prospect has scheduled a consultation, please input their date below to move forward.
            </div>
          ) : null}
          <div className="case-detail__row case-detail__row--inner">
            <div className="row">
              <div className="col-md-12">
                <div className="row prospect-contact-information">
                  <div className="col-md-12">
                    <div className={this.state.schedule_date_error ? 'medium prospect-input-label-error' : 'medium'}>
                      In-Office Consultation Date {this.state.prospect_data.prospect_status === 'Active' ? '*' : null}
                    </div>
                    <div className="prospect-input-field">
                      {this.state.prospect_data.prospect_status === 'Active' || this.state.edit_scheduled_date ? (
                        <Application theme={theme}>
                          <DatePicker
                            className={
                              this.state.schedule_date_error
                                ? 'form-control prospect-input-field-date prospect-input-field-error react-rainbow'
                                : 'form-control prospect-input-field-date react-rainbow'
                            }
                            value={date_value ? Moment(date_value).add(1, 'days').format('YYYY-MM-DD') : null} //weird fix to prevent showing the day before selected day
                            disabled={this.state.edit_mode}
                            maxDate={new Date(Moment().add(10, 'years').format('YYYY-MM-DD'))}
                            minDate={new Date('2010-01-01')}
                            onChange={(value) => this.onConsultationDateChange(Moment(value).format('YYYY-MM-DD'))}
                            placeholder="MM/DD/YYYY"
                            icon={<FontAwesomeIcon icon={faCalendar} />}
                            variant="brand"
                          />
                        </Application>
                      ) : Moment(this.displayQuestionValue(this.state.questions, 'office_consultation_date')).format('MM/DD/YYYY') !== 'Invalid date' ? (
                        Moment(this.displayQuestionValue(this.state.questions, 'office_consultation_date')).format('MM/DD/YYYY')
                      ) : (
                        'N/A'
                      )}
                    </div>
                    {this.state.schedule_date_error ? <div className="prospect-input-label-error prospect-input-label-error--small">Invalid date</div> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!is_a_case && !is_prospect_lost ? (
            <div className="row prospect-contact-information">
              <div className="float-right prospect-edit-button">
                {this.isStatusCurrent('Active') ? (
                  <>
                    <Button
                      onClick={this.scheduleConsultationDate}
                      onDoubleClick={this.scheduleConsultationDate}
                      disabled={!date_value || this.state.edit_in_progress}
                    >
                      Scheduled
                    </Button>
                  </>
                ) : this.state.edit_scheduled_date ? (
                  <>
                    <Button onClick={this.scheduleConsultationDate} onDoubleClick={this.scheduleConsultationDate} disabled={!date_value}>
                      Save
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({
                          edit_scheduled_date: false,
                          questions: this.state.original_questions,
                          edit_in_progress: false,
                          schedule_date_error: false,
                        });
                      }}
                      theme="secondary"
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        this.setState({
                          edit_office_consultation_date: this.displayQuestionValue(this.state.questions, 'office_consultation_date'),
                          edit_scheduled_date: true,
                          original_questions: _.cloneDeep(this.state.questions),
                          edit_in_progress: true,
                        });
                      }}
                      disabled={this.state.edit_in_progress}
                    >
                      Edit
                    </Button>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </CardContainer>
      </>
    );
  };

  /**
   * Display consultation edit step for prospect
   * @function
   */
  displayConsultationCompleteEdit = () => {
    const is_a_case = this.isStatusCurrent('Converted to Case');
    let in_office_consultation = this.state.questions.filter((question) => {
      return question.field_mapping === 'office_consultation';
    })[0];
    let scans_submitted = this.state.questions.filter((question) => {
      return question.field_mapping === 'records_submitted';
    })[0];
    let anticipated_scan_date = this.state.questions.filter((question) => {
      return question.field_mapping === 'anticipated_scan_date';
    })[0];
    let previous_tx_details = this.state.questions.filter((question) => {
      return question.field_mapping === 'previous_tx_details';
    })[0];
    const is_prospect_lost = this.state.prospect_data.prospect_status === 'Prospect Lost';
    let other_tx_needed = this.state.questions.filter((question) => {
      return question.field_mapping === 'ortho_tx_needed';
    })[0];
    if (
      !scans_submitted.value ||
      (scans_submitted.value !== 'Scheduled' && scans_submitted.value !== 'To be scheduled' && scans_submitted.value !== 'Scans Submitted')
    ) {
      this.setQuestionFieldValue(this.state.questions, 'records_submitted', 'Scheduled');
      scans_submitted = this.state.questions.filter((question) => {
        return question.field_mapping === 'records_submitted';
      })[0];
    }
    if (scans_submitted.value && (scans_submitted.value === 'To be scheduled' || scans_submitted.value === 'Scans Submitted') && anticipated_scan_date.value) {
      this.setQuestionFieldValue(this.state.questions, 'anticipated_scan_date', null);
    }
    return (
      <CardContainer type="top-border" className="case-view pad-lg">
        <h2 className="case-header">Consultation</h2>
        <div className="case-detail__row case-detail__row--inneredit">
          <div className="row top-border">
            <div className="col-md-12">
              <div className="row prospect-contact-information">
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={scans_submitted.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={scans_submitted.field_mapping}
                      >
                        {scans_submitted.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.displayScansSubmitted(
                          scans_submitted,
                          anticipated_scan_date,
                          in_office_consultation.value && !this.state.edit_mode && !this.state.edit_office_consultation_date ? false : true
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={previous_tx_details.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={previous_tx_details.field_mapping}
                      >
                        {previous_tx_details.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.selectDisplayInputUI(previous_tx_details, true, true, in_office_consultation.value ? false : true)}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={other_tx_needed.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={other_tx_needed.field_mapping}
                      >
                        {other_tx_needed.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.selectDisplayInputUI(other_tx_needed, true, true, in_office_consultation.value ? false : true)}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!is_a_case && !is_prospect_lost ? (
          <div className="row prospect-contact-information">
            <div className="float-right prospect-edit-button">
              <Button
                onClick={this.completeConsultation}
                onDoubleClick={this.completeConsultation}
                disabled={!in_office_consultation.value || !scans_submitted.value || (scans_submitted.value === 'Scheduled' && !anticipated_scan_date.value)}
              >
                Save
              </Button>

              <Button
                onClick={() => {
                  this.setState({ edit_consultation: false, questions: this.state.original_questions, edit_in_progress: false });
                  this.setAnticipatedScanDateWarnings(false);
                }}
                theme="secondary"
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : null}
      </CardContainer>
    );
  };

  /**
   * Display consultation complete summary step for prospect
   * @function
   */
  displayConsultationCompleteSummary = () => {
    const is_a_case = this.isStatusCurrent('Converted to Case') || this.isStatusCurrent('Converted to Draft');
    let scans_submitted = this.state.questions.filter((question) => {
      return question.field_mapping === 'records_submitted';
    })[0];
    let anticipated_scan_date = this.state.questions.filter((question) => {
      return question.field_mapping === 'anticipated_scan_date';
    })[0];
    let previous_tx_details = this.state.questions.filter((question) => {
      return question.field_mapping === 'previous_tx_details';
    })[0];
    const is_prospect_lost = this.state.prospect_data.prospect_status === 'Prospect Lost' || this.state.prospect_data.prospect_status === 'Hold';
    let other_tx_needed = this.state.questions.filter((question) => {
      return question.field_mapping === 'ortho_tx_needed';
    })[0];
    const prospect_lost = this.isStatusCurrent('Prospect Lost');
    const held = this.isStatusCurrent('Hold');
    const is_consultation_completed = this.isStatusPresent('Convert to Case');
    if (prospect_lost && !is_consultation_completed) {
      return <></>;
    } else if (held && !is_consultation_completed) {
      return (
        <>
          <CardContainer type="top-border" className="case-view pad-lg">
            <h2 className="case-header">Consultation</h2>
            <div className="case-detail__row case-detail__row--inner">
              <div className="row">
                <div className="col-md-12">Prospect on hold</div>
              </div>
            </div>
          </CardContainer>
        </>
      );
    }
    return (
      <CardContainer type="top-border" className="case-view pad-lg">
        <h2 className="case-header">Consultation</h2>
        <div className="case-detail__row case-detail__row--inneredit">
          <div className="row">
            <div className="col-md-12">
              <div className="row prospect-contact-information">
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={scans_submitted.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={scans_submitted.field_mapping}
                      >
                        Scan Date
                      </div>

                      <div className="prospect-input-field">
                        {anticipated_scan_date.value
                          ? `Scheduled  ${Moment(anticipated_scan_date.value).format('MM/DD/YYYY')}`
                          : scans_submitted.value && (scans_submitted.value === 'Scans Submitted' || scans_submitted.value === 'To be scheduled')
                          ? 'To be scheduled'
                          : 'N/A'}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={previous_tx_details.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={previous_tx_details.field_mapping}
                      >
                        {previous_tx_details.label}
                      </div>

                      <div className="prospect-input-field">
                        {previous_tx_details.value ? removeDuplicatesFromString(previous_tx_details.value) : previous_tx_details.value_other ? '' : 'None'}{' '}
                        {previous_tx_details.value_other ? (previous_tx_details.value ? ', ' : '') + 'Other - ' + previous_tx_details.value_other : null}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={other_tx_needed.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={other_tx_needed.field_mapping}
                      >
                        {other_tx_needed.label}
                      </div>

                      <div className="prospect-input-field">
                        {other_tx_needed.value ? removeDuplicatesFromString(other_tx_needed.value) : other_tx_needed.value_other ? '' : 'None'}
                        {other_tx_needed.value_other ? (other_tx_needed.value ? ', ' : '') + 'Other - ' + other_tx_needed.value_other : null}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!is_a_case && !is_prospect_lost ? (
          <div className="row prospect-contact-information">
            <div className="float-right prospect-edit-button">
              <Button
                onClick={() => {
                  this.setState({ edit_consultation: true, original_questions: _.cloneDeep(this.state.questions), edit_in_progress: true });
                }}
                onDoubleClick={() => {
                  this.setState({ edit_consultation: true, original_questions: _.cloneDeep(this.state.questions), edit_in_progress: true });
                }}
                disabled={this.state.edit_in_progress}
              >
                Edit
              </Button>
            </div>
          </div>
        ) : null}
      </CardContainer>
    );
  };

  /**
   * Display consultation complete step for prospect
   * @function
   */
  displayConsultationComplete = () => {
    if (this.state.edit_consultation) {
      return this.displayConsultationCompleteEdit();
    } else {
      return this.displayConsultationCompleteSummary();
    }
  };

  /**
   * Display consultation incomplete step for prospect
   * @function
   */
  displayConsultationIncomplete = () => {
    const is_a_case =
      this.state.prospect_statuses.filter((status) => {
        return status.prospect_status === 'Converted to Case';
      }).length > 0;
    let in_office_consultation = this.state.questions.filter((question) => {
      return question.field_mapping === 'office_consultation';
    })[0];
    let scans_submitted = this.state.questions.filter((question) => {
      return question.field_mapping === 'records_submitted';
    })[0];
    if (
      !scans_submitted.value ||
      (scans_submitted.value !== 'Scheduled' && scans_submitted.value !== 'To be scheduled' && scans_submitted.value !== 'Scans Submitted')
    ) {
      this.setQuestionFieldValue(this.state.questions, 'records_submitted', 'Scheduled');
      scans_submitted = this.state.questions.filter((question) => {
        return question.field_mapping === 'records_submitted';
      })[0];
    }
    let anticipated_scan_date = this.state.questions.filter((question) => {
      return question.field_mapping === 'anticipated_scan_date';
    })[0];
    let previous_tx_details = this.state.questions.filter((question) => {
      return question.field_mapping === 'previous_tx_details';
    })[0];

    let other_tx_needed = this.state.questions.filter((question) => {
      return question.field_mapping === 'ortho_tx_needed';
    })[0];
    const is_prospect_lost = this.state.prospect_data.prospect_status === 'Prospect Lost' || this.state.prospect_data.prospect_status === 'Hold';
    return (
      <CardContainer type="top-border" className="case-view pad-lg">
        <h2 className="case-header">
          Consultation <Exc className="alert--icon alert--icon__no-float" />
        </h2>
        <div className="prospect-scheduling-header">This consultation has been completed. Please fill the consultation information below to continue.</div>

        <React.Fragment>
          <div className="prospect-input prospect-input--checkbox">
            <div className="prospect-input-field">
              {this.selectDisplayInputUI(in_office_consultation, true, true, this.state.edit_mode || this.state.edit_office_consultation_date ? true : false)}
            </div>
            <div
              className={in_office_consultation.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
              data-field_mapping={in_office_consultation.field_mapping}
              onClick={this.selectCheckbox}
            >
              {in_office_consultation.label}
            </div>
          </div>
        </React.Fragment>
        <div className="case-detail__row case-detail__row--inneredit">
          <div className="row">
            <div className="col-md-12">
              <div className="row prospect-contact-information">
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={scans_submitted.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={scans_submitted.field_mapping}
                      >
                        {scans_submitted.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.displayScansSubmitted(
                          scans_submitted,
                          anticipated_scan_date,
                          in_office_consultation.value && !this.state.edit_mode && !this.state.edit_office_consultation_date ? false : true
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                    <div className="prospect-input__next-question">
                      <div
                        className={previous_tx_details.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={previous_tx_details.field_mapping}
                      >
                        {previous_tx_details.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.selectDisplayInputUI(
                          previous_tx_details,
                          true,
                          true,
                          in_office_consultation.value && !this.state.edit_mode && !this.state.edit_office_consultation_date ? false : true
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="col-md-6">
                  <React.Fragment>
                    <div className="prospect-input">
                      <div
                        className={other_tx_needed.error ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'}
                        data-field_mapping={other_tx_needed.field_mapping}
                      >
                        {other_tx_needed.label}
                      </div>

                      <div className="prospect-input-field">
                        {this.selectDisplayInputUI(
                          other_tx_needed,
                          true,
                          true,
                          in_office_consultation.value && !this.state.edit_mode && !this.state.edit_office_consultation_date ? false : true
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!is_a_case && !is_prospect_lost ? (
          <div className="row prospect-contact-information">
            <div className="float-right prospect-edit-button">
              <Button
                onClick={this.completeConsultation}
                onDoubleClick={this.completeConsultation}
                disabled={
                  !in_office_consultation.value ||
                  !scans_submitted.value ||
                  (scans_submitted.value === 'Scheduled' && !anticipated_scan_date.value && !this.state.edit_in_progress) ||
                  this.state.edit_in_progress
                }
              >
                Completed
              </Button>
            </div>
          </div>
        ) : null}
      </CardContainer>
    );
  };

  /**
   * Check if consultation section can be displayed
   * @function
   */
  allowedToDisplayConsultation = () => {
    const is_valid_status_current =
      this.isStatusCurrent('Complete Consultation') ||
      this.isStatusCurrent('Convert to Case') ||
      this.isStatusCurrent('Converted to Case') ||
      this.isStatusCurrent('Converted to Draft');
    const is_valid_status_present =
      this.isStatusPresent('Complete Consultation') ||
      this.isStatusPresent('Convert to Case') ||
      this.isStatusPresent('Converted to Case') ||
      this.isStatusPresent('Converted to Draft');
    const is_lost = this.isStatusCurrent('Prospect Lost');
    const is_held = this.isStatusCurrent('Hold');
    return is_valid_status_current || ((is_lost || is_held) && is_valid_status_present);
  };

  /**
   * Display consultation step for prospect
   * @function
   */
  displayConsultation = () => {
    if (this.allowedToDisplayConsultation()) {
      if (this.isStatusCurrent('Complete Consultation')) {
        return this.displayConsultationIncomplete();
      } else {
        return this.displayConsultationComplete();
      }
    }
    return null;
  };

  /**
   * Check if case submission section can be displayed
   * @function
   */
  allowedToDisplaySubmission = () => {
    const is_valid_status_current =
      this.isStatusCurrent('Convert to Case') || this.isStatusCurrent('Converted to Case') || this.isStatusCurrent('Converted to Draft');
    const is_valid_status_present =
      this.isStatusPresent('Convert to Case') || this.isStatusPresent('Converted to Case') || this.isStatusPresent('Converted to Draft');
    const is_lost = this.isStatusCurrent('Prospect Lost');
    const is_held = this.isStatusCurrent('Hold');

    return is_valid_status_current || ((is_lost || is_held) && is_valid_status_present);
  };

  /**
   * Display case submission step for prospect
   * @function
   */
  displaySubmission = () => {
    const is_converted = this.is_converted();
    const is_a_case = this.is_a_case();
    const is_prospect_lost = this.state.prospect_data.prospect_status === 'Prospect Lost' || this.state.prospect_data.prospect_status === 'Hold';
    const prospect_lost = this.isStatusCurrent('Prospect Lost');
    const held = this.isStatusCurrent('Hold');
    const is_submission_completed = this.isStatusPresent('Converted to Case');
    const is_valid_status_present = this.isStatusPresent('Convert to Case') || this.isStatusPresent('Converted to Draft');
    if (prospect_lost && !is_submission_completed && is_valid_status_present) {
      return <></>;
    } else if (held && !is_submission_completed && is_valid_status_present) {
      return (
        <>
          <CardContainer type="top-border" className="case-view pad-lg">
            <h2 className="case-header">Submission</h2>
            <div className="case-detail__row case-detail__row--inner">
              <div className="row">
                <div className="col-md-12">Prospect on hold</div>
              </div>
            </div>
          </CardContainer>
        </>
      );
    }
    if (!is_a_case && this.allowedToDisplaySubmission()) {
      return (
        <CardContainer type="top-border" className="case-view pad-lg">
          <h2 className="case-header">
            Submission <Exc className="alert--icon alert--icon__no-float" />
          </h2>
          <div className="prospect-scheduling-header">
            WOW starts now! Your prospect is ready to become an InBrace patient. Click the button below to move your prospect into a new case submission.
          </div>
          <div className="row prospect-contact-information">
            <div className="float-right prospect-edit-button">
              {!is_converted && !is_prospect_lost ? (
                <Button
                  onClick={() => {
                    this.onClickUpdateStatus('Converted to Draft', 'convert');
                  }}
                  onDoubleClick={() => {
                    this.onClickUpdateStatus('Converted to Draft', 'convert');
                  }}
                  disabled={this.state.edit_in_progress}
                >
                  Submit Case
                </Button>
              ) : is_converted ? (
                <Button onClick={this.onDraftRedirect} disabled={this.state.edit_in_progress}>
                  Resume Draft Submission
                </Button>
              ) : null}
            </div>
          </div>
        </CardContainer>
      );
    }
    return null;
  };

  /**
   * Display prospect contact information
   * @function
   */
  displayContactInformation = () => {
    const is_a_case = this.state.prospect_data.prospect_status === 'Converted to Case' || this.state.prospect_data.prospect_status === 'Converted to Draft';
    const is_prospect_lost = this.state.prospect_data.prospect_status === 'Prospect Lost' || this.state.prospect_data.prospect_status === 'Hold';
    return (
      <>
        {!this.state.edit_mode ? (
          <>
            <h2 className="case-header">Contact Information</h2>
            <div className="case-detail__row case-detail__row--inneredit">
              <div className="row">
                <div className="col-md-12">
                  <div className="row prospect-contact-information">
                    <div className="col-md-4">
                      <div className="medium">Email Address</div>
                      <div>{this.displayQuestionValue(this.state.questions, 'email')}</div>
                      <div className="medium">Phone Number</div>
                      <div>
                        {formatPhoneNumber(this.displayQuestionValue(this.state.questions, 'phone'))}
                        {this.displayQuestionValue(this.state.questions, 'phone_service_carrier')
                          ? ', ' + this.displayQuestionValue(this.state.questions, 'phone_service_carrier')
                          : null}{' '}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="medium">Parent Submitting for Child</div>
                      <div>{this.displayParentFullName() ? this.displayParentFullName() : 'N/A'}</div>
                      <div className="medium">Zip Code</div>
                      <div>
                        {this.state.prospect_data.city ? this.state.prospect_data.city + ', ' : null}
                        {this.displayQuestionValue(this.state.questions, 'postal_code')}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="medium">How Did Patient Hear About Us</div>
                      <div>
                        {this.displayQuestionValue(this.state.questions, 'advertising_source')
                          ? this.displayQuestionValue(this.state.questions, 'advertising_source') +
                            (this.displayQuestionOtherValue(this.state.questions, 'advertising_source') ||
                            this.displayQuestionReferralValue(this.state.questions, 'advertising_source')
                              ? ', '
                              : '')
                          : this.displayQuestionOtherValue(this.state.questions, 'advertising_source') ||
                            this.displayQuestionReferralValue(this.state.questions, 'advertising_source')
                          ? ''
                          : 'N/A'}
                        {this.displayQuestionOtherValue(this.state.questions, 'advertising_source')
                          ? this.displayQuestionOtherValue(this.state.questions, 'advertising_source') +
                            (this.displayQuestionReferralValue(this.state.questions, 'advertising_source') ? ', ' : '')
                          : ''}
                        {this.displayQuestionReferralValue(this.state.questions, 'advertising_source')
                          ? ' ' + this.displayQuestionReferralValue(this.state.questions, 'advertising_source')
                          : ''}
                      </div>
                      <div className="medium">Dental Insurance Information</div>
                      <div>
                        {this.displayQuestionValue(this.state.questions, 'dental_insurance_information')
                          ? this.displayQuestionValue(this.state.questions, 'dental_insurance_information')
                          : 'N/A'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!is_a_case && !is_prospect_lost ? (
              <div className="row prospect-contact-information">
                <div className="float-right prospect-edit-button">
                  <Button
                    onClick={() => {
                      this.setState({ edit_mode: true, edit_in_progress: true });
                    }}
                    disabled={this.state.edit_in_progress}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            <CreateEditProspect
              edit_mode={true}
              prospect_id={this.state.prospect_id}
              loadInformation={this.loadInformation}
              onCancelClick={() => {
                this.setState({ edit_mode: false, edit_in_progress: false });
              }}
            />
          </>
        )}
      </>
    );
  };

  /**
   * Display prospect status progress bar
   * @function
   */
  displayProspectStatus = () => {
    const prospect = this.state.prospect_data;
    const prospectItems = [
      {
        title: 'Creation',
        isCompleted: true,
        actionRequired: false,
        status: 'Created',
        date: convertDate(prospect.created_date),
      },
      this.getSchedulingStatus(),
      this.getConsultationStatus(),
      this.getSubmissionStatus(),
    ];

    const holdStatus = this.getHoldStatus();
    const lostStatus = this.getLostStatus();

    for (const [i, item] of prospectItems.entries()) {
      const isLast = i === prospectItems.length - 1;

      if (!item.isCompleted || isLast) {
        Object.assign(item, holdStatus, lostStatus, { current: true });
        break;
      }
    }

    return (
      <>
        <h2 className="case-header">Status</h2>
        <div className="case-detail__row case-detail__row--no-border">
          <ProspectProgress items={prospectItems} hideProgressBar={lostStatus.isCancelled}></ProspectProgress>
        </div>
      </>
    );
  };

  /**
   * Shows the unsave note modal
   * @function
   * @param {Object} location - The location object to navigate to
   */
  showUnsavedNoteModal = (location) => this.setState({ showUnsaveModal: true, last_location: location });

  /**
   * Navigates to last location when confirmed discard
   * @function
   */
  onDiscardNoteConfirm = () => {
    const { last_location } = this.state;
    if (last_location) {
      this.setState({ confirmed_navigation: true }, () => this.props.history.push(last_location));
    }
  };

  /**
   * Opens the Create Note Window
   * @function
   */
  openCreateNote = () => {
    this.setState({
      prospect_notes: true,
      notes_refresh: this.state.prospect_notes ? true : false,
    });
  };

  /**
   * Checks to see if user haves permission to patient notes
   * @param {Object} user_roles_and_permissions - contains the user role and permission
   * @function
   */
  hasPermissionToNote = (user_roles_and_permissions) => {
    return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
  };
  /**
   * Checks to see if note creator id is the same as the logged in user id
   * @param {Object} note_creator_id - creator id associated with the patient note
   * @param {Object} user_id - current logged in user id
   * @function
   */
  isNoteCreator = (note_creator_id, user_id) => {
    return note_creator_id === user_id;
  };

  /**
   * Closes the Create Note Window and set new notes
   * @function
   */
  closeCreateNote = (res) => {
    const notes = res && res.data && res.data.prospect_notes ? res.data.prospect_notes : this.state.notes;

    this.setState({
      notes: notes,
      prospect_notes: false,
      notes_id: '',
      notes_subject: '',
      notes_note_text: '',
      notes_refresh: false,
      notes_content: false,
      last_location: '',
    });
  };

  /**
   * Set patient notes details into the patient notes to be edit or delete
   * @param {Object} event - event handler for edit / delete note buttons
   * @function
   */
  setNote = (event, state) => {
    const notes_id =
      event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.note_id ? parseInt(event.currentTarget.dataset.note_id) : '';

    const notes = this.state.notes && this.state.notes ? this.state.notes : [];

    const notes_note_text = notes.filter((note) => {
      return note.id === notes_id;
    });

    if (notes.length > 0 && notes_id && notes_note_text.length > 0) {
      this.setState({
        ...state,
        notes_id: notes_id,
        notes_subject: notes_note_text[0].subject,
        notes_note_text: notes_note_text[0].note_text,
      });
    }
  };

  /**
   * Show patient notes box with information filled out to be edited
   * @param {Object} event - event for the edit note button
   * @function
   */
  setNoteToEdit = (event) => {
    this.setNote(event, { prospect_notes: true });
  };
  /**
   * Show delete dialog to be confirmed
   * @param {Object} event - event for the delete note button
   * @function
   */
  setNoteToDelete = (event) => {
    this.setNote(event, { notes_delete_modal: true });
  };
  /**
   * Call api after delete note is confirm
   * @param {Object} event - event for the confirmation button the modal dialog for deleting a note
   * @function
   */
  onConfirmDeleteNote = (event) => {
    let that = this;
    const note_id = this.state.notes_id;

    Axios.delete(`/apiv3/globalnotes/${note_id}`)
      .then((res) => {
        const notes = res && res.data && res.data.prospect_notes ? res.data.prospect_notes : [];
        that.onCloseDeleteNote(event, notes);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Close delete note Modal, set and clear content as approriate
   * @param {Object} event - event for the confirmation button the modal dialog for deleting a note
   * @param {Object} notes - contains the new global notes to be set in state
   * @function
   */
  onCloseDeleteNote = (event, notes = '') => {
    this.setState({
      notes: notes ? notes : this.state.notes,
      notes_delete_modal: false,
      notes_id: '',
      notes_subject: '',
      notes_note_text: '',
      notes_refresh: false,
      notes_content: false,
      last_location: '',
    });

    modalClear();
  };

  /**
   * Display prospect notes
   * @function
   */
  displayNotes = () => {
    return (
      <>
        <h2>Notes</h2>
        <div className="prospect-note-container">
          {this.state.notes
            ? this.state.notes
                .slice(0)
                .reverse()
                .map((note, index) => {
                  return (
                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        const is_note_creator = note.created_by_id.toString() === user_roles_and_permissions.user_id.toString();
                        return (
                          <div key={index}>
                            {index > 0 ? <div className="case-view-note-next"></div> : null}
                            <div className="case-view-note-date">
                              {displayPSTDateTimeStamp(note.modified_date)}{' '}
                              <div className="pull-right dropdown">
                                <FontAwesomeIcon
                                  icon={['fas', 'list-ul']}
                                  type="button"
                                  id="dropdownMenu1"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                  aria-hidden="true"
                                />
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                  <li className={!is_note_creator ? 'note-disabled' : ''}>
                                    {/* eslint-disable-next-line */}
                                    <a data-note_id={note.id} onClick={this.setNoteToEdit} disabled={this.state.prospect_notes}>
                                      {!is_note_creator && <LockedIcon />}
                                      Edit
                                    </a>
                                  </li>
                                  <li className={!is_note_creator ? 'note-disabled' : ''}>
                                    {/* eslint-disable-next-line */}
                                    <a data-note_id={note.id} onClick={this.setNoteToDelete} disabled={this.state.prospect_notes}>
                                      {!is_note_creator && <LockedIcon />}
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <DisplayProspectNotes note={note} />
                          </div>
                        );
                      }}
                    </UserPermissionsContext.Consumer>
                  );
                })
            : 'No notes found...'}
        </div>
        <div className="row prospect-contact-information">
          <div className="float-right prospect-edit-button">
            <Button onClick={this.openCreateNote}>Create</Button>
          </div>
        </div>
      </>
    );
  };

  /**
   * Display prospect summary data
   * @function
   */
  displaySummaryContainer = () => {
    return (
      <>
        <div className="case-summary-container">
          <CardContainer className="pad-md">
            <PatientInfo
              first_name={this.displayQuestionValue(this.state.questions, 'first_name')}
              last_name={this.displayQuestionValue(this.state.questions, 'last_name')}
              dob={this.displayQuestionValue(this.state.questions, 'dob')}
              sex={this.displayQuestionValue(this.state.questions, 'sex')}
              status={this.state.prospect_data.prospect_status ? this.displayStatus(this.state.prospect_data.prospect_status) : 'N/A'}
              prospect_status={
                this.state.prospect_statuses.filter((status) => {
                  return status.prospect_status === this.state.prospect_data.prospect_status && status.current_ind;
                })[0]
              }
              last_recorded_update={dateToFormatted(this.state.prospect_data.last_updated_time)}
              case_id={this.state.prospect_data.case_id}
              doctor_id={this.state.doctor_id}
            />
          </CardContainer>
          <CardContainer className="pad-md">{this.displayNotes()}</CardContainer>
        </div>
      </>
    );
  };

  /**
   * Display prospect detail container
   * @function
   */
  displayDetailsContainer = () => {
    return (
      <>
        <div className="case-detail-container">
          <div className="case-detail">
            <CardContainer type="top-border" className="case-view pad-lg">
              {this.displayProspectStatus()}
            </CardContainer>
            <CardContainer type="top-border" className="case-view pad-lg">
              {this.displayContactInformation()}
            </CardContainer>
            {this.displayScheduling()}
            {this.displayConsultation()}
            {this.displaySubmission()}
            <ActionBarEdit
              prospect_data={this.state.prospect_data}
              questions={this.state.questions}
              is_editing={this.state.edit_mode}
              is_held={this.is_held()}
              is_lost={this.is_lost()}
              is_converted={this.is_converted()}
              is_a_case={this.is_a_case()}
              onCancelClick={this.onCancelClick}
              onSaveClick={this.onSaveClick}
              hasActionInProgress={this.state.action}
              displayModal={this.displayModal}
              onDraftRedirect={this.onDraftRedirect}
              onCaseRedirect={this.onCaseRedirect}
              displayEditBtn={this.displayEditBtn()}
              disabled={this.state.edit_in_progress}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * Display modules related to notes
   * @function
   */
  displayNoteModules = () => {
    return (
      <>
        {this.state.prospect_notes ? (
          <CreatePatientNotes
            onCloseClick={this.closeCreateNote}
            id={this.state.prospect_id}
            patient_notes_id={this.state.notes_id}
            subject={this.state.notes_subject}
            note_text={this.state.notes_note_text}
            showUnsaveModal={this.state.showUnsaveModal}
            refresh={this.state.notes_refresh}
            onUnsaveModalLeave={() => this.setState({ showUnsaveModal: false, last_location: '' })}
            onDiscardConfirm={this.onDiscardNoteConfirm}
            refreshOff={() => this.setState({ notes_refresh: false })}
            getContent={(subject, notes_count) => {
              this.setState({
                notes_content: subject.length > 0 || notes_count > 0,
              });
            }}
            is_prospect={true}
          />
        ) : null}
        {this.state.notes_delete_modal ? (
          <Modal
            preset="decision"
            header_text={'Delete Note'}
            modal_class="modal-content-warning"
            message_text={
              <div className="case-view-note-modal">
                <span>Are you sure you want to delete this note?</span>
                <DisplayProspectNotes
                  note={{
                    note_text: this.state.notes_note_text,
                    subject: this.state.notes_subject,
                  }}
                />
              </div>
            }
            confirm_btn_text="Delete"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onConfirmDeleteNote}
            onCloseButtonClick={this.onCloseDeleteNote}
          />
        ) : null}
        {this.state.refresh ? (
          <Modal
            preset="action"
            x_btn={false}
            header_text="Session Expired"
            message_text="Sorry, your session has expired. Please refresh."
            confirm_btn_text="Refresh"
            onConfirmButtonClick={onReloadPage}
            theme="ipp"
          />
        ) : null}
        {this.state.failed_to_create ? (
          <Modal
            preset="action"
            x_btn={false}
            header_text="Unable to Create Prospect"
            message_text={this.state.failure_reason}
            confirm_btn_text="Ok"
            onConfirmButtonClick={this.onProspectFailedOkClicked}
            theme="ipp"
          />
        ) : null}
      </>
    );
  };

  /**
   * Whether to block the navgiation or not
   * @param {Object} location - The location to check
   * @returns {Boolean} Whether to block or not
   */
  shouldBlockNavigation(location) {
    const url = location.pathname;
    const tabs = ['/prospect/'];
    for (let tab of tabs) {
      if (url.indexOf(tab) !== -1) return false;
    }
    return true;
  }

  /**
   * Handles navigating away from case detail when editing notes
   *  - Boolean value indicates whether to allow the navigation or not
   * @param {Object} nextLocation - The next location object
   * @returns {Boolean} Whether to block or not
   */
  handleBlockedNavigation = (nextLocation) => {
    const { confirmed_navigation } = this.state;
    if (!confirmed_navigation && this.shouldBlockNavigation(nextLocation)) {
      this.showUnsavedNoteModal(nextLocation);
      return false;
    }
    return true;
  };

  render() {
    const title = 'Prospect Details';
    return (
      <div className="prospect-details">
        <Helmet>
          <title>{title} | InBrace Smile Design Studio™</title>
        </Helmet>
        <Prompt when={this.state.notes_content} message={this.handleBlockedNavigation} />
        <ContentHeader title={title} />
        {this.state.error ? (
          <div className="fullview">
            <NotFound />
          </div>
        ) : this.state.loading ? (
          <CircleLoader fullscreen={true} withBackground />
        ) : (
          <>
            <div className="content-body">
              {this.displaySummaryContainer()}
              {this.displayDetailsContainer()}
            </div>
          </>
        )}
        {this.onModalShow()}
        {this.displayNoteModules()}
      </div>
    );
  }
}

export default withRouter(ProspectDetails);
