import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

function getTeethClassname(teeth) {
  const isUpper = teeth.teeth_id <= 16;
  return cn('wasm-ipr-item', {
    'wasm-ipr--upper': isUpper,
    'wasm-ipr--lower': !isUpper,
    'wasm-ipr--missing': teeth.missing,
    'wasm-ipr--no-ipr': !teeth.has_ipr,
  });
}

function getIprValueContainerClassname(ipr) {
  const isIprNegative = ipr < 0;
  return cn('wasm-ipr-value-container', {
    'wasm-ipr-value-container--ipr': isIprNegative,
    'wasm-ipr-value-container--spacing': !isIprNegative,
  });
}

function IPRValue({ ipr, hasIpr }) {
  if (!ipr || !hasIpr) {
    return null;
  }

  const absIpr = Math.abs(ipr);
  const roundedIpr = `${Math.round(absIpr * 10) / 10}`;
  const displayedIpr = absIpr >= 1 ? roundedIpr : roundedIpr.slice(1);
  if (!displayedIpr) {
    return null;
  }

  return (
    <span className={getIprValueContainerClassname(ipr)}>
      <span className="wasm-ipr-value">{displayedIpr}</span>
    </span>
  );
}

IPRValue.propTypes = {
  ipr: PropTypes.number.isRequired,
  hasIpr: PropTypes.bool.isRequired,
};

function WasmIPRItem(props) {
  const { teeth, info } = props;

  return (
    <div className={getTeethClassname(info)}>
      {teeth}
      <IPRValue ipr={info.ipr} hasIpr={info.has_ipr} />
    </div>
  );
}

WasmIPRItem.propTypes = {
  teeth: PropTypes.string.isRequired,
  info: PropTypes.shape({
    teeth_ipr: PropTypes.number,
    ipr: PropTypes.number,
    missing: PropTypes.bool,
    has_ipr: PropTypes.bool,
  }).isRequired,
};

export default WasmIPRItem;
