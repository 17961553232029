// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';

/**
 * Displays list of provider tiers
 * @alias ProviderTiersList
 * @component
 * @category BPP
 * @subcategory Tier List
 */
class TierList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Retrieves tier number to display based on tier order
   * @function
   * @param tier {object} - Tier object
   * @return {number} - Tier number
   */
  getProviderTierNumber = (tier) => {
    return (
      this.props.provider_tiers
        .map(function (t) {
          return t.provider_tier_id;
        })
        .indexOf(tier.provider_tier_id) + 1
    );
  };

  render() {
    return (
      <div className="tiers">
        <div className="settings-heading content-heading">Internal Target Date Settings</div>
        <table>
          <thead>
            <tr className="settings-heading">
              <th>No.</th>
              <th>Provider Tier</th>
            </tr>
          </thead>
          <tbody>
            {this.props.provider_tiers_view.map((tier, index) => {
              return (
                <tr
                  className={this.props.selected_tier_id === tier.provider_tier_id ? 'tier-selected' : this.props.edit_mode ? 'tier-disabled' : ''}
                  onClick={() => this.props.onTierClick(tier.provider_tier_id)}
                  key={index}
                >
                  <td>{this.getProviderTierNumber(tier)}</td>
                  <td>
                    {tier.tier_name}
                    {this.props.selected_tier_id === tier.provider_tier_id && <i className="fa fa-caret-right" aria-hidden="true" />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TierList;
