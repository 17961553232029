import React from 'react';
import PropTypes from 'prop-types';
import { getProviderTierInfo } from './dashboard_helpers';

/**
 * Provider Details Card Component
 * @param props {item}
 * @returns {JSX}
 */
function ProviderTierDetailsItem(props) {
  const { item, provider_tier } = props;
  const { current } = getProviderTierInfo(provider_tier);
  const itemName = item.name.replace(' Provider', '');
  const currentTier = current.name === itemName ? true : false;

  return currentTier ? (
    <div key={item.name} className="provider-tier-item current">
      <div className="padding-wrapper">
        <div className="tier-banner">CURRENT TIER</div>
        <div className="provider-tier-item__inner">
          <span className="emphasis">{item.name}</span>
          <img src={item.logo} className="provider-tier-logo--lg" alt="provider logo" />
          <div>{item.requirements}</div>
          <div>Per Quarter</div>
        </div>
      </div>
    </div>
  ) : (
    <div key={item.name} className={`provider-tier-item ${currentTier && 'current'}`}>
      <div className="provider-tier-item__inner">
        <span className="emphasis">{item.name}</span>
        <img src={item.logo} className="provider-tier-logo--lg" alt="provider logo" />
        <div>{item.requirements}</div>
        <div>Per Quarter</div>
      </div>
    </div>
  );
}

ProviderTierDetailsItem.propTypes = {
  item: PropTypes.object.isRequired,
  provider_tier: PropTypes.object.isRequired,
};

export default ProviderTierDetailsItem;
