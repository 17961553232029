// ---------------------------------- Imports ----------------------------------
// Css
import './profile_picture.scss';
// External Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Internal
import { drawProfilePicture } from '../../common/functions';

// Redux
import { getProfileMetadata, getProfileImage, getProfileMetadataDsoDoctor, getProfileImageDsoDoctor } from '../../redux/reducers/ipp/account_settings';

/**
 * This modal is used for the doctor to be able to delete their profile picture.
 *
 * @component
 * @alias DeleteProfilePicture
 * @category IPP
 */

class DeleteProfilePicture extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      profile_picture_width_in_px: 100,
    };
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    drawProfilePicture(this, this.props.is_edit_dso_doctor_account_page);
  }

  render() {
    return (
      <React.Fragment>
        <div>Are you sure you want to delete your profile picture?</div>
        <div className="delete-picture">
          <canvas
            width={this.state.profile_picture_width_in_px}
            height={this.state.profile_picture_width_in_px}
            ref={this.canvasRef}
            className="delete-picture"
          ></canvas>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile_metadata: getProfileMetadata(state),
    profile_image: getProfileImage(state),
    profile_metadata_dso_doctor: getProfileMetadataDsoDoctor(state),
    profile_image_dso_doctor: getProfileImageDsoDoctor(state),
  };
};

export default connect(mapStateToProps, null)(DeleteProfilePicture);
