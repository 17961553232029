import Axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import RichText from '../../components/richtext/rich_text';
import Modal from '../../components/modal/modal';
import DisplayPatientNotes from './display_patient_notes';

import { handleHttpRequestError } from '../../common/error';
import { modalClear, removeEmoji } from '../../common/functions';
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import TextBox from '../components/inputs/text_box';

/**
 * Window pop up for creating a notes on the IPP
 * @component
 * @alias DoctorCreatePatientNotes
 * @category IPP
 */
class CreatePatientNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backdrop: false,
      size: 'default',
      subject: this.props.subject ? this.props.subject : '',
      note_text: this.props.note_text ? this.props.note_text : '',
      patient_notes_id: this.props.patient_notes_id,
      char_count: 0,
      char_count_max: 5000,
      load: true,
      unsaved_modal: false,
      has_changed: false,
      save_in_progress: false,
    };
  }

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
    if (this.props.refresh && this.props.refresh === true && this.state.size !== 'default') {
      this.setState({ size: 'default' });
      this.props.refreshOff();
    }
  }
  /**
   * Calls the API to create a note and closes out note window
   * @function
   */
  onCreateNotesClick = () => {
    this.setState({ save_in_progress: true });
    let json = {
      id: this.state.patient_notes_id,
      subject: this.state.subject,
      note_text: this.state.note_text,
    };
    json[this.props.is_prospect ? 'prospect_id' : 'case_id'] = this.props.id;

    if (this.props.is_prospect) {
      let that = this;
      if (this.props.notes_id) {
        Axios.put(`/apiv3/globalnotes/${that.props.notes_id}`, json)
          .then((res) => {
            that.props.onCloseClick(res);
            that.setState({ save_in_progress: false });
          })
          .catch((err) => handleHttpRequestError(err, this));
      } else {
        Axios.post(`/apiv3/globalnotes`, json)
          .then((res) => {
            that.props.onCloseClick(res);
            that.setState({ save_in_progress: false });
          })
          .catch((err) => handleHttpRequestError(err, this));
      }
    } else {
      Axios.put(`/apiv3/patientnotes`, json)
        .then((res) => {
          this.props.onCloseClick(res);
          this.props.onCreateCallBack();
          this.setState({ save_in_progress: false });
        })
        .catch((err) => handleHttpRequestError(err, this));
    }
  };

  /**
   * Handles the subject text change by updating the state
   * @param {Object} event - Event that is associated with Input field for the subject
   * related information
   * @function
   */
  onSubjectTextChange = (event) => {
    const subject = removeEmoji(event.target.value);
    this.setState({
      subject: subject,
      has_changed: true,
    });

    if (this.props.getContent) this.props.getContent(event.target.value, this.state.char_count);
    if (this.props.onNoteSubjectChange) this.props.onNoteSubjectChange(subject);
  };
  /**
   * Sets the text and count
   * @param {String} content - Contains the html version of the content
   * @param {Object} delta - Contains the change made since the last instance of react quill
   * @param {String} source - Identify who made the change, default is user
   * @param {Object} editor - Contains the editor object and its helper functions
   * @function
   */
  onNoteTextChange = (content, delta, source, editor) => {
    const text = removeEmoji(editor.getText(content));
    const char_count = text.length - 1; //minus 1 due to hidden enter char
    this.setState({
      note_text: removeEmoji(content),
      char_count: char_count,
      has_changed: true,
    });

    if (this.props.getContent) this.props.getContent(this.state.subject, char_count);
    if (this.props.onNoteTextChange) this.props.onNoteTextChange(removeEmoji(content), char_count, this.state.subject);
  };
  /**
   * Check to see if this is a note being edited
   * @function
   */
  isEditingNote = () => {
    const patient_notes_id = this.state.patient_notes_id;
    return this.props.is_editing !== undefined ? this.props.is_editing : patient_notes_id && patient_notes_id !== '';
  };

  /**
   * Toggle to min view or default view
   * @function
   */
  onMinimizeClick = () => {
    this.setState({
      size: this.state.size === 'min' ? 'default' : 'min',
    });
  };
  /**
   * Toggle to max view or default view
   * @function
   */
  onMaximizeClick = () => {
    this.setState({
      size: this.state.size === 'max' ? 'default' : 'max',
    });
  };
  /**
   * Show correct icon based on create note window state
   * @function
   */
  showMaximizeIcon = () => {
    const classname = this.state.size === 'max' ? 'fa fa-compress' : 'fa fa-expand';
    const title = this.state.size === 'max' ? 'Exit Full Screen' : 'Full Screen';
    return <i className={classname} aria-hidden onClick={this.onMaximizeClick} data-toggle="tooltip" data-original-title={title} />;
  };
  /**
   * Flip icon based on create note window state
   * @function
   */
  showMinimizeIcon = () => {
    const classname = this.state.size === 'min' ? 'fa fa-window-maximize' : 'fa fa-window-minimize';
    const title = this.state.size === 'min' ? 'Maximize' : 'Minimize';
    return <i className={classname} aria-hidden onClick={this.onMinimizeClick} data-toggle="tooltip" data-original-title={title} />;
  };
  /**
   * Runs after quill is loaded and passes back the editor to handle additional
   * computation, such as text length
   * @param {Object} editor - Contains the editor object and its helper functions
   * @function
   */
  onQuillLoad = (editor) => {
    if (this.state.load) {
      const text = editor.getText(this.state.note_text);

      this.setState({
        char_count: text.length - 1, //minus 1 due to hidden enter char
        load: false,
      });
    }
  };
  /**
   * Determined to show Unsaved Dialog when content exist or close
   * @function
   */
  onXClick = () => {
    if (this.state.has_changed) {
      this.setState({ unsaved_modal: true });
    } else {
      this.props.onCloseClick();
    }
  };
  /**
   * Discard all note content
   * @function
   */
  onConfirmUnsaved = () => {
    this.setState({ unsaved_modal: false });

    modalClear();
    this.props.onCloseClick();
    this.props.onDiscardConfirm();
  };
  /**
   * Cancel discarding changes modal dialog
   * @function
   */
  onCancelUnsaved = () => {
    this.setState({ unsaved_modal: false });

    modalClear();
    this.props.onUnsaveModalLeave();
  };

  displayContent = () => {
    return (
      <>
        {!this.props.is_modal && (
          <div className="note-heading">
            <h3>
              {this.isEditingNote() ? 'Edit Note' : 'Create Note'}
              {this.state.has_changed && '*'}
            </h3>
            <span className="note-container-controls">
              {this.showMinimizeIcon()}
              {this.showMaximizeIcon()}
              <i className="fa fa-times" aria-hidden onClick={this.onXClick} data-toggle="tooltip" title="Discard & close" />
            </span>
          </div>
        )}
        <div className="note-subject">
          <h5>Subject</h5>
          <TextBox
            type="text"
            placeholder="Subject (Optional)"
            aria-describedby="subject"
            onChange={this.onSubjectTextChange}
            value={this.state.subject}
            maxLength="250"
          />
        </div>
        <div className="note-react-quill">
          <h5>Body</h5>

          <RichText
            value={this.state.note_text}
            handleChange={this.onNoteTextChange}
            placeholder="Enter note here..."
            edit_in_progress={true}
            max_length={this.state.char_count_max}
            onLoad={this.onQuillLoad}
          />
        </div>
        <div className="note-counter">
          {this.state.char_count}/{this.state.char_count_max}
        </div>
        {!this.props.is_modal && (
          <div className="note-footer">
            <Button disabled={this.state.char_count === 0 || this.state.save_in_progress} onClick={this.onCreateNotesClick}>
              {this.isEditingNote() ? 'Save' : 'Create'}
            </Button>
          </div>
        )}
      </>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className={this.props.is_modal ? '' : `note-window note-window--${this.state.size}`}>
          {this.props.is_modal ? this.displayContent() : <CardContainer type="top-border">{this.displayContent()}</CardContainer>}
        </div>
        {this.state.unsaved_modal || this.props.showUnsaveModal ? (
          <Modal
            preset="decision"
            header_text={this.state.patient_notes_id ? 'Unsaved Changes' : 'Unsaved Note'}
            modal_class="modal-content-warning"
            message_text={
              <div className="case-view-note-modal">
                <span>
                  {this.state.patient_notes_id
                    ? 'Are you sure you want to discard your unsaved changes?'
                    : 'Are you sure you want to discard your unsaved note?'}
                </span>
                <DisplayPatientNotes note={{ note_text: this.state.note_text, subject: this.state.subject }} />
              </div>
            }
            confirm_btn_text="Discard"
            close_btn_text="Do Not Discard"
            onConfirmButtonClick={this.onConfirmUnsaved}
            onCloseButtonClick={this.onCancelUnsaved}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

CreatePatientNotes.propTypes = {
  subject: PropTypes.string,
  id: PropTypes.string,
  note_text: PropTypes.string,
  patient_notes_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onCloseClick: PropTypes.func,
  showUnsaveModal: PropTypes.bool,
  onUnsaveModalLeave: PropTypes.func,
  onDiscardConfirm: PropTypes.func,
};

export default CreatePatientNotes;
