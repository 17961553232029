import './button.scss';

import React from 'react';

/**
 * Displays the buttons for the setup viewer
 * @component
 * @alias SetupViewerButton
 */
export default function Button(props) {
  const themes = {
    success: '--success',
    danger: '--danger',
    secondary: '--secondary',
  };

  return (
    <button className={`button button${themes[props.theme]} fade-in`} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  );
}
