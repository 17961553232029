import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import PropTypes from 'prop-types';

import { textFieldLimited } from '../../common/functions';
import { splitStrToArray } from './edit_tx_de_plan';
import TxPlan from '../../doctor/case_submission/tx_plan';
import ErrorMessage from '../../doctor/components/container/error_message';

// Most of the code are copied from case_submission for tx plan editing
export class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

/**
 * Displays the TX plan editing form
 * @component
 * @alias EditTxPlan
 * @category BPP
 */
class EditTXPlan extends Component {
  state = {
    // the treatment_plan from props is stored as strings in the database, need to parse
    ...this.props.treatment_plan,
    conditions: this.removeCrowdingAndSpacingInfo(JSON.parse(this.props.treatment_plan.conditions)),
    bracketTeeth: splitStrToArray(this.props.treatment_plan.bracketTeeth),
    extractTeeth: splitStrToArray(this.props.treatment_plan.extractTeeth),
    missingTeeth: splitStrToArray(this.props.treatment_plan.missingTeeth),
    restrictTeeth: splitStrToArray(this.props.treatment_plan.restrictTeeth),
  };

  removeCrowdingAndSpacingInfo(conditions) {
    for (const cond in conditions) {
      if (cond === 'crowding' || cond === 'spacing') {
        delete conditions[cond].info;
      }
    }
    return conditions;
  }

  componentDidUpdate() {
    // The save_tx_plan_changes boolean flag is used to determine if the save button has been clicked or not
    if (this.props.save_tx_plan_changes && this.props.treatment_plan) {
      // check for changes, complete
      if (this.hasTxPlanField() && !this.isTxPlanUnChanged()) {
        this.postTXPlan();
      } else {
        this.props.changeSaveValue(); // set save_tx_plan_changes to false
      }
    }
  }

  /**
   * Performs a POST request to save the form and POST request to send email
   * @function
   */
  postTXPlan() {
    const newTxPlan = {
      description: 'Treatment Plan Edited - ' + this.props.description.trim(),

      archToTreat: this.state.archToTreat,
      chief_complaint: this.state.chief_complaint.trim(),
      opposingArch: this.state.opposingArch,
      notes: this.state.notes.trim(),
      notes_spacing: this.state.notes_spacing.trim(),

      missingTeeth: this.state.missingTeeth.toString(),
      extractTeeth: this.state.extractTeeth.toString(),
      bracketTeeth: this.state.bracketTeeth.toString(),
      restrictTeeth: this.state.restrictTeeth.toString(),

      conditions: JSON.stringify(this.state.conditions),
      impliedTeethSets: JSON.stringify(this.getImpliedTeethSets()),
    };

    this.props.setStatus({ loading: true, submit: true });
    Axios.post(`/apiV2/treatmentplan/${this.props.case_id}`, newTxPlan)
      .then((resp) => {
        // sends email regarding treatment plan changes
        Axios.post(`/api/email/?slug=treatment-form-change&caseId=${this.props.case_id}&method=standard&provider=${window.location.origin}`);

        this.props.reloadInformation();
        this.props.setStatus({ loading: false });
      })
      .catch((err) => {
        this.props.setStatus({ loading: false, error: true });
      });
  }

  /**
   * Checks whether the treatment plan has been changed
   * @function
   * @return {Boolean} Whether treatment plan has been changed
   */
  isTxPlanUnChanged() {
    const bracketTeeth = this.props.treatment_plan.bracketTeeth.split(',').filter(String);
    const extractTeeth = this.props.treatment_plan.extractTeeth.split(',').filter(String);
    const missingTeeth = this.props.treatment_plan.missingTeeth.split(',').filter(String);
    const restrictTeeth = this.props.treatment_plan.restrictTeeth.split(',').filter(String);

    const isUnChanged =
      this.props.treatment_plan.archToTreat === this.state.archToTreat &&
      this.props.treatment_plan.chief_complaint === this.state.chief_complaint &&
      this.props.treatment_plan.opposingArch === this.state.opposingArch &&
      this.props.treatment_plan.notes === this.state.notes &&
      this.props.treatment_plan.notes_spacing === this.state.notes_spacing &&
      _.intersection(bracketTeeth, this.state.bracketTeeth).length === bracketTeeth.length &&
      _.intersection(extractTeeth, this.state.extractTeeth).length === extractTeeth.length &&
      _.intersection(missingTeeth, this.state.missingTeeth).length === missingTeeth.length &&
      _.intersection(restrictTeeth, this.state.restrictTeeth).length === restrictTeeth.length &&
      bracketTeeth.sort().join('') === this.state.bracketTeeth.sort().join('') &&
      extractTeeth.sort().join('') === this.state.extractTeeth.sort().join('') &&
      missingTeeth.sort().join('') === this.state.missingTeeth.sort().join('') &&
      restrictTeeth.sort().join('') === this.state.restrictTeeth.sort().join('') &&
      !this.hasConditionsChange();

    if (isUnChanged) {
      const warnings = '<li>No changes have been made. Please update the treatment plan form to save.</li>';
      this.props.showWarning(warnings);
    }

    return isUnChanged;
  }

  /**
   * Checks whether the conditions in the treatment plan has been changed
   * @function
   * @return {Boolean} Whether treatment plan's condition has been changed
   */
  hasConditionsChange() {
    let result = false;
    const save_conditions = JSON.parse(this.props.treatment_plan.conditions);
    const state_conditions = this.state.conditions;
    const condition_names = ['ap_relationship', 'midlines', 'crowding', 'spacing', 'overject', 'deepbite', 'openbite', 'crossbite'];

    for (let i = 0; i < condition_names.length; i++) {
      if (!result) {
        for (let key in save_conditions[condition_names[i]]) {
          if (key !== 'info' && key !== 'title' && key !== 'infoEl' && save_conditions[condition_names[i]][key] !== state_conditions[condition_names[i]][key]) {
            //Array Comparision
            if (key === 'spaceTeeth') {
              result =
                _.intersection(save_conditions[condition_names[i]][key], state_conditions[condition_names[i]][key]).length !==
                save_conditions[condition_names[i]][key].length;
            } else {
              result = true;
            }

            break;
          }
        }
      } else {
        break;
      }
    }

    return result;
  }

  /**
   * Sets the state as the new state
   * @function
   * @param {Object} newState - The new state
   */
  setPlanState = (newState) => {
    // TxPlan 2.0 State
    const chief_complaint = newState['chief_complaint'] !== undefined ? textFieldLimited(newState['chief_complaint']) : this.state.chief_complaint;
    const missingTeeth = newState['missingTeeth'] !== undefined ? newState['missingTeeth'] : this.state.missingTeeth;
    const extractTeeth = newState['extractTeeth'] !== undefined ? newState['extractTeeth'] : this.state.extractTeeth;
    const archToTreat = newState['archToTreat'] !== undefined ? newState['archToTreat'] : this.state.archToTreat;
    const opposingArch = newState['opposingArch'] !== undefined ? newState['opposingArch'] : this.state.opposingArch;
    const bracketTeeth = newState['bracketTeeth'] !== undefined ? newState['bracketTeeth'] : this.state.bracketTeeth;
    const restrictTeeth = newState['restrictTeeth'] !== undefined ? newState['restrictTeeth'] : this.state.restrictTeeth;
    const notes = newState['notes'] !== undefined ? textFieldLimited(newState['notes']) : this.state.notes;
    const notes_spacing = newState['notes_spacing'] !== undefined ? textFieldLimited(newState['notes_spacing']) : this.state.notes_spacing;

    this.setState({
      chief_complaint: chief_complaint,
      archToTreat: archToTreat,
      missingTeeth: missingTeeth,
      extractTeeth: extractTeeth,
      opposingArch: opposingArch,
      bracketTeeth: bracketTeeth,
      restrictTeeth: restrictTeeth,
      notes: notes,
      notes_spacing: notes_spacing,
    });

    this.props.clearWarning();
    this.metAllPlanRequiredField({ archToTreat: archToTreat, notes_spacing: notes_spacing, chief_complaint: chief_complaint, opposingArch: opposingArch });
  };

  /**
   * Checks whether there is at least one condition in the treatment plan
   * @function
   * @return {Boolean} Whether there is at least one condition
   */
  hasOneClinicalCondition() {
    return (
      this.state.conditions.ap_relationship.active ||
      this.state.conditions.midlines.active ||
      this.state.conditions.crowding.active ||
      this.state.conditions.spacing.active ||
      this.state.conditions.overjet.active ||
      this.state.conditions.deepbite.active ||
      this.state.conditions.openbite.active ||
      this.state.conditions.crossbite.active
    );
  }

  /**
   * Returns whether met clinical conditions required fields
   *
   * @function
   * @param {Object} [conditions] - Treatment plan conditions
   * @param {String} [notes_spacing] - Additional spacing instructions
   * @param {String} [arch_to_treat] - Arch to treat
   * @param {String} [opposingArch] - Treatment of opposing arch
   * @return {Boolean} Whether required clinical conditions fields met
   */
  hasClinicalConditionRequireFieldMet(
    conditions = this.state.conditions,
    notes_spacing = this.state.notes_spacing,
    arch_to_treat = this.state.archToTreat,
    opposingArch = this.state.opposingArch
  ) {
    let hasMet = true;

    if (conditions.ap_relationship.active && conditions.ap_relationship.ap_improvement === 'auxiliaries' && conditions.ap_relationship.aux_note === '') {
      hasMet = false;
    } else if (conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
      hasMet = false;
    } else if (conditions.spacing.active && !this.hasSpacingValue(conditions) && notes_spacing === '') {
      hasMet = false;
    } else if (conditions.overjet.active && conditions.overjet.improve === 'auxiliaries' && conditions.overjet.aux_note === '') {
      hasMet = false;
    } else if (conditions.deepbite.active && conditions.deepbite.correct === 'auxiliaries' && conditions.deepbite.aux_note === '') {
      hasMet = false;
    } else if (
      (conditions.crossbite.active &&
        (arch_to_treat === 'both' || (arch_to_treat === 'upper' && opposingArch === 'ideal') || (arch_to_treat === 'lower' && opposingArch === 'ideal')) &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_lower &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'upper' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'lower' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_narrow_lower)
    ) {
      hasMet = false;
    }

    return hasMet;
  }

  /**
   * Returns whether all required fields are filled out.
   *
   * - For any missing field, adds warning classes to the corresponding div
   * @function
   * @return {Boolean} Whehter the treatment plan has all required fields
   */
  hasTxPlanField() {
    if (
      this.state.chief_complaint === '' ||
      this.state.archToTreat === '' ||
      (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) ||
      !this.hasOneClinicalCondition() ||
      !this.hasClinicalConditionRequireFieldMet() ||
      this.props.description.trim() === ''
    ) {
      let warnings = '';

      if (this.props.description.trim() === '') {
        if (document.querySelector('#tx_edit_desrcription_title')) {
          document.querySelector('#tx_edit_desrcription_title').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_heading')) {
          document.querySelector('#tx_edit_desrcription_heading').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_textarea')) {
          document.querySelector('#tx_edit_desrcription_textarea').classList.add('warning-border');
        }
        warnings = warnings + '<li><a href="#tx_edit_desrcription_heading">Description of Changes</a></li>';
      }

      if (this.state.chief_complaint.trim() === '') {
        document.querySelector('#chief_complaint').classList.add('warning-border');
        document.querySelector('#chief_complaint_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#chief_complaint_title">Patient Chief Complaint</a></li>';
      }

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.hasOneClinicalCondition()) {
        document.querySelector('#clinical_condition').classList.add('warning-text');
        warnings = warnings + '<li><a href="#clinical_condition">Missing Clinical Condition</a></li>';
      }

      if (
        this.state.conditions.ap_relationship.active &&
        this.state.conditions.ap_relationship.ap_improvement === 'auxiliaries' &&
        this.state.conditions.ap_relationship.aux_note === ''
      ) {
        document.querySelector('#ap_relationship_title').classList.add('warning-text');
        document.querySelector('#ap_relationship_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#ap_relationship_title">AP Relationship</a></li>';
      }

      if (this.state.conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
        document.querySelector('#crowding_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crowding_title">Crowding</a></li>';
      }

      if (this.state.conditions.spacing.active && !this.hasSpacingValue() && this.state.notes_spacing === '') {
        document.querySelector('#spacing_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#spacing_title">Spacing</a></li>';
      }

      if (this.state.conditions.overjet.active && this.state.conditions.overjet.improve === 'auxiliaries' && this.state.conditions.overjet.aux_note === '') {
        document.querySelector('#overjet_title').classList.add('warning-text');
        document.querySelector('#overjet_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#overjet_title">Overjet</a></li>';
      }

      if (this.state.conditions.deepbite.active && this.state.conditions.deepbite.correct === 'auxiliaries' && this.state.conditions.deepbite.aux_note === '') {
        document.querySelector('#deep_bite_title').classList.add('warning-text');
        document.querySelector('#deep_bite_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#deep_bite_title">Deep Bite</a></li>';
      }

      if (
        (this.state.conditions.crossbite.active &&
          (this.state.archToTreat === 'both' ||
            (this.state.archToTreat === 'upper' && this.state.opposingArch === 'ideal') ||
            (this.state.archToTreat === 'lower' && this.state.opposingArch === 'ideal')) &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_lower &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'upper' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'lower' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_narrow_lower)
      ) {
        document.querySelector('#crossbite_transverse_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crossbite_transverse_title">Crossbite/Transverse</a></li>';
      }

      this.props.showWarning(warnings);
      return false;
    }

    return true;
  }

  /**
   * Returns whether crowding has at least one selection
   *
   * @function
   * @return {Boolean} Whether crowding has at least one selection
   */
  crowdingHasOneValidSelection() {
    const fields = [
      'lower_expand',
      'lower_ipr_ant',
      'lower_ipr_left',
      'lower_ipr_right',
      'lower_procline',
      'upper_expand',
      'upper_ipr_ant',
      'upper_ipr_left',
      'upper_ipr_right',
      'upper_procline',
    ];

    const result = fields.filter((field) => this.state.conditions.crowding[field] !== 'none');

    return result.length > 0;
  }

  /**
   * Returns whether condition spacing is filled out
   *
   * @param {Object} [conditions] - Treatment plan conditions
   * @return {Boolean} Whether spacing in condition is filled out
   */
  hasSpacingValue(conditions = this.state.conditions) {
    const spacing = conditions.spacing.spacingUpper.concat(conditions.spacing.spacingLower);
    const result = spacing.filter((space_value) => space_value !== '');
    return result.length > 0 || conditions.spacing.spaces === 'close';
  }

  /**
   * Returns whether met all required fields.
   *
   * @function
   * @param {Object} [values={}] - The values for treatment plan
   * @return {Boolean} Whether met all required fields
   */
  metAllPlanRequiredField = (values = {}) => {
    const chief_complaint = values['chief_complaint'] !== undefined ? values['chief_complaint'] : this.state.chief_complaint;
    const notes_spacing = values['notes_spacing'] !== undefined ? values['notes_spacing'] : this.state.notes_spacing;
    const arch_to_treat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
    const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
    const conditions = values['conditions'] ? values['conditions'] : this.state.conditions;
    const opposingArch = values['opposingArch'] ? values['opposingArch'] : this.state.opposingArch;

    const hasMet =
      chief_complaint &&
      arch_to_treat &&
      bracketTeeth &&
      bracketTeeth.length > 0 &&
      (conditions.ap_relationship.active ||
        conditions.midlines.active ||
        conditions.crowding.active ||
        conditions.spacing.active ||
        conditions.overjet.active ||
        conditions.deepbite.active ||
        conditions.openbite.active ||
        conditions.crossbite.active) &&
      this.hasClinicalConditionRequireFieldMet(conditions, notes_spacing, arch_to_treat, opposingArch) &&
      (!conditions.spacing.active ||
        (conditions.spacing.active &&
          (notes_spacing !== '' || conditions.spacing.spacingUpper.join().length > 0 || conditions.spacing.spacingLower.join().length > 0)) ||
        (conditions.spacing.active && conditions.spacing.spaces === 'close'));

    return hasMet;
  };

  /**
   * Changes the condition state of a condition
   *
   * @function
   * @param {String} cnd - The condition to set
   * @param {Object} newState - The new condition state
   */
  setPlanConditionState = (cnd, newState) => {
    let conds = { ...this.state.conditions };
    let oldState = { ...this.state.conditions[cnd] };

    Object.assign(oldState, newState);

    conds[cnd] = oldState;

    // TxPlan 2.0 Condition State
    this.setConditionState(conds);
    this.metAllPlanRequiredField({ conditions: conds });
  };

  /**
   * Changes the entire condition in the state
   *
   * @function
   * @param {Object} conds - The new condition state
   */
  setConditionState = (conds) => {
    this.setState({
      conditions: conds,
    });

    this.props.clearWarning();
  };

  /**
   * Handles clicking on teethsets
   *
   * @function
   * @param {String} teethSet - The teethset to set
   */
  handleTeethClick = (teethSet) => {
    return (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);

      this.setPlanState(ns);

      if (teethSet === 'bracketTeeth') {
        this.metAllPlanRequiredField({ bracketTeeth: ns[teethSet] });
      }

      this.props.clearWarning();
    };
  };

  /**
   * Returns the implied teeth set base on the current state
   *
   * @function
   * @return {Object} Implied teeth set
   */
  getImpliedTeethSets = () => {
    let opposingTeeth = [];
    let hasUpper = this.state.archToTreat !== 'lower';
    let hasLower = this.state.archToTreat !== 'upper';
    let setupIdeal = this.state.archToTreat !== 'both' && this.state.opposingArch === 'ideal'; //&& this.state.opposingArch == "ideal";
    let setupCurrent = this.state.archToTreat !== 'both' && this.state.opposingArch === 'current'; //this.state.opposingArch == "current";

    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    let missingTeeth = this.state.missingTeeth;
    let extractTeeth = _.difference(this.state.extractTeeth, missingTeeth);
    let extractDisabled = missingTeeth;
    let extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    let bracketTeeth = _.difference(this.state.bracketTeeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.restrictTeeth, restrictImplied);
    if (setupCurrent) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    let tset = {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };

    return tset;
  };

  render() {
    return (
      <>
        <TxPlan
          getImpliedTeethSets={this.getImpliedTeethSets}
          setPlanState={this.setPlanState}
          setConditionState={this.setConditionState}
          setPlanConditionState={this.setPlanConditionState}
          handleTeethClick={this.handleTeethClick}
          onFocus={this.props.clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          hideAlert
          photoUpload={this.props.photoUpload}
          {...this.state}
        />
        <ErrorMessage
          title="Incomplete Fields"
          onClose={this.props.clearWarning}
          className={this.props.warning ? 'error-message-container' : 'error-message-container hide'}
        >
          <div id="warning-submit">Warning</div>
        </ErrorMessage>
      </>
    );
  }
}

EditTXPlan.propTypes = {
  /**
   * Case ID
   */
  case_id: PropTypes.string.isRequired,
  /**
   * Raw treatment plan with each value as string
   */
  treatment_plan: PropTypes.object.isRequired,
  /**
   * Whether the save button had been clicked
   */
  save_tx_plan_changes: PropTypes.bool.isRequired,
  /**
   * To change save_tx_plan_changes value to false
   */
  changeSaveValue: PropTypes.func.isRequired,
  /**
   * To reload the case details
   */
  reloadInformation: PropTypes.func.isRequired,
  /**
   * To close the modal after successful saving
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Description of changes
   */
  description: PropTypes.string.isRequired,
  /**
   * To display warnings or not
   */
  warning: PropTypes.bool.isRequired,
  /**
   * To show warnings
   */
  showWarning: PropTypes.func.isRequired,
  /**
   * To clear warnings
   */
  clearWarning: PropTypes.func.isRequired,
  /**
   * To set submit, warning, loading, and error status
   */
  setStatus: PropTypes.func.isRequired,
};

export default EditTXPlan;
