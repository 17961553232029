// Css
import '../settings.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';

// Internal
import { handleHttpRequestError } from '../../../common/error';
import SessionExpire from '../../../common/session_expire';
import { targetDaysNumberChar } from '../../../common/functions';
import AccountTypes from './account_types';
import CircleLoader from '../../../components/loader/circle_loader';
import ShippingInfo from './shipping_info';
import { performSearch, displayNoResults } from '../search';
import Datalist from '../../../components/form/list/datalist';
import Modal from '../../../components/modal/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Displays the settings for external target dates
 * @alias ExternalTargetDateSettings
 * @component
 * @category BPP
 * @subcategory External Target Date Settings
 */
class ExternalTargetDateSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      loading: false,
      external_target_days: [],
      external_target_days_view: [],
      shipping_info_update: {},
      new_shipping_info: {
        initial_case: null,
        digital_enhancement: null,
        item_request: null,
        provider_info: { id: null, account_id: null, name: '' },
      },
      providers: [],
      selected_setting_id: 1,
      edit_mode: false,
      search: '',
      invalid_settings: [],
      create_errors: {
        has_error: false,
        provider: false,
        initial_case: false,
        digital_enhancement: false,
        item_request: false,
      },
      show_create_modal: false,
      show_edit_confirmation_modal: false,
      create_setting_in_progress: false,
      edit_setting_in_progress: false,
      delete_setting_in_progress: false,
    };
  }

  componentDidMount() {
    this.resetView();
    this.getAllAccounts();
  }

  /**
   * Get all account information then set state
   */
  getAllAccounts = () => {
    Axios.get('/apiV2/allaccounts').then((res) => {
      const accounts = res.data.accounts;
      const sorted = _.orderBy(accounts, ['first_name', 'last_name'], 'asc');
      const allAccounts = [];
      for (let account of sorted) {
        if (account.role === 'Doctor' && account.is_active) {
          const doctor = {};
          let name = `${account.first_name} ${account.last_name}`;
          doctor.key = name;
          doctor.value = JSON.stringify({ id: account.account_id, account_id: account.account_id, name: name });
          allAccounts.push(doctor);
        }
      }
      this.setState({
        providers: allAccounts,
      });
    });
  };

  /**
   * Resets External Target Dates View
   */
  resetView = (new_setting_id = null) => {
    const that = this;
    that.setState({
      loading: true,
    });
    Axios.get('/apiv3/externaltargetdays')
      .then(function (res) {
        const sorted_view = res.data.external_target_days.sort((a, b) => {
          return a.id - b.id;
        });
        const first_setting = _.cloneDeep(sorted_view[0]);
        const shipping_info = new_setting_id ? _.cloneDeep(res.data.external_target_days.find((item) => item.id === new_setting_id)) : first_setting;
        const selected_setting_id = new_setting_id ? new_setting_id : first_setting.id;
        that.setState({
          external_target_days: res.data.external_target_days,
          external_target_days_view: sorted_view,
          selected_setting_id: selected_setting_id,
          shipping_info_update: shipping_info,
          loading: false,
          show_create_modal: false,
          show_delete_modal: false,
          create_setting_in_progress: false,
          delete_setting_in_progress: false,
          new_shipping_info: {
            initial_case: null,
            digital_enhancement: null,
            item_request: null,
            provider_info: { id: null, account_id: null, name: '' },
          },
          create_errors: {
            initial_case: false,
            digital_enhancement: false,
            item_request: false,
            provider_info: false,
          },
        });
      })
      .catch(function (err) {
        console.log('error', err);
        handleHttpRequestError(err, that);
        this.setState({
          loading: false,
        })
      });
  };

  /**
   * Gets shipping information given a providers shipping info id
   * @param {int} setting_id
   * @returns {Object} shipping Info
   */
  getShippingInfo = (setting_id) => {
    const setting_info = this.state.external_target_days.find((item) => item.id === setting_id);
    const info_copy = _.cloneDeep(setting_info);
    return info_copy ? info_copy : {};
  };

  /**
   * Gets account_type value given a providers shipping info id
   * @param {int} setting_id
   * @returns account_type
   */
  getAccountType = (setting_id) => {
    const shipping_info = this.getShippingInfo(setting_id);
    if (!shipping_info?.account_type) return '';
    return shipping_info.account_type;
  };

  /**
   * Returns the appropriate header text for the delete modal
   * @param {int} setting_id
   * @returns header_text
   */
  getHeaderTextForDeleteModal = (setting_id) => {
    const account_type = this.getAccountType(setting_id);
    if (account_type === '') return 'Delete Settings';
    return `Delete Settings - ${account_type}`;
  };

  /**
   * Handles when user clicks on a tier
   * @function
   * @param provider_tier_id {number} - Tier id
   */
  onProviderClick = (setting_id) => {
    const setting_info = this.getShippingInfo(setting_id);
    this.setState({
      selected_setting_id: setting_id,
      shipping_info_update: setting_info,
    });
  };

  /**
   * Toggles edit mode for setting
   * @function
   * @param edit_mode {boolean} - True or false
   */
  toggleEditMode = (edit_mode) => {
    this.setState({
      edit_mode: edit_mode,
    });
  };

  /**
   * Updates shipping_info_update value on input change
   * @param {String} setting Shipping Setting Type
   * @param {String} value Input String gets parsed to int
   */
  shippingInfoChange = (setting, value) => {
    let business_days = targetDaysNumberChar(value);
    business_days = business_days ? parseInt(business_days) : business_days;
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      const setting_index = -new_state.invalid_settings.indexOf(setting);
      new_state.shipping_info_update[setting] = business_days;
      if (new_state.invalid_settings.includes(setting)) {
        new_state.invalid_settings.splice(setting_index, 1);
      }
      return new_state;
    });
  };

  /**
   * Determines if there is an error with settings input
   * @function
   * @return {boolean} - True or false
   */
  determineSettingsInputError = () => {
    let invalid_settings = [];
    const input_types = ['initial_case', 'digital_enhancement', 'item_request'];
    let has_error = false;
    for (const type of input_types) {
      if (!this.state.shipping_info_update[type]) {
        invalid_settings.push(type);
        has_error = true;
      }
    }
    this.setState({
      invalid_settings: invalid_settings,
    });
    return has_error;
  };

  /**
   * Saves tier updates via API
   * @function
   */
  onEditSave = () => {
    const setting_id = this.state.shipping_info_update.id;
    const prev_setting = this.getShippingInfo(setting_id);
    const that = this;
    if (this.determineSettingsInputError()) return;
    else {
      const new_shipping_info = _.cloneDeep(this.state.shipping_info_update);
      Axios.put('/apiv3/externaltargetdays', { original_shipping_info: prev_setting, shipping_info: this.state.shipping_info_update })
        .then((res) => {
          this.setState((prev_state) => {
            const new_state = { ...prev_state };
            let view_index = new_state.external_target_days_view.findIndex((item) => item.id === setting_id);
            let raw_index = new_state.external_target_days.findIndex((item) => item.id === setting_id);
            new_state.external_target_days_view[view_index] = new_shipping_info;
            new_state.external_target_days[raw_index] = new_shipping_info;
            new_state.edit_mode = false;
            new_state.edit_setting_in_progress = false;
            new_state.show_edit_confirmation_modal = false;
            return new_state;
          });
        })
        .catch((error) => {
          console.log('error: ', error);
          this.setState({
            edit_mode: false,
            edit_setting_in_progress: false,
            shipping_info_update: prev_setting,
            show_edit_confirmation_modal: false,
          });
          handleHttpRequestError(error, that);
        });
    }
  };

  /**
   * Cancels edit mode
   * @function
   */
  onEditCancel = () => {
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      new_state.edit_mode = false;
      new_state.shipping_info_update = this.getShippingInfo(this.state.selected_setting_id);
      new_state.invalid_settings = [];
      return new_state;
    });
  };

  /**
   * Delete Provider Shipping Setting
   */
  deleteSetting = () => {
    const that = this;
    this.setState({
      delete_setting_in_progress: true,
    });
    Axios.delete(`/apiv3/externaltargetdays/${this.state.selected_setting_id}`)
      .then((res) => {
        this.setState({
          delete_setting_in_progress: false,
          show_delete_modal: false,
          selected_setting_id: 1,
        });
        this.resetView();
      })
      .catch((error) => {
        console.log('error: ', error);
        this.setState({
          delete_setting_in_progress: false,
          show_delete_modal: false,
        });
        handleHttpRequestError(error, that);
      });
  };

  /**
   * Displays tier search bar
   * @function
   * @return {JSX} - JSX for search bar
   */
  displaySearchBar = () => {
    return (
      <>
        <div className="dark-search">
          <input
            type="text"
            className="form-control search-bar-dark font-awesome"
            placeholder="Search"
            aria-describedby="basic-addon1"
            onChange={this.onChangeSearchValue}
            onKeyPress={this.onKeyPressSearch}
            value={this.state.search}
            disabled={this.state.edit_mode}
          />
        </div>
        <button className="btn btn-light-3" onClick={this.searchShippingSettings} disabled={this.state.edit_mode}>
          <i className="fa fa-search" aria-hidden="true" />
        </button>
      </>
    );
  };

  /**
   * Updates search state value
   */
  onChangeSearchValue = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  /**
   * Handles event when user presses enter in search bar
   * @function
   */
  onKeyPressSearch = (event) => {
    if (event.key === 'Enter') {
      this.searchShippingSettings();
    }
  };

  /**
   * Searches all shipping settings and sets new view with results
   */
  searchShippingSettings = () => {
    const search_results = performSearch(this.state.search, this.state.external_target_days, 'account_type');
    const setting_id = search_results.length ? search_results[0].id : null;
    const shipping_info = setting_id ? this.getShippingInfo(setting_id) : {};
    this.setState({
      external_target_days_view: search_results,
      selected_setting_id: setting_id,
      shipping_info_update: shipping_info,
    });
  };

  /**
   * Show Settings Edit Confirmation Modal
   */
  showEditConfirmationModal = () => {
    const setting_id = this.state.shipping_info_update.id;
    const prev_setting = this.getShippingInfo(setting_id);
    if (_.isMatch(prev_setting, this.state.shipping_info_update)) {
      this.setState({
        edit_mode: false,
      });
    } else {
      if (this.determineSettingsInputError()) return;
      this.setState({
        show_edit_confirmation_modal: true,
        edit_mode: false,
      });
    }
  };

  /**
   * Hides settings edit confirmation modal
   */
  hideEditConfirmationModal = () => {
    const setting_id = this.state.shipping_info_update.id;
    const prev_setting = this.getShippingInfo(setting_id);
    this.setState({
      show_edit_confirmation_modal: false,
      edit_mode: false,
      shipping_info_update: prev_setting,
    });
  };

  /**
   * Creates body for edit confirmation modal
   * @returns {JSX} Modal Body
   */
  editConfirmationModalBody = () => {
    const setting_id = this.state.shipping_info_update.id;
    const { initial_case, digital_enhancement, item_request } = this.getShippingInfo(setting_id);
    const { initial_case: new_initial, digital_enhancement: new_de, item_request: new_ir } = this.state.shipping_info_update;
    return (
      <>
        <div>
          Please click <span className="bold-text">Confirm</span> to save these changes.
        </div>
        <div className="edit-details">
          <div className="edit-details__type">
            <span className="bold-text heading">Initial Case</span>
            Estimated Ship Date (from Smile Design approval)
            <br />
            <div className="edit-details__change">
              {initial_case === new_initial ? (
                'No Change'
              ) : (
                <>
                  <span className="bold-text">{initial_case}</span> Business Days
                  <FontAwesomeIcon icon={['fa', 'arrow-right-long']} />
                  <span className="bold-text">{new_initial}</span> Business Days
                </>
              )}
            </div>
          </div>
          <div className="edit-details__type">
            <span className="bold-text heading">Digital Enhancement</span>
            Estimated Ship Date (from Smile Design approval)
            <br />
            <div className="edit-details__change">
              {digital_enhancement === new_de ? (
                'No Change'
              ) : (
                <>
                  <span className="bold-text">{digital_enhancement}</span> Business Days
                  <FontAwesomeIcon icon={['fa', 'arrow-right-long']} />
                  <span className="bold-text">{new_de}</span> Business Days
                </>
              )}
            </div>
          </div>
          <div className="edit-details__type">
            <span className="bold-text heading">Item Request</span>
            Estimated Ship Date (from Item Request approval)
            <br />
            <div className="edit-details__change">
              {item_request === new_ir ? (
                'No Change'
              ) : (
                <>
                  <span className="bold-text">{item_request}</span> Business Days
                  <FontAwesomeIcon icon={['fa', 'arrow-right-long']} />
                  <span className="bold-text">{new_ir}</span> Business Days
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * Show Create Setting Modal
   */
  showCreateModal = () => {
    this.setState({
      show_create_modal: true,
    });
  };

  /**
   * Show Delete Setting Modal
   */
  showDeleteModal = () => {
    this.setState({
      show_delete_modal: true,
    });
  };

  /**
   * Hide Create Ship Setting Modal
   */
  hideCreateModal = () => {
    this.setState({
      show_create_modal: false,
      new_shipping_info: {
        initial_case: null,
        digital_enhancement: null,
        item_request: null,
        provider_info: { id: null, account_id: null, name: '' },
      },
      create_errors: {
        initial_case: false,
        digital_enhancement: false,
        item_request: false,
        provider_info: false,
      },
    });
  };

  /**
   * Hide Delete Setting Modal
   */
  hideDeleteModal = () => {
    this.setState({
      show_delete_modal: false,
    });
  };

  /**
   * Checks if create modal has input errors
   * @returns {Boolean} Create Ship Setting Error status
   */
  hasCreateErrors = () => {
    const { provider_info, initial_case, digital_enhancement, item_request } = this.state.new_shipping_info;
    if (!provider_info.id || !initial_case || !digital_enhancement || !item_request) {
      return true;
    }
    return false;
  };

  /**
   * Sets error state for create setting modal
   */
  setCreateErrors = () => {
    const { provider_info, initial_case, digital_enhancement, item_request } = this.state.new_shipping_info;
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      new_state.create_errors.provider = !provider_info.id;
      new_state.create_errors.initial_case = !initial_case;
      new_state.create_errors.digital_enhancement = !digital_enhancement;
      new_state.create_errors.item_request = !item_request;
      new_state.create_errors.has_error = !provider_info.id || !initial_case || !digital_enhancement || !item_request;
      return new_state;
    });
  };

  /**
   * Creates new External Target Date Settings
   */
  createNewShipSetting = () => {
    const that = this;
    if (this.hasCreateErrors()) {
      this.setCreateErrors();
      return false;
    }
    this.setState({
      create_setting_in_progress: true,
    });
    Axios.post('/apiv3/externaltargetdays', { shipping_info: this.state.new_shipping_info })
      .then((res) => {
        this.resetView(res.data.id);
      })
      .catch((error) => {
        this.setState({
          show_create_modal: false,
          create_setting_in_progress: false,
        });
        handleHttpRequestError(error, that);
      });
  };

  /**
   * Changes Selected Provider on dropdown select in Create Setting Modal
   */
  onChangeProvider = (e) => {
    if (e.target.value) {
      const { id, name } = JSON.parse(e.target.value);
      this.setState((prev_state) => {
        const new_state = { ...prev_state };
        new_state.new_shipping_info.provider_info.id = id;
        new_state.new_shipping_info.provider_info.account_id = id;
        new_state.new_shipping_info.provider_info.name = name;
        new_state.create_errors.provider = false;
        if (!this.hasCreateErrors()) new_state.create_errors.has_error = false;
        return new_state;
      });
    } else {
      this.setState((prev_state) => {
        const new_state = { ...prev_state };
        new_state.new_shipping_info.provider_info.id = null;
        new_state.new_shipping_info.provider_info.account_id = null;
        new_state.new_shipping_info.provider_info.name = null;
        return new_state;
      });
    }
  };
  /**
   * Show list of available providers
   * @returns {Object} list of available providers
   */
  getFilterListOfProvider = () => {
    const providers_id_with_external_config = this.state.external_target_days_view.map((view) => {
      return view.doctor_id;
    });

    const filter_providers = this.state?.providers.filter((provider) => {
      const provider_meta = JSON.parse(provider.value);
      return _.intersection([provider_meta.id], providers_id_with_external_config).length === 0;
    });

    return filter_providers;
  };
  /**
   * Creates body for create setting modal
   * @returns {JSX} Modal Body
   */
  createSettingModalBody = () => {
    return (
      !this.state.create_setting_in_progress && (
        <div className="provider-select-modal">
          <div>Please select a provider and specify the estimated ship date lead times for each case type</div>
          <Datalist
            error={this.state.create_errors.provider}
            label="Provider:"
            list={this.getFilterListOfProvider()}
            value={JSON.stringify(this.state.new_shipping_info.provider_info)}
            change={(e) => this.onChangeProvider(e)}
            placeholder="Select Provider"
          />
          <div className="target-date__input">
            <label>Initial Case: </label>
            <input
              className={`form-control days-input ${this.state.create_errors.initial_case && 'days-input__error'}`}
              value={this.state?.new_shipping_info?.initial_case}
              onChange={(e) => this.updateNewShippingInfo('initial_case', e.target.value)}
            />
            <span>Business Days</span>
          </div>
          <div className="target-date__input">
            <label>Digital Enhancement: </label>
            <input
              className={`form-control days-input ${this.state.create_errors.digital_enhancement && 'days-input__error'}`}
              value={this.state?.new_shipping_info?.digital_enhancement}
              onChange={(e) => this.updateNewShippingInfo('digital_enhancement', e.target.value)}
            />
            <span>Business Days</span>
          </div>
          <div className="target-date__input">
            <label>Item Request: </label>
            <input
              className={`form-control days-input ${this.state.create_errors.item_request && 'days-input__error'}`}
              value={this.state?.new_shipping_info?.item_request}
              onChange={(e) => this.updateNewShippingInfo('item_request', e.target.value)}
            />
            <span>Business Days</span>
          </div>
          <div className="create-error-message">{this.state.create_errors.has_error && 'Please complete the required fields'}</div>
        </div>
      )
    );
  };
  /**
   * Sets state for new external target dates info
   * @param {String} type
   * @param {Int} value
   */
  updateNewShippingInfo = (type, value) => {
    let business_days = targetDaysNumberChar(value);
    business_days = business_days ? parseInt(business_days) : business_days;
    this.setState((prev_state) => {
      const new_state = { ...prev_state };
      new_state.new_shipping_info[type] = business_days;
      new_state.create_errors[type] = false;
      if (!this.hasCreateErrors()) new_state.create_errors.has_error = false;

      return new_state;
    });
  };

  render() {
    return this.state.loading ? (
      <CircleLoader fullscreen={true} />
    ) : (
      <div className="main-content main-content-solo target-date external-settings">
        <div className="dark-options">
          <div className="page-heading">External Target Date Settings</div>
          {this.displaySearchBar()}
          <button className="btn btn-light create-new" onClick={this.showCreateModal}>
            Create New
          </button>
        </div>
        <div className="page-tab-content">
          <div className="page-tab-content-section">
            {this.state.external_target_days_view.length === 0 ? (
              !this.state.loading && displayNoResults(this.state.search)
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <AccountTypes {...this.state} externalTargetDays={this.state.external_target_days} onProviderClick={this.onProviderClick} />
                </div>
                <div className="col-lg-6">
                  <ShippingInfo
                    {...this.state}
                    shippingInfoChange={this.shippingInfoChange}
                    toggleEditMode={this.toggleEditMode}
                    getAccountType={this.getAccountType}
                    onEditCancel={this.onEditCancel}
                    onEditSave={this.showEditConfirmationModal}
                    showDeleteModal={this.showDeleteModal}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.refresh && <SessionExpire />}
        {this.state.show_create_modal && (
          <Modal
            preset="external_target_date"
            has_error={this.state.create_errors.has_error}
            header_text="Create New Setting"
            onConfirmButtonClick={this.createNewShipSetting}
            onCloseButtonClick={this.hideCreateModal}
            in_progress={this.state.create_setting_in_progress}
            modal_body={this.createSettingModalBody()}
            confirm_btn_text="Save"
            close_btn_text="Cancel"
            theme="bpp"
          />
        )}
        {this.state.show_edit_confirmation_modal && (
          <Modal
            preset="external_target_date"
            has_error={this.state.create_errors.has_error}
            header_text="Save Settings"
            onConfirmButtonClick={this.onEditSave}
            onCloseButtonClick={this.hideEditConfirmationModal}
            in_progress={this.state.edit_setting_in_progress}
            modal_body={this.editConfirmationModalBody()}
            confirm_btn_text="Save"
            close_btn_text="Cancel"
            theme="bpp"
          />
        )}
        {this.state.show_delete_modal && (
          <Modal
            theme="bpp"
            preset="decision"
            header_text={this.getHeaderTextForDeleteModal(this.state.selected_setting_id)}
            in_progress={this.state.delete_setting_in_progress}
            confirm_btn_text="Delete"
            close_btn_text="Cancel"
            message_text="Are you sure you would like to delete the settings for this provider?"
            onConfirmButtonClick={this.deleteSetting}
            onCloseButtonClick={this.hideDeleteModal}
          />
        )}
      </div>
    );
  }
}

export default ExternalTargetDateSettings;
