import React, { Component } from 'react';
import CircleLoader from './circle_loader';

class Loader extends Component {
  render() {
    return (
      <div className={this.props.windowSize ? `loader-window-${this.props.windowSize}` : 'loader-container offset-heading'}>
        <div className="loader-header">
          <CircleLoader />
          {this.props.showAltText ? <p>{this.props.altText ? this.props.altText : 'Loading...'}</p> : null}
        </div>
      </div>
    );
  }
}

Loader.defaultProps = {
  showAltText: true,
};

export default Loader;
