import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TeethUtils, { ALPHANUMERIC_UNIVERSAL_MAPPING, FDI_UNIVERSAL_MAPPING } from '../../../common/teeth_utils';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import WasmIPRItem from './wasm_ipr_item';
import WasmIprLegend from './wasm_ipr_legend';
/**
 * Formats the IPR (Interproximal Reduction) data based on specific conditions.
 *
 * @param {Array} ipr - An array containing objects with IPR data.
 * @returns {Object} - Formatted IPR table with keys based on FDI_UNIVERSAL_MAPPING and values based on conditions.
 */
export function formatIPR(ipr) {
  const iprTable = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,

    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,

    32: 0,
    31: 0,
    30: 0,
    29: 0,
    28: 0,
    27: 0,
    26: 0,
    25: 0,

    24: 0,
    23: 0,
    22: 0,
    21: 0,
    20: 0,
    19: 0,
    18: 0,
    17: 0,
  };

  ipr.forEach((v) => {
    const currTeethId = FDI_UNIVERSAL_MAPPING[v.FDI];
    const distance = v.IP ? parseFloat(v.IP) : v.distance;
    // Check if the distance meets the minimum IPR or spacing criteria
    const hasMinimumIPR = distance <= -0.1;
    const hasMinimumSpacing = distance >= 0.1;

    // Update the iprTable if the conditions are met
    if (hasMinimumIPR || hasMinimumSpacing) {
      iprTable[currTeethId] = distance;
    }
  });
  return iprTable;
}

function toothHasIPR(teeth, missing_teeth) {
  // tooth can have ipr if there is any larger teeth not in missing teeth
  const last_teeth = teeth <= 16 ? 16 : 32;
  const missing = missing_teeth.filter((tn) => tn > teeth && tn <= last_teeth).length;
  const is_last = last_teeth - teeth <= missing;
  return !(is_last || teeth === last_teeth || missing_teeth.includes(teeth));
}

function getInitialTeethIPR(ipr_info = {}, missing_teeth) {
  const teeth_set = TeethUtils.teethSetInPalmerBySection();
  const all_teeth = [...teeth_set.upper, ...teeth_set.lower];

  const result = {};
  const missing_teeth_universal = missing_teeth.map((teeth) => ALPHANUMERIC_UNIVERSAL_MAPPING[teeth]);
  all_teeth.forEach((teeth, i) => {
    const is_upper = i <= 15;
    const teeth_id = is_upper ? i + 1 : 48 - i;
    result[teeth] = {
      teeth_id: teeth_id,
      ipr: ipr_info[teeth_id] ?? 0,
      missing: missing_teeth.includes(teeth),
      has_ipr: toothHasIPR(teeth_id, missing_teeth_universal),
    };
  });

  return result;
}

function WasmIPR(props) {
  const { missing_teeth, ipr } = props;
  const initialIPR = getInitialTeethIPR(ipr, missing_teeth);
  const [teethIPR, setTeethIPR] = useState(initialIPR);

  useEffect(() => {
    const new_ipr = getInitialTeethIPR(ipr, missing_teeth);
    setTeethIPR(new_ipr);
  }, [ipr, missing_teeth]);

  return (
    <div className="wasm-ipr-container">
      <WasmIprLegend />
      <div className="wasm-ipr-grid">
        {Object.keys(teethIPR).map((teeth_id) => (
          <WasmIPRItem key={teeth_id} teeth={teeth_id} info={teethIPR[teeth_id]} />
        ))}
      </div>
    </div>
  );
}

WasmIPR.propTypes = {
  ipr: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const wasmViewerState = getWasmViewerState(state);
  return {
    missing_teeth: wasmViewerState.missing_teeth,
  };
};

export default connect(mapStateToProps)(WasmIPR);
