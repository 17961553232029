import React from 'react';
import PropTypes from 'prop-types';

const themes = ['default', 'primary'];

/**
 * Displays a basic button
 * @component
 */
function SelectionArea({ theme, className, ...props }) {
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} selection-area selection-area--${themeClassName}`;

  return (
    <div className={classNames} {...props}>
      <div className="selection-area--content">
        {props.children}
      </div>
      <div className="selection-area--icon">
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  );
}

SelectionArea.propTypes = {
  theme: PropTypes.oneOf(themes),
};

SelectionArea.defaultProps = {
  theme: themes[0],
};

export default SelectionArea;
