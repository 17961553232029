import React, { Component } from 'react';
import Axios from 'axios';

import { withRouter } from 'react-router-dom';

import Modal from '../../components/modal/modal';
import { modalClose, convertDate } from '../../common/functions';

import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

import './api_details.scss';

class ApiDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      api_key_name: '',
      api_key_error: '',
      api_key_success: '',
      api_key_success_name: '',
      api_key: '',
      api_key_success_modal: false,
      api_key_list: [],
    };
  }

  componentDidMount() {
    let that = this;
    Axios.get('/apiV2/apikey')
      .then(function (result) {
        that.setState({
          api_key_list: result.data.api_keys,
        });
      })
      .catch(function (errors) {
        console.log(errors);
      });
  }

  OnCreate = () => {
    let that = this;

    Axios.post(`/apiV2/apikey/${this.state.api_key_name}`)
      .then(function (result) {
        that.setState({
          api_key_name: '',
          api_key_success_name: result.data.api_key,
          api_key_error: '',
          api_key_success: result.data.api_key,
          api_key: result.data.key,
          api_key_success_modal: true,
          api_key_list: result.data.api_keys,
        });
      })
      .catch(function (errors) {
        console.log(errors);
      });
  };

  onBlur = (e) => {
    const val = e.target.value;
    const api_list = this.state.api_key_list.filter(function (api) {
      return api.name === val;
    })[0];
    if (api_list && api_list.name) {
      this.setState({
        api_key_error: true,
      });
    } else {
      this.setState({
        api_key_error: false,
      });
    }
  };

  onChange = (e) => {
    const val = e.target.value
      .toLowerCase()
      .trim()
      .replace(/[^a-z_]/g, '');

    this.setState({
      api_key_name: val,
    });
  };
  onConfirmClicked = () => {
    this.setState({
      api_key_success_modal: false,
    });
    modalClose();
  };
  displayApiKeySuccessModal = () => {
    return (
      <Modal
        preset="action"
        x_btn={true}
        header_text="API Key Successfully Created"
        message_text={
          '<div><strong>Key Name: </strong>' +
          this.state.api_key_success_name +
          '</div><div><strong>Key: </strong>' +
          this.state.api_key +
          '</div><div>Please copy and save this key. You will not be able to retrieve it later.</div>'
        }
        confirm_btn_text="Ok"
        onConfirmButtonClick={this.onConfirmClicked}
        onCloseButtonClick={this.onConfirmClicked}
        theme="bpp"
      />
    );
  };

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div className="page-tab-content-section page-tab-top-section">
              <div className="row page-top-content page-top-content-extra">
                <div className="col-lg-6 business-dark-lg-6-l">
                  <div className="bold-text business-dark-heading">Generator API Key</div>
                  <div className="business-dark-theme">
                    <div className="">
                      <div className="api-heading">Key Name:</div>
                      <div className="api-section">
                        <input
                          value={this.state.api_key_name}
                          id="api_key_name"
                          className={'form-control ' + (this.state.api_key_error || this.state.api_key_error ? 'profile-edit-input-error' : '')}
                          maxLength="30"
                          placeholder="Enter Key Name"
                          onChange={this.onChange}
                          onBlur={this.onBlur}
                          autoFocus
                        />
                      </div>
                    </div>
                    <div className="api-button">
                      <button
                        onClick={this.OnCreate}
                        disabled={
                          this.state.api_key_name === '' || !userHasPermission('ACCOUNT_API_KEY', user_roles_and_permissions.permissions) ? true : false
                        }
                        className="btn btn-light api-create pull-right"
                        type="submit"
                      >
                        Create
                      </button>
                    </div>

                    <div className="profile-edit-container">
                      {this.state.api_key_error ? <span className="error-message">Provided Key Already Exists</span> : null}
                    </div>
                  </div>
                </div>

                {this.state.api_key_list && this.state.api_key_list.length > 0 ? (
                  <div className="col-lg-6 business-dark-lg-6-l">
                    <div className="bold-text business-dark-heading">API Key List</div>
                    <div className="business-dark-theme">
                      <table className="table table-marginless">
                        <thead>
                          <tr>
                            <th scope="col">Key Name</th>
                            <th scope="col">Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.api_key_list.length > 0
                            ? this.state.api_key_list.map((api_key, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{api_key.name}</td>
                                    <td>{convertDate(api_key.created)}</td>
                                  </tr>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}

                {this.state.api_key_success_modal ? this.displayApiKeySuccessModal() : null}
              </div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default withRouter(ApiDetails);
