import React, { Component } from 'react';
import Axios from 'axios';
import AxiosRetry from 'axios-retry';
import { connect } from 'react-redux';
import { getCaseDetails } from '../../../../../redux/reducers/common/common_case_details';
import Viewer3D from './viewer_3d';
import { checkModelHasAllMeshes, getActiveSetupModel } from '../../common/functions';
import * as THREE from 'three';
import { matTeeth, matGums } from '../../../viewer/common/mesh';
import PLYLoaderModule from 'threejs-ply-loader';
import RetryButton from '../../extras/retry_button';
import InLoader from '../../../../loader/in_loader';

const PLYLoader = PLYLoaderModule(THREE);

/**
 * Manages the loading and error handling of Viewer3D
 * @component
 */
class ViewerManager extends Component {
  componentDidMount() {
    AxiosRetry(Axios, {
      retries: 3,
      retryCondition: (err) => {
        return err && err.response && err.response.status !== 500 && err.response.status !== 400;
      },
    });
    this.loadModelsIfNeeded();
  }

  componentDidUpdate(prevProps) {
    const setupChanged = prevProps.scroll_options !== this.props.scroll_options;
    if (setupChanged) {
      this.loadModelsIfNeeded();
    }
  }

  componentWillUnmount() {
    AxiosRetry(Axios, { retries: 1 });
  }

  /**
   * Loads model if needed
   *  - If the model is not loaded already, and
   *  - If no error has occured, and
   *  - If not currently loading, and
   *  - If not loaded before
   * @function
   */
  loadModelsIfNeeded() {
    const { steps, setup_num, error, loading, loaded } = getActiveSetupModel(this.props);
    const shouldLoadModel = !steps && !error && !loading && !loaded;
    if (shouldLoadModel) {
      this.loadModels(setup_num);
    }
  }

  /**
   * Creates an empty Object to hold the model
   * @function
   * @return {Object} The object
   */
  initModel() {
    function initArch() {
      return {
        teeth: null,
        gums: null,
      };
    }
    function initStep(step) {
      return {
        step: step,
        man: initArch(),
        max: initArch(),
      };
    }

    let models = {
      ipr: null,
      steps: {
        first: initStep('first'),
        last: initStep('last'),
      },
    };
    return models;
  }

  /**
   * Obtains the setup folder name based on IFS status
   * @function
   * @param {String} setupNum = The setup's number
   * @returns {String} setup folder string
   */
  getSetupFolder(setupNum) {
    const { case_file_list } = this.props;
    const setupProcess = case_file_list[case_file_list.case_id].setup_process;
    const currentSetup = setupProcess.find((setup) => setup.setup_num === setupNum);
    const isIfsFolder = currentSetup && currentSetup.files?.upload_data?.includes('InBrace_IFS');

    if (isIfsFolder) {
      return 'InBrace_IFS/Viewer_InBrace_IFS';
    } else {
      return `SETUP${setupNum}/Viewer`;
    }
  }

  /**
   * Loads the models of the corresponding setup
   * @function
   * @param {String} setup_num - The setup's number
   */
  loadModels(setup_num) {
    const case_id = this.props.isSmileSimulationSetup ? this.props.smile_details['smile_id'] : this.props.case_details['case_id'];
    const loader = new PLYLoader();
    const loadingManager = new THREE.LoadingManager();
    const model = this.initModel();
    const setupFolder = this.props.isSmileSimulationSetup ? `SETUP${setup_num}/Viewer` : this.getSetupFolder(setup_num);

    loadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
      this.props.updateModels(setup_num, {
        loaded: true,
        loading: true,
        error: null,
        loading_percent: itemsLoaded / itemsTotal,
      });
    };
    loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      this.props.updateModels(setup_num, { loading_percent: itemsLoaded / itemsTotal });
    };
    loadingManager.onLoad = () => {
      this.props.updateModels(setup_num, { loading: false, ...model });
    };
    loadingManager.onError = (url) => {
      console.log('err: ' + url);
    };

    const loadMesh = (step, arch, isTeeth) => {
      const gname = step === 'first' ? 'start' : 'end';
      const arch_name = arch + (isTeeth ? '_teeth' : '');
      const file = this.props.isSmileSimulationSetup
        ? `/api/link/?file=/SMILES/${case_id}/${case_id}_SETUPS/SETUP1/Viewer/${gname}/${arch_name}.ply&s3=true`
        : `/api/link/?file=/CASE_FILES/${case_id}/${case_id}_SETUPS/${setupFolder}/${gname}/${arch_name}.ply`;

      loadingManager.itemStart(file);

      Axios.get(file)
        .then((res) => {
          loader.load(res.data, (geometry) => {
            const mat = isTeeth ? matTeeth : matGums;
            geometry.computeVertexNormals();
            const mesh = new THREE.Mesh(geometry, mat);
            model.steps[step][arch][isTeeth ? 'teeth' : 'gums'] = mesh;
            loadingManager.itemEnd(file);
          });
        })
        .catch((err) => {
          loadingManager.itemError(file);
          this.props.updateModels(setup_num, { loading: false, error: err });
        });
    };

    const loadIPR = () => {
      const iprFile = this.props.isSmileSimulationSetup
        ? `/api/download/?file=/SMILES/${case_id}/${case_id}_SETUPS/SETUP1/Viewer/ipr_info.json&s3=true`
        : `/api/download/?file=/CASE_FILES/${case_id}/${case_id}_SETUPS/${setupFolder}/ipr_info.json`;

      loadingManager.itemStart(iprFile);
      Axios.get(iprFile)
        .then((res) => {
          model['ipr'] = res.data;
          loadingManager.itemEnd(iprFile);
        })
        .catch((err) => {
          loadingManager.itemError(iprFile);
          this.props.updateModels(setup_num, { loading: false, error: err });
        });
    };

    ['first', 'last'].forEach((step) => {
      ['man', 'max'].forEach((arch) => {
        [true, false].forEach((isTeeth) => {
          loadMesh(step, arch, isTeeth);
        });
      });
    });
    loadIPR();
  }

  render() {
    const { steps, ipr, error, loading, loaded, loading_percent, setup_num } = getActiveSetupModel(this.props);
    const canDisplayViewer3D = steps && ipr && !error && !loading && loaded;
    const hasError = !loading && loaded && (error || !steps || !ipr || !checkModelHasAllMeshes(steps));
    if (hasError) {
      return <RetryButton className="fill center" message="Unable to load Smile Design." onRetry={() => this.loadModels(setup_num)} />;
    }
    if (loading) {
      return (
        <div className="fill center">
          <InLoader percentage={loading_percent * 100} />
        </div>
      );
    }
    return (
      canDisplayViewer3D && (
        <Viewer3D
          steps={steps}
          ipr={ipr}
          setup_num={setup_num}
          split={this.props.split}
          side_collapse={this.props.side_collapse}
          scroll_options={this.props.scroll_options}
          pane={this.props.pane}
          isSmileSimulationSetup={this.props.isSmileSimulationSetup}
          onSmileChangeStep={this.props.onChangeStep}
        />
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
  };
};

export default connect(mapStateToProps)(ViewerManager);
