import React, { useState, useEffect, useMemo, useRef } from 'react';

import './banner_carousel.scss';
import { getHolidayMessageElementHeight } from './holiday_message';
import { getCreditHoldWarningElementHeight } from './credit_hold_warning';
import { getBrowserWarningElementHeight } from '../components/browser_warning/browser_warning';
import { getFeatureAnnouncementElementHeight } from './feature_announcement';

/**
 * Minimum distance required for a swipe to be considered valid.
 * @type {number}
 */
const MIN_SWIPE_DISTANCE = 50;

const Previous = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g opacity="0.25">
      <path
        d="M19.044 4.668L8.68002 15.3C8.49799 15.4868 8.39612 15.7372 8.39612 15.998C8.39612 16.2588 8.49799 16.5093 8.68002 16.696L19.044 27.332C19.1288 27.4191 19.2302 27.4883 19.3422 27.5356C19.4542 27.5828 19.5745 27.6072 19.696 27.6072C19.8176 27.6072 19.9379 27.5828 20.0499 27.5356C20.1618 27.4883 20.2632 27.4191 20.348 27.332C20.5225 27.1534 20.6201 26.9137 20.6201 26.664C20.6201 26.4143 20.5225 26.1746 20.348 25.996L10.602 15.998L20.348 6.002C20.5219 5.8235 20.6191 5.58417 20.6191 5.335C20.6191 5.08583 20.5219 4.84651 20.348 4.668C20.2632 4.58092 20.1618 4.5117 20.0499 4.46444C19.9379 4.41717 19.8176 4.39282 19.696 4.39282C19.5745 4.39282 19.4542 4.41717 19.3422 4.46444C19.2302 4.5117 19.1288 4.58092 19.044 4.668Z"
        fill="#211B57"
      />
    </g>
  </svg>
);

const Next = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g opacity="0.25">
      <path
        d="M12.9561 27.332L23.3201 16.7C23.5021 16.5132 23.604 16.2628 23.604 16.002C23.604 15.7412 23.5021 15.4907 23.3201 15.304L12.9561 4.668C12.8713 4.58091 12.7699 4.51169 12.6579 4.46443C12.546 4.41717 12.4257 4.39282 12.3041 4.39282C12.1826 4.39282 12.0622 4.41717 11.9503 4.46443C11.8383 4.51169 11.7369 4.58091 11.6521 4.668C11.4777 4.84659 11.38 5.08634 11.38 5.336C11.38 5.58565 11.4777 5.8254 11.6521 6.004L21.3981 16.002L11.6521 25.998C11.4783 26.1765 11.381 26.4158 11.381 26.665C11.381 26.9142 11.4783 27.1535 11.6521 27.332C11.7369 27.4191 11.8383 27.4883 11.9503 27.5356C12.0622 27.5828 12.1826 27.6072 12.3041 27.6072C12.4257 27.6072 12.546 27.5828 12.6579 27.5356C12.7699 27.4883 12.8713 27.4191 12.9561 27.332Z"
        fill="#211B57"
      />
    </g>
  </svg>
);

const IndicatorActive = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="51" height="5" viewBox="0 0 51 5" fill="none">
    <rect x="0.5" width="50" height="5" rx="2.5" fill="#211B57" />
  </svg>
);

const Indicator = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="51" height="5" viewBox="0 0 51 5" fill="none">
    <g opacity="0.25">
      <rect x="0.5" width="50" height="5" rx="2.5" fill="#211B57" />
    </g>
  </svg>
);

const BannerCarousel = ({ isBannerLoaded, children }) => {
  const [currentBanner, setCurrentBanner] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState(null);
  const [height, setHeight] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const intervalRef = useRef(null);

  const startAutoPlay = () => {
    intervalRef.current = setInterval(() => {
      nextBanner();
    }, 5000);
  };

  const stopAutoPlay = () => {
    clearInterval(intervalRef.current);
  };

  const memoizedBanners = useMemo(() => {
    return React.Children.toArray(children);
  }, [children]);

  const memoizedTotalBanners = useMemo(() => {
    return memoizedBanners.length;
  }, [memoizedBanners]);

  const nextBanner = () => {
    if (memoizedTotalBanners < 2) return;
    setCurrentBanner((currentBanner + 1) % memoizedTotalBanners);
  };

  const prevBanner = () => {
    if (memoizedTotalBanners < 2) return;
    setCurrentBanner((currentBanner - 1 + memoizedTotalBanners) % memoizedTotalBanners);
  };

  const onIndicatorClick = (index) => {
    setCurrentBanner(index);
  };
  /**
   * Updates the background color of the banner carousel.
   */
  const updateBackgroundColor = () => {
    const bannerActive = document.querySelector('.banner.active > div');
    if (bannerActive) {
      let bgColor = window.getComputedStyle(bannerActive, null).getPropertyValue('background-color');
      if (bannerActive.classList[0] === 'browser-warning-doctor') {
        bgColor = 'rgba(0, 0, 0, 0.7)';
      }
      setBackgroundColor(bgColor);
    }
  };

  /**
   * Updates the height of the banner carousel.
   */
  const updateHeight = () => {
    // Get the heights of the holiday message, credit hold warning, and browser warning elements.
    const holidayMessageHeight = getHolidayMessageElementHeight();
    const creditHoldWarningHeight = getCreditHoldWarningElementHeight();
    const browserWarningHeight = getBrowserWarningElementHeight();
    const featureAnnouncementHeight = getFeatureAnnouncementElementHeight();

    // Find the largest height of the three elements.
    const maxHeight = Math.max(holidayMessageHeight, creditHoldWarningHeight, browserWarningHeight, featureAnnouncementHeight);

    if (maxHeight > 0) {
      // Set the height of the banner carousel to the largest height, plus 40 pixels.
      setHeight(maxHeight + 40);
    }
  };

  /**
   * Handles the touch start event.
   *
   * @param {TouchEvent} e - The touch start event.
   */
  const onTouchStart = (e) => {
    // Reset touchEnd to null to prevent firing swipe with normal touch events
    setTouchEnd(null);
    // Store the initial touch position
    setTouchStart(e.targetTouches[0].clientX);
  };

  /**
   * Handles the touch move event.
   *
   * @param {TouchEvent} e - The touch move event.
   */
  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  /**
   * Handles the touch end event and determines if it's a left or right swipe.
   */
  const onTouchEnd = () => {
    // Return if touchStart or touchEnd is not available
    if (!touchStart || !touchEnd) return;

    // Calculate the distance of the swipe
    const distance = touchStart - touchEnd;

    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;

    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        nextBanner();
      } else {
        prevBanner();
      }

      // Reset touchStart and touchEnd after the swipe action
      setTouchStart(null);
      setTouchEnd(null);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useEffect(() => {
    if (isBannerLoaded) {
      startAutoPlay();
    }

    return () => {
      stopAutoPlay();
    };
  }, [isBannerLoaded, currentBanner]);

  useEffect(() => {
    updateHeight();
    updateBackgroundColor();
  }, [isBannerLoaded]);

  useEffect(() => {
    updateBackgroundColor();
  }, [currentBanner]);

  if (!isBannerLoaded) return null;

  return (
    <div className="banner-carousel" style={{ height: height === 0 ? 'auto' : height }}>
      {memoizedTotalBanners > 1 && (
        <button className="banner-control banner-previous" onClick={prevBanner}>
          <Previous />
        </button>
      )}
      <div className="banner-container" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <div className="banner-track" style={{ transform: `translateX(-${currentBanner * 100}%)`, backgroundColor }}>
          {memoizedBanners.map((banner, index) => (
            <div key={index} className={`banner ${currentBanner === index ? 'active' : ''}`}>
              {banner}
            </div>
          ))}
        </div>
        {memoizedTotalBanners > 1 && (
          <div className="carousel-navigation" style={{ top: height ? height - 10 : 0 }}>
            {memoizedBanners.map((_, index) => {
              return (
                <React.Fragment key={index}>
                  {currentBanner === index ? (
                    <IndicatorActive className="carousel-indicator" onClick={() => onIndicatorClick(index)} />
                  ) : (
                    <Indicator className="carousel-indicator" onClick={() => onIndicatorClick(index)} />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
      {memoizedTotalBanners > 1 && (
        <button className="banner-control banner-next" onClick={nextBanner}>
          <Next />
        </button>
      )}
    </div>
  );
};

export default BannerCarousel;
