/**
 * File: auto_redirect_inactivity.js - redirects inactive user to login screen
 * Copyright: (c) Copyright Sept 2020 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
import Axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Modal from '../../components/modal/modal';
import { handleHttpRequestError } from '../../common/error';
import { resetTokenHeader } from '../../common/functions';
import { fakeAuth } from '../../doctor/login/login';

/**
 * Check of api activity and logs out user for inactivity
 * @component
 * @alias AutoRedirectInactivity
 * @category Common
 */
class AutoRedirectInactivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setInterval: null,
      count: 0,
      showContinueModal: false,
      counter: 0,
      counterInterval: null,
    };
  }
  /**
   * Fail-safe mechanism to prevent infinite loop of api call
   * @function
   * @param {Number} count - Current counter value of how many iteration the api
   * has been called.
   */
  exceedthreeDaysOfCount = (count) => {
    const three_days_of_counts = 432;

    if (count >= three_days_of_counts) {
      clearInterval(this.state.setInterval);
    }
  };
  /**
   * Calls API to check last activity, if last activity is less than 11 mins a
   * dialog will appear
   * @function
   */
  checkActivity = () => {
    const that = this;
    const count = this.state.count + 1;
    const modal_open = that.state.showContinueModal;

    Axios.get(`/auth/auth-jwt-active/`)
      .then((res) => {
        const eleven_minutes_in_seconds = 660;

        if (!modal_open && res.data <= eleven_minutes_in_seconds) {
          const one_second = 1000; //ms
          const counterInterval = setInterval(that.countDown, one_second);

          that.setState({
            showContinueModal: true,
            counter: res.data,
            counterInterval: counterInterval,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that, true);
      });

    that.setState({
      count: count,
    });

    this.exceedthreeDaysOfCount(count);
  };
  /**
   * Updates the counter in the dialog and logs out and redirect user to the login screen
   * @function
   */
  countDown = () => {
    const that = this;
    const counter = this.state.counter;

    if (counter > 0) {
      this.setState({
        counter: counter - 1,
      });
    } else {
      Axios.post('/auth/rest-auth/logout/')
        .then(function () {
          resetTokenHeader();
          fakeAuth.signout(() => that.props.history.push('/'));
        })
        .catch(function (err) {
          handleHttpRequestError(err, that, true);
        });
    }
  };
  /**
   * Clear outstanding counter interval (prevent double/inifite calls)
   * @function
   */
  resetCounterState = () => {
    clearInterval(this.state.counterInterval);

    this.setState({
      counter: 0,
      counterInterval: null,
    });
  };
  /**
   * Resets the user counter in the backend by calling the alive API
   * @function
   */
  continueConfirmation = () => {
    let that = this;

    Axios.get(`/apiv3/alive`).then(() => {
      that.resetCounterState();
      that.setState({
        showContinueModal: false,
      });
    });
  };
  /**
   * Returns a human readable version of the counter value
   * @function
   * @param {Number} counter - The current counter value
   */
  displayValueForCounter = (counter) => {
    const minutes = Math.floor(counter / 60);
    const seconds = counter % 60;

    if (minutes > 0) {
      return `(${minutes + 1} Min)`;
    }

    return `(${seconds} Sec)`;
  };
  /**
   * Check if this is BPP by looking at the current URL
   * @function
   */
  isBPP = () => {
    return window.location.pathname.indexOf('business') >= 0;
  };

  componentDidMount() {
    const five_minutes = 1000 * 60 * 5;
    const set_interval = setInterval(this.checkActivity, five_minutes);

    this.setState({
      setInterval: set_interval,
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.setInterval);
    clearInterval(this.state.counterInterval);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showContinueModal ? (
          <Modal
            preset="message"
            header_text="Session Timeout"
            message_text={
              <span>
                <span className="bold-text">Are you still here?</span>
                <br />
                You've been inactive so we'll help sign you out.
                <br /> If you're still here, please click continue to stay connected.
              </span>
            }
            close_btn_text={`Continue ${this.displayValueForCounter(this.state.counter)}`}
            close_btn_color={`${this.isBPP() ? '' : 'primary'}`}
            onCloseButtonClick={this.continueConfirmation}
          ></Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(AutoRedirectInactivity);
