import React from 'react';
import PropTypes from 'prop-types';

const themes = ['default', 'primary', 'secondary', 'midnight', 'nav'];

/**
 * Displays a basic badge
 * @component
 */
function Badge({ className, theme, ...props }) {
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} badge badge--${themeClassName}`;
  return <span className={classNames} {...props} />;
}

Badge.propTypes = {
  theme: PropTypes.oneOf(themes),
};

Badge.defaultProps = {
  theme: themes[0],
};

export default Badge;
