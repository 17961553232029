// External
import React from 'react';

// Internal
import Modal from '../../../components/modal/modal';

/**
 * Displays session expire modal
 * @component
 * @category IPP
 */
const SessionExpire = () => {
  return (
    <Modal
      preset="action"
      x_btn={false}
      header_text="Session Expired"
      message_text="Sorry, your session has expired. Please refresh."
      confirm_btn_text="Refresh"
      onConfirmButtonClick={() => {
        window.location.reload();
      }}
    />
  );
};

export default SessionExpire;
