import React, { useState } from 'react';
import ApprovalConfirmation from '../../../modal/approval_confirmation';
import Modal from '../../../modal/modal';
import HistoryListButtonArea from './history_list_button_area';

/**
 * Displays the revise setup display area
 * @component
 * @alias SetupViewerHistoryListItemReviewButtons
 */
export function HistoryListItemReviewButtons(props) {
  const [approve, setApprove] = useState(false);
  const [revise, setRevise] = useState(false);

  if (revise) {
    return (
      <div className="history-list-item-revise-container fade-in">
        <div className="history-list-item-header">Revise Smile Design</div>
        <textarea
          placeholder="Please specify revision request"
          value={props.revise_reason}
          onChange={props.onChangeReviseReason}
          disabled={props.disabled}
          maxLength="2500"
        />
        <HistoryListButtonArea
          leftButtonText="Revise"
          rightButtonText="Cancel"
          leftButtonOnClick={props.onClickRevise}
          rightButtonOnClick={() => setRevise(false)}
          disabled={props.disabled}
          disableLeftButton={!props.revise_reason}
        />
      </div>
    );
  } else {
    return (
      <>
        <Modal show={approve} onCloseModal={() => setApprove(false)}>
          <ApprovalConfirmation confirm={props.onClickApprove} cancel={() => setApprove(false)} />
        </Modal>
        <HistoryListButtonArea
          leftButtonText="Approve"
          rightButtonText="Revise"
          leftButtonOnClick={() => setApprove(true)}
          rightButtonOnClick={() => setRevise(true)}
          disabled={props.disabled || revise}
          disableLeftButton={props.disableApproveButton}
          disableRightButton={props.disableReviseButton}
          hideLeftButton={props.hideApproveButton}
          hideRightButton={props.hideReviseButton}
          leftButtonTooltip={props.disableApproveTooltip}
        />
      </>
    );
  }
}
