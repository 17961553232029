// ---------------------------------- Imports ----------------------------------
// Css
import './profile_picture.scss';
// External Libs
import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal
import { getDefaultImageCropInfo } from '../../common/functions';

// Redux
import { setProfileMetadataEdit } from '../../redux/actions/ipp/profile_picture_draft';
import { getProfileMetadataEdit, getProfileImageEdit } from '../../redux/reducers/ipp/profile_picture_draft';

/**
 * This modal is used for the doctor to be able to reposition their profile picture.
 *
 * @component
 * @alias DraftRepositionProfilePicture
 * @category IPP
 */
class DraftRepositionProfilePicture extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.state = {
      crop: {},
    };
  }

  /**
   * Handles when the user drags the profile picture circle
   *
   * @function
   * @param {object} crop - Crop object with x y coordinates
   */
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  /**
   * Handles when the user finishes dragging the profile picture circle
   *
   * @function
   * @param {object} crop - Crop object with x y coordinates
   * @param {object} percentCrop - Crop object with x y percentages
   */
  onCropComplete = (crop, percentCrop) => {
    this.props.setProfileMetadataEdit(percentCrop.x, percentCrop.y, percentCrop.width, percentCrop.height);
  };

  /**
   * Handles when the image gets loaded into the modal; sets where the crop should be placed
   *
   * @function
   * @param {object} image - Image object
   * @return {boolean} - Returns false (necessary according to ReactCrop docs)
   */
  onImageLoaded = (image) => {
    let x = this.props.profile_metadata_edit.x;
    let y = this.props.profile_metadata_edit.y;
    let width = this.props.profile_metadata_edit.width;
    let height = this.props.profile_metadata_edit.height;

    if (width === '' || height === '') {
      const default_image_crop = getDefaultImageCropInfo(image);
      x = default_image_crop.x;
      y = default_image_crop.y;
      width = default_image_crop.width;
      height = default_image_crop.height;
    }

    this.props.setProfileMetadataEdit(x, y, width, height);
    this.setState({ crop: { width: width, height: height, x: x, y: y, aspect: 1 / 1, unit: '%' } });
    this.props.handleImageLoadComplete(true);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="reposition">
          <ReactCrop
            src={this.props.profile_image_edit}
            crop={this.state.crop}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            circularCrop={true}
            onImageLoaded={this.onImageLoaded}
            minWidth={10}
            minHeight={10}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile_metadata_edit: getProfileMetadataEdit(state),
    profile_image_edit: getProfileImageEdit(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setProfileMetadataEdit: setProfileMetadataEdit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DraftRepositionProfilePicture);
