import React from 'react';
import PropTypes from 'prop-types';
import { GlobalHotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onChangeJaw, onChangeStep, onChangeView, onToggleOption } from '../../../../redux/actions/setup_viewer/setup_viewer';

/**
 * Contains the shortcut keys configured for the setup viewer
 * @component
 * @alias SetupViewerShortcuts
 */
function Shortcuts(props) {
  const keyMap = {
    TOP_VIEW: 't',
    BOTTOM_VIEW: 'b',
    LEFT_VIEW: 'l',
    RIGHT_VIEW: 'r',
    FRONT_VIEW: 'f',
    TOGGLE_IPR: 'i',
    TOGGLE_GUM: 'u',
    TOGGLE_GRID: 'g',
    TOGGLE_SUPER: ['s', 'm'],
    TOGGLE_UPPER: 'p',
    TOGGLE_LOWER: 'o',
    MALO_VIEW: ',',
    SETUP_VIEW: '.',
  };

  const handlers = {
    TOP_VIEW: (e) => props.onChangeView('top'),
    BOTTOM_VIEW: (e) => props.onChangeView('bottom'),
    LEFT_VIEW: (e) => props.onChangeView('left'),
    RIGHT_VIEW: (e) => props.onChangeView('right'),
    FRONT_VIEW: (e) => props.onChangeView('front'),
    TOGGLE_IPR: (e) => props.onToggleOption('ipr'),
    TOGGLE_GUM: (e) => props.onToggleOption('gingiva'),
    TOGGLE_GRID: (e) => props.onToggleOption('grid'),
    TOGGLE_SUPER: (e) => props.onToggleOption('superimpose'),
    TOGGLE_UPPER: (e) => props.onChangeJaw('lower'),
    TOGGLE_LOWER: (e) => props.onChangeJaw('upper'),
    MALO_VIEW: (e) => props.onChangeStep('first'),
    SETUP_VIEW: (e) => props.onChangeStep('last'),
  };

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
  );
}

Shortcuts.propTypes = {
  onChangeView: PropTypes.func.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  onChangeJaw: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangeStep: onChangeStep,
      onChangeView: onChangeView,
      onChangeJaw: onChangeJaw,
      onToggleOption: onToggleOption,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Shortcuts);
