import React from 'react';
import PropTypes from 'prop-types';

const themes = ['default', 'secondary', 'info', 'error', 'success'];

/**
 * Wraps the children with a alert div
 * @component
 */
function AlertContainer({ theme, className, ...props }) {
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} alert alert--${themeClassName}`;
  return <div className={classNames} {...props} />;
}

AlertContainer.propTypes = {
  theme: PropTypes.oneOf(themes),
};

AlertContainer.defaultProps = {
  theme: themes[0],
};

export default AlertContainer;
