import React, { useEffect } from 'react';

import { orderTeeth, oneLineBreakLimited } from '../../common/functions';
import TeethUtils from '../../common/teeth_utils';

/**
 * Displays summary header
 * @function
 * @return {JSX} - JSX for header
 */
export const SummaryHeader = () => {
  return (
    <div className="submission-summary__header">
      <h3>Submission Summary</h3>
      <p className="emphasis">Please review the submission summary below</p>
    </div>
  );
};

/**
 * Displays summary container
 * @function
 * @return {JSX} - JSX for container
 */
export const SummaryContainer = ({ children }) => {
  return <div className="submission-summary__container">{children}</div>;
};

/**
 * Displays section within summary container
 * @function
 * @return {JSX} - JSX for container section
 */
export const SummaryContainerSection = ({ children }) => {
  return <div className="submission-summary__container__section">{children}</div>;
};

export const useCommonSubmissionSummary = (props) => {
  const {
    buildRecordStates,
    photoUpload,
    xrayUpload,
    scanUpload,
    anteriorPosteriorR,
    anteriorPosteriorL,
    anteriorPosteriorCorrections,
    anteriorPosteriorNotes,
    bracketTeeth,
    archToTreat,
    missingTeeth,
    extractTeeth,
    auxiliariesWillBeUsed,
    auxiliariesNotes,
    // iTero integration
    uploadMethod,
    selectedIteroScanRecords,
  } = props;

  const buildImageRecords = () => {
    const records = [];
    if (photoUpload) records.push(...photoUpload);
    if (xrayUpload) records.push(...xrayUpload);

    // iTero integration
    if (uploadMethod === 'itero' && selectedIteroScanRecords) {
      records.push(...selectedIteroScanRecords);
    } else if (scanUpload) {
      records.push(...scanUpload);
    }

    const options = { teethViewer: true, caseType: 'incomplete' };

    buildRecordStates(records, options);
  };

  const getTeethValue = (teeth) => {
    return teeth.length > 0 ? orderTeeth(teeth).join(', ') : 'N/A';
  };

  const getAnteriorPosteriorRightLeftValue = (anterior_posterior) => {
    return anterior_posterior === 'current' ? 'Maintain Current' : anterior_posterior === 'correction' ? 'Correction to Class I' : 'Other';
  };

  const getAnteriorPosteriorCorrection = () => {
    const { classIIOrIIICorrectionSimulation, posteriorIPR } = anteriorPosteriorCorrections;
    if (classIIOrIIICorrectionSimulation && posteriorIPR) return 'Class II/III correction simulation and Posterior IPR';
    if (classIIOrIIICorrectionSimulation) return 'Class II/III correction simulation only';
    if (posteriorIPR) return 'Posterior IPR only';
    return '';
  };

  const getAnteriorPosteriorValue = () => {
    const right = getAnteriorPosteriorRightLeftValue(anteriorPosteriorR);
    const left = getAnteriorPosteriorRightLeftValue(anteriorPosteriorL);
    const correction = anteriorPosteriorR === 'correction' || anteriorPosteriorL === 'correction' ? getAnteriorPosteriorCorrection() : '';
    const notes = anteriorPosteriorR === 'other' || anteriorPosteriorL === 'other' ? anteriorPosteriorNotes.trim() : '';
    return (
      <div>
        <p>
          Right: {right}. Left: {left}
          {correction ? `. ${correction}` : ''}
        </p>
        {notes && <p>"{notes}"</p>}
      </div>
    );
  };

  const getBracketPlacementValue = () => {
    if (bracketTeeth.length > 0) {
      const bracket_placement = TeethUtils.formatBracketPlacement(archToTreat, bracketTeeth, missingTeeth, extractTeeth);
      if (bracket_placement) return bracket_placement;
    }
    return 'N/A';
  };

  const getAuxiliariesValue = () => {
    return auxiliariesWillBeUsed === 'No' ? 'None' : `Yes${auxiliariesNotes ? `, "${oneLineBreakLimited(auxiliariesNotes).trim()}"` : ''}`;
  };

  useEffect(() => {
    buildImageRecords();
  }, [photoUpload, xrayUpload, scanUpload, selectedIteroScanRecords]);

  return {
    getTeethValue,
    getAnteriorPosteriorValue,
    getBracketPlacementValue,
    getAuxiliariesValue,
  };
};
