// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';

/**
 * Displays list of Providers
 * @alias AccountTypes
 * @component
 * @category BPP
 * @subcategory Provider Types
 */
class AccountTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_id: null,
    };
  }

  render() {
    return (
      <div className="tiers external">
        <div className="settings-heading content-heading">External Target Date Settings</div>
        <table>
          <thead>
            <tr className="settings-heading">
              <th>No.</th>
              <th>Provider</th>
            </tr>
          </thead>
          <tbody>
            {this.props.external_target_days_view.map((item, index) => {
              return (
                <tr
                  className={this.props.selected_setting_id === item.id ? 'tier-selected' : this.props.edit_mode ? 'tier-disabled' : ''}
                  onClick={() => this.props.onProviderClick(item.id)}
                  key={index}
                >
                  <td>{index + 1}</td>
                  <td>
                    {item.account_type} {!item.current_ind && <span className="disabled-badge"> - ACCOUNT DISABLED</span>}
                    {this.props.selected_setting_id === item.id && <i className="fa fa-caret-right" aria-hidden="true" />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AccountTypes;
