import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { onDownload } from '../../../common/functions';

import './wire_selection_output.scss';
import WireSelectionRedo from './wire_selection_redo';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { getCustomWoCurrentRev } from '../../../components/workflow/wire_selection/wire_wo_creation_detail';
import { connect } from 'react-redux';
import { getWsGuideFileNameError } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import { removeCaseIdInitialNumber } from '../../../common/functions';
import Axios from 'axios';

const END_DATE = '2024-03-31T23:59:59';

const REDO_TOOLTIP = 'Appliance Design must be approved to redo wire selection';

const custom_part_num = {
  lower_specialty: {
    name: 'Lower Smartwire 0',
    part_no: '40351-05',
  },
  upper_specialty: {
    name: 'Upper Smartwire 0',
    part_no: '40352-05',
  },
  lower_initial: {
    name: 'Lower Smartwire 1',
    part_no: '40375-05',
  },
  upper_initial: {
    name: 'Upper Smartwire 1',
    part_no: '40376-05',
  },
  lower_intermediate: {
    name: 'Lower Smartwire 2',
    part_no: '40377-05',
  },
  upper_intermediate: {
    name: 'Upper Smartwire 2',
    part_no: '40378-05',
  },
  lower_final: {
    name: 'Lower Smartwire 3',
    part_no: '40379-05',
  },
  upper_final: {
    name: 'Upper Smartwire 3',
    part_no: '40380-05',
  },
};

const sortCW = (custom_wires) => {
  let sorted_custom_wires = custom_wires.map((cw) => {
    cw.wire_order = cw.wire_name
      .replace('upper_', '')
      .replace('lower_', '')
      .replace('specialty', 0)
      .replace('initial', 1)
      .replace('intermediate', 2)
      .replace('final', 3);
    cw.wire_arch = cw.wire_name.indexOf('upper') >= 0 ? 1 : 2;
    return cw;
  });

  return _.orderBy(sorted_custom_wires, ['wire_order', 'wire_arch'], ['asc', 'asc']);
};

const getRev = (ws) => {
  if (ws && ws.length > 0 && ws[0] && ws[0]['wire_rev']) {
    return ws[0]['wire_rev'].includes('-') ? ws[0]['wire_rev'].substr(0, ws[0]['wire_rev'].indexOf('-')) : ws[0]['wire_rev'];
  }
  return 'A';
};

function WireSelectionOutput(props) {
  const [showNewIcon, setShowNewIcon] = useState(false);
  const { case_status, onUploadClick } = props;
  const [redoWS, setRedoWS] = useState(false);

  /**
   * Closes Redo Modal and Clears any Temp files that may have been uploaded
   */
  const closeAndClearTempFiles = () => {
    Axios.post(`/apiv3/wireselection/${props.case_id}/clear_temp`)
      .then((res) => {
        setRedoWS(false);
      })
      .catch((e) => {
        setRedoWS(false);
      });
  };

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  useEffect(() => {
    const today = new Date();
    const endDate = new Date(END_DATE);
    setShowNewIcon(today <= endDate);
  }, []);

  const can_redo = case_status === 'STATUS_MANUFACTURE';
  const tooltip = can_redo ? '' : REDO_TOOLTIP;

  const has_redo = props.ws_statuses.filter((status) => status.status === 'WS Redo');
  const rev = getRev(props.ws);
  const custom_wires = props.ws.filter((wire) => {
    const default_is_not_template =
      wire.selection_override === null && wire.selection !== 'template' && wire.selection !== 'universal_template' && wire.selection !== 'none';
    const user_choice_is_not_template =
      wire.selection_override !== null && wire.selection_override !== 'template' && wire.selection_override !== 'universal_template';
    const is_correct_arch = props.arch === 'both' || wire.wire_name.indexOf(props.arch) >= 0;

    return (default_is_not_template || user_choice_is_not_template) && is_correct_arch;
  });

  const ws_guide = props.ws_files.filter((file) => file.file_type === 'ws_guide');
  const sorted_ws_guide = _.orderBy(ws_guide, ['id'], ['desc']);

  const isManual = (wire) => {
    return (
      wire.wire_name.includes('specialty') ||
      (wire.selection_override && wire.selection_override.indexOf('manual') >= 0) ||
      (wire.selection_override === null && wire.selection.indexOf('manual') >= 0)
    );
  };
  /**
   * Constructs the URL needed for the download
   * @param {Object} wire - contains the wire objects
   * @function
   */
  const getURLBySelection = (wire) => {
    return isManual(wire) ? wire.case_file_manual__upload_data : wire.case_file__upload_data;
  };

  /**
   * Determines if allowed to replace Wire Selection Guide
   * @function
   * @returns {boolean} - True or false
   */
  const shouldDisplayReplaceButton = (user_roles_and_permissions) => {
    const before_shipping_approved = props.case_details && !props.case_details.shipping_approved_ind;
    const has_ws_redo = props.ws_statuses.filter((s) => s.status === 'WS Redo').length > 0;
    const has_wfr_rework = props.wire_fabrication_review_status_log.filter((s) => s.status === 'WFR Rework').length > 0;
    const has_permission = userHasPermission('CASE_WIRE_SELECTION_REVIEW', user_roles_and_permissions.permissions);
    return before_shipping_approved && (has_ws_redo || has_wfr_rework) && !props.is_cancel_or_hold && has_permission && rev !== 'A';
  };

  const sorted_custom_wires = sortCW(custom_wires);

  const adjust_bmf_file = props.ws_files.findLast((file) => file.file_type === 'ws_bmf' && file.upload_data.includes('.pdf'));
  const adjust_bmf_file_csv = props.ws_files.findLast(
    (file) => file.upload_data.includes(`${props.case_details.case_id}_adjusted_bmf.csv`) && file.file_type === 'ws_bmf_csv'
  );

  const bmf_url = props.bmf_file ? props.bmf_file.url : '';
  const bmf_rev = props.bmf_file ? props.bmf_file.bmf_rev : 'N/A';
  const bmf_csv = adjust_bmf_file_csv ? adjust_bmf_file_csv.upload_data : '';

  const custom_wo_current_rev = getCustomWoCurrentRev(props.ws);
  const custom_wo = props.ws_files.find((file) => file.file_type === 'ws_custom_wo' && file.upload_data.includes(`/${custom_wo_current_rev}/`));

  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        return (
          <div className="wire-selection-output">
            <div>
              Custom Wire WO Ref: {props.ws_wo ? props.ws_wo : 'N/A'}
              {props.case_details.ws_rework_no ? ` (Rework ${props.case_details.ws_rework_no})` : null}
              {has_redo && has_redo.length > 0 ? ' (Wire selection has been redone, refer to NCR)' : null}
            </div>
            {custom_wo && (
              <>
                <div>
                  <span
                    className="underline-text viewable-text file-span"
                    onClick={() => {
                      props.setModal('wo_pdf', custom_wo);
                    }}
                  >
                    Custom Wire WO <i className="fa fa-file-pdf-o" aria-hidden="true" />
                  </span>
                </div>
              </>
            )}
            {bmf_url && (
              <div>
                <a className="underline-text viewable-text view-upload" href={`/${bmf_url}`} download onClick={onDownload}>
                  Bracket Measurements Form <i className="fa fa-file-excel-o" aria-hidden="true" />
                </a>
                <span>
                  (<span className="bold-text">Rev#: </span>
                  {bmf_rev})
                </span>
              </div>
            )}
            {adjust_bmf_file && (
              <div>
                <span
                  className="underline-text viewable-text file-span"
                  onClick={() => {
                    props.setModal('bmf_pdf', adjust_bmf_file);
                  }}
                >
                  Adjusted Bracket Measurements Form
                  <i className="fa fa-file-pdf-o abmf-file-icon" aria-hidden="true" />
                </span>
              </div>
            )}
            {bmf_csv && (
              <div>
                <a className="underline-text viewable-text file-span csv-link" href={`/${bmf_csv}`} download onClick={onDownload}>
                  Download ABMF CSV
                </a>
                <i className="fa fa-file-excel-o csv-icon-file" aria-hidden="true" />
                {showNewIcon && <span className="new-icon"> New!</span>}
              </div>
            )}
            {sorted_ws_guide.length > 0 ? (
              <>
                <div className="bold-text margin-top-10">Wire Selection</div>
                <div>
                  <span
                    className="underline-text viewable-text file-span"
                    onClick={() => {
                      props.setModal('ws_pdf', sorted_ws_guide[0]);
                    }}
                  >
                    Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true" />
                  </span>
                  {rev !== 'A' ? ` (Wire Selection Guide Rev: ${rev})` : ''}
                  {sorted_ws_guide[0]['upload_data'].includes('_manual') && <span className="ws-manual">(Manually Uploaded)</span>}
                  {shouldDisplayReplaceButton(user_roles_and_permissions) && (
                    <button
                      className="btn btn-light wsg-replace"
                      onClick={() => {
                        props.onUploadClick('', 'Replace Wire Selection Guide', 'ws_guide', false, rev, true);
                      }}
                    >
                      Replace
                    </button>
                  )}
                </div>
                {props.ws_guide_file_name_error && (
                  <>
                    <div className="subtle-error tablet-up">
                      Incorrect filename - Filename should be: {removeCaseIdInitialNumber(props.case_id)} - Wire Selection Guide Form
                    </div>
                    <div className="subtle-error mobile-only">
                      Incorrect filename <br /> Filename should be: {removeCaseIdInitialNumber(props.case_id)} - Wire Selection Guide Form
                    </div>
                  </>
                )}
              </>
            ) : null}
            {custom_wires.length > 0 ? (
              <>
                <div className="bold-text margin-top-10">Custom Wire</div>
                {sorted_custom_wires.map((wire, idx) => (
                  <div key={idx}>
                    <a className="underline-text viewable-text file-span" href={`/${getURLBySelection(wire)}`} download onClick={onDownload}>
                      {wire.wire_name in custom_part_num ? custom_part_num[wire.wire_name]['part_no'] : 'Unknown'}{' '}
                      {wire.wire_name in custom_part_num ? custom_part_num[wire.wire_name]['name'] : 'Unknown'}{' '}
                      <i className="fa fa-file-archive-o" aria-hidden="true"></i>
                    </a>
                    {isManual(wire) && <span className="ws-manual">(Manually Uploaded)</span>}
                    {wire.rework_no && <span className="ws-manual">(Rework {wire.rework_no})</span>}
                  </div>
                ))}
              </>
            ) : null}
            {props.case_status !== 'STATUS_SHIP' &&
            !props.is_cancel_or_hold &&
            !props.case_details.shipping_approved_ind &&
            props.case_details.ws_version === '1.0' &&
            userHasPermission('CASE_WIRE_SELECTION_REDO', user_roles_and_permissions.permissions) ? (
              <div className="redo-wire-btn" data-toggle="tooltip" title={tooltip} data-original-title={tooltip}>
                <button className="btn btn-light" disabled={!can_redo} onClick={() => setRedoWS(true)}>
                  Redo Wire Selection
                </button>
              </div>
            ) : null}
            {redoWS && (
              <WireSelectionRedo onClose={() => setRedoWS(false)} closeAndClearTempFiles={closeAndClearTempFiles} onUploadClick={onUploadClick} {...props} />
            )}
          </div>
        );
      }}
    </UserPermissionsContext.Consumer>
  );
}

WireSelectionOutput.propTypes = {
  ref_no: PropTypes.string,
  case_status: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    ws_guide_file_name_error: getWsGuideFileNameError(state),
  };
};

export { custom_part_num };
export default connect(mapStateToProps)(WireSelectionOutput);
