// External
import React from 'react';
import _ from 'lodash';

// Internal
import { userHasPermission } from '../../common/permission';
import './programs.scss';

/**
 * Component for program enrollment in doctor account page
 * @function
 */
const Programs = ({
  doctor_programs,
  user_permissions,
  edit_mode,
  in_progress,
  onProgramConfirm,
  onEditProfileCancel,
  onEditClick,
  setDoctorPrograms,
  dso_data,
  profile,
}) => {
  /**
   * Creates program enrollment
   * @function
   * @returns {JSX} - JSX for programs
   */
  const displayProgramEnrollment = () => {
    const left = [];
    const right = [];
    const columns = [left, right];
    doctor_programs
      .filter((program) => {
        return program?.current_ind === true;
      })
      .forEach((program, index) => columns[index % 2].push(program));

    return (
      <div className="page-tab-content-section">
        <div className="table-info-view">
          <div className="account-heading">Program Enrollment</div>
          <div className="program row">
            {columns.map((column, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  <table className="table feature-table">
                    <tbody className="table-inbrace-dark-industry-2">{displayProgramColumn(column)}</tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Displays single column of programs
   * @function
   * @param {list} programs - List of programs
   * @returns {JSX} - JSX for program column
   */
  const displayProgramColumn = (programs) => {
    return programs.map((program, index) => {
      const is_editing = edit_mode === program.name;
      const has_permission = userHasPermission('PROGRAM_SETTINGS_ENABLE_DISABLE', user_permissions);
      let program_available = true;
      const dso_doctor_roles = ['DSO_Doctor', 'DSO_Doctor_Override'];
      if (dso_doctor_roles.includes(profile?.role)) {
        if (program.program_id === 'epp') {
          program_available = dso_data?.enrolled_to_epp;
        } else {
          program_available = !!dso_data?.program_enrollment?.find((program_id) => program.program_id === program_id);
        }
      }
      const can_edit_click = !is_editing && has_permission && program_available;

      return (
        <React.Fragment key={index}>
          <tr className="pointer">
            <td
              className="account-table-start type-width-tb account-table-first"
              colSpan="2"
              data-mode={program.name}
              onClick={can_edit_click ? onEditClick : null}
            >
              <div className="program__item">{program.name}</div>
            </td>
            {displayOptedIn(program, is_editing, can_edit_click)}
            {is_editing ? displaySaveCancel(program) : <td className="type-width-tb"></td>}
          </tr>
          {program.error ? (
            <tr className="pointer">
              <td className="type-width-tb" colSpan="4">
                <span className="general-error">{program.error}</span>
              </td>
            </tr>
          ) : null}
        </React.Fragment>
      );
    });
  };

  /**
   * Displays opted-in selection
   * @function
   * @param {object} program - Given program
   * @param {boolean} is_editing - Indicator if program is being edited
   * @returns {JSX} - JSX for opted-in
   */
  const displayOptedIn = (program, is_editing, can_edit_click) => {
    return (
      <td
        className="account-table-end type-width-tb account-table-dark"
        data-mode={program.name}
              onClick={can_edit_click ? onEditClick : null}
      >
        <div className="program__opt">
          Opted In:
          <input
            className="checkbox-button program__checkbox"
            type="checkbox"
            data-id={program.program_id}
            name={program.name}
            checked={program.checked}
            readOnly={!is_editing}
            onChange={is_editing ? onProgramChange : null}
            disabled={is_editing ? program.disabled : false}
          />
        </div>
      </td>
    );
  };

  /**
   * Displays save and cancel options
   * @function
   * @param {object} program - Given program
   * @returns {JSX} - JSX for save/cancel
   */
  const displaySaveCancel = (program) => {
    return (
      <td className="type-width-tb">
        <div
          id="edit-confirm"
          className="btn-clear "
          onClick={() => onProgramConfirm(program.program_id)}
          data-toggle="tooltip"
          data-placement="top"
          title="Save"
        >
          <i className="fa fa-check" aria-hidden="true" />
        </div>
        <div
          id="edit-discard"
          className={'btn-clear ' + (in_progress ? 'btn-disabled' : '')}
          onClick={onEditProfileCancel}
          data-toggle="tooltip"
          data-placement="top"
          title="Cancel"
        >
          <i className="fa fa-times" aria-hidden="true" />
        </div>
      </td>
    );
  };

  /**
   * Handles Program Checkbox Toggles
   * @function
   * @param {object} event - Event object
   */
  const onProgramChange = (event) => {
    const program_id = event.target.getAttribute('data-id');
    const doctor_programs_copy = _.cloneDeep(doctor_programs);
    const program_to_edit = doctor_programs_copy.find((program) => program.program_id === program_id);
    program_to_edit.checked = !program_to_edit.checked;
    program_to_edit.levels.checked = program_to_edit.levels.current;
    setDoctorPrograms(doctor_programs_copy);
  };

  return displayProgramEnrollment();
};

export default Programs;
