export const ORIGINAL_SMARTWIRE_OPTIONS = [
  { value: 'Auto Custom', label: 'Auto Custom' },
  { value: 'Experimental', label: 'Experimental' },
  { value: 'Manual Custom', label: 'Manual Custom' },
  { value: 'No Original Wire', label: 'No Original Wire' },
  { value: 'Template', label: 'Template' },
];

export const ORIGINAL_WIRE = 'Original Wire';

export const IR_TYPE_OPTIONS = [
  { value: 'Redesign', label: 'Redesign' },
  { value: ORIGINAL_WIRE, label: ORIGINAL_WIRE },
];

export const WIRE_TYPE_OPTIONS = [
  { value: 'Manual Custom', label: 'Manual Custom' },
  { value: 'Minitab', label: 'Minitab' },
  { value: 'Pusher', label: 'Pusher' },
  { value: 'Minitab + Pusher', label: 'Minitab + Pusher' },
  { value: 'EXP', label: 'EXP' },
];

export const PUSER_OPTION = ['Pusher', 'Minitab + Pusher'];

export const FIELDS_TO_DISPLAY = {
  original_smartwire: { isVisible: true, isEditable: false },
  ir_type: { isVisible: true, isEditable: false },
  wire_type: { isVisible: false, isEditable: false },
  dxf_file: { isVisible: false, isEditable: true, isDeletable: false },
};

export const WS_CONVERSION_SUCCEEDED_STATUS = 'WS Conversion Succeeded';
export const WS_CONVERSION_STATUS = 'WS Conversion';
export const WS_CONVERSION_FAILED_STATUS = 'WS Conversion Failed';
export const WS_MANUAL_CONVERSION_STATUS = 'WS Manual Process';

export const WS_CONVERTING_STATUSES = [WS_CONVERSION_STATUS, WS_MANUAL_CONVERSION_STATUS, WS_CONVERSION_FAILED_STATUS, WS_CONVERSION_SUCCEEDED_STATUS];

export const WS_SELECTION_STATUS = 'WS Selection';
export const WS_RECOMMENDATION_STATUS = 'WS Recommendation';
export const WS_CLINICAL_REVIEW_STATUS = 'WS Clinical Review';
export const WS_REDESIGN_STATUS = 'WS Redesign';
export const WS_REDESIGN_FABRICATION_STATUS = 'WS Redesign Fabrication';
export const WS_REDESIGN_REVIEW_STATUS = 'WS Redesign Review';
export const WS_CUSTOM_WO_STATUS = 'WS Custom WO';
export const WS_COMPLETE_STATUS = 'WS Complete';
export const WS_COMPLETE_FABRICATION_STATUS = 'WS Complete Fabrication';
export const WS_WIRE_FABRICATION_STATUS = 'WS Wire Fabrication';
export const WS_WIRE_FABRICATION_REINITIATED_STATUS = 'WS Wire Fabrication Reinitiated';
export const WS_WIRE_FABRICATION_RESOLVED_STATUS = 'WS Wire Fabrication Resolved';

export const WS_WORKFLOW_STATUSES = [
  WS_SELECTION_STATUS,
  WS_RECOMMENDATION_STATUS,
  WS_CLINICAL_REVIEW_STATUS,
  WS_REDESIGN_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_REDESIGN_REVIEW_STATUS,
  WS_CUSTOM_WO_STATUS,
  WS_COMPLETE_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_CONVERSION_STATUS,
  WS_CONVERSION_SUCCEEDED_STATUS,
  WS_CONVERSION_FAILED_STATUS,
  WS_MANUAL_CONVERSION_STATUS,
  WS_WIRE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
];

export const WS_WORKFLOW_READY = [WS_COMPLETE_STATUS, WS_WIRE_FABRICATION_RESOLVED_STATUS];

export const SHOW_NOTES_BY_STATUS = {
  [WS_RECOMMENDATION_STATUS]: { isEditable: true },
  [WS_CLINICAL_REVIEW_STATUS]: { isEditable: false },
  [WS_REDESIGN_STATUS]: { isEditable: false },
  [WS_REDESIGN_REVIEW_STATUS]: { isEditable: false },
};

export const SHOW_REVISION_NOTES_BY_STATUSES = {
  [WS_REDESIGN_STATUS]: { isEditable: false },
  [WS_REDESIGN_REVIEW_STATUS]: { isEditable: true, only_in_modal: true },
};

export const SHOW_GUIDE_BY_STATUS = [
  WS_REDESIGN_REVIEW_STATUS,
  WS_COMPLETE_STATUS,
  WS_WIRE_FABRICATION_STATUS,
  WS_CUSTOM_WO_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
];

export const SHOW_WO_BY_STATUS = [
  WS_COMPLETE_STATUS,
  WS_WIRE_FABRICATION_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
];

export const PERMISSION_CODES = {
  [WS_SELECTION_STATUS]: 'IREQ_APPROVE',
  [WS_RECOMMENDATION_STATUS]: 'IREQ_DESIGN_RECOMMENDATION',
  [WS_CLINICAL_REVIEW_STATUS]: 'IREQ_CLINICAL_REVIEW',
  [WS_REDESIGN_STATUS]: 'IREQ_WIRE_REDESIGN',
  [WS_REDESIGN_FABRICATION_STATUS]: 'IREQ_WIRE_REDESIGN',
  [WS_REDESIGN_REVIEW_STATUS]: 'IREQ_WIRE_REDESIGN_REVIEW',
};

export const ASSIGNED_TO_STATUSES = [
  WS_RECOMMENDATION_STATUS,
  WS_CLINICAL_REVIEW_STATUS,
  WS_REDESIGN_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_REDESIGN_REVIEW_STATUS,
  WS_CUSTOM_WO_STATUS,
];
