import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressRing from './progress-ring';

/**
 * Displays stepper for form
 * @component
 */
class Stepper extends Component {
  /**
   * Retrieves a step's style class names
   * @function
   * @param {Object} step - The object that describes a step
   * @return {String} A step's class names
   */
  getStepperClassName(step) {
    let classNames = ['stepper__item'];
    if (step.completed) classNames.push('stepper__item--completed');
    if (step.active) classNames.push('stepper__item--active');
    if (step.uploading) classNames.push('stepper__item--blinking');
    if (step.disabled) classNames.push('stepper__item--disabled');
    return classNames.join(' ');
  }

  /**
   * Displays the progress ring for mobile
   * @function
   * @param {Array} content - The list of steps
   * @return {JSX} The progress ring
   */
  displayProgressRing(content) {
    const totalStep = content.length;
    const currentStep = this.props.activeStepper + 1;
    const completedSteps = content.reduce((acc, curr) => (curr.completed ? acc + 1 : acc), 0);

    // show a small percent in the beginning to indicate progress
    const percent = completedSteps <= 0 ? 4 : (completedSteps / totalStep) * 100;
    const text = `${currentStep} of ${totalStep}`;
    return <ProgressRing progress={percent} text={text} size={60} strokeWidth={4} />;
  }

  /**
   * Displays the mobile stepper
   * @function
   * @param {Array} content - The list of steps
   * @return {JSX} The mobile stepper JSX
   */
  displayMobileStepper(content) {
    const isLastStep = this.props.activeStepper + 1 === content.length;
    return (
      <div className="stepper show-on-mobile">
        {this.displayProgressRing(content)}
        <div className="stepper__info">
          <h3>{content[this.props.activeStepper].label}</h3>
          {!isLastStep && (
            <div>
              <span className="emphasis">Next: </span>
              <span>{content[this.props.activeStepper + 1].label}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  /**
   * Displays the desktop stepper
   * @function
   * @param {Array} content - The list of steps
   * @return {JSX} The stepper JSX
   */
  displayStepper(content) {
    return (
      <div className="stepper hide-on-mobile">
        {content.map((item, index) => (
          <React.Fragment key={item.description}>
            <span className={this.getStepperClassName(item)} onClick={item.disabled ? null : this.props.onStepperClick} data-description={item.description}>
              {item.label}
            </span>
            {index !== content.length - 1 && <span className="stepper__item__divider" />}
          </React.Fragment>
        ))}
      </div>
    );
  }

  render() {
    return (
      <>
        {this.displayMobileStepper(this.props.stepperContent)}
        {this.displayStepper(this.props.stepperContent)}
      </>
    );
  }
}

Stepper.propTypes = {
  onStepperClick: PropTypes.func.isRequired,
  stepperContent: PropTypes.array.isRequired,
  activeStepper: PropTypes.number.isRequired,
};

export default Stepper;
