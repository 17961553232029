// import { Group, Box3, Sphere, MeshPhongMaterial } from 'three';
import * as THREE from 'three';
import IPR from './IPR';
import { getMat } from '../common/mesh';

/**
 * Class that manages a single step in a setup
 */
class StepMeshGroup {
  constructor() {
    this.meshGroup = new THREE.Group();
    this.boundingBox = new THREE.Sphere();

    this.stepMeshes = {
      maxGum: new THREE.Group(),
      maxTeeth: new THREE.Group(),
      manTeeth: new THREE.Group(),
      manGum: new THREE.Group(),
    };
    this.ipr = new IPR();
  }

  /**
   * Sets this step's meshes
   * @function
   * @param {Object} meshes - The step's meshes
   * @param {String} version - The ipr version
   */
  setMeshes(meshes, version) {
    this.clear();
    for (const key of Object.keys(meshes)) {
      this.stepMeshes[key] = meshes[key].clone();
      this.meshGroup.add(this.stepMeshes[key]);
    }
    this.applyMat(version);
    this.computeBoundingBox();
  }

  /**
   * Generates the step's ipr meshes
   * @function
   * @param {Object} iprJSON - The ipr json
   */
  generateIPRMeshes(iprJSON) {
    this.ipr.generateIPRMeshes(iprJSON);
    this.ipr.addIPR(this.meshGroup);
  }

  /**
   * Updates the ipr
   * @function
   * @param {Number} textSize - The text size
   * @param {THREE.Quaternion} quat - The camera's quaternion
   */
  updateIPR(textSize, quat) {
    this.ipr.updateIPR(textSize, quat);
  }

  /**
   * To show or hide the ipr
   * @function
   * @param {Boolean} show - To show or not
   */
  show(show) {
    this.meshGroup.visible = show;
  }

  /**
   * Adds the ipr's meshes to the scene
   * @function
   * @param {THREE.Scene} scene - The scene to contain the ipr meshes
   */
  addToScene(scene) {
    scene.add(this.meshGroup);
  }

  /**
   * Removes the ipr's meshes from the scene
   * @function
   * @param {THREE.Scene} scene - The scene that contains the ipr meshes
   */
  removeFromScene(scene) {
    scene.remove(this.meshGroup);
  }

  /**
   * Clears the ipr's meshes
   * @function
   */
  clear() {
    this.meshGroup.remove(...this.meshGroup.children);
  }

  /**
   * Cleans up the ipr's meshes from memory
   * @function
   */
  dispose() {
    for (const mesh of Object.values(this.stepMeshes)) {
      mesh.geometry.dispose();
      if (mesh.material.map) {
        mesh.material.map.dispose();
      }
      mesh.material.dispose();
    }
    this.ipr.dispose();
  }

  /**
   * Computes the bounding box of this step
   * @function
   */
  computeBoundingBox() {
    const box = new THREE.Box3().setFromObject(this.meshGroup);
    const sphere = box.getBoundingSphere(new THREE.Sphere());
    this.boundingBox = sphere;
  }

  /**
   * Returns the center of the meshes
   * @function
   * @return {THREE.Vector3} The center
   */
  getCenter() {
    return this.boundingBox.center;
  }

  /**
   * Returns the radius of the meshes
   *
   * Specifying the center will calculate the distance between
   *  the two center
   * @function
   * @param {THREE.Vector3} [center] - The center of another point
   * @return {Number} The radius
   */
  getRadius(center = null) {
    let r = this.boundingBox.radius;
    if (center) {
      const r2 = this.boundingBox.center.distanceTo(center);
      r += r2;
    }
    return r;
  }

  /**
   * Sets both teeth arch's material
   * @function
   * @param {THREE.Material} material - The material
   */
  setTeethMaterial(material) {
    this.stepMeshes.maxTeeth.material = material;
    this.stepMeshes.manTeeth.material = material;
  }

  /**
   * Sets the upper arch teeth's material
   * @function
   * @param {THREE.Material} material - The material
   */
  setUpperTeethMaterial(material) {
    this.stepMeshes.maxTeeth.material = material;
  }

  /**
   * Sets the lower arch teeth's material
   * @function
   * @param {THREE.Material} material - The material
   */
  setLowerTeethMaterial(material) {
    this.stepMeshes.manTeeth.material = material;
  }

  /**
   * Shows teeth base on view options
   * @function
   * @param {Object} viewOptions - The view options
   */
  showTeeth(viewOptions) {
    for (const children of this.meshGroup.children) children.visible = false;
    this.stepMeshes.maxTeeth.visible = this.showUpperJaw(viewOptions.jaw);
    this.stepMeshes.manTeeth.visible = this.showLowerJaw(viewOptions.jaw);
    this.show(true);
  }

  /**
   * Sets the step's view
   * @function
   * @param {Object} viewOptions - The view options
   */
  setStepViewOptions(viewOptions) {
    const { gingiva, ipr, jaw } = viewOptions;
    this.showMesh(this.stepMeshes.maxGum, gingiva && this.showUpperJaw(jaw));
    this.showMesh(this.stepMeshes.maxTeeth, this.showUpperJaw(jaw));
    this.showMesh(this.stepMeshes.manTeeth, this.showLowerJaw(jaw));
    this.showMesh(this.stepMeshes.manGum, gingiva && this.showLowerJaw(jaw));
    this.ipr.showMaxIPR(ipr && this.showUpperJaw(jaw));
    this.ipr.showManIPR(ipr && this.showLowerJaw(jaw));
  }

  /**
   * Show or hide to mesh
   * @function
   * @param {THREE.Material} mesh - The material
   * @param {Boolean} show - To show or not
   */
  showMesh(mesh, show) {
    if (mesh) {
      mesh.visible = show;
    }
  }

  /**
   * Returns whether to show upper jaw or not
   * @function
   * @param {String} jaw - The jaw to show
   * @return {Boolean} Whether to show upper jaw
   */
  showUpperJaw(jaw) {
    return jaw !== 'lower';
  }

  /**
   * Returns whether to show lower jaw or not
   * @function
   * @param {String} jaw - The jaw to show
   * @return {Boolean} Whether to show lower jaw
   */
  showLowerJaw(jaw) {
    return jaw !== 'upper';
  }

  /**
   * Applies the proper material base on version
   * @function
   * @param {String} version - The ipr json version
   */
  applyMat(version) {
    const { matGums, matTeeth } = getMat(version);
    this.stepMeshes.maxGum.material = matGums;
    this.stepMeshes.maxTeeth.material = matTeeth;
    this.stepMeshes.manTeeth.material = matTeeth;
    this.stepMeshes.manGum.material = matGums;
  }
}

export { StepMeshGroup };
