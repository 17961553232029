/**
 * Converts filter name to a readable title
 * @function
 * @return {String} String version of filter
 */
const SDSFilterToTitle = (filter) => {
  const titles = {
    action: 'Action Required',
    draft: 'Draft',
    new: 'Upload New Records',
    setup: 'Review Smile Design',
    clarification: 'Pending Clarification',
    hold: 'On Hold',
    active: 'Active',
    ship: 'Shipped',
    cancel: 'Cancelled',
  };

  if (titles[filter]) {
    return titles[filter];
  }

  return filter;
};

export { SDSFilterToTitle };
