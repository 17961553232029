import React from 'react';
import { connect } from 'react-redux';
import './sw_zero.scss';
import SwZeroArch from './arch';
import { getSessionExpire } from '../../../../redux/reducers/bpp/wire_selection/wire_selection';
import SessionExpire from '../../../../common/session_expire';

const SwZero = (props) => {
  const { sessionExpire, arch, wire_fabrication_review_status, ws } = props;
  const reworkInProgress = wire_fabrication_review_status === 'WFR Rework';

  const getReworkWireInfo = (wireName) => {
    return ws.filter((wire) => {
      if (wire.wire_name === wireName && wire.status === 'Failure' && wire.comment) {
        return wire.comment.course_of_action === 'Redesign';
      }
      return false;
    });
  };

  let showSwZero = true;
  if (reworkInProgress) {
    const upperWireInfo = getReworkWireInfo('upper_specialty');
    const lowerWireInfo = getReworkWireInfo('lower_specialty');
    showSwZero = upperWireInfo.length > 0 || lowerWireInfo.length > 0;
  }

  return (
    <div className="wire-selection-review-item">
      {showSwZero && (
        <>
          <div className="bold-text">Smartwire 0</div>
          <form>
            {arch !== 'lower' && <SwZeroArch {...props} swZeroArch="upper" reworkInProgress={reworkInProgress} getReworkWireInfo={getReworkWireInfo} />}
            {arch !== 'upper' && <SwZeroArch {...props} swZeroArch="lower" reworkInProgress={reworkInProgress} getReworkWireInfo={getReworkWireInfo} />}
          </form>
        </>
      )}
      {sessionExpire && <SessionExpire />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sessionExpire: getSessionExpire(state),
  };
};

export default connect(mapStateToProps, null)(SwZero);
