// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

class Convert extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Automated Wire Bending</div>
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_AWB_CONVERT', user_roles_and_permissions.permissions) ? (
                <React.Fragment>
                  <div className="center-text">
                    The required {this.props.selections && this.props.selections.length > 1 ? 'wires' : 'wire'} for this item request have been preselected.
                    Click <span className="bold-text">Convert</span> to begin the conversion process using the original input file. Or click{' '}
                    <span className="bold-text">Manual Process</span> to manually enter the required input for conversion.
                  </div>

                  <div className="extra-padding-headline-bpp" />
                  <div className={'row label-center'}>
                    {this.props.selections && this.props.selections.length > 0
                      ? this.props.selections.map((selection, index) => {
                          return (
                            <div key={index} className={'col-lg-12'}>
                              <input
                                id={selection.parts_id}
                                className={selection.hide ? 'filter-hidden' : 'label-checkbox-ir pointer'}
                                type="checkbox"
                                name="case_labels"
                                value={selection.state}
                                checked={true}
                                disabled={true}
                              />
                              <label className={selection.hide ? 'filter-hidden' : 'label-text-ir'} htmlFor={selection.parts_id}>
                                {selection.description}
                              </label>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="extra-padding-headline-bpp" />
                  <div className="button-panel center-text">
                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('IREQ_AWB_CONVERT', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                          <button type="button" className="btn btn-light" onClick={this.props.openAwbConvertModal}>
                            Convert
                          </button>
                        ) : (
                          <button type="button" className="btn btn-light" disabled={true}>
                            Convert
                          </button>
                        );
                      }}
                    </UserPermissionsContext.Consumer>

                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('IREQ_AWB_MANUAL_CONVERSION', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                          <button type="button" className="btn btn-light" onClick={this.props.openManualModal}>
                            Manual Process
                          </button>
                        ) : (
                          <button type="button" className="btn btn-light" disabled={true}>
                            Manual Process
                          </button>
                        );
                      }}
                    </UserPermissionsContext.Consumer>

                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('IREQ_AWB_OVERRIDE', user_roles_and_permissions.permissions) ? (
                          <button type="button" className="btn btn-light" onClick={this.props.openOverrideModal} disabled={!this.props.ref_no}>
                            Override
                          </button>
                        ) : null;
                      }}
                    </UserPermissionsContext.Consumer>
                  </div>
                </React.Fragment>
              ) : (
                <div className="center-text">Automated wire bending process in progress...</div>
              );
            }}
          </UserPermissionsContext.Consumer>
        </div>
      </React.Fragment>
    );
  }
}

export default Convert;
