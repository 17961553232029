import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../../../components/loader/circle_loader';
import ImageViewer, { initialPosition } from './image_viewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function RecordThumbnail(props) {
  const {
    record,
    onLoad,
    tooltip,
    onClick,
    onRemove,
    removeAllowed,
    disabled,
    deletionInProcess,
    // iTero integration
    iteroRemoveAllowed = false,
    isLoadingIteroPhotos = false,
    isDeletingIteroPhotos = false,
  } = props;
  const [position, setPosition] = useState(initialPosition);
  const onRotate = () => onClick(record.id, 'rotate');
  const onFlipH = () => onClick(record.id, 'flipH');
  const onFlipV = () => onClick(record.id, 'flipV');
  const zoomIn = () => onClick(record.id, 'zoomIn');
  const zoomOut = () => onClick(record.id, 'zoomOut');
  const setScale = (scale) => onClick(record.id, scale);
  const viewerFunc = { setPosition, setScale, zoomIn, zoomOut, onRotate, onFlipH, onFlipV };

  useEffect(() => {
    if (record.loading) {
      onLoad(record.id, record.url);
    }
  }, [record, onLoad]);

  const classNames = ['small-img-window'];
  if (props.selected) classNames.push('active');

  const viewerState = { position, ...record.state };
  const show_delete_icon = useMemo(() => {
    // iTero integration
    if (isLoadingIteroPhotos || isDeletingIteroPhotos) {
      return false;
    }

    if (record.is_itero_scan) {
      return iteroRemoveAllowed;
    }

    return removeAllowed && !record.loading && !disabled && record.id !== 'occlusion-view';
  }, [record, removeAllowed, disabled, iteroRemoveAllowed, isLoadingIteroPhotos, isDeletingIteroPhotos]);
  const BootstrapTooltip = withStyles({
    arrow: {
      color: 'rgba(0,0,0,0.8)',
    },
    tooltip: {
      fontSize: '12px',
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  })(Tooltip);

  return (
    <div className={`thumbnail_image${record.hidden ? ' thumbnail_image__hidden' : ''}`}>
      <BootstrapTooltip title={tooltip} placement="top" arrow>
        <div className={classNames.join(' ')} onClick={!record.loading ? onClick : null}>
          {record.loading ? <CircleLoader /> : <ImageViewer src={record.thumbnail} controlsDisabled={true} {...viewerState} {...viewerFunc} />}
        </div>
      </BootstrapTooltip>
      {show_delete_icon ? (
        <BootstrapTooltip title="Delete" placement="top" arrow>
          <div className="remove-location">
            <a
              href={'/' + record.upload_data}
              onClick={onRemove}
              onDoubleClick={onRemove}
              data-type={record.file_type}
              data-file_id={record.incomplete_case_file_id ? record.incomplete_case_file_id : record.id}
              data-href={'/' + record.upload_data}
              data-original_filename={record.original_filename}
              data-itero-file-id={record.itero_file_id}
            >
              <FontAwesomeIcon
                style={{ display: record.hideDeleteIcon ? 'none' : 'block', pointerEvents: deletionInProcess ? 'none' : 'auto' }}
                icon="fa-solid fa-circle-xmark"
                className="icon--warning file-upload-remove rejected-text font-size-lg fa-circle-xmark white-bg"
                data-toggle={
                  record.folder !== 'ifu_upload' && record.folder !== 'ddm_upload' && record.folder !== 'bmf_upload' && record.location !== 'smilesimulation'
                    ? 'tooltip'
                    : ''
                }
              />
            </a>
          </div>
        </BootstrapTooltip>
      ) : null}
    </div>
  );
}

RecordThumbnail.propTypes = {
  record: PropTypes.object.isRequired,
  onLoad: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RecordThumbnail;
