/**
 * File:ir_submission.js - Component to submit item request on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ir_submission.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
// Internal Components
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import Quantity from './quantity';
import Reason from './reason';
import Review from './review';
import SubmissionConfirmation from '../case_submission/submission_confirmation';
import SubmitterLoader from '../case_submission/submitting_loader';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import {
  setTokenHeader,
  textFieldLimited,
  checkToken,
  getActiveAddress,
  getDisabledListOfTeethByIDBTray,
  clearIdbRequiredReasonForArchWhenNoQuantity,
  isActiveAddressById,
  clearIdbRequiredReason,
  removeEmoji,
  clearIdbRequiredReasonForArchByQuantity,
  clearSingleToothSelection,
} from '../../common/functions';
import IrReason from '../../common/ir_reason.json';
import IrReasonGen2 from '../../common/ir_reason_gen2.json';
import CircleLoader from '../../components/loader/circle_loader';
import CardContainer from '../components/container/card_container';
import Stepper from '../components/stepper';
import Button from '../components/buttons/button';
import ErrorMessage from '../components/container/error_message';
import { scrollToTop } from '../../common/scroll';
import { openStorefrontLink } from '../../common/shopify';
import { assignIdbPartNumbers } from '../../common/ir_helpers';

const ARCH_UPPER = 'upper';
const ARCH_LOWER = 'lower';
const REASON_CATEGORY_IDB_REQUIRED = 'idb_required';
const KEYWORD_IDB = 'idb';
const KEYWORD_UPPER = 'u';
const KEYWORD_LOWER = 'l';

/**
 * Used to allow a user to submit an item request
 * @component
 * @alias DoctorIrSubmission
 * @category IPP
 */
class IrSubmission extends Component {
  constructor(props) {
    super(props);
    const ir_reason_data = _.cloneDeep(IrReason);
    this.state = {
      case_id: '',
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      item_request_details: [],
      addresses: [],
      address: [],
      patient_first_name: '',
      patient_last_name: '',
      step: 'item_parts',
      parts_quantity: [],
      item_parts: [],
      original_item_parts_quantity: [],
      item_parts_quantity: [],
      buttons: (
        <Button data-position={'next'} data-description={'Parts'} onClick={this.onButtonClick}>
          Next
        </Button>
      ),
      warning: false,
      brackets_has_quantity: false,
      smartwire_has_quantity: false,
      idb_has_quantity: false,
      error: false,
      error_type: '',
      shipping_address_id: 0,
      request_reasons: ir_reason_data,
      gen_2: false,
      shopify_id: '',
      doctor_email: '',
      doctor_role: '',
      account_link_id: '',
    };

    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.totalPartQuantity = this.totalPartQuantity.bind(this);
    this.hasReasons = this.hasReasons.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
    this.hasReasonsMatchingQuantities = this.hasReasonsMatchingQuantities.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    let case_id =
      this.props.history && this.props.history && this.props.history.location.search ? this.props.history.location.search.replace('?caseid=', '') : null;
    Axios.get(`/apiV2/itemrequest/${case_id}`)
      .then((res) => {
        let counter = 0;
        let item_parts_quantity = that.state.item_parts_quantity;
        res.data.item_parts.forEach((item_part) => {
          item_parts_quantity.push({
            id: counter,
            part_id: item_part.part_id,
            quantity: 0,
            current_ind: item_part.current_ind,
            description: item_part.description,
            max_qty: item_part.max_qty,
            part_category: item_part.part_category,
            seq_no: item_part.seq_no,
            is_de: item_part.is_de,
          });
          counter++;
        });

        let active_addresses = [res.data.cases[0].shipping_address];

        if (!isActiveAddressById(res.data.shipping_addresses, res.data.cases[0].shipping_address.id)) {
          active_addresses = getActiveAddress(res.data.shipping_addresses);
        }

        that.setState({
          case_id: case_id,
          loading: false,
          item_request_details: res.data,
          addresses: res.data.shipping_addresses,
          patient_first_name: res.data.patient.first_name,
          patient_last_name: res.data.patient.last_name,
          item_parts: res.data.item_parts,
          item_parts_quantity: item_parts_quantity,
          address: active_addresses[0],
          shipping_address_id: active_addresses[0].id,
          gen_2: res.data.cases[0].gen_2,
          request_reasons: res.data.cases[0].gen_2 ? _.cloneDeep(IrReasonGen2) : that.state.request_reasons,
          doctor_id: res.data.doctor.id,
          doctor_first_name: res.data.doctor.first_name,
          doctor_last_name: res.data.doctor.last_name,
          shopify_id: res.data.doctor.shopify_id,
          doctor_email: res.data.doctor.email,
          account_link_id: res.data.doctor.account_link_id,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    //Check if user is still logged in
    const isAllowed = checkToken();
    if (!isAllowed) {
      this.props.history.push('/');
    }

    if (this.state.step === 'item_parts') {
      const hasParts = this.state.item_parts_quantity.length > 0;
      if (hasParts && !this.state.buttons) {
        this.setState({
          buttons: (
            <Button data-position={'next'} data-description={'Parts'} onClick={this.onButtonClick}>
              Next
            </Button>
          ),
        });
      } else if (!hasParts && this.state.buttons) {
        this.setState({
          buttons: '',
        });
      }
    }
  }

  redirectUserToCaseDetail() {
    const case_id = this.state.case_id;
    let path = this.props.history.location.pathname;
    let new_path = path.replace(/itemrequest/, `item/${case_id}`);
    this.props.history.push(new_path, { selectedTab: 'item' });
  }

  /**
   * Button click event for item request submission on ipp.
   *
   * - Submits the item request to database if all the validation is met.
   * - Used during item request submission or item request update
   * @function onSubmitButtonClick
   * @param {event} event - Button click event.
   */
  onSubmitButtonClick = (event) => {
    const item_request_reasons = this.state.gen_2 ? this.state.request_reasons : clearIdbRequiredReason(this.state.request_reasons);
    if (this.hasReasons() && this.totalPartQuantity()) {
      this.setState({
        request_reasons: item_request_reasons,
      });
      let item_part_quantity = this.state.item_parts_quantity; //Fix: Const vs let in this function
      let request_reasons = item_request_reasons;
      let case_id = this.state.case_id;
      const address_id = this.state.shipping_address_id;
      let data = {
        case_id,
        item_part_quantity,
        request_reasons,
        address_id,
      };

      let that = this;
      this.setState({
        step: 'in_progress',
        hide: true,
        buttons: <div />,
      });

      Axios.post(`/apiV2/itemrequest/${case_id}`, data)
        .then(function (res) {
          that.setState({
            step: 'thank_you',
          });

          Axios.post(`/api/email/?slug=new-ir-submission-1&ireqId=${res.data.data.item_request_id}&method=standard&provider=${window.location.origin}`);
        })
        .catch(function (err) {
          that.setState({
            error: true,
          });
        });
      this.props.history.push({ state: { refreshInfo: 'true' } });
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'item_parts' }, { name: 'item_reason' }, { name: 'item_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  hasReasons = () => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '<ul class="wizard-error-text">';
    let has_smartwire_reason = this.hasSmartWireReason();
    let has_idb_reason = this.hasIdbReason() && this.hasIdbRequiredReason();
    let has_bracket_reason = this.hasBracketReason();

    if (!has_smartwire_reason) warning_text += '<li>Smartwire</li>';
    if (!has_idb_reason) warning_text += '<li>IDB Tray</li>';
    if (!has_bracket_reason) warning_text += '<li>Brackets</li>';

    if (!has_smartwire_reason || !has_idb_reason || !has_bracket_reason) {
      warning_text += '</ul>';
      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        warning: true,
        error_type: 'Reason for Request',
      });
    }

    return has_smartwire_reason && has_idb_reason && has_bracket_reason;
  };

  hasBracketReason() {
    return !this.state.brackets_has_quantity || this.state.request_reasons.some((reason) => reason.booleanValue && reason.category === 'brackets');
  }

  hasSmartWireReason() {
    return !this.state.smartwire_has_quantity || this.state.request_reasons.some((reason) => reason.booleanValue && reason.category === 'smartwire');
  }

  hasIdbReason() {
    return !this.state.idb_has_quantity || this.state.request_reasons.some((reason) => reason.booleanValue && reason.category === 'idb');
  }

  hasIdbRequiredReason() {
    const hasRequiredReason =
      !this.state.idb_has_quantity ||
      this.state.request_reasons.some((reason) => {
        if (reason.category === 'idb_required') {
          return this.checkIdbSelection(reason);
        }
        return false;
      });
    return hasRequiredReason;
  }

  /**
   * Checks if any part has quantity
   * @function
   * @return {Boolean} Whether has quantity or not
   */
  hasQuantity() {
    const quantity = this.state.item_parts_quantity.reduce((accu, curr) => accu + curr.quantity, 0);
    return quantity > 0;
  }

  /**
   * Shows error messages if no part has quantity
   * @function
   * @return {Boolean} Whether has quantity or not
   */
  totalPartQuantity = () => {
    const { brackets_has_quantity, smartwire_has_quantity, idb_has_quantity } = this.state;
    if (!brackets_has_quantity && !smartwire_has_quantity && !idb_has_quantity) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');
      warning.innerHTML = '<ul class="wizard-error-text"><li>Select Item Quantity</li></ul>';

      this.setState({
        warning: true,
        error_type: 'Request Items',
      });

      return false;
    }
    return true;
  };

  /**
   * Checks if there are reasons matching quantities for upper or lower arch for IDB request.
   * E.g.: If the user selects Upper Initial IDB Tray and Lower Initial IDB Tray, then there should be a reason for each.
   * @returns {boolean} Returns true if there are matching reasons and quantities; otherwise, false.
   */
  hasReasonsMatchingQuantities = () => {
    const { item_parts_quantity, request_reasons } = this.state;

    const hasQuantityForArch = (arch) =>
      item_parts_quantity.some((part) => part.description.toLowerCase().includes(arch) && part.part_category === KEYWORD_IDB && part.quantity > 0);
    const hasReasonForArch = (arch, keyword) =>
      request_reasons.some((reason) => {
        if (reason.booleanValue && reason.category === REASON_CATEGORY_IDB_REQUIRED) {
          let has_single_tooth_selection = false;
          let has_arch_selection = false;

          if (this.state.gen_2 || reason.segment_preference === 'individual_teeth_segments') {
            has_single_tooth_selection = _.isArray(reason.selectedTeeth) && reason.selectedTeeth.some((tooth) => tooth.toLowerCase().startsWith(keyword));
          }
          if (this.state.gen_2 || reason.segment_preference === 'arch_segments') {
            has_arch_selection = reason.arch_segments_options.some((option) => option.active && option.title.toLowerCase().startsWith(arch));
          }

          return has_single_tooth_selection || has_arch_selection;
        }
        return false;
      });

    const displayWarning = () => {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        const warningText = '<ul class="wizard-error-text"><li>IDB Tray</li></ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warningText;

        this.setState({
          warning: true,
          error_type: 'Reason for Request',
        });
      }
    };

    try {
      const hasUpperQuantity = hasQuantityForArch(ARCH_UPPER);
      const hasLowerQuantity = hasQuantityForArch(ARCH_LOWER);
      const hasUpperReason = hasReasonForArch(ARCH_UPPER, KEYWORD_UPPER);
      const hasLowerReason = hasReasonForArch(ARCH_LOWER, KEYWORD_LOWER);

      if ((hasUpperQuantity && !hasUpperReason) || (hasLowerQuantity && !hasLowerReason)) {
        displayWarning();
        return false;
      }
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  onButtonClick = (event) => {
    let item_request_reasons = this.props.gen_2
      ? this.state.request_reasons
      : clearIdbRequiredReasonForArchByQuantity(this.state.item_parts_quantity, this.state.request_reasons);

    event.preventDefault();
    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    if (this.state.step === 'item_parts') {
      if (!this.totalPartQuantity()) {
        return;
      } else {
        this.hide_warning();
      }
      this.setState(
        {
          step: 'item_reason',
          original_item_parts_quantity: _.cloneDeep(this.state.item_parts_quantity),
          buttons: (
            <>
              <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                Back
              </Button>
              <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                Next
              </Button>
            </>
          ),
          request_reasons: item_request_reasons,
        },
        scrollToTop
      );
    } else if (this.state.step === 'item_reason') {
      let part_list = this.state.item_parts_quantity;
      if (event.currentTarget.dataset.position === 'next') {
        if (!this.hasReasons() || !this.hasReasonsMatchingQuantities()) {
          return;
        } else {
          this.hide_warning();
        }
        if (this.state.idb_has_quantity && this.state.gen_2) {
          part_list = assignIdbPartNumbers(this.state.request_reasons, _.cloneDeep(this.state.item_parts_quantity), this.state.case_id);
        }

        let item_request_reasons = clearSingleToothSelection(this.state.request_reasons);

        this.setState(
          {
            step: 'item_review',
            item_parts_quantity: part_list,
            buttons: (
              <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                Back
              </Button>
            ),
            request_reasons: item_request_reasons,
          },
          scrollToTop
        );
      } else {
        this.hide_warning();
        this.setState(
          {
            step: 'item_parts',
            buttons: (
              <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                Next
              </Button>
            ),
          },
          scrollToTop
        );
      }
    } else if (this.state.step === 'item_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        const item_parts_list = this.state.original_item_parts_quantity.length > 0 ? this.state.original_item_parts_quantity : this.state.item_parts_quantity;
        this.hide_warning();
        this.setState(
          {
            step: 'item_reason',
            item_parts_quantity: item_parts_list,
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          },
          scrollToTop
        );
      }
    }
  };

  setButtons(action) {
    if (action === 'item_parts') {
      this.setState({
        buttons: (
          <Button data-position={'next'} onClick={this.onButtonClick}>
            Next
          </Button>
        ),
      });
    } else if (action === 'item_reason') {
      this.setState({
        buttons: (
          <>
            <Button theme="secondary" data-position={'prev'} onClick={this.onButtonClick}>
              Back
            </Button>
            <Button data-position={'next'} onClick={this.onButtonClick}>
              Next
            </Button>
          </>
        ),
      });
    } else if (action === 'item_review') {
      this.setState({
        buttons: (
          <Button theme="secondary" data-position={'prev'} onClick={this.onButtonClick}>
            Back
          </Button>
        ),
      });
    }
  }

  hide_warning = () => {
    this.setState({
      warning: false,
    });
  };

  onRemoveDraft = () => {
    this.props.history.goBack();
  };

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  reviewInputChange = (event) => {
    this.setState({
      shipping_address_id: parseInt(event.target.dataset.id),
    });
  };

  decreaseQuantity = (event) => {
    let item_parts_quantity = this.state.item_parts_quantity;
    if (item_parts_quantity[parseInt(event.target.dataset.id)].quantity > 0) {
      item_parts_quantity[parseInt(event.target.dataset.id)].quantity = item_parts_quantity[parseInt(event.target.dataset.id)].quantity - 1;

      this.setState({
        item_parts_quantity: item_parts_quantity,
        ...this.getItemQuantityState(item_parts_quantity),
        request_reasons: clearIdbRequiredReasonForArchWhenNoQuantity(item_parts_quantity, this.state.request_reasons),
      });
    }
  };

  increaseQuantity = (event) => {
    this.hide_warning();
    let item_parts_quantity = this.state.item_parts_quantity;
    if (item_parts_quantity[parseInt(event.target.dataset.id)].quantity < item_parts_quantity[parseInt(event.target.dataset.id)].max_qty) {
      item_parts_quantity[parseInt(event.target.dataset.id)].quantity = item_parts_quantity[parseInt(event.target.dataset.id)].quantity + 1;
      this.setState({
        item_parts_quantity: item_parts_quantity,
        ...this.getItemQuantityState(item_parts_quantity),
      });
    }
  };

  /**
   * Retrieves the state of each category
   * @function
   * @param {Array} item_parts - List of item parts quantity
   * @return {Object} The state of each category
   */
  getItemQuantityState(item_parts) {
    let brackets_has_quantity = false;
    let idb_has_quantity = false;
    let smartwire_has_quantity = false;
    item_parts.forEach((part) => {
      if (part.part_category === 'bracket') {
        if (part.quantity > 0) brackets_has_quantity = true;
      } else if (part.part_category === 'smartwire') {
        if (part.quantity > 0) smartwire_has_quantity = true;
      } else if (part.part_category === 'idb') {
        if (part.quantity > 0) idb_has_quantity = true;
      }
    });
    return { brackets_has_quantity, idb_has_quantity, smartwire_has_quantity };
  }

  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onReasonTextFocus = (event) => {
    let id = parseInt(event.target.dataset.id);
    let reason_text = event.target.value.trim();
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].value = textFieldLimited(event.target.value.trim());
    if (reason_text) {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = true;
    } else {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = false;
    }

    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  /**
   * Checks if Item Request has a Single Tooth Selection
   * @param {json} request_reason
   * @returns {boolean} Single Tooth Selection Status
   */
  checkSingleToothSelection = (request_reason) => {
    if (request_reason.selectedTeeth && request_reason.selectedTeeth.length) {
      return true;
    }
    return false;
  };

  /**
   * Checks if Item Request has an Arch Selection
   * @param {json} request_reason
   * @returns {boolean} Arch Selection Status
   */
  checkArchSelection = (request_reason) => {
    return request_reason?.arch_segments_options?.some((segment) => segment.active);
  };

  /**
   * Checks if Item Request has an Idb Selection
   * @param {json} request_reason
   * @returns {boolean} IDB Selection Status
   */
  checkIdbSelection = (request_reason) => {
    return this.checkArchSelection(request_reason) || this.checkSingleToothSelection(request_reason);
  };

  /**
   * Handles Single Tooth Selection Click
   */
  onhandleTeethClick(request_reason) {
    const idb_reason_id = 799;
    let id = parseInt(request_reason.id);
    let selected_teeth = request_reason.selectedTeeth;
    let request_reasons = _.cloneDeep(this.state.request_reasons);
    const new_reason = request_reasons.find((reason) => reason.id === id);
    new_reason.selectedTeeth = selected_teeth;
    if (id === idb_reason_id) {
      new_reason.single_tooth_selection = this.checkSingleToothSelection(request_reason);
    }
    new_reason.booleanValue = this.checkIdbSelection(request_reason);
    this.setState({
      warning: false,
      request_reasons: request_reasons,
    });
  }

  setSegmentPreference(request_reason) {
    let id = parseInt(request_reason.id);
    let segment_preference = request_reason.segment_preference;
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].segment_preference = segment_preference;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].segment_preference_text = request_reason.segment_preference_text;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].label = request_reason.segment_preference_text;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].booleanValue = true;
    if (!this.state.gen_2) {
      if (segment_preference === 'individual_teeth_segments') {
        request_reasons.filter(function (reason) {
          return reason.id === id;
        })[0].arch_segment_selection = false;
        request_reasons.filter(function (reason) {
          return reason.id === id;
        })[0].single_tooth_selection = true;
      } else if (segment_preference === 'arch_segments') {
        request_reasons.filter(function (reason) {
          return reason.id === id;
        })[0].single_tooth_selection = false;
        request_reasons.filter(function (reason) {
          return reason.id === id;
        })[0].arch_segment_selection = true;
      }
    }
    this.setState({
      warning: false,
      request_reasons: request_reasons,
    });
  }

  /**
   * Button click event for selection of archsegment option under idb required reason on ipp.
   *
   * Allows you to select different arch segments that the idb tray is needed for
   * @function setArchSegmentOption
   * @param {json} request_reason - Specific request reason that is being changed from the reason json object.
   */
  setArchSegmentOption(request_reason) {
    let id = parseInt(request_reason.id);
    let arch_segments_options = request_reason.arch_segments_options;
    let request_reasons = _.cloneDeep(this.state.request_reasons);
    const new_reason = request_reasons.find((reason) => reason.id === id);
    new_reason.arch_segments_options = arch_segments_options;
    new_reason.arch_segment_selection = this.checkArchSelection(request_reason);
    new_reason.booleanValue = this.checkIdbSelection(request_reason);
    this.setState({
      request_reasons: request_reasons,
    });
  }

  onReasonTextChange = (event) => {
    let id = parseInt(event.target.dataset.id);
    let reason_text = removeEmoji(event.target.value);
    let request_reasons = this.state.request_reasons;
    request_reasons.filter(function (reason) {
      return reason.id === id;
    })[0].value = removeEmoji(textFieldLimited(event.target.value));
    if (reason_text) {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = true;
    } else {
      request_reasons.filter(function (reason) {
        return reason.id === id;
      })[0].booleanValue = false;
    }

    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  getStepsCompleted() {
    const item_parts_completed = this.hasQuantity();
    const item_reason_completed =
      item_parts_completed && this.hasBracketReason() && this.hasIdbReason() && this.hasIdbRequiredReason() && this.hasSmartWireReason();
    return {
      item_parts: item_parts_completed,
      item_reason: item_reason_completed,
      item_review: item_parts_completed && item_reason_completed,
    };
  }

  getStepperContent() {
    const stepsCompleted = this.getStepsCompleted();
    return [
      {
        description: 'item_parts',
        label: 'Request Items',
        active: this.state.step === 'item_parts',
        completed: stepsCompleted.item_parts,
      },
      {
        description: 'item_reason',
        label: 'Reason for Request',
        active: this.state.step === 'item_reason',
        completed: stepsCompleted.item_reason,
        disabled: !stepsCompleted.item_parts,
      },
      {
        description: 'item_review',
        label: 'Submit',
        active: this.state.step === 'item_review',
        completed: stepsCompleted.item_review,
        disabled: !stepsCompleted.item_parts || !stepsCompleted.item_reason,
      },
    ];
  }

  getStepIndex(step = this.state.step) {
    switch (step) {
      case 'item_parts':
        return 0;
      case 'item_reason':
        return 1;
      case 'item_review':
        return 2;
      default:
        return 0;
    }
  }

  onStepperClick = (event) => {
    const clickedStep = event.target.dataset.description;
    const isBackStep = this.getStepIndex(clickedStep) <= this.getStepIndex();
    const nextStep = this.getNextStepperNameByStepperName(this.state.step);
    const stepsCompleted = this.getStepsCompleted();
    if (isBackStep || stepsCompleted[clickedStep]) {
      this.setState({ step: clickedStep });
      this.setButtons(clickedStep);
      this.hide_warning();
    } else if (stepsCompleted[this.state.step]) {
      this.setState({ step: nextStep });
      this.setButtons(nextStep);
    }
  };

  /**
   * Opens InBrace Storefront link
   *
   * @function
   */
  openStorefrontLink = () => {
    const doctor = {
      doctor_id: this.state.doctor_id,
      shopify_id: this.state.shopify_id,
      email: this.state.doctor_email,
      first_name: this.state.doctor_first_name,
      last_name: this.state.doctor_last_name,
      account_link_id: this.state.account_link_id,
      role: this.state.doctor_role,
    };
    openStorefrontLink(doctor);
  };

  render() {
    if (this.state.loading) {
      return <CircleLoader fullscreen />;
    } else if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else {
      return (
        <div>
          <Helmet>
            <title>Item Request Submission | InBrace Smile Design Studio™</title>
          </Helmet>

          <h1 className="content__header fs-exclude">
            Item Request - {this.state.patient_first_name} {this.state.patient_last_name}
          </h1>

          {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
            <CardContainer>
              <Stepper activeStepper={this.getStepIndex()} stepperContent={this.getStepperContent()} onStepperClick={this.onStepperClick} />
            </CardContainer>
          ) : null}

          <CardContainer className="case-card-container pad-lg">
            {this.state.step === 'item_parts' ? (
              <Quantity
                item_parts={this.state.item_parts}
                item_parts_quantity={this.state.item_parts_quantity}
                parts_quantity={this.state.parts_quantity}
                decreaseQuantity={this.decreaseQuantity}
                increaseQuantity={this.increaseQuantity}
                goBack={this.onRemoveDraft}
                case_id={this.state.case_id}
                gen_2={this.state.gen_2}
                openStorefrontLink={this.openStorefrontLink}
              />
            ) : null}
            {this.state.step === 'item_reason' ? (
              <Reason
                item_parts={this.state.item_parts}
                request_reasons={this.state.request_reasons}
                brackets_has_quantity={this.state.brackets_has_quantity}
                smartwire_has_quantity={this.state.smartwire_has_quantity}
                case_id={this.state.case_id}
                gen_2={this.state.gen_2}
                smartwire_other_comments={
                  this.state.request_reasons.filter(function (reason) {
                    return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'smartwire';
                  })[0].value
                }
                brackets_other_comments={
                  !this.state.gen_2
                    ? this.state.request_reasons.filter(function (reason) {
                        return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'brackets';
                      })[0].value
                    : ''
                }
                idb_other_comments={
                  this.state.request_reasons.filter(function (reason) {
                    return (
                      (reason.label === 'Segmented bonding jig needed:' ||
                        reason.label === 'Other reason or special requirements:' ||
                        reason.label === 'Other reason or additional details:') &&
                      reason.category === 'idb'
                    );
                  })[0].value
                }
                smartwire_other_comments_require={
                  this.state.request_reasons.filter(function (reason) {
                    return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'smartwire';
                  })[0].booleanValue
                }
                brackets_other_comments_require={
                  !this.state.gen_2
                    ? this.state.request_reasons.filter(function (reason) {
                        return (reason.label === 'Other reason:' || reason.label === 'Other reason or additional details:') && reason.category === 'brackets';
                      })[0].booleanValue
                    : false
                }
                idb_other_comments_require={
                  this.state.request_reasons.filter(function (reason) {
                    return (
                      (reason.label === 'Segmented bonding jig needed:' ||
                        reason.label === 'Other reason or special requirements:' ||
                        reason.label === 'Other reason or additional details:') &&
                      reason.category === 'idb'
                    );
                  })[0].booleanValue
                }
                idb_has_quantity={this.state.idb_has_quantity}
                item_parts_quantity={this.state.item_parts_quantity}
                parts_quantity={this.state.parts_quantity}
                onCheckboxClicked={this.onCheckboxClicked}
                onTextChange={this.onReasonTextChange}
                onFocus={this.onReasonTextFocus}
                error={this.state.warning}
                onhandleTeethClick={(teethSet) => {
                  this.hide_warning();
                  this.onhandleTeethClick(teethSet);
                }}
                setSegmentPreference={(request_reason) => this.setSegmentPreference(request_reason)}
                setArchSegmentOption={(request_reason) => {
                  this.hide_warning();
                  this.setArchSegmentOption(request_reason);
                }}
                smartwire_breakage_comments={
                  this.state.gen_2
                    ? this.state.request_reasons.filter(function (reason) {
                        return reason.label === 'Wire breakage' && reason.category === 'smartwire';
                      })[0].value
                    : ''
                }
                disabled={getDisabledListOfTeethByIDBTray(this.state.item_parts_quantity)}
              />
            ) : null}
            {this.state.step === 'thank_you' ? (
              <SubmissionConfirmation placeholder_text="item request" onButtonClick={this.redirectUserToCaseDetail} refreshInfo />
            ) : null}
            {this.state.step === 'in_progress' ? <SubmitterLoader /> : null}
            {this.state.step === 'item_review' ? (
              <>
                <Review
                  item_parts={this.state.item_parts}
                  addresses={this.state.addresses}
                  address={this.state.address}
                  address_id={this.state.shipping_address_id > 0 ? this.state.shipping_address_id : this.state.address.id}
                  item_parts_quantity={this.state.item_parts_quantity}
                  parts_quantity={this.state.parts_quantity}
                  brackets_has_quantity={this.state.brackets_has_quantity}
                  smartwire_has_quantity={this.state.smartwire_has_quantity}
                  smartwire_other_comments={this.state.request_reasons[4].value}
                  brackets_other_comments={this.state.request_reasons[7].value}
                  idb_other_comments={this.state.request_reasons[10].value}
                  idb_has_quantity={this.state.idb_has_quantity}
                  request_reasons={this.state.request_reasons}
                  onEditClick={this.onEditClick}
                  onInputChange={this.reviewInputChange}
                  case_id={this.state.case_id}
                  gen_2={this.state.gen_2}
                />
                <Button type="button" onClick={this.onSubmitButtonClick} data-case_id={this.state.case_id}>
                  Submit
                </Button>
              </>
            ) : null}
          </CardContainer>
          {!this.state.hide && (
            <div className="case-form-controls">
              {this.state.buttons}
              {this.state.item_parts_quantity.length > 0 && this.state.step !== 'thank_you' && (
                <Button theme="revise" onClick={this.onModalShow} data-redirect="true">
                  Cancel
                </Button>
              )}
            </div>
          )}

          <ErrorMessage
            className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
            title={this.state.error_type}
            onClose={this.removeWizardErrorMsg}
          >
            <div id="warning-submit" />
          </ErrorMessage>

          {this.state.showCancelCase === true ? (
            <Modal
              preset="decision"
              header_text="Cancel"
              modal_class="modal-content-warning"
              message_text={<span>Are you sure you want to cancel this item request? You will not be able to undo this action if you proceed.</span>}
              close_btn_text="Do Not Cancel"
              confirm_btn_text="Cancel Request"
              onCloseButtonClick={this.onModalDismiss}
              onConfirmButtonClick={this.onRemoveDraft}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default withRouter(IrSubmission);
