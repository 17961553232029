import Axios from 'axios';

// Action Types
export const FETCH_SMILE_SIMULATION_PENDING = 'FETCH_SMILE_SIMULATION_PENDING';
export const FETCH_SMILE_SIMULATION_SUCCESS = 'FETCH_SMILE_SIMULATION_SUCCESS';
export const FETCH_SMILE_SIMULATION_ERROR = 'FETCH_SMILE_SIMULATION_ERROR';

// Action Creators
// -----------------------------------------------------------------------------
// FETCH

export function fetchSmileList(doctor_id = '') {
  return (dispatch, getState) => {
    dispatch(fetchSmilesSimulationPending());
    Axios.get(`/apiv3/smilesimulation?doctor_id=${doctor_id}`)
      .then(function (res) {
        const smiles = res.data.smiles;
        const status_count = res.data.status_count;
        dispatch(fetchSmilesSimulationSuccess(smiles, status_count));
        return res;
      })
      .catch(function (err) {
        dispatch(fetchSmilesSimulationError(err));
      });
  };
}

export function fetchSmilesSimulationPending() {
  return {
    type: FETCH_SMILE_SIMULATION_PENDING,
  };
}

export function fetchSmilesSimulationSuccess(smiles, status_count) {
  return {
    type: FETCH_SMILE_SIMULATION_SUCCESS,
    smiles: smiles,
    smiles_count: smiles.length,
    status_count: status_count,
  };
}

export function fetchSmilesSimulationError(error) {
  return {
    type: FETCH_SMILE_SIMULATION_ERROR,
    error: error,
  };
}

export function clearSmilesSimulationList() {
  return (dispatch, getState) => {
    const { status_count } = getState().smilesReducer;
    let empty_count = {};
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(status_count)) {
      empty_count[key] = 0;
    }

    dispatch(fetchSmilesSimulationSuccess([], empty_count));
  };
}
