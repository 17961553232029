// External Libs
import React, { Component } from 'react';

/**
 * SortHeader is a common header component for sortable view headers
 */
class SortHeader extends Component {
  addSortClass = () => {
    if (this.props.sort_method === this.props.category) {
      return this.props.ascending ? 'arrow asc' : 'arrow dsc';
    }
    return '';
  };
  render = () => {
    return (
      <span>
        {this.props.title ? <span dangerouslySetInnerHTML={{ __html: this.props.title }} /> : ''}
        <span className={this.addSortClass()} />
      </span>
    );
  };
}

export default SortHeader;
