export const loopOptions = [
    {
        value: 'U',
        label: 'U',
        disabled: false
    },
    {
        value: 'W',
        label: 'W',
        disabled: false
    },
];

export const wireLocketOptions = [
    {
        value: 'STD',
        label: 'Standard',
        disabled: false
    },
    {
        value: 'LSH',
        label: 'Leg - Shoulder',
        disabled: false
    },
    {
        value: 'SHL',
        label: 'Shoulder - Leg',
        disabled: false
    },
    // {
    //     value: 'ignore',
    //     label: 'Ignore'
    // }
];

export const archOptions = [
    { value: 'upper', label: 'Upper' },
    { value: 'lower', label: 'Lower' }
];

export const programOptions = [
    { value: 'override', label: 'Override by operator' },
    { value: 'preloaded', label: 'Preloaded based on Dr enrollment' }
];

export const wireOptions = [
    //{ value: 'sw0', label: 'SW0' },
    { value: 'initial', label: 'SW1' },
    { value: 'intermediate', label: 'SW2' },
    //{ value: 'final', label: 'SW3' }
];