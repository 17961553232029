import _ from 'lodash';

const defaultTeethReference = {
  UR: ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'],
  UL: ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'],
  LR: ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'],
  LL: ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'],
};

defaultTeethReference.All = []
  .concat(defaultTeethReference.UR)
  .concat(defaultTeethReference.UL)
  .concat(defaultTeethReference.LR)
  .concat(defaultTeethReference.LL);

/**
 * Checks if bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
 * @param {object} data object with archToTreat, missingTeeth, extractTeeth, and archToTreat
 * @param {object} teethReference object with teeth reference
 * @returns {boolean} - True or false
 */
const isBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat }, teethReference) => {
  if (!teethReference) {
    teethReference = defaultTeethReference;
  }

  const opposingTeeth = [];
  const hasUpper = archToTreat !== 'lower';
  const hasLower = archToTreat !== 'upper';

  if (!hasUpper) {
    opposingTeeth.push(...teethReference.UR);
    opposingTeeth.push(...teethReference.UL);
  }

  if (!hasLower) {
    opposingTeeth.push(...teethReference.LR);
    opposingTeeth.push(...teethReference.LL);
  }

  const bracketDisabled = _.union(missingTeeth, extractTeeth, opposingTeeth);

  const bracketTeethDifference = _.difference(bracketTeeth, bracketDisabled);

  if (archToTreat === 'both') {
    const hasTeethInBothArches =
      _.some(bracketTeethDifference, (tooth) => tooth.startsWith('U')) && _.some(bracketTeethDifference, (tooth) => tooth.startsWith('L'));

    if (!hasTeethInBothArches) {
      return false;
    }
  }

  return bracketTeethDifference.length > 0;
};

/**
 * Check if setupInstruction is valid based on selected setup
 * @param {object} data object with setupInstruction and setup
 * @param {string} data.setupInstruction - Setup Instruction
 * @param {string} data.setup - Setup
 * @returns {boolean} - True or false
 */
const isSetupInstructionValid = ({ setupInstruction, setup }) => {
  if (setup === 'adjust_treatment_improve_detailing') {
    return setupInstruction ? setupInstruction.trim() !== '' : false;
  }

  return true;
};

export { isBracketTeethValid, defaultTeethReference, isSetupInstructionValid };
