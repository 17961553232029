// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
/**
 * Displays label generation override step
 * @component
 * @alias LabelOverride
 * @category BPP
 */
class LabelOverride extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro">
          {!this.props.returnToSelection ? (
            <div className="process-box-title">Labels</div>
          ) : (
            <div className="process-box-title">Label Generation Overridden</div>
          )}
          <div>Label generation has been overridden...</div>
          {this.props.returnToSelection ? (
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
                  <span>
                    {/* eslint-disable-next-line */}
                    <a className="process-return" onClick={this.props.restartLabelGeneration}>
                      <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to label selection
                    </a>
                  </span>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default LabelOverride;
