export const RESET_VIEWER_OPTIONS_VIEW = 'RESET_VIEWER_OPTIONS_VIEW';
export const CHANGE_STEP_SINGLE_PANE = 'CHANGE_STEP_SINGLE_PANE';
export const CHANGE_STEP_BOTH_PANE = 'CHANGE_STEP_BOTH_PANE';
export const CHANGE_VIEW_SINGLE_PANE = 'CHANGE_VIEW_SINGLE_PANE';
export const CHANGE_VIEW_BOTH_PANE = 'CHANGE_VIEW_BOTH_PANE';
export const CHANGE_JAW_SINGLE_PANE = 'CHANGE_JAW_SINGLE_PANE';
export const CHANGE_JAW_BOTH_PANE = 'CHANGE_JAW_BOTH_PANE';
export const TOGGLE_OPTION_SINGLE_PANE = 'TOGGLE_OPTION_SINGLE_PANE';
export const TOGGLE_OPTION_BOTH_PANE = 'TOGGLE_OPTION_BOTH_PANE';

export function onChangeStep(newStep, pane) {
  const type = isBothPane(pane) ? CHANGE_STEP_BOTH_PANE : CHANGE_STEP_SINGLE_PANE;
  return {
    type,
    newStep,
    pane,
  };
}

export function onChangeView(newView, pane) {
  const type = isBothPane(pane) ? CHANGE_VIEW_BOTH_PANE : CHANGE_VIEW_SINGLE_PANE;
  return {
    type,
    newView,
    pane,
  };
}

export function onChangeJaw(newJaw, pane) {
  const type = isBothPane(pane) ? CHANGE_JAW_BOTH_PANE : CHANGE_JAW_SINGLE_PANE;
  return {
    type,
    newJaw,
    pane,
  };
}

export function onToggleOption(option, pane) {
  const type = isBothPane(pane) ? TOGGLE_OPTION_BOTH_PANE : TOGGLE_OPTION_SINGLE_PANE;
  return {
    type,
    option,
    pane,
  };
}

export function resetView(pane) {
  return {
    type: RESET_VIEWER_OPTIONS_VIEW,
    pane: pane,
  };
}

function isBothPane(pane) {
  return pane !== 1 && pane !== 2;
}
