import React from 'react';

import PatientInfoHeading from '../setup_viewer/components/panels/menu/patient_info/patient_info_heading';
import PatientInfoHeadingVertical from '../setup_viewer/components/panels/menu/patient_info/patient_info_heading_vertical';

/**
 * The container the patient info on the sidebar
 * @component
 * @alias SetupViewerPatientInfo
 */
function SmilePatientInfo(props) {
  const { assigned_doctor_id, assigned_doctor__first_name, assigned_doctor__last_name, doctor_id, doctor__user__first_name } = { ...props.smile_details };
  const doctor_name =
    assigned_doctor_id === doctor_id
      ? `${assigned_doctor__first_name} ${assigned_doctor__last_name}`
      : assigned_doctor_id
      ? `${assigned_doctor__first_name} ${assigned_doctor__last_name} - ${doctor__user__first_name}`
      : `Unassigned - ${doctor__user__first_name}`;

  const patient_name = props.smile_details.smile_first_name + ' ' + props.smile_details.smile_last_name;
  const smile_id = props.smile_details.smile_id;

  return (
    <>
      <div className={props.collapse ? '' : 'patient-info-bar-top'} />
      <div className="patient-info">
        <PatientInfoHeading title="Doctor" content={doctor_name} collapse={props.collapse} extend={'true'} />
        <PatientInfoHeading title="Name" content={patient_name} collapse={props.collapse} extend={'true'} />
        <PatientInfoHeading title="ID" content={smile_id} collapse={props.collapse} extend={'true'} />
        <div className="patient-info-bar" />
        <PatientInfoHeadingVertical content={patient_name} collapse={props.collapse} />
      </div>
    </>
  );
}

export default SmilePatientInfo;
