import Axios from 'axios';

import { sendFeedbackRatingEmail } from '../bpp/feedback_rating_form';

export const CLOSE_FEEDBACK_FORM = 'CLOSE_FEEDBACK_FORM';
export const OPEN_FEEDBACK_FORM = 'OPEN_FEEDBACK_FORM';
export const TOGGLE_FEEDBACK_FORM = 'TOGGLE_FEEDBACK_FORM';
export const SUBMIT_FEEDBACK_FORM = 'SUBMIT_FEEDBACK_FORM';
export const RESTART_SUBMIT_FEEDBACK_FORM = 'SUBMIT_FEEDBACK_FORM';
export const SUBMIT_FEEDBACK_FORM_PENDING = 'SUBMIT_FEEDBACK_FORM_PENDING';
export const SUBMIT_FEEDBACK_FORM_SUCCESS = 'SUBMIT_FEEDBACK_FORM_SUCCESS';
export const SUBMIT_FEEDBACK_FORM_ERROR = 'SUBMIT_FEEDBACK_FORM_ERROR';

export function openFeedbackForm() {
  return {
    type: OPEN_FEEDBACK_FORM,
  };
}

export function closeFeedbackForm() {
  return {
    type: CLOSE_FEEDBACK_FORM,
  };
}

export function toggleFeedbackForm() {
  return {
    type: TOGGLE_FEEDBACK_FORM,
  };
}

export function submitFeedbackFormPending() {
  return {
    type: SUBMIT_FEEDBACK_FORM_PENDING,
  };
}

export function submitFeedbackFormSuccess() {
  return {
    type: SUBMIT_FEEDBACK_FORM_SUCCESS,
  };
}

export function submitFeedbackFormError(error) {
  return {
    type: SUBMIT_FEEDBACK_FORM_ERROR,
    error,
  };
}

export function restartFeedbackForm() {
  return {
    type: RESTART_SUBMIT_FEEDBACK_FORM,
  };
}

export function submitFeedbackForm(doctorId, data) {
  return (dispatch) => {
    dispatch(submitFeedbackFormPending());
    Axios.post(`/apiv3/feedback/${doctorId}`, data)
      .then(() => {
        dispatch(submitFeedbackFormSuccess());
        sendFeedbackRatingEmail(doctorId);
      })
      .catch((error) => {
        dispatch(submitFeedbackFormError(error));
      });
  };
}
