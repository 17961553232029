import React from 'react';
import { connect } from 'react-redux';

import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import RecordViewer from '../components/record_viewer/record_viewer';

const IteroRecordViewer = ({ recordStates, subtitle }) => {
  return (
    <RecordViewer
      subtitle={subtitle}
      records={recordStates.filter((file) => {
        return file.iteroScan === true;
      })}
      onLoad={() => {}}
    />
  );
};


const mapStateToProps = (state) => ({
  recordStates: getRecordStates(state),
});

export default connect(mapStateToProps)(IteroRecordViewer);
