import React from 'react';
import { convertDate, convertSex } from '../../common/functions';
import useFitText from 'use-fit-text';

function PatientInfo(props) {
  const { first_name, last_name, dob, sex, status, last_recorded_update, case_id, doctor_id, prospect_status } = props;
  const { fontSize: PCN_fontSize, ref: PCN_ref } = useFitText();
  const { fontSize: name_fontSize, ref: name_ref } = useFitText();

  return (
    <>
      <div className="patient-info__personal-info">
        <div className="profile-icon-container">
          {first_name && last_name ? (
            <div className="patient__profile-icon patient__initials">
              {first_name[0].toUpperCase()}
              {last_name[0].toUpperCase()}
            </div>
          ) : (
            <div className="patient__profile-icon patient__silhouette">
              <i className="fa fa-user-square" aria-hidden />
            </div>
          )}
        </div>
        <div className="patient-info__detail">
          <div ref={name_ref} style={{ fontSize: name_fontSize }}>
            {first_name} {last_name}
          </div>
          <div>{convertDate(dob)}</div>
          <div>{convertSex(sex)}</div>
        </div>
      </div>

      <div>
        <div className="medium">Status</div>
        <div ref={PCN_ref} style={{ fontSize: PCN_fontSize }}>
          {status ? status : 'N/A'}{' '}
          {status && (status === 'On Hold' || status === 'Prospect Lost') && prospect_status && prospect_status.status_comment
            ? '- ' + prospect_status.status_comment
            : null}
        </div>
      </div>

      {case_id ? (
        <div>
          <div className="medium">Case ID</div>
          <div ref={PCN_ref} style={{ fontSize: PCN_fontSize }}>
            <a href={'/portal/' + doctor_id + '/case/' + case_id} className="midnight">
              {case_id}
            </a>
          </div>
        </div>
      ) : null}

      <div>
        <div className="medium">Last Recorded Update</div>
        <div ref={PCN_ref} style={{ fontSize: PCN_fontSize }}>
          {last_recorded_update ? last_recorded_update + ' (PST)' : 'N/A'}
        </div>
      </div>
    </>
  );
}

export default PatientInfo;
