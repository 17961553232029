import React, { useState } from 'react';
import { CircleCheckIcon, LoadingIcon } from '../../../doctor/assets/icons';
import { connect } from 'react-redux';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import Button from '../../setup_viewer/components/extras/button';
import Modal from '../../setup_viewer/components/modal/modal';
import { bindActionCreators } from 'redux';
import { saveDraftEnd } from '../../../redux/actions/wasm_viewer/wasm_viewer';
import { saveDraft } from '../../../redux/actions/wasm_viewer/wasm_viewer';
import { UserPermissionsContext } from '../../../context/user_permission';

function displayLoading() {
  return (
    <div className="center">
      <LoadingIcon className="wasm-save-loading" />
      <div>Saving Changes...</div>
      <div>Please do not close this window while saving.</div>
    </div>
  );
}

function displaySuccess(close_modal, return_portal) {
  const handleReturnPortalClick = () => {
    close_modal();
    return_portal();
  };
  const handleContinueEditing = () => {
    close_modal();
  };
  return (
    <div className="center">
      <CircleCheckIcon />
      <div>All Changes Saved</div>
      <div>
        <Button theme="success" onClick={handleContinueEditing}>
          Continue Editing
        </Button>
        <Button theme="success" onClick={handleReturnPortalClick}>
          Return to Portal
        </Button>
      </div>
    </div>
  );
}

function displayErrorRetry(retry, close_modal) {
  return (
    <div>
      <div className="modal-title">Unable to Save Progress</div>
      <div className="modal-body-paragraph">Unable to save progress. Please try again.</div>
      <div className="modal-button-group">
        <Button theme="success" onClick={retry}>
          Try Again
        </Button>
        <Button theme="secondary" onClick={close_modal}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

function displayErrorMaxRetry(close) {
  return (
    <div>
      <div className="modal-title">Unable to Save Progress</div>
      <div className="modal-body-paragraph">
        Sorry, we were still unable to save your progress. This may be due to a temporary issue. Please check your internet connection or try again later. If
        the problem persists, contact our support team for immediate assistance.
      </div>
      <div className="modal-button-group">
        <Button theme="secondary" onClick={close}>
          Close
        </Button>
      </div>
    </div>
  );
}

const MAX_RETRY = 3;

function getModalDisplay(props, is_max_retry, retry, newOnReturn2Portal) {
  const { save_draft_success, save_draft_error, saveDraftEnd } = props;
  if (save_draft_success) {
    return displaySuccess(saveDraftEnd, newOnReturn2Portal);
  } else if (save_draft_error) {
    return is_max_retry ? displayErrorMaxRetry(saveDraftEnd) : displayErrorRetry(retry, saveDraftEnd);
  } else {
    return displayLoading();
  }
}

function SaveDraftModal(props) {
  const { save_draft_loading, save_draft_success, save_draft_error, saveDraft, saveDraftEnd } = props;
  const [saveFailedCount, setSaveFailedCount] = useState(0);
  const is_max_retry = saveFailedCount >= MAX_RETRY;

  const { doctor_id: doctorId } = React.useContext(UserPermissionsContext);
  const newOnReturn2Portal = () => props.onReturn2Portal(doctorId);
  const handleCloseModal = () => {
    saveDraftEnd();
  };

  const retry = () => {
    setSaveFailedCount(saveFailedCount + 1);
    saveDraft();
  };
  return (
    <Modal show={save_draft_loading} show_close_button={save_draft_success || save_draft_error} onCloseModal={handleCloseModal}>
      <div className="wasm-save-draft-container">{getModalDisplay(props, is_max_retry, retry, newOnReturn2Portal)}</div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    save_draft_loading: wasm_state.save_draft_loading,
    save_draft_success: wasm_state.save_draft_success,
    save_draft_error: wasm_state.save_draft_error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDraft: saveDraft,
      saveDraftEnd: saveDraftEnd,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SaveDraftModal);
