export const TEETHS = {
  upper: ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'],
  lower: ['LL8', 'LL7', 'LL6', 'LL5', 'LL4', 'LL3', 'LL2', 'LL1', 'LR1', 'LR2', 'LR3', 'LR4', 'LR5', 'LR6', 'LR7', 'LR8'],
};

const teethsArray = TEETHS.upper.concat(TEETHS.lower);

export const generateManualWireJSON = (caseId, arch, auto, smartwire, program, teethsData) => {
  const manualWireJSON = {
    case: {
      case_id: caseId,
      wire: smartwire,
      arch,
      program,
    },
    tabs: {},
    pairs: {},
    auto: auto,
  };

  const range = teethsData.range;
  const missingTeeths = teethsData.missing.filter((t) => !isNaN(t));
  const currentTeethsArray = TEETHS[arch];
  const invalidKeys = ['range', 'missing'];
  Object.keys(teethsData).forEach((teethLabel, i) => {
    if (!invalidKeys.includes(teethLabel)) {
      let teethNumber = currentTeethsArray.findIndex((tLabel) => tLabel === teethLabel) + 1;

      if (arch === 'lower') {
        teethNumber += 16;
      }
      if (
        teethNumber >= range[0] &&
        teethNumber <= range[1] &&
        !missingTeeths.includes(teethNumber) &&
        ((arch === 'upper' && teethNumber > 0) || (arch === 'lower' && teethNumber > 16))
      ) {
        const currentTeethData = teethsData[teethLabel];
        const locket = currentTeethData.locket;

        manualWireJSON.tabs[teethNumber] = {
          tooth_num: teethNumber.toString(),
          type: locket,
        };

        if (teethNumber < range[1]) {
          const teethPairNumber = currentTeethData.pairTeeth;
          const pair = [parseInt(teethNumber), parseInt(teethPairNumber)];
          const type = currentTeethData.loop;
          const length = parseFloat(currentTeethData.length[smartwire]);
          const offset = [
            currentTeethData.offset.x ? parseFloat(currentTeethData.offset.x) : -1,
            currentTeethData.offset.y ? parseFloat(currentTeethData.offset.y) : -1,
          ];
          const units = 'mm';

          manualWireJSON.pairs[`${teethNumber}-${teethPairNumber}`] = {
            type,
            length,
            pair,
            offset,
            units,
          };
        }
      }
    }
  });

  return manualWireJSON;
};

/**
 * Calculates the X offset based on the given parameters.
 *
 * @param {string} arch - The arch type ('upper' or 'lower').
 * @param {string} teethLabel - The label of the teeth.
 * @param {number} offset - The offset values.
 * @returns {number} - The calculated X offset.
 */
export const calculateXOffset = (arch, teethLabel, xOffset) => {
  const toothNumber = teethLabel[2];

  if (arch === 'upper') {
    if (xOffset >= 5.7) return xOffset;
    if (xOffset < 5.7 && xOffset >= 5.4) return 5.7;
    if (xOffset < 5.4 && xOffset >= 5.24) return xOffset;
    return 5.24;
  }
  if (toothNumber  > 4) return xOffset;
  if (toothNumber  > 3) {
      if (xOffset >= 5.4) return xOffset;
      if (xOffset < 5.4 && xOffset >= 5.0) return 5.4;
      if (xOffset < 5.0 && xOffset >= 4.8) return xOffset;
      return 5.24;
  }
  if (xOffset >= 5.0) return xOffset;
  if (xOffset < 5.0 && xOffset >= 4.7) return 5.0;
  if (xOffset < 4.7 && xOffset >= 4.5) return xOffset;
  return 4.5;
};

/**
 * Calculates the ideal offset for a given teeth label, offset, and largest Y offset.
 * @param {string} teethLabel - The label of the teeth.
 * @param {number[]} offset - The current offset values.
 * @param {number} largestYOffset - The largest Y offset value.
 * @returns {number[]} - The updated offset values.
 */
export const calculateYOffset = (teethLabel, offsetY, largestYOffset, countLargerThan2_5) => {
  const toothNumber = teethLabel[2];
  let newOffsetY;

  let isNegative = false;
  if (offsetY < 0) {
    isNegative = true;
    offsetY *= -1;
  }

  if (offsetY <= (toothNumber <= 3 ? 0.5 : 0.7)) {
    newOffsetY = 0;
  } else if (offsetY <= 2.5) {
    newOffsetY = offsetY - (toothNumber <= 3 ? 0.5 : 0.7);
  } else if (countLargerThan2_5 > 1 && offsetY == largestYOffset) {
    newOffsetY = 1.5;
    largestYOffset = Infinity;
  } else {
    newOffsetY = 2.0;
  }

  if (isNegative) newOffsetY *= -1;

  newOffsetY = (teethLabel.startsWith('UR') || teethLabel.startsWith('LL')) ? newOffsetY * -1 : newOffsetY;
  return newOffsetY;
};

export const findLargestYOffsetAndCount = (pairs) => {
  let countLargerThan2_5 = 0;
  const largestYOffsetEntry = Object.entries(pairs).reduce((prevPair, currentPair) => {
    if (currentPair[1].offset[1] > 2.5) {
      countLargerThan2_5++;
    }
    return (currentPair[1].offset[1] > prevPair[1].offset[1]) ? currentPair : prevPair;
  });

  return { largestYOffset: largestYOffsetEntry[1].offset[1], countLargerThan2_5 };
};

export const getCaseDataFromCustomWireGeneration = (manualWireData, arch) => {
  const range = manualWireData[arch].range;
  const missingTeeths = manualWireData[arch].missing;
  const pairs = manualWireData.length ? manualWireData[0][arch].pairs : manualWireData[arch].pairs;
  const { largestYOffset, countLargerThan2_5 } = findLargestYOffsetAndCount(pairs);
  const caseData = {};

  Object.keys(pairs).forEach((pair) => {
    const pairTeeth = pairs[pair].pair[1];
    const teethIndex = pairs[pair].pair[0];
    const offset = pairs[pair].offset;
    
    const teethLabel = teethsArray[teethIndex - 1];
    const offsetX = calculateXOffset(arch, teethLabel, offset[0]);
    const offsetY = calculateYOffset(teethLabel, offset[1], largestYOffset, countLargerThan2_5);

    caseData[teethLabel] = {
      malloclusionBracToBracDist: pairs[pair].mal_dist,
      idealBracToBracDist: pairs[pair].ideal_dist,
      offset: [offsetX, offsetY],
      length: {
        initial: pairs[pair].bmfs.initial.loop_length,
        intermediate: pairs[pair].bmfs.intermediate.loop_length,
        final: pairs[pair].bmfs.final.loop_length,
      },
      pairTeeth,
    };
  });

  return {
    caseData,
    range,
    missingTeeths,
    auto: false,
  };
};

export const getCaseDataFromManualAutomationStatus = (manualWireAutomation, arch, smartwire) => {
  const cdata = JSON.parse(manualWireAutomation.cdata);
  const range = cdata[arch].range;
  const missingTeeths = cdata[arch].missing;
  const cdata_pairs = cdata.length ? cdata[0][arch].pairs : cdata[arch].pairs;
  const caseData = {};

  const settings = JSON.parse(manualWireAutomation.settings);
  const settings_pairs = settings.pairs;
  const auto = settings.auto;
  const hasGenerated = settings?.has_generated ?? false;

  Object.keys(cdata_pairs).forEach((pair) => {
    const pairTeeth = cdata_pairs[pair].pair[1];
    const teethIndex = cdata_pairs[pair].pair[0];
    const teethLabel = teethsArray[teethIndex - 1];

    const initialLength = settings.case.wire === smartwire ? settings_pairs[pair].length : cdata_pairs[pair].bmfs.initial.loop_length;
    const intermediateLength = settings.case.wire === smartwire ? settings_pairs[pair].length : cdata_pairs[pair].bmfs.intermediate.loop_length;
    const finalLength = settings.case.wire === smartwire ? settings_pairs[pair].length : cdata_pairs[pair].bmfs.final.loop_length;

    const generatedInitialLength = settings.case.wire === smartwire ? settings_pairs[pair].generated_length : cdata_pairs[pair].bmfs.initial.loop_length;
    const generatedIntermediateLength = settings.case.wire === smartwire ? settings_pairs[pair].generated_length : cdata_pairs[pair].bmfs.intermediate.loop_length;
    const generatedFinalLength = settings.case.wire === smartwire ? settings_pairs[pair].generated_length : cdata_pairs[pair].bmfs.final.loop_length;

    caseData[teethLabel] = {
      malloclusionBracToBracDist: cdata_pairs[pair].mal_dist,
      idealBracToBracDist: cdata_pairs[pair].ideal_dist,
      offset: settings_pairs[pair].offset,
      loop: settings_pairs[pair].type,
      locket: settings.tabs[teethIndex].type,
      length: {
        initial: initialLength,
        intermediate: intermediateLength,
        final: finalLength,
      },
      generatedLength: {
        initial: generatedInitialLength,
        intermediate: generatedIntermediateLength,
        final: generatedFinalLength,
      },
      pairTeeth,
    };
  });

  return {
    caseData,
    range,
    missingTeeths,
    auto,
    hasGenerated,
  };
};

/**
 * Filters teeth indexes based on arch type, missing teeth, and a specified range.
 *
 * @param {string} arch - The arch type ('upper' or 'lower').
 * @param {number[]} missingTeeths - An array of missing teeth numbers.
 * @param {number[]} range - An array of two numbers representing the range of teeth to include.
 * @param {number} numberOfItems - The total number of teeth.
 * @returns {number[]} An array of filtered indexes.
 */
export const getFilteredIndexes = (arch, missingTeeths, range, numberOfItems) => {
  const filteredIndexes = Array.from({ length: numberOfItems }, (_, i) => i + 1).filter((val, i) => {
    const rangeVal = arch === 'upper' ? val : val + 16;
    const isMissingTooth = missingTeeths.includes(rangeVal);
    const isInRange = rangeVal >= range[0] && (numberOfItems === 16 ? rangeVal <= range[1] : rangeVal <= range[1] - 1);
    return isInRange && !isMissingTooth;
  });
  return filteredIndexes;
};

export const getWireNamesOptions = (wireNames) => {
  const archs = [];
  const smartwires = {};

  wireNames.forEach((wireName) => {
    const [arch, smartwire] = wireName.split('_');

    if (!archs.includes(arch)) archs.push(arch);

    if (!smartwires[arch]) {
      smartwires[arch] = [smartwire];
    } else if (!smartwires[arch].includes(smartwire)) {
      smartwires[arch].push(smartwire);
    }
  });

  return {
    archs,
    smartwires
  };
};

/**
 * Prepares the state with case data.
 * @param {Object} state - The current state.
 * @param {Object} caseData - The case data.
 * @param {string} arch - The arch type ('lower' or 'upper').
 * @param {Array<number>} filteredIndexes - The filtered indexes.
 * @returns {Object} - The new state with updated case data.
 */
export const prepareStateWithCaseData = (state, caseData, arch, filteredIndexes) => {
  const newState = { ...state };
  if (
    (arch === 'lower' && Object.keys(caseData).find((teethLabel) => teethLabel.toLowerCase().startsWith('u'))) ||
    (arch === 'upper' && Object.keys(caseData).find((teethLabel) => teethLabel.toLowerCase().startsWith('l')))
  ) {
    return state;
  }

  Object.keys(newState[arch]).forEach((teethLabel) => {
    if (!Object.keys(caseData).includes(teethLabel)) {
      newState[arch][teethLabel].disabled = filteredIndexes ? !filteredIndexes.some((index) => TEETHS[arch][index - 1] === teethLabel) : true;
    } else {
      const length = caseData[teethLabel].length[state.wire];
      newState[arch][teethLabel].length[state.wire] = length ? parseFloat(length).toFixed(2) : '0.00';
      const generatedLength = caseData[teethLabel]?.generatedLength?.[state.wire] ?? null;
      newState[arch][teethLabel].generatedLength[state.wire] = generatedLength ? parseFloat(generatedLength).toFixed(2) : '0.00';
      const offsetX = caseData[teethLabel].offset[0];
      const offsetY = caseData[teethLabel].offset[1];
      newState[arch][teethLabel].offset = {
        x: offsetX ? parseFloat(offsetX).toFixed(2) : '0.00',
        y: offsetY ? parseFloat(offsetY).toFixed(2) : '0.00',
      };
      newState[arch][teethLabel].malloclusionBracToBracDist = caseData.malloclusionBracToBracDist;
      newState[arch][teethLabel].idealBracToBracDist = caseData.idealBracToBracDist;
      newState[arch][teethLabel].pairTeeth = caseData[teethLabel].pairTeeth;
      newState[arch][teethLabel].loop = caseData[teethLabel].loop ?? newState[arch][teethLabel].loop;
      newState[arch][teethLabel].locket = caseData[teethLabel].locket ?? newState[arch][teethLabel].locket;
    }
  });
  return newState;
}

/**
 * Checks if a value is a number.
 * @param {*} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is a number, false otherwise.
 */
export const isNumber = (value) => {
  return value !== '' && value !== null && !isNaN(value);
};

/**
 * Returns a string representation of the given value with only 2 decimal places.
 * If the value has more than 2 decimal places, it truncates the extra decimal places without rounding.
 *
 * @param {string} value - The value to be formatted.
 * @returns {string} The formatted value with only 2 decimal places.
 */
export const getOnly2DecimalPlaces = (value) => {
  const parts = value.split('.');
  const decimalPlaces = (parts.length > 1) ? parts[1].length : 0;
  if (decimalPlaces > 2) {
      value = parts[0] + '.' + parts[1].substr(0, 2); // Truncate without rounding
  }
  return value
};