import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loader from '../../components/loader/loader';
import EditDEPlan from './edit_de_plan';
import EditDE3Plan from './edit_de3_plan';
import EditTXPlan from './edit_tx_plan';
import EditTX3Plan from './edit_tx3_plan';
import TextArea from '../../doctor/components/inputs/text_area';
import { removeEmoji } from '../../common/functions';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';

/**
 * Splits the string into array
 *
 * - Separator is `','` by default,
 * - Removes empty strings by default
 *
 * Can specify the following options:
 * - sep {String}: changing the default seperator
 * - keepEmptyStrings {Boolean}: to keep the empty strings after splitting
 *
 * @example
 * // returns []
 * splitStrToArray(",,");
 * @example
 * // returns ["", "", ""]
 * splitStrToArray(",,", options={keepEmptyStrings: true});
 *
 * @function
 * @param {String} str - String to split
 * @param {Object} [options={}] - Options to split
 * @return {Array} The splitted array
 */
export const splitStrToArray = (str, options = {}) => {
  if (!str) return [];

  const sep = options.sep || ',';
  if (options.keepEmptyStrings) {
    return str.split(sep);
  }
  return str.split(sep).filter(String);
};

/**
 * Displays either TX or DE edit form.
 * @component
 * @alias EditTXDEPlan
 * @category BPP
 */
class EditTXDEPlan extends Component {
  state = {
    description: '',
    warning: false,
    submit: false,
    loading: false,
    error: null,
  };

  componentDidUpdate() {
    // closes modal when finish submitting plan without error
    const shouldCloseModal = !this.state.loading && this.state.submit && !this.state.error;
    if (shouldCloseModal) {
      this.props.closeModal();
    }
  }

  /**
   * Displays the warning summary window
   *
   * @function
   * @param {Array} warnings - An array of HTML string
   */
  showWarning = (warnings) => {
    let warning = document.querySelector('#warning-submit');
    if (warning) {
      warning.classList.add('warning-display');
      warning.innerHTML = `<ul>${warnings}</ul>`;
      this.setState({ warning: true });
    }
  };

  /**
   * Removes warning from divs by clearing class names.
   * @function
   */
  clearWarning = () => {
    const warning = document.querySelector('#warning-submit');
    if (warning) {
      warning.classList.remove('warning-display');
    }

    const ids = [
      '#tx_edit_desrcription_title',
      '#tx_edit_desrcription_heading',
      '#tx_edit_desrcription_textarea',

      '#providerAddress',
      '#providerAddressLabel',
      '#patientFirstName',
      '#patientFirstNameLabel',
      '#patientLastName',
      '#patientLastNameLabel',
      '#patientDOB',
      '#patientDOBLabel',
      '#patientSex',
      '#patientRef',
      '#providerAddress',
      '#providerAddressLabel',
      '#patientChiefComplaint',
      '#patientChiefComplaintLabel',
      '#archToTreat',
      '#archToTreatLabel',
      '#teethToTreat',
      '#teethToTreatLabel',
      '#chief_complaint',
      '#chief_complaint_title',
      '#arch_to_treat',
      '#bracket_teeth',
      '#clinical_condition',
      '#ap_relationship_title',
      '#ap_relationship_box',
      '#crowding_title',
      '#spacing_title',
      '#overjet_title',
      '#overjet_box',
      '#deep_bite_title',
      '#deep_bite_box',
      '#crossbite_transverse_title',

      '#scanDate',
      '#intraOral',
      '#reasonSelection',
      '#reason',
      '#setupSelection',
      '#setup',
      '#de2_initial_date',
      '#de2_initial_date_input',
      '#de2_reason_textarea',
      '#de2_reason',
      '#de2_intra_date',
      '#de2_intra_date_input',
      '#anterior_posterior',
      '#anteriorPosteriorNotes',
      '#midlines',
      '#tx_auxiliaries',
      '#auxiliariesNotes',
      '#de3_reason',
      '#de3_next_appointment_date_input',
      '#de3_next_appointment_date',
      '#de3_reason_textarea',
      '#de3_intra_date_input',
      '#de3_intra_date',
      '#de3_anterior_posterior',
      '#de3_anterior_posterior_notes',
      '#de3_midlines',
      '#de3_auxiliaries',
      '#de3_auxiliaries_notes',
      '#de3_bracket_teeth',
      '#de3_setup',
      '#de3_setup_instruction',
      'textarea[data-field="de3_setup_instruction"]',
    ];

    this.setState({ warning: false });

    for (let id of ids) {
      let element = document.querySelector(id);
      let static_warning = !(
        ((id === '#de2_intra_date_input' || id === '#de2_intra_date') && document.querySelector('#de2_intra_date_warning')) ||
        ((id === '#de2_initial_date' || id === '#de2_initial_date_input') && document.querySelector('#de2_initial_date_warning'))
      );

      if (static_warning && element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }

      handleAnteriorPosteriorCheckboxErrors(false);
    }
  };

  /**
   * Returns whether `case_id` is DE or not.
   *
   * @function
   * @param {String} case_id - A case id
   * @return {Boolean} whether `case_id` is DE or not
   */
  isDE(case_id) {
    return case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);
  }

  /**
   * Handles typing in the textarea
   *
   * @function
   * @param {Object} event - The event object after changing
   */
  onChangeDescription = (event) => {
    this.setState({ description: removeEmoji(event.target.value) });
  };

  /**
   * Handles updating the status in the state.
   *
   *  - warning
   *  - submit
   *  - loading
   *  - error
   * @function
   * @param {Object} status - The status in the state to update
   */
  onChangeStatus = (status) => {
    this.setState(status);
  };

  /**
   * Displays a text length limit counter.
   *
   * @function
   * @param {Number} maxLength - The max length to specify
   * @return {JSX} The text length JSX element
   */
  showTextLengthLimit(maxLength) {
    const textLength = this.state.description.length;
    const className = textLength >= maxLength ? 'text-error' : '';
    return (
      <p className={className} style={{ textAlign: 'right', fontSize: '1em' }}>
        {textLength} / {maxLength}
      </p>
    );
  }

  /**
   * Displays the text area for Description of Changes.
   *
   * @function
   * @return {JSX} The textarea JSX element
   */
  showTextArea = () => {
    const maxLength = 2500;
    return (
      <div className="col-md-12">
        <h3 id="tx_edit_desrcription_heading" className="tx-heading">
          Description of Changes*
        </h3>
        <div id="tx_edit_desrcription_title" className="title">
          Please provide details and reason for Treatment Plan changes to be displayed to the doctor...
        </div>

        <TextArea
          id="tx_edit_desrcription_textarea"
          className="modal-textarea-spacing"
          maxLength={maxLength}
          onChange={this.onChangeDescription}
          rows="4"
          value={this.state.description}
          onFocus={this.clearWarning}
        />
        {this.showTextLengthLimit(maxLength)}
        <hr className="bpp-hr" />
      </div>
    );
  };

  render() {
    if (!this.props.treatment_plan) {
      return <div className="text-error text-center">Error: Unable to load treatment plan...</div>;
    }
    if (this.state.loading) {
      return <Loader windowSize="form" altText="Updating Treatment Plan..." />;
    }
    if (this.state.submit && this.state.error) {
      return <div className="text-error text-center">Edit Failed...</div>;
    }

    const Plan = this.isDE(this.props.case_id)
      ? this.props.treatment_plan.revision_id === 'DE3.0'
        ? EditDE3Plan
        : EditDEPlan
      : this.props.treatment_plan.revision_id === 'TX3.0'
      ? EditTX3Plan
      : EditTXPlan;

    return (
      <div className="ipp-light">
        {this.showTextArea()}
        <Plan
          {...this.props}
          description={this.state.description}
          warning={this.state.warning}
          showWarning={this.showWarning}
          clearWarning={this.clearWarning}
          setStatus={this.onChangeStatus}
        />
      </div>
    );
  }
}

EditTXDEPlan.propTypes = {
  /**
   * Case ID
   */
  case_id: PropTypes.string.isRequired,
  /**
   * Raw treatment plan with each value as string
   */
  treatment_plan: PropTypes.object.isRequired,
  /**
   * Whether the save button had been clicked
   */
  save_tx_plan_changes: PropTypes.bool.isRequired,
  /**
   * To change save_tx_plan_changes value to false
   */
  changeSaveValue: PropTypes.func.isRequired,
  /**
   * To reload the case details
   */
  reloadInformation: PropTypes.func.isRequired,
  /**
   * To close the modal after successful saving
   */
  closeModal: PropTypes.func.isRequired,
};

export default EditTXDEPlan;
