// External
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal
import SingleToothIdb from '../../../components/workflow/cad/single_tooth_idb/single_tooth_idb';
import '../../../components/workflow/cad/cad_process.scss';

// Redux
import { getSingleToothIdbStatus } from '../../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';
import { fetchSingleToothIdb } from '../../../redux/actions/bpp/single_tooth_idb/single_tooth_idb';

/**
 * Displays Single-Tooth IDB generation for item requests
 * @component
 * @alias IrSingleToothIdb
 * @category BPP
 */
class IrSingleToothIdb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchSingleToothIdb(this.props.ireq_id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.ireq_id !== prevProps.ireq_id) this.props.fetchSingleToothIdb(this.props.ireq_id);
  }

  render() {
    return this.props.single_tooth_idb_status ? (
      <div className="cad-container">
        <div className="bold-text business-dark-heading">Single-Tooth IDB Tray</div>
        <div className="business-dark-theme">
          <SingleToothIdb id={this.props.ireq_id} shipping_approved={this.props.shipping_approved} />
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    single_tooth_idb_status: getSingleToothIdbStatus(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSingleToothIdb: fetchSingleToothIdb,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IrSingleToothIdb);
