/**
 * File: browser_warning.js - display a message for browser that are not using
 * google chrome
 * Copyright: (c) Copyright August 2019 by InBrace
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './browser_warning.scss';
// External Libs
import React, { Component } from 'react';
// Internal Functions
import { getEnvName, firstLetterCapital } from '../../../common/functions';
import { isChrome, isEdge, isModernSafari } from '../../../common/browser';
import EnvironmentBanner from '../../../components/environment_banner/environment_banner';

const MAIN_CLASS = 'browser-warning-doctor';
/**
 * Gets the height of the browser warning element.
 *
 * @returns {Number} The height of the child p element within the main element.
 */
export const getBrowserWarningElementHeight = () => {
  let result = 0;
  const browserWarningElement = document.querySelector(`.${MAIN_CLASS}`);
  const browserWarningChildElement = browserWarningElement?.querySelector('p');

  // Get the height of the child element if it exists
  result = browserWarningChildElement?.clientHeight || 0;

  return result;
};

/**
 * A banner that shows up when you're not using chrome
 * @component
 * @alias BrowserWarning
 * @category Common
 */
class BrowserWarning extends Component {
  render() {
    const envName = getEnvName();

    return (
      <React.Fragment>
        {isChrome || isModernSafari || isEdge ? (
          <EnvironmentBanner />
        ) : (
          <div className={MAIN_CLASS}>
            <p className="top-overlay-message">
              {envName ? `${firstLetterCapital(envName)} Environment: ` : ''}
              We noticed you are using a browser that is unsupported. This will prevent you from using certain features. To get the best experience, InBrace
              strongly recommends using{' '}
              <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                Google Chrome
              </a>
              .
            </p>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default BrowserWarning;
