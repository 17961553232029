// External Libs
import React, { Component } from 'react';

/**
 * Displays IR label generation retry processing step
 * @component
 * @alias CadLabelProcessing
 * @category BPP
 */
class CadLabelProcessing extends Component {
  render() {
    return (
      <>
        <div className="process-box-intro">
          <div className="process-box-title">Label Generation</div>
          <div>Returning to Label generation...</div>
        </div>

        <div className="row text-center">
          <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
        </div>
      </>
    );
  }
}

export default CadLabelProcessing;
