export const EDeltaType = {
  Angular: 'Angular',
  Spacial: 'Spacial',
};
export const ETmtKey = {
  Inclination: 'Inclination',
  Angulation: 'Angulation',
  Rotation: 'Rotation',
  Mesial: 'Mesial',
  Vestibular: 'Vestibular',
  Occlusal: 'Occlusal',
  MesialHinge: 'MesialHinge',
  DistalHinge: 'DistalHinge',
  RootTorque: 'RootTorque',
  CrownTorque: 'CrownTorque',
};
export const ETmtDecrKey = {
  Inclination: 'IncrementInclinationMinusLocked',
  Angulation: 'IncrementAngulationMinusLocked',
  Rotation: 'IncrementRotationMinusLocked',
  Mesial: 'IncrementMesialMinusLocked',
  Vestibular: 'IncrementVestibularMinusLocked',
  Occlusal: 'IncrementOcclusalMinusLocked',
  MesialHinge: 'IncrementMesialHingeMinusLocked',
  DistalHinge: 'IncrementDistalHingeMinusLocked',
  RootTorque: 'IncrementRootTorqueMinusLocked',
  CrownTorque: 'IncrementCrownTorqueMinusLocked',
};
export const ETmtIncrKey = {
  Inclination: 'IncrementInclinationPlusLocked',
  Angulation: 'IncrementAngulationPlusLocked',
  Rotation: 'IncrementRotationPlusLocked',
  Mesial: 'IncrementMesialPlusLocked',
  Vestibular: 'IncrementVestibularPlusLocked',
  Occlusal: 'IncrementOcclusalPlusLocked',
  MesialHinge: 'IncrementMesialHingePlusLocked',
  DistalHinge: 'IncrementDistalHingePlusLocked',
  RootTorque: 'IncrementRootTorquePlusLocked',
  CrownTorque: 'IncrementCrownTorquePlusLocked',
};
export const EMovementSigns = {
  [ETmtKey.Occlusal]: { neg: 'I', negTooltip: 'I', pos: 'E', posTooltip: 'E' },
  [ETmtKey.Vestibular]: { neg: 'L', negTooltip: 'L', pos: 'B', posTooltip: 'B' },
  [ETmtKey.Mesial]: { neg: 'D', negTooltip: 'D', pos: 'M', posTooltip: 'M' },
  [ETmtKey.Rotation]: { neg: 'D', negTooltip: 'D-Out', pos: 'M', posTooltip: 'M-Out' },
  [ETmtKey.MesialHinge]: { neg: 'D', negTooltip: 'D-Out', pos: 'M', posTooltip: 'D-In' },
  [ETmtKey.DistalHinge]: { neg: 'D', negTooltip: 'M-In', pos: 'M', posTooltip: 'M-Out' },
  [ETmtKey.Angulation]: { neg: 'M', negTooltip: 'MRT', pos: 'D', posTooltip: 'DRT' },
  [ETmtKey.Inclination]: { neg: 'B', negTooltip: 'B', pos: 'L', posTooltip: 'L' },
  [ETmtKey.RootTorque]: { neg: 'B', negTooltip: 'B', pos: 'L', posTooltip: 'L' },
  [ETmtKey.CrownTorque]: { neg: 'L', negTooltip: 'L', pos: 'B', posTooltip: 'B' },
};
export const movementTableFractionDigits = 1;
