// ---------------------------------- Imports ----------------------------------
// Css
import './invalid_address.scss';
// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux
import {
  getAddressSuggestions,
  getOriginalAddress,
  getSelectedAddressId,
  getOriginalAddressSelected,
  getSmartyStreetsError,
} from '../../redux/reducers/bpp/account/add_account';
import { onSuggestedAddressChange, onOriginalAddressChange } from '../../redux/actions/bpp/account/add_account';

/**
 * Contains modal content for displaying address suggestions when an invalid address was entered
 * @component
 * @category Modals
 */
class InvalidAddress extends Component {
  render() {
    return (
      <div className="invalid-address p-slim grey-text">
        {this.props.address_suggestions.length > 0 ? (
          <React.Fragment>
            <div className="invalid-message">
              The address you entered may not be valid. We have provided a suggested address. Please select an address, then click <b>Save</b> to proceed.
            </div>
            <div className="form-group">
              <b>Original Address</b>
            </div>
            <div className="form-group invalid-address-row">
              <input type="radio" className="form-check-input" onChange={this.props.onOriginalAddressChange} checked={this.props.original_address_selected} />
              <label>
                {this.props.original_address.address_line_1} {this.props.original_address.address_line_2}
                <br />
                {this.props.original_address.city}, {this.props.original_address.state} {this.props.original_address.zip}, {this.props.original_address.country}
              </label>
            </div>

            <div className="form-group">
              <b>Suggested Address</b>
            </div>

            <div className="form-group invalid-address-row">
              {this.props.address_suggestions.map((address, index) => {
                return (
                  <span className="address-suggestion" key={index}>
                    <input
                      type="radio"
                      className="form-check-input"
                      value={address.id}
                      checked={address.id === this.props.selected_address_id}
                      onChange={(e) => this.props.onSuggestedAddressChange(e)}
                    />
                    <label>
                      {address.addr1} {address.addr2}
                      <br />
                      {address.city}, {address.state} {address.zip}, {address.country}
                    </label>
                  </span>
                );
              })}
            </div>
          </React.Fragment>
        ) : this.props.smartystreets_error ? (
          <div className="message">Address lookup limit reached, unable to save address. Please contact admin.</div>
        ) : (
          <div className="message">The address you entered is invalid, please try again.</div>
        )}
      </div>
    );
  }
}

InvalidAddress.propTypes = {
  address_suggestions: PropTypes.array.isRequired,
  original_address: PropTypes.object.isRequired,
  selected_address_id: PropTypes.string.isRequired,
  original_address_selected: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    original_address: getOriginalAddress(state),
    address_suggestions: getAddressSuggestions(state),
    selected_address_id: getSelectedAddressId(state),
    original_address_selected: getOriginalAddressSelected(state),
    smartystreets_error: getSmartyStreetsError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSuggestedAddressChange: onSuggestedAddressChange,
      onOriginalAddressChange: onOriginalAddressChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvalidAddress);
