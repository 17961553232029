import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/modal/modal';
import WireSelectionReviewItem from '../../../components/workflow/wire_selection/wire_selection_review_item';
import { removeCaseIdInitialNumber } from '../../../common/functions';
import { parseWSSelections, assignUniversalArch } from '../../../components/workflow/wire_selection/wire_selection_review_detail';

const UPDATE_TEXT = 'Please update the wire(s) for the case.';
const CONFIRM_TEXT = 'Are you sure you want to redo wire selection?';
const ERROR_TEXT_CHANGE = 'Please update wire selections.';
const ERROR_TEXT_MANUAL = 'Please update the DXF file for the selected manual custom wire.';

const getStatusCommentJSON = (ws_statuses) => {
  const ws_status = ws_statuses && ws_statuses.find((s) => s.status === 'WS Conversion Succeeded');
  const status_comment = ws_status && ws_status.status_comment;
  const status_comment_json = status_comment ? JSON.parse(status_comment) : {};

  return status_comment_json;
};

const mergeWsTemp = (ws, ws_temp) => {
  return ws.map((w) => {
    const temp_wire = ws_temp.filter((wt) => wt.wire_name === w.wire_name);
    if (temp_wire && temp_wire.length > 0) {
      w.case_file_manual__file_type = temp_wire[0].case_file_manual__file_type;
      w.case_file_manual__original_filename = temp_wire[0].case_file_manual__original_filename;
      w.case_file_manual__upload_data = temp_wire[0].case_file_manual__upload_data;
      w.case_file_manual_id = temp_wire[0].case_file_manual_id;
    }

    return w;
  });
};

const constructInitialSelections = (ws) =>
  ws.map((w) => ({
    wire_name: w.wire_name,
    selection: w.selection_override ? w.selection_override : w.selection,
  }));

const mergeSelections = (ws, selections) => {
  return ws.map((w) => ({
    ...w,
    selection_previous: w.selection_override ? w.selection_override : w.selection,
    selection_override: selections.find((sel) => w.wire_name === sel.wire_name).selection,
  }));
};

const getNewSelection = (selections, selection, wire_name) =>
  selections.map((sel) =>
    sel.wire_name === wire_name
      ? {
          ...sel,
          selection: selection,
        }
      : sel
  );

/**
 * Determines if wire selections in redo modal have changed from previous selections
 * @function
 * @param {list} ws - List of case's wires
 * @param {list} selections - Current selections
 * @returns {boolean} - True or false
 */
const hasChanged = (ws, selections) => {
  const isDifferent = (wire) => {
    const selection = selections.find((s) => {
      return s.wire_name === wire.wire_name;
    });
    return selection ? wire.selection_previous !== selection.selection || selection.selection === 'manual_custom' : true;
  };
  const has_changed = ws.some(isDifferent);
  return has_changed;
};

const hasNoUploadManual = (ws) => {
  const notUploaded = (wire) => wire.manual_url === null && wire.selected === 'manual_custom';
  const hasNoUpload = ws.some(notUploaded);
  return hasNoUpload;
};

const filterByDE = (filtered_selection, de_selection) => {
  let ws_de_selection = de_selection.toLowerCase().replace('1', 'initial').replace('2', 'intermediate').replace('3', 'final');

  let selection =
    de_selection !== ''
      ? filtered_selection.filter((item) => {
          const arch_info = item.wire_name.split('_');
          return ws_de_selection.indexOf(arch_info[0]) >= 0 && ws_de_selection.indexOf(arch_info[1]) >= 0;
        })
      : filtered_selection;

  return selection;
};

const filterByArch = (ws, arch) => {
  return ws.filter((item) => {
    const arch_info = item.wire_name.split('_');
    return arch === 'both' || arch === arch_info[0];
  });
};

function WireSelectionRedo(props) {
  const { onClose, closeAndClearTempFiles, case_id, onUploadClick, onRemoveClick, ws, ws_statuses, ws_temp, wsRedo, arch, ws_de_selection } = props;
  const [redoConfirm, setRedoConfirm] = useState(false);
  const [error, setError] = useState('');
  const [selections, setSelections] = useState(constructInitialSelections(ws));
  useEffect(() => {
    setError('');
  }, [selections]);
  const onSelect = useCallback((selection, wire_name) => setSelections(getNewSelection(selections, selection, wire_name)), [selections]);

  const ws_status_comment_json = getStatusCommentJSON(ws_statuses);
  const filter_ws = filterByDE(filterByArch(ws, arch), ws_de_selection);
  const new_ws = mergeSelections(mergeWsTemp(filter_ws, ws_temp), selections);
  const universal_arch_info = assignUniversalArch(ws_status_comment_json);
  const unsort_selections = parseWSSelections(new_ws, ws_status_comment_json, universal_arch_info);
  const ordered_selections = _.orderBy(unsort_selections, ['smartwire_num', 'arch'], ['asc', 'desc']);

  const validate = () => {
    if (!hasChanged(new_ws, selections)) {
      setError('change');
    } else if (hasNoUploadManual(ordered_selections)) {
      setError('manual');
    } else {
      setRedoConfirm(true);
    }
  };

  return (
    <Modal
      modal_size_class={redoConfirm ? 'modal-md' : 'modal-lg'}
      theme="bpp"
      header_text={`Redo Wire Selection - ${removeCaseIdInitialNumber(case_id)}`}
      modal_body_class="left-text"
      onCloseButtonClick={closeAndClearTempFiles}
      footer={false}
    >
      <div className="grey-text center-text bottom-margin-10">{redoConfirm ? CONFIRM_TEXT : UPDATE_TEXT}</div>
      <div className="wire-selection-body">
        {!redoConfirm && (
          <div className="wire-selection-process process-box">
            {ordered_selections.map((wire, i) => (
              <WireSelectionReviewItem
                key={i}
                wire={wire}
                onChange={(selection) => onSelect(selection, wire.wire_name)}
                onUploadClick={onUploadClick}
                onRemoveClick={onRemoveClick}
                show_manual_error={wire.manual_url === null && wire.selected === 'manual_custom'}
                setError={setError}
                redo={'true'}
              />
            ))}
          </div>
        )}
      </div>
      {error === 'change' && <div className="center-text warning-text">{ERROR_TEXT_CHANGE}</div>}
      {error === 'manual' && <div className="center-text warning-text">{ERROR_TEXT_MANUAL}</div>}
      <div className="modal-footer">
        {redoConfirm ? (
          <>
            <button
              className="btn btn-light"
              onClick={() => {
                wsRedo(selections);
                onClose();
              }}
            >
              Confirm
            </button>
            <button className="btn btn-light btn-negative" onClick={() => setRedoConfirm(false)}>
              Cancel
            </button>
          </>
        ) : (
          <>
            <button className="btn btn-light" onClick={validate}>
              Redo Wire Selection
            </button>
            <button className="btn btn-light btn-negative" data-dismiss="modal" onClick={closeAndClearTempFiles}>
              Cancel
            </button>
          </>
        )}
      </div>
    </Modal>
  );
}

WireSelectionRedo.propTypes = {
  case_id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WireSelectionRedo;
