import _ from 'lodash';
import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';

import TeethSelector from '../../common/teeth_selector';
import { userHasPermission } from '../../common/permission';
import { textFieldCheck, removeEmoji, onImageToolAction } from '../../common/functions';

import { UserPermissionsContext } from '../../context/user_permission';

import TextArea from '../components/inputs/text_area';
import RecordViewer from '../components/record_viewer/record_viewer';
import AlertMessage from '../components/alert_message';

import { QBox, Teeth, ToothViewer, TxPlan } from './tx_plan';

class TX2Deprecated extends TxPlan {
  make_ap_relationship = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_midlines = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_crowding = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_spacing = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_overjet = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_deepbite = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_openbite = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  make_crossbite = (state, condition, tset) => {
    throw new Error('Deprecated');
  };

  onChangeSpacingValue(index, event, mode) {
    throw new Error('Deprecated');
  }

  onChangeSpacingOnBlur(index, event, mode) {
    throw new Error('Deprecated');
  }

  clearSpacingValue(index, mode) {
    throw new Error('Deprecated');
  }

  makeCheckBar = (cond, key, disabledEdit) => {
    throw new Error('Deprecated');
  };

  makeCheckbox = (
    name,
    group,
    { cond = null, disabled = false, onCheck = null },
    disabledEdit = false,
    onFocus_clearWarning = () => {
      return;
    }
  ) => {
    throw new Error('Deprecated');
  };

  makeAuxNotes = (
    test,
    condition,
    state_key,
    onFocus_clearWarning = () => {
      return;
    },
    disabledEdit = false
  ) => {
    throw new Error('Deprecated');
  };

  setConditionState = (cnd, newState) => {
    throw new Error('Deprecated');
  };

  getConditionState = (cnd) => {
    throw new Error('Deprecated');
  };

  formatMMEntry = (cond = null, group = null, value, index = null) => {
    throw new Error('Deprecated');
  };
}

class TxPlan3 extends TX2Deprecated {
  componentDidMount() {
    this.buildImageRecords();

    this.props.setPlanState({
      formVisited: true,
    });
  }

  /**
   * Creates a radio input element with the given properties.
   * @param {Object} options - The options for the radio input.
   * @param {string} options.name - The name of the radio input.
   * @param {string} options.value - The value of the radio input.
   * @param {string} [options.label=''] - The label for the radio input.
   * @param {boolean} [options.disabledEdit=false] - Whether the radio input is disabled.
   * @param {string} [options.mainRadioName=null] - The name of the main radio input.
   * @param {string} [options.mainRadioValue=null] - The value of the main radio input.
   * @param {string} [options.dataTestIdPrefix='radio'] - The prefix for the data-testid attribute.
   * @returns {JSX.Element} The radio input element.
   */
  makeRadio = ({ name, value, label = '', disabledEdit = false, mainRadioName = null, mainRadioValue = null, dataTestIdPrefix = 'radio' }) => {
    const cID = name + '-' + value;
    const dataTestId = `${dataTestIdPrefix}_${value}`;

    const state = this.props;

    const hasMainRadio = mainRadioName && mainRadioValue;
    const mainRadioIsChecked = state[mainRadioName] === mainRadioValue;

    const isDisabled = () => {
      if (hasMainRadio) {
        return !mainRadioIsChecked || disabledEdit;
      }
      return disabledEdit;
    };

    const isChecked = () => {
      const currentIsChecked = state[name] === value;
      if (hasMainRadio) {
        return mainRadioIsChecked && currentIsChecked;
      }
      return currentIsChecked;
    };

    const handleChange = (evt) => {
      const ns = {
        [name]: evt.target.value,
      };
      this.props.setPlanState(ns);
      this.props.onFocus();
    };

    const showInput = () => {
      return (
        <input
          className="form-check-input"
          type="radio"
          name={name}
          id={cID}
          value={value}
          checked={isChecked()}
          onChange={handleChange}
          disabled={isDisabled()}
          data-testid={dataTestId}
        />
      );
    };

    return (
      <div className="ib-radio">
        {label ? (
          <label className={'form-check-label ' + (isDisabled() ? 'disabled' : '')}>
            {showInput()} {label}
          </label>
        ) : (
          showInput()
        )}
      </div>
    );
  };

  /**
   * Creates a checkbox component for anterior/posterior correction.
   * @param {Object} options - The options object.
   * @param {string} options.name - The name of the checkbox.
   * @param {string} options.dataTestIdPrefix - The prefix for the data-testid attribute.
   * @param {string} [options.label=''] - The label for the checkbox.
   * @param {boolean} [options.disabledEdit=false] - Whether the checkbox is disabled for editing.
   * @returns {JSX.Element} The checkbox component.
   */
  makeAnteriorPosteriorCorrectionCheckbox = ({ name, dataTestIdPrefix, label = '', disabledEdit = false }) => {
    const cID = name + '-' + label.toLocaleLowerCase().replace(' ', '_');

    const state = this.props;

    const mainRadioIsSelected = [state['anteriorPosteriorR'], state['anteriorPosteriorL']].includes('correction');

    const isDisabled = () => {
      return !mainRadioIsSelected || disabledEdit;
    };

    const isChecked = () => {
      const currentIsChecked = state['anteriorPosteriorCorrections'][name] === true;
      return mainRadioIsSelected && currentIsChecked;
    };

    const handleChange = (evt) => {
      const ns = {
        anteriorPosteriorCorrections: {
          [name]: evt.target.checked,
        },
      };
      this.props.setPlanState(ns);
      this.props.onFocus();
    };

    return (
      <div className="ib-checkbox">
        <label className={'form-check-label ' + (isDisabled() ? 'disabled' : '')}>
          <input
            className={'form-check-input ' + (isDisabled() ? 'disabled' : '')}
            type="checkbox"
            name={name}
            id={cID}
            checked={isChecked()}
            onChange={handleChange}
            disabled={isDisabled()}
            data-testid={dataTestIdPrefix}
          />{' '}
          {label}
        </label>
      </div>
    );
  };

  /**
   * Creates a notes box component with a text area input field.
   * @param {Object} options - The options object.
   * @param {string} options.stateKey - The key for the state value.
   * @param {string} options.value - The value for the text area input field.
   * @param {string} options.dataTestId - The data-testid for the text area input field.
   * @param {number} [options.nRows=4] - The number of rows for the text area input field.
   * @param {string} [options.placeholder=''] - The placeholder text for the text area input field.
   * @param {boolean} [options.disabled=false] - Whether the text area input field is disabled.
   * @returns {JSX.Element} The notes box component.
   */
  makeNotesBox = ({ stateKey, value, dataTestId, nRows = 4, placeholder = '', disabled = false }) => {
    const onChange = (evt) => {
      const value = removeEmoji(evt.target.value);
      const ns = {
        [stateKey]: textFieldCheck(value),
      };

      this.props.setPlanState(ns);
    };

    return (
      <div className="form-group">
        <TextArea id={stateKey} rows={nRows} value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} data-testid={dataTestId} />
      </div>
    );
  };

  render() {
    const tset = this.getImpliedTeethSets();

    const presetChoice = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, this.props.bracketTeeth, tset);
    });

    let presetMax = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, Teeth.All, tset);
    });

    presetMax = presetMax ? presetMax : this.maxPreset + 1;

    const makePresetButton = (n, dataTestIdPrefix = 'preset_button') => {
      return (
        <button
          key={n}
          type="button"
          onClick={() => this.setPreset(n)}
          className={'btn-ib ' + (n === presetChoice ? 'btn-ib--active ' : '') + (n > presetMax || this.props.disabledEdit ? 'btn-ib--disabled' : '')}
          disabled={this.props.disabledEdit}
          data-testid={`${dataTestIdPrefix}_${n}x${n}`}
        >
          {n}x{n}
        </button>
      );
    };

    const makeNotesBox = this.makeNotesBox.bind(this);

    return (
      <>
        {!this.props.hideAlert && (
          <AlertMessage
            message="Based on the complexity of the initial malocclusion, your case may require additional orthodontic auxiliaries and/or appliances for predictable and
          successful case completion."
            theme="info"
          />
        )}
        <div className="col-md-8">
          <QBox title_index="1" title="Missing/Previously extracted teeth" id={'missing_teeth'} dataTestId={'tx3_missing_teeth_box'}>
            <TeethSelector
              selected={this.props.missingTeeth}
              onClick={this.props.handleTeethClick('missingTeeth')}
              hasUpper={true}
              hasLower={true}
              disabledEdit={this.props.disabledEdit}
              dataTestIdPrefix="missing_teeth"
            />
          </QBox>

          <QBox title_index="2" title="Teeth to be extracted" anchor="to_be_extracted" id={'extract_teeth'} dataTestId={'tx3_extract_teeth_box'}>
            <TeethSelector
              selected={this.props.extractTeeth}
              onClick={this.props.handleTeethClick('extractTeeth')}
              disabled={tset.extractDisabled}
              disabledEdit={this.props.disabledEdit}
              hasUpper={true}
              hasLower={true}
              dataTestIdPrefix="extract_teeth"
            />
            {this.makeWarning(
              tset.extractTeeth.length > 0,
              'InBrace is best suited to close spaces up to 3.5mm. If the spaces are greater than 3.5mm, an InBrace representative will contact you.'
            )}
          </QBox>

          <QBox title_index="3" title="Arch to treat with InBrace" id="arch_to_treat" required={true} dataTestId={'tx3_arch_to_treat_box'}>
            {this.makeRadio({
              label: 'Both arches',
              name: 'archToTreat',
              value: 'both',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'arch_to_treat',
            })}
            {this.makeRadio({
              label: 'Upper arch',
              name: 'archToTreat',
              value: 'upper',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'arch_to_treat',
            })}
            <span className="opposing-arch flex-column">
              {this.makeRadio({
                label: 'Lower arch will be untreated',
                name: 'opposingUpperArch',
                value: 'current',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'upper',
                dataTestIdPrefix: 'opposing_upper_arch',
              })}
              {this.makeRadio({
                label: 'Lower arch will be treated with an alternate system',
                name: 'opposingUpperArch',
                value: 'ideal',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'upper',
                dataTestIdPrefix: 'opposing_upper_arch',
              })}
              {this.makeRadio({
                label: 'Lower arch will be added in Digital Enhancement',
                name: 'opposingUpperArch',
                value: 'digital',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'upper',
                dataTestIdPrefix: 'opposing_upper_arch',
              })}
            </span>

            {this.makeRadio({ label: 'Lower arch', name: 'archToTreat', value: 'lower', disabledEdit: this.props.disabledEdit })}
            <span className="opposing-arch flex-column">
              {this.makeRadio({
                label: 'Upper arch will be untreated',
                name: 'opposingLowerArch',
                value: 'current',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'lower',
                dataTestIdPrefix: 'opposing_lower_arch',
              })}
              {this.makeRadio({
                label: 'Upper arch will be treated with an alternate system',
                name: 'opposingLowerArch',
                value: 'ideal',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'lower',
                dataTestIdPrefix: 'opposing_lower_arch',
              })}
              {this.makeRadio({
                label: 'Upper arch will be added in Digital Enhancement',
                name: 'opposingLowerArch',
                value: 'digital',
                disabledEdit: this.props.disabledEdit,
                mainRadioName: 'archToTreat',
                mainRadioValue: 'lower',
                dataTestIdPrefix: 'opposing_lower_arch',
              })}
            </span>
          </QBox>

          <QBox title_index="4" title="Bracket placement" id="bracket_teeth" required="true" dataTestId={'tx3_bracket_teeth_box'}>
            <div className="bracket-preset">
              Presets:{' '}
              <div className="btn-bar" role="toolbar" id="bracket-presets">
                {_.range(this.minPreset, this.maxPreset + 1)
                  .reverse()
                  .map((n) => makePresetButton(n, 'bracket_preset'))}
              </div>
            </div>
            <TeethSelector
              selected={this.props.bracketTeeth}
              onClick={this.props.handleTeethClick('bracketTeeth')}
              disabled={_.union(tset.extractDisabled, ['UL8', 'LL8', 'UR8', 'LR8'], tset.extractTeeth)}
              disabledEdit={this.props.disabledEdit}
              hasUpper={tset.hasUpper}
              hasLower={tset.hasLower}
              dataTestIdPrefix="bracket_teeth"
            />
          </QBox>

          <QBox title_index="5" title="Do not move the following teeth" id={'restrict_teeth'} dataTestId={'tx3_restrict_teeth_box'}>
            <TeethSelector
              selected={_.filter(_.union(this.props.restrictTeeth, tset.restrictImplied), (value) => {
                return value.indexOf('8') === -1;
              })}
              onClick={this.props.handleTeethClick('restrictTeeth')}
              disabled={tset.restrictImplied}
              disabledEdit={this.props.disabledEdit}
              hasUpper={tset.hasUpper || tset.setupIdeal}
              hasLower={tset.hasLower || tset.setupIdeal}
              className="selection-green"
              dataTestIdPrefix="restrict_teeth"
            />
          </QBox>

          <QBox
            title_index="6"
            title="Anterior-Posterior"
            id={'anterior_posterior'}
            required={
              [this.props['anteriorPosteriorR'], this.props['anteriorPosteriorL']].includes('correction') ||
              [this.props['anteriorPosteriorR'], this.props['anteriorPosteriorL']].includes('other')
            }
            dataTestId={'tx3_anterior_posterior_box'}
          >
            <div className="anterior-posterior">
              <div className="anterior-posterior__radios">
                <div className="header">
                  <div className="header__c1"></div>
                  <div className="header__c2">R</div>
                  <div className="header__c3">L</div>
                </div>

                <div className="row1">
                  <div className="row1__c1">Maintain current</div>
                  <div className="row1__c2">
                    {this.makeRadio({
                      name: 'anteriorPosteriorR',
                      value: 'current',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_r',
                    })}
                  </div>
                  <div className="row1__c3">
                    {this.makeRadio({
                      name: 'anteriorPosteriorL',
                      value: 'current',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_l',
                    })}
                  </div>
                </div>

                <div className="row2">
                  <div className="row2__c1">Correction to Class I</div>
                  <div className="row2__c2">
                    {this.makeRadio({
                      name: 'anteriorPosteriorR',
                      value: 'correction',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_r',
                    })}
                  </div>
                  <div className="row2__c3">
                    {this.makeRadio({
                      name: 'anteriorPosteriorL',
                      value: 'correction',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_l',
                    })}
                  </div>
                </div>

                {[this.props['anteriorPosteriorR'], this.props['anteriorPosteriorL']].includes('correction') ? (
                  <div className="anterior-posterior__corrections-checkboxes">
                    {this.makeAnteriorPosteriorCorrectionCheckbox({
                      name: 'classIIOrIIICorrectionSimulation',
                      label: 'Class II/III correction simulation (elastics or auxiliaries required)',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'class_ii_or_iii_correction_simulation',
                    })}
                    {this.makeAnteriorPosteriorCorrectionCheckbox({
                      name: 'posteriorIPR',
                      label: 'Posterior IPR',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'posterior_ipr',
                    })}
                  </div>
                ) : null}

                <div className="row2">
                  <div className="row2__c1">Other (Please specify)</div>
                  <div className="row2__c2">
                    {this.makeRadio({
                      name: 'anteriorPosteriorR',
                      value: 'other',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_r',
                    })}
                  </div>
                  <div className="row2__c3">
                    {this.makeRadio({
                      name: 'anteriorPosteriorL',
                      value: 'other',
                      disabledEdit: this.props.disabledEdit,
                      dataTestIdPrefix: 'anterior_posterior_l',
                    })}
                  </div>
                </div>
              </div>

              {[this.props['anteriorPosteriorR'], this.props['anteriorPosteriorL']].includes('other') ? (
                <div className="anterior-posterior__corrections-checkboxes">
                  {makeNotesBox({
                    placeholder: 'Specify instructions for AP correction',
                    nRows: 4,
                    stateKey: 'anteriorPosteriorNotes',
                    value: this.props.anteriorPosteriorNotes,
                    disabled: this.props.disabledEdit,
                    dataTestId: 'anterior_posterior_notes',
                  })}
                </div>
              ) : null}
            </div>
          </QBox>

          <QBox title_index="7" title="Midlines" id="tx_midlines" dataTestId={'tx3_midlines_box'}>
            {this.makeRadio({
              label: 'Show resulting midlines after alignment',
              name: 'midlines',
              value: 'Show resulting midlines after alignment',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'midlines',
            })}
            {this.makeRadio({
              label: 'Improve midlines with IPR',
              name: 'midlines',
              value: 'Improve midlines with IPR',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'midlines',
            })}
            {this.makeRadio({
              label: 'Improve midlines with AP shift (elastics required)',
              name: 'midlines',
              value: 'Improve midlines with AP shift (elastics required)',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'midlines',
            })}
            {this.makeRadio({
              label: 'Improve midlines with IPR and AP shift',
              name: 'midlines',
              value: 'Improve midlines with IPR and AP shift',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'midlines',
            })}
          </QBox>

          <QBox
            title_index="8"
            title="Will auxiliaries be used to treat this case (e.g. buttons and elastics, labial segment of traditional braces, Carriere® Motion appliance, etc.)?"
            titleClassName="tx-de-3-auxiliaries-title"
            id="tx_auxiliaries"
            dataTestId={'tx3_auxiliaries'}
            required={this.props['auxiliariesWillBeUsed'] === 'Yes'}
          >
            {this.makeRadio({
              label: 'No',
              name: 'auxiliariesWillBeUsed',
              value: 'No',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'auxiliaries_will_be_used',
            })}
            {this.makeRadio({
              label: 'Yes',
              name: 'auxiliariesWillBeUsed',
              value: 'Yes',
              disabledEdit: this.props.disabledEdit,
              dataTestIdPrefix: 'auxiliaries_will_be_used',
            })}
            {this.props['auxiliariesWillBeUsed'] === 'Yes'
              ? makeNotesBox({
                  placeholder: 'Specify planned auxiliaries',
                  nRows: 4,
                  stateKey: 'auxiliariesNotes',
                  value: this.props.auxiliariesNotes,
                  disabled: this.props.disabledEdit,
                  dataTestId: 'auxiliaries_notes',
                })
              : null}
          </QBox>

          <QBox title_index="9" title="Specify treatment goals and any additional instructions" id="tx_notes" dataTestId={'tx3_notes_box3'}>
            {makeNotesBox({
              placeholder: 'Describe your treatment goals and any additional instructions',
              nRows: 4,
              stateKey: 'notes',
              value: this.props.notes,
              disabled: this.props.disabledEdit,
              dataTestId: 'notes',
            })}
          </QBox>
        </div>
        <div className="col-md-4 tr-viewer-container-parent">
          <div className="tr-viewer-container">
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                const has_permission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
                return (
                  <RecordViewer
                    records={this.props.record_states}
                    onLoad={this.props.onLoadImageRecord}
                    onClick={(id, action) => {
                      onImageToolAction(id, action, this.props);
                    }}
                    disabled={!has_permission || this.props.disabledEdit}
                  >
                    <ToothViewer teethSets={tset} thumbs={this.state.thumbs} />
                  </RecordViewer>
                );
              }}
            </UserPermissionsContext.Consumer>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TxPlan3));
