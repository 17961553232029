import Axios from 'axios';
import { fetchDdmState } from './ddm_state';

export const OPEN_SPECS_FILE_MODAL = 'OPEN_SPECS_FILE_MODAL';
export const CLOSE_SPECS_FILE_MODAL = 'CLOSE_SPECS_FILE_MODAL';
export const OPEN_DESIGN_MANUAL_MODAL = 'OPEN_DESIGN_MANUAL_MODAL';
export const OPEN_SPECS_MANUAL_MODAL = 'OPEN_SPECS_MANUAL_MODAL';
export const RETURN_TO_DDM_PENDING = 'RETURN_TO_DDM_PENDING';
export const RETURN_TO_DDM_SUCCESS = 'RETURN_TO_DDM_SUCCESS';
export const RETURN_TO_DDM_ERROR = 'RETURN_TO_DDM_ERROR';
export const CLOSE_SPECS_MANUAL_MODAL = 'CLOSE_SPECS_MANUAL_MODAL';
export const CLOSE_DESIGN_MANUAL_MODAL = 'CLOSE_DESIGN_MANUAL_MODAL';
export const DDM_MANUAL_UPLOAD_PENDING = 'DDM_MANUAL_UPLOAD_PENDING';
export const DDM_MANUAL_UPLOAD_SUCCESS = 'DDM_MANUAL_UPLOAD_SUCCESS';
export const DDM_MANUAL_UPLOAD_ERROR = 'DDM_MANUAL_UPLOAD_ERROR';
export const REMOVE_SPECS_MANUAL_MODAL = 'REMOVE_SPECS_MANUAL_MODAL';
export const REMOVE_DESIGN_MANUAL_MODAL = 'REMOVE_DESIGN_MANUAL_MODAL';
export const CLOSE_REMOVE_SPECS_MODAL = 'CLOSE_REMOVE_SPECS_MODAL';
export const CLOSE_REMOVE_DESIGN_MODAL = 'CLOSE_REMOVE_DESIGN_MODAL';

export const REMOVE_SPECS_FILE_PENDING = 'REMOVE_SPECS_FILE_PENDING';
export const REMOVE_SPECS_FILE_SUCCESS = 'REMOVE_SPECS_FILE_SUCCESS';
export const REMOVE_SPECS_FILE_ERROR = 'REMOVE_SPECS_FILE_ERROR';
export const REMOVE_DESIGN_FILE_PENDING = 'REMOVE_DESIGN_FILE_PENDING';
export const REMOVE_DESIGN_FILE_SUCCESS = 'REMOVE_DESIGN_FILE_SUCCESS';
export const REMOVE_DESIGN_FILE_ERROR = 'REMOVE_DESIGN_FILE_ERROR';

export function openSpecsFileModal() {
  return {
    type: OPEN_SPECS_FILE_MODAL,
  };
}
export function closeSpecsFileModal() {
  return {
    type: CLOSE_SPECS_FILE_MODAL,
  };
}
export function openDesignManualUploadModal() {
  return {
    type: OPEN_DESIGN_MANUAL_MODAL,
  };
}
export function closeDesignManualUploadModal() {
  return {
    type: CLOSE_DESIGN_MANUAL_MODAL,
  };
}
export function openSpecsManualUploadModal() {
  return {
    type: OPEN_SPECS_MANUAL_MODAL,
  };
}
export function closeSpecsManualUploadModal() {
  return {
    type: CLOSE_SPECS_MANUAL_MODAL,
  };
}
export function returnPending() {
  return {
    type: RETURN_TO_DDM_PENDING,
  };
}
export function returnSuccess() {
  return {
    type: RETURN_TO_DDM_SUCCESS,
  };
}
export function returnError(error) {
  return {
    type: RETURN_TO_DDM_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

/**
 * Returns back to DDM design
 * @function
 * @param {function} reload_information - Reload case information function
 * @returns {object} - Action type
 */
export function onReturnClick(reload_information) {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(returnPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/return_to_upload`)
      .then((res) => {
        dispatch(returnSuccess());
        dispatch(fetchDdmState(case_id));
        reload_information();
      })
      .catch((error) => {
        dispatch(returnError(error));
      });
  };
}

export function onManualUpload(data) {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(manualUploadPending());

    if (data && data[0] && (data[0].folder === 'specs_manual' || data[0].folder === 'design_manual')) {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      const action = data[0].folder === 'specs_manual' ? 'upload_specs' : 'upload_design';

      Axios.post(`/apiV2/cadaction/${case_id}/ddm/${action}`, form_data)
        .then((res) => {
          dispatch(manualUploadSuccess());
          dispatch(fetchDdmState(case_id));
          if (action === 'upload_design') {
            dispatch(closeDesignManualUploadModal());
          }
        })
        .catch((error) => {
          dispatch(manualUploadError(error));
        });
    }
  };
}
export function manualUploadPending() {
  return {
    type: DDM_MANUAL_UPLOAD_PENDING,
  };
}
export function manualUploadSuccess() {
  return {
    type: DDM_MANUAL_UPLOAD_SUCCESS,
  };
}

export function manualUploadError(error) {
  return {
    type: DDM_MANUAL_UPLOAD_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
export function removeSpecsManualFileModal() {
  return {
    type: REMOVE_SPECS_MANUAL_MODAL,
  };
}
export function closeRemoveSpecsFileModal() {
  return {
    type: CLOSE_REMOVE_SPECS_MODAL,
  };
}
export function removeDesignManualFileModal() {
  return {
    type: REMOVE_DESIGN_MANUAL_MODAL,
  };
}
export function closeRemoveDesignFileModal() {
  return {
    type: CLOSE_REMOVE_DESIGN_MODAL,
  };
}
export function onConfirmRemoveSpecs() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;
    const action = ddm_state.specs_file ? 'remove_specs' : 'remove_specs_manual';

    dispatch(removeSpecsFilePending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ddm/${action}`)
      .then((res) => {
        dispatch(removeSpecsFileSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(closeRemoveSpecsFileModal());
      })
      .catch((error) => {
        dispatch(closeRemoveSpecsFileModal());
        dispatch(removeSpecsFileError(error));
      });
  };
}
export function removeSpecsFilePending() {
  return {
    type: REMOVE_SPECS_FILE_PENDING,
  };
}
export function removeSpecsFileSuccess() {
  return {
    type: REMOVE_SPECS_FILE_SUCCESS,
  };
}
export function removeSpecsFileError(error) {
  return {
    type: REMOVE_SPECS_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
export function onConfirmRemoveDesign() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    const action = ddm_state.design_file ? 'remove_design' : 'remove_design_manual';
    dispatch(removeDesignFilePending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ddm/${action}`)
      .then((res) => {
        dispatch(removeDesignFileSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(closeRemoveDesignFileModal());
      })
      .catch((error) => {
        dispatch(closeRemoveDesignFileModal());
        dispatch(removeDesignFileError(error));
      });
  };
}
export function removeDesignFilePending() {
  return {
    type: REMOVE_DESIGN_FILE_PENDING,
  };
}
export function removeDesignFileSuccess() {
  return {
    type: REMOVE_DESIGN_FILE_SUCCESS,
  };
}
export function removeDesignFileError(error) {
  return {
    type: REMOVE_DESIGN_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
