import React from 'react';
import _ from 'lodash';


const referralCategory = 'How did the patient learn about InBrace?*'
const previousTxCategory = 'Did the patient receive any previous orthodontic treatment?*'

/**
 * Get the display order of category
 *
 * @param {string} category - Question category
 * @returns {number} - Display order of category
 */
function getCategoryDisplayOrder(category) {
  switch (category) {
    case previousTxCategory:
      return 2;
    case referralCategory:
      return 1;
    default:
      return 0;
  }
}

/**
 * Get all question categories from question data
 *
 * @param {Object} questions - Object contains attribution question data
 * @return {Array} Array of strings of questions
 * @function
 */
function getAllQuestionCategories(questions) {
  const categories = [...new Set(questions.map((q) => q.category))].sort((a, b) => getCategoryDisplayOrder(a) - getCategoryDisplayOrder(b));
  return categories;
}

/**
 * Sort question array by id
 *
 * @param {Object} questions - Object contains attribution question data
 * @return {Array} Array of strings of questions
 * @function
 */
function OrderQuestionsById(questions) {
  const ordered_questions = questions.sort((a, b) => a.id - b.id);
  return ordered_questions;
}

/**
 * Get question value as id
 *
 * @param {Object} question - Object contains attribution question data
 * @return {String} Question value in id formate
 * @function
 */
function getQuestionValueAsID(question) {
  if (question && question.question_value) {
    return question.question_value.toLocaleLowerCase().replace(' ', '_');
  } else {
    return '';
  }
}

/**
 * Display text input for attribution other
 *
 * @param {Object} question - Current question object
 * @param {Boolean} checked - If current question is checked
 * @param {String} category - Current question category
 * @param {Object} props - Props
 * @return {JSX} Element for input box
 * @function
 */
function displayTextInput(question, checked, category, props) {
  const current_category = props.attributions.find((att) => att.question_category === category);
  const has_attribution_other_error = current_category ? current_category.attribution_other_error : false;
  const text_value = current_category ? current_category.other_response : '';
  const display = question.text_field && checked;
  const other_input = (
    <input
      className={
        has_attribution_other_error ? 'warning-border form-control patient-field other_input text-input' : 'form-control patient-field other_input text-input'
      }
      id={getQuestionValueAsID(question)}
      type="text"
      maxLength="30"
      value={text_value ? text_value : ''}
      category={category}
      onChange={props.onAttributionOtherChange}
    />
  );
  return display ? other_input : null;
}

/**
 * Displays attribution question radio btns
 * @param {Object} questions - Object contains attribution question data
 * @param {Object} props - Props
 * @param {String} category - Current question category
 * @return {Array} Array of strings of questions
 * @function
 */
function displayQuestions(questions, props, category) {
  return questions.map((question) => {
    if (question.category === category) {
      const current_category = props.attributions.find((att) => att.question_category === category);
      const checked_attributions = current_category ? current_category.question_response : [];
      const has_attribution_error = current_category ? current_category.attributions_error : false;
      const checked = _.intersection([question.question_value], checked_attributions).length > 0;
      const disable = _.intersection(['None'], checked_attributions).length > 0 && question.category === category && question.question_value !== 'None';

      return (
        <div key={getQuestionValueAsID(question)}>
          <div>
            <label>
              <input
                className={has_attribution_error ? 'inline-input case_attribute_checkbox checkbox_warning-border' : 'inline-input case_attribute_checkbox'}
                name="attribution"
                type="checkbox"
                value={question.question_value}
                category={category}
                category_release_date={question.release_date}
                checked={checked}
                onChange={props.onAttributionsChange}
                disabled={props.is_attribution_allowed === false || disable || props.disabledEdit}
              />
              <span>{question.question_label}</span>
            </label>
          </div>
          {displayTextInput(question, checked, category, props)}
        </div>
      );
    }
    return null;
  });
}

/**
 * Get attribution label classes
 * @param {String} category Current attribution question category
 * @param {Object} props Props
 * @param {Boolean} category_required  Is current category required
 * @return {String} Classes for attribution label
 * @function
 */
function getLabelClass(category, props, category_required) {
  const current_category = props.attributions.find((att) => att.question_category === category);
  const has_attribution_error = current_category ? current_category.attributions_error || current_category.attribution_other_error : false;
  let classes = 'attribute_header medium';
  if (has_attribution_error) {
    classes += ' warning-text';
  }
  return classes;
}

/**
 * Display attribution questions
 * @function
 */
function AttributionQuestions(props) {
  const questions = OrderQuestionsById(props.questions);
  const question_categories = getAllQuestionCategories(questions);
  let current_category = {};
  let category_required = true;

  return (
    <div>
      <p>
        We'd love your help. We want to continue to invest in marketing activities to bring you new patients. Can you click any and all the ways the patient
        learned about InBrace.
      </p>
      {question_categories.map((category, index) => {
        current_category = props.attributions.find((att) => att.question_category === category);
        category_required = current_category ? current_category.required : false;
        return (
          <div key={index}>
            <label key={index * 10} className={getLabelClass(category, props, category_required)}>
              {category_required ? category : category.slice(0, -1)} (Select all that apply)
            </label>
            {displayQuestions(questions, props, category)}
          </div>
        );
      })}
    </div>
  );
}

export default AttributionQuestions;
