import _ from 'lodash';
import React, { Component } from 'react';

import Thumbnail from './thumbnail';
import { getTempLink } from '../../common/dropbox';

class ThumbnailURLInterface extends Component {
  static defaultProps = {
    rebuildOnFilePropsChange: false,
    isInSubmitSummary: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: props.files,
      last_index: -1,
      rebuild: false,
      file_processed_count: 0,
    };
  }
  /**
   * Builds the file url entry for stl
   * @function
   */
  buildFileURL = () => {
    const { files } = this.props;
    let scan_counter = 0;

    files.map((file, index) => {
      const has_attributes = file && file.original_filename;

      if (has_attributes) {
        scan_counter += 1;
        this.fetchFileURL(file);
      }

      return file;
    });

    this.setState({
      last_index: scan_counter,
    });
  };
  /**
   * fetches an temporary link and to be store
   * @function
   * @param {Object} file - file object
   */
  fetchFileURL = (file) => {
    let that = this;
    Promise.resolve(getTempLink(file.original_filename, file.upload_data)).then((url) => {
      that.updateFilesState(file, 'file_url', url);
      that.runRebuild();
    });
  };

  /**
   * If all files are processed, reloads thumbnail
   * @function
   */
  runRebuild = () => {
    if (this.state.file_processed_count >= this.state.last_index) {
      if (this.props.hide_occlusion) {
        this.setState({ rebuild: true });
      } else {
        const files = this.displayOcclusionView();
        this.setState({ rebuild: true, files: files });
      }
    }
  };

  /**
   * Updates submission files with Occlusion view record
   *
   * @function
   * @return {Array} Returns updated file list
   */
  displayOcclusionView() {
    const { files } = this.state;
    let scan_url_count = [];
    let last_index = 0;
    files.map((file, index) => {
      if (file.file_url && ['scans', 'iteroScans'].includes(file.file_type)) {
        scan_url_count.push(file.file_url);
      }
      last_index = Math.max(last_index, index);
      return file;
    });

    if (scan_url_count.length === 2) {
      files.push({
        id: last_index + 1,
        file_type: 'multi_scans',
        upload_data: scan_url_count[1],
        original_filename: 'occlusion_view',
        created_date: '',
        created_by_id: '',
        file_url: scan_url_count[0],
      });
    }
    return files;
  }

  /**
   * fetches an temporary link and to be store
   * @function
   * @param {Object} fetch_file - file object
   * @param {String} key - key to be replaced with url in file object
   * @param {String} url - Value of the stream url link
   */
  updateFilesState = (fetch_file, key, url) => {
    const { files, file_processed_count } = this.state;
    files.map((file) => {
      if (this.props.isInSubmitSummary && file.file_type === 'scans') {
        return file;
      }

      if ([file, fetch_file].every((file) => file.file_type === 'progress_records')) {
        if ([file, fetch_file].every((file) => file.id)) {
          if (file.id === fetch_file.id) {
            file[key] = url;
          }
        } else if ([file, fetch_file].every((file) => file.original_filename)) {
          if (file.original_filename === fetch_file.original_filename) {
            file[key] = url;
          }
        }
      } else if (file.id === fetch_file.id) {
        file[key] = url;
      }

      return file;
    });

    const update_files = _.cloneDeep(files);
    this.setState({
      files: update_files,
      file_processed_count: file_processed_count + 1,
    });
  };
  /**
   * Turns off rebuild functionality
   * @function
   */
  disabledRebuild = () => {
    this.setState({
      rebuild: false,
    });
  };

  componentDidMount() {
    this.buildFileURL();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rebuildOnFilePropsChange) {
      const newFiles = this.props.files.filter((file) => !prevProps.files.includes(file));
      if (newFiles.length > 0) {
        const fetchUrlPromises = this.props.files.map((file) => {
          return getTempLink(file.original_filename, file.upload_data)
            .then((url) => {
              if (file.file_type !== 'scans') {
                file.file_url = url;
              }
              return file;
            })
            .catch(() => file);
        });

        Promise.all(fetchUrlPromises).then((filesWithUrl) => {
          const scansFiles = filesWithUrl.filter((file) => file.file_url && ['scans', 'iteroScans'].includes(file.file_type));
          if (scansFiles.length === 2) {
            filesWithUrl.push({
              id: filesWithUrl.length,
              file_type: 'multi_scans',
              upload_data: scansFiles[1].file_url,
              original_filename: 'occlusion_view',
              created_date: '',
              created_by_id: '',
              file_url: scansFiles[0].file_url,
            });
          }

          this.setState({
            files: filesWithUrl,
            rebuild: true,
          });
        });
      }
    }
  }

  render() {
    return <Thumbnail {...this.props} files={this.state.files} rebuild={this.state.rebuild} disabledRebuild={this.disabledRebuild} />;
  }
}

export default ThumbnailURLInterface;
