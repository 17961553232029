import React from 'react';
import Btn from '../../components/button/teeth_button';
import './dxtx_plan.scss';

class DxtxForm extends React.Component {
  componentDidMount() {
    let that = this;
    setTimeout(function() {
      that.setTreatmentArch();
    }, 0);
  }

  componentDidUpdate() {
    this.setTreatmentArch();
  }

  setTreatmentArch() {
    let upper = document.querySelectorAll('.upper-set');
    let lower = document.querySelectorAll('.lower-set');

    //Nodelist
    for (let i = 0; i < upper.length; i++) {
      upper[i].classList.add('show');

      // if (this.props.dxtxSection2_toothUpper) {
      //   upper[i].classList.add('show');
      //   upper[i].classList.remove('hide');
      // } else {
      //   upper[i].classList.remove('show');
      //   upper[i].classList.add('hide');
      // }
    }

    for (let i = 0; i < lower.length; i++) {
      lower[i].classList.add('show');

      // if (this.props.dxtxSection2_toothLower) {
      //   lower[i].classList.add('show');
      //   lower[i].classList.remove('hide');
      // } else {
      //   lower[i].classList.remove('show');
      //   lower[i].classList.add('hide');
      // }
    }
  }

  render() {
    return (
      <div className="portal-view">
        <form>
          <div id="chief" className="row">
            <div className="col-md-12">
              <label className="dxtx-heading" id="patientChiefComplaintLabel">
                Patient Chief Complaint *
              </label>
            </div>
            <div className="col-md-12">
              <textarea
                className="form-control dxtx-comment"
                placeholder="Enter comments here"
                id="patientChiefComplaint"
                rows="4"
                onChange={this.props.onInputChange_dxtxSection0_chiefComplaint}
                value={this.props.dxtxSection0_chiefComplaint}
                onFocus={this.props.onFocus}
              />
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">I. Diagnosis</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-6 col-form-label">
                  <span className="form-check-label mm-label" htmlFor="midline-right">
                    Mx1 exposure at rest
                  </span>
                  <input
                    type="text"
                    className="form-control inline-block mm-size"
                    onChange={this.props.onInputChange_dxtxSection1_mx}
                    value={this.props.dxtxSection1_mx}
                  />
                  <span> mm</span>
                </div>
                <div className="col-md-6 col-form-label">
                  <span className="form-check-label mm-label" htmlFor="midline-right">
                    Gingival exposure smiling
                  </span>
                  <input
                    type="text"
                    className="form-control inline-block mm-size"
                    onChange={this.props.onInputChange_dxtxSection1_gingival}
                    value={this.props.dxtxSection1_gingival}
                  />
                  <span> mm</span>
                </div>
              </div>
            </div>

            <div className="col-md-12 row-padding-t1">
              <label className="dxtx-heading">Upper midline to facial midline:</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-4 col-form-label">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline"
                      id="midline-correct"
                      value="correct"
                      onChange={this.props.onInputChange_dxtxSection1_upperMidline2Facial}
                      checked={this.props.dxtxSection1_upperMidline2Facial === 'correct'}
                    />
                    <label className="form-check-label" htmlFor="midline-correct">
                      Correct
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline"
                      id="midline-right"
                      value="midline-right"
                      onChange={this.props.onInputChange_dxtxSection1_upperMidline2Facial}
                      checked={this.props.dxtxSection1_upperMidline2Facial === 'midline-right'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-right">
                      Deviated to right
                    </label>
                    <input
                      type="text"
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection1_upperMidline2FacialRight}
                      value={this.props.dxtxSection1_upperMidline2FacialRight}
                      disabled={this.props.dxtxSection1_upperMidline2Facial !== 'midline-right'}
                    />
                    <span> mm</span>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline"
                      id="midline-left"
                      value="midline-left"
                      onChange={this.props.onInputChange_dxtxSection1_upperMidline2Facial}
                      checked={this.props.dxtxSection1_upperMidline2Facial === 'midline-left'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-left">
                      Deviated to left
                    </label>
                    <input
                      type="text"
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection1_upperMidline2FacialLeft}
                      value={this.props.dxtxSection1_upperMidline2FacialLeft}
                      disabled={this.props.dxtxSection1_upperMidline2Facial !== 'midline-left'}
                    />
                    <span> mm</span>
                  </div>
                </div>

                <div className="col-md-6 row-padding-t1">
                  <label htmlFor="right-molars">Right Molars:</label>
                  <div id="right-molars">
                    <div className="btn-group" role="group" aria-label="...">
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightMolars === 'Class I' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightMolars}
                      >
                        Class I
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightMolars === 'Class II' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightMolars}
                      >
                        Class II
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightMolars === 'Class III' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightMolars}
                      >
                        Class III
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 row-padding-t1">
                  <label htmlFor="right-canine">Right Canine:</label>
                  <div id="right-canine">
                    <div className="btn-group" role="group" aria-label="...">
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightCanine === 'Class I' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightCanine}
                      >
                        Class I
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightCanine === 'Class II' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightCanine}
                      >
                        Class II
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_rightCanine === 'Class III' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_rightCanine}
                      >
                        Class III
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 row-padding-t1">
                  <label htmlFor="left-molars">Left Molars:</label>
                  <div id="left-molars">
                    <div className="btn-group" role="group" aria-label="...">
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftMolars === 'Class I' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftMolars}
                      >
                        Class I
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftMolars === 'Class II' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftMolars}
                      >
                        Class II
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftMolars === 'Class III' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftMolars}
                      >
                        Class III
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 row-padding-t1">
                  <label htmlFor="left-canine">Left Canine:</label>
                  <div id="left-canine">
                    <div className="btn-group" role="group" aria-label="...">
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftCanine === 'Class I' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftCanine}
                      >
                        Class I
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftCanine === 'Class II' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftCanine}
                      >
                        Class II
                      </button>
                      <button
                        type="button"
                        className={this.props.dxtxSection1_leftCanine === 'Class III' ? 'btn btn-default active-selection' : 'btn btn-default'}
                        onClick={this.props.onInputChange_dxtxSection1_leftCanine}
                      >
                        Class III
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 row-padding-t1">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Overjet"
                    defaultChecked={this.props.dxtxSection1_overjet}
                    onChange={this.props.onInputChange_dxtxSection1_overjet}
                  />{' '}
                  <span className="dxtx-padding-l1">Overjet</span>
                </div>
                <div className="col-md-4 row-padding-t1">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Anterior cross bite"
                    defaultChecked={this.props.dxtxSection1_anterior}
                    onChange={this.props.onInputChange_dxtxSection1_anterior}
                  />{' '}
                  <span className="dxtx-padding-l1">Anterior Cross Bite</span>
                </div>
                <div className="col-md-4 row-padding-t1">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Deep bite"
                    defaultChecked={this.props.dxtxSection1_deep}
                    onChange={this.props.onInputChange_dxtxSection1_deep}
                  />{' '}
                  <span className="dxtx-padding-l1">Deep Bite</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Narrow arch"
                    defaultChecked={this.props.dxtxSection1_narrow}
                    onChange={this.props.onInputChange_dxtxSection1_narrow}
                  />{' '}
                  <span className="dxtx-padding-l1">Narrow Arch</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Posterior cross bite"
                    defaultChecked={this.props.dxtxSection1_posterior}
                    onChange={this.props.onInputChange_dxtxSection1_posterior}
                  />{' '}
                  <span className="dxtx-padding-l1">Posterior Cross Bite</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Open Bite"
                    defaultChecked={this.props.dxtxSection1_class}
                    onChange={this.props.onInputChange_dxtxSection1_class}
                  />{' '}
                  <span className="dxtx-padding-l1">Open Bite</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Crowding"
                    defaultChecked={this.props.dxtxSection1_crowding}
                    onChange={this.props.onInputChange_dxtxSection1_crowding}
                  />{' '}
                  <span className="dxtx-padding-l1">Crowding</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Spaces"
                    defaultChecked={this.props.dxtxSection1_spaces}
                    onChange={this.props.onInputChange_dxtxSection1_spaces}
                  />{' '}
                  <span className="dxtx-padding-l1">Spaces</span>
                </div>
                <div className="col-md-4">
                  <input
                    type="checkbox"
                    name="diagnosis"
                    value="Flared teeth"
                    defaultChecked={this.props.dxtxSection1_flared}
                    onChange={this.props.onInputChange_dxtxSection1_flared}
                  />{' '}
                  <span className="dxtx-padding-l1">Flared Teeth</span>
                </div>

                <div className="col-md-12 row-padding-t1">
                  <textarea
                    className="form-control dxtx-comment"
                    id="diagnosis-general-comment"
                    placeholder="Other and general notes enter here"
                    rows="4"
                    value={this.props.dxtxSection1_notes}
                    onChange={this.props.onInputChange_dxtxSection1_notes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">II. Tx Planning</label>
            </div>
            <div id="arch" className="col-md-12">
              <label className="dxtx-heading" id="archToTreatLabel">
                1. Arch to Treat: *{' '}
              </label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box" id="archToTreat" onFocus={this.props.onFocus}>
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="archTreatment"
                      id="tx-both"
                      value="Both"
                      onChange={this.props.onInputChange_dxtxSection2_arch}
                      defaultChecked={this.props.dxtxSection2_arch === 'Both'}
                    />
                    <label className="form-check-label" htmlFor="tx-both">
                      Both
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="archTreatment"
                      id="tx-upper"
                      value="Upper"
                      onChange={this.props.onInputChange_dxtxSection2_arch}
                      defaultChecked={this.props.dxtxSection2_arch === 'Upper'}
                    />
                    <label className="form-check-label" htmlFor="tx-upper">
                      Upper (Diagnosis model on opposite arch required)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="archTreatment"
                      id="tx-lower"
                      value="Lower"
                      onChange={this.props.onInputChange_dxtxSection2_arch}
                      defaultChecked={this.props.dxtxSection2_arch === 'Lower'}
                    />
                    <label className="form-check-label" htmlFor="tx-lower">
                      Lower (Diagnosis model on opposite arch required)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div id="teeth" className="col-md-12">
              <label className="dxtx-heading" id="teethToTreatLabel">
                2. Teeth to Treat: *{' '}
              </label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box" id="teethToTreat" onFocus={this.props.onFocus}>
                <div className="col-sm-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="teethTreatment"
                      id="teeth-5"
                      value="5x5"
                      onChange={this.props.onInputChange_dxtxSection2_teeth}
                      defaultChecked={this.props.dxtxSection2_teeth === '5x5'}
                    />
                    <label className="form-check-label" htmlFor="teeth-5">
                      5x5 arch
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="teethTreatment"
                      id="teeth-6"
                      value="6x6"
                      onChange={this.props.onInputChange_dxtxSection2_teeth}
                      defaultChecked={this.props.dxtxSection2_teeth === '6x6'}
                    />
                    <label className="form-check-label" htmlFor="teeth-6">
                      6x6 arch
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="teethTreatment"
                      id="teeth-7"
                      value="7x7"
                      onChange={this.props.onInputChange_dxtxSection2_teeth}
                      defaultChecked={this.props.dxtxSection2_teeth === '7x7'}
                    />
                    <label className="form-check-label" htmlFor="teeth-7">
                      7x7 arch
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="teethTreatment"
                      id="teeth-custom"
                      value="customxcustom"
                      onChange={this.props.onInputChange_dxtxSection2_teeth}
                      defaultChecked={this.props.dxtxSection2_teeth === 'customxcustom'}
                    />
                    <label className="form-check-label" htmlFor="teeth-custom">
                      Specify location
                      <div className="inline-input">
                        <input
                          type="text"
                          className="form-control"
                          id="teethLocation"
                          onChange={this.props.onInputChange_dxtxSection2_teethCustom}
                          value={this.props.dxtxSection2_teethCustom}
                          disabled={this.props.dxtxSection2_teeth !== 'customxcustom'}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">3. Anterior-Posterior (A-P) Relationship:</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-4">Maintain:</div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="maintain-left"
                    value="maintain-left"
                    defaultChecked={this.props.dxtxSection2_anteriorMaintainLeft}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorMaintainLeft}
                  />{' '}
                  <span className="dxtx-padding-l1">Left</span>
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="maintain-right"
                    value="maintain-right"
                    defaultChecked={this.props.dxtxSection2_anteriorMaintainRight}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorMaintainRight}
                  />{' '}
                  <span className="dxtx-padding-l1">Right</span>
                </div>
                <div className="col-md-4">Improve Canine relationship only:</div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="improve-left"
                    value="Improve Canine relationship left"
                    defaultChecked={this.props.dxtxSection2_anteriorImproveLeft}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorImproveLeft}
                  />{' '}
                  <span className="dxtx-padding-l1">Left</span>
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="imporve-right"
                    value="Improve Canine relationship right"
                    defaultChecked={this.props.dxtxSection2_anteriorImproveRight}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorImproveRight}
                  />{' '}
                  <span className="dxtx-padding-l1">Right</span>
                </div>
                <div className="col-md-4">Correction to Canine and Molar Class I:</div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="correct-left"
                    value="Correction to Canine and Molar Class I left"
                    defaultChecked={this.props.dxtxSection2_anteriorCorrectLeft}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorCorrectLeft}
                  />{' '}
                  <span className="dxtx-padding-l1">Left</span>
                </div>
                <div className="col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="correct-right"
                    value="Correction to Canine and Molar Class I right"
                    defaultChecked={this.props.dxtxSection2_anteriorCorrectRight}
                    onChange={this.props.onInputChange_dxtxSection2_anteriorCorrectRight}
                  />{' '}
                  <span className="dxtx-padding-l1">Right</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">4. Tooth Movement Restrictions (Bridges, ankylosed teeth, implants, etc.):</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12 div-padding-b1">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="toothRestriction"
                      id="restrictions-none"
                      value="None (Move all the teeth)"
                      onChange={this.props.onInputChange_dxtxSection2_toothMovement}
                      defaultChecked={this.props.dxtxSection2_toothMovement === 'None (Move all the teeth)'}
                    />
                    <label className="form-check-label" htmlFor="restrictions-none">
                      None (Move all the teeth)
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="toothRestriction"
                      id="restrictions-best"
                      value="These specific teeth should not be moved"
                      onChange={this.props.onInputChange_dxtxSection2_toothMovement}
                      defaultChecked={this.props.dxtxSection2_toothMovement === 'These specific teeth should not be moved'}
                    />
                    <label className="form-check-label" htmlFor="restrictions-best">
                      These specific teeth should not be moved
                    </label>
                  </div>
                </div>

                {this.props.dxtxSection2_toothMovement !== 'None (Move all the teeth)' ? (
                  <div className="col-md-6 collapse upper-set">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Btn
                        btname="UR8"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR7"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR6"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR5"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR4"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR3"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR2"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UR1"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                    </div>
                  </div>
                ) : null}

                {this.props.dxtxSection2_toothMovement !== 'None (Move all the teeth)' ? (
                  <div className="col-md-6 collapse upper-set">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Btn
                        btname="UL1"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL2"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL3"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL4"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL5"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL6"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL7"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="UL8"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                    </div>
                  </div>
                ) : null}

                {this.props.dxtxSection2_toothMovement !== 'None (Move all the teeth)' ? (
                  <div className="col-md-6 collapse lower-set">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Btn
                        btname="LR8"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR7"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR6"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR5"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR4"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR3"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR2"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LR1"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                    </div>
                  </div>
                ) : null}

                {this.props.dxtxSection2_toothMovement !== 'None (Move all the teeth)' ? (
                  <div className="col-md-6 collapse lower-set">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Btn
                        btname="LL1"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL2"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL3"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL4"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL5"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL6"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL7"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                      <Btn
                        btname="LL8"
                        onBtnClick={this.props.onInputChange_dxtxSection2_toothMovementSelection}
                        btnState={this.props.dxtxSection2_toothMovementSelection}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">5. Extraction Table:</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-6 collapse upper-set">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Btn
                      btname="UR8"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR7"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR6"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR5"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR4"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR3"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR2"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UR1"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                  </div>
                </div>
                <div className="col-md-6 collapse upper-set">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Btn
                      btname="UL1"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL2"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL3"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL4"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL5"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL6"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL7"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="UL8"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                  </div>
                </div>

                <div className="col-md-6 collapse lower-set">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Btn
                      btname="LR8"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR7"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR6"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR5"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR4"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR3"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR2"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LR1"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                  </div>
                </div>
                <div className="col-md-6 collapse lower-set">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Btn
                      btname="LL1"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL2"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL3"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL4"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL5"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL6"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL7"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                    <Btn
                      btname="LL8"
                      onBtnClick={this.props.onInputChange_dxtxSection2_toothExtractionSelection}
                      btnState={this.props.dxtxSection2_toothExtractionSelection}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">6. Overjet:</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overjet"
                      id="overject-maintain"
                      value="Maintain initial overjet"
                      onChange={this.props.onInputChange_dxtxSection2_overjet}
                      defaultChecked={this.props.dxtxSection2_overjet === 'Maintain initial overjet'}
                    />
                    <label className="form-check-label" htmlFor="overject-maintain">
                      Maintain initial overjet
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overjet"
                      id="overjet-improve"
                      value="Improve resulting overjet"
                      onChange={this.props.onInputChange_dxtxSection2_overjet}
                      defaultChecked={this.props.dxtxSection2_overjet === 'Improve resulting overjet'}
                    />
                    <label className="form-check-label" htmlFor="overjet-improve">
                      Improve resulting overjet
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overjet"
                      id="overjet-normal"
                      value="Achieve normal overjet"
                      onChange={this.props.onInputChange_dxtxSection2_overjet}
                      defaultChecked={this.props.dxtxSection2_overjet === 'Achieve normal overjet'}
                    />
                    <label className="form-check-label" htmlFor="overjet-normal">
                      Achieve normal overjet
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">7. Overbite:</label>
            </div>
            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overbite"
                      id="overbite-maintain"
                      value="Maintain initial overbite"
                      onChange={this.props.onInputChange_dxtxSection2_overbite}
                      defaultChecked={this.props.dxtxSection2_overbite === 'Maintain initial overbite'}
                    />
                    <label className="form-check-label" htmlFor="overbite-maintain">
                      Maintain initial overbite
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overbite"
                      id="overbite-open"
                      value="Correct open bite"
                      onChange={this.props.onInputChange_dxtxSection2_overbite}
                      defaultChecked={this.props.dxtxSection2_overbite === 'Correct open bite'}
                    />
                    <label className="form-check-label" htmlFor="overbite-open">
                      Correct open bite
                    </label>
                  </div>
                </div>

                {this.props.dxtxSection2_overbite === 'Correct open bite' ? (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <span className="bold-text dxtx-padding-l2">Extrude anterior teeth:</span>
                      </div>
                      <div className="col-md-4 collapse upper-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-open-extrude-upper"
                          value="overbite-open-extrude-upper"
                          defaultChecked={this.props.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
                        />{' '}
                        <span className="dxtx-padding-l1">Upper</span>
                      </div>
                      <div className="col-md-4 collapse lower-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-open-extrude-lower"
                          value="overbite-open-extrude-lower"
                          defaultChecked={this.props.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
                        />{' '}
                        <span className="dxtx-padding-l1">Lower</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.props.dxtxSection2_overbite === 'Correct open bite' ? (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <span className="bold-text dxtx-padding-l2"> Intrude posterior teeth:</span>
                      </div>
                      <div className="col-md-4 collapse upper-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-open-intrude-upper"
                          value="overbite-open-intrude-upper"
                          defaultChecked={this.props.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
                        />{' '}
                        <span className="dxtx-padding-l1">Upper</span>
                      </div>
                      <div className="col-md-4 collapse lower-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-open-intrude-lower"
                          value="overbite-open-intrude-lower"
                          defaultChecked={this.props.dxtxSection2_overbiteOpenIntrudePosteriorLower}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
                        />{' '}
                        <span className="dxtx-padding-l1">Lower</span>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overbite"
                      id="overbite-deep"
                      value="Correct deep bite"
                      onChange={this.props.onInputChange_dxtxSection2_overbite}
                      defaultChecked={this.props.dxtxSection2_overbite === 'Correct deep bite'}
                    />
                    <label className="form-check-label" htmlFor="overbite-deep">
                      Correct deep bite
                    </label>
                  </div>
                </div>

                {this.props.dxtxSection2_overbite === 'Correct deep bite' ? (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <span className="bold-text dxtx-padding-l2">Intrude anterior teeth:</span>
                      </div>
                      <div className="col-md-4 collapse upper-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-deep-intrude-upper"
                          value="overbite-deep-intrude-upper"
                          defaultChecked={this.props.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
                        />{' '}
                        <span className="dxtx-padding-l1">Upper</span>
                      </div>
                      <div className="col-md-4 collapse lower-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-deep-intrude-lower"
                          value="overbite-deep-intrude-lower"
                          defaultChecked={this.props.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
                        />{' '}
                        <span className="dxtx-padding-l1">Lower</span>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.props.dxtxSection2_overbite === 'Correct deep bite' ? (
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <span className="bold-text dxtx-padding-l2"> Extrude posterior teeth:</span>
                      </div>
                      <div className="col-md-4 collapse upper-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-deep-extrude-upper"
                          value="overbite-deep-extrude-upper"
                          defaultChecked={this.props.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
                        />{' '}
                        <span className="dxtx-padding-l1">Upper</span>
                      </div>
                      <div className="col-md-4 collapse lower-set">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overbite-deep-extrude-lower"
                          value="overbite-deep-extrude-lower"
                          defaultChecked={this.props.dxtxSection2_overbiteDeepExtrudePosteriorLower}
                          onChange={this.props.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
                        />{' '}
                        <span className="dxtx-padding-l1">Lower</span>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">8. Midline:</label>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12 collapse upper-set">
                  <label>Upper:</label>
                </div>
                <div className="col-md-4 col-form-label collapse upper-set">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-upper"
                      id="midline-upper-maintain"
                      value="Maintain"
                      onChange={this.props.onInputChange_dxtxSection2_midlineUpper}
                      defaultChecked={this.props.dxtxSection2_midlineUpper === 'Maintain'}
                    />
                    <label className="form-check-label" htmlFor="midline-upper-maintain">
                      Maintain
                    </label>
                  </div>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-upper"
                      id="midline-upper-right"
                      value="Move right"
                      onChange={this.props.onInputChange_dxtxSection2_midlineUpper}
                      defaultChecked={this.props.dxtxSection2_midlineUpper === 'Move right'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-upper-right">
                      Move to patient's right
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection2_midlineUpperRight}
                      value={this.props.dxtxSection2_midlineUpperRight}
                      disabled={this.props.dxtxSection2_midlineUpper !== 'Move right'}
                    />
                    <span> mm</span>
                  </div>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-upper"
                      id="midline-upper-left"
                      value="Move left"
                      onChange={this.props.onInputChange_dxtxSection2_midlineUpper}
                      defaultChecked={this.props.dxtxSection2_midlineUpper === 'Move left'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-upper-left">
                      Move to patient's left
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection2_midlineUpperLeft}
                      value={this.props.dxtxSection2_midlineUpperLeft}
                      disabled={this.props.dxtxSection2_midlineUpper !== 'Move left'}
                    />
                    <span> mm</span>
                  </div>
                </div>
                <div className="col-md-12 collapse lower-set">
                  <label>Lower:</label>
                </div>
                <div className="col-md-4 col-form-label collapse lower-set">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-lower"
                      id="midline-lower-maintain"
                      value="Maintain"
                      onChange={this.props.onInputChange_dxtxSection2_midlineLower}
                      defaultChecked={this.props.dxtxSection2_midlineLower === 'Maintain'}
                    />
                    <label className="form-check-label" htmlFor="midline-lower-maintain">
                      Maintain
                    </label>
                  </div>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-lower"
                      id="midline-lower-right"
                      value="Move right"
                      onChange={this.props.onInputChange_dxtxSection2_midlineLower}
                      defaultChecked={this.props.dxtxSection2_midlineLower === 'Move right'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-lower-right">
                      Move to patient's right
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection2_midlineLowerRight}
                      value={this.props.dxtxSection2_midlineLowerRight}
                      disabled={this.props.dxtxSection2_midlineLower !== 'Move right'}
                    />
                    <span> mm</span>
                  </div>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <div className="checkbox inline-block">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="midline-lower"
                      id="midline-lower-left"
                      value="Move left"
                      onChange={this.props.onInputChange_dxtxSection2_midlineLower}
                      defaultChecked={this.props.dxtxSection2_midlineLower === 'Move left'}
                    />
                    <label className="form-check-label mm-label" htmlFor="midline-lower-left">
                      Move to patient's left
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      className="form-control inline-block mm-size"
                      onChange={this.props.onInputChange_dxtxSection2_midlineLowerLeft}
                      value={this.props.dxtxSection2_midlineLowerLeft}
                      disabled={this.props.dxtxSection2_midlineLower !== 'Move left'}
                    />
                  </div>
                  <span> mm</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">9. Posterior Cross Bite (if present):</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="posterior"
                      id="posterior-not-corrected"
                      value="Do not correct"
                      onChange={this.props.onInputChange_dxtxSection2_posterior}
                      defaultChecked={this.props.dxtxSection2_posterior === 'Do not correct'}
                    />
                    <label className="form-check-label" htmlFor="posterior-not-corrected">
                      Do not correct
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="posterior"
                      id="posterior-correct"
                      value="Correct (additional auxilary device may be needed)"
                      onChange={this.props.onInputChange_dxtxSection2_posterior}
                      defaultChecked={this.props.dxtxSection2_posterior === 'Correct (additional auxilary device may be needed)'}
                    />
                    <label className="form-check-label" htmlFor="posterior-correct">
                      Correct (additional auxilary device may be needed)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">10. Spacing & Crowding (Arch Length Discrepancy):</label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box">
                <div className="col-md-12">
                  <label>Spacing:</label>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="spacing"
                      id="closeSpacing"
                      value="Close all the spaces"
                      onChange={this.props.onInputChange_dxtxSection2_spacing}
                      defaultChecked={this.props.dxtxSection2_spacing === 'Close all the spaces'}
                    />
                    <label className="form-check-label" htmlFor="closeSpacing">
                      Close all the spaces
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="spacing"
                      id="customSpacing"
                      value="custom"
                      onChange={this.props.onInputChange_dxtxSection2_spacing}
                      defaultChecked={this.props.dxtxSection2_spacing === 'custom'}
                    />
                    <label className="form-check-label" htmlFor="customSpacing">
                      Leave some specific spaces:
                      <div className="inline-input">
                        <input
                          type="text"
                          className="form-control"
                          id="spacingComments"
                          onChange={this.props.onInputChange_dxtxSection2_spacingValue}
                          value={this.props.dxtxSection2_spacingValue}
                          disabled={this.props.dxtxSection2_spacing !== 'custom'}
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="col-md-12 collapse upper-set">
                  <label>Resolve Upper crowding:</label>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="Expand"
                    defaultChecked={this.props.dxtxSection2_resolveUpperExpand}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperExpand}
                  />{' '}
                  <span className="dxtx-padding-l1">Expand</span>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="Procline"
                    defaultChecked={this.props.dxtxSection2_resolveUpperProcline}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperProcline}
                  />{' '}
                  <span className="dxtx-padding-l1">Procline</span>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="IPR Anterior"
                    defaultChecked={this.props.dxtxSection2_resolveUpperIPRAnterior}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Anterior</span>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="IPR Posterior Right"
                    defaultChecked={this.props.dxtxSection2_resolveUpperIPRPosteriorRight}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Posterior Right</span>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="IPR Posterior Left"
                    defaultChecked={this.props.dxtxSection2_resolveUpperIPRPosteriorLeft}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Posterior Left</span>
                </div>
                <div className="col-md-4 collapse upper-set">
                  <input
                    type="checkbox"
                    name="upper-crowding"
                    value="Extraction"
                    defaultChecked={this.props.dxtxSection2_resolveUpperExtraction}
                    onChange={this.props.onInputChange_dxtxSection2_resolveUpperExtraction}
                  />{' '}
                  <span className="dxtx-padding-l1">Extraction</span>
                </div>

                <div className="col-md-12 collapse lower-set">
                  <label>Resolve Lower crowding:</label>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="Expand"
                    defaultChecked={this.props.dxtxSection2_resolveLowerExpand}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerExpand}
                  />{' '}
                  <span className="dxtx-padding-l1">Expand</span>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="Procline"
                    defaultChecked={this.props.dxtxSection2_resolveLowerProcline}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerProcline}
                  />{' '}
                  <span className="dxtx-padding-l1">Procline</span>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="IPR Anterior"
                    defaultChecked={this.props.dxtxSection2_resolveLowerIPRAnterior}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Anterior</span>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="IPR Posterior Right"
                    defaultChecked={this.props.dxtxSection2_resolveLowerIPRPosteriorRight}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Posterior Right</span>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="IPR Posterior Left"
                    defaultChecked={this.props.dxtxSection2_resolveLowerIPRPosteriorLeft}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
                  />{' '}
                  <span className="dxtx-padding-l1">IPR Posterior Left</span>
                </div>
                <div className="col-md-4 collapse lower-set">
                  <input
                    type="checkbox"
                    name="lower-crowding"
                    value="Extraction"
                    defaultChecked={this.props.dxtxSection2_resolveLowerExtraction}
                    onChange={this.props.onInputChange_dxtxSection2_resolveLowerExtraction}
                  />{' '}
                  <span className="dxtx-padding-l1">Extraction</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">11. Special Instructions:</label>
            </div>
            <div className="col-md-12">
              <textarea
                className="form-control dxtx-comment"
                placeholder="Anything else we can help you with?"
                id="specialInstructions"
                rows="4"
                onChange={this.props.onInputChange_dxtxSection2_special}
                defaultValue={this.props.dxtxSection2_special}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default DxtxForm;
