import { SET_SINGLE_TOOTH_IDB_STATE, SET_SINGLE_TOOTH_IDB_MODAL, SET_SINGLE_TOOTH_IDB_REFRESH } from '../../../actions/bpp/single_tooth_idb/single_tooth_idb';

const initialState = {
  single_tooth_idb_status: '',
  single_tooth_idb_status_comment: '',
  single_tooth_idb_raw_file: null,
  single_tooth_idb_final_file: null,
  single_tooth_idb_modal: '',
  is_historical_single_tooth_idb: true,
  refresh: false,
};

export function singleToothIdbReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SINGLE_TOOTH_IDB_STATE:
      return {
        ...state,
        single_tooth_idb_status: action.state.status,
        single_tooth_idb_status_comment: action.state.status_comment,
        single_tooth_idb_raw_file: action.state.file_raw,
        single_tooth_idb_final_file: action.state.file_final,
        is_historical_single_tooth_idb: action.state.is_historical,
      };

    case SET_SINGLE_TOOTH_IDB_MODAL:
      return {
        ...state,
        single_tooth_idb_modal: action.modal,
      };

    case SET_SINGLE_TOOTH_IDB_REFRESH:
      return {
        ...state,
        refresh: true,
      };
    default:
      return state;
  }
}

export const getSingleToothIdbStatus = (state) => state.singleToothIdbReducer.single_tooth_idb_status;
export const getSingleToothIdbStatusComment = (state) => state.singleToothIdbReducer.single_tooth_idb_status_comment;
export const getSingleToothIdbRawFile = (state) => state.singleToothIdbReducer.single_tooth_idb_raw_file;
export const getSingleToothIdbFinalFile = (state) => state.singleToothIdbReducer.single_tooth_idb_final_file;
export const getSingleToothIdbModal = (state) => state.singleToothIdbReducer.single_tooth_idb_modal;
export const getIsHistoricalSingleToothIdb = (state) => state.singleToothIdbReducer.is_historical_single_tooth_idb;
export const getRefresh = (state) => state.singleToothIdbReducer.refresh;
