/**
 * Looks for the mouse event to determine the action to take for a given table row.
 *
 * If left click, it should continue as normal, but also captures
 * the caselist filter selection. This will be used later in on when needing to
 * use the return buttons.
 * @param {Object} event - contains the event handler values when event is trigger
 * @param {String} url - this is the url to navigate to next
 * @param {Object} props - This is the props of the given component
 * @param {String} search - the search param from the URL, this is often indicated with the ? in the url
 * @param {Object} override_state - allows to override the default state that gets
 * pass along with the history
 */
function tableRowLink(event, url, props, search = '', override_state = '', openInNewTab = false) {
  if (event.button === 1 || (event.button === 0 && event.ctrlKey) || event.currentTarget.className.includes('new-tab-link') || openInNewTab) {
    //middle click
    window.open(url);
  } else if (event.button === 0) {
    //left click
    let state = {
      search: search ? search : props.history.location.search,
      caselist_filter: props.history.location.search,
    };

    // for selected Item Request ID
    if (event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.requestid) {
      state.selectedReqId = event.currentTarget.dataset.requestid;
    }

    // for selected Progress Record ID
    if (event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.progressid) {
      state.selectedProgressId = event.currentTarget.dataset.progressid;
    }

    state = override_state ? override_state : state;

    props.history.push(url, state);
  }

  return;
}

export { tableRowLink };
