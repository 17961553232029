import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a error message box
 * @component
 */
function ErrorMessage({ title, onClose, className, children, ...props }) {
  const classNames = `${className ? className : ''} card card--top-border card--error`;
  return (
    <div className={classNames} {...props}>
      <button type="button" className="close card--x" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">×</span>
      </button>
      <div className="card--error__title">{title}</div>
      {children}
    </div>
  );
}

ErrorMessage.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

ErrorMessage.defaultProps = {
  title: 'Error',
};

export default ErrorMessage;
