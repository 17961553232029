import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux
import {
  getTreatmentArch,
  getOpposingTreatmentArch,
  getTreatmentPlanRevision,
  getUpperOpposingTreatmentArch,
  getLowerOpposingTreatmentArch,
} from '../../../redux/reducers/common/teeth_chart';
import { onTreatmentArchChange, onOpposingTreatmentArchChange } from '../../../redux/actions/common/teeth_chart';

/**
 * Treatment arch selection
 * @component
 * @alias ArchSelection
 * @category BPP
 */
class ArchSelection extends Component {
  /**
   * Displays opposing arch selections
   * @function
   * @param {String} opposing_arch - Opposing arch
   * @return {JSX} JSX for opposing arch selections
   */
  displayOpposingArchSelections(opposing_arch) {
    return (
      <div className="arch-to-treat__selection__step">
        <div>
          <input
            type="radio"
            value={`${opposing_arch.toLowerCase()}_untreated`}
            checked={
              this.props[`${this.props.treatment_arch.toLowerCase()}_opposing_treatment_arch`] === `${opposing_arch.toLowerCase()}_untreated` ||
              (this.props.treatment_plan_revision === 'DE3.0' && this.props[`${this.props.treatment_arch.toLowerCase()}_opposing_treatment_arch`] === 'current')
            }
            onChange={this.props.onOpposingTreatmentArchChange}
          />
          {this.props.treatment_plan_revision && ['DE2.0', 'DE3.0'].includes(this.props.treatment_plan_revision)
            ? `Use current ${opposing_arch.toLowerCase()} arch for Smile Design`
            : `${opposing_arch} arch will be untreated`}
        </div>
        <div>
          <input
            type="radio"
            value={`${opposing_arch.toLowerCase()}_alternate`}
            checked={
              this.props[`${this.props.treatment_arch.toLowerCase()}_opposing_treatment_arch`] === `${opposing_arch.toLowerCase()}_alternate` ||
              (this.props.treatment_plan_revision === 'DE3.0' && this.props[`${this.props.treatment_arch.toLowerCase()}_opposing_treatment_arch`] === 'both')
            }
            onChange={this.props.onOpposingTreatmentArchChange}
          />
          {this.props.treatment_plan_revision && ['DE2.0', 'DE3.0'].includes(this.props.treatment_plan_revision)
            ? 'Set up both arches'
            : `${opposing_arch} arch will be treated with an alternate system`}
        </div>
        {this.props.treatment_plan_revision && ['TX3.0'].includes(this.props.treatment_plan_revision) ? (
          <div>
            <input
              type="radio"
              value={`${opposing_arch.toLowerCase()}_digital`}
              checked={this.props[`${this.props.treatment_arch.toLowerCase()}_opposing_treatment_arch`] === `${opposing_arch.toLowerCase()}_digital`}
              onChange={this.props.onOpposingTreatmentArchChange}
            />
            {opposing_arch} arch will be added in Digital Enhancement
          </div>
        ) : null}
      </div>
    );
  }

  /**
   * Displays arch selection
   * @function
   * @param {String} arch - Upper lower or both
   * @return {JSX} JSX for arch selection
   */
  displayArchSelection(arch) {
    const labels = {
      both: 'Both arches',
      upper: 'Upper arch',
      lower: 'Lower arch',
    };
    return (
      <div className="arch-to-treat__selection">
        <input
          type="radio"
          value={arch}
          checked={this.props.treatment_arch === arch}
          onChange={this.props.onTreatmentArchChange}
          disabled={this.props.arch_disabled}
        />
        {labels[arch]}
        {arch !== 'both' && this.props.treatment_arch === arch && this.displayOpposingArchSelections(this.props.treatment_arch === 'upper' ? 'Lower' : 'Upper')}
      </div>
    );
  }

  render() {
    return (
      <div className="arch-to-treat teeth-container__row p-slim grey-text">
        <div className="bold-text">Arch to Treat:</div>
        {this.displayArchSelection('both')}
        {this.displayArchSelection('upper')}
        {this.displayArchSelection('lower')}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    treatment_arch: getTreatmentArch(state),
    opposing_treatment_arch: getOpposingTreatmentArch(state),
    upper_opposing_treatment_arch: getUpperOpposingTreatmentArch(state),
    lower_opposing_treatment_arch: getLowerOpposingTreatmentArch(state),
    treatment_plan_revision: getTreatmentPlanRevision(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onTreatmentArchChange: onTreatmentArchChange,
      onOpposingTreatmentArchChange: onOpposingTreatmentArchChange,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArchSelection);
