import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

const ManualWireTableHeaderElement = ({ error, arch, teethLabel, index }) => {
    return (
        <Box
          className={error ? 'manual-wire-table-header-element error' : 'manual-wire-table-header-element'}
          key={`manual-wire-table-header-element-${index}`}
        >
          {teethLabel}
        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {
    let currentTeethError = false;
    if (ownProps.teethLabel !== '') 
      currentTeethError = 
        state.manualWireReducer[ownProps.arch][ownProps.teethLabel].error ||
        state.manualWireReducer[ownProps.arch][ownProps.teethLabel].headerError;
    const error = currentTeethError;
    return {
      error
    };
};

export default connect(mapStateToProps)(ManualWireTableHeaderElement);