import React from 'react';
import PropTypes from 'prop-types';
import { getCurrentQuarterString } from './dashboard_helpers';

function ProviderTierStatus(props) {
  const { note, name, approvalsNeed } = props;

  return (
    (note || window.location.pathname.includes('tier-details')) && (
      <>
        <div className="emphasis">
          {approvalsNeed > 0 && note} {name} Provider
        </div>
        {approvalsNeed > 0 && (
          <div>
            Approve{' '}
            <span className="emphasis">
              {approvalsNeed} {approvalsNeed > 1 ? 'Smile Designs' : 'Smile Design'}
            </span>{' '}
            by {getCurrentQuarterString()}
          </div>
        )}
      </>
    )
  );
}

ProviderTierStatus.propTypes = {
  note: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  approvalsNeed: PropTypes.number.isRequired,
};

export default ProviderTierStatus;
