import {
  SET_WIRES,
  SET_UPLOAD_OPTION,
  POST_CASE_FILE_PENDING,
  POST_CASE_FILE_SUCCESS,
  POST_CASE_FILE_ERROR,
  PUT_UPPER_WIRES_PENDING,
  PUT_UPPER_WIRES_SUCCESS,
  PUT_UPPER_WIRES_ERROR,
  PUT_LOWER_WIRES_PENDING,
  PUT_LOWER_WIRES_SUCCESS,
  PUT_LOWER_WIRES_ERROR,
  PUT_UPLOAD_OPTION_PENDING,
  PUT_UPLOAD_OPTION_SUCCESS,
  PUT_UPLOAD_OPTION_ERROR,
  PUT_BRACKET_PENDING,
  PUT_BRACKET_SUCCESS,
  PUT_BRACKET_ERROR,
  OPEN_BRACKET_NOT_INCLUDED_MODAL,
  EDIT_BRACKET_NOT_INCLUDED_MODAL,
  CLOSE_BRACKET_NOT_INCLUDED_MODAL,
  OPEN_BRACKET_COLLISIONS_MODAL,
  EDIT_BRACKET_COLLISIONS_MODAL,
  CLOSE_BRACKET_COLLISIONS_MODAL,
  OPEN_BRACKET_REPOSITIONED_MODAL,
  EDIT_BRACKET_REPOSITIONED_MODAL,
  CLOSE_BRACKET_REPOSITIONED_MODAL,
  OPEN_BRACKET_NEW_MODAL,
  EDIT_BRACKET_NEW_MODAL,
  CLOSE_BRACKET_NEW_MODAL,
  OPEN_GENERATE_MODAL,
  CLOSE_GENERATE_MODAL,
  GENERATE_PENDING,
  GENERATE_SUCCESS,
  GENERATE_ERROR,
  OPEN_OVERRIDE_MODAL,
  CLOSE_OVERRIDE_MODAL,
  OVERRIDE_PENDING,
  OVERRIDE_SUCCESS,
  OVERRIDE_ERROR,
  OPEN_REMOVE_IFU_UPLOAD_MODAL,
  CLOSE_REMOVE_IFU_UPLOAD_MODAL,
  DELETE_IFU_UPLOAD_PENDING,
  DELETE_IFU_UPLOAD_SUCCESS,
  DELETE_IFU_UPLOAD_ERROR,
  SET_IFU_UPLOAD_PENDING,
  SET_IFU_UPLOAD_FINISHED,
  SET_IFU_INCORRECT_FILE_NAME_ERROR,
  SET_IFU_FILE_NAME,
  SET_IFU_FOLDER,
  CLEAR_IFU_UPLOAD_PENDING,
  SET_IFU_FILE_TYPE_ERROR,
  CLEAR_IFU_FILE_TYPE_ERROR,
  SET_IFU_FILE_EXT,
} from '../../actions/ifu/ifu_upload';
import { IFUFileExtension } from '../../../common/constants';

const initialState = {
  bpg_checked: null,
  wires: [],
  upper_selection: '16',
  lower_selection: '16',
  pending: false,
  error: null,
  heading: '',
  bracket_modal_type: '',
  selected_teeth: [],
  bracket_not_included_modal: false,
  bracket_collisions_modal: false,
  bracket_repositioned_modal: false,
  bracket_new_modal: false,
  generate_modal: false,
  override_modal: false,
  remove_ifu_upload_modal: false,
  ifu_upload_pending: false,
  session_error: false,
  ifu_incorrect_file_name_error: {
    case_id: false,
    format: false,
  },
  ifu_file_name: '',
  file_type_error: false,
  ifu_folder: 'ifu_upload',
  ifu_file_ext: IFUFileExtension.Matic,
};

export function ifuUploadReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WIRES:
      return {
        ...state,
        wires: action.wires,
        upper_selection: action.upper_selection,
        lower_selection: action.lower_selection,
      };

    case SET_UPLOAD_OPTION:
      return {
        ...state,
        bpg_checked: action.bpg_checked,
      };

    case POST_CASE_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_CASE_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
        ifu_upload_pending: false,
      };
    case POST_CASE_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case PUT_UPPER_WIRES_PENDING:
      return {
        ...state,
        pending: true,
        upper_selection: action.upper_selection,
      };
    case PUT_UPPER_WIRES_SUCCESS:
      return {
        ...state,
        pending: false,
        wires: action.wires,
      };

    case PUT_UPPER_WIRES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case PUT_LOWER_WIRES_PENDING:
      return {
        ...state,
        pending: true,
        lower_selection: action.lower_selection,
      };
    case PUT_LOWER_WIRES_SUCCESS:
      return {
        ...state,
        pending: false,
        wires: action.wires,
      };

    case PUT_LOWER_WIRES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case PUT_UPLOAD_OPTION_PENDING:
      return {
        ...state,
        bpg_checked: action.bpg_checked,
        pending: true,
      };
    case PUT_UPLOAD_OPTION_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case PUT_UPLOAD_OPTION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case PUT_BRACKET_PENDING:
      return {
        ...state,
        pending: true,
      };

    case PUT_BRACKET_SUCCESS:
      return {
        ...state,
        pending: false,
        selected_teeth: action.selected_teeth,
      };

    case PUT_BRACKET_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case GENERATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GENERATE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case GENERATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case OVERRIDE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case OVERRIDE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case OVERRIDE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case DELETE_IFU_UPLOAD_PENDING:
      return {
        ...state,
        pending: true,
      };

    case DELETE_IFU_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case DELETE_IFU_UPLOAD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    // Modals
    case OPEN_BRACKET_NOT_INCLUDED_MODAL:
      return {
        ...state,
        bracket_not_included_modal: true,
        heading: action.heading,
        bracket_modal_type: 'not_included',
        selected_teeth: action.selected_teeth,
      };
    case EDIT_BRACKET_NOT_INCLUDED_MODAL:
      return {
        ...state,
        selected_teeth: action.selected_teeth,
      };
    case CLOSE_BRACKET_NOT_INCLUDED_MODAL:
      return {
        ...state,
        bracket_not_included_modal: false,
      };
    case OPEN_BRACKET_COLLISIONS_MODAL:
      return {
        ...state,
        bracket_collisions_modal: true,
        heading: action.heading,
        bracket_modal_type: 'collisions',
        selected_teeth: action.selected_teeth,
      };
    case EDIT_BRACKET_COLLISIONS_MODAL:
      return {
        ...state,
        selected_teeth: action.selected_teeth,
      };
    case CLOSE_BRACKET_COLLISIONS_MODAL:
      return {
        ...state,
        bracket_collisions_modal: false,
      };
    case OPEN_BRACKET_REPOSITIONED_MODAL:
      return {
        ...state,
        bracket_repositioned_modal: true,
        heading: action.heading,
        bracket_modal_type: 'repositioned',
        selected_teeth: action.selected_teeth,
      };
    case EDIT_BRACKET_REPOSITIONED_MODAL:
      return {
        ...state,
        selected_teeth: action.selected_teeth,
      };
    case CLOSE_BRACKET_REPOSITIONED_MODAL:
      return {
        ...state,
        bracket_repositioned_modal: false,
      };

    case OPEN_BRACKET_NEW_MODAL:
      return {
        ...state,
        bracket_new_modal: true,
        heading: action.heading,
        bracket_modal_type: 'new',
        selected_teeth: action.selected_teeth,
      };
    case EDIT_BRACKET_NEW_MODAL:
      return {
        ...state,
        selected_teeth: action.selected_teeth,
      };
    case CLOSE_BRACKET_NEW_MODAL:
      return {
        ...state,
        bracket_new_modal: false,
      };
    case OPEN_GENERATE_MODAL:
      return {
        ...state,
        generate_modal: true,
      };
    case CLOSE_GENERATE_MODAL:
      return {
        ...state,
        generate_modal: false,
      };
    case OPEN_OVERRIDE_MODAL:
      return {
        ...state,
        override_modal: true,
      };
    case CLOSE_OVERRIDE_MODAL:
      return {
        ...state,
        override_modal: false,
      };
    case OPEN_REMOVE_IFU_UPLOAD_MODAL:
      return {
        ...state,
        remove_ifu_upload_modal: true,
      };
    case CLOSE_REMOVE_IFU_UPLOAD_MODAL:
      return {
        ...state,
        remove_ifu_upload_modal: false,
      };
    case CLEAR_IFU_UPLOAD_PENDING:
      return {
        ...state,
        ifu_upload_pending: false,
      };
    case SET_IFU_UPLOAD_PENDING:
      return {
        ...state,
        ifu_upload_pending: true,
      };
    case SET_IFU_UPLOAD_FINISHED:
      return {
        ...state,
        ifu_upload_pending: false,
      };
    case SET_IFU_INCORRECT_FILE_NAME_ERROR:
      return {
        ...state,
        ifu_incorrect_file_name_error: {
          ...state.ifu_incorrect_file_name_error,
          [action.error_type]: action.error,
          [action.error_type === 'case_id' ? 'format' : 'case_id']: false,
        },
      };
    case SET_IFU_FILE_NAME:
      return {
        ...state,
        ifu_file_name: action.filename,
      };
    case SET_IFU_FOLDER:
      return {
        ...state,
        ifu_folder: action.ifu_folder,
      };
    case SET_IFU_FILE_TYPE_ERROR:
      return {
        ...state,
        file_type_error: true,
      };

    case CLEAR_IFU_FILE_TYPE_ERROR:
      return {
        ...state,
        file_type_error: false,
      };

    case SET_IFU_FILE_EXT:
      return {
        ...state,
        ifu_file_ext: action.extension,
      };

    default:
      return state;
  }
}

export const getBpgCheckboxChecked = (state) => state.ifuUploadReducer.bpg_checked;
export const getUpperSelection = (state) => state.ifuUploadReducer.upper_selection;
export const getLowerSelection = (state) => state.ifuUploadReducer.lower_selection;
export const getSelectedTeethIfu = (state) => state.ifuUploadReducer.selected_teeth;
export const getBracketModalType = (state) => state.ifuUploadReducer.bracket_modal_type;
export const getHeading = (state) => state.ifuUploadReducer.heading;
export const getBracketNotIncludedModal = (state) => state.ifuUploadReducer.bracket_not_included_modal;
export const getBracketCollisionsModal = (state) => state.ifuUploadReducer.bracket_collisions_modal;
export const getBracketRepositionedModal = (state) => state.ifuUploadReducer.bracket_repositioned_modal;
export const getBracketNewModal = (state) => state.ifuUploadReducer.bracket_new_modal;
export const getGenerateModal = (state) => state.ifuUploadReducer.generate_modal;
export const getOverrideModal = (state) => state.ifuUploadReducer.override_modal;
export const getRemoveIfuUploadModal = (state) => state.ifuUploadReducer.remove_ifu_upload_modal;

export const getPending = (state) => state.ifuUploadReducer.pending;
export const getUploadPending = (state) => state.ifuUploadReducer.ifu_upload_pending;
export const getUploadSessionError = (state) => state.ifuUploadReducer.session_error;
export const getIfuIncorrectFileNameError = (state) => state.ifuUploadReducer.ifu_incorrect_file_name_error;
export const getIfuFileName = (state) => state.ifuUploadReducer.ifu_file_name;
export const getIfuFileTypeError = (state) => state.ifuUploadReducer.file_type_error;
export const getIfuFolder = (state) => state.ifuUploadReducer.ifu_folder;
export const getIfuFileExtension = (state) => state.ifuUploadReducer.ifu_file_ext;
