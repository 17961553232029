import PropTypes from 'prop-types';

export const HideContentIf = ({ condition, children }) => {
  if (condition) {
    return null;
  }

  return children;
};

HideContentIf.propTypes = {
  condition: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};
