import { Color, Scene } from 'three';

/**
 * Creates a scene
 * @function
 * @param {Number} pane - The pane of the setup viewer
 * @return {THREE.Scene} The scene
 */
function createScene(pane) {
  const scene = new Scene();
  scene.name = `viewer-${pane}`;
  scene.background = new Color(0x2a3033);

  return scene;
}

export { createScene };
