import Axios from 'axios';
import _ from 'lodash';

import { setIncompleteError } from '../production_tx_guide/segmentation';

// Action Types
export const FETCH_SEGMENTATION_STATE_SUCCESS = 'FETCH_SEGMENTATION_STATE_SUCCESS';
export const FETCH_SEGMENTATION_STATE_PENDING = 'FETCH_SEGMENTATION_STATE_PENDING';
export const FETCH_SEGMENTATION_STATE_ERROR = 'FETCH_SEGMENTATION_STATE_ERROR';
export const POST_SEGMENTATION_CASE_FILE_PENDING = 'POST_SEGMENTATION_CASE_FILE_PENDING';
export const POST_SEGMENTATION_CASE_FILE_SUCCESS = 'POST_SEGMENTATION_CASE_FILE_SUCCESS';
export const POST_SEGMENTATION_CASE_FILE_ERROR = 'POST_SEGMENTATION_CASE_FILE_ERROR';
export const POST_UPLOAD_PENDING = 'POST_UPLOAD_PENDING';
export const POST_UPLOAD_SUCCESS = 'POST_UPLOAD_SUCCESS';
export const POST_UPLOAD_ERROR = 'POST_UPLOAD_ERROR';
export const CHANGE_RELOAD_THUMBNAIL = 'CHANGE_RELOAD_THUMBNAIL';
export const SET_IMPORT_TYPE = 'SET_IMPORT_TYPE';

// Axios

// Mark Generation as Success
export function fetchSegmentationState(case_id) {
  return (dispatch, getState) => {
    dispatch(fetchSegmentationPending());

    Axios.get(`/apiv3/case/${case_id}/segmentation`)
      .then((res) => {
        dispatch(fetchSegmentationSuccess(res.data));
        dispatch(changeReloadThumbnail(true));
      })
      .catch((error) => {
        console.log(error);
        dispatch(fetchSegmentationError(error));
      });
  };
}

export function fetchSegmentationPending() {
  return {
    type: FETCH_SEGMENTATION_STATE_PENDING,
  };
}
export function fetchSegmentationSuccess(data) {
  return {
    type: FETCH_SEGMENTATION_STATE_SUCCESS,
    segmentation_state: data,
    reload_thumbnail: true,
  };
}
export function fetchSegmentationError(error) {
  const refresh = error.response.status === 409 ? true : false;

  return {
    type: FETCH_SEGMENTATION_STATE_ERROR,
    error: error,
    refresh: refresh,
  };
}

// Post Case File
export function postSegmentationCaseFile(data, case_id) {
  return (dispatch, getState) => {
    let form_data = new FormData();
    form_data.append('files', JSON.stringify(data));

    dispatch(postSegmentationFilePending());

    Axios.post(`/apiV2/casefiles/${case_id}/add`, form_data)
      .then((res) => {
        dispatch(fetchSegmentationState(case_id));

        dispatch(postSegmentationCaseFileSuccess());

        dispatch(changeReloadThumbnail(true));

        return res;
      })
      .catch((error) => {
        dispatch(postSegmentationCaseFileError(error));
      });
  };
}

export function postSegmentationFilePending() {
  return {
    type: POST_SEGMENTATION_CASE_FILE_PENDING,
  };
}
export function postSegmentationCaseFileSuccess() {
  return {
    type: POST_SEGMENTATION_CASE_FILE_SUCCESS,
  };
}

export function postSegmentationCaseFileError(error) {
  return {
    type: POST_SEGMENTATION_CASE_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

// Post Case File
export function proceedUpload(case_id) {
  return (dispatch, getState) => {
    dispatch(proceedUploadPending());

    Axios.put(`/apiv3/case/${case_id}/segmentation/proceed_upload`)
      .then((res) => {
        dispatch(fetchSegmentationState(case_id));

        dispatch(proceedUploadSuccess());

        return res;
      })
      .catch((error) => {
        dispatch(proceedUploadError(error));
      });
  };
}

export function forceSuccess(case_id) {
  return (dispatch, getState) => {
    dispatch(proceedUploadPending());

    Axios.put(`/apiv3/case/${case_id}/segmentation/force_success`)
      .then((res) => {
        dispatch(fetchSegmentationState(case_id));

        dispatch(proceedUploadSuccess());

        return res;
      })
      .catch((error) => {
        dispatch(proceedUploadError(error));
      });
  };
}

export function forceFailure(case_id) {
  return (dispatch, getState) => {
    dispatch(proceedUploadPending());

    Axios.put(`/apiv3/case/${case_id}/segmentation/force_failure`)
      .then((res) => {
        dispatch(fetchSegmentationState(case_id));

        dispatch(proceedUploadSuccess());

        return res;
      })
      .catch((error) => {
        dispatch(proceedUploadError(error));
      });
  };
}

export function retryUpload(case_id, action) {
  return (dispatch, getState) => {
    dispatch(proceedUploadPending());

    Axios.put(`/apiv3/case/${case_id}/segmentation/${action}`)
      .then((res) => {
        dispatch(fetchSegmentationState(case_id));

        dispatch(proceedUploadSuccess());

        dispatch(changeReloadThumbnail(true));
        dispatch(setIncompleteError(false));

        return res;
      })
      .catch((error) => {
        dispatch(proceedUploadError(error));
      });
  };
}

export function removeUploadFiles(case_id) {
  return (dispatch, getState) => {
    const { segmentation_state } = getState().segmentationReducer;
    let segmentation_state_updates = _.cloneDeep(segmentation_state);
    dispatch(proceedUploadPending());

    Axios.put(`/apiv3/case/${case_id}/segmentation/remove_files`)
      .then((res) => {
        segmentation_state_updates.file = [];
        dispatch(fetchSegmentationSuccess(segmentation_state_updates));

        dispatch(proceedUploadSuccess());
        dispatch(changeReloadThumbnail(true));

        return res;
      })
      .catch((error) => {
        dispatch(proceedUploadError(error));
      });
  };
}

export function changeReloadThumbnail(value) {
  return {
    type: CHANGE_RELOAD_THUMBNAIL,
    value: value,
  };
}

export function proceedUploadPending() {
  return {
    type: POST_UPLOAD_PENDING,
  };
}
export function proceedUploadSuccess() {
  return {
    type: POST_UPLOAD_SUCCESS,
  };
}

export function proceedUploadError(error) {
  return {
    type: POST_UPLOAD_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

/**
 * Handles event when user changes scan import type
 * @function
 * @param {Object} event - Event object
 * @param {String} case_id - Case id
 * @return {Object} Action type
 */
export function onImportTypeChange(event, case_id) {
  return (dispatch) => {
    const data = {
      import_type: event.target.value,
    };
    Axios.put(`/apiv3/case/${case_id}/segmentation/update_import_type`, data)
      .then((res) => {
        dispatch({
          type: SET_IMPORT_TYPE,
          import_type: data.import_type,
        });
      })
      .catch((error) => {});
  };
}
