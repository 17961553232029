import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../modal/modal';
import { removeCaseIdInitialNumber } from '../../common/functions';

import SegmentationProductionTxGuide from './segmentation_production_tx_guide';
import SmileDesignProductionTxGuide from './smile_design_production_tx_guide';
import Stage1ProductionTxGuide from './stage1_production_tx_guide';

import './production_tx_guide.scss';

// Redux
import { getProductionTxGuide } from '../../redux/reducers/bpp/production_tx_guide/production_tx_guide';
import { getSegmentationTxGuidePdfModal, getSegmentationTxGuideFilePath } from '../../redux/reducers/bpp/production_tx_guide/segmentation';
import { getSmileDesignTxGuidePdfModal, getSmileDesignTxGuideFilePath } from '../../redux/reducers/bpp/production_tx_guide/smile_design';
import { getStage1TxGuidePdfModal, getStage1TxGuideFilePath } from '../../redux/reducers/bpp/production_tx_guide/stage_1';
import { closeSegmentationTxGuidePdfModal } from '../../redux/actions/bpp/production_tx_guide/segmentation';
import { closeSmileDesignTxGuidePdfModal } from '../../redux/actions/bpp/production_tx_guide/smile_design';
import { closeStage1TxGuidePdfModal } from '../../redux/actions/bpp/production_tx_guide/stage_1';
import { getPDFNameTemplate } from '../../common/helpers';

const production_tx_guide_components = {
  segmentation: SegmentationProductionTxGuide,
  smile_design: SmileDesignProductionTxGuide,
  stage_1: Stage1ProductionTxGuide,
};

/**
 * Production tx guide form displayed throughout various stages in the BPP
 * @component
 * @alias ProductionTxGuide
 * @category BPP
 */
class ProductionTxGuide extends Component {
  /**
   * Gets the PDF form name
   * @function
   * @return {String} - PDF Title
   */
  getPDFFormName = () => {
    const form = this.props.gen_2 ? 'FM-068' : 'F021-15';
    const pdf_title = 'Production_Treatment_Guide.pdf';

    return `${form}_${pdf_title}`;
  };
  /**
   * Displays Production tx guide PDF modal
   * @function
   * @return {JSX} - JSX for modal
   */
  displayProductionTxGuideModal() {
    return (
      <Modal
        preset="pdf_viewer"
        header_text={'Production Treatment Guide - ' + removeCaseIdInitialNumber(this.props.case_id)}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={this.getTxGuideFilePath()}
        pdf_date={new Date()}
        onCloseButtonClick={this.getCloseTxGuidePdfModalMethod()}
        theme="bpp"
        case_id={this.props.case_id}
        pdf_type="production_tx_guide"
        original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, this.getPDFFormName())}
        watermark={true}
      />
    );
  }

  /**
   * Determines if Production tx guide PDF modal should be displayed
   * @function
   * @return {Boolean} - True or false
   */
  shouldDisplayTxGuidePdfModal() {
    const modals = {
      segmentation: this.props.segmentation_tx_guide_pdf_modal,
      smile_design: this.props.smile_design_tx_guide_pdf_modal,
      stage_1: this.props.stage_1_tx_guide_pdf_modal,
    };
    return modals[this.props.stage];
  }

  /**
   * Retrieves Production tx guide file path
   * @function
   * @return {String} - File path
   */
  getTxGuideFilePath() {
    const file_paths = {
      segmentation: this.props.segmentation_tx_guide_file_path,
      smile_design: this.props.smile_design_tx_guide_file_path,
      stage_1: this.props.stage_1_tx_guide_file_path,
    };
    return file_paths[this.props.stage];
  }

  /**
   * Retrieves close PDF modal method
   * @function
   * @return {Function} - Close function
   */
  getCloseTxGuidePdfModalMethod() {
    const file_paths = {
      segmentation: this.props.closeSegmentationTxGuidePdfModal,
      smile_design: this.props.closeSmileDesignTxGuidePdfModal,
      stage_1: this.props.closeStage1TxGuidePdfModal,
    };
    return file_paths[this.props.stage];
  }

  render() {
    const ProductionTxGuideComponent = production_tx_guide_components[this.props.stage];
    return (
      <div className="production-tx-guide">
        {this.props.production_tx_guide && <ProductionTxGuideComponent {...this.props} production_tx_guide={this.props.production_tx_guide} />}
        {this.shouldDisplayTxGuidePdfModal() && this.displayProductionTxGuideModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    production_tx_guide: getProductionTxGuide(state),
    segmentation_tx_guide_pdf_modal: getSegmentationTxGuidePdfModal(state),
    smile_design_tx_guide_pdf_modal: getSmileDesignTxGuidePdfModal(state),
    stage_1_tx_guide_pdf_modal: getStage1TxGuidePdfModal(state),
    segmentation_tx_guide_file_path: getSegmentationTxGuideFilePath(state),
    smile_design_tx_guide_file_path: getSmileDesignTxGuideFilePath(state),
    stage_1_tx_guide_file_path: getStage1TxGuideFilePath(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeSegmentationTxGuidePdfModal: closeSegmentationTxGuidePdfModal,
      closeSmileDesignTxGuidePdfModal: closeSmileDesignTxGuidePdfModal,
      closeStage1TxGuidePdfModal: closeStage1TxGuidePdfModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductionTxGuide);
