export default class Utils {
  static isValidEmail(email) {
    // eslint-disable-next-line
    let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (reg.test(email) === false) {
      return false;
    }

    return true;
  }

  static isValidPhoneNo(phoneno) {
    // eslint-disable-next-line
    let regPhone = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
    
    if (phoneno.match(regPhone)) {
      return true;
    } else {
      return false;
    }
  }

  static doPasswordsMatch(password1, password2) {
    return password1 === password2;
  }

  static isValidPassword(password) {
    return password.length >= 8;
  }
}
