import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { AnimationIcon } from './icons';
import { getWasmAnimationState } from './wasm_controller/wasm_controller';
import { onToggleOption } from '../../redux/actions/wasm_viewer/wasm_viewer';
import { WASM_ANIMATION_PROGRESS_INTERVAL_MS, WASM_ANIMATION_TOGGLE_OFF_TIMEOUT_MS } from './wasm-constants';

let interval;

function WasmAnimationProgressBar(props) {
  const { onToggleOption, isShowAnimationDisclaimer, animationContainerStyles } = props;
  const [isAnimating, setIsAnimating] = useState(false);
  const [flexGrow, setFlexGrow] = useState(0);

  useEffect(() => {
    interval = setInterval(() => {
      const { AnimationProgress: progress, AnimationActivated: isActive } = getWasmAnimationState();
      setIsAnimating(isActive);
      if (progress >= flexGrow && progress !== 1) {
        setFlexGrow(p => Math.max(p, progress));
      } else if (progress < flexGrow) {
        clearInterval(interval);
      }
    }, WASM_ANIMATION_PROGRESS_INTERVAL_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isAnimating && flexGrow > 0.8) {
      clearInterval(interval);
      setFlexGrow(1);
      setTimeout(() => onToggleOption('animation', false), WASM_ANIMATION_TOGGLE_OFF_TIMEOUT_MS);
    }
  }, [isAnimating, flexGrow]);

  return (
    <div className="wasm-animation-container" style={animationContainerStyles}>
      {isShowAnimationDisclaimer && (
        <div className="wasm-animation-disclaimer">
          InBrace Smile Design Animation shows simulated tooth movements intended for visualization purpose only.
          <br />
          Actual clinical tooth movements and results may vary.
        </div>
      )}
      <div className="wasm-animation-progress-bar-container">
        <AnimationIcon />
        <div className="wasm-animation-progress-bar">
          <div style={{ flexGrow, transitionDelay: `${WASM_ANIMATION_PROGRESS_INTERVAL_MS / 1000}ms` }} />
        </div>
      </div>
    </div>
  );
}

WasmAnimationProgressBar.propTypes = {
  onToggleOption: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onToggleOption: onToggleOption,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(WasmAnimationProgressBar);
