import { Camera } from './components/camera.js';
import { createLights } from './components/lights.js';
import { createScene } from './components/scene.js';
import { SetupManager } from './components/SetupManager';
import { Grid } from './components/Grid';

import { createControls } from './systems/controls.js';
import { createRenderer } from './systems/renderer.js';
import { Resizer } from './systems/Resizer.js';
import { Loop } from './systems/Loop.js';

import { getScreenScale } from './common/functions';

/**
 * Class that holds the setup viewer together
 */
class Viewer {
  constructor(container, pane, resetView) {
    this.pane = pane;
    this.resetView = resetView;
    this.setupManager = new SetupManager();
    this.camera = new Camera(container, this.setupManager, this.update);
    this.renderer = createRenderer();
    this.scene = createScene(pane);
    this.loop = new Loop(this.camera.getCamera(), this.scene, this.renderer);
    container.append(this.renderer.domElement);

    this.controls = createControls(this.camera.getCamera(), this.renderer.domElement);

    const lights = createLights();
    this.setupManager.addToScene(this.scene);
    this.camera.addChildren(lights);
    this.camera.addToScene(this.scene);

    this.grid = new Grid(this.camera.getCamera(), this.renderer);
    this.grid.addToScene(this.scene);
    this.controls.addEventListener('change', this.update);
    this.camera.setControls(this.controls);

    this.resizer = new Resizer(container, this.camera.getCamera(), this.renderer, this.update);
  }

  /**
   * Renders a single frame
   * @function
   */
  render = () => {
    this.renderer.render(this.scene, this.camera.getCamera());
  };

  /**
   * Starts the animation loop
   * @function
   */
  start = () => {
    this.loop.start();
  };

  /**
   * Stops the animation loop
   * @function
   */
  stop = () => {
    this.loop.stop();
    this.resizer.removesResizer();
  };

  /**
   * Cleans up the setup viewer from memory
   * @function
   */
  dispose = () => {
    this.stop();
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    this.setupManager.dispose();
    this.renderer.dispose();
    this.scene.dispose();
    this.controls.dispose();
  };

  /**
   * Handles window resizing
   * @function
   */
  updateViewerSizeToWindowSize = () => {
    this.resizer.handlesWindowResize();
  };

  /**
   * Updates everything in the setup viewer
   * @function
   * @param {Object} [controls] - The control object from change event listener
   */
  update = (controls) => {
    this.updateIPR();
    this.grid.draw(this.pane);
    if (controls && this.camera.view !== '') {
      this.resetView(this.pane);
      this.camera.resetView();
    }
  };

  /**
   * Updates the ipr
   * @function
   */
  updateIPR = () => {
    const textSize = getScreenScale(this.camera.getCamera(), this.renderer) * 0.4;
    const quat = this.camera.getCamera().quaternion;
    this.setupManager.updateIPR(textSize, quat);
  };

  /**
   * Loads the meshes into SetupManager
   * @function
   * @param {String} setupNum - The setup's number
   * @param {Object} setupMeshes - The setup's meshes
   * @param {Object} ipr_json - The ipr json
   */
  loadSetupMeshes = (setupNum, setupMeshes, ipr_json) => {
    this.setupManager.loadStepMeshes(setupNum, setupMeshes, ipr_json);
    this.update();
  };

  /**
   * Updates the view 
   * @function
   * @param {Object} viewOptions - The view options
   */
  setViewOptions = (viewOptions) => {
    this.setupManager.setViewOptions(viewOptions);
    this.grid.show(viewOptions.grid);
    this.camera.setView(viewOptions.view, this.setupManager);
    this.update();
  };
}

export { Viewer };
