import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { Uploader } from './uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faBan } from '@fortawesome/free-solid-svg-icons';
import { getAllowedFileType } from './file_upload_helper';
import './simple_file_upload.scss';

const UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS';
const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
const UPLOAD_ERROR = 'UPLOAD_ERROR';
const INIT_FILE_STATUS = {
  state: UPLOAD_IN_PROGRESS,
  size: 0,
  progress: 0,
  uploader: undefined,
  message: '',
};
const UPLOAD_ICON = <FontAwesomeIcon icon={faUpload} size="5x" />;
const REJECT_ICON = <FontAwesomeIcon icon={faBan} size="5x" />;
const DEFAULT_REJECT_MESSAGE = <div>{REJECT_ICON}</div>;
const DEFAULT_UPLOAD_MESSAGE = (
  <>
    <div>{UPLOAD_ICON}</div>
    <span className="emphasis">
      Drag and drop or <span className="midnight">browse</span>
    </span>
  </>
);

class SimpleFileUpload extends Component {
  getDropZoneClassName = () => {
    const classnames = ['fileUpload-box', 'center-text'];
    if (this.props.className) {
      classnames.push(this.props.className);
    }
    return classnames.join(' ');
  };

  getDragUploadDisplay = () => {
    return (
      <div className="fileUpload-content">
        <i className="fa fa-cloud-upload fa-5x" aria-hidden="true" />
        <div className="emphasis">Upload File</div>
      </div>
    );
  };

  getNormalDisplay = () => {
    return (
      <div className="fileUpload-content">
        {this.props.displayMessage}
        {this.props.uploadMessage}
      </div>
    );
  };

  getUploadRejectDisplay = () => {
    return <div className="fileUpload-content">{this.props.rejectMessage}</div>;
  };

  canUpload(file) {
    const not_duplicate_filename = !(file.name in this.props.fileStatus);
    const is_upload_failed = this.props.fileStatus?.[file.name]?.state === UPLOAD_ERROR;

    return not_duplicate_filename || is_upload_failed;
  }

  onUploadFile = (files) => {
    const newFileStatus = {};
    for (const file of files) {
      if (this.canUpload(file)) {
        newFileStatus[file.name] = { ...INIT_FILE_STATUS, size: file.size };
      }
    }
    if (newFileStatus) {
      this.props.setFileStatus({ ...newFileStatus, ...this.props.fileStatus });
      this.startUploadFiles(files);
    }
  };

  updateFileState = (filename, new_state) => {
    const { fileStatus } = this.props;
    const newFileStatus = {
      ...fileStatus,
      [filename]: {
        ...fileStatus[filename],
        ...new_state,
      },
    };
    this.props.setFileStatus(newFileStatus);
  };

  onCompleteFileUpload(filename, response) {
    this.props.onCompleteUpload(filename, response.data['Location']);
  }

  startUploadFiles(files) {
    for (const file of files) {
      const filename = file.name;
      const options = {
        filename: filename,
        file: file,
        uploadParams: this.props.uploadParams,
      };

      const uploader = new Uploader(options);
      uploader
        .onProgress(({ percentage: newPercentage }) => {
          this.updateFileState(filename, { progress: newPercentage });
        })
        .onComplete((response) => {
          this.updateFileState(filename, { state: UPLOAD_SUCCESS });
          this.onCompleteFileUpload(filename, response);
        })
        .onError((error) => {
          console.log(error);
          this.updateFileState(filename, { state: UPLOAD_ERROR, message: error.response.data.message });
        });

      this.updateFileState(filename, { uploader: uploader });
      uploader.start();
    }
  }

  onUploadReject = () => {
    console.log('onUploadReject');
  };

  render() {
    const { type, multiple, disabled } = this.props;

    return (
      <Dropzone
        className={this.getDropZoneClassName()}
        onDrop={this.onUploadFile}
        onDropRejected={this.onUploadReject}
        accept={getAllowedFileType(type)}
        multiple={multiple}
        disabled={disabled}
      >
        {({ isDragActive, isDragAccept, isDragReject, draggedFiles, acceptedFiles, rejectedFiles }) => {
          if (isDragAccept) return this.getDragUploadDisplay();
          else if (isDragReject) return this.getUploadRejectDisplay();
          return this.getNormalDisplay();
        }}
      </Dropzone>
    );
  }
}

SimpleFileUpload.propTypes = {
  fileStatus: PropTypes.object.isRequired,
  setFileStatus: PropTypes.func.isRequired,
  onCompleteUpload: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  displayMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rejectMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  uploadMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SimpleFileUpload.defaultProps = {
  multiple: true,
  disabled: false,
  type: '',
  displayMessage: '',
  rejectMessage: DEFAULT_REJECT_MESSAGE,
  uploadMessage: DEFAULT_UPLOAD_MESSAGE,
};

export default SimpleFileUpload;
