import _ from 'lodash';
import Axios from 'axios';
import {
  clearSelectedTeeth,
  setSelectedTeeth,
  setMessage,
  setTreatmentArch,
  setTreatmentPlanRevision as setTeethChartTreatmentPlanRevisionAction,
} from '../../common/teeth_chart';
import { orderTeeth, textFieldCheck, removeEmoji } from '../../../../common/functions';
import { isSegmentationTxGuideFormCompleted } from '../../../../common/helpers';

// Action Types
export const OPEN_TEETH_SELECTOR_MODAL = 'OPEN_TEETH_SELECTOR_MODAL';
export const CLOSE_TEETH_SELECTOR_MODAL = 'CLOSE_TEETH_SELECTOR_MODAL';
export const FETCH_PRODUCTION_TX_GUIDE_PENDING = 'FETCH_PRODUCTION_TX_GUIDE_PENDING';
export const FETCH_PRODUCTION_TX_GUIDE_ERROR = 'FETCH_PRODUCTION_TX_GUIDE_ERROR';
export const SET_NOTE = 'SET_NOTE';
export const SET_INCOMPLETE_ERROR = 'SET_INCOMPLETE_ERROR';
export const UPDATE_TREATMENT_GUIDE_SUCCESS = 'UPDATE_TREATMENT_GUIDE_SUCCESS';
export const UPDATE_TREATMENT_GUIDE_ERROR = 'UPDATE_TREATMENT_GUIDE_ERROR';
export const UPDATE_TREATMENT_GUIDE_PENDING = 'UPDATE_TREATMENT_GUIDE_PENDING';
export const SET_EDIT_MODE_SEGMENTATION = 'SET_EDIT_MODE_SEGMENTATION';
export const SET_SEGMENTATION_QUESTIONS = 'SET_SEGMENTATION_QUESTIONS';
export const OPEN_SEGMENTATION_TX_GUIDE_PDF_MODAL = 'OPEN_SEGMENTATION_TX_GUIDE_PDF_MODAL';
export const CLOSE_SEGMENTATION_TX_GUIDE_PDF_MODAL = 'CLOSE_SEGMENTATION_TX_GUIDE_PDF_MODAL';
export const SET_SEGMENTATION_TX_GUIDE_FILE_PATH = 'SET_SEGMENTATION_TX_GUIDE_FILE_PATH';
export const SET_SEGMENTATION_SESSION_EXPIRED = 'SET_SEGMENTATION_SESSION_EXPIRED';
export const SET_SEGMENTATION_QUESTIONS_OLD = 'SET_SEGMENTATION_QUESTIONS_OLD';
export const SET_TREATMENT_PLAN_REVISION = 'SET_TREATMENT_PLAN_REVISION';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Toggles edit mode for treatment guide
 * @function
 * @param {Boolean} is_edit_mode - True or False
 * @return {Object} Action type
 */
export function setEditModeSegmentation(is_edit_mode) {
  return {
    type: SET_EDIT_MODE_SEGMENTATION,
    is_edit_mode_segmentation: is_edit_mode,
  };
}

/**
 * Handles event when user cancels editing the production tx guide
 * @function
 * @return {Object} Action type
 */
export function onEditSegmentationTxGuideCancel() {
  return (dispatch) => {
    dispatch(fetchProductionTxGuide());
    dispatch(setIncompleteError(false));
  };
}

/**
 * Opens teeth selector modal
 * @function
 * @param {String} selected_category - Category of selected question
 * @param {String} selected_question - Selected question
 * @return {Object} Action type
 */
export function openTeethSelectorModal(selected_category, selected_question) {
  return (dispatch, getState) => {
    const { segmentation_questions, treatment_plan_revision } = getState().segmentationProductionTxGuideReducer;
    const selected_teeth = segmentation_questions[selected_category][selected_question].selected_teeth;
    dispatch(setTeethChartTreatmentPlanRevisionAction(treatment_plan_revision))
    dispatch({
      type: OPEN_TEETH_SELECTOR_MODAL,
      teeth_selector_category: selected_category,
      teeth_selector_question: selected_question,
    });
    dispatch(setSelectedTeeth(selected_teeth));
    dispatch(setTreatmentArch('both'));
    dispatch(setMessage(segmentation_questions[selected_category][selected_question].modal_message));
  };
}

/**
 * Closes teeth selector modal
 * @function
 * @return {Object} Action type
 */
export function closeTeethSelectorModal() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_TEETH_SELECTOR_MODAL,
    });
    dispatch(clearSelectedTeeth());
  };
}

/**
 * Retrieves treatment guide for a case
 * @function
 * @return {Object} Action type
 */
export function fetchProductionTxGuide() {
  return (dispatch, getState) => {
    const { case_id } = getState().segmentationProductionTxGuideReducer;
    dispatch(fetchProductionTxGuidePending(case_id));

    Axios.get(`/apiv3/productiontxguide/${case_id}`)
      .then((res) => {
        dispatch(setSegmentationProductionTxGuide(case_id, res.data));
        dispatch(setEditModeSegmentation(false));
      })
      .catch((error) => {
        dispatch(fetchProductionTxGuideError());
      });
  };
}

/**
 * Sets selections for the case's treatment guide
 * @function
 * @param {String} case_id - Case id
 * @param {Object} updates - Selections to update the treatment guide with
 * @return {Object} Action type
 */
export function setSegmentationProductionTxGuide(case_id, updates) {
  return (dispatch, getState) => {
    const { segmentation_questions } = getState().segmentationProductionTxGuideReducer;
    let segmentation_questions_updated = _.cloneDeep(segmentation_questions);
    segmentation_questions_updated = setSelections(segmentation_questions_updated, updates);
    let segmentation_questions_old = _.cloneDeep(segmentation_questions_updated);

    dispatch(setIncompleteError(false));

    dispatch({
      type: SET_SEGMENTATION_QUESTIONS,
      segmentation_questions: segmentation_questions_updated,
      note: updates.segmentation_notes,
      case_id: case_id,
    });

    segmentation_questions_old['segmentation_notes'] = updates.segmentation_notes;
    dispatch({
      type: SET_SEGMENTATION_QUESTIONS_OLD,
      segmentation_questions_old: segmentation_questions_old,
    });
  };
}

/**
 * Sets treatment guide selections
 * @function
 * @param {Object} segmentation_questions - Treatment guide questions
 * @param {Object} updates - Selections to update the treatment guide with
 * @return {Object} Updated treatment guide
 */
export function setSelections(segmentation_questions, updates) {
  const selected_teeth = updates.segmentation_selected_teeth;

  for (const category in segmentation_questions) {
    for (const question in segmentation_questions[category]) {
      const question_object = segmentation_questions[category][question];

      question_object.is_selected = updates[question_object.translated_name];

      if (question_object.translated_name in selected_teeth) {
        question_object.selected_teeth = selected_teeth[question_object.translated_name];
      } else if (question_object.selected_teeth.length > 0) {
        question_object.selected_teeth = [];
      }
    }
  }
  return segmentation_questions;
}

export function fetchProductionTxGuidePending(case_id) {
  return {
    type: FETCH_PRODUCTION_TX_GUIDE_PENDING,
    case_id: case_id,
  };
}

export function fetchProductionTxGuideError() {
  return {
    type: FETCH_PRODUCTION_TX_GUIDE_ERROR,
  };
}

/**
 * API call for updating the production tx guide
 * @function
 * @param {String} case_id - Case ID
 * @param {Object} updates - Selections to update the treatment guide with
 * @return {Promise} Promise from API call
 */
export function updateSegmentationProductionTxGuide(case_id, updates) {
  return Axios.put(`/apiv3/productiontxguide/${case_id}/segmentation`, updates);
}

export function updateTreatmentGuidePending() {
  return {
    type: UPDATE_TREATMENT_GUIDE_PENDING,
  };
}

export function updateTreatmentGuideSuccess(segmentation_questions) {
  return {
    type: UPDATE_TREATMENT_GUIDE_SUCCESS,
    segmentation_questions: segmentation_questions,
  };
}

export function updateTreatmentGuideError() {
  return {
    type: UPDATE_TREATMENT_GUIDE_ERROR,
  };
}

/**
 * Handles event when user clicks on a question checkbox
 * @function
 * @param {Object} event - Event object
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onSelectQuestion(event, is_tab_view) {
  return (dispatch, getState) => {
    const { segmentation_questions, case_id, segmentation_questions_old } = getState().segmentationProductionTxGuideReducer;
    const category = event.currentTarget.dataset.category;
    const selected_question = event.currentTarget.dataset.question;

    let segmentation_questions_updated = _.cloneDeep(segmentation_questions);

    const question = segmentation_questions_updated[category][selected_question];
    question.is_selected = !question.is_selected;

    if (is_tab_view) {
      dispatch(updateTreatmentGuideSuccess(segmentation_questions_updated));
    } else {
      const data = {
        update_data: {
          [category]: {
            [selected_question]: question,
          },
        },
        old_values: segmentation_questions_old,
      };

      updateSegmentationProductionTxGuide(case_id, data)
        .then((res) => {
          dispatch(updateTreatmentGuideSuccess(segmentation_questions_updated));
          let updated_segmentation_questions_old = _.cloneDeep(segmentation_questions_updated);
          dispatch({
            type: SET_SEGMENTATION_QUESTIONS_OLD,
            segmentation_questions_old: updated_segmentation_questions_old,
          });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            dispatch({
              type: SET_SEGMENTATION_SESSION_EXPIRED,
              session_expired: true,
            });
          }
          dispatch(updateTreatmentGuideError());
        });
    }
  };
}

/**
 * Handles event when user saves teeth selection from modal
 * @function
 * @param {Boolean} is_tab_view - Indicator if treatment guide form is in tab view
 * @return {Object} Action type
 */
export function onConfirmButtonClickTeethSelector(is_tab_view) {
  return (dispatch, getState) => {
    const { selected_teeth } = getState().teethChartReducer;
    const { segmentation_questions, teeth_selector_category, teeth_selector_question, case_id, segmentation_questions_old } =
      getState().segmentationProductionTxGuideReducer;
    let segmentation_questions_updated = _.cloneDeep(segmentation_questions);

    const question = segmentation_questions_updated[teeth_selector_category][teeth_selector_question];
    question.selected_teeth = orderTeeth(selected_teeth);

    if (is_tab_view) {
      dispatch(updateTreatmentGuideSuccess(segmentation_questions_updated));
      dispatch(closeTeethSelectorModal());
    } else {
      const data = {
        update_data: {
          [teeth_selector_category]: {
            [teeth_selector_question]: question,
          },
        },
        old_values: segmentation_questions_old,
      };
      updateSegmentationProductionTxGuide(case_id, data)
        .then((res) => {
          dispatch(updateTreatmentGuideSuccess(segmentation_questions_updated));
          dispatch(closeTeethSelectorModal());
          let updated_segmentation_questions_old = _.cloneDeep(segmentation_questions_updated);
          dispatch({
            type: SET_SEGMENTATION_QUESTIONS_OLD,
            segmentation_questions_old: updated_segmentation_questions_old,
          });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            dispatch({
              type: SET_SEGMENTATION_SESSION_EXPIRED,
              session_expired: true,
            });
          }
          dispatch(updateTreatmentGuideError());
        });
    }
  };
}

/**
 * Saves entire production tx guide selections from tab in case details
 * @function
 * @return {Object} Action type
 */
export function saveSegmentationProductionTxGuideSelections() {
  return (dispatch, getState) => {
    const { segmentation_questions, case_id, note, segmentation_questions_old } = getState().segmentationProductionTxGuideReducer;

    if (isSegmentationTxGuideFormCompleted(segmentation_questions)) {
      const segmentation_questions_updated = _.cloneDeep(segmentation_questions);

      for (const category in segmentation_questions_updated) {
        for (const question in segmentation_questions_updated[category]) {
          if (!segmentation_questions_updated[category][question].is_selected && segmentation_questions_updated[category][question].selected_teeth.length > 0) {
            segmentation_questions_updated[category][question].selected_teeth = [];
          }
        }
      }
      dispatch({
        type: SET_SEGMENTATION_QUESTIONS,
        segmentation_questions: segmentation_questions_updated,
        note: note,
        case_id: case_id,
      });

      const data = { update_data: _.cloneDeep(segmentation_questions_updated), old_values: segmentation_questions_old };
      data['update_data']['segmentation_notes'] = note;

      updateSegmentationProductionTxGuide(case_id, data)
        .then((res) => {
          dispatch(setEditModeSegmentation(false));
          let updated_segmentation_questions_old = _.cloneDeep(segmentation_questions_updated);
          updated_segmentation_questions_old['segmentation_notes'] = note;
          dispatch({
            type: SET_SEGMENTATION_QUESTIONS_OLD,
            segmentation_questions_old: updated_segmentation_questions_old,
          });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            dispatch({
              type: SET_SEGMENTATION_SESSION_EXPIRED,
              session_expired: true,
            });
          }
          dispatch(updateTreatmentGuideError());
        });
    } else {
      dispatch(setIncompleteError(true));
    }
  };
}

/**
 * Handles event when user enters a note
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onNoteChange(event) {
  return {
    type: SET_NOTE,
    note: removeEmoji(textFieldCheck(event.target.value)),
  };
}

/**
 * Flags/unflags incomplete error when treatment guide form is incomplete upon clicking Proceed
 * @function
 * @param {Boolean} error - True or False
 * @return {Object} Action type
 */
export function setIncompleteError(error) {
  return {
    type: SET_INCOMPLETE_ERROR,
    error: error,
  };
}

export function openSegmentationTxGuidePdfModal(case_id) {
  return (dispatch) => {
    dispatch({
      type: OPEN_SEGMENTATION_TX_GUIDE_PDF_MODAL,
      file_path: '',
    });
    Axios.post(`/apiv3/productiontxguide/${case_id}/generate`).then((res) => {
      dispatch({
        type: SET_SEGMENTATION_TX_GUIDE_FILE_PATH,
        file_path: res.data.file_path,
      });
    });
  };
}

export function closeSegmentationTxGuidePdfModal() {
  return {
    type: CLOSE_SEGMENTATION_TX_GUIDE_PDF_MODAL,
  };
}

export function setSegmentationQuestionsOld(questions) {
  return {
    type: SET_SEGMENTATION_QUESTIONS_OLD,
    segmentation_questions_old: questions,
  };
}

export function setTreatmentPlanRevision(treatment_plan_revision) {
  return {
    type: SET_TREATMENT_PLAN_REVISION,
    treatment_plan_revision: treatment_plan_revision,
  };
}
