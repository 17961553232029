import Axios from 'axios';

import { setNoteModal } from '../ipp/case_list';
// Action Types
export const FETCH_PROSPECTS_PENDING = 'FETCH_PROSPECTS_PENDING';
export const FETCH_PROSPECTS_SUCCESS = 'FETCH_PROSPECTS_SUCCESS';
export const FETCH_PROSPECTS_ERROR = 'FETCH_PROSPECTS_ERROR';

// Action Creators
// -----------------------------------------------------------------------------
// FETCH
export function fetchProspectsList(doctor_id = '') {
  return (dispatch, getState) => {
    dispatch(fetchProspectsPending());
    Axios.get(`/apiv3/prospect?doctor_id=${doctor_id}`)
      .then(function (res) {
        const prospects = res.data.prospects;
        const status_count = res.data.status_count;
        dispatch(fetchProspectsSuccess(prospects, status_count));

        return res;
      })
      .catch(function (err) {
        dispatch(fetchProspectsError(err));
      });
  };
}

export function fetchProspectsPending() {
  return {
    type: FETCH_PROSPECTS_PENDING,
  };
}

export function fetchProspectsSuccess(prospects, status_count) {
  return {
    type: FETCH_PROSPECTS_SUCCESS,
    prospects: prospects,
    prospects_count: prospects.length,
    status_count: status_count,
  };
}

export function fetchProspectsError(error) {
  return {
    type: FETCH_PROSPECTS_ERROR,
    error: error,
  };
}

export function clearProspectsList() {
  return (dispatch, getState) => {
    const { status_count } = getState().prospectsReducer;
    let empty_count = {};
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(status_count)) {
      empty_count[key] = 0;
    }

    dispatch(fetchProspectsSuccess([], empty_count));
  };
}

/**
 * Edit prospect note API
 * @function
 * @param {string} prospect_id - Prospect id
 * @param {object} note - Updated note
 * @param {function} refreshList - Refresh state list function
 * @returns {object} - Action object
 */
export function updateProspectNote(prospect_id, note, refreshList) {
  return (dispatch) => {
    const data = {
      id: note.id,
      prospect_id: prospect_id,
      subject: note.subject,
      note_text: note.text,
    };

    if (note.id) {
      Axios.put(`/apiv3/globalnotes/${note.id}`, data)
        .then((res) => {
          dispatch(onNoteSuccess(prospect_id, res, refreshList));
        })
        .catch((err) => {});
    } else {
      Axios.post(`/apiv3/globalnotes`, data)
        .then((res) => {
          dispatch(onNoteSuccess(prospect_id, res, refreshList));
        })
        .catch((err) => {});
    }
  };
}

/**
 * Handles note edit/create success event
 * @function
 * @param {string} prospect_id - Prospect id
 * @param {object} res - API result
 * @param {function} refreshList - Refresh state list function
 * @returns {object} - Action object
 */
function onNoteSuccess(prospect_id, res, refreshList) {
  return (dispatch) => {
    dispatch(setNoteModal(false));
    const prospect_notes = res.data.prospect_notes;
    if (prospect_notes.length > 0) {
      refreshList(prospect_id, prospect_notes[prospect_notes.length - 1]);
    }
  };
}
