const US_STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const IFUFileExtension = {
  DigiBP: 'DigiBP',
  Matic: '3Matic',
};

const CaseStage = {
  Stage1: 'Stage I',
  Stage2: 'Stage II',
  Stage3: 'Stage III',
  QualityControlStage1: 'Quality Control Stage I',
};

const CaseAutomationStatus = {
  ReadyForGeneration: 'Ready For Generation',
  GenerationInProcess: 'Generation In Progress',
  GenerationOk: 'Generation Success',
  GenerationFail: 'Generation Failed',
  LabelSelection: 'Label Selection',

  TitanGenerationOk: 'Titan Generation Success',
  TitanGenerationFail: 'Titan Generation Failed',

  BPGGeneration: 'BPG Generation Process',
  BPGGenerationOk: 'BPG Generation Success',
  BPGGenerationFail: 'BPG Generation Failed',
  BPGGen2Generation: 'BPG Gen2 Generation Process',

  TxInstructionGeneration: 'Tx Instruction Generation Process',
  TxInstructionGenerationOk: 'Tx Instruction Generation Success',
  TxInstructionGenerationFail: 'Tx Instruction Generation Failed',
  TxInstructionGen2Generation: 'Tx Instruction Gen2 Generation Process',

  WSConversion: 'WS Conversion',
  WSConversionOk: 'WS Conversion Succeeded',
  WSConversionFail: 'WS Conversion Failed',
  WSManual: 'WS Manual Process',
  WSGuide: 'WS Guide',
  WSReview: 'WS Review',
  WSComplete: 'WS Complete',
  WSRedo: 'WS Redo',
  WS_WO: 'WS WO Status',

  WFRRework: 'WFR Rework',

  IfuFileUploaded: 'IFU Input File Uploaded',
  IfuFileRemoved: 'IFU Input File Removed',
  IfuGenerationReprocess: 'IFU Generation Reprocess',

  IfuReadyForDigiBPConversion: 'DigiBP Ready For Conversion',
  IfuDigiBPConversion: 'DigiBP Conversion In Process',
  IfuDigiBPConversionOk: 'DigiBP Conversion Succeeded',
  IfuDigiBPConversionFail: 'DigiBP Conversion Failed',

  DdmFileUploaded: 'DDM Input File Uploaded',
  DdmFileRemoved: 'DDM Input File Removed',
  DdmGenerationReprocess: 'DDM Generation Reprocess',
};

const CaseAutomationProcessType = {
  Labels: 'labels',
  DDM_Main: 'ifu_main',
  IFU_Main: 'ifu_main',
  IFU_BPG: 'ifu_bpg',
  IFU_TxInstructions: 'ifu_txinstruction',
  DDMGeneration: 'ddm_generation',
  TitanMain: 'titan_main',
  WS: 'WS',
  WFR: 'WFR',
};

const INBRACE_LEARNING_URL = 'https://learning.inbrace.com';

export { US_STATES, IFUFileExtension, CaseStage, CaseAutomationStatus, CaseAutomationProcessType, INBRACE_LEARNING_URL };
