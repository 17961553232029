import React from 'react';
import './treatment_navigator.scss';

function TreatmentNavigatorBanner(props) {
  return (
    <div className="banner">
      <div className="treatmentBanner">
        <div className="text_conents">
          <h1>{props.title}</h1>
          <p className="navigator_content">{props.content}</p>
        </div>
        <div>
          <button className="btn btn--primary" onDoubleClick={props.onDoubleClick} onClick={props.onConfirmClicked} disabled={!props.has_edit_permission}>
            {props.has_edit_permission ? '' : <i className="fa fa-lock lock_icon"></i>}
            {props.confirm_btn_text}
          </button>
          <button className="btn btn--close" onDoubleClick={props.onDoubleClick} onClick={props.onCloseClicked} disabled={!props.has_edit_permission}>
            {props.close_btn_text}
          </button>
        </div>
      </div>
      <img className="right_img" src={props.right_img_url} alt="smiling face"></img>
    </div>
  );
}

export default TreatmentNavigatorBanner;
