import React from 'react';
import Btn from '../../components/button/teeth_button';

class Refinement extends React.Component {
  componentDidMount() {
    let that = this;
    setTimeout(function() {
      that.setTreatmentArch();
    }, 0);
  }

  componentDidUpdate() {
    this.setTreatmentArch();
  }

  setTreatmentArch() {
    let upper = document.querySelectorAll('.upper-set');
    let lower = document.querySelectorAll('.lower-set');

    //Nodelist
    for (let i = 0; i < upper.length; i++) {
      if (this.props.dxtxSection2_toothUpper) {
        upper[i].classList.add('show');
        // upper[i].classList.remove('hide');
      } else {
        // upper[i].classList.remove('show');
        // upper[i].classList.add('hide');
      }
    }

    for (let i = 0; i < lower.length; i++) {
      if (this.props.dxtxSection2_toothLower) {
        lower[i].classList.add('show');
        // lower[i].classList.remove('hide');
      } else {
        // lower[i].classList.remove('show');
        // lower[i].classList.add('hide');
      }
    }
  }

  render() {
    return (
      <div className="portal-view">
        <form>
          <div className="row">
            <div className="col-md-12">
              <label className="dxtx-heading" id="intraOral">
                Intraoral Scan Date *
              </label>
              <div>
                <input
                  id="scanDate"
                  type="date"
                  className="form-control patient-field"
                  placeholder="Date of Birth:"
                  data-field="scanDate"
                  onChange={this.props.onInputChange_refinement}
                  defaultValue={this.props.scanDate}
                  onFocus={this.props.onFocus}
                />
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading" id="reason">
                1. Reason for digital enhancement *
              </label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box" id="reasonSelection" onFocus={this.props.onFocus}>
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reasonChange"
                      value="Treatment plan changes"
                      data-field="reason"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.reason === 'Treatment plan changes'}
                    />
                    <label className="form-check-label" htmlFor="reasonChange">
                      Treatment plan changes
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      id="reasonImprovement"
                      value="Need finishing improvements (specify in item 7, where and what digital enhancement is needed)"
                      data-field="reason"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.reason === 'Need finishing improvements (specify in item 7, where and what digital enhancement is needed)'}
                    />
                    <label className="form-check-label" htmlFor="reasonImprovement">
                      Need finishing improvements (specify in item 7, where and what enhancement is needed)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading" id="smartwire">
                2. Which Smartwire<span className="trademark">&reg;</span> is the patient currently wearing?
              </label>
            </div>
            <div className="col-md-12">
              <label className="dxtx-heading" id="upperSmartwire">
                Upper Smartwire<span className="trademark">&reg;</span>
              </label>
            </div>
            <div className="col-md-12">
              <div className="row dxtx-group-box" id="upperSmartWireSelection" onFocus={this.props.onFocus}>
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="upper"
                      id="upperSmartWire14N"
                      value='Stage I Smartwire (0.014" NiTi)'
                      data-field="upperSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.upperSmartWire === 'Stage I Smartwire (0.014" NiTi)'}
                    />
                    <label className="form-check-label" htmlFor="upperSmartWire14N">
                      Stage I Smartwire<span className="trademark">&reg;</span> (0.014" NiTi)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="upper"
                      id="upperSmartWire16N"
                      value='Stage II Smartwire (0.016" NiTi)'
                      data-field="upperSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.upperSmartWire === 'Stage II Smartwire (0.016" NiTi)'}
                    />
                    <label className="form-check-label" htmlFor="upperSmartWire16N">
                      Stage II Smartwire<span className="trademark">&reg;</span> (0.016" NiTi)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="upper"
                      id="upperSmartWire16S"
                      value='0.016" SS'
                      data-field="upperSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.upperSmartWire === '0.016" SS'}
                    />
                    <label className="form-check-label" htmlFor="upperSmartWire16S">
                      0.016" SS
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading" id="lowerSmartwire">
                Lower Smartwire<span className="trademark">&reg;</span>
              </label>
            </div>
            <div className="col-md-12">
              <div className="row dxtx-group-box" id="lowerSmartWireSelection" onFocus={this.props.onFocus}>
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lower"
                      id="lowerSmartWire14N"
                      value='Stage I Smartwire (0.014" NiTi)'
                      data-field="lowerSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.lowerSmartWire === 'Stage I Smartwire (0.014" NiTi)'}
                    />
                    <label className="form-check-label" htmlFor="lowerSmartWire14N">
                      Stage I Smartwire<span className="trademark">&reg;</span> (0.014" NiTi)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lower"
                      id="upperSmartWire16NI"
                      value='Stage II Smartwire (0.016" NiTi)'
                      data-field="lowerSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.lowerSmartWire === 'Stage II Smartwire (0.016" NiTi)'}
                    />
                    <label className="form-check-label" htmlFor="upperSmartWire16NI">
                      Stage II Smartwire<span className="trademark">&reg;</span> (0.016" NiTi)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lower"
                      id="lowerSmartWire16NIStraight"
                      value='Stage IIa Smartwire (0.016" Straight NiTi)'
                      data-field="lowerSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.lowerSmartWire === 'Stage IIa Smartwire (0.016" Straight NiTi)'}
                    />
                    <label className="form-check-label" htmlFor="lowerSmartWire16NIStraight">
                      Stage IIa Smartwire<span className="trademark">&reg;</span> (0.016" Straight NiTi)
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="lower"
                      id="lowerSmartWire16S"
                      value='0.016" SS'
                      data-field="lowerSmartWire"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.lowerSmartWire === '0.016" SS'}
                    />
                    <label className="form-check-label" htmlFor="lowerSmartWire16S">
                      0.016" SS
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading" id="setup">
                3. How would you like your digital enhancement treatment plan set up? *
              </label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box" id="setupSelection" onFocus={this.props.onFocus}>
                <div className="col-md-12">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="setup"
                      id="setupSame"
                      value="Same final position as the original virtual setup approved"
                      data-field="setup"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.setup === 'Same final position as the original virtual setup approved'}
                    />
                    <label className="form-check-label" htmlFor="setupSame">
                      Same final position as the original virtual setup approved
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="setup"
                      id="setupImprove"
                      value="Accept current occlusion but improve on detailing"
                      data-field="setup"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.setup === 'Accept current occlusion but improve on detailing'}
                    />
                    <label className="form-check-label" htmlFor="setupImprove">
                      Accept current occlusion but improve on detailing
                    </label>
                  </div>
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="setup"
                      id="setupOther"
                      value="Other (specify in item 7)"
                      data-field="setup"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.setup === 'Other (specify in item 7)'}
                    />
                    <label className="form-check-label" htmlFor="setupOther">
                      Other (specify in item 7)
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading" id="restriction">
                4. Tooth movement restrictions
              </label>
            </div>

            <div className="col-md-12">
              <div className="row dxtx-group-box" id="restrictionSelection" onFocus={this.props.onFocus}>
                <div className="col-md-12 div-padding-b1">
                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="restriction"
                      id="restrictionNone"
                      value="None"
                      data-field="restrictions"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.restrictions === 'None'}
                    />
                    <label className="form-check-label" htmlFor="restrictionNone">
                      None
                    </label>
                  </div>

                  <div className="checkbox">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="restriction"
                      id="restrictionFollowing"
                      value="The following teeth should not be moved"
                      data-field="restrictions"
                      onChange={this.props.onInputChange_refinement}
                      defaultChecked={this.props.restrictions === 'The following teeth should not be moved'}
                    />
                    <label className="form-check-label" htmlFor="restrictionFollowing">
                      The following teeth should not be moved
                    </label>
                  </div>
                </div>

                {this.props.restrictions === 'None' ? null : (
                  <div className="col-md-12">
                    <div className="col-md-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Btn btname="UR8" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR7" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR6" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR5" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR4" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR3" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR2" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UR1" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Btn btname="UL1" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL2" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL3" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL4" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL5" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL6" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL7" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="UL8" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Btn btname="LR8" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR7" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR6" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR5" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR4" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR3" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR2" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LR1" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Btn btname="LL1" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL2" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL3" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL4" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL5" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL6" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL7" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                        <Btn btname="LL8" onBtnClick={this.props.onInputChange_refinement} btnState={this.props.restrictionsTeeth} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">5. IPR for this treatment plan</label>
            </div>
            <div className="col-md-12 div-padding-b1">
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  name="ipr"
                  id="iprNone"
                  value="Do not perform any IPR"
                  data-field="ipr"
                  onChange={this.props.onInputChange_refinement}
                  defaultChecked={this.props.ipr === 'Do not perform any IPR'}
                />
                <label className="form-check-label" htmlFor="iprNone">
                  Do not perform any IPR
                </label>
              </div>
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  name="ipr"
                  id="iprAsNeeded"
                  value="Perform IPR as needed"
                  data-field="ipr"
                  onChange={this.props.onInputChange_refinement}
                  defaultChecked={this.props.ipr === 'Perform IPR as needed'}
                />
                <label className="form-check-label" htmlFor="iprAsNeeded">
                  Perform IPR as needed
                </label>
              </div>
              <div className="checkbox">
                <input
                  className="form-check-input"
                  type="radio"
                  name="ipr"
                  id="iprSpecial"
                  value="Special IPR requirements"
                  data-field="ipr"
                  onChange={this.props.onInputChange_refinement}
                  defaultChecked={this.props.ipr === 'Special IPR requirements'}
                />
                <label className="form-check-label" htmlFor="iprSpecial">
                  Special IPR requirements
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <textarea
                className="form-control dxtx-comment"
                placeholder=""
                id="iprDialog"
                rows="4"
                data-field="iprDialog"
                onChange={this.props.onInputChange_refinement}
                value={this.props.iprDialog}
                disabled={this.props.ipr !== 'Special IPR requirements'}
              />
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">6. Specify the amount of residual spaces (if any) to be kept:</label>
            </div>
            <div className="col-md-12">
              <textarea
                className="form-control dxtx-comment"
                placeholder=""
                id="residualSpace"
                rows="4"
                data-field="residual"
                onChange={this.props.onInputChange_refinement}
                defaultValue={this.props.residual}
              />
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <label className="dxtx-heading">7. Specify treatment instructions describing where and what digital enhancement is needed for:</label>
            </div>
            <div className="col-md-12 row-padding-b1">
              <label className="dxtx-heading">Upper Arch</label>
            </div>
            <div className="col-md-12 div-margin-b1">
              <textarea
                className="form-control dxtx-comment"
                placeholder=""
                id="treatmentUpper"
                rows="4"
                data-field="upper"
                onChange={this.props.onInputChange_refinement}
                defaultValue={this.props.upper}
              />
            </div>
            <div className="col-md-12 row-padding-b1">
              <label className="dxtx-heading">Lower Arch</label>
            </div>
            <div className="col-md-12">
              <textarea
                className="form-control dxtx-comment"
                placeholder=""
                id="treatmentLower"
                rows="4"
                data-field="lower"
                onChange={this.props.onInputChange_refinement}
                defaultValue={this.props.lower}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default Refinement;
