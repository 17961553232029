import React from 'react';
import PropTypes from 'prop-types';

const types = ['regular', 'form', 'top-border', 'error', 'message', 'error-message'];

/**
 * Wraps the children with a card div
 * @component
 */
function CardContainer({ type, className, ...props }) {
  const typeClassName = types.includes(type) ? type : types[0];
  const classNames = `${className ? className : ''} card card--${typeClassName}`;

  return (
    <div className={classNames} {...props}>
      {props.children}
    </div>
  );
}

CardContainer.propTypes = {
  type: PropTypes.oneOf(types),
};

CardContainer.defaultProps = {
  type: types[0],
};

export default CardContainer;
