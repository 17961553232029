import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../setup_viewer/components/extras/button';
import { getBusinessRoleList } from '../../../common/helpers';
import Modal from '../../setup_viewer/components/modal/modal';
import { getCaseDetails } from '../../../redux/reducers/common/common_case_details';
import { saveRevisionEnd } from '../../../redux/actions/wasm_viewer/wasm_viewer';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { CircleCheckIcon, LoadingIcon } from '../../../doctor/assets/icons';

function displayLoading() {
  return (
    <div className="center">
      <LoadingIcon className="wasm-save-loading" />
      <div>Saving Changes...</div>
      <div>Please do not close this window while saving.</div>
    </div>
  );
}

function displaySuccess(close_modal, return_portal) {
  return (
    <div className="wasm-save-draft-container center">
      <CircleCheckIcon />
      <div>Revision Submitted</div>
      <div>
        <Button theme="success" onClick={return_portal}>
          Return to Portal
        </Button>
        <Button theme="secondary" onClick={close_modal}>
          Close
        </Button>
      </div>
    </div>
  );
}

function displayError(close_modal) {
  return (
    <div>
      <div className="modal-title">Unable to Save Progress</div>
      <div className="modal-body-paragraph">Unable to save progress. Please try again.</div>
      <div className="modal-button-group">
        <Button theme="success" onClick={close_modal}>
          Close
        </Button>
      </div>
    </div>
  );
}

function getModalDisplay(props, close_modal, return_portal) {
  const { save_revision_success, save_revision_error } = props;
  if (!!save_revision_success) {
    return displaySuccess(close_modal, return_portal);
  } else if (!!save_revision_error) {
    return displayError(close_modal);
  } else {
    return displayLoading();
  }
}

function SaveRevisionModal(props) {
  const { case_details, save_revision_loading, save_revision_success, save_revision_error, saveRevisionEnd } = props;

  const closeModal = () => {
    saveRevisionEnd();
  };

  const handleReturn2Portal = () => {
    const { doctor } = case_details;
    const doctorId = doctor.id;
    closeModal();
    props.onReturn2Portal(doctorId);
  };

  return (
    <Modal show={save_revision_loading || save_revision_error} show_close_button={save_revision_success || save_revision_error} onCloseModal={closeModal}>
      <div className="wasm-save-draft-container">{getModalDisplay(props, closeModal, handleReturn2Portal)}</div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const wasmState = getWasmViewerState(state);
  return {
    case_details: getCaseDetails(state),
    save_revision_loading: wasmState.save_revision_loading,
    save_revision_success: wasmState.save_revision_success,
    save_revision_error: wasmState.save_revision_error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRevisionEnd: saveRevisionEnd,
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SaveRevisionModal));
