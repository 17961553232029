import Axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';

import { getDoctorsIdFromRoute } from '../../common/route';
import { getDoctorInfo } from '../../redux/reducers/ipp/dashboard';
import { sendFeedbackRatingEmail } from '../../redux/actions/bpp/feedback_rating_form';
import { ToothRating } from '../../components/rating_form';
import { removeEmoji } from '../../common/functions';

import AlertMessage from './alert_message';
import Button from './buttons/button';

const commentMaxLength = 5000;

const RatingForm = ({ doctorInfo, case_id }) => {
  const [doctorId, setDoctorId] = React.useState(doctorInfo?.doctor_id);
  const [rating, setRating] = React.useState(0);
  const [comment, setComment] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [submitPending, setSubmitPending] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(null);

  const handleCommentChange = (event) => {
    const value = removeEmoji(event.target.value);
    setComment(value);
    if (value.length > commentMaxLength) {
      setErrors((prevErrors) => ({ ...prevErrors, comment: 'Character limit exceeded' }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.comment;
        return newErrors;
      });
    }
  };

  const fieldsAreDisabled = () => {
    return submitPending || submitSuccess;
  };

  const submitIsDisabled = () => {
    return rating === 0 || fieldsAreDisabled() || !validateForm();
  };

  const validateForm = () => {
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setSubmitPending(true);

      const url = new URL(window.location);

      if (!url.searchParams.get('case_id')) {
        url.searchParams.set('case_id', case_id);
      }
      const data = {
        type: 'Post Case Submission',
        feedback_category: null,
        comment: comment,
        rating: rating,
        url: url.toString(),
        case_id: case_id,
      };
      Axios.post(`/apiv3/feedback/${doctorId}`, data)
        .then(() => {
          setSubmitSuccess(true);
          sendFeedbackRatingEmail(doctorId);
        })
        .catch((error) => {
          setSubmitError(error);
        });
    }
  };

  React.useEffect(() => {
    if (!doctorId) {
      if (getDoctorsIdFromRoute()) {
        setDoctorId(getDoctorsIdFromRoute());
      } else {
        Axios.get(`/apiV2/account_light`).then(function (res) {
          if (res.data && res.data.role !== 'Business') {
            setDoctorId(res.data.account_id);
          }
        });
      }
    }
  }, [doctorId]);

  return (
    <div className="rating-form feedback-rating-post-case-submission">
      {submitSuccess ? (
        <>
          <h3 className="rating-form__title rating-form__title--center">Feedback Submitted</h3>
          <p className="rating-form__subtitle rating-form__subtitle--center">Your feedback has been successfully submitted.</p>
        </>
      ) : (
        <>
          <h3 className="rating-form__title">Rate Your Experience</h3>
          <ToothRating
            label={'Are you satisfied with your case submission experience?'}
            rating={rating}
            onClick={(i) => setRating(i)}
            disabled={fieldsAreDisabled()}
          />
          <div className="rating-form__comment">
            <h4 className="comment__label">Tell us what can be improved?</h4>
            <textarea
              className={`form-control item-reason-comment text-input ${!!errors.comment ? 'has-error' : ''}`}
              placeholder={'Tell us what can be improved?'}
              value={comment}
              onChange={handleCommentChange}
              disabled={fieldsAreDisabled()}
            />
            <div className={`text-area__count text-area__count--bottom ${!!errors.comment ? 'text-input__count--error' : ''}`}>
              <span>
                {comment.length}/{commentMaxLength}
              </span>
            </div>
          </div>

          <div className="rating-form__button">
            {submitSuccess ? null : (
              <Button onClick={handleSubmit} disabled={submitIsDisabled()}>
                Submit
              </Button>
            )}
          </div>

          <div className="rating-form__alert-container">
            {submitError ? <AlertMessage theme={'error'} className="rating-form__alert" message={'An error has ocurred please try again'} dismiss /> : null}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorInfo: getDoctorInfo(state),
  };
};

export default connect(mapStateToProps, null)(RatingForm);
