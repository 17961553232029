import React, { Component } from 'react';

import { getRootPath } from '../../common/functions';
import { withRouter } from 'react-router-dom';

import Badge from '../components/badge';
import FilterContainer from '../components/container/filter_container';
import FilterItem from '../components/filter_item';
import { getStatusFilterValueFromRoute } from '../../common/route';
/**
 * This component is used to show the user the IR list filter element on the IR list
 * @component
 * @alias DoctorIrFilter
 * @category IPP
 */
class IrFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
    };
  }
  componentDidMount() {
    this.updateStatusByURL();
  }
  componentDidUpdate() {
    const status = getStatusFilterValueFromRoute();

    if(this.state.status !== status){
      this.updateStatusByURL();
    }
  }
  updateStatusByURL = () => {
    const status = getStatusFilterValueFromRoute();
    this.setState({ status });
  };
  /**
   * Update browser route to the correct the url
   *
   * @function
   * @param {String} name - the name of the filter value
   */
  appendStatusToRoute = (name) => {
    let rootpath = getRootPath(this.props.history.location.pathname);
    const ir_route = `?filter=item_request`;
    this.props.history.replace(`${rootpath}${ir_route}` + (name !== '' ? `&status_filter=${name}` : ''));
  };
  /**
   * Update to the status in route and state
   *
   * @function
   * @param {Object} event - The event on the change of the status
   */
  onStatusClick = (event) => {
    if (event && event.currentTarget && event.currentTarget.dataset && (event.currentTarget.dataset.status || event.currentTarget.dataset.status === '')) {
      this.appendStatusToRoute(event.currentTarget.dataset.status);
      this.setState({
        status: event.currentTarget.dataset.status,
      });
    }
  };

  render() {
    return (
      <div className="statuses">
        {this.props.search ? this.props.search : null}
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === ''} data-status="" onClick={this.onStatusClick}>
            My Item Request
            <Badge>{this.props.statuses.all}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem type="subfilter" active={this.state.status === 'action'} data-status="action" onClick={this.onStatusClick}>
            Action Required
            <Badge theme="primary">{this.props.statuses.action}</Badge>
          </FilterItem>
          <FilterItem active={this.state.status === 'hold'} data-status="hold" onClick={this.onStatusClick}>
            On Hold
            <Badge theme="secondary">{this.props.statuses.hold}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'active'} data-status="active" onClick={this.onStatusClick}>
            Active
            <Badge>{this.props.statuses.active}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'ship'} data-status="ship" onClick={this.onStatusClick}>
            Shipped
            <Badge>{this.props.statuses.ship}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'cancel'} data-status="cancel" onClick={this.onStatusClick}>
            Cancelled
            <Badge>{this.props.statuses.cancel}</Badge>
          </FilterItem>
        </FilterContainer>
      </div>
    );
  }
}

export default withRouter(IrFilter);
