import React from 'react';
import { computeDaysPast, convertDate, removeCaseIdInitialNumber } from '../../common/functions';
import { translateStatus } from '../../common/helpers';
import { new_branding_words } from '../../common/nomenclature';

function caseIdCell(cases) {
  return (
    <>
      {removeCaseIdInitialNumber(cases.case_id)}
      {cases.late_type && (
        <i
          className="fa fa-flag target-date-flag"
          aria-hidden="true"
          data-toggle="tooltip"
          data-placement="right"
          title={`Missed ${cases.late_type} Date: ${convertDate(cases.late_date)}`}
        />
      )}
      {cases.ws_redo && (
        <i className="fa fa-refresh ws-redo-flag" aria-hidden="true" data-toggle="tooltip" data-placement="right" title={`Wire Selection Rework In Progress`} />
      )}
      <div className="tag-container">
        {cases.epp && <span className="case-epp">EPP</span>}
        {cases.gen_2 && <span className="case-gen2">Gen 2.0</span>}
      </div>
    </>
  );
}
function submissionDateCell(cases) {
  return convertDate(cases.submission_date);
}
function doctorCell(cases) {
  return cases.doctor_name;
}
function patientCell(cases) {
  return cases.patient_name;
}
function caseStatusCell(cases) {
  return cases.status === 'Hold' || cases.status === 'Cancelled' ? (
    <span className="hold-length-2">
      {cases.status} {'-'} {cases.status_comment}
    </span>
  ) : cases.status === 'Case Shipped' ? (
    `Case Shipped on ${convertDate(cases.ship_date)}`
  ) : (
    new_branding_words(translateStatus(cases.status))
  );
}
function assignedToCell(cases) {
  return cases.assigned_to ? cases.assigned_to : defaultCell();
}
function wsAssignedToCell(cases) {
  return cases.ws_assigned_to ? cases.ws_assigned_to : defaultCell();
}
function smileDesignDateCell(cases) {
  return cases.smile_design_target_date ? convertDate(cases.smile_design_target_date) : defaultCell();
}
function postApprovalDateCell(cases) {
  return cases.post_approval_target_date ? convertDate(cases.post_approval_target_date) : defaultCell();
}
function applianceDesignDateCell(cases) {
  return cases.appliance_design_target_date ? convertDate(cases.appliance_design_target_date) : defaultCell();
}
function targetShipDate(cases) {
  return cases.target_ship_date ? convertDate(cases.target_ship_date) : defaultCell();
}
function approvalDateCell(cases) {
  return cases.approval_date && cases.approval_date !== 'Invalid date' ? convertDate(cases.approval_date) : defaultCell();
}
function productionWOCell(cases) {
  return (
    cases.production_wo && (
      <span className="center-text">
        <i className="fa fa-check" aria-hidden="true" />
      </span>
    )
  );
}

/**
 * Renders Custom Wire WO Table cell
 * @param {Object} cases
 * @returns {JSX}
 */
function customWireWoCell(cases) {
  return (
    cases.custom_wire_wo && (
      <span className="center-text">
        <i className="fa fa-check" aria-hidden="true" />
      </span>
    )
  );
}
function doctorActionDateCell(cases) {
  return computeDaysPast(cases.doctor_action_date);
}
function doctorNewDateCell(cases) {
  return computeDaysPast(cases.doctor_new_date);
}
function doctorSetupDateCell(cases) {
  return computeDaysPast(cases.doctor_setup_date);
}
function defaultCell() {
  return '--------';
}

/**
 * Get the function for displaying the table cell
 * @function
 * @param {String} category - The table category
 * @returns {Function} The function for rendering the table cell
 */
export function getTableCellRender(category) {
  switch (category) {
    case 'case_id':
      return caseIdCell;
    case 'submission_date':
      return submissionDateCell;
    case 'doctor_name':
      return doctorCell;
    case 'patient_name':
      return patientCell;
    case 'status':
      return caseStatusCell;
    case 'assigned_to':
      return assignedToCell;
    case 'ws_assigned_to':
      return wsAssignedToCell;
    case 'smile_design_target_date':
      return smileDesignDateCell;
    case 'post_approval_target_date':
      return postApprovalDateCell;
    case 'approval_date':
      return approvalDateCell;
    case 'appliance_design_target_date':
      return applianceDesignDateCell;
    case 'target_ship_date':
      return targetShipDate;
    case 'production_wo':
      return productionWOCell;
    case 'custom_wire_wo':
      return customWireWoCell;
    case 'doctor_action_date':
      return doctorActionDateCell;
    case 'doctor_new_date':
      return doctorNewDateCell;
    case 'doctor_setup_date':
      return doctorSetupDateCell;
    default:
      return defaultCell;
  }
}
