import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { getRootPath } from '../../common/functions';
class NewCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true
    };
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      let route = getRootPath(this.props.history.location.pathname);
      route = route + '/submission/patient';
      return <Redirect to={route} />;
    }
  };
  render() {
    return <div>{this.renderRedirect()}</div>;
  }
}

export default withRouter(NewCase);
