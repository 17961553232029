import React from 'react';
import { connect } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';

import { setManualWireLocket, saveDraft } from '../../../../../../redux/actions/manual_wire/manual_wire';
import { wireLocketOptions } from '../../manual_wire_options';
import { TEETHS } from '../../manual_wire_utils';

const ManualWireTableLocketSelect = ({
  locket,
  options,
  teethLabel,
  disabled,
  setLocket,
  arch,
  disableAllFields,
  saveDraft,
}) => {
  const onChangeWireLocketValue = (event) => {
    const locketValue = event.target.value;
    saveDraft(setLocket, {locketValue, arch, teethLabel});
  };

  return (
    <Select
      className="select-manual-wire-locket"
      value={locket}
      onChange={onChangeWireLocketValue}
      MenuProps={{ classes: { paper: 'menu-paper' } }}
      disabled={disabled || disableAllFields}
    >
      {options.map((option) => (
        <MenuItem disabled={option.disabled} value={option.value} key={`option-${option.value}`}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const mapStateToProps = (state, ownProps) => {
  const teethLabel = TEETHS[ownProps.arch][ownProps.index - 1];
  const locket = state.manualWireReducer[ownProps.arch][teethLabel].locket;
  const loop = state.manualWireReducer[ownProps.arch][teethLabel].loop;
  const prevTeethLabel = (ownProps.index > 0) ? TEETHS[ownProps.arch][ownProps.index - 2] : '';
  const prevLocket = state.manualWireReducer[ownProps.arch][prevTeethLabel].locket;
  const prevLoop = state.manualWireReducer[ownProps.arch][prevTeethLabel].loop;
  const nextTeethLabel = (ownProps.index > 0) ? TEETHS[ownProps.arch][ownProps.index] : '';
  const nextLocket = state.manualWireReducer[ownProps.arch][nextTeethLabel].locket;
  const options = JSON.parse(JSON.stringify(wireLocketOptions));

  const minItem = Math.min(...ownProps.filteredIndexes);
  const maxItem = Math.max(...ownProps.filteredIndexes);
  const numberPart = parseInt(teethLabel.substring(2), 10);

  if (prevLocket === 'SHL' || prevLoop === 'W' || ownProps.index === minItem || numberPart >= 5) {
    // if previous locket is SHL, prevLoop is W or the curent loop is the first one, then disable LSH 
    // (it cannot have leg with, W Loop cannnot have Leg and it cannot start with leg)
    options[1].disabled = true; 
  };
  if (loop === 'W' || nextLocket === 'LSH' || ownProps.index === maxItem || numberPart >= 5) {
    // if current loop is W, next locket is LSH or the current loop is the last one, then disable SHL (W Loop cannnot have Leg)
    // (it cannot have leg with, W Loop cannnot have Leg and it cannot end with leg)
    options[2].disabled = true; 
  };


  const disabled = state.manualWireReducer[ownProps.arch][teethLabel].disabled;
  return {
    locket,
    teethLabel,
    disabled,
    options,
  };
};

const mapDispatchToProps = {
  setLocket: setManualWireLocket,
  saveDraft: saveDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireTableLocketSelect);
