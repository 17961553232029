/**
 * File:permission.js - Component that contains all the details of permission
 * Copyright: (c) Copyright Oct 2019 by InBrace
 * Authors: David Vu
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './permission.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// Internal Component
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import NotFound from '../../doctor/404/not_found';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { userHasPermission } from '../../common/permission';
// Context
import { UserPermissionsContext } from '../../context/user_permission';
/**
 * Allows a user to update or create permission for the portal
 * @component
 * @alias BusinessPermission
 * @category BPP
 */
class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      permission_by_role: [],
      role_selected: {},
      modal_remove_role: false,
      mode: 'view',
      edit_role_list: {},
      edit_role_name: '',
      edit_role_description: '',
    };
  }

  UNSAFE_componentWillMount() {
    let that = this;

    Axios.get('/apiV2/permission/get_all')
      .then(function (res) {
        const role_list = res.data.role_list;
        const role_selected = res.data.role_list && res.data.role_list.length > 0 ? res.data.role_list[0] : '';
        const role_selected_data = that.getRoleBySelection(role_selected.job_role_code_name, role_list);
        const all_permissions = res.data.permission_by_categories;

        that.setState({
          loading: false,
          role_list: role_list,
          role_selected: role_selected,
          permission_by_categories: all_permissions,
          permission_list: res.data.permission_list,
          edit_role_name: role_selected.job_role_code,
          edit_role_description: role_selected.job_role_description,
          edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
          edit_permission_by_categories: that.getEditRoleViewBySelection(role_selected, role_list, all_permissions),
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  getListOfRole() {
    if (this.state.role_list && this.state.role_list.length > 0) {
      return (
        <div className="item-table">
          <div className="item-content">
            {this.state.role_list.map((role, ind) => {
              return (
                <div
                  className={
                    this.state.role_selected.job_role_code_name === role.job_role_code_name
                      ? 'selected-item-row select-role-offset'
                      : 'item-row select-role-offset'
                  }
                  data-role={role.job_role_code_name}
                  onClick={this.onJobRoleSelection}
                  key={ind}
                >
                  {role.job_role_code}
                  {this.state.role_selected === role.job_role_code_name ? <i className="fa fa-caret-right align-right-role" aria-hidden="true" /> : null}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      return <div className="container_role">No roles have been create, please contact your portal admin.</div>;
    }
  }

  getEditRoleViewBySelection(role_selected, role_list, all_permission = this.state.permission_by_categories) {
    const role_code_name = role_selected.job_role_code_name;
    const role = this.getRoleBySelection(role_code_name, role_list);

    return this.setAllPermissionPropertiesToDefault(all_permission, role.permission_list);
  }

  getRoleBySelection(role_code_name, role_list = this.state.role_list) {
    let role = {};

    for (let i = 0; i < role_list.length; i++) {
      if (role_list[i].job_role_code_name === role_code_name) {
        role = role_list[i];
        break;
      }
    }

    return role;
  }

  getRoleSelectedById(role_id, role_list) {
    let role = {};

    for (let i = 0; i < role_list.length; i++) {
      if (role_list[i].job_role_id === role_id) {
        role = role_list[i];
        break;
      }
    }

    return role;
  }

  getViewPermission(role) {
    return (
      <React.Fragment>
        <div className="page-subheading-role">Permissions</div>
        <div className="permission-box">
          {role.permission_by_categories.length > 0 ? (
            role.permission_by_categories.map((permissions, ind) => {
              return (
                <div className="permission_indent" key={ind}>
                  <div className="bold-text">
                    {permissions.permission_category !== 'All' ? permissions.permission_category : permissions.permission_properties[0].permission_description}
                  </div>

                  {permissions.permission_category !== 'All'
                    ? permissions.permission_properties.map((properties, pInd) => {
                        return (
                          <div className="permission_indent-inner" key={pInd}>
                            {properties.permission_description}
                          </div>
                        );
                      })
                    : null}
                </div>
              );
            })
          ) : (
            <div>No permissions found for this role.</div>
          )}
        </div>
      </React.Fragment>
    );
  }

  getEditPermission() {
    const permission_by_categories = this.state.edit_permission_by_categories;

    return (
      <React.Fragment>
        <div className={this.state.mode === 'new' ? 'page-subheading-role page-subheading-role-2' : 'page-subheading-role'}>Permissions</div>
        <div className="permission-box">
          <div className="permission_indent">
            <div className="bold-text">
              <input
                className="checkbox-button-permission"
                type="checkbox"
                data-category={'All'}
                checked={this.hasAllPermissionSelected(permission_by_categories)}
                onChange={this.onChangeCategoryCheckBox}
              />
              <span className="checkbox-content" onClick={this.onClickPermissionCheckBox}>
                Access to all permissions
              </span>
            </div>
          </div>
          {permission_by_categories.map((permissions, ind) => {
            return (
              <div className="permission_indent" key={ind}>
                <div className="bold-text">
                  <input
                    data-id={ind}
                    className="checkbox-button-permission"
                    type="checkbox"
                    data-category={permissions.permission_category}
                    checked={permissions.permission_category !== 'All' ? permissions.is_checked : this.hasAllPermissionSelected(permission_by_categories)}
                    onChange={this.onChangeCategoryCheckBox}
                  />
                  <span className="checkbox-content" onClick={this.onClickPermissionCheckBox}>
                    {permissions.permission_category !== 'All' ? permissions.permission_category : permissions.permission_properties[0].permission_description}
                  </span>
                </div>

                {permissions.permission_category !== 'All'
                  ? permissions.permission_properties.map((properties, pInd) => {
                      return (
                        <div className="permission_indent-inner" key={pInd}>
                          <input
                            data-id={pInd}
                            className="checkbox-button-permission"
                            type="checkbox"
                            data-code={properties.permission_code}
                            checked={properties.is_checked}
                            onChange={this.onChangePermissionCheckBox}
                          />
                          <span className="checkbox-content" onClick={this.onClickPermissionCheckBox}>
                            {properties.permission_description}
                          </span>
                        </div>
                      );
                    })
                  : null}
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  /**
   * Displays the role name, its description, and content for viewing or editing
   * @function
   */
  getRolePermission() {
    const role = this.state.role_selected;

    return (
      <React.Fragment>
        <div className="page-heading-role page-heading-role-2">
          {this.state.mode === 'view' ? this.displayEditRoleBtn() : this.state.mode === 'new' ? <div className="role-title">Create New Role</div> : null}
          <div className="role-description">
            <label>Role Name:</label>
            <div>
              <input
                name="role_name"
                className="form-control role-text"
                disabled={this.state.mode === 'view'}
                value={this.state.edit_role_name}
                onChange={this.onChangeRoleName}
                maxLength={255}
              />
            </div>
          </div>
          <div className="role-description">
            <label>Role Description:</label>
            <div>
              <textarea
                name="role_name"
                className="form-control role-textarea"
                rows="3"
                disabled={this.state.mode === 'view'}
                value={this.state.edit_role_description}
                onChange={this.onChangeRoleDescription}
                maxLength={1024}
              />
            </div>
          </div>
        </div>
        {this.state.mode === 'view' ? this.getViewPermission(role) : this.getEditPermission()}
      </React.Fragment>
    );
  }

  setAllPermissionPropertiesToDefault(permissions, permission_list) {
    let default_permissions = _.cloneDeep(permissions);

    for (let i = 0; i < default_permissions.length; i++) {
      default_permissions[i].is_checked = false;
      for (let j = 0; j < default_permissions[i].permission_properties.length; j++) {
        default_permissions[i].permission_properties[j].is_checked = false;
        for (let k = 0; k < permission_list.length; k++) {
          if (permission_list[k].permission_code === default_permissions[i].permission_properties[j].permission_code) {
            default_permissions[i].permission_properties[j].is_checked = true;
            break;
          }
        }
      }

      // Check if top level is true
      default_permissions[i].is_checked = _.every(default_permissions[i].permission_properties, { is_checked: true });
    }

    return default_permissions;
  }

  hasAllPermissionSelected(permission_by_categories) {
    let hasAllPermissions = true;

    for (let i = 0; i < permission_by_categories.length; i++) {
      if (permission_by_categories[i].is_checked === false && permission_by_categories[i].permission_category !== 'All') {
        hasAllPermissions = false;
        break;
      }
    }

    return hasAllPermissions;
  }

  onCreateRoleClick = (e) => {
    if (this.state.mode !== 'new') {
      this.setState({
        mode: 'new',
        edit_role_name: '',
        edit_role_description: '',
        edit_permission_list: [],
        edit_permission_by_categories: this.setAllPermissionPropertiesToDefault(this.state.permission_by_categories, []),
      });
    }
  };
  /**
   * Checks to see if new role has a name
   * @function
   */
  hasNewRoleName = () => {
    return this.state.edit_role_name !== '' && this.state.mode === 'new';
  };
  /**
   * Checks to see if new role has a description
   * @function
   */
  hasNewRoleDescription = () => {
    return this.state.edit_role_description !== '' && this.state.mode === 'new';
  };
  /**
   * Checks to see if new role has at least 1 permission
   * @function
   */
  hasAtLeastOnePermissionSelected = () => {
    return this.state.edit_permission_list && this.state.edit_permission_list.length > 0;
  };
  onEditRoleClick = (e) => {
    const role_selected = this.state.role_selected;
    const role_selected_data = this.getRoleBySelection(role_selected.job_role_code_name, this.state.role_list);

    this.setState({
      mode: 'edit',
      edit_role_name: role_selected.job_role_code,
      edit_role_description: role_selected.job_role_description,
      edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
      edit_permission_by_categories: this.getEditRoleViewBySelection(role_selected, this.state.role_list, this.state.all_permissions),
    });
  };

  onCancelRoleClick = (e) => {
    const role_selected = this.state.role_selected;
    const role_selected_data = this.getRoleBySelection(role_selected.job_role_code_name, this.state.role_list);

    this.setState({
      mode: 'view',
      edit_role_name: role_selected.job_role_code,
      edit_role_description: role_selected.job_role_description,
      edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
      edit_permission_by_categories: this.getEditRoleViewBySelection(role_selected, this.state.role_list, this.state.all_permissions),
    });
  };

  onChangeCategoryCheckBox = (e) => {
    const orig_permission_list = this.state.permission_list;
    const edit_permission_list = this.state.edit_permission_list;
    const checked_new_value = e.target.checked;
    const category = e.target.dataset.category;

    let permission_list = [];

    if (category === 'All') {
      if (checked_new_value) {
        permission_list = _.cloneDeep(orig_permission_list);
      }
      // Empty List
    } else if (checked_new_value) {
      // remove all
      permission_list = edit_permission_list.filter(function (permission) {
        return permission.permission_category !== category;
      });

      // add back in
      orig_permission_list.map(function (permission) {
        if (permission.permission_category === category) {
          permission_list.push(permission);
        }

        return '';
      });
    } else {
      // remove all item in that category
      permission_list = edit_permission_list.filter(function (permission) {
        return permission.permission_category !== category;
      });
    }

    if (permission_list) {
      this.setState({
        edit_permission_list: permission_list,
        edit_permission_by_categories: this.setAllPermissionPropertiesToDefault(this.state.permission_by_categories, permission_list),
      });
    }

    return;
  };

  onChangePermissionCheckBox = (e) => {
    const permission_code = e.target.dataset.code;
    const orig_permission_list = this.state.permission_list;
    const edit_permission_list = this.state.edit_permission_list;
    const checked_new_value = e.target.checked;

    let permission_list = [];

    if (checked_new_value) {
      orig_permission_list.map((permission) => {
        if (permission.permission_code === permission_code) {
          edit_permission_list.push(permission);
        }

        return '';
      });

      permission_list = edit_permission_list;
    } else {
      // Remove
      edit_permission_list.map((permission) => {
        if (permission.permission_code !== permission_code) {
          permission_list.push(permission);
        }

        return '';
      });
    }

    this.setState({
      edit_permission_list: permission_list,
      edit_permission_by_categories: this.setAllPermissionPropertiesToDefault(this.state.permission_by_categories, permission_list),
    });
  };

  onClickPermissionCheckBox = (e) => {
    e.target.previousElementSibling.click();
  };
  /**
   * Update state as role name is changed and role needs to start with a char
   * @function
   */
  onChangeRoleName = (e) => {
    let value = e.target.value;
    let role_name = '';

    // Ensure the first character is a letter else
    for (let i = 0; i < value.length; i++) {
      let matches = value[i].match(/[A-Za-z]/);
      if (matches && matches.length > 0) {
        role_name = value.substring(i);
        break;
      }
    }

    this.setState({
      edit_role_name: role_name,
    });
  };

  onChangeRoleDescription = (e) => {
    this.setState({
      edit_role_description: e.target.value,
    });
  };

  /**
   * Determines if the accounts list should be reloaded based on the updated permissions
   *
   * @function
   * @param {list} permissions - List of updated permissions
   * @returns {boolean} - True or false
   */
  shouldReloadAccountsList = (permissions) => {
    const view_account_permissions = ['ACCOUNT_IPP_VIEW', 'ACCOUNT_BPP_VIEW', 'ACCOUNT_DSO_VIEW'];
    return (
      permissions.filter((permission) => {
        return view_account_permissions.includes(permission.permission_code);
      }).length > 0
    );
  };

  /**
   * Saves permissions and roles selections
   *
   * @function
   * @param {function} fetchUserPermissions - Function to refresh user permissions
   */
  onSaveRoleClick = (fetchUserPermissions) => {
    const that = this;

    const data = {
      role: this.state.role_selected,
      role_code: this.state.edit_role_name,
      role_description: this.state.edit_role_description,
      permission_list: this.state.edit_permission_list,
    };

    return Axios.post('/apiV2/permission/edit_role', data).then((res) => {
      fetchUserPermissions();
      const role_list = res.data.role_list;
      const role_selected = this.getRoleSelectedById(this.state.role_selected['job_role_id'], role_list);
      const role_selected_data = that.getRoleBySelection(role_selected.job_role_code_name, role_list);
      const all_permissions = res.data.permission_by_categories;

      if (this.shouldReloadAccountsList(res.data.permission_list)) {
        that.props.loadAccounts();
      }

      that.setState({
        mode: 'view',
        role_list: role_list,
        role_selected: role_selected,
        permission_by_categories: all_permissions,
        permission_list: res.data.permission_list,
        edit_role_name: role_selected.job_role_code,
        edit_role_description: role_selected.job_role_description,
        edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
        edit_permission_by_categories: that.getEditRoleViewBySelection(role_selected, role_list, all_permissions),
      });

      const role = document.querySelector(`[data-role="${role_selected.job_role_code}"]`);
      if (role) {
        role.click();
      }
    });
  };

  onCreateNewRoleClick = (e) => {
    const that = this;

    const data = {
      role_code: this.state.edit_role_name,
      role_description: this.state.edit_role_description,
      permission_list: this.state.edit_permission_list,
    };

    Axios.post('/apiV2/permission/add_role', data).then((res) => {
      const role_list = res.data.role_list;
      const role_selected = this.getRoleSelectedById(res.data.job_role_id, role_list);
      const role_selected_data = that.getRoleBySelection(role_selected.job_role_code_name, role_list);
      const all_permissions = res.data.permission_by_categories;

      that.setState({
        mode: 'view',
        role_list: role_list,
        role_selected: role_selected,
        permission_by_categories: all_permissions,
        permission_list: res.data.permission_list,
        edit_role_name: role_selected.job_role_code,
        edit_role_description: role_selected.job_role_description,
        edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
        edit_permission_by_categories: that.getEditRoleViewBySelection(role_selected, role_list, all_permissions),
      });
    });
  };

  onJobRoleSelection = (e) => {
    const role = e.currentTarget.dataset.role;
    const role_selected = this.getRoleBySelection(role);
    const role_selected_data = this.getRoleBySelection(role_selected.job_role_code_name, this.state.role_list);

    if (this.state.role_selected !== role_selected) {
      this.setState({
        mode: 'view',
        role_selected: role_selected,
        edit_role_name: role_selected.job_role_code,
        edit_role_description: role_selected.job_role_description,
        edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
        edit_permission_by_categories: this.getEditRoleViewBySelection(role_selected, this.state.role_list, this.state.all_permissions),
      });
    }
  };

  onRemoveRoleClick = (e) => {
    this.setState({
      modal_remove_role: true,
    });
  };

  onDeleteRoleDismiss = (e) => {
    this.setState({
      modal_remove_role: false,
    });
  };

  onDeleteRoleConfirm = (e) => {
    const that = this;

    const data = {
      role: this.state.role_selected,
    };

    // Axios call here to delete the role
    Axios.post('/apiV2/permission/delete_role', data).then((res) => {
      const role_list = res.data.role_list;
      const role_selected = res.data.role_list && res.data.role_list.length > 0 ? res.data.role_list[0] : '';
      const role_selected_data = that.getRoleBySelection(role_selected.job_role_code_name, role_list);
      const all_permissions = res.data.permission_by_categories;

      that.setState({
        mode: 'view',
        role_list: role_list,
        role_selected: role_selected,
        permission_by_categories: all_permissions,
        permission_list: res.data.permission_list,
        edit_role_name: role_selected.job_role_code,
        edit_role_description: role_selected.job_role_description,
        edit_permission_list: _.cloneDeep(role_selected_data.permission_list),
        edit_permission_by_categories: that.getEditRoleViewBySelection(role_selected, role_list, all_permissions),
        modal_remove_role: false,
      });
    });
  };

  /**
   * Display Create Role Permission when user has permission
   *
   * @function
   */
  displayCreateRoleBtn = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const has_permission = userHasPermission('ROLES_EDIT', user_roles_and_permissions.permissions);

          return has_permission ? (
            <span className="edit-position" onClick={this.onCreateRoleClick}>
              <i className="fa fa-plus-circle" aria-hidden="true" /> Create Role
            </span>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };
  /**
   * Display Edit role when user has permission
   *
   * @function
   */
  displayEditRoleBtn = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const has_permission = userHasPermission('ROLES_EDIT', user_roles_and_permissions.permissions);

          return has_permission ? (
            <div className="edit-role" onClick={this.onEditRoleClick}>
              <i className="fa fa-pencil" aria-hidden="true" />
              Edit Role
            </div>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };
  /**
   * Determines if role being edited is the admin
   *
   * @function
   * @return {Boolean} Returns true when role is string is 'Administrator'
   */
  isEditRoleAdmin = () => {
    return this.state.edit_role_name && this.state.edit_role_name === 'Administrator'
  }
  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div className="main-content">
          <Helmet>
            <title>Roles and Permissions | InBrace Business Portal</title>
          </Helmet>
          <div className="dark-options">
            <div className="page-heading page-heading-offset">Roles and Permissions</div>
            <div className="page-role-content">
              <div className="role_col">
                <div className="page-heading-role">Roles {this.displayCreateRoleBtn()}</div>
                {this.getListOfRole()}
              </div>
              <div className="permission_col">
                {this.getRolePermission()}
                <div className="button-role-container">
                  {this.state.mode === 'view' ? null : (
                    <React.Fragment>
                      {this.state.mode === 'new' ? (
                        <button
                          type="button"
                          className="btn btn-light"
                          disabled={!this.hasNewRoleName() || !this.hasNewRoleDescription() || !this.hasAtLeastOnePermissionSelected()}
                          onClick={this.onCreateNewRoleClick}
                        >
                          Create
                        </button>
                      ) : (
                        <UserPermissionsContext.Consumer>
                          {(user_roles_and_permissions) => {
                            return (
                              <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => this.onSaveRoleClick(user_roles_and_permissions.fetchUserPermissions)}
                              >
                                Save
                              </button>
                            );
                          }}
                        </UserPermissionsContext.Consumer>
                      )}

                      <button type="button" className="btn btn-light btn-closer" onClick={this.onCancelRoleClick}>
                        Cancel
                      </button>
                    </React.Fragment>
                  )}

                  {this.state.mode === 'edit' ? (
                    <button type="button" className="btn btn-light btn-closer pull-right" onClick={this.onRemoveRoleClick} disabled={this.isEditRoleAdmin()}>
                      Delete Role
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {this.state.modal_remove_role ? (
            <Modal
              preset="decision"
              header_text="Delete Role"
              message_text="Are you sure you want to delete this role?"
              confirm_btn_text="Delete"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onDeleteRoleConfirm}
              onCloseButtonClick={this.onDeleteRoleDismiss}
              theme="bpp"
            />
          ) : null}
        </div>
      );
    }
  }
}

export default Permission;
