import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Detail from './content_detail';
import { withRouter } from 'react-router-dom';
import NotFound from '../404/not_found';
import { getEditDoctorIdFromRoute, getDoctorsIdFromRoute } from '../../common/route';

// Redux
import { fetchAccountInformation, fetchDsoInformation, onMount } from '../../redux/actions/ipp/edit_account';
import { getAccount, getAccountError, get404NotFoundError } from '../../redux/reducers/ipp/edit_account';

import CardContainer from '../components/container/card_container';
import ContentHeader from '../components/content_header';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { content: null };
  }

  componentDidMount() {
    const { history, location } = this.props;
    this.props.fetchAccountInformation(getDoctorsIdFromRoute(), getEditDoctorIdFromRoute(), true);
    this.props.fetchDsoInformation(getDoctorsIdFromRoute());
    if (this.props.account) {
      this.setState({
        content: (
          <>
            <ContentHeader title={'Account Details - ' + this.props.account.first_name + ' ' + this.props.account.last_name} />
            <CardContainer className="pad-md pad-md--no-top">
              <Detail />
            </CardContainer>
          </>
        ),
      });
    }
    if (location?.state?.refreshInfo === 'true') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      history.push({
        state: {
          refreshInfo: 'false',
        },
      });
    }
  }

  componentDidUpdate() {
    const { account } = this.props;
    if (!this.state.content && account) {
      this.setState({
        content: (
          <>
            <ContentHeader title={'Account Details - ' + account.first_name + ' ' + account.last_name} />
            <CardContainer className="pad-md pad-md--no-top">
              <Detail />
            </CardContainer>
          </>
        ),
      });
    }
  }

  componentWillUnmount() {
    this.props.onMount();
  }

  render() {
    if (this.props.account_error || this.props.not_found_error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else {
      return this.state.content;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    account_error: getAccountError(state),
    not_found_error: get404NotFoundError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountInformation: fetchAccountInformation,
      fetchDsoInformation: fetchDsoInformation,
      onMount: onMount,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
