import React from 'react';

/**
 * Displays the split screen button and its interaction
 * @component
 * @alias SetupViewerSplitBtn
 */
export default function SplitBtn(props) {
  return (
    <div className="panel-menu-split">
      <div className="panel-menu-split-title">Split Screen</div>
      <label className={props.split ? 'switch switch-color-default' : 'switch'} onClick={props.toggleSplit}>
        <input type="checkbox" checked={props.split} disabled />
        <span className="slider round" />
      </label>
      <span className={props.split ? 'switch-label' : 'switch-label-right'} onClick={props.toggleSplit}>
        {props.split ? 'On' : 'Off'}
      </span>
    </div>
  );
}
