import {
  FETCH_PROFILE_PICTURE_PENDING,
  FETCH_PROFILE_PICTURE_SUCCESS,
  FETCH_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS,
  FETCH_PROFILE_PICTURE_ERROR,
  SET_PROFILE_METADATA_EDIT,
  SET_PROFILE_IMAGE,
  OPEN_UPLOAD_PICTURE_MODAL,
  CLOSE_UPLOAD_PICTURE_MODAL,
  SAVE_PROFILE_PICTURE_REPOSITION_PENDING,
  SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS,
  SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS,
  SAVE_PROFILE_PICTURE_REPOSITION_ERROR,
  OPEN_REPOSITION_PROFILE_PICTURE_MODAL,
  OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL,
  CLOSE_REPOSITION_PROFILE_PICTURE_MODAL,
  OPEN_PROFILE_PICTURE_MENU,
  CLOSE_PROFILE_PICTURE_MENU,
  OPEN_DELETE_PICTURE_MODAL,
  CLOSE_DELETE_PICTURE_MODAL,
  DELETE_PROFILE_PICTURE_PENDING,
  DELETE_PROFILE_PICTURE_SUCCESS,
  DELETE_PROFILE_PICTURE_ERROR,
  UPLOAD_PROFILE_PICTURE_PENDING,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  UPLOAD_PROFILE_PICTURE_ERROR,
  INVALID_PROFILE_PICTURE_SIZE,
  INVALID_PROFILE_PICTURE_FORMAT_ERROR,
  FETCH_ACCOUNT_SETTINGS_PENDING,
  FETCH_ACCOUNT_SETTINGS_SUCCESS,
  FETCH_ACCOUNT_SETTINGS_ERROR,
} from '../../actions/ipp/account_settings';

const initialState = {
  account: {},
  account_pending: false,
  account_error: false,
  pending: false,
  error: false,
  profile_image: '',
  profile_image_dso_doctor: '',
  profile_image_edit: '',
  profile_metadata: {},
  profile_metadata_dso_doctor: {},
  profile_metadata_edit: {},
  upload_picture_modal: false,
  save_profile_picture_pending: false,
  save_profile_picture_error: false,
  reposition_profile_picture_modal: false,
  profile_picture_menu: false,
  delete_picture_modal: false,
  delete_picture_pending: false,
  upload_picture_pending: false,
  upload_picture_error: false,
  invalid_profile_picture_format_error: false,
  invalid_profile_picture_size: false,
  s3_url_raw: '',
  s3_url_thumbnail: '',
};

export function accountSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_SETTINGS_PENDING:
      return {
        ...state,
        account_pending: true,
      };

    case FETCH_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        account_pending: false,
        account: action.account,
      };

    case FETCH_ACCOUNT_SETTINGS_ERROR:
      return {
        ...state,
        pending: false,
        account_error: true,
      };

    case FETCH_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        pending: false,
        profile_image: action.profile_image,
        profile_metadata: action.profile_metadata,
        s3_url_raw: action.s3_url_raw,
        s3_url_thumbnail: action.s3_url_thumbnail,
      };

    case FETCH_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS:
      return {
        ...state,
        pending: false,
        profile_image_dso_doctor: action.profile_image_dso_doctor,
        profile_metadata_dso_doctor: action.profile_metadata_dso_doctor,
        s3_url_raw: action.s3_url_raw,
        s3_url_thumbnail: action.s3_url_thumbnail,
      };

    case FETCH_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      };

    case OPEN_UPLOAD_PICTURE_MODAL:
      return {
        ...state,
        upload_picture_modal: true,
      };

    case CLOSE_UPLOAD_PICTURE_MODAL:
      return {
        ...state,
        upload_picture_modal: false,
        upload_picture_error: false,
        invalid_profile_picture_format_error: false,
        invalid_profile_picture_size: false,
      };

    case SAVE_PROFILE_PICTURE_REPOSITION_PENDING:
      return {
        ...state,
        save_profile_picture_pending: true,
      };

    case SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS:
      return {
        ...state,
        save_profile_picture_pending: false,
        reposition_profile_picture_modal: false,
        profile_image: state.profile_image_edit,
        profile_metadata: state.profile_metadata_edit,
      };

    case SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS:
      return {
        ...state,
        save_profile_picture_pending: false,
        reposition_profile_picture_modal: false,
        profile_image_dso_doctor: state.profile_image_edit,
        profile_metadata_dso_doctor: state.profile_metadata_edit,
      };

    case SAVE_PROFILE_PICTURE_REPOSITION_ERROR:
      return {
        ...state,
        save_profile_picture_pending: false,
        save_profile_picture_error: true,
      };

    case SET_PROFILE_METADATA_EDIT:
      return {
        ...state,
        profile_metadata_edit: action.profile_metadata_edit,
      };

    case SET_PROFILE_IMAGE:
      return {
        ...state,
        profile_image: action.profile_image,
      };

    case OPEN_REPOSITION_PROFILE_PICTURE_MODAL:
      return {
        ...state,
        reposition_profile_picture_modal: true,
        profile_picture_menu: false,
        profile_image_edit: state.profile_image,
        profile_metadata_edit: state.profile_metadata,
      };

    case OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL:
      return {
        ...state,
        reposition_profile_picture_modal: true,
        profile_picture_menu: false,
        profile_image_edit: state.profile_image_dso_doctor,
        profile_metadata_edit: state.profile_metadata_dso_doctor,
      };

    case CLOSE_REPOSITION_PROFILE_PICTURE_MODAL:
      return {
        ...state,
        reposition_profile_picture_modal: false,
      };

    case OPEN_PROFILE_PICTURE_MENU:
      return {
        ...state,
        profile_picture_menu: true,
      };

    case CLOSE_PROFILE_PICTURE_MENU:
      return {
        ...state,
        profile_picture_menu: false,
      };

    case OPEN_DELETE_PICTURE_MODAL:
      return {
        ...state,
        delete_picture_modal: true,
        profile_picture_menu: false,
      };

    case CLOSE_DELETE_PICTURE_MODAL:
      return {
        ...state,
        delete_picture_modal: false,
      };

    case DELETE_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        delete_picture_pending: true,
      };

    case DELETE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        delete_picture_pending: false,
        delete_picture_modal: false,
      };

    case DELETE_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        delete_picture_pending: false,
      };

    case UPLOAD_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        upload_picture_pending: true,
        upload_picture_modal: true,
      };

    case UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        upload_picture_modal: false,
        upload_picture_pending: false,
        profile_image_edit: action.profile_image_edit,
        profile_metadata_edit: {
          x: '',
          y: '',
          width: '',
          height: '',
        },
        reposition_profile_picture_modal: true,
        s3_url_raw: action.s3_url_raw,
        s3_url_thumbnail: action.s3_url_thumbnail,
      };

    case UPLOAD_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        upload_picture_error: true,
        upload_picture_pending: false,
      };

    case INVALID_PROFILE_PICTURE_FORMAT_ERROR:
      return {
        ...state,
        invalid_profile_picture_format_error: true,
        upload_picture_pending: false,
      };

    case INVALID_PROFILE_PICTURE_SIZE:
      return {
        ...state,
        invalid_profile_picture_size: true,
        upload_picture_pending: false,
      };

    default:
      return state;
  }
}

export const getProfileImage = (state) => state.accountSettingsReducer.profile_image;
export const getProfileImageDsoDoctor = (state) => state.accountSettingsReducer.profile_image_dso_doctor;
export const getProfileMetadata = (state) => state.accountSettingsReducer.profile_metadata;
export const getProfileMetadataDsoDoctor = (state) => state.accountSettingsReducer.profile_metadata_dso_doctor;
export const getUploadPictureModal = (state) => state.accountSettingsReducer.upload_picture_modal;
export const getUploadPicturePending = (state) => state.accountSettingsReducer.upload_picture_pending;
export const getUploadPictureError = (state) => state.accountSettingsReducer.upload_picture_error;
export const getInvalidProfilePictureFormatError = (state) => state.accountSettingsReducer.invalid_profile_picture_format_error;
export const getInvalidProfilePictureSize = (state) => state.accountSettingsReducer.invalid_profile_picture_size;
export const getRepositionProfilePictureModal = (state) => state.accountSettingsReducer.reposition_profile_picture_modal;
export const getProfilePictureMenu = (state) => state.accountSettingsReducer.profile_picture_menu;
export const getDeletePictureModal = (state) => state.accountSettingsReducer.delete_picture_modal;
export const getProfileImageEdit = (state) => state.accountSettingsReducer.profile_image_edit;
export const getProfileMetadataEdit = (state) => state.accountSettingsReducer.profile_metadata_edit;
export const getAccountSetting = (state) => state.accountSettingsReducer.account;
