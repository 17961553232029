// External Libs
import React, { Component } from 'react';

/**
 * TextInput provides a text input for field
 */
class TextInput extends Component {
  render = () => {
    return (
      <div className={this.props.divClass ? this.props.divClass : ''}>
        <input
          type="text"
          name={this.props.name || ''}
          className={this.props.className || ''}
          data-item={this.props.item || ''}
          value={this.props.value || ''}
          onChange={this.props.change || null}
          onKeyDown={this.props.keyDown || null}
          autoFocus={this.props.autoFocus || false}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength || ''}
          readOnly={this.props.readOnly || false}
        />
      </div>
    );
  };
}

export default TextInput;
