import DOMPurify from 'dompurify';
import React from 'react';
import { isHTML, convertDate } from '../../common/functions';

function displayComment(item) {
  return isHTML(item.smile_comment) ? (
    <div
      className="ql-editor"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(item.smile_comment, {
          ALLOWED_ATTR: ['target', 'href', 'rel', 'class'],
        }),
      }}
    />
  ) : (
    <div className="ql-editor">
      <p>{item.smile_comment}</p>
    </div>
  );
}

/**
 * Displays Status author name and prefixes
 * @function
 * @param {Object} log - Smile simulation status log
 * @return {String} Author name
 */
function displayCommentTitle(log) {
  const doctor_display = `Dr. ${log.created_by__last_name}`;
  const dso_display = `${log.created_by__first_name} ${log.created_by__last_name}`;
  const bpp_display = 'InBrace';

  return log.doctor_role === 'Business' ? bpp_display : log.doctor_role === 'DSO' ? dso_display : doctor_display;
}

function CaseHistory(props) {
  const { items } = props;
  return (
    <div className="case-history">
      {items.map((item, index) => (
        <div key={index} className="case-history__item">
          <span className="emphasis">{item.created_date !== '' ? convertDate(item.created_date) : ''}</span>
          <span>{item.smile_status}</span>
          {item.smile_comment && item.smile_comment !== 'N/A' && (
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">{displayCommentTitle(item)}</div>
                {displayComment(item)}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default CaseHistory;
