import Axios from 'axios';
import { getRootPath } from './functions';

const getCaseIdFromSubmissionRoute = () => {
  const pathname = window.location.search;
  const match = pathname.match(/case_id=(\d+)|caseid=([A-Z\-0-9]+)/);

  let value = '';

  // match found and has id
  if (match && match[1]) {
    value = match[1];
  } else if (match && match[2]) {
    value = match[2];
  }

  return value;
};

const getDoctorsIdFromRoute = () => {
  const pathname = window.location.pathname;
  const match = pathname.match(/\/portal\/(\d+)/);

  // match found and has id
  if (match && match[1]) {
    return match[1];
  }
};

const getEditDoctorIdFromRoute = () => {
  const pathname = window.location.pathname;
  return pathname.slice(pathname.lastIndexOf('/') + 1);
};

const getValueFromRoute = (key) => {
  const search = window.location.search;
  let value = '';

  if (search && search.indexOf(`?${key}=`) >= 0) {
    value = decodeURI(search.replace(`?${key}=`, '').replaceAll('+', ' '));
    //Additional Parameters
    if (value.indexOf(`&`) >= 0) {
      value = value.substring(0, value.indexOf(`&`));
    }
  } else if (search && search.indexOf(`&${key}=`) >= 0) {
    value = search.substring(search.indexOf(`&${key}=`), search.length);

    value = value.replace(`&${key}=`, '');

    if (value.indexOf(`&`) >= 0) {
      value = decodeURI(value.substring(0, value.indexOf(`&`)));
    }
    value = decodeURI(value);
  }

  return value;
};

const getBusinessValueFromRoute = (key) => {
  const search = window.location.search;
  let value = '';
  let reg;
  let account = key.indexOf('account') >= 0;

  if ((search && search.indexOf(`${key}=`) >= 0) || account) {
    switch (key) {
      case 'search':
        // eslint-disable-next-line
        reg = new RegExp(/search=([A-Za-z0-9%\-]+)/, 'g');
        break;

      case 'search_account':
        // eslint-disable-next-line
        reg = new RegExp(/search=([A-Za-z0-9%_\.@\-]+)/, 'g');
        break;

      case 'filter':
        reg = new RegExp(/filter=([A-Za-z0-9_]+)/, 'g');
        break;

      case 'col':
        reg = new RegExp(/col=([A-Za-z0-9%]+)/, 'g');
        break;

      case 'status':
        reg = new RegExp(/status=([A-Za-z0-9%_]+)/, 'g');
        break;

      case 'start':
        // eslint-disable-next-line
        reg = new RegExp(/start=([A-Za-z0-9%\-]+)/, 'g');
        break;

      case 'end':
        // eslint-disable-next-line
        reg = new RegExp(/end=([A-Za-z0-9%\-]+)/, 'g');
        break;

      default:
    }

    let search_match = reg.exec(search);

    if (search_match) {
      value = decodeURI(search_match[1]);
    }
  }
  return value;
};

const getSearchValueFromRoute = () => {
  return getValueFromRoute('search');
};

const getSearchFilterValueFromRoute = () => {
  return getValueFromRoute('search_filter');
};

const getFilterValueFromRoute = () => {
  return getValueFromRoute('filter');
};

const getStatusFilterValueFromRoute = () => {
  return getValueFromRoute('status_filter');
};

const getSelectionValueFromRoute = () => {
  return getValueFromRoute('selection');
};

const getBusinessSearchValueFromRoute = () => {
  return getBusinessValueFromRoute('search');
};

const getBusinessAccountSearchValueFromRoute = () => {
  return getBusinessValueFromRoute('search_account');
};

const getBusinessFilterValueFromRoute = () => {
  return getBusinessValueFromRoute('filter');
};

const getBusinessColumnValueFromRoute = () => {
  return getBusinessValueFromRoute('col');
};

const getBusinessStatusValueFromRoute = () => {
  return getBusinessValueFromRoute('status');
};

const getBusinessStartDateValueFromRoute = () => {
  return getBusinessValueFromRoute('start');
};

const getBusinessEndDateValueFromRoute = () => {
  return getBusinessValueFromRoute('end');
};

/**
 * Redirects page to item request submission
 * @function
 * @param {string} case_id - Case id
 * @param {object} props - Props object
 */
const submitIreqRedirect = (case_id, props) => {
  let path = props.history.location.pathname;
  let redirectPath = getRootPath(path);

  if (redirectPath) {
    props.history.push(`${redirectPath}/itemrequest?caseid=${case_id}`);
  } else {
    Axios.get('/apiv3/doctor').then(function (result) {
      if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
        props.history.push(`/portal/${result.data.doctor[0].id}/itemrequest?caseid=${case_id}`);
      }
    });
  }
};

/**
 * Redirects page to patient notes on IPP
 * @function
 * @param {string} case_id - Case id
 * @param {string} doctor_id - Doctor id
 * @param {object} props - Props object
 */
const viewAllNotesRedirect = (case_id, doctor_id, props) => {
  props.history.push(`/portal/${doctor_id}/note/${case_id}`);
};

export {
  getCaseIdFromSubmissionRoute,
  getDoctorsIdFromRoute,
  getEditDoctorIdFromRoute,
  getSearchValueFromRoute,
  getSearchFilterValueFromRoute,
  getFilterValueFromRoute,
  getSelectionValueFromRoute,
  getStatusFilterValueFromRoute,
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
  getBusinessAccountSearchValueFromRoute,
  submitIreqRedirect,
  viewAllNotesRedirect,
};
