import './ip_final.scss';
// External Libs
import React, { Component } from 'react';

import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';
import { onDownload } from '../../../../common/functions';

/**
 * Displays Automated wirebending final step
 * @component
 * @alias IpFinal
 * @category BPP
 */
class IpFinal extends Component {
  render() {
    const ip_loop_file = this.props.ip_loop_file;
    const recipe_file = this.props.recipe_file;
    const ip_loop_measurement_file = this.props.ip_loop_measurement_file;
    return (
      <div className="">
        <div className="">
          <div className="cad-window">
            <div className="paragraph">
              <div>
                <b>AWB</b>
              </div>
              Files successfully approved and sent to wirebending directory.
            </div>
            <div>
              {ip_loop_file && ip_loop_file.upload_data ? (
                <a className="underline-text viewable-text view-upload" href={`/${ip_loop_file.upload_data}`} download onClick={onDownload}>
                  Input File <i className="fa fa-file-excel-o" aria-hidden="true" />
                </a>
              ) : null}
            </div>
            <div>
              {recipe_file && recipe_file.upload_data ? (
                <a className="underline-text viewable-text view-upload" href={`/${recipe_file.upload_data}`} download onClick={onDownload}>
                  Recipe File <i className="fa fa-file-excel-o" aria-hidden="true" />
                </a>
              ) : null}
            </div>
            <div>
              {ip_loop_measurement_file && ip_loop_measurement_file.upload_data ? (
                <div className="underline-text viewable-text view-upload" onClick={this.props.onIPLoopMeasurementModalClick}>
                  IP Loop Measurements <i className="fa fa-file-pdf-o" aria-hidden="true" />
                </div>
              ) : null}
            </div>
            <div className={'margin-top-20'}>
              <UserPermissionsContext.Consumer>
                {(user_roles_and_permissions) => {
                  return userHasPermission('CASE_AWB_REWORK', user_roles_and_permissions.permissions) &&
                    this.props.selections &&
                    !this.props.has_status &&
                    this.props.inprocess_ipstatus &&
                    this.props.ref_no &&
                    this.props.get_case_status !== 'STATUS_SHIP' ? (
                    <button className="link-button" onClick={this.props.onRewokModalClick}>
                      <i className="fa fa-repeat" aria-hidden="true" /> Rework
                    </button>
                  ) : null;
                }}
              </UserPermissionsContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IpFinal;
