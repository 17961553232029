import React, { Component } from 'react';
import Axios from 'axios';
import CircleLoader from '../../components/loader/circle_loader';
import { getAbbreviation, getPDFNameTemplate } from '../../common/helpers';
import PDFViewerInterface from '../../components/pdf-viewer/pdf_viewer';
import { PdfModalDialog } from '../components/modals/pdf-modal';

class VBPCViewerModal extends Component {
  state = {
    loading: true,
    pdf_comp: null,
  };

  componentDidMount() {
    let that = this;

    setTimeout(function () {
      this.$('#generalModal').modal('show');

      if (that.props.photoUpload && that.props.photoUpload.length > 0) {
        //Load pdf
        Axios({
          url: '/api/download/?file=/' + that.props.photoUpload[0].upload_data,
          method: 'GET',
          responseType: 'arraybuffer',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          that.setState({
            loading: false,
            pdf_comp: (
              <PDFViewerInterface
                fileUrl={url}
                filename={getPDFNameTemplate(that.props.case_id, that.props.patient_info, `${getAbbreviation(that.getTitle(), ' ')}.pdf`)}
              />
            ),
          });
        });
      }
    }, 0);
  }

  getTitle() {
    let title = '';
    if (this.props.photoUpload && this.props.photoUpload.length > 0) {
      const file_type = this.props.photoUpload[0].file_type;
      const gen_2 = this.props.photoUpload[0].gen_2;
      const post_afg = this.props.photoUpload[0].post_afg;

      title = (file_type === 'afg' || gen_2 === 'true' || gen_2 === true) ? 'Adhesive Fill Guide' : post_afg && post_afg !== 'false' ? 'Bracket Positioning Guide & Adhesive Fill Guide' : 'Bracket Positioning Guide';
    }
    return title;
  }

  render() {
    return (
      <PdfModalDialog
        show={true}
        title={`${this.getTitle()} - ${this.props.patientName}`}
        onClose={this.props.onButtonClick}
      >
        {this.state.loading && <CircleLoader />}
        {this.state.pdf_comp ?? null}
      </PdfModalDialog>
    );
  }
}

export default VBPCViewerModal;
