import Axios from 'axios';
import React, { Component } from 'react';

//Internal Functions
import { PdfModalDialog } from '../components/modals/pdf-modal';
import CircleLoader from '../../components/loader/circle_loader';
import PDFViewerInterface from '../../components/pdf-viewer/pdf_viewer';
import { getPDFNameTemplate } from '../../common/helpers';

class TxInstructionViewerModal extends Component {
  state = {
    loading: true,
    pdf_comp: null,
  };

  isDE = () =>
    this.props.photoUpload &&
    this.props.photoUpload.length > 0 &&
    this.props.photoUpload[0] &&
    this.props.photoUpload[0].upload_data &&
    (this.props.photoUpload[0].upload_data.indexOf('-DE') >= 0 || this.props.photoUpload[0].upload_data.indexOf('-R') >= 0)
      ? true
      : false;

  componentDidMount() {
    let that = this;

    setTimeout(function () {
      this.$('#generalModal').modal('show');

      if (that.props.photoUpload && that.props.photoUpload.length > 0) {
        //Load pdf
        Axios({
          url: '/api/download/?file=/' + that.props.photoUpload[0].upload_data,
          method: 'GET',
          responseType: 'arraybuffer',
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          that.setState({
            loading: false,
            pdf_comp: (
              <PDFViewerInterface
                fileUrl={url}
                filename={getPDFNameTemplate(
                  that.props.case_id,
                  that.props.patient_info,
                  that.isDE() ? 'Digital_Enhancement_Instructions.pdf' : 'Treatment_Instructions.pdf'
                )}
              />
            ),
          });
        });
      }
    }, 0);
  }

  render() {
    return (
      <PdfModalDialog
        show={true}
        title={`${this.isDE() ? 'DE Instructions' : 'Tx Instructions'} - ${this.props.patientName}`}
        onClose={this.props.onButtonClick}
      >
        {this.state.loading && <CircleLoader />}
        {this.state.pdf_comp ?? null}
      </PdfModalDialog>
    );
  }
}

export default TxInstructionViewerModal;
