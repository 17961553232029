// External Libs
import React, { Component } from 'react';
class Override extends Component {
  render() {
    return (
      <React.Fragment>
        <div>Automated wire bending process has been overridden...</div>
      </React.Fragment>
    );
  }
}

export default Override;
