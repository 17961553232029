import './resizer.scss';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import rafSchedule from 'raf-schd';

import { clamp } from '../../setup_viewer/components/common/functions';
import MenuAccordianBtn from '../../setup_viewer/components/panels/menu/menu_accordian_btn';
import { setSidebarCollapse, setSidebarWidth, expandSidebar, collapseSidebar } from '../../../redux/actions/wasm_viewer';
import { getWasmSidebarState } from '../../../redux/selectors/wasm_viewer';
import WasmSidebar from './wasm_sidebar';
import { sidebarMaxWidth, sidebarMinWidth } from './sidebar_constants';

class Resizer extends Component {
  static defaultProps = {
    onResizeStart: () => {},
    onResize: () => {},
    onResizeEnd: () => {},
  };

  static resizerClickableWidth = 20;

  state = {
    startScreenX: 0,
    isResizing: false,
  };

  _scheduleResize = rafSchedule((delta) => {
    if (this.state.isResizing && delta) {
      this.props.onResize(delta);
    }
  });

  _mouseUpHandler = (e, outOfBounds = false) => {
    window.removeEventListener('mousemove', this._mouseMoveHandler);
    window.removeEventListener('mouseup', this._mouseUpHandler);
    window.removeEventListener('mouseout', this.handleOutofBounds);
    this.setState({
      isResizing: false,
    });

    const screenX = outOfBounds
      ? // If we have gone out of bounds, reduce the nav width so the resizer is still visible
        e.screenX - 2 * Resizer.resizerClickableWidth
      : e.screenX;

    const delta = screenX - this.state.startScreenX;

    if (delta === 0) {
      return;
    }

    // Perform one final resize before ending
    this.props.onResize(delta);

    this.props.onResizeEnd(delta);
  };

  _mouseMoveHandler = (e) => {
    this._scheduleResize(e.screenX - this.state.startScreenX);
  };

  _mouseDownHandler = (e) => {
    e.preventDefault();

    if (this.state.isResizing) {
      return;
    }

    this.setState({
      isResizing: true,
      startScreenX: e.screenX,
    });
    this.props.onResizeStart();
    window.addEventListener('mousemove', this._mouseMoveHandler);
    window.addEventListener('mouseup', this._mouseUpHandler);
    window.addEventListener('mouseout', this.handleOutofBounds);
  };

  render() {
    return <div className="resizer" onMouseDown={this._mouseDownHandler} />;
  }
}

class ResizableSidebar extends Component {
  static defaultProps = {
    sizes: [
      {
        width: 80,
        shouldSnap: true,
      },
      {
        width: 150,
        shouldSnap: false,
      },
      {
        width: 400,
        shouldSnap: false,
      },
      {
        width: 500,
        shouldSnap: true,
      },
    ],
  };

  state = {
    isResizing: false,
    resizeDelta: 0,
  };

  _onResize = (resizeDelta) => {
    this.setState({
      isResizing: true,
      resizeDelta,
    });
    const progressWidth = this._getProgressWidth(resizeDelta);
    const collapse = progressWidth < this.props.collapseWidth;
    if (this.props.collapse !== collapse) {
      this.props.setSidebarCollapse(collapse);
    }
  };

  _onResizeEnd = (resizeDelta) => {
    const width = this._getFinalWidth(resizeDelta);
    this.setState({
      isResizing: false,
      resizeDelta: 0,
    });
    this.props.setSidebarWidth(width);
  };

  _getFinalWidth = (resizeDelta) => {
    const width = clamp(this.props.width + resizeDelta, sidebarMinWidth, sidebarMaxWidth);
    const isShrinking = resizeDelta < 0;
    const sizes = isShrinking ? Object.values(this.props.sizes).reverse() : Object.values(this.props.sizes);

    const sizeMatch = sizes.find((size) => (isShrinking ? size.width <= width : size.width >= width));

    if (sizeMatch && sizeMatch.shouldSnap) {
      return sizeMatch.width;
    } else {
      return width;
    }
  };

  _getProgressWidth = (resizeDelta) => {
    const progressWidth = this.props.width + resizeDelta;
    return clamp(progressWidth, sidebarMinWidth, sidebarMaxWidth);
  };

  toggleCollapse = () => {
    const { collapse, onToggleCollapse } = this.props;
    if (collapse) {
      this.props.expandSidebar();
    } else {
      this.props.collapseSidebar();
    }
    if (typeof onToggleCollapse === 'function') onToggleCollapse(collapse);
  };

  render() {
    const width = `${this._getProgressWidth(this.state.resizeDelta)}px`;

    const styles = {
      width,
      transition: !this.state.isResizing ? '0.3s all' : '',
    };

    return (
      <div className="wasm-sidebar-container" style={styles}>
        <WasmSidebar
          history_list={this.props.history_list}
          status={this.props.status}
          onOpenModal={this.props.onOpenModal}
          onClickImageButton={this.props.onClickImageButton}
          onClickHistoryButton={this.props.onClickHistoryButton}
          onClickBoltonButton={this.props.onClickBoltonButton}
        />
        <Resizer onResize={this._onResize} onResizeEnd={this._onResizeEnd} />
        <MenuAccordianBtn className={'wasm'} collapse={this.props.collapse} toggleCollapse={this.toggleCollapse} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const wasmSidebarState = getWasmSidebarState(state);
  return {
    collapse: wasmSidebarState.collapse,
    width: wasmSidebarState.width,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSidebarCollapse,
      setSidebarWidth,
      expandSidebar,
      collapseSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResizableSidebar);
