import './navigation.scss';

import React, { Component } from 'react';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { withRouter, Link } from 'react-router-dom';
import { fakeAuth } from '../../doctor/login/login';

import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logoutCaseFilterPreferencesSuccess } from '../../redux/actions/case_filter_preferences';

import { ReactComponent as InLogo } from '../../doctor/components/in.svg';

class LeftNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showComingSoonModal: false,
      role: '',
      account_id: '',
      account_email: '',
      loading: true,
    };

    this.onClickLogout = this.onClickLogout.bind(this);
  }

  componentDidMount() {
    const that = this;
    Axios.get(`/apiV2/account`)
      .then(function (res) {
        that.setState({
          role: res.data.role,
          account_id: res.data.account_id,
          account_email: res.data.account_email,
          loading: res.data.role === 'Business' || res.data.role === 'Admin' ? false : true,
        });
      })
      .catch(function (err) {});
  }

  onClickLogout() {
    let that = this;

    Axios.post('/auth/rest-auth/logout/', {})
      .then(function (result) {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionid');

        Axios.defaults.headers.common['Authorization'] = null;
        fakeAuth.signout(() => that.props.history.push('/'));
        that.props.logoutCaseFilterPreferencesSuccess();
      })
      .catch(function (errors) {
        console.log(errors);
      });
  }

  render() {
    if (this.state.loading) {
      return <div />;
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return (
              <nav className="sidenav">
                <Link to="/business/portal">
                  <InLogo />
                </Link>

                {userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                  <Link to={{ pathname: '/business/portal/cases', state: { refresh: 'true' } }}>
                    <FontAwesomeIcon icon={['fa', 'briefcase']} />
                    <span className="sidebar-text">Cases</span>
                  </Link>
                ) : null}

                {userHasPermission('PROGRESS_VIEW', user_roles_and_permissions.permissions) ? (
                  <Link to="/business/portal/progress">
                    <FontAwesomeIcon icon={['fa', 'images']} />
                    <span className="sidebar-text">Progress Records</span>
                  </Link>
                ) : null}

                {userHasPermission('IREQ_VIEW', user_roles_and_permissions.permissions) ? (
                  <Link to="/business/portal/item">
                    <FontAwesomeIcon icon={['fas', 'box-open']} />
                    <span className="sidebar-text">Item Requests</span>
                  </Link>
                ) : null}
                {userHasPermission('SMILE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                  <Link to={{ pathname: '/business/portal/smiles', state: { refresh: 'true' } }}>
                    <FontAwesomeIcon icon={['fas', 'tooth']} />
                    <span className="sidebar-text">Smile Simulation</span>
                  </Link>
                ) : null}

                {userHasPermission('CASE_SHIPPING_APPROVAL', user_roles_and_permissions.permissions) ||
                userHasPermission('IREQ_SHIPPING_APPROVAL', user_roles_and_permissions.permissions) ||
                userHasPermission('CASE_SHIPPING_RELEASE', user_roles_and_permissions.permissions) ||
                userHasPermission('IREQ_SHIPPING_RELEASE', user_roles_and_permissions.permissions) ? (
                  <Link to={{ pathname: '/business/portal/shipping', state: { refresh: 'true' } }}>
                    <FontAwesomeIcon icon={['fas', 'truck']} />
                    <span className="sidebar-text">Shipping</span>
                  </Link>
                ) : null}
                {userHasPermission('REPORTS_VIEW', user_roles_and_permissions.permissions) ? (
                  <Link to="/business/portal/reports">
                    <FontAwesomeIcon icon={['fas', 'chart-line']} />
                    <span className="sidebar-text">Reports</span>
                  </Link>
                ) : null}
                {(userHasPermission('SETTINGS_TARGET_DATE', user_roles_and_permissions.permissions) ||
                  userHasPermission('PROGRAM_SETTINGS', user_roles_and_permissions.permissions) ||
                  userHasPermission('EXTERNAL_SETTINGS_TARGET_DATE', user_roles_and_permissions.permissions)) && (
                  <Link to="/business/portal/settings">
                    <FontAwesomeIcon icon={['fa', 'cog']} />
                    <span className="sidebar-text">Settings</span>
                  </Link>
                )}
                <Link to={{ pathname: '/business/portal/accounts/profile', state: { refresh: 'true' } }}>
                  <FontAwesomeIcon icon={['fa', 'users']} />
                  <span className="sidebar-text">Accounts</span>
                </Link>
                {userHasPermission('KNOWLEDGEBASE_VIEW', user_roles_and_permissions.permissions) ? (
                  <a href="/portal/knowledgebase" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fa', 'question-circle']} />
                    <span className="sidebar-text">Knowledge Base</span>
                  </a>
                ) : null}

                {userHasPermission('SALESFORCE_VIEW', user_roles_and_permissions.permissions) ? (
                  <a href="https://inbrace.my.salesforce.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={['fab', 'salesforce']} />
                    <span className="sidebar-text">Salesforce</span>
                  </a>
                ) : null}

                {userHasPermission('CASE_REVERSAL', user_roles_and_permissions.permissions) ? (
                  <a href="/business/portal/revert-case">
                    <FontAwesomeIcon icon={['fas', 'backward']} />
                    <span className="sidebar-text">Revert Case</span>
                  </a>
                ) : null}

                {/* eslint-disable-next-line */}
                <a className="sidebar-bottom" href="#" onClick={this.onClickLogout}>
                  <span className="icon-s">
                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                  </span>
                  <span className="sidebar-text">Logout</span>
                </a>
              </nav>
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutCaseFilterPreferencesSuccess: logoutCaseFilterPreferencesSuccess,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(LeftNav));
