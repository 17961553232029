import React from 'react';
import PropTypes from 'prop-types';
import PaneViewerRecordThumbnail from './pane_viewer_record_thumbnail';

/**
 * Displays the thumbnails for records
 * @component
 */
function PaneViewerRecordsSlider(props) {
  return (
    <div className="records-slider-container">
      {props.records.map((record, i) => (
        <PaneViewerRecordThumbnail key={record.id} record={record} pane={props.pane} onLoad={props.onLoad} onClick={() => props.onClick(i)} />
      ))}
    </div>
  );
}

PaneViewerRecordsSlider.propTypes = {
  records: PropTypes.array.isRequired,
  pane: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
};

export default PaneViewerRecordsSlider;
