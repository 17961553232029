import Axios from 'axios';

// Action Types

export const FETCH_PROGRAMS_STARTED = 'FETCH_PROGRAMS_STARTED';
export const FETCH_PROGRAMS_WAS_SUCCESSFUL = 'FETCH_PROGRAMS_WAS_SUCCESSFUL';
export const FETCH_PROGRAMS_FAILED = 'FETCH_PROGRAMS_FAILED';

// Mappers

/**
 * Maps a program by parsing the levels property from a request program object.
 * @param {Object} responseProgram - The request program object.
 * @returns {Object} - The mapped program object with parsed levels property.
 */
export const storeProgramMapper = (responseProgram) => {
  return { ...responseProgram, levels: JSON.parse(responseProgram.levels) };
};

// Action Creators

/**
 * Fetches programs with optional parameters.
 * @param {Object} params - Optional parameters for the API request.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export function fetchPrograms({ params = {} }) {
  return (dispatch) => {
    dispatch(fetchProgramsStarted());
    Axios.get('/apiv3/programsettings', {
      params,
    })
      .then(function (res) {
        const programs = res.data.programs.map(storeProgramMapper);
        dispatch(fetchProgramsWasSuccessful(programs));

        return res;
      })
      .catch(function (err) {
        dispatch(fetchProgramsFailed(err));
      });
  };
}

/**
 * Action creator function for fetching programs pending.
 * @returns {Object} The action object with type FETCH_PROGRAMS_STARTED.
 */
export function fetchProgramsStarted() {
  return {
    type: FETCH_PROGRAMS_STARTED,
  };
}

/**
 * Creates an action object for successful fetching of programs.
 *
 * @param {Array} programs - The array of programs fetched.
 * @returns {Object} - The action object.
 */
export function fetchProgramsWasSuccessful(programs) {
  return {
    type: FETCH_PROGRAMS_WAS_SUCCESSFUL,
    programs: programs,
  };
}

/**
 * Creates an action to handle the error when fetching programs.
 *
 * @param {Error} error - The error object.
 * @returns {Object} - The action object.
 */
export function fetchProgramsFailed(error) {
  return {
    type: FETCH_PROGRAMS_FAILED,
    error: error,
  };
}
