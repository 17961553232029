/**
 * File:quantity.js - Component to input item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React from 'react';
import { withRouter } from 'react-router-dom';
import FileUpload from '../../components/file_upload/file_upload';

const PrUpload = ({
  id,
  photoUpload,
  onEmptyFileError,
  onUpload,
  onRemove,
  onIncompleteSave,
  upload_state,
  upload_content,
  show_warning,
  hide_warning,
  updateRecordState,
  // iTero integration
  withIteroSelection,
  uploadMethod,
  onUploadMethodChange,
  selectedIteroScanRecords,
  iteroSelectionProps,
  recordViewerSubtitle,
  onRemoveIteroScansClick,
  isLoadingIteroPhotos,
  isDeletingIteroPhotos,
  isBuildingIteroScansRecords,
  showRemoveIteroPhotos,
  onRemoveIteroPhotosClick,
}) => {
  // iTero integration
  const showDropzone = !withIteroSelection || uploadMethod === 'manual';
  const showIteroSelection = withIteroSelection && uploadMethod === 'itero';

  const showRemoveIteroScans = (selectedIteroScanRecords.length && !isBuildingIteroScansRecords) || photoUpload.some((file) => file.is_itero_scan);
  return (
    <div className="case-form-container">
      <h3>Upload Progress Record</h3>
      <FileUpload
        id={id}
        filesUploaded={photoUpload}
        onEmptyFileError={onEmptyFileError}
        onUpload={onUpload}
        onRemove={onRemove}
        onIncompleteSave={onIncompleteSave}
        multiple={true}
        location="progress_record"
        folder="progress_records"
        type="progress_records"
        upload_state={upload_state}
        upload_content={upload_content}
        show_warning={show_warning}
        hide_warning={hide_warning}
        removeAllowed={true}
        updateRecordState={updateRecordState}
        hide_occlusion={true}
        showDropzone={showDropzone}
        showIteroSelection={showIteroSelection}
        withIteroSelection={withIteroSelection}
        disabled={false}
        // iTero integration
        uploadMethod={uploadMethod}
        onUploadMethodChange={onUploadMethodChange}
        selectedIteroScanRecords={selectedIteroScanRecords}
        iteroSelectionProps={iteroSelectionProps}
        recordViewerSubtitle={recordViewerSubtitle}
        onRemoveIteroScansClick={onRemoveIteroScansClick}
        showRemoveIteroScans={showRemoveIteroScans}
        onRemoveIteroPhotosClick={onRemoveIteroPhotosClick}
        showRemoveIteroPhotos={showRemoveIteroPhotos}
        isLoadingIteroPhotos={isLoadingIteroPhotos}
        isDeletingIteroPhotos={isDeletingIteroPhotos}
        isBuildingIteroScansRecords={isBuildingIteroScansRecords}
      />
    </div>
  );
};

export default withRouter(PrUpload);
