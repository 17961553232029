import {
  OPEN_DDM_GENERATE_MODAL,
  CLOSE_DDM_GENERATE_MODAL,
  OPEN_DDM_MANUAL_MODAL,
  CLOSE_DDM_MANUAL_MODAL,
  OPEN_DDM_OVERRIDE_MODAL,
  CLOSE_DDM_OVERRIDE_MODAL,
  GENERATE_PENDING,
  GENERATE_SUCCESS,
  GENERATE_ERROR,
  MANUAL_PROCESS_PENDING,
  MANUAL_PROCESS_SUCCESS,
  MANUAL_PROCESS_ERROR,
  OVERRIDE_PENDING,
  OVERRIDE_SUCCESS,
  OVERRIDE_ERROR,
  CLEAR_DDM_UPLOAD_PENDING,
  UPLOAD_DDM_INPUT_FILE_PENDING,
  UPLOAD_DDM_INPUT_FILE_SUCCESS,
  UPLOAD_DDM_INPUT_FILE_ERROR,
  OPEN_REMOVE_DDM_UPLOAD_MODAL,
  CLOSE_REMOVE_DDM_UPLOAD_MODAL,
  DELETE_DDM_UPLOAD_PENDING,
  DELETE_DDM_UPLOAD_SUCCESS,
  DELETE_DDM_UPLOAD_ERROR,
  SET_DDM_INCORRECT_FILE_NAME_ERROR,
  SET_DDM_FILE_NAME,
  SET_DDM_FILE_TYPE_ERROR,
  CLEAR_DDM_FILE_TYPE_ERROR,
} from '../../actions/ddm/ddm_upload';
const initialState = {
  generate_modal: false,
  override_modal: false,
  manual_modal: false,
  pending: false,
  override_pending: false,
  manual_pending: false,
  session_error: false,
  error: null,
  remove_ddm_upload_modal: false,
  ddm_incorrect_file_name_error: {
    case_id: false,
    format: false,
  },
  file_type_error: false,
  ddm_file_name: '',
};
export function ddmUploadReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DDM_GENERATE_MODAL:
      return {
        ...state,
        generate_modal: true,
      };
    case CLOSE_DDM_GENERATE_MODAL:
      return {
        ...state,
        generate_modal: false,
      };
    case OPEN_DDM_MANUAL_MODAL:
      return {
        ...state,
        manual_modal: true,
      };
    case CLOSE_DDM_MANUAL_MODAL:
      return {
        ...state,
        manual_modal: false,
      };
    case OPEN_DDM_OVERRIDE_MODAL:
      return {
        ...state,
        override_modal: true,
      };
    case CLOSE_DDM_OVERRIDE_MODAL:
      return {
        ...state,
        override_modal: false,
      };
    case GENERATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GENERATE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case GENERATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case MANUAL_PROCESS_PENDING:
      return {
        ...state,
        manual_pending: true,
      };
    case MANUAL_PROCESS_SUCCESS:
      return {
        ...state,
        manual_pending: false,
      };
    case MANUAL_PROCESS_ERROR:
      return {
        ...state,
        manual_pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case OVERRIDE_PENDING:
      return {
        ...state,
        override_pending: true,
      };
    case OVERRIDE_SUCCESS:
      return {
        ...state,
        override_pending: false,
      };
    case OVERRIDE_ERROR:
      return {
        ...state,
        override_pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case UPLOAD_DDM_INPUT_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CLEAR_DDM_UPLOAD_PENDING:
      return {
        ...state,
        pending: false,
      };
    case UPLOAD_DDM_INPUT_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case UPLOAD_DDM_INPUT_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case OPEN_REMOVE_DDM_UPLOAD_MODAL:
      return {
        ...state,
        remove_ddm_upload_modal: true,
      };
    case CLOSE_REMOVE_DDM_UPLOAD_MODAL:
      return {
        ...state,
        remove_ddm_upload_modal: false,
      };

    case DELETE_DDM_UPLOAD_PENDING:
      return {
        ...state,
        pending: true,
      };

    case DELETE_DDM_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case DELETE_DDM_UPLOAD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };

    case SET_DDM_INCORRECT_FILE_NAME_ERROR:
      return {
        ...state,
        ddm_incorrect_file_name_error: {
          ...state.ddm_incorrect_file_name_error,
          [action.error_type]: action.error,
          [action.error_type === 'case_id' ? 'format' : 'case_id']: false,
        },
      };

    case SET_DDM_FILE_NAME:
      return {
        ...state,
        ddm_file_name: action.filename,
      };

    case SET_DDM_FILE_TYPE_ERROR:
      return {
        ...state,
        file_type_error: true,
      };

    case CLEAR_DDM_FILE_TYPE_ERROR:
      return {
        ...state,
        file_type_error: false,
      };

    default:
      return state;
  }
}
export const getGenerateModal = (state) => {
  return state.ddmUploadReducer.generate_modal;
};
export const getManualModal = (state) => {
  return state.ddmUploadReducer.manual_modal;
};
export const getOverrideModal = (state) => {
  return state.ddmUploadReducer.override_modal;
};
export const getPending = (state) => {
  return state.ddmUploadReducer.pending;
};
export const getUploadSessionError = (state) => {
  return state.ddmUploadReducer.session_error;
};
export const getRemoveDdmUploadModal = (state) => {
  return state.ddmUploadReducer.remove_ddm_upload_modal;
};
export const getDdmFileName = (state) => {
  return state.ddmUploadReducer.ddm_file_name;
};
export const getDdmIncorrectFileNameError = (state) => {
  return state.ddmUploadReducer.ddm_incorrect_file_name_error;
};
export const getDdmFileTypeError = (state) => {
  return state.ddmUploadReducer.file_type_error;
};
export const getOverridePending = (state) => {
  return state.ddmUploadReducer.override_pending;
};
export const getManualPending = (state) => {
  return state.ddmUploadReducer.manual_pending;
};
