import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.scss';
import './components/richtext/rich_text.scss';
import 'font-awesome/css/font-awesome.css';
import 'react-quill/dist/quill.snow.css';
import 'intro.js/introjs.css';
import registerServiceWorker from './registerServiceWorker';

import Axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { Provider } from 'react-redux';
import store from './redux/store';

import { getJWTToken } from './common/token';
import * as FullStory from '@fullstory/browser';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Worker } from '@react-pdf-viewer/core';

/*
Secure API with token at top level
*/
(function () {
  var token = getJWTToken();

  if (token) {
    Axios.defaults.headers.common['Authorization'] = 'Token ' + token;
  } else {
    Axios.defaults.headers.common['Authorization'] = null;
  }
})();

FullStory.init({ orgId: process.env.REACT_APP_FULL_STORY_ORG, namespace: 'FullStory' });
Axios.interceptors.request.use(
  function (config) {
    config.headers['X-FullStory-URL'] = FullStory.getCurrentSessionURL(true);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <Provider store={store}>
    <Worker workerUrl={pdfjsWorker}>
      <App />
    </Worker>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
