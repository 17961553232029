// Css
import '../settings.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';

// Internal
import { handleHttpRequestError } from '../../../common/error';
import SessionExpire from '../../../common/session_expire';
import { targetDaysNumberChar } from '../../../common/functions';
import TierList from './tier_list';
import TierDetails from './tier_details';
import { performSearch, displayNoResults } from '../search';

/**
 * Displays the settings for internal target dates
 * @alias InternalTargetDateSettings
 * @component
 * @category BPP
 * @subcategory Internal Target Date Settings
 */
class InternalTargetDateSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      provider_tiers: [],
      provider_tiers_updates: [],
      provider_tiers_view: [],
      selected_tier_id: 1,
      edit_mode: false,
      no_input_error: false,
      target_ship_days_input_error: false,
      post_approval_days_input_error: false,
      search: '',
      search_display: '',
      invalid_settings: [],
      in_progress: false,
    };
  }

  componentDidMount = () => {
    this.setState({in_progress: true})
    const that = this;
    Axios.get('/apiv3/providertier')
      .then(function (res) {
        that.setState({
          provider_tiers: res.data.provider_tiers,
          provider_tiers_updates: res.data.provider_tiers,
          provider_tiers_view: res.data.provider_tiers,
          in_progress: false,
        });
      })
      .catch(function (err) {
        this.setState({in_progress: false})
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles when user clicks on a tier
   * @function
   * @param provider_tier_id {number} - Tier id
   */
  onTierClick = (provider_tier_id) => {
    this.setState({
      selected_tier_id: provider_tier_id,
    });
  };

  /**
   * Toggles edit mode for setting
   * @function
   * @param edit_mode {boolean} - True or false
   */
  toggleEditMode = (edit_mode) => {
    this.setState({
      edit_mode: edit_mode,
    });
  };

  /**
   * Handles event when user updates business days for setting
   * @function
   * @param event {event} - Event object
   */
  onBusinessDaysChange = (event) => {
    let business_days = targetDaysNumberChar(event.target.value);
    business_days = business_days ? parseInt(business_days) : business_days;
    const target_days_type = event.currentTarget.dataset.type;
    this.updateProviderTiers(business_days, target_days_type);
  };

  /**
   * Handles even when user updates preferred shipping method for setting
   * @function
   * @param event {event} - Event object
   * @param shipping_method_type {string} - Selected shipping method type
   */
  onPreferredShippingMethodChange = (event, shipping_method_type) => {
    const method = event.target.value;
    this.updateProviderTiers(method, shipping_method_type);
  };

  /**
   * Applies updates to tiers in UI
   * @function
   * @param update {object} - Tier with updates
   * @param type {string} - Setting type
   */
  updateProviderTiers = (update, type) => {
    let provider_tiers_updates_copy = _.cloneDeep(this.state.provider_tiers_updates);

    for (const tier of provider_tiers_updates_copy) {
      if (tier.provider_tier_id === this.state.selected_tier_id) {
        tier[type] = update;
        break;
      }
    }
    this.setState({
      provider_tiers_updates: provider_tiers_updates_copy,
      no_input_error: false,
      target_ship_days_input_error: false,
      post_approval_days_input_error: false,
      invalid_settings: [],
    });
  };

  /**
   * Cancels edit mode
   * @function
   */
  onEditCancel = () => {
    this.toggleEditMode(false);
    this.setState({
      provider_tiers_updates: this.state.provider_tiers,
      no_input_error: false,
      target_ship_days_input_error: false,
      post_approval_days_input_error: false,
      invalid_settings: [],
    });
  };

  /**
   * Saves tier updates via API
   * @function
   */
  onEditSave = () => {
    if (this.determineSettingsInputError()) return;

    const selected_provider_tier = this.state.provider_tiers.filter((tier) => {
      return tier.provider_tier_id === this.state.selected_tier_id;
    });
    const selected_provider_tier_update = this.state.provider_tiers_updates.filter((tier) => {
      return tier.provider_tier_id === this.state.selected_tier_id;
    });

    const that = this;
    const data = {
      provider_tier: selected_provider_tier_update.length > 0 ? selected_provider_tier_update[0] : {},
      old_tier_settings: selected_provider_tier.length > 0 ? selected_provider_tier[0] : {},
    };

    Axios.put('/apiv3/internaltargetdays', data)
      .then(function (res) {
        that.toggleEditMode(false);

        that.setState({
          provider_tiers: that.state.provider_tiers_updates,
          no_input_error: false,
          target_ship_days_input_error: false,
          post_approval_days_input_error: false,
          invalid_settings: [],
        });

        if (res && res.data && res.data.has_changes) {
          Axios.post(
            `/api/email/?slug=target-date-settings-updated&provider_tier_id=${that.state.selected_tier_id}&method=standard&provider=${window.location.origin}`
          );
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Determines if there is an error with settings input
   * @function
   * @return {boolean} - True or false
   */
  determineSettingsInputError = () => {
    let settings_input_error = false;
    let invalid_settings = _.cloneDeep(this.state.invalid_settings);
    for (const tier of this.state.provider_tiers_updates) {
      if (tier.provider_tier_id === this.state.selected_tier_id) {
        for (const setting in tier) {
          let setting_has_error = false;
          if (!tier[setting]) {
            this.setState({
              no_input_error: true,
            });
            setting_has_error = true;
          }

          if (this.isTargetShipDaysInputInvalid(tier, setting)) {
            this.setState({
              target_ship_days_input_error: true,
            });
            const case_type = setting.split('_')[0];
            invalid_settings.push(`${case_type}_appliance_design_target_days`);
            setting_has_error = true;
          }

          if (this.isPostApprovalDaysInputValid(tier, setting)) {
            this.setState({
              post_approval_days_input_error: true,
            });
            const case_type = setting.split('_')[0];
            invalid_settings.push(`${case_type}_post_approval_target_days`);
            invalid_settings.push(`${case_type}_appliance_design_target_days`);
            setting_has_error = true;
          }

          if (setting_has_error) {
            invalid_settings.push(setting);
            this.setState({ invalid_settings: invalid_settings });
            settings_input_error = true;
          }
        }
      }
    }

    return settings_input_error;
  };

  /**
   * Determines if target ship days is greater than or equal to appliance design days
   * @function
   * @param {object} tier - Tier settings updates
   * @param {string} setting - Target ship days setting
   * @return {boolean} - True or false
   */
  isTargetShipDaysInputInvalid = (tier, setting) => {
    if (setting.includes('target_ship_days') && !setting.includes('ir_')) {
      const case_type = setting.split('_')[0];
      const appliance_design_target_days = `${case_type}_appliance_design_target_days`;
      if (tier[setting] < tier[appliance_design_target_days]) {
        return true;
      }
    }
    return false;
  };

  /**
   * Determines if post approval target days is not be greater than appliance design and target datys
   * @function
   * @param {object} tier - Tier settings updates
   * @param {string} setting - Target ship days setting
   * @return {boolean} - True or false
   */
  isPostApprovalDaysInputValid = (tier, setting) => {
    if (setting.includes('target_ship_days') && !setting.includes('ir_')) {
      const case_type = setting.split('_')[0];
      const appliance_design_target_days = `${case_type}_appliance_design_target_days`;
      const post_approval_target_days = `${case_type}_post_approval_target_days`;
      if (tier[post_approval_target_days] >= tier[appliance_design_target_days] || post_approval_target_days >= tier[setting]) {
        return true;
      }
    }
    return false;
  };

  /**
   * Displays tier search bar
   * @function
   * @return {JSX} - JSX for search bar
   */
  displaySearchBar = () => {
    return (
      <>
        <div className="dark-search">
          <input
            type="text"
            className="form-control search-bar-dark font-awesome"
            placeholder="Search"
            aria-describedby="basic-addon1"
            onChange={this.onChangeSearchTier}
            onKeyPress={this.onKeyPressSearchTier}
            value={this.state.search}
            disabled={this.state.edit_mode}
          />
        </div>
        <button className="btn btn-light-3" onClick={this.searchTier} disabled={this.state.edit_mode}>
          <i className="fa fa-search" aria-hidden="true" />
        </button>
      </>
    );
  };

  /**
   * Handles event when user presses enter in search bar
   * @function
   * @param event {object} - Event object
   */
  onKeyPressSearchTier = (event) => {
    if (event.key === 'Enter') {
      this.searchTier();
    }
  };

  /**
   * Handles event when user searches tier in search bar
   * @function
   * @param event {object} - Event object
   */
  onChangeSearchTier = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  /**
   * Searches for tier
   * @function
   */
  searchTier = () => {
    let results = performSearch(this.state.search, this.state.provider_tiers, 'tier_name');
    this.setState({
      provider_tiers_view: results,
      search_display: this.state.search,
      selected_tier_id: this.state.search ? null : this.state.selected_tier_id,
    });
  };

  render() {
    return (
      <div className="main-content main-content-solo target-date">
        <div className="dark-options">
          <div className="page-heading">Internal Target Date Settings</div>
          {this.displaySearchBar()}
        </div>
        <div className="page-tab-content">
          <div className="page-tab-content-section">
            {this.state.provider_tiers_view.length === 0 ? (
              !this.state.in_progress && displayNoResults(this.state.search)
            ) : (
              <div className="row">
                <div className="col-lg-6">
                  <TierList {...this.state} onTierClick={this.onTierClick} />
                </div>
                <div className="col-lg-6">
                  <TierDetails
                    {...this.state}
                    toggleEditMode={this.toggleEditMode}
                    onBusinessDaysChange={this.onBusinessDaysChange}
                    onPreferredShippingMethodChange={this.onPreferredShippingMethodChange}
                    onEditCancel={this.onEditCancel}
                    onEditSave={this.onEditSave}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.refresh && <SessionExpire />}
      </div>
    );
  }
}

export default InternalTargetDateSettings;
