import bronze from './icons/bronze-provider.png';
import silver from './icons/silver-provider.png';
import gold from './icons/gold-provider.png';
import platinum from './icons/platinum-provider.png';
import platinumPlus from './icons/platinum-plus-provider.png';
import moment from 'moment-timezone';

const PROVIDER_TIERS = [
  { count: 0, rank_number: 1000, name: '', logo: null, required: 0 },
  { count: 1, rank_number: 2000, name: 'Bronze', logo: bronze, required: 1, tooltip: '<b>Bronze Provider</b><br>1 Approvals Required' },
  { count: 6, rank_number: 2500, name: 'Silver', logo: silver, required: 6, tooltip: '<b>Silver Provider</b><br>6 Approvals Required' },
  { count: 12, rank_number: 3000, name: 'Gold', logo: gold, required: 12, tooltip: '<b>Gold Provider</b><br>12 Approvals Required' },
  { count: 18, rank_number: 4000, name: 'Platinum', logo: platinum, required: 18, tooltip: '<b>Platinum Provider</b><br>18 Approvals Required' },
  { count: 45, rank_number: 5000, name: 'Platinum Plus', logo: platinumPlus, required: 45, tooltip: '<b>Platinum Plus Provider</b><br>45 Approvals Required' },
];

export const PROVIDER_TIERS_DETAIL = [
  {
    logo: bronze,
    name: 'Bronze Provider',
    approvals: '1-5',
    required: 1,
    tooltip: '<b>Bronze Provider</b><br>1 Approvals Required',
    requirements: '1-5 Smile Design Approvals',
    benefits: ['Patient Referral Program', "Feature on InBrace's Find a Provider Website"],
  },
  {
    logo: silver,
    name: 'Silver Provider',
    approvals: '6-11',
    required: 6,
    tooltip: '<b>Silver Provider</b><br>6 Approvals Required',
    requirements: '6-11 Smile Design Approvals',
    benefits: ['Treatment Coordinator Study Club', "Feature on InBrace's Find a Provider Website"],
  },
  {
    logo: gold,
    name: 'Gold Provider',
    approvals: '12-17',
    required: 12,
    tooltip: '<b>Gold Provider</b><br>12 Approvals Required',
    requirements: '12-17 Smile Design Approvals',
    benefits: [
      'Special Program Eligibility',
      'Marketing Website Consultation + Free Case for Implementation',
      'Additional Employee Discount',
      'Referral Network Discount',
      'Case Expedite Ability',
      "We will highlight you as the local Gold provider on InBrace's Find a Provider website. This includes a dedicated page to you and your practice that will drive higher interest, engagement and ultimately increase new patients going to you asking for InBrace",
    ],
  },
  {
    logo: platinum,
    name: 'Platinum Provider',
    approvals: '18-44',
    required: 18,
    tooltip: '<b>Platinum Provider</b><br>18 Approvals Required',
    requirements: '18-44 Smile Design Approvals',
    benefits: [
      'Practice growth and marketing consulting specific to your geography',
      'Platinum Study Club - Clinical Led peer Review Study Group',
      'Referral Network Discount',
      'InBrace Speaking Opportunities',
      "We will highlight you as the local Platinum provider on InBrace's Find a Provider website. This includes a dedicated page to you and your practice that will drive higher interest, engagement and ultimately increase new patients going to you asking for InBrace.",
      'One month of social media management platform - to gain insight and optimize your social efforts, additional InBrace monthly discount if you choose to continue',
    ],
  },
  {
    logo: platinumPlus,
    name: 'Platinum Plus Provider',
    approvals: '45+',
    required: 45,
    tooltip: '<b>Platinum Plus Provider</b><br>45 Approvals Required',
    requirements: '45+ Smile Design Approvals',
    benefits: [
      'InBrace HQ Tour (limit 1 per year)',
      'Personalized InBrace Marketing Videos',
      'Case start for influencer and social media promotion',
      'Predictive Index assessment for key practice team members',
      "We will highlight you as the local Platinum Plus provider on InBrace's Find a Provider website. This includes a dedicated page to you and your practice that will drive higher interest, engagement and ultimately increase new patients going to you asking for InBrace",
    ],
  },
];

export function hasProviderTier(provider_tier, doctor_id) {
  return provider_tier && Object.keys(provider_tier).length > 0 && provider_tier.doctor_id === Number(doctor_id);
}

export function getProviderTierInfo(provider_tier) {
  const current = Number(provider_tier.current_quarter_rank_code);
  const cumulative = Number(provider_tier.cumulative_quarter_rank_code);
  let current_rank_index = PROVIDER_TIERS.findIndex((ele) => ele.rank_number === current);
  let cumulative_rank_index = PROVIDER_TIERS.findIndex((ele) => ele.rank_number === cumulative);
  if (current_rank_index === -1) current_rank_index = 0;
  if (cumulative_rank_index === -1) cumulative_rank_index = 0;

  return getTierInfo(current_rank_index, cumulative_rank_index);
}

function getTierInfo(current_rank, cumulative_rank) {
  const isPlatPlus = current_rank === PROVIDER_TIERS.length - 1;
  const hasNoRank = current_rank === 0 && cumulative_rank === 0;
  if (hasNoRank || current_rank >= cumulative_rank) {
    const nextRank = Math.min(current_rank + 1, PROVIDER_TIERS.length - 1);
    return {
      current: PROVIDER_TIERS[current_rank],
      next: PROVIDER_TIERS[nextRank],
      note: isPlatPlus ? '' : 'Journey to',
    };
  } else {
    return {
      current: PROVIDER_TIERS[cumulative_rank],
      next: PROVIDER_TIERS[cumulative_rank],
      note: 'Maintain',
    };
  }
}

export function getCurrentQuarterString() {
  const today = moment.tz(new Date(), 'America/Los_Angeles');
  const month = today.month();
  const year = today.year();
  if (month < 3) return `April 1, ${year} PST`;
  if (month < 6) return `July 1, ${year} PST`;
  if (month < 9) return `October 1, ${year} PST`;
  if (month < 12) return `January 1, ${year + 1} PST`;
  return '';
}

export function isSameDate(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isToday(date) {
  const today = new Date();
  return isSameDate(today, date);
}

export function isYesterday(date) {
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);
  return isSameDate(yesterday, date);
}
