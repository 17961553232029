/**
 * File: content_detail.js - Contains the content for the doctors account details
 * Copyright: (c) Copyright August 2020 by InBrace
 * Author: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
import './add_account.scss';
// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Internal Components
import CircleLoader from '../../components/loader/circle_loader';
// Internal Functions
import AddEmail from './add_email';
import AddTelephoneNumber from './add_telephone_number';
import ValidationUtils from '../../common/validation/validation-utils';
import Button from '../components/buttons/button';
import TextBox from '../components/inputs/text_box';
import LineDivision from '../components/divisions/line_division';
import AccountAddress from '../account_settings/account_address';
// Redux
import {
  getAccount,
  getAccountPending,
  getAccountCreate,
  getAccountCreateError,
  getAccountCreatePending,
  getAccountCreateStatusCode,
  getAccountCreateSuccess,
  getAccountCreateSuccessID,
  getNewMode,
  getEditMode,
  getFields,
  getWarning,
  getEditErrorMessage,
  getNewErrorMessage,
} from '../../redux/reducers/ipp/add_account';

import {
  onFieldChange,
  onFieldBlur,
  onMount,
  setAddMode,
  setEditMode,
  onEditValueChange,
  setEditValue,
  setNewValue,
  clearEditMode,
  clearNewMode,
  isEnterKeyToSaveEditChanges,
  isEnterKeyToSaveNewChanges,
  saveEditChanges,
  saveNewChanges,
  createAccount,
  deleteEntry,
} from '../../redux/actions/ipp/add_account';

class ContentDetail extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.onMount();
  }

  returnToAccountList = () => {
    let redirectPath =
      this.props && this.props.history && this.props.history.location && this.props.history.location.pathname
        ? this.props.history.location.pathname
        : document.URL;

    redirectPath = redirectPath.replace('/add', '');

    this.props.history.push(redirectPath);
  };

  redirectToCreatedAccount() {
    let redirectPath = this.props?.history?.location?.pathname || document.URL;

    if (this.props.account_create_success_id !== undefined) {
      redirectPath = redirectPath.replace('/add', `/edit/${this.props.account_create_success_id}`);
    }

    this.props.history.push({ pathname: redirectPath, state: { refreshInfo: 'true' } });
  }

  componentDidUpdate(prevProps) {
    // Check if account creation was successful, and trigger redirection
    if (this.props.account_create_success && !prevProps.account_create_success) {
      this.redirectToCreatedAccount();
    }
  }

  render() {
    const disabled =
      (this.props.new_profile && this.props.new_profile.mode !== '') || (this.props.edit_profile && this.props.edit_profile.mode !== '') ? 'true' : 'false';

    return (
      <div>
        {this.props.account_pending ? (
          <CircleLoader fullscreen={true} />
        ) : (
          <div className="dso-account-profile">
            <div className="account__section">
              <h3>Doctor Information</h3>
              <div className="edit-table">
                {this.props.fields && this.props.fields.length > 0
                  ? this.props.fields.map((field, index) => {
                      return (
                        <div key={index} className="form-group">
                          <label
                            htmlFor={field.id}
                            className={
                              (field.value === '' && this.props.warning && field.required) ||
                              (this.props.warning && field.required && field.id === 'email' && !ValidationUtils.isValidEmail(field.value))
                                ? 'col-form-label emphasis field-error'
                                : 'col-form-label emphasis'
                            }
                            id={`${field.id}_label`}
                          >
                            {field.label}
                            {field.required ? '*' : ''}
                          </label>
                          <div className="inline-input">
                            {field.type === 'text' ? (
                              <TextBox
                                id={field.id}
                                className={
                                  (field.value === '' && this.props.warning && field.required) ||
                                  (this.props.warning && field.required && field.id === 'email' && !ValidationUtils.isValidEmail(field.value))
                                    ? 'form-control patient-field field-error'
                                    : 'form-control patient-field'
                                }
                                onChange={this.props.onFieldChange}
                                placeholder={`Enter ${field.label}`}
                                value={field.value}
                                onBlur={this.props.onFieldBlur}
                                maxLength={field.max_length}
                                autoFocus={index === 0}
                              />
                            ) : field.type === 'option' ? (
                              <select id={field.id} className="form-control patient-field select-menu" value={field.value} onChange={this.props.onFieldChange}>
                                {field.option.map((option, key) => {
                                  return (
                                    <option key={key} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            <LineDivision />
            <AccountAddress account={this.props.account} />

            <LineDivision />
            <AddEmail
              account={this.props.account_create}
              new_profile={this.props.new_profile}
              edit_profile={this.props.edit_profile}
              setAddMode={this.props.setAddMode}
              fields={this.props.fields}
              setEditMode={this.props.setEditMode}
              onEditValueChange={this.props.onEditValueChange}
              setEditValue={this.props.setEditValue}
              setNewValue={this.props.setNewValue}
              clearEditMode={this.props.clearEditMode}
              clearNewMode={this.props.clearNewMode}
              isEnterKeyToSaveEditChanges={this.props.isEnterKeyToSaveEditChanges}
              isEnterKeyToSaveNewChanges={this.props.isEnterKeyToSaveNewChanges}
              saveEditChanges={this.props.saveEditChanges}
              saveNewChanges={this.props.saveNewChanges}
              deleteEntry={this.props.deleteEntry}
            />

            <LineDivision />
            <AddTelephoneNumber
              account={this.props.account_create}
              new_profile={this.props.new_profile}
              edit_profile={this.props.edit_profile}
              setAddMode={this.props.setAddMode}
              fields={this.props.fields}
              setEditMode={this.props.setEditMode}
              onEditValueChange={this.props.onEditValueChange}
              setEditValue={this.props.setEditValue}
              setNewValue={this.props.setNewValue}
              clearEditMode={this.props.clearEditMode}
              clearNewMode={this.props.clearNewMode}
              isEnterKeyToSaveEditChanges={this.props.isEnterKeyToSaveEditChanges}
              isEnterKeyToSaveNewChanges={this.props.isEnterKeyToSaveNewChanges}
              saveEditChanges={this.props.saveEditChanges}
              saveNewChanges={this.props.saveNewChanges}
              deleteEntry={this.props.deleteEntry}
              new_error_msg={this.props.new_error_msg}
              edit_error_msg={this.props.edit_error_msg}
            />

            <LineDivision />
            <div className="account__section">
              <Button disable={disabled} onClick={this.props.createAccount}>
                Create Account
              </Button>
              <Button theme="secondary" onClick={this.returnToAccountList}>
                Cancel
              </Button>

              <div className="heading-item-warning-spacing">
                {this.props.account_create_error ? (
                  <div className="heading-item-warning">
                    {parseInt(this.props.status_error) === 409
                      ? 'Sorry, but it looks like this account already exist in our system.'
                      : 'An error has occur while trying to create an account. Please contact InBrace if issue presist.'}
                  </div>
                ) : null}
                {this.props.warning ? (
                  <div className="heading-item-warning">Please fill out all required fields and ensure that account email is a valid email.</div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    account_pending: getAccountPending(state),
    account_create: getAccountCreate(state),
    account_create_error: getAccountCreateError(state),
    account_create_pending: getAccountCreatePending(state),
    new_profile: getNewMode(state),
    edit_profile: getEditMode(state),
    fields: getFields(state),
    warning: getWarning(state),
    status_error: getAccountCreateStatusCode(state),
    account_create_success: getAccountCreateSuccess(state),
    account_create_success_id: getAccountCreateSuccessID(state),
    edit_error_msg: getEditErrorMessage(state),
    new_error_msg: getNewErrorMessage(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFieldChange: onFieldChange,
      onFieldBlur: onFieldBlur,
      setEditMode: setEditMode,
      setAddMode: setAddMode,
      onEditValueChange: onEditValueChange,
      setEditValue: setEditValue,
      setNewValue: setNewValue,
      clearEditMode: clearEditMode,
      clearNewMode: clearNewMode,
      isEnterKeyToSaveEditChanges: isEnterKeyToSaveEditChanges,
      isEnterKeyToSaveNewChanges: isEnterKeyToSaveNewChanges,
      saveEditChanges: saveEditChanges,
      saveNewChanges: saveNewChanges,
      deleteEntry: deleteEntry,
      createAccount: createAccount,
      onMount: onMount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContentDetail));
