import React from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getJWTToken } from '../../common/token';
import Loader from '../loader/loader';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './pdf-viewer.scss';

const renderError = (e) => {
  
  console.log('renderError', e);

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        backgroundColor: '#323539',
      }}
    >
      <div
        style={{
          backgroundColor: '#323539',
          borderRadius: '0.5rem',
          color: '#fff',
          padding: '1rem',
        }}
      >
        <div className="dialogStyles">
          <div className="dialog-header">Error</div>
          <div className="dialog-content">Failed to load PDF document</div>
        </div>
      </div>
    </div>
  );
};
/**
 * Overlays water mark css on pdf element
 * @return {JSX} Draft
 */
const onRenderWithWaterMark = (props) => {
  return (<>
      {props.canvasLayer.children}
      <div
          style={{
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
          }}
      >
          <div
              style={{
                  color: 'rgba(0, 0, 0, 0.2)',
                  fontSize: `${6 * props.scale}rem`,
                  fontWeight: 'bold',
                  transform: 'rotate(-45deg)',
                  userSelect: 'none',
              }}
          >
              Not for Production
          </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
  </>)
}
/**
 * This is used to control what is shown or hidden on the menu bar
 * @return {Object} Contains the list of object to render
 */
const transform = (slot) => {
  const { NumberOfPages } = slot;
  return Object.assign({}, slot, {
    Open: () => <></>,
    Search: () => <></>,
    ShowSearchPopover: () => <></>,
    NumberOfPages: () => (
      <>
        of <NumberOfPages />
      </>
    ),
  });
};
/**
 * Returns the JSX of the loading element
 * @return {JSX} loading element
 */
const onLoadAnimation = () => {
  return <Loader />;
};
function PDFViewerInterface(props) {
  const renderToolbar = (Toolbar) => {
    const toolbarPluginInstance = defaultLayoutPlugin().toolbarPluginInstance;
    const { renderDefaultToolbar } = toolbarPluginInstance;
    return (
      <span className="viewer-toolbar">
        <div className="toolbar-filename fs-exclude">{props.filename}</div>
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </span>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
    renderToolbar,
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: (file) => {
          return props.filename ?? file.name.substring(file.name.lastIndexOf('/') + 1);
        },
      },
    },
  });

  const token = getJWTToken();
  const header = { Authorization: 'Token ' + token };

  return (
    <div className="viewer fs-exclude">
      <Viewer
        plugins={[defaultLayoutPluginInstance]}
        defaultScale={SpecialZoomLevel.PageWidth}
        renderError={renderError}
        renderLoader={onLoadAnimation}
        renderPage={props.watermark ? onRenderWithWaterMark: null}
        theme="dark"
        httpHeaders={header}
        withCredentials
        {...props}
      />
    </div>
  );
}

export default PDFViewerInterface;
