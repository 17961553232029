// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

class Conversion extends Component {
  render() {
    return (
      <div className="center-text">
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Wire Selection</div>
          <div className="process-box-content">The wire selection guide for the selected wires is currently being generated...</div>
          <i className="fa fa-refresh font-size-lg rotate" aria-hidden="true" />
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_WIRE_SELECTION_BYPASS', user_roles_and_permissions.permissions) ? (
                <div className="button-panel center-text">
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => {
                      this.props.setModal('convert_failed');
                    }}
                  >
                    Mark as Failed
                  </button>
                </div>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        </div>
      </div>
    );
  }
}

export default Conversion;
