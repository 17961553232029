import './smile_design_preferences.scss';

import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { LoadingIcon, CircleCheckIcon } from '../assets/icons';
import { getDoctorsIdFromRoute } from '../../common/route';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { removeEmoji } from '../../common/functions';
import Loader from '../../components/loader/loader';
import Dropdown from '../components/inputs/dropdown';
import SimpleModal from '../components/modals/simple-modal';
import NotFound from '../404/not_found';
import SessionExpiredModal from '../components/modals/session_expire';
import AlertMessage from '../components/alert_message';

const TEXT_INPUT_MAX_LENGTH = 750;

const SmileDesignPreferencesContext = React.createContext({});

/**
 * Returns the warning message for an empty question value.
 * @param {string} questionType
 * @param {string} questionValue
 * @param {object} warning
 * @returns {string|null}
 */
const getEmptyWarningMessage = (questionType, questionValue, warning) => {
  switch (questionType) {
    case 'checkbox':
      if (questionValue.length === 0) {
        return warning.data.message;
      }
      return null;
    case 'text':
    case 'dropdown':
      if (!questionValue) {
        return warning.data.message;
      }
      return null;
    default:
      return null;
  }
};

/**
 * Hook to get the warning messages for the questions.
 * @returns {object} warnings
 */
const useQuestionWarningsAlerts = () => {
  const [warnings, setWarnings] = React.useState({});
  const { values, questions } = React.useContext(SmileDesignPreferencesContext);

  React.useEffect(() => {
    const newWarnings = {};
    questions.forEach((question) => {
      if (question.warnings) {
        for (const warning of question.warnings) {
          if (warning.on === 'empty') {
            newWarnings[question.question] = getEmptyWarningMessage(question.question_type, values[question.question], warning);
          }
        }
      }
    });
    setWarnings(newWarnings);
  }, [values, questions]);

  return warnings;
};

const FieldContainer = ({ children }) => {
  return <div className="field-container">{children}</div>;
};

const FieldTitleContainer = ({ children }) => {
  return <div className="field-title-container">{children}</div>;
};

const InputWarningAlertContainer = ({ children }) => {
  return <div className="input-warning-container">{children}</div>;
};

/**
 * Component to render the input container.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.className
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const InputContainer = ({ children, className, questionData }) => {
  const warningsAlerts = useQuestionWarningsAlerts();
  return (
    <div className={`input-container ${className ? className : ''}`}>
      {children}
      {warningsAlerts[questionData.question] ? (
        <InputWarningAlertContainer>
          <AlertMessage type="warning" message={warningsAlerts[questionData.question]} withIcon={false} />
        </InputWarningAlertContainer>
      ) : null}
    </div>
  );
};

/**
 * Component to render the input label tooltip.
 * @param {object} props
 * @param {string} props.questionInfo
 * @returns
 */
const QuestionTooltip = ({ questionInfo }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          arrow
          placement="bottom"
          title={questionInfo}
          classes={{ popper: 'smile-design-preferences__popper', tooltip: 'smile-design-preferences__tooltip' }}
          onClose={handleTooltipClose}
          open={tooltipOpen}
        >
          <span className="info-icon mobile" onClick={handleTooltipOpen}>
            <i className="fa fa-info-circle" />
          </span>
        </Tooltip>
      </ClickAwayListener>
      <Tooltip
        arrow
        placement="right-start"
        title={questionInfo}
        classes={{ popper: 'smile-design-preferences__popper', tooltip: 'smile-design-preferences__tooltip' }}
      >
        <span className="info-icon desktop">
          <i className="fa fa-info-circle" />
        </span>
      </Tooltip>
    </>
  );
};

/**
 * Component to render the question wrapper.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.fieldTitleClassName
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const QuestionWrapper = ({ children, fieldTitleClassName, index, questionData }) => {
  return (
    <FieldContainer>
      <FieldTitleContainer>
        <p className={`field-title ${fieldTitleClassName ? fieldTitleClassName : ''}`}>
          <span className={'field-title__text'}>
            <span className={'field-title__index'}>{index}.</span>
            <span className={'field-title__title'}>
              {questionData.question} {questionData?.question_info ? <QuestionTooltip questionInfo={questionData.question_info} /> : null}
            </span>
          </span>
        </p>
      </FieldTitleContainer>
      {children}
    </FieldContainer>
  );
};

/**
 * Component to render the checkbox input.
 * @param {object} props
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const CheckboxSelection = ({ questionData, index }) => {
  const { values, setValues, APIUrl, saveChangesStatus, canEdit, questions, setSessionExpired, setQuestions, setAutosaveStatus } =
    React.useContext(SmileDesignPreferencesContext);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [selectedChoice, setSelectedChoice] = React.useState(null);

  const inputIsDisabled = !canEdit() || saveChangesStatus === 'loading';
  const onUncheckAnyWarning = questionData.warnings?.find((warning) => warning.on === 'uncheck_any');
  const hasOnUncheckAnyWarning = !!onUncheckAnyWarning;
  const onUncheckAnyWarningType = onUncheckAnyWarning ? onUncheckAnyWarning.type : '';
  const onUncheckAnyWarningTitle = onUncheckAnyWarning ? onUncheckAnyWarning.data.title : 'Please review the important message below';
  const onUncheckAnyWarningMessage = onUncheckAnyWarning ? onUncheckAnyWarning.data.message : '';
  const onUncheckAnyWarningConfirmText = onUncheckAnyWarning ? onUncheckAnyWarning.data.confirm_text : '';
  const onUncheckAnyWarningCancelText = onUncheckAnyWarning ? onUncheckAnyWarning.data.cancel_text : '';

  const getNewSelectedChoice = (currentSelectedChoice, choice) => {
    const choiceId = Number(choice.id);
    const newSelectedChoice = [...currentSelectedChoice];
    const choiceIndex = newSelectedChoice.indexOf(choiceId);
    if (choiceIndex === -1) {
      newSelectedChoice.push(choiceId);
    } else {
      newSelectedChoice.splice(choiceIndex, 1);
    }
    return newSelectedChoice;
  };

  const updateValue = (choice) => {
    setAutosaveStatus('loading', questionData);

    const currentValue = values[questionData.question];

    setValues((prevValues) => {
      return {
        ...prevValues,
        [questionData.question]: getNewSelectedChoice(prevValues[questionData.question], choice),
      };
    });

    const data = { changes: { ...questionData, selected_choice: getNewSelectedChoice(currentValue, choice) }, currentSessionValues: questions };
    Axios.put(APIUrl, data)
      .then(() => {
        setAutosaveStatus('success');
        Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
      })
      .catch(function (err) {
        setAutosaveStatus('error');
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
        setValues((prevValues) => ({
          ...prevValues,
          [questionData.question]: currentValue,
        }));
      });
  };

  const getCheckboxChangeHandler = (choice) => (event) => {
    if (hasOnUncheckAnyWarning && !event.target.checked) {
      event.preventDefault();
      setShowWarningModal(true);
      setSelectedChoice(choice);
      return;
    }

    updateValue(choice);
  };

  return (
    <QuestionWrapper index={index} questionData={questionData}>
      <InputContainer questionData={questionData}>
        {questionData.choices.map((choice, index) => {
          return (
            <div className="checkbox" key={index}>
              <label className={`${inputIsDisabled ? 'disabled' : ''}`}>
                <input
                  type="checkbox"
                  value={choice.choice}
                  checked={values[questionData.question].includes(Number(choice.id))}
                  onChange={getCheckboxChangeHandler(choice)}
                  disabled={inputIsDisabled}
                />
                {choice.choice}
              </label>
              {choice.question_info ? <QuestionTooltip questionInfo={choice.question_info} /> : null}
            </div>
          );
        })}
      </InputContainer>
      {hasOnUncheckAnyWarning && onUncheckAnyWarningType === 'confirmation_modal' ? (
        <SimpleModal show={showWarningModal} containerclassName="smile-design-modal">
          <SimpleModal.Header title={onUncheckAnyWarningTitle} onClose={() => setShowWarningModal(false)} />
          <SimpleModal.ContentBody>
            <p>{onUncheckAnyWarningMessage}</p>
          </SimpleModal.ContentBody>
          <SimpleModal.FooterWithButtons
            onClickConfirm={() => setShowWarningModal(false)}
            onClickCancel={() => {
              setShowWarningModal(false);
              updateValue(selectedChoice);
            }}
            confirmButtonText={onUncheckAnyWarningConfirmText}
            cancelButtonText={onUncheckAnyWarningCancelText}
          />
        </SimpleModal>
      ) : null}
    </QuestionWrapper>
  );
};

/**
 * Component to render the radio input.
 * @param {object} props
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const RadioSelection = ({ questionData, index }) => {
  const {
    values,
    setValues,
    APIUrl,
    saveChangesStatus,
    canEdit,
    registerUnsavedChanges,
    unregisterUnsavedChanges,
    highlightUnsavedChanges,
    questions,
    setSessionExpired,
    setQuestions,
    questionBeingEdited,
    setAutosaveStatus,
  } = React.useContext(SmileDesignPreferencesContext);

  const [textInputHasEmptyError, setTextInputHasEmptyError] = React.useState(false);
  const [disableInputOnChange, setDisableInputOnChange] = React.useState(!Boolean(questionData.response_text));

  const inputIsDisabled = !canEdit() || (saveChangesStatus === 'loading' && (questionBeingEdited.id === questionData.id ? disableInputOnChange : true));

  const responseTextIsRequired = (choice) => Boolean(choice.text_placeholder);

  const getTextInputClassName = () => {
    return textInputHasEmptyError && highlightUnsavedChanges ? 'text-input error' : 'text-input';
  };

  const getFieldTitleClassName = () => {
    return textInputHasEmptyError && highlightUnsavedChanges ? 'error' : '';
  };

  const getTextAreaCountClassName = () => {
    const defaultClasses = ['text-area__count', 'text-area__count--bottom'];
    return defaultClasses.join(' ');
  };

  const getRadioChangeHandler = (choice) => () => {
    setAutosaveStatus('loading', questionData);

    const currentValue = values[questionData.question];

    setValues((prevValues) => ({
      ...prevValues,
      [questionData.question]: { ...prevValues[questionData.question], selectedChoice: Number(choice.id), responseText: '' },
    }));

    if (responseTextIsRequired(choice)) {
      setDisableInputOnChange(false);
      if (values[questionData.question].responseText === '') {
        setTextInputHasEmptyError(true);
        setAutosaveStatus(null);
        registerUnsavedChanges(questionData, index);
        return;
      }
    } else {
      setDisableInputOnChange(true);
    }

    setTextInputHasEmptyError(false);
    unregisterUnsavedChanges(questionData);

    const data = { changes: { ...questionData, selected_choice: Number(choice.id), response_text: null }, currentSessionValues: questions };
    Axios.put(APIUrl, data)
      .then(() => {
        setAutosaveStatus('success');
        Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
      })
      .catch(function (err) {
        setAutosaveStatus('error');
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
        setValues((prevValues) => ({
          ...prevValues,
          [questionData.question]: {
            ...prevValues[questionData.question],
            selectedChoice: currentValue.selectedChoice,
            responseText: currentValue.responseText,
          },
        }));
      });
  };

  const handleTextResponseChangeDebounceCb = React.useCallback(
    (responseText, currentValue) => {
      const responseTextData = responseText || null;
      const data = {
        changes: { ...questionData, selected_choice: currentValue.selectedChoice, response_text: responseTextData },
        currentSessionValues: questions,
      };
      Axios.put(APIUrl, data)
        .then(() => {
          setAutosaveStatus('success');
          Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
        })
        .catch(function (err) {
          setAutosaveStatus('error');
          if (err && err.response && err.response.status === 409) {
            setSessionExpired(true);
          }
        });
    },
    [questions, APIUrl, questionData, setQuestions, setAutosaveStatus, setSessionExpired]
  );

  const handleTextResponseChangeDebounceFn = React.useCallback(_.debounce(handleTextResponseChangeDebounceCb, 6000), [handleTextResponseChangeDebounceCb]);

  const getTextResponseChangeHandler = (choice) => (e) => {
    e.persist();

    let value = removeEmoji(e.target.value);

    const textInputHasMaxLengthError = value.length > TEXT_INPUT_MAX_LENGTH;

    if (textInputHasMaxLengthError) {
      value = value.slice(0, TEXT_INPUT_MAX_LENGTH);
    }

    const currentValue = values[questionData.question];

    if (currentValue.responseText === value) {
      return;
    }

    setAutosaveStatus('loading', questionData);

    setValues((prevValues) => ({
      ...prevValues,
      [questionData.question]: { ...prevValues[questionData.question], responseText: value },
    }));

    const textInputHasEmptyError = responseTextIsRequired(choice) && value === '';

    if (textInputHasEmptyError) {
      setTextInputHasEmptyError(true);
      setAutosaveStatus(null);
      unregisterUnsavedChanges(questionData);
      return;
    }

    unregisterUnsavedChanges(questionData);
    handleTextResponseChangeDebounceFn(value, currentValue);
  };

  return (
    <QuestionWrapper index={index} fieldTitleClassName={getFieldTitleClassName()} questionData={questionData}>
      <InputContainer questionData={questionData}>
        {questionData.choices.map((choice, index) => {
          return (
            <div className="radio" key={index}>
              <label className={`${inputIsDisabled ? 'disabled' : ''}`}>
                <input
                  type="radio"
                  value={choice.choice}
                  checked={Number(choice.id) === values[questionData.question].selectedChoice}
                  onChange={getRadioChangeHandler(choice)}
                  disabled={inputIsDisabled}
                />
                {choice.choice}
              </label>

              {Number(choice.id) === values[questionData.question].selectedChoice && responseTextIsRequired(choice) ? (
                <>
                  <div className="radio-text-input">
                    <textarea
                      className={getTextInputClassName(choice)}
                      rows={3}
                      value={values[questionData.question].responseText}
                      onChange={getTextResponseChangeHandler(choice)}
                      placeholder={choice.text_placeholder}
                      disabled={inputIsDisabled}
                    />
                  </div>
                  <div className={getTextAreaCountClassName()}>
                    <span>
                      {values[questionData.question].responseText.length}/{TEXT_INPUT_MAX_LENGTH}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          );
        })}
      </InputContainer>
    </QuestionWrapper>
  );
};

/**
 * Component to render the dropdown input.
 * @param {object} props
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const DropdownSelection = ({ questionData, index }) => {
  const { values, setValues, APIUrl, setAutosaveStatus, canEdit, saveChangesStatus, questions, setSessionExpired, setQuestions } =
    React.useContext(SmileDesignPreferencesContext);

  const inputIsDisabled = !canEdit() || saveChangesStatus === 'loading';

  const handleChange = (selectedOption) => {
    setAutosaveStatus('loading', questionData);

    const currentValue = values[questionData.question];

    setValues((prevValues) => ({ ...prevValues, [questionData.question]: selectedOption }));

    const data = { changes: { ...questionData, selected_choice: Number(selectedOption.value) }, currentSessionValues: questions };
    Axios.put(APIUrl, data)
      .then(() => {
        setAutosaveStatus('success');
        Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
      })
      .catch(function (err) {
        setAutosaveStatus('error');
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
        setValues((prevValues) => ({
          ...prevValues,
          [questionData.question]: currentValue,
        }));
      });
  };

  return (
    <QuestionWrapper index={index} questionData={questionData}>
      <InputContainer questionData={questionData}>
        <Dropdown
          isSearchable={false}
          value={values[questionData.question]}
          options={questionData.choices.map((choice) => ({ value: choice.id, label: choice.choice }))}
          onChange={handleChange}
          disabled={inputIsDisabled}
        />
      </InputContainer>
    </QuestionWrapper>
  );
};

/**
 * Component to render the text input.
 * @param {object} props
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const TextSelection = ({ questionData, index }) => {
  const {
    values,
    setValues,
    APIUrl,
    setAutosaveStatus,
    canEdit,
    unregisterUnsavedChanges,
    questions,
    setSessionExpired,
    setQuestions,
    questionBeingEdited,
    saveChangesStatus,
  } = React.useContext(SmileDesignPreferencesContext);
  const [error, setError] = React.useState(false);

  const inputIsDisabled = !canEdit() || (saveChangesStatus === 'loading' && questionBeingEdited.id !== questionData.id);

  const getTextInputClassName = () => {
    return error ? 'text-input error' : 'text-input';
  };

  const getFieldTitleClassName = () => {
    return error ? 'error' : '';
  };

  const getTextAreaCountClassName = () => {
    const defaultClasses = ['text-area__count', 'text-area__count--bottom'];

    if (error) {
      defaultClasses.push('text-input__count--error');
    }

    return defaultClasses.join(' ');
  };

  const handleChangeDebounceCb = React.useCallback(
    (responseText) => {
      const responseTextData = responseText || null;
      const data = { changes: { ...questionData, response_text: responseTextData }, currentSessionValues: questions };
      Axios.put(APIUrl, data)
        .then(() => {
          setAutosaveStatus('success');
          Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
        })
        .catch(function (err) {
          setAutosaveStatus('error');
          if (err && err.response && err.response.status === 409) {
            setSessionExpired(true);
          }
        });
    },
    [APIUrl, questionData, questions, setQuestions, setAutosaveStatus, setSessionExpired]
  );

  const handleChangeDebounceFn = React.useCallback(_.debounce(handleChangeDebounceCb, 6000), [handleChangeDebounceCb]);

  const handleChange = (e) => {
    e.persist();

    let value = removeEmoji(e.target.value);

    const textInputHasMaxLengthError = value.length > TEXT_INPUT_MAX_LENGTH;

    if (textInputHasMaxLengthError) {
      value = value.slice(0, TEXT_INPUT_MAX_LENGTH);
    }

    const currentValue = values[questionData.question];

    if (currentValue === value) {
      return;
    }

    setAutosaveStatus('loading', questionData);

    setValues((prevValues) => ({ ...prevValues, [questionData.question]: value }));

    unregisterUnsavedChanges(questionData);
    setError(false);
    handleChangeDebounceFn(value);
  };
  return (
    <QuestionWrapper index={index} questionData={questionData} fieldTitleClassName={getFieldTitleClassName()}>
      <InputContainer className={'input-container--text-input'} questionData={questionData}>
        <textarea
          className={getTextInputClassName()}
          rows={3}
          value={values[questionData.question]}
          onChange={handleChange}
          placeholder={questionData.text_placeholder}
          disabled={inputIsDisabled}
        />
        <div className={getTextAreaCountClassName()}>
          <span>
            {values[questionData.question].length}/{TEXT_INPUT_MAX_LENGTH}
          </span>
        </div>
      </InputContainer>
    </QuestionWrapper>
  );
};

/**
 * Component to render the tooth chart input.
 * @param {object} props
 * @param {number} props.index
 * @param {object} props.questionData
 * @returns {React.ReactNode}
 */
const ToothChartSelection = ({ questionData, index }) => {
  const { values, setValues, APIUrl, setAutosaveStatus, canEdit, questions, setSessionExpired, setQuestions, saveChangesStatus } =
    React.useContext(SmileDesignPreferencesContext);

  const inputIsDisabled = !canEdit() || saveChangesStatus === 'loading';

  const getApplyToAllInitial = () => {
    const defaultChoice = questionData.choices.find((choice) => questionData.default_choice.toString() === choice.id);
    const defaultChoiceValue = defaultChoice ? { label: defaultChoice.choice, value: defaultChoice.id } : '';
    const applyToAll = {};
    Object.keys(questionData.tooth_selections).forEach((teethPlace) => {
      applyToAll[teethPlace] = defaultChoiceValue;
    });
    return applyToAll;
  };

  const [applyToAll, setApplyToAll] = React.useState(getApplyToAllInitial());

  const getSingleDropdownChangeHandler = (teethPlaceParam, toothQuestionData) => (selectedOption) => {
    setAutosaveStatus('loading', questionData);

    const currentValue = values[questionData.question][teethPlaceParam][toothQuestionData.selection];

    setValues((prevValues) => {
      const newValues = { ...prevValues };
      newValues[questionData.question][teethPlaceParam][toothQuestionData.selection] = selectedOption;
      return newValues;
    });

    let toothSelectionsData = {};
    Object.keys(values[questionData.question]).forEach((teethPlace) => {
      toothSelectionsData[teethPlace] = Object.keys(values[questionData.question][teethPlace]).map((tooth) => {
        if (tooth === toothQuestionData.selection) {
          return { selection: tooth, selected_choice: selectedOption.value };
        }
        return { selection: tooth, selected_choice: values[questionData.question][teethPlace][tooth].value };
      });
    });

    const data = { changes: { ...questionData, tooth_selections: toothSelectionsData }, currentSessionValues: questions };
    Axios.put(APIUrl, data)
      .then(() => {
        setAutosaveStatus('success');
        Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
      })
      .catch(function (err) {
        setAutosaveStatus('error');
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
        setValues((prevValues) => {
          const newValues = { ...prevValues };
          newValues[questionData.question][teethPlaceParam][toothQuestionData.selection] = currentValue;
          return newValues;
        });
      });
  };

  const getApplyToAllChangeHandler = (teethPlaceParam) => () => {
    setAutosaveStatus('loading', questionData);

    setValues((prevValues) => {
      const newValues = { ...prevValues };
      questionData.tooth_selections[teethPlaceParam].forEach((tooth) => {
        newValues[questionData.question][teethPlaceParam][tooth.selection] = applyToAll[teethPlaceParam];
      });
      return newValues;
    });

    let toothSelectionsData = {};
    Object.keys(values[questionData.question]).forEach((teethPlace) => {
      if (teethPlace === teethPlaceParam) {
        toothSelectionsData[teethPlace] = Object.keys(values[questionData.question][teethPlace]).map((tooth) => {
          return { selection: tooth, selected_choice: applyToAll[teethPlace].value };
        });
      } else {
        toothSelectionsData[teethPlace] = Object.keys(values[questionData.question][teethPlace]).map((tooth) => {
          return { selection: tooth, selected_choice: values[questionData.question][teethPlace][tooth].value };
        });
      }
    });
    const data = { changes: { ...questionData, tooth_selections: toothSelectionsData }, currentSessionValues: questions };
    Axios.put(APIUrl, data)
      .then(() => {
        setAutosaveStatus('success');
        Axios.get(APIUrl).then((response) => setQuestions(response.data.preferences));
      })
      .catch(function (err) {
        setAutosaveStatus('error');
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
        setValues((prevValues) => {
          const newValues = { ...prevValues };
          questionData.tooth_selections[teethPlaceParam].forEach((tooth) => {
            newValues[questionData.question][teethPlaceParam][tooth.selection] = values[questionData.question][teethPlaceParam][tooth.selection];
          });
          return newValues;
        });
      });
  };

  return (
    <QuestionWrapper index={index} questionData={questionData}>
      <InputContainer questionData={questionData}>
        {Object.keys(questionData.tooth_selections).map((teethPlace, index) => {
          return (
            <div className="tooth-chart-selection" key={index}>
              <label className="teeth-label">{teethPlace.toUpperCase()}</label>
              <div className="teeth">
                {questionData.tooth_selections[teethPlace].map((tooth, index) => {
                  return (
                    <div className="tooth" key={index}>
                      <label className={`tooth__label ${['UR1 - UL1', 'LR1 - LL1'].includes(tooth.selection.toUpperCase()) ? 'bold' : ''}`}>
                        {tooth.selection.toUpperCase()}
                      </label>
                      <Dropdown
                        isSearchable={false}
                        value={values[questionData.question][teethPlace][tooth.selection]}
                        options={questionData.choices.map((choice) => ({ value: choice.id, label: choice.choice }))}
                        onChange={getSingleDropdownChangeHandler(teethPlace, tooth)}
                        disabled={inputIsDisabled}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="apply-to-all">
                <button type="button" className="btn btn--primary" onClick={getApplyToAllChangeHandler(teethPlace)} disabled={inputIsDisabled}>
                  Apply to all {teethPlace}
                </button>
                <Dropdown
                  isSearchable={false}
                  value={applyToAll[teethPlace]}
                  options={questionData.choices.map((choice) => ({ value: choice.id, label: choice.choice }))}
                  onChange={(selectedOption) => setApplyToAll((prevApplyToAll) => ({ ...prevApplyToAll, [teethPlace]: selectedOption }))}
                  disabled={inputIsDisabled}
                />
              </div>
            </div>
          );
        })}
      </InputContainer>
    </QuestionWrapper>
  );
};

/**
 * Question component, renders a question based on the question type
 *
 * @param {Object} props
 * @param {Object} props.questionData
 * @param {number} props.index
 *
 * @returns {JSX.Element}
 */
const Question = ({ questionData, index }) => {
  switch (questionData.question_type) {
    case 'checkbox':
      return <CheckboxSelection questionData={questionData} index={index} />;
    case 'radio':
      return <RadioSelection questionData={questionData} index={index} />;
    case 'dropdown':
      return <DropdownSelection questionData={questionData} index={index} />;
    case 'tooth_chart_selection':
      return <ToothChartSelection questionData={questionData} index={index} />;
    case 'text':
      return <TextSelection questionData={questionData} index={index} />;
    default:
      return null;
  }
};

const SmileDesignPreferencesPageContent = withRouter(({ userRolesAndPermissions, history }) => {
  const doctorId = React.useMemo(() => getDoctorsIdFromRoute(), []);
  const APIUrl = React.useMemo(() => `/apiv3/smiledesignpreference/${doctorId}`, [doctorId]);
  const AcceptAPIUrl = React.useMemo(() => `/apiv3/smiledesignpreference/accept/${doctorId}`, [doctorId]);

  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const [saveChangesStatus, setSaveChangesStatus] = React.useState(null);
  const [unsavedChanges, setUnsavedChanges] = React.useState([]);
  const [highlightUnsavedChanges, setHighlightUnsavedChanges] = React.useState(false);
  const [questions, setQuestions] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [unblock, setUnblock] = React.useState(null);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [afterUnblockGoTo, setAfterUnblockGoTo] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [sessionExpired, setSessionExpired] = React.useState(false);
  const [questionBeingEdited, setQuestionBeingEdited] = React.useState(null);
  const [preferencesAccepted, setPreferencesAccepted] = React.useState(true);

  const setAutosaveStatus = React.useCallback(
    (status, questionData = null) => {
      setSaveChangesStatus(status);
      setQuestionBeingEdited(questionData);
    },
    [setSaveChangesStatus, setQuestionBeingEdited]
  );

  const canEdit = () => {
    return userRolesAndPermissions.doctor_role === 'Business'
      ? preferencesAccepted
      : userHasPermission('IPP_EDIT', userRolesAndPermissions.permissions) ||
          userHasPermission('Smile Design Preferences', userRolesAndPermissions.program_enrollment);
  };

  const registerUnsavedChanges = (questionData, index) => {
    setUnsavedChanges((prevState) => {
      if (prevState.find((question) => question.id === questionData.id)) {
        return prevState;
      }
      const newState = [...prevState, { ...questionData, index }];
      newState.sort((a, b) => a.index - b.index);
      return newState;
    });
  };

  const unregisterUnsavedChanges = (questionData) => {
    setUnsavedChanges((prevState) => {
      return prevState.filter((question) => question.id !== questionData.id);
    });
  };

  const contextValue = {
    doctorId,
    APIUrl,
    questions,
    setQuestions,
    values,
    setValues,
    unsavedChanges,
    setUnsavedChanges,
    highlightUnsavedChanges,
    setHighlightUnsavedChanges,
    canEdit,
    showWarningModal,
    setShowWarningModal,
    afterUnblockGoTo,
    registerUnsavedChanges,
    unregisterUnsavedChanges,
    sessionExpired,
    setSessionExpired,
    saveChangesStatus,
    setSaveChangesStatus,
    questionBeingEdited,
    setQuestionBeingEdited,
    setAutosaveStatus,
  };

  const beforeUnloadListener = React.useCallback((event) => {
    setHighlightUnsavedChanges(true);
    event.preventDefault();
    event.returnValue = '';
  }, []);

  const addBeforeUnloadListener = React.useCallback(() => {
    window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }, [beforeUnloadListener]);

  const removeBeforeUnloadListener = React.useCallback(() => {
    window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
  }, [beforeUnloadListener]);

  const acceptSmileDesignPreferences = () => {
    Axios.put(AcceptAPIUrl)
      .then(function (res) {
        setPreferencesAccepted(true);
      })
      .catch(function (err) {
        if (err && err.response && err.response.status === 409) {
          setSessionExpired(true);
        }
      });
  };

  React.useEffect(() => {
    Axios.get(APIUrl)
      .then((response) => {
        setQuestions(response.data.preferences);
        if (!response.data.accepted && userRolesAndPermissions.doctor_role !== 'Business') {
          acceptSmileDesignPreferences();
        } else {
          setPreferencesAccepted(response.data.accepted);
        }
        const values = {};
        response.data.preferences.forEach((question) => {
          switch (question.question_type) {
            case 'checkbox':
              values[question.question] = question.selected_choice;
              break;
            case 'radio':
              const selectedRadioChoice = question.selected_choice || question.default_choice;
              const responseText = question.response_text || '';
              values[question.question] = { selectedChoice: selectedRadioChoice, responseText };
              break;
            case 'dropdown':
              const selectedDropdownChoice = question.selected_choice || question.default_choice;
              const value = question.choices.find((choice) => Number(choice.id) === selectedDropdownChoice);
              values[question.question] = { value: value.id, label: value.choice };
              break;
            case 'text':
              values[question.question] = question.response_text || '';
              break;
            case 'tooth_chart_selection':
              values[question.question] = {};
              Object.keys(question.tooth_selections).forEach((teethPlace) => {
                values[question.question][teethPlace] = {};
                question.tooth_selections[teethPlace].forEach((tooth) => {
                  const selectedToothChartSelectionChoice = tooth.selected_choice || question.default_choice.toString();
                  const value = question.choices.find((choice) => choice.id === selectedToothChartSelectionChoice);
                  values[question.question][teethPlace][tooth.selection] = { value: value.id, label: value.choice };
                });
              });
              break;
            default:
              break;
          }
        });
        setValues(values);
        setIsLoadingPage(false);
      })
      .catch(function (err) {
        setError(true);
      });
  }, [APIUrl]);

  React.useEffect(() => {
    if (unsavedChanges.length) {
      addBeforeUnloadListener();
      if (!unblock) {
        const newUnblock = history.block((location) => {
          setShowWarningModal(true);
          setHighlightUnsavedChanges(true);
          setAfterUnblockGoTo(location.pathname);
          return false;
        });
        setUnblock(() => newUnblock);
        return;
      }
    } else {
      setHighlightUnsavedChanges(false);
      if (unblock) {
        unblock();
        setUnblock(() => null);
      }
      removeBeforeUnloadListener();
    }
  }, [unsavedChanges]);

  return error ? (
    <NotFound />
  ) : (
    <div className="smile-design-preferences">
      <SmileDesignPreferencesContext.Provider value={contextValue}>
        <div className="smile-design-preferences__header">
          <h1>Smile Design Preferences</h1>
          {!canEdit() ? (
            <div className="header__subtitle">
              <span className="subtitle__text">No permission to edit</span>
            </div>
          ) : !preferencesAccepted ? (
            <div className="header__subtitle">
              <span className="subtitle__text">Provider must accept preferences to enable editing</span>
            </div>
          ) : null}
        </div>
        <div className="smile-design-preferences__form-container">
          {isLoadingPage ? (
            <Loader showAltText={false} />
          ) : (
            <form className="smile-design-preferences__form">
              {questions && values ? (
                questions.map((questionData, index) => <Question key={questionData.question} questionData={questionData} index={index + 1} />)
              ) : (
                <Loader showAltText={false} />
              )}
            </form>
          )}
        </div>
        <div className="smile-design-preferences__footer">
          {saveChangesStatus === 'loading' ? (
            <div className="footer__saving-indicator saving-indicator--saving-changes">
              <span className="saving-changes-icon">
                <LoadingIcon />
              </span>
              <span className="saving-indicator__text">Saving changes</span>
            </div>
          ) : null}
          {saveChangesStatus === 'success' ? (
            <div className="footer__saving-indicator saving-indicator--all-changes-saved">
              <CircleCheckIcon />
              <span className="saving-indicator__text">All changes saved</span>
            </div>
          ) : null}
          {saveChangesStatus === 'error' ? (
            <div className="footer__saving-indicator">
              <span className="saving-indicator__text">Error! changes was not saved</span>
            </div>
          ) : null}
        </div>
        <SimpleModal show={showWarningModal} containerclassName="smile-design-modal" theme="error">
          <SimpleModal.Header title={'Unsaved Changes'} onClose={() => setShowWarningModal(false)} />
          <SimpleModal.ContentBody>
            <p>The following questions have not been completed. Leaving this page will discard your changes.</p>
            <ul>
              {unsavedChanges.map((question) => (
                <li key={question.id}>
                  {question.index}. {question.question}
                </li>
              ))}
            </ul>
          </SimpleModal.ContentBody>
          <SimpleModal.FooterWithButtons
            theme="error"
            onClickConfirm={() => {
              unblock();
              removeBeforeUnloadListener();
              history.push(afterUnblockGoTo);
            }}
            onClickCancel={() => setShowWarningModal(false)}
            confirmButtonText={'Leave Page'}
            cancelButtonText={'Do Not Leave'}
          />
        </SimpleModal>
        {sessionExpired ? <SessionExpiredModal /> : null}
      </SmileDesignPreferencesContext.Provider>
    </div>
  );
});

const SmileDesignPreferences = () => {
  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => (
        <>
          <Helmet>
            <title>Smile Design Preferences | InBrace Smile Design Studio™</title>
          </Helmet>
          <SmileDesignPreferencesPageContent userRolesAndPermissions={user_roles_and_permissions} />
        </>
      )}
    </UserPermissionsContext.Consumer>
  );
};

export default SmileDesignPreferences;
