import { wasmControllerExecute } from '../wasm_controller';
import { PROCESSOR, WASM_ACTION, WASM_PROP } from '../wasm_controller_const';

export const ECompassControl = {
	Angulation: 'Angulation',
	CrownTorque: 'CrownTorque',
	ExtrusionIntrusion: 'ExtrusionIntrusion',
	HingeDistal: 'HingeDistal',
	HingeMesial: 'HingeMesial',
	RootTorque: 'RootTorque',
	Rotation: 'Rotation',
	TranslationBuccalLingual: 'TranslationBuccalLingual',
	TranslationMesialDistal: 'TranslationMesialDistal',
};

export class EditingModeProcessor {
	/**
	 * Handles change of the compass controls visibility
	 * @param {ECompassControl} [control]
	 * @return {String}
	 */
  static handleControlsVisibilityChange(control) {
    // make all controls visible if control does not exist
    const defaultValue = !(control in ECompassControl);
    const controlsVisibility = {
      [ECompassControl.Angulation]: defaultValue,
      [ECompassControl.CrownTorque]: defaultValue,
      [ECompassControl.ExtrusionIntrusion]: defaultValue,
      [ECompassControl.HingeDistal]: defaultValue,
      [ECompassControl.HingeMesial]: defaultValue,
      [ECompassControl.RootTorque]: defaultValue,
      [ECompassControl.Rotation]: defaultValue,
      [ECompassControl.TranslationBuccalLingual]: defaultValue,
      [ECompassControl.TranslationMesialDistal]: defaultValue,
    };
    if (control) {
      controlsVisibility[control] = true;
    }
    const req = {
      [WASM_PROP.EditingMode.CompassControlsVisibility]: controlsVisibility,
      Action: WASM_ACTION.EditingMode.UpdateCompassControlsVisibility,
      Processor: PROCESSOR.EditingMode,
    };
    return wasmControllerExecute(req);
  }
}
