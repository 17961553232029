import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getWasmViewerState } from '../../redux/selectors/wasm_viewer';
import { onChangeStep, setActiveTreatmentPlan } from '../../redux/actions/wasm_viewer/wasm_viewer';
import { setWasmActiveTreatmentPlan } from './wasm_controller/wasm_controller';
import { isIFSPlan } from './wasm_helper';
import { ETreatmentPlanStage } from './wasm-constants';

function getDropdownListItemClassName(is_active) {
  const classnames = ['wasm-dropdown-list-item'];
  if (is_active) classnames.push('wasm-dropdown-list-item--active');
  return classnames.join(' ');
}

function findTreatmentPlanName(treatment_list, index) {
  const treatment = treatment_list.find((treatment) => treatment.index === index);
  return treatment?.name;
}

function WasmDropdown(props) {
  const { treatment_plan_list, active_treatment_plan, setActiveTreatmentPlan, setActiveStage } = props;
  const [isOpen, setIsOpen] = useState(false);
  const chevronClassName = isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
  const headerClassName = isOpen ? 'wasm-dropdown-header wasm-dropdown-header--open' : 'wasm-dropdown-header';

  const isTreatmentPlanOk = treatment_plan_list && active_treatment_plan !== -1;

  const getTreatmentPlan = (index) => treatment_plan_list.find((tr) => tr.index === index);

  const onClick = (index) => {
    const tr = getTreatmentPlan(index);
    const nextStage = isIFSPlan(tr) ? ETreatmentPlanStage.Overcorrection : ETreatmentPlanStage.Ideal;
    setActiveTreatmentPlan(index);
    setWasmActiveTreatmentPlan(index);
    setActiveStage(nextStage);
    setIsOpen(false);
  };

  // don't allow to see treatment plans which are hidden for the user
  // when active_treatment_plan is lower than max treatment_plan index
  useEffect(() => {
    if (isTreatmentPlanOk) {
      setWasmActiveTreatmentPlan(active_treatment_plan);
      const treatmentPlan = getTreatmentPlan(active_treatment_plan);
      if (treatmentPlan && isIFSPlan(treatmentPlan)) {
        setActiveStage(ETreatmentPlanStage.Overcorrection);
      }
    }
  }, [isTreatmentPlanOk]);

  if (!isTreatmentPlanOk) {
    return <div />;
  }

  return (
    <div className="wasm-dropdown" onMouseLeave={() => setIsOpen(false)}>
      <div className={headerClassName} onClick={() => setIsOpen(!isOpen)}>
        <div>{findTreatmentPlanName(treatment_plan_list, active_treatment_plan)}</div>
        <i className={chevronClassName} aria-hidden />
      </div>
      {isOpen && (
        <div className="wasm-dropdown-list scrollbar--dark">
          {treatment_plan_list.map((item) => (
            <div className={getDropdownListItemClassName(item.index === active_treatment_plan)} key={item.index} onClick={() => onClick(item.index)}>
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    treatment_plan_list: wasm_state.treatment_plan_list,
    active_treatment_plan: wasm_state.active_treatment_plan,
    activeStage: wasm_state.step,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveTreatmentPlan: setActiveTreatmentPlan,
      setActiveStage: onChangeStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmDropdown);
