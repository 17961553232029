const VISIBILITY_UPPER_ONLY = {
  UpperTeethVisible: true,
  UpperTeethTransparent: false,
  LowerTeethVisible: false,
  LowerTeethTransparent: false,
};
const VISIBILITY_LOWER_TRANSPARENT = {
  UpperTeethVisible: true,
  UpperTeethTransparent: false,
  LowerTeethVisible: true,
  LowerTeethTransparent: true,
};
const VISIBILITY_BOTH_VISIBLE = {
  UpperTeethVisible: true,
  UpperTeethTransparent: false,
  LowerTeethVisible: true,
  LowerTeethTransparent: false,
};
const VISIBILITY_UPPER_TRANSPARENT = {
  UpperTeethVisible: true,
  UpperTeethTransparent: true,
  LowerTeethVisible: true,
  LowerTeethTransparent: false,
};
const VISIBILITY_LOWER_ONLY = {
  UpperTeethVisible: false,
  UpperTeethTransparent: false,
  LowerTeethVisible: true,
  LowerTeethTransparent: false,
};

const VISIBILITY_MAPPING = {
  lower: VISIBILITY_LOWER_ONLY,
  upperT: VISIBILITY_UPPER_TRANSPARENT,
  both: VISIBILITY_BOTH_VISIBLE,
  lowerT: VISIBILITY_LOWER_TRANSPARENT,
  upper: VISIBILITY_UPPER_ONLY,
};

const INC_MOVEMENT = {
  Angulation: 0.0,
  Inclination: 0.0,
  Mesial: 0.0,
  Occlusal: 0.0,
  Rotation: 0.0,
  Vestibular: 0.0,
};

const PROCESSOR = {
  CaseInfo: 'CaseInfoProcessor',
  Occlusalgram: 'OcclusalgramProcessor',
  Animation: 'AnimationProcessor',
  ColorTheme: 'ColorThemeProcessor',
  Comparison: 'ComparisionProcessor',
  EditingMode: 'EditingModeProcessor',
  Grid: 'GridProcessor',
  History: 'HistoryProcessor',
  IPRTable: 'IPRTableProcessor',
  MovementTable: 'MovementTableProcessor',
  OpenCase: 'OpenCaseProcessor',
  PredefinedView: 'PredefinedViewProcessor',
  RSC: 'RSCProcessor',
  Visibility: 'VisibilityProcessor',
  ToothPopupMenu: 'ToothPopupMenuProcessor',
  IPLabels3d: 'IPLabels3dProcessor',
  ErrorHandler: 'ErrorHandlerProcessor',
  BoltonAnalysis: 'BoltonAnalysisProcessor',
  MandibleShift: 'MandibleShiftProcessor',
  Configurer: 'ConfigurerProcessor',
};

const WASM_ACTION = {
  CaseInfo: {
    LockActivePlan: 'LockActiveTreatmentPlan',
    UnlockActivePlan: 'UnlockActiveTreatmentPlan', // should never be used to unlock treatment plans, it was added only for debugging
    SetActiveStage: 'SetActiveStage',
    SetActivePlan: 'SetActiveTreatmentPlan',
    RenameActivePlan: 'SetActiveTreatmentPlanName',
    DeleteActivePlan: 'DeleteActiveTreatmentPlan',
    SaveActivePlanAs: 'SaveActiveTreatmentPlanAs',
    RestorePlanFromParent: 'RestoreActiveTreatmentPlanFromParent',
  },
  Animation: {
    Play: 'Play',
    Stop: 'Stop',
    SetSpeed: 'SetSpeed',
  },
  ColorTheme: {
    UpdateColors: 'UpdateColors',
  },
  Comparison: {
    ShowSuperimposition: 'ShowSuperimposition',
    HideSuperimposition: 'HideSuperimposition',
    Compare: 'Compare',
  },
  Grid: {
    Show: 'Show',
    Hide: 'Hide',
  },
  History: {
    GetMetadata: 'Get metadata.json',
    Clear: 'ClearHistory',
    Redo: 'Redo',
    Undo: 'Undo',
  },
  IPR: {
    Changed: 'IPRChanged',
  },
  MovementTable: {
    Preview: 'ToothChanging',
    Apply: 'ToothChanged',
    SetSelectedTooth: 'SetSelectedTooth',
    SetIncrement: 'SetIncrement',
    IncrementalMovement: 'IncrementalMovement',
  },
  Occlusalgram: {
    Show: 'Show',
    Hide: 'Hide',
    UpdateColors: 'UpdateOcclusalgramColors',
  },
  OpenCase: {
    Open: 'OpenZip',
    Save: 'SaveZip',
  },
  PredefinedView: {
    Top: 'Top',
    Bottom: 'Bottom',
    Left: 'Left',
    Right: 'Right',
    Front: 'Front',
    Back: 'Back',
  },
  RSC: {
    On: 'On',
    Off: 'Off',
    GapClosureOn: 'Gap Closure On',
    GapClosureOff: 'Gap Closure Off',
    LocalInfluenceOn: 'Local Influence On',
    LocalInfluenceOff: 'Local Influence Off',
  },
  Visibility: {
    Update: 'UpdateState',
  },
  IPLabels: {
    Show: 'Show',
    Hide: 'Hide',
    SetState: 'SetState',
    ResetIP: 'ResetIP',
  },
  EditingMode: {
    Switch: 'Switch',
    UpdateCompass: 'UpdateCompass',
    UpdateCompassControlsVisibility: 'UpdateCompassControlsVisibility',
  },
  MandibleShift: {
    SetIgnored: 'SetIgnored',
  },
  Configurer: {
    Selection: 'Selection',
    ErrorHandler: 'ErrorHandler',
  },
};

const WASM_PROP = {
  CaseInfo: {
    Name: 'Name',
    IsLocked: 'Locked',
    RestorePlanFromParentEnabled: 'RestoreActiveTreatmentPlanFromParentEnabled',
    ActiveTreatmentPlanIndex: 'ActiveTreatementPlanIndex',
    Plans: 'TreatmentPlans',
  },
  RSC: {
    IsActive: 'RSCActive',
    IsGapClosure: 'RSCGapClosureIsSet',
    IsLocalInfluence: 'RSCLocalInfluenceIsSet',
  },
  EditingMode: {
    Mode: 'Mode',
    CompassControlsVisibility: 'CompassControlsVisibility',
    CompassXArchBased: 'CompassXArchBased',
    CompassAndAutoAdjustIPR: 'Compass & Auto Adjust IPR',
    MaintainContacts: 'Maintain Contacts',
    AutoAdjustIPR: 'Auto Adjust IPR',
    SelectTeeth: 'Select Teeth',
  },
  MovementTable: {
    AlphaNumID: 'AlphanumericID',
  },
  Comparison: {
    Delta: 'Delta',
    TransformsDelta: 'TransformsDelta',
    WithParentPlan: 'ComparisonWithParentPlan',
  },
  MandibleShift: {
    Ignored: 'Ignored',
  },
  IPLabels: {
    Jaw: 'Jaw',
    ResetSpaces: 'ResetSpaces',
  },
};

export {
  VISIBILITY_UPPER_ONLY,
  VISIBILITY_LOWER_TRANSPARENT,
  VISIBILITY_BOTH_VISIBLE,
  VISIBILITY_UPPER_TRANSPARENT,
  VISIBILITY_LOWER_ONLY,
  VISIBILITY_MAPPING,
  PROCESSOR,
  WASM_ACTION,
  WASM_PROP,
  INC_MOVEMENT,
};
