import './prop65_warning.scss';

import React, { Component } from 'react';
import AlertMessage from '../components/alert_message';

class Prop65Warning extends Component {
  render() {
    const message = (
      <>
        WARNING: This product contains nickel, a chemical known to the State of California to cause cancer. For more information, go to{' '}
        <a className="midnight" href="https://www.P65Warnings.ca.gov" target="_blank" rel="noopener noreferrer">
          www.P65Warnings.ca.gov
        </a>
      </>
    );
    return <AlertMessage message={message} theme="info" />;
  }
}

export default Prop65Warning;
