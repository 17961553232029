export const CaseStatus = {
  Submitted: 'Case Submitted',
  AwaitingFolderGeneration: 'Awaiting Folder Generation Process',
  FolderGeneration: 'Folder Generation Process',
  FolderGenerationOk: 'Folder Generation Success',
  FolderGeneratioFailed: 'Folder Generation Failed',
  Segmentation: 'Segmentation Process',
  DoctorUpload: 'STATUS_DOCTOR_UPLOAD',
  InBraceCheck: 'STATUS_INBRACE_CHECK',
  ReviseSetup: 'Revise Setup',
  SetupReview: 'Setup Review',
  SetupUploadReady: 'Setup Ready for Upload',
  SetupConversion: 'Setup Conversion Process',
  SetupConversionFailed: 'Setup Conversion Failed',
  SetupReleaseReady: 'Setup Ready for Release',
  DoctorApproveOrReviseSetup: 'Doctor Approve or Revise Setup',
  DoctorProvideClarification: 'Doctor Provide Clarification',
  DoctorClarificationResolved: 'Doctor Clarification Resolved',
  ApplianceDesign: 'CAD Process',
  StageI: 'Stage I',
  StageII: 'Stage II',
  StageIII: 'Stage III',
  QualityControlReview: 'Quality Control Review',

  FilesChecking: 'INBRACE Checking Files',
  SetupPreparing: 'INBRACE Preparing Setup',
  StatusManufacturing: 'STATUS_MANUFACTURE',
  ManufacturingCase: 'INBRACE Manufacturing Case',
  ProviderEdit: 'Provider Edit Review',
  IFSInConversion: 'Final Design in Conversion',
  IFSConversionFailed: 'Final Design Conversion Failed',
  IFSReadyForRelease: 'Final Design Ready for Release',
  IFSUpload: 'Final Design Upload',

  InBraceSetup: 'STATUS_INBRACE_SETUP',
  CaseShipped: 'Case Shipped',
  StatusShip: 'STATUS_SHIP',
  StatusHold: 'STATUS_HOLD',
  StatusUnhold: 'STATUS_UNHOLD',
  StatusCancel: 'STATUS_CANCEL',
  StatusUncancel: 'STATUS_UNCANCEL',
  StatusDoctorApproval: 'STATUS_DOCTOR_APPROVAL',
  Hold: 'Hold',
  Unhold: 'Unhold',
  Cancelled: 'Cancelled',
  Uncancelled: 'Uncancelled',
};

export const ECaseProcessStatus = {
  RevisionDraft: 1,
  RevisionDraftCancelled: 2,
  FolderJobFailedOnESLImport: 3,
  FolderJobFailedNoFilesForESLImport: 4,
};

export const AdjustedSetupStatus = {
  Initiated: 'Adjusted Setup Initiated',
  Uploaded: 'Adjusted Setup Zip Uploaded',
  Removed: 'Adjusted Setup Zip Upload Removed',
  Converting: 'Adjusted Setup Converting',
  Success: 'Adjusted Setup Conversion Success',
  Failed: 'Adjusted Setup Conversion Failed',
  Reprocess: 'Adjusted Setup Reprocess',
};

export const CaseHistoryStatus = {
  StartedRecordVerification: 'InBrace Started Records Verification',
  CompletedRecordVerification: 'InBrace Completed Records Verification',
  RecordsIncomplete: 'Records Incomplete',

  InbraceRequestedNewRecords: 'InBrace Requested New Records',
  ProviderUploadedNewRecords: 'Provider Uploaded New Records',

  InbraceStartedSmileDesign: 'InBrace Started Design',
  InbraceCompletedSmileDesign: 'InBrace Completed Design',

  InbraceStartedSmileDesignReview: 'Revision: InBrace Started Design Review',
  InbraceApprovedSmileDesignReview: 'Revision: InBrace Approved Design',

  ProviderRequestedRevision: 'Provider Requested Revision',
  ProviderApprovedDesign: 'Provider Approved Design',

  InbraceStartedFabrication: 'InBrace Started Case Fabrication',
  InbraceCompletedFabrication: 'InBrace Completed Case Fabrication',

  InbraceStartedClinicalReview: 'InBrace Initiated Clinical Review',
  InbraceCompletedClinicalReview: 'InBrace Completed Clinical Review',

  InbraceCaseOnHold: 'InBrace Placed Case on Hold',
  InbraceRequestedClarification: 'InBrace Requested Clarification',
  PendingDoctorClarification: 'Pending Doctor Clarification',
  InbraceResumed: 'InBrace Resumed',
};

export const SetupStatus = {
  Revised: {
    value: 'Revised',
    is: (target) => {
      return SetupStatus.Revised.value.toLowerCase() === target.toLowerCase();
    },
  },
  Released: {
    value: 'Released',
    is: (target) => {
      return SetupStatus.Released.value.toLowerCase() === target.toLowerCase();
    },
  },
  Rejected: {
    value: 'Rejected',
    is: (target) => {
      return SetupStatus.Rejected.value.toLowerCase() === target.toLowerCase();
    },
  },
  Approved: {
    value: 'Approved',
    is: (target) => {
      return SetupStatus.Approved.value.toLowerCase() === target.toLowerCase();
    },
  },
  InternallyRejected: {
    value: 'Internally Rejected',
    is: (target) => {
      return SetupStatus.InternallyRejected.value.toLowerCase() === target.toLowerCase();
    },
  },
  InternallyApproved: {
    value: 'Internally Approved',
    is: (target) => {
      return SetupStatus.InternallyApproved.value.toLowerCase() === target.toLowerCase();
    },
  },
  InBraceIFS: {
    value: 'InBrace IFS',
    is: (target) => {
      return SetupStatus.InBraceIFS.value.toLowerCase() === target.toLowerCase();
    },
  },
};
