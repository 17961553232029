import _ from 'lodash';
import React from 'react';
import Button from '../components/buttons/button';
import EditTable from '../components/tables/edit_table';
import TextBox from '../components/inputs/text_box';
import SecurityClearance from '../../components/security/security_clearance';
import { phoneNumberChar } from '../../common/functions';

function AddTelephoneNumber(props) {
  let that = props;

  const phone_data = [
    {
      id: 'phone_type',
      label: 'Type',
      col_width: '50%',
      mobile_order: 2,
      data_fields: ['type'],
      data_function: (props, data, fields, label = '') => {
        const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

        return props.editContent.id === data.id && data.is_active ? (
          <>
            {label}
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.editContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('type', 'Office')}>Office</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('type', 'Home')}>Home</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('type', 'Mobile')}>Mobile</a>
                </li>
              </ul>
            </li>
          </>
        ) : (
          value
        );
      },
      add_function: (props) => {
        return (
          <li className="dropdown heading-value-dropdown">
            <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
              {props.addContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
            </div>
            <ul className="dropdown-menu">
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('type', 'Office')}>Office</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('type', 'Home')}>Home</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('type', 'Mobile')}>Mobile</a>
              </li>
            </ul>
          </li>
        );
      },
    },
    {
      id: 'phone_name',
      label: 'Number',
      col_width: '50%',
      ellipsis: true,
      mobile_order: 1,
      data_fields: ['value'],
      data_function: (props, data, fields, label = '') => {
        const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

        return props.editContent.id === data.id && data.is_active ? (
          <>
            {label}
            <TextBox
              value={props.editContent.value}
              onChange={(e) => {
                e.target.value = phoneNumberChar(e.target.value);
                that.setEditValue('value', e);
              }}
              onKeyPress={(e) => that.isEnterKeyToSaveEditChanges(e)}
            />
          </>
        ) : (
          <span className='fs-exclude'>{value}</span>
        );
      },
      add_function: (props) => {
        return (
          <TextBox
            value={props.addContent.value}
            onChange={(e) => {
              e.target.value = phoneNumberChar(e.target.value);
              that.setNewValue('value', e);
            }}
            onKeyPress={(e) => that.isEnterKeyToSaveNewChanges(e)}
            autoFocus
          />
        );
      },
    },
    {
      id: 'phone_buttons',
      action: true,
      mobile_order: 4,
      action_function: (props, data, fields, show) => {
        if (props.addEnabled && data.id === 'add_buttons') {
          return (
            <>
              <Button onClick={() => that.saveNewChanges()}>Save</Button>
              <Button theme="secondary" onClick={() => that.clearNewMode()}>
                Cancel
              </Button>
            </>
          );
        } else if ((props.editContent.id === data.id && data.is_active) || show) {
          return (
            <>
              <Button onClick={() => that.saveEditChanges()}>Save</Button>
              <Button theme="revise" onClick={() => that.deleteEntry()}>
                Delete
              </Button>
              <Button theme="secondary" onClick={() => that.clearEditMode()}>
                Cancel
              </Button>
            </>
          );
        }
        return null;
      },
      add_function: (props) => {
        return (
          <>
            <Button onClick={() => that.saveNewChanges()}>Save</Button>
            <Button theme="secondary" onClick={() => that.clearNewMode()}>
              Cancel
            </Button>
          </>
        );
      },
    },
    {
      id: 'phone_add_buttons',
      add: true,
      mobile_order: 5,
      add_function: (props, fields) => {
        return (
          <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
            <Button
              className="float--right"
              onClick={() => that.setAddMode('add_number')}
              disabled={!(props.addContent.mode === '' && props.editContent.mode === '') || props.disabled}
            >
              Add
            </Button>
          </SecurityClearance>
        );
      },
    },
  ];
  const phone_mobile_data = _.cloneDeep(_.orderBy(phone_data, ['mobile_order']));

  return (
    <>
      <div className="account__section">
        <h3>Telephone Numbers</h3>
        {props.account.phone_numbers.length === 0 && (props.new_profile.mode === '' || props.new_profile.mode === 'add_email') ? (
          <div className="first-element-add">
            <Button type="button" onClick={() => props.setAddMode('add_number')} disabled={props.new_profile.mode === 'add_email' || props.disabled}>
              Add Number
            </Button>
          </div>
        ) : (
          <EditTable
            table_data={phone_data}
            mobile_data={phone_mobile_data}
            field_data={props.account.phone_numbers}
            addContent={props.new_profile}
            editContent={props.edit_profile}
            setEditMode={(data) => {
              return props.setEditMode('edit_number', data);
            }}
            setAddMode={(data) => {
              return props.setAddMode('add_number');
            }}
            editPermission="IPP_EDIT"
            addEnabled={props.new_profile.mode === 'add_number'}
            disabled={props.disabled}
          />
        )}
      </div>
      {props.edit_profile.error && props.edit_profile.mode === 'edit_number' ? (
        <div className="heading-item-warning">{props.edit_error_msg ? props.edit_error_msg : 'Please enter a valid telephone number'}</div>
      ) : null}
      {props.new_profile.error && props.new_profile.mode === 'add_number' ? (
        <div className="heading-item-warning">{props.new_error_msg ? props.new_error_msg : 'Please enter a valid telephone number'}</div>
      ) : null}
    </>
  );
}

export default AddTelephoneNumber;
