/**
 * File: page_number.js - A component to show page number for react-bootstrap pagination components
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Inbrace Software Team
 * Project: InBrace Provider/Business Portal
 * Special Notes: Provide startRange, endRange, total
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './page_number.scss';

// External Libs
import React, { Component } from 'react';

class PageNumber extends Component {
  render() {
    const { startRange, endRange, total, type } = this.props;
    return (
      <div className="page-number">
        {startRange}-{isNaN(endRange) ? 0 : endRange} of {total} {type}
      </div>
    );
  }
}

export default PageNumber;
