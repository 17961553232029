import { createSelector } from 'reselect';
import { isIFSPlan } from '../../../components/wasm_viewer/wasm_helper';

const getTreatmentPlans = createSelector([(state) => state.wasmViewerReducer], (wasmState) => wasmState.treatment_plan_list || []);
const getActivePlanIndex = createSelector([(state) => state.wasmViewerReducer], (wasmState) => wasmState.active_treatment_plan);

export const isIFSPlanActiveSelector = createSelector([getTreatmentPlans, getActivePlanIndex], (plans, activePlanIndex) => {
  if (!plans || !plans.length || activePlanIndex < 0) {
    return false;
  }
  const activePlan = plans.find((tr) => tr.index === activePlanIndex);
  return activePlan ? isIFSPlan(activePlan) : false;
});

export const getWasmViewerState = createSelector([(state) => state], (state) => state.wasmViewerReducer);

export const getWasmTMTState = createSelector([getWasmViewerState], (wasmViewerReducer) => wasmViewerReducer?.movement_table || {});

export const getWasmEditingTreatmentPlanIndex = createSelector(
  [getTreatmentPlans],
  /**
   * Extracts index of the first treatment plan
   * @param treatmentPlanList {Array<{}>}
   * @return {*}
   */
  (treatmentPlanList) => treatmentPlanList.at(0)?.index
);

export const getActiveToothIds = createSelector(
  [getWasmTMTState],
  /**
   * Extracts selected tooth ids
   * @param tmt {{
   *   [key: String]: Object,
   * }}
   * @return {string[]}
   */
  (tmt) => {
    return Object.keys(tmt).filter((id) => tmt[id]?.Selected);
  }
);

export const getWasmLoading = createSelector([getWasmViewerState], (state) => state.loading);

export const getWasmSidebarState = createSelector([getWasmViewerState], (wasmViewerReducer) => wasmViewerReducer.sidebar);

export const getSidebarActiveButton = createSelector([getWasmSidebarState], (sidebar) => sidebar.activeButton);

export const getSidebarCollapse = createSelector([getWasmSidebarState], (sidebar) => sidebar.collapse);

export const getSidebarWidth = createSelector([getWasmSidebarState], (sidebar) => sidebar.width);

export const getActiveStage = createSelector([getWasmViewerState], (state) => state.step);

export const hasIPR = createSelector([getWasmViewerState], (state) => Object.keys(state.ipr_table).some((key) => state.ipr_table[key] !== 0));

export const getMovement = createSelector([getWasmViewerState], (state) => state.movement);
