import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function TourHelpPanel({ restartTour, toggleHints, toggleTourAndHints, hintsEnabled }) {
  return (
    <div className="wasm-help-container">
      <ul className="wasm-help-menu">
        <li className="wasm-help-item" onClick={restartTour}>
          Restart Virtual Tour
        </li>
        <li className="wasm-help-item" onClick={toggleHints}>
          Turn {hintsEnabled ? 'off' : 'on'} hints
        </li>
      </ul>
      <FontAwesomeIcon
        className="wasm-help-icon"
        data-placement="left"
        data-toggle="tooltip"
        icon={faQuestionCircle}
        onClick={toggleTourAndHints}
        title="Reactivate Product Tour and Hints"
      />
    </div>
  );
}

TourHelpPanel.defaultProps = {
  restartTour: () => {},
  toggleHints: () => {},
  toggleTourAndHints: () => {},
  hintsEnabled: false,
};

TourHelpPanel.propTypes = {
  restartTour: PropTypes.func.isRequired,
  toggleHints: PropTypes.func.isRequired,
  toggleTourAndHints: PropTypes.func.isRequired,
  hintsEnabled: PropTypes.bool.isRequired,
};

export default TourHelpPanel;
