/**
 * File: case_submission.js - Contains the logic for submitting a case
 * Copyright: (c) Copyright October 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './case_submission.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal Components
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import CustomerAgreement from '../customer_agreement/customer_agreement';
import ErrorMessage from '../components/container/error_message';
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import PatientInfo from './patient_info';
import Photos from './photos';
import Radiographs from './radiographs';
import Scans from './scans';
import ShippingInfo from './shipping_info';
import Stepper from '../components/stepper';
import SubmissionConfirmation from './case_submission_confirmation';
import { SubmitErrorModal } from './submitting_error';
import Submit from './submit';
import SubmitterLoader from './submitting_loader';
import TxPlan from './tx_plan';
import TxPlan3 from './tx_plan_3';
import CaseSubmissionSummary from './submission_summary';

// Internal Functions
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../common/storage';
import {
  checkToken,
  convertDateWithFormat,
  dsoDraftDisabled,
  getDoctorIdFromPath,
  getRootPath,
  isDSOAssignedLoadable,
  isRoleAllowedToAssign,
  modalClear,
  patientChartTextConstraint,
  removeCaseIdInitialNumber,
  removeEmoji,
  removeOpposingTeeth,
  setTokenHeader,
  textFieldCheck,
  textFieldLimited,
} from '../../common/functions';
import { isBracketTeethValid as isBracketTeethValidHelper } from '../../common/tx_dx_form_utils';
import { applyCSSToID, isS3FileService, makePresets } from '../../common/helpers';
import { getDoctorsIdFromRoute } from '../../common/route';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import {
  updateCaseIteroFiles,
  fetchIteroScans,
  fetchIteroPhotos,
  addIteroPhotosToIncompleteCase,
  deleteIteroPhotosFromIncompleteCase,
} from '../../redux/actions/ipp/itero/itero_files';
import {
  getFetchIteroPhotosPending,
  getAddIteroPhotosToIncompleteCasePending,
  getDeleteIteroPhotosFromIncompleteCasePending,
} from '../../redux/reducers/ipp/itero/itero_files';
import { fetchLicenseNumbers } from '../../redux/actions/ipp/itero/itero_license_numbers';
import { getLicenseNumbers } from '../../redux/reducers/ipp/itero/itero_license_numbers';
import { buildRecordStates } from '../../redux/actions/record_viewer/record_viewer';
import { fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { getTempLink } from '../../common/dropbox';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';
import { hasTwoScansHelper, getIteroInitialState, WithIteroScansSelection, getMappedLicenseNumbersHelper } from '../../common/itero_utils';

//Modal
import EmptyFileErrorModal from '../../components/modal/empty_file';
import Gen1AlertModal from '../../components/modal/gen_1_alert_modal';
import PhotosS3 from './photos_s3';

const nowUTCString = Moment().utc().format('YYYY-MM-DDTHH:mm:ss.SS') + 'Z';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

/**
 * This component is used to allow a user to submit an initial case
 * @component
 * @alias DoctorCaseSubmissions
 * @category IPP
 */
class CaseSubmission extends WithIteroScansSelection {
  static contextType = UserPermissionsContext;

  constructor(props) {
    super(props);

    // Presets for TX2.0 Plan
    this.minPreset = 5;
    this.maxPreset = 7;
    this.presets = makePresets(this.minPreset, this.maxPreset);

    this.state = {
      id: '',
      error: false,
      warning: false,
      warning_title: 'Incomplete Fields',
      allow_error_msg: true,
      hide: false,
      new_case_id: '',
      loading: true,
      default_plan_version: 'TX2.0',

      //Prospect Information
      prospect_data: [],
      prospect_id: '',
      selected_prospect_id: '',
      referral_code_url: '',

      doctorId: '',
      dsoDoctorId: '',
      doctorfirstName: '',
      doctorlastName: '',
      doctorRole: '',
      doctorRoleId: '',
      doctorAccountLinkId: '',
      role: '',
      name: 'Patient Information',
      agreement: true,
      stepper: [2, 2, 2, 2, 2, 2, 2],
      active: 0,
      showCancelCase: false,
      showCaseRecommendationModal: false,
      showSaveModal: false,
      showEmptyFileModal: false,
      submit_state: '',

      //Doctors Info
      address: '',
      addresses: [],
      //Patients Info
      patientReferralCode: '',
      patientFirstName: '',
      patientLastName: '',
      patientDOB: '',
      patientSex: 'U',
      patientRef: '',
      assignedDoctor: '',
      showInvalidReferralCode: false,
      showRedeemedReferralCode: false,
      showUnableToValidateReferralCode: false,
      showValidReferralCode: false,
      showDeleteReferralCodeModal: false,
      referralCodeData: {},
      referral: false,
      editPatientReferralCode: '',

      //TX2.0
      chief_complaint: '',
      midlines: 'Show resulting midlines after alignment',
      auxiliariesWillBeUsed: 'No',
      auxiliariesNotes: '',
      formVisited: false,

      // Top down flow
      missingTeeth: ['UL8', 'UR8', 'LL8', 'LR8'],
      extractTeeth: [],
      bracketTeeth: this.presets[6],
      restrictTeeth: [],
      restrictReason: '',
      // Arch
      archToTreat: 'both',
      opposingArch: 'current',
      // Special Notes/Spacing Notes
      notes: '',
      notes_spacing: '',
      // TX3.0 state
      anteriorPosteriorR: 'current',
      anteriorPosteriorL: 'current',
      anteriorPosteriorCorrections: {
        classIIOrIIICorrectionSimulation: false,
        posteriorIPR: false,
      },
      anteriorPosteriorNotes: '',
      opposingLowerArch: 'current',
      opposingUpperArch: 'current',
      // Conditions
      conditions: {
        ap_relationship: {
          active: false,
          title: 'AP Relationship',
          info: [
            'InBrace is suited for cases that present Class II discrepancies ',
            'up to 1.5mm or Class III discrepancies up to 1mm. If larger discrepancies ',
            'exist, use sagittal auxiliaries before or in tandem with InBrace to ',
            'help achieve treatment goals.',
          ],
          ap_improvement: 'maintain',
          aux_note: '',
        },
        midlines: {
          active: false,
          title: 'Midlines',
          upper_mid_improve: 'maintain',
          upper_mid_improve_right_by: '',
          upper_mid_improve_left_by: '',
          lower_mid_improve: 'maintain',
          lower_mid_improve_right_by: '',
          lower_mid_improve_left_by: '',
        },
        crowding: {
          active: false,
          title: 'Crowding',
          info_title: true,
          upper_expand: 'as_needed',
          upper_ipr_ant: 'as_needed',
          upper_ipr_right: 'as_needed',
          upper_ipr_left: 'as_needed',
          upper_procline: 'as_needed',
          lower_expand: 'as_needed',
          lower_ipr_ant: 'as_needed',
          lower_ipr_right: 'as_needed',
          lower_ipr_left: 'as_needed',
          lower_procline: 'as_needed',
        },
        spacing: {
          active: false,
          title: 'Spacing',
          info_title: true,
          spaces: 'close',
          spaceTeeth: [],
          spacingUpper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          spacingLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          leave_spaces_text: '',
        },
        overjet: {
          active: false,
          title: 'Overjet',
          improve: 'inbrace',
          improve_ipr: false,
          procline: false,
          aux_note: '',
        },
        deepbite: {
          active: false,
          title: 'Deep Bite',
          info: [
            'InBrace is suited for cases that present 6mm of deep bite or less. ',
            'If deep bite greater than 6mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          correct: 'inbrace',
          aux_note: '',
        },
        openbite: {
          active: false,
          title: 'Open Bite',
          title_alt: 'Anterior Open Bite',
          info: [
            'InBrace is suited for cases that present 3mm of open bite or less. ',
            'If open bite greater than 3mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          improve_amt: 'maintain',
        },
        crossbite: {
          active: false,
          title: 'Crossbite/Transverse',
          info: ['InBrace can moderately expand/restrict archforms.'],
          anterior_advance_upper: false,
          anterior_retract_lower: false,
          posterior_expand_upper: false,
          posterior_narrow_upper: false,
          posterior_expand_lower: false,
          posterior_narrow_lower: false,
        },
      },

      // Uploaded files
      photoUpload: [],
      xrayUpload: [],
      scanUpload: [],

      // iTero integration - initial state
      ...getIteroInitialState({ iteroFileCaseType: 'incomplete', shouldFetchIteroPhotos: false, shouldAllowToRemoveAllIteroPhotos: true }),

      // Upload in progress
      photos_uploading: [],
      radiographs_uploading: [],
      scans_uploading: [],

      photo_completed: {},
      photo_statuses: {},

      caselist_filter: '',

      //Attributions
      attributions: [],
      attribution_questions: [],

      //agreement
      terms: false,

      case_id: '',
    };

    this.onStepperClick.bind(this);
    this.onInputChange_address.bind(this);

    this.onInputChange_patientFirstName.bind(this);
    this.onInputChange_patientLastName.bind(this);
    this.onInputChange_dob.bind(this);
    this.onInputChange_sex.bind(this);
    this.onInputChange_patientRef.bind(this);
    this.onInputChange_assignedDoctor.bind(this);

    this.hasAddressField.bind(this);
    this.hasOnePhoto.bind(this);
    this.hasOneXray.bind(this);
    this.hasTwoScans.bind(this);
    this.onIncompleteSave.bind(this);
    this.isSubmitAvailable.bind(this);
    this.isStepperDone.bind(this);
    this.updateStepperState.bind(this);

    this.onUpload.bind(this);
    this.onRemove.bind(this);

    this.onRemoveDraft.bind(this);
    this.onModalAccept.bind(this);
    this.onModalDismiss.bind(this);

    this.appendRoute.bind(this);
    this.freshRoute.bind(this);

    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);

    this.getPosition = this.getPosition.bind(this);
    this.getActivePositionByStepperName = this.getActivePositionByStepperName.bind(this);
    this.getNextStepperNameByStepperName = this.getNextStepperNameByStepperName.bind(this);
    this.getBackStepperNameByStepperName = this.getBackStepperNameByStepperName.bind(this);

    this.urlContainsWord = this.urlContainsWord.bind(this);
    this.photos_upload = this.photos_upload.bind(this);
    this.radiographs_upload = this.radiographs_upload.bind(this);
    this.scans_upload = this.scans_upload.bind(this);
    this.hide_warning = this.hide_warning.bind(this);
    this.show_warning = this.show_warning.bind(this);

    this.isUploadInProgress = this.isUploadInProgress.bind(this);
    this.showMoreTwoScansWarning = this.showMoreTwoScansWarning.bind(this);

    //TX2.0 Functionalities
    this.getImpliedTeethSets = this.getImpliedTeethSets.bind(this);
    this.getPlanByVersion = this.getPlanByVersion.bind(this);
    this.handleTeethClick = this.handleTeethClick.bind(this);
    this.setPlanConditionState = this.setPlanConditionState.bind(this);
    this.setPlanState = this.setPlanState.bind(this);
    this.metAllPlanRequiredField = this.metAllPlanRequiredField.bind(this);
  }

  // TX2.0 Static methods
  static makePreset = (n) => {
    var teeth = [];
    _.range(1, n + 1).forEach((x) => {
      teeth.push('UL' + x, 'UR' + x, 'LL' + x, 'LR' + x);
    });
    return teeth;
  };

  /**
   * iTero integration
   *
   * Update photo stepper state
   * @param {Array} photoUpload - photo upload array
   * @param {Array} stepper - stepper array
   * @returns {Array} new stepper array
   */
  updatePhotoStepperState = (photoUpload, stepper) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    const newStepper = [...stepper] || [...this.state.stepper];
    if (this.isPhotoUploadLengthValid(currentPhotoUpload)) {
      newStepper[this.getActivePositionByStepperName('Photos')] = 1;
    } else {
      newStepper[this.getActivePositionByStepperName('Photos')] = 2;
    }
    return newStepper;
  };

  /**
   * iTero integration
   *
   * Get case id for iTero scans
   */
  getCaseIdForIteroScans = () => {
    return this.state.id;
  };

  /**
   * iTero integration
   *
   * Get state key for iTero photos
   */
  getIteroPhotosStateKey() {
    return 'photoUpload';
  }

  /**
   * iTero integration
   *
   * Helper that returns true if should build itero record viewer
   * @function
   * @returns {Boolean} true if should build itero record viewer
   * @returns {Boolean} false if should not build itero record viewer
   */
  shouldBuildIteroRecordViewer = () => {
    return this.state.active === this.getActivePositionByStepperName('Impressions') && this.state.uploadMethod === 'itero';
  };

  /**
   * iTero integration
   *
   * On iTero files change
   */
  onSelectedIteroScanIdsChange = () => {
    this.updateCaseIteroFilesHelper();

    // update stepper state
    this.setState((prevState) => {
      let newStepper = prevState.stepper;

      if (this.state.selectedIteroScanIds.length === 2) {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
      } else {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
      }

      return { stepper: newStepper };
    });

    // clear warning
    this.onFocus_clearWarning();
  };

  /**
   * iTero integration
   *
   * Returns an array of uploaded photos that do not have an "itero_id" property.
   * @param {Array} photoUpload - An array of uploaded photos.
   * @returns {Array} - An array of uploaded photos without an "itero_id" property.
   */
  getUploadedPhotosWithouthIteroPhotos = (photoUpload) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    return currentPhotoUpload.filter((file) => !file.itero_id);
  };

  /**
   * iTero integration
   *
   * Handles the change event of the upload method dropdown.
   * If there is an existing case ID, it calls onIncompleteSave() before proceeding.
   * Updates the stepper state based on the selected upload method and the number of uploaded photos.
   * If the upload method is 'manual', it updates the stepper based on the number of impressions.
   * If the upload method is 'itero', it updates the stepper based on the number of selected Itero scan IDs and photos.
   * If shouldFetchIteroPhotos is true, it adds the selected Itero photo records to the photoUpload array.
   * Calls updatePhotoStepperState() to update the photo stepper state based on the photoUpload array.
   * @function
   * @name onUploadMethodChange
   * @memberof module:Doctor/CaseSubmission~CaseSubmission
   * @instance
   * @returns {void}
   */
  onUploadMethodChange = () => {
    if (this.state.id) {
      this.onIncompleteSave();
    }

    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      let newStepper = [...prevState.stepper];
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      let photoUpload = filteredPrevStatePhotoUpload;

      if (prevState.uploadMethod === 'manual') {
        if (prevState.scanUpload.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
      } else if (prevState.uploadMethod === 'itero') {
        if (prevState.selectedIteroScanIds.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
        if (this.state.shouldFetchIteroPhotos) {
          photoUpload = [...photoUpload, ...prevState.selectedIteroPhotoRecords];
        }
      }

      newStepper = this.updatePhotoStepperState(photoUpload, newStepper);

      return {
        stepper: newStepper,
        [photoUploadStateKey]: photoUpload,
      };
    });
  };

  /**
   * iTero integration
   *
   * On show already uploaded scans change
   */
  onShowAlreadyUploadedScansChange = () => {
    if (this.state.id) {
      this.onIncompleteSave();
    }
  };

  /**
   * iTero integration
   *
   * On should fetch itero photos change
   */
  onShouldFetchIteroPhotosChange = () => {
    if (this.state.id) {
      this.onIncompleteSave();
    }

    if (this.state.shouldFetchIteroPhotos && this.state.selectedIteroId) {
      if (this.hasIteroPhotos()) {
        const oldIteroId = this.getCurrentUploadedPhotosIteroId();
        if (oldIteroId !== this.state.selectedIteroId) {
          this.updateIteroPhotos(oldIteroId, this.state.selectedIteroId);
        }
      } else {
        this.fetchIteroPhotos(this.state.selectedIteroId);
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    //Check if user is still logged in
    const isAllowed = checkToken();
    if (!isAllowed) {
      this.props.history.push('/');
    }

    // iTero integration - component with iTero integration did update helper
    this.withIteroScansSelectionComponentDidUpdate(prevProps, prevState);

    // iTero integration - show already uploaded scans change
    if (this.state.showAlreadyUploadedScans !== prevState.showAlreadyUploadedScans) {
      this.onShowAlreadyUploadedScansChange();
    }

    if (this.state.active !== prevState.active) {
      this.buildIteroRecords();
    }
  }

  componentDidMount() {
    const isEnrolledToVersion3 = this.context.doctor_program_enrollment.includes('dx/tx_plan_3.0');
    const isEnrolledToiTeroIntegration = this.context.doctor_program_enrollment.includes('itero_integration');
    const isEnrolledToiTeroPhotosIntegration = this.context.doctor_program_enrollment.includes('itero_photos_integration');
    this.setState({
      isEnrolledToiTeroIntegration,
      isEnrolledToiTeroPhotosIntegration,
      default_plan_version: isEnrolledToVersion3 ? 'TX3.0' : this.state.default_plan_version,
      uploadMethod: isEnrolledToiTeroIntegration ? 'itero' : this.state.uploadMethod,
    });
    this.withIteroSelectionComponentDidMount();

    if (!this.props.fetchIteroPhotos) {
      throw new Error('fetchIteroPhotos redux action method is not in props');
    }
    if (!this.props.addIteroPhotosToIncompleteCase) {
      throw new Error('addIteroPhotosToIncompleteCase redux action method is not in props');
    }
    if (!this.props.deleteIteroPhotosFromIncompleteCase) {
      throw new Error('deleteIteroPhotosFromIncompleteCase redux action method is not in props');
    }

    let that = this;
    let rootId = '';
    let loadedState = {};

    if (that.props.history && that.props.history && that.props.history.location.search) {
      if (that.props.history.location.search.includes('doctor_id')) {
        rootId = that.props.history.location.search.substring(
          that.props.history.location.search.indexOf('?case_id=') + 9,
          that.props.history.location.search.indexOf('&')
        );
        that.setState({
          dsoDoctorId: that.props.history.location.search.split('&doctor_id=')[1],
        });
      } else {
        rootId = that.props.history.location.search.replace('?case_id=', '');
      }

      that.setState({
        caselist_filter:
          that.props.history.location && that.props.history.location.state && that.props.history.location.state.caselist_filter
            ? that.props.history.location.state.caselist_filter
            : '',
      });
    } else {
      rootId = null;
    }

    setTokenHeader();
    Axios.get(`/apiv3/keyval?key=referral_code_url`)
      .then(function (res) {
        if (res.data.data.length > 0 && res.data.data[0].current_ind) {
          that.setState({
            referral_code_url: res.data.data[0].value,
          });
        }
      })
      .catch(function (err) {});

    Axios.get('/apiv3/doctor')
      .then(function (result) {
        let doctor = result.data.doctor[0];
        let rootPath = getRootPath(that.props.history.location.pathname);

        if (!doctor.id || !rootPath) {
          return;
        }

        let routeId = rootPath.match(/portal\/(\w+)/);

        if ((doctor.role === 'Doctor' && routeId && doctor.id.toString() !== routeId[1]) || routeId === null) {
          that.setState({ error: true });
          return;
        }

        let getAddressId = '';

        if (doctor.role !== 'Doctor') {
          getAddressId = `?id=${routeId[1]}`;
        }

        that.setState({
          role: doctor.role,
          referral: doctor.referral,
        });

        return { id: routeId[1], role: doctor.role, addressId: getAddressId };
      })
      .then(function (res) {
        if (res && res.id) {
          const doctorId = res.id;
          Axios.get(`/apiv3/prospect/begin_submission?doctor_id=${doctorId}`).then(function (res2) {
            let prospect_data = res2 && res2.data && res2.data.prospects && res2.data.prospects.length > 0 ? res2.data.prospects : [];
            prospect_data = _.orderBy(prospect_data, 'first_name', 'last_name', 'email', ['desc']);
            Axios.get(`/apiv3/doctor/${doctorId}/profile`).then(function (doctorDetails) {
              const account_link_id = doctorDetails.data.doctor_profile[0].account_link_id;
              const doctorRole = doctorDetails.data.doctor_profile[0].role;
              const referral = doctorDetails.data.doctor_profile[0].referral;
              const doctorRoleId = res.id;
              const gen_2_doctor = doctorDetails.data.doctor_profile[0].gen_2;

              that.setState({
                referral: referral,
                doctorRole: doctorRole,
                doctorRoleId: doctorRoleId,
                doctorAccountLinkId: account_link_id,
                prospect_data: prospect_data,
                alert: !gen_2_doctor,
                gen_2: gen_2_doctor,
              });

              let licenseNumbers = [];
              let fetchedIteroScans = [];
              let fetchedIteroScansNextUrlCursor = '';
              that.props.fetchLicenseNumbers(doctorId, {
                onSuccess: async (_licenseNumbers) => {
                  licenseNumbers = _licenseNumbers;
                  that.props
                    .fetchIteroScans({ licenseNumbers: getMappedLicenseNumbersHelper(licenseNumbers) })
                    .then((data) => {
                      if (data.next) {
                        const fetchedIteroScansNextUrl = new URL(data.next);
                        fetchedIteroScansNextUrlCursor = fetchedIteroScansNextUrl.searchParams.get('cursor');
                      }
                      fetchedIteroScans = data.results;
                    })
                    .finally(() => {
                      const isDraftCase = that.props.incompleteId !== undefined || rootId !== null;
                      if (isDraftCase) {
                        //Load existing data from incomplete cases

                        let caseId = rootId !== null ? rootId : that.props.incompleteId;
                        Axios.get(`/api/incomplete/?id=${caseId}&doctorId=${that.state.dsoDoctorId ? that.state.dsoDoctorId : res.id}`)
                          .then(async function (result) {
                            if (result.data.data && result.data.data.length > 0) {
                              // Loading the data on click
                              loadedState = {
                                id: caseId,
                                fetchedIteroScans,
                                fetchedIteroScansNextUrlCursor,
                              };
                              if (result.data.data[0].referral_code && result.data.data[0].referral_code !== 'null') {
                                loadedState.patientReferralCode = result.data.data[0].referral_code;
                              }

                              if (result.data.data[0].referral_code_data) {
                                loadedState.referralCodeData = result.data.data[0].referral_code_data;
                              }

                              if (result.data.data[0].shipping_address_id) {
                                loadedState.address = result.data.data[0].shipping_address_id;
                              }

                              if (result.data.data[0].first_name) {
                                loadedState.patientFirstName = result.data.data[0].first_name;
                              }

                              if (result.data.data[0].prospect_id && result.data.data[0].prospect_id !== undefined) {
                                loadedState.prospect_id = result.data.data[0].prospect_id;
                              }

                              if (result.data.data[0].last_name) {
                                loadedState.patientLastName = result.data.data[0].last_name;
                              }

                              if (result.data.data[0].dob) {
                                loadedState.patientDOB = result.data.data[0].dob;
                              }

                              if (result.data.data[0].sex) {
                                loadedState.patientSex = result.data.data[0].sex;
                              }
                              if (result.data.data[0].patient_ref) {
                                loadedState.patientRef = result.data.data[0].patient_ref;
                              }
                              if (result.data.data[0].doctor_id) {
                                loadedState.assignedDoctor =
                                  (result.data.data[0].doctor_id.toString() === getDoctorsIdFromRoute() && res.role === 'DSO') ||
                                  result.data.data[0].doctor_id.toString() === account_link_id.toString()
                                    ? ''
                                    : result.data.data[0].doctor_id;
                              }

                              // Load TX2.0
                              if (result.data.data[0].revision_id === 'TX2.0') {
                                if (result.data.data[0].txplan_arch) {
                                  loadedState.archToTreat = result.data.data[0].txplan_arch;
                                }

                                let txplan_data = JSON.parse(result.data.data[0].txplan_data);

                                for (let data in txplan_data) {
                                  //Convert type
                                  if (txplan_data[data] === 'false') {
                                    loadedState[data] = false;
                                  } else if (txplan_data[data] === 'true') {
                                    loadedState[data] = true;
                                  } else if (data === 'missingTeeth' || data === 'extractTeeth' || data === 'bracketTeeth' || data === 'restrictTeeth') {
                                    loadedState[data] = txplan_data[data].split(',').filter((item) => Teeth.All.includes(item));
                                  } else if (data === 'conditions') {
                                    let conditions = JSON.parse(txplan_data[data]);
                                    for (const cond in conditions) {
                                      if (cond === 'crowding' || cond === 'spacing') {
                                        delete conditions[cond].info;
                                      }
                                    }
                                    loadedState[data] = conditions;
                                  } else {
                                    loadedState[data] = txplan_data[data];
                                  }
                                }
                              } else if (result.data.data[0].revision_id === 'TX3.0') {
                                // TX3.0 load txplan_data from response
                                if (result.data.data[0].txplan_arch) {
                                  loadedState.archToTreat = result.data.data[0].txplan_arch;
                                }

                                let txplan_data = JSON.parse(result.data.data[0].txplan_data);

                                for (let data in txplan_data) {
                                  //Convert type
                                  if (['missingTeeth', 'extractTeeth', 'bracketTeeth', 'restrictTeeth'].includes(data)) {
                                    loadedState[data] = txplan_data[data].split(',').filter((item) => Teeth.All.includes(item));
                                  } else if (['anteriorPosteriorCorrections'].includes(data)) {
                                    loadedState[data] = JSON.parse(txplan_data[data]);
                                  } else if (['opposingArch'].includes(data)) {
                                    if (loadedState?.archToTreat === 'upper') {
                                      loadedState['opposingUpperArch'] = txplan_data['opposingArch'];
                                    } else if (loadedState?.archToTreat === 'lower') {
                                      loadedState['opposingLowerArch'] = txplan_data['opposingArch'];
                                    }
                                    loadedState['opposingArch'] = txplan_data['opposingArch'];
                                  } else if (txplan_data[data] === 'false') {
                                    loadedState[data] = false;
                                  } else if (txplan_data[data] === 'true') {
                                    loadedState[data] = true;
                                  } else {
                                    loadedState[data] = txplan_data[data];
                                  }
                                }
                              }

                              //Load Photos
                              if (result.data.data[0].photoUpload && result.data.data[0].photoUpload.length > 0) {
                                loadedState.photoUpload = result.data.data[0].photoUpload;

                                // iTero integration - load previously selected iteroPhotoRecords
                                loadedState.selectedIteroPhotoRecords = result.data.data[0].photoUpload.filter((photo) => photo.itero_id);
                              }

                              //Load Xrays
                              if (result.data.data[0].xrayUpload && result.data.data[0].xrayUpload.length > 0) {
                                loadedState.xrayUpload = result.data.data[0].xrayUpload;
                              }

                              //Load Scans
                              if (result.data.data[0].scanUpload && result.data.data[0].scanUpload.length > 0) {
                                loadedState.scanUpload = result.data.data[0].scanUpload;
                              }

                              if (result.data.data[0].revision_id) {
                                loadedState.default_plan_version = result.data.data[0].revision_id;
                              }

                              if (isEnrolledToiTeroIntegration) {
                                // iTero integration - load previously selected iteroScanIds
                                if (result.data.data[0].iteroScanIds && result.data.data[0].iteroScanIds.length > 0) {
                                  const iteroScansLoadedState = await that.getIteroScansLoadedState({
                                    selectedIteroScanIds: result.data.data[0].iteroScanIds,
                                    fetchedIteroScans,
                                    fetchedIteroScansNextUrlCursor,
                                    licenseNumbers,
                                  });
                                  loadedState = { ...loadedState, ...iteroScansLoadedState };
                                }

                                // iTero integration - load iTero data
                                if (result.data.data[0].file_upload_settings) {
                                  const file_upload_settings = JSON.parse(result.data.data[0].file_upload_settings);

                                  // iTero integration - load uploadMethod, showAlreadyUploadedScans, shouldFetchIteroPhotos
                                  for (let setting in file_upload_settings) {
                                    if (file_upload_settings[setting] === 'false') {
                                      loadedState[setting] = false;
                                    } else if (file_upload_settings[setting] === 'true') {
                                      loadedState[setting] = true;
                                    } else {
                                      loadedState[setting] = file_upload_settings[setting];
                                    }
                                  }
                                }
                              }
                              that.setState(loadedState);

                              return result.data.data[0];
                            } else {
                              that.freshRoute();
                            }
                          })
                          .then(function (resultCaseData) {
                            let stepperState = that.state.stepper;

                            if (that.isSubmitAvailable()) {
                              stepperState = [1, 1, 1, 1, 1, 1, 2];
                            } else {
                              //Compute last known state
                              if (
                                that.state.patientFirstName !== '' &&
                                that.state.patientLastName !== '' &&
                                that.state.patientDOB !== '' &&
                                that.state.patientSex !== '' &&
                                that.hasAttributionResponse() &&
                                !(isRoleAllowedToAssign(that.state.doctorRole) && loadedState.assignedDoctor === '')
                              ) {
                                stepperState[0] = 1;
                              }

                              if (that.isScansLengthValid(that)) {
                                stepperState[1] = 1;
                              }

                              stepperState = that.updatePhotoStepperState(that.state.photoUpload, stepperState);

                              if (that.state.xrayUpload.length > 0) {
                                stepperState[3] = 1;
                              }

                              if (
                                that.state.default_plan_version === 'TX2.0' &&
                                that.state.chief_complaint !== '' &&
                                that.state.archToTreat !== '' &&
                                that.state.bracketTeeth &&
                                that.state.bracketTeeth.length > 0 &&
                                that.hasOneClinicalCondition() &&
                                that.hasClinicalConditionRequireFieldMet()
                              ) {
                                stepperState[4] = 1;
                              } else if (
                                // TX3.0 handle tX3.0 stepper
                                that.state.default_plan_version === 'TX3.0' &&
                                that.metAllPlanRequiredField(that)
                              ) {
                                stepperState[4] = 1;
                              }

                              if (that.state.address) {
                                stepperState[5] = 1;
                              }
                            }

                            let active = 0;
                            let name = 'Patient Information';

                            const address_id = that.state.doctorRole.includes('DSO') ? `?id=${that.state.doctorAccountLinkId}` : res.addressId;

                            Axios.get(`/api/practiceIdAddress/${address_id}`)
                              .then(function (result) {
                                if (result.data.data && result.data.data.length > 0 && that.state.active === 0) {
                                  if (that.props.history.location.pathname.indexOf('doctor') >= 0 && stepperState[0] !== 0) {
                                    active = that.getActivePositionByStepperName('Doctor Information');
                                    name = 'Doctor Information';
                                  } else if (that.props.history.location.pathname.indexOf('patient') >= 0 && stepperState[1] !== 0) {
                                    that.removeAttributionErrors();
                                    active = that.getActivePositionByStepperName('Patient Information');
                                    name = 'Patient Information';
                                  } else if (that.props.history.location.pathname.indexOf('dxtx') >= 0 && stepperState[2] !== 0) {
                                    active = that.getActivePositionByStepperName('Dx/Tx Plan');
                                    name = 'Dx/Tx Plan';
                                  } else if (that.props.history.location.pathname.indexOf('photos') >= 0 && stepperState[3] !== 0) {
                                    active = that.getActivePositionByStepperName('Photos');
                                    name = 'Photos';
                                  } else if (that.props.history.location.pathname.indexOf('radiographs') >= 0 && stepperState[4] !== 0) {
                                    active = that.getActivePositionByStepperName('X-Rays');
                                    name = 'X-Rays';
                                  } else if (that.props.history.location.pathname.indexOf('scans') >= 0 && stepperState[5] !== 0) {
                                    active = that.getActivePositionByStepperName('Impressions');
                                    name = 'Impressions';
                                    that.showMoreTwoScansWarning(that.state.scanUpload);
                                  } else if (that.props.history.location.pathname.indexOf('submit') >= 0 && stepperState[6] !== 0) {
                                    active = that.getActivePositionByStepperName('Submit');
                                    name = 'Submit';
                                  } else {
                                    that.appendRoute(caseId, 'doctor');
                                  }
                                  that.setState({
                                    active: active,
                                    name: name,
                                    addresses: result.data.data,
                                    stepper: stepperState,
                                    doctorfirstName: doctorDetails.data.doctor_profile[0].user__first_name,
                                    doctorlastName: doctorDetails.data.doctor_profile[0].user__last_name,
                                    doctorId: doctorDetails.data.doctor_profile[0].id,
                                  });
                                }
                              })
                              .then(function () {
                                Axios.get(`/apiV2/agreement`)
                                  .then(function (res) {
                                    that.setState({
                                      loading: false,
                                    });
                                  })
                                  .catch(function (err) {
                                    that.setState({
                                      loading: false,
                                      terms: true,
                                    });
                                  });
                              });

                            // Load attributions questions
                            Axios.get('/apiv3/attributionquestions', {
                              params: {
                                release_date__lt: nowUTCString,
                              },
                            })
                              .then((res) => {
                                if (res && res.data && res.data.attribution_questions) {
                                  that.setState({
                                    attributions: that.getAttributionsFromQuestionSet(
                                      resultCaseData.attribution_response,
                                      resultCaseData.id,
                                      res.data.attribution_questions
                                    ),
                                    attribution_questions: res.data.attribution_questions,
                                  });
                                }
                              })
                              .catch(function (err) {
                                console.log(err);
                              });
                          })
                          .catch(function (error) {
                            that.onRedirectManageCases();
                          });
                      } else {
                        that.setState({
                          fetchedIteroScans,
                          fetchedIteroScansNextUrlCursor,
                        });
                        //New Case submission
                        const address_id = that.state.doctorRole.includes('DSO') ? `?id=${that.state.doctorAccountLinkId}` : res.addressId;

                        Axios.get(`/api/practiceIdAddress/${address_id}`)
                          .then(function (result) {
                            if (result.data.data && result.data.data.length > 0 && that.state.active === 0) {
                              const assigned_doctor = res.id && res.role !== 'DSO' ? res.id : that.state.assignedDoctor;

                              that.setState({
                                address: '',
                                addresses: result.data.data,
                                doctorfirstName: doctorDetails.data.doctor_profile[0].user__first_name,
                                doctorlastName: doctorDetails.data.doctor_profile[0].user__last_name,
                                doctorId: doctorDetails.data.doctor_profile[0].id,
                                showCaseRecommendationModal: false,
                                assignedDoctor: assigned_doctor,
                              });
                            }
                          })
                          .then(function () {
                            Axios.get(`/apiV2/agreement`)
                              .then(function (res) {
                                that.setState({
                                  loading: false,
                                });
                              })
                              .catch(function (err) {
                                that.setState({
                                  loading: false,
                                  terms: true,
                                });
                              });
                          });

                        // Load attributions questions
                        Axios.get('/apiv3/attributionquestions', {
                          params: {
                            release_date__lt: nowUTCString,
                          },
                        })
                          .then((res) => {
                            if (res && res.data && res.data.attribution_questions) {
                              that.setState({
                                attribution_questions: res.data.attribution_questions,
                                attributions: that.getAttributionsFromQuestionSet([], '', res.data.attribution_questions),
                              });
                            }
                          })
                          .catch(function (err) {
                            console.error(err);
                          });
                      }
                    });
                },
              });
            });
          });
        }
      });
  }

  /**
   * Show Delete referral code.
   * @function
   */
  onDeleteLabelClick = () => {
    this.setState({ showDeleteReferralCodeModal: true });
  };

  /**
   * Updates Attribution Selection
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onAttributionsChange = (event) => {
    let stepper = this.state.stepper;
    let value = event.target.value;
    let category = event.target.attributes.getNamedItem('category').value;
    let attributions = this.state.attributions;
    let current_category = attributions.find((att) => att.question_category === category);
    let checked_attributions = current_category ? current_category.question_response : [];

    this.onFocus_clearWarning();

    if (!current_category) {
      attributions = this.getAttributionsFromQuestionSet(attributions, this.state.id);
      current_category = attributions.find((att) => att.question_category === category);
    }

    if (_.intersection(checked_attributions, [value]).length > 0) {
      checked_attributions = _.difference(checked_attributions, [value]);
    } else {
      if (value === 'None') {
        checked_attributions = [value];
        current_category.other_response = null;
      } else {
        checked_attributions.push(value);
      }
    }

    checked_attributions.sort();
    current_category.question_response = checked_attributions;

    attributions = _.cloneDeep(attributions);
    this.setState({ attributions: attributions });

    if (
      checked_attributions.length > 0 &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      !this.state.editPatientReferralCode &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[0] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (!this.hasAttributionResponse()) {
      stepper[0] = 2;
    }

    this.setState({ stepper: stepper, attributions: attributions });
  };

  /**
   * Updates Attribution other input field
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onAttributionOtherChange = (event) => {
    let text = removeEmoji(textFieldCheck(event.target.value));
    let stepper = this.state.stepper;
    let category = event.target.attributes.getNamedItem('category').value;
    let attributions = this.state.attributions;

    this.onFocus_clearWarning();

    attributions.find((question_set) => question_set.question_category === category).other_response = text;
    attributions = _.cloneDeep(attributions);

    if (
      text !== '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      !this.state.editPatientReferralCode &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[0] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (text === '') {
      stepper[0] = 2;
    }

    this.setState({ stepper: stepper, attributions: attributions });
  };

  /**
   * Check attribution error
   * @function
   * @param {Boolean} displayError - Should update attribution state for error display
   * @return {Object} - Attribution object with updated error
   */
  checkAttributionError = (displayError) => {
    let updated_attributions = _.cloneDeep(this.state.attributions);
    updated_attributions.forEach((question_set) => {
      question_set['required'] = true;
      const attributions_error = question_set.required && question_set.question_response && question_set.question_response.length === 0;
      const attribution_other_error =
        question_set.required && question_set.question_response && question_set.question_response.includes('Other') && !question_set.other_response;

      question_set['attributions_error'] = attributions_error;
      question_set['attribution_other_error'] = attribution_other_error;
    });
    if (displayError) {
      this.setState({ attributions: updated_attributions });
    }
    return updated_attributions;
  };

  /**
   * Check if attribution questions are allowed
   * @function
   * @param {Object} current_attributions - current attribution object
   * @param {String} case_id - Case id
   * @param {Array} attribution_questions - Attribution questions
   * @return {Object} - New attribution created from attribution question set
   */
  getAttributionsFromQuestionSet = (current_attributions, case_id, attribution_questions) => {
    const current_attribution_questions = attribution_questions ? attribution_questions : this.state.attribution_questions;
    let new_attributions = current_attributions ? current_attributions : [];
    const categories = [...new Set(current_attribution_questions.map((q) => q.category))];
    categories.forEach((category) => {
      const category_exists = new_attributions.find((question_set) => question_set.question_category === category);
      if (!category_exists) {
        const release_date = current_attribution_questions.find((question) => question.category === category).release_date;
        let new_category = {
          case_id: case_id.toString(),
          other_response: null,
          question_category: category,
          question_response: [],
          release_date: release_date,
          required: true,
          attributions_error: false,
          attribution_other_error: false,
        };
        new_attributions.push(new_category);
      }
    });

    new_attributions.forEach((q_set) => {
      q_set['attributions_error'] = false;
      q_set['attribution_other_error'] = false;
    });
    return new_attributions;
  };

  /**
   * Deletes referral code once confirmed.
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onDeleteReferralCodeConfirm = () => {
    this.setState(
      {
        showDeleteReferralCodeModal: false,
        editPatientReferralCode: '',
        patientReferralCode: '',
        referralCodeData: {},
      },
      () => {
        this.onIncompleteSave('patient');
      }
    );
  };

  /**
   * Show 0 data file error Modal
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Validate referral code once confirmed.
   *
   * @param {Object} event - contains the event handler values when event is trigger
   * @function
   */
  onValidateButtonClick = (event) => {
    event.preventDefault();
    const referral_code = this.state.editPatientReferralCode;
    const that = this;
    if (referral_code) {
      Axios.get(`/apiv3/referralcode/${referral_code}/validate`)
        .then(function (res) {
          that.setState(
            {
              patientReferralCode: referral_code,
              showValidReferralCode: true,
              referralCodeData: res.data.referral_data,
            },
            that.onFocus_clearWarning()
          );
        })
        .catch(function (errors) {
          if (errors.response && errors.response.status === 400) {
            that.setState(
              {
                showInvalidReferralCode: true,
              },
              that.onFocus_clearWarning()
            );
          } else if (errors.response && errors.response.status === 409) {
            that.setState(
              {
                showRedeemedReferralCode: true,
              },
              that.onFocus_clearWarning()
            );
          } else if (errors.response && errors.response.status === 408) {
            that.setState(
              {
                showUnableToValidateReferralCode: true,
              },
              that.onFocus_clearWarning()
            );
          }
        });
    }
  };

  /* Doctors Info */
  onInputChange_address = (event) => {
    const stepper = this.state.stepper;
    const value = event.target.dataset.id;

    if (value !== '') {
      stepper[5] = 1;
    }

    this.setState({
      component: (
        <ShippingInfo
          onInputChange={this.onInputChange_address}
          address={parseInt(value)}
          addresses={this.state.addresses}
          onFocus={this.onFocus_clearWarning}
          firstName={this.state.doctorfirstName}
          lastName={this.state.doctorlastName}
          disabledEdit={dsoDraftDisabled(this.state.doctorRole, this.state.doctorRoleId, this.state.assignedDoctor)}
        />
      ),
      address: parseInt(value),
      stepper: stepper,
    });
  };

  photos_upload = (filenames) => {
    this.setState({
      photos_uploading: filenames,
    });
  };

  radiographs_upload = (filenames) => {
    this.setState({
      radiographs_uploading: filenames,
    });
  };

  scans_upload = (filenames) => {
    this.setState({
      scans_uploading: filenames,
    });
  };

  hide_warning = () => {
    this.setState({
      warning: false,
      warning_title: 'Incomplete Fields',
    });
  };

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  onFocus_clearWarning = (event) => {
    let warning = document.querySelector('#warning-submit');

    if (warning) {
      warning.classList.remove('warning-display');
    }

    let terms = document.querySelector('#terms');

    const stepper = this.state.stepper;
    if (terms && terms.checked) {
      stepper[6] = 1;
    } else {
      stepper[6] = 2;
    }

    this.setState({
      stepper,
    });

    this.hide_warning();
    this.removeAttributionErrors();
    handleAnteriorPosteriorCheckboxErrors(false);

    let ids = [
      '#providerAddress',
      '#providerAddressLabel',
      '#patientFirstName',
      '#patientFirstNameLabel',
      '#patientLastName',
      '#patientLastNameLabel',
      '#patientDOB',
      '#patientDOBLabel',
      '#patientSex',
      '#patientRef',
      '#assignedDoctor',
      '#assignedDoctorLabel',
      '#providerAddress',
      '#providerAddressLabel',
      '#patientChiefComplaint',
      '#patientChiefComplaintLabel',
      '#archToTreat',
      '#archToTreatLabel',
      '#teethToTreat',
      '#teethToTreatLabel',
      '#chief_complaint',
      '#chief_complaint_title',
      '#arch_to_treat',
      '#bracket_teeth',
      '#clinical_condition',
      '#ap_relationship_title',
      '#ap_relationship_box',
      '#crowding_title',
      '#spacing_title',
      '#overjet_title',
      '#overjet_box',
      '#deep_bite_title',
      '#deep_bite_box',
      '#crossbite_transverse_title',
      '#referralCodeLabel',
      '#referralCode',
      '#anterior_posterior',
      '#anteriorPosteriorNotes',
      '#tx_midlines',
      '#tx_auxiliaries',
      '#auxiliariesNotes',
    ];

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }
    });
  };

  getCurrentData = () => {
    // eslint-disable-next-line
    let that = this;
    let formData = new FormData();
    let attributions = this.state.attributions.length === 0 ? '' : this.state.attributions;

    if (this.state.dsoDoctorId) {
      formData.append('doctorId', this.state.dsoDoctorId);
    } else {
      formData.append('doctorId', this.state.doctorId);
    }
    if (this.state.selected_prospect_id) {
      formData.append('prospect_id', this.state.selected_prospect_id);
    }
    formData.append('id', this.state.id);
    formData.append('stepper', this.state.stepper);
    formData.append('address', this.state.address);
    formData.append('patientFirstName', this.state.patientFirstName);
    formData.append('patientLastName', this.state.patientLastName);
    formData.append('patientDOB', this.state.patientDOB);
    formData.append('patientSex', this.state.patientSex);
    formData.append('patientRef', this.state.patientRef);
    if (this.state.patientReferralCode) {
      formData.append('referral_code', this.state.patientReferralCode);
      if (this.state.referralCodeData && this.state.referralCodeData.id) {
        formData.append('referral_id', this.state.referralCodeData.id);
        formData.append('referral_code_data', this.state.referralCodeData);
      }
    } else {
      formData.append('referral_code', '');
      formData.append('referral_id', '');
      formData.append('referral_code_data', '');
    }

    if (isRoleAllowedToAssign(this.state.doctorRole)) {
      formData.append('assignedDoctor', this.state.assignedDoctor);
    }

    formData = this.appendPlanToForm(formData);

    formData.append('photos', JSON.stringify(this.state.photoUpload));
    formData.append('xrays', JSON.stringify(this.state.xrayUpload));

    // iTero integration - add iTero file data to formData
    formData.append('uploadMethod', this.state.uploadMethod);
    formData.append('showAlreadyUploadedScans', this.state.showAlreadyUploadedScans);
    formData.append('shouldFetchIteroPhotos', this.state.shouldFetchIteroPhotos);
    formData.append('selected_itero_file_ids', JSON.stringify(this.state.selectedIteroScanIds));

    formData.append('scans', JSON.stringify(this.state.scanUpload));

    formData.append('revision', this.state.default_plan_version);
    formData.append('attribution_response', JSON.stringify(attributions));

    return formData;
  };

  getUploadJson = (uploads, index) => {
    const hasRecordStates = this.props.record_states && this.props.record_states.length >= uploads.length + index;
    if (!hasRecordStates) return;
    const result = uploads.map((photo, i) => ({ ...photo, record_state: this.props.record_states[i + index].state }));
    return JSON.stringify(result);
  };

  /**
   * Clear attribution questions
   * @function
   */
  removeAttributionErrors = () => {
    let attributions = this.state.attributions;
    attributions.forEach((question_set) => {
      if (question_set && question_set.attributions_error) {
        question_set.attributions_error = false;
      }
      if (question_set && question_set.attribution_other_error) {
        question_set.attribution_other_error = false;
      }
    });
    this.setState({ attributions: attributions });
    if (this.state.component && this.state.component.props && this.state.component.props.attributions) {
      this.setState({ attributions: attributions });
    }
  };

  /**
   * Saves the current state of the treatment instruction.
   *
   * This is achieved by calling the incomplete API which saves the files upload
   * information and the instruction
   * @param {Object} event - contains the event handler values when event is trigger
   * @param {String} photo_type - folder name of the photo type (photo, xray, scan)
   * @param {Object} options - additional options to be passed to the save function
   * @param {Boolean} options.refreshCaseList - refresh the case list after save
   * @param {Boolean} options.refreshDashboard - refresh the dashboard after save
   * account is created.
   * @function
   */
  onIncompleteSave = (event, photo_type = '', options = {}) => {
    let that = this;
    let redirect = 'false';
    let routename = '';

    if (event && event.currentTarget && event.currentTarget.dataset && event.currentTarget.dataset.redirect) {
      redirect = event.currentTarget.dataset.redirect;
    } else if (typeof event === 'string') {
      routename = event;
    }

    let formData = this.getCurrentData();

    Axios.post('/api/incomplete/', formData).then(function (result) {
      //Show save message for 3 secs
      //redirect to the main screen
      let photos = JSON.parse(result.data.data.photos);
      let xrays = JSON.parse(result.data.data.xrays);
      let scans = JSON.parse(result.data.data.scans);
      let case_id = '';

      if (that.state.id === '') {
        that.setState({
          id: result.data.data.incomplete_case_id,
          photoUpload: photos,
          xrayUpload: xrays,
          scanUpload: scans,
          showSaveModal: redirect && redirect === 'true',
        });
        case_id = result.data.data.incomplete_case_id;
      } else {
        //Prevent overwrite during save transition
        if (that.state.photoUpload.length !== photos.length && photo_type !== 'photos') {
          photos = that.state.photoUpload;
        } else if (that.state.xrayUpload.length !== xrays.length && photo_type !== 'xrays') {
          xrays = that.state.xrayUpload;
        } else if (that.state.scanUpload.length !== scans.length && photo_type !== 'scans') {
          scans = that.state.scanUpload;
        }

        if (that.state.selected_prospect_id) {
          that.setState({ prospect_id: that.state.selected_prospect_id, selected_prospect_id: '' });
        }

        that.setState({
          photoUpload: photos,
          xrayUpload: xrays,
          scanUpload: scans,
          showSaveModal: redirect && redirect === 'true',
        });
        case_id = that.state.id;
      }

      // Update browser route without refreshing
      // Check route before updating (are we stil on the submission page)
      const pathname =
        that && that.props && that.props.history && that.props.history.location && that.props.history.location.pathname
          ? that.props.history.location.pathname
          : '';
      if (pathname.indexOf('submission') >= 0) {
        that.appendRoute(case_id, routename);
      }
    });

    const doctorId = getDoctorsIdFromRoute();
    this.props.fetchLoggedDoctorCaseCount({ doctorId });

    if (options.refreshCaseList) {
      sendRefreshCaseListMessage();
    }
    if (options.refreshDashboard) {
      sendRefreshDashboardMessage();
    }
  };

  appendPlanToForm(formData) {
    if (this.state.default_plan_version === 'TX2.0') {
      formData.append('archToTreat', this.state.archToTreat);
      formData.append('chief_complaint', this.state.chief_complaint);
      formData.append('missingTeeth', this.state.missingTeeth);
      formData.append('extractTeeth', this.state.extractTeeth);
      formData.append('opposingArch', this.state.opposingArch);
      formData.append('bracketTeeth', this.state.bracketTeeth);
      formData.append('restrictTeeth', this.state.restrictTeeth);
      formData.append('conditions', JSON.stringify(this.state.conditions));
      formData.append('notes', this.state.notes);
      formData.append('notes_spacing', this.state.notes_spacing);
      formData.append('impliedTeethSets', JSON.stringify(this.getImpliedTeethSets()));
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TX3.0 append plan data to form
      formData.append('formVisited', this.state.formVisited);
      formData.append('archToTreat', this.state.archToTreat);
      formData.append('missingTeeth', this.state.missingTeeth);
      formData.append('extractTeeth', this.state.extractTeeth);
      formData.append('opposingArch', this.state.opposingArch);
      formData.append('bracketTeeth', removeOpposingTeeth(this.state.archToTreat, this.state.bracketTeeth));
      formData.append('restrictTeeth', this.state.restrictTeeth);
      formData.append('notes', this.state.notes);
      formData.append('impliedTeethSets', JSON.stringify(this.getImpliedTeethSets()));

      formData.append('anteriorPosteriorR', this.state.anteriorPosteriorR);
      formData.append('anteriorPosteriorL', this.state.anteriorPosteriorL);
      let anteriorPosteriorCorrections = this.state.anteriorPosteriorCorrections;
      if (this.state.anteriorPosteriorR === 'current' && this.state.anteriorPosteriorL === 'current') {
        anteriorPosteriorCorrections = {
          classIIOrIIICorrectionSimulation: false,
          posteriorIPR: false,
        };
      }
      formData.append('anteriorPosteriorCorrections', JSON.stringify(anteriorPosteriorCorrections));
      let anteriorPosteriorNotes = this.state.anteriorPosteriorNotes;
      if (this.state.anteriorPosteriorR !== 'other' && this.state.anteriorPosteriorL !== 'other') {
        anteriorPosteriorNotes = '';
      }
      formData.append('anteriorPosteriorNotes', anteriorPosteriorNotes);

      formData.append('midlines', this.state.midlines);

      formData.append('auxiliariesWillBeUsed', this.state.auxiliariesWillBeUsed);
      let auxiliariesNotes = this.state.auxiliariesNotes;
      if (this.state.auxiliariesWillBeUsed === 'No') {
        auxiliariesNotes = '';
      }
      formData.append('auxiliariesNotes', auxiliariesNotes);
    }

    return formData;
  }

  appendRoute = (case_id, routename = '') => {
    if (case_id) {
      if (routename !== '') {
        let pathname = this.props.history.location.pathname.match(/\/submission\/([A-Za-z]+)/);
        let newpath = '';
        if (pathname && pathname[1]) {
          newpath = this.props.history.location.pathname.replace(pathname[1], routename);
        }
        if (this.props.history.location.search.includes('doctor_id')) {
          this.props.history.replace(`${newpath}?case_id=${case_id}&doctor_id=${this.state.dsoDoctorId}`);
        } else {
          this.props.history.replace(`${newpath}?case_id=${case_id}`);
        }
        // this.props.history.replace(`${newpath}?case_id=${case_id}`);
      } else {
        // this.props.history.replace(`${this.props.history.location.pathname}?case_id=${case_id}`);
        if (this.props.history.location.search.includes('doctor_id')) {
          this.props.history.replace(`${this.props.history.location.pathname}?case_id=${case_id}&doctor_id=${this.state.dsoDoctorId}`);
        } else {
          this.props.history.replace(`${this.props.history.location.pathname}?case_id=${case_id}`);
        }
      }
    }
  };

  freshRoute = () => {
    this.onRedirectManageCases();
  };

  onRemoveDraft = (event) => {
    this.setState({ showCancelCase: true });
  };

  onModalDismiss = (event) => {
    this.setState(
      {
        showCancelCase: false,
      },
      modalClear()
    );
  };

  /**
   * Close unable to validate referral code modal
   *
   * @param {Object} event - button click event.
   * @function
   */
  onUnableToValidateReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showUnableToValidateReferralCode: false,
    });
  };
  onUnableToValidateReferralCodeDismiss = () => {
    this.setState({ showUnableToValidateReferralCode: false }, modalClear());
  };
  /**
   * Close invalid referral code modal
   *
   * @param {Object} event - button click event.
   * @function
   */
  onInvalidReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showInvalidReferralCode: false,
    });
  };
  onInvalidReferralCodeDismiss = () => {
    this.setState({ showInvalidReferralCode: false }, modalClear());
  };
  /**
   * Save referral code to draft
   *
   * @param {Object} event - button click event.
   * @function
   */
  onValidReferralCodeConfirm = (event) => {
    let stepper = this.state.stepper;
    const patientFirstName = this.state.patientFirstName ? this.state.patientFirstName : this.state.referralCodeData.firstName;
    const patientLastName = this.state.patientLastName ? this.state.patientLastName : this.state.referralCodeData.lastName;
    const patientDOB = this.state.patientDOB ? this.state.patientDOB : convertDateWithFormat(this.state.referralCodeData.dateOfBirth, 'YYYY-MM-DD');
    const patientSex = this.state.patientSex ? this.state.patientSex : this.translateSex(this.state.referralCodeData.personGender);

    if (
      patientFirstName !== '' &&
      patientLastName !== '' &&
      patientDOB !== '' &&
      patientSex !== '' &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }
    this.hide_warning();
    this.setState(
      {
        editPatientReferralCode: '',
        stepper: stepper,
        showValidReferralCode: false,
        patientFirstName: patientFirstName,
        patientLastName: patientLastName,
        patientDOB: patientDOB,
        patientSex: patientSex,
      },
      () => {
        this.onIncompleteSave('patient');
      }
    );
  };

  /**
   * Close redeemed referral code to draft
   *
   * @param {Object} event - button click event.
   * @function
   */
  onRedeemedReferralCodeConfirm = (event) => {
    this.setState({
      editPatientReferralCode: '',
      showRedeemedReferralCode: false,
    });
  };

  onRedeemedReferralCodeDismiss = () => {
    this.setState({ showRedeemedReferralCode: false }, modalClear());
  };

  /**
   * Close delete referral code from draft modal
   *
   * @param {Object} event - button click event.
   * @function
   */
  onDeleteReferralCodeDismiss = (event) => {
    this.setState({ showDeleteReferralCodeModal: false }, modalClear());
  };
  /**
   * Close valid referral code modal
   *
   * @param {Object} event - button click event.
   * @function
   */
  onValidReferralCodeDismiss = (event) => {
    const referral_code = this.state.patientReferralCode;
    this.setState(
      {
        showValidReferralCode: false,
        patientReferralCode: '',
        editPatientReferralCode: referral_code,
        referralCodeData: {},
      },
      modalClear()
    );
  };

  /**
   * Close empty file error modal
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({ showEmptyFileModal: false });
  };

  onModalDismissCaseRecommendation = (event) => {
    this.setState({ showCaseRecommendationModal: false }, modalClear());
  };

  onModalAccept = (event) => {
    let that = this;
    Axios.delete(`/api/incomplete/?id=${this.state.id}`).then(function (result) {
      that.setState({ showCancelCase: false });

      that.onRedirectManageCases();
    });

    // update navbar case count
    const doctorId = getDoctorsIdFromRoute();
    this.props.fetchLoggedDoctorCaseCount({ doctorId });

    sendRefreshCaseListMessage();
    sendRefreshDashboardMessage();
  };

  /**
   * Redirects the user back to the case list.
   *
   * If there are any filter that were applied before, it will redirect the page
   * with the filter.
   * @function
   */
  onRedirectManageCases = () => {
    let that = this;
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let caselist_filter = this.state.caselist_filter ? this.state.caselist_filter : '';
    caselist_filter = caselist_filter.replace(/\?search=\w+/, '');

    if (redirectPath) {
      this.props.history.push({
        pathname: redirectPath + caselist_filter,
        state: { refreshInfo: 'true' },
      });
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push({
            pathname: `/portal/${result.data.doctor[0].id}`,
            state: { refreshInfo: 'true' },
          });
        }
      });
    }
  };
  /**
   * Translates the value of sex into value that would match.
   *
   * @param {String} value - value that needs to be translated.
   * @function
   */
  translateSex = (value) => {
    value = value.toUpperCase();
    if (value && (value === 'M' || value.toLowerCase() === 'male')) {
      return 'M';
    } else if (value && (value === 'F' || value.toLowerCase() === 'female')) {
      return 'F';
    } else if (value && (value === 'N' || value.toLowerCase() === 'non-binary')) {
      return 'N';
    } else if (value && (value.toLowerCase() === 'unknown' || value.toLowerCase() === 'undisclosed')) {
      return 'U';
    } else {
      return '';
    }
  };
  /**
   * Dropdown prospect change event
   *
   * @param {Object} event - dropdown change event.
   * @function
   */
  onInputChange_prospect = (event) => {
    let stepper = this.state.stepper;
    const prospect_id = event ? event.value : '';
    let prospect = null;

    if (prospect_id !== '') {
      prospect = this.state.prospect_data.filter((prospect) => {
        return prospect.id === parseInt(prospect_id);
      })[0];
    }

    if (
      prospect_id &&
      prospect &&
      prospect.first_name &&
      prospect.last_name &&
      prospect.dob &&
      prospect.sex &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    } else {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }
    this.setState({
      patientFirstName: prospect && prospect.first_name ? prospect.first_name : this.state.patientFirstName,
      patientLastName: prospect && prospect.last_name ? prospect.last_name : this.state.patientLastName,
      patientDOB: prospect && prospect.dob ? convertDateWithFormat(prospect.dob, 'YYYY-MM-DD') : this.state.patientDOB,
      patientSex: prospect && this.translateSex(prospect.sex) ? this.translateSex(prospect.sex) : this.state.patientSex,
      selected_prospect_id: parseInt(prospect_id),
      stepper: stepper,
    });

    this.onFocus_clearWarning();
  };

  /**
   * Patient first name change event
   *
   * @param {Object} event - textbox change event.
   * @function
   */
  onInputChange_patientFirstName = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (
      text !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      !this.state.editPatientReferralCode &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (text === '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    this.setState({ patientFirstName: text, stepper: stepper }, this.updatePatientInfo);
  };

  /**
   * Patient referral code change event
   *
   * @param {Object} event - textbox change event.
   * @function
   */
  onInputChange_ReferralCode = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (
      !text &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (text !== '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    this.setState({ editPatientReferralCode: text, stepper: stepper }, this.updatePatientInfo);
  };

  /**
   * Patient last name change event
   *
   * @param {Object} event - textbox change event.
   * @function
   */
  onInputChange_patientLastName = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (
      text !== '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      !this.state.editPatientReferralCode &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (text === '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    this.setState({ patientLastName: text, stepper: stepper }, this.updatePatientInfo);
  };

  /**
   * Patient date of birth change event
   *
   * @param {Object} event - textbox change event.
   * @function
   */
  onInputChange_dob = (event) => {
    let stepper = this.state.stepper;

    if (
      event.target.value !== '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientSex !== '' &&
      !this.state.editPatientReferralCode &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (event.target.value === '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    this.setState({ patientDOB: event.target.value, stepper: stepper }, this.updatePatientInfo);
  };

  /**
   * Patient gender change event
   *
   * @param {Object} event - radio button change event.
   * @function
   */
  onInputChange_sex = (event) => {
    let stepper = this.state.stepper;

    if (
      event.target.value !== '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      !this.state.editPatientReferralCode &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (event.target.value === '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }

    this.setState({ patientSex: event.target.value, stepper: stepper }, this.updatePatientInfo);
  };

  /**
   * Patient reference change event
   *
   * @param {Object} event - textbox change event.
   * @function
   */
  onInputChange_patientRef = (event) => {
    let stepper = this.state.stepper;
    let text = patientChartTextConstraint(event.target.value);

    this.setState({ patientRef: text, stepper: stepper }, this.updatePatientInfo);
  };

  onInputChange_assignedDoctor = (event) => {
    let stepper = this.state.stepper;
    const doctor_id = event ? event.value : '';

    if (
      doctor_id !== '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 1;
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[6] = 2;
    }

    if (doctor_id === '') {
      stepper[this.getActivePositionByStepperName('Patient Information')] = 2;
    }
    this.setState({ assignedDoctor: doctor_id, stepper: stepper }, this.updatePatientInfo);
    this.onFocus_clearWarning();
  };

  urlContainsWord = (word) => {
    let has_word = false;

    if (this.props.history && this.props.history.location && this.props.history.location.pathname.indexOf(word) >= 0) {
      has_word = true;
    }

    return has_word;
  };

  /**
   * iTero integration
   *
   * Fetch iTero photos
   * @param {String} iteroId - itero id
   * @param {Object} ctx - context
   * @function
   */
  fetchIteroPhotos = (iteroId) => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.props.fetchIteroPhotos(iteroId, {
      onSuccess: (iteroPhotos) => {
        const iteroPhotoIds = iteroPhotos.map((file) => file.id);
        this.props.addIteroPhotosToIncompleteCase(
          {
            incompleteCaseId: `${this.state.id}`,
            iteroFileIds: iteroPhotoIds,
            iteroId: iteroId,
          },
          {
            onSuccess: (data) => {
              const { success } = data;
              const uploadedIteroPhotos = Object.values(success);
              this.setState((prevState) => {
                let newStepper = [...prevState.stepper];
                const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);

                const newPhotoUploadData = [...filteredPrevStatePhotoUpload, ...uploadedIteroPhotos];

                newStepper = this.updatePhotoStepperState(newPhotoUploadData, prevState.stepper);

                if (this.isSubmitAvailable({ newPhotoUploadData, uploadType: 'photos' }) && newStepper[this.getActivePositionByStepperName('Submit')] === 0) {
                  newStepper = [1, 1, 1, 1, 1, 1, 2];
                }

                return {
                  [photoUploadStateKey]: newPhotoUploadData,
                  selectedIteroPhotoRecords: uploadedIteroPhotos,
                  stepper: newStepper,
                };
              });
            },
          }
        );
      },
    });
  };

  /**
   * iTero integration
   *
   * Removes all Itero photos from the state and updates the selected Itero photo.
   * @param {string} oldIteroId - The ID of the old Itero photo.
   * @param {string} newSelectedIteroId - The ID of the new selected Itero photo.
   * @returns {void}
   */
  updateIteroPhotos = (oldIteroId, newSelectedIteroId) => {
    this.removeAllIteroPhotosFromState();
    const that = this;
    this.props.deleteIteroPhotosFromIncompleteCase(
      {
        incompleteCaseId: this.state.id,
        iteroId: oldIteroId,
      },
      {
        onSuccess: () => {
          if (that.state.shouldFetchIteroPhotos) {
            that.fetchIteroPhotos(newSelectedIteroId);
          }
        },
      }
    );
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    this.setState({ warning: false });

    if (data && data[0].folder) {
      let uploadData = [];
      let stepper = this.state.stepper;

      for (let index = 0; index < data.length; index++) {
        if (data[index].folder === 'scans') {
          const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
          data[index]['file_url'] = file_url;
        }
      }

      if (data[0].folder === 'photos') {
        uploadData = this.state.photoUpload;
        uploadData = uploadData.concat(data);

        stepper = this.updatePhotoStepperState(uploadData, stepper);

        if (this.isSubmitAvailable({ uploadData, uploadType: 'photos' }) && stepper[this.getActivePositionByStepperName('Submit')] === 0) {
          stepper = [1, 1, 1, 1, 1, 1, 2];
        }

        this.setState({
          photoUpload: uploadData,
          stepper: stepper,
          photos_uploading: [],
        });
      } else if (data[0].folder === 'xrays') {
        uploadData = this.state.xrayUpload;
        uploadData = uploadData.concat(data);

        if (uploadData.length >= 1) {
          stepper[this.getActivePositionByStepperName('X-Rays')] = 1;
        }

        if (this.isSubmitAvailable({ uploadData, uploadType: 'xrays' }) && stepper[this.getActivePositionByStepperName('Submit')] === 0) {
          stepper = [1, 1, 1, 1, 1, 1, 2];
        }

        this.setState({
          xrayUpload: uploadData,
          stepper: stepper,
          radiographs_uploading: [],
        });
      } else if (data[0].folder === 'upperscans' || data[0].folder === 'lowerscans' || data[0].folder === 'scans') {
        uploadData = this.state.scanUpload;
        uploadData = uploadData.concat(data);

        if (uploadData.length === 2) {
          stepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }

        if (this.isSubmitAvailable({ uploadData, uploadType: 'scans' }) && stepper[this.getActivePositionByStepperName('Submit')] === 0) {
          stepper = [1, 1, 1, 1, 1, 1, 2];
        }

        this.showMoreTwoScansWarning(uploadData);

        this.setState({
          scanUpload: uploadData,
          stepper: stepper,
          scans_uploading: [],
        });
      }
    }
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();

    const that = this;
    const file_id = event.currentTarget.dataset.file_id;
    let id = this.state.id;
    let href = event.currentTarget.href;
    let removeType = event.currentTarget.dataset.type;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');

    if (startingPath >= 0 && endPath >= 0) {
      let newRef = decodeURI(href.substring(startingPath, href.length));

      Axios({
        url: `/api/remove/?id=${id}&file=${newRef}`,
        method: 'POST',
      }).then((response) => {
        //Update state that removed incomplete file
        //Photos
        //
        let incomplete_case_file_id = parseInt(response.data.data.incomplete_case_file_id || file_id);
        if (response.data.data) {
          let uploadData = that.state.photoUpload;
          let newPhotoUpload = [];
          let newXrayUpload = [];
          let newScanUpload = [];

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].incomplete_case_file_id !== incomplete_case_file_id && newRef.indexOf(uploadData[i].upload_data) < 0) {
              newPhotoUpload.push(uploadData[i]);
            }
          }

          uploadData = that.state.xrayUpload;

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].incomplete_case_file_id !== incomplete_case_file_id && newRef.indexOf(uploadData[i].upload_data) < 0) {
              newXrayUpload.push(uploadData[i]);
            }
          }

          uploadData = that.state.scanUpload;

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].incomplete_case_file_id !== incomplete_case_file_id && newRef.indexOf(uploadData[i].upload_data) < 0) {
              newScanUpload.push(uploadData[i]);
            }
          }

          let stepper = this.state.stepper;

          if (removeType === 'photos') {
            stepper = this.updatePhotoStepperState(newPhotoUpload, stepper);
            if (!this.isPhotoUploadLengthValid(newPhotoUpload)) {
              stepper[6] = 2;
            }
            that.setState({
              photoUpload: newPhotoUpload,
              stepper: stepper,
            });
          } else if (removeType === 'xrays') {
            if (newXrayUpload.length === 0) {
              stepper[this.getActivePositionByStepperName('X-Rays')] = 2;
              stepper[6] = 2;
            }

            that.setState({
              xrayUpload: newXrayUpload,
              stepper: stepper,
            });
          } else if (removeType === 'upperscans' || removeType === 'lowerscans' || removeType === 'scans') {
            if (newScanUpload.length !== 2) {
              stepper[this.getActivePositionByStepperName('Impressions')] = 2;
              stepper[6] = 2;
            } else if (newScanUpload.length === 2) {
              stepper[this.getActivePositionByStepperName('Impressions')] = 1;
            }

            if (this.state.active === this.getActivePositionByStepperName('Impressions')) {
              if (newScanUpload.length === 2) {
                this.onFocus_clearWarning();
              }
            }

            that.setState({
              scanUpload: newScanUpload,
              stepper: stepper,
            });
          }
        }
      });
    }
  };

  /* Form checking */
  hasAddressField = () => {
    if (!this.state.address || this.state.address === '') {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul><li>Missing Address</li></ul>';

        this.setState({
          warning: true,
        });
      }
      return false;
    }

    return true;
  };

  /**
   * Check if submission has valid attribution response.
   *
   * @param {boolean} displayError - should display error message or not
   * @function
   */
  hasAttributionResponse = (displayError) => {
    const attributions = this.checkAttributionError(displayError);
    if (attributions && attributions.length !== 0) {
      for (var i = 0; i < attributions.length; i++) {
        if (attributions[i].attributions_error || attributions[i].attribution_other_error) {
          return false;
        }
      }
    }
    return true;
  };

  hasPatientField = () => {
    var patientDOB = Date.parse(this.state.patientDOB);
    var dateMin = Date.parse('1900-01-01');
    var dateNow = Date.now();
    var missingAttributes = !this.hasAttributionResponse(true);

    if (
      this.state.editPatientReferralCode !== '' ||
      this.state.patientFirstName === '' ||
      this.state.patientLastName === '' ||
      this.state.patientDOB === '' ||
      patientDOB < dateMin ||
      patientDOB > dateNow ||
      this.state.patientSex === '' ||
      missingAttributes ||
      (isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
    ) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');
      warning.innerHTML = '';

      if (this.state.editPatientReferralCode !== '' && this.state.referral) {
        warning.innerHTML += '<ul><li><a href="#patient_info">Validate Referral Code</a></li></ul>';
      }

      if (patientDOB < dateMin || patientDOB > dateNow) {
        warning.innerHTML += '<ul><li><a href="#patient_info">Invalid Date</a></li></ul>';
      }
      if (
        this.state.patientFirstName === '' ||
        this.state.patientLastName === '' ||
        this.state.patientSex === '' ||
        this.state.patientDOB === '' ||
        (isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
      ) {
        warning.innerHTML += '<ul><li><a href="#patient_info">Patient Information</a></li></ul>';
      }
      if (missingAttributes) {
        warning.innerHTML += '<ul><li><a href="#patient_info">Patient Survey</a></li></ul>';
      }

      //Added in border color highlight
      if (this.state.patientFirstName === '') {
        document.querySelector('#patientFirstName').classList.add('warning-border');
        document.querySelector('#patientFirstNameLabel').classList.add('warning-text');
      }

      //Added in border color highlight
      if (this.state.editPatientReferralCode) {
        document.querySelector('#referralCode').classList.add('warning-border');
        document.querySelector('#referralCodeLabel').classList.add('warning-text');
      }

      if (this.state.patientLastName === '') {
        document.querySelector('#patientLastName').classList.add('warning-border');
        document.querySelector('#patientLastNameLabel').classList.add('warning-text');
      }

      if (this.state.patientDOB === '') {
        document.querySelector('#patientDOB').classList.add('warning-border');
        document.querySelector('#patientDOBLabel').classList.add('warning-text');
      }

      if (patientDOB < dateMin || patientDOB > dateNow) {
        document.querySelector('#patientDOB').classList.add('warning-border');
        document.querySelector('#patientDOBLabel').classList.add('warning-text');
      }

      if (this.state.patientSex === '') {
        document.querySelector('#patientSex').classList.add('warning-text');
      }
      if (isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '') {
        applyCSSToID('#assignedDoctor', 'warning-border');
        applyCSSToID('#assignedDoctorLabel', 'warning-text');
      }

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  hasOnePhoto = () => {
    if (this.getPhotoUploadLength() === 0 && this.state.photos_uploading.length === 0) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = `<ul><li>${this.getPhotoSectionErrorMessage()}</li></ul>`;
      }

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  hasOneXray = () => {
    if (this.state.xrayUpload.length === 0 && this.state.radiographs_uploading.length === 0) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul><li>Radiographs</li></ul>';

        this.setState({
          warning: true,
        });
      }

      return false;
    }

    return true;
  };

  showMoreTwoScansWarning(scans) {
    if (scans.length > 2) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul><li>More than 2 STL Files</li></ul>';

        this.setState({
          warning: true,
        });
      }
    }
  }

  /**
   * iTero integration
   *
   * Validates that the user has selected two scans
   * @function
   * @returns {boolean} - True or false
   */
  hasTwoScans = () => {
    const _hasTwoScans = hasTwoScansHelper({
      uploadMethod: this.state.uploadMethod,
      scanUpload: this.state.scanUpload,
      scans_uploading: this.state.scans_uploading,
      selectedIteroScans: this.state.selectedIteroScans,
    });

    if (!_hasTwoScans) {
      this.setState({
        warning: true,
      });
    }

    return _hasTwoScans;
  };

  /**
   * Determines if anterior-posterior field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorCorrections } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.anteriorPosteriorL;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.anteriorPosteriorR;
    const currentAnteriorPosteriorCorrections = anteriorPosteriorCorrections || this.state.anteriorPosteriorCorrections;

    const correctionIsSelected = [currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('correction');

    if (correctionIsSelected) {
      return Object.values(currentAnteriorPosteriorCorrections).some((value) => value);
    }

    return true;
  };

  /**
   * Determines if anterior-posterior notes field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorNotesFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorNotes } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.anteriorPosteriorL;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.anteriorPosteriorR;
    const currentAnteriorPosteriorNotes =
      anteriorPosteriorNotes !== undefined && anteriorPosteriorNotes !== null ? anteriorPosteriorNotes : this.state.anteriorPosteriorNotes;

    if ([currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('other')) {
      return currentAnteriorPosteriorNotes !== '';
    }

    return true;
  };

  /**
   * Checks if bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns {boolean} - True or false
   */
  isBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat } = {}) => {
    const currentBracketTeeth = bracketTeeth || this.state.bracketTeeth;
    const currentMissingTeeth = missingTeeth || this.state.missingTeeth;
    const currentExtractTeeth = extractTeeth || this.state.extractTeeth;
    const currentArchToTreat = archToTreat || this.state.archToTreat;

    return isBracketTeethValidHelper(
      {
        bracketTeeth: currentBracketTeeth,
        missingTeeth: currentMissingTeeth,
        extractTeeth: currentExtractTeeth,
        archToTreat: currentArchToTreat,
      },
      Teeth
    );
  };

  /**
   * Determines if auxiliaries field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAuxiliariesFieldValid = ({ auxiliariesWillBeUsed, auxiliariesNotes } = {}) => {
    const currentAuxiliariesWillBeUsed = auxiliariesWillBeUsed || this.state.auxiliariesWillBeUsed;
    const currentAuxiliariesNotes = auxiliariesNotes !== undefined && auxiliariesNotes !== null ? auxiliariesNotes : this.state.auxiliariesNotes;

    if (currentAuxiliariesWillBeUsed === 'Yes') {
      return currentAuxiliariesNotes !== '';
    }

    return true;
  };

  hasOneClinicalCondition = () => {
    return (
      this.state.conditions.ap_relationship.active ||
      this.state.conditions.midlines.active ||
      this.state.conditions.crowding.active ||
      this.state.conditions.spacing.active ||
      this.state.conditions.overjet.active ||
      this.state.conditions.deepbite.active ||
      this.state.conditions.openbite.active ||
      this.state.conditions.crossbite.active
    );
  };

  crowdingHasOneValidSelection = () => {
    let that = this;
    const fields = [
      'lower_expand',
      'lower_ipr_ant',
      'lower_ipr_left',
      'lower_ipr_right',
      'lower_procline',
      'upper_expand',
      'upper_ipr_ant',
      'upper_ipr_left',
      'upper_ipr_right',
      'upper_procline',
    ];

    const result = fields.filter((field) => that.state.conditions.crowding[field] !== 'none');

    return result.length > 0;
  };

  hasSpacingValue = (conditions = this.state.conditions) => {
    const spacing = conditions.spacing.spacingUpper.concat(conditions.spacing.spacingLower);
    const result = spacing.filter((space_value) => space_value !== '');
    return result.length > 0 || conditions.spacing.spaces === 'close';
  };

  hasClinicalConditionRequireFieldMet = (
    conditions = this.state.conditions,
    notes_spacing = this.state.notes_spacing,
    arch_to_treat = this.state.archToTreat,
    opposingArch = this.state.opposingArch
  ) => {
    let hasMet = true;

    if (conditions.ap_relationship.active && conditions.ap_relationship.ap_improvement === 'auxiliaries' && conditions.ap_relationship.aux_note === '') {
      hasMet = false;
    } else if (conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
      hasMet = false;
    } else if (conditions.spacing.active && !this.hasSpacingValue(conditions) && notes_spacing === '') {
      hasMet = false;
    } else if (conditions.overjet.active && conditions.overjet.improve === 'auxiliaries' && conditions.overjet.aux_note === '') {
      hasMet = false;
    } else if (conditions.deepbite.active && conditions.deepbite.correct === 'auxiliaries' && conditions.deepbite.aux_note === '') {
      hasMet = false;
    } else if (
      (conditions.crossbite.active &&
        (arch_to_treat === 'both' || (arch_to_treat === 'upper' && opposingArch === 'ideal') || (arch_to_treat === 'lower' && opposingArch === 'ideal')) &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_lower &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'upper' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'lower' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_narrow_lower)
    ) {
      hasMet = false;
    }

    return hasMet;
  };

  /**
   * Returns whether all required fields are filled out and displays warning messages
   * @function
   * @returns {boolean} - True or false
   */
  hasTxPlanField = () => {
    if (
      this.state.default_plan_version === 'TX2.0' &&
      (this.state.chief_complaint === '' ||
        this.state.archToTreat === '' ||
        (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) ||
        !this.hasOneClinicalCondition() ||
        !this.hasClinicalConditionRequireFieldMet())
    ) {
      let warning = document.querySelector('#warning-submit');
      let warnings = '';

      warning.classList.add('warning-display');

      if (this.state.chief_complaint === '') {
        document.querySelector('#chief_complaint').classList.add('warning-border');
        document.querySelector('#chief_complaint_title').classList.add('warning-text');
        warnings = '<li><a href="#chief_complaint_title">Patient Chief Complaint</a></li>';
      }

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.hasOneClinicalCondition()) {
        document.querySelector('#clinical_condition').classList.add('warning-text');
        warnings = warnings + '<li><a href="#clinical_condition">Missing Clinical Condition</a></li>';
      }

      if (
        this.state.conditions.ap_relationship.active &&
        this.state.conditions.ap_relationship.ap_improvement === 'auxiliaries' &&
        this.state.conditions.ap_relationship.aux_note === ''
      ) {
        document.querySelector('#ap_relationship_title').classList.add('warning-text');
        document.querySelector('#ap_relationship_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#ap_relationship_title">AP Relationship</a></li>';
      }

      if (this.state.conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
        document.querySelector('#crowding_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crowding_title">Crowding</a></li>';
      }

      if (this.state.conditions.spacing.active && !this.hasSpacingValue() && this.state.notes_spacing === '') {
        document.querySelector('#spacing_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#spacing_title">Spacing</a></li>';
      }

      if (this.state.conditions.overjet.active && this.state.conditions.overjet.improve === 'auxiliaries' && this.state.conditions.overjet.aux_note === '') {
        document.querySelector('#overjet_title').classList.add('warning-text');
        document.querySelector('#overjet_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#overjet_title">Overjet</a></li>';
      }

      if (this.state.conditions.deepbite.active && this.state.conditions.deepbite.correct === 'auxiliaries' && this.state.conditions.deepbite.aux_note === '') {
        document.querySelector('#deep_bite_title').classList.add('warning-text');
        document.querySelector('#deep_bite_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#deep_bite_title">Deep Bite</a></li>';
      }

      if (
        (this.state.conditions.crossbite.active &&
          (this.state.archToTreat === 'both' ||
            (this.state.archToTreat === 'upper' && this.state.opposingArch === 'ideal') ||
            (this.state.archToTreat === 'lower' && this.state.opposingArch === 'ideal')) &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_lower &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'upper' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'lower' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_narrow_lower)
      ) {
        document.querySelector('#crossbite_transverse_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crossbite_transverse_title">Crossbite/Transverse</a></li>';
      }

      warning.innerHTML = `<ul>${warnings}</ul>`;

      this.setState({
        warning: true,
      });

      return false;
    } else if (this.state.default_plan_version === 'TX3.0' && !this.metAllPlanRequiredField()) {
      // TX3.0 - Add validation errors for TX3.0 fields
      let warning = document.querySelector('#warning-submit');
      let warnings = '';

      warning.classList.add('warning-display');

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (!this.isBracketTeethValid()) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
        document.querySelector('#anterior_posterior').classList.add('warning-text');
        if (!this.isAnteriorPosteriorFieldValid()) {
          handleAnteriorPosteriorCheckboxErrors(true);
        }
        if (!this.isAnteriorPosteriorNotesFieldValid()) {
          document.querySelector('#anteriorPosteriorNotes').classList.add('warning-border');
        }
        warnings = warnings + '<li><a href="#anterior_posterior">Anterior-Posterior</a></li>';
      }

      if (!this.state.midlines) {
        document.querySelector('#tx_midlines').classList.add('warning-text');
        warnings = warnings + '<li><a href="#tx_midlines">Midlines</a></li>';
      }

      if (!this.isAuxiliariesFieldValid()) {
        document.querySelector('#tx_auxiliaries').classList.add('warning-text');

        document.querySelector('#auxiliariesNotes').classList.add('warning-border');

        warnings = warnings + '<li><a href="#tx_auxiliaries">Auxiliaries</a></li>';
      }

      warning.innerHTML = `<ul>${warnings}</ul>`;

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  isSubmitAvailable = ({ uploadData, uploadType } = {}) => {
    let isFilesLengthValid = false;
    if (uploadData && uploadType) {
      if (uploadType === 'photos') {
        isFilesLengthValid = this.isPhotoUploadLengthValid(uploadData) && this.state.xrayUpload.length >= 1 && this.isScansLengthValid();
      } else if (uploadType === 'xrays') {
        isFilesLengthValid = this.isPhotoUploadLengthValid() && uploadData.length >= 1 && this.isScansLengthValid();
      } else if (uploadType === 'scans') {
        isFilesLengthValid = this.isPhotoUploadLengthValid() && this.state.xrayUpload.length >= 1 && uploadData.length == 2;
      }
    } else {
      isFilesLengthValid = this.isPhotoUploadLengthValid() && this.state.xrayUpload.length >= 1 && this.isScansLengthValid();
    }
    if (
      this.state.address &&
      this.state.address !== '' &&
      this.state.editPatientReferralCode === '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '') &&
      this.state.chief_complaint !== '' &&
      this.state.archToTreat !== '' &&
      this.state.bracketTeeth &&
      this.state.bracketTeeth.length > 0 &&
      this.hasOneClinicalCondition() &&
      this.hasClinicalConditionRequireFieldMet() &&
      isFilesLengthValid &&
      this.state.default_plan_version === 'TX2.0'
    ) {
      return true;
    } else if (
      // TX3.0 - Add submit availability for TX3.0
      this.state.address &&
      this.state.address !== '' &&
      this.state.editPatientReferralCode === '' &&
      this.state.patientFirstName !== '' &&
      this.state.patientLastName !== '' &&
      this.state.patientDOB !== '' &&
      this.state.patientSex !== '' &&
      this.hasAttributionResponse() &&
      !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '') &&
      isFilesLengthValid &&
      this.state.default_plan_version === 'TX3.0' &&
      this.metAllPlanRequiredField()
    ) {
      return true;
    }

    return false;
  };

  isStepperDone = (stepper) => {
    let result = true;
    for (let i = 0; i < stepper.length - 1; i++) {
      if (stepper[i] === 0 || stepper[i] === 2) {
        result = false;
        break;
      }
    }

    return result;
  };

  getPosition() {
    const position = [
      { name: 'Patient Information' },
      { name: 'Impressions' },
      { name: 'Photos' },
      { name: 'X-Rays' },
      { name: 'Dx/Tx Plan' },
      { name: 'Doctor Information' },
      { name: 'Submit' },
    ];

    return position;
  }

  getActivePositionByStepperName(stepper_name) {
    const position = this.getPosition();
    let active_index = 0;

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        active_index = i;
        break;
      }
    }

    return active_index;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  /**
   * Sets the state as the new state
   * @function
   * @param {object} ns - The new state
   */
  setPlanState = (ns) => {
    let metAllPlanRequiredField = false;

    // TxPlan 2.0 State
    if (this.state.default_plan_version === 'TX2.0') {
      const chief_complaint = ns['chief_complaint'] !== undefined ? textFieldLimited(ns['chief_complaint']) : this.state.chief_complaint;
      const missingTeeth = ns['missingTeeth'] !== undefined ? ns['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = ns['extractTeeth'] !== undefined ? ns['extractTeeth'] : this.state.extractTeeth;
      const archToTreat = ns['archToTreat'] !== undefined ? ns['archToTreat'] : this.state.archToTreat;
      const opposingArch = ns['opposingArch'] !== undefined ? ns['opposingArch'] : this.state.opposingArch;
      const bracketTeeth = ns['bracketTeeth'] !== undefined ? ns['bracketTeeth'] : this.state.bracketTeeth;
      const restrictTeeth = ns['restrictTeeth'] !== undefined ? ns['restrictTeeth'] : this.state.restrictTeeth;
      const notes = ns['notes'] !== undefined ? textFieldLimited(ns['notes']) : this.state.notes;
      const notes_spacing = ns['notes_spacing'] !== undefined ? textFieldLimited(ns['notes_spacing']) : this.state.notes_spacing;

      this.setState({
        chief_complaint: chief_complaint,
        archToTreat: archToTreat,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
        opposingArch: opposingArch,
        bracketTeeth: bracketTeeth,
        restrictTeeth: restrictTeeth,
        notes: notes,
        notes_spacing: notes_spacing,
      });

      metAllPlanRequiredField = this.metAllPlanRequiredField({
        archToTreat: archToTreat,
        notes_spacing: notes_spacing,
        chief_complaint: chief_complaint,
        opposingArch: opposingArch,
      });
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TX3.0 - set state for TX3.0
      const formVisited = ns['formVisited'] !== undefined ? ns['formVisited'] : this.state.formVisited;
      const missingTeeth = ns['missingTeeth'] !== undefined ? ns['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = ns['extractTeeth'] !== undefined ? ns['extractTeeth'] : this.state.extractTeeth;
      const archToTreat = ns['archToTreat'] !== undefined ? ns['archToTreat'] : this.state.archToTreat;
      const opposingUpperArch = ns['opposingUpperArch'] !== undefined ? ns['opposingUpperArch'] : this.state.opposingUpperArch;
      const opposingLowerArch = ns['opposingLowerArch'] !== undefined ? ns['opposingLowerArch'] : this.state.opposingLowerArch;
      const bracketTeeth = ns['bracketTeeth'] !== undefined ? ns['bracketTeeth'] : this.state.bracketTeeth;
      const restrictTeeth = ns['restrictTeeth'] !== undefined ? ns['restrictTeeth'] : this.state.restrictTeeth;
      const anteriorPosteriorR = ns['anteriorPosteriorR'] !== undefined ? ns['anteriorPosteriorR'] : this.state.anteriorPosteriorR;
      const anteriorPosteriorL = ns['anteriorPosteriorL'] !== undefined ? ns['anteriorPosteriorL'] : this.state.anteriorPosteriorL;
      const anteriorPosteriorNotes =
        ns['anteriorPosteriorNotes'] !== undefined ? removeEmoji(textFieldLimited(ns['anteriorPosteriorNotes'])) : this.state.anteriorPosteriorNotes;
      const midlines = ns['midlines'] !== undefined ? ns['midlines'] : this.state.midlines;
      const auxiliariesWillBeUsed = ns['auxiliariesWillBeUsed'] !== undefined ? ns['auxiliariesWillBeUsed'] : this.state.auxiliariesWillBeUsed;
      const auxiliariesNotes = ns['auxiliariesNotes'] !== undefined ? removeEmoji(textFieldLimited(ns['auxiliariesNotes'])) : this.state.auxiliariesNotes;
      const notes = ns['notes'] !== undefined ? removeEmoji(textFieldLimited(ns['notes'])) : this.state.notes;

      let anteriorPosteriorCorrections = _.cloneDeep(this.state.anteriorPosteriorCorrections);

      if (ns['anteriorPosteriorCorrections'] !== undefined) {
        anteriorPosteriorCorrections = {
          ...anteriorPosteriorCorrections,
          ...ns['anteriorPosteriorCorrections'],
        };
      }

      let opposingArch;

      if (archToTreat === 'upper') {
        opposingArch = opposingUpperArch;
      } else if (archToTreat === 'lower') {
        opposingArch = opposingLowerArch;
      } else {
        opposingArch = this.state.opposingArch;
      }

      this.setState({
        formVisited: formVisited,
        archToTreat: archToTreat,
        bracketTeeth: bracketTeeth,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
        opposingArch: opposingArch,
        opposingUpperArch: opposingUpperArch,
        opposingLowerArch: opposingLowerArch,
        restrictTeeth: restrictTeeth,
        anteriorPosteriorR: anteriorPosteriorR,
        anteriorPosteriorL: anteriorPosteriorL,
        anteriorPosteriorCorrections: anteriorPosteriorCorrections,
        anteriorPosteriorNotes: anteriorPosteriorNotes,
        midlines: midlines,
        auxiliariesWillBeUsed: auxiliariesWillBeUsed,
        auxiliariesNotes: auxiliariesNotes,
        notes: notes,
      });

      metAllPlanRequiredField = this.metAllPlanRequiredField({
        formVisited: formVisited,
        archToTreat: archToTreat,
        opposingArch: opposingArch,
        anteriorPosteriorR: anteriorPosteriorR,
        anteriorPosteriorL: anteriorPosteriorL,
        anteriorPosteriorCorrections: anteriorPosteriorCorrections,
        anteriorPosteriorNotes: anteriorPosteriorNotes,
        midlines: midlines,
        auxiliariesWillBeUsed: auxiliariesWillBeUsed,
        auxiliariesNotes: auxiliariesNotes,
        bracketTeeth: bracketTeeth,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
      });
    }

    if (metAllPlanRequiredField) {
      this.markPlanStepAsComplete();
    } else {
      this.markPlanStepAsIncomplete();
    }

    this.onFocus_clearWarning();
  };

  getConditionState = (cnd) => {
    return { ...this.state.conditions[cnd] };
  };

  setPlanConditionState = (cnd, newState) => {
    let conds = { ...this.state.conditions };
    let oldState = this.getConditionState(cnd);

    Object.assign(oldState, newState);

    conds[cnd] = oldState;

    // TxPlan 2.0 Condition State
    this.setConditionState(conds);
    const metAllPlanRequiredField = this.metAllPlanRequiredField({ conditions: conds });

    if (metAllPlanRequiredField) {
      this.markPlanStepAsComplete();
    } else {
      this.markPlanStepAsIncomplete();
    }
  };

  setConditionState = (conds) => {
    this.setState({ conditions: conds });
    this.onFocus_clearWarning();
  };

  getImpliedTeethSets = () => {
    let opposingTeeth = [];
    let hasUpper = this.state.archToTreat !== 'lower';
    let hasLower = this.state.archToTreat !== 'upper';
    let setupIdeal = this.state.archToTreat !== 'both' && this.state.opposingArch === 'ideal';
    let setupCurrent = this.state.archToTreat !== 'both' && this.state.opposingArch === 'current';
    let setupDigital = this.state.archToTreat !== 'both' && this.state.opposingArch === 'digital';

    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    let missingTeeth = this.state.missingTeeth;
    let extractTeeth = _.difference(this.state.extractTeeth, missingTeeth);
    let extractDisabled = missingTeeth;
    let extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal || setupDigital) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    let bracketTeeth = _.difference(this.state.bracketTeeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.restrictTeeth, restrictImplied);
    if (setupCurrent || setupDigital) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    let tset = {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };

    return tset;
  };

  handleTeethClick = (teethSet) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);

      this.setPlanState(ns);

      if (teethSet === 'bracketTeeth') {
        this.metAllPlanRequiredField({ bracketTeeth: ns[teethSet] });
      }

      this.onFocus_clearWarning();
    };
    return func;
  };

  /**
   * Returns whether all required fields are met
   * @function
   * @param {Object} [values={}] - The values for treatment plan
   * @returns {Boolean} Whether met all required fields
   */
  metAllPlanRequiredField = (values = {}) => {
    let hasMet = false;

    if (this.state.default_plan_version === 'TX2.0') {
      const chief_complaint = values['chief_complaint'] !== undefined ? values['chief_complaint'] : this.state.chief_complaint;
      const notes_spacing = values['notes_spacing'] !== undefined ? values['notes_spacing'] : this.state.notes_spacing;
      const arch_to_treat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
      const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
      const conditions = values['conditions'] ? values['conditions'] : this.state.conditions;
      const opposingArch = values['opposingArch'] ? values['opposingArch'] : this.state.opposingArch;

      hasMet =
        chief_complaint &&
        arch_to_treat &&
        bracketTeeth &&
        bracketTeeth.length > 0 &&
        (conditions.ap_relationship.active ||
          conditions.midlines.active ||
          conditions.crowding.active ||
          conditions.spacing.active ||
          conditions.overjet.active ||
          conditions.deepbite.active ||
          conditions.openbite.active ||
          conditions.crossbite.active) &&
        this.hasClinicalConditionRequireFieldMet(conditions, notes_spacing, arch_to_treat, opposingArch) &&
        (!conditions.spacing.active ||
          (conditions.spacing.active &&
            (notes_spacing !== '' || conditions.spacing.spacingUpper.join().length > 0 || conditions.spacing.spacingLower.join().length > 0)) ||
          (conditions.spacing.active && conditions.spacing.spaces === 'close'));
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TX3.0 Met Required Fields
      const formVisited = values['formVisited'] !== undefined ? values['formVisited'] : this.state.formVisited;
      const archToTreat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
      const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
      const missingTeeth = values['missingTeeth'] !== undefined ? values['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = values['extractTeeth'] !== undefined ? values['extractTeeth'] : this.state.extractTeeth;
      const anteriorPosteriorR = values['anteriorPosteriorR'] !== undefined ? values['anteriorPosteriorR'] : this.state.anteriorPosteriorR;
      const anteriorPosteriorL = values['anteriorPosteriorL'] !== undefined ? values['anteriorPosteriorL'] : this.state.anteriorPosteriorL;
      const anteriorPosteriorCorrections =
        values['anteriorPosteriorCorrections'] !== undefined ? values['anteriorPosteriorCorrections'] : this.state.anteriorPosteriorCorrections;
      const anteriorPosteriorNotes = values['anteriorPosteriorNotes'] !== undefined ? values['anteriorPosteriorNotes'] : this.state.anteriorPosteriorNotes;
      const midlines = values['midlines'] !== undefined ? values['midlines'] : this.state.midlines;
      const auxiliariesWillBeUsed = values['auxiliariesWillBeUsed'] !== undefined ? values['auxiliariesWillBeUsed'] : this.state.auxiliariesWillBeUsed;
      const auxiliariesNotes = values['auxiliariesNotes'] !== undefined ? values['auxiliariesNotes'] : this.state.auxiliariesNotes;

      hasMet =
        formVisited &&
        archToTreat &&
        midlines &&
        this.isBracketTeethValid({ bracketTeeth, missingTeeth, extractTeeth, archToTreat }) &&
        this.isAnteriorPosteriorFieldValid({ anteriorPosteriorR, anteriorPosteriorL, anteriorPosteriorCorrections }) &&
        this.isAnteriorPosteriorNotesFieldValid({ anteriorPosteriorR, anteriorPosteriorL, anteriorPosteriorNotes }) &&
        this.isAuxiliariesFieldValid({ auxiliariesWillBeUsed, auxiliariesNotes });
    }

    return hasMet;
  };

  markPlanStepAsComplete = () => {
    const active = this.getActivePositionByStepperName('Dx/Tx Plan');
    let stepper = this.state.stepper;

    stepper[active] = 1;

    this.setState({ stepper: stepper });
  };

  markPlanStepAsIncomplete = () => {
    const active = this.getActivePositionByStepperName('Dx/Tx Plan');
    let stepper = this.state.stepper;

    stepper[active] = 2;

    this.setState({ stepper: stepper });
  };

  getPlanByVersion = (disabled) => {
    if (this.state.default_plan_version === 'TX2.0') {
      return (
        <TxPlan
          setPlanState={this.setPlanState}
          setConditionState={this.setConditionState}
          setPlanConditionState={this.setPlanConditionState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          chief_complaint={this.state.chief_complaint}
          missingTeeth={this.state.missingTeeth}
          extractTeeth={this.state.extractTeeth}
          archToTreat={this.state.archToTreat}
          opposingArch={this.state.opposingArch}
          bracketTeeth={this.state.bracketTeeth}
          restrictTeeth={this.state.restrictTeeth}
          conditions={this.state.conditions}
          notes={this.state.notes}
          notes_spacing={this.state.notes_spacing}
          warning={this.state.warning}
          onFocus={this.onFocus_clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          disabledEdit={disabled}
          photoUpload={this.state.photoUpload}
          xrayUpload={this.state.xrayUpload}
          scanUpload={this.state.scanUpload}
          updateRecordState={this.updateRecordState}
          // iTero integration - props for TxPlan
          selectedIteroScanRecords={this.state.selectedIteroScanRecords}
          uploadMethod={this.state.uploadMethod}
        />
      );
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TX3.0 render tx plan 3
      return (
        <TxPlan3
          setPlanState={this.setPlanState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          missingTeeth={this.state.missingTeeth}
          extractTeeth={this.state.extractTeeth}
          archToTreat={this.state.archToTreat}
          opposingArch={this.state.opposingArch}
          opposingLowerArch={this.state.opposingLowerArch}
          opposingUpperArch={this.state.opposingUpperArch}
          bracketTeeth={this.state.bracketTeeth}
          restrictTeeth={this.state.restrictTeeth}
          notes={this.state.notes}
          anteriorPosteriorR={this.state.anteriorPosteriorR}
          anteriorPosteriorL={this.state.anteriorPosteriorL}
          anteriorPosteriorCorrections={this.state.anteriorPosteriorCorrections}
          anteriorPosteriorNotes={this.state.anteriorPosteriorNotes}
          midlines={this.state.midlines}
          auxiliariesWillBeUsed={this.state.auxiliariesWillBeUsed}
          auxiliariesNotes={this.state.auxiliariesNotes}
          warning={this.state.warning}
          onFocus={this.onFocus_clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          disabledEdit={disabled}
          photoUpload={this.state.photoUpload}
          xrayUpload={this.state.xrayUpload}
          scanUpload={this.state.scanUpload}
          updateRecordState={this.updateRecordState}
          // iTero integration - props for TxPlan3
          selectedIteroScanRecords={this.state.selectedIteroScanRecords}
          uploadMethod={this.state.uploadMethod}
        />
      );
    }
  };

  /**
   * Redirects the user to case details page post submission
   * @function
   */
  redirectUserToCaseDetail = () => {
    const case_id = this.state.new_case_id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(/submission\/[A-Za-z0-9\-\/\?=]+/, `case/${case_id}`);

    this.props.history.push(new_path);
  };

  isUploadInProgress() {
    return (
      (this.state.photos_uploading && this.state.photos_uploading.length > 0) ||
      (this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0) ||
      (this.state.scans_uploading && this.state.scans_uploading.length > 0) ||
      this.isLoadingIteroPhotos()
    );
  }

  onSubmit = () => {
    const that = this;

    if (!this.state.gen_2) {
      this.setState({ alert: true });
      return;
    }

    if (!this.isSubmitAvailable()) {
      let warning = document.querySelector('#warning-submit');
      let error = '';

      warning.classList.add('warning-display');
      if (this.state.editPatientReferralCode !== '') {
        error = error + '<li>Validate Referral Code</li>';
      }
      if (
        this.state.patientFirstName === '' ||
        this.state.patientLastName === '' ||
        this.state.patientDOB === '' ||
        this.state.patientSex === '' ||
        this.state.attributions.length <= 0 ||
        (isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
      ) {
        error = error + '<li>Patient Information</li>';
      }

      if (!this.hasAttributionResponse()) {
        error = error + '<li>Patient Survey</li>';
      }

      if (!this.isPhotoUploadLengthValid()) {
        error = error + `<li>${this.getPhotoSectionErrorMessage()}</li>`;
      }

      if (this.state.xrayUpload.length < 1) {
        error = error + '<li>Radiographs</li>';
      }

      if (!this.isScansLengthValid()) {
        if (this.getScansLength() > 2) {
          error = error + '<li>More than 2 STL Files</li>';
        } else {
          error = error + '<li>Toothprints</li>';
        }
      }

      if (
        this.state.default_plan_version === 'TX2.0' &&
        (this.state.archToTreat === '' ||
          this.state.chief_complaint === '' ||
          (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) ||
          !this.hasOneClinicalCondition() ||
          !this.hasClinicalConditionRequireFieldMet())
      ) {
        error = error + '<li>Treatment Plan</li>';
      } else if (
        // TX3.0 add tx3.0 error
        this.state.default_plan_version === 'TX3.0' &&
        !this.metAllPlanRequiredField()
      ) {
        error = error + '<li>Treatment Plan</li>';
      }

      if (!this.state.address || this.state.address === '') {
        error = error + '<li>Shipping Information</li>';
      }

      warning.innerHTML = `<ul>${error}</ul>`;

      this.setState({
        warning: true,
      });

      return;
    }
    //Although all criteria is might, upload may still be in-progress
    if (this.isUploadInProgress()) {
      let warning = document.querySelector('#warning-submit');

      if (warning) {
        warning.innerHTML = '<ul><li>Upload in Progress</li></ul>';
      }

      this.setState({
        warning: true,
        warning_title: 'Submission Error',
      });

      return;
    }

    // check terms and condition is selected
    let terms = document.querySelector('#terms');

    if (!(terms && terms.checked)) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');
      warning.innerHTML = '<ul><li>Terms & Conditions</li></ul>';

      this.setState({
        warning: true,
      });

      return;
    }

    //apply API
    let rootPath = getRootPath(this.props.history.location.pathname);
    let doctorId = getDoctorIdFromPath(rootPath);

    this.setState({
      submit_state: 'loading',
      hide: true,
    });

    this.clearAttributionOthers();
    let formData = this.getCurrentData();

    // iTero integration - delete itero photos if upload method is manual
    if (formData.get('uploadMethod') === 'manual' && this.state.selectedIteroId) {
      this.props.deleteIteroPhotosFromIncompleteCase(
        {
          incompleteCaseId: this.state.id,
          iteroId: this.state.selectedIteroId,
        },
        {
          onSuccess: () => {
            this.lastSave(formData, doctorId, that);
          },
        }
      );
    } else {
      this.lastSave(formData, doctorId, that);
    }
  };

  lastSave = (formData, doctorId, ctx) => {
    const currentCtx = ctx || this;
    Axios.post('/api/incomplete/', formData).then(function (result) {
      // Submit
      if (currentCtx.state.patientReferralCode) {
        Axios.put(`/apiv3/referralcode/${currentCtx.state.patientReferralCode}/redeem`);
      }
      Axios.get(`/api/completecase/?id=${currentCtx.state.id}&doctorId=${currentCtx.state.dsoDoctorId ? currentCtx.state.dsoDoctorId : doctorId}`)
        .then(function (result) {
          if (result && result.data && result.data.data && result.data.data.caseid) {
            Axios.post(`/api/casecleanup/?case_id=${result.data.data.caseid}&incomplete_id=${currentCtx.state.id}`).then(function (result) {
              //For updating navigation menu alerts
              currentCtx.props.history.push({ state: { refreshInfo: 'true' } });
            });
            Axios.post(
              `/api/email/?slug=new-case-submission-confirmation-1&doctorId=${doctorId}&caseId=${removeCaseIdInitialNumber(
                result.data.data.caseid
              )}&method=standard`
            );
            currentCtx.setState({ case_id: result.data.data.caseid });
          }
          //Update browser route without refreshing
          currentCtx.appendRoute(result.data.data.caseid, 'confirmation');
          currentCtx.setState({
            submit_state: 'confirmation',
            new_case_id: result.data.data.caseid,
          });
        })
        .catch(function (error) {
          currentCtx.setState({
            submit_state: 'error',
            hide: false,
          });
        });
    });
  };

  onButtonClick = (event) => {
    event.preventDefault();
    this.onFocus_clearWarning();
    let newState = {
      name: '',
      stepper: this.state.stepper,
      active: 0,
    };

    const currstep = this.state.name;
    const button_action = event.currentTarget.dataset.position;

    const nextStep = this.getNextStepperNameByStepperName(currstep);
    const prevStep = this.getBackStepperNameByStepperName(currstep);
    const prev_position = this.getActivePositionByStepperName(prevStep);
    const current_position = this.getActivePositionByStepperName(currstep);
    const next_position = this.getActivePositionByStepperName(nextStep);

    switch (currstep) {
      case 'Patient Information':
        if (button_action === 'next') {
          // Error Logic
          if (this.state.allow_error_msg && !this.hasPatientField()) {
            return;
          }

          // Next State
          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };

          // Complete logic
          newState.stepper[current_position] = 1;
        }

        //Save
        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      case 'Photos':
        if (button_action === 'next') {
          if (!this.hasOnePhoto() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };

          if (this.isPhotoUploadLengthValid()) {
            newState.stepper[current_position] = 1;
          }
        } else if (button_action === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };

          if (this.isPhotoUploadLengthValid()) {
            newState.stepper[current_position] = 1;
          }
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      case 'X-Rays':
        if (button_action === 'next') {
          if (!this.hasOneXray() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };

          if (this.state.xrayUpload.length > 0) {
            newState.stepper[current_position] = 1;
          }
        } else if (button_action === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };

          if (this.state.xrayUpload.length > 0) {
            newState.stepper[current_position] = 1;
          }
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));

        break;

      case 'Impressions':
        if (button_action === 'next') {
          if (!this.hasTwoScans() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };

          if (this.isScansLengthValid()) {
            newState.stepper[current_position] = 1;
          }
        } else if (button_action === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };

          if (this.isScansLengthValid()) {
            newState.stepper[current_position] = 1;
          }
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      case 'Dx/Tx Plan':
        if (button_action === 'next') {
          if (!this.hasTxPlanField()) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };
        } else if (button_action === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      case 'Doctor Information':
        if (button_action === 'next') {
          if (!this.hasAddressField()) {
            return;
          }

          newState.stepper[current_position] = 1;

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: next_position,
          };
        } else if (button_action === 'prev') {
          if (!this.hasAddressField()) {
            return;
          }

          newState.stepper[current_position] = 1;

          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      case 'Submit':
        if (button_action === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: prev_position,
          };
        }

        this.onIncompleteSave(this.convertStepperName(currstep, button_action));
        break;

      default:
    }

    if (newState.name) {
      this.setState(newState);
    }
  };

  updateStepperState = (newState, activeState) => {
    switch (activeState) {
      case 0:
        if (
          this.state.patientFirstName !== '' &&
          this.state.patientLastName !== '' &&
          this.state.patientDOB !== '' &&
          this.state.patientSex !== '' &&
          !this.state.editPatientReferralCode &&
          this.hasAttributionResponse() &&
          !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
        ) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 1:
        if (this.isScansLengthValid()) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 2:
        if (this.isPhotoUploadLengthValid()) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 3:
        if (this.state.xrayUpload.length > 0) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 4:
        if (
          this.state.default_plan_version === 'TX2.0' &&
          this.state.archToTreat &&
          this.state.chief_complaint &&
          this.state.bracketTeeth &&
          this.state.bracketTeeth.length > 0 &&
          this.hasOneClinicalCondition() &&
          this.hasClinicalConditionRequireFieldMet()
        ) {
          newState.stepper[activeState] = 1;
        } else if (
          // TX3.0 - Add stepper activation for TX3.0
          this.state.default_plan_version === 'TX3.0' &&
          this.metAllPlanRequiredField()
        ) {
          newState.stepper[activeState] = 1;
        }

        break;

      case 5:
        if (this.state.address) {
          newState.stepper[activeState] = 1;
        }
        break;

      default:
    }

    return newState;
  };

  // Added by D (Change movement name for routes : You can see in URL)

  convertStepperName = (name, movement = '') => {
    let result = '';

    switch (name) {
      case 'Doctor Information':
        if (movement === 'prev') {
          result = 'scans';
        } else if (movement === 'next') {
          result = 'submit';
        } else {
          result = 'doctor';
        }

        break;
      case 'Patient Information':
        if (movement === 'prev') {
          result = 'patient';
        } else if (movement === 'next') {
          result = 'scans';
        } else {
          result = 'patient';
        }

        break;
      case 'Dx/Tx Plan':
        if (movement === 'prev') {
          result = 'radiographs';
        } else if (movement === 'next') {
          result = 'doctor';
        } else {
          result = 'dxtx';
        }

        break;
      case 'Impressions':
        if (movement === 'prev') {
          result = 'patient';
        } else if (movement === 'next') {
          result = 'photos';
        } else {
          result = 'scans';
        }

        break;
      case 'Photos':
        if (movement === 'prev') {
          result = 'scans';
        } else if (movement === 'next') {
          result = 'radiographs';
        } else {
          result = 'photos';
        }

        break;
      case 'X-Rays':
        if (movement === 'prev') {
          result = 'photos';
        } else if (movement === 'next') {
          result = 'dxtx';
        } else {
          result = 'radiographs';
        }

        break;
      case 'Submit':
        if (movement === 'prev') {
          result = 'doctor';
        } else if (movement === 'next') {
          result = 'submit';
        } else {
          result = 'submit';
        }

        break;
      default:
    }

    return result;
  };

  onStepperClick = (event) => {
    //Check if any of these conditions are false before allow switching
    let activeState = this.state.active;
    const name = event.currentTarget.dataset.description;

    let newState = {
      name: name,
      stepper: this.state.stepper,
      active: this.getActivePositionByStepperName(name),
    };

    if (name !== 'Submit') {
      newState.stepper[6] = 2;
    }

    switch (name) {
      case 'Patient Information':
        if (
          this.state.patientFirstName !== '' &&
          this.state.patientLastName !== '' &&
          this.state.patientDOB !== '' &&
          this.state.patientSex !== '' &&
          !this.state.editPatientReferralCode &&
          this.hasAttributionResponse() &&
          !(isRoleAllowedToAssign(this.state.doctorRole) && this.state.assignedDoctor === '')
        ) {
          newState.stepper[0] = 1;
        }

        newState = this.updateStepperState(newState, activeState);
        break;

      case 'Photos':
        newState = this.updateStepperState(newState, activeState);
        break;

      case 'X-Rays':
        newState = this.updateStepperState(newState, activeState);
        break;

      case 'Impressions':
        newState = this.updateStepperState(newState, activeState);
        break;

      case 'Dx/Tx Plan':
        newState = this.updateStepperState(newState, activeState);
        break;

      case 'Doctor Information':
        newState = this.updateStepperState(newState, activeState);

        if (this.state.address && this.state.address !== '') {
          newState.stepper[5] = 1;
        }

        break;

      case 'Submit':
        newState = this.updateStepperState(newState, activeState);

        break;

      default:
      //Do Nothing
    }

    if (newState.name) {
      this.onFocus_clearWarning();
      this.onIncompleteSave(this.convertStepperName(event.currentTarget.dataset.description));
      this.setState(newState);
      if (event.currentTarget.dataset.description === 'Impressions') {
        this.showMoreTwoScansWarning(this.state.scanUpload);
      }
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }
  /**
   * Determines if the delete draft functionality is allowed
   * @return {Boolean} Returns allowable role to delete
   */
  isAllowedToDeleteCase = () => {
    const doctor_role = this.state.doctorRole;
    const doctor_role_id = this.state.doctorRoleId;
    const doctor_assigned_id = this.state.assignedDoctor;
    const allowed_doctor_role = ['Doctor', 'DSO_Doctor_Override', 'DSO'];

    return (
      _.intersection(allowed_doctor_role, [doctor_role]).length > 0 ||
      (doctor_role === 'DSO_Doctor' && doctor_role_id && doctor_assigned_id && doctor_role_id.toString() === doctor_assigned_id.toString())
    );
  };

  /**
   * Returns the title of case submission
   * @function
   * @return {String} The title of case submission
   */
  getTitle() {
    if (this.state.patientFirstName && this.state.patientLastName) {
      return `Submit Case - ${this.state.patientFirstName} ${this.state.patientLastName}`;
    } else {
      return 'Submit Case';
    }
  }

  /**
   * Retuns the content of the stepper
   * @function
   * @return {Array} The content list
   */
  getStepperContent() {
    return [
      {
        description: 'Patient Information',
        label: 'Patient Info',
        active: this.state.active === 0,
        completed: this.state.stepper[0] === 1,
      },
      {
        description: 'Impressions',
        label: 'Toothprints',
        active: this.state.active === 1,
        completed: this.state.stepper[1] === 1,
        uploading: this.state.uploadMethod === 'manual' && this.state.scans_uploading && this.state.scans_uploading.length > 0,
      },
      {
        description: 'Photos',
        label: 'Photos',
        active: this.state.active === 2,
        completed: this.state.stepper[2] === 1,
        uploading: (this.state.photos_uploading && this.state.photos_uploading.length > 0) || this.isLoadingIteroPhotos(),
      },
      {
        description: 'X-Rays',
        label: 'Radiographs',
        active: this.state.active === 3,
        completed: this.state.stepper[3] === 1,
        uploading: this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0,
      },
      {
        description: 'Dx/Tx Plan',
        label: 'Treatment Plan',
        active: this.state.active === 4,
        completed: this.state.stepper[4] === 1,
      },
      {
        description: 'Doctor Information',
        label: 'Shipping Info',
        active: this.state.active === 5,
        completed: this.state.stepper[5] === 1,
      },
      {
        description: 'Submit',
        label: 'Submit',
        active: this.state.active === 6,
        completed: this.state.stepper[6] === 1,
      },
    ];
  }

  /**
   * Clear the attribution other response if question response does not include other
   * @function
   */
  clearAttributionOthers = () => {
    let new_attributions = this.state.attributions;
    new_attributions.forEach((q_set) => {
      if (q_set && q_set.question_response) {
        if (!q_set.question_response.includes('Other')) {
          q_set.other_response = null;
        }
      }
    });
    this.setState({ attributes: new_attributions });
  };

  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    const file_type = folder && folder === 'photos' ? 'photoUpload' : 'xrayUpload';
    let new_file_upload = this.state[file_type];
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);

    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ [file_type]: new_file_upload });
    }
  };

  getButton() {
    const has_back_button = this.state.active !== 0;
    const has_next_button = this.state.active !== 6;
    return (
      <>
        {has_back_button && (
          <Button theme="secondary" data-position="prev" onClick={this.onButtonClick}>
            Back
          </Button>
        )}
        {has_next_button && (
          <Button data-position="next" onClick={this.onButtonClick}>
            Next
          </Button>
        )}
      </>
    );
  }

  onPhotoUpdate = (new_photo_statuses) => this.setState({ photo_statuses: new_photo_statuses });

  onCompletePhotoUpload = (filename, location) => this.setState({ photo_completed: { ...this.state.photo_completed, [filename]: location } });

  /**
   * iTero integration
   *
   * function to check if the itero photos are loading
   * @function
   * @return {Boolean} Returns true if itero photos are loading
   */
  isLoadingIteroPhotos = () => {
    if (!this.state.shouldFetchIteroPhotos) {
      return false;
    }
    return (
      this.props.isFetchIteroPhotosPending || this.props.isAddIteroPhotosToIncompleteCasePending || this.props.isDeleteIteroPhotosFromIncompleteCasePending
    );
  };

  /**
   * iTero integration
   *
   * function to check if itero photos was loaded
   * @function
   * @return {Boolean} Returns true if itero photos was loaded
   */
  hasIteroPhotos = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    return this.state[photoUploadStateKey].some((file) => file.itero_id);
  };

  /**
   * iTero integration
   *
   * function to check if user is allowed to remove all itero photos
   * @function
   * @return {Boolean} Returns true if user is allowed to remove all itero photos
   */
  shouldShowRemoveIteroPhotos = () => {
    return this.state.isEnrolledToiTeroPhotosIntegration && this.state.shouldAllowToRemoveAllIteroPhotos && this.hasIteroPhotos();
  };

  /**
   * iTero integration
   *
   * Gets the current uploaded photos iteroid
   * @function
   * @return {String} Returns the current uploaded photos iteroid
   * @return {null} Returns null if there are no itero photos
   */
  getCurrentUploadedPhotosIteroId = () => {
    if (this.hasIteroPhotos()) {
      const firstUploadedIteroPhoto = this.state[this.getIteroPhotosStateKey()].filter((file) => file.itero_id)[0];
      return firstUploadedIteroPhoto.itero_id;
    }
    return null;
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  removeAllIteroPhotosFromState = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      const newStepper = this.updatePhotoStepperState(filteredPrevStatePhotoUpload, prevState.stepper);
      return {
        warning: false,
        [photoUploadStateKey]: filteredPrevStatePhotoUpload,
        selectedIteroPhotoRecords: [],
        stepper: newStepper,
      };
    });
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  handleRemoveIteroPhotosClick = () => {
    this.handleShouldFetchIteroPhotosChange(false);
    this.removeAllIteroPhotosFromState();
    this.props.deleteIteroPhotosFromIncompleteCase({
      incompleteCaseId: this.state.id,
      iteroId: this.getCurrentUploadedPhotosIteroId(),
    });
  };

  /**
   * iTero integration
   *
   * function to check if the itero photos are being deleted
   * @function
   * @return {Boolean} Returns true if itero photos are being deleted
   */
  isDeletingIteroPhotos = () => {
    return this.props.isDeleteIteroPhotosFromIncompleteCasePending;
  };

  isDsoCase() {
    return this.state.doctorRole.includes('DSO');
  }

  getPhotosComponent(disabled) {
    const isS3 = isS3FileService();
    const isDso = this.isDsoCase();

    return isS3 ? (
      <PhotosS3
        id={this.state.id}
        location="incomplete"
        photo_statuses={this.state.photo_statuses}
        onPhotoUpdate={this.onPhotoUpdate}
        photo_completed={this.state.photo_completed}
        onCompleteUpload={this.onCompletePhotoUpload}
      />
    ) : (
      <Photos
        id={this.state.id}
        photoUpload={this.state.photoUpload}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        onIncompleteSave={this.onIncompleteSave}
        upload_state={this.photos_upload}
        upload_content={this.state.photos_uploading}
        show_warning={this.show_warning}
        hide_warning={this.hide_warning}
        isDso={isDso}
        disabledEdit={disabled}
        updateRecordState={this.updateRecordState}
        isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
        isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
        showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
        onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
        iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
      />
    );
  }

  getSubmitComponent(disabled) {
    const defaultProps = {
      onButtonClick: this.onFocus_clearWarning,
      onSubmitClick: this.onSubmit,
      agreement: this.state.agreement,
      disabledEdit: disabled,
    };
    if (this.state.default_plan_version === 'TX2.0') {
      return <Submit {...defaultProps} />;
    }
    return (
      <Submit
        {...defaultProps}
        summaryComponent={CaseSubmissionSummary}
        summaryProps={{
          caseId: this.state.case_id,
          getImpliedTeethSets: this.getImpliedTeethSets,
          missingTeeth: this.state.missingTeeth,
          extractTeeth: this.state.extractTeeth,
          archToTreat: this.state.archToTreat,
          opposingArch: this.state.opposingArch,
          bracketTeeth: this.state.bracketTeeth,
          restrictTeeth: this.state.restrictTeeth,
          notes: this.state.notes,
          anteriorPosteriorR: this.state.anteriorPosteriorR,
          anteriorPosteriorL: this.state.anteriorPosteriorL,
          anteriorPosteriorCorrections: this.state.anteriorPosteriorCorrections,
          anteriorPosteriorNotes: this.state.anteriorPosteriorNotes,
          midlines: this.state.midlines,
          auxiliariesWillBeUsed: this.state.auxiliariesWillBeUsed,
          auxiliariesNotes: this.state.auxiliariesNotes,
          photoUpload: this.state.photoUpload,
          xrayUpload: this.state.xrayUpload,
          scanUpload: this.state.scanUpload,
          revision_id: this.state.default_plan_version,
          // iTero integration - props to be passed to the summary component
          selectedIteroScanRecords: this.state.selectedIteroScanRecords,
          uploadMethod: this.state.uploadMethod,
        }}
      />
    );
  }

  getCurrentStepComponent(permissions) {
    const ippDisabledEdit = !userHasPermission('IPP_EDIT', permissions);
    const dsoDisabledEdit = dsoDraftDisabled(this.state.doctorRole, this.state.doctorRoleId, this.state.assignedDoctor);
    const disabled = ippDisabledEdit || dsoDisabledEdit;
    const isDso = this.isDsoCase();

    switch (this.state.active) {
      case 0:
        return (
          <PatientInfo
            onDeleteLabelClick={this.onDeleteLabelClick}
            referral={this.state.referral}
            onInputChange_ReferralCode={this.onInputChange_ReferralCode}
            onValidateButtonClick={this.onValidateButtonClick}
            editPatientReferralCode={this.state && this.state.editPatientReferralCode}
            patientReferralCode={this.state && this.state.patientReferralCode}
            onReferralCodeFocus={this.onFocus_clearWarning}
            onInputChange_firstName={this.onInputChange_patientFirstName}
            onProspectChange={this.onInputChange_prospect}
            patientFirstName={this.state.patientFirstName}
            onInputChange_lastName={this.onInputChange_patientLastName}
            patientLastName={this.state.patientLastName}
            onInputChange_dob={this.onInputChange_dob}
            patientDOB={this.state.patientDOB}
            onInputChange_sex={this.onInputChange_sex}
            gender={this.state.patientSex}
            onFocus={this.onFocus_clearWarning}
            onInputChange_patientRef={this.onInputChange_patientRef}
            patientRef={this.state.patientRef}
            doctorRole={this.state.doctorRole}
            doctorRoleId={this.state.doctorRoleId}
            disabledEdit={disabled}
            load_dso_assign={isDSOAssignedLoadable(this.state.doctorRole, this.state.doctorRoleId, this.state.assignedDoctor)}
            onInputChange_assignedDoctor={this.onInputChange_assignedDoctor}
            assignedDoctor={this.state.assignedDoctor}
            prospect_id={this.state.prospect_id}
            prospect_data={this.state.prospect_data}
            selected_prospect_id={parseInt(this.state.selected_prospect_id)}
            attributions={this.state.attributions}
            attribution_questions={this.state.attribution_questions}
            onAttributionsChange={this.onAttributionsChange}
            onAttributionOtherChange={this.onAttributionOtherChange}
          />
        );
      case 1:
        return (
          <Scans
            id={this.state.id}
            scanUpload={this.state.scanUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onEmptyFileError={this.onEmptyFileError}
            upload_state={this.scans_upload}
            upload_content={this.state.scans_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            disabledEdit={disabled}
            isDso={isDso}
            // iTero integration - props to be passed to the scans component
            uploadMethod={this.state.uploadMethod}
            onScansUploadMethodChange={this.handleScansUploadMethodChange}
            iteroSelectionProps={this.getIteroSelectionProps()}
            recordViewerSubtitle={this.getRecordViewerSubtitle(true, this.isLoadingIteroPhotos())}
            isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
          />
        );
      case 2:
        return this.getPhotosComponent(disabled);
      case 3:
        return (
          <Radiographs
            id={this.state.id}
            xrayUpload={this.state.xrayUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onIncompleteSave={this.onIncompleteSave}
            upload_state={this.radiographs_upload}
            upload_content={this.state.radiographs_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            disabledEdit={disabled}
            isDso={isDso}
            updateRecordState={this.updateRecordState}
          />
        );
      case 4:
        return this.getPlanByVersion(disabled);
      case 5:
        return (
          <ShippingInfo
            onInputChange={this.onInputChange_address}
            address={this.state.address}
            addresses={this.state.addresses}
            onFocus={this.onFocus_clearWarning}
            firstName={this.state.doctorfirstName}
            lastName={this.state.doctorlastName}
            disabledEdit={disabled}
          />
        );

      case 6:
        return this.getSubmitComponent(disabled);
      default:
    }
  }
  render() {
    const is_submission_process = Boolean(this.state.submit_state);
    const is_submission_confirmation = is_submission_process && this.state.submit_state === 'confirmation';
    const is_submission_loading = is_submission_process && this.state.submit_state === 'loading';
    const is_submission_error = is_submission_process && this.state.submit_state === 'error';

    const handleErrorModalClick = () => {
      this.setState({
        submit_state: '',
        hide: false,
      });
    };

    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.terms) {
      return <CustomerAgreement />;
    } else if (this.state.loading) {
      return <CircleLoader fullscreen={true} />;
    } else {
      return (
        <div>
          <Helmet>
            <title>Case Submission | InBrace Smile Design Studio™</title>
          </Helmet>

          {is_submission_confirmation ? null : (
            <div className="title-height content__header">
              {!this.state.hide ? (
                <Button
                  disabled={this.isUploadInProgress() || dsoDraftDisabled(this.state.doctorRole, this.state.doctorRoleId, this.state.assignedDoctor)}
                  onClick={(e) => {
                    this.onIncompleteSave(e, '', {
                      refreshCaseList: true,
                      refreshDashboard: true,
                    });
                  }}
                  data-redirect="true"
                  data-position="save"
                >
                  Save
                </Button>
              ) : null}
              <h1 className="fs-exclude">{this.getTitle()}</h1>
            </div>
          )}

          <div>
            {this.state.hide ? null : (
              <CardContainer>
                <Stepper activeStepper={this.state.active} stepperContent={this.getStepperContent()} onStepperClick={this.onStepperClick} />
              </CardContainer>
            )}
            {is_submission_confirmation ? (
              <SubmissionConfirmation onButtonClick={this.redirectUserToCaseDetail} case_id={this.state.case_id} />
            ) : is_submission_error ? (
              <SubmitErrorModal show={true} onClose={handleErrorModalClick} onClickConfirm={handleErrorModalClick} />
            ) : (
              <CardContainer className="case-card-container pad-lg clear-after">
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) =>
                    is_submission_loading ? <SubmitterLoader /> : this.getCurrentStepComponent(user_roles_and_permissions.permissions)
                  }
                </UserPermissionsContext.Consumer>

                {!this.state.hide && (
                  <ErrorMessage
                    className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
                    title={this.state.warning_title}
                    onClose={this.removeWizardErrorMsg}
                  >
                    <div id="warning-submit" />
                  </ErrorMessage>
                )}
              </CardContainer>
            )}

            {!this.state.hide && (
              <div className="case-form-controls">
                {this.getButton()}
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    const disabled = !userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) && this.isAllowedToDeleteCase();
                    return (
                      <Button
                        theme="revise"
                        disabled={disabled || dsoDraftDisabled(this.state.doctorRole, this.state.doctorRoleId, this.state.assignedDoctor)}
                        onClick={this.onRemoveDraft}
                      >
                        Delete
                      </Button>
                    );
                  }}
                </UserPermissionsContext.Consumer>
              </div>
            )}
          </div>

          {this.state.showCancelCase === true ? (
            <Modal
              preset="decision"
              header_text="Delete Draft"
              modal_class="modal-content-warning"
              modal_body_class="text-left"
              message_text={<span>Are you sure you want to delete this draft submission? You will not be able to undo this action if you proceed.</span>}
              confirm_btn_text="Delete"
              close_btn_text="Cancel"
              after_confirm={true}
              after_confirm_text="Deleting Draft..."
              onCloseButtonClick={this.onModalDismiss}
              onConfirmButtonClick={this.onModalAccept}
            />
          ) : null}

          {this.state.showDeleteReferralCodeModal === true ? (
            <Modal
              preset="decision"
              header_text="Remove Patient Referral Code"
              message_text={
                <span>
                  Would you like to remove patient referral code <strong>{this.state.patientReferralCode}</strong>?
                </span>
              }
              confirm_btn_text="Remove"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onDeleteReferralCodeConfirm}
              onCloseButtonClick={this.onDeleteReferralCodeDismiss}
            />
          ) : null}

          {this.state.showValidReferralCode === true ? (
            <Modal
              preset="decision-referralcode"
              header_text="Apply Referral Code"
              message_text={
                <span>
                  Please review the information below linked to referral code <strong>{this.state.patientReferralCode}</strong>
                  <br />
                  Click <strong>Continue</strong> to redeem this code.
                </span>
              }
              confirm_btn_text="Continue"
              close_btn_text="Cancel"
              onConfirmButtonClick={this.onValidReferralCodeConfirm}
              onCloseButtonClick={this.onValidReferralCodeDismiss}
              referral_code_data={this.state.referralCodeData}
            />
          ) : null}

          {this.state.showRedeemedReferralCode === true ? (
            <Modal
              preset="action"
              header_text="Invalid Patient Referral Code"
              message_text={
                <span>
                  The entered referral code had already been redeemed.
                  {this.state.referral_code_url ? (
                    <React.Fragment>
                      <br />A new referral code may be generated at{' '}
                      <a className="midnight" href={this.state.referral_code_url} target="_blank" rel="noopener noreferrer">
                        {this.state.referral_code_url}
                      </a>
                    </React.Fragment>
                  ) : null}
                </span>
              }
              confirm_btn_text="Ok"
              onConfirmButtonClick={this.onRedeemedReferralCodeConfirm}
              onCloseButtonClick={this.onRedeemedReferralCodeDismiss}
            />
          ) : null}

          {this.state.showUnableToValidateReferralCode === true ? (
            <Modal
              preset="action"
              header_text="Unable to Validate Patient Referral Code"
              message_text={<span>Unable to validate Patient Referral Code, please try again later.</span>}
              confirm_btn_text="Ok"
              onConfirmButtonClick={this.onUnableToValidateReferralCodeConfirm}
              onCloseButtonClick={this.onUnableToValidateReferralCodeDismiss}
            />
          ) : null}

          {this.state.showInvalidReferralCode === true ? (
            <Modal
              preset="action"
              header_text="Invalid Patient Referral Code"
              message_text={
                <span>
                  The entered referral code does not exist.
                  {this.state.referral_code_url ? (
                    <React.Fragment>
                      <br />A new referral code may be generated at{' '}
                      <a className="midnight" href={this.state.referral_code_url} target="_blank" rel="noopener noreferrer">
                        {this.state.referral_code_url}
                      </a>
                    </React.Fragment>
                  ) : null}
                </span>
              }
              confirm_btn_text="Ok"
              onConfirmButtonClick={this.onInvalidReferralCodeConfirm}
              onCloseButtonClick={this.onInvalidReferralCodeDismiss}
            />
          ) : null}

          {this.state.showEmptyFileModal === true ? <EmptyFileErrorModal onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} theme="ipp" /> : null}

          {this.state.showCaseRecommendationModal === true ? (
            <Modal
              preset="action"
              backdrop={true}
              header_text="Submit a Case"
              modal_class="modal__recommendation--size"
              modal_body_class="text-left"
              message_text={
                <div>
                  <div className="">Recommended Case Selection Guidelines:</div>
                  <div className="list-spaced">
                    <ul>
                      <li>Mild to moderate crowding</li>
                      <li>Class I or mild Class II/III AP discrepancy</li>
                      <li>Mild to moderate deep or open bite</li>
                      <li>Mild or absent transverse discrepancy</li>
                      <li>No spaces greater than 3.5mm that the treating doctor is planning to close</li>
                    </ul>
                    <div>
                      If you submit a case with attributes falling outside recommended InBrace guidelines, please be advised your case may require additional
                      auxiliaries or appliances for predictable and successful completion.
                    </div>
                  </div>
                </div>
              }
              confirm_btn_text="Continue"
              onConfirmButtonClick={this.onModalDismissCaseRecommendation}
              onCloseButtonClick={this.onModalDismissCaseRecommendation}
            />
          ) : null}

          {this.state.showSaveModal === true ? (
            <Modal
              preset="decision"
              header_text="Draft Saved"
              message_text={
                <span>
                  Your draft has been successfully saved! Click <span className="emphasis">Continue</span> to resume your submission
                </span>
              }
              confirm_btn_text="Continue"
              close_btn_text="Return To Case List"
              onCloseButtonClick={this.onRedirectManageCases}
              onConfirmButtonClick={() => {
                this.setState({ showSaveModal: false });
              }}
            />
          ) : null}

          <Gen1AlertModal
            doctor_id={this.state.doctorRoleId}
            show={this.state.alert}
            onCloseButtonClick={() => {
              this.setState({ alert: false });
            }}
          />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
    // iTero integration - redux state
    licenseNumbers: getLicenseNumbers(state),
    isFetchIteroPhotosPending: getFetchIteroPhotosPending(state),
    isAddIteroPhotosToIncompleteCasePending: getAddIteroPhotosToIncompleteCasePending(state),
    isDeleteIteroPhotosFromIncompleteCasePending: getDeleteIteroPhotosFromIncompleteCasePending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      // iTero integration - redux actions
      fetchLicenseNumbers,
      updateCaseIteroFiles,
      buildRecordStates,
      fetchIteroScans,
      fetchIteroPhotos,
      addIteroPhotosToIncompleteCase,
      deleteIteroPhotosFromIncompleteCase,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CaseSubmission));
