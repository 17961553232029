import * as THREE from 'three';
import { isMan } from '../common/functions';

const len = 15;
const angle = 30;
const txtAspect = 1;
const txtHeightMM = 5;
const txtHeightPt = 128;
const cradius = 0.17;
const txtWidthMM = txtAspect * txtHeightMM;
const borderWidth = 1;
const coneMat = new THREE.MeshBasicMaterial({ color: 0xcccccc });

/**
 * Class representing the IPR in setup viewer
 */
class IPR {
  constructor() {
    this.iprMeshes = {
      maxIPR: new THREE.Group(),
      manIPR: new THREE.Group(),
    };
    this.tmeshes = [];
    this.cmeshes = [];
  }

  /**
   * Adds IPR to the group
   * @function
   * @param {THREE.Object3D} group - The group to contain IPR
   */
  addIPR(group) {
    group.add(this.iprMeshes.maxIPR);
    group.add(this.iprMeshes.manIPR);
  }

  /**
   * Removes IPR from the group
   * @function
   * @param {THREE.Object3D} group - The group that contains IPR
   */
  removeIPR(group) {
    group.remove(this.iprMeshes.maxIPR);
    group.remove(this.iprMeshes.manIPR);
  }

  /**
   * Show or hide the upper ipr
   * @function
   * @param {Boolean} show - To show or not
   */
  showMaxIPR(show) {
    this.iprMeshes.maxIPR.visible = show;
  }

  /**
   * Show or hide the lower ipr
   * @function
   * @param {Boolean} show - To show or not
   */
  showManIPR(show) {
    this.iprMeshes.manIPR.visible = show;
  }

  /**
   * Updates the IPR's position and orientation
   * @function
   * @param {Number} textSize - The IPR's text size
   * @param {THREE.Quaternion} quat - The camera's quaternion
   */
  updateIPR(textSize, quat) {
    this.tmeshes.forEach(function (m) {
      m.scale.set(textSize, textSize, textSize);
      m.quaternion.copy(quat);
      const pNew = m.p0.clone();
      const zNorm = new THREE.Vector3(0, 0, 1);
      zNorm.applyQuaternion(quat);
      const offZ = m.vNorm.clone();
      offZ.sub(zNorm.multiplyScalar(zNorm.dot(offZ)));
      offZ.normalize().multiplyScalar((txtHeightMM / 2) * textSize);

      pNew.add(offZ);
      m.position.copy(pNew);
    });
    this.cmeshes.forEach(function (m) {
      m.scale.set(textSize, 1, textSize);
    });
  }

  /**
   * Generates the IPR meshes
   * @function
   * @param {Object} ipr_json - The ipr json
   */
  generateIPRMeshes(ipr_json) {
    if (!IPR.hasIPR(ipr_json)) return;

    const coneMesh = IPR.generateConeMesh();

    const createIPRMesh = (tn, amt, pos, norm) => {
      const angle_offset = IPR.getAngleOffset(tn);
      const vNorm = new THREE.Vector3(norm[0], norm[1], norm[2]);
      const vPos = new THREE.Vector3(pos[0], pos[1], pos[2]);

      const cone = coneMesh.clone();
      const mesh = IPR.generateIPRTextMesh(amt);
      IPR.setConePosition(cone, angle_offset, vNorm, vPos);
      IPR.setIPRTextPosition(mesh, angle_offset, vNorm, vPos);

      this.cmeshes.push(cone);
      this.tmeshes.push(mesh);
      return [cone, mesh];
    };

    const processTeeth = (teeth, ipr) => {
      ipr.remove(...ipr.children);
      for (let key in teeth) {
        const th = teeth[key];
        ipr.add(...createIPRMesh(key, th[2], th[0], th[1]));
      }
      ipr.name = 'ipr';
    };
    processTeeth(ipr_json.man, this.iprMeshes.manIPR);
    processTeeth(ipr_json.max, this.iprMeshes.maxIPR);
  }

  /**
   * Cleans up the meshes from memory
   * @function
   */
  dispose() {
    this.cmeshes.forEach((mesh) => {
      mesh.geometry.dispose();
      mesh.material.dispose();
    });
    this.tmeshes.forEach((mesh) => {
      mesh.geometry.dispose();
      mesh.material.dispose();
    });
  }

  /**
   * Checks whether the ipr json has ipr info
   * @param {Object} ipr_json - The ipr json
   */
  static hasIPR(ipr_json) {
    function checkIPR(arch) {
      const vals = ipr_json[arch];
      return vals && Object.keys(vals).length > 0;
    }
    const hasIPR = checkIPR('man') || checkIPR('max');
    return hasIPR;
  }

  /**
   * Generates the mesh that has the text
   * @function
   * @param {String} amt - The text for the mesh
   * @return {THREE.Mesh} The mesh
   */
  static generateIPRTextMesh(amt) {
    const iprTextMat = IPR.createTextMat(amt);
    const geo = new THREE.PlaneGeometry(txtWidthMM, txtHeightMM);
    const mesh = new THREE.Mesh(geo, iprTextMat);
    return mesh;
  }

  /**
   * Creates the material with the text
   * @function
   * @param {String} text - The text to show
   * @return {THREE.Material} - The material with the text
   */
  static createTextMat(text) {
    const { canvas, ctx } = IPR.createIPRCanvasAndCtx();
    IPR.drawIPRCircle(canvas, ctx);
    IPR.drawIPRText(canvas, ctx, text);

    const mat = IPR.createMatFromCanvas(canvas);
    return mat;
  }

  /**
   * Creates canvas and context for drawing text
   * @function
   * @return {Object} Canvas and context
   */
  static createIPRCanvasAndCtx() {
    const canvas = document.createElement('canvas');
    canvas.height = txtHeightPt;
    canvas.width = canvas.height * txtAspect;

    const ctx = canvas.getContext('2d');
    return { canvas, ctx };
  }

  /**
   * Sets the context's style to ipr circle
   * @function
   * @param {RenderingContext} ctx - The rendering context
   */
  static setIPRCircleStyle(ctx) {
    const lead = 2.2;
    ctx.font = (txtHeightPt / lead).toFixed(0) + 'pt Arial';
    ctx.globalAlpha = 0.6;
    ctx.fillStyle = 'white';
  }

  /**
   * Draws a IPR circle
   * @function
   * @param {Canvas} canvas - The Canvas
   * @param {RenderingContext} ctx - The rendering context
   */
  static drawIPRCircle(canvas, ctx) {
    IPR.setIPRCircleStyle(ctx);

    const centerX = canvas.width / 2 + borderWidth;
    const centerY = canvas.height / 2 + borderWidth;
    const radius = canvas.width / 2 - borderWidth * 2;
    const startAngle = 0;
    const endAngle = 2 * Math.PI;
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, startAngle, endAngle);
    ctx.fill();
    ctx.globalAlpha = 1;
    ctx.fillStyle = 'black';
    ctx.lineWidth = borderWidth;
    ctx.stroke();
  }

  /**
   * Sets context's style to ipr text
   * @function
   * @param {RenderingContext} ctx - The rendering context
   */
  static setIPRTextStyle(ctx) {
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.globalAlpha = 1;
  }

  /**
   * Draws the ipr amount
   * @function
   * @param {Canvas} canvas - The Canvas
   * @param {RenderingContext} ctx - The rendering context
   * @param {String} amount - The text to draw
   */
  static drawIPRText(canvas, ctx, amount) {
    IPR.setIPRTextStyle(ctx);

    let text = amount.toFixed(1).toString();
    if (text.startsWith('0')) text = text.substring(1);

    const align = 5;
    ctx.fillText(text, canvas.width / 2 - align, canvas.height / 2 + align);
  }

  /**
   * Creates material from canvas
   * @function
   * @param {Canvas} canvas - The Canvas
   * @return {THREE.Material} The material
   */
  static createMatFromCanvas(canvas) {
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    const mat = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    return mat;
  }

  /**
   * Calculate the angle offset for each teeth number
   * @function
   * @param {Number} tn - The teeth number
   * @return {Number} The angle offset
   */
  static getAngleOffset(tn) {
    let angle_offset = isMan(tn) ? -angle : angle;
    angle_offset = THREE.Math.degToRad(angle_offset);
    angle_offset += Math.PI / 2;
    return angle_offset;
  }

  /**
   * Generates cone mesh
   * @function
   * @return {THREE.Mesh} The cone mesh
   */
  static generateConeMesh() {
    const geo = new THREE.CylinderBufferGeometry(cradius, cradius, len);
    const cone = new THREE.Mesh(geo, coneMat);
    return cone;
  }

  /**
   * Sets the cone mesh's properties
   * @function
   * @param {THREE.Mesh} cone - The cone mesh
   * @param {Number} angle_offset - The angle offset
   * @param {THREE.Vector3} vNorm - The normal vector
   * @param {THREE.Vector3} vPos - The position vector
   */
  static setConePosition(cone, angle_offset, vNorm, vPos) {
    cone.lookAt(vNorm);
    cone.position.copy(vPos);
    cone.rotateX(angle_offset);
    cone.translateY(-len / 2);
  }

  /**
   * Sets the ipr text mesh's properties
   * @function
   * @param {THREE.Mesh} mesh - The text mesh
   * @param {Number} angle_offset - The angle offset
   * @param {THREE.Vector3} vNorm - The normal vector
   * @param {THREE.Vector3} vPos - The position vector
   */
  static setIPRTextPosition(mesh, angle_offset, vNorm, vPos) {
    mesh.lookAt(vNorm);
    mesh.position.copy(vPos);
    mesh.rotateX(angle_offset);
    mesh.translateY(-len);
    mesh.p0 = mesh.position.clone();
    mesh.vNorm = mesh.p0.clone().sub(vPos).normalize();
  }
}

export default IPR;
