import Axios from 'axios';

const axios = Axios.create()

/**
 * Generates an array of file chunks based on the specified file size and chunk size.
 *
 * @param {number} fileSize - The size of the file in bytes.
 * @param {number} chunkSizeInMB - The desired size of each chunk in megabytes.
 * @returns {Array<{start: number, end: number}>} An array of objects representing file chunks,
 * where each object contains 'start' and 'end' properties indicating the byte range of the chunk.
 * @throws {Error} Throws an error if fileSize or chunkSizeInMB is not a positive number.
 *
 * @example
 * const fileSize = 1024 * 1024 * 10; // 10 MB
 * const chunkSizeInMB = 2; // 2 MB
 * const chunks = getChunksBasedOnFileSize(fileSize, chunkSizeInMB);
 * // Output: [{ start: 0, end: 2097151 }, { start: 2097152, end: 4194303 }, ...]
 */
const getChunksBasedOnFileSize = (fileSize, chunkSizeInMB) => {
  const chunkSize = chunkSizeInMB * 1024 * 1024;
  const totalChunks = Math.ceil(fileSize / chunkSize);
  const chunks = [];
  for (let i = 0; i < totalChunks; i++) {
    const start = i * chunkSize;
    let end = start + chunkSize -1;
    if (end > fileSize) {
      end = fileSize
    }
    chunks.push({
      start,
      end
    })
  }
  return chunks;
}


/**
 * Merges an array of Uint8Array buffers into a single Uint8Array.
 *
 * @param {Array<Uint8Array>} buffers - An array of Uint8Array buffers to be merged.
 * @returns {Uint8Array} A Uint8Array containing the concatenated data from all input buffers.
 * @throws {TypeError} Throws a TypeError if any element in the 'buffers' array is not a Uint8Array.
 *
 * @example
 * const buffer1 = new Uint8Array([1, 2, 3]);
 * const buffer2 = new Uint8Array([4, 5, 6]);
 * const mergedBuffer = mergeBuffers([buffer1, buffer2]);
 * // Output: Uint8Array [1, 2, 3, 4, 5, 6]
 */
const mergeBuffers = (buffers) => {
  const totalLength = buffers.reduce((acc, buffer) => acc + buffer.length, 0);
  const mergedArray = new Uint8Array(totalLength);
  let offset = 0;

  buffers.forEach((buffer) => {
    mergedArray.set(buffer, offset);
    offset += buffer.length;
  });

  return mergedArray;
};


/**
 * Downloads a file from a presigned URL with multipart requests and provides progress updates.
 *
 * @async
 * @function downloadFileWithMultipart
 * @param {object} downloadInfo - Information about the download, including path, size, and ETag.
 * @param {function} onProgress - A callback function to notify download progress.
 * @param {number} [chunkSize=5] - The size of each chunk for multipart download, in megabytes.
 * @returns {Promise<Uint8Array>} A Promise that resolves to the downloaded file as a Uint8Array.
 */
const downloadFileWithMultipart = async (downloadInfo, onProgress, chunkSize=5) => {
  let { path: presignedUrl, size: fileSize, e_tag  } = downloadInfo;
  presignedUrl += `&e_tag=${e_tag}`;
  const chunks = getChunksBasedOnFileSize(fileSize, chunkSize);
  const chunkProgresses = [];
  const chunkResponses = Array.from({ length: chunks.length }, (_, index) => index + 1);
  const downloadPromises = [];
  for (let i = 0; i < chunks.length; i++) {
    const headers = {
      Range: `bytes=${chunks[i].start}-${chunks[i].end}`
    }
    downloadPromises.push(
      axios.get(presignedUrl, {
        responseType: 'arraybuffer',
        headers,
        onDownloadProgress: evt => {
          chunkProgresses[i] = evt.loaded;
          const totalProgress = chunkProgresses.reduce((a, b) => a + b);
          const progress = (totalProgress / fileSize) * 100;
          onProgress(progress);
        }
      }).then((res) => {
        chunkResponses[i] = new Uint8Array(res.data);
      })
    );
  }
  await Promise.all(downloadPromises);
  return mergeBuffers(chunkResponses);
};


/**
 * Downloads a file from the specified URL using a single part and returns it as a Uint8Array.
 *
 * @function downloadFileWithSinglePart
 * @param {object} downloadInfo - Information about the download, including path and ETag.
 * @returns {Promise<Uint8Array>} A Promise that resolves to the downloaded file as a Uint8Array.
 * @throws {string} Throws a string containing the HTTP status text if the download encounters an error.
 */
const downloadFileWithSinglePart = (downloadInfo) => {
  const url = downloadInfo.path + `&e_tag=${downloadInfo.e_tag}`
  return new Promise((resolve, reject) => {
    var blob = '';
    var xmlHTTP = new XMLHttpRequest();
    xmlHTTP.open('GET', url, true);
    xmlHTTP.setRequestHeader('Access-Control-Allow-Origin', '*');
    xmlHTTP.responseType = 'arraybuffer';
    xmlHTTP.onloadend = function (e) {
      if (xmlHTTP.status >= 200 && xmlHTTP.status < 300) {
        resolve(new Uint8Array(xmlHTTP.response));
      } else {
        reject(xmlHTTP.statusText);
      }
    };
    xmlHTTP.send();
  })
}
export {
  downloadFileWithMultipart,
  downloadFileWithSinglePart
}