/**
 * Filters the Gen2 cases from list of all cases
 * @function
 * @param {Object} cases - cases data
 * @return {Object} - list of Gen2 cases
 */
const getAllGen2Cases = (cases) => {
  let gen2_cases = [];
  for (let i = 0; i < cases.length; i++) {
    if (cases[i].gen_2) {
      gen2_cases.push(cases[i]);
    }
  }
  return gen2_cases;
};

/**
 * Filters the Gen2 cases from list of all cases
 * @function
 * @param {Object} ir_list - Item request data
 * @return {Object} - list of Gen2 item requests
 */
const getGen2Ir = (ir_list) => {
  let gen2_ir = [];
  for (var item_request of ir_list) {
    if (item_request.gen_2) {
      gen2_ir.push(item_request);
    }
  }
  return gen2_ir;
};

export { getAllGen2Cases, getGen2Ir };
