/**
 * File: user_type.js - A modal pop up that indicates the type of account to
 * create
 * Copyright: (c) Copyright June 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class CreateAccountModal extends Component {
  render() {
    return (
      <div>
        <div>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onCloseButtonClick}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p className="black-text text-center">Please select user type</p>
        <UserPermissionsContext.Consumer>
          { user_roles_and_permissions => (
              <div className="account-type-list">
                { userHasPermission('ACCOUNT_IPP_CREATE', user_roles_and_permissions.permissions) && 
                  <div className="account-type" data-dismiss="modal" aria-label="Close" onClick={this.props.onCreateDoctorClick}>
                    <i className="fa fa-user-circle account-type-logo" aria-hidden="true" />
                    <br />
                    Doctor
                  </div> }
                { userHasPermission('ACCOUNT_DSO_CREATE', user_roles_and_permissions.permissions) && 
                  <div className="account-type" data-dismiss="modal" aria-label="Close" onClick={this.props.onCreateDsoClick}>
                    <i className="fa fa-user-circle account-type-logo-dso" aria-hidden="true" />
                    <br />
                    DSO
                  </div> }
                { userHasPermission('ACCOUNT_BPP_CREATE', user_roles_and_permissions.permissions) && 
                  <div className="account-type" data-dismiss="modal" aria-label="Close" onClick={this.props.onCreateBppClick}>
                    <i className="fa fa-user-circle account-type-logo-dark" aria-hidden="true" />
                    <br />
                    BPP
                  </div> }
              </div>
            )
          }
        </UserPermissionsContext.Consumer>
      </div>
    );
  }
}

export default withRouter(CreateAccountModal);
