import React from 'react';

function WasmIprLegend() {
  return (
    <div className="wasm-ipr-legend-container">
      <div className='wasm-ipr-legend-item'>
        <span className="wasm-ipr-legend--ipr" />
        IPR
      </div>
      <div className='wasm-ipr-legend-item'>
        <span className="wasm-ipr-legend--spacing" />
        Spacing
      </div>
    </div>
  );
}

export default WasmIprLegend;
