import {
  SET_DOCTOR_STATS,
  FETCH_DOCTOR_INFO_START,
  FETCH_DOCTOR_INFO_SUCCESS,
  FETCH_DOCTOR_INFO_ERROR,
  FETCH_ACTION_REQUIRED_START,
  FETCH_ACTION_REQUIRED_SUCCESS,
  FETCH_ACTION_REQUIRED_ERROR,
  FETCH_ACTIVITY_START,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_ERROR,
  SET_PROVIDER_TIER,
  SET_PROVIDER_TIER_LOADING,
} from '../../actions/ipp/dashboard';

const initialState = {
  doctor_id: '',

  stats: {
    lifetime: 0,
    transit: 0,
    approved: 0,
  },

  doctor_info: null,
  doctor_info_loading: false,
  doctor_info_error: false,

  provider_tier: {},
  provider_tier_loading: false,

  action_required: [],
  action_required_total: 0,
  action_required_loading: false,
  action_required_error: false,

  activity: [],
  activity_total: 0,
  activity_loading: false,
  activity_error: false,
};

export function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCTOR_STATS:
      return setDoctorStats(state, action);
    case SET_PROVIDER_TIER:
      return setProviderTier(state, action);
    case SET_PROVIDER_TIER_LOADING:
      return setProviderTierLoading(state, action);
    case FETCH_DOCTOR_INFO_START:
      return fetchDoctorInfoStart(state, action);
    case FETCH_DOCTOR_INFO_SUCCESS:
      return fetchDoctorInfoSuccess(state, action);
    case FETCH_DOCTOR_INFO_ERROR:
      return fetchDoctorInfoError(state, action);
    case FETCH_ACTION_REQUIRED_START:
      return fetchActionRequiredStart(state, action);
    case FETCH_ACTION_REQUIRED_SUCCESS:
      return fetchActionRequiredSuccess(state, action);
    case FETCH_ACTION_REQUIRED_ERROR:
      return fetchActionRequiredError(state, action);
    case FETCH_ACTIVITY_START:
      return fetchActivityStart(state, action);
    case FETCH_ACTIVITY_SUCCESS:
      return fetchActivitySuccess(state, action);
    case FETCH_ACTIVITY_ERROR:
      return fetchActivityError(state, action);

    default:
      return state;
  }
}

function setDoctorStats(state, action) {
  return { ...state, stats: action.stats };
}

function setProviderTier(state, action) {
  return { ...state, provider_tier: action.info, provider_tier_loading: false };
}

function setProviderTierLoading(state, action) {
  return { ...state, provider_tier_loading: action.loading };
}

function fetchDoctorInfoStart(state, action) {
  return { ...state, doctor_info_loading: true };
}
function fetchDoctorInfoSuccess(state, action) {
  return { ...state, doctor_info: action.data, doctor_info_loading: false, doctor_info_error: false };
}
function fetchDoctorInfoError(state, action) {
  return { ...state, doctor_info_loading: false, doctor_info_error: true };
}

function fetchActionRequiredStart(state, action) {
  const action_required = action.doctor_id !== state.doctor_id ? [] : state.action_required;
  const action_required_total = action.doctor_id !== state.doctor_id ? 0 : state.action_required_total;
  return { ...state, action_required_loading: true, action_required, doctor_id: action.doctor_id, action_required_total };
}

function fetchActionRequiredSuccess(state, action) {
  const { total, action_required } = action.data;
  let new_action_required = state.action_required;
  if (action.reload || state.action_required_total !== total) {
    new_action_required = action_required;
  } else {
    new_action_required = [...state.action_required, ...action_required];
  }
  return {
    ...state,
    action_required: new_action_required,
    action_required_total: total,
    action_required_loading: false,
  };
}

function fetchActionRequiredError(state, action) {
  return { ...state, action_required_loading: false, action_required_error: true };
}

function fetchActivityStart(state, action) {
  const activity = action.doctor_id !== state.doctor_id ? [] : state.activity;
  const activity_total = action.doctor_id !== state.doctor_id ? 0 : state.activity_total;
  return { ...state, activity_loading: true, activity, doctor_id: action.doctor_id, activity_total };
}

function fetchActivitySuccess(state, action) {
  const { total, activity } = action.data;
  let new_activity = state.activity;
  if (action.reload || state.activity_total !== total) {
    new_activity = activity;
  } else {
    new_activity = [...state.activity, ...activity];
  }
  return {
    ...state,
    activity: new_activity,
    activity_total: total,
    activity_loading: false,
  };
}

function fetchActivityError(state, action) {
  return { ...state, activity_loading: false, activity_error: true };
}

export const getDoctorStats = (state) => state.dashboardReducer.stats;
export const getProviderTier = (state) => state.dashboardReducer.provider_tier;
export const getProviderTierLoading = (state) => state.dashboardReducer.provider_tier_loading;
export const getDoctorInfo = (state) => state.dashboardReducer.doctor_info;
export const getDoctorInfoLoading = (state) => state.dashboardReducer.doctor_info_loading;
export const getDoctorInfoError = (state) => state.dashboardReducer.doctor_info_error;
export const getActionRequiredList = (state) => state.dashboardReducer.action_required;
export const getActionRequiredTotal = (state) => state.dashboardReducer.action_required_total;
export const getActionRequiredLoading = (state) => state.dashboardReducer.action_required_loading;
export const getActivityList = (state) => state.dashboardReducer.activity;
export const getActivityTotal = (state) => state.dashboardReducer.activity_total;
export const getActivityLoading = (state) => state.dashboardReducer.activity_loading;
