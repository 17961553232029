import { FETCH_PROSPECTS_PENDING, FETCH_PROSPECTS_SUCCESS, FETCH_PROSPECTS_ERROR } from '../../actions/prospects/prospects';

const initialState = {
  prospects: [],
  prospects_count: 0,
  pending: true,
  error: false,
  status_count: {
    all: 0,
    action: 0,
    hold: 0,
    consult: 0,
    schedule: 0,
    begin_submission: 0,
    submitted: 0,
    lost: 0,
  },
};

export function prospectsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROSPECTS_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_PROSPECTS_SUCCESS:
      return {
        ...state,
        pending: false,
        prospects: action.prospects,
        prospects_count: action.prospects_count,
        status_count: action.status_count,
      };

    case FETCH_PROSPECTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export const getProspects = (state) => state.prospectsReducer.prospects;
export const getProspectsCount = (state) => state.prospectsReducer.prospects_count;
export const getPending = (state) => state.prospectsReducer.pending;
export const getError = (state) => state.prospectsReducer.error;
export const getStatusCount = (state) => state.prospectsReducer.status_count;
