import _ from 'lodash';
import Axios from 'axios';
import { removeIreqIdInitialNumber } from '../../common/functions';
// nomenclature
import { getLookup } from '../../common/nomenclature';
// Action Types
export const FETCH_AWB_SUCCESS = 'FETCH_AWB_SUCCESS';
export const EDIT_AWB_MISSING_TEETH_MEASUREMENTS_MODAL = 'EDIT_AWB_MISSING_TEETH_MEASUREMENTS_MODAL';
export const API_CALL_PENDING = 'API_CALL_PENDING';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const OPEN_AWB_MISSING_TEETH_MEASUREMENTS_MODAL = 'OPEN_AWB_MISSING_TEETH_MEASUREMENTS_MODAL';
export const SET_SPACING = 'SET_SPACING';
export const SET_SELECTION = 'SET_SELECTION';
export const SET_MEASUREMENTS_ERROR_TRUE = 'SET_MEASUREMENTS_ERROR_TRUE';
export const SET_MEASUREMENTS_ERROR_FALSE = 'SET_MEASUREMENTS_ERROR_FALSE';

// Action Creators
// -----------------------------------------------------------------------------

//CLOSE MODAL

export function toggleModal(modal_name, modal_value) {
  if (modal_name === 'missing_teeth_measurements_modal' && !modal_value) {
    return (dispatch, getState) => {
      dispatch(setMeasurementsErrorFalse());
      dispatch(sendToggleModal(modal_name, modal_value));
    };
  } else {
    return {
      type: TOGGLE_MODAL,
      modal_name,
      modal_value,
    };
  }
}
export function sendToggleModal(modal_name, modal_value) {
  return {
    type: TOGGLE_MODAL,
    modal_name,
    modal_value,
  };
}
// FETCH
export function fetchAwb(ireq_id) {
  return (dispatch, getState) => {
    dispatch(apiCallPending());
    let selections = [];
    const ITEM_PART_LOOKUP = getLookup(ireq_id, true); // nomenclature lookup
    Axios.get('/apiv3/ireqautomationstatus/' + ireq_id + '/awb')
      .then((res) => {
        const awb = res.data.awb;
        awb.ireq_parts = _.orderBy(awb.ireq_parts, ['seq_no'], ['asc']); // Use Lodash to sort array by 'name'
        try {
          if (selections.length === 0 && awb && awb.ireq_parts && awb.ireq_parts.length > 0) {
            selections = awb.ireq_parts;
            selections.forEach(function (element) {
              element.state = true;
              element.description = ITEM_PART_LOOKUP[element.parts_id];
            });
          }
        } catch (error) {
          console.log(error);
        }
        dispatch(fetchAwbSuccess(awb, selections));
        return awb;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

export function fetchAwbSuccess(awb, selections) {
  return {
    type: FETCH_AWB_SUCCESS,
    awb: awb,
    selections: selections,
  };
}

// Post call after convert button has been clicked
export function postConvertAwb(ireq_id, awb) {
  let wire_selection = [];
  awb.ireq_parts.forEach((item_part) => {
    wire_selection.push(item_part.description);
  });
  let upper = getsmartWire(awb.ireq_parts, 'Upper');
  let lower = getsmartWire(awb.ireq_parts, 'Lower');
  const lower_straight = getsmartWire(awb.ireq_parts, 'Straight');
  let status_comment = {
    case_id: removeIreqIdInitialNumber(ireq_id),
    upper: upper,
    lower: lower,
    lower_straight: lower_straight,
    wire_selection: wire_selection,
  };
  const data = { status: 'IREQ AWB Conversion Process', status_comment: status_comment };
  return (dispatch) => {
    dispatch(apiCallPending());
    if (awb.ireq_automation_status.length > 0) {
      awb.ireq_automation_status.forEach((status) => {
        if (status.current_ind) {
          status.status = 'IREQ AWB Conversion Process';
        }
      });
    } else {
      let status = { current_ind: true, id: 1, ireq_id: ireq_id, process_type: 'AWB', status: 'IREQ AWB Conversion Process', status_comment: '' };
      awb.ireq_automation_status.push(status);
    }

    dispatch(fetchAwbSuccess(awb, []));
    dispatch(toggleModal('convert_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/convert', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post mark a success

// Post call after convert button has been clicked
export function onConfirmButtonClickMarkAsSuccess(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('mark_success_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/succeed', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post mark a failed

// Post call after convert button has been clicked
export function onConfirmButtonClickMarkAsFailure(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('mark_failed_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/fail', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Reject

// Post call after reject button has been clicked
export function onConfirmButtonClickReject(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('reject_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/reject', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Transfer

// Post call after reject button has been clicked
export function onConfirmButtonClickTransfer(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/transfer', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('transfer_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Retry

// Post call after Retry button has been clicked
export function onConfirmButtonClickRetry(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/retry', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('retry_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Rework

// Post call after rework button has been clicked
export function onButtonClickRework(ireq_id) {
  return (dispatch, getState) => {
    let { selections } = getState().awbReducer;
    let wire_selection = [];
    let selection = [];
    selections.forEach((item_part) => {
      if (item_part.state) {
        wire_selection.push(item_part.description);
        selection.push(item_part);
      }
    });
    let upper = getsmartWire(selection, 'Upper');
    let lower = getsmartWire(selection, 'Lower');
    const lower_straight = getsmartWire(selection, 'Straight');
    let status_comment = {
      upper: upper,
      lower: lower,
      lower_straight: lower_straight,
      wire_selection: wire_selection,
    };
    const data = { status_comment: status_comment };
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/rework', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('rework_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Override

// Post call after Override button has been clicked
export function onConfirmButtonClickOverride(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/override', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('override_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Process Manual

// Post call after Process Manual button has been clicked
export function onConfirmButtonClickManual(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/processmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('manual_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

// Post call after convert button has been clicked
export function postManualConvertAwb(ireq_id, awb) {
  return (dispatch, getState) => {
    let { awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions, awb } = getState().awbReducer;
    let upper = getsmartWire(awb.ireq_parts, 'Upper');
    let lower = getsmartWire(awb.ireq_parts, 'Lower');
    const lower_straight = getsmartWire(awb.ireq_parts, 'Straight');
    let status_comment = {
      case_id: removeIreqIdInitialNumber(ireq_id),
      missing: awb_missing_teeth_measurements_modal_selected_teeth,
      upper_loop_measurement: measurement_conditions.spacing.spacingUpper,
      lower_loop_measurement: measurement_conditions.spacing.spacingLower,
      lower_straight_loop_measurement: measurement_conditions.spacing.straightLower,
      upper: upper,
      lower: lower,
      lower_straight: lower_straight,
    };
    const data = { status_comment: status_comment };
    awb.ireq_automation_status.forEach((status) => {
      if (status.current_ind) {
        status.status = 'IREQ AWB Manual Process Conversion';
      }
    });
    dispatch(fetchAwbSuccess(awb, []));
    dispatch(apiCallPending());
    dispatch(toggleModal('manual_convert_modal', false));
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/convertmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Manual Reject

// Post call after reject button has been clicked
export function onConfirmButtonClickManualReject(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('manual_reject_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/rejectmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Transfer

// Post call after reject button has been clicked
export function onConfirmButtonClickManualTransfer(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/transfermanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('manual_transfer_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post mark a success

// Post call after convert button has been clicked
export function onConfirmButtonClickManualMarkAsSuccess(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('manual_mark_success_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/succeedmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post mark a failed

// Post call after convert button has been clicked
export function onConfirmButtonClickManualMarkAsFailure(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    dispatch(toggleModal('manual_mark_failed_modal', false));

    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/failmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Retry

// Post call after Retry button has been clicked
export function onConfirmButtonClickManualRetry(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/retrymanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('manual_retry_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Automated Process

//post call after automated process has been clicked
export function onConfirmButtonClickAutomatedProcess(ireq_id) {
  const data = {};
  return (dispatch) => {
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/automatedprocess', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('automated_process_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

//Post Manual Rework

// Post call after rework button has been clicked
export function onButtonClickManualRework(ireq_id) {
  return (dispatch, getState) => {
    let { selections } = getState().awbReducer;
    let wire_selection = [];
    let selection = [];
    selections.forEach((item_part) => {
      if (item_part.state) {
        wire_selection.push(item_part.description);
        selection.push(item_part);
      }
    });
    let upper = getsmartWire(selection, 'Upper');
    let lower = getsmartWire(selection, 'Lower');
    const lower_straight = getsmartWire(selection, 'Straight');
    let status_comment = {
      upper: upper,
      lower: lower,
      lower_straight: lower_straight,
      wire_selection: wire_selection,
    };
    const data = { status_comment: status_comment };
    dispatch(apiCallPending());
    Axios.post('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/reworkmanual', data)
      .then((res) => {
        dispatch(fetchAwb(ireq_id));
        dispatch(apiCallSuccess());
        dispatch(toggleModal('manual_rework_modal', false));
        return res;
      })
      .catch((error) => {
        dispatch(apiCallError(error));
      });
  };
}

// Open Missing Teeth & IP Loop Measurements Modal

export function openAwbMissingTeethMeasurementsModal(heading, selected_teeth, upper_measurements, lower_measurements, lower_straight_measurements) {
  return {
    type: OPEN_AWB_MISSING_TEETH_MEASUREMENTS_MODAL,
    awb_missing_teeth_measurements_modal_heading: heading,
    awb_missing_teeth_measurements_modal_selected_teeth: selected_teeth,
    awb_missing_teeth_measurements_modal_upper_measurements: upper_measurements,
    awb_missing_teeth_measurements_modal_lower_measurements: lower_measurements,
    awb_missing_teeth_measurements_modal_lower_straight_measurements: lower_straight_measurements,
  };
}

export function editAwbMissingTeethMeasurementsModal(awb_missing_teeth_measurements_modal_selected_teeth) {
  return {
    type: EDIT_AWB_MISSING_TEETH_MEASUREMENTS_MODAL,
    awb_missing_teeth_measurements_modal_selected_teeth: awb_missing_teeth_measurements_modal_selected_teeth,
  };
}

// On Tooth select in Missing Teeth & IP Loop Measurements Modal

export function onToothSelectAwb(key, idx) {
  return (dispatch, getState) => {
    const { awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions } = getState().awbReducer;

    let selected_teeth_updates = _.cloneDeep(awb_missing_teeth_measurements_modal_selected_teeth);
    let conditions = _.cloneDeep(measurement_conditions);

    // If measurement was previously entered for the missing tooth, clear the measurement
    dispatch(clearMeasurementsForMissingTeeth(conditions, key, idx));

    if (selected_teeth_updates.indexOf(key) !== -1) {
      const index = selected_teeth_updates.indexOf(key);
      if (index > -1) {
        selected_teeth_updates.splice(index, 1);
      }
    } else {
      selected_teeth_updates.push(key);
    }

    dispatch(editAwbMissingTeethMeasurementsModal(selected_teeth_updates));
  };
}

export function clearMeasurementsForMissingTeeth(conditions, key, idx) {
  return (dispatch) => {
    const hidden_lower_straight = ['LR1', 'LR2', 'LL1', 'LL2'];
    if (!key.includes('R')) {
      idx += 8;
    }
    if (key.includes('U')) {
      if (idx !== 15) {
        conditions.spacing.spacingUpper[idx] = '';
      }
      conditions.spacing.spacingUpper[idx - 1] = '';
    } else {
      if (idx !== 15) {
        conditions.spacing.spacingLower[idx] = '';
      }
      conditions.spacing.spacingLower[idx - 1] = '';

      if (hidden_lower_straight.indexOf(key) === -1) {
        if (idx !== 15) {
          conditions.spacing.straightLower[idx] = '';
        }
        conditions.spacing.straightLower[idx - 1] = '';
      }
    }
    dispatch(setSpacing(conditions));
  };
}

// Put call after apply button has been clicked
export function onConfirmButtonClickMissingTeethMeasurements(ireq_id) {
  return (dispatch, getState) => {
    let { awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions, awb } = getState().awbReducer;
    let upper = getsmartWire(awb.ireq_parts, 'Upper');
    let lower = getsmartWire(awb.ireq_parts, 'Lower');
    const lower_straight = getsmartWire(awb.ireq_parts, 'Straight');
    let measurements_error = false;
    const smartwires = getSmartwires(awb);

    // Determine if there are missing measurements
    if (
      (smartwires.hasUpper &&
        determineMissingMeasurements(awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions.spacing.spacingUpper, 'upper')) ||
      (smartwires.hasLower &&
        determineMissingMeasurements(awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions.spacing.spacingLower, 'lower')) ||
      (smartwires.hasLowerST &&
        determineMissingMeasurements(awb_missing_teeth_measurements_modal_selected_teeth, measurement_conditions.spacing.straightLower, 'lowerst'))
    ) {
      measurements_error = true;
    }

    if (measurements_error) {
      dispatch(setMeasurementsErrorTrue());
    } else {
      dispatch(setMeasurementsErrorFalse());
      let status_comment = {
        missing: awb_missing_teeth_measurements_modal_selected_teeth,
        upper_loop_measurement: measurement_conditions.spacing.spacingUpper,
        lower_loop_measurement: measurement_conditions.spacing.spacingLower,
        lower_straight_loop_measurement: measurement_conditions.spacing.straightLower,
        upper: upper,
        lower: lower,
        lower_straight: lower_straight,
      };
      const data = { status_comment: status_comment };
      dispatch(apiCallPending());
      Axios.put('/apiv3/ireqautomationstatus/' + ireq_id + '/awb/saveinput', data)
        .then((res) => {
          dispatch(toggleModal('missing_teeth_measurements_modal', false));
          dispatch(fetchAwb(ireq_id));
          dispatch(apiCallSuccess());
          return res;
        })
        .catch((error) => {
          dispatch(apiCallError(error));
        });
    }
  };
}

export function setMeasurementsErrorTrue() {
  return {
    type: SET_MEASUREMENTS_ERROR_TRUE,
  };
}
export function setMeasurementsErrorFalse() {
  return {
    type: SET_MEASUREMENTS_ERROR_FALSE,
  };
}

function determineMissingMeasurements(missing_teeth, measurements, mode) {
  let right_teeth = [];
  let left_teeth = [];
  let full_teeth = [];
  if (mode === 'upper') {
    right_teeth = _.range(8, 0, -1).map((item) => 'UR' + item);
    left_teeth = _.range(1, 9).map((item) => 'UL' + item);
    full_teeth = right_teeth.concat(left_teeth);

    for (let tooth of missing_teeth.filter((tn) => tn.includes('U'))) {
      full_teeth[full_teeth.indexOf(tooth)] = '';
    }
  } else {
    right_teeth = _.range(8, 0, -1).map((item) => 'LR' + item);
    left_teeth = _.range(1, 9).map((item) => 'LL' + item);
    full_teeth = right_teeth.concat(left_teeth);

    if (mode === 'lower') {
      for (let tooth of missing_teeth.filter((tn) => !tn.includes('U'))) {
        full_teeth[full_teeth.indexOf(tooth)] = '';
      }
    } else if (mode === 'lowerst') {
      const hidden_teeth = ['LR2', 'LR1', 'LL1', 'LL2']; // These teeth are always hidden on the Lower ST chart
      let missing_lowerst_teeth = _.cloneDeep(missing_teeth);

      for (let tooth of hidden_teeth) {
        if (missing_lowerst_teeth.indexOf(tooth) === -1) {
          missing_lowerst_teeth.push(tooth);
        }
      }

      for (let tooth of missing_lowerst_teeth.filter((tn) => !tn.includes('U'))) {
        full_teeth[full_teeth.indexOf(tooth)] = '';
      }
    }
  }

  // List of indexes in the measurements that are allowed to have an empty value
  let allowed_empty = [0, 14];
  for (let i = 1; i < full_teeth.length - 2; i++) {
    if (full_teeth[i] === '' || isLastTooth(i, full_teeth)) {
      allowed_empty.push(i);
    }
  }

  for (let j = 0; j < measurements.length; j++) {
    if ((!measurements[j] || measurements[j] < 13 || measurements[j] > 30) && allowed_empty.indexOf(j) === -1) {
      if (mode === 'lowerst' && j === determineLowerStMiddleTooth(missing_teeth) && measurements[j] && allowed_empty.indexOf(j) === -1) {
      } else {
        return true;
      }
    }
  }
  return false;
}

function determineLowerStMiddleTooth(missing_teeth) {
  if (missing_teeth.length === 0) {
    return 5;
  } else if (missing_teeth.includes('LR3')) {
    let return_value = 4;
    if (missing_teeth.includes('LR4')) {
      return_value = return_value - 1;
      if (missing_teeth.includes('LR5')) {
        return_value = return_value - 1;
        if (missing_teeth.includes('LR6')) {
          return_value = return_value - 1;
          if (missing_teeth.includes('LR7')) {
            return 100;
          } else {
            return return_value;
          }
        } else {
          return return_value;
        }
      } else {
        return return_value;
      }
    } else {
      return return_value;
    }
  } else {
    return 5;
  }
}

function isLastTooth(idx, teeth) {
  const remaining_teeth = teeth.slice(idx + 1).filter((t) => t !== 'UL8' && t !== 'LL8');
  const missing_teeth = remaining_teeth.filter((t) => !t);

  return missing_teeth.length === remaining_teeth.length;
}

function getSmartwires(awb) {
  const smartwires = awb.ireq_parts;
  let hasUpper = false;
  let hasLower = false;
  let hasLowerST = false;

  for (let smartwire of smartwires) {
    if (smartwire.description.includes('Upper')) {
      hasUpper = true;
    } else if (smartwire.description.includes('Lower') && !smartwire.description.includes('Straight')) {
      hasLower = true;
    } else if (smartwire.description.includes('Lower') && smartwire.description.includes('Straight')) {
      hasLowerST = true;
    }
  }

  return {
    hasUpper: hasUpper,
    hasLower: hasLower,
    hasLowerST: hasLowerST,
  };
}

// On selection Change Rework

export function onSelectionChangeRework(id) {
  return (dispatch, getState) => {
    const { selections } = getState().awbReducer;
    let selections_editable = _.cloneDeep(selections);
    selections_editable.forEach(function (element) {
      if (element.parts_id === id) {
        element.state = !element.state;
      }
    });
    dispatch(setSelections(selections_editable));
  };
}

export function setSelections(selections) {
  return {
    type: SET_SELECTION,
    selections: selections,
  };
}

export function apiCallPending() {
  return {
    type: API_CALL_PENDING,
  };
}

export function apiCallSuccess() {
  return {
    type: API_CALL_SUCCESS,
  };
}

export function apiCallError(error) {
  return {
    type: API_CALL_ERROR,
    error: error,
  };
}

export function onChangeSpacingValue(index, event, mode) {
  return (dispatch, getState) => {
    let { measurement_conditions } = getState().awbReducer;
    let spacingUpper = measurement_conditions.spacing.spacingUpper.slice();
    let spacingLower = measurement_conditions.spacing.spacingLower.slice();
    let straightLower = measurement_conditions.spacing.straightLower.slice();
    if (mode === 'lowerst') {
      straightLower[index] = formatMMEntry('spacing', 'straightLower', event.target.value, index, measurement_conditions);
    } else if (mode === 'upper') {
      spacingUpper[index] = formatMMEntry('spacing', 'spacingUpper', event.target.value, index, measurement_conditions);
    } else {
      spacingLower[index] = formatMMEntry('spacing', 'spacingLower', event.target.value, index, measurement_conditions);
    }

    let conditions = { ...measurement_conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;
    conditions.spacing.straightLower = straightLower;
    dispatch(setSpacing(conditions));
  };
}

export function getsmartWire(item_parts, type) {
  let types = ['Straight'];
  types = types.filter((e) => e !== type);
  let return_array = [];
  item_parts.forEach((part) => {
    if (part.description.includes(type) && types.filter((e) => part.description.includes(e)).length === 0) {
      if (part.description.includes('16')) {
        return_array.push('16');
      } else if (part.description.includes('14')) {
        return_array.push('14');
      }
    }
  });
  return return_array;
}
export function onChangeSpacingOnBlur(index, event, mode) {
  return (dispatch, getState) => {
    let { measurement_conditions } = getState().awbReducer;

    let spacingUpper = measurement_conditions.spacing.spacingUpper.slice();
    let spacingLower = measurement_conditions.spacing.spacingLower.slice();
    let straightLower = measurement_conditions.spacing.straightLower.slice();
    if (mode === 'lowerst') {
      straightLower[index] = event.target.value;
    } else if (mode === 'upper') {
      spacingUpper[index] = event.target.value;
    } else {
      spacingLower[index] = event.target.value;
    }

    let conditions = { ...measurement_conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;
    conditions.spacing.straightLower = straightLower;

    dispatch(setSpacing(conditions));
  };
}

export function formatMMEntry(cond = null, group = null, value, index = null, conditions) {
  // Value should be XX
  const prevCond = index !== null && index >= 0 ? conditions[cond][group][index] : conditions[cond][group];

  let formattedValue = value.replace(/[^0-9.]/g, '').replace('.', '');

  //max character limit
  if (value.length > 2) {
    formattedValue = prevCond;
  }

  return formattedValue;
}

export function setSpacing(measurement_conditions) {
  return {
    type: SET_SPACING,
    measurement_conditions: measurement_conditions,
  };
}
