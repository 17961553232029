import React from 'react';
import { Check, Exc, InBrace } from '../assets/icons';

const checkIcon = <Check className="case-detail__status__icon" />;
const checkGrayIcon = <Check className="case-detail__status__icon case-detail__status__icon--inactive" />;
const exclamationIcon = <Exc className="case-detail__status__icon case-detail__status__icon--alert" />;
const warningIcon = <Exc className="case-detail__status__icon case-detail__status__icon--warning" />;
const inLogo = <InBrace className="case-detail__status__icon case-detail__status__icon--inbrace" />;

/**
 * Gets icon to display in case progress bar
 * @function
 * @param {Object} item - Current case status
 * @return {JSX} JSX element for icon
 */
function getIcon(item) {
  if (item.isCompleted) return checkIcon;
  else if (item.isCancelled) return checkGrayIcon;
  else if (item.isHold || item.isPendingClarification) return warningIcon;
  else if (item.actionRequired) return exclamationIcon;
  else if (item.status) return inLogo;
  else return checkGrayIcon;
}

function getClassNames(item) {
  const className = item.current ? ' case-detail__status--active' : '';
  return `case-detail__col case-detail__status${className}`;
}

/**
 * Gets class name for status text
 * @function
 * @param {Object} item - Current case status
 * @return {String} Class name
 */
function getTextClassName(item) {
  if (item.actionRequired || item.isCancelled) return 'case-detail__status__text--alert';
  return 'case-detail__status__text';
}

function CaseProgress(props) {
  const { items, hideProgressBar, children } = props;
  const completedItems = items.reduce((acc, curr) => (curr.isCompleted ? acc + 1 : acc), 0.5);
  const completedPercent = Math.min(((completedItems / items.length) * 100).toFixed(0), 100);
  const hideBar = hideProgressBar || completedPercent >= 100;

  return (
    <div className="case-progress">
      {!hideBar && (
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped active"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow={completedPercent}
            style={{ width: `${completedPercent}%` }}
          />
        </div>
      )}
      <div className="case-detail__status-container">
        {items.map((item) => (
          <div className={getClassNames(item)} key={item.title}>
            <div className="case-detail__status__title">{item.title}</div>
            {getIcon(item)}
            <div className={getTextClassName(item)}>
              {item.status && <div>{item.status}</div>}
              {item.date && <div>{item.date}</div>}
              {item.note && <div>{item.note}</div>}
              {item.current && children}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CaseProgress;
