import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useComputed } from '@preact/signals-react';

import './styles.scss';
import { dismissNotification, notificationsSignal } from './signals/notifications';
import { NotificationItem } from './notification-item/notification-item';

export const Notifications = ({ containerCls, itemCls, visibleCount }) => {
  const notifications = useComputed(() => notificationsSignal.value.slice(0, visibleCount));

  if (!notifications.value.length) {
    return null;
  }

  return (
    <div className={cn('notifications-container', containerCls)}>
      {notifications.value.map((notification) => (
        <NotificationItem
          key={notification.id}
          type={notification.type}
          visibleTimeMs={notification.visibleTimeMs}
          className={itemCls}
          onDismiss={() => dismissNotification(notification.id)}
        >
          {notification.message}
        </NotificationItem>
      ))}
    </div>
  );
};

Notifications.propTypes = {
  visibleCount: PropTypes.number,
  containerCls: PropTypes.string,
  itemCls: PropTypes.string,
};

Notifications.defaultProps = {
  visibleCount: 1,
};
