// External
import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

//Internal
import './wire_manufacturing.scss';
import WireManufacturingProcess from './wire_manufacturing_process';
import Modal from '../../../components/modal/modal';
import { removeCaseIdInitialNumber, convertDate } from '../../../common/functions';
import { isCaseBlocked } from '../../../common/helpers';
import { handleHttpRequestError } from '../../../common/error';
import { getWireManufacturingStatus, getWireManufacturingStatusLog } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import {
  fetchWireManufacturingStatus,
  setWireManufacturingStatus,
  setWireManufacturingStatusLog,
} from '../../../redux/actions/bpp/wire_selection/wire_selection';
import { fetchWireFabricationReviewStatus } from '../../../redux/actions/bpp/wire_selection/wire_fabrication_review';
import { userHasPermission } from '../../../common/permission';
import SessionExpire from '../../../common/session_expire';
class WireManufacturingContainer extends Component {
  state = {
    modal: '',
    mark_picked_up_permission: false,
    mark_ready_permission: false,
    refresh: false,
  };

  componentDidMount() {
    this.props.fetchWireManufacturingStatus(this.props.case_id);
    this.setPermissions();
  }

  /**
   * Set Permissions For Wire Manufacturing Process
   */
  setPermissions = () => {
    Axios.get('/apiV2/permission').then((res) => {
      const mark_ready_permission = userHasPermission('CASE_WIRE_MARK_READY', res.data.permission_list_raw);
      const mark_picked_up_permission = userHasPermission('CASE_WIRE_MARK_PICKUP', res.data.permission_list_raw);
      this.setState({
        mark_ready_permission: mark_ready_permission,
        mark_picked_up_permission: mark_picked_up_permission,
      });
    });
  };

  /**
   * Sets modal state
   * @function
   * @param {string} modal - Modal type
   */
  setModal = (modal) => {
    this.setState({
      modal: modal,
    });
  };

  /**
   * Clears modal state
   * @function
   */
  clearModal = () => {
    this.setState({
      modal: '',
    });
  };

  /**
   * Displays modal
   * @function
   * @returns {JSX} - JSX for modal
   */
  displayModal = () => {
    const that = this;
    const { modal } = this.state;
    const case_id = removeCaseIdInitialNumber(this.props.case_id);

    const modals = {
      ready_for_pickup: {
        preset: 'decision',
        header_text: `Ready For Pick Up - ${case_id}`,
        message_text: `Are you sure you want to mark this case as ready for pick up?`,
        close_btn_text: 'Cancel',
      },
      complete_pickup: {
        preset: 'decision',
        header_text: `Wire Picked Up - ${case_id}`,
        message_text: `Are you sure you want to mark this case as picked up?`,
        close_btn_text: 'Cancel',
      },
      wm_log: {
        preset: 'log',
        log_type: 'wire_manufacturing',
        header_text: 'Wire Manufacturing Log',
        case_id: this.props.case_id,
        selected_tab: 'case',
      },
    };

    if (modal in modals) {
      return (
        <Modal
          theme="bpp"
          confirm_btn_text="Confirm"
          onCloseButtonClick={this.clearModal}
          onConfirmButtonClick={() => {
            that.wmAction(modal);
          }}
          {...modals[modal]}
        />
      );
    }

    return null;
  };

  /**
   * Performs wire manufacturing action
   * @function
   * @param {string} action - Action type
   */
  wmAction = (action) => {
    const that = this;
    Axios.post(`/apiv3/wiremanufacturing/${this.props.case_id}/${action}`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({ ...res.data, modal: '' });
          that.props.setWireManufacturingStatus(res.data.wm_status);
          that.props.setWireManufacturingStatusLog(res.data.wm_status_log);
          if (action === 'complete_pickup') {
            that.props.reloadInformation();
            that.props.fetchWireFabricationReviewStatus(that.props.case_id);
          }
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Displays loading text
   * @function
   * @returns {JSX} - JSX for loading text
   */
  loadingText = () => {
    return <div className="center-text">Loading...</div>;
  };

  /**
   * Displays wire manufacturing completion
   * @function
   * @returns {JSX} - JSX for loading text
   */
  displayWMComplete = () => {
    return (
      <>
        {this.props.wm_status_log.map((status, index) => (
          <div key={index}>
            <span className="bold-text">Wire Received</span> {convertDate(status.date)} <span className="bold-text">By</span> {status.user}
            {status.rework_no ? ` (Rework ${status.rework_no})` : null}
          </div>
        ))}
      </>
    );
  };

  /**
   * Displays screen based on wire manufacturing stage
   * @function
   * @returns {JSX} - JSX for wire manufacturing display
   */
  selectDisplayByStatus = () => {
    const wm_status = this.props.wm_status;
    const wm_props = {
      ...this.state,
      case_id: this.props.case_id,
      case_details: this.props.case_details,
      wm_status: wm_status,
      is_case_blocked: isCaseBlocked(this.props.case_status),
      setModal: this.setModal,
    };

    const displayByStatus = {
      '': this.loadingText(),
      'Wire Cutting': <WireManufacturingProcess {...wm_props} />,
      'Wire Awaiting Pickup': <WireManufacturingProcess {...wm_props} />,
      'WM Complete': this.displayWMComplete(),
    };

    return wm_status in displayByStatus ? displayByStatus[wm_status] : this.loadingText();
  };

  /**
   * Determines if wire manufacturing section should be displayed
   * @function
   * @returns {boolean} - True or false
   */
  shouldDisplayWireManufacturing = () => {
    const valid_statuses = ['Wire Cutting', 'Wire Awaiting Pickup', 'WM Complete'];
    return valid_statuses.indexOf(this.props.wm_status) !== -1;
  };

  render() {
    return (
      <>
        {this.shouldDisplayWireManufacturing() && (
          <div className="wire-manufacturing-container">
            <div className="bold-text business-dark-heading">
              Wire Manufacturing
              <div
                className="pull-right btn-light-link"
                data-case_id={this.props.case_id}
                onClick={() => this.setModal('wm_log')}
                data-toggle="tooltip"
                data-placement="top"
                title="Wire Manufacturing Log"
              >
                <i className="fa fa-file-text-o" aria-hidden="true" />
              </div>
            </div>
            <div className="business-dark-theme">{this.selectDisplayByStatus()}</div>
            {this.displayModal()}
            {this.state.refresh && <SessionExpire />}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    wm_status: getWireManufacturingStatus(state),
    wm_status_log: getWireManufacturingStatusLog(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWireManufacturingStatus: fetchWireManufacturingStatus,
      fetchWireFabricationReviewStatus: fetchWireFabricationReviewStatus,
      setWireManufacturingStatus: setWireManufacturingStatus,
      setWireManufacturingStatusLog: setWireManufacturingStatusLog,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WireManufacturingContainer);
