import React from 'react';

/**
 * Contains the backdrop for the setup viewer used for modal
 * @component
 * @alias SetupViewerBackdrop
 */
export default function Backdrop(props) {
  return (
    <div
      className={'setup-viewer-backdrop'}
      onClick={props.onClick}
      style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0',
      }}
    ></div>
  );
}
