/**
 * File: add_address.js - A component that is apart of the add account process
 * which is used for constructing the address information
 * Copyright: (c) Copyright June 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './add_address.scss';
// External Libs
import React, { Component } from 'react';
import Axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Internal Constants
import { US_STATES } from '../../common/constants';
import { COUNTRIES } from '../../common/constants/countries';
import { UNITED_STATES } from '../../common/constants/countries';
import { removeEmoji } from '../../common/functions';
import { getPhoneCode } from '../../common/validation/phone_validation';

// Internal Functions
import {
  firstLetterCapital,
  formatState,
  removeNumberChar,
  removeSpaces,
  removeSpecialChar,
  zipNumberChar,
  addressIsChanged,
  isValidSocialMedia,
  isValidEmail,
} from '../../common/functions';
import ValidationUtils from '../../common/validation/validation-utils';

import { isValidState, isValidCountry } from '../../common/validation/address_validation';
import { formatPhoneNumber, isPhoneNumberValid } from '../../common/validation/phone_validation';

// Internal Components
import Datalist from '../../components/form/list/datalist';
import Modal from '../../components/modal/modal';
import TextInput from '../../components/form/text/text_input';

// Redux Reducers
import {
  getInvalidAddressModal,
  getAddressSuggestions,
  getSelectedAddressId,
  getOriginalAddressSelected,
  getEditAddress,
  getSmartyStreetsError,
} from '../../redux/reducers/bpp/account/add_account';

// Redux Actions
import { openInvalidAddressModal, closeInvalidAddressModal, openSmartyStreetsErrorModal } from '../../redux/actions/bpp/account/add_account';

class AddAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      value: '',
      type: 'Office',
      notification: true,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        country_code: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
        facebook_url: '',
        instagram_url: '',
        address_email: '',
      },
      new_profile: {
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        country_code: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
        facebook_url: '',
        instagram_url: '',
        address_email: '',
      },
      validate_address_pending: false,
    };

    this.onChangeNewProfileItem = this.onChangeNewProfileItem.bind(this);
    this.onNewProfileItemConfirm = this.onNewProfileItemConfirm.bind(this);
    this.onNewProfileCancel = this.onNewProfileCancel.bind(this);
    this.onChangeNewProfilePractice = this.onChangeNewProfilePractice.bind(this);
    this.onChangeNewProfileDoctorLoc = this.onChangeNewProfileDoctorLoc.bind(this);
    this.onChangeEditProfileItem = this.onChangeEditProfileItem.bind(this);
    this.onChangeEditProfilePractice = this.onChangeEditProfilePractice.bind(this);
    this.onChangeEditProfileDoctorLoc = this.onChangeEditProfileDoctorLoc.bind(this);
    this.isNewAddress = this.isNewAddress.bind(this);
    this.saveAddressSuggestion = this.saveAddressSuggestion.bind(this);
    this.saveOriginalAddress = this.saveOriginalAddress.bind(this);
    this.saveAddressSuggestionAfterEditing = this.saveAddressSuggestionAfterEditing.bind(this);
    this.saveOriginalAddressAfterEditing = this.saveOriginalAddressAfterEditing.bind(this);
  }

  isNewAddress(profile, id = '') {
    const address = this.props.addressList;
    let is_new = true;

    for (let i = 0; i < address.length; i++) {
      const address_zip = address[i].zip.includes('-') ? address[i].zip.substring(0, address[i].zip.indexOf('-')) : address[i].zip;
      const profile_zip = profile.zip.includes('-') ? profile.zip.substring(0, profile.zip.indexOf('-')) : profile.zip;
      if (
        parseInt(id) !== i &&
        address[i].address_line_1.toLowerCase() === profile.address_line_1.toLowerCase() &&
        address[i].address_line_2.toLowerCase() === profile.address_line_2.toLowerCase() &&
        address[i].city.toLowerCase() === profile.city.toLowerCase() &&
        address_zip === profile_zip
      ) {
        is_new = false;
        break;
      }
    }

    return is_new;
  }

  componentDidUpdate() {
    if (!this.props.edit_profile.active && this.state.edit_profile.active) {
      this.setState({
        edit_profile: {
          active: false,
          error: false,
          mode: '',
          notification: '',
          type: '',
          value: '',
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
          facebook_url: '',
          instagram_url: '',
          address_email: '',
        },
      });
    } else if (!this.props.new_profile.active && this.state.new_profile.active) {
      this.setState({
        new_profile: {
          active: false,
          error: false,
          mode: '',
          notification: '',
          type: '',
          value: '',
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: UNITED_STATES,
          country_code: '1',
          website: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
          facebook_url: '',
          instagram_url: '',
          address_email: '',
        },
        address_error_msg: '',
      });
    }
  }

  onChangeNewProfilePractice(event) {
    const shipping_only = event.currentTarget.checked;
    let newProfile = this.state.new_profile;
    newProfile.shipping_only = shipping_only;
    if (shipping_only && newProfile.is_doctor_loc) {
      newProfile.is_doctor_loc = false;
    }

    this.setState({
      new_profile: newProfile,
    });
  }

  onChangeNewProfileDoctorLoc(event) {
    const locator = event.currentTarget.checked;
    let newProfile = this.state.new_profile;
    newProfile.is_doctor_loc = locator;
    if (locator && newProfile.shipping_only) {
      newProfile.shipping_only = false;
    }
    this.setState({
      new_profile: newProfile,
    });
  }

  isOnNewProfileItemConfirmValid() {
    const { new_profile } = this.state;
    const isValidPhoneNumber = isPhoneNumberValid(new_profile.address_telephone, new_profile.country);

    const isValidWebsite = new_profile.website === '' || ValidationUtils.isValidURL(new_profile.website);
    const isValidInstagramURL =
      new_profile.instagram_url === null || new_profile.instagram_url === '' || isValidSocialMedia(new_profile.instagram_url, 'instagram');
    const isValidFacebookURL = new_profile.facebook_url === null || new_profile.facebook_url === '' || isValidSocialMedia(new_profile.facebook_url, 'facebook');
    const validEmail = new_profile.address_email === null || new_profile.address_email === '' || isValidEmail(new_profile.address_email);
    const validState = isValidState(new_profile.country, new_profile.state);
    const validCountry = isValidCountry(new_profile.country);

    const isNewAddress = this.isNewAddress(new_profile);
    return (
      new_profile.value !== '' &&
      new_profile.address_line_1 !== '' &&
      isValidPhoneNumber &&
      isValidWebsite &&
      isValidInstagramURL &&
      isValidFacebookURL &&
      validEmail &&
      validCountry &&
      new_profile.city !== '' &&
      validState &&
      new_profile.zip !== '' &&
      this.props.new_profile.mode === 'add_address' &&
      isNewAddress
    );
  }

  /**
   * Handles event when user adds a new item to the profile
   * @function
   * @param {Object} event - Event object
   */
  onNewProfileItemConfirm(event) {
    if (this.isOnNewProfileItemConfirmValid()) {
      var data = {
        address_name: firstLetterCapital(this.state.new_profile.value.trim()),
        address_line_1: firstLetterCapital(this.state.new_profile.address_line_1.trim()),
        address_line_2: firstLetterCapital(this.state.new_profile.address_line_2.trim()),
        address_telephone: formatPhoneNumber(this.state.new_profile.address_telephone, this.state.new_profile.country),
        city: firstLetterCapital(this.state.new_profile.city.trim()),
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        state: formatState(this.state.new_profile.state, this.state.new_profile.country),
        shipping_only: this.state.new_profile.shipping_only,
        current_ind: this.state.new_profile.is_active,
        doc_locator_ind: this.state.new_profile.is_doctor_loc,
        instagram_url: this.state.new_profile.instagram_url,
        facebook_url: this.state.new_profile.facebook_url,
        address_email: this.state.new_profile.address_email,
      };
      this.setState({
        validate_address_pending: true,
      });
      let that = this;
      Axios.post('/apiv3/address/validateaddress', data)
        .then((res) => {
          that.setState({
            validate_address_pending: false,
          });
          if (!res.data.address_is_valid) {
            that.props.openInvalidAddressModal(data, res.data.address_suggestions);
          } else {
            that.saveOriginalAddress(res.data.geo_lat, res.data.geo_long);
          }
        })
        .catch((error) => {
          that.setState({
            validate_address_pending: false,
          });
          that.props.openSmartyStreetsErrorModal();
        });

      this.setState({ error: false });
    } else {
      this.setState({
        error: true,
        address_error_msg:
          this.state.new_profile.value === '' ||
          this.state.new_profile.address_line_1 === '' ||
          this.state.new_profile.city === '' ||
          this.state.new_profile.state === '' ||
          this.state.new_profile.zip === ''
            ? 'Please enter a valid address or ensure the address does not already exist'
            : this.state.new_profile.website !== '' && !ValidationUtils.isValidURL(this.state.new_profile.website)
            ? 'Invalid entries'
            : !isPhoneNumberValid(this.state.new_profile.address_telephone, this.state.new_profile.country)
            ? 'Invalid entries'
            : this.state.new_profile.address_email !== '' && !isValidEmail(this.state.new_profile.address_email)
            ? 'Invalid entries'
            : this.state.new_profile.instagram_url !== '' && !isValidSocialMedia(this.state.new_profile.instagram_url, 'instagram')
            ? 'Invalid entries'
            : this.state.new_profile.facebook_url !== '' && !isValidSocialMedia(this.state.new_profile.facebook_url, 'facebook')
            ? 'Invalid entries'
            : '',
      });
    }
  }

  onNewProfileCancel(event) {
    this.setState((prevState) => ({
      error: false,
      address_error_msg: '',
      new_profile: {
        ...prevState.new_profile,
        country: UNITED_STATES,
        country_code: '1',
      },
    }));
    this.props.onNewProfileCancel(event);
  }

  onChangeNewProfileItem(event) {
    let item = event.target.dataset && event.target.dataset.item ? event.target.dataset.item : 'value';
    let {
      value,
      address_line_1,
      address_line_2,
      address_telephone,
      city,
      state,
      country,
      zip,
      website,
      facebook_url,
      instagram_url,
      address_email,
      country_code,
    } = this.state.new_profile;

    const emojiFreeValue = removeEmoji(event.target.value);

    switch (item) {
      case 'value':
        value = emojiFreeValue;
        break;

      case 'address_line_1':
        address_line_1 = emojiFreeValue;
        break;

      case 'address_line_2':
        address_line_2 = emojiFreeValue;
        break;

      case 'address_telephone':
        address_telephone = emojiFreeValue;
        break;

      case 'city':
        city = emojiFreeValue;
        break;

      case 'state':
        if (country === UNITED_STATES) {
          state = removeNumberChar(removeSpecialChar(emojiFreeValue.toUpperCase()));
          state = state.length > 2 ? state.substring(0, 2) : state;
        } else {
          state = removeSpecialChar(emojiFreeValue);
        }
        break;

      case 'zip':
        if (country === UNITED_STATES) {
          zip = zipNumberChar(emojiFreeValue);
        } else {
          zip = emojiFreeValue.toUpperCase();
        }
        break;

      case 'country':
        country = emojiFreeValue;
        country_code = getPhoneCode(country);
        break;

      case 'website':
        website = removeSpaces(emojiFreeValue);
        break;

      case 'facebook_url':
        facebook_url = removeSpaces(emojiFreeValue);
        break;

      case 'instagram_url':
        instagram_url = removeSpaces(emojiFreeValue);
        break;

      case 'address_email':
        address_email = removeSpaces(emojiFreeValue);
        break;

      default:
    }

    this.setState({
      error: false,
      new_profile: {
        active: true,
        error: false,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: this.state.new_profile.type,
        value: value,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        address_telephone: address_telephone,
        city: city,
        state: state,
        country: country,
        country_code: country_code,
        zip: zip,
        website: website,
        is_active: this.state.new_profile.is_active,
        shipping_only: this.state.new_profile.shipping_only,
        is_doctor_loc: this.state.new_profile.is_doctor_loc,
        instagram_url: instagram_url,
        facebook_url: facebook_url,
        address_email: address_email,
      },
      address_error_msg: '',
    });
  }

  onChangeEditProfilePractice(event) {
    const shipping_only = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.props.edit_profile.id,
        mode: this.props.edit_profile.mode,
        notification: this.props.edit_profile.notification,
        type: this.props.edit_profile.type,
        value: this.props.edit_profile.value,
        address_line_1: this.props.edit_profile.address_line_1,
        address_line_2: this.props.edit_profile.address_line_2,
        address_telephone: this.props.edit_profile.address_telephone,
        city: this.props.edit_profile.city,
        state: this.props.edit_profile.state,
        country: this.props.edit_profile.country,
        zip: this.props.edit_profile.zip,
        website: this.props.edit_profile.website,
        is_active: this.props.edit_profile.is_active,
        shipping_only: shipping_only,
        is_doctor_loc: shipping_only ? false : this.props.edit_profile.is_doctor_loc,
        facebook_url: this.props.edit_profile.facebook_url,
        instagram_url: this.props.edit_profile.instagram_url,
        address_email: this.props.edit_profile.address_email,
      },
    });
    this.props.setSelectedAddress(
      this.props.edit_profile.value,
      this.props.edit_profile.address_line_1,
      this.props.edit_profile.address_line_2,
      this.props.edit_profile.address_telephone,
      this.props.edit_profile.city,
      this.props.edit_profile.state,
      this.props.edit_profile.zip,
      this.props.edit_profile.country,
      this.props.edit_profile.website,
      shipping_only,
      this.props.edit_profile.is_active,
      shipping_only ? false : this.props.edit_profile.is_doctor_loc,
      this.props.edit_profile.id,
      this.props.edit_profile.facebook_url,
      this.props.edit_profile.instagram_url,
      this.props.edit_profile.address_email
    );
  }

  onChangeEditProfileDoctorLoc(event) {
    const locator = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.props.edit_profile.id,
        mode: this.props.edit_profile.mode,
        notification: this.props.edit_profile.notification,
        type: this.props.edit_profile.type,
        value: this.props.edit_profile.value,
        address_line_1: this.props.edit_profile.address_line_1,
        address_line_2: this.props.edit_profile.address_line_2,
        address_telephone: this.props.edit_profile.address_telephone,
        city: this.props.edit_profile.city,
        state: this.props.edit_profile.state,
        country: this.props.edit_profile.country,
        zip: this.props.edit_profile.zip,
        website: this.props.edit_profile.website,
        is_active: this.props.edit_profile.is_active,
        shipping_only: locator ? false : this.props.edit_profile.shipping_only,
        is_doctor_loc: locator,
        facebook_url: this.props.edit_profile.facebook_url,
        instagram_url: this.props.edit_profile.instagram_url,
        address_email: this.props.edit_profile.address_email,
      },
    });
    this.props.setSelectedAddress(
      this.props.edit_profile.value,
      this.props.edit_profile.address_line_1,
      this.props.edit_profile.address_line_2,
      this.props.edit_profile.address_telephone,
      this.props.edit_profile.city,
      this.props.edit_profile.state,
      this.props.edit_profile.zip,
      this.props.edit_profile.country,
      this.props.edit_profile.website,
      locator ? false : this.props.edit_profile.shipping_only,
      this.props.edit_profile.is_active,
      locator,
      this.props.edit_profile.id,
      this.props.edit_profile.facebook_url,
      this.props.edit_profile.instagram_url,
      this.props.edit_profile.address_email
    );
  }

  onChangeEditProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeNewProfileItemKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeEditProfileItem(event) {
    let value = this.props.edit_profile.value;
    let item = event.target.dataset && event.target.dataset.item ? event.target.dataset.item : 'value';
    let address_line_1 = this.props.edit_profile.address_line_1;
    let address_line_2 = this.props.edit_profile.address_line_2;
    let address_telephone = this.props.edit_profile.address_telephone;
    let city = this.props.edit_profile.city;
    let state = this.props.edit_profile.state;
    let country = this.props.edit_profile.country;
    let zip = this.props.edit_profile.zip;
    let website = this.props.edit_profile.website;
    let facebook_url = this.props.edit_profile.facebook_url;
    let instagram_url = this.props.edit_profile.instagram_url;
    let address_email = this.props.edit_profile.address_email;

    const emojiFreeValue = removeEmoji(event.target.value);

    switch (item) {
      case 'value':
        value = event.target.value;
        break;

      case 'address_telephone':
        address_telephone = event.target.value;
        break;

      case 'address_line_1':
        address_line_1 = event.target.value;
        break;

      case 'address_line_2':
        address_line_2 = event.target.value;
        break;

      case 'city':
        city = event.target.value;
        break;

      case 'state':
        if (country === UNITED_STATES) {
          state = removeSpaces(removeNumberChar(removeSpecialChar(event.target.value.toUpperCase())));
          state = state.length > 2 ? state.substring(0, 2) : state;
        } else {
          state = removeSpecialChar(emojiFreeValue);
        }
        break;

      case 'zip':
        if (country == UNITED_STATES) {
          zip = zipNumberChar(emojiFreeValue);
        } else {
          zip = emojiFreeValue.toUpperCase();
        }
        break;

      case 'country':
        country = emojiFreeValue;
        break;

      case 'website':
        website = removeSpaces(event.target.value);
        break;

      case 'facebook_url':
        facebook_url = removeSpaces(event.target.value);
        break;

      case 'instagram_url':
        instagram_url = removeSpaces(event.target.value);
        break;

      case 'address_email':
        address_email = removeSpaces(event.target.value);
        break;

      default:
    }

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.props.edit_profile.id,
        mode: this.props.edit_profile.mode,
        notification: this.props.edit_profile.notification,
        type: this.props.edit_profile.type,
        value: value,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        address_telephone: address_telephone,
        city: city,
        state: state,
        country: country,
        zip: zip,
        website: website,
        facebook_url: facebook_url,
        instagram_url: instagram_url,
        address_email: address_email,
        is_active: this.props.edit_profile.is_active,
        shipping_only: this.props.edit_profile.shipping_only,
        is_doctor_loc: this.props.edit_profile.is_doctor_loc,
      },
    });

    this.props.setSelectedAddress(
      value,
      address_line_1,
      address_line_2,
      address_telephone,
      city,
      state,
      zip,
      country,
      website,
      this.props.edit_profile.shipping_only,
      this.props.edit_profile.is_active,
      this.props.edit_profile.is_doctor_loc,
      this.props.edit_profile.id,
      facebook_url,
      instagram_url,
      address_email
    );
  }

  componentDidMount() {
    this.setState({
      new_profile: this.props.new_profile,
      edit_profile: this.props.edit_profile,
    });
  }
  editEmail = () => {
    document.getElementById('editModal').modal({ show: true });
  };

  onChangeNewProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onEditProfileDelete = () => {
    this.props.onEditProfileDelete(this.props.edit_profile.id);
  };

  onChangeEditEmail = (event) => {
    this.props.onChangeEditEmail(event.target.dataset.id, event.target.value);
  };

  onChangeEditEmailType = (event) => {
    this.props.onChangeEditEmailType(event.target.dataset.id, event.target.value);
  };

  onChangeEditEmailNotification = (event) => {
    this.props.onChangeEditEmailNotification(event.target.dataset.id, event.target.checked);
  };

  onEditClick = (event) => {
    if (!this.props.new_profile.active && this.props.new_profile.mode !== 'add_address') {
      let addressList = this.props.addressList;
      let id = parseInt(event.currentTarget.dataset.id);
      this.props.setSelectedAddress(
        addressList[id].address_name,
        addressList[id].address_line_1,
        addressList[id].address_line_2,
        addressList[id].address_telephone,
        addressList[id].city,
        addressList[id].state,
        addressList[id].zip,
        addressList[id].country,
        addressList[id].website,
        addressList[id].shipping_only,
        addressList[id].current_ind,
        addressList[id].doc_locator_ind,
        id,
        addressList[id].facebook_url,
        addressList[id].instagram_url,
        addressList[id].address_email
      );
    }
  };

  isValidOnEditProfileConfirm = () => {
    const { edit_profile } = this.props;
    const isValidPhoneNumber = isPhoneNumberValid(edit_profile.address_telephone, edit_profile.country);
    const isValidWebsite = edit_profile.website === '' || ValidationUtils.isValidURL(edit_profile.website);
    const isValidFacebookURL =
      edit_profile.facebook_url === null || edit_profile.facebook_url === '' || isValidSocialMedia(edit_profile.facebook_url, 'facebook');
    const isValidInstagramURL =
      edit_profile.instagram_url === null || edit_profile.instagram_url === '' || isValidSocialMedia(edit_profile.instagram_url, 'instagram');
    const isValidEmail = edit_profile.address_email === null || edit_profile.address_email === '' || isValidEmail(edit_profile.address_email);
    const validState = isValidState(edit_profile.country, edit_profile.state);
    const isNewAddress = this.isNewAddress(edit_profile, edit_profile.id);
    const validCountry = isValidCountry(edit_profile.country);

    return (
      edit_profile.value !== '' &&
      edit_profile.address_line_1 !== '' &&
      isValidPhoneNumber &&
      isValidWebsite &&
      isValidFacebookURL &&
      isValidInstagramURL &&
      isValidEmail &&
      edit_profile.city !== '' &&
      validState &&
      edit_profile.zip !== '' &&
      edit_profile.mode === 'edit_address' &&
      isNewAddress &&
      validCountry
    );
  };

  /**
   * Handles event when user saves changes to the profile
   * @function
   * @param {Object} event - Event object
   */
  onEditProfileConfirm = (event) => {
    if (this.isValidOnEditProfileConfirm()) {
      const data = {
        address_name: this.props.edit_profile.value,
        address_line_1: this.props.edit_profile.address_line_1,
        address_line_2: this.props.edit_profile.address_line_2,
        city: this.props.edit_profile.city,
        state: this.props.edit_profile.state,
        zip: this.props.edit_profile.zip,
        country: this.props.edit_profile.country,
      };

      if (addressIsChanged(this.props.addressList[this.props.edit_profile.id], this.props.edit_profile)) {
        this.setState({
          validate_address_pending: true,
        });
        let that = this;
        Axios.post('/apiv3/address/validateaddress', data)
          .then((res) => {
            that.setState({
              validate_address_pending: false,
            });
            if (!res.data.address_is_valid) {
              that.props.openInvalidAddressModal(data, res.data.address_suggestions, true);
            } else {
              that.saveOriginalAddressAfterEditing(res.data.geo_lat, res.data.geo_long);
            }
          })
          .catch((error) => {
            that.setState({
              validate_address_pending: false,
            });
            that.props.openSmartyStreetsErrorModal();
          });
        this.setState({ error: false });
      } else {
        const edit_address = this.props.addressList[this.props.edit_profile.id];
        this.saveOriginalAddressAfterEditing(edit_address.geo_lat, edit_address.geo_long);
      }
    } else {
      this.setState({
        error: true,
        address_error_msg:
          this.props.edit_profile.website !== '' && !ValidationUtils.isValidURL(this.props.edit_profile.website)
            ? 'Invalid entries'
            : !isPhoneNumberValid(this.props.edit_profile.address_telephone, this.props.edit_profile.country)
            ? 'Invalid entries'
            : this.props.edit_profile.address_email !== '' && !isValidEmail(this.props.edit_profile.address_email)
            ? 'Invalid entries'
            : this.props.edit_profile.instagram_url !== '' && !isValidSocialMedia(this.props.edit_profile.instagram_url, 'instagram')
            ? 'Invalid entries'
            : this.props.edit_profile.facebook_url !== '' && !isValidSocialMedia(this.props.edit_profile.facebook_url, 'facebook')
            ? 'Invalid entries'
            : '',
      });
    }
  };

  onEditProfileCancel = (event) => {
    this.setState({ error: false, address_error_msg: '' });

    this.props.onEditProfileCancel();
  };

  /**
   * Formats object based on address suggestion selection to save new address to db
   * @function
   */
  saveAddressSuggestion() {
    const selected_address = this.props.address_suggestions.find((address) => address.id === this.props.selected_address_id);

    const data = {
      address_name: firstLetterCapital(this.state.new_profile.value.trim()),
      address_line_1: firstLetterCapital(selected_address.addr1.trim()),
      address_line_2: firstLetterCapital(selected_address.addr2.trim()),
      address_telephone: formatPhoneNumber(this.state.new_profile.address_telephone, selected_address.country),
      city: firstLetterCapital(selected_address.city.trim()),
      zip: selected_address.zip,
      country: this.state.new_profile.country,
      website: this.state.new_profile.website,
      state: formatState(selected_address.state, this.state.new_profile.country),
      shipping_only: this.state.new_profile.shipping_only,
      current_ind: this.state.new_profile.is_active,
      doc_locator_ind: this.state.new_profile.is_doctor_loc,
      geo_lat: selected_address.geo_lat,
      geo_long: selected_address.geo_long,
      facebook_url: this.state.new_profile.facebook_url,
      instagram_url: this.state.new_profile.instagram_url,
      address_email: this.state.new_profile.address_email,
    };

    if (this.isNewAddress(data)) {
      this.props.onAddNewAddress(data);
    } else {
      this.setState({
        error: true,
        address_error_msg: 'Please enter a valid address or ensure the address does not already exist',
      });
    }
    this.props.closeInvalidAddressModal();
  }

  /**
   * Formats object based on original address input to save new address to db
   * @function
   */
  saveOriginalAddress(geo_lat = '', geo_long = '') {
    if (this.props.address_suggestions && (!geo_lat || !geo_long)) {
      const address_suggestion = this.props.address_suggestions[0];
      geo_lat = address_suggestion.geo_lat;
      geo_long = address_suggestion.geo_long;
    }

    const data = {
      address_name: firstLetterCapital(this.state.new_profile.value.trim()),
      address_line_1: firstLetterCapital(this.state.new_profile.address_line_1.trim()),
      address_line_2: firstLetterCapital(this.state.new_profile.address_line_2.trim()),
      address_telephone: formatPhoneNumber(this.state.new_profile.address_telephone, this.state.new_profile.country),
      city: firstLetterCapital(this.state.new_profile.city.trim()),
      zip: this.state.new_profile.zip,
      country: this.state.new_profile.country,
      website: this.state.new_profile.website,
      instagram_url: this.state.new_profile.instagram_url,
      facebook_url: this.state.new_profile.facebook_url,
      address_email: this.state.new_profile.address_email,
      state: formatState(this.state.new_profile.state, this.state.new_profile.country),
      shipping_only: this.state.new_profile.shipping_only,
      current_ind: this.state.new_profile.is_active,
      doc_locator_ind: this.state.new_profile.is_doctor_loc,
      geo_lat: geo_lat,
      geo_long: geo_long,
    };

    this.props.onAddNewAddress(data);
    this.props.closeInvalidAddressModal();
  }

  /**
   * Formats object based on original address input to save changes to existing address
   * @function
   */
  saveOriginalAddressAfterEditing(geo_lat = '', geo_long = '') {
    this.props.onUpdateAddress(
      this.props.edit_profile.value,
      this.props.edit_profile.address_line_1,
      this.props.edit_profile.address_line_2,
      this.props.edit_profile.address_telephone,
      this.props.edit_profile.city,
      this.props.edit_profile.state,
      this.props.edit_profile.zip,
      this.props.edit_profile.country,
      this.props.edit_profile.website,
      this.props.edit_profile.shipping_only,
      this.props.edit_profile.is_active,
      this.props.edit_profile.is_doctor_loc,
      this.props.edit_profile.id,
      geo_lat,
      geo_long,
      this.props.edit_profile.facebook_url,
      this.props.edit_profile.instagram_url,
      this.props.edit_profile.address_email
    );
    this.props.closeInvalidAddressModal();
  }

  /**
   * Formats objects based on address suggestion selection to save changes to existing address
   * @function
   */
  saveAddressSuggestionAfterEditing() {
    const selected_address = this.props.address_suggestions.find((address) => address.id === this.props.selected_address_id);

    const duplicate_address_validation = {
      address_line_1: firstLetterCapital(selected_address.addr1.trim()),
      address_line_2: firstLetterCapital(selected_address.addr2.trim()),
      city: firstLetterCapital(selected_address.city.trim()),
      zip: selected_address.zip,
      country: selected_address.country,
    };

    if (this.isNewAddress(duplicate_address_validation, this.props.edit_profile.id)) {
      this.props.onUpdateAddress(
        this.props.edit_profile.value,
        firstLetterCapital(selected_address.addr1.trim()),
        firstLetterCapital(selected_address.addr2.trim()),
        this.props.edit_profile.address_telephone,
        firstLetterCapital(selected_address.city.trim()),
        formatState(selected_address.state, selected_address.country),
        selected_address.zip,
        this.props.edit_profile.website,
        this.props.edit_profile.shipping_only,
        this.props.edit_profile.is_active,
        this.props.edit_profile.is_doctor_loc,
        this.props.edit_profile.id,
        selected_address.geo_lat,
        selected_address.geo_long,
        this.props.edit_profile.facebook_url,
        this.props.edit_profile.instagram_url,
        this.props.edit_profile.address_email
      );
    } else {
      this.setState({
        error: true,
        address_error_msg: 'Please enter a valid address or ensure the address does not already exist',
      });
    }
    this.props.closeInvalidAddressModal();
  }

  /**
   * Gets ERP account creation error message
   * @function
   * @param {Object} address - Address object
   * @return {String} ERP error message
   */
  getErpErrorMessage(address) {
    if (this.props.erp_error_info) {
      for (const key in this.props.erp_error_info) {
        const erp_address = this.props.erp_error_info[key].address;
        if (
          address.address_line_1 === erp_address.address_line_1 &&
          address.city === erp_address.city &&
          address.state === erp_address.state &&
          address.zip === erp_address.zip
        ) {
          return this.props.erp_error_info[key].error_message;
        }
      }
    }
    return '';
  }

  render() {
    if (this.props.addressList === null) return null;
    let addressList = this.props.addressList;
    return (
      <div className="col-lg-12">
        <div className="account-heading">Practice Information</div>
        <div className="row">
          {addressList.length > 0 ? (
            addressList.map((address, index) => {
              return (
                <div className="col-lg-6" key={index}>
                  <table
                    className={
                      parseInt(this.props.edit_profile.id) === index && this.props.edit_profile.mode === 'edit_address'
                        ? 'table table-condensed table-industry address-table'
                        : 'table table-condensed table-industry address-table address-table-condensed'
                    }
                  >
                    {parseInt(this.props.edit_profile.id) === index && this.props.edit_profile.mode === 'edit_address' ? (
                      <React.Fragment>
                        <thead className="table-inbrace-dark-industry-2">
                          <tr>
                            <th className="fourth-width-tb" />
                            <th className="fourth-width-tb" />
                            <th className="auto-width-tb" />
                            <th className="edit-width-tb" />
                          </tr>
                        </thead>
                        <tbody className="table-inbrace-dark-industry-2">
                          <tr className="account-start-height">
                            <td className="account-table-first account-label fourth-width-tb account-curve-top-left">ERP ID/Name: </td>
                            <td className="account-curve-top-right erp-input" colSpan="2">
                              <TextInput className="form-control" readOnly={true} />
                              <TextInput className="form-control" readOnly={true} />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Practice Name: </td>
                            <td colSpan="2">
                              <TextInput
                                name="address_name"
                                className={
                                  this.state.error && this.props.edit_profile.value === ''
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.value}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="value"
                                placeholder="Enter Practice Name"
                                autoFocus={true}
                                maxLength={100}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Address: </td>
                            <td colSpan="2" className="country-td">
                              <Datalist
                                className={
                                  this.state.error && this.state.edit_profile.country === ''
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.country}
                                change={this.onChangeEditProfileItem}
                                onKeyDown={this.onChangeEditProfileKeyPress}
                                item="country"
                                list={Object.values(COUNTRIES)}
                                width="100%"
                                placeholder="Select Country"
                                error={this.state.error && this.state.edit_profile.country === ''}
                                name="country"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="fourth-width-tb" />
                            <td colSpan="2">
                              <TextInput
                                name="address_line_1"
                                className={
                                  this.state.error && this.props.edit_profile.address_line_1 === ''
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.address_line_1}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="address_line_1"
                                placeholder="Address Line 1"
                                maxLength={250}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="fourth-width-tb" />
                            <td colSpan="2">
                              <TextInput
                                name="address_line_2"
                                className="form-control business-dark-theme-input"
                                value={this.props.edit_profile.address_line_2}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="address_line_2"
                                placeholder="Address Line 2"
                                maxLength={250}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="fourth-width-tb" />
                            <td colSpan="2">
                              <TextInput
                                name="city"
                                divClass={`account-city account-city${this.props.edit_profile.country === UNITED_STATES ? '--us' : '--ous'}`}
                                className={
                                  this.state.error && this.props.edit_profile.city === ''
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.city}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="city"
                                placeholder="City"
                                maxLength={50}
                              />
                              {this.props.edit_profile.country === UNITED_STATES && (
                                <Datalist
                                  name="state"
                                  className="form-control business-dark-theme-input state-datalist"
                                  fieldsetClass={this.state.error && this.props.edit_profile.state === '' ? 'account-state input-error' : 'account-state'}
                                  value={this.props.edit_profile.state}
                                  change={this.onChangeEditProfileItem}
                                  item="state"
                                  list={Object.keys(US_STATES)}
                                  width="70px"
                                />
                              )}
                            </td>
                            <td />
                          </tr>
                          {this.props.edit_profile.country !== UNITED_STATES && (
                            <tr>
                              <td className="fourth-width-tb" />
                              <td colSpan="2">
                                <TextInput
                                  name="state"
                                  className={
                                    this.state.error && this.props.edit_profile.state === ''
                                      ? 'form-control business-dark-theme-input input-error'
                                      : 'form-control business-dark-theme-input'
                                  }
                                  fieldsetClass={this.state.error && this.state.edit_profile.state === '' ? 'account-state input-error' : 'account-state'}
                                  value={this.props.edit_profile.state}
                                  change={this.onChangeEditProfileItem}
                                  item="state"
                                  placeholder="State / Province / Region"
                                  width="100%"
                                  maxLength={50}
                                />
                              </td>
                              <td />
                            </tr>
                          )}
                          <tr>
                            <td className="fourth-width-tb" />
                            <td className="fourth-width-tb">
                              <TextInput
                                name="zip"
                                className={
                                  this.state.error && this.props.edit_profile.zip === ''
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.zip}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="zip"
                                placeholder="Zip"
                                maxLength={15}
                              />
                            </td>
                            <td />
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Email: </td>
                            <td colSpan="2">
                              <TextInput
                                name="address_email"
                                className={
                                  this.props.edit_profile.error &&
                                  this.props.edit_profile.mode === 'edit_address' &&
                                  this.props.edit_profile.address_email !== null &&
                                  this.props.edit_profile.address_email !== '' &&
                                  !isValidEmail(this.props.edit_profile.address_email)
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.address_email}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="address_email"
                                placeholder="Email (Optional)"
                                maxLength={100}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Country Code: </td>
                            <td colSpan="2">
                              <TextInput
                                name="country_code"
                                readOnly={true}
                                className="form-control business-dark-theme-input"
                                value={getPhoneCode(this.props.edit_profile.country)}
                                item="country_code"
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Telephone: </td>
                            <td colSpan="2">
                              <TextInput
                                prefix={this.state.new_profile.country_code}
                                name="address_telephone"
                                className={
                                  this.state.error && !isPhoneNumberValid(this.props.edit_profile.address_telephone, this.props.edit_profile.country)
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.address_telephone}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="address_telephone"
                                placeholder="Enter Telephone Number"
                                maxLength={20}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Website: </td>
                            <td colSpan="2">
                              <TextInput
                                name="website"
                                className={
                                  this.state.error &&
                                  this.props.edit_profile.website !== null &&
                                  this.props.edit_profile.website !== '' &&
                                  !ValidationUtils.isValidURL(this.props.edit_profile.website)
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.website}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="website"
                                placeholder="Enter Website (Optional)"
                                maxLength={100}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Facebook: </td>
                            <td colSpan="2">
                              <TextInput
                                name="facebook_url"
                                className={
                                  this.props.edit_profile.error &&
                                  this.props.edit_profile.mode === 'edit_address' &&
                                  this.props.edit_profile.facebook_url !== null &&
                                  this.props.edit_profile.facebook_url !== '' &&
                                  !isValidSocialMedia(this.props.edit_profile.facebook_url, 'facebook')
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.facebook_url}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="facebook_url"
                                placeholder="https://www.facebook.com/username (Optional)"
                                maxLength={100}
                              />
                            </td>
                            <td />
                          </tr>

                          <tr>
                            <td className="account-table-first account-label fourth-width-tb">Instagram: </td>
                            <td colSpan="2">
                              <TextInput
                                name="instagram_url"
                                className={
                                  this.props.edit_profile.error &&
                                  this.props.edit_profile.mode === 'edit_address' &&
                                  this.props.edit_profile.instagram_url !== null &&
                                  this.props.edit_profile.instagram_url !== '' &&
                                  !isValidSocialMedia(this.props.edit_profile.instagram_url, 'instagram')
                                    ? 'form-control business-dark-theme-input input-error'
                                    : 'form-control business-dark-theme-input'
                                }
                                value={this.props.edit_profile.instagram_url}
                                change={this.onChangeEditProfileItem}
                                keyDown={this.onChangeEditProfileKeyPress}
                                item="instagram_url"
                                placeholder="https://www.instagram.com/username (Optional)"
                                maxLength={100}
                              />
                            </td>
                            <td />
                          </tr>
                          <tr className="practice-checkboxes-tr">
                            <td colSpan="3">
                              <div className="practice-checkboxes-div">
                                <div className="practice-checkbox-div">
                                  <span className="account-label">Shipping Only: </span>
                                  <input
                                    className="checkbox-button"
                                    type="checkbox"
                                    name="practice"
                                    checked={this.props.edit_profile.shipping_only}
                                    onChange={this.onChangeEditProfilePractice}
                                  />
                                </div>
                                <div className="practice-checkbox-div">
                                  <span className="account-label">Active: </span>
                                  <input
                                    className="checkbox-button"
                                    type="checkbox"
                                    name="active"
                                    checked={this.props.edit_profile.is_active}
                                    disabled={true}
                                  />
                                </div>
                                <div className="practice-checkbox-div">
                                  <span className="account-label">Doctor Locator: </span>
                                  <input
                                    className="checkbox-button"
                                    type="checkbox"
                                    name="doctor_locator"
                                    checked={this.props.edit_profile.is_doctor_loc}
                                    onChange={this.onChangeEditProfileDoctorLoc}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div
                                id="edit-confirm"
                                className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                                onClick={this.onEditProfileConfirm}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Save"
                              >
                                <i className="fa fa-check" aria-hidden="true" />
                              </div>
                              <div
                                id="edit-discard"
                                className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                                onClick={this.onEditProfileCancel}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Cancel"
                              >
                                <i className="fa fa-times" aria-hidden="true" />
                              </div>
                              <div
                                className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                                onClick={this.onEditProfileDelete}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i className="fa fa-trash" aria-hidden="true" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <tbody className="table-inbrace-dark-industry-2">
                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div className="bold-text">{address.address_name}</div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div className="account-checkbox-window">
                                <div className="account-checkbox-label">Shipping Only:</div>
                                <input className="checkbox-button" type="checkbox" name="notification" readOnly={true} checked={address.shipping_only} />
                              </div>
                            </td>
                            <td className="edit-width-tb" />
                          </tr>

                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>
                                {address.address_line_1 && address.address_line_2
                                  ? address.address_line_1 + ' ' + address.address_line_2
                                  : address.address_line_1}
                                {', ' + address.city + ', ' + address.state + ' ' + address.zip + ', ' + address.country}
                              </div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div className="account-checkbox-window">
                                <div className="account-checkbox-label">Active:</div>
                                <input className="checkbox-button" type="checkbox" name="notification" readOnly={true} checked={address.current_ind} />
                              </div>
                            </td>
                            <td className="edit-width-tb" />
                          </tr>
                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <i className="fa fa-envelope" aria-hidden="true" />
                            </td>
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>{address.address_email ? address.address_email : 'N/A'}</div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div className="account-checkbox-window">
                                <div className="account-checkbox-label">Doctor Locator:</div>
                                <input className="checkbox-button" type="checkbox" name="notification" readOnly={true} checked={address.doc_locator_ind} />
                              </div>
                            </td>
                            <td className="edit-width-tb" />
                          </tr>
                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <i className="fa fa-phone" aria-hidden="true" />
                            </td>
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>{address.address_telephone}</div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="edit-width-tb" />
                          </tr>

                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <i className="fa fa-link" aria-hidden="true" />
                            </td>
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>
                                {address.website ? (
                                  <a
                                    href={'http://' + address.website.replace('http://', '').replace('https://', '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {address.website}
                                  </a>
                                ) : (
                                  'N/A'
                                )}
                              </div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="edit-width-tb" />
                          </tr>
                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <FontAwesomeIcon icon={['fab', 'facebook']} aria-hidden="true" />
                            </td>
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>
                                {address.facebook_url ? (
                                  <div>
                                    <a
                                      href={'http://' + address.facebook_url.replace('http://', '').replace('https://', '')}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {address.facebook_url}
                                    </a>
                                  </div>
                                ) : (
                                  'N/A'
                                )}
                              </div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="edit-width-tb" />
                          </tr>
                          <tr className="pointer">
                            <td className="icon-width-tb" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <FontAwesomeIcon icon={['fab', 'instagram']} aria-hidden="true" />
                            </td>
                            <td className="auto-width-tb" colSpan="2" data-id={index} data-mode="edit_address" onClick={this.onEditClick}>
                              <div>
                                {address.instagram_url ? (
                                  <div>
                                    <a
                                      href={'http://' + address.instagram_url.replace('http://', '').replace('https://', '')}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {address.instagram_url}
                                    </a>
                                  </div>
                                ) : (
                                  'N/A'
                                )}
                              </div>
                            </td>
                            <td className="fourth-width-tb account-table-dark" data-id={index} data-mode="edit_address" onClick={this.onEditClick} />
                            <td className="edit-width-tb" />
                          </tr>
                        </tbody>
                      </React.Fragment>
                    )}
                  </table>
                  {this.state.error && this.props.edit_profile.mode === 'edit_address' ? (
                    <span className="info-error">
                      {this.state.address_error_msg
                        ? this.state.address_error_msg
                        : 'Please enter a valid address or ensure the address does not already exist'}
                    </span>
                  ) : null}
                  {this.props.erp_error ? <span className="info-error erp-error">{this.getErpErrorMessage(address)}</span> : null}

                  {!this.props.new_profile.active && !this.props.edit_profile.active && index === addressList.length - 1 ? (
                    <React.Fragment>
                      <div className="account-add account-add-last" data-mode="add_address" onClick={this.props.onAddProfileItemClick}>
                        Add <i className="fa fa-plus-circle" aria-hidden="true" data-mode="add_address" onClick={this.props.onAddProfileItemClick} />
                      </div>
                      {this.props.address_error ? (
                        <span className="info-error">
                          {this.state.address_error_msg ? this.state.address_error_msg : 'Please ensure that there is at least one active address'}
                        </span>
                      ) : null}
                    </React.Fragment>
                  ) : !this.props.new_profile.active && index === addressList.length - 1 ? (
                    <div className="account-add account-add-last" data-mode="add_address" />
                  ) : null}
                </div>
              );
            })
          ) : this.props.new_profile.active && this.props.new_profile.mode === 'add_address' ? null : (
            <div className="col-lg-6">
              <table className="table table-industry address-table">
                <thead className="table-inbrace-dark-industry-2">
                  <tr>
                    <th className="fourth-width-tb" />
                    <th className="fourth-width-tb" />
                    <th className="auto-width-tb" />
                    <th className="edit-width-tb" />
                  </tr>
                </thead>
                <tbody className="table-inbrace-dark-industry-2">
                  <tr className="force-auto-width">
                    <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="3">
                      - - - - - No Practice Information Found - - - - -
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
              {!this.props.new_profile.active && !this.props.edit_profile.active ? (
                <React.Fragment>
                  <div className="account-add" data-mode="add_address" onClick={this.props.onAddProfileItemClick}>
                    Add <i className="fa fa-plus-circle" aria-hidden="true" data-mode="add_address" onClick={this.props.onAddProfileItemClick} />
                  </div>
                  {this.props.address_error ? (
                    <span className="info-error">
                      {this.state.address_error_msg ? this.state.address_error_msg : 'Please ensure that there is at least one active address'}
                    </span>
                  ) : null}
                </React.Fragment>
              ) : (
                <div className="account-add" data-mode="add_address" />
              )}
            </div>
          )}

          <div className="col-lg-6" id="new_address">
            <table className="table table-condensed table-industry address-table">
              <thead className="table-inbrace-dark-industry-2">
                <tr>
                  <th className="fourth-width-tb" />
                  <th className="fourth-width-tb" />
                  <th className="auto-width-tb" />
                  <th className="edit-width-tb" />
                </tr>
              </thead>
              {this.props.new_profile.active && this.props.new_profile.mode === 'add_address' ? (
                <tbody className="table-inbrace-dark-industry-2">
                  <tr className="account-start-height">
                    <td className="account-table-first account-label fourth-width-tb account-curve-top-left">ERP ID/Name: </td>
                    <td className="account-curve-top-right erp-input" colSpan="2">
                      <TextInput className="form-control" readOnly={true} />
                      <TextInput className="form-control" readOnly={true} />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Practice Name: </td>
                    <td colSpan="2">
                      <TextInput
                        name="address_name"
                        className={
                          this.state.error && this.state.new_profile.value === ''
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.value}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="value"
                        placeholder="Enter Practice Name"
                        autoFocus={true}
                        maxLength={100}
                      />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Address: </td>
                    <td colSpan="2" className="country-td">
                      <Datalist
                        className={
                          this.state.error && this.state.new_profile.country === ''
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.country}
                        change={this.onChangeNewProfileItem}
                        onKeyDown={this.onChangeNewProfileItemKeyPress}
                        item="country"
                        list={Object.values(COUNTRIES)}
                        width="100%"
                        placeholder="Select Country"
                        error={this.state.error && this.state.new_profile.country === ''}
                        name="country"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="fourth-width-tb" />
                    <td colSpan="2">
                      <TextInput
                        name="address_line_1"
                        className={
                          this.state.error && this.state.new_profile.address_line_1 === ''
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.address_line_1}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="address_line_1"
                        placeholder="Address Line 1"
                        maxLength={250}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td className="fourth-width-tb" />
                    <td colSpan="2">
                      <TextInput
                        name="address_line_2"
                        className="form-control business-dark-theme-input"
                        value={this.state.new_profile.address_line_2}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="address_line_2"
                        placeholder="Address Line 2"
                        maxLength={250}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td className="fourth-width-tb" />
                    <td colSpan="2">
                      <TextInput
                        name="city"
                        divClass={`account-city account-city${this.state.new_profile.country === UNITED_STATES ? '--us' : '--ous'}`}
                        className={
                          this.state.error && this.state.new_profile.city === ''
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.city}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="city"
                        placeholder="City"
                        maxLength={50}
                      />
                      {this.state.new_profile.country === UNITED_STATES && (
                        <Datalist
                          name="state"
                          className="form-control business-dark-theme-input state-datalist"
                          fieldsetClass={this.state.new_profile.error && this.state.new_profile.state === '' ? 'account-state input-error' : 'account-state'}
                          value={this.state.new_profile.state}
                          change={this.onChangeNewProfileItem}
                          item="state"
                          list={Object.keys(US_STATES)}
                          width="70px"
                        />
                      )}
                    </td>
                    <td />
                  </tr>
                  {this.state.new_profile.country !== UNITED_STATES && (
                    <tr>
                      <td className="fourth-width-tb" />
                      <td colSpan="2">
                        <TextInput
                          name="state"
                          className={
                            this.state.error && this.state.new_profile.state === ''
                              ? 'form-control business-dark-theme-input input-error'
                              : 'form-control business-dark-theme-input'
                          }
                          fieldsetClass={this.state.new_profile.error && this.state.new_profile.state === '' ? 'account-state input-error' : 'account-state'}
                          value={this.state.new_profile.state}
                          change={this.onChangeNewProfileItem}
                          item="state"
                          placeholder="State / Province / Region"
                          width="100%"
                          maxLength={50}
                        />
                      </td>
                      <td />
                    </tr>
                  )}
                  <tr>
                    <td className="fourth-width-tb" />
                    <td className="fourth-width-tb">
                      <TextInput
                        name="zip"
                        className={
                          this.state.error && this.state.new_profile.zip === ''
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.zip}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="zip"
                        placeholder="Zip"
                        maxLength={15}
                      />
                    </td>
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Email: </td>
                    <td colSpan="2">
                      <TextInput
                        name="address_email"
                        className={
                          this.state.error &&
                          this.props.edit_profile.address_email !== null &&
                          this.state.new_profile.address_email !== '' &&
                          !isValidEmail(this.state.new_profile.address_email)
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.address_email}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="address_email"
                        placeholder="Enter Email (Optional)"
                        maxLength="100"
                      />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Country Code: </td>
                    <td colSpan="2">
                      <TextInput
                        name="country_code"
                        readOnly={true}
                        className="form-control business-dark-theme-input"
                        value={this.state.new_profile.country_code}
                        item="country_code"
                        placeholder="Country Code"
                      />
                    </td>
                    <td />
                  </tr>
                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Telephone: </td>
                    <td colSpan="2">
                      <TextInput
                        name="address_telephone"
                        className={
                          this.state.error && !isPhoneNumberValid(this.state.new_profile.address_telephone, this.state.new_profile.country)
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.address_telephone}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="address_telephone"
                        placeholder="Enter Telephone Number"
                        maxLength={20}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Website: </td>
                    <td colSpan="2">
                      <TextInput
                        name="website"
                        className={
                          this.state.error &&
                          this.props.edit_profile.website !== null &&
                          this.state.new_profile.website !== '' &&
                          !ValidationUtils.isValidURL(this.state.new_profile.website)
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.website}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="website"
                        placeholder="Enter Website (Optional)"
                        maxLength={100}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Facebook: </td>
                    <td colSpan="2">
                      <TextInput
                        name="facebook_url"
                        className={
                          this.state.error &&
                          this.props.edit_profile.facebook_url !== null &&
                          this.state.new_profile.facebook_url !== '' &&
                          !isValidSocialMedia(this.state.new_profile.facebook_url, 'facebook')
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.facebook_url}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="facebook_url"
                        placeholder="https://www.facebook.com/username (Optional)"
                        maxLength={100}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr>
                    <td className="account-table-first account-label fourth-width-tb">Instagram: </td>
                    <td colSpan="2">
                      <TextInput
                        name="instagram_url"
                        className={
                          this.state.error &&
                          this.props.edit_profile.instagram_url !== null &&
                          this.state.new_profile.instagram_url !== '' &&
                          !isValidSocialMedia(this.state.new_profile.instagram_url, 'instagram')
                            ? 'form-control business-dark-theme-input input-error'
                            : 'form-control business-dark-theme-input'
                        }
                        value={this.state.new_profile.instagram_url}
                        change={this.onChangeNewProfileItem}
                        keyDown={this.onChangeNewProfileItemKeyPress}
                        item="instagram_url"
                        placeholder="https://www.instagram.com/username (Optional)"
                        maxLength={100}
                      />
                    </td>
                    <td />
                  </tr>

                  <tr className="practice-checkboxes-tr">
                    <td colSpan="3">
                      <div className="practice-checkboxes-div">
                        <div className="practice-checkbox-div">
                          <span className="account-label">Shipping Only: </span>
                          <input
                            className="checkbox-button"
                            type="checkbox"
                            name="notification"
                            checked={this.state.new_profile.shipping_only}
                            onChange={this.onChangeNewProfilePractice}
                          />
                        </div>
                        <div className="practice-checkbox-div">
                          <span className="account-label">Active: </span>
                          <input className="checkbox-button" type="checkbox" name="notification" checked={this.state.new_profile.is_active} disabled={true} />
                        </div>
                        <div className="practice-checkbox-div">
                          <span className="account-label">Doctor Locator: </span>
                          <input
                            className="checkbox-button"
                            type="checkbox"
                            name="notification"
                            checked={this.state.new_profile.is_doctor_loc}
                            onChange={this.onChangeNewProfileDoctorLoc}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div
                        id="new-confirm"
                        className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Save"
                        onClick={this.onNewProfileItemConfirm}
                      >
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                      <div
                        id="new-discard"
                        className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Cancel"
                        onClick={this.onNewProfileCancel}
                      >
                        <i className="fa fa-times" aria-hidden="true" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : null}
            </table>

            <div>
              {this.state.error && this.props.new_profile.mode === 'add_address' ? (
                <span className="info-error">
                  {this.state.address_error_msg ? this.state.address_error_msg : 'Please enter a valid address or ensure the address does not already exist'}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {this.props.invalid_address_modal ? (
          <Modal
            preset="invalid-address"
            header_text={this.props.smartystreets_error ? 'Unable to save address' : 'Invalid Address'}
            confirm_btn_text={this.props.address_suggestions.length > 0 ? 'Save' : 'OK'}
            close_btn_text="Cancel"
            close_btn={this.props.address_suggestions.length > 0}
            onCloseButtonClick={this.props.closeInvalidAddressModal}
            onConfirmButtonClick={
              this.props.original_address_selected && !this.props.edit_address
                ? () => this.saveOriginalAddress('', '')
                : this.props.original_address_selected && this.props.edit_address
                ? () => this.saveOriginalAddressAfterEditing('', '')
                : this.props.address_suggestions.length > 0 && !this.props.edit_address
                ? this.saveAddressSuggestion
                : this.props.address_suggestions.length > 0 && this.props.edit_address
                ? this.saveAddressSuggestionAfterEditing
                : this.props.closeInvalidAddressModal
            }
            confirmButtonDisabled={this.props.address_suggestions.length > 0 && !this.props.original_address_selected && !this.props.selected_address_id}
            theme="bpp"
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invalid_address_modal: getInvalidAddressModal(state),
    address_suggestions: getAddressSuggestions(state),
    selected_address_id: getSelectedAddressId(state),
    original_address_selected: getOriginalAddressSelected(state),
    edit_address: getEditAddress(state),
    smartystreets_error: getSmartyStreetsError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openInvalidAddressModal: openInvalidAddressModal,
      closeInvalidAddressModal: closeInvalidAddressModal,
      openSmartyStreetsErrorModal: openSmartyStreetsErrorModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
