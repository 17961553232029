import Axios from 'axios';

//Action Imports
import { fetchIfuState } from './ifu_state';

// Action Types

export const OPEN_TX_INSTRUCTION_FILE_MODAL = 'OPEN_TX_INSTRUCTION_FILE_MODAL';
export const CLOSE_TX_INSTRUCTION_FILE_MODAL = 'CLOSE_TX_INSTRUCTION_FILE_MODAL';
export const OPEN_BPG_FILE_MODAL = 'OPEN_BPG_FILE_MODAL';
export const CLOSE_BPG_FILE_MODAL = 'CLOSE_BPG_FILE_MODAL';
export const OPEN_REMOVE_TX_INSTRUCTION_FILE_MODAL = 'OPEN_REMOVE_TX_INSTRUCTION_FILE_MODAL';
export const CLOSE_REMOVE_TX_INSTRUCTION_FILE_MODAL = 'CLOSE_REMOVE_TX_INSTRUCTION_FILE_MODAL';
export const OPEN_REMOVE_BPG_FILE_MODAL = 'OPEN_REMOVE_BPG_FILE_MODAL';
export const CLOSE_REMOVE_BPG_FILE_MODAL = 'CLOSE_REMOVE_BPG_FILE_MODAL';
export const REMOVE_TX_FILE_PENDING = 'REMOVE_TX_FILE_PENDING';
export const REMOVE_TX_FILE_SUCCESS = 'REMOVE_TX_FILE_SUCCESS';
export const REMOVE_TX_FILE_ERROR = 'REMOVE_TX_FILE_ERROR';
export const REMOVE_BPG_FILE_PENDING = 'REMOVE_BPG_FILE_PENDING';
export const REMOVE_BPG_FILE_SUCCESS = 'REMOVE_BPG_FILE_SUCCESS';
export const REMOVE_BPG_FILE_ERROR = 'REMOVE_TX_FILE_ERROR';
export const RETURN_TO_IFU_PENDING = 'RETURN_TO_IFU_PENDING';
export const RETURN_TO_IFU_SUCCESS = 'RETURN_TO_IFU_SUCCESS';
export const RETURN_TO_IFU_ERROR = 'RETURN_TO_IFU_ERROR';
export const OPEN_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL = 'OPEN_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL';
export const CLOSE_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL = 'CLOSE_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL';
export const OPEN_BPG_MANUAL_UPLOAD_MODAL = 'OPEN_BPG_MANUAL_UPLOAD_MODAL';
export const CLOSE_BPG_MANUAL_UPLOAD_MODAL = 'CLOSE_BPG_MANUAL_UPLOAD_MODAL';
export const MANUAL_UPLOAD_PENDING = 'MANUAL_UPLOAD_PENDING';
export const MANUAL_UPLOAD_SUCCESS = 'MANUAL_UPLOAD_SUCCESS';
export const MANUAL_UPLOAD_ERROR = 'MANUAL_UPLOAD_ERROR';

// Action Creators
// -----------------------------------------------------------------------------

export function openTxInstructionFileModal() {
  return {
    type: OPEN_TX_INSTRUCTION_FILE_MODAL,
  };
}

export function closeTxInstructionFileModal() {
  return {
    type: CLOSE_TX_INSTRUCTION_FILE_MODAL,
  };
}

export function openBpgFileModal() {
  return {
    type: OPEN_BPG_FILE_MODAL,
  };
}

export function closeBpgFileModal() {
  return {
    type: CLOSE_BPG_FILE_MODAL,
  };
}

export function openRemoveTxInstructionFileModal() {
  return {
    type: OPEN_REMOVE_TX_INSTRUCTION_FILE_MODAL,
  };
}

export function closeRemoveTxInstructionFileModal() {
  return {
    type: CLOSE_REMOVE_TX_INSTRUCTION_FILE_MODAL,
  };
}

export function openRemoveBpgFileModal() {
  return {
    type: OPEN_REMOVE_BPG_FILE_MODAL,
  };
}

export function closeRemoveBpgFileModal() {
  return {
    type: CLOSE_REMOVE_BPG_FILE_MODAL,
  };
}

export function openTxInstructionManualUploadModal() {
  return {
    type: OPEN_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL,
  };
}

export function closeTxInstructionManualUploadModal() {
  return {
    type: CLOSE_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL,
  };
}
export function openBpgManualUploadModal() {
  return {
    type: OPEN_BPG_MANUAL_UPLOAD_MODAL,
  };
}

export function closeBpgManualUploadModal() {
  return {
    type: CLOSE_BPG_MANUAL_UPLOAD_MODAL,
  };
}

// Axios

export function onConfirmButtonClickRemoveTxFile(isManual) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    const action = isManual ? 'remove_tx_manual' : 'remove_tx';

    dispatch(removeTxFilePending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ifu/${action}`)
      .then((res) => {
        dispatch(removeTxFileSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeRemoveTxInstructionFileModal());
      })
      .catch((error) => {
        dispatch(removeTxFileError(error));
      });
  };
}

export function removeTxFilePending() {
  return {
    type: REMOVE_TX_FILE_PENDING,
  };
}
export function removeTxFileSuccess() {
  return {
    type: REMOVE_TX_FILE_SUCCESS,
  };
}

export function removeTxFileError(error) {
  return {
    type: REMOVE_TX_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function onConfirmButtonClickRemoveBpgFile(isManual) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    const action = isManual ? 'remove_bpg_manual' : 'remove_bpg';

    dispatch(removeBpgFilePending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ifu/${action}`)
      .then((res) => {
        dispatch(removeBpgFileSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeRemoveBpgFileModal());
      })
      .catch((error) => {
        dispatch(removeBpgFileError(error));
      });
  };
}

export function removeBpgFilePending() {
  return {
    type: REMOVE_BPG_FILE_PENDING,
  };
}
export function removeBpgFileSuccess() {
  return {
    type: REMOVE_BPG_FILE_SUCCESS,
  };
}

export function removeBpgFileError(error) {
  return {
    type: REMOVE_BPG_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function onConfirmButtonClickReturnToIfu() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(returnToIfuPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ifu/return_to_upload`)
      .then((res) => {
        dispatch(returnToIfuSuccess());
        dispatch(fetchIfuState(case_id));
      })
      .catch((error) => {
        dispatch(returnToIfuError(error));
      });
  };
}

export function returnToIfuPending() {
  return {
    type: RETURN_TO_IFU_PENDING,
  };
}
export function returnToIfuSuccess() {
  return {
    type: RETURN_TO_IFU_SUCCESS,
  };
}

export function returnToIfuError(error) {
  return {
    type: RETURN_TO_IFU_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function onManualUpload(data) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(manualUploadPending());

    if (data && data[0] && (data[0].folder === 'tx_manual' || data[0].folder === 'bpg_manual')) {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      const action = data[0].folder === 'tx_manual' ? 'upload_txinstruction' : 'upload_bpg';

      Axios.post(`/apiV2/cadaction/${case_id}/ifu/${action}`, form_data)
        .then((res) => {
          dispatch(manualUploadSuccess());
          dispatch(fetchIfuState(case_id));
        })
        .catch((error) => {
          dispatch(manualUploadError(error));
        });
    }
  };
}

export function manualUploadPending() {
  return {
    type: MANUAL_UPLOAD_PENDING,
  };
}
export function manualUploadSuccess() {
  return {
    type: MANUAL_UPLOAD_SUCCESS,
  };
}

export function manualUploadError(error) {
  return {
    type: MANUAL_UPLOAD_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
