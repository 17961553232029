import React from 'react';

const Submitter = ({ name }) => (
  <div className="loader-container-2">
    <header className="loader-header">
      <img src={process.env.PUBLIC_URL + '/static/img/img_loader_gray.gif'} className="iloader" alt="loader" />
      <p>Submitting...</p>
    </header>
  </div>
);

export default Submitter;
