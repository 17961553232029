export const SETUP_PREFIX = 'Smile Design ';

/**
 * Filters through the case_details.cases that correspond with the case_id
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @param {String} case_id - Case ID
 * @return {Object} An Object of case_id's case detail
 */
function getCaseDetailByCaseId(case_details, case_id) {
  for (const detail of case_details.cases) {
    if (detail.case_id === case_id) {
      return detail;
    }
  }
  return {};
}

/**
 * Retrieves the case's treatment plan location and revision
 *
 * @function
 * @param {Object} case_files - Contains all the cases files
 * @param {String} case_id - Case ID
 * @return {Object} Object with treatment plan id and revision
 */
function getTxPlanInfo(case_files) {
  const case_id = case_files.case_id;
  const files = case_files?.[case_id]?.submission_process ?? {};
  const isDE = case_id.indexOf('-') >= 0;
  const name = isDE ? 'de_plan_files' : 'treatment_plan_files';

  const txplan_id = files?.[name]?.length ? files[name].at(-1).id : '';
  const txplan_revision = files.treatment_plan_revision;

  return {
    id: txplan_id,
    revision: txplan_revision,
  };
}

/**
 * Checks whether plan_rev is revision 2
 *
 * @function
 * @param {String} plan_rev - Treatment plan revision
 * @return {Boolean} whether plan_rev is revision 2
 */
function isTxPlanRevision2Or3(plan_rev) {
  const allowed_revisions = ['TX2.0', 'DE2.0', 'TX3.0', 'DE3.0'];
  return allowed_revisions.includes(plan_rev);
}

/**
 * Return active pane text value
 *
 * @function
 * @param {String} pane - The pane value (1 or 2)
 * @return {String} The pane text value
 */
function getPaneText(pane) {
  return `active_pane_${pane}`;
}

/**
 * Retrieves active model by setup number
 *
 * @function
 * @param {String} props - Contains props the entire setup viewer
 * @return {Object} The model object specified by the user selection of the setup
 */
function getActiveSetupModel(props) {
  const { models } = props;
  const setup_number = getSetupNumber(props);
  const model = models ? models.find((model) => model.setup_num === setup_number) : '';
  return model ? model : {};
}

/**
 * Retrieves the setup number
 *
 * @function
 * @param {String} props - Contains props the entire setup viewer
 * @return {Number} Return the setup number
 */
function getSetupNumber(props) {
  const active_pane_text = getActivePaneText(props);
  let setup_num = parseInt(active_pane_text.replace(SETUP_PREFIX, ''));
  if (active_pane_text.includes('(InBrace IFS)')) setup_num++;
  return active_pane_text === 'Smile Simulation' ? 1 : setup_num;
}

/**
 * Retrieves the selected text in a given pane (1 or 2)
 *
 * @function
 * @param {String} props - Contains props the entire setup viewer
 * @return {String} Return the selected pane text
 */
function getActivePaneText(props) {
  const { scroll_options, pane } = props;
  const pane_text = getPaneText(pane);
  const active_pane_option = scroll_options ? scroll_options.find((option) => option[pane_text]) : '';
  if (props.isSmileSimulationSetup) return 'Smile Simulation';
  return active_pane_option ? active_pane_option.text : '';
}

/**
 * Determines if choice is a setup
 *
 * @function
 * @param {String} props - Contains props the entire setup viewer
 * @return {Boolean} Return true if this is a setup
 */
function isSetup(props) {
  const active_pane_text = getActivePaneText(props);
  const is_setup = active_pane_text.indexOf(SETUP_PREFIX) !== -1;
  return is_setup;
}

/**
 * Determines if choice is a record
 *
 * @function
 * @param {String} props - Contains props the entire setup viewer
 * @return {Boolean} Return true if this is a record
 */
function isRecords(props) {
  const pane_text = getPaneText(props.pane);
  const is_records = props.scroll_options.filter((options) => {
    return options['text'] === 'Records' && options[pane_text];
  });
  return is_records && is_records.length > 0;
}

/**
 * Helper function that bounds the value to a min and max region
 *
 * @function
 * @param {Number} number - The number that is given to be bounded
 * @param {Number} min - The min bound region
 * @param {Number} max - The max bound region
 * @return {Number} Return bounded number
 */
function clamp(number, min, max) {
  return Math.max(min, Math.min(number, max));
}

/**
 * Check if the meshes are all there
 *
 * @function
 * @param {Object} steps - Contains the model steps
 * @return {Boolean} Return true if steps are found for Malocclusion and Setup
 */
function checkModelHasAllMeshes(steps) {
  const hasMalocclusion = steps && steps.first && steps.first.man && steps.first.max;
  const hasSetup = steps && steps.last && steps.last.man && steps.last.max;

  return hasMalocclusion && hasSetup;
}

/**
 * Base on the raw opacity value, compute the displayed value
 *
 * @function
 * @param {Object} opacity - Contains the opacity raw value
 * @return {Number} Return the computed opacity value
 */
function computeOpacityValue(opacity) {
  if (opacity < 50) return opacity + 50;
  if (opacity > 50) return -opacity + 150;
  return 100;
}

/**
 * Display value of the Arch opacity
 *
 * @function
 * @param {Object} opacity - Contains the opacity raw value
 * @return {String} Return the text translated value of the opacity
 */
function getArchOpacityTooltip(opacity) {
  const value = computeOpacityValue(opacity);
  if (opacity < 50) return `Lower (${value}%)`;
  if (opacity > 50) return `Upper (${value}%)`;

  return 'Arch Opacity';
}

/**
 * Display value of the Superimposition opacity
 *
 * @function
 * @param {Object} opacity - Contains the opacity raw value
 * @return {Number} Return the the text translated value of the opacity
 */
function getSuperimpositionTooltip(opacity) {
  const value = computeOpacityValue(opacity);
  if (opacity < 50) return `Setup (${value}%)`;
  if (opacity > 50) return `Malocclusion (${value}%)`;

  return 'Superimposition Opacity';
}

export {
  getCaseDetailByCaseId,
  getTxPlanInfo,
  isTxPlanRevision2Or3,
  getActiveSetupModel,
  getSetupNumber,
  getActivePaneText,
  isSetup,
  isRecords,
  clamp,
  checkModelHasAllMeshes,
  getArchOpacityTooltip,
  getSuperimpositionTooltip,
};
