// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';
import { onDownload } from '../../../../common/functions';

class Final extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_AWB_REWORK', user_roles_and_permissions.permissions) &&
                this.props.ref_no &&
                this.props.ireq_status !== 'Item Request Shipped' &&
                !this.props.shipping_approved_ind ? (
                <button className="process-return-ir link-button" onClick={this.props.onButtonClickRework}>
                  <i className="fa fa-repeat" aria-hidden="true" /> Rework
                </button>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
          {this.props.recipe_file ? (
            <React.Fragment>
              <div className="left-text">
                <a className="underline-text viewable-text view-upload" href={`/${this.props.recipe_file.upload_data}`} download onClick={onDownload}>
                  Recipe File <i className="fa fa-file-excel-o" aria-hidden="true" />
                </a>
              </div>
            </React.Fragment>
          ) : null}
          {this.props.ip_measurements_file ? (
            <React.Fragment>
              <div className="left-text">
                <span className="underline-text viewable-text" onClick={() => this.props.openMeasurementFileModal()}>
                  IP Loop Measurements
                </span>{' '}
                <i className="fa fa-file-pdf-o underline-text viewable-text" onClick={() => this.props.openMeasurementFileModal()} aria-hidden="true" />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default Final;
