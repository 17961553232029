import React from 'react';
import PropTypes from 'prop-types';
import SelectionBarItem from './selection_bar_item';

/**
 * Displays selection bar small
 * @component
 */
function SelectionBarTopSmall(props) {
  const pane = `active_pane_${props.pane}`;
  const active_item = props.scroll_options.filter((option, index) => {
    return option[pane] === true;
  });
  const ifs_setup_count = props.scroll_options?.filter((option) => {
    return option.is_inbrace_ifs_setup;
  })?.length;

  const max_height = props.scroll_options ? props.scroll_options.length : 0;

  if (props.isSmileSimulationSetup) {
    return (
      <React.Fragment>
        <div className={props.split ? 'selection-bar-top--small' : 'selection-bar-top--small selection-bar-top-oov'}>
          <SelectionBarItem key={1} text={props.pane === 1 ? 'Malocclusion' : 'Smile Simulation'} active={true} onClick={props.onClick} pane={props.pane} />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div className={props.split ? 'selection-bar-top--small' : 'selection-bar-top--small selection-bar-top-oov'}>
          {active_item
            ? active_item.map((option, index) => {
                return <SelectionBarItem key={index} text={option.text} active={option[pane]} onClick={props.onClick} pane={props.pane} />;
              })
            : null}
          <i className={props.collapse ? 'fa fa-chevron-down' : 'fa fa-chevron-down flip-180'} aria-hidden="true" onClick={props.onClick} />
        </div>

        <div
          className={
            props.collapse
              ? `selection-bar-top--small selection-bar-top--small-height-${max_height} selection-bar-top--small-offset-${max_height} ${
                  props.split ? '' : 'layer-hidden'
                }`
              : `selection-bar-top--small selection-bar-top--small-height-${max_height} selection-bar-top--small-expand`
          }
        >
          {props.scroll_options
            ? props.scroll_options.map((option, index) => {
                return <SelectionBarItem key={index} text={option.text} active={option[pane]} onClick={props.onScrollOptionClick} pane={props.pane} />;
              })
            : null}
        </div>
        <div className={props.collapse ? 'selection-bar-top--small-hr fade-out' : 'selection-bar-top--small-hr fade-in'} />
      </React.Fragment>
    );
  }
}

SelectionBarTopSmall.propTypes = {
  collapse: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onScrollOptionClick: PropTypes.func.isRequired,
  pane: PropTypes.number.isRequired,
  scroll_options: PropTypes.array.isRequired,
  split: PropTypes.bool.isRequired,
};

export default SelectionBarTopSmall;
