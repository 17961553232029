/**
 * File: edit_account.js - Allows the bpp user to edit an account
 * Copyright: (c) Copyright December 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './edit_account.scss';

// External Libs
import _, { add } from 'lodash';
import Axios from 'axios';
import PaginationContainer from '../../components/pagination/pagination_container';
import React, { Component } from 'react';
import Select from 'react-select';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as InLogo } from '../../doctor/components/in.svg';
import { parsePhoneNumber } from 'libphonenumber-js';
// Internal Constants
import { US_STATES } from '../../common/constants';
import { COUNTRIES } from '../../common/constants/countries';
import { UNITED_STATES } from '../../common/constants/countries';
// Internal Components
import Datalist from '../../components/form/list/datalist';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import NotFound from '../../doctor/404/not_found';
import SecurityClearance from '../../components/security/security_clearance';
import SortHeader from '../../components/layout/sort_header';
import TextInput from '../../components/form/text/text_input';
import Programs from './programs';
// Internal Functions
import ValidationUtils from '../../common/validation/validation-utils';
import { new_branding_words } from '../../common/nomenclature';
import {
  convertDate,
  firstLetterCapital,
  formatState,
  modalClose,
  removeCaseIdInitialNumber,
  removeNumberChar,
  removeSpaces,
  removeSpecialChar,
  setTokenHeader,
  zipNumberChar,
  npiNumberChar,
  addressIsChanged,
  isValidSocialMedia,
  isValidEmail,
  removeEmoji,
  phoneNumberCharInternational,
} from '../../common/functions';
import {
  getBusinessColumnValueFromRoute,
  getBusinessEndDateValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessSearchValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
} from '../../common/route';
import { getSortValue, getSubmissionDateDefaultSort, onReloadPage, translateStatus, truncateTextByLimit } from '../../common/helpers';
import {
  getCasesFromRow,
  getCasesFromSearch,
  getCasesFromFilter,
  getCasesFromEndDate,
  getCasesFromStartDate,
  getCasesFromStatus,
  onApplySearch,
  onChangeSearch,
  orderCasesByDefault,
  removeIncompleteCases,
  InitialManufacturingCases,
  InitialOnCreditHoldCases,
  searchErrorMessage,
  translateCase,
} from '../../common/search';
import { handleHttpRequestError } from '../../common/error';
import { tableRowLink } from '../../common/table';
import { getPhoneCode } from '../../common/validation/phone_validation';

// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

// Redux Reducers
import {
  getInvalidAddressModal,
  getAddressSuggestions,
  getSelectedAddressId,
  getOriginalAddressSelected,
  getEditAddress,
  getReactivationModal,
  getDeactivationModal,
} from '../../redux/reducers/bpp/account/add_account';

// Redux Actions
import {
  openInvalidAddressModal,
  closeInvalidAddressModal,
  openSmartyStreetsErrorModal,
  openPracticeActivationModal,
  closePracticeActivationModal,
  filterUserRoles,
} from '../../redux/actions/bpp/account/add_account';

import { isValidState, isValidLocation } from '../../common/validation/address_validation';
import { formatPhoneNumber, isPhoneNumberValid } from '../../common/validation/phone_validation';

class EditAccount extends Component {
  constructor(props) {
    super(props);
    if (props && props.match && props.match.params && props.match.params.doctorid) {
      this.state = {
        loading: false,
        error: false,
        refresh: false,
        search: '',
        filter: '',
        cases_raw: [],
        cases_view: [],
        cases_sort_method: 'submission_date',
        cases_sort_order_ascending: { case_id: true, submission_date: false, patient_name: true, status: true, assigned_to: true },
        cases_row_size: 25,
        cases_active_page: 1,
        cases_page_range: 5,
        cases_length: 1,
        is_bottom: false,
        column_filter: '',
        status_filter: '',
        start_date_filter: '',
        end_date_filter: '',

        salesforce_url: '',
        dso_data: null,

        profile: {
          account_id: '',
          first_name: '',
          last_name: '',
          role: '',
          account_email: '',
          notification_emails: [],
          phone_numbers: [],
          shipping_addresses: [],
          cases: [],
          is_active: true,
          credit_hold: false,
          referral: false,
          permission_roles: [],
          types: [],
          rae: '',
          cst: '',
          css: '',
          npi_number: '',
          referral_email: '',
          navigator_flag: false,
        },
        edit_profile: {
          id: '',
          shopify_address_id: null,
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          country_code: '',
          website: '',
          is_active: true,
          credit_hold: false,
          referral: false,
          is_practice: true,
          shipping_only: false,
          is_doctor_loc: false,
          permission_roles: [],
          types: [],
          rae: '',
          cst: '',
          css: '',
          npi_number: '',
          erp_id: '',
          erp_name: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
        },
        new_profile: {
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: UNITED_STATES,
          country_code: '1',
          is_active: true,
          credit_hold: false,
          referral: false,
          is_practice: true,
          shipping_only: false,
          is_doctor_loc: false,
          facebook_url: '',
          instagram_url: '',
          address_email: '',
        },
        selected_tab: 'User Details',
        address_error_msg: '',
        missing_error: false,
        limit_error: false,
        deleted_emails: [],
        edited_emails: [],
        referral_email: '',
        referral_email_error: false,
        deleted_phones: [],
        edited_phones: [],
        deleted_addresses: [],
        edited_addresses_without_cases: [],
        edited_addresses_cases: [],
        current_editing_address: {},
        e_addresses: [],
        e_cases: [],
        initial_manufacturing_cases: [],
        initial_on_hold_cases: [],
        main_address: '',
        doctor_id: props.match.params.doctorid,
        passwordSentModal: false,
        creditHoldModal: false,
        message_text: '',
        doctor_data: null,
        first_name_error: false,
        last_name_error: false,
        address_delete_error: false,
        email_delete_error: false,
        email_error: false,
        wrongPasswordError: '',
        min_email_error: false,
        already_existing_email_error: false,
        address_error: false,
        showGen2ConfirmationModal: false,
        modals: {
          showEditEmailModal: false,
          showDeleteEmailModal: false,
          showAddEmailModal: false,
          showEditAddressModal: false,
          showDeleteAddressModal: false,
          showAddAddressModal: false,
          showEditPhoneNoModal: false,
          showDeletePhoneModal: false,
          showAddPhoneModal: false,
          showDoctorDetailsModal: false,
          showEditAddressStep2Modal: false,
          updateNameModal: false,
          accountActivationModal: false,
          activeCasesModal: false,
          updateAddressInProgressModal: false,
          addAddressInProgressModal: false,
        },
        user: {
          first_name: '',
          last_name: '',
          email: '',
          currentuser: false,
          provider: true,
          loginEmail: '',
          notification_ind: false,
          notificationEmailList: [],
          notificationAddressList: [],
          phoneNumberList: [],
        },
        isReferralChecked: false,
        isGen2Checked: false,
        isSmileChecked: false,
        navigator_flag: false,
        dsoSmileDisabled: false,
        selectedEmail: null,
        selectedPhoneNumber: null,
        selectedAddress: null,
        loaded: false,
        in_progress: false,
        readonly: false,
        all_job_roles: [],
        all_user_types: [],
        all_rae: [],
        all_cst: [],
        all_css: [],
        customStyles: {
          control: (base) => ({
            ...base,
            height: 35,
            minHeight: 35,
          }),
        },
        validate_address_pending: false,
        update_name_in_progress: false,
        update_name_error: false,
        edit_first_name: false,
        erp_error_info: null,
        default_npi_number: 9999999999,
        account_activation_error: false,
        account_activation_selected_address_ids: [],
        active_cases: [],
        doctor_programs: [],
      };
      this.onfnChange = this.onfnChange.bind(this);
      this.onlnChange = this.onlnChange.bind(this);
      this.emailChange = this.emailChange.bind(this);
      this.changeRoute = this.changeRoute.bind(this);
      this.currentuserChange = this.currentuserChange.bind(this);
      this.providerChange = this.providerChange.bind(this);
      this.notification_indChange = this.notification_indChange.bind(this);
      this.onClickSendNewPassword = this.onClickSendNewPassword.bind(this);
      this.handleSaveAddressStep2Modal = this.handleSaveAddressStep2Modal.bind(this);
      this.onFeatureConfirm = this.onFeatureConfirm.bind(this);
      this.onGen2Update = this.onGen2Update.bind(this);
      this.onGenFlagCancel = this.onGenFlagCancel.bind(this);
      this.onEditFeatureClick = this.onEditFeatureClick.bind(this);
      this.applySearch = this.applySearch.bind(this);
      this.capitalizationByMode = this.capitalizationByMode.bind(this);
      this.getActionByMode = this.getActionByMode.bind(this);
      this.getActionByModeDelete = this.getActionByModeDelete.bind(this);
      this.getAddressById = this.getAddressById.bind(this);
      this.getOldValueByMode = this.getOldValueByMode.bind(this);
      this.getOldTypeByMode = this.getOldTypeByMode.bind(this);
      this.hasActiveCaseToAddressId = this.hasActiveCaseToAddressId.bind(this);
      this.hasActiveItemRequestToAddressId = this.hasActiveItemRequestToAddressId.bind(this);
      this.hasAtLeastOneValidAddress = this.hasAtLeastOneValidAddress.bind(this);
      this.hasZeroCasesAssociatedToAddressId = this.hasZeroCasesAssociatedToAddressId.bind(this);
      this.isEditableAddress = this.isEditableAddress.bind(this);
      this.noCasesInProgress = this.noCasesInProgress.bind(this);
      this.onAddProfileItemClick = this.onAddProfileItemClick.bind(this);
      this.onChangeEditProfileItem = this.onChangeEditProfileItem.bind(this);
      this.onChangeReferralEmail = this.onChangeReferralEmail.bind(this);
      this.onChangeReferralEmailKeyPress = this.onChangeReferralEmailKeyPress.bind(this);

      this.onChangeEditProfileNotification = this.onChangeEditProfileNotification.bind(this);
      this.onChangeEditProfileType = this.onChangeEditProfileType.bind(this);
      this.onChangeEditProfileActive = this.onChangeEditProfileActive.bind(this);
      this.onChangeEditProfilePractice = this.onChangeEditProfilePractice.bind(this);
      this.onChangeEditProfileDoctorLoc = this.onChangeEditProfileDoctorLoc.bind(this);
      this.onChangeNewProfileItem = this.onChangeNewProfileItem.bind(this);
      this.onChangeNewProfileNotification = this.onChangeNewProfileNotification.bind(this);
      this.onChangeNewProfilePractice = this.onChangeNewProfilePractice.bind(this);
      this.onChangeNewProfileDoctorLoc = this.onChangeNewProfileDoctorLoc.bind(this);
      this.onChangeNewProfileType = this.onChangeNewProfileType.bind(this);
      this.onEditClick = this.onEditClick.bind(this);
      this.onEditProfileConfirm = this.onEditProfileConfirm.bind(this);
      this.onEditProfileDiscard = this.onEditProfileDiscard.bind(this);
      this.onEditProfileDelete = this.onEditProfileDelete.bind(this);
      this.onEditProfileToggleAccount = this.onEditProfileToggleAccount.bind(this);
      this.onEndDateSelection = this.onEndDateSelection.bind(this);
      this.onNewProfileItemConfirm = this.onNewProfileItemConfirm.bind(this);
      this.onNewProfileItemDiscard = this.onNewProfileItemDiscard.bind(this);
      this.onStartDateSelection = this.onStartDateSelection.bind(this);
      this.onStatusFilterSelection = this.onStatusFilterSelection.bind(this);
      this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
      this.onPageChange = this.onPageChange.bind(this);
      this.onPatientClick = this.onPatientClick.bind(this);
      this.onTabClick = this.onTabClick.bind(this);
      this.getStartRange = this.getStartRange.bind(this);
      this.getEndRange = this.getEndRange.bind(this);
      this.onSortClick = this.onSortClick.bind(this);
      this.saveEditInfo = this.saveEditInfo.bind(this);
      this.saveNewInfo = this.saveNewInfo.bind(this);
      this.saveAddressSuggestion = this.saveAddressSuggestion.bind(this);
      this.saveAddressSuggestionAfterEditing = this.saveAddressSuggestionAfterEditing.bind(this);
      this.saveOriginalAddress = this.saveOriginalAddress.bind(this);
      this.saveOriginalAddressAfterEditing = this.saveOriginalAddressAfterEditing.bind(this);
      this.getCSIChanges = this.getCSIChanges.bind(this);
      this.onEditProfileToggleCreditHold = this.onEditProfileToggleCreditHold.bind(this);
      this.onEditProfileReferralProgram = this.onEditProfileReferralProgram.bind(this);
      this.onReactivateOrDeactivatePractice = this.onReactivateOrDeactivatePractice.bind(this);
      this.closeUpdateNameModal = this.closeUpdateNameModal.bind(this);
      this.onEditNameConfirm = this.onEditNameConfirm.bind(this);
      this.closeAccountActivationModal = this.closeAccountActivationModal.bind(this);
      this.onAccountActivationAddressChange = this.onAccountActivationAddressChange.bind(this);
      this.onEnableAccountConfirm = this.onEnableAccountConfirm.bind(this);
      this.closing_state.bind(this);
    }
  }

  getStartRange() {
    return this.state.cases_active_page * this.state.cases_row_size - this.state.cases_row_size + 1;
  }

  getEndRange() {
    return this.state.cases_active_page * this.state.cases_row_size > this.state.cases_length
      ? this.state.cases_length
      : this.state.cases_active_page * this.state.cases_row_size;
  }

  onPatientClick = (event) => {
    const pathname = `/business/portal/case/${event.currentTarget.dataset.caseid}`;
    tableRowLink(event, pathname, this.props);
  };

  getSortMethodByFilterSelection = (state_filter, filter, sort_method) => {
    let sort_method_update = sort_method;

    if (state_filter !== filter) {
      sort_method_update =
        filter === 'manufacture'
          ? 'approval_date'
          : filter === 'new'
          ? 'doctor_new_record_date'
          : filter === 'setup'
          ? 'doctor_setup_date'
          : filter === 'doctor_action'
          ? 'doctor_action_date'
          : 'submission_date';
    }

    return sort_method_update;
  };

  handleSaveAddressStep2Modal(data) {
    //data['edit_new_cases'] = true;
    let userInfo = this.state.user;
    var addr_data = this.state.current_editing_address;
    var index = addr_data['index'];
    userInfo.notificationAddressList[index] = addr_data;
    this.setState({ user: userInfo });
    data['current_editing_address'] = this.state.current_editing_address;
    //Axios.post('/api/casesfromaddressid/', data).then(function(res) {
    this.hideModal('showEditAddressStep2Modal');
    let edited_addresses_cases = this.state.edited_addresses_cases;
    edited_addresses_cases.push(data);
    this.setState({ edited_addresses_cases });

    //});
  }

  changeRoute() {
    this.props.history.push('/business/portal/doctors');
  }

  onPageChange(active_page) {
    //Allow for page changes without when filters are not applied yet
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute();
    const cases_sort_method = this.state.cases_sort_method;

    let cases_view = filter
      ? getCasesFromFilter(this.state.cases_raw, filter, this.state.user_id, this.state.assigned_cases)
      : getCasesFromSearch(
          getCasesFromEndDate(getCasesFromStartDate(getCasesFromStatus(this.state.cases_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    this.setState({
      cases_active_page: active_page,
      cases_view: getCasesFromRow(
        this.setOrderByMethod(cases_view, cases_sort_method, this.state.cases_sort_order_ascending[cases_sort_method]),
        active_page,
        this.state.cases_row_size
      ),
    });
  }

  componentDidMount() {
    this.reloadInformation();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  getTypeDisplayed = () => {
    const all_types = this.state.profile.types.map((type) => {
      return new_branding_words(type);
    });

    return all_types.join(', ');
  };

  /**
   * Pulls account information and sets state
   * @param {boolean} set_loading Optional - Defaults to True, if False prevents setting loading state
   */
  reloadInformation(set_loading = true) {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    set_loading && this.setState({ loading: true });
    Axios.get(`/apiV2/account/${this.props.match.params.doctorid}`)
      .then(function (res) {
        var data = res.data;
        let cases_raw = removeIncompleteCases(translateCase(data.cases));
        let initial_manufacturing_cases = InitialManufacturingCases(data.cases);
        let initial_on_hold_cases = InitialOnCreditHoldCases(data.cases);
        const cases_view = filter
          ? getCasesFromFilter(cases_raw, filter, data.user_id)
          : getCasesFromSearch(getCasesFromEndDate(getCasesFromStartDate(getCasesFromStatus(cases_raw, status), start_date), end_date), search, column);

        let all_rae = data.available_rae;

        let all_cst = data.available_cst;

        let all_css = data.available_css;
        all_css.unshift({
          label: 'Select User',
          value: 0,
        });

        if (data.rae) {
          let notification = data.rae.notification;
          let rae_temp = all_rae.find((c) => c.value === data.rae.id);

          if (rae_temp) {
            data.rae = rae_temp;
            data.rae.notification = notification;
          } else {
            data.rae.value = data.rae.id;
            data.rae.label = `${data.rae.first_name} ${data.rae.last_name}`;
          }
        } else {
          data.rae = {
            label: 'Select User',
            value: 0,
          };
        }

        if (data.cst) {
          let notification = data.cst.notification;
          let cst_temp = all_cst.find((c) => c.value === data.cst.id);

          if (cst_temp) {
            data.cst = cst_temp;
            data.cst.notification = notification;
          } else {
            data.cst.value = data.cst.id;
            data.cst.label = `${data.cst.first_name} ${data.cst.last_name}`;
          }
        } else {
          data.cst = {
            label: 'Select User',
            value: 0,
          };
        }

        if (data.css) {
          let notification = data.css.notification;
          let css_temp = all_css.find((c) => c.value === data.css.id);

          if (css_temp) {
            data.css = css_temp;
            data.css.notification = notification;
          } else {
            data.css.value = data.css.id;
            data.css.label = `${data.css.first_name} ${data.css.last_name}`;
          }
        } else {
          data.css = {
            label: 'Select User',
            value: 0,
          };
        }

        if (data.npi_number === that.state.default_npi_number) {
          data.npi_number = '';
        }
        that.setState({
          profile: data,
          loading: false,
          loaded: true,
          search: search,
          column_filter: column,
          status_filter: status,
          start_date_filter: start_date,
          end_date_filter: end_date,
          cases_raw: cases_raw,
          initial_manufacturing_cases: initial_manufacturing_cases,
          initial_on_hold_cases: initial_on_hold_cases,
          cases_view: getCasesFromRow(orderCasesByDefault(that, cases_view), that.state.cases_active_page, that.state.cases_row_size),
          cases_length: cases_view.length,
          selected_tab: that.props.history.location.search ? 'Cases' : 'User Details',
          readonly: that.isReadOnly(),
          all_user_types: data.available_types,
          all_rae: all_rae,
          all_cst: all_cst,
          all_css: all_css,
          isReferralChecked: data.referral,
          isGen2Checked: data.gen_2,
          referral_email: data.referral_email,
          isSmileChecked: data.smile,
          navigator_flag: data.navigator,
        });

        if (data.role.includes('DSO_Doctor')) {
          Axios.get(`/apiV2/account_light/${data.account_link_id}`).then((res) => {
            that.setState({
              dso_data: res.data,
            });
            if (!res.data.dso_admin_smile_permission) {
              that.setState({
                dsoSmileDisabled: true,
              });
            }
          });
        }

        if (data.role !== 'Business') {
          // Axios.get(`/apiV2/salesforce/get_url/doctor/${data.account_id}`)
          //   .then(function (res) {
          //     if (res && res.data && res.data.url) {
          //       that.setState({
          //         salesforce_url: res.data.url,
          //       });
          //     } else {
          //       that.setState({
          //         salesforce_url: 'https://inbrace.lightning.force.com/lightning/r/Contact/case_not_found/view',
          //       });
          //     }
          //   })
          //   .catch(function (err) {
          //     that.setState({
          //       salesforce_url: 'https://inbrace.lightning.force.com/lightning/r/Contact/case_not_found/view',
          //     });
          //   });
        }

        Axios.get(`/apiv3/program/${data.account_id}`)
          .then((res) => {
            const doctor_programs = res.data.doctor_programs
              .filter((program) => (program?.levels?.current !== 'full release' || program?.program_id === 'epp') && program?.current_ind === true)
              .map((program) => {
                return {
                  ...program,
                  checked: program.enrolled,
                };
              });
            that.setState({ doctor_programs: doctor_programs });
          })
          .catch((e) => console.log('error: ', e));
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    Axios.get(`/apiV2/job_role/get_all`).then(function (res) {
      let roles = filterUserRoles(res.data.all_job_roles);
      that.setState({
        all_job_roles: roles.sort(),
      });
    });
  }

  isReadOnly = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            (userHasPermission('ACCOUNT_DETAILS_IPP_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role === 'Doctor') ||
            (userHasPermission('ACCOUNT_DETAILS_BPP_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role === 'Business') ||
            (userHasPermission('ACCOUNT_DETAILS_DSO_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role.includes('DSO'))
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;

    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();
    let status = route_type === 'status' ? uri_text : getBusinessStatusValueFromRoute();
    let start_date = route_type === 'start' ? uri_text : getBusinessStartDateValueFromRoute();
    let end_date = route_type === 'end' ? uri_text : getBusinessEndDateValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';
    status = status !== '' ? `status=${status}` : '';
    start_date = start_date !== '' ? `start=${start_date}` : '';
    end_date = end_date !== '' ? `end=${end_date}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }

      if (status) {
        param.push(status);
      }

      if (start_date) {
        param.push(start_date);
      }

      if (end_date) {
        param.push(end_date);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  onClickSendNewPassword() {
    let that = this;
    Axios.post(`/api/email/?slug=password-reset-04082020&doctorId=${this.state.doctor_id}&provider=${window.location.origin}&method=standard`).then(function (
      res
    ) {
      if (res && res.data && res.data.message === 'success') {
        that.setState({
          passwordSentModal: true,
        });
      }
    });
  }

  onSendNewPasswordDismiss = (event) => {
    this.setState({ passwordSentModal: false });
  };

  onfnChange(e) {
    var user = this.state.user;
    user['first_name'] = e.target.value;
    this.setState({ first_name_error: false, user: user });
  }

  onlnChange(e) {
    var user = this.state.user;
    user['last_name'] = e.target.value;
    this.setState({ last_name_error: false, user: user });
  }

  emailChange(e) {
    this.setState({ email_error: false, already_existing_email_error: false });
  }

  currentuserChange(e) {
    var user = this.state.user;
    user['currentuser'] = this.refs.currentuser.checked;
    this.setState({ user: user });
  }

  providerChange(e) {
    var user = this.state.user;
    user['provider'] = this.refs.provider.checked;
    this.setState({ user: user });
  }

  notification_indChange(e) {
    var user = this.state.user;
    user['notification_ind'] = this.refs.notification_ind.checked;
    this.setState({ user: user });
  }

  //To show modal, pass the name of the modal as in state config
  showModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = true;
    this.setState({ modals: modals });
  };

  //To hide modal, pass the name of the modal as in state config
  hideModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = false;
    this.setState({ modals: modals });
  };

  //Called when either edit or delete is clicked on a row item in Address list
  setSelectedAddress = (data, index) => {
    this.setState({ selectedAddress: { data: data, index: index } });
  };
  //Called when either edit or delete is clicked on a row item in email list
  setSelectedEmail = (email, type, index) => {
    this.setState({ selectedEmail: { type: type, value: email, index: index } });
  };

  //Called when either edit or delete is clicked on a row item in phone no list
  setSelectedPhone = (phone, type, index) => {
    this.setState({ selectedPhone: { type: type, value: phone, index: index } });
  };

  //Called when user clicks on delete email in delete email modal
  deleteSelectedEmail = (email, index) => {
    setTokenHeader();
    var profile = {};
    profile['old'] = email;
    profile['type'] = 'email';
    profile['action'] = 'delete';
    profile['doctor_email'] = this.state.user.email;
    var _this = this;
    if (this.state.user.notificationEmailList.length <= 1) {
      _this.setState({ email_delete_error: true });
    } else {
      let userInfo = _this.state.user;
      userInfo.notificationEmailList.splice(index, 1);
      _this.setState({ user: userInfo });
      _this.hideModal('showDeleteEmailModal');
      var deleted_emails = this.state.deleted_emails;
      deleted_emails.push(email);
      _this.setState({ deleted_emails });
      modalClose('deleteEmailDismiss');
    }
  };

  //Called when user clicks on delete email in delete Address modal
  deleteSelectedAddress = (data, index) => {
    setTokenHeader();
    var profile = {};
    profile['address_data'] = data;
    profile['type'] = 'address';
    profile['action'] = 'delete';
    profile['doctor_email'] = this.state.user.email;
    var _this = this;
    if (this.state.user.notificationAddressList.length <= 1) {
      _this.setState({ address_delete_error: true });
    } else {
      let userInfo = _this.state.user;
      userInfo.notificationAddressList.splice(index, 1);
      let deleted_addresses = _this.state.deleted_addresses;
      deleted_addresses.push(data);
      _this.setState({ user: userInfo, deleted_addresses: deleted_addresses });
      //data['current_ind'] = false;
      //_this.updateAddress(data, index);
      _this.hideModal('showDeleteAddressModal');
      modalClose('addressDeleteDismiss');
      //});
    }
  };

  //Called when user clicks on delete phone no in delete phone number modal
  deleteSelectedPhone = (phone, index) => {
    let userInfo = this.state.user;
    userInfo.phoneNumberList.splice(index, 1);
    this.setState({ user: userInfo });
    this.hideModal('showDeletePhoneNoModal');
    setTokenHeader();
    var profile = {};
    profile['old'] = phone;
    profile['type'] = 'phone';
    profile['action'] = 'delete';
    profile['doctor_email'] = this.state.user.email;
    var deleted_phones = this.state.deleted_phones;
    deleted_phones.push(phone);
    this.setState({ deleted_phones });
  };

  //Called when user adds new email from add email modal
  addNewEmail = (email, location) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.push({ type: location, email: email });
    this.setState({ user: userInfo });
    this.hideModal('showAddEmailModal');
    setTokenHeader();
    var profile = {};
    profile['new'] = email;
    profile['type'] = 'email';
    profile['action'] = 'add';
    profile['loc'] = location;
    profile['doctor_email'] = this.state.user.email;
  };

  addNewAddress = (data) => {
    // let userInfo = this.state.user;
    // userInfo.notificationAddressList.push(data);
    // this.setState({ user: userInfo });
    this.hideModal('showAddAddressModal');
    setTokenHeader();
    var profile = {};
    profile['address_data'] = data;
    profile['type'] = 'address';
    profile['action'] = 'add';
    profile['doctor_email'] = this.state.user.email;
    var _this = this;

    let userInfo = _this.state.user;
    data['allow_delete'] = true;
    userInfo.notificationAddressList.push(data);
    _this.setState({ user: userInfo });
  };

  //Called when user adds new phone number from add phone no modal
  addNewTelephone = (type, phone) => {
    let userInfo = this.state.user;
    userInfo.phoneNumberList.push({ type: type, number: phone });
    this.setState({ user: userInfo });
    this.hideModal('showAddPhoneNoModal');
    setTokenHeader();
    var profile = {};
    profile['new'] = phone;
    profile['type'] = 'phone';
    profile['location'] = type;
    profile['action'] = 'add';
    profile['doctor_email'] = this.state.user.email;
  };

  //Called when user updates an existing phone no or type from edit phone no modal
  updatePhoneNo = (phoneNumber, type, index) => {
    let userInfo = this.state.user;
    var oldphoneNumber = userInfo.phoneNumberList[index].number;
    userInfo.phoneNumberList[index].number = phoneNumber;
    userInfo.phoneNumberList[index].type = type;
    let phone_data = {};
    phone_data['old'] = oldphoneNumber;
    phone_data['new'] = phoneNumber;
    phone_data['type'] = type;
    let edited_phones = this.state.edited_phones;
    edited_phones.push(phone_data);
    this.setState({ phone_data });
    this.setState({ user: userInfo });
    this.hideModal('showEditPhoneNoModal');
    setTokenHeader();
    var profile = {};
    profile['new'] = phoneNumber;
    profile['old'] = oldphoneNumber;
    profile['type'] = 'phone';
    profile['location'] = type;
    profile['action'] = 'edit';
    profile['doctor_email'] = this.state.user.email;
  };

  //Called when user updates an existing email from edit email modal
  updateEmail = (email, type, index) => {
    let userInfo = this.state.user;
    var oldEmail = userInfo.notificationEmailList[index].email;
    userInfo.notificationEmailList[index].email = email;
    userInfo.notificationEmailList[index].type = type;
    let email_data = {};
    email_data['old'] = oldEmail;
    email_data['new'] = email;
    email_data['type'] = type;
    let edited_emails = this.state.edited_emails;
    edited_emails.push(email_data);
    this.setState({ email_data });
    this.setState({ user: userInfo });
    this.hideModal('showEditEmailModal');
    setTokenHeader();
    var profile = {};
    profile['new'] = email;
    profile['old'] = oldEmail;
    profile['type'] = 'email';
    profile['location'] = type;
    profile['action'] = 'edit';
    profile['doctor_email'] = this.state.user.email;
  };

  onChangeSearchPatient = (event) => {
    onChangeSearch(this, event);
  };

  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  applySearch() {
    onApplySearch(this);

    this.setState({
      cases_sort_method: 'submission_date',
    });
  }

  getAddressById(id) {
    const addresses = this.state.profile.shipping_addresses;
    let address = {};

    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].id === id) {
        address = addresses[i];
        break;
      }
    }

    return address;
  }

  hasActiveCaseToAddressId(id, active_state = false) {
    //An active case is one that has the following status hold, not-shipped (still in progress), draft
    //Inactive case is shipped, cancelled

    const cases = this.state.profile.cases;
    let is_active = false;

    if (cases && cases.length > 0 && !active_state) {
      for (let i = 0; i < cases.length; i++) {
        if (cases[i].shipping_address_id === id && cases[i].status_code !== 'STATUS_SHIP' && cases[i].status_code !== 'STATUS_CANCEL') {
          is_active = true;
          break;
        }
      }
    }

    return is_active;
  }

  hasActiveItemRequestToAddressId(id, active_state = false) {
    //An active item request is one that has the following status hold, not-shipped (still in progress), draft
    //Inactive item request is shipped, cancelled

    const item_requests = this.state.profile.item_request;
    let is_active = false;

    if (item_requests && item_requests.length > 0 && !active_state) {
      for (let i = 0; i < item_requests.length; i++) {
        if (
          item_requests[i].shipping_address_id === id &&
          item_requests[i].status_code !== 'Item Request Shipped' &&
          item_requests[i].status_code !== 'Item Request Cancelled'
        ) {
          is_active = true;
          break;
        }
      }
    }

    return is_active;
  }

  hasAtLeastOneValidAddress(id, active, is_delete = false) {
    const addresses = this.state.profile.shipping_addresses;
    let is_valid = false;

    if ((addresses && addresses.length > 1 && !active) || is_delete) {
      for (let i = 0; i < addresses.length; i++) {
        if (addresses[i].is_active && addresses[i].id !== id) {
          is_valid = true;
          break;
        }
      }
    } else {
      is_valid = active;
    }

    return is_valid;
  }

  hasZeroCasesAssociatedToAddressId(id) {
    const cases = this.state.profile.cases;
    let has_zero = true;

    if (cases && cases.length > 0) {
      for (let i = 0; i < cases.length; i++) {
        if (cases[i].shipping_address_id === id) {
          has_zero = false;
          break;
        }
      }
    }

    return has_zero;
  }

  noCasesInProgress() {
    const cases_raw = this.state.cases_raw;
    let active_cases = 0;

    for (let i = 0; i < cases_raw.length; i++) {
      if (cases_raw[i].status_code !== 'STATUS_DRAFT_SUB') {
        active_cases = 1 + active_cases;
        break;
      }
    }

    return active_cases === 0;
  }

  onTabClick(event) {
    this.setState({
      selected_tab: event.currentTarget.dataset.tab,
    });
  }

  /**
   * Sets edit mode to a section in the edit accounts page
   * @function
   * @param {Object} event - The event handler when clicking any field that is
   * editable on the accounts page
   */
  onEditClick(event) {
    const id = parseInt(event.currentTarget.dataset.id);
    const mode = event.currentTarget.dataset && event.currentTarget.dataset.mode ? event.currentTarget.dataset.mode : '';
    const type = event.currentTarget.dataset && event.currentTarget.dataset.type ? event.currentTarget.dataset.type.toLowerCase() : '';
    const value =
      mode === 'rae'
        ? this.state.profile.rae
        : mode === 'cst'
        ? this.state.profile.cst
        : mode === 'css'
        ? this.state.profile.css
        : event.currentTarget.dataset.value;
    const notification = event.currentTarget.dataset.notification === 'true';
    const permission_roles = this.state.profile.permission_roles;
    const types = this.state.profile.types;

    if (this.state.profile.is_active && !this.state.edit_profile.active && !this.state.new_profile.active && mode) {
      if (mode === 'edit_address') {
        const address = this.getAddressById(id);
        const practice_info = _.cloneDeep(this.state.profile.practice_info).filter((practice) => {
          return practice.address_id === address.id;
        })[0];

        this.setState({
          edit_profile: {
            active: true,
            error: false,
            id: id,
            shopify_address_id: address.shopify_address_id,
            mode: mode,
            notification: false,
            type: '',
            value: address.address_name,
            address_line_1: address.address_line_1,
            address_line_2: address.address_line_2 ? address.address_line_2 : '',
            address_telephone: address.address_telephone,
            city: address.city,
            state: address.state,
            zip: address.zip ? address.zip.toString() : '',
            country: address.country,
            country_code: address.country_code,
            website: address.website,
            is_active: address.is_active,
            shipping_only: address.shipping_only,
            is_doctor_loc: address.is_doctor_loc,
            erp_id: address.erp_id,
            erp_name: address.erp_name,
            facebook_url: practice_info.facebook_url,
            instagram_url: practice_info.instagram_url,
            address_email: practice_info.address_email,
          },
          in_progress: false,
        });
      } else {
        this.setState({
          edit_profile: {
            active: true,
            error: false,
            id: id,
            mode: mode,
            notification: notification,
            type: type,
            value: value,
            permission_roles: permission_roles,
            types: types,
          },
          name_error: false,
          in_progress: false,
        });
      }
    }
  }

  onChangeList = (event, field) => {
    let edit_profile = this.state.edit_profile;

    if (field === 'rae' || field === 'cst' || field === 'css') {
      edit_profile.value = event;
    } else {
      let current_selected = [];

      if (event.target.selectedIndex > -1 && event.target.selectedOptions && event.target.selectedOptions.length > 0) {
        for (var i = 0; i < event.target.selectedOptions.length; i++) {
          const selected_option = event.target.selectedOptions[i];

          if (selected_option && selected_option.value) {
            current_selected.push(selected_option.value);
          }
        }
      }
      if (field === 'roles') {
        edit_profile.permission_roles = current_selected;
      } else if (field === 'types') {
        edit_profile.types = current_selected;
      }
    }

    edit_profile.error = false;

    this.setState({ edit_profile: edit_profile });
  };

  onChangeNotification = (event) => {
    let edit_profile = this.state.edit_profile;
    let value = {
      value: edit_profile.value.value,
      label: edit_profile.value.label,
      first_name: edit_profile.value.first_name,
      last_name: edit_profile.value.last_name,
      user_group: edit_profile.value.user_group,
      notification: event.target.checked,
    };

    edit_profile.value = value;

    this.setState({
      edit_profile: edit_profile,
    });
  };

  onChangeReferralEmail = (event) => {
    this.setState({
      referral_email_error: false,
      referral_email: removeEmoji(event.target.value),
    });
  };

  onChangeEditProfileItem(event) {
    let value = this.state.edit_profile.value;
    let item = event.target.dataset && event.target.dataset.item ? event.target.dataset.item : 'value';
    let address_line_1 = this.state.edit_profile.address_line_1;
    let address_line_2 = this.state.edit_profile.address_line_2;
    let country_code = this.state.edit_profile.country_code;
    let address_telephone = this.state.edit_profile.address_telephone;
    let city = this.state.edit_profile.city;
    let state = this.state.edit_profile.state;
    let zip = this.state.edit_profile.zip;
    let country = this.state.edit_profile.country;
    let website = this.state.edit_profile.website;
    let facebook_url = this.state.edit_profile.facebook_url;
    let instagram_url = this.state.edit_profile.instagram_url;
    let address_email = this.state.edit_profile.address_email;
    let missing_error = event.target.value.length > 0 ? false : this.state.missing_error;

    const emojiFreeValue = removeEmoji(event.target.value);

    switch (item) {
      case 'value':
        value = emojiFreeValue;
        if (this.state.edit_profile.mode === 'add_number') {
          value = phoneNumberCharInternational(emojiFreeValue);
        } else if (this.state.edit_profile.mode === 'edit_number') {
          value = phoneNumberCharInternational(emojiFreeValue);
        } else if (this.state.edit_profile.mode === 'npi') {
          value = npiNumberChar(emojiFreeValue);
        } else if (this.state.edit_profile.mode === 'facebook' || this.state.edit_profile.mode === 'instagram') {
          value = removeSpaces(value);
        }
        break;

      case 'address_line_1':
        address_line_1 = emojiFreeValue;
        break;

      case 'address_line_2':
        address_line_2 = emojiFreeValue;
        break;

      case 'address_telephone':
        address_telephone = emojiFreeValue;
        break;

      case 'city':
        city = emojiFreeValue;
        break;

      case 'state':
        if (country === UNITED_STATES) {
          state = removeNumberChar(removeSpecialChar(emojiFreeValue.toUpperCase()));
          state = state.length > 2 ? state.substring(0, 2) : state;
        } else {
          state = removeSpecialChar(emojiFreeValue);
        }
        break;

      case 'zip':
        if (country == UNITED_STATES) {
          zip = zipNumberChar(emojiFreeValue);
        } else {
          zip = emojiFreeValue.toUpperCase();
        }
        break;

      case 'country':
        country = emojiFreeValue;
        country_code = getPhoneCode(country);
        break;

      case 'website':
        website = removeSpaces(emojiFreeValue);
        break;

      case 'facebook_url':
        facebook_url = removeSpaces(emojiFreeValue);
        break;

      case 'instagram_url':
        instagram_url = removeSpaces(emojiFreeValue);
        break;

      case 'address_email':
        address_email = removeSpaces(emojiFreeValue);
        break;

      default:
    }

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        shopify_address_id: this.state.edit_profile.shopify_address_id,
        mode: this.state.edit_profile.mode,
        notification: this.state.edit_profile.notification,
        type: this.state.edit_profile.type,
        value: value,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        address_telephone: address_telephone,
        city: city,
        state: state,
        zip: zip,
        country_code: country_code,
        country: country,
        website: website,
        is_active: this.state.edit_profile.is_active,
        shipping_only: this.state.edit_profile.shipping_only,
        is_doctor_loc: this.state.edit_profile.is_doctor_loc,
        permissions_roles: this.state.edit_profile.permissions_roles,
        erp_id: this.state.edit_profile.erp_id,
        erp_name: this.state.edit_profile.erp_name,
        facebook_url: facebook_url,
        instagram_url: instagram_url,
        address_email: address_email,
      },
      missing_error: missing_error,
    });
  }

  onChangeEditProfileType(event) {
    const type = event.target.value;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        mode: this.state.edit_profile.mode,
        notification: this.state.edit_profile.notification,
        type: type,
        value: this.state.edit_profile.value,
      },
    });
  }

  onChangeEditProfileNotification(event) {
    const notification = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        mode: this.state.edit_profile.mode,
        notification: notification,
        type: this.state.edit_profile.type,
        value: this.state.edit_profile.value,
      },
    });
  }

  onChangeEditProfileActive(event) {
    const active = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        mode: this.state.edit_profile.mode,
        notification: this.state.edit_profile.notification,
        type: this.state.edit_profile.type,
        value: this.state.edit_profile.value,
        address_line_1: this.state.edit_profile.address_line_1,
        address_line_2: this.state.edit_profile.address_line_2,
        address_telephone: this.state.edit_profile.address_telephone,
        city: this.state.edit_profile.city,
        state: this.state.edit_profile.state,
        zip: this.state.edit_profile.zip,
        country: this.state.edit_profile.country,
        country_code: this.state.edit_profile.country_code,
        website: this.state.edit_profile.website,
        is_active: active,
        shipping_only: this.state.edit_profile.shipping_only,
        is_doctor_loc: this.state.edit_profile.is_doctor_loc,
        erp_id: this.state.edit_profile.erp_id,
        erp_name: this.state.edit_profile.erp_name,
        facebook_url: this.state.edit_profile.facebook_url,
        instagram_url: this.state.edit_profile.instagram_url,
        address_email: this.state.edit_profile.address_email,
        shopify_address_id: this.state.edit_profile.shopify_address_id,
      },
    });
  }

  /**
   * Called when the user reactivates/deactivates a practice after confirming on modal
   *
   * @function
   */
  onReactivateOrDeactivatePractice() {
    const selected_address = this.props.address_suggestions.find((address) => address.id === this.props.selected_address_id);
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const id = this.state.edit_profile.id;
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);
    let new_value = this.state.edit_profile.value;
    new_value = this.formatValueByMode(mode, new_value);
    this.openUpdateAddressInProgressModal();

    let data = new FormData();

    data.append('value', new_value ? this.capitalizationByMode(new_value.trim(), mode) : '');
    data.append('old_value', old_value);
    data.append('id', id);

    let old_address = this.getAddressById(id);
    let new_address = selected_address ? selected_address : this.state.edit_profile;
    const address_line_1 = selected_address ? selected_address.addr1.trim() : this.state.edit_profile.address_line_1.trim();
    const geo_lat = selected_address ? new_address.geo_lat : '';
    const geo_long = selected_address ? new_address.geo_long : '';

    data.append('shopify_address_id', this.state.edit_profile.shopify_address_id);
    data.append('address_line_1', address_line_1);
    data.append('address_telephone', formatPhoneNumber(this.state.edit_profile.address_telephone, old_address.country));
    data.append('city', new_address.city.trim());
    data.append('state', new_address.state.trim());
    data.append('zip', new_address.zip);
    data.append('country', new_address.country);
    data.append('shipping_only', this.state.edit_profile.shipping_only);
    data.append('is_active', this.state.edit_profile.is_active);
    data.append('is_doctor_loc', this.state.edit_profile.is_doctor_loc);
    data.append('old_address_line_1', old_address.address_line_1);
    data.append('old_city', old_address.city);
    data.append('old_state', old_address.state);
    data.append('old_zip', old_address.zip);
    data.append('old_country', old_address.country);
    data.append('old_shipping_only', old_address.shipping_only);
    data.append('old_is_active', old_address.is_active);
    data.append('geo_lat', geo_lat);
    data.append('geo_long', geo_long);
    data.append('erp_id', this.state.edit_profile.erp_id);
    data.append('erp_name', this.state.edit_profile.erp_name);

    if (old_address.address_line_2) {
      data.append('old_address_line_2', old_address.address_line_2);
    }

    const address_line_2 = selected_address ? selected_address.addr2 : this.state.edit_profile.address_line_2;

    if (address_line_2) {
      data.append('address_line_2', address_line_2.trim());
    }

    if (this.state.edit_profile.website) {
      data.append('website', this.state.edit_profile.website);
    }

    this.saveEditInfo(action, mode, old_address, data);

    this.props.closePracticeActivationModal();
  }

  onChangeEditProfilePractice(event) {
    const shipping_only = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        mode: this.state.edit_profile.mode,
        notification: this.state.edit_profile.notification,
        type: this.state.edit_profile.type,
        value: this.state.edit_profile.value,
        address_line_1: this.state.edit_profile.address_line_1,
        address_line_2: this.state.edit_profile.address_line_2,
        address_telephone: this.state.edit_profile.address_telephone,
        city: this.state.edit_profile.city,
        state: this.state.edit_profile.state,
        zip: this.state.edit_profile.zip,
        country: this.state.edit_profile.country,
        country_code: this.state.edit_profile.country_code,
        website: this.state.edit_profile.website,
        is_active: this.state.edit_profile.is_active,
        shipping_only: shipping_only,
        is_doctor_loc: shipping_only ? false : this.state.edit_profile.is_doctor_loc,
        erp_id: this.state.edit_profile.erp_id,
        erp_name: this.state.edit_profile.erp_name,
        facebook_url: this.state.edit_profile.facebook_url,
        instagram_url: this.state.edit_profile.instagram_url,
        address_email: this.state.edit_profile.address_email,
        shopify_address_id: this.state.edit_profile.shopify_address_id,
      },
    });
  }

  onChangeEditProfileDoctorLoc(event) {
    const locator = event.currentTarget.checked;

    this.setState({
      edit_profile: {
        active: true,
        error: false,
        id: this.state.edit_profile.id,
        mode: this.state.edit_profile.mode,
        notification: this.state.edit_profile.notification,
        type: this.state.edit_profile.type,
        value: this.state.edit_profile.value,
        address_line_1: this.state.edit_profile.address_line_1,
        address_line_2: this.state.edit_profile.address_line_2,
        address_telephone: this.state.edit_profile.address_telephone,
        city: this.state.edit_profile.city,
        state: this.state.edit_profile.state,
        zip: this.state.edit_profile.zip,
        country: this.state.edit_profile.country,
        country_code: this.state.edit_profile.country_code,
        website: this.state.edit_profile.website,
        is_active: this.state.edit_profile.is_active,
        shipping_only: locator ? false : this.state.edit_profile.shipping_only,
        is_doctor_loc: locator,
        erp_id: this.state.edit_profile.erp_id,
        erp_name: this.state.edit_profile.erp_name,
        facebook_url: this.state.edit_profile.facebook_url,
        instagram_url: this.state.edit_profile.instagram_url,
        address_email: this.state.edit_profile.address_email,
        shopify_address_id: this.state.edit_profile.shopify_address_id,
      },
    });
  }

  onChangeEditProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeReferralEmailKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('referral-confirm');

      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeEditProfileKeyUp(e) {
    if (e.target.value.length >= e.target.maxLength) {
      this.setState({
        limit_error: true,
      });
    } else {
      this.setState({
        limit_error: false,
      });
    }
  }

  getAddressWarningByStatus(status) {
    let warning = '';

    switch (status) {
      case 'one':
        warning = 'At least one active address must exist.';
        break;

      case 'deny':
        warning = 'Unable to delete address because it is linked to a current or past case.';
        break;

      case 'not-editable':
        warning = 'Unable to edit address, conflict on the database. Please enter a valid address or ensure the address does not already exist.';
        break;

      default:
    }

    return warning;
  }

  getOldPhoneNumberById(id) {
    const numbers = this.state.profile.phone_numbers;
    let result = '';

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].id === id) {
        result = numbers[i].value;
        break;
      }
    }

    return result;
  }

  getOldPhoneTypeById(id) {
    const numbers = this.state.profile.phone_numbers;
    let result = '';

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].id === id) {
        result = numbers[i].type;
        break;
      }
    }

    return result;
  }

  getOldNotificationById(id) {
    const notifications = this.state.profile.notification_emails;
    let result = '';

    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].id === id) {
        result = notifications[i].notification;
        break;
      }
    }

    return result;
  }
  /**
   * Retrieves previous data value of a particular "mode" which is the field type
   *
   * @function
   * @param {String} mode - Contains the field name value
   * @param {String} id - Contains the id associated with a selection
   * @return {String} Value of the previous field
   */
  getOldValueByMode(mode, id) {
    let old_value = '';
    let list = [];

    switch (mode) {
      case 'first_name':
        old_value = this.state.profile.first_name;

        break;

      case 'last_name':
        old_value = this.state.profile.last_name;

        break;

      case 'edit_number':
        list = this.state.profile.phone_numbers;
        old_value = this.getOldValueById(id, list);
        break;

      case 'edit_email':
        list = this.state.profile.notification_emails;
        old_value = this.getOldValueById(id, list);
        break;

      case 'edit_address':
        list = this.state.profile.shipping_addresses;
        old_value = this.getOldValueById(id, list);
        break;

      case 'rae':
        if (this.state.profile.rae) {
          old_value = JSON.stringify(this.state.profile.rae);
        } else {
          old_value = JSON.stringify({});
        }
        break;

      case 'cst':
        if (this.state.profile.cst) {
          old_value = JSON.stringify(this.state.profile.cst);
        } else {
          old_value = JSON.stringify({});
        }
        break;

      case 'css':
        if (this.state.profile.css) {
          old_value = JSON.stringify(this.state.profile.css);
        } else {
          old_value = JSON.stringify({});
        }
        break;

      case 'npi':
        old_value = this.state.profile.npi_number;
        break;

      case 'facebook':
        old_value = this.state.profile.facebook_url;
        break;

      case 'instagram':
        old_value = this.state.profile.instagram_url;
        break;

      default:
    }

    return old_value;
  }

  getOldTypeByMode(mode, id) {
    let old_type = '';
    let list = [];

    switch (mode) {
      case 'edit_number':
        list = this.state.profile.phone_numbers;
        break;

      case 'edit_email':
        list = this.state.profile.notification_emails;
        break;

      default:
    }

    old_type = this.getOldTypeById(id, list);

    return old_type;
  }

  getOldNotificationByMode(mode, id) {
    let old_notification = '';

    switch (mode) {
      case 'edit_email':
        old_notification = this.getOldNotificationById(id);
        break;

      default:
    }

    return old_notification;
  }

  getOldValueById(id, list) {
    let result = '';

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        result = list[i].value ? list[i].value : list[i].address_name ? list[i].address_name : '';
      }
    }

    return result;
  }

  getOldTypeById(id, list) {
    let result = '';

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        result = list[i].type;
      }
    }

    return result;
  }
  /**
   * Determines the action name
   *
   * @function
   * @param {String} mode - Contains the field name value
   * @return {String} Returns the name of the action
   */
  getActionByMode(mode) {
    let action = '';

    switch (mode) {
      case 'first_name':
        action = 'update_first_name';
        break;

      case 'last_name':
        action = 'update_last_name';
        break;

      case 'edit_number':
        action = 'update_number';
        break;

      case 'edit_email':
        action = 'update_email';
        break;

      case 'edit_address':
        action = 'update_address';
        break;

      case 'types':
        action = 'update_usergroupmember';
        break;

      case 'rae':
        action = 'update_rae';
        break;

      case 'cst':
        action = 'update_cst';
        break;

      case 'css':
        action = 'update_css';
        break;

      case 'npi':
        action = 'update_npi_number';
        break;

      case 'facebook':
      // intentional fall-thru
      // eslint-disable-next-line
      case 'instagram':
        action = 'update_' + mode + '_url';
        break;

      default:
    }

    return action;
  }

  getActionByModeDelete(mode) {
    let action = '';

    switch (mode) {
      case 'edit_number':
        action = 'remove_number';

        break;
      case 'edit_email':
        action = 'remove_email';

        break;

      case 'edit_address':
        action = 'remove_address';

        break;

      default:
    }

    return action;
  }

  isEditableAddress(id, address, old_address) {
    const cases_raw = this.state.cases_raw;
    let is_editable = true;

    if (
      address.value !== old_address.address_name ||
      address.address_line_1 !== old_address.address_line_1 ||
      address.address_line_2 !== old_address.address_line_2
        ? old_address.address_line_2
        : '' || address.city !== old_address.city || address.state !== old_address.state || address.zip !== old_address.zip.toString()
    ) {
      for (let i = 0; i < cases_raw.length; i++) {
        if (cases_raw[i].shipping_address_id === id) {
          is_editable = false;
          break;
        }
      }
    }

    return is_editable;
  }

  capitalizationByMode(value, mode) {
    let new_value = value;

    if (mode !== 'rae' && mode !== 'cst' && mode !== 'css') {
      new_value = new_value.trim();
      if (mode === 'first_name' || mode === 'last_name') {
        let value_split = value.split(' ');

        for (let i = 0; i < value_split.length; i++) {
          value_split[i] = value_split[i].charAt(0).toUpperCase() + value_split[i].slice(1);
        }

        new_value = value_split.join(' ');
      }
    }

    return new_value;
  }
  /**
   * Determines if the social media entried is a valid url
   *
   * @function
   * @param {String} value - Contains the url value entered by the user
   * @param {String} mode - Contains the field name
   * @return {Boolean} Returns true if social media url meets all criteria
   */
  isValidSocialMedia = (value, mode) => {
    if (value === undefined || value === null || value === '') return true;

    const social_media = 'https://www.' + mode + '.com/';
    const social_media_min = social_media + 'a';
    const has_minimum_length = value.length >= social_media_min.length;
    const starts_with_social_media = value.substring(0, social_media.length) === social_media;

    return value && has_minimum_length && starts_with_social_media;
  };
  /**
   * Response when user confirms their modified edit to an account field
   *
   * @function
   * @param {Object} event - Contains the event related to the field
   */

  /**
   * Formats data based on original address input to save new address to db
   * @function
   */
  saveOriginalAddress() {
    const type = this.state.new_profile.type;
    const lastest_id = this.getLatestNumberId();
    const notification = this.state.new_profile.notification;
    const mode = this.state.new_profile.mode;
    let value = this.state.new_profile.value;
    const { geo_lat, geo_long } = this.props.address_suggestions ? { ...this.props.address_suggestions[0] } : { geo_lat: null, geo_long: null };

    value = this.formatValueByMode(mode, value);
    let action = this.getAddActionByMode(mode);

    let data = new FormData();
    data.append('id', lastest_id);
    data.append('notification', notification);
    data.append('type', type);
    data.append('value', mode === 'add_address' ? firstLetterCapital(value.trim()) : value.trim());
    data.append('address_line_1', firstLetterCapital(this.state.new_profile.address_line_1.trim()));
    data.append('address_telephone', formatPhoneNumber(this.state.new_profile.address_telephone, this.state.new_profile.country));
    data.append('city', firstLetterCapital(this.state.new_profile.city.trim()));
    data.append('state', formatState(this.state.new_profile.state, this.state.new_profile.country));
    data.append('zip', this.state.new_profile.zip);
    data.append('country', this.state.new_profile.country);
    data.append('shipping_only', this.state.new_profile.shipping_only);
    data.append('is_active', this.state.new_profile.is_active);
    data.append('is_doctor_loc', this.state.new_profile.is_doctor_loc);
    geo_lat ? data.append('geo_lat', geo_lat) : data.append('geo_lat', '');
    geo_long ? data.append('geo_long', geo_long) : data.append('geo_long', '');

    if (this.state.new_profile.address_line_2) {
      data.append('address_line_2', firstLetterCapital(this.state.new_profile.address_line_2.trim()));
    }

    if (this.state.new_profile.website) {
      data.append('website', this.state.new_profile.website);
    }

    if (this.state.new_profile.instagram_url) {
      data.append('instagram_url', this.state.new_profile.instagram_url);
    }
    if (this.state.new_profile.facebook_url) {
      data.append('facebook_url', this.state.new_profile.facebook_url);
    }
    if (this.state.new_profile.address_email) {
      data.append('address_email', this.state.new_profile.address_email);
    }

    this.openAddAddressInProgressModal();
    this.saveNewInfo(action, data);

    this.props.closeInvalidAddressModal();
  }

  /**
   * Formats data based on original address input to save changes to an existing address
   * @function
   */
  saveOriginalAddressAfterEditing() {
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const id = this.state.edit_profile.id;
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);
    let new_value = this.state.edit_profile.value;
    new_value = this.formatValueByMode(mode, new_value);
    const { geo_lat, geo_long } =
      this.props.address_suggestions && this.props.address_suggestions[0] ? { ...this.props.address_suggestions[0] } : { geo_lat: null, geo_long: null };

    let data = new FormData();

    data.append('value', new_value ? this.capitalizationByMode(new_value.trim(), mode) : '');
    data.append('old_value', old_value);
    data.append('id', id);

    let old_address = this.getAddressById(id);

    data.append('address_line_1', this.state.edit_profile.address_line_1.trim());
    data.append('address_telephone', formatPhoneNumber(this.state.edit_profile.address_telephone, this.state.edit_profile.country));
    data.append('city', this.state.edit_profile.city.trim());
    data.append('state', this.state.edit_profile.state);
    data.append('zip', this.state.edit_profile.zip);
    data.append('country', this.state.edit_profile.country);
    data.append('shipping_only', this.state.edit_profile.shipping_only);
    data.append('is_active', this.state.edit_profile.is_active);
    data.append('is_doctor_loc', this.state.edit_profile.is_doctor_loc);
    data.append('old_address_line_1', old_address.address_line_1);
    data.append('old_city', old_address.city);
    data.append('old_state', old_address.state);
    data.append('old_zip', old_address.zip);
    data.append('old_country', old_address.country);
    data.append('old_shipping_only', old_address.shipping_only);
    data.append('old_is_active', old_address.is_active);
    data.append('geo_lat', '');
    data.append('geo_long', '');
    data.append('erp_id', this.state.edit_profile.erp_id);
    data.append('erp_name', this.state.edit_profile.erp_name);
    geo_lat ? data.append('geo_lat', geo_lat) : data.append('geo_lat', '');
    geo_long ? data.append('geo_long', geo_long) : data.append('geo_long', '');

    if (old_address.address_line_2) {
      data.append('old_address_line_2', old_address.address_line_2);
    }

    if (this.state.edit_profile.address_line_2) {
      data.append('address_line_2', this.state.edit_profile.address_line_2.trim());
    }

    if (this.state.edit_profile.website) {
      data.append('website', this.state.edit_profile.website);
    }

    if (this.state.edit_profile.instagram_url) {
      data.append('instagram_url', this.state.edit_profile.instagram_url);
    }
    if (this.state.edit_profile.facebook_url) {
      data.append('facebook_url', this.state.edit_profile.facebook_url);
    }
    if (this.state.edit_profile.address_email) {
      data.append('address_email', this.state.edit_profile.address_email);
    }

    let reset_invalid_address_modal = true;
    if (old_address.is_active !== this.state.edit_profile.is_active) {
      this.props.openPracticeActivationModal(this.state.edit_profile.is_active);
      reset_invalid_address_modal = false;
    } else {
      this.openUpdateAddressInProgressModal();
      this.saveEditInfo(action, mode, old_address, data);
    }
    this.props.closeInvalidAddressModal(reset_invalid_address_modal);
  }

  /**
   * Formats data based on selected address suggestion to save new address to db
   * @function
   */
  saveAddressSuggestion() {
    const selected_address = this.props.address_suggestions.find((address) => address.id === this.props.selected_address_id);
    const notification = this.state.new_profile.notification;
    const mode = this.state.new_profile.mode;
    const type = this.state.new_profile.type;
    const lastest_id = this.getLatestNumberId();
    let value = this.state.new_profile.value;

    value = this.formatValueByMode(mode, value);
    let action = this.getAddActionByMode(mode);
    let data = new FormData();
    data.append('id', lastest_id);
    data.append('notification', notification);
    data.append('type', type);
    data.append('value', mode === 'add_address' ? firstLetterCapital(value.trim()) : value.trim());
    data.append('address_line_1', selected_address.addr1.trim());
    data.append('address_telephone', formatPhoneNumber(this.state.new_profile.address_telephone, this.state.new_profile.country));
    data.append('city', selected_address.city.trim());
    data.append('state', formatState(selected_address.state, selected_address.country));
    data.append('zip', selected_address.zip);
    data.append('country', selected_address.country);
    data.append('shipping_only', this.state.new_profile.shipping_only);
    data.append('is_active', this.state.new_profile.is_active);
    data.append('is_doctor_loc', this.state.new_profile.is_doctor_loc);
    data.append('geo_lat', selected_address.geo_lat);
    data.append('geo_long', selected_address.geo_long);
    if (selected_address.addr2) {
      data.append('address_line_2', selected_address.addr2.trim());
    }

    if (this.state.new_profile.website) {
      data.append('website', this.state.new_profile.website);
    }
    if (this.state.new_profile.instagram_url) {
      data.append('instagram_url', this.state.new_profile.instagram_url);
    }
    if (this.state.new_profile.facebook_url) {
      data.append('facebook_url', this.state.new_profile.facebook_url);
    }
    if (this.state.new_profile.address_email) {
      data.append('address_email', this.state.new_profile.address_email);
    }

    const duplicate_address_validation = this.formatAddressForDuplicationValidation(selected_address);

    if (this.isNewAddress(duplicate_address_validation, this.state.edit_profile.id)) {
      this.openAddAddressInProgressModal();
      this.saveNewInfo(action, data);
    } else {
      this.setState({
        new_profile: { ...this.state.new_profile, active: true, error: true },
        in_progress: false,
        address_error_msg: 'Please enter a valid address or ensure the address does not already exist',
      });
    }
    this.props.closeInvalidAddressModal();
  }

  /**
   * Formats object based on address suggestion selection to save changes to existing address
   * @function
   */
  saveAddressSuggestionAfterEditing() {
    const selected_address = this.props.address_suggestions.find((address) => address.id === this.props.selected_address_id);
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const id = this.state.edit_profile.id;
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);
    let new_value = this.state.edit_profile.value;
    new_value = this.formatValueByMode(mode, new_value);

    let data = new FormData();

    data.append('value', new_value ? this.capitalizationByMode(new_value.trim(), mode) : '');
    data.append('old_value', old_value);
    data.append('id', id);

    let old_address = this.getAddressById(id);
    data.append('address_line_1', selected_address.addr1.trim());
    data.append('address_telephone', formatPhoneNumber(this.state.edit_profile.address_telephone, this.state.edit_profile.country));
    if (selected_address.city && selected_address.city.trim()) {
      data.append('city', selected_address.city.trim());
    }
    data.append('state', selected_address.state.trim());
    data.append('zip', selected_address.zip);
    data.append('country', this.state.edit_profile.country);
    data.append('shipping_only', this.state.edit_profile.shipping_only);
    data.append('is_active', this.state.edit_profile.is_active);
    data.append('is_doctor_loc', this.state.edit_profile.is_doctor_loc);
    data.append('old_address_line_1', old_address.address_line_1);
    data.append('old_city', old_address.city);
    data.append('old_state', old_address.state);
    data.append('old_zip', old_address.zip);
    data.append('old_country', old_address.country);
    data.append('old_shipping_only', old_address.shipping_only);
    data.append('old_is_active', old_address.is_active);
    data.append('geo_lat', selected_address.geo_lat);
    data.append('geo_long', selected_address.geo_long);
    data.append('erp_id', this.state.edit_profile.erp_id);
    data.append('erp_name', this.state.edit_profile.erp_name);

    if (old_address.address_line_2) {
      data.append('old_address_line_2', old_address.address_line_2);
    }

    if (selected_address.addr2) {
      data.append('address_line_2', selected_address.addr2.trim());
    }

    if (this.state.edit_profile.website) {
      data.append('website', this.state.edit_profile.website);
    }

    if (this.state.edit_profile.instagram_url) {
      data.append('instagram_url', this.state.edit_profile.instagram_url);
    }
    if (this.state.edit_profile.facebook_url) {
      data.append('facebook_url', this.state.edit_profile.facebook_url);
    }
    if (this.state.edit_profile.address_email) {
      data.append('address_email', this.state.edit_profile.address_email);
    }

    const duplicate_address_validation = this.formatAddressForDuplicationValidation(selected_address);

    let reset_invalid_address_modal = true;
    if (this.isNewAddress(duplicate_address_validation, this.state.edit_profile.id)) {
      if (old_address.is_active !== this.state.edit_profile.is_active) {
        this.props.openPracticeActivationModal(this.state.edit_profile.is_active);
        reset_invalid_address_modal = false;
      } else {
        this.openUpdateAddressInProgressModal();
        this.saveEditInfo(action, mode, old_address, data);
      }
    } else {
      this.setState({
        edit_profile: { ...this.state.edit_profile, active: true, error: true },
        in_progress: false,
        address_error_msg: 'Please enter a valid address or ensure the address does not already exist',
      });
    }
    this.props.closeInvalidAddressModal(reset_invalid_address_modal);
  }

  /**
   * Retrieves changes made to the customer support information
   * @function
   * @param {Object} data - Object with old CSI information
   * @return {Object} - Object with new changes
   */
  getCSIChanges(data) {
    let result = data;
    if (result.rae) {
      let notification = result.rae.notification;
      let rae_temp = this.state.all_rae.find((c) => c.value === result.rae.id);

      if (rae_temp) {
        result.rae = rae_temp;
        result.rae.notification = notification;
      } else {
        result.rae.value = result.rae.id;
        result.rae.label = `${result.rae.first_name} ${result.rae.last_name}`;
      }
    } else {
      result.rae = {
        label: 'Select User',
        value: 0,
      };
    }

    if (result.cst) {
      let notification = result.cst.notification;
      let cst_temp = this.state.all_cst.find((c) => c.value === result.cst.id);

      if (cst_temp) {
        result.cst = cst_temp;
        result.cst.notification = notification;
      } else {
        result.cst.value = result.cst.id;
        result.cst.label = `${result.cst.first_name} ${result.cst.last_name}`;
      }
    } else {
      result.cst = {
        label: 'Select User',
        value: 0,
      };
    }

    if (result.css) {
      let notification = result.css.notification;
      let css_temp = this.state.all_css.find((c) => c.value === result.css.id);

      if (css_temp) {
        result.css = css_temp;
        result.css.notification = notification;
      } else {
        result.css.value = result.css.id;
        result.css.label = `${result.css.first_name} ${result.css.last_name}`;
      }
    } else {
      result.css = {
        label: 'Select User',
        value: 0,
      };
    }

    return result;
  }

  /**
   * Calls account action api to save changes to a profile item
   * @function
   * @param {String} action - Account action
   * @param {String} mode - Which profile item is being edited
   * @param {Object} old_address - Address object before the new changes
   * @param {Object} data - Address object with new changes
   */
  saveEditInfo(action, mode, old_address, data) {
    let that = this;
    Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/${action}`, data)
      .then(function (res) {
        let account = res.data;

        let erp_name_error = false;
        if (res.data.erp_error_info) {
          if (mode === 'first_name' || mode === 'last_name') {
            erp_name_error = true;
            that.setState({
              update_name_in_progress: false,
              update_name_error: true,
              erp_error_info: res.data.erp_error_info,
              in_progress: false,
            });
            account = res.data.account;
          } else {
            that.setState({
              edit_profile: {
                ...that.state.edit_profile,
                error: true,
              },
              address_error_msg: `Unable to update the ERP account. ${res.data.erp_error_info.error_message}`,
              in_progress: false,
              modals: { ...that.state.modals, updateAddressInProgressModal: false },
            });
            return false;
          }
        }

        account = that.getCSIChanges(account);

        if (account.npi_number === that.state.default_npi_number) {
          account.npi_number = '';
        }

        that.setState({
          profile: account,
          edit_profile: {
            active: false,
            error: false,
            id: '',
            mode: '',
            notification: false,
            type: '',
            value: '',
            shopify_address_id: null,
            address_line_1: '',
            address_line_2: '',
            address_telephone: '',
            facebook_url: '',
            instagram_url: '',
            address_email: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            website: '',
            is_active: true,
            shipping_only: true,
            is_doctor_loc: false,
            permission_roles: [],
            types: [],
            erp_id: '',
            erp_name: '',
          },
          update_name_in_progress: false,
          address_error_msg: '',
          in_progress: false,
          modals: { ...that.state.modals, updateNameModal: erp_name_error, updateAddressInProgressModal: false },
        });

        if (mode === 'first_name' || mode === 'last_name') {
          that.props.onRefreshList();
        }
      })
      .catch(function (err) {
        that.setState({
          modals: { ...that.state.modals, updateNameModal: false, updateAddressInProgressModal: false },
        });
        if (err.response.status === 400) {
          that.setState({
            edit_profile: {
              ...that.state.edit_profile,
              error: true,
            },
            address_error_msg: 'Unable to update address',
            in_progress: false,
            update_name_in_progress: false,
          });
        } else if (err.response.status === 409 && err.response.data.active_cases) {
          that.setState({
            modals: {
              ...that.state.modals,
              activeCasesModal: true,
            },
            in_progress: false,
            active_cases: err.response.data.active_cases,
          });
        } else if (err.response.status === 409 && that.state.edit_profile.active) {
          that.setState({
            edit_profile: {
              active: true,
              error: true,
              id: that.state.edit_profile.id,
              mode: that.state.edit_profile.mode,
              notification: that.state.edit_profile.notification,
              type: that.state.edit_profile.type,
              value: that.state.edit_profile.value,
              address_line_1: that.state.edit_profile.address_line_1,
              address_line_2: that.state.edit_profile.address_line_2,
              address_telephone: that.state.edit_profile.address_telephone,
              city: that.state.edit_profile.city,
              state: that.state.edit_profile.state,
              zip: that.state.edit_profile.zip,
              country_code: this.state.edit_profile.country_code,
              country: that.state.edit_profile.country,
              website: that.state.edit_profile.website,
              is_active: that.state.edit_profile.is_active,
              shipping_only: that.state.edit_profile.shipping_only,
              is_doctor_loc: that.state.edit_profile.is_doctor_loc,
              facebook_url: that.state.edit_profile.facebook_url,
              instagram_url: that.state.edit_profile.instagram_url,
              address_email: that.state.edit_profile.address_email,
            },
            in_progress: false,
            address_error_msg:
              old_address.is_active === true && that.state.edit_profile.is_active === false
                ? that.getAddressWarningByStatus('in-use')
                : that.getAddressWarningByStatus('not-editable'),
            update_name_in_progress: false,
          });
        } else {
          handleHttpRequestError(err, that);
        }
      });
  }
  /**
   * Checks if the provided phone number is valid.
   *
   * @param {string} value - The phone number to validate.
   * @returns {boolean} Returns true if the phone number is valid, otherwise false.
   */
  isValidPhoneNo(value) {
    try {
      const cleanNumber = value.replace(/[^0-9\+]/g, '');
      const isNumber = parsePhoneNumber(cleanNumber, 'US');
      const isNumberValid = ValidationUtils.validateInternationalPhone(cleanNumber);
      return isNumberValid && isNumber;
    } catch (error) {
      // Do Nothing
    }

    return false;
  }

  isValidFormSubmission(mode, old_value, new_value, new_type, id, country) {
    if (old_value === undefined || new_value === undefined) {
      return false;
    }

    const isEditMode = mode === 'edit_number' || mode === 'edit_email';
    const isValidEdit =
      isEditMode &&
      new_type !== '' &&
      ((mode === 'edit_number' && this.isValidPhoneNo(new_value) && this.isNewPhoneNo(new_value, id)) ||
        (mode === 'edit_email' && ValidationUtils.isValidEmail(new_value) && this.isNewEmail(new_value, id)));
    const isValidName = (mode === 'first_name' || mode === 'last_name') && new_value;
    const isNPIValid = mode === 'npi' && new_value;
    const isEditAddressValid = mode === 'edit_address' && this.isEditAddressValid(mode, id, country);

    return isValidEdit || isValidName || ['types', 'rae', 'cst', 'css'].includes(mode) || isNPIValid || isEditAddressValid;
  }

  isEditAddressValid(mode, id, country) {
    const profile = this.state.edit_profile;
    const hasValidProfileInfo = profile.value && profile.address_line_1 !== '' && isPhoneNumberValid(profile.address_telephone, country);
    const hasValidURLs =
      (!profile.website || ValidationUtils.isValidURL(profile.website)) &&
      isValidSocialMedia(profile.facebook_url, 'facebook') &&
      isValidSocialMedia(profile.instagram_url, 'instagram');
    const hasValidEmail = !profile.address_email || isValidEmail(profile.address_email);
    const hasValidLocation = isValidLocation(profile.city, profile.state, profile.zip, profile.country);
    const isNewAndActiveAddress = this.isNewAddress(profile, id) && this.hasAtLeastOneValidAddress(id, profile.is_active);

    return hasValidProfileInfo && hasValidURLs && hasValidEmail && hasValidLocation && isNewAndActiveAddress;
  }

  /**
   * Handles event when user saves changes to the profile
   * @function
   * @param {Object} event - Event object
   */
  onEditProfileConfirm(event) {
    setTokenHeader();
    const that = this;
    const id = this.state.edit_profile.id;
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const new_type = this.state.edit_profile.type;
    const old_type = this.getOldTypeByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const notification = this.state.edit_profile.notification;
    const old_notification = this.getOldNotificationByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);
    window.$('[data-toggle="tooltip"]').tooltip('hide');

    let new_value = this.state.edit_profile.value;

    new_value = that.formatValueByMode(mode, new_value);

    this.setState({
      in_progress: true,
      limit_error: false,
    });

    if (this.isValidFormSubmission(mode, old_value, new_value, new_type, id, this.state.edit_profile.country)) {
      if ((mode === 'first_name' || mode === 'last_name') && new_value && that.state.profile.role === 'Doctor') {
        this.openUpdateNameModal(mode);
      } else {
        let data = new FormData();
        let old_address = [];

        if (mode === 'types') {
          data.append('value', this.state.edit_profile.types);
        } else if (mode === 'rae' || mode === 'cst' || mode === 'css') {
          data.append('value', new_value);
        } else {
          data.append('value', new_value ? that.capitalizationByMode(new_value.trim(), mode) : '');
        }
        data.append('old_value', old_value);
        data.append('type', new_type);
        data.append('old_type', old_type);
        data.append('notification', notification);
        data.append('old_notification', old_notification);
        data.append('id', id);

        if (mode === 'edit_address') {
          old_address = this.getAddressById(id);
          data.append('shopify_address_id', this.state.edit_profile.shopify_address_id);
          data.append('address_line_1', firstLetterCapital(that.state.edit_profile.address_line_1.trim()));
          data.append('address_telephone', formatPhoneNumber(this.state.edit_profile.address_telephone, that.state.edit_profile.country));
          data.append('city', firstLetterCapital(that.state.edit_profile.city.trim()));
          data.append('state', formatState(that.state.edit_profile.state, that.state.edit_profile.country));
          data.append('zip', that.state.edit_profile.zip);
          data.append('country', that.state.edit_profile.country);
          data.append('shipping_only', that.state.edit_profile.shipping_only);
          data.append('is_active', that.state.edit_profile.is_active);
          data.append('is_doctor_loc', that.state.edit_profile.is_doctor_loc);
          data.append('old_address_line_1', old_address.address_line_1);
          data.append('old_city', old_address.city);
          data.append('old_state', old_address.state);
          data.append('old_zip', old_address.zip);
          data.append('old_country', old_address.country);
          data.append('old_shipping_only', old_address.shipping_only);
          data.append('old_is_active', old_address.is_active);
          data.append('erp_id', that.state.edit_profile.erp_id);
          data.append('erp_name', that.state.edit_profile.erp_name);
          data.append('old_address_telephone', old_address.address_telephone);

          if (old_address.address_line_2) {
            data.append('old_address_line_2', old_address.address_line_2);
          }

          if (that.state.edit_profile.address_line_2) {
            data.append('address_line_2', firstLetterCapital(that.state.edit_profile.address_line_2.trim()));
          }

          if (that.state.edit_profile.website) {
            data.append('website', that.state.edit_profile.website);
          }

          if (that.state.edit_profile.instagram_url) {
            data.append('instagram_url', that.state.edit_profile.instagram_url);
          }

          if (that.state.edit_profile.facebook_url) {
            data.append('facebook_url', that.state.edit_profile.facebook_url);
          }

          if (that.state.edit_profile.address_email) {
            data.append('address_email', that.state.edit_profile.address_email);
          }

          const validate_address_data = {
            address_line_1: firstLetterCapital(that.state.edit_profile.address_line_1.trim()),
            address_line_2: firstLetterCapital(that.state.edit_profile.address_line_2?.trim() ?? ''),
            city: firstLetterCapital(that.state.edit_profile.city.trim()),
            state: formatState(that.state.edit_profile.state, that.state.edit_profile.country),
            zip: that.state.edit_profile.zip,
            country: that.state.edit_profile.country,
          };

          if (addressIsChanged(old_address, that.state.edit_profile)) {
            this.setState({
              validate_address_pending: true,
            });
            Axios.post('/apiv3/address/validateaddress', validate_address_data)
              .then((res) => {
                that.setState({
                  validate_address_pending: false,
                });
                if (!res.data.address_is_valid) {
                  that.props.openInvalidAddressModal(validate_address_data, res.data.address_suggestions, true);
                  that.setState({
                    in_progress: false,
                  });
                } else {
                  data.append('geo_lat', res.data.geo_lat);
                  data.append('geo_long', res.data.geo_long);
                  if (old_address.is_active !== that.state.edit_profile.is_active) {
                    that.props.openPracticeActivationModal(that.state.edit_profile.is_active);
                  } else {
                    that.openUpdateAddressInProgressModal();
                    that.saveEditInfo(action, mode, old_address, data);
                  }
                }
              })
              .catch((error) => {
                that.props.openSmartyStreetsErrorModal();

                that.setState({
                  in_progress: false,
                  validate_address_pending: false,
                });
              });
          } else {
            data.append('geo_lat', old_address.geo_lat);
            data.append('geo_long', old_address.geo_long);
            if (old_address.is_active !== that.state.edit_profile.is_active) {
              that.props.openPracticeActivationModal(that.state.edit_profile.is_active);
            } else {
              if (old_address.address_telephone !== that.state.edit_profile.address_telephone) {
                that.openUpdateAddressInProgressModal();
              }
              that.saveEditInfo(action, mode, old_address, data);
            }
          }
        } else {
          that.saveEditInfo(action, mode, old_address, data);
        }
      }
    } else if (this.state.edit_profile.mode === 'permissions_roles') {
      let jobrole_data = new FormData();

      jobrole_data.append('job_role_code', that.state.edit_profile.permission_roles);
      jobrole_data.append('account_id', that.state.profile.account_id);

      if (that.state.profile.permission_roles.join(' ').indexOf('Administrator') === -1 && that.state.edit_profile.permission_roles.length === 0) {
        let edit_profile = that.state.edit_profile;
        edit_profile.error = true;

        that.setState({
          edit_profile: edit_profile,
          in_progress: false,
        });
      } else {
        Axios.post(`/apiV2/job_role/update_jobrole`, jobrole_data).then(function (res) {
          let new_profile = that.state.profile;
          new_profile.permission_roles = res.data.job_roles;

          that.setState({
            profile: new_profile,
            edit_profile: {
              active: false,
              error: false,
              id: '',
              mode: '',
              notification: false,
              type: '',
              value: '',
              address_line_1: '',
              address_line_2: '',
              address_telephone: '',
              city: '',
              state: '',
              zip: '',
              country: '',
              website: '',
              is_active: true,
              shipping_only: false,
              is_doctor_loc: false,
              permission_roles: [],
              erp_id: '',
              erp_name: '',
              facebook_url: '',
              instagram_url: '',
              address_email: '',
            },
            in_progress: false,
          });

          that.props.onRefreshList();
        });
      }
    } else {
      that.setState({
        edit_profile: {
          active: true,
          error: true,
          id: that.state.edit_profile.id,
          mode: that.state.edit_profile.mode,
          notification: that.state.edit_profile.notification,
          type: that.state.edit_profile.type,
          value: that.state.edit_profile.value,
          address_line_1: that.state.edit_profile.address_line_1,
          address_line_2: that.state.edit_profile.address_line_2,
          address_telephone: that.state.edit_profile.address_telephone,
          city: that.state.edit_profile.city,
          state: that.state.edit_profile.state,
          zip: that.state.edit_profile.zip,
          country: that.state.edit_profile.country,
          website: that.state.edit_profile.website,
          is_active: that.state.edit_profile.is_active,
          shipping_only: that.state.edit_profile.shipping_only,
          is_doctor_loc: that.state.edit_profile.is_doctor_loc,
          permission_roles: that.state.edit_profile.permission_roles,
          types: that.state.edit_profile.types,
          erp_id: that.state.edit_profile.erp_id,
          erp_name: that.state.edit_profile.erp_name,
          facebook_url: that.state.edit_profile.facebook_url,
          instagram_url: that.state.edit_profile.instagram_url,
          address_email: that.state.edit_profile.address_email,
        },
        in_progress: false,
        address_error_msg:
          mode === 'edit_address' && !that.hasAtLeastOneValidAddress(id, that.state.edit_profile.is_active)
            ? that.getAddressWarningByStatus('one')
            : !isPhoneNumberValid(that.state.edit_profile.address_telephone, that.state.edit_profile.country)
            ? 'Invalid entries'
            : this.state.edit_profile.website !== null && this.state.edit_profile.website !== '' && !ValidationUtils.isValidURL(this.state.edit_profile.website)
            ? 'Invalid entries'
            : this.state.edit_profile.address_email !== null &&
              this.state.edit_profile.address_email !== '' &&
              !isValidEmail(this.state.edit_profile.address_email)
            ? 'Invalid entries'
            : this.state.edit_profile.instagram_url !== null &&
              this.state.edit_profile.instagram_url !== '' &&
              !isValidSocialMedia(this.state.edit_profile.instagram_url, 'instagram')
            ? 'Invalid entries'
            : this.state.edit_profile.facebook_url !== null &&
              this.state.edit_profile.facebook_url !== '' &&
              !isValidSocialMedia(this.state.edit_profile.facebook_url, 'facebook')
            ? 'Invalid entries'
            : '',

        // Go back here
        missing_error: new_value === '',
      });
    }
  }

  onEditProfileDiscard(event) {
    this.resetDoctorPrograms();
    this.setState({
      error: false,
      edit_profile: {
        active: false,
        error: false,
        id: '',
        mode: '',
        notification: true,
        value: '',
        type: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
        erp_id: '',
        erp_name: '',
      },
      isReferralChecked: this.state.profile.referral,
      isGen2Checked: this.state.profile.gen_2,
      isSmileChecked: this.state.profile.smile,
      navigator_flag: this.state.profile.navigator_flag,
      address_error_msg: '',
      missing_error: false,
      limit_error: false,
      referral_email: this.state.profile.referral_email,
      referral_email_error: false,
    });

    window.$('[data-toggle="tooltip"]').tooltip('hide');
  }

  onAddProfileItemClick(event) {
    if (!this.state.edit_profile.active && !this.state.new_profile.active) {
      this.setState(
        {
          new_profile: {
            active: true,
            error: false,
            mode: event.currentTarget.dataset.mode,
            notification: true,
            type: 'office',
            value: '',
            address_line_1: '',
            address_line_2: '',
            address_telephone: '',
            city: '',
            state: '',
            zip: '',
            country: UNITED_STATES,
            country_code: '1',
            website: '',
            is_active: true,
            shipping_only: false,
            is_doctor_loc: false,
            instagram_url: '',
            facebook_url: '',
            address_email: '',
          },
          address_error_msg: '',
        },
        function () {
          const new_address = document.querySelector('#new_address');
          if (new_address) {
            new_address.scrollIntoView();
          }
        }
      );
    }
  }

  onNewProfileItemDiscard(event) {
    this.setState({
      new_profile: {
        active: false,
        error: false,
        mode: '',
        notification: false,
        type: '',
        value: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
        instagram_url: '',
        facebook_url: '',
        address_email: '',
      },
    });
  }

  onChangeNewProfileItem(event) {
    let value = this.state.new_profile.value;
    let item = event.target.dataset && event.target.dataset.item ? event.target.dataset.item : 'value';
    let address_line_1 = this.state.new_profile.address_line_1;
    let address_line_2 = this.state.new_profile.address_line_2;
    let address_telephone = this.state.new_profile.address_telephone;
    let city = this.state.new_profile.city;
    let state = this.state.new_profile.state;
    let zip = this.state.new_profile.zip;
    let country_code = this.state.new_profile.country_code;
    let country = this.state.new_profile.country;
    let website = this.state.new_profile.website;
    let facebook_url = this.state.new_profile.facebook_url;
    let instagram_url = this.state.new_profile.instagram_url;
    let address_email = this.state.new_profile.address_email;

    const emojiFreeValue = removeEmoji(event.target.value);

    switch (item) {
      case 'value':
        value = emojiFreeValue;
        if (this.state.new_profile.mode === 'add_number') {
          value = phoneNumberCharInternational(emojiFreeValue);
        }
        break;

      case 'address_line_1':
        address_line_1 = emojiFreeValue;
        break;

      case 'address_line_2':
        address_line_2 = emojiFreeValue;
        break;

      case 'address_telephone':
        address_telephone = emojiFreeValue;
        break;

      case 'city':
        city = emojiFreeValue;
        break;

      case 'state':
        if (country === UNITED_STATES) {
          state = removeNumberChar(removeSpecialChar(emojiFreeValue.toUpperCase()));
          state = state.length > 2 ? state.substring(0, 2) : state;
        } else {
          state = removeSpecialChar(emojiFreeValue);
        }
        break;

      case 'zip':
        if (country == UNITED_STATES) {
          zip = zipNumberChar(emojiFreeValue);
        } else {
          zip = emojiFreeValue.toUpperCase();
        }
        break;

      case 'country':
        country = emojiFreeValue;
        country_code = getPhoneCode(country);
        break;

      case 'website':
        website = removeSpaces(emojiFreeValue);
        break;

      case 'facebook_url':
        facebook_url = removeSpaces(emojiFreeValue);
        break;

      case 'instagram_url':
        instagram_url = removeSpaces(emojiFreeValue);
        break;

      case 'address_email':
        address_email = removeSpaces(emojiFreeValue);
        break;

      default:
    }

    this.setState({
      new_profile: {
        active: true,
        error: false,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: this.state.new_profile.type,
        value: value,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        address_telephone: address_telephone,
        city: city,
        state: state,
        zip: zip,
        country_code: country_code,
        country: country,
        website: website,
        is_active: this.state.new_profile.is_active,
        shipping_only: this.state.new_profile.shipping_only,
        is_doctor_loc: this.state.new_profile.is_doctor_loc,
        instagram_url: instagram_url,
        facebook_url: facebook_url,
        address_email: address_email,
      },
      address_error_msg: '',
    });
  }

  onChangeNewProfileType(event) {
    const type = event.target.value;

    this.setState({
      new_profile: {
        active: true,
        error: false,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: type,
        value: this.state.new_profile.value,
        address_line_1: this.state.new_profile.address_line_1,
        address_line_2: this.state.new_profile.address_line_2,
        address_telephone: this.state.new_profile.address_telephone,
        city: this.state.new_profile.city,
        state: this.state.new_profile.state,
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        is_active: this.state.new_profile.is_active,
        shipping_only: this.state.new_profile.shipping_only,
        is_doctor_loc: this.state.new_profile.is_doctor_loc,
        instagram_url: this.state.new_profile.instagram_url,
        facebook_url: this.state.new_profile.facebook_url,
        address_email: this.state.new_profile.address_email,
      },
    });
  }

  onChangeNewProfileNotification(event) {
    const notification = event.currentTarget.checked;

    this.setState({
      new_profile: {
        active: true,
        error: false,
        id: this.state.new_profile.id,
        mode: this.state.new_profile.mode,
        notification: notification,
        type: this.state.new_profile.type,
        value: this.state.new_profile.value,
        address_line_1: this.state.new_profile.address_line_1,
        address_line_2: this.state.new_profile.address_line_2,
        address_telephone: this.state.new_profile.address_telephone,
        city: this.state.new_profile.city,
        state: this.state.new_profile.state,
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        is_active: this.state.new_profile.is_active,
        shipping_only: this.state.new_profile.shipping_only,
        is_doctor_loc: this.state.new_profile.is_doctor_loc,
        instagram_url: this.state.new_profile.instagram_url,
        facebook_url: this.state.new_profile.facebook_url,
        address_email: this.state.new_profile.address_email,
      },
    });
  }

  onChangeNewProfilePractice(event) {
    const shipping_only = event.currentTarget.checked;

    this.setState({
      new_profile: {
        active: true,
        error: false,
        id: this.state.new_profile.id,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: this.state.new_profile.type,
        value: this.state.new_profile.value,
        address_line_1: this.state.new_profile.address_line_1,
        address_line_2: this.state.new_profile.address_line_2,
        address_telephone: this.state.new_profile.address_telephone,
        city: this.state.new_profile.city,
        state: this.state.new_profile.state,
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        is_active: this.state.new_profile.is_active,
        shipping_only: shipping_only,
        is_doctor_loc: shipping_only ? false : this.state.new_profile.is_doctor_loc,
        instagram_url: this.state.new_profile.instagram_url,
        facebook_url: this.state.new_profile.facebook_url,
        address_email: this.state.new_profile.address_email,
      },
    });
  }

  onChangeNewProfileDoctorLoc(event) {
    const locator = event.currentTarget.checked;

    this.setState({
      new_profile: {
        active: true,
        error: false,
        id: this.state.new_profile.id,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: this.state.new_profile.type,
        value: this.state.new_profile.value,
        address_line_1: this.state.new_profile.address_line_1,
        address_line_2: this.state.new_profile.address_line_2,
        address_telephone: this.state.new_profile.address_telephone,
        city: this.state.new_profile.city,
        state: this.state.new_profile.state,
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        is_active: this.state.new_profile.is_active,
        shipping_only: locator ? false : this.state.new_profile.shipping_only,
        is_doctor_loc: locator,
        instagram_url: this.state.new_profile.instagram_url,
        facebook_url: this.state.new_profile.facebook_url,
        address_email: this.state.new_profile.address_email,
      },
    });
  }

  onStatusFilterSelection = (event) => {
    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);
    this.setState({
      status_filter: uri_text,
    });
  };

  onStartDateSelection = (event) => {
    this.setState({
      start_date_filter: event.target.value,
    });
  };

  onEndDateSelection = (event) => {
    this.setState({
      end_date_filter: event.target.value,
    });
  };

  orderCasesByCaseId(cases, sort) {
    return _.orderBy(cases, ['case_id'], [sort]);
  }

  orderCasesByName(cases, sort) {
    return _.orderBy(cases, ['first_name', 'last_name', 'submission_date_priority'], [sort, sort, getSubmissionDateDefaultSort()]);
  }

  orderCasesBySubmissionDate(cases, sort) {
    return _.orderBy(cases, ['submission_date', 'status_priority'], [sort]);
  }

  orderCasesByStatus(cases, sort) {
    return _.orderBy(cases, ['status_priority', 'submission_date_priority'], [sort, sort]);
  }
  orderCasesByAssignedTo(cases, sort) {
    return _.orderBy(cases, ['dso_doctor_name', 'submission_date_priority'], [sort, sort]);
  }

  isNewEmail(value, id = '') {
    const emails = this.state.profile.notification_emails;
    let is_new = true;

    for (let i = 0; i < emails.length; i++) {
      if (emails[i].value === value) {
        if (emails[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  isNewPhoneNo(value, id = '') {
    const phones = this.state.profile.phone_numbers;
    let is_new = true;

    for (let i = 0; i < phones.length; i++) {
      if (phones[i].value === value) {
        if (phones[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }
  /**
   * Determines if the incoming address information is new
   *
   * @function
   * @param {Object} profile - Contains address content
   * @param {String} id - Unique databe id of the address if applicable
   * @return {Boolean} Value that determines if address is new
   */
  isNewAddress(profile, id = '') {
    const addresses = this.state.profile.shipping_addresses;
    let is_new = true;

    const normalizedProfile = {
      address_line_1: profile.address_line_1.trim().toLowerCase(),
      address_line_2: profile.address_line_2 ? profile.address_line_2.trim().toLowerCase() : '',
      city: profile.city ? profile.city.trim().toLowerCase() : '',
      zip: profile.zip.includes('-') ? profile.zip.substring(0, profile.zip.indexOf('-')) : profile.zip,
    };

    for (let address of addresses) {
      const normalizedAddress = {
        address_line_1: address.address_line_1.toLowerCase(),
        address_line_2: address.address_line_2 ? address.address_line_2.toLowerCase() : '',
        city: address.city ? address.city.toLowerCase() : '',
        zip: address.zip.includes('-') ? address.zip.substring(0, address.zip.indexOf('-')) : address.zip,
      };

      if (
        normalizedAddress.address_line_1 === normalizedProfile.address_line_1 &&
        normalizedAddress.address_line_2 === normalizedProfile.address_line_2 &&
        normalizedAddress.city === normalizedProfile.city &&
        normalizedAddress.zip === normalizedProfile.zip &&
        profile.country === address.country
      ) {
        if (address.id !== id) {
          is_new = false;
          break;
        }
      }
    }
    return is_new;
  }

  /**
   * Formats address to feed into isNewAddress function
   *
   * @function
   * @param {Object} address - Contains address content
   * @return {Object} Formatted address object
   */
  formatAddressForDuplicationValidation(address) {
    return {
      address_line_1: firstLetterCapital(address.addr1.trim()),
      address_line_2: firstLetterCapital(address.addr2.trim()),
      city: firstLetterCapital(address.city.trim()),
      zip: address.zip,
      country: address.country,
    };
  }

  formatValueByMode(mode, value) {
    let new_value = value;

    switch (mode) {
      case 'add_number':
      case 'edit_number':
        const parsedPhone = parsePhoneNumber(value, 'US');
        new_value = parsedPhone.format('INTERNATIONAL');
        break;
      case 'first_name':
      case 'last_name':
        new_value = new_value
          .replace(/[^a-z\d\s-]+/gi, '')
          .replace(/\s\s+/g, ' ')
          .trimStart()
          .trimEnd()
          .replace(/^[-\d\s]*/, '');
        break;
      case 'rae':
      case 'cst':
      case 'css':
        new_value = new_value ? JSON.stringify(new_value) : JSON.stringify({});
        break;

      default:
    }

    return new_value;
  }

  getAddActionByMode(mode) {
    let action = mode;

    switch (mode) {
      case 'add_number':
      case 'add_email':
        action = mode;
        break;
      default:
    }

    return action;
  }

  /**
   * Calls account action api to save new profile item
   * @function
   * @param {String} action - Account action
   * @param {Object} data - New profile item with changes
   */
  saveNewInfo(action, data) {
    let that = this;
    Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/${action}`, data)
      .then(function (res) {
        that.setState({
          modals: { ...that.state.modals, addAddressInProgressModal: false },
        });
        if (res.data.erp_error_info) {
          that.setState({
            new_profile: { ...that.state.new_profile, active: true, error: true },
            in_progress: false,
            address_error_msg: res.data.erp_error_info.error_message,
          });
          return false;
        }
        let data = res.data;

        if (data.rae) {
          let notification = data.rae.notification;
          let rae_temp = that.state.all_rae.find((c) => c.value === data.rae.id);

          if (rae_temp) {
            data.rae = rae_temp;
            data.rae.notification = notification;
          } else {
            data.rae.value = data.rae.id;
            data.rae.label = `${data.rae.first_name} ${data.rae.last_name}`;
          }
        } else {
          data.rae = {
            label: 'Select User',
            value: 0,
          };
        }

        if (data.cst) {
          let notification = data.cst.notification;
          let cst_temp = that.state.all_cst.find((c) => c.value === data.cst.id);

          if (cst_temp) {
            data.cst = cst_temp;
            data.cst.notification = notification;
          } else {
            data.cst.value = data.cst.id;
            data.cst.label = `${data.cst.first_name} ${data.cst.last_name}`;
          }
        } else {
          data.cst = {
            label: 'Select User',
            value: 0,
          };
        }

        if (data.css) {
          let notification = data.css.notification;
          let css_temp = that.state.all_css.find((c) => c.value === data.css.id);

          if (css_temp) {
            data.css = css_temp;
            data.css.notification = notification;
          } else {
            data.css.value = data.css.id;
            data.css.label = `${data.css.first_name} ${data.css.last_name}`;
          }
        } else {
          data.css = {
            label: 'Select User',
            value: 0,
          };
        }

        that.setState({
          profile: data,
          new_profile: {
            active: false,
            error: false,
            mode: '',
            notification: false,
            type: '',
            value: '',
            address_line_1: '',
            address_line_2: '',
            address_telephone: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            website: '',
            is_active: true,
            shipping_only: false,
            is_doctor_loc: true,
            instagram_url: '',
            facebook_url: '',
            address_email: '',
          },
          in_progress: false,
        });

        that.props.onRefreshList();
      })
      .catch(function (err) {
        if (err.response.status === 400) {
          that.setState({
            new_profile: { ...that.state.new_profile, active: true, error: true },
            in_progress: false,
            address_error_msg: 'Unable to save address',
          });
        } else if (err.response.status === 409 && that.state.new_profile.active) {
          that.setState({
            new_profile: {
              active: true,
              error: true,
              mode: that.state.new_profile.mode,
              notification: that.state.new_profile.notification,
              type: that.state.new_profile.type,
              value: that.state.new_profile.value,
              address_line_1: that.state.new_profile.address_line_1,
              address_line_2: that.state.new_profile.address_line_2,
              address_telephone: that.state.new_profile.address_telephone,
              city: that.state.new_profile.city,
              state: that.state.new_profile.state,
              zip: that.state.new_profile.zip,
              country: that.state.new_profile.country,
              website: that.state.new_profile.website,
              is_active: that.state.new_profile.is_active,
              shipping_only: that.state.new_profile.shipping_only,
              is_doctor_loc: that.state.new_profile.is_doctor_loc,
              instagram_url: that.state.new_profile.instagram_url,
              facebook_url: that.state.new_profile.facebook_url,
              address_email: that.state.new_profile.address_email,
            },
            in_progress: false,
          });
        } else {
          handleHttpRequestError(err, that);
        }
      });
  }

  isOnNewProfileConfirmValid(mode, type, value) {
    const { new_profile } = this.state;

    const isPhoneConditionMet = this.isValidPhoneNo(value) && value !== '' && mode === 'add_number' && this.isNewPhoneNo(value);
    const isEmailConditionMet = ValidationUtils.isValidEmail(value) && this.isNewEmail(value) && mode === 'add_email';
    const isAddressConditionMet =
      new_profile.value !== '' &&
      new_profile.address_line_1 !== '' &&
      (new_profile.website === null || new_profile.website === '' || ValidationUtils.isValidURL(new_profile.website)) &&
      (new_profile.instagram_url === null || new_profile.instagram_url === '' || isValidSocialMedia(new_profile.instagram_url, 'instagram')) &&
      (new_profile.facebook_url === null || new_profile.facebook_url === '' || isValidSocialMedia(new_profile.facebook_url, 'facebook')) &&
      (new_profile.address_email === null || new_profile.address_email === '' || isValidEmail(new_profile.address_email)) &&
      new_profile.city !== '' &&
      isValidState(new_profile.country, new_profile.state) &&
      new_profile.zip !== '' &&
      new_profile.country !== '' &&
      mode === 'add_address' &&
      this.isNewAddress(new_profile);

    return isPhoneConditionMet || isEmailConditionMet || isAddressConditionMet;
  }

  /**
   * Handles event when user adds a new item to the profile
   * @function
   * @param {Object} event - Event object
   */
  onNewProfileItemConfirm(event) {
    setTokenHeader();
    const that = this;
    const type = that.state.new_profile.type;
    const lastest_id = that.getLatestNumberId();
    const notification = that.state.new_profile.notification;
    const mode = that.state.new_profile.mode;
    let value = that.state.new_profile.value;
    this.setState({
      in_progress: true,
      new_profile: { ...this.state.new_profile, error: false },
    });

    if (this.isOnNewProfileConfirmValid(mode, type, value)) {
      value = that.formatValueByMode(mode, value);
      let action = that.getAddActionByMode(mode);

      let data = new FormData();
      data.append('id', lastest_id);
      data.append('notification', notification);
      data.append('type', type);
      data.append('value', mode === 'add_address' ? firstLetterCapital(value.trim()) : value.trim());

      if (mode === 'add_address') {
        data.append('shopify_id', this.state.profile.shopify_id);
        data.append('address_line_1', firstLetterCapital(that.state.new_profile.address_line_1.trim()));
        data.append('address_telephone', formatPhoneNumber(this.state.new_profile.address_telephone, this.state.new_profile.country));
        data.append('city', firstLetterCapital(that.state.new_profile.city.trim()));
        data.append('state', formatState(that.state.new_profile.state, that.state.new_profile.country));
        data.append('zip', that.state.new_profile.zip);
        data.append('country', that.state.new_profile.country);
        data.append('shipping_only', that.state.new_profile.shipping_only);
        data.append('is_active', that.state.new_profile.is_active);
        data.append('is_doctor_loc', that.state.new_profile.is_doctor_loc);
        if (that.state.new_profile.address_line_2) {
          data.append('address_line_2', firstLetterCapital(that.state.new_profile.address_line_2.trim()));
        }

        if (that.state.new_profile.website) {
          data.append('website', that.state.new_profile.website);
        }

        if (that.state.new_profile.instagram_url) {
          data.append('instagram_url', that.state.new_profile.instagram_url);
        }

        if (that.state.new_profile.facebook_url) {
          data.append('facebook_url', that.state.new_profile.facebook_url);
        }

        if (that.state.new_profile.address_email) {
          data.append('address_email', that.state.new_profile.address_email);
        }

        that.setState({
          validate_address_pending: true,
        });

        const validate_address_data = {
          address_line_1: that.state.new_profile.address_line_1.trim(),
          address_line_2: that.state.new_profile.address_line_2 ? that.state.new_profile.address_line_2.trim() : '',
          city: firstLetterCapital(that.state.new_profile.city.trim()),
          state: formatState(that.state.new_profile.state, that.state.new_profile.country),
          zip: that.state.new_profile.zip,
          country: that.state.new_profile.country,
        };

        Axios.post('/apiv3/address/validateaddress', validate_address_data)
          .then((res) => {
            that.setState({
              validate_address_pending: false,
            });
            if (!res.data.address_is_valid) {
              that.props.openInvalidAddressModal(validate_address_data, res.data.address_suggestions);
              that.setState({
                in_progress: false,
              });
            } else {
              data.append('geo_lat', res.data.geo_lat);
              data.append('geo_long', res.data.geo_long);
              that.saveNewInfo(action, data);
            }
          })
          .catch((error) => {
            that.props.openSmartyStreetsErrorModal();

            that.setState({
              in_progress: false,
              validate_address_pending: false,
            });
          });
      } else {
        this.saveNewInfo(action, data);
      }
    } else {
      that.setState({
        new_profile: {
          active: true,
          error: true,
          mode: that.state.new_profile.mode,
          notification: that.state.new_profile.notification,
          type: that.state.new_profile.type,
          value: that.state.new_profile.value,
          address_line_1: that.state.new_profile.address_line_1,
          address_line_2: that.state.new_profile.address_line_2,
          address_telephone: that.state.new_profile.address_telephone,
          city: that.state.new_profile.city,
          state: that.state.new_profile.state,
          zip: that.state.new_profile.zip,
          country: that.state.new_profile.country,
          website: that.state.new_profile.website,
          is_active: that.state.new_profile.is_active,
          shipping_only: that.state.new_profile.shipping_only,
          is_doctor_loc: that.state.new_profile.is_doctor_loc,
          facebook_url: that.state.new_profile.facebook_url,
          instagram_url: that.state.new_profile.instagram_url,
          address_email: that.state.new_profile.address_email,
        },
        in_progress: false,
        address_error_msg:
          this.state.new_profile.value === '' ||
          this.state.new_profile.address_line_1 === '' ||
          this.state.new_profile.city === '' ||
          this.state.new_profile.state === '' ||
          this.state.new_profile.zip === ''
            ? 'Please enter a valid address or ensure the address does not already exist'
            : this.state.new_profile.website !== '' && !ValidationUtils.isValidURL(this.state.new_profile.website)
            ? 'Invalid entries'
            : this.state.new_profile.address_email !== '' && !isValidEmail(this.state.new_profile.address_email)
            ? 'Invalid entries'
            : this.state.new_profile.instagram_url !== '' && !isValidSocialMedia(this.state.new_profile.instagram_url, 'instagram')
            ? 'Invalid entries'
            : this.state.new_profile.facebook_url !== '' && !isValidSocialMedia(this.state.new_profile.facebook_url, 'facebook')
            ? 'Invalid entries'
            : !isPhoneNumberValid(this.state.new_profile.address_telephone, that.state.new_profile.country)
            ? 'Invalid entries'
            : '',
      });
    }
  }

  onEditProfileDelete(event) {
    setTokenHeader();
    const that = this;
    const id = this.state.edit_profile.id;
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const old_type = this.getOldTypeByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const action = this.getActionByModeDelete(this.state.edit_profile.mode);
    const mode = this.state.edit_profile.mode;

    let data = new FormData();
    data.append('value', old_value);
    data.append('type', old_type);
    data.append('id', id);

    this.setState({
      in_progress: true,
    });

    if (
      (mode === 'edit_address' && that.hasAtLeastOneValidAddress(id, that.state.edit_profile.is_active, true) && that.hasZeroCasesAssociatedToAddressId(id)) ||
      mode !== 'edit_address'
    ) {
      Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/${action}`, data)
        .then(function (res) {
          let data = res.data;

          if (data.rae) {
            let notification = data.rae.notification;
            let rae_temp = that.state.all_rae.find((c) => c.value === data.rae.id);

            if (rae_temp) {
              data.rae = rae_temp;
              data.rae.notification = notification;
            } else {
              data.rae.value = data.rae.id;
              data.rae.label = `${data.rae.first_name} ${data.rae.last_name}`;
            }
          } else {
            data.rae = {
              label: 'Select User',
              value: 0,
            };
          }

          if (data.cst) {
            let notification = data.cst.notification;
            let cst_temp = that.state.all_cst.find((c) => c.value === data.cst.id);

            if (cst_temp) {
              data.cst = cst_temp;
              data.cst.notification = notification;
            } else {
              data.cst.value = data.cst.id;
              data.cst.label = `${data.cst.first_name} ${data.cst.last_name}`;
            }
          } else {
            data.cst = {
              label: 'Select User',
              value: 0,
            };
          }

          if (data.css) {
            let notification = data.css.notification;
            let css_temp = that.state.all_css.find((c) => c.value === data.css.id);

            if (css_temp) {
              data.css = css_temp;
              data.css.notification = notification;
            } else {
              data.css.value = data.css.id;
              data.css.label = `${data.css.first_name} ${data.css.last_name}`;
            }
          } else {
            data.css = {
              label: 'Select User',
              value: 0,
            };
          }

          that.setState({
            profile: data,
            edit_profile: {
              active: false,
              error: false,
              id: '',
              value: '',
              type: '',
              address_line_1: '',
              address_line_2: '',
              address_telephone: '',
              city: '',
              state: '',
              zip: '',
              country: '',
              website: '',
              is_active: true,
              shipping_only: false,
              is_doctor_loc: false,
              erp_id: '',
              erp_name: '',
            },
            address_error_msg: '',
            in_progress: false,
          });

          that.props.onRefreshList();
          window.$('.tooltip').remove();
        })
        .catch(function (err) {
          if (err && err.response && err.response.status === 409 && mode === 'edit_address' && that.state.edit_profile.active) {
            that.setState({
              edit_profile: {
                active: true,
                error: true,
                id: that.state.edit_profile.id,
                mode: that.state.edit_profile.mode,
                notification: that.state.edit_profile.notification,
                type: that.state.edit_profile.type,
                value: that.state.edit_profile.value,
                address_line_1: that.state.edit_profile.address_line_1,
                address_line_2: that.state.edit_profile.address_line_2,
                address_telephone: that.state.edit_profile.address_telephone,
                city: that.state.edit_profile.city,
                state: that.state.edit_profile.state,
                zip: that.state.edit_profile.zip,
                country: that.state.edit_profile.country,
                website: that.state.edit_profile.website,
                is_active: that.state.edit_profile.is_active,
                shipping_only: that.state.edit_profile.shipping_only,
                is_doctor_loc: that.state.edit_profile.is_doctor_loc,
                erp_id: that.state.edit_profile.erp_id,
                erp_name: that.state.edit_profile.erp_name,
              },
              in_progress: false,
              address_error_msg: that.hasAtLeastOneValidAddress(id, that.state.edit_profile.is_active, true)
                ? that.getAddressWarningByStatus('deny')
                : that.getAddressWarningByStatus('one'),
            });
          } else {
            handleHttpRequestError(err, that);
          }
        });
    } else {
      that.setState({
        edit_profile: {
          active: true,
          error: true,
          id: that.state.edit_profile.id,
          mode: that.state.edit_profile.mode,
          notification: that.state.edit_profile.notification,
          type: that.state.edit_profile.type,
          value: that.state.edit_profile.value,
          address_line_1: that.state.edit_profile.address_line_1,
          address_line_2: that.state.edit_profile.address_line_2,
          address_telephone: that.state.edit_profile.address_telephone,
          city: that.state.edit_profile.city,
          state: that.state.edit_profile.state,
          zip: that.state.edit_profile.zip,
          country: that.state.edit_profile.country,
          website: that.state.edit_profile.website,
          is_active: that.state.edit_profile.is_active,
          shipping_only: that.state.edit_profile.shipping_only,
          is_doctor_loc: that.state.edit_profile.is_doctor_loc,
          erp_id: that.state.edit_profile.erp_id,
          erp_name: that.state.edit_profile.erp_name,
        },
        in_progress: false,
        address_error_msg: !that.hasAtLeastOneValidAddress(id, that.state.edit_profile.is_active, true)
          ? that.getAddressWarningByStatus('one')
          : !that.hasZeroCasesAssociatedToAddressId(id)
          ? that.getAddressWarningByStatus('deny')
          : '',
      });
    }
  }

  /**
   * On change of the referral program toggle and referral email
   *
   * @function
   * @param {Object} event - Checkbox change event
   */
  onEditProfileReferralProgram(event) {
    setTokenHeader();
    const that = this;
    const action = this.state.profile.referral ? 'turn_off' : 'turn_on';
    Axios.put(`/apiv3/doctor/${this.state.profile.account_id}/referral/${action}`)
      .then(function (res) {
        that.setState((prevState) => ({
          profile: {
            ...prevState.profile,
            referral: !that.state.profile.referral,
          },
        }));
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * On change of the credit hold toggle
   * @function
   * @param {Object} event - Checkbox change event
   */
  onEditProfileToggleCreditHold(event) {
    let message_text = '';
    if (!this.state.profile.credit_hold) {
      if (this.state.profile.role === 'DSO') {
        message_text = `Are you sure you would like to place a credit hold on <b>${this.state.profile.alias_name}</b> account?`;
      } else {
        message_text = `Are you sure you would like to place a credit hold on <b>Dr.${this.state.profile.first_name}  ${this.state.profile.last_name}’s</b> account?`;
      }
    } else {
      if (this.state.profile.role === 'DSO') {
        message_text = `Are you sure you would like to remove the credit hold on <b>${this.state.profile.alias_name}</b> account?`;
      } else {
        message_text = `Are you sure you would like to remove the credit hold on <b>Dr.${this.state.profile.first_name}  ${this.state.profile.last_name}’s</b> account?`;
      }
    }

    this.setState({ creditHoldModal: true, message_text: message_text });
  }

  /**
   * Closes credit hold modal
   * @function
   * @param {Object} event - Button click event
   */
  onCreditHoldDismiss = (event) => {
    this.setState({ creditHoldModal: false, message_text: '' });
  };

  /**
   * Events for Credit hold toggle model turns on or off credit hold
   * @function
   * @param {Object} event - Button click event
   */
  onCreditHoldButtonClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.state.profile.credit_hold ? 'turn_off' : 'turn_on';
    let affected_case_ids = [];
    if (that.state.profile.credit_hold) {
      this.state.initial_on_hold_cases.forEach((cases) => {
        affected_case_ids.push(cases.case_id);
      });
    } else {
      this.state.initial_manufacturing_cases.forEach((cases) => {
        affected_case_ids.push(cases.case_id);
      });
    }

    this.setState({
      in_progress: true,
    });

    const data = { case_ids: affected_case_ids };
    const dso = this.state.profile.role === 'DSO';
    Axios.put(`/apiv3/doctor/${this.state.profile.account_id}/credit_hold/${action}`, data)
      .then(function (res) {
        that.setState((prevState) => ({
          profile: {
            ...prevState.profile,
            credit_hold: !that.state.profile.credit_hold,
          },
          creditHoldModal: false,
          message_text: '',
          in_progress: false,
        }));
        if (action === 'turn_on') {
          if (dso) {
            Axios.post(
              `/api/email/?slug=account-on-credit-hold-dso&doctorId=${that.state.profile.account_id}&method=standard&provider=${window.location.origin}`
            );
          } else {
            Axios.post(`/api/email/?slug=account-on-credit-hold&doctorId=${that.state.profile.account_id}&method=standard&provider=${window.location.origin}`);
          }
          that.sendCaseEmails(that.state.initial_manufacturing_cases, 'assigned-case-on-hold', that.state.profile.account_id);
        } else {
          if (dso) {
            Axios.post(
              `/api/email/?slug=account-remove-credit-hold-dso&doctorId=${that.state.profile.account_id}&method=standard&provider=${window.location.origin}`
            );
          } else {
            Axios.post(
              `/api/email/?slug=account-remove-credit-hold&doctorId=${that.state.profile.account_id}&method=standard&provider=${window.location.origin}`
            );
          }
          Axios.post(
            `/api/email/?slug=remove-credit-hold-expedite-request-pending&doctorId=${that.state.profile.account_id}&method=standard&provider=${window.location.origin}`
          );
          that.sendCaseEmails(that.state.initial_on_hold_cases, 'assigned-case-resumed', that.state.profile.account_id);
        }

        that.props.onRefreshList();
        that.reloadInformation(false);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.setState({
          in_progress: false,
        });
      });
  };

  /**
   * Handles event when user toggles account
   * @function
   */
  onEditProfileToggleAccount() {
    setTokenHeader();
    const action = this.state.profile.is_active ? 'disable' : 'enable';

    if (this.state.profile.role === 'Doctor' || this.state.profile.role === 'DSO') {
      this.setState({
        in_progress: this.state.profile.is_active,
        modals: { ...this.state.modals, accountActivationModal: true },
      });

      if (this.state.profile.is_active) {
        this.enableOrDisableAccount(action);
      }
    } else {
      this.enableOrDisableAccount(action);
    }
  }

  /**
   * Handles event when user confirms to enable account from the modal
   * @function
   */
  onEnableAccountConfirm() {
    this.setState({
      in_progress: true,
    });
    const action = 'enable';
    this.enableOrDisableAccount(action);
  }

  /**
   * Calls API to enable or disable the account
   * @function
   * @param {String} action - Enable or disable identifier
   */
  enableOrDisableAccount(action) {
    const that = this;
    const data = {
      selected_addresses: this.state.account_activation_selected_address_ids,
    };
    Axios.post(`/apiV2/accountaction/${that.state.profile.account_id}/${action}`, data)
      .then(function (res) {
        let data = res.data;
        let account = data;

        if (data.erp_error_info) {
          that.setState({
            in_progress: false,
            account_activation_error: true,
            erp_error_info: data.erp_error_info,
          });
          account = data.account;
        } else {
          that.closeAccountActivationModal();
        }

        if (account.rae) {
          let notification = account.rae.notification;
          let rae_temp = that.state.all_rae.find((c) => c.value === account.rae.id);

          if (rae_temp) {
            account.rae = rae_temp;
            account.rae.notification = notification;
          } else {
            account.rae.value = account.rae.id;
            account.rae.label = `${account.rae.first_name} ${account.rae.last_name}`;
          }
        } else {
          account.rae = {
            label: 'Select User',
            value: 0,
          };
        }

        if (account.cst) {
          let notification = account.cst.notification;
          let cst_temp = that.state.all_cst.find((c) => c.value === account.cst.id);

          if (cst_temp) {
            account.cst = cst_temp;
            account.cst.notification = notification;
          } else {
            account.cst.value = account.cst.id;
            account.cst.label = `${account.cst.first_name} ${account.cst.last_name}`;
          }
        } else {
          account.cst = {
            label: 'Select User',
            value: 0,
          };
        }

        if (account.css) {
          let notification = account.css.notification;
          let css_temp = that.state.all_css.find((c) => c.value === account.css.id);

          if (css_temp) {
            account.css = css_temp;
            account.css.notification = notification;
          } else {
            account.css.value = account.css.id;
            account.css.label = `${account.css.first_name} ${account.css.last_name}`;
          }
        } else {
          account.css = {
            label: 'Select User',
            value: 0,
          };
        }

        that.setState({
          profile: account,
          in_progress: false,
        });

        that.props.onRefreshList();
      })
      .catch(function (err) {
        that.closeAccountActivationModal();
        if (err.response.status === 409 && err.response.data.active_cases) {
          that.setState({
            modals: {
              ...that.state.modals,
              activeCasesModal: true,
            },
            in_progress: false,
            active_cases: err.response.data.active_cases,
          });
        } else {
          handleHttpRequestError(err, that);
          that.setState({
            in_progress: false,
          });
        }
      });
  }

  onChangeNewProfileItemKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  getLatestNumberId() {
    let id = '';

    // Data pass from backend is sorted by created date
    if (this.state.profile && this.state.profile.phone_numbers && this.state.profile.phone_numbers.length > 0) {
      id = this.state.profile.phone_numbers[this.state.profile.phone_numbers.length - 1].id;
    }

    return id;
  }

  hasAccountPermissionToEdit(user_roles_and_permissions) {
    return (
      (userHasPermission('ACCOUNT_DETAILS_IPP_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role === 'Doctor') ||
      (userHasPermission('ACCOUNT_DETAILS_BPP_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role === 'Business') ||
      (userHasPermission('ACCOUNT_DETAILS_DSO_EDIT', user_roles_and_permissions.permissions) && this.state.profile.role.includes('DSO'))
    );
  }

  /**
   * Checks if the user has permission to use credit hold toggle
   * @function
   * @param {Object} user_roles_and_permissions - List of permissions for the user
   * @returns Boolean - Returns if the user has permission to use the toggle
   */
  hasAccountPermissionToUseCreditHold(user_roles_and_permissions) {
    return (
      userHasPermission('DOCTOR_CREDIT_HOLD', user_roles_and_permissions.permissions) &&
      this.state.profile.role !== 'DSO_Doctor' &&
      this.state.profile.role !== 'DSO_Doctor_Override'
    );
  }

  /**
   * Checks if the user has permission to use referral program toggle
   * @function
   * @param {Object} user_roles_and_permissions - List of permissions for the user
   * @returns Boolean - Returns if the user has permission to use the toggle
   */
  hasAccountPermissionToUseReferralProgram(user_roles_and_permissions) {
    return userHasPermission('DOCTOR_REFERRAL_PROGRAM', user_roles_and_permissions.permissions) && !this.state.profile.role.includes('DSO');
  }

  HasPermissionToEditSmileSimulation(user_roles_and_permissions) {
    return userHasPermission('SMILE_SIMULATION_PROGRAM', user_roles_and_permissions.permissions);
  }

  hasPermissionToViewRoles(user_roles_and_permissions) {
    return userHasPermission('ROLES_VIEW', user_roles_and_permissions.permissions) || userHasPermission('ROLES_EDIT', user_roles_and_permissions.permissions);
  }

  hasPermissionToEditRoles(user_roles_and_permissions) {
    return userHasPermission('ROLES_EDIT', user_roles_and_permissions.permissions);
  }

  /**
   * Opens update first/last name modal
   * @function
   */
  openUpdateNameModal(mode) {
    this.setState({
      modals: { ...this.state.modals, updateNameModal: true },
      edit_first_name: mode === 'first_name',
    });
  }

  /**
   * Closes update first/last name modal
   * @function
   */
  closeUpdateNameModal() {
    this.setState({
      modals: { ...this.state.modals, updateNameModal: false },
      in_progress: false,
      update_name_error: false,
    });
  }

  /**
   * Handles event when user updates first/last name after confirming on modal
   * @function
   */
  onEditNameConfirm() {
    setTokenHeader();
    const id = this.state.edit_profile.id;
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const new_type = this.state.edit_profile.type;
    const old_type = this.getOldTypeByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const notification = this.state.edit_profile.notification;
    const old_notification = this.getOldNotificationByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);
    window.$('[data-toggle="tooltip"]').tooltip('hide');

    let new_value = this.state.edit_profile.value;

    new_value = this.formatValueByMode(mode, new_value);

    this.setState({
      update_name_in_progress: true,
      limit_error: false,
    });

    let data = new FormData();
    let old_address = [];

    data.append('value', new_value ? this.capitalizationByMode(new_value.trim(), mode) : '');
    data.append('old_value', old_value);
    data.append('type', new_type);
    data.append('old_type', old_type);
    data.append('notification', notification);
    data.append('old_notification', old_notification);
    data.append('id', id);

    this.saveEditInfo(action, mode, old_address, data);
  }

  /**
   * Gets message to appear on the update first/last name modal
   * @function
   * @return {JSX} Modal message
   */
  getUpdateNameModalMessage() {
    if (this.state.update_name_error) {
      return (
        <div>
          <div>
            The doctor's {this.state.edit_first_name ? 'first' : 'last'} name has been updated in BPP, but the ERP name of the following practice(s) failed to
            be updated in ERP. Please manually update ERP name in ERP.
          </div>
          <br />
          {this.state.profile.shipping_addresses.map((address, index) => {
            let error_message = this.getErpErrorMessage(address);
            return error_message ? (
              <div key={index}>
                <div className="bold-text">{address.address_name}</div>
                <div>
                  {address.address_line_1 && address.address_line_2 ? address.address_line_1 + ' ' + address.address_line_2 : address.address_line_1}
                  {', ' + address.city + ', ' + address.state + ' ' + address.zip}
                </div>
                <div className="update-name-error">{error_message}</div>
                <br />
              </div>
            ) : null;
          })}
        </div>
      );
    } else {
      return (
        <div>
          Are you sure you want to update doctor's {this.state.edit_first_name ? 'first' : 'last'} name? <br /> This will also update the ERP name for all
          practices.
        </div>
      );
    }
  }

  /**
   * Gets ERP account update error message
   * @function
   * @param {Object} address - Address object
   * @return {String} ERP error message
   */
  getErpErrorMessage(address) {
    if (this.state.erp_error_info) {
      for (const key in this.state.erp_error_info) {
        const erp_address = this.state.erp_error_info[key].address;
        if (
          address.address_line_1 === erp_address.address_line_1 &&
          address.city === erp_address.city &&
          address.state === erp_address.state &&
          address.zip === erp_address.zip &&
          !this.state.erp_error_info[key].is_successful
        ) {
          return this.state.erp_error_info[key].error_message;
        }
      }
    }
    return '';
  }

  /**
   * Closes account reactivation/deactivation modal
   * @function
   */
  closeAccountActivationModal() {
    this.setState({
      modals: { ...this.state.modals, accountActivationModal: false },
      account_activation_error: false,
      account_activation_selected_address_ids: [],
    });
  }

  /**
   * Gets header text for account reactivation/deactivation modal
   * @function
   * @returns {JSX} Modal header text
   */
  getAccountActivationModalHeaderText() {
    if (this.state.account_activation_error) {
      return `Practice ${this.state.profile.is_active ? 'Deactivation' : 'Reactivation'} Failed`;
    }
    return `${this.state.profile.is_active ? 'Disable' : 'Enable'} Account`;
  }

  /**
   * Gets message to display in account reactivation/deactivation modal
   * @function
   * @returns {JSX} Modal message
   */
  getAccountActivationModalMessage() {
    if (this.state.account_activation_error) {
      return (
        <div>
          <div>
            The following practice(s) has been {this.state.profile.is_active ? 'reactivated' : 'deactivated'} in BPP, but failed to be{' '}
            {this.state.profile.is_active ? 'reactivated' : 'deactivated'} in ERP. Please manually update below practice(s) in ERP.
          </div>
          <br />
          {this.state.profile.shipping_addresses.map((address, index) => {
            let error_message = this.getErpErrorMessage(address);
            return error_message ? (
              <div key={index}>
                <div className="bold-text">{address.address_name}</div>
                <div>
                  {address.address_line_1 && address.address_line_2 ? address.address_line_1 + ' ' + address.address_line_2 : address.address_line_1}
                  {', ' + address.city + ', ' + address.state + ' ' + address.zip + ' - ' + address.country}
                </div>
                <div className="update-name-error">{error_message}</div>
                <br />
              </div>
            ) : null;
          })}
        </div>
      );
    }
  }
  /**
   * Changes checkbox state when modified in edit mode
   * @function
   */
  onEditFeatureClick(event) {
    var email = this.state.referral_email;
    if (event.currentTarget.name === 'referral_flag') {
      this.setState({ isReferralChecked: event.currentTarget.checked, referral_email_error: false, referral_email: email });
    } else if (event.currentTarget.name === 'gen2_flag') {
      this.setState({ isGen2Checked: event.currentTarget.checked });
    } else if (event.currentTarget.name === 'navigator_flag') {
      this.setState({ navigator_flag: event.currentTarget.checked });
    } else {
      this.setState({ isSmileChecked: event.currentTarget.checked });
    }
  }

  /**
   * Saves Gen2 value based on role and opens confirmation modal for DSO
   * @function
   */
  onFeatureConfirm(event) {
    setTokenHeader();
    const that = this;

    if (event.currentTarget.id === 'referral-confirm') {
      const referral = that.state.isReferralChecked;
      const referral_email = referral ? that.state.referral_email : '';

      if (isValidEmail(referral_email) || !referral) {
        if (referral !== that.state.profile.referral) {
          const action = referral ? 'turn_on' : 'turn_off';

          Axios.put(`/apiv3/doctor/${that.state.profile.account_id}/referral/${action}`)
            .then(function (res) {
              if (action === 'turn_on') {
                Axios.post(
                  `/api/email/?slug=referral-opt-in&doctorId=${that.state.profile.account_id}&method=standard&to=${referral_email}&provider=${window.location.origin}`
                ).catch(function (err) {
                  handleHttpRequestError(err, that);
                });
              } else if (action === 'turn_off') {
                that.setState({
                  referral_email: '',
                });
              }
              that.setState((prevState) => ({
                profile: {
                  ...prevState.profile,
                  referral: referral,
                },
              }));
            })
            .catch(function (err) {
              handleHttpRequestError(err, that);
            });
        }
        if (referral_email !== that.state.profile.referral_email) {
          const action = 'update_email';
          const data = { referral_email: referral_email };
          Axios.put(`/apiv3/doctor/${that.state.profile.account_id}/referral/${action}`, data)
            .then(function (res) {
              that.setState((prevState) => ({
                profile: {
                  ...prevState.profile,
                  referral_email: referral_email,
                },
              }));
            })
            .catch(function (err) {
              handleHttpRequestError(err, that);
            });
        }
      } else {
        that.setState({
          referral_email_error: true,
        });
        return;
      }
    } else if (event.currentTarget.id === 'navigator-confirm') {
      const navigator_flag = that.state.navigator_flag;
      if (navigator_flag !== that.state.profile.navigator_flag) {
        Axios.put(`/apiv3/doctor/${that.state.profile.account_id}/navigator/${navigator_flag}`)
          .then(function (res) {
            that.setState((prevState) => ({
              profile: {
                ...prevState.profile,
                navigator_flag: navigator_flag,
              },
            }));
          })
          .catch(function (err) {
            handleHttpRequestError(err, that);
          });
      }
    } else if (event.currentTarget.id === 'gen2-confirm') {
      if (this.state.profile.role === 'DSO') {
        this.setState({
          showGen2ConfirmationModal: true,
        });
        return;
      } else {
        const gen2 = that.state.isGen2Checked;
        if (gen2 !== that.state.profile.gen2) {
          Axios.put(`/apiv3/doctor/${that.state.profile.account_id}/gen2/${gen2}`)
            .then(function (res) {
              that.setState((prevState) => ({
                profile: {
                  ...prevState.profile,
                  gen_2: gen2,
                },
              }));
            })
            .catch(function (err) {
              handleHttpRequestError(err, that);
            });
        }
      }
    } else {
      const smile = this.state.isSmileChecked;
      if (smile !== this.state.profile.smile) {
        const action = smile ? 'turn_on' : 'turn_off';
        Axios.post(`/apiv3/smilesimulation/${this.state.profile.account_id}/permissions/${action}`)
          .then((res) => {
            that.setState((prevState) => ({
              profile: {
                ...prevState.profile,
                smile: smile,
              },
            }));
            that.props.onRefreshList();
          })
          .catch(function (err) {
            handleHttpRequestError(err, that);
          });
      }
    }
    this.closing_state();
  }

  closing_state() {
    this.setState({
      edit_profile: {},
      isGen2Checked: this.state.isGen2Checked,
      isSmileChecked: this.state.isSmileChecked,
      profile: this.state.profile,
    });

    window.$('[data-toggle="tooltip"]').tooltip('hide');
  }

  /**
   * Update Gen2 value for dso doctors upon DSO Gen change
   * @function
   */
  onGen2Update(event) {
    const that = this;
    const gen2_flag = this.state.isGen2Checked;
    Axios.get(`/apiv3/doctor/${this.state.profile.account_id}/dso`)
      .then(function (res) {
        let dso_doctors = res.data.dso_doctors;
        for (var doc of dso_doctors) {
          if (doc.gen_2 !== gen2_flag) {
            Axios.put(`/apiv3/doctor/${doc.id}/gen2/${gen2_flag}`)
              .then(function (res) {})
              .catch(function (err) {
                handleHttpRequestError(err, that);
              });
          }
        }
        that.setState((prevState) => ({
          profile: {
            ...prevState.profile,
            gen_2: gen2_flag,
          },
          showGen2ConfirmationModal: false,
        }));
        that.closing_state();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * Closes confirmation modal
   * @function
   */
  onGenFlagCancel(event) {
    this.setState({ showGen2ConfirmationModal: false, isGen2Checked: this.state.isGen2Checked });
  }

  /**
   * Handles event when user selects an address in the Enable Account modal
   * @function
   * @param {Number} selected_address_id - ID of address selected
   * @param {Event} event - The checkbox change event
   */
  onAccountActivationAddressChange(selected_address_id, event) {
    let current_selected_addresses = [...this.state.account_activation_selected_address_ids];
    if (event.currentTarget.checked) {
      current_selected_addresses.push(selected_address_id);
    } else {
      current_selected_addresses = current_selected_addresses.filter((address_id) => address_id !== selected_address_id);
    }
    this.setState({
      account_activation_selected_address_ids: current_selected_addresses,
    });
  }

  /**
   * Opens in progress modal when updating an address
   * @function
   */
  openUpdateAddressInProgressModal() {
    this.setState({
      modals: { ...this.state.modals, updateAddressInProgressModal: true },
    });
  }

  /**
   * Opens in progress modal when adding an address
   * @function
   */
  openAddAddressInProgressModal() {
    this.setState({
      modals: { ...this.state.modals, addAddressInProgressModal: true },
    });
  }

  /**
   * Sends emails for list of cases
   *
   * @function
   * @param {list} case_list - List of cases
   * @param {string} slug - Email slug
   * @param {number} account_id - Doctor id
   */
  sendCaseEmails = (case_list, slug, account_id) => {
    for (const cases of case_list) {
      setTimeout(function () {
        Axios.post(
          `/api/email/?slug=${slug}&caseId=${removeCaseIdInitialNumber(cases.case_id)}&method=standard&doctorId=${account_id}&provider=${
            window.location.origin
          }`
        );
      }, 2000);
    }
  };

  /**
   * Updates programs for doctor
   * @function
   * @param {number} program_id - Program ID
   */
  onProgramConfirm = (program_id) => {
    setTokenHeader();
    const that = this;
    const doctor_programs = _.cloneDeep(this.state.doctor_programs);
    const selected_program = doctor_programs.find((program) => program.program_id === program_id);
    const selected_level = selected_program.levels && selected_program.checked ? selected_program.levels.checked : null;
    const data = {
      program_id: program_id,
      checked: selected_program.checked,
      level: selected_level,
    };

    const has_program_update = selected_program.checked !== selected_program.enrolled;
    const has_level_update = selected_program.levels && selected_program.levels.checked !== selected_program.levels.enrolled;

    if (has_program_update || has_level_update) {
      Axios.put(`/apiv3/programenrollment/${that.state.profile.account_id}`, data)
        .then(function (res) {
          doctor_programs.forEach((program) => {
            program.enrolled = program.checked;
            if (program.levels) {
              program.levels.enrolled = program.levels.checked;
            }
          });
          that.setDoctorPrograms(doctor_programs);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          that.resetDoctorPrograms();
        });
    }
    this.closing_state();
  };

  /**
   * Sets doctor programs
   * @function
   * @param {programs} list - List of updated programs
   */
  setDoctorPrograms = (programs) => {
    this.setState({ doctor_programs: programs });
  };

  /**
   * Resets doctor programs to original state
   * @function
   */
  resetDoctorPrograms = () => {
    const doctor_programs = _.cloneDeep(this.state.doctor_programs);
    doctor_programs.forEach((program) => {
      program.checked = program.enrolled;
      if (program.levels) {
        program.levels.checked = program.levels.enrolled;
      }
    });
    this.setState({ doctor_programs: doctor_programs });
  };

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div className="">
          {this.state.loaded === false && this.state.profile.role === '' ? (
            ''
          ) : (
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return (
                  <div>
                    <div className="main-content">
                      <div className="dark-options dark-options-offset">
                        <div className="page-heading page-heading-solo">
                          {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO_Doctor') ? 'Doctor Profile' : 'Account Profile'}{' '}
                        </div>
                        <div className="page-tab-container">
                          <div
                            className={this.state.selected_tab === 'User Details' ? 'page-tab page-active' : 'page-tab'}
                            onClick={this.onTabClick}
                            data-tab="User Details"
                          >
                            User Details
                          </div>
                          {(this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO')) &&
                          userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                            <div
                              className={this.state.selected_tab === 'Cases' ? 'page-tab page-active' : 'page-tab'}
                              onClick={this.onTabClick}
                              data-tab="Cases"
                            >
                              Cases
                            </div>
                          ) : null}

                          <div className="page-tab-content">
                            {this.state.selected_tab === 'User Details' ? (
                              <div className="page-tab-content-section">
                                <div className="page-top-content page-top-content-bottom-space">
                                  <span className="profile-heading profile-w-button">Account Enabled</span>
                                  <span className="profile-info">
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={this.state.profile.is_active}
                                        onChange={this.hasAccountPermissionToEdit(user_roles_and_permissions) ? this.onEditProfileToggleAccount : () => {}}
                                        disabled={
                                          this.state.profile.role.includes('DSO_Doctor') ||
                                          this.state.edit_profile.mode === 'edit_address' ||
                                          this.state.edit_profile.mode === 'Referral' ||
                                          this.state.edit_profile.mode === 'Gen2'
                                        }
                                      />
                                      <span className={'slider round' + (this.state.profile.role.includes('DSO_Doctor') ? ' not-allowed' : '')} />
                                    </label>
                                    {this.state.profile.is_active ? (
                                      <span className={'switch-label' + (this.state.profile.role.includes('DSO_Doctor') ? ' not-allowed' : '')}>On</span>
                                    ) : null}
                                  </span>
                                  {this.state.profile.role !== 'Business' ? (
                                    <React.Fragment>
                                      <span className="profile-heading profile-w-button">Credit Hold</span>
                                      <span className="profile-info">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            checked={this.state.profile.credit_hold}
                                            onChange={
                                              this.hasAccountPermissionToUseCreditHold(user_roles_and_permissions) && this.state.profile.is_active
                                                ? this.onEditProfileToggleCreditHold
                                                : null
                                            }
                                            disabled={
                                              !this.hasAccountPermissionToUseCreditHold(user_roles_and_permissions) ||
                                              !this.state.profile.is_active ||
                                              this.state.edit_profile.mode === 'edit_address' ||
                                              this.state.edit_profile.mode === 'Referral' ||
                                              this.state.edit_profile.mode === 'Gen2'
                                            }
                                          />
                                          <span className={'slider round' + (this.state.profile.role.includes('DSO_Doctor') ? ' not-allowed' : '')} />
                                        </label>
                                        {this.state.profile.is_active ? (
                                          <span className={'switch-label' + (this.state.profile.role.includes('DSO_Doctor') ? ' not-allowed' : '')}>On</span>
                                        ) : null}
                                      </span>
                                    </React.Fragment>
                                  ) : null}

                                  {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                    <span>
                                      {userHasPermission('SALESFORCE_VIEW', user_roles_and_permissions.permissions) ? (
                                        <span className="pull-right profile-icons" data-toggle="tooltip" data-placement="top" title="Salesforce">
                                          <a className="btn-modal" href={this.state.salesforce_url} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={['fab', 'salesforce']} />
                                          </a>
                                        </span>
                                      ) : null}

                                      <SecurityClearance mode="ALL" permission_list={['IPP_VIEW']}>
                                        <span className="pull-right profile-icons" data-toggle="tooltip" data-placement="top" title="Doctor Portal">
                                          <a href={`/portal/${this.state.profile.account_id}/dashboard`} target="_blank" rel="noopener noreferrer">
                                            <div style={{ height: '20px', width: '20px' }}>
                                              <InLogo />
                                            </div>
                                          </a>
                                        </span>
                                      </SecurityClearance>
                                    </span>
                                  ) : null}
                                </div>
                                <div className="page-tab-content-section">
                                  <div className="table-info-view">
                                    <div className="row">
                                      <div className="col-lg-6">
                                        <div className="account-heading">User Information</div>
                                        <div className="account-body">
                                          <table>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <div className="profile-edit-container">
                                                    <div className="profile-edit-section">
                                                      <span
                                                        className={
                                                          this.state.edit_profile.mode === 'first_name'
                                                            ? 'profile-heading profile-heading-notched'
                                                            : 'profile-heading'
                                                        }
                                                      >
                                                        {this.state.profile.role === 'DSO' ? 'Dso Name' : 'First Name'}:
                                                      </span>
                                                    </div>
                                                    {this.state.edit_profile.mode === 'first_name' ? (
                                                      <div className="profile-edit-section">
                                                        <input
                                                          name="first_name"
                                                          id="first_name"
                                                          className={
                                                            'form-control ' +
                                                            (this.state.missing_error || this.state.limit_error ? 'profile-edit-input-error' : '')
                                                          }
                                                          value={this.state.edit_profile.value}
                                                          onChange={this.onChangeEditProfileItem}
                                                          onKeyDown={this.onChangeEditProfileKeyPress}
                                                          maxLength="30"
                                                          onKeyUp={this.onChangeEditProfileKeyUp.bind(this)}
                                                          placeholder="Enter First Name"
                                                          autoFocus
                                                        />
                                                        <div className="profile-edit-section-attach">
                                                          <div
                                                            id="edit-confirm"
                                                            className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                            onClick={this.onEditProfileConfirm}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Save"
                                                          >
                                                            <i className="fa fa-check" aria-hidden="true" />
                                                          </div>
                                                          <div
                                                            id="edit-discard"
                                                            className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                            onClick={this.onEditProfileDiscard}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Cancel"
                                                          >
                                                            <i className="fa fa-times" aria-hidden="true" />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="profile-edit-section profile-edit-window"
                                                        onClick={
                                                          this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                            ? this.onEditClick
                                                            : null
                                                        }
                                                        data-mode="first_name"
                                                        data-value={this.state.profile.first_name}
                                                      >
                                                        <span>{this.state.profile.first_name}</span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                              {this.state.profile.role !== 'DSO' ? (
                                                <tr>
                                                  <td>
                                                    <div className="profile-edit-container">
                                                      <div className="profile-edit-section">
                                                        <span
                                                          className={
                                                            this.state.edit_profile.mode === 'last_name'
                                                              ? 'profile-heading profile-heading-notched'
                                                              : 'profile-heading'
                                                          }
                                                        >
                                                          Last Name:
                                                        </span>
                                                      </div>
                                                      {this.state.edit_profile.mode === 'last_name' ? (
                                                        <div className="profile-edit-section">
                                                          <input
                                                            name="last_name"
                                                            id="last_name"
                                                            className={
                                                              'form-control ' +
                                                              (this.state.missing_error || this.state.limit_error ? 'profile-edit-input-error' : '')
                                                            }
                                                            value={this.state.edit_profile.value}
                                                            onChange={this.onChangeEditProfileItem}
                                                            onKeyDown={this.onChangeEditProfileKeyPress}
                                                            autoFocus
                                                            maxLength="70"
                                                            onKeyUp={this.onChangeEditProfileKeyUp.bind(this)}
                                                          />
                                                          <div className="profile-edit-section-attach">
                                                            <div
                                                              id="edit-confirm"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileConfirm}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Save"
                                                            >
                                                              <i className="fa fa-check" aria-hidden="true" />
                                                            </div>
                                                            <div
                                                              id="edit-discard"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileDiscard}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Cancel"
                                                            >
                                                              <i className="fa fa-times" aria-hidden="true" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div
                                                          className="profile-edit-section profile-edit-window"
                                                          onClick={
                                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                              ? this.onEditClick
                                                              : null
                                                          }
                                                          data-mode="last_name"
                                                          data-value={this.state.profile.last_name}
                                                        >
                                                          <span>{this.state.profile.last_name}</span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}

                                              {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO_Doctor') ? (
                                                <tr>
                                                  <td>
                                                    <div className="profile-edit-container">
                                                      <div className="profile-edit-section">
                                                        <span
                                                          className={
                                                            this.state.edit_profile.mode === 'npi'
                                                              ? 'profile-heading profile-heading-notched'
                                                              : 'profile-heading'
                                                          }
                                                        >
                                                          NPI Number:
                                                        </span>
                                                      </div>
                                                      {this.state.edit_profile.mode === 'npi' ? (
                                                        <div className="profile-edit-section">
                                                          <input
                                                            name="npi_number"
                                                            id="npi_number"
                                                            className={'form-control ' + (this.state.missing_error ? 'profile-edit-input-error' : '')}
                                                            value={this.state.edit_profile.value}
                                                            onChange={this.onChangeEditProfileItem}
                                                            onKeyDown={this.onChangeEditProfileKeyPress}
                                                            autoFocus
                                                            placeholder="Enter NPI Number"
                                                          />
                                                          <div className="profile-edit-section-attach">
                                                            <div
                                                              id="edit-confirm"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileConfirm}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Save"
                                                            >
                                                              <i className="fa fa-check" aria-hidden="true" />
                                                            </div>
                                                            <div
                                                              id="edit-discard"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileDiscard}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Cancel"
                                                            >
                                                              <i className="fa fa-times" aria-hidden="true" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div
                                                          className="profile-edit-section profile-edit-window"
                                                          onClick={
                                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                              ? this.onEditClick
                                                              : null
                                                          }
                                                          data-mode="npi"
                                                          data-value={this.state.profile.npi_number}
                                                        >
                                                          <span className="profile-info">{this.state.profile.npi_number}</span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}

                                              {this.state.profile.role !== 'Doctor' && !this.state.profile.role.includes('DSO') ? (
                                                <tr>
                                                  <td>
                                                    <div className={'profile-edit-container'}>
                                                      <div className={'profile-edit-section'}>
                                                        <span
                                                          className={
                                                            this.state.edit_profile.mode === 'types'
                                                              ? 'profile-heading profile-heading-pull'
                                                              : 'profile-heading'
                                                          }
                                                        >
                                                          User Type(s):
                                                        </span>
                                                      </div>
                                                      {this.state.edit_profile.mode === 'types' ? (
                                                        <div className="profile-edit-section">
                                                          <Datalist
                                                            borderClassName="select-border"
                                                            className="form-control datalist-select-option-medium"
                                                            hideDefaultOption={true}
                                                            multiple={true}
                                                            sortBy="asc"
                                                            name="user_types"
                                                            value={this.state.edit_profile.types}
                                                            change={(e) => this.onChangeList(e, 'types')}
                                                            item="user_types"
                                                            list={this.state.all_user_types}
                                                          />
                                                          <div className="profile-edit-section-attach">
                                                            <div
                                                              id="edit-confirm"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileConfirm}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Save"
                                                            >
                                                              <i className="fa fa-check" aria-hidden="true" />
                                                            </div>
                                                            <div
                                                              id="edit-discard"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileDiscard}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Cancel"
                                                            >
                                                              <i className="fa fa-times" aria-hidden="true" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : this.state.profile && this.state.profile.types ? (
                                                        <div
                                                          className={
                                                            'profile-edit-section profile-edit-window ' +
                                                            (this.hasAccountPermissionToEdit(user_roles_and_permissions) ? 'pointer' : '')
                                                          }
                                                          onClick={this.hasAccountPermissionToEdit(user_roles_and_permissions) ? this.onEditClick : null}
                                                          data-mode="types"
                                                          data-value={this.state.profile.types.join(', ')}
                                                        >
                                                          <div className="multi-selected-fields-container">
                                                            {this.state.profile.types.length > 0 ? (
                                                              <span>{this.getTypeDisplayed()}</span>
                                                            ) : (
                                                              <span>No Types</span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {this.hasPermissionToViewRoles(user_roles_and_permissions) &&
                                              this.state.profile.role !== 'Doctor' &&
                                              !this.state.profile.role.includes('DSO') ? (
                                                <tr>
                                                  <td>
                                                    <div className={'profile-edit-container'}>
                                                      <div className={'profile-edit-section'}>
                                                        <span
                                                          className={
                                                            this.state.edit_profile.mode === 'permissions_roles'
                                                              ? 'profile-heading profile-heading-pull'
                                                              : 'profile-heading'
                                                          }
                                                        >
                                                          User Role(s):
                                                        </span>
                                                      </div>
                                                      {this.state.edit_profile.mode === 'permissions_roles' ? (
                                                        <div className="profile-edit-section">
                                                          <Datalist
                                                            borderClassName="select-border"
                                                            className="form-control datalist-select-option-medium"
                                                            error={this.state.edit_profile.error}
                                                            hideDefaultOption={true}
                                                            multiple={true}
                                                            name="user_roles"
                                                            value={this.state.edit_profile.permission_roles}
                                                            change={(e) => this.onChangeList(e, 'roles')}
                                                            item="user_roles"
                                                            list={this.state.all_job_roles}
                                                          />
                                                          <div className="profile-edit-section-attach">
                                                            <div
                                                              id="edit-confirm"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileConfirm}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Save"
                                                            >
                                                              <i className="fa fa-check" aria-hidden="true" />
                                                            </div>
                                                            <div
                                                              id="edit-discard"
                                                              className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                              onClick={this.onEditProfileDiscard}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Cancel"
                                                            >
                                                              <i className="fa fa-times" aria-hidden="true" />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : this.state.profile && this.state.profile.permission_roles ? (
                                                        <div
                                                          className={
                                                            'profile-edit-section profile-edit-window ' +
                                                            (this.hasPermissionToEditRoles(user_roles_and_permissions) ? 'pointer' : '')
                                                          }
                                                          onClick={this.hasPermissionToEditRoles(user_roles_and_permissions) ? this.onEditClick : null}
                                                          data-mode="permissions_roles"
                                                          data-value={this.state.profile.permission_roles.join(', ')}
                                                        >
                                                          <div className="multi-selected-fields-container">
                                                            {this.state.profile.permission_roles.length > 0 ? (
                                                              <span>{this.state.profile.permission_roles.join(', ')}</span>
                                                            ) : (
                                                              <span>No Roles</span>
                                                            )}
                                                          </div>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                                <tr>
                                                  <td>
                                                    <div className="profile-edit-container">
                                                      <div className="profile-edit-section">
                                                        <span className="profile-heading">Provider ID:</span>
                                                      </div>
                                                      <div className="profile-edit-section">
                                                        <span className="account-plain-text">
                                                          {this.state.profile.provider_id ? this.state.profile.provider_id : 'N/A'}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              <tr>
                                                <td>
                                                  <div className="profile-edit-container">
                                                    <div className="profile-edit-section align-top">
                                                      <span className="profile-heading">Account Email:</span>
                                                    </div>
                                                    <div className="profile-edit-section">
                                                      <span className="account-plain-text fs-exclude">{this.state.profile.account_email}</span>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>

                                              <tr>
                                                <td>
                                                  <div className="profile-edit-container">
                                                    <div className="profile-edit-section">
                                                      <span className="profile-heading">Password:</span>
                                                    </div>
                                                    <div className="profile-edit-section">
                                                      <span className="account-plain-text">
                                                        ****************
                                                        {this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                        !this.state.new_profile.active &&
                                                        !this.state.edit_profile.active &&
                                                        this.state.profile.is_active ? (
                                                          <span className="hyperlink-black" id="send_reset_link" onClick={this.onClickSendNewPassword}>
                                                            Send Password Reset Link
                                                          </span>
                                                        ) : (
                                                          <span className="hyperlink-disabled" id="send_reset_link">
                                                            Send Password Reset Link
                                                          </span>
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                        <div className="user-error-view">
                                          {this.state.missing_error && this.state.edit_profile.mode !== 'edit_email' ? (
                                            <span className="profile-error">Please complete missing field</span>
                                          ) : this.state.limit_error &&
                                            (this.state.edit_profile.mode === 'first_name' || this.state.edit_profile.mode === 'last_name') ? (
                                            <span className="profile-error">Please ensure field is within character limit</span>
                                          ) : null}
                                          {this.state.edit_profile.error &&
                                          (this.state.edit_profile.mode === 'first_name' || this.state.edit_profile.mode === 'last_name') ? (
                                            <span className="profile-error">Unable to save because another process is using the username</span>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-lg-6">
                                        {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                          <React.Fragment>
                                            <div className="account-heading">Support Information</div>
                                            <div className="account-body">
                                              <table>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <div className="profile-edit-container">
                                                        <div className="profile-edit-section">
                                                          <span className="profile-heading">Business Growth Consultant:</span>
                                                        </div>
                                                        {this.state.edit_profile.mode === 'rae' ? (
                                                          <div className="profile-edit-section profile-edit-window profile-edit-window-react-select">
                                                            <Select
                                                              name="rae_list"
                                                              placeholder={this.state.profile.rae}
                                                              value={this.state.edit_profile.value}
                                                              options={this.state.all_rae}
                                                              onChange={(e) => this.onChangeList(e, 'rae')}
                                                              styles={this.state.customStyles}
                                                            />
                                                            {this.state.edit_profile.value.label !== 'Select User' && (
                                                              <input
                                                                className="notifications notifications-react-select"
                                                                type="checkbox"
                                                                checked={
                                                                  this.state.edit_profile.value && this.state.edit_profile.value.notification ? true : false
                                                                }
                                                                onChange={this.onChangeNotification}
                                                              />
                                                            )}
                                                            <div className="profile-edit-section-attach">
                                                              <div
                                                                id="edit-confirm"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileConfirm}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Save"
                                                              >
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </div>
                                                              <div
                                                                id="edit-discard"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileDiscard}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Cancel"
                                                              >
                                                                <i className="fa fa-times" aria-hidden="true" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className={
                                                              'profile-edit-section profile-edit-window ' +
                                                              (this.hasAccountPermissionToEdit(user_roles_and_permissions) ? 'pointer' : '')
                                                            }
                                                            onClick={
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                            data-mode="rae"
                                                            data-value={
                                                              this.state.profile.rae
                                                                ? this.state.all_rae.indexOf(this.state.all_rae.find((r) => r.id === this.state.profile.rae.id))
                                                                : -1
                                                            }
                                                          >
                                                            <span className="csi-display">{this.state.profile.rae.label}</span>
                                                            {this.state.profile.rae.notification ? (
                                                              <span className="notifications">
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </span>
                                                            ) : null}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>

                                                  <tr>
                                                    <td>
                                                      <div className="profile-edit-container">
                                                        <div className="profile-edit-section">
                                                          <span className="profile-heading">Technology Integration Specialist:</span>
                                                        </div>

                                                        {this.state.edit_profile.mode === 'cst' ? (
                                                          <div className="profile-edit-section profile-edit-window profile-edit-window-react-select">
                                                            <Select
                                                              name="cst_list"
                                                              placeholder={this.state.profile.cst}
                                                              value={this.state.edit_profile.value}
                                                              options={this.state.all_cst}
                                                              onChange={(e) => this.onChangeList(e, 'cst')}
                                                              styles={this.state.customStyles}
                                                            />
                                                            {this.state.edit_profile.value.label !== 'Select User' && (
                                                              <input
                                                                className="notifications notifications-react-select"
                                                                type="checkbox"
                                                                checked={
                                                                  this.state.edit_profile.value && this.state.edit_profile.value.notification ? true : false
                                                                }
                                                                onChange={this.onChangeNotification}
                                                              />
                                                            )}
                                                            <div className="profile-edit-section-attach">
                                                              <div
                                                                id="edit-confirm"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileConfirm}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Save"
                                                              >
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </div>
                                                              <div
                                                                id="edit-discard"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileDiscard}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Cancel"
                                                              >
                                                                <i className="fa fa-times" aria-hidden="true" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className={
                                                              'profile-edit-section profile-edit-window ' +
                                                              (this.hasAccountPermissionToEdit(user_roles_and_permissions) ? 'pointer' : '')
                                                            }
                                                            onClick={
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                            data-mode="cst"
                                                            data-value={
                                                              this.state.profile.cst
                                                                ? this.state.all_cst.indexOf(this.state.all_cst.find((c) => c.id === this.state.profile.cst.id))
                                                                : -1
                                                            }
                                                          >
                                                            <span className="csi-display">{this.state.profile.cst.label}</span>
                                                            {this.state.profile.cst.notification ? (
                                                              <span className="notifications">
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </span>
                                                            ) : null}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <div className="profile-edit-container">
                                                        <div className="profile-edit-section">
                                                          <span className="profile-heading">InBrace Concierge:</span>
                                                        </div>

                                                        {this.state.edit_profile.mode === 'css' ? (
                                                          <div className="profile-edit-section profile-edit-window profile-edit-window-react-select">
                                                            <Select
                                                              name="css_list"
                                                              placeholder={this.state.profile.css}
                                                              value={this.state.edit_profile.value}
                                                              options={this.state.all_css}
                                                              onChange={(e) => this.onChangeList(e, 'css')}
                                                              styles={this.state.customStyles}
                                                            />
                                                            {this.state.edit_profile.value.label !== 'Select User' && (
                                                              <input
                                                                className="notifications notifications-react-select"
                                                                type="checkbox"
                                                                checked={
                                                                  this.state.edit_profile.value && this.state.edit_profile.value.notification ? true : false
                                                                }
                                                                onChange={this.onChangeNotification}
                                                              />
                                                            )}
                                                            <div className="profile-edit-section-attach">
                                                              <div
                                                                id="edit-confirm"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileConfirm}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Save"
                                                              >
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </div>
                                                              <div
                                                                id="edit-discard"
                                                                className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                                onClick={this.onEditProfileDiscard}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="Cancel"
                                                              >
                                                                <i className="fa fa-times" aria-hidden="true" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className={
                                                              'profile-edit-section profile-edit-window ' +
                                                              (this.state.edit_profile.mode === 'css' ? 'edit ' : '') +
                                                              (this.hasAccountPermissionToEdit(user_roles_and_permissions) ? 'pointer' : '')
                                                            }
                                                            onClick={
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                            data-mode="css"
                                                            data-value={
                                                              this.state.profile.css
                                                                ? this.state.all_css.indexOf(this.state.all_css.find((c) => c.id === this.state.profile.css.id))
                                                                : -1
                                                            }
                                                          >
                                                            <span className="csi-display">{this.state.profile.css.label}</span>
                                                            {this.state.profile.css.notification ? (
                                                              <span className="notifications">
                                                                <i className="fa fa-check" aria-hidden="true" />
                                                              </span>
                                                            ) : null}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </React.Fragment>
                                        ) : null}
                                      </div>
                                    </div>
                                    {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                      <div className="row">
                                        <div className="col-lg-6">
                                          <div className="account-heading">Features</div>
                                          <table className="table feature-table">
                                            <tbody className="table-inbrace-dark-industry-2">
                                              {/* Referral Checkbox */}
                                              {this.state.profile.role.includes('DSO') ? null : this.state.edit_profile.mode === 'Referral' ? (
                                                <>
                                                  <tr className={this.state.isReferralChecked ? 'pointer opted_in_tr' : 'pointer'}>
                                                    <td className="account-table-start type-width-tb account-table-first vertical-align-middle" colSpan="2">
                                                      <div className="feature-item">Referral Program</div>
                                                      {this.state.isReferralChecked ? (
                                                        <>
                                                          <div className="referral-email-outer-div">
                                                            <div className="feature-item mt_10 email">Email:</div>
                                                            <TextInput
                                                              name="referral_email"
                                                              className={
                                                                this.state.referral_email_error
                                                                  ? 'form-control business-dark-theme-input referral_email_input input-error referral-email-input-field'
                                                                  : 'form-control business-dark-theme-input referral_email_input referral-email-input-field'
                                                              }
                                                              value={this.state.referral_email}
                                                              change={this.onChangeReferralEmail}
                                                              keyDown={this.onChangeReferralEmailKeyPress}
                                                              item="referral_email"
                                                              placeholder="Email (Required)"
                                                              maxLength={100}
                                                            />
                                                          </div>
                                                        </>
                                                      ) : null}
                                                    </td>
                                                    <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                                                      Opted In:
                                                      <input
                                                        className="checkbox-button feature__checkbox"
                                                        type="checkbox"
                                                        name="referral_flag"
                                                        checked={this.state.isReferralChecked}
                                                        onChange={this.onEditFeatureClick}
                                                      />
                                                    </td>
                                                    <td className="type-width-tb">
                                                      <div
                                                        id="referral-confirm"
                                                        className="btn-clear "
                                                        onClick={this.onFeatureConfirm}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Save"
                                                      >
                                                        <i className="fa fa-check" aria-hidden="true" />
                                                      </div>
                                                      <div
                                                        id="edit-discard"
                                                        className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                        onClick={this.onEditProfileDiscard}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="Cancel"
                                                      >
                                                        <i className="fa fa-times" aria-hidden="true" />
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : (
                                                <>
                                                  <tr className={this.state.isReferralChecked ? 'pointer opted_in_tr' : 'pointer'}>
                                                    <td
                                                      className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                      colSpan="2"
                                                      data-mode="Referral"
                                                      onClick={
                                                        this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                        this.hasAccountPermissionToUseReferralProgram(user_roles_and_permissions) &&
                                                        !this.state.profile.role.includes('DSO_Doctor') &&
                                                        this.state.profile.is_active
                                                          ? this.onEditClick
                                                          : null
                                                      }
                                                    >
                                                      <div className="feature-item">Referral Program</div>
                                                    </td>
                                                    <td
                                                      className="account-table-end type-width-tb vertical-align-middle account-table-dark"
                                                      style={{ verticalAlign: 'inherit' }}
                                                      data-mode="Referral"
                                                      onClick={
                                                        this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                        this.hasAccountPermissionToUseReferralProgram(user_roles_and_permissions) &&
                                                        !this.state.profile.role.includes('DSO_Doctor') &&
                                                        this.state.profile.is_active
                                                          ? this.onEditClick
                                                          : null
                                                      }
                                                    >
                                                      Opted In:
                                                      <input
                                                        className="checkbox-button feature__checkbox"
                                                        type="checkbox"
                                                        name="referral_flag"
                                                        readOnly={true}
                                                        checked={this.state.isReferralChecked}
                                                      />
                                                    </td>
                                                    <td className="type-width-tb"></td>
                                                  </tr>
                                                  {this.state.isReferralChecked ? (
                                                    <tr className="pointer opted_in_email_tr_with_icon">
                                                      <td>
                                                        <i className="fa fa-envelope" aria-hidden="true" />
                                                        <span className="account-plain-text" id="referral_email">
                                                          {this.state.referral_email ? (
                                                            <a
                                                              className="sub-item-0 support-link"
                                                              href={'mailto:' + this.state.referral_email + '?Subject=Hello%20Inbrace'}
                                                              target="_top"
                                                            >
                                                              {this.state.referral_email}
                                                            </a>
                                                          ) : (
                                                            'N/A'
                                                          )}
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  ) : null}
                                                </>
                                              )}
                                              {this.state.referral_email_error ? (
                                                <tr className="pointer">
                                                  <td className="type-width-tb" colSpan="4">
                                                    <span className="general-error">Please enter a valid email address.</span>
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {this.state.edit_profile.mode === 'Gen2' ? (
                                                <tr className="pointer">
                                                  <td className="account-table-start type-width-tb account-table-first vertical-align-middle" colSpan="2">
                                                    <div className="feature-item">Gen 2.0</div>
                                                  </td>
                                                  <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="gen2_flag"
                                                      checked={this.state.isGen2Checked}
                                                      onChange={this.onEditFeatureClick}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb">
                                                    <div
                                                      id="gen2-confirm"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onFeatureConfirm}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Save"
                                                    >
                                                      <i className="fa fa-check" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                      id="edit-discard"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onEditProfileDiscard}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Cancel"
                                                    >
                                                      <i className="fa fa-times" aria-hidden="true" />
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : (
                                                <tr className="pointer">
                                                  <td
                                                    className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                    colSpan="2"
                                                    data-mode="Gen2"
                                                    onClick={
                                                      this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                      userHasPermission('DOCTOR_GEN2_FLAG', user_roles_and_permissions.permissions) &&
                                                      this.state.profile.is_active &&
                                                      !this.state.isGen2Checked
                                                        ? this.onEditClick
                                                        : null
                                                    }
                                                  >
                                                    <div className="feature-item">Gen 2.0</div>
                                                  </td>
                                                  <td
                                                    className="account-table-end type-width-tb vertical-align-middle account-table-dark"
                                                    data-mode="Gen2"
                                                    onClick={
                                                      this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                      userHasPermission('DOCTOR_GEN2_FLAG', user_roles_and_permissions.permissions) &&
                                                      this.state.profile.is_active &&
                                                      !this.state.isGen2Checked
                                                        ? this.onEditClick
                                                        : null
                                                    }
                                                  >
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="gen2_flag"
                                                      readOnly={true}
                                                      checked={this.state.profile.gen_2}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb"></td>
                                                </tr>
                                              )}

                                              {this.state.edit_profile.mode === 'Navigator' ? (
                                                <tr className="pointer">
                                                  <td className="account-table-start type-width-tb account-table-first vertical-align-middle" colSpan="2">
                                                    <div className="feature-item">InBrace Navigator</div>
                                                  </td>
                                                  <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="navigator_flag"
                                                      checked={this.state.navigator_flag}
                                                      onChange={this.onEditFeatureClick}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb">
                                                    <div
                                                      id="navigator-confirm"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onFeatureConfirm}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Save"
                                                    >
                                                      <i className="fa fa-check" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                      id="edit-discard"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onEditProfileDiscard}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Cancel"
                                                    >
                                                      <i className="fa fa-times" aria-hidden="true" />
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : (
                                                <tr className="pointer">
                                                  <td
                                                    className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                    colSpan="2"
                                                    data-mode="Navigator"
                                                    onClick={
                                                      this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                      userHasPermission('INBRACE NAVIGATOR', user_roles_and_permissions.permissions) &&
                                                      this.state.profile.is_active
                                                        ? this.onEditClick
                                                        : null
                                                    }
                                                  >
                                                    <div className="feature-item">InBrace Navigator</div>
                                                  </td>
                                                  <td
                                                    className="account-table-end type-width-tb vertical-align-middle account-table-dark"
                                                    data-mode="Navigator"
                                                    onClick={
                                                      this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                      userHasPermission('INBRACE NAVIGATOR', user_roles_and_permissions.permissions) &&
                                                      this.state.profile.is_active
                                                        ? this.onEditClick
                                                        : null
                                                    }
                                                  >
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="navigator_flag"
                                                      readOnly={true}
                                                      checked={this.state.navigator_flag}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb"></td>
                                                </tr>
                                              )}

                                              {/* Smile Simulation Checkbox */}
                                              {!this.state.profile.role.includes('DSO') ? null : this.state.edit_profile.mode === 'Smile' ? (
                                                <tr className="pointer">
                                                  <td className="account-table-start type-width-tb account-table-first vertical-align-middle" colSpan="2">
                                                    <div className="feature-item">Smile Simulation</div>
                                                  </td>
                                                  <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="smile_flag"
                                                      checked={this.state.isSmileChecked}
                                                      onChange={this.onEditFeatureClick}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb">
                                                    <div
                                                      id="smile-confirm"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onFeatureConfirm}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Save"
                                                    >
                                                      <i className="fa fa-check" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                      id="edit-discard"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onEditProfileDiscard}
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Cancel"
                                                    >
                                                      <i className="fa fa-times" aria-hidden="true" />
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : (
                                                <tr className="pointer">
                                                  <td
                                                    className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                    colSpan="2"
                                                    data-mode="Smile"
                                                    onClick={
                                                      this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                      this.HasPermissionToEditSmileSimulation(user_roles_and_permissions) &&
                                                      !this.state.dsoSmileDisabled &&
                                                      this.state.profile.is_active
                                                        ? this.onEditClick
                                                        : null
                                                    }
                                                  >
                                                    <div className="feature-item">Smile Simulation</div>
                                                  </td>
                                                  <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                                                    Opted In:
                                                    <input
                                                      className="checkbox-button feature__checkbox"
                                                      type="checkbox"
                                                      name="smile_flag"
                                                      readOnly={true}
                                                      checked={this.state.profile.smile}
                                                    />
                                                  </td>
                                                  <td className="type-width-tb"></td>
                                                </tr>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    ) : null}
                                    {['DSO_Doctor', 'Doctor', 'DSO', 'DSO_Doctor_Override'].includes(this.state.profile.role) ? (
                                      <div className="row">
                                        <Programs
                                          doctor_programs={this.state.doctor_programs}
                                          user_permissions={user_roles_and_permissions.permissions}
                                          edit_mode={this.state.edit_profile.mode}
                                          in_progress={this.state.in_progress}
                                          onProgramConfirm={this.onProgramConfirm}
                                          onEditProfileCancel={this.onEditProfileDiscard}
                                          onEditClick={this.onEditClick}
                                          setDoctorPrograms={this.setDoctorPrograms}
                                          dso_data={this.state.dso_data}
                                          profile={this.state.profile}
                                        />
                                      </div>
                                    ) : null}
                                    <div className="row">
                                      {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                        <div className="col-lg-6">
                                          <div className="account-heading">Emails</div>
                                          <table className="table table-condensed table-industry account-table">
                                            <thead className="table-inbrace-dark-industry-2">
                                              <tr>
                                                <th className="type-width-tb">Type</th>
                                                <th className="auto-width-tb">Email</th>
                                                <th className="notify-width-tb">Notifications</th>
                                                <th className="edit-width-tb" />
                                              </tr>
                                            </thead>
                                            <tbody className="table-inbrace-dark-industry-2">
                                              {this.state.profile.notification_emails.length > 0 ? (
                                                this.state.profile.notification_emails.map((email, index) => {
                                                  return (
                                                    <tr className="pointer" key={index}>
                                                      {this.state.edit_profile.id === email.id && this.state.edit_profile.mode === 'edit_email' ? (
                                                        <td className="account-table-start type-width-tb">
                                                          <select
                                                            className="select-filter"
                                                            value={this.state.edit_profile.type}
                                                            onChange={this.onChangeEditProfileType}
                                                          >
                                                            <option value="office">Office</option>
                                                            <option value="personal">Personal</option>
                                                          </select>
                                                        </td>
                                                      ) : (
                                                        <td
                                                          className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                          data-id={email.id}
                                                          data-value={email.value}
                                                          data-type={email.type}
                                                          data-mode="edit_email"
                                                          data-notification={email.notification}
                                                          onClick={
                                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                              ? this.onEditClick
                                                              : null
                                                          }
                                                        >
                                                          {email.type}
                                                        </td>
                                                      )}
                                                      {this.state.edit_profile.id === email.id &&
                                                      this.state.edit_profile.mode === 'edit_email' &&
                                                      email.value.toLowerCase() !== this.state.profile.account_email.toLowerCase() ? (
                                                        <td className="auto-width-tb">
                                                          <input
                                                            name="email"
                                                            className={
                                                              this.state.edit_profile.error && this.state.edit_profile.mode === 'edit_email'
                                                                ? 'form-control business-dark-theme-input input-error'
                                                                : 'form-control business-dark-theme-input'
                                                            }
                                                            value={this.state.edit_profile.value}
                                                            onChange={this.onChangeEditProfileItem}
                                                            onKeyDown={this.onChangeEditProfileKeyPress}
                                                            maxLength={100}
                                                            autoFocus
                                                          />
                                                        </td>
                                                      ) : (
                                                        <td
                                                          className="auto-width-tb vertical-align-middle fs-exclude"
                                                          data-id={email.id}
                                                          data-value={email.value}
                                                          data-type={email.type}
                                                          data-mode="edit_email"
                                                          data-notification={email.notification}
                                                          onClick={
                                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                              ? this.onEditClick
                                                              : null
                                                          }
                                                        >
                                                          {truncateTextByLimit(email.value, 40)}
                                                        </td>
                                                      )}

                                                      {this.state.edit_profile.id === email.id && this.state.edit_profile.mode === 'edit_email' ? (
                                                        <td className="account-table-end text-center vertical-align-middle">
                                                          <input
                                                            className="checkbox-button"
                                                            type="checkbox"
                                                            name="notification"
                                                            value="notify"
                                                            checked={this.state.edit_profile.notification}
                                                            onChange={this.onChangeEditProfileNotification}
                                                          />
                                                        </td>
                                                      ) : (
                                                        <td
                                                          className="account-table-end text-center vertical-align-middle"
                                                          data-id={email.id}
                                                          data-value={email.value}
                                                          data-type={email.type}
                                                          data-mode="edit_email"
                                                          data-notification={email.notification}
                                                          onClick={
                                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                              ? this.onEditClick
                                                              : null
                                                          }
                                                        >
                                                          {email.notification ? <i className="fa fa-check" aria-hidden="true" /> : null}
                                                        </td>
                                                      )}
                                                      {this.state.edit_profile.id === email.id && this.state.edit_profile.mode === 'edit_email' ? (
                                                        <td>
                                                          <div
                                                            id="edit-confirm"
                                                            className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                            onClick={this.onEditProfileConfirm}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Save"
                                                          >
                                                            <i className="fa fa-check" aria-hidden="true" />
                                                          </div>
                                                          <div
                                                            id="edit-discard"
                                                            className="btn-clear"
                                                            onClick={this.onEditProfileDiscard}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="Cancel"
                                                          >
                                                            <i className="fa fa-times" aria-hidden="true" />
                                                          </div>
                                                          {email.value.toLowerCase() !== this.state.profile.account_email.toLowerCase() ? (
                                                            <div
                                                              className="btn-clear"
                                                              onClick={this.onEditProfileDelete}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Delete"
                                                            >
                                                              <i className="fa fa-trash" aria-hidden="true" />
                                                            </div>
                                                          ) : null}
                                                        </td>
                                                      ) : (
                                                        <td className="edit-width-tb" />
                                                      )}
                                                    </tr>
                                                  );
                                                })
                                              ) : this.state.new_profile.active && this.state.new_profile.mode === 'add_email' ? null : (
                                                <tr>
                                                  <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="3">
                                                    - - - - - No Notification Email Found - - - - -
                                                  </td>
                                                  <td />
                                                </tr>
                                              )}

                                              {this.state.new_profile.active && this.state.new_profile.mode === 'add_email' ? (
                                                <tr>
                                                  <td className="type-width-tb account-table-start">
                                                    <select
                                                      className="select-filter"
                                                      value={this.state.new_profile.type}
                                                      onChange={this.onChangeNewProfileType}
                                                    >
                                                      <option value="office">Office</option>
                                                      <option value="personal">Personal</option>
                                                    </select>
                                                  </td>
                                                  <td>
                                                    <input
                                                      name="email"
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.mode === 'add_email'
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.value}
                                                      onChange={this.onChangeNewProfileItem}
                                                      onKeyDown={this.onChangeNewProfileItemKeyPress}
                                                      maxLength="100"
                                                      autoFocus
                                                    />
                                                  </td>

                                                  <td className="text-center account-table-end">
                                                    <input
                                                      className="checkbox-button"
                                                      type="checkbox"
                                                      name="notification"
                                                      value="notify"
                                                      checked={this.state.new_profile.notification}
                                                      onChange={this.onChangeNewProfileNotification}
                                                    />
                                                  </td>
                                                  <td>
                                                    <div
                                                      id="new-confirm"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Save"
                                                      className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                      onClick={this.onNewProfileItemConfirm}
                                                    >
                                                      <i className="fa fa-check" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                      id="new-discard"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Cancel"
                                                      className="btn-clear"
                                                      onClick={this.onNewProfileItemDiscard}
                                                    >
                                                      <i className="fa fa-times" aria-hidden="true" />
                                                    </div>
                                                  </td>
                                                </tr>
                                              ) : null}
                                            </tbody>
                                          </table>
                                          <div>
                                            {(this.state.new_profile.error || this.state.edit_profile.error) &&
                                            (this.state.new_profile.mode === 'add_email' || this.state.edit_profile.mode === 'edit_email') ? (
                                              <span className="general-error">
                                                Please enter a valid email address or ensure the email address does not already exist
                                              </span>
                                            ) : null}
                                            {!this.state.new_profile.active &&
                                            !this.state.edit_profile.active &&
                                            this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                            this.state.profile.is_active ? (
                                              <div className="account-add" data-mode="add_email" onClick={this.onAddProfileItemClick}>
                                                Add{' '}
                                                <i
                                                  className="fa fa-plus-circle"
                                                  aria-hidden="true"
                                                  data-mode="add_email"
                                                  onClick={this.onAddProfileItemClick}
                                                />
                                              </div>
                                            ) : (
                                              <div className="account-add" />
                                            )}
                                          </div>
                                        </div>
                                      ) : null}
                                      <div className="col-lg-6">
                                        <div className="account-heading">Telephone</div>
                                        <table className="table table-condensed table-industry account-table">
                                          <thead className="table-inbrace-dark-industry-2">
                                            <tr>
                                              <th className="type-width-tb">Type</th>
                                              <th className="auto-width-tb">Number</th>
                                              <th className="edit-width-tb" />
                                            </tr>
                                          </thead>
                                          <tbody className="table-inbrace-dark-industry-2">
                                            {this.state.profile.phone_numbers.length > 0 ? (
                                              this.state.profile.phone_numbers.map((phone, index) => {
                                                return (
                                                  <tr className="pointer" key={index}>
                                                    {this.state.edit_profile.id === phone.id && this.state.edit_profile.mode === 'edit_number' ? (
                                                      <td className="account-table-start type-width-tb vertical-align-middle">
                                                        <select
                                                          className="select-filter"
                                                          value={this.state.edit_profile.type}
                                                          onChange={this.onChangeEditProfileType}
                                                        >
                                                          <option value="office">Office</option>
                                                          <option value="home">Home</option>
                                                          <option value="mobile">Mobile</option>
                                                        </select>
                                                      </td>
                                                    ) : (
                                                      <td
                                                        className="account-table-start type-width-tb account-table-first vertical-align-middle"
                                                        data-id={phone.id}
                                                        data-value={phone.value}
                                                        data-type={phone.type}
                                                        data-mode="edit_number"
                                                        onClick={
                                                          this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                            ? this.onEditClick
                                                            : null
                                                        }
                                                      >
                                                        {phone.type}
                                                      </td>
                                                    )}

                                                    {this.state.edit_profile.id === phone.id && this.state.edit_profile.mode === 'edit_number' ? (
                                                      <td className="account-table-end auto-width-tb">
                                                        <input
                                                          name="phone"
                                                          className={
                                                            this.state.edit_profile.error && this.state.edit_profile.mode === 'edit_number'
                                                              ? 'form-control business-dark-theme-input input-error'
                                                              : 'form-control business-dark-theme-input'
                                                          }
                                                          placeholder="Country Code + Phone Number"
                                                          value={this.state.edit_profile.value}
                                                          onChange={this.onChangeEditProfileItem}
                                                          onKeyDown={this.onChangeEditProfileKeyPress}
                                                          autoFocus
                                                        />
                                                      </td>
                                                    ) : (
                                                      <td
                                                        className="account-table-end auto-width-tb vertical-align-middle fs-exclude"
                                                        data-id={phone.id}
                                                        data-value={phone.value}
                                                        data-type={phone.type}
                                                        data-mode="edit_number"
                                                        onClick={
                                                          this.hasAccountPermissionToEdit(user_roles_and_permissions) && this.state.profile.is_active
                                                            ? this.onEditClick
                                                            : null
                                                        }
                                                      >
                                                        {phone.value}
                                                      </td>
                                                    )}

                                                    {this.state.edit_profile.id === phone.id && this.state.edit_profile.mode === 'edit_number' ? (
                                                      <td className="auto-width-tb">
                                                        <div
                                                          id="edit-confirm"
                                                          className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                          onClick={this.onEditProfileConfirm}
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Save"
                                                        >
                                                          <i className="fa fa-check" aria-hidden="true" />
                                                        </div>
                                                        <div
                                                          id="edit-discard"
                                                          className="btn-clear"
                                                          onClick={this.onEditProfileDiscard}
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Cancel"
                                                        >
                                                          <i className="fa fa-times" aria-hidden="true" />
                                                        </div>
                                                        <div
                                                          className="btn-clear"
                                                          onClick={this.onEditProfileDelete}
                                                          data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Delete"
                                                        >
                                                          <i className="fa fa-trash" aria-hidden="true" />
                                                        </div>
                                                      </td>
                                                    ) : (
                                                      <td className="edit-width-tb" />
                                                    )}
                                                  </tr>
                                                );
                                              })
                                            ) : this.state.new_profile.active && this.state.new_profile.mode === 'add_number' ? null : (
                                              <tr>
                                                <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="2">
                                                  - - - - - No Phone Number Found (Optional) - - - - -
                                                </td>
                                                <td />
                                              </tr>
                                            )}

                                            {this.state.new_profile.active && this.state.new_profile.mode === 'add_number' ? (
                                              <tr>
                                                <td className="type-width-tb account-table-start">
                                                  <select className="select-filter" value={this.state.new_profile.type} onChange={this.onChangeNewProfileType}>
                                                    <option value="office">Office</option>
                                                    <option value="home">Home</option>
                                                    <option value="mobile">Mobile</option>
                                                  </select>
                                                </td>
                                                <td className="account-table-end">
                                                  <input
                                                    name="phone"
                                                    className={
                                                      this.state.new_profile.error && this.state.new_profile.mode === 'add_number'
                                                        ? 'form-control business-dark-theme-input input-error'
                                                        : 'form-control business-dark-theme-input'
                                                    }
                                                    placeholder="Country Code + Phone Number"
                                                    value={this.state.new_profile.value}
                                                    onChange={this.onChangeNewProfileItem}
                                                    onKeyDown={this.onChangeNewProfileItemKeyPress}
                                                    autoFocus
                                                  />
                                                </td>

                                                <td>
                                                  <div
                                                    id="new-confirm"
                                                    className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                                                    onClick={this.onNewProfileItemConfirm}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Save"
                                                  >
                                                    <i className="fa fa-check" aria-hidden="true" />
                                                  </div>
                                                  <div
                                                    id="new-discard"
                                                    className="btn-clear"
                                                    onClick={this.onNewProfileItemDiscard}
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Cancel"
                                                  >
                                                    <i className="fa fa-times" aria-hidden="true" />
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : null}
                                          </tbody>
                                        </table>
                                        <div>
                                          {(this.state.new_profile.error || this.state.edit_profile.error) &&
                                          (this.state.new_profile.mode === 'add_number' || this.state.edit_profile.mode === 'edit_number') ? (
                                            <span className="general-error">
                                              Please enter a valid phone number or ensure the phone number does not already exist
                                            </span>
                                          ) : null}

                                          {!this.state.new_profile.active &&
                                          !this.state.edit_profile.active &&
                                          this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                          this.state.profile.is_active ? (
                                            <div className="account-add" data-mode="add_number" onClick={this.onAddProfileItemClick}>
                                              Add{' '}
                                              <i className="fa fa-plus-circle" aria-hidden="true" data-mode="add_number" onClick={this.onAddProfileItemClick} />
                                            </div>
                                          ) : (
                                            <div className="account-add">
                                              <span className="table-add-decor" />
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.profile.role === 'Doctor' || this.state.profile.role.includes('DSO') ? (
                                      <div className="row">
                                        <div
                                          className={
                                            'account-heading practice-heading' +
                                            (this.state.edit_profile.mode === 'edit_address' ? '' : ' practice-heading__margin-bottom')
                                          }
                                        >
                                          Practice Information
                                        </div>
                                        {this.state.profile.shipping_addresses.length > 0 ? (
                                          this.state.profile.shipping_addresses.map((address, index) => {
                                            const practice_info = this.state.profile.practice_info.filter((practice) => {
                                              return practice.address_id === address.id;
                                            })[0];
                                            return (
                                              <div className="col-lg-6" key={index}>
                                                <table
                                                  className={
                                                    this.state.edit_profile.id === address.id && this.state.edit_profile.mode === 'edit_address'
                                                      ? 'table table-condensed table-industry address-table'
                                                      : 'table table-condensed table-industry address-table address-table-condensed'
                                                  }
                                                >
                                                  {this.state.edit_profile.id === address.id && this.state.edit_profile.mode === 'edit_address' ? (
                                                    <React.Fragment>
                                                      <thead className="table-inbrace-dark-industry-2">
                                                        <tr>
                                                          <th className="fourth-width-tb" />
                                                          <th className="fourth-width-tb" />
                                                          <th className="auto-width-tb" />
                                                          <th className="edit-width-tb" />
                                                        </tr>
                                                      </thead>
                                                      <tbody className="table-inbrace-dark-industry-2">
                                                        <tr className="account-start-height">
                                                          <td className="account-table-first account-label fourth-width-tb account-curve-top-left">
                                                            ERP ID/Name:
                                                          </td>
                                                          <td className="account-curve-top-right erp-input" colSpan="2">
                                                            <TextInput className="form-control" value={address.erp_id} readOnly={true} />
                                                            <TextInput className="form-control" value={address.erp_name} readOnly={true} />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Practice Name: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="address_name"
                                                              className={
                                                                this.state.edit_profile.error && !this.state.edit_profile.value
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.value}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="value"
                                                              placeholder="Enter Practice Name"
                                                              autoFocus={true}
                                                              maxLength={100}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Address: </td>
                                                          <td colSpan="2" className="country-td">
                                                            <Datalist
                                                              className={
                                                                this.state.edit_profile.error && this.state.edit_profile.country === ''
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.country}
                                                              change={this.onChangeEditProfileItem}
                                                              onKeyDown={this.onChangeEditProfileKeyPress}
                                                              item="country"
                                                              list={Object.values(COUNTRIES)}
                                                              width="100%"
                                                              placeholder="Select Country"
                                                              error={this.state.edit_profile.error && this.state.edit_profile.country === ''}
                                                              name="country"
                                                            />
                                                          </td>
                                                        </tr>
                                                        <tr>
                                                          <td className="fourth-width-tb" />
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="address_line_1"
                                                              className={
                                                                this.state.edit_profile.error && this.state.edit_profile.address_line_1 === ''
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.address_line_1}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="address_line_1"
                                                              placeholder="Address Line 1"
                                                              maxLength={250}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>

                                                        <tr>
                                                          <td className="fourth-width-tb" />
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="address_line_2"
                                                              className="form-control business-dark-theme-input"
                                                              value={this.state.edit_profile.address_line_2}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="address_line_2"
                                                              placeholder="Address Line 2"
                                                              maxLength={250}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="fourth-width-tb" />
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="city"
                                                              divClass={`account-city account-city${
                                                                this.state.edit_profile.country === UNITED_STATES ? '--us' : '--ous'
                                                              }`}
                                                              className={
                                                                this.state.edit_profile.error && this.state.edit_profile.city === ''
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.city}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="city"
                                                              placeholder="City"
                                                              maxLength={50}
                                                            />
                                                            {this.state.edit_profile.country === UNITED_STATES && (
                                                              <Datalist
                                                                name="state"
                                                                className="form-control business-dark-theme-input state-datalist"
                                                                fieldsetClass={
                                                                  this.state.edit_profile.error && this.state.edit_profile.state === ''
                                                                    ? 'account-state input-error'
                                                                    : 'account-state'
                                                                }
                                                                value={this.state.edit_profile.state}
                                                                change={this.onChangeEditProfileItem}
                                                                item="state"
                                                                list={Object.keys(US_STATES)}
                                                                width="70px"
                                                              />
                                                            )}
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        {this.state.edit_profile.country !== UNITED_STATES && (
                                                          <tr>
                                                            <td className="fourth-width-tb" />
                                                            <td colSpan="2">
                                                              <TextInput
                                                                name="state"
                                                                className={
                                                                  this.state.new_profile.error && this.state.new_profile.state === ''
                                                                    ? 'account-state form-control business-dark-theme-input input-error'
                                                                    : 'account-state form-control business-dark-theme-input'
                                                                }
                                                                value={this.state.edit_profile.state}
                                                                change={this.onChangeEditProfileItem}
                                                                item="state"
                                                                placeholder="State / Province / Region"
                                                                width="100%"
                                                                maxLength={50}
                                                              />
                                                            </td>
                                                            <td />
                                                          </tr>
                                                        )}
                                                        <tr>
                                                          <td className="fourth-width-tb" />
                                                          <td className="fourth-width-tb">
                                                            <TextInput
                                                              name="zip"
                                                              className={
                                                                this.state.edit_profile.error && this.state.edit_profile.zip === ''
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.zip}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="zip"
                                                              placeholder="Zip"
                                                              maxLength={15}
                                                            />
                                                          </td>
                                                          <td />
                                                          <td />
                                                        </tr>

                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Email: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="address_email"
                                                              className={
                                                                this.state.edit_profile.error &&
                                                                this.state.edit_profile.mode === 'edit_address' &&
                                                                this.state.edit_profile.address_email !== null &&
                                                                this.state.edit_profile.address_email !== '' &&
                                                                !isValidEmail(this.state.edit_profile.address_email)
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.address_email}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="address_email"
                                                              placeholder="Email (Optional)"
                                                              maxLength={100}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Country Code: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="country_code"
                                                              readOnly={true}
                                                              className="form-control business-dark-theme-input"
                                                              value={getPhoneCode(this.state.edit_profile.country)}
                                                              item="country_code"
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Telephone: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="address_telephone"
                                                              className={
                                                                this.state.edit_profile.error &&
                                                                this.state.edit_profile.mode === 'edit_address' &&
                                                                !isPhoneNumberValid(this.state.edit_profile.address_telephone, this.state.edit_profile.country)
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.address_telephone}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="address_telephone"
                                                              placeholder="Enter Telephone Number"
                                                              maxLength={20}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>
                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Website: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="website"
                                                              className={
                                                                this.state.edit_profile.error &&
                                                                this.state.edit_profile.mode === 'edit_address' &&
                                                                this.state.edit_profile.website !== null &&
                                                                this.state.edit_profile.website !== '' &&
                                                                !ValidationUtils.isValidURL(this.state.edit_profile.website)
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.website}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="website"
                                                              placeholder="Enter Website (Optional)"
                                                              maxLength={100}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>

                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Facebook: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="facebook_url"
                                                              className={
                                                                this.state.edit_profile.error &&
                                                                this.state.edit_profile.mode === 'edit_address' &&
                                                                this.state.edit_profile.facebook_url !== null &&
                                                                this.state.edit_profile.facebook_url !== '' &&
                                                                !isValidSocialMedia(this.state.edit_profile.facebook_url, 'facebook')
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.facebook_url}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="facebook_url"
                                                              placeholder="https://www.facebook.com/username (Optional)"
                                                              maxLength={100}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>

                                                        <tr>
                                                          <td className="account-table-first account-label fourth-width-tb">Instagram: </td>
                                                          <td colSpan="2">
                                                            <TextInput
                                                              name="instagram_url"
                                                              className={
                                                                this.state.edit_profile.error &&
                                                                this.state.edit_profile.mode === 'edit_address' &&
                                                                this.state.edit_profile.instagram_url !== null &&
                                                                this.state.edit_profile.instagram_url !== '' &&
                                                                !isValidSocialMedia(this.state.edit_profile.instagram_url, 'instagram')
                                                                  ? 'form-control business-dark-theme-input input-error'
                                                                  : 'form-control business-dark-theme-input'
                                                              }
                                                              value={this.state.edit_profile.instagram_url}
                                                              change={this.onChangeEditProfileItem}
                                                              keyDown={this.onChangeEditProfileKeyPress}
                                                              item="instagram_url"
                                                              placeholder="https://www.instagram.com/username (Optional)"
                                                              maxLength={100}
                                                            />
                                                          </td>
                                                          <td />
                                                        </tr>

                                                        <tr className="practice-checkboxes-tr">
                                                          <td colSpan="3">
                                                            <div className="practice-checkboxes-div">
                                                              <div className="practice-checkbox-div">
                                                                <span className="account-label ">Shipping Only: </span>
                                                                <input
                                                                  className="checkbox-button"
                                                                  type="checkbox"
                                                                  name="practice"
                                                                  checked={this.state.edit_profile.shipping_only}
                                                                  onChange={this.onChangeEditProfilePractice}
                                                                />
                                                              </div>

                                                              <div className="practice-checkbox-div">
                                                                <span className="account-label">Active: </span>
                                                                <input
                                                                  className="checkbox-button"
                                                                  type="checkbox"
                                                                  name="active"
                                                                  checked={this.state.edit_profile.is_active}
                                                                  onChange={this.onChangeEditProfileActive}
                                                                />
                                                              </div>
                                                              <div className="practice-checkbox-div">
                                                                <span className="account-label">Doctor Locator: </span>
                                                                <input
                                                                  className="checkbox-button"
                                                                  type="checkbox"
                                                                  name="doctor_locator"
                                                                  checked={this.state.edit_profile.is_doctor_loc}
                                                                  onChange={this.onChangeEditProfileDoctorLoc}
                                                                />
                                                              </div>
                                                            </div>
                                                          </td>
                                                          <td>
                                                            <div
                                                              id="edit-confirm"
                                                              className={
                                                                'btn-clear ' +
                                                                (this.state.in_progress || this.state.validate_address_pending ? 'btn-disabled' : '')
                                                              }
                                                              onClick={this.onEditProfileConfirm}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Save"
                                                            >
                                                              <i className="fa fa-check" aria-hidden="true" />
                                                            </div>
                                                            <div
                                                              id="edit-discard"
                                                              className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                                                              onClick={this.onEditProfileDiscard}
                                                              data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="Cancel"
                                                            >
                                                              <i className="fa fa-times" aria-hidden="true" />
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <tbody className="table-inbrace-dark-industry-2">
                                                        <tr className="pointer">
                                                          {/* Address Edit Here */}
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div className="bold-text">{address.address_name}</div>
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div className="account-checkbox-window">
                                                              <div className="account-checkbox-label">Shipping Only:</div>
                                                              <input
                                                                className="checkbox-button"
                                                                type="checkbox"
                                                                name="notification"
                                                                readOnly={true}
                                                                checked={address.shipping_only}
                                                              />
                                                            </div>
                                                          </td>
                                                          <td className="edit-width-tb" />
                                                        </tr>

                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div>
                                                              {address.address_line_1 && address.address_line_2
                                                                ? address.address_line_1 + ' ' + address.address_line_2
                                                                : address.address_line_1}
                                                              {', ' + address.city + ', ' + address.state + ' ' + address.zip + ', ' + address.country}
                                                            </div>
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div className="account-checkbox-window">
                                                              <div className="account-checkbox-label">Active:</div>
                                                              <input
                                                                className="checkbox-button"
                                                                type="checkbox"
                                                                name="notification"
                                                                readOnly={true}
                                                                checked={address.is_active}
                                                              />
                                                            </div>
                                                          </td>
                                                          <td className="edit-width-tb" />
                                                        </tr>

                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <i className="fa fa-envelope" aria-hidden="true" />
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {practice_info && practice_info.address_email ? (
                                                              <div>
                                                                <a
                                                                  className="sub-item-0 support-link"
                                                                  href={'mailto:' + practice_info.address_email + '?Subject=Hello%20Inbrace'}
                                                                  target="_top"
                                                                >
                                                                  {practice_info.address_email}
                                                                </a>
                                                              </div>
                                                            ) : (
                                                              'N/A'
                                                            )}
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div className="account-checkbox-window">
                                                              <div className="account-checkbox-label">Doctor Locator:</div>
                                                              <input
                                                                className="checkbox-button"
                                                                type="checkbox"
                                                                name="notification"
                                                                readOnly={true}
                                                                checked={address.is_doctor_loc}
                                                              />
                                                            </div>
                                                          </td>

                                                          <td className="edit-width-tb" />
                                                        </tr>

                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <i className="fa fa-phone" aria-hidden="true" />
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <div>{address.address_telephone}</div>
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td className="edit-width-tb" />
                                                        </tr>
                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <i className="fa fa-link" aria-hidden="true" />
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {address.website ? (
                                                              <div>
                                                                <a
                                                                  href={'http://' + address.website.replace('http://', '').replace('https://', '')}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                >
                                                                  {address.website}
                                                                </a>
                                                              </div>
                                                            ) : (
                                                              'N/A'
                                                            )}
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td className="edit-width-tb" />
                                                        </tr>
                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <FontAwesomeIcon icon={['fab', 'facebook']} aria-hidden="true" />
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {practice_info && practice_info.facebook_url ? (
                                                              <div>
                                                                <a
                                                                  href={'http://' + practice_info.facebook_url.replace('http://', '').replace('https://', '')}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                >
                                                                  {practice_info.facebook_url}
                                                                </a>
                                                              </div>
                                                            ) : (
                                                              'N/A'
                                                            )}
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td className="edit-width-tb" />
                                                        </tr>
                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            <FontAwesomeIcon icon={['fab', 'instagram']} aria-hidden="true" />
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {practice_info && practice_info.instagram_url ? (
                                                              <div>
                                                                <a
                                                                  href={'http://' + practice_info.instagram_url.replace('http://', '').replace('https://', '')}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                >
                                                                  {practice_info.instagram_url}
                                                                </a>
                                                              </div>
                                                            ) : (
                                                              'N/A'
                                                            )}
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td className="edit-width-tb" />
                                                        </tr>
                                                        <tr className="pointer">
                                                          <td
                                                            className="icon-width-tb"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {address.erp_id ? <BusinessCenter className="erp-icon"></BusinessCenter> : null}
                                                          </td>
                                                          <td
                                                            className="auto-width-tb"
                                                            colSpan="2"
                                                            data-id={address.id}
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          >
                                                            {address.erp_id ? (
                                                              <div>
                                                                ({address.erp_id}) {address.erp_name}
                                                              </div>
                                                            ) : null}
                                                          </td>
                                                          <td
                                                            className="fourth-width-tb account-table-dark"
                                                            data-id={address.id}
                                                            data-mode="edit_address"
                                                            onClick={
                                                              !this.state.profile.role.includes('DSO_Doctor') &&
                                                              this.hasAccountPermissionToEdit(user_roles_and_permissions) &&
                                                              this.state.profile.is_active
                                                                ? this.onEditClick
                                                                : null
                                                            }
                                                          />
                                                          <td className="edit-width-tb" />
                                                        </tr>
                                                      </tbody>
                                                    </React.Fragment>
                                                  )}
                                                </table>

                                                {this.state.edit_profile.id === address.id &&
                                                this.state.edit_profile.error &&
                                                this.state.edit_profile.mode === 'edit_address' ? (
                                                  <span className="general-error">
                                                    {this.state.address_error_msg
                                                      ? this.state.address_error_msg
                                                      : 'Please enter a valid address or ensure the address does not already exist'}
                                                  </span>
                                                ) : null}

                                                {!this.state.profile.role.includes('DSO_Doctor') ? (
                                                  !this.state.new_profile.active &&
                                                  !this.state.edit_profile.active &&
                                                  index === this.state.profile.shipping_addresses.length - 1 &&
                                                  this.state.profile.is_active ? (
                                                    <div
                                                      className={
                                                        'account-add account-add-last ' +
                                                        (!this.state.new_profile.active &&
                                                        !this.state.edit_profile.active &&
                                                        this.hasAccountPermissionToEdit(user_roles_and_permissions)
                                                          ? ''
                                                          : 'btn-hide')
                                                      }
                                                      data-mode="add_address"
                                                      onClick={this.onAddProfileItemClick}
                                                    >
                                                      Add{' '}
                                                      <i
                                                        className="fa fa-plus-circle"
                                                        aria-hidden="true"
                                                        data-mode="add_address"
                                                        onClick={this.onAddProfileItemClick}
                                                      />
                                                    </div>
                                                  ) : !this.state.new_profile.active && index === this.state.profile.shipping_addresses.length - 1 ? (
                                                    <div className="account-add account-add-last" data-mode="add_address" />
                                                  ) : null
                                                ) : null}
                                              </div>
                                            );
                                          })
                                        ) : this.state.new_profile.active && this.state.new_profile.mode === 'add_address' ? null : (
                                          <div className="col-lg-6">
                                            <table className="table table-industry address-table">
                                              <thead className="table-inbrace-dark-industry-2">
                                                <tr>
                                                  <th className="fourth-width-tb" />
                                                  <th className="fourth-width-tb" />
                                                  <th className="auto-width-tb" />
                                                  <th className="edit-width-tb" />
                                                </tr>
                                              </thead>
                                              <tbody className="table-inbrace-dark-industry-2">
                                                <tr className="force-auto-width">
                                                  <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="3">
                                                    - - - - - No Practice Information Found - - - - -
                                                  </td>
                                                  <td />
                                                </tr>
                                              </tbody>
                                            </table>

                                            <div
                                              className={
                                                'account-add account-add-last ' +
                                                (!this.state.new_profile.active &&
                                                !this.state.edit_profile.active &&
                                                this.hasAccountPermissionToEdit(user_roles_and_permissions)
                                                  ? ''
                                                  : 'btn-hide')
                                              }
                                              data-mode="add_address"
                                              onClick={this.onAddProfileItemClick}
                                            >
                                              Add{' '}
                                              <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                                data-mode="add_address"
                                                onClick={this.onAddProfileItemClick}
                                              />
                                            </div>
                                          </div>
                                        )}

                                        {this.state.new_profile.active && this.state.new_profile.mode === 'add_address' ? (
                                          <div className="col-lg-6" id="new_address">
                                            <table className="table table-condensed table-industry address-table">
                                              <thead className="table-inbrace-dark-industry-2">
                                                <tr>
                                                  <th className="fourth-width-tb" />
                                                  <th className="fourth-width-tb" />
                                                  <th className="auto-width-tb" />
                                                  <th className="edit-width-tb" />
                                                </tr>
                                              </thead>
                                              <tbody className="table-inbrace-dark-industry-2">
                                                <tr className="account-start-height">
                                                  <td className="account-table-first account-label fourth-width-tb account-curve-top-left">ERP ID/Name:</td>
                                                  <td className="account-curve-top-right erp-input" colSpan="2">
                                                    <TextInput className="form-control" readOnly={true} />
                                                    <TextInput className="form-control" readOnly={true} />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Practice Name: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="address_name"
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.value === ''
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.value}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="value"
                                                      placeholder="Enter Practice Name"
                                                      autoFocus={true}
                                                      maxLength={100}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Address: </td>
                                                  <td colSpan="2" className="country-td">
                                                    <Datalist
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.country === ''
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.country}
                                                      change={this.onChangeNewProfileItem}
                                                      onKeyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="country"
                                                      list={Object.values(COUNTRIES)}
                                                      width="100%"
                                                      placeholder="Select Country"
                                                      error={this.state.new_profile.error && this.state.new_profile.country === ''}
                                                      name="country"
                                                    />
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td className="fourth-width-tb" />
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="address_line_1"
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.address_line_1 === ''
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.address_line_1}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="address_line_1"
                                                      placeholder="Address Line 1"
                                                      maxLength={250}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="fourth-width-tb" />
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="address_line_2"
                                                      className="form-control business-dark-theme-input"
                                                      value={this.state.new_profile.address_line_2}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="address_line_2"
                                                      placeholder="Address Line 2"
                                                      maxLength={250}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="fourth-width-tb" />
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="city"
                                                      divClass={`account-city account-city${
                                                        this.state.new_profile.country === UNITED_STATES ? '--us' : '--ous'
                                                      }`}
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.city === ''
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.city}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="city"
                                                      placeholder="City"
                                                      maxLength={50}
                                                      width={this.state.new_profile.country === UNITED_STATES ? 'calc(100% - 75px)' : '100%'}
                                                    />

                                                    {this.state.new_profile.country === UNITED_STATES && (
                                                      <Datalist
                                                        name="state"
                                                        className="form-control business-dark-theme-input state-datalist"
                                                        fieldsetClass={
                                                          this.state.new_profile.error && this.state.new_profile.state === ''
                                                            ? 'account-state input-error'
                                                            : 'account-state'
                                                        }
                                                        value={this.state.new_profile.state}
                                                        change={this.onChangeNewProfileItem}
                                                        item="state"
                                                        list={Object.keys(US_STATES)}
                                                        width="70px"
                                                      />
                                                    )}
                                                  </td>
                                                  <td />
                                                </tr>

                                                {this.state.new_profile.country !== UNITED_STATES && (
                                                  <tr>
                                                    <td className="fourth-width-tb" />
                                                    <td colSpan="2">
                                                      <TextInput
                                                        name="state"
                                                        className={
                                                          this.state.new_profile.error && this.state.new_profile.state === ''
                                                            ? 'account-state form-control business-dark-theme-input input-error'
                                                            : 'account-state form-control business-dark-theme-input'
                                                        }
                                                        value={this.state.new_profile.state}
                                                        change={this.onChangeNewProfileItem}
                                                        keyDown={this.onChangeNewProfileItemKeyPress}
                                                        item="state"
                                                        placeholder="State / Province / Region"
                                                        width="100%"
                                                        maxLength={50}
                                                      />
                                                    </td>
                                                    <td />
                                                  </tr>
                                                )}

                                                <tr>
                                                  <td className="fourth-width-tb" />
                                                  <td className="fourth-width-tb">
                                                    <TextInput
                                                      name="zip"
                                                      className={
                                                        this.state.new_profile.error && this.state.new_profile.zip === ''
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.zip}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="zip"
                                                      placeholder="Zip"
                                                      maxLength={15}
                                                    />
                                                  </td>
                                                  <td />
                                                  <td />
                                                </tr>

                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Email: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="address_email"
                                                      className={
                                                        this.state.new_profile.error &&
                                                        this.state.new_profile.mode === 'add_address' &&
                                                        this.state.new_profile.address_email !== null &&
                                                        this.state.new_profile.address_email !== '' &&
                                                        !isValidEmail(this.state.new_profile.address_email)
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.address_email}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="address_email"
                                                      placeholder="Enter Email (Optional)"
                                                      maxLength={100}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Country Code: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="country_code"
                                                      readOnly={true}
                                                      className="form-control business-dark-theme-input"
                                                      value={getPhoneCode(this.state.new_profile.country)}
                                                      item="country_code"
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Telephone: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="address_telephone"
                                                      className={
                                                        this.state.new_profile.error &&
                                                        this.state.new_profile.mode === 'add_address' &&
                                                        !isPhoneNumberValid(this.state.new_profile.address_telephone, this.state.new_profile.country)
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.address_telephone}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="address_telephone"
                                                      placeholder="Enter Telephone Number"
                                                      maxLength={20}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>

                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Website: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="website"
                                                      className={
                                                        this.state.new_profile.error &&
                                                        this.state.new_profile.mode === 'add_address' &&
                                                        this.state.new_profile.website !== null &&
                                                        this.state.new_profile.website !== '' &&
                                                        !ValidationUtils.isValidURL(this.state.new_profile.website)
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.website}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="website"
                                                      placeholder="Enter Website (Optional)"
                                                      maxLength={100}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>
                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Facebook: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="facebook_url"
                                                      className={
                                                        this.state.new_profile.error &&
                                                        this.state.new_profile.mode === 'edit_address' &&
                                                        this.state.new_profile.facebook_url !== null &&
                                                        this.state.new_profile.facebook_url !== '' &&
                                                        !isValidSocialMedia(this.state.new_profile.facebook_url, 'facebook')
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.facebook_url}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="facebook_url"
                                                      placeholder="https://www.facebook.com/username (Optional)"
                                                      maxLength={100}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>

                                                <tr>
                                                  <td className="account-table-first account-label fourth-width-tb">Instagram: </td>
                                                  <td colSpan="2">
                                                    <TextInput
                                                      name="instagram_url"
                                                      className={
                                                        this.state.new_profile.error &&
                                                        this.state.new_profile.mode === 'edit_address' &&
                                                        this.state.new_profile.instagram_url !== null &&
                                                        this.state.new_profile.instagram_url !== '' &&
                                                        !isValidSocialMedia(this.state.new_profile.instagram_url, 'instagram')
                                                          ? 'form-control business-dark-theme-input input-error'
                                                          : 'form-control business-dark-theme-input'
                                                      }
                                                      value={this.state.new_profile.instagram_url}
                                                      change={this.onChangeNewProfileItem}
                                                      keyDown={this.onChangeNewProfileItemKeyPress}
                                                      item="instagram_url"
                                                      placeholder="https://www.instagram.com/username (Optional)"
                                                      maxLength={100}
                                                    />
                                                  </td>
                                                  <td />
                                                </tr>

                                                <tr className="practice-checkboxes-tr">
                                                  <td colSpan="3">
                                                    <div className="practice-checkboxes-div">
                                                      <div className="practice-checkbox-div">
                                                        <span className="account-label">Shipping Only: </span>
                                                        <input
                                                          className="checkbox-button"
                                                          type="checkbox"
                                                          name="practice"
                                                          checked={this.state.new_profile.shipping_only}
                                                          onChange={this.onChangeNewProfilePractice}
                                                        />
                                                      </div>
                                                      <div className="practice-checkbox-div">
                                                        <span className="account-label">Active: </span>
                                                        <input
                                                          className="checkbox-button"
                                                          type="checkbox"
                                                          name="active"
                                                          checked={this.state.new_profile.is_active}
                                                          readOnly={true}
                                                        />
                                                      </div>
                                                      <div className="practice-checkbox-div">
                                                        <span className="account-label">Doctor Locator: </span>
                                                        <input
                                                          className="checkbox-button"
                                                          type="checkbox"
                                                          name="doctor_locator"
                                                          checked={this.state.new_profile.is_doctor_loc}
                                                          onChange={this.onChangeNewProfileDoctorLoc}
                                                        />
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div
                                                      id="new-confirm"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Save"
                                                      className={
                                                        'btn-clear ' + (this.state.in_progress || this.state.validate_address_pending ? 'btn-disabled' : '')
                                                      }
                                                      onClick={this.onNewProfileItemConfirm}
                                                    >
                                                      <i className="fa fa-check" aria-hidden="true" />
                                                    </div>
                                                    <div
                                                      id="new-discard"
                                                      data-toggle="tooltip"
                                                      data-placement="top"
                                                      title="Cancel"
                                                      className={'btn-clear' + (this.state.validate_address_pending ? ' btn-disabled' : '')}
                                                      onClick={this.onNewProfileItemDiscard}
                                                    >
                                                      <i className="fa fa-times" aria-hidden="true" />
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                            <div>
                                              {this.state.new_profile.active &&
                                              this.state.new_profile.error &&
                                              this.state.new_profile.mode === 'add_address' ? (
                                                <span className="general-error">
                                                  {this.state.address_error_msg
                                                    ? this.state.address_error_msg
                                                    : 'Please enter a valid address or ensure the address does not already exist'}
                                                </span>
                                              ) : null}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : this.state.selected_tab === 'Cases' ? (
                              <div className="">
                                <div className="dark-options">
                                  <div className="dark-search">
                                    <input
                                      type="text"
                                      className="form-control search-bar-dark font-awesome"
                                      placeholder="Search"
                                      aria-describedby="basic-addon1"
                                      onChange={this.onChangeSearchPatient}
                                      onKeyPress={this.keyPress}
                                      value={this.state.search}
                                    />
                                  </div>
                                  <div className="dark-filter">
                                    <div className="select-container">
                                      <select className="select-status" value={this.state.status_filter} onChange={this.onStatusFilterSelection}>
                                        <option value="all">All Status</option>
                                        <option value="segmentation_process">Segmentation Process</option>
                                        <option value="checking">{translateStatus('INBRACE Checking Files')}</option>
                                        <option value="upload">{translateStatus('Doctor Upload New Files')}</option>
                                        <option value="prepare">{translateStatus('INBRACE Preparing Setup')}</option>
                                        <option value="setup_ready_for_release">Smile Design Ready for Release</option>
                                        <option value="approve">{translateStatus('Doctor Approve or Revise Setup')}</option>
                                        <option value="cad_process">Appliance Design Process</option>
                                        <option value="qc_approval">Quality Control Review</option>
                                        <option value="manufacture">{translateStatus('INBRACE Manufacturing Case')}</option>
                                        <option value="shipped">{translateStatus('Case Shipped')}</option>
                                        <option value="hold">{translateStatus('Hold')}</option>
                                        <option value="cancel">{translateStatus('Cancelled')}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="dark-filter">
                                    <div className="select-container-date">
                                      <div className="text-center light-gray-text">Submission Date Between</div>
                                      <div>
                                        <input
                                          className="select-date-l"
                                          type="date"
                                          name="start"
                                          value={this.state.start_date_filter}
                                          onChange={this.onStartDateSelection}
                                        />
                                        <input
                                          className="select-date-l"
                                          type="date"
                                          name="end"
                                          value={this.state.end_date_filter}
                                          onChange={this.onEndDateSelection}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                                    <i className="fa fa-search" aria-hidden="true" />
                                  </button>
                                </div>
                                {this.state.cases_view.length > 0 && userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                  <div className="table-case-view">
                                    <table className="table table-condensed table-striped table-industry">
                                      <thead className="table-inbrace-dark-industry">
                                        <tr>
                                          <th className="caseid-width" data-method="case_id" onClick={this.onSortClick}>
                                            <SortHeader
                                              title="Case ID"
                                              category="case_id"
                                              sort_method={this.state.cases_sort_method}
                                              ascending={this.state.cases_sort_order_ascending['case_id']}
                                            />
                                          </th>
                                          <th className="submission-width" data-method="submission_date" onClick={this.onSortClick}>
                                            <SortHeader
                                              title="Submission"
                                              category="submission_date"
                                              sort_method={this.state.cases_sort_method}
                                              ascending={this.state.cases_sort_order_ascending['submission_date']}
                                            />
                                          </th>
                                          <th className="name-width" data-method="patient_name" onClick={this.onSortClick}>
                                            <SortHeader
                                              title="Patient"
                                              category="patient_name"
                                              sort_method={this.state.cases_sort_method}
                                              ascending={this.state.cases_sort_order_ascending['patient_name']}
                                            />
                                          </th>
                                          <th className="status-width" data-method="status" onClick={this.onSortClick}>
                                            <SortHeader
                                              title="Status"
                                              category="status"
                                              sort_method={this.state.cases_sort_method}
                                              ascending={this.state.cases_sort_order_ascending['status']}
                                            />
                                          </th>
                                          {this.state.profile.role === 'DSO' ? (
                                            <th className="status-width" data-method="assigned_to" onClick={this.onSortClick}>
                                              <SortHeader
                                                title="Assigned To"
                                                category="assigned_to"
                                                sort_method={this.state.cases_sort_method}
                                                ascending={this.state.cases_sort_order_ascending['assigned_to']}
                                              />
                                            </th>
                                          ) : null}
                                        </tr>
                                      </thead>
                                      <tbody className="table-inbrace-dark-industry">
                                        {this.state.cases_view.map((cases, index) => {
                                          if (cases.status.indexOf('Draft Digital Enhancement') < 0) {
                                            return (
                                              <tr className="pointer" key={index} onMouseUp={this.onPatientClick} data-caseid={cases.case_id}>
                                                <td className="td-industry-offset caseid-width">{removeCaseIdInitialNumber(cases.case_id)}</td>
                                                <td>{convertDate(cases.submission_date)}</td>
                                                <td>{`${cases.first_name} ${cases.last_name[0]}.`}</td>
                                                <td>
                                                  {cases.status === 'Hold' ? (
                                                    <span className="hold-length-2">
                                                      {cases.status} {'-'} {cases.hold_description}
                                                    </span>
                                                  ) : cases.status === 'Case Shipped' ? (
                                                    <span>Case Shipped on {convertDate(cases.shipped_date)}</span>
                                                  ) : (
                                                    new_branding_words(cases.status)
                                                  )}
                                                </td>
                                                {this.state.profile.role === 'DSO' ? <td>{cases.dso_doctor_name}</td> : null}
                                              </tr>
                                            );
                                          }

                                          return null;
                                        })}
                                      </tbody>
                                    </table>
                                    <PaginationContainer
                                      theme="bpp"
                                      isBottom={this.state.is_bottom}
                                      activePage={this.state.cases_active_page}
                                      itemsCountPerPage={this.state.cases_row_size}
                                      totalItemsCount={this.state.cases_length}
                                      pageRangeDisplayed={this.state.cases_page_range}
                                      onChange={this.onPageChange}
                                      startRange={this.getStartRange()}
                                      endRange={this.getEndRange()}
                                      type="Cases"
                                    />
                                  </div>
                                ) : this.state.cases_raw.length === 0 || this.noCasesInProgress() ? (
                                  <div className="result-none center-text">
                                    {this.state.profile.role.includes('DSO') ? (
                                      <div>There are no cases for this DSO account.</div>
                                    ) : (
                                      <div>There are no cases for this doctor.</div>
                                    )}
                                  </div>
                                ) : (
                                  searchErrorMessage(getBusinessSearchValueFromRoute(), 'accounts')
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </UserPermissionsContext.Consumer>
          )}
          {this.state.passwordSentModal ? (
            <Modal
              header_text="Send Password Reset"
              message_text="A new password reset email has been sent"
              onCloseButtonClick={this.onSendNewPasswordDismiss}
              theme="bpp"
            />
          ) : null}
          {this.state.creditHoldModal ? (
            <Modal
              preset="decision-credit-hold"
              header_text={this.state.profile.credit_hold ? 'Remove Credit Hold' : 'Credit Hold'}
              confirm_btn_text={this.state.profile.credit_hold ? 'Remove' : 'Hold'}
              close_btn_text="Cancel"
              cases={this.state.profile.credit_hold ? this.state.initial_on_hold_cases : this.state.initial_manufacturing_cases}
              dso_account={this.state.profile.role === 'DSO'}
              onConfirmButtonClick={this.onCreditHoldButtonClick}
              message_text={this.state.message_text}
              onCloseButtonClick={this.onCreditHoldDismiss}
              is_credithold_on={this.state.profile.credit_hold}
              theme="bpp"
              in_progress={this.state.in_progress}
              in_progress_text="Sending data to ERP..."
            />
          ) : null}
          {this.state.refresh ? (
            <Modal
              preset="action"
              x_btn={false}
              header_text="Session Expired"
              message_text="Sorry, your session has expired. Please refresh."
              confirm_btn_text="Refresh"
              onConfirmButtonClick={onReloadPage}
              theme="bpp"
            />
          ) : null}
          {this.props.invalid_address_modal ? (
            <Modal
              preset="invalid-address"
              header_text="Invalid Address"
              confirm_btn_text={this.props.address_suggestions.length > 0 ? 'Save' : 'OK'}
              close_btn_text="Cancel"
              close_btn={this.props.address_suggestions.length > 0}
              onCloseButtonClick={this.props.closeInvalidAddressModal}
              onConfirmButtonClick={
                this.props.original_address_selected && !this.props.edit_address
                  ? this.saveOriginalAddress
                  : this.props.original_address_selected && this.props.edit_address
                  ? this.saveOriginalAddressAfterEditing
                  : this.props.address_suggestions.length > 0 && !this.props.edit_address
                  ? this.saveAddressSuggestion
                  : this.props.address_suggestions.length > 0 && this.props.edit_address
                  ? this.saveAddressSuggestionAfterEditing
                  : this.props.closeInvalidAddressModal
              }
              confirmButtonDisabled={this.props.address_suggestions.length > 0 && !this.props.original_address_selected && !this.props.selected_address_id}
              theme="bpp"
            />
          ) : null}
          {this.props.practice_reactivation_modal ? (
            <Modal
              preset="decision"
              header_text="Reactivate Practice"
              message_text="Are you sure you want to reactivate the practice?"
              confirm_btn_text="Reactivate Practice"
              close_btn_text="Cancel"
              onCloseButtonClick={this.props.closePracticeActivationModal}
              onConfirmButtonClick={this.onReactivateOrDeactivatePractice}
              theme="bpp"
            />
          ) : null}
          {this.props.practice_deactivation_modal ? (
            <Modal
              preset="decision"
              header_text="Deactivate Practice"
              message_text="Are you sure you want to deactivate the practice?"
              confirm_btn_text="Deactivate Practice"
              close_btn_text="Cancel"
              onCloseButtonClick={this.props.closePracticeActivationModal}
              onConfirmButtonClick={this.onReactivateOrDeactivatePractice}
              theme="bpp"
            />
          ) : null}
          {this.state.modals.updateNameModal ? (
            <Modal
              preset="erp"
              header_text={`Update Doctor ${this.state.edit_first_name ? 'First' : 'Last'} Name`}
              message_text={this.getUpdateNameModalMessage()}
              confirm_btn={!this.state.update_name_error}
              confirm_btn_text="Update"
              close_btn_text={this.state.update_name_error ? 'OK' : 'Cancel'}
              onCloseButtonClick={this.closeUpdateNameModal}
              onConfirmButtonClick={this.onEditNameConfirm}
              in_progress={this.state.update_name_in_progress}
              in_progress_text="Sending data to ERP..."
              theme="bpp"
            />
          ) : null}
          {this.state.modals.accountActivationModal ? (
            <Modal
              preset="account-activation"
              header_text={this.getAccountActivationModalHeaderText()}
              message_text={this.getAccountActivationModalMessage()}
              confirm_btn={!this.state.profile.is_active && !this.state.account_activation_error}
              confirm_btn_text="Enable Account"
              close_btn_text={this.state.account_activation_error ? 'OK' : 'Cancel'}
              onCloseButtonClick={this.closeAccountActivationModal}
              onConfirmButtonClick={this.onEnableAccountConfirm}
              in_progress={this.state.in_progress}
              in_progress_text="Sending data to ERP..."
              theme="bpp"
              shipping_addresses={this.state.profile.shipping_addresses}
              selected_address_ids={this.state.account_activation_selected_address_ids}
              onAccountActivationAddressChange={this.onAccountActivationAddressChange}
              account_activation_error={this.state.account_activation_error}
              confirm_btn_disabled={this.state.account_activation_selected_address_ids.length === 0}
            />
          ) : null}
          {this.state.modals.activeCasesModal ? (
            <Modal
              preset="active-cases"
              header_text={`Unable to Deactivate ${this.state.edit_profile.mode === 'edit_address' ? 'Practice' : 'Account'}`}
              confirm_btn={false}
              close_btn_text="OK"
              onCloseButtonClick={() => {
                this.setState({ modals: { ...this.state.modals, activeCasesModal: false } });
              }}
              theme="bpp"
              active_cases={this.state.active_cases}
              is_edit_address={this.state.edit_profile.mode === 'edit_address'}
              is_dso={this.state.profile.role === 'DSO'}
            />
          ) : null}
          {this.state.modals.updateAddressInProgressModal ? (
            <Modal
              preset="decision"
              theme="bpp"
              in_progress={true}
              in_progress_text="Updating practice..."
              header_text="Update Practice"
              close_btn={false}
              confirm_btn={false}
            />
          ) : null}
          {this.state.modals.addAddressInProgressModal && (
            <Modal
              preset="decision"
              theme="bpp"
              in_progress={true}
              in_progress_text="Creating practice..."
              header_text="Create Practice"
              close_btn={false}
              confirm_btn={false}
            />
          )}
          {this.state.showGen2ConfirmationModal ? (
            <Modal
              theme="bpp"
              preset="decision"
              header_text={`Update Gen 2.0 Feature`}
              message_text="This change will also apply to the associated DSO and DSO override accounts. <br/>
              Are you sure you want to update the Gen 2.0 Feature for this DSO account?"
              confirm_btn_text="Update"
              onConfirmButtonClick={this.onGen2Update}
              close_btn_text="Cancel"
              onCloseButtonClick={this.onGenFlagCancel}
            />
          ) : null}
        </div>
      );
    }
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';

    let cases_sort_order_ascending = this.state.cases_sort_order_ascending;

    if (sort_method === this.state.cases_sort_method) {
      //Flip order
      cases_sort_order_ascending[sort_method] = !cases_sort_order_ascending[sort_method];
    }

    let cases_view = filter
      ? getCasesFromFilter(this.state.cases_raw, filter, this.state.user_id, this.state.assigned_cases)
      : getCasesFromSearch(
          getCasesFromEndDate(getCasesFromStartDate(getCasesFromStatus(this.state.cases_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    cases_view = getCasesFromRow(
      this.setOrderByMethod(cases_view, sort_method, cases_sort_order_ascending[sort_method]),
      this.state.cases_active_page,
      this.state.cases_row_size
    );

    this.setState({
      cases_view: cases_view,
      cases_sort_method: sort_method,
      cases_sort_order_ascending: cases_sort_order_ascending,
    });
  };

  setOrderByMethod(cases_view, sort_method, sort) {
    const sort_value = getSortValue(sort);

    switch (sort_method) {
      case 'case_id':
        cases_view = this.orderCasesByCaseId(cases_view, sort_value);
        break;

      case 'patient_name':
        cases_view = this.orderCasesByName(cases_view, sort_value);
        break;

      case 'status':
        cases_view = this.orderCasesByStatus(cases_view, sort_value);
        break;

      case 'submission_date':
        cases_view = this.orderCasesBySubmissionDate(cases_view, sort_value);
        break;

      case 'assigned_to':
        cases_view = this.orderCasesByAssignedTo(cases_view, sort_value);
        break;

      default:
    }

    return cases_view;
  }
}

const mapStateToProps = (state) => {
  return {
    invalid_address_modal: getInvalidAddressModal(state),
    address_suggestions: getAddressSuggestions(state),
    selected_address_id: getSelectedAddressId(state),
    original_address_selected: getOriginalAddressSelected(state),
    edit_address: getEditAddress(state),
    practice_reactivation_modal: getReactivationModal(state),
    practice_deactivation_modal: getDeactivationModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openInvalidAddressModal: openInvalidAddressModal,
      closeInvalidAddressModal: closeInvalidAddressModal,
      openSmartyStreetsErrorModal: openSmartyStreetsErrorModal,
      openPracticeActivationModal: openPracticeActivationModal,
      closePracticeActivationModal: closePracticeActivationModal,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAccount));
