/**
 * File:pr_submission.js - Component to submit progress record/clinical questionaire  on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import cloneDeep from 'lodash/cloneDeep';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  fetchIteroScans,
  fetchIteroPhotos,
  getIteroPhotoRecordsForProgressRecord,
  deleteIteroPhotosFromProgressRecord,
} from '../../redux/actions/ipp/itero/itero_files';
import { fetchLicenseNumbers } from '../../redux/actions/ipp/itero/itero_license_numbers';
import {
  getFetchIteroPhotosPending,
  getGetIteroPhotoRecordsForProgressRecordPending,
  getDeleteIteroPhotosFromProgressRecordPending,
} from '../../redux/reducers/ipp/itero/itero_files';
import { getLicenseNumbers } from '../../redux/reducers/ipp/itero/itero_license_numbers';
import { fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';

// Internal Components
import TreatmentData from './treatment_details.json';
import { TreatmentDataGen2 } from './treatment_details_gen2';
import CsrData from './csr.json';
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import Upload from './pr_upload';
import TreatmentDetails from './treatment_details';

import CsrReview from './csr_review';
import SubmissionConfirmation from '../case_submission/submission_confirmation';
import SubmitterLoader from '../case_submission/submitting_loader';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader, convertSex, removeCaseIdInitialNumber, textFieldLimited, removeEmoji, getRootPath } from '../../common/functions';
import { getDoctorAssignedNameFromCaseDetails } from '../../common/dso';
import CircleLoader from '../../components/loader/circle_loader';
import CardContainer from '../components/container/card_container';
import Button from '../components/buttons/button';
import Stepper from '../components/stepper';
import ErrorMessage from '../components/container/error_message';
import LoadingButton from '../components/buttons/loading_button';
import { scrollToTop } from '../../common/scroll';
import { isValidDate, isValidScheduledDate } from '../../common/helpers';
import { getTempLink } from '../../common/dropbox';
import EmptyFileErrorModal from '../../components/modal/empty_file';
import { getIteroInitialState, WithIteroSelection, getMappedLicenseNumbersHelper } from '../../common/itero_utils';
import { ApiServiceInstance } from '../../common/api_service';
import { getDoctorsIdFromRoute } from '../../common/route';
import { UserPermissionsContext } from '../../context/user_permission';

class CsrSubmission extends WithIteroSelection {
  static contextType = UserPermissionsContext;

  constructor(props) {
    super(props);
    const treatment_data = cloneDeep(TreatmentData);
    const csr_data = cloneDeep(CsrData);
    this.state = {
      case_id: '',
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      patient_first_name: '',
      patient_last_name: '',
      title: 'Progress Records',
      step: 'pr_upload',
      mode: 'pr_submit',
      total_steps: '3',
      current_step: '1',
      buttons: (
        <Button data-position="next" onClick={this.onButtonClick}>
          Next
        </Button>
      ),
      warning: false,
      error: false,
      error_type: '',
      treatment_data: treatment_data,
      csr_data: csr_data,
      //Uploads
      fileUpload: [],
      //Upload in progress
      files_uploading: [],
      file_location: '',
      upload_date: '',
      file_removing: false,
      csr_requested: false,
      case_precheck: false,
      progress_id: '',
      draft_save_inprogress: false,
      submit_inprogress: false,
      showSaveModal: false,
      gen_2: false,
      upload_Component: '',
      showEmptyFileModal: false,

      // iTero integration - initial state
      ...getIteroInitialState({
        shouldFetchIteroPhotos: false,
        removePhotosWhenUploadMethodIsManual: false,
        shouldAllowToRemoveAllIteroPhotos: true,
        shouldRequiredManualPhotoUpload: false,
      }),
    };

    this.goBack = this.goBack.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
  }

  /**
   * iTero integration
   *
   * function to check if the itero photos are loading
   * @function
   * @return {Boolean} Returns true if itero photos are loading
   */
  isLoadingIteroPhotos = () => {
    if (!this.state.shouldFetchIteroPhotos) {
      return false;
    }
    return (
      this.props.isFetchIteroPhotosPending ||
      this.props.isGetIteroPhotoRecordsForProgressRecordPending ||
      this.props.isDeleteIteroPhotosFromProgressRecordPending ||
      this.state.isLoadingUploadedFiles
    );
  };

  /**
   * iTero integration
   *
   * function to check if itero photos was loaded
   * @function
   * @return {Boolean} Returns true if itero photos was loaded
   */
  hasIteroPhotos = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    return this.state[photoUploadStateKey].some((file) => file.is_itero_photo);
  };

  /**
   * iTero integration
   *
   * function to check if user is allowed to remove all itero photos
   * @function
   * @return {Boolean} Returns true if user is allowed to remove all itero photos
   */
  shouldShowRemoveIteroPhotos = () => {
    return this.state.isEnrolledToiTeroPhotosIntegration && this.state.shouldAllowToRemoveAllIteroPhotos && this.hasIteroPhotos();
  };

  /**
   * iTero integration
   *
   * Gets the current uploaded photos iteroid
   * @function
   * @return {String} Returns the current uploaded photos iteroid
   * @return {null} Returns null if there are no itero photos
   */
  getCurrentUploadedPhotosIteroId = () => {
    if (this.hasIteroPhotos()) {
      const firstUploadedIteroPhoto = this.state[this.getIteroPhotosStateKey()].filter((file) => file.is_itero_photo)[0];
      return firstUploadedIteroPhoto.itero_id;
    }
    return null;
  };

  /**
   * iTero integration
   *
   * function to check if the itero photos are being deleted
   * @function
   * @return {Boolean} Returns true if itero photos are being deleted
   */
  isDeletingIteroPhotos = (ctx) => {
    return this.props.isDeleteIteroPhotosFromProgressRecordPending;
  };

  componentDidMount() {
    const isEnrolledToiTeroIntegration = this.context.doctor_program_enrollment.includes('itero_integration');
    const isEnrolledToiTeroPhotosIntegration = this.context.doctor_program_enrollment.includes('itero_photos_integration');
    this.setState({
      isEnrolledToiTeroIntegration,
      isEnrolledToiTeroPhotosIntegration,
      uploadMethod: isEnrolledToiTeroIntegration ? 'itero' : this.state.uploadMethod,
    });
    this.withIteroSelectionComponentDidMount();

    if (!this.props.fetchIteroPhotos) {
      throw new Error('fetchIteroPhotos redux action method is not in props');
    }
    if (!this.props.getIteroPhotoRecordsForProgressRecord) {
      throw new Error('getIteroPhotoRecordsForProgressRecord redux action method is not in props');
    }
    if (!this.props.deleteIteroPhotosFromProgressRecord) {
      throw new Error('deleteIteroPhotosFromProgressRecord redux action method is not in props');
    }

    setTokenHeader();
    let csr_data = cloneDeep(this.state.csr_data);
    let treatment_data = cloneDeep(this.state.treatment_data);
    const that = this;
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const case_id = params.get('caseid');
    const progress_id = params.get('progress_id');
    const mode = this.state.mode;
    const title = this.state.title;
    let step = this.state.step;
    let total_steps = this.state.total_steps;

    const route = getRootPath(that.props.history.location.pathname);
    const doctor_id_by_route = route.match(/portal\/(\w+)/);
    const doctorId = doctor_id_by_route ? doctor_id_by_route[1] : null;

    // iTero integration - fetch license numbers
    if (progress_id) {
      this.setState({
        isLoadingUploadedFiles: true,
      });
      Axios.post(`/apiV2/praction/${progress_id}/copy_files_draft`)
        .then(function (res) {
          that.setState({
            fileUpload: res.data.new_files,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        })
        .finally(() => {
          this.setState({
            isLoadingUploadedFiles: false,
          });
        });
    }

    let licenseNumbers = [];
    let fetchedIteroScans = [];
    let fetchedIteroScansNextUrlCursor = '';
    that.props.fetchLicenseNumbers(doctorId, {
      onSuccess: (_licenseNumbers) => {
        licenseNumbers = _licenseNumbers;
        that.props
          .fetchIteroScans({ licenseNumbers: getMappedLicenseNumbersHelper(licenseNumbers) })
          .then((data) => {
            if (data.next) {
              const fetchedIteroScansNextUrl = new URL(data.next);
              fetchedIteroScansNextUrlCursor = fetchedIteroScansNextUrl.searchParams.get('cursor');
            }
            fetchedIteroScans = data.results;
          })
          .finally(() => {
            ApiServiceInstance.fetchCaseDetails(case_id)
              .then(async (res) => {
                const loadedState = {
                  fetchedIteroScans,
                  fetchedIteroScansNextUrlCursor,
                };
                const selected_case = res.data.cases.filter(function (c) {
                  return c.case_id === case_id;
                })[0];

                if (progress_id && res.data.progress_record.length) {
                  const progress_record = res.data.progress_record.filter(function (w) {
                    return w.progress_id === parseInt(progress_id);
                  })[0];
                  treatment_data = JSON.parse(cloneDeep(progress_record.csq_data), (k, v) => (v === 'true' ? true : v === 'false' ? false : v));

                  // iTero integration - load iTero data
                  if (isEnrolledToiTeroIntegration) {
                    // iTero integration - load previously selected iteroFileIds
                    if (progress_record.iteroScanIds && progress_record.iteroScanIds.length > 0) {
                      const iteroScansLoadedState = await that.getIteroScansLoadedState({
                        selectedIteroScanIds: progress_record.iteroScanIds,
                        fetchedIteroScans,
                        fetchedIteroScansNextUrlCursor,
                        licenseNumbers,
                      });
                      Object.assign(loadedState, iteroScansLoadedState);
                    }
                    const file_upload_settings = JSON.parse(progress_record.file_upload_settings);
                    // iTero integration - load uploadMethod, showAlreadyUploadedScans, shouldFetchIteroPhotos
                    for (let setting in file_upload_settings) {
                      if (file_upload_settings[setting] === 'false') {
                        loadedState[setting] = false;
                      } else if (file_upload_settings[setting] === 'true') {
                        loadedState[setting] = true;
                      } else {
                        loadedState[setting] = file_upload_settings[setting];
                      }
                    }
                  }
                } else if (selected_case.gen_2) {
                  treatment_data = cloneDeep(TreatmentDataGen2);
                }
                csr_data.case.case_id = res.data.base_id;
                treatment_data.case.case_id = res.data.base_id;
                csr_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
                csr_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
                csr_data.case.sex = convertSex(res.data.patient.sex);
                csr_data.case.dob = res.data.patient.dob;
                treatment_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
                treatment_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
                treatment_data.case.sex = convertSex(res.data.patient.sex);
                treatment_data.case.dob = res.data.patient.dob;
                if (res.data.progress_record.length > 0 && !progress_id) {
                  treatment_data.questions.filter(function (csq) {
                    return csq.friendly_name === 'submission_type';
                  })[0].answer_text = 'Progress Records';

                  treatment_data.questions
                    .filter(function (csq) {
                      return csq.friendly_name === 'submission_type';
                    })[0]
                    .radio_button.filter(function (rb) {
                      return rb.radio_label === 'Initial Records';
                    })[0].radio_checked = false;
                  treatment_data.questions
                    .filter(function (csq) {
                      return csq.friendly_name === 'submission_type';
                    })[0]
                    .radio_button.filter(function (rb) {
                      return rb.radio_label === 'Progress Records';
                    })[0].radio_checked = true;
                }

                let case_precheck = that.state.case_precheck;
                case_precheck = selected_case.initial_precheck;

                this.setState({
                  ...loadedState,
                  case_id: case_id,
                  mode: mode,
                  title: title,
                  step: step,
                  total_steps: total_steps,
                  loading: false,
                  patient_first_name: res.data.patient.first_name,
                  patient_last_name: res.data.patient.last_name,
                  address: res.data.cases[0].shipping_address,
                  csr_data: csr_data,
                  case_precheck: case_precheck,
                  progress_id: progress_id ? progress_id : '',
                  gen_2: selected_case.gen_2,
                  treatment_data: treatment_data,
                });
              })
              .catch(function (err) {
                that.setState({
                  loading: false,
                  error: true,
                });
                handleHttpRequestError(err, that);
              });
          });
      },
    });
  }

  /**
   * iTero integration
   *
   * On iTero files change
   */
  onSelectedIteroScanIdsChange = () => {
    this.removeWarning();
  };

  /**
   * iTero integration
   *
   * On should fetch itero photos change
   */
  onShouldFetchIteroPhotosChange = () => {
    if (this.state.shouldFetchIteroPhotos && this.state.selectedIteroId) {
      if (this.hasIteroPhotos()) {
        const oldIteroId = this.getCurrentUploadedPhotosIteroId();
        if (oldIteroId !== this.state.selectedIteroId) {
          this.updateIteroPhotos(oldIteroId, this.state.selectedIteroId);
        }
      } else {
        this.fetchIteroPhotos(this.state.selectedIteroId);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    super.withIteroSelectionComponentDidUpdate(prevProps, prevState);
  }

  /**
   * iTero integration
   *
   * Get case id for iTero scans
   */
  getCaseIdForIteroScans = () => {
    return this.state.case_id;
  };

  redirectUserToCaseDetail() {
    const case_id = this.state.case_id;
    let path = this.props.history.location.pathname;
    let new_path = path.replace(/csqsubmit/, `progress/${case_id}`);
    new_path = new_path.replace(/prssubmit/, `progress/${case_id}`);
    new_path = new_path.replace(/csrsubmit/, `progress/${case_id}`);
    this.props.history.push(new_path, { selectedTab: 'progress' });
  }

  addDays = (dateObj, numDays) => {
    dateObj.setDate(dateObj.getDate() + numDays);
    return dateObj;
  };

  /**
   * Submits progress record or csr when submit button is clicked
   * @param {object} event - click outside event
   * @function
   */
  onSubmitButtonClick = (event) => {
    if (this.validateUpload()) {
      const case_id = this.state.case_id;
      let data = '';
      const files = JSON.stringify(this.state.fileUpload);
      const date_taken = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'pr_date_taken';
        })[0].answer_text;
      const appointment_date = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'next_scheduled_appt';
        })[0].answer_text;
      const csr_requested = this.state.treatment_data.questions.filter(function (q) {
        return q.friendly_name === 'csr_required';
      })[0].answer_text;
      const csq_data = this.state.treatment_data;
      const csq = JSON.stringify(this.state.treatment_data);
      let record_type = 'Progress Record';
      if (csr_requested === 'Yes') {
        record_type = 'CSR';
      }
      const progress_id = this.state.progress_id;
      let url = '';
      let submit = false;
      if (progress_id) {
        submit = true;
      }
      data = {
        case_id,
        csq_data,
        csq,
        date_taken,
        files,
        record_type,
        appointment_date,
        progress_id,
        submit,
        // iTero integration - add iTero file data to payload
        selected_itero_file_ids: this.state.selectedIteroScanIds,
        uploadMethod: this.state.uploadMethod,
        showAlreadyUploadedScans: this.state.showAlreadyUploadedScans,
      };
      if (progress_id) {
        url = `/apiV2/csr_update/${progress_id}`;
      } else {
        url = `/apiV2/csr_submission/${case_id}`;
      }
      let that = this;
      this.setState({
        step: 'in_progress',
        buttons: <div />,
        submit_inprogress: true,
      });
      let send_cst_email = false;
      if (this.state.case_precheck) {
        if (appointment_date) {
          let apt_date = Date.parse(appointment_date);
          const today = new Date();
          let ten_days_from_now = this.addDays(today, 14);
          if (apt_date < today) {
            send_cst_email = true;
          } else if (apt_date < ten_days_from_now) {
            send_cst_email = true;
          }
        } else {
          send_cst_email = true;
        }
      }

      Axios.post(url, data)
        .then(function (res) {
          that.setState({
            step: 'thank_you',
          });
          if (data.record_type === 'CSR') {
            Axios.post(
              `/api/email/?slug=csr-submission&caseId=${removeCaseIdInitialNumber(case_id)}&progressId=${res.data.data.progress_id}&provider=${
                window.location.origin
              }&method=standard`
            );

            Axios.post(
              `/api/email/?slug=csr-submission-cst&caseId=${removeCaseIdInitialNumber(case_id)}&progressId=${res.data.data.progress_id}&provider=${
                window.location.origin
              }&method=standard`
            );
          } else if (data.record_type === 'Progress Record') {
            Axios.post(
              `/api/email/?slug=progress-records-submission-03032020&caseId=${removeCaseIdInitialNumber(case_id)}&progressId=${
                res.data.data.progress_id
              }&provider=${window.location.origin}&method=standard`
            );
            if (send_cst_email) {
              Axios.post(
                `/api/email/?slug=progress-records-submission-cst&caseId=${removeCaseIdInitialNumber(case_id)}&progressId=${
                  res.data.data.progress_id
                }&provider=${window.location.origin}&method=standard`
              );
            }
          }
        })
        .catch(function (err) {
          that.setState({
            error: true,
            submit_inprogress: false,
          });
        });
      // this.props.history.push({ state: { refreshInfo: 'true' } });
    }
  };

  /**
   * Show Empty File Modal
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Hide Empty File Modal
   * @param {Hide } event
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };

  /**
   * Removes error messages displayed on screen
   * @function
   */
  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  /**
   * Shows error messages when there is a validation issue
   * @function
   */
  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'pr_upload' }, { name: 'pr_csq' }, { name: 'pr_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  hasOnePhoto = () => {
    return !!this.state.fileUpload.length || !!this.state.selectedIteroScanIds.length;
  };
  /**
   * Validates if the the file upload is valid or not
   * @function
   * @return {boolean} returns if validation was success or failed
   */
  validateUpload = () => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '';
    if (this.hasOnePhoto() && !this.state.file_removing && this.state.files_uploading.length === 0 && !this.isLoadingIteroPhotos()) {
      return true;
    } else {
      warning_text = '<ul class="wizard-error-text">';
      if (!this.hasOnePhoto()) {
        warning_text = warning_text + '<li>At least 1 file required</li>';
      }
      if (this.state.files_uploading.length > 0 || this.isLoadingIteroPhotos()) {
        warning_text = warning_text + '<li>File upload in progress</li>';
      }

      if (this.state.file_removing) {
        warning_text = warning_text + '<li>File removal in progress</li>';
      }
      warning_text = warning_text + '</ul>';
      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        warning: true,
        error_type: 'Upload Progress Records',
      });
      return false;
    }
  };

  isNoneSelected(csq) {
    if (csq.input_type === 'multiple_question' && !csq.optional) {
      const rb_none_selected = csq.multiple_question.filter((m) => m.input_type === 'radio_button' && m.answer_text === 'None').length > 1;
      return rb_none_selected;
    }
    return false;
  }

  /**
   * Check if all csq are filled out
   * @function
   * @return {boolean} returns true if all csq questions are completed
   */
  isCSQComplete(csq) {
    if (csq.input_type === 'text_area' || csq.input_type === 'text') {
      return csq.optional || csq.answer_text;
    } else if (csq.input_type === 'radio_button') {
      const has_selected = csq.radio_button.reduce((acc, next) => acc || next.radio_checked, false);
      if (has_selected && csq.toothSelector && csq.answer_text === 'Yes') {
        for (let rb of csq.radio_button) {
          if (rb.radio_checked && rb.selectedTeeth.length === 0) return false;
        }
      }
      return has_selected;
    } else if (csq.input_type === 'date') {
      return isValidDate(csq.answer_text) && (csq.optional || csq.answer_text);
    }
    return true;
  }

  /**
   * Check if all multiple questions are filled out
   * @function
   * @return {boolean} returns true if all multiple questions are completed
   */
  isMqComplete(treatment_data, mq) {
    if ((mq.input_type === 'text_area' || mq.input_type === 'text') && !mq.optional && !mq.answer_text) {
      if (mq.related_question_id && mq.related_mq_id) {
        if (
          treatment_data.questions
            .filter((q) => q.id === parseInt(mq.related_question_id))[0]
            .multiple_question.filter((m) => m.id === parseInt(mq.related_mq_id))[0].answer_text !== 'None'
        ) {
          return false;
        }
      } else {
        return false;
      }
    } else if (mq.input_type === 'radio_button') {
      return this.isCSQComplete(mq);
    } else if (mq.input_type === 'date') {
      const is_next_scheduled_appt = mq.friendly_name === 'next_scheduled_appt';
      const is_valid_date = is_next_scheduled_appt ? isValidScheduledDate(mq.answer_text, 1, 'y') || mq.answer_text === '' : isValidDate(mq.answer_text);
      return is_valid_date && (mq.optional || mq.answer_text);
    }
    return true;
  }

  /**
   * Validates smart wire date is enter and is valid
   * @function
   * @return {boolean} returns if validation was success or failed
   */
  smartWireDateValid() {
    const { treatment_data } = this.state;
    let valid = true;
    treatment_data.questions.forEach((csq) => {
      if (csq.input_type === 'multiple_question') {
        for (let mq of csq.multiple_question) {
          if (mq.input_type === 'radio_button') {
            /* eslint-disable */
            mq.radio_button.forEach((btn) => {
              if (btn.date && btn.date !== '' && !isValidDate(btn.date.answer_text)) {
                valid = false;
              }
            });
          }
        }
      }
    });

    return valid;
  }

  /**
   * Check if all treatment data are filled out
   * @function
   * @return {boolean} returns true if required fileds are filled out and in correct format
   */
  isTreatmentDataComplete() {
    const { treatment_data } = this.state;

    if (!this.smartWireDateValid()) return false;

    for (let csq of treatment_data.questions) {
      if (csq.input_type === 'multiple_question' && !csq.optional) {
        for (let mq of csq.multiple_question) {
          if (!this.isMqComplete(treatment_data, mq)) return false;
        }
      } else if (!this.isCSQComplete(csq)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Validates treatment data json
   * @function
   * @return {boolean} returns if validation was success or failed
   */
  validateTreatmentData = () => {
    const { treatment_data } = this.state;
    const errorFields = [];
    let error = false;
    let none_selected = false;
    let prevCsq = null;
    treatment_data.questions.forEach((csq) => {
      if (csq.input_type === 'multiple_question' && !csq.optional) {
        none_selected = none_selected || this.isNoneSelected(csq);
        csq.multiple_question.forEach((mq) => {
          if (!this.isMqComplete(treatment_data, mq)) {
            const errorData = { text: mq.errorFieldName || mq.form_text };
            if (prevCsq) {
              errorData.href = `#${prevCsq.input_type}-${prevCsq.id}`;
            } else {
              errorData.href = `#${csq.input_type}-${csq.id}`;
            }
            errorFields.push(errorData);
            error = true;
            csq.error = true;
            mq.error = true;
          }
        });
      } else if (!this.isCSQComplete(csq)) {
        const errorData = { text: csq.errorFieldName || csq.form_text };
        if (prevCsq) {
          errorData.href = `#${prevCsq.input_type}-${prevCsq.id}`;
        } else {
          errorData.href = `#${csq.input_type}-${csq.id}`;
        }
        errorFields.push(errorData);
        error = true;
        csq.error = true;
      }
      prevCsq = csq;
    });

    if (error) {
      let warning_text = '';
      const progress_records_date_empty = document.getElementById('multiple_question-2-3').value === '';
      const warning = document.querySelector('#warning-submit');
      let warnings = [];
      warnings.push('Incomplete Fields');
      if (none_selected) {
        warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
      } else {
        warning_text += '<p>Incomplete Fields<p>';
        warning_text += '<ul>';
        errorFields.forEach((field) => {
          warning_text += `<li><a href="${field.href}">${field.text}</a></li>`;
        });
        warning_text += '</ul>';
      }

      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        treatment_data: treatment_data,
        warning: true,
        error_type: 'Clinical Support Questionnaire',
      });
      return false;
    }
    return true;
  };

  /**
   * Validates clinical support request json data
   * @function
   * @param {object} event - button click event
   * @return {boolean} returns if validation was success or failed
   */
  validateCsr = (event) => {
    //Fix: const vs. let
    let treatment_data = this.state.csr_data;
    let warning_text = '';
    let error = false;
    let none_selected = false;
    let warning = document.querySelector('#warning-submit');
    treatment_data.questions.forEach((csq) => {
      if ((csq.input_type === 'text_area' || csq.input_type === 'text' || csq.input_type === 'date') && !csq.optional && !csq.answer_text) {
        error = true;
        csq.error = true;
      } else if (csq.input_type === 'radio_button') {
        let rb_selected =
          csq.radio_button.filter(function (rb) {
            return rb.radio_checked === true;
          }).length > 0
            ? true
            : false;
        if (!rb_selected) {
          error = true;
          csq.error = true;
        }
        const selected_rb = csq.radio_button.filter(function (rb) {
          return rb.radio_checked === true;
        })[0];
        if (csq.toothSelector === true && csq.answer_text === 'Yes') {
          if (selected_rb.selectedTeeth.length === 0) {
            error = true;
            csq.error = true;
          }
        }
      } else if (csq.input_type === 'multiple_question' && !csq.optional) {
        let rb_none_selected =
          csq.multiple_question.filter(function (m) {
            return m.input_type === 'radio_button' && m.answer_text === 'None';
          }).length > 1
            ? true
            : false;
        if (rb_none_selected) {
          error = true;
          csq.error = true;
          none_selected = true;
        }
        csq.multiple_question.forEach((mq) => {
          if ((mq.input_type === 'text_area' || mq.input_type === 'text' || mq.input_type === 'date') && !mq.answer_text) {
            if (mq.related_question_id && mq.related_mq_id) {
              if (
                treatment_data.questions
                  .filter(function (q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function (m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
              ) {
                error = true;
                csq.error = true;
                mq.error = true;
              }
            } else {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          } else if (mq.input_type === 'radio_button') {
            let rb_selected =
              mq.radio_button.filter(function (rb) {
                return rb.radio_checked === true;
              }).length > 0
                ? true
                : false;
            if (!rb_selected) {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          }
        });
      }
    });
    if (error) {
      if (!warning_text) {
        if (none_selected) {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
        } else {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li></ul>';
        }
      } else {
        warning_text = warning_text + '</ul>';
      }

      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        csr_data: treatment_data,
        warning: true,
        error_type: 'Clinical Support Questionnaire',
      });
      return false;
    }
    return true;
  };

  onHandleTeethClick = (rb, { id, rb_id }) => {
    const { csr_data } = this.state;
    const selected_teeth = rb.selectedTeeth;
    const question = csr_data.questions.find((csq) => csq.id === id);
    if (question.radio_button) {
      question.radio_button.find((rb) => rb.id === rb_id).selected_teeth = selected_teeth;
    } else if (question.check_box) {
      question.check_box.filter((rb) => rb.id === rb_id)[0].selected_teeth = selected_teeth;
    }
    this.setState({ csr_data });
    this.hide_warning();
  };

  /**
   * Button clicks for previous and next
   * @function
   * @param {object} event - button click event
   * @return {boolean} returns if validation was success or failed
   */
  onButtonClick = (event) => {
    event.preventDefault();
    if (this.state.step === 'pr_csq') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.setState({ step: 'pr_upload' }, scrollToTop);
        this.setButtons('pr_upload');
        this.onIncompleteSave();
      } else if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateTreatmentData()) {
          return;
        } else {
          this.hide_warning();
          this.onIncompleteSave();
        }
        this.setState({ step: 'pr_review' }, scrollToTop);
        this.setButtons('pr_review');
      }
    } else if (this.state.step === 'pr_upload') {
      if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateUpload() || this.state.file_removing || this.state.files_uploading.length > 0) {
          return;
        } else {
          this.hide_warning();
          this.onIncompleteSave();
        }

        this.setState({ step: 'pr_csq' }, scrollToTop);
        this.setButtons('pr_csq');
      }
    } else if (this.state.step === 'pr_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.hide_warning();
        this.setState({ step: 'pr_csq' }, scrollToTop);
        this.setButtons('pr_csq');
      }
    }
  };
  /**
   * Check to see if there is a file uploading
   * @function
   */
  isUploadInProgress = () => {
    return this.state.files_uploading && this.state.files_uploading.length > 0;
  };
  /**
   * Hides warning area
   * @function
   */
  hide_warning = () => {
    let treatment_data = this.state.treatment_data;
    let questions = this.state.treatment_data.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].input_type && questions[i].input_type === 'date' && isValidDate(questions[i].answer_text)) {
        questions[i].error = false;
      }
      if (questions[i].hasOwnProperty('multiple_question')) {
        for (let j = 0; j < questions[i].multiple_question.length; j++) {
          if (questions[i].multiple_question[j].error) {
            if (isValidDate(questions[i].multiple_question[j].answer_text)) {
              questions[i].multiple_question[j].error = false;
            }
          }
          if (questions[i].multiple_question[j].hasOwnProperty('radio_button')) {
            for (let k = 0; k < questions[i].multiple_question[j].radio_button.length; k++) {
              if (questions[i].multiple_question[j].radio_button[k].date && questions[i].multiple_question[j].radio_button[k].date.error) {
                if (isValidDate(questions[i].multiple_question[j].radio_button[k].date.answer_text)) {
                  questions[i].multiple_question[j].radio_button[k].date.error = false;
                }
              }
            }
          }
        }
      }
    }

    this.setState({
      treatment_data: treatment_data,
      warning: false,
    });
  };

  goBack() {
    this.props.history.goBack();
  }

  /**
   * Closes the modal for delete draft
   * @function
   */
  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  /**
   * Opens modal for delete draft
   * @function
   */
  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  /**
   * Checkbox button click event
   * @function
   * @param {object} event - checkbox click event
   */
  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons[id].booleanValue;
    request_reasons[id].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  /**
   * Textbox blur event
   * @function
   * @param {object} event - textbox click event
   */
  onCsqTextBlur = (event) => {
    const id = parseInt(event.target.dataset.csqId) || 0;
    const mqId = parseInt(event.target.dataset.mqId) || 0;
    const answerText = removeEmoji(textFieldLimited(event.target.value.trim()));
    const { treatment_data } = this.state;
    const inputType = event.target.dataset.inputType;
    const dateId = event.target.dataset.dateId;
    const isMqDate = inputType === 'multiple_question' && !!dateId;
    const isMultiple = mqId !== 0;
    const foundQuestion = treatment_data.questions.find((csq) => csq.id === id);
    const question = isMultiple ? foundQuestion.multiple_question.find((mq) => mq.id === mqId) : foundQuestion;

    if (isMqDate) {
      question.radio_button.forEach((rb) => {
        if (rb.date) {
          rb.date.answer_text = answerText;
        }
      });
    } else {
      question.answer_text = answerText;
    }

    this.setState({ treatment_data });
    this.hide_warning();
  };

  /**
   * Edit button click
   * @function
   * @param {object} event - button click event
   */
  onEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  /**
   * Removes the draft from database and closes the modal
   * @function
   * @param {object} event - button click event
   */
  onRemoveDraft = (event) => {
    if (this.state.progress_id) {
      const that = this;
      let selected_progress_id = this.state.progress_id;
      const status_comment = 'Delete Draft';
      let data = {
        status_comment,
      };
      this.setState({
        loading: true,
      });
      Axios.delete(`/apiV2/praction/${selected_progress_id}/delete_record`, { data })
        .then(function (res) {
          that.goBack();
          this.setState({
            loading: false,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      this.goBack();
    }

    // update navbar case count
    const doctorId = getDoctorsIdFromRoute();
    this.props.fetchLoggedDoctorCaseCount({ doctorId });
  };
  /**
   * Checkbox button click event on clinical support questionaire
   * @function
   * @param {object} event - button click event
   */
  onCsqCbChange = (event) => {
    const { treatment_data } = this.state;
    const id = parseInt(event.target.dataset.csqId) || 0;
    const rb_id = parseInt(event.target.dataset.rbId) || 0;
    const question_rb = treatment_data.questions.find((csq) => csq.id === id).check_box.find((rb) => rb.id === rb_id);
    question_rb.checked = !question_rb.checked;

    this.setState({ treatment_data });
    this.hide_warning();
  };
  /**
   * Radio button click event for clinical support questionaire
   * @function
   * @param {object} event - radio button click event
   */
  onCsqRbChange = (event) => {
    const { treatment_data: treatmentData, csr_data: csrData } = this.state;
    const id = parseInt(event.target.dataset.csqId) || 0;
    const mqId = parseInt(event.target.dataset.mqId) || 0;
    const rbId = parseInt(event.target.dataset.rbId) || 0;
    const isSmartwire = event.target.dataset.isSmartwire;

    const isMultiple = mqId !== 0;
    let question = treatmentData.questions.find((csq) => csq.id === id);
    question = isMultiple ? question.multiple_question.find((mq) => mq.id === mqId) : question;
    const first_radio_btn = question.radio_button.find((rb) => rb.id === rbId);
    if (!isMultiple || isSmartwire) {
      question.answer_text = first_radio_btn.radio_label;
    }
    question.radio_button.forEach((rb) => (rb.radio_checked = rb.id === rbId));

    const csrRequired = treatmentData.questions.find((csq) => csq.friendly_name === 'csr_required');
    let isCsrRequested = this.state.csr_requested;
    if (csrRequired && id === csrRequired.id) {
      const isAnsweredYes = csrRequired.answer_text === 'Yes';
      isCsrRequested = isAnsweredYes ? true : isCsrRequested;
      treatmentData.questions = isAnsweredYes
        ? [...treatmentData.questions, ...this.state.csr_data.questions]
        : treatmentData.questions.filter((csq) => csq.id < 1000);

      const csrQuestions = treatmentData.questions.filter((csq) => csq.id >= 1000);
      if (csrQuestions.length > 0) {
        csrData.questions = csrQuestions;
      }
    }

    this.setState({
      treatment_data: treatmentData,
      csr_data: csrData,
      csr_requested: isCsrRequested,
    });
    this.hide_warning();
  };

  /**
   * Textbox change event on upload screen
   * @function
   * @param {object} event - textbox change event
   */
  onUploadTextChange = (event) => {
    const upload_date = removeEmoji(textFieldLimited(event.target.value));
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  /**
   * Textbox blur event
   * @function
   * @param {object} event - textbox blur event
   */
  onUploadTextBlur = (event) => {
    const upload_date = textFieldLimited(event.target.value.trim());
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  /**
   * Get treatment data question inner elements
   * @function
   * @param {Array} ids - Array of ids
   */
  getCsqInnerElement = (questions, ids) => {
    if (ids.length === 1) {
      return questions.filter(function (csq) {
        return csq.id === parseInt(ids[0]);
      })[0];
    } else if (ids.length === 2) {
      return questions
        .filter(function (csq) {
          return csq.id === parseInt(ids[0]);
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === parseInt(ids[1]);
        })[0];
    } else if (ids.length === 3) {
      return questions
        .filter(function (csq) {
          return csq.id === parseInt(ids[0]);
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === parseInt(ids[1]);
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === parseInt(ids[2]);
        })[0];
    } else if (ids.length === 4) {
      return questions
        .filter(function (csq) {
          return csq.id === parseInt(ids[0]);
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === parseInt(ids[1]);
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === parseInt(ids[2]);
        })[0].date;
    } else return questions;
  };

  /**
   * Textbox change event on csq screen
   * @function
   * @param {object} event - textbox change event
   */
  onCsqTextChange = (event) => {
    const { treatment_data } = this.state;
    const answerText = removeEmoji(textFieldLimited(event.target.value));
    const id = parseInt(event.target.dataset.csqId) || 0;
    const rbId = parseInt(event.target.dataset.rbId) || 0;
    const mqId = parseInt(event.target.dataset.mqId) || 0;
    const dateId = event.target.dataset.dateId;
    const inputType = event.target.dataset.inputType;
    const isTextarea = inputType === 'textarea';
    const isMqDate = inputType === 'multiple_question' && !!dateId;
    const isMultiple = mqId !== 0;
    const foundQuestion = treatment_data.questions.find((csq) => csq.id === id);
    const question = isMultiple ? foundQuestion.multiple_question.find((mq) => mq.id === mqId) : foundQuestion;

    if (isTextarea) {
      question.radio_button.find((rb) => rb.id === rbId).text_area.answer_text = answerText;
    } else if (isMqDate) {
      question.radio_button.forEach((rb) => {
        if (rb.date) {
          rb.date.answer_text = answerText;
        }
      });
    } else {
      question.answer_text = answerText;
    }

    this.setState({ treatment_data });

    if (event.target.type === 'date') {
      const id_string = event.target.dataset.id;
      const date_id = id_string.split(/-(.+)/)[1];
      const date_id_array = date_id.split('-');
      const is_next_scheduled_appt = this.getCsqInnerElement(treatment_data.questions, date_id_array).friendly_name === 'next_scheduled_appt';
      const is_valid_scheduled_date = !isValidScheduledDate(event.target.value, 1, 'y');
      const is_valid_normal_date = !isValidDate(event.target.value);
      const is_valid_date = is_next_scheduled_appt ? is_valid_scheduled_date : is_valid_normal_date;
      this.getCsqInnerElement(treatment_data.questions, date_id_array).error = is_valid_date;
    }

    this.hide_warning();
  };

  /**
   * File upload process begininng
   * @function
   * @param {object} filenames - different file names
   */
  files_upload = (filenames) => {
    this.setState({
      files_uploading: filenames,
    });
  };

  removeWarning = () => {
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    this.setState({
      warning: false,
    });
  };

  /**
   * iTero integration
   *
   * Fetch iTero photos
   * @param {number} iteroId - iTero id
   * @returns {void}
   */
  fetchIteroPhotos = (iteroId) => {
    this.props.fetchIteroPhotos(iteroId, {
      onSuccess: (iteroPhotos) => {
        const iteroPhotoIds = iteroPhotos.map((file) => file.id);
        this.props.getIteroPhotoRecordsForProgressRecord(
          {
            caseId: this.state.case_id,
            iteroFileIds: iteroPhotoIds,
            iteroId,
          },
          {
            onSuccess: (data) => {
              this.hide_warning();
              const { success } = data;
              const uploadedIteroPhotos = Object.values(success);
              this.setState((prevState) => {
                const photoUploadStateKey = this.getIteroPhotosStateKey();

                // remove old itero photos from state
                const filteredPrevStatePhotoUpload = prevState[photoUploadStateKey].filter((file) => !file.is_itero_photo);

                const newPhotoUploadData = [...filteredPrevStatePhotoUpload, ...uploadedIteroPhotos];

                return {
                  [photoUploadStateKey]: newPhotoUploadData,
                };
              });
            },
          }
        );
      },
    });
  };

  /**
   * iTero integration
   *
   * Removes all Itero photos from the state and updates the selected Itero photo.
   * @param {string} oldIteroId - The ID of the old Itero photo.
   * @param {string} newSelectedIteroId - The ID of the new selected Itero photo.
   * @returns {void}
   */
  updateIteroPhotos = (oldIteroId, newSelectedIteroId) => {
    this.removeCurrentIteroPhotoRecords();
    const that = this;
    if (this.hasIteroPhotos()) {
      this.props.deleteIteroPhotosFromProgressRecord(
        {
          filePaths: this.state.fileUpload.filter((file) => file.is_itero_photo).map((file) => file.upload_data),
        },
        {
          onSuccess: () => {
            if (that.state.shouldFetchIteroPhotos) {
              that.fetchIteroPhotos(newSelectedIteroId);
            }
          },
        }
      );
    } else if (this.state.shouldFetchIteroPhotos) {
      that.fetchIteroPhotos(newSelectedIteroId);
    }
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    for (let index = 0; index < data.length; index++) {
      const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
      data[index]['file_url'] = file_url;
    }

    this.removeWarning();

    if (data && data[0].folder) {
      let uploadData = [];
      uploadData = this.state.fileUpload;
      uploadData = uploadData.concat(data);

      this.setState({
        fileUpload: uploadData,
        files_uploading: [],
      });
    }
  };

  prRemoveUpload = (data) => {
    const that = this;
    that.setState({
      file_removing: true,
    });
    Axios.post(`/apiV2/pr_remove_upload`, data).then(function (res) {
      if (res.data) {
        that.setState((prevState) => {
          let uploadData = prevState.fileUpload;
          let newFileUpload = [];

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].upload_data !== data.files.substring(1)) {
              newFileUpload.push(uploadData[i]);
            }
          }
          return {
            upload_Component: '',
            fileUpload: newFileUpload,
            file_removing: false,
          };
        });
      }
    });
  };

  /**
   * iTero integration
   *
   * Get state key for iTero photos
   */
  getIteroPhotosStateKey() {
    return 'fileUpload';
  }

  /**
   * iTero integration
   *
   * Remove current itero photo records from state
   */
  removeCurrentIteroPhotoRecords() {
    this.setState((prevState) => {
      const photoUploadStateKey = this.getIteroPhotosStateKey();

      // remove old itero photos from state
      const filteredPrevState = prevState[photoUploadStateKey].filter((file) => !file.is_itero_photo);

      return {
        [photoUploadStateKey]: filteredPrevState,
      };
    });
  }

  /**
   * iTero integration
   *
   * Handler for record viewer remove itero files button click
   * @function
   */
  handleRemoveIteroScansClick = () => {
    const newState = {
      selectedIteroScanIds: [],
      selectedIteroScanRecords: [],
      selectedIteroScans: [],
      fileUpload: this.state.fileUpload.filter((file) => !file.is_itero_scan),
    };

    if (!this.hasIteroPhotos()) {
      newState['selectedIteroId'] = '';
    }

    this.setState(newState);
  };

  /**
   * iTero integration
   *
   * Handler for record viewer remove itero files button click
   * @function
   */
  handleRemoveIteroPhotosClick = () => {
    this.handleShouldFetchIteroPhotosChange(false);
    this.removeCurrentIteroPhotoRecords();
    this.props.deleteIteroPhotosFromProgressRecord({
      filePaths: this.state.fileUpload.filter((file) => file.is_itero_photo).map((file) => file.upload_data),
    });

    if (!this.state.selectedIteroScanIds.length) {
      this.setState({
        selectedIteroId: '',
      });
    }
  };

  /**
   * Remove file upload
   * @function
   * @param {object} event - button click event
   */
  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();

    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');

    if (startingPath >= 0 && endPath >= 0) {
      let files = href.substring(startingPath, href.length);

      const data = {
        files,
      };

      this.prRemoveUpload(data);
    }
  };

  setButtons(action) {
    if (action === 'pr_upload') {
      this.setState({
        buttons: (
          <Button data-position={'next'} onClick={this.onButtonClick}>
            Next
          </Button>
        ),
      });
    } else if (action === 'pr_csq') {
      this.setState({
        buttons: (
          <>
            <Button theme="secondary" data-position={'prev'} onClick={this.onButtonClick}>
              Back
            </Button>
            <Button data-position={'next'} onClick={this.onButtonClick}>
              Next
            </Button>
          </>
        ),
      });
    } else if (action === 'pr_review') {
      this.setState({
        buttons: (
          <Button theme="secondary" data-position={'prev'} onClick={this.onButtonClick}>
            Back
          </Button>
        ),
      });
    }
  }

  /**
   * Save and exit button click
   * @function
   * @param {object} event - button click event
   */
  onSaveAndExitClick = (event) => {
    this.setState({
      loading: true,
    });
    this.onIncompleteSave(true);
  };

  /**
   * Saves draft for progress record or csr
   * @function
   * @param {boolean} exit - if the process should exit after saving the draft
   */
  onIncompleteSave = (exit = false) => {
    if (!this.state.submit_inprogress) {
      let i = 20;
      do {
        i--;
      } while (this.state.draft_save_inprogress && i > 0);

      const case_id = this.state.case_id;
      const files = JSON.stringify(this.state.fileUpload);
      const date_taken = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'pr_date_taken';
        })[0].answer_text;
      const appointment_date = this.state.treatment_data.questions
        .filter(function (q) {
          return q.friendly_name === 'pr_dates';
        })[0]
        .multiple_question.filter(function (q) {
          return q.friendly_name === 'next_scheduled_appt';
        })[0].answer_text;
      const csr_requested = this.state.treatment_data.questions.filter(function (q) {
        return q.friendly_name === 'csr_required';
      })[0].answer_text;
      const csq_data = this.state.treatment_data;
      const csq = JSON.stringify(this.state.treatment_data);
      const progress_id = this.state.progress_id;
      let record_type = 'Progress Record';
      if (csr_requested === 'Yes') {
        record_type = 'Clinical Support Request';
      }
      const draft = true;
      let data = {
        case_id,
        csq_data,
        csq,
        date_taken,
        files,
        record_type,
        appointment_date,
        progress_id,
        draft,
        // iTero integration - add iTero file data to payload
        selected_itero_file_ids: this.state.selectedIteroScanIds,
        uploadMethod: this.state.uploadMethod,
        showAlreadyUploadedScans: this.state.showAlreadyUploadedScans,
        shouldFetchIteroPhotos: this.state.shouldFetchIteroPhotos,
      };
      let that = this;
      let url = '';
      if (progress_id) {
        url = `/apiV2/csr_update/${progress_id}`;
      } else {
        url = `/apiV2/csr_submission/${case_id}`;
      }
      this.setState({ draft_save_inprogress: true, showSaveModal: exit });
      if (!this.state.submit_inprogress) {
        Axios.post(url, data)
          .then(function (res) {
            that.setState({ progress_id: res.data.data.progress_id, draft_save_inprogress: false });
          })
          .catch(function (err) {
            if (exit) {
              that.redirectUserToCaseDetail();
            }
          });
      }
    }
  };

  getStepsCompleted() {
    const pr_upload =
      this.hasOnePhoto() &&
      !this.state.file_removing &&
      this.state.files_uploading.length === 0 &&
      !this.isLoadingIteroPhotos() &&
      !this.isDeletingIteroPhotos();
    const pr_csq = this.isTreatmentDataComplete();
    const pr_review = pr_upload && pr_csq;
    return { pr_upload, pr_csq, pr_review };
  }

  getStepIndex(step = this.state.step) {
    switch (step) {
      case 'pr_upload':
        return 0;
      case 'pr_csq':
        return 1;
      case 'pr_review':
        return 2;
      default:
        return 0;
    }
  }

  getStepperContent() {
    const completed = this.getStepsCompleted();
    return [
      {
        description: 'pr_upload',
        label: 'Progress Records',
        active: this.state.step === 'pr_upload',
        completed: completed.pr_upload,
        uploading: this.state.files_uploading && this.state.files_uploading.length > 0,
      },
      {
        description: 'pr_csq',
        label: 'Treatment Details',
        active: this.state.step === 'pr_csq',
        completed: completed.pr_csq,
        disabled: !completed.pr_upload,
      },
      {
        description: 'pr_review',
        label: 'Submit',
        active: this.state.step === 'pr_review',
        completed: completed.pr_review,
        disabled: !completed.pr_upload || !completed.pr_csq,
      },
    ];
  }

  onStepperClick = (event) => {
    const clickedStep = event.target.dataset.description;
    const isBackStep = this.getStepIndex(clickedStep) <= this.getStepIndex();
    const nextStep = this.getNextStepperNameByStepperName(this.state.step);
    const stepsCompleted = this.getStepsCompleted();
    if (isBackStep || stepsCompleted[clickedStep]) {
      this.setState({ step: clickedStep });
      this.setButtons(clickedStep);
      this.hide_warning();
    } else if (stepsCompleted[this.state.step]) {
      this.setState({ step: nextStep });
      this.setButtons(nextStep);
    }
  };

  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    let new_file_upload = this.state.fileUpload;
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);

    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ fileUpload: new_file_upload });
    }
  };

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <CircleLoader fullscreen />;
    } else {
      return (
        <div>
          <Helmet>
            <title>{this.state.title} Submission | InBrace Smile Design Studio™</title>
          </Helmet>

          <div className="title-height content__header">
            {!this.state.hide && this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
              <LoadingButton
                onClick={() => this.onIncompleteSave(true)}
                disabled={(this.state.files_uploading && this.state.files_uploading.length > 0) || this.isLoadingIteroPhotos() || this.isDeletingIteroPhotos()}
                data-position="save"
                isLoading={this.state.draft_save_inprogress || this.isLoadingIteroPhotos() || this.isDeletingIteroPhotos()}
                loaderWithSpacing={false}
              >
                Save
              </LoadingButton>
            ) : null}
            <h1 className="fs-exclude">
              {this.state.title} - {this.state.patient_first_name} {this.state.patient_last_name}
            </h1>
          </div>
          {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
            <CardContainer>
              <Stepper activeStepper={this.getStepIndex()} stepperContent={this.getStepperContent()} onStepperClick={this.onStepperClick} />
            </CardContainer>
          ) : null}
          <CardContainer className="case-card-container pad-lg clearfix">
            {this.state.step === 'pr_upload' ? (
              this.state.upload_Component === '' ? (
                <Upload
                  goBack={this.onRemoveDraft}
                  photoUpload={this.state.fileUpload}
                  onEmptyFileError={this.onEmptyFileError}
                  onUpload={this.onUpload}
                  onRemove={this.onRemove}
                  upload_state={this.files_upload}
                  upload_content={this.state.files_uploading}
                  show_warning={this.show_warning}
                  hide_warning={this.hide_warning}
                  location={this.state.file_location}
                  upload_date={this.state.upload_date}
                  onTextChange={this.onUploadTextChange}
                  onBlur={this.onUploadTextBlur}
                  error={this.state.warning}
                  updateRecordState={this.updateRecordState}
                  // iTero integration
                  withIteroSelection={this.state.isEnrolledToiTeroIntegration}
                  uploadMethod={this.state.uploadMethod}
                  onUploadMethodChange={this.handleUploadMethodChange}
                  selectedIteroScanRecords={this.state.selectedIteroScanRecords}
                  iteroSelectionProps={this.getIteroSelectionProps()}
                  recordViewerSubtitle={this.getRecordViewerSubtitle(false, this.isLoadingIteroPhotos())}
                  onRemoveIteroScansClick={this.handleRemoveIteroScansClick}
                  onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
                  showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
                  isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
                  isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
                  isBuildingIteroScansRecords={this.state.isBuildingIteroScansRecords}
                />
              ) : (
                this.state.upload_Component
              )
            ) : null}
            {this.state.step === 'pr_csq' ? (
              <TreatmentDetails
                treatment_data={this.state.treatment_data}
                onCheckboxClicked={(e) => this.onCheckboxClicked(e)}
                onTextChange={this.onCsqTextChange}
                onRbChange={this.onCsqRbChange}
                onCbChange={this.onCsqCbChange}
                onBlur={this.onCsqTextBlur}
                error={this.state.warning}
                onHandleTeethClick={this.onHandleTeethClick}
                photoUpload={this.state.fileUpload}
                // iTero integration
                selectedIteroScanRecords={this.state.selectedIteroScanRecords}
                updateRecordState={this.updateRecordState}
              />
            ) : null}
            {this.state.step === 'thank_you' ? (
              <SubmissionConfirmation
                placeholder_text={'Progress Record'}
                csr_requested={this.state.csr_requested}
                onButtonClick={this.redirectUserToCaseDetail}
                refreshInfo
              />
            ) : null}

            {this.state.step === 'in_progress' ? <SubmitterLoader /> : null}
            {this.state.step === 'pr_review' ? (
              <CsrReview
                case_id={this.state.case}
                treatment_data={this.state.treatment_data}
                fileUpload={this.state.fileUpload}
                onCheckboxClicked={this.onCheckboxClicked}
                onTextChange={this.onCsqTextChange}
                onRbChange={this.onCsqRbChange}
                onCbChange={this.onCsqCbChange}
                onFocus={this.onCsqTextFocus}
                error={this.state.warning}
                onEditClick={this.onEditClick}
                draft_save_inprogress={this.state.draft_save_inprogress}
                onSubmitButtonClick={this.onSubmitButtonClick}
                onhandleTeethClick={(rb, id_string) => {
                  this.onhandleTeethClick(rb, id_string);
                }}
                // iTero integration
                selectedIteroScanRecords={this.state.selectedIteroScanRecords}
              />
            ) : null}
          </CardContainer>

          {!this.state.hide && (
            <div className="case-form-controls">
              {this.state.buttons}
              {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' && (
                <Button theme="revise" onClick={this.onModalShow} data-redirect="true">
                  Delete
                </Button>
              )}
            </div>
          )}

          <ErrorMessage
            className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
            title={this.state.error_type}
            onClose={this.removeWizardErrorMsg}
          >
            <div id="warning-submit" />
          </ErrorMessage>

          {this.state.showCancelCase === true ? (
            <Modal
              preset="decision"
              header_text="Delete Draft"
              modal_class="modal-content-warning"
              message_text={
                <span>
                  Are you sure you want to delete this draft submission?
                  <br />
                  You will not be able to undo this action if you proceed.
                </span>
              }
              close_btn_text="Cancel"
              confirm_btn_text="Delete"
              onCloseButtonClick={this.onModalDismiss}
              onConfirmButtonClick={this.onRemoveDraft}
            />
          ) : null}

          {this.state.showEmptyFileModal && <EmptyFileErrorModal preset="ipp" onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} />}

          {this.state.showSaveModal === true ? (
            <Modal
              preset="decision"
              header_text="Draft Saved"
              message_text={
                <span>
                  Your draft has been successfully saved! Click <span className="emphasis">Continue</span> to resume your submission
                </span>
              }
              in_progress={this.state.draft_save_inprogress}
              confirm_btn_text="Continue"
              close_btn_text="Return to Case Details"
              onCloseButtonClick={this.redirectUserToCaseDetail}
              onConfirmButtonClick={() => {
                this.setState({ showSaveModal: false });
              }}
            />
          ) : null}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    // iTero integration - redux state
    licenseNumbers: getLicenseNumbers(state),
    isFetchIteroPhotosPending: getFetchIteroPhotosPending(state),
    isGetIteroPhotoRecordsForProgressRecordPending: getGetIteroPhotoRecordsForProgressRecordPending(state),
    isDeleteIteroPhotosFromProgressRecordPending: getDeleteIteroPhotosFromProgressRecordPending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      // iTero integration - redux actions
      fetchLicenseNumbers,
      fetchIteroScans,
      fetchIteroPhotos,
      getIteroPhotoRecordsForProgressRecord,
      deleteIteroPhotosFromProgressRecord,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CsrSubmission));
