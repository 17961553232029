import { supportsPDFs } from './browser';
import Axios from 'axios';

/**
 * Returns pdf url with date
 * @function
 * @param {string} pdf_url - Original pdf url
 * @returns {string} Pdf url
 */
const getPdfURL = (pdf_url) => {
  const pdf_object = getPDFObject(pdf_url);
  if (pdf_object && pdf_object.url) {
    const pdf_date = null;
    const date = pdf_date ?? pdf_object?.params?.date;
    const datetime = new Date(date).getTime();
    const params = { ...pdf_object.params, date: isNaN(datetime) ? '' : datetime };
    const param_string = new URLSearchParams(params).toString();
    return `${pdf_object.url}?${param_string}`;
  }
};

/**
 * Returns pdf object
 * @function
 * @param {string} pdf_url - Pdf url
 * @returns {object} Pdf object
 */
const getPDFObject = (pdf_url) => {
  return pdf_url ? { url: '/media/pdf/', params: { file: pdf_url } } : {};
};

/**
 * Close the pdf_view modal after browser redirect because of pdf not supported
 * so that when click back, does not show the empty modal
 * @function
 */
const closePDFModalOnRedirect = () => {
  const close_btn = document.getElementById('modal_close_btn');
  if (close_btn && !supportsPDFs('', '#pdf')) {
    close_btn.click();
  }
};

/**
 * Fetches a file from the specified URL and opens it in a new browser window.
 *
 * @param {string} url - The URL of the file to fetch and view.
 * @param {Object} [customHeaders={}] - Optional custom headers for the fetch request.
 * @returns {Promise<void>} A Promise that resolves when the file is opened successfully.
 */
const openPdfNewTab = async (url, customHeaders = {}) => {
  try {
    const config = {
      headers: {
        ...customHeaders,
      },
      responseType: 'blob', // Set responseType to 'blob' to get a binary blob response
    };
    // Fetch the file using the specified URL and custom headers
    const response = await Axios.get(url, config);

    if (response.status !== 200) {
      throw new Error(`Failed to fetch file from ${url}.`);
    }

    // Retrieve the blob and create a local URL
    const blob = response.data;
    const localUrl = window.URL.createObjectURL(blob);

    // Open the file in a new browser window/tab and focus it
    const newWindow = window.open(localUrl, '_blank');
    if (newWindow) {
      newWindow.focus();
    } else {
      throw new Error('Failed to open a new window.');
    }
  } catch (err) {
    console.error('An error occurred:', err);
  }
};

export { getPdfURL, closePDFModalOnRedirect, openPdfNewTab };
