import React from 'react';
//To be refactor in loading

const Loader = ({ name }) => (
  <div className="loader-container">
    <header className="loader-header">
      <img src={process.env.PUBLIC_URL + '/static/img/img_loader.gif'} className="iloader" alt="loader" />
      <p>Loading...</p>
    </header>
  </div>
);

export default Loader;
