import React, { Component } from 'react';
import { setTokenHeader } from '../../common/functions';
import axios from 'axios';
import Button from '../components/buttons/button';
import TextBox from '../components/inputs/text_box';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword1: '',
      newPassword2: '',
    };
  }

  /**
   * Updates passowrd in the respective field
   * @function
   * @param {Object} event - Password edit event
   */
  handlePasswordChange = (event) => {
    this.props.clearError();
    let password = {};
    password[event.target.id] = event.target.value;
    this.setState(password);
  };

  updatePassword = (e) => {
    e.preventDefault();

    let that = this;
    setTokenHeader();

    var profile = {};
    profile['password'] = this.state.currentPassword;

    axios
      .post('/apiv3/user/password/validate', profile)
      .then((result) => {
        that.props.onUpdatePasswordClicked(that.state.currentPassword, that.state.newPassword1, that.state.newPassword2, that);
      })
      .catch(function (err) {
        that.props.onUpdatePasswordClicked(that.state.currentPassword, that.state.newPassword1, that.state.newPassword2, that);
      });
  };

  render() {
    let wrongPasswordError = this.props.wrongPasswordError ? (
      <div>
        <span className="heading-item-warning">{this.props.wrongPasswordError}</span>
      </div>
    ) : (
      ''
    );

    return (
      <>
        <div className="edit-table">
          <h3>Update Password</h3>
          <form>
            <label className="control-label emphasis" htmlFor="currentPassword">
              Current Password
            </label>
            <TextBox type="password" id="currentPassword" value={this.state.currentPassword} onChange={this.handlePasswordChange} />
            <label className="control-label emphasis" htmlFor="newPassword1">
              New Password
            </label>
            <TextBox type="password" id="newPassword1" value={this.state.newPassword1} onChange={this.handlePasswordChange} />
            <label className="control-label emphasis" htmlFor="newPassword2">
              Confirm New Password{' '}
            </label>
            <TextBox type="password" id="newPassword2" value={this.state.newPassword2} onChange={this.handlePasswordChange} />
          </form>
          <div className="block--right">
            <Button onClick={this.updatePassword} disabled={this.props.editing}>
              Update
            </Button>
          </div>
        </div>
        {wrongPasswordError}
      </>
    );
  }
}

export default UpdatePassword;
