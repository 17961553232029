import './ip_conversion.scss';

// External Libs
import React from 'react';
import ProcessComponent from '../../../../components/workflow/process_component';
import Modal from '../../../../components/modal/modal';
import { userHasPermission } from '../../../../common/permission';
import { removeCaseIdInitialNumber } from '../../../../common/functions';

/**
 * Displays Automated wirebending conversion step
 * @component
 * @alias IPConversion
 * @category BPP
 */
class IPConversion extends ProcessComponent {
  constructor(props) {
    super(props);

    this.state = {
      showCompleteModal: false,
      showIncompleteModal: false,
      upload_clicked: false,
      disable_complete_incomplete: false,
      page_refreshed: false,
    };
  }

  componentDidMount() {
    if (this.props.ip_process.status === 'Recipe Conversion Process') {
      this.setState({
        page_refreshed: true,
      });
    }
  }

  onUploadClick = (event) => {
    if (this.state.upload_clicked === false) {
      this.taskRunner('onIpConversionUploadClick', event).then((result) => {
        if (result) {
          this.props.reload_information();
        }
      });

      this.setState({
        upload_clicked: true,
      });
    }
  };

  getConversionFailureMessageFromCaseStatusLog() {
    const statuses = this.props.ip_process.log.filter((l) => {
      return l.status_code === 'Recipe Conversion Failed';
    });

    let status_comment = '';

    if (statuses && statuses.length > 0) {
      status_comment = statuses[statuses.length - 1].text;
    }

    return status_comment;
  }

  enterIpConversionFailedProcess = (event) => {
    this.setState({
      disable_complete_incomplete: true,
    });

    this.taskRunner('onIpConversionFailedClick', event).then((result) => {
      if (result) {
        // this.props.reload_information();
      }

      this.setState({
        showIncompleteModal: false,
      });
    });
  };

  enterIpConversionCompleteProcess = (event) => {
    this.setState({
      disable_complete_incomplete: true,
    });

    this.taskRunner('onIpConversionConfirmClick', event).then((result) => {
      if (result) {
        this.setState({
          showCompleteModal: false,
        });
        this.props.reload_information();
      }

      this.setState({
        showCompleteModal: false,
      });
    });
  };

  /* Incomplete modal */

  toggleIncompleteModal = () => {
    this.setState({ showIncompleteModal: !this.state.showIncompleteModal });
  };

  onIncompleteClick = (event) => {
    this.toggleIncompleteModal();
  };

  onCancelIncompleteModal = (event) => {
    this.toggleIncompleteModal();
  };

  incompleteModal = () => {
    const header_text = `Conversion Incomplete - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to set status to incomplete for this IP conversion?"
        confirm_btn_text="Incomplete"
        onConfirmButtonClick={this.enterIpConversionFailedProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelIncompleteModal}
      />
    );
  };

  /* Complete modal */

  toggleCompleteModal = () => {
    this.setState({ showCompleteModal: !this.state.showCompleteModal });
  };

  completeModal = () => {
    const header_text = `Conversion Complete - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to complete this IP conversion?"
        confirm_btn_text="Complete"
        onConfirmButtonClick={this.enterIpConversionCompleteProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelCompleteModal}
      />
    );
  };

  onCompleteClick = (event) => {
    this.toggleCompleteModal();
  };

  onCancelCompleteModal = (event) => {
    this.toggleCompleteModal();
  };

  render() {
    return (
      <div className="cad-window">
        {!(this.props.case_status === 'STATUS_HOLD' || this.props.case_status === 'STATUS_CANCEL') ? (
          <React.Fragment>
            {this.props.stage !== 'ip_conversion_failed' ? (
              <div>
                <p className="process_message">Conversion in Progress...</p>
                <i className="fa fa-refresh font-size-lg rotate conversion-icon" aria-hidden="true" />
              </div>
            ) : (
              <div className="cad-window">
                <div className="paragraph">
                  <b>Conversion Failed</b>
                </div>
                <div className="font-size-reg">File conversion failed for the following reason:</div>
                <div className="font-size-reg bold-text">{this.getConversionFailureMessageFromCaseStatusLog()}</div>
                <div className="font-size-reg">Click below retry conversion.</div>
                <div className="button-panel">
                  <button type="button" className="btn btn-light" onClick={this.onUploadClick}>
                    Upload
                  </button>
                </div>
              </div>
            )}
            {this.props.stage !== 'ip_conversion_failed' &&
            userHasPermission('BYPASS_CONVERSION_PROCESS', this.props.user_roles_and_permissions.permissions) &&
            !this.state.page_refreshed ? (
              <div className="button-panel">
                <button
                  type="button"
                  className={'btn btn-light ' + (this.state.disable_complete_incomplete ? 'btn-disabled' : '')}
                  onClick={this.onCompleteClick}
                >
                  Complete
                </button>

                <button
                  type="button"
                  className={'btn btn-light ' + (this.state.disable_complete_incomplete ? 'btn-disabled' : '')}
                  onClick={this.onIncompleteClick}
                  disabled={this.state.disable_complete_incomplete}
                >
                  Incomplete
                </button>
              </div>
            ) : null}
            {this.state.showCompleteModal ? this.completeModal() : null}
            {this.state.showIncompleteModal ? this.incompleteModal() : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default IPConversion;
