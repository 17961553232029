import '../case_list/case_list.scss';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Axios from 'axios';

import CustomerAgreement from '../customer_agreement/customer_agreement';
import Content from './content';
import NotFound from '../404/not_found';
import NoAccount from './no_account';

import { setTokenHeader } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { getDoctorsIdFromRoute } from '../../common/route';

//Redux
import { setDoctorInformation } from '../../redux/actions/ipp/case_list';

class DsoAccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: null,
      error: false,
      loading: true,
      terms: false,
      dso_doctors: [],
      dso_doctors_loading: true,
    };
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();

    setTimeout(function () {
      this.$('#affixSideBar').affix({
        offset: {
          top: 150,
        },
      });
    }, 0);

    //Push history

    // Get own doctor account info
    Axios.get(`/apiv3/doctor`)
      .then(function (res) {
        // Get doctor's account info (using id from url)
        Axios.get(`/apiv3/doctor/${doctor_id}/dso`)
          .then(function (doctor_res) {
            if ((res.data.doctor[0].role !== 'DSO' && res.data.doctor[0].role !== 'Business') || doctor_res.data.doctor[0].role !== 'DSO') {
              that.setState({
                error: true,
              });
            }

            that.setState({ dso_doctors: doctor_res.data.dso_doctors.filter((d) => d.id.toString() !== getDoctorsIdFromRoute()), dso_doctors_loading: false });

            // If looking at your own page
            if (res.data.doctor[0].id.toString() === doctor_id) {
              that.props.setDoctorInformation(res.data.doctor[0].role, res.data.doctor[0].account_link_id);
            } else {
              //If looking at someone else's page
              Axios.get(`/apiV2/authorization/${doctor_id}`)
                .then(function (auth_res) {})
                .catch(function (err) {
                  handleHttpRequestError(err, that, true, res.data.doctor[0].role);
                });
              that.props.setDoctorInformation(doctor_res.data.doctor[0].role, doctor_res.data.doctor[0].account_link_id);
            }
            that.setState({
              section: <Content />,
            });
          })
          .catch(function (err) {});
      })
      .catch(function (err) {
        handleHttpRequestError(err, that, true);
      });

    Axios.get(`/apiV2/agreement`)
      .then(function (res) {
        that.setState({
          loading: false,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          terms: true,
        });
      });
  }

  componentDidUpdate() {}

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return null;
    } else if (this.state.terms) {
      return <CustomerAgreement />;
    } else if (this.state.dso_doctors.length === 0 && !this.state.dso_doctors_loading) {
      return <NoAccount />;
    } else {
      return this.state.section;
    }
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDoctorInformation: setDoctorInformation,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(DsoAccountList);
