import React from 'react';

/**
 * Displays a basic breadcrumb
 * @component
 */
function Breadcrumb({ bread, ...rest }) {
  // eslint-disable-next-line
  return <a className="case-breadcrumb" href={`#${bread}`} {...rest} />;
}

export default Breadcrumb;
