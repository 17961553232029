import React from 'react';

/**
 * Matches search string with array of Strings or Objects
 * @function
 * @param {String} search_value Search String
 * @param {Array} search_content Content to search
 * @param {String} property_value Optional property to query object
 * @returns {Array} Matched search content
 */
const performSearch = (search_value, search_content, property_value = '') => {
  let results = [];
  if (!search_value) {
    results = search_content;
  } else {
    for (const item of search_content) {
      // Array of Objects
      if (property_value) {
        if (item[property_value].toLowerCase().replace(/\s/g, '').includes(search_value.toLowerCase().replace(/\s/g, ''))) {
          results.push(item);
        }
      }
      // Array of Strings
      else {
        if (item.toLowerCase().replace(/\s/g, '').includes(search_value.toLowerCase().replace(/\s/g, ''))) {
          results.push(item);
        }
      }
    }
  }
  return results;
};

/**
 * Displays message when there are no results that match the search
 * @function
 * @returns {JSX} - JSX for no results
 */
const displayNoResults = (search_value) => {
  return (
    <div className="result-none">
      <div>
        Sorry, we couldn't find any settings in our system that match your search for "<span className="bold-text">{search_value}</span>".
      </div>
      <br />
      <div>
        Suggestions:
        <ul className="dash-list">
          <li>Check your spelling and try again</li>
          <li>Try different keywords</li>
          <li>Try different filters</li>
        </ul>
      </div>
    </div>
  );
};

export { performSearch, displayNoResults };
