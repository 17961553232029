import './scans.scss';

import React, { useContext } from 'react';

import { UserPermissionsContext } from '../../context/user_permission';

import { userHasPermission } from '../../common/permission';
import { onImageToolAction } from '../../common/functions';
import { isS3FileService } from '../../common/helpers';

import AlertDraftReadOnly from '../components/alerts/alert_draft_read_only';
import FileUpload from '../../components/file_upload/file_upload';
import FileUploadProgress from '../../components/file_upload/file_upload_progress';
import RecordViewer from '../components/record_viewer/record_viewer';
import SimpleFileUpload from '../../components/file_upload/simple_file_upload';

import IteroSelection from '../itero_files/itero_selection';
import IteroRecordViewer from '../itero_files/itero_record_viewer';
import UploadMethodSelector from '../itero_files/upload_method_selector';

const ManualUpload = ({
  disabled,
  disabledEdit,
  editDisabled,
  hide_warning,
  id,
  location,
  onEmptyFileError,
  onIncompleteSave,
  onLoadImageRecord,
  onRecordViewerAction,
  onRemove,
  onUpload,
  record_states,
  removeAllowed,
  scanUpload,
  show_warning,
  text,
  updateRecordState,
  upload_content,
  upload_state,
  withIteroSelection,
  uploadMethod,
  onUploadMethodChange,
  recordViewerSubtitle,
  isLoadingIteroPhotos = false,
}) => {
  const { permissions } = useContext(UserPermissionsContext);
  const canEdit = userHasPermission('IPP_EDIT', permissions);
  const isS3 = isS3FileService();

  return isS3 ? (
    <div className="fileUpload-container">
      <div>
        <SimpleFileUpload
          fileStatus={upload_state}
          setFileStatus={onUpload}
          onCompleteUpload={() => {}}
          multiple
          uploadParams={{ folder: 'photos', id: id }}
          type="image"
          disabled={editDisabled || !canEdit}
        />
        <div className="fileupload-progress-container scrollbar--dark">
          <FileUploadProgress file_statuses={scanUpload} onAbortUpload={() => {}} />
        </div>
      </div>
      <RecordViewer
        subtitle={this.props.recordViewerSubtitle}
        records={record_states}
        onLoad={onLoadImageRecord}
        onRemove={onRemove}
        removeAllowed={removeAllowed}
        disabled={disabled}
        onClick={(id, action) => {
          onImageToolAction(id, action, { record_states, onRecordViewerAction, updateRecordState });
        }}
      />
    </div>
  ) : (
    <FileUpload
      id={id}
      filesUploaded={scanUpload}
      onUpload={onUpload}
      onRemove={onRemove}
      onEmptyFileError={onEmptyFileError}
      onIncompleteSave={onIncompleteSave}
      multiple={true}
      location={location ? location : 'incomplete'}
      folder="scans"
      type="model"
      text={text}
      upload_state={upload_state}
      upload_content={upload_content}
      show_warning={show_warning}
      hide_warning={hide_warning}
      removeAllowed={true}
      disabled={disabledEdit || !canEdit}
      updateRecordState={updateRecordState}
      withIteroSelection={withIteroSelection}
      uploadMethod={uploadMethod}
      onUploadMethodChange={onUploadMethodChange}
      recordViewerSubtitle={recordViewerSubtitle}
      isLoadingIteroPhotos={isLoadingIteroPhotos}
    />
  );
};

const IteroScansSelection = ({
  disabledEdit,
  caseId,
  uploadMethod,
  onScansUploadMethodChange,
  recordViewerSubtitle,
  isLoadingIteroPhotos,
  iteroSelectionProps,
}) => {
  return (
    <div className="itero-files-scans-section">
      <div className="itero-files-scans-section__left">
        <UploadMethodSelector
          disabledEdit={disabledEdit || isLoadingIteroPhotos}
          uploadMethod={uploadMethod}
          onChange={onScansUploadMethodChange}
        />
        <IteroSelection caseId={caseId} disabledEdit={disabledEdit} isLoadingIteroPhotos={isLoadingIteroPhotos} {...iteroSelectionProps} />
      </div>
      <div className="itero-files-scans-section__right">
        <IteroRecordViewer subtitle={recordViewerSubtitle} />
      </div>
    </div>
  );
};

function ImpressionsOrScans({
  disabled,
  disabledEdit,
  editDisabled,
  isDso,
  hide_warning,
  id,
  location,
  onEmptyFileError,
  onIncompleteSave,
  onLoadImageRecord,
  onRecordViewerAction,
  onRemove,
  onScansUploadMethodChange,
  onUpload,
  record_states,
  removeAllowed,
  scanUpload,
  show_warning,
  text,
  updateRecordState,
  upload_content,
  upload_state,
  recordViewerSubtitle,
  iteroSelectionProps = {},
  uploadMethod = 'manual',
  isLoadingIteroPhotos = false,
}) {
  return (
    <div className="case-form-container scans-case-submission">
      <h3 id="scanLabel">Upload Toothprints</h3>
      {isDso && disabledEdit ? <AlertDraftReadOnly /> : null}
      {iteroSelectionProps.enableIteroSelection && uploadMethod === 'itero' ? (
        <IteroScansSelection
          caseId={id}
          disabledEdit={disabledEdit}
          uploadMethod={uploadMethod}
          onScansUploadMethodChange={onScansUploadMethodChange}
          iteroSelectionProps={iteroSelectionProps}
          recordViewerSubtitle={recordViewerSubtitle}
          isLoadingIteroPhotos={isLoadingIteroPhotos}
        />
      ) : (
        <ManualUpload
          disabled={disabled}
          disabledEdit={disabledEdit}
          editDisabled={editDisabled}
          hide_warning={hide_warning}
          id={id}
          location={location}
          onEmptyFileError={onEmptyFileError}
          onIncompleteSave={onIncompleteSave}
          onLoadImageRecord={onLoadImageRecord}
          onRecordViewerAction={onRecordViewerAction}
          onRemove={onRemove}
          onUpload={onUpload}
          record_states={record_states}
          removeAllowed={removeAllowed}
          scanUpload={scanUpload}
          show_warning={show_warning}
          text={text}
          updateRecordState={updateRecordState}
          upload_content={upload_content}
          upload_state={upload_state}
          uploadMethod={uploadMethod}
          onUploadMethodChange={onScansUploadMethodChange}
          withIteroSelection={iteroSelectionProps.enableIteroSelection}
          recordViewerSubtitle={recordViewerSubtitle}
          isLoadingIteroPhotos={isLoadingIteroPhotos}
        />
      )}
    </div>
  );
}

export default ImpressionsOrScans;
