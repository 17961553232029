import Axios from 'axios';

// Action Types
export const FETCH_DDM_STATE_PENDING = 'FETCH_DDM_STATE_PENDING';
export const FETCH_DDM_STATE_SUCCESS = 'FETCH_DDM_STATE_SUCCESS';
export const FETCH_DDM_STATE_ERROR = 'FETCH_DDM_STATE_ERROR';
export function fetchDdmState(case_id) {
  return (dispatch) => {
    if (case_id) {
      dispatch(fetchddmStatePending());

      Axios.get(`/apiV2/cadaction/${case_id}/ddm/get_ddm_state`)
        .then((res) => {
          const {
            ddm_status,
            ddm_status_comment,
            generation_status,
            generation_status_comment,
            upload_file,
            specs_file,
            specs_manual_file,
            design_file,
            design_manual_file,
            design_raw_file,
          } = { ...res.data };

          let screen = '';

          if (
            (!ddm_status && !generation_status) ||
            ddm_status === 'DDM Input File Uploaded' ||
            ddm_status === 'DDM Input File Removed' ||
            ddm_status === 'DDM Generation Reprocess'
          ) {
            screen = 'ddm-upload';
          } else if (generation_status === 'Generation In Progress') {
            screen = 'ddm-in-progress';
          } else {
            screen = 'ddm-result';
          }
          let specs_status = '',
            design_file_status = '',
            design_raw_status = '';

          if (generation_status_comment) {
            specs_status = generation_status_comment['ddm_specs_form'];
            design_file_status = generation_status_comment['ddm_design_file'];
            design_raw_status = generation_status_comment['ddm_raw_file'];
          }

          const ddm_state = {
            case_id: case_id,
            ddm_screen: screen,
            ddm_status: ddm_status,
            ddm_status_comment: ddm_status_comment,
            generation_status: generation_status,
            generation_status_comment: generation_status,
            specs_status: specs_status,
            design_file_status: design_file_status,
            design_raw_status: design_raw_status,
            error: null,
            upload_file: upload_file,
            specs_file: specs_file,
            specs_manual_file: specs_manual_file,
            design_file: design_file,
            design_manual_file: design_manual_file,
            design_raw_file: design_raw_file,
          };

          dispatch(fetchddmStateSuccess(ddm_state));

          return res;
        })
        .catch((error) => {
          dispatch(fetchddmStateError(error));
        });
    } else {
      dispatch(fetchddmStateError('No Case ID Provided'));
    }
  };
}
export function createInitialState(case_id) {
  return (dispatch) => {
    dispatch(fetchddmStatePending());
    Axios.post(`/apiV2/cadaction/${case_id}/ddm/main`).then((res) => {});
  };
}
export function fetchddmStatePending() {
  return {
    type: FETCH_DDM_STATE_PENDING,
  };
}

export function fetchddmStateSuccess(ddm_state) {
  return {
    type: FETCH_DDM_STATE_SUCCESS,
    ddm_state: ddm_state,
  };
}

export function fetchddmStateError(error) {
  return {
    type: FETCH_DDM_STATE_ERROR,
    error: error,
  };
}
