import React, { useState, useRef } from 'react';
import Dropdown from '../components/inputs/dropdown';
import CaseListDropdownItem from './case_list_dropdown_item';
import './case_list_dropdown.scss';

/**
 * Displays dropdown in case filters section
 * @component
 * @category IPP
 */
function DoctorCaseListDropdown(props) {
  const { onSelectionClick, selection, case_selection_counts } = props;
  const { doctor, dso_statuses, dso_doctors, dso_addresses } = case_selection_counts;

  const [isMenuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const role = doctor[0].role;
  const is_dso = role === 'DSO';
  const is_doc = role === 'Doctor';

  let options = is_dso ? [] : [buildCaseTypeOption('My Cases', '', dso_statuses.my_cases, undefined, is_dso, 'my_cases')];
  options.push(
    ...[
      buildCaseTypeOption('All Cases', is_dso ? '' : 'all_cases', dso_statuses.all_cases, undefined, false, 'all_cases'),
      buildCaseTypeOption(
        'By Doctor',
        'by_doctor',
        null,
        dso_doctors
          .filter((doctor) => {
            return doctor.user__is_active && doctor.id !== doctor.account_link_id;
          })
          .map((doctor) => {
            let id = doctor.id;
            return buildCaseTypeOption(doctor.user__first_name + ' ' + doctor.user__last_name, `doctor_${id}`, doctor.cases_count, undefined, false, id);
          }),
        false,
        '',
        'No doctors found'
      ),
      buildCaseTypeOption(
        'By Location',
        'by_location',
        null,
        dso_addresses.map((address) => {
          let id = address.id;
          return buildCaseTypeOption(address.address_name, `location_${id}`, address.cases_count, undefined, false, id);
        }),
        false
      ),
    ]
  );

  /**
   * Builds the option dictionary with given values
   * @function
   * @param {dict} options - selected option
   */
  function buildCaseTypeOption(text, value, count, submenu, hide, id = '', alt = '') {
    const option = {
      id: id,
      label: `${text}${count === null ? '' : ` (${count})`}`,
      value: value,
      submenu: submenu,
      hide: hide,
      onClick: onSelectionClick,
      alt: alt,
    };
    return option;
  }

  /**
   * Recursively finds the selected option from the options list
   * @function
   * @param {List} options - list of options
   * @param {Integer} index - index of next option
   * @returns {dict} selected option
   */
  function getValue(options, index = 0) {
    if (index === options.length) return null;

    let current_option = options[index];

    if (current_option.value === selection) return current_option;
    if (current_option.submenu) return getValue(current_option.submenu) || getValue(options, index + 1);
    return getValue(options, index + 1);
  }

  function onOptionClick(option) {
    setMenuOpen(false);
    onSelectionClick(option);
    dropdownRef.current.blur();
  }

  return (
    !is_doc && (
      <div className="filter-menu">
        <Dropdown
          options={options}
          onChange={onOptionClick}
          value={getValue(options)}
          isSearchable={false}
          components={{ Option: CaseListDropdownItem }}
          menuIsOpen={isMenuOpen}
          onFocus={() => setMenuOpen(true)}
          onBlur={(e) => {
            setTimeout(() => {
              setMenuOpen(false);
            }, 200);
          }}
          dropdownRef={dropdownRef}
        />
      </div>
    )
  );
}

export default DoctorCaseListDropdown;
