/**
 * File: environment_banner.js - display a message for user when they are on a
 * test environment, e.i. dev1, testing, etc..
 * Copyright: (c) Copyright August 2020 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import { getEnvName, firstLetterCapital } from '../../common/functions';
/**
 * A banner that shows up when you're in the heroku eco system
 * @component
 * @alias EnvironmentBanner
 * @category Common
 */
class EnvironmentBanner extends Component {
  render() {
    const env_name = getEnvName();

    return env_name ? (
      <div className="browser-warning">
        <div className="right-overlay-message">
          <div className="environment-banner">{`${firstLetterCapital(env_name)} Environment`}</div>
        </div>
      </div>
    ) : null;
  }
}

export default EnvironmentBanner;
