import React, { useState } from 'react';

/**
 * Contains the approval dialog for the setup viewer
 * @component
 * @alias SetupViewerApprovalConfirmation
 */
export default function SmileReminder() {
  const [show, setShow] = useState(true);
  return (
    <div
      className="reminder-modal"
      style={{
        width: show ? '' : '0px',
        padding: show ? '' : '0',
        opacity: show ? '' : '0',
      }}
    >
      <i className="fa fa-times" aria-label="Close" onClick={() => setShow(false)} />
      <div className="modal-body-paragraph">
        This Smile Simulation does not replace the InBrace <br />
        Smile Design
      </div>
    </div>
  );
}
