import React, { Component } from 'react';
import { translateFilterStatus } from '../../common/helpers';
import CardContainer from '../components/container/card_container';

class NoResult extends Component {
  render() {
    return (
      <CardContainer type="top-border" className="hide-on-mobile--top-border pad-md">
        <div className="no-matches-container">
          Your search - <span className="emphasis">{this.props.query}</span> -{' '}
          {this.props.filter ? (
            <span>
              with <span className="emphasis">{translateFilterStatus(this.props.filter)}</span> as a filter{' '}
            </span>
          ) : null}
          did not match any{' '}
          {this.props.type && this.props.type === 'item_request'
            ? 'item request'
            : this.props.type && this.props.type === 'accounts'
            ? 'accounts'
            : this.props.type && this.props.type === 'prospect_list'
            ? 'prospects'
            : this.props.type && this.props.type === 'smiles'
            ? 'smiles'
            : 'cases'}{' '}
          in our system.
          <div className="no-matches-info">
            <span className="no-matches-heading">Suggestions:</span>
            <ul>
              <li>Make sure all words are spelled correctly.</li>
              <li>Try different keywords.</li>
              {this.props.filter ? <li>Try different filter.</li> : null}
              <li>Try more general keywords.</li>
              <li>Try fewer keywords.</li>
            </ul>
          </div>
        </div>
      </CardContainer>
    );
  }
}

export default NoResult;
