import Axios from 'axios';
import { WindowUtils } from './utils/window-utils';

/**
 * Opens InBrace Storefront link
 *
 * @function
 * @param {object} doctor - Doctor information
 * @param {function} setShopifyId - State function callback
 */
const openStorefrontLink = (doctor, setShopifyIdCallback) => {
  if (!doctor.shopify_id) {
    WindowUtils.openInNewWindowWithPromise(createNewShopifyCustomer, doctor, setShopifyIdCallback);
  } else {
    WindowUtils.openInNewWindowWithPromise(multipassLogin, doctor);
  }
};

/**
 * Adds unsynced addresses to shopify account
 * @param {object} doctor - Doctor Information
 */
const syncAddresses = (doctor) => {
  Axios.post(`/apiv3/shopify/syncaddresses/${doctor.doctor_id}`, {
    account_link_id: doctor.account_link_id,
    shopify_id: doctor.shopify_id,
  })
    .then((res) => {})
    .catch((e) => {
      console.log('error: ', e);
    });
};

/**
 * SSO Login API Call
 *
 * @function
 * @param {object} doctor - Doctor information
 */
const multipassLogin = (doctor) => {
  return new Promise((resolve, reject) => {
    syncAddresses(doctor);
    Axios.post(`/apiv3/shopify/multipass`, {
      email: doctor.email,
      first_name: doctor.first_name,
      last_name: doctor.last_name,
      account_link_id: doctor.account_link_id,
      shopify_id: doctor.shopify_id,
    })
      .then((res) => {
        resolve(res.data.url);
      })
      .catch((e) => {
        resolve(`/portal/${doctor.doctor_id}/storefront_unavailable`);
      });
  });
};

/**
 * Create a new Shopify Customer
 *
 * @function
 * @param {object} doctor - Doctor information
 */
const createNewShopifyCustomer = (doctor, setShopifyIdCallback) => {
  return new Promise(async (resolve, reject) => {
    try {
      const endpoint = `/apiv3/shopify/createcustomer/${doctor.doctor_id}`;
      const payload = {
        email: doctor.email,
        first_name: doctor.first_name,
        last_name: doctor.last_name,
        account_link_id: doctor.account_link_id,
        doctor_role: doctor.role,
      };
      const response = await Axios.post(endpoint, payload);
      setShopifyIdCallback(response.data.shopify_id);
      const url = await multipassLogin({
        ...doctor,
        shopify_id: res.data.shopify_id,
      });
      resolve(url);
    } catch (e) {
      console.error(e);
      resolve(`/portal/${doctor.doctor_id}/storefront_unavailable`, '_blank');
    }
  });
};

export { openStorefrontLink, multipassLogin };
