import React from 'react';
import { connect } from 'react-redux';
import { Box, Container, Typography } from '@material-ui/core';
import './manual_wire_error.scss';

const ManualWireError = ({ errorMessage }) => {
  return (
    <Container className="manual-wire-error">
      <Box className="error-log">
        <Typography variant="h6">Error Log</Typography>
      </Box>
      <Box className="error-message">
        {errorMessage.map((message, i) => (
          <p key={`error-${i}`}>{message}</p>
        ))}
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const errorMessage = state.manualWireReducer.errorMessage;

  return {
    errorMessage,
  };
};

export default connect(mapStateToProps)(ManualWireError);
