import { Collapse, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps the children for collapse
 * @component
 */
function CollapsibleTriangleContainer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => setHeight(ref.current.clientHeight), []);
  const matches = useMediaQuery('(max-width: 991px)');

  const className = props.containerClassName ? props.containerClassName : '';
  const triangleClassName = isOpen ? 'triangle-toggle' : 'triangle-toggle triangle-toggle--down';
  const open = matches || height <= props.collapsedHeight;
  const icon = <i className={isOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'} />;

  return (
    <div className={`${className} collapsible-triangle-container`}>
      <Collapse collapsedSize={props.collapsedHeight} in={isOpen || open} ref={ref}>
        {props.children}
      </Collapse>
      {!open && (
        <div aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)} className={triangleClassName}>
          {icon}
        </div>
      )}
    </div>
  );
}

CollapsibleTriangleContainer.propTypes = {
  collapsedHeight: PropTypes.number,
};

CollapsibleTriangleContainer.defaultProps = {
  collapsedHeight: 0,
};

export default CollapsibleTriangleContainer;
