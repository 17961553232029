import React from 'react';
import PropTypes from 'prop-types';

function isActive(props) {
  if (props.names) {
    return props.names.some((n) => n === props.view);
  } else {
    return props.name === props.view;
  }
}

/**
 * Displays a single view selection button.
 *
 * @component
 */
function SelectionViewControl(props) {
  const btns = isActive(props) ? 'btn btn-setup active' : 'btn btn-setup';

  return (
    <button type="button" onClick={() => props.onChangeView(props.name)} className={btns}>
      {props.children}
    </button>
  );
}

SelectionViewControl.propTypes = {
  name: PropTypes.string.isRequired,
  names: PropTypes.array,
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
};

export default SelectionViewControl;
