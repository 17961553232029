// Css
import './shipping_list.scss';
import '../../components/loader/loader.scss';

// External Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import ShippingContent from './shipping_content';

// Redux Reducers
import { getFetchShippingListError, getFetchShippingListCompleted } from '../../redux/reducers/bpp/shipping/shipping';

// Redux Actions
import { fetchAllCasesReadyForShipping, fetchCompletedBatches } from '../../redux/actions/bpp/shipping/shipping';

class ShippingList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: false,
    };

    this.setRefreshToFalse = this.setRefreshToFalse.bind(this);
  }
  componentDidMount() {
    this.props.fetchAllCasesReadyForShipping();
    this.props.fetchCompletedBatches();
  }

  componentDidUpdate() {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.refresh &&
      !this.state.refresh
    ) {
      this.setState({
        refresh: true,
      });
    }
  }

  /**
   * Sets state refresh back to false (used for routing purposes)
   * @function
   */
  setRefreshToFalse() {
    this.setState({
      refresh: false,
    });
  }

  render() {
    if (this.props.fetch_shipping_list_error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (!this.props.fetch_shipping_list_completed) {
      return <Loader />;
    } else {
      return <ShippingContent shipping_list_refresh={this.state.refresh} setRefreshToFalse={this.setRefreshToFalse} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    fetch_shipping_list_error: getFetchShippingListError(state),
    fetch_shipping_list_completed: getFetchShippingListCompleted(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllCasesReadyForShipping: fetchAllCasesReadyForShipping,
      fetchCompletedBatches: fetchCompletedBatches,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShippingList));
