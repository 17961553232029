import { wasmControllerGetRepresentation } from '../wasm_controller';
import { PROCESSOR } from '../wasm_controller_const';

export const EErrorCode = {
  CompassMoveX: 1,
  CompassMoveY: 2,
  CompassMoveZ: 3,
  CompassRotX: 4,
  CompassRotY: 5,
  CompassRotZ: 6,
  CompassMoveXArchBasedForward: 7,
  CompassMoveXArchBasedBackward: 8,
  MovementTable: 9,
  MovementTableIncrementInclinationPlus: 10,
  MovementTableIncrementAngulationPlus: 11,
  MovementTableIncrementRotationPlus: 12,
  MovementTableIncrementMesialPlus: 13,
  MovementTableIncrementVestibularPlus: 14,
  MovementTableIncrementOcclusalPlus: 15,
  MovementTableIncrementInclinationMinus: 16,
  MovementTableIncrementAngulationMinus: 17,
  MovementTableIncrementRotationMinus: 18,
  MovementTableIncrementMesialMinus: 19,
  MovementTableIncrementVestibularMinus: 20,
  MovementTableIncrementOcclusalMinus: 21,
  SetIPR: 22,
  SetSpace: 23,
  ResetIP: 24,
  HingeRotationX: 25,
  HingeRotationZ: 26,
  MoveByArchForward: 27,
  MoveByArchBackward: 28,
  Redistribute: 29,
  FromArchToArch: 30,
  Copy: 31,
  Count: 32,
};

export class ErrorHandlerProcessor {
  /**
   *
   * @return {{
   *    message: String,
   *    errors: {
   *      code: EErrorCode,
   *      message: String,
   *    }[],
   *  } | null}
   */
  static getState() {
    const state = wasmControllerGetRepresentation(PROCESSOR.ErrorHandler);
    if (state.status === 400) {
      return {
        message: state.message,
        errors: state.errors,
      };
    }

    return null;
  }
}
