import { COUNTRIES } from '../../common/constants/countries';
import { parsePhoneNumberFromString, isValidPhoneNumber, formatPhoneNumber as libFormatPhoneNumber } from 'libphonenumber-js';
import { phoneCodes } from './country_codes';

export const getCountryCode = (country) => Object.keys(COUNTRIES).find((key) => COUNTRIES[key] === country);

export const getPhoneCode = (country) => {
  const info = phoneCodes.find((item) => item.name === country);
  return info ? info.dial_code : '';
};

const formatPhoneNumberCustom = (phoneNumber, country) => {
  try {
    const countryCode = getCountryCode(country);
    if (!countryCode) return '';

    const phoneObj = parsePhoneNumberFromString(phoneNumber, countryCode);
    if (!phoneObj || !phoneObj.isValid()) return '';

    return countryCode === 'US' ? phoneObj.formatNational() : phoneObj.formatInternational();
  } catch (error) {
    console.error('Error formatting the phone number:', error);
    return '';
  }
};

const isPhoneNumberValid = (phoneNumber, country) => {
  const countryCode = getCountryCode(country);
  if (!countryCode || !phoneNumber) return false;

  const phoneObj = parsePhoneNumberFromString(phoneNumber, countryCode);
  if (!phoneObj) return false;
  return isValidPhoneNumber(phoneObj.number, phoneObj.country);
};

const parsePhoneNumberCustom = (phoneNumber, country) => {
  const countryCode = getCountryCode(country);
  return countryCode ? parsePhoneNumberFromString(phoneNumber, countryCode) : null;
};

export { formatPhoneNumberCustom as formatPhoneNumber, isPhoneNumberValid, parsePhoneNumberCustom as parsePhoneNumber };
