import React from 'react';
import Button from '../components/buttons/button';
import { useEffect } from 'react';

function FileSubmitted(props) {
  useEffect(() => {
    if (props.refreshInfo) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.refreshInfo]);
  return (
    <div className="submission-loader-container">
      <p>Your files have been successfully submitted.</p>
      <Button onClick={props.onButtonClick}>Go to Case Details</Button>
    </div>
  );
}

export default FileSubmitted;
