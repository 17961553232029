import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CreateEditProspect from './create_edit_prospect';
/**
 * Contains the ability to add a prospect
 * @component
 * @alias DoctorProspectAdd
 * @category IPP
 */
class ProspectAdd extends Component {
  render() {
    return <CreateEditProspect add_mode={true} />;
  }
}

export default withRouter(ProspectAdd);
