import {
  buildRecordStates,
  getNewImageRecords,
  getNewRecordState,
  syncRecordStateDebounce,
} from '../../../doctor/components/record_viewer/record_viewer_helpers';
import {
  BUILD_RECORD_VIEWER_STATE,
  UPDATE_RECORD_VIEWER_SRC,
  UPDATE_RECORD_VIEWER_STATE,
  UPDATE_DELETE_IN_PROCESS,
} from '../../actions/record_viewer/record_viewer';

const initialState = {
  record_states: [],
  case_type: 'incomplete',
  sync: true,
};

export function recordViewerReducer(state = initialState, action) {
  switch (action.type) {
    case BUILD_RECORD_VIEWER_STATE:
      return buildRecordViewerState(state, action);

    case UPDATE_RECORD_VIEWER_SRC:
      return getUpdatedRecordSrc(state, action);

    case UPDATE_RECORD_VIEWER_STATE:
      return getUpdatedRecordState(state, action);

    case UPDATE_DELETE_IN_PROCESS:
      return updateDeleteInProcess(state, action);

    default:
      return state;
  }
}

function buildRecordViewerState(state, action) {
  let newState = buildRecordStates(action.uploads, action.teethViewer, action.hide_occlusion);
  if (state.record_states.length > 0) {
    newState = newState.map((record) => {
      const r = findRecordState(state.record_states, record);
      return r ? r : record;
    });
  }
  return { record_states: newState, case_type: action.caseType, sync: action.sync };
}

function needReload(oldRecord, newRecord) {
  return oldRecord.src === '/static/img/no-image.png' || oldRecord.id !== newRecord.id;
}

function isSameRecord (oldRecord, newRecord) {
  return newRecord.tooltip === oldRecord.tooltip && newRecord.url === oldRecord.url;
}

function isSameOcclusionInnerRecord (oldRecord, newRecord) {
  if (!oldRecord || !newRecord) {
    return false;
  }
  return newRecord.id === oldRecord.id && newRecord.file_url === oldRecord.file_url;
}

function occlusionNeedReload(oldOcclusion, newOcclusion) {
  return !isSameOcclusionInnerRecord(oldOcclusion['0'], newOcclusion['0']) || !isSameOcclusionInnerRecord(oldOcclusion['1'], newOcclusion['1']);
}

function isOcclusionViewRecord (record) {
  return record.id === 'itero-occlussion-view' || record.tooltip === 'Occlusion View';
}

function findRecordState(oldStates, record) {
  for (const r of oldStates) {
    if (isOcclusionViewRecord(record)) {
      if (isOcclusionViewRecord(r)) {
        return occlusionNeedReload(r, record) ? null : r;
      }
    } else {
      if (isSameRecord(r, record)) {
        return needReload(r, record) ? null : r;
      }
    }

  }
  return null;
}

function getUpdatedRecordSrc(state, action) {
  return { ...state, record_states: getNewImageRecords(state.record_states, action.id, action.src) };
}

function getUpdatedRecordState(state, action) {
  let new_state = null;
  const new_record_states = state.record_states.map((record) => {
    if (record.id === action.id) {
      new_state = getNewRecordState(action.action, record.state);
      return { ...record, state: new_state };
    }
    return record;
  });
  if (state.sync) syncRecordStateDebounce(action.id, new_state, state.case_type);
  return { ...state, record_states: new_record_states };
}

function updateDeleteInProcess(state, action) {
  const new_record_states = state.record_states.map((file) => {
    // eslint-disable-next-line
    if (file.id == action.id || file.incomplete_case_file_id == action.id) {
      file.hideDeleteIcon = true;
      return file;
    }
    return file;
  });
  return { record_states: new_record_states, case_type: action.caseType, sync: action.sync };
}

export const getRecordStates = (state) => state.recordViewerReducer.record_states;
