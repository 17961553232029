import React from 'react';

function getStatusClassName(status) {
  const classnames = ['wasm-sidebar-status'];
  if (status === 'Review Smile Design') {
    classnames.push('wasm-sidebar-status--review');
  } else if (status === 'Revision Request Submitted') {
    classnames.push('wasm-sidebar-status--revised');
  } else if (status === 'Editing in Progress') {
    classnames.push('wasm-sidebar-status--edit');
  }
  return classnames.join(' ');
}

function WasmSidebarStatus(props) {
  const { status, is_revising } = props;
  const status_text = is_revising ? 'Editing in Progress' : status;
  return <div className={getStatusClassName(status)}>{status_text}</div>;
}

export default WasmSidebarStatus;
