import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays the selection bar page indicator
 * @component
 */
function SelectionBarPageIndicator(props) {
  const num_of_dots = Math.ceil(props.scroll_options.length / props.scroll_items);
  const exist = 1 < num_of_dots;

  let dots = [];

  for (let i = 0; i < num_of_dots; i++) {
    dots.push({
      id: i,
      active: i === props.scroll_area,
    });
  }

  return (
    <div className={!exist ? 'invisible' : props.split ? 'selection-bar-top-page-indicator fade-out' : 'selection-bar-top-page-indicator fade-in'}>
      {dots.map((dot, index) => {
        return <i key={index} className={dot.active ? 'fa fa-circle active' : 'fa fa-circle'} aria-hidden="true" onClick={props.onClick} data-value={dot.id} />;
      })}
    </div>
  );
}

SelectionBarPageIndicator.propTypes = {
  onClick: PropTypes.func.isRequired,
  scroll_area: PropTypes.number.isRequired,
  scroll_items: PropTypes.number.isRequired,
  scroll_options: PropTypes.array.isRequired,
  split: PropTypes.bool.isRequired,
};

export default SelectionBarPageIndicator;
