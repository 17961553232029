import React, { Component } from 'react';
import Privacy from '../../components/privacy/external_privacy';
import './password_recovery.scss';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getJWTToken, removeJWTToken } from '../../common/token';
import { resetTokenHeader } from '../../common/functions';

import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import TextBox from '../components/inputs/text_box';
import { LogoWhite } from '../assets/logos';
class Recovery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      showMessage: false,
      errorMessage: 'Invalid email address',
      loading: true,
      warning: false,
      on_button_click: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    let that = this;
    let token = getJWTToken();

    //Push history
    if (token) {
      Axios.get('/apiv3/doctor')
        .then(function (response) {
          if (response && response.data && response.data.doctor && response.data.doctor.length > 0) {
            let doctorId = response.data.doctor[0].id;
            if (doctorId) {
              that.props.history.push(`/portal/${doctorId}`);
            }
          }
        })
        .catch(function (err) {
          console.log(err);
          that.setState({
            loading: false,
          });
        });
    } else {
      that.setState({
        loading: false,
      });
    }
  }

  handleChange(event) {
    this.setState({ email: event.target.value, warning: false, on_button_click: false });
  }

  onInputClick(event) {
    this.setState({
      warning: false,
    });
  }

  validate(email) {
    // eslint-disable-next-line
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    return expression.test(String(email).toLowerCase());
  }

  handleSubmit(event) {
    event.preventDefault();
    const that = this;
    const email = this.state.email.trim();

    this.setState({
      on_button_click: true,
    });

    if (this.validate(email)) {
      removeJWTToken();
      resetTokenHeader();

      Axios.get(`/api/password/email/?email=${email}&provider=${window.location.origin}`).then(function (result) {
        if (result && result.data && result.data.message === 'success') {
          that.setState({
            email: '',
            showMessage: true,
            warning: false,
          });
        } else {
          that.setState({
            email: email,
            errorMessage: (
              <span>
                This account email <span className="bold-text">{that.state.email}</span> does not match an account in our portal. Please try a different email
                address, or contact InBrace Concierge on (877) 258-8677 or email{' '}
                <a href="mailto:support@inbrace.com?Subject=My%20Inbrace%20Account" target="_top">
                  support@inbrace.com
                </a>
                .
              </span>
            ),
            showMessage: false,
            warning: true,
          });
        }
      });
    } else {
      that.setState({
        email: email,
        errorMessage: 'Invalid email address',
        showMessage: false,
        warning: true,
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <div />;
    } else {
      return (
        <div className="ipp-light background">
          <Helmet>
            <title>Password Recovery | InBrace Smile Design Studio™</title>
            <meta
              name="description"
              content="Forgot your password? Visit the Password Recovery page and enter your account email to receive a password reset link."
            />
          </Helmet>
          <div className="login-window text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="">
                <LogoWhite width="280" />
                <h3 className="heading--light">Password Recovery</h3>
                {this.state.showMessage ? (
                  <p className="heading--light">
                    A password reset email has been sent to your account email address. Please follow the link in the email to reset your password.
                  </p>
                ) : (
                  <div>
                    <p className="heading--light">
                      Enter your account email below and click Submit. We will send you an email containing instructions for resetting your password.
                    </p>
                    <label className="content--left heading--light" htmlFor="email">
                      Account Email
                    </label>
                    <TextBox
                      id="password-recovery-email"
                      type="text"
                      name="email"
                      placeholder="Account Email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      onClick={this.onInputClick}
                      autoFocus={true}
                    />
                    <Button type="submit" theme="dark" onClick={this.handleSubmit} disabled={this.state.on_button_click}>
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            </form>
            {this.state.warning ? (
              <p className="heading--warning">
                <CardContainer type="error-message">{this.state.errorMessage}</CardContainer>
              </p>
            ) : null}
          </div>
          <Privacy className="footer-display footer-display-100 heading--light" />
        </div>
      );
    }
  }
}

export default withRouter(Recovery);
