/**
 * File:button_group.js - Component to return group of buttons for teeths.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Displays the group of buttons in teeth selector
 * @component
 */
class BtnGroup extends Component {
  /**
   * Returns whether the button should be disabled or not
   * @function
   * @param {String} name - The button's name
   * @return {Boolean} Whether disable or not
   */
  isDisabled(name) {
    const isSet = this.props.disabled instanceof Set;
    const disabled_list = isSet ? [...this.props.disabled] : this.props.disabled;

    return !this.props.enabled || _.intersection([name], disabled_list).length > 0;
  }

  /**
   * Returns the classes of displaying the button
   * @function
   * @param {String} name - The button's name
   * @return {String} The classes
   */
  getClassName(name) {
    const classes = ['btn-ib'];
    if (this.isDisabled(name)) classes.push('btn-ib--disabled');
    if (this.props.selected.has(name)) classes.push('btn-ib--active');

    return classes.join(' ');
  }

  /**
   * Handles the onClick event on each button on the group
   * @function
   * @param {Obect} evt - The click event
   */
  onClick = (evt) => {
    let name = evt.target.innerText;
    let sel = this.props.selected.has(name);
    if (this.props.onClick && !this.isDisabled(name)) {
      this.props.onClick(name, !sel);
    }
  };

  render() {
    return this.props.buttons.map((item) => (
      <button type="button" className={this.getClassName(item)} onClick={this.onClick} key={item} data-testid={`${this.props.dataTestIdPrefix}_${item}`}>
        {item}
      </button>
    ));
  }
}

BtnGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  disabled: PropTypes.instanceOf(Set).isRequired,
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.instanceOf(Set).isRequired,
};

BtnGroup.defaultProps = {
  enabled: true,
};

export default BtnGroup;
