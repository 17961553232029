import { FETCH_CASE_FILTER_COUNT_ERROR, FETCH_CASE_FILTER_COUNT_START, FETCH_CASE_FILTER_COUNT_SUCCESS } from '../../../actions/bpp/case_list/case_list_filter';

const initialState = {
  filter_count: {},
  loading: false,
  error: null,
};

export function caseListFilterCountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_FILTER_COUNT_START:
      return { ...state, loading: true };

    case FETCH_CASE_FILTER_COUNT_SUCCESS:
      return {
        ...state,
        filter_count: {
          ...state.filter_count,
          [action.case_type]: action.case_filter_count,
        },
        loading: false,
      };

    case FETCH_CASE_FILTER_COUNT_ERROR:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}

export const getCaseListFilterCount = (state) => state.caseListFilterCountReducer.filter_count;
export const getCaseListFilterCountLoading = (state) => state.caseListFilterCountReducer.loading;
