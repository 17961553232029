import Axios from 'axios';
import _ from 'lodash';

import { updateCaseListData } from './case_list/case_list_table';

// Action Types
export const SET_DOCTOR_INFORMATION = 'SET_DOCTOR_INFORMATION';
export const SET_ADDITIONAL_ACTIONS_MODAL = 'SET_ADDITIONAL_ACTIONS_MODAL';
export const SET_NOTE_MODAL = 'SET_NOTE_MODAL';
export const SET_DELETE_NOTE_MODAL = 'SET_DELETE_NOTE_MODAL';
export const SET_VIEW_PLAN_MODAL = 'SET_VIEW_PLAN_MODAL';
export const SET_VIEW_IFU_MODAL = 'SET_VIEW_IFU_MODAL';
export const SET_SELECTED_LIST_ITEM_ID = 'SET_SELECTED_LIST_ITEM_ID';

// Action Creators
// -----------------------------------------------------------------------------

export function setDoctorInformation(doctor_role, account_link_id) {
  return {
    type: SET_DOCTOR_INFORMATION,
    doctor_role: doctor_role,
    account_link_id: account_link_id,
  };
}

export function setAdditionalActionsModal(open) {
  return {
    type: SET_ADDITIONAL_ACTIONS_MODAL,
    open: open,
  };
}

export function setSelectedListItemId(id) {
  return {
    type: SET_SELECTED_LIST_ITEM_ID,
    selected_list_item_id: id,
  };
}

export function setNoteModal(open) {
  return {
    type: SET_NOTE_MODAL,
    open: open,
  };
}

export function setDeleteNoteModal(open) {
  return {
    type: SET_DELETE_NOTE_MODAL,
    open: open,
  };
}

export function setViewPlanModal(open) {
  return (dispatch) => {
    if (!open) dispatch(setSelectedListItemId(''));
    dispatch({
      type: SET_VIEW_PLAN_MODAL,
      open: open,
    });
  };
}

export function setViewIfuModal(open, view_ifu_modal_type = '') {
  return (dispatch) => {
    if (!open) dispatch(setSelectedListItemId(''));
    dispatch({
      type: SET_VIEW_IFU_MODAL,
      open: open,
      view_ifu_modal_type: view_ifu_modal_type,
    });
  };
}

/**
 * Create/Edit patient note API
 * @function
 * @param {string} id - Case id
 * @param {object} note - Updated note
 * @param {function} refreshList - Refresh state list function
 * @param {function} is_draft - Draft indicator
 * @returns {object} - Action object
 */
export function updateNote(id, note, refreshList, is_draft) {
  const is_case_draft = !id.includes('-DE') ? is_draft : false;
  return (dispatch) => {
    const data = {
      id: note.id,
      subject: note.subject,
      note_text: note.text,
      is_case_draft: is_case_draft,
    };
    if (is_case_draft) {
      data.incomplete_case_id = id;
    } else {
      data.case_id = id;
    }

    Axios.put(`/apiv3/patientnotes`, data)
      .then((res) => {
        dispatch(setNoteModal(false));
        const patient_notes = res.data.patient_notes;
        if (patient_notes.length > 0) {
          if (refreshList) {
            refreshList(id, patient_notes[patient_notes.length - 1]);
          } else {
            dispatch(updateCaseWithNote(id, patient_notes[patient_notes.length - 1]));
          }
        }
      })
      .catch((err) => {
        dispatch(setNoteModal(false));
      });
  };
}

/**
 * Deletes note from draft case submission
 * @function
 * @param {string} id - Draft id
 * @param {string} note_id - Note id to delete
 * @returns {object} - Action object
 */
export function deleteNote(id, note_id) {
  const data = { data: { id: id, is_draft: true } };
  return (dispatch) => {
    Axios.delete(`/apiv3/patientnotes/${note_id}`, data)
      .then((res) => {
        dispatch(setDeleteNoteModal(false));
        const empty_note = { id: '', subject: '', text: '', date: '', created_by_id: '' };
        dispatch(updateCaseWithNote(id, empty_note));
      })
      .catch(function (err) {
        dispatch(setNoteModal(false));
      });
  };
}

/**
 * Updates case in case list with new note
 * @function
 * @param {string} id - Case or draft id
 * @param {object} note - Updated note
 * @returns {object} - Action object
 */
function updateCaseWithNote(id, note) {
  return (dispatch, getState) => {
    const { case_list_data } = getState().doctorCaseListTableReducer;
    const case_updates = _.cloneDeep(case_list_data);

    for (const key of Object.keys(case_updates)) {
      for (const case_array of Object.keys(case_updates[key])) {
        if (Array.isArray(case_updates[key][case_array])) {
          let cases = case_updates[key][case_array].find((c) => (c.draft_case_id ? c.draft_case_id.toString() === id.toString() : c.case_id === id));
          if (cases) {
            const new_note = {
              date: note.modified_date,
              id: note.id,
              created_by_id: note.created_by_id,
            };
            cases.patient_note_info = JSON.stringify(new_note);
            cases.patient_note_subject = note.subject;
            cases.patient_note_text = note.note_text;
            break;
          }
        }
      }
    }
    dispatch(updateCaseListData(case_updates));
  };
}
