import React from 'react';

/**
 * The sidebar area that displays the patient name and history
 * @component
 * @alias SetupViewerPatientInfoHeading
 */
export default function PatientInfoHeading(props) {
  return (
    <div className={props.collapse ? 'patient-info-heading invisible' : 'patient-info-heading'}>
      <div className={props.extend ? "patient-info-heading-title patient-info-heading-title-extend" : "patient-info-heading-title"}>{props.title}:</div>
      <span className={props.exclude ? "fs-exclude" : ""}>{props.content}</span>
    </div>
  );
}
