import React, { Component } from 'react';
import { getRootPath } from '../../common/functions';
import { withRouter } from 'react-router-dom';

import Badge from '../components/badge';
import FilterContainer from '../components/container/filter_container';
import FilterItem from '../components/filter_item';
import { getStatusFilterValueFromRoute } from '../../common/route';

/**
 * This component is used to show the user the prospect list filter element on the prospect list
 * @component
 * @alias DoctorProspectFilter
 * @category IPP
 */
class ProspectFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
    };
  }
  componentDidMount() {
    this.updateStatusByURL();
  }
  componentDidUpdate() {
    const status = getStatusFilterValueFromRoute();

    if (this.state.status !== status) {
      this.updateStatusByURL();
    }
  }
  updateStatusByURL = () => {
    const status = getStatusFilterValueFromRoute();
    this.setState({ status });
  };
  /**
   * Update browser route to the correct the url
   *
   * @function
   * @param {String} name - the name of the filter value
   */
  appendStatusToRoute = (name) => {
    let rootpath = getRootPath(this.props.history.location.pathname);
    const ir_route = `?filter=prospect_list`;
    this.props.history.replace(`${rootpath}${ir_route}` + (name !== '' ? `&status_filter=${name}` : ''));
  };
  /**
   * Update to the status in route and state
   *
   * @function
   * @param {Object} event - The event on the change of the status
   */
  onStatusClick = (event) => {
    if (event && event.currentTarget && event.currentTarget.dataset && (event.currentTarget.dataset.status || event.currentTarget.dataset.status === '')) {
      this.appendStatusToRoute(event.currentTarget.dataset.status);
      this.setState({
        status: event.currentTarget.dataset.status,
      });
    }
  };
  render() {
    return (
      <div className="statuses">
        {this.props.search ? this.props.search : null}
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === ''} data-status="" onClick={this.onStatusClick}>
            My Prospects
            <Badge>{this.props.statuses.all}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem type="subfilter" active={this.state.status === 'action'} data-status="action" onClick={this.onStatusClick}>
            Action Required
            <Badge theme="primary">{this.props.statuses.action}</Badge>
          </FilterItem>
          <FilterItem active={this.state.status === 'schedule'} data-status="schedule" onClick={this.onStatusClick}>
            Schedule Consultation
            <Badge theme="secondary">{this.props.statuses.schedule}</Badge>
          </FilterItem>
          <FilterItem active={this.state.status === 'consult'} data-status="consult" onClick={this.onStatusClick}>
            Complete Consultation
            <Badge theme="secondary">{this.props.statuses.consult}</Badge>
          </FilterItem>
          <FilterItem active={this.state.status === 'begin_submission'} data-status="begin_submission" onClick={this.onStatusClick}>
            Begin Submission
            <Badge theme="secondary">{this.props.statuses.begin_submission}</Badge>
          </FilterItem>
          {/* <FilterItem active={this.state.status === 'draft'} data-status="draft" onClick={this.onStatusClick}>
            Resume Draft
            <Badge theme="secondary">{this.props.statuses.draft}</Badge>
          </FilterItem> */}
          <FilterItem active={this.state.status === 'hold'} data-status="hold" onClick={this.onStatusClick}>
            On Hold
            <Badge theme="secondary">{this.props.statuses.hold}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'submitted'} data-status="submitted" onClick={this.onStatusClick}>
            Submitted
            <Badge>{this.props.statuses.submitted}</Badge>
          </FilterItem>
        </FilterContainer>
        <FilterContainer className="hide-on-mobile">
          <FilterItem active={this.state.status === 'lost'} data-status="lost" onClick={this.onStatusClick}>
            Prospect Lost
            <Badge>{this.props.statuses.lost}</Badge>
          </FilterItem>
        </FilterContainer>
      </div>
    );
  }
}

export default withRouter(ProspectFilter);
