import React from 'react';
import { setManualWireArch } from '../../../../redux/actions/manual_wire/manual_wire';
import ManualWireDropdown from './manual_wire_dropdown/manual_wire_dropdown';
import { connect } from 'react-redux';
import { archOptions } from './manual_wire_options';

const ManualWireArch = ({ archOpts, arch, selectedWire, fetchManualWireData, disableAllFields }) => {
  const onChangeProgram = (event) => {
    const archValue = event.target.value;
    fetchManualWireData(archValue, selectedWire, true);
  };

  const options = archOptions.filter(option => archOpts.includes(option.value));

  return (
    <ManualWireDropdown
      options={options}
      value={arch}
      setValue={onChangeProgram}
      label="Arch"
      id="arch-select-label"
      style={{ height: '30px' }}
    />
  );
};

const mapStateToProps = (state) => {
  const arch = state.manualWireReducer.arch;
  const selectedWire = state.manualWireReducer.wire;

  return {
    arch,
    selectedWire,
  };
};

export default connect(mapStateToProps)(ManualWireArch);
