/**
 * File: expedite_details.js - A component contains modal content for viewing/editing a
 * case's expedite details
 * Copyright: (c) Copyright April 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './expedite_details.scss';
// External Libs
import React, { Component } from 'react';
import Moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Internal Libs
import { convertDate, isDateOnOrAfterMinDate } from '../../common/functions';
import { userHasPermission } from '../../common/permission';
import SelectShippingAddress from './select_address';
import { getShippingAddressName } from '../../common/helpers';
import { isAfterSmileDesignApproval, isBeforeFabricatingStage } from '../../common/case/case_status';
import SecurityClearance from '../../components/security/security_clearance';

// Redux
import {
  getSmileDesignTargetDateInput,
  getEditSmileDesignTargetDate,
  getExpediteDetailsSessionExpired,
  getApplianceDesignTargetDateInput,
  getPreferredShippingInput,
} from '../../redux/reducers/bpp/shipping/expedite_details';
import {
  editSmileDesignTargetDate,
  setSmileDesignTargetDateInput,
  cancelEditSmileDesignTargetDate,
  onSaveSmileDesignTargetDate,
  setPreferredShippingInput,
} from '../../redux/actions/bpp/shipping/expedite_details';

const SHIPPING_METHODS = ['Next Day Shipping', '2 Day Shipping', '3 Day Shipping', 'Ground Shipping'];

/**
 * Used to show all modals for the Expedite Details.
 *
 * @component
 * @alias ExpediteDetails
 * @category BPP
 */

class ExpediteDetails extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  /**
   * Determines if expedite request is approved
   * @function
   * @returns {boolean} - True or false
   */
  isExpediteRequestApproved = () => {
    return this.props.expedite_process.status_code === `${this.props.type} Expedite Request Approved`;
  };

  /**
   * Determines if expedite request is updated
   * @function
   * @returns {boolean} - True or false
   */
  isExpediteRequestUpdated = () => {
    return this.props.expedite_process.status_code === `${this.props.type} Expedite Request Updated`;
  };

  /**
   * Displays shipping address update
   * @function
   * @returns {JSX} - JSX for shipping address
   */

  displayShippingAddress = () => {
    if (!this.props.show_expedite_request && !this.props.show_expedite_approval && !this.props.show_expedite_update) {
      return (
        <div className="form-group expedite-details">
          <label className="inline-label text-top">Shipping Address:</label>
          <div className="inline-input expedite-details__shipping-address">
            <b>{getShippingAddressName(this.props.shipping_address)}</b>
            <br />
            {this.props.shipping_address.address_line_1}, {this.props.shipping_address.city}, {this.props.shipping_address.state}{' '}
            {this.props.shipping_address.zip}, {this.props.shipping_address.country}
          </div>
          {!this.props.shipping_approved_ind ? (
            <SecurityClearance mode="ALL" permission_list={[this.props.confirm_btn_permission]}>
              <div className="inline-link">
                {/*eslint-disable-next-line */}
                <a className="update-shipping-anchor" onClick={this.props.onUpdateAddressClick}>
                  Update
                </a>
              </div>
            </SecurityClearance>
          ) : null}
        </div>
      );
    }
    return (
      <SelectShippingAddress
        shipping_addresses={this.props.shipping_addresses}
        selected_address_id={this.props.selected_address_id}
        onSelectedAddress={this.props.onSelectedAddress}
      ></SelectShippingAddress>
    );
  };

  /**
   * Displays smile design target date
   * @function
   * @param {string} is_after_smile_design_approval - Indicator if case is after smile design approval
   * @returns {JSX} - JSX for smile design target date
   */
  displaySmileDesignTargetDate = (is_after_smile_design_approval) => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">Smile Design Target Date:</label>
        {(this.props.edit_smile_design_target_date || this.props.show_expedite_update) && !is_after_smile_design_approval ? (
          <div className="inline-input">
            <span className="target-date-input">
              <input
                required
                type="date"
                className="form-control \"
                placeholder="Smile Design Target Date:"
                value={this.props.smile_design_target_date_input}
                onChange={(e) => this.props.setSmileDesignTargetDateInput(e.target.value)}
                min={Moment().format('YYYY-MM-DD')}
              />
            </span>
            {this.props.edit_smile_design_target_date && (
              <span>
                <i
                  className="fa fa-check"
                  aria-hidden="true"
                  onClick={() => this.props.onSaveSmileDesignTargetDate(this.props.id, this.props.reloadInformation)}
                />
                <i className="fa fa-times" aria-hidden="true" onClick={this.props.cancelEditSmileDesignTargetDate} />
              </span>
            )}
          </div>
        ) : (
          <div className="inline-input">
            {this.props.target_dates.smile_design_target_date ? convertDate(this.props.target_dates.smile_design_target_date) : 'N/A'}
          </div>
        )}
        {this.props.expedite_process.status_code === 'Case Expedite Request Submitted' &&
          !is_after_smile_design_approval &&
          !this.props.edit_smile_design_target_date && (
            <span className="inline-link inline-link__smile_design">
              {/*eslint-disable-next-line */}
              <a
                className="update-shipping-anchor"
                onClick={() => this.props.editSmileDesignTargetDate(this.props.target_dates ? this.props.target_dates.smile_design_target_date : '')}
              >
                Update
              </a>
            </span>
          )}
      </div>
    );
  };

  /**
   * Displays appliance design target date
   * @function
   * @param {string} show_expedite_approval - Indicator if modal is showing expedite approval
   * @returns {JSX} - JSX for appliance design target date
   */
  displayApplianceDesignTargetDate = (show_expedite_approval) => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">{`${
          show_expedite_approval && isBeforeFabricatingStage(this.props.case_status) ? 'New ' : ''
        }Appliance Design Target Date:`}</label>
        {(this.props.show_expedite_update || show_expedite_approval) && isBeforeFabricatingStage(this.props.case_status)
          ? this.displayApplianceDesignTargetDateInput()
          : this.displayApplianceDesignTargetDateText()}
      </div>
    );
  };

  /**
   * Displays appliance design target date input
   * @function
   * @returns {JSX} - JSX for appliance design target date input
   */
  displayApplianceDesignTargetDateInput = () => {
    const value = this.props.appliance_design_target_date_input;
    const min_date = Moment().format('YYYY-MM-DD');

    return (
      <div className="inline-input">
        <input
          required
          type="date"
          className={`form-control target-date-input${
            this.props.date_error || (this.props.input_error && (!this.props.appliance_design_target_date_input || !isDateOnOrAfterMinDate(value, min_date)))
              ? ' inline-input__error'
              : ''
          }`}
          placeholder="Appliance Design Target Date:"
          value={value}
          onChange={(e) => this.props.setApplianceDesignTargetDateInput(e)}
          min={min_date}
        />
      </div>
    );
  };

  /**
   * Displays appliance design target date text
   * @function
   * @returns {JSX} - JSX for appliance design target date text
   */
  displayApplianceDesignTargetDateText = () => {
    return (
      <div className="inline-input">
        {this.props.target_dates.appliance_design_target_date ? convertDate(this.props.target_dates.appliance_design_target_date) : 'N/A'}
      </div>
    );
  };

  /**
   * Displays internal target ship date
   * @function
   * @param {string} show_expedite_approval - Indicator if modal is showing expedite approval
   * @returns {JSX} - JSX for target ship date
   */
  displayInternalTargetShipDate = (show_expedite_approval) => {
    const value = this.props.expedite_target_ship_date;
    let min_date = this.props.target_dates?.appliance_design_target_date ? this.props.target_dates.appliance_design_target_date : Moment().format('YYYY-MM-DD');
    min_date = this.props.appliance_design_target_date_input ? this.props.appliance_design_target_date_input : min_date;
    if (!isBeforeFabricatingStage(this.props.case_status)) min_date = Moment().format('YYYY-MM-DD');

    return (
      <div className="form-group expedite-details">
        <label className="inline-label">Internal Target Ship Date:</label>
        {this.props.show_expedite_update || show_expedite_approval ? (
          <div className="inline-input">
            <input
              required
              type="date"
              className={`form-control target-date-input${
                this.props.date_error || (this.props.input_error && (!this.props.expedite_target_ship_date || !isDateOnOrAfterMinDate(value, min_date)))
                  ? ' inline-input__error'
                  : ''
              }`}
              value={value}
              placeholder="Internal Target Ship Date:"
              onChange={(e) => this.props.onExpediteTargetShipDateChange(e)}
              min={min_date}
            />
          </div>
        ) : this.isExpediteRequestApproved() ? (
          <div className="inline-input">{convertDate(this.props.expedite_process.target_ship_date)}</div>
        ) : (
          <div className="inline-input">
            {this.props.target_dates && this.props.target_dates.internal_target_ship_date
              ? convertDate(this.props.target_dates.internal_target_ship_date)
              : 'N/A'}
          </div>
        )}
      </div>
    );
  };

  /**
   * Displays external target ship date
   * @function
   * @param {string} show_expedite_approval - Indicator if modal is showing expedite approval
   * @returns {JSX} - JSX for target ship date
   */
  displayExternalTargetShipDate = (show_expedite_approval) => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">External Target Ship Date:</label>
        {this.props.show_expedite_update || show_expedite_approval ? (
          <div className="inline-input">
            <input
              required
              type="date"
              className={`form-control target-date-input${
                this.props.date_error || (this.props.input_error && !this.props.expedite_target_ship_date) ? ' inline-input__error' : ''
              }`}
              value={this.props.expedite_target_ship_date}
              placeholder="External Target Ship Date:"
              onChange={this.props.onExpediteTargetShipDateChange}
              min={Moment().format('YYYY-MM-DD')}
            />
          </div>
        ) : this.isExpediteRequestApproved() ? (
          <div className="inline-input">{convertDate(this.props.expedite_process.target_ship_date)}</div>
        ) : (
          <div className="inline-input">
            {this.props.target_dates && this.props.target_dates.external_target_ship_date
              ? convertDate(this.props.target_dates.external_target_ship_date)
              : 'N/A'}
          </div>
        )}
      </div>
    );
  };

  /**
   * Displays preferred shipping method
   * @function
   * @param {string} show_expedite_approval - Indicator if modal is showing expedite approval
   * @returns {JSX} - JSX for preferred shipping method
   */
  displayPreferredShipping = (show_expedite_approval) => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">Preferred Shipping:</label>
        {this.props.show_expedite_update || show_expedite_approval ? (
          <div className="inline-input">
            <select
              className={`shipping-methods-input${this.props.input_error && !this.props.preferred_shipping_input ? ' inline-input__error' : ''}`}
              defaultValue={this.props.preferred_shipping_input}
              onChange={(e) => this.props.setPreferredShippingInput(e.target.value)}
            >
              <option value="" className="hide">
                Please select
              </option>
              {SHIPPING_METHODS.map((shipping_method, index) => {
                return (
                  <option value={shipping_method} key={index}>
                    {shipping_method}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          <div className="inline-input">{this.props.target_dates.preferred_shipping ? this.props.target_dates.preferred_shipping : 'N/A'}</div>
        )}
      </div>
    );
  };

  /**
   * Displays expedite shipping
   * @function
   * @param {string} show_expedite_approval - Indicator if modal is showing expedite approval
   * @returns {JSX} - JSX for expedite shipping
   */
  displayExpediteShipping = (show_expedite_approval) => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">Expedite Shipping:</label>
        {this.props.show_expedite_request || this.props.show_expedite_update || show_expedite_approval ? (
          this.displayExpediteShippingDropdown()
        ) : this.isExpediteRequestApproved() || this.isExpediteRequestUpdated() ? (
          <div className="inline-input">{this.props.expedite_process.expedite_ship_ind ? 'Yes' : 'No'}</div>
        ) : (
          <div className="inline-input">No</div>
        )}
      </div>
    );
  };

  /**
   * Displays expedite shipping input dropdown
   * @function
   * @returns {JSX} - JSX for expedite shipping dropdown
   */
  displayExpediteShippingDropdown = () => {
    return (
      <div className="inline-input">
        <select
          defaultValue={(this.props.show_expedite_update && this.props.expedite_process.expedite_ship_ind) || this.props.show_expedite_approval ? 'Yes' : 'No'}
          onChange={this.props.show_expedite_approval ? this.props.onExpediteApprovalDropdownChange : this.props.onExpediteDropdownChange}
        >
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
    );
  };

  /**
   * Displays internal request note
   * @function
   * @returns {JSX} - JSX for internal request note
   */
  displayInternalRequestNote = () => {
    return (
      <>
        <div className="form-group expedite-details">
          <label className="inline-label text-top">Internal Request Note:</label>
          <div className="inline-input">
            <textarea
              className="form-control"
              maxLength={2500}
              onChange={this.props.onTextAreaChange}
              placeholder="Please specify details for this expedite request..."
              rows="6"
              value={this.props.expedite_note}
            />
          </div>
        </div>
      </>
    );
  };

  /**
   * Displays additional notes textbox
   * @function
   * @returns {JSX} - JSX for additional notes
   */
  displayAdditionalNotes = () => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label text-top additional">Additional Notes:</label>
        <div className="inline-input">
          <textarea className="form-control" maxLength={2500} onChange={this.props.onTextAreaChange} placeholder="Optional..." rows="6" />
        </div>
      </div>
    );
  };

  /**
   * Displays expedite approval section
   * @function
   * @param {string} is_after_smile_design_approval - Indicator if case is after smile design approval
   * @returns {JSX} - JSX for expedite approval section
   */
  displayExpediteApproval = (is_after_smile_design_approval) => {
    return (
      <>
        {this.displayExpediteApprovalHeader(is_after_smile_design_approval)}
        <div className="form-group expedite-details">
          <label className="inline-label">Requester:</label>
          <div className="inline-input">
            {this.props.expedite_process.text_created_by_first_name + ' ' + this.props.expedite_process.text_created_by_last_name}
          </div>
        </div>
        <div className="form-group expedite-details">
          <label className="inline-label">Date Submitted:</label>
          <div className="inline-input">{convertDate(this.props.expedite_process.date)}</div>
        </div>
        <div className="form-group expedite-details">
          <label className="inline-label">Internal Request Note:</label>
          <div className="inline-input">{this.props.expedite_process.text}</div>
        </div>
        {this.props.type === 'Case' && (
          <div className="form-group expedite-details">
            <label className="inline-label">Smile Design Doctor Approval:</label>
            <div className="inline-input">{is_after_smile_design_approval ? 'Approved' : 'Not Approved'}</div>
          </div>
        )}
        {this.displayExpediteShipping(this.props.show_expedite_approval)}
        {this.props.show_expedite_approval ? (
          <React.Fragment>
            {this.props.type === 'Case' && this.displayApplianceDesignTargetDate(true)}
            {this.displayInternalTargetShipDate(true)}
            {this.displayPreferredShipping(true)}
            {this.displayAdditionalNotes()}
          </React.Fragment>
        ) : null}
      </>
    );
  };

  /**
   * Displays expedite approval section header
   * @function
   * @param {string} is_after_smile_design_approval - Indicator if case is after smile design approval
   * @returns {JSX} - JSX for expedite approval section header
   */
  displayExpediteApprovalHeader = (is_after_smile_design_approval) => {
    return (
      <div className="expedite-approval-header">
        {this.props.show_expedite_approval ? (
          <b>Enter new target ship dates and click Approve to complete this request</b>
        ) : (
          <b>
            Expedite Request (Pending Approval)
            {this.props.type === 'Case' && !is_after_smile_design_approval
              ? ". Expedite request can be approved only after doctor's approval of Smile Design."
              : ''}
          </b>
        )}
      </div>
    );
  };

  /**
   * Displays shipping priority
   * @function
   * @returns {JSX} - JSX for priority
   */
  displayPriority = () => {
    return (
      <div className="form-group expedite-details">
        <label className="inline-label">Priority:</label>
        <div className="inline-input">{this.props.expedite_priority}</div>
      </div>
    );
  };

  /**
   * Displays cancel expedite link
   * @function
   * @returns {JSX} - JSX for cancel expedite link
   */
  displayCancelExpedite = () => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <a className="cancel-expedite" onClick={this.props.onCancelExpeditedClick}>
          Cancel Expedite
        </a>
      </>
    );
  };

  render() {
    const is_after_smile_design_approval = isAfterSmileDesignApproval(this.props.case_status);

    return (
      <React.Fragment>
        {!this.props.show_expedite_update &&
          (this.isExpediteRequestApproved() || this.isExpediteRequestUpdated()) &&
          userHasPermission(this.props.cancel_permission, this.props.user_roles_and_permissions.permissions) &&
          this.displayCancelExpedite()}
        {this.displayPriority()}
        {this.displayShippingAddress()}
        {this.props.type === 'Case' && (
          <>
            {this.displaySmileDesignTargetDate(is_after_smile_design_approval)}
            {this.displayApplianceDesignTargetDate(false)}
          </>
        )}
        {this.displayInternalTargetShipDate(false)}
        {this.displayPreferredShipping(false)}
        {this.displayExpediteShipping(false)}
        {this.props.show_expedite_request && this.displayInternalRequestNote()}
        {this.props.show_expedite_update && this.displayAdditionalNotes()}
        {this.props.expedite_process.status_code === `${this.props.type} Expedite Request Submitted` &&
          this.displayExpediteApproval(is_after_smile_design_approval)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    smile_design_target_date_input: getSmileDesignTargetDateInput(state),
    edit_smile_design_target_date: getEditSmileDesignTargetDate(state),
    appliance_design_target_date_input: getApplianceDesignTargetDateInput(state),
    preferred_shipping_input: getPreferredShippingInput(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      editSmileDesignTargetDate: editSmileDesignTargetDate,
      setSmileDesignTargetDateInput: setSmileDesignTargetDateInput,
      cancelEditSmileDesignTargetDate: cancelEditSmileDesignTargetDate,
      onSaveSmileDesignTargetDate: onSaveSmileDesignTargetDate,
      getExpediteDetailsSessionExpired: getExpediteDetailsSessionExpired,
      setPreferredShippingInput: setPreferredShippingInput,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExpediteDetails);
