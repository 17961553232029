import React from 'react';
import CaseListFilterItem from './case_list_filter_item';

/**
 * Display the entire filter list
 * @component
 * @category BPP
 */
function CaseListFilter(props) {
  const { filters } = props;

  return (
    <div className="sidebar__filter-container">
      {filters.map((item) => (
        <CaseListFilterItem key={item.filter} {...item} />
      ))}
    </div>
  );
}

export default CaseListFilter;
