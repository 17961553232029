// External
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal
import './single_tooth_idb.scss';
import Modal from '../../../modal/modal';
import SessionExpire from '../../../../common/session_expire';
import SingleToothIdbGeneration from './single_tooth_idb_generation';
import SingleToothIdbSuccess from './single_tooth_idb_success';
import SingleToothIdbFailure from './single_tooth_idb_failure';
import SingleToothIdbOverride from './single_tooth_idb_override';
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../../../common/functions';

// Redux
import {
  getRefresh,
  getSingleToothIdbFinalFile,
  getSingleToothIdbModal,
  getSingleToothIdbRawFile,
  getSingleToothIdbStatus,
  getSingleToothIdbStatusComment,
} from '../../../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';
import { singleToothIdbAction, setSingleToothIdbModal } from '../../../../redux/actions/bpp/single_tooth_idb/single_tooth_idb';

/**
 * Displays Single-Tooth IDB Tray generation component
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - JSX for component
 */
function SingleToothIdb(props) {
  /**
   * Displays loading text while component is rendering
   * @function
   * @returns {JSX} - Loading text
   */
  const is_ireq = props.id.includes('-IR');

  const displayNotApplicable = () => {
    return <div className="center-text">Not Applicable</div>;
  };

  const displays = {
    '': displayNotApplicable(),
    'Single Tooth IDB Generation': <SingleToothIdbGeneration {...props} />,
    'Single Tooth IDB Success': <SingleToothIdbSuccess {...props} />,
    'Single Tooth IDB Failure': <SingleToothIdbFailure {...props} />,
    'Single Tooth IDB Overridden': <SingleToothIdbOverride {...props} />,
  };

  /**
   * Displays modal
   * @function
   * @returns {JSX} - JSX for modal
   */
  const displayModal = () => {
    if (props.single_tooth_idb_modal === 'upload') {
      return displayUploadModal();
    }
    const id = is_ireq ? removeIreqIdInitialNumber(props.id) : removeCaseIdInitialNumber(props.id);

    const modals = {
      mark_success: {
        preset: 'decision',
        header_text: `Mark as Success - ${id}`,
        message_text: `Are you sure you would like to force the Single-Tooth IDB Tray generation to succeed?`,
        close_btn_text: 'Cancel',
        confirm_btn_text: 'Mark as Success',
      },
      mark_failed: {
        preset: 'decision',
        header_text: `Mark as Failed - ${id}`,
        message_text: `Are you sure you would like to force the Single-Tooth IDB Tray generation to fail?`,
        close_btn_text: 'Cancel',
        confirm_btn_text: 'Mark as Failed',
      },
      retry: {
        preset: 'decision',
        header_text: `Retry - ${id}`,
        message_text: `Are you sure you want to retry the Single-Tooth IDB Tray generation?`,
        close_btn_text: 'Cancel',
        confirm_btn_text: 'Retry',
      },
      remove: {
        preset: 'decision',
        header_text: `Remove Single-Tooth IDB Tray File - ${id}`,
        message_text: `Are you sure you want to remove the Single-Tooth IDB Tray file?`,
        close_btn_text: 'Cancel',
        confirm_btn_text: 'Remove',
      },
      override: {
        preset: 'decision',
        header_text: `Override - ${id}`,
        message_text: `Are you sure you want to override the Single-Tooth IDB Tray generation?`,
        close_btn_text: 'Cancel',
        confirm_btn_text: 'Override',
      },
    };

    if (props.single_tooth_idb_modal in modals) {
      return (
        <Modal
          theme="bpp"
          onCloseButtonClick={() => props.setSingleToothIdbModal('')}
          onConfirmButtonClick={() => {
            props.singleToothIdbAction(props.single_tooth_idb_modal, props.id);
          }}
          {...modals[props.single_tooth_idb_modal]}
        />
      );
    }
    return null;
  };

  /**
   * Displays upload modal for file
   * @function
   * @returns {JSX} - JSX for modal
   */
  const displayUploadModal = () => {
    let config = {
      preset: 'upload',
      modal_size_class: 'modal-lg modal-lg-pdf',
      modal_body_class: 'modal-pdf',
      header_text: `Single-Tooth IDB Tray - ${is_ireq ? removeIreqIdInitialNumber(props.id) : removeCaseIdInitialNumber(props.id)}`,
      folder: 'single_tooth_idb_final',
      type: 'single_tooth_idb_final',
      location: is_ireq ? 'ireq_single_tooth_idb' : 'complete',
      onUpload: (data) => {
        props.singleToothIdbAction('upload', props.id, data);
      },
      upload_content: [],
      onCloseButtonClick: () => {
        props.setSingleToothIdbModal('');
      },
      theme: 'bpp',
    };
    config[is_ireq ? 'ireq_id' : 'case_id'] = props.id;

    return <Modal {...config} />;
  };

  /**
   * Gets class name for single-tooth IDB component
   * @function
   * @returns {string} - Class name
   */
  const getClassName = () => {
    let class_name = props.single_tooth_idb_status ? 'single-tooth-idb' : 'single-tooth-idb na';
    if (!is_ireq) return (class_name += ' process-box');

    const is_generating = props.single_tooth_idb_status === 'Single Tooth IDB Generation';
    const is_failed = props.single_tooth_idb_status === 'Single Tooth IDB Failure';
    if (is_generating || (is_failed && !props.single_tooth_idb_final_file)) return class_name;

    return (class_name += ' single-tooth-idb__align-left');
  };

  /**
   * Determiens if single-tooth IDB title should be displayed
   * @function
   * @returns {boolean} - True or false
   */
  const shouldDisplayTitle = () => {
    const has_file = props.single_tooth_idb_final_file || props.single_tooth_idb_raw_file;
    const is_overridden = props.single_tooth_idb_status === 'Single Tooth IDB Overridden';
    return is_ireq && (has_file || is_overridden) ? false : true;
  };

  return (
    <>
      {props.single_tooth_idb_status in displays && (
        <div className={getClassName()}>
          {shouldDisplayTitle() && <div className="single-tooth-idb__title">Single-Tooth IDB Tray</div>}
          {displays[props.single_tooth_idb_status]}
          {props.single_tooth_idb_modal && displayModal()}
          {props.refresh && <SessionExpire />}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    single_tooth_idb_modal: getSingleToothIdbModal(state),
    single_tooth_idb_status_comment: getSingleToothIdbStatusComment(state),
    single_tooth_idb_raw_file: getSingleToothIdbRawFile(state),
    single_tooth_idb_final_file: getSingleToothIdbFinalFile(state),
    single_tooth_idb_status: getSingleToothIdbStatus(state),
    refresh: getRefresh(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSingleToothIdbModal: setSingleToothIdbModal,
      singleToothIdbAction: singleToothIdbAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleToothIdb);
