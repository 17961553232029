import { computed, signal } from '@preact/signals-react';

export const LAST_SAVED_AT_FORMAT = 'M/D/YYYY hh:mm A';

export const EAutosaveState = {
  Idle: 'idle',
  Loading: 'loading',
  Failure: 'failure',
  Success: 'success',
};

const initialState = { state: EAutosaveState.Idle, isLoading: false, error: '' };

export const autoSaveSignal = signal(initialState);
export const lastSavedAtSignal = signal(undefined);

export const isDraftSaveAvailable = computed(() => autoSaveSignal.value.state !== EAutosaveState.Loading);
export const isAutosaveAvailable = computed(() =>
    autoSaveSignal.value.state === EAutosaveState.Idle ||
    autoSaveSignal.value.state === EAutosaveState.Success
);

export const resetAutosave = () => {
  autoSaveSignal.value = initialState;
  lastSavedAtSignal.value = undefined;
};

export const disableAutosave = () => {
  autoSaveSignal.value = initialState;
};
