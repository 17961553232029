import React from 'react';
import PropTypes from 'prop-types';
import Button from './button';
import CircleLoader from '../../../components/loader/circle_loader';

/**
 * Displays a loading button
 * @component
 */
function LoadingButton({
  isLoading,
  children,
  disabled,
  className,
  theme,
  loaderWithSpacing,
  ...props
}) {
  return (
    <Button disabled={isLoading || disabled} theme={theme} {...props}>
      {isLoading ? (
        <CircleLoader size="small" theme={theme} withSpacing={loaderWithSpacing} />
      ) : (
        children
      )}
    </Button>
  );
}

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  loaderWithSpacing: PropTypes.bool,
};

LoadingButton.defaultProps = {
  isLoading: false,
  loaderWithSpacing: true,
};

export default LoadingButton;
