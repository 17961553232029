import _ from 'lodash';
import { SegmentationQuestions } from '../../../../components/production_tx_guide/segmentation_questions';

import {
  OPEN_TEETH_SELECTOR_MODAL,
  CLOSE_TEETH_SELECTOR_MODAL,
  FETCH_PRODUCTION_TX_GUIDE_PENDING,
  FETCH_PRODUCTION_TX_GUIDE_ERROR,
  SET_NOTE,
  SET_INCOMPLETE_ERROR,
  UPDATE_TREATMENT_GUIDE_PENDING,
  UPDATE_TREATMENT_GUIDE_SUCCESS,
  UPDATE_TREATMENT_GUIDE_ERROR,
  SET_EDIT_MODE_SEGMENTATION,
  SET_SEGMENTATION_QUESTIONS,
  OPEN_SEGMENTATION_TX_GUIDE_PDF_MODAL,
  CLOSE_SEGMENTATION_TX_GUIDE_PDF_MODAL,
  SET_SEGMENTATION_TX_GUIDE_FILE_PATH,
  SET_SEGMENTATION_SESSION_EXPIRED,
  SET_SEGMENTATION_QUESTIONS_OLD,
  SET_TREATMENT_PLAN_REVISION,
} from '../../../actions/bpp/production_tx_guide/segmentation';

const initialState = {
  case_id: '',
  teeth_selector_modal: false,
  teeth_selector_category: '',
  teeth_selector_question: '',
  note: '',
  error: false,
  incomplete_error: false,
  segmentation_questions: _.cloneDeep(SegmentationQuestions),
  is_edit_mode_segmentation: false,
  segmentation_tx_guide_pdf_modal: false,
  segmentation_tx_guide_file_path: '',
  session_expired: false,
  segmentation_questions_old: {},
  treatment_plan_revision: '',
};

/**
 * This reducer is used for the Segmentation production tx guide form
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function segmentationProductionTxGuideReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_TEETH_SELECTOR_MODAL:
      return {
        ...state,
        teeth_selector_modal: true,
        teeth_selector_category: action.teeth_selector_category,
        teeth_selector_question: action.teeth_selector_question,
      };

    case CLOSE_TEETH_SELECTOR_MODAL:
      return {
        ...state,
        teeth_selector_modal: false,
        teeth_selector_category: '',
        teeth_selector_question: '',
      };

    case FETCH_PRODUCTION_TX_GUIDE_PENDING:
      return {
        ...state,
        pending: true,
        case_id: action.case_id,
      };

    case SET_SEGMENTATION_QUESTIONS:
      return {
        ...state,
        pending: false,
        error: false,
        segmentation_questions: action.segmentation_questions,
        note: action.note ? action.note : '',
        case_id: action.case_id,
      };

    case FETCH_PRODUCTION_TX_GUIDE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      };

    case SET_NOTE:
      return {
        ...state,
        note: action.note,
      };

    case SET_INCOMPLETE_ERROR:
      return {
        ...state,
        incomplete_error: action.error,
      };

    case UPDATE_TREATMENT_GUIDE_SUCCESS:
      return {
        ...state,
        segmentation_questions: action.segmentation_questions,
        incomplete_error: false,
        error: false,
        pending: false,
      };

    case UPDATE_TREATMENT_GUIDE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      };

    case UPDATE_TREATMENT_GUIDE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case SET_EDIT_MODE_SEGMENTATION:
      return {
        ...state,
        is_edit_mode_segmentation: action.is_edit_mode_segmentation,
      };

    case OPEN_SEGMENTATION_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        segmentation_tx_guide_pdf_modal: true,
        segmentation_tx_guide_file_path: action.file_path,
      };

    case CLOSE_SEGMENTATION_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        segmentation_tx_guide_pdf_modal: false,
      };

    case SET_SEGMENTATION_TX_GUIDE_FILE_PATH:
      return {
        ...state,
        segmentation_tx_guide_file_path: action.file_path,
      };

    case SET_SEGMENTATION_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: action.session_expired,
      };

    case SET_SEGMENTATION_QUESTIONS_OLD:
      return {
        ...state,
        segmentation_questions_old: action.segmentation_questions_old,
      };

    case SET_TREATMENT_PLAN_REVISION:
      return {
        ...state,
        treatment_plan_revision: action.treatment_plan_revision,
      };

    default:
      return state;
  }
}

export const getSegmentationQuestions = (state) => state.segmentationProductionTxGuideReducer.segmentation_questions;
export const getTeethSelectorModal = (state) => state.segmentationProductionTxGuideReducer.teeth_selector_modal;
export const getTeethSelectorCategory = (state) => state.segmentationProductionTxGuideReducer.teeth_selector_category;
export const getTeethSelectorQuestion = (state) => state.segmentationProductionTxGuideReducer.teeth_selector_question;
export const getNote = (state) => state.segmentationProductionTxGuideReducer.note;
export const getIncompleteError = (state) => state.segmentationProductionTxGuideReducer.incomplete_error;
export const getIsEditModeSegmentation = (state) => state.segmentationProductionTxGuideReducer.is_edit_mode_segmentation;
export const getSegmentationTxGuidePdfModal = (state) => state.segmentationProductionTxGuideReducer.segmentation_tx_guide_pdf_modal;
export const getSegmentationTxGuideFilePath = (state) => state.segmentationProductionTxGuideReducer.segmentation_tx_guide_file_path;
export const getSegmentationSessionExpired = (state) => state.segmentationProductionTxGuideReducer.session_expired;
export const getSegmentationQuestionsOld = (state) => state.segmentationProductionTxGuideReducer.segmentation_questions_old;
