import './itero_settings.scss';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';

import { getDoctorsIdFromRoute } from '../../common/route';
import { removeEmoji } from '../../common/functions';
import { fetchLicenseNumbers, createLicenseNumber, deleteLicenseNumber, updateLicenseNumber } from '../../redux/actions/ipp/itero/itero_license_numbers';
import {
  getCreateLicenseNumberPending,
  getDeleteLicenseNumberPending,
  getUpdateLicenseNumberPending,
  getFetchLicenseNumbersPending,
  getLicenseNumbers,
} from '../../redux/reducers/ipp/itero/itero_license_numbers';
import { getAccountSetting } from '../../redux/reducers/ipp/account_settings';
import { UserPermissionsContext } from '../../context/user_permission';
import CircleLoader from '../../components/loader/circle_loader';
import SessionExpire from '../components/modals/session_expire';
import { PdfModal } from '../components/modals/pdf-modal';
import SimpleModal from '../components/modals/simple-modal';
import NotFound from '../404/not_found';

const ITERO_SUPPORT_PHONE_DISPLAY = '(800) 577-8767';
const ITERO_SUPPORT_PHONE = '8005778767';

const LICENSE_NUMBER_MAX_LENGTH = 30;

const ActionButton = ({ onClick, tooltip, children, className, disabled }) => {
  const getActionButtonClass = () => {
    const classes = ['action-button'];
    if (disabled) {
      classes.push('disabled');
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(' ');
  };

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Tooltip
      arrow
      placement="top"
      title={tooltip}
      classes={{ popper: 'itero-settings-action__popper mobile-hidden', tooltip: 'itero-settings-action__tooltip' }}
    >
      <div className={getActionButtonClass()} onClick={handleClick}>
        {children}
      </div>
    </Tooltip>
  );
};

const IteroSettings = ({
  account,
  licenseNumbers,
  createLicenseNumber,
  deleteLicenseNumber,
  updateLicenseNumber,
  fetchLicenseNumbers,
  isCreateLicenseNumberPending,
  isDeleteLicenseNumberPending,
  isUpdateLicenseNumberPending,
  isFetchLicenseNumbersPending,
}) => {
  const { permissions } = React.useContext(UserPermissionsContext);
  const doctorId = getDoctorsIdFromRoute();
  const [newLicenseNumber, setNewLicenseNumber] = React.useState('');
  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
  const [licenseNumberError, setLicenseNumberError] = React.useState(false);
  const [licenseNumberErrorMessage, setLicenseNumberErrorMessage] = React.useState('');
  const [selectedLicenseNumber, setSelectedLicenseNumber] = React.useState(null);
  const [selectedLicenseNumberError, setSelectedLicenseNumberError] = React.useState(false);
  const [selectedLicenseNumberErrorMessage, setSelectedLicenseNumberErrorMessage] = React.useState('');
  const [selectedLicenseNumberValue, setSelectedLicenseNumberValue] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);
  const [showInstructionsPdfModal, setShowInstructionsPdfModal] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);

  const isLastLicenseNumber = (index) => {
    return index === licenseNumbers.length - 1;
  };

  const isSelectedLicenseNumber = (licenseNumberObj) => {
    return selectedLicenseNumber?.id === licenseNumberObj.id;
  };

  const canEdit = permissions.includes('IPP_EDIT');

  const isLicenseNumberSubmitDisabled = !newLicenseNumber || licenseNumberError || isCreateLicenseNumberPending || !canEdit;
  const isLicenseNumberSubmitInputDisabled = isCreateLicenseNumberPending || !canEdit;

  const isEditLicenseNumberSaveDisabled = !selectedLicenseNumberValue || selectedLicenseNumberError || isUpdateLicenseNumberPending;
  const isEditLicenseNumberInputDisabled = isUpdateLicenseNumberPending;

  const isDeleteLicenseNumberDisabled = isEditing && isEditLicenseNumberSaveDisabled;

  const getAddLicenseNumberInputClass = () => {
    const classes = ['input-container__input', 'text-input'];
    if (licenseNumberErrorMessage) {
      classes.push('error');
    }
    return classes.join(' ');
  };

  const getEditLicenseNumberInputClass = () => {
    const classes = ['input-container__input', 'text-input'];
    if (selectedLicenseNumberErrorMessage) {
      classes.push('error');
    }
    return classes.join(' ');
  };

  const getItemClass = (index) => {
    const classes = ['list__item', 'padding-top', 'padding-bottom'];
    if (!isLastLicenseNumber(index)) {
      classes.push('border-bottom');
    }
    return classes.join(' ');
  };

  const getLicenseNumberItemClass = () => {
    const classes = ['item__license-number'];
    return classes.join(' ');
  };

  const getActionsItemClass = (licenseNumberObj) => {
    const classes = ['item__actions'];
    if (!isSelectedLicenseNumber(licenseNumberObj)) {
      classes.push('hide');
    }
    return classes.join(' ');
  };

  const getListTitleTableClass = () => {
    const classes = ['list-container__title'];
    if (licenseNumbers.length > 5) {
      classes.push('with-scroll');
    }
    return classes.join(' ');
  };

  const getLicenseNumberClass = (licenseNumberObj) => {
    const classes = ['license-number'];
    if (isSelectedLicenseNumber(licenseNumberObj)) {
      classes.push('selected');
    }
    return classes.join(' ');
  };

  const getActionsClass = (licenseNumberObj) => {
    const classes = ['actions'];
    if (!isSelectedLicenseNumber(licenseNumberObj)) {
      classes.push('hide');
    }
    return classes.join(' ');
  };

  const getLicenseNumbersClass = () => {
    const classes = ['itero-settings__license-numbers'];
    if (!licenseNumbers.length) {
      classes.push('hide');
    }
    return classes.join(' ');
  };

  const getLicenseNumberItemClickHandler = (licenseNumberObj) => () => {
    if (!canEdit) {
      return;
    }
    if (selectedLicenseNumber?.id !== licenseNumberObj.id) {
      setIsEditing(false);
      setSelectedLicenseNumber(licenseNumberObj);
      setSelectedLicenseNumberError(false);
      setSelectedLicenseNumberErrorMessage('');
    }
  };

  const getLicenseNumberItemDoubleClickHandler = (licenseNumberObj) => () => {
    if (!canEdit) {
      return;
    }
    setSelectedLicenseNumber(licenseNumberObj);
    setIsEditing(true);
    setSelectedLicenseNumberValue(licenseNumberObj.license_number);
    setSelectedLicenseNumberError(false);
    setSelectedLicenseNumberErrorMessage('');
  };

  const getEditActionClickHandler = (licenseNumberObj) => () => {
    setSelectedLicenseNumber(licenseNumberObj);
    setIsEditing(true);
    setSelectedLicenseNumberValue(licenseNumberObj.license_number);
    setSelectedLicenseNumberError(false);
    setSelectedLicenseNumberErrorMessage('');
  };

  const handleSessionExpired = () => {
    setRefresh(true);
  };

  const handleSaveActionClick = () => {
    updateLicenseNumber(
      doctorId,
      selectedLicenseNumber?.id,
      {
        license_number: selectedLicenseNumberValue,
        previous_license_number: selectedLicenseNumber.license_number,
      },
      {
        onSuccess: () => {
          setSelectedLicenseNumber(null);
        },
        onSessionExpired: handleSessionExpired,
        reFetchLicenseNumbers: true,
      }
    );
  };

  const handleDeleteActionClick = () => {
    setShowDeleteWarning(true);
  };

  const handleCancelActionClick = () => {
    setSelectedLicenseNumber(null);
  };

  const handleConfirmDelete = () => {
    setShowDeleteWarning(false);
    deleteLicenseNumber(doctorId, selectedLicenseNumber?.id, selectedLicenseNumber.license_number, {
      onSuccess: () => {
        setSelectedLicenseNumber(null);
      },
      onSessionExpired: handleSessionExpired,
      reFetchLicenseNumbers: true,
    });
  };

  const handleCancelDelete = () => {
    setShowDeleteWarning(false);
  };

  const handleCloseDeleteWarning = () => {
    setShowDeleteWarning(false);
  };

  const handleInstructionsPdfModal = () => {
    setShowInstructionsPdfModal(!showInstructionsPdfModal);
  };

  const getLicenseNumberValidValue = (rawValue) => {
    return removeEmoji(rawValue).slice(0, LICENSE_NUMBER_MAX_LENGTH);
  };

  const handleLicenseNumberChange = (event) => {
    const rawValue = event.target.value;

    const value = getLicenseNumberValidValue(rawValue);

    setNewLicenseNumber(value);
    setLicenseNumberError(false);
    setLicenseNumberErrorMessage('');

    if (licenseNumbers.map((item) => item.license_number).includes(value)) {
      setLicenseNumberError(true);
      setLicenseNumberErrorMessage('License number already exists');
    }
  };

  const handleEditLicenseNumberChange = (event) => {
    const rawValue = event.target.value;

    const value = getLicenseNumberValidValue(rawValue);

    setSelectedLicenseNumberValue(value);
    setSelectedLicenseNumberError(false);
    setSelectedLicenseNumberErrorMessage('');

    if (
      licenseNumbers
        .filter((item) => item.license_number !== selectedLicenseNumber.license_number)
        .map((item) => item.license_number)
        .includes(value)
    ) {
      setSelectedLicenseNumberError(true);
      setSelectedLicenseNumberErrorMessage('License number already exists');
    }
  };

  const handleLicenseNumberInputFocus = () => {
    setSelectedLicenseNumber(null);
  };

  const handleLicenseNumberSubmit = (event) => {
    event.preventDefault();
    if (newLicenseNumber) {
      if (licenseNumbers.map((item) => item.license_number).includes(newLicenseNumber)) {
        setLicenseNumberError(true);
        setLicenseNumberErrorMessage('License number already exists');
        return;
      }
      setNewLicenseNumber('');
      setLicenseNumberError(false);
      setLicenseNumberErrorMessage('');
      createLicenseNumber(
        doctorId,
        { license_number: newLicenseNumber },
        {
          onSuccess: () => {
            setNewLicenseNumber('');
          },
          onError: () => {
            setLicenseNumberError(true);
            setLicenseNumberErrorMessage('An error occurred while creating the license number');
          },
          onSessionExpired: handleSessionExpired,
          reFetchLicenseNumbers: true,
        }
      );
    } else {
      setLicenseNumberError(true);
      setLicenseNumberErrorMessage('License number is required');
    }
  };

  React.useEffect(() => {
    fetchLicenseNumbers(doctorId);
  }, []);

  React.useEffect(() => {
    if (account) {
      setIsLoadingPage(false);
    }
  }, [account]);

  if (isLoadingPage) {
    return <CircleLoader fullscreen={true} />;
  }

  if (!account?.program_enrollment?.includes('itero_integration')) {
    return <NotFound />;
  }

  return (
    <>
      <div className="account__itero-settings">
        <h2 className="itero-settings__title">iTero Intraoral Scanner Integration</h2>
        <div className="itero-settings__instructions">
          <p>To connect iTero Scanner with InBrace Lab you will need to:</p>
          <ol className="itero-settings__instructions__list">
            <li>
              Contact iTero Lab Support at{' '}
              <a href={`tel:${ITERO_SUPPORT_PHONE}`} className="itero-link">
                {ITERO_SUPPORT_PHONE_DISPLAY}
              </a>
              , option 4. You will need your iTero Base Serial Number and Office ID which can be found on your scanner’s system information screen, and the{' '}
              <span className="itero-bold">InBrace Lab ID 93064</span>.
            </li>
            <li>
              <p>
                Once the call is complete, return to "Settings Menu" on the iTero Scanner and press <span className="itero-bold">"Sync Configuration"</span>.
              </p>
              <p>
                <span className="itero-bold">Note:</span> For additional support, click {/* eslint-disable-next-line */}
                <a className="itero-link" onClick={handleInstructionsPdfModal}>
                  here
                </a>{' '}
                to view the complete instructions.
              </p>
            </li>
            <li>Add provider's license number on the field below. More than one scanner may be added.</li>
          </ol>
          <p>Once all steps are completed, scans sent to the InBrace Lab can be uploaded directly in the portal.</p>
        </div>
        <form className="itero-settings__add-form" onSubmit={handleLicenseNumberSubmit}>
          <label className="input-container-label">Add Provider's License Number associated to iTero Scanner</label>
          <div className="input-container">
            <input
              className={getAddLicenseNumberInputClass()}
              type="text"
              placeholder="Provider's License Number"
              value={newLicenseNumber}
              onChange={handleLicenseNumberChange}
              onFocus={handleLicenseNumberInputFocus}
              disabled={isLicenseNumberSubmitInputDisabled}
            />
            <button type="submit" className="input-container__button btn btn--primary" disabled={isLicenseNumberSubmitDisabled}>
              {isCreateLicenseNumberPending ? (
                <div className="loader-container">
                  <CircleLoader size={'small'} withSpacing={false} />
                </div>
              ) : (
                'Add'
              )}
            </button>
          </div>
          {licenseNumberError ? <span className="error-message">{licenseNumberErrorMessage}</span> : null}
        </form>
        <div className={getLicenseNumbersClass()}>
          <div className="list-container pad-sm">
            {isFetchLicenseNumbersPending || isUpdateLicenseNumberPending ? (
              <div className="loader-container">
                <CircleLoader />
              </div>
            ) : (
              <>
                <div className={getListTitleTableClass()}>
                  <div className="title border-bottom padding-bottom">Provider's License Number</div>
                </div>
                <div className="list">
                  {licenseNumbers.map((licenseNumberObj, index) => (
                    <div key={licenseNumberObj.id} className={getItemClass(index)}>
                      <div key={licenseNumberObj.id} className={getLicenseNumberItemClass()} onClick={getLicenseNumberItemClickHandler(licenseNumberObj)}>
                        {isEditing && isSelectedLicenseNumber(licenseNumberObj) ? (
                          <>
                            <input
                              className={getEditLicenseNumberInputClass()}
                              type="text"
                              value={selectedLicenseNumberValue}
                              onChange={handleEditLicenseNumberChange}
                              disabled={isEditLicenseNumberInputDisabled}
                            />
                            {selectedLicenseNumberError && <div className="error-message">{selectedLicenseNumberErrorMessage}</div>}
                          </>
                        ) : (
                          <div
                            className={getLicenseNumberClass(licenseNumberObj)}
                            onClick={getLicenseNumberItemClickHandler(licenseNumberObj)}
                            onDoubleClick={getLicenseNumberItemDoubleClickHandler(licenseNumberObj)}
                          >
                            {licenseNumberObj.license_number} {licenseNumberObj.doctor_name ? <span>(Dr. {licenseNumberObj.doctor_name})</span> : null}
                          </div>
                        )}
                      </div>
                      <div key={`${licenseNumberObj.id}-actions`} className={getActionsItemClass(licenseNumberObj)}>
                        <div className={getActionsClass(licenseNumberObj)}>
                          {isEditing ? (
                            <ActionButton className={'save'} tooltip={'Save'} onClick={handleSaveActionClick} disabled={isEditLicenseNumberSaveDisabled}>
                              <i className="fa fa-check" aria-hidden="true" />
                            </ActionButton>
                          ) : (
                            <ActionButton className={'edit'} tooltip={'Edit'} onClick={getEditActionClickHandler(licenseNumberObj)}>
                              <i className="fa fa-pencil" aria-hidden="true" />
                            </ActionButton>
                          )}
                          <ActionButton className={'delete'} tooltip={'Delete'} onClick={handleDeleteActionClick} disabled={isDeleteLicenseNumberDisabled}>
                            <FontAwesomeIcon icon={['fas', 'trash']} aria-hidden="true" />
                          </ActionButton>
                          <ActionButton className={'cancel'} tooltip={'Cancel'} onClick={handleCancelActionClick}>
                            <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                          </ActionButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <SimpleModal show={showDeleteWarning} containerclassName="smile-design-modal" theme="error">
        <SimpleModal.Header title={'Delete License Number'} onClose={handleCloseDeleteWarning} />
        <SimpleModal.ContentBody>
          <p>You are about to delete the license number{selectedLicenseNumber ? ` ${selectedLicenseNumber.license_number}` : ''}, are you sure?</p>
        </SimpleModal.ContentBody>
        <SimpleModal.FooterWithButtons
          theme="error"
          onClickConfirm={handleConfirmDelete}
          onClickCancel={handleCancelDelete}
          confirmDisabled={isDeleteLicenseNumberPending}
          cancelDisabled={isDeleteLicenseNumberPending}
          confirmButtonText={'Yes'}
          cancelButtonText={'No'}
        />
      </SimpleModal>
      <PdfModal
        show={showInstructionsPdfModal}
        onClose={handleInstructionsPdfModal}
        pdfUrl={account.itero_instructions_file_path}
        fileName="How to upload Toothprints directly from iTero Scanner"
        title="How to upload Toothprints directly from iTero Scanner"
      />
      {refresh && <SessionExpire />}
    </>
  );
};

const mapStateToProps = (state) => ({
  account: getAccountSetting(state),
  isCreateLicenseNumberPending: getCreateLicenseNumberPending(state),
  isDeleteLicenseNumberPending: getDeleteLicenseNumberPending(state),
  isUpdateLicenseNumberPending: getUpdateLicenseNumberPending(state),
  isFetchLicenseNumbersPending: getFetchLicenseNumbersPending(state),
  licenseNumbers: getLicenseNumbers(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLicenseNumber,
      deleteLicenseNumber,
      updateLicenseNumber,
      fetchLicenseNumbers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IteroSettings);
