import React from 'react';
import HistoryListItem from './history_list_item';
import HistoryListHeading from './history_list_heading';
import { SETUP_PREFIX } from '../../../common/functions';

/**
 * The container of the entire history list of setups
 * @component
 * @alias SetupViewerHistoryList
 */
export default function HistoryList(props) {
  const history = props.history_list.filter((item) => item.text.indexOf(SETUP_PREFIX) !== -1);
  return (
    <div className={props.collapse ? 'history invisible' : 'history'}>
      <div className="panel-menu-surrounding--no-top">
        <HistoryListHeading title={`${SETUP_PREFIX}History`} />
      </div>
      <div className="history-area">
        {history.map((item) => (
          <HistoryListItem key={item.text} item={item} split={props.split} onClick={props.onScrollOptionClick} pane={1} history={history} />
        ))}
      </div>
    </div>
  );
}
