import React from 'react';
import PropTypes from 'prop-types';

const types = ['default', 'subfilter'];

/**
 * Displays a single filter item
 * @component
 */
function FilterItem({ active, className, type, ...props }) {
  const typeClassName = types.includes(type) ? type : types[0];
  const activeClassName = active ? ' filter__item--active' : '';
  const classNames = `${className ? className : ''} filter__item filter__item--${typeClassName}${activeClassName}`;
  return <div className={classNames} {...props} />;
}

FilterItem.propTypes = {
  active: PropTypes.bool,
  type: PropTypes.string,
};

FilterItem.defaultProps = {
  active: false,
  type: types[0],
};

export default FilterItem;
