import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../../../../../loader/circle_loader';
import { recordStatesToCssStyle } from '../../../../../../common/functions';

/**
 * Displays a single record thumbnail for setup viewer
 * @component
 */
function PaneViewerRecordThumbnail(props) {
  const { record, pane, onLoad, onClick } = props;
  const active = record[`pane_${pane}`].active;

  useEffect(() => {
    if (record.loading) {
      onLoad(record.id, record.image_url);
    }
  }, [record, onLoad]);

  return (
    <div
      className={active ? 'records-slider-thumbnail active' : 'records-slider-thumbnail'}
      id={record.id}
      data-id={record.id}
      data-pane={pane}
      onClick={!active && !record.loading ? onClick : null}
    >
      {record.loading ? (
        <CircleLoader />
      ) : (
        <img
          src={record.src}
          style={{
            transform: recordStatesToCssStyle(record.pane_1),
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
          draggable="false"
          alt="thumbnail"
        />
      )}
    </div>
  );
}

PaneViewerRecordThumbnail.propTypes = {
  record: PropTypes.object.isRequired,
  pane: PropTypes.number.isRequired,
  onLoad: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default PaneViewerRecordThumbnail;
