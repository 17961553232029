import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import PaneViewerRecordsSlider from './pane_viewer_records_slider';
import PaneViewerRecordsImage from './pane_viewer_records_image';
import PaneViewerRecordsControls from './pane_viewer_records_controls';
import PaneViewerTXPlan from './pane_viewer_txplan';
import STLViewer from '../../../../../stl_viewer/STLViewer';
import { initialPosition } from '../../../../../../doctor/components/record_viewer/image_viewer';
import PaneViewerNavigateControls from './pane_viewer_navigate_controls';
import { isDropboxURL } from '../../../../../../common/helpers';

function getActiveRecord(image_records, pane) {
  let i = 0;
  while (i < image_records.length) {
    if (image_records[i][`pane_${pane}`].active) break;
    i++;
  }
  return i < image_records.length ? [image_records[i], i] : [null, 0];
}

/**
 * Displays the records viewer
 * @component
 */
function PaneViewerRecords(props) {
  const { onImageZoomPlus, onImageSliderClick, image_records, onImageZoomMinus, onImageReset, pane } = props;
  const [position, setPosition] = useState(initialPosition);

  const zoomIn = useCallback((e) => onImageZoomPlus(pane, pane), [onImageZoomPlus, pane]);
  const zoomOut = useCallback((e) => onImageZoomMinus(pane, pane), [onImageZoomMinus, pane]);
  const reset = useCallback(
    (record) => {
      onImageReset(pane, record);
      setPosition(initialPosition);
    },
    [pane, onImageReset, setPosition]
  );
  const onClick = useCallback(
    (index) => {
      onImageSliderClick(pane, index);
      const thumbnails = window.$(`.records-slider-thumbnail`);
      if (thumbnails && thumbnails.length >= 1) thumbnails[index].scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
    },
    [pane, onImageSliderClick]
  );

  const [record_active, i] = getActiveRecord(image_records, pane);

  let record;
  if (!record_active) {
    record = null;
  } else if (record_active.type === 'txplan') {
    record = <PaneViewerTXPlan pane={pane} />;
  } else if (record_active.type === 'photos' || record_active.type === 'xrays') {
    record = (
      <>
        <PaneViewerRecordsImage pane={pane} record={record_active} position={position} setPosition={setPosition} zoomIn={zoomIn} zoomOut={zoomOut} />
        <PaneViewerRecordsControls
          pane={pane}
          record={record_active}
          onImageZoomPlusMouseDown={props.onImageZoomPlusMouseDown}
          onImageZoomMinusMouseDown={props.onImageZoomMinusMouseDown}
          onImageZoomMouseUp={props.onImageZoomMouseUp}
          onImageZoomChange={props.onImageZoomChange}
          onImageRotateClick={props.onImageRotateClick}
          onImageFlipUDClick={props.onImageFlipUDClick}
          onImageFlipLRClick={props.onImageFlipLRClick}
          reset={() => reset(record_active)}
        />
      </>
    );
  } else {
    record = (
      <div className="records-image">
        {record_active.file_url ? (
          <STLViewer
            model={record_active.file_url}
            urls={[record_active.file_url, isDropboxURL(record_active.image_url)]}
            width="100%"
            height="100%"
            modelColor="#bbbbbb"
            modelBackColor="#9bc6f2"
            backgroundColor="#2a3033"
            rotate={false}
            orbitControls={false}
            trackBallControls={true}
            split={props.split}
          />
        ) : (
          <img
            className="fs-exclude"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            draggable="false"
            src={record_active.src}
            alt={record_active.image_url}
          />
        )}
      </div>
    );
  }

  const hasPrev = i > 0 && !image_records[i - 1].loading;
  const hasNext = i < image_records.length - 1 && !image_records[i + 1].loading;

  return (
    <div className="pane-viewer-records">
      <PaneViewerNavigateControls
        showLeftButton={hasPrev}
        showRightButton={hasNext}
        pane={pane}
        leftButtonOnClick={() => onClick(i - 1)}
        rightButtonOnClick={() => onClick(i + 1)}
      />
      {record}
      <PaneViewerRecordsSlider pane={pane} records={image_records} onClick={onClick} onLoad={props.onFetchRecordImage} />
    </div>
  );
}

PaneViewerRecords.propTypes = {
  image_records: PropTypes.array.isRequired,
  pane: PropTypes.number.isRequired,
  onImageZoomChange: PropTypes.func.isRequired,
  onImageZoomMinusMouseDown: PropTypes.func.isRequired,
  onImageZoomPlusMouseDown: PropTypes.func.isRequired,
  onImageZoomMouseUp: PropTypes.func.isRequired,
  onImageRotateClick: PropTypes.func.isRequired,
  onImageFlipLRClick: PropTypes.func.isRequired,
  onImageFlipUDClick: PropTypes.func.isRequired,
  onImageSliderClick: PropTypes.func.isRequired,
  onFetchRecordImage: PropTypes.func.isRequired,
};

export default PaneViewerRecords;
