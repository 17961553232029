import Axios from 'axios';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { closeHelpSidebar } from '../../redux/actions/ipp/help_sidebar';
import { fetchDoctorInfo } from '../../redux/actions/ipp/dashboard';
import { getDoctorInfo } from '../../redux/reducers/ipp/dashboard';
import { getDoctorsIdFromRoute } from '../../common/route';
import { INBRACE_LEARNING_URL } from '../../common/constants';
import { getShowHelpSidebar } from '../../redux/reducers/ipp/help_sidebar';
import { openFeedbackForm } from '../../redux/actions/ipp/feedback_form';
import { userHasPermissions } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { NAVBAR_HEIGHT } from './nav_bar';

const INBRACE_MARKETING_URL = 'https://get.inbrace.com';
const SUPPORT_EMAIL = 'support@inbrace.com';
const SUPPORT_PHONE = '8772588677';
const SUPPORT_PHONE_DISPLAY = '(877) 258-8677';
const CALENDLY_URL = 'https://calendly.com/inbraceclinicalreview/30min';

const SIDEBAR_FULLWIDTH_BREAKPOINT = 768;

const HelpSidebar = ({ showHelpSidebar, doctorInfo, fetchDoctorInfo, closeHelpSidebar, openFeedbackForm }) => {
  const [doctor_id, setDoctorId] = React.useState(doctorInfo?.doctor_id);
  const [sidebarWidth, setSidebarWidth] = React.useState('0px');

  const getTISEmail = () => {
    if (doctorInfo && doctorInfo.tis) {
      return doctorInfo.tis.email;
    }
    return SUPPORT_EMAIL;
  };

  const getTISName = () => {
    if (doctorInfo && doctorInfo.tis) {
      return `${doctorInfo.tis.first_name} ${doctorInfo.tis.last_name}`.toUpperCase();
    }
    return 'SUPPORT';
  };

  const getTISHeadshot = () => {
    if (doctorInfo && doctorInfo.tis) {
      return doctorInfo.tis.s3_url_headshot;
    }
    return null;
  };

  const getBGCEmail = () => {
    if (doctorInfo && doctorInfo.bgc) {
      return doctorInfo.bgc.email;
    }
    return SUPPORT_EMAIL;
  };

  const getBGCName = () => {
    if (doctorInfo && doctorInfo.bgc) {
      return `${doctorInfo.bgc.first_name} ${doctorInfo.bgc.last_name}`.toUpperCase();
    }
    return 'SUPPORT';
  };

  const getBGCHeadshot = () => {
    if (doctorInfo && doctorInfo.bgc) {
      return doctorInfo.bgc.s3_url_headshot;
    }
    return null;
  };

  const userCanSeeKnowledgeBase = (permissions) => {
    return userHasPermissions(['KNOWLEDGEBASE_VIEW', 'IPP_VIEW'], permissions);
  };

  const userCanSubmitFeedback = (permissions) => {
    return userHasPermissions(['IPP_EDIT'], permissions);
  };

  const getGiveYourFeedbackClassName = (permissions) => {
    const classNames = ['give-your-feedback-action'];
    if (!userCanSubmitFeedback(permissions)) {
      classNames.push('give-your-feedback-action--disabled');
    }
    return classNames.join(' ');
  };

  const getHandleGiveFeedbackClick = (permissions) => () => {
    if (!userCanSubmitFeedback(permissions)) {
      return;
    }
    openFeedbackForm();
    closeHelpSidebar();
  };

  const sidebarIsFullWidth = () => {
    return window.visualViewport.width < SIDEBAR_FULLWIDTH_BREAKPOINT;
  };

  const getSidebarWidth = useCallback(() => {
    if (sidebarIsFullWidth()) {
      return '100%';
    }
    return '300px';
  }, []);

  const handleDynamicSidebarWidth = useCallback(() => {
    setSidebarWidth(getSidebarWidth());
  }, [getSidebarWidth]);

  const handleWindowResize = useCallback(() => {
    handleDynamicSidebarWidth();
  }, [handleDynamicSidebarWidth]);

  React.useEffect(() => {
    setSidebarWidth(getSidebarWidth());

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [getSidebarWidth, handleWindowResize]);

  React.useEffect(() => {
    if (doctor_id) {
      fetchDoctorInfo(doctor_id);
    } else {
      const doctor_id = getDoctorsIdFromRoute();
      if (doctor_id) {
        setDoctorId(doctor_id);
      } else {
        Axios.get(`/apiV2/account_light`).then(function (res) {
          if (res.data && res.data.role !== 'Business') {
            setDoctorId(res.data.account_id);
          }
        });
      }
    }
  }, [doctor_id, fetchDoctorInfo, setDoctorId]);

  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => (
        <div
          className="help-sidebar"
          onClick={() => closeHelpSidebar()}
          style={{
            transform: showHelpSidebar ? 'translateX(0)' : `translateX(calc(100vh + ${sidebarWidth}))}`,
            opacity: showHelpSidebar ? '1' : '0',
            zIndex: showHelpSidebar ? '1000' : '-1000',
            width: showHelpSidebar ? '100%' : '0',
            top: `${NAVBAR_HEIGHT}px`,
          }}
        >
          <aside
            className="help-sidebar__aside"
            onClick={(e) => e.stopPropagation()}
            style={{
              transform: showHelpSidebar ? 'translateX(0)' : `translateX(calc(100vh + ${sidebarWidth}))}`,
              opacity: showHelpSidebar ? '1' : '0',
              zIndex: showHelpSidebar ? '1000' : '-1000',
              width: showHelpSidebar ? `${sidebarWidth}` : '0',
            }}
          >
            {sidebarIsFullWidth() ? (
              <div className="aside__row aside__row--header-fullwidth">
                <div className="row__header">
                  <span className="header-text header-text--fullwidth" onClick={() => closeHelpSidebar()}>
                    <KeyboardBackspaceIcon className="header__go-back-icon" />
                    Go Back
                  </span>
                </div>
              </div>
            ) : null}

            <div className="aside__row aside__row--first">
              <div className="row__header">
                <span className="header-text">
                  LINKS
                  {!sidebarIsFullWidth() ? <FontAwesomeIcon icon={['fas', 'times']} className="header__close-icon" onClick={() => closeHelpSidebar()} /> : null}
                </span>
              </div>
              <div className="row__content">
                {userCanSeeKnowledgeBase(user_roles_and_permissions.permissions) ? (
                  <Link to="/portal/knowledgebase" target={'_blank'} rel="noopener noreferrer" onClick={() => closeHelpSidebar()}>
                    <div className="content__link">
                      <span className="link__text">Knowledge Base</span>
                      <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </div>
                  </Link>
                ) : null}
                <a href={INBRACE_LEARNING_URL} target={'_blank'} rel="noopener noreferrer" onClick={() => closeHelpSidebar()}>
                  <div className="content__link">
                    <span className="link__text">InBrace Courses</span>
                    <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                  </div>
                </a>
                <a href={INBRACE_MARKETING_URL} target={'_blank'} rel="noopener noreferrer" onClick={() => closeHelpSidebar()}>
                  <div className="content__link">
                    <span className="link__text">InBrace Marketing</span>
                    <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                  </div>
                </a>
              </div>
            </div>

            <div className="aside__row">
              <div className="row__content">
                <div className="content__specialist">
                  {getTISHeadshot() ? (
                    <div>
                      <img src={getTISHeadshot()} />
                    </div>
                  ) : null}
                  <div className={`content__specialist-info ${getTISHeadshot() ? 'left-padding' : ''}`}>
                    <p className="content__specialist-title">TECHNOLOGY INTEGRATION SPECIALIST</p>
                    <p className="content__specialist-name">{getTISName()}</p>
                  </div>
                </div>
                <a href={`mailto:${getTISEmail()}`} onClick={() => closeHelpSidebar()}>
                  <p className="content__email">{getTISEmail()}</p>
                </a>
              </div>
            </div>

            <div className="aside__row">
              <div className="row__content">
                <div className="content__specialist">
                  {getBGCHeadshot() ? (
                    <div>
                      <img src={getBGCHeadshot()} />
                    </div>
                  ) : null}
                  <div className={`content__specialist-info ${getBGCHeadshot() ? 'left-padding' : ''}`}>
                    <p className="content__specialist-title">BUSINESS GROWTH CONSULTANT</p>
                    <p className="content__specialist-name">{getBGCName()}</p>
                  </div>
                </div>
                <a href={`mailto:${getBGCEmail()}`} onClick={() => closeHelpSidebar()}>
                  <p className="content__email">{getBGCEmail()}</p>
                </a>
              </div>
            </div>

            <div className="aside__row">
              <div className="row__content">
                <p className="content__title">SUPPORT</p>
                <a href={`tel:${SUPPORT_PHONE}`} onClick={() => closeHelpSidebar()}>
                  <p className="content__phone">{SUPPORT_PHONE_DISPLAY}</p>
                </a>
                <a href={`mailto:${SUPPORT_EMAIL}`} onClick={() => closeHelpSidebar()}>
                  <p className="content__email">{SUPPORT_EMAIL}</p>
                </a>
              </div>
            </div>

            <div className="aside__row">
              <div className="row__content">
                <p className="content__title">CLINICAL CASE REVIEW</p>
                <a href={CALENDLY_URL} target={'_blank'} rel="noopener noreferrer" onClick={() => closeHelpSidebar()}>
                  <div className="content__link">
                    <span className="link__text">Schedule an Appointment</span>
                    <i className="fa fa-calendar-o" aria-hidden="true"></i>
                  </div>
                </a>
              </div>
            </div>
            <div className="aside__row aside__row--last">
              <div className="row__content">
                <p className="content__title">FEEDBACK</p>
                <div
                  className={getGiveYourFeedbackClassName(user_roles_and_permissions.permissions)}
                  onClick={getHandleGiveFeedbackClick(user_roles_and_permissions.permissions)}
                >
                  {!userCanSubmitFeedback(user_roles_and_permissions.permissions) ? <i className="fa fa-lock lock_icon"></i> : null}
                  <p className="content__action">Give your feedback</p>
                </div>
              </div>
            </div>
          </aside>
        </div>
      )}
    </UserPermissionsContext.Consumer>
  );
};

const mapStateToProps = (state) => {
  return {
    doctorInfo: getDoctorInfo(state),
    showHelpSidebar: getShowHelpSidebar(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDoctorInfo,
      closeHelpSidebar,
      openFeedbackForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HelpSidebar);
