const smartwireRadioElement = (number, is_de = false) => {
  const label = is_de ? `DE Smartwire ${number}` : `Smartwire ${number}`;
  const id = is_de ? 4 + number : number;
  return {
    id: id,
    radio_label: label,
    radio_checked: false,
    order: 0,
    showDate: true,
    date: {
      id: 1,
      question_text: 'Date of wire insertion:',
      form_text: 'INSERTION Date:',
      input_type: 'date',
      answer_text: '',
      input_placeholder: 'mm/dd/yyyy',
      answer_boolean: false,
      order: 0,
      error: false,
      error_label: '',
      active: true,
    },
  };
};

const smartwireRadioButtons = () => {
  const nums = Array.from(Array(4).keys());
  const wires = nums.map((num) => smartwireRadioElement(num, false));
  const de_wires = nums.map((num) => smartwireRadioElement(num, true));
  const none_button = {
    id: 8,
    radio_label: 'None',
    radio_checked: true,
    order: 15,
  };
  return wires.concat(de_wires).concat(none_button);
};

export const TreatmentDataGen2 = {
  name: 'Treatment Details',
  version: 1.0,
  releaseDate: '2020-03-10',
  case: {
    case_id: '',
    doctor_name: '',
    patient_name: '',
    sex: '',
    dob: '',
  },
  questions: [
    {
      id: 0,
      question_text: 'I am submitting...',
      form_text: 'I Am Submitting...',
      input_type: 'radio_button',
      answer_text: 'Initial Records',
      answer_boolean: false,
      order: 0,
      error: false,
      error_label: '',
      active: true,
      optional: false,
      display_in_summary: true,
      display_in_pdf: false,
      friendly_name: 'submission_type',
      radio_button: [
        {
          id: 1,
          radio_label: 'Initial Records',
          radio_checked: true,
          order: 0,
        },
        {
          id: 2,
          radio_label: 'Progress Records',
          radio_checked: false,
          order: 5,
        },
        {
          id: 3,
          radio_label: 'Final Records',
          radio_checked: false,
          order: 10,
        },
      ],
    },
    {
      id: 2,
      question_text: '',
      input_type: 'multiple_question',
      answer_text: '',
      answer_boolean: false,
      format: 'de_format',
      order: 100,
      error: false,
      error_label: '',
      active: true,
      optional: false,
      display_in_summary: true,
      display_in_pdf: false,
      friendly_name: 'pr_dates',
      multiple_question: [
        {
          id: 3,
          header_text: 'Date Progress Records were taken (most recent appointment)',
          question_text: 'Date Progress Records were taken (most recent appointment)*',
          form_text: 'Date Progress Records Were Taken',
          input_placeholder: 'mm/dd/yyyy',
          input_type: 'date',
          answer_text: '',
          answer_boolean: false,
          order: 0,
          error: false,
          error_label: '',
          active: true,
          display_in_summary: true,
          display_in_pdf: false,
          friendly_name: 'pr_date_taken',
          optional: false,
          errorFieldName: 'Progress Records Date',
        },
        {
          id: 4,
          header_text: 'Date of next scheduled appointment',
          question_text: 'Date of next scheduled appointment',
          form_text: 'Date Of Next Scheduled Appointment',
          input_placeholder: 'mm/dd/yyyy',
          input_type: 'date',
          answer_text: '',
          answer_boolean: false,
          order: 100,
          error: false,
          error_label: '',
          active: true,
          display_in_summary: true,
          display_in_pdf: false,
          friendly_name: 'next_scheduled_appt',
          optional: true,
        },
      ],
    },
    {
      id: 5,
      question_text: 'Which Smartwire(s) is the patient currently wearing?',
      input_type: 'multiple_question',
      answer_text: '',
      answer_boolean: false,
      order: 200,
      error: false,
      error_label: '',
      active: true,
      optional: true,
      display_in_summary: true,
      display_in_pdf: true,
      friendly_name: 'smartwire_type',
      multiple_question: [
        {
          id: 3,
          question_text: 'Upper Smartwire:',
          form_text: 'Current Upper Smartwire',
          input_type: 'radio_button',
          answer_text: 'None',
          answer_boolean: false,
          order: 0,
          error: false,
          error_label: '',
          active: true,
          optional: true,
          display_in_summary: true,
          display_in_pdf: false,
          friendly_name: 'upper_smartwire_type',
          hasCentralizedDate: true,
          centralizedDate: {
            id: 1,
            question_text: 'Date of wire insertion:',
            form_text: 'INSERTION Date:',
            input_type: 'date',
            answer_text: '',
            input_placeholder: 'mm/dd/yyyy',
            answer_boolean: false,
            order: 0,
            error: false,
            error_label: '',
            active: true,
            isCentralized: true,
          },
          radio_button: smartwireRadioButtons(),
        },
        {
          id: 4,
          question_text: 'Lower Smartwire:',
          form_text: 'Current Lower Smartwire',
          input_type: 'radio_button',
          answer_text: 'None',
          answer_boolean: false,
          order: 100,
          error: false,
          error_label: '',
          active: true,
          display_in_summary: true,
          display_in_pdf: true,
          optional: true,
          friendly_name: 'lower_smartwire_type',
          hasCentralizedDate: true,
          centralizedDate: {
            id: 1,
            question_text: 'Date of wire insertion:',
            form_text: 'INSERTION Date:',
            input_type: 'date',
            answer_text: '',
            input_placeholder: 'mm/dd/yyyy',
            answer_boolean: false,
            order: 0,
            error: false,
            error_label: '',
            active: true,
            isCentralized: true,
          },
          radio_button: smartwireRadioButtons(),
        },
      ],
    },
    {
      id: 6,
      question_text: 'Treatment notes from most recent appointment',
      form_text: 'Treatment Notes',
      input_type: 'text_area',
      input_placeholder: '',
      answer_text: '',
      answer_boolean: false,
      order: 300,
      active: true,
      error_label: '',
      multiple_questions: false,
      error: false,
      display_in_summary: true,
      display_in_pdf: false,
      friendly_name: 'treatment_notes',
      optional: true,
    },
    {
      id: 7,
      question_text: 'Would you like to request Clinical Support?',
      form_text: 'Would you like to request Clinical Support?',
      input_type: 'radio_button',
      answer_text: 'No',
      answer_boolean: false,
      order: 400,
      error: false,
      error_label: '',
      active: true,
      display_in_summary: true,
      display_in_pdf: false,
      optional: false,
      friendly_name: 'csr_required',
      radio_button: [
        {
          id: 1,
          radio_label: 'Yes',
          radio_checked: false,
          order: 0,
        },
        {
          id: 2,
          radio_label: 'No',
          radio_checked: true,
          order: 5,
        },
      ],
    },
  ],
};
