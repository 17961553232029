/**
 * File:teeth_selector.js - Component to return common teeth selector.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';
import BtnGroup from './button_group';
import _ from 'lodash';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

/**
 * Displays the entire teeth selector buttons
 * @component
 */
class TeethSelector extends Component {
  state = {
    disabled: new Set(),
    selected: new Set(),
  };

  static defaultProps = {
    hasUpper: true,
    hasLower: true,
    selected: [],
    disabled: [],
  };

  static getDerivedStateFromProps(props, state) {
    return {
      disabled: new Set(props.disabled),
      selected: new Set(props.selected),
    };
  }

  /**
   * Create a button group
   * @function
   * @param {Array} buttons - The list of strings for buttons
   * @param {Boolean} enabled - Whether this group is enabled
   * @return {JSX} The button group
   */
  makeGroup(buttons, enabled) {
    const disabled = this.props.disabledEdit ? Teeth.All : this.state.disabled;

    return (
      <div className="col-md-6">
        <BtnGroup
          buttons={buttons}
          enabled={enabled}
          disabled={disabled}
          selected={this.state.selected}
          onClick={this.props.onClick}
          dataTestIdPrefix={this.props.dataTestIdPrefix || 'teeth_selector'}
        />
      </div>
    );
  }

  render() {
    const upperHTML = (
      <div className="row">
        {this.makeGroup(Teeth.UR, this.props.hasUpper)}
        {this.makeGroup(Teeth.UL, this.props.hasUpper)}
      </div>
    );
    const lowerHTML = (
      <div className="row">
        {this.makeGroup(Teeth.LR, this.props.hasLower)}
        {this.makeGroup(Teeth.LL, this.props.hasLower)}
      </div>
    );

    return (
      <div className="teeth-selector">
        {upperHTML}
        {lowerHTML}
      </div>
    );
  }
}

export default TeethSelector;
