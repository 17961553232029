import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import NotFound from '../../../../doctor/404/not_found';
import { Container, Typography, Grid, Button, Box, CircularProgress } from '@material-ui/core';
import { onDownloadFile } from '../../../../common/dropbox';
import SessionExpire from '../../../../common/session_expire';
import Modal from '../../../../components/modal/modal';

import ManualWireTable from './manual_wire_table/manual_wire_table';
import ManualWireProgram from './manual_wire_program';
import ManualWireArch from './manual_wire_arch';
import ManualWireAuto from './manual_wire_auto';
import ManualWireSmartwire from './manual_wire_smartwire';
import ManualWireError from './manual_wire_error/manual_wire_error';
import {
  generateManualWireJSON,
  getCaseDataFromCustomWireGeneration,
  getCaseDataFromManualAutomationStatus,
  getWireNamesOptions,
  getFilteredIndexes,
  prepareStateWithCaseData,
} from './manual_wire_utils';
import { wireOptions } from './manual_wire_options';
import {
  saveDraft,
  setDefaultLoop,
  setDefaultLocket,
  setArchOptions,
  setCaseData,
  setDefaultCaseData,
  setManualWireArch,
  setManualWireAuto,
  setManualWireWire,
  setMissingTeeths,
  setSmartwireOptions,
  setManualWireError,
  addManualWireError,
  setTeethRange,
  setCustomData,
  setRefresh,
} from '../../../../redux/actions/manual_wire/manual_wire';
import { getHasErrors } from '../../../../redux/reducers/manual_wire/manual_wire';

import './manual_wire.scss';
import ManualWireCustomNotes from './manual_wire_custom_notes/manual_wire_custom_notes';

import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

const ERROR_KEYS = ['collision', 'over_rotated', 'height'];

const ManualWire = ({
  manualWireReducer,
  arch,
  auto,
  smartwire,
  program,
  teeths,
  hasError,
  errorMessage,
  archOpts,
  smartwireOpts,
  refresh,
  location,
  match,
  setDefaultCaseData,
  setCaseData,
  setWire,
  setArch,
  setAuto,
  setMissing,
  setRange,
  setManualWireError,
  addManualWireError,
  saveDraft,
  setDefaultLoop,
  setDefaultLocket,
  setArchOptions,
  setSmartwireOptions,
  setCustomData,
  setRefresh,
}) => {
  const caseId = match.params.case_id;
  const caseIdDisplay = caseId.endsWith('-DE1') ? caseId.replace('-DE1', '-DE') : caseId;
 
  const [notFoundError, setNotFoundError] = useState(false);
  const [caseInitialData, setCaseInitialData] = useState(null);
  const [caseInitialRange, setCaseInitialRange] = useState(null);
  const [caseInitialMissing, setCaseInitialMissing] = useState(null);
  const [customWireData, setCustomWireData] = useState(null);
  const [status, setStatus] = useState('');
  const [disableAllFields, setDisableAllFields] = useState(false);
  const [dxfPath, setDxfPath] = useState('');
  const [bmfCsvUrl, setBmfCsvUrl] = useState('');
  const [fileName, setFileName] = useState('');
  const [customNotes, setCustomNotes] = useState([]);
  const [allowGenerate, setAllowGenerate] = useState(true);
  const [showGeneratedLentgh, setShowGeneratedLentgh] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmBtnText, setConfirmBtnText] = useState('');
  const [onConfirmButtonClick, setOnConfirmButtonClick] = useState(() => () => {});


  const user_roles_and_permissions = useContext(UserPermissionsContext);
  const hasManualLokiPermission = userHasPermission('CASE_MANUAL_LOKI', user_roles_and_permissions.permissions);
  if (!notFoundError && !hasManualLokiPermission) {
    setNotFoundError(true);
  }

  const queryParams = new URLSearchParams(location.search);
  let wireFromPath = queryParams.get('smartwire');
  let archFromPath = queryParams.get('arch');
  if (!['initial', 'intermediate', 'final'].includes(wireFromPath)) {
    wireFromPath = '';
    if (!notFoundError) setNotFoundError(true);
  }
  if (!['upper', 'lower'].includes(archFromPath)){
    archFromPath = '';
    if (!notFoundError) setNotFoundError(true);
  }

  const archValueLabels = {
    upper: 'Upper',
    lower: 'Lower',
  };

  const getPageTitle = () => {
    if (arch === '') return '';

    const archLabel = archValueLabels[arch];
    const smartwireIndex = wireOptions.findIndex((wire) => wire.value === smartwire);
    const smartwireNumber = wireOptions[smartwireIndex].label.replace('SW', '');
    return `${archLabel} Smartwire ${smartwireNumber}`;
  };

  /**
   * Sets the page values for the case details.
   * 
   * @param {Object} caseData - The case data object.
   * @param {string} range - The range value.
   * @param {number} missingTeeths - The number of missing teeth.
   * @param {boolean} auto - The auto value.
   */
  const setPageValues = (selectedArch, defaultCaseData, caseData, range, missingTeeths, auto) => {
    setDefaultCaseData(selectedArch, defaultCaseData);
    setCaseInitialRange(range);
    setCaseInitialMissing(missingTeeths);
    setCaseInitialData(caseData);
    setMissing(selectedArch, missingTeeths);
    setRange(selectedArch, range);
    setAuto(auto);
  }

  /**
   * Primary function for fetching and setting manual wire generation state
   */
  const fetchManualWireData = async (selectedArch, selectedWire, initial = false) => {
    Axios.get(`/apiv3/manualwire/${caseId}?arch=${selectedArch}&wire=${selectedWire}`)
      .then((response) => {
        const {
          manual_automation_status: manualAutomationStatus,
          manual_wire_data: manualWireData,
          download_url: downloadUrl,
          bmf_csv_url: bmfCsvUrl,
          generation_status: status,
          filename,
          error_json: generationErrorData,
          wire_names: wireNames,
          status_comment: statusComment,
        } = response.data;

        setArch(selectedArch);
        setWire(selectedWire);

        setCustomNotes('');
        setManualWireError(false, []);
        if (status.trim() === 'DXF Generation Failed') addManualWireError([statusComment ?? 'DXF Generation Failed']);

        setBmfCsvUrl(bmfCsvUrl);
        setDxfPath(downloadUrl);
        if (filename) {
          const newFilename = filename.replace('-DE1_', '-DE_');
          setFileName(newFilename);
        } else {
          setFileName('');
        }
        setStatus(status);
        setDisableAllFields(status !== 'No Status');
        if (['No Status'].includes(status)) {
          setAllowGenerate(true);
        }

        if (initial) {
          // set local state for custom wire data
          setCustomWireData(manualWireData);
          // set redux state for custom wire data
          setCustomData(manualWireData);
        }

        if (generationErrorData.length > 0 && generationErrorData[0].errors) {
          setGenerationErrors(generationErrorData[0].errors);
        }

        if (archOpts.length === 0 && smartwireOpts.length === 0) {
          const { archs, smartwires } = getWireNamesOptions(wireNames);
          setArchOptions(archs);
          Object.keys(smartwires).forEach((arch) => {
            setSmartwireOptions(arch, smartwires[arch]);
          });
        }
        
        let currentManualAutomation = manualAutomationStatus.current;
        if (!currentManualAutomation && manualAutomationStatus?.last_generation)
          currentManualAutomation = manualAutomationStatus.last_generation;

        if (!wireNames.includes(`${selectedArch}_${selectedWire}`)) setNotFoundError(true);
        let defaultCaseData, caseData, range, missingTeeths, auto;

        if (currentManualAutomation) {
          let hasGenerated = false;
          ({ caseData, range, missingTeeths, auto, hasGenerated } = getCaseDataFromManualAutomationStatus(currentManualAutomation, selectedArch, selectedWire));
          ({ caseData: defaultCaseData } = getCaseDataFromCustomWireGeneration(manualWireData, selectedArch));
          setShowGeneratedLentgh(hasGenerated)
        } else {
          ({ caseData, range, missingTeeths, auto } = getCaseDataFromCustomWireGeneration(manualWireData, selectedArch));
          defaultCaseData = { ...caseData}
        }
        setPageValues(selectedArch, defaultCaseData, caseData, range, missingTeeths, auto);
        setPageLoaded(true);
      })
      .catch((error) => {
        console.error('Error on fetchManualWireData', error);
        if (error?.response?.status === 404) setNotFoundError(true)
        else addManualWireError([error.message]);
      });
  };

  let dxfCheck;
  useEffect(() => {
    if (status === 'Generation in Progress' || status === 'Ready for Generation') {
      const dxfCheck = setInterval(() => {
        fetchManualWireData(arch, smartwire);
      }, 30000);
      
      return () => {
        clearInterval(dxfCheck);
      };
    }
  }, [status]);

  /**
   * Generates manual wire data and sends a POST request to the server.
   * @async
   * @function generate
   * @returns {Promise<void>}
   */
  const generate = async () => {
    setAllowGenerate(false);
    const json = generateManualWireJSON(caseId, arch, auto, smartwire, program, teeths);

    const data = {
      cdata: JSON.stringify(customWireData),
      settings: JSON.stringify(json),
      arch,
      wire: smartwire,
    };

    setManualWireError(false, []);

    Axios.post(`/apiv3/manualwire/${caseId}/generate`, data)
      .then((res) => {
        fetchManualWireData(arch, smartwire);
      })
      .catch((error) => {
        console.error('Error on handlerGenerate', error);
        if (error.response.status === 409) setRefresh(true)
        else addManualWireError([error.message]);
      })
      .finally(() => {
        setShowConfirmModal(false);
      });
  };

  /**
   * Handles the generate action.
   */
  const handleGenerate = () => {
    setConfirmMessage("Are you sure you want to generate?");
    setConfirmBtnText("Generate");
    setOnConfirmButtonClick(() => generate);
    setShowConfirmModal(true);
  };

  /**
   * Sets default values for the case details.
   */
  const setDefaultValues = () => {
    setDefaultLoop(arch);
    setDefaultLocket(arch);
    const { caseData, range, missingTeeths, auto } = getCaseDataFromCustomWireGeneration(customWireData, arch);
    const defaultCaseData = { ...caseData };
    setPageValues(arch, defaultCaseData, caseData, range, missingTeeths, auto);
    const filteredIndexes = getFilteredIndexes(arch, caseInitialMissing, caseInitialRange, 16);
    const updatedState = prepareStateWithCaseData(manualWireReducer, caseData, arch, filteredIndexes)
    updatedState.auto = false;
    saveDraft(() => {}, updatedState, true);
    setShowConfirmModal(false);
  }

  /**
   * Handles the set default values action.
   */
  const handleSetDefaultValues = () => {
    setConfirmMessage("Are you sure you want to set default values?");
    setConfirmBtnText("Set Default Values");
    setOnConfirmButtonClick(() => setDefaultValues);
    setShowConfirmModal(true);
  };

  /**
   * Retries the manual wire operation.
   * @returns {Promise<void>} A promise that resolves when the retry operation is complete.
   */
  const retry = async () => {
    try {
      await Axios.post(`/apiv3/manualwire/${caseId}/retry`, {
        arch,
        wire: smartwire,
      });
      setCustomNotes([]);
      fetchManualWireData(arch, smartwire);
      setManualWireError(false, []);
    } catch (error) {
      console.error('Error on handleRetry', error);
      if (error.response.status === 409) setRefresh(true)
      else addManualWireError([error.message]);
    } finally {
      setShowConfirmModal(false);
    }
  };

  /**
   * Handles the retry action for generation.
   */
  const handleRetry = () => {
    setConfirmMessage("Are you sure you want to retry the generation?");
    setConfirmBtnText("Retry");
    setOnConfirmButtonClick(() => retry);
    setShowConfirmModal(true);
  };

  /**
   * Handles the download of a manual wire.
   * @async
   * @function handleDownload
   * @returns {Promise<void>} A promise that resolves when the download is complete.
   */
  const handleDownload = async () => {
    Axios.post(`/apiv3/manualwire/${caseId}/download`, {
      arch,
      wire: smartwire,
    })
      .then(() => {
        onDownloadFile(dxfPath);
      })
      .catch((error) => {
        console.error('Error on handleDownload', error);
        if (error.response.status === 409) setRefresh(true)
        else addManualWireError([error.message]);
      })
      .finally(() => {
        setShowConfirmModal(false);
      });
  };

  /**
   * Marks the manual wire as failed.
   * @async
   * @function markAsFailed
   * @returns {Promise<void>}
   */
  const markAsFailed = async () => {
    Axios.post(`/apiv3/manualwire/${caseId}/cancel`, {
      arch,
      wire: smartwire,
    })
      .then(() => {
        fetchManualWireData(arch, smartwire);
      })
      .catch((error) => {
        console.error('Error on markAsFailed', error);
        if (error.response.status === 409) setRefresh(true)
        else addManualWireError([error.message]);
      })      
      .finally(() => {
        setShowConfirmModal(false);
      });
  };

  /**
   * Handles the markAsFailed action.
   * Sets the confirm message, confirm button text, on confirm button click function, and shows the confirm modal.
   * @returns {Promise<void>} A promise that resolves when the action is completed.
  */
  const handleMarkAsFailed = async () => {
    setConfirmMessage("Are you sure you want to mark as failed?");
    setConfirmBtnText("Mark as Failed");
    setOnConfirmButtonClick(() => markAsFailed);
    setShowConfirmModal(true);
  };

  const setGenerationErrors = (generationErrors) => {
    let errorDescriptions = [];
    let notesDescriptions = [];
    Object.entries(generationErrors.descriptions).forEach(([key, descriptions]) => {
      if (descriptions?.length > 0) {
        if (ERROR_KEYS.includes(key)) {
          errorDescriptions.push(...descriptions);
        } else {
          notesDescriptions.push(...descriptions);
        }
      }
    });
    if (errorDescriptions.length > 0) setManualWireError(true, errorDescriptions)
    if (notesDescriptions.length > 0) setCustomNotes(notesDescriptions);
  };

  useEffect(() => {
    const selectedArch = archFromPath ? archFromPath : 'upper';
    const selectedWire = wireFromPath ? wireFromPath : 'initial';
    fetchManualWireData(selectedArch, selectedWire, true);
  }, []);

  useEffect(() => {
    if (caseInitialData) {
      const filteredIndexes = getFilteredIndexes(arch, caseInitialMissing, caseInitialRange, 16);
      setCaseData(arch, caseInitialData, filteredIndexes);
    }
  }, [caseInitialData]);

  useEffect(() => {
    const hasPermission = userHasPermission('CASE_WIRE_SELECTION_REVIEW', user_roles_and_permissions.permissions);
    if (!hasPermission && !disableAllFields) {
      setDisableAllFields(true);
    }
  }, [disableAllFields]);

  if (notFoundError) {
    return (
      <div className="fullview">
        <NotFound />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Manual Wire Design {caseIdDisplay} | InBrace Business Portal</title>
      </Helmet>
      <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        const hasPermission = userHasPermission('CASE_WIRE_SELECTION_REVIEW', user_roles_and_permissions.permissions);
        const hasBypassPermission = userHasPermission('CASE_MANUAL_LOKI_BYPASS', user_roles_and_permissions.permissions);
        return (
        <Container className="manual-wire-container">
          <>
            <Typography variant="h3" className="manual-wire-heading">
              Manual Wire Design - {caseIdDisplay}
            </Typography>
            <Grid container direction="column" spacing={3}>
              <ManualWireProgram disableAllFields={disableAllFields} />
              <Grid item className="manual-wire-option">
                <Typography variant="h6">Smartwire</Typography>
                <ManualWireArch archOpts={archOpts} disableAllFields={disableAllFields} fetchManualWireData={fetchManualWireData}/>
                <ManualWireSmartwire smartwireOptions={smartwireOpts} disableAllFields={disableAllFields} fetchManualWireData={fetchManualWireData}/>
              </Grid>
              <Grid item className="manual-wire-option">
                <Typography variant="h6">Auto-height squishing</Typography>
                <ManualWireAuto disableAllFields={disableAllFields} />
              </Grid>
            </Grid>
            {pageLoaded && (
              <>
                <Typography variant="h4" className="manual-wire-subheading">
                  {getPageTitle()}
                </Typography>
                <ManualWireTable disableAllFields={disableAllFields} showGeneratedLentgh={showGeneratedLentgh} />
                <Container className="button-container">
                  {hasPermission && status === 'No Status' && (
                    <Container className="buttons-row">
                      <Button
                        variant="contained"
                        className="generate-button mr-5"
                        onClick={handleGenerate}
                        disabled={!allowGenerate || hasError || errorMessage.length > 0}
                      >
                        Generate
                      </Button>
                      <Button
                        variant="contained"
                        className="generate-button"
                        onClick={handleSetDefaultValues}
                        disabled={!allowGenerate || hasError || errorMessage.length > 0}
                      >
                        Set Default Values
                      </Button>
                    </Container>
                  )}
                  <>
                    <div>{fileName}</div>
                    <Container className="buttons-row">
                      {status === 'DXF Generation Succeeded' && (
                        <>
                          <Button variant="outlined" className="preview-button mr-5" onClick={handleDownload}>
                            DXF Download
                          </Button>
                          <Button variant="outlined" className="preview-button mr-5" onClick={() => onDownloadFile(bmfCsvUrl)}>
                            CSV Download
                          </Button>
                        </>
                      )}
                      {hasPermission && ['DXF Generation Succeeded', 'DXF Generation Failed'].includes(status) && (
                        <Button variant="contained" className="retry-button" onClick={handleRetry}>
                          Retry
                        </Button>
                      )}
                    </Container>
                  </>
                </Container>
              </>
            )}
            {!pageLoaded && (
              <Box ml={2}>
                <CircularProgress />
              </Box>
            )}
          </>
          {['Generation in Progress', 'Ready for Generation'].includes(status) &&
            <>
              <div className="generation-in-progress">
                <div>Generating DXF File ...</div>
              </div>
              <i className="fa fa-refresh font-size-xlg rotate margin-top-10 process-tracker" aria-hidden="true" />
              {hasBypassPermission && 
                <>
                  <div className="generation-in-progress">
                    <Button variant="outlined" className="failed-button preview-button mr-5" onClick={handleMarkAsFailed}>
                      Mark as Failed
                    </Button>
                  </div>
                </>
              }
            </>
          }
          {hasError && errorMessage.length > 0 && <ManualWireError />}
          {customNotes.length > 0 && <ManualWireCustomNotes customNotes={customNotes} />}
          {refresh && <SessionExpire />}
          {showConfirmModal && (
            <Modal
              preset="decision"
              header_text="Manual Loki Generation"
              message_text={confirmMessage}
              confirm_btn_text={confirmBtnText}
              close_btn_text="Cancel"
              onConfirmButtonClick={onConfirmButtonClick}
              onCloseButtonClick={() => setShowConfirmModal(false)}
              theme="bpp"
            />
          )}
        </Container>)}}
      </UserPermissionsContext.Consumer>
    </>
  );
};

const mapStateToProps = (state) => {
  const manualWireReducer = state.manualWireReducer;
  const arch = state.manualWireReducer.arch;
  const auto = state.manualWireReducer.auto;
  const smartwire = state.manualWireReducer.wire;
  const program = state.manualWireReducer.program;
  const teeths = arch !== '' ? state.manualWireReducer[arch] : {};
  const hasError = state.manualWireReducer.error || getHasErrors(state);
  const errorMessage = state.manualWireReducer.errorMessage;
  const archOpts = state.manualWireReducer.archOptions;
  const refresh = state.manualWireReducer.refresh;
  const smartwireOpts = arch !== '' ? state.manualWireReducer[arch].smartwireOptions : [];

  return {
    manualWireReducer,
    arch,
    auto,
    smartwire,
    program,
    teeths,
    hasError,
    errorMessage,
    archOpts,
    smartwireOpts,
    refresh,
  };
};

const mapDispatchToProps = {
  saveDraft: saveDraft,
  setDefaultLoop: setDefaultLoop,
  setDefaultLocket: setDefaultLocket,
  setCaseData: setCaseData,
  setDefaultCaseData: setDefaultCaseData,
  setWire: setManualWireWire,
  setArch: setManualWireArch,
  setAuto: setManualWireAuto,
  setMissing: setMissingTeeths,
  setRange: setTeethRange,
  setManualWireError: setManualWireError,
  addManualWireError: addManualWireError,
  setRefresh: setRefresh,
  setArchOptions: setArchOptions,
  setSmartwireOptions: setSmartwireOptions,
  setCustomData: setCustomData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManualWire));
