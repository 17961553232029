/**
 * File: ip_loop_measurements.js - A component contains modal content for specifying IP Loop Measurements for teeth
 * Copyright: (c) Copyright July 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ip_loop_measurements.scss';
// External Libs
import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux Actions

// Redux Reducers
import { getAwb, getMeasurementConditions, getAwbMissingTeethMeasurementsModalSelectedTeeth, getMeasurementsError } from '../../redux/reducers/awb';
import { onChangeSpacingValue, onChangeSpacingOnBlur, setSpacing } from '../../redux/actions/awb';

class IPLoopMeasurements extends Component {
  getUpperTeeth() {
    const upper_right_teeth = _.range(8, 0, -1).map((item) => 'UR' + item);
    const upper_left_teeth = _.range(1, 9).map((item) => 'UL' + item);
    let upper_teeth = upper_right_teeth.concat(upper_left_teeth);

    for (let tooth of this.props.missing_teeth.filter((tn) => tn.includes('U'))) {
      upper_teeth[upper_teeth.indexOf(tooth)] = '';
    }

    return upper_teeth;
  }
  getLowerTeeth() {
    const lower_right_teeth = _.range(8, 0, -1).map((item) => 'LR' + item);
    const lower_left_teeth = _.range(1, 9).map((item) => 'LL' + item);
    let lower_teeth = lower_right_teeth.concat(lower_left_teeth);

    for (let tooth of this.props.missing_teeth.filter((tn) => !tn.includes('U'))) {
      lower_teeth[lower_teeth.indexOf(tooth)] = '';
    }

    return lower_teeth;
  }
  getLowerStraightTeeth() {
    const lower_right_teeth = _.range(8, 0, -1).map((item) => 'LR' + item);
    const lower_left_teeth = _.range(1, 9).map((item) => 'LL' + item);
    let lower_teeth = lower_right_teeth.concat(lower_left_teeth);

    const hidden_teeth = ['LR2', 'LR1', 'LL1', 'LL2']; // These teeth are always hidden on the Lower ST chart
    let missing_teeth = _.cloneDeep(this.props.missing_teeth);

    for (let tooth of hidden_teeth) {
      if (missing_teeth.indexOf(tooth) === -1) {
        missing_teeth.push(tooth);
      }
    }

    for (let tooth of missing_teeth.filter((tn) => !tn.includes('U'))) {
      lower_teeth[lower_teeth.indexOf(tooth)] = '';
    }

    return lower_teeth;
  }

  getTeethPosition = (tn, teeth) => {
    let position = 0;

    for (let i = 0; i < teeth.length; i++) {
      if (tn === teeth[i]) {
        position = i;
        break;
      }
    }
    return position;
  };

  isLastTooth(idx, teeth) {
    const remaining_teeth = teeth.slice(idx + 1).filter((t) => t !== 'UL8' && t !== 'LL8');
    const missing_teeth = remaining_teeth.filter((t) => !t);

    return missing_teeth.length === remaining_teeth.length;
  }

  getNumberOfMissingNeighboringTeethInputs(idx, teeth) {
    let count = 0;
    for (let i = idx + 1; i < teeth.length; i++) {
      if (!teeth[i]) {
        count++;
      } else {
        break;
      }
    }
    return count;
  }

  getNumberOfMissingNeighboringTeeth(idx, teeth) {
    const tn_before = idx !== 0 ? teeth[idx - 1] : '';

    let count = 0;
    let start_counting = false;

    for (let i = 0; i < teeth.length; i++) {
      if (teeth[i] === tn_before) {
        start_counting = true;
      } else if (!teeth[i] && start_counting) {
        count++;
      } else if (teeth[i] && start_counting) {
        break;
      }
    }

    return count;
  }

  teethChartRange(value, mode, idx, teeth, middle_id) {
    if (parseInt(value) < 13 || parseInt(value) > 30) {
      if (mode !== 'lowerst') {
        return true;
      } else {
        if (idx !== this.determineLowerStMiddleTooth(teeth)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  determineLowerStMiddleTooth(missing_teeth) {
    if (missing_teeth.length === 0) {
      return 5;
    } else if (!missing_teeth.includes('LR3')) {
      let return_value = 4;
      if (!missing_teeth.includes('LR4')) {
        return_value = return_value - 1;
        if (!missing_teeth.includes('LR5')) {
          return_value = return_value - 1;
          if (!missing_teeth.includes('LR6')) {
            return_value = return_value - 1;
            if (!missing_teeth.includes('LR7')) {
              return 0;
            } else {
              return return_value;
            }
          } else {
            return return_value;
          }
        } else {
          return return_value;
        }
      } else {
        return return_value;
      }
    } else {
      return 5;
    }
  }

  getSmartwires() {
    const smartwires = this.props.awb.ireq_parts;
    let hasUpper = false;
    let hasLower = false;
    let hasLowerST = false;

    for (let smartwire of smartwires) {
      if (smartwire.description.includes('Upper')) {
        hasUpper = true;
      } else if (smartwire.description.includes('Lower') && !smartwire.description.includes('Straight ')) {
        hasLower = true;
      } else if (smartwire.description.includes('Lower') && smartwire.description.includes('Straight')) {
        hasLowerST = true;
      }
    }

    return {
      hasUpper: hasUpper,
      hasLower: hasLower,
      hasLowerST: hasLowerST,
    };
  }

  make_spacing = (smartwires, isLowerST) => {
    let upperTeeth = this.getUpperTeeth();
    let lowerTeeth = this.getLowerTeeth();
    let lowerStTeeth = this.getLowerStraightTeeth();

    let hasBubbleBox = (idx, teeth) => {
      if (idx === teeth.length - 2 || (idx === 0 && !teeth[idx + 1]) || !teeth[idx] || !teeth[idx + 1]) {
        return false;
      }
      return true;
    };
    /**
     * Creates the spacing chart for tooth
     * @function
     * @param {list} teeth - list of teeth
     * @param {Object} smartwires - provided smart wires
     * @param {string} teeth_type - upper or lower teeth type
     * @returns {JSX} The spacing tooth chart
     */
    let makeSpacingChart = (teeth, smartwires, teeth_type) => {
      let isUpperOnly = smartwires.hasUpper && !smartwires.hasLower && !isLowerST;
      let isLowerOnly = !smartwires.hasUpper && smartwires.hasLower && !isLowerST;
      let isLowerAndUpper = smartwires.hasUpper && smartwires.hasLower && !isLowerST;

      if (teeth_type === 'upper') {
        teeth[0] = 'UR8';
        teeth[teeth.length - 1] = 'UL8';
      } else if (teeth_type === 'lower' || teeth_type === 'lowerst') {
        teeth[0] = 'LR8';
        teeth[teeth.length - 1] = 'LL8';
      }

      return (
        <div className="spacing-center">
          {teeth_type === 'upper' ? (
            <table className={isUpperOnly ? 'spacing-chart-input-U spacing-chart-input-padding' : 'spacing-chart-input-U'}>
              <tbody>
                <tr className="ip-loop">
                  {teeth[1] === 'UR7' ? <td className="spacing-hidden" colSpan="2"></td> : null}
                  {teeth.map((tn, idx) => {
                    return tn && tn !== 'UL8' && !(tn === 'UR8' && teeth[idx + 1]) ? (
                      <td
                        key={idx}
                        className={(idx === 1 && !tn) || !teeth[idx + 1] ? `input-box-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}` : ''}
                        colSpan="2"
                      >
                        {hasBubbleBox(idx, teeth) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-upper ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.spacingUpper[idx] ||
                                  this.teethChartRange(this.props.conditions.spacing.spacingUpper[idx], 'upper', idx, teeth, 0))
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'upper');
                              }}
                              value={this.props.conditions.spacing.spacingUpper[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'upper');
                              }}
                              onFocus={this.props.onFocus}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.target.blur();
                                }
                              }}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.spacingUpper[idx] ? (
                              <span className="ip-spacing-chart-x-upper x-position-0" onClick={() => this.clearSpacingValue(idx, 'upper')}>
                                x
                              </span>
                            ) : null}
                          </div>
                        ) : !this.isLastTooth(idx, teeth) && !(idx === 0 && !teeth[1]) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-upper ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.spacingUpper[idx] ||
                                  this.teethChartRange(this.props.conditions.spacing.spacingUpper[idx], 'upper', idx, teeth, 0))
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'upper');
                              }}
                              value={this.props.conditions.spacing.spacingUpper[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'upper');
                              }}
                              onFocus={this.props.onFocus}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.target.blur();
                                }
                              }}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.spacingUpper[idx] ? (
                              <span
                                className={`ip-spacing-chart-x-upper x-position-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}`}
                                onClick={() => this.clearSpacingValue(idx, 'upper')}
                              >
                                x
                              </span>
                            ) : null}
                            <div className="vertical-line"></div>
                          </div>
                        ) : null}
                      </td>
                    ) : null;
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
          <table className="spacing-chart">
            <tbody>
              <tr>
                {teeth.map((tn, idx) => {
                  return (tn || (!tn && teeth[idx - 1])) && tn !== 'UR8' && tn !== 'UL8' && tn !== 'LR8' && tn !== 'LL8' ? (
                    <td
                      key={idx}
                      className={
                        teeth_type === 'upper'
                          ? 'ip-spacing-chart-upper-label' +
                            (hasBubbleBox(idx, teeth)
                              ? tn === 'UR1'
                                ? ' border-right-bold'
                                : idx === teeth.length - 2
                                ? ' spacing-lower-border-right-none'
                                : ''
                              : ' spacing-chart-upper-label-hidden' + (!tn ? ` tooth-number-box-${this.getNumberOfMissingNeighboringTeeth(idx, teeth)}` : ''))
                          : teeth_type === 'lower'
                          ? hasBubbleBox(idx, teeth)
                            ? isLowerOnly
                              ? 'ip-spacing-chart-lower-label spacing-chart-lower-label-border border-top-bold' +
                                (tn === 'LR1' ? ' border-right-bold' : idx === teeth.length - 2 ? ' spacing-lower-border-right-none' : '')
                              : isLowerAndUpper
                              ? 'spacing-lower-border-top-none' +
                                (tn === 'LR1' ? ' border-right-bold' : idx === teeth.length - 2 ? ' spacing-lower-border-right-none' : '')
                              : 'ip-spacing-chart-lower-label'
                            : isLowerOnly // No bubble box
                            ? 'spacing-chart-lower-label-hidden border-top-bold spacing-chart-lower-label-border' +
                              (!tn ? ` tooth-number-box-${this.getNumberOfMissingNeighboringTeeth(idx, teeth)}` : '')
                            : isLowerAndUpper && !tn // No bubble box
                            ? 'spacing-lower-border-top-none spacing-lower-border-right-none' +
                              ` tooth-number-box-${this.getNumberOfMissingNeighboringTeeth(idx, teeth)}`
                            : 'spacing-chart-lower-label-hidden'
                          : isLowerST
                          ? hasBubbleBox(idx, teeth)
                            ? tn
                              ? 'ip-spacing-chart-lower-label spacing-chart-lower-st-label-border' +
                                (idx === teeth.length - 2 ? ' spacing-lower-border-right-none' : '')
                              : 'spacing-lower-st-width-middle spacing-chart-lower-st-label-border-empty' +
                                ` tooth-number-box-${this.getNumberOfMissingNeighboringTeeth(idx, teeth)}`
                            : tn //no bubble box
                            ? 'ip-spacing-chart-lower-label spacing-chart-lower-st-label-hidden'
                            : 'ip-spacing-chart-lower-label spacing-chart-lower-st-label-hidden' +
                              ` tooth-number-box-${this.getNumberOfMissingNeighboringTeeth(idx, teeth)}`
                          : ''
                      }
                    >
                      {!tn && teeth_type === 'upper' && idx !== 1 && !this.isLastTooth(idx - 1, teeth) ? (
                        <hr className="upper-horizontal-line" />
                      ) : !tn && teeth_type.includes('lower') && idx !== 1 && !this.isLastTooth(idx - 1, teeth) ? (
                        <hr className="lower-horizontal-line" />
                      ) : (
                        tn
                      )}
                    </td>
                  ) : null;
                })}
              </tr>
            </tbody>
          </table>
          {teeth_type === 'lower' ? (
            <table className="spacing-chart-input-L">
              <tbody>
                <tr className="ip-loop">
                  {teeth[1] === 'LR7' ? <td className="spacing-hidden" colSpan="2"></td> : null}
                  {teeth.map((tn, idx) => {
                    return tn && tn !== 'LL8' && !(tn === 'LR8' && teeth[idx + 1]) ? (
                      <td
                        key={idx}
                        className={(idx === 1 && !tn) || !teeth[idx + 1] ? `input-box-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}` : ''}
                      >
                        {hasBubbleBox(idx, teeth) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-lower ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.spacingLower[idx] ||
                                  this.teethChartRange(this.props.conditions.spacing.spacingLower[idx], 'lower', idx, teeth, 0))
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'lower');
                              }}
                              value={this.props.conditions.spacing.spacingLower[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'lower');
                              }}
                              onFocus={this.props.onFocus}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.spacingLower[idx] ? (
                              <span className="ip-spacing-chart-x-lower x-position-0" onClick={() => this.clearSpacingValue(idx, 'lower')}>
                                x
                              </span>
                            ) : null}
                          </div>
                        ) : !this.isLastTooth(idx, teeth) && !(idx === 0 && !teeth[1]) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-lower ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.spacingLower[idx] ||
                                  this.teethChartRange(!this.props.conditions.spacing.spacingLower[idx], 'lower', idx, teeth, 0))
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'lower');
                              }}
                              value={this.props.conditions.spacing.spacingLower[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'lower');
                              }}
                              onFocus={this.props.onFocus}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.spacingLower[idx] ? (
                              <span
                                className={`ip-spacing-chart-x-lower x-position-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}`}
                                onClick={() => this.clearSpacingValue(idx, 'lower')}
                              >
                                x
                              </span>
                            ) : null}
                            <div className="vertical-line bottom"></div>
                          </div>
                        ) : null}
                      </td>
                    ) : null;
                  })}
                </tr>
              </tbody>
            </table>
          ) : teeth_type === 'lowerst' ? (
            <table className="spacing-chart-input-L">
              <tbody>
                <tr className="ip-loop">
                  {teeth[1] === 'LR7' ? <td className="spacing-hidden" colSpan="2"></td> : null}
                  {teeth.map((tn, idx) => {
                    return tn && tn !== 'LL8' && !(tn === 'LR8' && teeth[idx + 1]) ? (
                      <td
                        key={idx}
                        className={(idx === 1 && !tn) || !teeth[idx + 1] ? `input-box-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}` : ''}
                      >
                        {hasBubbleBox(idx, teeth) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-lower ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.straightLower[idx] ||
                                  this.teethChartRange(
                                    this.props.conditions.spacing.straightLower[idx],
                                    'lowerst',
                                    idx,
                                    teeth,
                                    this.determineLowerStMiddleTooth(teeth)
                                  ))
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'lowerst');
                              }}
                              value={this.props.conditions.spacing.straightLower[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'lowerst');
                              }}
                              onFocus={this.props.onFocus}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.straightLower[idx] ? (
                              <span className="ip-spacing-chart-x-lower x-position-0" onClick={() => this.clearSpacingValue(idx, 'lowerst')}>
                                x
                              </span>
                            ) : null}
                          </div>
                        ) : !this.isLastTooth(idx, teeth) && !(idx === 0 && !teeth[1]) ? (
                          <div className="ip-spacing-chart-input">
                            <input
                              className={
                                'spacing-chart-input-lower ip-input' +
                                (this.props.measurements_error &&
                                (!this.props.conditions.spacing.straightLower[idx] ||
                                  this.teethChartRange(
                                    this.props.conditions.spacing.straightLower[idx],
                                    'lowerst',
                                    idx,
                                    teeth,
                                    this.determineLowerStMiddleTooth(teeth)
                                  ),
                                'lowerst')
                                  ? ' input-error'
                                  : '')
                              }
                              onChange={(e) => {
                                this.props.onChangeSpacingValue(idx, e, 'lowerst');
                              }}
                              value={this.props.conditions.spacing.straightLower[idx]}
                              onBlur={(e) => {
                                this.props.onChangeSpacingOnBlur(idx, e, 'lowerst');
                              }}
                              onFocus={this.props.onFocus}
                              placeholder="00"
                            />
                            {this.props.conditions.spacing.straightLower[idx] ? (
                              <span
                                className={`ip-spacing-chart-x-lower x-position-${this.getNumberOfMissingNeighboringTeethInputs(idx, teeth)}`}
                                onClick={() => this.clearSpacingValue(idx, 'lowerst')}
                              >
                                x
                              </span>
                            ) : null}
                            <div className="vertical-line bottom"></div>
                          </div>
                        ) : null}
                      </td>
                    ) : null;
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
      );
    };
    return (
      <div id="spacing-box">
        <div className={smartwires.hasUpper ? 'spacing-chart-zone upper-only' : 'spacing-chart-zone lower-only'}>
          {smartwires.hasUpper && !isLowerST ? makeSpacingChart(upperTeeth, smartwires, 'upper') : null}
          {smartwires.hasLower && !isLowerST ? makeSpacingChart(lowerTeeth, smartwires, 'lower') : null}
          {isLowerST ? makeSpacingChart(lowerStTeeth, smartwires, 'lowerst') : null}
        </div>
      </div>
    );
  };

  clearSpacingValue(index, mode) {
    let spacingUpper = this.props.conditions.spacing.spacingUpper;
    let spacingLower = this.props.conditions.spacing.spacingLower;
    let straightLower = this.props.conditions.spacing.straightLower;

    if (mode === 'upper') {
      spacingUpper[index] = '';
    } else if (mode === 'lower') {
      spacingLower[index] = '';
    } else {
      straightLower[index] = '';
    }

    let conditions = { ...this.props.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;
    conditions.spacing.straightLower = straightLower;

    this.props.setSpacing(conditions);
  }

  render() {
    const smartwires = this.getSmartwires();

    return (
      <React.Fragment>
        <div className="col-md-12 ip-loop-measurements">
          {smartwires.hasUpper && smartwires.hasLower ? (
            <React.Fragment>
              <div className={'missing-teeth-measurements-label'}>IP Loop Measurements - Upper and Lower</div>
              {this.make_spacing(smartwires, false)}
            </React.Fragment>
          ) : smartwires.hasUpper && !smartwires.hasLower ? (
            <React.Fragment>
              <div className="missing-teeth-measurements-label">IP Loop Measurements - Upper Only</div>
              {this.make_spacing(smartwires, false)}
            </React.Fragment>
          ) : !smartwires.hasUpper && smartwires.hasLower ? (
            <React.Fragment>
              <div className="missing-teeth-measurements-label">IP Loop Measurements - Lower Only</div>
              {this.make_spacing(smartwires, false)}
            </React.Fragment>
          ) : null}
        </div>
        {smartwires.hasLowerST ? (
          <div className="col-md-12 ip-loop-measurements">
            <div className={'missing-teeth-measurements-label' + (smartwires.hasUpper ? ' margin-bottom-50' : smartwires.hasLower ? ' margin-bottom-11' : '')}>
              IP Loop Measurements - Lower ST
            </div>
            {this.make_spacing(smartwires, true)}
          </div>
        ) : null}
        {this.props.measurements_error ? (
          <span className="center-text error-message">Input all required IP Loop Measurements. Ensure all inputs are within 13 to 30 range.</span>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    awb: getAwb(state),
    conditions: getMeasurementConditions(state),
    missing_teeth: getAwbMissingTeethMeasurementsModalSelectedTeeth(state),
    measurements_error: getMeasurementsError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangeSpacingValue: onChangeSpacingValue,
      onChangeSpacingOnBlur: onChangeSpacingOnBlur,
      setSpacing: setSpacing,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IPLoopMeasurements);
