import React from 'react';
import { convertDate, convertSex } from '../../common/functions';
import Button from '../components/buttons/button';
import Badge from '../components/badge';
import useFitText from 'use-fit-text';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

function PatientInfo(props) {
  const { first_name, last_name, dob, sex, patient_ref } = props.patient;
  const { fontSize: PCN_fontSize, ref: PCN_ref } = useFitText();
  const { fontSize: name_fontSize, ref: name_ref } = useFitText();

  return (
    <>
      <div className="patient-info__personal-info">
        <div className="profile-icon-container fs-exclude">
          {first_name && last_name ? (
            <div className="patient__profile-icon patient__initials">
              {first_name[0].toUpperCase()}
              {last_name[0].toUpperCase()}
            </div>
          ) : (
            <div className="patient__profile-icon patient__silhouette">
              <i className="fa fa-user-square" aria-hidden />
            </div>
          )}
        </div>
        <div className="patient-info__detail">
          <div className="fs-exclude" ref={name_ref} style={{ fontSize: name_fontSize }}>
            {first_name} {last_name}
          </div>
          <div>{convertDate(dob)}</div>
          <div>{convertSex(sex)}</div>
        </div>
      </div>

      <div>
        <div className="emphasis">Patient Chart No.</div>
        <div ref={PCN_ref} style={{ fontSize: PCN_fontSize }}>
          {patient_ref ? patient_ref : 'N/A'}
        </div>
      </div>

      {props.dso_doctor && (
        <div>
          <div className="emphasis">Doctor</div>
          <div>
            {props.dso_doctor.first_name} {props.dso_doctor.last_name}
          </div>
        </div>
      )}

      {props.smile_id && (
        <div className="patient_details">
          <div className="emphasis">Smile Simulation ID</div>
          <div onClick={props.onSmileSimulationIdClick} className="linked_id">
            {props.smile_id}
          </div>
        </div>
      )}

      {props.referral_code ? <Badge theme="midnight">{props.referral_code}</Badge> : null}
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const hasIPPEditPermission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
          const disabled = !hasIPPEditPermission;
          return (
            <Button onClick={props.onEdit} disabled={disabled}>
              Edit
            </Button>
          );
        }}
      </UserPermissionsContext.Consumer>
    </>
  );
}

export default PatientInfo;
