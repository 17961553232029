import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fakeAuth } from '../login/login';
import axios from 'axios';
import Button from '../components/buttons/button';
import * as FullStory from '@fullstory/browser';

class Logout extends Component {
  logout = (event) => {
    event.preventDefault();
    axios
      .post('/auth/rest-auth/logout/', {})
      .then((result) => {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionid');
        axios.defaults.headers.common['Authorization'] = null;
        fakeAuth.signout(() => this.props.history.push('/'));
        FullStory.anonymize();
      })
      .catch((errors) => console.log(errors));
  };

  render() {
    const logout_name = this.props.name ? this.props.name : 'Log out';

    return (
      // eslint-disable-next-line
      <a className={this.props.className} onClick={this.logout} role="button">
        {this.props.children}
        {this.props.type ? logout_name : <Button theme="secondary">{logout_name}</Button>}
      </a>
    );
  }
}

export default withRouter(Logout);
