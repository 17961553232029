import DOMPurify from 'dompurify';
import React from 'react';
import { convertDate, isHTML } from '../../common/functions';

function displayComment(item) {
  return isHTML(item.comment) ? (
    <div
      className="ql-editor"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(item.comment, {
          ALLOWED_ATTR: ['target', 'href', 'rel', 'class'],
        }),
      }}
    />
  ) : (
    <div className="ql-editor">
      <p>{item.comment}</p>
    </div>
  );
}

function CaseHistory(props) {
  const { items } = props;
  return (
    <div className="case-history">
      {items.map((item, index) => (
        <div key={index} className="case-history__item">
          <span className="emphasis">{convertDate(item.date)}</span>
          <span>{item.status}</span>
          {item.comment && (
            <div className="case-history__item__comment">
              <div className="comment-container">
                <div className="comment__title">{item.author}</div>
                {displayComment(item)}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default CaseHistory;
