import Axios from 'axios';

export const FETCH_CASE_LIST_TABLE_START = 'FETCH_CASE_LIST_TABLE_START';
export const FETCH_CASE_LIST_TABLE_SUCCESS = 'FETCH_CASE_LIST_TABLE_SUCCESS';
export const FETCH_CASE_LIST_TABLE_ERROR = 'FETCH_CASE_LIST_TABLE_ERROR';

export function fetchCaseListTable(config) {
  const { key, status, type, page, row_size, search, start_date, end_date, sort_method, sort_asc, values } = config;

  const params = {
    status: status,
    type: type,
    offset: (page - 1) * row_size,
    limit: page * row_size,
    search: search,
    start: start_date,
    end: end_date,
    sort: sort_method.join(','),
    order: sort_asc.join(','),
    values: values.join(','),
  };
  const urlParamsString = new URLSearchParams(params).toString();
  return (dispatch) => {
    dispatch(fetchCaseListTableStart());
    Axios.get(`/apiv3/caselist?${urlParamsString}`)
      .then((res) => dispatch(fetchCaseListTableSuccess(params, key, page, res.data)))
      .catch((err) => dispatch(fetchCaseListTableError(err)));
  };
}

function fetchCaseListTableStart() {
  return {
    type: FETCH_CASE_LIST_TABLE_START,
  };
}

function fetchCaseListTableSuccess(params, key, page, result) {
  return {
    type: FETCH_CASE_LIST_TABLE_SUCCESS,
    key: key,
    params: params,
    page: page,
    total: result.total,
    case_list_data: result.result,
  };
}

function fetchCaseListTableError(error) {
  return {
    type: FETCH_CASE_LIST_TABLE_ERROR,
    error: error,
  };
}
