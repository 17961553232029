import React from 'react';
import { Box } from '@material-ui/core';
import './manual_wire_table_empty_element.scss';

const ManualWireTableEmptyElement = ({ classname }) => {
    const className = classname ? 'empty-element ' + classname : 'empty-element';
    return (
        <Box className={className} />
    )
}

export default ManualWireTableEmptyElement;
