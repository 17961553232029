/**
 * Copyright: (c) Copyright September 2021 by InBrace
 * Authors: Jiahui Jiang
 * Project: Referral Opt In
 * Special Notes: NA
 **/

// SCSS
import './account_programs.scss';

// External Libs
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

// Internal Components
import CircleLoader from '../../components/loader/circle_loader';
import CustomerAgreement from '../customer_agreement/customer_agreement';
import SelectionArea from '../components/selection_area';
import ReferralOptIn from './referral_opt_in';
import Modal from '../../components/modal/modal';
import CardContainer from '../components/container/card_container';
import ContentHeader from '../components/content_header';
import NotFound from '../../doctor/404/not_found';

// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader, isValidEmail, removeEmoji } from '../../common/functions';
import { getDoctorsIdFromRoute } from '../../common/route';

class Programs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      refresh: false,
      loading: true,
      opted_in: false,
      terms: false,
      agreeTerm1: false,
      agreeTerm2: false,
      opting_in: false,
      opted_in_success: false,
      opting_out: false,
      referall_email: '',
      valid_email: true,
      agreement_link: '',
      program_list: ['Patient Referral Program'],
      current_selection: 'Patient Referral Program',
    };

    this.showAgreement = this.showAgreement.bind(this);
    this.hideConfirmationModals = this.hideConfirmationModals.bind(this);
    this.onAgreeTerm1 = this.onAgreeTerm1.bind(this);
    this.onAgreeTerm2 = this.onAgreeTerm2.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onAcceptSuccess = this.onAcceptSuccess.bind(this);
    this.onOptingOut = this.onOptingOut.bind(this);
    this.onOptingIn = this.onOptingIn.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleEmailChanges = this.handleEmailChanges.bind(this);
    this.allowToViewContent = this.allowToViewContent.bind(this);
    this.handleProgramSelect = this.handleProgramSelect.bind(this);
    this.getAgreementLink = this.getAgreementLink.bind(this);
    this.onReloadPage = this.onReloadPage.bind(this);
    this.getReferralStatus = this.getReferralStatus.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    this.fetchData();
  }

  componentDidUpdate(preState) {
    if (this.state.opted_in === preState.opted_in && this.state.opted_in_success) {
      this.fetchData();
    }
  }

  /**
   * fetch referral opt in data
   *
   * @function
   */
  fetchData() {
    const doctor_id = getDoctorsIdFromRoute();
    const that = this;
    const allowedToFetch = that.allowToViewContent();
    if (allowedToFetch) {
      that.getAgreementLink();
      Axios.get(`/apiV2/account/${doctor_id}`)
        .then(function (res) {
          if (res.data.role && res.data.role.includes('DSO'))
            that.setState({
              error: true,
            });
          else
            that.setState({
              opted_in: res.data.referral,
            });
        })
        .then(function () {
          Axios.get(`/apiV2/agreement`)
            .then(function (res) {
              that.setState({
                loading: false,
              });
            })
            .catch(function (err) {
              that.setState({
                loading: false,
                terms: true,
              });
            });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else
      that.setState({
        error: true,
      });
  }

  /**
   * determine is user is allowed to view the page content
   *
   * @function
   * @return {Boolean} Return false if this is a DSO Admin, false otherwise
   */
  allowToViewContent() {
    const doctor_id = getDoctorsIdFromRoute();
    if (doctor_id) {
      return true;
    } else return false;
  }

  /**
   * toggle between the agreement:  I agree to the terms of the InBrace Patient Referral Code Program Services Agreement
   *
   * @function
   */
  onAgreeTerm1() {
    this.setState({
      agreeTerm1: !this.state.agreeTerm1,
    });
  }

  /**
   * toggle between the agreement:  I agree to the Business Associate Agreement [Exhibit C]
   *
   * @function
   */
  onAgreeTerm2() {
    this.setState({
      agreeTerm2: !this.state.agreeTerm2,
    });
  }

  /**
   * handle email input changes
   *
   * @function
   * @param {Object} user input object
   */

  handleEmailChanges(e) {
    this.setState({
      valid_email: true,
      referall_email: removeEmoji(e.target.value),
    });
  }

  /**
   * handle program selection
   *
   * @function
   * @param {string} program name
   */

  handleProgramSelect(pgname) {
    this.setState({
      current_selection: pgname,
    });
  }

  /**
   * handle user confirm accepting opt in
   *
   * @function
   */
  onAccept() {
    if ((isValidEmail(this.state.referall_email) && this.state.agreeTerm1 && this.state.agreeTerm2) || this.state.opted_in) {
      const that = this;
      const doctor_id = getDoctorsIdFromRoute();
      const action = this.state.opted_in ? 'turn_off' : 'turn_on';

      Axios.put(`/apiv3/doctor/${doctor_id}/referral/${action}`)
        .then(function (res) {
          that.onOptingIn();
          that.onAcceptSuccess(doctor_id);
        })
        .catch(function (err) {
          that.setState({
            refresh: true,
          });
          handleHttpRequestError(err, that);
        });
    } else this.setState({ valid_email: false });
  }

  /**
   * handle user confirm accepting opt in success
   *
   * @function
   */
  onAcceptSuccess(doctor_id) {
    if (this.state.opting_out) {
      this.hideConfirmationModals();
      this.setState({
        opted_in: !this.state.opted_in,
      });
    } else {
      this.setState({
        opted_in: !this.state.opted_in,
        opted_in_success: !this.state.opted_in_success,
        opting_in: !this.state.opting_in,
      });
      Axios.post(`/api/email/?slug=referral-opt-in&doctorId=${doctor_id}&method=standard&to=${this.state.referall_email}&provider=${window.location.origin}`);
    }
  }

  /**
   * handle user click opt out button
   *
   * @function
   */
  onOptingOut() {
    this.setState({
      opting_out: !this.state.opting_out,
      referall_email: '',
      agreeTerm1: false,
      agreeTerm2: false,
    });
  }

  /**
   * handle user click opt in button
   *
   * @function
   */
  onOptingIn() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    const action = 'update_email';
    const data = { referral_email: that.state.referall_email };
    Axios.put(`/apiv3/doctor/${doctor_id}/referral/${action}`, data)
      .then(function (res) {})
      .catch(function (err) {
        that.setState({
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  /**
   * get agreement link
   *
   * @function
   */
  getAgreementLink() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();

    Axios.get(`/apiv3/doctor/${doctor_id}/referral`)
      .then(function (res) {
        that.setState({
          agreement_link: res.data.agreement_link,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * get referral status from db
   *
   * @function
   */
  getReferralStatus() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    Axios.get(`/apiv3/doctor/${doctor_id}/referral`)
      .then(function (res) {
        if (that.state.opted_in !== res.data.opted_in) {
          that.setState({
            refresh: true,
          });
        }
      })
      .catch(function (err) {
        that.setState({
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  /**
   * show Agreement Content Modal
   *
   * @function
   */
  showAgreement() {
    this.getReferralStatus();
    this.setState({
      valid_email: true,
      opting_in: !this.state.opting_in,
      agreeTerm1: false,
      agreeTerm2: false,
    });
  }

  /**
   * hide Opt in and Opt out confirmation modals
   *
   * @function
   */
  hideConfirmationModals() {
    this.setState({
      opting_out: false,
      opted_in_success: false,
      opting_in: false,
    });
  }

  /**
   * get all program selections
   *
   * @function
   * @return {JSX} elements for selection area
   */
  getProgramSelections() {
    const that = this;
    var is_selected = false;
    var pgList = that.state.program_list.map(function (pgname, index) {
      is_selected = pgname === that.state.current_selection;
      return (
        <SelectionArea theme={is_selected ? 'primary' : 'default'} key={index} onClick={() => that.handleProgramSelect(pgname)}>
          <p className="emphasis">{pgname}</p>
          <div className="pg_selection_status">{that.state.opted_in ? 'Opted in' : 'Not Opted in'}</div>
        </SelectionArea>
      );
    });

    return (
      <div className="program_selections">
        <h3>Programs</h3>
        <div>{that.state.program_list ? pgList : null}</div>
      </div>
    );
  }

  /**
   * get program content
   *
   * @function
   * @return {JSX} elements for program content based on selected  program
   */
  getProgramContent() {
    switch (this.state.current_selection) {
      case 'Patient Referral Program':
        return (
          <ReferralOptIn
            title={this.state.current_selection}
            opted_in={this.state.opted_in}
            agreement_link={this.state.agreement_link}
            showAgreement={this.showAgreement}
            onOptingOut={this.onOptingOut}
          />
        );

      default:
        return (
          <ReferralOptIn
            title={this.state.current_selection}
            opted_in={this.state.opted_in}
            agreement_link={this.state.agreement_link}
            showAgreement={this.showAgreement}
            onOptingOut={this.onOptingOut}
          />
        );
    }
  }

  onReloadPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.terms) {
      return <CustomerAgreement />;
    } else if (this.state.loading) {
      return <CircleLoader fullscreen={true} withBackground />;
    } else {
      const allowAccept = this.state.agreeTerm1 && this.state.agreeTerm2 && this.state.referall_email !== '';
      return (
        <>
          <Helmet>
            <title>Programs | InBrace Smile Design Studio™</title>
          </Helmet>
          <ContentHeader title="Programs" />

          <div className="program_container">
            <CardContainer className="pad-md  background--wires--yellow card card--regular card-container">
              {this.getProgramSelections()}
              {this.getProgramContent()}
            </CardContainer>
          </div>

          {this.state.opting_in ? (
            <div className="agreement_container">
              <Modal
                preset="program_agreement"
                modal_size_class="modal-lg modal-lg-pdf referral-modal"
                modal_body_class="modal-pdf"
                header_text="INBRACE PATIENT REFERRAL CODE PROGRAM SERVICES AGREEMENT"
                close_btn_text="Cancel"
                confirm_btn_text="Accept"
                agreeTerm1={this.state.agreeTerm1}
                agreeTerm2={this.state.agreeTerm2}
                confirm_btn_disabled={!allowAccept}
                onConfirmButtonClick={this.onAccept}
                onAccept={this.onAccept}
                onCloseButtonClick={this.showAgreement}
                onAgreeTerm1={this.onAgreeTerm1}
                onAgreeTerm2={this.onAgreeTerm2}
                referall_email={this.state.referall_email}
                handleEmailChanges={this.handleEmailChanges}
                onOptingOut={this.onOptingOut}
                valid_email={this.state.valid_email}
              />
            </div>
          ) : null}

          {this.state.opted_in_success ? (
            <Modal
              preset="message"
              modal_size_class="wide_modal"
              header_text="Thank You For Opting In"
              message_text={
                <span>
                  You are now participating in the InBrace Patient Referral Program. You may cancel your participation at any time by returning to this screen.
                  <br />
                  <br />
                  You will also receive an email confirming your opt in.
                </span>
              }
              close_btn_color="primary"
              theme="ipp"
              close_btn_text="Close"
              onCloseButtonClick={this.hideConfirmationModals}
            ></Modal>
          ) : null}

          {this.state.opting_out ? (
            <Modal
              preset="decision"
              modal_size_class="wide_modal"
              header_text="InBrace Patient Referral Program Opt Out"
              message_text={
                <span>
                  Are you sure you would like to opt out of the <strong> InBrace Patient Referral Program</strong>?
                </span>
              }
              confirm_btn_text="Confirm"
              close_btn_text="Close"
              onConfirmButtonClick={this.onAccept}
              onCloseButtonClick={this.hideConfirmationModals}
              theme="ipp"
            />
          ) : null}

          {this.state.refresh ? (
            <Modal
              preset="action"
              x_btn={false}
              header_text="Session Expired"
              message_text="Sorry, your session has expired. Please refresh."
              confirm_btn_text="Refresh"
              onConfirmButtonClick={this.onReloadPage}
              theme="ipp"
            />
          ) : null}
        </>
      );
    }
  }
}

export default withRouter(Programs);
