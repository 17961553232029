// Css
import './settings.scss';
// External Libs
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Internal Components
import Loader from '../../components/loader/loader';
import InternalTargetDateSettings from './internal_target_date_settings/internal_target_date_settings';
import ExternalTargetDateSettings from './external_target_date_settings/external_target_date_settings';
import ProgramSettings from './program_settings/program_settings';
import { userHasPermission } from '../../common/permission';
import Axios from 'axios';
import { getSelectionValueFromRoute } from '../../common/route';

/**
 * Displays the settings list in BPP
 * @alias Settings
 * @component
 * @category BPP
 * @subcategory Settings
 */
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: '',
      side_collapse: false,
      internal_target_date: false,
      external_target_date: false,
      program: false,
      has_program_create_permission: false,
      has_program_edit_permission: false,
    };
  }

  componentDidMount() {
    this.hasSelectionPermission();
    this.setInitialView();
  }

  /**
   * Checks if user can view External Target Dates Feature
   * Any additional permission checks should be added here
   */
  hasSelectionPermission = () => {
    Axios.get('/apiV2/permission').then((res) => {
      const user_permissions = res.data.permission_list_raw;
      const has_external_target_permission = userHasPermission('EXTERNAL_SETTINGS_TARGET_DATE', user_permissions);
      const has_internal_target_permission = userHasPermission('SETTINGS_TARGET_DATE', user_permissions);
      const has_program_create_permission = userHasPermission('PROGRAM_SETTINGS_CREATE', user_permissions);
      const has_program_edit_permission = userHasPermission('PROGRAM_SETTINGS_EDIT', user_permissions);
      const has_program_permission = has_program_create_permission || has_program_edit_permission;
      this.setState({
        external_target_date: has_external_target_permission,
        internal_target_date: has_internal_target_permission,
        program: has_program_permission,
        has_program_create_permission: has_program_create_permission,
        has_program_edit_permission: has_program_edit_permission,
        loading: false,
      });
    });
  };

  /**
   * Sets initial view on Settings page load
   */
  setInitialView = () => {
    this.setState({
      setting: getSelectionValueFromRoute() || 'internal_target_date',
    });
  };

  /**
   * Handles when user collapses sidebar
   * @function
   * @param provider_tier_id {number} - Tier id
   */
  onSideBarCollapseClick = () => {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  };

  /**
   * Refreshes URL route
   * @function
   * @param event {object} - Event object
   */
  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace('/business/portal/settings');
      this.setState({
        refresh: true,
        setting: 'internal_target_date',
      });
    }
  };

  /**
   * Sets the URL route for current
   * @param {String} setting setting name
   */
  setRoute = (setting) => {
    this.props.history.push(`/business/portal/settings?selection=${setting}`);
  };

  /**
   * Sets the state for which Setting is selected
   */
  setSettingView = (event) => {
    const setting = event.target.dataset['setting'];
    this.setState({
      setting: setting,
    });
    this.setRoute(setting);
  };

  /**
   * Passes Settings details to other components
   * @returns {Array} Settings info
   */
  getSettingsInfo = () => {
    const settingsInfo = [
      {
        canSee: this.state.internal_target_date,
        title: 'Internal Target Date',
        settingName: 'internal_target_date',
        component: <InternalTargetDateSettings />,
      },
      {
        canSee: this.state.external_target_date,
        title: 'External Target Date',
        settingName: 'external_target_date',
        component: <ExternalTargetDateSettings />,
      },
      {
        canSee: this.state.program,
        title: 'Program',
        settingName: 'program',
        component: (
          <ProgramSettings
            has_program_create_permission={this.state.has_program_create_permission}
            has_program_edit_permission={this.state.has_program_edit_permission}
          />
        ),
      },
    ];
    return settingsInfo;
  };

  /**
   * Displays settings sidebar menu
   * @function
   * @return {JSX} - JSX for settings menu
   */
  displaySettingsMenu = () => {
    const settingsInfo = this.getSettingsInfo();
    return (
      <div className={this.state.side_collapse ? 'sidenav-submenu submenu-collapse' : 'sidenav-submenu'}>
        {/* eslint-disable-next-line */}
        <a className="submenu-title" onClick={this.refreshRoute}>
          Settings
        </a>
        {/* eslint-disable-next-line */}
        {settingsInfo.map((item, index) => {
          /* eslint-disable-next-line */
          return item.canSee ? (
            /* eslint-disable-next-line */
            <a
              key={index}
              className={this.state.setting === item.settingName && !this.state.side_collapse ? 'submenu-heading submenu-active' : 'submenu-heading'}
              data-setting={item.settingName}
              onClick={this.setSettingView}
            >
              {item.title}
            </a>
          ) : null;
        })}
        <span className="sidenav-collapse-btn" onClick={this.onSideBarCollapseClick}>
          {this.state.side_collapse ? <i className="fa fa-chevron-right" aria-hidden="true" /> : <i className="fa fa-chevron-left" aria-hidden="true" />}
        </span>
      </div>
    );
  };

  /**
   * Displays selected setting content
   * @function
   * @return {JSX} - JSX for setting
   */
  displaySelectedSetting = () => {
    const settingsInfo = this.getSettingsInfo();
    const selectedSetting = settingsInfo.filter((item) => {
      if (item.settingName === this.state.setting && item.canSee) {
        return true;
      }
      return false;
    });

    return selectedSetting[0]?.component;
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div className="settings">
          <Helmet>
            <title>Settings | InBrace Business Portal</title>
          </Helmet>
          {this.displaySettingsMenu()}
          <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
            {this.state.setting && this.displaySelectedSetting()}
          </div>
        </div>
      );
    }
  }
}

export default Settings;
