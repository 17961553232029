import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import './manual_wire_custom_notes.scss';

const ManualWireCustomNotes = ({ customNotes }) => {
    return (
        <Container className='manual-wire-custom-notes'>
            <Box className="custom-note-log">
                <Typography variant='h6'>Manual Custom Wire Notes</Typography>
            </Box>
            <Box className="custom-note">
                {customNotes.map((note, i) => (
                    <p key={`note-${i}`}>{note}</p>
                ))}
            </Box>
        </Container>
    )
}

export default ManualWireCustomNotes;
