import React, { useEffect } from 'react';
import Button from '../../../extras/button';
import PropTypes from 'prop-types';

/**
 * Contains the area define as the interaction when approving or rejecting a setup
 * @component
 * @alias SetupViewerHistoryListButtonArea
 */
function HistoryListButtonArea(props) {
  useEffect(() => {
    window.$('[data-toggle="tooltip-delay"]').tooltip({
      trigger: 'hover',
      delay: {
        show: 50,
        hide: 50,
      },
    });
  }, []);

  const leftButton = !props.hideLeftButton && (
    <div
      className={`tooltip-wrapper${props.disableLeftButton ? ' disabled' : ''}`}
      data-toggle="tooltip-delay"
      data-placement="top"
      title={props.leftButtonTooltip}
      data-html={true}
    >
      <Button theme="success" onClick={props.leftButtonOnClick} disabled={props.disabled || props.disableLeftButton || props.hideLeftButton}>
        {props.leftButtonText}
      </Button>
    </div>
  );
  const rightButton = !props.hideRightButton && (
    <Button theme="danger" onClick={props.rightButtonOnClick} disabled={props.disabled || props.disableRightButton || props.hideRightButton}>
      {props.rightButtonText}
    </Button>
  );

  return (
    !(props.hideLeftButton && props.hideRightButton) && (
      <div className="history-list-button-area">
        {leftButton}
        {rightButton}
      </div>
    )
  );
}

HistoryListButtonArea.propTypes = {
  disabled: PropTypes.bool,
  disableLeftButton: PropTypes.bool,
  disableRightButton: PropTypes.bool,
  hideLeftButton: PropTypes.bool,
  hideRightButton: PropTypes.bool,
  leftButtonOnClick: PropTypes.func,
  leftButtonText: PropTypes.string,
  leftButtonTooltip: PropTypes.string,
  rightButtonOnClick: PropTypes.func,
  rightButtonText: PropTypes.string,
};

HistoryListButtonArea.defaultProps = {
  leftButtonText: 'Confirm',
  rightButtonText: 'Cancel',
};

export default HistoryListButtonArea;
