import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import FlipIcon from '@material-ui/icons/Flip';
import { maxScale, minScale, zoomStep } from '../../../../../../doctor/components/record_viewer/image_viewer';

/**
 * Displays the image controls for records
 * @component
 */
class PaneViewerRecordsControls extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentWillUnmount() {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  }

  render() {
    const scale = (this.props.record[`pane_${this.props.pane}`].scale * 100).toFixed(0);

    return (
      <div className="records-zoom-control-window">
        <i
          className="fa fa-refresh records-controller-btn records-reset-controller-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Reset"
          aria-hidden="true"
          onClick={this.props.reset}
        />
        <i
          className="fa fa-minus records-controller-btn records-zoom-controller-left-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Zoom Out"
          aria-hidden="true"
          onMouseDown={this.props.onImageZoomMinusMouseDown}
          onMouseUp={this.props.onImageZoomMouseUp}
          onMouseOut={this.props.onImageZoomMouseUp}
        />
        <input
          className="records-zoom-controller"
          type="range"
          min={minScale}
          max={maxScale}
          step={zoomStep}
          value={this.props.record[`pane_${this.props.pane}`].scale}
          data-pane={this.props.pane}
          onChange={this.props.onImageZoomChange}
        />
        <i
          className="fa fa-plus records-controller-btn records-zoom-controller-right-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Zoom In"
          aria-hidden="true"
          onMouseDown={this.props.onImageZoomPlusMouseDown}
          onMouseUp={this.props.onImageZoomMouseUp}
          onMouseOut={this.props.onImageZoomMouseUp}
        />
        <Rotate90DegreesCcwIcon
          className="records-controller-btn"
          id="records-rotate-controller-right-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Rotate"
          onClick={this.props.onImageRotateClick}
        />
        <FlipIcon
          className="records-controller-btn"
          id="records-flip-u-d-controller-right-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Vertical Flip"
          onClick={this.props.onImageFlipUDClick}
        />
        <FlipIcon
          className="records-controller-btn"
          id="records-flip-l-r-controller-right-btn"
          data-toggle="tooltip"
          data-placement="top"
          data-pane={this.props.pane}
          title="Horizontal Flip"
          onClick={this.props.onImageFlipLRClick}
        />
        <div className="records-zoom-controller-value" aria-hidden="true">
          {scale}%
        </div>
      </div>
    );
  }
}

PaneViewerRecordsControls.propTypes = {
  record: PropTypes.object.isRequired,
  pane: PropTypes.number.isRequired,
  onImageZoomChange: PropTypes.func.isRequired,
  onImageZoomMinusMouseDown: PropTypes.func.isRequired,
  onImageZoomPlusMouseDown: PropTypes.func.isRequired,
  onImageZoomMouseUp: PropTypes.func.isRequired,
  onImageRotateClick: PropTypes.func.isRequired,
  onImageFlipLRClick: PropTypes.func.isRequired,
  onImageFlipUDClick: PropTypes.func.isRequired,
};

export default PaneViewerRecordsControls;
