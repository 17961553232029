import React from 'react';
import PropTypes from 'prop-types';

import * as Icons from '../index';
import { HideContentIf } from '../../../../common/hocs/hide-content-if';

import './styles.scss';

export const ESaveIconState = {
  Idle: 'idle',
  Loading: 'loading',
  Failure: 'failure',
  Success: 'success',
};

const iconsByState = {
  [ESaveIconState.Loading]: <Icons.SaveLoadingIcon className='loading-state' />,
  [ESaveIconState.Success]: <Icons.SaveSuccessIcon />,
  [ESaveIconState.Failure]: <Icons.SaveFailureIcon />,
};

export const SaveIcon = ({ state, disabled }) => {
  const stateIcon = iconsByState[state];

  return <div className='save-icon'>
    <Icons.SaveIcon />
    <HideContentIf condition={!stateIcon || disabled}>
      <div className='save-icon-state'>{stateIcon}</div>
    </HideContentIf>
  </div>;
};

SaveIcon.propTypes = {
  state: PropTypes.oneOf(Object.values(ESaveIconState)),
};
