import React from 'react';
import CaseListDropdown from './case_list_dropdown';

/**
 * Builds the dso dropdown
 * @function
 * @param {Array} dso_list - The list of dso
 * @param {function} onChangeCaseType - onClick handler
 * @returns {Array} The list of dropdown
 */
function buildDSODropdown(dso_list, onChangeCaseType) {
  if (!dso_list) return {};
  return dso_list.map((dso) => ({
    key: dso,
    text: `${dso} Cases`,
    onClick: () => onChangeCaseType(dso),
  }));
}

/**
 * Displays the bpp case list sidebar top filter
 * @component
 * @category BPP
 */
function CaseListTopFilter(props) {
  const { selectedFilter, onChangeCaseType, dso_list, openFilterPreferenceModal } = props;
  const dropdown = [
    {
      key: 'all',
      text: 'All Cases',
      onClick: () => onChangeCaseType('all'),
    },
    ...buildDSODropdown(dso_list, onChangeCaseType),
    {
      key: 'gen2',
      text: 'Gen 2.0 Cases',
      onClick: () => onChangeCaseType('gen2'),
    },
  ];

  return (
    <div className="sidebar__top-container">
      <CaseListDropdown selected={selectedFilter} dropdown_list={dropdown} />
      <i
        className="fa fa-cog case-list-settings"
        aria-hidden="true"
        data-toggle="tooltip"
        title="Filter Settings"
        data-placement="right"
        onClick={openFilterPreferenceModal}
      />
    </div>
  );
}

export default CaseListTopFilter;
