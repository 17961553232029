import {
  OPEN_SPECS_FILE_MODAL,
  CLOSE_SPECS_FILE_MODAL,
  OPEN_DESIGN_MANUAL_MODAL,
  OPEN_SPECS_MANUAL_MODAL,
  RETURN_TO_DDM_PENDING,
  RETURN_TO_DDM_SUCCESS,
  RETURN_TO_DDM_ERROR,
  CLOSE_SPECS_MANUAL_MODAL,
  CLOSE_DESIGN_MANUAL_MODAL,
  DDM_MANUAL_UPLOAD_PENDING,
  DDM_MANUAL_UPLOAD_SUCCESS,
  DDM_MANUAL_UPLOAD_ERROR,
  REMOVE_SPECS_MANUAL_MODAL,
  REMOVE_DESIGN_MANUAL_MODAL,
  CLOSE_REMOVE_SPECS_MODAL,
  CLOSE_REMOVE_DESIGN_MODAL,
  REMOVE_SPECS_FILE_SUCCESS,
  REMOVE_SPECS_FILE_PENDING,
  REMOVE_SPECS_FILE_ERROR,
  REMOVE_DESIGN_FILE_PENDING,
  REMOVE_DESIGN_FILE_ERROR,
  REMOVE_DESIGN_FILE_SUCCESS,
} from '../../actions/ddm/ddm_result';

const initialState = {
  error: null,
  pending: false,
  specs_file_modal: false,
  session_error: false,
  specs_manual_modal: false,
  design_manual_modal: false,
  remove_specs_modal: false,
  remove_design_modal: false,
};

export function ddmResultReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SPECS_FILE_MODAL:
      return {
        ...state,
        specs_file_modal: true,
      };
    case CLOSE_SPECS_FILE_MODAL:
      return {
        ...state,
        specs_file_modal: false,
      };
    case OPEN_DESIGN_MANUAL_MODAL:
      return {
        ...state,
        design_manual_modal: true,
      };
    case CLOSE_DESIGN_MANUAL_MODAL:
      return {
        ...state,
        design_manual_modal: false,
      };
    case OPEN_SPECS_MANUAL_MODAL:
      return {
        ...state,
        specs_manual_modal: true,
      };
    case CLOSE_SPECS_MANUAL_MODAL:
      return {
        ...state,
        specs_manual_modal: false,
      };
    case RETURN_TO_DDM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case RETURN_TO_DDM_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case RETURN_TO_DDM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case DDM_MANUAL_UPLOAD_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DDM_MANUAL_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case DDM_MANUAL_UPLOAD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case REMOVE_SPECS_MANUAL_MODAL:
      return {
        ...state,
        remove_specs_modal: true,
      };
    case REMOVE_DESIGN_MANUAL_MODAL:
      return {
        ...state,
        remove_design_modal: true,
      };
    case CLOSE_REMOVE_SPECS_MODAL:
      return {
        ...state,
        remove_specs_modal: false,
      };
    case CLOSE_REMOVE_DESIGN_MODAL:
      return {
        ...state,
        remove_design_modal: false,
      };
    case REMOVE_SPECS_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_SPECS_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_SPECS_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case REMOVE_DESIGN_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_DESIGN_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_DESIGN_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    default:
      return state;
  }
}
export const getSpecsFileModal = (state) => state.ddmResultReducer.specs_file_modal;
export const getSpecsManualModal = (state) => state.ddmResultReducer.specs_manual_modal;
export const getDesignManualModal = (state) => state.ddmResultReducer.design_manual_modal;
export const getPending = (state) => state.ddmResultReducer.pending;
export const getResultSessionError = (state) => state.ddmResultReducer.session_error;
export const getRemoveSpecsModal = (state) => state.ddmResultReducer.remove_specs_modal;
export const getRemoveDesignModal = (state) => state.ddmResultReducer.remove_design_modal;
