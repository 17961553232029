import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onChangeView } from '../../redux/actions/wasm_viewer/wasm_viewer';
import * as Icons from './icons';
import WasmArchControls from './wasm_arch_controls';
import { getWasmViewerState } from '../../redux/selectors/wasm_viewer';

function getButtonClassnames(is_active) {
  const classnames = ['wasm-tooth-view-button'];
  if (is_active) classnames.push('wasm-tooth-view-button--active');
  return classnames.join(' ');
}

function WasmViewControls(props) {
  const { view, onChangeView } = props;
  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  return (
    <div className="wasm-view-controls">
      <div className="wasm-tooth-view-controls">
        <div>
          <div
            className={getButtonClassnames(view === 'Bottom')}
            data-toggle="tooltip"
            data-placement="top"
            title="Top View"
            onClick={() => onChangeView('Bottom')}
          >
            <Icons.TogglesBottomIcon style={{ transform: 'scaleY(-1)' }} />
          </div>
        </div>

        <div>
          <div
            className={getButtonClassnames(view === 'Right')}
            data-toggle="tooltip"
            data-placement="top"
            title="Right View"
            onClick={() => onChangeView('Right')}
          >
            <Icons.TogglesLeftIcon />
          </div>
          <div
            className={getButtonClassnames(view === 'Front' || view === 'Back')}
            data-toggle="tooltip"
            data-placement="top"
            title="Front / Back View"
            onClick={() => onChangeView('Front')}
          >
            <Icons.TogglesFrontIcon />
          </div>
          <div
            className={getButtonClassnames(view === 'Left')}
            data-toggle="tooltip"
            data-placement="top"
            title="Left View"
            onClick={() => onChangeView('Left')}
          >
            <Icons.TogglesLeftIcon style={{ transform: 'scaleX(-1)' }} />
          </div>
        </div>

        <div>
          <div
            className={getButtonClassnames(view === 'Top')}
            data-toggle="tooltip"
            data-placement="bottom"
            title="Bottom View"
            onClick={() => onChangeView('Top')}
          >
            <Icons.TogglesBottomIcon />
          </div>
        </div>
      </div>
      <WasmArchControls />
    </div>
  );
}

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    view: wasm_state.view,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangeView: onChangeView,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmViewControls);
