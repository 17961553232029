import React from 'react';
import { onDownload } from '../../../common/functions';

function WireSelectionOverride(props) {
  const bmf_url = props.bmf_file ? props.bmf_file.url : '';
  const bmf_rev = props.bmf_file ? props.bmf_file.bmf_rev : 'N/A';

  return (
    <div className="wire-selection-output">
      <div>Custom Wire WO Ref: N/A</div>
      {bmf_url && (
        <div>
          <a className="underline-text viewable-text view-upload" href={`/${bmf_url}`} download onClick={onDownload}>
            Bracket Measurements Form <i className="fa fa-file-excel-o" aria-hidden="true" />
          </a>
          <span>
            (<span className="bold-text">Rev#: </span>
            {bmf_rev})
          </span>
        </div>
      )}
      <div className="margin-top-10">The wire selection process has been overridden...</div>
    </div>
  );
}

export default WireSelectionOverride;
