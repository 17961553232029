/**
 * File: status.js - A component that display the status history
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React from 'react';
import DOMPurify from 'dompurify';
import { isHTML } from '../../common/functions';
import './status.scss';

const Status = ({ log_value }) => {
  return (
    <div className="modal-status">
      <table className="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th className="table-status">Status</th>
            <th>Comments</th>
            <th className="table-status-user">Status User</th>
            <th className="table-date">Date and Time</th>
          </tr>
        </thead>
        <tbody>
          {log_value.map((status, index) => {
            return (
              <tr key={index}>
                <td>{status.status}</td>
                <td>
                  {isHTML(status.comment) ? (
                    <div
                      className="ql-editor ql-table"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(status.comment, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                      }}
                    ></div>
                  ) : (
                    <div className="ql-editor ql-table">
                      <p>{status.comment}</p>
                    </div>
                  )}
                </td>
                <td>{status.user}</td>
                <td>{status.date}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Status;
