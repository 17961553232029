// External Libs
import React, { Component } from 'react';
// Internal Libs
import Modal from '../../../components/modal/modal';
import { removeIreqIdInitialNumber } from '../../../common/functions';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { getPDFNameTemplate } from '../../../common/helpers';

const LABELS = {
  singlesheet: { header: 'Single Sheet', folder: 'ir_singlesheet_label', file_name: 'Label_Single_Sheet_Manual.pdf' },
  inner_packaging: { header: 'Inner Packaging Label', folder: 'ireq_labels', file_name: 'Label_Inner_Packaging_Manual.pdf' },
};

class CadLabelFailure extends Component {
  state = {
    selected_upload_label: '',
    content_file: [],
  };
  onUploadLabelModalClick = (label_type) => {
    this.setState({ upload_label: !this.state.upload_label, selected_upload_label: label_type });
  };

  onUpload = (data) => {
    this.setState({
      content_file: data,
    });
    this.props.onUpload(data);
  };
  onUploadInnerPackagingModalDismiss = () => {
    this.setState({ upload_label: !this.state.upload_label, selected_upload_label: '' });
    this.props.reloadInformation();
  };

  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro text-center">
          <div className="process-box-title bold-text">Label Generation Failure</div>
          <div className="process-failure">{this.props.status_comment ? this.props.status_comment : 'N/A'}</div>
          <div className="process-retry">
            Manually upload or click <b>Retry</b> to retry label generation
          </div>
          <div className="upload-area">
            {this.props.gen_2 ? (
              <div className="upload-block">
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                      <React.Fragment>
                        <div className="bold-text">Single Sheet</div>
                        <div>Generation Failed</div>
                        <button
                          type="button"
                          className="btn btn-bpp-upload btn-shipped view-upload"
                          onClick={() => this.onUploadLabelModalClick('singlesheet')}
                        >
                          Upload Label
                        </button>
                      </React.Fragment>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
              </div>
            ) : (
              <div className="upload-block">
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                      <React.Fragment>
                        <div className="bold-text">Inner packaging</div>
                        <div>Generation Failed</div>
                        <button
                          type="button"
                          className="btn btn-bpp-upload btn-shipped view-upload"
                          onClick={() => this.onUploadLabelModalClick('inner_packaging')}
                        >
                          Upload Label
                        </button>
                      </React.Fragment>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
              </div>
            )}
          </div>

          <div>
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('IREQ_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
                  <button type="button" className="btn btn-light" onClick={this.props.restartLabelGeneration}>
                    Retry
                  </button>
                ) : (
                  <button type="button" className="btn btn-light" disabled={true}>
                    Retry
                  </button>
                );
              }}
            </UserPermissionsContext.Consumer>
          </div>
        </div>
        {this.state.upload_label ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload ' + LABELS[this.state.selected_upload_label].header + ' - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            ireq_id={this.props.item_request.ireq_id}
            folder={LABELS[this.state.selected_upload_label].folder}
            type="additional"
            onUpload={this.onUpload}
            upload_content={this.state.content_file ? this.state.content_file : this.props.files[0]}
            onCloseButtonClick={this.onUploadInnerPackagingModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.item_request.ireq_id, this.props.patient_info, LABELS[this.state.selected_upload_label].file_name)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default CadLabelFailure;
