/**
 * File: tx_plan.js - Contains the logic for the
 * Copyright: (c) Copyright August 2019 by InBrace
 * Authors: Eric Guenterberg, David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './tx_plan.scss';
// External Libs
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import { withRouter } from 'react-router-dom';

// Internal Functions/Images
import tooth_diagram from './tx_plan_diagram.svg';
import { textFieldCheck, textFieldLimited, removeEmoji, onImageToolAction } from '../../common/functions';
import { makePresets } from '../../common/helpers';
import TeethSelector from '../../common/teeth_selector';
import CardContainer from '../components/container/card_container';
import ClinicalConditions from '../components/buttons/clinical_conditions';
import TextArea from '../components/inputs/text_area';
import AlertContainer from '../components/container/alert_container';
import RecordViewer from '../components/record_viewer/record_viewer';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';

// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import AlertMessage from '../components/alert_message';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

export class QBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoEl: null,
      info: null,
    };
    this.handleClick.bind(this);
    this.setInfoEl.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('scroll', this.handleClick, false);
    this.setInfoEl(this.props.info);
  }

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('scroll', this.handleClick, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setInfoEl(this.props.infoEl);
  }

  setInfoEl = (info) => {
    if (Array.isArray(info)) info = info.join('');
    if (this.state.info === info) {
      return;
    }
    this.setState({ info: info });

    let infoEl = this.props.info ? (
      <span data-toggle="tooltip" title={this.props.info}>
        <i className="fa fa-info-circle" />
      </span>
    ) : null;

    if (this.props.setInfo) {
      this.props.setInfo(infoEl);
    } else {
      this.setState({ infoEl: infoEl });
    }
  };

  static defaultProps = {
    title: 'Untitled',
  };

  handleClick = (evt) => {
    this.setState({ popOpen: false });
  };

  render() {
    return (
      <div className={this.props.placeholder ? null : 'question-box'} data-testid={this.props.dataTestId}>
        {/* eslint-disable-next-line */}
        {this.props.anchor ? <a id={this.props.anchor} className="anchor-offset" /> : null}
        {this.props.title_alt || this.props.title ? (
          <span id={this.props.id} className={`title emphasis ${this.props.titleClassName ? this.props.titleClassName : ''} `}>
            {this.props.title_index ? <span className="title-index emphasis">{this.props.title_index}.</span> : null}
            {this.props.title_alt ? this.props.title_alt : `${this.props.title}${this.props.required ? '*' : ''}`}{' '}
          </span>
        ) : null}
        {this.state.infoEl ? this.state.infoEl : null}
        {this.props.placeholder ? this.props.children : <CardContainer type="form">{this.props.children}</CardContainer>}
      </div>
    );
  }
}

export class CheckBar extends Component {
  constructor(props) {
    super(props);

    this.handleClick.bind(this);
  }

  static defaultProps = {
    buttons: {},
    value: '',
  };

  handleClick = (evt) => {
    if (this.props.onClick) this.props.onClick(evt.target.value);
  };

  render() {
    return (
      <div className="btn-bar btn-bar-spaced" role="toolbar">
        {Object.entries(this.props.buttons).map(([key, name]) => {
          return (
            <button
              type="button"
              key={key}
              onClick={this.handleClick}
              className={'btn-ib ' + (key === this.props.value ? 'btn-ib--active' : '') + (this.props.disabledEdit ? ' disabled' : '')}
              value={key}
            >
              {name}
            </button>
          );
        })}
      </div>
    );
  }
}

export class ToothViewer extends Component {
  constructor(props) {
    super(props);

    this.refOut = React.createRef();
    this.refIn = React.createRef();

    this.state = {
      width: '100%',
      loading: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeDebounce);
    this.onResize();
  }

  componentDidUpdate() {
    this.onResizeDebounce();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeDebounce);
  }

  /**
   * Adjusts the width of toothviewer to ensure that it
   * will leave enough spaces in the bottom
   *
   * @function
   * @param {Number} width - Current width to be adjusted
   * @return {Number} The adjusted width
   */
  adjustWidth(width) {
    const SPACES_2_LEAVE = 120;
    const PROPORTION = 0.66;

    if (this.refIn && this.refIn.current) {
      const { top } = this.refIn.current.getBoundingClientRect();
      const win_height = window.innerHeight;

      const height = width / PROPORTION;
      const remaining_spaces = win_height - (top + height);

      if (remaining_spaces < SPACES_2_LEAVE) {
        width = width - (SPACES_2_LEAVE - remaining_spaces) * PROPORTION;
      }
    }

    return width;
  }

  onResize = () => {
    let w = this.refOut && this.refOut.current && this.refOut.current.offsetWidth ? this.refOut.current.offsetWidth : '';
    w = this.adjustWidth(w);
    if (w !== this.state.width) {
      this.setState({
        width: w,
      });
    }
  };

  onResizeDebounce = _.debounce(this.onResize, 50);

  render() {
    let tset = this.props.teethSets;

    return (
      <div ref={this.refOut} className="tooth-viewer-outer-frame" style={{ width: '100%' }}>
        <div className="tooth-viewer-inner-frame" ref={this.refIn} style={{ width: this.state.width }}>
          <div className="tooth-viewer" id="tooth-viewer">
            {this.state.loading ? <div className="tooth-loader">Loading...</div> : null}
            <SvgLoader
              path={tooth_diagram}
              onSVGReady={() => {
                this.setState({ loading: false });
              }}
            >
              <SvgProxy selector="#Extractions rect" class="" fill="none" stroke="none" />
              <SvgProxy selector="#Teeth path.st0" class="st0" fill="FFFFFF" stroke="" stroke-width="" />
              <SvgProxy selector="#Teeth path.st2" class="st2" fill="E4E4E4" stroke="" stroke-width="" />
              <SvgProxy selector="#Teeth path.st4" class="st4" stroke="#666666" stroke-miterlimit="10" stroke-width="1.25pt" stroke-dasharray="" />

              {Teeth.All.map((x, ind) => {
                let gid = '#Brackets #' + x + '_1_';
                let gSel = gid;

                return <SvgProxy key={ind} selector={gSel} display="none" />;
              })}

              {tset.extractTeeth.map((x, ind) => {
                let gid = '#Extractions #' + x + '_X';
                let gSel = gid + ' rect';

                let gid2 = '#Teeth #' + x;
                let gSel2 = gid2 + ' path';

                return (
                  <React.Fragment key={ind}>
                    <SvgProxy selector={gSel2 + '.st0'} fill="#d6dbe0" stroke="" class="" />
                    <SvgProxy selector={gSel2 + '.st2'} fill="#c0c5c9" stroke="" class="" />
                    <SvgProxy selector={gSel} fill="none" stroke="#282828" stroke-width="1.5pt" />
                  </React.Fragment>
                );
              })}

              {tset.missingTeeth.map((x, ind) => {
                let gid = '#Teeth #' + x;
                let gSel = gid + ' path';

                return (
                  <React.Fragment key={ind}>
                    <SvgProxy selector={gSel + '.st0'} fill="none" stroke="none" class="" />
                    <SvgProxy selector={gSel + '.st2'} fill="none" stroke="none" class="" />
                    <SvgProxy selector={gSel + '.st4'} fill="none" stroke="#575E65" stroke-dasharray="10,5" />
                  </React.Fragment>
                );
              })}

              {tset.restrictTeeth.map((x, ind) => {
                let gid = '#Teeth #' + x;
                let gSel = gid + ' path';

                if (_.intersection([x], tset.missingTeeth).length > 0) {
                  return '';
                } else {
                  return (
                    <React.Fragment key={ind}>
                      <SvgProxy selector={gSel + '.st0'} fill="#d6dbe0" stroke="" class="" />
                      <SvgProxy selector={gSel + '.st2'} fill="#c0c5c9" stroke="" class="" />
                    </React.Fragment>
                  );
                }
              })}

              {tset.bracketTeeth.map((x, ind) => {
                let gid = '#Brackets #' + x + '_1_';
                let gSel = gid;

                return <SvgProxy key={ind} selector={gSel} display="inherit" />;
              })}
            </SvgLoader>
          </div>
        </div>
      </div>
    );
  }
}

export class TxPlan extends Component {
  constructor(props) {
    super(props);

    this.caseID = '49F4W-DE1';
    this.minPreset = 5;
    this.maxPreset = 7;
    this.presets = makePresets(this.minPreset, this.maxPreset);
    this.state = {
      thumbs: [],
      archToTreat: 'both',
      opposingArch: 'current',
      // setupOpposing: false,
      missingTeeth: ['UL8', 'UR8', 'LL8', 'LR8'],
      extractTeeth: [],
      bracketTeeth: this.presets[6],
      restrictTeeth: [],
      restrictReason: '',
      chief_complaint: '',
      notes: '',
      notes_spacing: '',
      conditions: {
        ap_relationship: {
          active: false,
          title: 'AP Relationship',
          info: [
            'InBrace is suited for cases that present Class II discrepancies ',
            'up to 1.5mm or Class III discrepancies up to 1mm. If larger discrepancies ',
            'exist, use sagittal auxiliaries before or in tandem with InBrace to ',
            'help achieve treatment goals.',
          ],
          ap_improvement: 'maintain',
          aux_note: '',
        },
        midlines: {
          active: false,
          title: 'Midlines',
          upper_mid_improve: 'maintain',
          upper_mid_improve_right_by: '',
          upper_mid_improve_left_by: '',
          lower_mid_improve: 'maintain',
          lower_mid_improve_right_by: '',
          lower_mid_improve_left_by: '',
        },
        crowding: {
          active: false,
          title: 'Crowding',
          info_title: true,
          upper_expand: 'as_needed',
          upper_ipr_ant: 'as_needed',
          upper_ipr_right: 'as_needed',
          upper_ipr_left: 'as_needed',
          upper_procline: 'as_needed',
          lower_expand: 'as_needed',
          lower_ipr_ant: 'as_needed',
          lower_ipr_right: 'as_needed',
          lower_ipr_left: 'as_needed',
          lower_procline: 'as_needed',
        },
        spacing: {
          active: false,
          title: 'Spacing',
          info_title: true,
          // info: ['InBrace is suited for cases that present no single occurrence of spacing greater than 3.5mm.'],
          spaces: 'close',
          spaceTeeth: [],
          spacingUpper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          spacingLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          leave_spaces_text: '',
        },
        overjet: {
          active: false,
          title: 'Overjet',
          improve: 'inbrace',
          improve_ipr: false,
          procline: false,
          aux_note: '',
        },
        deepbite: {
          active: false,
          title: 'Deepbite',
          info: [
            'InBrace is suited for cases that present 6mm of deepbite or less. ',
            'If deepbite greater than 6mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          correct: 'inbrace',
          aux_note: '',
        },
        openbite: {
          active: false,
          title: 'Openbite',
          title_alt: 'Anterior Openbite',
          info: [
            'InBrace is suited for cases that present 3mm of openbite or less. ',
            'If openbite greater than 3mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          improve_amt: 'maintain',
        },
        crossbite: {
          active: false,
          title: 'Crossbite/Transverse',
          info: ['InBrace can moderately expand/restrict archforms.'],
          anterior_advance_upper: false,
          anterior_retract_lower: false,
          posterior_expand_upper: false,
          posterior_narrow_upper: false,
          posterior_expand_lower: false,
          posterior_narrow_lower: false,
        },
      },
      image_records: [],
    };

    this.clearSpacingValue.bind(this);
    this.makeCheckbox.bind(this);
    this.makeRadio.bind(this);
    this.makeCheckBar.bind(this);
    this.setPreset.bind(this);
    this.makeAuxNotes.bind(this);
    this.checkPreset.bind(this);
    this.getConditionState.bind(this);
    this.setConditionState.bind(this);
    this.getImpliedTeethSets.bind(this);
    this.handleTeethClick.bind(this);
    this.onChangeSpacingValue.bind(this);
    this.onChangeSpacingOnBlur.bind(this);

    Object.entries(this.state.conditions).forEach((x) => this['make_' + x[0]].bind(this));
  }

  componentDidMount() {
    this.buildImageRecords();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.conditions.midlines.active) {
      const that = this;

      if (
        this.state.conditions.midlines.lower_mid_improve !== 'maintain' &&
        this.state.conditions.midlines.lower_mid_improve !== prevState.conditions.midlines.lower_mid_improve
      ) {
        if (this.refs['midlines-lower_mid_improve-left'] && this.state.conditions.midlines.lower_mid_improve === 'left') {
          setTimeout(function () {
            that.refs['midlines-lower_mid_improve-left'].focus();
          }, 100);
        } else if (this.refs['midlines-lower_mid_improve-right'] && this.state.conditions.midlines.lower_mid_improve === 'right') {
          setTimeout(function () {
            that.refs['midlines-lower_mid_improve-right'].focus();
          }, 100);
        }
      } else if (
        this.state.conditions.midlines.upper_mid_improve !== 'maintain' &&
        this.state.conditions.midlines.upper_mid_improve !== prevState.conditions.midlines.upper_mid_improve
      ) {
        if (this.refs['midlines-upper_mid_improve-left'] && this.state.conditions.midlines.upper_mid_improve === 'left') {
          setTimeout(function () {
            that.refs['midlines-upper_mid_improve-left'].focus();
          }, 100);
        } else if (this.refs['midlines-upper_mid_improve-right'] && this.state.conditions.midlines.upper_mid_improve === 'right') {
          setTimeout(function () {
            that.refs['midlines-upper_mid_improve-right'].focus();
          }, 100);
        }
      }
    }
    if (
      this.props.photoUpload !== prevProps.photoUpload ||
      this.props.xrayUpload !== prevProps.xrayUpload ||
      this.props.scanUpload !== prevProps.scanUpload ||
      this.props.selectedIteroScanRecords !== prevProps.selectedIteroScanRecords
    ) {
      this.buildImageRecords();
    }
  }

  buildImageRecords() {
    const {
      photoUpload,
      xrayUpload,
      scanUpload,
      // iTero integration
      uploadMethod,
      selectedIteroScanRecords,
    } = this.props;

    const records = [];

    if (photoUpload) records.push(...photoUpload);
    if (xrayUpload) records.push(...xrayUpload);

    // iTero integration
    if (uploadMethod === 'itero' && selectedIteroScanRecords) {
      records.push(...selectedIteroScanRecords);
    } else if (scanUpload) {
      records.push(...scanUpload);
    }

    const options = { teethViewer: true, caseType: 'incomplete' };

    this.props.buildRecordStates(records, options);
  }

  onChangeSpacingValue(index, event, mode) {
    let spacingUpper = this.props.conditions.spacing.spacingUpper.slice();
    let spacingLower = this.props.conditions.spacing.spacingLower.slice();

    if (mode === 'upper') {
      spacingUpper[index] = this.formatMMEntry('spacing', 'spacingUpper', event.target.value, index);
    } else {
      spacingLower[index] = this.formatMMEntry('spacing', 'spacingLower', event.target.value, index);
    }

    let conditions = { ...this.props.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.props.setConditionState(conditions);
    this.props.metAllPlanRequiredField(conditions);
  }

  onChangeSpacingOnBlur(index, event, mode) {
    let spacingUpper = this.props.conditions.spacing.spacingUpper;
    let spacingLower = this.props.conditions.spacing.spacingLower;

    if (mode === 'upper') {
      spacingUpper[index] = this.formatMMFromBlur(event.target.value);
    } else {
      spacingLower[index] = this.formatMMFromBlur(event.target.value);
    }

    let conditions = { ...this.props.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.props.setConditionState(conditions);
  }

  clearSpacingValue(index, mode) {
    let spacingUpper = this.props.conditions.spacing.spacingUpper;
    let spacingLower = this.props.conditions.spacing.spacingLower;

    if (mode === 'upper') {
      spacingUpper[index] = '';
    } else {
      spacingLower[index] = '';
    }

    let conditions = { ...this.props.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.props.setConditionState(conditions);
    this.props.metAllPlanRequiredField(conditions);
  }

  static improveBarNames = {
    primary: 'Yes',
    as_needed: 'If Needed',
    none: 'None',
  };

  //Moved
  static makePreset = (n) => {
    var teeth = [];
    _.range(1, n + 1).forEach((x) => {
      teeth.push('UL' + x, 'UR' + x, 'LL' + x, 'LR' + x);
    });
    return teeth;
  };

  makeCheckBar = (cond, key, disabledEdit) => {
    let val = this.getConditionState(cond)[key];
    let onClick = (val) => {
      let res = {};
      res[key] = val;
      this.setConditionState(cond, res);

      let allCond = { ...this.props.conditions };
      allCond['crowding'][key] = val;

      this.props.metAllPlanRequiredField({ conditions: allCond });
      this.props.onFocus();
    };
    return <CheckBar value={val} buttons={TxPlan.improveBarNames} onClick={onClick} disabledEdit={disabledEdit} />;
  };

  handleTeethClick = (teethSet) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);

      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);
      this.setState(ns);
    };

    return func;
  };

  setPreset = (n) => {
    this.props.setPlanState({ bracketTeeth: this.presets[n] });
  };

  getConditionState = (cnd) => {
    return { ...this.props.conditions[cnd] };
  };

  setConditionState = (cnd, newState) => {
    this.props.setPlanConditionState(cnd, newState);
  };

  makeCheckbox = (
    name,
    group,
    { cond = null, disabled = false, onCheck = null },
    disabledEdit = false,
    onFocus_clearWarning = () => {
      return;
    }
  ) => {
    let cID = cond ? cond + '-' + group : group;
    let state = cond ? this.getConditionState(cond) : this.state;
    let isChecked = state[group] === true;
    let updateF = (group) => {
      let func = (evt) => {
        let ns = {};
        if (onCheck) {
          ns = onCheck(evt.target.checked);
        }
        ns[group] = evt.target.checked;
        if (cond) {
          this.setConditionState(cond, ns);
        } else {
          this.setState(ns);
        }
      };
      return func;
    };

    return (
      <div className="ib-checkbox">
        <label className={'form-check-label ' + (disabled ? 'disabled' : '')}>
          <input
            className={'form-check-input ' + (disabled ? 'disabled' : '')}
            type="checkbox"
            id={cID}
            checked={isChecked && !disabled}
            onChange={updateF(group)}
            disabled={disabled || disabledEdit}
            onFocus={() => {
              onFocus_clearWarning();
            }}
          />{' '}
          {name}
        </label>
      </div>
    );
  };

  makeWarning = (condition, warning) => {
    if (condition && warning) {
      return <AlertContainer>{warning}</AlertContainer>;
    }
  };

  formatMMEntry = (cond = null, group = null, value, index = null) => {
    // Value should be X.X
    const prevCond = index !== null && index >= 0 ? this.props.conditions[cond][group][index] : this.props.conditions[cond][group];
    let formattedValue = value
      .replace(/[^0-9.]/g, '')
      .replace('..', '.')
      .replace('00', '0');

    //Enforce .
    if (formattedValue.length === 1 && prevCond === '' && formattedValue !== '.') {
      formattedValue += '.';
    } else if (formattedValue.length === 1 && prevCond === '' && formattedValue === '.') {
      formattedValue = '0.';
    } else if ((value.length > 2 && prevCond === '0') || (value.length === 2 && parseInt(prevCond) <= 9 && value[1] !== '.' && value[0] !== '.')) {
      formattedValue = value[0] + '.' + value[1];
    } else if (formattedValue.length === 1 && prevCond === '.') {
      formattedValue = '';
    }

    //if . is detected than only allow one digit after
    const decimalPos = formattedValue.indexOf('.');
    if (decimalPos >= 0 && formattedValue.length >= decimalPos + 2) {
      formattedValue = formattedValue.slice(0, decimalPos + 2);
    }

    //trim off leading 0 conditions
    if (decimalPos !== 1 && value[0] === '0') {
      formattedValue = formattedValue.slice(1);
    }

    //max character limit
    if (value.length >= 4) {
      formattedValue = prevCond;

      if (parseFloat(value) > 9.9) {
        formattedValue = '9.9';
      }
    } else if (formattedValue.length === 3 && formattedValue[2] === '.') {
      formattedValue = '9.9';
    }

    return formattedValue;
  };

  formatMMFromBlur = (value) => {
    let formattedValue = value;

    if (formattedValue.length < 3) {
      if (value.indexOf('.') === value.length - 1 && value !== '') {
        formattedValue = formattedValue + '0';
      }
    }

    return formattedValue;
  };

  makeRadio = (
    name,
    group,
    value,
    { cond = null, textValue = null, textUnits = 'mm', infoEl = null, disabled = false, forceCheck = false } = {},
    disabledEdit = false
  ) => {
    let groupText = cond ? cond + '-' + group : group;
    let cID = groupText + '-' + value;

    let state = cond ? this.getConditionState(cond) : this.props;
    let isChecked = (state[group] === value || forceCheck) && !disabled;

    let updateF = (group) => {
      let func = (evt) => {
        let ns = {};

        if (group.indexOf('_by') >= 0) {
          ns[group] = this.formatMMEntry(cond, group, evt.target.value);
        } else {
          ns[group] = evt.target.value;
        }

        if (group === 'improve') {
          ns['improve_ipr'] = false;
          ns['procline'] = false;
        }

        if (cond) {
          this.props.setPlanConditionState(cond, ns);
        } else {
          this.props.setPlanState(ns);
        }

        this.props.onFocus();
      };

      return func;
    };

    let updateFBlur = (group) => {
      let func = (evt) => {
        let ns = {};

        if (group.indexOf('_by') >= 0) {
          ns[group] = this.formatMMFromBlur(evt.target.value);
        } else {
          ns[group] = evt.target.value;
        }

        if (cond) {
          this.props.setPlanConditionState(cond, ns);
        } else {
          this.props.setPlanState(ns);
        }
      };

      return func;
    };

    let clickRadio = (event) => {
      const id = event.currentTarget.dataset.id;
      const disabled = event.currentTarget.dataset.disabled;
      const radio = document.getElementById(id);

      if (radio && disabled === 'true') {
        radio.click();
      }
    };

    return (
      <div className="ib-radio">
        <label className={'form-check-label ' + (disabled ? 'disabled' : '')}>
          <input
            className="form-check-input"
            type="radio"
            name={groupText}
            id={cID}
            value={value}
            checked={isChecked && !disabled}
            onChange={updateF(group)}
            disabled={disabled || disabledEdit}
          />{' '}
          {name} {infoEl}
        </label>{' '}
        {textValue ? (
          <span data-id={cID} data-disabled={!isChecked} onClick={clickRadio}>
            <input
              type="text"
              className="form-control inline-block mm-size"
              disabled={!isChecked || disabledEdit}
              placeholder={isChecked ? '0.0' : ''}
              value={isChecked ? state[textValue] : ''}
              onChange={updateF(textValue)}
              ref={cID}
              onBlur={updateFBlur(textValue)}
            />
          </span>
        ) : null}
        {textValue && textUnits ? <span>mm</span> : null}
      </div>
    );
  };

  checkPreset = (n, teeth, tset) => {
    let diff = _.xor(this.presets[n], teeth);
    diff = _.difference(diff, tset.bracketDisabled);
    return diff.length === 0;
  };

  getImpliedTeethSets = () => {
    return this.props.getImpliedTeethSets();
  };

  makeAuxNotes = (
    test,
    condition,
    state_key,
    onFocus_clearWarning = () => {
      return;
    },
    disabledEdit = false
  ) => {
    const placeholder = 'Please describe auxiliaries to be used for treatment...';
    let onChange = (evt) => {
      let ns = {};
      let value = removeEmoji(evt.target.value);
      ns[state_key] = textFieldLimited(value);
      this.setConditionState(condition, ns);
    };
    let state = this.getConditionState(condition);
    if (!test) return null;

    return (
      <div className="form-group aux-box">
        <TextArea
          id={state['title'].toLowerCase().replace(/[ /]/, '_') + '_box'}
          className="form-control"
          rows="2"
          value={state[state_key]}
          onChange={onChange}
          onFocus={() => {
            onFocus_clearWarning();
          }}
          placeholder={placeholder}
          disabled={disabledEdit}
        />
        {this.makeWarning(test, 'Smile Design will depict full improvement. Auxiliaries or light force elastics to be provided by the treating doctor.')}
      </div>
    );
  };

  makeNotesBox = (
    title,
    nrows,
    state_key,
    value,
    placeholder = false,
    disabled = false,
    onFocus_clearWarning = () => {
      return;
    },
    metAllPlanRequiredField = () => {
      return;
    },
    dataTestId = undefined
  ) => {
    let onChange = (evt) => {
      let ns = {};
      let value = removeEmoji(evt.target.value);
      ns[state_key] = textFieldCheck(value);
      if (metAllPlanRequiredField !== null && state_key === 'chief_complaint') {
        metAllPlanRequiredField({ chief_complaint: value });
      } else if (metAllPlanRequiredField !== null && state_key === 'notes_spacing') {
        metAllPlanRequiredField({ notes_spacing: value });
      }

      this.props.setPlanState(ns);
    };

    return (
      <QBox title={!placeholder ? title : null} placeholder={placeholder} dataTestId={dataTestId}>
        <div className="form-group">
          <TextArea
            id={state_key}
            rows={nrows}
            value={value}
            onChange={onChange}
            placeholder={placeholder ? title : title === 'Additional spacing instructions' ? 'Please describe any additional spacing instructions' : null}
            onFocus={() => {
              onFocus_clearWarning();
              metAllPlanRequiredField();
            }}
            disabled={disabled}
          />
        </div>
      </QBox>
    );
  };

  render() {
    let tset = this.getImpliedTeethSets();

    let presetChoice = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, this.props.bracketTeeth, tset);
    });

    let presetMax = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, Teeth.All, tset);
    });

    presetMax = presetMax ? presetMax : this.maxPreset + 1;

    let makePresetButton = (n) => {
      return (
        <button
          key={n}
          type="button"
          onClick={() => this.setPreset(n)}
          className={'btn-ib ' + (n === presetChoice ? 'btn-ib--active ' : '') + (n > presetMax || this.props.disabledEdit ? 'btn-ib--disabled' : '')}
          disabled={this.props.disabledEdit}
        >
          {n}x{n}
        </button>
      );
    };

    let makeNotesBox = this.makeNotesBox.bind(this);
    let anyConditions = _.some(this.props.conditions, 'active');

    return (
      <>
        {!this.props.hideAlert && (
          <AlertMessage
            message="Based on the complexity of the initial malocclusion, your case may require additional orthodontic auxiliaries and/or appliances for predictable and
          successful case completion."
            theme="info"
          />
        )}
        <div className="col-md-8">
          <h3 id="chief_complaint_title" className="tx-heading">
            Patient Chief Complaint*
          </h3>
          {makeNotesBox(
            "Please describe the patient's chief complaint",
            4,
            'chief_complaint',
            this.props.chief_complaint,
            true,
            this.props.disabledEdit,
            this.props.onFocus,
            this.props.metAllPlanRequiredField,
            "tx_chief_complaint_box"
          )}

          <h3 className="tx-heading">Extent of Treatment</h3>

          <QBox title="Missing/Previously extracted teeth" dataTestId={'tx_missing_teeth_box'}>
            <TeethSelector
              selected={this.props.missingTeeth}
              onClick={this.props.handleTeethClick('missingTeeth')}
              hasUpper={true}
              hasLower={true}
              disabledEdit={this.props.disabledEdit}
            />
          </QBox>

          <QBox title="Teeth to be extracted" anchor="to_be_extracted" dataTestId={'tx_extract_teeth'}>
            <TeethSelector
              selected={this.props.extractTeeth}
              onClick={this.props.handleTeethClick('extractTeeth')}
              disabled={tset.extractDisabled}
              disabledEdit={this.props.disabledEdit}
              hasUpper={true || tset.hasUpper || tset.setupIdeal}
              hasLower={true || tset.hasLower || tset.setupIdeal}
            />
            {this.makeWarning(
              tset.extractTeeth.length > 0,
              'InBrace is best suited to close spaces up to 3.5mm. If the spaces are greater than 3.5mm, an InBrace representative will contact you.'
            )}
          </QBox>

          <QBox title="Arch to treat with InBrace" id="arch_to_treat" required="true" dataTestId={'tx_arch_to_treat_box'}>
            {this.makeRadio('Both arches', 'archToTreat', 'both', {}, this.props.disabledEdit)}
            {this.makeRadio('Upper arch', 'archToTreat', 'upper', {}, this.props.disabledEdit)}
            {this.props.archToTreat === 'upper' ? (
              <span className="opposing-arch">
                {this.makeRadio('Lower arch will be untreated', 'opposingArch', 'current', {}, this.props.disabledEdit)}{' '}
                {this.makeRadio('Lower arch will be treated with an alternate system', 'opposingArch', 'ideal', {}, this.props.disabledEdit)}
              </span>
            ) : null}

            {this.makeRadio('Lower arch', 'archToTreat', 'lower', {}, this.props.disabledEdit)}
            {this.props.archToTreat === 'lower' ? (
              <span className="opposing-arch">
                {this.makeRadio('Upper arch will be untreated', 'opposingArch', 'current', {}, this.props.disabledEdit)}{' '}
                {this.makeRadio('Upper arch will be treated with an alternate system', 'opposingArch', 'ideal', {}, this.props.disabledEdit)}
              </span>
            ) : null}
          </QBox>

          <QBox title="Bracket placement" id="bracket_teeth" required="true" dataTestId={'tx_bracket_teeth_box'}>
            <div className="bracket-preset">
              Presets:{' '}
              <div className="btn-bar" role="toolbar" id="bracket-presets">
                {_.range(this.minPreset, this.maxPreset + 1)
                  .reverse()
                  .map((n) => makePresetButton(n))}
              </div>
            </div>
            <TeethSelector
              selected={this.props.bracketTeeth}
              onClick={this.props.handleTeethClick('bracketTeeth')}
              disabled={_.union(tset.extractDisabled, ['UL8', 'LL8', 'UR8', 'LR8'], tset.extractTeeth)}
              disabledEdit={this.props.disabledEdit}
              hasUpper={tset.hasUpper}
              hasLower={tset.hasLower}
            />
          </QBox>

          <QBox title="Do not move the following teeth" dataTestId={'tx_restrict_teeth_box'}>
            <TeethSelector
              selected={_.filter(_.union(this.props.restrictTeeth, tset.restrictImplied), (value) => {
                return value.indexOf('8') === -1;
              })}
              onClick={this.props.handleTeethClick('restrictTeeth')}
              disabled={tset.restrictImplied}
              disabledEdit={this.props.disabledEdit}
              hasUpper={tset.hasUpper || tset.setupIdeal}
              hasLower={tset.hasLower || tset.setupIdeal}
              className="selection-green"
            />
          </QBox>

          <h3 className="tx-heading">Clinical Conditions</h3>
          <QBox title="Select conditions to treat" id="clinical_condition" required="true" dataTestId={'tx_clinical_condition_box'}>
            <div id="clinical-conditions">
              <ClinicalConditions conditions={this.props.conditions} setConditionState={this.setConditionState} disabled={this.props.disabledEdit} />
            </div>
          </QBox>

          {anyConditions ? <h3 className="tx-heading">Treatment</h3> : null}
          {Object.entries(this.props.conditions).map(([key, state]) => {
            if (state.active) {
              let setInfo = (infoEl) => {
                this.setConditionState(key, { infoEl: infoEl });
              };
              if (state.info_title) setInfo = null;
              return (
                <QBox
                  key={key}
                  id={
                    // eslint-disable-next-line
                    state.title.toLowerCase().replace(/[ \/]/g, '_') + '_title'
                  }
                  title={state.title}
                  title_alt={state.title_alt}
                  info={state.info}
                  setInfo={setInfo}
                  dataTestId={'tx_' + key + '_box'}
                >
                  {this['make_' + key](state, key, tset)}
                </QBox>
              );
            } else {
              return null;
            }
          })}

          <h3 className="tx-heading">Special Instructions</h3>
          {makeNotesBox('Describe any special instructions or conditions', 4, 'notes', this.props.notes, true, this.props.disabledEdit)}
        </div>
        <div className="col-md-4 tr-viewer-container-parent">
          <div className="tr-viewer-container">
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                const has_permission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);

                return (
                  <RecordViewer
                    records={this.props.record_states}
                    onLoad={this.props.onLoadImageRecord}
                    onClick={(id, action) => {
                      onImageToolAction(id, action, this.props);
                    }}
                    disabled={!has_permission || this.props.disabledEdit}
                  >
                    <ToothViewer teethSets={tset} thumbs={this.state.thumbs} />
                  </RecordViewer>
                );
              }}
            </UserPermissionsContext.Consumer>
          </div>
        </div>
      </>
    );
  }

  make_ap_relationship = (state, condition, tset) => {
    let usesAux = state['ap_improvement'] === 'auxiliaries';

    return (
      <div>
        {this.makeRadio('Maintain current AP relationship', 'ap_improvement', 'maintain', { cond: condition }, this.props.disabledEdit)}
        {this.makeRadio(
          'Improve with InBrace and light force elastics up to 1.5mm for Class II or 1mm for Class III discrepancies',
          'ap_improvement',
          'inbrace',
          { cond: condition },
          this.props.disabledEdit
        )}
        {this.makeRadio('Fully improve with auxiliaries', 'ap_improvement', 'auxiliaries', { cond: condition }, this.props.disabledEdit)}
        {this.makeAuxNotes(usesAux, condition, 'aux_note', this.props.onFocus, this.props.disabledEdit)}
        {this.makeWarning(state['ap_improvement'] === 'inbrace', ['Light force elastics to be provided by the treating doctor.'])}
      </div>
    );
  };

  make_midlines = (state, condition, tset) => {
    let disableUpper =
      _.intersection(_.difference(tset.restrictTeeth, _.union(tset.extractTeeth, tset.missingTeeth)), ['UL1', 'UR1']).length > 0 &&
      this.props.archToTreat === 'both';
    let disableLower =
      _.intersection(_.difference(tset.restrictTeeth, _.union(tset.extractTeeth, tset.missingTeeth)), ['LL1', 'LR1']).length > 0 &&
      this.props.archToTreat === 'both';
    let disableWarning = '';

    if (disableUpper && disableLower) disableWarning = 'Upper and lower midlines';
    else if (disableUpper) disableWarning = 'Upper midline';
    else if (disableLower) disableWarning = 'Lower midline';
    if (disableUpper || disableLower) disableWarning += ' must be maintained due to movement restrictions.';

    return (
      <div>
        {tset.hasUpper || tset.setupIdeal ? (
          <div className="row">
            <div className="col-md-12">
              <h4 className="tx-heading-h4">Upper:</h4>
            </div>
            <div className="col-md-6">
              {this.makeRadio('Maintain midline', 'upper_mid_improve', 'maintain', { cond: condition, forceCheck: disableUpper }, this.props.disabledEdit)}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                'Match to lower midline',
                'upper_mid_improve',
                'match',
                {
                  cond: condition,
                  forceCheck: disableUpper,
                  disabled: state.lower_mid_improve === 'match' || disableUpper,
                },
                this.props.disabledEdit
              )}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                "Move to the patient's right",
                'upper_mid_improve',
                'right',
                {
                  cond: condition,
                  textValue: 'upper_mid_improve_right_by',
                  disabled: disableUpper,
                },
                this.props.disabledEdit
              )}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                "Move to the patient's left",
                'upper_mid_improve',
                'left',
                {
                  cond: condition,
                  textValue: 'upper_mid_improve_left_by',
                  disabled: disableUpper,
                },
                this.props.disabledEdit
              )}
            </div>
          </div>
        ) : null}

        {tset.hasLower || tset.setupIdeal ? (
          <div className="row">
            <div className="col-md-12">
              <h4 className="tx-heading-h4">Lower:</h4>
            </div>
            <div className="col-md-6">
              {this.makeRadio('Maintain midline', 'lower_mid_improve', 'maintain', { cond: condition, forceCheck: disableLower }, this.props.disabledEdit)}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                'Match to upper midline',
                'lower_mid_improve',
                'match',
                {
                  cond: condition,
                  // forceCheck: disableLower,
                  disabled: state.upper_mid_improve === 'match' || disableLower,
                },
                this.props.disabledEdit
              )}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                "Move to the patient's right",
                'lower_mid_improve',
                'right',
                {
                  cond: condition,
                  textValue: 'lower_mid_improve_right_by',
                  disabled: disableLower,
                },
                this.props.disabledEdit
              )}
            </div>
            <div className="col-md-6">
              {this.makeRadio(
                "Move to the patient's left",
                'lower_mid_improve',
                'left',
                {
                  cond: condition,
                  textValue: 'lower_mid_improve_left_by',
                  disabled: disableLower,
                },
                this.props.disabledEdit
              )}
            </div>
          </div>
        ) : null}
        <div>{this.makeWarning(disableUpper || disableLower, disableWarning)}</div>
      </div>
    );
  };

  make_crowding = (state, condition, tset) => {
    let kvs = {
      expand: 'Expand',
      procline: 'Procline',
      ipr_ant: 'IPR Anterior',
      ipr_right: 'IPR Posterior Right',
      ipr_left: 'IPR Posterior Left',
    };
    let makeCB = (key, prefix) => {
      let title = kvs[key];
      return (
        <div key={key}>
          <div className="col-md-4 label-right">{title}</div>
          <div className="col-md-8">{this.makeCheckBar(condition, prefix + '_' + key, this.props.disabledEdit)}</div>
        </div>
      );
    };

    return (
      <div id="improve-crowding" className="row">
        {tset.hasUpper || tset.setupIdeal ? (
          <div>
            <div className="col-md-12">
              <h4 className="tx-heading-h4">Resolve upper crowding up to 6mm:</h4>
            </div>
            {Object.keys(kvs).map((key) => makeCB(key, 'upper'))}
          </div>
        ) : null}

        {tset.hasLower || tset.setupIdeal ? (
          <div>
            <div className="col-md-12">
              <h4 className="tx-heading-h4">Resolve lower crowding up to 6mm:</h4>
            </div>
            {Object.keys(kvs).map((key) => makeCB(key, 'lower'))}
          </div>
        ) : null}
        <div>
          <div className="col-md-12">
            {this.makeWarning(
              true,
              <div>
                If extractions are required to resolve crowding, please specify in the{' '}
                <a className="midnight" href="#to_be_extracted">
                  Teeth to be Extracted
                </a>{' '}
                section above.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  make_spacing = (state, condition, tset) => {
    let upperTeeth = Teeth.UR.concat(Teeth.UL);
    let lowerTeeth = Teeth.LR.concat(Teeth.LL);
    let showChart = state['spaces'] === 'leave';
    let spaceTeeth = new Set(state.spaceTeeth);

    let onSpaceClick = (evt, tn) => {
      let sel = new Set(state.spaceTeeth);
      if (evt.target.checked) {
        sel.add(tn);
      } else {
        sel.delete(tn);
      }
      this.setConditionState(condition, { spaceTeeth: Array.from(sel) });
    };

    // eslint-disable-next-line
    let makeTeethChart = (teeth) => {
      let teeth2 = _.difference(teeth, tset.extractTeethAll);

      return (
        <div className="spacing-chart-line">
          {teeth2.slice(0).map((tn, idx) => {
            let isLast = idx === teeth2.length - 1;
            return (
              <React.Fragment key={tn}>
                <div className="spacing-chart-tooth">{tn}</div>{' '}
                {isLast ? null : (
                  <input type="checkbox" disabled={this.props.disabledEdit} checked={spaceTeeth.has(tn)} onClick={(evt) => onSpaceClick(evt, tn)} />
                )}{' '}
              </React.Fragment>
            );
          })}
        </div>
      );
    };

    let hasMissingTeeth = (tn) => {
      let teeth_match = _.intersection(tset.extractTeethAll, [tn]);
      return teeth_match.length > 0;
    };

    let getTeethPosition = (tn, teeth) => {
      let position = 0;

      for (let i = 0; i < teeth.length; i++) {
        if (tn === teeth[i]) {
          position = i;
          break;
        }
      }

      return position;
    };

    let getArrayOfTeeth = (teeth) => {
      let array = [];

      for (let i = 0; i < teeth.length; i++) {
        if (hasMissingTeeth(teeth[i])) {
          array.push(0);
        } else {
          array.push(1);
        }
      }

      return array;
    };

    let hasMissingTeethToEnd = (tn) => {
      let upperTeeth = Teeth.UR.concat(Teeth.UL);
      let lowerTeeth = Teeth.LR.concat(Teeth.LL);
      let upperTeethArray = getArrayOfTeeth(upperTeeth);
      let lowerTeethArray = getArrayOfTeeth(lowerTeeth);
      let position = 0;
      let teethArray = [];

      if (tn.includes('U')) {
        position = getTeethPosition(tn, upperTeeth) + 1;
        teethArray = upperTeethArray.slice(position);
      } else {
        position = getTeethPosition(tn, lowerTeeth) + 1;
        teethArray = lowerTeethArray.slice(position);
      }

      return _.sum(teethArray) === 0;
    };

    let hasBubbleBox = (tn) => {
      // bubble index starts at UR8
      let upperTeeth = Teeth.UR.concat(Teeth.UL);
      let lowerTeeth = Teeth.LR.concat(Teeth.LL);
      let upperTeethArray = getArrayOfTeeth(upperTeeth);
      let lowerTeethArray = getArrayOfTeeth(lowerTeeth);
      let position = 0;
      let teethArray = [];

      if (tn.includes('U')) {
        position = getTeethPosition(tn, upperTeeth);
        teethArray = upperTeethArray.slice(position);
      } else {
        position = getTeethPosition(tn, lowerTeeth);
        teethArray = lowerTeethArray.slice(position);
      }

      return _.sum([teethArray[0], teethArray[1]]) === 2 || (teethArray[0] && !hasMissingTeethToEnd(tn));
    };

    //Creates the chart
    let makeSpacingChart = (teeth, onFocus_clearWarning) => {
      let teeth2 = _.difference(teeth, tset.extractTeethAll);
      let isUpperOnly = tset.hasUpper && !tset.hasLower;
      let isLowerOnly = !tset.hasUpper && tset.hasLower;

      return (
        <div className="spacing-center">
          {teeth[0].includes('U') ? (
            <table className={isUpperOnly ? 'spacing-chart-input-U spacing-chart-input-padding' : 'spacing-chart-input-U'}>
              <tbody>
                <tr className="tx-instruction">
                  {teeth.map((tn, idx) => {
                    return (
                      <td key={idx}>
                        {idx !== teeth.length - 1 && (tn.includes('UR') || tn.includes('UL')) && hasBubbleBox(tn) && teeth2.length > 1 ? (
                          <div>
                            {this.props.conditions.spacing.spacingUpper[idx] !== '' && !this.props.disabledEdit ? (
                              <span className="spacing-chart-x-upper" onClick={() => this.clearSpacingValue(idx, 'upper')}>
                                x
                              </span>
                            ) : null}
                            <input
                              className={
                                this.props.conditions.spacing.spacingUpper[idx] !== ''
                                  ? 'spacing-chart-input-upper spacing-chart-input-upper-lrg'
                                  : 'spacing-chart-input-upper'
                              }
                              onChange={(e) => {
                                this.onChangeSpacingValue(idx, e, 'upper');
                              }}
                              value={this.props.conditions.spacing.spacingUpper[idx]}
                              onBlur={(e) => {
                                this.onChangeSpacingOnBlur(idx, e, 'upper');
                              }}
                              onFocus={this.props.onFocus}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.target.blur();
                                }
                              }}
                              disabled={this.props.disabledEdit}
                              placeholder="0.0"
                            />
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
          <table className="spacing-chart">
            <tbody>
              <tr>
                {teeth.map((tn, idx) => {
                  return (
                    <td
                      key={idx}
                      className={
                        tn.includes('U')
                          ? hasBubbleBox(tn)
                            ? 'spacing-chart-upper-label'
                            : 'spacing-chart-upper-label spacing-chart-upper-label-hidden'
                          : hasBubbleBox(tn)
                          ? isLowerOnly
                            ? 'spacing-chart-lower-label spacing-chart-lower-label-border'
                            : 'spacing-chart-lower-label'
                          : isLowerOnly
                          ? 'spacing-chart-lower-label spacing-chart-lower-label-hidden spacing-chart-lower-label-border'
                          : 'spacing-chart-lower-label spacing-chart-lower-label-hidden'
                      }
                    >
                      {!hasMissingTeeth(tn) ? tn : null}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          {teeth[0].includes('LR') || teeth[0].includes('LL') ? (
            <table className="spacing-chart-input-L">
              <tbody>
                <tr className="tx-instruction">
                  {teeth.map((tn, idx) => {
                    return (
                      <td key={idx}>
                        {' '}
                        {idx !== teeth.length - 1 && (tn.includes('LR') || tn.includes('LL')) && hasBubbleBox(tn) && teeth2.length > 1 ? (
                          <div>
                            <input
                              className={
                                this.props.conditions.spacing.spacingLower[idx] !== ''
                                  ? 'spacing-chart-input-lower spacing-chart-input-lower-lrg'
                                  : 'spacing-chart-input-lower'
                              }
                              onChange={(e) => {
                                this.onChangeSpacingValue(idx, e, 'lower');
                              }}
                              value={this.props.conditions.spacing.spacingLower[idx]}
                              onBlur={(e) => {
                                this.onChangeSpacingOnBlur(idx, e, 'lower');
                              }}
                              onFocus={this.props.onFocus}
                              disabled={this.props.disabledEdit}
                              placeholder="0.0"
                            />
                            {this.props.conditions.spacing.spacingLower[idx] !== '' && !this.props.disabledEdit ? (
                              <span className="spacing-chart-x-lower" onClick={() => this.clearSpacingValue(idx, 'lower')}>
                                x
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
      );
    };

    return (
      <div id="spacing-box">
        {this.makeRadio('Close all spaces', 'spaces', 'close', { cond: condition }, this.props.disabledEdit)}
        {this.makeRadio('Leave spaces (mm)', 'spaces', 'leave', { cond: condition }, this.props.disabledEdit)}

        <div
          className={
            this.props.conditions && this.props.conditions.spacing && this.props.conditions.spacing.spaces === 'leave'
              ? tset.hasUpper && tset.hasLower
                ? 'spacing-chart-zone'
                : tset.hasUpper
                ? 'spacing-chart-zone upper-only'
                : tset.hasLower
                ? 'spacing-chart-zone lower-only'
                : null
              : null
          }
        >
          {showChart && (tset.hasUpper || tset.setupIdeal) ? makeSpacingChart(upperTeeth) : null}
          {showChart && (tset.hasLower || tset.setupIdeal) ? makeSpacingChart(lowerTeeth) : null}
          {this.props.conditions && this.props.conditions.spacing && this.props.conditions.spacing.spaces === 'leave'
            ? this.makeNotesBox(
                'Additional spacing instructions',
                4,
                'notes_spacing',
                this.props.notes_spacing,
                false,
                this.props.disabledEdit,
                this.props.onFocus,
                this.props.metAllPlanRequiredField
              )
            : null}
        </div>
      </div>
    );
  };

  make_overjet = (state, condition, tset) => {
    let opts = (
      <React.Fragment>
        {this.makeCheckbox('Improve with IPR', 'improve_ipr', { cond: condition }, this.props.disabledEdit)}
        {tset.hasLower || tset.setupIdeal ? this.makeCheckbox('Procline lower anteriors', 'procline', { cond: condition }, this.props.disabledEdit) : null}
      </React.Fragment>
    );
    let usesAux = state['improve'] === 'auxiliaries';

    return (
      <div className="overjet">
        {this.makeRadio('Improve with InBrace only', 'improve', 'inbrace', { cond: condition }, this.props.disabledEdit)}
        {state.improve === 'inbrace' ? opts : null}
        {this.makeRadio('Fully improve with light force elastics or auxiliaries', 'improve', 'auxiliaries', { cond: condition }, this.props.disabledEdit)}
        {state.improve === 'auxiliaries' ? opts : null}
        {this.makeAuxNotes(usesAux, condition, 'aux_note', this.props.onFocus, this.props.disabledEdit)}
      </div>
    );
  };

  make_deepbite = (state, condition, tset) => {
    let usesAux = state['correct'] === 'auxiliaries';
    return (
      <div>
        {this.makeRadio('Improve with InBrace up to 6mm', 'correct', 'inbrace', { cond: condition }, this.props.disabledEdit)}
        {this.makeRadio('Fully improve with light force elastics or auxiliaries', 'correct', 'auxiliaries', { cond: condition }, this.props.disabledEdit)}
        {this.makeAuxNotes(usesAux, condition, 'aux_note', this.props.onFocus, this.props.disabledEdit)}
      </div>
    );
  };

  make_openbite = (state, condition, tset) => {
    return (
      <div>
        {this.makeRadio('Maintain open bite', 'improve_amt', 'maintain', { cond: condition }, this.props.disabledEdit)}
        {this.makeRadio('Improve with InBrace up to 3mm with light force elastics', 'improve_amt', 'elastics', { cond: condition }, this.props.disabledEdit)}
        {this.makeWarning(state['improve_amt'] === 'elastics', 'Light force elastics to be provided by the treating doctor.')}
      </div>
    );
  };

  make_crossbite = (state, condition, tset) => {
    let upperQ = tset.hasUpper || tset.setupIdeal;
    let lowerQ = tset.hasLower || tset.setupIdeal;
    let archClass = upperQ && lowerQ ? 'col-md-6' : 'col-md-12';
    let checkF = (key) => {
      return (isChecked) => {
        let ns = {};
        if (isChecked) ns[key] = false;
        return ns;
      };
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <h4 className="tx-heading-h4">Improve Anterior Crossbite:</h4>
        </div>
        {upperQ ? (
          <div className="col-md-6">
            {this.makeCheckbox('Advance upper', 'anterior_advance_upper', { cond: condition }, this.props.disabledEdit, this.props.onFocus)}
          </div>
        ) : null}
        {lowerQ ? (
          <div className="col-md-6">
            {this.makeCheckbox('Retract lower', 'anterior_retract_lower', { cond: condition }, this.props.disabledEdit, this.props.onFocus)}
          </div>
        ) : null}

        <div className="col-md-12">
          <h4 className="tx-heading-h4">Improve Posterior Crossbite:</h4>
        </div>
        {upperQ ? (
          <div className={archClass}>
            {this.makeCheckbox(
              'Expand upper',
              'posterior_expand_upper',
              { cond: condition, onCheck: checkF('posterior_narrow_upper') },
              this.props.disabledEdit,
              this.props.onFocus
            )}
          </div>
        ) : null}

        {upperQ ? (
          <div className={archClass ? archClass + ' ib-checkbox-no-space' : archClass}>
            {this.makeCheckbox(
              'Constrict upper',
              'posterior_narrow_upper',
              { cond: condition, onCheck: checkF('posterior_expand_upper') },
              this.props.disabledEdit,
              this.props.onFocus
            )}
          </div>
        ) : null}

        {lowerQ ? (
          <div className={archClass}>
            {this.makeCheckbox(
              'Expand lower',
              'posterior_expand_lower',
              { cond: condition, onCheck: checkF('posterior_narrow_lower') },
              this.props.disabledEdit,
              this.props.onFocus
            )}
          </div>
        ) : null}

        {lowerQ ? (
          <div className={archClass ? archClass + ' ib-checkbox-no-space' : archClass}>
            {this.makeCheckbox(
              'Constrict lower',
              'posterior_narrow_lower',
              { cond: condition, onCheck: checkF('posterior_expand_lower') },
              this.props.disabledEdit,
              this.props.onFocus
            )}
          </div>
        ) : null}

        <div className="col-md-12">
          {this.makeWarning(
            true,
            'It is recommended to use auxiliaries to treat crossbite. Archform can be minimally modified to address posterior crossbite with InBrace alone.'
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TxPlan));

export { Teeth };
