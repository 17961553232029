import Axios from 'axios';

//Action Imports
import { fetchIfuState } from './ifu_state';

// Action Types
export const MARK_AS_SUCCESS_PENDING = 'MARK_AS_SUCCESS_PENDING';
export const MARK_AS_SUCCESS_SUCCESS = 'MARK_AS_SUCCESS_SUCCESS';
export const MARK_AS_SUCCESS_ERROR = 'MARK_AS_SUCCESS_ERROR';
export const OPEN_MARK_AS_SUCCESS_MODAL = 'OPEN_MARK_AS_SUCCESS_MODAL';
export const CLOSE_MARK_AS_SUCCESS_MODAL = 'CLOSE_MARK_AS_SUCCESS_MODAL';

export const MARK_AS_FAILURE_PENDING = 'MARK_AS_FAILURE_PENDING';
export const MARK_AS_FAILURE_SUCCESS = 'MARK_AS_FAILURE_SUCCESS';
export const MARK_AS_FAILURE_ERROR = 'MARK_AS_FAILURE_ERROR';
export const OPEN_MARK_AS_FAILURE_MODAL = 'OPEN_MARK_AS_FAILURE_MODAL';
export const CLOSE_MARK_AS_FAILURE_MODAL = 'CLOSE_MARK_AS_FAILURE_MODAL';

export const OVERRIDE_PENDING = 'OVERRIDE_PENDING';
export const OVERRIDE_SUCCESS = 'OVERRIDE_SUCCESS';
export const OVERRIDE_ERROR = 'OVERRIDE_ERROR';
export const OPEN_OVERRIDE_MODAL = 'OPEN_OVERRIDE_MODAL';
export const CLOSE_OVERRIDE_MODAL = 'CLOSE_OVERRIDE_MODAL';

// Action Creators
// -----------------------------------------------------------------------------

// Modals

export function openMarkAsSuccessModal() {
  return {
    type: OPEN_MARK_AS_SUCCESS_MODAL,
  };
}
export function closeMarkAsSuccessModal() {
  return {
    type: CLOSE_MARK_AS_SUCCESS_MODAL,
  };
}
export function markAsSuccessPending() {
  return {
    type: MARK_AS_SUCCESS_PENDING,
  };
}
export function markAsSuccessSuccess() {
  return {
    type: MARK_AS_SUCCESS_SUCCESS,
  };
}
export function markAsSuccessError(error) {
  const refresh = error.response.status === 409 ? true : false;

  return {
    type: MARK_AS_SUCCESS_ERROR,
    error: error,
    refresh: refresh,
  };
}

export function openMarkAsFailureModal() {
  return {
    type: OPEN_MARK_AS_FAILURE_MODAL,
  };
}
export function closeMarkAsFailureModal() {
  return {
    type: CLOSE_MARK_AS_FAILURE_MODAL,
  };
}
export function markAsFailureSuccess() {
  return {
    type: MARK_AS_FAILURE_SUCCESS,
  };
}
export function markAsFailureError(error) {
  const refresh = error.response.status === 409 ? true : false;

  return {
    type: MARK_AS_FAILURE_ERROR,
    error: error,
    refresh: refresh,
  };
}
export function markAsFailurePending() {
  return {
    type: MARK_AS_FAILURE_PENDING,
  };
}


export function openOverrideModal() {
  return {
    type: OPEN_OVERRIDE_MODAL,
  };
}
export function closeOverrideModal() {
  return {
    type: CLOSE_OVERRIDE_MODAL,
  };
}
export function overridePending() {
  return {
    type: OVERRIDE_PENDING,
  };
}
export function overrideError(error) {
  const refresh = error.response?.status === 409 ? true : false;

  return {
    type: OVERRIDE_ERROR,
    error: error,
    refresh: refresh,
  };
}
export function overrideSuccess() {
  return {
    type: OVERRIDE_SUCCESS,
  };
}

// Axios

// Mark Generation as Success
export function onConfirmButtonClickMarkAsSuccess() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(markAsSuccessPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ifu/force_success`)
      .then((res) => {
        dispatch(markAsSuccessSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeMarkAsSuccessModal());
      })
      .catch((error) => {
        dispatch(markAsSuccessError(error));
      });
  };
}



// Mark Generation as Failure
export function onConfirmButtonClickMarkAsFailure() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(markAsFailurePending());

    Axios.post(`/apiV2/cadaction/${case_id}/ifu/force_failure`)
      .then((res) => {
        dispatch(markAsFailureSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeMarkAsFailureModal());
      })
      .catch((error) => {
        dispatch(markAsFailureError(error));
      });
  };
}

