import React from 'react';
import Button from '../../setup_viewer/components/extras/button';
import WasmChangeSummary from '../wasm_changes_summary';
import { Verbiage } from '../verbiage.constants';

function ModelChangesModal(props) {
  const { close, movement, ipr_change, revision_note } = props;

  return (
    <div>
      <div className="modal-title">{Verbiage.ChangesModal.Title}</div>
      <div className="modal-body-paragraph">
        <WasmChangeSummary movement={movement} ipr_change={ipr_change} revision_note={revision_note} />
      </div>
      <div className="modal-button-group">
        <Button theme="secondary" onClick={close}>
          {Verbiage.ChangesModal.CloseBtn}
        </Button>
      </div>
    </div>
  );
}

export default ModelChangesModal;
