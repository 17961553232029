import React from 'react';
import { translateStatus } from '../../common/helpers';

/**
 * Display the case list search filter 
 * @component
 * @category BPP
 */
function CaseListSearch(props) {
  const { search, onChangeSearch, status_filter, onChangeStatusFilter, start_date, onChangeStartDate, end_date, onChangeEndDate, date_error, onSearch } = props;
  const onKeyPress = (event) => {
    if (event.key === 'Enter') onSearch();
  };
  const dateClasses = ['select-date-l'];
  if (date_error) dateClasses.push('date-error');

  return (
    <div className="dark-options">
      <div className="page-heading">Case List</div>
      <div className="dark-search">
        <input
          type="text"
          className="form-control search-bar-dark font-awesome"
          placeholder="Search"
          aria-describedby="basic-addon1"
          value={search}
          onChange={onChangeSearch}
          onKeyPress={onKeyPress}
        />
      </div>
      <div className="dark-filter">
        <div className="select-container">
          <select className="select-status" value={status_filter} onChange={onChangeStatusFilter}>
            <option value="">All Status</option>
            <option value="segmentation_process">Segmentation Process</option>
            <option value="checking">{translateStatus('INBRACE Checking Files')}</option>
            <option value="new">{translateStatus('Doctor Upload New Files')}</option>
            <option value="prepare">{translateStatus('INBRACE Preparing Setup')}</option>
            <option value="setup_ready_for_release">Smile Design Ready for Release</option>
            <option value="setup">{translateStatus('Doctor Approve or Revise Setup')}</option>
            <option value="cad_process">Appliance Design Process</option>
            <option value="qc_approval">Quality Control Review</option>
            <option value="manufacture">{translateStatus('INBRACE Manufacturing Case')}</option>
            <option value="ship">{translateStatus('Case Shipped')}</option>
            <option value="hold">{translateStatus('Hold')}</option>
            <option value="cancel">{translateStatus('Cancelled')}</option>
          </select>
        </div>
      </div>
      <div className="dark-filter">
        <div className="select-container-date">
          <div className="text-center light-gray-text">Submission Date Between</div>
          <div>
            <input className={dateClasses.join(' ')} type="date" name="start" value={start_date} onChange={onChangeStartDate} />
            <input className={dateClasses.join(' ')} type="date" name="end" value={end_date} onChange={onChangeEndDate} />
          </div>
        </div>
      </div>
      <button className="btn btn-light-3" onClick={onSearch}>
        <i className="fa fa-search" aria-hidden="true" />
      </button>
    </div>
  );
}

export default CaseListSearch;
