// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';

/**
 * Displays list of programs
 * @alias ProgramList
 * @component
 * @category BPP
 * @subcategory Program List
 */
class ProgramList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Retrieves program number to display based on program order
   * @function
   * @param program {object} - Program object
   * @return {number} - Program number
   */
  getProgramNumber = (program) => {
    return (
      this.props.programs
        .map(function (t) {
          return t.program_id;
        })
        .indexOf(program.program_id) + 1
    );
  };
  render() {
    return (
      <div className="program-list">
        <div className="settings-heading content-heading">Program Settings</div>
        <table>
          <thead>
            <tr className="settings-heading">
              <th>No.</th>
              <th>Program</th>
            </tr>
          </thead>
          <tbody>
            {this.props.programs_view.map((program, index) => {
              return (
                <tr
                  className={this.props.selected_program_id === program.program_id ? 'program-selected' : this.props.edit_mode ? 'program-disabled' : ''}
                  onClick={() => this.props.onProgramClick(program.program_id)}
                  key={index}
                >
                  <td>{this.getProgramNumber(program)}</td>
                  <td>
                    {`${program.name} `}
                    {!program?.current_ind ? <span className="color-grey"> - PROGRAM DISABLED</span> : ''}
                    {program?.levels?.current?.toLowerCase() === 'full release' ? <span className="color-grey"> - FULLY RELEASED</span> : ''}
                    {this.props.selected_program_id === program.program_id && <i className="fa fa-caret-right" aria-hidden="true" />}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ProgramList;
