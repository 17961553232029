import {
  FETCH_SHIPPO_REPORT_PENDING,
  FETCH_SHIPPO_REPORT_SUCCESS,
  FETCH_SHIPPO_REPORT_ERROR,
  FETCH_SHIPPO_REPORT_DATE_ERROR,
  SET_START_DATE,
  SET_END_DATE,
} from '../../actions/shippo/shippo';

const initialState = {
  shippo_report: null,
  pending: true,
  error: null,
  start_date: '',
  end_date: '',
  date_error: false,
  date_error_message: '',
};

export function shippoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPPO_REPORT_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_SHIPPO_REPORT_SUCCESS:
      return {
        ...state,
        pending: false,
        shippo_report: action.shippo_report,
        date_error: false,
        date_error_message: '',
      };

    case FETCH_SHIPPO_REPORT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case FETCH_SHIPPO_REPORT_DATE_ERROR:
      return {
        ...state,
        pending: false,
        date_error: true,
        date_error_message: action.error,
      };

    case SET_START_DATE:
      return {
        ...state,
        start_date: action.start_date,
        date_error: false,
        date_error_message: '',
      };

    case SET_END_DATE:
      return {
        ...state,
        end_date: action.end_date,
        date_error: false,
        date_error_message: '',
      };

    default:
      return state;
  }
}

export const getShippoReport = (state) => state.shippoReducer.shippo_report;
export const getPending = (state) => state.shippoReducer.pending;
export const getError = (state) => state.shippoReducer.error;
export const getDateError = (state) => state.shippoReducer.date_error;
export const getDateErrorMessage = (state) => state.shippoReducer.date_error_message;
export const getStartDate = (state) => state.shippoReducer.start_date;
export const getEndDate = (state) => state.shippoReducer.end_date;
