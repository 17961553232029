import React from 'react';

/**
 * Contains the heading for each history item
 * @component
 * @alias SetupViewerHistoryListHeading
 */
export default function HistoryListHeading(props) {
  return <div className="history-list-heading">{props.title}</div>;
}
