/**
 * File: content_detail.js - Contains the content for the doctors account details
 * Copyright: (c) Copyright August 2020 by InBrace
 * Author: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import Axios from 'axios';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Internal Components
import ContentUser from '../account_settings/content_user';
import Modal from '../../components/modal/modal';
import CircleLoader from '../../components/loader/circle_loader';
import LineDivision from '../components/divisions/line_division';
import AccountAddress from '../account_settings/account_address';
import AddEmail from '../account_details/add_email';
import AddTelephoneNumber from '../account_details/add_telephone_number';
import Button from '../components/buttons/button';
import Dropdown from '../components/inputs/dropdown';
import TextBox from '../components/inputs/text_box';

// Redux
import {
  getAccount,
  getAccountPending,
  getRole,
  getEditProviderId,
  getProviderId,
  getEditProfile,
  getNewProfile,
  getEditErrorMessage,
  getNewErrorMessage,
  getDisableAccountModal,
  getDsoInformation,
  getResetLinkAccountModal,
} from '../../redux/reducers/ipp/edit_account';
import {
  onRoleChange,
  toggleProviderIdEdit,
  onProviderIdChange,
  saveProviderId,
  setEditMode,
  setEditValue,
  saveEditChanges,
  clearEditMode,
  deleteEntry,
  setAddMode,
  setNewValue,
  clearNewMode,
  saveNewChanges,
  onEditProfileToggleAccount,
  closeDisableAccountModal,
  onDisableAccountClick,
  openResetLinkAccountModal,
  closeResetLinkAccountModal,
} from '../../redux/actions/ipp/edit_account';

import './edit_account.scss';

class ContentDetail extends Component {
  componentDidMount() {}

  isEnterKeyToSaveEditChanges = (e) => {
    if (e.key === 'Enter') {
      this.props.saveEditChanges();
    }
  };

  isEnterKeyToSaveNewChanges = (e) => {
    if (e.key === 'Enter') {
      this.props.saveNewChanges();
    }
  };

  isEditable = (email) => {
    return email.value !== this.props.account.account_email;
  };

  onClickSendNewPassword = () => {
    let that = this;

    Axios.post(`/api/email/?slug=password-reset-04082020&doctorId=${this.props.account.account_id}&provider=${window.location.origin}&method=standard`).then(
      function (res) {
        if (res && res.data && res.data.message === 'success') {
          that.props.openResetLinkAccountModal();
        }
      }
    );
  };

  displayAccountEnabled = () => {
    return (
      <div className="account-enabled">
        <span className="profile-w-button">Account Enabled</span>
        <span className="profile-info">
          <label className="switch">
            <input
              type="checkbox"
              checked={this.props.account.is_active}
              onChange={() => this.props.onEditProfileToggleAccount(this.props.account.is_active)}
            />

            <span className="slider round round-box" />
          </label>
          {/* {this.state.profile.is_active ? <span className="switch-label">On</span> : null} */}
          <span className="switch-label">On</span>
        </span>
      </div>
    );
  };

  render() {
    const options = [
      { label: 'Doctor', value: 'DSO_Doctor' },
      { label: 'Doctor Override', value: 'DSO_Doctor_Override' },
    ];

    let disable_account_props = {
      confirm_btn: true,
      close_btn: true,
      confirm_btn_text: 'Disable Account',
      close_btn_text: 'Cancel',
      onConfirmButtonClick: this.props.onDisableAccountClick,
      onCloseButtonClick: this.props.closeDisableAccountModal,
    };

    const dso_doctors = this.props.dso_info.dso_doctors.filter((d) => {
      return d.id !== this.props.account.account_id && d.id !== this.props.account.account_link_id && d.user__is_active;
    });

    if (dso_doctors.length === 0) {
      disable_account_props = {
        confirm_btn: false,
        close_btn: true,
        close_btn_text: 'OK',
        onCloseButtonClick: this.props.closeDisableAccountModal,
      };
    }

    const getDisplayLabel = (value) => {
      const selected_option = options.filter((option) => {
        return option.value === value;
      });

      return selected_option && selected_option.length > 0 ? selected_option : options[0];
    };

    const disabled = this.props.account && !this.props.account.is_active;

    return (
      <div>
        {this.props.account_pending ? (
          <CircleLoader fullscreen={true} />
        ) : (
          <div className="dso-account-profile">
            <div className="profile-header">
              <ContentUser
                name={this.props.account.first_name + ' ' + this.props.account.last_name}
                loginEmail={this.props.account.account_email}
                doctor_id={this.props.account.account_id}
                is_edit_dso_doctor_account_page={true}
                displayAccountEnabled={this.displayAccountEnabled()}
                edit_picture={this.props.account.is_active}
              />
            </div>
            <LineDivision />
            <div className="account__section">
              <div className="edit-table">
                <label className="control-label emphasis">Provider ID</label>
                <TextBox
                  value={!this.props.edit_provider_id && !this.props.provider_id ? 'None' : this.props.provider_id}
                  onClick={() => this.props.toggleProviderIdEdit(true)}
                  onChange={this.props.onProviderIdChange}
                  disabled={disabled}
                />
                {this.props.edit_provider_id ? (
                  <div className="heading-item-action">
                    <Button onClick={() => this.props.saveProviderId()}>Save</Button>
                    <Button theme="secondary" onClick={() => this.props.toggleProviderIdEdit(false)}>
                      Cancel
                    </Button>
                  </div>
                ) : null}
                <div className="dso-role">
                  <label className="control-label emphasis">Role</label>
                  <Dropdown disabled={disabled} value={getDisplayLabel(this.props.role)} onChange={this.props.onRoleChange} options={options} />
                </div>

                {this.props.account.is_active ? (
                  // eslint-disable-next-line
                  <a className="midnight" id="send_reset_link" onClick={this.onClickSendNewPassword}>
                    Send Password Reset Link
                  </a>
                ) : (
                  // eslint-disable-next-line
                  <a className="not-allowed" id="send_reset_link">
                    Send Password Reset Link
                  </a>
                )}
              </div>
            </div>
            <LineDivision />
            <AccountAddress account={this.props.account} />

            <LineDivision />
            <AddEmail
              account={this.props.account}
              new_profile={this.props.new_profile}
              edit_profile={this.props.edit_profile}
              setAddMode={this.props.setAddMode}
              setEditMode={this.props.setEditMode}
              setEditValue={this.props.setEditValue}
              setNewValue={this.props.setNewValue}
              clearEditMode={this.props.clearEditMode}
              clearNewMode={this.props.clearNewMode}
              isEnterKeyToSaveEditChanges={this.isEnterKeyToSaveEditChanges}
              isEnterKeyToSaveNewChanges={this.isEnterKeyToSaveNewChanges}
              saveEditChanges={this.props.saveEditChanges}
              saveNewChanges={this.props.saveNewChanges}
              deleteEntry={this.props.deleteEntry}
              disabled={disabled}
            />

            <LineDivision />
            <AddTelephoneNumber
              account={this.props.account}
              new_profile={this.props.new_profile}
              edit_profile={this.props.edit_profile}
              setAddMode={this.props.setAddMode}
              setEditMode={this.props.setEditMode}
              setEditValue={this.props.setEditValue}
              setNewValue={this.props.setNewValue}
              clearEditMode={this.props.clearEditMode}
              clearNewMode={this.props.clearNewMode}
              isEnterKeyToSaveEditChanges={this.isEnterKeyToSaveEditChanges}
              isEnterKeyToSaveNewChanges={this.isEnterKeyToSaveNewChanges}
              saveEditChanges={this.props.saveEditChanges}
              saveNewChanges={this.props.saveNewChanges}
              deleteEntry={this.props.deleteEntry}
              disabled={disabled}
              new_error_msg={this.props.new_error_msg}
              edit_error_msg={this.props.edit_error_msg}
            />
            {this.props.disable_account_modal ? (
              <Modal
                preset="disable-account"
                header_text="Disable Account"
                modal_size_class="modal-lg"
                theme="ipp"
                dso_doctors={dso_doctors}
                {...disable_account_props}
              />
            ) : null}

            {this.props.reset_link_account_modal ? (
              <Modal
                header_text="Send Password Reset"
                message_text="A new password reset email has been sent"
                onCloseButtonClick={this.props.closeResetLinkAccountModal}
                theme="ipp"
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    account_pending: getAccountPending(state),
    role: getRole(state),
    edit_provider_id: getEditProviderId(state),
    provider_id: getProviderId(state),
    edit_profile: getEditProfile(state),
    new_profile: getNewProfile(state),
    edit_error_msg: getEditErrorMessage(state),
    new_error_msg: getNewErrorMessage(state),
    disable_account_modal: getDisableAccountModal(state),
    dso_info: getDsoInformation(state),
    reset_link_account_modal: getResetLinkAccountModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onRoleChange: onRoleChange,
      toggleProviderIdEdit: toggleProviderIdEdit,
      onProviderIdChange: onProviderIdChange,
      saveProviderId: saveProviderId,
      setEditMode: setEditMode,
      setEditValue: setEditValue,
      saveEditChanges: saveEditChanges,
      clearEditMode: clearEditMode,
      deleteEntry: deleteEntry,
      setAddMode: setAddMode,
      setNewValue: setNewValue,
      clearNewMode: clearNewMode,
      saveNewChanges: saveNewChanges,
      onEditProfileToggleAccount: onEditProfileToggleAccount,
      closeDisableAccountModal: closeDisableAccountModal,
      onDisableAccountClick: onDisableAccountClick,
      openResetLinkAccountModal: openResetLinkAccountModal,
      closeResetLinkAccountModal: closeResetLinkAccountModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetail);
