import _ from 'lodash';
import React from 'react';
import Button from '../components/buttons/button';
import EditTable from '../components/tables/edit_table';
import TextBox from '../components/inputs/text_box';
import SecurityClearance from '../../components/security/security_clearance';
import { truncateTextByLimit } from '../../common/helpers';

function AddEmail(props) {
  const isEditable = (email) => {
    if (props.fields && props.fields.email) {
      return email.value !== props.fields.email;
    } else {
      return email.value !== props.account.account_email;
    }
  };

  const that = props;

  const notification_data = [
    {
      id: 'email_type',
      label: 'Type',
      col_width: '25%',
      mobile_order: 2,
      data_fields: ['type'],
      data_function: (props, data, fields, label = '') => {
        const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

        return props.editContent.id === data.id && data.is_active ? (
          <>
            {label}
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.editContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('type', 'Office')}>Office</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('type', 'Personal')}>Personal</a>
                </li>
              </ul>
            </li>
          </>
        ) : (
          value
        );
      },
      add_function: (props) => {
        return (
          <li className="dropdown heading-value-dropdown">
            <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
              {props.addContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
            </div>
            <ul className="dropdown-menu">
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('type', 'Office')}>Office</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('type', 'Personal')}>Personal</a>
              </li>
            </ul>
          </li>
        );
      },
    },
    {
      id: 'email_name',
      label: 'Email',
      col_width: '50%',
      ellipsis: true,
      mobile_order: 1,
      data_fields: ['value'],
      data_function: (props, data, fields, label = '') => {
        const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

        return props.editContent.id === data.id && data.is_active ? (
          <>
            {label}
            {isEditable(data) ? (
              <TextBox
                type="email"
                value={props.editContent.value}
                onChange={(e) => that.setEditValue('value', e)}
                maxLength={100}
                onKeyPress={(e) => that.isEnterKeyToSaveEditChanges(e)}
              />
            ) : (
              <div className='fs-exclude'>{truncateTextByLimit(value, 25)}</div>
            )}
          </>
        ) : (
          <div className='fs-exclude'>{truncateTextByLimit(value, 25)}</div>
        );
      },
      add_function: (props) => {
        return (
          <TextBox
            type="email"
            value={props.addContent.value}
            onChange={(e) => that.setNewValue('value', e)}
            onKeyPress={(e) => that.isEnterKeyToSaveNewChanges(e)}
            maxLength={100}
            autoFocus
          />
        );
      },
    },
    {
      id: 'email_notifications',
      label: 'Notifications',
      col_width: '25%',
      mobile_order: 3,
      data_fields: ['notification'],
      data_function: (props, data, fields, label) => {
        const field_values = fields.map((field) => {
          let value = data[field] ? 'Yes' : 'No';
          return label ? value.replace('No', 'Notification Disabled').replace('Yes', 'Notification Enabled') : value;
        });

        return props.editContent.id === data.id && data.is_active ? (
          <>
            {label}
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-enable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.editContent.notification ? 'Yes' : 'No'} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('notification', true)}>Yes</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setEditValue('notification', false)}>No</a>
                </li>
              </ul>
            </li>
          </>
        ) : field_values && field_values.length > 0 ? (
          field_values[0]
        ) : (
          '- - - - -'
        );
      },
      add_function: (props) => {
        return (
          <li className="dropdown heading-value-dropdown">
            <div className="heading-item heading-enable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
              {props.addContent.notification ? 'Yes' : 'No'} <i className="fa fa-caret-down" aria-hidden="true" />
            </div>
            <ul className="dropdown-menu">
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('notification', true)}>Yes</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => that.setNewValue('notification', false)}>No</a>
              </li>
            </ul>
          </li>
        );
      },
    },
    {
      id: 'email_buttons',
      action: true,
      mobile_order: 4,
      action_function: (props, data, fields, show) => {
        if (props.addEnabled && data.id === 'add_buttons') {
          return (
            <>
              <Button onClick={() => props.saveNewChanges()}>Save</Button>
              <Button theme="secondary" onClick={() => props.clearNewMode()}>
                Cancel
              </Button>
            </>
          );
        } else if ((props.editContent.id === data.id && data.is_active) || show) {
          return (
            <>
              <Button onClick={() => that.saveEditChanges()}>Save</Button>
              {isEditable(data) ? (
                <Button theme="revise" onClick={() => that.deleteEntry()}>
                  Delete
                </Button>
              ) : null}
              <Button theme="secondary" onClick={() => that.clearEditMode()}>
                Cancel
              </Button>
            </>
          );
        }
        return null;
      },
      add_function: (props) => {
        return (
          <>
            <Button onClick={() => that.saveNewChanges()}>Save</Button>
            <Button theme="secondary" onClick={() => that.clearNewMode()}>
              Cancel
            </Button>
          </>
        );
      },
    },
    {
      id: 'email_add_buttons',
      add: true,
      mobile_order: 5,
      add_function: (props, fields) => {
        return (
          <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
            <Button
              className="float--right"
              onClick={() => that.setAddMode('add_email')}
              disabled={!(props.addContent.mode === '' && props.editContent.mode === '') || props.disabled}
            >
              Add
            </Button>
          </SecurityClearance>
        );
      },
    },
  ];

  const notification_mobile_data = _.cloneDeep(_.orderBy(notification_data, ['mobile_order']));

  return (
    <>
      <div className="account__section">
        <h3>Email for Notifications</h3>
        {props.account &&
        props.account.notification_emails &&
        props.account.notification_emails.length === 0 &&
        (props.new_profile.mode === '' || props.new_profile.mode === 'add_number') ? (
          <div className="first-element-add">
            <Button type="button" onClick={() => props.setAddMode('add_email')} disabled={props.new_profile.mode === 'add_number' || props.disabled}>
              Add email
            </Button>
          </div>
        ) : (
          <EditTable
            table_data={notification_data}
            mobile_data={notification_mobile_data}
            field_data={props.account.notification_emails}
            addContent={props.new_profile}
            editContent={props.edit_profile}
            setEditMode={(data) => {
              return props.setEditMode('edit_email', data);
            }}
            setAddMode={(data) => {
              return props.setAddMode('add_email');
            }}
            editPermission="IPP_EDIT"
            addEnabled={props.new_profile.mode === 'add_email'}
            disabled={props.disabled}
          />
        )}
      </div>

      {props.edit_profile.error && props.edit_profile.mode === 'edit_email' ? (
        <div className="heading-item-warning">{props.edit_error_msg ? props.edit_error_msg : 'Please enter a valid email address'}</div>
      ) : null}
      {props.new_profile.error && props.new_profile.mode === 'add_email' ? (
        <div className="heading-item-warning">{props.new_error_msg ? props.new_error_msg : 'Please enter a valid email address'}</div>
      ) : null}
    </>
  );
}

export default AddEmail;
