/**
 * Grabs the case id from the URL
 * @function
 * @param {String} pathname - Optional Page URL Argument
 * @returns {String} Case ID
 */
const getCaseIdFromUrl = (pathname) => {
  const tabnames = ['case', 'item', 'progress', 'note', 'setup'];
  let case_id = '';

  for (let tab of tabnames) {
    // eslint-disable-next-line
    const re = new RegExp(`\/${tab}\/([A-Za-z0-9-]+)`);
    case_id = pathname.match(re);

    if (case_id) {
      break;
    }
  }

  return case_id && case_id[1] ? case_id[1] : '';
};

/**
 * Returns friendly alias of caseid
 * @param {string} case_id - case id string
 * @function
 * @return {string} returns formatted case id
 */
const convertAliasToCaseId = (case_id) => {
  const sf_case_id_regex = /[A-Z0-9]{5,6}-DE$|[A-Z0-9]{5,6}-R$/;
  return sf_case_id_regex.test(case_id) ? `${case_id}1` : case_id;
};

export { getCaseIdFromUrl, convertAliasToCaseId };
