import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { removeEmoji, removeOpposingTeeth, textFieldLimited } from '../../common/functions';
import { isBracketTeethValid as isBracketTeethValidHelper } from '../../common/tx_dx_form_utils';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';

import ErrorMessage from '../../doctor/components/container/error_message';
import TxPlan3 from '../../doctor/case_submission/tx_plan_3';

import { splitStrToArray } from './edit_tx_de_plan';
import { Teeth } from './edit_tx_plan';

/**
 * Displays the TX3 plan editing form
 * @component
 * @alias EditTx3Plan
 * @category BPP
 */
class EditTX3Plan extends Component {
  state = {
    ...this.props.treatment_plan,
    bracketTeeth: splitStrToArray(this.props.treatment_plan.bracketTeeth),
    extractTeeth: splitStrToArray(this.props.treatment_plan.extractTeeth),
    missingTeeth: splitStrToArray(this.props.treatment_plan.missingTeeth),
    restrictTeeth: splitStrToArray(this.props.treatment_plan.restrictTeeth),
    opposingUpperArch: this.props.treatment_plan.archToTreat === 'upper' ? this.props.treatment_plan.opposingArch : 'current',
    opposingLowerArch: this.props.treatment_plan.archToTreat === 'lower' ? this.props.treatment_plan.opposingArch : 'current',
    anteriorPosteriorCorrections: this.props.treatment_plan.anteriorPosteriorCorrections
      ? JSON.parse(this.props.treatment_plan.anteriorPosteriorCorrections)
      : {},
  };

  componentDidUpdate() {
    if (this.props.save_tx_plan_changes && this.props.treatment_plan) {
      if (this.hasTxPlanField() && !this.isTxPlanUnChanged()) {
        this.postTXPlan();
      } else {
        this.props.changeSaveValue();
      }
    }
  }

  /**
   * Performs a POST request to save the form and POST request to send email
   * @function
   */
  postTXPlan() {
    const newTxPlan = {
      description: 'Treatment Plan Edited - ' + this.props.description.trim(),

      archToTreat: this.state.archToTreat,
      chief_complaint: this.state.chief_complaint,
      opposingArch: this.state.opposingArch,
      notes: this.state.notes.trim(),
      notes_spacing: this.state.notes_spacing,

      missingTeeth: this.state.missingTeeth.toString(),
      extractTeeth: this.state.extractTeeth.toString(),
      bracketTeeth: removeOpposingTeeth(this.state.archToTreat, this.state.bracketTeeth).toString(),
      restrictTeeth: this.state.restrictTeeth.toString(),
      impliedTeethSets: JSON.stringify(this.getImpliedTeethSets()),
      anteriorPosteriorR: this.state.anteriorPosteriorR,
      anteriorPosteriorL: this.state.anteriorPosteriorL,
      anteriorPosteriorCorrections: JSON.stringify(this.state.anteriorPosteriorCorrections),
      anteriorPosteriorNotes: this.state.anteriorPosteriorNotes.trim(),
      midlines: this.state.midlines,
      auxiliariesWillBeUsed: this.state.auxiliariesWillBeUsed,
      auxiliariesNotes: this.state.auxiliariesNotes.trim(),
    };

    this.props.setStatus({ loading: true, submit: true });
    Axios.post(`/apiV2/treatmentplan/${this.props.case_id}`, newTxPlan)
      .then((resp) => {
        Axios.post(`/api/email/?slug=treatment-form-change&caseId=${this.props.case_id}&method=standard&provider=${window.location.origin}`);
        this.props.reloadInformation();
        this.props.setStatus({ loading: false });
      })
      .catch((err) => {
        this.props.setStatus({ loading: false, error: true });
      });
  }

  /**
   * Checks whether the treatment plan has been changed
   * @function
   * @returns {boolean} - Whether treatment plan has been changed
   */
  isTxPlanUnChanged() {
    const bracketTeeth = this.props.treatment_plan.bracketTeeth.split(',').filter(String);
    const extractTeeth = this.props.treatment_plan.extractTeeth.split(',').filter(String);
    const missingTeeth = this.props.treatment_plan.missingTeeth.split(',').filter(String);
    const restrictTeeth = this.props.treatment_plan.restrictTeeth.split(',').filter(String);

    const isUnChanged =
      this.props.treatment_plan.archToTreat === this.state.archToTreat &&
      this.props.treatment_plan.opposingArch === this.state.opposingArch &&
      this.props.treatment_plan.notes === this.state.notes &&
      this.props.treatment_plan.notes_spacing === this.state.notes_spacing &&
      _.intersection(bracketTeeth, this.state.bracketTeeth).length === bracketTeeth.length &&
      _.intersection(extractTeeth, this.state.extractTeeth).length === extractTeeth.length &&
      _.intersection(missingTeeth, this.state.missingTeeth).length === missingTeeth.length &&
      _.intersection(restrictTeeth, this.state.restrictTeeth).length === restrictTeeth.length &&
      bracketTeeth.sort().join('') === this.state.bracketTeeth.sort().join('') &&
      extractTeeth.sort().join('') === this.state.extractTeeth.sort().join('') &&
      missingTeeth.sort().join('') === this.state.missingTeeth.sort().join('') &&
      restrictTeeth.sort().join('') === this.state.restrictTeeth.sort().join('') &&
      this.props.treatment_plan.anteriorPosteriorL === this.state.anteriorPosteriorL &&
      this.props.treatment_plan.anteriorPosteriorR === this.state.anteriorPosteriorR &&
      this.props.treatment_plan.anteriorPosteriorCorrections.replaceAll(' ', '') ===
        JSON.stringify(this.state.anteriorPosteriorCorrections).replaceAll(' ', '') &&
      this.props.treatment_plan.anteriorPosteriorNotes === this.state.anteriorPosteriorNotes &&
      this.props.treatment_plan.auxiliariesWillBeUsed === this.state.auxiliariesWillBeUsed &&
      this.props.treatment_plan.auxiliariesNotes === this.state.auxiliariesNotes &&
      this.props.treatment_plan.midlines === this.state.midlines;

    if (isUnChanged) {
      const warnings = '<li>No changes have been made. Please update the treatment plan form to save.</li>';
      this.props.showWarning(warnings);
    }

    return isUnChanged;
  }

  /**
   * Sets the state as the new state
   * @function
   * @param {object} ns - The new state
   */
  setPlanState = (ns) => {
    const missingTeeth = ns['missingTeeth'] !== undefined ? ns['missingTeeth'] : this.state.missingTeeth;
    const extractTeeth = ns['extractTeeth'] !== undefined ? ns['extractTeeth'] : this.state.extractTeeth;
    const archToTreat = ns['archToTreat'] !== undefined ? ns['archToTreat'] : this.state.archToTreat;
    const opposingUpperArch = ns['opposingUpperArch'] !== undefined ? ns['opposingUpperArch'] : this.state.opposingUpperArch;
    const opposingLowerArch = ns['opposingLowerArch'] !== undefined ? ns['opposingLowerArch'] : this.state.opposingLowerArch;
    const bracketTeeth = ns['bracketTeeth'] !== undefined ? ns['bracketTeeth'] : this.state.bracketTeeth;
    const restrictTeeth = ns['restrictTeeth'] !== undefined ? ns['restrictTeeth'] : this.state.restrictTeeth;
    const anteriorPosteriorR = ns['anteriorPosteriorR'] !== undefined ? ns['anteriorPosteriorR'] : this.state.anteriorPosteriorR;
    const anteriorPosteriorL = ns['anteriorPosteriorL'] !== undefined ? ns['anteriorPosteriorL'] : this.state.anteriorPosteriorL;
    const anteriorPosteriorNotes = ns['anteriorPosteriorNotes'] !== undefined ? ns['anteriorPosteriorNotes'] : this.state.anteriorPosteriorNotes;
    const midlines = ns['midlines'] !== undefined ? ns['midlines'] : this.state.midlines;
    const auxiliariesWillBeUsed = ns['auxiliariesWillBeUsed'] !== undefined ? ns['auxiliariesWillBeUsed'] : this.state.auxiliariesWillBeUsed;
    const auxiliariesNotes = ns['auxiliariesNotes'] !== undefined ? removeEmoji(textFieldLimited(ns['auxiliariesNotes'])) : this.state.auxiliariesNotes;
    const notes = ns['notes'] !== undefined ? removeEmoji(textFieldLimited(ns['notes'])) : this.state.notes;

    let anteriorPosteriorCorrections = _.cloneDeep(this.state.anteriorPosteriorCorrections);

    if (ns['anteriorPosteriorCorrections'] !== undefined) {
      anteriorPosteriorCorrections = {
        ...anteriorPosteriorCorrections,
        ...ns['anteriorPosteriorCorrections'],
      };
    }

    let opposingArch;

    if (archToTreat === 'upper') {
      opposingArch = opposingUpperArch;
    } else if (archToTreat === 'lower') {
      opposingArch = opposingLowerArch;
    } else {
      opposingArch = this.state.opposingArch;
    }

    this.setState({
      archToTreat: archToTreat,
      bracketTeeth: bracketTeeth,
      missingTeeth: missingTeeth,
      extractTeeth: extractTeeth,
      opposingArch: opposingArch,
      opposingUpperArch: opposingUpperArch,
      opposingLowerArch: opposingLowerArch,
      restrictTeeth: restrictTeeth,
      anteriorPosteriorR: anteriorPosteriorR,
      anteriorPosteriorL: anteriorPosteriorL,
      anteriorPosteriorCorrections: anteriorPosteriorCorrections,
      anteriorPosteriorNotes: anteriorPosteriorNotes,
      midlines: midlines,
      auxiliariesWillBeUsed: auxiliariesWillBeUsed,
      auxiliariesNotes: auxiliariesNotes,
      notes: notes,
    });

    this.props.clearWarning();

    this.metAllPlanRequiredField({
      archToTreat: archToTreat,
      opposingArch: opposingArch,
      anteriorPosteriorR: anteriorPosteriorR,
      anteriorPosteriorL: anteriorPosteriorL,
      anteriorPosteriorCorrections: anteriorPosteriorCorrections,
      anteriorPosteriorNotes: anteriorPosteriorNotes,
      midlines: midlines,
      auxiliariesWillBeUsed: auxiliariesWillBeUsed,
      auxiliariesNotes: auxiliariesNotes,
      bracketTeeth: bracketTeeth,
      missingTeeth: missingTeeth,
      extractTeeth: extractTeeth,
    });
  };

  /**
   * Determines if all required fields are met
   * @function
   * @returns {boolean} - True or false
   */
  isTxPlanValid = () => {
    return this.props.description.trim() && this.metAllPlanRequiredField();
  };

  /**
   * Returns whether all required fields are filled out and displays warning messages
   * @function
   * @returns {boolean} - True or false
   */
  hasTxPlanField() {
    if (!this.isTxPlanValid()) {
      let warnings = '';

      if (!this.props.description || !this.props.description.trim()) {
        if (document.querySelector('#tx_edit_desrcription_title')) {
          document.querySelector('#tx_edit_desrcription_title').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_heading')) {
          document.querySelector('#tx_edit_desrcription_heading').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_textarea')) {
          document.querySelector('#tx_edit_desrcription_textarea').classList.add('warning-border');
        }
        warnings = warnings + '<li><a href="#tx_edit_desrcription_heading">Description of Changes</a></li>';
      }

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (!this.isBracketTeethValid()) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
        document.querySelector('#anterior_posterior').classList.add('warning-text');
        if (!this.isAnteriorPosteriorFieldValid()) {
          handleAnteriorPosteriorCheckboxErrors(true);
        }
        if (!this.isAnteriorPosteriorNotesFieldValid()) {
          document.querySelector('#anteriorPosteriorNotes').classList.add('warning-border');
        }
        warnings = warnings + '<li><a href="#anterior_posterior">Anterior-Posterior</a></li>';
      }

      if (!this.state.midlines) {
        document.querySelector('#midlines').classList.add('warning-text');
        warnings = warnings + '<li><a href="#midlines">Midlines</a></li>';
      }

      if (!this.isAuxiliariesFieldValid()) {
        document.querySelector('#tx_auxiliaries').classList.add('warning-text');
        document.querySelector('#auxiliariesNotes').classList.add('warning-border');
        warnings = warnings + '<li><a href="#tx_auxiliaries">Auxiliaries</a></li>';
      }

      this.props.showWarning(warnings);
      return false;
    }

    return true;
  }

  /**
   * Determines if anterior-posterior field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorFieldValid = () => {
    const correctionIsSelected = [this.state.anteriorPosteriorL, this.state.anteriorPosteriorR].includes('correction');
    if (correctionIsSelected) {
      return Object.values(this.state.anteriorPosteriorCorrections).some((value) => value);
    }
    return true;
  };

  /**
   * Determines if anterior-posterior notes field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorNotesFieldValid = () => {
    const otherIsSelected = [this.state.anteriorPosteriorL, this.state.anteriorPosteriorR].includes('other');
    if (otherIsSelected) {
      return this.state.anteriorPosteriorNotes.trim() !== '';
    }
    return true;
  };

  /**
   * Checks if bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns boolean
   */
  isBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat } = {}) => {
    const currentBracketTeeth = bracketTeeth || this.state.bracketTeeth;
    const currentMissingTeeth = missingTeeth || this.state.missingTeeth;
    const currentExtractTeeth = extractTeeth || this.state.extractTeeth;
    const currentArchToTreat = archToTreat || this.state.archToTreat;

    return isBracketTeethValidHelper(
      {
        bracketTeeth: currentBracketTeeth,
        missingTeeth: currentMissingTeeth,
        extractTeeth: currentExtractTeeth,
        archToTreat: currentArchToTreat,
      },
      Teeth
    );
  };

  /**
   * Determines if auxiliaries field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAuxiliariesFieldValid = () => {
    if (this.state.auxiliariesWillBeUsed === 'Yes') {
      return this.state.auxiliariesNotes.trim() !== '';
    }
    return true;
  };

  /**
   * Returns whether all required fields are met
   * @function
   * @param {Object} [values={}] - The values for treatment plan
   * @returns {Boolean} Whether met all required fields
   */
  metAllPlanRequiredField = (values = {}) => {
    const archToTreat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
    const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
    const missingTeeth = values['missingTeeth'] !== undefined ? values['missingTeeth'] : this.state.missingTeeth;
    const extractTeeth = values['extractTeeth'] !== undefined ? values['extractTeeth'] : this.state.extractTeeth;
    const midlines = values['midlines'] !== undefined ? values['midlines'] : this.state.midlines;
    const hasMet =
      archToTreat &&
      midlines &&
      this.isBracketTeethValid({ bracketTeeth, missingTeeth, extractTeeth, archToTreat }) &&
      this.isAnteriorPosteriorFieldValid() &&
      this.isAnteriorPosteriorNotesFieldValid() &&
      this.isAuxiliariesFieldValid();
    return hasMet;
  };

  /**
   * Handles clicking on teethsets
   * @function
   * @param {String} teethSet - The teethset to set
   */
  handleTeethClick = (teethSet) => {
    return (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);

      this.setPlanState(ns);

      if (teethSet === 'bracketTeeth') {
        this.metAllPlanRequiredField({ bracketTeeth: ns[teethSet] });
      }

      this.props.clearWarning();
    };
  };

  /**
   * Returns the implied teeth set base on the current state
   * @function
   * @returns {object} - Implied teeth set
   */
  getImpliedTeethSets = () => {
    let opposingTeeth = [];
    let hasUpper = this.state.archToTreat !== 'lower';
    let hasLower = this.state.archToTreat !== 'upper';
    let setupIdeal = this.state.archToTreat !== 'both' && this.state.opposingArch === 'ideal';
    let setupCurrent = this.state.archToTreat !== 'both' && this.state.opposingArch === 'current';
    let setupDigital = this.state.archToTreat !== 'both' && this.state.opposingArch === 'digital';

    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    let missingTeeth = this.state.missingTeeth;
    let extractTeeth = _.difference(this.state.extractTeeth, missingTeeth);
    let extractDisabled = missingTeeth;
    let extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal || setupDigital) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    let bracketTeeth = _.difference(this.state.bracketTeeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.restrictTeeth, restrictImplied);
    if (setupCurrent || setupDigital) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    let tset = {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };

    return tset;
  };

  render() {
    return (
      <>
        <TxPlan3
          hideAlert
          setPlanState={this.setPlanState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          onFocus={this.props.clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          photoUpload={this.props.photoUpload}
          {...this.state}
        />
        <ErrorMessage
          title="Incomplete Fields"
          onClose={this.props.clearWarning}
          className={this.props.warning ? 'error-message-container' : 'error-message-container hide'}
        >
          <div id="warning-submit">Warning</div>
        </ErrorMessage>
      </>
    );
  }
}

export default EditTX3Plan;
