import {
  FETCH_SEGMENTATION_STATE_SUCCESS,
  FETCH_SEGMENTATION_STATE_PENDING,
  FETCH_SEGMENTATION_STATE_ERROR,
  POST_SEGMENTATION_CASE_FILE_PENDING,
  POST_SEGMENTATION_CASE_FILE_SUCCESS,
  POST_SEGMENTATION_CASE_FILE_ERROR,
  POST_UPLOAD_PENDING,
  POST_UPLOAD_SUCCESS,
  POST_UPLOAD_ERROR,
  CHANGE_RELOAD_THUMBNAIL,
  SET_IMPORT_TYPE,
} from '../../../actions/bpp/case_details/segmentation';

const initialState = {
  segmentation_state: {},
  segmentation_pending: false,
  segmentation_error: false,
  reload_thumbnail: false,
  session_error: false,
};

/**
 * This reducer is used for the segmentation process on the BPP
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function segmentationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEGMENTATION_STATE_SUCCESS:
      return {
        ...state,
        segmentation_state: action.segmentation_state,
        reload_thumbnail: action.reload_thumbnail,
        segmentation_pending: false,
        segmentation_error: false,
      };

    case FETCH_SEGMENTATION_STATE_PENDING:
      return {
        ...state,
        segmentation_pending: true,
      };

    case FETCH_SEGMENTATION_STATE_ERROR:
      return {
        ...state,
        segmentation_pending: false,
        segmentation_error: true,
      };

    case POST_SEGMENTATION_CASE_FILE_SUCCESS:
      return {
        ...state,
        segmentation_pending: false,
        segmentation_error: false,
      };

    case POST_SEGMENTATION_CASE_FILE_PENDING:
      return {
        ...state,
        segmentation_pending: true,
      };

    case POST_SEGMENTATION_CASE_FILE_ERROR:
      return {
        ...state,
        segmentation_pending: false,
        segmentation_error: true,
        session_error: action.session_error,
      };

    case POST_UPLOAD_SUCCESS:
      return {
        ...state,
        segmentation_pending: false,
        segmentation_error: false,
      };

    case POST_UPLOAD_PENDING:
      return {
        ...state,
        segmentation_pending: true,
      };

    case POST_UPLOAD_ERROR:
      return {
        ...state,
        segmentation_pending: false,
        segmentation_error: true,
        session_error: action.session_error,
      };

    case CHANGE_RELOAD_THUMBNAIL:
      return {
        ...state,
        reload_thumbnail: action.value,
      };

    case SET_IMPORT_TYPE:
      return {
        ...state,
        segmentation_state: { ...state.segmentation_state, import_type: action.import_type },
      };

    default:
      return state;
  }
}

export const getSegmentationState = (state) => state.segmentationReducer.segmentation_state;
export const getSegmentationStatePending = (state) => state.segmentationReducer.segmentation_pending;
export const getSegmentationStateError = (state) => state.segmentationReducer.segmentation_error;
export const getReloadThumbnail = (state) => state.segmentationReducer.reload_thumbnail;
export const getSegmentationSessionError = (state) => state.segmentationReducer.session_error;
