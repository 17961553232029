import React from 'react';
import PropTypes from 'prop-types';
import ProcessTracker from '../../../components/workflow/process_tracker';

function WireManufacturingProcess(props) {
  const { wm_status } = props;

  return <ProcessTracker {...props} process="wire_manufacturing" hideRuler stage={wm_status} />;
}

WireManufacturingProcess.propTypes = {
  wm_status: PropTypes.string.isRequired,
};

export default WireManufacturingProcess;
