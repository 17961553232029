// Css
import '../setup_viewer/setup_viewer.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
// Internal Functions
import NotFound from '../../doctor/404/not_found';
import SmilePanelMenu from './smile_menu';
import { handleHttpRequestError } from '../../common/error';
import SmilePanelViewer from './smile_viewer';

import Shortcuts from '../setup_viewer/components/extras/shortcuts';
import { UserPermission } from '../../context/user_permission';
import { SETUP_PREFIX } from '../setup_viewer/components/common/functions';
import { getBusinessRoleList } from '../../common/helpers';
import CircleLoader from '../loader/circle_loader';
import { getViewerOptions } from '../../redux/reducers/setup_viewer/setup_viewer';
import { onChangeStep } from '../../redux/actions/setup_viewer/setup_viewer';

/**
 * Displays the entire Setup Viewer
 * @component
 * @alias SetupSmileViewer
 */
class SetupSmileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctor_id: '',
      collapse: false,
      collapse_pane_1: true,
      collapse_pane_2: true,
      split: false,
      smileDetails: [],
      smileData_loading: true,
      smileData_error: false,
      user_roles: '',
      user_roles_loading: true,

      scroll_area: 0,
      scroll_items: 5,
      scroll_options: [],
      models: [],
    };
    this.fetchSmileData = this.fetchSmileData.bind(this);
    this.onStepClicked = this.onStepClicked.bind(this);
  }

  componentDidMount() {
    const that = this;
    const { smile_id } = that.getURLParams();
    if (smile_id && that.isValidURLParams()) {
      that.fetchSmileData(smile_id);
      that.setScrollOption('Smile Simulation');
    } else {
      that.setState({
        smileData_error: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const finishedFetchingCaseDetails = this.state.smileDetails && !this.state.smileData_loading && !this.state.smileData_error;
    const caseDetailsChanged = this.state.smileDetails !== prevProps.smileDetails;
    const recordsNotBuilt = !this.state.scroll_options.length;
    const modelsNotBuilt = !this.state.models.length;
    const locationChanged = this.props.location !== prevProps.location;

    if (finishedFetchingCaseDetails && (recordsNotBuilt || caseDetailsChanged)) {
      if (modelsNotBuilt) this.buildModels();
    }
    if (locationChanged) {
      this.updateScrollOptionsOnRouteChange();
    }
  }

  fetchSmileData(smile_id) {
    const that = this;
    const released_smile_status = ['Setup Ready for Release', 'Released', 'Prospect Lost', 'Case Submitted', 'Resume Case Submission'];

    Axios.get(`/apiv3/smilesimulation/${smile_id}`)
      .then((res) => {
        const smiles = res.data.smiles[0];
        if (released_smile_status.includes(smiles.smile_status)) {
          that.setState({
            smileDetails: smiles,
            smileData_loading: false,
          });
        } else {
          that.setState({
            smileData_error: true,
            smileData_loading: false,
          });
        }
      })
      .catch((err) => {
        that.setState({
          smileData_error: true,
          smileData_loading: false,
        });
        handleHttpRequestError(err, that);
      });

    Axios.get(`/apiV2/permission`)
      .then((res) => {
        if (res && res.data && res.data.permission_list_raw) {
          that.setState({
            doctor_id: res.data.doctor_id,
            user_roles: res.data.doctor_role,
            user_roles_loading: false,
          });
        }
      })
      .catch((err) => {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * Retrieves parameters from the URL
   * @function
   *
   */
  getURLParams() {
    const { smile_id, pane_1 } = this.props.match && this.props.match.params;
    const default_pane = 'simulation';
    return {
      smile_id,
      pane_1: pane_1 ? pane_1 : default_pane,
    };
  }

  // Builds state
  //----------------------------------------------------------------------------

  /**
   * Builds the initial empty object to store models
   * @function
   */
  buildModels() {
    const setup_process = [{ index: 1 }];

    if (setup_process) {
      const new_models = setup_process.map((setup) => ({
        setup_num: setup.index,
        steps: null,
        ipr: null,
        loading: false,
        loading_percent: 0,
        error: null,
        loaded: false,
      }));
      this.setState({ models: new_models });
    }
  }

  /**
   * Updates the scroll option when the route change
   *
   * The route change can occur when pressing forward and backward
   *  broswer history
   *
   * @function
   */
  updateScrollOptionsOnRouteChange() {
    const { pane_1 } = this.getURLParams();
    let hasChanged = false;
    const new_scroll_options = this.state.scroll_options.map((option) => {
      const is_active_pane_1 = option.text.replace(SETUP_PREFIX, '') === pane_1;

      if (is_active_pane_1 !== option.active_pane_1) hasChanged = true;
      return {
        ...option,
        active_pane_1: is_active_pane_1,
      };
    });
    if (hasChanged) this.setState({ scroll_options: new_scroll_options });
  }

  /**
   * Updates the model in the state to the new model
   *
   * @param {Number} setup_num - The corresponding setup's number to update
   * @param {Object} new_model - The new model to store
   * @function
   */
  updateModels = (setup_num, new_model) => {
    const new_models = this.state.models.map((model) => {
      if (model.setup_num === setup_num) {
        return {
          ...model,
          ...new_model,
        };
      } else {
        return { ...model };
      }
    });
    this.setState({ models: new_models });
  };

  /**
   * Checks whether the current URL params is valid
   * @function
   * @return {Boolean} Whether the current URL is valid or not
   */
  isValidURLParams() {
    const { pane_1 } = this.getURLParams();
    return this.isValidPane(pane_1);
  }

  /**
   * Whether the pane is valid or not
   * @function
   * @param {String} pane - The pane to validate
   */
  isValidPane(pane) {
    return !pane || pane === 'simulation' || pane === 'malocculusion';
  }

  /**
   * Toggles menu collapse
   * @function
   */
  toggleCollapse = () => this.toggleState('collapse');

  /**
   * Toggles pane 1's menu
   * @function
   */
  toggleCollapsePane1 = () => this.toggleState('collapse_pane_1');

  /**
   * Toggles pane 2's menu
   * @function
   */
  toggleCollapsePane2 = () => this.toggleState('collapse_pane_2');

  /**
   * Toggles split screen
   * @function
   */
  toggleSplit = () => {
    const current_split_on = this.state.split;

    // Set next view
    if (current_split_on) {
      this.setScrollOption('Smile Simulation');
      this.onStepClicked('last', 1);
    } else {
      this.onStepClicked('first', 1);
    }

    this.setState({
      collapse: !this.state.split ? true : this.state.collapse,
      collapse_pane_1: this.state.split ? true : this.state.collapse_pane_1,
      collapse_pane_2: this.state.split ? true : this.state.collapse_pane_2,
      split: !this.state.split,
    });
  };

  /**
   * Toggles the state
   * @function
   * @param {String} state - State to toggle
   */
  toggleState = (state) => {
    this.setState({
      [state]: !this.state[state],
    });

    this.tooltipCleanUp();
  };

  /**
   * Handles clicking on selecting scroll area
   * @function
   * @param {Object} e - The click event
   */
  setScrollArea = (e) => {
    const val = e.target.dataset.value;

    this.setState({
      scroll_area: parseInt(val),
    });
  };

  /**
   * Builds the list of setup history
   * @function
   * @return {Number} Number of setups this case has
   */
  setScrollOption(text) {
    const simulation = true;
    this.setState({
      scroll_options: [
        {
          text: text,
          active_pane_1: simulation,
        },
      ],
    });
    return this.state.scroll_options.length;
  }

  /**
   * Handles clicking on scroll items
   * @function
   * @param {Object} e - The onclick event
   */
  onScrollOptionClick = (e) => {};

  onStepClicked = (step, pane) => {
    const newStep = this.props.onChangeStep(step, pane);
    newStep['newStep'] === 'first' ? this.setScrollOption('Malocclusion') : this.setScrollOption('Smile Simulation');
  };

  /**
   * Updates the URL using the text and pane
   * @function
   * @param {String} text - The param to update URL with
   * @param {String} pane - The target pane for text
   */
  updateURLParams = () => {
    const { smile_id, pane_1 } = this.getURLParams();
    this.props.history.push(`/smile_setup/${smile_id}/${pane_1}`);
  };

  /**
   * Cleans up tooltip by hiding
   * @function
   */
  tooltipCleanUp = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Retrieves the pane from dataset
   * @function
   * @param {Object} e - The click event
   */
  getPaneFromEvent = (e) => {
    return e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.pane ? e.currentTarget.dataset.pane : e;
  };

  //----------------------------------------------------------------------------

  /**
   * Handles clicking on the logo
   * @function
   */
  onLogoClick = () => {
    const { smile_id } = { ...this.state.smileDetails };
    const { doctor_id } = { ...this.state };
    const hasAccessToBusinessPortal = _.intersection(getBusinessRoleList(), [this.state.user_roles]).length > 0;

    if (hasAccessToBusinessPortal && smile_id) {
      this.props.history.push(`/business/portal/smile/${smile_id}`);
    } else {
      this.props.history.push(`/portal/${doctor_id}/smile/detail/${smile_id}`);
    }
  };

  render() {
    const displayLoading = (this.state.smileDetails.length === 0 && this.state.smileData_loading) || this.state.user_roles_loading;
    const hasAccessToBusinessPortal = _.intersection(getBusinessRoleList(), [this.state.user_roles]).length > 0;
    const hasError =
      this.state.smileData_error ||
      !this.state.smileDetails ||
      (this.state.smileDetails.smile_status === 'Setup Ready for Release' && !hasAccessToBusinessPortal);

    if (displayLoading) {
      return (
        <div className="background--dark">
          <Helmet>
            <title>Loading... | InBrace Smile Design™ Viewer</title>
          </Helmet>
          <div className="fill center">
            <CircleLoader />
          </div>
        </div>
      );
    } else if (hasError) {
      return <NotFound />;
    } else {
      return (
        <div className="background--dark">
          <UserPermission>
            <Shortcuts />
            <Helmet>
              <title>InBrace Smile Simulation™ Viewer</title>
            </Helmet>
            <SmilePanelMenu
              smile_details={this.state.smileDetails}
              collapse={this.state.collapse}
              onLogoClick={this.onLogoClick}
              toggleCollapse={this.toggleCollapse}
              onScrollOptionClick={this.onScrollOptionClick}
            />
            <SmilePanelViewer
              collapse={this.state.collapse}
              collapse_pane_1={this.state.collapse_pane_1}
              collapse_pane_2={this.state.collapse_pane_2}
              split={this.state.split}
              toggleSplit={this.toggleSplit}
              scroll_options={this.state.scroll_options}
              scroll_area={this.state.scroll_area}
              scroll_items={this.state.scroll_items}
              setScrollArea={this.setScrollArea}
              toggleCollapsePane1={this.toggleCollapsePane1}
              toggleCollapsePane2={this.toggleCollapsePane2}
              onScrollOptionClick={this.onScrollOptionClick}
              models={this.state.models}
              updateModels={this.updateModels}
              isSmileSimulationSetup={true}
              smile_details={this.state.smileDetails}
              onChangeStep={this.onStepClicked}
            />
          </UserPermission>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    viewOptions: getViewerOptions(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangeStep: onChangeStep,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SetupSmileViewer);
