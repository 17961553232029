import React from 'react';
import { Verbiage } from '../verbiage.constants';

function WasmSidebarRevisionNotes(props) {
  const { note, onNoteChange, onClose } = props;
  return (
    <>
      <div className="wasm-sidebar-revision-title">
        <div>{Verbiage.SidebarRevision.Title}</div>
        <i className="fa fa-times" aria-label="Close" onClick={onClose} />
      </div>
      <textarea
        className="scrollbar--dark"
        placeholder="Please specify revision request"
        maxLength="2500"
        onChange={onNoteChange}
        value={note}
      />
    </>
  );
}

export default WasmSidebarRevisionNotes;
