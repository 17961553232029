import React, { Component } from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import Agreement from '../customer_agreement/customer_agreement';
import Error from '../404/not_found';
import Privacy from '../../components/privacy/external_privacy';

import { hasMatchingPassword, getErrorMessageForPasswordMisMatch, hasMetAllPasswordCondition } from '../../common/validation/password';
import { handleHttpRequestError } from '../../common/error';

import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import TextBox from '../components/inputs/text_box';
import { LogoWhite } from '../assets/logos';
class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      showMessage: false,
      loading: true,
      message: '',
      error: true,
      warning: false,
      agreement: false,
      checkbox: false,
    };

    this.handleChangePassword1 = this.handleChangePassword1.bind(this);
    this.handleChangePassword2 = this.handleChangePassword2.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onNewAgreeClick = this.onNewAgreeClick.bind(this);
  }

  componentDidMount() {
    if (this.props && this.props.match && this.props.match.params && this.props.match.params.token) {
      const that = this;
      const token = this.props.match.params.token;

      localStorage.removeItem('token');

      let data = new FormData();
      data.append('token', token);

      Axios.post('/apiV2/passwordresetvalidation', data)
        .then(function (res) {
          that.setState({
            token: token,
            error: false,
          });
        })
        .then(function () {
          Axios.post('/apiV2/agreement/status', data).then(function (res) {
            that.setState({
              loading: false,
              agreement: res.data === 'Agreed',
            });
          });
        })
        .catch(function (err) {
          that.setState({
            loading: false,
          });

          handleHttpRequestError(err, that);
        });
    }
  }

  handleChangePassword1(event) {
    this.setState({ password1: event.target.value, message: '', warning: false });
  }

  handleChangePassword2(event) {
    this.setState({ password2: event.target.value, message: '', warning: false });
  }

  clearMessage(event) {
    this.setState({ message: '', warning: false });
  }
  /**
   * Handle submission of the form for resetting the password
   * @function
   */
  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    let that = this;

    if (!hasMatchingPassword(this.state.password1, this.state.password2)) {
      this.setState({
        message: getErrorMessageForPasswordMisMatch(),
        warning: true,
      });

      return;
    }

    if (!hasMetAllPasswordCondition(this.state.password1)) {
      this.setState({
        message: 'Password does not meet specified requirements',
        warning: true,
      });

      return;
    }

    let body = {
      'password--new': this.state.password1,
      password_reset_token: this.state.token,
    };

    Axios.defaults.headers.common = {
      Authorization: '',
      'X-CSRFToken': '',
    };

    Axios.put('/apiv3/user/password/reset', body).then(function (res) {
      if (res.status === 200) {
        that.setState({
          message: '',
          showMessage: true,
          warning: false,
          password1: '',
          password2: '',
        });

        setTimeout(function () {
          that.props.history.push('/');
        }, 5000);
      } else {
        that.setState({
          message: 'Your password was not changed due to an invalid or expired reset link.',
          warning: true,
        });
      }
    });
  }

  onKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }

  onCheckboxChange(event) {
    this.setState({
      checkbox: !this.state.checkbox,
      message: '',
      warning: false,
    });
  }

  onNewAgreeClick() {
    const that = this;
    let data = new FormData();
    data.append('token', this.state.token);

    Axios.post('/apiV2/agreement/agree', data).then(function (res) {
      that.setState({
        agreement: true,
      });
    });
  }

  onLinkClick() {
    const token = this.state.token;

    let data = new FormData();
    data.append('token', token);

    Axios.post('/apiV2/agreement/click', data);
  }

  render() {
    if (this.state.loading) {
      return <div />;
    } else if (this.state.error) {
      return <Error />;
    } else if (!this.state.agreement) {
      return <Agreement messageType="new" onNewAgreeClick={this.onNewAgreeClick} onLinkClick={this.onLinkClick} token={this.state.token} />;
    } else {
      return (
        <div className="ipp-light background">
          <Helmet>
            <title>Password Reset | InBrace</title>
            <meta name="description" content="Reset your InBrace Smile Design Studio™ TM account password by entering and confirming a new password." />
          </Helmet>
          <div className="login-window text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="">
                <LogoWhite width="280" />
                {this.state.showMessage ? (
                  <div>
                    <p className="heading--light">Your password has been reset. You will be redirected to the login screen in a few moments.</p>
                    <i className="fa fa-spinner fa-spin fa-spin-mod" aria-hidden="true" />
                  </div>
                ) : (
                  <div>
                    <p className="heading--light left-align-bullet">
                      Password must include:
                      <ul>
                        <li>At least 12 characters</li>
                        <li>At least one uppercase letter</li>
                        <li>At least one lowercase letter</li>
                        <li>At least one digit (0-9)</li>
                        <li>At least one special character</li>
                      </ul>
                    </p>
                    <label className="content--left heading--light" htmlFor="email">
                      New Password
                    </label>
                    <TextBox
                      id="password-reset-1"
                      type="password"
                      name="password1"
                      placeholder="New Password"
                      value={this.state.password1}
                      onChange={this.handleChangePassword1}
                      onFocus={this.clearMessage}
                      onKeyPress={this.onKeyPress}
                      autoFocus={true}
                    />
                    <label className="content--left heading--light" htmlFor="email">
                      Confirm Password
                    </label>
                    <TextBox
                      id="password-reset-2"
                      type="password"
                      name="password2"
                      placeholder="Confirm Password"
                      value={this.state.password2}
                      onChange={this.handleChangePassword2}
                      onFocus={this.clearMessage}
                      onKeyPress={this.onKeyPress}
                    />
                    <Button type="submit" theme="dark" onClick={this.handleSubmit}>
                      Submit
                    </Button>
                  </div>
                )}
              </div>
            </form>
            {this.state.warning ? (
              <p className="heading--warning">
                <CardContainer type="error-message">{this.state.message}</CardContainer>
              </p>
            ) : null}
          </div>
          <Privacy className="footer-display footer-display-100 heading--light" />
        </div>
      );
    }
  }
}

export default withRouter(PasswordReset);
