/**
 * File:reason.js - Component to input item request submission reasons on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import TeethSelector from '../../common/teeth_selector';
class ItemReason extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleTeethClick = (rb, id_string) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(rb.selectedTeeth);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      rb.selectedTeeth = Array.from(sel);
      this.props.onhandleTeethClick(rb, id_string);
    };
    return func;
  };

  displayTextArea = csq => {
    return (
      <div key={csq.id}>
        <div className="row">
          <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span>
        </div>
        <div className="extra-padding-headline" />
        <div data-id={csq.id} className="reason-input-data-bpp">
          <textarea
            className={this.props.error && csq.error ? 'form-control item-reason-comment warning-border row' : 'form-control item-reason-comment row'}
            placeholder={csq.input_placeholder}
            id={csq.input_type + '-' + csq.id}
            data-id={csq.input_type + '-' + csq.id}
            data-name={csq.input_type + '-' + csq.id}
            rows="3"
            value={csq.answer_text}
            onChange={this.props.onTextChange}
            onBlur={this.props.onFocus}
          />
        </div>
        <div className="extra-padding-headline-bpp" />
      </div>
    );
  };

  displayRadioButton = csq => {
    if (csq.friendly_name !== 'csr_required') {
      return (
        <div key={csq.id}>
          <div className="row">
            <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span>
          </div>
          <div className="extra-padding-headline" />
          <div className={this.props.error && csq.error ? 'bpp-csq-gray-background warning-border row' : 'bpp-csq-gray-background row'}>
            {csq.radio_button
              .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
              .map((rb, index) => (
                <div key={index} data-id={index} className="reason-input-data-bpp">
                  <input
                    type="radio"
                    className="form-check-input"
                    name={csq.input_type + csq.id}
                    placeholder={csq.input_placeholder}
                    id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-name={csq.input_type + csq.id}
                    data-parent={csq.id}
                    data-label={rb.radio_label}
                    value={csq.answer_text}
                    checked={rb.radio_checked}
                    onChange={this.props.onRbChange}
                  />
                  <label className="csq-radio-label csq-radio-area" htmlFor={csq.input_type + '-' + csq.id + '-' + rb.id}>
                    {rb.radio_label}
                  </label>
                  {rb.radio_label === 'Yes' && rb.radio_checked && rb.toothSelector ? (
                    <div className={'csq-teeth-selector'}>
                      <span className={this.props.error && csq.error ? 'warning-text' : ''}>Specify location:</span>
                      <TeethSelector
                        selected={rb.selectedTeeth}
                        onClick={this.handleTeethClick(rb, csq.input_type + '-' + csq.id + '-' + rb.id)}
                        disabled={this.props.disabled}
                        hasUpper={this.props.selected}
                        hasLower={true}
                      />
                      <div className="extra-padding-headline" />
                    </div>
                  ) : null}
                  {rb.radio_checked && rb.text_area ? (
                    <div key={rb.id}>
                      {/* <div className="row">
                      <h4 className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{rb.text_area.question_text}</h4>
                    </div> */}
                      <div data-id={csq.id + '-' + rb.id + '-' + rb.text_area.id} className="reason-input-data-bpp">
                        <textarea
                          className={
                            this.props.error && csq.error ? 'form-control item-reason-comment warning-border row' : 'form-control item-reason-comment row'
                          }
                          placeholder={rb.text_area.input_placeholder}
                          id={csq.input_type + '-' + csq.id + '-' + rb.id + '-' + rb.text_area.id}
                          data-id={csq.input_type + '-' + csq.id + '-' + rb.id + '-' + rb.text_area.id}
                          data-name={csq.input_type + '-' + csq.id + '-' + rb.id + '-' + rb.text_area.id}
                          rows="2"
                          value={rb.text_area.answer_text}
                          onChange={this.props.onTextChange}
                          onBlur={this.props.onFocus}
                        />
                      </div>
                      <div className="extra-padding-headline-bpp" />
                    </div>
                  ) : null}
                </div>
              ))}
          </div>
          <div className="extra-padding-headline-bpp" />
        </div>
      );
    }
  };

  displayTextBox = csq => {
    return (
      <div key={csq.id}>
        <div className="row">
          <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span>
        </div>
        <div className="extra-padding-headline" />
        <div data-id={csq.id} className="reason-input-data-bpp">
          <input
            type="text"
            className={this.props.error && csq.error ? 'form-control patient-field warning-border row' : 'form-control patient-field row'}
            placeholder={csq.input_placeholder}
            id={csq.input_type + '-' + csq.id}
            data-id={csq.input_type + '-' + csq.id}
            data-name={csq.input_type + '-' + csq.id}
            value={csq.answer_text}
            onChange={this.props.onTextChange}
            onBlur={this.props.onFocus}
          />
        </div>
        <div className="extra-padding-headline-bpp" />
      </div>
    );
  };

  displayDate = csq => {
    return (
      <div key={csq.id}>
        <div className="row">
          <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span>
        </div>
        <div className="extra-padding-headline" />
        <div
          data-id={csq.id}
          className={
            this.props.error && csq.error
              ? 'bpp-csq-gray-background warning-border row reason-input-data-bpp'
              : 'bpp-csq-gray-background row reason-input-data-bpp'
          }
        >
          {/* <div data-id={csq.id} className="reason-input-data-bpp"> */}
          <input
            type="date"
            className={this.props.error && csq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
            placeholder={csq.input_placeholder}
            id={csq.input_type + '-' + csq.id}
            data-id={csq.input_type + '-' + csq.id}
            data-name={csq.input_type + '-' + csq.id}
            value={csq.answer_text}
            onChange={this.props.onTextChange}
            onBlur={this.props.onFocus}
          />
        </div>
        <div className="extra-padding-headline-bpp" />
      </div>
    );
  };

  displayCheckBox = csq => {
    return (
      <div key={csq.id}>
        <div className="row">
          <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span>
        </div>
        <div className="extra-padding-headline" />
        <div className={this.props.error && csq.error ? 'bpp-csq-gray-background warning-border row ' : 'bpp-csq-gray-background row'}>
          {csq.check_box
            .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
            .map((rb, index) => (
              <div key={index} data-id={index} className="reason-input-data-bpp">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name={csq.input_type + csq.id}
                  placeholder={csq.input_placeholder}
                  id={csq.input_type + '-' + csq.id + '-' + rb.id}
                  data-id={csq.input_type + '-' + csq.id + '-' + rb.id}
                  data-name={csq.input_type + csq.id}
                  data-parent={csq.id}
                  data-label={rb.label}
                  value={csq.answer_text}
                  checked={rb.checked}
                  onChange={this.props.onCbChange}
                />
                <label className="csq-radio-label csq-radio-area" htmlFor={csq.input_type + '-' + csq.id + '-' + rb.id}>
                  {rb.label}
                </label>
                {rb.checked && rb.toothSelector ? (
                  <div className={'csq-teeth-selector'}>
                    <span className={this.props.error && csq.error ? 'warning-text' : ''}>Specify location:</span>
                    <TeethSelector
                      selected={rb.selectedTeeth}
                      onClick={this.handleTeethClick(rb, csq.input_type + '-' + csq.id + '-' + rb.id)}
                      disabled={this.props.disabled}
                      hasUpper={this.props.selected}
                      hasLower={true}
                    />
                    <div className="extra-padding-headline" />
                  </div>
                ) : null}
              </div>
            ))}
        </div>
        <div className="extra-padding-headline-bpp" />
      </div>
    );
  };

  displayMq = csq => {
    return (
      <div key={csq.id}>
        <div className="row">
          {csq.question_text ? <span className={this.props.error && csq.error ? 'csq-label warning-text' : 'csq-label'}>{csq.question_text}</span> : null}
        </div>
        {csq.question_text ? <div className="extra-padding-headline" /> : null}
        {csq.format ? (
          this.displayMqDateDe(csq)
        ) : (
          <div className={this.props.error && csq.error ? 'bpp-csq-gray-background warning-border row' : 'bpp-csq-gray-background row'}>
            {csq.multiple_question
              .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
              .map((mq, index) => (
                <div key={index} className="col-sm-6">
                  <div className="row">
                    <span className="bold-text csq-label">{mq.question_text}</span>
                    <div className="extra-padding-headline-bpp" />
                  </div>
                  {mq.input_type === 'radio_button' ? this.displayMqRadioButton(csq, mq) : null}
                  {mq.input_type === 'text' ? this.displayMqTextBox(csq, mq) : null}
                  {mq.input_type === 'date' ? this.displayMqDate(csq, mq) : null}
                  <div className="extra-padding-headline-bpp" />
                </div>
              ))}
          </div>
        )}

        <div className="extra-padding-headline-bpp" />
      </div>
    );
  };

  displayMqRadioButton = (csq, mq) => {
    return (
      <div>
        {mq.radio_button
          .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
          .map((rb, index) => (
            <div key={index} data-id={index} className="reason-input-data-bpp">
              <input
                type="radio"
                className="form-check-input"
                placeholder={csq.input_placeholder}
                id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id}
                name={csq.input_type + mq.id}
                data-id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id}
                data-parent={csq.id}
                data-mq={mq.id}
                data-label={rb.radio_label}
                data-name={csq.input_type + mq.id}
                checked={rb.radio_checked}
                onChange={this.props.onRbChange}
              />
              <label className="csq-label csq-radio-area" htmlFor={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id}>
                {rb.radio_label}
              </label>
              {rb.radio_checked === true && rb.date ? (
                <div data-id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id} className="reason-input-data-bpp">
                  <label className="csq-radio-date-label">{rb.date.question_text}</label>
                  <input
                    type="date"
                    className={
                      this.props.error && rb.date.error
                        ? 'form-control patient-field warning-border csq-radio-date-field'
                        : 'form-control patient-field csq-radio-date-field'
                    }
                    placeholder={rb.date.input_placeholder}
                    id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id}
                    data-id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id}
                    data-name={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id}
                    value={rb.date.answer_text}
                    onChange={this.props.onTextChange}
                    onBlur={this.props.onFocus}
                  />
                </div>
              ) : null}
            </div>
          ))}
      </div>
    );
  };

  displayMqTextBox = (csq, mq) => {
    return (
      <div data-id={mq.id} className="reason-input-data-bpp">
        <input
          type="text"
          className={this.props.error && mq.error ? 'form-control patient-field warning-border row' : 'form-control patient-field row'}
          placeholder={csq.input_placeholder}
          id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
          value={mq.answer_text}
          onChange={this.props.onTextChange}
          onBlur={this.props.onFocus}
        />
      </div>
    );
  };

  displayMqDate = (csq, mq) => {
    return (
      <div data-id={mq.id} className="reason-input-data-bpp">
        <input
          type="date"
          className={this.props.error && mq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
          placeholder={csq.input_placeholder}
          id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
          value={mq.answer_text}
          onChange={this.props.onTextChange}
          onBlur={this.props.onFocus}
          disabled={
            mq.related_question_id
              ? this.props.treatment_data.questions
                  .filter(function(q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function(m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
                ? false
                : true
              : false
          }
        />
      </div>
    );
  };

  displayMqDateDe = csq => {
    return csq.multiple_question
      .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
      .map((mq, index) => (
        <div className={index === 0 ? 'col-sm-6  nopadding' : 'col-sm-6'}>
          <div className="question-box">
            <span className="bpp-csq-label" id={mq.id}>
              {mq.question_text}
            </span>
            <div className="extra-padding-headline" />
            <div className={this.props.error && mq.error ? 'bpp-csq-gray-background warning-border' : 'bpp-csq-gray-background'}>
              <div>
                <input
                  id={csq.input_type + '-' + csq.id + '-' + mq.id}
                  type="date"
                  className={this.props.error && mq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
                  data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
                  data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
                  value={mq.answer_text}
                  onChange={this.props.onTextChange}
                  onBlur={this.props.onFocus}
                />
              </div>
            </div>
          </div>
        </div>
      ));
  };

  render() {
    return (
      <div className="bpp-portal-view">
        <div className="ir-submission-heading">
          <h4 className="bold-text">Treatment Details - Step 2 of 3</h4>
        </div>
        {this.props.treatment_data ? (
          <div>
            <div className="row remove-margin noselect">
              <div>
                {this.props.treatment_data.questions
                  .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                  .map(csq =>
                    csq.input_type === 'text_area'
                      ? this.displayTextArea(csq)
                      : csq.input_type === 'text'
                      ? this.displayTextBox(csq)
                      : csq.input_type === 'date'
                      ? this.displayDate(csq)
                      : csq.input_type === 'radio_button'
                      ? this.displayRadioButton(csq)
                      : csq.input_type === 'multiple_question'
                      ? this.displayMq(csq)
                      : csq.input_type === 'check_box'
                      ? this.displayCheckBox(csq)
                      : null
                  )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default ItemReason;
