import React from 'react';

function WireSelectionRedoProgress(props) {
  return (
    <div className="wire-selection-output center-text">
      {!props.is_cancel_or_hold ? (
        <>
          <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
          <div className="margin-top-10">Wire Selection re-generation in progress...</div>
        </>
      ) : (
        <div>
          <span className="bold-text">Custom Wire WO Ref: </span>
          {props.ws_wo ? props.ws_wo : 'N/A'}
        </div>
      )}
    </div>
  );
}

export default WireSelectionRedoProgress;
