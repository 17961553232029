import React from 'react';
import Select from 'react-select';

/**
 * Displays a drop down input
 * @component
 */
function Dropdown(props) {
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        border: `1px solid ${state.selectProps.error ? '#e13146' : '#e1e2e2'}`,
        minHeight: '34px',
        height: '34px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '34px',
      padding: '0 6px',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),

    indicatorSeparator: (state) => ({
      display: 'none',
    }),

    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '34px',
    }),
  };

  return (
    <Select isDisabled={props.disabled} className="dropdown-input" classNamePrefix="dropdown-input" styles={customStyles} {...props} ref={props.dropdownRef} />
  );
}

export default Dropdown;
