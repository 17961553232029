import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * ScrollspyNav component
 *  - Based on react-scrollspy-nav
 * @component
 */
class ScrollspyNav extends Component {
  /**
   * onScroll event handler.
   *  - checks the current window offset and compares it with the pageYOffset of each
   *  target id
   *  - highlights the nav link when scrolling to a corresponding section
   * @function
   */
  onScroll = () =>
    this.props.scrollTargetIds.forEach((sectionID, index) => {
      if (!document.getElementById(sectionID)) {
        console.warn(`No element with id ${sectionID} present in the DOM`);
        return;
      }

      const scrollSectionOffsetTop = document.getElementById(sectionID).offsetTop;
      const linkElement = this.getNavLinkElement(sectionID);
      if (linkElement) {
        const isActive =
          window.pageYOffset - this.props.offset >= scrollSectionOffsetTop &&
          window.pageYOffset < scrollSectionOffsetTop + document.getElementById(sectionID).scrollHeight;
        if (isActive) {
          linkElement.classList.add(this.props.activeNavClass);
          this.clearOtherNavLinkActiveStyle(sectionID);
        } else {
          linkElement.classList.remove(this.props.activeNavClass);
        }

        const isBottom = window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
        if (isBottom && index === this.props.scrollTargetIds.length - 1) {
          linkElement.classList.add(this.props.activeNavClass);
          this.clearOtherNavLinkActiveStyle(sectionID);
        }
      }
    });

  easeInOutQuad(current_time, start, change, duration) {
    current_time /= duration / 2;
    if (current_time < 1) return (change / 2) * current_time * current_time + start;
    current_time--;
    return (-change / 2) * (current_time * (current_time - 2) - 1) + start;
  }

  /**
   * Perform scroll animation with given start, destination and duration
   * @param {Number} start
   * @param {Number} dest
   * @param {Number} duration
   */
  scrollTo(start, dest, duration) {
    const change = dest - start;
    const increment = 10;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  /**
   * Get the nav link element with a given sectionID that the nav link links to
   * @param {String} sectionID
   */
  getNavLinkElement(sectionID) {
    return document.querySelector(`a[href='#${sectionID}']`);
  }

  /**
   * Clear the highlight style on the non-current viewed nav elements
   * @param {String} excludeSectionID
   */
  clearOtherNavLinkActiveStyle(excludeSectionID) {
    this.props.scrollTargetIds.forEach((sectionID) => {
      if (sectionID !== excludeSectionID) {
        const linkElement = this.getNavLinkElement(sectionID);
        if (linkElement) linkElement.classList.remove(this.props.activeNavClass);
      }
    });
  }

  addOnClickListener() {
    document
      .querySelector("div[data-nav='list']")
      .querySelectorAll('a')
      .forEach((navLink) => {
        navLink.addEventListener('click', (event) => {
          event.preventDefault();
          const sectionID = navLink.getAttribute('href').replace('#', '');

          if (sectionID) {
            if (document.getElementById(sectionID)) {
              const scrollTargetPosition = document.getElementById(sectionID).offsetTop;
              this.scrollTo(window.pageYOffset, scrollTargetPosition + this.props.offset, this.props.scrollDuration);
            } else {
              console.warn(`No element with id ${sectionID} present in the DOM`);
            }
          } else {
            this.scrollTo(window.pageYOffset, 0, this.props.scrollDuration);
          }
        });
      });
  }

  componentDidMount() {
    this.onScroll();
    this.addOnClickListener();
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    return <div data-nav="list">{this.props.children}</div>;
  }
}

ScrollspyNav.propTypes = {
  scrollTargetIds: PropTypes.array.isRequired,
  activeNavClass: PropTypes.string.isRequired,
  offset: PropTypes.number,
  scrollDuration: PropTypes.number,
};

ScrollspyNav.defaultProps = {
  offset: 0,
  scrollDuration: 300,
};

export default ScrollspyNav;
