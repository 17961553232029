import React from 'react';
import PropTypes from 'prop-types';
import ProcessTracker from '../../../components/workflow/process_tracker';

/**
 * Calls process tracker for Production section
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - Production process
 */
function ProductionProcess(props) {
  const { production_stage } = props;

  return <ProcessTracker {...props} process="production" hideRuler stage={production_stage} />;
}

ProductionProcess.propTypes = {
  production_stage: PropTypes.string.isRequired,
};

export default ProductionProcess;
