import Axios from 'axios';

import {
  generateManualWireJSON,
} from '../../../business/case_details/ir_production/manual_wire/manual_wire_utils';

export const SET_LOOP_TO_DEFAULT = 'SET_LOOP_TO_DEFAULT';

export const setDefaultLoop = (arch) => ({
  type: SET_LOOP_TO_DEFAULT,
  payload: {
    arch,
  },
});

export const SET_LOCKET_TO_DEFAULT = 'SET_LOCKET_TO_DEFAULT';

export const setDefaultLocket = (arch) => ({
  type: SET_LOCKET_TO_DEFAULT,
  payload: {
    arch,
  },
});

export const SET_MANUAL_WIRE_ARCH = 'SET_MANUAL_WIRE_ARCH';

export const setManualWireArch = (arch) => ({
  type: SET_MANUAL_WIRE_ARCH,
  payload: {
    arch,
  },
});

export const SET_MANUAL_WIRE_AUTO = 'SET_MANUAL_WIRE_AUTO';

export const setManualWireAuto = (auto) => ({
  type: SET_MANUAL_WIRE_AUTO,
  payload: {
    auto,
  },
});

export const SET_MANUAL_WIRE_WIRE = 'SET_MANUAL_WIRE_WIRE';

export const setManualWireWire = (wire) => ({
  type: SET_MANUAL_WIRE_WIRE,
  payload: {
    wire,
  },
});

export const SET_MANUAL_WIRE_PROGRAM = 'SET_MANUAL_WIRE_PROGRAM';

export const setManualWireProgram = (program) => ({
  type: SET_MANUAL_WIRE_PROGRAM,
  payload: {
    program,
  },
});


export const saveDraft = (updateDataFn, data={}, useData=false) => {
  return (dispatch, getState) => {
    updateDataFn(data);
    const manualWireState = useData ? data : getState().manualWireReducer;

    const {customData, arch, auto, wire, program} = manualWireState;
    const teeths = arch !== '' ? manualWireState[arch] : {};
    const caseId = customData.case_id;
    const json = generateManualWireJSON(caseId, arch, auto, wire, program, teeths);
    const post_data = {
      cdata: JSON.stringify(customData),
      settings: JSON.stringify(json),
      arch,
      wire,
    };

    Axios.post(`/apiv3/manualwire/${caseId}/save_draft`, post_data)
      .catch(error => {
        if (error.response.status === 409) dispatch(setRefresh(true));
        else dispatch(setManualWireError(true, [error.message]));
        console.error('Error on handleGenerate', error);
      });
  };
};

export const SET_MANUAL_WIRE_LOCKET = 'SET_MANUAL_WIRE_LOCKET';

export const setManualWireLocket = (data) => ({
  type: SET_MANUAL_WIRE_LOCKET,
  payload: {
    arch: data.arch,
    teethLabel: data.teethLabel,
    locket: data.locketValue,
  },
});

export const SET_MANUAL_WIRE_LOOP = 'SET_MANUAL_WIRE_LOOP';

export const setManualWireLoop = (data) => ({
  type: SET_MANUAL_WIRE_LOOP,
  payload: {
    arch: data.arch,
    teethLabel: data.teethLabel,
    loop: data.loopValue,
  },
});

export const SET_MANUAL_WIRE_OFFSET_X = 'SET_MANUAL_WIRE_OFFSET_X';

export const setManualWireOffsetX = (data) => ({
  type: SET_MANUAL_WIRE_OFFSET_X,
  payload: {
    arch: data.arch,
    teethLabel: data.teethLabel,
    offsetX: data.offsetX,
  },
});

export const SET_MANUAL_WIRE_OFFSET_Y = 'SET_MANUAL_WIRE_OFFSET_Y';

export const setManualWireOffsetY = (data) => ({
  type: SET_MANUAL_WIRE_OFFSET_Y,
  payload: {
    arch: data.arch,
    teethLabel: data.teethLabel,
    offsetY: data.offsetY,
  },
});

export const SET_MANUAL_WIRE_LENGTH = 'SET_MANUAL_WIRE_LENGTH';

export const setManualWireLength = (data) => ({
  type: SET_MANUAL_WIRE_LENGTH,
  payload: {
    arch: data.arch,
    teethLabel: data.teethLabel,
    length: data.length,
  },
});

export const SET_MANUAL_WIRE_CASE_DATA = 'SET_MANUAL_WIRE_CASE_DATA';

export const setCaseData = (arch, caseData, filteredIndexes) => ({
  type: SET_MANUAL_WIRE_CASE_DATA,
  payload: {
    arch,
    caseData,
    filteredIndexes,
  },
});

export const SET_MANUAL_WIRE_DEFAULT_CASE_DATA = 'SET_MANUAL_WIRE_DEFAULT_CASE_DATA';

export const setDefaultCaseData = (arch, caseData) => ({
  type: SET_MANUAL_WIRE_DEFAULT_CASE_DATA,
  payload: {
    arch,
    caseData,
  },
});

export const SET_MANUAL_WIRE_TEETH_ERROR = 'SET_MANUAL_WIRE_TEETH_ERROR';

export const setTeethError = (errorMessage, teethLabel) => ({
  type: SET_MANUAL_WIRE_TEETH_ERROR,
  payload: {
    errorMessage,
    teethLabel,
  },
});

export const REMOVE_MANUAL_WIRE_TEETH_ERROR = 'REMOVE_MANUAL_WIRE_TEETH_ERROR';

export const removeTeethError = (errorMessage, teethLabel) => ({
  type: REMOVE_MANUAL_WIRE_TEETH_ERROR,
  payload: {
    errorMessage,
    teethLabel,
  },
});

export const SET_MANUAL_WIRE_REFRESH = 'SET_MANUAL_WIRE_REFRESH';

export const setRefresh = (refresh) => ({
  type: SET_MANUAL_WIRE_REFRESH,
  payload: {
    refresh,
  },
});

export const SET_MANUAL_WIRE_ERROR = 'SET_MANUAL_WIRE_ERROR';

export const setManualWireError = (error, errorMessage) => ({
  type: SET_MANUAL_WIRE_ERROR,
  payload: {
    error,
    errorMessage,
  },
});

export const ADD_MANUAL_WIRE_ERROR = 'ADD_MANUAL_WIRE_ERROR';

export const addManualWireError = (errorMessage) => ({
  type: ADD_MANUAL_WIRE_ERROR,
  payload: {
    errorMessage,
  },
});

export const SET_MANUAL_WIRE_TEETH_DISABLED = 'SET_MANUAL_WIRE_TEETH_DISABLED';

export const setTeethDisabled = (arch, teethLabel, disabled) => ({
  type: SET_MANUAL_WIRE_TEETH_DISABLED,
  payload: {
    arch,
    teethLabel,
    disabled,
  },
});

export const SET_MANUAL_WIRE_TEETH_RANGE = 'SET_MANUAL_WIRE_TEETH_RANGE';

export const setTeethRange = (arch, range) => ({
  type: SET_MANUAL_WIRE_TEETH_RANGE,
  payload: {
    arch,
    range,
  },
});

export const SET_MANUAL_WIRE_TEETH_MISSING = 'SET_MANUAL_WIRE_TEETH_MISSING';

export const setMissingTeeths = (arch, missingTeeths) => ({
  type: SET_MANUAL_WIRE_TEETH_MISSING,
  payload: {
    arch,
    missingTeeths,
  },
});

export const SET_MANUAL_WIRE_ARCH_OPTIONS = 'SET_MANUAL_WIRE_ARCH_OPTIONS';

export const setArchOptions = (archOptions) => ({
  type: SET_MANUAL_WIRE_ARCH_OPTIONS,
  payload: {
    archOptions,
  },
});

export const SET_MANUAL_WIRE_SMARTWIRE_OPTIONS = 'SET_MANUAL_WIRE_SMARTWIRE_OPTIONS';

export const setSmartwireOptions = (arch, smartwireOptions) => ({
  type: SET_MANUAL_WIRE_SMARTWIRE_OPTIONS,
  payload: {
    arch,
    smartwireOptions,
  },
});


export const SET_MANUAL_WIRE_CUSTOM_DATA = 'SET_MANUAL_WIRE_CUSTOM_DATA';

export const setCustomData = (customData) => ({
  type: SET_MANUAL_WIRE_CUSTOM_DATA,
  payload: {
    customData,
  },
});
