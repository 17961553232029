import React from 'react';

/**
 * Displays a hamburger menu with animation
 * @component
 */
function HamburgerMenu(props) {
  const className = props.active ? 'hamburger hamburger--slider active' : 'hamburger hamburger--slider';
  return (
    <div className={className} aria-label="Menu" aria-controls="navigation">
      <div className="hamburger-box">
        <div className="hamburger-inner" />
      </div>
    </div>
  );
}

export default HamburgerMenu;
