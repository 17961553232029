import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import { removeCaseIdInitialNumber } from '../../../common/functions';
import { getCaseDetails, isEnrolledProviderEditPhase2 } from '../../../redux/reducers/common/common_case_details';
import { getWasmLoading } from '../../../redux/selectors/wasm_viewer';
import { getBoltonAnalysisValues } from '../wasm_controller/wasm_controller';
import { sidebarButton } from './sidebar_constants';

function WasmSidebarInfo(props) {
  const { case_details, activeButton, onClickHistoryButton, onClickImageButton, onClickBoltonButton, isEnrolledPhase2, isWasmLoading } = props;
  const [boltonValues, setBoltonValues] = useState(null);
  const history_classnames = ['fa fa-comments'];
  const images_classnames = ['fa fa-picture-o'];
  const bolton_classnames = ['fa fa-bold'];
  if (activeButton === sidebarButton.history) history_classnames.push('active');
  if (activeButton === sidebarButton.images) images_classnames.push('active');
  if (activeButton === sidebarButton.bolton) bolton_classnames.push('active');
  const doctor_name = getDoctorName(case_details);
  const patient_name = getPatientName(case_details);
  const case_id = getCaseID(case_details);

  const hideBoltonButton = () => {
    if (!isEnrolledPhase2 || isWasmLoading || !boltonValues) {
      return true;
    }
    if (!isWasmLoading) {
      return boltonValues?.status === 404;
    }
    return false;
  };
  useEffect(() => {
    if (!isWasmLoading && !boltonValues) setBoltonValues(getBoltonAnalysisValues());
  }, [isWasmLoading]);
  return (
    <div className="wasm-sidebar-top">
      <div className="wasm-sidebar-name--vertical header header--bold">{patient_name}</div>
      <div className="wasm-sidebar-patient-info header header--bold">
        <div className="wasm-sidebar-patient-name">
          Patient: <span>{patient_name}</span>
        </div>
        <div className="wasm-sidebar-caseid">
          Case ID: <span>{case_id}</span>
        </div>
        <div className="wasm-sidebar-doctor-name">
          Doctor: <span>{doctor_name}</span>
        </div>
      </div>
      <div className="wasm-sidebar-buttons">
        <i className={`${history_classnames.join(' ')} history-icon`} aria-hidden onClick={onClickHistoryButton} />
        <i className={`${images_classnames.join(' ')} photo-icon`} aria-hidden onClick={onClickImageButton} />
        <HideContentIf condition={hideBoltonButton()}>
          <i className={`${bolton_classnames.join(' ')} bolton-icon`} aria-hidden onClick={onClickBoltonButton} />
        </HideContentIf>
      </div>
      <div className="horizontal-divider" />
    </div>
  );
}

/**
 * Retrieves the Doctor's name from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the Doctor's name where applicable
 */
function getDoctorName(case_details) {
  const doctor = case_details && case_details.doctor;
  if (doctor) {
    return `${doctor.first_name} ${doctor.last_name}`;
  } else {
    return '';
  }
}

/**
 * Retrieves the Patient name from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the Patient name where applicable
 */
function getPatientName(case_details) {
  const patient = case_details && case_details.patient;
  if (patient) {
    return `${patient.first_name} ${patient.last_name}`;
  } else {
    return '';
  }
}

/**
 * Retrieves the case id from the case details
 *
 * @function
 * @param {Object} case_details - Contains all the cases' details
 * @return {String} A string value of the case id name where applicable
 */
function getCaseID(case_details) {
  const case_id = case_details && case_details.case_id;
  if (case_id) {
    return removeCaseIdInitialNumber(case_id);
  } else {
    return '';
  }
}

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
    isWasmLoading: getWasmLoading(state),
  };
};

export default connect(mapStateToProps)(WasmSidebarInfo);
