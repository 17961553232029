import { WebGLRenderer } from 'three';

/**
 * Creates the renderer for the setup viewer
 * @function
 * @return {THREE.WebGLRenderer} The renderer
 */
function createRenderer() {
  const renderer = new WebGLRenderer({ antialias: true });

  return renderer;
}

export { createRenderer };
