import React from 'react';

function CaseTabs(props) {
  return (
    <div className="case-tab-container">
      {props.items.map(
        (item) =>
          !item.hide && (
            <div className="case-tab__item-container" key={item.data}>
              <div className={`case-tab__item${item.active ? ' case-tab__item--active' : ''}`} onClick={props.onTabSelection} data-tab={item.data}>
                {item.icon}
                {item.label} ({item.count})
              </div>
              <div className="case-tab__sub-item">{item.active && props.children}</div>
            </div>
          )
      )}
    </div>
  );
}

export default CaseTabs;
