import React from 'react';
import CircleLoader from '../../components/loader/circle_loader';
import Modal from '../../components/modal/modal';

const Submitter = () => (
  <div className="submission-loader-container">
    <Modal
      preset="submitting"
      message_text={
        <div id='modalContentContainer'>
          <CircleLoader />
          <span id="header">
            Submitting...
          </span>
          <span>
            Please do not navigate from this page or close browser.<br/> You will be redirected automatically once complete.
          </span>
      </div>  
      }
    ></Modal>
  </div>
);

export default Submitter;
