import _ from 'lodash';
import React, { Component } from 'react';

import { getTempLink } from '../../common/dropbox';
import FileUpload from '../../components/file_upload/file_upload';

class FileUploadUrlInterface extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: props.filesUploaded,
      last_index: -1,
      file_processed_count: 0,
    };
  }
  /**
   * Builds the file url entry for stl
   * @function
   */
  buildFileURL = () => {
    const { filesUploaded } = this.props;
    let scan_counter = 0;

    filesUploaded.map((file, index) => {
      const is_scan = file && file.upload_data && file.upload_data.includes('.stl');
      const has_attributes = file && file.original_filename;

      if (is_scan && has_attributes) {
        scan_counter += 1;
        this.fetchFileURL(file);
      }

      return file;
    });

    this.setState({
      last_index: scan_counter,
    });
  };
  /**
   * fetches an temporary link and to be store
   * @function
   * @param {Object} file - file object
   */
  fetchFileURL = (file) => {
    let that = this;
    Promise.resolve(getTempLink(file.original_filename, file.upload_data)).then((url) => {
      that.updateFilesState(file, 'file_url', url);
      that.runRebuild();
    });
  };

  /**
   * If all files are processed, reloads thumbnail
   * @function
   */
  runRebuild = () => {
    if (this.state.file_processed_count >= this.state.last_index) {
      const files = this.displayOcclusionView();
      this.setState({ files: files });
    }
  };

  /**
   * Updates submission files with Occlusion view record
   *
   * @function
   * @return {Array} Returns updated file list
   */
  displayOcclusionView() {
    const { files } = this.state;
    let scan_url_count = [];
    let last_index = 0;

    files.map((file, index) => {
      if (file.file_url) {
        scan_url_count.push(file.file_url);
      }
      last_index = Math.max(last_index, index);
      return file;
    });

    if (scan_url_count.length === 2) {
      files.push({
        id: last_index + 1,
        file_type: 'multi_scans',
        upload_data: scan_url_count[1],
        original_filename: 'occlusion_view',
        created_date: '',
        created_by_id: '',
        file_url: scan_url_count[0],
      });
    }
    return files;
  }

  /**
   * fetches an temporary link and to be store
   * @function
   * @param {Object} fetch_file - file object
   * @param {String} key - key to be replaced with url in file object
   * @param {String} url - Value of the stream url link
   */
  updateFilesState = (fetch_file, key, url) => {
    const { files, file_processed_count } = this.state;
    files.map((file) => {
      if (file.id === fetch_file.id) {
        file[key] = url;
      }

      return file;
    });

    const update_files = _.cloneDeep(files);
    this.setState({
      files: update_files,
      file_processed_count: file_processed_count + 1,
    });
  };

  componentDidMount() {
    this.buildFileURL();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.filesUploaded) !== JSON.stringify(this.props.filesUploaded)) this.setState({ files: this.props.filesUploaded });
    if (JSON.stringify(this.state.files.map((file) => file.id)) !== JSON.stringify(prevState.files.map((file) => file.id))) this.buildFileURL();
  }

  render() {
    return <FileUpload {...this.props} filesUploaded={this.state.files} />;
  }
}

export default FileUploadUrlInterface;
