import Axios from 'axios';
import _ from 'lodash';
import { setTokenHeader, configLightBox } from '../../../common/functions';
import { maxScale, minScale, zoomStep } from './image_viewer';

export const defaultState = { scale: 1, rotate: 0, flipV: false, flipH: false };
const IMAGE_MIME_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/tiff', 'image/gif'];
const SCAN_MIME_TYPES = [
  '.stl',
  'model/stl',
  'application/sla',
  'application/vnd.ms-pki.stl',
  'application/vnd.ms-pkistl',
  'application/x-navistyle',
  'application/netfabb',
  'application/octet-stream',
  'model/x.stl-binary',
  'model/x.stl-ascii',
  'application/vnd.epson.salt',
  'application/wavefront-stl',
  'application/vnd.ms-pki.stl',
];

/**
 * Constructs submission files to be displayed
 * @function
 * @param {object} uploads - list of submission file data objects
 * @param {boolean} teethViewer - flag to display teeth viewer image
 * @param {boolean} hide_occlusion - flag to hide occlusion view though scans exist
 */
export function buildRecordStates(uploads, teethViewer = false, hide_occlusion = false) {
  const scanUploads = [];

  // iTero integration
  const iteroScans = [];

  const recordStates = [];
  if (teethViewer) recordStates.push(buildTeethViewerRecord());
  uploads.forEach((upload, i) => {
    if (isPhoto(upload)) {
      recordStates.push(buildImageRecord(upload, i));

      // iTero integration
    } else if (isIteroScan(upload)) {
      iteroScans.push(upload);
      recordStates.push(buildIteroScanRecord(upload, i));
    } else if (isScan(upload)) {
      scanUploads.push(upload);
      recordStates.push(buildScanRecord(upload, i));
    }
  });

  if (scanUploads.length === 2 && !hide_occlusion) {
    recordStates.push(buildOcclusionScanRecord(scanUploads))
  }

  // iTero integration
   if (iteroScans.length === 2 && !hide_occlusion) {
    recordStates.push(buildIteroOcclusionScanRecord(iteroScans))
  }

  return recordStates;
}

function isPhoto(upload) {
  return upload.file_type === 'photos' || upload.file_type === 'xrays' || IMAGE_MIME_TYPES.includes(upload.mime_type);
}

function isScan(upload) {
  return upload.file_type === 'scans' || SCAN_MIME_TYPES.includes(upload.mime_type);
}

/**
 * iTero integration
 *
 * @param {object} upload - submission file data object
 * @returns {boolean}
 */
function isIteroScan(upload) {
  return upload.file_type === 'iteroScans';
}

function buildImageRecord(record, i) {
  let id = record.incomplete_case_file_id ? record.incomplete_case_file_id : record.id ? record.id : `${record.file_type}-${i}`;
  let image_record = {
    id: id,
    tooltip: `${record.original_filename}`,
    src: '',
    thumbnail: '',
    loading: true,
    url: record.upload_data,
    image: true,
    state: getRecordState(record.record_state),
    hideDeleteIcon: false,
    caption: configLightBox([record])[0]?.caption,
  };

  const combined_record = Object.assign(image_record, record);

  return combined_record;
}

function buildScanRecord(record, i) {
  let scan_record = {
    id: record.incomplete_case_file_id ? record.incomplete_case_file_id : record.id ? record.id : `${record.file_type}-${i}`,
    tooltip: `${record.original_filename}`,
    src: record.file_url,
    thumbnail: '/static/img/model.png',
    loading: false,
    scan: true,
    hideDeleteIcon: false,
    caption: configLightBox([record])[0]?.caption,
  };

  const combined_record = Object.assign(scan_record, record);

  return combined_record;
}

/**
 * iTero integration
 *
 * @param {object} upload - submission file data object
 * @returns {boolean}
 */
function buildIteroScanRecord(record, i) {
  const newRecord = buildScanRecord(record, i);

  // delete scan property
  delete newRecord.scan;

  // add iteroScan property as true
  newRecord.iteroScan = true;

  return newRecord;
}

function buildOcclusionScanRecord(scanUploads) {
  let scan_record = {
    id: 'occlusion-view',
    tooltip: 'Occlusion View',
    src: scanUploads[0].file_url,
    alt: scanUploads[1].file_url,
    thumbnail: '/static/img/model.png',
    loading: false,
    scan: true,
    hideDeleteIcon: false,
  };

  const combined_record = Object.assign(scan_record, scanUploads);

  return combined_record;
}

function buildIteroOcclusionScanRecord(scanUploads) {
  const newRecord = buildOcclusionScanRecord(scanUploads);

  // delete scan property
  delete newRecord.scan;

  // add iteroScan property as true
  newRecord.iteroScan = true;

  // update id
  newRecord.id = 'itero-occlusion-view';

  return newRecord;
}

export function getRecordState(state) {
  let parsed = state;
  try {
    if (typeof parsed === 'string') parsed = JSON.parse(parsed);
  } catch (e) {}

  return isValidRecordState(parsed) ? parsed : defaultState;
}

function isValidRecordState(state) {
  return typeof state === 'object' && state && 'scale' in state && 'rotate' in state && 'flipV' in state && 'flipH' in state;
}

export function getNewRecordState(action, state) {
  let newState = null;
  if (action === 'rotate') {
    newState = { rotate: (state.rotate - 90) % 360 };
  } else if (action === 'flipV') {
    newState = { flipV: !state.flipV };
  } else if (action === 'flipH') {
    newState = { flipH: !state.flipH };
  } else if (action === 'zoomIn') {
    newState = { scale: Math.min(state.scale + zoomStep, maxScale) };
  } else if (action === 'zoomOut') {
    newState = { scale: Math.max(state.scale - zoomStep, minScale) };
  } else if (typeof action === 'number') {
    newState = { scale: action };
  } else {
    newState = defaultState;
  }
  return { ...state, ...newState };
}

export function buildTeethViewerRecord() {
  return {
    id: 'teeth-viewer',
    tooltip: 'Teeth Viewer',
    src: '/static/img/teeth_viewer.png',
    thumbnail: '/static/img/teeth_viewer.png',
    loading: false,
    viewer: true,
    children: true,
    hideDeleteIcon: false,
  };
}

function syncRecordState(id, newState, caseType = 'incomplete') {
  setTokenHeader();
  const request = {
    url: `/apiv3/recordstate/${caseType}/${id}`,
    method: 'POST',
    data: newState,
  };
  //Prevent 403 error if no id or is temporary id
  if (id && typeof id === 'string' && !id.includes('-')) {
    Axios(request).catch((err) => console.log(err));
  }
}

export const syncRecordStateDebounce = _.debounce(syncRecordState, 1000);

export async function getImageURLSrc(url) {
  let new_src = '/static/img/no-image.png';

  const request = {
    url: `/api/thumbnail/?file=${url}&size=lrg`,
    method: 'GET',
    responseType: 'blob',
  };
  return Axios(request)
    .then((res) => {
      if (res.data.size !== 0) {
        new_src = window.URL.createObjectURL(new Blob([res.data]));
      }
    })
    .catch((err) => console.log(err))
    .then(() => new_src);
}

export function getNewImageRecords(image_records, id, new_src) {
  const new_image_records = image_records.map((record) => {
    return record.id !== id
      ? record
      : {
          ...record,
          src: new_src,
          thumbnail: new_src,
          loading: false,
        };
  });
  return new_image_records;
}
