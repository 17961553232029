import React from 'react';
import PropTypes from 'prop-types';
import SortHeader from '../../../components/layout/sort_header';
import { translateStatus } from '../../../common/helpers';
import DivTableShimmer from './div_table_shimmer';

/**
 * Displays a foldable div table
 * @component
 */
function DivTable(props) {
  return (
    <>
      {!props.hideColumnName && (
        <div className="div-table--heading-container">
          {props.table_data.map((heading) => {
            return heading.display ? (
              <div
                key={heading.id}
                className={`div-table--heading hide-on-mobile${!heading.label ? ' div-table--heading--empty' : ''}`}
                id={heading.id}
                data-method={heading.id}
                onClick={heading.can_sort ? props.onSortClick : null}
                style={{ width: heading.col_width }}
              >
                <SortHeader title={heading.label} category={heading.id} sort_method={props.sort_method} ascending={props.sort_order_ascending[heading.id]} />
              </div>
            ) : null;
          })}
        </div>
      )}

      <div className="context-menu-container" style={{ position: 'relative' }}>
        {props.isLoading ? <DivTableShimmer table_data={props.table_data} rowSize={15} /> : null}
        {!props.isLoading &&
          props.field_data &&
          props.field_data.map((data, index) => {
            const status = data.status ? translateStatus(data.status, 'ipp') : data.ireq_status ? data.ireq_status : '';
            let data_meta = {};
            let is_smile_sim = data.smile_id ? true : false;

            props.meta_data.map((meta) => {
              data_meta[`data-${meta}`] = data[meta];
              return null;
            });

            return (
              <div
                onMouseUp={props.onCaseTrClick}
                className={
                  props.showCancelCase &&
                  ((props.selected_id && props.selected_id.replace('DE1', 'DE') === data.case_id) ||
                    (props.selected_draft_id && props.selected_draft_id === data.draft_case_id))
                    ? 'div-table--content red-color'
                    : `div-table--content${is_smile_sim ? ' div-table--content--no-notes' : props.less_space ? ' div-table--content--accounts' : ''}`
                }
                key={index}
                {...data_meta}
                data-status={status}
                onMouseOver={props.onMouseOver}
              >
                {props.table_data.map((heading, heading_index) => {
                  return heading.display ? (
                    <div
                      key={heading_index}
                      className={`div-table--item hide-on-mobile${heading.get_class_name ? ` ${heading.get_class_name(data, heading)}` : ''}`}
                      style={{ width: heading.col_width }}
                    >
                      {heading.data_function
                        ? heading.data_function(props, data, heading.data_fields)
                        : heading.data_fields && heading.data_fields.length > 0
                        ? data[heading.data_fields[0]] !== null
                          ? data[heading.data_fields[0]]
                          : '- - - - -'
                        : '- - - - -'}
                    </div>
                  ) : null;
                })}
                {props.table_data.filter((data) => data.display === true).length % 2 === 1 ? <div className="div-table--item hide-on-mobile"></div> : null}

                {props.mobile_data.map((heading, heading_index) => {
                  return (
                    <div
                      key={heading_index}
                      className={`div-table--item show-on-mobile div-table--item-${heading_index}`}
                      style={{ width: heading.col_width, ...heading.tableItemStyle }}
                    >
                      {heading.data_function
                        ? heading.data_function(props, data, heading.data_fields)
                        : heading.data_fields && heading.data_fields.length > 0
                        ? data[heading.data_fields[0]]
                        : '- - - - -'}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
    </>
  );
}

DivTable.propTypes = {
  table_data: PropTypes.array,
  meta_data: PropTypes.array,
  field_data: PropTypes.array,
  mobile_data: PropTypes.array,
};

DivTable.defaultProps = {
  meta_data: [],
  table_data: [],
  field_data: [],
  mobile_data: [],
};

export default DivTable;
