import { COUNTRIES } from '../../common/constants/countries';
import { US_STATES } from '../../common/constants';

const isValidState = (country, state) => {
  if (country == 'United States') {
    return state in US_STATES;
  }
  return state !== '';
};

const isValidCountry = (country) => {
  return Object.values(COUNTRIES).includes(country);
};

const isValidLocation = (city, state, zip, country) => {
  if (!country) return false;
  if (country == 'United States') {
    return city !== '' && state !== '' && state in US_STATES && zip !== '';
  }
  return city !== '' && state !== '' && zip !== '';
};

export { isValidState, isValidCountry, isValidLocation };
