import React, { useEffect } from 'react';
import { displaySingleToothIdbFile } from './single_tooth_idb_success';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

/**
 * Displays Single-Tooth IDB Tray generation failed component
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - JSX for component
 */
function SingleToothIdbFailure(props) {
  const BootstrapTooltip = withStyles({
    arrow: {
      color: 'rgba(0,0,0,0.8)',
    },
    tooltip: {
      fontSize: '12px',
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
  })(Tooltip);

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip({ container: 'main' });
  }, []);

  const displayButtons = (has_override_permission, has_generate_permission) => {
    return !props.shipping_approved ? (
      <div className="btn-action">
        {!props.single_tooth_idb_final_file && (
          <>
            {has_generate_permission && (
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  props.setSingleToothIdbModal('retry');
                }}
              >
                Retry
              </button>
            )}
            {has_override_permission && (
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  props.setSingleToothIdbModal('override');
                }}
              >
                Override
              </button>
            )}
          </>
        )}
      </div>
    ) : null;
  };

  return (
    <UserPermissionsContext.Consumer>
      {(user_roles_and_permissions) => {
        const idb_gen_type = props.id.includes('-IR') ? 'IREQ' : 'CASE';
        const has_override_permission = userHasPermission(`${idb_gen_type}_IDB_OVERRIDE`, user_roles_and_permissions.permissions);
        const has_generate_permission = userHasPermission(`${idb_gen_type}_IDB_GENERATE`, user_roles_and_permissions.permissions);
        return (
          <div>
            {props.single_tooth_idb_final_file ? (
              displaySingleToothIdbFile(props, 'final')
            ) : (
              <>
                <div>Generation Failure</div>
                <div className="center-text underline-text margin-top-10">
                  <div>
                    <BootstrapTooltip title={props.single_tooth_idb_status_comment} placement="top" arrow>
                      <i className="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i>
                    </BootstrapTooltip>
                  </div>
                </div>
                {has_generate_permission && (
                  <>
                    <div className="single-tooth-idb__failure">Manually upload the document below or retry generation</div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-bpp-upload btn-margin-bottom"
                        onClick={() => {
                          props.setSingleToothIdbModal('upload');
                        }}
                      >
                        Upload
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
            {displayButtons(has_override_permission, has_generate_permission)}
          </div>
        );
      }}
    </UserPermissionsContext.Consumer>
  );
}

export default SingleToothIdbFailure;
