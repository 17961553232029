import React from 'react';

import { closePDFModalOnRedirect, getPdfURL } from '../../../common/pdf';
import PDFViewerInterface from '../../../components/pdf-viewer/pdf_viewer';
import SimpleModal from './simple-modal';

const Pdf = ({ pdfUrl, fileName }) => {
  const fileUrl = getPdfURL(pdfUrl);
  if (fileUrl) {
    return (
      <>
        <PDFViewerInterface fileUrl={fileUrl} filename={fileName} />
        {closePDFModalOnRedirect()}
      </>
    );
  }
};

export const PdfModalDialog = ({ show, onClose, title, children }) => {
  return (
    <SimpleModal show={show} size="lg" contentClassName={'pdf-modal-content'}>
      <SimpleModal.Header title={title} onClose={onClose} />
      <SimpleModal.ContentBody>
        {children}
      </SimpleModal.ContentBody>
      <SimpleModal.FooterWithButtons onClickCancel={onClose} showConfirm={false} cancelButtonText={'Close'} />
    </SimpleModal>
  );
};

export const PdfModal = ({ show, pdfUrl, fileName, onClose, title }) => {
  return (
    <PdfModalDialog show={show} title={title} onClose={onClose}>
        <Pdf pdfUrl={pdfUrl} fileName={fileName} />
    </PdfModalDialog>
  );
};
