import './itero_selection.scss';

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getFetchIteroScansPending } from '../../redux/reducers/ipp/itero/itero_files';

import { getDoctorIdFromPath, removeCaseIdInitialNumber } from '../../common/functions';
import { groupIteroFilesByIteroId } from '../../common/itero_utils';

import AlertContainer from '../components/container/alert_container';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import { UserPermissionsContext } from '../../context/user_permission';
import TextBox from '../components/inputs/text_box';

import { CircleCheckIcon } from '../assets/icons';

const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const innerHeight = window.innerHeight || document.documentElement.clientHeight;
  const innerWidth = window.innerWidth || document.documentElement.clientWidth;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) && ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

const loadTooltips = () => {
  setTimeout(() => {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }, 0);
};

const OrderbyArrow = ({ direction }) => {
  if (direction === 'asc') {
    return <span className="arrow asc" />;
  } else if (direction === 'desc') {
    return <span className="arrow dsc" />;
  } else {
    return null;
  }
};

const IteroSelection = ({
  fetchedIteroScans,
  caseId,
  disabledEdit,
  history,
  isFetchIteroScansPending,
  licenseNumbers,
  onSelectedIteroIdChange,
  onSearchIteroScansQueryChange,
  onShowAlreadyUploadedScansChange,
  onShouldFetchIteroPhotosChange,
  searchIteroScansQuery,
  selectedIteroId,
  showAlreadyUploadedScans,
  isLoadingIteroPhotos,
  isDeletingIteroPhotos,
  isBuildingIteroScansRecords,
  shouldFetchIteroPhotos,
  handleLoadMoreIteroScans,
  handleIteroFileSearch,
}) => {
  const [scrollObserverTarget, setScrollObserverTarget] = React.useState(null);
  const scrollObserverRef = React.useCallback((node) => {
    setScrollObserverTarget(node);
  });
  const { doctor_program_enrollment } = React.useContext(UserPermissionsContext);
  const [groupedFiles, setGroupedFiles] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('datetime');
  const [orderDirection, setOrderDirection] = React.useState('desc');
  const [isFetchingInitialData, setIsFetchingInitialData] = React.useState(true);
  const listRef = React.useRef(null);

  const isSearchDisabled = disabledEdit || isLoadingIteroPhotos || isFetchingInitialData;
  const isSortDisabled = disabledEdit;
  const isFileSelectionDisabled = disabledEdit;
  const isShowAlreadyUploadedScansDisabled = disabledEdit || isLoadingIteroPhotos || isFetchingInitialData;
  const isLoadIteroPhotosDisabled = disabledEdit || isLoadingIteroPhotos || isFetchingInitialData;

  const isEnrolledToiTeroPhotosIntegration = doctor_program_enrollment.includes('itero_photos_integration');

  const isLastFile = (index) => {
    return index === groupedFiles.length - 1;
  };

  const getFilesIdWithoutCurrentCaseId = (files) => {
    return [
      ...new Set(
        files
          .map((file) => file.cases)
          .flat()
          .filter((_caseId) => {
            return _caseId !== caseId;
          })
      ),
    ];
  };

  const areFilesUsedInOtherCase = (files) => {
    const filteredFilesCaseIds = getFilesIdWithoutCurrentCaseId(files);
    return filteredFilesCaseIds.length > 0;
  };

  const isGroupFilesSelected = (fileGroup) => {
    return !!selectedIteroId && fileGroup.iteroId === selectedIteroId;
  };

  const getListItemClassName = (index) => {
    const classNameArr = ['list__item'];

    if (!isLastFile(index)) {
      classNameArr.push('border-bottom');
    }

    return classNameArr.join(' ');
  };

  const getGroupUsedTooltipTitle = (files) => {
    let title = 'Records used for Case ID';

    const cases = getFilesIdWithoutCurrentCaseId(files).map(removeCaseIdInitialNumber);

    if (cases.length > 1) {
      title += 's: ';
    } else {
      title += ': ';
    }

    title += cases.join(', ');

    return title;
  };

  const getUnusedFiles = (fileGroups) => {
    return fileGroups.filter((fileGroup) => {
      return isGroupFilesSelected(fileGroup) || !areFilesUsedInOtherCase(fileGroup.files);
    });
  };

  const getGroupFilesIteroIds = React.useCallback(() => {
    return groupedFiles.map((fileGroup) => fileGroup.iteroId);
  }, [groupedFiles]);

  const updateFileList = () => {
    let fileGroups = Object.entries(groupIteroFilesByIteroId(fetchedIteroScans)).map(([, fileGroup]) => fileGroup);

    if (!showAlreadyUploadedScans) {
      fileGroups = getUnusedFiles(fileGroups);
    }

    if (orderBy === 'datetime') {
      fileGroups.sort((a, b) => {
        const dateA = new Date(a.datetime);
        const dateB = new Date(b.datetime);

        if (orderDirection === 'desc') {
          return dateB - dateA;
        } else {
          return dateA - dateB;
        }
      });
    } else if (orderBy === 'patientName') {
      fileGroups.sort((a, b) => {
        if (orderDirection === 'desc') {
          return b.patientName.localeCompare(a.patientName);
        } else {
          return a.patientName.localeCompare(b.patientName);
        }
      });
    }

    setGroupedFiles(fileGroups);
    loadTooltips();
  };

  const handleChangeSearchField = (e) => {
    onSearchIteroScansQueryChange(e.target.value);
    if (!e.target.value) {
      handleIteroFileSearch();
    }
  };
  const handleSearchFieldButtonClick = () => handleIteroFileSearch(searchIteroScansQuery);
  const handleSearchFieldKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleIteroFileSearch(searchIteroScansQuery);
    }
  };

  const handleShowUsedChange = (e) => {
    const showUsedValue = e.target.checked;
    onShowAlreadyUploadedScansChange(showUsedValue);
  };

  const handleShouldFetchIteroPhotosChange = (e) => {
    const shouldFetchIteroPhotosValue = e.target.checked;
    onShouldFetchIteroPhotosChange(shouldFetchIteroPhotosValue);
  };

  const handleItemChange = (e) => {
    const iteroId = e.target.value;
    onSelectedIteroIdChange(iteroId);
  };

  const getCurrentPatientName = () => {
    const selectedGroup = groupedFiles.find((group) => isGroupFilesSelected(group));
    if (selectedGroup) {
      return `${selectedGroup.patientFirstName} ${selectedGroup.patientLastName}`;
    }
    return '';
  };

  const getHeaderClickHandler = (clickedHeader) => () => {
    if (isSortDisabled) {
      return;
    }
    if (orderBy === clickedHeader) {
      setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderBy(clickedHeader);
    }
  };

  const getIteroSettingsUrl = () => {
    const doctorId = getDoctorIdFromPath(history.location.pathname);
    return `/portal/${doctorId}/account/itero`;
  };

  React.useEffect(() => {
    if (selectedIteroId && groupedFiles.length) {
      const groupedFilesIteroIds = getGroupFilesIteroIds();
      if (groupedFilesIteroIds.includes(selectedIteroId)) {
        const selectedGroupElement = document.querySelector('[data-selected-group="true"]');
        if (selectedGroupElement && listRef.current && !elementIsVisibleInViewport(selectedGroupElement)) {
          // make selected checkbox visible in viewport within list element
          listRef.current.scrollTop = selectedGroupElement.offsetTop - listRef.current.offsetTop;
        }
      }
    }
  }, [selectedIteroId, getGroupFilesIteroIds, listRef.current, isLoadingIteroPhotos, isFetchingInitialData]);

  React.useEffect(() => {
    if (fetchedIteroScans) {
      updateFileList();
    }
  }, [fetchedIteroScans]);

  React.useEffect(() => {
    if (isFetchingInitialData && !isFetchIteroScansPending) {
      setIsFetchingInitialData(false);
    }
  }, [isFetchIteroScansPending]);

  React.useEffect(() => {
    updateFileList();
  }, [orderBy, orderDirection, showAlreadyUploadedScans]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleLoadMoreIteroScans();
        }
      },
      { threshold: 1 }
    );

    if (scrollObserverTarget) {
      observer.observe(scrollObserverTarget);
    }

    return () => {
      if (scrollObserverTarget) {
        observer.unobserve(scrollObserverTarget);
      }
    };
  }, [scrollObserverTarget]);

  return (
    <div className="itero-files-selection">
      {licenseNumbers.length ? (
        <>
          <span className="title">iTero Scanner</span>
          <AlertContainer className={'itero-files-list-alert'}>
            Please allow up to 10 minutes for scans to be uploaded from the InBrace Lab to the portal
          </AlertContainer>

          <div className="search">
            <TextBox
              placeholder="Search by Patient Name or iTero ID"
              onChange={handleChangeSearchField}
              onKeyPress={handleSearchFieldKeyPress}
              value={searchIteroScansQuery}
              icon={'fa fa-search'}
              onIconClick={handleSearchFieldButtonClick}
              disabled={isSearchDisabled}
            ></TextBox>
          </div>

          {isEnrolledToiTeroPhotosIntegration ? (
            <div className="load-itero-photos-field-container">
              <div className="ib-checkbox">
                <label className={'form-checkbox-label'}>
                  <input
                    className="form-checkbox-input"
                    type="checkbox"
                    name={'shouldFetchIteroPhotos'}
                    checked={shouldFetchIteroPhotos}
                    onChange={handleShouldFetchIteroPhotosChange}
                    disabled={isLoadIteroPhotosDisabled}
                  />{' '}
                  {'Upload iTero Photos'}
                </label>
              </div>
            </div>
          ) : null}

          <CardContainer type="top-border" className="list-card">
            {isFetchingInitialData ? (
              <div className="itero-loader-container">
                <CircleLoader />
              </div>
            ) : isDeletingIteroPhotos ? (
              <div className="itero-loader-container">
                <CircleLoader />
                <span className="loader__text">Removing current files</span>
              </div>
            ) : isLoadingIteroPhotos || isBuildingIteroScansRecords ? (
              <div className="itero-loader-container">
                <CircleLoader />
                <span className="loader__text">{`Loading Files${getCurrentPatientName() ? ' for ' + getCurrentPatientName() : ''}`}</span>
              </div>
            ) : (
              <div ref={listRef} className="list">
                {groupedFiles.length ? (
                  <>
                    <div className="list__header border-bottom">
                      <div className="header__left" onClick={getHeaderClickHandler('patientName')}>
                        Patient Name (iTero ID) {orderBy === 'patientName' ? <OrderbyArrow direction={orderDirection} /> : null}
                      </div>
                      <div className="header__right" onClick={getHeaderClickHandler('datetime')}>
                        Date & Time {orderBy === 'datetime' ? <OrderbyArrow direction={orderDirection} /> : null}
                      </div>
                    </div>
                    {groupedFiles.map((group, index) => (
                      <div key={group.iteroId} className={getListItemClassName(index)} data-selected-group={isGroupFilesSelected(group)}>
                        <div className="item__left">
                          <div className="ib-radio">
                            <input
                              id={group.iteroId}
                              className="form-radio-input"
                              type="radio"
                              name={'selectedIteroScanIds'}
                              value={group.iteroId}
                              checked={isGroupFilesSelected(group)}
                              onChange={handleItemChange}
                              disabled={isFileSelectionDisabled}
                            />
                            <label className={'form-radio-label'} htmlFor={group.iteroId}>
                              <span className={'patient-name'}>
                                {group.patientFirstName} {group.patientLastName}
                              </span>
                              <span className={'itero-id'}>({group.iteroId})</span>
                            </label>
                          </div>
                          {areFilesUsedInOtherCase(group.files) ? (
                            <div className="checkmark" data-toggle="tooltip" data-placement="right" title={getGroupUsedTooltipTitle(group.files)}>
                              <CircleCheckIcon />
                            </div>
                          ) : null}
                        </div>
                        <div className="item__right">{group.datetime}</div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="list__item list__item--not-found">No Results Found</div>
                )}
                <div className={'list__load-more-indicator'} ref={scrollObserverRef}>
                  {isFetchIteroScansPending ? <CircleLoader /> : null}
                </div>
              </div>
            )}
          </CardContainer>

          <div className="show-used-field-container">
            <div className="ib-checkbox">
              <label className={'form-checkbox-label'}>
                <input
                  className="form-checkbox-input"
                  type="checkbox"
                  name={'uploadMethod'}
                  value={'itero'}
                  checked={showAlreadyUploadedScans}
                  onChange={handleShowUsedChange}
                  disabled={isShowAlreadyUploadedScansDisabled}
                />{' '}
                {'Show already uploaded scans'}
              </label>
            </div>
          </div>
        </>
      ) : (
        <div className="no-license-numbers-message">
          <span className="title">No iTero Scanner Connected</span>
          {!disabledEdit ? (
            <span>
              To integrate your iTero Scanner, click{' '}
              <a href={getIteroSettingsUrl()} target="_blank">
                here
              </a>
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isFetchIteroScansPending: getFetchIteroScansPending(state),
});

export default connect(mapStateToProps)(withRouter(IteroSelection));
