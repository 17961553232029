import Axios from 'axios';

//Action Imports
import { setWires, setUploadOption, setUploadFinished, setIfuFolder } from './ifu_upload';

// Action Types
export const FETCH_IFU_STATE_PENDING = 'FETCH_IFU_STATE_PENDING';
export const FETCH_IFU_STATE_SUCCESS = 'FETCH_IFU_STATE_SUCCESS';
export const FETCH_IFU_STATE_ERROR = 'FETCH_IFU_STATE_ERROR';


/**
 * Maps the IFU state from the response data.
 * @param {Object} res - The response object.
 * @param {string} case_id - The case ID.
 * @returns {Object} - The mapped IFU state object.
 */
export function mapIfuState(res, case_id) {
  const ifu_status = res.data.ifu_status;
  const ifu_status_comment = res.data.ifu_status_comment;
  const bpg_status = res.data.bpg_status;
  const bpg_status_comment = res.data.bpg_status_comment;
  const txinstruction_status = res.data.txinstruction_status;
  const txinstruction_status_comment = res.data.txinstruction_status_comment;
  const upload_file = res.data.upload_file;
  const txinstruction_file = res.data.txinstruction_file;
  const txinstruction_manual_file = res.data.txinstruction_manual_file;
  const bpg_file = res.data.bpg_file;
  const bpg_manual_file = res.data.bpg_manual_file;
  const arch_to_treat = res.data.arch_to_treat;
  const historical_vbpc_file = res.data.historical_vbpc_file;
  let screen = '';
  if ((!ifu_status && !bpg_status && !txinstruction_status) ||
  ifu_status === 'IFU Input File Uploaded' ||
  ifu_status === 'IFU Input File Removed' ||
  ifu_status === 'IFU Generation Reprocess') {
    screen = 'upload';
  } else if (bpg_status === 'BPG Generation Process' ||
  bpg_status === 'BPG Gen2 Generation Process' ||
  txinstruction_status === 'Tx Instruction Generation Process' ||
  txinstruction_status === 'Tx Instruction Gen2 Generation Process') {
    screen = 'in-progress';
  } else {
    screen = 'result';
  }
  return {
    case_id: case_id,
    ifu_screen: screen,
    ifu_status: ifu_status,
    ifu_status_comment: ifu_status_comment,
    bpg_status: bpg_status,
    bpg_status_comment: bpg_status_comment,
    txinstruction_status: txinstruction_status,
    txinstruction_status_comment: txinstruction_status_comment,
    error: null,
    upload_file: upload_file,
    txinstruction_file: txinstruction_file,
    txinstruction_manual_file: txinstruction_manual_file,
    bpg_file: bpg_file,
    bpg_manual_file: bpg_manual_file,
    arch_to_treat: arch_to_treat,
    historical_vbpc_file: historical_vbpc_file,
  };
}

/**
 * Retrieves the IFU state for a given case ID.
 * @param {string} case_id - The ID of the case.
 * @returns {Promise<Array>} - A promise that resolves to an array containing the mapped IFU state and the response object.
 */
export async function getIfu(case_id) {
  const res = await Axios.get(`/apiV2/cadaction/${case_id}/ifu/get_ifu_state`);
  return [mapIfuState(res, case_id), res];
}

// Action Creators
// -----------------------------------------------------------------------------
// FETCH
export function fetchIfuState(case_id) {
  return async (dispatch) => {
    dispatch(fetchIfuStatePending());

    try {
      const [ifu_state, res] = await getIfu(case_id);
      if (ifu_state.upload_file) {
        dispatch(setUploadFinished());
        if (ifu_state.upload_file['file_type'] === 'digi_bp') {
          dispatch(setIfuFolder('digi_bp'));
        }
      }
      dispatch(fetchIfuStateSuccess(ifu_state));
      if (case_id.indexOf('-DE') !== -1 || case_id.indexOf('-R') !== -1) {
        dispatch(setWires(ifu_state.ifu_status_comment, ifu_state.arch_to_treat));
        dispatch(setUploadOption(ifu_state.ifu_status_comment));
      }
      return res;
    } catch (error) {
      dispatch(fetchIfuStateError(error));
    }
  };
}

export function fetchIfuStatePending() {
  return {
    type: FETCH_IFU_STATE_PENDING,
  };
}

export function fetchIfuStateSuccess(ifu_state) {
  return {
    type: FETCH_IFU_STATE_SUCCESS,
    ifu_state: ifu_state,
  };
}

export function fetchIfuStateError(error) {
  return {
    type: FETCH_IFU_STATE_ERROR,
    error: error,
  };
}
