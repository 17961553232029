import React from 'react';
import Badge from '../components/badge';
import FilterContainer from '../components/container/filter_container';
import FilterItem from '../components/filter_item';

/**
 * A component use to display the dropdown and actionable sections
 * @component
 * @alias DoctorCaseListFilter
 * @category IPP
 */
function CaseListFilter(props) {
  const { filter, statuses, onFilterClick, is_dso } = props;
  const subStatuses = {
    hide: false,
    options: [
      { label: 'Action Required', value: 'action', theme: 'primary', type: 'subfilter' },
      { label: 'Review Smile Design', value: 'setup', theme: 'secondary' },
      { label: 'Upload New Records', value: 'new', theme: 'secondary' },
      { label: 'Pending Clarification', value: 'clarification', theme: 'secondary' },
      { label: 'On Hold', value: 'hold', theme: 'secondary' },
      { label: 'Draft', value: 'draft', theme: 'secondary' },
    ],
  };
  const status = [
    { hide: is_dso, options: [{ label: 'My Cases', value: '' }] },
    subStatuses,
    { hide: false, options: [{ label: 'Active', value: 'active' }] },
    { hide: false, options: [{ label: 'Shipped', value: 'ship' }] },
    { hide: false, options: [{ label: 'Cancelled', value: 'cancel' }] },
  ];
  return (
    <>
      {status.map((filter_block, index) =>
        !filter_block.hide ? (
          <FilterContainer key={index} className="hide-on-mobile">
            {filter_block.options.map((action) => (
              <FilterItem key={action.value} active={filter === action.value} onClick={() => onFilterClick(action.value)} {...action}>
                {action.label}
                <Badge theme={action.theme}>{statuses ? statuses[action.label === 'My Cases' ? 'all' : action.value] : 0}</Badge>
              </FilterItem>
            ))}
          </FilterContainer>
        ) : null
      )}
    </>
  );
}

export default CaseListFilter;
