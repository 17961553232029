import {
  defaultTableHeader,
  PostApprovalTableHeader,
  ProviderEditReviewHeader,
  smileDesignTableHeader,
  applianceDesignTableHeader,
  qcApprovalTableHeader,
  fabricationTableHeader,
  wsHeader,
  wsReviewHeader,
  wsWoHeader,
  wireCuttingHeader,
  wfReviewHeader,
  labelsHeader,
  doctorActionTableHeader,
  doctorNewRecordTableHeader,
  doctorApprovalTableHeader,
  expediteTableHeader,
  lateTableHeader,
} from './case_list_table_header_data';

/**
 * Get the index for by title
 * @function
 * @param {Object} columns - List of column properties
 * @param {String} title - Title it is looking for
 * @returns {Int} Returns the index if nothing found defaults to -1
 */
export function getColumnIndexByTitle(columns, title) {
  for (let i = 0; i < columns.length; i++) {
    if (columns[i] && columns[i].title === title) {
      return i;
    }
  }

  return -1;
}

/**
 * Get the table header list base on filter
 * @function
 * @param {String} filter - The current filter
 * @returns {List} The table header list
 */
export function getCaseListTableHeader(filter) {
  switch (filter) {
    case 'segmentation_process':
    case 'checking':
    case 'prepare_new':
    case 'prepare_correction':
    case 'setup_review':
    case 'revise_setup':
    case 'setup_ready_upload':
    case 'setup_conversion':
    case 'setup_conversion_failed':
    case 'setup_ready_for_release':
      return smileDesignTableHeader;
    case 'final_design':
    case 'provider_edit':
      return ProviderEditReviewHeader;
    case 'final_design_upload':
    case 'final_design_conversion':
    case 'final_design_conversion_failed':
    case 'final_design_ready_for_release':
      return PostApprovalTableHeader;
    case 'cad_process':
    case 'cad_stage_1':
    case 'cad_qc_stage_1':
    case 'cad_stage_2':
    case 'cad_stage_3':
    case 'cad_revision':
    case 'cad_stage_1_revision':
    case 'cad_qc_stage_1_revision':
    case 'cad_stage_2_revision':
    case 'cad_stage_3_revision':
      return applianceDesignTableHeader;

    case 'qc_approval':
      return qcApprovalTableHeader;

    case 'manufacture':
      return fabricationTableHeader;
    case 'ws_guide':
    case 'ws_review':
      return wsReviewHeader;
    case 'ws_generation':
    case 'ws_failed':
      return wsHeader;
    case 'ws_wo':
      return wsWoHeader;
    case 'ws_wire_cutting':
    case 'ws_wire_pickup':
      return wireCuttingHeader;
    case 'wf_review':
      return wfReviewHeader;
    case 'ws_labels':
      return labelsHeader;

    case 'doctor_action':
      return doctorActionTableHeader;
    case 'new':
      return doctorNewRecordTableHeader;
    case 'setup':
      return doctorApprovalTableHeader;

    case 'pendingexp':
    case 'activeexp':
      return expediteTableHeader;

    case 'late':
      return lateTableHeader;

    default:
      return defaultTableHeader;
  }
}
