export const BETA = 'beta';
export const PILOT = 'pilot';
export const FULL_RELEASE = 'full release';

export const PROGRAM_LEVELS = {
  BETA,
  PILOT,
  FULL_RELEASE,
};

/**
 * Frontend Constants for different programs.
 *
 * Should be kept in sync with the database.
 * table: db_program
 */
export const PROGRAMS_FRONTEND_CONSTANTS = {
  'dx/tx_plan_3.0': { program_id: 'dx/tx_plan_3.0' },
  itero_integration: { program_id: 'itero_integration' },
  loki_automated_w_loops: { program_id: 'loki_automated_w_loops' },
  itero_photos_integration: { program_id: 'itero_photos_integration' },
  provprogram_ider_edit: { program_id: 'provprogram_ider_edit' },
  pusher_loops: { program_id: 'pusher_loops' },
  universal_template_wires: { program_id: 'universal_template_wires' },
  smile_design_preferences: { program_id: 'smile_design_preferences' },
  epp: { program_id: 'epp' },
  inbrace_ifs: { program_id: 'inbrace_ifs' },
  provider_edit_phase_2: { program_id: 'provider_edit_phase_2' },
};
