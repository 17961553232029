import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './styles.scss';
import { ENotificationType } from '../signals/notifications';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import { NotificationErrorIcon } from '../icons/notification-error-icon';

const icons = {
  [ENotificationType.Error]: NotificationErrorIcon,
  [ENotificationType.Warning]: NotificationErrorIcon,
};

const DISAPPEAR_TIMEOUT_MS = 300;

export const NotificationItem = ({ type, children, className, onDismiss, visibleTimeMs }) => {
  const [isDisappearing, setIsDisappearing] = useState(false);
  const isError = type === ENotificationType.Error;
  const Icon = icons[type];

  const dismiss = () => {
    setIsDisappearing(true);
    setTimeout(onDismiss, DISAPPEAR_TIMEOUT_MS);
  };

  useEffect(() => {
    const timeout = visibleTimeMs >= 0 ? setTimeout(dismiss, visibleTimeMs - DISAPPEAR_TIMEOUT_MS) : null;
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      className={cn('notification', type, className, { isDisappearing })}
      style={{ transitionDuration: `${DISAPPEAR_TIMEOUT_MS / 1000}s` }}
    >
      <HideContentIf condition={!Icon}>
        <div className="icon">
          <NotificationErrorIcon />
        </div>
      </HideContentIf>

      {children}

      <HideContentIf condition={!isError}>
        <div onClick={onDismiss} className="dismiss-btn">
          Dismiss
        </div>
      </HideContentIf>
    </div>
  );
};

NotificationItem.propTypes = {
  children: PropTypes.node,
  visibleTimeMs: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ENotificationType)),
  onDismiss: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {
  visibleTimeMs: -1,
};
