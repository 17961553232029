// External Libs
import React, { Component } from 'react';

// Internal Libs
import Modal from '../../../components/modal/modal';
import { removeIreqIdInitialNumber } from '../../../common/functions';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
// import WorkOrderRef from '../../../components/workorder_reference/work_order_reference';
class CadLabelGeneration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessModal: false,
      showFailedModal: false,
    };
  }

  toggleSuccessModal = () => {
    this.setState({ showSuccessModal: !this.state.showSuccessModal });
  };

  toggleFailedModal = () => {
    this.setState({ showFailedModal: !this.state.showFailedModal });
  };

  SuccessModal = () => {
    const header_text = `Mark as Success -  ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the label generation process to succeed?"
        confirm_btn_text="Mark as Success"
        onConfirmButtonClick={this.props.markGenerationAsSuccess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleSuccessModal}
      />
    );
  };

  FailedModal = () => {
    const header_text = `Mark as Failed -  ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the label generation process to fail?"
        confirm_btn_text="Mark as Failed"
        onConfirmButtonClick={this.props.markGenerationAsFailed}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleFailedModal}
      />
    );
  };

  toggleSuccessModal = () => {
    this.setState({ showSuccessModal: !this.state.showSuccessModal });
  };

  toggleFailedModal = () => {
    this.setState({ showFailedModal: !this.state.showFailedModal });
  };

  render() {
    return (
      <React.Fragment>
        {/* <WorkOrderRef label={'WO Ref:'} placeholder={'WO1234567'} ref_no={this.props.ref_no} update_wo={this.props.update_wo} permission="IREQ_PROD_WO_EDIT" /> */}
        <div className="ir process-box-intro">
          <div className="ir process-box-title center-text bold-text">Label Generation</div>
          <div className="center-text">The selected labels are currently being generated...</div>
        </div>
        <div className="extra-padding-headline-bpp" />
        <div className="process_tracker center-text">
          <i className="fa fa-refresh font-size-xlg rotate" aria-hidden="true" />
        </div>
        <div className="extra-padding-headline-bpp" />
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('IREQ_LABEL_MARK_BYPASS', user_roles_and_permissions.permissions) ? (
              <div className="button-panel center-text">
                <button type="button" className="btn btn-light" onClick={this.toggleSuccessModal}>
                  Mark as Success
                </button>

                <button type="button" className="btn btn-light" onClick={this.toggleFailedModal}>
                  Mark as Failed
                </button>
              </div>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>

        {this.state.showSuccessModal ? this.SuccessModal() : null}
        {this.state.showFailedModal ? this.FailedModal() : null}
      </React.Fragment>
    );
  }
}

export default CadLabelGeneration;
