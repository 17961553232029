import React from 'react';
import Button from '../extras/button';

/**
 * Contains the approval dialog for the setup viewer
 * @component
 * @alias SetupViewerApprovalConfirmation
 */
export default function ApprovalConfirmation(props) {
  return (
    <div id="approval-confirmation" className="approval-confirmation-container">
      <div className="modal-title">Approve Smile Design</div>
      <div className="modal-body-paragraph">
        InBrace Case Approval represents your final and complete prescription order to Swift Health Systems, Inc. (Swift). As the treating physician, diagnosis,
        prescription, ordering and administering this appliance is your sole responsibility, and you waive any and all claims against Swift and Employees of
        Swift based on the failure of InBrace to achieve a desired outcome, either alone or in combination with other appliances. All treatments submitted to
        Swift are governed by the details in effect at the time of each treatment submission and listed in the InBrace{' '}
        <a className="midnight" href="/portal/agreement" target="_blank" rel="noopener noreferrer">
          Customer Agreement
        </a>
        . Swift reserves the right to refuse any prescription. By clicking 'Approve', I confirm I have read and understand the InBrace Customer Agreement.
      </div>
      <div className="modal-button-group">
        <Button theme="success" onClick={props.confirm} disabled={props.disabled}>
          Approve
        </Button>
        <Button theme="secondary" onClick={props.cancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
