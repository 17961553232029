import React from 'react';

/**
 * Displays Single-Tooth IDB Tray generation overridden component
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - JSX for component
 */
function SingleToothIdbOverride(props) {
  return <div className={!props.id?.includes('-IR') ? 'single-tooth-idb__override' : ''}>Single-Tooth IDB Tray generation has been overridden...</div>;
}

export default SingleToothIdbOverride;
