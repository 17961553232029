/**
 * File:quantity.js - Component to input item request submission parts on BPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';

//Internal Functions
import { removeCaseIdInitialNumber } from '../../common/functions';

// nomenclature
import { getLookup } from '../../common/nomenclature';

class ItemQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const ITEM_PART_LOOKUP = getLookup(this.props.case_id, true); // nomenclature lookup
    const isDE = this.props.case_id && (this.props.case_id.indexOf('-DE') >= 0 || this.props.case_id.indexOf('-R') >= 0);
    let cases = [];
    this.props.casedetails.cases.forEach((c) => {
      if (c.status_code === 'STATUS_SHIP') {
        cases.push(c);
      }
    });

    return (
      <div>
        {this.props.item_parts_quantity && this.props.item_parts_quantity.length > 0 ? (
          <div className="bpp-portal-view">
            <div className="ir-submission-heading">Item Request Form - Request Items (Step 1 of 3)</div>
            <div className="bold-text ir-case-id-label">Case ID:</div>
            <div className="ir-case-id">
              <select
                className={this.props.mode === 'update' ? 'bpp-ir-select-caseid-disbaled' : 'bpp-ir-select-caseid'}
                value={this.props.case_id}
                disabled={this.props.mode === 'update' ? true : false}
                onChange={this.props.onCaseIdChange}
              >
                {cases.map((c, index) => {
                  return (
                    <option key={index} value={c.case_id}>
                      {removeCaseIdInitialNumber(c.case_id)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="main-headline extra-padding-headline bold-text">Please select the items you would like to request:</div>
            <div className="bpp-irsubmission-heading bpp-heading-part-no">Part No.</div>
            <div className="bpp-irsubmission-heading bpp-heading-part">Smartwires</div>
            <div className="bpp-irsubmission-heading bpp-header-qty text-center">Quantity</div>
            <div className="bpp-heading-divider" />
            <div className="item-section noselect">
              {this.props.item_parts_quantity
                .filter((ip) => {
                  return !this.props.gen_2 || (this.props.case_id.includes('-DE') ? ip.is_de : !ip.is_de);
                })
                .map((item_part, idx) =>
                  item_part.current_ind || item_part.quantity_previous > 0 ? (
                    <div key={item_part.id}>
                      {item_part.part_category === 'smartwire' ? (
                        <span>
                          <div className="bpp-item-container" key={idx}>
                            <span className="heading-item bpp-heading-part-no" data-id={item_part.id}>
                              {item_part.part_id}
                            </span>
                            <span className="heading-item bpp-heading-part" data-id={item_part.id}>
                              {ITEM_PART_LOOKUP[item_part.part_id]}
                            </span>
                            <span className="heading-item bpp-heading-qty">
                              <span
                                className={item_part.quantity === 0 ? 'icon-disabled' : 'bpp-minus-icon'}
                                data-id={item_part.id}
                                onClick={this.props.decreaseQuantity}
                              >
                                -
                              </span>
                              <span className="heading-quantity">{item_part.quantity}</span>
                              <span
                                className={item_part.quantity >= item_part.max_qty ? 'icon-disabled' : 'bpp-plus-icon'}
                                onClick={this.props.increaseQuantity}
                                data-id={item_part.id}
                              >
                                +
                              </span>
                            </span>
                          </div>
                          {item_part.quantity >= item_part.max_qty ? (
                            <div className="bpp-item-max-qty">
                              <span>Max Quantity Reached</span>
                            </div>
                          ) : null}
                        </span>
                      ) : null}
                    </div>
                  ) : null
                )}
            </div>
            <div className="item-section noselect">
              <div className="bpp-irsubmission-heading bpp-heading-part-no">Part No.</div>
              <div className="bpp-irsubmission-heading bpp-heading-part">{isDE || !this.props.gen_2 ? 'IDB Trays' : 'Initial IDB Trays'}</div>
              <div className="bpp-irsubmission-heading bpp-header-qty text-center">Quantity</div>
              <div className="bpp-heading-divider" />
              {this.props.item_parts_quantity
                .filter((ip) => {
                  return !this.props.gen_2 || (this.props.case_id.includes('-DE') ? ip.is_de : !ip.is_de);
                })
                .map((item_part, idx) =>
                  item_part.current_ind ? (
                    <div key={item_part.id}>
                      {item_part.part_category === 'idb' && !item_part.description.includes('Single-Tooth') ? (
                        <span>
                          <div className="bpp-item-container" key={idx}>
                            <span className="heading-item bpp-heading-part-no" data-id={item_part.id}>
                              {!this.props.gen_2 && item_part.part_id}
                            </span>
                            <span className="heading-item bpp-heading-part" data-id={item_part.id}>
                              {ITEM_PART_LOOKUP[item_part.part_id]}
                            </span>
                            <span className="heading-item bpp-heading-qty">
                              <span
                                className={item_part.quantity === 0 ? 'icon-disabled' : 'bpp-minus-icon'}
                                data-id={item_part.id}
                                onClick={this.props.decreaseQuantity}
                              >
                                -
                              </span>
                              <span className="heading-quantity">{item_part.quantity}</span>
                              <span
                                className={item_part.quantity >= item_part.max_qty ? 'icon-disabled' : 'bpp-plus-icon'}
                                onClick={this.props.increaseQuantity}
                                data-id={item_part.id}
                              >
                                +
                              </span>
                            </span>
                            {/* </div> */}
                          </div>
                          {item_part.quantity >= item_part.max_qty ? (
                            <div className="bpp-item-max-qty">
                              <span>Max Quantity Reached</span>
                            </div>
                          ) : null}
                        </span>
                      ) : null}
                    </div>
                  ) : null
                )}
            </div>
            {!this.props.gen_2 && (
              <div>
                <div className="bpp-irsubmission-heading bpp-heading-part-no">Part No.</div>
                <div className="bpp-irsubmission-heading bpp-heading-part">Brackets</div>
                <div className="bpp-irsubmission-heading bpp-header-qty text-center">Quantity</div>
                <div className="bpp-heading-divider" />
                {this.props.item_parts_quantity.map((item_part, idx) =>
                  item_part.current_ind ? (
                    <div className="noselect" key={item_part.id}>
                      {item_part.part_category === 'bracket' ? (
                        <span>
                          <div className="bpp-item-container" key={idx}>
                            <span className="heading-item bpp-heading-part-no" data-id={item_part.id}>
                              {item_part.part_id}
                            </span>
                            <span className="heading-item bpp-heading-part" data-id={item_part.id}>
                              {ITEM_PART_LOOKUP[item_part.part_id]}
                            </span>
                            <span className="heading-item bpp-heading-qty">
                              <span
                                className={item_part.quantity === 0 ? 'icon-disabled' : 'bpp-minus-icon'}
                                data-id={item_part.id}
                                onClick={this.props.decreaseQuantity}
                              >
                                -
                              </span>
                              <span className="heading-quantity">{item_part.quantity}</span>
                              <span
                                className={item_part.quantity >= item_part.max_qty ? 'icon-disabled' : 'bpp-plus-icon'}
                                onClick={this.props.increaseQuantity}
                                data-id={item_part.id}
                              >
                                +
                              </span>
                            </span>
                          </div>
                          {item_part.quantity >= item_part.max_qty ? (
                            <div className="bpp-item-max-qty">
                              <span>Max Quantity Reached</span>
                            </div>
                          ) : null}
                        </span>
                      ) : null}
                    </div>
                  ) : null
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="loader-container-2">
              <header className="bpp-loader-header">
                <p className="text-normal">
                  Sorry, an unexpected error occured. <br /> Please contact support at (877) 258-8677
                  <br /> or support@inbrace.com
                </p>
                <button className="btn btn-light" onClick={this.props.goBack}>
                  Go Back
                </button>
              </header>
            </div>
          </>
        )}
      </div>
    );
  }
}
export default ItemQuantity;
