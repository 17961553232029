import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cn from 'classnames';

import { getWasmSidebarState } from '../../../redux/selectors/wasm_viewer';
import { setSidebarActiveButton, expandSidebar } from '../../../redux/actions/wasm_viewer';
import WasmSidebarHistory from './wasm_sidebar_history';
import WasmSidebarInfo from './wasm_sidebar_info';
import WasmSidebarRecord from './wasm_sidebar_record';
import { sidebarButton } from './sidebar_constants';
import { WasmBolton } from '../components/wasm-bolton/wasm-bolton';

function WasmSidebar(props) {
  const {
    isCollapsed,
    expandSidebar,
    history_list,
    status,
    onOpenModal,
    onClickHistoryButton,
    onClickImageButton,
    activeTab,
    setActiveTab,
    onClickBoltonButton,
  } = props;

  const tabs = {
    [sidebarButton.history]: <WasmSidebarHistory history_list={history_list} status={status} onOpenModal={onOpenModal} />,
    [sidebarButton.images]: <WasmSidebarRecord />,
    [sidebarButton.bolton]: <WasmBolton />,
  };
  const tabComponent = tabs[activeTab] ?? null;

  return (
    <div className={cn('wasm-sidebar', { 'wasm-sidebar--collapse': isCollapsed })}>
      <WasmSidebarInfo
        collapse={isCollapsed}
        activeButton={isCollapsed ? '' : activeTab}
        onClickHistoryButton={() => {
          setActiveTab(sidebarButton.history);
          expandSidebar();
          if (typeof onClickHistoryButton === 'function') onClickHistoryButton();
        }}
        onClickImageButton={() => {
          setActiveTab(sidebarButton.images);
          expandSidebar();
          if (typeof onClickImageButton === 'function') onClickImageButton();
        }}
        onClickBoltonButton={() => {
          setActiveTab(sidebarButton.bolton);
          expandSidebar();
          if (typeof onClickBoltonButton === 'function') onClickBoltonButton();
        }}
      />
      {tabComponent}
    </div>
  );
}

const mapStateToProps = (state) => {
  const wasmSidebarState = getWasmSidebarState(state);
  return {
    activeTab: wasmSidebarState.activeButton,
    isCollapsed: wasmSidebarState.collapse,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setActiveTab: setSidebarActiveButton,
      expandSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmSidebar);
