import { ValidationPipe } from '../../../../common/validation/validation-utils';
import { movementTableFractionDigits } from '../constants';

const optionalDecimalRegex = new RegExp(`^\\d*[.,]?\\d{0,${movementTableFractionDigits}}$`);

const isZeroRegex = new RegExp(`^0[.,]?0{0,${movementTableFractionDigits}}$`);

/**
 * Allows both: empty, integer, decimal
 * @param {string} value
 * @returns {boolean}
 */
export function allowEmptyOrDecimal(value) {
  return optionalDecimalRegex.test(value);
}

/**
 * Checks if value is negative
 * @param {string} value
 * @returns {boolean}
 */
export function isValueNegative(value) {
  return `${value}`.startsWith('-');
}

/**
 * Checks if a value is zero.
 * @param {any} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is zero, otherwise returns false.
 */
export function isZero(value) {
  return isZeroRegex.test(value);
}

export const tmtInputOnChangeValidator = new ValidationPipe(allowEmptyOrDecimal);
export const incMovementInputOnChangeValidator = new ValidationPipe(allowEmptyOrDecimal);
