import Axios from 'axios';

export const FETCH_DOCTOR_CASE_LIST_TABLE_START = 'FETCH_DOCTOR_CASE_LIST_TABLE_START';
export const FETCH_DOCTOR_CASE_LIST_TABLE_SUCCESS = 'FETCH_DOCTOR_CASE_LIST_TABLE_SUCCESS';
export const FETCH_DOCTOR_CASE_LIST_TABLE_ERROR = 'FETCH_DOCTOR_CASE_LIST_TABLE_ERROR';
export const UPDATE_CASE_LIST_DATA = 'UPDATE_CASE_LIST_DATA';

export function fetchDoctorCaseListTable(config) {
  const { key, status, type, page, row_size, search, doctor_id, sort_asc, sort_method } = config;
  const params = {
    status: status,
    type: type,
    offset: (page - 1) * row_size,
    limit: page * row_size,
    search: search,
    sort: sort_method.join(','),
    order: sort_asc.join(','),
  };
  const urlParamsString = new URLSearchParams(params).toString();
  return (dispatch) => {
    dispatch(fetchDoctorCaseListTableStart());
    Axios.get(`/apiv3/doctorcaselist/${doctor_id}?${urlParamsString}`)
      .then((res) => dispatch(fetchDoctorCaseListTableSuccess(params, key, page, res.data)))
      .catch((err) => dispatch(fetchDoctorCaseListTableError(err)));
  };
}

function fetchDoctorCaseListTableStart() {
  return {
    type: FETCH_DOCTOR_CASE_LIST_TABLE_START,
  };
}

function fetchDoctorCaseListTableSuccess(params, key, page, result) {
  return {
    type: FETCH_DOCTOR_CASE_LIST_TABLE_SUCCESS,
    key: key,
    params: params,
    page: page,
    total: result.total,
    case_list_data: result.result,
  };
}

export function fetchDoctorCaseListTableError(error) {
  return {
    type: FETCH_DOCTOR_CASE_LIST_TABLE_ERROR,
    error: error,
  };
}

export function updateCaseListData(case_list_data) {
  return {
    type: UPDATE_CASE_LIST_DATA,
    case_list_data: case_list_data,
  };
}
