import React from 'react';
import PropTypes from 'prop-types';

import Backdrop from './backdrop';
import './modal.scss';

const themes = ['primary', 'danger'];

/**
 * Contains the modal for the setup viewer
 * @component
 * @alias SetupViewerModal
 */
function Modal(props) {
  const { theme, show, onCloseModal, show_close_button, children, className: propsClassName } = props;
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  return (
    <>
      <Backdrop show={show} />
      <div
        className={`setup-viewer-modal setup-viewer-modal--${themeClassName} ${propsClassName ? propsClassName : ''} scrollbar--dark`}
        style={{
          transform: show ? 'translate(-50%, -50%)' : 'translate(-50%, calc(-100vh - 50%))',
          opacity: show ? '1' : '0',
        }}
      >
        {show_close_button && <i className="fa fa-times" aria-label="Close" onClick={onCloseModal} />}
        {children}
      </div>
    </>
  );
}

Modal.propTypes = {
  theme: PropTypes.oneOf(themes),
  show: PropTypes.bool,
  show_close_button: PropTypes.bool,
  children: PropTypes.node,
  onCloseModal: PropTypes.func,
};

export default Modal;
