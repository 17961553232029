/**
 * File: knowledge_base.js - Displays the specific details of a case
 * Copyright: (c) Copyright January 2019 by InBrace
 * Authors: Eric Guenterberg, David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './knowledge_base.scss';
// External Libs
import Axios from 'axios';
import Autosuggest from 'react-autosuggest';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
// Internal Components
import CircleLoader from '../../components/loader/circle_loader';
import Modal from '../../components/modal/modal';
import CustomerAgreement from '../customer_agreement/customer_agreement';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader } from '../../common/functions';
import { INBRACE_LEARNING_URL } from '../../common/constants';
import CardContainer from '../components/container/card_container';
import TextBox from '../components/inputs/text_box';
import SelectionArea from '../components/selection_area';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchErrorMessage } from '../../common/search';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { PdfModalDialog } from '../components/modals/pdf-modal';
import PDFViewerInterface from '../../components/pdf-viewer/pdf_viewer';

class KBCategory extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  getAllSubCatCount = (cat) => {
    let count = 0;

    if (cat && cat.subcategories) {
      cat.subcategories.map((sub_cat) => {
        count = count + sub_cat.links.length;
        return '';
      });
    }

    return count;
  };

  render() {
    const is_selected = this.props.selected === this.props.id;
    const cat = this.props.data.find((cat) => cat.id === this.props.id);

    return (
      <SelectionArea theme={is_selected ? 'primary' : 'default'} onClick={this.props.onClick} id={this.props.id}>
        <p className="emphasis">{this.props.title}</p>
        {this.props.links.length || this.getAllSubCatCount(cat) ? <p>{`${this.props.links.length + this.getAllSubCatCount(cat)} Articles`}</p> : null}
      </SelectionArea>
    );
  }
}

class KBResults extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const cols = (12 / this.props.cols).toFixed(0).toString();
    const one_col = this.props.cols === 1;

    return (
      <>
        {one_col ? null : (
          <div className={'emphasis line-division--limited' + (this.props.issub ? ' ' : ' ')}>
            {this.props.links.length > 0 || !this.props.issub ? this.props.title : this.props.empty}
          </div>
        )}

        {one_col ? <span className="inbrace-text-primary border-sub">{this.props.title}</span> : <div />}

        {this.props.links && this.props.links.length === 0 && this.props.search
          ? searchErrorMessage(this.props.search, 'Articles', 'wrap-text')
          : this.props.links.map((item, index) => (
              <div key={index} className={one_col ? '' : 'description-detail col-md-' + cols}>
                <p className="subfont-subcat">
                  {item.type === 'html' ? (
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                  ) : (
                    <a href={'/' + this.props.prefix + item.link} onClick={this.props.onClick}>
                      <FontAwesomeIcon icon={['fas', `file-${item.type === 'zip' ? 'archive' : item.type}`]} className="icon--primary" />
                      {item.text}
                    </a>
                  )}
                </p>
              </div>
            ))}
      </>
    );
  }
}

class KBCategoryResults extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const results = this.props.data.find((cat) => cat.id === this.props.id);
    if (results == null) return <div />;
    const prefix = `portal/knowledgebase/${this.props.id}`;

    return (
      <div className="selection-area--body col-md-12">
        <KBResults prefix={prefix} title={results.title} links={results.links} cols="2" issub="" onClick={this.props.onClick} search={this.props.search} />
        {results.subcategories.map((subcat, index) =>
          subcat.links.length > 0 ? (
            <div className="sub-cat col-md-12" key={index}>
              <KBResults
                prefix={prefix}
                title={subcat.title}
                links={subcat.links}
                cols="2"
                issub="true"
                index={index}
                resultlength={results.links.length}
                drawline={index === 0 && results.links.length === 0 ? false : true}
                onClick={this.props.onClick}
              />
            </div>
          ) : null
        )}
      </div>
    );
  }
}

class KnowledgeBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      displaySuggestions: false,
      doctor_id: '',
      role: '',
      data: [],
      all_links: [],
      value: '',
      current_selection: '',
      highlighted_suggestions: '',
      suggestions: [],
      viewer_type: null,
      viewer_name: null,
      viewer_url: null,
      terms: false,
      additional_action: false,
    };
    this.change_links = this.change_links.bind(this);
    this.preserveHistory = this.preserveHistory.bind(this);

    this.getDoctorPortalRoute = this.getDoctorPortalRoute.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.onEndModal = this.onEndModal.bind(this);
    this.lookupLink = this.lookupLink.bind(this);
    this.showViewer = this.showViewer.bind(this);
    this.renderSuggestInput = this.renderSuggestInput.bind(this);
    this.executeSearch = this.executeSearch.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;

    Axios.get(`/apiV2/account`)
      .then(function (res) {
        const { css, cst, rae } = res.data;

        that.setState({
          doctor_id: res.data.account_id,
          role: res.data.role,
          ic: css,
          tis: cst,
          bsg: rae,
        });
      })
      .catch(function (err) {
        //ignore error for banner
      });

    Axios.get(`/apiV2/knowledgebase`)
      .then(function (res) {
        that.updateKnowledgeBaseState(res);
      })
      .then(function () {
        Axios.get(`/apiV2/agreement`)
          .then(function (res) {
            that.setState({
              loading: false,
            });
          })
          .catch(function (err) {
            that.setState({
              loading: false,
              terms: true,
            });
          });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * Updates the knowledgebase state
   * @function
   * @param {List} res - The knowledgebase list from api
   */
  updateKnowledgeBaseState(res) {
    const params = this.props.match.params;
    const category = decodeURIComponent(params.cat);
    const extra = params.extra;
    const extras = extra ? extra.split('/') : [];

    const data = res.data.slice();
    var all_links = [];
    data.map(function (cat) {
      all_links.push(...cat.links);
      cat.subcategories.map(function (scat) {
        all_links.push(...scat.links);
        return '';
      });
      return '';
    });
    const current_selection = category && category !== 'search' ? category : data[0].id;
    const search = category === 'search' && extras.length > 0;
    const value = search ? extras[0] : '';

    this.setState({
      data,
      all_links,
      value,
      current_selection,
    });
    if (search) {
      const suggestions = this.getSuggestions(extras[0]);
      this.setState({
        displaySuggestions: true,
        suggestions,
      });
    }
    var file = null;
    if (search && extras.length > 1) {
      file = extras.slice(1);
    } else if (!search && extras.length > 0) {
      file = extras.slice(0);
    }

    if (file) {
      file = decodeURIComponent('/' + file.join('/'));
      this.showViewer(file);
    }
  }

  getDoctorPortalRoute() {
    this.props.history.push(`/portal/${this.state.doctor_id}`);
  }

  change_links(e) {
    this.setState({ suggestions: [], displaySuggestions: false });
    let id = e.currentTarget.id;
    this.setState({ current_selection: id, value: '' });

    this.preserveHistory();
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.all_links.filter((link) => link.text.toLowerCase().includes(inputValue));
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {};

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      displaySuggestions: false,
    });
  };

  preserveHistory = () => {
    let that = this;
    let match = this.props.match;
    // let params = match.params;
    let path = match.path;
    let toPath = pathToRegexp.compile(path);

    setTimeout(function () {
      let state = that.state;
      let search = state.displaySuggestions && state.value;
      var extra = [];
      if (search) extra.push(state.value);
      if (state.viewer_url) {
        extra.push(...state.viewer_url.split('/').slice(1));
      }
      let newPath = toPath({
        // doctorid:params.doctorid,
        cat: search ? 'search' : state.current_selection,
        extra: extra,
      });
      that.props.history.replace(newPath, []);
    }, 0);
  };

  executeSearch = () => {
    this.setState({ displaySuggestions: true });
    this.preserveHistory();
  };
  /**
   * On Enter key, execute the updates on suggested list to be displayed
   *
   * @param {Object} event - The DOM event related to the key press
   */
  onKeyPress(event) {
    if (event.key === 'Enter') {
      const value = this.state.highlighted_suggestions !== this.state.value ? this.state.value : this.state.highlighted_suggestions;
      this.onSuggestionsFetchRequested({ value });
      this.executeSearch();
    }
  }

  lookupLink = (link) => {
    let links = this.state.all_links.filter((x) => x.link === link);
    if (links.length === 0) {
      return null;
    } else {
      return links[0];
    }
  };

  showViewer = (path) => {
    let link = this.lookupLink(path);

    if (!link) return;

    const full_path = encodeURIComponent(link.link);
    if (link.type === 'pdf' || link.type === 'video') {
      this.setState({ viewer_type: link.type, viewer_url: full_path, viewer_name: link.text });
    } else if (link.type === 'zip') {
      this.downloadKBFile(full_path);
    }
  };

  downloadKBFile(path) {
    Axios({
      url: `/apiv3/knowledgebase?file=${path}`,
      method: 'GET',
    }).then((response) => {
      const url = response.data;
      const win = window.open(url, '_blank');

      if (win) {
        win.focus();
        win.onblur = function () {
          win.close();
        };
      }
    });
  }

  /**
   * Takes the current href and goes through a list of lookup to pull up a pdf
   * or download zip and push to the browser state
   * @param {Object} event - DOM event object
   * @function
   */
  onLinkClick = (event) => {
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);

    if (startingPath >= 0) {
      let path = decodeURI(href.substring(startingPath, href.length));
      path = path.replace(/(\/[\w _&.]+){3}/m, '');
      this.showViewer(path);
      this.preserveHistory();
    }
  };

  onEndModal = (event) => {
    this.setState({ viewer_type: null, viewer_url: null, viewer_name: null });
    this.preserveHistory();
  };

  getSuggestionValue = (suggestion) => suggestion.text;

  renderSuggestion = (item) => (
    <div>
      <a className="subfont searchfont" href={this.getBaseRoute() + item.link} onClick={this.onLinkClick} rel="noopener noreferrer">
        <div className="description-detail">
          <FontAwesomeIcon icon={['fas', `file-${item.type === 'zip' ? 'archive' : item.type}`]} className="icon--primary" href={item.link} /> {item.text}
        </div>
      </a>{' '}
    </div>
  );

  renderSuggestInput = (inputProps) => (
    <TextBox
      placeholder="Search"
      onChange={this.onChangeSearchPatient}
      onKeyPress={this.onKeyPressSearchPatient}
      value={this.state.search_phrase}
      icon={'fa fa-search'}
      onIconClick={this.executeSearch}
      {...inputProps}
    ></TextBox>
  );

  getBaseRoute = () => {
    return window.location.pathname;
  };
  /**
   * Handle highlighted scenario to store the text value, it will be used when
   * the user hits the enter key
   * @param {Object} input - The API return of the AutoSuggest package
   */
  onSuggestionHighlighted = (input) => {
    if (input && input.suggestion && input.suggestion.text) {
      const highlighted_suggestions = input.suggestion.text;
      this.setState({ highlighted_suggestions });
    }
  };

  /**
   * Refresh the knowledge base cache
   * @function
   */
  refreshKnowledgeBase = () => {
    this.setState({ loading: true, additional_action: false });
    Axios.get(`/apiV2/knowledgebase?update=update`).then((res) => {
      this.updateKnowledgeBaseState(res);
      this.setState({ loading: false });
    });
  };

  /**
   * Toggles additional button dropdown menu
   * @function
   */
  toggleAdditionalActionButton = () => {
    this.setState((prevState) => ({
      additional_action: !prevState.additional_action,
    }));
  };

  /**
   * Checks if user has permission to use additional action
   * @function
   * @returns {Boolean} Whether has permission or not
   */
  hasAdditionalActionPermission(permissions) {
    const has_permission = userHasPermission('KNOWLEDGEBASE_REFRESH', permissions);
    return has_permission;
  }

  /**
   * Displays the additional action button
   * @function
   * @param {List} permissions - The user's list of permissions
   * @returns {JSX} The JSX for displaying the button
   */
  displayAdditionalActionButton(permissions) {
    const classname = this.state.additional_action ? 'navigator_dropdown_list' : 'navigator_dropdown_list_hide';
    if (this.hasAdditionalActionPermission(permissions)) {
      return (
        <div className="addtional_action_div">
          <button className="btn btn--primary additional_action_btn" onClick={this.toggleAdditionalActionButton}>
            Additional Actions <i className="fa fa-caret-down additional_action_icon" aria-hidden="true" />
          </button>

          <ul className={classname} onMouseLeave={this.toggleAdditionalActionButton}>
            <li onClick={this.refreshKnowledgeBase}>Refresh List</li>
          </ul>
        </div>
      );
    }
  }

  getPdfUrl() {
    const params = { file: this.state.viewer_url, type: 'pdf' };
    const baseUrl = '/apiv3/knowledgebase';
    const paramsString = new URLSearchParams(params).toString();
    return `${baseUrl}?${paramsString}`;
  }

  render() {
    if (this.state.terms) {
      return <CustomerAgreement />;
    } else if (this.state.loading) {
      return <CircleLoader fullscreen={true} />;
    } else {
      const { value, suggestions } = this.state;
      const inputProps = {
        placeholder: 'Search',
        value,
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
      };

      return (
        <>
          <Helmet>
            <title>Knowledge Base | InBrace Smile Design Studio™</title>
          </Helmet>
          <span className="case_detail_title_div">
            <h1 className="content__header">Knowledge Base</h1>
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => this.displayAdditionalActionButton(user_roles_and_permissions.permissions)}
            </UserPermissionsContext.Consumer>
          </span>
          <div className="list-content">
            <div className="statuses">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                renderInputComponent={this.renderSuggestInput}
                inputProps={inputProps}
              />

              {this.state.ic || this.state.role === 'Doctor' ? (
                <CardContainer className="pad-md hide-on-mobile tiny">
                  <h3>InBrace Concierge</h3>
                  <p>Contact me for assistance addressing any of your customer support needs.</p>
                  <p className="ellipsis">
                    <span className="emphasis">Phone</span>{' '}
                    <span>
                      <a href="tel:8772588677">877 258 8677</a>
                    </span>
                  </p>
                  <p className="ellipsis">
                    <span className="emphasis">Email</span>{' '}
                    <a className="mail" href="mailto:support@inbrace.com">
                      support@inbrace.com
                    </a>
                  </p>
                </CardContainer>
              ) : null}

              {this.state.bsg ? (
                <CardContainer className="pad-md hide-on-mobile tiny">
                  <h3>Business Growth Consultant</h3>
                  <p>Contact me for help growing your business with sales and marketing expertise.</p>
                  <p className="ellipsis">
                    <span className="emphasis">Contact</span>{' '}
                    <span className="">
                      {this.state.bsg.first_name} {this.state.bsg.last_name}
                    </span>
                  </p>
                  <p className="ellipsis">
                    <span className="emphasis">Phone</span>{' '}
                    <span>
                      <a href="tel:8772588677">877 258 8677</a>
                    </span>
                  </p>
                  <p className="ellipsis">
                    <span className="emphasis">Email</span>{' '}
                    <a className="mail" href={`mailto:${this.state.bsg.email}`}>
                      {this.state.bsg.email}
                    </a>
                  </p>
                </CardContainer>
              ) : null}

              {this.state.tis ? (
                <CardContainer className="pad-md hide-on-mobile tiny">
                  <h3>Technology Integration Specialist</h3>
                  <p>Contact me for help with clinical and technology integration of InBrace in your practice.</p>
                  <p className="ellipsis">
                    <span className="emphasis">Contact</span>{' '}
                    <span className="">
                      {this.state.tis.first_name} {this.state.tis.last_name}
                    </span>
                  </p>
                  <p className="ellipsis">
                    <span className="emphasis">Phone</span>{' '}
                    <span>
                      <a href="tel:8772588677">877 258 8677</a>
                    </span>
                  </p>
                  <p className="ellipsis">
                    <span className="emphasis">Email</span>{' '}
                    <a className="mail" href={`mailto:${this.state.tis.email}`}>
                      {this.state.tis.email}
                    </a>
                  </p>
                </CardContainer>
              ) : null}

              <CardContainer className="pad-md hide-on-mobile tiny">
                <h3>Links</h3>
                <div>
                  <a href={INBRACE_LEARNING_URL} className="mail">
                    InBrace Learning
                  </a>
                </div>
                <div>
                  <a href="https://get.inbrace.com/" className="mail">
                    InBrace Marketing
                  </a>
                </div>
              </CardContainer>
            </div>
            <div className="statuses-result-container">
              <div className="statuses-result">
                <CardContainer className="pad-md card--fullwidth statuses-col background--wires">
                  <div className="statuses-col-25">
                    <h3>Resources</h3>
                    {this.state.data
                      ? this.state.data.map((ibcat, index) => (
                          <KBCategory
                            id={ibcat.id}
                            key={index}
                            onClick={this.change_links}
                            selected={this.state.displaySuggestions ? '' : this.state.current_selection}
                            title={ibcat.title}
                            links={ibcat.links}
                            data={this.state.data}
                          />
                        ))
                      : null}
                  </div>
                  <div className="statuses-col-1 wall-vertical"></div>
                  <div className="statuses-col-70">
                    {this.state.displaySuggestions ? (
                      <KBResults
                        prefix={this.getBaseRoute()}
                        title="Search Results"
                        links={this.state.suggestions}
                        cols="2"
                        empty="No Results Found"
                        onClick={this.onLinkClick}
                        search={this.state.value}
                      />
                    ) : (
                      <KBCategoryResults id={this.state.current_selection} data={this.state.data} onClick={this.onLinkClick} search={this.state.search} />
                    )}
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>
          {this.state.viewer_type === 'pdf' ? (
            <PdfModalDialog show title={this.state.viewer_name} onClose={this.onEndModal}>
              <PDFViewerInterface fileUrl={this.getPdfUrl()} fileName={`${this.state.viewer_name.trim()}.pdf`} />
            </PdfModalDialog>
          ) : null}

          {this.state.viewer_type === 'video' ? (
            <Modal
              preset="video_viewer"
              header_text={this.state.viewer_name}
              modal_size_class="modal-lg"
              modal_body_class="modal-video"
              kb_video_url={this.state.viewer_url}
              onCloseButtonClick={this.onEndModal}
            />
          ) : null}
        </>
      );
    }
  }
}

export default withRouter(KnowledgeBase);
