import React, { useState, useEffect } from 'react';
// External Libs
import Axios from 'axios';

// Internal Functions
import SessionExpire from '../../../common/session_expire';
import { getArchToTreat, getBracketPositionings } from '../../../common/helpers';

// Internal Components
import CircleLoader from '../../../components/loader/circle_loader';

import StatusCard from './status_card';
import { v4 as uuidv4 } from 'uuid';

/**
 * Component displaying the Navigator status section
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for modal
 */
function TreatmentNavigator(props) {
  const [initialBond, setInitialBond] = useState('Incomplete');
  const [bracketPosition, setBracketPosition] = useState('Incomplete');
  const [iprCompleted, setIprCompleted] = useState('Incomplete');
  const [smartWireEngaged, setSmartWireEngaged] = useState('Incomplete');
  const [smartWireFullyExpressed, setSmartWireFullyExpressed] = useState('Incomplete');
  const [interArch, setInterArch] = useState('Incomplete');
  const [biteTurbo, setBiteTurbo] = useState('Incomplete');
  const [deBonding, setDeBonding] = useState('Incomplete');
  const [ipr, setIpr] = useState('');
  const [session_expired, setSessionExpired] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ifuBrackets, setIfuBrackets] = useState([]);
  const [navigator_data, setNavigator_data] = useState([]);
  const [status_list, setStatusList] = useState([
    [{ title: 'Initial Bonding', status: initialBond }],
    [
      { title: 'Optimal Bracket Positioning', status: bracketPosition },
      { title: 'IPR', status: iprCompleted, set: setInitialBond },
      { title: 'Completed Initial Leveling and Alignment', status: smartWireEngaged },
    ],
    [
      { title: 'Full Expression of Smartwires', status: smartWireFullyExpressed },
      { title: 'Inter-Arch Mechanics', status: interArch },
      { title: 'Bite Turbo Removal/Reduction', status: biteTurbo },
      { title: 'Debonding', status: deBonding },
    ],
  ]);
  const [status_list_de, setStatusListDe] = useState([
    { title: 'Optimal Bracket Positioning', status: bracketPosition },
    { title: 'IPR', status: iprCompleted, set: setInitialBond },
    { title: 'Full Expression of Smartwires', status: smartWireFullyExpressed },
    { title: 'Inter-Arch Mechanics', status: interArch },
    { title: 'Debonding', status: deBonding },
  ]);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      checkStatusCompletion();
    }
    //component will unmount
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line
  }, [initialBond]);

  /**
   * display InBrace Treatment Workflow Navigator
   * @function
   * @param {Object} card_data - Data to display status card
   * @param {Object} props - Props of the component
   * @return {JSX} returns Navigator jsx
   */
  function displayNavigation(card_data, props) {
    return card_data.map((cards) => (
      <div className="status_column" key={uuidv4()}>
        <h4 className="status_column_title" key={uuidv4()}>
          {cards.column_title}
          <span className="label-dropdown-popup" data-toggle="tooltip" title={cards.label_description}>
            <i className="fa fa-info-circle" />
          </span>
        </h4>

        {loading ? (
          <CircleLoader />
        ) : (
          <StatusCard
            items={cards.card}
            key={uuidv4()}
            cases={props.cases}
            checkStatusCompletion={checkStatusCompletion}
            ipr={ipr}
            getCompletionByName={getCompletionByName}
            has_edit_permission={props.has_edit_permission}
            ifu_brackets={ifuBrackets}
            navigator_data={navigator_data}
          />
        )}
      </div>
    ));
  }

  /**
   * Update bracket positioning completion status
   * @function
   * @param {Object} record - Navigator database record
   * @param {Object} props - Props of the component
   * @param {Object} ifu_brackets - Brackets selected from IFU process (not from Form 3420)
   *
   */
  function updateBracketPositioningStatus(record, props, ifu_brackets) {
    const brackets = getBracketPositionings(props.cases, false, ifu_brackets);

    if (brackets.length > 0 && record && record.bracket_positioning && brackets.sort().toString() === record.bracket_positioning.split(',').sort().toString()) {
      setBracketPosition('Complete');
    } else if (record && record.bracket_positioning && record.bracket_positioning.split(',').length > 0) {
      setBracketPosition('Partially Complete');
    } else {
      setBracketPosition('Incomplete');
    }
  }

  /**
   * Update smart wire engaged status
   * @function
   * @param {Object} record - Navigator database record
   * @param {Object} props - Props of the component
   *
   */
  function updateSmartwireEngagedStatus(record, props) {
    const treatment_arch = getArchToTreat(props);
    const smartwires_engaged = record && record.smartwires_engaged ? record.smartwires_engaged.split(',') : null;
    if (treatment_arch && smartwires_engaged) {
      if (treatment_arch === 'both' && smartwires_engaged.includes('Upper Smartwire 1') && smartwires_engaged.includes('Lower Smartwire 1')) {
        setSmartWireEngaged('Complete');
      } else if (treatment_arch === 'upper' && smartwires_engaged.length === 1 && smartwires_engaged.includes('Upper Smartwire 1')) {
        setSmartWireEngaged('Complete');
      } else if (treatment_arch === 'lower' && smartwires_engaged.length === 1 && smartwires_engaged.includes('Lower Smartwire 1')) {
        setSmartWireEngaged('Complete');
      } else if (treatment_arch === 'both' && smartwires_engaged.length === 1) {
        setSmartWireEngaged('Partially Complete');
      } else {
        setSmartWireEngaged('Incomplete');
      }
    } else {
      setSmartWireEngaged('Incomplete');
    }
  }

  /**
   * Update smart wire fully expressed status
   * @function
   * @param {String} record - Navigator database record
   * @param {Object} props - Props of the component
   *
   */
  function updateSmartWireFullyExpressedStatus(record, props) {
    const newStatus = getSmartWireFullyExpressedStatus(record, props);
    setSmartWireFullyExpressed(newStatus);
  }

  /**
   * Get smart wire fully expressed status
   * @function
   * @param {String} record - Navigator database record
   * @param {Object} props - Props of the component
   *
   */
  function getSmartWireFullyExpressedStatus(record, props) {
    const treatment_arch = getArchToTreat(props);
    const completed_smartWires = record && record.smartwires_fully_expressed && record.smartwires_fully_expressed.split(',');
    if (treatment_arch && completed_smartWires) {
      const require_wires = getNumberOfFullyExpressedArchWires(treatment_arch);
      if (completed_smartWires.length === require_wires) {
        return 'Complete';
      } else if (completed_smartWires.length !== 0) {
        return 'Partially Complete';
      }
    }
    return 'Incomplete';
  }

  /**
   * Get number of fully expressed arch wires
   * @function
   * @param {String} record - Navigator database record
   * @param {Object} props - Props of the component
   *
   */
  function getNumberOfFullyExpressedArchWires(treatment_arch) {
    if (props.cases.case_id.includes('-DE')) {
      let wire_selections =
        props.cases && props.cases.production_tx_guide && props.cases.production_tx_guide.wire_selections
          ? props.cases.production_tx_guide.wire_selections
          : [];
      wire_selections = wire_selections.filter(
        (wire) => !wire.includes('Smartwire 1') && (treatment_arch === 'both' || wire.toLowerCase().includes(treatment_arch))
      );
      return wire_selections.length;
    }
    if (treatment_arch === 'both') return 4;
    if (treatment_arch === 'upper' || treatment_arch === 'lower') return 2;
    return 0;
  }

  /**
   * Update Inter Arch Mechanics completion status
   * @function
   * @param {Object} record - Navigator database record
   *
   */
  function updateInterArchStatus(record) {
    if (record && record.inter_arch_mechanics === true) {
      setInterArch('Complete');
    } else {
      setInterArch('Incomplete');
    }
  }

  /**
   * Update Bite Turbo Removal/Reduction completion status
   * @function
   * @param {Object} record - Navigator database record
   *
   */
  function updateTurboRemovalStatus(record) {
    if (record && record.bite_turbo_removal === true) {
      setBiteTurbo('Complete');
    } else {
      setBiteTurbo('Incomplete');
    }
  }

  /**
   * Update Ipr completion status
   * @function
   * @param {Object} record - Navigator database record
   * @param {Object} reipr_datacord - Json formate ipr data
   *
   */
  function updateIprStatus(record, ipr_data) {
    let numOfIprRequired = -2;
    let numOfIprCompleted = -1;
    const has_ipr_data = ipr_data && ipr_data.end && ipr_data.end.man;
    const has_record = record && record.ipr && record.ipr.split(',').length;

    if (has_ipr_data) {
      numOfIprRequired = Object.keys(ipr_data.end.man).length + Object.keys(ipr_data.end.max).length;
    } else return;

    if (has_record) {
      numOfIprCompleted = record.ipr.split(',').length;
    }
    if (record && record.ipr === 'Incomplete') {
      setIprCompleted('Incomplete');
    } else if (record && record.ipr === 'Complete') {
      setIprCompleted('Complete');
    } else if (record && record.ipr === 'Partially Complete') {
      setIprCompleted('Partially Complete');
    }

    if (numOfIprCompleted <= 0) {
      setIprCompleted('Incomplete');
    } else if (numOfIprCompleted === numOfIprRequired) {
      setIprCompleted('Complete');
    } else if (numOfIprCompleted < numOfIprRequired && numOfIprCompleted > 0) {
      setIprCompleted('Partially Complete');
    }
  }

  /**
   * Update Bonding and Debonding completion status
   * @function
   * @param {Object} record - Navigator database record
   *
   */
  function bondingsStatus(record) {
    if (record && record.initial_bonding_date) {
      setInitialBond('Complete');
    } else {
      setInitialBond('Incomplete');
    }

    if (record && record.debonding_date) {
      setDeBonding('Complete');
    } else {
      setDeBonding('Incomplete');
    }
  }

  /**
   * Get card completion status by card name
   * @function
   * @param {Object} name - Card title
   * @return {String} String of card completion status
   */
  function getCompletionByName(name) {
    switch (name) {
      case 'Initial Bonding':
        return initialBond;
      case 'Optimal Bracket Positioning':
        return bracketPosition;
      case 'IPR':
        return iprCompleted;
      case 'Completed Initial Leveling and Alignment':
        return smartWireEngaged;
      case 'Full Expression of Smartwires':
        return smartWireFullyExpressed;
      case 'Inter-Arch Mechanics':
        return interArch;
      case 'Bite Turbo Removal/Reduction':
        return biteTurbo;
      case 'Debonding':
        return deBonding;

      default:
        break;
    }
  }

  /**
   * Check if a status are complete
   * @function
   * @return {Boolean} returns true if completed
   */
  function checkStatusCompletion() {
    Axios.get(`/apiv3/case/${props.cases.case_id}/navigator`)
      .then((res) => {
        setNavigator_data(res);
        const record = res.data.navigator_record;
        const ipr_data = res.data.ipr;
        setIfuBrackets(res.data.ifu_brackets);
        updateStatus(record, ipr_data, res.data.ifu_brackets);
      })
      .catch(function (err) {
        console.log(err);
        setSessionExpired(true);
      });
  }

  /**
   * Update modal status
   * @function
   * @param {Object} record - Object of navigator data
   * @param {Object} ipr_data - Object of ipr json data
   * @param {Object} ifu_brackets - Brackets selected from IFU process (not from Form 3420)
   */
  function updateStatus(record, ipr_data, ifu_brackets) {
    setIpr(ipr_data);
    bondingsStatus(record);
    updateBracketPositioningStatus(record, props, ifu_brackets);
    updateSmartwireEngagedStatus(record, props);
    updateSmartWireFullyExpressedStatus(record, props);
    updateInterArchStatus(record);
    updateTurboRemovalStatus(record);
    updateIprStatus(record, ipr_data);
    removeCardsList(ipr_data, props, ifu_brackets);
    setLoading(false);
  }
  /**
   * List of cards to be removed
   * @function
   * @param {Object} ipr_data - Object of ipr json data
   * @param {Object} props - props passed in
   * @param {Object} ifu_brackets - Brackets selected from IFU process (not from Form 3420)
   * @return {Array} List of name of cards to be removed
   */
  function removeCardsList(ipr_data, props, ifu_brackets) {
    let removal_list = [];

    if (!shouldDisplayIprModal(ipr_data)) removal_list.push('IPR');
    if (!shouldDisplayBracketPositioningModal(props, ifu_brackets)) removal_list.push('Optimal Bracket Positioning');
    if (!shouldDisplayDebondingModal()) removal_list.push('Debonding');

    return removeStatusCardModal(removal_list);
  }

  /**
   * Store ipr values in case_ipr table
   * @function
   * @param {Object} props - props passed in
   * @param {Object} ifu_brackets - Brackets selected from IFU process (not from Form 3420)
   * @return {Boolean} True if should display the ipr modal false otherwise
   */
  function shouldDisplayBracketPositioningModal(props, ifu_brackets) {
    const brackets = getBracketPositionings(props.cases, false, ifu_brackets);
    return brackets.length > 0;
  }
  /**
   * Remove the Card
   * @function
   */
  function removeStatusCardModal(titles) {
    let newList = status_list;
    let newDeList = status_list_de;

    for (let i = 0; i < newList.length; i++) {
      newList[i] = newList[i].filter((card) => {
        return !titles.includes(card.title);
      });
    }

    newDeList = newDeList.filter((card) => {
      return !titles.includes(card.title);
    });

    setStatusList(newList);
    setStatusListDe(newDeList);
  }
  /**
   * Check if should display the ipr modal
   * @function
   * @param {Object} ipr_data - Object of ipr json data
   * @return {Boolean} True if should display the ipr modal false otherwise
   */
  function shouldDisplayIprModal(ipr_data) {
    if (
      ipr_data &&
      ipr_data.end &&
      ((ipr_data.end.man && Object.keys(ipr_data.end.man).length > 0) || (ipr_data.end.max && Object.keys(ipr_data.end.max).length > 0))
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Check if should display the debonding modal
   * @function
   * @return {Boolean} True if should display the ipr modal false otherwise
   */
  function shouldDisplayDebondingModal() {
    if ((props.nextRecord && props.nextRecord.status_code === 'STATUS_SHIP') || (!props.cases.case_id.includes('-DE') && props.has_shipped_de)) {
      return false;
    } else {
      return true;
    }
  }

  const card_data = [];

  if (props.isCaseDE) {
    card_data.push({
      column_title: 'Digital Enhancement Goals',
      card: status_list_de,
      label_description:
        'An optional finishing phase - DE-Smartwire 2/3 fully expressed, finishing adjustments performed, remaining inter-arch discrepancies resolved',
    });
  } else {
    for (let i = 0; i < status_list.length; i++) {
      if (i === 0) {
        card_data.push({
          column_title: 'Initial Bonding',
          card: status_list[i],
          label_description:
            'Bond brackets using IDB trays; for blocked-out teeth: direct bond a bracket, directional bond the Locket, or leave the Locket unligated',
        });
      } else if (i === 1) {
        card_data.push({
          column_title: 'Stage I Goals',
          card: status_list[i],
          label_description:
            'The leveling and aligning phase - all brackets placed in their planned bracket position, IPR completed, and Smartwire 1 sufficiently expressed',
        });
      } else if (i === 2) {
        card_data.push({
          column_title: 'Stage II Goals',
          card: status_list[i],
          label_description:
            'The working and finishing phase - Smartwire 2/3 fully expressed, inter-arch discrepancies resolved, and bite turbos removed/reduced',
        });
      }
    }
  }

  return (
    <div className="navigator_section">
      <h3 className="case-detail__status-header">InBrace Navigator</h3>
      <div className="navigator_status_section">{displayNavigation(card_data, props)}</div>
      {session_expired && <SessionExpire theme="ipp" />}
    </div>
  );
}

export default TreatmentNavigator;
