/**
 * File: browser_warning.js - display a message for browser that are not using
 * google chrome
 * Copyright: (c) Copyright August 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './browser_warning.scss';
// External Libs
import React, { Component } from 'react';
import EnvironmentBanner from '../environment_banner/environment_banner';
// Internal Functions
import { isChrome, isEdge, isModernSafari } from '../../common/browser';
import { getEnvName, firstLetterCapital } from '../../common/functions';

/**
 * A banner that shows up when you're not using chrome
 * @component
 * @alias BrowserWarning
 * @category Common
 */
class BrowserWarning extends Component {
  render() {
    const envName = getEnvName();

    return (
      <React.Fragment>
        {isChrome || isModernSafari || isEdge ? (
          <EnvironmentBanner />
        ) : (
          <div className="browser-warning">
            <div className="top-overlay-message">
              {envName ? `${firstLetterCapital(envName)} Environment: ` : ''}
              We noticed you are using a browser that is unsupported. This will prevent you from using certain features. To get the best experience, InBrace
              strongly recommends using{' '}
              <a href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                Google Chrome
              </a>
              .
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default BrowserWarning;
