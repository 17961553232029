// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';
import { onDownload } from '../../../../common/functions';
class SuccessManual extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Conversion Successful</div>
          <div className="center-text">
            Please review files below to confirm conversion was completed successfully. Click <span className="bold-text">Transfer</span> to send the files to
            the wire bending machine. Click <span className="bold-text">Reject</span> to make adjustments.
          </div>
          {this.props.recipe_file ? (
            <React.Fragment>
              <div className="center-text">
                <a className="underline-text viewable-text file-span" href={`/${this.props.recipe_file.upload_data}`} download onClick={onDownload}>
                  Recipe File <i className="fa fa-file-excel-o" aria-hidden="true" />
                </a>{' '}
                <a className="file-span" href={`/${this.props.recipe_file.upload_data}`} download onClick={onDownload}>
                  {' '}
                  (Manual Process)
                </a>
              </div>
            </React.Fragment>
          ) : null}
          {this.props.ip_measurements_file ? (
            <React.Fragment>
              <div className="center-text">
                <span className="underline-text viewable-text" onClick={() => this.props.openMeasurementFileModal()}>
                  IP Loop Measurements
                </span>{' '}
                <i className="fa fa-file-pdf-o underline-text viewable-text" onClick={() => this.props.openMeasurementFileModal()} aria-hidden="true" />{' '}
                <span className="file-span" onClick={() => this.props.openMeasurementFileModal()}>
                  {' '}
                  (Manual Process)
                </span>
              </div>
            </React.Fragment>
          ) : null}
          <div className="extra-padding-headline-bpp" />
          <div className="button-panel center-text">
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('IREQ_AWB_MANUAL_APPROVAL', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                  <button type="button" className="btn btn-light" onClick={this.props.openManualTransferModal}>
                    Manual Transfer
                  </button>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>

            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('IREQ_AWB_MANUAL_APPROVAL', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                  <button type="button" className="btn btn-light" onClick={this.props.openManualRejectModal}>
                    Reject
                  </button>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SuccessManual;
