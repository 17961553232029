import React from 'react';

/**
 * Displays a basic date input
 * @component
 */
function InputDate({ className, ...props }) {
  return <input className={`${className ? className : ''} text-input`} type="date" {...props} />;
}

export default InputDate;
