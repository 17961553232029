// External
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Internal
import Modal from '../../../components/modal/modal';
import { handleHttpRequestError } from '../../../common/error';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { textFieldCheck, removeCaseIdInitialNumber, modalClear } from '../../../common/functions';
import { getPDFNameTemplate, isCaseBlocked, onReloadPage } from '../../../common/helpers';
import WorkOrderRef from '../../../components/workorder_reference/work_order_reference';
import AutomatedWireBending from './automated_wirebending';
import Labels from '../../../components/workflow/production/labels';
import ProductionSummary from './production_summary';
import ProductionProcess from './production_process';

// nomenclature
import { getLookup } from '../../../common/nomenclature';
// Sass
import './production_container.scss';
import {
  fetchWireFabricationReviewStatus,
  onProductionStageChange,
  toggleInitiateWireFabricationReviewModal,
  initiateWireFabricationReview,
  toggleProductionLogModal,
} from '../../../redux/actions/bpp/wire_selection/wire_fabrication_review';
import {
  getInitiateWireFabricationReviewModal,
  getProductionLogModal,
  getRefreshStatus,
  getSelectedProductionStage,
  getWireFabricationReviewStatus,
  getWireFabricationReviewStatusLog,
} from '../../../redux/reducers/bpp/wire_selection/wire_fabrication_review';
import { getWireManufacturingStatus } from '../../../redux/reducers/bpp/wire_selection/wire_selection';
import SessionExpire from '../../../common/session_expire';

const GEN_2_LABELS = {
  smartwire: 'Smartwire and BFL',
  idb: 'IDB Tray',
  bracket: 'Brackets',
  outer_patient: 'Outer Patient',
  singlesheet: 'Single Sheet',
};

/**
 * Used to perform production steps during case manufacturing process
 * @component
 * @alias BusinessCaseProductionContainer
 * @category BPP
 */
class ProductionContainer extends Component {
  constructor(props) {
    super(props);

    this.BUSINESS_ROLE = 1;

    this.state = {
      setupPath: '',
      editable_status_comment: '',
      ip_loop_measurement_modal: false,
      rework_modal: false,
      api_pending: false,
      selections: [],
      inprocess_ipstatus: {},
      production_process_step: 'Labels',
      label_generation_status: 'Label Selection',
      label_generation_status_comment: '',
      label_options: [],
      mark_as_success: false,
      complete_process_modal: false,
      label_inner_packaging_modal: false,
      label_contents_modal: false,
      udi_label_modal: false,
      label_message: '',
      refresh: false,
      gen_2_label_modal: false,
      selected_gen_2_label: '',
      in_progress_text: 'Sending files to wire bending machine...',
    };
  }

  toggleCheckbox = (event) => {
    let selections = this.state.selections;
    selections.forEach(function (element) {
      if (element.parts_id === parseInt(event.target.id)) {
        element.state = !element.state;
      }
    });

    selections = _.cloneDeep(selections);

    this.setState({ selections: selections });
  };

  updateLabel = (label) => {
    const isDE = this.props.case_id.indexOf('-DE') >= 0 || this.props.case_id.indexOf('-R') >= 0;

    return isDE ? 'DE ' + label : label;
  };

  onStatusCommentChange = (event) => {
    const editable_status_comment_value = textFieldCheck(event.target.value);

    this.setState({
      editable_status_comment: editable_status_comment_value,
    });
  };

  onConversionComplete = () => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/conversion_complete`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  setSelection = () => {
    const ITEM_PART_LOOKUP = getLookup(this.props.case_id, true); // nomenclature lookup
    let selections = [
      {
        description: ITEM_PART_LOOKUP['6502-03'],
        state: true,
        parts_id: 1,
        type: 'upper',
        size: '14',
      },
      {
        description: ITEM_PART_LOOKUP['6500-12'],
        state: true,
        parts_id: 2,
        type: 'upper',
        size: '16',
      },
      {
        description: ITEM_PART_LOOKUP['6502-04'],
        state: true,
        parts_id: 3,
        type: 'lower',
        size: '14',
      },
      {
        description: ITEM_PART_LOOKUP['6500-13'],
        state: true,
        parts_id: 4,
        type: 'lower',
        size: '16',
      },
      {
        description: ITEM_PART_LOOKUP['6500-20'],
        state: true,
        parts_id: 5,
        type: 'lower',
        size: '16',
      },
    ];
    this.fetchInProcessStatus(selections);
  };

  componentDidMount() {
    this.setSelection();
    this.setState({ is_mounted: true });

    this.props.fetchWireFabricationReviewStatus(this.props.case_id);
    let that = this;
    Axios.get(`/apiV2/cadaction/${this.props.case_id}/generate_label/get_status`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
            label_generation_status_comment: res.data.status_comment,
          });
        }
      })
      .catch(function (err) {
        // do nothing
      });
  }

  componentDidUpdate() {
    let that = this;
    let production_process_step =
      that.props.history && that.props.history.location && that.props.history.location.state && that.props.history.location.state.production_process_step
        ? that.props.history.location.state.production_process_step
        : that.state.production_process_step;

    if (production_process_step !== that.state.production_process_step) {
      that.setState({
        production_process_step: production_process_step,
      });
    }
    let last_ip_status = this.getIPLastStatus();
    if (last_ip_status && last_ip_status === 'ip_files' && _.isEmpty(this.state.inprocess_ipstatus)) {
      this.setSelection();
    }
  }

  /**
   * Fetches Automated wire bending steps
   * @function
   * @param {list} selections - Selections list
   */
  fetchInProcessStatus = (selections) => {
    let inprocess_ipstatus = this.state.inprocess_ipstatus;
    let selection = selections;
    let that = this;
    Axios.get('/apiv3/ipstatus/' + this.props.case_id, {
      params: {
        status: 'Recipe Conversion Process',
      },
    })
      .then((res) => {
        if (res.data.ipstatus && res.data.ipstatus.length > 0) {
          inprocess_ipstatus = JSON.parse(res.data.ipstatus[0].status_comment);
          let inprocess_ipstatus_upper = inprocess_ipstatus.upper;
          let inprocess_ipstatus_lower = inprocess_ipstatus.lower;
          try {
            selection.forEach(function (item, index, object) {
              if ((inprocess_ipstatus_upper === 'both' || inprocess_ipstatus_upper === item.size) && item.type === 'upper') {
                item.state = true;
              } else if ((inprocess_ipstatus_lower === 'both' || inprocess_ipstatus_lower === item.size) && item.type === 'lower') {
                item.state = true;
              } else {
                item.state = false;
              }
            });
            that.setState({
              inprocess_ipstatus: inprocess_ipstatus,
              selections: selection.filter((s) => {
                return s.state === true;
              }),
            });
          } catch (error) {
            console.log(error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIPLastStatus = () => {
    const cases = this.props.cases;
    const lookup_status = {
      '': 'ip_upload',
      'IP Measurements Uploaded': 'ip_upload',
      'Recipe Conversion Process': 'ip_conversion',
      'Recipe Conversion Failed': 'ip_conversion_failed',
      'Recipe Conversion Success': 'ip_review',
      'Outputs Approved': 'ip_files',
      'Rework Process': 'ip_files',
      'Outputs Rejected': 'ip_upload',
      'Override Process': 'ip_none',
    };

    if (cases && cases.ip_process) {
      return lookup_status[cases.ip_process.status];
    }

    return '';
  };

  /**
   * Uploads IP file
   * @function
   * @param {Object} data - File object
   */
  onIpUpload = (data) => {
    const that = this;

    let upload_data = data[0].upload_data;

    let modified_file_path = upload_data.slice(0, upload_data.indexOf('IP_UPLOAD/') + 10);
    let modified_file_name = removeCaseIdInitialNumber(upload_data.slice(upload_data.indexOf('IP_UPLOAD/') + 10, upload_data.length));

    data[0].upload_data = modified_file_path + modified_file_name;

    if (data && data[0] && data[0].folder === 'ip_upload') {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      return Axios.post(`/apiV2/casefiles/${this.props.case_id}/add_ipworksheet`, form_data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  onRemove = (event) => {
    event.preventDefault();
    const that = this;
    const file_type = 'ip_upload';

    const ip_upload_file = this.props.files?.ip_process.filter((files) => {
      return files.file_type === file_type;
    });

    let upload_data = ip_upload_file[0]['upload_data'];

    let modified_file_path = upload_data.slice(0, upload_data.indexOf('IP_UPLOAD/') + 10);
    let modified_file_name = upload_data.slice(upload_data.indexOf('IP_UPLOAD/') + 10, upload_data.length).replace('DE1', 'DE');

    upload_data = modified_file_path + modified_file_name;

    let form_data = new FormData();

    form_data.append(
      'files',
      JSON.stringify([
        {
          file_type: file_type,
          folder: file_type,
          incomplete_id: '',
          location: 'delete',
          mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          original_filename: '',
          upload_data: upload_data,
        },
      ])
    );

    return Axios.post(`/apiV2/casefiles/${this.props.case_id}/remove`, form_data)
      .then((res) => {
        that.props.reload_information({
          case_details: res.data,
        });

        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  onOverrideProcess = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/override_ip`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onApproveRecipeFiles = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/approve_ip`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onRejectRecipeFiles = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/reject_ip`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onStartConversion = (params) => {
    const that = this;
    const file_type = 'ip_upload';

    const ip_upload_file = this.props.files?.ip_process?.filter((files) => {
      return files.file_type === file_type;
    });

    const upload_data = ip_upload_file[0]['upload_data'];
    let data = {
      file_path: upload_data,
      wire_option: JSON.stringify({
        upper: params.upper_selection,
        lower: params.lower_selection,
      }),
    };

    this.props.setConversion(true);

    return Axios.post(`/apiV2/caseaction/${that.props.case_id}/process_ip_worksheet`, data)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  getIPCaseFilePath = (file_type) => {
    let file = '';
    if (this.props.files?.ip_process && this.props.files?.ip_process.length > 0) {
      file = this.props.files?.ip_process.filter((file) => {
        return file.file_type === file_type;
      });

      if (file && file.length > 0) {
        file = file[0];
      }
    }

    return file;
  };

  onIpConversionUploadClick = (event) => {
    let that = this;

    return Axios.post(`/apiV2/caseaction/${this.props.case_id}/failed_ip_to_upload`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onIpConversionFailedClick = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${that.props.case_id}/ip_conversion_failed`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onIpConversionConfirmClick = (event) => {
    const that = this;

    return Axios.post(`/apiV2/caseaction/${that.props.case_id}/ip_conversion_complete`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onButtonClickRework = (event) => {
    let selections = _.cloneDeep(this.state.selections);
    let wire_selection = [];
    let selection = [];
    selections.forEach((item_part) => {
      if (item_part.state) {
        wire_selection.push(item_part.description);
        selection.push(item_part);
      }
    });
    let upper = this.getsmartWire(selection, 'Upper');
    let lower = this.getsmartWire(selection, 'Lower');
    const lower_straight = this.getsmartWire(selection, 'Straight');
    let status_comment = {
      upper: upper,
      lower: lower,
      lower_straight: lower_straight,
      wire_selection: wire_selection,
    };
    const data = { status_comment: status_comment };
    const that = this;
    this.setState({ api_pending: true });
    return Axios.post(`/apiv3/ipstatus/${this.props.case_id}/rework`, data)
      .then(function (res) {
        that.props.reload_information();
        that.setState({ rework_modal: false, api_pending: false });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.setState({ in_progress_text: 'Error: File(s) are missing to be transfer' }, () => {
          setTimeout(() => {
            that.setState({ rework_modal: false, api_pending: false });
          }, 4000);
        });
      });
  };

  onIPLoopMeasurementModalClick = (event) => {
    this.setState({ ip_loop_measurement_modal: true });
  };

  onIPLoopMeasurementModalDismiss = (event) => {
    this.setState({ ip_loop_measurement_modal: false });
    modalClear();
  };

  onRewokModalClick = (event) => {
    this.setSelection();
    this.setState({ rework_modal: true, in_progress_text: 'Sending files to wire bending machine...' });
  };

  onRewokModalDismiss = (event) => {
    this.setSelection();
    this.setState({ rework_modal: false, api_pending: false });
    modalClear();
  };

  getsmartWire(item_parts, type) {
    let types = ['Straight'];
    types = types.filter((e) => e !== type);
    let return_array = [];
    item_parts.forEach((part) => {
      if (part.description.includes(type) && types.filter((e) => part.description.includes(e)).length === 0) {
        if (part.description.includes('16')) {
          return_array.push('16');
        } else if (part.description.includes('14')) {
          return_array.push('14');
        }
      }
    });
    return return_array;
  }

  /**
   * Start label generation process api
   * @function
   * @param {Object} label_request - label request object
   */
  startLabelGeneration = (label_request) => {
    this.setState({
      label_options: label_request.label_options,
    });
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/start_process`, label_request)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.props.reload_information(false, function () {
            that.setState({
              label_generation_status: res.data.status,
              label_generation_status_comment: res.data.status_comment,
            });
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status !== 409) {
          that.props.reload_information(false, function () {
            that.setState({
              label_generation_status: 'Label Generation Failed',
              label_generation_status_comment: 'Loading Comments...',
            });
          });
        }
      });

    that.setState({
      label_generation_status: 'Label Generation Process',
    });
  };

  /**
   * Start label override process api
   * @function
   * @param {Object} event - Button click event
   */
  overrideLabelGeneration = (event) => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/override_process`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information();
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Forces label generation process success api
   * @function
   * @param {Object} event - Button click event
   */
  markGenerationAsSuccess = (event) => {
    this.setState({
      mark_as_success: true,
    });
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/force_success`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Forces label generation process failed api
   * @function
   * @param {Object} event - Button click event
   */
  markGenerationAsFailed = (event) => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/force_failure`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
            label_generation_status_comment: res.data.status_comment,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      label_generation_status: 'Label Generation Failed',
      label_generation_status_comment: 'Loading Comment...',
    });
  };

  /**
   * Restarts label generation process api
   * @function
   * @param {Object} event - Button click event
   */
  restartLabelGeneration = (event) => {
    const label_generation_status = this.state.label_generation_status;
    this.setState({
      mark_as_success: false,
    });
    let that = this;
    this.props.process_in_progress(true);
    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/retry_process`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          if (label_generation_status === 'Production Process Complete') {
            that.props.fetchWireFabricationReviewStatus(that.props.case_id);
          }
          that.setState({
            label_generation_status: res.data.status,
            label_message: '',
            production_process_step: 'Labels',
          });
          that.props.reload_information();
          that.props.process_in_progress(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.setState({
          label_generation_status: 'Label Generation Reprocess',
          label_message: '',
        });
        that.props.reload_information();
        that.props.process_in_progress(false);
      });
  };

  /**
   * Uploads label generation file
   * @function
   * @param {object} data - File object
   */
  onUpload = (data) => {
    const that = this;
    const label_actions = {
      inner_package_label: 'upload_inner_package',
      contents_labels: 'upload_contents',
      smartwire_label: 'upload_smartwire_label',
      idbtray_label: 'upload_idb_label',
      smallbracket_label: 'upload_bracket_label',
      outerpatient_label: 'upload_outer_patient_label',
      singlesheet_label: 'upload_single_sheet_label',
    };
    if (data && data[0] && data[0].folder in label_actions) {
      let action = label_actions[data[0].folder];
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));
      Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/${action}`, form_data)
        .then(function (res) {
          if (res && res.data && res.data.status) {
            this.props.reloadInformation();

            that.setState({
              label_generation_status: res.data.status,
            });
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          that.props.reload_information(false);
        });
    }
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };

  /**
   * Removes Inner Packaging file
   * @function
   * @param {object} event - Button click event
   */
  removeInnerPackageFile = (event) => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/remove_package_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };

  /**
   * Removes Inner Contents file
   * @function
   * @param {object} event - Button click event
   */
  removeInnerContentsFile = (event) => {
    let that = this;
    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/remove_content_label`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };

  /**
   * Removes Gen 2.0 Label file
   * @function
   * @param {object} event - Button click event
   */
  removeGen2LabelFile = (label_type) => {
    let that = this;
    const label_actions = {
      smartwire: 'remove_smartwire_label',
      idb: 'remove_idb_label',
      bracket: 'remove_bracket_label',
      outer_patient: 'remove_outer_patient_label',
      single_sheet: 'remove_single_sheet_label',
    };
    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/${label_actions[label_type]}`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.setState({
            label_generation_status: res.data.status,
          });
          that.props.reload_information(false);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.props.reload_information(false);
      });
    that.setState({
      label_generation_status: 'Label Generation Success',
    });
  };

  generateReworkModal = () => {
    const header_text = `Rework -  ${removeCaseIdInitialNumber(this.props.case_id)}`;
    return (
      <Modal
        theme="bpp"
        preset="decision-rework-case"
        header_text={header_text}
        message_text="Review the selected wires, then click transfer to send them to wire bending machine."
        confirm_btn_text="Transfer"
        checkbox_selections={this.state.selections}
        onConfirmButtonClick={this.onButtonClickRework}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onRewokModalDismiss}
        onCheckedChange={this.toggleCheckbox}
        in_progress={this.state.api_pending}
        in_progress_text={this.state.in_progress_text}
      />
    );
  };

  reload = () => {};
  changeCadProcessStep = (e, step) => {
    this.setState({
      production_process_step: step,
    });
    try {
      this.props.history.replace({ state: { production_process_step: step } });
    } catch (error) {}
  };

  /**
   * Gets label file path for label generation files
   * @function
   * @return {string} - Returns file path
   */
  getLabelFilePath = (file_type, files) => {
    let file = '';

    if (files && files.length > 0) {
      file = files.filter((file) => {
        if (file_type === 'inner') {
          return file.upload_data.indexOf('inner') !== -1;
        } else if (file_type === 'udi') {
          return file.upload_data.indexOf('udi') !== -1;
        } else if (file_type === 'contents') {
          return file.upload_data.indexOf('content') !== -1;
        } else if (file_type === 'smartwire') {
          return file.upload_data.indexOf('smartwire_label') !== -1;
        } else if (file_type === 'idb') {
          return file.upload_data.indexOf('idbtray_label') !== -1;
        } else if (file_type === 'bracket') {
          return file.upload_data.indexOf('smallbracket_label') !== -1;
        } else if (file_type === 'outer_patient') {
          return file.upload_data.indexOf('outerpatient_label') !== -1;
        } else if (file_type === 'singlesheet') {
          return file.upload_data.indexOf('singlesheet_label') !== -1;
        }
        return false;
      });

      if (file && file.length > 0) {
        file = file[file.length - 1];
      } else {
        file = '';
      }
    }

    return file;
  };

  /**
   * Displays Complete process modal
   * @function
   * @return {JSX} - Modal jsx
   */
  completeProcessModal = () => {
    const header_text = `${this.props.cases.gen_2 ? 'Label Generation' : 'Production Process'} - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to mark this process as complete?"
        confirm_btn_text="Complete"
        onConfirmButtonClick={this.onConfirmcompleteProcessModal}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelcompleteProcessModal}
      />
    );
  };

  /**
   * Opens Complete process modal
   * @function
   */
  onCompleteButtonClick = () => {
    this.setState({
      complete_process_modal: true,
    });
  };

  /**
   * Closes Complete process modal
   * @function
   */
  onCancelcompleteProcessModal = () => {
    this.setState({
      complete_process_modal: false,
    });
    modalClear();
  };

  /**
   * Completes production process step and fires complete process api
   * @function
   */
  onConfirmcompleteProcessModal = () => {
    let that = this;

    Axios.post(`/apiV2/cadaction/${this.props.case_id}/generate_label/complete_process`)
      .then(function (res) {
        if (res && res.data && res.data.status) {
          that.props.reload_information();
          that.setState({
            complete_process_modal: false,
            label_generation_status: res.data.status,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      complete_process_modal: false,
    });
    this.props.cases.cad_process.status = 'Production Process Complete';
  };

  /**
   * Sets label generation status
   * @function
   * @param {string} - Label status
   */
  setLabelGenerationStatus = (status) => {
    this.setState({
      label_generation_status: status,
    });
  };

  /**
   * Displays Inner Packaging modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onInnerPackagingModalClick = (event) => {
    this.setState({ label_inner_packaging_modal: true });
  };

  /**
   * Closes Inner Packaging modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onInnerPackagingModalDismiss = (event) => {
    this.setState({ label_inner_packaging_modal: false });
    modalClear();
  };

  /**
   * Displays Inner Contents modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onLabelContentsModalClick = (event) => {
    this.setState({ label_contents_modal: true });
  };

  /**
   * Closes Inner Contents modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onLabelContentsModalDismiss = (event) => {
    this.setState({ label_contents_modal: false });
    modalClear();
  };

  /**
   * Closes UDI Label modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onUdiLabelModalDismiss = (event) => {
    this.setState({ udi_label_modal: false });
    modalClear();
  };

  /**
   * Displays UDI Label modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onUdiLabelModalClick = () => {
    this.setState({ udi_label_modal: !this.state.udi_label_modal });
  };

  /**
   * Displays Gen 2.0 Label modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onGen2LabelModalClick = (label) => {
    this.setState({ gen_2_label_modal: true, selected_gen_2_label: label });
  };

  /**
   * Closes Gen 2.0 Label modal
   * @function
   * @return {JSX} - Modal jsx
   */
  onGen2LabelModalDismiss = (event) => {
    this.setState({ gen_2_label_modal: false, selected_gen_2_label: '' });
    modalClear();
  };

  /**
   * Checks if production step is valid
   * @function
   * @return {Boolean} - Returns if the production step is valid or not
   */
  isProductionStepValid() {
    const label_status = this.props.cases.cad_process.status;
    const label_status_state = this.state.label_generation_status;
    const has_valid_ws_state = this.props.cases.gen_2
      ? this.props.wire_selection_status !== 'WS Complete' ||
        this.props.wire_selection_status !== 'WS Override' ||
        this.props.wire_selection_status !== 'WS Redo' ||
        this.props.wire_selection_status !== '' ||
        this.props.wire_fabrication_review_status === 'WFR Rework' ||
        this.props.wire_fabrication_review_status === 'WFR Complete'
      : true;

    if (
      this.props.get_case_status &&
      (this.props.get_case_status.toUpperCase() === 'STATUS_MANUFACTURE' || isCaseBlocked(this.props.get_case_status.toUpperCase())) &&
      this.props.cases.manufacturing_process &&
      this.props.cases.manufacturing_process.log.length > 0 &&
      this.props.cases.manufacturing_process.log[this.props.cases.manufacturing_process.log.length - 1].status_code.toUpperCase() === 'STATUS_MANUFACTURE' &&
      (label_status !== 'Production Process Complete' || label_status_state !== 'Production Process Complete') &&
      has_valid_ws_state
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if production summary step is valid
   * @function
   * @return {Boolean} - Returns if the production summary step is valid or not
   */
  isProductionSummaryValid() {
    const label_status = this.props.cases.cad_process.status;
    const label_status_state = this.state.label_generation_status;
    let last_ip_status = this.getIPLastStatus();
    if (this.props.ip_conversion === true) {
      last_ip_status = 'ip_conversion';
    }
    if (
      !(!this.props.cases.gen_2 && last_ip_status !== 'ip_files' && last_ip_status !== 'ip_none') &&
      (((label_status === 'Production Process Complete' || label_status_state === 'Production Process Complete') &&
        (this.props.get_case_status.toUpperCase() === 'STATUS_MANUFACTURE' ||
          this.props.get_case_status.toUpperCase() === 'STATUS_CANCEL' ||
          this.props.get_case_status.toUpperCase() === 'STATUS_HOLD')) ||
        this.props.get_case_status.toUpperCase() === 'STATUS_SHIP')
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if work order reference number should be disabled
   * @function
   * @return {Boolean} - Returns if the work order reference number should be disabled
   */
  disableWorkOrderRefComponent = () => {
    const label_status = this.props.cases.cad_process.status;
    const label_status_state = this.state.label_generation_status;
    if (
      this.props.ref_no &&
      (label_status === 'Label Generation Process' ||
        label_status_state === 'Label Generation Process' ||
        label_status_state === 'Labels Complete' ||
        (label_status === 'Label Generation Success' &&
          this.props.files?.cad_process?.filter(function (a) {
            return a.original_filename.includes('udi_label') || a.original_filename.includes('singlesheet_label');
          }).length > 0))
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Determines if Gen 2.0 Label was selected
   * @function
   * @param {String} label_type - Label type
   * @return {Boolean} - True or false
   */
  isLabelSelected(label_type) {
    let logs = this.props.cases.cad_process.log.filter((log) => {
      return log.status_code === 'Label Generation Process';
    });

    if (logs.length > 0 && logs[logs.length - 1].text) {
      let status_comment = JSON.parse(logs[logs.length - 1].text);
      status_comment = status_comment.manifest;
      return (
        status_comment.filter((label) => {
          return label.includes(label_type);
        }).length > 0
      );
    }
    return false;
  }

  /**
   * Checks if Gen 2.0 Label Generation process is complete
   * @function
   * @return {Boolean} - Returns if the work order reference number should be disabled
   */
  isGen2LabelGenerationProcessCompleted() {
    const label_status = this.props.cases.cad_process.status;
    const label_status_state = this.state.label_generation_status;
    const files = this.props.files?.cad_process;
    const has_smartwire_label = this.isLabelSelected('smartwire')
      ? files?.filter(function (a) {
          return a.original_filename.includes('smartwire_label');
        }).length !== 0
      : true;
    const has_idb_label = this.isLabelSelected('idb')
      ? files?.filter(function (a) {
          return a.original_filename.includes('idbtray_label');
        }).length !== 0
      : true;
    const has_bracket_label = this.isLabelSelected('bracket')
      ? files?.filter(function (a) {
          return a.original_filename.includes('smallbracket_label');
        }).length !== 0
      : true;
    const has_outer_patient_label = this.isLabelSelected('outer_patient')
      ? files?.filter(function (a) {
          return a.original_filename.includes('outerpatient_label');
        }).length !== 0
      : true;
    const has_single_sheet_label =
      files?.filter(function (a) {
        return a.original_filename.includes('singlesheet_label');
      }).length !== 0;

    if (label_status_state === 'Label Override Process' || label_status === 'Label Override Process') {
      return true;
    }
    return (
      (label_status === 'Production Process Complete' ||
        label_status === 'Label Manual Process' ||
        label_status === 'Label Generation Success' ||
        label_status_state === 'Production Process Complete' ||
        label_status_state === 'Label Generation Success') &&
      label_status_state !== 'Label Generation Reprocess' &&
      label_status !== 'Label Generation Reprocess' &&
      ((has_smartwire_label && has_idb_label && has_bracket_label && !(!this.props.case_id.includes('-DE') && !has_outer_patient_label)) ||
        has_single_sheet_label)
    );
  }

  /**
   * Checks if Label Generation process is complete
   * @function
   * @return {Boolean} - Returns if the work order reference number should be disabled
   */
  isLabelGenerationProcessCompleted() {
    const label_status = this.props.cases.cad_process.status;
    const label_status_state = this.state.label_generation_status;
    if (label_status_state === 'Label Override Process' || label_status === 'Label Override Process') {
      return true;
    } else if (
      (label_status === 'Production Process Complete' ||
        label_status === 'Label Manual Process' ||
        label_status === 'Label Generation Success' ||
        label_status_state === 'Production Process Complete' ||
        label_status_state === 'Label Generation Success') &&
      label_status_state !== 'Label Generation Reprocess' &&
      label_status !== 'Label Generation Reprocess' &&
      this.props.files?.cad_process?.filter(function (a) {
        return a.original_filename.includes('inner_packaging');
      }).length !== 0 &&
      label_status_state !== 'Label Generation Reprocess'
    ) {
      if (this.props.case_id.includes('-DE')) {
        return true;
      } else if (
        this.props.files?.cad_process?.filter(function (a) {
          return a.original_filename.includes('contents');
        }).length !== 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * Displays loading text while component is rendering
   * @function
   * @returns {JSX} - Loading text
   */
  loadingText = () => {
    return <div className="center-text">Loading...</div>;
  };

  /**
   * Displays production component based on status
   * @function
   * @returns {JSX} - Production component
   */
  selectDisplayByStatus = () => {
    const production_status =
      this.props.selected_production_stage === 'labels' ? this.state.label_generation_status : this.props.wire_fabrication_review_status;
    const production_props = {
      case_id: this.props.case_id,
      production_stage: production_status,
      cases: this.props.cases,
      treatment_arch: this.props.cases.treatment_arch,
      status_log: this.props.cases.cad_process.log,
      gen_2: this.props.cases.gen_2,
      status_comment: this.state.label_generation_status_comment,
      files: this.props.files?.cad_process,
      mark_as_success: this.state.mark_as_success,
      message: this.state.label_message,
      returnToSelection: true,
      label_options: this.state.label_options,
      stage_disabled: !this.props.wire_fabrication_review_status,
      selected_production_stage: this.props.selected_production_stage,
      label_generation_status: this.state.label_generation_status,
      ref_no: this.props.ref_no,
      update_wo: this.props.update_wo,
      wire_fabrication_review_status: this.props.wire_fabrication_review_status,
      wire_selection_status: this.props.wire_selection_status,
      wire_manufacturing_status: this.props.wire_manufacturing_status,
      is_case_blocked: isCaseBlocked(this.props.get_case_status.toUpperCase()),
      patient_info: this.props.patient_info,
      is_wfr_initiated: this.isWireFabricationReviewInitiated(),
      startLabelGeneration: this.startLabelGeneration,
      overrideLabelGeneration: this.overrideLabelGeneration,
      markGenerationAsSuccess: this.markGenerationAsSuccess,
      markGenerationAsFailed: this.markGenerationAsFailed,
      getLabelFilePath: this.getLabelFilePath,
      restartLabelGeneration: this.restartLabelGeneration,
      removeInnerPackageFile: this.removeInnerPackageFile,
      removeInnerContentsFile: this.removeInnerContentsFile,
      removeGen2LabelFile: this.removeGen2LabelFile,
      onUpload: this.onUpload,
      onButtonClick: () => this.props.toggleInitiateWireFabricationReviewModal(true),
      onStageChange: this.onStageChange,
      disableWorkOrderRefComponent: this.disableWorkOrderRefComponent,
      loadingText: this.loadingText,
      reloadInformation: this.props.reload_information,
      setLabelGenerationStatus: this.setLabelGenerationStatus,
    };

    if (this.props.cases.ws_version === '1.0' || !this.props.cases.gen_2) {
      return <Labels {...production_props} />;
    }

    const displayByStatus = {
      '': this.loadingText(),
      'Label Selection': <ProductionProcess {...production_props} />,
      'Label Generation Reprocess': <ProductionProcess {...production_props} />,
      'Label Generation Process': <ProductionProcess {...production_props} />,
      'Label Generation Failed': <ProductionProcess {...production_props} />,
      'Label Generation Success': <ProductionProcess {...production_props} />,
      'Label Manual Process': <ProductionProcess {...production_props} />,
      'Label Override Process': <ProductionProcess {...production_props} />,
      'Labels Complete': <ProductionProcess {...production_props} />,
      'WFR Process': <ProductionProcess {...production_props} />,
      'WFR Rework': <ProductionProcess {...production_props} />,
      'WFR Complete': <ProductionProcess {...production_props} />,
    };

    return production_status in displayByStatus ? displayByStatus[production_status] : this.loadingText();
  };

  /**
   * Displays initiate wire fabrication review modal
   * @function
   * @returns {JSX} - Initiate Wire fabrication review modal
   */
  displayInitiateWireFabricationReviewModal = () => {
    const wfr_stage =
      this.state.label_generation_status === 'Production Process Complete' ? 'complete' : this.props.cases.ws_rework_no ? 'in-progress' : 'initial';
    const message =
      wfr_stage === 'initial' ? 'Are you sure you want to initiate wire fabrication review?' : 'Would you like to reinitiate wire fabrication review?';

    return (
      <Modal
        preset="decision"
        header_text={`Initiate Wire Fabrication Review - ${removeCaseIdInitialNumber(this.props.case_id)}`}
        close_btn_text="Cancel"
        confirm_btn_text="Confirm"
        message_text={message}
        onConfirmButtonClick={() =>
          this.props.initiateWireFabricationReview(
            this.props.case_id,
            this.setLabelGenerationStatus,
            this.state.label_generation_status,
            this.props.reload_information,
            wfr_stage
          )
        }
        onCloseButtonClick={() => this.props.toggleInitiateWireFabricationReviewModal(false)}
        theme="bpp"
      />
    );
  };

  /**
   * Updates selected stage in production section
   * @function
   * @param {string} stage - Stage to switch to
   */
  onStageChange = (stage) => {
    this.props.onProductionStageChange(this.props.case_id, stage);
  };

  /**
   * Determines if Complete button should be displayed
   * @function
   * @param {string} last_ip_status - Last IP status
   * @returns {boolean} - True or false
   */
  shouldDisplayCompleteButton = (last_ip_status) => {
    const labels_completed = this.props.cases.gen_2 ? this.isGen2LabelGenerationProcessCompleted() : this.isLabelGenerationProcessCompleted();
    const awb_completed = !(!this.props.cases.gen_2 && last_ip_status !== 'ip_none' && last_ip_status !== 'ip_files');
    const wm_completed =
      this.props.cases.gen_2 && this.props.cases.ws_version !== '1.0'
        ? !this.props.wire_manufacturing_status || this.props.wire_manufacturing_status === 'WM Complete'
        : true;
    return labels_completed && awb_completed && wm_completed && this.props.selected_production_stage !== 'wire_fabrication_review';
  };

  /**
   * Displays modal for production logs
   * @function
   * @returns {JSX} - Production logs modal
   */
  displayProductionLogModal = () => {
    return (
      <Modal
        className="production-log wire-failure-modal"
        preset="log"
        theme="bpp"
        onCloseButtonClick={() => this.props.toggleProductionLogModal(false)}
        log_type="production"
        header_text="Production Log"
        case_id={this.props.case_id}
        selected_tab="case"
      />
    );
  };

  /**
   * Displays production log icon
   * @function
   * @returns {JSX} - Production log icon
   */
  displayProductionLogIcon = () => {
    return (
      <div
        className="pull-right btn-light-link"
        data-case_id={this.props.case_id}
        onClick={() => this.props.toggleProductionLogModal(true)}
        data-toggle="tooltip"
        data-placement="top"
        title="Production Log"
      >
        <i className="fa fa-file-text-o" aria-hidden="true" />
      </div>
    );
  };

  /**
   * Determines if wire fabrication review has been initiated
   * @function
   * @returns {boolean} - True or false
   */
  isWireFabricationReviewInitiated = () => {
    return (
      this.props.wire_fabrication_review_status === 'WFR Process' ||
      this.props.wire_fabrication_review_status_log.some((status) => status.status === 'WFR Process')
    );
  };

  render() {
    let last_ip_status = this.getIPLastStatus();
    let label_status = this.props.cases.cad_process.status;
    const ip_loop_measurement_file = this.getIPCaseFilePath('ip_measurements');
    if (this.props.ip_conversion === true) {
      last_ip_status = 'ip_conversion';
    }
    const label_files = this.props.files?.cad_process;
    const ip_files = this.props.files?.ip_process;

    const is_case_blocked = isCaseBlocked(this.props.get_case_status.toUpperCase());

    if (this.isProductionStepValid()) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return (
              <div id="production-area" className="cad-container">
                <div className="bold-text business-dark-heading">
                  Production
                  {this.props.cases.ws_version && this.props.cases.ws_version !== '1.0' && this.props.cases.gen_2 && this.displayProductionLogIcon()}
                </div>
                <div className="business-dark-theme">
                  <div key={`cad_container`} className="cad-process production-process">
                    {(!this.props.cases.gen_2 || this.props.cases.ws_version === '1.0') && (
                      <WorkOrderRef
                        label={'Production WO Ref:'}
                        classOverwrite={'production-label'}
                        placeholder={'WO1234567'}
                        ref_no={this.props.ref_no}
                        update_wo={this.props.update_wo}
                        permission="CASE_PROD_WO_EDIT"
                        status_code={this.props.cases.status_code}
                        disabled={this.disableWorkOrderRefComponent()}
                        ref_no_required={true}
                      />
                    )}
                    {(this.props.cases.ws_version === '1.0' || !this.props.cases.gen_2 ? this.props.ref_no : true) ? (
                      <React.Fragment>
                        {!is_case_blocked && (
                          <>
                            {!this.props.cases.gen_2 && (
                              <div className="process_message center-text">
                                Complete the steps below and review the generated outputs. The steps may be completed in any order. Upon completion of the
                                Production process, click <span className="bold-text">Complete</span>.
                              </div>
                            )}
                            {!this.props.cases.gen_2 && !this.props.loading && (
                              <div className="cad-selection">
                                <ul className="cad-workflow">
                                  <li
                                    className={
                                      (this.state.production_process_step === 'Labels' ? 'process-in-progress' : '') +
                                      (((this.state.label_generation_status === 'Label Generation Success' ||
                                        this.state.label_generation_status === 'Label Manual Process') &&
                                        ((this.props.case_id.indexOf('-DE') !== -1 &&
                                          label_files.length > 0 &&
                                          label_files.filter(function (a) {
                                            return a.original_filename.includes('inner_packaging');
                                          }).length > 0) ||
                                          (this.props.case_id.indexOf('-R') !== -1 &&
                                            label_files.length > 0 &&
                                            this.props.files.filter(function (a) {
                                              return a.original_filename.includes('inner_packaging');
                                            }).length > 0) ||
                                          (this.props.case_id.indexOf('-DE') === -1 &&
                                            this.props.case_id.indexOf('-R') === -1 &&
                                            this.props.cases.cad_process &&
                                            label_files &&
                                            label_files.length > 0 &&
                                            label_files.filter(function (a) {
                                              return a.original_filename.includes('inner_packaging');
                                            }).length > 0 &&
                                            label_files.filter(function (a) {
                                              return a.original_filename.includes('contents');
                                            }).length > 0))) ||
                                      this.state.label_generation_status === 'Label Override Process'
                                        ? ' process-success'
                                        : this.state.label_generation_status === 'Label Generation Failed'
                                        ? ' process-failure'
                                        : '')
                                    }
                                    onClick={(e) => this.changeCadProcessStep(e, 'Labels')}
                                  >
                                    <p>Labels</p>
                                  </li>
                                  <li
                                    className={
                                      (this.state.production_process_step === 'AWB' ? 'process-in-progress' : '') +
                                      (last_ip_status === 'ip_conversion_failed'
                                        ? ' process-failure'
                                        : last_ip_status === 'ip_files' || last_ip_status === 'ip_none'
                                        ? ' process-success'
                                        : '')
                                    }
                                    onClick={(e) => this.changeCadProcessStep(e, 'AWB')}
                                  >
                                    <p>AWB</p>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </>
                        )}
                        {(this.props.cases.gen_2 || (this.state.production_process_step === 'Labels' && !this.props.loading)) && (
                          <div className={this.props.cases.gen_2 ? 'production-process__gen-2' : !is_case_blocked ? 'process-box' : ''}>
                            {this.selectDisplayByStatus()}
                            {this.props.initiate_wire_fabrication_review_modal && this.displayInitiateWireFabricationReviewModal()}
                            {this.props.refresh && <SessionExpire />}
                          </div>
                        )}
                        {!is_case_blocked && (
                          <>
                            {this.state.production_process_step === 'AWB' ? (
                              <div className="process-box center-text">
                                <AutomatedWireBending
                                  last_ip_status={this.getIPLastStatus()}
                                  ip_files={ip_files}
                                  ip_process={this.props.cases.ip_process}
                                  cases={this.props.cases}
                                  case_status={this.props.cases.status_code}
                                  setConversion={this.props.setConversion}
                                  getIPCaseFilePath={this.getIPCaseFilePath}
                                  case_id={this.props.case_id}
                                  project={this}
                                  user_roles_and_permissions={this.props.user_roles_and_permissions}
                                  editable_status_comment={this.state.editable_status_comment}
                                  isUploading={this.props.isUploading}
                                  txplan_arch={this.props.txplan_arch}
                                  onOverrideProcess={this.onOverrideProcess}
                                  onIpUpload={this.onIpUpload}
                                  reloadInformation={this.props.reload_information}
                                  ip_loop_file={this.getIPCaseFilePath('ip_upload')}
                                  recipe_file={this.getIPCaseFilePath('ip_recipe')}
                                  ip_loop_measurement_file={this.getIPCaseFilePath('ip_measurements')}
                                  inprocess_ipstatus={this.state.inprocess_ipstatus}
                                  onRewokModalClick={this.onRewokModalClick}
                                  selections={this.state.selections}
                                  ref_no={this.props.ref_no}
                                  get_case_status={this.props.get_case_status}
                                  reload_information={this.props.reload_information}
                                  onIPLoopMeasurementModalClick={this.onIPLoopMeasurementModalClick}
                                />
                              </div>
                            ) : null}
                            <div className="button-panel">
                              <UserPermissionsContext.Consumer>
                                {(user_roles_and_permissions) => {
                                  return userHasPermission('CASE_PRODUCTION_COMPLETE', user_roles_and_permissions.permissions) &&
                                    this.shouldDisplayCompleteButton(last_ip_status) ? (
                                    <button type="button" className="btn btn-light" onClick={this.onCompleteButtonClick}>
                                      Complete
                                    </button>
                                  ) : null;
                                }}
                              </UserPermissionsContext.Consumer>
                            </div>
                            {this.state.complete_process_modal ? this.completeProcessModal() : null}
                          </>
                        )}
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
                {this.state.ip_loop_measurement_modal ? (
                  <Modal
                    preset="pdf_viewer"
                    header_text={`IP Loop Measurement - ${removeCaseIdInitialNumber(this.props.case_id)}`}
                    modal_size_class="modal-lg modal-lg-pdf"
                    modal_body_class="modal-pdf"
                    pdf_url={ip_loop_measurement_file.upload_data}
                    pdf_date={ip_loop_measurement_file.created_date}
                    onCloseButtonClick={this.onIPLoopMeasurementModalDismiss}
                    theme="bpp"
                    original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, '3450-01_IP_Loop_Measurements.pdf')}
                  />
                ) : null}
                {this.state.rework_modal ? (
                  <Modal
                    theme="bpp"
                    preset="decision-rework-case"
                    header_text={`Rework -  ${removeCaseIdInitialNumber(this.props.case_id)}`}
                    message_text="Review the selected wires, then click transfer to send them to wire bending machine."
                    confirm_btn_text="Transfer"
                    checkbox_selections={this.state.selections}
                    onConfirmButtonClick={this.onButtonClickRework}
                    close_btn_text="Cancel"
                    onCloseButtonClick={this.onRewokModalDismiss}
                    onCheckedChange={this.toggleCheckbox}
                    in_progress={this.state.api_pending}
                    in_progress_text={this.state.in_progress_text}
                  />
                ) : null}
                {this.state.refresh ? (
                  <Modal
                    preset="action"
                    x_btn={false}
                    header_text="Session Expired"
                    message_text="Sorry, your session has expired. Please refresh."
                    confirm_btn_text="Refresh"
                    onConfirmButtonClick={onReloadPage}
                    theme="bpp"
                  />
                ) : null}
                {this.props.production_log_modal && this.displayProductionLogModal()}
              </div>
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    } else if (this.isProductionSummaryValid() && !this.props.loading) {
      const label_inner_packaging_file = this.getLabelFilePath('inner', label_files);
      const label_contents_file = this.getLabelFilePath('contents', label_files);
      const udi_label_file = this.getLabelFilePath('udi', label_files);
      const smartwire_label_file = this.getLabelFilePath('smartwire', label_files);
      const idb_label_file = this.getLabelFilePath('idb', label_files);
      const bracket_label_file = this.getLabelFilePath('bracket', label_files);
      const outer_patient_label_file = this.getLabelFilePath('outer_patient', label_files);
      const singlesheet_label_file = this.getLabelFilePath('singlesheet', label_files);
      const selected_label_file =
        this.state.selected_gen_2_label === 'smartwire'
          ? smartwire_label_file
          : this.state.selected_gen_2_label === 'idb'
          ? idb_label_file
          : this.state.selected_gen_2_label === 'bracket'
          ? bracket_label_file
          : this.state.selected_gen_2_label === 'singlesheet'
          ? singlesheet_label_file
          : outer_patient_label_file;

      return (
        <React.Fragment>
          <ProductionSummary
            label_status={label_status}
            last_ip_status={this.getIPLastStatus()}
            ip_files={ip_files}
            ip_process={this.props.cases.ip_process}
            cases={this.props.cases}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            getIPCaseFilePath={this.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            editable_status_comment={this.state.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.txplan_arch}
            onOverrideProcess={this.onOverrideProcess}
            onIpUpload={this.onIpUpload}
            reloadInformation={this.props.reload_information}
            ip_loop_file={this.getIPCaseFilePath('ip_upload')}
            recipe_file={this.getIPCaseFilePath('ip_recipe')}
            ip_loop_measurement_file={this.getIPCaseFilePath('ip_measurements')}
            inprocess_ipstatus={this.state.inprocess_ipstatus}
            onRewokModalClick={this.onRewokModalClick}
            selections={this.state.selections}
            ref_no={this.props.ref_no}
            get_case_status={this.props.get_case_status}
            reload_information={this.props.reload_information}
            label_inner_packaging_file={label_inner_packaging_file}
            label_contents_file={label_contents_file}
            udi_label_file={udi_label_file}
            onInnerPackagingModalClick={this.onInnerPackagingModalClick}
            onLabelContentsModalClick={this.onLabelContentsModalClick}
            onUdiLabelModalClick={this.onUdiLabelModalClick}
            return_clicked={this.state.return_clicked}
            restartLabelGeneration={this.restartLabelGeneration}
            onIPLoopMeasurementModalClick={this.onIPLoopMeasurementModalClick}
            smartwire_label_file={smartwire_label_file}
            idb_label_file={idb_label_file}
            bracket_label_file={bracket_label_file}
            outer_patient_label_file={outer_patient_label_file}
            singlesheet_label_file={singlesheet_label_file}
            onGen2LabelModalClick={this.onGen2LabelModalClick}
            toggleInitiateWireFabricationReviewModal={this.props.toggleInitiateWireFabricationReviewModal}
            displayProductionLogIcon={this.displayProductionLogIcon}
            wire_selection_status={this.props.wire_selection_status}
          />

          {this.state.label_inner_packaging_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={`Label Inner Packaging - ${removeCaseIdInitialNumber(this.props.case_id)}`}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              pdf_url={label_inner_packaging_file.upload_data}
              pdf_date={label_inner_packaging_file.created_date}
              onCloseButtonClick={this.onInnerPackagingModalDismiss}
              theme="bpp"
              original_filename={getPDFNameTemplate(
                this.props.case_id,
                this.props.patient_info,
                `Label_Inner_Packaging${label_inner_packaging_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
              )}
            />
          ) : null}
          {this.state.label_contents_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={`Label Contents - ${removeCaseIdInitialNumber(this.props.case_id)}`}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              pdf_url={label_contents_file.upload_data}
              pdf_date={label_contents_file.created_date}
              onCloseButtonClick={this.onLabelContentsModalDismiss}
              theme="bpp"
              original_filename={getPDFNameTemplate(
                this.props.case_id,
                this.props.patient_info,
                `Label_Contents${label_contents_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
              )}
            />
          ) : null}
          {this.state.udi_label_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={`UDI Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              pdf_url={udi_label_file.upload_data}
              pdf_date={udi_label_file.created_date}
              onCloseButtonClick={this.onUdiLabelModalDismiss}
              theme="bpp"
              original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, 'Label_UDI.pdf')}
            />
          ) : null}
          {this.state.ip_loop_measurement_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={`IP Loop Measurement - ${removeCaseIdInitialNumber(this.props.case_id)}`}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              pdf_url={ip_loop_measurement_file.upload_data}
              pdf_date={ip_loop_measurement_file.created_date}
              onCloseButtonClick={this.onIPLoopMeasurementModalDismiss}
              theme="bpp"
              original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, '3450-01_IP_Loop_Measurements.pdf')}
            />
          ) : null}
          {this.state.rework_modal ? (
            <Modal
              theme="bpp"
              preset="decision-rework-case"
              header_text={`Rework -  ${removeCaseIdInitialNumber(this.props.case_id)}`}
              message_text="Review the selected wires, then click transfer to send them to wire bending machine."
              confirm_btn_text="Transfer"
              checkbox_selections={this.state.selections}
              onConfirmButtonClick={this.onButtonClickRework}
              close_btn_text="Cancel"
              onCloseButtonClick={this.onRewokModalDismiss}
              onCheckedChange={this.toggleCheckbox}
              in_progress={this.state.api_pending}
              in_progress_text={this.state.in_progress_text}
            />
          ) : null}
          {this.state.gen_2_label_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={`${GEN_2_LABELS[this.state.selected_gen_2_label]} Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              pdf_url={selected_label_file.upload_data}
              pdf_date={selected_label_file.created_date}
              onCloseButtonClick={this.onGen2LabelModalDismiss}
              theme="bpp"
              original_filename={getPDFNameTemplate(
                this.props.case_id,
                this.props.patient_info,
                ` ${
                  this.state.selected_gen_2_label === 'smartwire'
                    ? `Label_Smartwire${smartwire_label_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                    : this.state.selected_gen_2_label === 'idb'
                    ? `Label_IDB${idb_label_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                    : this.state.selected_gen_2_label === 'bracket'
                    ? `Label_Brackets${bracket_label_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                    : this.state.selected_gen_2_label === 'singlesheet'
                    ? `Label_Single_Sheet${singlesheet_label_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                    : `Label_Outer_Patient${outer_patient_label_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                }`
              )}
            />
          ) : null}
          {this.props.initiate_wire_fabrication_review_modal && this.displayInitiateWireFabricationReviewModal()}
          {this.props.production_log_modal && this.displayProductionLogModal()}
          {this.props.refresh && <SessionExpire />}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    wire_fabrication_review_status: getWireFabricationReviewStatus(state),
    selected_production_stage: getSelectedProductionStage(state),
    initiate_wire_fabrication_review_modal: getInitiateWireFabricationReviewModal(state),
    production_log_modal: getProductionLogModal(state),
    wire_fabrication_review_status_log: getWireFabricationReviewStatusLog(state),
    refresh: getRefreshStatus(state),
    wire_manufacturing_status: getWireManufacturingStatus(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchWireFabricationReviewStatus: fetchWireFabricationReviewStatus,
      onProductionStageChange: onProductionStageChange,
      toggleInitiateWireFabricationReviewModal: toggleInitiateWireFabricationReviewModal,
      initiateWireFabricationReview: initiateWireFabricationReview,
      toggleProductionLogModal: toggleProductionLogModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductionContainer);
