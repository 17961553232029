import React from 'react';

import { convertInputDate } from '../../../../common/functions';

import {
  SET_SELECTED_DDM_SUBMITTED_DATE,
  SET_SELECTED_DDM_RECEIVED_DATE,
  SET_SELECTED_PRODUCTION_RECEIVED_DATE,
  OPEN_APPROVE_SHIPPING_MODAL,
  CLOSE_APPROVE_SHIPPING_MODAL,
  CLOSE_IR_APPROVE_SHIPPING_MODAL,
  APPROVE_SHIPPING_PENDING,
  APPROVE_SHIPPING_SUCCESS,
  APPROVE_SHIPPING_ERROR,
  APPROVE_IR_SHIPPING_PENDING,
  APPROVE_IR_SHIPPING_SUCCESS,
  APPROVE_IR_SHIPPING_ERROR,
  TOGGLE_MODAL,
  OPEN_INTERNATIONAL_MODAL,
  CLOSE_INTERNATIONAL_MODAL,
  OPEN_MARK_SHIPPED_MODAL,
  CLOSE_MARK_SHIPPED_MODAL,
  OPEN_IR_MARK_SHIPPED_MODAL,
  CLOSE_IR_MARK_SHIPPED_MODAL,
  SET_SELECTED_SHIP_DATE,
  MARK_SHIPPED_PENDING,
  MARK_SHIPPED_SUCCESS,
  MARK_SHIPPED_ERROR,
  MARK_SHIPPED_IR_SUCCESS,
  MARK_SHIPPED_IR_PENDING,
  MARK_SHIPPED_IR_ERROR,
  OPEN_SHIPPING_LABEL_MODAL,
  CLOSE_SHIPPING_LABEL_MODAL,
  FETCH_CASES_PENDING,
  FETCH_CASES_SUCCESS,
  FETCH_CASES_ERROR,
  UPDATE_MARK_SHIPPED_CASES,
  SET_SHIP_DATE_ERROR,
  BATCH_MARK_SHIPPED_SUCCESS,
  BATCH_MARK_SHIPPED_ERROR,
  BATCH_ALL_FAILED_MARK_SHIPPED_ERROR,
  OPEN_BATCH_LABELS_MODAL,
  CLOSE_BATCH_LABELS_MODAL,
  FETCH_COMPLETED_BATCHES_PENDING,
  FETCH_COMPLETED_BATCHES_SUCCESS,
  FETCH_COMPLETED_BATCHES_ERROR,
  OPEN_BATCH_LABELS_MODAL_FROM_LIST,
  OPEN_SESSION_EXPIRE_MODAL,
} from '../../../actions/bpp/shipping/shipping';

const initialState = {
  selected_ddm_submitted_date: '',
  selected_ddm_received_date: '',
  selected_production_received_date: '',
  approve_shipping_modal: false,
  approve_shipping_pending: false,
  international_shipping_modal: false,
  error: false,
  approve_shipping_completed: false,
  ir_approve_shipping_modal: false,
  ir_approve_shipping_pending: false,
  ir_approve_shipping_completed: false,
  approve_shipping_message: <div>Are you sure you want to approve this case for shipping?</div>,
  ir_approve_shipping_message: <div>Are you sure you want to approve this item request for shipping?</div>,
  mark_shipped_modal: false,
  selected_ship_date: getCurrentDate(),
  ir_mark_shipped_modal: false,
  ir_mark_shipped_pending: false,
  ir_selected_ship_date: '',
  mark_shipped_pending: false,
  mark_shipped_cases: [],
  selected_ireq_id: '',
  refresh: false,
  shipping_label_modal: false,
  all_cases: [],
  fetch_shipping_list_pending: false,
  fetch_shipping_list_completed: false,
  fetch_shipping_list_error: false,
  statuses: {
    approve_for_shipping: 0,
    mark_shipped: 0,
  },
  ship_date_error: false,
  batch_mark_shipped_error: false,
  batch_failed_ids: [],
  batch_all_failed_mark_shipped_error: false,
  batch_labels_modal: false,
  batch_labels_file: '',
  batches: [],
  only_non_automatic_cases_succeded: false,
  shipping_services: ['FedEx', 'UPS'],
  selected_case_id: '',
  selected_ireq: '',
  case_details: {},
  batch_mark_shipped_modal: false,
};

/**
 * This reducer is used for the shipping container in case_details and ir_details
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function shippingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_DDM_SUBMITTED_DATE:
      return {
        ...state,
        selected_ddm_submitted_date: action.selected_ddm_submitted_date,
      };

    case SET_SELECTED_DDM_RECEIVED_DATE:
      return {
        ...state,
        selected_ddm_received_date: action.selected_ddm_received_date,
      };

    case SET_SELECTED_PRODUCTION_RECEIVED_DATE:
      return {
        ...state,
        selected_production_received_date: action.selected_production_received_date,
      };
    
    case OPEN_INTERNATIONAL_MODAL:
      return {
        ...state,
        international_shipping_modal: true,
      };
    
    case CLOSE_INTERNATIONAL_MODAL:
      return {
        ...state,
        international_shipping_modal: false,
      };

    case OPEN_APPROVE_SHIPPING_MODAL:
      return {
        ...state,
        approve_shipping_modal: true,
      };

    case CLOSE_APPROVE_SHIPPING_MODAL:
      return {
        ...state,
        approve_shipping_modal: false,
        approve_shipping_completed: false,
        approve_shipping_message: <div>Are you sure you want to approve this case for shipping?</div>,
        error: false,
      };

    case CLOSE_IR_APPROVE_SHIPPING_MODAL:
      return {
        ...state,
        ir_approve_shipping_modal: false,
        ir_approve_shipping_completed: false,
        ir_approve_shipping_message: <div>Are you sure you want to approve this item request for shipping?</div>,
        error: false,
      };

    case APPROVE_SHIPPING_PENDING:
      return {
        ...state,
        approve_shipping_pending: true,
      };

    case APPROVE_SHIPPING_SUCCESS:
      return {
        ...state,
        approve_shipping_pending: false,
        approve_shipping_message: action.approve_shipping_message,
        approve_shipping_completed: true,
      };

    case APPROVE_SHIPPING_ERROR:
      return {
        ...state,
        approve_shipping_pending: false,
        error: action.error,
        approve_shipping_completed: true,
        refresh: action.refresh,
        approve_shipping_message: action.approve_shipping_message,
      };

    case APPROVE_IR_SHIPPING_PENDING:
      return {
        ...state,
        ir_approve_shipping_pending: true,
      };

    case APPROVE_IR_SHIPPING_SUCCESS:
      return {
        ...state,
        ir_approve_shipping_pending: false,
        ir_approve_shipping_message: action.approve_shipping_message,
        ir_approve_shipping_completed: true,
      };

    case APPROVE_IR_SHIPPING_ERROR:
      return {
        ...state,
        ir_approve_shipping_pending: false,
        error: action.error,
        ir_approve_shipping_completed: true,
        refresh: action.refresh,
        ir_approve_shipping_message: action.ir_approve_shipping_message,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        [`${action.modal_name}`]: action.modal_value,
      };

    case OPEN_MARK_SHIPPED_MODAL:
      return {
        ...state,
        mark_shipped_modal: true,
        selected_ship_date: action.selected_ship_date,
        mark_shipped_cases: action.mark_shipped_cases,
        case_details: action.case_details,
        selected_case_id: action.selected_case_id,
        batch_mark_shipped_modal: action.batch_mark_shipped_modal,
      };

    case CLOSE_MARK_SHIPPED_MODAL:
      return {
        ...state,
        mark_shipped_modal: false,
        error: false,
        ship_date_error: false,
        mark_shipped_cases: action.mark_shipped_cases,
        batch_all_failed_mark_shipped_error: false,
        batch_mark_shipped_error: false,
      };

    case OPEN_IR_MARK_SHIPPED_MODAL:
      return {
        ...state,
        ir_mark_shipped_modal: true,
        selected_ship_date: action.selected_ship_date,
        mark_shipped_cases: action.mark_shipped_cases,
        case_details: action.case_details,
        selected_ireq: action.selected_ireq,
      };

    case CLOSE_IR_MARK_SHIPPED_MODAL:
      return {
        ...state,
        ir_mark_shipped_modal: false,
        error: false,
        ship_date_error: false,
        mark_shipped_cases: action.mark_shipped_cases,
        batch_all_failed_mark_shipped_error: false,
        batch_mark_shipped_error: false,
      };

    case SET_SELECTED_SHIP_DATE:
      return {
        ...state,
        selected_ship_date: action.selected_ship_date,
        ship_date_error: false,
        mark_shipped_cases: action.mark_shipped_cases,
      };

    case MARK_SHIPPED_PENDING:
      return {
        ...state,
        mark_shipped_pending: true,
      };

    case MARK_SHIPPED_SUCCESS:
      return {
        ...state,
        mark_shipped_pending: false,
        mark_shipped_modal: false,
        mark_shipped_cases: [],
      };

    case MARK_SHIPPED_ERROR:
      return {
        ...state,
        mark_shipped_pending: false,
        error: action.error,
        refresh: action.refresh,
      };

    case BATCH_MARK_SHIPPED_SUCCESS:
      return {
        ...state,
        mark_shipped_pending: false,
        batch_labels_file: action.batch_labels_file,
        mark_shipped_modal: false,
        mark_shipped_cases: [],
      };

    case BATCH_MARK_SHIPPED_ERROR:
      return {
        ...state,
        mark_shipped_pending: false,
        batch_mark_shipped_error: true,
        batch_failed_ids: action.batch_failed_ids,
        batch_labels_file: action.batch_labels_file,
        only_non_automatic_cases_succeded: action.only_non_automatic_cases_succeded,
        mark_shipped_cases: action.mark_shipped_cases,
      };

    case BATCH_ALL_FAILED_MARK_SHIPPED_ERROR:
      return {
        ...state,
        mark_shipped_pending: false,
        batch_all_failed_mark_shipped_error: true,
      };

    case MARK_SHIPPED_IR_PENDING:
      return {
        ...state,
        ir_mark_shipped_pending: true,
      };

    case MARK_SHIPPED_IR_SUCCESS:
      return {
        ...state,
        ir_mark_shipped_pending: false,
        ir_mark_shipped_modal: false,
        mark_shipped_cases: [],
      };

    case MARK_SHIPPED_IR_ERROR:
      return {
        ...state,
        ir_mark_shipped_pending: false,
        error: action.error,
        refresh: action.refresh,
      };

    case OPEN_SHIPPING_LABEL_MODAL:
      return {
        ...state,
        shipping_label_modal: true,
      };

    case CLOSE_SHIPPING_LABEL_MODAL:
      return {
        ...state,
        shipping_label_modal: false,
      };

    case FETCH_CASES_PENDING:
      return {
        ...state,
        fetch_shipping_list_pending: true,
      };

    case FETCH_CASES_SUCCESS:
      return {
        ...state,
        fetch_shipping_list_pending: false,
        fetch_shipping_list_completed: true,
        all_cases: action.all_cases,
        statuses: action.statuses,
      };

    case FETCH_CASES_ERROR:
      return {
        ...state,
        fetch_shipping_list_pending: false,
        fetch_shipping_list_completed: true,
        fetch_shipping_list_error: true,
      };

    case UPDATE_MARK_SHIPPED_CASES:
      return {
        ...state,
        mark_shipped_cases: action.mark_shipped_cases_updates,
      };

    case SET_SHIP_DATE_ERROR:
      return {
        ...state,
        ship_date_error: true,
      };

    case OPEN_BATCH_LABELS_MODAL:
      return {
        ...state,
        batch_labels_modal: true,
      };

    case CLOSE_BATCH_LABELS_MODAL:
      return {
        ...state,
        batch_labels_modal: false,
      };

    case FETCH_COMPLETED_BATCHES_PENDING:
      return {
        ...state,
        fetch_shipping_list_pending: true,
      };

    case FETCH_COMPLETED_BATCHES_SUCCESS:
      return {
        ...state,
        fetch_shipping_list_pending: false,
        batches: action.batches,
      };

    case FETCH_COMPLETED_BATCHES_ERROR:
      return {
        ...state,
        fetch_shipping_list_pending: false,
        fetch_shipping_list_error: true,
      };

    case OPEN_BATCH_LABELS_MODAL_FROM_LIST:
      return {
        ...state,
        batch_labels_modal: true,
        batch_labels_file: action.batch_labels_file,
      };

    case OPEN_SESSION_EXPIRE_MODAL:
      return {
        ...state,
        refresh: true,
      };
    default:
      return state;
  }
}

/**
 * Gets the current date used as the default for the ship date picker
 * @function
 * @return {String} Current date in the correct format
 */
export function getCurrentDate() {
  let date = new Date();
  return convertInputDate(date.toISOString());
}

export const getSelectedDDMSubmittedDate = (state) => state.shippingReducer.selected_ddm_submitted_date;
export const getSelectedDDMReceivedDate = (state) => state.shippingReducer.selected_ddm_received_date;
export const getSelectedProductionReceivedDate = (state) => state.shippingReducer.selected_production_received_date;
export const getApproveShippingModal = (state) => state.shippingReducer.approve_shipping_modal;
export const getApproveShippingPending = (state) => state.shippingReducer.approve_shipping_pending;
export const getApproveShippingMessage = (state) => state.shippingReducer.approve_shipping_message;
export const getApproveShippingCompleted = (state) => state.shippingReducer.approve_shipping_completed;
export const getIrApproveShippingModal = (state) => state.shippingReducer.ir_approve_shipping_modal;
export const getIrApproveShippingPending = (state) => state.shippingReducer.ir_approve_shipping_pending;
export const getIrApproveShippingMessage = (state) => state.shippingReducer.ir_approve_shipping_message;
export const getIrApproveShippingCompleted = (state) => state.shippingReducer.ir_approve_shipping_completed;
export const getMarkShippedModal = (state) => state.shippingReducer.mark_shipped_modal;
export const getSelectedShipDate = (state) => state.shippingReducer.selected_ship_date;
export const getIrMarkShippedModal = (state) => state.shippingReducer.ir_mark_shipped_modal;
export const getIrMarkShippedPending = (state) => state.shippingReducer.ir_mark_shipped_pending;
export const getIrSelectedShipDate = (state) => state.shippingReducer.ir_selected_ship_date;
export const getMarkShippedPending = (state) => state.shippingReducer.mark_shipped_pending;
export const getMarkShippedCases = (state) => state.shippingReducer.mark_shipped_cases;
export const getRefresh = (state) => state.shippingReducer.refresh;
export const getShippingLabelModal = (state) => state.shippingReducer.shipping_label_modal;
export const getError = (state) => state.shippingReducer.error;
export const getAllCases = (state) => state.shippingReducer.all_cases;
export const getFetchShippingListCompleted = (state) => state.shippingReducer.fetch_shipping_list_completed;
export const getFetchShippingListError = (state) => state.shippingReducer.fetch_shipping_list_error;
export const getStatuses = (state) => state.shippingReducer.statuses;
export const getShipDateError = (state) => state.shippingReducer.ship_date_error;
export const getBatchMarkShippedError = (state) => state.shippingReducer.batch_mark_shipped_error;
export const getBatchFailedIds = (state) => state.shippingReducer.batch_failed_ids;
export const getBatchAllFailedMarkShippedError = (state) => state.shippingReducer.batch_all_failed_mark_shipped_error;
export const getBatchLabelsModal = (state) => state.shippingReducer.batch_labels_modal;
export const getBatchLabelsFile = (state) => state.shippingReducer.batch_labels_file;
export const getBatches = (state) => state.shippingReducer.batches;
export const getOnlyNonAutomaticCasesSucceeded = (state) => state.shippingReducer.only_non_automatic_cases_succeded;
export const getShippingServices = (state) => state.shippingReducer.shipping_services;
export const getInternationalModal = (state) => state.shippingReducer.international_shipping_modal;
