// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Axios from 'axios';
import { handleHttpRequestError } from '../../../common/error';
import { capitalizeFirstLetters } from '../../../common/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { hasEmoji } from '../../../common/functions';
import { BETA, PILOT, FULL_RELEASE } from '../../../common/constants/programs';

export const DISABLED_PROGRAMS = ['epp'];

export const SETTINGS_INFO = [
  {
    type: 'name',
    name: 'Name',
    description: 'Program Name',
    field_type: 'title',
    display: false,
    required: true,
    max_length: 50,
    place_holder: 'Enter program name',
  },
  {
    type: 'description',
    name: 'Description',
    description: 'Description',
    field_type: 'textArea',
    display: true,
    required: true,
    max_length: 1000,
    place_holder: 'Enter description',
  },
  {
    type: 'auto_enroll_doctor',
    name: 'Auto-Enable Program',
    description: 'Auto-Enable Program',
    field_type: 'radioButton',
    options: [
      { name: 'Enable program upon provider account creation', value: true },
      { name: 'Do not enable program upon provider account creation', value: false },
    ],
    display: true,
    required: true,
  },
  {
    type: 'program_type',
    name: 'Program Type',
    description: 'Program Type',
    field_type: 'checkbox',
    option: { name: 'Custom Wire', value: 'custom_wire' },
    display: true,
    required: false,
  },
  {
    type: 'tags',
    name: 'Tags',
    description: 'Program Tags',
    field_type: 'multiselect',
    max_length: 20,
    display: true,
    required: false,
    place_holder: 'Add tag',
  },
  {
    type: 'tags_on_case_creation',
    name: 'Tags on Case Creation',
    description: 'Program Tags on Case Creation',
    max_length: 20,
    field_type: 'multiselect',
    display: true,
    required: false,
    place_holder: 'Add tag',
  },
  { type: 'levels', name: 'Level', description: 'Program Level', hidden_field: true, required: false },
];

/**
 * Displays program settings
 * @alias ProgramDetails
 * @component
 * @category BPP
 * @subcategory ProgramDetails
 */
class ProgramDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      tags_on_case_creation: [],
      error_field: '',
      error_char_limit: false,
      error_3_tags_max: false,
      error_no_space_in_field: false,
      error_no_upper_case: false,
      error_has_emoji: false,
      error_messages: [],
    };
  }

  componentDidMount() {
    if (this.props.create_mode || this.props.edit_mode) {
      this.loadTags();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.create_mode && this.props.create_mode !== prevProps.create_mode) ||
      (this.props.edit_mode && this.props.edit_mode !== prevProps.edit_mode)
    ) {
      this.loadTags();
    }
    if (!_.isEqual(this.props.invalid_settings, prevProps.invalid_settings)) {
      this.setState({ error_messages: this.props.invalid_settings });
    }
  }

  /**
   * Display temprary error message
   * @function
   * @param error_state_key {object} - key to show /
   * @param field_value {any}  - Value to update parent state
   */
  displayTempraryErrorMessage = (error_state_key, error_field) => {
    this.setState({ [error_state_key]: true, error_field: error_field });
    setTimeout(() => {
      this.setState({ [error_state_key]: false, error_field: '' });
    }, 2000);
  };

  /**
   * Make a request to list all the tags and update the state tags
   * @function
   */
  showErrorMessage = (error) => {
    return (
      <div className="error-message-container">
        <FontAwesomeIcon icon={faCircleExclamation} className="error-exclamation-icon" />
        {error}
      </div>
    );
  };

  /**
   * Make a request to list all the tags and update the state tags
   * @function
   */
  loadTags = () => {
    const that = this;
    Axios.get('/apiv3/tag')
      .then(function (res) {
        const tags = res.data.tags.filter((tag) => tag.tag_on_case_creation === false).map((tag) => ({ value: tag.tag_name, label: tag.tag_name }));
        const tags_on_case_creation = res.data.tags
          .filter((tag) => tag.tag_on_case_creation === true)
          .map((tag) => ({ value: tag.tag_name, label: tag.tag_name }));
        that.setState({
          tags: tags,
          tags_on_case_creation: tags_on_case_creation,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * When program in program_settings.js
   * @function
   * @param field_name {string} - Field name to be updated in parent state
   * @param field_value {string}  - Field value to be updated in parent state
   */
  updateProgram = (field_name, field_value) => {
    if (this.props.create_mode) {
      this.props.setProgramCreateState(field_name, field_value);
    } else {
      this.props.setProgramsUpdateState(field_name, field_value);
    }
  };

  /**
   * When radio field is changed, update its state
   * @function
   * @param info {object} - setting entry from SETTINGS /
   * @param field_value {any}  - Value to update parent state
   */
  onChangeInputField = (info, field_value) => {
    if (field_value && hasEmoji(field_value)) {
      this.displayTempraryErrorMessage('error_has_emoji', info.type);
      return;
    }
    this.updateProgram(info.type, field_value);
    const error_messages = this.state.error_messages?.length ? [...this.state.error_messages].filter((error) => Object.keys(error)[0] !== info.type) : [];
    this.setState({ error_messages });
  };

  /**
   * Displays input for program fields input (text or textarea)
   * @function
   * @param program {object} - program to be updated
   * @param info {string} - setting entry from SETTINGS /
   * @param is_text_area {boolean} - indicates to show textarea instead of an input
   * @return {JSX} - JSX for program fields input
   */
  getInputField = (program, info, is_text_area) => {
    const max_length = info.max_length;
    const field_value = program[info.type];
    const field_name = info.type;
    const invalid_setting = this.state.error_messages.filter((setting) => field_name in setting);
    let error_message = invalid_setting.length ? invalid_setting[0][field_name] : '';
    const input_props = {
      type: is_text_area ? 'textarea' : 'text',
      className: `form-control input${error_message ? '__error' : ''}`,
      'data-type': field_name,
      value: field_value,
      onChange: (e) => this.onChangeInputField(info, e.target.value),
      maxLength: max_length,
      disabled: info.edit_disabled && !this.props.create_mode,
      placeholder: info.place_holder,
    };
    const InputComponent = is_text_area ? 'textarea' : 'input';
    return (
      <>
        <div className="input">
          <InputComponent {...input_props} />
          {error_message && this.showErrorMessage(error_message)}
        </div>
      </>
    );
  };

  /**
   * Retrieves currently selected program or a new program when create_mode
   * @function
   * @return {object} - Program object or null
   */
  getProgram = () => {
    if (this.props.create_mode) {
      return this.props.program_create;
    }
    const programs = this.props.edit_mode ? this.props.programs_update : this.props.programs;
    for (const program of programs) {
      if (program.program_id === this.props.selected_program_id) {
        return program;
      }
    }
    return null;
  };

  /**
   * When MultiSelect field is changed, update its state
   * @function
   * @param program - Program to be updated
   * @param selected_tags - All selected tags from select component
   * @param info {object} - setting entry from SETTINGS /
   *
   */
  onChangeMultiSelect = (program, selected_tags, info) => {
    if (!selected_tags) selected_tags = [];
    if (['tags', 'tags_on_case_creation'].includes(info.type)) {
      let total = selected_tags.length;
      if (info.type === 'tags') {
        total += program['tags_on_case_creation'].length;
      } else {
        total += program['tags'].length;
      }
      if (total > 3) {
        this.displayTempraryErrorMessage('error_3_tags_max', info.type);
        return;
      }
    }
    this.updateProgram(info.type, selected_tags);
  };

  /**
   * When creating a MultiSelect option, update program tags and add to the list of tags
   * @function
   * @param program {object} - program to be updated
   * @param tag {object} - tag name to be created
   * @param info {object} - setting entry from SETTINGS /
   */
  onCreateOption = (program, tag, info) => {
    if (['tags', 'tags_on_case_creation'].includes(info.type)) {
      if (tag.includes(' ')) {
        this.displayTempraryErrorMessage('error_no_space_in_field', info.type);
        return;
      }
      if (program['tags'].length + program['tags_on_case_creation'].length >= 3) {
        this.displayTempraryErrorMessage('error_3_tags_max', info.type);
        return;
      }
      if (hasEmoji(tag)) {
        this.displayTempraryErrorMessage('error_has_emoji', info.type);
        return;
      }
      if (tag.length > 20) {
        this.displayTempraryErrorMessage('error_char_limit', info.type);
        return;
      }
      const new_option = { value: tag, label: tag };
      if (info.type === 'tags') {
        const tags = [...program.tags, new_option];
        this.updateProgram(info.type, tags);
        this.setState((prev_state) => {
          return {
            tags: [...prev_state.tags, new_option],
          };
        });
      } else {
        const tags_on_case_creation = [...program.tags_on_case_creation, new_option];
        this.updateProgram(info.type, tags_on_case_creation);
        this.setState((prev_state) => {
          return {
            tags_on_case_creation: [...prev_state.tags_on_case_creation, new_option],
          };
        });
      }
    }
  };

  /**
   * Get the JSX readio field
   * @function
   * @param info {object} - setting entry from SETTINGS
   * @return {array} - Array of tags
   */
  getMultiSelectOptions = (info) => {
    if (info.type === 'tags') {
      return this.state.tags;
    }
    if (info.type === 'tags_on_case_creation') {
      return this.state.tags_on_case_creation;
    }
  };
  /**
   * Get the JSX readio field
   * @function
   * @param program {object} - program to be updated
   * @param info {object} - setting entry from SETTINGS
   * @param is_edit_mode {boolean} - indicate if the field is to be updated
   * @return {JSX.Element} - JSX for program radio field input
   */
  getMultiSelectField = (program, info, is_edit_mode) => {
    const invalid_setting = this.props.invalid_settings.filter((setting) => info.type in setting);
    const error_message = invalid_setting.length ? invalid_setting[0][info.type] : '';
    if (is_edit_mode) {
      return (
        <div className="input">
          <CreatableSelect
            isMulti
            value={program[info.type]}
            onChange={(selected_tags) => this.onChangeMultiSelect(program, selected_tags, info)}
            onCreateOption={(input_value) => this.onCreateOption(program, input_value, info)}
            options={this.getMultiSelectOptions(info)}
            isDisabled={!is_edit_mode}
            placeholder={info.place_holder}
          />
          {error_message && this.showErrorMessage(error_message)}
          {this.state.error_3_tags_max && this.state.error_field === info.type && this.showErrorMessage('3 tags max')}
          {this.state.error_no_space_in_field && this.state.error_field === info.type && this.showErrorMessage('Space not allowed')}
          {this.state.error_no_upper_case && this.state.error_field === info.type && this.showErrorMessage('Upper case letters not allowed')}
          {this.state.error_char_limit && this.state.error_field === info.type && this.showErrorMessage('The character limit has been reached')}
          {this.state.error_has_emoji && this.state.error_field === info.type && this.showErrorMessage('Emoji not allowed')}
        </div>
      );
    } else {
      if (program[info.type].length) {
        return (
          <div className="display-tags">
            {program[info.type].map((tag, index) => (
              <div key={index} className="display-tag">
                {tag.value}
              </div>
            ))}
          </div>
        );
      } else {
        return <div>N/A</div>;
      }
    }
  };

  /**
   * When radio field is changed, update its state
   * @function
   * @param info {object} - setting entry from SETTINGS /
   * @param option {object}  - Radio option selected
   */
  onChangeRadio = (info, option) => {
    this.updateProgram(info.type, option.value);
  };

  /**
   * Get the JSX readio field
   * @function
   * @param program {object} - program to be updated
   * @param info {object} - setting entry from SETTINGS
   * @param is_edit_mode {boolean} - indicate if the field is to be updated
   * @return {JSX.Element} - JSX for program radio field input
   */
  getRadioField = (program, info, is_edit_mode) => {
    return (
      <div className="option-container">
        {info.options.map((option, optionIndex) => (
          <label key={optionIndex}>
            <input
              type="radio"
              name={option.type}
              checked={program[info.type] === option.value}
              onChange={() => this.onChangeRadio(info, option)}
              disabled={!is_edit_mode}
            />
            {option.name}
          </label>
        ))}
      </div>
    );
  };

  /**
   * When Checkbox field is changed, update its state
   * @function
   * @param program {object} - program to be updated
   * @param info {object} - setting entry from SETTINGS /
   */
  onChangeCheckbox = (program, info) => {
    if (program[info.type] === info.option.value) {
      this.updateProgram(info.type, '');
    } else {
      this.updateProgram(info.type, info.option.value);
    }
  };

  /**
   * Get the JSX radio field
   * @function
   * @param program {object} - program to be updated
   * @param info {object} - setting entry from SETTINGS
   * @param is_edit_mode {boolean} - indicate if the field is to be updated
   * @return {JSX.Element} - JSX for program checkbox field input
   */
  getCheckboxField = (program, info, is_edit_mode) => {
    return (
      <div className="option-container">
        <label>
          <input
            type="checkbox"
            name={info.type}
            checked={program[info.type] === info.option.value}
            onChange={() => this.onChangeCheckbox(program, info)}
            disabled={!is_edit_mode}
          />
          {info.option.name}
        </label>
      </div>
    );
  };

  /**
   * call parent showUpgradeModal with the correct level
   * @function
   * @param program {object} - program to be updated
   */
  upgradeProgram = (program) => {
    const level = program.levels.current === BETA ? PILOT : FULL_RELEASE;
    this.props.showUpgradeModal(level);
  };

  /**
   * Displays all individual setting field
   * @function
   * @return {JSX.Element} - JSX for setting
   */
  displaySetting = () => {
    let program = this.getProgram();
    const is_edit_mode = this.props.edit_mode;

    const filtered_settings = SETTINGS_INFO.filter((entry) => (entry.display !== false || is_edit_mode) && !entry.hidden_field);
    return filtered_settings.map((info, index) => {
      const is_radio_button = info.field_type === 'radioButton';
      const is_checkbox = info.field_type === 'checkbox';
      const is_text_area = info.field_type === 'textArea';
      const is_multiselect = info.field_type === 'multiselect';

      let content;
      if (is_radio_button) {
        content = this.getRadioField(program, info, is_edit_mode);
      } else if (is_checkbox) {
        content = this.getCheckboxField(program, info, is_edit_mode);
      } else if (is_multiselect) {
        content = this.getMultiSelectField(program, info, is_edit_mode);
      } else if (is_edit_mode) {
        content = this.getInputField(program, info, is_text_area);
      } else {
        content = <div className={is_text_area ? 'display_text_area' : 'display'}>{program[info.type]}</div>;
      }
      if (this.props.create_mode) {
        return (
          <div key={index} className="program-details-create">
            <label className="create">{`${info.description}${info.required ? '*' : ''}`}</label>
            {content}
          </div>
        );
      } else {
        return (
          <div key={index} className="program-details">
            <div className="bold-text settings-label">{`${info.description}${this.props.edit_mode ? (info.required ? '*' : '') : ''}`}</div>
            {content}
          </div>
        );
      }
    });
  };

  /**
   * Checks if it is a disable program.
   *
   * @function
   * @return {boolean} - `true` if button should be disabled, `false` otherwise.
   */
  isDisabledProgram = () => {
    return DISABLED_PROGRAMS.includes(this.getProgram()?.program_id);
  };

  /**
   * Checks if the button should be disabled based on the program's status.
   *
   * @function
   * @return {boolean} - `true` if button should be disabled, `false` otherwise.
   */
  isButtonDisabled = () => {
    return !this.getProgram()?.current_ind;
  };

  /**
   * Displays settings buttons excpet for create_mode
   * @function
   * @return {false|JSX.Element} - JSX for buttons
   */
  displaySettingsButtons = (selected_program) => {
    const enableDisableButtonText = this.getProgram()?.current_ind ? 'Disable' : 'Enable';
    return (
      !this.props.create_mode &&
      !(this.getProgram()?.levels?.current?.toLowerCase() === FULL_RELEASE) && (
        <div className="btn-container">
          {this.props.edit_mode ? (
            <>
              <button className="btn btn-light" onClick={() => this.props.onEditSave()}>
                Save
              </button>
              <button className="btn btn-light" onClick={this.props.onEditCancel}>
                Cancel
              </button>
            </>
          ) : (
            this.props.has_program_edit_permission && (
              <>
                <button className="btn btn-light btn-edit" onClick={() => this.props.showEnableDisableModal()} disabled={this.isDisabledProgram()}>
                  {enableDisableButtonText}
                </button>
                <button
                  className="btn btn-light btn-edit"
                  disabled={this.isButtonDisabled() || this.isDisabledProgram()}
                  onClick={() => this.props.toggleEditMode(true)}
                >
                  Edit
                </button>
                <button
                  className="btn-upgrade btn btn-light"
                  disabled={this.isButtonDisabled() || this.isDisabledProgram()}
                  onClick={() => this.upgradeProgram(selected_program)}
                >
                  Upgrade to {capitalizeFirstLetters(selected_program?.levels?.current === BETA ? PILOT : FULL_RELEASE)}
                </button>
              </>
            )
          )}
        </div>
      )
    );
  };

  render() {
    const selected_program = this.getProgram();
    return (
      <>
        {this.props.create_mode ? (
          <div className="create-title-box">Please complete the fields below</div>
        ) : (
          <div className={`bold-text ${this.props.create_mode ? '' : 'business-dark-heading'}`}>
            {selected_program.name}
            {selected_program?.levels?.current && ` (${capitalizeFirstLetters(selected_program.levels.current)})`}
          </div>
        )}
        {
          <div className={`${this.props.create_mode ? '' : 'business-dark-theme'}`}>
            {this.displaySetting()}
            {this.displaySettingsButtons(selected_program)}
          </div>
        }
      </>
    );
  }
}
export default ProgramDetails;
