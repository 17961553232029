import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@material-ui/core';
import classNames from 'classnames';

import ManualWireTableOffset from './manual_wire_table_offset/manual_wire_table_offset';
import ManualWireTableLength from './manual_wire_table_length/manual_wire_table_length';
import ManualWireTableLocket from './manual_wire_table_locket/manual_wire_table_locket';
import ManualWireTableLoop from './manual_wire_table_loop/manual_wire_table_loop';
import ManualWireTableHeader from './manual_wire_table_header/manual_wire_table_header';
import './manual_wire_table.scss';

const ManualWireTable = ({ disableAllFields, showGeneratedLentgh, arch, missingTeeths, range }) => {
    const tableWidth = 130.16 * ((range[1] - range[0]) - missingTeeths.filter((t) => !isNaN(t)).length + 2);
    const width = tableWidth >= window.innerWidth ? "100vw" : 'auto';
    const withGeneratedLengthClass = {'with-generated-length': showGeneratedLentgh};
    const tableHeaderClass = classNames('manual-wire-table-header', withGeneratedLengthClass);
    const tableContentClass = classNames('manual-wire-table-content', withGeneratedLengthClass);

    return (
        <Box className='manual-wire-table' style={{ width: `${width}`}}>
            <Box className={tableHeaderClass} sx={{ position: 'sticky', left: 0, zIndex: 1 }}>
                <Box  className='header-item'></Box>
                <Box  className='header-item'>Locket</Box>
                <Box  className='header-item loop-item'>Loop</Box>
                <Box className='header-item' component="div">
                    <Box className='header-offset-item' component="div">Offset [X,Y] (mm)</Box>
                </Box>
                <Box className='header-item length-item' component="div">Length (mm)</Box>
                {showGeneratedLentgh && <Box className='header-item length-item' component="div">Generated Length (mm)</Box>}
            </Box>

            <Box className={tableContentClass} sx={{ overflowX: 'auto' }}>
                <ManualWireTableHeader arch={arch} missingTeeths={missingTeeths} range={range} />
                <ManualWireTableLocket arch={arch} missingTeeths={missingTeeths} range={range} disableAllFields={disableAllFields} />
                <ManualWireTableLoop arch={arch} missingTeeths={missingTeeths} range={range} disableAllFields={disableAllFields} />
                <ManualWireTableOffset arch={arch} missingTeeths={missingTeeths} range={range} disableAllFields={disableAllFields} />
                <ManualWireTableLength arch={arch} missingTeeths={missingTeeths} range={range} disableAllFields={disableAllFields} generatedLength={false}/>
                {showGeneratedLentgh && <ManualWireTableLength arch={arch} missingTeeths={missingTeeths} range={range} disableAllFields={true} generatedLength={true}/>}
            </Box>
        </Box>
    );
}

const mapStateToProps = (state) => {
    const arch = state.manualWireReducer.arch;
	const missingTeeths = state.manualWireReducer[arch].missing;
	const range = state.manualWireReducer[arch].range;
    return {
        arch,
        missingTeeths,
        range
    }
}

export default connect(mapStateToProps)(ManualWireTable);
