import {
  SET_EDIT_SMILE_DESIGN_TARGET_DATE,
  SET_SMILE_DESIGN_TARGET_DATE,
  SET_EXPEDITE_DETAILS_SESSION_EXPIRED,
  SET_APPLIANCE_DESIGN_TARGET_DATE_INPUT,
  SET_PREFERRED_SHIPPING_INPUT,
} from '../../../actions/bpp/shipping/expedite_details';

const initialState = {
  edit_smile_design_target_date: false,
  smile_design_target_date_input: '',
  session_expired: false,
  appliance_design_target_date_input: '',
  preferred_shipping_input: '',
};

/**
 * This reducer is used for the expedite details modal
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function expediteDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_SMILE_DESIGN_TARGET_DATE:
      return {
        ...state,
        edit_smile_design_target_date: action.edit_smile_design_target_date,
        smile_design_target_date_input: action.smile_design_target_date_input,
      };

    case SET_SMILE_DESIGN_TARGET_DATE:
      return {
        ...state,
        smile_design_target_date_input: action.smile_design_target_date_input,
      };

    case SET_EXPEDITE_DETAILS_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: true,
      };

    case SET_APPLIANCE_DESIGN_TARGET_DATE_INPUT:
      return {
        ...state,
        appliance_design_target_date_input: action.appliance_design_target_date_input,
      };

    case SET_PREFERRED_SHIPPING_INPUT:
      return {
        ...state,
        preferred_shipping_input: action.preferred_shipping_input,
      };

    default:
      return state;
  }
}

export const getEditSmileDesignTargetDate = (state) => state.expediteDetailsReducer.edit_smile_design_target_date;
export const getSmileDesignTargetDateInput = (state) => state.expediteDetailsReducer.smile_design_target_date_input;
export const getExpediteDetailsSessionExpired = (state) => state.expediteDetailsReducer.session_expired;
export const getApplianceDesignTargetDateInput = (state) => state.expediteDetailsReducer.appliance_design_target_date_input;
export const getPreferredShippingInput = (state) => state.expediteDetailsReducer.preferred_shipping_input;
