import React, { useRef } from 'react';
import * as RS from 'reactstrap';
import { components } from 'react-select';

/**
 * Displays options in the dropdown menu
 * @component
 * @category IPP
 */
function CaseListDropdownItem(props) {
  const { label, submenu, onClick, hide, alt } = props.data;
  const ref = useRef(null);

  return (
    !hide &&
    (submenu ? (
      <div>
        <components.Option {...props}>
          {label}
          <i className="fa fa-chevron-right pull-right" aria-hidden ref={ref} />
        </components.Option>
        {props.innerRef ? (
          <RS.Popover
            name="popover"
            placement="right"
            className="tend-location-popover"
            isOpen={Boolean(props.innerRef)}
            modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
            target={ref.current}
          >
            <RS.PopoverBody>
              <ul className="tend-submenu">
                {submenu.length > 0 ? (
                  submenu.map((item) =>
                    !item.hide ? (
                      <li key={item.value} onClick={() => onClick(item)}>
                        {item.label}
                      </li>
                    ) : null
                  )
                ) : (
                  <li>{alt}</li>
                )}
              </ul>
            </RS.PopoverBody>
          </RS.Popover>
        ) : null}
      </div>
    ) : (
      <components.Option {...props} />
    ))
  );
}

export default CaseListDropdownItem;
