import { accountAddReducer } from './ipp/add_account';
import { accountSettingsReducer } from './ipp/account_settings';
import { addAccountReducer } from './bpp/account/add_account';
import { awbReducer } from './awb';
import { caseDetailsReducer } from './ipp/case_details';
import { caseFileReducer } from './ipp/case_details/case_files';
import { caseFilterPreferencesModalReducer } from './case_filter_preferences_modal';
import { caseFilterPreferencesReducer } from './case_filter_preferences';
import { caseListFilterCountReducer } from './bpp/case_list/case_list_filter';
import { caseListReducer } from './ipp/case_list';
import { caseListTableReducer } from './bpp/case_list/case_list_table';
import { combineReducers } from 'redux';
import { commonCaseDetailsReducer } from './common/common_case_details';
import { dashboardReducer } from './ipp/dashboard';
import { ddmGenerateReducer } from './ddm/ddm_generate';
import { ddmResultReducer } from './ddm/ddm_result';
import { ddmStateReducer } from './ddm/ddm_state';
import { ddmUploadReducer } from './ddm/ddm_upload';
import { doctorCaseListFilterCountReducer } from './ipp/case_list/case_list_filter';
import { doctorCaseListTableReducer } from './ipp/case_list/case_list_table';
import { editAccountReducer } from './ipp/edit_account';
import { expediteDetailsReducer } from './bpp/shipping/expedite_details';
import { featureAnnouncementReducer } from './ipp/feature_announcement';
import { feedbackFormReducer } from './ipp/feedback_form';
import { feedbackRatingFormReducer } from './bpp/feedback_rating_form';
import { helpSidebarReducer } from './ipp/help_sidebar';
import { ifuGenerationReducer } from './ifu/ifu_generation';
import { ifuResultReducer } from './ifu/ifu_result';
import { ifuStateReducer } from './ifu/ifu_state';
import { ifuUploadReducer } from './ifu/ifu_upload';
import { iteroFilesReducer } from './ipp/itero/itero_files';
import { iteroLicenseNumbersReducer } from './ipp/itero/itero_license_numbers';
import { manualWireReducer } from './manual_wire/manual_wire';
import { practiceLogoReducer } from './ipp/practice_logo';
import { productTourReducer } from './product_tour/product_tour';
import { productionTxGuideReducer } from './bpp/production_tx_guide/production_tx_guide';
import { profilePictureDraftReducer } from './ipp/profile_picture_draft';
import { programsReducer } from './programs';
import { prospectsReducer } from './prospects/prospects';
import { recordViewerReducer } from './record_viewer/record_viewer';
import { segmentationProductionTxGuideReducer } from './bpp/production_tx_guide/segmentation';
import { segmentationReducer } from './bpp/case_details/segmentation';
import { setupViewerReducer } from './setup_viewer/setup_viewer';
import { shippingReducer } from './bpp/shipping/shipping';
import { shippoReducer } from './shippo/shippo';
import { singleToothIdbReducer } from './bpp/single_tooth_idb/single_tooth_idb';
import { smileDesignProductionTxGuideReducer } from './bpp/production_tx_guide/smile_design';
import { smilesReducer } from './smiles_simulation/smiles_simulation';
import { stage1ProductionTxGuideReducer } from './bpp/production_tx_guide/stage_1';
import { teethChartReducer } from './common/teeth_chart';
import { wasmViewerReducer } from './wasm_viewer/wasm_viewer';
import { wireFabricationReviewReducer } from './bpp/wire_selection/wire_fabrication_review';
import { wireSelectionReducer } from './bpp/wire_selection/wire_selection';

export default combineReducers({
  accountAddReducer,
  accountSettingsReducer,
  addAccountReducer,
  awbReducer,
  caseDetailsReducer,
  caseFileReducer,
  caseFilterPreferencesModalReducer,
  caseFilterPreferencesReducer,
  caseListFilterCountReducer,
  caseListReducer,
  caseListTableReducer,
  commonCaseDetailsReducer,
  dashboardReducer,
  ddmGenerateReducer,
  ddmResultReducer,
  ddmStateReducer,
  ddmUploadReducer,
  doctorCaseListFilterCountReducer,
  doctorCaseListTableReducer,
  editAccountReducer,
  expediteDetailsReducer,
  featureAnnouncementReducer,
  feedbackFormReducer,
  feedbackRatingFormReducer,
  helpSidebarReducer,
  ifuGenerationReducer,
  ifuResultReducer,
  ifuStateReducer,
  ifuUploadReducer,
  iteroFilesReducer,
  iteroLicenseNumbersReducer,
  manualWireReducer,
  practiceLogoReducer,
  productTourReducer,
  productionTxGuideReducer,
  profilePictureDraftReducer,
  programsReducer,
  prospectsReducer,
  recordViewerReducer,
  segmentationProductionTxGuideReducer,
  segmentationReducer,
  setupViewerReducer,
  shippingReducer,
  shippoReducer,
  singleToothIdbReducer,
  smileDesignProductionTxGuideReducer,
  smilesReducer,
  stage1ProductionTxGuideReducer,
  teethChartReducer,
  wasmViewerReducer,
  wireFabricationReviewReducer,
  wireSelectionReducer,
});
