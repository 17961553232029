import Axios from 'axios';
import { closeCaseFilterPreferencesModal } from './case_filter_preferences_modal';
import { case_filter_preferences_options } from '../reducers/case_filter_preferences_modal';

// Action Types
export const FETCH_CASE_FILTER_PREFERENCES_PENDING = 'FETCH_CASE_FILTER_PREFERENCES_PENDING';
export const FETCH_CASE_FILTER_PREFERENCES_SUCCESS = 'FETCH_CASE_FILTER_PREFERENCES_SUCCESS';
export const FETCH_CASE_FILTER_PREFERENCES_ERROR = 'FETCH_CASE_FILTER_PREFERENCES_ERROR';
export const PUT_CASE_FILTER_PREFERENCES_PENDING = 'PUT_CASE_FILTER_PREFERENCES_PENDING';
export const PUT_CASE_FILTER_PREFERENCES_SUCCESS = 'PUT_CASE_FILTER_PREFERENCES_SUCCESS';
export const PUT_CASE_FILTER_PREFERENCES_ERROR = 'PUT_CASE_FILTER_PREFERENCES_ERROR';
export const LOGIN_CASE_FILTER_PREFERENCES_SUCCESS = 'LOGIN_CASE_FILTER_PREFERENCES_SUCCESS';
export const LOGOUT_CASE_FILTER_PREFERENCES_SUCCESS = 'LOGOUT_CASE_FILTER_PREFERENCES_SUCCESS';

// Action Creators
// -----------------------------------------------------------------------------
// FETCH
export function fetchCaseFilterPreferences() {
  return (dispatch) => {
    dispatch(fetchCaseFilterPreferencesPending());

    Axios.get('/apiV2/user/case_filter_preferences')
      .then((res) => {
        const case_filter_preferences =
          res.data.case_filter_preferences === '{}' ? case_filter_preferences_options : JSON.parse(res.data.case_filter_preferences);

        dispatch(fetchCaseFilterPreferencesSuccess(case_filter_preferences));
        return res;
      })
      .catch((error) => {
        dispatch(fetchCaseFilterPreferencesError(error));
      });
  };
}

export function fetchCaseFilterPreferencesPending() {
  return {
    type: FETCH_CASE_FILTER_PREFERENCES_PENDING,
  };
}

export function fetchCaseFilterPreferencesSuccess(case_filter_preferences) {
  return {
    type: FETCH_CASE_FILTER_PREFERENCES_SUCCESS,
    case_filter_preferences: case_filter_preferences,
  };
}

export function fetchCaseFilterPreferencesError(error) {
  return {
    type: FETCH_CASE_FILTER_PREFERENCES_ERROR,
    error: error,
  };
}

// PUT
// -----------------------------------------------------------------------------
export function putCaseFilterPreferences() {
  return (dispatch, getState) => {
    let { case_filter_preferences_editable } = getState().caseFilterPreferencesModalReducer;

    dispatch(putCaseFilterPreferencesPending());

    Axios.put('/apiV2/user/case_filter_preferences', { case_filter_preferences: case_filter_preferences_editable })
      .then((res) => {
        dispatch(putCaseFilterPreferencesSuccess(case_filter_preferences_editable));
        dispatch(closeCaseFilterPreferencesModal());
      })
      .catch((error) => {
        dispatch(putCaseFilterPreferencesError(error));
      });
  };
}

export function putCaseFilterPreferencesPending() {
  return {
    type: PUT_CASE_FILTER_PREFERENCES_PENDING,
  };
}

export function putCaseFilterPreferencesSuccess(case_filter_preferences) {
  return {
    type: PUT_CASE_FILTER_PREFERENCES_SUCCESS,
    case_filter_preferences: case_filter_preferences,
  };
}

export function putCaseFilterPreferencesError(error) {
  return {
    type: PUT_CASE_FILTER_PREFERENCES_ERROR,
    error: error,
  };
}

// LOGIN or LOGOUT
// -----------------------------------------------------------------------------
export function loginCaseFilterPreferencesSuccess(error) {
  return {
    type: LOGIN_CASE_FILTER_PREFERENCES_SUCCESS,
    case_filter_preferences: {},
  };
}

export function logoutCaseFilterPreferencesSuccess(error) {
  return {
    type: LOGOUT_CASE_FILTER_PREFERENCES_SUCCESS,
    case_filter_preferences: {},
  };
}
