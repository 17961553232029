/**
 * Handles anterior posterior checkbox errors
 * @function
 */
const handleAnteriorPosteriorCheckboxErrors = (has_error) => {
const inputs = document.querySelectorAll('.anterior-posterior__corrections-checkboxes input');
inputs.forEach(input => {
    if (has_error) input.classList.add('checkbox_warning-border');
    else input.classList.remove('checkbox_warning-border');
});
}

export { handleAnteriorPosteriorCheckboxErrors };
