// Css
import './shipping_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import PaginationContainer from '../../components/pagination/pagination_container';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Internal Components
import SortHeader from '../../components/layout/sort_header';
import Modal from '../../components/modal/modal';

// Internal Functions
import { setTokenHeader } from '../../common/functions';
import { getBusinessSearchValueFromRoute } from '../../common/route';
import { onChangeSearch, onApplySearchBatch, getBatchesFromRow, getBatchesFromSearch, translateDate } from '../../common/search';

// Redux Reducers
import { getBatches } from '../../redux/reducers/bpp/shipping/shipping';

// Redux Actions
import { openBatchLabelsModalFromList } from '../../redux/actions/bpp/shipping/shipping';

/**
 * Displays the list of completed Shippo batches
 * @alias LabelsContent
 * @component
 * @category BPP
 * @subcategory Shipping List
 */
class LabelsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      batches_raw: [],
      batches_view: [],
      batches_length: 1,
      batches_sort_method: 'date',
      batches_sort_order_ascending: {
        batch_id: true,
        date: false,
        label_count: true,
      },
      batches_row_size: 25,
      batches_active_page: 1,
      batches_page_range: 5,
      is_bottom: false,
      selected_batch_id: '',
    };
    this.onChangeSearchBatch = this.onChangeSearchBatch.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    let batches_raw = this.addBatchMetaData(this.props.batches);

    const batches_sort_method = 'date';
    const batches_view = getBatchesFromSearch(batches_raw, search);
    const active_page = that.state.batches_active_page;
    const row_size = this.state.batches_row_size;

    that.setState({
      loading: false,
      search: search,
      batches_raw: batches_raw,
      batches_view: getBatchesFromRow(
        that.setOrderByMethod(batches_view, batches_sort_method, that.state.batches_sort_order_ascending[batches_sort_method]),
        active_page,
        row_size
      ),
      batches_length: batches_view.length,
      batches_sort_method: batches_sort_method,
    });
  }

  componentDidUpdate() {
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const refresh = this.state.refresh;
    const active_page = 1;
    const row_size = this.state.batches_row_size;
    let batches_view = [];
    if (refresh) {
      batches_view = getBatchesFromSearch(this.props.batches, search);
      const batches_sort_method = 'date';
      that.setState({
        batches_view: getBatchesFromRow(
          that.setOrderByMethod(batches_view, batches_sort_method, that.state.batches_sort_order_ascending[batches_sort_method]),
          active_page,
          row_size
        ),
        batches_active_page: active_page,
        batches_length: batches_view.length,
        batches_sort_method: batches_sort_method,
        search: search,
        refresh: false,
      });
    }
    let numBatchesRaw = this.props.batches.length;
    let numBatches = this.state.batches_length;
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.endsWith('portal') &&
      !this.props.history.location.search &&
      numBatches !== numBatchesRaw &&
      !this.state.refresh
    ) {
      // Refresh route when cases header is clicked or cases icon is clicked
      this.refreshRoute();
    }
  }
  /**
   * Added additional meta data to the batch shipping
   * @function
   * @param batches {Array} - An array of all batch processes
   */
  addBatchMetaData = (batches) => {
    return batches.map((batch) => {
      batch.date_val = translateDate(batch.date);
      return batch;
    });
  };
  /**
   * Handles page change event and updates the cases view
   * @function
   * @param active_page {number} - The number of the current page
   */
  onPageChange(active_page) {
    const batches_sort_method = this.state.batches_sort_method;
    let batches_view = getBatchesFromSearch(this.props.batches, this.state.search);
    this.setState({
      batches_active_page: active_page,
      batches_view: getBatchesFromRow(
        this.setOrderByMethod(batches_view, batches_sort_method, this.state.batches_sort_order_ascending[batches_sort_method]),
        active_page,
        this.state.batches_row_size
      ),
    });
  }

  /**
   * Redirect user to login page
   * @function
   */
  onRedirectToLogin = () => {
    this.props.history.push(`/login`);
  };

  /**
   * Retrieves how the column should be sorted
   * @function
   * @param sort {String} - The column type
   * @return {String} - Ascending or descending
   */
  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }
  /**
   * Gets the start of the page numbers to display
   * @function
   * @return {number} - The number of the page
   */

  getStartRange() {
    return this.state.batches_active_page * this.state.batches_row_size - this.state.batches_row_size + 1;
  }

  /**
   * Gets the end of the page numbers to display
   * @function
   * @return {Number} - The number of the page
   */

  getEndRange() {
    return this.state.batches_active_page * this.state.batches_row_size > this.state.batches_length
      ? this.state.batches_length
      : this.state.batches_active_page * this.state.batches_row_size;
  }
  /**
   * Sets the URL route whenever there is a search or filter change
   * @function
   * @param uri_text {String} - The search or filter value
   * @param route_type {String} - 'Search' or 'filter' (whichever one the user is trying to change)
   * @return {String} - The new route
   */

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;
    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    search = search !== '' ? `search=${search}` : '';
    let param = [];
    param.push(search);

    let route_end = param.length > 0 ? '?' + param.join('&') : '';
    return `${route_base}${route_end}`;
  };

  /**
   * Handles change event when using the search bar
   * @function
   * @param event {Object} - Event object
   */
  onChangeSearchBatch = (event) => {
    onChangeSearch(this, event);
  };

  /**
   * Handles change event when the enter key is pressed after using the search bar
   * @function
   * @param event {Object} - Event object
   */
  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  /**
   * Applies search from search bar
   * @function
   */
  applySearch() {
    onApplySearchBatch(this);
  }

  /**
   * Handles event when search button is clicked
   * @function
   * @param event {Object} - Event object
   */
  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  /**
   * Orders cases by batch id
   * @function
   * @param batches {Array} - List of batches to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of batches
   */
  orderBatchesByBatchId(batches, sort) {
    return _.orderBy(batches, ['batch_id'], [sort]);
  }

  /**
   * Orders batches by date
   * @function
   * @param batches {Array} - List of batches to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of batches
   */
  orderBatchesByDate(batches, sort) {
    return _.orderBy(batches, ['date_val'], [sort]);
  }

  /**
   * Orders batches by label count
   * @function
   * @param batches {Array} - List of batches to sort
   * @param sort {String} - Ascending or descending
   * @return {Array} - Sorted array of batches
   */
  orderBatchesByLabelCount(batches, sort) {
    return _.orderBy(batches, ['label_count'], [sort]);
  }

  /**
   * Refresh route
   * @function
   * @param event {Object} - Event object
   */
  refreshRoute = (event) => {
    this.props.history.replace(this.props.history.location.pathname);
    this.setState({ refresh: true });
  };

  /**
   * Handles event when user clicks on a column header to sort
   * @function
   * @param event {Object} - Event object
   */
  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    let batches_sort_order_ascending = this.state.batches_sort_order_ascending;
    if (sort_method === this.state.batches_sort_method) {
      //Flip order
      batches_sort_order_ascending[sort_method] = !batches_sort_order_ascending[sort_method];
    }
    let batches_view = getBatchesFromSearch(this.props.batches, this.state.search);
    batches_view = getBatchesFromRow(
      this.setOrderByMethod(batches_view, sort_method, batches_sort_order_ascending[sort_method]),
      this.state.batches_active_page,
      this.state.batches_row_size
    );
    this.setState({
      batches_view: batches_view,
      batches_sort_method: sort_method,
      batches_sort_order_ascending: batches_sort_order_ascending,
    });
  };

  /**
   * Determines the method to sort the batches by
   * @function
   * @param batches_view {Array} - List of batches
   * @param sort_method {String} - Sort method
   * @param sort {Array} - Ascending or descending
   * @return {Array} - List of sorted batches
   */
  setOrderByMethod(batches_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);
    switch (sort_method) {
      case 'batch_id':
        batches_view = this.orderBatchesByBatchId(batches_view, sort_value);
        break;
      case 'date':
        batches_view = this.orderBatchesByDate(batches_view, sort_value);
        break;
      case 'label_count':
        batches_view = this.orderBatchesByLabelCount(batches_view, sort_value);
        break;
      default:
    }
    return batches_view;
  }

  /**
   * Handles event when user clicks on a batch from the list
   * @function
   * @param event {Object} - Event object
   */
  onBatchClick = (event) => {
    const labels_path = event.currentTarget.dataset.labels_path;

    this.props.openBatchLabelsModalFromList(labels_path);
    this.setState({ selected_batch_id: event.currentTarget.dataset.batch_id });
  };

  render() {
    return (
      <div className={this.props.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
        <div className="main-content">
          <div className="dark-options">
            <div className="page-heading">Shipping Labels</div>
            <div className="dark-search">
              <input
                type="text"
                className="form-control search-bar-dark font-awesome"
                placeholder="Search"
                aria-describedby="basic-addon1"
                onChange={this.onChangeSearchBatch}
                onKeyPress={this.keyPress}
                value={this.state.search}
              />
            </div>
            <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
              <i className="fa fa-search" aria-hidden="true" />
            </button>
          </div>
          {this.state.batches_view.length > 0 ? (
            <div className="table-case-view">
              <table className="table table-condensed table-striped table-industry table-shipping">
                <thead className="table-inbrace-dark-industry">
                  <tr>
                    <th className="caseid-width" data-method="batch_id" onClick={this.onSortClick}>
                      <SortHeader
                        title="Batch ID"
                        category="batch_id"
                        sort_method={this.state.batches_sort_method}
                        ascending={this.state.batches_sort_order_ascending['batch_id']}
                      />
                    </th>
                    <th className="shipping-name-width" data-method="date" onClick={this.onSortClick}>
                      <SortHeader
                        title="Time/Date"
                        category="date"
                        sort_method={this.state.batches_sort_method}
                        ascending={this.state.batches_sort_order_ascending['date']}
                      />
                    </th>
                    <th className="shipping-name-width" data-method="label_count" onClick={this.onSortClick}>
                      <SortHeader
                        title="Label Count"
                        category="label_count"
                        sort_method={this.state.batches_sort_method}
                        ascending={this.state.batches_sort_order_ascending['label_count']}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody className="table-inbrace-dark-industry">
                  {this.state.batches_view.map((batch, index) => {
                    return (
                      <tr className="pointer" key={index} onMouseUp={this.onBatchClick} data-labels_path={batch.labels_path} data-batch_id={batch.batch_id}>
                        <td className="td-industry-offset">{batch.batch_id}</td>
                        <td>{batch.date}</td>
                        <td>{batch.label_count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <PaginationContainer
                theme="bpp"
                isBottom={this.state.is_bottom}
                activePage={this.state.batches_active_page}
                itemsCountPerPage={this.state.batches_row_size}
                totalItemsCount={this.state.batches_length}
                pageRangeDisplayed={this.state.batches_page_range}
                onChange={this.onPageChange}
                startRange={this.getStartRange()}
                endRange={this.getEndRange()}
                type="Cases"
              />
            </div>
          ) : (
            <div className="result-none">
              {/* Use searchErrorMessage() from search.js when merging */}
              <div>
                Sorry, we couldn't find any batches in our system that match your search for "
                <span className="bold-text fs-exclude">{getBusinessSearchValueFromRoute()}</span>".
              </div>
              <br />
              <div>
                Suggestions:
                <ul className="dash-list">
                  <li>Check your spelling and try again</li>
                  <li>Try different keywords</li>
                  <li>Try different filters</li>
                </ul>
              </div>
            </div>
          )}
        </div>
        {this.props.batch_labels_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text="Shipping Label"
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            onCloseButtonClick={this.props.closeBatchLabelsModal}
            theme="bpp"
            pdf_url={this.props.batch_labels_file}
            original_filename={`${this.state.selected_batch_id}_Label_Shipping.pdf`}
          />
        ) : null}
      </div>
    );
  }
}

LabelsContent.propTypes = {
  batches: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    batches: getBatches(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openBatchLabelsModalFromList: openBatchLabelsModalFromList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LabelsContent));
