import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a selection bar item
 * @component
 */
function SelectionBarItem(props) {
  const is_ifs_setup = typeof props.text === 'string' && props.text.includes('(InBrace IFS)');
  const ifs_setup = is_ifs_setup ? ' selection-bar-item--ifs' : '';
  const active = props.active ? ' selection-bar-item--active' : '';
  const disabled = props.disabled ? ' selection-bar-item--disabled' : '';

  return (
    <div
      className={`selection-bar-item${active}${disabled}${ifs_setup}`}
      disabled={props.disabled}
      onClick={props.disabled ? null : props.onClick}
      onDoubleClick={props.disabled ? null : props.onClick}
      data-text={props.text}
      data-type={props.type}
      data-pane={props.pane}
    >
      {props.text}
    </div>
  );
}

SelectionBarItem.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  pane: PropTypes.number,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SelectionBarItem;
