import {
  CREATE_LICENSE_NUMBER_ERROR,
  CREATE_LICENSE_NUMBER_PENDING,
  CREATE_LICENSE_NUMBER_SUCCESS,
  DELETE_LICENSE_NUMBER_ERROR,
  DELETE_LICENSE_NUMBER_PENDING,
  DELETE_LICENSE_NUMBER_SUCCESS,
  FETCH_LICENSE_NUMBERS_ERROR,
  FETCH_LICENSE_NUMBERS_PENDING,
  FETCH_LICENSE_NUMBERS_SUCCESS,
  UPDATE_LICENSE_NUMBER_ERROR,
  UPDATE_LICENSE_NUMBER_PENDING,
  UPDATE_LICENSE_NUMBER_SUCCESS,
} from '../../../actions/ipp/itero/itero_license_numbers';

const initialState = {
  license_numbers: [],
  fetch_license_numbers_pending: false,
  fetch_license_numbers_success: false,
  fetch_license_numbers_error: null,
  create_license_number_pending: false,
  create_license_number_success: false,
  create_license_number_error: null,
  delete_license_number_pending: false,
  delete_license_number_success: false,
  delete_license_number_error: null,
  update_license_number_pending: false,
  update_license_number_success: false,
  update_license_number_error: null,
};

export function iteroLicenseNumbersReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_LICENSE_NUMBER_PENDING:
      return {
        ...state,
        create_license_number_pending: true,
        create_license_number_success: false,
        create_license_number_error: null,
      };

    case CREATE_LICENSE_NUMBER_SUCCESS:
      return {
        ...state,
        create_license_number_pending: false,
        create_license_number_success: true,
        create_license_number_error: null,
      };

    case CREATE_LICENSE_NUMBER_ERROR:
      return {
        ...state,
        create_license_number_pending: false,
        create_license_number_success: false,
        create_license_number_error: action.error,
      };

    case DELETE_LICENSE_NUMBER_PENDING:
      return {
        ...state,
        delete_license_number_pending: true,
        delete_license_number_success: false,
        delete_license_number_error: null,
      };

    case DELETE_LICENSE_NUMBER_SUCCESS:
      return {
        ...state,
        delete_license_number_pending: false,
        delete_license_number_success: true,
        delete_license_number_error: null,
      };

    case DELETE_LICENSE_NUMBER_ERROR:
      return {
        ...state,
        delete_license_number_pending: false,
        delete_license_number_success: false,
        delete_license_number_error: action.error,
      };

    case UPDATE_LICENSE_NUMBER_PENDING:
      return {
        ...state,
        update_license_number_pending: true,
        update_license_number_success: false,
        update_license_number_error: null,
      };

    case UPDATE_LICENSE_NUMBER_SUCCESS:
      return {
        ...state,
        update_license_number_pending: false,
        update_license_number_success: true,
        update_license_number_error: null,
      };

    case UPDATE_LICENSE_NUMBER_ERROR:
      return {
        ...state,
        update_license_number_pending: false,
        update_license_number_success: false,
        update_license_number_error: action.error,
      };

    case FETCH_LICENSE_NUMBERS_PENDING:
      return {
        ...state,
        fetch_license_numbers_pending: true,
        fetch_license_numbers_success: false,
        fetch_license_numbers_error: null,
      };

    case FETCH_LICENSE_NUMBERS_SUCCESS:
      return {
        ...state,
        fetch_license_numbers_pending: false,
        fetch_license_numbers_success: true,
        fetch_license_numbers_error: null,
        license_numbers: action.license_numbers,
      };

    case FETCH_LICENSE_NUMBERS_ERROR:
      return {
        ...state,
        fetch_license_numbers_pending: false,
        fetch_license_numbers_success: false,
        fetch_license_numbers_error: action.error,
      };

    default:
      return state;
  }
}

export const getCreateLicenseNumberPending = (state) => state.iteroLicenseNumbersReducer.create_license_number_pending;
export const getCreateLicenseNumberSuccess = (state) => state.iteroLicenseNumbersReducer.create_license_number_success;
export const getCreateLicenseNumberError = (state) => state.iteroLicenseNumbersReducer.create_license_number_error;
export const getDeleteLicenseNumberPending = (state) => state.iteroLicenseNumbersReducer.delete_license_number_pending;
export const getDeleteLicenseNumberSuccess = (state) => state.iteroLicenseNumbersReducer.delete_license_number_success;
export const getDeleteLicenseNumberError = (state) => state.iteroLicenseNumbersReducer.delete_license_number_error;
export const getUpdateLicenseNumberPending = (state) => state.iteroLicenseNumbersReducer.update_license_number_pending;
export const getUpdateLicenseNumberSuccess = (state) => state.iteroLicenseNumbersReducer.update_license_number_success;
export const getUpdateLicenseNumberError = (state) => state.iteroLicenseNumbersReducer.update_license_number_error;
export const getFetchLicenseNumbersPending = (state) => state.iteroLicenseNumbersReducer.fetch_license_numbers_pending;
export const getFetchLicenseNumbersSuccess = (state) => state.iteroLicenseNumbersReducer.fetch_license_numbers_success;
export const getFetchLicenseNumbersError = (state) => state.iteroLicenseNumbersReducer.fetch_license_numbers_error;
export const getIsLicenseNumbersFetched = (state) => {
  return (
    !state.iteroLicenseNumbersReducer.fetch_license_numbers_pending &&
    (state.iteroLicenseNumbersReducer.fetch_license_numbers_success || state.iteroLicenseNumbersReducer.fetch_license_numbers_error)
  );
};
export const getLicenseNumbers = (state) => state.iteroLicenseNumbersReducer.license_numbers;
