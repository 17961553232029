import React, { useEffect, useState } from 'react';
import { changeWasmArch } from './wasm_controller/wasm_controller';
import { getWasmViewerState } from '../../redux/selectors/wasm_viewer';
import { connect } from 'react-redux';

const SLIDER_WASM_MAPPING = {
  1: 'upper',
  2: 'lowerT',
  3: 'both',
  4: 'upperT',
  5: 'lower',
};

const TOP_SLIDER_VALUE = '1';
const DEFAULT_SLIDER_VALUE = '3';
const BOTTOM_SLIDER_VALUE = '5';

function getArchButtonClassnames(is_inactive) {
  const classnames = ['wasm-arch-button'];
  if (is_inactive) {
    classnames.push('wasm-arch-button--inactive');
  }
  return classnames.join(' ');
}

function WasmArchControls(props) {
  const { arch } = props;
  const [value, setValue] = useState(DEFAULT_SLIDER_VALUE);
  const onChangeValue = (newValue) => {
    setValue(newValue);
    changeWasmArch(SLIDER_WASM_MAPPING[newValue]);
  };
  const handleArchButtonClick = (newValue) => {
    if (value === newValue) {
      onChangeValue(DEFAULT_SLIDER_VALUE);
    } else {
      onChangeValue(newValue);
    }
  };
  useEffect(() => {
    const key = Object.keys(SLIDER_WASM_MAPPING).find((key) => SLIDER_WASM_MAPPING[key] === arch);
    setValue(key);
  }, [arch]);

  return (
    <div className="wasm-arch-control">
      <div
        className={getArchButtonClassnames(value === BOTTOM_SLIDER_VALUE)}
        onClick={() => handleArchButtonClick(BOTTOM_SLIDER_VALUE)}
        data-toggle="tooltip"
        data-placement="top"
        title="Hide upper arch"
      >
        U
      </div>
      <div className="wasm-arch-control-slider-indicators">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <input
        className="wasm-arch-control-slider"
        type="range"
        min="1"
        max="5"
        value={value}
        onMouseUp={(e) => e.target.blur()}
        onChange={(e) => onChangeValue(e.target.value)}
        data-toggle="tooltip"
        data-placement="left"
        title="Adjust arch transparency"
      />
      <div
        className={getArchButtonClassnames(value === TOP_SLIDER_VALUE)}
        onClick={() => handleArchButtonClick(TOP_SLIDER_VALUE)}
        data-toggle="tooltip"
        data-placement="bottom"
        title="Hide lower arch"
      >
        L
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const wasm_state = getWasmViewerState(state);
  return {
    arch: wasm_state.arch,
  };
};

export default connect(mapStateToProps)(WasmArchControls);
