import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getCaseFileError, getCaseFileList, getCaseFileListLoading } from '../../redux/reducers/ipp/case_details/case_files';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';
import RecordViewer from '../../doctor/components/record_viewer/record_viewer';
import { bindActionCreators } from 'redux';
import { fetchCaseFileData } from '../../redux/actions/ipp/case_details/case_files';
import { onImageToolAction } from '../../common/functions';

import './record_viewer_popout.scss';

class RecordViewerPopout extends Component {
  componentDidMount() {
    const { case_id } = this.props.match && this.props.match.params;
    if (case_id) {
      this.props.fetchCaseFileData(case_id, false, true);
    }
  }

  componentDidUpdate(prevProps) {
    const finishedFetchingCaseFiles = this.props.case_file_list && !this.props.case_file_list_loading && !this.props.case_file_list_error;
    const caseFileListChanged = this.props.case_file_list !== prevProps.case_file_list;

    if (finishedFetchingCaseFiles && caseFileListChanged) {
      const case_id = this.props.case_file_list.case_id;
      const files = this.props.case_file_list?.[case_id]?.submission_process?.files ?? [];
      this.props.buildRecordStates(files);
    }
  }

  render() {
    return (
      <div className="ipp-light">
        <div className="record-viewer-popout">
          <RecordViewer
            records={this.props.record_states}
            onLoad={this.props.onLoadImageRecord}
            onClick={(id, action) => {
              onImageToolAction(id, action, this.props);
            }}
            showHeader={false}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    case_file_list: getCaseFileList(state),
    case_file_list_loading: getCaseFileListLoading(state),
    case_file_list_error: getCaseFileError(state),
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseFileData: fetchCaseFileData,
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(RecordViewerPopout);
