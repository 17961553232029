export const sidebarDefaultWidth = 300;
export const sidebarMinWidth = 80;
export const sidebarMaxWidth = 500;

export const sidebarButton = {
  history: 'history',
  images: 'images',
  bolton: 'bolton',
};

export const defaultSidebarActiveButton = sidebarButton.history;
