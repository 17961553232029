import _ from 'lodash';

/**
 * Class that handles window resizing
 */
class Resizer {
  constructor(container, camera, renderer, update) {
    this.container = container;
    this.camera = camera;
    this.renderer = renderer;
    this.update = update;

    this.resizeTimeout = null;
    this.resizeDebounce = _.debounce(this.handlesWindowResize, 50, { trailing: true });

    // set initial size
    this.handlesWindowResize();
    window.addEventListener('resize', this.resizeDebounce);
  }

  /**
   * Handles the window resize
   *
   * - Refreshes the timeout every time a resize event is triggered
   * @function
   * @param {Number} [counter=0] - The counter to account for css transition
   */
  handlesWindowResize = (counter = 0) => {
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;
    const max_count = 100;

    counter = _.isNumber(counter) ? counter + 1 : 0;

    this.updateSize(width, height);
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    // Capture last resize event
    if (counter < max_count) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.handlesWindowResize(counter);
        this.update();
      }, 10);
    }
  };

  /**
   * Removes the resize listener
   * @function
   */
  removesResizer() {
    window.removeEventListener('resize', this.resizeDebounce);
  }

  /**
   * Updates the camera's viewing size
   * @function
   * @param {Number} width - Width of the renderer
   * @param {Number} height - Height of the renderer
   */
  updateSize = (width, height) => {
    const radius = 50;
    const aspect = height / width;
    const r2 = Math.max(radius, radius / aspect);

    this.camera.near = 0.01;
    // this.camera.far = 4 * radius;
    this.camera.far = 10000;
    this.camera.left = -r2;
    this.camera.right = r2;
    this.camera.top = r2 * aspect;
    this.camera.bottom = -r2 * aspect;
    this.camera.updateProjectionMatrix();
  };
}

export { Resizer };
