/**
 * File:ir_content.js - Content of the Item Request list
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ir_content.scss';
// External Libs
import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Internal Components
import CardContainer from '../components/container/card_container';
import IrFilter from './ir_filter';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import DivTable from '../components/tables/div_table';
import Dropdown from '../components/inputs/dropdown';
import AdditionalActionsDropdown from '../case_list/additional_actions_dropdown';
import NoResult from '../case_list/no_result';
import TextBox from '../components/inputs/text_box';
import NoRecords from '../case_list/no_records';
import Modal from '../../components/modal/modal';
// Internal Functions
// eslint-disable-next-line
import { formatCaseId, setTokenHeader, convertDate, getRefinementSuffix, getRootPath, textFieldCheck, removeEmoji } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import {
  getDoctorsIdFromRoute,
  getSearchValueFromRoute,
  getStatusFilterValueFromRoute,
  getSearchFilterValueFromRoute,
  getSelectionValueFromRoute,
  submitIreqRedirect,
  viewAllNotesRedirect,
} from '../../common/route';
import {
  getIrStatusPriorityDefinition,
  getPDFNameTemplate,
  getSubmissionDateDefaultSort,
  rebrandStatus,
  convertHtmlToPlainText,
  translateStatus,
  truncateTextByLimit,
} from '../../common/helpers';
import { tableRowLink } from '../../common/table';
import { displayPSTDateTime } from '../../common/date';
import { UserPermissionsContext } from '../../context/user_permission';
import { setOverflowTooltip } from '../../common/tooltip';

//Redux
import { getDoctorRole, getAccountLinkId, getAdditionalActionsModal, getNoteModal, getViewPlanModal } from '../../redux/reducers/ipp/case_list';
import { setNoteModal, setViewPlanModal, updateNote } from '../../redux/actions/ipp/case_list';

const DEFAULT_REBRANDED_STATUS_SORT_ORDER_PRIORITY = {
  'Review Smile Design': 0,
  'Upload New Records': 1,
  'Pending Doctor Clarification': 2,
  'On Hold': 3,
  'Draft Submission': 4,
  'Draft Digital Enhancement': 5,
}

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctor_id: '',
      search: '',
      search_phrase: '',
      search_filter: '',
      filter: '',
      ir_sort_method: 'default',
      ir_sort_order_ascending: { ireq_id: true, patient_name: true, created_date: false, status: true, default: true },
      is_bottom: false,
      item_request: [],
      ir_raw: [],
      ir_view: [],
      ir_active_page: 1,
      ir_page_range: 5,
      ir_length: 1,
      ir_row_size: 15,
      status_filter: '',
      statuses: {
        all: 0,
        action: 0,
        hold: 0,
        active: 0,
        ship: 0,
        cancel: 0,
      },
      showCancelCase: false,
      selected_ireq_id: '',
      selected_draft_id: '',
      selected_first_name: '',
      selected_last_name: '',
      selection: '',
      note_updated: false,
    };

    this.getRefinementSuffix = getRefinementSuffix;
    this.displaySearchBar.bind(this);
    this.onChangeSearchPatient.bind(this);
    this.onChangeRowSize = this.onChangeRowSize.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchFilterSelection = this.onSearchFilterSelection.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
  }

  translateIrStatusPriority(item_request) {
    const status_priority_definition = getIrStatusPriorityDefinition();
    const last_priority = 10;
    return status_priority_definition[item_request.ireq_status] ? status_priority_definition[item_request.ireq_status] : last_priority;
  }

  translateIrSubmissionPriority(item_request) {
    const date_in_milliseconds = new Date(item_request.created_date).getTime();
    return item_request.status_priority >= getIrStatusPriorityDefinition('Item Request Shipped') ? -date_in_milliseconds : date_in_milliseconds;
  }

  onChangeSearchPatient = (event) => {
    let text = textFieldCheck(removeEmoji(event.target.value));
    this.setState({ search_phrase: text });
  };

  setRouteBySearch(text) {
    const search_filter = this.state.search_filter;

    this.setRoute(text, search_filter);
    this.setState({ search_phrase: text });
  }

  setRouteBySearchFilter(filter) {
    const search_phrase = this.state.search_phrase;

    this.setRoute(search_phrase, filter);
  }

  setRoute(search_phrase, search_filter) {
    const selection_value = getSelectionValueFromRoute();

    if (selection_value.includes('doctor') || selection_value.includes('location') || selection_value === 'all_cases') {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=item_request`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=item_request&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(
          `/portal/${this.state.doctor_id}?selection=${selection_value}&filter=item_request&search=${search_phrase}&search_filter=${search_filter}`
        );
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=item_request&search=${search_phrase}`);
      }
    } else {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=item_request`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=item_request&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=item_request&search=${search_phrase}&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=item_request&search=${search_phrase}`);
      }
    }
  }

  onKeyPressSearchPatient = (event) => {
    let text = textFieldCheck(event.target.value);

    if (event.key === 'Enter') {
      this.setRouteBySearch(text);
    }
  };

  onSearchButtonClick = (event) => {
    this.setRouteBySearch(this.state.search_phrase);
  };

  onChangeRowSize(event) {
    const active_page = 1;
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    const ir_view = this.setOrderByMethod(
      this.getIrFromSelection(this.getIrFromFilter(this.getItemRequestFromSearch(this.state.ir_raw, this.state.search), filter), selection),
      this.state.ir_sort_method,
      this.state.ir_sort_order_ascending[this.state.ir_sort_method]
    );

    this.setState({
      ir_row_size: event.target.value,
      ir_active_page: active_page,
      ir_view: this.getItemRequestFromRow(ir_view, active_page, event.target.value),
      ir_length: ir_view.length,
    });
  }

  onPageChange(active_page) {
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    this.setState({
      ir_active_page: active_page,
      ir_view: this.getItemRequestFromRow(
        this.setOrderByMethod(
          this.getIrFromSelection(this.getIrFromFilter(this.getItemRequestFromSearch(this.state.ir_raw, this.state.search), filter), selection),
          this.state.ir_sort_method,
          this.state.ir_sort_order_ascending[this.state.ir_sort_method]
        ),
        active_page,
        this.state.ir_row_size
      ),
    });
  }

  getItemRequestFromRow(ir_view, page, row_size) {
    const start_index = (Number(page) - 1) * Number(row_size);
    const end_index = start_index + Number(row_size);

    return ir_view.slice(start_index, end_index);
  }

  getRecentlyShipDaysInMilliseconds() {
    const milli = 1000;
    const seconds = 60;
    const mins_in_hour = 60;
    const hour_in_day = 24;
    const recently_days = 14;

    return new Date().getTime() - milli * seconds * mins_in_hour * hour_in_day * recently_days;
  }
  /**
   * Search result alg for the doctor side
   * @function
   * @param {Object} item_requests - List of IRs
   * @param {String} search_phrase - Search parameter to be used
   * @return {Object} returns a filter version of the IR against search criteria
   */
  getItemRequestFromSearch(item_requests, search_phrase) {
    if (search_phrase === '') {
      return item_requests;
    }
    search_phrase = search_phrase.toLowerCase();

    const space_index = search_phrase.lastIndexOf(' ');
    const inital_index = 0;

    let item_request = [];
    let search_ireq_id = '';
    let search_ireq_id_filter = '';
    let search_first_name = '';
    let search_last_name = '';

    let phrase_one = search_phrase;
    let phrase_two = '';

    if (space_index >= 0) {
      phrase_one = search_phrase.substring(inital_index, space_index);
      phrase_two = search_phrase.substring(space_index + 1, search_phrase.length);
    }

    for (let i = 0; i < item_requests.length; i++) {
      search_first_name = item_requests[i].first_name.toLowerCase();
      search_last_name = item_requests[i].last_name.toLowerCase();
      search_ireq_id = item_requests[i].ireq_id.toLowerCase();
      search_ireq_id_filter = search_ireq_id.replace('-de1', '-de').replace('-ir1', '-ir').replace('-de0', '-de10').replace('-ir0', '-ir10');

      if (
        (search_first_name.indexOf(phrase_one) >= 0 && search_last_name.indexOf(phrase_two) >= 0) ||
        (search_first_name.indexOf(phrase_two) >= 0 && search_last_name.indexOf(phrase_one) >= 0)
      ) {
        item_request.push(item_requests[i]);
      } else {
        if (
          search_first_name.indexOf(search_phrase) >= 0 ||
          search_last_name.indexOf(search_phrase) >= 0 ||
          search_ireq_id.indexOf(search_phrase) >= 0 ||
          search_ireq_id_filter.indexOf(search_phrase) >= 0
        ) {
          item_request.push(item_requests[i]);
        }
      }
    }

    return item_request;
  }

  getStartRange() {
    return this.state.ir_active_page * this.state.ir_row_size - this.state.ir_row_size + 1;
  }

  getEndRange() {
    return this.state.ir_active_page * this.state.ir_row_size > this.state.ir_length
      ? this.state.ir_length
      : this.state.ir_active_page * this.state.ir_row_size;
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    let ir_sort_order_ascending = this.state.ir_sort_order_ascending;
    if (sort_method === this.state.ir_sort_method) {
      //Flip order
      ir_sort_order_ascending[sort_method] = !ir_sort_order_ascending[sort_method];
    }

    let ir_view = this.getItemRequestFromRow(
      this.setOrderByMethod(
        this.getIrFromSelection(this.getIrFromFilter(this.getItemRequestFromSearch(this.state.ir_raw, this.state.search), filter), selection),
        sort_method,
        ir_sort_order_ascending[sort_method]
      ),

      this.state.ir_active_page,
      this.state.ir_row_size
    );

    this.setState({
      ir_view: ir_view,
      ir_sort_method: sort_method,
      ir_sort_order_ascending: ir_sort_order_ascending,
    });
  };

  setOrderByMethod(ir_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'ireq_id':
        ir_view = this.orderItemRequestByIReqId(ir_view, sort_value);
        break;

      case 'patient_name':
        ir_view = this.orderItemRequestByName(ir_view, sort_value);
        break;

      case 'created_date':
        ir_view = this.orderItemRequestBySubmissionDate(ir_view, sort_value);
        break;

      case 'status':
        ir_view = this.orderItemRequestByStatus(ir_view, sort_value);
        break;
      default:
        ir_view = this.orderItemRequestByDefault(ir_view);
    }

    return ir_view;
  }

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  /**
   * Orders item requests by descending submission date
   * @function
   * @param {list} item_requests - List of item requests
   * @return {list} - Ordered item requests
   */
  orderItemRequestByReset = (item_requests) => {
    return this.orderItemRequestBySubmissionDate(item_requests, 'desc');
  };

  orderItemRequestByIReqId(item_request, sort) {
    return _.orderBy(item_request, ['ireq_id'], [sort]);
  }

  orderItemRequestByName(item_request, sort) {
    return _.orderBy(item_request, ['first_name', 'last_name', 'submission_date_priority'], [sort, sort, getSubmissionDateDefaultSort()]);
  }

  orderItemRequestBySubmissionDate(item_request, sort) {
    return _.orderBy(item_request, ['created_date', this.getStatusSortFunction()], [sort]);
  }

  orderItemRequestByStatus(item_request, sort) {
    return _.orderBy(item_request, [this.getStatusActionRequiredSortFunction(), this.getStatusSortFunction(), 'submission_date_priority'], [sort, sort]);
  }

  orderItemRequestByDefault(item_request) {
    return _.orderBy(item_request, [this.getStatusActionRequiredSortFunction(), 'submission_date_priority'], ['asc', 'desc']);
  }

  /**
   * Returns function for sorting by ireq status
   * @returns {function}  - Sorting function
   * @function
   */
  getStatusSortFunction = () => {
    return function (ireq) {
      return rebrandStatus(ireq.ireq_status);
    };
  };

  /**
   * Returns function for sorting by ireq status
   * @returns {function}  - Sorting function
   * @function
   */
  getStatusActionRequiredSortFunction = () => {
    return function (ireq) {
      let rebrandredStatus = rebrandStatus(ireq.ireq_status);
      if (rebrandredStatus.includes('-')) {
        rebrandredStatus = rebrandredStatus.split('-')[0].trim()
      }

      let priority;
      if (DEFAULT_REBRANDED_STATUS_SORT_ORDER_PRIORITY[rebrandredStatus] !== undefined) {
        priority = DEFAULT_REBRANDED_STATUS_SORT_ORDER_PRIORITY[rebrandredStatus];
      } else {
        priority = DEFAULT_REBRANDED_STATUS_SORT_ORDER_PRIORITY.length;
      }
      return priority;
    };
  };

  createItemRequestArray(item_request_list, cases) {
    for (let i = 0; i < item_request_list.length; i++) {
      item_request_list[i].status_priority = this.translateIrStatusPriority(item_request_list[i]);
      item_request_list[i].submission_date_priority = this.translateIrSubmissionPriority(item_request_list[i]);
    }
    return item_request_list;
  }

  getIrFromFilter(item_request, filter_phrase) {
    if (filter_phrase === '') {
      return item_request;
    }
    let item_request_list = [];

    for (let i = 0; i < item_request.length; i++) {
      if (_.intersection(item_request[i].filter_codes, [filter_phrase]).length > 0) {
        item_request_list.push(item_request[i]);
      }
    }

    return item_request_list;
  }

  /**
   * Retrieves item requests based on selection from menu
   * @function
   * @param {list} item_request - List of item requests
   * @param {string} selection_phrase - Selection from menu
   * @return {list} -Selected item requests
   */
  getIrFromSelection(item_request, selection_phrase) {
    if (
      !this.props.doctor_role.includes('DSO') ||
      selection_phrase === 'all_cases' ||
      ((this.props.doctor_role === 'DSO' || this.props.doctor_role === 'DSO_Doctor_Override') && selection_phrase === '')
    ) {
      return item_request;
    }

    let item_request_list = [];

    if (selection_phrase === '') {
      const doctor_id = getDoctorsIdFromRoute();

      for (let i = 0; i < item_request.length; i++) {
        if (item_request[i].dso_doctor && item_request[i].dso_doctor.id.toString() === doctor_id.toString()) {
          item_request_list.push(item_request[i]);
        }
      }
    } else if (selection_phrase.includes('doctor')) {
      const doctor_id = selection_phrase.substring(selection_phrase.indexOf('_') + 1);
      for (let i = 0; i < item_request.length; i++) {
        if (item_request[i].dso_doctor && item_request[i].dso_doctor.id.toString() === doctor_id) {
          item_request_list.push(item_request[i]);
        }
      }
    } else if (selection_phrase.includes('location')) {
      const location_id = selection_phrase.substring(selection_phrase.indexOf('_') + 1);
      for (let i = 0; i < item_request.length; i++) {
        if (item_request[i].shipping_address__id && item_request[i].shipping_address__id.toString() === location_id) {
          item_request_list.push(item_request[i]);
        }
      }
    }

    return item_request_list;
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const doctor_id = this.props.doctor_role.includes('DSO') ? this.props.account_link_id : getDoctorsIdFromRoute();
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const filter = status_filter || search_filter;
    const selection = getSelectionValueFromRoute();

    //API Call to load data
    Axios.get(`/apiV2/cases/${doctor_id}?dso_doctor_id=${getDoctorsIdFromRoute()}`)
      .then(function (res) {
        const ir_raw = that.createItemRequestArray(res.data.item_request, res.data.cases);
        const ir_view = that.orderItemRequestByDefault(
          that.getIrFromSelection(that.getIrFromFilter(that.getItemRequestFromSearch(ir_raw, search), filter), selection)
        );

        that.setState({
          loading: false,
          doctor_id: res.data.doctor_id,
          search: search,
          search_phrase: search,
          search_filter: search_filter,
          filter: filter,
          status_filter: status_filter,
          ir_active_page: 1,
          ir_length: ir_view.length,
          item_request: res.data.item_request,
          ir_raw: ir_raw,
          ir_view: that.getItemRequestFromRow(ir_view, that.state.ir_active_page, that.state.ir_row_size),
          selection: selection,
          statuses: res.data.item_request_alert,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    //Enable tooltip
    setTimeout(function () {
      this.$('[data-toggle="tooltip"]').tooltip();
    }, 0);
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
    const that = this;
    const state_search = this.state.search;
    const state_search_filter = this.state.search_filter;
    const state_status_filter = this.state.status_filter;
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const sort_method = that.state.ir_sort_method;
    const ir_sort_order_ascending = this.state.ir_sort_order_ascending;
    const active_page = this.state.note_updated ? this.state.ir_active_page : 1;
    const row_size = this.state.ir_row_size;
    let ir_view = [];

    if (state_search !== search || state_search_filter !== search_filter || state_status_filter !== status_filter || this.state.note_updated) {
      if (search_filter !== '' || status_filter !== '') {
        const filter = search_filter || status_filter;
        ir_view = that.setOrderByMethod(
          that.getIrFromSelection(that.getIrFromFilter(that.getItemRequestFromSearch(that.state.ir_raw, search), filter), selection),
          sort_method,
          ir_sort_order_ascending[sort_method]
        );
      } else {
        ir_view = that.setOrderByMethod(that.getItemRequestFromSearch(that.state.ir_raw, search), sort_method, ir_sort_order_ascending[sort_method]);
      }

      that.setState({
        ir_active_page: active_page,
        ir_length: ir_view.length,
        ir_view: this.getItemRequestFromRow(ir_view, active_page, row_size),
        search: search,
        search_filter: search_filter,
        search_phrase: search,
        filter: '',
        selection: selection,
        status_filter: status_filter,
        note_updated: false,
      });
    } else if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.reset_list &&
      this.props.history.location.state.reset_list === 'true'
    ) {
      this.props.history.replace({ search: this.props.history.location.search, state: { reset_list: 'false', filter: 'item_request' } });
      const ir_raw = that.state.ir_raw;
      const ir_view = that.orderItemRequestByReset(that.getIrFromFilter(that.getItemRequestFromSearch(ir_raw, ''), ''));

      that.setState({
        ir_view: that.getItemRequestFromRow(ir_view, active_page, row_size),
        ir_active_page: active_page,
        ir_sort_order_ascending: { ireq_id: true, patient_name: true, created_date: false, status: true },
        ir_sort_method: 'default',
        search_phrase: '',
      });
    }
  }

  componentWillUnmount() {
    // clean up jquery
    window.$('.sub-item').off('click');
    this.props.setViewPlanModal(false);
    this.props.setNoteModal(false);
  }

  onCaseTrClick = (event) => {
    if (
      event.target.innerHTML === 'Tracking Info' ||
      event.target.dataset.additionalactions ||
      this.props.additional_actions_modal ||
      this.props.note_modal ||
      this.props.view_plan_modal
    ) {
      return;
    }

    let case_id = event.currentTarget.dataset.case_id;
    const ireq_id = event.currentTarget.dataset.ireq_id;
    if (case_id.endsWith('-DE')) {
      case_id = case_id + '1';
    }
    const block = event.target && event.target.className && event.target.className.indexOf('block-tr') >= 0;
    if (!block) {
      let rootPath = getRootPath(this.props.history.location.pathname);
      const pathname = `${rootPath}/item/${formatCaseId(case_id)}`;
      tableRowLink(event, pathname, this.props, '', { selectedTab: 'item', ireq_id: ireq_id });
    }
  };

  onSearchFilterSelection(option) {
    this.setRouteBySearchFilter(option.value);
  }
  /**
   * Converts filter name to a readable title
   * @function
   * @return {String} String version of filter
   */
  filterToTitle(filter) {
    const titles = {
      action: 'Action Required',
      hold: 'On Hold',
      active: 'Active',
      ship: 'Shipped',
      cancel: 'Cancelled',
    };

    if (titles[filter]) {
      return titles[filter];
    }

    return filter;
  }

  displaySearchBar() {
    const options = [
      { label: 'All Status', value: '' },
      { label: rebrandStatus('INBRACE Reviewing Request'), value: 'review' },
      { label: rebrandStatus('INBRACE Manufacturing Item Request'), value: 'prepare' },
      { label: translateStatus('Hold'), value: 'hold' },
      { label: rebrandStatus('Item Request Shipped'), value: 'ship' },
      { label: translateStatus('Cancelled'), value: 'cancel' },
    ];

    const getDisplayLabel = (value) => {
      const selected_option = options.filter((option) => {
        return option.value === value;
      });

      return selected_option && selected_option.length > 0 ? selected_option : options[0];
    };

    return (
      <div className="search-container">
        <div className="search">
          <TextBox
            placeholder="Search"
            onChange={this.onChangeSearchPatient}
            onKeyPress={this.onKeyPressSearchPatient}
            value={this.state.search_phrase}
            icon={'fa fa-search'}
            onIconClick={this.onSearchButtonClick}
          ></TextBox>
        </div>
        <Dropdown value={getDisplayLabel(this.state.search_filter)} onChange={this.onSearchFilterSelection} options={options} />
      </div>
    );
  }

  getSubmissionDate = (props, item_request) => {
    return <div className="div-table--item-restrict-text">{convertDate(item_request.created_date) ? convertDate(item_request.created_date) : '- - - - -'}</div>;
  };

  getStatus = (props, item_request, fields, is_mobile = false) => {
    const rebrand_status = rebrandStatus(item_request.ireq_status);
    const is_action_required = item_request.ireq_status === 'Item Request On Hold';
    const badge_class = `badge--status badge--${is_action_required ? 'primary' : 'default'}`;
    const ship_date =
      item_request.ireq_status === 'INBRACE Manufacturing Item Request'
        ? `${item_request.expedite_ind ? 'Expedited ' : ''}Est. Ship Date ${convertDate(item_request.target_ship_date)}`
        : '';

    return (
      <div className={`div-table--item-restrict-text div-table--item-restrict-text--${is_action_required ? 'primary' : 'default'}`}>
        {item_request.ireq_status === 'Item Request Shipped' ? (
          <span className={badge_class}>
            {rebrand_status}{' '}
            {item_request.hand_delivery_ind ? (
              <span>- Hand Delivered</span>
            ) : item_request.tracking_info && item_request.tracking_info !== 'NA' && item_request.tracking_info !== 'N/A' ? (
              <span>
                <span>- </span>
                <a className="underline-text" href={item_request.tracking_url} target="_blank" rel="noopener noreferrer">
                  Tracking Info
                </a>
              </span>
            ) : null}
          </span>
        ) : item_request.ireq_status === 'INBRACE Manufacturing Item Request' ? (
          <span className={badge_class} data-toggle="tooltip" data-placement="top" data-original-title={ship_date}>
            {`${rebrand_status} - ${ship_date}`}
          </span>
        ) : item_request.ireq_status === 'Item Request On Hold' || item_request.ireq_status === 'Item Request Cancelled' ? (
          <span className={badge_class} data-toggle="tooltip" data-placement="top" data-original-title={truncateTextByLimit(item_request.status_comment, 200)}>
            {rebrand_status}
            {is_mobile ? ` - ${item_request.status_comment}` : ''}
          </span>
        ) : (
          <span className={badge_class}>{rebrand_status}</span>
        )}
      </div>
    );
  };

  /**
   * Retrieves most recent patient note to display for item request
   * @function
   * @param {object} props - Props object
   * @param {object} item_request - Item request object
   * @returns {JSX} - JSX for patient note
   */
  getNote = (props, item_request) => {
    let note = { id: '', subject: '', text: '', date: '', created_by_id: '' };
    if (item_request.patient_note_info) {
      note = JSON.parse(item_request.patient_note_info);
      note.text = item_request.patient_note_text;
      note.subject = item_request.patient_note_subject;
    }
    const note_text = note.id && note.text ? convertHtmlToPlainText(note.text) : '';

    return (
      <div className="div-table--item-notes">
        <div className={`div-table--item-notes-content${!note?.id ? ' div-table--item-notes-content--not-applicable' : ''}`}>
          {note?.id && (
            <div className="note-tooltip" id={item_request.ireq_id} data-toggle="tooltip" data-placement="top">
              <div className="restrict-text-single-row row-label">
                {displayPSTDateTime(note.date)}
                {note.subject ? ` - ${note.subject.toUpperCase()}` : ''}
              </div>
              <div className="restrict-text" ref={(ref) => setOverflowTooltip(ref, item_request.ireq_id, note_text)}>
                {note_text}
              </div>
            </div>
          )}
        </div>
        {this.displayAdditionalActions(item_request, note)}
      </div>
    );
  };

  /**
   * Displays additional actions menu
   * @function
   * @param {object} item_request - Selected item request
   * @param {object} note - Most recent patient note
   * @returns {JSX} - JSX for additional actions
   */
  displayAdditionalActions = (item_request, note) => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <AdditionalActionsDropdown
              id={item_request.ireq_id}
              note={note}
              patient_name={item_request.patient_name}
              case_id={item_request.case_id}
              refreshList={this.updateIreqListWithNote}
              updateNote={this.props.updateNote}
              user_roles_and_permissions={user_roles_and_permissions}
              displayForm={this.displayIreqForm}
              can_view_plan={!item_request.historical && item_request.is_approved}
              submitIreqRedirect={this.submitIreqRedirect}
              viewAllNotesRedirect={(case_id) => viewAllNotesRedirect(case_id, this.state.doctor_id, this.props)}
            />
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Updates ireq list with new note
   * @function
   * @param {string} case_id - Case id of ireq
   * @param {object} note - Updated note
   */
  updateIreqListWithNote = (case_id, note) => {
    let ireq_updates = _.cloneDeep(this.state.ir_raw);
    let ireqs = ireq_updates.filter((ir) => ir.ireq_id.includes(case_id.split('-')[0]));
    for (let ireq of ireqs) {
      ireq.patient_note_info = JSON.stringify({
        date: note.modified_date,
        id: note.id,
        created_by_id: note.created_by_id,
      });
      ireq.patient_note_subject = note.subject;
      ireq.patient_note_text = note.note_text;
    }
    this.setState({ ir_raw: ireq_updates, note_updated: true });
  };

  /**
   * Displays item request form
   * @function
   * @param {string} ireq_id - Item request id
   * @returns {JSX} - JSX for IR form
   */
  displayIreqForm = (ireq_id) => {
    const ireq = this.state.ir_raw.find((ir) => ireq_id === ir.ireq_id);
    if (ireq) {
      const patient = {
        first_name: ireq.first_name,
        last_name: ireq.last_name,
        sex: ireq.patient_sex,
        dob: ireq.dob,
      };
      return (
        <Modal
          preset="pdf_viewer"
          header_text={`Item Request Form${ireq.patient_name ? ` - ${ireq.patient_name}` : ''}`}
          modal_size_class="modal-lg modal-lg-pdf"
          modal_body_class="modal-pdf"
          ir_invoice_type="ipp"
          ireq_id={ireq_id}
          onCloseButtonClick={() => this.props.setViewPlanModal(false)}
          theme="ipp"
          original_filename={getPDFNameTemplate(ireq_id, patient, 'IR.pdf')}
        />
      );
    }
  };

  /**
   * Redirects page to item request submission
   * @function
   * @param {string} ireq_id - Item request id
   */
  submitIreqRedirect = (ireq_id) => {
    const that = this;
    Axios.get(`/apiv3/ireq/${ireq_id}`)
      .then(function (result) {
        if (result?.data?.last_shipped_case_id) {
          submitIreqRedirect(result.data.last_shipped_case_id, that.props);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  render() {
    const that = this;
    const meta_data = ['case_id', 'ireq_id', 'first_name', 'last_name'];

    const table_data = [
      {
        id: 'patient_name',
        label: 'Patient',
        col_width: '21%',
        display: true,
        can_sort: true,
        data_fields: ['first_name', 'last_name', 'ireq_id'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field];
          });
          const name = `${field_values[0]} ${field_values[1]}`;
          const ireq_id = field_values[2];
          return (
            <div>
              {ireq_id && (
                <div>
                  <span className="case-id-label row-label div-table--item-restrict-text">
                    {ireq_id.endsWith('-IR1')
                      ? ireq_id.replace('-IR1', '-IR').replace('-DE1', '-DE').replace('-R1', '-R').replace('-DE0', '-DE10').replace('-IR0', '-IR10')
                      : ireq_id.replace('-DE1', '-DE').replace('-R1', '-R').replace('-DE0', '-DE10')}
                  </span>
                  {data.gen_2 && <span className="case-gen2-text">(Gen 2.0)</span>}
                </div>
              )}
              <div className="fs-exclude div-table--item-restrict-text">{name}</div>
            </div>
          );
        },
      },
      {
        id: 'created_date',
        label: 'Submitted',
        col_width: '13%',
        display: true,
        can_sort: true,
        data_function: (props, data, fields) => {
          return <>{that.getSubmissionDate(props, data)}</>;
        },
      },
      {
        id: 'status',
        label: 'Status',
        col_width: '25%',
        display: true,
        can_sort: true,
        data_function: that.getStatus,
      },
      {
        id: 'notes',
        label: 'Notes',
        col_width: '41%',
        display: true,
        data_fields: [],
        can_sort: false,
        get_class_name: () => 'div-table--item-multiple-rows',
        data_function: that.getNote,
      },
    ];

    const mobile_data = [
      {
        id: 'patient_name',
        data_fields: ['first_name', 'last_name'],
        data_function: (props, data, fields) => {
          let field_values = fields.map((field, index) => {
            return data[field];
          });

          field_values.splice(0, 0, '<span class="emphasis">');
          field_values.splice(3, 0, '</span>');

          return (
            <>
              <span dangerouslySetInnerHTML={{ __html: field_values.join(' ') }} />
              {data.gen_2 && <span className="case-gen2-text">(Gen 2.0)</span>}
            </>
          );
        },
      },
      {
        id: 'ireq_id',
        data_fields: ['ireq_id'],
        data_function: (props, data, fields) => {
          return (
            <span className="case-id">
              {data[fields].endsWith('-IR1')
                ? data[fields].replace('-IR1', '-IR').replace('-DE1', '-DE').replace('-R1', '-R').replace('-DE0', '-DE10').replace('-IR0', '-IR10')
                : data[fields].replace('-DE1', '-DE').replace('-R1', '-R').replace('-DE0', '-DE10')}
            </span>
          );
        },
      },
      {
        id: 'status',
        new_row: true,
        data_fields: [],
        data_function: (props, item_request, fields) => that.getStatus(props, item_request, fields, true),
      },
    ];

    if (
      (this.state.ir_view.length === 0 && this.state.search && this.state.search !== '') ||
      (this.state.ir_view.length === 0 && this.state.search_filter && this.state.search_filter !== '') ||
      (this.state.ir_view.length === 0 && this.state.status_filter && this.state.status_filter !== '')
    ) {
      return (
        <>
          <Helmet>
            <title>
              Item Request List
              {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} | InBrace
              Smile Design Studio
            </title>
          </Helmet>
          <ContentHeader title="Item Request List" />
          <div className="list-content">
            <IrFilter statuses={this.state.statuses} search={this.displaySearchBar()} />
            <div className="statuses-result-container">
              <div className="statuses-result">
                {this.state.search || this.state.search_filter ? (
                  <NoResult query={this.state.search} filter={this.state.search_filter} type="item_request" />
                ) : (
                  <NoRecords />
                )}
              </div>
            </div>
          </div>
        </>
      );
    } else if (this.state.ir_view.length === 0 && (this.state.search === '' || this.state.search === undefined) && this.state.filter === '') {
      return this.state.loading ? (
        <CircleLoader fullscreen={true} />
      ) : (
        <>
          <Helmet>
            <title>
              Item Request List
              {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} | InBrace
              Smile Design Studio
            </title>
          </Helmet>
          <CardContainer type="message">
            <h3>No Item Request Found</h3>
          </CardContainer>
        </>
      );
    } else {
      return (
        <div>
          <Helmet>
            <title>
              Item Request List
              {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} | InBrace
              Smile Design Studio
            </title>
          </Helmet>
          <ContentHeader title="Item Request List" />
          <div className="list-content">
            <IrFilter statuses={this.state.statuses} search={this.displaySearchBar()} />
            <div className="statuses-result-container">
              <div className="statuses-result">
                <CardContainer type="top-border" className="hide-on-mobile--top-border">
                  <DivTable
                    meta_data={meta_data}
                    table_data={table_data}
                    mobile_data={mobile_data}
                    field_data={this.state.ir_view}
                    sort_method={this.state.ir_sort_method}
                    sort_order_ascending={this.state.ir_sort_order_ascending}
                    showCancelCase={this.state.showCancelCase}
                    selected_case_id={this.state.selected_case_id}
                    selected_draft_id={this.state.selected_draft_id}
                    onSortClick={this.onSortClick}
                    onCaseTrClick={this.onCaseTrClick}
                    getRefinementSuffix={this.getRefinementSuffix}
                  />
                </CardContainer>
              </div>
              <div className="pagination__container">
                <div className="pagination__number">
                  {this.getStartRange()}-{this.getEndRange()} of {this.state.ir_length} Item Requests
                </div>
                <Pagination
                  className="pagination"
                  activePage={this.state.ir_active_page}
                  itemsCountPerPage={this.state.ir_row_size}
                  totalItemsCount={this.state.ir_length}
                  pageRangeDisplayed={this.state.ir_page_range}
                  onChange={this.onPageChange}
                  prevPageText={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                  nextPageText={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                  firstPageText={<i className="fa fa-angle-double-left" aria-hidden="true"></i>}
                  lastPageText={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    doctor_role: getDoctorRole(state),
    account_link_id: getAccountLinkId(state),
    additional_actions_modal: getAdditionalActionsModal(state),
    note_modal: getNoteModal(state),
    view_plan_modal: getViewPlanModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateNote: updateNote,
      setViewPlanModal: setViewPlanModal,
      setNoteModal: setNoteModal,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
