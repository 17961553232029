import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../modal/modal';
import { userHasPermission } from '../../common/permission';
import { isCaseBlocked } from '../../common/helpers';
import { removeCaseIdInitialNumber } from '../../common/functions';
import SessionExpire from '../../common/session_expire';

// Redux
import {
  getSegmentationQuestions,
  getTeethSelectorModal,
  getTeethSelectorCategory,
  getTeethSelectorQuestion,
  getNote,
  getIncompleteError,
  getIsEditModeSegmentation,
  getSegmentationSessionExpired,
} from '../../redux/reducers/bpp/production_tx_guide/segmentation';
import {
  closeTeethSelectorModal,
  onSelectQuestion,
  openTeethSelectorModal,
  onConfirmButtonClickTeethSelector,
  onNoteChange,
  setEditModeSegmentation,
  saveSegmentationProductionTxGuideSelections,
  onEditSegmentationTxGuideCancel,
  openSegmentationTxGuidePdfModal,
  setTreatmentPlanRevision,
} from '../../redux/actions/bpp/production_tx_guide/segmentation';
import { getIfuState } from '../../redux/reducers/ifu/ifu_state';

/**
 * Production TX Guide form for Segmentation section
 * @component
 * @alias SegmentationProductionTxGuide
 * @category BPP
 */
class SegmentationProductionTxGuide extends Component {
  componentDidMount() {
    this.props.setEditModeSegmentation(!this.props.is_tab_view);
    if (this.props.submission_process && this.props.submission_process.treatment_plan_revision) {
      this.props.setTreatmentPlanRevision(this.props.submission_process.treatment_plan_revision);
    }
  }

  /**
   * Determines if teeth are not selected for a checked question
   * @function
   * @return {Boolean} True or False
   */
  isQuestionIncomplete(selected_teeth) {
    return this.props.incomplete_error && selected_teeth.length === 0;
  }

  /**
   * Gets length of category object with the most questions
   * @function
   * @param {Object} category_questions_left - Left side category object
   * @param {Object} category_questions_right - Right side category object
   * @return {Number} Most number of questions
   */
  getLongestCategoryLength(category_questions_left, category_questions_right) {
    if (!category_questions_right) {
      return Object.keys(category_questions_left).length;
    }
    return Math.max(Object.keys(category_questions_left).length, Object.keys(category_questions_right).length);
  }

  /**
   * Displays single row of categories
   * @function
   * @param {String} category_name_left - Left side category name
   * @param {String} category_name_right - Right side category name
   * @return {JSX} Row of categories
   */
  displayFormRow(category_name_left, category_name_right, index) {
    return (
      <React.Fragment key={index}>
        <div className="row category">
          <div className="col-lg-6">
            <div className="bold-text">{category_name_left}</div>
          </div>
          <div className="col-lg-6">
            <div className="bold-text">{category_name_right}</div>
          </div>
        </div>
        {this.displayQuestionBoxes(category_name_left, category_name_right)}
      </React.Fragment>
    );
  }

  /**
   * Displays questions/checkboxes for a single row of categories
   * @function
   * @param {String} category_name_left - Left side category name
   * @param {String} category_name_right - Right side category name
   * @return {JSX} Question boxes
   */
  displayQuestionBoxes(category_name_left, category_name_right) {
    const category_questions_left = this.props.segmentation_questions[category_name_left];
    const category_questions_right = this.props.segmentation_questions[category_name_right];

    return (
      <div className="row question-row">
        {[...Array(this.getLongestCategoryLength(category_questions_left, category_questions_right))].map((question, index) => {
          const question_left = Object.keys(category_questions_left)[index];
          const question_right = category_questions_right ? Object.keys(category_questions_right)[index] : null;

          return (
            <React.Fragment key={index}>
              <div className="col-lg-6">{this.displayQuestion(category_name_left, category_questions_left, question_left)}</div>
              <div className="col-lg-6">{question_right && this.displayQuestion(category_name_right, category_questions_right, question_right)}</div>
            </React.Fragment>
          );
        })}
      </div>
    );
  }

  /**
   * Displays single question/checkbox
   * @function
   * @param {String} category_name - Category name
   * @param {String} category_questions - Questions under the category
   * @param {String} question - Question to display
   * @return {JSX} JSX for question
   */
  displayQuestion(category_name, category_questions, question) {
    const selected_teeth = this.props.segmentation_questions[category_name][question].selected_teeth;
    const is_selected = this.props.segmentation_questions[category_name][question].is_selected;
    return (
      <React.Fragment>
        <div>
          <input
            data-category={category_name}
            data-question={question}
            type="checkbox"
            className="pointer"
            onChange={(e) => this.props.onSelectQuestion(e, this.props.is_tab_view)}
            checked={category_questions[question].is_selected}
            disabled={!this.props.is_edit_mode_segmentation}
          />
          {question}
          {this.props.is_tab_view && !this.props.is_edit_mode_segmentation && is_selected && selected_teeth && selected_teeth.length > 0 && (
            <span>: {selected_teeth.join(', ')}</span>
          )}
        </div>
        {category_questions[question].is_selected && this.props.is_edit_mode_segmentation && this.displayTeethSelectorLink(category_name, question)}
      </React.Fragment>
    );
  }

  /**
   * Displays teeth selector link for a question
   * @function
   * @param {String} category_name - Category name
   * @param {String} question - Question to display
   * @return {JSX} JSX for selector link
   */
  displayTeethSelectorLink(category_name, question) {
    const selected_teeth = this.props.segmentation_questions[category_name][question].selected_teeth;
    return (
      <div className="location">
        <span
          className={`bracket-link${this.isQuestionIncomplete(selected_teeth) ? ' location__incomplete' : ''}`}
          onClick={() => this.props.openTeethSelectorModal(category_name, question)}
        >
          Specify location*
        </span>
        <span>: {selected_teeth.length > 0 ? selected_teeth.join(', ') : 'N/A'}</span>
      </div>
    );
  }

  /**
   * Displays buttons under treatment guide in tab view
   * @function
   * @return {JSX} JSX for tab buttons
   */
  displayTabButtons() {
    return this.props.is_edit_mode_segmentation && this.props.case_status !== 'Quality Control Review' && !isCaseBlocked(this.props.case_status) ? (
      <React.Fragment>
        {this.props.incomplete_error && <div className="incomplete-error">Please complete the required field(s) marked in red.</div>}
        <div className="button-panel">
          <button type="button" className="btn btn-light" onClick={this.props.saveSegmentationProductionTxGuideSelections}>
            Save
          </button>
          <button type="button" className="btn btn-light btn-cancel" onClick={this.props.onEditSegmentationTxGuideCancel}>
            Cancel
          </button>
        </div>
      </React.Fragment>
    ) : (
      <div className="button-panel">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => this.props.openSegmentationTxGuidePdfModal(this.props.case_id)}
        >
          Preview Form
        </button>
        {this.canEditQuestions() && (
          <button type="button" className="btn btn-light" onClick={() => this.props.setEditModeSegmentation(true)}>
            Edit
          </button>
        )}
      </div>
    );
  }

  canEditQuestions() {
    return (
      this.props.case_status !== 'Quality Control Review' &&
      this.props.case_status !== '​STATUS_DOCTOR_APPROVAL' &&
      !isCaseBlocked(this.props.case_status) &&
      !(this.props.ifu_state && (this.props.ifu_state.ifu_screen === 'in-progress' || this.props.ifu_state.ifu_screen === 'result')) &&
      userHasPermission('CASE_SEGMENTATION', this.props.user_roles_and_permissions.permissions) &&
      !(this.props.gen_2 && this.props.case_status === 'CAD Process' && this.props.cad_status !== 'Stage I')
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="question-container">
          <div className={`${this.props.className ? this.props.className : ''} process-box`}>
            <div className="process-box-title bold-text">Special Instructions:</div>
            {_.map(_.chunk(Object.keys(this.props.segmentation_questions), 2), ([category_name_left, category_name_right], index) => {
              return this.displayFormRow(category_name_left, category_name_right, index);
            })}
            <div className="row category bold-text padding-left">Other</div>
            <div className="question-row">
              <textarea
                className="form-control"
                placeholder="Specify other special instructions if not listed above or additional details."
                rows={3}
                maxLength={750}
                onChange={this.props.onNoteChange}
                value={this.props.note}
                disabled={!this.props.is_edit_mode_segmentation}
              />
            </div>
            {this.props.teeth_selector_modal && (
              <Modal
                theme="bpp"
                preset="teeth-chart-8"
                modal_size_class="modal-lg"
                header_text={`${this.props.teeth_selector_category} - ${this.props.teeth_selector_question} - ${removeCaseIdInitialNumber(this.props.case_id)}`}
                confirm_btn_text="Apply"
                onConfirmButtonClick={() => this.props.onConfirmButtonClickTeethSelector(this.props.is_tab_view)}
                close_btn_text="Cancel"
                onCloseButtonClick={this.props.closeTeethSelectorModal}
              />
            )}
          </div>
        </div>
        {this.props.is_tab_view && this.displayTabButtons()}
        {this.props.session_expired && <SessionExpire />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    segmentation_questions: getSegmentationQuestions(state),
    teeth_selector_modal: getTeethSelectorModal(state),
    teeth_selector_category: getTeethSelectorCategory(state),
    teeth_selector_question: getTeethSelectorQuestion(state),
    note: getNote(state),
    incomplete_error: getIncompleteError(state),
    is_edit_mode_segmentation: getIsEditModeSegmentation(state),
    ifu_state: getIfuState(state),
    session_expired: getSegmentationSessionExpired(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSelectQuestion: onSelectQuestion,
      openTeethSelectorModal: openTeethSelectorModal,
      closeTeethSelectorModal: closeTeethSelectorModal,
      onConfirmButtonClickTeethSelector: onConfirmButtonClickTeethSelector,
      onNoteChange: onNoteChange,
      setEditModeSegmentation: setEditModeSegmentation,
      saveSegmentationProductionTxGuideSelections: saveSegmentationProductionTxGuideSelections,
      onEditSegmentationTxGuideCancel: onEditSegmentationTxGuideCancel,
      openSegmentationTxGuidePdfModal: openSegmentationTxGuidePdfModal,
      setTreatmentPlanRevision: setTreatmentPlanRevision,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SegmentationProductionTxGuide);
