import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Exc } from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { textFieldCheck, removeEmoji } from '../../../common/functions';

/**
 * Displays a controlled input
 * @component
 */
function ControlledInput({
  className = '',
  maxLength = 0,
  onSave = (val) => {},
  displayButtons = false,
  displayCount = false,
  value = '',
  type = 'text',
  autocomplete = [],
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [count, setCount] = useState(value.length);
  const [hasError, setHasError] = useState(false);
  const [matches, setMatches] = useState([{}]);
  const updateMatches = useCallback(
    (event) => {
      if (autocomplete.length > 0 && count > 0) {
        const partialMatches = autocomplete.filter((item) => item.name?.toLowerCase()?.includes(inputValue.toLowerCase()));
        if (partialMatches && partialMatches.length) {
          setMatches(partialMatches);
        }

        if (partialMatches && partialMatches.length === 0) {
          setMatches([]);
        }
      }

      if (event.key === 'Escape' || event.key === 'Enter') {
        setMatches([]);
      }
    },
    [inputValue, autocomplete, count]
  );
  useEffect(() => {
    const size = inputValue.length;
    setCount(size);
    if (maxLength > 0 && size > maxLength) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [inputValue, maxLength, updateMatches]);
  /**
   * Handles the blur
   * @param {*} e
   * @function
   */
  const handleBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  /**
   * Handles the click event
   * @param {*} e
   * @function
   */
  const handleClick = (e) => {
    if (displayButtons) {
      setOpen(true);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };
  /**
   * Handles the change event
   * @function
   */
  const handleSave = () => {
    if (!hasError) {
      setOpen(false);
      onSave(inputValue);
    }
  };

  /**
   * Handles the change event
   * @function
   */
  const handleCancel = () => {
    setOpen(false);
    if (props.onCancel) {
      if (props.original_value || props.original_value === '') {
        setInputValue(props.original_value);
      }
      props.onCancel();
    }
  };

  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  const handleChange = (e) => {
    e.currentTarget.value = removeEmoji(textFieldCheck(e.currentTarget.value));
    setInputValue(e.currentTarget.value);

    if (props.onChange) {
      props.onChange(e);
    }
  };
  /**
   * Handles the click event
   * @param {string} value
   */
  const handleAutocompleteClick = (value) => {
    setInputValue(value);
    setMatches([]);
  };

  const { permissions } = useContext(UserPermissionsContext);
  const canEdit = userHasPermission('IPP_EDIT', permissions);

  const inputProps = {
    ...props,
    className: `${className ? className : ''}${hasError ? ' has-error' : ''} text-input`,
    value: inputValue,
    onChange: handleChange,
    onClick: handleClick,
    onKeyUp: updateMatches,
    disabled: props.disabled || !canEdit,
  };

  const Input = {
    text: <input type="text" {...inputProps} />,
    textarea: <textarea {...inputProps} />,
  };

  return (
    <div className="input-container" onBlur={handleBlur}>
      {Input[type]}
      {displayCount && open && (
        <div className={`${type === 'textarea' ? 'text-area__count' : 'text-input__count'} ${hasError ? 'text-input__count--error' : ''}`}>
          <span>
            {count}/{maxLength}
          </span>
        </div>
      )}
      {hasError && (
        <div className="text-input__error">
          <Exc className="alert--icon alert--icon__no-float" />
          Character limit exceeded
        </div>
      )}
      {matches && matches.length > 0 && !isEmpty(matches[0]) && (
        <div className="autocomplete">
          <div className="autocomplete__list">
            {matches.map((item, idx) => (
              <div className="autocomplete__item" key={idx} onClick={() => handleAutocompleteClick(item.name)}>
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
      {displayButtons && open && (
        <div className="svg-button">
          <div className="action-buttons save-svg-button" onClick={() => handleSave()} onDoubleClick={() => handleSave()}>
            <span>
              <i className="fa fa-check" aria-hidden="true" />
            </span>
          </div>
          <div className="action-buttons cancel-svg-button" onClick={() => handleCancel()} onDoubleClick={() => handleCancel()}>
            <span>
              <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ControlledInput;
