import React, { Component } from 'react';
import './context_menu.scss';

/**
 * Context Menu Component
 * ----
 * Nest inside element with position: relative. Pass a ref to component
 * as container property. Pass menu items to component as children. Pass either
 * 'div' or 'table' to containerType depending on what element you are nesting in
 * to avoid element nesting errors.
 * @property container
 * @property containerType
 */

class ContextMenu extends Component {
  state = {
    xPos: 0,
    yPos: 0,
    showMenu: false,
  };

  constructor(props) {
    super(props);
    this.handleContextMenu.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    document.addEventListener('contextmenu', this.handleContextMenu);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    document.removeEventListener('contextmenu', this.handleContextMenu);
  }

  onMouseEnter = (event) => {
    this.setState({
      showMenu: true,
    });
  };
  onMouseLeave = (event) => {
    this.setState({
      showMenu: false,
    });
  };

  handleClick = (event) => {
    if (this.state.showMenu) this.setState({ showMenu: false });
  };

  handleContextMenu = (event) => {
    if (this.props.container.current.contains(event.target)) {
      event.preventDefault();
      this.setState({
        xPos: event.pageX - this.props.container.current.getBoundingClientRect().left,
        yPos: event.pageY - this.props.container.current.getBoundingClientRect().top - window.pageYOffset,
        showMenu: true,
      });
    }
  };

  render() {
    const { showMenu, xPos, yPos } = this.state;

    return !showMenu ? null : this.props.containerType === 'table' ? (
      <tr>
        <td className="context-menu" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} style={{ top: yPos, left: xPos + 2 }}>
          {this.props.children}
        </td>
      </tr>
    ) : (
      <div className="context-menu" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} style={{ top: yPos, left: xPos + 2 }}>
        {this.props.children}
      </div>
    );
  }
}

export default ContextMenu;
