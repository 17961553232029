import { FETCH_CASE_FILES_START, FETCH_CASE_FILES_SUCCESS, FETCH_CASE_FILES_ERROR } from '../../../actions/ipp/case_details/case_files';

const initialState = {
  case_files_list: {},
  loading: true,
  error: null,
};

export function caseFileReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_FILES_START:
      return { ...state, loading: true };

    case FETCH_CASE_FILES_SUCCESS:
      return { ...state, case_files_list: action.result, loading: false };

    case FETCH_CASE_FILES_ERROR:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}

export const getCaseFileList = (state) => state.caseFileReducer.case_files_list;
export const getCaseFileListLoading = (state) => state.caseFileReducer.loading;
export const getCaseFileError = (state) => state.caseFileReducer.error;
