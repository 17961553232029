import React from 'react';
import PropTypes from 'prop-types';

import SelectionBarItem from './selection_bar_item';
import SuperImpositionControls from './superimposition_controls';

/**
 * Displays the selection bar bottom
 * @component
 */
function SelectionBarBottom(props) {
  const bar_items = [
    {
      key: 'gingiva',
      text: <span>Gingiva</span>,
      active: props.gingiva,
      onClick: () => props.onToggleOption('gingiva'),
    },
    {
      key: 'ipr',
      text: (
        <span>
          <span className="underline">I</span>PR
        </span>
      ),
      active: props.ipr,
      onClick: () => props.onToggleOption('ipr'),
    },
    {
      key: 'grid',
      text: (
        <span>
          <span className="underline">G</span>rid
        </span>
      ),
      active: props.grid,
      onClick: () => props.onToggleOption('grid'),
    },
    {
      key: 'superimpose',
      text: (
        <span className="superimpose-event">
          <span className="underline">S</span>uperimposition
        </span>
      ),
      active: props.superimpose,
      onClick: () => props.onToggleOption('superimpose'),
    },
  ];

  return (
    <>
      <div className="selection-bar-bottom" style={{ display: props.isSmileSimulationSetup ? 'none' : '' }}>
        {bar_items.map((item) => (
          <SelectionBarItem disabled={props.disabled} key={item.key} text={item.text} active={item.active} onClick={item.onClick} />
        ))}
        <SuperImpositionControls {...props} />
      </div>
    </>
  );
}

SelectionBarBottom.propTypes = {
  gingiva: PropTypes.bool.isRequired,
  grid: PropTypes.bool.isRequired,
  ipr: PropTypes.bool.isRequired,
  superimpose: PropTypes.bool.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectionBarBottom;
