/**
 * File:pr_content.js
 * Copyright: (c) Copyright March 2020 by InBrace
 * Authors: Katie Chen
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './pr_list.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import PaginationContainer from '../../components/pagination/pagination_container';
import SortHeader from '../../components/layout/sort_header';
import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';

// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader, convertDate } from '../../common/functions';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from '../../common/route';
import {
  onChangeSearch,
  applyProgressRecordSearch,
  translateFilterStatus,
  getProgressRecordsFromRow,
  getProgressRecordsFromSearch,
  getProgressRecordsFromFilter,
  getProgressRecordsFromEndDate,
  getProgressRecordsFromStartDate,
  orderProgressRecordsByDefault,
  searchErrorMessage,
} from '../../common/search';
import { getSubmissionDateDefaultSort } from '../../common/helpers';
import { tableRowLink } from '../../common/table';
import CaseListSidebar from '../case_list/case_list_sidebar';
import CaseListFilter from '../case_list/case_list_filter';

class PrContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      search: '',
      filter: '',
      side_collapse: false,
      column_filter: '',
      status_filter: '',
      start_date_filter: '',
      end_date_filter: '',
      statuses: {
        assigned_me_csr: 0,
        assigned_me_pr: 0,
        all: 0,
        unassigned_csr: 0,
        unassigned_pr: 0,
        pending: 0,
        closed: 0,
      },
      pr_raw: [],
      pr_view: [],
      pr_sort_method: 'created_date',
      pr_sort_order_ascending: {
        pr_id: true,
        patient_name: true,
        doctor_name: true,
        created_date: false,
        treatment: true,
        status: true,
        status_date: false,
        doctor_action_date: true,
        doctor_new_record_date: true,
        doctor_setup_date: true,
      },
      user_id: '',
      submission_date_error: false,
      pr_row_size: 25,
      pr_active_page: 1,
      is_bottom: false,
      pr_length: 1,
      pr_page_range: 5,
      newTabUrl: '',
    };

    this.onChangeSearchPatient = this.onChangeSearchPatient.bind(this);
    this.onPageChange = this.onPageChange.bind(this);

    this.onPatientClick = this.onPatientClick.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onQueryFilterSelection = this.onQueryFilterSelection.bind(this);
    this.onStatusFilterSelection = this.onStatusFilterSelection.bind(this);
    this.onSideBarCollapseClick = this.onSideBarCollapseClick.bind(this);
    this.hasAssignTo = this.hasAssignTo.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.setRoute = this.setRoute.bind(this);
    this.prTable = React.createRef();
  }

  hasAssignTo() {
    return false;
  }

  /**
   * When page change update list content
   *
   * @function
   * @param {Object} active_page - Contains page number to paginated to
   */
  onPageChange(active_page) {
    //Allow for page changes without when filters are not applied yet
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute();
    const pr_sort_method = this.state.pr_sort_method;

    let pr_view = filter
      ? getProgressRecordsFromFilter(this.state.pr_raw, filter, this.state.user_id, this.state.assigned_csr, this.state.assigned_pr)
      : getProgressRecordsFromSearch(
          getProgressRecordsFromEndDate(getProgressRecordsFromStartDate(this.state.pr_raw, start_date), end_date),
          this.state.search,
          column
        );

    this.setState({
      pr_active_page: active_page,
      pr_view: getProgressRecordsFromRow(
        this.setOrderByMethod(pr_view, pr_sort_method, this.state.pr_sort_order_ascending[pr_sort_method]),
        active_page,
        this.state.pr_row_size
      ),
    });
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    Axios.get('/apiV2/allprogressrecords')
      .then(function (res) {
        let pr_raw = that.translatePR(res.data.progress_records);
        let { assigned_csr, assigned_pr } = res.data;

        const pr_sort_method = that.getSortMethodByFilterSelection(that.state.filter, filter, that.state.pr_sort_method);
        const pr_view = filter
          ? getProgressRecordsFromFilter(pr_raw, filter, res.data.user_id, assigned_csr, assigned_pr)
          : getProgressRecordsFromSearch(getProgressRecordsFromEndDate(getProgressRecordsFromStartDate(pr_raw, start_date), end_date), search, column);

        //TBD WITH FILTER
        that.setState({
          loading: false,
          search: search,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          pr_raw: pr_raw,
          //   statuses: res.data.statuses,
          pr_view: that.setOrderByMethod(
            getProgressRecordsFromRow(orderProgressRecordsByDefault(that, pr_view), that.state.pr_active_page, that.state.pr_row_size),
            pr_sort_method,
            that.state.pr_sort_order_ascending[pr_sort_method]
          ),
          pr_length: pr_view.length,
          pr_sort_method: pr_sort_method,
          assigned_csr: assigned_csr,
          assigned_pr: assigned_pr,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    this.updateAlerts();
  }

  updateAlerts() {
    setTokenHeader();
    const that = this;

    Axios.get(`/apiV2/pralerts/`)
      .then(function (res) {
        that.setState({ statuses: res.data.statuses, user_id: res.data.user_id });
      })
      .catch(function (err) {
        //ignore error for side nav
      });
  }

  componentDidUpdate() {
    const that = this;
    const state_filter = this.state.filter;
    const search = getBusinessSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = this.state.status_filter;
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const refresh = this.state.refresh;
    const active_page = 1;
    const row_size = this.state.pr_row_size;
    let pr_view = [];
    if ((state_filter !== filter || refresh) && that.state.user_id) {
      if (!this.state.submission_date_error) {
        pr_view = filter
          ? getProgressRecordsFromFilter(that.state.pr_raw, filter, that.state.user_id, that.state.assigned_csr, that.state.assigned_pr)
          : getProgressRecordsFromSearch(
              getProgressRecordsFromEndDate(getProgressRecordsFromStartDate(that.state.pr_raw, start_date), end_date),
              search,
              column
            );

        const pr_sort_method = that.getSortMethodByFilterSelection(state_filter, filter, that.state.pr_sort_method);

        that.setState({
          pr_view: getProgressRecordsFromRow(
            that.setOrderByMethod(pr_view, pr_sort_method, that.state.pr_sort_order_ascending[pr_sort_method]),
            active_page,
            row_size
          ),
          pr_active_page: active_page,
          pr_length: pr_view.length,
          pr_sort_method: pr_sort_method,
          search: search,
          filter: filter,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          refresh: false,
        });
      }
    }

    let numCasesRaw = this.state.pr_raw.length;
    let numCases = this.state.pr_length;

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.endsWith('progress') &&
      !this.props.history.location.search &&
      numCases !== numCasesRaw &&
      !this.state.refresh
    ) {
      // Refresh route when cases header is clicked or cases icon is clicked
      this.refreshRoute();
    }
  }

  translatePR(progress_record_list) {
    for (let i = 0; i < progress_record_list.length; i++) {
      progress_record_list[i].created_date_val = new Date(moment.tz(progress_record_list[i].created_date, 'America/Los_Angeles').format()).getTime();
    }

    return progress_record_list;
  }

  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace(this.props.history.location.pathname);

      this.setState({
        refresh: true,
      });
    }
  };

  onClickSubMenu = (filter) => {
    this.setState({ submission_date_error: false });
    this.props.history.replace(this.setRoute(filter, 'filter'));
    this.onPageChange(1);
  };

  onSideBarCollapseClick() {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  }

  onChangeSearchPatient = (event) => {
    onChangeSearch(this, event);
  };

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  applySearch() {
    applyProgressRecordSearch(this);
  }

  onQueryFilterSelection = (event) => {
    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);

    this.setState({
      column_filter: uri_text,
    });
  };

  onStatusFilterSelection = (event) => {
    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);
    this.setState({
      status_filter: uri_text,
    });
  };

  onStartDateSelection = (event) => {
    this.setState({
      start_date_filter: event.target.value,
    });
  };

  onEndDateSelection = (event) => {
    this.setState({
      end_date_filter: event.target.value,
    });
  };

  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  /**
   * Sort list based on click event
   *
   * @function
   * @param {Object} event - Event of click
   */
  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';
    let pr_sort_order_ascending = this.state.pr_sort_order_ascending;

    if (sort_method === this.state.pr_sort_method) {
      //Flip order
      pr_sort_order_ascending[sort_method] = !pr_sort_order_ascending[sort_method];
    }

    let pr_view = filter
      ? getProgressRecordsFromFilter(this.state.pr_raw, filter, this.state.user_id, this.state.assigned_csr, this.state.assigned_pr)
      : getProgressRecordsFromSearch(
          getProgressRecordsFromEndDate(getProgressRecordsFromStartDate(this.state.pr_raw, start_date), end_date),
          this.state.search,
          column
        );

    pr_view = getProgressRecordsFromRow(
      this.setOrderByMethod(pr_view, sort_method, pr_sort_order_ascending[sort_method]),
      this.state.pr_active_page,
      this.state.pr_row_size
    );

    this.setState({
      pr_view: pr_view,
      pr_sort_method: sort_method,
      pr_sort_order_ascending: pr_sort_order_ascending,
    });
  };

  onPatientClick = (event) => {
    const pathname = `/business/portal/case/${event.currentTarget.dataset.caseid}/progress`;
    tableRowLink(event, pathname, this.props);
  };

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  getStartRange() {
    return this.state.pr_active_page * this.state.pr_row_size - this.state.pr_row_size + 1;
  }

  getEndRange() {
    return this.state.pr_active_page * this.state.pr_row_size > this.state.pr_length
      ? this.state.pr_length
      : this.state.pr_active_page * this.state.pr_row_size;
  }

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;
    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();
    let status = route_type === 'status' ? uri_text : getBusinessStatusValueFromRoute();
    let start_date = route_type === 'start' ? uri_text : getBusinessStartDateValueFromRoute();
    let end_date = route_type === 'end' ? uri_text : getBusinessEndDateValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';
    status = status !== '' ? `status=${status}` : '';
    start_date = start_date !== '' ? `start=${start_date}` : '';
    end_date = end_date !== '' ? `end=${end_date}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }

      if (status) {
        param.push(status);
      }

      if (start_date) {
        param.push(start_date);
      }

      if (end_date) {
        param.push(end_date);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  setOrderByMethod(pr_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'type':
        pr_view = this.orderPrsByType(pr_view, sort_value);
        break;

      case 'case_id':
        pr_view = this.orderPrsByCaseId(pr_view, sort_value);
        break;

      case 'doctor_name':
        pr_view = this.orderPrsByDoctorName(pr_view, sort_value);
        break;

      case 'patient_name':
        pr_view = this.orderPrsByName(pr_view, sort_value);
        break;

      case 'created_date':
        pr_view = this.orderPrsBySubmissionDate(pr_view, sort_value);
        break;

      case 'assigned_to':
        pr_view = this.orderPrsByAssignedTo(pr_view, sort_value);
        break;

      case 'next_appointment':
        pr_view = this.orderPrsByNextAppointment(pr_view, sort_value);
        break;

      default:
    }

    return pr_view;
  }

  orderPrsByType(progress_record, sort) {
    return _.orderBy(progress_record, ['type'], [sort]);
  }

  orderPrsByCaseId(progress_record, sort) {
    return _.orderBy(progress_record, ['case_id'], [sort]);
  }

  orderPrsByDoctorName(progress_record, sort) {
    return _.orderBy(progress_record, ['doctor_name', 'submission_date_priority'], [sort, getSubmissionDateDefaultSort()]);
  }

  orderPrsByName(progress_record, sort) {
    return _.orderBy(progress_record, ['first_name', 'last_name', 'submission_date_priority'], [sort, sort, getSubmissionDateDefaultSort()]);
  }

  orderPrsBySubmissionDate(progress_record, sort) {
    return _.orderBy(progress_record, ['created_date', 'status_priority'], [sort]);
  }

  orderPrsByAssignedTo(progress_record, sort) {
    return _.orderBy(progress_record, ['assigned_to', 'submission_date_priority'], [sort]);
  }

  orderPrsByNextAppointment(progress_record, sort) {
    return _.orderBy(progress_record, ['next_appointment', 'submission_date_priority'], [sort]);
  }

  getSortMethodByFilterSelection = (state_filter, filter, sort_method) => {
    let sort_method_update = sort_method;

    if (state_filter !== filter) {
      sort_method_update = 'created_date';
    }

    return sort_method_update;
  };

  /**
   * Right click handler opens context menu
   * @function
   */
  onPrRightClick = (event) => {
    this.setState({
      newTabUrl: `/business/portal/case/${event.currentTarget.dataset.caseid}/progress`,
    });
  };

  /**
   * Creates a single sidebar filter configuration
   * @function
   * @param {String} filter_key - The id of the filter
   * @param {String} text - The display text of the filter
   * @param {Array} [submenu=undefined] - The list of submenu under the filter
   * @returns {Object} A single filter config
   */
   buildSingleSidebarFilter(filter_key, text, submenu = undefined) {
    const { filter, statuses } = this.state;
    return {
      filter: filter_key,
      text: text,
      count: statuses[filter_key],
      active: filter === filter_key,
      onClick: () => this.onClickSubMenu(filter_key),
      submenu: submenu,
    };
  }

  /**
   * Creates the list of filters configurations
   * @function
   * @returns {Array} The result menu filters
   */
   buildSidebarFilters() {
    return [
      this.buildSingleSidebarFilter('assigned_me_pr', 'PR Assigned to Me'),
      this.buildSingleSidebarFilter('assigned_me_csr', 'CSR Assigned to Me'),
      this.buildSingleSidebarFilter('all', 'All Submissions'),
      this.buildSingleSidebarFilter('unassigned_pr', 'Unassigned PR'),
      this.buildSingleSidebarFilter('unassigned_csr', 'Unassigned Pending CSR'),
      this.buildSingleSidebarFilter('pending', 'Pending CSR'),
      this.buildSingleSidebarFilter('closed', 'Closed CSR'),
    ];
  }

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div className="">
          <Helmet>
            <title>Progress Records List | InBrace Business Portal</title>
          </Helmet>
          <CaseListSidebar side_collapse={this.state.side_collapse} onClickCollapse={this.onSideBarCollapseClick}>
            {/* eslint-disable-next-line */}
            <a className="submenu-title" onClick={this.refreshRoute}>
              Progress Records
            </a>
            <CaseListFilter filters={this.buildSidebarFilters()} />
          </CaseListSidebar>
          <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
            <div className="main-content">
              <div className="dark-options">
                <div className="page-heading">Progress Records</div>
                <div className="dark-search">
                  <input
                    type="text"
                    className="form-control search-bar-dark font-awesome"
                    placeholder="Search"
                    aria-describedby="basic-addon1"
                    onChange={this.onChangeSearchPatient}
                    onKeyPress={this.keyPress}
                    value={this.state.search}
                  />
                </div>
                <div className="dark-filter">
                  <div className="select-container-date">
                    <div className="text-center light-gray-text">Submission Date Between</div>
                    <div>
                      <input
                        className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                        type="date"
                        name="start"
                        value={this.state.start_date_filter}
                        onChange={this.onStartDateSelection}
                      />
                      <input
                        className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                        type="date"
                        name="end"
                        value={this.state.end_date_filter}
                        onChange={this.onEndDateSelection}
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div>
              {this.state.pr_view.length > 0 ? (
                <div className="table-case-view">
                  <table className="table table-condensed table-striped table-industry">
                    <thead className="table-inbrace-dark-industry">
                      <tr>
                        <th className="type-width" data-method="type" onClick={this.onSortClick}>
                          <SortHeader
                            title="Type"
                            category="type"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['type']}
                          />
                        </th>
                        <th className="caseid-width" data-method="case_id" onClick={this.onSortClick}>
                          <SortHeader
                            title="Case ID"
                            category="case_id"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['case_id']}
                          />
                        </th>
                        <th className="submission-width" data-method="created_date" onClick={this.onSortClick}>
                          <SortHeader
                            title="Submission"
                            category="created_date"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['created_date']}
                          />
                        </th>
                        <th className="name-width" data-method="doctor_name" onClick={this.onSortClick}>
                          <SortHeader
                            title="Doctor"
                            category="doctor_name"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['doctor_name']}
                          />
                        </th>
                        <th className="name-width" data-method="patient_name" onClick={this.onSortClick}>
                          <SortHeader
                            title="Patient"
                            category="patient_name"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['patient_name']}
                          />
                        </th>
                        <th className="name-width" data-method="assigned_to" onClick={this.onSortClick}>
                          <SortHeader
                            title="Assigned To"
                            category="assigned_to"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['assigned_to']}
                          />
                        </th>
                        <th className="name-width" data-method="next_appointment" onClick={this.onSortClick}>
                          <SortHeader
                            title="Next Appointment"
                            category="next_appointment"
                            sort_method={this.state.pr_sort_method}
                            ascending={this.state.pr_sort_order_ascending['next_appointment']}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody ref={this.prTable} className="table-inbrace-dark-industry context-menu-container">
                      <ContextMenu container={this.prTable} containerType="table">
                        <NewTabLink url={this.state.newTabUrl} />
                      </ContextMenu>
                      {this.state.pr_view.map((progress_record, index) => {
                        return (
                          <tr
                            className="pointer"
                            key={index}
                            onMouseUp={this.onPatientClick}
                            onContextMenu={this.onPrRightClick}
                            data-progressid={progress_record.progress_id}
                            data-caseid={progress_record.case_id}
                          >
                            <td className="td-industry-offset type-width">{progress_record.type}</td>
                            <td>
                              {progress_record.case_id}
                              {progress_record.gen_2 ? <span className="case-gen2">Gen 2.0</span> : null}
                            </td>
                            <td>{convertDate(progress_record.created_date)}</td>
                            <td>{progress_record.doctor_name}</td>
                            <td className="fs-exclude">{progress_record.patient_name}</td>
                            <td>{progress_record.assigned_to ? progress_record.assigned_to : '- - - - - - - - - -'}</td>
                            <td>{progress_record.next_appointment ? convertDate(progress_record.next_appointment) : '- - - - - - - - - -'}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className={this.state.is_bottom ? 'pagination-bottom pagination-dark' : 'pagination-dark'}>
                    <PaginationContainer
                      theme="bpp"
                      isBottom={this.state.is_bottom}
                      activePage={this.state.pr_active_page}
                      itemsCountPerPage={this.state.pr_row_size}
                      totalItemsCount={this.state.pr_length}
                      pageRangeDisplayed={this.state.pr_page_range}
                      onChange={this.onPageChange}
                      startRange={this.getStartRange()}
                      endRange={this.getEndRange()}
                      type="Progress Records"
                    />
                  </div>
                </div>
              ) : (
                searchErrorMessage(getBusinessSearchValueFromRoute(), 'progress records')
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
export default withRouter(PrContent);
