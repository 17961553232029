import React, { Component } from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { recordStatesToCssStyle } from '../../../common/functions';
import CircleLoader from '../../../components/loader/circle_loader';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const zoomStep = 0.1;
export const maxScale = 5;
export const minScale = 0.1;
export const defaultScale = 1;
export const defaultRotate = 0;
export const initialPosition = { x: 0, y: 0 };
export const controlsDisabled = false;

export const initialState = {
  scale: defaultScale,
  flipH: false,
  flipV: false,
  rotate: defaultRotate,
};

class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      loading: true,
      load_error: false,
    };
  }

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
    if (this.ref && this.ref.current && this.props.zoomOut && this.props.zoomIn) {
      this.ref.current.addEventListener('wheel', this.onWheel);
    }
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentWillUnmount() {
    if (this.ref && this.ref.current) {
      this.ref.current.removeEventListener('wheel', this.onWheel);
    }
  }

  onDrag = (e, data) => this.props.setPosition({ x: data.x, y: data.y });

  onWheel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.deltaY > 0 && !this.props.controlsDisabled) {
      this.props.zoomOut(e);
    } else if (e.deltaY < 0) {
      this.props.zoomIn(e);
    }
  };

  /**
   * Handle on image load
   * @function
   */
  onLoad = () => {
    this.setState({ loading: false });
  };

  /**
   * Handle on image load error
   * @function
   */
  onError = () => {
    this.setState({ loading: false, load_error: true });
  };

  /**
   * Returns transformed image view
   * @function
   * @param {string} transform - transform values
   * @param {string} src - image src
   * @return {JSX} image view element
   */
  getImageView = (transform, src) => {
    return (
      <img
        style={{
          transform: transform,
          maxWidth: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        draggable="false"
        onLoad={this.onLoad}
        onError={this.onError}
        src={src}
        alt=""
      />
    );
  };

  /**
   * Returns transformed draggable image view
   * @function
   * @param {string} transform - transform values
   * @param {string} src - image src
   * @param {string} position - draggable position values
   * @return {JSX} draggable image view element
   */
  getDraggableImageView = (transform, src, position) => {
    return (
      <Draggable position={position} onDrag={this.onDrag}>
        <div ref={this.ref} style={{ cursor: 'move', height: '100%', textAlign: 'center' }}>
          <img
            style={{
              transform: transform,
              maxWidth: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            draggable="false"
            onLoad={this.onLoad}
            onError={this.onError}
            src={src}
            alt=""
          />
        </div>
      </Draggable>
    );
  };

  /**
   * Displays image view element based on image attributes
   * @function
   * @return {JSX} image view element
   */
  displayImageView = () => {
    const { position, src, controlsDisabled, image } = this.props;
    const transform = src === '/static/img/no-image.png' ? '' : recordStatesToCssStyle(this.props, this.props.ignore_zoom);
    const loading_icon = <CircleLoader />;
    const image_view = this.getImageView(transform, src);
    const draggable_image_view = this.getDraggableImageView(transform, src, position);
    if (image?.loading) {
      return loading_icon;
    } else if (controlsDisabled) {
      return image_view;
    } else {
      return draggable_image_view;
    }
  };

  render() {
    return <Container className="image-viewer-container fs-exclude">{this.displayImageView()}</Container>;
  }
}

export default ImageViewer;
