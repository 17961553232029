import {
  SET_EDIT_MODE_STAGE_1,
  SET_EDIT_NOTES_MODE,
  FETCH_STAGE_1_QUESTIONS_SUCCESS,
  SET_STAGE_1_QUESTIONS,
  OPEN_CHANGE_TREATMENT_STAGE_1_MODAL,
  CLOSE_CHANGE_TREATMENT_STAGE_1_MODAL,
  SET_STAGE_1_INCOMPLETE_ERROR,
  UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_ERROR,
  UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_PENDING,
  UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS,
  SET_REQUESTED_TREATMENT_STAGE_1,
  SET_TREATMENT_PLAN_REVISION,
  OPEN_STAGE_1_TEETH_SELECTOR_MODAL,
  CLOSE_STAGE_1_TEETH_SELECTOR_MODAL,
  OPEN_STAGE_1_TX_GUIDE_PDF_MODAL,
  CLOSE_STAGE_1_TX_GUIDE_PDF_MODAL,
  SET_STAGE_1_TX_GUIDE_FILE_PATH,
  SET_STAGE_1_QUESTIONS_OLD,
  SET_STAGE_1_SESSION_EXPIRED,
} from '../../../actions/bpp/production_tx_guide/stage_1';

const initialState = {
  case_id: '',
  stage_1_questions: {
    change_treatment_stage_1: false,
    change_treatment_stage_1_selections: {},
    stage_1_selected_teeth: {},
    stage_1_notes: '',
    wire_selections: [],
    upper_idb_tray: false,
    lower_idb_tray: false,
    bmf_rev: null,
  },
  last_informed_archs: {},
  is_edit_mode_stage_1: false,
  is_edit_notes_mode: false,
  change_treatment_modal: false,
  incomplete_error: {
    change_treatment: false,
    wire_selections: false,
    no_treatment: false,
  },
  teeth_selector_modals: {
    not_included: false,
    collisions: false,
    repositioned: false,
    new: false,
    arch: false,
  },
  requested_treatment: {},
  treatment_plan_revision: '',
  error: false,
  pending: false,
  wire_config: {
    upper: {
      14: ['Upper DE Smartwire 1 (.014 NiTi)'],
      16: ['Upper DE Smartwire 2 (.016 NiTi)'],
      both: ['Upper DE Smartwire 1 (.014 NiTi)', 'Upper DE Smartwire 2 (.016 NiTi)'],
      none: [],
    },

    lower: {
      14: ['Lower DE Smartwire 1 (.014 NiTi)'],
      16: ['Lower DE Smartwire 2 (.016 NiTi)'],
      both: ['Lower DE Smartwire 1 (.014 NiTi)', 'Lower DE Smartwire 2 (.016 NiTi)'],
      none: [],
    },
  },
  stage_1_tx_guide_pdf_modal: false,
  stage_1_tx_guide_file_path: '',
  stage_1_questions_old: {},
  session_expired: false,
};

/**
 * This reducer is used for the Stage I production tx guide form
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function stage1ProductionTxGuideReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_MODE_STAGE_1:
      return {
        ...state,
        is_edit_mode_stage_1: action.is_edit_mode_stage_1,
      };

    case SET_EDIT_NOTES_MODE:
      return {
        ...state,
        is_edit_notes_mode: action.is_edit_notes_mode,
      };

    case FETCH_STAGE_1_QUESTIONS_SUCCESS:
      return {
        ...state,
        stage_1_questions: action.stage_1_questions,
        case_id: action.case_id,
      };

    case SET_STAGE_1_QUESTIONS:
      return {
        ...state,
        stage_1_questions: action.stage_1_questions,
      };

    case OPEN_CHANGE_TREATMENT_STAGE_1_MODAL:
      return {
        ...state,
        change_treatment_modal: true,
      };

    case CLOSE_CHANGE_TREATMENT_STAGE_1_MODAL:
      return {
        ...state,
        change_treatment_modal: false,
      };

    case SET_STAGE_1_INCOMPLETE_ERROR:
      return {
        ...state,
        incomplete_error: { ...state.incomplete_error, [action.error_type]: action.is_error },
      };

    case UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_ERROR:
      return {
        ...state,
        error: true,
        pending: false,
      };

    case UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case UPDATE_STAGE_1_PRODUCTION_TX_GUIDE_SUCCESS:
      return {
        ...state,
        pending: false,
        last_informed_archs: action.last_informed_archs,
        stage_1_questions: action.stage_1_questions,
      };

    case SET_REQUESTED_TREATMENT_STAGE_1:
      return {
        ...state,
        requested_treatment: action.requested_treatment,
      };

    case SET_TREATMENT_PLAN_REVISION:
      return {
        ...state,
        treatment_plan_revision: action.treatment_plan_revision,
      };

    case OPEN_STAGE_1_TEETH_SELECTOR_MODAL:
      return {
        ...state,
        teeth_selector_modals: { ...state.teeth_selector_modals, [action.modal_type]: true },
      };

    case CLOSE_STAGE_1_TEETH_SELECTOR_MODAL:
      return {
        ...state,
        teeth_selector_modals: { ...state.teeth_selector_modals, [action.modal_type]: false },
      };

    case OPEN_STAGE_1_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        stage_1_tx_guide_pdf_modal: true,
        stage_1_tx_guide_file_path: action.file_path,
      };

    case CLOSE_STAGE_1_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        stage_1_tx_guide_pdf_modal: false,
      };

    case SET_STAGE_1_TX_GUIDE_FILE_PATH:
      return {
        ...state,
        stage_1_tx_guide_file_path: action.file_path,
      };

    case SET_STAGE_1_QUESTIONS_OLD:
      return {
        ...state,
        stage_1_questions_old: action.stage_1_questions_old,
      };

    case SET_STAGE_1_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: action.session_expired,
      };

    default:
      return state;
  }
}

export const getStage1Questions = (state) => state.stage1ProductionTxGuideReducer.stage_1_questions;
export const getIsEditModeStage1 = (state) => state.stage1ProductionTxGuideReducer.is_edit_mode_stage_1;
export const getIsEditNotesMode = (state) => state.stage1ProductionTxGuideReducer.is_edit_notes_mode;
export const getChangeTreatmentStage1Modal = (state) => state.stage1ProductionTxGuideReducer.change_treatment_modal;
export const getStage1IncompleteError = (state) => state.stage1ProductionTxGuideReducer.incomplete_error;
export const getTeethSelectorModals = (state) => state.stage1ProductionTxGuideReducer.teeth_selector_modals;
export const getWireConfig = (state) => state.stage1ProductionTxGuideReducer.wire_config;
export const getStage1TxGuidePdfModal = (state) => state.stage1ProductionTxGuideReducer.stage_1_tx_guide_pdf_modal;
export const getStage1TxGuideFilePath = (state) => state.stage1ProductionTxGuideReducer.stage_1_tx_guide_file_path;
export const getStage1QuestionsOld = (state) => state.stage1ProductionTxGuideReducer.stage_1_questions_old;
export const getStage1SessionExpired = (state) => state.stage1ProductionTxGuideReducer.session_expired;
