import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
export const BusinessTooltip = withStyles({
  arrow: {
    color: 'rgba(0,0,0,0.8)',
  },
  tooltip: {
    fontSize: '12px',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
})(Tooltip);

/**
 * Adds or removes tooltip depending on length/overflow of ref's text
 * @function
 * @param {object} ref - Ref object for text
 * @param {string} id - Id for tooltip element
 * @param {string} tooltip_text - Text to display on tooltip
 */
export const setOverflowTooltip = (ref, id, tooltip_text) => {
  if (ref) {
    const is_overflow = ref.offsetHeight < ref.scrollHeight;
    const element = document.getElementById(id);
    element.setAttribute('data-original-title', is_overflow ? tooltip_text : '');
  }
};
