import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSuperimpositionTooltip } from '../../../common/functions';

/**
 * Displays the zoom controls for superimposition
 * @component
 */
class ZoomControls extends Component {
  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentWillUnmount() {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  }

  /**
   * Returns the superimposition text color
   * @function
   * @return {String} Text color classname
   */
  getTooltipColor() {
    if (this.props.super_imposition_val < 50) return ' super-purple';
    if (this.props.super_imposition_val > 50) return ' super-blue';
    return '';
  }

  render() {
    const tooltipClassNames = `records-zoom-controller-value${this.getTooltipColor()}`;

    return (
      <div className="superimpose-window hide">
        <div className={`records-zoom-control-window${this.props.disabled ? ' disabled' : ''}`}>
          <i
            className="fa fa-minus records-controller-btn records-zoom-controller-left-btn"
            data-toggle="tooltip"
            data-placement="top"
            title="Decrease Opacity"
            aria-hidden="true"
            onMouseDown={this.props.onSuperImpositionMinusMouseDown}
            onMouseUp={this.props.onSuperImpositionMouseUp}
            onMouseOut={this.props.onSuperImpositionMouseUp}
          />
          <input
            className="records-zoom-controller fifty-percent-bar"
            type="range"
            min="0"
            max="100"
            value={this.props.super_imposition_val}
            onChange={this.props.onSuperImpositionChange}
          />
          <i
            className="fa fa-plus records-controller-btn records-zoom-controller-right-btn"
            data-toggle="tooltip"
            data-placement="top"
            title="Increase Opacity"
            aria-hidden="true"
            onMouseDown={this.props.onSuperImpositionPlusMouseDown}
            onMouseUp={this.props.onSuperImpositionMouseUp}
            onMouseOut={this.props.onSuperImpositionMouseUp}
          />
          <div className={tooltipClassNames} aria-hidden="true">
            {getSuperimpositionTooltip(this.props.super_imposition_val)}
          </div>
        </div>
      </div>
    );
  }
}

ZoomControls.propTypes = {
  onSuperImpositionChange: PropTypes.func.isRequired,
  onSuperImpositionMinusMouseDown: PropTypes.func.isRequired,
  onSuperImpositionMouseUp: PropTypes.func.isRequired,
  onSuperImpositionPlusMouseDown: PropTypes.func.isRequired,
  super_imposition_val: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default ZoomControls;
