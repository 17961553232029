// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal Libs
import Modal from '../../../components/modal/modal';
import { removeIreqIdInitialNumber } from '../../../common/functions';
import Convert from './awb/convert';
import ConvertManual from './awb/convertmanual';
import Fail from './awb/fail';
import Success from './awb/success';
import FailManual from './awb/failmanual';
import Final from './awb/final';
import FinalManual from './awb/finalmanual';
import InProcess from './awb/in_process';
import Override from './awb/override';
import SuceessManual from './awb/successmanual';
import InProcessManual from './awb/in_processmanual';
import './production_container.scss';

// Redux Actions
import {
  fetchAwb,
  postConvertAwb,
  onConfirmButtonClickMarkAsSuccess,
  onConfirmButtonClickMarkAsFailure,
  onConfirmButtonClickReject,
  onConfirmButtonClickTransfer,
  onConfirmButtonClickRetry,
  onButtonClickRework,
  onConfirmButtonClickOverride,
  onConfirmButtonClickManual,
  postManualConvertAwb,
  onConfirmButtonClickManualReject,
  onConfirmButtonClickManualTransfer,
  onConfirmButtonClickManualMarkAsSuccess,
  onConfirmButtonClickManualMarkAsFailure,
  onConfirmButtonClickManualRetry,
  onButtonClickManualRework,
  onConfirmButtonClickMissingTeethMeasurements,
  toggleModal,
  openAwbMissingTeethMeasurementsModal,
  onSelectionChangeRework,
  onConfirmButtonClickAutomatedProcess,
  setSelections,
} from '../../../redux/actions/awb';
// Redux Reducers
import {
  getAwb,
  getAwbPending,
  getAwbError,
  getAwbConvertModal,
  getAwbRecipeFileModal,
  getAwbMeasurementFileModal,
  getAwbMarkSuccessModal,
  getAwbMarkFailedModal,
  getAwbRejectModal,
  getAwbTransferModal,
  getAwbRetryModal,
  getAwbOverrideModal,
  getAwbManualModal,
  getAwbManualConvertModal,
  getAwbManualRejectModal,
  getAwbManualTransferModal,
  getAwbManualMarkSuccessModal,
  getAwbManualMarkFailedModal,
  getAwbManualRetryModal,
  getAwbMissingTeethMeasurementsModal,
  getAwbReworkModal,
  getAwbManualReworkModal,
  getAwbAutomatedProcessModal,
  getAwbMissingTeethMeasurementsModalSelectedTeeth,
  getAwbSelections,
} from '../../../redux/reducers/awb';
import { getPDFNameTemplate } from '../../../common/helpers';
/**
 * Used to show different automated wire bending process steps for item request
 * @component
 * @alias BusinessIrProductionContainer
 * @category BPP
 */
class ProductionContainer extends Component {
  ManualConvertModal = () => {
    const header_text = `Conversion Process - ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to proceed with conversion process?"
        confirm_btn_text="Convert"
        onConfirmButtonClick={() => this.props.postManualConvertAwb(this.props.ireq_id, this.props.awb)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_convert_modal', !this.props.manual_convert_modal)}
      />
    );
  };

  ConvertModal = () => {
    const header_text = `Conversion Process - ${removeIreqIdInitialNumber(this.props.ireq_id)}`;
    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to proceed with conversion process?"
        confirm_btn_text="Convert"
        onConfirmButtonClick={() => this.props.postConvertAwb(this.props.ireq_id, this.props.awb)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('convert_modal', !this.props.convert_modal)}
      />
    );
  };

  generateMarkAsSuccessModal = () => {
    const header_text = `Mark as Success -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the conversion process to succeed?"
        confirm_btn_text="Mark as Success"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickMarkAsSuccess(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('mark_success_modal', !this.props.mark_success_modal)}
      />
    );
  };

  generateMarkAsFailureModal = () => {
    const header_text = `Mark as Failed -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the conversion process to fail?"
        confirm_btn_text="Mark as Failed"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickMarkAsFailure(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('mark_failed_modal', !this.props.mark_failed_modal)}
      />
    );
  };

  generateRejectModal = () => {
    const header_text = `Reject Files -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to reject these files?"
        confirm_btn_text="Reject"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickReject(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('reject_modal', !this.props.reject_modal)}
      />
    );
  };

  generateAutomatedProcessModal = () => {
    const header_text = `Automated Process -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to go back to Automated Process?"
        confirm_btn_text="Automated Process"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickAutomatedProcess(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('automated_process_modal', !this.props.automated_process_modal)}
      />
    );
  };

  generateTransferModal = () => {
    const header_text = `Transfer Files -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to transfer these files to the wire bending machine?"
        confirm_btn_text="Transfer"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickTransfer(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('transfer_modal', !this.props.transfer_modal)}
        in_progress={this.props.pending}
        in_progress_text={'Sending files to wire bending machine...'}
      />
    );
  };

  generateRetryModal = () => {
    const header_text = `Retry Conversion -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to retry the conversion process?"
        confirm_btn_text="Retry"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickRetry(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('retry_modal', !this.props.retry_modal)}
      />
    );
  };

  generateOverrideModal = () => {
    const header_text = `Override Process -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to override this process?"
        confirm_btn_text="Override"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickOverride(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('override_modal', !this.props.override_modal)}
      />
    );
  };

  generateManualModal = () => {
    const header_text = `Manual Process -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to complete this process manually?"
        confirm_btn_text="Manual Process"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManual(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_modal', !this.props.manual_modal)}
      />
    );
  };

  generateManualRejectModal = () => {
    const header_text = `Reject Files -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to reject these files?"
        confirm_btn_text="Reject"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManualReject(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_reject_modal', !this.props.manual_reject_modal)}
      />
    );
  };

  generateManualTransferModal = () => {
    const header_text = `Transfer Files -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to transfer these files to the wire bending machine?"
        confirm_btn_text="Transfer"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManualTransfer(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_transfer_modal', !this.props.manual_transfer_modal)}
        in_progress={this.props.pending}
        in_progress_text={'Sending files to wire bending machine...'}
      />
    );
  };

  generateManualMarkAsSuccessModal = () => {
    const header_text = `Mark as Success -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the conversion process to succeed?"
        confirm_btn_text="Mark as Success"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManualMarkAsSuccess(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_mark_success_modal', !this.props.manual_mark_success_modal)}
      />
    );
  };

  generateManualMarkAsFailureModal = () => {
    const header_text = `Mark as Fail -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to force the conversion process to fail?"
        confirm_btn_text="Mark as Fail"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManualMarkAsFailure(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_mark_failed_modal', !this.props.manual_mark_failed_modal)}
      />
    );
  };

  generateManualRetryModal = () => {
    const header_text = `Retry Conversion -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to retry the conversion process?"
        confirm_btn_text="Retry"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickManualRetry(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_retry_modal', !this.props.manual_retry_modal)}
      />
    );
  };

  generateMissingTeethMeasurementsModal = () => {
    const header_text = `Specify Teeth and IP Loop Measurements -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;
    return (
      <Modal
        theme="bpp"
        preset="awb-missing-teeth"
        modal_size_class="modal-lg modal-lg-pdf awb"
        header_text={header_text}
        confirm_btn_text="Apply"
        onConfirmButtonClick={() => this.props.onConfirmButtonClickMissingTeethMeasurements(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('missing_teeth_measurements_modal', !this.props.missing_teeth_measurements_modal)}
      />
    );
  };

  setSelectionChangeRework = (id) => {
    this.props.onSelectionChangeRework(id);
  };

  generateManualReworkModal = () => {
    const header_text = `Manual Rework -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;
    return (
      <Modal
        theme="bpp"
        preset="decision-rework"
        header_text={header_text}
        message_text="Review the selected wires, then click transfer to send them to wire bending machine."
        confirm_btn_text="Manual Transfer"
        selections={this.props.selections}
        onConfirmButtonClick={() => this.props.onButtonClickManualRework(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('manual_rework_modal', !this.props.manual_rework_modal)}
        in_progress={this.props.pending}
        in_progress_text={'Sending files to wire bending machine...'}
        onSelectionChangeRework={this.setSelectionChangeRework}
        setSelections={this.props.setSelections}
      />
    );
  };

  generateReworkModal = () => {
    const header_text = `Rework -  ${removeIreqIdInitialNumber(this.props.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision-rework"
        header_text={header_text}
        message_text="Review the selected wires, then click transfer to send them to wire bending machine."
        confirm_btn_text="Transfer"
        selections={this.props.selections}
        disbale_button={
          this.props.selections.filter(function (w) {
            return w.state === true;
          }).length === 0
        }
        onConfirmButtonClick={() => this.props.onButtonClickRework(this.props.ireq_id)}
        close_btn_text="Cancel"
        onCloseButtonClick={() => this.props.toggleModal('rework_modal', !this.props.rework_modal)}
        in_progress={this.props.pending}
        in_progress_text={'Sending files to wire bending machine...'}
        onSelectionChangeRework={this.setSelectionChangeRework}
        setSelections={this.props.setSelections}
      />
    );
  };

  generateMeasurementFileModal = () => {
    const header_text = `IP Loop Measurements - ${removeIreqIdInitialNumber(this.props.ireq_id)}`;
    let ip_measurements_file = {};
    if (this.props.awb && this.props.awb.files) {
      ip_measurements_file = this.props.awb.files.filter((file) => {
        return file.file_type === 'ip_measurements';
      })[0];
    }
    return (
      <Modal
        preset="pdf_viewer"
        header_text={header_text}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_url={ip_measurements_file?.upload_data}
        pdf_date={ip_measurements_file?.id}
        onCloseButtonClick={() => this.props.toggleModal('measurement_file_modal', !this.props.measurement_file_modal)}
        theme="bpp"
        original_filename={getPDFNameTemplate(this.props.ireq_id, this.props.patient_info, '3450-01_IP_Loop_Measurements.pdf')}
      />
    );
  };

  renderAwbSteps = () => {
    let selections = this.props.awb.ireq_parts;
    let latest_status = {};
    if (this.props.awb && this.props.awb.ireq_automation_status) {
      latest_status = this.props.awb.ireq_automation_status.filter((status) => {
        return status.current_ind === true;
      })[0];
    }
    let recipe_file = {};
    if (this.props.awb && this.props.awb.files) {
      recipe_file = this.props.awb.files.filter((file) => {
        return file.file_type === 'ip_recipe';
      })[0];
    }
    let ip_measurements_file = {};
    if (this.props.awb && this.props.awb.files) {
      ip_measurements_file = this.props.awb.files.filter((file) => {
        return file.file_type === 'ip_measurements';
      })[0];
    }
    let ireq_parts = this.props.item_parts_quantity.filter((ip) => {
      return ip.description.toLowerCase().includes('smartwire');
    });
    const status =
      latest_status && latest_status.status && this.props.ireq_status !== 'Item Request Shipped'
        ? latest_status.status
        : !latest_status && this.props.ireq_status === 'Item Request Shipped'
        ? 'IREQ AWB Override Process'
        : latest_status && latest_status.status && latest_status.status === 'IREQ AWB Manual Process' && this.props.ireq_status === 'Item Request Shipped'
        ? 'IREQ AWB Override Process'
        : latest_status && latest_status.status && latest_status.status !== 'IREQ AWB Manual Process' && this.props.ireq_status === 'Item Request Shipped'
        ? latest_status.status
        : '';

    if (ireq_parts.length > 0) {
      switch (status) {
        case '':
        case 'IREQ AWB Conversion Reprocess':
        case 'IREQ AWB Automated Conversion Process':
          return (
            <Convert
              item_request={this.props.item_request}
              ireq_id={this.props.ireq_id}
              selections={selections}
              ref_no={this.props.ref_no}
              openManualModal={() => this.props.toggleModal('manual_modal', !this.props.manual_modal)}
              openAwbConvertModal={() => this.props.toggleModal('convert_modal', !this.props.convert_modal)}
              openOverrideModal={() => this.props.toggleModal('override_modal', !this.props.override_modal)}
            />
          );
        case 'IREQ AWB Manual Process':
        case 'IREQ AWB Manual Reprocess':
          return (
            <ConvertManual
              ireq_id={this.props.ireq_id}
              selections={selections}
              ref_no={this.props.ref_no}
              openAwbManualConvertModal={() => this.props.toggleModal('manual_convert_modal', !this.props.manual_convert_modal)}
              openOverrideModal={() => this.props.toggleModal('override_modal', !this.props.override_modal)}
              openAwbAutomatedConvertModal={() => this.props.toggleModal('automated_process_modal', !this.props.automated_process_modal)}
            />
          );
        case 'IREQ AWB Conversion Process':
          return (
            <InProcess
              ref_no={this.props.ref_no}
              openMarkAsSuccessModal={() => this.props.toggleModal('mark_success_modal', !this.props.mark_success_modal)}
              openMarkAsFailureModal={() => this.props.toggleModal('mark_failed_modal', !this.props.mark_failed_modal)}
            />
          );
        case 'IREQ AWB Manual Process Conversion':
          return (
            <InProcessManual
              ref_no={this.props.ref_no}
              openManualMarkAsSuccessModal={() => this.props.toggleModal('manual_mark_success_modal', !this.props.manual_mark_success_modal)}
              openManualMarkAsFailureModal={() => this.props.toggleModal('manual_mark_failed_modal', !this.props.manual_mark_failed_modal)}
            />
          );
        case 'IREQ AWB Conversion Failed':
          return (
            <Fail
              latest_status={latest_status}
              ref_no={this.props.ref_no}
              openRetryModal={() => this.props.toggleModal('retry_modal', !this.props.retry_modal)}
            />
          );
        case 'IREQ AWB Manual Process Failed':
          return (
            <FailManual
              latest_status={latest_status}
              ref_no={this.props.ref_no}
              openManualRetryModal={() => this.props.toggleModal('manual_retry_modal', !this.props.manual_retry_modal)}
            />
          );
        case 'IREQ AWB Override Process':
          return <Override ref_no={this.props.ref_no} />;
        case 'IREQ AWB Outputs Approved':
        case 'IREQ AWB Rework':
          return (
            <Final
              ref_no={this.props.ref_no}
              recipe_file={recipe_file}
              ip_measurements_file={ip_measurements_file}
              ireq_status={this.props.ireq_status}
              onButtonClickRework={() => this.props.toggleModal('rework_modal', !this.props.rework_modal)}
              openRecipeFileModal={() => this.props.toggleModal('recipe_file_modal', !this.props.recipe_file_modal)}
              openMeasurementFileModal={() => this.props.toggleModal('measurement_file_modal', !this.props.measurement_file_modal)}
              shipping_approved_ind={this.props.item_request.shipping_approved_ind}
            />
          );
        case 'IREQ AWB Manual Transfer':
        case 'IREQ AWB Manual Rework':
          return (
            <FinalManual
              ref_no={this.props.ref_no}
              recipe_file={recipe_file}
              ip_measurements_file={ip_measurements_file}
              ireq_status={this.props.ireq_status}
              onButtonClickRework={() => this.props.toggleModal('manual_rework_modal', !this.props.manual_rework_modal)}
              openRecipeFileModal={() => this.props.toggleModal('recipe_file_modal', !this.props.recipe_file_modal)}
              openMeasurementFileModal={() => this.props.toggleModal('measurement_file_modal', !this.props.measurement_file_modal)}
              shipping_approved_ind={this.props.item_request.shipping_approved_ind}
            />
          );
        case 'IREQ AWB Conversion Success':
          return (
            <Success
              ref_no={this.props.ref_no}
              recipe_file={recipe_file}
              ip_measurements_file={ip_measurements_file}
              openTransferModal={() => this.props.toggleModal('transfer_modal', !this.props.transfer_modal)}
              openRejectModal={() => this.props.toggleModal('reject_modal', !this.props.reject_modal)}
              openRecipeFileModal={() => this.props.toggleModal('recipe_file_modal', !this.props.recipe_file_modal)}
              openMeasurementFileModal={() => this.props.toggleModal('measurement_file_modal', !this.props.measurement_file_modal)}
            />
          );
        case 'IREQ AWB Manual Process Success':
          return (
            <SuceessManual
              ref_no={this.props.ref_no}
              recipe_file={recipe_file}
              ip_measurements_file={ip_measurements_file}
              openRecipeFileModal={() => this.props.toggleModal('recipe_file_modal', !this.props.recipe_file_modal)}
              openMeasurementFileModal={() => this.props.toggleModal('measurement_file_modal', !this.props.measurement_file_modal)}
              openManualTransferModal={() => this.props.toggleModal('manual_transfer_modal', !this.props.manual_transfer_modal)}
              openManualRejectModal={() => this.props.toggleModal('manual_reject_modal', !this.props.manual_reject_modal)}
            />
          );
        default:
      }
    }
  };

  render() {
    return (
      <div className={'cad-process'}>
        <React.Fragment>
          {this.renderAwbSteps()}
          {this.props.convert_modal ? this.ConvertModal() : null}
          {this.props.measurement_file_modal ? this.generateMeasurementFileModal() : null}
          {this.props.mark_as_success_modal ? this.generateMarkAsSuccessModal() : null}
          {this.props.mark_as_failure_modal ? this.generateMarkAsFailureModal() : null}
          {this.props.reject_modal ? this.generateRejectModal() : null}
          {this.props.transfer_modal ? this.generateTransferModal() : null}
          {this.props.retry_modal ? this.generateRetryModal() : null}
          {this.props.override_modal ? this.generateOverrideModal() : null}
          {this.props.manual_modal ? this.generateManualModal() : null}
          {this.props.manual_convert_modal ? this.ManualConvertModal() : null}
          {this.props.manual_reject_modal ? this.generateManualRejectModal() : null}
          {this.props.manual_transfer_modal ? this.generateManualTransferModal() : null}
          {this.props.manual_mark_as_success_modal ? this.generateManualMarkAsSuccessModal() : null}
          {this.props.manual_mark_as_failure_modal ? this.generateManualMarkAsFailureModal() : null}
          {this.props.manual_retry_modal ? this.generateManualRetryModal() : null}
          {this.props.rework_modal ? this.generateReworkModal() : null}
          {this.props.manual_rework_modal ? this.generateManualReworkModal() : null}
          {this.props.missing_teeth_measurements_modal ? this.generateMissingTeethMeasurementsModal() : null}
          {this.props.automated_process_modal ? this.generateAutomatedProcessModal() : null}
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    awb: getAwb(state),
    convert_modal: getAwbConvertModal(state),
    recipe_file_modal: getAwbRecipeFileModal(state),
    measurement_file_modal: getAwbMeasurementFileModal(state),
    mark_as_success_modal: getAwbMarkSuccessModal(state),
    mark_as_failure_modal: getAwbMarkFailedModal(state),
    reject_modal: getAwbRejectModal(state),
    transfer_modal: getAwbTransferModal(state),
    retry_modal: getAwbRetryModal(state),
    override_modal: getAwbOverrideModal(state),
    manual_modal: getAwbManualModal(state),
    manual_convert_modal: getAwbManualConvertModal(state),
    manual_reject_modal: getAwbManualRejectModal(state),
    manual_transfer_modal: getAwbManualTransferModal(state),
    manual_mark_as_success_modal: getAwbManualMarkSuccessModal(state),
    manual_mark_as_failure_modal: getAwbManualMarkFailedModal(state),
    manual_retry_modal: getAwbManualRetryModal(state),
    missing_teeth_measurements_modal: getAwbMissingTeethMeasurementsModal(state),
    rework_modal: getAwbReworkModal(state),
    manual_rework_modal: getAwbManualReworkModal(state),
    automated_process_modal: getAwbAutomatedProcessModal(state),
    pending: getAwbPending(state),
    error: getAwbError(state),
    missing_teeth_measurements_modal_selected_teeth: getAwbMissingTeethMeasurementsModalSelectedTeeth(state),
    selections: getAwbSelections(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAwb: fetchAwb,
      postConvertAwb: postConvertAwb,
      onConfirmButtonClickMarkAsSuccess: onConfirmButtonClickMarkAsSuccess,
      onConfirmButtonClickMarkAsFailure: onConfirmButtonClickMarkAsFailure,
      onConfirmButtonClickReject: onConfirmButtonClickReject,
      onConfirmButtonClickTransfer: onConfirmButtonClickTransfer,
      onConfirmButtonClickRetry: onConfirmButtonClickRetry,
      onButtonClickRework: onButtonClickRework,
      onConfirmButtonClickOverride: onConfirmButtonClickOverride,
      onConfirmButtonClickManual: onConfirmButtonClickManual,
      postManualConvertAwb: postManualConvertAwb,
      onConfirmButtonClickManualReject: onConfirmButtonClickManualReject,
      onConfirmButtonClickManualTransfer: onConfirmButtonClickManualTransfer,
      onConfirmButtonClickManualMarkAsSuccess: onConfirmButtonClickManualMarkAsSuccess,
      onConfirmButtonClickManualMarkAsFailure: onConfirmButtonClickManualMarkAsFailure,
      onConfirmButtonClickManualRetry: onConfirmButtonClickManualRetry,
      onButtonClickManualRework: onButtonClickManualRework,
      onConfirmButtonClickMissingTeethMeasurements: onConfirmButtonClickMissingTeethMeasurements,
      toggleModal: toggleModal,
      openAwbMissingTeethMeasurementsModal: openAwbMissingTeethMeasurementsModal,
      onSelectionChangeRework: onSelectionChangeRework,
      onConfirmButtonClickAutomatedProcess: onConfirmButtonClickAutomatedProcess,
      setSelections: setSelections,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductionContainer);
