import '../styles/styles.scss';

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { isKnowledgeBaseRoute } from '../../common/helpers';
import { userHasPermission } from '../../common/permission';
import { UserPermission, UserPermissionsContext } from '../../context/user_permission';

import NavBar from '../components/nav_bar';
import HelpSidebar from '../components/help_sidebar';
import FeedbackForm from '../components/feedback_form';
import FeatureAnnouncement from '../components/modals/feature_announcement';
import PrivacyInternal from '../../components/privacy/internal_privacy';
import PrivateRoute from '../../common/private_route';
import CustomerAgreement from '../customer_agreement/customer_agreement';
import NotFound from '../404/not_found';
import { calculateHolidayRange, getUpcomingHolidays } from '../../common/date';

import { setTokenHeader } from '../../common/functions';
import BannerCarousel from '../banner/banner_carousel';
import CreditHoldWarning from '../banner/credit_hold_warning';
import HolidayMessage from '../banner/holiday_message';
import { isChrome, isEdge, isModernSafari } from '../../common/browser';
import BrowserWarning from '../components/browser_warning/browser_warning';
import FeatureAnnouncementBanner from '../banner/feature_announcement';

const SHOULD_SHOW_FEATURE_ANNOUNCEMENT = false;

export default function Layout({ doctor_id, onCreditHoldChange, ...rest }) {
  const [holidays, setHolidays] = useState(false);
  const [creditHold, setCreditHold] = useState(false);
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);
  /**
   * Fetches the upcoming holidays and credit hold information.
   */
  useEffect(() => {
    async function fetchData() {
      try {
        setTokenHeader();
        // Fetch the upcoming holidays.
        const { data: holidaysData } = await axios.get('/apiV2/holidays');
        const holidayFound = getUpcomingHolidays(holidaysData.holidays);

        // Fetch the credit hold information.
        const { data: doctorData } = await axios.get(`/apiV2/account/${doctor_id}`);

        // Update the state if the fetched data is different from the current state.
        if (holidayFound && holidayFound.length > 0) {
          const holidayRange = calculateHolidayRange(holidayFound);
          setHolidays(holidayRange);
        }
        if (doctorData.credit_hold !== creditHold) {
          setCreditHold(doctorData.credit_hold);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsBannerLoaded(true);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="ipp-light">
      <UserPermission>
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const canViewDoctor = userHasPermission('IPP_VIEW', user_roles_and_permissions.permissions);
            const knowledgebase_ref = isKnowledgeBaseRoute();
            const canViewKnowledgeBase = userHasPermission('KNOWLEDGEBASE_VIEW', user_roles_and_permissions.permissions) && knowledgebase_ref;
            const terms = user_roles_and_permissions.terms;

            if (terms) return <CustomerAgreement />;

            return canViewDoctor || canViewKnowledgeBase ? (
              <>
                <BannerCarousel isBannerLoaded={isBannerLoaded}>
                  {creditHold && <CreditHoldWarning doctor_id={doctor_id} onCreditHoldChange={setCreditHold} />}
                  {holidays &&
                    holidays.map((holiday) => (
                      <HolidayMessage key={holiday.holiday} holidayName={holiday.holiday} from={holiday.from} to={holiday.to} reopen={holiday.reopen} />
                    ))}
                  {SHOULD_SHOW_FEATURE_ANNOUNCEMENT && <FeatureAnnouncementBanner />}
                  {!isChrome && !isModernSafari && !isEdge && <BrowserWarning />}
                </BannerCarousel>
                <NavBar appHasMessageInfo={Boolean(holidays)} creditHold={creditHold} sticky />
                <HelpSidebar />
                <FeedbackForm />
                <FeatureAnnouncement feature="smile_design_preferences" />
                <main>
                  <div className="content no-margin-top">
                    <PrivateRoute {...rest} />
                  </div>
                  <PrivacyInternal className="privacy__footer privacy__footer--gray" />
                </main>{' '}
              </>
            ) : (
              <NotFound />
            );
          }}
        </UserPermissionsContext.Consumer>
      </UserPermission>
    </div>
  );
}
