import React from 'react';
import SelectionBarTopSmall from './selection_bar/selection_bar_top_small';
import PaneViewer from './pane_viewer';

/**
 * Displays a single pane viewer without selection bar on top
 * @component
 */
export default function PaneSection(props) {
  return (
    <div className={props.split ? 'pane-section-2' : 'pane-section'}>
      <SelectionBarTopSmall
        split={props.split}
        collapse={props.collapse}
        scroll_options={props.scroll_options}
        pane={props.pane}
        onClick={props.onClick}
        onScrollOptionClick={props.onScrollOptionClick}
        isSmileSimulationSetup={props.isSmileSimulationSetup}
      />
      <PaneViewer {...props} id={`pane-viewer-${props.pane}`} />
    </div>
  );
}
