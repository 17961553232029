// External
import React, { useState } from 'react';
import * as RS from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal
import CreatePatientNotes from '../patient_notes/create_patient_notes';
import Modal from '../../components/modal/modal';
import LockedIcon from '../../components/custom_lightbox/components/LockedIcon';
import DisplayPatientNotes from '../patient_notes/display_patient_notes';
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../common/functions';
import { userHasPermission } from '../../common/permission';

// Redux
import {
  setAdditionalActionsModal,
  setNoteModal,
  setSelectedListItemId,
  setViewPlanModal,
  setViewIfuModal,
  setDeleteNoteModal,
  deleteNote,
} from '../../redux/actions/ipp/case_list';
import {
  getAdditionalActionsModal,
  getNoteModal,
  getSelectedListItemId,
  getViewPlanModal,
  getViewIfuModal,
  getDeleteNoteModal,
} from '../../redux/reducers/ipp/case_list';

/**
 * Displays options in the Additional Actions dropdown menu
 * @component
 * @category IPP
 */
function AdditionalActionsDropdown(props) {
  const { id, note } = props;
  const is_draft = props.case_status ? props.case_status.includes('Draft') : false;
  const is_ireq = id.includes('-IR');
  const has_ipp_permission = userHasPermission('IPP_EDIT', props.user_roles_and_permissions.permissions);
  const [save_disabled, setSaveDisabled] = useState(false);
  const [is_editing, setIsEditing] = useState(false);

  const getNoteOptions = () => {
    let options = [
      {
        label: 'Create note',
        disabled: !has_ipp_permission,
        option_function: () => {
          props.setNoteModal(true);
          props.setAdditionalActionsModal(false);
          setIsEditing(false);
          setSaveDisabled(true);
        },
      },
    ];

    if (id.includes('-DE') || !is_draft) {
      options.push({
        label: 'View all notes',
        disabled: false,
        option_function: () => {
          props.setAdditionalActionsModal(false);
          props.setSelectedListItemId('');
          props.viewAllNotesRedirect(is_ireq ? props.case_id : id);
        },
      });
    }

    if (note?.id) {
      options.unshift({
        label: 'Edit note',
        disabled: !canUpdateNote(),
        option_function: () => {
          props.setNoteModal(true);
          props.setAdditionalActionsModal(false);
          setIsEditing(true);
          setSaveDisabled(false);
        },
      });
      if (is_draft && !id.includes('-DE')) {
        options = options.filter((option) => option.label !== 'Create note');
        options.push({
          label: 'Delete note',
          disabled: !canUpdateNote(),
          option_function: () => {
            props.setDeleteNoteModal(!props.delete_note_modal);
            props.setAdditionalActionsModal(false);
          },
        });
      }
    }

    return options;
  };

  const getDocumentOptions = () => {
    let options = [];
    if (!is_draft) {
      if (props.can_view_plan) {
        options.push({
          label: `View ${is_ireq ? 'IR Form' : id.includes('-DE') ? 'Digital Enhancement Form' : 'Treatment Plan'}`,
          disabled: false,
          option_function: () => props.setViewPlanModal(true),
        });
      }

      const ifu_files = {
        instructions: { file: props.instructions_file, label: `View ${id.includes('-DE') ? 'DE' : 'Tx'} Instructions` },
        bpg: { file: props.bpg_file, label: 'View Bracket Positioning Guide' },
        afg: {
          file: props.afg_file,
          label: `View ${props.afg_file?.file_type === 'bpg_manual' && !props.gen_2 ? 'Bracket Positioning Guide and ' : ''}Adhesive Fill Guide`,
        },
      };

      for (const file_type of Object.keys(ifu_files)) {
        if (ifu_files[file_type].file) {
          options.push({
            label: ifu_files[file_type].label,
            disabled: false,
            option_function: () => props.setViewIfuModal(true, file_type),
          });
        }
      }
    }
    return options;
  };

  const getSubmissionOptions = () => {
    let options = [];
    if (props.can_delete_draft) {
      options.push({
        label: 'Delete Draft',
        disabled: false,
        option_function: () => {
          props.setSelectedListItemId('');
          props.setAdditionalActionsModal(false);
          props.openDeleteCaseModal(id);
        },
      });
    }
    return options;
  };

  const getIreqOptions = () => {
    const can_submit_ireq = is_ireq || props.case_status === 'Shipped' || (id.includes('-DE') && !is_draft);
    return can_submit_ireq
      ? [
          {
            label: 'Submit Item Request',
            disabled: false,
            option_function: () => props.submitIreqRedirect(id),
          },
        ]
      : [];
  };

  const canUpdateNote = () => {
    const user_role = props.user_roles_and_permissions.doctor_role;
    return (
      has_ipp_permission &&
      ((user_role === 'DSO_Doctor' || user_role === 'Doctor' ? props.case_doctor_id === props.user_roles_and_permissions.doctor_id.toString() : true) ||
        note.created_by_id.toString() === props.user_roles_and_permissions.user_id.toString())
    );
  };

  const onNoteTextChange = (note_text, char_count, current_subject) => {
    note.text = note_text;
    note.subject = current_subject;
    setSaveDisabled(!char_count);
  };

  const onNoteSubjectChange = (subject) => {
    note.subject = subject;
  };

  const displayNoteModal = () => {
    const id_string = ` (${removeCaseIdInitialNumber(removeIreqIdInitialNumber(id))})`;
    return (
      <Modal
        preset="decision"
        header_text={`${is_editing ? 'Edit' : 'Create'} Note${is_draft ? '' : `- ${props.patient_name}${!props.is_prospect ? id_string : ''}`}`}
        confirm_btn_text={is_editing ? 'Save' : 'Create'}
        close_btn_text="Cancel"
        onCloseButtonClick={() => {
          props.setNoteModal(false);
          props.setSelectedListItemId('');
        }}
        onConfirmButtonClick={() => {
          if (!is_editing) note.id = '';
          props.updateNote(is_ireq ? props.case_id : id, note, props.refreshList, is_draft);
          props.setSelectedListItemId('');
        }}
        theme="ipp"
        modal_class="patient-notes-modal"
        modal_body={
          <CreatePatientNotes
            id={is_ireq ? props.case_id : id}
            patient_notes_id={is_editing ? note.id : null}
            subject={is_editing ? note.subject : null}
            note_text={is_editing ? note.text : null}
            is_modal={true}
            onNoteTextChange={onNoteTextChange}
            onNoteSubjectChange={onNoteSubjectChange}
            is_prospect={props.is_prospect}
            is_editing={is_editing}
          />
        }
        confirm_btn_disabled={save_disabled}
      />
    );
  };

  const displayDeleteNoteModal = () => {
    return (
      <Modal
        preset="decision"
        header_text="Delete Note"
        modal_class="modal-content-warning"
        message_text={
          <div className="case-view-note-modal">
            <span>Are you sure you want to delete this note?</span>
            <DisplayPatientNotes
              note={{
                note_text: note.text,
                subject: note.subject,
              }}
            />
          </div>
        }
        confirm_btn_text="Delete"
        close_btn_text="Cancel"
        onConfirmButtonClick={() => props.deleteNote(id, note.id)}
        onCloseButtonClick={() => props.setDeleteNoteModal(false)}
      />
    );
  };

  const getCategories = () => {
    let categories = {
      notes: getNoteOptions(),
      submission: getSubmissionOptions(),
      documents: getDocumentOptions(),
      'item request': getIreqOptions(),
    };

    let categories_list = Object.entries(categories);
    categories_list = categories_list.filter(([key, value]) => value.length > 0);
    return Object.fromEntries(categories_list);
  };

  const categories = getCategories();
  const category_keys = Object.keys(categories);

  return (
    <div className="additional-actions">
      <i
        className="fa fa-ellipsis-v navigator-right-icon"
        aria-hidden="true"
        data-additionalactions={true}
        onClick={() => {
          props.setSelectedListItemId(props.additional_actions_modal ? '' : id);
          props.setAdditionalActionsModal(!props.additional_actions_modal);
        }}
        id={`aa-${id}`}
      />
      {props.selected_list_item_id === id && (
        <>
          <RS.Popover
            name="popover"
            placement="bottom"
            className="aa-popover tend-doctor-popover"
            isOpen={props.additional_actions_modal}
            target={`aa-${id}`}
            toggle={() => {
              props.setSelectedListItemId(props.additional_actions_modal ? '' : id);
              props.setAdditionalActionsModal(!props.additional_actions_modal);
            }}
          >
            <RS.PopoverBody>
              <ul className="tend-submenu aa-submenu">
                {category_keys.map((category, idx) => {
                  return categories[category].length > 0 ? (
                    <React.Fragment key={idx}>
                      <div className="aa-popover__label">{category.toUpperCase()}</div>
                      {categories[category].map((option, option_idx) => (
                        <li
                          key={option_idx}
                          onClick={!option.disabled ? option.option_function : null}
                          className={option.disabled ? 'aa-popover__label__disabled' : ''}
                        >
                          {option.disabled && <LockedIcon />}
                          <span>{option.label}</span>
                        </li>
                      ))}
                      {idx !== category_keys.length - 1 && <div className="aa-popover__border" />}
                    </React.Fragment>
                  ) : null;
                })}
              </ul>
            </RS.PopoverBody>
          </RS.Popover>
          {props.note_modal && displayNoteModal()}
          {props.delete_note_modal && displayDeleteNoteModal()}
          {props.view_plan_modal && props.displayForm(id)}
          {props.view_ifu_modal && props.displayIfuForm(id)}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    additional_actions_modal: getAdditionalActionsModal(state),
    note_modal: getNoteModal(state),
    delete_note_modal: getDeleteNoteModal(state),
    view_plan_modal: getViewPlanModal(state),
    view_ifu_modal: getViewIfuModal(state),
    selected_list_item_id: getSelectedListItemId(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAdditionalActionsModal: setAdditionalActionsModal,
      setNoteModal: setNoteModal,
      setDeleteNoteModal: setDeleteNoteModal,
      setViewPlanModal: setViewPlanModal,
      setViewIfuModal: setViewIfuModal,
      setSelectedListItemId: setSelectedListItemId,
      deleteNote: deleteNote,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalActionsDropdown);
