import { TransformationPipe } from '../../../../common/validation/validation-utils';
import { isValueNegative } from './validator.pipe';

/**
 * Replaces comma with dot
 * @param value {string}
 * @returns {string}
 */
export function replaceCommaWithDot(value) {
  return value.replace(',', '.');
}

/**
 * Prepends a value with 0 if starts with , or .
 * @param value {string}
 * @returns {string}
 */
function prependWithZeroIfNone(value) {
  return value.startsWith('.') || value.startsWith(',') ? `0${value}` : value;
}

/**
 * Removes .0 on the end of the string
 * @param value {string}
 * @returns {string}
 */
function removeZeroEnding(value) {
  if (value.endsWith('.0')) {
    return value.slice(0, -2);
  }
  return value;
}

/**
 * Removes dot on the end of the string
 * @param value {string}
 * @returns {string}
 */
function removeDotEnding(value) {
  if (value.endsWith('.')) {
    return value.slice(0, -1);
  }
  return value;
}

/**
 * Sets the sign of a value based on the options provided.
 * If the options contain a 'currentValue' property that is a number and is less than 0, the sign of the value will be negated.
 * @param {any} value - The value to set the sign for.
 * @param {object} options - The options object.
 * @returns {any} - The value with the sign set according to the options.
 */
export function setSign(value, options = {}) {
  if (options.hasOwnProperty('currentValue') && !Number.isNaN(options.currentValue) && options.currentValue < 0 && !isValueNegative(value)) {
    return `-${value}`;
  }
  return value;
}

/**
 * Takes abs value from input
 * @param {number | string} value
 * @returns {string}
 */
export function takeAbsValue(value) {
  const isNegative = `${value}`.startsWith('-');
  return isNegative ? `${value}`.slice(1) : `${value}`;
}

/**
 * Transforms TMT input value in onBlur event
 * @type {TransformationPipe}
 */
export const tmtInputOnBlurTransformer = new TransformationPipe(
  replaceCommaWithDot,
  prependWithZeroIfNone,
  removeZeroEnding,
  removeDotEnding,
  parseFloat,
);
export const incMovementInputOnBlurTransformer = new TransformationPipe(replaceCommaWithDot, removeZeroEnding, removeDotEnding, setSign, parseFloat);
