import './message_banner.scss';

import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const MAIN_CLASS = 'banner-message-info';

/**
 * Gets the height of the main holiday message element.
 *
 * @returns {Number} The height of the child paragraph element within the main element.
 */
export const getHolidayMessageElementHeight = () => {
  let result = 0;
  const messageInfoElement = document.querySelector(`.${MAIN_CLASS}`);
  const messageInfoChildElement = messageInfoElement?.querySelector('p');

  // Get the height of the child element if it exists
  result = messageInfoChildElement?.clientHeight || 0;

  return result;
};

/**
 * Renders a holiday message with the given information.
 * @param {Object} props - The component props.
 * @param {string} props.holidayName - The name of the holiday being observed.
 * @param {string} props.from - The date when the break starts.
 * @param {string} props.to - The date when the break ends.
 * @param {string} props.reopen - The date when the office will reopen.
 * @returns {JSX.Element|null} The holiday message component.
 */
const HolidayMessage = ({ holidayName, from, to, reopen }) => {
  if (!holidayName || !from || !reopen) {
    return null;
  }
  return (
    <div className={MAIN_CLASS}>
      <p className={`${MAIN_CLASS}__text`}>
        In observance of {holidayName?.trim()}, InBrace will be out of office on {from}
        {from !== to && ` to ${to}`}. We will be returning on {reopen}.
        <br />
        Thank you for being a valued customer of InBrace!
      </p>
    </div>
  );
};

HolidayMessage.propTypes = {
  holidayName: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default withRouter(HolidayMessage);
