import { Helmet } from 'react-helmet';
import { Link, Redirect, withRouter } from 'react-router-dom';

import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import ProcessTracker from '../../components/workflow/process_tracker';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';
import Modal from '../../components/modal/modal';
import ClipboardCopy from '../../components/clipboard/clipboard';

import { clearZipStatus, downloadZip, getSmileIdFromURL, onReloadPage } from '../../common/helpers';
import { configLightBox, convertDate, convertSex, modalClear, textFieldCheck } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { scrollToTop } from '../../common/scroll';
import { translateSmileStatus } from '../../common/search';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';

/**
 * Smile Simulation Details Page
 * @component
 * @alias BusinessSmileSimulationDetails
 * @category BPP
 */
class SmileSimulationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      refresh: false,
      status: 'loading',
      status_type: '',
      status_message: '',
      smile: {},
      smile_files: [],
      smile_setup_files: [],
      smile_comment: '',
      smile_status_comment: '',
      modal: '',
      case_id: '',

      // historical dropdown code
      assign_to_options: [],
      setup_assigned_to: [
        {
          label: 'Unassigned',
          value: '',
        },
      ],
      upload_in_progress: false,
    };
  }

  componentDidMount() {
    const that = this;
    const smile_id = getSmileIdFromURL(that);

    Axios.get(`/apiv3/smilesimulation/${smile_id}`)
      .then(function (res) {
        const smile = res.data.smiles[0];
        const { smilefiles, smileassignment, smileassignmentgroup, smilesetupfiles, smilestatus, allsmilestatus } = { ...res.data };
        const smileassignmentgroup_sorted = _.orderBy(smileassignmentgroup, 'value');
        smile.smile_status = smile.smile_status.replace('Setup Ready for Reupload', 'Setup Ready for Upload');
        that.setState(
          {
            smile: smile,
            case_id: res.data.case_id,
            smile_files: smilefiles,
            smile_setup_files: smilesetupfiles,
            smile_statuses: allsmilestatus,
            assign_to_options: smileassignmentgroup_sorted,
            setup_assigned_to: smileassignment,
            smile_status_comment: smilestatus.length > 0 ? smilestatus[0]['smile_comment'] : '',
            status: 'ready',
          },
          scrollToTop
        );
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }
  /**
   * Displays case Header of the case details page
   * @function
   * @param {Object} context - Smile Simulation information from the API
   * @return {JSX} JSX element for smile header
   */
  displayHeading = (context) => {
    const { smile_id, smile_name, smile_dob, smile_sex, assigned_doctor_name, assigned_doctor_id, doctor_id, doctor__user__first_name } = {
      ...this.state.smile,
    };

    const doctor_name = assigned_doctor_id
      ? assigned_doctor_id === doctor_id
        ? assigned_doctor_name
        : `${assigned_doctor_name} - ${doctor__user__first_name}`
      : `Unassigned - ${doctor__user__first_name}`;
    const doctor_routed_id = assigned_doctor_id ? assigned_doctor_id : doctor_id;

    return (
      <div className="page-info">
        <div className="row">
          <div className="col-lg-2">
            <span className="bold-text">ID: </span>
            <div className="copy-text">
              {userHasPermission('IPP_VIEW', context.permissions) && userHasPermission('SMILE_VIEW', context.permissions) ? (
                <Link
                  to={{
                    pathname: `/portal/${doctor_routed_id}/smile/detail/${smile_id}`,
                  }}
                >
                  {smile_id}
                </Link>
              ) : (
                smile_id
              )}
              <ClipboardCopy copy_text={smile_id} theme="dark"></ClipboardCopy>
            </div>
          </div>
          <div className="col-lg-3">
            <span className="bold-text">Doctor Name: </span>
            <div className="copy-text">
              {userHasPermission('ACCOUNT_IPP_VIEW', context.permissions) && !(doctor_name.indexOf('Unassigned') >= 0) ? (
                <Link to={{ pathname: `/business/portal/accounts/edit/${assigned_doctor_id}` }}>{doctor_name}</Link>
              ) : (
                <span>{doctor_name}</span>
              )}
              <ClipboardCopy copy_text={doctor_name} theme="dark"></ClipboardCopy>
            </div>
          </div>
          <div className="col-lg-3">
            <span className="bold-text">Name: </span>{' '}
            <div className="copy-text">
              <span className="js-exclude"></span>
            </div>
            {smile_name}
            <ClipboardCopy copy_text={smile_name} theme="dark"></ClipboardCopy>
          </div>
          <div className="col-lg-2">
            <span className="bold-text">Sex: </span> <div className="copy-text">{convertSex(smile_sex)}</div>
          </div>
          <div className="col-lg-2">
            <span className="bold-text">DOB: </span> <div className="copy-text">{convertDate(smile_dob)}</div>
          </div>
        </div>
      </div>
    );
  };

  displayStatusAndActions = (context) => {
    const that = this;
    const smile = this.state.smile;
    const { smile_status } = { ...this.state.smile };
    const { upload_in_progress } = { ...this.state };
    const uncancellable_status = ['Released', 'Prospect Lost', 'Resume Case Submission', 'Case Submitted', 'Setup Conversion Process', 'Cancelled'];
    const allowed_to_cancel = !uncancellable_status.includes(smile_status) && !upload_in_progress && userHasPermission('SMILE_CANCEL', context.permissions);
    const show_prospect_lost_comment = this.state.smile_status_comment && this.state.smile_status_comment !== '' && smile_status === 'Prospect Lost';
    return (
      <div className="row page-top-content-no-border">
        <div className="col-lg-6">
          <span className="bold-text">Status: </span>
          {translateSmileStatus(smile)}
          {show_prospect_lost_comment ? ' - ' + this.state.smile_status_comment : ''}
          {this.displayCaseID(userHasPermission('CASE_DETAILS_VIEW', context.permissions))}
        </div>

        <div className="col-lg-6">
          <div className="pull-right">
            <div
              className="btn-action btn-modal"
              onClick={() => {
                that.onActiveModal('status_history');
              }}
              data-toggle="tooltip"
              data-placement="top"
              title="Status History"
            >
              <i className="fa fa-clock-o" aria-hidden="true" />
            </div>
            {allowed_to_cancel ? (
              <div className="btn-action btn-modal" data-toggle="tooltip" data-placement="top" title="Additional Action">
                <i
                  className="fa fa-list-ul dropdown-toggle"
                  type="button"
                  id="dropdownMenu1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  aria-hidden="true"
                />
                <ul className="dropdown-menu dropdown-menu-offset" aria-labelledby="dropdownMenu1">
                  {allowed_to_cancel ? (
                    <li
                      onClick={() => {
                        that.onActiveModal('cancel');
                      }}
                    >
                      {/* eslint-disable-next-line */}
                      <a>Cancel</a>
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Return the element for case ID
   * @function
   *  @param {Boolean} - If user has view case detail bpp permission
   * @return {JSX} - Message element
   */
  displayCaseID = (has_view_permission) => {
    const element = (
      <span className="linked_id_div">
        <span className="bold-text">Case ID: </span>
        {has_view_permission ? <Link to={{ pathname: `/business/portal/case/${this.state.case_id}` }}>{this.state.case_id}</Link> : <>{this.state.case_id}</>}
      </span>
    );

    return this.state.case_id ? element : null;
  };

  /**
   * Zips p files from case files for download
   * @param {object} event - button click event
   * @function
   */
  onZipFiles = (event) => {
    const { smile_id } = { ...this.state.smile };
    const timestamp = Moment().format('YYYY-MM-DD_hh-mm');
    const filename = `${smile_id}_Records_${timestamp}.zip`;
    const that = this;

    if (this.state.status_type !== 'zip') {
      this.setState({ status_type: 'zip', status_message: `Preparing Zip: ${smile_id}` });

      Axios({
        url: `/apiV2/zip/${smile_id}/files`,
        method: 'GET',
      })
        .then((response) => {
          downloadZip(that, response, filename);
        })
        .catch(function (err) {
          clearZipStatus(that);
        });
    }
  };

  getFilesZipButton = (context) => {
    const { smile_status } = { ...this.state.smile };

    if (smile_status !== 'Doctor Upload New Files') {
      return (
        <span className="pull-right btn-light-link" onClick={this.onZipFiles}>
          <i className="fa fa-file-archive-o" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
        </span>
      );
    } else {
      return null;
    }
  };

  displayScanVerifications = (context) => {
    const that = this;
    const { smile_status } = { ...this.state.smile };

    return smile_status === 'INBRACE Checking Files' && userHasPermission('SMILE_EDIT', context.permissions) ? (
      <div className="business-division text-center">
        <div className="bold-text">Scans Verification</div>
        <div className="business-message-action">
          <div className="business-message">
            Once scans verification has been completed, click <span className="bold-text">Complete</span> to proceed to the Smile Simulation step or click{' '}
            <span className="bold-text">Incomplete</span> to have the doctor upload new scans
          </div>
          <div className="">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                that.onActiveModal('scan_complete');
              }}
            >
              Complete
            </button>
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                that.onActiveModal('scan_incomplete');
              }}
            >
              Incomplete
            </button>
          </div>
        </div>
      </div>
    ) : smile_status === 'INBRACE Checking Files' ? (
      <div className="business-division text-center">
        <div className="bold-text">Scans Verification</div>
        <div className="business-message-action">
          <div className="business-message">The scans are currently being verified...</div>
        </div>
      </div>
    ) : null;
  };

  getStatusDateForVerification = (statuses) => {
    const status = statuses && statuses.length > 0 && statuses[0] ? statuses[0] : [];
    let date_index = 0;

    if (status && statuses.length > 2 && status.smile_status === 'INBRACE Checking Files') {
      for (let i = 0; i < statuses.length; i++) {
        if (statuses[i]['smile_status'] === 'Doctor Upload New Files') {
          date_index = i;
          break;
        }
      }

      return statuses[date_index]['created_date'];
    }

    return;
  };

  displaySmileRecords = (context) => {
    const { smile_files, smile_statuses } = this.state;
    const is_allowed_to_download_zip = userHasPermission('SMILE_DETAILS_VIEW', context.permissions);

    return (
      <div id="record-area">
        <div className="bold-text business-dark-heading">
          Records
          {is_allowed_to_download_zip ? this.getFilesZipButton() : null}
        </div>
        <div className="business-dark-theme">
          <div className="business-dark-theme-content">
            <ThumbnailURLInterface
              files={smile_files}
              images={configLightBox(smile_files)}
              hide_occlusion={true}
              theme="dark"
              latest_status_date={this.getStatusDateForVerification(smile_statuses)}
            />
            {this.displayScanVerifications(context)}
          </div>
        </div>
      </div>
    );
  };

  displaySmilePerferences = () => {
    const { smile_correction, smile_spaces, smile_diastema, smile_special_instructions } = { ...this.state.smile };
    const residual_spaces_response = `${smile_spaces ? 'Maintain' : 'Do not maintain'} space for restoration. ${
      smile_diastema ? 'Maintain' : 'Do not maintain'
    } diastema.`;

    return (
      <div id="preference-area">
        <div className="bold-text business-dark-heading">Smile Simulation Preferences</div>
        <div className="business-dark-theme text-wrappable">
          <div className="business-dark-theme-content">
            <div className="bold-text">Anterior-Posterior Correction</div>
            <div>{smile_correction ? 'Yes' : 'No'}</div>
            <div className="bold-text">Residual Spaces</div>
            <div>{residual_spaces_response}</div>
            <div style={{ fontWeight: 'bold' }}>Special Instructions</div>
            <div>{smile_special_instructions ? smile_special_instructions : 'N/A'}</div>
          </div>
        </div>
      </div>
    );
  };

  handleAssignTo = (setup_assigned_to) => {
    const { smile_id } = { ...this.state.smile };

    if (setup_assigned_to) {
      this.setState({ setup_assigned_to: setup_assigned_to }, () => {
        Axios.post(`/apiv3/smilesimulation/${smile_id}/assignment`, setup_assigned_to);
      });
    }
  };

  isUploading = (state) => {
    this.setState({
      upload_in_progress: state,
    });
  };

  onUpload = (data) => {
    const that = this;
    const { smile_id } = { ...this.state.smile };

    let smile = this.state.smile;
    smile.smile_setup_files = data;

    if (data && data[0] && data[0].folder === 'setup') {
      Axios.post(`/apiv3/smilesimulation/${smile_id}/setup_file_upload`, smile)
        .then(function (res) {
          that.setState({
            smile_setup_files: smile.smile_setup_files,
            upload_in_progress: false,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  onSetupRemovalRequest = (e) => {
    e.preventDefault();

    this.setState({
      modal: 'setup_removal',
    });
  };

  onSetupRemove = (event) => {
    event.preventDefault();

    const that = this;
    const smile = { ...this.state };
    const { smile_id } = { ...this.state.smile };

    Axios.delete(`/apiv3/smilesimulation/${smile_id}/setup_file_remove`, smile)
      .then(function (res) {
        const smile_setup_files = { ...res.data };
        that.setState({
          smile_setup_files: smile_setup_files,
          modal: '',
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Handles opening the setup viewer window
   * @function
   * @param {Object} event - The click event
   */
  onSetupClick = () => {
    const { smile_id } = { ...this.state.smile };
    window.open(`/smile_setup/${smile_id}/simulation`);
  };

  /**
   * Retrieves last status history
   * @function
   */
  getLatestStatusHistory = () => {
    const that = this;
    const smile_id = getSmileIdFromURL(that);
    return Axios.get(`/apiv3/smilesimulation/${smile_id}`).then(function (res) {
      const { allsmilestatus } = { ...res.data };

      that.setState({
        smile_statuses: allsmilestatus,
      });
    });
  };

  /**
   * Retrieves last active status
   * @function
   */
  getLastActiveStatus = () => {
    if (this.state.smile_statuses && this.state.smile_statuses.length > 0 && this.state.smile_statuses[0]['smile_status'] === 'Cancelled') {
      return this.state.smile_statuses[1]['smile_status'];
    } else if (this.state.smile_statuses && this.state.smile_statuses.length > 0) {
      return this.state.smile_statuses[0]['smile_status'];
    }

    return '';
  };

  isCancelled = () => {
    return this.state.smile_statuses[0]['smile_status'] === 'Cancelled';
  };

  displaySmileSimulations = (context) => {
    const { smile_id, smile_status } = { ...this.state.smile };
    const hide = smile_status === 'INBRACE Checking Files' || smile_status === 'Doctor Upload New Files';
    const last_active_status = this.getLastActiveStatus();
    const allow_assigning =
      (smile_status === 'Setup Ready for Upload' ||
        smile_status === 'Setup Conversion Failed' ||
        smile_status === 'Setup Ready for Release' ||
        last_active_status !== 'INBRACE Checking Files') &&
      userHasPermission('SMILE_EDIT', context.permissions);

    const complete =
      smile_status === 'Released' || smile_status === 'Prospect Lost' || smile_status === 'Resume Case Submission' || smile_status === 'Case Submitted';

    return hide || (this.isCancelled() && (last_active_status === 'INBRACE Checking Files' || last_active_status === 'Doctor Upload New Files')) ? null : (
      <div id="setup-area" className="setup-container">
        <div className="bold-text business-dark-heading">Smile Simulation</div>
        <div className="business-dark-theme">
          <div className="business-dark-theme-content">
            {complete ? (
              <div className="business-dark-setup-window">
                <div className="setup-text">
                  <span className="bold-text">Smile Simulation</span>
                </div>
                <div className="relative">
                  <img
                    className="img-preview img-sm pointer"
                    data-setup={1}
                    src={process.env.PUBLIC_URL + '/static/img/setup.jpg'}
                    onClick={this.onSetupClick}
                    alt={'setup' + 1}
                  />
                </div>
              </div>
            ) : (
              <div className="setup-assigned-to setup-assigned-to-hrless">
                <div className="setup-text">
                  <span className="bold-text">Smile Simulation Assigned To: </span>
                  {allow_assigning && !this.isCancelled() ? (
                    <Select
                      name="setup_assignment"
                      className="assign-to-dropdown"
                      placeholder="Unassigned"
                      item="User"
                      value={this.state.setup_assigned_to}
                      options={this.state.assign_to_options}
                      autosize={false}
                      onChange={this.handleAssignTo}
                    />
                  ) : this.state.setup_assigned_to && this.state.setup_assigned_to.label ? (
                    this.state.setup_assigned_to.label
                  ) : (
                    'Unassigned'
                  )}

                  {this.state.setup_assigned_to && this.state.setup_assigned_to.label && this.state.setup_assigned_to.label !== 'Unassigned' ? (
                    <ProcessTracker
                      process="setup_smile"
                      stage={last_active_status}
                      smile_id={smile_id}
                      project={this}
                      setup_upload={this.state.smile_setup_files}
                      onActiveModal={this.onActiveModal}
                      isUploading={this.state.isUploading}
                      postAction={this.postAction}
                      status_comment={this.state.smile_status_comment}
                      status={smile_status}
                      user_roles_and_permissions={context}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  onActiveModal = (modal_name) => {
    this.setState({
      modal: modal_name,
    });
  };

  onDismissModal = (event) => {
    this.setState({
      modal: '',
      smile_comment: '',
    });

    modalClear();
  };

  displayModals = () => {
    return (
      <>
        {this.displayCancelModal()}
        {this.displayScanCompleteModal()}
        {this.displayScanIncompleteModal()}
        {this.displaySetupRemovalModal()}
        {this.displaySetupConvertModal()}
        {this.displaySetupConvertCompleteModal()}
        {this.displaySetupConvertIncompleteModal()}
        {this.displaySetupRevisionModal()}
        {this.displaySetupReleaseModal()}
        {this.displaySmileStatusHistory()}
      </>
    );
  };

  postAction = (action) => {
    const that = this;
    const { smile_id } = { ...this.state.smile };
    const { smile_comment } = { ...this.state };
    const data = {
      smile_comment: smile_comment !== '' ? smile_comment : action === 'convert_failed' ? 'System Admin Failed Smile Simulation Conversion' : null,
    };

    Axios.post(`/apiv3/smilesimulation/${smile_id}/${action}`, data)
      .then((res) => {
        let smile = res.data.smiles[0];
        const allsmilestatus = res.data.allsmilestatus;
        smile.smile_status = smile.smile_status.replace('Setup Ready for Reupload', 'Setup Ready for Upload');

        that.setState({
          smile: smile,
          modal: '',
          smile_statuses: allsmilestatus,
          smile_status_comment: data['smile_comment'],
          smile_comment: '',
        });
      })
      .catch((err) => {
        handleHttpRequestError(err, that);
        if (err && err.response && err.response.status === 409) {
          that.setState({
            modal: '',
          });
        }
      });
  };

  onStatusCommentChange = (event) => {
    const smile_comment = textFieldCheck(event.target.value);

    this.setState({
      smile_comment: smile_comment,
    });
  };

  displaySmileStatusHistory = () => {
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'status_history' ? (
      <Modal
        preset="status"
        smile_id={smile_id}
        header_text={`Status History - Smile ${smile_id}`}
        modal_class="modal-lg"
        modal_body_class="text-left"
        onCloseButtonClick={this.onDismissModal}
        theme="bpp"
      />
    ) : null;
  };

  displayCancelModal = () => {
    const that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'cancel' ? (
      <Modal
        preset="decision"
        header_text={`Cancel Smile Simulation - ${smile_id}`}
        message_text="Are you sure you want to cancel this Smile Simulation?"
        confirm_btn_text="Cancel Smile Simulation"
        close_btn_text="Do Not Cancel"
        onConfirmButtonClick={() => {
          that.postAction('cancel');
        }}
        onCloseButtonClick={this.onDismissModal}
        theme="bpp"
      />
    ) : null;
  };

  displayScanIncompleteModal = () => {
    const that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'scan_incomplete' ? (
      <Modal
        preset="decision-dialog"
        header_text={`Scans Incomplete - ${smile_id}`}
        message_text="Are you sure you want to mark scans as incomplete?"
        textarea_placeholder="Specify reason for marking incomplete. Message will be displayed to the doctor"
        textarea_warning_text="Please specify reason for marking incomplete"
        confirm_btn_text="Incomplete"
        close_btn_text="Cancel"
        onConfirmButtonClick={() => {
          that.postAction('scan_incomplete');
        }}
        onCloseButtonClick={this.onDismissModal}
        onTextAreaChange={this.onStatusCommentChange}
        theme="bpp"
      />
    ) : null;
  };

  displayScanCompleteModal = () => {
    const that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'scan_complete' ? (
      <Modal
        preset="decision"
        header_text={`Scans Complete - ${smile_id}`}
        message_text="Are you sure you want to mark scans as complete?"
        confirm_btn_text="Complete"
        close_btn_text="Cancel"
        onConfirmButtonClick={() => {
          that.postAction('scan_complete');
        }}
        onCloseButtonClick={this.onDismissModal}
        theme="bpp"
      />
    ) : null;
  };

  displaySetupRemovalModal = () => {
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_removal' ? (
      <Modal
        preset="decision"
        header_text={`Remove Smile Simulation - ${smile_id}`}
        message_text="Are you sure you want to remove this Smile Simulation?"
        confirm_btn_text="Remove"
        theme="bpp"
        onCloseButtonClick={this.onDismissModal}
        onConfirmButtonClick={this.onSetupRemove}
      />
    ) : null;
  };

  displaySetupConvertModal = () => {
    let that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_convert' ? (
      <Modal
        preset="decision"
        header_text={`Convert Smile Simulation - ${smile_id}`}
        message_text="Are you sure you want to convert this Smile Simulation?"
        confirm_btn_text="Convert"
        close_btn_text="Cancel"
        theme="bpp"
        onCloseButtonClick={this.onDismissModal}
        onConfirmButtonClick={() => {
          that.postAction('convert');
        }}
      />
    ) : null;
  };

  displaySetupConvertCompleteModal = () => {
    let that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_complete' ? (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={`Conversion Complete - ${smile_id}`}
        message_text="Are you sure you want to complete this conversion?"
        confirm_btn_text="Complete"
        onConfirmButtonClick={() => {
          that.postAction('convert_success');
        }}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onDismissModal}
      />
    ) : null;
  };

  displaySetupConvertIncompleteModal = () => {
    let that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_incomplete' ? (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={`Conversion Incomplete - ${smile_id}`}
        message_text="Are you sure you want to set status to incomplete for this conversion?"
        confirm_btn_text="Incomplete"
        onConfirmButtonClick={() => {
          that.postAction('convert_failed');
        }}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onDismissModal}
      />
    ) : null;
  };

  displaySetupRevisionModal = () => {
    let that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_revision' ? (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={`Do Not Release Smile Simulation - ${smile_id}`}
        message_text="Are you sure you do not want to release this Smile Simulation?"
        confirm_btn_text="Confirm"
        onConfirmButtonClick={() => {
          that.postAction('reupload_setup');
        }}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onDismissModal}
      />
    ) : null;
  };

  displaySetupReleaseModal = () => {
    let that = this;
    const { smile_id } = { ...this.state.smile };
    const { modal } = { ...this.state };

    return modal === 'setup_release' ? (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={`Release Smile Simulation - ${smile_id}`}
        message_text="Are you sure you want to release this Smile Simulation?"
        confirm_btn_text="Release"
        onConfirmButtonClick={() => {
          that.postAction('release');
        }}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onDismissModal}
      />
    ) : null;
  };

  render() {
    const { smile_id } = { ...this.state.smile };

    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.status === 'loading') {
      return <Loader />;
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(context) => {
            return userHasPermission('SMILE_DETAILS_VIEW', context.permissions) ? (
              <div>
                <Helmet>
                  <title>Smile Detail {smile_id} | InBrace Business Portal</title>
                </Helmet>

                <div className="main-content">
                  <div className="dark-options dark-options-offset">
                    <div className="page-heading page-return">
                      <Link
                        to={{
                          pathname: '/business/portal/smiles',
                        }}
                      >
                        <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to Smile List
                      </Link>
                    </div>
                    <div className="page-heading">Smile Details</div>
                    {this.displayHeading(context)}

                    <div className="page-tab-container page-tab-container--spaced">
                      <div className="page-tab-content">
                        {this.displayStatusAndActions(context)}
                        <div className="row">
                          <div className="col-lg-6 business-dark-lg-6-l">
                            {this.displaySmileRecords(context)}
                            {this.displaySmileSimulations(context)}
                          </div>
                          <div className="col-lg-6 business-dark-lg-6-r">{this.displaySmilePerferences(context)}</div>
                        </div>
                        {this.displayModals()}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.refresh ? (
                  <Modal
                    preset="action"
                    x_btn={false}
                    header_text="Session Expired"
                    message_text="Sorry, your session has expired. Please refresh."
                    confirm_btn_text="Refresh"
                    onConfirmButtonClick={onReloadPage}
                    theme="bpp"
                  />
                ) : null}
              </div>
            ) : (
              <Redirect to="/business/portal" />
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

export default withRouter(SmileSimulationDetails);
