import {
  FETCH_ITERO_SCANS_ERROR,
  FETCH_ITERO_SCANS_PENDING,
  FETCH_ITERO_SCANS_SUCCESS,
  FETCH_ITERO_PHOTOS_ERROR,
  FETCH_ITERO_PHOTOS_PENDING,
  FETCH_ITERO_PHOTOS_SUCCESS,
  UPDATE_ICOMPLETE_CASE_ITERO_FILES_PENDING,
  UPDATE_ICOMPLETE_CASE_ITERO_FILES_SUCCESS,
  UPDATE_ICOMPLETE_CASE_ITERO_FILES_ERROR,
  ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_PENDING,
  ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_SUCCESS,
  ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_ERROR,
  DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_PENDING,
  DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_SUCCESS,
  DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_ERROR,
  ADD_ITERO_PHOTOS_TO_DE_PENDING,
  ADD_ITERO_PHOTOS_TO_DE_SUCCESS,
  ADD_ITERO_PHOTOS_TO_DE_ERROR,
  DELETE_ITERO_PHOTOS_FROM_DE_PENDING,
  DELETE_ITERO_PHOTOS_FROM_DE_SUCCESS,
  DELETE_ITERO_PHOTOS_FROM_DE_ERROR,
  GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_PENDING,
  GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_SUCCESS,
  GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_ERROR,
  DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_PENDING,
  DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_SUCCESS,
  DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_ERROR,
  GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_PENDING,
  GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_SUCCESS,
  GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_ERROR,
} from '../../../actions/ipp/itero/itero_files';

const initialState = {
  fetchIteroScansPending: false,
  fetchIteroScansSuccess: false,
  fetchIteroScansError: null,
  fetchIteroPhotosPending: false,
  fetchIteroPhotosSuccess: false,
  fetchIteroPhotosError: null,
  update_incomplete_case_itero_files_pending: false,
  update_incomplete_case_itero_files_success: false,
  update_incomplete_case_itero_files_error: null,
  addIteroPhotosToIncompleteCasePending: false,
  addIteroPhotosToIncompleteCaseSuccess: false,
  addIteroPhotosToIncompleteCaseError: null,
  deleteIteroPhotosFromIncompleteCasePending: false,
  deleteIteroPhotosFromIncompleteCaseSuccess: false,
  deleteIteroPhotosFromIncompleteCaseError: null,
  addIteroPhotosToDEPending: false,
  addIteroPhotosToDESuccess: false,
  addIteroPhotosToDEError: null,
  deleteIteroPhotosFromDEPending: false,
  deleteIteroPhotosFromDESuccess: false,
  deleteIteroPhotosFromDEError: null,
  getIteroPhotoRecordsForProgressRecordPending: false,
  getIteroPhotoRecordsForProgressRecordSuccess: false,
  getIteroPhotoRecordsForProgressRecordError: null,
  deleteIteroPhotosFromProgressRecordPending: false,
  deleteIteroPhotosFromProgressRecordSuccess: false,
  deleteIteroPhotosFromProgressRecordError: null,
  getIteroPhotoRecordsForRecordsReuploadPending: false,
  getIteroPhotoRecordsForRecordsReuploadSuccess: false,
  getIteroPhotoRecordsForRecordsReuploadError: null,
};

export function iteroFilesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITERO_SCANS_PENDING:
      return {
        ...state,
        fetchIteroScansPending: true,
        fetchIteroScansSuccess: false,
        fetchIteroScansError: null,
      };

    case FETCH_ITERO_SCANS_SUCCESS:
      return {
        ...state,
        fetchIteroScansPending: false,
        fetchIteroScansSuccess: true,
        fetchIteroScansError: null,
      };

    case FETCH_ITERO_SCANS_ERROR:
      return {
        ...state,
        fetchIteroScansPending: false,
        fetchIteroScansSuccess: false,
        fetchIteroScansError: action.error,
      };

    case FETCH_ITERO_PHOTOS_PENDING:
      return {
        ...state,
        fetchIteroPhotosPending: true,
        fetchIteroPhotosSuccess: false,
        fetchIteroPhotosError: null,
      };

    case FETCH_ITERO_PHOTOS_SUCCESS:
      return {
        ...state,
        fetchIteroPhotosPending: false,
        fetchIteroPhotosSuccess: true,
        fetchIteroPhotosError: null,
        iteroPhotos: action.iteroPhotos,
      };

    case FETCH_ITERO_PHOTOS_ERROR:
      return {
        ...state,
        fetchIteroPhotosPending: false,
        fetchIteroPhotosSuccess: false,
        fetchIteroPhotosError: action.error,
      };

    case UPDATE_ICOMPLETE_CASE_ITERO_FILES_PENDING:
      return {
        ...state,
        update_incomplete_case_itero_files_pending: true,
        update_incomplete_case_itero_files_success: false,
        update_incomplete_case_itero_files_error: null,
      };

    case UPDATE_ICOMPLETE_CASE_ITERO_FILES_SUCCESS:
      return {
        ...state,
        update_incomplete_case_itero_files_pending: false,
        update_incomplete_case_itero_files_success: true,
        update_incomplete_case_itero_files_error: null,
      };

    case UPDATE_ICOMPLETE_CASE_ITERO_FILES_ERROR:
      return {
        ...state,
        update_incomplete_case_itero_files_pending: false,
        update_incomplete_case_itero_files_success: false,
        update_incomplete_case_itero_files_error: action.error,
      };

    case ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_PENDING:
      return {
        ...state,
        addIteroPhotosToIncompleteCasePending: true,
        addIteroPhotosToIncompleteCaseSuccess: false,
        addIteroPhotosToIncompleteCaseError: null,
      };

    case ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_SUCCESS:
      return {
        ...state,
        addIteroPhotosToIncompleteCasePending: false,
        addIteroPhotosToIncompleteCaseSuccess: true,
        addIteroPhotosToIncompleteCaseError: null,
      };

    case ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_ERROR:
      return {
        ...state,
        addIteroPhotosToIncompleteCasePending: false,
        addIteroPhotosToIncompleteCaseSuccess: false,
        addIteroPhotosToIncompleteCaseError: action.error,
      };

    case DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_PENDING:
      return {
        ...state,
        deleteIteroPhotosFromIncompleteCasePending: true,
        deleteIteroPhotosFromIncompleteCaseSuccess: false,
        deleteIteroPhotosFromIncompleteCaseError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_SUCCESS:
      return {
        ...state,
        deleteIteroPhotosFromIncompleteCasePending: false,
        deleteIteroPhotosFromIncompleteCaseSuccess: true,
        deleteIteroPhotosFromIncompleteCaseError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_ERROR:
      return {
        ...state,
        deleteIteroPhotosFromIncompleteCasePending: false,
        deleteIteroPhotosFromIncompleteCaseSuccess: false,
        deleteIteroPhotosFromIncompleteCaseError: action.error,
      };

    case ADD_ITERO_PHOTOS_TO_DE_PENDING:
      return {
        ...state,
        addIteroPhotosToDEPending: true,
        addIteroPhotosToDESuccess: false,
        addIteroPhotosToDEError: null,
      };

    case ADD_ITERO_PHOTOS_TO_DE_SUCCESS:
      return {
        ...state,
        addIteroPhotosToDEPending: false,
        addIteroPhotosToDESuccess: true,
        addIteroPhotosToDEError: null,
      };

    case ADD_ITERO_PHOTOS_TO_DE_ERROR:
      return {
        ...state,
        addIteroPhotosToDEPending: false,
        addIteroPhotosToDESuccess: false,
        addIteroPhotosToDEError: action.error,
      };

    case DELETE_ITERO_PHOTOS_FROM_DE_PENDING:
      return {
        ...state,
        deleteIteroPhotosFromDEPending: true,
        deleteIteroPhotosFromDESuccess: false,
        deleteIteroPhotosFromDEError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_DE_SUCCESS:
      return {
        ...state,
        deleteIteroPhotosFromDEPending: false,
        deleteIteroPhotosFromDESuccess: true,
        deleteIteroPhotosFromDEError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_DE_ERROR:
      return {
        ...state,
        deleteIteroPhotosFromDEPending: false,
        deleteIteroPhotosFromDESuccess: false,
        deleteIteroPhotosFromDEError: action.error,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_PENDING:
      return {
        ...state,
        getIteroPhotoRecordsForProgressRecordPending: true,
        getIteroPhotoRecordsForProgressRecordSuccess: false,
        getIteroPhotoRecordsForProgressRecordError: null,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_SUCCESS:
      return {
        ...state,
        getIteroPhotoRecordsForProgressRecordPending: false,
        getIteroPhotoRecordsForProgressRecordSuccess: true,
        getIteroPhotoRecordsForProgressRecordError: null,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_ERROR:
      return {
        ...state,
        getIteroPhotoRecordsForProgressRecordPending: false,
        getIteroPhotoRecordsForProgressRecordSuccess: false,
        getIteroPhotoRecordsForProgressRecordError: action.error,
      };

    case DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_PENDING:
      return {
        ...state,
        deleteIteroPhotosFromProgressRecordPending: true,
        deleteIteroPhotosFromProgressRecordSuccess: false,
        deleteIteroPhotosFromProgressRecordError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_SUCCESS:
      return {
        ...state,
        deleteIteroPhotosFromProgressRecordPending: false,
        deleteIteroPhotosFromProgressRecordSuccess: true,
        deleteIteroPhotosFromProgressRecordError: null,
      };

    case DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_ERROR:
      return {
        ...state,
        deleteIteroPhotosFromProgressRecordPending: false,
        deleteIteroPhotosFromProgressRecordSuccess: false,
        deleteIteroPhotosFromProgressRecordError: action.error,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_PENDING:
      return {
        ...state,
        getIteroPhotoRecordsForRecordsReuploadPending: true,
        getIteroPhotoRecordsForRecordsReuploadSuccess: false,
        getIteroPhotoRecordsForRecordsReuploadError: null,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_SUCCESS:
      return {
        ...state,
        getIteroPhotoRecordsForRecordsReuploadPending: false,
        getIteroPhotoRecordsForRecordsReuploadSuccess: true,
        getIteroPhotoRecordsForRecordsReuploadError: null,
      };

    case GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_ERROR:
      return {
        ...state,
        getIteroPhotoRecordsForRecordsReuploadPending: false,
        getIteroPhotoRecordsForRecordsReuploadSuccess: false,
        getIteroPhotoRecordsForRecordsReuploadError: action.error,
      };

    default:
      return state;
  }
}

export const getFetchIteroScansPending = (state) => state.iteroFilesReducer.fetchIteroScansPending;
export const getFetchIteroScansSuccess = (state) => state.iteroFilesReducer.fetchIteroScansSuccess;
export const getFetchIteroScansError = (state) => state.iteroFilesReducer.fetchIteroScansError;
export const getFetchIteroPhotosPending = (state) => state.iteroFilesReducer.fetchIteroPhotosPending;
export const getFetchIteroPhotosSuccess = (state) => state.iteroFilesReducer.fetchIteroPhotosSuccess;
export const getFetchIteroPhotosError = (state) => state.iteroFilesReducer.fetchIteroPhotosError;
export const getupdateCaseIteroFilesPending = (state) => state.iteroFilesReducer.update_incomplete_case_itero_files_pending;
export const getupdateCaseIteroFilesSuccess = (state) => state.iteroFilesReducer.update_incomplete_case_itero_files_success;
export const getupdateCaseIteroFilesError = (state) => state.iteroFilesReducer.update_incomplete_case_itero_files_error;
export const getAddIteroPhotosToIncompleteCasePending = (state) => state.iteroFilesReducer.addIteroPhotosToIncompleteCasePending;
export const getAddIteroPhotosToIncompleteCaseSuccess = (state) => state.iteroFilesReducer.addIteroPhotosToIncompleteCaseSuccess;
export const getAddIteroPhotosToIncompleteCaseError = (state) => state.iteroFilesReducer.addIteroPhotosToIncompleteCaseError;
export const getDeleteIteroPhotosFromIncompleteCasePending = (state) => state.iteroFilesReducer.deleteIteroPhotosFromIncompleteCasePending;
export const getDeleteIteroPhotosFromIncompleteCaseSuccess = (state) => state.iteroFilesReducer.deleteIteroPhotosFromIncompleteCaseSuccess;
export const getDeleteIteroPhotosFromIncompleteCaseError = (state) => state.iteroFilesReducer.deleteIteroPhotosFromIncompleteCaseError;
export const getAddIteroPhotosToDEPending = (state) => state.iteroFilesReducer.addIteroPhotosToDEPending;
export const getAddIteroPhotosToDESuccess = (state) => state.iteroFilesReducer.addIteroPhotosToDESuccess;
export const getAddIteroPhotosToDEError = (state) => state.iteroFilesReducer.addIteroPhotosToDEError;
export const getDeleteIteroPhotosFromDEPending = (state) => state.iteroFilesReducer.deleteIteroPhotosFromDEPending;
export const getDeleteIteroPhotosFromDESuccess = (state) => state.iteroFilesReducer.deleteIteroPhotosFromDESuccess;
export const getDeleteIteroPhotosFromDEError = (state) => state.iteroFilesReducer.deleteIteroPhotosFromDEError;
export const getGetIteroPhotoRecordsForProgressRecordPending = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForProgressRecordPending;
export const getGetIteroPhotoRecordsForProgressRecordSuccess = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForProgressRecordSuccess;
export const getGetIteroPhotoRecordsForProgressRecordError = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForProgressRecordError;
export const getDeleteIteroPhotosFromProgressRecordPending = (state) => state.iteroFilesReducer.deleteIteroPhotosFromProgressRecordPending;
export const getDeleteIteroPhotosFromProgressRecordSuccess = (state) => state.iteroFilesReducer.deleteIteroPhotosFromProgressRecordSuccess;
export const getDeleteIteroPhotosFromProgressRecordError = (state) => state.iteroFilesReducer.deleteIteroPhotosFromProgressRecordError;
export const getGetIteroPhotoRecordsForRecordsReuploadPending = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForRecordsReuploadPending;
export const getGetIteroPhotoRecordsForRecordsReuploadSuccess = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForRecordsReuploadSuccess;
export const getGetIteroPhotoRecordsForRecordsReuploadError = (state) => state.iteroFilesReducer.getIteroPhotoRecordsForRecordsReuploadError;
