import {
  MARK_SUCCESS_PENDING,
  MARK_SUCCESS_SUCCESS,
  MARK_SUCCESS_ERROR,
  MARK_FAILED_PENDING,
  MARK_FAILED_SUCCESS,
  MARK_FAILED_ERROR,
  OPEN_MARK_SUCCESS_MODAL,
  OPEN_MARK_FAILED_MODAL,
  CLOSE_MARK_SUCCESS_MODAL,
  CLOSE_MARK_FAILED_MODAL,
} from '../../actions/ddm/ddm_generation';
const initialState = {
  pending: false,
  error: null,
  session_error: false,
  mark_as_success_modal: false,
  mark_as_failure_modal: false,
};
export function ddmGenerateReducer(state = initialState, action) {
  switch (action.type) {
    case MARK_SUCCESS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MARK_SUCCESS_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MARK_SUCCESS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case MARK_FAILED_PENDING:
      return {
        ...state,
        pending: true,
      };
    case MARK_FAILED_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case MARK_FAILED_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error,
      };
    case OPEN_MARK_SUCCESS_MODAL:
      return {
        ...state,
        mark_as_success_modal: true,
      };
    case OPEN_MARK_FAILED_MODAL:
      return {
        ...state,
        mark_as_failure_modal: true,
      };
    case CLOSE_MARK_SUCCESS_MODAL:
      return {
        ...state,
        mark_as_success_modal: false,
      };
    case CLOSE_MARK_FAILED_MODAL:
      return {
        ...state,
        mark_as_failure_modal: false,
      };
    default:
      return state;
  }
}
export const getPending = (state) => state.ddmGenerateReducer.pending;
export const getSessionError = (state) => state.ddmGenerateReducer.session_error;
export const getMarkAsSuccessModal = (state) => state.ddmGenerateReducer.mark_as_success_modal;
export const getMarkAsFailureModal = (state) => state.ddmGenerateReducer.mark_as_failure_modal;
