import React, { useState } from 'react';
import TeethUtils from '../../../common/teeth_utils';
import { getTmtClassNames, FIELDS } from './wasm_movement_table';
import { movementTableFractionDigits } from './constants';
import { HideContentIf } from '../../../common/hocs/hide-content-if';
import { isValueNegative } from './pipes/validator.pipe';
import { takeAbsValue } from './pipes/transformer.pipe';
import { connect } from 'react-redux';
import { isEnrolledProviderEditPhase2 } from '../../../redux/reducers/common/common_case_details';

/**
 * Calculates the difference in movement value based on the provided value.
 * @param {string|number} value - The movement value to be evaluated.
 * @returns {string} Returns the calculated movement value difference or an empty string if conditions are not met.
 */
function getMovementValueDiff(value) {
  if (value === undefined) {
    return '';
  }
  const floatValue = parseFloat(value);
  if (floatValue === 0) {
    return '';
  }
  const fixedValue = floatValue.toFixed(movementTableFractionDigits);
  if (fixedValue === '0.0' || fixedValue === '-0.0') {
    return '';
  }
  return fixedValue;
}
/**
 * Determines the sign of the provided value based on the given signs object.
 * @param {number} value - The value to evaluate its sign.
 * @param {Object} signs - An object containing the positive and negative sign indicators.
 * @param {string} signs.neg - The negative sign indicator.
 * @param {string} signs.pos - The positive sign indicator.
 * @returns {string} Returns the corresponding sign indicator from the signs object or an empty string if the value is falsy.
 */
function getSign(value, signs) {
  if (!value) {
    return '';
  }
  return isValueNegative(value) ? signs.neg : signs.pos;
}

function WasmMovementTableView(props) {
  const { movementData, isEnrolledPhase2 } = props;
  const missing_teeth = ['UR8', 'UL8', 'LL8', 'LR8'];
  const [arch, setArch] = useState('upper');
  const teeth_set = TeethUtils.teethSetInPalmerBySection();
  const tmt = arch === 'upper' ? teeth_set.upper : teeth_set.lower;

  return (
    <div className="wasm-tmt-container">
      <div className="wasm-tmt-arch-option">
        <label>
          <input type="radio" value="upper" checked={arch === 'upper'} onChange={(e) => setArch(e.target.value)} /> Upper
        </label>
        <label>
          <input type="radio" value="lower" checked={arch === 'lower'} onChange={(e) => setArch(e.target.value)} /> Lower
        </label>
      </div>
      {tmt.map((teeth) => (
        <div key={teeth} className="wasm-tmt-header">
          {teeth}
        </div>
      ))}
      {FIELDS.map((field, i) => (
        <React.Fragment key={field.key}>
          <div className={getTmtClassNames(i % 2 === 0)}>{field.text}</div>
          {tmt.map((teeth) => {
            const fixedValue = getMovementValueDiff(movementData?.[teeth]?.[field.key]);
            const value = isEnrolledPhase2 ? takeAbsValue(fixedValue) : fixedValue;
            return (
              <div className={getTmtClassNames(i % 2 === 0, missing_teeth.includes(teeth))} key={teeth}>
                <div>{value}</div>
                <HideContentIf condition={!isEnrolledPhase2 || !value}>
                  <span className="tmt-input-sign">{getSign(movementData?.[teeth]?.[field.key], field.signs)}</span>
                </HideContentIf>
              </div>
            );
          })}
        </React.Fragment>
      ))}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
  };
};

export default connect(mapStateToProps)(WasmMovementTableView);
