import React from 'react';
import { Box } from '@material-ui/core';

import ManualWireTableEmptyElement from '../manual_wire_table_empty_element/manual_wire_table_empty_element';
import ManualWireTableOffsetValues from './manual_wire_table_offset_values';
import { getFilteredIndexes } from '../../manual_wire_utils';
import './manual_wire_table_offset.scss';

const ManualWireTableOffset = ({ arch, missingTeeths, range, disableAllFields }) => {
    const filteredIndexes = getFilteredIndexes(arch, missingTeeths, range, 15);

    return (
        <Box display="flex" flexDirection="row" className='manual-wire-table-offset' component="div">
            <ManualWireTableEmptyElement classname='dark-bg' />
            {filteredIndexes.map((teethIndex, index) => (
                <ManualWireTableOffsetValues 
                    arch={arch}
                    index={teethIndex}
                    disableAllFields={disableAllFields}
                    key={`manual-wire-table-offset-${index}`}
                />
            ))}
            <ManualWireTableEmptyElement classname='dark-bg' />
        </Box>
    )
};

export default ManualWireTableOffset;
