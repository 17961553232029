import {
  RESET_VIEWER_OPTIONS_VIEW,
  CHANGE_STEP_SINGLE_PANE,
  CHANGE_STEP_BOTH_PANE,
  CHANGE_VIEW_SINGLE_PANE,
  CHANGE_VIEW_BOTH_PANE,
  CHANGE_JAW_SINGLE_PANE,
  CHANGE_JAW_BOTH_PANE,
  TOGGLE_OPTION_SINGLE_PANE,
  TOGGLE_OPTION_BOTH_PANE,
} from '../../actions/setup_viewer/setup_viewer';

const DEFAULT_VIEWER_OPTIONS = {
  step: 'last',
  gingiva: true,
  superimpose: false,
  grid: false,
  ipr: false,
  view: 'front',
  jaw: 'both',
};

const initialState = {
  viewer_options: {
    1: { ...DEFAULT_VIEWER_OPTIONS },
    2: { ...DEFAULT_VIEWER_OPTIONS },
  },
};

export function setupViewerReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_STEP_BOTH_PANE:
      return changeStepBothPane(state, action);

    case CHANGE_STEP_SINGLE_PANE:
      return changeStepSinglePane(state, action);

    case CHANGE_VIEW_BOTH_PANE:
      return changeViewBothPane(state, action);

    case CHANGE_VIEW_SINGLE_PANE:
      return changeViewSinglePane(state, action);

    case CHANGE_JAW_BOTH_PANE:
      return changeJawBothPane(state, action);

    case CHANGE_JAW_SINGLE_PANE:
      return changeJawSinglePane(state, action);

    case TOGGLE_OPTION_BOTH_PANE:
      return toggleOptionBothPane(state, action);

    case TOGGLE_OPTION_SINGLE_PANE:
      return toggleOptionSinglePane(state, action);

    case RESET_VIEWER_OPTIONS_VIEW:
      return resetViewerOptionsView(state, action);

    default:
      return state;
  }
}

function resetViewerOptionsView(state, action) {
  const newOptions = {
    ...state.viewer_options,
    [action.pane]: {
      ...state.viewer_options[action.pane],
      view: '',
    },
  };
  return { ...state, viewer_options: { ...newOptions } };
}

function changeViewSinglePane(state, action) {
  const { pane, newView } = action;
  const { view, jaw } = getNewViewAndJaw(state.viewer_options[pane].view, newView);
  const newOptions = { ...state.viewer_options, [pane]: { ...state.viewer_options[pane], view: view, jaw: jaw } };
  return { ...state, viewer_options: { ...newOptions } };
}

function changeViewBothPane(state, action) {
  const { viewer_options } = state;
  const isDifferentView = viewer_options['1'].view !== viewer_options['2'].view;
  const originalView = isDifferentView ? '' : viewer_options['1'].view;
  const { view, jaw } = getNewViewAndJaw(originalView, action.newView);

  const newOptions = {
    ...viewer_options,
    1: { ...viewer_options['1'], view: view, jaw: jaw },
    2: { ...viewer_options['2'], view: view, jaw: jaw },
  };
  return { ...state, viewer_options: newOptions };
}

function changeStepSinglePane(state, action) {
  const { viewer_options } = state;
  const { newStep, pane } = action;
  const newOptions = { ...viewer_options, [pane]: { ...viewer_options[pane], step: newStep, superimpose: false } };

  return { ...state, viewer_options: { ...newOptions } };
}

function changeStepBothPane(state, action) {
  const { viewer_options } = state;
  const { newStep } = action;
  const newOptions = {
    ...viewer_options,
    1: { ...viewer_options['1'], step: newStep, superimpose: false },
    2: { ...viewer_options['2'], step: newStep, superimpose: false },
  };

  return { ...state, viewer_options: { ...newOptions } };
}

function changeJawSinglePane(state, action) {
  const { viewer_options } = state;
  const { newJaw, pane } = action;

  const changeToBothJaw = viewer_options[pane].jaw === newJaw;
  const jaw = changeToBothJaw ? 'both' : newJaw;
  const newOptions = { ...viewer_options, [pane]: { ...viewer_options[pane], jaw: jaw } };
  return { ...state, viewer_options: { ...newOptions } };
}

function changeJawBothPane(state, action) {
  const { viewer_options } = state;
  const { newJaw } = action;

  const changeToBothJaw = viewer_options['1'].jaw === viewer_options['2'].jaw && viewer_options['1'].jaw === newJaw;
  const jaw = changeToBothJaw ? 'both' : newJaw;
  const newOptions = { ...viewer_options, 1: { ...viewer_options['1'], jaw: jaw }, 2: { ...viewer_options['2'], jaw: jaw } };
  return { ...state, viewer_options: { ...newOptions } };
}

function toggleOptionSinglePane(state, action) {
  const { viewer_options } = state;
  const { option, pane } = action;

  const changeStep = option === 'superimpose';
  const step = changeStep ? 'last' : viewer_options[pane].step;

  const newOptions = {
    ...viewer_options,
    [pane]: {
      ...viewer_options[pane],
      step: step,
      [option]: !viewer_options[pane][option],
    },
  };
  return { ...state, viewer_options: { ...newOptions } };
}

function toggleOptionBothPane(state, action) {
  const { viewer_options } = state;
  const { option } = action;

  const newOptionState = !(viewer_options['1'][option] && viewer_options['2'][option]); // NAND
  const changeStep = option === 'superimpose' && newOptionState;
  const step1 = changeStep ? 'last' : viewer_options['1'].step;
  const step2 = changeStep ? 'last' : viewer_options['2'].step;

  const newOptions = {
    ...viewer_options,
    1: {
      ...viewer_options['1'],
      step: step1,
      [option]: newOptionState,
    },
    2: {
      ...viewer_options['2'],
      step: step2,
      [option]: newOptionState,
    },
  };
  return { ...state, viewer_options: { ...newOptions } };
}

function getNewViewAndJaw(prevView, newView) {
  const changeToBack = prevView === 'front' && prevView === newView;
  const view = changeToBack ? 'back' : newView;
  const jaw = getJawOnView(view);
  return { view, jaw };
}

function getJawOnView(view) {
  if (view === 'top') {
    return 'upper';
  } else if (view === 'bottom') {
    return 'lower';
  } else {
    return 'both';
  }
}

export const getViewerOptions = (state) => state.setupViewerReducer.viewer_options;
