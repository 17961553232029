import { FETCH_IFU_STATE_PENDING, FETCH_IFU_STATE_SUCCESS, FETCH_IFU_STATE_ERROR } from '../../actions/ifu/ifu_state';

const initialState = {
  ifu_state: null,
};

export function ifuStateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IFU_STATE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_IFU_STATE_SUCCESS:
      return {
        ...state,
        ifu_state: action.ifu_state,
      };

    case FETCH_IFU_STATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export const getIfuState = (state) => state.ifuStateReducer.ifu_state;
