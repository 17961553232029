// External Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Internal Libs
import Modal from '../../modal/modal';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { removeCaseIdInitialNumber, modalClear } from '../../../common/functions';
import { getPDFNameTemplate } from '../../../common/helpers';

const LABELS = {
  smartwire: 'Smartwire and BFL',
  idb: 'IDB Tray',
  bracket: 'Brackets',
  outer_patient: 'Outer Patient Label',
  single_sheet: 'Single Sheet',
};

const LABEL_NAMES = {
  smartwire: 'smartwire_label',
  idb: 'idbtray_label',
  bracket: 'smallbracket_label',
  outer_patient: 'outerpatient_label',
  single_sheet: 'singlesheet_label',
};

/**
 * Displays label generation failure step
 * @component
 * @alias LabelFailure
 * @category BPP
 */
class LabelFailure extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf_modal: false,
      label_inner_packaging_modal: false,
      label_contents_modal: false,
      return_clicked: false,
      delete_inner_packaging_modal: false,
      remove_inner_packaging_in_progress: false,
      delete_inner_contents_modal: false,
      remove_inner_contents_in_progress: false,
      upload_inner_packaging_modal: false,
      upload_contents_modal: false,
      inner_packaging_file: [],
      content_file: [],
      udi_label_modal: false,
      udi_file: [],
      gen_2_manual_upload_modal: false,
      selected_gen_2_label: '',
      smartwire_label_file: [],
      idb_label_file: [],
      bracket_label_file: [],
      outer_patient_label_file: [],
      single_sheet_label_file: [],
      gen_2_label_modal: false,
      delete_gen_2_label_modal: false,
      remove_gen_2_label_in_progress: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.delete_inner_packaging_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('inner_packaging');
      }).length === 0
    ) {
      this.setState({ delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal });
    }

    if (
      this.state.delete_inner_contents_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('contents');
      }).length === 0
    ) {
      this.setState({ delete_inner_contents_modal: !this.state.delete_inner_contents_modal });
    }

    const that = this;
    if (
      this.state.delete_gen_2_label_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes(LABEL_NAMES[that.state.selected_gen_2_label]);
      }).length === 0
    ) {
      this.setState({ delete_gen_2_label_modal: !this.state.delete_gen_2_label_modal, selected_gen_2_label: '' });
    }
  }

  onInnerPackagingModalClick = (event) => {
    this.setState({ label_inner_packaging_modal: true });
  };

  onInnerPackagingModalDismiss = (event) => {
    this.setState({ label_inner_packaging_modal: false });
    modalClear();
  };

  onLabelContentsModalClick = (event) => {
    this.setState({ label_contents_modal: true });
  };

  onLabelContentsModalDismiss = (event) => {
    this.setState({ label_contents_modal: false });
    modalClear();
  };

  onGen2LabelModalClick = (label) => {
    this.setState({ gen_2_label_modal: true, selected_gen_2_label: label });
  };

  onGen2LabelModalDismiss = (event) => {
    this.setState({ gen_2_label_modal: false, selected_gen_2_label: '' });
    modalClear();
  };

  restartLabelGeneration = (event) => {
    this.setState({
      return_clicked: true,
    });
    this.props.restartLabelGeneration();
  };

  onDeleteInnerPackagingModalClick = () => {
    this.setState({ delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal });
  };

  onDeleteInnerPackagingModalDismiss = () => {
    this.setState({ delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal });
  };

  onRemoveInnerPackagingButtonClick = () => {
    this.setState({ remove_inner_packaging_in_progress: !this.state.remove_inner_packaging_in_progress });
    this.props.removeInnerPackageFile();
  };

  onDeleteInnerContentsModalClick = () => {
    this.setState({ delete_inner_contents_modal: !this.state.delete_inner_contents_modal });
  };

  onDeleteInnerContentsModalDismiss = () => {
    this.setState({ delete_inner_contents_modal: !this.state.delete_inner_contents_modal });
  };

  onRemoveInnerContentsButtonClick = () => {
    this.setState({ remove_inner_contents_in_progress: !this.state.remove_inner_contents_in_progress });
    this.props.removeInnerContentsFile();
  };

  onUploadInnerPackagingModalClick = () => {
    this.setState({ upload_inner_packaging_modal: !this.state.upload_inner_packaging_modal });
  };

  onUploadContentsModalClick = () => {
    this.setState({ upload_contents_modal: !this.state.upload_contents_modal });
  };

  onUploadInnerPackagingModalDismiss = () => {
    this.setState({ upload_inner_packaging_modal: !this.state.upload_inner_packaging_modal });
    this.props.reloadInformation();
  };

  onUploadContentModalDismiss = () => {
    this.setState({ upload_contents_modal: !this.state.upload_contents_modal });
    this.props.reloadInformation();
  };

  onUploadInnerPackaging = (data) => {
    this.setState({
      inner_packaging_file: data,
    });
    this.props.onUpload(data);
  };

  onUploadContent = (data) => {
    this.setState({
      content_file: data,
    });
    this.props.onUpload(data);
  };

  onDeleteGen2LabelModalClick = (label) => {
    this.setState({ delete_gen_2_label_modal: true, selected_gen_2_label: label });
  };

  onDeleteGen2LabelModalDismiss = () => {
    this.setState({ delete_gen_2_label_modal: false, selected_gen_2_label: '' });
  };

  onDeleteGen2LabelButtonClick = () => {
    this.setState({ remove_gen_2_label_in_progress: true });
    this.props.removeGen2LabelFile(this.state.selected_gen_2_label);
  };

  /**
   * Displays UDI Label Modal
   * @function
   */
  onUdiLabelModalClick = () => {
    this.setState({ udi_label_modal: !this.state.udi_label_modal });
  };

  /**
   * Closes UDI Label Modal
   * @function
   */
  onUdiLabelModalDismiss = (event) => {
    this.setState({ udi_label_modal: false });
    modalClear();
  };

  /**
   * Displays link for gen 2 label
   * @function
   * @param {object} labels - Label const
   * @param {string} label_type - Label type
   * @param {object} label_info - Label information
   * @param {number} index - Index of label
   * @param {boolean} is_wip - Indicator if case went through old label process
   * @returns {JSX} - Label
   */
  displayGen2Label(labels, label_type, label_info, index, is_wip) {
    const label_file = label_info[label_type]['label_file'];
    return (
      <div className={`label-output${is_wip ? ' col-sm-6' : ''}`} key={index}>
        <div className="bold-text center-text">{labels[label_type]}</div>
        {label_info[label_type]['file_exists'] ? (
          <React.Fragment>
            <div className="center-text">
              {label_file.file_type === 'labels_manual'
                ? 'Manually Uploaded'
                : label_file.file_type === 'labels'
                ? 'Generation successful'
                : 'Generation Failed'}
            </div>
            <div className="underline-text viewable-text center-text" onClick={() => this.onGen2LabelModalClick(label_type)}>
              Label, {labels[label_type]} <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </div>
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                  <div className="delete-package" onClick={() => this.onDeleteGen2LabelModalClick(label_type)}>
                    <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                  </div>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>
          </React.Fragment>
        ) : (
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                <React.Fragment>
                  <div>
                    <div className="center-text">
                      {label_file.file_type === 'labels_manual'
                        ? 'Manually Uploaded'
                        : label_file.file_type === 'labels'
                        ? 'Generation successful'
                        : 'Generation Failed'}
                    </div>
                    <div className="case-upload-button">
                      <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={() => this.onGen2ManualUploadClick(label_type)}>
                        Upload
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        )}
      </div>
    );
  }

  /**
   * Displays links for gen 2 labels
   * @function
   * @param {object} label_info - Label information
   * @returns {JSX} - Labels
   */
  displayGen2Labels(label_info) {
    const is_wip = this.isGen2WipCase(label_info);
    let labels = _.cloneDeep(LABELS);

    if (is_wip) {
      delete labels.single_sheet;
    } else {
      delete labels.smartwire;
      delete labels.idb;
      delete labels.bracket;
      delete labels.outer_patient;
    }
    return (
      <>
        {Object.keys(labels).map((label_type, index) => {
          if (!is_wip || this.isLabelSelected(label_type)) {
            return this.displayGen2Label(labels, label_type, label_info, index, is_wip);
          }
          return null;
        })}
      </>
    );
  }

  /**
   * Determines if gen 2 case has gone through old label generation process
   * @function
   * @param {object} gen_2_label_info - Label files
   * @returns {boolean} - True or false
   */
  isGen2WipCase = (gen_2_label_info) => {
    if (this.props.gen_2) {
      const labels = Object.keys(gen_2_label_info).filter((l) => l !== 'single_sheet');
      for (const label of labels) {
        if (gen_2_label_info[label].exists) {
          return true;
        }
      }
    }
    return false;
  };

  isLabelSelected(label_type) {
    let logs = this.props.status_log.filter((log) => {
      return log.status_code === 'Label Generation Process';
    });

    if (logs.length > 0 && logs[logs.length - 1].text) {
      let status_comment = JSON.parse(logs[logs.length - 1].text);
      status_comment = status_comment.manifest;
      return (
        status_comment.filter((label) => {
          return label.includes(label_type);
        }).length > 0
      );
    }
    return false;
  }

  onGen2ManualUploadClick = (label_type) => {
    this.setState({
      gen_2_manual_upload_modal: true,
      selected_gen_2_label: label_type,
    });
  };

  onGen2ManualUploadDismiss = () => {
    this.setState({
      gen_2_manual_upload_modal: false,
      selected_gen_2_label: '',
    });
  };

  onGen2ManualUpload = (data) => {
    switch (this.state.selected_gen_2_label) {
      case 'smartwire':
        this.setState({
          smartwire_label_file: data,
        });
        break;
      case 'idb':
        this.setState({
          idb_label_file: data,
        });
        break;
      case 'bracket':
        this.setState({
          bracket_label_file: data,
        });
        break;
      case 'outer_patient':
        this.setState({
          outer_patient_label_file: data,
        });
        break;
      case 'single_sheet':
        this.setState({ single_sheet_label_file: data });
        break;
      default:
    }
    this.props.onUpload(data);
  };

  getGen2LabelFile(label) {
    const label_files = {
      smartwire: this.state.smartwire_label_file,
      idb: this.state.idb_label_file,
      bracket: this.state.bracket_label_file,
      outer_patient: this.state.outer_patient_label_file,
      single_sheet: this.state.single_sheet_label_file,
    };
    let label_file_exists = false;
    let label_file = label_files[label];

    if (
      this.props.files.filter(function (a) {
        return a.original_filename.includes(LABEL_NAMES[label]);
      })[0]
    ) {
      label_file_exists = true;
      label_file =
        label_file.length > 0
          ? label_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes(LABEL_NAMES[label]);
            })[0];
    }
    return [label_file_exists, label_file];
  }

  getGen2LabelInfo() {
    const smartwire_label_info = this.getGen2LabelFile('smartwire');
    const smartwire_label_file_exists = smartwire_label_info[0];
    const smartwire_label_file = smartwire_label_info[1];

    const idb_label_info = this.getGen2LabelFile('idb');
    const idb_label_file_exists = idb_label_info[0];
    const idb_label_file = idb_label_info[1];

    const bracket_label_info = this.getGen2LabelFile('bracket');
    const bracket_label_file_exists = bracket_label_info[0];
    const bracket_label_file = bracket_label_info[1];

    const outer_patient_label_info = this.getGen2LabelFile('outer_patient');
    const outer_patient_label_file_exists = outer_patient_label_info[0];
    const outer_patient_label_file = outer_patient_label_info[1];

    const single_sheet_label_info = this.getGen2LabelFile('single_sheet');
    const single_sheet_label_file_exists = single_sheet_label_info[0];
    const single_sheet_label_file = single_sheet_label_info[1];

    return {
      smartwire: {
        file_exists: smartwire_label_file_exists,
        label_file: smartwire_label_file,
      },
      idb: {
        file_exists: idb_label_file_exists,
        label_file: idb_label_file,
      },
      bracket: {
        file_exists: bracket_label_file_exists,
        label_file: bracket_label_file,
      },
      outer_patient: {
        file_exists: outer_patient_label_file_exists,
        label_file: outer_patient_label_file,
      },
      single_sheet: {
        file_exists: single_sheet_label_file_exists,
        label_file: single_sheet_label_file,
      },
    };
  }

  gen2LabelFile(gen_2_label_info) {
    return this.state.selected_gen_2_label === 'smartwire'
      ? gen_2_label_info.smartwire.label_file
      : this.state.selected_gen_2_label === 'idb'
      ? gen_2_label_info.idb.label_file
      : this.state.selected_gen_2_label === 'bracket'
      ? gen_2_label_info.bracket.label_file
      : this.state.selected_gen_2_label === 'single_sheet'
      ? gen_2_label_info.single_sheet.label_file
      : gen_2_label_info.outer_patient.label_file;
  }

  render() {
    const label_inner_packaging_file = this.props.getLabelFilePath('inner', this.props.files);
    const label_contents_file = this.props.getLabelFilePath('contents', this.props.files);
    let udi_label_file = this.props.getLabelFilePath('udi', this.props.files);

    let inner_packaging_file_exists = false;
    let inner_packaging_file = this.state.inner_packaging_file;
    if (
      this.props.files.filter(function (a) {
        return a.original_filename.includes('inner_packaging');
      })[0]
    ) {
      inner_packaging_file_exists = true;
      inner_packaging_file =
        inner_packaging_file.length > 0
          ? inner_packaging_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes('inner_packaging');
            })[0];
    }
    let contents_file_exists = false;
    let content_file = this.state.content_file;
    if (
      this.props.files.filter(function (a) {
        return a.original_filename.includes('contents');
      })[0]
    ) {
      contents_file_exists = true;
      content_file =
        content_file.length > 0
          ? content_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes('contents');
            })[0];
    }

    let udi_file_exists = false;
    let udi_file = this.state.udi_file;
    if (
      this.props.files.filter(function (a) {
        return a.original_filename.includes('udi_label');
      })[0]
    ) {
      udi_file_exists = true;
      udi_file =
        udi_file.length > 0
          ? udi_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes('udi_label');
            })[0];
    }

    const gen_2_label_info = this.getGen2LabelInfo();
    const is_gen_2_wip_case = this.isGen2WipCase(gen_2_label_info);

    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="process-box-title">Label Generation Failure</div>
          <div>Label Generation failed for the following reason:</div>
          <div className="process-failure">{this.props.status_comment ? this.props.status_comment : 'N/A'}</div>
          <div className="process-retry">
            Manually upload or click <strong>Retry</strong> to retry label generation
          </div>
          <div className="row">
            {this.props.gen_2 ? (
              <>{this.displayGen2Labels(gen_2_label_info)}</>
            ) : (
              <>
                <div className={this.props.case_id.indexOf('-') === -1 ? 'col-sm-6' : ''}>
                  <div className="bold-text center-text">Inner Packaging</div>
                  {inner_packaging_file_exists ? (
                    <React.Fragment>
                      <div className="center-text">
                        {inner_packaging_file.file_type === 'labels_manual'
                          ? 'Manually Uploaded'
                          : inner_packaging_file.file_type === 'labels'
                          ? 'Generation successful'
                          : 'Generation Failed'}
                      </div>
                      <div className="underline-text viewable-text center-text" onClick={this.onInnerPackagingModalClick}>
                        Label, Inner Packaging <i className="fa fa-file-pdf-o" aria-hidden="true" />
                      </div>
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                            <div className="delete-package" onClick={this.onDeleteInnerPackagingModalClick}>
                              <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                            </div>
                          ) : null;
                        }}
                      </UserPermissionsContext.Consumer>
                    </React.Fragment>
                  ) : (
                    <UserPermissionsContext.Consumer>
                      {(user_roles_and_permissions) => {
                        return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                          <React.Fragment>
                            <div>
                              <div className="center-text">
                                {inner_packaging_file.file_type === 'labels_manual'
                                  ? 'Manually Uploaded'
                                  : inner_packaging_file.file_type === 'labels'
                                  ? 'Generation successful'
                                  : 'Generation Failed'}
                              </div>
                              <div className="case-upload-button">
                                <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadInnerPackagingModalClick}>
                                  Upload
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null;
                      }}
                    </UserPermissionsContext.Consumer>
                  )}
                </div>
                {this.props.case_id.indexOf('-') === -1 ? (
                  <div className="col-sm-6">
                    <div className="bold-text center-text">Contents</div>
                    {contents_file_exists ? (
                      <React.Fragment>
                        <div className="center-text">
                          {content_file.file_type === 'labels_manual'
                            ? 'Manually Uploaded'
                            : content_file.file_type === 'labels'
                            ? 'Generation successful'
                            : 'Generation Failed'}
                        </div>
                        <div className="underline-text viewable-text center-text" onClick={this.onLabelContentsModalClick}>
                          Label, Contents <i className="fa fa-file-pdf-o" aria-hidden="true" />
                        </div>
                        <UserPermissionsContext.Consumer>
                          {(user_roles_and_permissions) => {
                            return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                              <div className="delete-package" onClick={this.onDeleteInnerContentsModalClick}>
                                <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                              </div>
                            ) : null;
                          }}
                        </UserPermissionsContext.Consumer>
                      </React.Fragment>
                    ) : (
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                            <React.Fragment>
                              <div className="center-text">
                                {content_file.file_type === 'labels_manual'
                                  ? 'Manually Uploaded'
                                  : content_file.file_type === 'labels'
                                  ? 'Generation successful'
                                  : 'Generation Failed'}
                              </div>
                              <div className="case-upload-button">
                                <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadContentsModalClick}>
                                  Upload
                                </button>
                              </div>
                            </React.Fragment>
                          ) : null;
                        }}
                      </UserPermissionsContext.Consumer>
                    )}
                  </div>
                ) : null}
              </>
            )}
            {!this.props.gen_2 || is_gen_2_wip_case ? (
              <div className={`col-sm-6 ${!this.props.gen_2 ? ' margin-top-10' : ''}`}>
                <div className="bold-text center-text">UDI Label</div>
                {!this.isLabelSelected('udi') ? (
                  <div className="center-text">{'Not generated'}</div>
                ) : udi_file_exists ? (
                  <React.Fragment>
                    <div className="center-text">
                      {udi_file.file_type === 'labels_manual'
                        ? 'Manually Uploaded'
                        : udi_file.file_type === 'labels'
                        ? 'Generation successful'
                        : 'Generation Failed'}
                    </div>
                    <div className="underline-text viewable-text center-text" onClick={this.onUdiLabelModalClick}>
                      UDI Label <i className="fa fa-file-pdf-o" aria-hidden="true" />
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="center-text">{'Generation failed'}</div>
                )}
              </div>
            ) : null}
          </div>
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
                <button type="button" className="btn btn-light label-failed-retry" onClick={this.props.restartLabelGeneration}>
                  Retry
                </button>
              ) : (
                <button type="button" className="btn btn-light label-failed-retry" disabled={true}>
                  Retry
                </button>
              );
            }}
          </UserPermissionsContext.Consumer>
        </div>
        {this.state.label_inner_packaging_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Label Inner Packaging - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={label_inner_packaging_file.upload_data}
            pdf_date={label_inner_packaging_file?.created_date}
            onCloseButtonClick={this.onInnerPackagingModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Inner_Packaging${inner_packaging_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.label_contents_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Label Contents - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={label_contents_file.upload_data}
            pdf_date={label_contents_file?.created_date}
            onCloseButtonClick={this.onLabelContentsModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Contents${content_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.delete_inner_packaging_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Inner Packaging Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            message_text={'Are you sure you want to remove the Inner Packaging Label?'}
            in_progress={this.state.remove_inner_packaging_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveInnerPackagingButtonClick}
            onCloseButtonClick={this.onDeleteInnerPackagingModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.delete_inner_contents_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Inner Contents Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            message_text={'Are you sure you want to remove the Inner Contents Label?'}
            in_progress={this.state.remove_inner_contents_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveInnerContentsButtonClick}
            onCloseButtonClick={this.onRemoveInnerContentsButtonClick}
            theme="bpp"
          />
        ) : null}
        {this.state.upload_inner_packaging_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload Inner Packaging Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder="inner_package_label"
            type="additional"
            onUpload={this.onUploadInnerPackaging}
            upload_content={inner_packaging_file}
            onCloseButtonClick={this.onUploadInnerPackagingModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
          />
        ) : null}

        {this.state.upload_contents_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload Contents Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder="contents_labels"
            type="additional"
            onUpload={this.onUploadContent}
            upload_content={content_file}
            onCloseButtonClick={this.onUploadContentModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
          />
        ) : null}
        {this.state.udi_label_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`UDI Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={udi_label_file.upload_data}
            pdf_date={udi_label_file?.created_date}
            onCloseButtonClick={this.onUdiLabelModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, 'Label_UDI.pdf')}
          />
        ) : null}

        {this.state.gen_2_manual_upload_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={
              `Upload ${this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label]} Label - ` +
              removeCaseIdInitialNumber(this.props.case_id)
            }
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder={LABEL_NAMES[this.state.selected_gen_2_label]}
            type="additional"
            onUpload={this.onGen2ManualUpload}
            upload_content={
              this.state.selected_gen_2_label === 'smartwire'
                ? gen_2_label_info.smartwire.label_file
                : this.state.selected_gen_2_label === 'idb'
                ? gen_2_label_info.idb.label_file
                : this.state.selected_gen_2_label === 'bracket'
                ? gen_2_label_info.bracket.label_file
                : gen_2_label_info.outer_patient.label_file
            }
            onCloseButtonClick={this.onGen2ManualUploadDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              ` ${
                this.state.selected_gen_2_label === 'smartwire'
                  ? 'Label_Smartwire_Manual.pdf'
                  : this.state.selected_gen_2_label === 'idb'
                  ? 'Label_IDB_Manual.pdf'
                  : this.state.selected_gen_2_label === 'bracket'
                  ? 'Label_Brackets_Manual.pdf'
                  : this.state.selected_gen_2_label === 'single_sheet'
                  ? 'Label_Single_Sheet_Manual.pdf'
                  : 'Label_Outer_Patient_Manual.pdf'
              }`
            )}
          />
        ) : null}

        {this.state.gen_2_label_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label]
            } Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={this.gen2LabelFile(gen_2_label_info).upload_data}
            pdf_date={this.gen2LabelFile(gen_2_label_info)?.created_date}
            onCloseButtonClick={this.onGen2LabelModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              ` ${
                this.state.selected_gen_2_label === 'smartwire'
                  ? `Label_Smartwire${this.getGen2LabelFile('smartwire')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                  : this.state.selected_gen_2_label === 'idb'
                  ? `Label_IDB${this.getGen2LabelFile('idb')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                  : this.state.selected_gen_2_label === 'bracket'
                  ? `Label_Brackets${this.getGen2LabelFile('bracket')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                  : this.state.selected_gen_2_label === 'single_sheet'
                  ? `Label_Single_Sheet${this.getGen2LabelFile('single_sheet')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
                  : `Label_Outer_Patient${this.getGen2LabelFile('outer_patient')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
              }`
            )}
          />
        ) : null}

        {this.state.delete_gen_2_label_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={`Remove ${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label]
            } Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            message_text={`Are you sure you want to remove the ${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label]
            } Label?`}
            in_progress={this.state.remove_gen_2_label_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onDeleteGen2LabelButtonClick}
            onCloseButtonClick={this.onDeleteGen2LabelModalDismiss}
            theme="bpp"
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default LabelFailure;
