/**
 * Overrides IDB part selection
 * @param {Object} request_reason
 * @param {Object} item_part_quantity
 * @returns {List} new item part list
 */
export const assignIdbPartNumbers = (request_reason, item_part_quantity, case_id) => {
  const idb_reason_id = 799;
  const lower_single_tooth_idb_part_id = '80026';
  const upper_single_tooth_idb_part_id = '80025';
  const upper_arch_segment_idb_part_id = '80008';
  const lower_arch_segment_idb_part_id = '80007';
  const de_upper_arch_segment_idb_part_id = '80016';
  const de_lower_arch_segment_idb_part_id = '80015';
  const part_list = clearIdbItemParts(item_part_quantity);
  const idb_reason = request_reason.find((reason) => reason.id === idb_reason_id);
  const is_de = case_id.includes('-DE');

  if (hasSingleToothSelection(idb_reason)) {
    const single_teeth = idb_reason.selectedTeeth;
    for (let tooth of single_teeth) {
      if (tooth.includes('LL') || tooth.includes('LR')) {
        const part = part_list.find((item) => item.part_id === lower_single_tooth_idb_part_id);
        part.quantity += 1;
      }
      if (tooth.includes('UL') || tooth.includes('UR')) {
        const part = part_list.find((item) => item.part_id === upper_single_tooth_idb_part_id);
        part.quantity += 1;
      }
    }
  }
  if (hasArchSegmentSelection(idb_reason)) {
    const arches = idb_reason.arch_segments_options;
    for (let arch of arches) {
      if (arch.title.includes('Upper') && arch.active) {
        const part_id = is_de ? de_upper_arch_segment_idb_part_id : upper_arch_segment_idb_part_id;
        const part = part_list.find((item) => item.part_id === part_id);
        part.quantity = 1;
      }
      if (arch.title.includes('Lower') && arch.active) {
        const part_id = is_de ? de_lower_arch_segment_idb_part_id : lower_arch_segment_idb_part_id;
        const part = part_list.find((item) => item.part_id === part_id);
        part.quantity = 1;
      }
    }
  }
  return part_list;
};

/**
 * Checks if IR has Arch Segment selections
 * @param {Object} request_reason
 * @returns {boolean} true if has IDB reason has Arch Segment Selection
 */
export const hasArchSegmentSelection = (reason) => {
  return reason.category === 'idb_required' && (reason.arch_segment_selection || reason.arch_segments_options.some((option) => option.active));
};

/**
 * Checks if IR has Single Tooth selections
 * @param {Object} request_reason
 * @returns {boolean} true if has IDB reason has Single Tooth Selection
 */
export const hasSingleToothSelection = (reason) => {
  return reason.category === 'idb_required' && (reason.single_tooth_selection || reason.selectedTeeth.length > 0);
};

/**
 * Checks which IDB parts are in state and clears them for Gen 2 IRs
 * @param {Object} request_reason
 * @param {Object} item_part_quantity
 */
export const clearIdbItemParts = (part_list) => {
  for (let part of part_list) {
    if (['80007', '80008', '80025', '80026', '80015', '80016'].includes(part.part_id) && part.quantity > 0) {
      part.quantity = 0;
    }
  }
  return part_list;
};
