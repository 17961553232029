import { CLOSE_HELP_SIDEBAR, OPEN_HELP_SIDEBAR, TOGGLE_HELP_SIDEBAR } from '../../actions/ipp/help_sidebar';

const initialState = {
  show_help_sidebar: false,
};

export function helpSidebarReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_HELP_SIDEBAR:
      return {
        ...state,
        show_help_sidebar: false,
      };

    case OPEN_HELP_SIDEBAR:
      return {
        ...state,
        show_help_sidebar: true,
      };

    case TOGGLE_HELP_SIDEBAR:
      return {
        ...state,
        show_help_sidebar: !state.show_help_sidebar,
      };

    default:
      return state;
  }
}

export const getShowHelpSidebar = (state) => state.helpSidebarReducer.show_help_sidebar;
