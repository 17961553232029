import {
  FETCH_AWB_SUCCESS,
  OPEN_AWB_MISSING_TEETH_MEASUREMENTS_MODAL,
  EDIT_AWB_MISSING_TEETH_MEASUREMENTS_MODAL,
  API_CALL_PENDING,
  API_CALL_SUCCESS,
  API_CALL_ERROR,
  TOGGLE_MODAL,
  SET_SPACING,
  SET_SELECTION,
  SET_MEASUREMENTS_ERROR_TRUE,
  SET_MEASUREMENTS_ERROR_FALSE,
} from '../actions/awb';

const initialState = {
  pending: false,
  awb: {},
  selections: [],
  error: null,
  convert_modal: false,
  recipe_file_modal: false,
  measurement_file_modal: false,
  mark_success_modal: false,
  mark_failed_modal: false,
  reject_modal: false,
  transfer_modal: false,
  retry_modal: false,
  override_modal: false,
  manual_modal: false,
  manual_convert_modal: false,
  manual_reject_modal: false,
  manual_transfer_modal: false,
  manual_mark_success_modal: false,
  manual_mark_failed_modal: false,
  manual_retry_modal: false,
  rework_modal: false,
  manual_rework_modal: false,
  automated_process_modal: false,
  missing_teeth_measurements_modal: false,
  awb_missing_teeth_measurements_modal_selected_teeth: [],
  awb_missing_teeth_measurements_modal_heading: '',
  measurement_conditions: {
    spacing: {
      spacingLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      spacingUpper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      straightLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
    },
  },
  measurements_error: false,
};

export function awbReducer(state = initialState, action) {
  switch (action.type) {
    case API_CALL_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_AWB_SUCCESS:
      return {
        ...state,
        pending: false,
        awb: action.awb,
        selections: action.selections,
      };

    case API_CALL_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case API_CALL_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        [`${action.modal_name}`]: action.modal_value,
      };

    case OPEN_AWB_MISSING_TEETH_MEASUREMENTS_MODAL:
      const measurements = {
        spacing: {
          spacingLower: action.awb_missing_teeth_measurements_modal_lower_measurements,
          spacingUpper: action.awb_missing_teeth_measurements_modal_upper_measurements,
          straightLower: action.awb_missing_teeth_measurements_modal_lower_straight_measurements,
        },
      };
      return {
        ...state,
        missing_teeth_measurements_modal: true,
        awb_missing_teeth_measurements_modal_selected_teeth: action.awb_missing_teeth_measurements_modal_selected_teeth,
        awb_missing_teeth_measurements_modal_heading: action.awb_missing_teeth_measurements_modal_heading,
        measurement_conditions: measurements,
      };

    case EDIT_AWB_MISSING_TEETH_MEASUREMENTS_MODAL:
      return {
        ...state,
        awb_missing_teeth_measurements_modal_selected_teeth: action.awb_missing_teeth_measurements_modal_selected_teeth,
      };

    case SET_SPACING:
      return {
        ...state,
        measurement_conditions: action.measurement_conditions,
      };

    case SET_SELECTION:
      return {
        ...state,
        selections: action.selections,
      };

    case SET_MEASUREMENTS_ERROR_TRUE:
      return {
        ...state,
        measurements_error: true,
      };
    case SET_MEASUREMENTS_ERROR_FALSE:
      return {
        ...state,
        measurements_error: false,
      };

    default:
      return state;
  }
}

export const getAwb = (state) => state.awbReducer.awb;
export const getAwbPending = (state) => state.awbReducer.pending;
export const getAwbError = (state) => state.awbReducer.error;
export const getAwbConvertModal = (state) => state.awbReducer.convert_modal;
export const getAwbRecipeFileModal = (state) => state.awbReducer.recipe_file_modal;
export const getAwbMeasurementFileModal = (state) => state.awbReducer.measurement_file_modal;
export const getAwbMarkSuccessModal = (state) => state.awbReducer.mark_success_modal;
export const getAwbMarkFailedModal = (state) => state.awbReducer.mark_failed_modal;
export const getAwbRejectModal = (state) => state.awbReducer.reject_modal;
export const getAwbTransferModal = (state) => state.awbReducer.transfer_modal;
export const getAwbAutomatedProcessModal = (state) => state.awbReducer.automated_process_modal;
export const getAwbRetryModal = (state) => state.awbReducer.retry_modal;
export const getAwbOverrideModal = (state) => state.awbReducer.override_modal;
export const getAwbManualModal = (state) => state.awbReducer.manual_modal;
export const getAwbManualConvertModal = (state) => state.awbReducer.manual_convert_modal;
export const getAwbManualRejectModal = (state) => state.awbReducer.manual_reject_modal;
export const getAwbManualTransferModal = (state) => state.awbReducer.manual_transfer_modal;
export const getAwbManualMarkSuccessModal = (state) => state.awbReducer.manual_mark_success_modal;
export const getAwbManualMarkFailedModal = (state) => state.awbReducer.manual_mark_failed_modal;
export const getAwbManualRetryModal = (state) => state.awbReducer.manual_retry_modal;
export const getAwbMissingTeethMeasurementsModal = (state) => state.awbReducer.missing_teeth_measurements_modal;
export const getAwbMissingTeethMeasurementsModalHeading = (state) => state.awbReducer.awb_missing_teeth_measurements_modal_heading;
export const getAwbMissingTeethMeasurementsModalSelectedTeeth = (state) => state.awbReducer.awb_missing_teeth_measurements_modal_selected_teeth;
export const getMeasurementConditions = (state) => state.awbReducer.measurement_conditions;
export const getAwbReworkModal = (state) => state.awbReducer.rework_modal;
export const getAwbManualReworkModal = (state) => state.awbReducer.manual_rework_modal;
export const getAwbSelections = (state) => state.awbReducer.selections;
export const getMeasurementsError = (state) => state.awbReducer.measurements_error;
