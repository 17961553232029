import React from 'react';

/**
 * Displays the split screen button and its interaction
 * @component
 * @alias SetupViewerCompareBtn
 */
export default function CompareBtn(props) {
  return (
    <div className="compareBtnContainer">
      <button className={props.split ? 'compare_active ' : 'compare'} onClick={props.toggleSplit}>
        Compare
      </button>
    </div>
  );
}
