import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { drawProfilePicture } from '../../common/functions';
import { connect } from 'react-redux';
import { getProfileImage, getProfileMetadata } from '../../redux/reducers/ipp/account_settings';
import { bindActionCreators } from 'redux';
import { fetchProfilePicture } from '../../redux/actions/ipp/account_settings';
import { getDoctorInfo } from '../../redux/reducers/ipp/dashboard';

class DoctorProfilePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_picture_width_in_px: 100,
    };
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const { profile_image, profile_metadata } = this.props;
    if (profile_image && profile_metadata) drawProfilePicture(this);
  }

  componentDidUpdate(prevProps) {
    const hasProfileChanged = (prevProps.profile_image !== this.props.profile_image ||
                                prevProps.profile_metadata !== this.props.profile_metadata);
    const hasProfileImage = this.props.profile_image && this.props.profile_metadata;

    if (hasProfileChanged && hasProfileImage) drawProfilePicture(this);
  }

  render() {
    const { first_name, last_name } = this.props.doctor_info;

    return (
      <div className="profile-image">
        {this.props.profile_image ? (
          <canvas width={this.state.profile_picture_width_in_px} height={this.state.profile_picture_width_in_px} ref={this.canvasRef} />
        ) : (
          <div className="account__initials">{first_name[0]}{last_name[0]}</div>
        )}
      </div>
    );
  }
}

DoctorProfilePhoto.propTypes = {
  doctor_info: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    doctor_info: getDoctorInfo(state),
    profile_image: getProfileImage(state),
    profile_metadata: getProfileMetadata(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProfilePicture: fetchProfilePicture,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfilePhoto);
