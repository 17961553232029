import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './revert_case.scss';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { Redirect } from 'react-router-dom';
import Axios from 'axios';
import { ApiServiceInstance } from '../../common/api_service';
import SimpleModal from '../../doctor/components/modals/simple-modal';

const RevertCase = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputIreqValue, setInputIreqValue] = useState('');
  const [currentStatus, setCurrentStatus] = useState('');
  const [caseId, setCaseId] = useState('');
  const [ireqId, setIreqId] = useState('');
  const [ireqIrreversibleState, setIreqIrreversibleState] = useState(false);
  const [tracking, setTracking] = useState('');
  const [reversibleStates, setReversibleStates] = useState({});
  const [ireqReversibleStates, setIreqReversibleStates] = useState({});
  const [ireqDisplayTrackingState, setIreqDisplayTrackingState] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const resetInfo = () => {
    setCaseId('');
    setCurrentStatus('');
  };

  const setKeyInfo = (case_details) => {
    const current_status = case_details.case_statuses.find((status) => status.current_ind);
    setCurrentStatus(current_status.case_status);
    setCaseId(case_details.case_id);
  };

  const setIReqKeyInfo = (ireq_id) => {
    setIreqId(ireq_id);
  };

  const setIReqStatus = (status) => {
    if (
      (!status.has_target_ship_date && !status.has_tracking_number && !status.has_ship_info_log && !status.already_delivered) ||
      (status.has_target_ship_date && status.has_tracking_number && status.has_ship_info_log && status.already_delivered)
    ) {
      setIreqIrreversibleState(true);
    }
  };

  const lookupCase = (e) => {
    e.preventDefault();
    setCaseDetails(inputValue);
    Axios.get(`/apiv3/case/${inputValue}/reversal`)
      .then((res) => {
        setReversibleStates(res.data);
      })
      .catch((error) => {
        resetInfo();
        console.log('error: ', error);
        if (error.response.status === 404) {
          setModalMessage('Case not found');
          setShowModal(true);
        }
      });

    setInputValue('');
  };

  const lookupIreq = (e) => {
    e.preventDefault();
    Axios.get(`/apiv3/ireq/${inputIreqValue}`)
      .then((res) => {
        resetInfo();
        setIreqIrreversibleState(false);
        const dataObject = res.data;
        setIReqKeyInfo(inputIreqValue, dataObject);
        setIReqStatus(dataObject);
        setIreqReversibleStates(res.data);
        setIreqDisplayTrackingState(true);
      })
      .catch((error) => {
        resetInfo();
        console.log('error: ', error);
        if (error.response.status === 404) {
          setModalMessage('Ireq not found');
          setShowModal(true);
        }
      });
  };

  const setCaseDetails = (caseId) => {
    ApiServiceInstance.fetchCaseDetails(caseId)
      .then((res) => {
        resetInfo();
        const case_details = res.data.cases.find((info) => info.case_id === caseId);
        setKeyInfo(case_details);
        setInputValue('');
      })
      .catch((error) => {
        resetInfo();
        console.log('error: ', error);
      });
  };

  const revertToQcReview = () => {
    Axios.post(`/apiv3/case/${caseId}/reversal/manufacturing_to_qc`)
      .then((res) => {
        setModalMessage('Case Reverted to QC Review');
        setShowModal(true);
        resetInfo();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const cancelWireFabReview = () => {
    Axios.post(`/apiv3/case/${caseId}/reversal/cancel_wfr`)
      .then((res) => {
        setModalMessage('WFR Canceled');
        setShowModal(true);
        resetInfo();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const doctorReviewToSetupRelease = () => {
    Axios.post(`/apiv3/case/${caseId}/reversal/doctor_review_to_setup_release`)
      .then((res) => {
        setModalMessage('Case Reverted to Setup Release');
        setShowModal(true);
        resetInfo();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  const applianceDesignToDrApprove = (e) => {
    e.preventDefault();
    setCaseDetails(inputValue);
    Axios.post(`/apiv3/case/${caseId}/reversal/appliance_design_to_dr_approve`)
      .then((res) => {
        setModalMessage('Case Reverted to Doctor Approve or Revise Setup');
        setShowModal(true);
        resetInfo();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };
  const updateIreqTracking = (e) => {
    e.preventDefault();
    setIReqKeyInfo(inputIreqValue);
    Axios.post(`/apiv3/ireq/${ireqId}/update_tracking/${tracking}`)
      .then((res) => {
        setModalMessage('Tracking number updated for ireq!');
        setShowModal(true);
        setIreqIrreversibleState(false);
        setIreqDisplayTrackingState(false);
        setIreqId();
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  };

  return (
    <>
      <Helmet>
        <title>Revert Case</title>
      </Helmet>
      <UserPermissionsContext.Consumer>
        {(userRolesAndPermissions) => {
          return userHasPermission('CASE_REVERSAL', userRolesAndPermissions.permissions) ? (
            <div className="case-reversal main-content">
              <h1>Revert Case</h1>
              <h3>Case</h3>
              <form onSubmit={lookupCase}>
                <input
                  type="text"
                  className="form-control search-bar-dark font-awesome reversal-input"
                  placeholder="Enter Case ID"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value.trim())}
                />
                <button type="submit" className="btn btn-light-3">
                  Look Up
                </button>
              </form>
              <div className="case-reversal__details">
                {caseId && <p>Case ID: {caseId}</p>}
                {currentStatus && <p>Current Status: {currentStatus}</p>}
              </div>
              <div className="revert-button">
                {currentStatus && reversibleStates.manufacturing && (
                  <button className="btn btn-light-3" onClick={revertToQcReview}>
                    Revert to QC Review
                  </button>
                )}
                {currentStatus && reversibleStates.wfr && (
                  <button className="btn btn-light-3" onClick={cancelWireFabReview}>
                    Cancel WFR
                  </button>
                )}
                {currentStatus && reversibleStates.doctor_review && (
                  <button className="btn btn-light-3" onClick={doctorReviewToSetupRelease}>
                    Revert to Setup Release
                  </button>
                )}
                {currentStatus && reversibleStates.has_cad_status && (
                  <button className="btn btn-light-3" onClick={applianceDesignToDrApprove}>
                    Revert to Doctor Approve or Revise Setup
                  </button>
                )}
              </div>
              <hr></hr>
              <div className="revert-ireq">
                <h3>Irequest</h3>
                <form onSubmit={lookupIreq}>
                  <input
                    type="text"
                    className="form-control search-bar-dark font-awesome reversal-input"
                    placeholder="Enter Ireq ID"
                    value={inputIreqValue}
                    onChange={(e) => setInputIreqValue(e.target.value.trim())}
                  />
                  <button type="submit" className="btn btn-light-3">
                    Look Up
                  </button>
                </form>
                <div className="case-reversal__ireq-details">
                  {ireqId && <p>Irequest ID: {ireqId}</p>}
                  {ireqIrreversibleState && <p>This Item Request cannot be reverted </p>}
                  {ireqDisplayTrackingState && ireqReversibleStates.has_ship_info_log && !ireqReversibleStates.already_delivered && (
                    <form onSubmit={updateIreqTracking}>
                      <input
                        type="text"
                        className="form-control font-awesome reversal-input"
                        placeholder="New Tracking"
                        value={tracking}
                        onChange={(e) => setTracking(e.target.value.trim())}
                      />
                      <button type="submit" className="btn btn-light-3">
                        Update IReq Tracking
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Redirect push to="/" />
          );
        }}
      </UserPermissionsContext.Consumer>

      <SimpleModal show={showModal} containerclassName="smile-design-modal" theme="error">
        <SimpleModal.Header title={'REVERT CASE RESULT'} onClose={() => setShowModal(false)} />

        <SimpleModal.ContentBody>
          <div style={{ padding: '16px' }}>{modalMessage}</div>
        </SimpleModal.ContentBody>
        <SimpleModal.FooterWithButtons
          onClickConfirm={() => setShowModal(false)}
          confirmButtonText={'OK'}
          showCancel={false}
          confirmButtonClassName={'btn-light-3'}
        />
      </SimpleModal>
    </>
  );
};

export default RevertCase;
