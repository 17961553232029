import React, { Component } from 'react';

import _ from 'lodash';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';

import { convertDate, convertSex, removeCaseIdInitialNumber } from '../../common/functions';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class DePlan extends Component {
  downloadpdf = e => {
    const el = document.getElementById('plandownload');
    let filename = 'TX-Case-' + removeCaseIdInitialNumber(this.props.caseId) + '.pdf';

    if (this.props.caseId.indexOf('-R') >= 0 || this.props.caseId.indexOf('-DE') >= 0) {
      filename = filename.replace('TX-Case-', 'DE-Case-');
    }

    var opt = {
      filename: filename,
      margin: [3, 3, 3, 3],
      pagebreak: { mode: ['css', 'legacy'] }
    };
    html2pdf()
      .set(opt)
      .from(el)
      .toPdf()
      .save();
  };

  printpdf = e => {
    const element = document.getElementById('plandownload');
    var opt = {
      margin: [3, 3, 3, 3],
      pagebreak: { mode: ['css', 'legacy'] }
    };

    var img = html2pdf()
      .set(opt)
      .from(element)
      .toImg();
    img.then(
      accept => {
        var isFirefox = typeof InstallTrigger !== 'undefined';

        if (isFirefox === true) {
          var canvas = img.prop.canvas;
          const imgData = canvas.toDataURL('image/png');
          var win1 = window.open();

          if (win1 !== null) {
            win1.document.write("<img style='display:block;' src = '" + imgData + "'/>");
            win1.print();
          }
        } else {
          var c = img.prop.img.src;

          printJS(c, 'image');
        }
      },
      reject => {}
    );
  };

  toothSelection(selection, movement) {
    let ur = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'];
    let lr = ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'];
    let ul = ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'];
    let ll = ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'];
    let allTooth = [ur, lr, ul, ll];
    let result = [];
    let temp = '';
    let i = 0;

    //Generate class for displaying
    if (selection === 'UR') {
      i = 0;
    } else if (selection === 'LR') {
      i = 1;
    } else if (selection === 'UL') {
      i = 2;
    } else if (selection === 'LL') {
      i = 3;
    }

    if (movement) {
      movement = movement.split(',').sort();
    }

    for (let j = 0; j < allTooth[i].length; j++) {
      temp = _.intersection([allTooth[i][j]], movement);
      if (temp.length > 0) {
        result.push({
          tooth: allTooth[i][j],
          class: 'selected'
        });
      } else {
        result.push({
          tooth: allTooth[i][j],
          class: ''
        });
      }
    }

    if (movement) {
      //Finish off format
      let selected = false;
      let selectedCnt = 0;

      for (let k = 0; k < result.length; k++) {
        if (k === result.length - 1 && result[k].class) {
          if (result[k].class && selectedCnt === 0) {
            result[k].class = 'full-circle';
          } else if (result[k].class) {
            result[k].class = 'right-circle';
          }
        } else if (selected === true && result[k].class) {
          result[k].class = 'middle-circle';
          selectedCnt++;
        } else if (selected === true && result[k].class === '') {
          if (selectedCnt === 1) {
            result[k - 1].class = 'full-circle';
          } else {
            result[k - 1].class = 'right-circle';
          }
          selected = false;
          selectedCnt = 0;
        } else if (selected === false && result[k].class) {
          result[k].class = 'left-circle';
          selected = true;
          selectedCnt++;
        }
      }
    }

    return (
      <div>
        {result.map((tooth, tooth_ind) => {
          return (
            <span key={tooth_ind} className={'txplan-tooth ' + tooth.class}>
              {tooth.tooth}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {user_roles_and_permissions => {
          return (
            <div>
              {this.props.bpp && userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                <div>
                  <span onClick={this.printpdf} name="print" type="button" className="mount-corner" data-toggle="tooltip" data-placement="top" title="Print">
                    <i className="fa fa-print" aria-hidden="true" />
                  </span>
                  <span
                    onClick={this.downloadpdf}
                    name="download"
                    type="button"
                    className="mount-corner-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download"
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </span>
                </div>
              ) : null}
              <div id="plandownload" className="plan-height">
                <div className={this.props.bpp ? 'txplan-heading-dark' : 'txplan-heading'}>InBrace Digital Enhancement Plan</div>
                <table className="txplan-table">
                  <tbody>
                    <tr>
                      <td className="txplan-border-bottom" colSpan="4">
                        <span className="bold-text">Doctor:</span> {this.props.doctorfirstName} {this.props.doctorlastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="txplan-border-bottom-right txplan-width-case">
                        <span className="bold-text">Case ID:</span> {removeCaseIdInitialNumber(this.props.caseId)}
                      </td>
                      <td className="txplan-border-bottom-right">
                        <span className="bold-text">Patient:</span> {this.props.selectedPatient.first_name + ' ' + this.props.selectedPatient.last_name}
                      </td>
                      <td className="txplan-border-bottom-right">
                        <span className="bold-text">Sex:</span>{' '}
                        {convertSex(this.props.selectedPatient.sex)}
                      </td>
                      <td className="txplan-border-bottom">
                        <span className="bold-text">DOB:</span> {convertDate(this.props.selectedPatient.dob)}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.props.is_historical && this.props.treatment_plan_revision === 'TX0.0' ? null : (
                  <div>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom txplan-half">
                            <div>
                              <span className="bold-text">Intraoral Scan Date:</span>{' '}
                              {this.props.caseData.scanDate ? convertDate(this.props.caseData.scanDate) : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">Reason for Digital Enhancement:</span>{' '}
                              {this.props.caseData.reason
                                ? this.props.caseData.reason
                                    .replace('(specify in item 7, where and what refinement is needed)', '')
                                    .replace('(specify in item 7, where and what digital enhancement is needed)', '')
                                : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">Treatment Plan Setup: </span>{' '}
                              {this.props.caseData.setup
                                ? this.props.caseData.setup.replace('Other (specify in item 7)', 'Please refer to treatment instructions (upper/lower) below')
                                : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">
                                Upper Smartwire<span className="trademark">&reg;</span>:
                              </span>{' '}
                              {this.props.caseData.upperSmartWire ? this.props.caseData.upperSmartWire : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">
                                Lower Smartwire<span className="trademark">&reg;</span>:
                              </span>{' '}
                              {this.props.caseData.lowerSmartWire ? this.props.caseData.lowerSmartWire : 'NA'}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="">
                            <div>
                              <span className="bold-text">Tooth Movement Restrictions (circled):</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom txplan-half">
                            <div className="txplan-padding-b1">{this.toothSelection('UR', this.props.caseData.restrictionsTeeth)}</div>
                            <div>{this.toothSelection('LR', this.props.caseData.restrictionsTeeth)}</div>
                          </td>
                          <td className="txplan-border-bottom">
                            <div className="txplan-padding-b1">{this.toothSelection('UL', this.props.caseData.restrictionsTeeth)}</div>
                            <div>{this.toothSelection('LL', this.props.caseData.restrictionsTeeth)}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr className="txplan-height-5">
                          <td className="txplan-border-bottom txplan-top">
                            <span className="bold-text">IPR for this treatment plan: </span> {this.props.caseData.ipr ? this.props.caseData.ipr : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr className="txplan-height-5">
                          <td className="txplan-border-bottom txplan-top">
                            <span className="bold-text">Amount of residual spaces (if any) to be kept: </span>
                            {this.props.caseData.residual ? this.props.caseData.residual : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr className="txplan-height-5">
                          <td className="txplan-border-bottom txplan-top">
                            <span className="bold-text"> Treatment instructions describing where and what digital enhancement is needed for upper arch: </span>
                            {this.props.caseData.upper ? this.props.caseData.upper : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr className="txplan-height-5">
                          <td className="txplan-top">
                            <span className="bold-text"> Treatment instructions describing where and what digital enhancement is needed for lower arch: </span>
                            {this.props.caseData.lower ? this.props.caseData.lower : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default DePlan;
