import Axios from 'axios';

export const FETCH_LICENSE_NUMBERS_PENDING = 'FETCH_LICENSE_NUMBERS_PENDING';
export const FETCH_LICENSE_NUMBERS_SUCCESS = 'FETCH_LICENSE_NUMBERS_SUCCESS';
export const FETCH_LICENSE_NUMBERS_ERROR = 'CREATE_LICENSE_NUMBER_ERROR';
export const CREATE_LICENSE_NUMBER_PENDING = 'CREATE_LICENSE_NUMBER_PENDING';
export const CREATE_LICENSE_NUMBER_SUCCESS = 'CREATE_LICENSE_NUMBER_SUCCESS';
export const CREATE_LICENSE_NUMBER_ERROR = 'CREATE_LICENSE_NUMBER_ERROR';
export const DELETE_LICENSE_NUMBER_PENDING = 'DELETE_LICENSE_NUMBER_PENDING';
export const DELETE_LICENSE_NUMBER_SUCCESS = 'DELETE_LICENSE_NUMBER_SUCCESS';
export const DELETE_LICENSE_NUMBER_ERROR = 'DELETE_LICENSE_NUMBER_ERROR';
export const UPDATE_LICENSE_NUMBER_PENDING = 'UPDATE_LICENSE_NUMBER_PENDING';
export const UPDATE_LICENSE_NUMBER_SUCCESS = 'UPDATE_LICENSE_NUMBER_SUCCESS';
export const UPDATE_LICENSE_NUMBER_ERROR = 'UPDATE_LICENSE_NUMBER_ERROR';

export function fetchLicenseNumbersPending() {
  return {
    type: FETCH_LICENSE_NUMBERS_PENDING,
  };
}

export function fetchLicenseNumbersSuccess(license_numbers) {
  return {
    type: FETCH_LICENSE_NUMBERS_SUCCESS,
    license_numbers,
  };
}

export function fetchLicenseNumbersError(error) {
  return {
    type: FETCH_LICENSE_NUMBERS_ERROR,
    error,
  };
}

export function createLicenseNumberPending() {
  return {
    type: CREATE_LICENSE_NUMBER_PENDING,
  };
}

export function createLicenseNumberSuccess() {
  return {
    type: CREATE_LICENSE_NUMBER_SUCCESS,
  };
}

export function createLicenseNumberError(error) {
  return {
    type: CREATE_LICENSE_NUMBER_ERROR,
    error,
  };
}

export function deleteLicenseNumberPending() {
  return {
    type: DELETE_LICENSE_NUMBER_PENDING,
  };
}

export function deleteLicenseNumberSuccess() {
  return {
    type: DELETE_LICENSE_NUMBER_SUCCESS,
  };
}

export function deleteLicenseNumberError(error) {
  return {
    type: DELETE_LICENSE_NUMBER_ERROR,
    error,
  };
}

export function updateLicenseNumberPending() {
  return {
    type: UPDATE_LICENSE_NUMBER_PENDING,
  };
}

export function updateLicenseNumberSuccess() {
  return {
    type: UPDATE_LICENSE_NUMBER_SUCCESS,
  };
}

export function updateLicenseNumberError(error) {
  return {
    type: UPDATE_LICENSE_NUMBER_ERROR,
    error,
  };
}

/**
 * Fetches license numbers for a doctor
 * @param {number} doctorId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 * @example
 * fetchLicenseNumbers(
 *   123,
 *   {
 *    onSuccess: (data) => console.log('success'),
 *    onError: (error) => console.log('error'),
 *   }
 * )(dispatch);
 */
export function fetchLicenseNumbers(doctorId, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(fetchLicenseNumbersPending());
    Axios.get(`/apiv3/doctor/${doctorId}/license_numbers`)
      .then((fetchLicenseNumbersResponse) => {
        dispatch(fetchLicenseNumbersSuccess(fetchLicenseNumbersResponse.data));
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(fetchLicenseNumbersResponse.data);
        }
      })
      .catch((error) => {
        dispatch(fetchLicenseNumbersError(error));
        onError && onError(error);
      });
  };
}

/**
 * Creates a license number for a doctor
 * @param {number} doctorId
 * @param {object} data
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @param {boolean} options.reFetchLicenseNumbers
 * @param {function} options.onFetchLicenseNumbersSuccess
 * @param {function} options.onFetchLicenseNumbersError
 * @param {function} options.onSessionExpired
 * @returns {function}
 * @example
 * createLicenseNumber(
 *   123,
 *   {
 *    license_number: '1234567890',
 *   },
 *   {
 *     onSuccess: (data) => console.log('success'),
 *     onError: (error) => console.log('error'),
 *     reFetchLicenseNumbers: true,
 *     onFetchLicenseNumbersSuccess: (data) => console.log('fetch success'),
 *     onFetchLicenseNumbersError: (error) => console.log('fetch error'),
 *     onSessionExpired: () => console.log('session expired'),
 *   }
 * )(dispatch);
 */
export function createLicenseNumber(
  doctorId,
  data,
  { onSuccess, onError, reFetchLicenseNumbers, onFetchLicenseNumbersSuccess, onFetchLicenseNumbersError, onSessionExpired } = {}
) {
  return (dispatch) => {
    dispatch(createLicenseNumberPending());
    dispatch(fetchLicenseNumbersPending());
    Axios.post(`/apiv3/doctor/${doctorId}/license_numbers`, data)
      .then((createLicenseNumberResponse) => {
        dispatch(createLicenseNumberSuccess());
        if (reFetchLicenseNumbers) {
          fetchLicenseNumbers(doctorId, { onSuccess: onFetchLicenseNumbersSuccess, onError: onFetchLicenseNumbersError })(dispatch);
        }
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(createLicenseNumberResponse.data);
        }
      })
      .catch((error) => {
        dispatch(createLicenseNumberError(error));
        if (error.response && error.response.status === 409) {
          onSessionExpired && onSessionExpired();
        }
        onError && onError(error);
      });
  };
}

/**
 * Deletes a license number for a doctor
 * @param {number} doctorId
 * @param {number} licenseNumberId
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @param {boolean} options.reFetchLicenseNumbers
 * @param {function} options.onFetchLicenseNumbersSuccess
 * @param {function} options.onFetchLicenseNumbersError
 * @param {function} options.onSessionExpired
 * @returns {function}
 * @example
 * deleteLicenseNumber(
 *   123,
 *   456,
 *   {
 *     onSuccess: () => console.log('success'),
 *     onError: (error) => console.log('error'),
 *     reFetchLicenseNumbers: true,
 *     onFetchLicenseNumbersSuccess: (data) => console.log('fetch success'),
 *     onFetchLicenseNumbersError: (error) => console.log('fetch error'),
 *     onSessionExpired: () => console.log('session expired'),
 *   }
 * )(dispatch);
 * */
export function deleteLicenseNumber(
  doctorId,
  licenseNumberId,
  licenseNumber,
  { onSuccess, onError, reFetchLicenseNumbers, onFetchLicenseNumbersSuccess, onFetchLicenseNumbersError, onSessionExpired } = {}
) {
  return (dispatch) => {
    dispatch(deleteLicenseNumberPending());
    dispatch(fetchLicenseNumbersPending());
    Axios.delete(`/apiv3/doctor/${doctorId}/license_numbers/${licenseNumberId}`, {
      params: { license_number: licenseNumber },
    })
      .then(() => {
        dispatch(deleteLicenseNumberSuccess());
        if (reFetchLicenseNumbers) {
          fetchLicenseNumbers(doctorId, { onSuccess: onFetchLicenseNumbersSuccess, onError: onFetchLicenseNumbersError })(dispatch);
        }
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(deleteLicenseNumberError(error));
        if (error.response && error.response.status === 409) {
          onSessionExpired && onSessionExpired();
        }
        onError && onError(error);
      });
  };
}

/**
 * Updates a license number for a doctor
 * @param {number} doctorId
 * @param {number} licenseNumberId
 * @param {object} payload
 * @param {string} payload.license_number
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @param {boolean} options.reFetchLicenseNumbers
 * @param {function} options.onFetchLicenseNumbersSuccess
 * @param {function} options.onFetchLicenseNumbersError
 * @param {function} options.onSessionExpired
 * @returns {function}
 * @example
 * updateLicenseNumber(
 *   123,
 *   456,
 *   {
 *     onSuccess: () => console.log('success'),
 *     onError: (error) => console.log('error'),
 *     reFetchLicenseNumbers: true,
 *     onFetchLicenseNumbersSuccess: (data) => console.log('fetch success'),
 *     onFetchLicenseNumbersError: (error) => console.log('fetch error'),
 *     onSessionExpired: () => console.log('session expired'),
 *   }
 * )(dispatch);
 * */
export function updateLicenseNumber(
  doctorId,
  licenseNumberId,
  payload,
  { onSuccess, onError, reFetchLicenseNumbers, onFetchLicenseNumbersSuccess, onFetchLicenseNumbersError, onSessionExpired } = {}
) {
  return (dispatch) => {
    dispatch(updateLicenseNumberPending());
    dispatch(fetchLicenseNumbersPending());
    Axios.patch(`/apiv3/doctor/${doctorId}/license_numbers/${licenseNumberId}`, payload)
      .then(() => {
        dispatch(updateLicenseNumberSuccess());
        if (reFetchLicenseNumbers) {
          fetchLicenseNumbers(doctorId, { onSuccess: onFetchLicenseNumbersSuccess, onError: onFetchLicenseNumbersError })(dispatch);
        }
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(updateLicenseNumberError(error));
        if (error.response && error.response.status === 409) {
          onSessionExpired && onSessionExpired();
        }
        onError && onError(error);
      });
  };
}
