import { FETCH_CASE_LIST_TABLE_ERROR, FETCH_CASE_LIST_TABLE_START, FETCH_CASE_LIST_TABLE_SUCCESS } from '../../../actions/bpp/case_list/case_list_table';

const initialState = {
  case_list_data: {},
  loading: false,
  error: null,
};

export function caseListTableReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_LIST_TABLE_START:
      return { ...state, loading: true };

    case FETCH_CASE_LIST_TABLE_SUCCESS:
      return getNewCaseListTableState(state, action);

    case FETCH_CASE_LIST_TABLE_ERROR:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
}

/**
 * Get the new state based on the success action result
 * @function
 * @param {Object} state - The reducer state
 * @param {Object} action - The action object
 * @returns {Object} The new state base on the action
 */
function getNewCaseListTableState(state, action) {
  const total_changed = state.case_list_data[action.key]?.total !== action.total;
  const curr_action_case_list_data = total_changed ? {} : state.case_list_data[action.key];
  return {
    ...state,
    case_list_data: {
      ...state.case_list_data,
      [action.key]: {
        ...curr_action_case_list_data,
        total: action.total,
        [action.page]: action.case_list_data,
      },
    },
    loading: false,
  };
}

export const getCaseListTableData = (state) => state.caseListTableReducer.case_list_data;
export const getCaseListTableLoading = (state) => state.caseListTableReducer.loading;
