import React from 'react';
import DOMPurify from 'dompurify';

/**
 * Displays the history item message from internal team
 * @component
 * @alias SetupViewerHistoryListHTMLItem
 */
export default function HistoryListHTMLItem(props) {
  return (
    <>
      <span className="history-list-item-name">InBrace</span>
      <span
        className="ql-editor ql-table quill-formatted"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.value, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
        }}
      />
    </>
  );
}
