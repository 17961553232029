import { getCaseDetailByCaseId } from '../../setup_viewer/components/common/functions';
import { ROLE } from '../../../common/role';
const FINANCIAL_TOOLTIP = 'Please approve financial status first';
const CLINICAL_REVIEW_TOOLTIP =  'This case is under clinical review and cannot be approved';
const COMBINED_TOOLTIP = `\u2022 ${FINANCIAL_TOOLTIP}<br>\u2022 ${CLINICAL_REVIEW_TOOLTIP}`;
const REVISE_TOOLTIP = '"Revise" option now provides you with flexibility to modify your Smile Design with 3D controls or request a written revision'

export const TOOLTIP_VERBIAGES = {
  FINANCIAL_TOOLTIP,
  CLINICAL_REVIEW_TOOLTIP,
  COMBINED_TOOLTIP,
  REVISE_TOOLTIP,
}

/**
 * Check if the DSO doctor is different from the requesting doctor.
 * @param {Object} caseDetails - The case details object.
 * @returns {boolean} - True if different, false otherwise.
 */
export const isDifferentDsoDoctor = (caseDetails) => {
  return caseDetails.dso_doctor && caseDetails.role === ROLE.DSODoctor && caseDetails.dso_doctor.user_id !== caseDetails.request_id;
}

/**
 * Check if the case is initial with unapproved financial status.
 * @param {Object} caseDetails - The case details object.
 * @returns {boolean} - True if initial with unapproved financial status, false otherwise.
 */
export const isInitialWithUnapproved = (caseDetails) => {
  const caseDetail = getCaseDetailByCaseId(caseDetails, caseDetails.case_id);
  const isInitialCase = caseDetails.case_id.indexOf('-') === -1;
  const isDSOCase = caseDetail.is_dso_case;
  const isUnapprovedFinancially = !caseDetail.financial_approval;

  return isInitialCase && isDSOCase && isUnapprovedFinancially;
}

/**
 * Check if the case is in clinical review.
 * @param {Object} caseDetails - The case details object.
 * @returns {boolean} - True if in clinical review, false otherwise.
 */
export const isInClinicalReview = (caseDetails) => {
  const caseDetail = getCaseDetailByCaseId(caseDetails, caseDetails.case_id);
  return caseDetail.clinical_review;
}

/**
 * Get button clearance based on case details.
 * @param {Object} caseDetails - The case details object.
 * @returns {Object} - Object containing approved_disabled, revise_disabled, and approve_tooltip.
 */
export const getButtonClearance = (caseDetails) => {
  const _isDifferentDSODoctor = isDifferentDsoDoctor(caseDetails);
  const _isInitialWithUnapproved = isInitialWithUnapproved(caseDetails);
  const _isInClinicalReview = isInClinicalReview(caseDetails);

  const approvedDisabled = _isDifferentDSODoctor || _isInitialWithUnapproved || _isInClinicalReview;
  const reviseDisabled = _isDifferentDSODoctor || _isInClinicalReview;

  let approveTooltip = '';
  if (_isInitialWithUnapproved && _isInClinicalReview) {
    approveTooltip = TOOLTIP_VERBIAGES.COMBINED_TOOLTIP;
  } else if (_isInitialWithUnapproved) {
    approveTooltip = TOOLTIP_VERBIAGES.FINANCIAL_TOOLTIP;
  } else if (_isInClinicalReview) {
    approveTooltip = TOOLTIP_VERBIAGES.CLINICAL_REVIEW_TOOLTIP;
  }

  return { approved_disabled: approvedDisabled, revise_disabled: reviseDisabled, approve_tooltip: approveTooltip };
}