import { Group, AmbientLight, DirectionalLight, HemisphereLight } from 'three';

/**
 * Creates the light group
 * @function
 * @return {THREE.Group} The light group
 */
function createLights() {
  const lightGroup = new Group();

  const amblight = new AmbientLight(0x404040, 0.2);
  const hemiLight = new HemisphereLight(0xcccccc, 0xcccccc, 0.2);
  const shadowLight1 = createDirectionalLight(200, 1, 1, 0xffffff, 0.5);
  const shadowLight2 = createDirectionalLight(-200, -0.1, 1, 0xffffff, 0.4);

  lightGroup.add(amblight, hemiLight, shadowLight1, shadowLight2);

  return lightGroup;
}

/**
 * Creates a directional light
 * @function
 * @param {Number} x - Position x
 * @param {Number} y - Position y
 * @param {Number} z - Position z
 * @param {Number} color - Color of the light
 * @param {Number} intensity - Intensity of the light
 * @return {THREE.DirectionalLight} The directional light
 */
function createDirectionalLight(x, y, z, color, intensity) {
  const light = new DirectionalLight(color, intensity);
  light.position.set(x, y, z);
  return light;
}

export { createLights };
