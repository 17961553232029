import React from 'react';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import FlipIcon from '@material-ui/icons/Flip';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { defaultScale, maxScale, minScale, zoomStep } from './image_viewer';

function RecordViewerControls(props) {
  const visibility = props.fullscreenOnly ? 'hidden' : 'visible';
  const scale = props.scale ? props.scale : defaultScale;

  return (
    <>
      {props.hasPrev && <i className="fa fa-angle-left image-viewer-btn" aria-hidden="true" onClick={props.onClickPrev} />}
      {props.hasNext && <i className="fa fa-angle-right image-viewer-btn" aria-hidden="true" onClick={props.onClickNext} />}
      <div className="image-viewer-controls">
        <i
          className="fa fa-refresh image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Reset"
          aria-hidden="true"
          style={{ visibility: visibility }}
          onClick={props.fullscreenOnly ? null : props.reset}
        />
        <i
          className="fa fa-minus image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Zoom Out"
          aria-hidden="true"
          style={{ visibility: visibility }}
          onClick={props.fullscreenOnly ? null : props.zoomOut}
        />
        <input
          type="range"
          min={minScale}
          max={maxScale}
          value={scale}
          step={zoomStep}
          onChange={(e) => props.setScale(parseFloat(e.target.value))}
          style={{ visibility: visibility }}
        />
        <i
          className="fa fa-plus image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Zoom In"
          aria-hidden="true"
          style={{ visibility: visibility }}
          onClick={props.fullscreenOnly ? null : props.zoomIn}
        />
        <span style={{ visibility: visibility }}>{(props.scale * 100).toFixed(0)}%</span>
        <span
          className="image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Rotate"
          onClick={props.fullscreenOnly ? null : props.onRotate}
          style={{ visibility: visibility }}
        >
          <Rotate90DegreesCcwIcon />
        </span>
        <span
          className="image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Vertical Flip"
          style={{ transform: 'rotate(90deg)', visibility: visibility }}
          onClick={props.fullscreenOnly ? null : props.onFlipV}
        >
          <FlipIcon />
        </span>
        <span
          className="image-viewer-controls__btn"
          data-toggle="tooltip"
          data-placement="top"
          title="Horizontal Flip"
          onClick={props.fullscreenOnly ? null : props.onFlipH}
          style={{ visibility: visibility }}
        >
          <FlipIcon />
        </span>
        <span className="image-viewer-controls__btn" data-toggle="tooltip" data-placement="top" title="Fullscreen" onClick={props.onOpenLightBox}>
          <FullscreenIcon />
        </span>
      </div>
    </>
  );
}

export default RecordViewerControls;
