import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { getRootPath } from '../../common/functions';
import { withRouter, Link } from 'react-router-dom';

import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import Gen1AlertModal from '../../components/modal/gen_1_alert_modal';

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
    };
  }

  getSubmitCaseRoute = () => {
    let submissionRoute = this.props.history.location.pathname.match(/submission\//);
    let route = getRootPath(this.props.history.location.pathname);

    if (!this.props.gen_2) {
      route = '';
    } else if (submissionRoute) {
      //Already in submission do nothing
      route = this.props.history.location.pathname;
    } else {
      route = route + '/submission/patient';
    }

    return route;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Welcome | InBrace Smile Design Studio™</title>
        </Helmet>
        <CardContainer type="message">
          <h3>Welcome to the InBrace Smile Design Studio™</h3>
          <p>You can start by submitting your first case</p>
          <div className="block--right">
            <Link to={{ pathname: this.getSubmitCaseRoute() }}>
              <Button
                onClick={() => {
                  if (!this.props.gen_2) {
                    this.setState({ alert: true });
                  }
                }}
              >
                Submit Case
              </Button>
            </Link>
          </div>
        </CardContainer>
        <Gen1AlertModal
          doctor_id={this.props.doctor_id}
          show={this.state.alert}
          onCloseButtonClick={() => {
            this.setState({ alert: false });
          }}
        />
      </>
    );
  }
}

export default withRouter(Welcome);
