import React from 'react';

/**
 * Wraps the children with a note container
 * @component
 */
function NoteContainer({ className, ...props }) {
  const classNames = `${className ? className : ''} note-container`;
  return <div className={classNames} {...props} />;
}

export default NoteContainer;
