import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Popout from 'react-popout';

import { getCaseFileList } from '../../../redux/reducers/ipp/case_details/case_files';

const ERROR_IMAGE = process.env.PUBLIC_URL + '/static/img/no-image-white.png';
const onImageError = (evt) => {
  const img = evt.currentTarget;
  img.onerror = null;
  img.src = ERROR_IMAGE;
};

function getImagesFromCaseFileList(case_files_list) {
  const case_id = case_files_list.case_id;
  const files = case_files_list?.[case_id]?.submission_process?.files;
  const images = [];
  if (files) {
    files.forEach((file) => {
      if (file.file_type === 'photos' || file.file_type === 'xrays') {
        images.push(`/media/image/?file=${file.upload_data}&size=lrg`);
      }
    });
  }
  return images;
}

function WasmSidebarRecord(props) {
  const [showPopout, setShowPopout] = useState(false);
  const popoutRef = React.useRef();
  const images = getImagesFromCaseFileList(props.case_files_list);
  const record_title = `Records (${images.length})`;
  const popout_url = `/popout/record_viewer/${props.case_files_list.case_id}`;

  const onClose = () => {
    if (popoutRef.current) popoutRef.current.closeWindow();
  };

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
    return onClose;
  }, []);

  return (
    <div className="wasm-sidebar-main-content">
      <div className="wasm-sidebar-detail-title">
        {record_title}
        <i
          className="fa fa-external-link"
          aria-hidden
          data-toggle="tooltip"
          data-placement="top"
          title="View records in popout window"
          onClick={() => setShowPopout(!showPopout)}
        />
      </div>
      <div className="wasm-sidebar-detail-list scrollbar--dark">
        {images.map((img, i) => (
          <div key={i} className="wasm-sidebar-image-item">
            <img src={img} alt="" onError={onImageError} />
          </div>
        ))}
      </div>

      {showPopout && (
        <Popout
          ref={popoutRef}
          url={popout_url}
          title={record_title}
          onClosing={() => setShowPopout(false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    case_files_list: getCaseFileList(state),
  };
};

export default connect(mapStateToProps)(WasmSidebarRecord);
