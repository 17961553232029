import Axios from 'axios';

export const FETCH_ITERO_SCANS_PENDING = 'FETCH_ITERO_SCANS_PENDING';
export const FETCH_ITERO_SCANS_SUCCESS = 'FETCH_ITERO_SCANS_SUCCESS';
export const FETCH_ITERO_SCANS_ERROR = 'FETCH_ITERO_SCANS_ERROR';
export const FETCH_ITERO_PHOTOS_PENDING = 'FETCH_ITERO_PHOTOS_PENDING';
export const FETCH_ITERO_PHOTOS_SUCCESS = 'FETCH_ITERO_PHOTOS_SUCCESS';
export const FETCH_ITERO_PHOTOS_ERROR = 'FETCH_ITERO_PHOTOS_ERROR';
export const UPDATE_ICOMPLETE_CASE_ITERO_FILES_PENDING = 'UPDATE_ICOMPLETE_CASE_ITERO_FILES_PENDING';
export const UPDATE_ICOMPLETE_CASE_ITERO_FILES_SUCCESS = 'UPDATE_ICOMPLETE_CASE_ITERO_FILES_SUCCESS';
export const UPDATE_ICOMPLETE_CASE_ITERO_FILES_ERROR = 'UPDATE_ICOMPLETE_CASE_ITERO_FILES_ERROR';
export const ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_PENDING = 'ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_PENDING';
export const ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_SUCCESS = 'ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_SUCCESS';
export const ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_ERROR = 'ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_ERROR';
export const DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_PENDING = 'DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_PENDING';
export const DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_SUCCESS = 'DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_SUCCESS';
export const DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_ERROR = 'DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_ERROR';
export const ADD_ITERO_PHOTOS_TO_DE_PENDING = 'ADD_ITERO_PHOTOS_TO_DE_PENDING';
export const ADD_ITERO_PHOTOS_TO_DE_SUCCESS = 'ADD_ITERO_PHOTOS_TO_DE_SUCCESS';
export const ADD_ITERO_PHOTOS_TO_DE_ERROR = 'ADD_ITERO_PHOTOS_TO_DE_ERROR';
export const DELETE_ITERO_PHOTOS_FROM_DE_PENDING = 'DELETE_ITERO_PHOTOS_FROM_DE_PENDING';
export const DELETE_ITERO_PHOTOS_FROM_DE_SUCCESS = 'DELETE_ITERO_PHOTOS_FROM_DE_SUCCESS';
export const DELETE_ITERO_PHOTOS_FROM_DE_ERROR = 'DELETE_ITERO_PHOTOS_FROM_DE_ERROR';
export const GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_PENDING = 'GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_PENDING';
export const GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_SUCCESS = 'GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_SUCCESS';
export const GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_ERROR = 'GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_ERROR';
export const DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_PENDING = 'DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_PENDING';
export const DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_SUCCESS = 'DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_SUCCESS';
export const DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_ERROR = 'DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_ERROR';
export const GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_PENDING = 'GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_PENDING';
export const GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_SUCCESS = 'GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_SUCCESS';
export const GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_ERROR = 'GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_ERROR';

export function fetchIteroScansPending() {
  return {
    type: FETCH_ITERO_SCANS_PENDING,
  };
}

export function fetchIteroScansSuccess() {
  return {
    type: FETCH_ITERO_SCANS_SUCCESS,
  };
}

export function fetchIteroScansError(error) {
  return {
    type: FETCH_ITERO_SCANS_ERROR,
    error,
  };
}

export function fetchIteroPhotosPending() {
  return {
    type: FETCH_ITERO_PHOTOS_PENDING,
  };
}

export function fetchIteroPhotosSuccess(iteroPhotos) {
  return {
    type: FETCH_ITERO_PHOTOS_SUCCESS,
    iteroPhotos,
  };
}

export function fetchIteroPhotosError(error) {
  return {
    type: FETCH_ITERO_PHOTOS_ERROR,
    error,
  };
}

export function updateCaseIteroFilesPending() {
  return {
    type: UPDATE_ICOMPLETE_CASE_ITERO_FILES_PENDING,
  };
}

export function updateCaseIteroFilesSuccess() {
  return {
    type: UPDATE_ICOMPLETE_CASE_ITERO_FILES_SUCCESS,
  };
}

export function updateCaseIteroFilesError(error) {
  return {
    type: UPDATE_ICOMPLETE_CASE_ITERO_FILES_ERROR,
    error,
  };
}

export function addIteroPhotosToIncompleteCasePending() {
  return {
    type: ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_PENDING,
  };
}

export function addIteroPhotosToIncompleteCaseSuccess() {
  return {
    type: ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_SUCCESS,
  };
}

export function addIteroPhotosToIncompleteCaseError(error) {
  return {
    type: ADD_ITERO_PHOTOS_TO_INCOMPLETE_CASE_ERROR,
    error,
  };
}

export function deleteIteroPhotosFromIncompleteCasePending() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_PENDING,
  };
}

export function deleteIteroPhotosFromIncompleteCaseSuccess() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_SUCCESS,
  };
}

export function deleteIteroPhotosFromIncompleteCaseError(error) {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_INCOMPLETE_CASE_ERROR,
    error,
  };
}

export function addIteroPhotosToDEPending() {
  return {
    type: ADD_ITERO_PHOTOS_TO_DE_PENDING,
  };
}

export function addIteroPhotosToDESuccess() {
  return {
    type: ADD_ITERO_PHOTOS_TO_DE_SUCCESS,
  };
}

export function addIteroPhotosToDEError(error) {
  return {
    type: ADD_ITERO_PHOTOS_TO_DE_ERROR,
    error,
  };
}

export function deleteIteroPhotosFromDEPending() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_DE_PENDING,
  };
}

export function deleteIteroPhotosFromDESuccess() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_DE_SUCCESS,
  };
}

export function deleteIteroPhotosFromDEError(error) {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_DE_ERROR,
    error,
  };
}

export function getIteroPhotoRecordsForProgressRecordPending() {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_PENDING,
  };
}

export function getIteroPhotoRecordsForProgressRecordSuccess() {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_SUCCESS,
  };
}

export function getIteroPhotoRecordsForProgressRecordError(error) {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_PROGRESS_RECORD_ERROR,
    error,
  };
}

export function deleteIteroPhotosFromProgressRecordPending() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_PENDING,
  };
}

export function deleteIteroPhotosFromProgressRecordSuccess() {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_SUCCESS,
  };
}

export function deleteIteroPhotosFromProgressRecordError(error) {
  return {
    type: DELETE_ITERO_PHOTOS_FROM_PROGRESS_RECORD_ERROR,
    error,
  };
}

export function getIteroPhotoRecordsForRecordsReuploadPending() {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_PENDING,
  };
}

export function getIteroPhotoRecordsForRecordsReuploadSuccess() {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_SUCCESS,
  };
}

export function getIteroPhotoRecordsForRecordsReuploadError(error) {
  return {
    type: GET_ITERO_PHOTO_RECORDS_FOR_RECORDS_REUPLOAD_ERROR,
    error,
  };
}

/**
 * Fetches itero scans
 *
 * @param {object} options
 * @param {Array} options.licenseNumbers
 * @param {string} options.cursor
 * @param {string} options.searchQuery
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 * @example
 * fetchIteroScans(
 *   [123, 456],
 *   {
 *    onSuccess: (data) => console.log('success'),
 *    onError: (error) => console.log('error'),
 *   }
 * )(dispatch);
 */
export function fetchIteroScans({ licenseNumbers, cursor, searchQuery, onSuccess, onError } = {}) {
  return async (dispatch) => {
    dispatch(fetchIteroScansPending());
    try {
      const fetchIteroScansResponse = await Axios.get('/apiv3/iterofiles', {
        params: { license_number: licenseNumbers, cursor, file_type: 'scan', q: searchQuery },
      });
      dispatch(fetchIteroScansSuccess());
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(fetchIteroScansResponse.data);
      }
      return fetchIteroScansResponse.data;
    } catch (error) {
      dispatch(fetchIteroScansError(error));
      onError && onError(error);
    }
  };
}

/**
 * Fetches itero photos
 * @param {number} iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 * @example
 * fetchIteroPhotos(
 *   123,
 *   {
 *   onSuccess: (data) => console.log('success'),
 *   onError: (error) => console.log('error'),
 *  }
 * )(dispatch);
 * */
export function fetchIteroPhotos(iteroId, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(fetchIteroPhotosPending());
    Axios.get('/apiv3/iterofiles', { params: { itero_id: iteroId, file_type: 'photo' } })
      .then((fetchIteroPhotosResponse) => {
        dispatch(fetchIteroPhotosSuccess(fetchIteroPhotosResponse.data.results));
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(fetchIteroPhotosResponse.data.results);
        }
      })
      .catch((error) => {
        dispatch(fetchIteroPhotosError(error));
        onError && onError(error);
      });
  };
}

/**
 * Updates case related itero files
 *
 * @param {number} caseId
 * @param {object} payload
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @param {boolean} options.reFetchIteroScans
 * @param {Array} options.licenseNumbers
 * @param {function} options.onFetchIteroScansSuccess
 * @param {function} options.onFetchIteroScansError
 * @returns {function}
 * @example
 * updateCaseIteroFiles(
 *  123,
 *  {
 *   itero_files: [123, 2456],
 *  },
 *  {
 *   onSuccess: (data) => console.log('success'),
 *   onError: (error) => console.log('error'),
 *   reFetchIteroScans: true,
 *   reFetchIteroScansType: 'scan',
 *   licenseNumbers: [123, 456],
 *   onFetchIteroScansSuccess: (data) => console.log('success'),
 *   onFetchIteroScansError: (error) => console.log('error'),
 *  }
 * )(dispatch);
 * */
export function updateCaseIteroFiles(
  caseId,
  payload,
  { onSuccess, onError, reFetchIteroScans, licenseNumbers, onFetchIteroScansSuccess, onFetchIteroScansError } = {}
) {
  return (dispatch) => {
    dispatch(updateCaseIteroFilesPending());
    Axios.post(`/apiv3/iterofiles/case/${caseId}`, payload)
      .then((updateCaseIteroFilesResponse) => {
        dispatch(updateCaseIteroFilesSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(updateCaseIteroFilesResponse.data);
        }
        if (reFetchIteroScans) {
          dispatch(fetchIteroScans(licenseNumbers, { onSuccess: onFetchIteroScansSuccess, onError: onFetchIteroScansError }));
        }
      })
      .catch((error) => {
        dispatch(updateCaseIteroFilesError(error));
        onError && onError(error);
      });
  };
}

/**
 * Adds itero photos to incomplete case
 *
 * @param {object} payload
 * @param {number} payload.incompleteCaseId
 * @param {Array} payload.iteroFilesIds
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 *
 */
export function addIteroPhotosToIncompleteCase(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(addIteroPhotosToIncompleteCasePending());
    Axios.post('/apiv3/iterofiles/add-itero-photos-to-incomplete-case', payload)
      .then((addIteroPhotosToIncompleteCaseResponse) => {
        dispatch(addIteroPhotosToIncompleteCaseSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(addIteroPhotosToIncompleteCaseResponse.data);
        }
      })
      .catch((error) => {
        dispatch(addIteroPhotosToIncompleteCaseError(error));
        onError && onError(error);
      });
  };
}

/**
 * Deletes itero photos from incomplete case
 *
 * @param {object} payload
 * @param {number} payload.incompleteCaseId
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 *
 */
export function deleteIteroPhotosFromIncompleteCase(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(deleteIteroPhotosFromIncompleteCasePending());
    Axios.post('/apiv3/iterofiles/delete-itero-photos-from-incomplete-case', payload)
      .then((deleteIteroPhotosFromIncompleteCaseResponse) => {
        dispatch(deleteIteroPhotosFromIncompleteCaseSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(deleteIteroPhotosFromIncompleteCaseResponse.data);
        }
      })
      .catch((error) => {
        dispatch(deleteIteroPhotosFromIncompleteCaseError(error));
        onError && onError(error);
      });
  };
}

/**
 * Adds itero photos to DE
 *
 * @param {object} payload
 * @param {string} payload.digitalId
 * @param {Array} payload.iteroFilesIds
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 */
export function addIteroPhotosToDE(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(addIteroPhotosToDEPending());
    Axios.post('/apiv3/iterofiles/add-itero-photos-to-de', payload)
      .then((addIteroPhotosToDEResponse) => {
        dispatch(addIteroPhotosToDESuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(addIteroPhotosToDEResponse.data);
        }
      })
      .catch((error) => {
        dispatch(addIteroPhotosToDEError(error));
        onError && onError(error);
      });
  };
}

/**
 * Deletes itero photos from DE
 *
 * @param {object} payload
 * @param {string} payload.digitalId
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 */
export function deleteIteroPhotosFromDE(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(deleteIteroPhotosFromDEPending());
    Axios.post('/apiv3/iterofiles/delete-itero-photos-from-de', payload)
      .then((deleteIteroPhotosFromDEResponse) => {
        dispatch(deleteIteroPhotosFromDESuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(deleteIteroPhotosFromDEResponse.data);
        }
      })
      .catch((error) => {
        dispatch(deleteIteroPhotosFromDEError(error));
        onError && onError(error);
      });
  };
}

/**
 * Fetches itero photo records for progress record
 *
 * @param {object} params
 * @param {number} params.caseId
 * @param {Array} payload.iteroFilesIds
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 */
export function getIteroPhotoRecordsForProgressRecord(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(getIteroPhotoRecordsForProgressRecordPending());
    Axios.get('/apiv3/iterofiles/get-itero-photo-records-for-progress-record', { params })
      .then((getIteroPhotoRecordsForProgressRecordResponse) => {
        dispatch(getIteroPhotoRecordsForProgressRecordSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(getIteroPhotoRecordsForProgressRecordResponse.data);
        }
      })
      .catch((error) => {
        dispatch(getIteroPhotoRecordsForProgressRecordError(error));
        onError && onError(error);
      });
  };
}

/**
 * Deletes itero photos from progress record
 *
 * @param {object} payload
 * @param {Array} payload.filePaths
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 */
export function deleteIteroPhotosFromProgressRecord(payload, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(deleteIteroPhotosFromProgressRecordPending());
    Axios.post('/apiv3/iterofiles/delete-itero-photos-from-progress-record', payload)
      .then((deleteIteroPhotosFromProgressRecordResponse) => {
        dispatch(deleteIteroPhotosFromProgressRecordSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(deleteIteroPhotosFromProgressRecordResponse.data);
        }
      })
      .catch((error) => {
        dispatch(deleteIteroPhotosFromProgressRecordError(error));
        onError && onError(error);
      });
  };
}

/**
 * Fetches itero photo records for records reupload
 *
 * @param {object} params
 * @param {number} params.caseId
 * @param {Array} payload.iteroFilesIds
 * @param {string} payload.iteroId
 * @param {object} options
 * @param {function} options.onSuccess
 * @param {function} options.onError
 * @returns {function}
 */
export function getIteroPhotoRecordsForRecordsReupload(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(getIteroPhotoRecordsForRecordsReuploadPending());
    Axios.get('/apiv3/iterofiles/get-itero-photo-records-for-records-reupload', { params })
      .then((getIteroPhotoRecordsForRecordsReuploadResponse) => {
        dispatch(getIteroPhotoRecordsForRecordsReuploadSuccess());
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(getIteroPhotoRecordsForRecordsReuploadResponse.data);
        }
      })
      .catch((error) => {
        dispatch(getIteroPhotoRecordsForRecordsReuploadError(error));
        onError && onError(error);
      });
  };
}
