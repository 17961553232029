import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays content header
 * @component
 */
function ContentHeader(props) {
  return (
    <div className="content__header">
      <h1 className='content__header__restrict'>{props.title}</h1>
      {props.children}
    </div>
  );
}

ContentHeader.proptTypes = {
  title: PropTypes.string.isRequired,
};

export default ContentHeader;
