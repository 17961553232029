import _ from 'lodash';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DOMPurify from 'dompurify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Deprecation Warning: This component is deprecated and will be removed in the future.
 *
 * Gets the latest comment from dr. to be display on setup
 * @function
 * @param {JSX} Entry to be displayed to user
 */
const getLatestSetupCommentFromDr = (props) => {
  const prep_statuses = props?.case_statuses.filter((status) => status.case_status === 'INBRACE Preparing Setup');

  return prep_statuses.length > 1 ? (
    <div className="ql-editor ql-table rich-logs rich-logs--teal">
      <p className="bold-text">Doctor Requested Revision</p>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(prep_statuses[prep_statuses.length - 1].status_comment, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
        }}
      />
    </div>
  ) : null;
};

const useSetupHelperAccordionStyles = makeStyles(() => ({
  accordionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    rowGap: '16px',
  },
  accordion: {
    textAlign: 'left',
    borderRadius: '3px',
    padding: 0,
    wordBreak: 'break-word',
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  inbraceCommentBgColor: {
    backgroundColor: '#96B8E1',
    color: 'black',
  },
  accordionQLEditor: {
    '&.ql-editor.ql-table.rich-logs': {
      padding: 0,
    },
    '&.ql-editor.ql-table.rich-logs--teal': {
      margin: 0,
    },
    '&.ql-editor.ql-table.rich-logs--teal p': {
      margin: 0,
    },
  },
  accordionSmileDesignPreferences: {
    backgroundColor: '#211B57',
    '&:hover': {
      opacity: 0.75,
    },
    color: 'white',
    '& .MuiSvgIcon-root': {
      color: 'white',
    },

    '& ol': {
      paddingInlineStart: '16px',
    },
    '& ol li::marker': {
      fontWeight: 700,
    },
    '& ol ul': {
      listStyle: 'none',
      paddingInlineStart: '0',
    },
    '& ol li .response': {
      fontWeight: 700,
    },
    '& ol li .view-ipr-modal-text': {
      fontWeight: 700,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    '& ol li .response.response--not-default': {
      color: '#C9E63F',
    },
  },
  accordionSummary: {
    flexDirection: 'row-reverse',
    padding: 0,
    minHeight: 'auto',
    '&.Mui-expanded': {
      minHeight: 'auto',
    },
    '& .MuiAccordionSummary-expandIcon': {
      margin: 0,
      padding: '8px',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '24px',
    },
  },
  heading: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'white !important',
  },
  heading2: {
    fontSize: '14px',
    fontWeight: 500,
    color: 'Black !important',
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '9px',

    '& .last-updated-date': {
      color: 'white',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      margin: 0,
      textAlign: 'left',
    },
    '& .last-updated-date .date': {
      fontWeight: 700,
    },
  },
}));

const useSetupHelperIPRModalStyles = makeStyles(() => ({
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  toothChartSelection: {
    color: '#707070',
    '& .teeth-label': {
      fontFamily: "sofia-pro, 'sans-serif'",
      fontSize: '12px',
      fontWeight: 800,
      lineHeight: '24px',
    },
    '& .teeth': {
      columnGap: '8px',
      display: 'flex',
      flexFlow: 'row wrap',
      rowGap: '8px',
    },
    '& .teeth .tooth': {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 75px',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '& .teeth .tooth .tooth__label': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16px',
    },
    '& .teeth .tooth .tooth__label.bold': {
      fontWeight: 700,
    },
    '& .teeth .tooth .tooth__value': {
      border: '1px solid #E1E2E2',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
      padding: '4px',
      width: '100%',
    },
  },
}));

const SetupAccordionsWrapper = (props) => {
  const classes = useSetupHelperAccordionStyles();
  const has_children = Array.isArray(props.children) ? props.children.find((child) => child) : props.children !== false;

  return has_children ? <div className={classes.accordionsWrapper}>{props.children}</div> : null;
};

const shouldShowInBraceComment = (statuses) => {
  const lastCommentStatus = statuses
    ?.slice()
    ?.reverse()
    ?.find((status) => status?.case_status === 'Provider Edit Review' || status?.case_status === 'Doctor Approve or Revise Setup')?.case_status;

  return lastCommentStatus === 'Provider Edit Review';
};

const SetupLatestCommentFromDr = (props) => {
  const prep_statuses = props?.case_statuses.filter((status) => status.case_status === 'INBRACE Preparing Setup');
  const show_inbrace_comment = shouldShowInBraceComment(props.case_statuses);
  const heading = show_inbrace_comment ? 'View InBrace Recommended Revisions' : 'Doctor Requested Revision';
  const classes = useSetupHelperAccordionStyles();

  if (prep_statuses.length <= 1) {
    return null;
  }

  return (
    <Accordion
      className={`ql-editor ql-table  ${show_inbrace_comment ? classes.inbraceCommentBgColor : ' rich-logs rich-logs--teal'} ${classes.accordion} ${
        classes.accordionQLEditor
      }`}
      defaultExpanded={true}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel-latest-comment-from-dr-content"
        id="panel-latest-comment-from-dr-header"
      >
        <Typography className={show_inbrace_comment ? classes.heading2 : classes.heading}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(prep_statuses[prep_statuses.length - 1].status_comment, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const MaximumIPRModal = ({ preference, onClose, close_btn_text, classNames = {} }) => {
  const classes = useSetupHelperIPRModalStyles();

  const { dialogClassName, modalFooterClassName } = classNames;

  const handleClose = () => {
    onClose();
  };

  const getToothSelectionChoice = (tooth) => {
    const selectedChoice = preference.choices.find((choice) => choice.id === tooth.selected_choice);
    const defaultChoice = preference.choices.find((choice) => Number(choice.id) === preference.default_choice);
    return selectedChoice ? selectedChoice : defaultChoice;
  };

  return (
    <div className="modal fade base-modal bpp-modal in" id="baseModal" role="dialog" style={{ paddingRight: '17px' }} onClick={handleClose}>
      <div className={`modal-dialog modal-dialog--setup-ipr ${dialogClassName ? dialogClassName : ''}`} role="document">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <button type="button" className="close" onClick={handleClose}>
              <span>×</span>
            </button>
            <h4 className="modal-title fs-exclude">Maximum IPR per contact</h4>
            <p></p>
          </div>
          <div className={'modal-body ' + classes.modalBody}>
            {Object.keys(preference.tooth_selections).map((toothSelection, index) => {
              return (
                <div key={index} className={classes.toothChartSelection}>
                  <label className="teeth-label">{toothSelection.toUpperCase()}</label>
                  <div className="teeth">
                    {preference.tooth_selections[toothSelection].map((tooth, index) => {
                      return (
                        <div className="tooth" key={index}>
                          <label className={`tooth__label ${['UR1 - UL1', 'LR1 - LL1'].includes(tooth.selection.toUpperCase()) ? 'bold' : ''}`}>
                            {tooth.selection.toUpperCase()}
                          </label>
                          <div className="tooth__value">{getToothSelectionChoice(tooth).choice}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={`modal-footer ${modalFooterClassName ? modalFooterClassName : ''}`}>
            <button id="modal_close_btn" type="button" className="btn btn-light btn-negative" onClick={handleClose}>
              {close_btn_text}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SetupSmileDesignPreferences = (props) => {
  const classes = useSetupHelperAccordionStyles();
  const [openIPRModal, setOpenIPRModal] = React.useState(false);
  const [currentIPRModalPreference, setCurrentIPRModalPreference] = React.useState(null);

  const getPreferences = () => {
    return props.doctor_smile_design_preferences.preferences;
  };

  const getLastUpdatedDate = () => {
    return props.doctor_smile_design_preferences.modified_date;
  };

  const getQuestion = (preference) => {
    return preference.question;
  };

  const getResponseElement = (preference) => {
    switch (preference.question_type) {
      case 'checkbox':
        const displayCheckboxChoices = preference.choices.map((choice) => {
          const answer = preference.selected_choice.includes(Number(choice.id)) ? 'Yes' : 'No';
          const isDefaultAnswer = preference.default_choice.includes(Number(choice.id)) ? answer === 'Yes' : answer === 'No';
          return (
            <li key={choice.id} className={'response'}>
              {choice.choice} (<span className={`response ${!isDefaultAnswer ? 'response--not-default' : ''}`}>{answer}</span>)
            </li>
          );
        });

        return <ul>{displayCheckboxChoices}</ul>;
      case 'radio':
        const selectedRadioChoice = preference.choices.find((choice) => Number(choice.id) === preference.selected_choice);
        const defaultRadioChoice = preference.choices.find((choice) => Number(choice.id) === preference.default_choice);
        const displayRadioChoice = selectedRadioChoice ? selectedRadioChoice : defaultRadioChoice;
        const hasResponseText = Boolean(displayRadioChoice.text_placeholder);
        const displayRadioChoiceText = hasResponseText ? preference.response_text : displayRadioChoice.choice;
        const isDefaultRadioChoice = selectedRadioChoice ? Number(displayRadioChoice.id) === preference.default_choice : false;
        return <span className={`response ${!isDefaultRadioChoice ? 'response--not-default' : ''}`}>{displayRadioChoiceText}</span>;
      case 'dropdown':
        const selectedDropdownChoice = preference.choices.find((choice) => Number(choice.id) === preference.selected_choice);
        const defaultDropdownChoice = preference.choices.find((choice) => Number(choice.id) === preference.default_choice);
        const isDefaultDropdownChoice = selectedDropdownChoice ? Number(selectedDropdownChoice.id) === preference.default_choice : false;
        return (
          <span className={`response ${!isDefaultDropdownChoice ? 'response--not-default' : ''}`}>
            {selectedDropdownChoice ? selectedDropdownChoice.choice : defaultDropdownChoice.choice}
          </span>
        );
      case 'text':
        return preference.response_text ? <span className="response response--not-default">{preference.response_text}</span> : null;
      default:
        return null;
    }
  };

  const handleViewIPRModalClick = (preference) => {
    document.body.classList.add('modal-open');
    document.body.style.paddingRight = '17px';
    setCurrentIPRModalPreference(preference);
    setOpenIPRModal(true);
  };

  const handleIPRModalClose = () => {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '0px';
    setOpenIPRModal(false);
  };

  return (
    <>
      <Accordion className={`${classes.accordion} ${classes.accordionSmileDesignPreferences}`}>
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-smile-design-preferences-content"
          id="panel-smile-design-preferences-header"
        >
          <Typography className={classes.heading}>View Provider Smile Design Preferences</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <ol>
            {getPreferences().map((preference) => (
              <li key={preference.id}>
                {getQuestion(preference)}:{' '}
                {preference.question_type === 'tooth_chart_selection' ? (
                  <span className="view-ipr-modal-text" onClick={() => handleViewIPRModalClick(preference)}>
                    View
                  </span>
                ) : (
                  getResponseElement(preference)
                )}
              </li>
            ))}
          </ol>
          {getLastUpdatedDate() && (
            <p className="last-updated-date">
              Last Updated: <span className="date">{getLastUpdatedDate()}</span>
            </p>
          )}
        </AccordionDetails>
      </Accordion>
      {openIPRModal ? <MaximumIPRModal preference={currentIPRModalPreference} onClose={handleIPRModalClose} close_btn_text="Close" /> : null}
    </>
  );
};

export { getLatestSetupCommentFromDr, SetupSmileDesignPreferences, SetupLatestCommentFromDr, SetupAccordionsWrapper, MaximumIPRModal };
