import Axios from 'axios';

/**
 * Retrieves temporary Dropbox link
 * @function
 * @param {string} file_name - File name
 * @param {string} file_path - File path
 * @returns {string} - Temporary file url
 */
const getTempLink = async (file_name, file_path) => {
  let res = await Axios.get(`/apiV2/get_temp_file_link`, { params: { file_name: file_name, file_path: file_path } });
  let data = await res.data;
  return data.file_url;
};

/**
 * Retrieves temporary Dropbox links for itero files
 *
 * @param {Array} filePaths - File paths
 * @returns {Promise}
 */
const getIteroFilesTempLinks = (filePaths) => {
  return Axios.post(`/apiV2/get-itero-temp-file-links`, { itero_files_paths: filePaths });
};

/**
 * Downloads zip from dropbox
 * @function
 * @param {object} event - Event object
 * @param {string} href - Href link
 */
const onZipClick = (event, href = null) => {
  if (!href) {
    event.preventDefault();
    href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    href = href.substring(startingPath, href.length);
  }

  if (href !== '/') {
    Axios({
      url: `/api/link/?file=${href}`,
      method: 'GET',
    })
      .then((response) => {
        const URL = response.data;
        const win = window.open(URL, '_blank');

        if (win) {
          win.focus();
          win.onblur = function () {
            win.close();
          };
        }
      })
      .catch((error) => {});
  }
};

const onDownloadFile = (file_path) => {
  if (file_path !== '/') {
    Axios({
      url: `/api/link/?file=/${file_path}`,
      method: 'GET',
    })
      .then((response) => {
        const URL = response.data;
        const win = window.open(URL, '_blank');

        if (win) {
          win.focus();
          win.onblur = function () {
            win.close();
          };
        }
      })
      .catch((error) => {});
  }
};

export { getTempLink, onZipClick, onDownloadFile, getIteroFilesTempLinks };
