// External
import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal
import { modalClear, removeCaseIdInitialNumber, setTokenHeader, textFieldCheck, convertDate } from '../../../common/functions';
import { isTodayPastDate } from '../../../common/date';
import Modal from '../../../components/modal/modal';
import { handleHttpRequestError } from '../../../common/error';
import { isSmileDesignStage, isPostApprovalStage } from '../../../common/case/case_status';
import ProcessTracker from '../../../components/workflow/process_tracker';
import SecurityClearance from '../../../components/security/security_clearance';
import { userHasPermission, EUserPermission } from '../../../common/permission';
import {
  clearZipStatus,
  downloadZip,
  getCaseLastStatus,
  getLastAssignedTo,
  getPDFNameTemplate,
  onReloadPage,
  getInBraceIfsRequirement,
  isCaseBlocked,
  isProviderEdited,
} from '../../../common/helpers';
import Uploader from '../../../components/modal/uploader';
import { onZipClick } from '../../../common/dropbox';
import { CaseStatus, AdjustedSetupStatus, SetupStatus } from '../../../common/case/case_details.constants';

// Sass
import './setup_container.scss';

// Redux
import { setProductionTxGuideTabConfig } from '../../../redux/actions/bpp/production_tx_guide/production_tx_guide';

class SetupContainer extends Component {
  constructor(props) {
    super(props);
    this.BUSINESS_ROLE = 1;

    this.state = {
      assignable: false,
      assign_to_options: [],
      disable_undo_button: false,
      edit_status_comment: false,
      editable_status_comment: '',
      permission_list: [],
      setup_assigned_to: [
        {
          label: 'Unassigned',
          value: '',
        },
      ],
      setupPath: '',
      showSetupDeletionModal: false,
      status_comment: '',
      comment_load_attempted: false,
      assignee_load_transition_attempted: false,
      showIprModal: false,
      iprIndex: 0,
      showAddAdjustedSetupModal: false,
      showConvertAdjustedSetupModal: false,
      showRemoveSetupZipModal: false,
      showCompleteAdjustedSetupModal: false,
      showIncompleteAdjustedSetupModal: false,
      showRemoveConvertedAdjustedSetupModal: false,
      disableRetryAdjustedSetupButton: false,
      disableConversionButton: false,
      doctorSmileDesignPreferences: {},
      inbrace_ifs_required: getInBraceIfsRequirement(this.props.case_details, this.props.case_id),
      provider_edit: isProviderEdited(this.props.case_details, this.props.case_id),
      forceHideProcessTracker: false,
    };
    this.onViewIprClick.bind(this);
    this.onShowIprModalDismiss.bind(this);
    this.handleAssignTo = this.handleAssignTo.bind(this);
  }

  getSelectedSetupByCaseId(case_details, case_id) {
    let setup_process = this.props.case_files?.setup_process ?? [];
    return setup_process;
  }

  getSetupUndoButton(cases) {
    const case_files = this.props.case_files;
    if (
      (cases.status_code === 'STATUS_DOCTOR_APPROVAL' || cases.status_code === 'STATUS_INBRACE_SETUP') &&
      case_files.setup_process.length > 0 &&
      case_files.setup_process[0].files.id &&
      cases.status_code !== 'STATUS_MANUFACTURE' &&
      case_files.setup_process.length > 0 &&
      case_files.setup_process[0].files.id &&
      cases.status_code !== 'STATUS_SHIP' &&
      this.props.case_details.role === 'Admin'
    ) {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoSetupFiles} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    } else if (
      cases.status_code === 'STATUS_INBRACE_SETUP' &&
      case_files.setup_process.length === 1 &&
      !case_files.setup_process[0].files.id &&
      this.props.case_details.role === 'Admin'
    ) {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoFilesEnd} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    }
  }

  getSetupZipButton(cases) {
    if (cases.manufacturing_process && cases.manufacturing_process.log.length > 0) {
      return (
        <span className="download-zip btn-light-link" onClick={this.onZipSetup}>
          <i className="fa fa-file-archive-o download-zip-icon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
        </span>
      );
    }
  }

  getConvertedAdjustedSetupZipButton(cases) {
    if (cases.adjusted_setup_process && cases.adjusted_setup_process.log.length > 0) {
      return (
        <span className="download-zip btn-light-link" onClick={this.onGetAdjustedSetupZip}>
          <i className="fa fa-file-archive-o download-zip-icon" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
        </span>
      );
    }
  }

  getAdjustedSetupButton(cases) {
    if (cases.manufacturing_process && cases.manufacturing_process.log.length > 0) {
      return (
        <span className="upload-adjusted-setup btn-light-link" onClick={this.onAddAdjustedSetupClick}>
          <i
            className="fa fa-upload upload-adjusted-setup-icon"
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="top"
            title="Upload Adjusted Smile Design"
          />
        </span>
      );
    }
  }

  getUploadDataPath(href) {
    const starting_path = href.indexOf('/', 0) === 0 ? 1 : 0;
    return href.substring(starting_path, href.length);
  }

  handleAssignTo = (setup_assigned_to) => {
    if (setup_assigned_to) {
      this.setState({ setup_assigned_to: [setup_assigned_to] });
      this.updateCaseAssignment(setup_assigned_to.value);
    }
  };
  onViewIprClick = (event) => {
    const iprIndex = event.target.dataset.iprindex;
    this.setState({ showIprModal: true, iprIndex: iprIndex });
  };

  onShowIprModalDismiss = (event) => {
    this.setState({ showIprModal: !this.state.showIprModal });
  };

  initialize = () => {
    this.loadAssignToOptions();
    this.loadComment();
    this.loadSmileDesignPreferences();
  };

  loadAssignToOptions = () => {
    try {
      if (this.state.is_mounted) {
        var that = this;

        Axios.get('/apiV2/permission').then(function (res) {
          const permission_list = res.data.permission_list_raw;

          if (permission_list) {
            that.setState({ permission_list: permission_list, assignable: permission_list.includes(EUserPermission.CaseDetailsView) });
          }
        });

        Axios.get('/apiV2/processtracker', {
          params: { action: 'get_users_group_list', group: 'Digiset' },
        })
          .then(function (response) {
            const result = response.data.data.task_results['get_users_group_list'];
            let assign_to_options = [];

            const mapAssignToOptions = (userData) => {
              return {
                label: `${userData['user__first_name']} ${userData['user__last_name']}`,
                value: userData['user__id'],
              };
            };

            assign_to_options = result.map(mapAssignToOptions);
            assign_to_options = _.orderBy(assign_to_options, ['label']);
            assign_to_options.unshift({
              label: 'Unassigned',
              value: '',
            });

            if (assign_to_options && assign_to_options.length > 0) {
              that.setState({ assign_to_options: assign_to_options });
            }
          })
          .catch(function (err) {
            console.error(`Error getting job role users:`, err);
          });

        this.setAssignedUser();
      }
    } catch (error) {
      console.log('Error loading assign to options', Error);
    }
  };

  setAssignedUser = () => {
    let that = this;

    Axios.get('/apiV2/processtracker', {
      params: { action: 'get_assigned_user', case_id: this.props.case_id },
    })
      .then(function (response) {
        const result = response.data.data.task_results['get_assigned_user'];

        if (result) {
          const mapSetupAssignToUser = (userData) => {
            if (userData['assignment__first_name'] === null) {
              return {
                label: 'Unassigned',
                value: '',
              };
            } else {
              return {
                label: `${userData['assignment__first_name']} ${userData['assignment__last_name']}`,
                value: userData['assignment_id'],
              };
            }
          };

          let setup_assigned_to = result.map(mapSetupAssignToUser)[0];

          if (mapSetupAssignToUser && mapSetupAssignToUser.length > 0 && setup_assigned_to) {
            that.setState({ setup_assigned_to: [setup_assigned_to] });
          }
        }
      })
      .catch(function (err) {
        console.error(`Error retrieving assigned user of case:`, err);
      });
  };

  iprModal = () => {
    const header_text = `IPR Form - ${removeCaseIdInitialNumber(this.props.case_id)}`;
    const original_filename = getPDFNameTemplate(this.props.case_id, this.props.patient_info, '3433-01_Recommended_IPR.pdf');

    return (
      <Modal
        theme="bpp"
        preset="pdf_viewer"
        header_text={header_text}
        modal_size_class="modal-lg modal-lg-pdf"
        modal_body_class="modal-pdf"
        pdf_type="ipr"
        iprIndex={this.state.iprIndex}
        case_id={this.props.case_id}
        onCloseButtonClick={this.onShowIprModalDismiss}
        original_filename={original_filename}
      />
    );
  };

  loadComment = () => {
    const current_status = this.props.get_case_status;
    const last_stage = this.props.last_stage;

    if (
      (current_status === 'Setup Ready for Upload' ||
        current_status === 'Setup Ready for Release' ||
        last_stage === 'Setup Ready for Upload' ||
        last_stage === 'Setup Ready for Release') &&
      this.props.cases.case_statuses &&
      this.props.cases.case_statuses.length > 0
    ) {
      const case_statuses = this.props.cases.case_statuses.filter((statuses) => {
        return (
          statuses.case_status !== 'Uncancelled' &&
          statuses.case_status !== 'Cancelled' &&
          statuses.case_status !== 'STATUS_CANCEL' &&
          statuses.case_status !== 'STATUS_UNCANCEL' &&
          statuses.case_status !== 'Hold' &&
          statuses.case_status !== 'Unhold' &&
          statuses.case_status !== 'STATUS_HOLD' &&
          statuses.case_status !== 'STATUS_UNHOLD' &&
          statuses.case_status !== 'Doctor Provide Clarification' &&
          statuses.case_status !== 'Doctor Clarification Resolved'
        );
      });

      case_statuses.forEach((case_status) => {
        if (case_status.case_status === current_status || case_status.case_status === last_stage) {
          const comment = case_status.status_comment === null ? '' : case_status.status_comment;
          this.setState({
            status_comment: comment,
            editable_status_comment: comment,
          });
        }
      });
    }
  };

  loadSmileDesignPreferences = () => {
    Axios.get(`/apiv3/smiledesignpreference/${this.props.case_details.doctor.id}`).then((response) => {
      this.setState({
        doctorSmileDesignPreferences: response.data,
      });
    });
  };

  onCommentSave = (event) => {
    event.preventDefault();

    setTokenHeader();
    const that = this;
    const comment_type = this.props.cases.status_code === 'Setup Ready for Release' ? 'setup_release_comment' : 'setup_upload_comment';
    let data = { status_comment: this.state.editable_status_comment };

    Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${comment_type}`, data)
      .then((response) => {
        that.setState({
          status_comment: this.state.editable_status_comment,
          edit_status_comment: false,
        });
      })
      .catch((error) => {
        handleHttpRequestError(error, that);
      });
  };

  onCommentCancel = (event) => {
    event.preventDefault();

    const prev_comment = this.state.status_comment ? this.state.status_comment : '';

    this.setState({
      edit_status_comment: false,
      editable_status_comment: prev_comment,
    });
  };

  onProceedToSetupReviewClick = (event) => {
    setTokenHeader();
    const that = this;

    return Axios.post(`/apiv3/caseaction/setup/${this.props.case_id}?action=proceed_to_setup_review`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();
    const file_type = event.currentTarget.dataset.type;
    const that = this;

    if (file_type === 'setup' || file_type === 'final_design') {
      let form_data = new FormData();

      form_data.append(
        'files',
        JSON.stringify([
          {
            file_type: file_type,
            folder: file_type,
            incomplete_id: '',
            location: 'delete',
            mime_type: 'application/x-zip-compressed',
            original_filename: '',
            upload_data: this.getUploadDataPath(event.currentTarget.dataset.href),
          },
        ])
      );

      Axios.post(`/apiV2/casefiles/${this.props.case_id}/remove`, form_data)
        .then((res) => {
          that.setState({
            showSetupDeletionModal: false,
          });

          const selected_setup = that.getSelectedSetupByCaseId(res.data, that.props.case_id);

          that.props.reload_information({
            case_details: res.data,
            selected_setup: selected_setup,
          });

          document.body.className = document.body.className.replace('modal-open', '');
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);

          if (err && err.response && err.response.status === 409) {
            that.setState({
              showSetupDeletionModal: false,
              refresh: true,
            });
          }
        });
    }
  };

  /**
   * Determines if the setup has a model change.
   * @param {Object} setup - The setup object.
   * @param {Object[]} otherSetups - The other setups.
   * @returns {Object} - The current and next setup model change.
   */
  getHasModelChange = (setup, otherSetups) => {
    let currentHasModelChange = false;
    let nextHaveModelChange = false;
    let firstLogOfNextSetupHasModelChange = false;

    if (setup['log'] && setup['log'].length) {
      currentHasModelChange = setup['log'].some((log) => log['has_model_change']);
    }
    if (otherSetups && otherSetups.length) {
      const nextSetup = otherSetups.find((otherSetup) => otherSetup['index'] === setup['index'] + 1);
      if (nextSetup && nextSetup['log'] && nextSetup['log'].length) {
        nextHaveModelChange = nextSetup['log'].some((log) => log['has_model_change']);
        if (nextSetup['log'].length) {
          firstLogOfNextSetupHasModelChange = nextSetup['log'][0]['has_model_change'];
        }
      }
    }

    return {
      currentHasModelChange,
      nextHaveModelChange,
      firstLogOfNextSetupHasModelChange,
      currentHasModelChangeOrNextHaveModelChange: currentHasModelChange || nextHaveModelChange,
    };
  };

  /**
   * Handles opening the setup viewer window
   * @function
   * @param {Number} setup - The setup
   * @param {String} overwriteStatus - The status to overwrite the setup status with
   */
  onSetupClick = (setup, overwriteStatus) => {
    const { case_id } = this.props;
    const { is_wasm_viewer_enabled, setup_process } = this.props.cases;
    const { index, provider_edit, is_inbrace_ifs_setup } = setup;
    const setupStatus = overwriteStatus ?? setup.setup_status;
    const otherSetups = setup_process?.filter((s) => s.setup_num !== setup.setup_num) ?? [];
    const hasModelChange = this.getHasModelChange(setup, otherSetups);
    const setup_index = setupStatus === 'InBrace IFS' ? `${index} (${setupStatus})` : index;
    const revisionSetupStatus = ['Internally Approved', 'Internally Rejected', 'InBrace IFS'];
    const isIFSWithProviderEdit = is_inbrace_ifs_setup && provider_edit;
    const showRevisionSetup =
      (revisionSetupStatus.includes(setupStatus) && provider_edit) ||
      (SetupStatus.Rejected.is(setupStatus) && hasModelChange.nextHaveModelChange) ||
      (SetupStatus.Revised.is(setupStatus) && isIFSWithProviderEdit);
    const selectedTreatmentName = `Smile Design ${index}${showRevisionSetup ? ' - Revision' : ''}${setupStatus === 'InBrace IFS' ? ' (IFS)' : ''}`;
    const url = is_wasm_viewer_enabled ? `/smile_design/${case_id}?selected_treatment_name=${selectedTreatmentName}` : `/setup/${case_id}/${setup_index}`;

    window.open(url);
  };

  onSetupCommentsClick = (event) => {
    this.setState({ showSetupCommentsModal: true });
  };

  onSetupCommentsDismiss = (event) => {
    this.setState({
      showSetupCommentsModal: false,
    });
    modalClear();
  };

  onSetupDeletionModalClick = (event) => {
    let path = event.currentTarget.dataset.path;
    this.setState({ showSetupDeletionModal: true, setupPath: path });
  };

  onSetupDeletionModalDismiss = (event) => {
    this.setState({ showSetupDeletionModal: false });
    modalClear();
  };

  onAddAdjustedSetupModalDismiss = (event) => {
    this.setState({ showAddAdjustedSetupModal: false });
    modalClear();
  };

  onAddAdjustedSetupClick = (event) => {
    this.setState({ showAddAdjustedSetupModal: true });
  };

  onAddAdjustedSetupModalProceed = (event) => {
    setTokenHeader();
    const that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/start_process`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            showAddAdjustedSetupModal: false,
          });
          modalClear();
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onCancelAdjustedSetupClick = (event) => {
    this.setState({ showCancelAdjustedSetupModal: true });
  };

  onCancelAdjustedSetupModalDismiss = (event) => {
    this.setState({ showCancelAdjustedSetupModal: false });
    modalClear();
  };

  onCancelAdjustedSetupModalProceed = (event) => {
    setTokenHeader();

    this.setState({
      disableConversionButton: false,
    });

    const that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/cancel_process`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            showCancelAdjustedSetupModal: false,
          });
          modalClear();
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onConvertAdjustedSetupClick = (event) => {
    this.setState({ showConvertAdjustedSetupModal: true });
  };

  onConvertAdjustedSetupModalDismiss = (event) => {
    this.setState({ showConvertAdjustedSetupModal: false });
    modalClear();
  };

  onRemoveAdjustedSetupZipModalDismiss = (event) => {
    this.setState({ showRemoveSetupZipModal: false });
    modalClear();
  };

  onConvertAdjustedSetupModalProceed = (event) => {
    setTokenHeader();
    this.setState({
      showConvertAdjustedSetupModal: false,
      disableConversionButton: true,
      disableRetryAdjustedSetupButton: false,
    });
    modalClear();

    const that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/convert_setup`)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onCompleteAdjustedSetup = (event) => {
    this.setState({
      showCompleteAdjustedSetupModal: true,
    });
  };

  onCompleteAdjustedSetupDismiss = (event) => {
    this.setState({
      showCompleteAdjustedSetupModal: false,
    });
    modalClear();
  };

  onCompleteAdjustedSetupModalProceed = (event) => {
    this.setState({
      showCompleteAdjustedSetupModal: false,
    });
    modalClear();

    let that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/conversion_success`)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onIncompleteAdjustedSetup = (event) => {
    this.setState({
      showIncompleteAdjustedSetupModal: true,
    });
  };

  onIncompleteAdjustedSetupDismiss = (event) => {
    this.setState({
      showIncompleteAdjustedSetupModal: false,
    });
    modalClear();
  };

  onIncompleteAdjustedSetupModalProceed = (event) => {
    this.setState({
      showIncompleteAdjustedSetupModal: false,
    });
    modalClear();

    let that = this;

    let request = {
      status_comment: 'System Admin Failed Adjusted Smile Design Conversion',
    };

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/conversion_failed`, request)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onRemoveConvertedAdjustedSetup = (event) => {
    this.setState({
      showRemoveConvertedAdjustedSetupModal: true,
    });
  };

  onRemoveConvertedAdjustedSetupDismiss = (event) => {
    this.setState({
      showRemoveConvertedAdjustedSetupModal: false,
    });
    modalClear();
  };

  onRemoveConvertedAdjustedSetupModalProceed = (event) => {
    this.setState({
      showRemoveConvertedAdjustedSetupModal: false,
      disableConversionButton: false,
    });
    modalClear();

    const setup_number = 'SETUP' + this.props.case_files.setup_process.length;

    const request = {
      setup_number: setup_number,
    };

    let that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/remove_setup`, request)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onRetryAdjustedSetupClick = (event) => {
    this.setState({
      disableRetryAdjustedSetupButton: true,
      disableConversionButton: false,
    });
    let that = this;

    return Axios.post(`/apiV2/adjustedsetupaction/${that.props.case_id}/conversion_retry`)
      .then(function (res) {
        if (res && res.data) {
          that.props.reload_information();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupConversionUploadClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupConversionUploadClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupReviewApprovedClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupReviewApprovedClick');
    const case_id = that.props.case_id;

    return Axios.post(`/apiv3/caseaction/setup/${case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          if (action === 'provider_edit_review_approved')
            Axios.post(`/api/email/?slug=provider-edit-approval&caseId=${case_id}&method=standard&provider=${window.location.origin}`);

          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, case_id),
            inbrace_ifs_required: getInBraceIfsRequirement(res.data, case_id),
          });
          that.setAssignedUser();
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupReviewRevisedClick = (event) => {
    setTokenHeader();
    const that = this;
    let form_data = new FormData();
    form_data.append('status_comment', this.state.editable_status_comment);
    const action = 'setup_review_revised';

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`, form_data)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
          for (const cases of res.data.cases) {
            if (cases.case_id === that.props.case_id) {
              that.props.setProductionTxGuideTabConfig('STATUS_INBRACE_SETUP', '', cases, cases.skip_production_tx_guide);
            }
          }
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupConversionConfirmClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupConversionConfirmClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupConversionFailedClick = (event) => {
    setTokenHeader();
    const that = this;
    let form_data = new FormData();
    form_data.append('status_comment', 'System Admin Failed Smile Design Conversion');
    const action = that.getSetupActionType('onSetupConversionFailedClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`, form_data)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupReleaseConfirmClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupReleaseConfirmClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          let selected_setup = that.getSelectedSetupByCaseId(res.data, that.props.case_id);
          that.setState({
            case_details: res.data,
            selected_setup: selected_setup,
            inbrace_ifs_required: getInBraceIfsRequirement(res.data, that.props.case_id),
          });
          if (action === 'release') {
            Axios.post(
              `/api/email/?slug=ss-cases-waiting-for-approval-first-notice-1&caseId=${removeCaseIdInitialNumber(that.props.case_id)}&method=standard&provider=${
                window.location.origin
              }&setupNumber=${selected_setup.length}&doctorId=${that.props.case_details.doctor.id}`
            );
          }
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupDoNotReleaseConfirmClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupDoNotReleaseConfirmClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupUploadConfirmClick = (event) => {
    setTokenHeader();
    const that = this;
    const action = that.getSetupActionType('onSetupUploadConfirmClick');

    return Axios.post(`/apiv3/caseaction/setup/${that.props.case_id}?action=${action}`)
      .then(function (res) {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onSetupReviseClick = (comment) => {
    setTokenHeader();
    const that = this;
    let fdata = new FormData();
    fdata.append('status_comment', comment);

    return Axios.post(`/apiV2/caseaction/${that.props.case_id}/revision`, fdata)
      .then((res) => {
        if (res && res.data) {
          that.setState({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });

          for (const cases of res.data.cases) {
            if (cases.case_id === that.props.case_id) {
              that.props.setProductionTxGuideTabConfig('STATUS_INBRACE_SETUP', '', cases, cases.skip_production_tx_guide);
            }
          }
        }
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          return err;
        }
      });
  };

  onFocusComment = (event) => {
    this.setState({
      edit_status_comment: true,
    });
  };

  onRtCommentChange = (value) => {
    const editable_status_comment_value = value;

    this.setState({
      editable_status_comment: editable_status_comment_value,
    });
  };

  onStatusCommentChange = (event) => {
    const editable_status_comment_value = textFieldCheck(event.target.value);

    this.setState({
      editable_status_comment: editable_status_comment_value,
    });
  };

  onUpload = (data) => {
    const that = this;
    if (data && data[0] && (data[0].folder === 'setup' || data[0].folder === 'final_design')) {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      Axios.post(`/apiV2/casefiles/${this.props.case_id}/add`, form_data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
            selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };

  onUploadAdjustedSetup = (data) => {
    this.props.isUploading(true);
    const that = this;

    if (data && data[0] && data[0].folder === 'adjusted_setup') {
      let form_data = new FormData();
      form_data.append('files', JSON.stringify(data));

      Axios.post(`/apiV2/casefiles/${this.props.case_id}/add`, form_data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  };
  onRemoveAdjustedSetupZipClick = (event) => {
    const that = this;
    setTokenHeader();
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');

    if (startingPath >= 0 && endPath >= 0) {
      that.setState({
        file_removing: true,
        showRemoveSetupZipModal: true,
      });
    }
  };

  onRemoveAdjustedSetupZipButtonClick = (event) => {
    const that = this;
    let form_data = new FormData();
    setTokenHeader();

    that.setState({
      file_removing: true,
      showRemoveSetupZipModal: false,
    });

    Axios.post(`/apiV2/adjustedsetupaction/${this.props.case_id}/remove_zip`, form_data)
      .then(function (res) {
        that.setState({
          file_removing: false,
          showRemoveSetupZipModal: false,
        });
        modalClear();
        that.props.reload_information();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  onUndoFilesEnd = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.props.case_id}/reset_to_file_end`)
      .then(function (res) {
        that.setState({
          disable_undo_button: false,
        });

        that.props.reload_information({ case_details: res.data });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  onUndoSetupFiles = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.props.case_id}/reset_to_setup`)
      .then(function (res) {
        that.setState({
          disable_undo_button: false,
        });

        that.props.reload_information({
          case_details: res.data,
          selected_setup: that.getSelectedSetupByCaseId(res.data, that.props.case_id),
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  onZipSetup = (event) => {
    const timestamp = Moment().format('YYYY-MM-DD_hh-mm');
    const filename = `${removeCaseIdInitialNumber(this.props.case_id)}_Approved_Smile_Design_${timestamp}.zip`;
    const that = this;
    const bundle_type = this.state.inbrace_ifs_required || this.state.provider_edit ? 'final_design' : 'setup';

    if (this.state.status_type !== 'zip') {
      this.setState({ status_type: 'zip', status_message: `Preparing Zip: ${removeCaseIdInitialNumber(this.props.case_id)}` });

      Axios({
        url: `/apiV2/zip/${this.props.case_id}/${bundle_type}`,
        method: 'GET',
      })
        .then((response) => {
          if (response.data.path.includes('.zip')) {
            onZipClick(event, response.data.path);
            clearZipStatus(that);
          } else {
            downloadZip(that, response, filename);
          }
        })
        .catch(function (err) {
          clearZipStatus(that);
        });
    }
  };

  onGetAdjustedSetupZip = (event) => {
    const timestamp = Moment().format('YYYY-MM-DD_hh-mm');
    const filename = `${removeCaseIdInitialNumber(this.props.case_id)}_Smile_Design_Adjusted_${timestamp}.zip`;
    const that = this;

    if (this.state.status_type !== 'zip') {
      this.setState({ status_type: 'zip', status_message: `Preparing Zip: ${removeCaseIdInitialNumber(this.props.case_id)}` });

      Axios({
        url: `/apiV2/zip/${this.props.case_id}/adjusted_setup`,
        method: 'GET',
      })
        .then((response) => {
          if (response.data.path.includes('.zip')) {
            onZipClick(event, response.data.path);
            clearZipStatus(that);
          } else {
            downloadZip(that, response, filename);
          }
        })
        .catch(function (err) {
          clearZipStatus(that);
        });
    }
  };

  updateCaseAssignment = (assigned_user) => {
    var that = this;
    return Axios.post('/apiV2/processtracker', {
      actions: ['update_case_assignment'],
      update_case_assignment: { case_id: that.props.case_id, user_id: assigned_user },
    }).catch(function (err) {
      console.error(`Error Updating Case Assignment for Case: ${that.props.case_id}`, err);
    });
  };

  adjustedSetupInProgress = (status) => {
    if (
      status === AdjustedSetupStatus.Initiated ||
      status === AdjustedSetupStatus.Uploaded ||
      status === AdjustedSetupStatus.Removed ||
      status === AdjustedSetupStatus.Converting ||
      status === AdjustedSetupStatus.Failed ||
      status === AdjustedSetupStatus.Success ||
      status === AdjustedSetupStatus.Reprocess
    ) {
      return true;
    }
    return false;
  };
  /**
   * Displays the setup details based on the provided setup information.
   *
   * @param {Object} setup - The setup object containing setup details.
   * @returns {JSX.Element|null} Returns JSX elements representing the setup details or null if the setup process is not completed.
   */
  showSetupByLog = (setup) => {
    const iprStatuses = ['Released', 'Approved', 'Internally Approved'];
    const isInAdjustedProcess = this.adjustedSetupInProgress(this.props.cases.adjusted_setup_process.status);
    const isLastSetup = setup.setup_num === this.props.case_files?.setup_process.length;
    const displayDownloadAndAdjBtns = isLastSetup && !isInAdjustedProcess;
    const isIFSWithProviderEdit = setup.is_inbrace_ifs_setup && setup.provider_edit;
    const hasIPRChart = iprStatuses.includes(setup.setup_status) || (isIFSWithProviderEdit && isLastSetup);

    let isNextSetupApproved = false;
    const nextSetup = this.props.case_files?.setup_process.filter((_nextSetup) => _nextSetup.setup_num === setup.setup_num + 1).pop();
    const nextSetupContainsFinalDesignUpload = !!nextSetup ? nextSetup.log.filter((log) => (log.status_code = CaseStatus.IFSUpload)).length > 0 : false;
    const nextSetupStatusIsApproved = !!nextSetup ? SetupStatus.Approved.is(nextSetup.setup_status) : false;
    if (nextSetupStatusIsApproved || nextSetupContainsFinalDesignUpload) {
      isNextSetupApproved = true;
    }

    const nextSetupIsInbraceIfs = !!nextSetup ? nextSetup.is_inbrace_ifs_setup : false;
    const isIfsSetup = nextSetupIsInbraceIfs || setup.is_inbrace_ifs_setup;

    let finalStatus = setup.setup_status;
    if (setup.setup_status === 'Revised' && isIfsSetup && isNextSetupApproved) {
      finalStatus = 'Internally Approved';
    }

    if (setup.process_completed) {
      const setupMaskText = setup.setup_status !== finalStatus ? this.getSetupMaskText(setup, finalStatus) : this.getSetupMaskText(setup);
      const setupClickHandler = () => (setup.setup_status !== finalStatus ? this.onSetupClick(setup, finalStatus) : this.onSetupClick(setup));
      const elementId = `smile-design-${setup.index}-${setup.setup_num}`;

      return (
        <span key={elementId} style={{ whiteSpace: 'nowrap' }}>
          <div data-testid={elementId} className="business-dark-setup-window" style={{ whiteSpace: 'break-spaces' }}>
            <div className="setup-text">
              <span className="bold-text">Smile Design {setup.index}</span>
              <span>: {finalStatus}</span>
            </div>
            <div style={{ display: 'inline-flex' }}>
              <div className="business-dark-setup-window" onClick={setupClickHandler}>
                <img className="img-preview img-sm pointer" src={process.env.PUBLIC_URL + '/static/img/setup.jpg'} alt={'setup' + setup.index} />
                {setupMaskText && <div className="setup_img_mask">{setupMaskText}</div>}
              </div>
              {displayDownloadAndAdjBtns ? this.displayDownloadAndAdjBtns() : ''}
            </div>
            {setup?.ipr_file_present && hasIPRChart && (
              <div data-iprindex={setup.setup_num - 1} className="underline-text viewable-text center-text" onClick={this.onViewIprClick}>
                View IPR Chart
              </div>
            )}
          </div>
        </span>
      );
    }
  };
  /**
   * Determines the description text based on the provided setup status and other conditions.
   * @param {Object} setup - The setup object containing setup information.
   * @param {string} overwriteStatus - The status to overwrite the setup status.
   * @returns {string|JSX.Element} - Returns the description text or JSX element based on the setup status.
   */
  getSetupMaskText = (setup, overwriteStatus) => {
    const { case_files, cases } = this.props;
    const otherSetups = cases.setup_process?.filter((s) => s.setup_num !== setup.setup_num) ?? [];
    const setupStatus = overwriteStatus ?? setup.setup_status;
    const latestSetup = case_files?.setup_process[case_files?.setup_process.length - 1];
    const isLastSetup = setup === latestSetup;
    const inPostApprovalStage = isPostApprovalStage(this.props.get_case_status, false) && isLastSetup;
    const hasModelChange = this.getHasModelChange(setup, otherSetups);
    const currentHasProviderEditOrNextHasModelChange = setup.provider_edit || hasModelChange.firstLogOfNextSetupHasModelChange;

    const nextSetupArr = case_files?.setup_process.filter((_nextSetup) => _nextSetup.setup_num === setup.setup_num + 1) ?? [];
    const nextSetup = nextSetupArr.length > 0 ? nextSetupArr[0] : null;

    const nextSetupIsInbraceIfs = !!nextSetup ? nextSetup.is_inbrace_ifs_setup : false;
    const currentOrNextSetupIsIfs = nextSetupIsInbraceIfs || setup.is_inbrace_ifs_setup;

    let currentSetupHasIfsRequiredLog = setup.is_inbrace_ifs_setup;
    const currentLog = setup.log.find((log) => log.current);
    if (currentLog && currentLog.ifs_required !== null) {
      currentSetupHasIfsRequiredLog = currentLog.ifs_required;
    }

    let descText = '';

    if (inPostApprovalStage) {
      return descText;
    }

    const providerEditText = 'Provider Edit';
    const inbraceIfsRequiredText = 'InBrace IFS Required';
    const inbraceIfsAppliedText = 'InBrace IFS Applied';
    const noInbraceIfsRequiredText = 'No InBrace IFS Required';
    const inbraceIfsAppliedWithProviderEditText = (
      <span>
        InBrace IFS Applied <br /> (Provider Edit)
      </span>
    );
    const inbraceIfsRequiredWithProviderEditText = (
      <span>
        InBrace IFS Required <br /> (Provider Edit)
      </span>
    );

    if (SetupStatus.Released.is(setupStatus)) {
      descText = setup.is_inbrace_ifs_setup ? inbraceIfsRequiredText : '';
    } else if (SetupStatus.Revised.is(setupStatus)) {
      if ((isLastSetup && currentSetupHasIfsRequiredLog) || (!isLastSetup && setup.is_inbrace_ifs_setup)) {
        descText = inbraceIfsRequiredText;
      } else {
        descText = '';
      }
    } else if (SetupStatus.Rejected.is(setupStatus)) {
      if (setup.is_inbrace_ifs_setup && currentHasProviderEditOrNextHasModelChange) {
        descText = inbraceIfsRequiredWithProviderEditText;
      } else if (setup.is_inbrace_ifs_setup) {
        descText = inbraceIfsRequiredText;
      } else if (currentHasProviderEditOrNextHasModelChange) {
        descText = providerEditText;
      } else {
        descText = '';
      }
    } else if (SetupStatus.Approved.is(setupStatus)) {
      descText = setup.is_inbrace_ifs_setup ? inbraceIfsRequiredText : setup.provider_edit ? providerEditText : '';
    } else if (setupStatus === 'InBrace IFS') {
      descText = setup.provider_edit ? inbraceIfsAppliedWithProviderEditText : inbraceIfsAppliedText;
    } else if (SetupStatus.InternallyRejected.is(setupStatus)) {
      descText = setup.is_inbrace_ifs_setup ? inbraceIfsRequiredWithProviderEditText : providerEditText;
    } else if (SetupStatus.InternallyApproved.is(setupStatus)) {
      if (currentOrNextSetupIsIfs && currentHasProviderEditOrNextHasModelChange) {
        descText = inbraceIfsRequiredWithProviderEditText;
      } else if (currentOrNextSetupIsIfs) {
        descText = inbraceIfsRequiredText;
      } else if (currentHasProviderEditOrNextHasModelChange) {
        descText = providerEditText;
      } else {
        descText = '';
      }
    } else if (!isLastSetup && !setup.is_inbrace_ifs_setup) {
      descText = setup.provider_edit ? providerEditText : noInbraceIfsRequiredText;
    }

    return descText;
  };

  displayDownloadAndAdjBtns = () => {
    const isStageI = this.props.get_case_status === CaseStatus.ApplianceDesign && this.props.cad_status === CaseStatus.StageI;
    return (
      <span className="business-dark-setup-window" style={{ width: 'auto' }}>
        <SecurityClearance mode="ALL" permission_list={['SETUP_FILES_DOWNLOAD']}>
          {this.getSetupZipButton(this.props.cases)}
        </SecurityClearance>
        <SecurityClearance mode="ALL" permission_list={[EUserPermission.AdjSetupTrigger]}>
          {!isCaseBlocked(this.props.cases.status_code) && isStageI && this.getAdjustedSetupButton(this.props.cases)}
        </SecurityClearance>
      </span>
    );
  };

  displayAdjustedSetup = (setup) => {
    const adjustedSetupStatus = this.props.cases.adjusted_setup_process.status;
    const isLastSetup = setup.setup_num === this.props.case_files?.setup_process.length;
    const isInManufactureProcess = (this.props.cases.manufacturing_process.log.length > 0 && isLastSetup) || setup.setup_status == 'InBrace IFS';
    const isInAdjustedProcess = this.adjustedSetupInProgress(adjustedSetupStatus);
    const isStageI = this.props.get_case_status === CaseStatus.ApplianceDesign && this.props.cad_status === CaseStatus.StageI;

    if (isInManufactureProcess)
      if (isInAdjustedProcess)
        return (
          <React.Fragment>
            <React.Fragment>
              <div className="business-dark-setup-window">
                <div className="setup-text adjusted-setup-text">
                  <span className="bold-text">
                    Adjusted Smile
                    <br /> Design
                  </span>
                  {adjustedSetupStatus !== AdjustedSetupStatus.Converting &&
                  adjustedSetupStatus !== AdjustedSetupStatus.Success &&
                  userHasPermission(EUserPermission.AdjSetupCancel, this.props.user_roles_and_permissions.permissions) &&
                  !this.props.upload_in_progress ? (
                    <span className="pull-right">
                      <i className="fa fa-times-circle-o cancel-setup" aria-hidden="true" onClick={this.onCancelAdjustedSetupClick} />
                    </span>
                  ) : null}
                </div>
                {adjustedSetupStatus === AdjustedSetupStatus.Success ? (
                  <div style={{ display: 'inline-flex' }}>
                    <div className="business-dark-setup-window">
                      <img
                        className="img-preview img-sm"
                        src={process.env.PUBLIC_URL + '/static/img/setup-dark.jpg'}
                        onClick={this.onAdjustedSetupClick}
                        alt=""
                      />
                      <div className="conversion-success-message" onClick={this.onThumbnailClick}>
                        Conversion Successful
                      </div>
                      {userHasPermission(EUserPermission.AdjSetupRemove, this.props.user_roles_and_permissions.permissions) && isStageI ? (
                        <i className="fa fa-trash-o remove-setup rejected-text font-size-lg" aria-hidden="true" onClick={this.onRemoveConvertedAdjustedSetup} />
                      ) : null}
                    </div>
                    <SecurityClearance mode="ALL" permission_list={['SETUP_FILES_DOWNLOAD']}>
                      {this.getConvertedAdjustedSetupZipButton(this.props.cases)}
                    </SecurityClearance>
                  </div>
                ) : adjustedSetupStatus === AdjustedSetupStatus.Failed ? (
                  <React.Fragment>
                    <div className="adjusted-setup-conversion-failed">
                      <p className="failure-conversion-message">Conversion Failed</p>
                      <i
                        className="fa fa-exclamation-circle conversion-error-icon"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={this.props.cases.adjusted_setup_process.status_comment}
                      />
                    </div>
                    {userHasPermission(EUserPermission.AdjSetupConvertFail, this.props.user_roles_and_permissions.permissions) ? (
                      <div className="underline-text viewable-text center-text">
                        <button
                          type="button"
                          className="btn btn-light btn-adjusted-setup"
                          onClick={this.onRetryAdjustedSetupClick}
                          disabled={this.state.disableRetryAdjustedSetupButton}
                        >
                          Retry
                        </button>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : adjustedSetupStatus === AdjustedSetupStatus.Converting ? (
                  <div className="setup-upload-button">
                    <i className="fa fa-refresh rotate setup-conversion-icon" aria-hidden="true" />
                    <p className="converting-message">Converting...</p>
                  </div>
                ) : (
                  <React.Fragment>
                    {userHasPermission(EUserPermission.AdjSetupTrigger, this.props.user_roles_and_permissions.permissions) ? (
                      <Uploader
                        className="setup-upload-button"
                        id={this.props.case_id}
                        photoUpload={this.props.case_files?.adjusted_setup_process?.id ? [this.props.case_files.adjusted_setup_process] : []}
                        onUpload={this.onUploadAdjustedSetup}
                        onRemove={this.onRemoveAdjustedSetupZipClick}
                        isUploading={this.props.isUploading}
                        folder="adjusted_setup"
                        type="adjusted_setup"
                        loader_type="bpp"
                        location="complete"
                        remove_btn={userHasPermission(EUserPermission.AdjSetupTrigger, this.props.user_roles_and_permissions.permissions)}
                      />
                    ) : (
                      <div className="relative">
                        <img className="img-preview img-sm" src={process.env.PUBLIC_URL + '/static/img/setup-dark.jpg'} alt="setup dark" />
                        <div className="conversion-success-message">Conversion In Progress</div>
                      </div>
                    )}
                    {userHasPermission(EUserPermission.AdjSetupConvert, this.props.user_roles_and_permissions.permissions) ? (
                      <div className="button-centered">
                        <button
                          type="button"
                          className="btn btn-light btn-adjusted-setup"
                          onClick={this.onConvertAdjustedSetupClick}
                          disabled={
                            (adjustedSetupStatus !== AdjustedSetupStatus.Uploaded && adjustedSetupStatus !== AdjustedSetupStatus.Reprocess) ||
                            this.state.disableConversionButton
                          }
                        >
                          Convert
                        </button>
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
              </div>

              {adjustedSetupStatus === AdjustedSetupStatus.Converting ? (
                <div className="section-buttons">
                  {userHasPermission(EUserPermission.AdjSetupBypass, this.props.user_roles_and_permissions.permissions) ? (
                    <button type="button" className="btn btn-light btn-adjusted-setup" onClick={this.onCompleteAdjustedSetup}>
                      Complete
                    </button>
                  ) : null}
                  {userHasPermission(EUserPermission.AdjSetupBypass, this.props.user_roles_and_permissions.permissions) ? (
                    <button type="button" className="btn btn-light btn-adjusted-setup" onClick={this.onIncompleteAdjustedSetup}>
                      Incomplete
                    </button>
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
          </React.Fragment>
        );
  };

  /**
   * Sets the value of forceHideProcessTracker in the component's state.
   * @param {boolean} value - The new value for forceHideProcessTracker.
   */
  setForceHideProcessTracker = (value) => {
    this.setState({
      forceHideProcessTracker: value,
    });
  };

  /**
   * Reloads the information with updated state.
   * @param {object} updatedState - The updated state.
   * @param {function} callback - The callback function to be executed after reloading the information.
   * @param {boolean} [init=false] - Optional parameter indicating if it is an initial reload.
   * @param {object} [options={}] - Optional parameter containing additional options.
   * @param {boolean} [options.forceHideProcessTracker=false] - Optional parameter indicating if the process tracker should be hidden.
   */
  reloadInformation = (updatedState, callback, init = false, { forceHideProcessTracker = false } = {}) => {
    if (forceHideProcessTracker) this.setForceHideProcessTracker(true);
    const cb = () => {
      if (callback) {
        callback();
      }
      if (forceHideProcessTracker) this.setForceHideProcessTracker(false);
    };
    this.props.reload_information(updatedState, cb, init);
  };

  showProgressTracker = (setup_process, final_design_process) => {
    const setup_index = setup_process.length - 1;
    const setup = setup_process[setup_index];
    const last_status = getCaseLastStatus(true, this.props.case_details, this.props.case_id);
    const last_assigned_to = getLastAssignedTo(this.props.case_details, this.props.case_id);
    const has_post_approval_edit_permission = userHasPermission(EUserPermission.PostApprovalSmileDesign, this.props.user_roles_and_permissions.permissions);
    const has_post_approval_override_permission = userHasPermission(EUserPermission.PostApprovalBypass, this.props.user_roles_and_permissions.permissions);
    const assigned_to_text = this.getAssignedToText(setup);
    const otherSetups = this.props.cases.setup_process?.filter((s) => s.setup_num !== setup.setup_num) ?? [];
    const currentSetupHasProviderEdit = setup.provider_edit || this.getHasModelChange(setup, otherSetups).currentHasModelChange;
    if (isSmileDesignStage(this.props.cases.status_code)) {
      return (
        <div key={`setup_container_${setup_index}`}>
          {this.props.case_files.setup_process.length > 1 && setup.index === this.props.case_files.setup_process.length && <hr className="horizontal-ruler" />}
          {this.state.assign_to_options && this.state.assign_to_options.length > 0 ? (
            <div className="setup-assigned-to">
              <div className="setup-text">
                <span className="bold-text">{assigned_to_text}</span>
                {!this.props.disabled &&
                this.state.assignable &&
                userHasPermission(EUserPermission.SetupAssign, this.props.user_roles_and_permissions.permissions) ? (
                  <Select
                    name="setup_assignment"
                    className="assign-to-dropdown"
                    placeholder="Unassigned"
                    item="User"
                    value={this.state.setup_assigned_to}
                    options={this.state.assign_to_options}
                    autosize={false}
                    onChange={this.handleAssignTo}
                  />
                ) : this.state.setup_assigned_to && this.state.setup_assigned_to.length > 0 && this.state.setup_assigned_to[0].label ? (
                  this.state.setup_assigned_to[0].label
                ) : (
                  'Unassigned'
                )}
              </div>
            </div>
          ) : null}
          {this.state.setup_assigned_to &&
          this.state.setup_assigned_to.length > 0 &&
          this.state.setup_assigned_to[0].label &&
          this.state.setup_assigned_to[0].label !== 'Unassigned' ? (
            <ProcessTracker
              edit_status_comment={
                userHasPermission(EUserPermission.SetupCommentEdit, this.props.user_roles_and_permissions.permissions) ? this.state.edit_status_comment : null
              }
              editable_status_comment={this.state.editable_status_comment}
              hideRuler={true}
              key={`setup_process_tracker_${setup_index}`}
              process="setup"
              stage={this.props.get_case_status}
              case_id={this.props.case_id}
              project={this}
              setup_assigned_to={this.state.setup_assigned_to}
              log_index={`${setup_index}`}
              setup={setup}
              case_statuses={this.props.cases.case_statuses}
              selected_setup={setup_process}
              reload_information={this.reloadInformation}
              user_roles_and_permissions={this.props.user_roles_and_permissions}
              ipr_file_present={setup.ipr_file_present}
              isUploading={this.props.isUploading}
              requested_treatment={this.props.cases.requested_treatment}
              cases={this.props.cases}
              patient_info={this.props.case_details.patient}
              doctor_smile_design_preferences={this.state.doctorSmileDesignPreferences}
              setForceHideProcessTracker={this.setForceHideProcessTracker}
              forceHideProcessTrackerOnRelease={true}
            />
          ) : null}
        </div>
      );
    } else if (
      (this.props.cases.status_code === 'STATUS_HOLD' ||
        this.props.cases.status_code === 'STATUS_CANCEL' ||
        this.props.cases.status_code === 'Doctor Provide Clarification') &&
      last_status !== 'Doctor Approve or Revise Setup' &&
      !this.props.cases.manufacturing_process.log.length > 0 &&
      !isPostApprovalStage(last_status)
    ) {
      return (
        <SecurityClearance mode="ALL" permission_list={['SETUP_UPLOAD']}>
          <div key={`setup_container_${setup_index}`}>
            {this.props.case_files.setup_process.length > 1 && setup.index === this.props.case_files.setup_process.length && (
              <hr className="horizontal-ruler" />
            )}

            <div className="setup-assigned-to">
              <div className="setup-text">
                <span className="bold-text">{assigned_to_text}</span>
                {last_assigned_to}
              </div>
            </div>
            <ProcessTracker
              case_id={this.props.case_id}
              case_statuses={this.props.cases.case_statuses}
              edit_status_comment={this.state.edit_status_comment}
              editable_status_comment={this.state.editable_status_comment}
              hideRuler={true}
              key={`setup_process_tracker_${setup_index}`}
              last_stage={last_status}
              log_index={`${setup_index}`}
              process="setup"
              project={this}
              setup_assigned_to={this.state.setup_assigned_to}
              stage={this.props.get_case_status}
              selected_setup={setup_process}
              setup={setup}
              user_roles_and_permissions={this.props.user_roles_and_permissions}
              reload_information={this.reloadInformation}
              ipr_file_present={setup.ipr_file_present}
              isUploading={this.props.isUploading}
              requested_treatment={this.props.cases.requested_treatment}
              cases={this.props.cases}
              doctor_smile_design_preferences={this.state.doctorSmileDesignPreferences}
            />
          </div>
        </SecurityClearance>
      );
    } else if (isPostApprovalStage(this.props.cases.status_code) || (isCaseBlocked(this.props.cases.status_code) && isPostApprovalStage(last_status))) {
      return (
        <div key={`setup_container_${setup_index}`} className="final_design">
          <div className="setup-assigned-to">
            <div className="setup-text">
              <span className="bold-text">{assigned_to_text}</span>
              {!this.props.disabled && this.state.assignable && has_post_approval_edit_permission && !isCaseBlocked(this.props.cases.status_code) ? (
                <Select
                  name="setup_assignment"
                  className="assign-to-dropdown"
                  placeholder="Unassigned"
                  item="User"
                  value={this.state.setup_assigned_to}
                  options={this.state.assign_to_options}
                  autosize={false}
                  onChange={this.handleAssignTo}
                />
              ) : this.state.setup_assigned_to && this.state.setup_assigned_to.length > 0 && this.state.setup_assigned_to[0].label ? (
                this.state.setup_assigned_to[0].label
              ) : (
                'Unassigned'
              )}
            </div>
          </div>
          {this.state.setup_assigned_to[0].label !== 'Unassigned' ? (
            <ProcessTracker
              setup={setup}
              setup_process={setup_process}
              log_index={setup_index}
              project={this}
              case_id={this.props.case_id}
              isUploading={this.props.isUploading}
              last_stage={last_status}
              stage={this.props.get_case_status}
              has_post_approval_override_permission={has_post_approval_override_permission}
              has_post_approval_edit_permission={has_post_approval_edit_permission}
              reload_information={this.reloadInformation}
              user_roles_and_permissions={this.props.user_roles_and_permissions}
              cases={this.props.cases}
              ipr_file_present={setup.ipr_file_present}
              case_statuses={this.props.cases.case_statuses}
              final_design_upload={final_design_process}
              setup_assigned_to={this.state.setup_assigned_to}
              process="post_approval"
              hideRuler={true}
              filter={this.state.inbrace_ifs_required && !currentSetupHasProviderEdit ? 'inbrace_ifs' : ''}
              setup_type={this.state.inbrace_ifs_required ? 'InBrace IFS' : 'Provider Edit'}
              patient_info={this.props.case_details.patient}
              setForceHideProcessTracker={this.setForceHideProcessTracker}
              doctor_smile_design_preferences={this.state.doctorSmileDesignPreferences}
            />
          ) : null}
        </div>
      );
    }
  };

  /**
   * Displays smile design target completion date
   * @function
   * @param {string} date - Date
   * @returns {JSX} JSX for target completion date
   */
  displayTargetCompletionDate = (date) => {
    return (
      <div className="target-date pull-right">
        <span>Target Completion Date: </span>
        <span>{date ? convertDate(date) : 'N/A'}</span>
      </div>
    );
  };

  componentDidMount() {
    this.setState({ is_mounted: true }, this.initialize);
  }

  componentDidUpdate() {
    // Reload status comment upon transition from conversion failure state
    if (this.state.status_comment === '' && this.props.get_case_status === 'Setup Ready for Upload' && this.state.comment_load_attempted === false) {
      this.loadComment();
      this.setState({
        comment_load_attempted: true,
      });
    } else if (this.props.get_case_status === 'STATUS_INBRACE_SETUP' && this.state.assignee_load_transition_attempted === false) {
      this.setAssignedUser();
      this.setState({
        assignee_load_transition_attempted: true,
      });
    }
  }

  /**
   * get api call action types base on conditions
   * @function
   * @param {string} type - function name
   * @returns {string} action for the function
   */
  getSetupActionType = (type) => {
    const case_status = this.props.get_case_status;
    const is_post_approval_stage = isPostApprovalStage(case_status);
    const inbrace_ifs_required = this.state.inbrace_ifs_required;
    const provider_edit = this.state.provider_edit && is_post_approval_stage;
    const is_post_approval = is_post_approval_stage && (inbrace_ifs_required || provider_edit);

    switch (type) {
      case 'onSetupConversionUploadClick':
        return is_post_approval ? 'final_design_reupload' : 'setup_conversion_reupload';

      case 'onSetupConversionConfirmClick':
        return is_post_approval ? 'final_design_conversion_complete' : 'setup_conversion_complete';

      case 'onSetupConversionFailedClick':
        return is_post_approval ? 'final_design_conversion_failed' : 'setup_conversion_failed';

      case 'onSetupReleaseConfirmClick':
        return is_post_approval ? 'release_final_design' : 'release';

      case 'onSetupDoNotReleaseConfirmClick':
        return is_post_approval ? 'final_design_revision_after_review' : 'revision_after_review';

      case 'onSetupUploadConfirmClick':
        return is_post_approval ? 'final_design_upload_complete' : 'setup_upload_complete';

      case 'onSetupReviewApprovedClick':
        return provider_edit ? 'provider_edit_review_approved' : 'setup_review_approved';

      default:
        break;
    }
  };

  /**
   * Filters final design setups from normal setups
   * @function
   * @returns {Array} Array of setup objects
   */
  getPostApprovalSetupFiles = () => {
    return (
      this.props.case_files?.setup_process?.filter((setup) => {
        return setup.is_inbrace_ifs_setup || setup.provider_edit;
      }) ?? []
    );
  };

  /**
   * Displays the assigned to text based on conditions
   * @function
   * @param {Array} setup - Array of objects containing setup info
   * @returns {string} Text for assigned to
   */
  getAssignedToText = (setup) => {
    let suffix = '';
    const in_post_approval_stage = isPostApprovalStage(this.props.cases.status_code);
    const last_status = getCaseLastStatus(true, this.props.case_details, this.props.case_id);
    const hold_statuses = ['STATUS_HOLD', 'STATUS_CANCEL', 'Doctor Provide Clarification'];
    const in_post_approval_hold_stage = hold_statuses.includes(this.props.cases.status_code) && isPostApprovalStage(last_status);
    const in_provider_edit_stage = in_post_approval_hold_stage ? last_status == 'Provider Edit Review' : this.props.cases.status_code == 'Provider Edit Review';

    if (in_post_approval_stage || in_post_approval_hold_stage) {
      if (this.state.provider_edit && in_provider_edit_stage) suffix = ' (Provider Edit) ';
      else if (this.state.inbrace_ifs_required) suffix = ' (InBrace IFS) ';
      else if (this.state.provider_edit) suffix = ' (Provider Edit) ';
    }

    const assigned_to_text = `Smile Design ${setup?.index}${suffix} Assigned To: `;
    return assigned_to_text;
  };

  render() {
    const setup_process = this.props.case_files?.setup_process ?? [];
    const final_design_process = this.getPostApprovalSetupFiles();
    const is_final_design_stage = isPostApprovalStage(this.props.cases.status_code);
    const bundle_type = this.state.inbrace_ifs_required || this.state.provider_edit ? 'final_design' : 'setup';
    return setup_process.length > 0 && userHasPermission(EUserPermission.CaseDetailsView, this.props.user_roles_and_permissions.permissions) ? (
      <div id="setup-area" className="setup-container">
        <div className="bold-text business-dark-heading">
          {setup_process.length > 1 ? 'Smile Designs' : 'Smile Design'}
          {((isSmileDesignStage(this.props.cases.status_code) &&
            this.props.cases.smile_design_target_date &&
            isTodayPastDate(this.props.cases.smile_design_target_date)) ||
            (is_final_design_stage && this.props.cases.post_approval_target_date && isTodayPastDate(this.props.cases.post_approval_target_date))) && (
            <i
              className="fa fa-flag target-date-flag"
              aria-hidden="true"
              data-toggle="tooltip"
              data-placement="right"
              title={
                is_final_design_stage
                  ? `Missed Post Approval Date: ${convertDate(this.props.cases.post_approval_target_date)}`
                  : `Missed Smile Design Date: ${convertDate(this.props.cases.smile_design_target_date)}`
              }
            />
          )}
          {setup_process.length > 0 && userHasPermission(EUserPermission.CaseDetailsView, this.props.user_roles_and_permissions.permissions) ? (
            <div
              id="setup_files"
              className="pull-right btn-light-link"
              data-case_id={this.props.cases.case_id}
              onClick={this.onSetupCommentsClick}
              data-toggle="tooltip"
              data-placement="top"
              title="Smile Designs Log"
            >
              <i className="fa fa-file-text-o" aria-hidden="true" />
            </div>
          ) : null}
          {this.getSetupUndoButton(this.props.cases)}
          {isSmileDesignStage(this.props.cases.status_code) && this.displayTargetCompletionDate(this.props.cases.smile_design_target_date)}
          {isPostApprovalStage(this.props.cases.status_code) && this.displayTargetCompletionDate(this.props.cases.post_approval_target_date)}
        </div>
        <div className="business-dark-theme">
          {setup_process.map((setup) => {
            return this.showSetupByLog(setup);
          })}

          {setup_process.map((setup) => {
            return this.displayAdjustedSetup(setup);
          })}

          {!this.state.forceHideProcessTracker && this.showProgressTracker(setup_process, final_design_process)}
        </div>

        {this.state.status_message ? (
          <div className="status__message--dark">
            <i className="fa fa-spinner fa-spin" aria-hidden="true" /> {this.state.status_message}
          </div>
        ) : null}
        <div>
          {this.state.showSetupCommentsModal ? (
            <Modal
              preset="log"
              log_type="setup"
              case_id={this.props.case_id}
              selected_tab={this.props.selected_tab}
              header_text="Smile Design Log"
              onCloseButtonClick={this.onSetupCommentsDismiss}
              theme="bpp"
            />
          ) : null}
          {this.state.showSetupModal ? (
            <Modal
              preset="upload"
              header_text="Upload Smile Design"
              remove_btn={
                (this.state.selected_setup[this.state.selected_setup.length - 1].files &&
                this.state.selected_setup[this.state.selected_setup.length - 1].files.id
                  ? [this.state.selected_setup[this.state.selected_setup.length - 1].files]
                  : []
                ).length > 0
              }
              remove_btn_text={'Remove Smile Design'}
              case_id={this.props.case_id}
              folder="setup"
              type="setup"
              onUpload={this.onUpload}
              upload_content={
                this.state.selected_setup[this.state.selected_setup.length - 1].files &&
                this.state.selected_setup[this.state.selected_setup.length - 1].files.id
                  ? [this.state.selected_setup[this.state.selected_setup.length - 1].files]
                  : []
              }
              onCloseButtonClick={this.onSetupModalDismiss}
              onRemoveButtonClick={this.onRemove}
              theme="bpp"
            />
          ) : null}
          {this.state.showSetupDeletionModal ? (
            <Modal
              preset="decision"
              header_text={'Remove Smile Design - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you want to remove this Smile Design?"
              confirm_btn_data_href={`/${this.state.setupPath}`}
              confirm_btn_data_type={bundle_type}
              confirm_btn_text="Remove"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onSetupDeletionModalDismiss}
              onConfirmButtonClick={this.onRemove}
            />
          ) : null}
          {this.state.showAddAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Adjusted Smile Design - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you would like to begin the adjusted Smile Design conversion process?"
              confirm_btn_text="Proceed"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onAddAdjustedSetupModalDismiss}
              onConfirmButtonClick={this.onAddAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.showCancelAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Cancel Process - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you would like to cancel the adjusted Smile Design conversion process?"
              confirm_btn_text="Cancel"
              close_btn_text="Do Not Cancel"
              theme="bpp"
              onCloseButtonClick={this.onCancelAdjustedSetupModalDismiss}
              onConfirmButtonClick={this.onCancelAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.showRemoveSetupZipModal ? (
            <Modal
              preset="decision"
              header_text={'Remove Smile Design - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you want to remove this Smile Design?"
              confirm_btn_text="Remove"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onRemoveAdjustedSetupZipModalDismiss}
              onConfirmButtonClick={this.onRemoveAdjustedSetupZipButtonClick}
            />
          ) : null}
          {this.state.showConvertAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Convert Smile Design - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you want to convert this Smile Design?"
              confirm_btn_text="Convert"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onConvertAdjustedSetupModalDismiss}
              onConfirmButtonClick={this.onConvertAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.showCompleteAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Conversion Complete - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you want to complete this conversion?"
              confirm_btn_text="Complete"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onCompleteAdjustedSetupDismiss}
              onConfirmButtonClick={this.onCompleteAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.showIncompleteAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Conversion Incomplete - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you want to set status to incomplete for this conversion?"
              confirm_btn_text="Incomplete"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onIncompleteAdjustedSetupDismiss}
              onConfirmButtonClick={this.onIncompleteAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.showRemoveConvertedAdjustedSetupModal ? (
            <Modal
              preset="decision"
              header_text={'Remove Smile Design - ' + removeCaseIdInitialNumber(this.props.case_id)}
              message_text="Are you sure you would like to remove the adjusted Smile Design?"
              confirm_btn_text="Remove"
              close_btn_text="Cancel"
              theme="bpp"
              onCloseButtonClick={this.onRemoveConvertedAdjustedSetupDismiss}
              onConfirmButtonClick={this.onRemoveConvertedAdjustedSetupModalProceed}
            />
          ) : null}
          {this.state.refresh ? (
            <Modal
              preset="action"
              x_btn={false}
              header_text="Session Expired"
              message_text="Sorry, your session has expired. Please refresh."
              confirm_btn_text="Refresh"
              onConfirmButtonClick={onReloadPage}
              theme="bpp"
            />
          ) : null}
          {this.state.showIprModal ? this.iprModal() : null}
        </div>
      </div>
    ) : null;
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setProductionTxGuideTabConfig: setProductionTxGuideTabConfig,
    },
    dispatch
  );

const SetupContainerDefault = connect(null, mapDispatchToProps)(SetupContainer);

export default SetupContainerDefault;
