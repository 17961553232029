/**
 * File:reason.js - Component to input item request submission reasons on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TeethSelector from '../../common/teeth_selector';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';
import { onImageToolAction } from '../../common/functions';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import CardContainer from '../components/container/card_container';
import TextArea from '../components/inputs/text_area';
import TextBox from '../components/inputs/text_box';
import RecordViewer from '../components/record_viewer/record_viewer';
import { getMinDate, getMaxDate } from '../../common/helpers';
import { getIteroProgressRecords, getIteroScansRecords, removeDuplicateIteroScansRecords } from '../../common/itero_utils';

class ItemReason extends Component {
  static defaultProps = {
    selectedIteroScanRecords: [],
  };

  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
    const options = { sync: false, hide_occlusion: true };
    let records = [...this.props.photoUpload, ...this.props.selectedIteroScanRecords];
    const hastIteroProgressRecords = getIteroProgressRecords(records).length;
    const hasIteroScans = getIteroScansRecords(records).length;
    if (hastIteroProgressRecords && hasIteroScans) {
      records = removeDuplicateIteroScansRecords(records);
    }
    this.props.buildRecordStates(records, options);
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  handleTeethClick = (rb, id, rb_id) => {
    const func = (tooth, isSelected) => {
      const sel = new Set(rb.selectedTeeth);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      rb.selectedTeeth = Array.from(sel);
      this.props.onHandleTeethClick(rb, { id, rb_id });
    };
    return func;
  };

  /**
   * Displays text area
   * @param {object} csq - csq json object
   * @function
   */
  displayTextArea = (csq) => {
    return (
      <div id={csq.input_type + '-' + csq.id} className="question-box" key={csq.id}>
        <div className={this.props.error && csq.error ? 'warning-text title emphasis' : 'title emphasis'}>{csq.question_text}</div>
        <TextArea
          className={this.props.error && csq.error ? 'warning-border' : ''}
          placeholder={csq.input_placeholder}
          id={csq.input_type + '-' + csq.id}
          data-id={csq.input_type + '-' + csq.id}
          data-csq-id={csq.id}
          data-input-type={csq.input_type}
          value={csq.answer_text}
          onChange={this.props.onTextChange}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  };

  /**
   * Displays radio button
   * @param {{
   *   id: string,
   *   toothSelector: any,
   *   radio_label: string,
   *   input_type: string,
   *   input_placeholder: string,
   *   text_area?: string,
   *   question_text?: string,
   *   error?: string,
   *   radio_button: [{
   *     id: string,
   *     radio_checked: boolean,
   *     selectedTeeth: [],
   *     text_area?: {
   *       id: string,
   *       answer_text: string,
   *       input_placeholder: string,
   *     },
   *   }],
   * }} csq - csq json object
   * @function
   */
  displayRadioButton = (csq) => {
    const radios = [...csq.radio_button].sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
    return (
      <div id={csq.input_type + '-' + csq.id} className="question-box" key={csq.id}>
        <div className={this.props.error && csq.error ? 'warning-text title emphasis' : 'title emphasis'}>{csq.question_text}</div>
        <CardContainer type="form">
          {radios.map(rb => (
              <div className="ib-radio" key={rb.id}>
                <label className="form-check-label">
                  <TextBox
                    type="radio"
                    className="form-check-input"
                    placeholder={csq.input_placeholder}
                    id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-csq-id={csq.id}
                    data-rb-id={rb.id}
                    data-input-type={csq.input_type}
                    checked={rb.radio_checked}
                    onChange={this.props.onRbChange}
                  />
                  <span>{rb.radio_label}</span>
                </label>
                {rb.radio_label === 'Yes' && rb.radio_checked && rb.toothSelector ? (
                  <div>
                    <span className={this.props.error && csq.error ? 'warning-text title emphasis' : 'title emphasis'}>Specify location:</span>
                    <TeethSelector
                      selected={rb.selectedTeeth}
                      onClick={this.handleTeethClick(rb, csq.id, rb.id)}
                      disabled={this.props.disabled}
                      hasUpper={this.props.selected}
                      hasLower={true}
                    />
                  </div>
                ) : null}
                {rb.radio_checked && rb.text_area ? (
                  <TextArea
                    className={this.props.error && csq.error ? 'warning-border' : ''}
                    placeholder={rb.text_area.input_placeholder}
                    id={csq.input_type + '-' + csq.id + '-' + rb.id + '-' + rb.text_area.id}
                    data-id={csq.input_type + '-' + csq.id + '-' + rb.id + '-' + rb.text_area.id}
                    data-csq-id={csq.id}
                    data-rb-id={rb.id}
                    data-input-type={csq.input_type}
                    rows="2"
                    value={rb.text_area.answer_text}
                    onChange={this.props.onTextChange}
                    onBlur={this.props.onBlur}
                  />
                ) : null}
              </div>
            ))}
        </CardContainer>
      </div>
    );
  };

  /**
   * Displays text box
   * @param {object} csq - csq json object
   * @function
   */
  displayTextBox = (csq) => {
    return (
      <div id={csq.input_type + '-' + csq.id} className="question-box" key={csq.id}>
        <div className="row">
          <span className={this.props.error && csq.error ? 'csq-label warning-text title emphasis' : 'csq-label title emphasis'}>{csq.question_text}</span>
        </div>
        <div data-id={csq.id} className="reason-input-data">
          <input
            type="text"
            className={this.props.error && csq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
            placeholder={csq.input_placeholder}
            id={csq.input_type + '-' + csq.id}
            data-id={csq.input_type + '-' + csq.id}
            data-csq-id={csq.id}
            data-input-type={csq.input_type}
            data-name={csq.input_type + '-' + csq.id}
            value={csq.answer_text}
            onChange={this.props.onTextChange}
            onBlur={this.props.onBlur}
          />
        </div>
        <div className="extra-padding-headline-csr" />
      </div>
    );
  };

  /**
   * Displays date input
   * @param {object} csq - csq json object
   * @function
   */
  displayDate = (csq) => {
    return (
      <div id={csq.input_type + '-' + csq.id} className="row">
        <div className="col-sm-6">
          <div className="question-box" key={csq.id}>
            <span className={csq.error ? 'warning-text title emphasis' : 'title emphasis'}>{csq.question_text}</span>
            <CardContainer type="form" style={{ paddingLeft: '0px' }}>
              <TextBox
                type="date"
                className={csq.error ? 'warning-border' : ''}
                placeholder={csq.input_placeholder}
                id={csq.input_type + '-' + csq.id}
                data-id={csq.input_type + '-' + csq.id}
                data-csq-id={csq.id}
                data-input-type={csq.input_type}
                data-name={csq.input_type + '-' + csq.id}
                value={csq.answer_text}
                onChange={this.props.onTextChange}
                onBlur={this.props.onBlur}
                max={getMaxDate()}
                min={getMinDate()}
              />
              {csq.answer_text && csq.error && (
                <span id={csq.answer_text + '_warning'} className="title title--warning emphasis">
                  Invalid Date
                </span>
              )}
            </CardContainer>
            <div className="extra-padding-headline-csr" />
          </div>
        </div>
      </div>
    );
  };

  /**
   * Displays check box
   * @param {object} csq - csq json object
   * @function
   */
  displayCheckBox = (csq) => {
    const check_box = [...csq.check_box].sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
    return (
      <div id={csq.input_type + '-' + csq.id} className="question-box" key={csq.id}>
        <span className={this.props.error && csq.error ? 'warning-text title emphasis' : 'title emphasis'}>{csq.question_text}</span>
        <CardContainer type="form">
          {check_box
            .map((rb) => (
              <div key={rb.id}>
                <label>
                  <TextBox
                    type="checkbox"
                    className="form-check-input"
                    name={csq.input_type + csq.id}
                    placeholder={csq.input_placeholder}
                    id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-id={csq.input_type + '-' + csq.id + '-' + rb.id}
                    data-csq-id={csq.id}
                    data-rb-id={rb.id}
                    data-input-type={csq.input_type}
                    value={csq.answer_text}
                    checked={rb.checked}
                    onChange={this.props.onCbChange}
                  />
                  {rb.label}
                </label>
                {rb.checked && rb.toothSelector ? (
                  <div>
                    <span className={this.props.error && csq.error ? 'warning-text box-offset' : 'box-offset'}>Specify location:</span>
                    <TeethSelector
                      selected={rb.selectedTeeth}
                      onClick={this.handleTeethClick(rb, csq.id, rb.id)}
                      disabled={this.props.disabled}
                      hasUpper={this.props.selected}
                      hasLower={true}
                    />
                  </div>
                ) : null}
              </div>
            ))}
        </CardContainer>
      </div>
    );
  };

  /**
   * Displays multiple question section
   * @param {object} csq - csq json object
   * @function
   */
  displayMq = (csq) => {
    return (
      <div id={csq.input_type + '-' + csq.id} className="question-box" key={csq.id}>
        {csq.question_text ? <div className={this.props.error && csq.error ? 'warning-text title emphasis' : 'title emphasis'}>{csq.question_text}</div> : null}
        {csq.format ? (
          this.displayMqDateDe(csq)
        ) : (
          <CardContainer type="form">
            <div className="row">
              {csq.multiple_question
                .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
                .map((mq, index) => (
                  <div key={index} className="col-sm-6">
                    <div className="emphasis">{mq.question_text}</div>
                    {mq.input_type === 'radio_button' ? this.displayMqRadioButton(csq, mq) : null}
                    {mq.input_type === 'text' ? this.displayMqTextBox(csq, mq) : null}
                    {mq.input_type === 'date' ? this.displayMqDate(csq, mq) : null}
                  </div>
                ))}
            </div>
          </CardContainer>
        )}
      </div>
    );
  };

  displayMqRadioButton = (csq, mq) => {
    const radios = [...mq.radio_button].sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    return radios.map((rb) => (
        <div key={rb.id} className="ib-radio">
          <label className="form-check-label">
            <TextBox
              type="radio"
              className="form-check-input"
              placeholder={csq.input_placeholder}
              id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id}
              name={csq.input_type + mq.id}
              data-id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id}
              data-csq-id={csq.id}
              data-mq-id={mq.id}
              data-rb-id={rb.id}
              data-input-type={csq.input_type}
              checked={rb.radio_checked}
              onChange={this.props.onRbChange}
              data-is-smartwire={mq.question_text.includes('Smartwire')}
            />
            {rb.radio_label}
          </label>
          {rb.radio_checked && rb.showDate && rb.date ? (
            <div className="inline-date-container">
              <label className={'title emphasis' + (rb.date.error ? ' title--warning' : '')}>{rb.date.question_text}</label>
              <TextBox
                type="date"
                className={rb.date.error ? 'warning-border' : ''}
                placeholder={rb.date.input_placeholder}
                id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id}
                data-id={csq.input_type + '-' + csq.id + '-' + mq.id + '-' + rb.id + '-' + rb.date.id}
                data-csq-id={csq.id}
                data-mq-id={mq.id}
                data-rb-id={rb.id}
                data-date-id={rb.date.id}
                data-input-type={csq.input_type}
                value={rb.date.answer_text}
                onChange={this.props.onTextChange}
                onBlur={this.props.onBlur}
                max={getMaxDate()}
                min={getMinDate()}
                data-is-centralized-date={mq.hasCentralizedDate}
              />
              {rb.date.answer_text && rb.date.error && (
                <span id={csq.answer_text + '_warning'} className="title title--warning emphasis">
                  Invalid Date
                </span>
              )}
            </div>
          ) : null}
        </div>
      ));
  };

  displayMqTextBox = (csq, mq) => {
    return (
      <div data-id={mq.id} className="reason-input-data">
        <input
          type="text"
          className={this.props.error && mq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
          placeholder={csq.input_placeholder}
          id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-csq-id={csq.id}
          data-mq-id={mq.id}
          data-input-type={csq.input_type}
          data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
          value={mq.answer_text}
          onChange={this.props.onTextChange}
          onBlur={this.props.onBlur}
        />
      </div>
    );
  };

  displayMqDate = (csq, mq) => {
    const { treatment_data } = this.props;
    let is_disabled = false;
    if (mq.related_question_id) {
      const q = treatment_data.questions
        .find(q => q.id === parseInt(mq.related_question_id))
        .multiple_question.find(m => m.id === parseInt(mq.related_mq_id));
      is_disabled = q.answer_text === 'None';
    }
    return (
      <div data-id={mq.id} className="reason-input-data">
        <input
          type="date"
          className={this.props.error && mq.error ? 'form-control patient-field warning-border' : 'form-control patient-field'}
          placeholder={csq.input_placeholder}
          id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
          data-csq-id={csq.id}
          data-mq-id={mq.id}
          data-input-type={csq.input_type}
          data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
          value={mq.answer_text}
          onChange={this.props.onTextChange}
          onBlur={this.props.onBlur}
          disabled={is_disabled}
          max={getMaxDate()}
          min={getMinDate()}
        />
      </div>
    );
  };

  /**
   * Displays multiple questions dates
   * @param {object} csq - csq json object
   * @function
   */
  displayMqDateDe = (csq) => {
    return (
      <div id={csq.input_type + '-' + csq.id} className="row equal">
        {csq.multiple_question
          .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
          .map((mq, index) => (
            <div key={index} className="col-sm-6">
              <div className={'title emphasis' + (mq.error ? ' title--warning' : '')}>{mq.question_text}</div>
              <CardContainer type="form" style={{ paddingLeft: '0px' }}>
                <TextBox
                  id={csq.input_type + '-' + csq.id + '-' + mq.id}
                  data-id={csq.input_type + '-' + csq.id + '-' + mq.id}
                  type="date"
                  className={mq.error ? 'warning-border' : ''}
                  data-csq-id={csq.id}
                  data-mq-id={mq.id}
                  data-input-type={csq.input_type}
                  data-name={csq.input_type + '-' + csq.id + '-' + mq.id}
                  value={mq.answer_text}
                  onChange={this.props.onTextChange}
                  onBlur={this.props.onBlur}
                  max={mq.friendly_name === 'next_scheduled_appt' ? getMaxDate(1, 'y') : getMaxDate()}
                  min={getMinDate()}
                />
                {mq.answer_text && mq.error && (
                  <span id={csq.answer_text + '_warning'} className="title title--warning emphasis" style={{ position: 'absolute', paddingTop: '4px' }}>
                    Invalid Date
                  </span>
                )}
              </CardContainer>
            </div>
          ))}
      </div>
    );
  };

  render() {
    const { treatment_data } = this.props;
    const questions = treatment_data
      ? [...treatment_data.questions].sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
      : [];
    return (
      <form className="case-form-container">
        <div className="row">
          <div className="col-md-8">
            <h3>Treatment Details</h3>
            {questions
                .map((csq) =>
                  csq.input_type === 'text_area'
                    ? this.displayTextArea(csq)
                    : csq.input_type === 'text'
                    ? this.displayTextBox(csq)
                    : csq.input_type === 'date'
                    ? this.displayDate(csq)
                    : csq.input_type === 'radio_button'
                    ? this.displayRadioButton(csq)
                    : csq.input_type === 'multiple_question'
                    ? this.displayMq(csq)
                    : csq.input_type === 'check_box'
                    ? this.displayCheckBox(csq)
                    : null
                )}
          </div>
          <div className="col-md-4">
            <RecordViewer
              records={this.props.record_states}
              onLoad={this.props.onLoadImageRecord}
              onClick={(id, action) => {
                onImageToolAction(id, action, this.props);
              }}
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemReason);
