import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getCaseDetails } from '../../../../../../redux/reducers/common/common_case_details';
import { getTxPlanInfo, isTxPlanRevision2Or3 } from '../../../common/functions';
import { getCaseFileList } from '../../../../../../redux/reducers/ipp/case_details/case_files';
import { getPDFNameTemplate } from '../../../../../../common/helpers';
import PDFViewerInterface from '../../../../../pdf-viewer/pdf_viewer';

/**
 * Displays the treatment plan PDF
 * @component
 * @category SetupViewer
 */
class PaneViewerTXPlan extends Component {
  /**
   * Get treatment plan pdf url
   * @function
   * @return {String} Treatment plan url
   */
  getTxPlanURL = () => {
    const planDetails = getTxPlanInfo(this.props.case_file_list);
    if (isTxPlanRevision2Or3(planDetails.revision)) {
      return `/media/pdf/?file_id=${planDetails.id}`;
    }
    return '';
  };

  getTxDePlanPDFFiletype() {
    const file_rev = getTxPlanInfo(this.props.case_file_list).revision;
    if (file_rev === 'TX2.0' || file_rev === 'TX3.0') {
      return 'Treatment_Plan.pdf';
    } else if (file_rev === 'DE2.0' || file_rev === 'DE3.0') {
      return 'Digital_Enhancement.pdf';
    } else {
      return '';
    }
  }

  getTxDePlanPDFFileName() {
    const case_id = this.props.case_file_list.case_id;
    const patient = this.props.case_details.patient;
    const file_type = this.getTxDePlanPDFFiletype();
    return getPDFNameTemplate(case_id, patient, file_type);
  }

  render() {
    const pdf_url = this.getTxPlanURL();
    const file_name = this.getTxDePlanPDFFileName();

    return <div className="records-image pdf-record">{pdf_url && <PDFViewerInterface fileUrl={pdf_url} filename={file_name} />}</div>;
  }
}

PaneViewerTXPlan.propTypes = {
  pane: PropTypes.number.isRequired,
  case_details: PropTypes.object,
  case_file_list: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
    case_file_list: getCaseFileList(state),
  };
};

export default connect(mapStateToProps)(PaneViewerTXPlan);
