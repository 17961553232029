import React, { Component } from 'react';
// External Libs
import Axios from 'axios';
import { Check, Exc } from '../../assets/icons';
import { connect } from 'react-redux';

// Internal Functions
import { setTokenHeader, convertDate } from '../../../common/functions';
import { handleHttpRequestError } from '../../../common/error';
import { getTempLink } from '../../../common/dropbox';
import SessionExpire from '../../../common/session_expire';
import { getArchToTreat, getBracketPositionings, isValidDate } from '../../../common/helpers';

// Internal Components
import Modal from '../../../components/modal/modal';

// Redux
import { getRecordStates } from '../../../redux/reducers/record_viewer/record_viewer';

const checkIcon = <Check className="case-detail__status__icon case-detail__status__icon--primary navigator_icon" />;
const checkGrayIcon = <Check className="case-detail__status__icon case-detail__status__icon--inactive navigator_icon" />;
const warningIcon = <Exc className="case-detail__status__icon case-detail__status__icon--warning navigator_icon" />;

/**
 * Component displaying one or more status cards
 */
class StatusCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalDisplaying: '',
      initial_bonding_date: '',
      bracket_positions: [],
      ipr_radioBtn_options: [],
      ipr_checked: [],
      ipr_note: '',
      smartwires_engaged: [],
      smartwires_fully_expressed: [],
      inter_arch_mechanics: [
        { id: 0, name: 'incomplete', description: 'Incomplete', state: false },
        { id: 1, name: 'complete', description: 'Complete', state: false },
      ],
      bite_turbo_removal: [
        { id: 0, name: 'incomplete', description: 'Incomplete', state: false },
        { id: 1, name: 'complete', description: 'Complete', state: false },
      ],
      debonding_date: '',
      files_uploaded: [],
      files_uploading: [],
      current_item: {},
      previous_modal_value: '',
      refresh: false,
      saving: false,
    };
  }

  componentDidMount() {
    this.reloadInformation();
    //Enable tooltip
    setTimeout(function () {
      this.$('[data-toggle="tooltip"]').tooltip();
    }, 0);
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  /**
   * Reload data
   * @function
   */
  reloadInformation = () => {
    setTokenHeader();
    this.updateStateWithResData(this.props.navigator_data);
  };

  /**
   * Update State with res data
   * @function
   * @param {Object} res - Navigator api request data
   *
   */
  updateStateWithResData = (res) => {
    if (res.data && res.data.navigator_record) {
      const record = res.data.navigator_record;
      const initial_bonding_date = record.initial_bonding_date ? record.initial_bonding_date : '';
      const bracket_positions = this.getBracketsAndStatus(record.bracket_positioning);
      const smartwires_engaged = this.getSmartwiresShouldEngaged(record.smartwires_engaged);
      const smartwires_fully_expressed = this.getSmartwiresShouldFullyExpress(record.smartwires_fully_expressed);
      const ipr_checked = record.ipr ? record.ipr.split(',') : [];
      const ipr_radioBtn_options = this.getIprRadioOptions(record.ipr);
      const ipr_note = record.ipr_note ? record.ipr_note : '';
      const inter_arch_mechanics = this.getRadioBtnModalsCompletion(record.inter_arch_mechanics, 'inter_arch_mechanics');
      const bite_turbo_removal = this.getRadioBtnModalsCompletion(record.bite_turbo_removal, 'bite_turbo_removal');
      const files_uploaded = res.data.files;
      const debonding_date = record.debonding_date ? record.debonding_date : '';

      this.setState({
        initial_bonding_date: initial_bonding_date,
        bracket_positions: bracket_positions,
        smartwires_engaged: smartwires_engaged,
        smartwires_fully_expressed: smartwires_fully_expressed,
        inter_arch_mechanics: inter_arch_mechanics,
        bite_turbo_removal: bite_turbo_removal,
        ipr_checked: ipr_checked,
        ipr_note: ipr_note,
        ipr_radioBtn_options: ipr_radioBtn_options,
        debonding_date: debonding_date,
        files_uploaded: files_uploaded,
        previous_modal_value: res,
      });
    }
  };

  /**
   * Gets icon to display in navigator status card
   * @function
   * @param {Object} item - status card item
   * @return {JSX} JSX element for icon
   */
  getIcon = (item) => {
    if (this.props.getCompletionByName(item.title) === 'Complete') {
      return checkIcon;
    } else if (this.props.getCompletionByName(item.title) === 'Partially Complete') {
      return warningIcon;
    } else {
      return checkGrayIcon;
    }
  };

  /**
   * Determine which modal to display
   * @function
   * @param {Object} item - Object containing status card information
   * @param {Object} cases - Object containing case information
   * @return {JSX} JSX element for modal
   */
  onCardClick = (item, cases) => {
    this.setState({ current_item: item });
    switch (item.title) {
      case 'Initial Bonding':
        this.displayInitialBondingModal(item, cases);
        break;
      case 'Optimal Bracket Positioning':
        this.displayBracketPositionModal(item);
        break;
      case 'IPR':
        this.displayIprModal(item, this.state.ipr_radioBtn_options);
        break;
      case 'Completed Initial Leveling and Alignment':
        this.displaySmartwireEngagedModal(item);
        break;
      case 'Full Expression of Smartwires':
        this.displaySmartwireExpressedModal(item);
        break;
      case 'Inter-Arch Mechanics':
        this.displayInterArchModal(item);
        break;
      case 'Bite Turbo Removal/Reduction':
        this.displayBiteTurboModal(item);
        break;
      case 'Debonding':
        this.displayDebondingModal(item, cases, this.state.files_uploaded);
        break;

      default:
        break;
    }
  };

  /**
   * Display Initial Bonding Modal
   * @function
   * @param {Object} item - status card item
   * @param {Object} cases - case information
   */
  displayInitialBondingModal = (item, cases) => {
    let shipping_date = '';
    if (
      cases &&
      cases.ship_process &&
      cases.ship_process.log &&
      cases.ship_process.log.length > 0 &&
      cases.ship_process.log[0] &&
      cases.ship_process.log[0].date
    ) {
      shipping_date = convertDate(cases.ship_process.log[0].date);
    }

    let modal = (
      <Modal
        preset="navigator_initial_bonding"
        modal_size_class="modal_centered"
        header_text={item.title}
        theme="ipp"
        date_input_id="initial_bonding_date"
        datearea_text={'Date of initial bonding'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        datearea_value={this.state.initial_bonding_date}
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        datearea_warning_text={shipping_date !== '' ? 'Date must be after the case shipped date: ' + shipping_date : 'Date must be after the case shipped date'}
        onDateAreaChange={(e) => {
          this.onDateAreaChange(e, true);
        }}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        min_date={shipping_date}
        onCloseButtonClick={this.closeModal}
        isValidDate={(e) => {
          return isValidDate(e, shipping_date, '9999-12-31', true);
        }}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('initial_bonding_date');
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display Bracket Positioning Modal
   * @function
   * @param {Object} item - status card item
   */
  displayBracketPositionModal = (item) => {
    let missing_teeth = this.props.ipr && this.props.ipr.end && this.props.ipr.end.missing_teeth ? this.props.ipr.end.missing_teeth : [];

    let modal = (
      <Modal
        preset="teeth_chart_with_checkboxes"
        modal_size_class="modal-lg modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        message_text={
          'Select all brackets that have been placed in their planned bracket position as indicated on the Treatment Instructions. Includes brackets not included in the initial bonding tray and brackets to be repositioned.'
        }
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        disabledEdit={!this.props.has_edit_permission}
        selections={this.state.bracket_positions}
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('bracket_positioning');
        }}
        onSelectionChange={(id) => {
          this.onSelectionChange(id, 'bracket_positions');
        }}
        missing_teeth={missing_teeth}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display IPR Modal
   * @function
   * @param {Object} item - status card item
   */
  displayIprModal = (item, ipr_radioBtn_options) => {
    let modal = (
      <Modal
        preset="ipr_chart"
        modal_size_class="modal-lg modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        message_text={this.props.ipr ? 'Select the IPR that has been completed' : 'IPR'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        onCloseButtonClick={this.closeModal}
        ipr_radioBtn_options={ipr_radioBtn_options}
        ipr={this.props.ipr}
        ipr_checked={this.state.ipr_checked}
        ipr_note={this.state.ipr_note}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('ipr');
        }}
        onSelectionChange={(id) => {
          this.props.ipr ? this.onIprChecked(id.toString()) : this.onIprRadioClicked(id, this.props.getCompletionByName(item.title), item.title);
        }}
        onTextChange={(text) => {
          this.onIprNoteTextChange(text);
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display Smartwire Engaged Modal
   * @function
   * @param {Object} item - status card item
   */
  displaySmartwireEngagedModal = (item) => {
    let modal = (
      <Modal
        preset="multiple_checkboxex"
        modal_size_class="modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        message_text={'Select the Smartwire(s) that has been engaged for at least 8 weeks'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('smartwires_engaged');
        }}
        selections={this.state.smartwires_engaged}
        onSelectionChange={(id) => {
          this.onSelectionChange(id, 'smartwires_engaged');
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display Smartwire Fully Expressed Modal
   * @function
   * @param {Object} item - status card item
   */
  displaySmartwireExpressedModal = (item) => {
    let modal = (
      <Modal
        preset="multiple_checkboxex"
        modal_size_class="modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        message_text={'Select the Smartwire(s) that has been fully expressed'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('smartwires_fully_expressed');
        }}
        selections={this.state.smartwires_fully_expressed}
        onSelectionChange={(id) => {
          this.onSelectionChange(id, 'smartwires_fully_expressed');
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display Inter-Arch Mechanics Modal
   * @function
   * @param {Object} item - status card item
   */
  displayInterArchModal = (item) => {
    let modal = (
      <Modal
        preset="radio_btns"
        modal_size_class="modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        message_text={'Inter-arch mechanics'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('inter_arch_mechanics');
        }}
        radio_btn_options={this.state.inter_arch_mechanics}
        onSelectionChange={() => {
          this.radioBtnClicked('inter_arch_mechanics');
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display Bite Turbo Modal
   * @function
   * @param {Object} item - status card item
   */
  displayBiteTurboModal = (item) => {
    let modal = (
      <Modal
        preset="radio_btns"
        modal_size_class="modal_centered"
        message_text_class="input_description "
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        message_text={'Bite Turbo Removed'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('bite_turbo_removal');
        }}
        radio_btn_options={this.state.bite_turbo_removal}
        onSelectionChange={() => {
          this.radioBtnClicked('bite_turbo_removal');
        }}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * Display DebondingModal
   * @function
   * @param {Object} item - status card item
   * @param {Object} cases - case information
   */
  displayDebondingModal = (item, cases, files_uploaded) => {
    let shipping_date = '';
    if (
      cases &&
      cases.ship_process &&
      cases.ship_process.log &&
      cases.ship_process.log.length > 0 &&
      cases.ship_process.log[0] &&
      cases.ship_process.log[0].date
    ) {
      shipping_date = convertDate(cases.ship_process.log[0].date);
    }

    let modal = (
      <Modal
        preset="navigator_debonding"
        modal_size_class="modal_centered modal_crunch"
        header_text={item.title}
        theme="ipp"
        no_edit_permission={!this.props.has_edit_permission}
        disabledEdit={!this.props.has_edit_permission}
        datearea_text={'Date of debonding'}
        file_upload_text={'Upload final records (optional)'}
        sub_header_text={this.props.getCompletionByName(item.title)}
        datearea_value={this.state.debonding_date}
        datearea_warning_text={shipping_date !== '' ? 'Date must be after the case shipped date: ' + shipping_date : 'Date must be after the case shipped date'}
        onDateAreaChange={(e) => {
          this.onDateAreaChange(e, false);
        }}
        confirm_btn_text="Save"
        close_btn_text="Cancel"
        min_date={shipping_date}
        onCloseButtonClick={this.closeModal}
        onConfirmButtonClick={() => {
          this.onConfirmBtnClicked('debonding_date');
        }}
        isValidDate={(e) => {
          return isValidDate(e, shipping_date, '9999-12-31', true);
        }}
        uploader_id={cases.case_id}
        filesUploaded={files_uploaded}
        upload_content={this.state.files_uploading}
        folder="navigator"
        location="complete"
        multiple={true}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        type="navigator"
        remove_btn={true}
      />
    );
    this.setState({ modalDisplaying: modal });
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - Uploading file object
   */
  onUpload = async (data) => {
    let files_uploaded = this.state.files_uploaded;

    for (let index = 0; index < data.length; index++) {
      const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
      data[index]['file_url'] = file_url;
    }

    if (data && data[0].folder && data[0].folder === 'navigator') {
      files_uploaded = files_uploaded.concat(data);
      this.setState({
        files_uploaded: files_uploaded,
        files_uploading: [],
      });
      this.displayDebondingModal(this.state.current_item, this.props.cases, files_uploaded);
    }
  };

  /**
   * On removing file
   * @function
   * @param {object} event - returns click event of hitting the remove button
   */
  onRemove = (event) => {
    event.preventDefault();

    const tag = event.currentTarget.dataset.href.substring(1);
    let files_uploaded = this.state.files_uploaded;

    files_uploaded = files_uploaded.filter((file) => {
      return file.upload_data !== tag;
    });

    this.setState({
      files_uploaded: files_uploaded,
    });
    this.displayDebondingModal(this.state.current_item, this.props.cases, files_uploaded);
  };

  /**
   * Get no bracket positions and status of position being positioned
   * @function
   * @param {Array} positioned - Array of brackets already positions
   * @return {Object} Object of num bracket positions and their status
   */
  getBracketsAndStatus = (positioned) => {
    let bracket_positions = [];
    const brackets = getBracketPositionings(this.props.cases, false, this.props.ifu_brackets);

    let no_bracket_bonded = brackets ? brackets.sort().reverse() : null;

    if (no_bracket_bonded) {
      brackets.forEach((bracket, index) => {
        const status = positioned ? positioned.includes(bracket) : false;
        bracket_positions.push({ id: index, description: bracket, state: status });
      });
    }

    return bracket_positions;
  };

  /**
   * Get ipr radio button options and status
   * @function
   * @param {Array} smartwire_selected - Array of ipr radio button options
   * @return {Object} Object of  ipr radio button options and their status
   */
  getIprRadioOptions = (ipr) => {
    let ipr_radioBtn_options = [
      { id: 0, description: 'Incomplete', state: ipr !== 'Complete' && ipr !== 'Partially Complete' },
      { id: 1, description: 'Complete', state: ipr === 'Complete' },
      { id: 2, description: 'Partially Complete', state: ipr === 'Partially Complete' },
    ];

    return ipr_radioBtn_options;
  };

  /**
   * Get treatment arch and status
   * @function
   * @param {Array} smartwire_selected - Array of smartwires already selected
   * @return {Object} Object of treatment_arch and their status
   */
  getSmartwiresShouldEngaged = (smartwire_selected) => {
    const arch = getArchToTreat(this.props);
    let treatment_arch = [];
    let state = false;

    if (arch) {
      switch (arch) {
        case 'upper':
          state = smartwire_selected ? smartwire_selected.includes('Upper Smartwire 1') : false;
          treatment_arch.push({ id: 0, description: 'Upper Smartwire 1', state: state });
          break;
        case 'lower':
          state = smartwire_selected ? smartwire_selected.includes('Lower Smartwire 1') : false;
          treatment_arch.push({ id: 0, description: 'Lower Smartwire 1', state: state });
          break;
        case 'both':
          state = smartwire_selected ? smartwire_selected.includes('Upper Smartwire 1') : false;
          treatment_arch.push({ id: 0, description: 'Upper Smartwire 1', state: state });
          state = smartwire_selected ? smartwire_selected.includes('Lower Smartwire 1') : false;
          treatment_arch.push({ id: 1, description: 'Lower Smartwire 1', state: state });
          break;
        default:
          break;
      }
    }
    return treatment_arch;
  };

  /**
   * Get treatment arch and status
   * @function
   * @param {Array} smartwire_selected - Array of smartwires already selected
   * @return {Object} Object of treatment_arch and their status
   */
  getSmartwiresShouldFullyExpress = (smartwire_selected) => {
    const arch = getArchToTreat(this.props);
    let treatment_arch = [];

    if (arch) {
      let state = false;
      const is_de = this.props.cases.case_id.includes('-DE');
      const wire_selections =
        this.props.cases && this.props.cases.production_tx_guide && this.props.cases.production_tx_guide.wire_selections
          ? this.props.cases.production_tx_guide.wire_selections
          : [];

      const upper_smartwire_2_label = `Upper${is_de ? ' DE' : ''} Smartwire 2`;
      const upper_smartwire_3_label = `Upper${is_de ? ' DE' : ''} Smartwire 3`;
      const lower_smartwire_2_label = `Lower${is_de ? ' DE' : ''} Smartwire 2`;
      const lower_smartwire_3_label = `Lower${is_de ? ' DE' : ''} Smartwire 3`;
      const smartwire_labels = [upper_smartwire_2_label, lower_smartwire_2_label, upper_smartwire_3_label, lower_smartwire_3_label];

      for (const wire of smartwire_labels) {
        if (this.hasWire(is_de, wire_selections, wire, arch)) {
          state = smartwire_selected ? smartwire_selected.includes(wire) : false;
          treatment_arch.push({ id: treatment_arch.length, description: wire, state: state });
        }
      }
    }
    return treatment_arch;
  };

  /**
   * Determines if case has smartwire as part of its treatment
   * @function
   * @param {boolean} is_de - Indicator if case is DE or not
   * @param {list} wire_selections - List of wires selected for case's treatment
   * @param {string} wire - Given wire
   * @param {string} arch - Treatment arch
   * @return {boolean} - True or false
   */
  hasWire = (is_de, wire_selections, wire, arch) => {
    return (!is_de || wire_selections.indexOf(wire) !== -1) && (arch === 'both' || wire.toLowerCase().includes(arch));
  };

  /**
   * Get radio btn modal complete status
   * @function
   * @param {Boolean} complete_status - status of completion
   * @param {String} modal_name - Name of the modal
   * @return {Object}  Object containing completion status
   */
  getRadioBtnModalsCompletion = (complete_status, modal_name) => {
    var newState = [];
    if (modal_name) {
      newState = [...this.state[modal_name]];
      newState[0].state = !complete_status;
      newState[1].state = complete_status;
    }
    return newState;
  };

  /**
   * Double-check initial bond date input validation and send data to backend
   * @param {String} id - Id of the checkbox
   * @param {String} modal_name - Name of the modal and the name of the the state
   * @function
   */
  onSelectionChange = (id, modal_name) => {
    let updatedState = '';
    if (modal_name) {
      updatedState = this.state[modal_name];
      if (updatedState) {
        for (const wire of updatedState) {
          if (wire.id === id) {
            wire.state = !wire.state;
            break;
          }
        }
        this.setState({ modal_name: updatedState });
      }
    }
  };

  /**
   * Handle ipr note text change
   * @param {Object} text - User input text
   * @function
   */
  onIprNoteTextChange = (text) => {
    this.setState({ ipr_note: text });
  };

  /**
   * Handle ipr Radio btn click
   * @param {String} id - Id of the checkbox
   * @param {String} status - Completion status of the modal
   * @param {String} title - Title of the modal
   * @function
   */
  onIprRadioClicked = (id, status, title) => {
    var updated_ipr_radioBtn_options = this.getIprRadioOptions(id);

    this.setState({
      ipr_radioBtn_options: updated_ipr_radioBtn_options,
    });
    this.displayIprModal(this.state.current_item, updated_ipr_radioBtn_options);
  };

  /**
   * Handle ipr checkbox click
   * @param {String} id - id of the checkbox
   * @function
   */
  onIprChecked = (id) => {
    var new_ipr_checked = this.state.ipr_checked;
    if (this.state.ipr_checked.includes(id)) {
      for (var i = 0; i < new_ipr_checked.length; i++) {
        if (new_ipr_checked[i] === id) {
          new_ipr_checked.splice(i, 1);
        }
      }
      this.setState({ ipr_checked: new_ipr_checked });
    } else if (!this.state.ipr_checked.includes(id)) {
      new_ipr_checked.push(id);
      this.setState({ ipr_checked: new_ipr_checked });
    }
  };

  /**
   * Handle radio btn click
   * @param {String} modal_name - Name of the modal and the name of the the state
   * @function
   */
  radioBtnClicked = (modal_name) => {
    let updatedState = '';
    if (modal_name) {
      updatedState = this.state[modal_name];
      if (updatedState) {
        updatedState[0].state = !updatedState[0].state;
        updatedState[1].state = !updatedState[1].state;
        this.setState({ modal_name: updatedState });
      }
    }
  };

  /**
   * Get checked checkbox values
   * @param {String} modal_name - Name of the modal and the name of the the state
   * @function
   * @return {Array} Array of checked values
   */
  getCheckboxCheckValue = (modal_name) => {
    var checked = [];
    if (modal_name) {
      this.state[modal_name].forEach((checkbox) => {
        if (checkbox.state) {
          checked.push(checkbox.description);
        }
      });
    }
    return checked;
  };

  /**
   * pack up data to send to backend
   * @function
   */
  getFdataForApi = async () => {
    let fdata = new FormData();
    let initial_bonding_date = this.state.initial_bonding_date;
    let completed_brackets = this.getCheckboxCheckValue('bracket_positions');
    let smartwires_engaged = this.getCheckboxCheckValue('smartwires_engaged');
    let smartwires_fully_expressed = this.getCheckboxCheckValue('smartwires_fully_expressed');
    let inter_arch_mechanics = this.state.inter_arch_mechanics[1].state;
    let bite_turbo_removal = this.state.bite_turbo_removal[1].state;
    let ipr = this.props.ipr ? this.state.ipr_checked : this.getCheckboxCheckValue('ipr_radioBtn_options');
    let ipr_note = this.state.ipr_note;
    let debonding_date = this.state.debonding_date;
    let files_uploaded = this.joinRecordStateToNavigatorFiles();

    fdata.append('initial_bonding_date', initial_bonding_date);
    fdata.append('bracket_positioning', completed_brackets);
    fdata.append('smartwires_engaged', smartwires_engaged);
    fdata.append('smartwires_fully_expressed', smartwires_fully_expressed);
    fdata.append('inter_arch_mechanics', inter_arch_mechanics);
    fdata.append('bite_turbo_removal', bite_turbo_removal);
    fdata.append('ipr', ipr);
    fdata.append('ipr_note', ipr_note);
    fdata.append('debonding_date', debonding_date);
    fdata.append('navigator_files', JSON.stringify(files_uploaded));

    return fdata;
  };
  /**
   * Joins the record state to be pass to the backend for saving
   * @function
   */
  joinRecordStateToNavigatorFiles = () => {
    let files_uploaded = this.state.files_uploaded;
    let record_states = this.props.record_states;

    for (let i = 0; i < files_uploaded.length; i++) {
      for (let j = 0; j < record_states.length; j++){
        if (record_states[j].upload_data === files_uploaded[i].upload_data){
          files_uploaded[i]['record_state'] = record_states[j].state
          break;
        }
      }
    }

    return files_uploaded
  }
  /**
   * Check session data
   * @function
   */
  checkSessionData = async () => {
    const that = this;
    return Axios.get(`/apiv3/case/${that.props.cases.case_id}/navigator`)
      .then((res) => {
        if (JSON.stringify(res.data) !== JSON.stringify(that.state.previous_modal_value.data)) {
          that.setState({ refresh: true });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };
  /**
   * Handle multiple confirm btn clicked
   * @param {String} endpoint - endpoint name
   * @function
   */
  onConfirmBtnClicked = async (endpoint) => {
    await this.checkSessionData();
    const fdata = await this.getFdataForApi();
    const that = this;

    if (endpoint && endpoint !== '' && fdata && !this.state.refresh && !this.state.saving) {
      //Prevent API from hitting x3
      this.setState({saving: true}, () => {
        Axios.post(`/apiv3/case/${this.props.cases.case_id}/navigator/${endpoint}`, fdata)
        .then((res) => {
          that.props.checkStatusCompletion(endpoint);
          that.closeModal();
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          that.setState({saving: false})
        });
      })
    }
  };
  /**
   *Handle on date input change for initial bond date
   * @function
   * @param {Object} item - status card item
   * @return {JSX} JSX element for modal
   */
  onDateAreaChange = (e, bonding) => {
    let inputDate = e.target.value;
    if (bonding) {
      this.setState({ initial_bonding_date: inputDate });
    } else if (!bonding) {
      this.setState({ debonding_date: inputDate });
    }
  };

  /**
   *Get class name based on card completion status, to change card boarder color
   * @function
   * @param {Object} item - status card item
   * @return {String} classname
   */
  getClassByStatus = (item) => {
    let className = 'status_card ';
    if (item && item.title) {
      if (this.props.getCompletionByName(item.title) === 'Complete') {
        className += 'complete_border';
      } else if (this.props.getCompletionByName(item.title) === 'Partially Complete') {
        className += 'partial_complete_border';
      }
    }
    return className;
  };

  /**
   * Close all modal
   * @function
   */
  closeModal = () => {
    this.updateStateWithResData(this.state.previous_modal_value);
    this.setState({ modalDisplaying: '', saving: false });
  };

  render() {
    const { items, cases } = this.props;
    return (
      <div className="status_card_section">
        {items.map((item) => (
          <div className={this.getClassByStatus(item)} key={item.title} onClick={() => this.onCardClick(item, cases)}>
            {this.getIcon(item)}
            <div className="status_card_content">
              <p className="status_card_title">{item.title}</p>
              <div className="status_card_status_text">{this.props.getCompletionByName(item.title) && <p>{this.props.getCompletionByName(item.title)}</p>}</div>
            </div>
            <i className="fa fa-ellipsis-v navigator-right-icon" aria-hidden="true" />
          </div>
        ))}
        {this.state.modalDisplaying}
        {this.state.refresh && <SessionExpire theme="ipp" />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

export default connect(mapStateToProps)(StatusCard);