/**
 * File: edit_patient.js - A component contains modal content for editing a
 * patient's detail
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';

//Internal Functions
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import { getAddressName, sortedAddressByName } from '../../common/helpers';

//Internal Component
import AttributionQuestions from '../../components/attribution_questions/attribution_questions';
import TextBox from '../../doctor/components/inputs/text_box';
import Dropdown from '../../doctor/components/inputs/dropdown';

class EditPatient extends Component {
  /**
   * Display the available value and the current value of the dropdown for doctor field
   * @param {Boolean} canEdit - Permission to allow user is allowed to edit
   * @function
   */
  getAssignedDoctorOptionValue(canEdit) {
    if (canEdit || this.props.smile_simulation) {
      const options = this.props.dso_doctors.map((doctor) => ({
        label: `${doctor.user__first_name} ${doctor.user__last_name}`,
        value: doctor.id,
      }));

      const is_smile = this.props.case_id.slice(0, 3) === 'SS-';

      const selectable_doctors = this.props.dso_doctors.filter((doctor) => {
        return is_smile
          ? doctor.smile && doctor.user__is_active && doctor.id !== doctor.account_link_id
          : doctor.user__is_active && doctor.id !== doctor.account_link_id;
      });

      const options_filtered = selectable_doctors.map((doctor) => ({
        label: `${doctor.user__first_name} ${doctor.user__last_name}`,
        value: doctor.id,
      }));

      const value = options.find((option) => option.value === this.props.dsoDoctorDropdownId);

      return { options: options_filtered, value: value };
    } else {
      return {
        value: { label: this.props.doctor_name },
      };
    }
  }
  /**
   * Display the available value and the current value of the dropdown for address field
   * @param {Boolean} canEdit - Permission to allow user is allowed to edit
   * @function
   */
  getDSOAddressOptionValue(canEdit) {
    if (canEdit || this.props.smile_simulation) {
      const sorted_addresses = sortedAddressByName(this.props.dso_addresses);

      // All options for address even when inactivated
      const options = sorted_addresses.map((address) => ({
        label: getAddressName(address),
        value: address.id,
      }));

      const value = options.find((option) => option.value === this.props.dsoAddressDropdownId);

      const options_filtered = sorted_addresses
        .filter((address) => address.current_ind)
        .map((address) => ({
          label: getAddressName(address),
          value: address.id,
        }));

      return { options: options_filtered, value: value };
    } else {
      return {
        value: { label: this.props.shipping_address_name },
      };
    }
  }
  /**
   * Determines if allowed to edit DSO doctor
   *
   * @function
   * @param {list} permissions - List of user's permissions
   * @returns {boolean} - True or false
   */
  canEditDSODoctor(permissions) {
    const is_smile = this.props.case_id.slice(0, 3) === 'SS-';
    const can_edit_smile = this.canEditSmileDetails();
    const can_edit_case =
      (userHasPermission('DSO_ADMIN', permissions) || userHasPermission('DSO_DOCTOR_OVERRIDE', permissions)) &&
      this.props.account_role !== 'DSO_Doctor' &&
      this.props.case_status !== 'STATUS_CANCEL';

    return is_smile ? can_edit_smile : can_edit_case;
  }
  /**
   * Determines if allowed to edit DSO address
   *
   * @function
   * @param {list} permissions - List of user's permissions
   * @returns {boolean} - True or false
   */
  canEditDSOAddress(permissions) {
    const is_smile = this.props.case_id.slice(0, 3) === 'SS-';
    const can_edit_smile = this.canEditSmileDetails() && this.props.account_role === 'DSO';
    const can_edit_case = userHasPermission('DSO_ADMIN', permissions) && this.props.account_role !== 'DSO_Doctor' && !this.props.inactive_case;

    return is_smile ? can_edit_smile : can_edit_case;
  }
  /**
   * Determines if allowed to edit patient chart no
   *
   * @function
   * @returns {boolean} - True or false
   */
  canEditPatientChartNo = () => {
    const is_smile = this.props.case_id.slice(0, 3) === 'SS-';
    const can_edit_smile = this.props.smile_simulation_status !== 'Cancelled' && this.props.smile_simulation_edit_patient_char;
    const can_edit_case =
      this.props.account_role === 'DSO_Doctor' ? this.props.dso_doctor_info && this.props.account_id === this.props.dso_doctor_info.id : true;
    return is_smile ? can_edit_smile : can_edit_case;
  };

  /**
   * Determines if allowed to smile sim patient details
   *
   * @function
   * @returns {boolean} - True or false
   */
  canEditSmileDetails = () => {
    return this.props.smile_simulation_status !== 'Cancelled' && (this.props.account_role !== 'DSO_Doctor' ? this.props.smile_permission : false);
  };

  render() {
    const inputClass = 'form-control patient-field';

    return (
      !this.props.in_progress && (
        <React.Fragment>
          {this.props.theme === 'ipp' ? (
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                const canEditDSODoctor = this.canEditDSODoctor(user_roles_and_permissions.permissions);
                const canEditDSOAddress = this.canEditDSOAddress(user_roles_and_permissions.permissions);
                return (
                  <React.Fragment>
                    {this.props.dso_doctor_info && (
                      <>
                        <div id="doctor_name" className="form-group patients-edit-info">
                          <label htmlFor="doctorName" className="inline-label emphasis" id="doctorNameLabel">
                            Assigned Doctor
                          </label>
                          <Dropdown
                            id="doctorName"
                            className="patient-field"
                            isDisabled={!canEditDSODoctor}
                            onChange={this.props.onDsoDoctorDropdownChange}
                            {...this.getAssignedDoctorOptionValue(canEditDSODoctor)}
                          />
                        </div>
                        <div id="shipping_address" className="form-group patients-edit-info">
                          <label htmlFor="shippingAddress" className="inline-label emphasis" id="shippingAddressLabel">
                            Shipping Address
                          </label>
                          <Dropdown
                            id="shippingAddress"
                            className="patient-field"
                            isDisabled={!canEditDSOAddress}
                            onChange={this.props.onDsoAddressDropdownChange}
                            {...this.getDSOAddressOptionValue(canEditDSOAddress)}
                          />
                        </div>
                      </>
                    )}
                    <div id="case_id" className="form-group patients-edit-info">
                      <label htmlFor="caseId" className="inline-label emphasis" id="caseIdLabel">
                        {this.props.case_id.slice(0, 3) === 'SS-' ? 'ID' : 'Case ID'}
                      </label>
                      <div className="inline-input">
                        <TextBox required id="caseId" type="text" value={this.props.case_id} className={inputClass} maxLength="30" disabled />
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            </UserPermissionsContext.Consumer>
          ) : null}
          <div id="patient_info" className="form-group patients-edit-info">
            <label htmlFor="patientFirstName" className="inline-label emphasis" id="patientFirstNameLabel">
              {this.props.case_id.slice(0, 3) === 'SS-' ? 'First Name' : 'Patient First Name'}
            </label>
            <div className="inline-input">
              <TextBox
                required
                id="patientFirstName"
                type="text"
                value={this.props.patient_firstname}
                className={inputClass}
                onChange={this.props.onPatientFirstNameChange}
                maxLength="30"
                disabled={this.props.theme === 'ipp'}
              />
            </div>
          </div>
          <div className="form-group patients-edit-info">
            <label htmlFor="patientLastName" className="inline-label emphasis" id="patientLastNameLabel">
              {this.props.case_id.slice(0, 3) === 'SS-' ? 'Last Name' : 'Patient Last Name'}
            </label>
            <div className="inline-input">
              <TextBox
                required
                id="patientLastName"
                type="text"
                value={this.props.patient_lastname}
                className={inputClass}
                onChange={this.props.onPatientLastNameChange}
                maxLength="30"
                disabled={this.props.theme === 'ipp'}
              />
            </div>
          </div>
          <div className="form-group patients-edit-info">
            <label htmlFor="patientDOB" className="inline-label emphasis" id="patientDOBLabel">
              Date of Birth
            </label>
            <div className="inline-input">
              <TextBox
                required
                id="patientDOB"
                type="date"
                className={`${inputClass} ${this.props.patient_dob_error ? 'warning-border' : ''}` }
                value={this.props.patient_dob}
                onChange={this.props.onPatientDobChange}
                disabled={this.props.theme === 'ipp'}
              />
            </div>
          </div>
          <div className="form-group patients-edit-info">
            <label id="patientSex" htmlFor="sex" className="inline-label emphasis">
              Sex
            </label>
            <div className="inline-input">
              <span className="radio-spacing">
                <input
                  type="radio"
                  name="sex"
                  value="F"
                  onChange={this.props.onPatientSexChange}
                  defaultChecked={this.props.patient_sex === 'F'}
                  disabled={this.props.theme === 'ipp'}
                />{' '}
                Female
              </span>
              <span className="radio-spacing">
                <input
                  type="radio"
                  name="sex"
                  value="M"
                  onChange={this.props.onPatientSexChange}
                  defaultChecked={this.props.patient_sex === 'M'}
                  disabled={this.props.theme === 'ipp'}
                />{' '}
                Male
              </span>
              <span className="radio-spacing">
                <input
                  type="radio"
                  name="sex"
                  value="N"
                  onChange={this.props.onPatientSexChange}
                  defaultChecked={this.props.patient_sex === 'N'}
                  disabled={this.props.theme === 'ipp'}
                />{' '}
                Non-Binary
              </span>
              <input
                type="radio"
                name="sex"
                value="U"
                onChange={this.props.onPatientSexChange}
                defaultChecked={this.props.patient_sex === 'U'}
                disabled={this.props.theme === 'ipp'}
              />{' '}
              Undisclosed
              <br />
            </div>
          </div>
          <div className="form-group patients-edit-info">
            <label htmlFor="patientRef" className={`inline-label emphasis${this.props.edit_patient_error ? ' warning-text' : ''}`}>
              Patient Chart No
            </label>
            <div className="inline-input">
              {this.props.theme === 'ipp' ? (
                <TextBox
                  id="patientRef"
                  type="text"
                  value={this.props.patient_ref}
                  className={`${inputClass}${this.props.edit_patient_error ? ' warning-border' : ''}`}
                  onChange={this.props.onPatientRefChange}
                  maxLength="50"
                  disabled={!this.canEditPatientChartNo()}
                />
              ) : (
                <span>{this.props.patient_ref ? this.props.patient_ref : 'N/A'}</span>
              )}
            </div>
          </div>

          {this.props.patient_referral_code ? (
            <div className="form-group patients-edit-info">
              <label htmlFor="patientReferralCode" className="inline-label col-form-label right-text" id="patientReferralCodeLabel">
                Referral Code:
              </label>
              <div className="inline-input">
                <input
                  required
                  id="patientReferralCode"
                  type="text"
                  value={this.props.patient_referral_code}
                  className={inputClass}
                  maxLength="30"
                  disabled={true}
                />
              </div>
            </div>
          ) : null}

          {this.props.attribution_questions ? (
            <div className="form-group patients-edit-info" style={{ paddingBottom: '10px' }}>
              <AttributionQuestions
                questions={this.props.attribution_questions}
                attributions={this.props.attributions}
                onAttributionsChange={this.props.onAttributionsChange}
                onAttributionOtherChange={this.props.onAttributionOtherChange}
                is_attribution_allowed={this.props.is_attribution_allowed}
              />
            </div>
          ) : null}
        </React.Fragment>
      )
    );
  }
}
export default EditPatient;
