import React from 'react';
import PaneSection from './pane_section';

/**
 * Displays the two pane section of split screen
 * @component
 */
export default function Pane(props) {
  return (
    <div className="pane">
      <PaneSection {...props} pane={1} onClick={props.toggleCollapsePane1} collapse={props.collapse_pane_1} side_collapse={props.collapse} />
      <PaneSection {...props} pane={2} onClick={props.toggleCollapsePane2} collapse={props.collapse_pane_2} side_collapse={props.collapse} />
    </div>
  );
}
