import {
  FETCH_DOCTOR_CASE_FILTER_COUNT_START,
  FETCH_DOCTOR_CASE_FILTER_COUNT_SUCCESS,
  FETCH_DOCTOR_CASE_FILTER_COUNT_ERROR,
  FETCH_LOGGED_DOCTOR_CASE_COUNT_START,
  FETCH_LOGGED_DOCTOR_CASE_COUNT_SUCCESS,
  FETCH_LOGGED_DOCTOR_CASE_COUNT_ERROR,
} from '../../../actions/ipp/case_list/case_list_filter';

const initialState = {
  filter_count: {},
  loggedDoctorCaseCount: {},
  fetchLoggedDoctorCaseCountStart: false,
  fetchLoggedDoctorCaseCountSuccess: false,
  fetchLoggedDoctorCaseCountError: null,
  loading: true,
  error: null,
};

export function doctorCaseListFilterCountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCTOR_CASE_FILTER_COUNT_START:
      return { ...state, loading: true };

    case FETCH_DOCTOR_CASE_FILTER_COUNT_SUCCESS:
      return {
        ...state,
        filter_count: {
          ...state.filter_count,
          [action.case_type]: action.case_filter_count,
        },
        loading: false,
      };

    case FETCH_DOCTOR_CASE_FILTER_COUNT_ERROR:
      return { ...state, error: action.error, loading: false };

    case FETCH_LOGGED_DOCTOR_CASE_COUNT_START:
      return { ...state, fetchLoggedDoctorCaseCountStart: true, fetchLoggedDoctorCaseCountSuccess: false, fetchLoggedDoctorCaseCountError: null };

    case FETCH_LOGGED_DOCTOR_CASE_COUNT_SUCCESS:
      return {
        ...state,
        loggedDoctorCaseCount: action.case_count,
        fetchLoggedDoctorCaseCountStart: false,
        fetchLoggedDoctorCaseCountSuccess: true,
        fetchLoggedDoctorCaseCountError: null,
      };

    case FETCH_LOGGED_DOCTOR_CASE_COUNT_ERROR:
      return { ...state, fetchLoggedDoctorCaseCountStart: false, fetchLoggedDoctorCaseCountSuccess: false, fetchLoggedDoctorCaseCountError: action.error };

    default:
      return state;
  }
}

export const getDoctorCaseListFilterCount = (state) => state.doctorCaseListFilterCountReducer.filter_count;
export const getDoctorCaseListFilterCountLoading = (state) => state.doctorCaseListFilterCountReducer.loading;
export const getDoctorCaseListFilterCountError = (state) => state.doctorCaseListFilterCountReducer.error;
export const getLoggedDoctorCaseCount = (state) => state.doctorCaseListFilterCountReducer.loggedDoctorCaseCount;
export const getLoggedDoctorCaseCountStart = (state) => state.doctorCaseListFilterCountReducer.fetchLoggedDoctorCaseCountStart;
export const getLoggedDoctorCaseCountSuccess = (state) => state.doctorCaseListFilterCountReducer.fetchLoggedDoctorCaseCountSuccess;
export const getLoggedDoctorCaseCountError = (state) => state.doctorCaseListFilterCountReducer.fetchLoggedDoctorCaseCountError;
