/**
 * File: progress_notes.js - A component that display the notes for the all progress records
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './notes.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
// Internal Functions
import { setTokenHeader, textFieldCheck, removeEmoji } from '../../common/functions';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class ProgressNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseNotes: [],
      loading: true,
      note: '',
      isEdit: '',
      isDelete: '',
      sort: true,
      sort_method: 'desc',
      user_id: '',
    };

    this.setNotes = this.setNotes.bind(this);
  }

  onNoteChange = (e) => {
    if (e.key === 'ENTER') {
      this.onNoteSubmission();
    } else {
      this.setState({
        note: removeEmoji(textFieldCheck(e.target.value)),
      });
    }
  };

  setNotes = (notes) => {
    return notes;
  };

  onNoteSubmission = (e) => {
    setTokenHeader();

    if (this.state.note !== '' && this.props.progress_id !== '') {
      let that = this;
      let form_data = new FormData();
      const status_comment = this.state.note;
      form_data.append('status_comment', status_comment);
      form_data.append('generic', true);
      Axios.post(`/apiV2/praction/${this.props.progress_id}/add_notes`, form_data).then(function (res) {
        let progress_notes = [];
        res.data.progress_record
          .slice(0)
          .reverse()
          .map((progress_record, index) => {
            let status = '';
            if (progress_record.status === 'Request Closed') {
              status = progress_record.status;
            }
            if (progress_record.notes && progress_record.notes.length > 0) {
              progress_record.notes.forEach((note) =>
                progress_notes.push({
                  id: note.id,
                  no: res.data.progress_record.length - index,
                  type: progress_record.record_type,
                  note_text: note.note_text,
                  user: note.user,
                  date: note.date,
                  status: status,
                  generic: note.generic,
                  created_date: note.created_date,
                  created_by_id: note.created_by_id,
                })
              );
            }

            return '';
          });
        that.setState({
          caseNotes: that.setNotes(progress_notes),
          note: '',
        });
        that.props.setNotesIndicator(progress_notes);
      });
    }
  };

  toggleNoteSort = () => {
    const sort_type = this.state.sort_type === 'desc' ? 'asc' : 'desc';
    let prorgress_notes = _.orderBy(this.state.caseNotes, 'created_date', sort_type);
    this.setState({
      caseNotes: prorgress_notes,
      sort_type: sort_type,
    });
  };

  componentDidMount() {
    let that = this;
    let progress_notes = this.props.progress_notes;
    that.setState({
      user_id: this.props.user_id,
      caseNotes: progress_notes,
      loading: false,
    });
  }

  enableEditDeleteButton(date, user_id) {
    let start = new Date(date);
    let end = new Date();
    let result = false;
    if (end.getTime() - start.getTime() < 3600000 && this.state.user_id && parseInt(this.state.user_id) === parseInt(user_id)) {
      result = true;
    }

    return result;
  }

  setNotes = (notes) => {
    if (!this.state.sort) {
      notes = notes.reverse();
    }

    return notes;
  };

  onEditNoteSubmission = (e) => {
    setTokenHeader();

    if (this.state.note !== '') {
      let that = this;
      let formData = new FormData();
      formData.append('note_text', this.state.note);
      formData.append('note_id', this.state.isEdit);
      Axios.post(`/apiV2/praction/${this.props.progress_id}/edit_note`, formData).then(function (res) {
        let progress_notes = [];
        res.data.progress_record
          .slice(0)
          .reverse()
          .map((progress_record, index) => {
            let status = '';
            if (progress_record.status === 'Request Closed') {
              status = progress_record.status;
            }
            if (progress_record.notes && progress_record.notes.length > 0) {
              progress_record.notes.forEach((note) =>
                progress_notes.push({
                  id: note.id,
                  no: res.data.progress_record.length - index,
                  type: progress_record.record_type,
                  note_text: note.note_text,
                  user: note.user,
                  date: note.date,
                  status: status,
                  generic: note.generic,
                  created_date: note.created_date,
                  created_by_id: note.created_by_id,
                })
              );
            }

            return '';
          });
        that.setState({
          caseNotes: that.setNotes(progress_notes),
          note: '',
          isEdit: '',
        });
        that.props.setNotesIndicator(progress_notes);
      });
    }
  };

  onDeleteNoteSubmission = (e) => {
    setTokenHeader();

    if (e.target.dataset.note_id) {
      let that = this;
      let note_id = e.target.dataset.note_id;
      let data = {
        note_id,
      };
      Axios.delete(`/apiV2/praction/${this.props.progress_id}/delete_note`, { data }).then(function (res) {
        let isEdit = that.state.isEdit;
        let note = that.state.note;
        if (note_id === that.state.isEdit) {
          isEdit = '';
          note = '';
        }
        let progress_notes = [];
        res.data.progress_record
          .slice(0)
          .reverse()
          .map((progress_record, index) => {
            let status = '';
            if (progress_record.status === 'Request Closed') {
              status = progress_record.status;
            }
            if (progress_record.notes && progress_record.notes.length > 0) {
              progress_record.notes.forEach((note) =>
                progress_notes.push({
                  id: note.id,
                  no: res.data.progress_record.length - index,
                  type: progress_record.record_type,
                  note_text: note.note_text,
                  user: note.user,
                  date: note.date,
                  status: status,
                  generic: note.generic,
                  created_date: note.created_date,
                  created_by_id: note.created_by_id,
                })
              );
            }

            return '';
          });
        that.setState({
          caseNotes: that.setNotes(progress_notes),
          isEdit: isEdit,
          note: note,
          isDelete: '',
        });
        that.props.setNotesIndicator(progress_notes);
      });
    }
  };

  setEditSubmission = (e) => {
    this.setState({
      isEdit: e.target.dataset.note_id,
      note: e.target.dataset.note_text,
    });
  };

  setDeleteSubmission = (e) => {
    this.setState({
      isDelete: e.target.dataset.note_id,
    });
  };

  cancelDeleteSubmission = (e) => {
    this.setState({
      isDelete: '',
    });
  };

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <React.Fragment>
              {userHasPermission('PROGRESS_VIEW', user_roles_and_permissions.permissions) ? (
                <div className="modal-condense-top">
                  {this.state.caseNotes.length === 0 ? (
                    <div className="no-notes">No notes were found for this case.</div>
                  ) : (
                    <table className="table table-note">
                      <thead>
                        <tr>
                          <th className="table-comments">Notes</th>
                          <th className="table-date-time" onClick={this.toggleNoteSort}>
                            Created Date Time{' '}
                            {this.state.sort ? (
                              <i className="fa fa-caret-down pull-right" aria-hidden="true" onClick={this.toggleNoteSort} />
                            ) : (
                              <i className="fa fa-caret-up pull-right" aria-hidden="true" onClick={this.toggleNoteSort} />
                            )}
                          </th>
                          <th className="table-created-by">Created By</th>
                          <th className="table-action" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.caseNotes.map((note, index) => {
                          return (
                            <tr
                              className={
                                parseInt(this.state.isEdit) === parseInt(note.id)
                                  ? 'edit-text'
                                  : parseInt(this.state.isDelete) === parseInt(note.id)
                                  ? 'delete-text'
                                  : this.state.isEdit
                              }
                              key={index}
                            >
                              <td className="wrap-text">
                                {!note.generic ? (
                                  <span className="bold-text">
                                    No.{note.no} {note.type} -{' '}
                                  </span>
                                ) : null}

                                {note.note_text}
                              </td>
                              <td className="table-date-time">{note.date}</td>
                              <td className="table-created-by">{note.user}</td>
                              <td className="table-action">
                                {this.enableEditDeleteButton(note.created_date, note.created_by_id) ? (
                                  <span className="">
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                      data-note_id={note.id}
                                      data-note_text={note.note_text}
                                      onClick={this.setEditSubmission}
                                    />{' '}
                                    <i className="fa fa-trash-o" aria-hidden="true" data-note_id={note.id} onClick={this.setDeleteSubmission} />
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : null}
              {userHasPermission('PROGRESS_NOTES_CREATE', user_roles_and_permissions.permissions) ? (
                <div className="modal-condense">
                  {this.state.isDelete ? (
                    <div className="text-center">
                      <div className="text-message">Are you sure you want to delete this note? </div>{' '}
                      <button type="button" className="btn btn-light" data-note_id={this.state.isDelete} onClick={this.onDeleteNoteSubmission}>
                        Delete
                      </button>
                      <button type="button" className="btn btn-default btn-link-round btn-space-general" onClick={this.cancelDeleteSubmission}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <textarea
                        className={this.state.textarea_maxlength_active ? 'text-notes error-notes' : 'text-notes'}
                        rows="5"
                        placeholder="Enter note here..."
                        maxLength={999}
                        onChange={this.onNoteChange}
                        value={this.state.note}
                      />

                      {this.state.textarea_maxlength_active ? (
                        <div className="modal-error">
                          <p>{this.state.textarea_maxlength_message}</p>
                        </div>
                      ) : null}

                      <div className="text-center">
                        {this.state.isEdit ? (
                          <button type="button" className="btn btn-light" onClick={this.onEditNoteSubmission}>
                            Finish Editing Note
                          </button>
                        ) : (
                          <button type="button" className="btn btn-light" onClick={this.onNoteSubmission}>
                            Create Note
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </React.Fragment>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default ProgressNotes;
