import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class BusinessHome extends Component {
  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          if (user_roles_and_permissions && user_roles_and_permissions.permissions_loaded) {
            if (userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions)) {
              return this.props.history.push('/business/portal/cases');
            } else if (userHasPermission('IREQ_VIEW', user_roles_and_permissions.permissions)) {
              return this.props.history.push('/business/portal/item');
            } else if (userHasPermission('ACCOUNT_BPP_VIEW', user_roles_and_permissions.permissions)) {
              return this.props.history.push('/business/portal/accounts');
            } else {
              if (user_roles_and_permissions['doctor_role'] === 'Business' || user_roles_and_permissions['doctor_role'] === 'Admin') {
                this.props.history.push('/business/portal/accounts?filter=profile');
              } else {
                this.props.history.push('/');
              }
            }
          }
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}
export default withRouter(BusinessHome);
