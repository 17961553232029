import {
  FETCH_DRAFT_PROFILE_PICTURE_PENDING,
  FETCH_DRAFT_PROFILE_PICTURE_SUCCESS,
  FETCH_DRAFT_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS,
  FETCH_DRAT_PROFILE_PICTURE_ERROR,
  SET_DRAFT_PROFILE_METADATA_EDIT,
  SET_DRAFT_PROFILE_IMAGE,
  DRAFT_OPEN_UPLOAD_PICTURE_MODAL,
  DRAFT_CLOSE_UPLOAD_PICTURE_MODAL,
  DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_PENDING,
  DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS,
  SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS,
  SAVE_PROFILE_PICTURE_REPOSITION_ERROR,
  DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_MODAL,
  DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL,
  DRAFT_CLOSE_REPOSITION_PROFILE_PICTURE_MODAL,
  DRAFT_OPEN_PROFILE_PICTURE_MENU,
  DRAFT_CLOSE_PROFILE_PICTURE_MENU,
  DRAFT_OPEN_DELETE_PICTURE_MODAL,
  DRAFT_CLOSE_DELETE_PICTURE_MODAL,
  DRAFT_DELETE_PROFILE_PICTURE_PENDING,
  DRAFT_DELETE_PROFILE_PICTURE_SUCCESS,
  DRAFT_DELETE_PROFILE_PICTURE_ERROR,
  DRAFT_UPLOAD_PROFILE_PICTURE_PENDING,
  DRAFT_UPLOAD_PROFILE_PICTURE_SUCCESS,
  DRAFT_UPLOAD_PROFILE_PICTURE_ERROR,
  DRAFT_INVALID_PROFILE_PICTURE_SIZE,
  DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR,
} from '../../actions/ipp/profile_picture_draft';

const initialState = {
  pending: false,
  error: false,
  draft_profile_image: '',
  draft_profile_image_dso_doctor: '',
  draft_profile_image_edit: '',
  draft_profile_metadata: {},
  draft_profile_metadata_dso_doctor: {},
  draft_profile_metadata_edit: {},
  draft_upload_picture_modal: false,
  draft_save_profile_picture_pending: false,
  draft_save_profile_picture_error: false,
  draft_reposition_profile_picture_modal: false,
  draft_profile_picture_menu: false,
  draft_delete_picture_modal: false,
  draft_delete_picture_pending: false,
  draft_upload_picture_pending: false,
  draft_upload_picture_error: false,
  draft_invalid_profile_picture_format_error: false,
  draft_invalid_profile_picture_size: false,
  draft_s3_url_raw: '',
  draft_s3_url_thumbnail: '',
};

export function profilePictureDraftReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRAFT_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_DRAFT_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        pending: false,
        draft_profile_image: action.draft_profile_image,
        draft_profile_metadata: action.draft_profile_metadata,
        draft_s3_url_raw: action.draft_s3_url_raw,
        draft_s3_url_thumbnail: action.draft_s3_url_thumbnail,
      };

    case FETCH_DRAFT_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS:
      return {
        ...state,
        pending: false,
        draft_profile_image_dso_doctor: action.draft_profile_image_dso_doctor,
        draft_profile_metadata_dso_doctor: action.draft_profile_metadata_dso_doctor,
        draft_s3_url_raw: action.draft_s3_url_raw,
        draft_s3_url_thumbnail: action.draft_s3_url_thumbnail,
      };

    case FETCH_DRAT_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      };

    case DRAFT_OPEN_UPLOAD_PICTURE_MODAL:
      return {
        ...state,
        draft_upload_picture_modal: true,
      };

    case DRAFT_CLOSE_UPLOAD_PICTURE_MODAL:
      return {
        ...state,
        draft_upload_picture_modal: false,
        draft_upload_picture_error: false,
        draft_invalid_profile_picture_format_error: false,
        draft_invalid_profile_picture_size: false,
      };

    case DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_PENDING:
      return {
        ...state,
        draft_save_profile_picture_pending: true,
      };

    case DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS:
      return {
        ...state,
        draft_save_profile_picture_pending: false,
        draft_reposition_profile_picture_modal: false,
        reposition_profile_picture_modal: false,
        draft_profile_image: state.draft_profile_image_edit,
        draft_profile_metadata: state.draft_profile_metadata_edit,
      };

    case SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS:
      return {
        ...state,
        draft_save_profile_picture_pending: false,
        draft_reposition_profile_picture_modal: false,
        reposition_profile_picture_modal: false,
        draft_profile_image_dso_doctor: state.draft_profile_image_edit,
        draft_profile_metadata_dso_doctor: state.draft_profile_metadata_edit,
      };

    case SAVE_PROFILE_PICTURE_REPOSITION_ERROR:
      return {
        ...state,
        draft_save_profile_picture_pending: false,
        draft_save_profile_picture_error: true,
      };

    case SET_DRAFT_PROFILE_METADATA_EDIT:
      return {
        ...state,
        draft_profile_metadata_edit: action.draft_profile_metadata_edit,
      };

    case SET_DRAFT_PROFILE_IMAGE:
      return {
        ...state,
        draft_profile_image: action.draft_profile_image,
      };

    case DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_MODAL:
      return {
        ...state,
        draft_reposition_profile_picture_modal: true,
        draft_profile_picture_menu: false,
        draft_profile_image_edit: state.draft_profile_image,
        draft_profile_metadata_edit: state.draft_profile_metadata,
      };

    case DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL:
      return {
        ...state,
        draft_reposition_profile_picture_modal: true,
        draft_profile_picture_menu: false,
        draft_profile_image_edit: state.draft_profile_image_dso_doctor,
        draft_profile_metadata_edit: state.draft_profile_metadata_dso_doctor,
      };

    case DRAFT_CLOSE_REPOSITION_PROFILE_PICTURE_MODAL:
      return {
        ...state,
        draft_reposition_profile_picture_modal: false,
        reposition_profile_picture_modal: false,
      };

    case DRAFT_OPEN_PROFILE_PICTURE_MENU:
      return {
        ...state,
        draft_profile_picture_menu: true,
      };

    case DRAFT_CLOSE_PROFILE_PICTURE_MENU:
      return {
        ...state,
        draft_profile_picture_menu: false,
      };

    case DRAFT_OPEN_DELETE_PICTURE_MODAL:
      return {
        ...state,
        draft_delete_picture_modal: true,
        draft_profile_picture_menu: false,
      };

    case DRAFT_CLOSE_DELETE_PICTURE_MODAL:
      return {
        ...state,
        draft_delete_picture_modal: false,
      };

    case DRAFT_DELETE_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        draft_delete_picture_pending: true,
      };

    case DRAFT_DELETE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        draft_delete_picture_pending: false,
        draft_delete_picture_modal: false,
        draft_profile_image: action.draft_profile_image,
        draft_profile_metadata: action.draft_profile_metadata,
        draft_s3_url_raw: action.draft_s3_url_raw,
        draft_s3_url_thumbnail: action.draft_s3_url_thumbnail,
      };

    case DRAFT_DELETE_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        draft_delete_picture_pending: false,
      };

    case DRAFT_UPLOAD_PROFILE_PICTURE_PENDING:
      return {
        ...state,
        draft_upload_picture_pending: true,
        draft_upload_picture_modal: true,
      };

    case DRAFT_UPLOAD_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        draft_upload_picture_modal: false,
        draft_upload_picture_pending: false,
        draft_profile_image_edit: action.draft_profile_image_edit,
        draft_profile_metadata_edit: {
          x: '',
          y: '',
          width: '',
          height: '',
        },
        draft_reposition_profile_picture_modal: true,
        draft_s3_url_raw: action.draft_s3_url_raw,
        draft_s3_url_thumbnail: action.draft_s3_url_thumbnail,
      };

    case DRAFT_UPLOAD_PROFILE_PICTURE_ERROR:
      return {
        ...state,
        draft_upload_picture_error: true,
        draft_upload_picture_pending: false,
      };

    case DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR:
      return {
        ...state,
        draft_invalid_profile_picture_format_error: true,
        draft_upload_picture_pending: false,
      };

    case DRAFT_INVALID_PROFILE_PICTURE_SIZE:
      return {
        ...state,
        draft_invalid_profile_picture_size: true,
        draft_upload_picture_pending: false,
      };

    default:
      return state;
  }
}

export const getProfileImage = (state) => state.profilePictureDraftReducer.draft_profile_image;
export const getProfileImageDsoDoctor = (state) => state.profilePictureDraftReducer.draft_profile_image_dso_doctor;
export const getProfileMetadata = (state) => state.profilePictureDraftReducer.draft_profile_metadata;
export const getProfileMetadataDsoDoctor = (state) => state.profilePictureDraftReducer.draft_profile_metadata_dso_doctor;
export const getUploadPictureModal = (state) => state.profilePictureDraftReducer.draft_upload_picture_modal;
export const getUploadPicturePending = (state) => state.profilePictureDraftReducer.draft_upload_picture_pending;
export const getUploadPictureError = (state) => state.profilePictureDraftReducer.draft_upload_picture_error;
export const getInvalidProfilePictureFormatError = (state) => state.profilePictureDraftReducer.draft_invalid_profile_picture_format_error;
export const getInvalidProfilePictureSize = (state) => state.profilePictureDraftReducer.draft_invalid_profile_picture_size;
export const getRepositionProfilePictureModal = (state) => state.profilePictureDraftReducer.draft_reposition_profile_picture_modal;
export const getProfilePictureMenu = (state) => state.profilePictureDraftReducer.draft_profile_picture_menu;
export const getDeletePictureModal = (state) => state.profilePictureDraftReducer.draft_delete_picture_modal;
export const getProfileImageEdit = (state) => state.profilePictureDraftReducer.draft_profile_image_edit;
export const getProfileMetadataEdit = (state) => state.profilePictureDraftReducer.draft_profile_metadata_edit;
