import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

function PaneViewerNavigateControls(props) {
  const { showLeftButton, showRightButton, pane, leftButtonOnClick, rightButtonOnClick } = props;

  return (
    <div className="pane-viewer-navigation-controls">
      {showLeftButton && <KeyboardArrowLeftIcon className="pane-viewer-navigation--prev" data-pane={pane} onClick={leftButtonOnClick} />}
      {showRightButton && <KeyboardArrowLeftIcon className="pane-viewer-navigation--next" data-pane={pane} onClick={rightButtonOnClick} />}
    </div>
  );
}

export default PaneViewerNavigateControls;
