import React from 'react';
import CheckBox from '../checkbox/checkbox';
import './ipr_teech_chart.scss';
import { v4 as uuidv4 } from 'uuid';

const TEETH = [
  ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'],
  ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'],
  ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'],
  ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'],
];

let count = 1;

/**
 * Function increasing the globle variable count
 * @function
 */
function addCount() {
  count++;
}

/**
 * Component displaying the teeth chart checkboxes
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart upper bubble
 */
function getUpperBubble(props, tooth) {
  const current_bracket = props && props.selections && props.selections.filter((bracket) => bracket.description === tooth);
  const isUpperArch = tooth && tooth.charAt(0) && tooth.charAt(0) === 'U';
  if (current_bracket && current_bracket[0]) {
    return (
      <div className={isUpperArch ? 'upper_checkboxes' : 'lower_checkboxes'}>
        <CheckBox
          id={current_bracket.id}
          className=" ipr_teeth_chart_checkbox"
          label_className="ipr_teeth_chart_checkbox_label"
          toggleChange={() => props.onSelectionChange(current_bracket[0].id)}
          isChecked={current_bracket[0].state}
        />
      </div>
    );
  }
}

/**
 * Component displaying the teeth chart
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart note
 */
function getToothChart(props) {
  let has_bracket_position = false;
  count = 1;

  return (
    <div className="ipr_teeth_chart">
      {' '}
      {TEETH.map((arch, arch_index) => (
        <div className={arch[0] + '_row ipr_teeth_row'} key={uuidv4()}>
          {arch.map((tooth) => (
            <span key={uuidv4()}>
              <div style={{ display: 'inline-block' }} key={uuidv4()}>
                {(has_bracket_position = props.selections && props.selections.some((bracket) => bracket.description === tooth))}
                {has_bracket_position && arch_index < 2 ? getUpperBubble(props, tooth) : ''}
                <p className={props.missing_teeth && props.missing_teeth.includes(count) ? 'ipr_char_tooth ipr_char_tooth_missing' : 'ipr_char_tooth'}>
                  {tooth}
                </p>
                {has_bracket_position && arch_index >= 2 ? getUpperBubble(props, tooth) : ''}
                {addCount()}
              </div>
            </span>
          ))}{' '}
        </div>
      ))}
    </div>
  );
}

/**
 * Component displaying the teeth chart with checkboxes
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for Ipr teeth chart
 */
function TeethChartWithCheckboxes(props) {
  return <div>{getToothChart(props)}</div>;
}

export default TeethChartWithCheckboxes;
