import React from 'react';
import { Box } from '@material-ui/core';

import ManualWireTableLengthValue from './manual_wire_table_length_value'
import ManualWireTableEmptyElement from '../manual_wire_table_empty_element/manual_wire_table_empty_element';
import { getFilteredIndexes } from '../../manual_wire_utils';
import './manual_wire_table_length.scss';

const ManualWireLength = ({ arch, missingTeeths, range, disableAllFields, generatedLength }) => {
    const filteredIndexes = getFilteredIndexes(arch, missingTeeths, range, 15);

    return (
        <Box display="flex" flexDirection="row" className='manual-wire-table-length' component="div">
            <ManualWireTableEmptyElement />
            {filteredIndexes.map((teethIndex, index) => (
                <ManualWireTableLengthValue
                    arch={arch}
                    index={teethIndex}
                    key={`manual-wire-table-length-${index}`}
                    disableAllFields={disableAllFields}
                    generatedLength={generatedLength}
                />
            ))}
            <ManualWireTableEmptyElement />
        </Box>
    )
};

export default ManualWireLength;
