import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { EBoltonExcessType } from '../../wasm-constants';
import { WasmBoltonTablePart } from './wasm-bolton-table-part';

// 3-3
// UR1, UR2, UR3, UL1, UL2, UL3, LL1, LL2, LL3, LR1, LR2, LR3
const upperLower3 = ['11', '12', '13', '21', '22', '23', '31', '32', '33', '41', '42', '43'];

// 6-6
// UR1, UR2, UR3, UL1, UL2, UL3, LL1, LL2, LL3, LR1, LR2, LR3, UR4, UR5, UR6, UL4, UL5, UL6, LL4, LL5, LL6, LR4, LR5, LR6
const upperLower6 = [
  '11',
  '12',
  '13',
  '21',
  '22',
  '23',
  '31',
  '32',
  '33',
  '41',
  '42',
  '43',
  '14',
  '15',
  '16',
  '24',
  '25',
  '26',
  '34',
  '35',
  '36',
  '44',
  '45',
  '46',
];

/**
 * Formats excess to 0.00 format
 * @param {number} excess
 * @returns {string}
 */
const formatExcess = (excess) => Math.abs(excess).toFixed(2);

/**
 * Filters bolton widths by range
 * @param {Array<[string, string]>} entries
 * @param {number} start
 * @param {number} end
 * @returns {Array<[string, string]>}
 */
const filterBoltonValuesByRange = (entries, start, end) => entries.filter((item) => item[0] >= start && item[0] <= end);

/**
 * Checks if there are missing teeth in the current set of teeth.
 *
 * @param {Array<number>} currentTeeth - The array of current teeth.
 * @param {Array<number>} allTeeth - The array of all teeth.
 * @returns {boolean} - True if there are missing teeth, false otherwise.
 */
const hasMissingTeeth = (currentTeeth, allTeeth) => allTeeth.some((tooth) => !currentTeeth.includes(tooth));

const WasmBoltonValueUnavailable = () => (
  <div className="wasm-bolton-value-container">
    <div className="wasm-bolton-value">N/A</div>
    <div className="wasm-bolton-overall">
      Analysis
      <br />
      unavailable due to
      <br />
      missing teeth
    </div>
  </div>
);

/**
 * Calculates the lower correction value based on the sum of maxillary, sum of mandibular, and estimated index.
 *
 * @param {number} sumOfMaxillary - The sum of maxillary value.
 * @param {number} sumOfMandibular - The sum of mandibular value.
 * @param {number} estimatedIndex - The estimated index value.
 * @returns {number} The lower correction value.
 */
const getLowerCorrection = (sumOfMaxillary, sumOfMandibular, estimatedIndex) => {
  return sumOfMandibular - sumOfMaxillary * (estimatedIndex / 100);
};

/**
 * Calculates the upper correction value based on the sum of maxillary, sum of mandibular, and estimated index.
 *
 * @param {number} sumOfMaxillary - The sum of maxillary value.
 * @param {number} sumOfMandibular - The sum of mandibular value.
 * @param {number} estimatedIndex - The estimated index value.
 * @returns {number} The upper correction value.
 */
const getUpperCorrection = (sumOfMaxillary, sumOfMandibular, estimatedIndex) => {
  return sumOfMaxillary - sumOfMandibular / (estimatedIndex / 100);
};

export const WasmBoltonTable = ({ boltonValues }) => {
  const [overallExcess, setOverallExcess] = useState(0);
  const [anteriorExcess, setAnteriorExcess] = useState(0);
  const [overallExcessType, setOverallExcessType] = useState('');
  const [anteriorExcessType, setAnteriorExcessType] = useState('');
  const [hasMissingUpperLower3, setHasMissingUpperLower3] = useState(false);
  const [hasMissingUpperLower6, setHasMissingUpperLower6] = useState(false);
  const [ULValues, setULValues] = useState([]);
  const [URValues, setURValues] = useState([]);
  const [LLValues, setLLValues] = useState([]);
  const [LRValues, setLRValues] = useState([]);

  useEffect(() => {
    const { Overall, Anteriors, status } = boltonValues;

    if (status === 404 || !Overall || !Anteriors) return;

    const hasMissingUpperLower3 = Overall?.widths ? hasMissingTeeth(Object.keys(boltonValues.Overall.widths), upperLower3) : false;
    const hasMissingUpperLower6 = Overall?.widths ? hasMissingTeeth(Object.keys(boltonValues.Overall.widths), upperLower6) : false;

    setHasMissingUpperLower3(hasMissingUpperLower3);
    setHasMissingUpperLower6(hasMissingUpperLower6);

    let overallExcess;
    if (Overall.index > Overall.estimatedIndex) {
      setOverallExcessType(EBoltonExcessType.Mandibular);
      overallExcess = getLowerCorrection(Overall.upperWidth, Overall.lowerWidth, Overall.estimatedIndex);
    } else {
      setOverallExcessType(EBoltonExcessType.Maxillary);
      overallExcess = getUpperCorrection(Overall.upperWidth, Overall.lowerWidth, Overall.estimatedIndex);
    }

    let anteriorExcess;
    if (Anteriors.index > Anteriors.estimatedIndex) {
      setAnteriorExcessType(EBoltonExcessType.Mandibular);
      anteriorExcess = getLowerCorrection(Anteriors.upperWidth, Anteriors.lowerWidth, Anteriors.estimatedIndex);
    } else {
      setAnteriorExcessType(EBoltonExcessType.Maxillary);
      anteriorExcess = getUpperCorrection(Anteriors.upperWidth, Anteriors.lowerWidth, Anteriors.estimatedIndex);
    }

    setOverallExcess(overallExcess);
    setAnteriorExcess(anteriorExcess);

    const widthEntries = Object.entries(boltonValues.Overall.widths);
    setURValues(filterBoltonValuesByRange(widthEntries, 11, 18));
    setULValues(filterBoltonValuesByRange(widthEntries, 21, 28));
    setLLValues(filterBoltonValuesByRange(widthEntries, 31, 38));
    setLRValues(filterBoltonValuesByRange(widthEntries, 41, 48));
  }, [boltonValues]);

  return (
    <div className="wasm-sidebar-main-content">
      {boltonValues?.status === 404 ? (
        <div className="wasm-sidebar-detail-title">Bolton values could not be loaded</div>
      ) : (
        <>
          <div className="wasm-sidebar-detail-title header header--bold">Bolton Analysis (mm)</div>
          <div className="wasm-bolton-values-container">
            {hasMissingUpperLower6 ? (
              <WasmBoltonValueUnavailable />
            ) : (
              <div className="wasm-bolton-value-container">
                <div className="wasm-bolton-value">{formatExcess(overallExcess)}</div>
                <div className="wasm-bolton-overall">
                  Overall {overallExcessType} Excess <br /> 6-6
                </div>
              </div>
            )}

            {hasMissingUpperLower3 ? (
              <WasmBoltonValueUnavailable />
            ) : (
              <div className="wasm-bolton-value-container">
                <div className="wasm-bolton-value">{formatExcess(anteriorExcess)}</div>
                <div className="wasm-bolton-anterior">
                  Anterior {anteriorExcessType} Excess <br /> 3-3
                </div>
              </div>
            )}
          </div>
          <div className="wasm-bolton-tables-container scrollbar--dark">
            <WasmBoltonTablePart title={'Upper Right'} values={URValues} />
            <WasmBoltonTablePart title={'Upper Left'} values={ULValues} />
            <WasmBoltonTablePart title={'Lower Right'} values={LRValues} />
            <WasmBoltonTablePart title={'Lower Left'} values={LLValues} />
          </div>
        </>
      )}
    </div>
  );
};

WasmBoltonTable.propTypes = {
  boltonValues: PropTypes.object,
};
