import React from 'react';
import AlertContainer from '../container/alert_container';

/**
 * Displays Draft Read Only Alert
 * @component
 */
function AlertDraftReadOnly() {
  return (
    <AlertContainer>
      The draft submission has been set to <span className="emphasis">read-only</span>, please assign this case to yourself to make changes
    </AlertContainer>
  );
}

export default AlertDraftReadOnly;
