import Axios from 'axios';
import React from 'react';
import Modal from './modal';

// Internal Functions
const Gen1AlertModal = (props) => {
  const sendEmail = () => {
    props.doctor_id && Axios.post(`/api/email/?slug=gen-1-attempt-2022-08-08&doctorId=${props.doctor_id}&method=standard&provider=${window.location.origin}`)
  }

  return (
      props.show ? (
          <Modal
            preset="message"
            header_text="Submission Notice"
            message_text={
              <span>
                While we will continue to provide service for all existing Gen 1.0 cases, 
                we are only accepting new Toothprints (new patient cases) for our new Gen 2.0 products and services. <br/><br/>
                Please reach out to your local BGC or contact Customer Concierge at <a className='midnight' href="tel:8772588677">877-258-8677</a>{' '}
                or email <a className='midnight' href="mailto:support@inbrace.com">support@inbrace.com</a> should you have any questions.
              </span>
            }
            onCloseButtonClick={() => {
              sendEmail()
              props.onCloseButtonClick()
            }}
          />
      ): null
  );
}

export default Gen1AlertModal;
