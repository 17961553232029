import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { oneLineBreakLimited } from '../../common/functions';
import { SummaryContainer, SummaryContainerSection, SummaryHeader, useCommonSubmissionSummary } from '../components/commons_submission_summary';

import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord } from '../../redux/actions/record_viewer/record_viewer';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';

import { ToothViewer } from './tx_plan';

/**
 * Component for displaying summary on case submission page
 * @function
 * @param {object} props - Props object
 * @return {JSX} - JSX for submission summary
 */
const CaseSubmissionSummary = (props) => {
  const {
    buildRecordStates,
    photoUpload,
    xrayUpload,
    scanUpload,
    anteriorPosteriorR,
    anteriorPosteriorL,
    anteriorPosteriorCorrections,
    anteriorPosteriorNotes,
    bracketTeeth,
    archToTreat,
    missingTeeth,
    extractTeeth,
    auxiliariesWillBeUsed,
    auxiliariesNotes,
    // iTero integration
    uploadMethod,
    selectedIteroScanRecords,
    ...restProps
  } = props;
  const { getTeethValue, getAnteriorPosteriorValue, getBracketPlacementValue, getAuxiliariesValue } = useCommonSubmissionSummary({
    buildRecordStates,
    photoUpload,
    xrayUpload,
    scanUpload,
    anteriorPosteriorR,
    anteriorPosteriorL,
    anteriorPosteriorCorrections,
    anteriorPosteriorNotes,
    bracketTeeth,
    archToTreat,
    missingTeeth,
    extractTeeth,
    auxiliariesWillBeUsed,
    auxiliariesNotes,
    // iTero integration
    uploadMethod,
    selectedIteroScanRecords,
  });

  const getArchValue = () => {
    switch (archToTreat) {
      case 'upper':
        return 'Upper arch';
      case 'lower':
        return 'Lower arch';
      case 'both':
        return 'Both arches';
      default:
        return '';
    }
  };

  const getOpposingArchValue = () => {
    const opposing_arch = archToTreat === 'upper' ? 'Lower' : 'Upper';
    const texts = {
      current: `${opposing_arch} arch will be untreated`,
      ideal: `${opposing_arch} arch will be treated with an alternate system`,
      digital: `${opposing_arch} arch will be added in Digital Enhancement`,
    };
    return restProps.opposingArch in texts ? texts[restProps.opposingArch] : '';
  };

  const getArchesValue = () => {
    return `${getArchValue(archToTreat)}${archToTreat !== 'both' ? `. ${getOpposingArchValue()}` : ''}`;
  };

  const getNotesValue = () => {
    return restProps.notes ? `"${oneLineBreakLimited(restProps.notes).trim()}"` : 'N/A';
  };

  const fields = [
    {
      title: 'Missing/Previously extracted teeth',
      value: getTeethValue(missingTeeth),
    },
    {
      title: 'Teeth to be extracted',
      value: getTeethValue(extractTeeth),
    },
    {
      title: 'Arch to treat with InBrace',
      value: getArchesValue(),
    },
    {
      title: 'Bracket Placement',
      value: getBracketPlacementValue(),
    },
    {
      title: 'Do not move the following teeth',
      value: getTeethValue(restProps.restrictTeeth),
    },
    {
      title: 'Anterior-Posterior',
      value: getAnteriorPosteriorValue(),
    },
    {
      title: 'Midlines',
      value: restProps.midlines,
    },
    {
      title: 'Auxiliaries',
      value: getAuxiliariesValue(),
    },
    {
      title: 'Treatment goals and additional instructions',
      value: getNotesValue(),
    },
  ];

  return (
    <>
      <SummaryHeader />
      <ThumbnailURLInterface {...restProps.thumbnailComponentProps} recordScroll={true} />
      <div className="submission-summary">
        <SummaryContainer>
          <SummaryContainerSection>
            <ol className="submission-summary__ol">
              {fields.map((field, index) => {
                return (
                  <li className="submission-summary__field" key={index}>
                    <div className="emphasis">{field.title}</div>
                    <div className="submission-summary__field__value">{field.value}</div>
                  </li>
                );
              })}
            </ol>
          </SummaryContainerSection>
          <SummaryContainerSection>
            <ToothViewer teethSets={restProps.getImpliedTeethSets()} />
          </SummaryContainerSection>
        </SummaryContainer>
        <div className="line-division--primary" />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CaseSubmissionSummary);
