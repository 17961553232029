import React, { useEffect, useRef } from 'react';
import { withResizeDetector } from 'react-resize-detector';
import throttle from 'lodash/throttle';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { loadTPS, removeScripts } from './tps_load_script';
import { WASM_CANVAS_RESIZE_INTERVAL_MS } from './wasm-constants';

const resize = () => {
  try {
    if (typeof window.onResizeCanvas === 'function') {
      window.onResizeCanvas();
    }
  } catch (e) {
    console.log('Canvas resize has error:', e);
  }
};
const throttledCanvasResize = throttle(resize, WASM_CANVAS_RESIZE_INTERVAL_MS);

function WasmCanvas(props) {
  const { width, height, isHidden } = props;
  const ref = useRef();
  const classname = cn('emscripten', { hidden: isHidden });

  useEffect(() => {
    let scripts = [];
    if (ref.current) {
      scripts = loadTPS();
    }
    return () => {
      removeScripts(scripts);
    };
  }, []);

  useEffect(() => {
    throttledCanvasResize();
  }, [width, height]);

  return <canvas className={classname} id='canvas' ref={ref} onContextMenu={(e) => e.preventDefault()} tabIndex='-1' />;
}

WasmCanvas.propTypes = {
  isHidden: PropTypes.bool,
};

export default withResizeDetector(WasmCanvas);
