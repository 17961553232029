import React, { Component } from 'react';
import Button from '../components/buttons/button';
import { scrollToTop } from '../../common/scroll';

class DeSubmitted extends Component {
  componentDidMount() {
    scrollToTop();
  }

  render() {
    return (
      <div className='submission-loader-container'>
        <p>Thank you for submitting your digital enhancement request to InBrace.</p>
        <p>You will receive an email to confirm your submission.</p>
        <Button onClick={this.props.onButtonClick}>Go to Case Details</Button>
      </div>
    );
  }
}

export default DeSubmitted;
