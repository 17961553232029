/**
 * File: account_list.js - Display the list of accounts
 * Copyright: (c) Copyright December 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel, Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './account_list.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import PaginationContainer from '../../components/pagination/pagination_container';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// Internal Components
import AddAccount from '../account_details/add_account';
import ApiDetails from '../api_details/api_details';
import EditAccount from '../account_details/edit_account';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import SortHeader from '../../components/layout/sort_header';
import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';
import Profile from '../profile/profile';
import Permission from '../permission/permission';

// Internal Functions
import { getBusinessRoleListLowerCase, getSortValue, getDsoRoleList } from '../../common/helpers';
import { getBusinessAccountSearchValueFromRoute, getBusinessFilterValueFromRoute } from '../../common/route';
import { handleHttpRequestError } from '../../common/error';
import { safeSearchCharPlusEmail, setTokenHeader } from '../../common/functions';
import { searchErrorMessage } from '../../common/search';
// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class AccountList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      user_id: '',
      accounts: [],
      search: '',
      filter: '',
      modals: {
        showSelectAccountRoleModal: false,
      },
      accounts_mode: 'profile',
      accounts_raw: [],
      accounts_view: [],
      accounts_sort_method: 'first_name',
      accounts_sort_order_ascending: {
        first_name: true,
        last_name: true,
        account_email: true,
        enabled: true,
        role: true,
      },
      accounts_row_size: 25,
      accounts_active_page: 1,
      accounts_page_range: 5,
      accounts_length: 1,
      side_collapse: false,
      refresh_list: false,
      bpp_click: false,
      doctor_click: false,
      dso_click: false,
      newTabUrl: '',
      accounts_expanded: false,
    };

    this.onChangeSearchAccounts = this.onChangeSearchAccounts.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onSideBarCollapseClick = this.onSideBarCollapseClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onRefreshList = this.onRefreshList.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.onDoctorClick = this.onDoctorClick.bind(this);
    this.onAddAccountClick = this.onAddAccountClick.bind(this);
    this.getAccountsMode = this.getAccountsMode.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.onDoctorSelectClick = this.onDoctorSelectClick.bind(this);
    this.onBppSelectClick = this.onBppSelectClick.bind(this);
    this.accountTable = React.createRef();
  }

  hideModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = false;
    this.setState({ modals: modals });
  };

  onDoctorSelectClick = () => {
    let doctor_click = !this.state.doctor_click;
    this.setState({
      accounts_mode: 'add',
      search: '',
      filter: '',
      accounts_active_page: 1,
      modals: {
        showSelectAccountRoleModal: false,
      },
      doctor_click: doctor_click,
    });
    const previousUrl = this.props.history.location.pathname + this.props.history.location.search;
    this.props.history.push({
      pathname: '/business/portal/accounts/add',
      search: 'filter=add',
      state: { previousUrl: previousUrl, accountType: 'Doctor', doctor_click: doctor_click },
    });
  };

  onBppSelectClick = () => {
    let bpp_click = !this.state.bpp_click;
    this.setState({
      accounts_mode: 'add',
      search: '',
      filter: '',
      accounts_active_page: 1,
      modals: {
        showSelectAccountRoleModal: false,
      },
      bpp_click: bpp_click,
    });
    const previousUrl = this.props.history.location.pathname + this.props.history.location.search;
    this.props.history.push({
      pathname: '/business/portal/accounts/add',
      search: 'filter=add',
      state: { previousUrl: previousUrl, accountType: 'Business', bpp_click: bpp_click },
    });
  };

  onCreateDsoClick = () => {
    let dso_click = !this.state.dso_click;
    this.setState({
      accounts_mode: 'add',
      search: '',
      filter: '',
      accounts_active_page: 1,
      modals: {
        showSelectAccountRoleModal: false,
      },
      dso_click: dso_click,
    });
    const previousUrl = this.props.history.location.pathname + this.props.history.location.search;
    this.props.history.push({
      pathname: '/business/portal/accounts/add',
      search: 'filter=add',
      state: { previousUrl: previousUrl, accountType: 'DSO', dso_click: dso_click },
    });
  };

  applySearch() {
    this.props.history.replace(this.setRoute(this.state.search, 'search'));

    const that = this;
    const search = getBusinessAccountSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    const active_page = 1;
    const row_size = this.state.accounts_row_size;
    let accounts_view = [];

    accounts_view = filter ? that.getAccountsFromRole(that.state.accounts_raw, filter) : that.getAccountsFromSearch(that.state.accounts_raw, search);

    that.setState({
      accounts_mode: 'search',
      accounts_view: that.getAccountsFromRow(
        that.setOrderByMethod(accounts_view, that.state.accounts_sort_method, that.state.accounts_sort_order_ascending),
        active_page,
        row_size
      ),
      accounts_active_page: active_page,
      accounts_length: accounts_view.length,
      search: search,
      filter: filter,
    });
  }

  getAccountsFromRole(accounts, filter) {
    const role =
      filter === 'bpp'
        ? getBusinessRoleListLowerCase()
        : filter === 'dso'
        ? getDsoRoleList()
        : filter === 'doctor'
        ? [filter, 'dso_doctor', 'dso_doctor_override']
        : '';
    let accounts_list = [];
    let match;

    for (let i = 0; i < accounts.length; i++) {
      match = _.intersection(role, [accounts[i].role.toLowerCase()]);
      if (match && match.length > 0) {
        accounts_list.push(accounts[i]);
      }
    }

    return accounts_list;
  }

  getAccountsFromRow(accounts, page, row_size) {
    const start_index = (Number(page) - 1) * Number(row_size);
    const end_index = start_index + Number(row_size);

    return accounts.slice(start_index, end_index);
  }

  getStartRange() {
    return this.state.accounts_active_page * this.state.accounts_row_size - this.state.accounts_row_size + 1;
  }

  getEndRange() {
    return this.state.accounts_active_page * this.state.accounts_row_size > this.state.accounts_length
      ? this.state.accounts_length
      : this.state.accounts_active_page * this.state.accounts_row_size;
  }

  getAccountsFromSearch(accounts, search_phrase) {
    if (search_phrase === '') {
      return accounts;
    }

    search_phrase = search_phrase.toLowerCase();
    const phrases = search_phrase.split(' ');

    let accounts_list = [];
    let accounts_match = [];
    let search_first_name = '';
    let search_last_name = '';
    let search_email = '';

    _.each(phrases, (phrase) => {
      accounts_match = [];

      for (let i = 0; i < accounts.length; i++) {
        search_first_name = accounts[i].first_name.toLowerCase();
        search_last_name = accounts[i].last_name.toLowerCase();
        search_email = accounts[i].account_email.toLowerCase();

        if (search_first_name.indexOf(phrase) >= 0 || search_last_name.indexOf(phrase) >= 0 || search_email.indexOf(phrase) >= 0) {
          accounts_match.push(accounts[i]);
        }
      }

      if (accounts_list.length === 0) {
        //duplicate array
        accounts_list = accounts_match.slice();
      } else {
        accounts_list = _.intersectionBy(accounts_list, accounts_match, 'account_id');
      }
    });

    return accounts_list;
  }

  getAccountsMode() {
    let allowed_modes = ['edit', 'add', 'profile', 'permission'];

    for (const allowed_mode of allowed_modes) {
      if (this.props.history.location.pathname.indexOf(allowed_mode) >= 0) {
        return allowed_mode;
      }
    }
    return 'search';
  }

  orderAccountsByDefault(accounts) {
    return this.orderAccountsByFirstName(accounts, 'asc');
  }

  orderAccountsByFirstName(accounts, sort) {
    return _.orderBy(accounts, ['first_name'], [sort]);
  }

  orderAccountsByLastName(accounts, sort) {
    return _.orderBy(accounts, ['last_name'], [sort]);
  }

  orderAccountsByAccountEmail(accounts, sort) {
    return _.orderBy(accounts, ['account_email'], [sort]);
  }

  orderAccountsByAccountEnabled(accounts, sort) {
    return _.orderBy(accounts, ['is_active', 'first_name'], [sort, 'asc']);
  }

  orderAccountsByCreditHold(accounts, sort) {
    return _.orderBy(accounts, ['credit_hold', 'first_name'], [sort, 'asc']);
  }

  orderAccountsByAccountRole(accounts, sort) {
    return _.orderBy(accounts, ['permission_roles', 'first_name'], [sort, 'asc']);
  }

  /**
   * Loads list of accounts
   *
   * @function
   */
  loadAccounts = () => {
    setTokenHeader();
    const that = this;
    const search = getBusinessAccountSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    Axios.get('/apiV2/allaccounts')
      .then(function (res) {
        let accounts_raw = res.data.accounts;
        const user_id = res.data.user_id;
        const accounts_view = filter ? that.getAccountsFromRole(accounts_raw, filter) : that.getAccountsFromSearch(accounts_raw, search);
        const accounts_sort_method = that.getSortMethodByFilterSelection(that.state.filter, filter, that.state.accounts_sort_method);

        that.setState({
          loading: false,
          user_id: user_id,
          search: search,
          accounts_raw: accounts_raw,
          accounts_view: that.getAccountsFromRow(
            that.setOrderByMethod(accounts_view, accounts_sort_method, that.state.accounts_sort_order_ascending[accounts_sort_method]),
            that.state.accounts_active_page,
            that.state.accounts_row_size
          ),
          accounts_length: accounts_view.length,
          accounts_mode: that.getAccountsMode(),
          accounts_sort_method: accounts_sort_method,
        });
      })
      .catch(function (err) {
        if (err && err.response && err.response.status === 401) {
          that.setState({
            loading: false,
          });
        } else {
          handleHttpRequestError(err, that);
        }
      });
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.props.location.state.bpp_click = this.state.bpp_click;
      this.props.location.state.doctor_click = this.state.doctor_click;
      this.props.location.state.dso_click = this.state.dso_click;
    }
    this.loadAccounts();
  }

  onChangeSearchAccounts(event) {
    const text = safeSearchCharPlusEmail(event.target.value);

    this.setState({
      search: text,
    });
  }

  onPageChange(active_page) {
    //Allow for page changes without when filters are not applied yet
    const filter = getBusinessFilterValueFromRoute();
    const accounts_sort_method = this.getSortMethodByFilterSelection(this.state.filter, filter, this.state.accounts_sort_method);

    let accounts_view = filter
      ? this.getAccountsFromRole(this.state.accounts_raw, filter)
      : this.getAccountsFromSearch(this.state.accounts_raw, this.state.search);

    this.setState({
      accounts_active_page: active_page,
      accounts_view: this.getAccountsFromRow(
        this.setOrderByMethod(accounts_view, accounts_sort_method, this.state.accounts_sort_order_ascending[accounts_sort_method]),
        active_page,
        this.state.accounts_row_size
      ),
    });
  }

  onSearchButtonClick(event) {
    this.applySearch();
  }

  setRoute = (uri_text, route_type) => {
    const route_base = '/business/portal/accounts';

    let search = route_type === 'search' ? uri_text : getBusinessAccountSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  componentDidUpdate() {
    const that = this;
    const state_filter = this.state.filter;

    const search = getBusinessAccountSearchValueFromRoute();

    const filter = getBusinessFilterValueFromRoute();
    const refresh = this.state.refresh;
    const refresh_history =
      this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.refresh
        ? this.props.history.location.state.refresh
        : false;
    const refresh_list = this.state.refresh_list;

    const active_page = 1;
    const row_size = this.state.accounts_row_size;
    let accounts_view = [];

    if (state_filter !== filter || refresh || refresh_history === 'true') {
      accounts_view = filter ? that.getAccountsFromRole(that.state.accounts_raw, filter) : that.getAccountsFromSearch(that.state.accounts_raw, search);
      const accounts_sort_method = that.getSortMethodByFilterSelection(state_filter, filter, that.state.accounts_sort_method);

      that.setState({
        accounts_view: that.getAccountsFromRow(
          that.setOrderByMethod(accounts_view, accounts_sort_method, that.state.accounts_sort_order_ascending[accounts_sort_method]),
          active_page,
          row_size
        ),
        accounts_active_page: active_page,
        accounts_length: accounts_view.length,
        accounts_sort_method: accounts_sort_method,
        search: search,
        filter: filter,
        refresh: false,
        accounts_expanded: that.state.accounts_mode !== 'profile' && that.state.accounts_mode !== 'permission',
      });

      if (refresh_history === 'true') {
        that.props.history.replace({ state: { refresh: 'false' } });
      }
    } else if (refresh_list) {
      Axios.get('/apiV2/allaccounts')
        .then(function (res) {
          let accounts_raw = res.data.accounts;

          let new_filter = getBusinessFilterValueFromRoute();

          const accounts_view = new_filter ? that.getAccountsFromRole(accounts_raw, new_filter) : that.getAccountsFromSearch(accounts_raw, search);
          const accounts_sort_method = that.getSortMethodByFilterSelection(that.state.filter, new_filter, that.state.accounts_sort_method);

          that.setState({
            accounts_raw: accounts_raw,
            refresh_list: false,
            accounts_view: that.getAccountsFromRow(
              that.setOrderByMethod(accounts_view, accounts_sort_method, that.state.accounts_sort_order_ascending[accounts_sort_method]),
              1,
              row_size
            ),
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }

    if (this.pathIncludes('accounts', 'search', true)) {
      this.setState({
        accounts_mode: 'search',
      });
    }

    const accounts_modes = ['edit', 'profile', 'permission'];
    for (const mode of accounts_modes) {
      if (this.pathIncludes(`/accounts/${mode}${mode === 'edit' ? '/' : ''}`, mode)) {
        this.setState({
          accounts_mode: mode,
          search: '',
          filter: '',
          accounts_active_page: 1,
        });
      }
    }

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.includes('/accounts/edit/') &&
      this.props.history.location.state &&
      this.props.history.location.state.previousUrl &&
      this.props.history.location.state.previousUrl === '/business/portal/accounts/add'
    ) {
      this.props.history.location.state = {};

      Axios.get('/apiV2/allaccounts')
        .then(function (res) {
          let accounts_raw = res.data.accounts;
          that.setState({
            accounts_raw: accounts_raw,
            refresh_list: true,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  }

  /**
   * Determines if current path includes keyword
   * @function
   * @param {string} keyword - Path keyword
   * @param {string} accounts_mode - Accounts mode
   * @param {boolean} ends_with - Identifier for string matching technique
   * @return {boolean} - True or false
   */
  pathIncludes = (keyword, accounts_mode, ends_with = false) => {
    return (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      (ends_with ? this.props.history.location.pathname.endsWith(keyword) : this.props.history.location.pathname.includes(keyword)) &&
      this.state.accounts_mode !== accounts_mode
    );
  };

  keyPress(event) {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  }

  onClickSubMenu = (event) => {
    if (!this.state.side_collapse) {
      const uri_text = encodeURI(event.currentTarget.dataset.filter);
      this.props.history.replace(this.setRoute(uri_text, 'filter'));

      this.setState({
        accounts_mode: 'search',
      });
      this.onPageChange(1);
    }
  };

  onDoctorClick(e) {
    if (e.currentTarget.dataset && e.currentTarget.dataset.doctorid) {
      this.props.history.push(`/business/portal/accounts/edit/${e.currentTarget.dataset.doctorid}`);
      this.setState({
        accounts_mode: 'edit',
        search: '',
        filter: '',
        accounts_active_page: 1,
      });
    }
  }

  /**
   * Handles event when user clicks on left nav menu item
   * @function
   * @param {object} e - Event object
   */
  onAccountsMenuClick = (e) => {
    if (e.currentTarget.dataset && e.currentTarget.dataset.category) {
      const category = e.currentTarget.dataset.category;
      this.props.history.push(`/business/portal/accounts/${category}`);
      this.setState({
        accounts_mode: category,
        search: '',
        filter: '',
        accounts_active_page: 1,
        accounts_expanded: category !== 'profile' && category !== 'permission',
      });
    }
  };

  onAddAccountClick(e) {
    this.setState({
      modals: {
        showSelectAccountRoleModal: true,
      },
    });
  }

  onRefreshList() {
    this.setState({
      refresh_list: true,
    });
  }

  onSideBarCollapseClick() {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  }

  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace('/business/portal/accounts');

      this.setState({
        refresh: true,
        accounts_mode: 'search',
      });
    }
  };

  getSortMethodByFilterSelection = (state_filter, filter, sort_method) => {
    let sort_method_update = sort_method;

    if (state_filter !== filter) {
      sort_method_update = 'first_name';
    }

    return sort_method_update;
  };

  /**
   * Right click handler opens context menu
   * @function
   */
  onAccountRightClick = (event) => {
    this.setState({
      newTabUrl: `/business/portal/accounts/edit/${event.currentTarget.dataset.doctorid}`,
    });
  };

  /**
   * Determines if account related filter is selected
   * @function
   * @return {boolean} - True or false
   */
  isAccountFilterSelected = () => {
    const account_filters = ['doctor', 'dso', 'bpp', 'api', 'add'];
    return account_filters.indexOf(this.state.filter) !== -1;
  };

  /**
   * Opens/closes accounts menu
   * @function
   */
  toggleAccountsMenu = (expanded) => {
    this.setState({ accounts_expanded: expanded });
  };

  /**
   * Determines if logged in user has permission to view any accounts
   *
   * @function
   * @param {object} user_roles_and_permissions - User's roles/permissions information
   * @returns {boolean} - True or false
   */
  hasViewAccountPermission = (user_roles_and_permissions) => {
    return (
      userHasPermission('ACCOUNT_IPP_VIEW', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_BPP_VIEW', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_DSO_VIEW', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_IPP_CREATE', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_BPP_CREATE', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_DSO_CREATE', user_roles_and_permissions.permissions) ||
      userHasPermission('ACCOUNT_API_KEY', user_roles_and_permissions.permissions)
    );
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>Account List | InBrace Business Portal</title>
          </Helmet>
          <div className={this.state.side_collapse ? 'sidenav-submenu submenu-collapse' : 'sidenav-submenu'}>
            {/* eslint-disable-next-line */}
            <a className="submenu-title" onClick={this.refreshRoute}>
              Accounts
            </a>
            {/* eslint-disable-next-line */}
            <a
              className={this.state.accounts_mode === 'profile' && !this.state.side_collapse ? 'submenu-heading submenu-active' : 'submenu-heading'}
              onClick={this.onAccountsMenuClick}
              data-category="profile"
            >
              My Profile
            </a>

            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return (
                  <>
                    {userHasPermission('ROLES_VIEW', user_roles_and_permissions.permissions) ||
                    userHasPermission('ROLES_EDIT', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={
                          this.state.accounts_mode === 'permission' && !this.state.side_collapse ? 'submenu-heading submenu-active' : 'submenu-heading'
                        }
                        onClick={this.onAccountsMenuClick}
                        data-category="permission"
                      >
                        Roles and Permissions
                      </a>
                    ) : null}

                    {this.hasViewAccountPermission(user_roles_and_permissions) ? (
                      <>
                        {/* eslint-disable-next-line */}
                        <a className="submenu-heading" onClick={this.onClickSubMenu} data-filter="">
                          Accounts{' '}
                          <span className="collapse-caret">
                            <i
                              onClick={() => this.toggleAccountsMenu(!this.state.accounts_expanded)}
                              className={`accounts-submenu-icon fa fa-caret-${this.state.accounts_expanded ? 'up' : 'down'}`}
                              aria-hidden="true"
                            />
                          </span>
                        </a>
                      </>
                    ) : null}
                  </>
                );
              }}
            </UserPermissionsContext.Consumer>

            {this.state.accounts_expanded && (
              <>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('ACCOUNT_IPP_VIEW', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={`submenu-heading submenu-heading__accounts${
                          this.state.filter === 'doctor' && !this.state.side_collapse ? ' submenu-active' : ''
                        }`}
                        onClick={this.onClickSubMenu}
                        data-filter="doctor"
                      >
                        Doctor
                      </a>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('ACCOUNT_DSO_VIEW', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={`submenu-heading submenu-heading__accounts${
                          this.state.filter === 'dso' && !this.state.side_collapse ? ' submenu-active' : ''
                        }`}
                        onClick={this.onClickSubMenu}
                        data-filter="dso"
                      >
                        DSO
                      </a>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('ACCOUNT_BPP_VIEW', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={`submenu-heading submenu-heading__accounts${
                          this.state.filter === 'bpp' && !this.state.side_collapse ? ' submenu-active' : ''
                        }`}
                        onClick={this.onClickSubMenu}
                        data-filter="bpp"
                      >
                        BPP
                      </a>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('ACCOUNT_API_KEY', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={`submenu-heading submenu-heading__accounts${
                          this.state.filter === 'api' && !this.state.side_collapse ? ' submenu-active' : ''
                        }`}
                        onClick={this.onClickSubMenu}
                        data-filter="api"
                      >
                        API Key
                      </a>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('ACCOUNT_IPP_CREATE', user_roles_and_permissions.permissions) ||
                      userHasPermission('ACCOUNT_BPP_CREATE', user_roles_and_permissions.permissions) ||
                      userHasPermission('ACCOUNT_DSO_CREATE', user_roles_and_permissions.permissions) ? (
                      /* eslint-disable-next-line */
                      <a
                        className={`submenu-heading submenu-heading__accounts${
                          this.state.filter === 'add' && !this.state.side_collapse ? ' submenu-active' : ''
                        }`}
                        onClick={this.onAddAccountClick}
                        data-filter="add"
                      >
                        Create Account <i className="fa fa-plus-circle fa-badge-color" id="addaccountbell" aria-hidden="true" />
                      </a>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
              </>
            )}
            <span className="sidenav-collapse-btn" onClick={this.onSideBarCollapseClick}>
              {this.state.side_collapse ? <i className="fa fa-chevron-right" aria-hidden="true" /> : <i className="fa fa-chevron-left" aria-hidden="true" />}
            </span>
          </div>

          {this.state.filter === 'api' ? (
            <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
              <div className="main-content main-content-solo">
                <div className="dark-options">
                  <div className="page-heading">API Key List</div>
                  <ApiDetails />
                </div>
              </div>
            </div>
          ) : (
            <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
              {this.state.accounts_mode === 'profile' ? (
                <Profile />
              ) : this.state.accounts_mode === 'permission' ? (
                <Permission loadAccounts={this.loadAccounts} />
              ) : this.state.accounts_mode === 'search' ? (
                <div className="main-content main-content-solo">
                  <div className="dark-options">
                    <div className="page-heading">Account List</div>
                    <div className="dark-search">
                      <input
                        type="text"
                        className="form-control search-bar-dark font-awesome"
                        placeholder="Search"
                        aria-describedby="basic-addon1"
                        onChange={this.onChangeSearchAccounts}
                        onKeyPress={this.keyPress}
                        value={this.state.search}
                      />
                    </div>
                    <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </div>

                  {this.state.accounts_view.length > 0 ? (
                    <div className="table-case-view page-heading-solo">
                      <table className="table table-condensed table-striped table-industry accounts">
                        <thead className="table-inbrace-dark-industry">
                          <tr>
                            {this.state.filter === 'dso' ? (
                              <th
                                className={this.state.filter === 'dso' ? 'dso-name-width' : 'account-name-width'}
                                data-method="first_name"
                                onClick={this.onSortClick}
                              >
                                <SortHeader
                                  title="DSO Name"
                                  category="first_name"
                                  sort_method={this.state.accounts_sort_method}
                                  ascending={this.state.accounts_sort_order_ascending['first_name']}
                                />
                              </th>
                            ) : (
                              <React.Fragment>
                                <th className="name-width" data-method="first_name" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="First Name"
                                    category="first_name"
                                    sort_method={this.state.accounts_sort_method}
                                    ascending={this.state.accounts_sort_order_ascending['first_name']}
                                  />
                                </th>
                                <th className="name-width" data-method="last_name" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Last Name"
                                    category="last_name"
                                    sort_method={this.state.accounts_sort_method}
                                    ascending={this.state.accounts_sort_order_ascending['last_name']}
                                  />
                                </th>
                              </React.Fragment>
                            )}

                            <th className="" data-method="account_email" onClick={this.onSortClick}>
                              <SortHeader
                                title="Account Email"
                                category="account_email"
                                sort_method={this.state.accounts_sort_method}
                                ascending={this.state.accounts_sort_order_ascending['account_email']}
                              />
                            </th>
                            <th
                              className={this.state.filter === 'dso' ? 'dso-name-width' : 'account-name-width'}
                              data-method="enabled"
                              onClick={this.onSortClick}
                            >
                              <SortHeader
                                title="Enabled"
                                category="enabled"
                                sort_method={this.state.accounts_sort_method}
                                ascending={this.state.accounts_sort_order_ascending['enabled']}
                              />
                            </th>
                            {this.state.filter !== 'bpp' ? (
                              <th
                                className={this.state.filter === 'dso' ? 'dso-name-width' : 'account-name-width'}
                                data-method="credit_hold"
                                onClick={this.onSortClick}
                              >
                                <SortHeader
                                  title="Credit Hold"
                                  category="credit_hold"
                                  sort_method={this.state.accounts_sort_method}
                                  ascending={this.state.accounts_sort_order_ascending['credit_hold']}
                                />
                              </th>
                            ) : null}

                            <th className={this.state.filter === 'dso' ? 'dso-name-width' : 'account-name-width'} data-method="role" onClick={this.onSortClick}>
                              <SortHeader
                                title="Role(s)"
                                category="role"
                                sort_method={this.state.accounts_sort_method}
                                ascending={this.state.accounts_sort_order_ascending['role']}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody ref={this.accountTable} className="table-inbrace-dark-industry context-menu-container">
                          <ContextMenu container={this.accountTable} containerType="table">
                            <NewTabLink url={this.state.newTabUrl} />
                          </ContextMenu>
                          {this.state.accounts_view.map((account, index) => {
                            return (
                              <tr
                                className="pointer"
                                key={index}
                                onContextMenu={this.onAccountRightClick}
                                onClick={this.onDoctorClick}
                                data-doctorid={account.account_id}
                              >
                                <td className="td-industry-offset">{account.first_name}</td>
                                {this.state.filter !== 'dso' ? <td>{account.last_name}</td> : null}
                                <td className="fs-exclude">{account.account_email}</td>
                                <td>{account.is_active ? 'Yes' : 'No'}</td>
                                {this.state.filter !== 'bpp' ? <td>{account.credit_hold ? 'Yes' : account.role === 'Business' ? '' : 'No'}</td> : null}
                                <td>{account.permission_roles}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      <div className={this.state.is_bottom ? 'pagination-bottom pagination-dark' : 'pagination-dark'}>
                        <PaginationContainer
                          theme="bpp"
                          isBottom={this.state.is_bottom}
                          activePage={this.state.accounts_active_page}
                          itemsCountPerPage={this.state.accounts_row_size}
                          totalItemsCount={this.state.accounts_length}
                          pageRangeDisplayed={this.state.accounts_page_range}
                          onChange={this.onPageChange}
                          startRange={this.getStartRange()}
                          endRange={this.getEndRange()}
                          type="Users"
                        />
                      </div>
                    </div>
                  ) : (
                    searchErrorMessage(getBusinessAccountSearchValueFromRoute(), 'accounts')
                  )}
                </div>
              ) : null}

              {this.state.accounts_mode === 'edit' ? <EditAccount onRefreshList={this.onRefreshList} /> : null}
              {this.state.accounts_mode === 'add' ? <AddAccount /> : null}
            </div>
          )}

          {this.state.modals.showSelectAccountRoleModal ? (
            <Modal
              preset="account-creation-type"
              theme="bpp"
              onCreateDoctorClick={this.onDoctorSelectClick}
              onCreateBppClick={this.onBppSelectClick}
              onCreateDsoClick={this.onCreateDsoClick}
              onCloseButtonClick={() => {
                this.hideModal('showSelectAccountRoleModal');
              }}
              user_id={this.state.user_id}
            />
          ) : null}
        </div>
      );
    }
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';

    let accounts_sort_order_ascending = this.state.accounts_sort_order_ascending;

    if (sort_method === this.state.accounts_sort_method) {
      //Flip order
      accounts_sort_order_ascending[sort_method] = !accounts_sort_order_ascending[sort_method];
    }

    let accounts_view = filter
      ? this.getAccountsFromRole(this.state.accounts_raw, filter)
      : this.getAccountsFromSearch(this.state.accounts_raw, this.state.search);

    accounts_view = this.getAccountsFromRow(
      this.setOrderByMethod(accounts_view, sort_method, accounts_sort_order_ascending[sort_method]),
      this.state.accounts_active_page,
      this.state.accounts_row_size
    );

    this.setState({
      accounts_view: accounts_view,
      accounts_sort_method: sort_method,
      accounts_sort_order_ascending: accounts_sort_order_ascending,
    });
  };

  setOrderByMethod(accounts_view, sort_method, sort) {
    const sort_value = getSortValue(sort);

    switch (sort_method) {
      case 'first_name':
        accounts_view = this.orderAccountsByFirstName(accounts_view, sort_value);
        break;

      case 'last_name':
        accounts_view = this.orderAccountsByLastName(accounts_view, sort_value);
        break;

      case 'account_email':
        accounts_view = this.orderAccountsByAccountEmail(accounts_view, sort_value);
        break;

      case 'enabled':
        accounts_view = this.orderAccountsByAccountEnabled(accounts_view, sort_value);
        break;

      case 'credit_hold':
        accounts_view = this.orderAccountsByCreditHold(accounts_view, sort_value);
        break;

      case 'role':
        accounts_view = this.orderAccountsByAccountRole(accounts_view, sort_value);
        break;

      default:
    }

    return accounts_view;
  }
}

export default AccountList;
