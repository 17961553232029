import React from 'react';
import PropTypes from 'prop-types';

export const NotificationErrorIcon = ({ size, color }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3718 3.24292C16.7357 4.65149 18.3142 7.19006 18.3142 10.0886C18.3142 14.5172 14.6302 18.1072 10.0858 18.1072C5.5414 18.1072 1.85742 14.5172 1.85742 10.0886C1.85742 7.32149 3.29636 4.88149 5.4856 3.44006"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.88612 13.41C10.1464 13.41 10.3574 13.2104 10.3574 12.9643C10.3574 12.7181 10.1464 12.5186 9.88612 12.5186C9.62581 12.5186 9.41479 12.7181 9.41479 12.9643C9.41479 13.2104 9.62581 13.41 9.88612 13.41Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.93454 9.51854H9.76128L9.63647 1.64282H10.0579L9.93454 9.51854Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

NotificationErrorIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

NotificationErrorIcon.defaultProps = {
  size: 20,
  color: 'white',
};
