import Axios from 'axios';
import _ from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import { firstLetterCapital, npiNumberChar, getRootPath, removeEmoji, formatPhoneNumber } from '../../../common/functions';
import ValidationUtils from '../../../common/validation/validation-utils';

// Action Types
export const FETCH_ACCOUNT_PENDING = 'FETCH_ACCOUNT_PENDING';
export const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';
export const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_PENDING = 'CREATE_ACCOUNT_PENDING';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_INCOMPLETE = 'CREATE_ACCOUNT_INCOMPLETE';
export const ON_INPUT_CHANGE = 'ON_INPUT_CHANGE';
export const ON_SETEDITMODE_CLICK = 'ON_SETEDITMODE_CLICK';
export const ON_SETADDMODE_CLICK = 'ON_SETADDMODE_CLICK';
export const ON_EDIT_CHANGE = 'ON_EDIT_CHANGE';
export const ON_NEW_CHANGE = 'ON_NEW_CHANGE';
export const ON_ACCOUNT_CREATE_CHANGE = 'ON_ACCOUNT_CREATE_CHANGE';
export const ON_MOUNT = 'ON_MOUNT';

// Action Creators
// -----------------------------------------------------------------------------
export function onMount(account) {
  return {
    type: ON_MOUNT,
  };
}

// Get account info
export function fetchAccount(doctor_id) {
  return (dispatch) => {
    dispatch(fetchAccountPending());

    Axios.get(`/apiV2/account/${doctor_id}`)
      .then(function (result) {
        if (result.data.role !== 'DSO') dispatch(fetchAccountError());
        else dispatch(fetchAccountSuccess(result.data));
      })
      .catch(function (err) {
        dispatch(fetchAccountError());
      });
  };
}

export function fetchAccountPending() {
  return {
    type: FETCH_ACCOUNT_PENDING,
  };
}

export function fetchAccountSuccess(account) {
  return {
    type: FETCH_ACCOUNT_SUCCESS,
    account: account,
  };
}

export function fetchAccountError() {
  return {
    type: FETCH_ACCOUNT_ERROR,
  };
}

// -----------------------------------------------------------------------------
// Add Account info

function getFieldValueById(list, id) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i].value;
    }
  }

  return;
}

function allRequiredFieldFilledOut(list) {
  const fields = list.filter((item) => {
    return item.required && item.value === '';
  });

  return fields.length === 0;
}

function formatEmail(list) {
  return list.map((item) => {
    return {
      email: item.value,
      type: item.type,
      notification: item.notification,
    };
  });
}

function formatPhone(list) {
  return list.map((item) => {
    return {
      number: item.value,
      type: item.type,
      notification: item.notification,
    };
  });
}

/**
 * Creates DSO account by calling the api once all the validations are met
 * @function
 */
export function createAccount() {
  return (dispatch, getState) => {
    const rootPath = getRootPath(document.URL);
    const { account_create, fields, account_create_pending } = getState().accountAddReducer;
    const { notification_emails, phone_numbers } = account_create;
    const account_email = getFieldValueById(fields, 'email');
    let account_link_id = rootPath.match(/portal\/(\w+)/);
    account_link_id = account_link_id.length === 2 ? account_link_id[1] : 0;

    // if all fields are filled out
    if (!allRequiredFieldFilledOut(fields) || !ValidationUtils.isValidEmail(account_email)) {
      dispatch(createAccountIncomplete());
      return;
    }

    const user_data = {
      first_name: getFieldValueById(fields, 'first_name'),
      last_name: getFieldValueById(fields, 'last_name'),
      email: account_email,
      usenotify: true,
      currentuser: true,
      provider: true,
      provider_id: getFieldValueById(fields, 'provider_id'),
      notification_ind: true,
      npi_number: getFieldValueById(fields, 'npi_number'),
      account_link_id: account_link_id,
    };

    const notification_account = {
      email: user_data.email,
      type: 'Office',
      notification: true,
    };

    let email = formatEmail(notification_emails);
    email.unshift(notification_account);

    const phone = formatPhone(phone_numbers);
    const role = getFieldValueById(fields, 'role');
    const account_types = {
      Doctor: 'DSO_Doctor',
      'Doctor Override': 'DSO_Doctor_Override',
    };
    const account_type = account_types[role];

    const user_role = role === 'Doctor' ? 'DSO Doctor' : 'DSO Doctor Override';
    const assigned_user_roles = [user_role];

    var data = {
      user_data,
      email,
      phone,
      account_type,
      assigned_user_roles,
    };

    if (account_create_pending !== true) {
      dispatch(createAccountPending());

      Axios.post(`/apiV2/createaccount/dso`, data)
        .then(function (result) {
          Axios.post(`/api/email/?slug=welcome-to-inbrace-nation&doctorId=${result.data.data.doctorId}&method=standard&provider=${window.location.origin}`);
          dispatch(createAccountSuccess(result.data.data.doctorId));
        })
        .catch(function (err) {
          dispatch(createAccountError(err.response.status));
        });
    }
  };
}

export function createAccountPending() {
  return {
    type: CREATE_ACCOUNT_PENDING,
  };
}

export function createAccountSuccess(id) {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    id: id,
  };
}

export function createAccountError(error_code) {
  return {
    type: CREATE_ACCOUNT_ERROR,
    status_error: error_code,
  };
}

export function createAccountIncomplete() {
  return {
    type: CREATE_ACCOUNT_INCOMPLETE,
  };
}

// -----------------------------------------------------------------------------

export function onInputChange(fields) {
  return {
    type: ON_INPUT_CHANGE,
    fields: fields,
    warning: false,
  };
}

export function onAccountCreateChange(account_create) {
  return {
    type: ON_ACCOUNT_CREATE_CHANGE,
    account_create: account_create,
    warning: false,
  };
}

export function onEditValueChange(edit_profile, edit_error_msg = '') {
  return {
    type: ON_EDIT_CHANGE,
    edit_profile: edit_profile,
    edit_error_msg: edit_error_msg,
    warning: false,
  };
}

export function onNewValueChange(new_profile, new_error_msg = '') {
  return {
    type: ON_NEW_CHANGE,
    new_profile: new_profile,
    new_error_msg: new_error_msg,
    warning: false,
  };
}

function onEditMode(edit_profile) {
  return {
    type: ON_SETEDITMODE_CLICK,
    edit_profile: edit_profile,
    edit_error_msg: '',
  };
}

export function setEditMode(mode, contact_details) {
  return (dispatch, getState) => {
    const { edit_profile, new_profile } = getState().accountAddReducer;
    if (edit_profile.mode === '' && new_profile.mode === '') {
      let edit_profile_updates = { ...contact_details };
      edit_profile_updates.mode = mode;
      edit_profile_updates.error = false;
      dispatch(onEditMode(edit_profile_updates));
    }
  };
}

export function setEditValue(key, value) {
  return (dispatch, getState) => {
    const { edit_profile } = getState().accountAddReducer;
    let edit_profile_update = _.cloneDeep(edit_profile);
    switch (key) {
      case 'type':
        edit_profile_update.type = removeEmoji(value);
        break;

      case 'notification':
        edit_profile_update.notification = removeEmoji(value);
        break;

      case 'value':
        edit_profile_update.value = removeEmoji(value.target.value);
        break;

      default:
    }

    dispatch(onEditValueChange(edit_profile_update));
  };
}

export function clearEditMode() {
  return (dispatch, getState) => {
    dispatch(
      onEditValueChange({
        id: '',
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        is_active: true,
      })
    );
  };
}

export function isEnterKeyToSaveEditChanges(event) {
  return (dispatch, getState) => {
    if (event.key === 'Enter') {
      dispatch(saveEditChanges());
    }
  };
}

// Helpers
function formatValueByMode(mode, value) {
  let new_value = value;

  switch (mode) {
    case 'add_number':
    case 'edit_number':
      const usa_number = parsePhoneNumber(value, 'US');
      new_value = usa_number.format('NATIONAL');
      break;
    default:
  }

  return new_value;
}

function isValidPhoneNo(value) {
  let is_valid = false;

  try {
    const usa_number = parsePhoneNumber(value, 'US');
    const valid_number = ValidationUtils.isValidPhoneNo(value);

    if (valid_number && usa_number.isValid()) {
      is_valid = true;
    }
  } catch (error) {
    // Do Nothing
  }

  return is_valid;
}

function isNewPhoneNo(value, phone_numbers, id = '') {
  const phones = phone_numbers;
  let is_new = true;

  for (let i = 0; i < phones.length; i++) {
    if (phones[i].value === formatPhoneNumber(value)) {
      if (phones[i].id === id) {
        //Do nothing
      } else {
        is_new = false;
        break;
      }
    }
  }

  return is_new;
}

function isNewEmail(value, notification_emails, id = '') {
  const emails = notification_emails;
  let is_new = true;

  for (let i = 0; i < emails.length; i++) {
    if (emails[i].value === value) {
      if (emails[i].id === id) {
        //Do nothing
      } else {
        is_new = false;
        break;
      }
    }
  }

  return is_new;
}

function isAccountEmail(email, fields) {
  let result = false;
  fields.forEach((item) => {
    if (item.id === 'email' && item.value === email) result = true;
  });
  return result;
}

export function saveEditChanges() {
  return (dispatch, getState) => {
    const { edit_profile, account_create, fields } = getState().accountAddReducer;
    const account_create_update = _.cloneDeep(account_create);
    let { phone_numbers, notification_emails } = account_create_update;
    let edit_profile_update = _.cloneDeep(edit_profile);
    let { id, mode, type, value, error } = edit_profile_update;
    const isBaseEmail = isAccountEmail(value, fields);

    if (
      value !== undefined &&
      ((isValidPhoneNo(value) && type !== '' && isNewPhoneNo(value, phone_numbers, id) && mode === 'edit_number') ||
        (ValidationUtils.isValidEmail(value) && type !== '' && isNewEmail(value, notification_emails, id) && !isBaseEmail && mode === 'edit_email'))
    ) {
      value = formatValueByMode(mode, value);
      edit_profile_update.value = value;

      let index =
        mode === 'edit_number'
          ? getArrayIndex(account_create_update.phone_numbers, edit_profile_update)
          : getArrayIndex(account_create_update.notification_emails, edit_profile_update);

      if (mode === 'edit_number' && index !== '') {
        account_create_update.phone_numbers[index] = edit_profile_update;
      } else if (mode === 'edit_email' && index !== '') {
        account_create_update.notification_emails[index] = edit_profile_update;
      }

      dispatch(onAccountCreateChange(account_create_update));
      dispatch(clearEditMode());
    } else {
      error =
        mode === 'edit_number' && !isNewPhoneNo(value, phone_numbers, id)
          ? 'Telephone number already exists'
          : mode === 'edit_email' && (!isNewEmail(value, notification_emails, id) || isBaseEmail)
          ? 'Email address already exists'
          : '';

      edit_profile_update.error = true;
      dispatch(onEditValueChange(edit_profile_update, error));
    }
  };
}

function removeFromArray(list, item) {
  list = list.filter((list_item) => {
    return list_item.id !== item.id;
  });

  return list;
}

function getArrayIndex(list, item) {
  let index = '';

  for (let i = 0; i < list.length; i++) {
    if (list[i].id === item.id) {
      index = i;
      break;
    }
  }

  return index;
}

export function deleteEntry(event) {
  return (dispatch, getState) => {
    const { edit_profile, account_create } = getState().accountAddReducer;
    const { mode } = edit_profile;
    let account_create_updates = _.cloneDeep(account_create);
    let { phone_numbers, notification_emails } = account_create_updates;

    if (mode === 'edit_number') {
      account_create_updates.phone_numbers = removeFromArray(phone_numbers, edit_profile);
    } else if (mode === 'edit_email') {
      account_create_updates.notification_emails = removeFromArray(notification_emails, edit_profile);
    }

    dispatch(onAccountCreateChange(account_create_updates));
    dispatch(clearEditMode());
  };
}

export function setNewValue(key, value) {
  return (dispatch, getState) => {
    const { new_profile } = getState().accountAddReducer;
    let new_profile_update = _.cloneDeep(new_profile);
    new_profile_update.error = false;

    switch (key) {
      case 'type':
        new_profile_update.type = value;
        break;

      case 'notification':
        new_profile_update.notification = value;
        break;

      case 'value':
        new_profile_update.value = removeEmoji(value.target.value);
        break;

      default:
    }

    dispatch(onNewValueChange(new_profile_update));
  };
}

export function clearNewMode() {
  return (dispatch, getState) => {
    dispatch(
      onNewValueChange({
        id: '',
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        is_active: true,
      })
    );
  };
}

export function isEnterKeyToSaveNewChanges(event) {
  return (dispatch, getState) => {
    if (event.key === 'Enter') {
      dispatch(saveNewChanges());
    }
  };
}

function assignAnId(list) {
  list[list.length - 1].id = list.length - 1;

  return list;
}

export function saveNewChanges() {
  return (dispatch, getState) => {
    const { new_profile, account_create, fields } = getState().accountAddReducer;
    const account_create_update = _.cloneDeep(account_create);
    let { phone_numbers, notification_emails } = account_create_update;
    let new_profile_update = _.cloneDeep(new_profile);
    let { mode, type, value } = new_profile_update;
    let isBaseEmail = isAccountEmail(value, fields);
    const newEmail = isNewEmail(value, notification_emails);
    const newPhone = isNewPhoneNo(value, phone_numbers);

    if (
      (isValidPhoneNo(value, phone_numbers) && type !== '' && mode === 'add_number' && newPhone) ||
      (ValidationUtils.isValidEmail(value) && newEmail && !isBaseEmail && mode === 'add_email')
    ) {
      value = formatValueByMode(mode, value);
      new_profile_update.value = value;

      if (mode === 'add_number') {
        account_create_update.phone_numbers.push(new_profile_update);
        account_create_update.phone_numbers = assignAnId(account_create_update.phone_numbers);
      } else if (mode === 'add_email') {
        account_create_update.notification_emails.push(new_profile_update);
        account_create_update.notification_emails = assignAnId(account_create_update.notification_emails);
      }

      dispatch(onAccountCreateChange(account_create_update));
      dispatch(clearNewMode());
    } else {
      // error
      let error =
        new_profile_update.mode === 'add_number' && !newPhone
          ? 'Telephone number already exists'
          : new_profile_update.mode === 'add_email' && (!newEmail || isBaseEmail)
          ? 'Email address already exists'
          : '';

      new_profile_update.error = true;
      dispatch(onNewValueChange(new_profile_update, error));
    }
  };
}

export function setAddMode(mode) {
  return {
    type: ON_SETADDMODE_CLICK,
    new_profile: {
      mode: mode,
      value: '',
      type: 'Office',
      notification: true,
      error: false,
      is_active: true,
    },
    new_error_msg: '',
  };
}

export function onFieldBlur(event) {
  return (dispatch, getState) => {
    const { fields } = getState().accountAddReducer;
    const new_fields = _.cloneDeep(fields);
    const field_id = event.target.id;
    let value = removeEmoji(event.target.value);

    switch (field_id) {
      case 'first_name':
      case 'last_name':
        value = value
          .replace(/[^a-z\d\s-]+/gi, '')
          .replace(/\s\s+/g, ' ')
          .trimStart()
          .trimEnd()
          .replace(/^[-\d\s]*/, '');
        break;
      case 'email':
        value = value.trimEnd();
        break;
      default:
    }

    new_fields.map((field) => {
      if (field.id === field_id) {
        field.value = value;
      }

      return true;
    });

    dispatch(onInputChange(new_fields));
  };
}

export function onFieldChange(event) {
  return (dispatch, getState) => {
    const { fields } = getState().accountAddReducer;
    const new_fields = _.cloneDeep(fields);
    const field_id = event.target.id;
    let value = removeEmoji(event.target.value);

    switch (field_id) {
      case 'first_name':
      case 'last_name':
        value = firstLetterCapital(value);
        break;
      case 'npi_number':
        value = npiNumberChar(value);
        break;
      case 'email':
        value = value.toLowerCase().trim();

        break;
      default:
    }

    new_fields.map((field) => {
      if (field.id === field_id) {
        field.value = value;
      }

      return true;
    });

    dispatch(onInputChange(new_fields));
  };
}
