import './referral_code_data.scss';
import React, { Component } from 'react';
import { convertDate, calculateAge, translateSex } from '../../common/functions';

/**
 * This component is used to allow a user to show validated referral code data
 * @component
 * @alias DoctorReferralCodeData
 * @category IPP
 */
class ReferralCodeData extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.referral_code_data.firstName || this.props.referral_code_data.lastName ? (
          <div className="row referral-code-data first-child">
            <div className="col-md-12 referral-code-content">
              <strong>Name: </strong>
              {this.props.referral_code_data.firstName ? this.props.referral_code_data.firstName : null}
              {this.props.referral_code_data.lastName ? ' ' + this.props.referral_code_data.lastName : null}
            </div>
          </div>
        ) : null}
        {this.props.referral_code_data.dateOfBirth ? (
          <div className="row  referral-code-data">
            <div className="col-md-12 referral-code-content">
              <strong>Date of Birth: </strong>
              {convertDate(this.props.referral_code_data.dateOfBirth)} ({calculateAge(this.props.referral_code_data.dateOfBirth)})
            </div>
          </div>
        ) : null}
        {this.props.referral_code_data.personGender ? (
          <div className="row  referral-code-data">
            <div className="col-md-12 referral-code-content">
              <strong>Gender: </strong>
              {translateSex(this.props.referral_code_data.personGender)}
            </div>
            <div className="col-md-12 referral-code-content"></div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReferralCodeData;
