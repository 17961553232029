// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

// Internal Components
import AlertContainer from '../components/container/alert_container';
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import CustomerAgreement from '../../doctor/customer_agreement/customer_agreement';
import Error from '../../doctor/404/not_found';
import ErrorMessage from '../components/container/error_message';
import Photos from '../../doctor/case_submission/photos';
import Scans from '../../doctor/case_submission/scans';
import SubmitterLoader from '../../doctor/case_submission/submitting_loader';

// Internal Functions
import { getDoctorsIdFromRoute } from '../../common/route';
import { handleHttpRequestError } from '../../common/error';

// Modals
import EmptyFileErrorModal from '../../components/modal/empty_file';

class SmileSimulationReupload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor_id: '',
      smile_id: '',
      smile_first_name: '',
      smile_last_name: '',
      smile_comment: '',
      smile_photos: [],
      smile_photos_uploading: [],
      smile_photos_count: 0,
      smile_scans: [],
      smile_scans_uploading: [],
      smile_scans_count: 0,
      smile_error: [],
      status: 'loading',
      terms: false,
    };
  }
  componentDidMount() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    const smile_id = this.getSmileIdFromRoute();

    Axios.get(`/apiV2/agreement`).catch(function () {
      that.setState({
        terms: true,
      });
    });

    Axios.get(`/apiv3/smilesimulation/${doctor_id}/reupload/${smile_id}`)
      .then(function (res) {
        const { smile_first_name, smile_last_name } = { ...res.data.smile[0] };
        const smile_files = res.data.smile_files;
        const smile_comment = res.data.smile_comment;
        const smile_photos = smile_files.filter((file) => file.file_type === 'photos');
        const smile_scans = smile_files.filter((file) => ['scans', 'multi_scans'].includes(file.file_type));

        that.setState({
          doctor_id: doctor_id,
          smile_id: smile_id,
          smile_first_name: smile_first_name,
          smile_last_name: smile_last_name,
          smile_comment: smile_comment,
          smile_photos: smile_photos,
          smile_photos_count: smile_photos.length,
          smile_scans: smile_scans,
          smile_scans_count: smile_scans.length,
          status: 'ready',
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }
  /**
   * Extract Smile Id from code
   * @function
   */
  getSmileIdFromRoute = () => {
    let pathname = this.props.history.location.pathname.match(/\/smile\/reupload\/(.+)*/);
    return pathname && pathname.length > 1 ? pathname[1] : 'error';
  };
  /**
   * On Focus Event for all input on this page
   * @function
   */
  onFocus = (e) => {
    let ids = ['#photoLabel', '#scanLabel'];

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }
    });

    this.setState({
      smile_error: [],
    });
  };
  /**
   * Show Empty File Modal
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Hide Empty File Modal
   * @param {Hide } event
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };
  /**
   * Determines if there are any errors and saves it to a list
   * @function
   */
  hasError = (e) => {
    let error_list = [];

    for (const [key, value] of Object.entries(this.state)) {
      switch (key) {
        case 'smile_photos':
          if (value.length === 0) {
            error_list.push(key);
          }
          break;

        case 'smile_scans':
          if (value.length !== 3) {
            error_list.push(key);
          }
          break;

        case 'smile_photos_uploading':
        case 'smile_scans_uploading':
          if (value.length !== 0) {
            error_list.push(key);
          }
          break;

        default:
          break;
      }
    }

    return error_list;
  };
  /**
   * Display the errors
   * @function
   */
  displayError = (error_list) => {
    let ids = [];

    error_list.forEach((type) => {
      switch (type) {
        case 'smile_photos':
        case 'smile_photos_uploading':
          ids.push('#photoLabel');
          break;

        case 'smile_scans':
        case 'smile_scans_uploading':
          ids.push('#scanLabel');
          break;

        case 'duplicate_file_name':
          ids.push('#photoLabel');
          break;

        default:
          break;
      }

      return null;
    });

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element && (id.indexOf('Label') > 0 || id.indexOf('patientSex') > 0)) {
        element.classList.add('warning-text');
      } else if (element) {
        element.classList.add('warning-border');
      }
    });
  };

  /**
   * Display duplicate file name warning
   * @function
   * @param {object} data - file object
   */
  displaySmileError = () => {
    let smile_error = [''];
    this.setState({ smile_error: smile_error });
    this.displayError(smile_error);
  };

  /**
   * On Reupload of the submit files
   * @function
   */
  onReupload = (e) => {
    const that = this;
    const { doctor_id, smile_id } = { ...this.state };
    let smile_error = this.hasError();

    if (smile_error.length > 0) {
      this.setState({ smile_error: smile_error });
      this.displayError(smile_error);
      return;
    }

    this.setState({ status: 'submit' }, () => {
      Axios.put(`/apiv3/smilesimulation/${doctor_id}/reupload/${smile_id}`, that.state)
        .then(function (res) {
          that.setState({ status: 'complete' });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        })
        .catch(function (err) {
          console.log(err);
          that.setState({ status: 'error' });
        });
    });
  };
  /**
   * Returns the title of smile simulation display
   * @function
   * @return {String} The title of smile simulation submission
   */
  displayTitle() {
    if (this.state.smile_first_name && this.state.smile_last_name) {
      return `Upload New Toothprints - ${this.state.smile_first_name} ${this.state.smile_last_name}`;
    } else {
      return 'Upload New Toothprints';
    }
  }
  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = (data) => {
    const that = this;
    let { smile_photos, smile_scans, doctor_id, smile_id } = { ...this.state };
    this.onFocus();

    if (data && data[0].folder && data[0].folder === 'photos') {
      smile_photos = smile_photos.concat(data);
      this.setState({
        smile_photos: smile_photos,
        smile_photos_uploading: [],
      });
    } else if (data && data[0].folder && (data[0].folder === 'upperscans' || data[0].folder === 'lowerscans' || data[0].folder === 'scans')) {
      smile_scans = smile_scans.concat(data);
      Axios.post(`/apiv3/smilesimulation/${doctor_id}/reupload/${smile_id}/scantemp`, smile_scans)
        .then(function (res) {
          that.setState({
            smile_scans: res.data.smile_scans,
            smile_scans_uploading: [],
          });
        })
        .catch(function (err) {
          that.setState({
            smile_scans: smile_scans,
            smile_scans_uploading: [],
          });
        });
    }
  };
  /**
   * On removing file
   * @function
   * @param {object} event - returns click event of hitting the remove button
   */
  onRemove = (event) => {
    event.preventDefault();
    this.onFocus();

    const tag = event.currentTarget.dataset.href.substring(1);
    let { smile_photos, smile_scans } = { ...this.state };

    smile_photos = smile_photos.filter((file) => {
      return file.upload_data !== tag;
    });

    smile_scans = smile_scans.filter((file) => {
      return file.upload_data !== tag;
    });

    if (smile_scans.length === 2 && smile_scans.filter((scan) => scan.file_type === 'multi_scans').length > 0) {
      smile_scans = smile_scans.filter((file) => {
        return file.file_type !== 'multi_scans';
      });
    }

    this.setState({
      smile_photos: smile_photos,
      smile_scans: smile_scans,
    });
  };
  onRedirectSmileDetail = () => {
    const smile_id = this.state.smile_id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(`reupload/${smile_id}`, `detail/${smile_id}`);
    this.props.history.push(new_path);
  };
  /**
   * On uploading update state
   * @function
   * @param {object} event - returns click event of hitting the remove button
   */
  smile_photos_uploading = (filenames) => {
    this.onFocus();

    this.setState({
      smile_photos_uploading: filenames,
    });
  };
  smile_scans_uploading = (filenames) => {
    this.onFocus();

    this.setState({
      smile_scans_uploading: filenames,
    });
  };
  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    const file_type = folder && folder === 'photos' ? 'smile_photos' : 'smile_scans';
    let new_file_upload = this.state[file_type];
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);
    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ [file_type]: new_file_upload });
    }
  };
  /**
   * Returns the photos section
   * @function
   * @return {JSX} Returns the Photos and viewer
   */
  displayPhotos() {
    return (
      <Photos
        id={this.state.smile_id}
        photoUpload={this.state.smile_photos.concat(this.state.smile_scans)}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        location={'smilesimulation'}
        upload_state={this.smile_photos_uploading}
        upload_content={this.state.smile_photos_uploading}
        show_warning={this.displaySmileError}
        updateRecordState={this.updateRecordState}
      />
    );
  }
  /**
   * Returns the scan section
   * @function
   * @return {JSX} Returns the Scan and viewer
   */
  displayScans() {
    return (
      <Scans
        id={this.state.smile_id}
        scanUpload={this.state.smile_photos.concat(this.state.smile_scans)}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        onEmptyFileError={this.onEmptyFileError}
        location={'smilesimulation'}
        upload_state={this.smile_scans_uploading}
        upload_content={this.state.smile_scans_uploading}
        show_warning={this.displaySmileError}
        updateRecordState={this.updateRecordState}
      />
    );
  }
  /**
   * Returns the break border
   * @function
   * @return {JSX} Returns the break border
   */
  displaySectionBreak() {
    return <div className="row border--top"></div>;
  }
  render() {
    if (this.state.terms) return <CustomerAgreement />;
    if (this.state.status === 'loading') return <CircleLoader fullscreen={true} />;
    if (this.state.status === 'error')
      return (
        <div className="fullview">
          <Error />
        </div>
      );

    const incomplete_scans = _.intersection(['smile_scans'], this.state.smile_error).length > 0;
    const incomplete_photos = _.intersection(['smile_photos'], this.state.smile_error).length > 0;
    const upload_in_progress = _.intersection(['smile_scans_uploading', 'smile_photos_uploading'], this.state.smile_error).length > 0;
    const has_same_photo_count = this.state.smile_photos.length === this.state.smile_photos_count;
    const has_same_scan_count = this.state.smile_scans.length === this.state.smile_scans_count;
    const duplicate_file_name = _.intersection(['duplicate_file_name'], this.state.smile_error).length > 0;
    const new_photos_qty = this.state.smile_photos.filter((file) => file.location === 'smilesimulation').length;
    const new_scans_qty = this.state.smile_scans.filter((file) => file.created_date === null).length;
    const has_no_changes = new_photos_qty + new_scans_qty === 0 && has_same_photo_count && has_same_scan_count;

    return (
      <div>
        <Helmet>
          <title>Smile Simulation Submission | InBrace Smile Design Studio™</title>
        </Helmet>

        <h1 className="content__header fs-exclude">{this.displayTitle()}</h1>

        {this.state.status === 'submit' ? (
          <CardContainer className="case-card-container mt-sm pad-lg card--smile">
            <SubmitterLoader />
          </CardContainer>
        ) : this.state.status === 'complete' ? (
          <CardContainer className="case-card-container mt-sm pad-lg card--smile">
            <div className="submission-loader-container">
              <p>Your files have been successfully submitted.</p>
              <Button onClick={this.onRedirectSmileDetail}>Go to Smile Simulation Details</Button>
            </div>
          </CardContainer>
        ) : (
          <>
            <CardContainer className="case-card-container mt-sm pad-lg card--smile">
              <div className="row">
                <div className="col-md-12">
                  <AlertContainer>{this.state.smile_comment}</AlertContainer>
                </div>
              </div>
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayPhotos()}</div>
              </div>
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayScans()}</div>
              </div>
            </CardContainer>

            {this.state.showEmptyFileModal && <EmptyFileErrorModal theme="ipp" onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} />}

            <ErrorMessage
              className={this.state.smile_error.length > 0 ? 'error-message-container' : 'error-message-container hide'}
              title="Incomplete Fields"
              onClose={this.onFocus}
            >
              <div className="warning-display">
                <ul className="wizard-error-text">
                  {incomplete_photos ? <li>Photos</li> : null}
                  {incomplete_scans ? <li>Toothprints</li> : null}
                  {upload_in_progress ? <li>Upload In Progress</li> : null}
                  {duplicate_file_name ? <li>Duplicate File Name Found</li> : null}
                </ul>
              </div>
              <div id="warning-submit" />
            </ErrorMessage>

            <div className="case-form-controls">
              <Button data-position={'next'} onClick={this.onReupload} disabled={has_no_changes}>
                Submit
              </Button>
              <Button theme="revise" onClick={this.onRedirectSmileDetail}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(SmileSimulationReupload);
