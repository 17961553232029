import './smile_simulation_details.scss';

import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getDoctorIdFromPath, getRootPath, convertDate, setTokenHeader, configLightBox, patientChartTextConstraint, removeEmoji } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { Helmet } from 'react-helmet';
import { withRouter, Link, Redirect } from 'react-router-dom';
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import CollapsibleTriangleContainer from '../components/container/collapsible_triangle_container';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';
import SmileHistory from './smile_history';
import CollapsibleContainer from '../components/container/collapsible_container';
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import PatientInfo from './patient_info';
import SmileSimulationProgress from './smile_progress';
import { UserPermissionsContext } from '../../context/user_permission';
import { getBusinessRoleList } from '../../common/helpers';
import LockedIcon from '../../components/custom_lightbox/components/LockedIcon';
import { userHasPermission } from '../../common/permission';

import { getSmileIdFromURL, getShippingAddress } from '../../common/helpers';

// Redux
import { openEditPatientModal, closeEditPatientModal } from '../../redux/actions/ipp/case_details';
import { getEditPatientModal } from '../../redux/reducers/ipp/case_details';

/**
 * Contains the Smile Simulation Details
 * @component
 * @alias DoctorSmileSimulationDetails
 * @category IPP
 */
class SmileSimulationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      doctor_id: '',
      doctor_info: {},
      dso_addresses: [],
      smile_addresses: [],
      smile_simulation_data: {},
      smile_doctors: [],
      smile_simulation_files: [],
      smile_id: '',
      refresh: false,
      edit_in_progress: false,
      status_history: [],
      user_doctor_roles: '',
      edit_patient_error: false,
      assigned_doctor_name_org: '',
      assigned_doctor_id_org: null,
      assigned_address_org: {},
      smile_permission: false,
      submission_in_progress: false,
      case_submission_modal: false,
      prospect_lost_modal: false,
      incomplete_case_id: '',
      case_id: '',
      prospect_lost_comment: '',
      prospect_lost_reason: '',
      case_submission_radio_options: [
        { id: 0, name: 'use uploaded records', description: 'Use uploaded records', state: true },
        { id: 1, name: 'upload new records', description: 'Upload new records', state: false },
      ],
      case_submission_in_process: false,
    };
    this.onSmileRefChange = this.onSmileRefChange.bind(this);
    this.onDsoDoctorDropdownChange = this.onDsoDoctorDropdownChange.bind(this);
    this.onDsoAddressDropdownChange = this.onDsoAddressDropdownChange.bind(this);
  }

  componentDidMount() {
    this.loadInformation();
  }

  /**
   * fetching data
   * @function
   * @param
   */

  loadInformation = () => {
    setTokenHeader();
    const that = this;
    const smile_id = getSmileIdFromURL(that);
    const doctor_id = getDoctorIdFromPath(that.props.history.location.pathname);

    Axios.get(`/apiV2/permission`)
      .then((res) => {
        if (res && res.data && res.data.permission_list_raw) {
          that.setState({
            permissions: res.data.permission_list_raw,
            permissions_categories: res.data.permission_categories_raw,
            user_doctor_roles: res.data.doctor_role,
            user_id: res.data.user_id,
          });
        }
      })
      .catch((err) => {
        handleHttpRequestError(err, that);
      });

    Axios.get(`/apiv3/smilesimulation/${smile_id}?doctor_id=${doctor_id}`)
      .then(function (res) {
        const smile_addresses = res.data.smileaddresses;
        const smile_simulation_data = res && res.data && res.data.smiles && res.data.smiles.length > 0 ? res.data.smiles[0] : [];
        const smile_simulation_files = res && res.data && res.data.smilefiles && res.data.smilefiles.length > 0 ? res.data.smilefiles : [];
        const status_history = res && res.data && res.data.allsmilestatus && res.data.allsmilestatus.length > 0 ? res.data.allsmilestatus : [];
        const smile_assignment_group = res && res.data && res.data.smileassignmentgroup > 0 ? res.data.smileassignmentgroup : [];
        const smile_doctors = res && res.data && res.data.smiledoctors && res.data.smiledoctors.length > 0 ? res.data.smiledoctors : [];
        const smile_permission = res && res.data && res.data.smilepermission;
        const incomplete_case_id = res.data.incomplete_case_id;
        const case_id = res.data.case_id;

        if (smile_simulation_data.length === 0 || !res.data.dso_admin_smile_permission) {
          that.setState({
            error: true,
          });
        } else {
          that.setState({
            loading: false,
            doctor_id: doctor_id,
            smile_simulation_data: smile_simulation_data,
            smile_doctors: smile_doctors,
            smile_addresses: smile_addresses,
            smile_simulation_files: smile_simulation_files,
            smile_assignment_group: smile_assignment_group,
            smile_id: smile_id,
            status_history: status_history,
            failed_to_create: false,
            edit_in_progress: false,
            patient_ref: smile_simulation_data.smile_ref,
            patient_ref_org: smile_simulation_data.smile_ref,
            assigned_doctor_name_org: smile_simulation_data.assigned_doctor_name,
            assigned_doctor_id_org: smile_simulation_data.assigned_doctor_id,
            assigned_address_org: smile_simulation_data.address,
            assigned_doctor_name: smile_simulation_data.assigned_doctor_name,
            smile_permission: smile_permission,
            incomplete_case_id: incomplete_case_id,
            case_id: case_id,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    Axios.get(`/apiv3/doctor/${doctor_id}`)
      .then(function (res) {
        that.setState({
          doctor_info: {
            role: res.data.doctor[0].role,
            id: res.data.doctor[0].id,
            account_link_id: res.data.doctor[0].account_link_id,
            referral: res.data.doctor[0].referral,
            first_name: res.data.doctor[0].user__first_name,
            last_name: res.data.doctor[0].user__last_name,
            user_id: res.data.doctor[0].user__id,
            alias_name: res.data.doctor[0].alias_name,
          },
        });

        Axios.get(`/api/practiceIdAddress/?id=${res.data.doctor[0].account_link_id}`).then(function (res) {
          that.setState({
            dso_addresses: res.data.data,
          });
        });
      })
      .catch(function (err) {});
  };
  /**
   * Attach the response value to the question by looking at the database field (field_mapping)
   * @function
   */
  attachValueToQuestion = (questions, data) => {
    let copied_questions = _.cloneDeep(questions);

    for (let key in data) {
      for (let i = 0; i < copied_questions.length; i++) {
        if (copied_questions[i].field_mapping === key) {
          if (
            (copied_questions[i].field_mapping === 'ortho_tx_needed' ||
              copied_questions[i].field_mapping === 'previous_tx_details' ||
              copied_questions[i].field_mapping === 'advertising_source') &&
            data[key]
          ) {
            let values = data[key].split(', ');
            let mapped_value = copied_questions[i].input_list.map((list) => {
              return list.value;
            });

            // Clean bad data
            values = values.filter((value) => {
              return value.trim() !== '';
            });

            // Capture "other" data
            let other_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Other:');
            });

            // Capture "referral" data
            let referral_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Referral:');
            });

            // Capture recognized_values
            let recognized_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length > 0;
            });

            if (other_values.length >= 1) {
              let other_value_key_index = 0;

              for (let i = 0; i < other_values.length; i++) {
                if (other_values[i].indexOf('Other:') >= 0) {
                  other_value_key_index = i;
                  other_values[i] = other_values[i].replace('Other:', '').trim();
                  break;
                }
              }

              if (other_value_key_index > 0) {
                let other_values_combined = other_values.slice(0, other_value_key_index);

                if (other_value_key_index !== other_values.length) {
                  other_values_combined.concat(other_values.slice(other_value_key_index + 1));
                }

                other_values_combined.unshift(other_values[other_value_key_index]);
                other_values = other_values_combined;
              }
            }

            if (referral_values.length >= 1) {
              let referral_value_key_index = 0;

              for (let i = 0; i < referral_values.length; i++) {
                if (referral_values[i].indexOf('Referral:') >= 0) {
                  referral_value_key_index = i;
                  referral_values[i] = referral_values[i].replace('Referral:', '').trim();
                  break;
                }
              }

              if (referral_value_key_index > 0) {
                let referral_values_combined = referral_values.slice(0, referral_value_key_index);

                if (referral_value_key_index !== referral_values.length) {
                  referral_values_combined.concat(referral_values.slice(referral_value_key_index + 1));
                }

                referral_values_combined.unshift(referral_values[referral_value_key_index]);
                referral_values = referral_values_combined;
              }
            }
            recognized_values = recognized_values.filter(function (value, index, arr) {
              return value !== 'Other' && value !== 'Referral';
            });
            values = recognized_values.join(', ');
            let other = other_values.join(', ');
            let referral = referral_values.join(', ');

            copied_questions[i].value_other_org = _.cloneDeep(other.trim());
            copied_questions[i].value_other = _.cloneDeep(other.trim());
            copied_questions[i].value_referral = _.cloneDeep(referral.trim());
            copied_questions[i].value_org = _.cloneDeep(values);
            copied_questions[i].value = _.cloneDeep(values);
          } else {
            copied_questions[i].value_org = _.cloneDeep(data[key]);
            copied_questions[i].value = _.cloneDeep(data[key]);
          }
          break;
        }
      }
    }

    return copied_questions;
  };

  displayScansSubmitted = (question, related_question, disabled) => {
    return question.input_list.map((radio, index) => {
      return (
        <div className="prospect-input-field-radioedit" key={index}>
          <input
            type="radio"
            name={question.field_mapping}
            className="prospect-input-field-radioedit-button"
            value={radio.value}
            checked={radio.value === question.value || (question.value && question.value[0] && radio.value === question.value[0])}
            data-field_mapping={question.field_mapping}
            onChange={this.onChangeTextField}
            disabled={disabled}
          />
          <label
            className={disabled ? 'prospect-input-field-radioedit-label-disabled' : 'prospect-input-field-radioedit-label'}
            disabled={disabled}
            onClick={!disabled ? this.onChangeTextField : null}
            data-field_mapping={question.field_mapping}
            data-value={radio.value}
          >
            {radio.label}
          </label>
          {(radio.value === question.value || (question.value && question.value[0] && radio.value === question.value[0])) && radio.show_field ? (
            <div className="prospect-sub-question">{this.selectDisplayInputUI(related_question, true, true, disabled)}</div>
          ) : null}
        </div>
      );
    });
  };

  /**
   * open patient info edit modal
   * @function
   * @param {Object} smile_ref - smile simulation ref
   */
  onConfirmClickEditPatientModal = (smile_ref) => {
    if (!smile_ref || smile_ref.includes(' ')) {
      this.setState({
        edit_patient_error: true,
      });
      return;
    }
    this.setState({
      submission_in_progress: true,
    });
    let that = this;
    const smile_id = getSmileIdFromURL(that);
    const doctor_id = getDoctorIdFromPath(that.props.history.location.pathname);
    let form_data = {
      smile_ref: this.state.patient_ref,
      assigned_doctor_id: this.state.smile_simulation_data.assigned_doctor_id,
      assigned_address_id: this.state.smile_simulation_data.address.id,
    };

    Axios.put(`/apiv3/smilesimulation/${smile_id}?doctor_id=${doctor_id}`, form_data)
      .then(function (res) {
        let smile_simulation_data = that.state.smile_simulation_data;
        smile_simulation_data.smile_ref = smile_ref;

        that.setState((prevState) => {
          const newState = { ...prevState };
          newState.smile_simulation_data = smile_simulation_data;
          newState.patient_ref = smile_ref;
          newState.patient_ref_org = smile_ref;
          newState.assigned_doctor_name_org = smile_simulation_data.assigned_doctor_name;
          newState.assigned_doctor_id_org = smile_simulation_data.assigned_doctor_id;
          newState.assigned_address_org = smile_simulation_data.address;
          return newState;
        });

        that.setState({ submission_in_progress: false });

        that.props.closeEditPatientModal();
        if (that.state.doctor_info.role.includes('DSO')) {
          that.props.history.push({ state: { refreshInfo: 'true' } });
        }
      })
      .catch(function (err) {
        if (err && err.response && err.response.status === 409) {
          that.setState({ submission_in_progress: false });
          that.props.closeEditPatientModal();
        }
      });
  };

  /**
   * update state when smile ref is changed
   * @function
   * @param {Object} event - contains the event handler input is entered
   */
  onSmileRefChange(event) {
    let smile_ref = patientChartTextConstraint(event.target.value);

    smile_ref = smile_ref
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    this.setState({
      patient_ref: smile_ref,
      edit_patient_error: false,
    });
  }

  /**
   * update state when dso doctor is changed
   * @function
   * @param {Object} event - contains the event handler input is entered
   */
  onDsoDoctorDropdownChange(event) {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.smile_simulation_data.assigned_doctor_id = event.value;
      newState.smile_simulation_data.assigned_doctor_name = event.label;
      return newState;
    });
  }

  /**
   * update state when smile practice location is changed
   * @function
   * @param {Object} event - contains the event handler input is entered
   */
  onDsoAddressDropdownChange(event) {
    this.setState((prevState) => {
      const newAddress = this.state.smile_addresses.filter((address) => address.id === event.value)[0];
      const newState = { ...prevState };
      newState.smile_simulation_data.address = newAddress;
      return newState;
    });
  }

  /**
   * Opens a new window to the setup viewer
   * @function
   * @param {Object} event - contains the event handler setup button is clicked
   */
  onSetupClick = (event) => {
    window.open(`/setup/${event.target.dataset.case_id}`, '_top');
  };

  /**
   * Display scan files
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX}  - JSX element of Smile Simulation Case Scan Files
   */
  displaySmileScans(smile) {
    const show_setup_btn = smile.manufacturing_process && smile.manufacturing_process.log && smile.manufacturing_process.log.length > 0;
    return (
      <CollapsibleTriangleContainer collapsedHeight={210} containerClassName="case-detail__summary">
        <div className="case-detail__row case-detail__row--inner">
          {this.displaySmilePerferences()}
          <div className="col-md-6 smile_scans">
            <h3>Records</h3>
            <ThumbnailURLInterface
              files={this.state.smile_simulation_files}
              images={configLightBox(this.state.smile_simulation_files)}
              multinail={`_${smile.smile_id}`}
              hide_occlusion={true}
              fit
              has_setup={show_setup_btn}
              setupClick={this.onSetupClick}
              case_id={smile.smile_id}
            />
          </div>
        </div>
      </CollapsibleTriangleContainer>
    );
  }

  /**
   * Display smile simulation preferences
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX}  - JSX element of Smile Simulation Case Scan Files
   */
  displaySmilePerferences = () => {
    const { smile_correction, smile_spaces, smile_diastema, smile_special_instructions } = { ...this.state.smile_simulation_data };
    const residual_spaces_response = `${smile_spaces ? 'Maintain' : 'Do not maintain'} space for restoration. ${
      smile_diastema ? 'Maintain' : 'Do not maintain'
    } diastema.`;

    return (
      <div className="col-md-6 smile_preferences">
        <span className="text-wrappable">
          <h3>Smile Simulation Preferences</h3>
          <div style={{ fontWeight: 'bold' }}>Anterior-Posterior Correction</div>
          <div>{smile_correction ? 'Yes' : 'No'}</div>
          <div style={{ fontWeight: 'bold' }}>Residual Spaces</div>
          <div>{residual_spaces_response}</div>
          <div style={{ fontWeight: 'bold' }}>Special Instructions</div>
          <div>{smile_special_instructions ? smile_special_instructions : 'N/A'}</div>
        </span>
      </div>
    );
  };

  /**
   * Redirect to File Submission page on File Upload Button click
   * @function
   * @param {Object} event - info about the click event
   */
  onDoctorsUploadClick = (event) => {
    if (event && event.target && event.target.dataset && event.target.dataset.smile_id) {
      let path = this.props.history.location.pathname;
      let redirectPath = getRootPath(path);
      let that = this;
      let smile_id = event.target.dataset.smile_id;

      if (redirectPath) {
        this.props.history.push(`${redirectPath}/smile/reupload/${smile_id}`);
      } else {
        Axios.get('/apiv3/doctor').then(function (result) {
          if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
            that.props.history.push(`/portal/${result.data.doctor[0].id}/smile/reupload/${smile_id}`);
          }
        });
      }
    }
  };

  /**
   * Returns True (Disables Upload Button) if DSO doctor ID doesnt match smile simulation assigned doctor ID
   * @function
   * @return {Boolean} True
   */
  isUploadDisabled = () => {
    return (
      (!this.state.smile_permission && this.state.doctor_id !== this.state.smile_simulation_data.assigned_doctor_id.toString()) ||
      (this.state.doctor_info &&
        this.state.doctor_info.role === 'DSO_Doctor' &&
        this.state.doctor_id &&
        this.state.smile_simulation_data &&
        this.state.doctor_id !== this.state.smile_simulation_data.assigned_doctor_id.toString())
    );
  };

  /**
   * Displays smile simulation File Upload Button
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element of File Upload Button
   */
  displayFileReviewButton(smile) {
    const has_edit_permission = userHasPermission('IPP_EDIT', this.state.permissions) && !this.isUploadDisabled();
    return (
      <Button className={'submit-case-btn'} onClick={this.onDoctorsUploadClick} data-smile_id={smile.smile_id} disabled={!has_edit_permission}>
        {has_edit_permission ? '' : <LockedIcon />}
        Upload
      </Button>
    );
  }

  /**
   * get Smile Simulation Case Cancel Status
   * @function
   * @param {Object} smile - smile simulation information
   * @return {Object} Object of Cancel status
   */
  getCancelStatus(smile, title) {
    if (smile.smile_status === 'Cancelled') {
      return {
        title: title,
        isCancelled: true,
        actionRequired: false,
        date: this.getStatusDate('Cancelled'),
        status: 'Request Cancelled',
        note: '',
      };
    }
    return {};
  }

  /**
   * get Smile Simulation File Review Status
   * @function
   * @param {Object} smile - smile simulation information
   * @return {Object} Object of  File Review status
   */
  getFileReviewStatus(smile) {
    const is_complete = this.state.status_history.filter((history) => history.smile_status === 'Setup Ready for Upload').length === 1;
    const passed_complete = this.state.status_history.filter((history) => history.smile_status === 'Setup Ready for Upload').length === 0;
    const action_required = smile.smile_status === 'Doctor Upload New Files';
    const is_cancelled = smile.smile_status === 'Cancelled' && passed_complete;

    if (is_cancelled) {
      return this.getCancelStatus(smile, 'Toothprints Review');
    } else
      return {
        title: 'Toothprints Review',
        isCompleted: is_complete,
        actionRequired: action_required,
        status: is_complete ? 'Completed' : action_required ? 'Upload New Toothprints' : 'In Progress',
        note: is_complete || action_required ? '' : 'InBrace Verifying Toothprints',
        action_btn: action_required ? this.displayFileReviewButton(smile) : null,
        date:
          is_complete || action_required
            ? action_required
              ? this.getStatusDate('Doctor Upload New Files')
              : this.getStatusDate('Setup Ready for Upload')
            : null,
      };
  }

  /**
   * get Smile Simulation Case Complete Status
   * @function
   * @param {Object} smile - smile simulation information
   * @return {Object} Object of complete status
   */
  getSmileCompleteStatus(smile) {
    const completed_status = ['Released', 'Prospect Lost', 'Resume Case Submission', 'Case Submitted'];
    const is_complete = completed_status.includes(smile.smile_status);
    const is_cancelled = smile.smile_status === 'Cancelled';
    const is_creating = this.state.status_history.filter((history) => history.smile_status === 'Setup Ready for Upload').length === 1;

    if (is_cancelled && is_creating) {
      return this.getCancelStatus(smile, 'Smile Simulation');
    } else
      return {
        title: 'Smile Simulation',
        isCompleted: is_complete,
        status: is_complete || is_creating ? (is_complete ? 'Released' : 'In Progress') : '',
        note: is_complete ? '' : is_creating ? 'InBrace Preparing\nSmile Simulation' : '',
        date: is_complete ? this.getStatusDate('Released') : null,
        action_btn: is_complete ? this.displayViewSmile(smile) : null,
      };
  }

  /**
   * Get smile simulation convert to case submission status
   * @function
   * @param {Object} smile - smile simulation information
   * @return {Object} Object of complete status
   */
  getSmileCaseSubmissionStatus(smile) {
    const begin_submission = smile.smile_status === 'Released';
    const resume_submission = smile.smile_status === 'Resume Case Submission';
    const case_submitted = smile.smile_status === 'Case Submitted';
    const prospect_lost = smile.smile_status === 'Prospect Lost';
    const is_cancelled = smile.smile_status === 'Cancelled';
    const submission_status = this.translateCaseSubmissionStatus(smile.smile_status);

    if (is_cancelled) {
      return this.getCancelStatus(smile, 'Submission');
    } else
      return {
        title: 'Submission',
        actionRequired: begin_submission || resume_submission || prospect_lost,
        isCompleted: case_submitted,
        status: submission_status,
        date: prospect_lost || case_submitted ? this.getStatusDate(smile.smile_status) : null,
        action_btn: this.displayCaseSubmissionBtns(smile),
      };
  }

  /**
   * Translate smile simulation case submission status
   * @function
   * @param {String} status - smile simulation status
   * @return {Sting} Translated smile simulation status
   */
  translateCaseSubmissionStatus = (status) => {
    switch (status) {
      case 'Released':
        return 'Begin Submission\nProcess';
      case 'Resume Case Submission':
        return 'Resume Draft\nSubmission';
      case 'Case Submitted':
        return 'Submitted';
      case 'Prospect Lost':
        return 'Prospect Lost';

      default:
        return null;
    }
  };

  /**
   * get Smile Simulation Case File Review Status
   * @function
   * @param {Object} smile - smile simulation information
   * @return {Object} Object of  File Review status
   */
  getStatusDate = (status) => {
    const completed_status = this.state.status_history.filter((history) => history.smile_status === status);
    return completed_status.length > 0 ? convertDate(completed_status[0].created_date) : null;
  };

  /**
   * Displays smile simulation progress bar
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element of smile simulation progress bar
   */
  displaySmileStatus(smile) {
    const caseItems = [
      {
        title: 'Creation',
        isCompleted: true,
        status: 'Created',
        date: convertDate(smile.created_date),
      },
      this.getFileReviewStatus(smile),
      this.getSmileCompleteStatus(smile),
      this.getSmileCaseSubmissionStatus(smile),
    ];

    const cancelStatus = this.getCancelStatus(smile, '');
    const completedStatus = this.getSmileCompleteStatus(smile);

    for (const [i, item] of caseItems.entries()) {
      const isLast = i === caseItems.length - 1;
      if (!item.isCompleted || isLast) {
        Object.assign(item, { current: true });
        break;
      }
    }

    return (
      <div className="case-detail__row case-detail__row--no-border">
        <h3 className="case-detail__status-header">Status</h3>
        <SmileSimulationProgress items={caseItems} hideProgressBar={cancelStatus.isCancelled || completedStatus.isCompleted} />
      </div>
    );
  }

  /**
   * Displays view smile btn
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element of view smile btn
   */
  displayViewSmile = (smile) => {
    const that = this;
    return (
      <Button
        onClick={() => {
          that.props.history.push(`/smile_setup/${smile.smile_id}/simulation`);
        }}
      >
        View Smile
      </Button>
    );
  };

  /**
   * Displays convert to case and prospect lost btns
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element of case conversion btns
   */
  displayCaseSubmissionBtns = (smile) => {
    const begin_submission = smile.smile_status === 'Released';
    const resume_submission = smile.smile_status === 'Resume Case Submission';
    const case_submitted = smile.smile_status === 'Case Submitted';
    const has_edit_permission = this.hasPermissionToConvert();
    let submission_btns = null;

    if (begin_submission) {
      submission_btns = (
        <div className="case-submission-btns">
          <Button
            disabled={!has_edit_permission}
            className="submit-case-btn"
            onClick={() => {
              this.setState({ case_submission_modal: true });
            }}
          >
            {has_edit_permission ? null : <LockedIcon />}
            Submit Case
          </Button>
          <Button
            disabled={!has_edit_permission}
            onClick={() => {
              this.setState({ prospect_lost_modal: true });
            }}
            className="btn btn--secondary prospect-lost-btn"
          >
            {has_edit_permission ? null : <LockedIcon />}
            Prospect Lost
          </Button>
        </div>
      );
    } else if (resume_submission) {
      submission_btns = (
        <Button className="submit-case-btn" disabled={!has_edit_permission} onClick={this.redirectToCaseDraft}>
          {has_edit_permission ? null : <LockedIcon />}
          Resume
        </Button>
      );
    } else if (case_submitted) {
      submission_btns = (
        <Button className="submit-case-btn" onClick={this.redirectToCase}>
          Go to Case
        </Button>
      );
    }

    return submission_btns;
  };

  /**
   * Call an api to update database with new status change
   * @param {String} status - Value of the new status to be changed to
   * @param {String} api_endpoint - The endpoint of the status change
   * @function
   */
  onClickUpdateStatus = (smile, api_endpoint) => {
    const that = this;
    let fdata = new FormData();
    if (api_endpoint === 'convert') {
      const use_uploaded_records = this.state.case_submission_radio_options[0].state;
      fdata.append('use_uploaded_records', use_uploaded_records);
      fdata.append('doctor_id', this.state.doctor_id);
      fdata.append('assigned_doctor', this.state.smile_simulation_data.assigned_doctor_id);
      fdata.append('smile_status', this.state.smile_simulation_data.smile_status);
      this.setState({ case_submission_in_process: true });
    } else if (api_endpoint === 'lost') {
      const prospect_lost_reason =
        this.state.prospect_lost_reason === 'Other'
          ? this.state.prospect_lost_reason + ': ' + this.state.prospect_lost_comment
          : this.state.prospect_lost_reason;
      fdata.append('smile_status', this.state.smile_simulation_data.smile_status);
      fdata.append('prospect_lost_reason', prospect_lost_reason);
      fdata.append('doctor_id', this.state.doctor_id);
    }
    Axios.put(`/apiv3/smilesimulation/${smile.smile_id}/case_submission/${api_endpoint}`, fdata)
      .then(function (res) {
        if (res.data && res.data.draft_id && api_endpoint === 'convert') {
          that.setState({ incomplete_case_id: res.data.draft_id, case_submission_in_process: false });
          that.redirectToCaseDraft();
        } else if (res.data && res.data.smile_status && api_endpoint === 'lost') {
          that.updateSmileStatus('Prospect Lost');
          that.setState({ prospect_lost_modal: false, status_history: res.data.smile_status });
        }
      })
      .catch(function (err) {
        that.setState({ prospect_lost_modal: false, case_submission_modal: false, case_submission_in_process: false });
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Displays smile simulation file reupload comment
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element for smile file reupload comment
   */
  displayScanUploadComment(smile) {
    const statuses_should_display = ['Doctor Upload New Files', 'Prospect Lost'];
    const historyFiltered = [];
    this.state.status_history.forEach((status) => {
      if (statuses_should_display.includes(status.smile_status) && status.current_ind === true) {
        if (status.smile_status === 'Doctor Upload New Files') {
          status.smile_status = 'Upload New Toothprints';
        }
        historyFiltered.push(status);
      }
    });

    if (historyFiltered.length > 0) {
      return (
        <div className="case-detail__row case-detail__row--border-top">
          <SmileHistory items={historyFiltered} />
        </div>
      );
    }
  }

  /**
   * Displays smile simulation history
   * @function
   * @param {Object} smile - smile simulation information
   * @return {JSX} JSX element for smile simulation history
   */
  displaySmileHistory(smile) {
    const history = _.cloneDeep(this.state.status_history);
    const historyFiltered = [];

    history.sort((a, b) => new Date(a.created_date) - new Date(b.created_date));
    history.forEach((h) => {
      if (h.smile_status === 'Smile Simulation Submitted') {
        h['smile_status'] = 'Request Submitted';
        historyFiltered.push(h);
      } else if (h.smile_status === 'INBRACE Checking Files') {
        h['smile_status'] = 'InBrace Verifying Toothprints';
        historyFiltered.push(h);
      } else if (h.smile_status === 'Doctor Upload New Files') {
        historyFiltered[historyFiltered.length - 1]['smile_status'] = 'Toothprints Incomplete';
        h['smile_status'] = 'Upload New Toothprints';
        historyFiltered.push(h);
      } else if (h.smile_status === 'Setup Ready for Upload') {
        historyFiltered[historyFiltered.length - 1]['smile_status'] = 'Review Complete';
        h['smile_status'] = 'InBrace Preparing Smile Simulation';
        historyFiltered.push(h);
      } else if (h.smile_status === 'Released') {
        historyFiltered.pop();
        h['smile_status'] = 'Smile Simulation Completed';
        historyFiltered.push(h);
      } else if (h.smile_status === 'Prospect Lost') {
        historyFiltered.push(h);
      } else if (h.smile_status === 'Cancelled') {
        historyFiltered.push(h);
      } else if (h.smile_status === 'Case Submitted') {
        historyFiltered.push(h);
      }
    });

    return (
      <CollapsibleContainer collapsedText="Show Full History" openedText="Collapse History">
        <div className="case-detail__row case-detail__row--border-top">
          <h3>Smile Simulation History</h3>
          <SmileHistory items={historyFiltered} />
        </div>
      </CollapsibleContainer>
    );
  }

  /**
   * Displays case Header
   * @function
   * @param {Object} smile - Smile Simulation information
   * @param {Object} smile_id - smile simulation id
   * @return {JSX} JSX element for smile header
   */
  displaySmileHeader = (smile) => {
    const { smile_id } = { ...smile };

    return (
      <div className="case-detail__row clearfix no-gutter">
        <div className="col-sm-6">
          <h2 className="case-detail__header"> Smile Simulation </h2>
          {_.intersection(getBusinessRoleList(), [this.state.user_doctor_roles]).length > 0 ? (
            <Link
              to={{
                pathname: `/business/portal/smile/${smile_id}`,
              }}
            >
              {smile_id}
            </Link>
          ) : (
            smile_id
          )}
        </div>
        {smile.status_code !== 'STATUS_DRAFT_DE' && (
          <div className="col-sm-6 case-detail__address">
            <span className="emphasis">Location </span>
            <span>{getShippingAddress(smile.address)}</span>
          </div>
        )}
      </div>
    );
  };

  closeEditPatientModal = () => {
    let that = this;
    this.setState(
      (prevState) => {
        const newState = { ...prevState };
        newState.patient_ref = prevState.patient_ref_org;
        newState.smile_simulation_data.address = prevState.assigned_address_org;
        newState.smile_simulation_data.assigned_doctor_id = prevState.assigned_doctor_id_org;
        newState.smile_simulation_data.assigned_doctor_name = prevState.assigned_doctor_name_org;
        newState.edit_patient_error = false;
        return newState;
      },
      () => that.props.closeEditPatientModal()
    );
  };

  /**
   * Handle radio btn click
   * @function
   */
  onRadioBtnClicked = () => {
    let updatedState = '';
    updatedState = this.state.case_submission_radio_options;
    if (updatedState) {
      updatedState[0].state = !updatedState[0].state;
      updatedState[1].state = !updatedState[1].state;
      this.setState({ case_submission_radio_options: updatedState });
    }
  };

  /**
   * Handle modal close
   * @function
   */
  onModalClose = () => {
    let updatedState = this.state.case_submission_radio_options;
    updatedState[0].state = true;
    updatedState[1].state = false;

    this.setState({
      prospect_lost_modal: false,
      case_submission_modal: false,
      case_submission_radio_options: updatedState,
    });
  };

  /**
   * Update smile simulation status
   * @function
   * @param {string} status Updated smile status
   */
  updateSmileStatus = (status) => {
    let newSmileData = this.state.smile_simulation_data;
    newSmileData.smile_status = status;
    this.setState({ smile_simulation_data: newSmileData });
  };

  /**
   * Redirect user to draft page
   * @function
   */
  redirectToCaseDraft = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const doctor_id =
      this.state && this.state.smile_simulation_data && this.state.smile_simulation_data.assigned_doctor_id
        ? this.state.smile_simulation_data.assigned_doctor_id
        : this.state.doctor_id;
    const pathname = `${rootPath}/submission/patient?case_id=${this.state.incomplete_case_id}&doctor_id=${doctor_id}`;
    this.props.history.push(pathname);
  };

  /**
   * Redirect user to case detail page
   * @function
   */
  redirectToCase = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/case/${this.state.case_id}`;
    this.props.history.push(pathname);
  };

  /**
   * Redirect user to draft page
   * @function
   * @param {Object} e Event object
   */
  setProspectLostComment = (e) => {
    const value = removeEmoji(e.target.value);
    this.setState({ prospect_lost_comment: value });
  };

  /**
   * Determing if user can perform case submission or prospect lost action
   * @function
   * @return {Boolean} True if has smile edit permission and is a authorized user
   */
  hasPermissionToConvert = () => {
    const permissions = this.state.permissions;
    const user_id = this.state.user_id;
    const assigned_doctor_user_id = this.state.smile_simulation_data.assigned_doctor__user_id;
    const has_edit_permission = userHasPermission('IPP_EDIT', permissions);
    const has_elevated_permission = userHasPermission('DSO_ADMIN', permissions) || userHasPermission('DSO_DOCTOR_OVERRIDE', permissions);
    const is_assigned_dso_doctor = assigned_doctor_user_id === user_id;
    const is_business_user = _.intersection(getBusinessRoleList(), [this.state.user_doctor_roles]).length > 0;
    return (has_elevated_permission || is_assigned_dso_doctor || is_business_user) && has_edit_permission;
  };

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <CircleLoader fullscreen withBackground />;
    } else if (this.state.smile_simulation_data) {
      var smile = this.state.smile_simulation_data;
      return (
        <UserPermissionsContext.Consumer>
          {(context) => {
            return true ? (
              <div>
                <Helmet>
                  <title>Smile Simulation Detail {smile.smile_id} | InBrace Smile Design Studio™</title>
                </Helmet>
                <h1 className="content__header">Smile Simulation Details</h1>

                <div className="content-body">
                  <div className="case-summary-container">
                    <CardContainer className="pad-md">
                      <PatientInfo
                        case_id_smile_simulation={this.state.case_id}
                        onCaseIdClick={this.redirectToCase}
                        smile_first_name={smile.smile_first_name}
                        smile_last_name={smile.smile_last_name}
                        smile_dob={smile.smile_dob}
                        smile_sex={smile.smile_sex}
                        assigned_doctor_id={this.state.smile_simulation_data.assigned_doctor_id}
                        smile_ref={this.state.smile_simulation_data.smile_ref}
                        assigned_doctor={this.state.smile_simulation_data.assigned_doctor_name}
                        onEdit={() => this.props.openEditPatientModal()}
                      />
                    </CardContainer>
                  </div>
                  <div className="case-detail-container">
                    <CardContainer type="top-border" id={smile.smile_id} className="case-detail" key={smile.smile_id}>
                      {this.displaySmileHeader(smile)}
                      {
                        <>
                          {this.displaySmileScans(smile)}
                          {this.displaySmileStatus(smile)}
                          {this.displayScanUploadComment(smile)}
                          {this.displaySmileHistory(smile)}
                        </>
                      }
                    </CardContainer>
                  </div>
                </div>

                {this.state.refresh ? (
                  <Modal
                    preset="action"
                    x_btn={false}
                    header_text="Session Expired"
                    message_text="Sorry, your session has expired. Please refresh."
                    confirm_btn_text="Refresh"
                    onConfirmButtonClick={() => {
                      window.location.reload();
                    }}
                  />
                ) : null}

                {this.props.edit_patient_modal ? (
                  <Modal
                    preset="edit-patient"
                    modal_body_class="text-left"
                    header_text="Edit Smile Simulation Details"
                    edit_patient_warning_text="Please complete all required fields"
                    confirm_btn_text="Save"
                    close_btn_text="Cancel"
                    smile_simulation={true}
                    onCloseButtonClick={this.closeEditPatientModal}
                    onConfirmButtonClick={() => this.onConfirmClickEditPatientModal(this.state.patient_ref)}
                    account_role={this.state.doctor_info.role}
                    dso_doctors={this.state.smile_doctors}
                    dso_doctor_info={this.state.doctor_info}
                    dso_addresses={this.state.dso_addresses}
                    patient_firstname={this.state.smile_simulation_data.smile_first_name}
                    patient_lastname={this.state.smile_simulation_data.smile_last_name}
                    patient_dob={this.state.smile_simulation_data.smile_dob}
                    patient_sex={this.state.smile_simulation_data.smile_sex}
                    patient_ref={this.state.patient_ref}
                    assigned_doctor={this.state.smile_simulation_data.assigned_doctor_name}
                    dsoDoctorDropdownId={this.state.smile_simulation_data.assigned_doctor_id}
                    dsoAddressDropdownId={this.state.smile_simulation_data.address.id}
                    onDsoDoctorDropdownChange={this.onDsoDoctorDropdownChange}
                    onDsoAddressDropdownChange={this.onDsoAddressDropdownChange}
                    onPatientRefChange={this.onSmileRefChange}
                    theme="ipp"
                    case_id={this.state.smile_simulation_data.smile_id}
                    account_id={parseInt(this.state.doctor_id)}
                    edit_patient_error={this.state.edit_patient_error}
                    submission_in_progress={this.state.submission_in_progress}
                    smile_permission={this.state.smile_permission}
                    confirm_btn_disabled={
                      parseInt(this.state.doctor_id) === this.state.smile_simulation_data.assigned_doctor_id
                        ? false
                        : this.state.doctor_info.role !== 'DSO_Doctor'
                        ? !this.state.smile_permission
                        : true
                    }
                    smile_simulation_doctor_id={this.state.smile_simulation_data.assigned_doctor_id}
                    smile_simulation_status={this.state.smile_simulation_data ? this.state.smile_simulation_data.smile_status : ''}
                    smile_simulation_edit_patient_char={this.hasPermissionToConvert()}
                  />
                ) : null}
                {this.state.case_submission_modal ? (
                  <Modal
                    preset="radio_btns"
                    radio_btn_options={this.state.case_submission_radio_options}
                    modal_body_class="text-left"
                    header_text={'Submit as Case - ' + smile.smile_name}
                    message_text="Would you like to use the uploaded records for submission or upload new records?"
                    confirm_btn_text="Continue"
                    in_progress_text="Beginning case submission"
                    close_btn_text="Cancel"
                    onSelectionChange={this.onRadioBtnClicked}
                    onConfirmButtonClick={() => {
                      this.onClickUpdateStatus(smile, 'convert');
                    }}
                    in_progress={this.state.case_submission_in_process}
                    onCloseButtonClick={this.onModalClose}
                  />
                ) : null}

                {this.state.prospect_lost_modal ? (
                  <Modal
                    preset="decision-dialog"
                    header_text={'Prospect Lost'}
                    header_text_paitent_name={smile.smile_name}
                    confirm_btn_text="Prospect Lost"
                    close_btn_text="Cancel"
                    message_text="Are you sure you would like to mark prospect as lost?"
                    textarea_placeholder="Please specify prospect lost reason"
                    dropdown={true}
                    default_text="Select Reason"
                    label_dropdown="Prospect Lost Reason:"
                    dropdown_error="Please select a reason or select other with a reason description"
                    textarea_required={false}
                    hold_cancel_reasons={[
                      { category: 'Prospect Lost', reason: 'Cost' },
                      { category: 'Prospect Lost', reason: 'Insurance Not Accepted' },
                      { category: 'Prospect Lost', reason: 'Distance' },
                      { category: 'Prospect Lost', reason: 'Personal' },
                      { category: 'Prospect Lost', reason: 'Timing' },
                      { category: 'Prospect Lost', reason: 'Not Treatable Candidate' },
                      { category: 'Prospect Lost', reason: 'Patient Requested Alternate Treatment Option' },
                      { category: 'Prospect Lost', reason: 'No Dental Clearance' },
                      { category: 'Prospect Lost', reason: 'Other' },
                    ]}
                    onConfirmButtonClick={() => {
                      this.onClickUpdateStatus(smile, 'lost');
                    }}
                    onCloseButtonClick={this.onModalClose}
                    onDropdownChange={(e) => {
                      this.setState({ prospect_lost_reason: e.target.value });
                    }}
                    onTextAreaChange={this.setProspectLostComment}
                  />
                ) : null}
              </div>
            ) : (
              <Redirect to="/business/portal" />
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    edit_patient_modal: getEditPatientModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openEditPatientModal: openEditPatientModal,
      closeEditPatientModal: closeEditPatientModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmileSimulationDetails));
