import React, { Component } from 'react';
import './multiple_checkboxes.scss';
import CheckBox from '../checkbox/checkbox';
/**
 * This component is display multiple checkboxes
 * @component
 * @alias MultipleCheckBoxes
 * @props selections=[{index:0,description:'checkbox1',state:false}]
 * @category IPP
 */
class MultipleCheckBoxes extends Component {
  render() {
    return (
      <div className="multiple_checkboxes">
        {this.props.selections && this.props.selections.length > 0
          ? this.props.selections.map((selection, index) => {
              return (
                <div key={index} className={'center-text'}>
                  <p className="text-justify">
                    <CheckBox
                      id={selection.description}
                      className="label-checkbox-ir pointer text-left"
                      isChecked={selection.state}
                      label={selection.description}
                      toggleChange={() => this.props.onSelectionChange(selection.id)}
                      label_className="label-text-ir text-left"
                      disabled={this.props.disabledEdit}
                    />
                  </p>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

export default MultipleCheckBoxes;
