/**
 * File:pr_submission.js - Component to submit progress record/clinical questionaire  on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './pr_submission.scss';
// External Libs
import Axios from 'axios';
import { cloneDeep } from 'lodash';
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Internal Components
import CsqData from '../../csq.json';
import BackButton from '../../components/button/back_button';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import NextButton from '../../components/button/next_button';
import NotFound from '../404/not_found';
import Upload from './pr_upload';
import Csq from './csq';
import Thumbnail from '../../components/thumbnail/thumbnail';
import SubmissionConfirmation from '../case_submission/submission_confirmation';
import SubmitterLoader from '../case_submission/submitting_loader';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader, configLightBox, convertSex, removeCaseIdInitialNumber, textFieldLimited, checkToken } from '../../common/functions';
import { getDoctorAssignedNameFromCaseDetails } from '../../common/dso';
import { getTempLink } from '../../common/dropbox';
import { ApiServiceInstance } from '../../common/api_service';
class PrSubmission extends Component {
  constructor(props) {
    super(props);
    const csq_data = cloneDeep(CsqData);
    this.state = {
      case_id: '',
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      patient_first_name: '',
      patient_last_name: '',
      title: 'Progress Records',
      step: 'pr_csq',
      mode: 'csq_submit',
      total_steps: '3',
      current_step: '1',
      buttons: <NextButton position={'next'} description={'Parts'} onButtonClick={this.onButtonClick} />,
      warning: false,
      error: false,
      error_type: '',
      csq_data: csq_data,
      //Uploads
      fileUpload: [],
      //Upload in progress
      files_uploading: [],
      file_location: '',
      upload_date: '',
      file_removing: false,
    };

    this.goBack = this.goBack.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    let csq_data = cloneDeep(this.state.csq_data);
    const that = this;
    let case_id =
      this.props.history && this.props.history && this.props.history.location.search ? this.props.history.location.search.replace('?caseid=', '') : null;
    const mode =
      this.props.history && this.props.history && this.props.history.location && this.props.history.location.pathname.includes('csqsubmit')
        ? 'csq_submit'
        : 'pr_submit';
    const title =
      this.props.history && this.props.history && this.props.history.location && this.props.history.location.pathname.includes('csqsubmit')
        ? 'Clinical Support Questionnaire'
        : 'Progress Records';

    csq_data.case.case_id = case_id;
    let step = this.state.step;
    let total_steps = this.state.total_steps;
    if (mode === 'pr_submit') {
      step = 'pr_upload';
      total_steps = '2';
    }
    ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        csq_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
        csq_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
        csq_data.case.sex = convertSex(res.data.patient.sex);
        csq_data.case.dob = res.data.patient.dob;

        this.setState({
          case_id: case_id,
          mode: mode,
          title: title,
          step: step,
          total_steps: total_steps,
          loading: false,
          patient_first_name: res.data.patient.first_name,
          patient_last_name: res.data.patient.last_name,
          address: res.data.cases[0].shipping_address,
          csq_data: csq_data,
          doctor_id: res.data.doctor.id,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    //Check if user is still logged in
    const isAllowed = checkToken();
    if (!isAllowed) {
      this.props.history.push('/');
    }
  }

  redirectUserToCaseDetail() {
    const case_id = this.state.case_id;
    let path = this.props.history.location.pathname;
    let new_path = path.replace(/csqsubmit/, `progress/${case_id}`);
    new_path = new_path.replace(/prssubmit/, `progress/${case_id}`);
    this.props.history.push(new_path, { selectedTab: 'progress' });
  }

  onSubmitButtonClick = (event) => {
    if (this.validateUpload()) {
      const case_id = this.state.case_id;
      let data = '';
      const files = JSON.stringify(this.state.fileUpload);
      const date_taken = this.state.upload_date;

      if (
        this.props.history &&
        this.props.history.location &&
        this.props.history.location.pathname &&
        this.props.history.location.pathname.includes('csqsubmit')
      ) {
        const csq_data = this.state.csq_data;
        const csq = JSON.stringify(this.state.csq_data);
        // JSON.stringify(csq_data);
        const record_type = 'CSQ';
        data = {
          case_id,
          csq_data,
          csq,
          date_taken,
          files,
          record_type,
        };
      } else {
        const record_type = 'Progress Record';
        data = {
          case_id,
          date_taken,
          files,
          record_type,
        };
      }
      let that = this;
      this.setState({
        step: 'in_progress',
        buttons: <div />,
      });

      Axios.post(`/apiV2/pr_submission/${case_id}`, data)
        .then(function (res) {
          that.setState({
            step: 'thank_you',
          });
          if (data.record_type === 'CSQ') {
            Axios.post(`/api/email/?slug=csr-submission&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${window.location.origin}&method=standard`);
            Axios.post(`/api/email/?slug=csr-submission-cst&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${window.location.origin}&method=standard`);
          } else if (data.record_type === 'Progress Record') {
            Axios.post(
              `/api/email/?slug=progress-records-submission-03032020&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${
                window.location.origin
              }&method=standard`
            );
            Axios.post(
              `/api/email/?slug=progress-records-submission-cst&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${window.location.origin}&method=standard`
            );
          }
        })
        .catch(function (err) {
          that.setState({
            error: true,
          });
        });
      this.props.history.push({ state: { refreshInfo: 'true' } });
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'pr_upload' }, { name: 'pr_csq' }, { name: 'pr_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  hasOnePhoto = () => {
    if (this.state.fileUpload.length === 0) {
      return false;
    }
    return true;
  };
  validateUpload = (event) => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '';
    if (this.hasOnePhoto() && this.state.upload_date && !this.state.file_removing && this.state.files_uploading.length === 0) {
      return true;
    } else {
      warning_text = '<ul class="wizard-error-text">';
      if (!this.state.upload_date) {
        warning_text = warning_text + '<li>Specify date taken</li>';
      }
      if (!this.hasOnePhoto()) {
        warning_text = warning_text + '<li>At least 1 file required</li>';
      }

      if (this.state.files_uploading.length > 0) {
        warning_text = warning_text + '<li>File upload in progress</li>';
      }

      if (this.state.file_removing) {
        warning_text = warning_text + '<li>File removal in progress</li>';
      }
      warning_text = warning_text + '</ul>';
      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        warning: true,
        error_type: 'Upload Progress Records',
      });
      return false;
    }
  };

  validateCsq = (event) => {
    //Fix: const vs. let
    let csq_data = this.state.csq_data;
    let warning_text = '';
    let error = false;
    let none_selected = false;
    let warning = document.querySelector('#warning-submit');
    csq_data.questions.forEach((csq) => {
      if ((csq.input_type === 'text_area' || csq.input_type === 'text' || csq.input_type === 'date') && !csq.optional && !csq.answer_text) {
        error = true;
        csq.error = true;
      } else if (csq.input_type === 'radio_button') {
        let rb_selected =
          csq.radio_button.filter(function (rb) {
            return rb.radio_checked === true;
          }).length > 0
            ? true
            : false;
        if (!rb_selected) {
          error = true;
          csq.error = true;
        }
        const selected_rb = csq.radio_button.filter(function (rb) {
          return rb.radio_checked === true;
        })[0];
        if (csq.toothSelector === true && csq.answer_text === 'Yes') {
          if (selected_rb.selectedTeeth.length === 0) {
            error = true;
            csq.error = true;
          }
        }
      } else if (csq.input_type === 'multiple_question' && !csq.optional) {
        let rb_none_selected =
          csq.multiple_question.filter(function (m) {
            return m.input_type === 'radio_button' && m.answer_text === 'None';
          }).length > 1
            ? true
            : false;
        if (rb_none_selected) {
          error = true;
          csq.error = true;
          none_selected = true;
        }
        csq.multiple_question.forEach((mq) => {
          if ((mq.input_type === 'text_area' || mq.input_type === 'text' || mq.input_type === 'date') && !mq.answer_text) {
            if (mq.related_question_id && mq.related_mq_id) {
              if (
                csq_data.questions
                  .filter(function (q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function (m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
              ) {
                error = true;
                csq.error = true;
                mq.error = true;
              }
            } else {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          } else if (mq.input_type === 'radio_button') {
            let rb_selected =
              mq.radio_button.filter(function (rb) {
                return rb.radio_checked === true;
              }).length > 0
                ? true
                : false;
            if (!rb_selected) {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          }
        });
      }
    });
    if (error) {
      if (!warning_text) {
        if (none_selected) {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
        } else {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li></ul>';
        }
      } else {
        warning_text = warning_text + '</ul>';
      }

      warning.classList.add('warning-display');
      warning.innerHTML = warning_text;
      this.setState({
        csq_data: csq_data,
        warning: true,
        error_type: 'Clinical Support Questionnaire',
      });
      return false;
    }
    return true;
  };

  onhandleTeethClick(rb, id_string) {
    let csq_data = this.state.csq_data;
    const selected_teeth = rb.selectedTeeth;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].selected_teeth = selected_teeth;
    } else {
      if (
        csq_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].radio_button
      ) {
        csq_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .radio_button.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      } else if (
        csq_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].check_box
      ) {
        csq_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .check_box.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      }
    }
    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  }

  onButtonClick = (event) => {
    event.preventDefault();
    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    if (this.state.step === 'pr_csq') {
      if (!this.validateCsq()) {
        return;
      } else {
        this.hide_warning();
      }
      this.setState({
        step: 'pr_upload',
        buttons: (
          <div>
            <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
            <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (this.state.step === 'pr_upload') {
      if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateUpload() || this.state.file_removing || this.state.files_uploading.length > 0) {
          return;
        } else {
          this.hide_warning();
        }

        this.setState({
          step: 'pr_review',
          buttons: (
            <div>
              <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
            </div>
          ),
        });
      } else {
        this.hide_warning();
        this.setState({
          step: 'pr_csq',
          buttons: (
            <div>
              <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
            </div>
          ),
        });
      }
    } else if (this.state.step === 'pr_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.hide_warning();
        if (
          this.props.history &&
          this.props.history.location &&
          this.props.history.location.pathname &&
          this.props.history.location.pathname.includes('csqsubmit')
        ) {
          this.setState({
            step: 'pr_upload',
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          });
        } else {
          this.setState({
            step: 'pr_upload',
            buttons: (
              <div>
                {' '}
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          });
        }
      }
    }
  };

  hide_warning = () => {
    let csq_data = this.state.csq_data;
    csq_data.questions.forEach((csq) => {
      csq.error = false;
      if (csq.input_type === 'multiple_question') {
        csq.multiple_question.forEach((mq) => {
          mq.error = false;
        });
      }
    });

    this.setState({
      csq_data: csq_data,
      warning: false,
    });
  };

  goBack() {
    this.props.history.goBack();
  }

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons[id].booleanValue;
    request_reasons[id].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onCsqTextFocus = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let csq_data = this.state.csq_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = textFieldLimited(event.target.value.trim());
    } else {
      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = textFieldLimited(event.target.value.trim());
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  onEditClick = (event) => {
    let action = event.target.dataset.action;
    this.setState({
      step: action,
    });
    this.setButtons(action);
  };

  onRemoveDraft = (event) => {
    this.goBack();
  };

  onCsqCbChange = (event) => {
    let csq_data = this.state.csq_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked
    ) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = false;
    } else {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = true;
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  onCsqRbChange = (event) => {
    let csq_data = this.state.csq_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    } else {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    }
    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  onUploadTextChange = (event) => {
    const upload_date = textFieldLimited(event.target.value);
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onUploadTextBlur = (event) => {
    const upload_date = textFieldLimited(event.target.value.trim());
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onCsqTextChange = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let rb_id = 0;
    let csq_data = this.state.csq_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 4) {
      rb_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].text_area.answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 5) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].date.answer_text = textFieldLimited(event.target.value);
    } else {
      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = textFieldLimited(event.target.value);
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  files_upload = (filenames) => {
    this.setState({
      files_uploading: filenames,
    });
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    for (let index = 0; index < data.length; index++) {
      const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
      data[index]['file_url'] = file_url;
    }
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    this.setState({
      warning: false,
    });

    if (data && data[0].folder) {
      let uploadData = [];
      uploadData = this.state.fileUpload;
      uploadData = uploadData.concat(data);

      this.setState({
        fileUpload: uploadData,
        files_uploading: [],
      });
    }
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');
    let that = this;

    if (startingPath >= 0 && endPath >= 0) {
      let files = href.substring(startingPath, href.length);

      const data = {
        files,
      };

      that.setState({
        file_removing: true,
      });

      Axios.post(`/apiV2/pr_remove_upload`, data).then(function (res) {
        //Update state that removed incomplete file
        //

        if (res.data) {
          let uploadData = that.state.fileUpload;
          let newFileUpload = [];

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].upload_data !== files.substring(1)) {
              newFileUpload.push(uploadData[i]);
            }
          }

          that.setState({
            fileUpload: newFileUpload,
            file_removing: false,
          });
        }
      });
    }
  };

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else {
      return (
        <div className="portal-view-wide-2">
          <Helmet>
            <title>{this.state.title} Submission | InBrace Smile Design Studio™</title>
          </Helmet>

          <div className="page__heading page__heading--long">
            <div className="page__name page__name--default">
              {' '}
              {this.state.title} - {this.state.patient_first_name} {this.state.patient_last_name}
            </div>
          </div>
          <div className="submit__content ir__submit__content">
            <div className="submit__window ir__submit__windows">
              {this.state.step === 'pr_upload' ? (
                <Upload
                  goBack={this.onRemoveDraft}
                  photoUpload={this.state.fileUpload}
                  onUpload={this.onUpload}
                  onRemove={this.onRemove}
                  upload_state={this.files_upload}
                  upload_content={this.state.files_uploading}
                  show_warning={this.show_warning}
                  hide_warning={this.hide_warning}
                  location={this.state.file_location}
                  upload_date={this.state.upload_date}
                  onTextChange={this.onUploadTextChange}
                  onBlur={this.onUploadTextBlur}
                  error={this.state.warning}
                />
              ) : null}
              {this.state.step === 'pr_csq' ? (
                <Csq
                  csq_data={this.state.csq_data}
                  onCheckboxClicked={this.onCheckboxClicked}
                  onTextChange={this.onCsqTextChange}
                  onRbChange={this.onCsqRbChange}
                  onCbChange={this.onCsqCbChange}
                  onFocus={this.onCsqTextFocus}
                  error={this.state.warning}
                  onhandleTeethClick={(rb, id_string) => {
                    this.onhandleTeethClick(rb, id_string);
                  }}
                />
              ) : null}
              {this.state.step === 'thank_you' ? (
                <SubmissionConfirmation
                  placeholder_text={
                    this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.pathname &&
                    this.props.history.location.pathname.includes('csqsubmit')
                      ? 'Clinical Support Questionnaire'
                      : 'Progress Record'
                  }
                  onButtonClick={this.redirectUserToCaseDetail}
                />
              ) : null}
              {this.state.step === 'in_progress' ? <SubmitterLoader /> : null}
              {this.state.step === 'pr_review' ? (
                <div className="portal-view">
                  <div className="main-heading">
                    <h4>
                      {this.props.history &&
                      this.props.history.location &&
                      this.props.history.location.pathname &&
                      this.props.history.location.pathname.includes('csqsubmit')
                        ? 'Submit Clinical Support Questionnaire - Step 3 of 3'
                        : 'Submit Progress Records - Step 2 of 2'}
                    </h4>
                  </div>
                  <div>
                    <span className="pr-upload-date-label">Click "Submit" to complete the upload process</span>
                    <div className="extra-padding-headline" />
                  </div>
                  <Thumbnail files={this.state.fileUpload} images={configLightBox(this.state.fileUpload)} multinail={`_${this.state.case_id}`} />
                  <button
                    className="btn btn-primary btn-round-6 view-plan-button"
                    type="button"
                    onClick={this.onSubmitButtonClick}
                    data-case_id={this.state.case_id}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
            {this.state.hide ? null : this.state.buttons}

            <div id="wizard-warning" className={this.state.warning ? 'wizard-error noselect' : 'wizard-error wizard-hidden'}>
              <div className="wizard-error-title">
                <span id="wizard-heading" className="wizard-error-title-main">
                  {this.state.error_type}
                </span>{' '}
                <i className="fa fa-times pull-right pointer" aria-hidden="true" onClick={this.removeWizardErrorMsg} />
              </div>
              <div id="warning-submit">Warning</div>
            </div>
            {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
              <div className="pull-right wizard-bottom-btn wizard-bottom-btn-danger submit__remove" onClick={this.onModalShow} data-redirect="true">
                <div className="text-center">
                  <div className="inbrace-text-danger">
                    <i className="fa fa-times" aria-hidden="true" /> Cancel
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.showCancelCase === true ? (
              <Modal
                preset="decision"
                header_text="Cancel"
                message_text={
                  <span>
                    Are you sure you want to cancel this submission?
                    <br />
                    You will not be able to undo this action if you proceed.
                  </span>
                }
                close_btn_text="Do Not Cancel"
                confirm_btn_text="Cancel Submission"
                onCloseButtonClick={this.onModalDismiss}
                onConfirmButtonClick={this.onRemoveDraft}
              />
            ) : null}
          </div>
        </div>
      );
    }
  }
}
export default withRouter(PrSubmission);
