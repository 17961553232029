import React from 'react';

function WasmBottomContainer(props) {
  const { title, children, onClose, containerStyles } = props;

  return (
    <div className='wasm-bottom-container' style={containerStyles}>
      <div className="wasm-bottom-container-title-bar">
        <div className="wasm-bottom-container-title">{title}</div>
        <div className="wasm-bottom-container-controls">
          <span aria-hidden onClick={onClose}>&times;</span>
        </div>
      </div>
      <div className="wasm-bottom-container-content">{children}</div>
    </div>
  );
}

export default WasmBottomContainer;
