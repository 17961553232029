/**
 * File:review.js - Component to review item request submission parts on BPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';

//Internal Functions
import { getShippingAddress } from '../../common/helpers';
import { getShippingAddressName } from '../../common/helpers';
import { firstLetterCapital } from '../../common/functions';
import { getLookup } from '../../common/nomenclature';
import { hasSingleToothSelection, hasArchSegmentSelection } from '../../common/ir_helpers';

class ItemReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_comment: false,
      edit_status_comment: '',
    };
  }
  onEditClick = (event) => {
    this.setState({
      edit_comment: true,
      edit_status_comment: this.props.status_comment,
    });
  };
  onEditStatusDiscard = (event) => {
    this.setState({
      edit_comment: false,
    });
  };

  onEditStatusConfirm = (event) => {
    this.setState({
      edit_comment: false,
    });
    this.props.confirmStatuUpdate(this.state.edit_status_comment);
  };

  onTextChange = (event) => {
    this.setState({
      edit_status_comment: event.target.value,
    });
  };

  onBlur = (event) => {
    this.setState({
      edit_status_comment: event.target.value.trim(),
    });
  };
  onEditStatusKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  /**
   * Creates reason summary based on gen 2 status
   * @returns {JSX} IDB Reasons JSX
   */
  displayIdbReasons() {
    let arch_segments_options = [];
    this.props.request_reasons.forEach(function (reason) {
      if (reason.arch_segments_options) {
        let options = reason.arch_segments_options.filter(function (arch_segments_option) {
          return arch_segments_option.active === true;
        });
        options.forEach(function (arch_segments_option) {
          arch_segments_options.push(arch_segments_option.title);
        });
      }
    });
    const isDE = this.props.case_id && (this.props.case_id.indexOf('-DE') >= 0 || this.props.case_id.indexOf('-R') >= 0);
    return this.props.gen_2
      ? this.props.request_reasons.map((reason) => (
          <span key={reason.id}>
            {hasArchSegmentSelection(reason) ? (
              <li>
                {`${reason.name_label}: ${reason.arch_segment_selection_text}`} {' (' + arch_segments_options.join(', ') + ')'}
              </li>
            ) : null}
            {hasSingleToothSelection(reason) ? (
              <li>
                {`${reason.name_label}: ${reason.single_tooth_selection_text}`} {' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')'}
              </li>
            ) : null}
            {reason.booleanValue && reason.category !== 'idb_required' && (
              <li>
                {reason.name_label}:{' '}
                {reason.segment_preference_text
                  ? reason.segment_preference_text.replace(':', '')
                  : isDE && reason.label_de
                  ? reason.label_de.replace(':', '')
                  : reason.label.replace(':', '')}
                {reason.selectedTeeth && reason.selectedTeeth.length > 0 ? ' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')' : null}
                {reason.value ? ` - "${reason.value}"` : null}
              </li>
            )}
          </span>
        ))
      : this.props.request_reasons.map((reason) => (
          <span key={reason.id}>
            {reason.booleanValue ? (
              <div key={reason.id} data-id={reason.id} className="review-reasons">
                <li key={reason.id} data-id={reason.id}>
                  {reason.name_label}:{' '}
                  {reason.segment_preference_text
                    ? reason.segment_preference_text.replace(':', '')
                    : isDE && reason.label_de
                    ? reason.label_de.replace(':', '')
                    : reason.label.replace(':', '')}
                  {reason.selectedTeeth && reason.selectedTeeth.length > 0 && (!reason.segment_preference || reason.segment_preference !== 'arch_segments')
                    ? ' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')'
                    : null}
                  {reason.segment_preference && reason.segment_preference === 'arch_segments' && arch_segments_options && arch_segments_options.length > 0
                    ? ' (' + arch_segments_options.join(', ') + ')'
                    : null}
                  {reason.value ? ' - "' + reason.value + '"' : null}
                </li>
              </div>
            ) : null}
          </span>
        ));
  }

  render() {
    const ITEM_PART_LOOKUP = getLookup(this.props.case_id, true); // nomenclature lookup
    return (
      <div>
        <div className="ir-submission-heading">Item Request Form - Submit (Step 3 of 3)</div>
        <div>
          <form>
            {this.props.addresses && this.props.addresses.length > 0 ? (
              <div>
                <div className="review-headers bold-text">
                  <h4>Shipping Address:</h4>
                </div>
                <div className="emphasis pull-left">Select shipping address:</div>
                <div>
                  {this.props.addresses.map((address, idx) =>
                    address.is_active ? (
                      <div className="review-address-format" key={idx}>
                        <input
                          className="address-format-radio review-radio"
                          type="radio"
                          key={idx}
                          name="address"
                          defaultChecked={address.id === this.props.address_id}
                          onChange={this.props.onInputChange}
                          defaultValue={address.id}
                          data-id={address.id}
                          id={address.id}
                        />
                        <label className="review-address-label" htmlFor={address.id}>
                          <span className="pointer bold-text" onClick={this.props.onInputChange} data-id={address.id}>
                            {firstLetterCapital(getShippingAddressName(address))} <br />
                          </span>
                          <span className="pointer" onClick={this.props.onInputChange} data-id={address.id}>
                            {getShippingAddress(address)}
                          </span>
                        </label>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            ) : (
              <div>No addresses found for this users account. Please contact your InBrace Rep.</div>
            )}
          </form>
          {this.props.mode === 'update' && this.props.status_comment ? (
            <div>
              <div className="bpp-review__line-divison" />
              <div className="review-headers review-heading bold-text">
                <h4>Reason for Update:</h4>
              </div>
              <div className="bpp-heading-edit-button" data-action="update_reason" onClick={this.onEditClick}>
                Edit
              </div>
              {this.state.edit_comment ? (
                <div>
                  <textarea
                    className={
                      this.props.idb_other_comments_require && !this.props.idb_other_comments && this.props.error
                        ? 'form-control item-reason-comment-warning'
                        : 'form-control bpp-item-update-comment'
                    }
                    placeholder="Specify segmented bonding jig if needed"
                    id="other-reason-for-idb"
                    data-id="6"
                    data-name="idb_4"
                    rows="2"
                    value={this.state.edit_status_comment}
                    onChange={this.onTextChange}
                    onBlur={this.onBlur}
                    onKeyDown={this.onEditStatusKeyPress}
                  />
                  <div className="ir-profile-edit">
                    <div
                      id="edit-confirm"
                      className="btn btn-light btn-next"
                      onClick={this.onEditStatusConfirm}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                    <div
                      id="edit-discard"
                      className="btn btn-light btn-next"
                      onClick={this.onEditStatusDiscard}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Cancel"
                    >
                      <i className="fa fa-times" aria-hidden="true" />
                    </div>
                    {this.state.name_error ? <span className="name-warning-text">Please enter a first name</span> : null}
                  </div>
                </div>
              ) : (
                <div>{this.props.status_comment}</div>
              )}
            </div>
          ) : null}
          <div className="bpp-review__line-divison" />
          <div>
            <div className="review-headers review-heading bold-text">
              <h4>Item Request Summary:</h4>
            </div>

            <div className="bpp-heading-edit-button" data-action="item_parts" onClick={this.props.onEditClick}>
              Edit
            </div>
          </div>
          <div className="review-item bpp-review-heading-item">Item</div>
          <div className="review-quantity bpp-review-heading-quantity text-center">Quantity</div>
          {this.props.item_parts_quantity.map((item_part, idx) =>
            item_part.current_ind && item_part.quantity > 0 ? (
              <div key={item_part.id}>
                <div className="review-item">{ITEM_PART_LOOKUP[item_part.part_id]}</div>
                <div className="review-quantity text-center">{item_part.quantity}</div>
              </div>
            ) : null
          )}
          <div className="extra-padding" />
          <div className="bpp-review__line-divison" />
          <div>
            <div className="review-headers review-heading bold-text">
              <h4>Reason for Request:</h4>
            </div>
            <div className="bpp-heading-edit-button" data-action="item_reason" onClick={this.props.onEditClick}>
              Edit
            </div>
          </div>
          {this.displayIdbReasons()}
        </div>
      </div>
    );
  }
}
export default ItemReview;
