/**
 * Checks if the JWT Token exist
 * @function
 */
const checkJWTToken = () => {
  const token = getJWTToken();

  if (token) {
    return true;
  } else {
    return false;
  }
};
/**
 * Retrieves the JWT Token
 * @function
 */
const getJWTToken = () => {
  return localStorage && localStorage.getItem('token');
};
/**
 * Remove JWT Token
 * @function
 */
const removeJWTToken = () => {
  const token = getJWTToken();

  if (token) {
    localStorage.removeItem('token');
  }
};

export { checkJWTToken, getJWTToken, removeJWTToken };
