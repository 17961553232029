import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as InLogo } from '../components/in.svg';
import Modal from '../../components/modal/modal';
import SecurityClearance from '../../components/security/security_clearance';

// Redux
import {
  fetchLogoPicture,
  openUploadPictureModal,
  closeUploadPictureModal,
  openLogoPictureMenu,
  closeLogoPictureMenu,
  openDeletePictureModal,
  closeDeletePictureModal,
  setLogoImage,
  onDeleteLogoPicture,
  onLogoPictureUpload,
} from '../../redux/actions/ipp/practice_logo';
import {
  getLogoImage,
  getUploadPictureModal,
  getLogoPictureMenu,
  getDeletePictureModal,
  getUploadPicturePending,
  getUploadPictureError,
  getInvalidLogoPictureFormatError,
  getInvalidLogoPictureSize,
} from '../../redux/reducers/ipp/practice_logo';

/**
 * Shows profile picture in account page on IPP
 *
 * @component
 * @alias DoctorPracticeLogo
 * @category IPP
 */
class PracticeLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practice_logo_picture_width_in_px: 150,
      fetch_done: false,
    };

    this.inputReference = React.createRef();
    this.onReupload = this.onReupload.bind(this);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.fetchInformation();
  }

  fetchInformation() {
    if (!this.state.fetch_done) {
      this.props.fetchLogoPicture(this.props.address_id, this);
      this.setState({ fetch_done: true });
    }
  }

  /**
   * Handles event when user clicks on camera icon to upload a profile picture
   *
   * @function
   */
  fileUploadAction = () => {
    this.inputReference.current.click();
  };

  /**
   * Handles when user chooses to reupload their profile picture
   *
   * @function
   */
  onReupload() {
    this.fileUploadAction();
    this.props.closeLogoPictureMenu();
  }

  /**
   * Retrieves error message for modal when the user uploads an invalid file type
   *
   * @function
   * @return {JSX} - Returns JSX element with error message
   */
  getInvalidLogoPictureFormatErrorMessage() {
    return (
      <div>
        The file type is not supported.
        <br />
        Please upload a JPG or PNG or WEBP or SVG file and try again.
      </div>
    );
  }

  render() {
    return (
      <>
        {this.props.edit_picture ? (
          <>
            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <div>
                <span className="practice-logo-title emphasis">Practice Logo</span>
                <div
                  onClick={
                    this.props.logo_image
                      ? this.props.practice_logo_picture_menu
                        ? this.props.closeLogoPictureMenu
                        : this.props.openLogoPictureMenu
                      : this.fileUploadAction
                  }
                  className="practice-logo-camera"
                >
                  <i className="fa fa-camera" aria-hidden="true"></i>
                </div>
              </div>
            </SecurityClearance>
            {this.props.practice_logo_picture_menu && (
              <div className="practice-logo-menu">
                <ul>
                  <li onClick={this.onReupload}>
                    <i className="fa fa-upload" />
                    Upload
                  </li>
                  <li onClick={() => this.props.openDeletePictureModal(this)}>
                    <i className="fa fa-trash" />
                    Delete
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : null}

        {this.props.logo_image ? (
          <img src={this.props.logo_image} alt={''} />
        ) : (
          // <canvas width={this.state.practice_logo_picture_width_in_px} height={this.state.practice_logo_picture_width_in_px} ref={this.canvasRef}></canvas>
          <div className="account__initials">
            <InLogo />
          </div>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={this.inputReference}
          onChange={(e) => this.props.onLogoPictureUpload(e, this.props.address_id, this)}
          accept="image/jpg,image/jpeg,image/png,image/webp,image/svg"
        />

        {this.props.upload_picture_modal ? (
          <Modal
            preset="profile-picture"
            theme="ipp"
            header={this.props.upload_picture_error || this.props.invalid_practice_logo_picture_format_error || this.props.invalid_practice_logo_picture_size}
            header_text={
              this.props.upload_picture_error
                ? 'Upload Failed'
                : this.props.invalid_practice_logo_picture_format_error
                ? 'Unsupported File Type'
                : 'Image too small'
            }
            message_text_class={'center-text'}
            message_text={
              this.props.upload_picture_error
                ? 'There was an upload error. Please try again.'
                : this.props.invalid_practice_logo_picture_format_error
                ? this.getInvalidLogoPictureFormatErrorMessage()
                : this.props.invalid_practice_logo_picture_size
                ? 'Unable to upload image. Please upload a larger image.'
                : 'Uploading...'
            }
            in_progress={this.props.upload_picture_pending}
            in_progress_text="Uploading..."
            close_btn={false}
            confirm_btn={
              this.props.upload_picture_error || this.props.invalid_practice_logo_picture_format_error || this.props.invalid_practice_logo_picture_size
            }
            confirm_btn_text="OK"
            onConfirmButtonClick={() => this.props.closeUploadPictureModal(this)}
          />
        ) : null}
        {this.props.delete_picture_modal ? (
          <Modal
            preset="decision"
            theme="ipp"
            header_text="Delete Practice Logo"
            message_text="Are you sure you want to delete your practice logo?"
            confirm_btn_text="Delete"
            close_btn_text="Cancel"
            onConfirmButtonClick={() => this.props.onDeleteLogoPicture(this)}
            onCloseButtonClick={this.props.closeDeletePictureModal}
            is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logo_image: getLogoImage(state),
    upload_picture_modal: getUploadPictureModal(state),
    practice_logo_picture_menu: getLogoPictureMenu(state),
    delete_picture_modal: getDeletePictureModal(state),
    upload_picture_error: getUploadPictureError(state),
    upload_picture_pending: getUploadPicturePending(state),
    invalid_practice_logo_picture_format_error: getInvalidLogoPictureFormatError(state),
    invalid_practice_logo_picture_size: getInvalidLogoPictureSize(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLogoPicture: fetchLogoPicture,
      openUploadPictureModal: openUploadPictureModal,
      closeUploadPictureModal: closeUploadPictureModal,
      openLogoPictureMenu: openLogoPictureMenu,
      closeLogoPictureMenu: closeLogoPictureMenu,
      openDeletePictureModal: openDeletePictureModal,
      closeDeletePictureModal: closeDeletePictureModal,
      setLogoImage: setLogoImage,
      onDeleteLogoPicture: onDeleteLogoPicture,
      onLogoPictureUpload: onLogoPictureUpload,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PracticeLogo);
