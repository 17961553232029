export class WindowUtils {
  /**
   * Opens new window with the url
   * @param {String} url
   *
   * @return void
   */
  static openInNewWindow(url) {
    const win = window.open(url, '_blank');

    if (!win) {
      return;
    }
    win.focus();
    win.onblur = () => win.close();
  }
  /**
   * Opens a new browser window and navigates to a URL resolved from a provided promise function.
   * See https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
   *
   * @param {Function} promiseFunction - An asynchronous function that returns a promise resolving to a URL string.
   * @param {...*} args - Arguments to be passed to the promiseFunction.
   */
  static async openInNewWindowWithPromise(promiseFunction, ...args) {
    const popup = window.open('');
    try {
      const url = await promiseFunction(...args);
      popup.location.href = url;
      popup.focus();
    } catch (e) {
      console.error(e);
    }
  }
}
