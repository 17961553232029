/**
 * File: notes.js - A component that display the notes for the current case
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './notes.scss';
// External Libs
import Axios from 'axios';
import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import { isHTML } from '../../common/functions';

// Internal Functions
import { setTokenHeader, textFieldCheck, removeEmoji } from '../../common/functions';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

/**
 * Displays the notes modal
 * @component
 * @alias Notes
 * @category BPP
 */
class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      caseNotes: [],
      loading: true,
      note: '',
      isEdit: '',
      isDelete: '',
      sort: true,
      user_id: '',
      textarea_maxlength_active: false,
      textarea_maxlength_message: 'The character limit has been reached.',
    };

    this.setNotes = this.setNotes.bind(this);
  }

  /**
   * Records user note input and stores in state
   * @function
   */
  onNoteChange = (e) => {
    if (e.key === 'ENTER') {
      this.onNoteSubmission();
    } else {
      const text = removeEmoji(textFieldCheck(e.target.value));

      if (text.length >= e.target.maxLength) {
        this.setState({ textarea_maxlength_active: true, note: text });
      } else {
        this.setState({
          textarea_maxlength_active: false,
          note: text,
        });
      }
    }
  };

  setNotes = (notes) => {
    if (!this.state.sort) {
      notes = notes.reverse();
    }

    return notes;
  };

  onNoteSubmission = (e) => {
    setTokenHeader();

    if (this.state.note !== '') {
      let that = this;
      let formData = new FormData();
      formData.append('note_text', this.state.note);
      let url = '';
      if (this.props.ireq_id) {
        url = '/api/ir_note/?ireq_id=' + this.props.ireq_id;
      } else if (this.props.case_id) {
        url = '/api/note/?case_id=' + this.props.case_id;
      }
      Axios.post(url, formData).then(function (res) {
        if (res && res.data && res.data.data) {
          that.setState({
            textarea_maxlength_active: false,
            caseNotes: that.setNotes(res.data.data),
            note: '',
          });

          that.props.setNotesIndicator(res.data.data);
        }
      });
    }
  };

  onEditNoteSubmission = (e) => {
    setTokenHeader();

    if (this.state.note !== '') {
      let that = this;
      let formData = new FormData();
      formData.append('note_text', this.state.note);
      let url = '';
      if (this.props.ireq_id) {
        url = '/api/ir_note/?ireq_id=' + this.props.ireq_id + '&note_id=' + this.state.isEdit;
      } else if (this.props.case_id) {
        url = '/api/note/?case_id=' + this.props.case_id + '&note_id=' + this.state.isEdit;
      }
      Axios.post(url, formData).then(function (res) {
        if (res && res.data && res.data.data) {
          that.setState({
            caseNotes: that.setNotes(res.data.data),
            note: '',
            isEdit: '',
          });

          that.props.setNotesIndicator(res.data.data.length > 0);
        }
      });
    }
  };

  onDeleteNoteSubmission = (e) => {
    setTokenHeader();

    if (e.target.dataset.note_id) {
      let that = this;
      let note_id = e.target.dataset.note_id;
      let url = '';
      if (this.props.ireq_id) {
        url = '/api/ir_note/?ireq_id=' + this.props.ireq_id + '&note_id=' + note_id;
      } else if (this.props.case_id) {
        url = '/api/note/?case_id=' + this.props.case_id + '&note_id=' + note_id;
      }
      Axios.delete(url).then(function (res) {
        if (res && res.data && res.data.data) {
          let isEdit = that.state.isEdit;
          let note = that.state.note;
          if (note_id === that.state.isEdit) {
            isEdit = '';
            note = '';
          }

          that.setState({
            caseNotes: that.setNotes(res.data.data),
            isEdit: isEdit,
            note: note,
            isDelete: '',
          });

          that.props.setNotesIndicator(res.data.data.length > 0);
        }
      });
    }
  };

  setEditSubmission = (e) => {
    this.setState({
      isEdit: e.target.dataset.note_id,
      note: e.target.dataset.note_text,
    });
  };

  setDeleteSubmission = (e) => {
    this.setState({
      isDelete: e.target.dataset.note_id,
    });
  };

  cancelDeleteSubmission = (e) => {
    this.setState({
      isDelete: '',
    });
  };

  toggleNoteSort = () => {
    this.setState({
      caseNotes: this.state.caseNotes.reverse(),
      sort: !this.state.sort,
    });
  };

  componentDidMount() {
    let that = this;
    let url = '';
    if (this.props.ireq_id) {
      url = '/api/ir_note/?ireq_id=' + this.props.ireq_id;
    } else if (this.props.case_id) {
      url = '/api/note/?case_id=' + this.props.case_id;
    }
    Axios.get(url).then(function (res) {
      if (res && res.data && res.data.data) {
        that.setState({
          caseNotes: that.setNotes(res.data.data),
          loading: false,
          user_id: res.data.request_id,
        });
      }
    });
  }

  enableEditDeleteButton(date, user_id) {
    let start = new Date(date);
    let end = new Date();
    let result = false;

    if (end.getTime() - start.getTime() < 3600000 && this.state.user_id && parseInt(this.state.user_id) === parseInt(user_id)) {
      result = true;
    }

    return result;
  }

  /**
   * Returns whether the note can be edit/delete base on its text or type
   * @param {Object} note - The note
   * @return {Boolean} Whether the note can be edit/delete
   */
  canEditDeleteNote(note) {
    if (
      (note.note_type &&
        (note.note_type.includes('Clinical Review') || note.note_type === 'Change Treatment' || note.note_type === 'External Target Date Override')) ||
      note.note_text.includes('External Estimated Ship Date Updated From')
    ) {
      return false;
    }
    const disableEditNoteTypes = ['Treatment Plan Edited', 'Technology Updated'];

    return disableEditNoteTypes.every((disableNote) => note.note_text.indexOf(disableNote) === -1);
  }

  /**
   * Formats the text to add bold format in the beginning.
   *
   * The text format is expected to be `"<bold text> - <more text>"`.
   * If the text doesn't have `<more text>`, then it will only show `<bold text>`.
   * @function
   * @param {String} text - The text to format
   * @return {String} Formatted text
   */
  formatText(text) {
    const boldTexts = [
      'Case Held',
      'Case Cancelled',
      'Expedite Request Submitted',
      'Expedite Request Rejected',
      'Expedite Request Approved',
      'Expedite Request Updated',
      'Expedite Request Cancelled',
      'Treatment Plan Edited',
      'Technology Updated',
    ];

    for (let bt of boldTexts) {
      let i = text.indexOf(bt);
      if (i !== -1) {
        const hasMoreText = text.length > i + bt.length + 4;
        return (
          <>
            <b>{bt}</b>
            {hasMoreText && text.substr(i + bt.length)}
          </>
        );
      }
    }
    return text;
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <React.Fragment>
              {userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                <div className="modal-condense-top">
                  {this.state.caseNotes.length === 0 ? (
                    <div className="no-notes">No notes were found for this case.</div>
                  ) : (
                    <table className="table table-note">
                      <thead>
                        <tr>
                          <th className="table-comments">Comments</th>
                          <th className="table-date-time" onClick={this.toggleNoteSort}>
                            Created Date Time{' '}
                            {this.state.sort ? (
                              <i className="fa fa-caret-down pull-right" aria-hidden="true" onClick={this.toggleNoteSort} />
                            ) : (
                              <i className="fa fa-caret-up pull-right" aria-hidden="true" onClick={this.toggleNoteSort} />
                            )}
                          </th>
                          <th className="table-created-by">Created By</th>
                          <th className="table-action" />
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.caseNotes.map((note, index) => {
                          return (
                            <tr
                              className={
                                parseInt(this.state.isEdit) === parseInt(note.id)
                                  ? 'edit-text'
                                  : parseInt(this.state.isDelete) === parseInt(note.id)
                                  ? 'delete-text'
                                  : this.state.isEdit
                              }
                              key={index}
                            >
                              {isHTML(note.note_text) ? (
                                <td className="wrap-text">
                                  <div
                                    className="ql-editor"
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(note.note_text, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                                    }}
                                  />
                                </td>
                              ) : (
                                <td className="wrap-text">{this.formatText(note.note_text)}</td>
                              )}

                              <td className="table-date-time">{note.created_date}</td>
                              <td className="table-created-by">{note.created_by_name}</td>
                              <td className="table-action">
                                {this.canEditDeleteNote(note) && this.enableEditDeleteButton(note.created_org, note.created_by_id) ? (
                                  <span className="">
                                    <i
                                      className="fa fa-pencil"
                                      aria-hidden="true"
                                      data-note_id={note.id}
                                      data-note_text={note.note_text}
                                      onClick={this.setEditSubmission}
                                    />{' '}
                                    <i className="fa fa-trash-o" aria-hidden="true" data-note_id={note.id} onClick={this.setDeleteSubmission} />
                                  </span>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              ) : null}
              {(this.props.case_id && userHasPermission('CASE_DETAILS_NOTES_CREATE', user_roles_and_permissions.permissions)) ||
              (this.props.ireq_id && userHasPermission('IREQ_NOTES_CREATE', user_roles_and_permissions.permissions)) ? (
                <div className="modal-condense">
                  {this.state.isDelete ? (
                    <div className="text-center">
                      <div className="text-message">Are you sure you want to delete this note? </div>{' '}
                      <button
                        type="button"
                        className="btn btn-danger btn-link-round btn-space-general"
                        data-note_id={this.state.isDelete}
                        onClick={this.onDeleteNoteSubmission}
                      >
                        Delete
                      </button>
                      <button type="button" className="btn btn-default btn-link-round btn-space-general" onClick={this.cancelDeleteSubmission}>
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <div>
                      <textarea
                        className={this.state.textarea_maxlength_active ? 'text-notes error-notes' : 'text-notes'}
                        rows="5"
                        placeholder="Enter note here..."
                        maxLength={999}
                        onChange={this.onNoteChange}
                        value={this.state.note}
                      />

                      {this.state.textarea_maxlength_active ? (
                        <div className="modal-error">
                          <p>{this.state.textarea_maxlength_message}</p>
                        </div>
                      ) : null}

                      <div className="text-center">
                        {this.state.isEdit ? (
                          <button type="button" className="btn btn-light" onClick={this.onEditNoteSubmission}>
                            Finish Editing Note
                          </button>
                        ) : (
                          <button type="button" className="btn btn-light" onClick={this.onNoteSubmission}>
                            Create Note
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
            </React.Fragment>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default Notes;
