import './dxtx_proto.scss';

import React, { Component } from 'react';
import Axios from 'axios';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import * as RS from 'reactstrap';
import { generateId } from '../../common/functions';

import { withRouter } from 'react-router-dom';

import tooth_diagram from './tooth_diagram.svg';
import { ApiServiceInstance } from '../../common/api_service';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

class QBox extends Component {
  constructor(props) {
    super(props);

    this.popRef = React.createRef();
    this.state = {
      popOpen: false,
      infoEl: null,
      info: null,
    };
    this.handleClick.bind(this);
    this.setInfoEl.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('scroll', this.handleClick, false);
    this.setInfoEl(this.props.info);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('scroll', this.handleClick, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setInfoEl(this.props.infoEl);
  }

  setInfoEl = (info) => {
    if (Array.isArray(info)) info = info.join('');
    if (this.state.info === info) {
      return;
    }
    this.setState({ info: info });

    // eslint-disable-next-line
    let infoClick = () => this.setState({ popOpen: !this.state.popOpen });
    let infoEnter = () => this.setState({ popOpen: true });
    let infoLeave = () => this.setState({ popOpen: false });

    // eslint-disable-next-line
    let infoEl = this.props.info ? (
      <span>
        {/* eslint-disable-next-line */}
        <a onMouseEnter={infoEnter} onMouseLeave={infoLeave}>
          <i className="fa fa-info-circle" ref={this.popRef} />
        </a>
      </span>
    ) : null;
    if (this.props.setInfo) {
      this.props.setInfo(infoEl);
    } else {
      this.setState({ infoEl: infoEl });
    }
  };

  static defaultProps = {
    title: 'Untitled',
  };

  handleClick = (evt) => {
    this.setState({ popOpen: false });
  };

  render() {
    return (
      <div className="question-box">
        {/* eslint-disable-next-line */}
        {this.props.anchor ? <a id={this.props.anchor} className="anchor-offset" /> : null}
        <span className="title">{this.props.title_alt ? this.props.title_alt : this.props.title}: </span>
        {this.state.infoEl ? this.state.infoEl : null}
        <div className="question">{this.props.children}</div>
        {this.props.info && this.popRef.current ? (
          <RS.Popover placement="top" isOpen={this.state.popOpen} target={this.popRef.current} hideArrow={false} className="info-box">
            <RS.PopoverBody>{this.props.info}</RS.PopoverBody>
          </RS.Popover>
        ) : null}
      </div>
    );
  }
}

class BtnGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: new Set(),
      selected: new Set(),
    };

    this.onClick.bind(this);
    this.isDisabled.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      disabled: new Set(props.disabled),
      selected: new Set(props.selected),
      // missing: new Set(props.missing)
    };
  }

  static defaultProps = {
    enabled: true,
  };

  isDisabled = (name) => {
    return !this.props.enabled || this.state.disabled.has(name);
  };

  onClick = (evt) => {
    let btn = evt.target;
    let sel = btn.classList.contains('active');
    let name = btn.innerText;
    // let isMissing = this.state.missing.has(name);
    if (this.props.onClick && !this.isDisabled(name)) {
      this.props.onClick(name, !sel);
    }
  };

  render() {
    return (
      <div>
        <div>
          {this.props.buttons.map((item, index) => {
            const classes = ['btn-ib'];
            if (this.isDisabled(item)) classes.push('disabled');
            if (this.state.selected.has(item)) classes.push('active');
            // if(this.state.missing.has(item))
            //   classes.push('missing')
            return (
              <button type="button" className={classes.join(' ')} onClick={this.onClick} key={item}>
                {item}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

class TeethSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.makeGroup.bind(this);
    this.onClick.bind(this);
  }

  static defaultProps = {
    hasUpper: true,
    hasLower: true,
    selected: [],
    disabled: [],
    // missing: [],
  };

  makeGroup = (buttons, enabled) => {
    return (
      <div className="col-md-6">
        <BtnGroup
          buttons={buttons}
          // missing={this.props.missing}
          enabled={enabled}
          disabled={this.props.disabled}
          selected={this.props.selected}
          onClick={this.onClick}
        />
      </div>
    );
  };

  onClick = (tooth, isSelected) => {
    if (this.props.onClick) this.props.onClick(tooth, isSelected);
  };

  render() {
    const upperHTML = (
      <div className="row">
        {this.makeGroup(Teeth.UR, this.props.hasUpper)}
        {this.makeGroup(Teeth.UL, this.props.hasUpper)}
      </div>
    );
    const lowerHTML = (
      <div className="row">
        {this.makeGroup(Teeth.LR, this.props.hasLower)}
        {this.makeGroup(Teeth.LL, this.props.hasLower)}
      </div>
    );

    return (
      <div className={'teeth-selector'}>
        {this.props.hasUpper || true ? upperHTML : null}
        {this.props.hasLower || true ? lowerHTML : null}
      </div>
    );
  }
}

class CheckBar extends Component {
  constructor(props) {
    super(props);

    this.handleClick.bind(this);
  }

  static defaultProps = {
    buttons: {},
    value: '',
  };

  handleClick = (evt) => {
    if (this.props.onClick) this.props.onClick(evt.target.value);
  };

  render() {
    return (
      <div className="btn-bar" role="toolbar">
        {Object.entries(this.props.buttons).map(([key, name]) => {
          return (
            <button type="button" key={key} onClick={this.handleClick} className={'btn-ib ' + (key === this.props.value ? 'active' : '')} value={key}>
              {name}
            </button>
          );
        })}
      </div>
    );
  }
}

class ToothViewer extends Component {
  constructor(props) {
    super(props);

    this.refOut = React.createRef();
    this.refIn = React.createRef();

    this.state = {
      width: '100%',
    };

    this.prevF.bind(this);
    this.nextF.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize.bind(this));
    let that = this;
    // setTimeout(() => {
    that.onResize();
    // },0);
  }

  componentDidUpdate() {
    let that = this;
    // setTimeout(() => {
    that.onResize();
    // },0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  onResize() {
    let w = this.refOut && this.refOut.current && this.refOut.current.offsetWidth ? this.refOut.current.offsetWidth : '';
    if (w !== this.state.width) {
      this.setState({
        width: w,
      });
    }
  }

  nextF = () => {};
  prevF = () => {};

  render() {
    let tset = this.props.teethSets;
    return (
      <div ref={this.refOut} className="tooth-viewer-outer-frame" style={{ width: '100%' }}>
        <div className="tooth-viewer-inner-frame" ref={this.refIn} style={{ width: this.state.width }}>
          <div className="tooth-viewer" id="tooth-viewer">
            <SvgLoader path={tooth_diagram}>
              <SvgProxy selector="#Extractions line" class="" fill="none" stroke="none" />
              <SvgProxy selector="#Teeth path" fill="white" class="" stroke="black" stroke-dasharray="" />
              <SvgProxy selector="#Brackets rect" fill="none" class="" stroke="none" />
              {tset.restrictTeeth.map((x) => {
                let gid = '#Teeth #' + x;
                let gSel = gid + ' path';
                return <SvgProxy key={x} selector={gSel} fill="#CCC" />;
              })}
              {tset.missingTeeth.map((x) => {
                let gid = '#Teeth #' + x;
                let gSel = gid + ' path';
                return <SvgProxy key={x} selector={gSel} fill="none" stroke="black" stroke-dasharray="10,5" />;
              })}
              {tset.extractTeeth.map((x) => {
                let gid = '#Extractions #' + x + '_X';
                let gSel = gid + ' line';
                return <SvgProxy key={x} selector={gSel} fill="none" stroke="black" stroke-width="5pt" />;
              })}
              {tset.bracketTeeth.map((x) => {
                let gid = '#Brackets #' + x + '_1_';
                // let gSel = gid + " rect";
                let gSel = gid;
                return <SvgProxy key={x} selector={gSel} fill="black" />;
              })}
            </SvgLoader>
          </div>
        </div>
      </div>
    );
  }
}

class DxTx extends Component {
  constructor(props) {
    super(props);

    this.caseID = '7L3VX-DE1';
    this.minPreset = 5;
    this.maxPreset = 7;
    this.presets = DxTx.makePresets(this.minPreset, this.maxPreset);
    this.state = {
      thumbs: [],
      archToTreat: 'both',
      opposingArch: 'current',
      // setupOpposing: false,
      missingTeeth: ['UL8', 'UR8', 'LL8', 'LR8'],
      extractTeeth: [],
      bracketTeeth: this.presets[7],
      restrictTeeth: [],
      restrictReason: '',
      chief_complaint: '',
      notes: '',
      notes_aux: '',
      notes_spacing: '',
      conditions: {
        ap_relationship: {
          active: false,
          title: 'AP Relationship',
          info: [
            'InBrace is suited for cases that present Class II discrepancies ',
            'up to 1.5mm or Class III discrepancies up to 1mm. If larger discrepancies ',
            'exist, use sagittal auxiliaries before or in tandem with InBrace to ',
            'help achieve treatment goals.',
          ],
          ap_improvement: 'maintain',
          aux_note: '',
        },
        midlines: {
          active: false,
          title: 'Midlines',
          upper_mid_improve: 'maintain',
          upper_mid_improve_right_by: '',
          upper_mid_improve_left_by: '',
          lower_mid_improve: 'maintain',
          lower_mid_improve_right_by: '',
          lower_mid_improve_left_by: '',
        },
        crowding: {
          active: false,
          title: 'Crowding',
          info_title: true,
          upper_expand: 'as_needed',
          upper_ipr_ant: 'as_needed',
          upper_ipr_right: 'as_needed',
          upper_ipr_left: 'as_needed',
          upper_procline: 'as_needed',
          lower_expand: 'as_needed',
          lower_ipr_ant: 'as_needed',
          lower_ipr_right: 'as_needed',
          lower_ipr_left: 'as_needed',
          lower_procline: 'as_needed',
        },
        spacing: {
          active: false,
          title: 'Spacing',
          info_title: true,
          spaces: 'close',
          spaceTeeth: [],
          spacingUpper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          spacingLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          leave_spaces_text: '',
        },
        overjet: {
          active: false,
          title: 'Overjet',
          improve: 'inbrace',
          improve_ipr: false,
          procline: false,
          aux_note: '',
        },
        deepbite: {
          active: false,
          title: 'Deepbite',
          info: [
            'InBrace is suited for cases that present 6mm of deepbite or less. ',
            'If deepbite greater than 6mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          correct: 'inbrace',
          aux_note: '',
        },
        openbite: {
          active: false,
          title: 'Openbite',
          title_alt: 'Anterior Openbite',
          info: [
            'InBrace is suited for cases that present 3mm of openbite or less. ',
            'If openbite greater than 3mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          improve_amt: 'maintain',
        },
        crossbite: {
          active: false,
          title: 'Crossbite/Transverse',
          info: ['InBrace can moderately expand/restrict archforms.'],
          anterior_advance_upper: false,
          anterior_retract_lower: false,
          posterior_expand_upper: false,
          posterior_narrow_upper: false,
          posterior_expand_lower: false,
          posterior_narrow_lower: false,
        },
      },
    };

    this.clearSpacingValue.bind(this);
    this.makeCheckbox.bind(this);
    this.makeRadio.bind(this);
    this.makeCheckBar.bind(this);
    this.setPreset.bind(this);
    this.makeAuxNotes.bind(this);
    this.checkPreset.bind(this);
    this.getConditionState.bind(this);
    this.setConditionState.bind(this);
    this.getImpliedTeethSets.bind(this);
    this.handleTeethClick.bind(this);
    this.onChangeSpacingValue.bind(this);
    this.onChangeSpacingOnBlur.bind(this);

    Object.entries(this.state.conditions).forEach((x) => this['make_' + x[0]].bind(this));
  }

  componentDidMount() {
    let that = this;
    ApiServiceInstance.fetchCaseDetails(this.caseID).then((result) => {
      let thisCase = _.find(result.data.cases, (x) => x.case_id === that.caseID);
      let files = thisCase.submission_process.files;

      let flist = files.map((file, index) => {
        let id = generateId(file.upload_data + that.caseID);
        let fdata = {};

        if (file.file_type === 'upperscans') {
          fdata = {
            static: '/static/img/upper.png',
            description: null,
            blob: null,
          };
        } else if (file.file_type === 'lowerscans') {
          fdata = {
            static: '/static/img/lower.png',
            description: null,
            blob: null,
          };
        } else if (file.file_type === 'scans') {
          fdata = {
            static: '/static/img/model.png',
            description: file.original_filename,
            blob: null,
          };
        } else {
          fdata = {
            static: '/static/img/no-image.png',
            description: null,
            blob: file.upload_data,
          };
        }
        fdata = Object.assign(fdata, {
          id: id,
          new: false,
        });
        return fdata;
      });
      that.setState({ thumbs: flist });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.conditions.midlines.active) {
      const that = this;

      if (
        this.state.conditions.midlines.lower_mid_improve !== 'maintain' &&
        this.state.conditions.midlines.lower_mid_improve !== prevState.conditions.midlines.lower_mid_improve
      ) {
        if (this.refs['midlines-lower_mid_improve-left'] && this.state.conditions.midlines.lower_mid_improve === 'left') {
          setTimeout(function () {
            that.refs['midlines-lower_mid_improve-left'].focus();
          }, 100);
        } else if (this.refs['midlines-lower_mid_improve-right'] && this.state.conditions.midlines.lower_mid_improve === 'right') {
          setTimeout(function () {
            that.refs['midlines-lower_mid_improve-right'].focus();
          }, 100);
        }
      } else if (
        this.state.conditions.midlines.upper_mid_improve !== 'maintain' &&
        this.state.conditions.midlines.upper_mid_improve !== prevState.conditions.midlines.upper_mid_improve
      ) {
        if (this.refs['midlines-upper_mid_improve-left'] && this.state.conditions.midlines.upper_mid_improve === 'left') {
          setTimeout(function () {
            that.refs['midlines-upper_mid_improve-left'].focus();
          }, 100);
        } else if (this.refs['midlines-upper_mid_improve-right'] && this.state.conditions.midlines.upper_mid_improve === 'right') {
          setTimeout(function () {
            that.refs['midlines-upper_mid_improve-right'].focus();
          }, 100);
        }
      }
    }
  }

  onChangeSpacingValue(index, event, mode) {
    let spacingUpper = this.state.conditions.spacing.spacingUpper.slice();
    let spacingLower = this.state.conditions.spacing.spacingLower.slice();

    if (mode === 'upper') {
      spacingUpper[index] = this.formatMMEntry('spacing', 'spacingUpper', event.target.value, index);
    } else {
      spacingLower[index] = this.formatMMEntry('spacing', 'spacingLower', event.target.value, index);
    }

    let conditions = { ...this.state.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.setState({
      conditions: conditions,
    });
  }

  onChangeSpacingOnBlur(index, event, mode) {
    let spacingUpper = this.state.conditions.spacing.spacingUpper;
    let spacingLower = this.state.conditions.spacing.spacingLower;

    if (mode === 'upper') {
      spacingUpper[index] = this.formatMMFromBlur(event.target.value);
    } else {
      spacingLower[index] = this.formatMMFromBlur(event.target.value);
    }

    let conditions = { ...this.state.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.setState({
      conditions: conditions,
    });
  }

  clearSpacingValue(index, mode) {
    let spacingUpper = this.state.conditions.spacing.spacingUpper;
    let spacingLower = this.state.conditions.spacing.spacingLower;

    if (mode === 'upper') {
      spacingUpper[index] = '';
    } else {
      spacingLower[index] = '';
    }

    let conditions = { ...this.state.conditions };
    conditions.spacing.spacingUpper = spacingUpper;
    conditions.spacing.spacingLower = spacingLower;

    this.setState({
      conditions: conditions,
    });
  }

  static improveBarNames = {
    primary: 'Yes',
    as_needed: 'If Needed',
    none: 'None',
  };

  static makePreset = (n) => {
    var teeth = [];
    _.range(1, n + 1).forEach((x) => {
      teeth.push('UL' + x, 'UR' + x, 'LL' + x, 'LR' + x);
    });
    return teeth;
  };
  static makePresets = (first, last) => {
    var presets = {};
    _.range(first, last + 1).forEach((x) => {
      presets[x] = DxTx.makePreset(x);
    });
    return presets;
  };

  makeCheckBar = (cond, key) => {
    let val = this.getConditionState(cond)[key];
    let onClick = (val) => {
      let res = {};
      res[key] = val;
      this.setConditionState(cond, res);
    };
    return <CheckBar value={val} buttons={DxTx.improveBarNames} onClick={onClick} />;
  };

  handleTeethClick = (teethSet) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);
      this.setState(ns);
    };
    return func;
  };
  setPreset = (n) => {
    this.setState({ bracketTeeth: this.presets[n] });
  };

  getConditionState = (cnd) => {
    return { ...this.state.conditions[cnd] };
  };
  setConditionState = (cnd, newState) => {
    let conds = { ...this.state.conditions };
    let oldState = this.getConditionState(cnd);

    Object.assign(oldState, newState);

    conds[cnd] = oldState;
    this.setState({ conditions: conds });
  };

  makeCheckbox = (name, group, { cond = null, disabled = false, onCheck = null }) => {
    let cID = cond ? cond + '-' + group : group;
    let state = cond ? this.getConditionState(cond) : this.state;
    let isChecked = state[group] === true;
    let updateF = (group) => {
      let func = (evt) => {
        let ns = {};
        if (onCheck) {
          ns = onCheck(evt.target.checked);
        }
        ns[group] = evt.target.checked;
        if (cond) {
          this.setConditionState(cond, ns);
        } else {
          this.setState(ns);
        }
      };
      return func;
    };

    return (
      <div className="ib-checkbox">
        <label className={'form-check-label ' + (disabled ? 'disabled' : '')}>
          <input
            className={'form-check-input ' + (disabled ? 'disabled' : '')}
            type="checkbox"
            id={cID}
            checked={isChecked && !disabled}
            onChange={updateF(group)}
            disabled={disabled}
          />{' '}
          {name}
        </label>
      </div>
    );
  };

  makeWarning = (condition, warning) => {
    if (condition && warning) {
      return <div className="ib-warning">{warning}</div>;
    }
  };

  formatMMEntry = (cond = null, group = null, value, index = null) => {
    // Value should be X.X
    const prevCond = index !== null && index >= 0 ? this.state.conditions[cond][group][index] : this.state.conditions[cond][group];
    let formattedValue = value
      .replace(/[^0-9.]/g, '')
      .replace('..', '.')
      .replace('00', '0');

    //Enforce .
    if (formattedValue.length === 1 && prevCond === '' && formattedValue !== '.') {
      formattedValue += '.';
    } else if (formattedValue.length === 1 && prevCond === '' && formattedValue === '.') {
      formattedValue = '0.';
    } else if (value.length > 2 && prevCond === '0') {
      formattedValue = value[0] + '.' + value[1];
    } else if (formattedValue.length === 1 && prevCond === '.') {
      formattedValue = '';
    }

    //if . is detected than only allow one digit after
    const decimalPos = formattedValue.indexOf('.');
    if (decimalPos >= 0 && formattedValue.length >= decimalPos + 2) {
      formattedValue = formattedValue.slice(0, decimalPos + 2);
    }

    //trim off leading 0 conditions
    if (decimalPos !== 1 && value[0] === '0') {
      formattedValue = formattedValue.slice(1);
    }

    //max character limit
    if (value.length > 4) {
      formattedValue = prevCond;
    }

    return formattedValue;
  };

  formatMMFromBlur = (value) => {
    let formattedValue = value;

    if (formattedValue.length < 3) {
      if (value.indexOf('.') === value.length - 1 && value !== '') {
        formattedValue = formattedValue + '0';
      }
    }

    return formattedValue;
  };

  makeRadio = (name, group, value, { cond = null, textValue = null, textUnits = 'mm', infoEl = null, disabled = false, forceCheck = false } = {}) => {
    let groupText = cond ? cond + '-' + group : group;
    let cID = groupText + '-' + value;

    let state = cond ? this.getConditionState(cond) : this.state;
    let isChecked = (state[group] === value || forceCheck) && !disabled;

    let updateF = (group) => {
      let func = (evt) => {
        let ns = {};

        if (group.indexOf('_by') >= 0) {
          ns[group] = this.formatMMEntry(cond, group, evt.target.value);
        } else {
          ns[group] = evt.target.value;
        }

        if (group === 'improve') {
          ns['improve_ipr'] = false;
          ns['procline'] = false;
        }

        if (cond) {
          this.setConditionState(cond, ns);
        } else {
          this.setState(ns);
        }
      };
      return func;
    };

    let updateFBlur = (group) => {
      let func = (evt) => {
        let ns = {};

        if (group.indexOf('_by') >= 0) {
          ns[group] = this.formatMMFromBlur(evt.target.value);
        } else {
          ns[group] = evt.target.value;
        }

        if (cond) {
          this.setConditionState(cond, ns);
        } else {
          this.setState(ns);
        }
      };

      return func;
    };

    let clickRadio = (event) => {
      const id = event.currentTarget.dataset.id;
      const disabled = event.currentTarget.dataset.disabled;
      const radio = document.getElementById(id);

      if (radio && disabled === 'true') {
        radio.click();
      }
    };

    return (
      <div className="ib-radio">
        <label className={'form-check-label ' + (disabled ? 'disabled' : '')}>
          <input
            className="form-check-input"
            type="radio"
            name={groupText}
            id={cID}
            value={value}
            checked={isChecked && !disabled}
            onChange={updateF(group)}
            disabled={disabled}
          />{' '}
          {name} {infoEl}
        </label>{' '}
        {textValue ? (
          <span data-id={cID} data-disabled={!isChecked} onClick={clickRadio}>
            <input
              type="text"
              className="form-control inline-block mm-size"
              disabled={!isChecked}
              placeholder={isChecked ? '0.0' : ''}
              value={isChecked ? state[textValue] : ''}
              onChange={updateF(textValue)}
              autoFocus={true}
              ref={cID}
              onBlur={updateFBlur(textValue)}
            />
          </span>
        ) : null}
        {textValue && textUnits ? <span>mm</span> : null}
      </div>
    );
  };

  checkPreset = (n, teeth, tset) => {
    let diff = _.xor(this.presets[n], teeth);
    diff = _.difference(diff, tset.bracketDisabled);
    return diff.length === 0;
  };

  getImpliedTeethSets = () => {
    let opposingTeeth = [];
    let hasUpper = this.state.archToTreat !== 'lower';
    let hasLower = this.state.archToTreat !== 'upper';
    let setupIdeal = this.state.archToTreat !== 'both' && this.state.opposingArch === 'ideal'; //&& this.state.opposingArch == "ideal";
    let setupCurrent = this.state.archToTreat !== 'both' && this.state.opposingArch === 'current'; //this.state.opposingArch == "current";
    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }
    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    let missingTeeth = this.state.missingTeeth;

    let extractTeeth = _.difference(this.state.extractTeeth, missingTeeth);
    let extractDisabled = missingTeeth;
    // if(setupCurrent)
    //   extractTeeth = _.difference(extractTeeth, opposingTeeth);
    let extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    let bracketTeeth = _.difference(this.state.bracketTeeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.restrictTeeth, restrictImplied);
    if (setupCurrent) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    let tset = {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };

    return tset;
  };

  makeAuxNotes = (test, condition, state_key) => {
    const placeholder = 'Please describe auxiliaries to be used for treatment...';
    let onChange = (evt) => {
      let ns = {};
      ns[state_key] = evt.target.value;
      this.setConditionState(condition, ns);
    };
    let state = this.getConditionState(condition);
    if (!test) return null;
    return (
      <div className="form-group">
        <textarea className="form-control" rows="2" value={state[state_key]} onChange={onChange} placeholder={placeholder} />
      </div>
    );
  };

  makeNotesBox = (title, nrows, state_key) => {
    let onChange = (evt) => {
      let ns = {};
      ns[state_key] = evt.target.value;
      this.setState(ns);
    };
    return (
      <QBox title={title}>
        <div className="form-group">
          <textarea className="form-control" rows={nrows} value={this.state[state_key]} onChange={onChange} />
        </div>
      </QBox>
    );
  };

  render() {
    let tset = this.getImpliedTeethSets();

    let presetChoice = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, this.state.bracketTeeth, tset);
    });
    let presetMax = _.range(this.minPreset, this.maxPreset + 1).find((n) => {
      return this.checkPreset(n, Teeth.All, tset);
    });
    presetMax = presetMax ? presetMax : this.maxPreset + 1;

    let makePresetButton = (n) => {
      return (
        <button
          key={n}
          type="button"
          onClick={() => this.setPreset(n)}
          className={'btn-ib ' + (n === presetChoice ? 'active ' : '') + (n > presetMax ? 'disabled' : '')}
        >
          {n}x{n}
        </button>
      );
    };

    let makeNotesBox = this.makeNotesBox;

    let anyConditions = _.some(this.state.conditions, 'active');
    // eslint-disable-next-line
    let opposingArchName = tset.hasUpper ? 'lower' : 'upper';
    return (
      <div className="portal-view-wide-2">
        <Helmet>
          <title>DXTX Prototype | InBrace Smile Design Studio™</title>
        </Helmet>

        <div className="return_link return_link--default">
          {this.state.hide ? null : (
            <div onClick={this.onIncompleteSave} data-redirect="true">
              <i className="fa fa-long-arrow-left" aria-hidden="true" /> Save and Return to Case List
            </div>
          )}
        </div>
        <div className="page__heading">
          <div className="page__name page__name--default"> Submit a Case</div>
        </div>

        <div className="submit__content row">
          <div className="breadcrumb">
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="Patient Information">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Patient Info</span>
              <span className="show-mobile">Patient Info</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="Photos">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Photos</span>
              <span className="show-mobile">Photos</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="X-Rays">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Radiographs</span>
              <span className="show-mobile">Radiographs</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="Impressions">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Scans</span>
              <span className="show-mobile">Scans</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive active" data-description="Dx/Tx Plan">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Treatment Plan</span>
              <span className="show-mobile">Treatment Plan</span>
              <div id="bottom-border" />
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="Doctor Information">
              <span className="arrow" />
              <span className="arrow" />
              <span className="hide-mobile">Shipping Info</span>
              <span className="show-mobile">Shipping Info</span>
            </a>
            {/* eslint-disable-next-line */}
            <a className="item inactive " data-description="Submit">
              <span className="hide-mobile">Submit</span>
              <span className="show-mobile">Submit</span>
            </a>
          </div>

          <div className="submit__window">
            <div className="portal-view">
              <div className="col-md-9">
                <h3>Patient Chief Complaint</h3>
                {makeNotesBox("Please describe the patient's chief complaint", 2, 'chief_complaint')}

                <h3>Extent of Treatment</h3>

                <QBox title="Missing/Previously Extracted Teeth">
                  <TeethSelector selected={tset.missingTeeth} onClick={this.handleTeethClick('missingTeeth')} hasUpper={true} hasLower={true} />
                </QBox>

                <QBox title="Teeth to be Extracted" anchor="to_be_extracted">
                  <TeethSelector
                    selected={tset.extractTeeth}
                    onClick={this.handleTeethClick('extractTeeth')}
                    disabled={tset.extractDisabled}
                    hasUpper={true || tset.hasUpper || tset.setupIdeal}
                    hasLower={true || tset.hasLower || tset.setupIdeal}
                  />
                  {this.makeWarning(
                    tset.extractTeeth.length > 0,
                    'InBrace is best suited to close spaces up to 3.5mm. If the spaces are greater than 3.5mm, an InBrace representative will contact you.'
                  )}
                </QBox>

                <QBox title="Arch to treat with InBrace">
                  {this.makeRadio('Both arches', 'archToTreat', 'both')}
                  {this.makeRadio('Upper arch', 'archToTreat', 'upper')}
                  {this.state.archToTreat === 'upper' ? (
                    <span className="opposing-arch">
                      {this.makeRadio('Lower arch will be untreated', 'opposingArch', 'current')}{' '}
                      {this.makeRadio('Lower arch will be treated with an alternate system', 'opposingArch', 'ideal')}
                    </span>
                  ) : null}

                  {this.makeRadio('Lower arch', 'archToTreat', 'lower')}
                  {this.state.archToTreat === 'lower' ? (
                    <span className="opposing-arch">
                      {this.makeRadio('Upper arch will be untreated', 'opposingArch', 'current')}{' '}
                      {this.makeRadio('Upper arch will be treated with an alternate system', 'opposingArch', 'ideal')}
                    </span>
                  ) : null}
                  {
                    // this.makeWarning(tset.setupIdeal, ['The doctor will provide all the appliances for treating the ' + opposingArchName + ' arch.'])
                  }
                </QBox>

                <QBox title="Bracket Placement">
                  Presets:{' '}
                  <div className="btn-bar" role="toolbar" id="bracket-presets">
                    {_.range(this.minPreset, this.maxPreset + 1)
                      .reverse()
                      .map((n) => makePresetButton(n))}
                  </div>
                  <TeethSelector
                    selected={tset.bracketTeeth}
                    onClick={this.handleTeethClick('bracketTeeth')}
                    disabled={tset.bracketDisabled}
                    hasUpper={tset.hasUpper}
                    hasLower={tset.hasLower}
                  />
                </QBox>

                <QBox title="Do not move the following teeth">
                  <TeethSelector
                    selected={tset.restrictTeeth}
                    onClick={this.handleTeethClick('restrictTeeth')}
                    disabled={tset.restrictImplied}
                    hasUpper={tset.hasUpper || tset.setupIdeal}
                    hasLower={tset.hasLower || tset.setupIdeal}
                  />
                  {/*(tset.restrictTeeth.length-tset.restrictImplied.length) > 0?(
                  <div className="form-group" id="restrict-reason">
                    <label htmlFor="restrict-reason-text">Specify reasons for tooth movement restrictions:</label>
                      <textarea className="form-control" rows="4" id="restrict-reason-text"
                        value={this.state.restrictReason}
                        onChange={(evt) => this.setState({restrictReason: evt.target.value})} />
                  </div>
                ):null*/}
                </QBox>

                <h3>Clinical Conditions</h3>
                <QBox title="Select conditions to treat">
                  <div id="clinical-conditions">
                    {Object.entries(this.state.conditions).map(([key, state]) => {
                      return (
                        <button
                          key={key}
                          type="button"
                          className={'btn-ib ' + (state.active ? 'active' : '')}
                          onClick={() => this.setConditionState(key, { active: !state.active })}
                        >
                          {state.title}
                        </button>
                      );
                    })}
                  </div>
                </QBox>

                {anyConditions ? <h3>Treatment</h3> : null}
                {Object.entries(this.state.conditions).map(([key, state]) => {
                  if (state.active) {
                    let setInfo = (infoEl) => {
                      this.setConditionState(key, { infoEl: infoEl });
                    };
                    if (state.info_title) setInfo = null;
                    return (
                      <QBox key={key} title={state.title} title_alt={state.title_alt} info={state.info} setInfo={setInfo}>
                        {this['make_' + key](state, key, tset)}
                      </QBox>
                    );
                  } else {
                    return null;
                  }
                })}
                {
                  // <h3>Auxiliary Appliances</h3>
                  // {makeNotesBox("Describe auxiliary appliances",4,"notes_aux")}
                }

                <h3>Special Instructions</h3>
                {makeNotesBox('Describe any special instructions or conditions', 4, 'notes')}
              </div>
              <div className="col-md-3">
                <ToothViewer teethSets={tset} thumbs={this.state.thumbs} />
              </div>
            </div>
            <div>
              <div className="submit__back" data-description="Dx/Tx Plan" data-position="prev">
                <i className="fa fa-chevron-left" aria-hidden="true" />
              </div>
              <div className="submit__next" data-description="Dx/Tx Plan" data-position="next">
                <i className="fa fa-chevron-right" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  make_ap_relationship = (state, condition, tset) => {
    let usesAux = state['ap_improvement'] === 'auxiliaries';
    return (
      <div>
        {this.makeRadio('Maintain current AP relationship', 'ap_improvement', 'maintain', { cond: condition })}
        {this.makeRadio(
          'Improve with InBrace and light force elastics up to 1.5mm for Class II or 1mm for Class III discrepancies',
          'ap_improvement',
          'inbrace',
          { cond: condition }
        )}
        {this.makeRadio('Fully improve with auxiliaries', 'ap_improvement', 'auxiliaries', { cond: condition })}
        {this.makeAuxNotes(usesAux, condition, 'aux_note')}
        {this.makeWarning(state['ap_improvement'] === 'inbrace', ['Light force elastics to be provided by the treating doctor.'])}
        {this.makeWarning(usesAux, ['Digital setup will depict full improvement. Auxiliaries or light force elastics to be provided by the treating doctor.'])}
      </div>
    );
  };
  make_midlines = (state, condition, tset) => {
    let disableUpper = _.intersection(tset.restrictTeeth, ['UL1', 'UR1']).length > 0;
    let disableLower = _.intersection(tset.restrictTeeth, ['LL1', 'LR1']).length > 0;
    let disableWarning = '';

    if (disableUpper && disableLower) disableWarning = 'Upper and lower midlines';
    else if (disableUpper) disableWarning = 'Upper midline';
    else if (disableLower) disableWarning = 'Lower midline';
    if (disableUpper || disableLower) disableWarning += ' must be maintained due to movement restrictions.';
    return (
      <div>
        {tset.hasUpper || tset.setupIdeal ? (
          <div className="row">
            <div className="col-md-12">
              <h4>Upper:</h4>
            </div>
            <div className="col-md-6">{this.makeRadio('Maintain midline', 'upper_mid_improve', 'maintain', { cond: condition, forceCheck: disableUpper })}</div>
            <div className="col-md-6">
              {this.makeRadio('Match to lower midline', 'upper_mid_improve', 'match', { cond: condition, forceCheck: disableUpper })}
            </div>
            <div className="col-md-6">
              {this.makeRadio("Move to the patient's right", 'upper_mid_improve', 'right', {
                cond: condition,
                textValue: 'upper_mid_improve_right_by',
                disabled: disableUpper,
              })}
            </div>
            <div className="col-md-6">
              {this.makeRadio("Move to the patient's left", 'upper_mid_improve', 'left', {
                cond: condition,
                textValue: 'upper_mid_improve_left_by',
                disabled: disableUpper,
              })}
            </div>
          </div>
        ) : null}

        {tset.hasLower || tset.setupIdeal ? (
          <div className="row">
            <div className="col-md-12">
              <h4>Lower:</h4>
            </div>
            <div className="col-md-6">{this.makeRadio('Maintain midline', 'lower_mid_improve', 'maintain', { cond: condition, forceCheck: disableLower })}</div>
            <div className="col-md-6">
              {this.makeRadio('Match to upper midline', 'lower_mid_improve', 'match', { cond: condition, forceCheck: disableLower })}
            </div>
            <div className="col-md-6">
              {this.makeRadio("Move to the patient's right", 'lower_mid_improve', 'right', {
                cond: condition,
                textValue: 'lower_mid_improve_right_by',
                disabled: disableLower,
              })}
            </div>
            <div className="col-md-6">
              {this.makeRadio("Move to the patient's left", 'lower_mid_improve', 'left', {
                cond: condition,
                textValue: 'lower_mid_improve_left_by',
                disabled: disableLower,
              })}
            </div>
          </div>
        ) : null}
        <div>{this.makeWarning(disableUpper || disableLower, disableWarning)}</div>
      </div>
    );
  };
  make_crowding = (state, condition, tset) => {
    let kvs = {
      expand: 'Expand',
      procline: 'Procline',
      ipr_ant: 'IPR Anterior',
      ipr_right: 'IPR Posterior Right',
      ipr_left: 'IPR Posterior Left',
    };
    let makeCB = (key, prefix) => {
      let title = kvs[key];
      return (
        <div key={key}>
          <div className="col-md-4 label-right">{title}</div>
          <div className="col-md-8">{this.makeCheckBar(condition, prefix + '_' + key)}</div>
        </div>
      );
    };

    return (
      <div id="improve-crowding" className="row">
        {tset.hasUpper || tset.setupIdeal ? (
          <div>
            <div className="col-md-12">
              <h4>Resolve Upper Crowding up to 6mm:</h4>
            </div>
            {Object.keys(kvs).map((key) => makeCB(key, 'upper'))}
          </div>
        ) : null}

        {tset.hasLower || tset.setupIdeal ? (
          <div>
            <div className="col-md-12">
              <h4>Resolve Lower Crowding up to 6mm:</h4>
            </div>
            {Object.keys(kvs).map((key) => makeCB(key, 'lower'))}
          </div>
        ) : null}
        <div>
          <div className="col-md-12">
            {this.makeWarning(
              true,
              <div>
                If extractions are required to resolve crowding, please specify in the <a href="#to_be_extracted">Teeth to be Extracted</a> section above.
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  make_spacing = (state, condition, tset) => {
    let upperTeeth = Teeth.UR.concat(Teeth.UL);
    let lowerTeeth = Teeth.LR.concat(Teeth.LL);
    let showChart = state['spaces'] === 'leave';
    let spaceTeeth = new Set(state.spaceTeeth);

    let onSpaceClick = (evt, tn) => {
      let sel = new Set(state.spaceTeeth);
      if (evt.target.checked) {
        sel.add(tn);
      } else {
        sel.delete(tn);
      }
      this.setConditionState(condition, { spaceTeeth: Array.from(sel) });
    };

    // eslint-disable-next-line
    let makeTeethChart = (teeth) => {
      let teeth2 = _.difference(teeth, tset.extractTeethAll);

      return (
        <div className="spacing-chart-line">
          {teeth2.slice(0).map((tn, idx) => {
            let isLast = idx === teeth2.length - 1;
            return (
              <React.Fragment key={tn}>
                <div className="spacing-chart-tooth">{tn}</div>{' '}
                {isLast ? null : <input type="checkbox" checked={spaceTeeth.has(tn)} onClick={(evt) => onSpaceClick(evt, tn)} />}{' '}
              </React.Fragment>
            );
          })}
        </div>
      );
    };

    let hasMissingTeeth = (tn) => {
      let teeth_match = _.intersection(tset.extractTeethAll, [tn]);
      return teeth_match.length > 0;
    };

    let getTeethPosition = (tn, teeth) => {
      let position = 0;

      for (let i = 0; i < teeth.length; i++) {
        if (tn === teeth[i]) {
          position = i;
          break;
        }
      }

      return position;
    };

    let getArrayOfTeeth = (teeth) => {
      let array = [];

      for (let i = 0; i < teeth.length; i++) {
        if (hasMissingTeeth(teeth[i])) {
          array.push(0);
        } else {
          array.push(1);
        }
      }

      return array;
    };

    let hasMissingTeethToEnd = (tn) => {
      let upperTeeth = Teeth.UR.concat(Teeth.UL);
      let lowerTeeth = Teeth.LR.concat(Teeth.LL);
      let upperTeethArray = getArrayOfTeeth(upperTeeth);
      let lowerTeethArray = getArrayOfTeeth(lowerTeeth);
      let position = 0;
      let teethArray = [];

      if (tn.includes('U')) {
        position = getTeethPosition(tn, upperTeeth) + 1;
        teethArray = upperTeethArray.slice(position);
      } else {
        position = getTeethPosition(tn, lowerTeeth) + 1;
        teethArray = lowerTeethArray.slice(position);
      }

      return _.sum(teethArray) === 0;
    };

    let hasBubbleBox = (tn) => {
      // bubble index starts at UR8
      let upperTeeth = Teeth.UR.concat(Teeth.UL);
      let lowerTeeth = Teeth.LR.concat(Teeth.LL);
      let upperTeethArray = getArrayOfTeeth(upperTeeth);
      let lowerTeethArray = getArrayOfTeeth(lowerTeeth);
      let position = 0;
      let teethArray = [];

      if (tn.includes('U')) {
        position = getTeethPosition(tn, upperTeeth);
        teethArray = upperTeethArray.slice(position);
      } else {
        position = getTeethPosition(tn, lowerTeeth);
        teethArray = lowerTeethArray.slice(position);
      }

      return _.sum([teethArray[0], teethArray[1]]) === 2 || (teethArray[0] && !hasMissingTeethToEnd(tn));
    };

    //Creates the chart
    let makeSpacingChart = (teeth) => {
      let teeth2 = _.difference(teeth, tset.extractTeethAll);
      let isUpperOnly = tset.hasUpper && !tset.hasLower;
      let isLowerOnly = !tset.hasUpper && tset.hasLower;

      return (
        <div className="spacing-center">
          {teeth[0].includes('U') ? (
            <table className={isUpperOnly ? 'spacing-chart-input-U spacing-chart-input-padding' : 'spacing-chart-input-U'}>
              <tbody>
                <tr>
                  {teeth.map((tn, idx) => {
                    return (
                      <td key={idx}>
                        {idx !== teeth.length - 1 && (tn.includes('UR') || tn.includes('UL')) && hasBubbleBox(tn) && teeth2.length > 1 ? (
                          <div>
                            {this.state.conditions.spacing.spacingUpper[idx] !== '' ? (
                              <span className="spacing-chart-x-upper" onClick={() => this.clearSpacingValue(idx, 'upper')}>
                                x
                              </span>
                            ) : null}
                            <input
                              className={
                                this.state.conditions.spacing.spacingUpper[idx] !== ''
                                  ? 'spacing-chart-input-upper spacing-chart-input-upper-lrg'
                                  : 'spacing-chart-input-upper'
                              }
                              onChange={(e) => {
                                this.onChangeSpacingValue(idx, e, 'upper');
                              }}
                              value={this.state.conditions.spacing.spacingUpper[idx]}
                              onBlur={(e) => {
                                this.onChangeSpacingOnBlur(idx, e, 'upper');
                              }}
                              placeholder="0.0"
                            />
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
          <table className="spacing-chart">
            <tbody>
              <tr>
                {teeth.map((tn, idx) => {
                  return (
                    <td
                      key={idx}
                      className={
                        tn.includes('U')
                          ? hasBubbleBox(tn)
                            ? 'spacing-chart-upper-label'
                            : 'spacing-chart-upper-label spacing-chart-upper-label-hidden'
                          : hasBubbleBox(tn)
                          ? isLowerOnly
                            ? 'spacing-chart-lower-label spacing-chart-lower-label-border'
                            : 'spacing-chart-lower-label'
                          : isLowerOnly
                          ? 'spacing-chart-lower-label spacing-chart-lower-label-hidden spacing-chart-lower-label-border'
                          : 'spacing-chart-lower-label spacing-chart-lower-label-hidden'
                      }
                    >
                      {!hasMissingTeeth(tn) ? tn : null}
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
          {teeth[0].includes('LR') || teeth[0].includes('LL') ? (
            <table className="spacing-chart-input-L">
              <tbody>
                <tr>
                  {teeth.map((tn, idx) => {
                    return (
                      <td key={idx}>
                        {' '}
                        {idx !== teeth.length - 1 && (tn.includes('LR') || tn.includes('LL')) && hasBubbleBox(tn) && teeth2.length > 1 ? (
                          <div>
                            <input
                              className={
                                this.state.conditions.spacing.spacingLower[idx] !== ''
                                  ? 'spacing-chart-input-lower spacing-chart-input-lower-lrg'
                                  : 'spacing-chart-input-lower'
                              }
                              onChange={(e) => {
                                this.onChangeSpacingValue(idx, e, 'lower');
                              }}
                              value={this.state.conditions.spacing.spacingLower[idx]}
                              onBlur={(e) => {
                                this.onChangeSpacingOnBlur(idx, e, 'lower');
                              }}
                              placeholder="0.0"
                            />
                            {this.state.conditions.spacing.spacingLower[idx] !== '' ? (
                              <span className="spacing-chart-x-lower" onClick={() => this.clearSpacingValue(idx, 'lower')}>
                                x
                              </span>
                            ) : null}
                          </div>
                        ) : null}
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
      );
    };

    return (
      <div id="spacing-box">
        {this.makeRadio('Close all spaces', 'spaces', 'close', { cond: condition })}
        {this.makeRadio('Leave spaces (mm)', 'spaces', 'leave', { cond: condition })}

        <div
          className={
            this.state.conditions && this.state.conditions.spacing && this.state.conditions.spacing.spaces === 'leave'
              ? tset.hasUpper && tset.hasLower
                ? 'spacing-chart-zone'
                : tset.hasUpper
                ? 'spacing-chart-zone upper-only'
                : tset.hasLower
                ? 'spacing-chart-zone lower-only'
                : null
              : null
          }
        >
          {showChart && (tset.hasUpper || tset.setupIdeal) ? makeSpacingChart(upperTeeth) : null}
          {showChart && (tset.hasLower || tset.setupIdeal) ? makeSpacingChart(lowerTeeth) : null}
          {this.state.conditions && this.state.conditions.spacing && this.state.conditions.spacing.spaces === 'leave'
            ? this.makeNotesBox('Additional Spacing Instructions', 4, 'notes_spacing')
            : null}
        </div>
      </div>
    );
  };
  make_overjet = (state, condition, tset) => {
    let opts = (
      <React.Fragment>
        {this.makeCheckbox('Improve with IPR', 'improve_ipr', { cond: condition })}
        {tset.hasLower || tset.setupIdeal ? this.makeCheckbox('Procline lower anteriors', 'procline', { cond: condition }) : null}
      </React.Fragment>
    );
    let usesAux = state['improve'] === 'auxiliaries';

    return (
      <div className="overjet">
        {this.makeRadio('Improve with InBrace only', 'improve', 'inbrace', { cond: condition })}
        {state.improve === 'inbrace' ? opts : null}
        {this.makeRadio('Fully improve with light force elastics or auxiliaries', 'improve', 'auxiliaries', { cond: condition })}
        {state.improve === 'auxiliaries' ? opts : null}
        {this.makeAuxNotes(usesAux, condition, 'aux_note')}
        {this.makeWarning(usesAux, 'Digital setup will depict full improvement. Auxiliaries or light force elastics to be provided by the treating doctor.')}
      </div>
    );
  };
  make_deepbite = (state, condition, tset) => {
    let usesAux = state['correct'] === 'auxiliaries';
    return (
      <div>
        {this.makeRadio('Improve with InBrace up to 6mm', 'correct', 'inbrace', { cond: condition })}
        {this.makeRadio('Fully improve with light force elastics or auxiliaries', 'correct', 'auxiliaries', { cond: condition })}
        {this.makeAuxNotes(usesAux, condition, 'aux_note')}
        {this.makeWarning(usesAux, 'Digital setup will depict full improvement. Auxiliaries or light force elastics to be provided by the treating doctor.')}
      </div>
    );
  };
  make_openbite = (state, condition, tset) => {
    return (
      <div>
        {this.makeRadio('Maintain openbite', 'improve_amt', 'maintain', { cond: condition })}
        {this.makeRadio('Improve with InBrace up to 3mm with light force elastics', 'improve_amt', 'elastics', { cond: condition })}
        {this.makeWarning(state['improve_amt'] === 'elastics', 'Light force elastics to be provided by the treating doctor.')}
      </div>
    );
  };
  make_crossbite = (state, condition, tset) => {
    let upperQ = tset.hasUpper || tset.setupIdeal;
    let lowerQ = tset.hasLower || tset.setupIdeal;
    let archClass = upperQ && lowerQ ? 'col-md-6' : 'col-md-12';
    let checkF = (key) => {
      return (isChecked) => {
        let ns = {};
        if (isChecked) ns[key] = false;
        return ns;
      };
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <h4>Improve Anterior Crossbite:</h4>
        </div>
        <div className="col-md-6">{upperQ ? this.makeCheckbox('Advance upper', 'anterior_advance_upper', { cond: condition }) : null}</div>
        <div className="col-md-6">{lowerQ ? this.makeCheckbox('Retract lower', 'anterior_retract_lower', { cond: condition }) : null}</div>

        <div className="col-md-12">
          <h4>Improve Posterior Crossbite:</h4>
        </div>
        {upperQ ? (
          <div className={archClass}>
            {this.makeCheckbox('Expand upper', 'posterior_expand_upper', { cond: condition, onCheck: checkF('posterior_narrow_upper') })}
          </div>
        ) : null}

        {upperQ ? (
          <div className={archClass}>
            {this.makeCheckbox('Constrict upper', 'posterior_narrow_upper', { cond: condition, onCheck: checkF('posterior_expand_upper') })}
          </div>
        ) : null}

        {lowerQ ? (
          <div className={archClass}>
            {this.makeCheckbox('Expand lower', 'posterior_expand_lower', { cond: condition, onCheck: checkF('posterior_narrow_lower') })}
          </div>
        ) : null}

        {lowerQ ? (
          <div className={archClass}>
            {this.makeCheckbox('Constrict lower', 'posterior_narrow_lower', { cond: condition, onCheck: checkF('posterior_expand_lower') })}
          </div>
        ) : null}

        <div className="col-md-12">
          {this.makeWarning(
            true,
            'It is recommended to use auxiliaries to treat crossbite. Archform can be minimally modified to address posterior crossbite with InBrace alone.'
          )}
        </div>
      </div>
    );
  };
}
export default withRouter(DxTx);
