import React from 'react';
import PropTypes from 'prop-types';

const themes = ['primary', 'secondary', 'revise', 'dark', 'dark2'];

/**
 * Displays a basic button
 * @component
 */
function Button({ theme, className, ...props }) {
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} btn btn--${themeClassName}`;
  return <button className={classNames} {...props} />;
}

Button.propTypes = {
  theme: PropTypes.oneOf(themes),
};

Button.defaultProps = {
  theme: themes[0],
};

export default Button;
