import React, { Component } from 'react';
import AccountSettings from './content_detail';
import IteroSettings from './itero_settings';
import ProviderLocatorInfo from './provider_locator_info';
import { withRouter } from 'react-router-dom';
import { getDoctorIdFromPath } from '../../common/functions';
import Error from '../404/not_found';
import Axios from 'axios';
import CardContainer from '../components/container/card_container';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidMount() {
    let that = this;
    this.setPage();
    Axios.get('/apiv3/doctor').then(function (response) {
      if (response && response.data && response.data.doctor && response.data.doctor.length > 0) {
        let doctorId = response.data.doctor[0].id;
        let pathDoctorId = getDoctorIdFromPath(that.props.history.location.pathname);

        if (doctorId.toString() !== pathDoctorId && response.data.doctor[0].role === 'Doctor') {
          that.setState({
            error: true,
            page: 'settings',
          });
        }
      }
    });
  }

  componentDidUpdate() {
    this.setPage();
  }

  setPage() {
    let path = this.props.history.location.pathname;
    const page = path.split('/').pop();
    if (page !== this.state.page) {
      this.setState({
        page: page,
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <Error />
        </div>
      );
    } else {
      if (this.state.page === 'settings' || this.state.page === 'account') {
        return (
          <CardContainer>
            <AccountSettings />
          </CardContainer>
        );
      } else if (this.state.page === 'itero') {
        return (
          <CardContainer type="top-border" className="pad-md">
            <IteroSettings />
          </CardContainer>
        );
      } else {
        return <ProviderLocatorInfo />;
      }
    }
  }
}

export default withRouter(Profile);
