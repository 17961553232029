import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardContainer from '../components/container/card_container';
import DoctorProfilePhoto from './doctor_profile_photo';
import { getProviderTier, getProviderTierLoading } from '../../redux/reducers/ipp/dashboard';
import { fetchProviderTier } from '../../redux/actions/ipp/dashboard';
import { getProviderTierInfo, hasProviderTier } from './dashboard_helpers';
import ProviderTierStatus from './provider_tier_status';

function ProviderTierCard(props) {
  const { doctor_id, provider_tier, fetchProviderTier } = props;

  useEffect(() => {
    fetchProviderTier(doctor_id);
  }, [fetchProviderTier, doctor_id]);

  if (hasProviderTier(provider_tier, doctor_id)) {
    const { current, next, note } = getProviderTierInfo(provider_tier);
    const approvalsNeed = next.count - Number(provider_tier.case_approvals);

    return (
      <CardContainer className="pad-md provider-tier-container">
        <DoctorProfilePhoto />
        <div className="provider-tier-logo">
          {current.name && (
            <>
              <img src={current.logo} alt="provider tier logo" />
              <span className="emphasis">
                {current.name}
                <br />
                Provider
              </span>
            </>
          )}
        </div>
        <ProviderTierStatus note={note} name={next.name} approvalsNeed={approvalsNeed} />
        <div className="provider-tier-progress">
          <progress className="gradient-progress" max={next.count} value={provider_tier.case_approvals} />
          <img src={next.logo} alt="gold provider tier logo" />
        </div>
        <div className="provider-tier-note">
          {provider_tier.case_approvals}/{next.count} Approved Smile Designs
        </div>
        <div className="provider-tier-footer underlined block--right">
          <Link to="./tier-details">See more details</Link>
        </div>
      </CardContainer>
    );
  }

  return <div />;
}

ProviderTierCard.propTypes = {
  doctor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  provider_tier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    provider_tier: getProviderTier(state),
    provider_tier_loading: getProviderTierLoading(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProviderTier: fetchProviderTier,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProviderTierCard);
