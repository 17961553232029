/**
 * File: de_form2.js - Contains the logic for submitting a digital
 * enhancenment
 * Copyright: (c) Copyright December 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isValidDate } from '../../common/helpers';
import * as nomenclature from '../../common/nomenclature';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';
import { onImageToolAction } from '../../common/functions';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import AlertContainer from '../components/container/alert_container';
import CardContainer from '../components/container/card_container';
import InputDate from '../components/inputs/input_date';
import TextArea from '../components/inputs/text_area';
import TextBox from '../components/inputs/text_box';
import RecordViewer from '../components/record_viewer/record_viewer';
import { getMinDate, getMaxDate } from '../../common/helpers';
import { ToothViewer, Teeth } from '../case_submission/tx_plan';

/**
 * Renders a text area form component.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the field.
 * @param {string} props.heading - The heading text.
 * @param {string} [props.headingIndex] - The heading index.
 * @param {string} [props.description] - The placeholder text.
 * @param {string} [props.rows] - The number of rows for the text area.
 * @param {string} props.value - The value of the text area.
 * @param {function} props.onChange - The onChange event handler.
 * @param {function} props.onFocus - The onFocus event handler.
 * @param {boolean} [props.warning] - Whether to display a warning for the field.
 * @returns {JSX.Element} - The text area form component.
 */
function TextAreaForm(props) {
  return (
    <div className="question-box" data-testid={props.dataTestId}>
      <span className={'title emphasis' + (props.warning ? ' title--warning' : '')} id={props.field}>
        {props.headingIndex ? <span className="title-index emphasis">{props.headingIndex}.</span> : null}
        {props.heading}
      </span>
      <CardContainer type="form">
        <div className="form-group">
          <TextArea
            id={props.field + '_textarea'}
            rows={props.rows ? props.rows : '4'}
            placeholder={props.description}
            data-field={props.field}
            value={props.value}
            onChange={props.onChange}
            onFocus={props.onFocus}
          />
        </div>
      </CardContainer>
    </div>
  );
}

/**
 * Renders a date input area with a title and warning message if applicable.
 * @param {Object} props - The component props.
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the input field.
 * @param {string} props.heading - The title of the input area.
 * @param {number} props.headingIndex - The index of the title, if applicable.
 * @param {function} props.onChange - The function to call when the input value changes.
 * @param {function} props.onFocus - The function to call when the input is focused.
 * @param {string} props.value - The value of the input.
 * @param {boolean} props.warning - Whether the input has a warning message.
 * @returns {JSX.Element} - The rendered component.
 */
function DateArea(props) {
  return (
    <div className="question-box" data-testid={props.dataTestId}>
      <span className={'title emphasis' + (props.warning ? ' title--warning' : '')} id={props.field}>
        {props.headingIndex ? <span className="title-index emphasis">{props.headingIndex}.</span> : null}
        {props.heading}
      </span>
      <CardContainer type="form">
        <InputDate
          id={props.field + '_input'}
          data-field={props.field}
          onChange={props.onChange}
          value={props.value}
          onFocus={props.onFocus}
          className={'de3-date' + (props.warning ? ' warning-border' : '')}
          max={props.field === 'de3_next_appointment_date' ? Moment('3000-01-01').format('YYYY-MM-DD') : getMaxDate()}
          min={props.field === 'de3_next_appointment_date' ? Moment().format('YYYY-MM-DD') : getMinDate()}
        />
        {props.warning && (
          <span id={props.field + '_warning'} className="title title--warning emphasis">
            Invalid Date
          </span>
        )}
      </CardContainer>
    </div>
  );
}

/**
 * Renders a teeth selector chart for a specific field in the form.
 * @function
 * @param {Object} props - The props object.
 * @param {string} props.field - The name of the field.
 * @param {boolean} props.hasUpper - Whether the patient has upper teeth.
 * @param {boolean} props.hasLower - Whether the patient has lower teeth.
 * @param {Array<string>} props.disabledTeeth - An array of teeth to disable.
 * @param {Array<string>} props.teeth - An array of selected teeth.
 * @param {Object} props.teethReference - An object containing arrays of teeth for each quadrant.
 * @param {number} props.minPreset - The minimum preset size.
 * @param {number} props.maxPreset - The maximum preset size.
 * @param {Array<Array<number>>} props.presets - An array of preset tooth configurations.
 * @param {Object} props.tset - The bracket set object.
 * @param {string} props.dataTestId - The data-testid attribute for testing.
 * @param {string} props.heading - The heading for the field.
 * @param {string} props.sub_heading - The sub-heading for the field.
 * @param {string} props.warning - The warning message for the field.
 * @param {boolean} props.showPresets - Whether to show the preset buttons.
 * @param {number} props.headingIndex - The index for the heading.
 * @param {function} props.onClick - The function to call when a tooth is clicked.
 * @param {function} props.setBracketTeeth - The function to call when a preset is selected.
 * @returns {JSX.Element} - The teeth selector chart as a JSX element.
 */
function TeethArea(props) {
  let disabledTeeth = [];

  if (['de3_bracket_teeth', 'de3_teeth_restriction'].includes(props.field)) {
    if (!props.hasUpper) {
      disabledTeeth = disabledTeeth.concat(props.teethReference.UR.concat(props.teethReference.UL));
    } else if (!props.hasLower) {
      disabledTeeth = disabledTeeth.concat(props.teethReference.LR.concat(props.teethReference.LL));
    }
  }

  if (props.disabledTeeth) {
    disabledTeeth = disabledTeeth.concat(props.disabledTeeth);
  }

  /**
   * Constructs teeth selector chart
   * @function
   * @param {Array<string>} teethSet - Set of teeth to display
   * @return {Array<JSX.Element>} - Array of JSX elements for each tooth
   */
  const teethArray = (teethSet) => {
    return teethSet.map((teeth, ind) => {
      if (disabledTeeth.includes(teeth)) {
        return (
          <button key={ind} type="button" className="btn-ib btn-ib--disabled">
            {teeth}
          </button>
        );
      }

      return (
        <button
          key={ind}
          type="button"
          className={props.teeth.includes(teeth) ? 'btn-ib btn-ib--active' : 'btn-ib'}
          data-field={props.field}
          onClick={props.onClick}
          value={teeth}
        >
          {teeth}
        </button>
      );
    });
  };

  /**
   * Checks if the given teeth match the preset for the given index.
   * @function
   * @param {number} n - The index of the preset to check.
   * @param {Array<string>} teeth - The teeth to check against the preset.
   * @param {Object} tset - The bracket set object.
   * @returns {boolean} - True if the teeth match the preset, false otherwise.
   */
  const checkPreset = (n, teeth, tset) => {
    let diff = _.xor(props.presets[n], teeth);
    diff = _.difference(diff, tset.bracketDisabled);
    return diff.length === 0;
  };

  const presetChoice = _.range(props.minPreset, props.maxPreset + 1).find((n) => {
    return checkPreset(n, props.de3_bracket_teeth, props.tset);
  });

  let presetMax = _.range(props.minPreset, props.maxPreset + 1).find((n) => {
    return checkPreset(n, Teeth.All, props.tset);
  });

  presetMax = presetMax ? presetMax : props.maxPreset + 1;

  /**
   * Creates a preset button with the given size.
   * @function
   * @param {number} n - The size of the preset button.
   * @returns {JSX.Element} - The preset button as a JSX element.
   */
  const makePresetButton = (n) => {
    return (
      <button
        key={n}
        type="button"
        onClick={() => props.setBracketTeeth(props.presets[n])}
        className={'btn-ib ' + (n === presetChoice ? 'btn-ib--active ' : '') + (n > presetMax ? 'btn-ib--disabled' : '')}
      >
        {n}x{n}
      </button>
    );
  };

  return (
    <div className="question-box" data-testid={props.dataTestId}>
      <span id={props.field} className="title emphasis">
        {props.headingIndex ? <span className="title-index emphasis">{props.headingIndex}.</span> : null}
        {props.heading}
      </span>
      <CardContainer type="form">
        {props.sub_heading ? props.sub_heading : null}
        {props.showPresets && (
          <div className="bracket-preset">
            Presets:{' '}
            <div className="btn-bar" role="toolbar" id="bracket-presets">
              {_.range(props.minPreset, props.maxPreset + 1)
                .reverse()
                .map((n) => makePresetButton(n))}
            </div>
          </div>
        )}
        <div className="teeth-selector">
          <div className="row">
            <div className="col-md-6">{teethArray(props.teethReference.UR)}</div>
            <div className="col-md-6">{teethArray(props.teethReference.UL)}</div>
          </div>
          <div className="row">
            <div className="col-md-6">{teethArray(props.teethReference.LR)}</div>
            <div className="col-md-6">{teethArray(props.teethReference.LL)}</div>
          </div>
        </div>
        {props.warning ? props.warning : null}
      </CardContainer>
    </div>
  );
}

/**
 * A functional component that renders a radio button with a label.
 * @param {Object} props - The component props.
 * @param {string} props.dataTestId - The data-testid attribute for the radio button.
 * @param {string} props.field - The name of the radio button group.
 * @param {string} props.value - The value of the radio button.
 * @param {string} props.label - The label for the radio button.
 * @param {function} props.onChange - The function to call when the radio button is changed.
 * @param {function} props.onFocus - The function to call when the radio button is focused.
 * @param {string} props.selection - The currently selected value for the radio button group.
 * @returns {JSX.Element} - The rendered radio button.
 */
function RadioArea(props) {
  return (
    <div className="ib-radio" data-testid={props.dataTestId}>
      <label className="form-check-label">
        <input
          id={props.field + '_' + props.value}
          className="form-check-input"
          type="radio"
          name={props.field}
          data-field={props.field}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          defaultChecked={props.value === props.selection}
        />{' '}
        {props.label}
      </label>
    </div>
  );
}

/**
 * Renders a component with a date input and a label for wire insertion date.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the field.
 * @param {string} props.value - The value of the date input.
 * @param {function} props.onChange - The function to be called when the date input changes.
 * @param {function} props.onFocus - The function to be called when the date input is focused.
 * @param {boolean} props.warning - A flag indicating whether the date input is invalid.
 * @returns {JSX.Element} - A JSX element containing a date input and a label for wire insertion date.
 */
function RadioDate(props) {
  return (
    <div className="inline-date-container" data-testid={props.dataTestId}>
      <span className={props.warning ? 'inline-date-container--warning' : ''}>Date of wire insertion:</span>
      <TextBox
        id={props.field + '_input'}
        type="date"
        data-field={props.field}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        className={props.warning ? 'warning-border' : ''}
        max={getMaxDate()}
        min={getMinDate()}
      />
      {props.warning && <span className="title title--warning emphasis">Invalid Date</span>}
    </div>
  );
}

/**
 * Renders a group of radio buttons with an optional date input field for each radio button.
 * @param {Object} props - The props object containing the following properties:
 * @param {Array} props.radios - An array of objects representing each radio button. Each object should have the following properties:
 * @param {string} props.radios.label - The label for the radio button.
 * @param {string} props.radios.field - The name of the field for the radio button.
 * @param {string} props.radios.value - The value of the radio button.
 * @param {Function} props.radios.selection - A function that returns the currently selected radio button value.
 * @param {Function} props.radios.onChange - A function that is called when the radio button selection changes.
 * @param {Function} props.radios.onFocus - A function that is called when the radio button receives focus.
 * @param {string} props.dataTestIdPrefix - A prefix to use for the data-testid attribute of each radio button.
 * @returns {JSX.Element} A React Fragment containing the radio buttons and date input fields.
 */
function RadioAreaWithDate(props) {
  return props.radios.map((radio, ind) => {
    return (
      <React.Fragment key={ind}>
        <RadioArea
          label={radio.label}
          field={radio.field}
          value={radio.value}
          selection={radio.selection()}
          onChange={radio.onChange}
          onFocus={radio.onFocus}
          dataTestId={`${props.dataTestIdPrefix}_${radio.value}`}
        />
        {radio.date && radio.selection() === radio.value ? (
          <RadioDate field={radio.date_field} value={radio.date_value()} onChange={radio.onChange} onFocus={radio.onFocus} warning={radio.warning()} />
        ) : null}
      </React.Fragment>
    );
  });
}

class DigitalEnhancementForm3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbs: [],
    };
  }

  componentDidMount() {
    this.buildImageRecords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.photoUpload !== prevProps.photoUpload || this.props.xrayUpload !== prevProps.xrayUpload || this.props.scanUpload !== prevProps.scanUpload) {
      this.buildImageRecords();
    }
  }

  buildImageRecords() {
    const {
      photoUpload,
      xrayUpload,
      scanUpload,
      // iTero integration
      uploadMethod,
      selectedIteroScanRecords,
    } = this.props;
    const records = [];
    if (photoUpload) records.push(...photoUpload);
    if (xrayUpload) records.push(...xrayUpload);

    // iTero integration
    if (uploadMethod === 'itero' && selectedIteroScanRecords) {
      records.push(...selectedIteroScanRecords);
    } else if (scanUpload) {
      records.push(...scanUpload);
    }

    const options = { teethViewer: true, caseType: 'complete' };
    this.props.buildRecordStates(records, options);
  }

  upper_smartwire_field = 'de3_upper_smartwire';
  lower_smartwire_field = 'de3_lower_smartwire';

  upper_smartwire = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.upper_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'de',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.upper_smartwire_field,
      value: 'none',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  lower_smartwire = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.lower_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2A}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2a',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'de',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2A}</span>,
      field: this.lower_smartwire_field,
      value: 'de_16_straight',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.lower_smartwire_field,
      value: 'none',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  upper_smartwire_gen2 = [
    {
      label: <span>{nomenclature.SMARTWIRE_0}</span>,
      field: this.upper_smartwire_field,
      value: 'stage0',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.upper_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_3}</span>,
      field: this.upper_smartwire_field,
      value: 'stage3',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_0}</span>,
      field: this.upper_smartwire_field,
      value: 'de0',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_1}</span>,
      field: this.upper_smartwire_field,
      value: 'de1',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'de2',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_3}</span>,
      field: this.upper_smartwire_field,
      value: 'de3',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de3_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de3_upper_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.upper_smartwire_field,
      value: 'none',
      selection: () => this.props.de3_upper_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  lower_smartwire_gen2 = [
    {
      label: <span>{nomenclature.SMARTWIRE_0}</span>,
      field: this.lower_smartwire_field,
      value: 'stage0',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.lower_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_3}</span>,
      field: this.lower_smartwire_field,
      value: 'stage3',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_0}</span>,
      field: this.lower_smartwire_field,
      value: 'de0',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_1}</span>,
      field: this.lower_smartwire_field,
      value: 'de1',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'de2',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_3}</span>,
      field: this.lower_smartwire_field,
      value: 'de3',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de3_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de3_lower_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.lower_smartwire_field,
      value: 'none',
      selection: () => this.props.de3_lower_smartwire,
      onChange: this.props.onInputChange_de3,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  isDe = () => {
    return this.props && this.props.de3_prev_case_info && this.props.de3_prev_case_info.case_id && this.props.de3_prev_case_info.case_id.indexOf('-') >= 0;
  };

  getCaseType = () => {
    return this.isDe() ? 'most recent Digital Enhancement' : 'initial case';
  };

  getRestrictionMsg = () => {
    let result = '';

    if (
      this.props.de3_prev_case_info &&
      this.props.de3_prev_case_info.exist &&
      _.difference(this.props.de3_teeth_restriction, this.props.de3_prev_case_info.teeth_restriction).length === 0 &&
      _.difference(this.props.de3_prev_case_info.teeth_restriction, this.props.de3_teeth_restriction).length === 0
    ) {
      result = 'Teeth indicated not to be moved from your ' + this.getCaseType() + ' have been preselected for you';
    }

    return result;
  };

  hasByPassCondition = () => {
    return (
      (this.props.de3_arch_to_treat === 'upper' && this.props.de3_arch_opposing_treat !== 'current') ||
      (this.props.de3_arch_to_treat === 'lower' && this.props.de3_arch_opposing_treat !== 'current')
    );
  };

  smartWireWarning = (warning_msg) => {
    if (warning_msg) {
      return (
        <div className="col-md-12">
          <AlertContainer>
            {warning_msg.split('.').map((sentence, ind) => {
              return (
                <span key={ind}>
                  {sentence}
                  <br />
                </span>
              );
            })}
          </AlertContainer>
        </div>
      );
    }

    return null;
  };

  smartWireWornLessThan6Weeks = (date) => {
    let less_than_6_weeks = false;

    if (date) {
      const days = Moment().diff(Moment(date), 'days');
      const six_weeks_in_days = 6 * 7;

      less_than_6_weeks = days < six_weeks_in_days;
    }

    return less_than_6_weeks;
  };

  showSmartWireDateWarning = () => {
    // Standard warning for stage 1 wire
    let warning_msg = '';
    const upper_smartwire = this.props.de3_upper_smartwire;
    const upper_smartwire_date = this.props.de3_upper_smartwire_date;
    const lower_smartwire = this.props.de3_lower_smartwire;
    const lower_smartwire_date = this.props.de3_lower_smartwire_date;

    if (upper_smartwire === 'stage1' || lower_smartwire === 'stage1') {
      warning_msg = `Please insert the ${nomenclature.SMARTWIRE_2} and allow it to fully express before submitting for Digital Enhancement`;
    }

    if (
      (upper_smartwire !== 'none' && upper_smartwire !== 'stage1' && upper_smartwire_date !== '' && this.smartWireWornLessThan6Weeks(upper_smartwire_date)) ||
      (lower_smartwire !== 'none' &&
        lower_smartwire !== 'stage1' &&
        lower_smartwire !== 'stage2a' &&
        lower_smartwire !== 'de_16_straight' &&
        lower_smartwire_date !== '' &&
        this.smartWireWornLessThan6Weeks(lower_smartwire_date))
    ) {
      if (warning_msg !== '') {
        warning_msg = warning_msg + '.';
      }
      warning_msg = warning_msg + 'Please ensure there has been no observed tooth movement for the last 4-8 weeks';
    }

    if (!this.props.reupload) {
      if (warning_msg) {
        this.props.getDeHold(true);
      } else {
        this.props.getDeHold(false);
      }
    }

    return this.smartWireWarning(warning_msg);
  };

  getFormClass() {
    return this.props.record_states.length > 0 ? 'col-md-8' : 'col-md-12';
  }

  render() {
    const tset = this.props.getImpliedTeethSets();
    return (
      <div className="row">
        <form className={this.getFormClass()}>
          {/* 1. Reason(s) for Digital Enhancement */}
          <div className="row">
            <div className="col-md-12">
              <TextAreaForm
                headingIndex={'1'}
                heading={'Reason(s) for Digital Enhancement*'}
                field={'de3_reason'}
                description="Please describe the reason(s) for Digital Enhancement"
                value={this.props.de3_reason}
                onChange={this.props.onInputChange_de3}
                onFocus={this.props.onFocus}
                dataTestId="de3_reason_box"
              />
            </div>
          </div>

          {/* 2. Date of next appointment (optional) */}
          <div className="row">
            <div className="col-md-12">
              <DateArea
                headingIndex={'2'}
                heading={'Date of next appointment (optional)'}
                field={'de3_next_appointment_date'}
                onChange={this.props.onInputChange_de3}
                value={this.props.de3_next_appointment_date}
                onFocus={this.props.onFocus}
                warning={this.props.de3_next_appointment_date_warning}
                dataTestId="de3_next_appointment_date_box"
              />
            </div>
          </div>

          {/* 3. Date of Digital Enhancement Toothprints */}
          <div className="row">
            <div className="col-md-12">
              <DateArea
                headingIndex={'3'}
                heading={'Date of Digital Enhancement Toothprints*'}
                field={'de3_intra_date'}
                onChange={this.props.onInputChange_de3}
                value={this.props.de3_intra_date}
                onFocus={this.props.onFocus}
                warning={this.props.de3_intra_date_warning}
                dataTestId="de3_intra_date_box"
              />
            </div>
          </div>

          {/* 4. Which Smartwire® was most recently inserted? */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_smartwire_box">
                <span className="title emphasis">
                  <span className="title-index emphasis">4.</span>
                  <span className="emphasis">
                    Which Smartwire<span className="trademark">&reg;</span> was most recently inserted?
                  </span>
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="emphasis">Upper Smartwire:</div>
                      <RadioAreaWithDate radios={this.props.gen_2 ? this.upper_smartwire_gen2 : this.upper_smartwire} dataTestIdPrefix={'de3_smartwire'} />
                    </div>

                    <div className="col-md-6">
                      <div className="emphasis">Lower Smartwire:</div>
                      <RadioAreaWithDate radios={this.props.gen_2 ? this.lower_smartwire_gen2 : this.lower_smartwire} dataTestIdPrefix={'de3_smartwire'} />
                    </div>

                    {!this.props.gen_2 && this.showSmartWireDateWarning()}
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 5. Missing/Previously Extracted teeth */}
          <div className="row">
            <div className="col-md-12">
              <TeethArea
                headingIndex="5"
                heading="Missing/Previously Extracted teeth"
                field="de3_missing_teeth"
                teeth={this.props.de3_missing_teeth}
                onClick={this.props.onInputChange_de3}
                warning={
                  _.intersection(['UR7', 'UL7'], this.props.de3_missing_teeth).length >= 1 && this.props.hasUpper() ? (
                    <AlertContainer>Brackets on upper 7's will be added only if there is enough room on the lingual surface.</AlertContainer>
                  ) : null
                }
                hasUpper={this.props.hasUpper()}
                hasLower={this.props.hasLower()}
                teethReference={this.props.teethReference}
                dataTestId="de3_missing_teeth_box"
              />
            </div>
          </div>

          {/* 6. Teeth to be extracted */}
          <div className="row">
            <div className="col-md-12">
              <TeethArea
                headingIndex="6"
                heading="Teeth to be extracted"
                field="de3_extract_teeth"
                teeth={this.props.de3_extract_teeth}
                onClick={this.props.onInputChange_de3}
                warning={
                  _.intersection(['UR7', 'UL7'], this.props.de3_extract_teeth).length >= 1 && this.props.hasUpper() ? (
                    <AlertContainer>Brackets on upper 7's will be added only if there is enough room on the lingual surface.</AlertContainer>
                  ) : null
                }
                hasUpper={this.props.hasUpper()}
                hasLower={this.props.hasLower()}
                disabledTeeth={this.props.de3_missing_teeth}
                teethReference={this.props.teethReference}
                dataTestId="de3_extract_teeth_box"
              />
            </div>
          </div>

          {/* 7. Arch to treat for Digital Enhancement */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_arch_to_treat_box">
                <span id="de3_arch_to_treat" className="title emphasis">
                  <span className="title-index emphasis">7.</span>
                  Arch to treat for Digital Enhancement*
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_arch_to_treat-both"
                            className="form-check-input"
                            type="radio"
                            name="de3_arch_to_treat"
                            data-field="de3_arch_to_treat"
                            value="both"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_arch_to_treat === 'both'}
                          />{' '}
                          Both arches{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_arch_to_treat-upper"
                            className="form-check-input"
                            type="radio"
                            name="de3_arch_to_treat"
                            data-field="de3_arch_to_treat"
                            value="upper"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_arch_to_treat === 'upper'}
                          />{' '}
                          Upper arch (Smartwire provided for upper arch only){' '}
                        </label>{' '}
                      </div>

                      <React.Fragment>
                        <div className="ib-radio ib-arch ib-arch-sidebyside">
                          <label className="form-check-label ">
                            <input
                              id="de3_upper_arch_opposing_treat-lower"
                              type="radio"
                              name="de3_upper_arch_opposing_treat"
                              className="form-check-input"
                              data-field="de3_upper_arch_opposing_treat"
                              value="current"
                              checked={this.props.de3_arch_to_treat === 'upper' && this.props.de3_upper_arch_opposing_treat === 'current'}
                              onChange={this.props.onInputChange_de3}
                              disabled={this.props.de3_arch_to_treat !== 'upper'}
                            />{' '}
                            Use current lower arch for Smile Design
                          </label>
                        </div>
                        <div className="ib-radio ib-arch ib-arch-sidebyside">
                          <label className="form-check-label ">
                            <input
                              id="de3_upper_arch_opposing_treat-both"
                              type="radio"
                              name="de3_upper_arch_opposing_treat"
                              className="form-check-input"
                              data-field="de3_upper_arch_opposing_treat"
                              value="both"
                              checked={this.props.de3_arch_to_treat === 'upper' && this.props.de3_upper_arch_opposing_treat === 'both'}
                              onChange={this.props.onInputChange_de3}
                              disabled={this.props.de3_arch_to_treat !== 'upper'}
                            />{' '}
                            Set up both arches
                          </label>
                        </div>
                      </React.Fragment>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_arch_to_treat-lower"
                            className="form-check-input"
                            type="radio"
                            name="de3_arch_to_treat"
                            data-field="de3_arch_to_treat"
                            value="lower"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_arch_to_treat === 'lower'}
                          />{' '}
                          Lower arch (Smartwire provided for lower arch only){' '}
                        </label>{' '}
                      </div>

                      <React.Fragment>
                        <div className="ib-radio ib-arch ib-arch-sidebyside">
                          <label className="form-check-label ">
                            <input
                              id="de3_lower_arch_opposing_treat-upper"
                              type="radio"
                              name="de3_lower_arch_opposing_treat"
                              className="form-check-input"
                              data-field="de3_lower_arch_opposing_treat"
                              value="current"
                              checked={this.props.de3_arch_to_treat === 'lower' && this.props.de3_lower_arch_opposing_treat === 'current'}
                              onChange={this.props.onInputChange_de3}
                              disabled={this.props.de3_arch_to_treat !== 'lower'}
                            />{' '}
                            Use current upper arch for Smile Design
                          </label>
                        </div>
                        <div className="ib-radio ib-arch ib-arch-sidebyside">
                          <label className="form-check-label ">
                            <input
                              id="de3_lower_arch_opposing_treat-both"
                              type="radio"
                              name="de3_lower_arch_opposing_treat"
                              className="form-check-input"
                              data-field="de3_lower_arch_opposing_treat"
                              value="both"
                              checked={this.props.de3_arch_to_treat === 'lower' && this.props.de3_lower_arch_opposing_treat === 'both'}
                              onChange={this.props.onInputChange_de3}
                              disabled={this.props.de3_arch_to_treat !== 'lower'}
                            />{' '}
                            Set up both arches
                          </label>
                        </div>
                      </React.Fragment>
                    </div>

                    {this.props.de3_prev_case_info &&
                    this.props.de3_prev_case_info.exist &&
                    this.props.de3_prev_case_info.arch_to_treat !== this.props.de3_arch_to_treat ? (
                      <div className="col-md-12">
                        <AlertContainer>This arch selection differs from the previous submission.</AlertContainer>
                      </div>
                    ) : null}
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 8. Digital Enhancement Smartwires designed for the following bracket placement */}
          <div className="row">
            <div className="col-md-12">
              <TeethArea
                headingIndex="8"
                heading="Digital Enhancement Smartwires designed for the following bracket placement*"
                field="de3_bracket_teeth"
                teeth={this.props.de3_bracket_teeth}
                onClick={this.props.onInputChange_de3}
                warning={
                  _.intersection(['UR7', 'UL7'], this.props.de3_bracket_teeth).length >= 1 && this.props.hasUpper() ? (
                    <AlertContainer>Brackets on upper 7's will be added only if there is enough room on the lingual surface.</AlertContainer>
                  ) : null
                }
                hasUpper={this.props.hasUpper()}
                hasLower={this.props.hasLower()}
                disabledTeeth={[...this.props.de3_missing_teeth, ...this.props.de3_extract_teeth]}
                teethReference={this.props.teethReference}
                tset={tset}
                showPresets={true}
                presets={this.props.presets}
                minPreset={this.props.minPreset}
                maxPreset={this.props.maxPreset}
                de3_bracket_teeth={this.props.de3_bracket_teeth}
                setBracketTeeth={this.props.setBracketTeeth}
                dataTestId="de3_bracket_teeth_box"
              />
            </div>
          </div>

          {/* 9. Do not move the following teeth */}
          <div className="row">
            <div className="col-md-12">
              <TeethArea
                headingIndex="9"
                heading="Do not move the following teeth"
                sub_heading={this.getRestrictionMsg()}
                field="de3_teeth_restriction"
                teeth={this.props.de3_teeth_restriction}
                onClick={this.props.onInputChange_de3}
                hasUpper={this.props.hasUpper() || this.hasByPassCondition()}
                hasLower={this.props.hasLower() || this.hasByPassCondition()}
                disabledTeeth={[...this.props.de3_missing_teeth, ...this.props.de3_extract_teeth]}
                teethReference={this.props.teethReference}
                dataTestId="de3_teeth_restriction_box"
              />
            </div>
          </div>

          {/* 10. Anterior-Posterior */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_anterior_posterior_box">
                <span id="de3_anterior_posterior" className="title emphasis">
                  <span className="title-index emphasis">10.</span>
                  Anterior-Posterior
                  {[this.props.de3_anterior_posterior_l, this.props.de3_anterior_posterior_r].includes('correction') ||
                  [this.props.de3_anterior_posterior_l, this.props.de3_anterior_posterior_r].includes('other')
                    ? '*'
                    : ''}
                </span>

                <CardContainer type="form">
                  <div className="anterior-posterior">
                    <div className="anterior-posterior__radios">
                      <div className="header">
                        <div className="header__c1"></div>
                        <div className="header__c2">R</div>
                        <div className="header__c3">L</div>
                      </div>

                      <div className="row1">
                        <div className="row1__c1">Maintain current</div>
                        <div className="row1__c2">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_r-current"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_r"
                              data-field="de3_anterior_posterior_r"
                              value="current"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_r === 'current'}
                            />
                          </div>
                        </div>
                        <div className="row1__c3">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_l-current"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_l"
                              data-field="de3_anterior_posterior_l"
                              value="current"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_l === 'current'}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row2">
                        <div className="row2__c1">Correction to Class I</div>
                        <div className="row2__c2">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_r-correction"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_r"
                              data-field="de3_anterior_posterior_r"
                              value="correction"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_r === 'correction'}
                            />
                          </div>
                        </div>
                        <div className="row2__c3">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_l-correction"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_l"
                              data-field="de3_anterior_posterior_l"
                              value="correction"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_l === 'correction'}
                            />
                          </div>
                        </div>
                      </div>

                      {[this.props.de3_anterior_posterior_l, this.props.de3_anterior_posterior_r].includes('correction') ? (
                        <div className="anterior-posterior__corrections-checkboxes">
                          <div className="ib-checkbox">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="class_II_or_III_correction_simulation"
                                id="class_II_or_III_correction_simulation"
                                data-field="de3_anterior_posterior_corrections"
                                checked={this.props.de3_anterior_posterior_corrections.class_II_or_III_correction_simulation}
                                onChange={this.props.onInputChange_de3}
                              />{' '}
                              {'Class II/III correction simulation (elastics or auxiliaries required)'}
                            </label>
                          </div>
                          <div className="ib-checkbox">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="posterior_IPR"
                                id="posterior_IPR"
                                data-field="de3_anterior_posterior_corrections"
                                checked={this.props.de3_anterior_posterior_corrections.posterior_IPR}
                                onChange={this.props.onInputChange_de3}
                              />{' '}
                              {'Posterior IPR'}
                            </label>
                          </div>
                        </div>
                      ) : null}

                      <div className="row2">
                        <div className="row2__c1">Other (Please specify)</div>
                        <div className="row2__c2">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_r-other"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_r"
                              data-field="de3_anterior_posterior_r"
                              value="other"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_r === 'other'}
                            />
                          </div>
                        </div>
                        <div className="row2__c3">
                          <div className="ib-radio">
                            <input
                              id="de3_anterior_posterior_l-other"
                              className="form-check-input"
                              type="radio"
                              name="de3_anterior_posterior_l"
                              data-field="de3_anterior_posterior_l"
                              value="other"
                              onChange={this.props.onInputChange_de3}
                              defaultChecked={this.props.de3_anterior_posterior_l === 'other'}
                            />
                          </div>
                        </div>
                      </div>

                      {[this.props.de3_anterior_posterior_r, this.props.de3_anterior_posterior_l].includes('other') ? (
                        <div className="anterior-posterior__corrections-checkboxes">
                          <TextArea
                            rows="3"
                            type="text"
                            className="form-control inline-block fluid-size"
                            placeholder="Specify instructions for AP correction"
                            data-field="de3_anterior_posterior_notes"
                            id="de3_anterior_posterior_notes"
                            onChange={this.props.onInputChange_de3}
                            value={this.props.de3_anterior_posterior_notes}
                            onFocus={this.props.onFocus}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 11. Midlines */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_midlines_box">
                <span id="de3_midlines" className="title emphasis">
                  <span className="title-index emphasis">11.</span>
                  Midlines
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_midlines-show_resulting_after_aligment"
                            className="form-check-input"
                            type="radio"
                            name="de3_midlines"
                            data-field="de3_midlines"
                            value="show_resulting_after_aligment"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_midlines === 'show_resulting_after_aligment'}
                          />{' '}
                          Show resulting midlines after alignment{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_midlines-improve_with_ipr"
                            className="form-check-input"
                            type="radio"
                            name="de3_midlines"
                            data-field="de3_midlines"
                            value="improve_with_ipr"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_midlines === 'improve_with_ipr'}
                          />{' '}
                          Improve midlines with IPR{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_midlines-improve_with_ap_shift"
                            className="form-check-input"
                            type="radio"
                            name="de3_midlines"
                            data-field="de3_midlines"
                            value="improve_with_ap_shift"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_midlines === 'improve_with_ap_shift'}
                          />{' '}
                          Improve midlines with AP shift (elastics required){' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_midlines-improve_with_ipr_and_ap_shift"
                            className="form-check-input"
                            type="radio"
                            name="de3_midlines"
                            data-field="de3_midlines"
                            value="improve_with_ipr_and_ap_shift"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_midlines === 'improve_with_ipr_and_ap_shift'}
                          />{' '}
                          Improve midlines with IPR and AP shift{' '}
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 12. Will auxiliaries be used to treat this case (e.g. buttons and elastics, labial segment of traditional braces, Carriere® Motion appliance, etc.)? */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_auxiliaries_box">
                <span id="de3_auxiliaries" className="title emphasis tx-de-3-auxiliaries-title">
                  <span className="title-index emphasis">12.</span>
                  Will auxiliaries be used to treat this case (e.g. buttons and elastics, labial segment of traditional braces, Carriere® Motion appliance,
                  etc.)?{this.props.de3_auxiliaries === 'Yes' ? '*' : ''}
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_auxiliaries-No"
                            className="form-check-input"
                            type="radio"
                            name="de3_auxiliaries"
                            data-field="de3_auxiliaries"
                            value="No"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_auxiliaries === 'No'}
                          />{' '}
                          No{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_auxiliaries-Yes"
                            className="form-check-input"
                            type="radio"
                            name="de3_auxiliaries"
                            data-field="de3_auxiliaries"
                            value="Yes"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_auxiliaries === 'Yes'}
                          />{' '}
                          Yes{' '}
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  {this.props.de3_auxiliaries === 'Yes' ? (
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <TextArea
                            rows="3"
                            type="text"
                            className="form-control inline-block fluid-size"
                            placeholder="Specify planned auxiliaries"
                            data-field="de3_auxiliaries_notes"
                            id="de3_auxiliaries_notes"
                            onChange={this.props.onInputChange_de3}
                            value={this.props.de3_auxiliaries_notes}
                            onFocus={this.props.onFocus}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 13. Allow InBrace to perform additional Smile Design adjustments as needed?* */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_setup_additional_box">
                <span id="de3_setup_additional" className="title emphasis">
                  <span className="title-index emphasis">13.</span>
                  Allow InBrace to perform additional Smile Design adjustments as needed?
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup_additional_instruction-allow"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup_additional_instruction"
                            data-field="de3_setup_additional_instruction"
                            value="yes"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup_additional_instruction === 'yes'}
                          />{' '}
                          Yes
                        </label>{' '}
                      </div>
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup_additional_instruction-only"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup_additional_instruction"
                            data-field="de3_setup_additional_instruction"
                            value="no"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup_additional_instruction === 'no'}
                          />{' '}
                          No thanks. Perform only what I have specified in the next question
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>

          {/* 14. How would you like your Digital Enhancement Smile Design?* */}
          <div className="row">
            <div className="col-md-12">
              <div className="question-box" data-testid="de3_setup_box">
                <span id="de3_setup" className="title emphasis">
                  <span className="title-index emphasis">14.</span>
                  How would you like your Digital Enhancement Smile Design?
                </span>

                <CardContainer type="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup-accept_current_improve_detailing"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup"
                            data-field="de3_setup"
                            value="accept_current_improve_detailing"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup === 'accept_current_improve_detailing'}
                          />{' '}
                          Accept current occlusion but improve on detailing{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup-settle_current_improve_detailing"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup"
                            data-field="de3_setup"
                            value="settle_current_improve_detailing"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup === 'settle_current_improve_detailing'}
                          />{' '}
                          Settle the current occlusion and improve on detailing{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup-same"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup"
                            data-field="de3_setup"
                            value="same"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup === 'same'}
                          />{' '}
                          Same treatment goals as the most recently approved Smile Design{' '}
                        </label>{' '}
                      </div>

                      <div className="ib-radio">
                        <label className="form-check-label ">
                          <input
                            id="de3_setup-adjust_treatment_improve_detailing"
                            className="form-check-input"
                            type="radio"
                            name="de3_setup"
                            data-field="de3_setup"
                            value="adjust_treatment_improve_detailing"
                            onChange={this.props.onInputChange_de3}
                            defaultChecked={this.props.de3_setup === 'adjust_treatment_improve_detailing'}
                          />{' '}
                          Adjust treatment goals and improve on detailing{' '}
                        </label>{' '}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <div>
                        <span id="de3_setup_instruction" className="title emphasis">
                          Please instruct specific tooth movements and any additional instructions
                          {this.props.de3_setup === 'adjust_treatment_improve_detailing' ? '*' : ''}
                        </span>
                        <TextArea
                          rows="3"
                          type="text"
                          className="form-control inline-block fluid-size"
                          placeholder="Specify tooth movements and additional instructions"
                          data-field="de3_setup_instruction"
                          onChange={this.props.onInputChange_de3}
                          value={this.props.de3_setup_instruction}
                          onFocus={this.props.onFocus}
                        />
                      </div>
                    </div>
                  </div>
                </CardContainer>
              </div>
            </div>
          </div>
        </form>

        {this.props.record_states.length > 0 ? (
          <div className="col-md-4 tr-viewer-container-parent">
            <div className="tr-viewer-container">
              <RecordViewer
                records={this.props.record_states}
                onLoad={this.props.onLoadImageRecord}
                onClick={(id, action) => {
                  onImageToolAction(id, action, this.props);
                }}
              >
                <ToothViewer teethSets={tset} thumbs={this.state.thumbs} />
              </RecordViewer>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DigitalEnhancementForm3);
