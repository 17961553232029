/**
 * Copyright: (c) Copyright August 2020 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/

// ---------------------------------- Imports ----------------------------------
// Css
import './label_dropdown_list.scss';
// External Libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../doctor/components/inputs/dropdown';

const SAVING_VALUE = 'Saving...';

/**
 * Standard dropdown list with a label for the IPP
 *
 * @component
 * @alias DoctorLabelDropdown
 * @category IPP
 */
class LabelDropdownList extends Component {
  state = {
    edit_value: '',
  };

  /**
   * Returns edit value to the default value (empty in this case)
   * @function
   */
  clearEditValue = () => {
    this.setState({ edit_value: '' });
  };

  /**
   * Returns edit value to the default value (empty in this case)
   * @function
   */
  onConfirmationClick = () => {
    this.props.onConfirmationButtonClick(this.state.edit_value, this.clearEditValue);
    this.setState({ edit_value: SAVING_VALUE });
  };

  getValue() {
    const value = this.state.edit_value ? this.state.edit_value : this.props.value;
    if (value === SAVING_VALUE) {
      return { label: value, value: value };
    } else {
      return this.props.dropdown_list.filter((item) => item.value === value);
    }
  }

  onChange = (e) => {
    this.setState({ edit_value: e.value });
  };

  render() {
    return (
      <div id={this.props.id} className="label-dropdown-container">
        <span>
          <span className="emphasis">{this.props.label_name}</span>
          {this.props.label_description && (
            <span className="label-dropdown-popup" data-toggle="tooltip" title={this.props.label_description}>
              <i className="fa fa-info-circle" />
            </span>
          )}
          {': '}
        </span>

        {this.props.readonly ? (
          <span>{this.props.value}</span>
        ) : (
          <div className="dropdown-container">
            <Dropdown value={this.getValue()} options={this.props.dropdown_list} onChange={this.onChange} isLoading={this.state.edit_value === SAVING_VALUE} />

            {this.state.edit_value && this.state.edit_value !== this.props.value && this.state.edit_value !== SAVING_VALUE && (
              <span className="dropdown-action-container">
                <div className="heading-item heading-button">
                  <i className="fa fa-check heading-action" aria-hidden onClick={this.onConfirmationClick} />
                </div>
                <div className="heading-item heading-button">
                  <i className="fa fa-times heading-action" aria-hidden onClick={this.clearEditValue} />
                </div>
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

LabelDropdownList.propTypes = {
  dropdown_list: PropTypes.array,
  label_name: PropTypes.string,
  label_description: PropTypes.string,
  onConfirmationButtonClick: PropTypes.func,
  readonly: PropTypes.bool,
  value: PropTypes.string,
};

export default LabelDropdownList;
