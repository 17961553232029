/**
 * File: page_number.js - A component to show page number for react-bootstrap pagination components
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Inbrace Software Team
 * Project: InBrace Provider/Business Portal
 * Special Notes: Provide startRange, endRange, total
 **/
// CSS
import './pagination_container.scss';
import './ipp_pagination_container.scss';
import './bpp_pagination_container.scss';
// External Libs
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';
// Internal Components
import PageNumber from './page_number/page_number';

class PaginationContainer extends Component {
  getPaginationClassName() {
    let paginationClassName = '';

    if (this.props.theme === 'ipp') {
      paginationClassName = 'ipp-pagination';
    } else if ((this.props.theme === 'bpp') & this.props.isBottom) {
      paginationClassName = 'pagination-dark pagination-bottom';
    } else if (this.props.theme === 'bpp' && !this.props.isBottom) {
      paginationClassName = 'pagination-dark';
    } else {
      paginationClassName = null;
    }

    return paginationClassName;
  }

  render() {
    const pageNumber = (
      <PageNumber
        theme={this.props.theme === 'ipp' ? 'pagination__number' : this.props.theme}
        startRange={this.props.startRange}
        endRange={this.props.endRange}
        total={this.props.totalItemsCount}
        type={this.props.type}
      />
    );

    return (
      <div className="pagination-container">
        {!this.props.hideDisabled && (
          <div className={`${this.props.theme === 'ipp' ? 'pagination__container' : 'bpp-pagination-container'}`}>
            {this.props.theme === 'ipp' ? pageNumber : null}
            <div className={this.getPaginationClassName()}>
              <Pagination className={`${this.props.theme === 'ipp' ? '' : 'bpp-pagination'}`} {...this.props} />
              {this.props.theme === 'ipp' ? null : pageNumber}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PaginationContainer;
