import _ from 'lodash';
import React from 'react';
import moment from 'moment-timezone';
import { textFieldCheck, safeSearchChar, removeEmoji } from './functions';
import { getStatusPriorityDefinition, translateCaseStatus, translateStatus, getSmileStatusPriorityDefinition } from './helpers';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from './route';
import CircleLoader from '../components/loader/circle_loader';
import { isPostApprovalStage } from './case/case_status';

const getCasesFromStatus = (cases, status) => {
  if (status === '') {
    return cases;
  }

  let cases_list = [];

  status = getStatusFilterDefinition(status);

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].status === status) {
      cases_list.push(cases[i]);
    }
  }

  return cases_list;
};

const getSmilesFromStatus = (smiles, status) => {
  if (status === '') {
    return smiles;
  }

  let smiles_list = [];

  status = getSmileStatusFilterDefinition(status);

  for (let i = 0; i < smiles.length; i++) {
    if (smiles[i].status === status) {
      smiles_list.push(smiles[i]);
    }
  }

  return smiles_list;
};

const getCasesFromStartDate = (cases, start_date) => {
  if (start_date === '') {
    return cases;
  }

  let cases_list = [];
  start_date = new Date(moment.tz(start_date + ' 00:00:00', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].submission_date_val >= start_date) {
      cases_list.push(cases[i]);
    }
  }

  return cases_list;
};

const getCasesFromEndDate = (cases, end_date) => {
  if (end_date === '') {
    return cases;
  }

  let cases_list = [];
  end_date = new Date(moment.tz(end_date + ' 23:59:59', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].submission_date_val <= end_date) {
      cases_list.push(cases[i]);
    }
  }

  return cases_list;
};

const getCasesFromFilter = (cases, filter_code, user_id, assigned_cases, ws_assigned_cases = null) => {
  let cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (filter_code === 'assigned_me' && assigned_cases) {
      for (let j = 0; j < assigned_cases.length; j++) {
        if (
          cases[i].case_id === assigned_cases[j]['case_status__case_id'] &&
          assigned_cases[j]['assignment_id'] === user_id &&
          _.intersection(cases[i]['filter_codes'], ['inbrace_active', 'assigned_me']).length > 0
        ) {
          cases_list.push(cases[i]);
        }
      }
    }
    if (filter_code === 'assigned_me' && ws_assigned_cases) {
      ws_assigned_cases = ws_assigned_cases.filter((w) => assigned_cases.every((a) => w['case_automation_status__case_id'] !== a['case_status__case_id']));

      for (let j = 0; j < ws_assigned_cases.length; j++) {
        if (
          cases[i].case_id === ws_assigned_cases[j]['case_automation_status__case_id'] &&
          ws_assigned_cases[j]['assignment_id'] === user_id &&
          _.intersection(cases[i]['filter_codes'], ['inbrace_active', 'hold']).length > 0
        ) {
          cases_list.push(cases[i]);
        }
      }
    } else if (filter_code === 'active_me') {
      if (_.intersection(cases[i]['filter_codes'], ['inbrace_active']).length > 0 && cases[i]['assigned_to'].id === user_id) {
        cases_list.push(cases[i]);
      }
    } else {
      if (_.intersection(cases[i]['filter_codes'], [filter_code]).length > 0) {
        cases_list.push(cases[i]);
      }
    }
  }

  return cases_list;
};

const getSmilesFromFilter = (smiles, filter_code, user_id, assigned_smiles) => {
  let smiles_list = [];
  for (let i = 0; i < smiles.length; i++) {
    if (filter_code === 'assigned_me' && assigned_smiles) {
      for (let j = 0; j < assigned_smiles.length; j++) {
        if (
          smiles[i].smile_id === assigned_smiles[j]['smile_status__smile_id'] &&
          assigned_smiles[j]['assignment_id'] === user_id &&
          _.intersection(smiles[i]['filter_codes'], ['inbrace_active', 'assigned_me']).length > 0
        ) {
          smiles_list.push(smiles[i]);
        }
      }
    } else if (filter_code === 'active_me') {
      // TBD
    } else {
      if (_.intersection(smiles[i]['filter_codes'], [filter_code]).length > 0) {
        smiles_list.push(smiles[i]);
      }
    }
  }
  return smiles_list;
};

/**
 * Gets list of cases from the Approve For Shipping or Mark Shipped filters
 * @function
 * @param cases {Array} - Full case list
 * @param filter_code {String} - The filter name
 * @return {Array} - List of cases from filter
 */
const getShippingCasesFromFilter = (cases, filter_code) => {
  let cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (!filter_code && cases[i]['filter_codes'].includes('approve_for_shipping')) {
      cases_list.push(cases[i]);
    } else if (_.intersection(cases[i]['filter_codes'], [filter_code]).length > 0) {
      cases_list.push(cases[i]);
    }
  }

  return cases_list;
};

const getCasesFromSearch = (cases, search_phrase, column_filter) => {
  if (search_phrase === '') {
    return cases;
  }

  search_phrase = search_phrase.toLowerCase();
  const phrases = search_phrase.split(' ');

  let cases_list = [];
  let cases_match = [];
  let search_case_id = '';
  let search_first_name = '';
  let search_last_name = '';
  let search_last_name_raw = '';
  let search_doctor_first_name = '';
  let search_doctor_last_name = '';
  let search_assign_first_name = '';
  let search_assign_last_name = '';

  let phrase_case_id = '';
  let phrase_name = '';
  let phrase_doctor = '';
  let phrase_assign = '';

  _.each(phrases, (phrase) => {
    cases_match = [];

    if (phrase) {
      phrase_case_id = column_filter === 'caseid' || column_filter === '' ? phrase : '-----';
      phrase_name = column_filter === 'patient' || column_filter === '' ? phrase : '-----';
      phrase_doctor = column_filter === 'doctor' || column_filter === '' ? phrase : '-----';
      phrase_assign = column_filter === 'assign' || column_filter === '' ? phrase : '-----';

      for (let i = 0; i < cases.length; i++) {
        search_case_id = cases[i].case_id.toLowerCase();
        search_first_name = cases[i].first_name.toLowerCase();
        search_last_name = cases[i].last_name.toLowerCase();
        search_last_name_raw = cases[i].last_name_raw ? cases[i].last_name_raw.toLowerCase() : '';
        search_doctor_first_name = cases[i].doctor && cases[i].doctor.first_name ? cases[i].doctor.first_name.toLowerCase() : '';
        search_doctor_last_name = cases[i].doctor && cases[i].doctor.last_name ? cases[i].doctor.last_name.toLowerCase() : '';
        // search_assign_first_name = cases[i].assigned_to && cases[i].assigned_to.first_name ? cases[i].assigned_to.first_name.toLowerCase() : '';
        // search_assign_last_name = cases[i].assigned_to && cases[i].assigned_to.last_name ? cases[i].assigned_to.last_name.toLowerCase() : '';

        if (
          search_case_id.indexOf(phrase_case_id) >= 0 ||
          search_first_name.indexOf(phrase_name) >= 0 ||
          search_last_name.indexOf(phrase_name) >= 0 ||
          search_last_name_raw.indexOf(phrase_name) >= 0 ||
          search_doctor_first_name.indexOf(phrase_doctor) >= 0 ||
          search_doctor_last_name.indexOf(phrase_doctor) >= 0 ||
          search_assign_first_name.indexOf(phrase_assign) >= 0 ||
          search_assign_last_name.indexOf(phrase_assign) >= 0
        ) {
          cases_match.push(cases[i]);
        }
      }

      if (cases_list.length === 0) {
        //duplicate array
        cases_list = cases_match.slice();
      } else {
        cases_list = _.intersectionBy(cases_list, cases_match, 'case_id');
      }
    }
  });

  return cases_list;
};

const getSmilesFromSearch = (smiles, search_phrase, column_filter) => {
  if (search_phrase === '') {
    return smiles;
  }

  search_phrase = search_phrase.toLowerCase();
  const phrases = search_phrase.split(' ');

  let smile_list = [];
  let smiles_match = [];

  let search_smile_id = '';
  let search_first_name = '';
  let search_last_name = '';
  let search_doctor_first_name = '';
  let search_doctor_last_name = '';
  let doctor__user__first_name = '';

  let phrase_smile_id = '';
  let phrase_name = '';
  let phrase_doctor = '';

  _.each(phrases, (phrase) => {
    smiles_match = [];

    if (phrase) {
      phrase_smile_id = column_filter === 'smileid' || column_filter === '' ? phrase : '-----';
      phrase_name = column_filter === 'patient' || column_filter === '' ? phrase : '-----';
      phrase_doctor = column_filter === 'doctor' || column_filter === '' ? phrase : '-----';

      for (let i = 0; i < smiles.length; i++) {
        search_smile_id = smiles[i].smile_id.toLowerCase();
        search_first_name = smiles[i].smile_first_name.toLowerCase();
        search_last_name = smiles[i].smile_last_name.toLowerCase();
        search_doctor_first_name = smiles[i].assigned_doctor__first_name ? smiles[i].assigned_doctor__first_name.toLowerCase() : '';
        search_doctor_last_name = smiles[i].assigned_doctor__last_name ? smiles[i].assigned_doctor__last_name.toLowerCase() : '';
        doctor__user__first_name = smiles[i].doctor__user__first_name ? smiles[i].doctor__user__first_name.toLowerCase() : '';

        if (
          search_smile_id.indexOf(phrase_smile_id) >= 0 ||
          search_first_name.indexOf(phrase_name) >= 0 ||
          search_last_name.indexOf(phrase_name) >= 0 ||
          search_doctor_first_name.indexOf(phrase_doctor) >= 0 ||
          search_doctor_last_name.indexOf(phrase_doctor) >= 0 ||
          doctor__user__first_name.indexOf(phrase_doctor) >= 0
        ) {
          smiles_match.push(smiles[i]);
        }
      }

      if (smile_list.length === 0) {
        //duplicate array
        smile_list = smiles_match.slice();
      } else {
        smile_list = _.intersectionBy(smile_list, smiles_match, 'smile_id');
      }
    }
  });

  return smile_list;
};

/**
 * Retrieves list of batches based on search query
 * @function
 * @param batches {Array} - Full batch list
 * @param search_phrase {String} - Search query
 * @return {Array} - List of batches based on search
 */
const getBatchesFromSearch = (batches, search_phrase) => {
  if (search_phrase === '') {
    return batches;
  }

  search_phrase = search_phrase.toLowerCase();
  const phrases = search_phrase.split(' ');

  let batches_list = [];
  let batches_match = [];
  let search_batch_id = '';

  _.each(phrases, (phrase) => {
    batches_match = [];

    if (phrase) {
      for (let i = 0; i < batches.length; i++) {
        search_batch_id = batches[i].batch_id;
        if (search_batch_id.indexOf(phrase) >= 0) {
          batches_match.push(batches[i]);
        }
      }

      if (batches_list.length === 0) {
        batches_list = batches_match.slice();
      } else {
        batches_list = _.intersectionBy(batches_list, batches_match, 'batch_id');
      }
    }
  });

  return batches_list;
};

const getItemRequestsFromStatus = (item_requests, status) => {
  if (status === '') {
    return item_requests;
  }

  let ir_list = [];

  status = getIrStatusFilterDefinition(status);

  for (let i = 0; i < item_requests.length; i++) {
    if (item_requests[i].ireq_status === status) {
      ir_list.push(item_requests[i]);
    }
  }

  return ir_list;
};

const getItemRequestsFromStartDate = (item_requests, start_date) => {
  if (start_date === '') {
    return item_requests;
  }

  let ir_list = [];
  start_date = new Date(moment.tz(start_date + ' 00:00:00', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < item_requests.length; i++) {
    if (item_requests[i].submission_date_val >= start_date) {
      ir_list.push(item_requests[i]);
    }
  }

  return ir_list;
};

const getItemRequestsFromEndDate = (item_requests, end_date) => {
  if (end_date === '') {
    return item_requests;
  }

  let ir_list = [];
  end_date = new Date(moment.tz(end_date + ' 23:59:59', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < item_requests.length; i++) {
    if (item_requests[i].submission_date_val <= end_date) {
      ir_list.push(item_requests[i]);
    }
  }

  return ir_list;
};

const getItemRequestsFromFilter = (item_requests, filter_code) => {
  let item_request = [];

  for (let i = 0; i < item_requests.length; i++) {
    if (_.intersection(item_requests[i]['filter_codes'], [filter_code]).length > 0) {
      item_request.push(item_requests[i]);
    }
  }
  return item_request;
};

/**
 * Search result alg for the business side
 * @function
 * @param {Object} item_requests - List of IRs
 * @param {String} search_phrase - Search parameter to be used
 * @param {String} column_filter - Selected filter to apply with search param
 * @return {Object} returns a filter version of the IR against search criteria
 */
const getItemRequestsFromSearch = (item_requests, search_phrase, column_filter) => {
  if (search_phrase === '') {
    return item_requests;
  }

  search_phrase = search_phrase.toLowerCase();
  const phrases = search_phrase.split(' ');

  let ir_list = [];
  let ir_match = [];
  let search_ireq_id = '';
  let search_ireq_id_filter = '';
  let search_first_name = '';
  let search_last_name = '';
  let search_doctor_first_name = '';
  let search_doctor_last_name = '';
  let search_assign_first_name = '';
  let search_assign_last_name = '';

  let phrase_ireq_id = '';
  let phrase_name = '';
  let phrase_doctor = '';
  let phrase_assign = '';

  _.each(phrases, (phrase) => {
    ir_match = [];

    if (phrase) {
      phrase_ireq_id = column_filter === 'ireqid' || column_filter === '' ? phrase : '-----';
      phrase_name = column_filter === 'patient' || column_filter === '' ? phrase : '-----';
      phrase_doctor = column_filter === 'doctor' || column_filter === '' ? phrase : '-----';
      phrase_assign = column_filter === 'assign' || column_filter === '' ? phrase : '-----';

      for (let i = 0; i < item_requests.length; i++) {
        search_ireq_id = item_requests[i].ireq_id.toLowerCase();
        search_ireq_id_filter = search_ireq_id.replace('-de1', '-de').replace('-ir1', '-ir').replace('-de0', '-de10').replace('-ir0', '-ir10');
        search_first_name = item_requests[i].first_name.toLowerCase();
        search_last_name = item_requests[i].last_name.toLowerCase();
        search_doctor_first_name =
          item_requests[i].doctor_first_name && item_requests[i].doctor_first_name ? item_requests[i].doctor_first_name.toLowerCase() : '';
        search_doctor_last_name = item_requests[i].doctor_last_name && item_requests[i].doctor_last_name ? item_requests[i].doctor_last_name.toLowerCase() : '';
        if (
          search_ireq_id.indexOf(phrase_ireq_id) >= 0 ||
          search_ireq_id_filter.indexOf(phrase_ireq_id) >= 0 ||
          search_first_name.indexOf(phrase_name) >= 0 ||
          search_last_name.indexOf(phrase_name) >= 0 ||
          search_doctor_first_name.indexOf(phrase_doctor) >= 0 ||
          search_doctor_last_name.indexOf(phrase_doctor) >= 0 ||
          search_assign_first_name.indexOf(phrase_assign) >= 0 ||
          search_assign_last_name.indexOf(phrase_assign) >= 0
        ) {
          ir_match.push(item_requests[i]);
        }
      }

      if (ir_list.length === 0) {
        //duplicate array
        ir_list = ir_match.slice();
      } else {
        ir_list = _.intersectionBy(ir_list, ir_match, 'ireq_id');
      }
    }
  });

  return ir_list;
};

/**
 * Filters Progress Record out only the based on filter code
 *
 * @function
 * @param {Array} progress_records - Array of Progress Records
 * @param {Array} filter_code - Code use to determine list from progress Records
 * @param {Number} user_id - Id of user
 * @param {Array} assigned_csr - List of assigned CSR
 * @param {Array} assigned_pr - List of assigned PR
 */
const getProgressRecordsFromFilter = (progress_records, filter_code, user_id, assigned_csr, assigned_pr) => {
  let progress_record = [];

  for (let i = 0; i < progress_records.length; i++) {
    if (filter_code === 'assigned_me_csr' && assigned_csr) {
      for (let j = 0; j < assigned_csr.length; j++) {
        if (progress_records[i]['progress_id'] === assigned_csr[j]['progress_status__progress__progress_id'] && user_id === assigned_csr[j]['assignment_id']) {
          progress_record.push(progress_records[i]);
        }
      }
    } else if (filter_code === 'assigned_me_pr' && assigned_pr) {
      for (let j = 0; j < assigned_pr.length; j++) {
        if (progress_records[i]['progress_id'] === assigned_pr[j]['progress_status__progress__progress_id'] && user_id === assigned_pr[j]['assignment_id']) {
          progress_record.push(progress_records[i]);
        }
      }
    } else if (filter_code === 'all' || _.intersection(progress_records[i]['filter_codes'], [filter_code]).length > 0) {
      progress_record.push(progress_records[i]);
    }
  }
  return progress_record;
};

const getProgressRecordsFromSearch = (progress_records, search_phrase, column_filter) => {
  if (search_phrase === '') {
    return progress_records;
  }

  search_phrase = search_phrase.toLowerCase();
  const phrases = search_phrase.split(' ');

  let pr_list = [];
  let pr_match = [];
  let search_first_name = '';
  let search_last_name = '';
  let search_doctor_first_name = '';
  let search_doctor_last_name = '';
  let search_case_id = '';

  let phrase_name = '';
  let phrase_doctor = '';
  let phrase_case_id = '';

  _.each(phrases, (phrase) => {
    pr_match = [];

    if (phrase) {
      phrase_name = column_filter === 'patient' || column_filter === '' ? phrase : '-----';
      phrase_doctor = column_filter === 'doctor' || column_filter === '' ? phrase : '-----';
      phrase_case_id = column_filter === 'case_id' || column_filter === '' ? phrase : '-----';

      for (let i = 0; i < progress_records.length; i++) {
        search_case_id = progress_records[i].case_id.toLowerCase();
        search_first_name = progress_records[i].first_name.toLowerCase();
        search_last_name = progress_records[i].last_name.toLowerCase();
        search_doctor_first_name =
          progress_records[i].doctor_first_name && progress_records[i].doctor_first_name ? progress_records[i].doctor_first_name.toLowerCase() : '';
        search_doctor_last_name =
          progress_records[i].doctor_last_name && progress_records[i].doctor_last_name ? progress_records[i].doctor_last_name.toLowerCase() : '';
        if (
          search_case_id.indexOf(phrase_case_id) >= 0 ||
          search_first_name.indexOf(phrase_name) >= 0 ||
          search_last_name.indexOf(phrase_name) >= 0 ||
          search_doctor_first_name.indexOf(phrase_doctor) >= 0 ||
          search_doctor_last_name.indexOf(phrase_doctor) >= 0
        ) {
          pr_match.push(progress_records[i]);
        }
      }

      if (pr_list.length === 0) {
        //duplicate array
        pr_list = pr_match.slice();
      } else {
        pr_list = _.intersectionBy(pr_list, pr_match, 'case_id');
      }
    }
  });

  return pr_list;
};

const getProgressRecordsFromStartDate = (progress_records, start_date) => {
  if (start_date === '') {
    return progress_records;
  }

  let pr_list = [];
  start_date = new Date(moment.tz(start_date + ' 00:00:00', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < progress_records.length; i++) {
    if (progress_records[i].created_date_val >= start_date) {
      pr_list.push(progress_records[i]);
    }
  }

  return pr_list;
};

const getProgressRecordsFromEndDate = (progress_records, end_date) => {
  if (end_date === '') {
    return progress_records;
  }

  let pr_list = [];
  end_date = new Date(moment.tz(end_date + ' 23:59:59', 'America/Los_Angeles').format()).getTime();

  for (let i = 0; i < progress_records.length; i++) {
    if (progress_records[i].created_date_val <= end_date) {
      pr_list.push(progress_records[i]);
    }
  }

  return pr_list;
};

const getStatusFilterDefinition = (status) => {
  const statuses = {
    checking: translateStatus('INBRACE Checking Files'),
    upload: translateStatus('Doctor Upload New Files'),
    new: translateStatus('Doctor Upload New Files'),
    prepare: translateStatus('INBRACE Preparing Setup'),
    approve: translateStatus('Doctor Approve or Revise Setup'),
    setup: translateStatus('Doctor Approve or Revise Setup'),
    manufacture: translateStatus('INBRACE Manufacturing Case'),
    shipped: translateStatus('Case Shipped'),
    ship: translateStatus('Case Shipped'),
    hold: translateStatus('Hold'),
    cancel: translateStatus('Cancelled'),
    'INBRACE Checking Files': translateStatus('INBRACE Checking Files'),
    'Doctor Upload New Files': translateStatus('Doctor Upload New Files'),
    'INBRACE Preparing Setup': translateStatus('INBRACE Preparing Setup'),
    'Doctor Approve or Revise Setup': translateStatus('Doctor Approve or Revise Setup'),
    'INBRACE Manufacturing Case': translateStatus('INBRACE Manufacturing Case'),
    'Case Shipped': translateStatus('Case Shipped'),
    'Awaiting Folder Generation': 'Awaiting Folder Generation',
    'Folder Generation Success': 'Folder Generation Success',
    'Folder Generation Failed': 'Folder Generation Failed',
    'Segmentation Process': 'Segmentation Process',
    'Setup Review': 'Smile Design Review',
    'Revise Setup': 'Revise Smile Design',
    'Setup Ready for Upload': 'Smile Design Ready for Upload',
    'Setup Conversion Process': 'Smile Design Conversion Process',
    'Setup Conversion Failed': 'Smile Design Conversion Failed',
    'Setup Ready for Release': 'Smile Design Ready for Release',
    'CAD Process': 'CAD Process',
    'Quality Control Review': 'Quality Control Review',
    folder_generation: 'Awaiting Folder Generation',
    folder_generation_success: 'Folder Generation Success',
    folder_generation_failed: 'Folder Generation Failed',
    segmentation_process: 'Segmentation Process',
    setup_review: 'Smile Design Review',
    revise_setup: 'Revise Smile Design',
    setup_ready_upload: 'Smile Design Ready for Upload',
    setup_conversion: 'Smile Design Conversion Process',
    setup_conversion_failed: 'Smile Design Conversion Failed',
    setup_ready_for_release: 'Smile Design Ready for Release',
    final_design_upload: 'Final Design Upload',
    final_design_conversion: 'Final Design in Conversion',
    final_design_conversion_failed: 'Conversion Failed',
    final_design_ready_for_release: 'Release Final Design',
    cad_process: 'CAD Process',
    qc_approval: 'Quality Control Review',
    Hold: translateStatus('Hold'),
    Cancelled: translateStatus('Cancelled'),
  };

  return statuses[status];
};

const getSmileStatusFilterDefinition = (status) => {
  const statuses = {
    checking: 'InBrace Verifying Toothprints',
    new: 'Doctor Upload New Toothprints',
    setup_ready_upload: 'Smile Simulation Ready for Upload',
    setup_conversion: 'Smile Simulation Conversion Process',
    setup_conversion_failed: 'Smile Simulation Conversion Failed',
    setup_ready_for_release: 'Smile Simulation Ready for Release',
    released: 'Smile Simulation Released',
    cancel: 'Cancelled',
    'INBRACE Checking Files': 'InBrace Verifying Toothprints',
    'Doctor Upload New Files': 'Doctor Upload New Toothprints',
    'Setup Ready for Upload': 'Smile Simulation Ready for Upload',
    'Setup Conversion Process': 'Smile Simulation Conversion Process',
    'Setup Conversion Failed': 'Smile Simulation Conversion Failed',
    'Setup Ready for Release': 'Smile Simulation Ready for Release',
    'Resume Case Submission': 'Smile Simulation Released',
    lost: 'Prospect Lost',
    submitted: 'Case Submitted',
    Released: 'Smile Simulation Released',
    Cancelled: 'Cancelled',
  };

  return statuses[status] ? statuses[status] : status;
};

const getSmileStatusFilterDefinitionIPP = (status) => {
  const statuses = {
    'INBRACE Checking Files': 'Verifying Toothprints',
    'Doctor Upload New Files': 'Upload New Toothprints',
    'Setup Ready for Upload': 'Preparing Simulation',
    'Setup Ready for Reupload': 'Preparing Simulation',
    'Setup Conversion Process': 'Preparing Simulation',
    'Setup Conversion Failed': 'Preparing Simulation',
    'Setup Ready for Release': 'Preparing Simulation',
    'Prospect Lost': 'Prospect Lost',
    'Resume Case Submission': 'Resume Submission',
    'Case Submitted': 'Case Submitted',
    Released: 'Begin Submission',
    Cancelled: 'Cancelled',
  };

  return statuses[status] ? statuses[status] : status;
};

const getIrStatusFilterDefinition = (status) => {
  const statuses = {
    manufacture: translateStatus('INBRACE Manufacturing Item Request'),
    prepare: translateStatus('INBRACE Manufacturing Item Request'),
    shipped: translateStatus('Item Request Shipped'),
    ship: translateStatus('Item Request Shipped'),
    hold: 'Item Request On Hold',
    cancel: 'Item Request Cancelled',
    review: translateStatus('INBRACE Reviewing Request'),
  };

  return statuses[status];
};

const getSortValue = (sort) => {
  return sort ? 'asc' : 'desc';
};

const orderCasesBySubmissionDate = (cases, sort) => {
  return _.orderBy(cases, ['submission_date'], [sort]);
};

const orderItemRequestsBySubmissionDate = (item_request, sort) => {
  return _.orderBy(item_request, ['created_date'], [sort]);
};

const orderProgressRecordsBySubmissionDate = (progress_record, sort) => {
  return _.orderBy(progress_record, ['created_date'], [sort]);
};

/**
 * Orders list of batches by their date
 * @function
 * @param batches {Array} - Full batch list
 * @param sort {String} - Ascending or descending
 * @return {Array} - List of sorted batches
 */
const orderBatchesByDate = (batches, sort) => {
  return _.orderBy(batches, ['date'], [sort]);
};

const setOrderByMethod = (cases_view, sort_method, sort) => {
  const sort_value = getSortValue(sort);

  switch (sort_method) {
    case 'submission_date':
      cases_view = orderCasesBySubmissionDate(cases_view, sort_value);
      break;
    default:
  }

  return cases_view;
};
/**
 * Sanitized search for search field text area
 * @function
 * @param that {Object} - Contains parent the ref object
 * @param event {Object} - Contains the event that is being called
 * @param callBack {Function} - Contains a function to be called after completing function
 */
const onChangeSearch = (that, event, callBack = () => {}) => {
  const text = removeEmoji(safeSearchChar(textFieldCheck(event.target.value)));

  that.setState(
    {
      search: text,
    },
    callBack
  );
};

const translateDoctorName = (cases) => {
  if (cases.doctor) {
    return `Dr. ${cases.doctor.first_name} ${cases.doctor.last_name}`;
  } else {
    return '';
  }
};

const translateAssignedDoctorName = (smile) => {
  if (smile.assigned_doctor__first_name) {
    return smile.doctor_id === smile.assigned_doctor_id
      ? `Dr. ${smile.assigned_doctor__first_name} ${smile.assigned_doctor__last_name}`
      : `Dr. ${smile.assigned_doctor__first_name} ${smile.assigned_doctor__last_name} - ${smile.doctor__user__first_name}`;
  } else {
    return '';
  }
};

const translatePatientName = (cases) => {
  return `${cases.first_name} ${cases.last_name}`;
};

const translateSmilePatientName = (smile) => {
  return `${smile.smile_first_name} ${smile.smile_last_name}`;
};

const translateSmileStatus = (smile) => {
  return getSmileStatusFilterDefinition(smile.smile_status);
};

const translateSmileSubmissionDate = (smile) => {
  return translateDate(moment.tz(smile.smile_submitted_date, 'America/Los_Angeles'));
};

const translateSmileCreatedDate = (smile) => {
  return translateDate(moment.tz(smile.created_date, 'America/Los_Angeles'));
};

const translateAssignToName = (cases) => {
  if (cases.assigned_to) {
    return `${cases.assigned_to.first_name} ${cases.assigned_to.last_name}`;
  } else {
    return '';
  }
};

const translateDate = (date) => {
  return new Date(date).getTime();
};

const translateCaseDoctorActionDate = (cases) => {
  return translateDate(cases.doctor_action_date);
};

const translateCaseDoctorActionDateTimeZone = (cases) => {
  return moment.tz(cases.doctor_action_date, 'America/Los_Angeles').format();
};

const translateCaseDoctorNewUploadDate = (cases) => {
  return translateDate(cases.doctor_new_record_date);
};

const translateCaseDoctorNewUploadDateTimeZone = (cases) => {
  return moment.tz(cases.doctor_new_record_date, 'America/Los_Angeles').format();
};

const translateCaseDoctorSetupDate = (cases) => {
  return translateDate(cases.doctor_setup_date);
};

const translateCaseDoctorSetupDateTimeZone = (cases) => {
  return moment.tz(cases.doctor_setup_date, 'America/Los_Angeles').format();
};

const translateCaseShippedDate = (cases) => {
  return translateDate(cases.shipping_date);
};

const translateCaseShippedDateTimeZone = (cases) => {
  return moment.tz(cases.shipping_date, 'America/Los_Angeles').format();
};

const translateCaseSubmissionDate = (cases) => {
  return translateDate(cases.submission_date);
};

const translateCaseSubmissionDateTimeZone = (cases) => {
  return moment.tz(cases.submission_date, 'America/Los_Angeles').format();
};

const translateCaseSetupApprovalDate = (cases) => {
  return translateDate(cases.approval_date);
};

const translateCaseSetupApprovalDateTimeZone = (cases) => {
  return moment.tz(cases.approval_date, 'America/Los_Angeles').format();
};

const translateCase = (cases) => {
  //Does any neccessary translation for the cases here
  for (let i = 0; i < cases.length; i++) {
    cases[i].doctor_name = translateDoctorName(cases[i]);
    cases[i].patient_name = translatePatientName(cases[i]);
    cases[i].assigned_to_name = translateAssignToName(cases[i]);
    cases[i].status = translateCaseStatus(cases[i]);
    cases[i].status_priority = translateCaseStatusPriority(cases[i]);
    cases[i].submission_date = translateCaseSubmissionDateTimeZone(cases[i]);
    cases[i].submission_date_val = translateCaseSubmissionDate(cases[i]);
    cases[i].submission_date_priority = translateCaseSubmissionPriority(cases[i]);
    cases[i].shipped_date_val = translateCaseShippedDate(cases[i]);
    cases[i].shipped_date = translateCaseShippedDateTimeZone(cases[i]);
    cases[i].setup_approval_date_val = translateCaseSetupApprovalDate(cases[i]);
    cases[i].setup_approval_date = translateCaseSetupApprovalDateTimeZone(cases[i]);
    cases[i].doctor_action_date_val = translateCaseDoctorActionDate(cases[i]);
    cases[i].doctor_action_date = translateCaseDoctorActionDateTimeZone(cases[i]);
    cases[i].doctor_new_record_date_val = translateCaseDoctorNewUploadDate(cases[i]);
    cases[i].doctor_new_record_date = translateCaseDoctorNewUploadDateTimeZone(cases[i]);
    cases[i].doctor_setup_date_val = translateCaseDoctorSetupDate(cases[i]);
    cases[i].doctor_setup_date = translateCaseDoctorSetupDateTimeZone(cases[i]);
  }

  return cases;
};

const translateSmile = (smiles) => {
  for (let i = 0; i < smiles.length; i++) {
    smiles[i].doctor_name = translateAssignedDoctorName(smiles[i]);
    smiles[i].patient_name = translateSmilePatientName(smiles[i]);
    smiles[i].status = translateSmileStatus(smiles[i]);
    smiles[i].status_priority = translateSmileStatusPriority(smiles[i]);
    smiles[i].submission_date_val = translateSmileSubmissionDate(smiles[i]);
    smiles[i].created_date_val = translateSmileCreatedDate(smiles[i]);
  }

  return smiles;
};

const translateCaseStatusPriority = (cases) => {
  const status_priority_definition = getStatusPriorityDefinition();
  const last_priority = 10;
  return status_priority_definition[cases.status_code] ? status_priority_definition[cases.status_code] : last_priority;
};

const translateSmileStatusPriority = (smile) => {
  const status_priority_definition = getSmileStatusPriorityDefinition();
  const last_priority = 10;
  return status_priority_definition[smile.status] ? status_priority_definition[smile.status] : last_priority;
};

const translateCaseSubmissionPriority = (cases) => {
  const date_in_milliseconds = new Date(cases.submission_date).getTime();
  return cases.status_priority >= getStatusPriorityDefinition('CASE_SHIP') ? -date_in_milliseconds : date_in_milliseconds;
};

const translateFilterStatus = (status, filter) => {
  switch (filter) {
    case 'checking':
    case 'review':
    case 'new':
    case 'prepare':
    case 'setup':
    case 'setup_ready_for_release':
    case 'released':
    case 'manufacture':
    case 'ship':
    case 'hold':
    case 'cancel':
      status = filter;
      break;
    default:
      break;
  }

  return status;
};

const removeIncompleteCases = (cases) => {
  let new_cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].status_code !== 'STATUS_DRAFT_SUB' && cases[i].status_code !== 'STATUS_DRAFT_DE') {
      new_cases_list.push(cases[i]);
    }
  }

  return new_cases_list;
};

const InitialManufacturingCases = (cases) => {
  let new_cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (
      (cases[i].status_code === 'STATUS_MANUFACTURE' ||
        cases[i].status_code === 'CAD Process' ||
        cases[i].status_code === 'Quality Control Review' ||
        isPostApprovalStage(cases[i].status_code, false)) &&
      !cases[i].case_id.includes('-DE')
    ) {
      new_cases_list.push(cases[i]);
    }
  }

  return new_cases_list;
};

/**
 * Gets list of initial held cases from list of cases
 * @function
 * @param cases {Array} - Full case list
 * @return {Array} - List of cases with right conditions
 */
const InitialOnHoldCases = (cases) => {
  let new_cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].status_code === 'STATUS_HOLD' && !cases[i].case_id.includes('-DE')) {
      new_cases_list.push(cases[i]);
    }
  }
  return new_cases_list;
};

/**
 * Gets list of initial held cases with credit hold from list of cases
 * @function
 * @param cases {Array} - Full case list
 * @return {Array} - List of cases with right conditions
 */
const InitialOnCreditHoldCases = (cases) => {
  let new_cases_list = [];

  for (let i = 0; i < cases.length; i++) {
    if (cases[i].status_code === 'STATUS_HOLD' && cases[i].hold_description === 'Credit Hold' && !cases[i].case_id.includes('-DE')) {
      new_cases_list.push(cases[i]);
    }
  }
  return new_cases_list;
};

const getCasesFromRow = (cases_view, page, row_size) => {
  const start_index = (Number(page) - 1) * Number(row_size);
  const end_index = start_index + Number(row_size);

  return cases_view.slice(start_index, end_index);
};

const getItemRequestsFromRow = (ir_view, page, row_size) => {
  const start_index = (Number(page) - 1) * Number(row_size);
  const end_index = start_index + Number(row_size);

  return ir_view.slice(start_index, end_index);
};

const getProgressRecordsFromRow = (pr_view, page, row_size) => {
  const start_index = (Number(page) - 1) * Number(row_size);
  const end_index = start_index + Number(row_size);

  return pr_view.slice(start_index, end_index);
};

/**
 * Gets list of batches from row
 * @function
 * @param batches_view {Array} - List of batches
 * @param page {String} - Current page
 * @param row_size {String} - Size of the row
 * @return {Array} - List of batches from row
 */
const getBatchesFromRow = (batches_view, page, row_size) => {
  const start_index = (Number(page) - 1) * Number(row_size);
  const end_index = start_index + Number(row_size);

  return batches_view.slice(start_index, end_index);
};

// eslint-disable-next-line
const onPageChange = (active_page) => {
  //Allow for page changes without when filters are not applied yet
  const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
  const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
  const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
  const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
  const filter = getBusinessFilterValueFromRoute();

  let cases_view = filter
    ? removeIncompleteCases(getCasesFromFilter(this.state.cases_raw, filter, this.state.user_id))
    : removeIncompleteCases(
        getCasesFromSearch(
          this.getCasesFromEndDate(getCasesFromStartDate(getCasesFromStatus(this.state.cases_raw, status), start_date), end_date),
          this.state.search,
          column
        )
      );

  this.setState({
    cases_active_page: active_page,
    cases_view: getCasesFromRow(orderCasesByDefault(this, cases_view), active_page, this.state.cases_row_size),
  });
};

const onApplySearch = (that) => {
  that.props.history.replace(that.setRoute(that.state.column_filter, 'col'));
  that.props.history.replace(that.setRoute(that.state.status_filter, 'status'));
  that.props.history.replace(that.setRoute(that.state.start_date_filter, 'start'));
  that.props.history.replace(that.setRoute(that.state.end_date_filter, 'end'));
  that.props.history.replace(that.setRoute(that.state.search, 'search'));

  const search = getBusinessSearchValueFromRoute();
  const filter = getBusinessFilterValueFromRoute();
  const column = getBusinessColumnValueFromRoute();
  const status = getBusinessStatusValueFromRoute();
  const start_date = getBusinessStartDateValueFromRoute();
  const end_date = getBusinessEndDateValueFromRoute();

  const active_page = 1;
  const row_size = that.state.cases_row_size;
  let cases_view = [];

  if (start_date && end_date && start_date > end_date) {
    that.setState({
      submission_date_error: true,
    });
  } else {
    cases_view = filter
      ? removeIncompleteCases(getCasesFromFilter(that.state.cases_raw, filter, that.state.user_id))
      : removeIncompleteCases(
          getCasesFromSearch(getCasesFromEndDate(getCasesFromStartDate(getCasesFromStatus(that.state.cases_raw, status), start_date), end_date), search, column)
        );

    that.setState({
      cases_view: getCasesFromRow(orderCasesByDefault(that, cases_view), active_page, row_size),
      cases_active_page: active_page,
      cases_length: cases_view.length,
      search: search,
      filter: filter,
      column_filter: column,
      status_filter: status,
      start_date_filter: start_date,
      end_date_filter: end_date,
      submission_date_error: false,
    });
  }
};

const onApplySearchShipping = (that) => {
  that.props.history.replace(that.setRoute(that.state.search, 'search'));

  const search = getBusinessSearchValueFromRoute();
  const filter = getBusinessFilterValueFromRoute();
  const column = getBusinessColumnValueFromRoute();

  const active_page = 1;
  const row_size = that.state.cases_row_size;
  let cases_view = [];

  cases_view = removeIncompleteCases(getCasesFromSearch(getShippingCasesFromFilter(that.state.cases_raw, filter), search, column));

  that.setState({
    cases_view: getCasesFromRow(orderCasesByDefault(that, cases_view), active_page, row_size),
    cases_active_page: active_page,
    cases_length: cases_view.length,
    search: search,
    filter: filter,
    column_filter: column,
  });
};

/**
 * Handles when user clicks on search button in batch list
 * @function
 */
const onApplySearchBatch = (that) => {
  that.props.history.replace(that.setRoute(that.state.search, 'search'));

  const search = getBusinessSearchValueFromRoute();

  const active_page = 1;
  const row_size = that.state.batches_row_size;
  let batches_view = [];

  batches_view = getBatchesFromSearch(that.state.batches_raw, search);

  that.setState({
    batches_view: getBatchesFromRow(orderBatchesByDefault(that, batches_view), active_page, row_size),
    batches_active_page: active_page,
    batches_length: batches_view.length,
    search: search,
  });
};

const applyItemRequestSearch = (that) => {
  that.props.history.replace(that.setRoute(that.state.column_filter, 'col'));
  that.props.history.replace(that.setRoute(that.state.status_filter, 'status'));
  that.props.history.replace(that.setRoute(that.state.start_date_filter, 'start'));
  that.props.history.replace(that.setRoute(that.state.end_date_filter, 'end'));
  that.props.history.replace(that.setRoute(that.state.search, 'search'));

  const ir_sort_method = that.state.ir_sort_method;

  const search = getBusinessSearchValueFromRoute();
  const filter = getBusinessFilterValueFromRoute();
  const column = getBusinessColumnValueFromRoute();
  const status = getBusinessStatusValueFromRoute();
  const start_date = getBusinessStartDateValueFromRoute();
  const end_date = getBusinessEndDateValueFromRoute();

  const active_page = 1;
  const row_size = that.state.ir_row_size;
  let ir_view = [];

  if (start_date && end_date && start_date > end_date) {
    that.setState({
      submission_date_error: true,
    });
  } else {
    ir_view = filter
      ? getItemRequestsFromFilter(that.state.ir_raw, filter, that.state.user_id)
      : getItemRequestsFromSearch(
          getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(that.state.ir_raw, status), start_date), end_date),
          search,
          column
        );

    that.setState({
      ir_view: that.setOrderByMethod(
        getItemRequestsFromRow(orderItemRequestsByDefault(that, ir_view), active_page, row_size),
        ir_sort_method,
        that.state.ir_sort_order_ascending[ir_sort_method]
      ),
      ir_length: ir_view.length,
      cases_active_page: active_page,
      cases_length: ir_view.length,
      search: search,
      filter: filter,
      column_filter: column,
      status_filter: status,
      start_date_filter: start_date,
      end_date_filter: end_date,
      submission_date_error: false,
    });
  }
};

/**
 * Search that is applied to progress records
 *
 * @function
 * @param {Object} that - Contains a component properties
 */
const applyProgressRecordSearch = (that) => {
  that.props.history.replace(that.setRoute(that.state.column_filter, 'col'));
  that.props.history.replace(that.setRoute(that.state.start_date_filter, 'start'));
  that.props.history.replace(that.setRoute(that.state.end_date_filter, 'end'));
  that.props.history.replace(that.setRoute(that.state.search, 'search'));

  const pr_sort_method = that.state.pr_sort_method;

  const search = getBusinessSearchValueFromRoute();
  const filter = getBusinessFilterValueFromRoute();
  const column = getBusinessColumnValueFromRoute();
  const status = getBusinessStatusValueFromRoute();
  const start_date = getBusinessStartDateValueFromRoute();
  const end_date = getBusinessEndDateValueFromRoute();

  const active_page = 1;
  const row_size = that.state.pr_row_size;
  let pr_view = [];

  if (start_date && end_date && start_date > end_date) {
    that.setState({
      submission_date_error: true,
    });
  } else {
    pr_view = filter
      ? getProgressRecordsFromFilter(that.state.pr_raw, filter, that.state.user_id, that.state.assigned_csr, that.state.assigned_pr)
      : getProgressRecordsFromSearch(getProgressRecordsFromEndDate(getProgressRecordsFromStartDate(that.state.pr_raw, start_date), end_date), search, column);

    that.setState({
      pr_view: that.setOrderByMethod(
        getProgressRecordsFromRow(orderProgressRecordsByDefault(that, pr_view), active_page, row_size),
        pr_sort_method,
        that.state.pr_sort_order_ascending[pr_sort_method]
      ),
      pr_length: pr_view.length,
      cases_active_page: active_page,
      cases_length: pr_view.length,
      search: search,
      filter: filter,
      column_filter: column,
      status_filter: status,
      start_date_filter: start_date,
      end_date_filter: end_date,
    });
  }
};

const orderCasesByDefault = (that, cases) => {
  const sort = getSortValue(that.state.cases_sort_order_ascending['submission_date']);
  return orderCasesBySubmissionDate(cases, sort);
};

const orderItemRequestsByDefault = (that, item_requests) => {
  const sort = getSortValue(that.state.ir_sort_order_ascending['created_date']);
  return orderItemRequestsBySubmissionDate(item_requests, sort);
};

const orderProgressRecordsByDefault = (that, progress_records) => {
  const sort = getSortValue(that.state.pr_sort_order_ascending['created_date']);
  return orderProgressRecordsBySubmissionDate(progress_records, sort);
};
/**
 * A message that indentifies that loading is not yet complete
 *
 * @param {String} search_type - Type of search to update wording and phrasing
 * @return {JSX} Returns loading still in progress
 */
const notAllLoaded = (search_type) => {
  return (
    <div className="result-none">
      <div>
        <CircleLoader />
      </div>
      <br />
      <div className="text-center">{`Loading ${search_type}...`}</div>
    </div>
  );
};
/**
 * Results in returning search message error
 *
 * @param {String} search_val - Value of the user trying to use as a search criteria
 * @return {String} Returns search error message
 */
const searchErrorMessage = (search_val, search_type, className = 'result-none') => {
  return (
    <div className={className}>
      <div>
        {`Sorry, we couldn't find any ${search_type} in our system that match your search`}
        {search_val ? (
          <span>
            {' '}
            for <span className="bold-text fs-exclude">{`"${search_val}"`}</span>.
          </span>
        ) : (
          '.'
        )}
      </div>
      <br />
      <div>
        Suggestions:
        <ul className="dash-list">
          <li>Check your spelling and try again</li>
          <li>Try different keywords</li>
          <li>Try different filters</li>
        </ul>
      </div>
    </div>
  );
};
/**
 * Update Configuration setting with state values
 *
 * @param {String} that - References the component properties
 * @param {String} config - Configuration for the Progress Loading Content
 * @return {String} Returns the updated configuration
 */
const loadConfigWithState = (that, config) => {
  config.search = that.state.search ? that.state.search : '';
  config.column = that.state.column_filter ? that.state.column_filter : '';
  config.status = that.state.status_filter ? that.state.status_filter : '';
  config.start_date = that.state.start_date ? that.state.start_date : '';
  config.end_date = that.state.end_date ? that.state.end_date : '';
  config.filter = that.state.filter ? that.state.filter : '';

  return config;
};
/**
 * Update configuration and double rate of pulling
 *
 * @param {String} config - Configuration for the Progress Loading Content
 * @return {String} Returns the updated configuration
 */
const updateLoadConfiguration = (config) => {
  const DOUBLE = 2;

  config.offset = config.offset + config.rate;
  config.rate = config.rate * DOUBLE > config.total ? config.total : config.rate * DOUBLE;
  config.limit = config.limit + config.rate;

  return config;
};

/**
 * Order batches by default sort method
 * @function
 * @param batches {Array} - Full batch list
 * @return {Array} - List of ordered batches
 */
const orderBatchesByDefault = (that, batches) => {
  const sort = getSortValue(that.state.batches_sort_order_ascending['date']);
  return orderBatchesByDate(batches, sort);
};

export {
  applyItemRequestSearch,
  applyProgressRecordSearch,
  getBatchesFromRow,
  getBatchesFromSearch,
  getCasesFromEndDate,
  getCasesFromFilter,
  getCasesFromRow,
  getCasesFromSearch,
  getCasesFromStartDate,
  getCasesFromStatus,
  getIrStatusFilterDefinition,
  getItemRequestsFromEndDate,
  getItemRequestsFromFilter,
  getItemRequestsFromRow,
  getItemRequestsFromSearch,
  getItemRequestsFromStartDate,
  getItemRequestsFromStatus,
  getProgressRecordsFromEndDate,
  getProgressRecordsFromFilter,
  getProgressRecordsFromRow,
  getProgressRecordsFromSearch,
  getProgressRecordsFromStartDate,
  getShippingCasesFromFilter,
  getSmilesFromFilter,
  getSmilesFromSearch,
  getSmilesFromStatus,
  getSmileStatusFilterDefinition,
  getSmileStatusFilterDefinitionIPP,
  getSortValue,
  InitialManufacturingCases,
  InitialOnCreditHoldCases,
  InitialOnHoldCases,
  loadConfigWithState,
  notAllLoaded,
  onApplySearch,
  onApplySearchBatch,
  onApplySearchShipping,
  onChangeSearch,
  orderBatchesByDefault,
  orderCasesByDefault,
  orderCasesBySubmissionDate,
  orderItemRequestsByDefault,
  orderProgressRecordsByDefault,
  orderProgressRecordsBySubmissionDate,
  removeIncompleteCases,
  searchErrorMessage,
  setOrderByMethod,
  translateCase,
  translateDate,
  translateFilterStatus,
  translateSmile,
  translateSmileStatus,
  updateLoadConfiguration,
};
