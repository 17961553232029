import Axios from 'axios';
import { parsePhoneNumber } from 'libphonenumber-js';
import { formatPhoneNumber, removeEmoji } from '../../../common/functions';
import Utils from '../../../doctor/account_settings/utils';

// Action Types
export const FETCH_ACCOUNT_INFORMATION_PENDING = 'FETCH_ACCOUNT_INFORMATION_PENDING';
export const FETCH_ACCOUNT_INFORMATION_PENDING_WITH_LOADER = 'FETCH_ACCOUNT_INFORMATION_PENDING_WITH_LOADER';
export const FETCH_ACCOUNT_INFORMATION_SUCCESS = 'FETCH_ACCOUNT_INFORMATION_SUCCESS';
export const FETCH_ACCOUNT_INFORMATION_ERROR = 'FETCH_ACCOUNT_INFORMATION_ERROR';
export const SAVE_ROLE_PENDING = 'SAVE_ROLE_PENDING';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const SAVE_ROLE_ERROR = 'SAVE_ROLE_ERROR';
export const EDIT_PROVIDER_ID = 'EDIT_PROVIDEr_ID';
export const SET_PROVIDER_ID = 'SET_PROVIDER_ID';
export const SAVE_PROVIDER_ID_PENDING = 'SAVE_PROVIDER_ID_PENDING';
export const SAVE_PROVIDER_ID_SUCCESS = 'SAVE_PROVIDER_ID_SUCCESS';
export const SAVE_PROVIDER_ID_ERROR = 'SAVE_PROVIDER_ID_ERROR';
export const SET_EDIT_PROFILE = 'SET_EDIT_PROFILE';
export const SAVE_EDIT_CHANGES_PENDING = 'SAVE_EDIT_CHANGES_PENDING';
export const SAVE_EDIT_CHANGES_SUCCESS = 'SAVE_EDIT_CHANGES_SUCCESS';
export const SAVE_EDIT_CHANGES_ERROR = 'SAVE_EDIT_CHANGES_ERROR';
export const SET_EDIT_ERROR_MESSAGE = 'SET_EDIT_ERROR_MESSAGE';
export const SET_NEW_PROFILE = 'SET_NEW_PROFILE';
export const SET_NEW_ERROR_MESSAGE = 'SET_NEW_ERROR_MESSAGE';
export const SAVE_NEW_CHANGES_PENDING = 'SAVE_NEW_CHANGES_PENDING';
export const SAVE_NEW_CHANGES_SUCCESS = 'SAVE_NEW_CHANGES_SUCCESS';
export const SAVE_NEW_CHANGES_ERROR = 'SAVE_NEW_CHANGES_ERROR';
export const SHOW_404_NOT_FOUND = 'SHOW_404_NOT_FOUND';
export const OPEN_DISABLE_ACCOUNT_MODAL = 'OPEN_DISABLE_ACCOUNT_MODAL';
export const CLOSE_DISABLE_ACCOUNT_MODAL = 'CLOSE_DISABLE_ACCOUNT_MODAL';
export const OPEN_RESET_LINK_ACCOUNT_MODAL = 'OPEN_RESET_LINK_ACCOUNT_MODAL';
export const CLOSE_RESET_LINK_ACCOUNT_MODAL = 'CLOSE_RESET_LINK_ACCOUNT_MODAL';
export const FETCH_DSO_INFORMATION_PENDING = 'FETCH_DSO_INFORMATION_PENDING';
export const FETCH_DSO_INFORMATION_SUCCESS = 'FETCH_DSO_INFORMATION_SUCCESS';
export const FETCH_DSO_INFORMATION_ERROR = 'FETCH_DSO_INFORMATION_ERROR';
export const SET_DOCTOR_DROPDOWN_VALUE_CASES = 'SET_DOCTOR_DROPDOWN_VALUE_CASES';
export const SET_DOCTOR_DROPDOWN_VALUE_SMILES = 'SET_DOCTOR_DROPDOWN_VALUE_SMILES';
export const SET_MISSING_DOCTOR_ERROR = 'SET_MISSING_DOCTOR_ERROR';
export const DISABLE_ACCOUNT_PENDING = 'DISABLE_ACCOUNT_PENDING';
export const DISABLE_ACCOUNT_SUCCESS = 'DISABLE_ACCOUNT_SUCCESS';
export const DISABLE_ACCOUNT_ERROR = 'DISABLE_ACCOUNT_ERROR';
export const ENABLE_ACCOUNT_PENDING = 'ENABLE_ACCOUNT_PENDING';
export const ENABLE_ACCOUNT_SUCCESS = 'ENABLE_ACCOUNT_SUCCESS';
export const ENABLE_ACCOUNT_ERROR = 'ENABLE_ACCOUNT_ERROR';
export const ON_MOUNT = 'ON_MOUNT';

// Action Creators
// -----------------------------------------------------------------------------
export function onMount(account) {
  return {
    type: ON_MOUNT,
  };
}

// Get account and doctor info

export function fetchAccountInformation(doctor_id, edit_doctor_id, show_loader) {
  return (dispatch) => {
    dispatch(fetchAccountInformationPending(show_loader));
    dispatch(fetchDoctor(doctor_id));

    Axios.get(`/apiV2/account/${edit_doctor_id}`)
      .then(function (result) {
        if (result.data.account_link_id.toString() !== doctor_id.toString()) {
          dispatch(fetchAccountInformationError());
        } else {
          dispatch(fetchAccountInformationSuccess(result.data));
        }
      })
      .catch(function (err) {
        dispatch(fetchAccountInformationError());
      });
  };
}

export function fetchDoctor(doctor_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/doctor`)
      .then(function (response) {
        if (response && response.data && response.data.doctor && response.data.doctor.length > 0) {
          if (
            (response.data.doctor[0].role !== 'Business' && response.data.doctor[0].role !== 'DSO') ||
            (response.data.doctor[0].role === 'DSO' && response.data.doctor[0].id.toString() !== doctor_id.toString())
          ) {
            dispatch(fetchAccountInformationError());
          }
        } else {
          dispatch(fetchAccountInformationError());
        }
      })
      .catch(function (err) {
        dispatch(fetchAccountInformationError());
      });
  };
}

export function fetchAccountInformationPending(show_loader) {
  if (show_loader) {
    return {
      type: FETCH_ACCOUNT_INFORMATION_PENDING_WITH_LOADER,
    };
  } else {
    return {
      type: FETCH_ACCOUNT_INFORMATION_PENDING,
    };
  }
}

export function fetchAccountInformationSuccess(account) {
  return {
    type: FETCH_ACCOUNT_INFORMATION_SUCCESS,
    account: account,
  };
}
export function fetchAccountInformationError() {
  return {
    type: FETCH_ACCOUNT_INFORMATION_ERROR,
  };
}

export function fetchDsoInformation(doctor_id) {
  return (dispatch) => {
    dispatch(fetchDsoInformationPending());
    Axios.get(`/apiv3/doctor/${doctor_id}/dso`)
      .then(function (response) {
        dispatch(fetchDsoInformationSuccess(response.data));
      })
      .catch(function (err) {
        dispatch(fetchDsoInformationError());
      });
  };
}

export function fetchDsoInformationPending() {
  return {
    type: FETCH_DSO_INFORMATION_PENDING,
  };
}
export function fetchDsoInformationSuccess(dso_info) {
  return {
    type: FETCH_DSO_INFORMATION_SUCCESS,
    dso_info: dso_info,
  };
}
export function fetchDsoInformationError(error) {
  return {
    type: FETCH_DSO_INFORMATION_ERROR,
  };
}

// Edit account details

export function onRoleChange(event) {
  return (dispatch, getState) => {
    const { account } = getState().editAccountReducer;

    dispatch(saveRolePending(event.value));

    let data = new FormData();

    data.append('value', event.value);

    Axios.post(`/apiV2/accountaction/${account.account_id}/update_role`, data)
      .then(function (response) {
        dispatch(saveRoleSuccess());
      })
      .catch(function (err) {
        dispatch(saveRoleError(err));
      });
  };
}

export function saveRolePending(role) {
  return {
    type: SAVE_ROLE_PENDING,
    role: role,
  };
}
export function saveRoleSuccess() {
  return {
    type: SAVE_ROLE_SUCCESS,
  };
}
export function saveRoleError(error) {
  return {
    type: SAVE_ROLE_ERROR,
    error: error,
  };
}

export function toggleProviderIdEdit(edit_provider_id) {
  return (dispatch, getState) => {
    const { provider_id, provider_id_old } = getState().editAccountReducer;
    dispatch(setOldProviderId(edit_provider_id, provider_id, provider_id_old));
  };
}

export function setOldProviderId(edit_provider_id, provider_id, provider_id_old) {
  return {
    type: EDIT_PROVIDER_ID,
    edit_provider_id: edit_provider_id,
    provider_id_old: edit_provider_id ? provider_id : '',
    provider_id: edit_provider_id ? provider_id : provider_id_old,
  };
}

export function setProviderId(id) {
  return {
    type: SET_PROVIDER_ID,
    provider_id: id,
  };
}

export function onProviderIdChange(event) {
  return (dispatch, getState) => {
    dispatch(setProviderId(removeEmoji(event.target.value)));
  };
}

export function saveProviderId() {
  return (dispatch, getState) => {
    const { provider_id } = getState().editAccountReducer;
    const { account } = getState().editAccountReducer;

    dispatch(saveProviderIdPending());

    let data = new FormData();

    data.append('value', provider_id);

    Axios.post(`/apiV2/accountaction/${account.account_id}/update_provider_id`, data)
      .then(function (response) {
        dispatch(saveProviderIdSuccess());
      })
      .catch(function (err) {
        dispatch(saveProviderIdError(err));
      });
  };
}

export function saveProviderIdPending() {
  return {
    type: SAVE_PROVIDER_ID_PENDING,
  };
}
export function saveProviderIdSuccess() {
  return {
    type: SAVE_PROVIDER_ID_SUCCESS,
  };
}
export function saveProviderIdError(error) {
  return {
    type: SAVE_PROVIDER_ID_ERROR,
    error: error,
  };
}

export function setEditMode(mode, contact_details) {
  return (dispatch, getState) => {
    const { edit_profile } = getState().editAccountReducer;
    const { new_profile } = getState().editAccountReducer;

    if (edit_profile.mode === '' && new_profile.mode === '') {
      dispatch({
        type: SET_EDIT_PROFILE,
        edit_profile: {
          id: contact_details.id,
          mode: mode,
          value: contact_details.value,
          type: contact_details.type,
          notification: contact_details.notification,
          error: false,
          is_active: contact_details.is_active,
        },
      });
    }
  };
}

export function setEditValue(key, value) {
  return (dispatch, getState) => {
    const { edit_profile } = getState().editAccountReducer;

    let new_value = edit_profile.value;
    let notification = edit_profile.notification;
    let type = edit_profile.type;

    switch (key) {
      case 'type':
        type = value;
        break;

      case 'notification':
        notification = value;
        break;

      case 'value':
        new_value = value.target.value;
        break;

      default:
    }

    dispatch({
      type: SET_EDIT_PROFILE,
      edit_profile: {
        id: edit_profile.id,
        active: edit_profile.is_active,
        mode: edit_profile.mode,
        value: new_value,
        type: type,
        notification: notification,
        error: false,
        is_active: edit_profile.is_active,
      },
    });
  };
}

export function clearEditMode() {
  return (dispatch) => {
    dispatch({
      type: SET_EDIT_PROFILE,
      edit_profile: {
        id: '',
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        is_active: true,
      },
    });

    dispatch({ type: SET_EDIT_ERROR_MESSAGE, edit_error_msg: '' });
  };
}

export function saveEditChanges() {
  return (dispatch, getState) => {
    const { edit_profile } = getState().editAccountReducer;
    const { account } = getState().editAccountReducer;

    dispatch(saveEditChangesPending());

    const id = edit_profile.id;
    const old_value = getOldValueByMode(account, edit_profile.mode, edit_profile.id);
    const new_type = edit_profile.type;
    const old_type = getOldTypeByMode(account, edit_profile.mode, edit_profile.id);
    const notification = edit_profile.notification;
    const old_notification = getOldNotificationByMode(account, edit_profile.mode, edit_profile.id);
    const mode = edit_profile.mode;
    const action = getActionByMode(edit_profile.mode);

    let new_value = edit_profile.value;
    new_value = formatValueByMode(mode, new_value);

    if (
      old_value !== undefined &&
      new_value !== undefined &&
      ((isValidPhoneNo(new_value) && new_type !== '' && isNewPhoneNo(account, new_value, id) && mode === 'edit_number') ||
        (Utils.isValidEmail(new_value) && new_type !== '' && isNewEmail(account, new_value, id) && mode === 'edit_email'))
    ) {
      let data = new FormData();

      // eslint-disable-next-line
      let old_address = [];

      data.append('value', new_value);
      data.append('old_value', old_value);
      data.append('type', new_type);
      data.append('old_type', old_type);
      data.append('notification', notification);
      data.append('old_notification', old_notification);
      data.append('id', id);

      Axios.post(`/apiV2/accountaction/${account.account_id}/${action}`, data)
        .then(function (res) {
          dispatch(saveEditChangesSuccess(res.data));
        })
        .catch(function (err) {
          dispatch(saveEditChangesError());
        });
    } else {
      dispatch({
        type: SET_EDIT_PROFILE,
        edit_profile: {
          id: edit_profile.id,
          mode: edit_profile.mode,
          notification: edit_profile.notification,
          value: edit_profile.value,
          type: edit_profile.type,
          error: true,
          is_active: edit_profile.is_active,
        },
      });

      dispatch({
        type: SET_EDIT_ERROR_MESSAGE,
        edit_error_msg:
          edit_profile.mode === 'edit_number' && !isNewPhoneNo(account, new_value)
            ? 'Telephone number already exists'
            : edit_profile.mode === 'edit_email' && !isNewEmail(account, new_value)
            ? 'Email address already exists'
            : '',
      });
    }
  };
}

export function saveEditChangesPending() {
  return {
    type: SAVE_EDIT_CHANGES_PENDING,
  };
}
export function saveEditChangesSuccess(account) {
  return {
    type: SAVE_EDIT_CHANGES_SUCCESS,
    account: account,
    edit_profile: {
      id: '',
      mode: '',
      value: '',
      type: '',
      notification: false,
      error: false,
      is_active: true,
    },
  };
}
export function saveEditChangesError(error) {
  return {
    type: SHOW_404_NOT_FOUND,
    error: error,
  };
}

export function deleteEntry() {
  return (dispatch, getState) => {
    const { edit_profile } = getState().editAccountReducer;
    const { account } = getState().editAccountReducer;

    dispatch(saveEditChangesPending());

    const id = edit_profile.id;
    const old_value = getOldValueByMode(account, edit_profile.mode, edit_profile.id);
    const old_type = getOldTypeByMode(account, edit_profile.mode, edit_profile.id);
    const action = getActionByModeDelete(edit_profile.mode);

    let data = new FormData();
    data.append('value', old_value);
    data.append('type', old_type);
    data.append('id', id);

    Axios.post(`/apiV2/accountaction/${account.account_id}/${action}`, data)
      .then(function (res) {
        dispatch(saveEditChangesSuccess(res.data));
      })
      .catch(function (err) {
        dispatch(saveEditChangesError());
      });
  };
}

export function setAddMode(mode) {
  return (dispatch, getState) => {
    const { new_profile } = getState().editAccountReducer;

    dispatch({
      type: SET_NEW_PROFILE,
      new_profile: {
        mode: mode,
        value: new_profile.value,
        type: new_profile.type,
        notification: new_profile.notification,
        error: false,
        is_active: new_profile.is_active,
      },
    });
    dispatch({
      type: SET_NEW_ERROR_MESSAGE,
      new_error_msg: '',
    });
  };
}

export function setNewValue(key, value) {
  return (dispatch, getState) => {
    const { new_profile } = getState().editAccountReducer;

    let new_value = new_profile.value;
    let notification = new_profile.notification;
    let type = new_profile.type;

    switch (key) {
      case 'type':
        type = value;
        break;

      case 'notification':
        notification = value;
        break;

      case 'value':
        new_value = value.target.value;
        break;

      default:
    }

    dispatch({
      type: SET_NEW_PROFILE,
      new_profile: {
        mode: new_profile.mode,
        value: new_value,
        type: type,
        notification: notification,
        error: false,
        is_active: new_profile.is_active,
      },
    });
    dispatch({
      type: SET_NEW_ERROR_MESSAGE,
      new_error_msg: '',
    });
  };
}

export function clearNewMode() {
  return (dispatch, getState) => {
    dispatch({
      type: SET_NEW_PROFILE,
      new_profile: {
        id: '',
        mode: '',
        value: '',
        type: 'Office',
        notification: true,
        error: false,
        is_active: true,
      },
    });
    dispatch({
      type: SET_NEW_ERROR_MESSAGE,
      new_error_msg: '',
    });
  };
}

export function saveNewChanges() {
  return (dispatch, getState) => {
    const { new_profile } = getState().editAccountReducer;
    const { account } = getState().editAccountReducer;

    const type = new_profile.type;
    const lastest_id = getLatestNumberId(account);
    const notification = new_profile.notification;
    const mode = new_profile.mode;
    let value = new_profile.value;

    dispatch(saveNewChangesPending());

    if (
      (isValidPhoneNo(value) && type !== '' && mode === 'add_number' && isNewPhoneNo(account, value)) ||
      (Utils.isValidEmail(value) && isNewEmail(account, value) && mode === 'add_email')
    ) {
      value = formatValueByMode(mode, value);
      let action = getAddActionByMode(mode);

      let data = new FormData();
      data.append('id', lastest_id);
      data.append('notification', notification);
      data.append('type', type);
      data.append('value', value.trim());

      Axios.post(`/apiV2/accountaction/${account.account_id}/${action}`, data)
        .then(function (res) {
          dispatch(saveNewChangesSuccess(res.data));
        })
        .catch(function (err) {
          if (err.response.status === 409) {
            dispatch({
              type: SET_NEW_PROFILE,
              new_profile: {
                error: true,
                mode: new_profile.mode,
                notification: new_profile.notification,
                type: new_profile.type,
                value: new_profile.value,
                is_active: new_profile.is_active,
              },
            });
            dispatch({
              type: SET_NEW_ERROR_MESSAGE,
              new_error_msg:
                new_profile.mode === 'add_number' && !isNewPhoneNo(account, value)
                  ? 'Telephone number already exists'
                  : new_profile.mode === 'add_email' && !isNewEmail(account, value)
                  ? 'Email address already exists'
                  : '',
            });
            dispatch(saveNewChangesError());
          } else {
            dispatch(show404NotFound());
          }
        });
    } else {
      dispatch({
        type: SET_NEW_PROFILE,
        new_profile: {
          error: true,
          mode: new_profile.mode,
          notification: new_profile.notification,
          type: new_profile.type,
          value: new_profile.value,
          is_active: new_profile.is_active,
        },
      });
      dispatch({
        type: SET_NEW_ERROR_MESSAGE,
        new_error_msg:
          new_profile.mode === 'add_number' && !isNewPhoneNo(account, value)
            ? 'Telephone number already exists'
            : new_profile.mode === 'add_email' && !isNewEmail(account, value)
            ? 'Email address already exists'
            : '',
      });
    }
  };
}

export function saveNewChangesPending() {
  return {
    type: SAVE_NEW_CHANGES_PENDING,
  };
}
export function saveNewChangesSuccess(account) {
  return {
    type: SAVE_NEW_CHANGES_SUCCESS,
    account: account,
    new_profile: {
      mode: '',
      value: '',
      type: 'Office',
      notification: true,
      error: false,
      is_active: true,
    },
    ner_error_msg: '',
  };
}
export function saveNewChangesError(error) {
  return {
    type: SAVE_NEW_CHANGES_ERROR,
  };
}

export function show404NotFound() {
  return {
    type: SHOW_404_NOT_FOUND,
  };
}

// Disabling/enabling accounts

export function onEditProfileToggleAccount(is_enabled) {
  return (dispatch) => {
    if (is_enabled) {
      dispatch({
        type: OPEN_DISABLE_ACCOUNT_MODAL,
      });
    } else {
      dispatch(onEnableAccountClick());
    }
  };
}

export function closeDisableAccountModal() {
  return {
    type: CLOSE_DISABLE_ACCOUNT_MODAL,
  };
}

export function openResetLinkAccountModal() {
  return {
    type: OPEN_RESET_LINK_ACCOUNT_MODAL,
  };
}

export function closeResetLinkAccountModal() {
  return {
    type: CLOSE_RESET_LINK_ACCOUNT_MODAL,
  };
}

export function onDoctorDisableSelectCases(event) {
  return {
    type: SET_DOCTOR_DROPDOWN_VALUE_CASES,
    selected_doctor_id_cases: event.target.value,
  };
}

export function onDoctorDisableSelectSmiles(event) {
  return {
    type: SET_DOCTOR_DROPDOWN_VALUE_SMILES,
    selected_doctor_id_smiles: event.target.value,
  };
}

export function onDisableAccountClick() {
  return (dispatch, getState) => {
    const { selected_doctor_id_cases, selected_doctor_id_smiles } = getState().editAccountReducer;
    const { account, dso_info } = getState().editAccountReducer;
    const dso_admin_smile_permission = dso_info && dso_info.doctor && dso_info.doctor.length > 0 && dso_info.doctor[0].smile;
    let active_smile_doctors_count = 1;
    active_smile_doctors_count = dso_info
      ? dso_info.smile_doctors.filter((doctor) => {
          return doctor.user__is_active === true;
        }).length
      : active_smile_doctors_count;

    if (
      (!selected_doctor_id_cases && account.cases.length > 0) ||
      (!selected_doctor_id_smiles && active_smile_doctors_count > 1 && account.smile_simulations.length > 0 && dso_admin_smile_permission)
    ) {
      dispatch({
        type: SET_MISSING_DOCTOR_ERROR,
      });
    } else {
      dispatch(disableAccountPending());

      Axios.post(`/apiV2/accountaction/${account.account_id}/disable`)
        .then(function (account_action_res) {
          if (account.cases.length > 0 || (account.smile_simulations && account.smile_simulations.length > 0 && dso_admin_smile_permission)) {
            const data = {
              account_link_id: account.account_link_id,
              old_doctor_id: account.account_id,
              new_doctor_id_cases: parseInt(selected_doctor_id_cases),
              new_doctor_id_smiles: parseInt(selected_doctor_id_smiles),
            };

            Axios.put(`/apiv3/doctorassignment/reassign`, data)
              .then(function (res) {
                dispatch(disableAccountSuccess());
                dispatch(fetchAccountInformation(account.account_link_id, account.account_id, false));
                dispatch(fetchDsoInformation(account.account_link_id));
              })
              .catch(function (err) {
                dispatch(disableAccountError(err));
              });
          } else {
            dispatch(disableAccountSuccess());
            dispatch(fetchAccountInformation(account.account_link_id, account.account_id, false));
            dispatch(fetchDsoInformation(account.account_link_id));
          }
        })
        .catch(function (account_action_err) {
          dispatch(disableAccountError(account_action_err));
        });
    }
  };
}

export function disableAccountPending() {
  return {
    type: DISABLE_ACCOUNT_PENDING,
  };
}
export function disableAccountSuccess() {
  return {
    type: DISABLE_ACCOUNT_SUCCESS,
  };
}
export function disableAccountError(error) {
  return {
    type: DISABLE_ACCOUNT_ERROR,
    error: error,
  };
}

export function onEnableAccountClick() {
  return (dispatch, getState) => {
    const { account } = getState().editAccountReducer;

    dispatch(enableAccountPending());

    Axios.post(`/apiV2/accountaction/${account.account_id}/enable`)
      .then(function (account_action_res) {
        dispatch(enableAccountSuccess());
        dispatch(fetchAccountInformation(account.account_link_id, account.account_id, false));
        dispatch(fetchDsoInformation(account.account_link_id));
      })
      .catch(function (account_action_err) {
        dispatch(enableAccountError(account_action_err));
      });
  };
}
export function enableAccountPending() {
  return {
    type: ENABLE_ACCOUNT_PENDING,
  };
}
export function enableAccountSuccess() {
  return {
    type: ENABLE_ACCOUNT_SUCCESS,
  };
}
export function enableAccountError(error) {
  return {
    type: ENABLE_ACCOUNT_ERROR,
    error: error,
  };
}

// Helper Functions

function isValidPhoneNo(value) {
  let is_valid = false;

  try {
    const usa_number = parsePhoneNumber(value, 'US');
    const valid_number = Utils.isValidPhoneNo(value);

    if (valid_number && usa_number.isValid()) {
      is_valid = true;
    }
  } catch (error) {
    // Do Nothing
  }

  return is_valid;
}

function formatValueByMode(mode, value) {
  let new_value = value;

  switch (mode) {
    case 'add_number':
    case 'edit_number':
      const usa_number = parsePhoneNumber(value, 'US');
      new_value = usa_number.format('NATIONAL');
      break;
    default:
  }

  return new_value;
}

function isNewPhoneNo(account, value, id = '') {
  const phones = account.phone_numbers;
  let is_new = true;

  for (let i = 0; i < phones.length; i++) {
    if (phones[i].value === formatPhoneNumber(value)) {
      if (phones[i].id === id) {
        //Do nothing
      } else {
        is_new = false;
        break;
      }
    }
  }

  return is_new;
}

function isNewEmail(account, value, id = '') {
  const emails = account.notification_emails;
  let is_new = true;

  for (let i = 0; i < emails.length; i++) {
    if (emails[i].value === value) {
      if (emails[i].id === id) {
        //Do nothing
      } else {
        is_new = false;
        break;
      }
    }
  }

  return is_new;
}

function getActionByMode(mode) {
  let action = '';

  switch (mode) {
    case 'edit_number':
      action = 'update_number';
      break;

    case 'edit_email':
      action = 'update_email';
      break;

    default:
  }

  return action;
}

function getOldValueById(id, list) {
  let result = '';

  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      result = list[i].value ? list[i].value : list[i].address_name ? list[i].address_name : '';
    }
  }

  return result;
}

function getOldValueByMode(account, mode, id) {
  let old_value = '';
  let list = [];

  switch (mode) {
    case 'edit_number':
      list = account.phone_numbers;
      old_value = getOldValueById(id, list);
      break;

    case 'edit_email':
      list = account.notification_emails;
      old_value = getOldValueById(id, list);
      break;

    default:
  }

  return old_value;
}

function getOldTypeByMode(account, mode, id) {
  let old_type = '';
  let list = [];

  switch (mode) {
    case 'edit_number':
      list = account.phone_numbers;
      break;

    case 'edit_email':
      list = account.notification_emails;
      break;

    default:
  }

  old_type = getOldTypeById(id, list);

  return old_type;
}

function getOldTypeById(id, list) {
  let result = '';

  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      result = list[i].type;
    }
  }

  return result;
}

function getOldNotificationByMode(account, mode, id) {
  let old_notification = '';

  switch (mode) {
    case 'edit_email':
      old_notification = getOldNotificationById(account, id);
      break;

    default:
  }

  return old_notification;
}

function getOldNotificationById(account, id) {
  const notifications = account.notification_emails;
  let result = '';

  for (let i = 0; i < notifications.length; i++) {
    if (notifications[i].id === id) {
      result = notifications[i].notification;
      break;
    }
  }

  return result;
}

function getActionByModeDelete(mode) {
  let action = '';

  switch (mode) {
    case 'edit_number':
      action = 'remove_number';
      break;
    case 'edit_email':
      action = 'remove_email';
      break;

    default:
  }

  return action;
}

function getLatestNumberId(account) {
  let id = '';

  // Data pass from backend is sorted by created date
  if (account.phone_numbers && account.phone_numbers.length > 0) {
    id = account.phone_numbers[account.phone_numbers.length - 1].id;
  }

  return id;
}

function getAddActionByMode(mode) {
  let action = mode;

  switch (mode) {
    case 'add_number':
    case 'add_email':
      action = mode;
      break;
    default:
  }

  return action;
}
