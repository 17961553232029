// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';

const SETTINGS_INFO = [
  { type: 'initial_case', name: 'Initial Case', description: 'Estimated Ship Date (from Smile Design approval)' },
  { type: 'digital_enhancement', name: 'Digital Enhancement', description: 'Estimated Ship Date (from Smile Design approval)' },
  { type: 'item_request', name: 'Item Request', description: 'Estimated Ship Date (from Item Request approval)' },
];

/**
 * Displays the external target date settings for a provider type
 * @alias ShippingInfo
 * @component
 * @category BPP
 * @subcategory External Target Date Info
 */
class ShippingInfo extends Component {
  /**
   * Displays input for target ship date
   * @function
   * @param setting {string} - Setting type
   * @return {JSX} - JSX for target ship date input
   */
  displayTargetShipDaysInput = (setting) => {
    return (
      <>
        <input
          className={`form-control days-input${this.props.invalid_settings.indexOf(setting) !== -1 ? ' days-input__error' : ''}`}
          data-type={setting}
          value={this.props.shipping_info_update[setting]}
          onChange={(e) => this.props.shippingInfoChange(setting, e.target.value)}
        />
        <span>Business Days</span>
      </>
    );
  };

  /**
   * Displays individual setting
   * @function
   * @param selected_setting_info {object} - Selected Settings Information
   * @return {JSX} - JSX for setting
   */
  displaySetting = () => {
    return SETTINGS_INFO.map((info, index) => (
      <div key={index} className="target-date external">
        <div className="bold-text settings-heading settings-heading__case">{info['name']}</div>
        <div className="bold-text settings-label">{info['description']}</div>
        {this.props.edit_mode ? (
          <div className="target-date__input">{this.displayTargetShipDaysInput(info['type'], this.props.shipping_info_update)}</div>
        ) : (
          <div className="target-date__display">{this.props.shipping_info_update[info['type']]}</div>
        )}
      </div>
    ));
  };

  /**
   * Displays settings buttons
   * @function
   * @return {JSX} - JSX for buttons
   */
  displaySettingsButtons = () => {
    return (
      <div className="target-date external">
        {this.props.edit_mode ? (
          <div className="btn-save-cancel">
            <button className="btn btn-light" onClick={this.props.onEditSave}>
              Save
            </button>
            <button className="btn btn-light" onClick={this.props.onEditCancel}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="btn-edit-settings external">
            <button className="btn btn-light" onClick={() => this.props.toggleEditMode(true)}>
              Edit
            </button>{' '}
            {this.showDeleteBtn() && (
              <button className="btn btn-light" onClick={this.props.showDeleteModal}>
                Delete
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  /**
   * Logic for showing and hiding delete btn for shipping detail
   * @function
   * @return {Boolean} - Only hide delete btn for top three shipping details and all DSO shipping details
   */
  showDeleteBtn = () => {
    const protected_account_types = ['Default', 'DSO - Tend', 'DSO - InStudio', 'DSO - Default'];
    const current_account_type = this.props.selected_setting_id ? this.props.getAccountType(this.props.selected_setting_id) : '';
    return !protected_account_types.includes(current_account_type) && !current_account_type.includes('DSO - ');
  };

  render() {
    return this.props.selected_setting_id ? (
      <>
        <div className="bold-text business-dark-heading">Details</div>
        {this.props.selected_setting_id && (
          <div className="business-dark-theme">
            {this.displaySetting()}
            {this.props.invalid_settings.length ? <div className="target-date-error">Please ensure the highlighted fields are greater than zero.</div> : null}
            {this.displaySettingsButtons()}
          </div>
        )}
      </>
    ) : null;
  }
}

export default ShippingInfo;
