import React from 'react';

/**
 * Displays collasped button to enlarge or minimized the side bar
 * @component
 * @alias SetupViewerMenuAccordianBtn
 */
export default function MenuAccordianBtn(props) {
  return (
    <div className={`panel-menu-accordian-btn ${props.className ? props.className : ''}`} onClick={props.toggleCollapse} onDoubleClick={props.toggleCollapse}>
      <i
        className={props.collapse ? 'fa fa-angle-double-right' : 'fa fa-angle-double-left'}
        aria-hidden="true"
        onClick={props.toggleCollapse}
        onDoubleClick={props.toggleCollapse}
      />
    </div>
  );
}
