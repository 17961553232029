// import { Collapse } from '@material-ui/core';
import { Collapse } from 'reactstrap';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps the children for collapse
 * @component
 */
function CollapsibleContainer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const className = props.containerClassName ? props.containerClassName : '';
  const text = isOpen ? props.openedText : props.collapsedText;
  const icon = <i className={isOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'} />;

  return (
    <div className={`${className} collapsible-container`}>
      <Collapse isOpen={isOpen}>{props.children}</Collapse>
      <div className="collapsible-toggle">
        <button aria-expanded={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {text} {icon}
        </button>
      </div>
    </div>
  );
}

CollapsibleContainer.propTypes = {
  collapsedText: PropTypes.string,
  openedText: PropTypes.string,
};

CollapsibleContainer.defaultProps = {
  collapsedText: 'Show',
  openedText: 'Hide',
};

export default CollapsibleContainer;
