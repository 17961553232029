import React from 'react';
import CardContainer from '../components/container/card_container';

/**
 * Component displaying empty content
 * @function
 * @return {JSX} JSX element to display no records
 */
const NoRecords = () => {
  return (
    <CardContainer type="top-border" className="hide-on-mobile--top-border no-records">
      <div className="div-table--heading-container">No records found</div>
    </CardContainer>
  );
};
export default NoRecords;
