import React from 'react';
import PropTypes from 'prop-types';

import SelectionBarItem from './selection_bar_item';
import SelectionBarPageRight from './selection_bar_page_right';
import SelectionBarPageLeft from './selection_bar_page_left';
import SelectionBarPageIndicator from './selection_bar_page_indicator';

/**
 * Displays the entire selection bar top
 * @component
 */
function SelectionBarTop(props) {
  const has_inbrace_ifs_setup =
    props.scroll_options?.filter((option) => {
      return option.is_inbrace_ifs_setup;
    }).length > 0;

  const inbrace_ifs_width_adjust = has_inbrace_ifs_setup ? 70 : 0;
  const selection_bar_width = props.scroll_options.length * 150 + inbrace_ifs_width_adjust;
  const left_margin = `calc(50% - ${selection_bar_width / 2}px)`;

  return (
    <div className={props.split ? 'selection-bar-top selection-bar-top-oov' : 'selection-bar-top'} style={{ width: selection_bar_width, left: left_margin }}>
      <div className={props.isSmileSimulationSetup ? 'smile-selection-bar-scroll-zone' : 'selection-bar-scroll-zone'}>
        <div
          className={
            props.isSmileSimulationSetup ? `smile-selection-bar-scroll-zone-area-${props.scroll_area}` : `selection-bar-scroll-zone-area-${props.scroll_area}`
          }
        >
          {props.scroll_options
            ? props.scroll_options.map((options, index) => {
                return <SelectionBarItem key={index} text={options.text} active={options.active_pane_1} onClick={props.onScrollOptionClick} pane={1} />;
              })
            : null}
        </div>
      </div>

      <SelectionBarPageLeft scroll_area={props.scroll_area} onClick={props.prevScrollArea} />
      <SelectionBarPageRight
        scroll_options={props.scroll_options}
        scroll_items={props.scroll_items}
        scroll_area={props.scroll_area}
        onClick={props.nextScrollArea}
      />
      <SelectionBarPageIndicator
        split={props.split}
        scroll_options={props.scroll_options}
        scroll_items={props.scroll_items}
        scroll_area={props.scroll_area}
        onClick={props.setScrollArea}
      />
    </div>
  );
}

SelectionBarTop.propTypes = {
  onScrollOptionClick: PropTypes.func.isRequired,
  scroll_area: PropTypes.number.isRequired,
  scroll_items: PropTypes.number.isRequired,
  scroll_options: PropTypes.array.isRequired,
  setScrollArea: PropTypes.func.isRequired,
  split: PropTypes.bool.isRequired,
};

export default SelectionBarTop;
