/**
 * Class that handles the animation loop
 */
class Loop {
  constructor(camera, scene, renderer) {
    this.camera = camera;
    this.scene = scene;
    this.renderer = renderer;
  }

  /**
   * Starts the animation loop
   * @function
   */
  start() {
    this.renderer.setAnimationLoop(this.animate);
  }

  /**
   * Stops the animation loop
   */
  stop() {
    this.renderer.setAnimationLoop(null);
  }

  /**
   * Renders a single frame
   */
  animate = () => {
    this.renderer.render(this.scene, this.camera);
  };
}

export { Loop };
