import './ss.scss';

import Axios from 'axios';
import React, { Component } from 'react';
import _ from 'lodash';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
// Internal Components
import Button from '../components/buttons/button';
import { handleHttpRequestError } from '../../common/error';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import DivTable from '../components/tables/div_table';
import Dropdown from '../components/inputs/dropdown';
import NoRecords from '../case_list/no_records';
import TextBox from '../components/inputs/text_box';
import SimulationFilter from './ss_filter';
import NoResult from '../case_list/no_result';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermissions, userHasPermission } from '../../common/permission';

// Internal Functions
// eslint-disable-next-line
import { setTokenHeader, convertDate, getRootPath, textFieldCheck, removeEmoji } from '../../common/functions';
import {
  getDoctorsIdFromRoute,
  getSearchValueFromRoute,
  getSearchFilterValueFromRoute,
  getSelectionValueFromRoute,
  getStatusFilterValueFromRoute,
} from '../../common/route';
import { capitalizeFirstLetter, getSubmissionDateDefaultSort } from '../../common/helpers';
import { tableRowLink } from '../../common/table';
import { getSmileStatusFilterDefinitionIPP, translateSmile } from '../../common/search';

/**
 * Used to displayed all smile simulations in a list format on the IPP.
 *
 * The smile simulation list page is where the users can click on a particular smile simulation to
 * get details on a smile simulation.
 * @component
 * @alias SimulationList
 * @category IPP
 */
class SimulationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      doctor_id: '',
      doctor_role: '',
      smile_permission: null,
      search: '',
      search_phrase: '',
      search_filter: '',
      doctor_filter: 'all',
      location_filter: '',
      filter: '',
      cases_raw: [],
      simulation_sort_method: 'created_date',
      simulation_sort_order_ascending: { smile_id: true, smile_name: true, created_date: false, smile_status: true },
      smile_doctors: [],
      simulation_list: [],
      simulation_list_doctor: {},
      simulation_list_location: {},
      simulation_raw: [],
      simulation_view: [],
      simulation_active_page: 1,
      simulation_page_range: 5,
      simulation_length: 1,
      simulation_row_size: 15,
      showCancelCase: false,
      selected_simulation_id: '',
      selected_first_name: '',
      selected_last_name: '',
      selection: '',
      search_filter_name: {
        action: 'Action Required',
        lost: 'Prospect Lost',
        submitted: 'Case Submitted',
        doctor_action: 'Upload New Toothprints',
        request_pending: 'Request Pending',
        released: 'Begin Submission',
        cancelled: 'Cancelled',
      },
      status_filter: '',
      statuses: {
        all: 0,
        action: 0,
        submitted: 0,
        lost: 0,
        doctor_action: 0,
        request_pending: 0,
        released: 0,
        cancelled: 0,
      },
      has_smile_simulations: false,
      refresh_list: false,
    };

    this.displaySearchBar.bind(this);
    this.onChangeSearchPatient.bind(this);
    this.onChangeRowSize = this.onChangeRowSize.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchFilterSelection = this.onSearchFilterSelection.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.fetchSmileList = this.fetchSmileList.bind(this);
    this.setStatuses = this.setStatuses.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    //Enable tooltip
    window.$('[data-toggle="tooltip"]').tooltip();
    window.$('.sub-item').click(() => this.onPageChange(1));
    this.fetchSmileList();
  }

  componentDidUpdate() {
    if (
      this.state.simulation_list.length > 0 &&
      (this.state.simulation_raw.length === 0 || this.state.simulation_list.length !== this.state.simulation_raw.length)
    ) {
      this.fetchSmileList();
    }
    const that = this;
    const state_search = this.state.search;
    const state_selection = this.state.selection;
    const state_search_filter = this.state.search_filter;
    const state_status_filter = this.state.status_filter;
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();
    const sort_method = this.state.simulation_sort_method;
    const simulation_sort_order_ascending = this.state.simulation_sort_order_ascending;
    const active_page = 1;
    const row_size = this.state.simulation_row_size;
    const refresh_list = this.state.refresh_list;
    const filter = status_filter || search_filter;
    let simulation_view = this.setOrderByMethod(
      this.getSimulationListFromFilter(
        this.getSimulationListFromSearch(this.getSimulationListFromSelection(this.state.simulation_raw, selection), search),
        filter
      ),
      sort_method,
      simulation_sort_order_ascending
    );

    if (
      state_search !== search ||
      state_search_filter !== search_filter ||
      state_status_filter !== status_filter ||
      state_selection !== selection ||
      refresh_list
    ) {
      this.setState({
        simulation_active_page: active_page,
        simulation_length: simulation_view.length,
        simulation_view: this.getSimulationListFromRow(simulation_view, active_page, row_size),
        search: search,
        search_filter: search_filter,
        search_phrase: search,
        filter: '',
        selection: selection,
        status_filter: status_filter,
        refresh_list: false,
      });
      this.setStatuses(selection);
    } else if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.reset_list &&
      this.props.history.location.state.reset_list === 'true'
    ) {
      this.props.history.replace({ search: this.props.history.location.search, state: { reset_list: 'false', filter: 'smile_simulation' } });
      const simulation_raw = that.state.simulation_raw;
      const simulation_view = that.setOrderByMethod(
        that.getSimulationListFromFilter(that.getSimulationListFromSearch(that.getSimulationListFromSelection(simulation_raw, ''), ''), ''),
        'created_date',
        false
      );

      that.setState({
        simulation_view: that.getSimulationListFromRow(simulation_view, active_page, row_size),
        simulation_active_page: active_page,
        simulation_sort_method: 'created_date',
        simulation_sort_order_ascending: { smile_id: true, smile_name: true, created_date: false, smile_status: true },
        search_phrase: '',
      });
      this.setStatuses(selection);
    }
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
  }

  /**
   * Sets Local State
   * @function
   */
  setLocalState(data) {
    const doctor_id = getDoctorsIdFromRoute();
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const filter = status_filter || search_filter;
    const selection = getSelectionValueFromRoute();
    const sort_method = this.state.simulation_sort_method;
    const simulation_sort_order_ascending = this.state.simulation_sort_order_ascending;
    const simulation_raw = translateSmile(data.smiles);
    const smile_doctors = data.smiledoctors.filter((doctor) => {
      return doctor.role !== 'DSO' && doctor.user__is_active;
    });
    // Create simulation list for Doctor and Location Views
    const simulation_list_doctor = {};
    const simulation_list_location = {};
    for (let smile of data.smiles) {
      const assigned_doctor_id = smile.assigned_doctor_id ? smile.assigned_doctor_id : 'unassigned';
      simulation_list_doctor[assigned_doctor_id]
        ? simulation_list_doctor[assigned_doctor_id].push(smile)
        : (simulation_list_doctor[assigned_doctor_id] = [smile]);
      simulation_list_location[smile.address.id]
        ? simulation_list_location[smile.address.id].push(smile)
        : (simulation_list_location[smile.address.id] = [smile]);
    }

    this.setState({
      loading: false,
      doctor_id: doctor_id,
      doctor_role: data.doctor_role,
      smile_permission: data.smilepermission,
      smile_doctors: smile_doctors,
      smile_addresses: data.smileaddresses,
      search: search,
      search_phrase: search,
      search_filter: search_filter,
      status_filter: status_filter,
      filter: filter,
      simulation_list: data.smiles,
      simulation_raw: simulation_raw,
      simulation_list_doctor: simulation_list_doctor,
      simulation_list_location: simulation_list_location,
      selection: selection,
      statuses: data.status_count,
      has_smile_simulations: data.assignedsmiles ? true : false,
    });
    this.setStatuses(selection);
    const simulation_view = this.setOrderByMethod(
      this.getSimulationListFromFilter(this.getSimulationListFromSearch(this.getSimulationListFromSelection(simulation_raw, selection), search), filter),
      sort_method,
      simulation_sort_order_ascending
    );
    this.setState({
      simulation_view: this.getSimulationListFromRow(simulation_view, this.state.simulation_active_page, this.state.simulation_row_size),
      simulation_length: simulation_view.length,
    });
  }

  componentWillUnmount() {
    // clean up jquery
    window.$('.sub-item').off('click');
  }

  fetchSmileList() {
    const that = this;
    Axios.get(`/apiv3/smilesimulation?doctor_id=${getDoctorsIdFromRoute()}`)
      .then(function (res) {
        that.setLocalState(res.data);
        return res;
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  /**
   * Changes search phrase once typed in new one in textbox
   * @param {Object} event - Input event listener
   * @function
   */
  onChangeSearchPatient = (event) => {
    let text = textFieldCheck(removeEmoji(event.target.value));
    this.setState({ search_phrase: text });
  };

  /**
   * Sets route with search phrase
   * @param {String} text - Search text
   * @function
   */
  setRouteBySearch(text) {
    const search_filter = this.state.search_filter;

    this.setRoute(text, search_filter);
    this.setState({ search_phrase: text });
  }

  /**
   * Sets route with filter terms
   * @param {String} filter - Search term using filter
   * @function
   */
  setRouteBySearchFilter(filter) {
    const search_phrase = this.state.search_phrase;

    this.setRoute(search_phrase, filter);
  }

  /**
   * Sets route with all the right filters and search terms
   * @param {String} search_phrase - Search term using filter
   * @param {String} search_filter - Search term using filter
   * @function
   */
  setRoute(search_phrase, search_filter) {
    this.fetchSmileList();
    const selection_value = getSelectionValueFromRoute();

    if (selection_value.includes('doctor') || selection_value.includes('location') || selection_value === 'all_cases') {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=smile_simulation`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=smile_simulation&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(
          `/portal/${this.state.doctor_id}?selection=${selection_value}&filter=smile_simulation&search=${search_phrase}&search_filter=${search_filter}`
        );
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=smile_simulation&search=${search_phrase}`);
      }
    } else {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=smile_simulation`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=smile_simulation&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=smile_simulation&search=${search_phrase}&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=smile_simulation&search=${search_phrase}`);
      }
    }
  }
  /**
   * Sets state for Smile Simulation Statuses
   * @param {string} selection phrase
   */
  setStatuses = (selection = '') => {
    const statuses = {
      all: 0,
      action: 0,
      lost: 0,
      submitted: 0,
      doctor_action: 0,
      request_pending: 0,
      released: 0,
      cancelled: 0,
    };
    let simulation_list = [];
    // Doctor ID
    if (selection.includes('doctor')) {
      const doctor_id = selection.substring(selection.indexOf('_') + 1);
      simulation_list = this.state.simulation_list_doctor && this.state.simulation_list_doctor[doctor_id] ? this.state.simulation_list_doctor[doctor_id] : [];
    }
    // Location
    else if (selection.includes('location')) {
      const location_id = selection.substring(selection.indexOf('_') + 1);
      simulation_list =
        this.state.simulation_list_location && this.state.simulation_list_location[location_id] ? this.state.simulation_list_location[location_id] : [];
    }
    // My Requests
    else if (this.state.doctor_role !== 'DSO' && selection === '') {
      simulation_list =
        this.state.simulation_list_doctor && this.state.simulation_list_doctor[this.state.doctor_id]
          ? this.state.simulation_list_doctor[this.state.doctor_id]
          : [];
    }
    // All Requests
    else if (selection === 'all_cases' || (this.state.doctor_role === 'DSO' && selection === '')) {
      simulation_list = this.state.simulation_raw;
    }
    for (let smile of simulation_list) {
      switch (smile.smile_status) {
        case 'Setup Ready for Upload':
        case 'INBRACE Checking Files':
        case 'Setup Conversion Process':
        case 'Setup Conversion Failed':
        case 'Setup Ready for Release':
          statuses.request_pending += 1;
          break;
        case 'Released':
        case 'Resume Case Submission':
          statuses.released += 1;
          statuses.action += 1;
          break;
        case 'Cancelled':
          statuses.cancelled += 1;
          break;
        case 'Doctor Upload New Files':
          statuses.doctor_action += 1;
          statuses.action += 1;
          break;
        case 'Prospect Lost':
          statuses.lost += 1;
          break;
        case 'Case Submitted':
          statuses.submitted += 1;
          break;
        default:
          break;
      }
    }
    this.setState({
      statuses: statuses,
    });
  };

  /**
   * Sets search phrase and detects keys like enter
   * @param {Object} event - Key press event listener
   * @function
   */
  onKeyPressSearchPatient = (event) => {
    let text = textFieldCheck(event.target.value);

    if (event.key === 'Enter') {
      this.setRouteBySearch(text);
    }
  };

  /**
   * Search button click event
   * @param {Object} event - Button click event listener
   * @function
   */
  onSearchButtonClick = (event) => {
    this.setRouteBySearch(this.state.search_phrase);
  };

  /**
   * Changes row size and setup new states
   * @param {Object} event - Button click event
   * @function
   */
  onChangeRowSize(event) {
    const active_page = 1;
    const filter = this.state.search_filter;
    const selection = this.state.selection;
    const simulation_view = this.setOrderByMethod(
      this.getSimulationListFromSelection(
        this.getSimulationListFromFilter(this.getSimulationListFromSearch(this.state.simulation_raw, this.state.search), filter),
        selection
      ),
      this.state.simulation_sort_method,
      this.state.simulation_sort_order_ascending[this.state.simulation_sort_method]
    );

    this.setState({
      simulation_row_size: event.target.value,
      simulation_active_page: active_page,
      simulation_view: this.getSimulationListFromRow(simulation_view, active_page, event.target.value),
      simulation_length: simulation_view.length,
    });
  }

  /**
   * Retrieves new entries and page changes
   * @param {BigInteger} active_page - Page number
   * @function
   */
  onPageChange(active_page) {
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    this.setState({
      simulation_active_page: active_page,
      simulation_view: this.getSimulationListFromRow(
        this.setOrderByMethod(
          this.getSimulationListFromSelection(
            this.getSimulationListFromFilter(this.getSimulationListFromSearch(this.state.simulation_raw, this.state.search), filter),
            selection
          ),
          this.state.simulation_sort_method,
          this.state.simulation_sort_order_ascending[this.state.simulation_sort_method]
        ),
        active_page,
        this.state.simulation_row_size
      ),
    });
  }

  /**
   * Reset Simulation View
   */
  resetPage = () => {
    this.setState({
      refresh_list: true,
    });
  };

  /**
   * Gets smile simulation list from row
   * @param {List} simulation_view - List array of all the smile simulation
   * @param {Int} page - Page number
   * @param {Int} row_size - Row size per page
   * @function
   */
  getSimulationListFromRow(simulation_view, page, row_size) {
    const start_index = (Number(page) - 1) * Number(row_size);
    const end_index = start_index + Number(row_size);

    return simulation_view.slice(start_index, end_index);
  }

  /**
   * Gets smile simulation list from search phrase
   * @param {List} simulations - List array of all the smile simulation
   * @param {String} search_phrase - Search phrase
   * @function
   */
  getSimulationListFromSearch(simulations, search_phrase) {
    if (search_phrase === '') {
      return simulations;
    }
    search_phrase = search_phrase.toLowerCase();
    const space_index = search_phrase.lastIndexOf(' ');
    const inital_index = 0;

    let simulation = [];
    let search_id = '';
    let search_first_name = '';
    let search_last_name = '';

    let phrase_one = search_phrase;
    let phrase_two = '';

    if (space_index >= 0) {
      phrase_one = search_phrase.substring(inital_index, space_index);
      phrase_two = search_phrase.substring(space_index + 1, search_phrase.length);
    }

    for (let i = 0; i < simulations.length; i++) {
      search_first_name = simulations[i].smile_first_name ? simulations[i].smile_first_name.toLowerCase() : '';
      search_last_name = simulations[i].smile_last_name ? simulations[i].smile_last_name.toLowerCase() : '';
      search_id = simulations[i].smile_id ? simulations[i].smile_id.toLowerCase() : '';

      if (
        (search_first_name.indexOf(phrase_one) >= 0 && search_last_name.indexOf(phrase_two) >= 0) ||
        (search_first_name.indexOf(phrase_two) >= 0 && search_last_name.indexOf(phrase_one) >= 0)
      ) {
        simulation.push(simulations[i]);
      } else {
        if (search_first_name.indexOf(search_phrase) >= 0 || search_last_name.indexOf(search_phrase) >= 0 || search_id.indexOf(search_phrase) >= 0) {
          simulation.push(simulations[i]);
        }
      }
    }

    return simulation;
  }

  /**
   * Gets page start range using array length and page size
   * @function
   */
  getStartRange() {
    return this.state.simulation_active_page * this.state.simulation_row_size - this.state.simulation_row_size + 1;
  }

  /**
   * Gets page end range using array length and page size
   * @function
   */
  getEndRange() {
    return this.state.simulation_active_page * this.state.simulation_row_size > this.state.simulation_length
      ? this.state.simulation_length
      : this.state.simulation_active_page * this.state.simulation_row_size;
  }

  /**
   * Sorts the smile simulation list based on event listener and value
   * @param {Object} event - Button event listener
   * @function
   */
  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    let simulation_sort_order_ascending = this.state.simulation_sort_order_ascending;
    if (sort_method === this.state.simulation_sort_method) {
      //Flip order
      simulation_sort_order_ascending[sort_method] = !simulation_sort_order_ascending[sort_method];
    }

    let simulation_view = this.getSimulationListFromRow(
      this.setOrderByMethod(
        this.getSimulationListFromSelection(
          this.getSimulationListFromFilter(this.getSimulationListFromSearch(this.state.simulation_raw, this.state.search), filter),
          selection
        ),
        sort_method,
        simulation_sort_order_ascending[sort_method]
      ),

      this.state.simulation_active_page,
      this.state.simulation_row_size
    );

    this.setState({
      simulation_view: simulation_view,
      simulation_sort_method: sort_method,
      simulation_sort_order_ascending: simulation_sort_order_ascending,
    });
  };

  /**
   * Sorts the smile simulation list based sort type method
   * @param {List} simulation_view - List array of all the smile simulation
   * @param {String} sort_method - Sort method
   * @param {String} sort - Sort type
   * @function
   */
  setOrderByMethod(simulation_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'smile_name':
        simulation_view = this.orderSimulationListByName(simulation_view, sort_value);
        break;

      case 'created_date':
        simulation_view = this.orderSimulationListBySubmissionDate(simulation_view, sort_value);
        break;

      case 'smile_status':
        simulation_view = this.orderSimulationListByStatus(simulation_view, sort_value);
        break;

      case 'smile_id':
        simulation_view = this.orderSimulationListByID(simulation_view, sort_value);
        break;

      case 'smile_location':
        simulation_view = this.orderSimulationListByLocation(simulation_view, sort_value);
        break;

      default:
    }

    return simulation_view;
  }

  /**
   * Returns sort type
   * @param {String} sort - Sort type
   * @function
   */
  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  /**
   * Returns ordered list of smile simulation
   * @param {List} simulation_list - List of smile simulation
   * @function
   */
  orderSimulationListByDefault(simulation_list) {
    const sort = this.getSortValue(this.state.simulation_sort_order_ascending['created_date']);
    return this.orderSimulationListBySubmissionDate(simulation_list, sort);
  }
  /**
   * Returns ordered list of smile simulation by name
   * @param {List} simulation_list - List of smile simulation
   * @param {String} sort - Sort type
   * @function
   */
  orderSimulationListByName(simulation_list, sort) {
    return _.orderBy(simulation_list, ['smile_first_name', 'smile_last_name', 'smile_submitted_date'], [sort, sort, getSubmissionDateDefaultSort()]);
  }
  /**
   * Returns ordered list of smile simulation by submission date
   * @param {List} simulation_list - List of smile simulation
   * @param {String} sort - Sort type
   * @function
   */
  orderSimulationListBySubmissionDate(simulation_list, sort) {
    return _.orderBy(simulation_list, ['created_date_val'], [sort]);
  }

  /**
   * Returns ordered list of smiles by id
   * @param {List} simulation_list - List of smile simulation
   * @param {String} sort - Sort type
   * @function
   */
  orderSimulationListByID(simulation_list, sort) {
    return _.orderBy(simulation_list, ['smile_id'], [sort]);
  }
  /**
   * Returns ordered list of smile simulation by status
   * @param {List} simulation_list - List of smile simulation
   * @param {String} sort - Sort type
   * @function
   */
  orderSimulationListByStatus(simulation_list, sort) {
    return _.orderBy(simulation_list, [this.getStatusSortFunction(), 'smile_submitted_date'], [sort, sort]);
  }

  /**
   * Returns ordered list of smile simulation by location
   * @param {List} simulation_list - List of smile simulation
   * @param {String} sort - Sort type
   * @function
   */
  orderSimulationListByLocation(simulation_list, sort) {
    return _.orderBy(simulation_list, ['address.address_name', 'address.city'], [sort, sort]);
  }

  /**
   * Returns function for sorting by ss status
   * @returns {function}  - Sorting function
   * @function
   */
  getStatusSortFunction = () => {
    return function (smile) {
      return getSmileStatusFilterDefinitionIPP(smile.smile_status);
    };
  };

  /**
   * Returns ordered list of smile simulations using filters
   * @param {List} simulation - List of smile simulations
   * @param {String} filter_phrase - Phrase for the filter
   * @function
   */
  getSimulationListFromFilter(simulation, filter_phrase) {
    if (filter_phrase === '') {
      return simulation;
    }
    let simulation_list = [];
    for (let i = 0; i < simulation.length; i++) {
      if (_.intersection(simulation[i].filter_codes, [filter_phrase]).length > 0) {
        simulation_list.push(simulation[i]);
      }
    }

    return simulation_list;
  }

  /**
   * Returns ordered list of smile simulations using selections
   * @param {List} simulation - List of smile simulations
   * @param {String} selection_phrase - Phrase for the selection
   * @function
   */
  getSimulationListFromSelection(simulation, selection_phrase) {
    if (selection_phrase === 'all_cases' || (this.state.doctor_role === 'Doctor' && selection_phrase === '')) {
      return simulation;
    } else if (selection_phrase === '' && this.state.doctor_role === 'DSO') {
      // DSO Admin
      return simulation;
    } else if ((this.state.doctor_role === 'DSO_Doctor' || this.state.doctor_role === 'DSO_Doctor_Override') && selection_phrase === '') {
      // DSO Doctor
      const doctor_id = getDoctorsIdFromRoute();
      const selected = this.state.simulation_list_doctor[doctor_id] ? this.state.simulation_list_doctor[doctor_id] : [];
      return selected;
    } else if (selection_phrase === '' || selection_phrase === 'my_cases') {
      // Doctor and DSO Override
      const doctor_id = getDoctorsIdFromRoute();
      const selected = this.state.simulation_list_doctor[doctor_id] ? this.state.simulation_list_doctor[doctor_id] : [];
      return selected;
    } else if (selection_phrase.includes('doctor')) {
      // Doctor filter
      const doctor_id = selection_phrase.substring(selection_phrase.indexOf('_') + 1);
      const selected = this.state.simulation_list_doctor[doctor_id] ? this.state.simulation_list_doctor[doctor_id] : [];
      return selected;
    } else if (selection_phrase.includes('location')) {
      // Location Filter
      const location_id = selection_phrase.substring(selection_phrase.indexOf('_') + 1);
      const selected = this.state.simulation_list_location[location_id] ? this.state.simulation_list_location[location_id] : [];
      return selected;
    }
  }

  /**
   * Returns smile simulation details
   * @param {Object} event - Click event
   * @function
   */
  onCaseTrClick = (event) => {
    if (event.target.innerText === 'View Smile') {
      return;
    }

    const smile_id = event.currentTarget.dataset.smile_id;
    let rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/smile/detail/${smile_id}`;
    tableRowLink(event, pathname, this.props, '', {});
  };

  /**
   * Sets search filter selection
   * @param {Object} option - Selection option of the dropdown
   * @function
   */
  onSearchFilterSelection(option) {
    this.setRouteBySearchFilter(option.value);
  }

  /**
   * Converts filter name to a readable title
   * @function
   * @return {String} String version of filter
   */
  filterToTitle(filter) {
    const titles = {
      action: 'Action Required',
      doctor_action: 'Upload New Toothprints',
      lost: 'Prospect Lost',
      submitted: 'Case Submitted',
      request_pending: 'Request Pending',
      released: 'Begin Submission',
      cancelled: 'Cancelled',
    };

    if (titles[filter]) {
      return titles[filter];
    }

    return filter;
  }
  /**
   * Displays search bar
   * @function
   */
  displaySearchBar() {
    const options = [
      { label: 'All Status', value: '' },
      { label: 'Action Required', value: 'action' },
      { label: 'Upload New Toothprints', value: 'doctor_action' },
      { label: 'Request Pending', value: 'request_pending' },
      { label: 'Prospect Lost', value: 'lost' },
      { label: 'Case Submitted', value: 'submitted' },
      { label: 'Begin Submission', value: 'released' },
      { label: 'Cancelled', value: 'cancelled' },
    ];

    const getDisplayLabel = (value) => {
      const selected_option = options.filter((option) => {
        return option.value === value;
      });

      return selected_option && selected_option.length > 0 ? selected_option : options[0];
    };

    return (
      <div className="search-container">
        <div className="search">
          <TextBox
            placeholder="Search"
            onChange={this.onChangeSearchPatient}
            onKeyPress={this.onKeyPressSearchPatient}
            value={this.state.search_phrase}
            icon={'fa fa-search'}
            onIconClick={this.onSearchButtonClick}
          ></TextBox>
        </div>
        <Dropdown value={getDisplayLabel(this.state.search_filter)} onChange={this.onSearchFilterSelection} options={options} />
      </div>
    );
  }
  /**
   * Redirect to create smile simulation page on click of the anchor
   * @function
   */
  redirectToAddSmileSimulation = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/smile/submission`;
    this.props.history.push(pathname);
  };
  /**
   * Displays add new smile simulation link
   * @function
   */
  displayAddSimulation = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) ? (
            <Button className="float--right" onClick={this.redirectToAddSmileSimulation} disabled={!this.state.smile_permission}>
              <i className="fa fa-plus-square" aria-hidden="true" /> Request Smile Simulation
            </Button>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  getSubmissionDate = (props, data) => {
    return <div className="div-table--item-restrict-text">{convertDate(data.created_date) ? convertDate(data.created_date) : '- - - - -'}</div>;
  };

  /**
   * Displays smile simulation status
   * @function
   * @param {object} props - Props object
   * @param {object} data - Smile simulation object
   * @param {list} fields - Smile simulation fields
   * @param {boolean} is_mobile - Mobile view indicator
   * @returns {JSX} - JSX for status
   */
  getStatus = (props, data, fields, is_mobile = false) => {
    const field_values = fields.map((field) => {
      return data[field] ? data[field] : false;
    });
    const badge_class = `badge--status badge--${field_values[1] ? 'primary' : 'default'}`;
    return (
      <div className={`div-table--item-restrict-text div-table--item-restrict-text--${field_values[1] ? 'primary' : 'default'}`}>
        <span className={badge_class}>{this.displayStatus(field_values[0], field_values[3], field_values[4], is_mobile)}</span>
      </div>
    );
  };

  displayStatus = (status, smile_id, smile_comment, is_mobile) => {
    const statuses_should_display = ['Released', 'Resume Case Submission'];
    const has_smile_comment = (smile_comment !== null || smile_comment !== []) && status === 'Prospect Lost';
    const status_comment = has_smile_comment ? ' - ' + smile_comment : '';

    return (
      <>
        {has_smile_comment ? (
          <span data-toggle="tooltip" data-original-title={smile_comment} data-placement="top">
            {getSmileStatusFilterDefinitionIPP(status)}
            {is_mobile ? ` ${status_comment}` : ''}
          </span>
        ) : (
          <span>{getSmileStatusFilterDefinitionIPP(status)}</span>
        )}

        {statuses_should_display.includes(status) ? (
          <span>
            <span>
              {' '}
              -{' '}
              <a className="underlined" href={`/smile_setup/${smile_id}/simulation`} target="_blank" rel="noopener noreferrer">
                View Smile
              </a>
            </span>
          </span>
        ) : null}
      </>
    );
  };

  render() {
    const that = this;
    const meta_data = ['smile_id', 'smile_first_name', 'smile_last_name', 'created_date', 'smile_status'];
    const table_data = [
      {
        id: 'smile_name',
        label: 'Name',
        col_width: '23%',
        display: true,
        can_sort: true,
        data_fields: ['smile_first_name', 'smile_last_name', 'smile_id'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? data[field] : '';
          });
          const name = `${field_values[0]} ${field_values[1]}`;
          const ss_id = field_values[2];
          return (
            <div>
              <div className="case-id-label row-label div-table--item-restrict-text">{ss_id}</div>
              <div className="fs-exclude div-table--item-restrict-text">{name}</div>
            </div>
          );
        },
      },
      {
        id: 'created_date',
        label: 'Submitted',
        col_width: '15%',
        display: true,
        can_sort: true,
        data_fields: ['created_date'],
        data_function: (props, data, fields) => {
          return <>{that.getSubmissionDate(props, data)}</>;
        },
      },
      {
        id: 'smile_status',
        label: 'Status',
        col_width: '30%',
        display: true,
        can_sort: true,
        data_fields: ['smile_status', 'action_required', 'filter_codes', 'smile_id', 'smile_comment'],
        data_function: that.getStatus,
      },
      {
        id: 'smile_location',
        label: 'Location',
        col_width: '32%',
        display: true,
        can_sort: true,
        data_fields: ['address_name', 'city'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data['address'][field] ? data['address'][field] : null;
          });

          return (
            <>
              <div className="div-table--item-restrict-text">
                {!field_values[0] && !field_values[1] ? 'Unassigned' : field_values[0] ? capitalizeFirstLetter(field_values[0]) : field_values[1] + ' Practice'}
              </div>
            </>
          );
        },
      },
    ];

    const mobile_data = [
      {
        id: 'smile_name',
        data_fields: ['smile_first_name', 'smile_last_name'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? data[field] : '';
          });
          return <span className="smile_name">{field_values.join(' ')}</span>;
        },
      },
      {
        id: 'smile_id',
        data_fields: ['smile_id'],
      },
      {
        id: 'smile_status',
        data_fields: ['smile_status', 'action_required', 'filter_codes', 'smile_id', 'smile_comment'],
        data_function: (props, data, fields) => that.getStatus(props, data, fields, true),
      },
    ];

    if (
      (this.state.simulation_view.length === 0 && this.state.search && this.state.search !== '') ||
      (this.state.simulation_view.length === 0 && this.state.search_filter && this.state.search_filter !== '') ||
      (this.state.simulation_view.length === 0 && this.state.status_filter && this.state.status_filter !== '')
    ) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['SMILE_VIEW'], user_roles_and_permissions.permissions) || this.state.has_smile_simulations ? (
              <>
                <Helmet>
                  <title>
                    Smile Simulation List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <ContentHeader title="Smile Simulation List">{this.displayAddSimulation()}</ContentHeader>
                <div className="list-content">
                  <SimulationFilter
                    search={this.displaySearchBar()}
                    statuses={this.state.statuses}
                    selection={this.state.selection}
                    smileDoctors={this.state.smile_doctors}
                    smileAddresses={this.state.smile_addresses}
                    doctorRole={this.state.doctor_role}
                    simulationRaw={this.state.simulation_raw}
                    simulationListDoctor={this.state.simulation_list_doctor}
                    simulationListLocation={this.state.simulation_list_location}
                    resetPage={this.resetPage}
                  />
                  <div className="statuses-result-container">
                    <div className="statuses-result">
                      {this.state.search || this.state.search_filter ? (
                        <NoResult
                          query={this.state.search}
                          filter={
                            this.state.search_filter
                              ? this.state.search_filter_name[this.state.search_filter]
                              : this.state.status_filter
                              ? this.state.search_filter_name[this.state.status_filter]
                              : 'All Smiles'
                          }
                          type="smiles"
                        />
                      ) : (
                        <NoRecords />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    } else if (
      this.state.simulation_view.length === 0 &&
      this.state.simulation_raw.length === 0 &&
      (this.state.search === '' || this.state.search === undefined) &&
      this.state.filter === '' &&
      this.state.status_filter === '' &&
      this.state.selection === ''
    ) {
      return this.state.loading ? (
        <CircleLoader fullscreen={true} />
      ) : (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['SMILE_VIEW'], user_roles_and_permissions.permissions) || this.state.has_smile_simulations ? (
              <>
                <Helmet>
                  <title>
                    Smile Simulation List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <CardContainer type="message">
                  <h3>No Smile Simulation found</h3>
                  <p>You can start by creating your first Smile Simulation request</p>
                  <div className="block--right">
                    <Button onClick={this.redirectToAddSmileSimulation}>Request Smile Simulation</Button>
                  </div>
                </CardContainer>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['SMILE_VIEW'], user_roles_and_permissions.permissions) || this.state.has_smile_simulations ? (
              <>
                <Helmet>
                  <title>
                    Smile Simulation List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <ContentHeader title="Smile Simulation List">{this.displayAddSimulation()}</ContentHeader>
                <div className="list-content">
                  <SimulationFilter
                    search={this.displaySearchBar()}
                    statuses={this.state.statuses}
                    selection={this.state.selection}
                    smileDoctors={this.state.smile_doctors}
                    smileAddresses={this.state.smile_addresses}
                    doctorRole={this.state.doctor_role}
                    simulationRaw={this.state.simulation_raw}
                    simulationListDoctor={this.state.simulation_list_doctor}
                    simulationListLocation={this.state.simulation_list_location}
                    resetPage={this.resetPage}
                  />
                  <div className="statuses-result-container">
                    {this.state.simulation_raw.length > 0 && this.state.simulation_view.length === 0 ? (
                      <div className="statuses-result">
                        <NoRecords />
                      </div>
                    ) : (
                      <>
                        <div className="statuses-result">
                          <CardContainer type="top-border" className="hide-on-mobile--top-border">
                            <DivTable
                              meta_data={meta_data}
                              table_data={table_data}
                              mobile_data={mobile_data}
                              field_data={this.state.simulation_view}
                              sort_method={this.state.simulation_sort_method}
                              sort_order_ascending={this.state.simulation_sort_order_ascending}
                              showCancelCase={this.state.showCancelCase}
                              selected_case_id={this.state.selected_simulation_id}
                              onSortClick={this.onSortClick}
                              onCaseTrClick={this.onCaseTrClick}
                            />
                          </CardContainer>
                        </div>
                        <div className="pagination__container">
                          <div className="pagination__number">
                            {this.getStartRange()}-{this.getEndRange()} of {this.state.simulation_length} Smile Simulation
                          </div>
                          <Pagination
                            className="pagination"
                            activePage={this.state.simulation_active_page}
                            itemsCountPerPage={this.state.simulation_row_size}
                            totalItemsCount={this.state.simulation_length}
                            pageRangeDisplayed={this.state.simulation_page_range}
                            onChange={this.onPageChange}
                            prevPageText={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                            nextPageText={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                            firstPageText={<i className="fa fa-angle-double-left" aria-hidden="true"></i>}
                            lastPageText={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

export default withRouter(SimulationList);
