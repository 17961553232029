/**
 * File: profile.js - Display the business user profile
 * Copyright: (c) Copyright February 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './profile.scss';
// External Libs
import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
// Internal Components
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
// Internal Functions
import ValidationUtils from '../../common/validation/validation-utils';
import { handleHttpRequestError } from '../../common/error';
import { parsePhoneNumber } from 'libphonenumber-js';
import { phoneNumberCharInternational, setTokenHeader } from '../../common/functions';
import { onReloadPage, translateRole } from '../../common/helpers';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      refresh: false,
      profile: {
        account_id: '',
        first_name: '',
        last_name: '',
        account_email: '',
        role: '',
        phone_numbers: [],
      },
      new_number: {
        active: false,
        value: '',
        type: '',
        error: false,
      },
      edit_number: {
        id: '',
        value: '',
        type: '',
        error: false,
      },
    };

    //Added a new number
    this.onAddNumberClick = this.onAddNumberClick.bind(this);
    this.onChangeNewNumber = this.onChangeNewNumber.bind(this);
    this.onChangeNewNumberKeyPress = this.onChangeNewNumberKeyPress.bind(this);
    this.onChangeNewType = this.onChangeNewType.bind(this);
    this.onNewNumberConfirm = this.onNewNumberConfirm.bind(this);
    this.onNewNumberDiscard = this.onNewNumberDiscard.bind(this);

    //Updating/Remove an existing number
    this.onEditPhoneClick = this.onEditPhoneClick.bind(this);
    this.onChangeEditNumber = this.onChangeEditNumber.bind(this);
    this.onChangeEditNumberKeyPress = this.onChangeEditNumberKeyPress.bind(this);
    this.onChangeEditType = this.onChangeEditType.bind(this);
    this.onEditNumberConfirm = this.onEditNumberConfirm.bind(this);
    this.onEditNumberDiscard = this.onEditNumberDiscard.bind(this);
    this.onEditNumberDelete = this.onEditNumberDelete.bind(this);

    this.isNewPhoneNo = this.isNewPhoneNo.bind(this);
  }

  componentDidMount() {
    // setTokenHeader();
    const that = this;

    Axios.get('/apiV2/account')
      .then(function (res) {
        if (res.data.role !== 'Business' && res.data.role !== 'Admin') {
          that.props.history.push('/');
        } else {
          that.setState({
            loading: false,
            profile: res.data,
          });
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {}

  getOldPhoneNumberById(id) {
    const numbers = this.state.profile.phone_numbers;
    let result = '';

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].id === id) {
        result = numbers[i].value;
      }
    }

    return result;
  }

  getOldTypeById(id) {
    const numbers = this.state.profile.phone_numbers;
    let result = '';

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i].id === id) {
        result = numbers[i].type;
      }
    }

    return result;
  }

  onAddNumberClick() {
    this.setState({
      new_number: {
        active: true,
        value: '',
        type: 'office',
        error: false,
      },
    });
  }

  onChangeNewNumber(event) {
    const value = phoneNumberCharInternational(event.target.value);

    this.setState({
      new_number: {
        active: true,
        value: value,
        type: this.state.new_number.type,
        error: false,
      },
    });
  }

  onChangeNewType(event) {
    const type = event.target.value;

    this.setState({
      new_number: {
        active: true,
        value: this.state.new_number.value,
        type: type,
        error: false,
      },
    });
  }

  onEditPhoneClick(event) {
    const id = event.target.dataset.id;
    const value = event.target.dataset.value;
    const type = event.target.dataset.type.toLowerCase();

    if (!this.state.new_number.active) {
      this.setState({
        edit_number: {
          active: true,
          id: parseInt(id),
          value: value,
          type: type,
          error: false,
        },
      });
    }
  }

  onChangeEditNumber(event) {
    this.setState({
      edit_number: {
        active: true,
        id: this.state.edit_number.id,
        value: phoneNumberCharInternational(event.target.value),
        type: this.state.edit_number.type,
        error: false,
      },
    });
  }

  onChangeEditType(event) {
    this.setState({
      edit_number: {
        active: true,
        id: this.state.edit_number.id,
        value: this.state.edit_number.value,
        type: event.target.value,
        error: false,
      },
    });
  }

  onChangeNewNumberKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeEditNumberKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }
  /**
   * Checks if the provided phone number is valid.
   *
   * @param {string} value - The phone number to validate.
   * @returns {boolean} Returns true if the phone number is valid, otherwise false.
   */
  isValidPhoneNo(value) {
    try {
      const cleanNumber = value.replace(/[^0-9\+]/g, '');
      const isNumber = parsePhoneNumber(cleanNumber, 'US');
      const isNumberValid = ValidationUtils.validateInternationalPhone(cleanNumber);

      return isNumberValid && isNumber;
    } catch (error) {
      // Do Nothing
    }

    return false;
  }

  getLatestNumberId() {
    let id = '';

    // Data pass from backend is sorted by created date
    if (this.state.profile && this.state.profile.phone_numbers && this.state.profile.phone_numbers.length > 0) {
      id = this.state.profile.phone_numbers[this.state.profile.phone_numbers.length - 1].id;
    }

    return id;
  }

  onNewNumberConfirm(event) {
    setTokenHeader();
    const that = this;
    const type = this.state.new_number.type;
    const lastest_id = this.getLatestNumberId();
    let number = this.state.new_number.value;

    if (this.isValidPhoneNo(number) && type !== '') {
      const parsedPhone = parsePhoneNumber(number, 'US');
      number = parsedPhone.format('INTERNATIONAL');

      let data = new FormData();
      data.append('value', number);
      data.append('type', type);
      data.append('id', lastest_id);

      Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/add_number`, data)
        .then(function (res) {
          that.setState({
            profile: res.data,
            new_number: {
              active: false,
              value: '',
              type: '',
              error: false,
            },
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          if (err.response.status === 409) {
            that.setState({
              new_number: {
                active: true,
                value: that.state.new_number.value,
                type: that.state.new_number.type,
                error: true,
              },
            });
          } else {
            handleHttpRequestError(err, that);
          }
        });
    } else {
      that.setState({
        new_number: {
          active: true,
          value: this.state.new_number.value,
          type: this.state.new_number.type,
          error: true,
        },
      });
    }
  }

  isNewPhoneNo(new_number, id) {
    let is_new = true;

    for (let i = 0; i < this.state.profile.phone_numbers.length; i++) {
      if (this.state.profile.phone_numbers[i].value === new_number && this.state.profile.phone_numbers[i].id !== id) {
        is_new = false;
        break;
      }
    }

    return is_new;
  }

  onEditNumberConfirm(event) {
    setTokenHeader();
    const that = this;
    const id = this.state.edit_number.id;
    const new_type = this.state.edit_number.type;
    const old_type = this.getOldTypeById(id);
    const old_number = this.getOldPhoneNumberById(id);

    let new_number = this.state.edit_number.value;
    const parsedPhone = parsePhoneNumber(new_number, 'US');
    new_number = parsedPhone.format('INTERNATIONAL');

    if (this.isValidPhoneNo(new_number) && new_type !== '' && this.isNewPhoneNo(new_number, id)) {
      let data = new FormData();
      data.append('value', new_number);
      data.append('old_value', old_number);
      data.append('type', new_type);
      data.append('old_type', old_type);
      data.append('id', id);

      Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/update_number`, data)
        .then(function (res) {
          that.setState({
            profile: res.data,
            edit_number: {
              id: '',
              active: false,
              value: '',
              type: '',
              error: false,
            },
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      that.setState({
        edit_number: {
          id: id,
          active: true,
          value: this.state.edit_number.value,
          type: this.state.edit_number.type,
          error: true,
        },
      });
    }
  }

  onEditNumberDelete(event) {
    setTokenHeader();
    const that = this;
    const id = this.state.edit_number.id;
    const old_type = this.getOldTypeById(id);
    const old_number = this.getOldPhoneNumberById(id);

    let data = new FormData();
    data.append('value', old_number);
    data.append('type', old_type);
    data.append('id', id);

    Axios.post(`/apiV2/accountaction/${this.state.profile.account_id}/remove_number`, data)
      .then(function (res) {
        that.setState({
          profile: res.data,
          edit_number: {
            id: '',
            active: false,
            value: '',
            type: '',
            error: false,
          },
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  onEditNumberDiscard(event) {
    this.setState({
      edit_number: {
        active: false,
        id: '',
        value: '',
        type: '',
        error: false,
      },
    });
  }

  onNewNumberDiscard(event) {
    this.setState({
      new_number: {
        active: false,
        value: '',
        type: '',
        error: false,
      },
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <Helmet>
            <title>My Profile | InBrace Business Portal</title>
          </Helmet>
          <div className="main-content">
            <div className="dark-options dark-options-offset">
              <div className="page-heading page-heading-solo">My Profile</div>
              <div className="page-tab-container">
                <div className="page-tab page-active">User Details</div>
                <div className="page-tab-content">
                  <div className="page-tab-content-section">
                    <div>
                      <span className="profile-heading">First Name:</span>
                      <span className="profile-info">{this.state.profile.first_name}</span>
                    </div>
                    <div>
                      <span className="profile-heading">Last Name:</span>
                      <span className="profile-info">{this.state.profile.last_name}</span>
                    </div>
                    <div>
                      <span className="profile-heading">Account Email:</span>
                      <span className="profile-info fs-exclude">{this.state.profile.account_email}</span>
                    </div>
                    <div>
                      <span className="profile-heading">Role:</span>
                      <span className="profile-info">{translateRole(this.state.profile.role)}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="account-heading">Telephone</div>
                    <table className="table table-condensed table-industry account-table">
                      <thead className="table-inbrace-dark-industry-2">
                        <tr>
                          <th className="type-width-tb">Type</th>
                          <th className="auto-width-tb">Number</th>
                          <th className="edit-width-tb" />
                        </tr>
                      </thead>
                      <tbody className="table-inbrace-dark-industry-2">
                        {this.state.profile.phone_numbers.length > 0 ? (
                          this.state.profile.phone_numbers.map((phone, index) => {
                            return (
                              <tr className="pointer force-auto-width" key={index}>
                                {this.state.edit_number.id === phone.id ? (
                                  <td className="account-table-start type-width-tb">
                                    <select className="select-filter" value={this.state.edit_number.type} onChange={this.onChangeEditType}>
                                      <option value="office">Office</option>
                                      <option value="home">Home</option>
                                      <option value="mobile">Mobile</option>
                                    </select>
                                  </td>
                                ) : (
                                  <td
                                    className="account-table-start type-width-tb account-table-first"
                                    data-id={phone.id}
                                    data-value={phone.value}
                                    data-type={phone.type}
                                    onClick={this.onEditPhoneClick}
                                  >
                                    {phone.type}
                                  </td>
                                )}

                                {this.state.edit_number.id === phone.id ? (
                                  <td className="account-table-end auto-width-tb">
                                    <input
                                      name="phone"
                                      className={
                                        this.state.edit_number.error
                                          ? 'form-control business-dark-theme-input input-error'
                                          : 'form-control business-dark-theme-input'
                                      }
                                      placeholder="Country Code + Phone Number"
                                      value={this.state.edit_number.value}
                                      onChange={this.onChangeEditNumber}
                                      onKeyDown={this.onChangeEditNumberKeyPress}
                                      autoFocus
                                    />
                                  </td>
                                ) : (
                                  <td
                                    className="account-table-end auto-width-tb"
                                    data-id={phone.id}
                                    data-value={phone.value}
                                    data-type={phone.type}
                                    onClick={this.onEditPhoneClick}
                                  >
                                    {phone.value}
                                  </td>
                                )}

                                {this.state.edit_number.id === phone.id ? (
                                  <td className="auto-width-tb">
                                    <div id="edit-confirm" className="btn-clear" onClick={this.onEditNumberConfirm}>
                                      <i className="fa fa-check" aria-hidden="true" />
                                    </div>
                                    <div id="edit-discard" className="btn-clear" onClick={this.onEditNumberDiscard}>
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </div>
                                    <div className="btn-clear" onClick={this.onEditNumberDelete}>
                                      <i className="fa fa-trash" aria-hidden="true" />
                                    </div>
                                  </td>
                                ) : (
                                  <td className="edit-width-tb" />
                                )}
                              </tr>
                            );
                          })
                        ) : this.state.new_number.active ? null : (
                          <tr>
                            <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="2">
                              - - - - - No Phone Number Found (Optional) - - - - -
                            </td>
                            <td />
                          </tr>
                        )}

                        {this.state.new_number.active ? (
                          <tr>
                            <td className="type-width-tb account-table-start">
                              <select className="select-filter" value={this.state.new_number.type} onChange={this.onChangeNewType}>
                                <option value="office">Office</option>
                                <option value="home">Home</option>
                                <option value="mobile">Mobile</option>
                              </select>
                            </td>
                            <td className="account-table-end">
                              <input
                                name="phone"
                                className={
                                  this.state.new_number.error ? 'form-control business-dark-theme-input input-error' : 'form-control business-dark-theme-input'
                                }
                                placeholder="Country Code + Phone Number"
                                value={this.state.new_number.value}
                                onChange={this.onChangeNewNumber}
                                onKeyDown={this.onChangeNewNumberKeyPress}
                                autoFocus
                              />
                            </td>

                            <td>
                              <div
                                id="new-confirm"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Save"
                                className="btn-clear"
                                onClick={this.onNewNumberConfirm}
                              >
                                <i className="fa fa-check" aria-hidden="true" />
                              </div>
                              <div
                                id="new-discard"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Cancel"
                                className="btn-clear"
                                onClick={this.onNewNumberDiscard}
                              >
                                <i className="fa fa-times" aria-hidden="true" />
                              </div>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                    <div>
                      {this.state.new_number.error || this.state.edit_number.error ? (
                        <span className="general-error">Please enter a valid phone number or ensure the phone number does not already exist</span>
                      ) : null}
                      {!this.state.new_number.active && !this.state.edit_number.active ? (
                        <div className="account-add" onClick={this.onAddNumberClick}>
                          Add <i className="fa fa-plus-circle" aria-hidden="true" onClick={this.onAddNumberClick} />
                        </div>
                      ) : (
                        <div className="account-add">
                          <span className="table-add-decor" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.refresh ? (
            <Modal
              preset="action"
              x_btn={false}
              header_text="Session Expired"
              message_text="Sorry, your session has expired. Please refresh."
              confirm_btn_text="Refresh"
              onConfirmButtonClick={onReloadPage}
              theme="bpp"
            />
          ) : null}
        </div>
      );
    }
  }
}

export default withRouter(Profile);
