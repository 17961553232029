import React from 'react';
import { connect } from 'react-redux';
import { Select, MenuItem } from '@material-ui/core';

import { setManualWireLoop, saveDraft } from '../../../../../../redux/actions/manual_wire/manual_wire';
import { loopOptions } from '../../manual_wire_options';
import { TEETHS } from '../../manual_wire_utils';

const ManualWireTableLoopSelect = ({
    loop,
    teethLabel,
    archTeethsData,
    malloclusionBracToBracDist,
    idealBracToBracDist,
    disabled,
    error,
    setLoop,
    arch,
    index,
    filteredIndexes,
    disableAllFields,
    saveDraft,
    options,
}) => {    
    const onChangeWireLoopValue = (event) => {
        const loopValue = event.target.value;
        saveDraft(setLoop, {loopValue, arch, teethLabel});
    };

    return (
        <Select
            value={loop}
            onChange={onChangeWireLoopValue}
            MenuProps={{ classes: { paper: 'menu-paper' } }}
            className={error ? "manual-wire-loop-select error" : "manual-wire-loop-select"}
            disabled={disabled || disableAllFields}
        >
            {options.map((option) => (
                <MenuItem disabled={option.disabled} value={option.value} key={`option-${option.value}`}>{option.label}</MenuItem>
            ))}
        </Select>
    );
}

const mapStateToProps = (state, ownProps) => {
    const teethLabel = TEETHS[ownProps.arch][ownProps.index - 1];
    const loop = state.manualWireReducer[ownProps.arch][teethLabel].loop;
    const archTeethsData = state.manualWireReducer[ownProps.arch];
    const malloclusionBracToBracDist = archTeethsData[teethLabel].malloclusionBracToBracDist;
    const idealBracToBracDist = archTeethsData[teethLabel].idealBracToBracDist;
    const disabled = archTeethsData[teethLabel].disabled;
    const error = archTeethsData[teethLabel].error;

    const locket = state.manualWireReducer[ownProps.arch][teethLabel].locket;
    const nextLabel = (ownProps.index > 0) ? TEETHS[ownProps.arch][ownProps.index] : '';
    const nextLocket = state.manualWireReducer[ownProps.arch][nextLabel].locket;
    
    const options = JSON.parse(JSON.stringify(loopOptions));
    if (locket==='SHL' || nextLocket === 'LSH') {
      // if previous locket is SHL or current locket is LSH, then disable W loop (it cannot have leg with W loop)
      options[1].disabled = true; 
    };

    return {
        loop,
        teethLabel,
        archTeethsData,
        malloclusionBracToBracDist,
        idealBracToBracDist,
        disabled,
        error,
        options
    };
};

const mapDispatchToProps = {
    setLoop: setManualWireLoop,
    saveDraft: saveDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireTableLoopSelect);
