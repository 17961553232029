import { SET_FEATURE_ANNOUNCEMENT_MODAL, SET_SMILE_DESIGN_PREFERENCES_MODAL } from '../../actions/ipp/feature_announcement';

const initialState = {
  feature_announcement_modal: false,
  smile_design_preferences_modal: false,
};

export function featureAnnouncementReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEATURE_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        feature_announcement_modal: action.feature_announcement_modal,
      };

    case SET_SMILE_DESIGN_PREFERENCES_MODAL:
      return {
        ...state,
        smile_design_preferences_modal: action.smile_design_preferences_modal,
      };

    default:
      return state;
  }
}

export const getFeatureAnnouncementModal = (state) => state.featureAnnouncementReducer.feature_announcement_modal;
export const getSmileDesignPreferencesModal = (state) => state.featureAnnouncementReducer.smile_design_preferences_modal;
