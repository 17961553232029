/**
 * File: content_detail.js - Contains the content for the doctors account details for the user
 * Copyright: (c) Copyright November 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Moment from 'moment';

// Internal Components
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import LineDivision from '../components/divisions/line_division';
import ProfilePictureDraft from './profile_picture_draft';
import PracticeLogo from './practice_logo';
import Modal from '../../components/modal/modal';
import TextBox from '../components/inputs/text_box';
import Dropdown from '../components/inputs/dropdown';
import ControlledInput from '../components/inputs/controlled_input';
import AlertMessage from '../components/alert_message';

// Internal Functions
import {
  formatPhoneNumber,
  getBasedFADURLBySite,
  isValidEmail,
  isValidPhoneNo,
  isValidSocialMedia,
  isValidUrl,
  removeEmoji,
  removeSpaces,
  setTokenHeader,
} from '../../common/functions';
import { truncateTextByLimit } from '../../common/helpers';
import { getDoctorsIdFromRoute } from '../../common/route';
import { handleHttpRequestError } from '../../common/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLogoImage } from '../../redux/reducers/ipp/practice_logo';
import universities from './universities.json';
import { userHasPermission } from '../../common/permission';
import { UserPermissionsContext } from '../../context/user_permission';
import { COUNTRY_CODES_BY_NAME } from '../../common/constants/countries';

class ProviderLocatorInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_submit_provider_loc_info_modal: false,
      user: {
        name: '',
        loginEmail: '',
        notificationEmailList: [],
        phoneNumberList: [],
      },
      edit_practice_information: false,
      edit_practice_information_error: false,
      edit_practice_record: {},
      edit_address_record: {},
      error: false,
      account: {},
      loaded: false,
      refresh: false,
      fields: {
        association: ['', '', ''],
        board_certification: '',
        dental_school: '',
        ba_bs: '',
      },
      edit_provider_locator_information_same_error: false,
      edit_bio: '',
      edit_field: '',
      doctor_id: getDoctorsIdFromRoute(),
      doctor_role: '',
      hours_of_operation_error: [],
      hours_of_operation: [
        '----',
        '6:00 AM',
        '6:30 AM',
        '7:00 AM',
        '7:30 AM',
        '8:00 AM',
        '8:30 AM',
        '9:00 AM',
        '9:30 AM',
        '10:00 AM',
        '10:30 AM',
        '11:00 AM',
        '11:30 AM',
        '12:00 PM',
        '12:30 PM',
        '1:00 PM',
        '1:30 PM',
        '2:00 PM',
        '2:30 PM',
        '3:00 PM',
        '3:30 PM',
        '4:00 PM',
        '4:30 PM',
        '5:00 PM',
        '5:30 PM',
        '6:00 PM',
        '6:30 PM',
        '7:00 PM',
        '7:30 PM',
        '8:00 PM',
        '8:30 PM',
        '9:00 PM',
      ],
    };
  }

  componentDidMount() {
    this.loadInformation();
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  /**
   * Refreshes and loads the information
   * @function
   */
  loadInformation = () => {
    const doctor_id = getDoctorsIdFromRoute();

    setTokenHeader();
    const that = this;

    if (doctor_id) {
      Axios.get(`/apiV2/account_setting/${doctor_id}`).then(function (result) {
        if (result.data && result.data.role !== 'Doctor' && result.data.role !== 'DSO') {
          that.setState({ error: true });
        }

        const { bio, ba_bs, association, board_certification, dental_school } = result.data.doctor_info;
        const fields = {
          association: association && JSON.parse(association) !== '' ? JSON.parse(association) : ['', '', ''],
          board_certification: board_certification ? board_certification : '',
          dental_school: dental_school ? dental_school : '',
          ba_bs: ba_bs ? ba_bs : '',
        };

        that.setState({
          account: result.data,
          loaded: true,
          doctor_id: doctor_id,
          doctor_role: result.data.role,
          edit_bio: bio ? bio : '',
          fields: fields,
        });
      });
    }
  };
  /**
   * Set the value to the bio field and saves it
   * @function
   */
  onSaveBioChange = (value) => {
    const that = this;
    this.setState({ edit_bio: value }, () => {
      that.submitAndReset();
    });
  };
  /**
   * Set the value to the associations field and saves it
   * @function
   */
  onSaveAssociationsChange = (value, index) => {
    const that = this;
    let { fields } = this.state;
    fields.association[index] = value;
    this.setState({ fields }, () => {
      that.submitAndReset();
    });
  };
  /**
   * Set the value to the board certification field and saves it
   * @function
   */
  onSaveBoardChange = (value) => {
    const that = this;
    let { fields } = this.state;
    fields.board_certification = value;

    this.setState({ fields }, () => {
      that.submitAndReset();
    });
  };
  /**
   * Set the value to the dental school field and saves it
   * @function
   */
  onSaveDentalSchoolChange = (value) => {
    const that = this;
    let { fields } = this.state;
    fields.dental_school = value;

    this.setState({ fields }, () => {
      that.submitAndReset();
    });
  };
  /**
   * Set the value to the ba bs field and saves it
   * @function
   */
  onSaveBaBsChange = (value) => {
    const that = this;
    let { fields } = this.state;
    fields.ba_bs = value;

    this.setState({ fields }, () => {
      that.submitAndReset();
    });
  };
  /**
   * Set expected initial state before editing
   * @function
   */
  initializedEditField = (event) => {
    this.setState({
      edit_field: event.target.id,
    });
  };
  /**
   * Reset fields
   * @function
   */
  resetEditField = () => {
    this.setState({
      edit_field: '',
    });
  };
  /**
   * Reset fields and apply api
   * @function
   */
  submitAndReset = () => {
    this.resetEditField();
    this.submitProviderLocatorInfo();
  };
  /**
   * Display provider information
   * @function
   */
  displayProviderInformation = () => {
    const { fields, edit_bio, edit_field, doctor_role } = this.state;

    return (
      <div className="case-detail edit-provider-locator-info">
        <div>
          <h2 className="case-header">Provider Information</h2>
        </div>
        <LineDivision theme="provider" />
        <div className="row">
          <div className="col-md-12">
            <div>
              <h3>Edit Provider Information</h3>
            </div>
            <AlertMessage
              theme="info"
              cname="char_limit_locator"
              message="Due to the recent changes in settings there is now a character limit of 600"
              dismiss={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="medium">Profile Photo</div>
            <ProfilePictureDraft
              edit_picture={true}
              name={this.state.account.first_name + ' ' + this.state.account.last_name}
              loginEmail={this.state.account.account_email}
              doctor_id={getDoctorsIdFromRoute()}
              is_edit_dso_doctor_account_page={false}
            />
          </div>
          <div className="col-md-8">
            <div className="provider-bio-row">
              <div className="medium">Bio</div>
              <ControlledInput
                id="bio"
                className="form-control"
                type="textarea"
                value={edit_bio}
                original_value={edit_bio}
                maxLength={600}
                displayButtons
                displayCount
                onSave={this.onSaveBioChange}
                onClick={this.initializedEditField}
                onCancel={this.resetEditField}
                disabled={edit_field !== 'bio' && edit_field !== ''}
                permission="IPP_EDIT"
              />
            </div>
          </div>
        </div>
        {doctor_role === 'Doctor' ? (
          <>
            <div className="row">
              <div className="col-md-12">
                <div className="medium">Enter up to 3 Associations in the boxes below</div>
              </div>
              <div className="provider-association-row">
                {fields.association.map((field, idx) => {
                  return (
                    <div key={idx} className={edit_field === `association_${idx}` ? 'col-md-4 active' : 'col-md-4'}>
                      <ControlledInput
                        id={`association_${idx}`}
                        className="form-control"
                        type="textarea"
                        value={field}
                        original_value={field}
                        maxLength={60}
                        displayButtons
                        displayCount
                        onSave={(value) => this.onSaveAssociationsChange(value, idx)}
                        onClick={this.initializedEditField}
                        onCancel={this.resetEditField}
                        disabled={edit_field !== `association_${idx}` && edit_field !== ''}
                        permission="IPP_EDIT"
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row row--no-space">
              <div className="col-md-8">
                <div className="provider-education-row provider-education-row--large">
                  <div className="medium">Where did you obtain your board certification?</div>
                  <ControlledInput
                    id="board_certification"
                    className="form-control"
                    value={fields.board_certification}
                    original_value={fields.board_certification}
                    maxLength={75}
                    displayButtons
                    displayCount
                    autocomplete={universities}
                    onSave={this.onSaveBoardChange}
                    onClick={this.initializedEditField}
                    onCancel={this.resetEditField}
                    disabled={edit_field !== 'board_certification' && edit_field !== ''}
                    permission="IPP_EDIT"
                  />
                </div>
              </div>
            </div>
            <div className="row row--no-space">
              <div className="col-md-8">
                <div className="provider-education-row">
                  <div className="medium">Where did you attend dental school?</div>
                  <ControlledInput
                    id="dental_school"
                    className="form-control"
                    value={fields.dental_school}
                    original_value={fields.dental_school}
                    maxLength={75}
                    displayButtons
                    displayCount
                    autocomplete={universities}
                    onSave={this.onSaveDentalSchoolChange}
                    onClick={this.initializedEditField}
                    onCancel={this.resetEditField}
                    disabled={edit_field !== 'dental_school' && edit_field !== ''}
                    permission="IPP_EDIT"
                  />
                </div>
              </div>
            </div>
            <div className="row row--no-space">
              <div className="col-md-8">
                <div className="provider-education-row">
                  <div className="medium">Where did you obtain your BA/BS?</div>
                  <ControlledInput
                    id="ba_bs"
                    className="form-control"
                    value={fields.ba_bs}
                    original_value={fields.ba_bs}
                    maxLength={75}
                    displayButtons
                    displayCount
                    autocomplete={universities}
                    onSave={this.onSaveBaBsChange}
                    onClick={this.initializedEditField}
                    onCancel={this.resetEditField}
                    disabled={edit_field !== 'ba_bs' && edit_field !== ''}
                    permission="IPP_EDIT"
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="row top-margin-16">
          <div className="float-right prospect-edit-button">
            <div>
              {this.state.edit_provider_locator_information_same_error ? (
                <div>
                  <span className="prospect-input-label-error">No changes made to submit</span>{' '}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Validate Edit Practice Information
   * @returns {boolean} - returns if validation was successful or not
   */
  validatePracticeInformation = () => {
    const practice_information = this.state.edit_practice_record;
    const address = this.state.edit_address_record;
    const countryCode = COUNTRY_CODES_BY_NAME[address.country];
    let error = false;
    if (address.website && !isValidUrl(address.website)) {
      error = true;
    } else if (!address.address_telephone || !isValidPhoneNo(address.address_telephone, countryCode)) {
      error = true;
    } else if (practice_information.address_email && !isValidEmail(practice_information.address_email)) {
      error = true;
    } else if (practice_information.instagram_url && !isValidSocialMedia(practice_information.instagram_url, 'instagram')) {
      error = true;
    } else if (practice_information.facebook_url && !isValidSocialMedia(practice_information.facebook_url, 'facebook')) {
      error = true;
    } else if (!this.isValidOperationHours(practice_information)) {
      error = true;
    }
    if (error) {
      this.setState({
        edit_practice_information_error: error,
      });
    }
    return error;
  };

  /**
   * Display PracticeInfo buttons
   * @param {*} address
   * @param {*} practice_info
   * @returns JSX
   */
  displayPracticeInfoButtons = (address, practice_info) => {
    return (
      <div className="row top-margin-16 bottom-margin-16">
        <div className="col-lg-12">
          <div className="float-right prospect-edit-button">
            {this.state.edit_practice_information && this.state.edit_address_record.id === address.id ? (
              <>
                <Button
                  onClick={() => {
                    if (!this.validatePracticeInformation()) {
                      this.setState({ show_submit_practice_information_modal: true });
                    }
                  }}
                >
                  Submit
                </Button>
                <Button
                  theme="secondary"
                  onClick={() => {
                    this.onTextFocus();
                    this.setState({
                      edit_practice_information: false,
                      edit_practice_record: {},
                      edit_address_record: {},
                    });
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : !this.state.edit_practice_information ? (
              <>
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return (
                      <Button
                        onClick={() => {
                          this.setState({
                            edit_practice_information: true,
                            edit_practice_record: _.cloneDeep(practice_info),
                            edit_address_record: _.cloneDeep(address),
                          });
                        }}
                        disabled={this.state.edit_practice_information || !userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions)}
                      >
                        Edit
                      </Button>
                    );
                  }}
                </UserPermissionsContext.Consumer>
              </>
            ) : (
              <Button disabled={true}>Edit</Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  /**
   * Get Options for all the closing and opening times
   * @function
   */
  getHoursOpenClosetOptionsValues(current_name, current_value) {
    let options = this.state.hours_of_operation.map((hour) => ({
      label: hour,
      value: hour !== '----' ? hour : '',
      name: current_name,
    }));

    options = this.filterHours(current_name, options);

    const value = options.find((option) => option.value === current_value);
    return { options, value };
  }

  /**
   * Text box on change event function
   * @function
   */
  onTextChange = (event) => {
    const address = _.cloneDeep(this.state.edit_address_record);
    const practice_info = _.cloneDeep(this.state.edit_practice_record);
    const property_name = event.target.dataset.id;
    const property_type = event.target.dataset.type;
    let value = event.target.value;
    if (property_name.includes('_url') || property_name.includes('_email') || property_name === 'website') {
      value = removeSpaces(value);
    }
    if (property_type === 'address') {
      address[property_name] = removeEmoji(value);
    } else {
      practice_info[property_name] = removeEmoji(value);
    }
    this.setState({
      edit_address_record: address,
      edit_practice_record: practice_info,
    });
  };

  /**
   * Text box on blur event function
   * @function
   */
  onTextBlur = (event) => {
    const address = _.cloneDeep(this.state.edit_address_record);
    const practice_info = _.cloneDeep(this.state.edit_practice_record);
    const property_name = event.target.dataset.id;
    const property_type = event.target.dataset.type;
    let value = event.target.value;
    if (property_name.includes('_url') || property_name.includes('_email')) {
      value = removeSpaces(value);
    }
    if (property_type === 'address') {
      address[property_name] = removeEmoji(value);
    } else {
      practice_info[property_name] = removeEmoji(value);
    }
    this.setState({
      edit_address_record: address,
      edit_practice_record: practice_info,
    });
  };

  /**
   * Practice hours on chnage event function
   * @function
   */
  onHoursChange = (event) => {
    const practice_info = _.cloneDeep(this.state.edit_practice_record);
    const property_name = event.name;
    practice_info[property_name] = event.value;

    this.setState({
      edit_practice_record: practice_info,
    });
  };

  /**
   * Practice information on focus
   * @function
   */
  onTextFocus = (event) => {
    this.setState({ edit_practice_information_error: false, hours_of_operation_error: [] });
  };

  /**
   * Display practice information Edit
   * @function
   */
  displayPracticeInformationEdit = (address, practice_info) => {
    const countryCode = COUNTRY_CODES_BY_NAME[address.country];
    const isValidPhoneNumber = isValidPhoneNo(address.address_telephone, countryCode);
    return (
      <>
        <div className="practice-image">
          <PracticeLogo address_id={address.id} practice_id={practice_info.id} edit_picture={true} />
        </div>
        <div className={'row'}>
          <div className="col-lg-12">
            <div className="col-lg-6">
              <div className="bottom-margin-10">
                <div className="medium">Address</div>
                <div>
                  {address.address_line_1}
                  {address.address_line_2 ? ',' : null} {address.address_line_2}
                </div>
                <div>
                  {address.city + ','} {address.state + ','} {address.country ? address.country + ',' : null} {address.zip}
                </div>
              </div>
              <div className="bottom-margin-10">
                <label
                  htmlFor="address_telephone"
                  className={
                    this.state.edit_practice_information_error && address.address_telephone && !isValidPhoneNumber
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                  id="practicePhoneNumberLabel"
                >
                  Phone Number
                </label>
                <div className="inline-input">
                  <TextBox
                    id="address_telephone"
                    data-id={'address_telephone'}
                    data-type={'address'}
                    type="text"
                    className={
                      this.state.edit_practice_information_error && !isValidPhoneNumber
                        ? 'practice-input-field-error form-control patient-field'
                        : 'form-control patient-field'
                    }
                    onChange={this.onTextChange}
                    onFocus={this.onTextFocus}
                    value={address.address_telephone ? address.address_telephone : ''}
                    onBlur={this.onTextBlur}
                    placeholder={'Phone Number'}
                    maxLength="30"
                  />
                </div>
                {this.state.edit_practice_information_error && !isValidPhoneNumber ? (
                  <label htmlFor="address_telephone" id="practicePhoneNumberLabel" className={'address-practice-error-label'}>
                    Please enter a valid phone number
                  </label>
                ) : null}
              </div>
              <div className="bottom-margin-10">
                <label
                  htmlFor="address_email"
                  id="practiceEmailLabel"
                  className={
                    this.state.edit_practice_information_error && practice_info.address_email && !isValidEmail(practice_info.address_email)
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                >
                  Email
                </label>
                <div className="inline-input">
                  <TextBox
                    id="address_email"
                    type="text"
                    data-id={'address_email'}
                    data-type={'practice_info'}
                    className={
                      this.state.edit_practice_information_error && practice_info.address_email && !isValidEmail(practice_info.address_email)
                        ? 'practice-input-field-error form-control patient-field'
                        : 'form-control patient-field'
                    }
                    onChange={this.onTextChange}
                    value={practice_info.address_email ? practice_info.address_email : ''}
                    onBlur={this.onTextBlur}
                    onFocus={this.onTextFocus}
                    placeholder={'Email'}
                    maxLength="100"
                  />
                </div>
                {this.state.edit_practice_information_error && practice_info.address_email && !isValidEmail(practice_info.address_email) ? (
                  <label htmlFor="address_email" id="practiceEmailLabel" className={'address-practice-error-label'}>
                    Please enter a valid email address
                  </label>
                ) : null}
              </div>
              <div className="bottom-margin-10">
                <label
                  htmlFor="instagram_url"
                  id="practiceInstagramLabel"
                  className={
                    this.state.edit_practice_information_error && practice_info.instagram_url && !isValidSocialMedia(practice_info.instagram_url, 'instagram')
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                >
                  Instagram
                </label>
                <div className="inline-input">
                  <TextBox
                    id="instagram_url"
                    type="text"
                    data-id={'instagram_url'}
                    data-type={'practice_info'}
                    className={
                      this.state.edit_practice_information_error && practice_info.instagram_url && !isValidSocialMedia(practice_info.instagram_url, 'instagram')
                        ? 'practice-input-field-error form-control patient-field'
                        : 'form-control patient-field'
                    }
                    onChange={this.onTextChange}
                    value={practice_info.instagram_url ? practice_info.instagram_url : ''}
                    onBlur={this.onTextBlur}
                    onFocus={this.onTextFocus}
                    placeholder={'https://www.instagram.com/username'}
                    maxLength="250"
                  />
                </div>
                {this.state.edit_practice_information_error && practice_info.instagram_url && !isValidSocialMedia(practice_info.instagram_url, 'instagram') ? (
                  <label htmlFor="instagram_url" id="practiceInstagramLabel" className={'address-practice-error-label'}>
                    Please enter a valid Instagram link
                  </label>
                ) : null}
              </div>
              <div className="bottom-margin-10">
                <label
                  htmlFor="facebook_url"
                  id="practiceFacebookLabel"
                  className={
                    this.state.edit_practice_information_error && practice_info.facebook_url && !isValidSocialMedia(practice_info.facebook_url, 'facebook')
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                >
                  Facebook
                </label>
                <div className="inline-input">
                  <TextBox
                    id="facebook_url"
                    type="text"
                    className={
                      this.state.edit_practice_information_error && practice_info.facebook_url && !isValidSocialMedia(practice_info.facebook_url, 'facebook')
                        ? 'practice-input-field-error form-control patient-field'
                        : 'form-control patient-field'
                    }
                    data-id={'facebook_url'}
                    data-type={'practice_info'}
                    onChange={this.onTextChange}
                    value={practice_info.facebook_url ? practice_info.facebook_url : ''}
                    onBlur={this.onTextBlur}
                    onFocus={this.onTextFocus}
                    placeholder={'https://www.facebook.com/username'}
                    maxLength="250"
                  />
                </div>
                {this.state.edit_practice_information_error && practice_info.facebook_url && !isValidSocialMedia(practice_info.facebook_url, 'facebook') ? (
                  <label htmlFor="facebook_url" id="practiceFacebookLabel" className={'address-practice-error-label'}>
                    Please enter a valid Facebook link
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bottom-margin-10">
                <label
                  htmlFor="practiceEmail"
                  id="practiceEmailLabel"
                  className={
                    this.state.edit_practice_information_error && address.website && !isValidUrl(address.website)
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                >
                  Website
                </label>
                <div className="inline-input">
                  <TextBox
                    id="website_url"
                    type="text"
                    className={
                      this.state.edit_practice_information_error && address.website && !isValidUrl(address.website)
                        ? 'practice-input-field-error form-control patient-field'
                        : 'form-control patient-field'
                    }
                    data-id={'website'}
                    data-type={'address'}
                    onChange={this.onTextChange}
                    value={address.website ? address.website : ''}
                    onBlur={this.onTextBlur}
                    onFocus={this.onTextFocus}
                    placeholder={'Website Url'}
                    maxLength="100"
                  />
                </div>
                {this.state.edit_practice_information_error && address.website && !isValidUrl(address.website) ? (
                  <label htmlFor="practiceEmail" id="practiceEmailLabel" className={'address-practice-error-label'}>
                    Please enter a valid website
                  </label>
                ) : null}
              </div>
              <div className="bottom-margin-10">
                <label
                  htmlFor="practiceEmail"
                  id="practiceOperateHourLabel"
                  className={
                    this.state.edit_practice_information_error && this.state.hours_of_operation_error.length > 0
                      ? 'address-practice-error-label medium'
                      : 'medium'
                  }
                >
                  Hours Of Operation
                </label>
                {this.displayOperationDateOnEdit(practice_info)}
                {this.state.edit_practice_information_error && this.state.hours_of_operation_error.length > 0 ? (
                  <label htmlFor="practiceOperateHourLabel" id="practiceOperateHourError" className="address-practice-error-label">
                    Please enter a valid time sequence
                  </label>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * Display practice information
   * @function
   */
  displayPracticeInformationSummary = (address, practice_info) => {
    const countryCode = COUNTRY_CODES_BY_NAME[address.country];
    return (
      <>
        <div className="practice-image">{practice_info.practice_logo ? <img src={practice_info.practice_logo} alt={''} /> : null}</div>
        <div className={'row'}>
          <div className="col-lg-12">
            <div className="col-lg-6">
              <div className="bottom-margin-10">
                <div className="medium">Address</div>
                <div>
                  {address.address_line_1}
                  {address.address_line_2 ? ',' : null} {address.address_line_2}
                </div>
                <div>
                  {address.city + ','} {address.state + ','} {address.country ? address.country + ',' : null} {address.zip}
                </div>
              </div>
              <div className="bottom-margin-10">
                <div className="medium">Phone Number</div>
                <div>{formatPhoneNumber(address.address_telephone, countryCode)}</div>
              </div>
              <div className="bottom-margin-10">
                <div className="medium">Email</div>
                <div className="word-wrap-content">
                  {practice_info.address_email ? (
                    <a className="mail" href={'mailto:' + practice_info.address_email + '?Subject=Hello%20Inbrace'} target="_top">
                      {truncateTextByLimit(practice_info.address_email, 100)}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="bottom-margin-10">
                <div className="medium">Instagram</div>
                <div className="word-wrap-content">
                  {practice_info.instagram_url ? (
                    <a
                      className="mail"
                      href={'http://' + practice_info.instagram_url.replace('http://', '').replace('https://', '')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {practice_info.instagram_url}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="bottom-margin-10">
                <div className="medium">Facebook</div>
                <div className="word-wrap-content">
                  {practice_info.facebook_url ? (
                    <a
                      className="mail"
                      href={'http://' + practice_info.facebook_url.replace('http://', '').replace('https://', '')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {practice_info.facebook_url}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bottom-margin-10">
                <div className="medium">Website</div>
                <div className="word-wrap-content">
                  {address.website ? (
                    <a
                      className="mail"
                      href={'http://' + address.website.replace('http://', '').replace('https://', '')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {address.website}
                    </a>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="bottom-margin-10">
                <div className="medium">Hours Of Operation</div>
                <div>
                  <span className="day-label">Monday</span>{' '}
                  <span className="open-time-label">{practice_info.mon_open_time ? practice_info.mon_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.mon_close_time ? practice_info.mon_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Tuesday</span>{' '}
                  <span className="open-time-label">{practice_info.tue_open_time ? practice_info.tue_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.tue_close_time ? practice_info.tue_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Wednesday</span>{' '}
                  <span className="open-time-label">{practice_info.wed_open_time ? practice_info.wed_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.wed_close_time ? practice_info.wed_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Thursday</span>{' '}
                  <span className="open-time-label">{practice_info.thu_open_time ? practice_info.thu_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.thu_close_time ? practice_info.thu_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Friday</span>{' '}
                  <span className="open-time-label">{practice_info.fri_open_time ? practice_info.fri_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.fri_close_time ? practice_info.fri_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Saturday</span>{' '}
                  <span className="open-time-label">{practice_info.sat_open_time ? practice_info.sat_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.sat_close_time ? practice_info.sat_close_time : '- - - - - - -'}</span>
                </div>
                <div>
                  <span className="day-label">Sunday</span>{' '}
                  <span className="open-time-label">{practice_info.sun_open_time ? practice_info.sun_open_time : '- - - - - - -'}</span>
                  <div className="to-time-label">To</div>
                  <span className="close-time-label">{practice_info.sun_close_time ? practice_info.sun_close_time : '- - - - - - -'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  /**
   * Display practice information
   * @function
   */
  displayPracticeInformation = () => {
    let adrs = _.cloneDeep(
      this.state.account.shipping_addresses.filter(function (address) {
        return address.is_active === true;
      })
    );
    const addresses = _.orderBy(adrs, [(adr) => (adr.address_name ? adr.address_name.toLowerCase() : adr.address_name)], ['asc']);

    return (
      <>
        {this.displayPracticeInfoStaticHeader()}
        {React.Children.toArray(
          addresses.map((address) => {
            const practice_info = this.state.account.practice_info.filter(function (practice) {
              return practice.address_id === address.id;
            })[0];
            return (
              <div key={address.id} className={'row'}>
                {this.state.edit_practice_information && this.state.edit_address_record.id === address.id ? (
                  <>{this.displayPracticeInfoHeaderEdit(address)}</>
                ) : (
                  <>{this.displayPracticeInfoHeader(address)}</>
                )}
                {address.is_doctor_loc ? (
                  <>
                    {this.state.edit_practice_information && this.state.edit_address_record.id === address.id ? (
                      <>{this.displayPracticeInformationEdit(this.state.edit_address_record, this.state.edit_practice_record)}</>
                    ) : (
                      <>{this.displayPracticeInformationSummary(address, practice_info)}</>
                    )}
                    {this.displayPracticeInfoButtons(address, practice_info)}
                  </>
                ) : (
                  <>{this.displayPracticeAddress(address)}</>
                )}
              </div>
            );
          })
        )}
      </>
    );
  };

  /**
   * Display Practice information static header
   * @returns {JSX}
   */
  displayPracticeInfoStaticHeader = () => {
    return (
      <div>
        <h2 className="case-header">Practice Information</h2>
        <div className="practice-information-text">
          <div className="bottom-margin-10">
            Contact the InBrace Concierge team at (877) 258 - 8677 or{' '}
            <a href="mailto:support@inbrace.com?Subject=Hello%20Inbrace" target="_top" rel="noopener noreferrer" className="mail">
              support@inbrace.com
            </a>{' '}
            to hide and display specific practice locations on InBrace’s Find a Provider website.
          </div>
        </div>
      </div>
    );
  };

  displayPracticeInfoHeaderEdit = (address) => {
    return (
      <>
        <LineDivision theme="provider" />
        <div>
          <span className="practice-name emphasis">Edit Practice {address.address_name ? '- ' + address.address_name : address.address_name}</span>
        </div>
        <div>
          <span className="practice-image">
            Edit practice information below. Click <strong>Submit</strong> button to save your changes.
          </span>
        </div>
      </>
    );
  };

  /**
   * Display Practice info header
   * @param {dict} address
   * @returns {JSX}
   */

  displayPracticeInfoHeader = (address) => {
    return (
      <>
        <LineDivision theme="provider" />
        <div>
          <span className="practice-name emphasis">{address.address_name}</span>
          <span className="float-right prospect-edit-button">
            {!address.shipping_only ? (
              <>
                <span className="medium find-a-provider-label">Find a Provider</span>
                <label className="switch">
                  <input type="checkbox" checked={address.is_doctor_loc} disabled={true} />
                  <span className="slider round ipp not-allowed" />
                </label>
              </>
            ) : (
              <div className="bottom-margin-10">
                <span className="medium">Shipping Only</span>
              </div>
            )}
          </span>
        </div>
      </>
    );
  };

  /**
   * Display Practice Address
   * @param {dict} address
   * @returns {JSX}
   */
  displayPracticeAddress = (address) => {
    return (
      <div className={'row'}>
        <div className="col-lg-12">
          <div className="col-lg-6">
            <div className="bottom-margin-10">
              <div className="medium">Address</div>
              <div>
                {address.address_line_1}
                {address.address_line_2 ? ',' : null} {address.address_line_2}
              </div>
              <div>
                {address.city + ','} {address.state + ','} {address.country ? address.country + ',' : null} {address.zip}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /**
   * Display provider locator
   * @function
   */
  displayProviderLocator = () => {
    const doctor_id = getDoctorsIdFromRoute();
    const base_fad_url = getBasedFADURLBySite();

    return (
      <>
        <div>
          <h2 className="case-header">
            Provider Locator
            {this.state.account.approved_case_count > 0 ? (
              <div className="case-header-anchor">
                <a href={`https://${base_fad_url}.com/provider?provider_id=` + doctor_id} rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={['fas', 'link']} /> View my Find a Provider page
                </a>
              </div>
            ) : null}
          </h2>
        </div>

        <div className="row">
          {this.state.account.approved_case_count > 0 ? (
            <>
              <div className="col-md-12 bottom-margin-10">
                Gold, Platinum and Platinum Plus providers receive a dedicated page that features all the information below. If you're not a Gold or Platinum
                provider - we hope you will be one soon so we recommend filling all of the information so that when you hit Gold and above, you immediately have
                all your information displayed correctly.
              </div>
              <div className="col-md-12 bottom-margin-10">
                Since this information is displayed on{' '}
                <a href="https://inbrace.com/" rel="noopener noreferrer" className="mail" target="_blank">
                  inbrace.com
                </a>
                , be sure to only input information you want potential patients to see.
              </div>
              <div className="col-md-12 bottom-margin-10">Please allow up to 24 hours to see your changes reflected on the live site.</div>
            </>
          ) : (
            <div className="col-md-12 bottom-margin-10">
              In order to appear on the InBrace Find a Provider tool you must have submitted a case within the last 270 days. Once you submit a new case, you'll
              automatically reappear on the Find a Provider tool within 24 hours. Please contact your Business Growth Consultant or InBrace Concierge if you
              need additional assistance.
            </div>
          )}
        </div>
      </>
    );
  };

  /**
   * Calls the API to edit provider locator information
   * @function
   */
  submitProviderLocatorInfo = () => {
    const that = this;
    const json = {
      bio: this.state.edit_bio.trim(),
      association: JSON.stringify(this.state.fields.association),
      board_certification: this.state.fields.board_certification,
      dental_school: this.state.fields.dental_school,
      ba_bs: this.state.fields.ba_bs,
    };

    Axios.put(`/apiv3/doctor/${this.state.doctor_id}/providerlocatorinfo`, json)
      .then((res) => {
        that.loadInformation();
        that.setState({
          show_submit_provider_loc_info_modal: false,
          edit_provider_locator_information_same_error: false,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
        });

        if (err && err.response && err.response.status === 400) {
          that.setState({ show_submit_provider_loc_info_modal: false, edit_provider_locator_information_same_error: true });
        } else {
          console.log(err);
        }
        handleHttpRequestError(err, this);
      });
  };

  /**
   * Calls the API to edit practice information
   * @function
   */
  submitEditPracticeInfo = () => {
    const edit_practice_information_record = this.state.edit_practice_record;
    edit_practice_information_record.practice_logo = this.props.logo_image;
    let address_record = this.state.edit_address_record;
    const countryCode = COUNTRY_CODES_BY_NAME[address_record.country];
    address_record.address_telephone = formatPhoneNumber(address_record.address_telephone, countryCode);
    const that = this;
    const json = {
      address: address_record,
      practice_info: edit_practice_information_record,
    };

    Axios.put(`/apiv3/practiceinfo/${this.state.edit_address_record.id}/practiceinfo`, json)
      .then((res) => {
        that.loadInformation();
        that.setState({
          edit_practice_information: false,
          edit_address_record: false,
          edit_practice_record: false,
          show_submit_practice_information_modal: false,
        });
      })
      .catch((err) => handleHttpRequestError(err, this));
  };

  /**
   * Displays the operation date select on edit
   * @function
   * @param {Object} practice_info - Practice info data
   */
  displayOperationDateOnEdit = (practice_info) => {
    const date_list = [
      { Monday: 'mon' },
      { Tuesday: 'tue' },
      { Wednesday: 'wed' },
      { Thursday: 'thu' },
      { Friday: 'fri' },
      { Saturday: 'sat' },
      { Sunday: 'sun' },
    ];

    return date_list.map((date) => {
      const date_name = Object.keys(date);
      const open_date = date[date_name] + '_open_time';
      const close_date = date[date_name] + '_close_time';
      const open_date_error = this.state.hours_of_operation_error.includes(open_date);
      const close_date_error = this.state.hours_of_operation_error.includes(close_date);
      return (
        <div className="bottom-margin-10" key={date_name}>
          <span className="day-label-edit">{date_name}</span>{' '}
          <span className="open-time-label-edit" key={open_date}>
            <Dropdown
              id={open_date}
              key={open_date + '_dropdown'}
              className={open_date_error ? 'practice-input-field-error hours-field' : 'hours-field'}
              onChange={this.onHoursChange}
              onFocus={this.onTextFocus}
              placeholder="----"
              {...this.getHoursOpenClosetOptionsValues(open_date, practice_info[open_date])}
            />
          </span>
          <div className="to-time-label">To</div>
          <span className="open-time-label-edit" key={close_date}>
            <Dropdown
              id={close_date}
              key={close_date + '_dropdown'}
              className={close_date_error ? 'practice-input-field-error hours-field' : 'hours-field'}
              onChange={this.onHoursChange}
              onFocus={this.onTextFocus}
              placeholder="----"
              {...this.getHoursOpenClosetOptionsValues(close_date, practice_info[close_date])}
            />
          </span>
        </div>
      );
    });
  };

  /**
   * Check if operation hours are valid
   * @function
   * @param {Object} practice_info - Practice info data
   */
  isValidOperationHours = (practice_info) => {
    const dates = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    let error_dates = [];
    let all_hours_valid = true;
    dates.forEach((day) => {
      let open_hour = practice_info[day + '_open_time'];
      let close_hour = practice_info[day + '_close_time'];
      let open_hour_missing = (open_hour === '' || open_hour === null) && close_hour;
      let close_hour_missing = (close_hour === '' || close_hour === null) && open_hour;

      if (open_hour_missing) {
        error_dates.push(day + '_open_time');
        all_hours_valid = false;
      }
      if (close_hour_missing) {
        error_dates.push(day + '_close_time');
        all_hours_valid = false;
      }
    });
    this.setState({ hours_of_operation_error: error_dates });

    return all_hours_valid;
  };

  /**
   * Filter operation hours to show only valid hours
   * @function
   * @param {String} current_name - Current operation day name
   * @param {Array} options - Array of objects of all hours
   */
  filterHours = (current_name, options) => {
    let practice_info = _.cloneDeep(this.state.edit_practice_record);
    let is_close_hour = current_name && current_name.includes('close');
    let open_close_hour = is_close_hour ? current_name.replace('close', 'open') : current_name.replace('open', 'close');

    const filtered_options = options.filter((day) => {
      let hour_value = Moment(day.value, 'h:mm a');
      let opposite_hour_value = practice_info[open_close_hour] ? Moment(practice_info[open_close_hour], 'h:mm a') : '';
      if (day.label === '----' || opposite_hour_value === '') {
        return true;
      } else if (!is_close_hour && hour_value.isSame(Moment('9:00 PM', 'h:mm a'))) {
        return false;
      } else if (is_close_hour && hour_value.isSame(Moment('6:00 AM', 'h:mm a'))) {
        return false;
      } else if (is_close_hour && opposite_hour_value && hour_value.isAfter(opposite_hour_value)) {
        return true;
      } else if (!is_close_hour && opposite_hour_value && hour_value.isBefore(opposite_hour_value)) {
        return true;
      }

      return false;
    });
    return filtered_options;
  };

  render() {
    return (
      <>
        {this.state.loaded === false ? (
          <CircleLoader fullscreen={true} />
        ) : (
          <>
            <CardContainer type="top-border" className="case-view pad-lg">
              {this.displayProviderLocator()}
            </CardContainer>
            <CardContainer type="top-border" className="case-view pad-lg">
              {this.displayProviderInformation()}
            </CardContainer>
            <CardContainer type="top-border" className="case-view pad-lg">
              {this.displayPracticeInformation()}
            </CardContainer>

            {this.state.show_submit_practice_information_modal ? (
              <Modal
                preset="decision"
                theme="ipp"
                header_text="Submit Changes"
                confirm_btn_text="Submit"
                close_btn_text="Cancel"
                message_text="Are you sure you would like these changes to go live on the Find a Provider site?"
                onConfirmButtonClick={() => this.submitEditPracticeInfo()}
                onCloseButtonClick={() => this.setState({ show_submit_practice_information_modal: false })}
                is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page}
              />
            ) : null}
            {this.state.show_submit_provider_loc_info_modal ? (
              <Modal
                preset="decision"
                theme="ipp"
                header_text="Submit Changes"
                confirm_btn_text="Submit"
                close_btn_text="Cancel"
                message_text="Are you sure you would like these changes to go live on the Find a Provider site?"
                onConfirmButtonClick={() => this.submitProviderLocatorInfo()}
                onCloseButtonClick={() => this.setState({ show_submit_provider_loc_info_modal: false })}
                is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page}
              />
            ) : null}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logo_image: getLogoImage(state),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProviderLocatorInfo);
