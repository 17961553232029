import _ from 'lodash';
import * as THREE from 'three';
import STLLoaderModule from 'three-stl-loader';
const TrackballControls = require('three-trackballcontrols');
const STLLoader = STLLoaderModule(THREE);

class SimpleSTLViewer {
  constructor(container, colors) {
    this.container = container;
    this.sceneMeshes = [];

    const { modelColor, modelBackColor, backgroundColor } = colors;
    const materials = this.createTeethMaterial(modelColor, modelBackColor);
    this.matTeeth = materials.matTeeth;
    this.matTeethBack = materials.matTeethBack;

    this.camera = this.createTrackBallPerspectiveCamera(container);
    this.scene = this.createScene(backgroundColor);
    this.renderer = this.createRenderer(container, backgroundColor);
    this.controls = this.createTrackballControls(this.camera, this.renderer);
    this.camera.add(this.createLights());
    this.scene.add(this.camera);

    this.resizeTimeout = null;
    this.resizeDebounce = _.debounce(this.onResize, 50, { trailing: true });
    window.addEventListener('resize', this.resizeDebounce);
  }

  start = () => {
    this.renderer.setAnimationLoop(this.animate);
  };

  stop = () => {
    this.renderer.setAnimationLoop(null);
    window.removeEventListener('resize', this.resizeDebounce);
  };

  animate = () => {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  };

  load = (urls, onComplete = () => {}, onProgress = () => {}) => {
    this.cleanSceneMeshes();
    this.loadSTLURLs(urls, onComplete, onProgress);
  };

  clean = () => {
    this.stop();
    while (this.scene.children.length > 0) {
      this.scene.remove(this.scene.children[0]);
    }
    this.disposeScene();
    this.renderer.renderLists.dispose();
    this.renderer.dispose();
  };

  cleanSceneMeshes = () => {
    for (const mesh of this.sceneMeshes) {
      this.scene.remove(mesh);
    }
    this.sceneMeshes = [];
  };

  createTrackBallPerspectiveCamera = (container) => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    const distance = 10000;
    const camera = new THREE.PerspectiveCamera(30, width / height, 1, distance);
    camera.position.set(0, -175, 50);

    return camera;
  };

  createTeethMaterial = (modelColor, modelBackColor) => {
    const matTeeth = new THREE.MeshPhongMaterial({
      color: modelColor,
      specular: 0x222222,
      shininess: 10,
      polygonOffset: false,
      polygonOffsetFactor: -1,
      side: THREE.FrontSide,
      flatShading: true,
    });
    const matTeethBack = new THREE.MeshPhongMaterial({
      color: modelBackColor,
      specular: 0,
      shininess: 0,
      polygonOffset: false,
      polygonOffsetFactor: -1,
      side: THREE.BackSide,
      flatShading: true,
    });

    return { matTeeth, matTeethBack };
  };

  createTrackballControls = (camera, renderer) => {
    const controls = new TrackballControls(camera, renderer.domElement);
    controls.rotateSpeed = 6.0;
    controls.zoomSpeed = 3.2;
    controls.panSpeed = 1;
    controls.staticMoving = true;
    controls.keys = [65, 83, 68];

    return controls;
  };

  createLights = () => {
    const lightGroup = new THREE.Group();

    // Key Direction Light
    const light1 = new THREE.DirectionalLight(0xffffff, 0.2);
    light1.position.set(-1, 0.2, -0.5);
    light1.position.normalize();

    // Fill Direction Light
    const light2 = new THREE.DirectionalLight(0xffffff, 0.2);
    light2.position.set(-1, 0.2, 0.5);
    light2.position.normalize();

    // Back Direction Light
    const light3 = new THREE.DirectionalLight(0xffffff, 0.2);
    light3.position.set(1, 0.2, -0.5);
    light3.position.normalize();

    // Top Direction Light
    const light4 = new THREE.DirectionalLight(0xffffff, 0.2);
    light3.position.set(0.6, -1, 0);
    light4.position.normalize();

    lightGroup.add(light1, light2, light3, light4);
    return lightGroup;
  };

  createScene = (backgroundColor) => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(backgroundColor);
    return scene;
  };

  createRenderer = (container, backgroundColor) => {
    const width = container.clientWidth;
    const height = container.clientHeight;
    const renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true, antialias: true });
    renderer.setSize(width, height);
    renderer.setClearColor(backgroundColor, 1);
    container.append(renderer.domElement);
    return renderer;
  };

  loadSTLURLs = (urls, onComplete, onProgress) => {
    const loader = new STLLoader();
    const onLoadSTL = (geometry) => {
      this.onLoadSTL(geometry);
      onComplete();
    };
    const onProgressSTL = (progress) => {
      onProgress(progress.loaded / progress.total);
    };

    urls.forEach((url) => {
      loader.load(url, onLoadSTL, onProgressSTL);
    });
  };

  onLoadSTL = (geometry) => {
    const teethMesh = new THREE.Mesh(geometry, this.matTeeth);
    this.sceneMeshes.push(teethMesh);
    this.scene.add(teethMesh);

    const backTeethMesh = new THREE.Mesh(geometry, this.matTeethBack);
    this.sceneMeshes.push(backTeethMesh);
    this.scene.add(backTeethMesh);
  };

  onResize = (counter = 0) => {
    const width = this.container.clientWidth;
    const height = this.container.clientHeight;
    const aspect = width / height;
    const max_count = 100;

    counter = _.isNumber(counter) ? counter + 1 : 0;

    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.controls.handleResize();

    if (counter < max_count) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.onResize(counter);
      }, 10);
    }
  };

  disposeScene = () => {
    if (this.scene.geometry) {
      this.scene.geometry.dispose();
    }

    if (this.scene.material) {
      if (this.scene.material.length) {
        for (let i = 0; i < this.scene.material.length; ++i) {
          this.scene.material[i].dispose();
        }
      } else {
        this.scene.material.dispose();
      }
    }

    if (this.scene.children.length > 0) {
      this.scene.children.forEach((element) => {
        if (element.geometry) {
          element.geometry.dispose();
        }
        if (element.material) {
          if (element.material.length) {
            for (let i = 0; i < element.material.length; ++i) {
              element.material[i].dispose();
            }
          } else {
            element.material.dispose();
          }
        }
      });
    }
  };
}

export { SimpleSTLViewer };
