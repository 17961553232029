/**
 * File:stl_viewer.js - Component to view STL files.
 * Copyright: (c) Copyright December 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../loader/circle_loader';
import { SimpleSTLViewer } from './simple_stl_viewer';

class STLViewer extends Component {
  state = {
    split: false,
    loading: false,
    loading_completed: 0,
    progress: 0,
  };

  static propTypes = {
    className: PropTypes.string,
    urls: PropTypes.arrayOf(PropTypes.string),
    file: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backgroundColor: PropTypes.string,
    modelColor: PropTypes.string,
    modelBackColor: PropTypes.string,
    sceneClassName: PropTypes.string,
    onSceneRendered: PropTypes.func,
  };

  static defaultProps = {
    urls: [],
    backgroundColor: '#EAEAEA',
    modelColor: '#B92C2C',
    modelBackColor: '#B92C2C',
    height: 400,
    width: 400,
    rotate: true,
    trackBallControls: true,
    sceneClassName: '',
  };

  componentDidMount() {
    const colors = {
      backgroundColor: this.props.backgroundColor,
      modelColor: this.props.modelColor,
      modelBackColor: this.props.modelBackColor,
    };
    this.stl_viewer = new SimpleSTLViewer(this.ref, colors);
    this.stl_viewer.start();
    this.loadSTL();
  }

  componentWillUnmount() {
    this.stl_viewer.clean();
  }

  componentDidUpdate(nextProps) {
    const has_new_urls = !_.isEqual(nextProps.urls, this.props.urls);
    if (has_new_urls) {
      this.loadSTL();
    }

    if (this.state.split !== this.props.split) {
      this.stl_viewer.onResize();
      this.setState({ split: this.props.split });
    }
  }

  loadSTL() {
    this.setState({ loading: true, loading_completed: 0, progress: 0 });
    const valid_urls = this.props.urls.filter((url) => Boolean(url));
    this.stl_viewer.load(valid_urls, this.onComplete, this.onProgress);
  }

  onComplete = () => {
    const valid_url_count = this.props.urls.filter((url) => Boolean(url)).length;
    this.setState((prevState) => {
      if (prevState.loading_completed === valid_url_count) {
        return { ...prevState, loading: false };
      }
      return {
        ...prevState,
        loading_completed: prevState.loading_completed + 1,
        loading: valid_url_count !== prevState.loading_completed + 1,
      };
    });
  };

  onProgress = (newProgress) => {
    this.setState({ progress: Math.round(newProgress * 100) });
  };

  render() {
    const valid_url_count = this.props.urls.filter((url) => Boolean(url)).length;
    return (
      <div
        className={this.props.className}
        style={{
          width: this.props.width,
          height: this.props.height,
          overflow: 'hidden',
        }}
      >
        {this.state.loading && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <CircleLoader />
              <div className="emphasis">
                {this.state.loading_completed + 1}/{valid_url_count}: {this.state.progress}%
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
          ref={(ref) => (this.ref = ref)}
        />
      </div>
    );
  }
}

export default STLViewer;
