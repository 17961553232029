import React from 'react';
import { convertDate, convertSex } from '../../common/functions';
import Button from '../components/buttons/button';
import Badge from '../components/badge';
import useFitText from 'use-fit-text';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

function PatientInfo(props) {
  const { smile_first_name, smile_last_name, smile_dob, smile_sex, smile_ref } = props;
  const { fontSize: PCN_fontSize, ref: PCN_ref } = useFitText();
  const { fontSize: name_fontSize, ref: name_ref } = useFitText();

  return (
    <>
      <div className="patient-info__personal-info">
        <div className="profile-icon-container">
          {smile_first_name && smile_last_name ? (
            <div className="patient__profile-icon patient__initials fs-exclude">
              {smile_first_name[0].toUpperCase()}
              {smile_last_name[0].toUpperCase()}
            </div>
          ) : (
            <div className="patient__profile-icon patient__silhouette">
              <i className="fa fa-user-square" aria-hidden />
            </div>
          )}
        </div>
        <div className="patient-info__detail">
          <div className="fs-exclude" ref={name_ref} style={{ fontSize: name_fontSize }}>
            {smile_first_name} {smile_last_name}
          </div>
          <div>{convertDate(smile_dob)}</div>
          <div>{convertSex(smile_sex)}</div>
        </div>
      </div>

      <div>
        <div className="emphasis">Patient Chart No.</div>
        <div ref={PCN_ref} style={{ fontSize: PCN_fontSize }}>
          {smile_ref ? smile_ref : 'N/A'}
        </div>
      </div>

      {props.assigned_doctor && (
        <div>
          {props.assigned_doctor.trim() ? (
            <>
              <div className="emphasis">Doctor</div>
              <div>{props.assigned_doctor}</div>
            </>
          ) : (
            <div className="emphasis">Unassigned</div>
          )}
        </div>
      )}

      {props.case_id_smile_simulation && (
        <div className="patient_details">
          <div className="emphasis">Case ID</div>
          <div onClick={props.onCaseIdClick} className="linked_id">
            {props.case_id_smile_simulation}
          </div>
        </div>
      )}

      {props.referral_code ? <Badge theme="midnight">{props.referral_code}</Badge> : null}
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const hasIPPEditPermission = userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
          const disabled = !hasIPPEditPermission;
          return (
            <Button onClick={props.onEdit} disabled={disabled}>
              Edit
            </Button>
          );
        }}
      </UserPermissionsContext.Consumer>
    </>
  );
}

export default PatientInfo;
