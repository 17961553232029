import React from 'react';
import TeethUtils from '../../../../../../common/teeth_utils';

/**
 * Displays a ipr item
 * @component
 */
export default function IPRChartItem(props) {
  return (
    <td className={TeethUtils.isToothInList(props.teeth, props.missing_teeth) ? `ipr-chart-missing-teeth ${props.baseClass}` : props.baseClass}>
      {props.teeth}
    </td>
  );
}
