import './case_submission_confirmation.scss';

import React, { Component } from 'react';

import RatingForm from '../components/feedback_rating_form';

import Button from '../components/buttons/button';

import CardContainer from '../components/container/card_container';
import { scrollToTop } from '../../common/scroll';
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../common/storage';

class CaseSubmitted extends Component {
  componentDidMount() {
    scrollToTop();
    sendRefreshCaseListMessage();
    sendRefreshDashboardMessage();
  }

  render() {
    return (
      <>
        <div className="submission-confirmation-container">
          <div className="title-height content__header--confirmation">
            <h1 className="fs-exclude ">Submission Successful</h1>
          </div>
          <div className="content__action">
            <p>Thank you for submitting your case to InBrace. You will receive an email to confirm your submission.</p>
            <Button onClick={this.props.onButtonClick}>Go to Case Details</Button>
          </div>
        </div>
        <CardContainer className="rating-form-container">
          <RatingForm case_id={this.props.case_id} />
        </CardContainer>
      </>
    );
  }
}

export default CaseSubmitted;
