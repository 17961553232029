import React from 'react';
import { rebrandStatus, translateStatus } from '../../common/helpers';
import TextBox from '../components/inputs/text_box';
import Dropdown from '../components/inputs/dropdown';

/**
 * Display the case list search filter
 * @component
 * @category BPP
 */
function DoctorCaseListSearch(props) {
  const { search, onChangeSearch, onSearchButtonClick, search_filter, onSearchFilterSelection } = props;
  const options = [
    { label: 'All Status', value: '' },
    { label: rebrandStatus('Doctor Approve or Revise Setup'), value: 'setup' },
    { label: rebrandStatus('Doctor Upload New Files'), value: 'new' },
    { label: translateStatus('Doctor Provide Clarification', 'ipp'), value: 'clarification' },
    { label: translateStatus('Hold'), value: 'hold' },
    { label: 'Draft Submission', value: 'draft' },
    { label: rebrandStatus('Case Shipped'), value: 'ship' },
    { label: translateStatus('Cancelled'), value: 'cancel' },
  ];
  const onKeyPress = (event) => {
    if (event.key === 'Enter') onSearchButtonClick();
  };
  return (
    <div className="search-container">
      <div className="search">
        <TextBox
          placeholder="Search"
          onChange={onChangeSearch}
          onKeyPress={onKeyPress}
          value={search}
          icon={'fa fa-search'}
          onIconClick={onSearchButtonClick}
        ></TextBox>
      </div>
      <Dropdown defaultValue={options[0]} value={options.find((item) => item.value === search_filter)} onChange={onSearchFilterSelection} options={options} />
    </div>
  );
}
export default DoctorCaseListSearch;
