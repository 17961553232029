import React from 'react';

/**
 * Display the case list table loading placeholder
 * @component
 * @category BPP
 */
function CaseListTableLoadingPlaceholder(props) {
  const { header, rowSize } = props;
  return (
    <tbody className="case-list-table-loading-placeholder">
      {[...Array(rowSize)].map((e, i) => (
        <tr key={i} className="table-placeholder-row">
          {header.map((item) => (
            <td key={item.category} className={`table-placeholder-item ${item.className}`}>
              <div />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

export default CaseListTableLoadingPlaceholder;
