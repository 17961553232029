import './alert_message.scss';

import React from 'react';

export const AlertMessageContainer = ({ className, theme = 'success', dismiss, ...props }) => {
  const [show, setShow] = React.useState(true);

  const classNames = `bpp-alert bpp-alert--${theme} ${className ? className : ''}`;

  if (!show) return null;

  return (
    <div className={classNames} {...props}>
      {dismiss ? (
        <span className="bpp-alert-dismiss" onClick={() => setShow(false)}>
          x
        </span>
      ) : null}
      {props.children}
    </div>
  );
};

export const AlertMessage = ({ children, className, ...props }) => {
  const classNames = `bpp-alert-text ${className ? className : ''}`;
  return (
    <span className={classNames} {...props}>
      {children}
    </span>
  );
};
