import React, { useContext } from 'react';
import { onImageToolAction } from '../../common/functions';
import { userHasPermission } from '../../common/permission';
import FileUploadProgress from '../../components/file_upload/file_upload_progress';
import SimpleFileUpload from '../../components/file_upload/simple_file_upload';
import { UserPermissionsContext } from '../../context/user_permission';
import AlertDraftReadOnly from '../components/alerts/alert_draft_read_only';
import RecordViewer from '../components/record_viewer/record_viewer';

function PhotosS3(props) {
  const { permissions } = useContext(UserPermissionsContext);
  const canEdit = userHasPermission('IPP_EDIT', permissions);

  // will be used when s3 is activated
  // eslint-disable-next-line
  const { photoUpload, buildRecordStates, hide_occlusion, photo_statuses, onPhotoUpdate, photo_completed, onCompleteUpload } = props;

  const onAbortUpload = (filename) => photo_statuses[filename].uploader.abort();
  return (
    <form className="case-form-container">
      <h3 id="photoLabel">Upload Photos</h3>
      {props.isDso && props.disabledEdit ? <AlertDraftReadOnly /> : null}
      <div className="fileUpload-container">
        <div>
          <SimpleFileUpload
            fileStatus={photo_statuses}
            setFileStatus={onPhotoUpdate}
            onCompleteUpload={onCompleteUpload}
            multiple
            uploadParams={{ folder: 'photos', id: props.id, location: props.location }}
            type="image"
            disabled={props.editDisabled || !canEdit}
          />
          <div className="fileupload-progress-container scrollbar--dark">
            <FileUploadProgress file_statuses={photo_statuses} onAbortUpload={onAbortUpload} />
          </div>
        </div>
        <RecordViewer
          records={props.record_states}
          onLoad={props.onLoadImageRecord}
          onRemove={props.onRemove}
          removeAllowed={props.removeAllowed}
          disabled={props.disabled}
          onClick={(id, action) => {
            onImageToolAction(id, action, props);
          }}
        />
      </div>
    </form>
  );
}

export default PhotosS3;
