import React, { useContext } from 'react';
import { connect } from 'react-redux';
import HistoryListItemReview from './history_list_item_review';
import HistoryListHTMLItem from './history_list_html_item';
import { getCaseDetailByCaseId } from '../../../common/functions';
import { getCaseDetails } from '../../../../../../redux/reducers/common/common_case_details';
import { UserPermissionsContext } from '../../../../../../context/user_permission';
import { userHasPermission } from '../../../../../../common/permission';

/**
 * Contains the full dialog between each history item
 * @component
 * @alias SetupViewerHistoryListItem
 */
function HistoryListItem(props) {
  const { permissions } = useContext(UserPermissionsContext);
  const { split, item, onClick, pane } = props;
  const { text, status, inbrace_comment, doctor_name, doctor_comment, active_pane_1, active_pane_2 } = item;
  const is_selected = split ? active_pane_1 || active_pane_2 : active_pane_1;
  const case_detail = getCaseDetailByCaseId(props.case_details, props.case_details.case_id);
  const is_ifs_setup = text.includes('(InBrace IFS)');
  const clinical_review = case_detail?.clinical_review;
  const allowed_clinical_notes =
    case_detail?.status_code !== 'STATUS_HOLD' && case_detail?.status_code !== 'STATUS_CANCEL' && case_detail?.status_code !== 'Doctor Provide Clarification';

  return (
    <div className={is_ifs_setup ? 'ifs-history-list' : ''}>
      <div className={is_selected ? 'history-list-item history-list-item--active' : 'history-list-item'} onClick={onClick} data-pane={pane} data-text={text}>
        <div className="history-list-item-title">{text}</div>
        <div className="history-list-item-status">{!is_ifs_setup && status}</div>
        {inbrace_comment && (
          <div className="history-list-item-comments">
            <HistoryListHTMLItem value={inbrace_comment} />
          </div>
        )}

        {doctor_comment && (
          <div className="history-list-item-comments">
            <span className="history-list-item-name">{doctor_name}</span>
            <span className="ql-editor ql-table quill-formatted">{doctor_comment}</span>
          </div>
        )}

        {clinical_review && allowed_clinical_notes && (
          <div className="history-list-item-note">
            <hr />
            <p>
              <span className="history-list-item-note-text">Note:</span> Clinical Review must be completed before reviewing this Smile Design
            </p>
            <hr />
          </div>
        )}

        {status === 'In Review' && (
          <HistoryListItemReview
            history={props.history}
            disabled={!is_selected}
            hideApproveButton={!userHasPermission('IPP_SETUP_APPROVAL', permissions)}
            hideReviseButton={!userHasPermission('IPP_SETUP_REVISION', permissions)}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    case_details: getCaseDetails(state),
  };
};

export default connect(mapStateToProps)(HistoryListItem);
