import Axios from 'axios';
import { convertInputDate } from '../../../common/functions';

// Action Types
export const FETCH_SHIPPO_REPORT_PENDING = 'FETCH_SHIPPO_REPORT_PENDING';
export const FETCH_SHIPPO_REPORT_SUCCESS = 'FETCH_SHIPPO_REPORT_SUCCESS';
export const FETCH_SHIPPO_REPORT_ERROR = 'FETCH_SHIPPO_REPORT_ERROR';
export const FETCH_SHIPPO_REPORT_DATE_ERROR = 'FETCH_SHIPPO_REPORT_DATE_ERROR';
export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

// Action Creators
// -----------------------------------------------------------------------------
// FETCH
export function fetchShippoReport() {
  return (dispatch, getState) => {
    const { start_date } = getState().shippoReducer;
    const { end_date } = getState().shippoReducer;

    // Check that start date is less than or equal to end date
    let date_error = false;
    if ((start_date && end_date) || (start_date && !end_date)) {
      if (start_date && !end_date) {
        const current_date = new Date();
        if (start_date > convertInputDate(current_date.toISOString())) {
          date_error = true;
        }
      } else if (start_date > end_date) {
        date_error = true;
      }
    }

    if (date_error) {
      dispatch(fetchShippoReportDateError('End date is earlier than start date'));
    } else {
      dispatch(fetchShippoReportPending());

      Axios.get(`/apiv3/reports/shippoextract?from=${start_date}&to=${end_date}`)
        .then(function (res) {
          const reports = res.data.report;
          let report_data = JSON.parse(res.data.report_data);
          let keys = [];
          let columns = [];

          if (report_data.length > 0) {
            for (let k in report_data[0]) {
              keys.push(k);
              columns.push({ title: k, field: k, width: 150 });
            }
          } else {
            let report_data_object = {};
            for (let k of res.data.columns) {
              keys.push(k);
              columns.push({ title: k, field: k, width: 150 });
              report_data_object[k] = '';
            }
            report_data = [report_data_object];
          }

          const shippo_report = {
            reports: reports,
            report_data: report_data,
            csv_columns: keys,
            columns: columns,
            account_id: res.data.account_id,
            account_email: res.data.account_email,
          };

          dispatch(fetchShippoReportSuccess(shippo_report));

          return res;
        })
        .catch(function (err) {
          dispatch(fetchShippoReportError(err));
        });
    }
  };
}

export function fetchShippoReportPending() {
  return {
    type: FETCH_SHIPPO_REPORT_PENDING,
  };
}

export function fetchShippoReportSuccess(shippo_report) {
  return {
    type: FETCH_SHIPPO_REPORT_SUCCESS,
    shippo_report: shippo_report,
  };
}

export function fetchShippoReportError(error) {
  return {
    type: FETCH_SHIPPO_REPORT_ERROR,
    error: error,
  };
}

export function fetchShippoReportDateError(error) {
  return {
    type: FETCH_SHIPPO_REPORT_DATE_ERROR,
    error: error,
  };
}

export function onStartDateSelect(event) {
  return {
    type: SET_START_DATE,
    start_date: event.target.value,
  };
}

export function onEndDateSelect(event) {
  return {
    type: SET_END_DATE,
    end_date: event.target.value,
  };
}
