import './account_programs.scss';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class ReferralOptIn extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
  }

  /**
   * Handle opt in click
   * @function
   */
  onToggle(e) {
    if (this.props.opted_in) this.props.onOptingOut();
    else this.props.showAgreement();
  }

  optInBtn = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <input
              type="checkbox"
              disabled={!userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions)}
              checked={this.props.opted_in}
              onChange={this.onToggle}
            />
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  render() {
    return (
      <div className="program_content">
        <h3 className="emphasis line-division--limited ">{this.props.title}</h3>
        <label className="switch">
          {this.optInBtn()}
          <span className="slider round ipp" />
        </label>
        <div>
          <h4 className="pg_content_light_header">Status</h4>
          <div>{this.props.opted_in ? 'Opted in' : 'Not Opted in'}</div>
          <h4 className="pg_content_light_header">Service Agreement</h4>
          <a href={this.props.agreement_link}>InBrace Patient Referral Code Program Services Agreement</a>
        </div>
        <div>
          <h4 className="pg_content_header">About the Patient Referral Program</h4>
          <p>
            The Patient Referral Program is created to drive awareness of InBrace and to help expand your practice.
            <br />
            <br />
            We’ve developed this high value referral program for new patients that will leverage their quick speed to satisfaction experience by encouraging
            them to share InBrace with a friend.
            <br />
            This will:
          </p>
          <ul>
            <li>Expand current network and patient reach</li>
            <li>Help you grow your practice faster</li>
            <li>Reduce your costs for reaching new patients</li>
          </ul>
          <p>InBrace is creating awareness by:</p>
          <ul>
            <li>Partnering with influencers to reach their audiences</li>
            <li>Develop relationships with high value review sites (like Real Self)</li>
            <li>Select curated editorial partners (e.g. bloggers and beauty & health publications)</li>
          </ul>
        </div>
        <div className="workflow_container">
          <h4 className="pg_content_header">How the Program Works</h4>
          <div className="workflow">
            <div className="box">
              <div className="rectangle"></div>
              <div className="triangle-right"></div>
              <span>Referee</span>
            </div>
            <div className="box">
              <div className="rectangle"></div>
              <div className="triangle-right"></div>
              <span>Share with Prospect Patient</span>
            </div>
            <div className="box">
              <div className="rectangle"></div>
              <div className="triangle-right"></div>
              <span>InBrace Landing Page Code Claim</span>
            </div>
            <div className="box">
              <div className="rectangle"></div>
              <div className="triangle-right"></div>
              <span>Find a Provider</span>
            </div>
            <div className="box">
              <span>New Patient Start</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReferralOptIn);
