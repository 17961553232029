import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { updateIPRDiff, updateTMTDiff } from '../../../redux/actions/wasm_viewer/wasm_viewer';
import { getWasmEditingTreatmentPlanIndex, getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { getIPRAndTMTTablesDifference } from '../wasm_controller/wasm_controller';

export const withIprAndTmtChanges = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { updateIPRDiff, updateTMTDiff, editingTreatmentPlanIndex } = props;
    const handleFetchIRPAndTMTChanges = () => {
      const { ipr, tmt } = getIPRAndTMTTablesDifference(editingTreatmentPlanIndex);
      updateIPRDiff(ipr);
      updateTMTDiff(tmt);
    };

    return <WrappedComponent {...props} onFetchIPRAndTMTChanges={handleFetchIRPAndTMTChanges} />;
  };

  const mapStateToProps = (state) => {
    const wasmViewerState = getWasmViewerState(state);
    return {
      treatmentPlanStage: wasmViewerState.step,
      activeTreatmentPlan: wasmViewerState.active_treatment_plan,
      editingTreatmentPlanIndex: getWasmEditingTreatmentPlanIndex(state),
    };
  };

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        updateIPRDiff: updateIPRDiff,
        updateTMTDiff: updateTMTDiff,
      },
      dispatch
    );

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
