import {
  FETCH_ACCOUNT_PENDING,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNT_ERROR,
  CREATE_ACCOUNT_PENDING,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_INCOMPLETE,
  ON_INPUT_CHANGE,
  ON_SETADDMODE_CLICK,
  ON_SETEDITMODE_CLICK,
  ON_EDIT_CHANGE,
  ON_NEW_CHANGE,
  ON_ACCOUNT_CREATE_CHANGE,
  ON_MOUNT,
} from '../../actions/ipp/add_account';

const initialState = {
  account: '',
  account_pending: false,
  account_error: false,
  account_create: {
    notification_emails: [],
    phone_numbers: [],
  },
  account_create_pending: false,
  account_create_error: false,
  account_create_success: false,
  account_create_success_id: '',
  fields: [
    {
      id: 'first_name',
      label: 'First Name',
      type: 'text',
      max_length: 30,
      required: true,
      value: '',
    },
    {
      id: 'last_name',
      label: 'Last Name',
      type: 'text',
      max_length: 30,
      required: true,
      value: '',
    },
    {
      id: 'provider_id',
      label: 'Provider ID',
      type: 'text',
      max_length: 20,
      required: false,
      value: '',
    },
    {
      id: 'npi_number',
      label: 'NPI Number',
      type: 'text',
      max_length: 15,
      required: true,
      value: '',
    },
    {
      id: 'email',
      label: 'Account Email',
      type: 'text',
      max_length: 100,
      required: true,
      value: '',
    },
    {
      id: 'role',
      label: 'Role',
      type: 'option',
      max_length: '',
      required: true,
      value: 'Doctor',
      option: ['Doctor', 'Doctor Override'],
    },
  ],
  warning: false,
  shipping_address: [],
  //Edit Params
  edit_profile: {
    id: '',
    mode: '',
    value: '',
    type: '',
    notification: false,
    error: false,
    is_active: true,
  },
  //New Params
  new_profile: {
    mode: '',
    value: '',
    type: 'Office',
    notification: true,
    error: false,
    is_active: true,
  },
  new_error_msg: '',
  edit_error_msg: '',
  refresh: false,
  not_found_error: false,
  status_error: '',
};

export function accountAddReducer(state = initialState, action) {
  switch (action.type) {
    case ON_MOUNT:
      if (!state['account_error']) return initialState;
      break;

    case FETCH_ACCOUNT_PENDING:
      return {
        ...state,
        account_pending: true,
      };

    case FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        account_pending: false,
        account_error: false,
        account: action.account,
      };

    case FETCH_ACCOUNT_ERROR:
      return {
        ...state,
        account_pending: false,
        account_error: true,
      };

    case CREATE_ACCOUNT_PENDING:
      return {
        ...state,
        account_create_pending: true,
        account_create_error: false,
      };

    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account_create_pending: false,
        account_create_error: false,
        account_create_success: true,
        account_create_success_id: action.id,
      };

    case CREATE_ACCOUNT_ERROR:
      return {
        ...state,
        account_create_pending: false,
        account_create_error: true,
        status_error: action.status_error,
      };

    case CREATE_ACCOUNT_INCOMPLETE:
      return {
        ...state,
        warning: true,
      };

    case ON_ACCOUNT_CREATE_CHANGE:
      return {
        ...state,
        account_create: action.account_create,
        warning: action.warning,
      };

    case ON_INPUT_CHANGE:
      return {
        ...state,
        fields: action.fields,
        warning: action.warning,
      };

    case ON_NEW_CHANGE:
      return {
        ...state,
        new_profile: action.new_profile,
        new_error_msg: action.new_error_msg,
        warning: action.warning,
      };

    case ON_SETADDMODE_CLICK:
      return {
        ...state,
        new_profile: action.new_profile,
        new_error_msg: action.new_error_msg,
      };

    case ON_EDIT_CHANGE:
      return {
        ...state,
        edit_profile: action.edit_profile,
        edit_error_msg: action.edit_error_msg,
        waring: action.warning,
      };

    case ON_SETEDITMODE_CLICK:
      return {
        ...state,
        edit_profile: action.edit_profile,
        edit_error_msg: action.edit_error_msg,
      };

    default:
      return state;
  }
}

export const getAccount = (state) => state.accountAddReducer.account;
export const getAccountError = (state) => state.accountAddReducer.account_error;
export const getAccountPending = (state) => state.accountAddReducer.account_pending;
export const getAccountCreate = (state) => state.accountAddReducer.account_create;
export const getAccountCreateError = (state) => state.accountAddReducer.account_create_error;
export const getAccountCreatePending = (state) => state.accountAddReducer.account_create_pending;
export const getAccountCreateStatusCode = (state) => state.accountAddReducer.status_error;
export const getAccountCreateSuccess = (state) => state.accountAddReducer.account_create_success;
export const getAccountCreateSuccessID = (state) => state.accountAddReducer.account_create_success_id;
export const getEditErrorMessage = (state) => state.accountAddReducer.edit_error_msg;
export const getNewErrorMessage = (state) => state.accountAddReducer.new_error_msg;
export const getNewMode = (state) => state.accountAddReducer.new_profile;
export const getEditMode = (state) => state.accountAddReducer.edit_profile;
export const getFields = (state) => state.accountAddReducer.fields;
export const getWarning = (state) => state.accountAddReducer.warning;
