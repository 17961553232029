import React, { Component } from 'react';

class NextButton extends Component {
  render() {
    return (
      <div className="bpp-submit__next" data-description={this.props.description} data-position={this.props.position} onClick={this.props.onButtonClick}>
        <i className="fa fa-chevron-right" aria-hidden="true" />
        <p className="bpp-next-back-buttons">Next</p>
      </div>
    );
  }
}

export default NextButton;
