import React from 'react';
import { Box } from '@material-ui/core';

import ManualWireTableLocketSelect from './manual_wire_table_locket_select';
import { getFilteredIndexes } from '../../manual_wire_utils';
import './manual_wire_table_locket.scss';

const ManualWireTableLocket = ({ arch, missingTeeths, range, disableAllFields }) => {
    const filteredIndexes = getFilteredIndexes(arch, missingTeeths, range, 16);

    return (
        <Box display="flex" flexDirection="row" className='manual-wire-locket'>
            {filteredIndexes.map((teethIndex, index) => (
                <Box
                    className='manual-wire-locket-element'
                    key={`wire-loop-element-${index}`}
                >
                    <ManualWireTableLocketSelect
                        arch={arch}
                        index={teethIndex}
                        filteredIndexes={filteredIndexes}
                        disableAllFields={disableAllFields}
                    />
                </Box>
            ))}
        </Box>
    )
};

export default ManualWireTableLocket;
