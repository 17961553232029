/**
 * File: content_detail.js - Contains the content for the doctors account details for the user
 * Copyright: (c) Copyright November 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Internal Components
import ContentUser from './content_user';
import ContentPassword from './content_password';
import Modal from '../../components/modal/modal';
// Internal Functions
import SecurityClearance from '../../components/security/security_clearance';
import Utils from './utils';
import { setTokenHeader, removeEmoji, phoneNumberChar, formatPhoneNumber } from '../../common/functions';
import { getDoctorsIdFromRoute } from '../../common/route';
import { handleHttpRequestError } from '../../common/error';
import { onReloadPage } from '../../common/helpers';
// Context
import {
  hasMatchingPassword,
  getErrorMessageForPasswordMisMatch,
  hasMetAllPasswordCondition,
  hasAnyPasswordErrorMessage,
} from '../../common/validation/password';
import CircleLoader from '../../components/loader/circle_loader';
import Button from '../components/buttons/button';
import LineDivision from '../components/divisions/line_division';
import EditTable from '../components/tables/edit_table';
import TextBox from '../components/inputs/text_box';
import AccountAddress from './account_address';

class ContentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wrongPasswordError: '',
      modals: {
        showEditEmailModal: false,
        showDeleteEmailModal: false,
        showAddEmailModal: false,
        showEditPhoneNoModal: false,
        showDeletePhoneModal: false,
        showAddPhoneModal: false,
        showPasswordChangedModal: false,
      },
      passwordChangeMessage: '',
      user: {
        name: '',
        loginEmail: '',
        notificationEmailList: [],
        phoneNumberList: [],
      },
      selectedEmail: null,
      selectedPhoneNumber: null,

      account: {},
      //Edit Params
      edit_profile: {
        id: '',
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        is_active: true,
      },
      //New Params
      new_profile: {
        mode: '',
        value: '',
        type: 'Office',
        notification: true,
        error: false,
        is_active: true,
      },
      loaded: false,
      new_error_msg: '',
      edit_error_msg: '',
      refresh: false,
    };

    this.clearEditMode = this.clearEditMode.bind(this);
    this.getOldNotificationById = this.getOldNotificationById.bind(this);
    this.getOldTypeByMode = this.getOldTypeByMode.bind(this);
    this.getOldValueByMode = this.getOldValueByMode.bind(this);
    this.getOldValueById = this.getOldValueById.bind(this);
    this.isNewEmail = this.isNewEmail.bind(this);
    this.isNewPhoneNo = this.isNewPhoneNo.bind(this);
    this.saveNewChanges = this.saveNewChanges.bind(this);
    this.setAddMode = this.setAddMode.bind(this);
    this.setEditMode = this.setEditMode.bind(this);
    this.setEditValue = this.setEditValue.bind(this);
  }

  componentDidMount() {
    const doctor_id = getDoctorsIdFromRoute();

    setTokenHeader();
    const that = this;

    if (doctor_id) {
      Axios.get(`/apiV2/account_setting/${doctor_id}`).then(function (result) {
        that.setState({
          account: result.data,
          loaded: true,
        });
      });
    }
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  //To show modal, pass the name of the modal as in state config
  showModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = true;
    this.setState({ modals: modals });
  };

  //To hide modal, pass the name of the modal as in state config
  hideModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = false;
    this.setState({ modals: modals });
  };

  //Called when either edit or delete is clicked on a row item in phone no list
  setSelectedPhone = (phone, type, index) => {
    this.setState({ selectedPhone: { type: type, value: phone, index: index } });
  };

  //Called when user clicks on change password button
  updatePassword = (currentPassword, newPassword1, newPassword2, state) => {
    setTokenHeader();
    const that = this;
    var profile = {};
    profile['password'] = currentPassword;
    profile['password--new'] = newPassword1;

    if (!hasMatchingPassword(newPassword1, newPassword2)) {
      that.setState({
        wrongPasswordError: getErrorMessageForPasswordMisMatch(),
      });

      return;
    }

    if (!hasMetAllPasswordCondition(newPassword1)) {
      that.setState({
        wrongPasswordError: hasAnyPasswordErrorMessage(newPassword1),
      });

      return;
    }

    Axios.put('/apiv3/user/password', profile)
      .then(function (res) {
        that.setState({ passwordChangeMessage: 'Your password has been changed successfully!', wrongPasswordError: '' }, function () {
          that.showModal('showPasswordChangedModal');
          state.setState({
            currentPassword: '',
            newPassword1: '',
            newPassword2: '',
          });
        });
      })
      .catch(function (err) {
        that.setState({ wrongPasswordError: 'Please enter the correct current password' });
      });
  };

  clearEditMode = () => {
    this.setState({
      edit_profile: {
        id: '',
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        is_active: true,
      },
      edit_error_msg: '',
    });
  };

  clearNewMode = () => {
    this.setState({
      new_profile: {
        id: '',
        mode: '',
        value: '',
        type: 'Office',
        notification: true,
        error: false,
        is_active: true,
      },
      new_error_msg: '',
    });
  };

  /**
   * Clears password error message on change
   * @function
   */
  clearError = () => {
    this.setState({ wrongPasswordError: '' });
  };

  getActionByModeDelete(mode) {
    let action = '';

    switch (mode) {
      case 'edit_number':
        action = 'remove_number';
        break;
      case 'edit_email':
        action = 'remove_email';
        break;

      default:
    }

    return action;
  }

  deleteEntry(event) {
    setTokenHeader();
    const that = this;
    const id = this.state.edit_profile.id;
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const old_type = this.getOldTypeByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const action = this.getActionByModeDelete(this.state.edit_profile.mode);

    let data = new FormData();
    data.append('value', old_value);
    data.append('type', old_type);
    data.append('id', id);

    Axios.post(`/apiV2/accountaction/${this.state.account.account_id}/${action}`, data)
      .then(function (res) {
        that.setState({
          account: res.data,
          edit_profile: {
            id: '',
            mode: '',
            value: '',
            type: '',
            notification: false,
            error: false,
            is_active: true,
          },
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  getActionByMode(mode) {
    let action = '';

    switch (mode) {
      case 'edit_number':
        action = 'update_number';
        break;

      case 'edit_email':
        action = 'update_email';
        break;

      default:
    }

    return action;
  }

  getOldValueById(id, list) {
    let result = '';

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        result = list[i].value ? list[i].value : list[i].address_name ? list[i].address_name : '';
      }
    }

    return result;
  }

  getOldValueByMode(mode, id) {
    let old_value = '';
    let list = [];

    switch (mode) {
      case 'edit_number':
        list = this.state.account.phone_numbers;
        old_value = this.getOldValueById(id, list);
        break;

      case 'edit_email':
        list = this.state.account.notification_emails;
        old_value = this.getOldValueById(id, list);
        break;

      default:
    }

    return old_value;
  }

  getOldTypeByMode(mode, id) {
    let old_type = '';
    let list = [];

    switch (mode) {
      case 'edit_number':
        list = this.state.account.phone_numbers;
        break;

      case 'edit_email':
        list = this.state.account.notification_emails;
        break;

      default:
    }

    old_type = this.getOldTypeById(id, list);

    return old_type;
  }

  getOldTypeById(id, list) {
    let result = '';

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        result = list[i].type;
      }
    }

    return result;
  }

  getOldNotificationByMode(mode, id) {
    let old_notification = '';

    switch (mode) {
      case 'edit_email':
        old_notification = this.getOldNotificationById(id);
        break;

      default:
    }

    return old_notification;
  }

  getOldNotificationById(id) {
    const notifications = this.state.account.notification_emails;
    let result = '';

    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].id === id) {
        result = notifications[i].notification;
        break;
      }
    }

    return result;
  }

  isValidPhoneNo(value) {
    let is_valid = false;

    try {
      const usa_number = parsePhoneNumber(value, 'US');
      const valid_number = Utils.isValidPhoneNo(value);

      if (valid_number && usa_number.isValid()) {
        is_valid = true;
      }
    } catch (error) {
      // Do Nothing
    }

    return is_valid;
  }

  formatValueByMode(mode, value) {
    let new_value = value;

    switch (mode) {
      case 'add_number':
      case 'edit_number':
        const usa_number = parsePhoneNumber(value, 'US');
        new_value = usa_number.format('NATIONAL');
        break;
      default:
    }

    return new_value;
  }

  isNewPhoneNo(value, id = '') {
    const phones = this.state.account.phone_numbers;
    let is_new = true;

    for (let i = 0; i < phones.length; i++) {
      if (phones[i].value === formatPhoneNumber(value)) {
        if (phones[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  isNewEmail(value, id = '') {
    const emails = this.state.account.notification_emails;
    let is_new = true;

    for (let i = 0; i < emails.length; i++) {
      if (emails[i].value === value) {
        if (emails[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  isEnterKeyToSaveEditChanges = (e) => {
    if (e.key === 'Enter') {
      this.saveEditChanges();
    }
  };
  saveEditChanges = () => {
    setTokenHeader();

    const that = this;
    const id = this.state.edit_profile.id;
    const old_value = this.getOldValueByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const new_type = this.state.edit_profile.type;
    const old_type = this.getOldTypeByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const notification = this.state.edit_profile.notification;
    const old_notification = this.getOldNotificationByMode(this.state.edit_profile.mode, this.state.edit_profile.id);
    const mode = this.state.edit_profile.mode;
    const action = this.getActionByMode(this.state.edit_profile.mode);

    let new_value = this.state.edit_profile.value;
    new_value = that.formatValueByMode(mode, new_value);

    if (
      old_value !== undefined &&
      new_value !== undefined &&
      ((that.isValidPhoneNo(new_value) && new_type !== '' && that.isNewPhoneNo(new_value, id) && mode === 'edit_number') ||
        (Utils.isValidEmail(new_value) && new_type !== '' && that.isNewEmail(new_value, id) && mode === 'edit_email'))
    ) {
      let data = new FormData();

      // eslint-disable-next-line
      let old_address = [];

      data.append('value', new_value);
      data.append('old_value', old_value);
      data.append('type', new_type);
      data.append('old_type', old_type);
      data.append('notification', notification);
      data.append('old_notification', old_notification);
      data.append('id', id);

      Axios.post(`/apiV2/accountaction/${that.state.account.account_id}/${action}`, data)
        .then(function (res) {
          that.setState({
            account: res.data,
            edit_profile: {
              id: '',
              mode: '',
              value: '',
              type: '',
              notification: false,
              error: false,
              is_active: true,
            },
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      that.setState({
        edit_profile: {
          id: that.state.edit_profile.id,
          mode: that.state.edit_profile.mode,
          notification: that.state.edit_profile.notification,
          value: that.state.edit_profile.value,
          type: that.state.edit_profile.type,
          error: true,
          is_active: that.state.edit_profile.is_active,
        },
        edit_error_msg:
          that.state.edit_profile.mode === 'edit_number' && !that.isNewPhoneNo(new_value)
            ? 'Telephone number already exists'
            : that.state.edit_profile.mode === 'edit_email' && !that.isNewEmail(new_value)
            ? 'Email address already exists'
            : '',
      });
    }
  };

  getLatestNumberId() {
    let id = '';

    // Data pass from backend is sorted by created date
    if (this.state.profile && this.state.profile.phone_numbers && this.state.profile.phone_numbers.length > 0) {
      id = this.state.profile.phone_numbers[this.state.profile.phone_numbers.length - 1].id;
    }

    return id;
  }

  getAddActionByMode(mode) {
    let action = mode;

    switch (mode) {
      case 'add_number':
      case 'add_email':
        action = mode;
        break;
      default:
    }

    return action;
  }

  isEnterKeyToSaveNewChanges = (e) => {
    if (e.key === 'Enter') {
      this.saveNewChanges();
    }
  };

  saveNewChanges = () => {
    setTokenHeader();
    const that = this;
    const type = that.state.new_profile.type;
    const lastest_id = that.getLatestNumberId();
    const notification = that.state.new_profile.notification;
    const mode = that.state.new_profile.mode;
    let value = that.state.new_profile.value;

    if (
      (that.isValidPhoneNo(value) && type !== '' && mode === 'add_number' && this.isNewPhoneNo(value)) ||
      (Utils.isValidEmail(value) && that.isNewEmail(value) && mode === 'add_email')
    ) {
      value = that.formatValueByMode(mode, value);
      let action = that.getAddActionByMode(mode);

      let data = new FormData();
      data.append('id', lastest_id);
      data.append('notification', notification);
      data.append('type', type);
      data.append('value', value.trim());

      Axios.post(`/apiV2/accountaction/${this.state.account.account_id}/${action}`, data)
        .then(function (res) {
          that.setState({
            account: res.data,
            new_profile: {
              mode: '',
              value: '',
              type: 'Office',
              notification: true,
              error: false,
              is_active: true,
            },
            new_error_msg: '',
          });
        })
        .catch(function (err) {
          if (err.response.status === 409) {
            that.setState({
              new_profile: {
                error: true,
                mode: that.state.new_profile.mode,
                notification: that.state.new_profile.notification,
                type: that.state.new_profile.type,
                value: that.state.new_profile.value,
                is_active: that.state.new_profile.is_active,
              },
              new_error_msg:
                that.state.new_profile.mode === 'add_number' && !that.isNewPhoneNo(value)
                  ? 'Telephone number already exists'
                  : that.state.new_profile.mode === 'add_email' && !that.isNewEmail(value)
                  ? 'Email address already exists'
                  : '',
            });
          } else {
            handleHttpRequestError(err, that);
          }
        });
    } else {
      that.setState({
        new_profile: {
          error: true,
          mode: that.state.new_profile.mode,
          notification: that.state.new_profile.notification,
          type: that.state.new_profile.type,
          value: that.state.new_profile.value,
          is_active: that.state.new_profile.is_active,
        },
        new_error_msg:
          that.state.new_profile.mode === 'add_number' && !that.isNewPhoneNo(value)
            ? 'Telephone number already exists'
            : that.state.new_profile.mode === 'add_email' && !that.isNewEmail(value)
            ? 'Email address already exists'
            : '',
      });
    }
  };

  setAddMode = (mode) => {
    this.setState({
      new_profile: {
        mode: mode,
        value: this.state.new_profile.value,
        type: this.state.new_profile.type,
        notification: this.state.new_profile.notification,
        error: false,
        is_active: this.state.new_profile.is_active,
      },
      new_error_msg: '',
      wrongPasswordError: '',
    });
  };

  setEditMode = (mode, contact_details) => {
    if (this.state.edit_profile.mode === '' && this.state.new_profile.mode === '') {
      this.setState({
        edit_profile: {
          id: contact_details.id,
          mode: mode,
          value: contact_details.value,
          type: contact_details.type,
          notification: contact_details.notification,
          error: false,
          is_active: contact_details.is_active,
        },
        wrongPasswordError: '',
      });
    }
  };

  setEditValue = (key, value) => {
    let new_value = removeEmoji(this.state.edit_profile.value);
    let notification = this.state.edit_profile.notification;
    let type = this.state.edit_profile.type;
    switch (key) {
      case 'type':
        type = value;
        break;

      case 'notification':
        notification = value;
        break;

      case 'value':
        new_value = removeEmoji(value.target.value);
        break;

      default:
    }

    this.setState({
      edit_profile: {
        id: this.state.edit_profile.id,
        active: this.state.edit_profile.is_active,
        mode: this.state.edit_profile.mode,
        value: new_value,
        type: type,
        notification: notification,
        error: false,
        is_active: this.state.edit_profile.is_active,
      },
    });
  };

  setNewValue = (key, value) => {
    let new_value = removeEmoji(this.state.new_profile.value);
    let notification = this.state.new_profile.notification;
    let type = this.state.new_profile.type;

    switch (key) {
      case 'type':
        type = value;
        break;

      case 'notification':
        notification = value;
        break;

      case 'value':
        new_value = removeEmoji(value.target.value);
        break;

      default:
    }

    this.setState({
      new_profile: {
        mode: this.state.new_profile.mode,
        value: new_value,
        type: type,
        notification: notification,
        error: false,
        is_active: this.state.new_profile.is_active,
      },
      new_error_msg: '',
    });
  };

  isEditable = (email) => {
    return email.value !== this.state.account.account_email;
  };

  render() {
    const that = this;

    const notification_data = [
      {
        id: 'email_type',
        label: 'Type',
        col_width: '25%',
        mobile_order: 2,
        data_fields: ['type'],
        data_function: (props, data, fields, label = '') => {
          const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

          return props.editContent.id === data.id && data.is_active ? (
            <>
              {label}
              <li className="dropdown heading-value-dropdown">
                <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                  {props.editContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('type', 'Office')}>Office</a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('type', 'Personal')}>Personal</a>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            value
          );
        },
        add_function: (props) => {
          return (
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.addContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('type', 'Office')}>Office</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('type', 'Personal')}>Personal</a>
                </li>
              </ul>
            </li>
          );
        },
      },
      {
        id: 'email_name',
        label: 'Email',
        col_width: '50%',
        ellipsis: true,
        mobile_order: 1,
        data_fields: ['value'],
        data_function: (props, data, fields, label = '') => {
          const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

          return props.editContent.id === data.id && data.is_active ? (
            <>
              {label}
              {that.isEditable(data) ? (
                <TextBox
                  type="email"
                  value={props.editContent.value}
                  onChange={(e) => that.setEditValue('value', e)}
                  onKeyPress={(e) => that.isEnterKeyToSaveEditChanges(e)}
                  maxLength={100}
                />
              ) : (
                <div className="ellipsis fs-exclude">{value}</div>
              )}
            </>
          ) : (
            <div className="ellipsis fs-exclude">{value}</div>
          );
        },
        add_function: (props) => {
          return (
            <TextBox
              type="email"
              value={props.addContent.value}
              onChange={(e) => that.setNewValue('value', e)}
              onKeyPress={(e) => that.isEnterKeyToSaveNewChanges(e)}
              autoFocus
              maxLength={100}
            />
          );
        },
      },
      {
        id: 'email_notifications',
        label: 'Notifications',
        col_width: '25%',
        mobile_order: 3,
        data_fields: ['notification'],
        data_function: (props, data, fields, label) => {
          const field_values = fields.map((field) => {
            let value = data[field] ? 'Yes' : 'No';
            return label ? value.replace('No', 'Notification Disabled').replace('Yes', 'Notification Enabled') : value;
          });

          return props.editContent.id === data.id && data.is_active ? (
            <>
              {label}
              <li className="dropdown heading-value-dropdown">
                <div className="heading-item heading-enable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                  {props.editContent.notification ? 'Yes' : 'No'} <i className="fa fa-caret-down" aria-hidden="true" />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('notification', true)}>Yes</a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('notification', false)}>No</a>
                  </li>
                </ul>
              </li>
            </>
          ) : field_values && field_values.length > 0 ? (
            field_values[0]
          ) : (
            '- - - - -'
          );
        },
        add_function: (props) => {
          return (
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-enable" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.addContent.notification ? 'Yes' : 'No'} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('notification', true)}>Yes</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('notification', false)}>No</a>
                </li>
              </ul>
            </li>
          );
        },
      },
      {
        id: 'email_buttons',
        action: true,
        mobile_order: 4,
        action_function: (props, data, fields, show) => {
          if (props.addEnabled && data.id === 'add_buttons') {
            return (
              <>
                <div className="hide-on-mobile svg-button">
                  <div
                    className="action-buttons save-svg-button"
                    onClick={() => that.saveNewChanges()}
                    data-toggle="tooltip"
                    title="Save"
                    placement="top"
                    onDoubleClick={() => that.saveNewChanges()}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true" />
                    </span>
                  </div>
                  <div
                    className="action-buttons cancel-svg-button"
                    onClick={() => that.clearNewMode()}
                    onDoubleClick={() => that.clearNewMode()}
                    data-toggle="tooltip"
                    title="Cancel"
                    placement="top"
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="show-on-mobile">
                  <Button onClick={() => that.saveNewChanges()}>Save</Button>
                  <Button theme="secondary" onClick={() => that.clearNewMode()}>
                    Cancel
                  </Button>
                </div>
              </>
            );
          } else if ((props.editContent.id === data.id && data.is_active) || show) {
            return (
              <>
                <div className="hide-on-mobile svg-button">
                  <div
                    className="action-buttons save-svg-button"
                    onClick={() => that.saveEditChanges()}
                    onDoubleClick={() => that.saveEditChanges()}
                    data-toggle="tooltip"
                    title="Save"
                    placement="top"
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true" />
                    </span>
                  </div>

                  {that.isEditable(data) ? (
                    <div
                      className="action-buttons delete-svg-button"
                      onClick={() => that.deleteEntry()}
                      onDoubleClick={() => that.deleteEntry()}
                      data-toggle="tooltip"
                      title="Delete"
                      placement="top"
                    >
                      <span>
                        <FontAwesomeIcon icon={['fas', 'trash']} aria-hidden="true" />
                      </span>
                    </div>
                  ) : null}
                  <div
                    className="action-buttons cancel-svg-button"
                    onClick={() => that.clearEditMode()}
                    onDoubleClick={() => that.clearEditMode()}
                    data-toggle="tooltip"
                    title="Cancel"
                    placement="top"
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="show-on-mobile">
                  <Button onClick={() => that.saveEditChanges()}>Save</Button>
                  {that.isEditable(data) ? (
                    <Button theme="revise" onClick={() => that.deleteEntry()}>
                      Delete
                    </Button>
                  ) : null}
                  <Button theme="secondary" onClick={() => that.clearEditMode()}>
                    Cancel
                  </Button>
                </div>
              </>
            );
          }
          return null;
        },
        add_function: (props) => {
          return (
            <>
              <div className="hide-on-mobile svg-button">
                <div
                  className="action-buttons save-svg-button"
                  onClick={() => that.saveNewChanges()}
                  onDoubleClick={() => that.saveNewChanges()}
                  data-toggle="tooltip"
                  title="Save"
                  placement="top"
                >
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
                <div
                  className="action-buttons cancel-svg-button"
                  onClick={() => that.clearNewMode()}
                  onDoubleClick={() => that.clearNewMode()}
                  data-toggle="tooltip"
                  title="Cancel"
                  placement="top"
                >
                  <span>
                    <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                  </span>
                </div>
              </div>
              <div className="show-on-mobile">
                <Button onClick={() => that.saveNewChanges()}>Save</Button>
                <Button theme="secondary" onClick={() => that.clearNewMode()}>
                  Cancel
                </Button>
              </div>
            </>
          );
        },
      },
      {
        id: 'email_add_buttons',
        add: true,
        mobile_order: 5,
        add_function: (props, fields) => {
          return (
            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <Button
                className="float--right"
                onClick={() => that.setAddMode('add_email')}
                disabled={!(props.addContent.mode === '' && props.editContent.mode === '')}
              >
                Add
              </Button>
            </SecurityClearance>
          );
        },
      },
    ];

    const phone_data = [
      {
        id: 'phone_type',
        label: 'Type',
        col_width: '50%',
        mobile_order: 2,
        data_fields: ['type'],
        data_function: (props, data, fields, label = '') => {
          const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

          return props.editContent.id === data.id && data.is_active ? (
            <>
              {label}
              <li className="dropdown heading-value-dropdown">
                <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                  {props.editContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('type', 'Office')}>Office</a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('type', 'Home')}>Home</a>
                  </li>
                  <li>
                    {/* eslint-disable-next-line */}
                    <a onClick={() => that.setEditValue('type', 'Mobile')}>Mobile</a>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            value
          );
        },
        add_function: (props) => {
          return (
            <li className="dropdown heading-value-dropdown">
              <div className="heading-item heading-type" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" type="button">
                {props.addContent.type} <i className="fa fa-caret-down" aria-hidden="true" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('type', 'Office')}>Office</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('type', 'Home')}>Home</a>
                </li>
                <li>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => that.setNewValue('type', 'Mobile')}>Mobile</a>
                </li>
              </ul>
            </li>
          );
        },
      },
      {
        id: 'phone_name',
        label: 'Number',
        col_width: '50%',
        ellipsis: true,
        mobile_order: 1,
        data_fields: ['value'],
        data_function: (props, data, fields, label = '') => {
          const value = fields && fields.length > 0 && data[fields[0]] ? data[fields[0]] : '';

          return props.editContent.id === data.id && data.is_active ? (
            <>
              {label}
              <TextBox
                value={props.editContent.value}
                onChange={(e) => {
                  e.target.value = phoneNumberChar(e.target.value);
                  that.setEditValue('value', e);
                }}
                onKeyPress={(e) => that.isEnterKeyToSaveEditChanges(e)}
              />
            </>
          ) : (
            <span className="fs-exclude">{value}</span>
          );
        },
        add_function: (props) => {
          return (
            <TextBox
              value={props.addContent.value}
              onChange={(e) => {
                e.target.value = phoneNumberChar(e.target.value);
                that.setNewValue('value', e);
              }}
              onKeyPress={(e) => that.isEnterKeyToSaveNewChanges(e)}
              autoFocus
            />
          );
        },
      },
      {
        id: 'phone_buttons',
        action: true,
        mobile_order: 4,
        action_function: (props, data, fields, show) => {
          if (props.addEnabled && data.id === 'add_buttons') {
            return (
              <>
                <div className="hide-on-mobile svg-button">
                  <div
                    className="action-buttons save-svg-button"
                    data-toggle="tooltip"
                    title="Save"
                    placement="top"
                    onClick={() => that.saveNewChanges()}
                    onDoubleClick={() => that.saveNewChanges()}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true" />
                    </span>
                  </div>
                  <div
                    className="action-buttons cancel-svg-button"
                    data-toggle="tooltip"
                    title="Cancel"
                    placement="top"
                    onClick={() => that.clearNewMode()}
                    onDoubleClick={() => that.clearNewMode()}
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="show-on-mobiless">
                  <Button onClick={() => that.saveNewChanges()}>Save</Button>
                  <Button theme="secondary" onClick={() => that.clearNewMode()}>
                    Cancel
                  </Button>
                </div>
              </>
            );
          } else if ((props.editContent.id === data.id && data.is_active) || show) {
            return (
              <>
                <div className="hide-on-mobile svg-button">
                  <div
                    className="action-buttons save-svg-button"
                    data-toggle="tooltip"
                    title="Save"
                    placement="top"
                    onClick={() => that.saveEditChanges()}
                    onDoubleClick={() => that.saveEditChanges()}
                  >
                    <span>
                      <i className="fa fa-check" aria-hidden="true" />
                    </span>
                  </div>
                  <div
                    className="action-buttons delete-svg-button"
                    data-toggle="tooltip"
                    title="Delete"
                    placement="top"
                    onClick={() => that.deleteEntry()}
                    onDoubleClick={() => that.deleteEntry()}
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'trash']} aria-hidden="true" />
                    </span>
                  </div>
                  <div
                    className="action-buttons cancel-svg-button"
                    data-toggle="tooltip"
                    title="Cancel"
                    placement="top"
                    onClick={() => that.clearEditMode()}
                    onDoubleClick={() => that.clearEditMode()}
                  >
                    <span>
                      <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                    </span>
                  </div>
                </div>
                <div className="show-on-mobile">
                  <Button onClick={() => that.saveEditChanges()}>Save</Button>
                  <Button theme="revise" onClick={() => that.deleteEntry()}>
                    Delete
                  </Button>
                  <Button theme="secondary" onClick={() => that.clearEditMode()}>
                    Cancel
                  </Button>
                </div>
              </>
            );
          }
          return null;
        },
        add_function: (props) => {
          return (
            <>
              <div className="hide-on-mobile svg-button">
                <div
                  className="action-buttons save-svg-button"
                  data-toggle="tooltip"
                  title="Save"
                  placement="top"
                  onClick={() => that.saveNewChanges()}
                  onDoubleClick={() => that.saveNewChanges()}
                >
                  <span>
                    <i className="fa fa-check" aria-hidden="true" />
                  </span>
                </div>
                <div
                  className="action-buttons cancel-svg-button"
                  data-toggle="tooltip"
                  title="Cancel"
                  placement="top"
                  onClick={() => that.clearNewMode()}
                  onDoubleClick={() => that.clearNewMode()}
                >
                  <span>
                    <FontAwesomeIcon icon={['fas', 'times']} aria-hidden="true" />
                  </span>
                </div>
              </div>
              <div className="show-on-mobile">
                <Button onClick={() => that.saveNewChanges()}>Save</Button>

                <Button theme="secondary" onClick={() => that.clearNewMode()}>
                  Cancel
                </Button>
              </div>
            </>
          );
        },
      },
      {
        id: 'phone_add_buttons',
        add: true,
        mobile_order: 5,
        add_function: (props, fields) => {
          return (
            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <Button
                className="float--right"
                onClick={() => that.setAddMode('add_number')}
                disabled={!(props.addContent.mode === '' && props.editContent.mode === '')}
              >
                Add
              </Button>
            </SecurityClearance>
          );
        },
      },
    ];

    const notification_mobile_data = _.cloneDeep(_.orderBy(notification_data, ['mobile_order']));
    const phone_mobile_data = _.cloneDeep(_.orderBy(phone_data, ['mobile_order']));

    return (
      <div>
        {this.state.loaded === false ? (
          <CircleLoader fullscreen={true} />
        ) : (
          <div>
            <ContentUser
              name={this.state.account.first_name + ' ' + this.state.account.last_name}
              loginEmail={this.state.account.account_email}
              doctor_id={getDoctorsIdFromRoute()}
              is_edit_dso_doctor_account_page={false}
              edit_picture={this.state.account.is_active}
            />

            <LineDivision />

            <AccountAddress account={this.state.account} />

            <LineDivision />

            <div className="account__section">
              <div className="account__setting">
                <h3>Email for Notifications</h3>

                {this.state.account.notification_emails.length === 0 && (this.state.new_profile.mode === '' || this.state.new_profile.mode === 'add_number') ? (
                  <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                    <Button onClick={() => this.setAddMode('add_email')} disabled={this.state.new_profile.mode === 'add_number'}>
                      Add
                    </Button>
                  </SecurityClearance>
                ) : (
                  <div>
                    <EditTable
                      table_data={notification_data}
                      mobile_data={notification_mobile_data}
                      field_data={this.state.account.notification_emails}
                      addContent={this.state.new_profile}
                      editContent={this.state.edit_profile}
                      setEditMode={(data) => {
                        return this.setEditMode('edit_email', data);
                      }}
                      setAddMode={(data) => {
                        return this.setAddMode('add_email');
                      }}
                      editPermission="IPP_EDIT"
                      addEnabled={this.state.new_profile.mode === 'add_email'}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.state.new_profile.mode === 'add_email' && this.state.new_profile.error ? (
              <div className="heading-item-warning heading-item-warning--margin">
                {this.state.new_error_msg ? this.state.new_error_msg : 'Please enter a valid email address'}
              </div>
            ) : null}

            <LineDivision />

            <div className="account__section">
              <div className="account__setting">
                <h3>Telephone Numbers</h3>
                {this.state.account.phone_numbers.length === 0 && (this.state.new_profile.mode === '' || this.state.new_profile.mode === 'add_email') ? (
                  <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
                    <Button onClick={() => this.setAddMode('add_number')} disabled={this.state.new_profile.mode === 'add_email'}>
                      Add
                    </Button>
                  </SecurityClearance>
                ) : (
                  <div>
                    <EditTable
                      table_data={phone_data}
                      mobile_data={phone_mobile_data}
                      field_data={this.state.account.phone_numbers}
                      addContent={this.state.new_profile}
                      editContent={this.state.edit_profile}
                      setEditMode={(data) => {
                        return this.setEditMode('edit_number', data);
                      }}
                      setAddMode={(data) => {
                        return this.setAddMode('add_number');
                      }}
                      editPermission="IPP_EDIT"
                      addEnabled={this.state.new_profile.mode === 'add_number'}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.state.edit_profile.mode === 'edit_number' && this.state.edit_profile.error ? (
              <div className="heading-item-warning heading-item-warning--margin">
                {this.state.edit_error_msg ? this.state.edit_error_msg : 'Please enter a valid telephone number'}
              </div>
            ) : null}
            {this.state.new_profile.mode === 'add_number' && this.state.new_profile.error ? (
              <div className="heading-item-warning heading-item-warning--margin">
                {this.state.new_error_msg ? this.state.new_error_msg : 'Please enter a valid telephone number'}
              </div>
            ) : null}

            <LineDivision />

            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <div className="account__section">
                <ContentPassword
                  onUpdatePasswordClicked={(currentPassword, newPassword1, newPassword2, that) => {
                    this.updatePassword(currentPassword, newPassword1, newPassword2, that);
                  }}
                  wrongPasswordError={this.state.wrongPasswordError}
                  clearError={this.clearError}
                  editing={this.state.new_profile.mode !== '' || this.state.edit_profile.mode !== ''}
                />
              </div>
            </SecurityClearance>

            <LineDivision />
            <div className="account__section">
              <h3>Other</h3>
              <div>
                <a className="underlined" href={`/portal/agreement`} target="_blank" rel="noopener noreferrer">
                  Customer Agreement
                </a>
              </div>

              <div>
                <a className="underlined" href="https://www.inbrace.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </div>
              <div>
                <a className="underlined" href="https://www.inbrace.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>
              </div>
            </div>

            {this.state.modals.showPasswordChangedModal ? (
              <Modal
                header_text="Password Changed"
                message_text={this.state.passwordChangeMessage}
                onCloseButtonClick={() => {
                  this.hideModal('showPasswordChangedModal');
                }}
              />
            ) : null}

            {this.state.refresh ? (
              <Modal
                preset="action"
                x_btn={false}
                header_text="Session Expired"
                message_text="Sorry, your session has expired. Please refresh."
                confirm_btn_text="Refresh"
                onConfirmButtonClick={onReloadPage}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default ContentDetail;
