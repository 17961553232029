import React from 'react';
import { getCurrentYear } from '../../common/functions';

const Privacy = props => {
  return (
    <div className={props.className}>
      ©{`${getCurrentYear()}`} Swift Health Systems, Inc. All rights reserved.{' '}
      <a className="privacy__footer--link" href="https://www.inbrace.com/privacy-policy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>
    </div>
  );
};

export default Privacy;
