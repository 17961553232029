import './message_banner.scss';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const MAIN_CLASS = 'banner-message-critical';
/**
 * Gets the height of the credit hold warning element.
 *
 * @returns {Number} The height of the child div element within the main element.
 */
export const getCreditHoldWarningElementHeight = () => {
  let result = 0;
  const creditHoldWarningElement = document.querySelector(`.${MAIN_CLASS}`);
  const creditHoldWarningChildElement = creditHoldWarningElement?.querySelector('div');

  // Get the height of the child element if it exists
  result = creditHoldWarningChildElement?.clientHeight || 0;

  return result;
};

class CreditHoldWarning extends Component {
  render() {
    return (
      <div className={MAIN_CLASS}>
        <div>
          <div className="banner-message-header">Account Placed On Credit Hold</div>A credit hold has been placed on your account. All initial cases after Smile
          Design approval will be placed on hold. <br /> To ensure no delay on your case, please contact InBrace for assistance. <br />
          Click
          <Link to={'/portal/' + this.props.doctor_id + '?filter=hold'}> here </Link>
          to view cases affected by the credit hold.
        </div>
      </div>
    );
  }
}

export default CreditHoldWarning;
