import './not_found.scss';
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from '../components/buttons/button';
import { LogoWhite } from '../assets/logos';

class Error404 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    let content = document.getElementById('content');
    if (content) {
      content.id = 'content-alt';
    }
  }

  render() {
    return (
      <div className="ipp-light text-center background not-found-container">
        <Helmet>
          <title>404 Error</title>
        </Helmet>
        <div className="login-window">
          <LogoWhite width="280" />
          <h3 className="heading--light">404</h3>
          <p className="heading--light">Oops! Page can't be found.</p>
          <Link to={{ pathname: `/` }}>
            <Button type="submit" theme="dark">
              Go Home
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}
export default withRouter(Error404);
