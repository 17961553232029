/**
 * File:csr_review.js - Component to input item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2020 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ThumbnailURLInterface from '../../components/thumbnail/thumbnail_url_interface';
import { convertDate, configLightBox } from '../../common/functions';
import Button from '../components/buttons/button';
import { getIteroProgressRecords, getIteroScansRecords, removeDuplicateIteroScansRecords } from '../../common/itero_utils';
import { getSmartWireProgressRecordDate } from '../../common/helpers';

class CsrReview extends Component {
  static defaultProps = {
    selectedIteroScanRecords: [],
  };
  render() {
    let files = [...this.props.fileUpload, ...this.props.selectedIteroScanRecords];
    const hastIteroProgressRecords = getIteroProgressRecords(files).length;
    const hasIteroScans = getIteroScansRecords(files).length;
    if (hastIteroProgressRecords && hasIteroScans) {
      files = removeDuplicateIteroScansRecords(files);
    }
    let date_taken = this.props.treatment_data.questions
      .filter(function (q) {
        return q.friendly_name === 'pr_dates';
      })[0]
      .multiple_question.filter(function (q) {
        return q.friendly_name === 'pr_date_taken';
      })[0].answer_text;

    let date_appointment = this.props.treatment_data.questions
      .filter(function (q) {
        return q.friendly_name === 'pr_dates';
      })[0]
      .multiple_question.filter(function (q) {
        return q.friendly_name === 'next_scheduled_appt';
      })[0].answer_text;

    const upperSmartwireDate = convertDate(
      getSmartWireProgressRecordDate(this.props.treatment_data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Upper Smartwire:')
    );
    const lowerSmartwireDate = convertDate(
      getSmartWireProgressRecordDate(this.props.treatment_data.questions, 'Which Smartwire(s) is the patient currently wearing?', 'Lower Smartwire:')
    );

    return (
      <div>
        <h3>Progress Records</h3>
        <ThumbnailURLInterface files={files} images={configLightBox(files)} hide_occlusion={true} multinail={`_${this.props.case_id}`} />
        <div className="header-edit-container">
          <h3 className="header-edit__heading">Treatment Details </h3>
          <span className="header-edit__button" data-action="pr_csq" onClick={this.props.onEditClick}>
            Edit
          </span>
        </div>
        <div>
          <div className="emphasis">I am submitting...</div>
          <div>
            {
              this.props.treatment_data.questions.filter(function (q) {
                return q.friendly_name === 'submission_type';
              })[0].answer_text
            }
          </div>
          <div className="emphasis">Treatment Notes</div>
          <div>
            {this.props.treatment_data.questions.filter(function (q) {
              return q.friendly_name === 'treatment_notes';
            })[0].answer_text
              ? this.props.treatment_data.questions.filter(function (q) {
                  return q.friendly_name === 'treatment_notes';
                })[0].answer_text
              : 'N/A'}
          </div>
          <div className="question-size row">
            <div className="emphasis col-sm-6">
              <div className="emphasis">Date Progress Records Were Taken</div>
              <div>{date_taken ? convertDate(date_taken) : 'N/A'}</div>
            </div>
            <div className="emphasis col-sm-6">
              <div className="emphasis">Date of Next Scheduled Appointment</div>
              <div>{date_appointment ? convertDate(date_appointment) : 'N/A'}</div>
            </div>
          </div>
          <div className="question-size row">
            <div className="col-sm-6">
              <div className="emphasis">Current Upper Smartwire</div>
              <div>
                {
                  this.props.treatment_data.questions
                    .filter(function (q) {
                      return q.friendly_name === 'smartwire_type';
                    })[0]
                    .multiple_question.filter(function (q) {
                      return q.friendly_name === 'upper_smartwire_type';
                    })[0].answer_text
                }
              </div>
              {upperSmartwireDate && <div>Inserted on {upperSmartwireDate}</div>}
            </div>
            <div className="col-sm-6">
              <div className="emphasis">Current Lower Smartwire</div>
              <div>
                {
                  this.props.treatment_data.questions
                    .filter(function (q) {
                      return q.friendly_name === 'smartwire_type';
                    })[0]
                    .multiple_question.filter(function (q) {
                      return q.friendly_name === 'lower_smartwire_type';
                    })[0].answer_text
                }
              </div>
              {lowerSmartwireDate && <div>Inserted on {lowerSmartwireDate}</div>}
            </div>
          </div>
          <div className="emphasis">Clinical Support Requested</div>
          <div>
            {
              this.props.treatment_data.questions.filter(function (q) {
                return q.friendly_name === 'csr_required';
              })[0].answer_text
            }
          </div>
        </div>
        <Button onClick={this.props.onSubmitButtonClick} data-case_id={this.props.case_id} disabled={this.props.draft_save_inprogress}>
          Submit
        </Button>
      </div>
    );
  }
}

export default withRouter(CsrReview);
