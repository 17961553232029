import { getImageURLSrc } from '../../../doctor/components/record_viewer/record_viewer_helpers';

export const BUILD_RECORD_VIEWER_STATE = 'BUILD_RECORD_VIEWER_STATE';
export const UPDATE_RECORD_VIEWER_SRC = 'UPDATE_RECORD_VIEWER_SRC';
export const UPDATE_RECORD_VIEWER_STATE = 'UPDATE_RECORD_VIEWER_STATE';
export const UPDATE_DELETE_IN_PROCESS = 'UPDATE_DELETE_IN_PROCESS';

export function buildRecordStates(uploads, options = {}) {
  if (!('teethViewer' in options)) options.teethViewer = false;
  if (!('caseType' in options)) options.caseType = 'incomplete';
  if (!('sync' in options)) options.sync = true;

  return {
    type: BUILD_RECORD_VIEWER_STATE,
    uploads: uploads,
    ...options,
  };
}

export function onLoadImageRecord(id, url) {
  return (dispatch) => {
    getImageURLSrc(url).then((new_src) => dispatch(updateRecordSrc(id, new_src)));
  };
}

export function onRecordViewerAction(id, action) {
  return {
    type: UPDATE_RECORD_VIEWER_STATE,
    id: id,
    action: action,
  };
}

export function updateDeleteInProcess(records, id) {
  return {
    type: UPDATE_DELETE_IN_PROCESS,
    records: records,
    id: id,
  };
}

function updateRecordSrc(id, src) {
  return {
    type: UPDATE_RECORD_VIEWER_SRC,
    id: id,
    src: src,
  };
}
