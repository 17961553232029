// External Libs
import React, { Component } from 'react';

// Internal Libs
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

class InProcessManual extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="ir process-box-title center-text bold-text">Conversion</div>
          <div className="center-text">Conversion in progress</div>
          <div className="center-text">
            <i className="fa fa-refresh font-size-lg rotate" aria-hidden="true" />
          </div>
          <div className="extra-padding-headline-bpp" />
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_AWB_BYPASS', user_roles_and_permissions.permissions) ? (
                <div className="button-panel center-text">
                  <button type="button" className="btn btn-light" onClick={this.props.openManualMarkAsSuccessModal}>
                    Mark as Success
                  </button>

                  <button type="button" className="btn btn-light" onClick={this.props.openManualMarkAsFailureModal}>
                    Mark as Failed
                  </button>
                </div>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        </div>
      </React.Fragment>
    );
  }
}

export default InProcessManual;
