import './ip_review.scss';

// External Libs
import React from 'react';
import ProcessComponent from '../../../../components/workflow/process_component';
import Modal from '../../../../components/modal/modal';
import { userHasPermission } from '../../../../common/permission';
import { removeCaseIdInitialNumber, onDownload, modalClear } from '../../../../common/functions';
import { getPDFNameTemplate } from '../../../../common/helpers';

/**
 * Displays Automated wirebending review step
 * @component
 * @alias IPReview
 * @category BPP
 */
class IPReview extends ProcessComponent {
  constructor(props) {
    super(props);

    this.state = {
      reject_modal: false,
      approve_modal: false,
      ip_loop_measurement_modal: false,
      approve_failure: false,
      approve_modal_text: 'Are you sure you want to approve these files and send them to the wirebending directory?',
    };
  }

  onUpload = (data) => {
    this.taskRunner('onUpload', data);
  };

  onConfirmApproveClick = (event) => {
    let that = this;

    this.taskRunner('onApproveRecipeFiles', event)
      .then((res) => {
        if (res) {
          that.setState({ approve_modal: false, approve_failure: false });
          that.props.reload_information();
        } else if (res.response && res.response.status >= 400) {
          that.setState({
            approve_failure: true,
            approve_modal_text: 'Unable to send file to wire bending machine. Please contact support for assistance.',
          });
        }
      })
      .catch(function (err) {
        that.setState({
          approve_failure: true,
          approve_modal_text: 'Unable to send file to wire bending machine. Please contact support for assistance.',
        });
      });
  };

  onConfirmRejectClick = (event) => {
    let that = this;

    this.taskRunner('onRejectRecipeFiles', event).then((result) => {
      if (result) {
        this.props.reload_information();
      }

      that.setState({ reject_modal: false });
    });
  };

  onConvertClick = (event) => {
    event.preventDefault();
    this.toggleUploadModal();
  };

  onRemoveClick = (event) => {
    event.preventDefault();
    this.toggleRemoveModal();
  };

  toggleApproveModal = () => {
    if (!this.state.approve_modal === true) {
      this.setState({
        approve_modal_text: 'Are you sure you want to transfer these files to the wirebending directory?',
        approve_failure: false,
      });
    }
    this.setState({ approve_modal: !this.state.approve_modal });
  };

  toggleRejectModal = () => {
    this.setState({ reject_modal: !this.state.reject_modal });
  };

  onIncompleteApproveClick = () => {
    this.setState({
      approve_modal_text: 'Unable to send file to wire bending machine. Please contact support for assistance.',
      approve_failure: true,
    });
  };

  onApproveFileModal = () => {
    const header_text = `Transfer Files - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="ip_review"
        header_text={header_text}
        message_text={this.state.approve_modal_text}
        confirm_btn_text="Transfer"
        onConfirmButtonClick={this.onConfirmApproveClick}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleApproveModal}
        onIncompleteClick={this.onIncompleteApproveClick}
        approveFailure={this.state.approve_failure}
        user_roles_and_permissions={this.props.user_roles_and_permissions}
      />
    );
  };

  onRejectFileModal = () => {
    const header_text = `Reject Files - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you would like to reject these files and upload new files?"
        confirm_btn_text="Reject"
        onConfirmButtonClick={this.onConfirmRejectClick}
        close_btn_text="Cancel"
        onCloseButtonClick={this.toggleRejectModal}
      />
    );
  };

  getFileCommentWireSelection = () => {
    let wires = {
      upper: '',
      lower: '',
    };

    if (this.props.ip_process && this.props.ip_process.log) {
      const wire_log = this.props.ip_process.log.filter((log) => {
        return log.status_code === 'Recipe Conversion Process';
      });

      if (wire_log && wire_log.length > 0) {
        const wire_comment = JSON.parse(wire_log[wire_log.length - 1]['text']);

        wires.upper = wire_comment['upper'];
        wires.lower = wire_comment['lower'];
      }
    }

    return wires;
  };

  getIPCaseFilePath = (file_type) => {
    let file = '';

    if (this.props.ip_files && this.props.ip_files.length > 0) {
      file = this.props.ip_files.filter((file) => {
        return file.file_type === file_type;
      });

      if (file && file.length > 0) {
        file = file[file.length - 1];
      }
    }

    return file;
  };

  onIPLoopMeasurementModalClick = (event) => {
    this.setState({ ip_loop_measurement_modal: true });
  };

  onIPLoopMeasurementModalDismiss = (event) => {
    this.setState({ ip_loop_measurement_modal: false });
    modalClear();
  };

  render() {
    const wires = this.getFileCommentWireSelection();
    const ip_loop_file = this.getIPCaseFilePath('ip_upload');
    const recipe_file = this.getIPCaseFilePath('ip_recipe');
    const ip_loop_measurement_file = this.getIPCaseFilePath('ip_measurements');
    const upper_selection = wires.upper;
    const lower_selection = wires.lower;

    return (
      <div className="process_tracker">
        <div className={'margin-top-20'}>
          <hr className={'hidden'} />
          <div className="cad-window">
            {!(this.props.case_status === 'STATUS_HOLD' || this.props.case_status === 'STATUS_CANCEL') ? (
              <React.Fragment>
                <div className="paragraph">
                  <div>
                    <b>Review</b>
                  </div>
                  Review the files below to confirm conversion was completed successfully. Click <span className="bold-text">Transfer</span> to send the file to
                  the wire bending machine. Click <span className="bold-text">Reject</span> to reupload a new file.
                </div>

                <div className="ip-container">
                  <div className="ip-file-download">
                    {ip_loop_file && ip_loop_file.upload_data ? (
                      <a className="underline-text viewable-text view-upload" href={`/${ip_loop_file.upload_data}`} download onClick={onDownload}>
                        Input File <i className="fa fa-file-excel-o" aria-hidden="true" />
                      </a>
                    ) : null}

                    {recipe_file && recipe_file.upload_data ? (
                      <a className="underline-text viewable-text view-upload" href={`/${recipe_file.upload_data}`} download onClick={onDownload}>
                        Recipe File <i className="fa fa-file-excel-o" aria-hidden="true" />
                      </a>
                    ) : null}

                    {ip_loop_measurement_file && ip_loop_measurement_file.upload_data ? (
                      <div className="underline-text viewable-text view-upload" onClick={this.onIPLoopMeasurementModalClick}>
                        IP Loop Measurements <i className="fa fa-file-pdf-o" aria-hidden="true" />
                      </div>
                    ) : null}
                  </div>

                  <div className="ip-wire-selection">
                    <div className="">
                      <div className="bold-text select-label select-label-inline">Upper Wire:</div>
                      <div className="select-wire-readonly">
                        {upper_selection === 'both' ? '.016 and .014' : null}
                        {upper_selection === '16' ? '.016 only' : null}
                        {upper_selection === '14' ? '.014 only' : null}
                        {upper_selection === 'none' || upper_selection === '' ? 'None' : null}
                      </div>
                    </div>

                    <div className="">
                      <div className="bold-text select-label select-label-inline">Lower Wire:</div>
                      <div className="select-wire-readonly">
                        {lower_selection === 'both' ? '.016 (Lower/Lower St) and .014' : null}
                        {lower_selection === '16' ? '.016 only (Lower/Lower St)' : null}
                        {lower_selection === '14' ? '.014 only' : null}
                        {lower_selection === 'none' || lower_selection === '' ? 'None' : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ip-actions">
                  {userHasPermission('IPLOOP_APPROVAL', this.props.user_roles_and_permissions.permissions) ? (
                    <button type="button" className="btn btn-light" onClick={this.toggleApproveModal}>
                      Transfer
                    </button>
                  ) : null}

                  {userHasPermission('IPLOOP_APPROVAL', this.props.user_roles_and_permissions.permissions) ? (
                    <button type="button" className="btn btn-light" onClick={this.toggleRejectModal}>
                      Reject
                    </button>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}

            {this.state.reject_modal ? this.onRejectFileModal() : null}
            {this.state.approve_modal ? this.onApproveFileModal() : null}

            {this.state.ip_loop_measurement_modal ? (
              <Modal
                preset="pdf_viewer"
                header_text={`IP Loop Measurement - ${removeCaseIdInitialNumber(this.props.case_id)}`}
                modal_size_class="modal-lg modal-lg-pdf"
                modal_body_class="modal-pdf"
                pdf_url={ip_loop_measurement_file.upload_data}
                pdf_date={ip_loop_measurement_file?.created_date}
                onCloseButtonClick={this.onIPLoopMeasurementModalDismiss}
                theme="bpp"
                original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, '3450-01_IP_Loop_Measurements.pdf')}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default IPReview;
