import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InfoBlueIcon } from '../assets/icons';
import AlertContainer from './container/alert_container';
import { getCookie, setCookie } from '../../common/functions';

const themes = ['default', 'secondary', 'info', 'error', 'success'];

function AlertMessage({ message, theme, className = '', cname = '', withIcon = true, ...props }) {
  const [open, setOpen] = useState(true);
  const type = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} ${type}--alert`;
  useEffect(() => {
    if (cname) {
      const cvalue = getCookie(cname);
      if (cvalue) {
        setOpen(false);
      }
    }
    // eslint-disable-next-line
  }, []);
  const handleState = () => {
    if (open) {
      setCookie(cname, true);
    }
    setOpen(!open);
  };
  if (!open) return null;
  return (
    <AlertContainer theme={type} className={classNames}>
      {['default', 'secondary', 'info'].includes(type) && withIcon ? <InfoBlueIcon className={`${type}--icon`} /> : null}
      <span className={`${type}--text`}>{message}</span>
      {props.dismiss ? (
        <span className={`${type}--close`} onClick={() => handleState()}>
          Dismiss
        </span>
      ) : null}
    </AlertContainer>
  );
}

AlertMessage.propTypes = {
  theme: PropTypes.oneOf(themes),
};

AlertMessage.defaultProps = {
  theme: themes[0],
};

export default AlertMessage;
