export const metaData = [
  'case_id',
  'draft_case_id',
  'first_name',
  'last_name',
  'treatment_arch',
  'dob',
  'submission_date',
  'sex',
  'draft_doctor_role',
  'draft_doctor_id',
  'prospect_id',
  'is_wasm_viewer_enabled',
];

export const colWidth = { patient_name: '20%', submission_date: '15%', status: '25%', doctor_name: '0%', notes: '40%' };

export const dsoColWidth = { patient_name: '16%', submission_date: '12%', status: '25%', doctor_name: '16%', notes: '31%' };

export const dashboardColWidth = { patient_name: '33%', submission_date: '33%', status: '33%', doctor_name: '0%', notes: '0%' };

export const dsoDashboardColWidth = { patient_name: '25%', submission_date: '15%', status: '35%', doctor_name: '25%', notes: '0%' };
