// External Libs
import React, { Component } from 'react';

//Internal files
import Modal from '../components/modal/modal';
import { onReloadPage } from './helpers';

/**
 * Shows the Session Has Expired modal and gives the option to refresh the page
 * @component
 * @category Common
 */
class SessionExpire extends Component {
  render() {
    return (
      <Modal
        preset="action"
        x_btn={false}
        header_text="Session Expired"
        message_text="Sorry, your session has expired. Please refresh."
        confirm_btn_text="Refresh"
        onConfirmButtonClick={onReloadPage}
        theme={this.props.theme ? this.props.theme : 'bpp'}
      />
    );
  }
}

export default SessionExpire;
