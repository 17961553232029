import React from 'react';
import _ from 'lodash';

class Btn extends React.Component {
  render() {
    let result = _.intersection(this.props.btnState, [this.props.btname]);
    let btnStyle = {
      backgroundColor: 'white',
      color: '#43a7b3',
      height: '50px',
      width: '50px',
      borderRadius: '10px'
    };

    if (result && result.length > 0) {
      btnStyle = {
        backgroundColor: '#43a7b3',
        color: 'white',
        height: '50px',
        width: '50px',
        borderRadius: '10px'
      };
    }

    return (
      <div>
        <button type="button" data-field="restrictionsTeeth" style={btnStyle} onClick={this.props.onBtnClick}>
          {this.props.btname}
        </button>
      </div>
    );
  }
}

export default Btn;
