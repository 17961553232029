import React from 'react';
import PropTypes from 'prop-types';

import RetryButton from '../setup_viewer/components/extras/retry_button';
import WasmViewControls from './wasm_view_controls';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onToggleOption } from '../../redux/actions/wasm_viewer/wasm_viewer';
import WasmDropdown from './wasm_dropdown';
import WasmCanvas from './wasm-canvas';
import { HideContentIf } from '../../common/hocs/hide-content-if';
import InLoader from '../loader/in_loader';
import { ToothPopupMenu } from './tooth-popup-menu/tooth-popup-menu';
import { Notifications } from '../notifications';
import { WasmViewInitialStage } from './components/wasm-view-initial-stage';
import { isEnrolledProviderEditPhase2 } from '../../redux/reducers/common/common_case_details';
import { getWasmViewerState } from '../../redux/selectors/wasm_viewer';

function WasmMain(props) {
  const {
    loading: caseIsLoading,
    loadingInitialTreatmentPlan,
    error,
    selectedTreatmentPlanError,
    onClickRetry,
    containerStyles,
    percentage,
    toothPopupMenuState,
    onClickToothPopupItem,
    isEnrolledPhase2,
  } = props;
  const hasError = error || !!selectedTreatmentPlanError;
  const retryButtonIsHidden = caseIsLoading || !hasError;

  const shouldHideLoader = () => {
    if (caseIsLoading) {
      return false;
    }

    if (!caseIsLoading && hasError) {
      return true;
    }

    if (!caseIsLoading && !hasError && loadingInitialTreatmentPlan) {
      return false;
    }

    if (!caseIsLoading && !hasError && !loadingInitialTreatmentPlan) {
      return true;
    }

    return true;
  };
  const shouldHideWasm = () => {
    if (caseIsLoading) {
      return true;
    }

    if (!caseIsLoading && hasError) {
      return true;
    }

    if (!caseIsLoading && !hasError && loadingInitialTreatmentPlan) {
      return true;
    }

    if (!caseIsLoading && !hasError && !loadingInitialTreatmentPlan) {
      return false;
    }

    return true;
  };

  return (
    <div className="wasm-container" style={containerStyles}>
      <HideContentIf condition={retryButtonIsHidden}>
        <RetryButton className="retry-btn" message="Unable to load Smile Design." onRetry={onClickRetry} />
      </HideContentIf>
      <HideContentIf condition={shouldHideLoader()}>
        <InLoader percentage={percentage} />
      </HideContentIf>
      <HideContentIf condition={shouldHideWasm()}>
        <WasmViewControls />
      </HideContentIf>
      <WasmCanvas isHidden={shouldHideWasm()} />
      <HideContentIf condition={!isEnrolledPhase2}>
        <WasmViewInitialStage />
      </HideContentIf>
      <WasmDropdown />
      <HideContentIf condition={!toothPopupMenuState || !isEnrolledPhase2}>
        <ToothPopupMenu x={toothPopupMenuState?.x} y={toothPopupMenuState?.y} items={toothPopupMenuState?.items} onItemClick={onClickToothPopupItem} />
      </HideContentIf>
      <Notifications containerCls="wasm-notifications" />
    </div>
  );
}

WasmMain.propTypes = {
  toothPopupMenuState: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.string),
  }),
  onClickToothPopupItem: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isEnrolledPhase2: isEnrolledProviderEditPhase2(state),
  ...getWasmViewerState(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onToggleOption: onToggleOption,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WasmMain);
