/**
 * File:ir_content.js - Component to list content of item request list
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './smile_simulation_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import Axios from 'axios';

import { withRouter } from 'react-router-dom';

// Internal Functions
import { setTokenHeader } from '../../common/functions';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from '../../common/route';
import CaseListSidebar from '../case_list/case_list_sidebar';
import CaseListFilter from '../case_list/case_list_filter';

class SmileSimulationNav extends Component {
  constructor(props) {
    super(props);

    let status_types = {
      assigned_me: 0,
      all: 0,
      smile_unassigned: 0,
      //
      inbrace_action: 0,
      checking: 0,
      setup_ready_upload: 0,
      setup_conversion: 0,
      setup_conversion_failed: 0,
      setup_ready_for_release: 0,
      //
      doctor_action: 0,
      new: 0,
      //
      released: 0,
      cancel: 0,
      //
      submitted: 0,
      lost: 0,
    };

    this.state = {
      statuses: status_types,
      inbrace_action_required_collapse: false,
      doctor_action_required_collapse: false,
    };
  }

  hasAssignTo() {
    return false;
  }

  componentDidMount() {
    this.updateAlerts();
  }

  updateAlerts() {
    setTokenHeader();
    const that = this;

    Axios.get(`/apiv3/smilesimulation/alerts`).then(function (res) {
      that.setState({
        statuses: res.data.statuses,
      });
    });
  }

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;

    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();
    let status = route_type === 'status' ? uri_text : getBusinessStatusValueFromRoute();
    let start_date = route_type === 'start' ? uri_text : getBusinessStartDateValueFromRoute();
    let end_date = route_type === 'end' ? uri_text : getBusinessEndDateValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';
    status = status !== '' ? `status=${status}` : '';
    start_date = start_date !== '' ? `start=${start_date}` : '';
    end_date = end_date !== '' ? `end=${end_date}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }

      if (status) {
        param.push(status);
      }

      if (start_date) {
        param.push(start_date);
      }

      if (end_date) {
        param.push(end_date);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  onInbraceActionRequiredCollapseClick = () => {
    this.setState({
      inbrace_action_required_collapse: !this.state.inbrace_action_required_collapse,
    });
  };

  onDoctorActionRequiredCollapseClick = () => {
    this.setState({
      doctor_action_required_collapse: !this.state.doctor_action_required_collapse,
    });
  };

  onClickSubMenu = (filter) => {
    this.setState({ submission_date_error: false });
    this.props.history.replace(this.setRoute(filter, 'filter'));
    this.props.onPageChange(1);
  };

  onClickInbraceActionRequiredSubMenu = (event) => {
    if (!this.state.inbrace_action_required_collapse) {
      const uri_text = encodeURI(event.currentTarget.dataset.filter);
      this.props.history.replace(this.setRoute(uri_text, 'filter'));
    }
  };

  onClickDoctorActionRequiredSubMenu = (event) => {
    if (!this.state.doctor_action_required_collapse) {
      const uri_text = encodeURI(event.currentTarget.dataset.filter);
      this.props.history.replace(this.setRoute(uri_text, 'filter'));
    }
  };

  /**
   * Creates a single sidebar filter configuration
   * @function
   * @param {String} filter_key - The id of the filter
   * @param {String} text - The display text of the filter
   * @param {Array} [submenu=undefined] - The list of submenu under the filter
   * @returns {Object} A single filter config
   */
  buildSingleSidebarFilter(filter_key, text, submenu = undefined) {
    const { filter, statuses } = this.state;
    return {
      filter: filter_key,
      text: text,
      count: statuses[filter_key],
      active: filter === filter_key,
      onClick: () => this.onClickSubMenu(filter_key),
      submenu: submenu,
    };
  }

  /**
   * Creates the list of filters configurations
   * @function
   * @returns {Array} The result menu filters
   */
  buildSidebarFilters() {
    return [
      this.buildSingleSidebarFilter('assigned_me', 'Smile Assigned to Me'),
      this.buildSingleSidebarFilter('all', 'All Submissions'),
      this.buildSingleSidebarFilter('smile_unassigned', 'Unassigned Smiles'),
      this.buildSingleSidebarFilter('inbrace_action', 'InBrace Action Required', [
        this.buildSingleSidebarFilter('checking', 'Verify Scans'),
        this.buildSingleSidebarFilter('setup_ready_upload', 'Create Smile Simulation'),
        this.buildSingleSidebarFilter('setup_conversion', 'Simulation in Conversion'),
        this.buildSingleSidebarFilter('setup_conversion_failed', 'Conversion Failed'),
        this.buildSingleSidebarFilter('setup_ready_for_release', 'Release Smile Simulation'),
      ]),
      this.buildSingleSidebarFilter('doctor_action', 'Doctor Action Required', [this.buildSingleSidebarFilter('new', 'Awaiting New Scans')]),
      this.buildSingleSidebarFilter('smile_completed', 'Released Smile Simulations'),
      this.buildSingleSidebarFilter('submitted', 'Case Submitted'),
      this.buildSingleSidebarFilter('lost', 'Prospect Lost'),
      this.buildSingleSidebarFilter('cancel', 'Cancelled'),
    ];
  }

  render() {
    return (
      <CaseListSidebar side_collapse={this.state.side_collapse} onClickCollapse={this.onSideBarCollapseClick}>
        {/* eslint-disable-next-line */}
        <a className="submenu-title">Smile Simulations</a>
        <CaseListFilter filters={this.buildSidebarFilters()} />
      </CaseListSidebar>
    );
  }
}

export default withRouter(SmileSimulationNav);
