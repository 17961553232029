import React from 'react';

import { getIteroFilesTempLinks } from './dropbox';

/**
 * Helper function for the finally callback of fetchedIteroScans.
 * Updates the state of the context based on the fetchedIteroScans array.
 * @param {Object} ctx - The context object.
 */
export const fetchedIteroScansFinallyCallbackHelper = (ctx) => {
  if (ctx.props.fetchedIteroScans.length) {
    ctx.setState((prevState) => {
      if (prevState.selectedIteroScanIds.length) {
        const selectedIteroScans = ctx.props.fetchedIteroScans.filter((file) => prevState.selectedIteroScanIds.includes(file.id));
        if (selectedIteroScans.length) {
          return {
            selectedIteroId: selectedIteroScans[0].itero_id,
            selectedIteroScanIds: [],
          };
        } else {
          return {
            selectedIteroId: '',
            selectedIteroScanIds: [],
            selectedIteroScanRecords: [],
            selectedIteroScans: [],
          };
        }
      }
    });
  }
};

export const getIteroScansRecords = (records) => {
  return records.filter((record) => {
    return record.file_type === 'iteroScans';
  });
};

export const getIteroProgressRecords = (records) => {
  return records.filter((record) => {
    return record.file_type === 'progress_records' && record.itero_file_id;
  });
};

export const removeDuplicateIteroScansRecords = (records) => {
  const iteroProgressRecordsIteroScanIds = getIteroProgressRecords(records).map((record) => record.itero_file_id);

  return records.filter((record) => {
    if (record.file_type === 'iteroScans') {
      return !iteroProgressRecordsIteroScanIds.includes(record.id);
    }
    return true;
  });
};

/**
 * iTero integration
 *
 * Map a fetched itero file to a common info object for grouping
 * @function
 * @param {Object} file - itero file
 * @returns {Object} - common info object
 */
export const mapToIteroFilesCommonInfo = (file) => {
  let patientName = file.patient_first_name || '';

  if (file.patient_last_name) {
    if (patientName) patientName += ' ';
    patientName += file.patient_last_name;
  }

  let datetime = '';

  if (file.scan_date) {
    // Convert to MM/DD/YYYY HH:MM AM/PM in PST timezone
    datetime = new Date(file.scan_date).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });

    // Remove seconds from datetime
    datetime = datetime.replace(/:\d{2}\s/, ' ');

    // Remove comma from datetime
    datetime = datetime.replace(',', '');
  }

  return {
    iteroId: file.itero_id,
    patientName,
    patientFirstName: file.patient_first_name,
    patientLastName: file.patient_last_name,
    datetime,
  };
};

/**
 * iTero integration
 *
 * Group fetched itero files by itero id
 * @function
 * @param {Array} files - itero files
 * @returns {Object} - itero file groups
 */
export const groupIteroFilesByIteroId = (files) => {
  const fileGroups = {};

  if (!files) return fileGroups;

  files.forEach((file) => {
    if (!fileGroups[file.itero_id]) {
      const row = mapToIteroFilesCommonInfo(file);
      fileGroups[file.itero_id] = {
        ...row,
        files: [file],
      };
    } else {
      fileGroups[file.itero_id].files.push(file);
    }
  });

  return fileGroups;
};

/**
 * iTero integration
 *
 * @param {Array} files - itero files
 */
export const getIteroFilesTempUrls = async (files) => {
  const filePaths = files.map((file) => file.file_path);
  const iteroScanTempLinksResponse = await getIteroFilesTempLinks(filePaths);
  return iteroScanTempLinksResponse.data.file_urls;
};

/**
 * iTero integration
 *
 * Builds and returns record itero file records for fetched itero scans
 * @function
 * @param {String} caseId - case id
 * @param {Array} iteroScans - itero scans
 */
export const buildIteroScanRecordsHelper = async (caseId, iteroScans) => {
  const fileUrls = await getIteroFilesTempUrls(iteroScans);

  return iteroScans.map((file) => {
    const fileUrl = fileUrls[file.file_path];
    const originalFilename = file.file_path.split('/').pop();
    return {
      id: file.id,
      folder: 'iteroScans',
      file_type: 'iteroScans',
      location: 'incomplete',
      mime_type: 'model/stl',
      original_filename: originalFilename,
      file_url: fileUrl,
      incomplete_id: caseId,
      upload_data: file.file_path,
      is_itero_scan: true,
      itero_file_id: file.id,
    };
  });
};

export const getMappedLicenseNumbersHelper = (licenseNumbers) => {
  return licenseNumbers.map((licenseNumberObj) => licenseNumberObj.license_number);
};

/**
 * iTero integration
 *
 * Checks if the selected itero scans selected/upload qty is valid
 * @function
 * @param {Object} options - object containing the following properties
 * @param {String} options.uploadMethod - scans upload method
 * @param {Array} options.scanUpload - scan upload
 * @param {Array} options.scans_uploading - scans uploading
 * @param {Array} options.selectedIteroScans - selected itero file ids
 * @returns {Boolean}
 */
export const hasTwoScansHelper = ({ uploadMethod, scanUpload, scans_uploading, selectedIteroScans }) => {
  const moreThanTwoScansWarningEl = '<ul><li>More than 2 STL Files</li></ul>';
  const twoScansRequiredWarningEl = '<ul><li>2 STL Files Required</li></ul>';
  const defaultWarningEl = '<ul><li>Toothprints</li></ul>';

  const warning = document.querySelector('#warning-submit');
  if (uploadMethod === 'manual') {
    if (scanUpload.length + scans_uploading.length !== 2) {
      if (warning) {
        warning.classList.add('warning-display');

        if (warning && scanUpload.length + scans_uploading.length > 2) {
          warning.innerHTML = moreThanTwoScansWarningEl;
        } else if (scanUpload.length + scans_uploading.length === 1) {
          warning.innerHTML = twoScansRequiredWarningEl;
        } else {
          warning.innerHTML = defaultWarningEl;
        }
      }
      return false;
    }
  }

  if (uploadMethod === 'itero') {
    if (selectedIteroScans.length !== 2) {
      if (warning) {
        warning.classList.add('warning-display');

        if (warning && selectedIteroScans.length > 2) {
          warning.innerHTML = moreThanTwoScansWarningEl;
        } else if (selectedIteroScans.length === 1) {
          warning.innerHTML = twoScansRequiredWarningEl;
        } else {
          warning.innerHTML = defaultWarningEl;
        }
      }
      return false;
    }
  }
  return true;
};

/**
 * iTero integration
 *
 * Get initial state for scans iTero integration
 * @function
 * @param {Object} defaultsOverride - override default values
 * @param {Boolean} defaultsOverride.showAlreadyUploadedScans - show already uploaded scans
 * @param {Array} defaultsOverride.selectedIteroId - selected itero id
 * @param {String} defaultsOverride.searchIteroScansQuery - search itero scans query
 */
export const getIteroSelectionInitialState = (defaultsOverride = {}) => ({
  showAlreadyUploadedScans: false,
  selectedIteroId: '',
  searchIteroScansQuery: '',
  shouldFetchIteroPhotos: false,
  uploadMethod: 'manual',
  ...defaultsOverride,
});

/**
 * iTero integration
 *
 * Get initial state for scans iTero integration
 * @function
 * @param {Object} defaultsOverride - override default values
 * @param {Boolean} defaultsOverride.isEnrolledToiTeroIntegration - is enrolled to iTero integration
 * @param {String} defaultsOverride.uploadMethod - scans upload method
 * @param {Boolean} defaultsOverride.showAlreadyUploadedScans - show already uploaded scans
 * @param {Array} defaultsOverride.selectedIteroScanIds - selected itero file ids
 * @param {Array} defaultsOverride.selectedIteroScanRecords - selected itero file records
 * @param {String} defaultsOverride.searchIteroScansQuery - search itero scans query
 */
export const getIteroInitialState = (defaultsOverride = {}) =>
  getIteroSelectionInitialState({
    fetchedIteroScans: [],
    selectedIteroScans: [],
    selectedIteroScanIds: [],
    selectedIteroScanRecords: [],
    selectedIteroPhotoRecords: [],
    isEnrolledToiTeroIntegration: false,
    isEnrolledToiTeroPhotosIntegration: false,
    selectedIteroPatientName: '',
    removePhotosWhenUploadMethodIsManual: true,
    shouldAllowToRemoveAllIteroPhotos: false,
    shouldRequiredManualPhotoUpload: true,
    isBuildingIteroScansRecords: false,
    isLoadingUploadedFiles: false,
    fetchedIteroScansNextUrlCursor: null,
    ...defaultsOverride,
  });

export class WithIteroSelection extends React.Component {
  validPhotoUploadMinLength = 1;

  constructor(props) {
    super(props);

    if (!props.licenseNumbers) {
      throw new Error('licenseNumbers redux state is not in props');
    }
    if (!props.fetchLicenseNumbers) {
      throw new Error('fetchLicenseNumbers redux action method is not in props');
    }
    if (!props.fetchIteroScans) {
      throw new Error('fetchIteroScans redux action method is not in props');
    }

    this.getCaseIdForIteroScans = this.getCaseIdForIteroScans.bind(this);
    this.getIteroPhotosStateKey = this.getIteroPhotosStateKey.bind(this);
    this.getIteroSelectionProps = this.getIteroSelectionProps.bind(this);
    this.getRecordViewerSubtitle = this.getRecordViewerSubtitle.bind(this);
    this.getUploadedPhotosWithouthIteroPhotos = this.getUploadedPhotosWithouthIteroPhotos.bind(this);
    this.handleSearchIteroScansQueryChange = this.handleSearchIteroScansQueryChange.bind(this);
    this.handleSelectedIteroIdChange = this.handleSelectedIteroIdChange.bind(this);
    this.handleSelectedIteroScanRecordsChange = this.handleSelectedIteroScanRecordsChange.bind(this);
    this.handleShouldFetchIteroPhotosChange = this.handleShouldFetchIteroPhotosChange.bind(this);
    this.handleShowAlreadyUploadedScansChange = this.handleShowAlreadyUploadedScansChange.bind(this);
    this.handleUploadMethodChange = this.handleUploadMethodChange.bind(this);
    this.onSelectedIteroIdChange = this.onSelectedIteroIdChange.bind(this);
    this.onSelectedIteroScanIdsChange = this.onSelectedIteroScanIdsChange.bind(this);
    this.onSelectedIteroScansChange = this.onSelectedIteroScansChange.bind(this);
    this.onShouldFetchIteroPhotosChange = this.onShouldFetchIteroPhotosChange.bind(this);
    this.onUploadMethodChange = this.onUploadMethodChange.bind(this);
    this.selectedIteroScanIdsChanged = this.selectedIteroScanIdsChanged.bind(this);
    this.selectedIteroScansChanged = this.selectedIteroScansChanged.bind(this);
    this.updateSelectedIteroScanPatientNameHelper = this.updateSelectedIteroScanPatientNameHelper.bind(this);
    this.updateSelectedIteroScanRecordsHelper = this.updateSelectedIteroScanRecordsHelper.bind(this);
    this.withIteroSelectionComponentDidMount = this.withIteroSelectionComponentDidMount.bind(this);
    this.withIteroSelectionComponentDidUpdate = this.withIteroSelectionComponentDidUpdate.bind(this);
    this.loadSelectedIteroFiles = this.loadSelectedIteroFiles.bind(this);
    this.getIteroScansLoadedState = this.getIteroScansLoadedState.bind(this);
    this.getNextCursorFromFetchedIteroScans = this.getNextCursorFromFetchedIteroScans.bind(this);
    this.handleIteroFileSearch = this.handleIteroFileSearch.bind(this);
    this.handleLoadMoreIteroScans = this.handleLoadMoreIteroScans.bind(this);
    this.getPhotoUploadLength = this.getPhotoUploadLength.bind(this);
    this.isPhotoUploadLengthValid = this.isPhotoUploadLengthValid.bind(this);
    this.getIteroPhotosDropzoneMessage = this.getIteroPhotosDropzoneMessage.bind(this);
    this.getPhotoSectionErrorMessage = this.getPhotoSectionErrorMessage.bind(this);
  }

  /**
   * Returns the error message for the photo section.
   * @returns {string} The error message.
   */
  getPhotoSectionErrorMessage = () => {
    let message = 'Photos';

    if (this.state.isEnrolledToiTeroPhotosIntegration) {
      message += ' of Patient';
    }

    return message;
  };

  /**
   * Loads selected Itero files based on the provided parameters.
   * @param {string} cursor - The cursor used for pagination.
   * @param {Array<string>} selectedIteroScanIds - The IDs of the selected Itero scans.
   * @param {Array<Object>} fetchedIteroScans - The array of fetched Itero scans.
   * @param {Array<string>} licenseNumbers - The license numbers to filter the Itero scans.
   * @returns {Promise<{ fetchedIteroScans: Array<Object>, selectedIteroFiles: Array<Object>, nextCursor: string }>} - The fetched Itero scans, selected Itero files, and the next cursor for pagination.
   */
  loadSelectedIteroFiles = async (cursor, selectedIteroScanIds, fetchedIteroScans, licenseNumbers) => {
    let _cursor = cursor;
    let _fetchedIteroScans = [...fetchedIteroScans];
    let selectedIteroFiles = fetchedIteroScans.filter((file) => selectedIteroScanIds.includes(file.id));
    while (!selectedIteroFiles.length && _cursor) {
      const newFetchedIteroScansData = await this.props.fetchIteroScans({
        cursor: _cursor,
        licenseNumbers: getMappedLicenseNumbersHelper(licenseNumbers),
      });
      _fetchedIteroScans = [..._fetchedIteroScans, ...newFetchedIteroScansData.results];
      selectedIteroFiles = newFetchedIteroScansData.results.filter((file) => selectedIteroScanIds.includes(file.id));
      if (newFetchedIteroScansData.next) {
        const nextUrl = new URL(newFetchedIteroScansData.next);
        _cursor = nextUrl.searchParams.get('cursor');
      } else {
        _cursor = null;
      }
    }

    return { fetchedIteroScans: _fetchedIteroScans, selectedIteroFiles, nextCursor: _cursor };
  };

  /**
   * Retrieves the loaded state for Itero scans.
   * @param {Object} options - The options for retrieving the loaded state.
   * @param {Array} options.selectedIteroScanIds - The IDs of the selected Itero scans.
   * @param {Array} options.fetchedIteroScans - The fetched Itero scans.
   * @param {string} options.fetchedIteroScansNextUrlCursor - The next URL cursor for fetching Itero scans.
   * @param {Array} options.licenseNumbers - The license numbers associated with the Itero scans.
   * @returns {Object} - The loaded state for Itero scans.
   */
  getIteroScansLoadedState = async ({ selectedIteroScanIds, fetchedIteroScans, fetchedIteroScansNextUrlCursor, licenseNumbers }) => {
    const loadedState = {};
    let selectedIteroFiles = fetchedIteroScans.filter((file) => selectedIteroScanIds.includes(file.id));
    if (selectedIteroFiles.length) {
      loadedState.selectedIteroScanIds = selectedIteroScanIds;
      loadedState.selectedIteroId = selectedIteroFiles[0].itero_id;
    } else if (fetchedIteroScansNextUrlCursor) {
      const loadedSelectedIteroFilesData = await this.loadSelectedIteroFiles(
        fetchedIteroScansNextUrlCursor,
        selectedIteroScanIds,
        fetchedIteroScans,
        licenseNumbers
      );
      loadedState.selectedIteroScanIds = selectedIteroScanIds;
      loadedState.fetchedIteroScans = loadedSelectedIteroFilesData.fetchedIteroScans;
      loadedState.selectedIteroId = loadedSelectedIteroFilesData.selectedIteroFiles[0].itero_id;
      loadedState.fetchedIteroScansNextUrlCursor = loadedSelectedIteroFilesData.nextCursor;
    }

    return loadedState;
  };

  /**
   * iTero integration
   *
   * On selected itero id change
   */
  onSelectedIteroIdChange() {
    if (this.state.selectedIteroId) {
      this.setState({
        selectedIteroScans: this.state.fetchedIteroScans.filter((file) => file.itero_id === this.state.selectedIteroId && file.file_type === 'scan'),
      });
    } else {
      this.setState({ selectedIteroScans: [] });
    }
  }

  /**
   * iTero integration
   *
   * On selected itero scans change
   */
  onSelectedIteroScansChange() {
    if (this.state.selectedIteroScans.length) {
      this.setState({ selectedIteroScanIds: this.state.selectedIteroScans.map((file) => file.id) });
    } else {
      this.setState({ selectedIteroScanIds: [] });
    }
    this.updateSelectedIteroScanRecordsHelper();
    this.updateSelectedIteroScanPatientNameHelper();
  }

  /**
   * iTero integration
   *
   * On selected itero scans ids change
   */
  onSelectedIteroScanIdsChange() {
    throw new Error('onSelectedIteroScanIdsChange method is not implemented');
  }

  /**
   * iTero integration
   *
   * On selected itero id change
   */
  onUploadMethodChange() {
    if (this.state.removePhotosWhenUploadMethodIsManual) {
      throw new Error('onUploadMethodChange method is not defined in the state');
    }
  }

  /**
   * iTero integration
   *
   * On should fetch itero photos change
   */
  onShouldFetchIteroPhotosChange() {
    throw new Error('onShouldFetchIteroPhotosChange method is not implemented');
  }

  /**
   * iTero integration
   *
   * Helper for componentDidMount pre-defined conditions
   * @function
   */
  withIteroSelectionComponentDidMount() {
    if (!this.updateIteroPhotos) {
      throw new Error('updateIteroPhotos method is not implemented');
    }
  }

  /**
   * iTero integration
   *
   * Helper for componentDidUpdate pre-defined conditions
   * @function
   * @param {Object} prevProps - previous props
   * @param {Object} prevState - previous state
   */
  withIteroSelectionComponentDidUpdate(prevProps, prevState) {
    if (this.state.selectedIteroId !== prevState.selectedIteroId) {
      this.onSelectedIteroIdChange();
    }
    if (this.selectedIteroScansChanged(prevState.selectedIteroScans)) {
      this.onSelectedIteroScansChange();
    }
    if (this.selectedIteroScanIdsChanged(prevState.selectedIteroScanIds)) {
      this.onSelectedIteroScanIdsChange();
    }
    if (this.state.uploadMethod !== prevState.uploadMethod) {
      this.onUploadMethodChange();
    }
    if (this.state.shouldFetchIteroPhotos !== prevState.shouldFetchIteroPhotos) {
      this.onShouldFetchIteroPhotosChange();
    }
  }

  getCaseIdForIteroScans() {
    throw new Error('getCaseIdForIteroScans method is not implemented');
  }

  getIteroPhotosStateKey() {
    throw new Error('getIteroPhotosStateKey method is not implemented');
  }

  getUploadedPhotosWithouthIteroPhotos() {
    throw new Error('getUploadedPhotosWithouthIteroPhotos method is not implemented');
  }

  /**
   * iTero integration
   *
   * Helper for get props for Scans component with iTero integration
   * @function
   * @param {Object} ctx - context
   * @param {Object} defaults - defaults
   */
  getIteroSelectionProps(ctx, defaults = {}) {
    const currentCtx = ctx || this;

    return {
      licenseNumbers: currentCtx.props.licenseNumbers,
      onSelectedIteroIdChange: currentCtx.handleSelectedIteroIdChange,
      onSearchIteroScansQueryChange: currentCtx.handleSearchIteroScansQueryChange,
      onShowAlreadyUploadedScansChange: currentCtx.handleShowAlreadyUploadedScansChange,
      onShouldFetchIteroPhotosChange: currentCtx.handleShouldFetchIteroPhotosChange,
      searchIteroScansQuery: currentCtx.state.searchIteroScansQuery,
      selectedIteroId: currentCtx.state.selectedIteroId,
      showAlreadyUploadedScans: currentCtx.state.showAlreadyUploadedScans,
      enableIteroSelection: currentCtx.state.isEnrolledToiTeroIntegration,
      shouldFetchIteroPhotos: currentCtx.state.shouldFetchIteroPhotos,
      fetchedIteroScans: currentCtx.state.fetchedIteroScans,
      handleLoadMoreIteroScans: currentCtx.handleLoadMoreIteroScans,
      handleIteroFileSearch: currentCtx.handleIteroFileSearch,
      ...defaults,
    };
  }

  /**
   * iTero integration
   *
   * Helper that returns true if selected itero scans changed
   * @function
   * @param {Array} prevSelectedIteroScans - previous selected itero scans
   * @returns {Boolean} true if selected itero scans changed
   * @returns {Boolean} false if selected itero scans not changed
   */
  selectedIteroScansChanged(prevSelectedIteroScans) {
    return (
      this.state.selectedIteroScans.length !== prevSelectedIteroScans.length ||
      !this.state.selectedIteroScans.every((file) => prevSelectedIteroScans.find((_file) => _file.id === file.id))
    );
  }

  /**
   * iTero integration
   *
   * Helper that returns true if selected itero scans ids changed
   * @function
   * @param {Array} prevSelectedIteroScanIds - previous selected itero scans ids
   * @returns {Boolean} true if selected itero scans ids changed
   * @returns {Boolean} false if selected itero scans ids not changed
   */
  selectedIteroScanIdsChanged(prevSelectedIteroScanIds) {
    return (
      this.state.selectedIteroScanIds.length !== prevSelectedIteroScanIds.length ||
      !this.state.selectedIteroScanIds.every((id) => prevSelectedIteroScanIds.includes(id))
    );
  }

  /**
   * iTero integration
   *
   * Update selected itero file records
   */
  updateSelectedIteroScanRecordsHelper() {
    this.setState({ isBuildingIteroScansRecords: true });
    // update selectedIteroScanRecords state
    if (this.state.selectedIteroScans.length) {
      buildIteroScanRecordsHelper(this.getCaseIdForIteroScans(), this.state.selectedIteroScans).then((iteroScanRecords) => {
        this.setState({ selectedIteroScanRecords: iteroScanRecords, isBuildingIteroScansRecords: false });
      });
    } else if (this.state.selectedIteroScans.length === 0) {
      this.setState({ selectedIteroScanRecords: [], isBuildingIteroScansRecords: false });
    }
  }

  /**
   * iTero integration
   *
   * Update selected itero file records
   */
  updateSelectedIteroScanPatientNameHelper() {
    // update selectedIteroScanRecords state
    if (this.state.selectedIteroScans.length) {
      const firstFile = mapToIteroFilesCommonInfo(this.state.selectedIteroScans[0]);
      this.setState({
        selectedIteroPatientName: firstFile.patientName,
      });
    } else if (!this.state.selectedIteroId) {
      this.setState({
        selectedIteroPatientName: '',
      });
    }
  }

  /**
   * iTero integration
   *
   * Get record viewer subtitle
   * @function
   * @param {Boolean} checkUploadMethod - check upload method
   * @param {Boolean} isLoading - is loading
   * @returns {String} record viewer subtitle
   */
  getRecordViewerSubtitle(checkUploadMethod = true, isLoading = false) {
    if ((checkUploadMethod && this.state.uploadMethod !== 'itero') || isLoading) {
      return '';
    }
    if (this.state.selectedIteroPatientName && this.state.selectedIteroId) {
      return `${this.state.selectedIteroPatientName} (${this.state.selectedIteroId})`;
    }
    return '';
  }

  /**
   * iTero integration
   *
   * Handle scans upload method change
   * @function
   * @param {String} method - new scans upload method
   */
  handleUploadMethodChange(method) {
    this.setState({ uploadMethod: method });
  }

  /**
   * iTero integration
   *
   * Handle itero id change
   * @function
   * @param {Array} newSelectedIteroId - new selected itero id
   */
  handleSelectedIteroIdChange(newSelectedIteroId) {
    if (this.state.isEnrolledToiTeroPhotosIntegration && this.state.selectedIteroId !== newSelectedIteroId) {
      const oldIteroId = this.state.selectedIteroId;
      this.updateIteroPhotos(oldIteroId, newSelectedIteroId);
    }
    this.setState({ selectedIteroId: newSelectedIteroId });
  }

  /**
   * iTero integration
   *
   * Handle show already uploaded scans change
   * @function
   * @param {Boolean} show - new show already uploaded scans value
   */
  handleShowAlreadyUploadedScansChange(show) {
    this.setState({ showAlreadyUploadedScans: show });
  }

  /**
   * iTero integration
   *
   * Handle search itero scans query change
   * @function
   * @param {String} query - new search itero scans query
   */
  handleSearchIteroScansQueryChange(query) {
    this.setState({ searchIteroScansQuery: query });
  }

  /**
   * iTero integration
   *
   * Handle selected itero scans records change
   * @function
   * @param {Array} newSelectedIteroScanRecords - new selected itero scans records
   */
  handleSelectedIteroScanRecordsChange(newSelectedIteroScanRecords) {
    this.setState({ selectedIteroScanRecords: newSelectedIteroScanRecords });
  }

  /**
   * iTero integration
   *
   * Handle should fetch itero photos change
   * @function
   * @param {Boolean} shouldFetchIteroPhotos - should fetch itero photos
   */
  handleShouldFetchIteroPhotosChange(shouldFetchIteroPhotos) {
    this.setState({ shouldFetchIteroPhotos });
  }

  /**
   * iTero integration
   *
   * Retrieves the next cursor from the fetched Itero scans.
   * @param {Object} fetchedIteroScans - The fetched Itero scans object.
   * @returns {string|null} - The next cursor or null if it doesn't exist.
   */
  getNextCursorFromFetchedIteroScans = (fetchedIteroScans) => {
    if (fetchedIteroScans.next) {
      const nextUrl = new URL(fetchedIteroScans.next);
      return nextUrl.searchParams.get('cursor');
    }
    return null;
  };

  /**
   * iTero integration
   *
   * Handles the action of loading more Itero scans.
   * If there is a fetchedIteroScansNextUrlCursor, it fetches more Itero scans using the fetchIteroScans function.
   * Updates the state with the fetched Itero scans and the next cursor.
   * @returns {Promise<void>} A promise that resolves when the loading of more Itero scans is complete.
   */
  handleLoadMoreIteroScans = async () => {
    if (this.state.fetchedIteroScansNextUrlCursor) {
      const newFetchedIteroScansData = await this.props.fetchIteroScans({
        cursor: this.state.fetchedIteroScansNextUrlCursor,
        licenseNumbers: getMappedLicenseNumbersHelper(this.props.licenseNumbers),
      });
      this.setState({
        fetchedIteroScans: [...this.state.fetchedIteroScans, ...newFetchedIteroScansData.results],
        fetchedIteroScansNextUrlCursor: this.getNextCursorFromFetchedIteroScans(newFetchedIteroScansData),
      });
    }
  };

  /**
   * iTero integration
   *
   * Handles the search for Itero files.
   * @param {string} searchQuery - The search query.
   * @returns {Promise<void>} - A promise that resolves when the search is complete.
   */
  handleIteroFileSearch = async (searchQuery) => {
    const newFetchedIteroScansData = await this.props.fetchIteroScans({
      searchQuery,
      licenseNumbers: getMappedLicenseNumbersHelper(this.props.licenseNumbers),
    });
    this.setState({
      fetchedIteroScans: [...this.state.selectedIteroScans, ...newFetchedIteroScansData.results],
      fetchedIteroScansNextUrlCursor: this.getNextCursorFromFetchedIteroScans(newFetchedIteroScansData),
    });
  };

  /**
   * iTero integration
   *
   * Returns the length of the photo upload array.
   * @param {Array} photoUpload - The photo upload array to get the length of.
   * @returns {number} - The length of the photo upload array.
   */
  getPhotoUploadLength = (photoUpload) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    if (this.state.shouldRequiredManualPhotoUpload) {
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(currentPhotoUpload);
      return filteredPrevStatePhotoUpload.length;
    }
    return currentPhotoUpload.length;
  };

  /**
   * iTero integration
   *
   * Checks if the length of the photo upload is valid.
   * @param {Array} photoUpload - The photo upload to check.
   * @returns {boolean} - True if the length is valid, false otherwise.
   */
  isPhotoUploadLengthValid = (photoUpload) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    return this.getPhotoUploadLength(currentPhotoUpload) >= this.validPhotoUploadMinLength;
  };

  /**
   * iTero integration
   *
   * function to get the message to be displayed in the dropzone
   * @function
   * @return {String} Returns the message to be displayed in the dropzone
   */
  getIteroPhotosDropzoneMessage = () => {
    return `Records have been automatically uploaded from ${
      this.state.selectedIteroPatientName ? 'iTero patient ' + this.state.selectedIteroPatientName : 'selected iTero patient'
    }. Photos of your patient are still required, you may upload below.`;
  };
}

export class WithIteroScansSelection extends WithIteroSelection {
  constructor(props) {
    super(props);

    if (!props.buildRecordStates) {
      throw new Error('buildRecordStates redux action method is not in props');
    }
  }

  /**
   * iTero integration
   *
   * Update case itero scans helper
   */
  updateCaseIteroFilesHelper = () => {
    if (!this.state.iteroFileCaseType) {
      throw new Error('iteroFileCaseType is not defined in the state');
    }
    if (!this.props.updateCaseIteroFiles) {
      throw new Error('updateCaseIteroFiles redux action method is not in props');
    }
    // update case itero scans case relation in backend
    this.props.updateCaseIteroFiles(this.getCaseIdForIteroScans(), {
      case_type: this.state.iteroFileCaseType,
      itero_files: this.state.selectedIteroScanIds,
    });
  };

  /**
   * iTero integration
   *
   * On upload method change
   */
  onUploadMethodChange = () => {
    throw new Error('onUploadMethodChange method is not implemented');
  };

  /**
   * iTero integration
   *
   * Helper for componentDidUpdate pre-defined conditions
   * @function
   * @param {Object} prevProps - previous props
   * @param {Object} prevState - previous state
   */
  withIteroScansSelectionComponentDidUpdate(prevProps, prevState) {
    super.withIteroSelectionComponentDidUpdate(prevProps, prevState);
    if (this.state.selectedIteroScanRecords !== prevState.selectedIteroScanRecords) {
      this.buildIteroRecords();
    }
  }

  /**
   * iTero integration
   *
   * Helper for set record viewerd records redux state for selected itero scans
   * @function
   * @param {Boolean} runCheckBeforeBuild - run check before build
   */
  buildIteroRecords = (runCheckBeforeBuild = true) => {
    if (runCheckBeforeBuild && !this.shouldBuildIteroRecordViewer()) {
      return;
    }
    if (this.state.selectedIteroScanRecords.length) {
      const selectedIteroScanRecordsFilesIds = this.state.selectedIteroScanRecords.map((file) => file.id);
      if (
        this.state.selectedIteroScans.length &&
        this.state.selectedIteroScans.every((selectedFile) => selectedIteroScanRecordsFilesIds.includes(selectedFile.id))
      ) {
        this.props.buildRecordStates(this.state.selectedIteroScanRecords);
      } else {
        this.props.buildRecordStates([]);
      }
    } else if (this.state.selectedIteroScans.length) {
      buildIteroScanRecordsHelper(this.getCaseIdForIteroScans(), this.state.selectedIteroScans).then((iteroScanRecords) => {
        this.setState({ selectedIteroScanRecords: iteroScanRecords });
        this.props.buildRecordStates(iteroScanRecords);
      });
    } else {
      this.props.buildRecordStates([]);
    }
  };

  /**
   * iTero integration
   *
   * Helper that returns true if should build itero record viewer
   * @function
   * @returns {Boolean} true if should build itero record viewer
   * @returns {Boolean} false if should not build itero record viewer
   */
  shouldBuildIteroRecordViewer = () => {
    throw new Error('shouldBuildIteroRecordViewer method is not implemented');
  };

  /**
   * iTero integration
   *
   * Helper that returns true if selected itero scans records changed
   * @function
   * @param {Array} prevSelectedIteroScanRecords - previous selected itero scans records
   * @returns {Boolean} true if selected itero scans records changed
   * @returns {Boolean} false if selected itero scans records not changed
   */
  selectedIteroScanRecordsChanged = (prevSelectedIteroScanRecords) => {
    return (
      this.state.selectedIteroScanRecords.length !== prevSelectedIteroScanRecords.length ||
      !this.state.selectedIteroScanRecords.every((record) => prevSelectedIteroScanRecords.find((_record) => _record.file_url === record.file_url))
    );
  };

  /**
   * iTero integration
   *
   * Helper that returns true if scans length is valid
   * @function
   * @param {Object} ctx - context
   */
  isScansLengthValid = (ctx) => {
    const currentCtx = ctx || this;
    if (currentCtx.state.uploadMethod === 'manual') {
      return currentCtx.state.scanUpload?.length === 2;
    }

    if (currentCtx.state.uploadMethod === 'itero') {
      return currentCtx.state.selectedIteroScans?.length === 2;
    }
  };

  /**
   * iTero integration
   *
   * Helper that returns scans length
   * @function
   * @param {Object} ctx - context
   */
  getScansLength = (ctx) => {
    const currentCtx = ctx || this;
    if (currentCtx.state.uploadMethod === 'manual') {
      return currentCtx.state.scanUpload?.length || 0;
    }

    if (currentCtx.state.uploadMethod === 'itero') {
      return currentCtx.state.selectedIteroScans?.length || 0;
    }
  };

  /**
   * iTero integration
   *
   * Handle scans upload method change
   * @function
   * @param {String} method - new scans upload method
   */
  handleScansUploadMethodChange = (method) => {
    super.handleUploadMethodChange(method);
    if (method === 'itero') {
      this.buildIteroRecords(false);
    }
  };
}
