import React, { useEffect } from 'react';
import Button from '../../setup_viewer/components/extras/button';
import WasmChangesSummary from '../wasm_changes_summary';
import { Verbiage } from '../verbiage.constants';
import { HideContentIf } from '../../../common/hocs/hide-content-if';

function getMessage(hasRevisionNote, hasModelChange) {
  if (hasRevisionNote && hasModelChange) {
    return Verbiage.RevisionModal.NoteAndModelChange;
  } else if (hasRevisionNote) {
    return Verbiage.RevisionModal.NoteOnly;
  } else if (hasModelChange) {
    return Verbiage.RevisionModal.ModelOnly;
  } else {
    return Verbiage.RevisionModal.NoChange;
  }
}

function RevisionConfirmation(props) {
  const {
    movement,
    ipr_change: iprChange,
    revision_note: revisionNote,
    has_revision_note: hasRevisionNote,
    has_model_change: hasModelChange,
    confirm,
    cancel,
    disabled,
    approveTooltip,
  } = props;
  const hasChanges = hasRevisionNote || hasModelChange;
  const cancelTheme = hasChanges ? 'secondary' : 'danger';
  const cancelText = hasChanges ? Verbiage.RevisionModal.CancelBtn : Verbiage.RevisionModal.OkBtn;
  const title = hasChanges ? Verbiage.RevisionModal.ConfirmTitle : Verbiage.RevisionModal.SubmitErrorTitle;
  const messageClassname = hasChanges ? 'wasm-yellow-text' : '';

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  const approveButton = (
    <Button theme="success" onClick={confirm} disabled={disabled}>
      <div data-toggle="tooltip" data-placement="top" title={approveTooltip}>
        {Verbiage.RevisionModal.ApproveBtn}
      </div>
    </Button>
  );
  const reviseButton = (
    <Button theme="danger" onClick={confirm} disabled={disabled}>
      {Verbiage.RevisionModal.ReviseBtn}
    </Button>
  );
  const submitButton = hasRevisionNote ? reviseButton : approveButton;

  return (
    <div>
      <div className="modal-title">{title}</div>
      <div className="modal-body-paragraph">
        <div className={messageClassname}>{getMessage(hasRevisionNote, hasModelChange)}</div>
        {hasChanges && <div className="horizontal-divider" />}
        {hasChanges && <WasmChangesSummary movement={movement} ipr_change={iprChange} revision_note={revisionNote} />}
      </div>
      <div className="modal-button-group">
        <HideContentIf condition={!hasChanges}>{submitButton}</HideContentIf>
        <Button theme={cancelTheme} onClick={cancel}>
          {cancelText}
        </Button>
      </div>
    </div>
  );
}

export default RevisionConfirmation;
