import React, { Component } from 'react';
import Axios from 'axios';
import './cancel_case.scss';

// Internal Components
import Modal from '../../components/modal/modal';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { removeCaseIdInitialNumber, redirectToCaseDetail } from '../../common/functions';

class ConvertCaseGeneration extends Component {
  constructor(props) {
    super(props);

    this.state = { confirm_btn_clicked: false, new_case_id: '', loading: false };
  }

  /**
   * On confirm cancel case btn click
   * @function
   */
  onConfirmButtonClick = () => {
    const that = this;
    let form_data = new FormData();

    form_data.append('status_comment', this.isGen1ToGen2Convert() ? 'Converted to Gen 2.0' : 'Converted to Gen 1');
    form_data.append('doctor_id', this.props.doctor_id);
    form_data.append('case_status', this.props.case_status);
    that.setState({ loading: true });

    Axios.post(`/apiv3/case/${this.props.case_id}/convertcasegeneration/${this.props.convert_to}`, form_data)
      .then((res) => {
        that.setState({
          confirm_btn_clicked: true,
          new_case_id: res.data.case_id,
          loading: false,
        });
        that.sendEmails(res.data.case_id);
      })
      .catch((err) => {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status) {
          that.setState({ loading: false });
          that.props.closeModal();
          that.props.refresh();
        }
      });
  };

  /**
   * Get message text with case id
   * @function
   * @param {Boolean} - Is after or the before convert modal
   * @return {String} - Text message for the modal
   */
  getMessageText = (after_convert) => {
    if (after_convert) {
      if (this.props.another_gen_case_exists) {
        return 'This case has been successfully cancelled and the original case has been resumed.';
      } else {
        return (
          'Case ' +
          this.props.case_id +
          ' for ' +
          this.props.patient_name +
          ' has been successfully cancelled. A new case ' +
          this.state.new_case_id +
          ' has been created for this patient. '
        );
      }
    } else {
      const generation = this.isGen1ToGen2Convert() ? 'Gen 2.0' : 'Gen 1';

      if (this.props.another_gen_case_exists) {
        return (
          'There is an existing ' +
          generation +
          ' case created for this patient. Would you like to convert this case back to ' +
          generation +
          '? The current case will be cancelled and the original case will be resumed.'
        );
      } else {
        return (
          'Are you sure you would like to convert this case to ' +
          generation +
          '? The current case will be cancelled and a new one will be automatically created.'
        );
      }
    }
  };

  /**
   * Get header text for the modal
   * @function
   * @param {Boolean} - Is after or the before convert modal
   * @return {String} - Text for the modal header
   */
  getHeaderText = (after_convert) => {
    let text_prefix = '';
    let text_postfix = '';
    if (after_convert) {
      text_prefix = this.isGen1ToGen2Convert() ? 'Case Sucessfully Converted to Gen 2.0 - ' : 'Case Sucessfully Converted to Gen 1 - ';
      text_postfix = this.props.modal_theme === 'ipp' ? this.props.patient_name : this.state.new_case_id;
      return text_prefix + text_postfix;
    } else {
      text_prefix = this.isGen1ToGen2Convert() ? 'Convert to Gen 2.0 - ' : 'Convert to Gen 1 - ';
      text_postfix = this.props.modal_theme === 'ipp' ? this.props.patient_name : this.props.case_id;
      return text_prefix + text_postfix;
    }
  };

  /**
   * Get if it is a gen 2.0 to gen 1 convert
   * @function
   * @return {Boolean} - True if is converting gen 1 to gen 2.0, false if convert gen 2.0 to gen 1
   */
  isGen1ToGen2Convert = () => {
    return this.props.gen_2 ? false : true;
  };

  /**
   * Send Emails
   * @function
   * @param {String} case_id_after_conversion - Case id after conversion
   */
  sendEmails = (case_id_after_conversion) => {
    const conversion_gen_type = this.isGen1ToGen2Convert() ? 'Gen 2' : ' Gen 1';
    Axios.post(
      `/api/email/?slug=case-converted&caseId=${removeCaseIdInitialNumber(this.props.case_id)}&case_id_after_conversion=${removeCaseIdInitialNumber(
        case_id_after_conversion
      )}&conversion_gen_type=${conversion_gen_type}&method=standard&provider=${window.location.origin}&doctorId=${this.props.doctor_id}`
    );

    Axios.post(
      `/api/email/?slug=case-converted-internal&caseId=${removeCaseIdInitialNumber(this.props.case_id)}&case_id_after_conversion=${removeCaseIdInitialNumber(
        case_id_after_conversion
      )}&conversion_gen_type=${conversion_gen_type}&method=standard&provider=${window.location.origin}&doctorId=${this.props.doctor_id}`
    );

    Axios.post(
      `/api/email/?slug=assigned-case-cancellation&caseId=${removeCaseIdInitialNumber(this.props.case_id)}&method=standard&doctorId=${
        this.props.doctor_id
      }&provider=${window.location.origin}`
    );
    if (this.props.convert_to === 'resume_original_case') {
      Axios.post(
        `/api/email/?slug=assigned-case-cancellation-reversed&caseId=${removeCaseIdInitialNumber(
          this.props.case_id_after_conversion
        )}&method=standard&doctorId=${this.props.doctor_id}&provider=${window.location.origin}`
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.confirm_btn_clicked ? (
          <>
            <Modal
              preset="decision"
              header_text={this.getHeaderText(true)}
              confirm_btn_text="Go to Case"
              close_btn_text="Close"
              onConfirmButtonClick={() => redirectToCaseDetail(this.state.new_case_id, this.props.history.location.pathname, true)}
              onCloseButtonClick={this.props.closeCancelCaseModalAndReload}
              message_text={this.getMessageText(true)}
              theme={this.props.modal_theme}
            />
          </>
        ) : null}

        {this.state.confirm_btn_clicked ? null : (
          <Modal
            preset="decision"
            header_text={this.getHeaderText(false)}
            confirm_btn_text="Confirm"
            close_btn_text="Cancel"
            in_progress={this.state.loading}
            in_progress_text={this.isGen1ToGen2Convert() ? 'Converting to Gen 2.0 ...' : 'Converting to Gen 1 ...'}
            onConfirmButtonClick={this.onConfirmButtonClick}
            onCloseButtonClick={this.props.closeModal}
            message_text={this.getMessageText(false)}
            theme={this.props.modal_theme}
          />
        )}
      </div>
    );
  }
}

export default ConvertCaseGeneration;
