export default class ValidationUtils {
  static isValidEmail(email) {
    // eslint-disable-next-line
    let reg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (reg.test(email) === false) {
      return false;
    }

    return true;
  }

  static isValidPhoneNo(phoneno) {
    // eslint-disable-next-line
    let regPhone = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
    return phoneno.match(regPhone);
  }

  static validateInternationalPhone(phoneno) {
    const pattern = /^\+?[1-9]\d{1,14}$/;
    if (phoneno === '') return false;
    return pattern.test(phoneno);
  }

  static isValidURL(url) {
    let expression =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\.(?:[a-z\\u00a1-\\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

    if (expression.test(url)) {
      return true;
    }
    return false;
  }

  static doPasswordsMatch(password1, password2) {
    return password1 === password2;
  }

  static isValidPassword(password) {
    return password.length >= 8;
  }
}

export class TransformationPipe {
  constructor(...transformers) {
    this.transformers = transformers;
  }

  transform = (value, options = {}) => {
    let result = value;
    for (const cb of this.transformers) {
      result = cb(result, options);
    }
    return result;
  };
}

export class ValidationPipe {
  constructor(...validators) {
    this.validators = validators;
  }

  validate = (value) => {
    for (const cb of this.validators) {
      if (!cb(value)) {
        return false;
      }
    }

    return true;
  };
}
