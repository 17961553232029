import {
  OPEN_EDIT_PATIENT_MODAL,
  CLOSE_EDIT_PATIENT_MODAL,
  SET_PATIENT_REF,
  FETCH_DSO_DOCTOR_DATA_PENDING,
  FETCH_DSO_DOCTOR_DATA_SUCCESS,
  FETCH_DSO_DOCTOR_DATA_ERROR,
  SET_DSO_DOCTOR_DROPDOWN_VALUE,
  SET_DSO_ADDRESS_DROPDOWN_VALUE,
} from '../../actions/ipp/case_details';

const initialState = {
  edit_patient_modal: false,
  patient_ref: '',
  pending: false,
  error: null,
  dso_doctors: [],
  dso_addresses: [],
  dso_doctor_dropdown_id: '',
  dso_address_dropdown_id: '',
};

export function caseDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_EDIT_PATIENT_MODAL:
      return {
        ...state,
        edit_patient_modal: true,
        patient_ref: action.patient_ref,
        dso_doctor_dropdown_id: action.dso_doctor_id,
        dso_address_dropdown_id: action.dso_address_id,
      };
    case CLOSE_EDIT_PATIENT_MODAL:
      return {
        ...state,
        edit_patient_modal: false,
      };
    case SET_PATIENT_REF:
      return {
        ...state,
        patient_ref: action.patient_ref,
      };

    case FETCH_DSO_DOCTOR_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DSO_DOCTOR_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        dso_doctors: action.dso_doctors,
        dso_addresses: action.dso_addresses,
      };
    case FETCH_DSO_DOCTOR_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case SET_DSO_DOCTOR_DROPDOWN_VALUE:
      return {
        ...state,
        dso_doctor_dropdown_id: action.dso_doctor_dropdown_id,
      };

    case SET_DSO_ADDRESS_DROPDOWN_VALUE:
      return {
        ...state,
        dso_address_dropdown_id: action.dso_address_dropdown_id,
      };
    default:
      return state;
  }
}

export const getEditPatientModal = (state) => state.caseDetailsReducer.edit_patient_modal;
export const getPatientRef = (state) => state.caseDetailsReducer.patient_ref;
export const getDsoDoctors = (state) => state.caseDetailsReducer.dso_doctors;
export const getDsoAddresses = (state) => state.caseDetailsReducer.dso_addresses;
export const getDsoDoctorDropdownId = (state) => state.caseDetailsReducer.dso_doctor_dropdown_id;
export const getDsoAddressDropdownId = (state) => state.caseDetailsReducer.dso_address_dropdown_id;
