import { signal } from '@preact/signals-react';

export const ENotificationType = {
  Error: 'error',
  Warning: 'warn',
  Info: 'info',
};

export class SignalNotification {
  constructor(message, type, visibleTimeMs = -1) {
    this.id = (Math.random() + 1).toString(36).substring(2);
    this.message = message;
    this.type = type;
    this.visibleTimeMs = visibleTimeMs;
  }
}

/**
 *
 * @type {SignalNotification[]}
 */
const initialNotifications = [];

export const notificationsSignal = signal(initialNotifications);

/**
 * Creates a notification to be shown somewhere in the app
 * @param message {String}
 * @param type {ENotificationType}
 * @param [visibleTimeMs] {Number}
 */
export const createNotification = (message, type, visibleTimeMs) => {
  const notification = new SignalNotification(message, type, visibleTimeMs);
  notificationsSignal.value = [...notificationsSignal.value, notification];
  return notification;
};

/**
 * Dismisses a notification with optional timeout parameter
 * @param id {String}
 * @param [timeout] {Number}
 */
export const dismissNotification = (id, timeout) => {
  const dismiss = () => {
    notificationsSignal.value = notificationsSignal.value.filter((n) => n.id !== id);
  };

  if (typeof timeout === 'number') {
    return setTimeout(dismiss, timeout);
  }
  dismiss();
};
