import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wraps the children with a filter container
 * @component
 */
function FilterContainer({ className, ...props }) {
  const classNames = `${className ? className : ''} card card--padding mb-sm`;
  return <div className={classNames} {...props} />;
}

FilterContainer.propTypes = {
  children: PropTypes.node,
};

FilterContainer.defaultProps = {
  children: null,
};

export default FilterContainer;
