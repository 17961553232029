import './message_banner.scss';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getDoctorsIdFromRoute } from '../../common/route';
import PropTypes from 'prop-types';

const MAIN_CLASS = 'banner-message-info';

/**
 * Gets the height of the feature announcement element.
 *
 * @returns {Number} The height of the child paragraph element within the main element.
 */
export const getFeatureAnnouncementElementHeight = () => {
  let result = 0;
  const featureAnnouncementElement = document.querySelector(`.${MAIN_CLASS}`);
  const featureAnnouncementChildElement = featureAnnouncementElement?.querySelector('p');

  // Get the height of the child element if it exists
  result = featureAnnouncementChildElement?.clientHeight || 0;

  return result;
};

/**
 * Renders a feature announcement message with the given information.
 * @param {Object} props - The component props.
 * @returns {JSX.Element|null} The holiday message component.
 */
const FeatureAnnouncementBanner = ({ location }) => {
  const showIteroSettingsLink = !location?.pathname.includes('/account/itero');
  return (
    <div className={MAIN_CLASS}>
      <p className={`${MAIN_CLASS}__text`}>
        New Feature Update: iTero integration feature is live!
        <br />
        Integrate your iTero scanner today to submit your toothprints faster.{' '}
        {showIteroSettingsLink ? (
          <>
            Click{' '}
            <Link to={`/portal/${getDoctorsIdFromRoute()}/account/itero`} className="banner-message-info__link">
              General Settings
            </Link>{' '}
            to get started!
          </>
        ) : null}
      </p>
    </div>
  );
};

FeatureAnnouncementBanner.propTypes = {
  location: PropTypes.object,
};

export default withRouter(FeatureAnnouncementBanner);
