import Axios from 'axios';

//Action Imports
import { fetchDdmState } from './ddm_state';
import { fetchIfuState } from '../ifu/ifu_state';
export const OPEN_DDM_GENERATE_MODAL = 'OPEN_DDM_GENERATE_MODAL';
export const CLOSE_DDM_GENERATE_MODAL = 'CLOSE_DDM_GENERATE_MODAL';
export const OPEN_DDM_MANUAL_MODAL = 'OPEN_DDM_MANUAL_MODAL';
export const CLOSE_DDM_MANUAL_MODAL = 'CLOSE_DDM_MANUAL_MODAL';
export const OPEN_DDM_OVERRIDE_MODAL = 'OPEN_DDM_OVERRIDE_MODAL';
export const CLOSE_DDM_OVERRIDE_MODAL = 'CLOSE_DDM_OVERRIDE_MODAL';
export const GENERATE_SUCCESS = 'GENERATE_SUCCESS';
export const GENERATE_PENDING = 'GENERATE_PENDING';
export const GENERATE_ERROR = 'GENERATE_ERROR';
export const MANUAL_PROCESS_PENDING = 'MANUAL_PROCESS_PENDING';
export const MANUAL_PROCESS_SUCCESS = 'MANUAL_PROCESS_SUCCESS';
export const MANUAL_PROCESS_ERROR = 'MANUAL_PROCESS_ERROR';
export const OVERRIDE_PENDING = 'OVERRIDE_PENDING';
export const OVERRIDE_SUCCESS = 'OVERRIDE_SUCCESS';
export const OVERRIDE_ERROR = 'OVERRIDE_ERROR';
export const CLEAR_DDM_UPLOAD_PENDING = 'CLEAR_DDM_UPLOAD_PENDING';
export const UPLOAD_DDM_INPUT_FILE_PENDING = 'UPLOAD_DDM_INPUT_FILE_PENDING';
export const UPLOAD_DDM_INPUT_FILE_SUCCESS = 'UPLOAD_DDM_INPUT_FILE_SUCCESS';
export const UPLOAD_DDM_INPUT_FILE_ERROR = 'UPLOAD_DDM_INPUT_FILE_ERROR';
export const OPEN_REMOVE_DDM_UPLOAD_MODAL = 'OPEN_REMOVE_DDM_UPLOAD_MODAL';
export const CLOSE_REMOVE_DDM_UPLOAD_MODAL = 'CLOSE_REMOVE_DDM_UPLOAD_MODAL';
export const DELETE_DDM_UPLOAD_PENDING = 'DELETE_DDM_UPLOAD_PENDING';
export const DELETE_DDM_UPLOAD_SUCCESS = 'DELETE_DDM_UPLOAD_SUCCESS';
export const DELETE_DDM_UPLOAD_ERROR = 'DELETE_DDM_UPLOAD_ERROR';
export const SET_DDM_FILE_NAME = 'SET_DDM_FILE_NAME';
export const SET_DDM_INCORRECT_FILE_NAME_ERROR = 'SET_DDM_INCORRECT_FILE_NAME';
export const SET_DDM_FILE_TYPE_ERROR = 'SET_DDM_FILE_TYPE_ERROR';
export const CLEAR_DDM_FILE_TYPE_ERROR = 'CLEAR_DDM_FILE_TYPE_ERROR';

export function openGenerateModal() {
  return {
    type: OPEN_DDM_GENERATE_MODAL,
  };
}
export function onGenerateClose() {
  return {
    type: CLOSE_DDM_GENERATE_MODAL,
  };
}
export function openManualModal() {
  return {
    type: OPEN_DDM_MANUAL_MODAL,
  };
}
export function onManualClose() {
  return {
    type: CLOSE_DDM_MANUAL_MODAL,
  };
}
export function openOverrideModal() {
  return {
    type: OPEN_DDM_OVERRIDE_MODAL,
  };
}
export function onOverrideClose() {
  return {
    type: CLOSE_DDM_OVERRIDE_MODAL,
  };
}
export function onConfirmButtonClickGenerate() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(generatePending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/generate`)
      .then((res) => {
        dispatch(generateSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(onGenerateClose());
      })
      .catch((error) => {
        dispatch(generateError(error));
      });
  };
}
export function onConfirmButtonClickManual() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(manualPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/manual`)
      .then((res) => {
        dispatch(manualSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(onManualClose());
      })
      .catch((error) => {
        dispatch(manualError(error));
      });
  };
}
export function onConfirmButtonClickOverride() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(overridePending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/override`)
      .then((res) => {
        dispatch(overrideSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(onOverrideClose());
      })
      .catch((error) => {
        dispatch(overrideError(error));
      });
  };
}

export function generatePending() {
  return {
    type: GENERATE_PENDING,
  };
}
export function generateSuccess() {
  return {
    type: GENERATE_SUCCESS,
  };
}
export function generateError(error) {
  return {
    type: GENERATE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function manualPending() {
  return {
    type: MANUAL_PROCESS_PENDING,
  };
}
export function manualSuccess() {
  return {
    type: MANUAL_PROCESS_SUCCESS,
  };
}
export function manualError(error) {
  return {
    type: MANUAL_PROCESS_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
export function overridePending() {
  return {
    type: OVERRIDE_PENDING,
  };
}
export function overrideSuccess() {
  return {
    type: OVERRIDE_SUCCESS,
  };
}
export function overrideError(error) {
  return {
    type: OVERRIDE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
export function setDdmFileTypeError() {
  return {
    type: SET_DDM_FILE_TYPE_ERROR,
  };
}
export function clearDdmFileTypeError() {
  return {
    type: CLEAR_DDM_FILE_TYPE_ERROR,
  };
}
/**
 * Uploads DDM 3Matic input file
 * @function
 * @returns {object} - Action type
 */
export function uploadDdmInputFile(data) {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    let form_data = new FormData();
    form_data.append('files', JSON.stringify(data));

    dispatch(uploadDdmInputFilePending());

    Axios.post(`/apiV2/casefiles/${case_id}/add`, form_data)
      .then((res) => {
        dispatch(fetchDdmState(case_id));

        dispatch(uploadDdmInputFileSuccess());

        return res;
      })
      .catch((error) => {
        dispatch(uploadDdmInputFileError(error));
      });
  };
}

export function uploadDdmInputFilePending() {
  return {
    type: UPLOAD_DDM_INPUT_FILE_PENDING,
  };
}
export function clearDdmUploadPending() {
  return {
    type: CLEAR_DDM_UPLOAD_PENDING,
  };
}
export function uploadDdmInputFileSuccess() {
  return {
    type: UPLOAD_DDM_INPUT_FILE_SUCCESS,
  };
}

export function uploadDdmInputFileError(error) {
  return {
    type: UPLOAD_DDM_INPUT_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

/**
 * Opens modal for removing DDM input file
 * @function
 * @returns {object} - Action type
 */
export function openRemoveDdmUploadModal(event) {
  event.preventDefault();
  let href = event.currentTarget.href;
  let startingPath = href.indexOf('/', 9);
  let endPath = href.lastIndexOf('/');

  if (startingPath >= 0 && endPath >= 0) {
    return {
      type: OPEN_REMOVE_DDM_UPLOAD_MODAL,
    };
  }
  return;
}

/**
 * Closes modal for removing DDM input file
 * @function
 * @returns {object} - Action type
 */
export function closeRemoveDdmUploadModal() {
  return {
    type: CLOSE_REMOVE_DDM_UPLOAD_MODAL,
  };
}

/**
 * Removes DDM input file
 * @function
 * @returns {object} - Action type
 */
export function onConfirmButtonClickRemoveDdmUpload() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(deleteDdmUploadPending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ddm/remove_file`)
      .then((res) => {
        dispatch(deleteDdmUploadSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(closeRemoveDdmUploadModal());
        dispatch(fetchIfuState(case_id));
      })
      .catch((error) => {
        dispatch(deleteDdmUploadError(error));
      });
  };
}

export function deleteDdmUploadPending() {
  return {
    type: DELETE_DDM_UPLOAD_PENDING,
  };
}
export function deleteDdmUploadSuccess() {
  return {
    type: DELETE_DDM_UPLOAD_SUCCESS,
  };
}

export function deleteDdmUploadError(error) {
  return {
    type: DELETE_DDM_UPLOAD_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function setDDMIncorrectFileNameError(error_type, error) {
  return {
    type: SET_DDM_INCORRECT_FILE_NAME_ERROR,
    error: error,
    error_type: error_type,
  };
}

export function setDdmFileName(filename) {
  return {
    type: SET_DDM_FILE_NAME,
    filename: filename,
  };
}
