import { OrthographicCamera, Vector3, Quaternion } from 'three';
import { Tween, Easing, autoPlay } from 'es6-tween';

const SIDE_ANGLE = 65;

autoPlay(true);

/**
 * Class logic for camera and updating views
 */
class Camera {
  constructor(container, target, update) {
    const width = container.clientWidth;
    const height = container.clientHeight;
    this.camera = new OrthographicCamera(width / -10, width / 10, height / 10, height / -10, 1, 1000);
    this.view = '';
    this.camTween = new Tween();
    this.controls = null;
    this.update = update;
    this.setView('front', target, 0);
  }

  /**
   * Get the camera
   * @function
   * @return {THREE.Camera} The camera
   */
  getCamera() {
    return this.camera;
  }

  /**
   * Adds an Object3D as camera's children
   * @function
   * @param {THREE.Object3D} children - Children to add
   */
  addChildren(children) {
    this.camera.add(children);
  }

  /**
   * Adds camera to the scene
   * @function
   * @param {THREE.Object3D} scene - The scene to contain camera
   */
  addToScene(scene) {
    scene.add(this.camera);
  }

  /**
   * Adds control to camera
   * @function
   * @param {THREE.OrbitControls} controls - The camera's camera
   */
  setControls(controls) {
    this.controls = controls;
  }

  /**
   * Reset the camera view
   * @function
   */
  resetView() {
    this.view = '';
  }

  /**
   * Set the camera to a particular view
   * @function
   * @param {String} view - The view to change to
   * @param {THREE.Object3D} target - The target to look at
   * @param {Number} duration - View change animation duration
   */
  setView(view, target, duration = 700) {
    if (view === '' || view === this.view) return;

    this.view = view;
    const [xv, yv] = this.computeSideXYVector(SIDE_ANGLE);
    const vec = new Vector3();
    const up_vec = new Vector3(0, 1, 0);
    switch (view) {
      case 'front':
        vec.set(0, 0, 1);
        break;
      case 'back':
        vec.set(0, 0, -1);
        break;
      case 'top':
        vec.set(0, -1, 0);
        up_vec.set(0, 0, 1);
        break;
      case 'right':
        vec.set(-xv, 0, yv);
        break;
      case 'bottom':
        vec.set(0, 1, 0);
        up_vec.set(0, 0, -1);
        break;
      case 'left':
        vec.set(xv, 0, yv);
        break;
      default:
    }

    const radius = 200;
    const pos = vec.multiplyScalar(radius);

    const tempCamera = this.camera.clone();
    tempCamera.position.set(...pos.toArray());
    tempCamera.up.set(...up_vec.toArray());
    tempCamera.lookAt(new Vector3());

    this.tweenRotation(tempCamera.quaternion, duration);
    this.tweenPosition(duration);
  }

  /**
   * Computes the x, y vector for side angle
   * @function
   * @param {Number} side_deg - The side angle
   * @return {List} The x and y vector
   */
  computeSideXYVector(side_deg) {
    const side_rad = (side_deg / 180) * Math.PI;
    const xv = Math.sin(side_rad);
    const yv = Math.cos(side_rad);
    return [xv, yv];
  }

  /**
   * Rotate the camera to the targetQuaternion
   * @function
   * @param {THREE.Quaternion} targetQuaternion - The target Quaternion to rotate to
   * @param {Number} duration - The animation duration
   */
  tweenRotation(targetQuaternion, duration) {
    const qm = new Quaternion();
    const curQuaternion = this.camera.quaternion;
    const dur = (duration / Math.PI) * curQuaternion.angleTo(targetQuaternion);
    const ct = { t: 0 };
    this.camTween.stop();
    this.camTween = new Tween(ct)
      .to({ t: 1 }, dur)
      .easing(Easing.Sinusoidal.InOut)
      .on('update', () => {
        Quaternion.slerp(curQuaternion, targetQuaternion, qm, ct.t);
        this.updateCameraPos(qm);
      });

    this.camTween.start();
  }

  /**
   * Position the camera to center
   * @function
   * @param {Number} duration - The animation duration
   */
  tweenPosition(duration) {
    if (!this.controls) return;

    const p0 = this.controls.target.clone();
    const pF = new Vector3();
    new Tween(p0)
      .to(pF, duration)
      .easing(Easing.Sinusoidal.InOut)
      .on('update', () => {
        this.controls.target.copy(p0);
        this.updateCameraPos();
      })
      .start();
  }

  /**
   * Update the camera position to the target
   * @function
   * @param {THREE.Quaternion} quat - The target Quaternion
   */
  updateCameraPos(quat) {
    if (!this.controls) return;

    if (!quat) quat = this.camera.quaternion.clone();
    const target = this.controls.target.clone();
    const radius = 200;

    quat.normalize();
    this.camera.setRotationFromQuaternion(quat);

    const pm = new Vector3(0, 0, radius);
    pm.applyQuaternion(quat);
    pm.add(target);
    this.camera.position.copy(pm);
    this.camera.updateProjectionMatrix();
    this.update();
  }
}

export { Camera };
