import _ from 'lodash';
import { ordered_teeth } from './functions';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

export default class TeethUtils {
  static teethSetInPalmerBySection = () => {
    return {
      upper: [].concat(Teeth.UR, Teeth.UL),
      lower: [].concat(Teeth.LR, Teeth.LL),
    };
  };

  /**
   * Converts ISO dental notation to Palmer dental notation.
   * @function
   * @param {string} tooth_id - Tooth id in ISO dental notation.
   * @returns {string} Tooth id in Palmer dental notation.
   */
  static fromISOToPalmer = (tooth_id) => {
    tooth_id = parseInt(tooth_id);
    let palmer = tooth_id;

    if (11 <= tooth_id && tooth_id <= 18) {
      palmer = `UR${tooth_id - 10}`;
    } else if (21 <= tooth_id && tooth_id <= 28) {
      palmer = `UL${tooth_id - 20}`;
    } else if (31 <= tooth_id && tooth_id <= 38) {
      palmer = `LL${tooth_id - 30}`;
    } else if (41 <= tooth_id && tooth_id <= 48) {
      palmer = `LR${tooth_id - 40}`;
    }

    return palmer;
  };

  static fromUniversalToPalmer = (tooth_id) => {
    tooth_id = parseInt(tooth_id);
    let palmer = tooth_id;

    if (1 <= tooth_id && tooth_id <= 8) {
      palmer = `UR${Math.abs(9 - tooth_id)}`;
    } else if (9 <= tooth_id && tooth_id <= 16) {
      palmer = `UL${Math.abs(tooth_id - 8)}`;
    } else if (17 <= tooth_id && tooth_id <= 24) {
      palmer = `LL${Math.abs(25 - tooth_id)}`;
    } else if (25 <= tooth_id && tooth_id <= 32) {
      palmer = `LR${Math.abs(tooth_id - 24)}`;
    }

    return palmer;
  };

  static isToothInList = (tooth_id, tooth_list) => {
    return _.intersection([tooth_id], tooth_list).length > 0;
  };

  static consecutiveMissingTooth = (startingTooth, missing_teeth) => {
    const that = this;
    let counter = 0;
    let consective = true;
    let consective_count = 0;
    let tooth = startingTooth;

    while (counter <= 16 && consective) {
      if (that.isToothInList(tooth, missing_teeth)) {
        consective_count = consective_count + 1;
      } else {
        consective = false;
      }

      tooth++;
      // safe guard inifinte loop
      counter++;
    }

    return consective_count;
  };

  static convertListFromUniversalToPalmer = (list) => {
    const that = this;

    return list.map((item, index) => {
      return that.fromUniversalToPalmer(item);
    });
  };

  static bubbleMap = (arch) => {
    const xClass = 'ipr-bubble-x-';
    const yClass = 'ipr-bubble-y-';
    const numOfBubblePos = 29;

    let mapping = [];

    for (let i = 1; i <= numOfBubblePos; i++) {
      mapping.push({
        'x-pos': `${xClass}${i}`,
        'y-pos': `${yClass}${arch === 'max' || arch === 'upper' ? 0 : 1}`,
        value: '',
      });
    }

    return mapping;
  };

  static fromUniversalToBubbleId = (tooth_id, offset) => {
    const directional_offset = 1; // coming from the bottom opposing side (in ipr_info)
    const based_formula = tooth_id >= 17 ? Math.abs(32 - tooth_id - directional_offset) : Math.abs(tooth_id - 1);

    return based_formula * 2 + offset;
  };

  static convertIPRInfoToBubblePosition = (ipr_info) => {
    const that = this;
    const missing_teeth = ipr_info.start.missing_teeth;
    const upper_iprs = Object.keys(ipr_info.start.max).length > 0 ? ipr_info.start.max : {};
    const lower_iprs = Object.keys(ipr_info.start.man).length > 0 ? ipr_info.start.man : {};

    let output = {
      upper: that.bubbleMap('upper'),
      lower: that.bubbleMap('lower'),
    };

    // upper configuration
    let bound_universal = [1, 16];
    let offset = 0;

    for (let i = bound_universal[0]; i <= bound_universal[1]; i++) {
      if (upper_iprs[`${i}`]) {
        offset = that.consecutiveMissingTooth(i, missing_teeth);
        output.upper[that.fromUniversalToBubbleId(i, offset)].value = upper_iprs[`${i}`][2];
      }
    }

    // lower configuration
    bound_universal = [32, 17];
    offset = 0;

    for (let i = bound_universal[0]; i >= bound_universal[1]; i--) {
      if (lower_iprs[`${i}`]) {
        offset = that.consecutiveMissingTooth(i, missing_teeth);
        output.lower[that.fromUniversalToBubbleId(i, offset)].value = lower_iprs[`${i}`][2];
      }
    }

    return output;
  };

  static iprInfoHasIPR = (ipr_info) => {
    return ipr_info && ipr_info.start && (Object.keys(ipr_info.start.max).length > 0 || Object.keys(ipr_info.start.man).length > 0);
  };

  /**
   * Gets formatted string for teeth/arch selection
   * @function
   * @param {string} arch - Treatment arch
   * @param {list} teeth - List of teeth
   * @param {string} opposing_arch - Opposing arch selection
   * @param {string} case_id - Case id
   * @return {string} - Formatted treatment arch string
   */
  static formatTreatmentArch = (arch, teeth, opposing_arch, case_id) => {
    const formatted_teeth = this.formatTeeth(arch, teeth);

    let opposing_arch_message = '';
    if (arch !== 'both') {
      if (case_id.includes('-DE')) {
        opposing_arch_message = opposing_arch.includes('alternate')
          ? ' / Set up both arches'
          : ` / Use current ${arch === 'upper' ? 'lower' : 'upper'} arch for Smile Design`;
      } else {
        opposing_arch_message = opposing_arch.includes('untreated')
          ? 'will be untreated'
          : opposing_arch.includes('alternate')
          ? 'will be treated with an alternate system'
          : opposing_arch.includes('digital')
          ? 'will be added in Digital Enhancement'
          : '';
        opposing_arch_message = ` / ${arch === 'upper' ? 'Lower' : 'Upper'} arch ${opposing_arch_message}`;
      }
    }

    return `${formatted_teeth}${opposing_arch_message}`;
  };

  /**
   * Gets formatted string for bracket placement
   * @function
   * @param {string} arch - Treatment arch
   * @param {list} bracket_teeth - List of teeth with brackets
   * @param {list} missing_teeth - List of missing teeth
   * @param {list} extract_teeth - List of extracted teeth
   * @param {string} separator - Separator between teeth
   * @return {string} - Bracket placement string
   */
  static formatBracketPlacement = (arch, bracket_teeth, missing_teeth, extract_teeth, separator = ' and ') => {
    const missing = missing_teeth + extract_teeth;
    bracket_teeth = bracket_teeth.filter((tooth) => !missing.includes(tooth));
    return this.formatTeeth(arch, bracket_teeth, separator);
  };

  /**
   * Gets formatted teeth selection string
   * @function
   * @param {string} arch - Treatment arch
   * @param {list} teeth - List of selected teeth
   * @param {string} separator - Separator between teeth
   * @return {string} - Teeth selection string
   */
  static formatTeeth = (arch, teeth, separator = ' and ') => {
    const getLowestIndex = (acc, curr) => Math.min(acc, ordered_teeth.indexOf(curr));
    const getHighestIndex = (acc, curr) => Math.max(acc, ordered_teeth.lastIndexOf(curr));

    const [upper_teeth, lower_teeth] = _.partition(teeth, (bt) => bt.includes('U'));

    const UR_index = upper_teeth.reduce(getLowestIndex, ordered_teeth.length);
    const UL_index = upper_teeth.reduce(getHighestIndex, -1);
    const UR_value = ordered_teeth[UR_index];
    const UL_value = ordered_teeth[UL_index];

    const LR_index = lower_teeth.reduce(getLowestIndex, ordered_teeth.length);
    const LL_index = lower_teeth.reduce(getHighestIndex, -1);
    const LR_value = ordered_teeth[LR_index];
    const LL_value = ordered_teeth[LL_index];

    const upper = arch !== 'lower' ? _.uniq([UR_value, UL_value]).filter(Boolean).join(' to ') : '';
    const lower = arch !== 'upper' ? _.uniq([LR_value, LL_value]).filter(Boolean).join(' to ') : '';

    return [upper, lower].filter(Boolean).join(separator);
  };

  /**
   * Formats the teeth value to two decimal places.
   * If the value is not a valid number, returns '0.00'.
   * @param {number|string} value - The value to be formatted.
   * @returns {string} The formatted value with the mm suffix.
   */
  static formatTeethValue = (value) => {
    const parsed = parseFloat(value);
    if (Number.isNaN(parsed)) {
      return '0.00 mm';
    }
    return parsed.toFixed(2) + ' mm';
  };
}

export const ALPHANUMERIC_FDI_MAPPING = {
  UR8: 18,
  UR7: 17,
  UR6: 16,
  UR5: 15,
  UR4: 14,
  UR3: 13,
  UR2: 12,
  UR1: 11,

  UL1: 21,
  UL2: 22,
  UL3: 23,
  UL4: 24,
  UL5: 25,
  UL6: 26,
  UL7: 27,
  UL8: 28,

  LR8: 48,
  LR7: 47,
  LR6: 46,
  LR5: 45,
  LR4: 44,
  LR3: 43,
  LR2: 42,
  LR1: 41,

  LL1: 31,
  LL2: 32,
  LL3: 33,
  LL4: 34,
  LL5: 35,
  LL6: 36,
  LL7: 37,
  LL8: 38,
};

export const ALPHANUMERIC_UNIVERSAL_MAPPING = {
  UR8: 1,
  UR7: 2,
  UR6: 3,
  UR5: 4,
  UR4: 5,
  UR3: 6,
  UR2: 7,
  UR1: 8,

  UL1: 9,
  UL2: 10,
  UL3: 11,
  UL4: 12,
  UL5: 13,
  UL6: 14,
  UL7: 15,
  UL8: 16,

  LR8: 32,
  LR7: 31,
  LR6: 30,
  LR5: 29,
  LR4: 28,
  LR3: 27,
  LR2: 26,
  LR1: 25,

  LL1: 24,
  LL2: 23,
  LL3: 22,
  LL4: 21,
  LL5: 20,
  LL6: 19,
  LL7: 18,
  LL8: 17,
};

export const UNIVERSAL_ALPHANUMERIC_MAPPING = {
  1: 'UR8',
  2: 'UR7',
  3: 'UR6',
  4: 'UR5',
  5: 'UR4',
  6: 'UR3',
  7: 'UR2',
  8: 'UR1',

  9: 'UL1',
  10: 'UL2',
  11: 'UL3',
  12: 'UL4',
  13: 'UL5',
  14: 'UL6',
  15: 'UL7',
  16: 'UL8',

  32: 'LR8',
  31: 'LR7',
  30: 'LR6',
  29: 'LR5',
  28: 'LR4',
  27: 'LR3',
  26: 'LR2',
  25: 'LR1',

  24: 'LL1',
  23: 'LL2',
  22: 'LL3',
  21: 'LL4',
  20: 'LL5',
  19: 'LL6',
  18: 'LL7',
  17: 'LL8',
};

export const UNIVERSIAL_FDI_MAPPING = {
  1: 18,
  2: 17,
  3: 16,
  4: 15,
  5: 14,
  6: 13,
  7: 12,
  8: 11,

  9: 21,
  10: 22,
  11: 23,
  12: 24,
  13: 25,
  14: 26,
  15: 27,
  16: 28,

  32: 48,
  31: 47,
  30: 46,
  29: 45,
  28: 44,
  27: 43,
  26: 42,
  25: 41,

  24: 31,
  23: 32,
  22: 33,
  21: 34,
  20: 35,
  19: 36,
  18: 37,
  17: 38,
};

export const FDI_UNIVERSAL_MAPPING = {
  18: 1,
  17: 2,
  16: 3,
  15: 4,
  14: 5,
  13: 6,
  12: 7,
  11: 8,

  21: 9,
  22: 10,
  23: 11,
  24: 12,
  25: 13,
  26: 14,
  27: 15,
  28: 16,

  48: 32,
  47: 31,
  46: 30,
  45: 29,
  44: 28,
  43: 27,
  42: 26,
  41: 25,

  31: 24,
  32: 23,
  33: 22,
  34: 21,
  35: 20,
  36: 19,
  37: 18,
  38: 17,
};
