import {
  FETCH_CASE_FILTER_PREFERENCES_PENDING,
  FETCH_CASE_FILTER_PREFERENCES_SUCCESS,
  FETCH_CASE_FILTER_PREFERENCES_ERROR,
  PUT_CASE_FILTER_PREFERENCES_PENDING,
  PUT_CASE_FILTER_PREFERENCES_SUCCESS,
  PUT_CASE_FILTER_PREFERENCES_ERROR,
  LOGIN_CASE_FILTER_PREFERENCES_SUCCESS,
  LOGOUT_CASE_FILTER_PREFERENCES_SUCCESS
} from '../actions/case_filter_preferences';

const initialState = {
  pending: false,
  case_filter_preferences: {},
  error: null
};

export function caseFilterPreferencesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CASE_FILTER_PREFERENCES_PENDING:
    case PUT_CASE_FILTER_PREFERENCES_PENDING:
      return {
        ...state,
        pending: true
      };

    case FETCH_CASE_FILTER_PREFERENCES_SUCCESS:
    case PUT_CASE_FILTER_PREFERENCES_SUCCESS:
    case LOGIN_CASE_FILTER_PREFERENCES_SUCCESS:
    case LOGOUT_CASE_FILTER_PREFERENCES_SUCCESS:
      return {
        ...state,
        pending: false,
        case_filter_preferences: action.case_filter_preferences
      };

    case FETCH_CASE_FILTER_PREFERENCES_ERROR:
    case PUT_CASE_FILTER_PREFERENCES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error
      };

    default:
      return state;
  }
}

export const getCaseFilterPreferences = state => state.caseFilterPreferencesReducer.case_filter_preferences;
export const getCaseFilterPreferencesPending = state => state.caseFilterPreferencesReducer.pending;
export const getCaseFilterPreferencesError = state => state.caseFilterPreferencesReducer.error;
