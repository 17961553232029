// External
import Axios from 'axios';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internal
import Modal from '../../../components/modal/modal';
import { handleHttpRequestError } from '../../../common/error';
import { userHasPermission } from '../../../common/permission';
import { getPDFNameTemplate, getShippingAddress, getShippingAddressName } from '../../../common/helpers';
import { isTodayPastDate } from '../../../common/date';
import {
  convertDate,
  convertDateToMilliseconds,
  isActiveAddressById,
  modalClear,
  removeCaseIdInitialNumber,
  isValidMarkShippedDate,
  isDateOnOrBeforeToday,
} from '../../../common/functions';
import { isNotBlockedOrInactive } from '../../../common/case/case_status';
import SessionExpire from '../../../common/session_expire';
import SecurityClearance from '../../../components/security/security_clearance';

// Sass
import './shipping_container.scss';

// Redux Actions
import {
  setSelectedDDMSubmittedDate,
  setSelectedDDMReceivedDate,
  setSelectedProductionReceivedDate,
  openApproveShippingModal,
  closeApproveShippingModal,
  onApproveShippingButtonClick,
  openMarkShippedModal,
  closeMarkShippedModal,
  onMarkShippedButtonClick,
  openShippingLabelModal,
  closeShippingLabelModal,
  openInternationalModal,
  closeInternationalModal,
} from '../../../redux/actions/bpp/shipping/shipping';

// Redux Reducers
import {
  getSelectedDDMSubmittedDate,
  getSelectedDDMReceivedDate,
  getSelectedProductionReceivedDate,
  getApproveShippingModal,
  getApproveShippingPending,
  getApproveShippingMessage,
  getApproveShippingCompleted,
  getMarkShippedModal,
  getSelectedShipDate,
  getMarkShippedPending,
  getRefresh,
  getShippingLabelModal,
  getError,
  getMarkShippedCases,
  getShipDateError,
  getInternationalModal,
} from '../../../redux/reducers/bpp/shipping/shipping';

/**
 * This component is used to display the shipping section in case details page
 * @alias CaseShippingContainer
 * @component
 * @category BPP
 */
class ShippingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit_ddm_submitted: false,
      edit_ddm_submitted_date: '',
      edit_ddm_submitted_date_error: false,
      disable_undo_button: false,
      edit_ddm_received: false,
      edit_production_received: false,
      edit_ddm_received_date: '',
      edit_ddm_received_date_error: false,
      edit_production_received_date: '',
      edit_production_received_date_error: false,
      showUploadTxModal: false,
      showUploadVBPCModal: false,
    };

    this.onDDMSubmissionConfirm = this.onDDMSubmissionConfirm.bind(this);
    this.onDDMReceivedConfirm = this.onDDMReceivedConfirm.bind(this);
    this.onProductionReceivedConfirm = this.onProductionReceivedConfirm.bind(this);
    this.onUploadVBPCModalClick.bind(this);
    this.onUploadVBPCModalDismiss.bind(this);
    this.onUploadTxModalClick.bind(this);
    this.onUploadTxModalDismiss.bind(this);
  }

  componentDidMount() {}

  getManufacturingUndoButton() {
    if (this.props.cases.status_code === 'STATUS_MANUFACTURE' && this.props.case_details.role === 'Admin') {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoProductionFiles} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    }
  }

  getShippingUndoButton() {
    if (this.props.cases.status_code === 'STATUS_SHIP' && this.props.case_details.role === 'Admin') {
      return (
        <span className="pull-right btn-light-link" onClick={this.onUndoShipmentFiles} disabled={this.state.disable_undo_button}>
          <i className="fa fa-undo" aria-hidden="true" />
        </span>
      );
    }
  }

  onUndoProductionFiles = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.props.selected_case_id}/reset_to_setup_end`)
      .then(function (res) {
        that.props.reload_information({
          case_details: res.data,
        });
        that.setState({
          disable_undo_button: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  onUndoShipmentFiles = (event) => {
    const that = this;

    Axios.delete(`/apiV2/caseaction/${this.props.selected_case_id}/reset_to_manufacture`)
      .then(function (res) {
        that.props.reload_information({
          case_details: res.data,
        });
        that.setState({
          disable_undo_button: false,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    that.setState({
      disable_undo_button: true,
    });
  };

  displayDDMSubmissionDate = () => {
    return (
      <div className="dark-filter">
        <div className="ddm-container">
          <span className="bold-text other-title">DDM Submitted:</span>
          {this.props.cases.shipping_approved_ind ? (
            <span>{convertDate(this.props.selected_ddm_submitted_date)}</span>
          ) : this.state.edit_ddm_submitted ? (
            <input
              disabled={this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'}
              id="edit"
              type="date"
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              max={new Date().toISOString().split('T')[0]}
              value={this.state.edit_ddm_submitted_date}
              onChange={this.onDDMSubmissionDateChange}
              onKeyPress={this.onDDMSubmissionDateKeyPress}
              data-id="ddm_submitted"
            />
          ) : this.props.getCaseStatus === 'STATUS_SHIP' || !userHasPermission('DDM_DATE_EDIT', this.props.user_roles_and_permissions.permissions) ? (
            this.props.selected_ddm_submitted_date ? (
              convertDate(this.props.selected_ddm_submitted_date)
            ) : (
              'N/A'
            )
          ) : (
            <input
              disabled={this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'}
              id="read"
              type={this.props.selected_ddm_submitted_date ? 'date' : ''}
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              placeholder={'mm / dd / yyyy'}
              value={this.props.selected_ddm_submitted_date}
              readOnly={true}
              onFocus={this.onFocusDDMSubmission}
              onDoubleClick={this.onFocusDDMSubmission}
              onClick={this.onFocusDDMSubmission}
              data-id="ddm_submitted"
            />
          )}

          {this.state.edit_ddm_submitted && (this.props.cases.status_code !== 'STATUS_HOLD' || this.props.cases.status_code !== 'STATUS_CANCEL') ? (
            <div className="btn-group-action">
              <div className="btn btn-light btn-next" onClick={this.onDDMSubmissionConfirm}>
                <i className="fa fa-check" aria-hidden="true" />
              </div>
              <div className="btn btn-light btn-next" onClick={this.onDDMSubmissionDiscard}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
          ) : null}
        </div>
        {this.state.edit_ddm_submitted_date_error ? this.getDDMSubmittedDateErrorMessage() : null}
      </div>
    );
  };

  onDDMSubmissionDateChange = (event) => {
    this.setState({
      edit_ddm_submitted_date: event.target.value,
      edit_ddm_submitted_date_error: false,
    });
  };

  onDDMSubmissionDateKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onDDMSubmissionConfirm();
    }
  };

  onDDMSubmissionConfirm() {
    const that = this;
    let data = new FormData();
    if (this.isValidDDMSubmittedDate()) {
      data.append('ddm_submitted_date', this.state.edit_ddm_submitted_date);
      const old_ddm_submitted_date = this.props.selected_ddm_submitted_date;
      that.props.setSelectedDDMSubmittedDate(that.state.edit_ddm_submitted_date);

      Axios.post(`/apiV2/caseaction/${this.props.selected_case_id}/update_ddm_submitted_date`, data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
          that.props.setSelectedDDMSubmittedDate(old_ddm_submitted_date);
        });

      this.setState({
        edit_ddm_submitted: false,
        edit_ddm_submitted_date_error: false,
      });
    } else {
      this.setState({
        edit_ddm_submitted_date_error: true,
      });
    }
  }

  /**
   * Checks to see if DDM date is valid by comparing date after setup approval but before or equal to received date
   * @function
   */
  isValidDDMSubmittedDate = () => {
    const post_setup_approval_status_date = this.props.getSelectedApprovedSetupCaseStatusDateByCaseId(this.props.case_details, this.props.selected_case_id);
    const edit_ddm_submitted_date = this.state.edit_ddm_submitted_date;
    const selected_ddm_received_date = this.props.selected_ddm_received_date;

    return (
      (convertDateToMilliseconds(edit_ddm_submitted_date) >= convertDateToMilliseconds(post_setup_approval_status_date) &&
        convertDateToMilliseconds(edit_ddm_submitted_date) <= convertDateToMilliseconds(selected_ddm_received_date)) ||
      (convertDateToMilliseconds(edit_ddm_submitted_date) >= convertDateToMilliseconds(post_setup_approval_status_date) &&
        !selected_ddm_received_date &&
        convertDateToMilliseconds(edit_ddm_submitted_date) <= convertDateToMilliseconds(new Date())) ||
      (edit_ddm_submitted_date === '' && selected_ddm_received_date === '')
    );
  };

  onDDMSubmissionDiscard = (event) => {
    this.setState({
      edit_ddm_submitted: false,
      edit_ddm_submitted_date: '',
      edit_ddm_submitted_date_error: false,
    });
    this.props.setSelectedDDMSubmittedDate(this.props.selected_ddm_submitted_date);
  };

  onFocusDDMSubmission = (event) => {
    if (this.isEditAvailable()) {
      this.setState({
        edit_ddm_submitted: true,
        edit_ddm_submitted_date: this.props.selected_ddm_submitted_date,
      });
    }
  };

  isEditAvailable = () => {
    return (
      // this.state.edit_tracking === false &&
      this.state.edit_ddm_submitted === false && this.state.edit_ddm_received === false && this.state.edit_production_received === false
    );
  };

  /**
   * Message containing error for DDM Submitted Date
   *
   * @return {JSX} Returns the error text message for DDM Submitted Date
   */
  getDDMSubmittedDateErrorMessage() {
    const { edit_ddm_submitted_date } = this.state;
    const { selected_ddm_received_date } = this.props;
    const isEmptySubmittedDate = edit_ddm_submitted_date === '';
    const ERROR_MESSAGES = {
      AFTER_SMILE_DESIGN: 'The entered date must be on or after Smile Design approval date',
      BEFORE_DDM_RECEIVED: 'The entered date must be before or on the DDM Received date',
      EMPTY_SUBMITTED_DATE: 'Please enter DDM submitted date',
      BEFORE_TODAY: 'The entered date must be on or before today',
    };
    const isDateAfterToday = convertDateToMilliseconds(edit_ddm_submitted_date) > convertDateToMilliseconds(new Date());
    const isBeforeDDmReceived = () => {
      if (edit_ddm_submitted_date === '' || selected_ddm_received_date === '') {
        return false;
      }
      return convertDateToMilliseconds(edit_ddm_submitted_date) > convertDateToMilliseconds(selected_ddm_received_date);
    };

    let errorMessage;
    if (isEmptySubmittedDate) {
      errorMessage = ERROR_MESSAGES.EMPTY_SUBMITTED_DATE;
    } else if (isBeforeDDmReceived()) {
      errorMessage = ERROR_MESSAGES.BEFORE_DDM_RECEIVED;
    } else if (isDateAfterToday) {
      errorMessage = ERROR_MESSAGES.BEFORE_TODAY;
    } else {
      errorMessage = ERROR_MESSAGES.AFTER_SMILE_DESIGN;
    }

    return <span className="text-error">{errorMessage}</span>;
  }

  /**
   * Message containing error for DDM Received Date
   *
   * @return {JSX} Returns the error text message for DDM Received Date
   */
  getDDMReceivedDateErrorMessage() {
    const { edit_ddm_received_date } = this.state;
    const { selected_production_received_date } = this.props;

    const dateInMilliseconds = convertDateToMilliseconds(edit_ddm_received_date);
    const productionDateInMilliseconds = convertDateToMilliseconds(selected_production_received_date);
    const currentDateInMilliseconds = convertDateToMilliseconds(new Date());

    let errorMessage;
    if (dateInMilliseconds > currentDateInMilliseconds) {
      errorMessage = 'The entered date must be on or before today';
    } else if (dateInMilliseconds > productionDateInMilliseconds) {
      errorMessage = 'The entered date must be on or before the Production Received date';
    } else {
      errorMessage = 'The entered date must be on or after the DDM Submitted date';
    }
    return <span className="text-error">{errorMessage}</span>;
  }

  getDDMReceivedDatePreCheckErrorMessage() {
    return <span className="text-error">Provide DDM Submitted date first</span>;
  }

  getProductionReceivedDateErrorMessage() {
    const today = new Date();
    const isDateAfterToday = convertDateToMilliseconds(this.state.edit_production_received_date) > convertDateToMilliseconds(today);
    let errorMessage;
    if (isDateAfterToday) errorMessage = 'The entered date must be on or before today';
    else errorMessage = 'The entered date must be on or after the DDM Received date';
    return <span className="text-error">{errorMessage}</span>;
  }

  getProductionReceivedDatePreCheckErrorMessage() {
    return <span className="text-error">Provide DDM Received date first</span>;
  }

  displayDDMReceivedDate = () => {
    return (
      <div className="dark-filter">
        <div className="ddm-container">
          <span id="ddm_recieved" className="bold-text other-title">
            DDM Received:
          </span>
          {this.props.cases.shipping_approved_ind ? (
            <span>{convertDate(this.props.selected_ddm_received_date)}</span>
          ) : this.state.edit_ddm_received ? (
            <input
              disabled={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL' || !this.props.selected_ddm_submitted_date
              }
              id="edit"
              type="date"
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              max={new Date().toISOString().split('T')[0]}
              value={this.state.edit_ddm_received_date}
              onChange={this.onDDMReceivedDateChange}
              onKeyPress={this.onDDMReceivedDateKeyPress}
              data-id="ddm_received"
            />
          ) : this.props.getCaseStatus === 'STATUS_SHIP' || !userHasPermission('DDM_DATE_EDIT', this.props.user_roles_and_permissions.permissions) ? (
            this.props.selected_ddm_received_date ? (
              convertDate(this.props.selected_ddm_received_date)
            ) : (
              'N/A'
            )
          ) : (
            <input
              disabled={this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'}
              id="read"
              type={this.props.selected_ddm_received_date ? 'date' : ''}
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              placeholder={'mm / dd / yyyy'}
              value={this.props.selected_ddm_received_date}
              readOnly={true}
              onFocus={this.onFocusDDMReceived}
              onDoubleClick={this.onFocusDDMReceived}
              onClick={this.onFocusDDMReceived}
              data-id="ddm_received"
            />
          )}

          {this.state.edit_ddm_received && (this.props.cases.status_code !== 'STATUS_HOLD' || this.props.cases.status_code !== 'STATUS_CANCEL') ? (
            <div className="btn-group-action">
              <div className="btn btn-light btn-next" onClick={this.onDDMReceivedConfirm}>
                <i className="fa fa-check" aria-hidden="true" />
              </div>
              <div className="btn btn-light btn-next" onClick={this.onDDMReceivedDiscard}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
          ) : null}
        </div>
        {!this.props.selected_ddm_submitted_date && this.state.edit_ddm_received ? this.getDDMReceivedDatePreCheckErrorMessage() : null}
        {this.state.edit_ddm_received_date_error ? this.getDDMReceivedDateErrorMessage() : null}
      </div>
    );
  };

  onDDMReceivedDateChange = (event) => {
    this.setState({
      edit_ddm_received_date: event.target.value,
      edit_ddm_received_date_error: false,
    });
  };

  onDDMReceivedDateKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onDDMReceivedConfirm();
    }
  };

  onDDMReceivedConfirm() {
    const that = this;
    let data = new FormData();

    if (this.isValidDDMReceivedDate()) {
      data.append('ddm_received_date', this.state.edit_ddm_received_date);

      Axios.post(`/apiV2/caseaction/${this.props.selected_case_id}/update_ddm_received_date`, data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
          });
          that.props.setSelectedDDMReceivedDate(that.state.edit_ddm_received_date);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });

      this.setState({
        edit_ddm_received: false,
        edit_ddm_received_date_error: false,
      });
      this.props.setSelectedDDMReceivedDate('');
    } else {
      this.setState({
        edit_ddm_received_date_error: true,
      });
    }
  }

  onDDMReceivedDiscard = (event) => {
    this.setState({
      edit_ddm_received: false,
      edit_ddm_received_date: '',
      edit_ddm_received_date_error: false,
    });
    this.props.setSelectedDDMReceivedDate(this.props.selected_ddm_received_date);
  };

  isValidDDMReceivedDate() {
    const edit_ddm_received_date = this.state.edit_ddm_received_date;
    const selected_ddm_submitted_date = this.props.selected_ddm_submitted_date;
    const production_received_date = this.props.selected_production_received_date;
    let result = false;

    if (
      (production_received_date === '' &&
        this.props.selected_ddm_submitted_date &&
        convertDateToMilliseconds(edit_ddm_received_date) >= convertDateToMilliseconds(selected_ddm_submitted_date) &&
        convertDateToMilliseconds(edit_ddm_received_date) <= convertDateToMilliseconds(new Date())) ||
      this.state.edit_ddm_received_date === ''
    ) {
      result = true;
    } else if (
      (production_received_date !== '' &&
        convertDateToMilliseconds(edit_ddm_received_date) <= convertDateToMilliseconds(production_received_date) &&
        this.props.selected_ddm_submitted_date &&
        convertDateToMilliseconds(edit_ddm_received_date) >= convertDateToMilliseconds(selected_ddm_submitted_date) &&
        convertDateToMilliseconds(edit_ddm_received_date) <= convertDateToMilliseconds(new Date())) ||
      this.state.edit_ddm_received_date === ''
    ) {
      result = true;
    }

    return result;
  }

  onFocusDDMReceived = (event) => {
    if (this.isEditAvailable()) {
      this.setState({
        edit_ddm_received: true,
        edit_ddm_received_date: this.props.selected_ddm_received_date,
      });
    }
  };

  displayProductionReceivedDate = () => {
    return (
      <div className="dark-filter">
        <div className="ddm-container">
          <span className="bold-text other-title">Production Received:</span>
          {this.props.cases.shipping_approved_ind ? (
            <span>{convertDate(this.props.selected_production_received_date)}</span>
          ) : this.state.edit_production_received ? (
            <input
              disabled={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL' || !this.props.selected_ddm_received_date
              }
              id="edit"
              type="date"
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              max={new Date().toISOString().split('T')[0]}
              value={this.state.edit_production_received_date}
              onChange={this.onProductionReceivedDateChange}
              onKeyPress={this.onProductionReceivedDateKeyPress}
              data-id="production_received"
            />
          ) : this.props.getCaseStatus === 'STATUS_SHIP' || !userHasPermission('DDM_DATE_EDIT', this.props.user_roles_and_permissions.permissions) ? (
            this.props.selected_production_received_date ? (
              convertDate(this.props.selected_production_received_date)
            ) : (
              'N/A'
            )
          ) : (
            <input
              disabled={this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'}
              id="read"
              type={this.props.selected_production_received_date ? 'date' : ''}
              className={
                this.props.cases.status_code === 'STATUS_HOLD' || this.props.cases.status_code === 'STATUS_CANCEL'
                  ? 'form-control business-dark-theme-input date-input'
                  : 'form-control business-dark-theme-input date-input'
              }
              placeholder={'mm / dd / yyyy'}
              value={this.props.selected_production_received_date}
              readOnly={true}
              onFocus={this.onFocusProductionReceived}
              onDoubleClick={this.onFocusProductionReceived}
              onClick={this.onFocusProductionReceived}
              data-id="production_received"
            />
          )}

          {this.state.edit_production_received && (this.props.cases.status_code !== 'STATUS_HOLD' || this.props.cases.status_code !== 'STATUS_CANCEL') ? (
            <div className="btn-group-action">
              <div className="btn btn-light btn-next" onClick={this.onProductionReceivedConfirm}>
                <i className="fa fa-check" aria-hidden="true" />
              </div>
              <div className="btn btn-light btn-next" onClick={this.onProductionReceivedDiscard}>
                <i className="fa fa-times" aria-hidden="true" />
              </div>
            </div>
          ) : null}
        </div>
        {!this.props.selected_ddm_received_date && this.state.edit_production_received ? this.getProductionReceivedDatePreCheckErrorMessage() : null}
        {this.state.edit_production_received_date_error ? this.getProductionReceivedDateErrorMessage() : null}
      </div>
    );
  };

  onProductionReceivedDiscard = (event) => {
    this.setState({
      edit_production_received: false,
      edit_production_received_date: '',
      edit_production_received_date_error: false,
    });
    this.props.setSelectedProductionReceivedDate(this.props.selected_production_received_date);
  };

  onFocusProductionReceived = (event) => {
    if (this.isEditAvailable()) {
      this.setState({
        edit_production_received: true,
        edit_production_received_date: this.props.selected_production_received_date,
      });
    }
  };

  onProductionReceivedDateChange = (event) => {
    this.setState({
      edit_production_received_date: event.target.value,
      edit_production_received_date_error: false,
    });
  };

  onProductionReceivedDateKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onProductionReceivedConfirm();
    }
  };

  onProductionReceivedConfirm() {
    const that = this;
    let data = new FormData();

    if (that.isValidProductionReceivedDate()) {
      data.append('production_received_date', this.state.edit_production_received_date);

      Axios.post(`/apiV2/caseaction/${this.props.selected_case_id}/update_production_received_date`, data)
        .then(function (res) {
          that.props.reload_information({
            case_details: res.data,
          });
          that.props.setSelectedProductionReceivedDate(that.state.edit_production_received_date);
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });

      that.setState({
        edit_production_received: false,
        edit_production_received_date_error: false,
      });
      that.props.setSelectedProductionReceivedDate('');
    } else {
      that.setState({
        edit_production_received_date_error: true,
      });
    }
  }

  isValidProductionReceivedDate() {
    const today = new Date();
    return (
      (convertDateToMilliseconds(this.state.edit_production_received_date) >= convertDateToMilliseconds(this.props.selected_ddm_received_date) &&
        this.props.selected_ddm_received_date &&
        convertDateToMilliseconds(this.state.edit_production_received_date) <= convertDateToMilliseconds(today)) ||
      this.state.edit_production_received_date === ''
    );
  }

  onUploadVBPCModalClick = (event) => {
    this.setState({ showUploadVBPCModal: true });
  };

  onUploadVBPCModalDismiss = (event) => {
    this.setState({ showUploadVBPCModal: false });
    modalClear();
  };

  onUploadTxModalClick = (event) => {
    this.setState({ showUploadTxModal: true });
  };

  onUploadTxModalDismiss = (event) => {
    this.setState({ showUploadTxModal: false });
    modalClear();
  };

  /**
   * Determines if the case is ready to be approved for shipping
   * @function
   * @return {Boolean} True or False
   */
  isReadyForShipping() {
    return (
      this.props.selected_ddm_received_date &&
      this.props.selected_ddm_submitted_date &&
      this.props.selected_production_received_date &&
      !this.state.edit_ddm_submitted &&
      !this.state.edit_ddm_received &&
      !this.state.edit_production_received &&
      !this.props.edit_address &&
      !this.props.process_loading &&
      !(!this.props.cases.gen_2 && this.props.cases.ip_process.status !== 'Outputs Approved' && this.props.cases.ip_process.status !== 'Override Process') &&
      this.props.cases.cad_process.status === 'Production Process Complete' &&
      (!this.props.cases.gen_2 || (this.props.cases.gen_2 && this.props.wire_selection_status !== 'WS Redo'))
    );
  }

  /**
   * Gets shipping label url if exists in case files
   * @function
   * @return {String} Shipping label url used for displaying the pdf in modal
   */
  getShippingLabel() {
    const shipping_files = this.props.case_files?.ship_process ?? [];
    if (shipping_files.length > 0) {
      const shipping_label_files = shipping_files.filter((file) => {
        return file.file_type === 'shipping_label';
      });

      if (shipping_label_files.length > 0) {
        return shipping_label_files[0].upload_data;
      }
    }
    return null;
  }

  /**
   * Determines if manual shipping fields are filled out for all cases in the mark shipped modal
   * @function
   * @return {Boolean} True if completed, False otherwise
   */
  isManualShippingCompleted() {
    for (const cases of this.props.mark_shipped_cases) {
      if (cases.shipping_method === 'manual_shipping' && (!cases.tracking_number || !cases.shipping_service)) {
        return false;
      }
    }
    return true;
  }

  /**
   * Gets warning text when trying to mark shipped
   * @function
   * @returns {String} Warning text
   */
  getWarningText() {
    const mark_shipped_date = this.props.selected_mark_shipped_date;
    if (!isValidMarkShippedDate(this.props.mark_shipped_cases, mark_shipped_date)) {
      if (!isDateOnOrBeforeToday(mark_shipped_date)) {
        return "The entered date should be on or before today's date";
      } else {
        return 'The entered date must be on or after the production received date';
      }
    }
    return '';
  }

  /**
   * Displays target ship date
   * @function
   * @returns {JSX} JSX for target ship date
   */
  displayTargetShipDate = () => {
    return (
      <div className="target-date pull-right">
        <span>Target Ship Date: </span>
        <span>{this.props.cases.internal_target_ship_date ? convertDate(this.props.cases.internal_target_ship_date) : 'N/A'}</span>
      </div>
    );
  };

  showShippingModal = () => {
    const country = this.props?.cases?.shipping_address?.country;
    if (!country) return false;
    if (country !== 'United States') {
      this.props.openInternationalModal();
    } else {
      this.props.openApproveShippingModal();
    }
  };

  render() {
    return (
      <SecurityClearance mode="ALL" permission_list={['CASE_DETAILS_VIEW']}>
        <div id="shipping-area">
          <div className="bold-text business-dark-heading">
            Shipping Details
            {isNotBlockedOrInactive(this.props.cases.status_code) &&
              this.props.cases.internal_target_ship_date &&
              isTodayPastDate(this.props.cases.internal_target_ship_date) && (
                <i
                  className="fa fa-flag target-date-flag"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="right"
                  title={`Missed Target Ship Date: ${convertDate(this.props.cases.internal_target_ship_date)}`}
                />
              )}
            {this.getManufacturingUndoButton()}
            {this.getShippingUndoButton()}
            {isNotBlockedOrInactive(this.props.cases.status_code) && this.displayTargetShipDate()}
          </div>

          <div className="business-dark-theme">
            <div className="row business-dark-theme-content">
              <div className="col-lg-12">
                <div className="business-dark-group">
                  {this.displayDDMSubmissionDate()}
                  {this.displayDDMReceivedDate()}
                  {this.displayProductionReceivedDate()}
                  <div className="top-seperator" />
                  <div className="dark-filter pull-left shipping-display">
                    <div className="shipping-container">
                      <span className="bold-text ship-title">Preferred Shipping:</span>
                      <span className="expedite-shipping-label">{this.props.cases.preferred_shipping ? this.props.cases.preferred_shipping : 'N/A'}</span>
                    </div>
                  </div>
                  <div className="dark-filter pull-left shipping-display">
                    <div className="shipping-container">
                      <span className="bold-text ship-title">Expedited Shipping:</span>
                      <span className="expedite-shipping-label">{this.props.cases.expedite_ship_ind ? 'Yes' : 'No'}</span>
                    </div>
                  </div>
                  <div className="dark-filter pull-left">
                    <div className="shipping-container">
                      <span
                        className={
                          this.props.cases.status_code !== 'STATUS_SHIP' && !this.props.cases.shipping_approved_ind
                            ? 'bold-text ship-title'
                            : 'bold-text shipped-title'
                        }
                      >
                        Shipping Address:
                      </span>

                      <select
                        className="select-address"
                        data-width="392px"
                        data-divider="true"
                        data-dropup-auto="true"
                        data-size="5"
                        data-border-radius="10px"
                        data-style="select-address"
                        value={this.props.edit_address ? this.props.edit_selected_address_id : this.props.selected_address_id}
                        onChange={this.props.onSelectedAddress}
                        disabled={
                          this.props.cases.shipping_approved_ind ||
                          this.props.cases.status_code === 'STATUS_SHIP' ||
                          this.props.cases.status_code === 'STATUS_HOLD' ||
                          this.props.cases.status_code === 'STATUS_CANCEL' ||
                          !userHasPermission('CASE_SHIPPING_DETAILS_UPDATE', this.props.user_roles_and_permissions.permissions)
                            ? true
                            : false
                        }
                      >
                        {!isActiveAddressById(this.props.case_details.shipping_addresses, this.props.selected_address_id) ? (
                          <option
                            data-content={'<strong> No Active Address Selected </strong><br/> --------------------------------------------------------'}
                            value={this.props.selected_address_id}
                          >
                            --------------------------------------------------------
                          </option>
                        ) : null}

                        {this.props.case_details.shipping_addresses
                          .filter(function (a) {
                            return a.is_active;
                          })
                          .map((address, address_index) => {
                            return (
                              <option
                                data-content={'<strong>' + getShippingAddressName(address) + '</strong><br>' + getShippingAddress(address)}
                                key={address_index}
                                value={address.id}
                              >
                                {getShippingAddress(address)}
                              </option>
                            );
                          })}
                      </select>

                      {this.props.cases.status_code === 'STATUS_SHIP' || this.props.cases.shipping_approved_ind ? (
                        <div className="ship-address-label">
                          <div>{getShippingAddressName(this.props.cases.delivered_address)}</div>
                          <div>{getShippingAddress(this.props.cases.delivered_address)}</div>
                        </div>
                      ) : null}

                      {this.props.edit_address ? (
                        <div className="btn-group-action">
                          <div className="btn btn-light btn-next" onClick={this.props.onAddressConfirm}>
                            <i className="fa fa-check" aria-hidden="true" />
                          </div>
                          <div className="btn btn-light btn-next" onClick={this.props.onAddressDiscard}>
                            <i className="fa fa-times" aria-hidden="true" />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div>
                  {this.props.cases.status_code === 'STATUS_SHIP' && this.props.cases.hand_delivery_ind ? (
                    <div className="pull-left">
                      <div className="dark-filter">
                        <div className="tracking-container">
                          <span className="bold-text other-title">Delivery Method:</span>
                          <span>Hand Delivery</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="pull-left">
                        <div className="dark-filter">
                          <div className="tracking-container">
                            <span className="bold-text other-title">Tracking Number:</span>
                            <span>
                              {this.props.cases.ship_process.tracking_number ? (
                                !this.props.cases.tracking_url ? (
                                  <span>{this.props.cases.ship_process.tracking_number}</span>
                                ) : (
                                  <a
                                    href={'http://' + this.props.cases.tracking_url.replace('http://', '').replace('https://', '')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {this.props.cases.ship_process.tracking_number}
                                  </a>
                                )
                              ) : (
                                <span>N/A</span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="pull-left">
                        <div className="dark-filter">
                          <div className="tracking-container">
                            <span className="bold-text other-title">Shipping Label:</span>
                            {this.getShippingLabel() ? (
                              <span className="underline-text viewable-text" onClick={this.props.openShippingLabelModal}>
                                Shipping Label <i className="fa fa-file-pdf-o" aria-hidden="true" />
                              </span>
                            ) : (
                              <span>N/A</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {this.props.cases.status_code === 'STATUS_SHIP' ? (
                    <>
                      <div className="pull-left dark-filter margin-bottom-10">
                        <div className="tracking-container">
                          <span className="bold-text other-title">Shipped Date:</span>
                          <div className="dark-filter">
                            <div className="ship-date-container">{convertDate(this.props.cases.shipping_date)}</div>
                          </div>
                        </div>
                      </div>
                      <div className="dark-filter pull-left">
                        <div className="shipping-container">
                          <span className="bold-text other-title">Delivered Date: </span>
                          <span>{this.props.cases.delivered_date ? convertDate(this.props.cases.delivered_date) : 'N/A'}</span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {this.props.cases.status_code !== 'STATUS_SHIP' &&
                ((this.props.selected_tx && this.props.selected_tx.length > 0) ||
                  (this.props.selected_tx &&
                    this.props.selected_tx.length === 0 &&
                    this.props.cases.status_code !== 'STATUS_HOLD' &&
                    this.props.cases.status_code !== 'STATUS_CANCEL') ||
                  (this.props.selected_vbpc &&
                    this.props.selected_vbpc.length === 0 &&
                    this.props.cases.status_code !== 'STATUS_HOLD' &&
                    this.props.cases.status_code !== 'STATUS_CANCEL') ||
                  (this.props.selected_vbpc && this.props.selected_vbpc.length > 0)) ? (
                  <div className="pull-left">
                    <div className="dark-filler">
                      <div className="upload-container">
                        <span className="bold-text other-title">
                          {' '}
                          {this.props.ifu_state &&
                          !this.props.ifu_state.ifu_status &&
                          !this.props.ifu_state.txinstruction_status &&
                          !this.props.ifu_state.bpg_status &&
                          this.props.cases.status_code !== 'STATUS_SHIP' &&
                          ((this.props.selected_tx && this.props.selected_tx.length > 0) ||
                            (this.props.selected_tx &&
                              this.props.selected_tx.length === 0 &&
                              this.props.cases.status_code !== 'STATUS_SHIP' &&
                              this.props.cases.status_code !== 'STATUS_HOLD' &&
                              this.props.cases.status_code !== 'STATUS_CANCEL') ||
                            (this.props.selected_vbpc &&
                              this.props.selected_vbpc.length === 0 &&
                              this.props.cases.status_code !== 'STATUS_SHIP' &&
                              this.props.cases.status_code !== 'STATUS_HOLD' &&
                              this.props.cases.status_code !== 'STATUS_CANCEL') ||
                            (this.props.selected_vbpc &&
                              this.props.selected_vbpc.length > 0 &&
                              (userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions) ||
                                userHasPermission('CASE_SHIPPING_DETAILS_UPDATE', this.props.user_roles_and_permissions.permissions))))
                            ? 'Uploads:'
                            : null}
                        </span>
                        {this.props.ifu_state &&
                        !this.props.ifu_state.ifu_status &&
                        !this.props.ifu_state.txinstruction_status &&
                        !this.props.ifu_state.bpg_status &&
                        this.props.cases.status_code !== 'STATUS_SHIP' ? (
                          <React.Fragment>
                            {!this.props.ifu_state.txinstruction_status &&
                            !this.props.ifu_state.txinstruction_file &&
                            this.props.cases.status_code !== 'STATUS_SHIP' &&
                            this.props.cases.status_code !== 'STATUS_HOLD' &&
                            this.props.cases.status_code !== 'STATUS_CANCEL' &&
                            userHasPermission('CASE_SHIPPING_DETAILS_UPDATE', this.props.user_roles_and_permissions.permissions) ? (
                              <button type="button" className="btn btn-bpp-upload btn-shipped btn-upload" onClick={this.onUploadTxModalClick}>
                                Upload Tx Instructions
                              </button>
                            ) : this.props.selected_tx &&
                              this.props.selected_tx.length > 0 &&
                              this.props.cases.status_code !== 'STATUS_SHIP' &&
                              userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions) ? (
                              <div className="underline-text viewable-text view-upload" onClick={this.onUploadTxModalClick}>
                                View Tx Instructions <i className="fa fa-file-pdf-o" aria-hidden="true" />
                              </div>
                            ) : null}
                            {!this.props.ifu_state.bpg_status &&
                            !this.props.ifu_state.bpg_file &&
                            this.props.cases.status_code !== 'STATUS_SHIP' &&
                            this.props.cases.status_code !== 'STATUS_HOLD' &&
                            this.props.cases.status_code !== 'STATUS_CANCEL' &&
                            userHasPermission('CASE_SHIPPING_DETAILS_UPDATE', this.props.user_roles_and_permissions.permissions) ? (
                              <div className="vbpc-section">
                                <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadVBPCModalClick}>
                                  Upload VBPC
                                </button>
                              </div>
                            ) : this.props.selected_vbpc &&
                              this.props.selected_vbpc.length > 0 &&
                              this.props.cases.status_code !== 'STATUS_SHIP' &&
                              userHasPermission('CASE_DETAILS_VIEW', this.props.user_roles_and_permissions.permissions) ? (
                              <div className="underline-text viewable-text vbpc-section" onClick={this.onUploadVBPCModalClick}>
                                View VBPC <i className="fa fa-file-pdf-o" aria-hidden="true" />
                              </div>
                            ) : null}
                          </React.Fragment>
                        ) : null}
                        {this.props.selected_vbpc &&
                        this.props.selected_vbpc.length === 0 &&
                        this.props.selected_tx &&
                        this.props.selected_tx.length === 0 &&
                        this.props.cases.status_code !== 'STATUS_SHIP' &&
                        this.props.cases.status_code !== 'STATUS_HOLD' &&
                        this.props.cases.status_code !== 'STATUS_CANCEL' &&
                        !userHasPermission('CASE_SHIPPING_DETAILS_UPDATE', this.props.user_roles_and_permissions.permissions)
                          ? 'N/A'
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.props.cases.status_code !== 'STATUS_SHIP' &&
                this.props.cases.status_code !== 'STATUS_HOLD' &&
                this.props.cases.status_code !== 'STATUS_CANCEL' ? (
                  <div>
                    <div className="pull-left">
                      <div className="tracking-container">
                        <span className="bold-text other-title" />
                        {this.props.cases.status_code !== 'STATUS_SHIP' &&
                        this.props.cases.status_code !== 'STATUS_HOLD' &&
                        this.props.cases.status_code !== 'STATUS_CANCEL' ? (
                          !this.props.cases.shipping_approved_ind ? (
                            userHasPermission('CASE_SHIPPING_APPROVAL', this.props.user_roles_and_permissions.permissions) ? (
                              <React.Fragment>
                                <div className="btn-shipped view-upload empty-mark-ship-section" />
                                <button
                                  type="button"
                                  className="btn btn-light btn-shipped-2"
                                  disabled={!this.isReadyForShipping()}
                                  onClick={this.showShippingModal}
                                >
                                  Approve for Shipping
                                </button>
                              </React.Fragment>
                            ) : (
                              <span className="approval-waiting-message bold-text">Awaiting Shipping Approval...</span>
                            )
                          ) : userHasPermission('CASE_SHIPPING_RELEASE', this.props.user_roles_and_permissions.permissions) ? (
                            <React.Fragment>
                              <div className="btn-shipped view-upload empty-mark-ship-section" />
                              <button
                                type="button"
                                className="btn btn-light btn-shipped-2"
                                disabled={!this.isReadyForShipping()}
                                onClick={() => this.props.openMarkShippedModal(this.props.selected_case_id, this.props.case_details)}
                              >
                                Mark Shipped
                              </button>
                            </React.Fragment>
                          ) : (
                            <span className="approval-waiting-message bold-text">Shipping Process in Progress...</span>
                          )
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div>
          {this.state.showUploadVBPCModal ? (
            <Modal
              preset="upload-pdf"
              header_text={(this.props.getCaseStatus !== 'Case Shipped' && this.props.getCaseStatus !== 'STATUS_SHIP' ? 'Upload ' : '') + 'VBPC'}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              remove_btn={this.props.selected_vbpc.length > 0 && this.props.getCaseStatus !== 'Case Shipped' && this.props.getCaseStatus !== 'STATUS_SHIP'}
              remove_btn_text={'Remove File'}
              case_id={this.props.selected_case_id}
              folder="bpg"
              type="additional"
              onUpload={this.props.onUpload}
              upload_content={this.props.selected_vbpc}
              onCloseButtonClick={this.onUploadVBPCModalDismiss}
              onRemoveButtonClick={this.props.onRemove}
              theme="bpp"
            />
          ) : null}
          {this.state.showUploadTxModal ? (
            <Modal
              preset="upload-pdf"
              header_text={(this.props.getCaseStatus !== 'Case Shipped' && this.props.getCaseStatus !== 'STATUS_SHIP' ? 'Upload ' : '') + 'Tx Instructions'}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              remove_btn={this.props.selected_tx.length > 0 && this.props.getCaseStatus !== 'Case Shipped' && this.props.getCaseStatus !== 'STATUS_SHIP'}
              remove_btn_text={'Remove File'}
              case_id={this.props.selected_case_id}
              folder="tx"
              type="additional"
              onUpload={this.props.onUpload}
              upload_content={this.props.selected_tx}
              onCloseButtonClick={this.onUploadTxModalDismiss}
              onRemoveButtonClick={this.props.onRemove}
              theme="bpp"
            />
          ) : null}

          {this.props.approve_shipping_modal ? (
            <Modal
              preset="approve-shipping"
              header_text={'Approve for Shipping - ' + removeCaseIdInitialNumber(this.props.selected_case_id)}
              message_text={this.props.approve_shipping_message}
              confirm_btn={!this.props.approve_shipping_completed}
              confirm_btn_text="Approve"
              close_btn_text={this.props.approve_shipping_completed ? 'Close' : 'Cancel'}
              onCloseButtonClick={this.props.closeApproveShippingModal}
              onConfirmButtonClick={() => this.props.onApproveShippingButtonClick(this.props.selected_case_id, this.props.reload_information)}
              theme="bpp"
              in_progress={this.props.approve_shipping_pending}
              in_progress_text="Sending data to Shippo..."
            />
          ) : null}

          {this.props.international_modal ? (
            <Modal
              preset="international-shiping"
              modal_body_class="overflow-show center-text"
              header_text={`Case: ${removeCaseIdInitialNumber(this.props.selected_case_id)}`}
              modal_body="For international orders please ship through Netsuite."
              confirm_btn_text="Ship"
              close_btn_text={'Close'}
              close_btn={true}
              onCloseButtonClick={() => this.props.closeInternationalModal()}
              theme="bpp"
            />
          ) : null}

          {this.props.mark_shipped_modal ? (
            <Modal
              preset="mark-shipped"
              modal_size_class="modal-lg"
              modal_body_class="overflow-show center-text"
              header_text={'Mark Shipped - ' + removeCaseIdInitialNumber(this.props.selected_case_id)}
              confirm_btn={!this.props.mark_shipped_error}
              confirm_btn_text="Ship"
              close_btn_text={this.props.mark_shipped_error ? 'Close' : 'Cancel'}
              close_btn={true}
              onCloseButtonClick={() => this.props.closeMarkShippedModal('single')}
              onConfirmButtonClick={() =>
                this.props.onMarkShippedButtonClick(this.props.selected_case_id, this.props.reload_information, this.props.selected_address_id)
              }
              theme="bpp"
              in_progress={this.props.mark_shipped_pending}
              in_progress_text="Marking Case as Shipped..."
              datearea_warning_text={this.getWarningText()}
              ship_date_error={this.props.ship_date_error}
              confirm_btn_disabled={!this.isManualShippingCompleted()}
            />
          ) : null}
          {this.props.shipping_label_modal ? (
            <Modal
              preset="pdf_viewer"
              header_text={'Shipping Label - ' + removeCaseIdInitialNumber(this.props.selected_case_id)}
              modal_size_class="modal-lg modal-lg-pdf"
              modal_body_class="modal-pdf"
              onCloseButtonClick={this.props.closeShippingLabelModal}
              theme="bpp"
              pdf_url={this.getShippingLabel()}
              original_filename={getPDFNameTemplate(this.props.selected_case_id, this.props.patient_info, 'Label_Shipping.pdf')}
            />
          ) : null}

          {this.props.refresh ? <SessionExpire /> : null}
        </div>
      </SecurityClearance>
    );
  }
}

ShippingContainer.propTypes = {
  selected_ddm_submitted_date: PropTypes.string.isRequired,
  selected_ddm_received_date: PropTypes.string.isRequired,
  selected_production_received_date: PropTypes.string.isRequired,
  approve_shipping_modal: PropTypes.bool.isRequired,
  approve_shipping_pending: PropTypes.bool.isRequired,
  approve_shipping_message: PropTypes.object.isRequired,
  approve_shipping_completed: PropTypes.bool.isRequired,
  mark_shipped_modal: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  shipping_label_modal: PropTypes.bool.isRequired,
  mark_shipped_error: PropTypes.bool.isRequired,
  mark_shipped_cases: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selected_ddm_submitted_date: getSelectedDDMSubmittedDate(state),
    selected_ddm_received_date: getSelectedDDMReceivedDate(state),
    selected_production_received_date: getSelectedProductionReceivedDate(state),
    international_modal: getInternationalModal(state),
    approve_shipping_modal: getApproveShippingModal(state),
    approve_shipping_pending: getApproveShippingPending(state),
    approve_shipping_message: getApproveShippingMessage(state),
    approve_shipping_completed: getApproveShippingCompleted(state),
    mark_shipped_modal: getMarkShippedModal(state),
    selected_mark_shipped_date: getSelectedShipDate(state),
    mark_shipped_pending: getMarkShippedPending(state),
    refresh: getRefresh(state),
    shipping_label_modal: getShippingLabelModal(state),
    mark_shipped_error: getError(state),
    mark_shipped_cases: getMarkShippedCases(state),
    ship_date_error: getShipDateError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedDDMSubmittedDate: setSelectedDDMSubmittedDate,
      setSelectedDDMReceivedDate: setSelectedDDMReceivedDate,
      setSelectedProductionReceivedDate: setSelectedProductionReceivedDate,
      openApproveShippingModal: openApproveShippingModal,
      closeApproveShippingModal: closeApproveShippingModal,
      onApproveShippingButtonClick: onApproveShippingButtonClick,
      openMarkShippedModal: openMarkShippedModal,
      closeMarkShippedModal: closeMarkShippedModal,
      onMarkShippedButtonClick: onMarkShippedButtonClick,
      openShippingLabelModal: openShippingLabelModal,
      closeShippingLabelModal: closeShippingLabelModal,
      openInternationalModal: openInternationalModal,
      closeInternationalModal: closeInternationalModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShippingContainer);
