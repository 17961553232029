const CASEID_COL = {
  className: 'caseid-width',
  title: 'Case ID',
  category: 'case_id',
};
const SUBMISSION_DATE_COL = {
  className: 'submission-width',
  title: 'Submission',
  category: 'submission_date',
};
const DOCTOR_COL = {
  className: 'name-width',
  title: 'Doctor',
  category: 'doctor_name',
};
const PATIENT_COL = {
  className: 'patient-width',
  title: 'Patient',
  category: 'patient_name',
};
const STATUS_COL = {
  className: 'name-width',
  title: 'Status',
  category: 'status',
};

export const defaultTableHeader = [CASEID_COL, SUBMISSION_DATE_COL, DOCTOR_COL, PATIENT_COL, STATUS_COL];
export const smileDesignTableHeader = [
  CASEID_COL,
  SUBMISSION_DATE_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'autosize-width',
    title: 'Assigned To',
    category: 'assigned_to',
  },
  {
    className: 'target-date-width',
    title: 'Smile Design Target Date',
    category: 'smile_design_target_date',
  },
];
export const PostApprovalTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'autosize-width',
    title: 'Assigned To',
    category: 'assigned_to',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'target-date-width',
    title: 'Post Approval Target Date',
    category: 'post_approval_target_date',
  },
];

export const ProviderEditReviewHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'target-date-width',
    title: 'Assigned To',
    category: 'assigned_to',
  },
  {
    className: 'target-date-width',
    title: 'Post Approval Target Date',
    category: 'post_approval_target_date',
  },
];

export const applianceDesignTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  {
    className: 'manufacturing-name-width',
    title: 'Patient',
    category: 'patient_name',
  },
  STATUS_COL,
  {
    className: 'manufacturing-assigned-to-width',
    title: 'Assigned To',
    category: 'assigned_to',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'target-date-width',
    title: 'Appliance Design Target Date',
    category: 'appliance_design_target_date',
  },
];
export const qcApprovalTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  {
    className: 'manufacturing-name-width',
    title: 'Patient',
    category: 'patient_name',
  },
  {
    className: 'status-qc',
    title: 'Status',
    category: 'status',
  },
  {
    className: 'autosize-width',
    title: 'Assigned To',
    category: 'assigned_to',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];
export const fabricationTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  {
    className: 'manufacturing-name-width',
    title: 'Patient',
    category: 'patient_name',
  },
  STATUS_COL,
  {
    className: 'wo-width',
    title: 'WO',
    category: 'production_wo',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const wsHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const wsReviewHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'manufacturing-assigned-to-width',
    title: 'Assigned To',
    category: 'ws_assigned_to',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const wsWoHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'wo-width',
    title: 'WO',
    category: 'custom_wire_wo',
  },
  {
    className: 'manufacturing-assigned-to-width',
    title: 'Assigned To',
    category: 'ws_assigned_to',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const wireCuttingHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const wfReviewHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const labelsHeader = [
  CASEID_COL,
  DOCTOR_COL,
  PATIENT_COL,
  STATUS_COL,
  {
    className: 'wo-width',
    title: 'WO',
    category: 'production_wo',
  },
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];

export const doctorActionTableHeader = [
  ...defaultTableHeader,
  {
    className: 'approval-width',
    title: 'Days Past',
    category: 'doctor_action_date',
  },
];
export const doctorNewRecordTableHeader = [
  ...defaultTableHeader,
  {
    className: 'approval-width',
    title: 'Days Past',
    category: 'doctor_new_date',
  },
];
export const doctorApprovalTableHeader = [
  ...defaultTableHeader,
  {
    className: 'approval-width',
    title: 'Days Past',
    category: 'doctor_setup_date',
  },
];
export const expediteTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  {
    className: 'manufacturing-name-width',
    title: 'Patient',
    category: 'patient_name',
  },
  STATUS_COL,
  {
    className: 'submission-width',
    title: 'Dr. Approval',
    category: 'approval_date',
  },
  {
    className: 'manufacturing-submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];
export const lateTableHeader = [
  CASEID_COL,
  DOCTOR_COL,
  STATUS_COL,
  {
    className: 'target-date-width',
    title: 'Smile Design<br>Target Date',
    category: 'smile_design_target_date',
  },
  {
    className: 'target-date-width',
    title: 'Post Approval<br>Target Date',
    category: 'post_approval_target_date',
  },
  {
    className: 'target-date-width',
    title: 'Appliance Design<br>Target Date',
    category: 'appliance_design_target_date',
  },
  {
    className: 'submission-width',
    title: 'Target Ship Date',
    category: 'target_ship_date',
  },
];
