import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Redux
import { onParentCheckboxClick, onChildCheckboxClick, onInnerChildCheckboxClick } from '../../redux/actions/case_filter_preferences_modal';
import {
  getCaseFilterPreferencesEditable,
  getCaseFilterPreferencesOptions,
  getCaseFilterPreferencesDisabled,
} from '../../redux/reducers/case_filter_preferences_modal';
import { new_branding_words } from '../../common/nomenclature';

class CaseFilterPreferences extends Component {
  /**
   * Displays a single column of child filter preferences
   * @function
   * @param {number} start - Start index for the column
   * @param {number} end - End index for the column
   * @param {string} key - Parent filter preference
   * @param {string} key_name - Parent filter preference name
   * @param {object} value - Children preferences object
   * @return {JSX} JSX for child filter preferences column
   */
  displayChildPreferences = (start, end, key, key_name, value) => {
    return (
      <div className="col-lg-6">
        {Object.entries(value).map(([inner_key, inner_value], inner_index) => {
          const inner_key_disabled = _.intersection([inner_key], this.props.case_filter_preferences_disabled).length > 0;
          const inner_key_name = inner_key.split(' ').join('_').toLowerCase();
          if (inner_index >= start && inner_index <= end) {
            return (
              <div key={inner_key_name} className="filter-child">
                <input
                  type="checkbox"
                  className="pointer"
                  id={inner_key_name}
                  name={key_name}
                  disabled={inner_key_disabled}
                  checked={this.props.case_filter_preferences_editable[key] && this.props.case_filter_preferences_editable[key][inner_key] ? true : false}
                  onChange={() => this.props.onChildCheckboxClick(key, inner_key)}
                />
                <label
                  className="filter-offset"
                  onClick={() => {
                    if (inner_key_disabled) {
                      return;
                    }

                    this.props.onChildCheckboxClick(key, inner_key);
                  }}
                >
                  {new_branding_words(inner_key)}
                </label>
                {inner_value.map((inner_v, inner_v_index) => {
                  const inner_value_name = inner_v.split(' ').join('_').toLowerCase();
                  const inner_value_disabled = _.intersection([inner_v], this.props.case_filter_preferences_disabled).length > 0;

                  return (
                    <div key={inner_v_index} className="inner-filter-child">
                      <input
                        type="checkbox"
                        className="pointer"
                        id={inner_value_name}
                        name={key_name}
                        disable={inner_value_disabled ? inner_value_disabled : undefined}
                        checked={
                          this.props.case_filter_preferences_editable[key] &&
                          this.props.case_filter_preferences_editable[key][inner_key] &&
                          this.props.case_filter_preferences_editable[key][inner_key].length > 0 &&
                          _.intersection([inner_v], this.props.case_filter_preferences_editable[key][inner_key]).length > 0
                            ? true
                            : false
                        }
                        onChange={() => this.props.onInnerChildCheckboxClick(key, inner_key, inner_v)}
                      />
                      <label
                        className="filter-offset"
                        onClick={() => {
                          if (inner_value_disabled) {
                            return;
                          }

                          this.props.onInnerChildCheckboxClick(key, inner_key, inner_v);
                        }}
                      >
                        {new_branding_words(inner_v)}
                      </label>
                    </div>
                  );
                })}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };
  generateFilterSettingItems = (start, end) => {
    return Object.entries(this.props.case_filter_preferences_options).map(([key, value], index) => {
      if (index >= start && index <= end) {
        const key_disabled = _.intersection([key], this.props.case_filter_preferences_disabled).length > 0;
        const key_name = key.split(' ').join('_').toLowerCase();
        const children_first_col_index = Object.entries(value).length <= 3 ? 1 : 12;

        return (
          <div key={key_name} className="filter-parent">
            <input
              type="checkbox"
              className="pointer"
              id={key_name}
              name={key_name}
              disabled={key_disabled}
              checked={this.props.case_filter_preferences_editable[key] ? true : false}
              onChange={() => this.props.onParentCheckboxClick(key)}
            />
            <label
              className={key_disabled ? 'filter-offset filter-offset-disabled' : 'filter-offset'}
              onClick={() => {
                if (key_disabled) {
                  return;
                }

                this.props.onParentCheckboxClick(key);
              }}
            >
              {new_branding_words(key)}
            </label>
            <div className="row">
              {this.displayChildPreferences(0, children_first_col_index, key, key_name, value)}
              {this.displayChildPreferences(children_first_col_index + 1, Object.entries(value).length - 1, key, key_name, value)}
            </div>
          </div>
        );
      }

      return null;
    });
  };

  render() {
    return (
      <div>
        <div className="filter-setting-heading text-center">
          Select filters you would like to hide or display in the side navigation. Click <span className="bold-text">Apply</span> to save your selection
        </div>
        <div className="filter-container">
          <div className="filter-col">{this.generateFilterSettingItems(0, 2)}</div>
          <div className="filter-col">{this.generateFilterSettingItems(3, Object.keys(this.props.case_filter_preferences_options).length + 1)}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    case_filter_preferences_editable: getCaseFilterPreferencesEditable(state),
    case_filter_preferences_options: getCaseFilterPreferencesOptions(state),
    case_filter_preferences_disabled: getCaseFilterPreferencesDisabled(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onParentCheckboxClick: onParentCheckboxClick,
      onChildCheckboxClick: onChildCheckboxClick,
      onInnerChildCheckboxClick: onInnerChildCheckboxClick,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CaseFilterPreferences);
