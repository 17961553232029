import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CircleLoader from '../../components/loader/circle_loader';
import { withRouter } from 'react-router-dom';
import { convertDate } from '../../common/functions';
import { isToday, isYesterday } from './dashboard_helpers';

function getDetail(item) {
  let result = item.detail;
  if (!item.detail) return null;
  if (item.detail_url) {
    result = (
      <a className="underlined" href={item.detail_url} target="_blank" rel="noopener noreferrer">
        {item.detail}
      </a>
    );
  }

  return <span> - {result}</span>;
}

function formatDate(date_str) {
  if (!date_str) return null;

  const date = new Date(date_str);
  if (isToday(date)) {
    return 'Today';
  } else if (isYesterday(date)) {
    return 'Yesterday';
  } else {
    return convertDate(date_str);
  }
}

function DashboardList(props) {
  const { items, hasMore, onReachBottom, loading, history, emptyMessage } = props;
  const bottomRef = useRef(null);

  const scrollObserver = useCallback(
    (node) => {
      const obs = new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            onReachBottom();
            obs.unobserve(node);
          }
        });
      });
      obs.observe(node);
    },
    [onReachBottom]
  );

  useEffect(() => {
    if (bottomRef.current) {
      scrollObserver(bottomRef.current);
    }
  }, [scrollObserver, bottomRef]);

  const onClick = useCallback(
    (event, url) => {
      if (event.target.innerText === 'Tracking Info' || event.target.innerText === 'View Smile') return;

      if (event.button === 1 || (event.button === 0 && event.ctrlKey)) {
        window.open(url);
      } else if (event.button === 0) {
        history.push(url);
      }
    },
    [history]
  );

  if (loading) {
    return <CircleLoader />;
  }
  return (
    <ul className="dashboard-list scrollbar--dark dashboard-activity">
      {items.map((item, index) => (
        <li key={index} className="dashboard-list__item" onMouseUp={(e) => onClick(e, item.url)}>
          <div className="dashboard-list__item__status">
            <span>
              {item.title}
              {getDetail(item)}
            </span>
            <span className="dashboard-list__item__date">{formatDate(item.date)}</span>
          </div>
          <div className="dashboard-list__item__info fs-exclude">{item.description}</div>
        </li>
      ))}
      {hasMore && (
        <li className="dashboard-list__item" ref={bottomRef}>
          <CircleLoader />
        </li>
      )}
      {items.length === 0 && <li className="dashboard-list__message">{emptyMessage}</li>}
    </ul>
  );
}

DashboardList.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onReachBottom: PropTypes.func.isRequired,
};

export default withRouter(DashboardList);
