import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a slider control with a fifty percent bar
 * @component
 */
function ViewerOpacityController(props) {
  return (
    <input
      className="records-zoom-controller vertical-controller fifty-percent-bar"
      data-toggle={props.name}
      data-placement="right"
      data-animation="false"
      type="range"
      min="0"
      max="100"
      orient="vertical"
      data-original-title={props.title}
      value={props.value}
      onChange={props.onTeethOpacityChange}
      onMouseLeave={props.tooltipCleanUp}
      onMouseEnter={props.showTooltip}
      disabled={props.disabled}
    />
  );
}

ViewerOpacityController.propTypes = {
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onTeethOpacityChange: PropTypes.func.isRequired,
  showTooltip: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltipCleanUp: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default ViewerOpacityController;
