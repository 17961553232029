/**
 * File:reason.js - Component to input item request submission reasons on BPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';
import TeethSelector from '../../common/teeth_selector';

class ItemReason extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleTeethClick.bind(this);
  }

  handleTeethClick = (request_reason) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(request_reason.selectedTeeth);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      request_reason.selectedTeeth = Array.from(sel);
      this.props.onhandleTeethClick(request_reason);
    };
    return func;
  };
  setSegmentPreference = (request_reason, segment_preference, segment_preference_text) => {
    request_reason.segment_preference = segment_preference;
    request_reason.segment_preference_text = segment_preference_text;
    this.props.setSegmentPreference(request_reason);
  };
  setArchSegmentOption(request_reason, arch_segments_option) {
    const active = !arch_segments_option.active;
    const id = arch_segments_option.id;
    request_reason.arch_segments_options.filter(function (arch_segments_option) {
      return arch_segments_option.id === id;
    })[0].active = active;
    this.props.setArchSegmentOption(request_reason);
  }

  /**
   * Returns a segment button element.
   *
   * @param {string} request_reason - The request reason.
   * @param {object} arch_segments_option - The architectural segments option.
   * @param {boolean} disabled - Indicates if the button is disabled.
   * @returns {JSX.Element} The segment button element.
   */
  getSegmentButton = (request_reason, arch_segments_option, disabled) => {
    return (
      <button
        key={arch_segments_option.id}
        type="button"
        className={'btn-ib ' + (arch_segments_option.active ? 'active' : '') + (disabled ? ' btn-ib--disabled' : '')}
        onClick={() => {
          this.setArchSegmentOption(request_reason, arch_segments_option);
        }}
        disabled={disabled}
      >
        {arch_segments_option.title}
      </button>
    );
  };

  createIdbChoiceView = (request_reason) => {
    const upper_disabled = this.props.disabled.filter((t) => t.indexOf('UL') >= 0 || t.indexOf('UR') >= 0).length > 0;
    const lower_disabled = this.props.disabled.filter((t) => t.indexOf('LL') >= 0 || t.indexOf('LR') >= 0).length > 0;

    let selectedTeeth = request_reason.selectedTeeth;
    let archSegmentsOptions = request_reason.arch_segments_options;
    const hasUpperIdb = this.props.item_parts_quantity.some((item) => ['80008', '80016', '6400-05', '6401-02'].includes(item.part_id) && item.quantity > 0);
    const hasLowerIdb = this.props.item_parts_quantity.some((item) => ['80007', '80015', '6400-06', '6401-03'].includes(item.part_id) && item.quantity > 0);
    if (!hasUpperIdb && _.isArray(selectedTeeth)) {
      selectedTeeth = selectedTeeth.filter((tooth) => !tooth.startsWith('U'));
      for (let option of archSegmentsOptions) {
        if (option.title.includes('Upper')) option.active = false;
      }
    }
    if (!hasLowerIdb && _.isArray(selectedTeeth)) {
      selectedTeeth = selectedTeeth.filter((tooth) => !tooth.startsWith('L'));
      for (let option of archSegmentsOptions) {
        if (option.title.includes('Lower')) option.active = false;
      }
    }
    request_reason.selectedTeeth = selectedTeeth;
    request_reason.archSegmentsOptions = archSegmentsOptions;

    return this.props.gen_2 ? (
      <div className="col-sm-12 idb-selectors">
        Single-Tooth Trays
        <div className="reason-teeth-container">
          <TeethSelector
            selected={request_reason.selectedTeeth}
            onClick={this.handleTeethClick(request_reason)}
            disabled={this.props.disabled}
            hasUpper={this.props.selected}
            hasLower={true}
          />
          <div className="extra-padding-headline" />
        </div>
        Arch Segments
        <div id="reason-arch-segments">
          <div class="arch-segment-row upper-row">
            {request_reason.arch_segments_options
              .filter((arch_segments_option) => arch_segments_option.title.toLowerCase().indexOf('upper') >= 0)
              .map((arch_segments_option) => {
                return this.getSegmentButton(request_reason, arch_segments_option, upper_disabled);
              })}
          </div>
          <div class="arch-segment-row upper-row">
            {request_reason.arch_segments_options
              .filter((arch_segments_option) => arch_segments_option.title.toLowerCase().indexOf('lower') >= 0)
              .map((arch_segments_option) => {
                return this.getSegmentButton(request_reason, arch_segments_option, lower_disabled);
              })}
          </div>
          <div className="extra-padding-headline" />
        </div>
      </div>
    ) : (
      <div className="col-sm-12">
        <div>
          Segment Preference:
          <div className="btn-bar" role="toolbar" id="segment-preference">
            <button
              key="individual_teeth_segments"
              type="button"
              onClick={() => this.setSegmentPreference(request_reason, 'individual_teeth_segments', 'Individual Teeth Segments')}
              className={'btn-ib ' + (request_reason.segment_preference === 'individual_teeth_segments' ? 'active ' : '')}
            >
              Individual Teeth Segments
            </button>
            <button
              key="arch_segments"
              type="button"
              onClick={() => this.setSegmentPreference(request_reason, 'arch_segments', 'Arch Segments')}
              className={'btn-ib ' + (request_reason.segment_preference === 'arch_segments' ? 'active ' : '')}
            >
              Arch Segments
            </button>
          </div>
        </div>
        {request_reason.segment_preference === 'individual_teeth_segments' ? (
          <div className="reason-teeth-container">
            <TeethSelector
              selected={request_reason.selectedTeeth}
              onClick={this.handleTeethClick(request_reason)}
              disabled={this.props.disabled}
              hasUpper={this.props.selected}
              hasLower={true}
            />
            <div className="extra-padding-headline" />
          </div>
        ) : (
          <div id="reason-arch-segments">
            <div class="arch-segment-row upper-row">
              {request_reason.arch_segments_options
                .filter((arch_segments_option) => arch_segments_option.title.toLowerCase().indexOf('upper') >= 0)
                .map((arch_segments_option) => {
                  return this.getSegmentButton(request_reason, arch_segments_option, upper_disabled);
                })}
            </div>
            <div class="arch-segment-row upper-row">
              {request_reason.arch_segments_options
                .filter((arch_segments_option) => arch_segments_option.title.toLowerCase().indexOf('lower') >= 0)
                .map((arch_segments_option) => {
                  return this.getSegmentButton(request_reason, arch_segments_option, lower_disabled);
                })}
            </div>
            <div className="extra-padding-headline" />
          </div>
        )}
      </div>
    );
  };

  render() {
    const case_id = this.props.case_id;
    const isDE = case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);

    return (
      <div>
        <div className="bpp-portal-view">
          <div className="ir-submission-heading">Item Request Form - Reasons for Request (Step 2 of 3)</div>
          <div className="main-headline bold-text">Please select the reason(s) for the following item(s):</div>
          <div className="row remove-margin noselect">
            {this.props.smartwire_has_quantity ? (
              <div>
                <div className="bpp-reason-heading-new">Smartwires</div>
                <div className="bpp-heading-divider" />
                <div className="row bpp-reason-list remove-margin noselect">
                  <div className="bpp-reason-content">
                    {this.props.request_reasons.map((request_reason, idx) =>
                      request_reason.category === 'smartwire' &&
                      request_reason.label !== 'Other reason:' &&
                      request_reason.label !== 'Other reason or additional details:' ? (
                        <div key={request_reason.id} data-id={request_reason.id} className="reason-input-data">
                          <input
                            type="checkbox"
                            id={request_reason.name}
                            data-id={request_reason.id}
                            checked={request_reason.booleanValue}
                            className="reason-checkbox"
                            onChange={this.props.onCheckboxClicked}
                          />
                          <label htmlFor={request_reason.name} data-id={request_reason.id} className="reason-checkbox-lebel">
                            {request_reason.label.replace(':', ' (specify below)')}
                          </label>
                          {request_reason.booleanValue && request_reason.toothSelector ? (
                            <div className="col-sm-12">
                              <div className="reason-teeth-container">
                                <span>Specify location:</span>
                                <TeethSelector
                                  selected={request_reason.selectedTeeth}
                                  onClick={this.handleTeethClick(request_reason)}
                                  disabled={request_reason.name === 'idb_required_0' ? this.props.disabled : []}
                                  hasUpper={this.props.selected}
                                  hasLower={true}
                                />
                                <div className="extra-padding-headline" />
                              </div>
                            </div>
                          ) : null}
                          {request_reason.booleanValue && request_reason.text_area && (
                            <div>
                              <textarea
                                className="form-control bpp-item-reason-comment"
                                placeholder="Please provide additional information (optional)"
                                id="smartwire-additional-information"
                                data-id={request_reason.id}
                                data-name={request_reason.name}
                                rows="2"
                                value={this.props.smartwire_breakage_comments}
                                onChange={this.props.onTextChange}
                                onBlur={this.props.onFocus}
                              />
                            </div>
                          )}
                        </div>
                      ) : null
                    )}
                    <div className="bpp-reason-content">
                      <div
                        className={
                          this.props.smartwire_other_comments_require && !this.props.smartwire_other_comments && this.props.error
                            ? 'item-reason-headline remove-margin warning-text'
                            : 'item-reason-headline remove-margin'
                        }
                      >
                        Other reason or additional details:
                      </div>
                      <div className="remove-margin">
                        <textarea
                          className={
                            this.props.smartwire_other_comments_require && !this.props.smartwire_other_comments && this.props.error
                              ? 'form-control item-reason-comment-warning'
                              : 'form-control bpp-item-reason-comment'
                          }
                          placeholder="Specify other reason for request if not listed above or additional details"
                          id="other-reason-for-smartwire"
                          data-id={
                            this.props.request_reasons.filter(function (reason) {
                              return reason.category === 'smartwire' && reason.type === 'text_area';
                            })[0].id
                          }
                          data-name="smartwire_5"
                          rows="2"
                          value={this.props.smartwire_other_comments}
                          onChange={this.props.onTextChange}
                          onBlur={this.props.onFocus}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.idb_has_quantity ? (
              <div>
                <div className="bpp-reason-heading-new">{this.props.gen_2 && isDE ? `Digital Enhancement IDB Tray` : `IDB Tray`}</div>
                <div className="bpp-heading-divider" />
                <div className="row bpp-reason-list remove-margin noselect">
                  <div className="bpp-reason-content">
                    {this.props.request_reasons.map((request_reason, idx) =>
                      request_reason.category === 'idb_required' ? (
                        <div key={request_reason.id} className="col-sm-12">
                          <div className="main-headline">
                            {isDE && request_reason.question_text_de ? request_reason.question_text_de : request_reason.question_text}
                          </div>
                          {this.createIdbChoiceView(request_reason)}
                        </div>
                      ) : null
                    )}
                    {this.props.request_reasons.filter(function (reason) {
                      return reason.category === 'idb_required';
                    }).length > 0 ? (
                      <div className="main-headline">2. Specify reason(s) for request</div>
                    ) : null}
                    {this.props.request_reasons.map((request_reason, idx) =>
                      request_reason.category === 'idb' &&
                      request_reason.label !== 'Other reason or additional details:' &&
                      request_reason.label !== 'Segmented bonding jig needed:' &&
                      request_reason.label !== 'Other reason or special requirements:' ? (
                        <div key={request_reason.id} data-id={request_reason.id} className="reason-input-data">
                          <input
                            type="checkbox"
                            id={request_reason.name}
                            data-id={request_reason.id}
                            checked={request_reason.booleanValue}
                            className="reason-checkbox"
                            onChange={this.props.onCheckboxClicked}
                          />
                          <label htmlFor={request_reason.name} data-id={request_reason.id} className="reason-checkbox-lebel">
                            {isDE && request_reason.label_de
                              ? request_reason.label_de.replace(':', ' (specify below)')
                              : request_reason.label.replace(':', ' (specify below)')}
                          </label>
                          {request_reason.booleanValue && request_reason.toothSelector ? (
                            <div className="col-sm-12">
                              <div className="reason-teeth-container">
                                <span>Specify location:</span>
                                <TeethSelector
                                  selected={request_reason.selectedTeeth}
                                  onClick={this.handleTeethClick(request_reason)}
                                  disabled={this.props.disabled}
                                  hasUpper={this.props.selected}
                                  hasLower={true}
                                />
                                <div className="extra-padding-headline" />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    )}
                    <div
                      className={
                        this.props.idb_other_comments_require && !this.props.idb_other_comments && this.props.error
                          ? 'item-reason-headline remove-margin warning-text'
                          : 'item-reason-headline remove-margin'
                      }
                    >
                      Other reason or additional details:
                    </div>
                    <div className="remove-margin">
                      <textarea
                        className={
                          this.props.idb_other_comments_require && !this.props.idb_other_comments && this.props.error
                            ? 'form-control item-reason-comment-warning'
                            : 'form-control bpp-item-reason-comment'
                        }
                        placeholder="Specify other reason for request if not listed above or additional details"
                        id="other-reason-for-idb"
                        data-id={
                          this.props.request_reasons.filter(function (reason) {
                            return reason.category === 'idb' && reason.type === 'text_area';
                          })[0].id
                        }
                        data-name="idb_4"
                        rows="2"
                        value={this.props.idb_other_comments}
                        onChange={this.props.onTextChange}
                        onBlur={this.props.onFocus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.brackets_has_quantity ? (
              <div>
                <div className="bpp-reason-heading-new">Brackets</div>
                <div className="bpp-heading-divider" />
                <div className="row bpp-reason-list remove-margin noselect">
                  <div className="bpp-reason-content">
                    {this.props.request_reasons.map((request_reason, idx) =>
                      request_reason.category === 'brackets' &&
                      request_reason.label !== 'Other reason:' &&
                      request_reason.label !== 'Other reason or additional details:' ? (
                        <div key={request_reason.id} data-id={request_reason.id} className="reason-input-data">
                          <input
                            type="checkbox"
                            id={request_reason.name}
                            data-id={request_reason.id}
                            checked={request_reason.booleanValue}
                            className="reason-checkbox"
                            onChange={this.props.onCheckboxClicked}
                          />
                          <label htmlFor={request_reason.name} data-id={request_reason.id} className="reason-checkbox-lebel">
                            {request_reason.label.replace(':', ' (specify below)')}
                          </label>
                          {request_reason.booleanValue && request_reason.toothSelector ? (
                            <div className="col-sm-12">
                              <div className="reason-teeth-container">
                                <span>Specify location:</span>
                                <TeethSelector
                                  selected={request_reason.selectedTeeth}
                                  onClick={this.handleTeethClick(request_reason)}
                                  hasUpper={this.props.selected}
                                  hasLower={true}
                                />
                                <div className="extra-padding-headline" />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null
                    )}
                    <div
                      className={
                        this.props.brackets_other_comments_require && !this.props.brackets_other_comments && this.props.error
                          ? 'item-reason-headline remove-margin warning-text'
                          : 'item-reason-headline remove-margin'
                      }
                    >
                      Other reason or additional details:
                    </div>
                    <div className="remove-margin">
                      <textarea
                        className={
                          this.props.brackets_other_comments_require && !this.props.brackets_other_comments && this.props.error
                            ? 'form-control item-reason-comment-warning'
                            : 'form-control bpp-item-reason-comment'
                        }
                        placeholder="Specify other reason for request if not listed above or additional details"
                        id="other-reason-for-bracket"
                        data-id={
                          this.props.request_reasons.filter(function (reason) {
                            return reason.category === 'brackets' && reason.type === 'text_area';
                          })[0].id
                        }
                        data-name="brackets_3"
                        rows="2"
                        value={this.props.brackets_other_comments}
                        onChange={this.props.onTextChange}
                        onBlur={this.props.onFocus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
export default ItemReason;
