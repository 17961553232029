/**
 * File: missing_teeth_measurements.js - A component contains modal content for selecting missing teeth and IP Loop Measurements (used for AWB)
 * Copyright: (c) Copyright July 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
// External Libs
import React, { Component } from 'react';

import TeethChart from './teeth_chart/teeth_chart';
import IPLoopMeasurements from './ip_loop_measurements';

class MissingTeethMeasurements extends Component {
  render() {
    return (
      <React.Fragment>
        <TeethChart></TeethChart>
        <IPLoopMeasurements></IPLoopMeasurements>
      </React.Fragment>
    );
  }
}

export default MissingTeethMeasurements;
