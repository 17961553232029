// Css
import '../settings.scss';
// External Libs
import React, { Component } from 'react';

// Internal
import Datalist from '../../../components/form/list/datalist';

const SETTINGS = {
  'Initial Case': {
    case_smile_design_target_days: 'Smile Design Target Date',
    case_smile_design_revision_target_days: 'Smile Design Revision Target Date',
    case_post_approval_target_days: 'Post Approval Target Date',
    case_appliance_design_target_days: 'Appliance Design Target Date',
    case_target_ship_days: 'Target Ship Date',
    case_preferred_shipping_method: 'Preferred Shipping Method',
  },
  'Digital Enhancement Case': {
    de_smile_design_target_days: 'Smile Design Target Date',
    de_smile_design_revision_target_days: 'Smile Design Revision Target Date',
    de_post_approval_target_days: 'Post Approval Target Date',
    de_appliance_design_target_days: 'Appliance Design Target Date',
    de_target_ship_days: 'Target Ship Date',
    de_preferred_shipping_method: 'Preferred Shipping Method',
  },
  'Item Request': {
    ir_target_ship_days: 'Target Ship Date',
    ir_preferred_shipping_method: 'Preferred Shipping Method',
  },
};

const SHIPPING_METHODS = ['Next Day Shipping', '2 Day Shipping', '3 Day Shipping', 'Ground Shipping'];

/**
 * Displays the internal target date settings for a provider tier
 * @alias TierDetails
 * @component
 * @category BPP
 * @subcategory TierDetails
 */
class TierDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Retrieves currently selected tier
   * @function
   * @param provider_tier_id {number} - Tier id
   * @return {object} - Tier object
   */
  getSelectedTier = (provider_tier_id) => {
    for (const tier of this.props.provider_tiers) {
      if (tier.provider_tier_id === provider_tier_id) {
        return tier;
      }
    }
    return null;
  };

  /**
   * Retrieves tier currently being updated
   * @function
   * @param provider_tier_id {number} - Tier id
   * @return {object} - Tier object
   */
  getSelectedTierUpdate = (provider_tier_id) => {
    for (const tier of this.props.provider_tiers_updates) {
      if (tier.provider_tier_id === provider_tier_id) {
        return tier;
      }
    }
    return null;
  };

  /**
   * Displays setting section
   * @function
   * @param case_type {string} - Initial case, DE, or item request
   * @param selected_tier {object} - Selected tier
   * @param index {number} - Index of setting section
   * @return {JSX} - JSX for setting section
   */
  displaySettingSection = (case_type, selected_tier, index) => {
    return (
      <div key={index}>
        <div className={`bold-text settings-heading settings-heading${case_type.includes('Initial') ? '__case' : '__de'}`}>{case_type}</div>
        {this.displaySetting(case_type, selected_tier)}
      </div>
    );
  };

  /**
   * Displays individual setting
   * @function
   * @param case_type {string} - Initial case, DE, or item request
   * @param selected_tier {object} - Selected tier
   * @return {JSX} - JSX for setting
   */
  displaySetting = (case_type, selected_tier) => {
    const selected_tier_update = this.getSelectedTierUpdate(this.props.selected_tier_id);

    return Object.keys(SETTINGS[case_type]).map((setting, index) => {
      return (
        <div className="target-date" key={index}>
          <div className="target-date__label">
            <span className="bold-text">{SETTINGS[case_type][setting]}:</span>
          </div>
          {this.props.edit_mode ? (
            <div className="target-date__input">
              {setting.includes('preferred_shipping_method')
                ? this.displayPreferredShippingMethodInput(setting, selected_tier_update)
                : this.displayTargetShipDaysInput(setting, selected_tier_update)}
            </div>
          ) : (
            <div className="target-date__display">
              {selected_tier[setting]}
              {!setting.includes('preferred_shipping_method') && ' Business Days'}
            </div>
          )}
        </div>
      );
    });
  };

  /**
   * Displays input for preferred shipping method
   * @function
   * @param setting {string} - Setting type
   * @param selected_tier_update {object} - Selected tier for updating
   * @return {JSX} - JSX for preferred shipping method
   */
  displayPreferredShippingMethodInput = (setting, selected_tier_update) => {
    return (
      <Datalist
        borderClassName="select-border"
        className={`form-control ${setting.includes('case') ? 'preferred-shipping-case' : ''}`}
        hideDefaultOption={true}
        multiple={false}
        name={setting.includes('case') ? 'shipping_case' : setting.includes('de') ? 'shipping_de' : 'shipping_ir'}
        value={selected_tier_update[setting]}
        change={(e) => this.props.onPreferredShippingMethodChange(e, setting)}
        item={setting.includes('case') ? 'shipping_case' : setting.includes('de') ? 'shipping_de' : 'shipping_ir'}
        list={SHIPPING_METHODS}
      />
    );
  };

  /**
   * Displays input for target ship date
   * @function
   * @param setting {string} - Setting type
   * @param selected_tier_update {object} - Selected tier for updating
   * @return {JSX} - JSX for target ship date input
   */
  displayTargetShipDaysInput = (setting, selected_tier_update) => {
    return (
      <>
        <input
          className={`form-control days-input${this.props.invalid_settings.indexOf(setting) !== -1 ? ' days-input__error' : ''}`}
          data-type={setting}
          value={selected_tier_update[setting]}
          onChange={this.props.onBusinessDaysChange}
        />
        <span>Business Days</span>
      </>
    );
  };

  /**
   * Displays settings buttons
   * @function
   * @return {JSX} - JSX for buttons
   */
  displaySettingsButtons = () => {
    return (
      <div className="target-date">
        {this.props.edit_mode ? (
          <div className="btn-save-cancel">
            <button className="btn btn-light" onClick={this.props.onEditSave}>
              Save
            </button>
            <button className="btn btn-light" onClick={this.props.onEditCancel}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="btn-edit-settings">
            <button className="btn btn-light btn-edit" onClick={() => this.props.toggleEditMode(true)}>
              Edit
            </button>{' '}
          </div>
        )}
      </div>
    );
  };

  render() {
    const selected_tier = this.getSelectedTier(this.props.selected_tier_id);

    return this.props.selected_tier_id ? (
      <>
        <div className="bold-text business-dark-heading">Details</div>
        {selected_tier && (
          <div className="business-dark-theme">
            {Object.keys(SETTINGS).map((case_type, index) => {
              return this.displaySettingSection(case_type, selected_tier, index);
            })}
            {this.props.no_input_error && <div className="target-date-error">Please ensure the highlighted fields are greater than zero.</div>}
            {this.props.target_ship_days_input_error && (
              <div className="target-date-error">
                Value for "Target Ship Date" should be greater than or equal to "Appliance Design Target Date", please enter a valid value.
              </div>
            )}
            {this.props.post_approval_days_input_error && (
              <div className="target-date-error">
                Value for "Post Approval Target Date" should not be greater than or equal to "Appliance Design Target Date" or "Target Ship Date", please enter
                a valid value.
              </div>
            )}
            {this.displaySettingsButtons()}
          </div>
        )}
      </>
    ) : null;
  }
}

export default TierDetails;
