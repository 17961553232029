import React from 'react';
import Axios from 'axios';
import { onZipClick } from '../../../../common/dropbox';
export const SET_SINGLE_TOOTH_IDB_STATE = 'SET_SINGLE_TOOTH_IDB_STATE';
export const SET_SINGLE_TOOTH_IDB_MODAL = 'SET_SINGLE_TOOTH_IDB_MODAL';
export const SET_SINGLE_TOOTH_IDB_REFRESH = 'SET_SINGLE_TOOTH_IDB_REFRESH';

/**
 * Retrieves single tooth IDB state
 * @function
 * @param {string} id - Case or ireq id
 * @returns {object} - Action type
 */
export function fetchSingleToothIdb(id) {
  return (dispatch) => {
    Axios.get(`/apiv3/singletoothidb/${id}`)
      .then((res) => {
        if (res && res.data) {
          dispatch(setSingleToothIdbState(res.data));
        }
      })
      .catch((error) => {});
  };
}

/**
 * Sets single tooth IDB status
 * @function
 * @param {string} status - Single tooth IDB status
 * @returns {object} - Action type
 */
export function setSingleToothIdbState(state) {
  return {
    type: SET_SINGLE_TOOTH_IDB_STATE,
    state: state,
  };
}

/**
 * Sets single tooth IDB modal
 * @function
 * @param {string} modal - Modal type
 * @returns {object} - Action type
 */
export function setSingleToothIdbModal(modal) {
  return {
    type: SET_SINGLE_TOOTH_IDB_MODAL,
    modal: modal,
  };
}

/**
 * API call for single-tooth IDB action
 * @function
 * @param {string} action - Type of action
 * @param {string} id - Case or ireq id
 * @returns {object} - Action type
 */
export function singleToothIdbAction(action, id, data = {}) {
  return (dispatch) => {
    Axios.post(`/apiv3/singletoothidb/${id}/${action}`, data)
      .then(function (res) {
        if (res && res.data) {
          dispatch(setSingleToothIdbModal(''));
          dispatch(setSingleToothIdbState(res.data));
        }
      })
      .catch(function (error) {
        dispatch(setSingleToothIdbModal(''));
        if (error && error.response && error.response.status === 409) {
          dispatch(setRefresh());
        }
      });
  };
}

/**
 * Sets refresh for session expire
 * @function
 * @returns {object} - Action type
 */
export function setRefresh() {
  return {
    type: SET_SINGLE_TOOTH_IDB_REFRESH,
  };
}

/**
 * Displays download links for single-tooth idb files
 * @function
 * @param {string} status - Single tooth idb status
 * @param {object} final_file - Single tooth idb final file
 * @param {object} raw_file - Single tooth idb raw file
 * @returns {JSX} - JSX for output
 */
export function displaySingleToothIdbOutput(status, final_file, raw_file = null) {
  return (
    <>
      <div className="bold-text">Single-Tooth IDB Tray</div>
      <div>
        {status === 'Single Tooth IDB Overridden' ? (
          <div>Single-Tooth IDB Tray generation has been overridden...</div>
        ) : final_file ? (
          <>
            {raw_file && (
              <div className="file-display">
                <a href={'/' + raw_file.upload_data} onClick={onZipClick} download>
                  <div className="underline-text viewable-text center-text">
                    Single-Tooth IDB Tray Raw <i className="fa fa-file-zip-o" aria-hidden="true" />
                  </div>
                </a>
              </div>
            )}
            <div className="file-display">
              <a href={'/' + final_file.upload_data} onClick={onZipClick} download>
                <div className="underline-text viewable-text center-text">
                  Single-Tooth IDB Tray Final <i className="fa fa-file-zip-o" aria-hidden="true" />
                </div>
              </a>
              {final_file.upload_data.includes('_manual') && <span> (Manually Uploaded)</span>}
            </div>
          </>
        ) : (
          <div>Single-Tooth IDB Tray (Not Applicable)</div>
        )}
      </div>
    </>
  );
}
