import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import DOMPurify from 'dompurify';
import { convertDate } from '../../../common/functions';
import WasmSidebarApproval from './wasm_sidebar_approval';
import { ReactComponent as InLogo } from '../../../doctor/components/in.svg';
import classNames from 'classnames';

const IN_LOGO = <InLogo className="wasm-sidebar-in-logo" />;
const DOCTOR_LOGO = <i className="fa fa-user-md fa-sm wasm-sidebar-dr-logo" aria-hidden />;

function WasmSidebarHistoryItem(props) {
  const { showReview, title, name, comment_date, ifs, comment, collapseDisabled, onOpenModal, size } = props;
  const [isOpen, setIsOpen] = useState(true);
  const containerClassNames = classNames({
    'wasm-sidebar-history-item': true,
    'wasm-sidebar-history-item--ifs': ifs,
    'wasm-sidebar-history-item--review': showReview,
    'wasm-sidebar-history-item--no-collapse': collapseDisabled,
  });
  const collapseClassNames = isOpen ? 'wasm-minus' : 'wasm-plus';
  const isInBraceComment = name === 'InBrace';
  const historyClassNames = classNames({
    'scrollbar--dark': true,
    'wasm-sidebar-history-comment': true,
    'wasm-sidebar-history-comment--scrollbar': size === 1,
  });
  return (
    <div className={containerClassNames}>
      <div className="wasm-sidebar-heading header" onClick={() => setIsOpen(!isOpen)}>
        <div className="wasm-sidebar-heading-title">
          {!collapseDisabled && <div className={collapseClassNames} />}
          {title}
        </div>
        {isInBraceComment ? IN_LOGO : DOCTOR_LOGO}
      </div>
      <Collapse in={isOpen}>
        <div className="wasm-sidebar-history-comment-item">
          {comment && (
            <>
              <div className="wasm-sidebar-history-info">
                <div>
                  {isInBraceComment ? IN_LOGO : DOCTOR_LOGO}
                  <span className="wasm-sidebar-history-name header">{name}</span>
                </div>
                <div className="wasm-sidebar-history-date">{convertDate(comment_date)}</div>
              </div>
              <div className={historyClassNames}>
                <span
                  className="ql-editor ql-table quill-formatted"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(comment, { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
                  }}
                />
              </div>
            </>
          )}
          {showReview && <WasmSidebarApproval name={title} onOpenModal={onOpenModal} />}
        </div>
      </Collapse>
    </div>
  );
}

export default WasmSidebarHistoryItem;
