import Axios from 'axios';

export const FETCH_CASE_FILTER_COUNT_START = 'FETCH_CASE_FILTER_COUNT_START';
export const FETCH_CASE_FILTER_COUNT_SUCCESS = 'FETCH_CASE_FILTER_COUNT_SUCCESS';
export const FETCH_CASE_FILTER_COUNT_ERROR = 'FETCH_CASE_FILTER_COUNT_ERROR';

export function fetchCaseFilterCount(case_type) {
  return (dispatch) => {
    dispatch(fetchCaseFilterCountStart());
    Axios.get(`/apiv3/casestatuscount?type=${case_type}`)
      .then((res) => dispatch(fetchCaseFilterCountSuccess(case_type, res.data)))
      .catch((err) => dispatch(fetchCaseFilterCountError(err)));
  };
}

function fetchCaseFilterCountStart() {
  return {
    type: FETCH_CASE_FILTER_COUNT_START,
  };
}

function fetchCaseFilterCountSuccess(case_type, case_filter_count) {
  return {
    type: FETCH_CASE_FILTER_COUNT_SUCCESS,
    case_type: case_type,
    case_filter_count: case_filter_count,
  };
}

function fetchCaseFilterCountError(error) {
  return {
    type: FETCH_CASE_FILTER_COUNT_ERROR,
    error: error,
  };
}
