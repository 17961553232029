/**
 * File:ir_content.js - Component to list content of item request list
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './smile_simulation_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import PaginationContainer from '../../components/pagination/pagination_container';
import { Helmet } from 'react-helmet';
import { withRouter, Redirect } from 'react-router-dom';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import SortHeader from '../../components/layout/sort_header';
import SmileSimulationNav from './smile_simulation_nav';
import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';

// Internal Functions
import { setTokenHeader, convertDate } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from '../../common/route';
import { getSubmissionDateDefaultSort } from '../../common/helpers';
import {
  onChangeSearch,
  translateSmile,
  translateFilterStatus,
  getCasesFromRow,
  getSmilesFromSearch,
  getSmilesFromFilter,
  getCasesFromEndDate,
  getCasesFromStartDate,
  getSmilesFromStatus,
  getSmileStatusFilterDefinition,
  searchErrorMessage,
} from '../../common/search';
import { tableRowLink } from '../../common/table';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class SmileSimulationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      module: 'case_list',
      loading: true,
      error: false,
      search: '',
      filter: '',
      smiles_raw: [],
      smiles_view: [],
      smiles_length: 1,
      smiles_sort_method: 'submission_date',
      smiles_sort_order_ascending: {
        smile_id: true,
        case_id: true,
        patient_name: true,
        doctor_name: true,
        submission_date: false,
        case_submission_date: false,
        status: true,
        assigned_to: true,
      },
      smiles_row_size: 25,
      smiles_active_page: 1,
      smiles_page_range: 5,
      is_bottom: false,
      column_filter: '',
      status_filter: '',
      start_date_filter: '',
      end_date_filter: '',
      side_collapse: false,
      inbrace_action_required_collapse: false,
      doctor_action_required_collapse: false,
      user_id: '',
      submission_date_error: false,
      selected_case_type: 'All Cases',
      newTabUrl: '',
    };
    this.ssTable = React.createRef();
  }

  onPageChange = (active_page) => {
    //Allow for page changes without when filters are not applied yet
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute();
    const smiles_sort_method = this.state.smiles_sort_method;

    let smiles_view = filter
      ? getSmilesFromFilter(this.state.smiles_raw, filter, this.state.user_id, this.state.assigned_smiles)
      : getSmilesFromSearch(
          getCasesFromEndDate(getCasesFromStartDate(getSmilesFromStatus(this.state.smiles_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    this.setState({
      smiles_active_page: active_page,
      smiles_view: getCasesFromRow(
        this.setOrderByMethod(smiles_view, smiles_sort_method, this.state.smiles_sort_order_ascending[smiles_sort_method]),
        active_page,
        this.state.smiles_row_size
      ),
    });
  };

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    Axios.get(`/apiv3/smilesimulation`)
      .then(function (res) {
        let smiles_raw = translateSmile(res.data.smiles);
        let assigned_smiles = res.data.assigned_smiles;
        let user_id = res.data.user_id;

        const smiles_sort_method = that.getSortMethodByFilterSelection(that.state.filter, filter, that.state.smiles_sort_method);
        const smiles_view = filter
          ? getSmilesFromFilter(smiles_raw, filter, res.data.user_id, assigned_smiles)
          : getSmilesFromSearch(getCasesFromEndDate(getCasesFromStartDate(getSmilesFromStatus(smiles_raw, status), start_date), end_date), search, column);

        that.setState({
          loading: false,
          search: search,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          smiles_raw: smiles_raw,
          smiles_view: getCasesFromRow(
            that.setOrderByMethod(smiles_view, smiles_sort_method, that.state.smiles_sort_order_ascending[smiles_sort_method]),
            that.state.smiles_active_page,
            that.state.smiles_row_size
          ),
          smiles_length: smiles_view.length,
          smiles_sort_method: smiles_sort_method,
          assigned_smiles: assigned_smiles,
          filter: filter,
          user_id: user_id,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    const that = this;
    const state_filter = this.state.filter;
    const search = getBusinessSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const refresh = this.state.refresh;
    const refresh_history =
      this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.refresh
        ? this.props.history.location.state.refresh
        : false;

    const active_page = 1;
    const row_size = this.state.smiles_row_size;
    let smiles_view = [];

    if ((state_filter !== filter || refresh || refresh_history === 'true') && that.state.user_id) {
      if (!this.state.submission_date_error) {
        smiles_view = filter
          ? getSmilesFromFilter(that.state.smiles_raw, filter, that.state.user_id, that.state.assigned_smiles)
          : getSmilesFromSearch(
              getCasesFromEndDate(getCasesFromStartDate(getSmilesFromStatus(that.state.smiles_raw, status), start_date), end_date),
              search,
              column
            );
        if (refresh_history === 'true') {
          that.props.history.replace({ state: { refresh: 'false' } });
        }

        const smiles_sort_method = that.getSortMethodByFilterSelection(state_filter, filter, that.state.smiles_sort_method);

        that.setState({
          smiles_view: getCasesFromRow(
            this.setOrderByMethod(smiles_view, smiles_sort_method, that.state.smiles_sort_order_ascending[smiles_sort_method]),
            active_page,
            row_size
          ),
          smiles_active_page: active_page,
          smiles_length: smiles_view.length,
          smiles_sort_method: smiles_sort_method,
          search: search,
          filter: filter,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          refresh: false,
        });
      }
    }

    let numCasesRaw = this.state.smiles_raw.length;
    let numCases = this.state.smiles_length;

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.endsWith('portal') &&
      !this.props.history.location.search &&
      numCases !== numCasesRaw &&
      !this.state.refresh
    ) {
      // Refresh route when cases header is clicked or cases icon is clicked
      this.refreshRoute();
    }
  }

  onPatientClick = (event) => {
    const pathname = `/business/portal/smile/${event.currentTarget.dataset.smile_id}`;
    tableRowLink(event, pathname, this.props);
  };

  getSortMethodByFilterSelection = (state_filter, filter, sort_method) => {
    let sort_method_update = sort_method;

    if (state_filter !== filter) {
      sort_method_update = 'submission_date';
    }

    return sort_method_update;
  };

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  getStartRange = () => {
    return this.state.smiles_active_page * this.state.smiles_row_size - this.state.smiles_row_size + 1;
  };

  getEndRange = () => {
    return this.state.smiles_active_page * this.state.smiles_row_size > this.state.smiles_length
      ? this.state.smiles_length
      : this.state.smiles_active_page * this.state.smiles_row_size;
  };

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;

    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();
    let status = route_type === 'status' ? uri_text : getBusinessStatusValueFromRoute();
    let start_date = route_type === 'start' ? uri_text : getBusinessStartDateValueFromRoute();
    let end_date = route_type === 'end' ? uri_text : getBusinessEndDateValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';
    status = status !== '' ? `status=${status}` : '';
    start_date = start_date !== '' ? `start=${start_date}` : '';
    end_date = end_date !== '' ? `end=${end_date}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }

      if (status) {
        param.push(status);
      }

      if (start_date) {
        param.push(start_date);
      }

      if (end_date) {
        param.push(end_date);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  onChangeSearchPatient = (event) => {
    onChangeSearch(this, event);
  };

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  applySearch() {
    let that = this;

    that.props.history.replace(that.setRoute(that.state.column_filter, 'col'));
    that.props.history.replace(that.setRoute(that.state.status_filter, 'status'));
    that.props.history.replace(that.setRoute(that.state.start_date_filter, 'start'));
    that.props.history.replace(that.setRoute(that.state.end_date_filter, 'end'));
    that.props.history.replace(that.setRoute(that.state.search, 'search'));

    const search = getBusinessSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();

    const active_page = 1;
    const row_size = that.state.smiles_row_size;
    let smiles_view = [];

    if (start_date && end_date && start_date > end_date) {
      that.setState({
        submission_date_error: true,
      });
    } else {
      smiles_view = filter
        ? getSmilesFromFilter(that.state.smiles_raw, filter, that.state.user_id, that.state.assigned_smiles)
        : getSmilesFromSearch(
            getCasesFromEndDate(getCasesFromStartDate(getSmilesFromStatus(that.state.smiles_raw, status), start_date), end_date),
            search,
            column
          );

      const smiles_sort_method = that.getSortMethodByFilterSelection(that.state.state_filter, filter, that.state.smiles_sort_method);

      that.setState({
        smiles_view: getCasesFromRow(
          that.setOrderByMethod(smiles_view, smiles_sort_method, that.state.smiles_sort_order_ascending[smiles_sort_method]),
          active_page,
          row_size
        ),
        smiles_active_page: active_page,
        smiles_length: smiles_view.length,
        search: search,
        filter: filter,
        column_filter: column,
        status_filter: status,
        start_date_filter: start_date,
        end_date_filter: end_date,
        submission_date_error: false,
      });
    }
  }

  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  onQueryFilterSelection = (event) => {
    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);

    this.setState({
      column_filter: uri_text,
    });
  };

  onStatusFilterSelection = (event) => {
    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);
    this.setState({
      status_filter: uri_text,
    });
  };

  onStartDateSelection = (event) => {
    this.setState({
      start_date_filter: event.target.value,
    });
  };

  onEndDateSelection = (event) => {
    this.setState({
      end_date_filter: event.target.value,
    });
  };

  onSideBarCollapseClick = () => {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  };

  onClickSubMenu = (event) => {
    this.setState({
      submission_date_error: false,
    });
    if (!this.state.side_collapse) {
      const uri_text = encodeURI(event.currentTarget.dataset.filter);
      this.props.history.replace(this.setRoute(uri_text, 'filter'));
      this.onPageChange(1);
    }
  };

  onClickDoctorActionRequiredSubMenu = (event) => {
    if (!this.state.doctor_action_required_collapse) {
      const uri_text = encodeURI(event.currentTarget.dataset.filter);
      this.props.history.replace(this.setRoute(uri_text, 'filter'));
    }
  };

  orderBySmileId(smiles, sort) {
    return _.orderBy(smiles, ['smile_id'], [sort]);
  }

  orderByCaseId(smiles, sort) {
    return _.orderBy(smiles, ['case_id'], [sort]);
  }

  orderByDoctorName(smiles, sort) {
    return _.orderBy(smiles, ['doctor_name', 'submission_date_priority'], [sort, getSubmissionDateDefaultSort()]);
  }

  orderByPatientName(smiles, sort) {
    return _.orderBy(smiles, ['patient_name'], [sort]);
  }

  orderBySubmissionDate(smiles, sort) {
    return _.orderBy(smiles, ['submission_date_val', 'created_date_val'], [sort, sort]);
  }

  orderByCaseSubmissionDate(smiles, sort) {
    return _.orderBy(smiles, ['submission_date_val', 'created_date_val'], [sort, sort]);
  }

  orderByAssignedTo(smiles, sort) {
    return _.orderBy(smiles, ['assigned_to'], [sort]);
  }

  orderByStatus(smiles, sort) {
    return _.orderBy(smiles, ['status_priority'], [sort]);
  }

  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace(this.props.history.location.pathname);

      this.setState({
        refresh: true,
      });
    }
  };

  renameStatus(status) {
    return status === 'Setup Ready for Reupload' ? 'Smile Simulation Ready for Upload' : status;
  }

  /**
   * Right click handler opens context menu
   * @function
   */
  onSsRightClick = (event) => {
    this.setState({
      newTabUrl: `/business/portal/smile/${event.currentTarget.dataset.smile_id}`,
    });
  };

  render() {
    const filter = this.state.filter;
    const display_assigned_to =
      filter === 'setup_ready_upload' || filter === 'setup_conversion' || filter === 'setup_conversion_failed' || filter === 'setup_ready_for_release';

    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('SMILE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
              <div className="">
                <Helmet>
                  <title>Smile Simulation List | InBrace Business Portal</title>
                </Helmet>
                <div className={this.state.side_collapse ? 'sidenav-submenu submenu-collapse' : 'sidenav-submenu'}>
                  <SmileSimulationNav
                    onPageChange={this.onPageChange}
                    side_collapse={this.state.side_collapse}
                    onSideBarCollapseClick={this.onSideBarCollapseClick}
                  />
                </div>
                <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
                  <div className="main-content">
                    <div className="dark-options">
                      <div className="page-heading">Smile Simulations</div>
                      <div className="dark-search">
                        <input
                          type="text"
                          className="form-control search-bar-dark font-awesome"
                          placeholder="Search"
                          aria-describedby="basic-addon1"
                          onChange={this.onChangeSearchPatient}
                          onKeyPress={this.keyPress}
                          value={this.state.search}
                        />
                      </div>
                      <div className="dark-filter dark-filter-hide">
                        <div className="select-container">
                          <select className="select-filter" value={this.state.column_filter} onChange={this.onQueryFilterSelection}>
                            <option value="all">All</option>
                            <option value="smileid">ID</option>
                            <option value="doctor">Doctor Name</option>
                            <option value="patient">Name</option>
                          </select>
                        </div>
                      </div>
                      <div className="dark-filter">
                        <div className="select-container">
                          <select className="select-status" value={this.state.status_filter} onChange={this.onStatusFilterSelection}>
                            <option value="all">All Status</option>
                            <option value="checking">{getSmileStatusFilterDefinition('checking')}</option>
                            <option value="new">{getSmileStatusFilterDefinition('new')}</option>
                            <option value="setup_ready_for_release">{getSmileStatusFilterDefinition('setup_ready_for_release')}</option>
                            <option value="released">{getSmileStatusFilterDefinition('released')}</option>
                            <option value="cancel">{getSmileStatusFilterDefinition('cancel')}</option>
                          </select>
                        </div>
                      </div>
                      <div className="dark-filter">
                        <div className="select-container-date">
                          <div className="text-center light-gray-text">Submission Date Between</div>
                          <div>
                            <input
                              className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                              type="date"
                              name="start"
                              value={this.state.start_date_filter}
                              onChange={this.onStartDateSelection}
                            />
                            <input
                              className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                              type="date"
                              name="end"
                              value={this.state.end_date_filter}
                              onChange={this.onEndDateSelection}
                            />
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                        <i className="fa fa-search" aria-hidden="true" />
                      </button>
                    </div>
                    {this.state.smiles_view.length > 0 ? (
                      <div className="table-case-view">
                        <table className="table table-condensed table-striped table-industry">
                          <thead className="table-inbrace-dark-industry">
                            <tr>
                              <th className="caseid-width" data-method="smile_id" onClick={this.onSortClick}>
                                <SortHeader
                                  title="ID"
                                  category="smile_id"
                                  sort_method={this.state.smiles_sort_method}
                                  ascending={this.state.smiles_sort_order_ascending['smile_id']}
                                />
                              </th>
                              {filter === 'submitted' ? (
                                <th className="submission-width" data-method="case_id" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Case ID"
                                    category="case_id"
                                    sort_method={this.state.smiles_sort_method}
                                    ascending={this.state.smiles_sort_order_ascending['case_id']}
                                  />
                                </th>
                              ) : null}
                              <th className="submission-width" data-method="submission_date" onClick={this.onSortClick}>
                                <SortHeader
                                  title="Submission"
                                  category="submission_date"
                                  sort_method={this.state.smiles_sort_method}
                                  ascending={this.state.smiles_sort_order_ascending['submission_date']}
                                />
                              </th>
                              {filter === 'submitted' ? (
                                <th className="caseid-width" data-method="case_submission_date" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Case Submission"
                                    category="case_submission_date"
                                    sort_method={this.state.smiles_sort_method}
                                    ascending={this.state.smiles_sort_order_ascending['case_submission_date']}
                                  />
                                </th>
                              ) : null}

                              <th className="name-width" data-method="doctor_name" onClick={this.onSortClick}>
                                <SortHeader
                                  title="Doctor"
                                  category="doctor_name"
                                  sort_method={this.state.smiles_sort_method}
                                  ascending={this.state.smiles_sort_order_ascending['doctor_name']}
                                />
                              </th>
                              <th className="name-width" data-method="patient_name" onClick={this.onSortClick}>
                                <SortHeader
                                  title="Name"
                                  category="patient_name"
                                  sort_method={this.state.smiles_sort_method}
                                  ascending={this.state.smiles_sort_order_ascending['patient_name']}
                                />
                              </th>
                              <th className="name-width" data-method="status" onClick={this.onSortClick}>
                                <SortHeader
                                  title="Status"
                                  category="status"
                                  sort_method={this.state.smiles_sort_method}
                                  ascending={this.state.smiles_sort_order_ascending['status']}
                                />
                              </th>
                              {display_assigned_to ? (
                                <th className="name-width" data-method="assigned_to" onClick={this.onSortClick}>
                                  <SortHeader
                                    title="Assigned To"
                                    category="assigned_to"
                                    sort_method={this.state.smiles_sort_method}
                                    ascending={this.state.smiles_sort_order_ascending['assigned_to']}
                                  />
                                </th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody ref={this.ssTable} className="table-inbrace-dark-industry context-menu-container">
                            <ContextMenu container={this.ssTable} containerType="table">
                              <NewTabLink url={this.state.newTabUrl} />
                            </ContextMenu>
                            {this.state.smiles_view.map((smile, index) => {
                              return (
                                <UserPermissionsContext.Consumer key={index}>
                                  {(user_roles_and_permissions) => {
                                    return userHasPermission('SMILE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                                      <tr
                                        className="pointer"
                                        onContextMenu={this.onSsRightClick}
                                        key={index}
                                        onMouseUp={this.onPatientClick}
                                        data-smile_id={smile.smile_id}
                                      >
                                        <td className="td-industry-offset caseid-width">{smile.smile_id}</td>
                                        {this.state.filter === 'submitted' ? <td>{smile.case_id}</td> : null}
                                        <td>{convertDate(smile.smile_submitted_date)}</td>
                                        {this.state.filter === 'submitted' ? <td className="caseid-width">{convertDate(smile.case_submitted_date)}</td> : null}
                                        <td>{smile.doctor_name || `Unassigned - ${smile.doctor__user__first_name}`}</td>
                                        <td className='fs-exclude'>{smile.smile_first_name + ' ' + smile.smile_last_name[0] + '.'}</td>
                                        <td>{this.renameStatus(smile.status)}</td>
                                        {display_assigned_to ? <td>{smile.assigned_to}</td> : null}
                                      </tr>
                                    ) : null;
                                  }}
                                </UserPermissionsContext.Consumer>
                              );
                            })}
                          </tbody>
                        </table>

                        <PaginationContainer
                          theme="bpp"
                          isBottom={this.state.is_bottom}
                          activePage={this.state.smiles_active_page}
                          itemsCountPerPage={this.state.smiles_row_size}
                          totalItemsCount={this.state.smiles_length}
                          pageRangeDisplayed={this.state.smiles_page_range}
                          onChange={this.onPageChange}
                          startRange={this.getStartRange()}
                          endRange={this.getEndRange()}
                          type="Smiles"
                        />
                      </div>
                    ) : (
                      searchErrorMessage(getBusinessSearchValueFromRoute(), 'smiles')
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Redirect to="/business/portal" />
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';

    let smiles_sort_order_ascending = this.state.smiles_sort_order_ascending;

    if (sort_method === this.state.smiles_sort_method) {
      smiles_sort_order_ascending[sort_method] = !smiles_sort_order_ascending[sort_method];
    }

    let smiles_view = filter
      ? getSmilesFromFilter(this.state.smiles_raw, filter, this.state.user_id)
      : getSmilesFromSearch(
          getCasesFromEndDate(getCasesFromStartDate(getSmilesFromStatus(this.state.smiles_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    smiles_view = getCasesFromRow(
      this.setOrderByMethod(smiles_view, sort_method, smiles_sort_order_ascending[sort_method]),
      this.state.smiles_active_page,
      this.state.smiles_row_size
    );

    this.setState({
      smiles_view: smiles_view,
      smiles_sort_method: sort_method,
      smiles_sort_order_ascending: smiles_sort_order_ascending,
    });
  };

  setOrderByMethod(smiles_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'smile_id':
        smiles_view = this.orderBySmileId(smiles_view, sort_value);
        break;

      case 'case_id':
        smiles_view = this.orderByCaseId(smiles_view, sort_value);
        break;

      case 'doctor_name':
        smiles_view = this.orderByDoctorName(smiles_view, sort_value);
        break;

      case 'patient_name':
        smiles_view = this.orderByPatientName(smiles_view, sort_value);
        break;

      case 'status':
        smiles_view = this.orderByStatus(smiles_view, sort_value);
        break;

      case 'submission_date':
        smiles_view = this.orderBySubmissionDate(smiles_view, sort_value);
        break;

      case 'case_submission_date':
        smiles_view = this.orderByCaseSubmissionDate(smiles_view, sort_value);
        break;

      case 'assigned_to':
        smiles_view = this.orderByAssignedTo(smiles_view, sort_value);
        break;

      default:
    }

    return smiles_view;
  }
}

export default withRouter(SmileSimulationList);
