/**
 * File: add_telephone_number.js - A component that is apart of the add account
 * process which is used for constructing the telephone information
 * Copyright: (c) Copyright December 2018 by InBrace
 * Authors: Dhruvang Patel, Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './add_telephone_number.scss';
// External Libs
import React, { Component } from 'react';
// Internal Functions
import ValidationUtils from '../../common/validation/validation-utils';
import { parsePhoneNumber } from 'libphonenumber-js';
import { phoneNumberCharInternational, removeEmoji } from '../../common/functions';

class AddTelephoneNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      value: '',
      type: 'Office',
    };
  }
  onChangeEditProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeNewProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onEditProfileCancel = (event) => {
    this.setState({
      error: false,
      value: '',
      type: 'Office',
    });
    this.props.onEditProfileCancel();
  };

  onNewPhoneNumberCancel = (event) => {
    this.setState({
      error: false,
      value: '',
      type: 'Office',
    });
    this.props.onNewPhoneNumberCancel();
  };

  onChangeEditPhoneType = (event) => {
    this.props.onChangeEditPhoneType(event.target.dataset.id, event.target.value);
  };

  onChangeEditPhoneNumber = (event) => {
    var value = phoneNumberCharInternational(removeEmoji(event.target.value));
    this.props.onChangeEditPhoneNumber(event.target.dataset.id, value);
  };

  updateValue = (event) => {
    var value = phoneNumberCharInternational(removeEmoji(event.target.value));
    this.setState({ value: value });
  };

  handlePhoneTypeChange = (event) => {
    this.setState({ type: event.target.value });
  };

  handleDeletePhoneNo = () => {
    this.props.onDeletePhone(this.props.edit_profile.value, this.props.edit_profile.id);
  };

  updatePhoneNo = () => {
    this.props.updatePhoneNo(this.state.selectedPhone.value, this.props.selectedPhone.type, this.props.selectedPhone.index);
  };

  onUpdatePhoneNo = () => {
    if (this.props.edit_profile.value === '') {
      this.setState({ error: true });
      return null;
    }
    var phoneList = this.props.phoneList;
    var valid = true;
    try {
      const phoneNumber_out = parsePhoneNumber(this.props.edit_profile.value, 'US');
      if (phoneNumber_out.isValid()) {
        var formattedNumber_out = phoneNumber_out.format('NATIONAL');
        for (var i = 0; i < phoneList.length; i++) {
          if (phoneList[i]['number'] === formattedNumber_out) {
            valid = false;
          }
        }
      }
    } catch (error) {
      this.setState({ error: true });
    }
    const clearNumber = this.props.edit_profile.value.replace(/[^0-9\+]/g, '');
    if (ValidationUtils.validateInternationalPhone(clearNumber) && valid === true) {
      try {
        const phoneNumber = parsePhoneNumber(clearNumber, 'US');
        if (phoneNumber.isValid()) {
          var formattedNumber = phoneNumber.format('INTERNATIONAL');
          this.props.onUpdatePhoneNo(formattedNumber, this.props.edit_profile.type, this.props.edit_profile.id);
          this.setState({ error: false, value: '' });
        } else {
          this.setState({ error: true });
        }
      } catch (error) {
        this.setState({ error: true });
      }
    } else {
      this.setState({ error: true });
    }
  };

  onEditClick = (event) => {
    if (!this.props.new_profile.active && this.props.new_profile.mode !== 'add_number') {
      this.props.setSelectedPhone(event.target.dataset.value, event.target.dataset.type, event.target.dataset.id);
    }
  };

  addNewPhone = () => {
    if (this.state.value === '') {
      this.setState({ error: true });
      return null;
    }
    var phoneList = this.props.phoneNumberList;
    var valid = true;
    try {
      const phoneNumber_out = parsePhoneNumber(this.state.value, 'US');
      if (phoneNumber_out) {
        var formattedNumber_out = phoneNumber_out.format('INTERNATIONAL');
        for (var i = 0; i < phoneList.length; i++) {
          if (phoneList[i]['number'] === formattedNumber_out) {
            valid = false;
          }
        }
      }
    } catch (error) {
      this.setState({ error: true });
    }
    const clearNumber = this.state.value.replace(/[^0-9\+]/g, '');
    if (ValidationUtils.validateInternationalPhone(clearNumber) && valid === true) {
      try {
        const phoneNumber = parsePhoneNumber(clearNumber, 'US');
        if (phoneNumber) {
          var formattedNumber = phoneNumber.format('INTERNATIONAL');
          this.props.onAddNewPhone(this.state.type, formattedNumber);
          this.setState({ error: false, value: '' });
        } else {
          this.setState({ error: true });
        }
      } catch (error) {
        this.setState({ error: true });
      }
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    if (this.props.phoneNumberList === null) return null;
    let phoneNoList = this.props.phoneNumberList;

    return (
      <div className="col-lg-6">
        <div className="account-heading">Telephone</div>
        <table className="table table-condensed table-industry account-table">
          <thead className="table-inbrace-dark-industry-2">
            <tr>
              <th className="type-width-tb">Type</th>
              <th className="auto-width-tb">Number</th>
              <th className="edit-width-tb" />
            </tr>
          </thead>
          <tbody className="table-inbrace-dark-industry-2">
            {phoneNoList !== null && phoneNoList.length >= 1 ? (
              phoneNoList.map((phone, index) => {
                return (
                  <tr className="pointer" key={index}>
                    {this.props.edit_profile.active && this.props.edit_profile.mode === 'edit_number' && parseInt(this.props.edit_profile.id) === index ? (
                      <td className="account-table-start type-width-tb">
                        <select className="select-filter" data-id={index} value={this.props.edit_profile.type} onChange={this.onChangeEditPhoneType}>
                          <option value="Office">Office</option>
                          <option value="Home">Home</option>
                          <option value="Mobile">Mobile</option>
                        </select>
                      </td>
                    ) : (
                      <td
                        className="account-table-start type-width-tb account-table-first fs-exclude"
                        data-id={index}
                        data-value={phone.number}
                        data-type={phone.type}
                        data-mode="edit_number"
                        onClick={this.onEditClick}
                      >
                        {phone.type}
                      </td>
                    )}

                    {this.props.edit_profile.active && this.props.edit_profile.mode === 'edit_number' && parseInt(this.props.edit_profile.id) === index ? (
                      <td className="account-table-end auto-width-tb">
                        <input
                          name="phone"
                          className={
                            this.state.error && this.props.edit_profile.mode === 'edit_number'
                              ? 'form-control business-dark-theme-input input-error'
                              : 'form-control business-dark-theme-input'
                          }
                          placeholder="Country Code + Phone Number"
                          data-id={index}
                          value={this.props.edit_profile.value}
                          onChange={this.onChangeEditPhoneNumber}
                          onKeyDown={this.onChangeEditProfileKeyPress}
                          autoFocus
                        />
                      </td>
                    ) : (
                      <td
                        className="account-table-end auto-width-tb"
                        data-id={index}
                        data-value={phone.number}
                        data-type={phone.type}
                        data-mode="edit_number"
                        onClick={this.onEditClick}
                      >
                        {phone.number}
                      </td>
                    )}

                    {this.props.edit_profile.active && this.props.edit_profile.mode === 'edit_number' && parseInt(this.props.edit_profile.id) === index ? (
                      <td className="auto-width-tb">
                        <div id="edit-confirm" className="btn-clear" onClick={this.onUpdatePhoneNo} data-toggle="tooltip" data-placement="top" title="Save">
                          <i className="fa fa-check" aria-hidden="true" />
                        </div>
                        <div
                          id="edit-discard"
                          className="btn-clear"
                          onClick={this.onEditProfileCancel}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Cancel"
                        >
                          <i className="fa fa-times" aria-hidden="true" />
                        </div>
                        <div className="btn-clear" data-id={index} onClick={this.handleDeletePhoneNo} data-toggle="tooltip" data-placement="top" title="Delete">
                          <i className="fa fa-trash" aria-hidden="true" />
                        </div>
                      </td>
                    ) : (
                      <td className="edit-width-tb" />
                    )}
                  </tr>
                );
              })
            ) : this.props.new_profile.active && this.props.new_profile.mode === 'add_number' ? null : (
              <tr>
                <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="2">
                  - - - - - No Phone Number Found (Optional) - - - - -
                </td>
                <td />
              </tr>
            )}

            {this.props.new_profile.active && this.props.new_profile.mode === 'add_number' ? (
              <tr>
                <td className="type-width-tb account-table-start">
                  <select className="select-filter" value={this.state.type} onChange={this.handlePhoneTypeChange}>
                    <option value="Office">Office</option>
                    <option value="Home">Home</option>
                    <option value="Mobile">Mobile</option>
                  </select>
                </td>
                <td className="account-table-end">
                  <input
                    name="phone"
                    className={
                      this.state.error && this.props.new_profile.mode === 'add_number'
                        ? 'form-control business-dark-theme-input input-error'
                        : 'form-control business-dark-theme-input'
                    }
                    placeholder="Country Code + Phone Number"
                    value={this.state.value}
                    onChange={this.updateValue}
                    onKeyDown={this.onChangeNewProfileKeyPress}
                    autoFocus
                  />
                </td>
                <td>
                  <div id="new-confirm" className="btn-clear" onClick={this.addNewPhone} data-toggle="tooltip" data-placement="top" title="Save">
                    <i className="fa fa-check" aria-hidden="true" />
                  </div>
                  <div id="new-discard" className="btn-clear" onClick={this.onNewPhoneNumberCancel} data-toggle="tooltip" data-placement="top" title="Cancel">
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <div>
          {this.state.error && (this.props.new_profile.mode === 'add_number' || this.props.edit_profile.mode === 'edit_number') ? (
            <span className="general-error">Please enter a valid phone number or ensure the phone number does not already exist</span>
          ) : null}
          {!this.props.new_profile.active && !this.props.edit_profile.active ? (
            <div className="account-add" data-mode="add_number" onClick={this.props.onAddProfileItemClick}>
              Add <i className="fa fa-plus-circle" aria-hidden="true" data-mode="add_number" onClick={this.props.onAddProfileItemClick} />
            </div>
          ) : (
            <div className="account-add">
              <span className="table-add-decor" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AddTelephoneNumber;
