import React from 'react';
import PropTypes from 'prop-types';
import ProcessTracker from '../../../components/workflow/process_tracker';

function WireSelectionProcess(props) {
  const { wire_selection_stage } = props;
  return (
    <ProcessTracker
      {...props}
      process="wire_selection"
      hideRuler
      stage={wire_selection_stage}
      updateAssignedTo={props.updateAssignedTo}
      has_wsg={props.ws_files.filter((wire) => wire.file_type.includes('ws_guide') && wire.upload_data.includes(`/${props.current_rev}/`)).length > 0}
      ws_status_comment={props.ws_status_comment}
    />
  );
}

WireSelectionProcess.propTypes = {
  wire_selection_stage: PropTypes.string.isRequired,
};

export default WireSelectionProcess;
