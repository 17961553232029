import Axios from 'axios';

// Action Types
export const FETCH_TOUR_PENDING = 'FETCH_TOUR_PENDING';
export const FETCH_TOUR_SUCCESS = 'FETCH_TOUR_SUCCESS';
export const FETCH_TOUR_ERROR = 'FETCH_TOUR_ERROR';

export function fetchProductTour(tourSlug = 'wasm_viewer') {
  return (dispatch, getState) => {
    dispatch(fetchProductTourPending());
    Axios.get(`/apiV2/product_tour?tour_slug=${tourSlug}`)
      .then(function (res) {
        const productTour = res.data;
        dispatch(fetchTourSuccess(tourSlug, productTour.tour_completed, productTour.tour_phase));
      })
      .catch(function (err) {
        console.error('Error fetching product tour:', err);
        dispatch(fetchTourError(err));
      });
  };
}

export function updateProductTour(tourSlug, tourCompleted, tourPhase = 1) {
  return (dispatch, getState) => {
    dispatch(fetchProductTourPending());
    Axios.post(`/apiV2/product_tour`, {
      tour_slug: tourSlug,
      tour_completed: tourCompleted,
      tour_phase: tourPhase,
    })
      .then(function (res) {
        dispatch(fetchTourSuccess(tourSlug, tourCompleted, tourPhase));
        return res;
      })
      .catch(function (err) {
        console.error('Error updating product tour:', err);
        dispatch(fetchTourError(err));
      });
  };
}

export function fetchProductTourPending() {
  return {
    type: FETCH_TOUR_PENDING,
  };
}

export function fetchTourSuccess(tourSlug, tourCompleted, tourPhase = 1) {
  return {
    type: FETCH_TOUR_SUCCESS,
    tour_slug: tourSlug,
    tour_completed: tourCompleted,
    tour_phase: tourPhase,
  };
}

export function fetchTourError(error) {
  return {
    type: FETCH_TOUR_ERROR,
    error: error,
  };
}
