import React, { Component } from 'react';
import _ from 'lodash';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import { configLightBox } from '../../common/functions';
import Prop65Warning from '../layout/prop65_warning';
import Button from '../components/buttons/button';

class Terms extends Component {
  render() {
    const SummaryComponent = this.props.summaryComponent;
    let files = [];
    if (SummaryComponent && this.props.summaryProps) {
      const photoUpload = this.props.summaryProps.photoUpload || [];
      const xrayUpload = this.props.summaryProps.xrayUpload || [];

      // iTero integration
      const scans = [];
      if (this.props.summaryProps.uploadMethod === 'itero' && this.props.summaryProps.selectedIteroScanRecords.length) {
        scans.push(...this.props.summaryProps.selectedIteroScanRecords);
      } else if (this.props.summaryProps.scanUpload.length) {
        scans.push(...this.props.summaryProps.scanUpload);
      }

      files = _.concat(photoUpload, xrayUpload, scans);
    }
    return (
      <div className="case-form-container">
        <Prop65Warning />
        {SummaryComponent ? (
          <SummaryComponent
            {...this.props.summaryProps}
            thumbnailComponentProps={{
              files: files,
              images: configLightBox(files),
              multinail: `_${this.props.summaryProps.case_id}`,
              rebuildOnFilePropsChange: true,
              isInSubmitSummary: true,
            }}
          />
        ) : null}
        <div>
          <p className="dark-text">
            This information and approval constitutes your final and complete prescription to Swift Health Systems Inc. The diagnosis and prescription are the
            decision and sole responsibility of the doctor ordering this appliance who waives any and all claims against Swift and Employees of Swift based on
            the failure of InBrace to achieve a successful outcome, either alone or in combination with other appliances. Swift reserves the right to refuse any
            treatment prescription. All treatments submitted to Swift are governed by the details listed in the Customer Agreement that are in effect at the
            time of this treatment submission.
          </p>
          <p className="dark-text">By clicking "I Agree," I confirm I have understood the Customer Agreement.</p>
          <div className="inline-ele">
            <input id="terms" type="checkbox" name="remember" onClick={this.props.onButtonClick} />
            <span className="inbrace-text-default checkbox-padding-l">
              I agree to the{' '}
              <span className="inbrace-text-primary">
                <a className="midnight" href={`/portal/agreement`} target="_blank" rel="noopener noreferrer">
                  Customer Agreement
                </a>
              </span>
            </span>
          </div>
        </div>
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            const canSubmit = userHasPermission('IPP_CASE_SUBMIT', user_roles_and_permissions.permissions) && this.props.agreement && !this.props.disabledEdit;
            return (
              <Button data-position="submit" data-description="Submit" onClick={this.props.onSubmitClick} disabled={!canSubmit}>
                Submit
              </Button>
            );
          }}
        </UserPermissionsContext.Consumer>
      </div>
    );
  }
}

export default Terms;
