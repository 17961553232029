import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Box, TextField } from '@material-ui/core';

import { setManualWireLength, setTeethError, removeTeethError, saveDraft } from '../../../../../../redux/actions/manual_wire/manual_wire';
import { TEETHS } from '../../manual_wire_utils';
import { isNumber, getOnly2DecimalPlaces } from '../../manual_wire_utils'
  
const ManualWireTableLengthValue = ({
  wireLength,
  defaultWireLength,
  teethLabel,
  disabled,
  error,
  errorMessage,
  setWireLength,
  setTeethError,
  removeTeethError,
  arch,
  index,
  disableAllFields,
  generatedLength,
  saveDraft,
}) => {
  const onWireLengthBlur = (event) => {
    let textFieldValue = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');

    if (!isNumber(textFieldValue)) {
      textFieldValue = '0'
    }

    const parsedValue = parseFloat(textFieldValue).toFixed(2);
    saveDraft(setWireLength, {length: parsedValue, arch, teethLabel});
  };

  const onWireLengthChange = (event) => {
    let wireLengthValue = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');

    if (isNumber(wireLengthValue)) {
      wireLengthValue = getOnly2DecimalPlaces(wireLengthValue);
      saveDraft(setWireLength, {length: wireLengthValue, arch, teethLabel});
    } else{
      setWireLength({length: wireLengthValue, arch, teethLabel});
    }
  };

  useEffect(() => {
    if (generatedLength) return;
    const parsedValue = isNumber(wireLength) ? parseFloat(wireLength) : null;

    const rangeErrorMessage = `Error on teeth ${teethLabel}: Length value must be between 0 and 50.`;
    const teethErrorMessage = `Error on teeth ${teethLabel}: Length value must be positive and not 0.`;
    const blankErrorMessage = `Error on teeth ${teethLabel}: Length value can't be blank`;

    let newErrorMessage = [...errorMessage];

    newErrorMessage = newErrorMessage.filter((msg) => 
      msg !== rangeErrorMessage && msg !== teethErrorMessage && msg !== blankErrorMessage
    );
    if (parsedValue > 50) newErrorMessage.push(rangeErrorMessage);
    if (parsedValue <= 0 && wireLength !== '') newErrorMessage.push(teethErrorMessage);
    if (wireLength === '') newErrorMessage.push(blankErrorMessage);

    const removedElements = errorMessage.filter(item => !newErrorMessage.includes(item));
    const addedElements = newErrorMessage.filter(item => !errorMessage.includes(item));

    if (addedElements.length) setTeethError(addedElements, teethLabel);
    if (removedElements.length) removeTeethError(removedElements, teethLabel);
  }, [wireLength]);

  const showlabel = !generatedLength && defaultWireLength != wireLength;
  const label = showlabel ? `${defaultWireLength}` : '';
  return (
    <Box
      className={error ? 'manual-wire-table-length-element error' : 'manual-wire-table-length-element'}
      key={`manual-wire-table-length-element-${index}`}
      component="div"
    >
      <TextField
        type="text"
        value={wireLength}
        onChange={onWireLengthChange}
        onBlur={onWireLengthBlur}
        placeholder={disabled ? '' : '0.00'}
        InputProps={{
          disableUnderline: true,
          classes: { input: disabled ? 'input-number disabled' : 'input-number' },
        }}
        disabled={disabled || disableAllFields}
        label={label}
      />
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const teethLabel = TEETHS[ownProps.arch][ownProps.index - 1];
  const selectedWire = state.manualWireReducer.wire;
  const defaultWireLength = state.manualWireReducer.defaultCaseData[teethLabel]?.length[selectedWire];
  const wireLength = ownProps.generatedLength ? 
    state.manualWireReducer[ownProps.arch][teethLabel].generatedLength[selectedWire] :
    state.manualWireReducer[ownProps.arch][teethLabel].length[selectedWire];
  const disabled = state.manualWireReducer[ownProps.arch][teethLabel].disabled;
  const error = state.manualWireReducer[ownProps.arch][teethLabel].error;
  const errorMessage = state.manualWireReducer.errorMessage;

  return {
    wireLength,
    defaultWireLength,
    selectedWire,
    teethLabel,
    disabled,
    error,
    errorMessage,
  };
};

const mapDispatchToProps = {
  setWireLength: setManualWireLength,
  setTeethError: setTeethError,
  removeTeethError: removeTeethError,
  saveDraft: saveDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireTableLengthValue);
