import React from 'react';
import PropTypes from 'prop-types';

import Button from '../extras/button';
import { removeCaseIdInitialNumber } from '../../../../common/functions';

/**
 * Contains the approval dialog for the transition to new wasm viewer
 * @component
 * @alias NewViewerConfirmation
 */
export default function NewViewerConfirmation(props) {
  return (
    <div id="new-viewer-confirmation" className="approval-confirmation-container">
      <div className="modal-title">New Setup Viewer</div>
      <div className="modal-body-paragraph">
        You are entering the new Setup Viewer. Please note that this will permanently switch case {removeCaseIdInitialNumber(props.caseId)} to the new viewer.
        Please click <span className="bold">Confirm</span> to proceed.
      </div>
      <div className="modal-button-group">
        <Button theme="success" onClick={props.onConfirm}>
          Confirm
        </Button>
        <Button theme="secondary" onClick={props.onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

NewViewerConfirmation.propTypes = {
  caseId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
