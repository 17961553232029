import React from 'react';
import Logo from './logo';
import PatientInfo from './patient_info/patient_info';
import HistoryList from './history_list/history_list';
import MenuAccordianBtn from './menu_accordian_btn';
import SplitBtn from './split_btn';

/**
 * The container of the side bar known as the panel menu
 * @component
 * @alias SetupViewerPanelMenu
 */
export default function PanelMenu(props) {
  return (
    <div className={props.collapse ? 'panel-menu--dark collapse' : 'panel-menu--dark'}>
      <div className="panel-menu-surrounding">
        <Logo className="panel-menu-logo" collapse={props.collapse} onClick={props.onLogoClick} />
        <PatientInfo collapse={props.collapse} />
      </div>
      <HistoryList collapse={props.collapse} history_list={props.history_list} split={props.split} onScrollOptionClick={props.onScrollOptionClick} />
      <MenuAccordianBtn collapse={props.collapse} toggleCollapse={props.toggleCollapse} />
      <SplitBtn split={props.split} toggleSplit={props.toggleSplit} />
    </div>
  );
}
