/**
 * File: add_email.js - A component that is apart of the add account process
 * which is used for constructing the email information
 * Copyright: (c) Copyright June 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import React, { Component } from 'react';

// Internal Functions
import ValidationUtils from '../../common/validation/validation-utils';
import { truncateTextByLimit } from '../../common/helpers';
import { removeEmoji } from '../../common/functions';

class AddEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      value: '',
      type: 'Office',
      notification: true,
    };
    this.onNewProfileCancel = this.onNewProfileCancel.bind(this);
  }

  onNewProfileCancel = (event) => {
    this.setState({
      error: false,
      value: '',
      type: 'Office',
      notification: true,
    });
    this.props.onNewProfileCancel();
  };

  editEmail = () => {
    document.getElementById('editModal').modal({ show: true });
  };

  onChangeNewProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('new-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('new-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeEditProfileKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeEditEmail = (event) => {
    this.props.onChangeEditEmail(event.target.dataset.id, removeEmoji(event.target.value));
  };

  onChangeEditEmailType = (event) => {
    this.props.onChangeEditEmailType(event.target.dataset.id, event.target.value);
  };

  onChangeEditEmailNotification = (event) => {
    this.props.onChangeEditEmailNotification(event.target.dataset.id, event.target.checked);
  };

  onEditClick = (event) => {
    if (!this.props.new_profile.active && this.props.new_profile.mode !== 'add_email') {
      this.props.setSelectedEmail(event.target.dataset.value, event.target.dataset.type, event.target.dataset.notification, event.target.dataset.id);
    }
  };

  updateValue = (event) => {
    event.target.value = removeEmoji(event.target.value.trim());
    this.setState({ value: event.target.value });
  };

  updateType = (event) => {
    this.setState({ type: event.target.value });
  };

  updateNotification = (event) => {
    this.setState({ notification: event.target.checked });
  };

  handleDeleteEmail = () => {
    this.props.onDeleteEmail(this.props.edit_profile.value, this.props.edit_profile.id);
  };

  addNewEmail = () => {
    if (this.state.value === '') {
      this.setState({ error: true });
      return null;
    }
    var emailList = this.props.emailList;
    var valid = true;
    try {
      var email_out = this.state.value.trim();
      for (var i = 0; i < emailList.length; i++) {
        if (emailList[i].email === email_out) {
          valid = false;
        }
      }
    } catch (error) {
      this.setState({ error: true });
    }
    if (ValidationUtils.isValidEmail(this.state.value) && valid === true && this.isNewEmail(this.state.value)) {
      try {
        this.props.onAddNewEmail(this.state.type, this.state.value, this.state.notification);
        this.setState({ error: false, value: '' });
      } catch (error) {
        this.setState({ error: true });
      }
    } else {
      this.setState({ error: true });
    }
  };

  isNewEmail(value, id = '') {
    const emails = this.props.emailList;
    let is_new = true;

    for (let i = 0; i < emails.length; i++) {
      if (emails[i].email === value) {
        if (id && parseInt(id) === i) {
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  onUpdateEmail = () => {
    if (this.props.edit_profile.value === '') {
      this.setState({ error: true });
      return null;
    }

    var valid = true;

    if (ValidationUtils.isValidEmail(this.props.edit_profile.value) && valid === true && this.isNewEmail(this.props.edit_profile.value, this.props.edit_profile.id)) {
      try {
        this.props.onUpdateEmail(this.props.edit_profile.value, this.props.edit_profile.type, this.props.edit_profile.notification, this.props.edit_profile.id);
        this.setState({ error: false, value: '' });
      } catch (error) {
        this.setState({ error: true });
      }
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    if (this.props.emailList === null) return null;
    let emailList = this.props.emailList;
    return (
      <div className="col-lg-6">
        <div className="account-heading">Emails</div>
        <table className="table table-condensed table-industry account-table">
          <thead className="table-inbrace-dark-industry-2">
            <tr>
              <th className="type-width-tb">Type</th>
              <th className="auto-width-tb">Email</th>
              <th className="notify-width-tb">Notifications</th>
              <th className="edit-width-tb" />
            </tr>
          </thead>
          <tbody className="table-inbrace-dark-industry-2">
            {emailList.length > 0 ? (
              emailList.map((email, index) => {
                return (
                  <tr className="pointer" key={index}>
                    {parseInt(this.props.edit_profile.id) === index && this.props.edit_profile.mode === 'edit_email' ? (
                      <td className="account-table-start type-width-tb">
                        <select className="select-filter" data-id={index} value={this.props.edit_profile.type} onChange={this.onChangeEditEmailType}>
                          <option value="Office">Office</option>
                          <option value="Personal">Personal</option>
                        </select>
                      </td>
                    ) : (
                      <td
                        className="account-table-start type-width-tb account-table-first"
                        data-id={index}
                        data-value={email.email}
                        data-type={email.type}
                        data-mode="edit_email"
                        data-notification={email.notification}
                        onClick={this.onEditClick}
                      >
                        {email.type}
                      </td>
                    )}

                    {parseInt(this.props.edit_profile.id) === index &&
                    this.props.edit_profile.mode === 'edit_email' &&
                    this.props.edit_profile.value !== this.props.accountEmail ? (
                      <td className="auto-width-tb">
                        <input
                          data-id={index}
                          name="email"
                          className={
                            this.state.error && this.props.edit_profile.mode === 'edit_email'
                              ? 'form-control business-dark-theme-input input-error'
                              : 'form-control business-dark-theme-input'
                          }
                          value={this.props.edit_profile.value}
                          maxLength="100"
                          onChange={this.onChangeEditEmail}
                          onKeyDown={this.onChangeEditProfileKeyPress}
                          autoFocus
                        />
                      </td>
                    ) : (
                      <td
                        className="auto-width-tb fs-exclude"
                        data-id={index}
                        data-value={email.email}
                        data-type={email.type}
                        data-mode="edit_email"
                        data-notification={email.notification}
                        onClick={this.onEditClick}
                      >
                        {truncateTextByLimit(email.email, 50)}
                      </td>
                    )}

                    {parseInt(this.props.edit_profile.id) === index && this.props.edit_profile.mode === 'edit_email' ? (
                      <td className="account-table-end text-center">
                        <input
                          data-id={index}
                          className="checkbox-button"
                          type="checkbox"
                          name="notification"
                          value="notify"
                          checked={this.props.edit_profile.notification}
                          onChange={this.onChangeEditEmailNotification}
                        />
                      </td>
                    ) : (
                      <td
                        className="account-table-end text-center"
                        data-id={index}
                        data-value={email.email}
                        data-type={email.type}
                        data-mode="edit_email"
                        data-notification={email.notification}
                        onClick={this.onEditClick}
                      >
                        {email.notification ? <i className="fa fa-check" aria-hidden="true" /> : null}
                      </td>
                    )}
                    {parseInt(this.props.edit_profile.id) === index && this.props.edit_profile.mode === 'edit_email' ? (
                      <td>
                        <div id="edit-confirm" className="btn-clear" onClick={this.onUpdateEmail} data-toggle="tooltip" data-placement="top" title="Save">
                          <i className="fa fa-check" aria-hidden="true" />
                        </div>
                        <div
                          id="edit-discard"
                          className="btn-clear"
                          onClick={this.props.onEditProfileCancel}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Cancel"
                        >
                          <i className="fa fa-times" aria-hidden="true" />
                        </div>
                        {email.email.toLowerCase() !== this.props.accountEmail.toLowerCase() && (
                          <div className="btn-clear" onClick={this.handleDeleteEmail} data-toggle="tooltip" data-placement="top" title="Delete">
                            <i className="fa fa-trash" aria-hidden="true" />
                          </div>
                        )}
                      </td>
                    ) : (
                      <td className="edit-width-tb" />
                    )}
                  </tr>
                );
              })
            ) : this.props.new_profile.active && this.props.new_profile.mode === 'add_email' ? null : (
              <tr>
                <td className="td-industry-offset auto-width-tb account-table account-table-round" colSpan="3">
                  - - - - - No Notification Email Found - - - - -
                </td>
                <td />
              </tr>
            )}

            {this.props.new_profile.active && this.props.new_profile.mode === 'add_email' ? (
              <tr>
                <td className="type-width-tb account-table-start">
                  <select className="select-filter" value={this.state.type} onChange={this.updateType}>
                    <option value="Office">Office</option>
                    <option value="Personal">Personal</option>
                  </select>
                </td>
                <td>
                  <input
                    name="email"
                    className={
                      this.state.error && this.props.new_profile.mode === 'add_email'
                        ? 'form-control business-dark-theme-input input-error'
                        : 'form-control business-dark-theme-input'
                    }
                    value={this.state.value}
                    onChange={this.updateValue}
                    maxLength="100"
                    onKeyDown={this.onChangeNewProfileKeyPress}
                    autoFocus
                  />
                </td>

                <td className="text-center account-table-end">
                  <input
                    className="checkbox-button"
                    type="checkbox"
                    name="notification"
                    value="notify"
                    checked={this.state.notification}
                    onChange={this.updateNotification}
                  />
                </td>
                <td>
                  <div id="new-confirm" className="btn-clear" data-toggle="tooltip" data-placement="top" title="Save" onClick={this.addNewEmail}>
                    <i className="fa fa-check" aria-hidden="true" />
                  </div>
                  <div id="new-discard" className="btn-clear" data-toggle="tooltip" data-placement="top" title="Cancel" onClick={this.onNewProfileCancel}>
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
        <div>
          {this.state.error && (this.props.new_profile.mode === 'add_email' || this.props.edit_profile.mode === 'edit_email') ? (
            <span className="general-error">Please enter a valid email address or ensure the email address does not already exist</span>
          ) : null}
          {!this.props.new_profile.active && !this.props.edit_profile.active ? (
            <div className="account-add" data-mode="add_email" onClick={this.props.onAddProfileItemClick}>
              Add <i className="fa fa-plus-circle" aria-hidden="true" data-mode="add_email" onClick={this.props.onAddProfileItemClick} />
            </div>
          ) : (
            <div className="account-add" />
          )}
        </div>
      </div>
    );
  }
}

export default AddEmail;
