import _ from 'lodash';
import React, { Component } from 'react';
import IpUpload from './automate_wirebending/ip_upload';
import IpConversion from './automate_wirebending/ip_conversion';
import IpReview from './automate_wirebending/ip_review';
import IpOverride from './automate_wirebending/ip_override';
import IpFinal from './automate_wirebending/ip_final';

/**
 * Used to perform production steps during case manufacturing process
 * @component
 * @alias AutomatedWireBending
 * @category BPP
 */
class AutomatedWireBending extends Component {
  /**
   * Displays different automated wirebending process steps
   * @function
   * @return {JSX} returns component based on status
   */
  renderAutomatedWireBendingProcessStep = () => {
    let last_ip_status = this.props.last_ip_status;
    switch (last_ip_status) {
      case '':
      case 'ip_upload':
        return (
          <IpUpload
            ip_files={this.props.ip_files}
            ip_process={this.props.cases.ip_process}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            stage={last_ip_status}
            getIPCaseFilePath={this.props.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this.props.project}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            reload_information={this.props.reload_information}
            editable_status_comment={this.props.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.cases.treatment_arch.toLowerCase()}
            onOverrideProcess={this.props.onOverrideProcess}
            onIpUpload={this.props.onIpUpload}
          />
        );

      case 'ip_conversion':
      case 'ip_conversion_failed':
        return (
          <IpConversion
            ip_files={this.props.ip_files}
            ip_process={this.props.cases.ip_process}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            stage={last_ip_status}
            getIPCaseFilePath={this.props.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this.props.project}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            reload_information={this.props.reload_information}
            editable_status_comment={this.props.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.cases.treatment_arch}
          />
        );

      case 'ip_review':
        return (
          <IpReview
            ip_files={this.props.ip_files}
            ip_process={this.props.cases.ip_process}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            stage={last_ip_status}
            getIPCaseFilePath={this.props.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this.props.project}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            reload_information={this.props.reload_information}
            editable_status_comment={this.props.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.cases.treatment_arch}
            patient_info={this.props.patient_info}
          />
        );
      case 'ip_none':
        return (
          <IpOverride
            ip_files={this.props.ip_files}
            ip_process={this.props.cases.ip_process}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            stage={last_ip_status}
            getIPCaseFilePath={this.props.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this.props.project}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            reload_information={this.props.reload_information}
            editable_status_comment={this.props.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.cases.treatment_arch}
          />
        );
      case 'ip_files':
        const ip_loop_file = this.props.ip_loop_file;
        const recipe_file = this.props.recipe_file;
        const ip_loop_measurement_file = this.props.ip_loop_measurement_file;
        const has_status = _.isEmpty(this.props.inprocess_ipstatus);
        return (
          <IpFinal
            ip_files={this.props.ip_files}
            ip_process={this.props.cases.ip_process}
            case_status={this.props.cases.status_code}
            setConversion={this.props.setConversion}
            stage={last_ip_status}
            getIPCaseFilePath={this.props.getIPCaseFilePath}
            case_id={this.props.case_id}
            project={this.props.project}
            user_roles_and_permissions={this.props.user_roles_and_permissions}
            reload_information={this.props.reload_information}
            editable_status_comment={this.props.editable_status_comment}
            isUploading={this.props.isUploading}
            txplan_arch={this.props.cases.treatment_arch}
            onRewokModalClick={this.props.onRewokModalClick}
            ip_loop_file={ip_loop_file}
            recipe_file={recipe_file}
            ip_loop_measurement_file={ip_loop_measurement_file}
            has_status={has_status}
            selections={this.props.selections}
            ref_no={this.props.ref_no}
            get_case_status={this.props.get_case_status}
            inprocess_ipstatus={this.props.inprocess_ipstatus}
            onIPLoopMeasurementModalClick={this.props.onIPLoopMeasurementModalClick}
          />
        );

      default:
    }
  };

  render() {
    return this.renderAutomatedWireBendingProcessStep();
  }
}
export default AutomatedWireBending;
