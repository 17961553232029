import './internal_privacy.scss';
import Axios from 'axios';
import { LogoWhite } from '../../doctor/assets/logos';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getCurrentYear } from '../../common/functions';
import { getDoctorsIdFromRoute } from '../../common/route';

const Privacy = (props) => {
  const [role, setRole] = useState('');
  const [id, setId] = useState('');

  useEffect(() => {
    Axios.get(`/apiV2/account_light`).then(function (res) {
      setRole(res.data.role.toLowerCase());
      setId(res.data.account_id);
    });
  });

  const doctor_id = getDoctorsIdFromRoute() ? getDoctorsIdFromRoute() : id;

  return (
    <div className="footer">
      <div className="collection_1 footer_collections ">
        <Link to={role === 'business' ? '/business/portal/cases' : { pathname: `/portal/${doctor_id}/dashboard` }}>
          <LogoWhite className="footer_logo" />
        </Link>
        <p className="right">©{`${getCurrentYear()}`} Swift Health Systems, Inc. All rights reserved.</p>
      </div>
      <div className=" collection_2 footer_collections ">
        <a className="footer_item" href="https://www.inbrace.com/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <a className="footer_item" href="https://www.inbrace.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        <Link className="footer_item" to="/portal/knowledgebase/policies_%26_agreements" target="_blank">
          Policies & Agreements
        </Link>
      </div>
      <div className="collection_3 footer_collections ">
        <p className="footer_item header">Support</p>
        <a className="footer_item" href="tel:877-258-8677" target="_blank" rel="noopener noreferrer">
          (877) 258-8677
        </a>
        <a className="footer_item" href="mailto:Support@inbrace.com" target="_blank" rel="noopener noreferrer">
          support@inbrace.com
        </a>
      </div>
    </div>
  );
};

export default Privacy;
