import {
  SET_PRODUCTION_TX_GUIDE,
  SET_SELECTED_PRODUCTION_TX_GUIDE_TAB,
  SET_PRODUCTION_TX_GUIDE_TAB_CONFIG,
} from '../../../actions/bpp/production_tx_guide/production_tx_guide';

const initialState = {
  production_tx_guide: null,
  selected_production_tx_guide_tab: 'treatment_plan',
  allowed_production_tx_guide_tabs: [],
  production_tx_guide_modal: false,
  production_tx_guide_file_path: '',
  loading_modal: false,
  is_after_3420_launch: true,
  gen_2: false,
};

/**
 * This reducer is used for the production tx guide form used in various stages on BPP
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function productionTxGuideReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTION_TX_GUIDE:
      return {
        ...state,
        production_tx_guide: action.production_tx_guide,
        is_after_3420_launch: action.is_after_3420_launch,
        gen_2: action.gen_2,
      };

    case SET_SELECTED_PRODUCTION_TX_GUIDE_TAB:
      return {
        ...state,
        selected_production_tx_guide_tab: action.selected_production_tx_guide_tab,
      };

    case SET_PRODUCTION_TX_GUIDE_TAB_CONFIG:
      return {
        ...state,
        selected_production_tx_guide_tab: action.selected_production_tx_guide_tab,
        allowed_production_tx_guide_tabs: action.allowed_production_tx_guide_tabs,
      };

    default:
      return state;
  }
}

export const getProductionTxGuide = (state) => state.productionTxGuideReducer.production_tx_guide;
export const getSelectedProductionTxGuideTab = (state) => state.productionTxGuideReducer.selected_production_tx_guide_tab;
export const getAllowedProductionTxGuideTabs = (state) => state.productionTxGuideReducer.allowed_production_tx_guide_tabs;
export const getIsAfter3420Launch = (state) => state.productionTxGuideReducer.is_after_3420_launch;
