import React from 'react';
import Modal from '../../components/modal/modal';

const EmptyFileErrorModal = (props) => {
  const header_text = 'Error: Unable to upload STL Files';
  const message_text = (
    <div>
      The current STL files contain 0 data.
      <br />
      <br />
      Please export the STL files again, ensuring the STL download is complete <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>before</span> uploading
      them to the InBrace Portal. <br />
      <br />
      If you need assistance, please visit Knowledgebase and select{' '}
      <a className="midnight" target="_blank" rel="noopener noreferrer" href="/portal/knowledgebase/software_&_scanner_guides">
        "Software and Scanner Guides"
      </a>{' '}
      or reach out to InBrace Concierge{' '}
      <a className="mail" href="tel:877-258-8677">
        877.258.8677
      </a>
    </div>
  );

  return (
    <Modal
      theme={props.theme}
      preset="action"
      header_text={header_text}
      message_text={message_text}
      confirm_btn_text="Close"
      onCloseButtonClick={props.onEmptyFileErrorDismiss}
      onConfirmButtonClick={props.onEmptyFileErrorDismiss}
    />
  );
};

export default EmptyFileErrorModal;
