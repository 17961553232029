import * as THREE from 'three';

import { getScreenScale } from '../common/functions';

const line_width = 0.1;
const line_width_bold = 0.15;
const line_width_origin = 0.2;
const bold_every_x = 5;

const mat = new THREE.MeshBasicMaterial({
  color: '#185e5e',
  transparent: true,
  opacity: 0.3,
});
const mat_bold = new THREE.MeshBasicMaterial({
  color: '#0d1616',
  transparent: true,
  opacity: 0.35,
});
const mat_origin = new THREE.MeshBasicMaterial({
  color: '#000000',
  transparent: true,
  opacity: 0.4,
});

/**
 * Class representing the grid in setup viewer
 */
class Grid {
  constructor(camera, renderer) {
    this.grid = new THREE.Group();
    this.size = 1;
    this.camera = camera;
    this.renderer = renderer;
    this.grid.visible = false;
  }

  /**
   * Draws the entire grid
   * @function
   * @param {Number} pane - The pane of the viewer
   */
  draw = (pane) => {
    this.clear();
    const shape = new THREE.ShapePath();
    const shape_five = new THREE.ShapePath();
    const shape_origin = new THREE.ShapePath();
    const tsz = getScreenScale(this.camera, this.renderer);
    const zLevel = Math.floor(Math.log10(tsz));
    const szFloor = Math.pow(10, zLevel);
    const sz = Grid.computeGridSize(szFloor, tsz);
    const lw2Sm = (line_width / 2) * tsz;
    const lw2Md = (line_width_bold / 2) * tsz;
    const lw2Lg = (line_width_origin / 2) * tsz;
    const margin = 2;
    const xM = (this.camera.left + this.camera.right) / 2;
    const yM = (this.camera.top + this.camera.bottom) / 2;
    const xCnt = Math.ceil((this.camera.right - this.camera.left + 2 * margin) / (sz * 2 * this.camera.zoom));
    const yCnt = Math.ceil((this.camera.top - this.camera.bottom + 2 * margin) / (sz * 2 * this.camera.zoom));

    const center = new THREE.Vector3();
    const z = center.z - 200 / 4;

    const y0 = yM - yCnt * sz;
    const yF = 2 * yM - y0;
    for (let i = -xCnt; i <= xCnt; i++) {
      const lw = i === 0 ? lw2Lg : i % bold_every_x === 0 ? lw2Md : lw2Sm;
      const shp = i === 0 ? shape_origin : i % bold_every_x === 0 ? shape_five : shape;
      const x = xM + i * sz;
      shp.moveTo(x - lw, y0, z);
      shp.lineTo(x + lw, y0, z);
      shp.lineTo(x + lw, yF, z);
      shp.lineTo(x - lw, yF, z);
    }
    const x0 = xM - xCnt * sz;
    const xF = 2 * xM - x0;
    for (let i = -yCnt; i <= yCnt; i++) {
      const lw = i === 0 ? lw2Lg : i % bold_every_x === 0 ? lw2Md : lw2Sm;
      const shp = i === 0 ? shape_origin : i % bold_every_x === 0 ? shape_five : shape;
      const y = yM + i * sz;
      shp.moveTo(x0, y - lw, z);
      shp.lineTo(xF, y - lw, z);
      shp.lineTo(xF, y + lw, z);
      shp.lineTo(x0, y + lw, z);
    }

    const createGrid = (shapes, mat) => {
      const geo = new THREE.ShapeGeometry(shapes.toShapes());
      const mesh = new THREE.Mesh(geo, mat);
      this.grid.add(mesh);
    };
    createGrid(shape, mat);
    createGrid(shape_five, mat_bold);
    createGrid(shape_origin, mat_origin);

    const camOffset = new THREE.Vector3(0, 0, -10).applyQuaternion(this.camera.quaternion);

    this.grid.position.copy(this.camera.position.clone().add(camOffset));
    this.grid.quaternion.copy(this.camera.quaternion);

    if (this.size !== sz && pane) {
      this.size = sz;
      this.updateGridSize(pane);
    }
  };

  /**
   * Updates the grid size
   * @function
   * @param {Number} pane - The pane of the viewer
   */
  updateGridSize(pane) {
    const el = window.$(`#grid-size-${pane}`);
    if (el) {
      el.html(`${this.size} mm`);
    }
  }

  /**
   * Adds the grid to the scene
   * @function
   * @param {THREE.Object3D} scene - The scene to contain the grid
   */
  addToScene(scene) {
    this.draw();
    scene.add(this.grid);
  }

  /**
   * Removes the grid from the scene
   * @function
   * @param {THREE.Object3D} scene - The scene that contains the grid
   */
  removeFromScene(scene) {
    scene.remove(this.grid);
  }

  /**
   * Clears the grid
   * @function
   */
  clear() {
    this.grid.remove(...this.grid.children);
  }

  /**
   * Set the visibility of the grid
   * @function
   * @param {Boolean} show - To show or not show
   */
  show(show) {
    this.draw();
    this.grid.visible = show;
  }

  /**
   * Computes the grid size
   * @function
   * @param {Number} szFloor - The minimum grid size
   * @param {Number} tsz - The screen scale
   */
  static computeGridSize(szFloor, tsz) {
    let sz = szFloor;
    [1, 2, 5, 10].some(function (x) {
      sz = szFloor * x;
      return sz > tsz;
    });
    return sz;
  }
}

export { Grid };
