import './case_list.scss';

import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Axios from 'axios';

import CustomerAgreement from '../customer_agreement/customer_agreement';
import Content from './content';
import NotFound from '../404/not_found';
import IRContent from '../ir_list/ir_content';
import ProspectList from '../prospect_list/prospect_list';
import SimulationList from '../smile_simulation_list/ss_list';

import { setTokenHeader } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { getDoctorsIdFromRoute, getFilterValueFromRoute, getSelectionValueFromRoute } from '../../common/route';

//Redux
import { setDoctorInformation } from '../../redux/actions/ipp/case_list';
import { fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';
import CircleLoader from '../../components/loader/circle_loader';

/**
 * Used to displayed all case in a list format on the IPP.
 *
 * The case list page is where the users can click on a particular case to
 * finished a draft or get details on a case. This list is searchable and is now
 * adapted for DSO's, which means doctors within the DSO can view and some roles
 * are allowed to action events on behalf of other doctors.
 * @component
 * @alias DoctorCaseList
 * @category IPP
 */
class CaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      doctor_role: '',
      section: null,
      error: false,
      loading: true,
      terms: false,
      filter: '',
      selection: '',
    };
  }
  /**
   * Check is keys are allowed in URL
   * @function
   * @param {dict} options - selected option
   */
  hasAllowedURLKeys = () => {
    const allowed_keys = ['search', 'filter', 'status_filter'];
    const searchParams = new URLSearchParams(window.location.search);
    let allowed = true;

    for (var key of searchParams.keys()) {
      if (_.intersection(allowed_keys, [key]).length === 0) {
        allowed = false;
        break;
      }
    }

    return allowed;
  };

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    const filter = getFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();

    // TODO:: fix for future
    // const bad_filter_value = SDSFilterToTitle(filter) === filter && filter !== '';

    // if (bad_filter_value || !this.hasAllowedURLKeys()){
    //   that.setState({ error: true });
    //   return;
    // }

    setTimeout(function () {
      this.$('#affixSideBar').affix({
        offset: {
          top: 150,
        },
      });
    }, 0);

    // updates navbar case count
    this.props.fetchLoggedDoctorCaseCount({ doctorId: doctor_id });

    // Get own doctor account info
    Axios.get(`/apiv3/doctor`)
      .then(function (res) {
        // Get doctor's account info (using id from url)
        Axios.get(`/apiv3/doctor/${doctor_id}`)
          .then(function (doctor_res) {
            if (res.data.doctor[0].id.toString() === doctor_id) {
              that.props.setDoctorInformation(res.data.doctor[0].role, res.data.doctor[0].account_link_id);
            } else {
              if (
                doctor_res.data.doctor[0].user__is_active &&
                (doctor_res.data.doctor[0].account_link_id === res.data.doctor[0].id ||
                  doctor_res.data.doctor[0].account_link_id === res.data.doctor[0].account_link_id)
              ) {
                if (res.data.doctor[0].account_link_id.toString() === getDoctorsIdFromRoute()) {
                  that.props.history.push(`/portal/${res.data.doctor[0].id}?selection=all_cases`);
                } else {
                  that.props.history.push(`/portal/${res.data.doctor[0].id}?selection=doctor_${getDoctorsIdFromRoute()}`);
                }
                window.location.reload();
              } else {
                Axios.get(`/apiV2/authorization/${doctor_id}`)
                  .then(function (auth_res) {
                    that.setState({
                      doctor_id: doctor_id,
                      doctor_first_name: auth_res.data.first_name,
                      doctor_last_name: auth_res.data.last_name,
                    });
                  })
                  .catch(function (err) {
                    handleHttpRequestError(err, that, true, res.data.doctor[0].role);
                  });

                that.props.setDoctorInformation(doctor_res.data.doctor[0].role, doctor_res.data.doctor[0].account_link_id);
              }
            }

            if (filter && filter === 'item_request') {
              that.setState({
                section: <IRContent />,
                filter: filter,
                selection: selection,
              });
            } else if (filter && filter === 'prospect_list') {
              that.setState({
                section: <ProspectList />,
                filter: filter,
                selection: selection,
              });
            } else if (filter && filter === 'smile_simulation') {
              Axios.get(`/apiV2/account_light/${doctor_id}`).then(function (doctor_res) {
                if (
                  doctor_res.data &&
                  doctor_res.data.permission_roles &&
                  (_.intersection(['Doctor-SmileSimulation'], doctor_res.data.permission_roles).length > 0 || doctor_res.data.smile_simulation_count > 0)
                ) {
                  that.setState({
                    section: <SimulationList />,
                    filter: filter,
                    selection: selection,
                  });
                } else {
                  that.setState({
                    error: true,
                  });
                }
              });
            } else {
              that.setState({
                section: <Content />,
                filter: filter,
              });
            }
          })
          .catch(function (err) {
            handleHttpRequestError(err, that, true);
          });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that, true);
      });

    Axios.get(`/apiV2/agreement`)
      .then(function (res) {
        that.setState({
          loading: false,
          filter: filter,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          terms: true,
          filter: filter,
        });
      });
  }

  componentDidUpdate() {
    const that = this;
    const filter = getFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();

    if ((filter && filter !== this.state.filter) || (selection && selection !== this.state.selection)) {
      if (filter === 'item_request') {
        this.setState({
          section: <IRContent />,
          filter: filter,
          selection: selection,
        });
      } else if (filter === 'prospect_list') {
        this.setState({
          section: <ProspectList />,
          filter: filter,
          selection: selection,
        });
      } else if (filter === 'smile_simulation') {
        this.setState({
          section: <SimulationList />,
          filter: filter,
          selection: selection,
        });
      } else {
        this.setState({
          section: <Content />,
          filter: filter,
          selection: selection,
        });
      }
    } else if (
      !filter &&
      this.state.filter &&
      (this.state.filter === 'item_request' || this.state.filter === 'prospect_list' || this.state.filter === 'smile_simulation')
    ) {
      this.setState({
        section: <Content />,
        filter: filter,
      });
    }

    if (!this.state.terms) {
      Axios.get(`/apiV2/agreement`).catch(function (err) {
        that.setState({
          terms: true,
        });
      });
    }
  }

  render() {
    if (this.state.error) {
      return <NotFound />;
    } else if (this.state.loading) {
      return <CircleLoader fullscreen={true} />;
    } else if (this.state.terms) {
      return <CustomerAgreement />;
    } else {
      return this.state.section;
    }
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      setDoctorInformation: setDoctorInformation,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(CaseList);
