import _ from 'lodash';
import Axios from 'axios';

// import * as nomenclature from '../../../common/nomenclature';

//Action Imports
import { fetchIfuState } from './ifu_state';

// Action Types
export const POST_CASE_FILE_PENDING = 'POST_CASE_FILE_PENDING';
export const POST_CASE_FILE_SUCCESS = 'POST_CASE_FILE_SUCCESS';
export const POST_CASE_FILE_ERROR = 'POST_CASE_FILE_ERROR';
export const PUT_UPPER_WIRES_PENDING = 'PUT_UPPER_WIRES_PENDING';
export const PUT_UPPER_WIRES_SUCCESS = 'PUT_UPPER_WIRES_SUCCESS';
export const PUT_UPPER_WIRES_ERROR = 'PUT_UPPER_WIRES_ERROR';
export const PUT_LOWER_WIRES_PENDING = 'PUT_LOWER_WIRES_PENDING';
export const PUT_LOWER_WIRES_SUCCESS = 'PUT_LOWER_WIRES_SUCCESS';
export const PUT_LOWER_WIRES_ERROR = 'PUT_LOWER_WIRES_ERROR';
export const PUT_BRACKET_PENDING = 'PUT_BRACKET_PENDING';
export const PUT_BRACKET_SUCCESS = 'PUT_BRACKET_SUCCESS';
export const PUT_BRACKET_ERROR = 'PUT_BRACKET_ERROR';
export const SET_WIRES = 'SET_WIRES';
export const SET_UPLOAD_OPTION = 'SET_UPLOAD_OPTION';
export const PUT_UPLOAD_OPTION_PENDING = 'PUT_UPLOAD_OPTION_PENDING';
export const PUT_UPLOAD_OPTION_SUCCESS = 'PUT_UPLOAD_OPTION_SUCCESS';
export const PUT_UPLOAD_OPTION_ERROR = 'PUT_UPLOAD_OPTION_ERROR';
export const OPEN_BRACKET_NOT_INCLUDED_MODAL = 'OPEN_BRACKET_NOT_INCLUDED_MODAL';
export const EDIT_BRACKET_NOT_INCLUDED_MODAL = 'EDIT_BRACKET_NOT_INCLUDED_MODAL';
export const CLOSE_BRACKET_NOT_INCLUDED_MODAL = 'CLOSE_BRACKET_NOT_INCLUDED_MODAL';
export const OPEN_BRACKET_COLLISIONS_MODAL = 'OPEN_BRACKET_COLLISIONS_MODAL';
export const EDIT_BRACKET_COLLISIONS_MODAL = 'EDIT_BRACKET_COLLISIONS_MODAL';
export const CLOSE_BRACKET_COLLISIONS_MODAL = 'CLOSE_BRACKET_COLLISIONS_MODAL';
export const OPEN_BRACKET_REPOSITIONED_MODAL = 'OPEN_BRACKET_REPOSITIONED_MODAL';
export const EDIT_BRACKET_REPOSITIONED_MODAL = 'EDIT_BRACKET_REPOSITIONED_MODAL';
export const CLOSE_BRACKET_REPOSITIONED_MODAL = 'CLOSE_BRACKET_REPOSITIONED_MODAL';
export const OPEN_BRACKET_NEW_MODAL = 'OPEN_BRACKET_NEW_MODAL';
export const EDIT_BRACKET_NEW_MODAL = 'EDIT_BRACKET_NEW_MODAL';
export const CLOSE_BRACKET_NEW_MODAL = 'CLOSE_BRACKET_NEW_MODAL';
export const OPEN_GENERATE_MODAL = 'OPEN_GENERATE_MODAL';
export const CLOSE_GENERATE_MODAL = 'CLOSE_GENERATE_MODAL';
export const GENERATE_PENDING = 'GENERATE_PENDING';
export const GENERATE_SUCCESS = 'GENERATE_SUCCESS';
export const GENERATE_ERROR = 'GENERATE_ERROR';
export const OPEN_OVERRIDE_MODAL = 'OPEN_OVERIDE_MODAL';
export const CLOSE_OVERRIDE_MODAL = 'CLOSE_OVERRIDE_MODAL';
export const OVERRIDE_PENDING = 'OVERRIDE_PENDING';
export const OVERRIDE_SUCCESS = 'OVERRIDE_SUCCESS';
export const OVERRIDE_ERROR = 'OVERRIDE_ERROR';
export const OPEN_REMOVE_IFU_UPLOAD_MODAL = 'OPEN_REMOVE_IFU_UPLOAD_MODAL';
export const CLOSE_REMOVE_IFU_UPLOAD_MODAL = 'CLOSE_REMOVE_IFU_UPLOAD_MODAL';
export const DELETE_IFU_UPLOAD_PENDING = 'DELETE_IFU_UPLOAD_PENDING';
export const DELETE_IFU_UPLOAD_SUCCESS = 'DELETE_IFU_UPLOAD_SUCCESS';
export const DELETE_IFU_UPLOAD_ERROR = 'DELETE_IFU_UPLOAD_ERROR';
export const SET_IFU_UPLOAD_PENDING = 'SET_IFU_UPLOAD_PENDING';
export const SET_IFU_UPLOAD_FINISHED = 'SET_IFU_UPLOAD_FINISHED';
export const SET_IFU_INCORRECT_FILE_NAME_ERROR = 'SET_IFU_INCORRECT_FILE_NAME_ERROR';
export const SET_IFU_FILE_NAME = 'SET_IFU_FILE_NAME';
export const CLEAR_IFU_UPLOAD_PENDING = 'CLEAR_IFU_UPLOAD_PENDING';
export const SET_IFU_FILE_TYPE_ERROR = 'SET_IFU_FILE_TYPE_ERROR';
export const CLEAR_IFU_FILE_TYPE_ERROR = 'CLEAR_IFU_FILE_TYPE_ERROR';
export const SET_IFU_FOLDER = 'SET_IFU_FOLDER';
export const SET_IFU_FILE_EXT = 'SET_IFU_FILE_EXT';

const upper_wires = {
  14: ['Upper DE Smartwire 1 (.014 NiTi)'],
  16: ['Upper DE Smartwire 2 (.016 NiTi)'],
  both: ['Upper DE Smartwire 1 (.014 NiTi)', 'Upper DE Smartwire 2 (.016 NiTi)'],
  none: [],
};
const lower_wires = {
  14: ['Lower DE Smartwire 1 (.014 NiTi)'],
  16: ['Lower DE Smartwire 2 (.016 NiTi)'],
  both: ['Lower DE Smartwire 1 (.014 NiTi)', 'Lower DE Smartwire 2 (.016 NiTi)'],
  none: [],
};

// Action Creators
// -----------------------------------------------------------------------------

export function setWires(status_comment, arch_to_treat) {
  // If no status yet, then set default wires based on arch type
  if (!status_comment) {
    let wires = [];
    if (arch_to_treat === 'upper') {
      wires = ['Upper DE Smartwire 2 (.016 NiTi)'];
    } else if (arch_to_treat === 'lower') {
      wires = ['Lower DE Smartwire 2 (.016 NiTi)'];
    } else if (arch_to_treat === 'both') {
      wires = ['Upper DE Smartwire 2 (.016 NiTi)', 'Lower DE Smartwire 2 (.016 NiTi)'];
    }
    return {
      type: SET_WIRES,
      wires: wires,
      upper_selection: '16',
      lower_selection: '16',
    };
  }

  // Else set wires based on status comment
  const wires = JSON.parse(status_comment).wires;
  const upper = wires.filter(function (w) {
    return w.indexOf('Upper') !== -1;
  });

  const lower = wires.filter(function (w) {
    return w.indexOf('Lower') !== -1;
  });

  const upper_selection = Object.keys(upper_wires).find((key) => JSON.stringify(upper_wires[key]) === JSON.stringify(upper));
  const lower_selection = Object.keys(lower_wires).find((key) => JSON.stringify(lower_wires[key]) === JSON.stringify(lower));

  return {
    type: SET_WIRES,
    wires: wires,
    upper_selection: upper_selection,
    lower_selection: lower_selection,
  };
}

export function setUploadOption(status_comment) {
  let bpg_checked = false;

  if (status_comment) {
    const upload_options = JSON.parse(status_comment).upload_options;
    if (upload_options.indexOf('Bracket Placement Guide') !== -1) {
      bpg_checked = true;
    }
  }

  return {
    type: SET_UPLOAD_OPTION,
    bpg_checked: bpg_checked,
  };
}

export function onToothSelectIfu(key, type) {
  return (dispatch, getState) => {
    const { selected_teeth } = getState().ifuUploadReducer;

    let selected_teeth_updates = _.cloneDeep(selected_teeth);

    if (selected_teeth_updates.indexOf(key) !== -1) {
      const index = selected_teeth_updates.indexOf(key);
      if (index > -1) {
        selected_teeth_updates.splice(index, 1);
      }
    } else {
      selected_teeth_updates.push(key);
    }

    if (type === 'collisions') {
      dispatch(editBracketCollisionsModal(selected_teeth_updates));
    } else if (type === 'not_included') {
      dispatch(editBracketNotIncludedModal(selected_teeth_updates));
    } else if (type === 'repositioned') {
      dispatch(editBracketRepositionedModal(selected_teeth_updates));
    } else if (type === 'new') {
      dispatch(editBracketNewModal(selected_teeth_updates));
    }
  };
}

// Modals
export function openBracketNotIncludedModal(heading, selected_teeth) {
  return {
    type: OPEN_BRACKET_NOT_INCLUDED_MODAL,
    heading: heading,
    selected_teeth: selected_teeth,
  };
}
export function editBracketNotIncludedModal(selected_teeth) {
  return {
    type: EDIT_BRACKET_NOT_INCLUDED_MODAL,
    selected_teeth: selected_teeth,
  };
}
export function closeBracketNotIncludedModal() {
  return {
    type: CLOSE_BRACKET_NOT_INCLUDED_MODAL,
  };
}
export function openBracketCollisionsModal(heading, selected_teeth) {
  return {
    type: OPEN_BRACKET_COLLISIONS_MODAL,
    heading: heading,
    selected_teeth: selected_teeth,
  };
}
export function editBracketCollisionsModal(selected_teeth) {
  return {
    type: EDIT_BRACKET_COLLISIONS_MODAL,
    selected_teeth: selected_teeth,
  };
}
export function closeBracketCollisionsModal() {
  return {
    type: CLOSE_BRACKET_COLLISIONS_MODAL,
  };
}
export function openBracketRepositionedModal(heading, selected_teeth) {
  return {
    type: OPEN_BRACKET_REPOSITIONED_MODAL,
    heading: heading,
    selected_teeth: selected_teeth,
  };
}
export function editBracketRepositionedModal(selected_teeth) {
  return {
    type: EDIT_BRACKET_REPOSITIONED_MODAL,
    selected_teeth: selected_teeth,
  };
}
export function closeBracketRepositionedModal() {
  return {
    type: CLOSE_BRACKET_REPOSITIONED_MODAL,
  };
}

export function openBracketNewModal(heading, selected_teeth) {
  return {
    type: OPEN_BRACKET_NEW_MODAL,
    heading: heading,
    selected_teeth: selected_teeth,
  };
}
export function editBracketNewModal(selected_teeth) {
  return {
    type: EDIT_BRACKET_NEW_MODAL,
    selected_teeth: selected_teeth,
  };
}
export function closeBracketNewModal() {
  return {
    type: CLOSE_BRACKET_NEW_MODAL,
  };
}
export function openGenerateModal() {
  return {
    type: OPEN_GENERATE_MODAL,
  };
}
export function closeGenerateModal() {
  return {
    type: CLOSE_GENERATE_MODAL,
  };
}
export function openOverrideModal() {
  return {
    type: OPEN_OVERRIDE_MODAL,
  };
}
export function closeOverrideModal() {
  return {
    type: CLOSE_OVERRIDE_MODAL,
  };
}
export function openRemoveIfuUploadModalV2() {
  return {
    type: OPEN_REMOVE_IFU_UPLOAD_MODAL,
  };
}
export function openRemoveIfuUploadModal(event) {
  event.preventDefault();
  let href = event.currentTarget.href;
  let startingPath = href.indexOf('/', 9);
  let endPath = href.lastIndexOf('/');

  if (startingPath >= 0 && endPath >= 0) {
    return {
      type: OPEN_REMOVE_IFU_UPLOAD_MODAL,
    };
  }
  return;
}
export function closeRemoveIfuUploadModal() {
  return {
    type: CLOSE_REMOVE_IFU_UPLOAD_MODAL,
  };
}

// Axios

// Put Upload Options
export function onUploadOptionSelect(bpg_checked) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(putUploadOptionPending(bpg_checked));

    let upload_options = ['Digital Enhancement Instructions'];
    if (bpg_checked) {
      upload_options.push('Bracket Placement Guide');
    }

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/upload_options`, { upload_options: upload_options })
      .then((res) => {
        dispatch(putUploadOptionSuccess());
      })
      .catch((error) => {
        dispatch(putUploadOptionError(error));
      });
  };
}

export function putUploadOptionPending(bpg_checked) {
  return {
    type: PUT_UPLOAD_OPTION_PENDING,
    bpg_checked: bpg_checked,
  };
}
export function putUploadOptionSuccess() {
  return {
    type: PUT_UPLOAD_OPTION_SUCCESS,
  };
}
export function putUploadOptionError(error) {
  return {
    type: PUT_UPLOAD_OPTION_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
// Put Wires
export function onUpperWireSelect(upper_selection) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;
    let { wires } = getState().ifuUploadReducer;

    const upper = upper_wires[upper_selection];

    wires = wires.filter((w) => {
      return w.indexOf('Upper') === -1;
    });
    wires.push.apply(wires, upper);

    const request = {
      wires: wires,
    };

    dispatch(putUpperWiresPending(upper_selection));

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/wires`, request)
      .then((res) => {
        dispatch(putUpperWiresSuccess(wires));
      })
      .catch((error) => {
        dispatch(putUpperWiresError(error));
      });
  };
}

export function onLowerWireSelect(lower_selection) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    let { wires } = getState().ifuUploadReducer;

    const lower = lower_wires[lower_selection];

    wires = wires.filter((w) => {
      return w.indexOf('Lower') === -1;
    });
    wires.push.apply(wires, lower);

    const request = {
      wires: wires,
    };

    dispatch(putLowerWiresPending(lower_selection));

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/wires`, request)
      .then((res) => {
        dispatch(putLowerWiresSuccess(wires));
      })
      .catch((error) => {
        dispatch(putLowerWiresError(error));
      });
  };
}

export function putUpperWiresPending(upper_selection) {
  return {
    type: PUT_UPPER_WIRES_PENDING,
    upper_selection: upper_selection,
  };
}
export function putUpperWiresSuccess(wires) {
  return {
    type: PUT_UPPER_WIRES_SUCCESS,
    wires: wires,
  };
}
export function putUpperWiresError(error) {
  return {
    type: PUT_UPPER_WIRES_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
export function putLowerWiresPending(lower_selection) {
  return {
    type: PUT_LOWER_WIRES_PENDING,
    lower_selection: lower_selection,
  };
}
export function putLowerWiresSuccess(wires) {
  return {
    type: PUT_LOWER_WIRES_SUCCESS,
    wires: wires,
  };
}
export function putLowerWiresError(error) {
  return {
    type: PUT_LOWER_WIRES_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

// Post Case File
export function postIfuCaseFile(data) {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    let form_data = new FormData();
    form_data.append('files', JSON.stringify(data));

    dispatch(postIfuCaseFilePending());

    Axios.post(`/apiV2/casefiles/${case_id}/add`, form_data)
      .then((res) => {
        dispatch(fetchIfuState(case_id));

        dispatch(postIfuCaseFileSuccess());

        return res;
      })
      .catch((error) => {
        dispatch(postIfuCaseFileError(error));
      });
  };
}

export function postIfuCaseFilePending() {
  return {
    type: POST_CASE_FILE_PENDING,
  };
}
export function clearIfuCaseFilePending() {
  return {
    type: CLEAR_IFU_UPLOAD_PENDING,
  };
}
export function postIfuCaseFileSuccess() {
  return {
    type: POST_CASE_FILE_SUCCESS,
  };
}

export function setUploadPending() {
  return {
    type: SET_IFU_UPLOAD_PENDING,
  };
}
export function setUploadFinished() {
  return {
    type: SET_IFU_UPLOAD_FINISHED,
  };
}

export function setIfuFolder(folder_name) {
  return {
    type: SET_IFU_FOLDER,
    ifu_folder: folder_name,
  };
}

export function postIfuCaseFileError(error) {
  return {
    type: POST_CASE_FILE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

// Put Brackets

export function onConfirmButtonClickCollisions() {
  return (dispatch, getState) => {
    let { selected_teeth } = getState().ifuUploadReducer;
    let { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(putBracketPending());

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/bracket_collisions`, { collisions: selected_teeth })
      .then((res) => {
        dispatch(putBracketSuccess(selected_teeth));
        dispatch(fetchIfuState(case_id));
        dispatch(closeBracketCollisionsModal());
      })
      .catch((error) => {
        dispatch(putBracketError(error));
      });
  };
}

export function onConfirmButtonClickNotIncluded() {
  return (dispatch, getState) => {
    let { selected_teeth } = getState().ifuUploadReducer;
    let { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(putBracketPending());

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/bracket_not_included`, { not_included: selected_teeth })
      .then((res) => {
        dispatch(putBracketSuccess(selected_teeth));
        dispatch(fetchIfuState(case_id));
        dispatch(closeBracketNotIncludedModal());
      })
      .catch((error) => {
        dispatch(putBracketError(error));
      });
  };
}

export function onConfirmButtonClickRepositioned() {
  return (dispatch, getState) => {
    let { selected_teeth } = getState().ifuUploadReducer;
    let { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(putBracketPending());

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/bracket_repositioned`, { repositioned: selected_teeth })
      .then((res) => {
        dispatch(putBracketSuccess(selected_teeth));
        dispatch(fetchIfuState(case_id));
        dispatch(closeBracketRepositionedModal());
      })
      .catch((error) => {
        dispatch(putBracketError(error));
      });
  };
}

export function onConfirmButtonClickNew() {
  return (dispatch, getState) => {
    let { selected_teeth } = getState().ifuUploadReducer;
    let { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(putBracketPending());

    Axios.put(`/apiV2/cadaction/${case_id}/ifu/bracket_new`, { new: selected_teeth })
      .then((res) => {
        dispatch(putBracketSuccess(selected_teeth));
        dispatch(fetchIfuState(case_id));
        dispatch(closeBracketNewModal());
      })
      .catch((error) => {
        dispatch(putBracketError(error));
      });
  };
}

export function putBracketPending() {
  return {
    type: PUT_BRACKET_PENDING,
  };
}
export function putBracketSuccess(selected_teeth) {
  return {
    type: PUT_BRACKET_SUCCESS,
    selected_teeth: selected_teeth,
  };
}

export function putBracketError(error) {
  return {
    type: PUT_BRACKET_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

/**
 * Starts IFU generation
 * @function
 * @return {Object} - Dispatch object
 */
export function onConfirmButtonClickGenerate() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(generatePending());

    Axios.post(`/apiV2/cadaction/${case_id}/ifu/generate`)
      .then((res) => {
        dispatch(generateSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeGenerateModal());
      })
      .catch((error) => {
        dispatch(generateError(error));
      });
  };
}

export function generatePending() {
  return {
    type: GENERATE_PENDING,
  };
}
export function generateSuccess() {
  return {
    type: GENERATE_SUCCESS,
  };
}
export function generateError(error) {
  return {
    type: GENERATE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

// Override
export function onConfirmButtonClickOverride() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(overridePending());

    Axios.post(`/apiV2/cadaction/${case_id}/ifu/override`)
      .then((res) => {
        dispatch(overrideSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeOverrideModal());
      })
      .catch((error) => {
        dispatch(overrideError(error));
      });
  };
}

export function overridePending() {
  return {
    type: OVERRIDE_PENDING,
  };
}
export function overrideSuccess() {
  return {
    type: OVERRIDE_SUCCESS,
  };
}
export function overrideError(error) {
  return {
    type: OVERRIDE_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

// Remove IFU Upload
export function onConfirmButtonClickRemoveIfuUpload() {
  return (dispatch, getState) => {
    const { ifu_state } = getState().ifuStateReducer;
    const case_id = ifu_state.case_id;

    dispatch(deleteIfuUploadPending());

    Axios.delete(`/apiV2/cadaction/${case_id}/ifu/remove_file`)
      .then((res) => {
        dispatch(deleteIfuUploadSuccess());
        dispatch(fetchIfuState(case_id));
        dispatch(closeRemoveIfuUploadModal());
      })
      .catch((error) => {
        dispatch(deleteIfuUploadError(error));
      });
  };
}

export function deleteIfuUploadPending() {
  return {
    type: DELETE_IFU_UPLOAD_PENDING,
  };
}
export function deleteIfuUploadSuccess() {
  return {
    type: DELETE_IFU_UPLOAD_SUCCESS,
  };
}

export function deleteIfuUploadError(error) {
  return {
    type: DELETE_IFU_UPLOAD_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function setIFUIncorrectFileNameError(error_type, error) {
  return {
    type: SET_IFU_INCORRECT_FILE_NAME_ERROR,
    error: error,
    error_type: error_type,
  };
}

export function setIfuFileName(filename) {
  return {
    type: SET_IFU_FILE_NAME,
    filename: filename,
  };
}

export function setIfuFileTypeError() {
  return {
    type: SET_IFU_FILE_TYPE_ERROR,
  };
}

export function clearIfuFileTypeError() {
  return {
    type: CLEAR_IFU_FILE_TYPE_ERROR,
  };
}

export function setIfuFileExtension(extension) {
  return {
    type: SET_IFU_FILE_EXT,
    extension,
  };
}
