import React, { Component } from 'react';
import './track_shipment_modal.scss';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../common/functions';
import { LogoWhite } from '../assets/logos';

class TrackShipmentModal extends Component {
  componentDidMount() {
    setTimeout(function () {
      this.$('#generalModal').modal('show');
    }, 0);
    this.downloadpdf.bind(this);
  }

  downloadpdf = (e) => {
    const el = document.getElementById('trackshipments');
    let case_id = '';
    if (this.props.selected_tab === 'item') {
      case_id = removeIreqIdInitialNumber(this.props.caseid);
    } else {
      case_id = removeCaseIdInitialNumber(this.props.caseid);
    }
    let filename = 'Tracking Details - ' + case_id + '.pdf';

    var opt = {
      filename: filename,
      margin: [3, 3, 3, 3],
      pagebreak: { mode: ['css', 'legacy'] },
    };

    html2pdf().set(opt).from(el).toPdf().save();
  };

  printpdf = (e) => {
    const element = document.getElementById('trackshipments');
    var img = html2pdf().from(element).toImg();
    img.then(
      (accept) => {
        var isFirefox = typeof InstallTrigger !== 'undefined';

        if (isFirefox === true) {
          var canvas = img.prop.canvas;
          const imgData = canvas.toDataURL('image/png');
          var win1 = window.open();

          if (win1 !== null) {
            win1.document.write("<img style='display:block;' src = '" + imgData + "'/>");
            win1.print();
          }
        } else {
          var c = img.prop.img.src;

          printJS(c, 'image');
        }
      },
      (reject) => {}
    );
  };

  render() {
    return (
      <div
        className="modal base-modal fade"
        id="generalModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="generalModal"
        data-backdrop="false"
        data-keyboard="false"
        onClick={this.props.onModalClick}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onButtonClick}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">Tracking Information</h4>
            </div>
            <div id="trackshipments">
              <div className="modal-body margin-text">
                <LogoWhite />
                <p>
                  Dear Dr.{' '}
                  {this.props.user.firstName
                    ? this.props.user.lastName
                    : this.props.user.user__first_name
                    ? this.props.user.user__last_name
                    : this.props.user.first_name
                    ? this.props.user.last_name
                    : null}
                  ,
                </p>
                {this.props.hand_delivery_ind ? (
                  <p className="stretch">
                    {this.props.patientsFirstName} {this.props.patientsLastName}
                    's {this.props.selected_tab && this.props.selected_tab === 'item' ? 'item request' : 'case'} will be delivered to{' '}
                    {this.props.shippingAddress}.
                  </p>
                ) : (
                  <>
                    <p className="stretch">
                      {this.props.patientsFirstName} {this.props.patientsLastName}
                      's {this.props.selected_tab && this.props.selected_tab === 'item' ? 'item request' : 'case'} has been shipped and is on its way to{' '}
                      {this.props.shippingAddress}.
                    </p>
                    <p className="stretch">
                      Keep track of your package at:{' '}
                      <a className="midnight" href={this.props.tracking_url} target="_blank" rel="noopener noreferrer">
                        {this.props.comments ? this.props.comments.trim() : null}
                      </a>
                      {'. '}
                      Shipping usually takes 3-5 business days.
                    </p>
                  </>
                )}
                <p className="stretch">For immediate assistance, contact InBrace Concierge.</p>
                <p className="stretch center">
                  InBrace Concierge <br /> Monday - Friday ~ 5 am - 5 pm PST <br /> (877) 258-8677 <br />
                  support@inbrace.com
                </p>
              </div>
            </div>
            <div className="modal-footer" id="trackshipments-footer">
              <button type="button" className="btn btn--secondary pull-center" data-dismiss="modal" onClick={this.props.onButtonClick}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrackShipmentModal;
