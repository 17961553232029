// Css
import './datalist.scss';

// External Libs
import React, { Component } from 'react';
import { new_branding_words } from '../../../common/nomenclature';
import * as _ from 'lodash';
/**
 * Datalist provides an autocomplete text field for a list of information
 */
class Datalist extends Component {
  componentDidMount() {
    this.attachSelectPicker();
  }

  componentDidUpdate(prevProps, prevState) {
    const windowData = this.props.windowData || window;
    if (prevProps.list !== this.props.list) {
      windowData.$(`#select-${this.props.name}`).selectpicker('refresh');
    }
  }

  attachSelectPicker = () => {
    const windowData = this.props.windowData || window;
    if (windowData.$(`#select-${this.props.name}`)) {
      const selectPicker = windowData.$(`#select-${this.props.name}`).selectpicker({
        liveSearch: true,
        size: 'auto',
        width: this.props.width ? this.props.width : 'auto',
      });
      if (!(this.props.item && (this.props.item.includes('upper_wires') || this.props.item.includes('lower_wires')))) {
        selectPicker.on('changed.bs.select', (event) => {
          event.target.dataset['item'] = this.props.item;
          this.props.change(event);
        });
      }
    }
  };

  getFormLabel = () => {
    return (
      <div className="form-label">
        <label>{this.props.label}</label>
      </div>
    );
  };

  /**
   * Creates an <option> component and populates it with the data from the given item.
   * @param {*} item object containing itemKey, itemValue and updatedNomenclature
   * @param {*} index value that will be used for the key property
   * @returns <option>
   */
  extractDatalistItems = (item, index) => {
    return (
      <option key={`list-item-${index}`} data-content={item.updatedNomenclature} value={item.itemValue}>
        {item.updatedNomenclature}
      </option>
    );
  };

  /**
   * Returns an enriched list containing metadata for the array of options given
   * @param {[]} rawItemsList list of strings containing the options for the Datalist
   * @returns Array of objects containing itemKey, itemValue and updatedNomenclature
   */
  retrieveListWithNewNomenclature = (rawItemsList) => {
    if (!rawItemsList) return [];

    const listWithMappings = [];
    rawItemsList.forEach((item) => {
      const isObject = item instanceof Object;
      const itemKey = isObject ? item.key : item;
      const itemValue = isObject ? item.value : item;
      const updatedNomenclature = new_branding_words(itemKey);
      listWithMappings.push({
        itemKey,
        itemValue,
        updatedNomenclature,
      });
    });
    return listWithMappings;
  };

  /**
   * Sorts an array based on the value of the sortBy property. Uses lodash.orderBy().
   * @param {*} list list of items to be sorted
   * @param {*} propertyNames properties used to do the sorting
   * @returns Sorted array
   */
  sortListItemsByProperties = (list, propertyNames = []) => {
    if (!this.props.sortBy) return list;
    return _.orderBy(list, propertyNames, this.props.sortBy);
  };

  render = () => {
    let list = this.retrieveListWithNewNomenclature(this.props.list);
    list = this.sortListItemsByProperties(list, ['updatedNomenclature']);
    const datalist = list.map(this.extractDatalistItems);

    return (
      <fieldset className={this.props.error ? 'datalist datalist-error' : this.props.fieldsetClass ? 'datalist ' + this.props.fieldsetClass : 'datalist'}>
        {this.props.label ? this.getFormLabel() : null}
        <div className={this.props.clearClass ? '' : this.props.disabled ? 'form-input select-disabled' : 'form-input'}>
          <select
            disabled={this.props.disabled}
            multiple={this.props.multiple}
            name={this.props.name || ''}
            id={`select-${this.props.name}`}
            className={`${this.props.className || ''} ${this.props.borderClassName || ''} datalist-select-option`}
            data-width="auto"
            data-divider="true"
            data-selected-text-format={this.props.multiple ? (this.props.max_count ? `count > ${this.props.max_count}` : 'count > 2') : null}
            data-style="datalist-select-option"
            value={this.props.value || ''}
            onChange={this.props.change || ''}
            title={this.props.title}
          >
            <option value="" className={this.props.hideDefaultOption ? 'hide' : null}>
              {this.props.placeholder ? this.props.placeholder : '--'}
            </option>
            {datalist}
          </select>
        </div>
      </fieldset>
    );
  };
}

export default Datalist;
