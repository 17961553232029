import React, { useEffect } from 'react';
import './case_list_sidebar.scss';

/**
 * Displays the bpp sidebar
 * @component
 * @category BPP
 */
function CaseListSidebar(props) {
  const { side_collapse, onClickCollapse, children } = props;

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip();
  }, []);

  const classNames = side_collapse ? 'sidenav-submenu submenu-collapse' : 'sidenav-submenu';
  return (
    <div className={classNames}>
      {children}
      <span className="sidenav-collapse-btn collapsed" onClick={onClickCollapse}>
        {side_collapse ? <i className="fa fa-chevron-right" aria-hidden="true" /> : <i className="fa fa-chevron-left" aria-hidden="true" />}
      </span>
    </div>
  );
}

export default CaseListSidebar;
