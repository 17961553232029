import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import WasmToolbarDropdown from './wasm_toolbar_dropdown';

function getClassnames(active, disabled) {
  return classNames('control-btn', {
    'control-btn--active': active,
    'control-btn--disabled': disabled,
  });
}

const kebabCase = (string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ /)
    .map((word) => word.toLowerCase())
    .join('-');
};

export function SingleControlBtn(props) {
  const { active, disabled, onClick, icon, text, tooltip, placement, onMouseOver, onMouseLeave } = props;
  const onClickHandler = disabled ? null : onClick;
  const id = kebabCase(text);
  const onMouseOverHandler = disabled && onMouseOver ? null : onMouseOver;
  const onMouseLeaveHandler = disabled && onMouseLeave ? null : onMouseLeave;
  const btn = (
    <div className={getClassnames(active, disabled)} id={id} onMouseLeave={onMouseLeaveHandler} onMouseOver={onMouseOverHandler} onClick={onClickHandler}>
      {icon}
      <div>{text}</div>
    </div>
  );

  if (!tooltip) {
    return btn;
  }

  return (
    <Tooltip title={tooltip} placement={placement} arrow>
      {btn}
    </Tooltip>
  );
}

SingleControlBtn.defaultProps = {
  placement: 'bottom',
};

SingleControlBtn.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  icon: PropTypes.node,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  onClick: PropTypes.func,
};

/**
 * Displays panel top tool bar
 * @component
 */
export default function ControlBtn(props) {
  return (
    <div className={classNames('control-btn-group', props.className)}>
      {props.btns.map((btn) => (btn.dropdown ? <WasmToolbarDropdown key={btn.text} btn={btn} /> : <SingleControlBtn key={btn.text} {...btn} />))}
    </div>
  );
}

ControlBtn.propTypes = {
  btns: PropTypes.arrayOf(
    PropTypes.shape({
      ...SingleControlBtn.propTypes,
      dropdown: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          disabled: PropTypes.bool,
          toggle: PropTypes.bool,
          onClick: PropTypes.func,
        })
      ),
    })
  ),
  className: PropTypes.string,
};
