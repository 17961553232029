// External Libs
import React, { Component } from 'react';

// Internal Libs
import Modal from '../../../components/modal/modal';
import { removeIreqIdInitialNumber } from '../../../common/functions';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
// import WorkOrderRef from '../../../components/workorder_reference/work_order_reference';
class CadLabelSelection extends Component {
  constructor(props) {
    super(props);

    const txplan_arch = this.props.txplan_arch ? this.props.txplan_arch.toUpperCase() : 'BOTH';
    const case_id = this.props.case_id;

    let state = {
      showGenerateModal: false,
      showOverrideModal: false,
      upper_smartwire_014_NiTi: true,
      upper_smartwire_016_NiTi: true,
      lower_smartwire_014_NiTi: true,
      lower_smartwire_016_NiTi: true,
      lower_smartwire_016_straight_NiTi: true,
      upper_idb: true,
      lower_idb: true,
      replacement_brackets: true,
      content_labels: true,
    };

    switch (txplan_arch) {
      case 'UPPER':
        state['lower_smartwire_014_NiTi'] = false;
        state['lower_smartwire_016_NiTi'] = false;
        state['lower_smartwire_016_straight_NiTi'] = false;
        state['lower_idb'] = false;
        break;

      case 'LOWER':
        state['upper_smartwire_014_NiTi'] = false;
        state['upper_smartwire_016_NiTi'] = false;
        state['upper_idb'] = false;
        break;

      default:
    }

    if (case_id.indexOf('-') >= 0) {
      state['replacement_brackets'] = false;
      state['upper_smartwire_014_NiTi'] = false;
      state['lower_smartwire_014_NiTi'] = false;
      state['upper_idb'] = false;
      state['lower_idb'] = false;
      state['content_labels'] = false;
    }

    this.state = state;
  }

  enterReviewProcess = () => {
    this.setState({ showGenerateModal: !this.state.showGenerateModal });
    this.props.generateLabel();
  };

  toggleGenerateModal = () => {
    this.setState({ showGenerateModal: !this.state.showGenerateModal });
  };

  GenerateModal = () => {
    const header_text = `Generate Labels - ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to proceed with label generation?"
        confirm_btn_text="Generate"
        onConfirmButtonClick={this.enterReviewProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelGenerateModal}
      />
    );
  };

  onCancelGenerateModal = (event) => {
    this.toggleGenerateModal();
  };

  toggleCheckbox = (event) => {
    const state_name = event.target.id;
    let state = {};
    state[state_name] = !this.state[state_name];

    this.setState(state);
  };
  overrideModal = () => {
    const header_text = `Override Process - ${removeIreqIdInitialNumber(this.props.item_request.ireq_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to override process?"
        confirm_btn_text="Override"
        onConfirmButtonClick={this.props.overrideLabelGeneration}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelOverrideModal}
      />
    );
  };

  onCancelOverrideModal = (event) => {
    this.toggleOverrideModal();
  };

  toggleOverrideModal = () => {
    this.setState({ showOverrideModal: !this.state.showOverrideModal });
  };

  render() {
    let selections = this.props.selections;

    return (
      <React.Fragment>
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermission('IREQ_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
              <React.Fragment>
                <div className="ir process-box-intro">
                  <div className="ir process-box-title center-text bold-text">Label Selection</div>
                  <div className="center-text">
                    The required labels to be printed have been preselected. Click <span className="bold-text">Generate</span> to begin the label generation
                    process.
                  </div>
                </div>
                <div className="extra-padding-headline-bpp" />
                <div className={'row ' + (selections.length < 6 ? 'label-center' : '')}>
                  {selections.map((selection, index) => {
                    return (
                      <div key={index} className={selections.length > 5 ? 'col-lg-6' : 'col-lg-12'}>
                        <input
                          id={selection.state}
                          className={selection.hide ? 'filter-hidden' : 'label-checkbox-ir pointer'}
                          type="checkbox"
                          name="case_labels"
                          value={selection.state}
                          checked={true}
                          disabled={true}
                        />
                        <label className={selection.hide ? 'filter-hidden' : 'label-text-ir'} htmlFor={selection.state}>
                          {' '}
                          {selection.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className="extra-padding-headline-bpp" />
                <div className="button-panel center-text">
                  <UserPermissionsContext.Consumer>
                    {(user_roles_and_permissions) => {
                      return userHasPermission('IREQ_LABEL_GENERATION', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                        <button type="button" className="btn btn-light" onClick={this.toggleGenerateModal}>
                          Generate
                        </button>
                      ) : (
                        <button type="button" className="btn btn-light" disabled={true}>
                          Generate
                        </button>
                      );
                    }}
                  </UserPermissionsContext.Consumer>

                  <UserPermissionsContext.Consumer>
                    {(user_roles_and_permissions) => {
                      return userHasPermission('IREQ_LABEL_OVERRIDE', user_roles_and_permissions.permissions) && this.props.ref_no ? (
                        <button type="button" className="btn btn-light" onClick={this.toggleOverrideModal}>
                          Override
                        </button>
                      ) : (
                        <button type="button" className="btn btn-light" disabled={true}>
                          Override
                        </button>
                      );
                    }}
                  </UserPermissionsContext.Consumer>
                </div>

                {this.state.showGenerateModal ? this.GenerateModal() : null}
                {this.state.showOverrideModal ? this.overrideModal() : null}
              </React.Fragment>
            ) : (
              <div className="center-text">Label generation process in progress...</div>
            );
          }}
        </UserPermissionsContext.Consumer>
      </React.Fragment>
    );
  }
}

export default CadLabelSelection;
