import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '../../components/container/card_container';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';

/**
 * Displays an editable table
 * @component
 */

function EditTable(props) {
  const { permissions } = useContext(UserPermissionsContext);

  const setEditMode = (event, props, data) => {
    if (
      event.target &&
      event.target.getAttribute('class') &&
      (event.target.getAttribute('class').indexOf('edit-table--item') >= 0 || event.target.getAttribute('class').indexOf('edit-table--mobile') >= 0) &&
      userHasPermission(props.editPermission, permissions) &&
      !props.disabled
    ) {
      props.setEditMode(data);
    }
  };

  const desktopView = () => {
    return (
      <div className="hide-on-mobile">
        <CardContainer type="top-border" className="pad-md pad-md--no-bottom">
          {React.Children.toArray(
            props.table_data.map((heading) => {
              return (
                <div
                  key={heading.id}
                  className={heading.action || heading.add ? '' : 'edit-table--heading'}
                  id={heading.id}
                  data-method={heading.id}
                  style={{ width: heading.col_width }}
                >
                  <span>{heading.label}</span>
                </div>
              );
            })
          )}

          {React.Children.toArray(
            props.field_data.map((data, index) => {
              return (
                <div className={'edit-table--parent'}>
                  <div
                    onClick={(event) => {
                      setEditMode(event, props, data);
                    }}
                    className={'edit-table--content'}
                    key={index}
                  >
                    {props.table_data.map((heading, heading_index) => {
                      return heading.add ? null : heading.action ? null : (
                        <div id={heading.id} key={heading_index} className="edit-table--item" style={{ width: heading.col_width }}>
                          {heading.data_function
                            ? heading.data_function(props, data, heading.data_fields)
                            : heading.data_fields && heading.data_fields.length > 0
                            ? data[heading.data_fields[0]]
                            : '- - - - -'}
                        </div>
                      );
                    })}
                  </div>
                  {props.table_data.map((heading, heading_index) => {
                    return heading.add ? null : heading.action ? (
                      <div id={`mobile-${heading.id}`} key={heading_index} className={'edit-table--action'}>
                        {heading.action ? heading.action_function(props, data, heading.data_fields) : null}
                      </div>
                    ) : null;
                  })}
                </div>
              );
            })
          )}

          {props.addEnabled ? (
            <div className={'edit-table--parent'}>
              <div className={'edit-table--content'}>
                {props.table_data.map((heading, heading_index) => {
                  return heading.action ? null : (
                    <div id={heading.id} key={heading_index} className="edit-table--item" style={{ width: heading.col_width }}>
                      {heading.add_function && !heading.add ? heading.add_function(props) : null}
                    </div>
                  );
                })}
              </div>
              {props.table_data.map((heading, heading_index) => {
                return heading.action ? (
                  <div id={heading.id} key={heading_index} className={'edit-table--action'}>
                    {heading.action ? heading.add_function(props) : null}
                  </div>
                ) : null;
              })}
            </div>
          ) : null}
        </CardContainer>
        {React.Children.toArray(
          props.table_data.map((heading, heading_index) => {
            return heading.add ? heading.add_function(props) : null;
          })
        )}
      </div>
    );
  };

  const mobileView = () => {
    let show_mobile_action = false;

    return (
      <div className="show-on-mobile">
        <CardContainer type="top-border" className="pad-md pad-md--no-bottom">
          {React.Children.toArray(
            props.field_data.map((data, index) => {
              return (
                <div
                  onClick={(event) => {
                    setEditMode(event, props, data);
                  }}
                  className={'edit-table--mobile'}
                  key={index}
                >
                  {props.mobile_data.map((heading, heading_index) => {
                    const label = <span className="emphasis edit-table--mobile-label">{heading.label}</span>;
                    if (!show_mobile_action) {
                      show_mobile_action = props.editContent.id === data.id && data.is_active;
                    }

                    return heading.add || heading.action ? null : (
                      <div id={heading.id} key={heading_index} className={'edit-table--mobile-item' + (heading_index === 0 ? ' emphasis' : '')}>
                        {heading.data_function
                          ? heading.data_function(props, data, heading.data_fields, label)
                          : heading.data_fields && heading.data_fields.length > 0
                          ? data[heading.data_fields[0]]
                          : '- - - - -'}
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}

          {props.addEnabled
            ? React.Children.toArray(
                props.mobile_data.map((heading, heading_index) => {
                  const label = <span className="emphasis edit-table--mobile-label">{heading.label}</span>;
                  return heading.add || heading.action ? null : (
                    <div key={heading_index} className={'edit-table--mobile-item'}>
                      {label}
                      {heading.add_function && !heading.add ? heading.add_function(props) : null}
                    </div>
                  );
                })
              )
            : null}
        </CardContainer>

        {React.Children.toArray(
          props.table_data.map((heading, heading_index) => {
            return heading.add && !props.addEnabled ? (
              heading.add_function(props)
            ) : props.addEnabled && heading.action ? (
              <div id={heading.id} key={heading_index} className="edit-table--mobile-action">
                {heading.action ? heading.add_function(props) : null}
              </div>
            ) : heading.action ? (
              <div id={heading.id} key={heading_index} className="edit-table--mobile-action">
                {heading.action ? heading.action_function(props, {}, heading.data_fields, show_mobile_action) : null}
              </div>
            ) : null;
          })
        )}
      </div>
    );
  };

  return (
    <div className="edit-table">
      {desktopView()}
      {mobileView()}
    </div>
  );
}

EditTable.propTypes = {
  table_data: PropTypes.array,
  meta_data: PropTypes.array,
  field_data: PropTypes.array,
  mobile_data: PropTypes.array,
};

EditTable.defaultProps = {
  meta_data: [],
  table_data: [],
  field_data: [],
  mobile_data: [],
};

export default EditTable;
