import _ from 'lodash';
import { textFieldCheck, removeEmoji, orderTeeth, removeOpposingTeeth } from '../../../common/functions';

// Action Types
export const SET_TREATMENT_PLAN_REVISION = 'SET_TREATMENT_PLAN_REVISION';
export const SET_SELECTED_TEETH = 'SET_SELECTED_TEETH';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_TEXT_BODY = 'SET_TEXT_BODY';
export const SET_SHOW_ARCH_SELECTION = 'SET_SHOW_ARCH_SELECTION';
export const SET_SHOW_TEXT_BOX = 'SET_SHOW_TEXT_BOX';
export const SET_TEETH_SELECTOR_LABEL = 'SET_TEETH_SELECTOR_LABEL';
export const SET_TREATMENT_ARCH = 'SET_TREATMENT_ARCH';
export const SET_OPPOSING_TREATMENT_ARCH = 'SET_OPPOSING_TREATMENT_ARCH';
export const SET_UPPER_OPPOSING_TREATMENT_ARCH = 'SET_UPPER_OPPOSING_TREATMENT_ARCH';
export const SET_LOWER_OPPOSING_TREATMENT_ARCH = 'SET_LOWER_OPPOSING_TREATMENT_ARCH';
export const SET_TEXT_BOX_LABEL = 'SET_TEXT_BOX_LABEL';
export const SET_TEXT_BOX_PLACEHOLDER = 'SET_TEXT_BOX_PLACEHOLDER';
export const SET_TEXT_BOX_NOTE = 'SET_TEXT_BOX_NOTE';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_TEETH_CHART_INFORMATION = 'CLEAR_TEETH_CHART_INFORMATION';
export const SET_DISABLED_TEETH = 'SET_DISABLED_TEETH';
export const SET_SHOW_PRESETS = 'SET_SHOW_PRESETS';
export const SET_PRESET_SELECTION = 'SET_PRESET_SELECTION';
export const SET_ARCH_DISABLED = 'SET_ARCH_DISABLED';

// Action Creators
// -----------------------------------------------------------------------------

export function setTreatmentPlanRevision(treatment_plan_revision) {
  return {
    type: SET_TREATMENT_PLAN_REVISION,
    treatment_plan_revision: treatment_plan_revision,
  };
}

/**
 * Handles event when user selects a tooth from the teeth chart
 * @function
 * @param {String} key - Tooth identifier
 * @return {Object} Action type
 */
export function onToothSelect(key) {
  return (dispatch, getState) => {
    const { selected_teeth } = getState().teethChartReducer;
    let selected_teeth_updates = _.cloneDeep(selected_teeth);

    if (selected_teeth_updates.indexOf(key) !== -1) {
      const index = selected_teeth_updates.indexOf(key);
      if (index > -1) {
        selected_teeth_updates.splice(index, 1);
      }
    } else {
      selected_teeth_updates.push(key);
    }

    dispatch(setError(false, '', 'text_box'));
    dispatch(setError(false, '', 'teeth_selection'));

    dispatch(setPresetSelection(selected_teeth_updates));

    dispatch({
      type: SET_SELECTED_TEETH,
      selected_teeth: selected_teeth_updates,
    });
  };
}

/**
 * Clears teeth selection
 * @function
 * @return {Object} Action type
 */
export function clearSelectedTeeth() {
  return {
    type: SET_SELECTED_TEETH,
    selected_teeth: [],
  };
}

/**
 * Sets teeth selection
 * @function
 * @param {Array} selected_teeth - List of teeth selections
 * @return {Object} Action type
 */
export function setSelectedTeeth(selected_teeth) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_TEETH,
      selected_teeth: selected_teeth,
    });
    dispatch(setPresetSelection(selected_teeth));
  };
}

/**
 * Sets message to be displayed above the teeth chart
 * @function
 * @param {String} message - Message
 * @return {Object} Action type
 */
export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    message: message,
  };
}

/**
 * Sets text body to appear above teeth selector
 * @function
 * @param {JSX} text_body - JSX for body
 * @return {Object} Action type
 */
export function setTextBody(text_body) {
  return {
    type: SET_TEXT_BODY,
    text_body: text_body,
  };
}

/**
 * Sets indicator for showing arch selection
 * @function
 * @param {Boolean} show_arch_selection - True or False
 * @return {Object} Action type
 */
export function setShowArchSelection(show_arch_selection) {
  return {
    type: SET_SHOW_ARCH_SELECTION,
    show_arch_selection: show_arch_selection,
  };
}

/**
 * Sets disabled attribute for arch selection inputs
 * @param {Boolean} arch_disabled
 * @returns {Object} Action type
 */
export function setArchDisabled(arch_disabled) {
  return {
    type: SET_ARCH_DISABLED,
    arch_disabled: arch_disabled,
  };
}

/**
 * Sets indicator for showing text box
 * @function
 * @param {Boolean} show_text_box - True or False
 * @return {Object} Action type
 */
export function setShowTextBox(show_text_box) {
  return {
    type: SET_SHOW_TEXT_BOX,
    show_text_box: show_text_box,
  };
}

/**
 * Sets teeth selector label
 * @function
 * @param {String} label - Label text
 * @return {Object} Action type
 */
export function setTeethSelectorLabel(label) {
  return {
    type: SET_TEETH_SELECTOR_LABEL,
    teeth_selector_label: label,
  };
}

/**
 * Sets treatment arch
 * @function
 * @param {String} arch - Treatment arch
 * @return {Object} Action type
 */
export function setTreatmentArch(arch) {
  return {
    type: SET_TREATMENT_ARCH,
    treatment_arch: arch,
  };
}

/**
 * Handles event when user selects treatment arch
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onTreatmentArchChange(event) {
  return (dispatch, getState) => {
    const { selected_teeth, upper_opposing_treatment_arch, lower_opposing_treatment_arch } = getState().teethChartReducer;
    const new_treatment_arch = event.target.value;
    dispatch(setTreatmentArch(new_treatment_arch));

    if (new_treatment_arch === 'upper') {
      const opposing_treatment_arch = upper_opposing_treatment_arch || 'lower_untreated';
      dispatch(setUpperOpposingTreatmentArch(opposing_treatment_arch));
    } else if (new_treatment_arch === 'lower') {
      const opposing_treatment_arch = lower_opposing_treatment_arch || 'upper_untreated';
      dispatch(setLowerOpposingTreatmentArch(opposing_treatment_arch));
    } else {
      dispatch(setOpposingTreatmentArch(''));
    }

    dispatch(setPresetSelection(selected_teeth));

    dispatch(setError(false, '', 'text_box'));
    dispatch(setError(false, '', 'teeth_selection'));
  };
}

/**
 * Sets opposing treatment arch
 * @function
 * @param {String} opposing_treatment_arch - Opposing arch
 * @return {Object} Action type
 */
export function setOpposingTreatmentArch(opposing_treatment_arch) {
  return {
    type: SET_OPPOSING_TREATMENT_ARCH,
    opposing_treatment_arch: opposing_treatment_arch,
  };
}

/**
 * Sets upper opposing treatment arch
 * @function
 * @param {String} upper_opposing_treatment_arch - Opposing arch
 * @return {Object} Action type
 */
export function setUpperOpposingTreatmentArch(upper_opposing_treatment_arch) {
  return {
    type: SET_UPPER_OPPOSING_TREATMENT_ARCH,
    upper_opposing_treatment_arch: upper_opposing_treatment_arch,
  };
}

/**
 * Sets lower opposing treatment arch
 * @function
 * @param {String} lower_opposing_treatment_arch - Opposing arch
 * @return {Object} Action type
 */
export function setLowerOpposingTreatmentArch(lower_opposing_treatment_arch) {
  return {
    type: SET_LOWER_OPPOSING_TREATMENT_ARCH,
    lower_opposing_treatment_arch: lower_opposing_treatment_arch,
  };
}

/**
 * Handles event when user selects opposing treatment arch
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onOpposingTreatmentArchChange(event) {
  return (dispatch, getState) => {
    const { treatment_arch } = getState().teethChartReducer;

    if (treatment_arch === 'upper') {
      dispatch(setUpperOpposingTreatmentArch(event.target.value));
    } else if (treatment_arch === 'lower') {
      dispatch(setLowerOpposingTreatmentArch(event.target.value));
    } else {
      dispatch(setOpposingTreatmentArch(event.target.value));
    }

    dispatch(setError(false, '', 'text_box'));
    dispatch(setError(false, '', 'teeth_selection'));
  };
}

/**
 * Sets text box label
 * @function
 * @param {String} label - Text box label
 * @return {Object} Action type
 */
export function setTextBoxLabel(label) {
  return {
    type: SET_TEXT_BOX_LABEL,
    text_box_label: label,
  };
}

/**
 * Sets text box placeholder
 * @function
 * @param {String} placeholder - Placeholder text
 * @return {Object} Action type
 */
export function setTextBoxPlaceholder(placeholder) {
  return {
    type: SET_TEXT_BOX_PLACEHOLDER,
    text_box_placeholder: placeholder,
  };
}

/**
 * Handles event when user enters a note in the text box
 * @function
 * @param {Object} event - Event object
 * @return {Object} Action type
 */
export function onTextBoxNoteChange(event) {
  return (dispatch) => {
    dispatch({
      type: SET_TEXT_BOX_NOTE,
      text_box_note: removeEmoji(textFieldCheck(event.target.value)),
    });

    dispatch(setError(false, '', 'text_box'));
    dispatch(setError(false, '', 'teeth_selection'));
  };
}

/**
 * Sets text box note
 * @function
 * @param {String} note - Text box note
 * @return {Object} Action type
 */
export function setTextBoxNote(note) {
  return {
    type: SET_TEXT_BOX_NOTE,
    text_box_note: note,
  };
}

/**
 * Sets error to show on modal
 * @function
 * @param {Boolean} error - True or False
 * @param {String} error_message - Error message
 * @param {String} type - Error type
 * @return {Object} Action type
 */
export function setError(error, error_message, type) {
  return {
    type: SET_ERROR,
    error: {
      is_error: error,
      message: error_message,
      type: type,
    },
  };
}

/**
 * Clears teeth chart information
 * @function
 * @return {Object} Action type
 */
export function clearTeethChartInformation() {
  return {
    type: CLEAR_TEETH_CHART_INFORMATION,
  };
}

export function setDisabledTeeth(disabled_teeth) {
  return {
    type: SET_DISABLED_TEETH,
    disabled_teeth: disabled_teeth,
  };
}

export function setShowPresets() {
  return {
    type: SET_SHOW_PRESETS,
  };
}

export function onPresetSelect(preset_selection) {
  return (dispatch, getState) => {
    const { presets } = getState().teethChartReducer;
    dispatch({
      type: SET_PRESET_SELECTION,
      preset_selection: preset_selection,
    });

    dispatch({
      type: SET_SELECTED_TEETH,
      selected_teeth: presets[preset_selection],
    });
  };
}

/**
 * Set preset selection
 * @function
 * @param {List} selected_teeth - Selected teeth from modal
 * @return {Object} Action type
 */
function setPresetSelection(selected_teeth) {
  return (dispatch, getState) => {
    const { presets, treatment_arch } = getState().teethChartReducer;
    let select_preset = false;
    for (const preset in presets) {
      if (
        orderTeeth(removeOpposingTeeth(treatment_arch, selected_teeth)).join(',') === orderTeeth(removeOpposingTeeth(treatment_arch, presets[preset])).join(',')
      ) {
        dispatch({
          type: SET_PRESET_SELECTION,
          preset_selection: parseInt(preset),
        });
        select_preset = true;
        break;
      }
    }

    if (!select_preset) {
      dispatch({
        type: SET_PRESET_SELECTION,
        preset_selection: null,
      });
    }
  };
}
