export const Verbiage = {
  ChangesSummary: {
    Title: 'REVISION COMMENTS',
    TmtTitle: 'TOOTH MOVEMENTS TABLE - DOCTOR ADJUSTMENTS',
    IprTitle: 'TOTAL IPR/SPACING',
    NoChange: 'No Change',
  },
  ChangesModal: {
    Title: 'Revision Summary',
    CloseBtn: 'Close',
  },
  RevisionModal: {
    ConfirmTitle: 'Revision Summary',
    SubmitErrorTitle: 'Unable to Submit Revision Request',
    NoteOnly: 'Please confirm to submit your comments to receive a revised Smile Design.',
    ModelOnly: 'Please confirm your approval of your doctor adjusted Smile Design for fabrication of Smartwires. If further revisions are desired, please submit comments or continue editing the Smile Design.',
    NoteAndModelChange: 'Please confirm to submit your comments to receive a revised Smile Design based off your doctor adjusted Smile Design.',
    NoChange: 'No revisions were found in the Smile Design. Please include revision comments or design modifications to proceed.',
    CancelBtn: 'Cancel',
    OkBtn: 'Okay',
    ApproveBtn: 'Approve',
    ReviseBtn: 'Revise',
  },
  SidebarRevision: {
    Title: 'Revision Comments',
  },
};
