import React from 'react';
import PropTypes from 'prop-types';

import TeethUtils from '../../../../common/teeth_utils';

export const WasmBoltonTablePart = ({ title, values }) => {
  return (
    <div className='wasm-bolton-table-container'>
      <p className='wasm-bolton-table-title'>{title}</p>
      <div className='wasm-bolton-table'>
        {values.map((item) => {
          const [id, value] = item;
          const formattedValue = TeethUtils.formatTeethValue(value);
          const teethId = TeethUtils.fromISOToPalmer(id);
          return (
            <div className='wasm-bolton-table-row' key={id}>
              <div className='wasm-bolton-table-key'>{teethId}</div>
              <div className='wasm-bolton-table-value'>{formattedValue}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

WasmBoltonTablePart.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
