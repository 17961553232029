import {
  CLOSE_FEEDBACK_FORM,
  OPEN_FEEDBACK_FORM,
  RESTART_SUBMIT_FEEDBACK_FORM,
  SUBMIT_FEEDBACK_FORM_ERROR,
  SUBMIT_FEEDBACK_FORM_PENDING,
  SUBMIT_FEEDBACK_FORM_SUCCESS,
  TOGGLE_FEEDBACK_FORM,
} from '../../actions/ipp/feedback_form';

const initialState = {
  show_feedback_form: false,
  submit_feedback_form_pending: false,
  submit_feedback_form_success: false,
  submit_feedback_form_error: null,
};

export function feedbackFormReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_FEEDBACK_FORM:
      return {
        ...state,
        show_feedback_form: false,
      };

    case OPEN_FEEDBACK_FORM:
      return {
        ...state,
        show_feedback_form: true,
      };

    case TOGGLE_FEEDBACK_FORM:
      return {
        ...state,
        show_feedback_form: !state.show_feedback_form,
      };

    case SUBMIT_FEEDBACK_FORM_PENDING:
      return {
        ...state,
        submit_feedback_form_pending: true,
        submit_feedback_form_success: false,
        submit_feedback_form_error: null,
      };

    case SUBMIT_FEEDBACK_FORM_SUCCESS:
      return {
        ...state,
        submit_feedback_form_pending: false,
        submit_feedback_form_success: true,
        submit_feedback_form_error: null,
      };

    case SUBMIT_FEEDBACK_FORM_ERROR:
      return {
        ...state,
        submit_feedback_form_pending: false,
        submit_feedback_form_success: false,
        submit_feedback_form_error: action.error,
      };

    case RESTART_SUBMIT_FEEDBACK_FORM:
      return initialState

    default:
      return state;
  }
}

export const getShowFeedbackForm = (state) => state.feedbackFormReducer.show_feedback_form;
export const getSubmitFeedbackFormPending = (state) => state.feedbackFormReducer.submit_feedback_form_pending;
export const getSubmitFeedbackFormSuccess = (state) => state.feedbackFormReducer.submit_feedback_form_success;
export const getSubmitFeedbackFormError = (state) => state.feedbackFormReducer.submit_feedback_form_error;

