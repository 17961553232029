import {
  SET_DOCTOR_INFORMATION,
  SET_ADDITIONAL_ACTIONS_MODAL,
  SET_NOTE_MODAL,
  SET_DELETE_NOTE_MODAL,
  SET_VIEW_PLAN_MODAL,
  SET_VIEW_IFU_MODAL,
  SET_SELECTED_LIST_ITEM_ID,
} from '../../actions/ipp/case_list';

const initialState = {
  doctor_role: '',
  account_link_id: null,
  additional_actions_modal: false,
  note_modal: false,
  delete_note_modal: false,
  view_plan_modal: false,
  view_ifu_modal: false,
  view_ifu_modal_type: '',
  selected_list_item_id: '',
};

export function caseListReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCTOR_INFORMATION:
      return {
        ...state,
        doctor_role: action.doctor_role,
        account_link_id: action.account_link_id,
      };

    case SET_ADDITIONAL_ACTIONS_MODAL:
      return {
        ...state,
        additional_actions_modal: action.open,
      };

    case SET_NOTE_MODAL:
      return {
        ...state,
        note_modal: action.open,
      };

    case SET_DELETE_NOTE_MODAL:
      return {
        ...state,
        delete_note_modal: action.open,
      };

    case SET_VIEW_PLAN_MODAL:
      return {
        ...state,
        view_plan_modal: action.open,
        additional_actions_modal: false,
      };

    case SET_VIEW_IFU_MODAL:
      return {
        ...state,
        view_ifu_modal: action.open,
        additional_actions_modal: false,
        view_ifu_modal_type: action.view_ifu_modal_type,
      };

    case SET_SELECTED_LIST_ITEM_ID:
      return {
        ...state,
        selected_list_item_id: action.selected_list_item_id,
      };

    default:
      return state;
  }
}

export const getDoctorRole = (state) => state.caseListReducer.doctor_role;
export const getAccountLinkId = (state) => state.caseListReducer.account_link_id;
export const getAdditionalActionsModal = (state) => state.caseListReducer.additional_actions_modal;
export const getNoteModal = (state) => state.caseListReducer.note_modal;
export const getDeleteNoteModal = (state) => state.caseListReducer.delete_note_modal;
export const getViewPlanModal = (state) => state.caseListReducer.view_plan_modal;
export const getViewIfuModal = (state) => state.caseListReducer.view_ifu_modal;
export const getViewIfuModalType = (state) => state.caseListReducer.view_ifu_modal_type;
export const getSelectedListItemId = (state) => state.caseListReducer.selected_list_item_id;
