import {
  FETCH_ACCOUNT_INFORMATION_PENDING,
  FETCH_ACCOUNT_INFORMATION_PENDING_WITH_LOADER,
  FETCH_ACCOUNT_INFORMATION_SUCCESS,
  FETCH_ACCOUNT_INFORMATION_ERROR,
  SAVE_ROLE_PENDING,
  SAVE_ROLE_SUCCESS,
  SAVE_ROLE_ERROR,
  EDIT_PROVIDER_ID,
  SET_PROVIDER_ID,
  SAVE_PROVIDER_ID_PENDING,
  SAVE_PROVIDER_ID_SUCCESS,
  SAVE_PROVIDER_ID_ERROR,
  SET_EDIT_PROFILE,
  SAVE_EDIT_CHANGES_PENDING,
  SAVE_EDIT_CHANGES_SUCCESS,
  SAVE_EDIT_CHANGES_ERROR,
  SET_EDIT_ERROR_MESSAGE,
  SET_NEW_PROFILE,
  SET_NEW_ERROR_MESSAGE,
  SHOW_404_NOT_FOUND,
  SAVE_NEW_CHANGES_PENDING,
  SAVE_NEW_CHANGES_SUCCESS,
  SAVE_NEW_CHANGES_ERROR,
  OPEN_DISABLE_ACCOUNT_MODAL,
  CLOSE_DISABLE_ACCOUNT_MODAL,
  OPEN_RESET_LINK_ACCOUNT_MODAL,
  CLOSE_RESET_LINK_ACCOUNT_MODAL,
  FETCH_DSO_INFORMATION_PENDING,
  FETCH_DSO_INFORMATION_SUCCESS,
  FETCH_DSO_INFORMATION_ERROR,
  SET_DOCTOR_DROPDOWN_VALUE_CASES,
  SET_DOCTOR_DROPDOWN_VALUE_SMILES,
  SET_MISSING_DOCTOR_ERROR,
  DISABLE_ACCOUNT_PENDING,
  DISABLE_ACCOUNT_SUCCESS,
  DISABLE_ACCOUNT_ERROR,
  ENABLE_ACCOUNT_PENDING,
  ENABLE_ACCOUNT_SUCCESS,
  ENABLE_ACCOUNT_ERROR,
  ON_MOUNT,
} from '../../actions/ipp/edit_account';

const initialState = {
  account: '',
  account_error: false,
  account_pending: false,
  role: '',
  error: '',
  pending: false,
  edit_provider_id: false,
  provider_id: '',
  provider_id_old: '',
  //Edit Params
  edit_profile: {
    id: '',
    mode: '',
    value: '',
    type: '',
    notification: false,
    error: false,
    is_active: true,
  },
  //New Params
  new_profile: {
    mode: '',
    value: '',
    type: 'Office',
    notification: true,
    error: false,
    is_active: true,
  },
  new_error_msg: '',
  edit_error_msg: '',
  refresh: false,
  not_found_error: false,
  disable_account_modal: false,
  reset_link_account_modal: false,
  dso_info: {
    doctor: [],
    dso_doctors: [],
    smile_doctors: [],
    dso_addresses: [],
    dso_statuses: [],
  },
  selected_doctor_id_cases: '',
  selected_doctor_id_smiles: '',
  disable_account_error: false,
};

export function editAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ON_MOUNT:
      return initialState;

    case FETCH_ACCOUNT_INFORMATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_ACCOUNT_INFORMATION_PENDING_WITH_LOADER:
      return {
        ...state,
        account_pending: true,
      };
    case FETCH_ACCOUNT_INFORMATION_SUCCESS:
      return {
        ...state,
        account_pending: false,
        pending: false,
        account_error: false,
        account: action.account,
        role: action.account.role,
        provider_id: action.account.provider_id,
      };
    case FETCH_ACCOUNT_INFORMATION_ERROR:
      return {
        ...state,
        account_pending: false,
        pending: false,
        account_error: true,
      };

    case SAVE_ROLE_PENDING:
      return {
        ...state,
        pending: true,
        role: action.role,
      };
    case SAVE_ROLE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case SAVE_ROLE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case EDIT_PROVIDER_ID:
      return {
        ...state,
        edit_provider_id: action.edit_provider_id,
        provider_id_old: action.provider_id_old,
        provider_id: action.provider_id,
      };

    case SET_PROVIDER_ID:
      return {
        ...state,
        provider_id: action.provider_id,
      };

    case SAVE_PROVIDER_ID_PENDING:
      return {
        ...state,
        pending: true,
      };
    case SAVE_PROVIDER_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        edit_provider_id: false,
      };
    case SAVE_PROVIDER_ID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case SET_EDIT_PROFILE:
      return {
        ...state,
        edit_profile: action.edit_profile,
      };

    case SAVE_EDIT_CHANGES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case SAVE_EDIT_CHANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        account: action.account,
        edit_profile: action.edit_profile,
      };
    case SAVE_EDIT_CHANGES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case SET_EDIT_ERROR_MESSAGE:
      return {
        ...state,
        edit_error_msg: action.edit_error_msg,
      };

    case SET_NEW_PROFILE:
      return {
        ...state,
        new_profile: action.new_profile,
      };

    case SET_NEW_ERROR_MESSAGE:
      return {
        ...state,
        new_error_msg: action.new_error_msg,
      };

    case SHOW_404_NOT_FOUND:
      return {
        ...state,
        pending: false,
        not_found_error: true,
      };

    case SAVE_NEW_CHANGES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case SAVE_NEW_CHANGES_SUCCESS:
      return {
        ...state,
        pending: false,
        account: action.account,
        new_profile: action.new_profile,
        new_error_msg: action.new_error_msg,
      };
    case SAVE_NEW_CHANGES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case OPEN_DISABLE_ACCOUNT_MODAL:
      return {
        ...state,
        disable_account_modal: true,
      };

    case CLOSE_DISABLE_ACCOUNT_MODAL:
      return {
        ...state,
        disable_account_modal: false,
        disable_account_error: false,
        selected_doctor_id_cases: '',
        selected_doctor_id_smiles: '',
        missing_doctor_error: false,
      };

    case OPEN_RESET_LINK_ACCOUNT_MODAL:
      return {
        ...state,
        reset_link_account_modal: true,
      };

    case CLOSE_RESET_LINK_ACCOUNT_MODAL:
      return {
        ...state,
        reset_link_account_modal: false,
      };

    case FETCH_DSO_INFORMATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DSO_INFORMATION_SUCCESS:
      return {
        ...state,
        pending: false,
        dso_info: action.dso_info,
      };
    case FETCH_DSO_INFORMATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case SET_DOCTOR_DROPDOWN_VALUE_CASES:
      return {
        ...state,
        selected_doctor_id_cases: action.selected_doctor_id_cases,
        missing_doctor_error: false,
      };

    case SET_DOCTOR_DROPDOWN_VALUE_SMILES:
      return {
        ...state,
        selected_doctor_id_smiles: action.selected_doctor_id_smiles,
        missing_doctor_error: false,
      };

    case SET_MISSING_DOCTOR_ERROR:
      return {
        ...state,
        missing_doctor_error: true,
      };

    case DISABLE_ACCOUNT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case DISABLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
        disable_account_modal: false,
      };
    case DISABLE_ACCOUNT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    case ENABLE_ACCOUNT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case ENABLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case ENABLE_ACCOUNT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export const getAccount = (state) => state.editAccountReducer.account;
export const getAccountError = (state) => state.editAccountReducer.account_error;
export const getAccountPending = (state) => state.editAccountReducer.account_pending;
export const getResetLinkAccountModal = (state) => state.editAccountReducer.reset_link_account_modal;
export const getRole = (state) => state.editAccountReducer.role;
export const getEditProviderId = (state) => state.editAccountReducer.edit_provider_id;
export const getProviderId = (state) => state.editAccountReducer.provider_id;
export const getEditProfile = (state) => state.editAccountReducer.edit_profile;
export const getNewProfile = (state) => state.editAccountReducer.new_profile;
export const getEditErrorMessage = (state) => state.editAccountReducer.edit_error_msg;
export const getNewErrorMessage = (state) => state.editAccountReducer.new_error_msg;
export const get404NotFoundError = (state) => state.editAccountReducer.not_found_error;
export const getDisableAccountModal = (state) => state.editAccountReducer.disable_account_modal;
export const getDsoInformation = (state) => state.editAccountReducer.dso_info;
export const getSelectedDoctorIdCases = (state) => state.editAccountReducer.selected_doctor_id_cases;
export const getSelectedDoctorIdSmiles = (state) => state.editAccountReducer.selected_doctor_id_smiles;
export const getMissingDoctorError = (state) => state.editAccountReducer.missing_doctor_error;
