import React from 'react';

/**
 * Renders a textarea input element.
 * @param {Object} props - The props object.
 * @param {string} [props.className] - The class name for the textarea.
 * @returns {JSX.Element} - The textarea input element.
 */
function TextArea({ className, ...props }) {
  return <textarea className={`${className ? className : ''} text-input`} {...props} />;
}

TextArea.defaultProps = {
  rows: 3,
};

export default TextArea;
