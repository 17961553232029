import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';

import { isValidDate, makePresets } from '../../common/helpers';
import {
  defaultTeethReference as teethReference,
  isBracketTeethValid as isBracketTeethValidHelper,
  isSetupInstructionValid as isSetupInstructionValidHelper,
} from '../../common/tx_dx_form_utils';
import { removeEmoji, removeOpposingTeeth, textFieldLimited } from '../../common/functions';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';
import { isDateInBetween } from '../../common/date';

import DeForm3 from '../../doctor/de_submission/de_form3';
import ErrorMessage from '../../doctor/components/container/error_message';

import { splitStrToArray } from './edit_tx_de_plan';

/**
 * Displays the DE3.0 plan editing form
 * @component
 * @alias EditDE3Plan
 * @category BPP
 */
class EditDE3Plan extends Component {
  state = {
    ...this.props.treatment_plan,
    de3_teeth_restriction: splitStrToArray(this.props.treatment_plan.de3_teeth_restriction, { keepEmptyStrings: true }),
    de3_bracket_teeth: splitStrToArray(this.props.treatment_plan.de3_bracket_teeth, { keepEmptyStrings: true }),
    de3_missing_teeth: splitStrToArray(this.props.treatment_plan.de3_missing_teeth, { keepEmptyStrings: true }),
    de3_extract_teeth: splitStrToArray(this.props.treatment_plan.de3_extract_teeth, { keepEmptyStrings: true }),
    de3_next_appointment_date_warning: false,
    de3_intra_date_warning: false,
    de3_upper_smartwire_date_warning: false,
    de3_lower_smartwire_date_warning: false,
    de3_upper_arch_opposing_treat: this.props.treatment_plan.de3_arch_to_treat === 'upper' ? this.props.treatment_plan.de3_arch_opposing_treat : 'current',
    de3_lower_arch_opposing_treat: this.props.treatment_plan.de3_arch_to_treat === 'lower' ? this.props.treatment_plan.de3_arch_opposing_treat : 'current',
    de3_anterior_posterior_corrections: this.props.treatment_plan.de3_anterior_posterior_corrections
      ? JSON.parse(this.props.treatment_plan.de3_anterior_posterior_corrections)
      : {},
  };

  componentDidUpdate() {
    if (this.props.save_tx_plan_changes && this.props.treatment_plan) {
      if (this.isDEFormComplete() && this.isDEFormChanged()) {
        this.postTXPlan();
      } else {
        this.props.changeSaveValue();
      }
    }
  }

  /**
   * Performs a POST request to save the form and POST request to send email
   * @function
   */
  postTXPlan() {
    const newTxPlan = {
      description: 'Treatment Plan Edited - ' + this.props.description.trim(),
      ...this.state,
      de3_teeth_restriction: this.state.de3_teeth_restriction.toString(),
      de3_bracket_teeth: removeOpposingTeeth(this.state.de3_arch_to_treat, this.state.de3_bracket_teeth).toString(),
      de3_missing_teeth: this.state.de3_missing_teeth.toString(),
      de3_extract_teeth: this.state.de3_extract_teeth.toString(),
      de3_anterior_posterior_corrections: JSON.stringify(this.state.de3_anterior_posterior_corrections),
      de3_reason: this.state.de3_reason?.trim() ?? '',
      de3_auxiliaries_notes: this.state.de3_auxiliaries_notes?.trim() ?? '',
      de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes?.trim() ?? '',
      de3_setup_instruction: this.state.de3_setup_instruction?.trim() ?? '',
    };

    this.props.setStatus({ loading: true, submit: true });
    Axios.post(`/apiV2/treatmentplan/${this.props.case_id}`, newTxPlan)
      .then((resp) => {
        Axios.post(`/api/email/?slug=treatment-form-change&caseId=${this.props.case_id}&method=standard&provider=${window.location.origin}`);
        this.props.reloadInformation();
        this.props.setStatus({ loading: false });
      })
      .catch((err) => {
        this.props.setStatus({ loading: false, error: true });
      });
  }

  /**
   * Returns whether the de form has changed
   * @function
   * @return {boolean} - True or false
   */
  isDEFormChanged() {
    for (const key in this.state) {
      if (key === 'warning') {
        continue;
      } else if (key === 'de3_teeth_restriction' || key === 'de3_bracket_teeth' || key === 'de3_missing_teeth' || key === 'de3_extract_teeth') {
        const prev_value = splitStrToArray(this.props.treatment_plan[key]).filter(String);
        const state_value = this.state[key].filter(String);

        if (!(prev_value.every((elem) => state_value.includes(elem)) && state_value.every((elem) => prev_value.includes(elem)))) {
          return true;
        }
      } else if (key === 'de3_anterior_posterior_corrections') {
        if (this.props.treatment_plan[key].replaceAll(' ', '') !== JSON.stringify(this.state[key]).replaceAll(' ', '')) return true;
      } else {
        if (this.props.treatment_plan[key] !== this.state[key] && !key.includes('date_warning')) return true;
      }
    }

    const warning = '<li>No changes have been made. Please update the Digital Enhancement form to save.</li>';
    this.props.showWarning(warning);

    return false;
  }

  /**
   * Returns whether de form's required fields are completed
   * @function
   * @return {boolean} - True or false
   */
  isDEFormComplete() {
    const is_complete =
      this.props.description.trim() !== '' &&
      this.state.de3_intra_date !== '' &&
      this.state.de3_reason.trim() !== '' &&
      !this.state.de3_next_appointment_date_warning &&
      !this.state.de3_intra_date_warning &&
      !this.state.de3_upper_smartwire_date_warning &&
      !this.state.de3_lower_smartwire_date_warning &&
      this.isBracketTeethValid() &&
      this.isAnteriorPosteriorFieldValid() &&
      this.isAnteriorPosteriorNotesFieldValid() &&
      this.isAuxiliariesFieldValid() &&
      this.isSetupInstructionValid();

    if (!is_complete) {
      let errors = '';
      if (this.props.description.trim() === '') {
        if (document.querySelector('#tx_edit_desrcription_title')) {
          document.querySelector('#tx_edit_desrcription_title').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_heading')) {
          document.querySelector('#tx_edit_desrcription_heading').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_textarea')) {
          document.querySelector('#tx_edit_desrcription_textarea').classList.add('warning-border');
        }
        errors = errors + '<li><a href="#tx_edit_desrcription_heading">Description of Changes</a></li>';
      }

      if (!this.state.de3_reason.trim()) {
        if (document.querySelector('#de3_reason_textarea')) {
          document.querySelector('#de3_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de3_reason')) {
          document.querySelector('#de3_reason').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de3_reason">Reason</a></li>';
      }

      if (this.state.de3_next_appointment_date_warning) {
        if (document.querySelector('#de3_next_appointment_date_input')) {
          document.querySelector('#de3_next_appointment_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_next_appointment_date')) {
          document.querySelector('#de3_next_appointment_date').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de3_next_appointment_date">Next Appointment Date</a></li>';
      }

      if (this.state.de3_intra_date === '' || this.state.de3_intra_date_warning) {
        if (document.querySelector('#de3_intra_date_input')) {
          document.querySelector('#de3_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_intra_date')) {
          document.querySelector('#de3_intra_date').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de3_intra_date">Scan Date</a></li>';
      }

      if (!this.isBracketTeethValid()) {
        document.querySelector('#de3_bracket_teeth').classList.add('warning-text');
        errors = errors + '<li><a href="#de3_bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
        document.querySelector('#de3_anterior_posterior').classList.add('warning-text');
        if (!this.isAnteriorPosteriorFieldValid()) {
          handleAnteriorPosteriorCheckboxErrors(true);
        }
        if (!this.isAnteriorPosteriorNotesFieldValid()) {
          if (document.querySelector('textarea[data-field="de3_anterior_posterior_notes"]')) {
            document.querySelector('textarea[data-field="de3_anterior_posterior_notes"]').classList.add('warning-border');
          }
        }
        errors = errors + '<li><a href="#de3_anterior_posterior">Anterior-Posterior</a></li>';
      }

      if (!this.state.de3_midlines) {
        document.querySelector('#de3_midlines').classList.add('warning-text');
        errors = errors + '<li><a href="#de3_midlines">Midlines</a></li>';
      }

      if (!this.isAuxiliariesFieldValid()) {
        document.querySelector('#de3_auxiliaries').classList.add('warning-text');
        if (document.querySelector('textarea[data-field="de3_auxiliaries_notes"]')) {
          document.querySelector('textarea[data-field="de3_auxiliaries_notes"]').classList.add('warning-border');
        }
        errors = errors + '<li><a href="#de3_auxiliaries">Auxiliaries</a></li>';
      }

      if (!this.isSetupInstructionValid()) {
        if (document.querySelector('#de3_setup_instruction')) {
          document.querySelector('#de3_setup_instruction').classList.add('warning-text');
        }
        if (document.querySelector('textarea[data-field="de3_setup_instruction"]')) {
          document.querySelector('textarea[data-field="de3_setup_instruction"]').classList.add('warning-border');
        }
        if (document.querySelector('#de3_setup')) {
          document.querySelector('#de3_setup').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de3_setup">Additional Instructions</a></li>';
      }
      this.props.showWarning(errors);
    }

    return is_complete;
  }

  /**
   * Determines if anterior-posterior field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorFieldValid = () => {
    const correctionIsSelected = [this.state.de3_anterior_posterior_l, this.state.de3_anterior_posterior_r].includes('correction');
    if (correctionIsSelected) {
      return Object.values(this.state.de3_anterior_posterior_corrections).some((value) => value);
    }
    return true;
  };

  /**
   * Determines if anterior-posterior notes field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorNotesFieldValid = () => {
    const otherIsSelected = [this.state.de3_anterior_posterior_l, this.state.de3_anterior_posterior_r].includes('other');
    if (otherIsSelected) {
      return this.state.de3_anterior_posterior_notes && this.state.de3_anterior_posterior_notes.trim() !== '';
    }
    return true;
  };

  /**
   * Checks if DE3.0 bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns {boolean} - True or false
   */
  isBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat } = {}) => {
    const currentBracketTeeth = bracketTeeth || this.state.de3_bracket_teeth;
    const currentMissingTeeth = missingTeeth || this.state.de3_missing_teeth;
    const currentExtractTeeth = extractTeeth || this.state.de3_extract_teeth;
    const currentArchToTreat = archToTreat || this.state.de3_arch_to_treat;

    return isBracketTeethValidHelper({
      bracketTeeth: currentBracketTeeth,
      missingTeeth: currentMissingTeeth,
      extractTeeth: currentExtractTeeth,
      archToTreat: currentArchToTreat,
    });
  };

  /**
   * Determines if auxiliaries field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAuxiliariesFieldValid = () => {
    if (this.state.de3_auxiliaries === 'Yes') {
      return this.state.de3_auxiliaries_notes && this.state.de3_auxiliaries_notes.trim() !== '';
    }
    return true;
  };

  /**
   * Check if setupInstruction is valid based on selected setup
   * @param {object} data object with setupInstruction and setup
   * @param {string} data.setupInstruction - Setup Instruction
   * @param {string} data.setup - Setup
   * @returns {boolean} - True or false
   */
  isSetupInstructionValid = ({ setupInstruction, setup } = {}) => {
    const currentSetupInstruction = setupInstruction || this.state.de3_setup_instruction;
    const currentSetup = setup || this.state.de3_setup;

    return isSetupInstructionValidHelper({
      setupInstruction: currentSetupInstruction,
      setup: currentSetup,
    });
  };

  /**
   * Handles all input changes on the form
   * @function
   * @param {object} event - The event object on change
   */
  onInputChange_de3 = (event) => {
    let de3_reason = this.state.de3_reason;
    let de3_next_appointment_date = this.state.de3_next_appointment_date;
    let de3_intra_date = this.state.de3_intra_date;
    let de3_upper_smartwire = this.state.de3_upper_smartwire;
    let de3_upper_smartwire_date = this.state.de3_upper_smartwire_date;
    let de3_lower_smartwire = this.state.de3_lower_smartwire;
    let de3_lower_smartwire_date = this.state.de3_lower_smartwire_date;
    let de3_arch_to_treat = this.state.de3_arch_to_treat;
    let de3_arch_opposing_treat = this.state.de3_arch_opposing_treat;
    let de3_upper_arch_opposing_treat = this.state.de3_upper_arch_opposing_treat;
    let de3_lower_arch_opposing_treat = this.state.de3_lower_arch_opposing_treat;
    let de3_setup = this.state.de3_setup;
    let de3_setup_instruction = this.state.de3_setup_instruction;
    let de3_setup_additional_instruction = this.state.de3_setup_additional_instruction;
    let de3_teeth_restriction = this.state.de3_teeth_restriction;
    let de3_bracket_teeth = this.state.de3_bracket_teeth;
    let de3_missing_teeth = this.state.de3_missing_teeth;
    let de3_extract_teeth = this.state.de3_extract_teeth;
    let de3_intra_date_warning = this.state.de3_intra_date_warning;
    let de3_upper_smartwire_date_warning = this.state.de3_upper_smartwire_date_warning;
    let de3_lower_smartwire_date_warning = this.state.de3_lower_smartwire_date_warning;
    let de3_next_appointment_date_warning = this.state.de3_next_appointment_date_warning;
    let de3_anterior_posterior_r = this.state.de3_anterior_posterior_r;
    let de3_anterior_posterior_l = this.state.de3_anterior_posterior_l;
    let de3_anterior_posterior_corrections = this.state.de3_anterior_posterior_corrections;
    let de3_anterior_posterior_notes = this.state.de3_anterior_posterior_notes;
    let de3_midlines = this.state.de3_midlines;
    let de3_auxiliaries = this.state.de3_auxiliaries;
    let de3_auxiliaries_notes = this.state.de3_auxiliaries_notes;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';

    switch (event_type) {
      case 'de3_reason':
        de3_reason = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_next_appointment_date':
        de3_next_appointment_date = event.target.value;
        de3_next_appointment_date_warning = !isDateInBetween(de3_next_appointment_date, new Date(), '3000-01-01');
        break;

      case 'de3_intra_date':
        de3_intra_date = event.target.value;
        de3_intra_date_warning = !isValidDate(de3_intra_date);
        break;

      case 'de3_upper_smartwire':
        de3_upper_smartwire = event.target.value;
        if (de3_upper_smartwire === 'none') {
          de3_upper_smartwire_date = '';
        }
        break;

      case 'de3_upper_smartwire_date':
        de3_upper_smartwire_date = event.target.value;
        de3_upper_smartwire_date_warning = !isValidDate(de3_upper_smartwire_date);
        break;

      case 'de3_lower_smartwire':
        de3_lower_smartwire = event.target.value;

        if (de3_lower_smartwire === 'none') {
          de3_lower_smartwire_date = '';
        }
        break;

      case 'de3_lower_smartwire_date':
        de3_lower_smartwire_date = event.target.value;
        de3_lower_smartwire_date_warning = !isValidDate(de3_lower_smartwire_date);
        break;

      case 'de3_arch_to_treat':
        const de3_arch_to_treat_value = event.target.value;
        if (de3_arch_to_treat_value === 'upper') {
          de3_arch_opposing_treat = de3_upper_arch_opposing_treat;
        } else if (de3_arch_to_treat_value === 'lower') {
          de3_arch_opposing_treat = de3_lower_arch_opposing_treat;
        }
        de3_arch_to_treat = de3_arch_to_treat_value;
        break;

      case 'de3_arch_opposing_treat':
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_upper_arch_opposing_treat':
        de3_upper_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_lower_arch_opposing_treat':
        de3_lower_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_setup':
        de3_setup = event.target.value;
        break;

      case 'de3_setup_instruction':
        de3_setup_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_setup_additional_instruction':
        de3_setup_additional_instruction = event.target.value;
        break;

      case 'de3_teeth_restriction':
        if (_.intersection(de3_teeth_restriction, [event.target.value]).length > 0) {
          de3_teeth_restriction = _.difference(de3_teeth_restriction, [event.target.value]);
        } else {
          de3_teeth_restriction.push(event.target.value);
        }
        break;

      case 'de3_bracket_teeth':
        if (_.intersection(de3_bracket_teeth, [event.target.value]).length > 0) {
          de3_bracket_teeth = _.difference(de3_bracket_teeth, [event.target.value]);
        } else {
          de3_bracket_teeth.push(event.target.value);
        }
        this.props.clearWarning();
        break;

      case 'de3_missing_teeth':
        if (_.intersection(de3_missing_teeth, [event.target.value]).length > 0) {
          de3_missing_teeth = _.difference(de3_missing_teeth, [event.target.value]);
        } else {
          de3_missing_teeth.push(event.target.value);
        }
        break;

      case 'de3_extract_teeth':
        if (_.intersection(de3_extract_teeth, [event.target.value]).length > 0) {
          de3_extract_teeth = _.difference(de3_extract_teeth, [event.target.value]);
        } else {
          de3_extract_teeth.push(event.target.value);
        }
        break;

      case 'de3_anterior_posterior_r':
        de3_anterior_posterior_r = event.target.value;
        break;

      case 'de3_anterior_posterior_l':
        de3_anterior_posterior_l = event.target.value;
        break;

      case 'de3_anterior_posterior_corrections':
        const { name, checked } = event.target;
        de3_anterior_posterior_corrections = {
          ...de3_anterior_posterior_corrections,
          [name]: checked,
        };
        break;

      case 'de3_anterior_posterior_notes':
        de3_anterior_posterior_notes = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_midlines':
        de3_midlines = event.target.value;
        break;

      case 'de3_auxiliaries':
        de3_auxiliaries = event.target.value;
        break;

      case 'de3_auxiliaries_notes':
        de3_auxiliaries_notes = removeEmoji(textFieldLimited(event.target.value));

      default:
    }

    this.props.clearWarning();

    this.setState({
      de3_reason: de3_reason,
      de3_next_appointment_date: de3_next_appointment_date,
      de3_intra_date: de3_intra_date,
      de3_upper_smartwire: de3_upper_smartwire,
      de3_upper_smartwire_date: de3_upper_smartwire_date,
      de3_lower_smartwire: de3_lower_smartwire,
      de3_lower_smartwire_date: de3_lower_smartwire_date,
      de3_arch_to_treat: de3_arch_to_treat,
      de3_arch_opposing_treat: de3_arch_opposing_treat,
      de3_upper_arch_opposing_treat: de3_upper_arch_opposing_treat,
      de3_lower_arch_opposing_treat: de3_lower_arch_opposing_treat,
      de3_setup: de3_setup,
      de3_setup_instruction: de3_setup_instruction,
      de3_setup_additional_instruction: de3_setup_additional_instruction,
      de3_teeth_restriction: de3_teeth_restriction,
      de3_bracket_teeth: de3_bracket_teeth,
      de3_missing_teeth: de3_missing_teeth,
      de3_extract_teeth: de3_extract_teeth,
      de3_next_appointment_date_warning: de3_next_appointment_date_warning,
      de3_intra_date_warning: de3_intra_date_warning,
      de3_upper_smartwire_date_warning: de3_upper_smartwire_date_warning,
      de3_lower_smartwire_date_warning: de3_lower_smartwire_date_warning,
      de3_anterior_posterior_r: de3_anterior_posterior_r,
      de3_anterior_posterior_l: de3_anterior_posterior_l,
      de3_anterior_posterior_corrections: de3_anterior_posterior_corrections,
      de3_anterior_posterior_notes: de3_anterior_posterior_notes,
      de3_midlines: de3_midlines,
      de3_auxiliaries: de3_auxiliaries,
      de3_auxiliaries_notes: de3_auxiliaries_notes,
    });
  };

  /**
   * Retrieves teeth sets for DE
   * @function
   * @returns {object} - Teeth sets
   */
  getDE3ImpliedTeethSets = () => {
    const opposingTeeth = [];
    const hasUpper = this.de3HasUpper();
    const hasLower = this.de3HasLower();
    const setupIdeal = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'ideal';
    const setupCurrent = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'current';

    if (!hasUpper) {
      opposingTeeth.push(...teethReference.UR);
      opposingTeeth.push(...teethReference.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...teethReference.LR);
      opposingTeeth.push(...teethReference.LL);
    }

    const missingTeeth = this.state.de3_missing_teeth;
    const extractTeeth = _.difference(this.state.de3_extract_teeth, missingTeeth);
    const extractDisabled = missingTeeth;
    const extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    const bracketTeeth = _.difference(this.state.de3_bracket_teeth, bracketDisabled);

    let restrictImplied = _.difference(teethReference.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.de3_teeth_restriction, restrictImplied);
    if (setupCurrent) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    return {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };
  };

  /**
   * Determines if DE has lower arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasLower = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'lower';
  };

  /**
   * Determines if DE has upper arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasUpper = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'upper';
  };

  /**
   * Sets bracket teeth
   * @function
   * @param {list} teeth - Bracket teeth
   */
  setBracketTeeth = (teeth) => {
    const bracket_teeth =
      teeth !== undefined
        ? removeOpposingTeeth(this.state.de3_arch_to_treat, teeth)
        : removeOpposingTeeth(this.state.de3_arch_to_treat, this.state.de3_bracket_teeth);
    this.setState({
      de3_bracket_teeth: bracket_teeth,
    });
  };

  render() {
    return (
      <>
        <DeForm3
          onInputChange_de3={this.onInputChange_de3}
          onFocus={this.props.clearWarning}
          getDeHold={() => {}}
          photoUpload={this.props.photoUpload}
          getImpliedTeethSets={this.getDE3ImpliedTeethSets}
          hasUpper={this.de3HasUpper}
          hasLower={this.de3HasLower}
          teethReference={teethReference}
          gen_2={this.props.gen_2}
          minPreset={5}
          maxPreset={7}
          presets={makePresets(5, 7)}
          setBracketTeeth={this.setBracketTeeth}
          {...this.state}
        />
        <ErrorMessage
          title="Incomplete Fields"
          onClose={this.props.clearWarning}
          className={this.props.warning ? 'error-message-container' : 'error-message-container hide'}
        >
          <div id="warning-submit">Warning</div>
        </ErrorMessage>
      </>
    );
  }
}

export default EditDE3Plan;
