import './prospect_details.scss';

import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CircleLoader from '../../components/loader/circle_loader';
import { prospect_questions } from './prospect_config';
import { onReloadPage } from '../../common/helpers';
import {
  getDoctorIdFromPath,
  getRootPath,
  firstLetterCapital,
  modalClear,
  convertDate,
  setTokenHeader,
  zipNumberChar,
  removeDuplicatesFromString,
  removeEmoji,
  phoneNumberChar,
} from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { withRouter } from 'react-router-dom';
import Modal from '../../components/modal/modal';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';
import ContentHeader from '../components/content_header';
import CardContainer from '../components/container/card_container';
import Button from '../components/buttons/button';
import ErrorMessage from '../components/container/error_message';
import {
  DisplayValue,
  AddBar,
  ActionBar,
  MultiCheckboxField,
  DropdownField,
  RadioField,
  DateField,
  TextAreaField,
  CheckBoxField,
  TextField,
} from './helper_components';
import { dateToFormatted } from '../../common/date';

/**
 * Contains the Prospect Details
 * @component
 * @alias DoctorProspectDetails
 * @category IPP
 */
class CreateEditProspect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      edit_mode: this.props.edit_mode ? this.props.edit_mode : false,
      add_mode: this.props.add_mode ? this.props.add_mode : false,
      doctor_id: '',
      draft_id: '',
      questions: [],
      prospect_data: {},
      prospect_id: this.props.prospect_id ? this.props.prospect_id : '',
      prospect_status: '',
      modal_convert: false,
      modal_hold: false,
      modal_referral: false,
      modal_unhold: false,
      modal_status_comment: '',
      modal_dropdown_value: '',
      refresh: false,
      action: false,
      warning: false,
      warning_title: '',
    };
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const prospect_id = this.getProspectIdFromURL();
    const doctor_id = getDoctorIdFromPath(that.props.history.location.pathname);
    const questions = _.orderBy(prospect_questions, ['sort_order']);

    if (this.state.add_mode) {
      that.setState({
        loading: false,
        doctor_id: doctor_id,
        questions: that.attachValueToQuestion(questions, []),
        edit_mode: true,
      });
    } else {
      Axios.get(`/apiv3/prospect/${prospect_id}?doctor_id=${doctor_id}`)
        .then(function (res) {
          const prospect_data = res && res.data && res.data.prospects && res.data.prospects.length > 0 ? res.data.prospects[0] : [];
          const prospect_status = res && res.data && res.data.prospectstatus && res.data.prospectstatus.length > 0 ? res.data.prospectstatus[0] : [];
          const draft_id = res && res.data && res.data.draft_id ? res.data.draft_id : '';
          const case_id = res && res.data && res.data.case_id ? res.data.case_id : '';

          that.setState({
            loading: false,
            doctor_id: doctor_id,
            draft_id: draft_id,
            case_id: case_id,
            questions: that.attachValueToQuestion(questions, prospect_data),
            prospect_data: prospect_data,
            prospect_id: prospect_id,
            prospect_status: prospect_status,
            edit_mode: that.props.edit_mode ? true : false,
            failure_reason: '',
            failed_to_create: false,
          });
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    }
  }

  componentDidUpdate() {
    this.resetWarningLabel();
  }
  /**
   * Reset Warning if all the errors are cleared
   * @function
   */
  resetWarningLabel = () => {
    if (this.state.warning) {
      const has_field_error = this.state.questions.filter((question) => {
        return question.error;
      });

      const met_all_required_field = this.state.questions.filter((question) => {
        const dependency_met = this.hasMetDependency(this.state.questions, question);
        return question.required && dependency_met && (question.value === '' || question.value === null);
      });

      if (has_field_error.length === 0 && !this.state.action && met_all_required_field.length === 0) {
        this.setState({ warning: false });
        let warning = document.querySelector('#warning-submit');
        if (warning) {
          warning.classList.remove('warning-display');
          warning.innerHTML = '';

          this.setState({
            warning_title: '',
          });
        }
      }
    }
  };
  /**
   * Get ths Prospect Id from the URL
   * @function
   */
  getProspectIdFromURL = () => {
    const pathname = this.props.history && this.props.history.location && this.props.history.location.pathname ? this.props.history.location.pathname : '';
    const last_slash_index = pathname.lastIndexOf('/');
    return last_slash_index >= 0 ? parseInt(pathname.substr(last_slash_index + 1)) : '';
  };
  /**
   * Attach the response value to the question by looking at the database field (field_mapping)
   * @function
   */
  attachValueToQuestion = (questions, data) => {
    let copied_questions = _.cloneDeep(questions);

    for (let key in data) {
      for (let i = 0; i < copied_questions.length; i++) {
        if (copied_questions[i].field_mapping === key) {
          if ((copied_questions[i].field_mapping === 'ortho_tx_needed' || copied_questions[i].field_mapping === 'advertising_source') && data[key]) {
            let values = data[key].split(', ');
            let mapped_value = copied_questions[i].input_list.map((list) => {
              return list.value;
            });

            // Clean bad data
            values = values.filter((value) => {
              return value.trim() !== '';
            });

            // Capture "other" data
            let other_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Other:');
            });

            // Capture "referral" data
            let referral_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length === 0 && value.includes('Referral:');
            });

            // Capture recognized_values
            let recognized_values = values.filter((value) => {
              return _.intersection(mapped_value, [value]).length > 0;
            });

            if (other_values.length >= 1) {
              let other_value_key_index = 0;

              for (let i = 0; i < other_values.length; i++) {
                if (other_values[i].indexOf('Other:') >= 0) {
                  other_value_key_index = i;
                  other_values[i] = other_values[i].replace('Other:', '').trim();
                  break;
                }
              }

              if (other_value_key_index > 0) {
                let other_values_combined = other_values.slice(0, other_value_key_index);

                if (other_value_key_index !== other_values.length) {
                  other_values_combined.concat(other_values.slice(other_value_key_index + 1));
                }

                other_values_combined.unshift(other_values[other_value_key_index]);
                other_values = other_values_combined;
              }
            }

            if (referral_values.length >= 1) {
              let referral_value_key_index = 0;

              for (let i = 0; i < referral_values.length; i++) {
                if (referral_values[i].indexOf('Referral:') >= 0) {
                  referral_value_key_index = i;
                  referral_values[i] = referral_values[i].replace('Referral:', '').trim();
                  break;
                }
              }

              if (referral_value_key_index > 0) {
                let referral_values_combined = referral_values.slice(0, referral_value_key_index);

                if (referral_value_key_index !== referral_values.length) {
                  referral_values_combined.concat(referral_values.slice(referral_value_key_index + 1));
                }

                referral_values_combined.unshift(referral_values[referral_value_key_index]);
                referral_values = referral_values_combined;
              }
            }

            values = recognized_values.join(', ');
            let other = other_values.join(', ');
            let referral = referral_values.join(', ');

            copied_questions[i].value_other_org = _.cloneDeep(other.trim());
            copied_questions[i].value_other = _.cloneDeep(other.trim());
            copied_questions[i].value_referral = _.cloneDeep(referral.trim());
            copied_questions[i].value_org = _.cloneDeep(values);
            copied_questions[i].value = _.cloneDeep(values);
          } else {
            copied_questions[i].value_org = _.cloneDeep(data[key]);
            copied_questions[i].value = _.cloneDeep(data[key]);
          }
          break;
        }
      }
    }

    return copied_questions;
  };
  /**
   * Display the correct UI by input type or read
   * @function
   */
  selectDisplayInputUI = (question, dependency_met) => {
    const is_editing = this.state.edit_mode;
    const is_converted = this.is_converted();
    const is_disabled = !this.state.add_mode && question.editable_after_created === false ? !question.editable_after_created : false;

    if (!is_editing) {
      return <DisplayValue question={question} />;
    } else if (question && question.input_type === 'text') {
      return (
        <TextField
          question={question}
          onChange={this.onChangeTextField}
          onBlur={this.onBlurTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={this.state.warning}
        />
      );
    } else if (question && question.input_type === 'textarea') {
      return (
        <TextAreaField
          question={question}
          onChange={this.onChangeTextField}
          onBlur={this.onBlurTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={this.state.warning}
        />
      );
    } else if (question && question.input_type === 'date') {
      return (
        <DateField
          question={question}
          onChange={this.onChangeTextField}
          onBlur={this.onBlurTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={this.state.warning}
        />
      );
    } else if (question && question.input_type === 'checkbox') {
      return (
        <CheckBoxField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={this.state.warning}
        />
      );
    } else if (question && question.input_type === 'radio') {
      return (
        <RadioField question={question} onChange={this.onChangeTextField} is_converted={is_converted} disabled={is_disabled} dependency_met={dependency_met} />
      );
    } else if (question && question.input_type === 'dropdown') {
      return (
        <DropdownField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          warning={this.state.warning}
        />
      );
    } else if (question && question.input_type === 'multi-checkbox-other') {
      return (
        <MultiCheckboxField
          question={question}
          onChange={this.onChangeTextField}
          is_converted={is_converted}
          disabled={is_disabled}
          dependency_met={dependency_met}
          onOtherTextChange={this.onOtherTextChange}
          onReferralTextChange={this.onReferralTextChange}
          warning={this.state.warning}
        />
      );
    }
  };
  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onChangeTextField = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const multi_checkbox = data.type === 'multi-checkbox' ? true : false;
    const field_mapping = data.field_mapping ? data.field_mapping : id;

    let value =
      e && e.currentTarget && e.currentTarget.value
        ? e.currentTarget.type === 'checkbox'
          ? multi_checkbox && data && data.value
            ? data.value
            : e.currentTarget.checked
          : e.currentTarget.value
        : data && data.value
        ? data.value
        : '';

    if (e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.field_mapping && e.currentTarget.dataset.field_mapping === 'phone') {
      value = phoneNumberChar(value);
    }

    value = _.isString(value) ? removeEmoji(value) : value;
    if (field_mapping === 'postal_code') {
      value = zipNumberChar(value);
    }

    if (field_mapping.includes('_name')) {
      value = value
        .replace(/[^a-z\d\s-]+/gi, '')
        .replace(/\s\s+/g, ' ')
        .trimStart()
        .trimEnd();
    }

    if (multi_checkbox) {
      let current_value = this.getValueFromFieldMapping(field_mapping) ? this.getValueFromFieldMapping(field_mapping) : '';
      if (current_value !== '') {
        current_value = current_value.split(', ');
        if (_.intersection(current_value, [value]).length > 0) {
          current_value = current_value.filter((cv) => {
            return cv !== value;
          });
        } else {
          current_value.push(value);
        }
        value = current_value.join(', ');
      }
      if (value && !value.includes('Other')) {
        this.setQuestionOtherValue(field_mapping, '');
      }
      if (value && !value.includes('Referral')) {
        this.setQuestionReferralValue(field_mapping, '');
      }
    }

    const set_error = false;
    this.setQuestionValue(field_mapping, value, set_error);

    if (value === false || value === '') {
      this.clearAllDependencyResponse(field_mapping);
    }
  };
  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onOtherTextChange = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const field_mapping = data.field_mapping ? data.field_mapping : id;
    const value = e && e.currentTarget && e.currentTarget.value ? removeEmoji(e.currentTarget.value) : '';

    this.setQuestionOtherValue(field_mapping, value);
  };
  /**
   * Set the value to the correct field mapping and disabled setting error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onReferralTextChange = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const field_mapping = data.field_mapping ? data.field_mapping : id;
    const value = e && e.currentTarget && e.currentTarget.value ? removeEmoji(e.currentTarget.value) : '';

    this.setQuestionReferralValue(field_mapping, value);
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getValueFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let value = '';

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        value = question.value;
      }

      return '';
    });

    return value;
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getAllowableValueListFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let list = [];

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        list = question.field_mapping.input_list.map((input_list) => {
          return input_list.value;
        });
      }

      return '';
    });

    return list;
  };
  /**
   * Retrieve unique_id from the list of question by field mapping name
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  getUniqueIdFromFieldMapping = (field_mapping) => {
    const questions = this.state.questions;
    let unique_id = '';

    questions.map((question) => {
      if (question.field_mapping === field_mapping) {
        unique_id = question.unique_id;
      }

      return '';
    });

    return unique_id;
  };
  /**
   * Make the value of all dependent question empty
   * @param {String} field_mapping - The string of the field mapping name
   * @function
   */
  clearAllDependencyResponse = (field_mapping) => {
    const questions = this.state.questions;
    const unique_id = this.getUniqueIdFromFieldMapping(field_mapping);

    questions.map((question) => {
      if (question.dependency_unique_id && question.dependency_unique_id === unique_id) {
        this.setQuestionValue(question.field_mapping, '', false);
      }

      return '';
    });
  };
  /**
   * When blur adjust the text input or show an error
   * @param {Object} e - The event handler for the input fields
   * @function
   */
  onBlurTextField = (e) => {
    const data = e && e.currentTarget && e.currentTarget.dataset;
    const id = e && e.currentTarget && e.currentTarget.id;
    const field_mapping = data.field_mapping ? data.field_mapping : id;
    const set_error = true;
    let value = e && e.currentTarget && e.currentTarget.value ? (e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value) : '';
    value = value.trim();
    if (field_mapping.includes('_name')) {
      value = value
        .replace(/[^a-z\d\s-]+/gi, '')
        .replace(/\s\s+/g, ' ')
        .trimStart()
        .trimEnd();
    } else if (field_mapping === 'postal_code') {
      value = zipNumberChar(value);
    }

    this.setQuestionValue(field_mapping, value, set_error);
  };
  /**
   * Set the question and value
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @param {Boolean} set_error - Decided wether or not to change the error state
   * @function
   */
  setQuestionValue = (field_mapping, value, set_error) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        if (questions[i].input_capitalize) {
          value = firstLetterCapital(value);
        }

        questions[i].value = value;

        if (set_error) {
          questions[i].error = questions[i].validation ? !questions[i].validation(value) : false;
        }

        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Set the question and value_other
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @function
   */
  setQuestionOtherValue = (field_mapping, value) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        questions[i].value_other = value;
        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Set the required questions error to be true
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @function
   */
  setAllQuestionErrorValue = () => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      const dependency_met = this.hasMetDependency(questions, questions[i]);
      if (questions[i].required && dependency_met && (questions[i].value === '' || questions[i].value === null)) {
        questions[i].error = true;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Set the question and value_referral
   * @param {String} field_mapping - The database field name
   * @param {String} value - Assigned value to the field
   * @function
   */
  setQuestionReferralValue = (field_mapping, value) => {
    let questions = this.state.questions;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].field_mapping === field_mapping) {
        questions[i].value_referral = value;
        break;
      }
    }

    this.setState({
      questions: questions,
    });
  };
  /**
   * Undo changes by reverting to original value when user want to cancel changes
   * @function
   */
  onCancelClick = () => {
    let questions = _.cloneDeep(this.state.questions);

    for (let i = 0; i < questions.length; i++) {
      questions[i].value = _.cloneDeep(questions[i].value_org);
      questions[i].error = false;

      if (questions[i].value_other_org) {
        questions[i].value_other = _.cloneDeep(questions[i].value_other_org);
      }
    }

    this.setState({
      questions: questions,
      edit_mode: false,
    });
    this.props.onCancelClick();
  };
  /**
   * Get all the question and map them to the correct field value for a post call
   * @function
   */
  gatherData = () => {
    let questions = _.cloneDeep(this.state.questions);
    let data = {};
    let group_allowed = [1, 2, 3, 4];

    for (let i = 0; i < questions.length; i++) {
      if (group_allowed.includes(questions[i].group)) {
        data[questions[i].field_mapping] = questions[i].value;

        if (questions[i].value_other) {
          data[questions[i].field_mapping] =
            data[questions[i].field_mapping] && data[questions[i].field_mapping] !== null
              ? data[questions[i].field_mapping] + ', Other: ' + questions[i].value_other.replace(',', '')
              : 'Other: ' + questions[i].value_other.replace(',', '');
        }

        if (questions[i].value_referral) {
          data[questions[i].field_mapping] =
            data[questions[i].field_mapping] && data[questions[i].field_mapping] !== null
              ? data[questions[i].field_mapping] + ', Referral: ' + questions[i].value_referral.replace(',', '')
              : 'Referral: ' + questions[i].value_referral.replace(',', '');
        }

        if (questions[i].input_type.includes('multi-checkbox-other')) {
          data[questions[i].field_mapping] = removeDuplicatesFromString(data[questions[i].field_mapping]);
        }
      }
    }

    return data;
  };
  /**
   * Update original value to have the new value
   * @function
   */
  setOrgDataToCurrentValue = () => {
    let questions = _.cloneDeep(this.state.questions);
    for (let i = 0; i < questions.length; i++) {
      questions[i].value_org = _.cloneDeep(questions[i].value);
    }

    this.setState({
      questions: questions,
      edit_mode: false,
      action: false,
    });
  };
  /**
   * Save the changes for the current prospect record
   * @function
   */
  onSaveClick = () => {
    let has_field_error = this.state.questions.filter((question) => {
      return question.error;
    });

    const parents_submitting_for_child = this.state.questions.filter((question) => {
      return question.field_mapping === 'parent_submitting_for_child';
    });

    const met_all_required_field = this.state.questions.filter((question) => {
      const dependency_met = this.hasMetDependency(this.state.questions, question);
      return question.required && dependency_met && (question.value === '' || question.value === null);
    });

    let i;
    for (i = 0; i < has_field_error.length; i++) {
      if (!parents_submitting_for_child.value) {
        if (has_field_error[i].field_mapping === 'parent_first_name' || has_field_error[i].field_mapping === 'parent_last_name') {
          has_field_error.pop(i);
        }
      }
    }

    if (has_field_error.length === 0 && !this.state.action && met_all_required_field.length === 0) {
      const data = this.gatherData();
      const that = this;
      const prospect_id = this.state.prospect_id;
      const prospect_data = this.state.prospect_data;
      that.setActionInProgress();

      Axios.put(`/apiv3/prospect/${prospect_id}`, data)
        .then(function (res) {
          if (res.data && res.data.failure_reason && res.data.failure_reason.includes('Lead already exists')) {
            const failure_reason = 'Account email already exists in the system and may not be assigned to you. Please try another email.';
            that.setState({ failure_reason: failure_reason, failed_to_create: true, action: false });
          } else {
            that.setOrgDataToCurrentValue();
            prospect_data.last_update_recorded = dateToFormatted();
            that.setState({ prospect_data: prospect_data });
            that.props.loadInformation();
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      this.setAllQuestionErrorValue();
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul class="wizard-error-text"><li>Please complete required fields.</li></ul>';

        this.setState({
          warning: true,
          warning_title: 'Incomplete Fields',
        });
      } else {
        this.setState({
          warning: true,
        });
      }
    }
  };
  /**
   * Creates a brand new prospect and redirect the user
   * @function
   */
  onCreateClick = () => {
    const has_field_error = this.state.questions.filter((question) => {
      return question.error;
    });

    const met_all_required_field = this.state.questions.filter((question) => {
      const dependency_met = this.hasMetDependency(this.state.questions, question);
      return question.required && dependency_met && (question.value === '' || question.value === null);
    });

    if (has_field_error.length === 0 && !this.state.action && met_all_required_field.length === 0) {
      const data = this.gatherData();
      const rootPath = getRootPath(this.props.history.location.pathname);
      const that = this;
      that.setActionInProgress();
      data['doctor_id'] = this.state.doctor_id;

      Axios.post(`/apiv3/prospect`, data)
        .then(function (res) {
          const prospect_id = res && res.data && res.data.prospect_id ? res.data.prospect_id : '';
          let failure_reason = res && res.data && res.data.failure_reason ? res.data.failure_reason : '';
          if (res.data.failure_reason && res.data.failure_reason.includes('Lead already exists')) {
            failure_reason = 'Account email already exists in the system and may not be assigned to you. Please try another email.';
          }
          if (prospect_id) {
            const pathname = `${rootPath}/prospect/edit/${prospect_id}`;
            that.props.history.push({ pathname: pathname, state: { refreshInfo: 'true' } });
          } else if (failure_reason) {
            that.setState({ failure_reason: failure_reason, failed_to_create: true, action: false });
          }
        })
        .catch(function (err) {
          handleHttpRequestError(err, that);
        });
    } else {
      this.setAllQuestionErrorValue();
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul class="wizard-error-text"><li>Please complete required fields.</li></ul>';

        this.setState({
          warning: true,
          warning_title: 'Incomplete Fields',
        });
      }
    }
  };
  /**
   * Update the prospect status in the state
   * @param {String} status - Value of the new status to be changed to
   * @param {String} status_comment - Status comment value
   * @function
   */
  updateProspectStatus = (status, status_comment) => {
    let prospect_data = _.cloneDeep(this.state.prospect_data);
    let prospect_status = { status_comment: status_comment };
    prospect_data.prospect_status = status;
    prospect_data.last_update_recorded = dateToFormatted();

    this.setState({ prospect_data: prospect_data, prospect_status: prospect_status });
    this.onModalDismiss();
  };
  /**
   * Update the draft id in the state
   * @param {String} draft_id - Value of the draft id status needs to change to
   * @function
   */
  updateDraftId = (draft_id) => {
    this.setState({ draft_id: draft_id });
  };
  /**
   * Call an api to update database with new status change
   * @param {String} status - Value of the new status to be changed to
   * @param {String} api_endpoint - The endpoint of the status change
   * @function
   */
  onClickUpdateStatus = (status, api_endpoint) => {
    const that = this;
    const prospect_id = this.state.prospect_id;
    const doctor_id = this.state.doctor_id;
    const status_comment = this.state.modal_dropdown_value
      ? this.state.modal_dropdown_value === 'Other'
        ? `${this.state.modal_dropdown_value}: ${this.state.modal_status_comment}`
        : this.state.modal_dropdown_value
      : this.state.modal_status_comment;

    let data = { status_comment: status_comment };

    if (status === 'Converted to Draft') {
      let gatherData = this.gatherData();
      gatherData.status_comment = data.status_comment;
      gatherData.doctor_id = doctor_id;
      data = gatherData;
      data['create_draft'] = true;
    }

    Axios.put(`/apiv3/prospect/${prospect_id}/${api_endpoint}`, data)
      .then(function (res) {
        if (res.data.draft_id) {
          that.updateDraftId(res.data.draft_id);
        }

        if (api_endpoint === 'referrallost') {
          that.setQuestionValue('referred_lost_reason', status_comment, false);
          status = 'Prospect Lost';
        }

        if (status === 'Converted to Draft') {
          that.onDraftRedirect();
        }

        that.updateProspectStatus(status, status_comment);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };
  /**
   * Display the correct modal
   * @param {String} modal_name - The name of the modal
   * @function
   */
  displayModal = (modal_name) => {
    let modal = {};
    modal[modal_name] = true;

    this.setState(modal);
  };
  /**
   * Clear modal from screen
   * @function
   */
  onModalDismiss = () => {
    this.setState({
      modal_convert: false,
      modal_hold: false,
      modal_referral: false,
      modal_unhold: false,
      modal_status_comment: '',
      modal_dropdown_value: '',
    });

    modalClear();
  };
  /**
   * Update value of status comment
   * @param {Object} e - The event related to the status comment field
   * @function
   */
  setModalStatusComment = (e) => {
    this.setState({
      modal_status_comment: e.target.value,
    });
  };
  /**
   * An action is in progress so no other action should take place
   * @function
   */
  setActionInProgress = () => {
    this.setState({
      action: true,
    });
  };
  /**
   * Decide which modal configuration and display it
   * @function
   */
  onModalShow = () => {
    const that = this;
    let preset = 'decision';
    let modal_setting = {
      header_text: '',
      confirm_btn_text: '',
      close_btn_text: '',
      message_text: '',
      onCloseButtonClick: that.onModalDismiss,
      onConfirmButtonClick: null,
    };

    if (this.state.modal_referral) {
      preset = 'decision-dialog';
      modal_setting.header_text = 'Referral Lost';
      modal_setting.confirm_btn_text = 'Referral Lost';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to mark prospect as a lost referral?';
      modal_setting.onTextAreaChange = this.setModalStatusComment;
      modal_setting.onDropdownChange = (e) => {
        this.setState({ modal_dropdown_value: e.target.value });
      };
      modal_setting.dropdown = true;
      modal_setting.default_text = 'Select Reason';
      modal_setting.label_dropdown = 'Referred Lost Reason:';
      modal_setting.dropdown_error = 'Please select a referral reason or select other with a reason description';
      modal_setting.textarea_required = false;
      modal_setting.hold_cancel_reasons = [
        //reused this code, but this just a list of value for the dropdown
        { category: 'Referral Lost', reason: 'Pending patient approval' },
        { category: 'Referral Lost', reason: 'Cost' },
        { category: 'Referral Lost', reason: 'Distance' },
        { category: 'Referral Lost', reason: 'No Response for Appointment ' },
        { category: 'Referral Lost', reason: 'Not Treatable Candidate' },
        { category: 'Referral Lost', reason: 'Timing' },
        { category: 'Referral Lost', reason: 'Transferred to Another Provider' },
        { category: 'Referral Lost', reason: 'Other' },
      ];
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Referral Lost', 'referrallost');
      };
    } else if (this.state.modal_hold) {
      preset = 'decision-dialog';
      modal_setting.header_text = 'Hold Prospect';
      modal_setting.confirm_btn_text = 'Hold';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to put prospect on hold?';
      modal_setting.onTextAreaChange = this.setModalStatusComment;
      modal_setting.textarea_placeholder = 'Specify reason for hold';
      modal_setting.textarea_required = false;
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Hold', 'hold');
      };
    } else if (this.state.modal_convert) {
      modal_setting.header_text = 'Convert Prospect';
      modal_setting.confirm_btn_text = 'Convert';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = <div>Are you sure you would like to convert prospect? You will be redirected to the Submit a Case page</div>;
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Converted to Draft', 'convert');
      };
    } else if (this.state.modal_unhold) {
      modal_setting.header_text = 'Reverse Hold';
      modal_setting.confirm_btn_text = 'Reverse Hold';
      modal_setting.close_btn_text = 'Cancel';
      modal_setting.message_text = 'Are you sure you would like to reverse hold?';
      modal_setting.onConfirmButtonClick = () => {
        this.onClickUpdateStatus('Active', 'unhold');
      };
    }

    return modal_setting.header_text ? <Modal preset={preset} {...modal_setting} theme="ipp" /> : null;
  };
  /**
   * Determine if prospect record can still be editable
   * @function
   */
  is_editable = () => {
    return this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status !== 'Prospect Lost';
  };
  /**
   * Determine if prospect record is held
   * @function
   */
  is_held = () => {
    return this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status === 'Hold';
  };
  /**
   * Determine if prospect record is converted
   * @function
   */
  is_converted = () => {
    return (
      this.state.prospect_data &&
      this.state.prospect_data.prospect_status &&
      (this.state.prospect_data.prospect_status.toLowerCase() === 'converted to draft' ||
        this.state.prospect_data.prospect_status.toLowerCase() === 'converted to case')
    );
  };
  /**
   * Determine if prospect record is converted to a Case
   * @function
   */
  is_a_case = () => {
    return (
      this.state.prospect_data && this.state.prospect_data.prospect_status && this.state.prospect_data.prospect_status.toLowerCase() === 'converted to case'
    );
  };
  /**
   * Display the correct status heading
   * @param {String} status - The status of the prospect record
   * @function
   */
  displayStatus = (status) => {
    if (status === 'Referral Lost') {
      const status_comment = this.state.prospect_status && this.state.prospect_status.status_comment;
      status = `${status} - ${status_comment}`;
    }

    return status;
  };
  /**
   * Redirect user to draft page
   * @function
   */
  onDraftRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const draft_id = this.state.draft_id;
    const pathname = `${rootPath}/submission/patient?case_id=${draft_id}`;

    this.props.history.push(pathname);
  };
  /**
   * Redirect user to case details page
   * @function
   */
  onCaseRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const case_id = this.state.case_id;
    const pathname = `${rootPath}/case/${case_id}`;

    this.props.history.push(pathname);
  };
  /**
   * Closes warning message
   * @function
   */
  removeWizardErrorMsg = () => {
    this.setState({
      warning: false,
    });
  };
  /**
   * Redirect user to prospect list
   * @function
   */
  onListRedirect = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}?filter=prospect_list`;

    this.props.history.push(pathname);
  };
  /**
   * Check if dependency is met
   * @function
   */
  hasMetDependency = (questions, question) => {
    const dependency_unique_id = question.dependency_unique_id;
    const dependency_value = question.dependency_value;

    if (dependency_unique_id === undefined) {
      return true;
    }

    const acceptable_condition = questions.filter((question) => {
      if (dependency_value && dependency_value !== '') {
        return question.unique_id === dependency_unique_id && question.value && question.value === dependency_value;
      } else {
        return question.unique_id === dependency_unique_id && question.value && question.value !== '';
      }
    });

    return acceptable_condition.length > 0;
  };
  /**
   * A helper functions which will cause a click for check box label area
   * @param {Object} e - The event of the text object
   * @function
   */
  selectCheckbox = (e) => {
    const field_mapping = e && e.currentTarget && e.currentTarget.dataset && e.currentTarget.dataset.field_mapping ? e.currentTarget.dataset.field_mapping : '';

    if (field_mapping) {
      const element = document.querySelector(`input[data-field_mapping=${field_mapping}]`);
      if (element) {
        element.click();
      }
    }
  };
  /**
   * Display Question UI
   * @param {Array} questions - Contains the questions
   * @param {Object} prospect_data - Contains the state data of the prospect data
   * @function
   */
  displayQuestion = (questions, prospect_data) => {
    const groups = [
      {
        title: 'Personal Details',
        content: questions.filter((question) => question.group === 1),
        show_divider: false,
        split_id: 3,
      },
      {
        title: 'Contact Details',
        content: questions.filter((question) => question.group === 2),
        show_divider: true,
        split_id: 6,
      },
      {
        title: '',
        content: questions.filter((question) => question.group === 3),
        show_divider: false,
        split_id: 7,
      },
      {
        title: '',
        content: questions.filter((question) => question.group === 4),
        show_divider: false,
        split_id: 11,
      },
    ];

    const phone_question = questions.filter((question) => question.field_mapping === 'phone')[0];
    const email_question = questions.filter((question) => question.field_mapping === 'email')[0];

    return groups.map((group, index) => {
      return (
        <React.Fragment key={index}>
          <div className="display-prospect-questions">
            <div className="row">
              <div className="col-lg-12">
                {group.title ? (
                  <h3
                    className={
                      group.title === 'Personal Details'
                        ? ''
                        : (this.state.edit_mode || this.state.add_mode) && index === 0
                        ? 'prospect-title prospect-title--no-border'
                        : group.show_divider
                        ? 'prospect-title'
                        : 'prospect-title prospect-title--no-border prospect-title--fake-section'
                    }
                  >
                    {group.title}
                  </h3>
                ) : null}

                <div className="row">
                  <>
                    {group.content.map((question) => {
                      const dependency_met = this.hasMetDependency(questions, question);
                      const display =
                        (this.state.edit_mode || this.state.add_mode || question.hide_on_detail === undefined || !question.hide_on_detail) && dependency_met;
                      if (question.blank) {
                        return (
                          <div
                            id={question.unique_id}
                            key={question.unique_id}
                            className={this.state.edit_mode || this.state.add_mode ? 'col-lg-12' : 'col-lg-12 min-height'}
                          ></div>
                        );
                      } else if (question.unique_id % 2 !== 0 && display) {
                        return (
                          <div className="col-lg-6">
                            <div id={question.unique_id} key={question.unique_id} className="col-lg-12">
                              <div
                                className={
                                  question.input_type === 'textarea'
                                    ? 'prospect-input prospect-input--textarea'
                                    : question.input_type === 'checkbox' && (this.state.edit_mode || this.state.add_mode)
                                    ? question.field_mapping === 'parent_submitting_for_child'
                                      ? 'prospect-input prospect-input--30-min-height  prospect-input--checkbox'
                                      : 'prospect-input prospect-input--checkbox'
                                    : 'prospect-input'
                                }
                              >
                                {question.input_type === 'checkbox' && (this.state.edit_mode || this.state.add_mode) ? (
                                  <React.Fragment>
                                    <div className="prospect-input-field">{this.selectDisplayInputUI(question, dependency_met)}</div>
                                    <div
                                      className={
                                        question.error && this.state.warning ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'
                                      }
                                      data-field_mapping={question.field_mapping}
                                      onClick={this.selectCheckbox}
                                    >
                                      {question.label}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      className={
                                        question.error && this.state.warning ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'
                                      }
                                    >
                                      {question.label}{' '}
                                      {question.input_type === 'multi-checkbox-other' ? (
                                        <span className="prospect-input-label--addon">(Select all that apply)</span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div className="prospect-input-field">{this.selectDisplayInputUI(question, dependency_met)}</div>
                                  </React.Fragment>
                                )}
                                {question.error && this.state.warning ? (
                                  <div className="prospect-input-label-error prospect-input-label-error--small">{question.error_message}</div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (question.unique_id % 2 === 0 && display) {
                        return (
                          <div className="col-lg-6">
                            <div id={question.unique_id} key={question.unique_id} className="col-lg-12">
                              {question.field_mapping === 'sms_comms' || question.field_mapping === 'email_comms' ? (
                                <div className="prospect-input-label"></div>
                              ) : null}
                              <div
                                className={
                                  question.input_type === 'textarea'
                                    ? 'prospect-input prospect-input--textarea'
                                    : question.input_type === 'checkbox' && (this.state.edit_mode || this.state.add_mode)
                                    ? question.field_mapping === 'sms_comms' || question.field_mapping === 'email_comms'
                                      ? 'prospect-input prospect-input--30-min-height  prospect-input--checkbox'
                                      : 'prospect-input prospect-input--checkbox'
                                    : 'prospect-input'
                                }
                              >
                                {question.input_type === 'checkbox' && (this.state.edit_mode || this.state.add_mode) ? (
                                  <React.Fragment>
                                    <div className="prospect-input-field">{this.selectDisplayInputUI(question, dependency_met)}</div>
                                    <div
                                      className={
                                        question.error && this.state.warning ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'
                                      }
                                      data-field_mapping={question.field_mapping}
                                      onClick={this.selectCheckbox}
                                    >
                                      {question.label}
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <div
                                      className={
                                        question.error && this.state.warning ? 'prospect-input-label prospect-input-label-error' : 'prospect-input-label'
                                      }
                                    >
                                      {question.label}{' '}
                                      {question.input_type === 'multi-checkbox-other' ? (
                                        <span className="prospect-input-label--addon">(Select all that apply)</span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                    <div className="prospect-input-field">{this.selectDisplayInputUI(question, dependency_met)}</div>
                                  </React.Fragment>
                                )}
                                {question.error && this.state.warning ? (
                                  <div className="prospect-input-label-error prospect-input-label-error--small">{question.error_message}</div>
                                ) : (
                                  ''
                                )}
                              </div>
                              {question.field_mapping === 'sms_comms' && phone_question.error && this.state.warning ? (
                                <div className="prospect-input-label"></div>
                              ) : null}
                              {question.field_mapping === 'email_comms' && email_question.error && this.state.warning ? (
                                <div className="prospect-input-label"></div>
                              ) : null}
                            </div>
                          </div>
                        );
                      }

                      return null;
                    })}
                  </>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };
  /**
   * Closes modal for prospect failed
   * @function
   */
  onProspectFailedOkClicked = () => {
    this.setState({ failed_to_create: false });
    modalClear();
  };
  /**
   * Display Edit Btn or not
   * @function
   */
  displayEditBtn = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) && this.is_editable() ? (
            <div className="wall-right">
              <Button
                onClick={() => {
                  this.setState({ edit_mode: true });
                }}
              >
                Edit
              </Button>
            </div>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  getInitialsFromName = () => {
    let initials = '';
    const name_fields = this.getProspectNameFields();

    if (name_fields.length > 0) {
      const initial_list = name_fields.map((field) => {
        return field.value && field.value[0] ? field.value[0].toUpperCase() : '';
      });

      initials = initial_list.join('');
    }

    return initials;
  };

  getProspectNameFields = () => {
    const name_fields = this.state.questions.filter((question) => {
      return question.field_mapping === 'first_name' || question.field_mapping === 'last_name';
    });

    return name_fields;
  };

  displayFullName = () => {
    let prospect_name = '';
    const name_fields = this.getProspectNameFields();

    if (name_fields.length > 0) {
      const values = name_fields.map((field) => {
        return field.value;
      });

      prospect_name = values.join(' ');
    }
    return prospect_name;
  };

  displayUserStatus = () => {
    return (
      <div className="account__data">
        <div className="account__initials">{this.getInitialsFromName()}</div>
        <div className="account__info">
          <div className="prospect-detail-heading-item break-text">
            <h3 className="emphasis">{this.displayFullName()}</h3>
          </div>
          <div className="prospect-detail-heading-item">
            <span className="emphasis">Status:</span>
            <span>
              {this.state.prospect_data && this.state.prospect_data.prospect_status ? this.displayStatus(this.state.prospect_data.prospect_status) : 'N/A'}
            </span>
          </div>
          <div className="prospect-detail-heading-item">
            <span className="emphasis">Created Date:</span>
            <span>{this.state.prospect_data && this.state.prospect_data.created_date ? convertDate(this.state.prospect_data.created_date) : 'N/A'}</span>
          </div>
          <div className="prospect-datestamp">{`Last Updated Recorded: ${dateToFormatted(this.state.prospect_data.last_updated_time)}`}</div>
        </div>
      </div>
    );
  };

  render() {
    const title = this.state.add_mode ? 'Create New Prospect' : 'Prospect Details';

    return (
      <div>
        <Helmet>
          <title>{title} | InBrace Smile Design Studio™</title>
        </Helmet>
        {this.state.add_mode ? <ContentHeader title={title} /> : null}
        {this.state.loading ? (
          <CircleLoader fullscreen={true} />
        ) : (
          <>
            <div className="content-body">
              <div className="case-detail-container">
                <div className="case-detail">
                  {this.state.add_mode ? (
                    <CardContainer type={this.state.add_mode ? 'top-border' : 'regular'} className="case-view pad-lg">
                      <h2 className="case-header">Prospect Details</h2>
                      <div className="case-detail__row case-detail__row--inner">
                        <div className="">{this.displayQuestion(this.state.questions, this.state.prospect_data)}</div>
                      </div>
                    </CardContainer>
                  ) : (
                    <div className="case-view pad-lg">
                      <h2 className="case-header">Prospect Details</h2>
                      <div className="case-detail__row case-detail__row--inner">
                        <div className="">{this.displayQuestion(this.state.questions, this.state.prospect_data)}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.is_editable() ? (
              <ActionBar
                prospect_data={this.state.prospect_data}
                questions={this.state.questions}
                is_editing={this.state.edit_mode}
                is_held={this.is_held()}
                is_converted={this.is_converted()}
                is_a_case={this.is_a_case()}
                onCancelClick={this.onCancelClick}
                onSaveClick={this.onSaveClick}
                hasActionInProgress={this.state.action}
                displayModal={this.displayModal}
                onDraftRedirect={this.onDraftRedirect}
                onCaseRedirect={this.onCaseRedirect}
                displayEditBtn={this.displayEditBtn()}
              />
            ) : null}
            {this.state.add_mode ? (
              <>
                <AddBar
                  questions={this.state.questions}
                  onCancelClick={this.onListRedirect}
                  onCreateClick={this.onCreateClick}
                  hasActionInProgress={this.state.action}
                />
                <ErrorMessage
                  className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
                  title={this.state.warning_title}
                  onClose={this.removeWizardErrorMsg}
                >
                  <div id="warning-submit" />
                </ErrorMessage>
              </>
            ) : null}
          </>
        )}
        {this.onModalShow()}
        {this.state.refresh ? (
          <Modal
            preset="action"
            x_btn={false}
            header_text="Session Expired"
            message_text="Sorry, your session has expired. Please refresh."
            confirm_btn_text="Refresh"
            onConfirmButtonClick={onReloadPage}
            theme="ipp"
          />
        ) : null}
        {this.state.failed_to_create ? (
          <Modal
            preset="action"
            x_btn={false}
            header_text="Unable to Create Prospect"
            message_text={this.state.failure_reason}
            confirm_btn_text="Ok"
            onConfirmButtonClick={this.onProspectFailedOkClicked}
            theme="ipp"
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(CreateEditProspect);
