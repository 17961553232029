import React, { Component } from 'react';
import { convertDate, convertSex } from '../../common/functions';
import html2pdf from 'html2pdf.js';
import printJS from 'print-js';
import _ from 'lodash';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

class TreamentPlanContent extends Component {
  downloadpdf = (e) => {
    const el = document.getElementById('plandownload');
    let filename = 'TX-Case-' + this.props.caseId + '.pdf';

    if (this.props.caseId.indexOf('-') >= 0) {
      filename = filename.replace('TX-Case-', 'DE-Case-');
    }

    var opt = {
      filename: filename,
      margin: [3, 3, 3, 3],
      pagebreak: { mode: ['css', 'legacy'] },
    };
    html2pdf().set(opt).from(el).toPdf().save();
  };

  printpdf = (e) => {
    const element = document.getElementById('plandownload');
    let opt = {
      image: { type: 'jpeg', quality: 1 },
    };
    var img = html2pdf().set(opt).from(element).toImg();
    img.then(
      (accept) => {
        var isFirefox = typeof InstallTrigger !== 'undefined';

        if (isFirefox === true) {
          var canvas = img.prop.canvas;
          const imgData = canvas.toDataURL('image/png');
          var win1 = window.open();

          if (win1 !== null) {
            win1.document.write("<img style='display:block;' src = '" + imgData + "'/>");
            win1.print();
          }
        } else {
          var c = img.prop.img.src;

          printJS(c, 'image');
        }
      },
      (reject) => {}
    );
  };

  displaymmValue(mm_value) {
    let display_value = '';

    if (mm_value && mm_value !== 'NA' && mm_value !== 'N/A' && mm_value !== 'undefined') {
      display_value = mm_value + ' mm';
    }

    return display_value;
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  displayUpperMidlineToFacial() {
    let results = '';
    switch (this.props.caseData.dxtxSection1_upperMidline2Facial) {
      case 'correct':
        results = 'correct';
        break;
      case 'midline-right':
        results = `Deviated to the right ${this.displaymmValue(this.props.caseData.dxtxSection1_upperMidline2FacialRight)}`;
        break;
      case 'midline-left':
        results = `Deviated to the right ${this.displaymmValue(this.props.caseData.dxtxSection1_upperMidline2FacialLeft)}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  overbiteMethod() {
    let result = 'NA';

    if (this.props.caseData && this.props.caseData.dxtxSection2_overbite && this.props.caseData.dxtxSection2_overbite !== 'undefined') {
      result = this.props.caseData.dxtxSection2_overbite;
    }

    return result;
  }

  overBiteMethodStatus(upper, lower) {
    let result = '';

    if (upper === 'true' && lower === 'true') {
      result = 'upper and lower';
    } else if (upper === 'true') {
      result = 'upper';
    } else if (lower === 'true') {
      result = 'lower';
    }

    return result;
  }

  overBiteMethodCorrection(method) {
    let result = '';
    let upper = '';
    let lower = '';

    if (this.props.caseData && this.props.caseData.dxtxSection2_overbite) {
      switch (this.props.caseData.dxtxSection2_overbite) {
        case 'Correct open bite':
          if (method === 'extrude') {
            upper = this.props.caseData.dxtxSection2_overbiteOpenExtrudeAnteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteOpenExtrudeAnteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Extrude anterior teeth to ' + this.overBiteMethodStatus(upper, lower);
            }
          } else {
            upper = this.props.caseData.dxtxSection2_overbiteOpenIntrudePosteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteOpenIntrudePosteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Intrude posterior teeth to ' + this.overBiteMethodStatus(upper, lower);
            }
          }
          break;

        case 'Correct deep bite':
          if (method === 'extrude') {
            upper = this.props.caseData.dxtxSection2_overbiteDeepExtrudePosteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteDeepExtrudePosteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Extrude posterior teeth to ' + this.overBiteMethodStatus(upper, lower);
            }
          } else {
            upper = this.props.caseData.dxtxSection2_overbiteDeepIntrudeAnteriorUpper;
            lower = this.props.caseData.dxtxSection2_overbiteDeepIntrudeAnteriorLower;
            if (this.overBiteMethodStatus(upper, lower) !== '') {
              result = 'Intrude anterior teeth to ' + this.overBiteMethodStatus(upper, lower);
            }
          }

          break;

        default:
      }
    }

    return result;
  }

  teethToTreat() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_teeth) {
      case '5x5':
        results = '5x5 Arch';
        break;
      case '6x6':
        results = '6x6 Arch';
        break;
      case '7x7':
        results = '7x7 Arch';
        break;
      case 'customxcustom':
        results = `Specify Location ${this.props.caseData.dxtxSection2_teethCustom}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  overjetDisplay() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_overjet) {
      case 'Maintain initial overjet':
        results = 'Maintain initial overjet';
        break;
      case 'Improve resulting overjet':
        results = 'Improve resulting overjet';
        break;
      case 'Achieve normal overjet':
        results = 'Achieve normal overjet';
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  posteriorCrossBite() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_posterior) {
      case 'Do not correct':
        results = 'Do not correct';
        break;
      case 'Correct (additional auxilary device may be needed)':
        results = 'Correct (additional auxilary device may be needed)';
        break;
      default:
        results = 'NA';
    }

    return results;
  }

  antPostRelationship = function (left = '', right = '') {
    if (left === '' && right === '') {
      return 'Neither';
    } else if (left === 'true' && right === 'true') {
      return 'Both';
    } else if (left === 'true') {
      return 'Left';
    } else if (right === 'true') {
      return 'Right';
    } else {
      return 'NA';
    }
  };

  spacing() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_spacing) {
      case 'Close all the spaces':
        results = 'Close all the spaces';
        break;
      case 'custom':
        results = `Leave some specific spaces : ${this.props.caseData.dxtxSection2_spacingValue}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  midlineUpper() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_midlineUpper) {
      case 'Maintain':
        results = 'Maintain';
        break;
      case 'Move right':
        results = `Move to patient's right ${this.displaymmValue(this.props.caseData.dxtxSection2_midlineUpperRight)}`;
        break;
      case 'Move left':
        results = `Move to patient's left ${this.displaymmValue(this.props.caseData.dxtxSection2_midlineUpperLeft)}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  midlineLower() {
    let results = '';
    switch (this.props.caseData.dxtxSection2_midlineLower) {
      case 'Maintain':
        results = 'Maintain';
        break;
      case 'Move right':
        results = `Move to patient's right ${this.displaymmValue(this.props.caseData.dxtxSection2_midlineLowerRight)}`;
        break;
      case 'Move left':
        results = `Move to patient's left ${this.displaymmValue(this.props.caseData.dxtxSection2_midlineLowerLeft)}`;
        break;
      default:
        results = 'NA';
    }
    return results;
  }

  toothSelection(selection, movement) {
    let ur = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'];
    let lr = ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'];
    let ul = ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'];
    let ll = ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'];
    let allTooth = [ur, lr, ul, ll];
    let result = [];
    let temp = '';
    let i = 0;

    //Generate class for displaying
    if (selection === 'UR') {
      i = 0;
    } else if (selection === 'LR') {
      i = 1;
    } else if (selection === 'UL') {
      i = 2;
    } else if (selection === 'LL') {
      i = 3;
    }

    if (movement) {
      movement = movement.split(',').sort();
    }

    for (let j = 0; j < allTooth[i].length; j++) {
      temp = _.intersection([allTooth[i][j]], movement);
      if (temp.length > 0) {
        result.push({
          tooth: allTooth[i][j],
          class: 'selected',
        });
      } else {
        result.push({
          tooth: allTooth[i][j],
          class: '',
        });
      }
    }

    if (movement) {
      //Finish off format
      let selected = false;
      let selectedCnt = 0;

      for (let k = 0; k < result.length; k++) {
        if (k === result.length - 1 && result[k].class) {
          if (result[k].class && selectedCnt === 0) {
            result[k].class = 'full-circle';
          } else if (result[k].class) {
            result[k].class = 'right-circle';
          }
        } else if (selected === true && result[k].class) {
          result[k].class = 'middle-circle';
          selectedCnt++;
        } else if (selected === true && result[k].class === '') {
          if (selectedCnt === 1) {
            result[k - 1].class = 'full-circle';
          } else {
            result[k - 1].class = 'right-circle';
          }
          selected = false;
          selectedCnt = 0;
        } else if (selected === false && result[k].class) {
          result[k].class = 'left-circle';
          selected = true;
          selectedCnt++;
        }
      }
    }

    return (
      <div>
        {result.map((tooth, index) => {
          return (
            <span key={index} className={'txplan-tooth ' + tooth.class}>
              {tooth.tooth}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div>
              {this.props.bpp && userHasPermission('CASE_DETAILS_VIEW', user_roles_and_permissions.permissions) ? (
                <div>
                  <span onClick={this.printpdf} name="print" type="button" className="mount-corner" data-toggle="tooltip" data-placement="top" title="Print">
                    <i className="fa fa-print" aria-hidden="true" />
                  </span>
                  <span
                    onClick={this.downloadpdf}
                    name="download"
                    type="button"
                    className="mount-corner-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Download"
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </span>
                </div>
              ) : null}
              <div id="plandownload" className="plan-height">
                <div className={this.props.bpp ? 'txplan-heading-dark' : 'txplan-heading'}>InBrace Diagnosis & Treatment Plan</div>
                <table className="txplan-table">
                  <tbody>
                    <tr>
                      <td className="txplan-border-bottom" colSpan="4">
                        <span className="bold-text">Doctor:</span> {this.props.doctorfirstName} {this.props.doctorlastName}
                      </td>
                    </tr>
                    <tr>
                      <td className="txplan-border-bottom-right txplan-width-case">
                        <span className="bold-text">Case ID:</span> {this.props.caseId}
                      </td>
                      <td className="txplan-border-bottom-right">
                        <span className="bold-text">Patient:</span> {this.props.selectedPatient.first_name + ' ' + this.props.selectedPatient.last_name}
                      </td>
                      <td className="txplan-border-bottom-right">
                        <span className="bold-text">Sex:</span> {convertSex(this.props.selectedPatient.sex)}
                      </td>
                      <td className="txplan-border-bottom">
                        <span className="bold-text">DOB:</span> {convertDate(this.props.selectedPatient.dob)}
                      </td>
                    </tr>
                    <tr>
                      <td className="txplan-height-5 txplan-top" colSpan="4">
                        <span className="bold-text">Patient's Chief Complaint:</span>{' '}
                        {this.props.caseData.dxtxSection0_chiefComplaint ? this.props.caseData.dxtxSection0_chiefComplaint : 'NA'}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {this.props.is_historical && this.props.treatment_plan_revision === 'TX0.0' ? null : (
                  <div>
                    <div className={this.props.bpp ? 'txplan-heading-dark' : 'txplan-heading'}>I. Diagnosis</div>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom-right txplan-half">
                            <span className="bold-text">Mx1 Exposure at Rest:</span>{' '}
                            {this.props.caseData.dxtxSection1_mx ? this.displaymmValue(this.props.caseData.dxtxSection1_mx) : 'NA'}
                          </td>
                          <td className="txplan-border-bottom">
                            <span className="bold-text">Gingival Exposure Smiling:</span>{' '}
                            {this.props.caseData.dxtxSection1_gingival ? this.displaymmValue(this.props.caseData.dxtxSection1_gingival) : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-ignore-bottom-padding">
                            <div>
                              <span className="bold-text">Upper Midline to Facial Midline:</span> {this.displayUpperMidlineToFacial()}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom txplan-lesshalf txplan-top txplan-ignore-top-padding">
                            <div>
                              <span className="bold-text">Right Molar:</span>{' '}
                              {this.props.caseData.dxtxSection1_rightMolars ? this.props.caseData.dxtxSection1_rightMolars : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">Left Molar:</span>{' '}
                              {this.props.caseData.dxtxSection1_leftMolars ? this.props.caseData.dxtxSection1_leftMolars : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">Right Canine:</span>{' '}
                              {this.props.caseData.dxtxSection1_rightCanine ? this.props.caseData.dxtxSection1_rightCanine : 'NA'}
                            </div>
                            <div>
                              <span className="bold-text">Left Canine:</span>{' '}
                              {this.props.caseData.dxtxSection1_leftCanine ? this.props.caseData.dxtxSection1_leftCanine : 'NA'}
                            </div>
                          </td>
                          <td className="txplan-border-bottom txplan-sixth-1 txplan-ignore-top-padding">
                            <div>
                              {this.props.caseData.dxtxSection1_overjet && this.props.caseData.dxtxSection1_overjet === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Overjet</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Overjet</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_narrow && this.props.caseData.dxtxSection1_narrow === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Narrow arch</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Narrow arch</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_crowding && this.props.caseData.dxtxSection1_crowding === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Crowding</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Crowding</span>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="txplan-border-bottom txplan-sixth-2 txplan-ignore-top-padding">
                            <div>
                              {this.props.caseData.dxtxSection1_anterior && this.props.caseData.dxtxSection1_anterior === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Anterior cross bite</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Anterior cross bite</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_posterior && this.props.caseData.dxtxSection1_posterior === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Posterior cross bite</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Posterior cross bite</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_spaces && this.props.caseData.dxtxSection1_spaces === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Spaces</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Spaces</span>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="txplan-border-bottom txplan-sixth-3 txplan-ignore-top-padding">
                            <div>
                              {this.props.caseData.dxtxSection1_deep && this.props.caseData.dxtxSection1_deep === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Deep bite</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Deep bite</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_class && this.props.caseData.dxtxSection1_class === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Open bite</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Open bite</span>
                                </div>
                              )}
                            </div>
                            <div>
                              {this.props.caseData.dxtxSection1_flared && this.props.caseData.dxtxSection1_flared === 'true' ? (
                                <div>
                                  <span className="box text-center">
                                    <i className="fa fa-times" aria-hidden="true" />
                                  </span>
                                  <span className="box-text">Flared teeth</span>
                                </div>
                              ) : (
                                <div>
                                  <span className="box" />
                                  <span className="box-text">Flared teeth</span>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-height-3 txplan-top">
                            <span className="bold-text">General Notes:</span>{' '}
                            {this.props.caseData.dxtxSection1_notes ? this.props.caseData.dxtxSection1_notes : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className={this.props.bpp ? 'txplan-heading-dark' : 'txplan-heading'}>II. Treatment Planning</div>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom-right">
                            <span className="bold-text">Arch to Treat:</span> {this.props.arch ? this.props.arch : 'NA'}
                          </td>
                          <td className="txplan-border-bottom">
                            <span className="bold-text">Teeth to Treat:</span> {this.teethToTreat()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom-right txplan-half">
                            <div className="txplan-left txplan-force-left">
                              <span className="bold-text">Overjet:</span> {this.overjetDisplay()}
                            </div>
                            <div className="txplan-left txplan-force-left">
                              <span className="bold-text">Overbite:</span> {this.overbiteMethod()}
                              {this.overbiteMethod() !== 'NA' ? '.' : null}
                              {this.overBiteMethodCorrection('extrude') ? <span className=""> {this.overBiteMethodCorrection('extrude')}. </span> : null}
                              {this.overBiteMethodCorrection('intrude') ? <span className=""> {this.overBiteMethodCorrection('intrude')}. </span> : null}
                            </div>
                            <div className="txplan-left txplan-force-left">
                              <span className="bold-text">Midline Upper:</span> {this.midlineUpper()}
                            </div>
                            <div className="txplan-left txplan-force-left">
                              <span className="bold-text">Midline Lower:</span> {this.midlineLower()}
                            </div>
                            <div className="txplan-left txplan-force-left">
                              <span className="bold-text">Posterior Cross Bite:</span> {this.posteriorCrossBite()}
                            </div>
                          </td>
                          <td className="txplan-border-bottom txplan-top">
                            <div className="">
                              <div className="bold-text">Anterior-Posterior (A-P) Relationship:</div>
                              <ul className="no-bullet">
                                <li>
                                  <span className="txplan-max-1">Maintain</span>
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorMaintainLeft,
                                    this.props.caseData.dxtxSection2_anteriorMaintainRight
                                  ) === 'Left' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorMaintainLeft,
                                    this.props.caseData.dxtxSection2_anteriorMaintainRight
                                  ) === 'Both' ? (
                                    <span className="txplan-left-space">
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Left</span>
                                    </span>
                                  ) : (
                                    <span className="txplan-left-space">
                                      <span className="box" />
                                      <span className="box-text">Left</span>
                                    </span>
                                  )}
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorMaintainLeft,
                                    this.props.caseData.dxtxSection2_anteriorMaintainRight
                                  ) === 'Right' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorMaintainLeft,
                                    this.props.caseData.dxtxSection2_anteriorMaintainRight
                                  ) === 'Both' ? (
                                    <span>
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Right</span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span className="box" />
                                      <span className="box-text">Right</span>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  <span className="txplan-max-1">Improve Canine Relationship</span>
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorImproveLeft,
                                    this.props.caseData.dxtxSection2_anteriorImproveRight
                                  ) === 'Left' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorImproveLeft,
                                    this.props.caseData.dxtxSection2_anteriorImproveRight
                                  ) === 'Both' ? (
                                    <span className="txplan-left-space">
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Left</span>
                                    </span>
                                  ) : (
                                    <span className="txplan-left-space">
                                      <span className="box" />
                                      <span className="box-text">Left</span>
                                    </span>
                                  )}
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorImproveLeft,
                                    this.props.caseData.dxtxSection2_anteriorImproveRight
                                  ) === 'Right' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorImproveLeft,
                                    this.props.caseData.dxtxSection2_anteriorImproveRight
                                  ) === 'Both' ? (
                                    <span>
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Right</span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span className="box" />
                                      <span className="box-text">Right</span>
                                    </span>
                                  )}
                                </li>
                                <li>
                                  <span className="txplan-max-1">Correction to Canine & Molar Class I</span>
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorCorrectLeft,
                                    this.props.caseData.dxtxSection2_anteriorCorrectRight
                                  ) === 'Left' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorCorrectLeft,
                                    this.props.caseData.dxtxSection2_anteriorCorrectRight
                                  ) === 'Both' ? (
                                    <span className="txplan-left-space">
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Left</span>
                                    </span>
                                  ) : (
                                    <span className="txplan-left-space">
                                      <span className="box" />
                                      <span className="box-text">Left</span>
                                    </span>
                                  )}
                                  {this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorCorrectLeft,
                                    this.props.caseData.dxtxSection2_anteriorCorrectRight
                                  ) === 'Right' ||
                                  this.antPostRelationship(
                                    this.props.caseData.dxtxSection2_anteriorCorrectLeft,
                                    this.props.caseData.dxtxSection2_anteriorCorrectRight
                                  ) === 'Both' ? (
                                    <span>
                                      <span className="box text-center">
                                        <i className="fa fa-times" aria-hidden="true" />
                                      </span>
                                      <span className="box-text">Right</span>
                                    </span>
                                  ) : (
                                    <span>
                                      <span className="box" />
                                      <span className="box-text">Right</span>
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="">
                            <div>
                              <span className="bold-text">Tooth Movement Restrictions (circled):</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom txplan-half">
                            <div className="txplan-padding-b1">{this.toothSelection('UR', this.props.caseData.dxtxSection2_toothMovementSelection)}</div>
                            <div>{this.toothSelection('LR', this.props.caseData.dxtxSection2_toothMovementSelection)}</div>
                          </td>
                          <td className="txplan-border-bottom">
                            <div className="txplan-padding-b1">{this.toothSelection('UL', this.props.caseData.dxtxSection2_toothMovementSelection)}</div>
                            <div>{this.toothSelection('LL', this.props.caseData.dxtxSection2_toothMovementSelection)}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="">
                            <div>
                              <span className="bold-text">Extractions (circled):</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom txplan-half">
                            <div className="txplan-padding-b1">{this.toothSelection('UR', this.props.caseData.dxtxSection2_toothExtractionSelection)}</div>
                            <div>{this.toothSelection('LR', this.props.caseData.dxtxSection2_toothExtractionSelection)}</div>
                          </td>
                          <td className="txplan-border-bottom">
                            <div className="txplan-padding-b1">{this.toothSelection('UL', this.props.caseData.dxtxSection2_toothExtractionSelection)}</div>
                            <div>{this.toothSelection('LL', this.props.caseData.dxtxSection2_toothExtractionSelection)}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom">
                            <span className="bold-text">Spacing:</span> {this.spacing()}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-border-bottom-right txplan-half">
                            <div>
                              <span className="bold-text">Resolve Upper Crowding:</span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperExpand && this.props.caseData.dxtxSection2_resolveUpperExpand === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Expand</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Expand</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperIPRPosteriorRight &&
                                this.props.caseData.dxtxSection2_resolveUpperIPRPosteriorRight === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Posterior Right</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Posterior Right</span>
                                  </span>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperExtraction &&
                                this.props.caseData.dxtxSection2_resolveUpperExtraction === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Extraction</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Extraction</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperIPRPosteriorLeft &&
                                this.props.caseData.dxtxSection2_resolveUpperIPRPosteriorLeft === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Posterior Left</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Posterior Left</span>
                                  </span>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperProcline && this.props.caseData.dxtxSection2_resolveUpperProcline === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Procline</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Procline</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveUpperIPRAnterior &&
                                this.props.caseData.dxtxSection2_resolveUpperIPRAnterior === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Anterior</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Anterior</span>
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                          <td className="txplan-border-bottom txplan-half">
                            <div>
                              <span className="bold-text">Resolve Lower Crowding:</span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerExpand && this.props.caseData.dxtxSection2_resolveLowerExpand === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Expand</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Expand</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerIPRPosteriorRight &&
                                this.props.caseData.dxtxSection2_resolveLowerIPRPosteriorRight === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Posterior Right</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Posterior Right</span>
                                  </span>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerExtraction &&
                                this.props.caseData.dxtxSection2_resolveLowerExtraction === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Extraction</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Extraction</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerIPRPosteriorLeft &&
                                this.props.caseData.dxtxSection2_resolveLowerIPRPosteriorLeft === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Posterior Left</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Posterior Left</span>
                                  </span>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerProcline && this.props.caseData.dxtxSection2_resolveLowerProcline === 'true' ? (
                                  <span className="txplan-left-space">
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">Procline</span>
                                  </span>
                                ) : (
                                  <span className="txplan-left-space">
                                    <span className="box" />
                                    <span className="box-text">Procline</span>
                                  </span>
                                )}
                              </span>
                              <span className="txplan-halfspan">
                                {this.props.caseData.dxtxSection2_resolveLowerIPRAnterior &&
                                this.props.caseData.dxtxSection2_resolveLowerIPRAnterior === 'true' ? (
                                  <span>
                                    <span className="box text-center">
                                      <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                    <span className="box-text">IPR Anterior</span>
                                  </span>
                                ) : (
                                  <span>
                                    <span className="box" />
                                    <span className="box-text">IPR Anterior</span>
                                  </span>
                                )}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="txplan-table">
                      <tbody>
                        <tr>
                          <td className="txplan-height-5 txplan-top">
                            <span className="bold-text">Special Instructions:</span>{' '}
                            {this.props.caseData.dxtxSection2_special ? this.props.caseData.dxtxSection2_special : 'NA'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

export default TreamentPlanContent;
