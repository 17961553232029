// External Libs
import React, { Component } from 'react';
// Internal Libs
import Modal from '../../../components/modal/modal';
import { modalClear, removeIreqIdInitialNumber } from '../../../common/functions';

// Internal Libs
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { getPDFNameTemplate } from '../../../common/helpers';

const LABELS = {
  smartwire: { header: 'Smartwire', folder: 'ir_smartwire_label', file_name: 'Label_Smartwire_Manual.pdf' },
  idb: { header: 'IDB Tray', folder: 'ir_idb_label', file_name: 'Label_IDB_Manual.pdf' },
  singlesheet: { header: 'Single Sheet', folder: 'ir_singlesheet_label', file_name: 'Label_Single_Sheet_Manual.pdf' },
  inner_packaging: { header: 'Inner Packaging Label', folder: 'ireq_labels', file_name: 'Label_Inner_Packaging_Manual.pdf' },
};
class CadLabelSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf_modal: false,
      delete_pdf_modal: false,
      delete_smartwire_modal: false,
      delete_idb_modal: false,
      delete_singlesheet_modal: false,
      upload_label: false,
      remove_in_progress: false,
      content_file: [],
      return_clicked: false,
      inner_packaging_file: [],
      smartwire_file: [],
      idb_file: [],
      singlesheet_file: [],
      smartwire_modal: false,
      idb_modal: false,
      singlesheet_modal: false,
      selected_upload_label: '',
    };
  }

  componentDidUpdate() {
    if (this.state.delete_pdf_modal && !this.state.upload_label && this.props.files.length === 0) {
      this.setState({ delete_pdf_modal: !this.state.delete_pdf_modal });
    }
    if (
      this.state.delete_smartwire_modal &&
      !this.state.upload_label &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('smartwire');
      }).length === 0
    ) {
      this.setState({ delete_smartwire_modal: !this.state.delete_smartwire_modal });
    }
    if (
      this.state.delete_idb_modal &&
      !this.state.upload_label &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('idb');
      }).length === 0
    ) {
      this.setState({ delete_idb_modal: !this.state.delete_idb_modal });
    }
  }
  onGen2labelModalClick = (event) => {
    switch (event.target.dataset.value) {
      case 'smartwire':
        this.setState({ smartwire_modal: !this.state.smartwire_modal });
        break;
      case 'idb':
        this.setState({ idb_modal: !this.state.idb_modal });
        break;
      case 'singlesheet':
        this.setState({ singlesheet_modal: !this.state.singlesheet_modal });
        break;
      default:
    }
  };

  onSmartWireModalDismiss = (event) => {
    this.setState({ smartwire_modal: !this.state.smartwire_modal });
    modalClear();
  };
  onIdbTrayModalDismiss = (event) => {
    this.setState({ idb_modal: !this.state.idb_modal });
    modalClear();
  };
  /**
   * Closes modal for single sheet label
   * @function
   */
  onSingleSheetModalDismiss = () => {
    this.setState({ singlesheet_modal: !this.state.singlesheet_modal });
    modalClear();
  };
  onInnerPackagingModalClick = () => {
    this.setState({ pdf_modal: !this.state.pdf_modal });
  };
  onInnerPackagingModalDismiss = () => {
    this.setState({ pdf_modal: !this.state.pdf_modal });
  };

  onDeleteInnerPackagingModalClick = () => {
    this.setState({ delete_pdf_modal: !this.state.delete_pdf_modal, remove_in_progress: false });
  };
  onDeleteInnerPackagingModalDismiss = () => {
    this.setState({ delete_pdf_modal: !this.state.delete_pdf_modal });
  };

  onDeleteSmartwireModalClick = () => {
    this.setState({ delete_smartwire_modal: !this.state.delete_smartwire_modal, remove_in_progress: false });
  };
  onDeleteSmartwireModalDismiss = () => {
    this.setState({ delete_smartwire_modal: !this.state.delete_smartwire_modal });
  };
  onDeleteIdbtrayModalClick = () => {
    this.setState({ delete_idb_modal: !this.state.delete_idb_modal, remove_in_progress: false });
  };
  onDeleteIdbtrayModalDismiss = () => {
    this.setState({ delete_idb_modal: !this.state.delete_idb_modal });
  };
  /**
   * Opens modal for deleting single sheet label
   * @function
   */
  onDeleteSingleSheetModalClick = () => {
    this.setState({ delete_singlesheet_modal: !this.state.delete_singlesheet_modal, remove_in_progress: false });
  };
  /**
   * Closes modal for deleting single sheet label
   * @function
   */
  onDeleteSingleSheetModalDismiss = () => {
    this.setState({ delete_singlesheet_modal: !this.state.delete_singlesheet_modal });
  };
  onUploadInnerPackagingModalDismiss = () => {
    switch (this.state.selected_upload_label) {
      case 'smartwire':
        this.setState({
          smartwire_file: [],
        });
        break;
      case 'idb':
        this.setState({
          idb_file: [],
        });
        break;
      case 'singlesheet':
        this.setState({
          singlesheet_file: [],
        });
        break;
      default:
        this.setState({
          content_file: [],
        });
    }
    this.setState({ upload_label: !this.state.upload_label, selected_upload_label: '' });
    this.props.reloadInformation();
  };

  onSmartwireRemoveButtonClick = () => {
    this.setState({ remove_in_progress: !this.state.remove_in_progress, smartwire_file: [] });
    this.props.removeSmartwireFile();
    this.props.reloadInformation();
  };

  onIdbtrayRemoveButtonClick = () => {
    this.setState({ remove_in_progress: !this.state.remove_in_progress, idb_file: [] });
    this.props.removeIdbtrayFile();
    this.props.reloadInformation();
  };

  /**
   * Removes single sheet label
   * @function
   */
  onSingleSheetRemoveButtonClick = () => {
    this.setState({ remove_in_progress: !this.state.remove_in_progress, singlesheet_file: [] });
    this.props.removeSingleSheetFile();
    this.props.reloadInformation();
    this.onDeleteSingleSheetModalDismiss();
  };

  onRemoveButtonClick = () => {
    this.setState({ remove_in_progress: !this.state.remove_in_progress, content_file: [] });
    this.props.removePackageFile();
    this.props.reloadInformation();
  };

  onUploadLabelModalClick = (label_type) => {
    this.setState({ upload_label: !this.state.upload_label, selected_upload_label: label_type });
  };

  onUpload = (data) => {
    switch (this.state.selected_upload_label) {
      case 'smartwire':
        this.setState({
          smartwire_file: data,
        });
        break;
      case 'idb':
        this.setState({
          idb_file: data,
        });
        break;
      case 'singlesheet':
        this.setState({
          singlesheet_file: data,
        });
        break;
      default:
        this.setState({
          content_file: data,
        });
    }
    this.props.onUpload(data);
  };

  restartLabelGeneration = (event) => {
    this.setState({
      return_clicked: true,
    });
    this.props.reloadInformation();
    this.props.restartLabelGeneration();
  };

  getLabelInfo(label_type) {
    const label_files = { smartwire: this.state.smartwire_file, idb: this.state.idb_file, singlesheet: this.state.singlesheet_file };

    let label_selected =
      label_type === 'single_sheet'
        ? true
        : this.props.selections.filter(function (a) {
            return a.state ? a.state.includes(label_type) : null;
          }).length > 0;
    let file_exists = false;
    let file = label_files[label_type];
    if (
      this.props.files.filter(function (a) {
        return a.original_filename.includes(label_type);
      })[0]
    ) {
      file_exists = true;
      file =
        file.length > 0
          ? file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes(label_type);
            })[0];
    }
    return [label_selected, file_exists, file];
  }

  getFileUploadTime() {
    return this.props.files?.at(-1)?.created_date;
  }

  render() {
    const [smartwire_label_selected, smartwire_file_exists, smartwire_file] = this.getLabelInfo('smartwire');
    const [idb_label_selected, idb_file_exists, idb_file] = this.getLabelInfo('idb');
    // eslint-disable-next-line
    const [singlesheet_label_selected, singlesheet_file_exists, singlesheet_file] = this.getLabelInfo('singlesheet');
    const is_gen_2_wip = smartwire_file_exists || idb_file_exists;

    return (
      <React.Fragment>
        {this.props.item_request.ireq_status !== 'Item Request Shipped' && this.props.item_parts_quantity.length > 0 ? (
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('IREQ_LABEL_GENERATION', user_roles_and_permissions.permissions) && !this.props.item_request.shipping_approved_ind ? (
                <div>
                  {/* eslint-disable-next-line */}
                  <a className={'process-return-ir' + (this.state.return_clicked ? ' return-disabled' : '')} onClick={this.restartLabelGeneration}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to label selection
                  </a>
                </div>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        ) : null}
        <div>
          {this.props.gen_2 ? (
            <>
              {!is_gen_2_wip ? (
                singlesheet_file_exists || this.props.mark_as_success ? (
                  <div>
                    <div className="underline-text viewable-text" data-value="singlesheet" onClick={this.onGen2labelModalClick}>
                      Label, Single Sheet <i className="fa fa-file-pdf-o" aria-hidden="true" />{' '}
                    </div>
                    {singlesheet_file.file_type === 'labels_manual' ? <span> (Manual Upload) </span> : null}
                    {this.props.item_request.ireq_status !== 'Item Request Shipped' ? (
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) &&
                            !this.props.item_request.shipping_approved_ind ? (
                            <div className="delete-package" onClick={this.onDeleteSingleSheetModalClick}>
                              <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                            </div>
                          ) : null;
                        }}
                      </UserPermissionsContext.Consumer>
                    ) : null}
                  </div>
                ) : (
                  <UserPermissionsContext.Consumer>
                    {(user_roles_and_permissions) => {
                      return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                        <React.Fragment>
                          <div className="ir-file-upload">
                            <div className="text-center bold-text">Single Sheet Label</div>
                            <button
                              type="button"
                              className="btn btn-bpp-upload btn-shipped view-upload"
                              onClick={() => this.onUploadLabelModalClick('singlesheet')}
                            >
                              Upload Label
                            </button>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className="ir-file-upload">
                          <div className="text-center bold-text">Single Sheet Label</div>
                          <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" disabled={true}>
                            Upload Label
                          </button>
                        </div>
                      );
                    }}
                  </UserPermissionsContext.Consumer>
                )
              ) : (
                <>
                  {smartwire_label_selected ? (
                    smartwire_file_exists || this.props.mark_as_success ? (
                      <div>
                        <div className="underline-text viewable-text" data-value="smartwire" onClick={this.onGen2labelModalClick}>
                          Label, Smartwire <i className="fa fa-file-pdf-o" aria-hidden="true" />{' '}
                        </div>
                        {smartwire_file.file_type === 'labels_manual' ? <span> (Manual Upload) </span> : null}
                        {this.props.item_request.ireq_status !== 'Item Request Shipped' ? (
                          <UserPermissionsContext.Consumer>
                            {(user_roles_and_permissions) => {
                              return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) &&
                                !this.props.item_request.shipping_approved_ind ? (
                                <div className="delete-package" onClick={this.onDeleteSmartwireModalClick}>
                                  <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                                </div>
                              ) : null;
                            }}
                          </UserPermissionsContext.Consumer>
                        ) : null}
                      </div>
                    ) : (
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                            <React.Fragment>
                              <div className="ir-file-upload">
                                <div className="text-center bold-text">Smartwire Label</div>
                                <button
                                  type="button"
                                  className="btn btn-bpp-upload btn-shipped view-upload"
                                  onClick={() => this.onUploadLabelModalClick('smartwire')}
                                >
                                  Upload Label
                                </button>
                              </div>
                            </React.Fragment>
                          ) : (
                            <div className="ir-file-upload">
                              <div className="text-center bold-text">Smartwire Label</div>
                              <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" disabled={true}>
                                Upload Label
                              </button>
                            </div>
                          );
                        }}
                      </UserPermissionsContext.Consumer>
                    )
                  ) : null}
                  {idb_label_selected ? (
                    idb_file_exists || this.props.mark_as_success ? (
                      <div>
                        <div className="underline-text viewable-text" data-value="idb" onClick={this.onGen2labelModalClick}>
                          Label, IDB Tray <i className="fa fa-file-pdf-o" aria-hidden="true" />{' '}
                        </div>
                        {idb_file.file_type === 'labels_manual' ? <span> (Manual Upload) </span> : null}
                        {this.props.item_request.ireq_status !== 'Item Request Shipped' ? (
                          <UserPermissionsContext.Consumer>
                            {(user_roles_and_permissions) => {
                              return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) &&
                                !this.props.item_request.shipping_approved_ind ? (
                                <div className="delete-package" onClick={this.onDeleteIdbtrayModalClick}>
                                  <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                                </div>
                              ) : null;
                            }}
                          </UserPermissionsContext.Consumer>
                        ) : null}
                      </div>
                    ) : (
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                            <React.Fragment>
                              <div className="ir-file-upload">
                                <div className="text-center bold-text">IDB Tray Label</div>
                                <button
                                  type="button"
                                  className="btn btn-bpp-upload btn-shipped view-upload"
                                  onClick={() => this.onUploadLabelModalClick('idb')}
                                >
                                  Upload Label
                                </button>
                              </div>
                            </React.Fragment>
                          ) : (
                            <div className="ir-file-upload">
                              <div className="text-center bold-text">IDB Tray Label</div>
                              <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" disabled={true}>
                                Upload Label
                              </button>
                            </div>
                          );
                        }}
                      </UserPermissionsContext.Consumer>
                    )
                  ) : null}
                </>
              )}
            </>
          ) : this.props.files.length > 0 ? (
            <React.Fragment>
              <div className="underline-text viewable-text" onClick={this.onInnerPackagingModalClick}>
                Label, Inner Packaging <i className="fa fa-file-pdf-o" aria-hidden="true" />{' '}
              </div>
              {this.props.files[0].file_type === 'labels_manual' ? <span> (Manual Upload) </span> : null}
              {this.props.item_request.ireq_status !== 'Item Request Shipped' ? (
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) &&
                      !this.props.item_request.shipping_approved_ind ? (
                      <div className="delete-package" onClick={this.onDeleteInnerPackagingModalClick}>
                        <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                      </div>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
              ) : null}
            </React.Fragment>
          ) : (
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('IREQ_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                  <React.Fragment>
                    <button
                      type="button"
                      className="btn btn-bpp-upload btn-shipped view-upload"
                      onClick={() => this.onUploadLabelModalClick('inner_packaging')}
                    >
                      Upload Label
                    </button>
                  </React.Fragment>
                ) : (
                  <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" disabled={true}>
                    Upload Label
                  </button>
                );
              }}
            </UserPermissionsContext.Consumer>
          )}
        </div>

        {this.state.pdf_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={'Item Request Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_type="ir_label"
            ireq_id={this.props.item_request.ireq_id}
            pdf_date={this.getFileUploadTime()}
            onCloseButtonClick={this.onInnerPackagingModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.item_request.ireq_id,
              this.props.patient_info,
              this.props.files[0].file_type === 'labels_manual' ? 'Label_Inner_Packaging_Manual.pdf' : 'Label_Inner_Packaging.pdf'
            )}
          />
        ) : null}
        {this.state.smartwire_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={'Item Request Smartwire Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={smartwire_file.upload_data.substr(smartwire_file.upload_data.indexOf('ITEM_REQUEST_FILES'))}
            pdf_date={this.getFileUploadTime()}
            onCloseButtonClick={this.onSmartWireModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.item_request.ireq_id,
              this.props.patient_info,
              smartwire_file.file_type === 'labels_manual' ? 'Label_Smartwire_Manual.pdf' : 'Label_Smartwire.pdf'
            )}
          />
        ) : null}
        {this.state.idb_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={'Item Request IDB Tray Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            data-value="idb"
            pdf_url={idb_file.upload_data.substr(idb_file.upload_data.indexOf('ITEM_REQUEST_FILES'))}
            pdf_date={this.getFileUploadTime()}
            onCloseButtonClick={this.onIdbTrayModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.item_request.ireq_id,
              this.props.patient_info,
              idb_file.file_type === 'labels_manual' ? 'Label_IDB_Manual.pdf' : 'Label_IDB.pdf'
            )}
          />
        ) : null}
        {this.state.singlesheet_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={'Single Sheet Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            data-value="idb"
            pdf_url={singlesheet_file.upload_data.substr(singlesheet_file.upload_data.indexOf('ITEM_REQUEST_FILES'))}
            pdf_date={this.getFileUploadTime()}
            onCloseButtonClick={this.onSingleSheetModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.item_request.ireq_id,
              this.props.patient_info,
              idb_file.file_type === 'labels_manual' ? 'Label_Single_Sheet_Manual.pdf' : 'Label_Single_Sheet.pdf'
            )}
          />
        ) : null}
        {this.state.delete_pdf_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Inner Packaging Label -' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            message_text={'Are you sure you want to remove the inner Packaging Label?'}
            in_progress={this.state.remove_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveButtonClick}
            onCloseButtonClick={this.onDeleteInnerPackagingModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.delete_smartwire_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Smartwire Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            message_text={'Are you sure you want to remove the Smartwire Label?'}
            in_progress={this.state.remove_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onSmartwireRemoveButtonClick}
            onCloseButtonClick={this.onDeleteSmartwireModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.delete_idb_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove IDB Tray Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            message_text={'Are you sure you want to remove the IDB Tray Label?'}
            in_progress={this.state.remove_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onIdbtrayRemoveButtonClick}
            onCloseButtonClick={this.onDeleteIdbtrayModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.delete_singlesheet_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Single Sheet Label - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            message_text={'Are you sure you want to remove the Single Sheet Label?'}
            in_progress={this.state.remove_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onSingleSheetRemoveButtonClick}
            onCloseButtonClick={this.onDeleteSingleSheetModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.upload_label ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload ' + LABELS[this.state.selected_upload_label].header + ' - ' + removeIreqIdInitialNumber(this.props.item_request.ireq_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            ireq_id={this.props.item_request.ireq_id}
            folder={LABELS[this.state.selected_upload_label].folder}
            type="additional"
            onUpload={this.onUpload}
            upload_content={
              this.state.selected_upload_label === 'smartwire'
                ? smartwire_file
                : this.state.selected_upload_label === 'idb'
                ? idb_file
                : this.state.selected_upload_label === 'singlesheet'
                ? singlesheet_file
                : this.state.content_file
                ? this.state.content_file
                : this.props.files[0]
            }
            onCloseButtonClick={this.onUploadInnerPackagingModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.item_request.ireq_id, this.props.patient_info, LABELS[this.state.selected_upload_label].file_name)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default CadLabelSuccess;
