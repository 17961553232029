import Axios from 'axios';
import _ from 'lodash';
import { drawProfilePicture } from '../../../common/functions';

// Action Types
export const FETCH_DRAFT_PROFILE_PICTURE_SUCCESS = 'FETCH_DRAFT_PROFILE_PICTURE_SUCCESS';
export const FETCH_DRAFT_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS = 'FETCH_DRAFT_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS';
export const FETCH_DRAFT_PROFILE_PICTURE_PENDING = 'FETCH_DRAFT_PROFILE_PICTURE_PENDING';
export const FETCH_DRAT_PROFILE_PICTURE_ERROR = 'FETCH_DRAT_PROFILE_PICTURE_ERROR';
export const SET_DRAFT_PROFILE_METADATA_EDIT = 'SET_DRAFT_PROFILE_METADATA_EDIT';
export const SET_DRAFT_PROFILE_IMAGE = 'SET_DRAFT_PROFILE_IMAGE;';
export const DRAFT_OPEN_UPLOAD_PICTURE_MODAL = 'DRAFT_OPEN_UPLOAD_PICTURE_MODAL';
export const DRAFT_CLOSE_UPLOAD_PICTURE_MODAL = 'DRAFT_CLOSE_UPLOAD_PICTURE_MODAL';
export const DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_PENDING = 'DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_PENDING';
export const DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS = 'DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS';
export const SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS = 'SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS';
export const SAVE_PROFILE_PICTURE_REPOSITION_ERROR = 'SAVE_PROFILE_PICTURE_REPOSITION_ERROR';
export const DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_MODAL = 'DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_MODAL';
export const DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL = 'DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL';
export const DRAFT_CLOSE_REPOSITION_PROFILE_PICTURE_MODAL = 'DRAFT_CLOSE_REPOSITION_PROFILE_PICTURE_MODAL';
export const DRAFT_OPEN_PROFILE_PICTURE_MENU = 'DRAFT_OPEN_PROFILE_PICTURE_MENU';
export const DRAFT_CLOSE_PROFILE_PICTURE_MENU = 'DRAFT_CLOSE_PROFILE_PICTURE_MENU';
export const DRAFT_OPEN_DELETE_PICTURE_MODAL = 'DRAFT_OPEN_DELETE_PICTURE_MODAL';
export const DRAFT_CLOSE_DELETE_PICTURE_MODAL = 'DRAFT_CLOSE_DELETE_PICTURE_MODAL';
export const DRAFT_DELETE_PROFILE_PICTURE_PENDING = 'DRAFT_DELETE_PROFILE_PICTURE_PENDING';
export const DRAFT_DELETE_PROFILE_PICTURE_SUCCESS = 'DRAFT_DELETE_PROFILE_PICTURE_SUCCESS';
export const DRAFT_DELETE_PROFILE_PICTURE_ERROR = 'DRAFT_DELETE_PROFILE_PICTURE_ERROR';
export const DRAFT_UPLOAD_PROFILE_PICTURE_PENDING = 'DRAFT_UPLOAD_PROFILE_PICTURE_PENDING';
export const DRAFT_UPLOAD_PROFILE_PICTURE_SUCCESS = 'DRAFT_UPLOAD_PROFILE_PICTURE_SUCCESS';
export const DRAFT_UPLOAD_PROFILE_PICTURE_ERROR = 'DRAFT_UPLOAD_PROFILE_PICTURE_ERROR';
export const DRAFT_INVALID_PROFILE_PICTURE_SIZE = 'DRAFT_INVALID_PROFILE_PICTURE_SIZE';
export const DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR = 'DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Retrieves doctor's profile picture info
 *
 * @function
 * @param {number} doctor_id - Doctor id
 * @param {boolean} open_reposition_modal - Indicating if the reposition modal should be opened after fetching profile picture
 * @param {object} that - Instance of class
 */
export function fetchProfilePicture(doctor_id, is_edit_dso_doctor_account_page, open_reposition_modal, that) {
  return (dispatch) => {
    dispatch(fetchProfilePicturePending());

    Axios.get(`/apiv3/doctor/${doctor_id}/profilepicturedraft`)
      .then(function (result) {
        if (is_edit_dso_doctor_account_page) {
          dispatch(
            fetchProfilePictureDsoDoctorSuccess(result.data.presigned_url, result.data.profile_metadata, result.data.s3_url_raw, result.data.s3_url_thumbnail)
          );
        } else {
          dispatch(fetchProfilePictureSuccess(result.data.presigned_url, result.data.profile_metadata, result.data.s3_url_raw, result.data.s3_url_thumbnail));
        }
        if (open_reposition_modal) {
          dispatch(openRepositionProfilePictureModal());
        }
        drawProfilePicture(that, is_edit_dso_doctor_account_page);
      })
      .catch(function (err) {
        dispatch(fetchProfilePictureError());
      });
  };
}

export function fetchProfilePicturePending() {
  return {
    type: FETCH_DRAFT_PROFILE_PICTURE_PENDING,
  };
}

export function fetchProfilePictureDsoDoctorSuccess(draft_profile_image, draft_profile_metadata, draft_s3_url_raw, s3_url_thumbnail) {
  return {
    type: FETCH_DRAFT_PROFILE_PICTURE_DSO_DOCTOR_SUCCESS,
    draft_profile_image_dso_doctor: draft_profile_image,
    draft_profile_metadata_dso_doctor: draft_profile_metadata,
    draft_s3_url_raw: draft_s3_url_raw,
    s3_url_thumbnail: s3_url_thumbnail,
  };
}

export function fetchProfilePictureSuccess(draft_profile_image, draft_profile_metadata, draft_s3_url_raw, s3_url_thumbnail) {
  return {
    type: FETCH_DRAFT_PROFILE_PICTURE_SUCCESS,
    draft_profile_image: draft_profile_image,
    draft_profile_metadata: draft_profile_metadata,
    draft_s3_url_raw: draft_s3_url_raw,
    s3_url_thumbnail: s3_url_thumbnail,
  };
}

export function fetchProfilePictureError() {
  return {
    type: FETCH_DRAT_PROFILE_PICTURE_ERROR,
  };
}

/**
 * Saves the reposition info for the profile picture
 *
 * @function
 * @param {number} doctor_id - Doctor id
 * @param {object} that - Instance of class
 */
export function saveProfilePictureReposition(doctor_id, that) {
  return (dispatch, getState) => {
    dispatch(saveProfilePictureRepositionPending());
    const { draft_profile_metadata_edit, draft_s3_url_raw, draft_s3_url_thumbnail } = getState().profilePictureDraftReducer;
    const data = _.cloneDeep(draft_profile_metadata_edit);
    data.s3_url_raw = draft_s3_url_raw;
    data.s3_url_thumbnail = draft_s3_url_thumbnail;
    Axios.put(`/apiv3/doctor/${doctor_id}/profilepicturedraft`, data)
      .then(function (result) {
        if (that.props.is_edit_dso_doctor_account_page) {
          dispatch(saveProfilePictureDsoDoctorRepositionSuccess());
        } else {
          dispatch(saveProfilePictureRepositionSuccess());
        }
        drawProfilePicture(that, that.props.is_edit_dso_doctor_account_page);
      })
      .catch(function (err) {
        dispatch(saveProfilePictureRepositionError());
      });
  };
}

export function saveProfilePictureRepositionPending() {
  return {
    type: DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_PENDING,
  };
}

export function saveProfilePictureRepositionSuccess() {
  return {
    type: DRAFT_SAVE_PROFILE_PICTURE_REPOSITION_SUCCESS,
  };
}

export function saveProfilePictureDsoDoctorRepositionSuccess() {
  return {
    type: SAVE_PROFILE_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS,
  };
}

export function saveProfilePictureRepositionError() {
  return {
    type: SAVE_PROFILE_PICTURE_REPOSITION_ERROR,
  };
}

/**
 * Handles the event when the user drags the profile picture circle when repositioning
 *
 * @function
 * @param {number} x - X coordinate of the image
 * @param {number} y - Y coordinate of the image
 * @param {number} width - Width of the cropped image
 * @param {number} height - Height of the cropped image
 * @return {object} - Action type with profile metadata
 */
export function setProfileMetadataEdit(x, y, width, height) {
  return (dispatch, getState) => {
    const { draft_profile_metadata_edit } = getState().profilePictureDraftReducer;
    let draft_profile_metadata_edit_updates = _.cloneDeep(draft_profile_metadata_edit);

    if (draft_profile_metadata_edit_updates) {
      draft_profile_metadata_edit_updates.x = x;
      draft_profile_metadata_edit_updates.y = y;
      draft_profile_metadata_edit_updates.width = width;
      draft_profile_metadata_edit_updates.height = height;
    } else {
      draft_profile_metadata_edit_updates = {
        x: x,
        y: y,
        width: width,
        height: height,
      };
    }

    dispatch({
      type: SET_DRAFT_PROFILE_METADATA_EDIT,
      draft_profile_metadata_edit: draft_profile_metadata_edit_updates,
    });
  };
}

export function setProfileImage(draft_profile_image) {
  return {
    type: SET_DRAFT_PROFILE_IMAGE,
    draft_profile_image: draft_profile_image,
  };
}

/**
 * Handles when user deletes their profile picture
 *
 * @function
 * @param {number} doctor_id - Doctor id
 * @param {object} that - Instance of class
 */
export function onDeleteProfilePicture(doctor_id, that) {
  return (dispatch) => {
    dispatch(deleteProfilePicturePending());
    Axios.delete(`/apiv3/doctor/${doctor_id}/profilepicturedraft`)
      .then((res) => {
        dispatch(deleteProfilePictureSuccess());
      })
      .catch(function (err) {
        dispatch(deleteProfilePictureError());
      });
  };
}

export function deleteProfilePicturePending() {
  return {
    type: DRAFT_DELETE_PROFILE_PICTURE_PENDING,
  };
}

export function deleteProfilePictureSuccess() {
  return {
    type: DRAFT_DELETE_PROFILE_PICTURE_SUCCESS,
    draft_profile_image_dso_doctor: '',
    draft_profile_metadata_dso_doctor: '',
    draft_s3_url_raw: '',
    s3_url_thumbnail: '',
  };
}

export function deleteProfilePictureError() {
  return {
    type: DRAFT_DELETE_PROFILE_PICTURE_ERROR,
  };
}

/**
 * Handles event when user selects a profile picture to upload
 *
 * @function
 * @param {object} e - Event object
 * @param {number} doctor_id - Doctor id
 */
export function onProfilePictureUpload(e, doctor_id, that) {
  return (dispatch) => {
    dispatch(uploadProfilePicturePending());
    let formData = new FormData();
    const max_image_dimension = 500;
    formData.append('file', e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (event) {
        let img = new Image();
        img.onload = function () {
          if (img.width > max_image_dimension && img.height > max_image_dimension) {
            Axios.post(`/apiv3/doctor/${doctor_id}/profilepicturedraft`, formData)
              .then((res) => {
                dispatch(uploadProfilePictureSuccess(res.data.s3_url_raw, res.data.s3_url_thumbnail, res.data.presigned_url_raw));
                that.inputReference.current.value = '';
              })
              .catch(function (err) {
                dispatch(uploadProfilePictureError(err));
                that.inputReference.current.value = '';
              });
          } else {
            dispatch(uploadProfilePictureErrorSize());
            that.inputReference.current.value = '';
          }
        };
        if (reader.result.includes('image/jpg') || reader.result.includes('image/jpeg') || reader.result.includes('image/png')) {
          img.src = reader.result;
        } else {
          dispatch(unSupportedFileTypeError());
          that.inputReference.current.value = '';
        }
      };
    }
  };
}

export function uploadProfilePicturePending() {
  return {
    type: DRAFT_UPLOAD_PROFILE_PICTURE_PENDING,
  };
}

export function uploadProfilePictureSuccess(draft_s3_url_raw, s3_url_thumbnail, presigned_url_raw) {
  return {
    type: DRAFT_UPLOAD_PROFILE_PICTURE_SUCCESS,
    draft_profile_image_edit: presigned_url_raw,
    draft_s3_url_raw: draft_s3_url_raw,
    s3_url_thumbnail: s3_url_thumbnail,
  };
}

export function unSupportedFileTypeError() {
  return {
    type: DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR,
  };
}

export function uploadProfilePictureError(err) {
  if (err.response.status === 400 && err.response.data === 'Invalid format') {
    return {
      type: DRAFT_INVALID_PROFILE_PICTURE_FORMAT_ERROR,
    };
  }
  return {
    type: DRAFT_UPLOAD_PROFILE_PICTURE_ERROR,
  };
}
/**
 * Handles event when user selects a profile picture to upload which is smaller than required dimension
 *
 * @function
 * @return {object} - Returns redux action type
 */
export function uploadProfilePictureErrorSize() {
  return {
    type: DRAFT_INVALID_PROFILE_PICTURE_SIZE,
  };
}

// Modals and Menus
export function openUploadPictureModal() {
  return {
    type: DRAFT_OPEN_UPLOAD_PICTURE_MODAL,
  };
}

export function closeUploadPictureModal() {
  return {
    type: DRAFT_CLOSE_UPLOAD_PICTURE_MODAL,
  };
}

export function openRepositionProfilePictureModal() {
  return {
    type: DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_MODAL,
  };
}

export function openRepositionProfilePictureDsoDoctorModal() {
  return {
    type: DRAFT_OPEN_REPOSITION_PROFILE_PICTURE_DSO_DOCTOR_MODAL,
  };
}

/**
 * Close the reposition modal and retrieve last saved profile image and metadata
 *
 * @function
 * @param {number} doctor_id - Doctor id
 * @param {object} that - Instance of class
 */
export function closeRepositionProfilePictureModal(doctor_id, that) {
  return (dispatch) => {
    dispatch(fetchProfilePicturePending());

    Axios.get(`/apiv3/doctor/${doctor_id}/profilepicturedraft?keep_draft_data=True`)
      .then(function (result) {
        if (that.props.is_edit_dso_doctor_account_page) {
          dispatch(
            fetchProfilePictureDsoDoctorSuccess(result.data.presigned_url, result.data.profile_metadata, result.data.s3_url_raw, result.data.s3_url_thumbnail)
          );
        } else {
          dispatch(fetchProfilePictureSuccess(result.data.presigned_url, result.data.profile_metadata, result.data.s3_url_raw, result.data.s3_url_thumbnail));
        }

        if (result.data.presigned_url) {
          console.log(that);
          drawProfilePicture(that, that.props.is_edit_dso_doctor_account_page);
        }

        dispatch({
          type: DRAFT_CLOSE_REPOSITION_PROFILE_PICTURE_MODAL,
        });
      })
      .catch(function (err) {
        dispatch(fetchProfilePictureError());
      });
  };
}

export function openProfilePictureMenu() {
  return {
    type: DRAFT_OPEN_PROFILE_PICTURE_MENU,
  };
}

export function closeProfilePictureMenu() {
  return {
    type: DRAFT_CLOSE_PROFILE_PICTURE_MENU,
  };
}

export function openDeletePictureModal(that) {
  return (dispatch) => {
    dispatch({
      type: DRAFT_OPEN_DELETE_PICTURE_MODAL,
    });
    drawProfilePicture(that, that.props.is_edit_dso_doctor_account_page);
  };
}

export function closeDeletePictureModal() {
  return {
    type: DRAFT_CLOSE_DELETE_PICTURE_MODAL,
  };
}
