/**
 * File: date.js - Contains the helper functions related to date changes
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Moment from 'moment';
import moment from 'moment-timezone';

const slash2dashFormat = (date) => {
  let new_date = date;

  if (date) {
    const slash_format = /(\d{2})[/]*/gm;
    let match = date.match(slash_format);

    if (match && match.length === 3) {
      match = _.map(match, (value) => {
        return value.replace('/', '');
      });

      new_date = `20${match[2]}-${match[0]}-${match[1]}`;
    }
  }

  return new_date;
};

/**
 * Format incoming date to PST as MM/DD/YY hour:min
 * @function
 */
const displayPSTDateTimeStamp = (date) => {
  return Moment.tz(date, 'America/Los_Angeles').format('MM/DD/YY h:mm A');
};

/**
 * Format incoming date to PST as MM/DD/YY
 * @function
 */
const displayPSTDateTime = (date) => {
  return Moment.tz(date, 'America/Los_Angeles').format('MM/DD/YY');
};

/**
 * Determines if today's date is past a given date
 * @function
 * @param date {object} - Date object
 * @return {boolean} - True or false
 */
const isTodayPastDate = (date) => {
  if (date) {
    let current_date = new Date();
    current_date = moment.tz(current_date, 'America/Los_Angeles');
    date = moment.tz(date, 'America/Los_Angeles');
    return current_date.isAfter(date, 'day');
  }
  return false;
};

/**
 * Based on date type, gets list of cases with an existing date and list of cases without a date
 * @function
 * @param {List} cases - Array of cases
 * @param {String} date_type - Date to look for
 * @return {list} List of cases with date, list of cases without date
 */
const getCasesWithAndWithoutDate = (cases, date_type) => {
  let cases_w_date = [];
  let cases_wo_date = [];
  for (const case_copy of cases) {
    if (!case_copy[date_type]) {
      cases_wo_date.push(case_copy);
    } else {
      cases_w_date.push(case_copy);
    }
  }
  return [cases_w_date, cases_wo_date];
};

/**
 * Gets new date with added business days from given date
 * @function
 * @param {object} date - From date to add business days to
 * @param {number} business_days - Number of business days to add
 * @return {object} Date with added business days
 */
function getDateWithAddedBusinessDays(date, business_days) {
  let tmp = new Date(moment.tz(date, 'America/Los_Angeles').format('MM/DD/YY'));
  while (business_days > 0) {
    tmp.setDate(tmp.getDate() + 1);
    if (isBusinessDay(tmp)) {
      --business_days;
    }
  }
  return formatDate(tmp);
}

/**
 * Formats date to slash format
 * @function
 * @param {object} date - Date object
 * @return {string} Formatted date
 */
function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
}

/**
 * Determines if date is a business day
 * @function
 * @param {object} date - Date object
 * @return {boolean} True or false
 */
function isBusinessDay(date) {
  const dayOfWeek = date.getDay();
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    // Weekend
    return false;
  }

  const holidays = [
    '12/31+5', // New Year's Day on a saturday celebrated on previous friday
    '1/1', // New Year's Day
    '1/2+1', // New Year's Day on a sunday celebrated on next monday
    '1-3/1', // Birthday of Martin Luther King, third Monday in January
    '2-3/1', // Washington's Birthday, third Monday in February
    '5~1/1', // Memorial Day, last Monday in May
    '7/3+5', // Independence Day
    '7/4', // Independence Day
    '7/5+1', // Independence Day
    '9-1/1', // Labor Day, first Monday in September
    '10-2/1', // Columbus Day, second Monday in October
    '11/10+5', // Veterans Day
    '11/11', // Veterans Day
    '11/12+1', // Veterans Day
    '11-4/4', // Thanksgiving Day, fourth Thursday in November
    '11-4/5', // Day after Thanksgiving Day, fourth Friday in November
    '12/24+5', // Christmas Day
    '12/25', // Christmas Day
    '12/26+1', // Christmas Day
  ];

  const dayOfMonth = date.getDate(),
    month = date.getMonth() + 1,
    monthDay = month + '/' + dayOfMonth;

  if (holidays.indexOf(monthDay) > -1) {
    return false;
  }

  const monthDayDay = monthDay + '+' + dayOfWeek;

  if (holidays.indexOf(monthDayDay) > -1) {
    return false;
  }

  const weekOfMonth = Math.floor((dayOfMonth - 1) / 7) + 1,
    monthWeekDay = month + '-' + weekOfMonth + '/' + dayOfWeek;

  if (holidays.indexOf(monthWeekDay) > -1) {
    return false;
  }

  const lastDayOfMonth = new Date(date);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(0);
  const negWeekOfMonth = Math.floor((lastDayOfMonth.getDate() - dayOfMonth - 1) / 7) + 1,
    monthNegWeekDay = month + '~' + negWeekOfMonth + '/' + dayOfWeek;
  if (holidays.indexOf(monthNegWeekDay) > -1) {
    return false;
  }

  return true;
}

/**
 * Determines if given date is in between 2 dates
 * @function
 * @param {string} date - Given date
 * @param {string} min_date - Min date
 * @param {string} max_date - Max date
 * @return {boolean} True or false
 */
function isDateInBetween(date, min_date, max_date) {
  date = moment.tz(date, 'America/Los_Angeles');
  min_date = moment.tz(min_date, 'America/Los_Angeles');
  max_date = moment.tz(max_date, 'America/Los_Angeles');
  return date.isBetween(min_date, max_date, 'day', '[]');
}

/**
 * Gets a list of upcoming holidays within the next 10 days, grouping holidays that are too far apart.
 *
 * @param {Array} holidays An array of holiday objects.
 * @param {number} [maxGap] Maximum gap (in days) between holidays to be grouped together (defaults to 1 day).
 * @param {number} [daysRange] Number of days to look ahead for upcoming holidays (defaults to 10 days).
 * @returns {Array} An array of arrays containing grouped upcoming holidays.
 */
function getUpcomingHolidays(holidays, maxGap = 1, daysRange = 10) {
  const groupedHolidays = [];
  const today = Moment();
  const futureDate = today.clone().add(daysRange, 'days');

  const isWithinRange = (holidayDate) => holidayDate.isSameOrAfter(today, 'day') && holidayDate.isSameOrBefore(futureDate, 'day');
  const isNotWeekend = (holidayDate) => holidayDate.day() !== 6 && holidayDate.day() !== 0;

  let prevHolidayDate = null;
  let currentGroup = [];

  if (!holidays || !Array.isArray(holidays)) {
    return groupedHolidays;
  }

  // Sort the holidays array by date.
  holidays.sort((a, b) => Moment(a.holiday_date).diff(Moment(b.holiday_date)));

  holidays.forEach((holiday) => {
    const holidayDate = Moment(holiday.holiday_date);
    const gap = holidayDate.diff(prevHolidayDate, 'days');

    if (isWithinRange(holidayDate)) {
      // Found a holiday within range
      if ((prevHolidayDate === null && isNotWeekend(holidayDate)) || (!isNaN(gap) && gap <= maxGap)) {
        currentGroup.push(holiday);
      } else if (isNotWeekend(holidayDate)) {
        // The gap is too big, so we start a new group.
        groupedHolidays.push([...currentGroup]);
        currentGroup = [holiday];
      }
      prevHolidayDate = holidayDate;
    }
  });

  // Add the last group if it is not empty.
  if (currentGroup.length > 0) {
    groupedHolidays.push([...currentGroup]);
  }

  return groupedHolidays;
}

/**
 * Calculates the holiday range and return date based on the given holiday information.
 * It assumes that, if each holiday from the group has more than 1 item, it means it's a bridge holiday.
 *
 * @param {Array} holidayGroups - An array of holiday objects.
 * @returns {Object[]|null} - An array of objects containing 'from', 'to', 'reopen', and 'holiday' properties,
 *                         or null if the input is invalid or empty.
 */
function calculateHolidayRange(holidayGroups) {
  // Check if holidayInfo is valid and not empty
  if (!holidayGroups || !Array.isArray(holidayGroups)) {
    return null;
  }

  const response = [];

  holidayGroups.forEach((holidayInfo) => {
    // Extract the first and last holiday dates
    const fromDate = new Date(`${holidayInfo[0].holiday_date}T12:00:00.000Z`);
    const lastHolidayDate = new Date(`${holidayInfo[holidayInfo.length - 1].holiday_date}T12:00:00.000Z`);
    const returnDate = new Date(lastHolidayDate);

    const WEEKEND_OFFSET = 3; // Assuming 3 days for weekends, adjust as needed
    returnDate.setDate(returnDate.getUTCDate() + (returnDate.getUTCDay() === 5 ? WEEKEND_OFFSET : 1));

    // Format the dates and create the result object
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    let formattedHoliday = holidayInfo[0].holiday.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
    // Also remove special characters
    formattedHoliday = formattedHoliday.replace(/[^\w\s]/gi, '').trim();
    // Assuming holidayInfo has a 'holiday' property for each item
    response.push({
      from: fromDate.toLocaleDateString('en-US', options),
      to: lastHolidayDate.toLocaleDateString('en-US', options),
      reopen: returnDate.toLocaleDateString('en-US', options),
      holiday: formattedHoliday,
    });
  });

  return response;
}

/**
 * Formats date according to specified format
 * @param [date] {string}
 * @param [format] {string}
 * @return {string}
 */
function dateToFormatted(date, format = 'MM/DD/YYYY HH:mm A') {
  return date ? Moment(date).format(format) : Moment().format(format);
}

export {
  slash2dashFormat,
  displayPSTDateTimeStamp,
  isTodayPastDate,
  getCasesWithAndWithoutDate,
  getDateWithAddedBusinessDays,
  displayPSTDateTime,
  isDateInBetween,
  calculateHolidayRange,
  getUpcomingHolidays,
  dateToFormatted,
};
