import { FETCH_PROGRAMS_STARTED, FETCH_PROGRAMS_WAS_SUCCESSFUL, FETCH_PROGRAMS_FAILED } from '../../actions/programs/programs';

const initialState = {
  programs: [],
  fetchProgramsIsPending: true,
  fetchProgramError: null,
};

export function programsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRAMS_STARTED:
      return {
        ...state,
        fetchProgramsIsPending: true,
        fetchProgramError: null,
      };

    case FETCH_PROGRAMS_WAS_SUCCESSFUL:
      return {
        ...state,
        fetchProgramsIsPending: false,
        fetchProgramError: null,
        programs: action.programs,
      };

    case FETCH_PROGRAMS_FAILED:
      return {
        ...state,
        fetchProgramsIsPending: false,
        fetchProgramError: action.error,
      };

    default:
      return state;
  }
}
