import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';
import { EPopupMenuAction } from '../wasm_controller/processors/tooth-popup-menu-processor';

const actionToReadable = {
  [EPopupMenuAction.Movable]: 'Unlock tooth position',
  [EPopupMenuAction.Fixed]: 'Lock tooth position',
  [EPopupMenuAction.RestorePosition]: 'Reset to malocclusion',
};

export function ToothPopupMenu(props) {
  const menuItems = props.items.map(item => (
    <div key={item} className='tooth-popup-menu-item' onClick={() => props.onItemClick(item)}>{actionToReadable[item] || item}</div>
  ));

  const left = props.x / window.devicePixelRatio;
  const top = props.y / window.devicePixelRatio;

  return <div className='tooth-popup-menu' style={{ left, top }}>{menuItems}</div>;
}

ToothPopupMenu.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.string),
  onItemClick: PropTypes.func.isRequired,
};
