import Axios from 'axios';
import { patientChartTextConstraint } from '../../../common/functions';
// Action Types
export const OPEN_EDIT_PATIENT_MODAL = 'OPEN_EDIT_PATIENT_MODAL';
export const CLOSE_EDIT_PATIENT_MODAL = 'CLOSE_EDIT_PATIENT_MODAL';
export const SET_PATIENT_REF = 'SET_PATIENT_REF';
export const FETCH_DSO_DOCTOR_DATA_PENDING = 'FETCH_DSO_DOCTOR_DATA_PENDING';
export const FETCH_DSO_DOCTOR_DATA_SUCCESS = 'FETCH_DSO_DOCTOR_DATA_SUCCESS';
export const FETCH_DSO_DOCTOR_DATA_ERROR = 'FETCH_DSO_DOCTOR_DATA_ERROR';
export const SET_DSO_DOCTOR_DROPDOWN_VALUE = 'SET_DSO_DOCTOR_DROPDOWN_VALUE';
export const SET_DSO_ADDRESS_DROPDOWN_VALUE = 'SET_DSO_ADDRESS_DROPDOWN_VALUE';

// Action Creators
// -----------------------------------------------------------------------------

export function openEditPatientModal(patient_ref, dso_doctor_id, dso_address_id) {
  return {
    type: OPEN_EDIT_PATIENT_MODAL,
    patient_ref: patient_ref,
    dso_doctor_id: dso_doctor_id,
    dso_address_id: dso_address_id,
  };
}
export function closeEditPatientModal() {
  return {
    type: CLOSE_EDIT_PATIENT_MODAL,
  };
}

export function onPatientRefChange(event) {
  let patient_ref = patientChartTextConstraint(event.target.value);

  patient_ref = patient_ref
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

  return {
    type: SET_PATIENT_REF,
    patient_ref: patient_ref,
  };
}

// Tend

export function fetchDsoDoctorData(doctor_id) {
  return (dispatch) => {
    dispatch(fetchDsoDoctorDataPending());

    Axios.get(`/apiv3/doctor/${doctor_id}/dso`)
      .then(function (res) {
        dispatch(fetchDsoDoctorDataSuccess(res.data.dso_doctors, res.data.dso_addresses));
      })
      .catch(function (err) {
        dispatch(fetchDsoDoctorDataError(err));
      });
  };
}

export function fetchDsoDoctorDataPending() {
  return {
    type: FETCH_DSO_DOCTOR_DATA_PENDING,
  };
}
export function fetchDsoDoctorDataSuccess(dso_doctors, dso_addresses) {
  return {
    type: FETCH_DSO_DOCTOR_DATA_SUCCESS,
    dso_doctors: dso_doctors,
    dso_addresses: dso_addresses,
  };
}
export function fetchDsoDoctorDataError(error) {
  return {
    type: FETCH_DSO_DOCTOR_DATA_ERROR,
    error: error,
  };
}

export function onDsoDoctorDropdownChange(event) {
  return {
    type: SET_DSO_DOCTOR_DROPDOWN_VALUE,
    dso_doctor_dropdown_id: event.value,
  };
}
export function onDsoAddressDropdownChange(event) {
  return {
    type: SET_DSO_ADDRESS_DROPDOWN_VALUE,
    dso_address_dropdown_id: event.value,
  };
}
