/**
 * File: disable_account.js - A component contains modal content disabling a DSO doctor's account
 * Copyright: (c) Copyright August 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './disable_account.scss';
// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//Internal Libs
import { translateStatus, rebrandStatus } from '../../common/helpers';
import { removeCaseIdInitialNumber } from '../../common/functions';
import { getSmileStatusFilterDefinitionIPP } from '../../common/search';
// Redux
import {
  getDsoInformation,
  getAccount,
  getSelectedDoctorIdCases,
  getSelectedDoctorIdSmiles,
  getMissingDoctorError,
} from '../../redux/reducers/ipp/edit_account';
import { onDoctorDisableSelectCases, onDoctorDisableSelectSmiles } from '../../redux/actions/ipp/edit_account';
import CardContainer from '../../doctor/components/container/card_container';

class DisableAccount extends Component {
  /**
   * Displays reassignment message on disable account modal based on case and smile simulation count
   *
   * @param {list} DSO Doctors
   * @return {JSX} Reassignment HTML
   */
  displayReassignmentMessage() {
    const case_count = this.props.account.cases && this.props.account.cases.length;
    const smile_count = this.props.account.smile_simulations && this.props.account.smile_simulations.length;
    const dso_admin_smile_permission = this.hasDsoAdminSmilePermission();
    let assignment_jsx;

    if (case_count && (!smile_count || !dso_admin_smile_permission)) {
      assignment_jsx = (
        <>
          currently has <b>{case_count}</b> cases. The cases below need to be reassigned to disable this account.
        </>
      );
    } else if (case_count && smile_count && dso_admin_smile_permission) {
      assignment_jsx = (
        <>
          currently has <b>{case_count}</b> cases and <b>{smile_count}</b> Smile Simulations. The cases and Smile Simulations below need to be reassigned to
          disable this account.
        </>
      );
    } else if (smile_count && !case_count && dso_admin_smile_permission) {
      assignment_jsx = (
        <>
          currently has <b>{smile_count}</b> Smile Simulations. The Smile Simulations below need to be reassigned to disable this account.
        </>
      );
    } else {
      assignment_jsx = (
        <>
          currently has <b>{case_count}</b> cases. Would you like to disable this account?
        </>
      );
    }
    return (
      <div className={'p-slim grey-text'}>
        Doctor{' '}
        <b>
          {this.props.account.first_name} {this.props.account.last_name}
        </b>{' '}
        {assignment_jsx}
      </div>
    );
  }

  displayCasesToReassignTable(dso_doctors) {
    /**
     * Render the case reassignment table
     *
     * @param {list objects} DSO Doctors
     * @return {JSX} Cases to Reassign HTML
     */
    return (
      <React.Fragment>
        <div className="form-group">
          <span className="assign-label">Assign cases to:</span>
          <select
            className={'heading-item-container' + (this.props.missing_doctor_error && !this.props.selected_doctor_id_cases ? ' error' : '')}
            value={this.props.selected_doctor_id_cases}
            onChange={this.props.onDoctorDisableSelectCases}
          >
            <option disabled value="" hidden>
              Select Doctor
            </option>
            {dso_doctors.map((doctor, index) => {
              return (
                <option value={doctor.id} key={index}>
                  {doctor.user__first_name} {doctor.user__last_name}
                </option>
              );
            })}
          </select>
        </div>
        <CardContainer type="top-border" className="case-table">
          <table>
            <thead>
              <tr>
                <th>Case ID</th>
                <th>Patient</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.props.account.cases.map((cases, index) => {
                if (cases.record_type === 'Draft Submission') {
                  return (
                    <tr key={index}>
                      <td>- - - - -</td>
                      <td>{cases.first_name && cases.last_name ? cases.first_name + ' ' + cases.last_name : '- - - - -'}</td>
                      <td>Draft Submission</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={index}>
                      <td>{removeCaseIdInitialNumber(cases.case_id)}</td>
                      <td>
                        {cases.first_name} {cases.last_name}
                      </td>
                      <td>{rebrandStatus(translateStatus(cases.status_code, 'ipp'))}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </CardContainer>
      </React.Fragment>
    );
  }

  displaySmilesToReassignTable(smile_doctors) {
    /**
     * Render the smile reassignment table
     *
     * @param {list objects} DSO Doctors
     * @return {JSX} Smiles to Reassign HTML
     */
    return (
      <React.Fragment>
        <div className="form-group">
          <span className={smile_doctors.length > 0 ? 'assign-label' : ''}>Assign Smile Simulations to:</span>
          {smile_doctors.length > 0 ? (
            <select
              className={'heading-item-container' + (this.props.missing_doctor_error && !this.props.selected_doctor_id_smiles ? ' error' : '')}
              value={this.props.selected_doctor_id_smiles}
              onChange={this.props.onDoctorDisableSelectSmiles}
            >
              <option disabled value="" hidden>
                Select Doctor
              </option>
              {smile_doctors.map((doctor, index) => {
                return (
                  <option value={doctor.id} key={index}>
                    {doctor.user__first_name} {doctor.user__last_name}
                  </option>
                );
              })}
            </select>
          ) : (
            <span> Unassigned (No available doctor for reassignment)</span>
          )}
        </div>
        <CardContainer type="top-border" className="case-table">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.props.account.smile_simulations.map((smile, index) => {
                if (smile.smile_status !== 'Draft Smile Simulation') {
                  return (
                    <tr key={index}>
                      <td>{smile.smile_id}</td>
                      <td>
                        {smile.smile_first_name} {smile.smile_last_name}
                      </td>
                      <td>{getSmileStatusFilterDefinitionIPP(smile.smile_status)}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </CardContainer>
      </React.Fragment>
    );
  }

  /**
   * Determines if DSO admin has smile simulation permission enabled
   *
   * @function
   * @returns {boolean} True or false
   */
  hasDsoAdminSmilePermission = () => {
    return this.props.dso_info && this.props.dso_info.doctor && this.props.dso_info.doctor.length > 0 && this.props.dso_info.doctor[0].smile;
  };

  render() {
    const dso_doctors = this.props.dso_info.dso_doctors.filter((d) => {
      return d.id !== this.props.account.account_id && d.id !== this.props.account.account_link_id && d.user__is_active;
    });

    const smile_doctors = this.props.dso_info.dso_doctors.filter((d) => {
      return d.id !== this.props.account.account_id && d.id !== this.props.account.account_link_id && d.user__is_active && d.smile;
    });

    return (
      <div className="disable-account">
        {dso_doctors.length === 0 ? (
          <div className={'p-slim grey-text'}>Unable to disable account, no available doctor for reassignment.</div>
        ) : (
          <React.Fragment>
            {this.displayReassignmentMessage()}
            {this.props.account.cases && this.props.account.cases.length > 0 ? this.displayCasesToReassignTable(dso_doctors) : null}
            {this.props.account.smile_simulations && this.props.account.smile_simulations.length > 0 && this.hasDsoAdminSmilePermission()
              ? this.displaySmilesToReassignTable(smile_doctors)
              : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dso_info: getDsoInformation(state),
    account: getAccount(state),
    selected_doctor_id_cases: getSelectedDoctorIdCases(state),
    selected_doctor_id_smiles: getSelectedDoctorIdSmiles(state),
    missing_doctor_error: getMissingDoctorError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onDoctorDisableSelectCases: onDoctorDisableSelectCases,
      onDoctorDisableSelectSmiles: onDoctorDisableSelectSmiles,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DisableAccount);
