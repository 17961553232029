import { createSelector } from 'reselect';

const selectProgramsReducer = (state) => state.programsReducer;

export const getPrograms = createSelector(
  [selectProgramsReducer],
  (programsReducer) => programsReducer.programs
);

export const getFetchProgramsIsPending = createSelector(
  [selectProgramsReducer],
  (programsReducer) => programsReducer.fetchProgramsIsPending
);

export const getFetchProgramsError = createSelector(
  selectProgramsReducer,
  (programsReducer) => programsReducer.fetchProgramError
);
