import React from 'react';
import { Box } from '@material-ui/core';

import { TEETHS, getFilteredIndexes } from '../../manual_wire_utils';

import ManualWireTableHeaderElement from './manual_wire_table_header_element';
import './manual_wire_table_header.scss';

const ManualWireTableHeader = ({ arch, missingTeeths, range }) => {
    let headers = TEETHS[arch];
	const filteredIndexes = getFilteredIndexes(arch, missingTeeths, range, 16);

    return (
        <Box display="flex" flexDirection="row" id='manual-wire-table-header'>
            {filteredIndexes.map((teethIndex, index) => (
				<ManualWireTableHeaderElement
					arch={arch}
					teethLabel={headers[teethIndex-1]}
					index={index}
					key={`manual-wire-table-header-element-${index}`}
				/>
            ))}
        </Box>
    );
};

export default ManualWireTableHeader;
