import React from 'react';
import Button from '../../setup_viewer/components/extras/button';

function WasmSidebarRevisionButtons(props) {
  const { onClickViewChanges, onClickConfirm } = props;
  return (
    <>
      <Button theme="secondary" onClick={onClickViewChanges}>
        View Changes
      </Button>
      <Button theme="danger" onClick={onClickConfirm}>
        Confirm Revision
      </Button>
    </>
  );
}

export default WasmSidebarRevisionButtons;
