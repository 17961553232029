import { FETCH_DDM_STATE_PENDING, FETCH_DDM_STATE_SUCCESS, FETCH_DDM_STATE_ERROR } from '../../actions/ddm/ddm_state';
const initialState = {
  ddm_state: {
    ddm_screen: '',
    ddm_status: '',
    ddm_status_comment: '',
    generation_status: '',
    generation_status_comment: '',
    upload_file: '',
    specs_file: null,
    specs_manual_file: '',
    design_file: null,
    design_manual_file: '',
    design_raw_file: null,
  },
};

export function ddmStateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DDM_STATE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FETCH_DDM_STATE_SUCCESS:
      return {
        ...state,
        ddm_state: action.ddm_state,
      };
    case FETCH_DDM_STATE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export const getDdmState = (state) => {
  return state.ddmStateReducer.ddm_state;
};
