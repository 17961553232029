import './program_agreement.scss';
import React, { Component } from 'react';
/**
 * show Inbrace Referral program agreement texts
 * @component
 * @alias ProgramAgreementText
 * @category IPP
 */
class ProgramAgreementText extends Component {
  render() {
    return (
      <div className="agreement">
        <div id="page_1" className="page">
          <p className="p0 ft0">INBRACE® PATIENT REFERRAL CODE PROGRAM SERVICES AGREEMENT</p>
          <p className="p1 ft1">
            This Services Agreement (the “<span className="ft0">Agreement</span>”) by and between Swift Health Systems, Inc., a California corporation located
            at 111 Academy, Ste 150, Irvine CA 92617 (“<span className="ft0">Swift</span>”) and the party executing this Agreement (“
            <span className="ft0">Provider</span>
            ”) is effective as of the date accepted by Provider (the “<span className="ft0">Effective Date</span>”).
          </p>
          <p className="p2 ft1">
            BY DIGITALLY EXECUTING THIS AGREEMENT, PROVIDER EXPRESSLY ACCEPTS AND AGREES TO THE TERMS OF THIS AGREEMENT. IF YOU ARE AN INDIVIDUAL AGREEING TO
            THE TERMS OF THIS AGREEMENT ON BEHALF OF AN ENTITY, SUCH AS YOUR EMPLOYER, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ENTITY AND
            “PROVIDER” SHALL REFER HEREIN TO SUCH ENTITY. IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THE TERMS OF THIS AGREEMENT, YOU MUST
            NOT EXECUTE THIS AGREEMENT.
          </p>
          <p className="p3 ft1">
            WHEREAS, Swift manufactures the INBRACE® Orthodontic System (the “<span className="ft0">INBRACE Device</span>”) and wishes to implement a pilot
            program (the “<span className="ft0">Program</span>”) to help improve the consumer experience regarding access to the INBRACE Device by connecting
            interested consumers who contact Swift through Swift’s INBRACE® advertisements with orthodontists who are experienced with the INBRACE Device;
          </p>
          <p className="p4 ft1">
            WHEREAS, Provider desires to participate in the Program to provide eligible consumers who are also participating in the Program and who the Provider
            has examined and prescribed the INBRACE Device (“<span className="ft0">Patients</span>”) with dental services relating to the INBRACE Device at the
            Program’s special pricing, including any New Patient Code offered by Swift to consumers who consent to participate in the Program;
          </p>
          <p className="p4 ft1">
            WHEREAS, simultaneously with the execution of this Agreement, Swift and Provider are entering into a Customer Agreement in the form attached hereto
            as <span className="ft0">Exhibit B </span>(the “<span className="ft0">Customer Agreement</span>”), which agreement will govern Provider’s purchases
            of the INBRACE Devices from Swift; and
          </p>
          <p className="p2 ft1">
            NOW, THEREFORE, in consideration of the premises and the mutual promises set forth in this Agreement, and other good and valuable consideration, the
            exchange, receipt and sufficiency of which are acknowledged, the parties agree as follows:
          </p>
          <p className="p5 ft1">
            <span className="ft1">1.0</span>
            <span className="ft2">Services and Fees</span>.
          </p>
          <p className="p6 ft1">
            <span className="ft1">1.1</span>
            <span className="ft3">Each of Swift and Provider will perform their respective obligations set forth in </span>
            <span className="ft0">Exhibit A (Services Description)</span>. For each consumer who participates in the Program, executes an Informed Consent Form
            and independently selects a Provider from Swift’s listing of licensed orthodontists who have experience with the INBRACE Device for independent
            professional evaluation and obtaining services related to the INBRACE Device, Provider agrees to provide the services described herein for the
            maximum fee mutually agreed upon between and among all parties, as specified in <span className="ft0">Schedule 1 </span>to new Patients who present
            a New Patient Code that evidences the Patient’s participation in and acceptance of the terms and conditions of this Program.
          </p>
        </div>
        <div id="page_2" className="page">
          <div id="id2_1">
            <p className="p7 ft5">
              Upon receiving a valid prescription order from Provider for an INBRACE Device as described in the Customer Agreement (“
              <span className="ft4">Order</span>”), Swift agrees to provide the ordered INBRACE Device to Provider at the price specified in{' '}
              <span className="ft4">Schedule 1 </span>attached to this Agreement.
            </p>
            <p className="p8 ft1">
              <span className="ft1">1.2</span>
              <span className="ft3">
                For qualifying new Patients who present the New Patient Code, Provider agrees to apply the entire value of the New Patient Code to the maximum
                fee charged to such new Patients, or the discounted pricing as specified in{' '}
              </span>
              <span className="ft0">Schedule 1</span>, which have been accepted by Patients as part of the informed consent process and participation in this
              Program. When submitting such an Order to Swift, Provider agrees to disclose the New Patient Code received from the new Patient. Such New Patient
              Code or discounted pricing offered under this Program shall not apply to any other dental services or products outside of this Program or outside
              the scope of this Agreement.
            </p>
            <p className="p9 ft1">
              <span className="ft1">1.3</span>
              <span className="ft3">
                Provider’s participation in this Program is entirely voluntary. Provider is free to terminate his/her participation in the Program at any time.
                Provider is under no obligation to prescribe the INBRACE Device to any interested consumer who consents to participation in the Program. No
                compensation shall be provided by Swift to Provider or to any Patient in connection with Provider’s participation in the Program. All clinical
                decisions, including whether an interested consumer is eligible for the INBRACE Device and confirmation of the consultation appointment shall be
                made independently by the Provider. Provider agrees that fees it charges to Patients for all dental services provided by Provider in connection
                with the INBRACE, including the consultation appointment, exams and{' '}
              </span>
              <nobr>follow-ups</nobr> necessary for the INBRACE treatment plan, as more particularly set forth in <span className="ft0">Exhibit A</span>, shall
              not exceed the maximum amounts set forth in <span className="ft0">Schedule 1 </span>to this Agreement.
            </p>
            <p className="p10 ft5">
              <span className="ft1">1.4</span>
              <span className="ft6">
                All communications between Provider and Swift relating to the fulfillment of an Order shall comply with the Business Associate Agreement
                attached hereto as{' '}
              </span>
              <span className="ft4">Exhibit C</span>.
            </p>
            <p className="p11 ft1">
              <span className="ft1">2.0</span>
              <span className="ft2">Compliance</span>.
            </p>
            <p className="p12 ft1">
              <span className="ft1">2.1</span>
              <span className="ft3">
                By entering into this Agreement, the parties specifically intend to comply with all applicable laws, rules, and regulations, including the
                Federal Food, Drug, and Cosmetic Act and implementing regulations, state regulation on the practice of dentistry and patient privacy, and state{' '}
              </span>
              <nobr>anti-referral</nobr> laws. Parties understand and agree that no part of any consideration provided hereunder is a prohibited payment for the
              recommending or arranging for the referral of business or the ordering of items or services; nor are the payments intended to induce illegal
              referrals of business. Nothing in this Agreement shall be construed as an interference on Provider’s independent practice of dentistry, or an
              inducement or a referral for a commercial product or service. Nothing in this Agreement or the services provided thereunder shall be construed as
              or require marketing or promotion of Provider’s dental practice.
            </p>
            <p className="p13 ft1">
              <span className="ft1">2.2</span>
              <span className="ft3">
                In the event that any part of this Agreement is determined to violate federal, state, or local laws, rules, or regulations, the parties agree to
                negotiate in good faith revisions to the provision or provisions which are in violation. In the event the parties are unable to agree to new or
                modified terms as required to bring the entire Agreement into compliance, either party may terminate this Agreement on sixty (60) days written
                notice to the other party.
              </span>
            </p>
          </div>
        </div>
        <div id="page_3" className="page">
          <div id="id3_1">
            <p className="p15 ft1">
              <span className="ft1">3.0</span>
              <span className="ft2">Term and Termination</span>.
            </p>
            <p className="p6 ft1">
              <span className="ft1">3.1</span>
              <span className="ft3">
                The term of this Agreement shall commence on the Effective Date and, unless earlier terminated in accordance with this Section{' '}
              </span>
              <a href="#page_3">3.0 </a>or extended by mutual written agreement of the Parties, shall expire upon the first anniversary of the Effective Date
              (the “<span className="ft0">Term</span>”).
            </p>
            <p className="p16 ft5">
              <span className="ft1">3.2</span>
              <span className="ft6">This Agreement may be terminated by either party, without cause, upon ninety (90) days’ notice to the other party.</span>
            </p>
            <p className="p17 ft1">
              <span className="ft1">3.3</span>
              <span className="ft3">The </span>
              <nobr>non-breaching</nobr> party may terminate this Agreement if the other party materially breaches any terms and conditions of this Agreement
              and does not cure the breach within 30 days after receipt of written notice of the breach.
            </p>
            <p className="p18 ft1">
              <span className="ft1">3.4</span>
              <span className="ft3">The obligations of the parties contained in Sections </span>
              <a href="#page_4">3.4, 4.0, 5.3, </a>and <a href="#page_5">7.0</a>-
            </p>
            <p className="p19 ft1">
              <span className="ft1">16.0</span>
              <span className="ft3">
                shall survive expiration or termination of this Agreement. Upon termination or expiration of this Agreement, Provider will continue to provide
                treatment for Patients who have already received an INBRACE Device according to this Agreement.
              </span>
            </p>
            <p className="p5 ft1">
              <span className="ft1">4.0</span>
              <span className="ft2">Confidentiality</span>.
            </p>
            <p className="p20 ft7">
              <span className="ft7">4.1</span>
              <span className="ft8">Confidential Information</span>. Each party may from time to time disclose to the other party Confidential Information. As
              used herein, “Confidential Information” means any information disclosed by one party to the other party that (a) if disclosed in tangible form, is
              marked “confidential” or with other similar designation to indicate its confidential or proprietary nature; (b) if disclosed orally, is indicated
              orally to be confidential or proprietary by the Party disclosing the information at the time of the disclosure and is confirmed in writing as
              confidential or proprietary by the disclosing Party within <nobr>forty-five</nobr> (45) days after the initial disclosure; or
            </p>
            <p className="p21 ft1">
              (c) is of the type otherwise reasonably expected to be treated in a confidential manner under the circumstances of disclosure or by the nature of
              the information itself. Notwithstanding the foregoing, Confidential Information shall not include any information to the extent that such
              information, as demonstrated by written documentation: (i) is or becomes generally available to the public through no fault of the receiving
              party; (ii) is known by the receiving party, other than under an obligation of confidentiality, at the time of its disclosure by the other party;
              (iii) is independently developed by the receiving party after the date of disclosure without the application or use of the disclosing party’s
              Confidential Information; or (iv) becomes known to the receiving party without an obligation of confidentiality from a source other than the
              disclosing party without breach of this Agreement by such party, provided, that such source has the lawful right to disclose such Confidential
              Information to such party.
            </p>
            <p className="p22 ft1">
              <span className="ft1">4.2</span>
              <nobr>
                <span className="ft2">Non-Use</span>
              </nobr>
              <span className="ft9"> and </span>
              <nobr>
                <span className="ft9">Non-Disclosure</span>.
              </nobr>{' '}
              Except as reasonably necessary to fulfill its obligations or exercise its rights under this Agreement, each party and its respective personnel
              shall not use or disclose to any third parties any Confidential Information of the other party. Nothing contained in this Section{' '}
              <a href="#page_3">4.0 </a>shall prevent either party from disclosing any Confidential Information of the other party to the extent reasonably
              necessary in complying with applicable laws or orders; provided that if a party is required by law to make any such disclosure of the other
              party’s Confidential Information, other than pursuant to a confidentiality agreement, it will, to the
            </p>
          </div>
        </div>
        <div id="page_4" className="page">
          <p className="p23 ft5">
            extent legally permissible, give reasonable advance notice to the other party of such disclosure and will use its reasonable efforts to secure
            confidential treatment of such Confidential Information.
          </p>
          <p className="p17 ft1">
            <span className="ft1">4.3</span>
            <span className="ft2">Maintenance</span>. The receiving party shall take reasonable measures to protect the secrecy of and avoid disclosure and
            unauthorized use of the Confidential Information of the disclosing party. Without limiting the foregoing, the receiving party shall take at least
            those measures that it employs to protect its own confidential information of a similar nature and shall ensure that its personnel who have access
            to Confidential Information of the disclosing party have signed a <nobr>non-use</nobr> and <nobr>non-disclosure</nobr> agreement in content at least
            as protective of the disclosing party and its Confidential Information as the provisions of this Agreement, prior to any disclosure of the
            disclosing party’s Confidential Information to such personnel.
          </p>
          <p className="p8 ft1">
            <span className="ft1">4.4</span>
            <span className="ft2">Required Disclosures</span>. The receiving party will be allowed to disclose Confidential Information to the extent that the
            disclosure is required by law or by the order of a court of similar judicial or administrative body, provided that the receiving party notifies the
            disclosing party of the required disclosure promptly and in writing and cooperates with the disclosing party, at the disclosing party’s request and
            expense, in any lawful action to contest or limit the scope of the required disclosure.
          </p>
          <p className="p11 ft1">
            <span className="ft1">5.0</span>
            <span className="ft2">Representations and Warranties</span>.
          </p>
          <p className="p12 ft1">
            <span className="ft1">5.1</span>
            <span className="ft3">
              Each party represents and warrants that it is qualified and permitted to enter into this Agreement and that the terms of the Agreement are not
              inconsistent with its other contractual arrangements.
            </span>
          </p>
          <p className="p24 ft1">
            <span className="ft1">5.2</span>
            <span className="ft3">
              Each party represents and warrants that (a) it has the full corporate power and authority to enter into this Agreement, (b) this Agreement has
              been duly authorized, and (c) this Agreement is binding upon it.
            </span>
          </p>
          <p className="p24 ft1">
            <span className="ft1">5.3</span>
            <span className="ft3">
              SWIFT WARRANTS INBRACE DEVICES, IF AT ALL, DIRECTLY TO PATIENTS. OTHER THAN EXPRESS WARRANTIES, IF ANY, GRANTED BY SWIFT DIRECTLY TO PATIENTS
              UNDER THE APPLICABLE TERMS, INBRACE DEVICES ARE PROVIDED “AS IS” WITHOUT WARRANTIES OF ANY KIND. EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THIS
              AGREEMENT, SWIFT DOES NOT MAKES ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO INBRACE DEVICES OR THE
              SERVICES PROVIDED HEREUNDER, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF
              THE INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES.
            </span>
          </p>
          <p className="p25 ft1">
            <span className="ft1">6.0</span>
            <span className="ft2">Indemnification</span>.
          </p>
          <p className="p6 ft1">
            <span className="ft1">6.1</span>
            <span className="ft2">Indemnification by Provider</span>. Provider shall indemnify, defend, and hold harmless Swift and its directors, officers, and
            employees from any liabilities, damages and costs, including costs and attorney fees, resulting from third party claims arising out of: (i)
            Provider’s provision of any services to any consumer(s); (ii) breach by Provider of its representations and warranties under Article{' '}
            <a href="#page_4">5.0; </a>(iii) the gross negligence or willful misconduct of Provider; or (iv) Provider’s independent professional practice.
          </p>
        </div>
        <div id="page_5" className="page">
          <p className="p27 ft7">
            <span className="ft7">7.0</span>
            <span className="ft8">LIMITATION OF LIABILITY</span>. EXCEPT WITH RESPECT TO THE INDEMNIFICATION OBLIGATIONS IN ARTICLE <a href="#page_4">6.0, </a>
            TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT,
            EXEMPLARY, SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING ANY LOST PROFITS, ARISING FROM OR RELATING TO THIS AGREEMENT EVEN IF THE PARTY HAS BEEN ADVISED
            OF THE POSSIBILITY OF THOSE DAMAGES. SWIFT’S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT AND THE INBRACE DEVICES, WHETHER IN
            CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED THE AMOUNT OF FEES PAID TO SWIFT BY PROVIDER PURSUANT TO THIS AGREEMENT DURING THE 12- MONTH PERIOD
            PRECEDING THE EVENTS GIVING RISE TO THAT LIABILITY. THE PARTIES ACKNOWLEDGE THAT THE PRICES SPECIFIED IN THIS AGREEMENT REFLECT THE ALLOCATION OF
            RISK SET FORTH IN THIS AGREEMENT AND THAT SWIFT WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THE FOREGOING LIMITATIONS OF ITS LIABILITY AND THE
            WARRANTY DISCLAIMERS CONTAINED IN THIS AGREEMENT.
          </p>
          <p className="p28 ft1">
            <span className="ft1">8.0</span>
            <span className="ft2">Independent Contractor Relationship</span>. The parties hereto are independent contractors and nothing contained in this
            Agreement shall be construed to place them in the relationship of partners, principal and agent, employer/employee or joint venturer. Both parties
            agree that neither shall have power or right to bind or obligate the other, nor shall either hold itself out as having such authority.
          </p>
          <p className="p29 ft1">
            <span className="ft1">9.0</span>
            <span className="ft2">Notices</span>. Any notice required or permitted to be given hereunder by either party hereunder shall be in writing and shall
            be deemed given on the date received if delivered personally or by fax or five (5) days after the date postmarked if sent by registered or certified
            U.S. mail, return receipt requested, postage prepaid to the following address:
          </p>
          <p className="p30 ft1">If to Provider, to the address provided at execution of the Customer Agreement</p>
          <table cellPadding="0" className="t0">
            <tbody>
              <tr>
                <td className="tr0 td0">
                  <p className="p31 ft1">If to Swift:</p>
                </td>
                <td className="tr0 td1">
                  <p className="p31 ft1">Swift Health Systems, Inc.,</p>
                </td>
              </tr>
              <tr>
                <td className="tr1 td0">
                  <p className="p31 ft10">&nbsp;</p>
                </td>
                <td className="tr1 td1">
                  <p className="p31 ft1">111 Academy, Ste 150</p>
                </td>
              </tr>
              <tr>
                <td className="tr2 td0">
                  <p className="p31 ft11">&nbsp;</p>
                </td>
                <td className="tr2 td1">
                  <p className="p31 ft7">Irvine CA 92617</p>
                </td>
              </tr>
              <tr>
                <td className="tr1 td0">
                  <p className="p31 ft10">&nbsp;</p>
                </td>
                <td className="tr1 td1">
                  <p className="p31 ft1">Attention: Marketing</p>
                </td>
              </tr>
              <tr>
                <td className="tr3 td0">
                  <p className="p31 ft10">&nbsp;</p>
                </td>
                <td className="tr3 td1">
                  <p className="p31 ft1">
                    Phone: (877) <nobr>258-8677</nobr>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="p3 ft1">
            <span className="ft1">10.0</span>
            <span className="ft12">Governing Law</span>. This Agreement and the rights and obligations of the parties hereunder shall be governed by the laws of
            California without regard to the conflict of laws provisions thereof. The parties agree that any dispute regarding the interpretation or validity of
            this Agreement shall be subject to the exclusive jurisdiction of the state and federal courts in Orange County, California, and each party hereby
            agrees to submit to the personal and exclusive jurisdiction and venue of such courts. The prevailing party in any action to enforce this Agreement
            shall be entitled to costs and attorneys’ fees.
          </p>
          <p className="p32 ft5">
            <span className="ft1">11.0</span>
            <span className="ft13">Severability</span>. If any one or more provisions of this Agreement shall be found to be illegal or unenforceable in any
            respect, the validity, legality and enforceability of the remaining
          </p>
        </div>
        <div id="page_6" className="page">
          <div id="id6_1">
            <p className="p14 ft5">
              provisions shall not in any way be affected or impaired thereby, provided the surviving agreement materially comports with the parties' original
              intent.
            </p>
            <p className="p34 ft1">
              <span className="ft1">12.0</span>
              <span className="ft12">Waiver</span>. Waiver or forbearance by either party or the failure by either party to claim a breach of any provision of
              this Agreement or exercise any right or remedy provided by this Agreement or applicable law, shall not be deemed to constitute a waiver with
              respect to any subsequent breach of any provision hereof.
            </p>
            <p className="p35 ft1">
              <span className="ft1">13.0</span>
              <span className="ft12">Changes and Modification</span>. No changes or modifications of this Agreement shall be deemed effective unless in writing
              and executed by the parties hereto.
            </p>
            <p className="p36 ft1">
              <span className="ft1">14.0</span>
              <span className="ft12">Assignment</span>. This Agreement may not be assigned by either party without the prior written consent of the other party,
              such consent not to be unreasonably withheld, except that Swift may assign this Agreement in connection with a sale or merger of all or
              substantially all of the assets to which this Agreement pertains. Any assignment or transfer of this Agreement in violation of the foregoing shall
              be null and void.
            </p>
            <p className="p37 ft1">
              <span className="ft1">15.0</span>
              <span className="ft12">Force Majeure</span>. Except for any payment obligations, neither party will be liable under this Agreement by reason of
              any failure or delay in the performance of its obligations under this Agreement for any cause which is beyond the reasonable control of the
              applicable party, including but not limited to acts of God, such as severe acts of nature or weather events including floods, fires, earthquakes,
              hurricanes, or explosions, war, acts of terrorism, pandemics or epidemics, acts of governmental authorities such as expropriation, condemnation,
              changes in laws and regulations, strikes and labor disputes or other similar events.
            </p>
            <p className="p38 ft1">
              <span className="ft1">16.0</span>
              <span className="ft12">Entire Agreement; Amendment</span>. This Agreement, including the attached Exhibits and Schedules and all Orders placed by
              Provider, represents the complete and entire understanding between the parties regarding the subject matter hereof and supersedes all prior
              negotiations, representations or agreements, either written or oral, regarding this subject matter. In the event of a conflict between this
              Agreement and the terms in an Exhibit or an Order, the terms in this Agreement will control. If the Parties have entered into a separate Program
              Services Agreement for a different program offered by Swift, the terms of this Agreement will only apply to Provider’s participation in the New
              Patient Code program. This Agreement may only be amended in writing executed by Swift and Provider.
            </p>
          </div>
          <div id="id6_2"></div>
        </div>
        <div id="page_7" className="page">
          <p className="p39 ft0">EXHIBIT A</p>
          <p className="p40 ft0">SERVICES DESCRIPTION</p>
          <p className="p41 ft4">
            During the Term of this Agreement, Swift agrees to provide the following services with respect to consumers who elect to participate in the Program
            and execute the Consent Form:
          </p>
          <p className="p42 ft1">
            <span className="ft1">•</span>
            <span className="ft14">
              Provide a list of orthodontists who participate in the Program within the geographic area specified by interested consumers for consumers to
              independently select the Provider they wish to consult with regarding the INBRACE® Orthodontic System.
            </span>
          </p>
          <p className="p43 ft1">
            <span className="ft1">•</span>
            <span className="ft14">
              Collect Consent Form from each consumer who elects to participate in the Program, including acceptance of the special new patient price offered
              under this Program and related Terms and Conditions, and issue a New Patient Code to participating consumers.
            </span>
          </p>
          <p className="p44 ft5">
            <span className="ft1">•</span>
            <span className="ft15">Upon receiving a valid Order from Provider, Swift agrees to supply Provider with the INBRACE Device for the fee in </span>
            <span className="ft4">Schedule 1</span>, which will be billed to the Provider.
          </p>
          <p className="p45 ft4">During the Term of this Agreement, Provider agrees to provide the following services to Patients:</p>
          <p className="p46 ft1">
            <span className="ft1">•</span>
            <span className="ft14">
              Upon request by interested consumers, Provider agrees to consult with consumers who agreed to participate in the Program in a timely manner and
              establish{' '}
            </span>
            <nobr>orthodontist-patient</nobr> relationship if appropriate, as determined independently by Provider.
          </p>
          <p className="p47 ft7">
            <span className="ft7">•</span>
            <span className="ft16">
              For new Patients who are prescribed the INBRACE Device and present the New Patient Code, Provider agrees to provide the following dental services
              for no more than the maximum fee described in{' '}
            </span>
            <span className="ft17">Schedule 1 </span>to such new Patients:
          </p>
          <p className="p15 ft1">
            <span className="ft18">o </span>Consultation appointment
          </p>
          <p className="p48 ft1">
            <span className="ft1">•</span>
            <span className="ft14">Provider is solely responsible for billing and collecting all clinical fees from the Patient.</span>
          </p>
        </div>
        <div id="page_8" className="page">
          <p className="p49 ft0">EXHIBIT B</p>
          <p className="p50 ft0">CUSTOMER AGREEMENT</p>
          <p className="p51 ft21">
            <span className="ft19">Customer agreement available at </span>
            <a href="https://portal.inbrace.com/portal/agreement">
              <span className="ft20">https://portal.inbrace.com/portal/agreement</span>
            </a>
          </p>
        </div>
        <div id="page_9" className="page">
          <p className="p39 ft0">EXHIBIT C</p>
          <p className="p53 ft0">Business Associate Agreement</p>
          <p className="p54 ft1">
            This Business Associate Agreement (“<span className="ft0">BAA</span>”) is entered into by and between Swift Health Systems, Inc., a California
            corporation located at 111 Academy, Ste 150, Irvine CA 92617, as Business Associate, and the party executing this BAA (“
            <span className="ft0">Provider</span>”) as Covered Entity, (in each case as those terms are further defined in Section <a href="#page_9">1.2), </a>
            and is effective as of the date accepted by Provider (“<span className="ft0">Effective Date</span>”). Business Associate and Covered Entity may be
            referred to herein collectively as “<span className="ft0">Parties</span>” or individually as a “<span className="ft0">Party</span>.” This BAA is
            incorporated into and made part of the Service Agreement (as defined below).
          </p>
          <p className="p55 ft1">
            <span className="ft0">WHEREAS</span>, Business Associate provides certain administrative and <nobr>non-clinical</nobr>
          </p>
          <p className="p56 ft5">
            services, including fulfillment of prescription orders for the INBRACE® Orthodontic System, as part of a Pilot Program to Covered Entity pursuant to
            the Services Agreement between Swift Health Systems, Inc. and Provider (the “<span className="ft4">Services Agreement</span>”);
          </p>
          <p className="p57 ft5">
            <span className="ft4">WHEREAS</span>, in connection with these Services, Business Associate may create, receive, maintain or transmit PHI from, to,
            or on behalf of, Covered Entity, which PHI is subject to certain protections under the HIPAA Rules; and
          </p>
          <p className="p58 ft5">
            <span className="ft4">WHEREAS</span>, this BAA defines the rights and responsibilities of each Party with respect to PHI exchanged pursuant to this
            BAA and the Service Agreement(s);
          </p>
          <p className="p59 ft5">
            <span className="ft4">NOW, THEREFORE</span>, for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the
            parties agree as follows:
          </p>
          <p className="p11 ft0">
            <span className="ft1">1.</span>
            <span className="ft22">Definitions</span>
            <span className="ft1">.</span>
          </p>
          <p className="p60 ft1">
            <span className="ft1">a.</span>
            <span className="ft23">All capitalized term used but not defined herein shall have the meaning set forth in the HIPAA Rules.</span>
          </p>
          <p className="p61 ft1">
            <span className="ft1">b.</span>
            <span className="ft24">The following terms are specifically defined as follows:</span>
          </p>
          <p className="p62 ft1">
            <span className="ft1">i.</span>
            <span className="ft25">Business Associate</span>. “Business Associate” has the same meaning as the term “business associate” at 45 CFR 160.103, and
            in reference to the Party to this BAA, shall mean Swift Health Systems, Inc.
          </p>
          <p className="p63 ft1">
            <span className="ft1">ii.</span>
            <span className="ft25">Covered Entity</span>. “Covered Entity” has the same meaning as the term “covered entity” at 45 CFR 160.103, and in reference
            to the Party to this BAA, shall mean Provider.
          </p>
          <p className="p64 ft1">
            <span className="ft1">iii.</span>
            <span className="ft25">HIPAA Rules</span>. “HIPAA Rules” means, with respect to the federal Health Insurance Portability and Accountability Act of
            1996, Pub. Law <nobr>101-191,</nobr> as amended from time to time, the Privacy, Security, Breach Notification, and Enforcement Rules at 45 CFR Part
            160 and Part 164; and the Health Information Technology for Economic and Clinical Health (“HITECH”) Act of 2009 (as applicable).
          </p>
        </div>
        <div id="page_10" className="page">
          <p className="p66 ft1">
            <span className="ft1">iv.</span>
            <span className="ft25">Unsuccessful Security Incidents</span> means, without limitation, pings and other broadcast attacks on Business Associate’s
            firewall, port scans, unsuccessful <nobr>log-on</nobr> attempts, denial of service attacks, and any combination of the above, so long as no such
            incident results in unauthorized access, Use or Disclosure of Covered Entity’s ePHI.
          </p>
          <p className="p67 ft0">
            <span className="ft1">2.</span>
            <span className="ft22">Obligations and Activities of Business Associate</span>
            <span className="ft1">.</span>
          </p>
          <p className="p68 ft1">
            <span className="ft1">a.</span>
            <span className="ft23">
              Business Associate agrees not to Use or Disclose PHI received or created by Business Associate except as permitted by this BAA, the Service
              Agreement(s), or as Required by Law.
            </span>
          </p>
          <p className="p69 ft1">
            <span className="ft1">b.</span>
            <span className="ft24">
              Business Associate agrees to use appropriate safeguards, and to comply with Subpart C of 45 CFR Part 164 with respect to ePHI, to prevent Use or
              Disclosure of PHI other than as provided for by this BAA, the Service Agreement(s), or as Required by Law.
            </span>
          </p>
          <p className="p70 ft1">
            <span className="ft1">c.</span>
            <span className="ft23">
              Business Associate agrees to report to Covered Entity any Use or Disclosure of PHI not provided for by this BAA of which it becomes aware,
              including a Breach of Unsecured PHI as required under 45 C.F.R. §164.410, and any Security Incident of which it becomes aware. Notwithstanding the
              foregoing, the Parties acknowledge and agree that this Section 2(c) constitutes notice by Business Associate to Covered Entity of the ongoing
              existence and occurrence or attempts of Unsuccessful Security Incidents for which no additional notice to Covered Entity shall be required.
            </span>
          </p>
          <p className="p71 ft1">
            <span className="ft1">d.</span>
            <span className="ft24">
              Business Associate agrees, in accordance with 45 CFR 164.502(e)(1)(ii) and 164.308(b)(2), if applicable, to obtain from any Subcontractor that
              creates, receives, maintains, or transmits PHI on behalf of Business Associate pursuant to this BAA and the Service Agreement, reasonable
              assurance that Subcontractor will adhere to restrictions and conditions that apply to Business Associate through this BAA with respect to such PHI
              as required by the HIPAA Rules.
            </span>
          </p>
          <p className="p72 ft1">
            <span className="ft1">e.</span>
            <span className="ft23">
              Business Associate agrees to make available, at the request of Covered Entity, PHI that is maintained in a Designated Record Set (if any) as
              necessary to allow Covered Entity to satisfy its obligations under 45 C.F.R.
            </span>
          </p>
          <p className="p73 ft1">§164.524.</p>
          <p className="p74 ft1">
            <span className="ft1">f.</span>
            <span className="ft26">
              Business Associate Agrees to make amendment(s) to PHI maintained in a Designated Record Set (if any), as requested by the Covered Entity pursuant
              to 45 C.F.R. §164.526, or take other measures as reasonably necessary to enable Covered Entity to satisfy its obligations under 45 C.F.R.
              §164.526.
            </span>
          </p>
          <p className="p75 ft5">
            <span className="ft1">g.</span>
            <span className="ft27">
              Business Associate agrees to maintain and make available to Covered Entity the information required to provide an accounting of Disclosures,
            </span>
          </p>
        </div>
        <div id="page_11" className="page">
          <p className="p77 ft5">as reasonably necessary to satisfy Covered Entity’s obligations under 45 C.F.R. §164.528.</p>
          <p className="p78 ft1">
            <span className="ft1">i.</span>
            <span className="ft28">For clarity, with respect to the forgoing Section </span>
            <a href="#page_10">2.5</a>- <a href="#page_10">2.7, </a>in no case shall Business Associate be responsible for responding directly to any Individual
            who submits a request to Business Associate pursuant to 45 CFR §164.524; provided that Business Associate shall promptly forward such request to
            Covered Entity in accordance with Section{' '}
            <nobr>
              <a href="#page_10">22.5</a>-<a href="#page_10">2.7.</a>
            </nobr>
          </p>
          <p className="p79 ft1">
            <span className="ft1">h.</span>
            <span className="ft24">
              To the extent that Business Associate is to carry out one or more of Covered Entity’s obligation(s) under Subpart E of 45 CFR Part 164, Business
              Associate agrees to comply with the requirements of Subpart E that apply to Covered Entity in the performance of such obligation(s).
            </span>
          </p>
          <p className="p80 ft1">
            <span className="ft1">i.</span>
            <span className="ft29">
              Business Associate agrees to make its internal practices, books, and records, regarding the Use and Disclosure of PHI created or received by the
              Business Associate on behalf of the Covered Entity available to the Secretary for purposes of the Secretary determining compliance with the HIPAA
              Rules.
            </span>
          </p>
          <p className="p81 ft0">
            <span className="ft1">3.</span>
            <span className="ft22">Permitted Uses and Disclosures by Business Associate</span>
            <span className="ft1">.</span>
          </p>
          <p className="p82 ft5">
            <span className="ft1">a.</span>
            <span className="ft30">
              Business Associate may not Use or Disclose PHI in a manner that would violate Subpart E of 45 C.F.R. Part 164 if done by Covered Entity.
            </span>
          </p>
          <p className="p83 ft1">
            <span className="ft1">b.</span>
            <span className="ft24">Business Associate may Use and Disclose PHI:</span>
          </p>
          <p className="p84 ft1">
            <span className="ft1">i.</span>
            <span className="ft28">To perform the Services set forth in the Service</span>
          </p>
          <p className="p85 ft1">Agreement(s) and as otherwise set forth in this BAA;</p>
          <p className="p86 ft5">
            <span className="ft1">ii.</span>
            <span className="ft31">As Required by Law, including to report violations of law to appropriate Federal and State authorities;</span>
          </p>
          <p className="p87 ft1">
            <span className="ft1">iii.</span>
            <span className="ft28">
              For the proper management and administration of the Business Associate or to carry out the legal responsibilities of the Business Associate;
            </span>
          </p>
          <p className="p88 ft1">
            <span className="ft1">iv.</span>
            <span className="ft28">To provide Data Aggregation services to Covered</span>
          </p>
          <p className="p89 ft1">Entity; and</p>
          <p className="p90 ft1">
            <span className="ft1">v.</span>
            <span className="ft28">To </span>
            <nobr>de-identify</nobr> the PHI in accordance with 45 CFR <nobr>164.514(a)-(c),</nobr> (after which the information shall not longer be considered
            PHI and shall not be subject to the terms of this BAA).
          </p>
          <p className="p91 ft1">
            <span className="ft1">c.</span>
            <span className="ft23">Any permitted Use or Disclosure under Section </span>
            <nobr>
              <a href="#page_11">3.2(a)-3.2(d)</a>
            </nobr>
            <a href="#page_11"> </a>shall be consistent with the minimum necessary requirements set forth in the HIPAA Rules.
          </p>
        </div>
        <div id="page_12" className="page">
          <p className="p14 ft0">
            <span className="ft1">4.</span>
            <span className="ft22">Obligations of Covered Entity</span>
            <span className="ft1">.</span>
          </p>
          <p className="p93 ft1">
            <span className="ft1">a.</span>
            <span className="ft23">During the Term of this BAA, Covered Entity shall:</span>
          </p>
          <p className="p94 ft5">
            <span className="ft1">i.</span>
            <span className="ft31">Provide Business Associate with a copy of its Notice of Privacy Practices;</span>
          </p>
          <p className="p95 ft1">
            <span className="ft1">ii.</span>
            <span className="ft28">
              Notify Business Associate of any limitations in its Notice of Privacy Practices, to the extent that such limitation may affect Business
              Associate’s Use or Disclosure of PHI;
            </span>
          </p>
          <p className="p96 ft1">
            <span className="ft1">iii.</span>
            <span className="ft28">
              Notify Business Associate of any changes in, or revocation of, permission by an Individual to Use or Disclose PHI, to the extent that such changes
              may affect Business Associate’s Use or Disclosure of PHI;
            </span>
          </p>
          <p className="p97 ft1">
            <span className="ft1">iv.</span>
            <span className="ft28">
              Not request Business Associate to Use or Disclose PHI in any manner that would not be permissible under HIPAA if done by Covered Entity (other
              than as permitted pursuant to Section{' '}
            </span>
            <nobr>
              <a href="#page_11">3.2(c)</a>-<a href="#page_11">3.2(d),</a>
            </nobr>
            <a href="#page_11"> </a>above); and
          </p>
          <p className="p98 ft1">
            <span className="ft1">v.</span>
            <span className="ft28">Comply with all HIPAA requirements applicable to</span>
          </p>
          <p className="p85 ft1">Covered Entity.</p>
          <p className="p99 ft32">
            <span className="ft0">5.</span>
            <span className="ft22">Term and Termination.</span>
          </p>
          <p className="p100 ft1">
            <span className="ft1">a.</span>
            <span className="ft33">Term</span>. The Term of this BAA shall commence as of the Effective Date and, except for the rights and obligations set
            forth in this BAA specifically surviving termination, shall terminate upon the termination or expiration of the last Service Agreement, unless
            otherwise earlier terminated for cause in accordance with this Section <a href="#page_12">V.</a>
          </p>
          <p className="p101 ft1">
            <span className="ft1">b.</span>
            <span className="ft34">Termination by Covered Entity</span>. In addition to any termination provisions set forth in the applicable Service
            Agreement, Covered Entity may terminate this BAA if Covered Entity determines, in good faith and after reasonable investigation, that Business
            Associate has violated a material term of this BAA, and Business Associate has failed to cure such material breach or end the violation within
            thirty (30) days of notice by Covered Entity of such alleged breach.
          </p>
          <p className="p102 ft1">
            <span className="ft1">c.</span>
            <span className="ft33">Termination by Business Associate</span>. In addition to and notwithstanding any termination provisions set forth in the
            applicable Service Agreement, Business Associate may terminate this BAA if Business Associate determines, in good faith and after reasonable
            investigation, that Covered Entity has violated a material term of this BAA, and Covered Entity has failed to cure such material breach or end the
            violation within thirty (30) days of notice by Business Associate of such alleged breach.
          </p>
        </div>
        <div id="page_13" className="page">
          <p className="p104 ft1">
            <span className="ft1">d.</span>
            <span className="ft34">Effect of Termination</span>. Upon termination or expiration of this BAA for any reason, Business Associate, with respect to
            any PHI received from Covered Entity or created, maintained, or received by Business Associate on behalf of Covered Entity, shall:
          </p>
          <p className="p105 ft1">
            <span className="ft1">i.</span>
            <span className="ft28">
              Retain only that PHI which is necessary for Business Associate to continue its proper management and administration or to carry out its legal
              responsibilities (if any);
            </span>
          </p>
          <p className="p106 ft1">
            <span className="ft1">ii.</span>
            <span className="ft28">
              Return to Covered Entity or destroy the remaining PHI that the Business Associate still maintains in any form that is not necessary to carry out
              Section 5(d)(i);
            </span>
          </p>
          <p className="p107 ft1">
            <span className="ft1">iii.</span>
            <span className="ft28">
              Continue to use appropriate safeguards and comply with Subpart C of 45 CFR Part 164 with respect to ePHI to prevent Use or Disclosure of the PHI,
              other than as provided for in this Section 5.4, for as long as Business Associate retains the PHI;
            </span>
          </p>
          <p className="p108 ft1">
            <span className="ft1">iv.</span>
            <span className="ft28">
              Not Use or Disclose the PHI retained by Business Associate other than for the purposes for which such PHI was retained and subject to the same
              conditions set out at Section 3.2(c) which applied prior to termination; and
            </span>
          </p>
          <p className="p109 ft1">
            <span className="ft1">v.</span>
            <span className="ft28">
              Return to Covered Entity or destroy the PHI retained by Business Associate when it is no longer needed by Business Associate for its proper
              management and administration, or to carry out its legal responsibilities.
            </span>
          </p>
          <p className="p110 ft0">
            <span className="ft1">6.</span>
            <span className="ft22">Miscellaneous</span>
            <span className="ft1">.</span>
          </p>
          <p className="p111 ft7">
            <span className="ft7">a.</span>
            <span className="ft35">
              Notwithstanding anything to the contrary contained herein or set forth in the Service Agreement(s), in no event shall either Party be liable for
              any indirect, incidental, special, punitive, or consequential damages incurred by a Party or any other person in connection with this BAA, whether
              in contract, tort or under any other theory of liability and whether or not the party has been advised of the possibility of such damages; and
              (ii) Business Associate’s aggregate liability under or in connection with this BAA shall not exceed the two
            </span>
          </p>
          <p className="p112 ft1">
            <span className="ft1">(2)</span>
            <span className="ft3">
              times the total amounts paid by Covered Entity to Business Associate under the applicable Service Agreement(s) for Services provided in the 12
              months period preceding the date on which the liability arose.
            </span>
          </p>
          <p className="p113 ft5">
            <span className="ft1">b.</span>
            <span className="ft36">Regulatory References</span>. A reference in this BAA to the HIPAA Rules means the provision or regulation as amended from
            time to time.
          </p>
          <p className="p114 ft1">
            <span className="ft1">c.</span>
            <span className="ft33">Amendment</span>. The Parties agree to take such action as is necessary to amend this BAA from time to time as is necessary
            for Business Associate and Covered Entity to comply with the requirements of the HIPAA Rules. No
          </p>
        </div>
        <div id="page_14" className="page">
          <p className="p115 ft5">amendment to this BAA, however, shall be effective unless agreed to in writing and signed by both Parties.</p>
          <p className="p116 ft5">
            <span className="ft1">d.</span>
            <span className="ft36">Interpretation</span>. Any ambiguity in this BAA shall be resolved to permit Business Associate and Covered Entity to comply
            with the HIPAA Rules.
          </p>
          <p className="p117 ft1">
            <span className="ft1">e.</span>
            <span className="ft33">Conflicts</span>. To the extent that there is any conflict between the provisions of this BAA and any Service Agreement(s),
            the provisions of this BAA shall control with respect to the subject matter herein.
          </p>
          <p className="p118 ft5">
            <span className="ft1">f.</span>
            <span className="ft37">Survival</span>. The rights and obligations under Section 5.4 and Article 6 shall survive the termination of this BAA and the
            Services Agreement.
          </p>
          <p className="p119 ft1">[Remainder of Page Intentionally Blank]</p>
        </div>
        <div id="page_15" className="page">
          <p className="p121 ft0">SCHEDULE 1</p>
          <p className="p122 ft0">Pricing</p>
          <p className="p123 ft1">
            <span className="ft1">•</span>
            <span className="ft14">
              Maximum price charged to new Patients who present the New Patient Code and qualify for InBrace; receive $500 off or $5,500 and applies to InBrace
              appliance only.
            </span>
          </p>
        </div>
      </div>
    );
  }
}

export default ProgramAgreementText;
