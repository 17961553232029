import Axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as FullStory from '@fullstory/browser';

import './login.scss';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginCaseFilterPreferencesSuccess } from '../../redux/actions/case_filter_preferences';
import { setFeatureAnnouncementModal } from '../../redux/actions/ipp/feature_announcement';
import { fetchPrograms } from '../../redux/actions/programs';

import { getJWTToken } from '../../common/token';
import { LogoWhite } from '../assets/logos';
import { resetTokenHeader, setTokenHeader, getCookie } from '../../common/functions';

import CardContainer from '../components/container/card_container';
import LoadingButton from '../components/buttons/loading_button';
import Privacy from '../../components/privacy/external_privacy';
import TextBox from '../components/inputs/text_box';

const fakeAuth = {
  isAuthenticated: false,
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100); // fake async

    let cookies = document.cookie.split(';');

    cookies.map((cookie) => {
      let eqPos = cookie.indexOf('=');
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      return cookie;
    });
  },
  isLoggedIn() {
    let token = false;

    if (token) {
      this.isAuthenticated = true;
      return true;
    } else {
      return false;
    }
  },
};

/**
 * Main login screen for the Provider/Business Portal
 *
 * @component
 */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      auth: false,
      loading: true,
      returnurl: '',
      locked: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
  }

  componentDidMount() {
    let that = this;
    let token = getJWTToken();
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    let returnurlparam = params.get('returnurl');
    if (returnurlparam) {
      params.forEach(function (value, key) {
        if (key !== 'returnurl') {
          returnurlparam += '&' + key + '=' + value;
        }
      });
      that.setState({
        returnurl: returnurlparam,
      });
    }
    //Push history
    if (token) {
      Axios.get('/apiv3/doctor')
        .then(function (response) {
          if (response && response.data && response.data.doctor && response.data.doctor.length > 0) {
            let id = response.data.doctor[0].id;
            let role = response.data.doctor[0].role;

            if (id) {
              if (role && (role.indexOf('Business') >= 0 || role.indexOf('Admin') >= 0)) {
                that.props.history.push(`/business/portal`);
              } else {
                that.props.history.push(`/portal/${id}/dashboard`);
              }
            }
          }
        })
        .catch(function (err) {
          that.setState({
            loading: false,
          });

          if (err && err.response && err.response.status === 401) {
            resetTokenHeader();
          } else {
            console.log(err);
          }
        });
    } else {
      that.setState({
        loading: false,
      });
    }
  }

  onLoginSuccess = () => {
    this.props.fetchPrograms({});
  };

  /**
   * Update state value based on input changes
   * @function
   */
  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      locked: false,
    });

    this.removeWarning();
  }

  removeWarning = () => {
    const warning_login = document.querySelector('#warning-login');
    if (warning_login && warning_login.classList) {
      warning_login.classList.remove('warning-display');
    }
  };
  /**
   * Handle submission of the form
   * @function
   */
  handleSubmit(event) {
    if (this.state.email && this.state.password) {
      event.preventDefault();
      resetTokenHeader();

      this.setState({ loading: true });
      var that = this;
      Axios.post('/auth/auth-jwt/', {
        username: this.state.email.toLowerCase(),
        password: this.state.password,
      })
        .then(function (result) {
          that.setState({
            email: '',
            password: '',
          });

          localStorage.setItem('token', result.data.token);

          setTokenHeader();

          that.onLoginSuccess();

          Axios.get('/apiv3/doctor').then(function (response) {
            if (response && response.data && response.data.doctor && response.data.doctor.length > 0) {
              const doctor = response.data.doctor[0];
              let id = doctor.id;
              let role = doctor.role;
              that.identifyUser(doctor);

              if (role !== 'Business' && role !== 'DSO') {
                const show_feature_announcement = !doctor.smile_design_preferences_accepted && doctor.is_enrolled_smile_design_preferences;
                if (show_feature_announcement) {
                  that.props.setFeatureAnnouncementModal(true);
                }
              }

              //Add return URL saved from querystring
              let returnurl = that.state.returnurl;
              let hasRedirect =
                that.props && that.props.history && that.props.history.location && that.props.history.location.state && that.props.history.location.state.from;

              if (hasRedirect) {
                that.props.history.push(that.props.history.location.state.from);
              } else if (returnurl) {
                //if the url is for the static setup viewer then do the hard redirect instead of chaning the routes.
                if (returnurl.includes('setup_viewer.html')) {
                  window.location.href = returnurl;
                } else {
                  if (returnurl.indexOf('/business/portal') >= 0) {
                    if (role && (role.indexOf('Business') >= 0 || role.indexOf('Admin') >= 0)) {
                      that.props.history.push(returnurl);
                    } else {
                      that.props.history.push(`/portal/${id}/dashboard`);
                    }
                  } else {
                    that.props.history.push(returnurl);
                  }
                }
                //window.location.reload();
              } else if (id) {
                if (role && (role.indexOf('Business') >= 0 || role.indexOf('Admin') >= 0)) {
                  that.props.history.push(`/business/portal`);
                } else {
                  that.props.history.push(`/portal/${id}/dashboard`);
                }
              }
            }
          });

          that.props.loginCaseFilterPreferencesSuccess();
        })
        .catch(function (errors) {
          that.setState({
            locked: errors && errors.response && errors.response.status === 401,
          });
          document.querySelector('#warning-login').classList.add('warning-display');
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      event.preventDefault();
    }
  }

  /**
   * Identify user on fullstory upon login
   *
   * Check environment, if production use the doctor id, else use the doctorId_environment
   * We do this because since 04/14/24 we started sharing FS orgs and we want to make sure we are not mixing data
   * while also preserving what we have for production
   * @function
   * @param {Object} doctor_info - Doctor info from doctor api
   */
  identifyUser(doctor_info) {
    const id = String(doctor_info['id']);
    const env = process.env.REACT_APP_ENVIRONMENT || 'dev';
    const uuid = env === 'production' ? id : `${id}_${env}`;
    const email = doctor_info['user__email'];
    const firstname = doctor_info['user__first_name'];
    const lastname = doctor_info['user__last_name'];
    const name = firstname + ' ' + lastname;
    FullStory.identify(uuid, { displayName: name, email: email, host: window.location.host });
  }

  onInputClick(e) {
    document.querySelector('#warning-login').classList.remove('warning-display');
  }

  render() {
    return (
      <div className="ipp-light background login-container">
        <Helmet>
          <title>Login | InBrace Smile Design Studio™</title>
          <meta
            name="description"
            content="Login to the InBrace Smile Design Studio™ TM to easily submit cases, manage your InBrace treatment progress, and access our Knowledge Base."
          />
        </Helmet>
        <div className="split-50 text-center">
          <div className="login-window">
            <form>
              <div>
                <LogoWhite width="280" />
                <h3 className="heading--light">Welcome to the InBrace Smile Design Studio™</h3>
                <label className="content--left heading--light" htmlFor="email">
                  Account Email
                </label>
                <TextBox
                  id="login-email"
                  type="text"
                  name="email"
                  placeholder="Account Email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  onClick={this.onInputClick}
                  autoFocus={true}
                />
                <label className="content--left heading--light" htmlFor="email">
                  Password
                </label>
                <TextBox
                  id="login-password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  onClick={this.onInputClick}
                />
                <Link className="block white" to="/recovery">
                  Forgot your password?
                </Link>
                <Link to="/portal">
                  <LoadingButton type="submit" theme="dark" isLoading={this.state.loading} onClick={this.handleSubmit}>
                    Login
                  </LoadingButton>
                </Link>
              </div>
            </form>
            <p id="warning-login" className="warning-login heading--warning">
              <CardContainer type="error-message">
                {this.state.locked
                  ? `Your account has been locked due to too many login attempts. Please try again in 10 minutes.`
                  : `Invalid username or password. Please try again`}
              </CardContainer>
            </p>
          </div>

          <Privacy className="footer-display heading--light" />
        </div>
        <div className="split-50 login-side"></div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginCaseFilterPreferencesSuccess: loginCaseFilterPreferencesSuccess,
      setFeatureAnnouncementModal: setFeatureAnnouncementModal,
      fetchPrograms,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(Login));

export { fakeAuth, getCookie };
