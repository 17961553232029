import React from 'react';
import PropTypes from 'prop-types';

import IPRChart from './ipr_chart';
import IPRTitle from './ipr_title';
import TeethUtils from '../../../../../../common/teeth_utils';

import { isSetup } from '../../../common/functions';

/**
 * Displays the entire IPR
 * @component
 */
function IPRWindow(props) {
  const show_ipr = props.showIPR && isSetup(props);
  const { ipr } = props;

  const has_iprs = TeethUtils.iprInfoHasIPR(ipr);

  const showClassName = show_ipr ? '' : 'invisible ';
  const hasIPRClassName = has_iprs ? '' : 'ipr-none ';
  const classNames = `${showClassName}${hasIPRClassName}ipr-window fade-in`;
  return (
    <div className={classNames}>
      <div className="ipr-window-area">
        {has_iprs ? (
          <>
            <IPRTitle />
            <IPRChart ipr_info={ipr} />
          </>
        ) : (
          <div>There is no IPR.</div>
        )}
      </div>
    </div>
  );
}

IPRWindow.propTypes = {
  showIPR: PropTypes.bool.isRequired,
  scroll_options: PropTypes.array.isRequired,
  pane: PropTypes.number.isRequired,
};

export default IPRWindow;