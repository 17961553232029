import Axios from 'axios';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AlertMessage from './alert_message';
import Dropdown from './inputs/dropdown';
import SimpleModal from './modals/simple-modal';
import { closeFeedbackForm, restartFeedbackForm, submitFeedbackForm } from '../../redux/actions/ipp/feedback_form';
import { getDoctorsIdFromRoute } from '../../common/route';
import { getDoctorInfo } from '../../redux/reducers/ipp/dashboard';
import {
  getShowFeedbackForm,
  getSubmitFeedbackFormError,
  getSubmitFeedbackFormPending,
  getSubmitFeedbackFormSuccess,
} from '../../redux/reducers/ipp/feedback_form';
import { removeEmoji } from '../../common/functions';

const feedbackCategoryOptions = [
  { value: 'Share my experience', label: 'Share my experience' },
  { value: 'Report a bug', label: 'Report a bug' },
  { value: 'Suggest an improvement', label: 'Suggest an improvement' },
];

const commentMaxLength = 5000;

const InputErrorMessage = ({ message }) => {
  return <div className="text-input__error">{message}</div>;
};

const FeedbackForm = ({
  closeFeedbackForm,
  doctorInfo,
  feedbackFormSubmitError,
  isFeedbackFormSubmitSuccess,
  isFeedbackFormSubmitting,
  restartFeedbackForm,
  showFeedbackForm,
  submitFeedbackForm,
}) => {
  const [doctor_id, setDoctorId] = React.useState(doctorInfo?.doctor_id);
  const [selectedFeedbackCategory, setSelectedFeedbackCategory] = React.useState(null);
  const [errors, setErrors] = React.useState({});
  const [comment, setComment] = React.useState('');

  const getCommentLabel = () => {
    switch (selectedFeedbackCategory.value) {
      case 'Share my experience':
        return 'What would you like to share?';
      case 'Report a bug':
        return 'Describe the bug or issue';
      case 'Suggest an improvement':
        return 'Let us know what you would like to improve';
      default:
        return 'Comment';
    }
  };

  const getCancelText = () => {
    if (isFeedbackFormSubmitSuccess) {
      return 'Close';
    }
    return 'Cancel';
  };

  const getModalHeaderTitle = () => {
    if (isFeedbackFormSubmitSuccess) {
      return 'Feedback Submitted';
    }
    return 'Give your feedback';
  };

  const submitIsDisabled = () => {
    return Object.keys(errors).length > 0 || isFeedbackFormSubmitting || isFeedbackFormSubmitSuccess;
  };

  const showConfirm = () => {
    return !isFeedbackFormSubmitSuccess;
  };

  const cancelIsDisabled = () => {
    return isFeedbackFormSubmitting;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedFeedbackCategory) {
      newErrors.selectedFeedbackCategory = 'Please select a category';
    }
    if (selectedFeedbackCategory && !comment) {
      newErrors.comment = 'Please enter a comment';
    }
    setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
    return Object.keys({ ...errors, ...newErrors }).length === 0;
  };

  const handleFeedbackCategoryChange = (selectedOption) => {
    setSelectedFeedbackCategory(selectedOption);
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.selectedFeedbackCategory;
      return newErrors;
    });
  };

  const handleCommentChange = (event) => {
    const value = removeEmoji(event.target.value);
    setComment(value);
    if (value.length > commentMaxLength) {
      setErrors((prevErrors) => ({ ...prevErrors, commentCharacterLimit: 'Character limit exceeded' }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors.commentCharacterLimit;
        return newErrors;
      });
    }
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors.comment;
      return newErrors;
    });
  };

  const handleOnClose = () => {
    closeFeedbackForm();
    setSelectedFeedbackCategory(null);
    setComment('');
    setErrors({});
    restartFeedbackForm();
  };

  const handleCancel = () => {
    closeFeedbackForm();
    setSelectedFeedbackCategory(null);
    setComment('');
    setErrors({});
    restartFeedbackForm();
  };

  const handleConfirm = () => {
    if (validateForm()) {
      const data = {
        type: 'Give Your Feedback',
        feedback_category: selectedFeedbackCategory.value,
        comment: comment,
        rating: null,
        url: window.location.href,
        case_id: null,
      };
      submitFeedbackForm(doctor_id, data);
    }
  };

  React.useEffect(() => {
    if (!doctor_id) {
      if (getDoctorsIdFromRoute()) {
        setDoctorId(getDoctorsIdFromRoute());
      } else {
        Axios.get(`/apiV2/account_light`).then(function (res) {
          if (res.data && res.data.role !== 'Business') {
            setDoctorId(res.data.account_id);
          }
        });
      }
    }
  }, [doctor_id]);

  return (
    <SimpleModal show={showFeedbackForm} containerclassName="feedback-form-modal">
      <SimpleModal.Header title={getModalHeaderTitle()} onClose={handleOnClose} />
      <SimpleModal.ContentBody>
        <>
          {isFeedbackFormSubmitSuccess ? (
            <p className="submitted-message">Your feedback has been successfully submitted.</p>
          ) : (
            <>
              <div>
                <Dropdown
                  id="feedbackCategory"
                  placeholder="I want to..."
                  options={feedbackCategoryOptions}
                  value={selectedFeedbackCategory}
                  onChange={handleFeedbackCategoryChange}
                  error={!!errors.selectedFeedbackCategory}
                  isDisabled={isFeedbackFormSubmitSuccess || isFeedbackFormSubmitting}
                  isSearchable={false}
                />
                {!!errors.selectedFeedbackCategory ? <InputErrorMessage message={errors.selectedFeedbackCategory} /> : null}
              </div>
              {selectedFeedbackCategory ? (
                <div className="content-comment-container">
                  <h4 className="comment-label">{getCommentLabel()}*</h4>
                  <div className="reason-input-data">
                    <textarea
                      className={`form-control item-reason-comment text-input ${!!errors.comment || !!errors.commentCharacterLimit ? 'has-error' : ''}`}
                      placeholder={'Let us know what you would like to share?'}
                      value={comment}
                      onChange={handleCommentChange}
                      disabled={isFeedbackFormSubmitSuccess || isFeedbackFormSubmitting}
                    />
                    <div className={`text-area__count text-area__count--bottom ${!!errors.commentCharacterLimit ? 'text-input__count--error' : ''}`}>
                      <span>
                        {comment.length}/{commentMaxLength}
                      </span>
                    </div>
                    {!!errors.comment ? <InputErrorMessage message={errors.comment} /> : null}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </>
      </SimpleModal.ContentBody>
      <SimpleModal.FooterWithButtons
        onClickConfirm={handleConfirm}
        onClickCancel={handleCancel}
        confirmButtonText={'Submit'}
        cancelButtonText={getCancelText()}
        confirmDisabled={submitIsDisabled()}
        cancelDisabled={cancelIsDisabled()}
        showConfirm={showConfirm()}
      />
      {feedbackFormSubmitError ? <AlertMessage theme={'error'} message={'An error has ocurred please try again'} dismiss /> : null}
    </SimpleModal>
  );
};

const mapStateToProps = (state) => {
  return {
    showFeedbackForm: getShowFeedbackForm(state),
    isFeedbackFormSubmitting: getSubmitFeedbackFormPending(state),
    isFeedbackFormSubmitSuccess: getSubmitFeedbackFormSuccess(state),
    feedbackFormSubmitError: getSubmitFeedbackFormError(state),
    doctorInfo: getDoctorInfo(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeFeedbackForm,
      submitFeedbackForm,
      restartFeedbackForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
