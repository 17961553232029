import Axios from 'axios';

export const FETCH_DOCTOR_CASE_FILTER_COUNT_START = 'FETCH_DOCTOR_CASE_FILTER_COUNT_START';
export const FETCH_DOCTOR_CASE_FILTER_COUNT_SUCCESS = 'FETCH_DOCTOR_CASE_FILTER_COUNT_SUCCESS';
export const FETCH_DOCTOR_CASE_FILTER_COUNT_ERROR = 'FETCH_DOCTOR_CASE_FILTER_COUNT_ERROR';

export const FETCH_LOGGED_DOCTOR_CASE_COUNT_START = 'FETCH_LOGGED_DOCTOR_CASE_COUNT_START';
export const FETCH_LOGGED_DOCTOR_CASE_COUNT_SUCCESS = 'FETCH_LOGGED_DOCTOR_CASE_COUNT_SUCCESS';
export const FETCH_LOGGED_DOCTOR_CASE_COUNT_ERROR = 'FETCH_LOGGED_DOCTOR_CASE_COUNT_ERROR';

export function fetchDoctorCaseFilterCount(config) {
  const { type, doctor_id } = config;
  return (dispatch) => {
    dispatch(fetchDoctorCaseFilterCountStart());
    Axios.get(`/apiv3/doctorcaselistcount/${doctor_id}?type=${type}`)
      .then((res) => {
        dispatch(fetchDoctorCaseFilterCountSuccess(type, res.data))
        for (let key in res.data) {
          dispatch(fetchDoctorCaseFilterCountSuccess(key, res.data[key]));
        }
      })
      .catch((err) => dispatch(fetchDoctorCaseFilterCountError(err)));
  };
}

export function fetchLoggedDoctorCaseCount(config) {
  const { doctorId } = config;
  return (dispatch) => {
    dispatch(fetchLoggedDoctorCaseCountStart());
    Axios.get(`/apiv3/doctorcaselistcount/${doctorId}`)
      .then((res) => {
        dispatch(fetchLoggedDoctorCaseCountSuccess(res.data));
      })
      .catch((err) => dispatch(fetchLoggedDoctorCaseCountError(err)));
  };
}

function fetchDoctorCaseFilterCountStart() {
  return {
    type: FETCH_DOCTOR_CASE_FILTER_COUNT_START,
  };
}

function fetchDoctorCaseFilterCountSuccess(case_type, case_filter_count) {
  return {
    type: FETCH_DOCTOR_CASE_FILTER_COUNT_SUCCESS,
    case_type: case_type,
    case_filter_count: case_filter_count,
  };
}

function fetchDoctorCaseFilterCountError(error) {
  return {
    type: FETCH_DOCTOR_CASE_FILTER_COUNT_ERROR,
    error: error,
  };
}

function fetchLoggedDoctorCaseCountStart() {
  return {
    type: FETCH_LOGGED_DOCTOR_CASE_COUNT_START,
  };
}

function fetchLoggedDoctorCaseCountSuccess(case_count) {
  return {
    type: FETCH_LOGGED_DOCTOR_CASE_COUNT_SUCCESS,
    case_count: case_count,
  };
}

function fetchLoggedDoctorCaseCountError(error) {
  return {
    type: FETCH_LOGGED_DOCTOR_CASE_COUNT_ERROR,
    error: error,
  };
}
