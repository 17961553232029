import { FETCH_SMILE_SIMULATION_PENDING, FETCH_SMILE_SIMULATION_SUCCESS, FETCH_SMILE_SIMULATION_ERROR } from '../../actions/smiles_simulation/smiles_simulation';

const initialState = {
  smiles: [],
  smiles_count: 0,
  pending: true,
  error: false,
  status_count: {
    action: 0,
    request_pending:0,
    smile_completed:0
  },
};

export function smilesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SMILE_SIMULATION_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_SMILE_SIMULATION_SUCCESS:
      return {
        ...state,
        pending: false,
        smiles: action.smiles,
        smiles_count: action.smiles_count,
        status_count: action.status_count,
      };

    case FETCH_SMILE_SIMULATION_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}



export const getSmilesSimulation = (state) =>state.smilesReducer.smiles;
export const getSmilesSimulationCount = (state) => state.smilesReducer.smiles_count;
export const getPending = (state) => state.smilesReducer.pending;
export const getError = (state) => state.smilesReducer.error;
export const getStatusCount = (state) => state.smilesReducer.status_count;
