import React, { Component } from 'react';
import './shipping_info.scss';
import { setTokenHeader, firstLetterCapital } from '../../common/functions';
import { getShippingAddress } from '../../common/helpers';
import { getShippingAddressName } from '../../common/helpers';

class ShippingInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
    };
  }

  componentDidMount() {
    setTokenHeader();

    // Default to the first active address
    if (this.props.address === '' && this.props.addresses.length > 0) {
      let active_address_id = '';

      for (let i = 0; i < this.props.addresses.length; i++) {
        if (this.props.addresses[i].is_active) {
          active_address_id = this.props.addresses[i].id;
          break;
        }
      }

      this.props.onInputChange({ target: { dataset: { id: active_address_id } } });
    }
  }

  UNSAFE_componentWillReceiveProps(next, prev) {
    if (this.state.address !== next.address) {
      this.setState({
        address: next.address,
      });
    }
  }

  render() {
    return (
      <div className="case-form-container">
        <h3>{this.props.location ? 'Location' : 'Shipping Info'}</h3>
        <p>{`Please contact support at (877) 258-8677 or email support@inbrace.com if your ${this.props.location ? '' : 'shipping'} address is not listed below`}.</p>
        <form>
          {this.props.addresses && this.props.addresses.length > 0 ? (
            <div className="address-container">
              <div className="emphasis">Select {this.props.location ? 'location' : 'shipping address'}:</div>
              <div className="address-list">
                {this.props.addresses.map((address) =>
                  address.is_active ? (
                    <label key={address.id}>
                      <input
                        type="radio"
                        name="address"
                        checked={address.id === this.state.address}
                        onChange={this.props.onInputChange}
                        defaultValue={address.id}
                        data-id={address.id}
                        disabled={this.props.disabledEdit}
                      />
                      <div className="address-text">
                        <div className="emphasis">{firstLetterCapital(getShippingAddressName(address))}</div>
                        <div>{getShippingAddress(address)}</div>
                      </div>
                    </label>
                  ) : null
                )}
              </div>
            </div>
          ) : (
            <div>No addresses found for this users account. Please contact your InBrace Rep.</div>
          )}
        </form>
      </div>
    );
  }
}

export default ShippingInfo;
