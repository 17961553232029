import React from 'react';
import Button from '../../setup_viewer/components/extras/button';

function UnsavedChangesModal(props) {
  const { confirm, cancel } = props;

  return (
    <div>
      <div className="modal-title">Unsaved Changes</div>
      <div className="modal-body-paragraph">Unsaved changes were detected. Are you sure you want to exit?</div>
      <div className="modal-button-group">
        <Button theme="success" onClick={confirm}>
          Yes
        </Button>
        <Button theme="secondary" onClick={cancel}>
          No
        </Button>
      </div>
    </div>
  );
}

export default UnsavedChangesModal;
