import Axios from 'axios';
import Error from '../404/not_found';
import Privacy from '../../components/privacy/external_privacy';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { handleHttpRequestError } from '../../common/error';
import {checkPDFSupport} from '../../common/browser';

class InternalCustomerAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: true,
      loading: true,
    };
  }

  componentDidMount() {
    const that = this;

    Axios({
      url: '/apiV2/customeragreement',
      method: 'GET',
      responseType: 'arraybuffer',
    })
      .then((response) => {
        that.setState({
          loading: false,
          error: false,
        });

        return response;
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        checkPDFSupport(url,'#customer')
      })
      .catch(function (err) {
        that.setState({
          loading: false,
        });

        handleHttpRequestError(err, that);
      });
  }

  render() {
    if (this.state.loading) {
      return <div />;
    } else if (this.state.error) {
      return <Error />;
    } else {
      return (
        <div className="ipp-light background">
          <Helmet>
            <title>Customer Agreement | InBrace</title>
            <meta name="description" content="Reset your InBrace Smile Design Studio™ TM account password by entering and confirming a new password." />
          </Helmet>
          <div className="content--fullscreen">
            <div id="customer"></div>
            <Privacy className="footer-center heading--light" />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(InternalCustomerAgreement);
