import React from 'react';

function WasmToggleBtn(props) {
  const { onClick, checked, disabled } = props;

  const onToggle = (e) => {
    onClick();
    e.target.blur();
  };

  return (
    <span className="wasm-toggle-btn">
      <input type="checkbox" checked={checked} onChange={onToggle} disabled={disabled} />
      <div />
    </span>
  );
}

export default WasmToggleBtn;
