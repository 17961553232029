import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

import { formatHTMLLinks } from '../../common/functions';
import NoteContainer from '../components/container/note_container';

function DisplayProspectNotes(props) {
  return (
    <NoteContainer>
      {props.note.subject && (
        <div className="note__subject">
          <span className="emphasis">Subject </span>
          {props.note.subject}
        </div>
      )}
      <div
        className="ql-editor"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(formatHTMLLinks(props.note.note_text), { ALLOWED_ATTR: ['target', 'href', 'rel', 'class'] }),
        }}
      />
    </NoteContainer>
  );
}

DisplayProspectNotes.propTypes = {
  note: PropTypes.object,
};

export default DisplayProspectNotes;
