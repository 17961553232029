import React from 'react';
import Button from '../../setup_viewer/components/extras/button';
import Modal from '../../setup_viewer/components/modal/modal';

function ResetModal(props) {
  const { smile_design_name, show, confirm, close } = props;
  return (
    <Modal show_close_button show={show} onCloseModal={close}>
      <div>
        <div className="modal-title">Reset Design</div>
        <div className="modal-body-paragraph">
          Resetting your design to the original {smile_design_name} will delete any saved drafts you have made. Kindly keep in mind that this action cannot be
          undone. Are you sure you want to proceed?
        </div>
        <div className="modal-button-group">
          <Button theme="success" onClick={confirm}>
            Reset
          </Button>
          <Button theme="secondary" onClick={close}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ResetModal;
