import React from 'react';
import SelectionBarTop from '../setup_viewer/components/panels/viewer/selection_bar/selection_bar_top';
import Pane from '../setup_viewer/components/panels/viewer/pane';
import CompareBtn from './compare_btn';
import SmileReminder from './reminder';

/**
 * Displays the viewer panels
 * @component
 */
export default function SmilePanelViewer(props) {
  return (
    <div className={props.collapse ? 'panel-viewer--dark panel-viewer--dark--collapse' : 'panel-viewer--dark'} onContextMenu={(e) => e.preventDefault()}>
      <SelectionBarTop
        split={props.split}
        scroll_options={props.scroll_options}
        scroll_items={props.scroll_items}
        scroll_area={props.scroll_area}
        nextScrollArea={props.nextScrollArea}
        prevScrollArea={props.prevScrollArea}
        setScrollArea={props.setScrollArea}
        onScrollOptionClick={props.onScrollOptionClick}
        isSmileSimulationSetup={props.isSmileSimulationSetup}
      />
      <Pane {...props} />
      <CompareBtn split={props.split} toggleSplit={props.toggleSplit} />
      <SmileReminder />
    </div>
  );
}
