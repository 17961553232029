import {
  SET_EDIT_MODE_SMILE_DESIGN,
  FETCH_SMILE_DESIGN_QUESTIONS_SUCCESS,
  SET_SMILE_DESIGN_QUESTIONS,
  OPEN_CHANGE_TREATMENT_MODAL,
  CLOSE_CHANGE_TREATMENT_MODAL,
  OPEN_REQUESTED_TREATMENT_MODAL,
  CLOSE_REQUESTED_TREATMENT_MODAL,
  SET_SMILE_DESIGN_INCOMPLETE_ERROR,
  UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_ERROR,
  UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_PENDING,
  UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_SUCCESS,
  SET_REQUESTED_TREATMENT,
  SET_TREATMENT_PLAN_REVISION,
  OPEN_SMILE_DESIGN_TX_GUIDE_PDF_MODAL,
  CLOSE_SMILE_DESIGN_TX_GUIDE_PDF_MODAL,
  SET_SMILE_DESIGN_TX_GUIDE_FILE_PATH,
  SET_SMILE_DESIGN_QUESTIONS_OLD,
  SET_SMILE_DESIGN_SESSION_EXPIRED,
  SAVE_SMILE_DESIGN_SUCCESS,
  RESET_SAVE_SMILE_DESIGN_SUCCESS
} from '../../../actions/bpp/production_tx_guide/smile_design';

const initialState = {
  case_id: '',
  smile_design_questions: {
    change_treatment_smile_design: false,
    change_treatment_smile_design_selections: {},
    inter_arch_collision: false,
    inter_arch_collision_region: '',
    inbrace_ifs_required: false,
    inbrace_ifs_applied: '',
    smile_design_notes: '',
    requested_treatment_selections: {},
  },
  requested_treatment: {},
  treatment_plan_revision: '',
  is_edit_mode_smile_design: false,
  change_treatment_modal: false,
  requested_treatment_modal: false,
  incomplete_error: {
    change_treatment: false,
    requested_treatment: false,
    inter_arch_collision_region: false,
    inbrace_ifs_applied: false,
    identical_treatment: false,
  },
  error: false,
  pending: false,
  smile_design_tx_guide_pdf_modal: false,
  smile_design_tx_guide_file_path: '',
  session_expire: false,
  smile_design_questions_old: {},
};

/**
 * This reducer is used for the Smile Design treatment guide form
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function smileDesignProductionTxGuideReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EDIT_MODE_SMILE_DESIGN:
      return {
        ...state,
        is_edit_mode_smile_design: action.is_edit_mode_smile_design,
      };

    case FETCH_SMILE_DESIGN_QUESTIONS_SUCCESS:
      return {
        ...state,
        smile_design_questions: action.smile_design_questions,
        case_id: action.case_id,
      };

    case SET_SMILE_DESIGN_QUESTIONS:
      return {
        ...state,
        smile_design_questions: action.smile_design_questions,
      };

    case OPEN_CHANGE_TREATMENT_MODAL:
      return {
        ...state,
        change_treatment_modal: true,
      };

    case CLOSE_CHANGE_TREATMENT_MODAL:
      return {
        ...state,
        change_treatment_modal: false,
      };

    case OPEN_REQUESTED_TREATMENT_MODAL:
      return {
        ...state,
        requested_treatment_modal: true,
      };

    case CLOSE_REQUESTED_TREATMENT_MODAL:
      return {
        ...state,
        requested_treatment_modal: false,
      };

    case SET_SMILE_DESIGN_INCOMPLETE_ERROR:
      return {
        ...state,
        incomplete_error: { ...state.incomplete_error, [action.error_type]: action.is_error },
      };

    case UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_ERROR:
      return {
        ...state,
        error: true,
        pending: false,
        session_expire: action.session_expire,
      };

    case UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case UPDATE_SMILE_DESIGN_PRODUCTION_TX_GUIDE_SUCCESS:
      return {
        ...state,
        pending: false,
        smile_design_questions: action.smile_design_questions,
      };

    case SET_REQUESTED_TREATMENT:
      return {
        ...state,
        requested_treatment: action.requested_treatment,
      };

    case SET_TREATMENT_PLAN_REVISION:
      return {
        ...state,
        treatment_plan_revision: action.treatment_plan_revision,
      };

    case OPEN_SMILE_DESIGN_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        smile_design_tx_guide_pdf_modal: true,
        smile_design_tx_guide_file_path: action.file_path,
      };

    case CLOSE_SMILE_DESIGN_TX_GUIDE_PDF_MODAL:
      return {
        ...state,
        smile_design_tx_guide_pdf_modal: false,
      };

    case SET_SMILE_DESIGN_TX_GUIDE_FILE_PATH:
      return {
        ...state,
        smile_design_tx_guide_file_path: action.file_path,
      };

    case SET_SMILE_DESIGN_QUESTIONS_OLD:
      return {
        ...state,
        smile_design_questions_old: action.smile_design_questions_old,
      };

    case SET_SMILE_DESIGN_SESSION_EXPIRED:
      return {
        ...state,
        session_expire: action.session_expire,
      };
    case SAVE_SMILE_DESIGN_SUCCESS:
      return {
        ...state,
        isSmileDesignSaveSuccessful: true,
      };
    case RESET_SAVE_SMILE_DESIGN_SUCCESS:
      return {
        ...state,
        isSmileDesignSaveSuccessful: false,
      }
    default:
      return state;
  }
}

export const getSmileDesignQuestions = (state) => state.smileDesignProductionTxGuideReducer.smile_design_questions;
export const getIsEditModeSmileDesign = (state) => state.smileDesignProductionTxGuideReducer.is_edit_mode_smile_design;
export const getChangeTreatmentModal = (state) => state.smileDesignProductionTxGuideReducer.change_treatment_modal;
export const getRequestedTreatmentModal = (state) => state.smileDesignProductionTxGuideReducer.requested_treatment_modal;
export const getSmileDesignIncompleteError = (state) => state.smileDesignProductionTxGuideReducer.incomplete_error;
export const getSmileDesignTxGuidePdfModal = (state) => state.smileDesignProductionTxGuideReducer.smile_design_tx_guide_pdf_modal;
export const getSmileDesignTxGuideFilePath = (state) => state.smileDesignProductionTxGuideReducer.smile_design_tx_guide_file_path;
export const getSessionExpire = (state) => state.smileDesignProductionTxGuideReducer.session_expire;
export const getSmileDesignQuestionsOld = (state) => state.smileDesignProductionTxGuideReducer.smile_design_questions_old;

export const getSmileDesignSelectionsSaveSuccess = (state) => state.smileDesignProductionTxGuideReducer.isSmileDesignSaveSuccessful;