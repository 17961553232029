import React, { Component } from 'react';

// CSS
import './not_available.scss';

class NotAvailable extends Component {
  render() {
    return (
      <div className="not-available">
        <div className="not-available-container">
          <img src={process.env.PUBLIC_URL + '/static/img/no_records.png'} className="not-available-image" alt="no records found" />
          <div className="not-available-text">{this.props.message}</div>
        </div>
      </div>
    );
  }
}

export default NotAvailable;
