import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { getRootPath } from '../../common/functions';
import { withRouter, Link } from 'react-router-dom';

import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
/**
 * Shows no account page when no accounts are found.
 * *
 * @component
 * @alias NoAccount
 * @category IPP
 */
class NoAccount extends Component {
  constructor(props) {
    super(props);

    this.getCreateAccountRoute.bind(this);
  }

  getCreateAccountRoute = (event) => {
    let route = getRootPath(this.props.history.location.pathname);
    return route + '/accounts/add';
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Accounts | InBrace Smile Design Studio™</title>
        </Helmet>
        <CardContainer type="message">
          <h3>Welcome to the Accounts Page</h3>
          <p>There were no accounts found</p>
          <div className="block--right">
            <Link to={{ pathname: this.getCreateAccountRoute() }}>
              <Button>Create New Account</Button>
            </Link>
          </div>
        </CardContainer>
      </>
    );
  }
}

export default withRouter(NoAccount);
