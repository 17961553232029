import Axios from 'axios';
import { setSegmentationProductionTxGuide, onEditSegmentationTxGuideCancel } from './segmentation';
import { setSmileDesignProductionTxGuide, onEditSmileDesignTxGuideCancel } from './smile_design';
import { setStage1ProductionTxGuide, onEditStage1TxGuideCancel } from './stage_1';

import { getLastStatusBySelectedCase, isCaseBlocked } from '../../../../common/helpers';

// Action Types
export const SET_PRODUCTION_TX_GUIDE = 'SET_PRODUCTION_TX_GUIDE';
export const SET_SELECTED_PRODUCTION_TX_GUIDE_TAB = 'SET_SELECTED_PRODUCTION_TX_GUIDE_TAB';
export const SET_PRODUCTION_TX_GUIDE_TAB_CONFIG = 'SET_PRODUCTION_TX_GUIDE_TAB_CONFIG';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Retrieves production tx guide for a case
 * @function
 * @param {String} case_id - Case id
 * @return {Object} Action type
 */
export function fetchProductionTxGuide(case_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/productiontxguide/${case_id}`)
      .then((res) => {
        dispatch({
          type: SET_PRODUCTION_TX_GUIDE,
          production_tx_guide: res.data,
          is_after_3420_launch: res.data.is_after_3420_launch,
          gen_2: res.data.gen_2,
        });
        dispatch(setSegmentationProductionTxGuide(case_id, res.data));
        dispatch(setSmileDesignProductionTxGuide(case_id, res.data));
        dispatch(setStage1ProductionTxGuide(case_id, res.data));
      })
      .catch((error) => {});
  };
}

/**
 * Handles event when user switches between treatment guide tabs
 * @function
 * @param {String} tab - Tab name
 * @return {Object} Action type
 */
export function onProductionTxGuideTabSelect(tab) {
  return (dispatch, getState) => {
    const { selected_production_tx_guide_tab } = getState().productionTxGuideReducer;
    const { is_edit_mode_segmentation } = getState().segmentationProductionTxGuideReducer;
    const { is_edit_mode_smile_design } = getState().smileDesignProductionTxGuideReducer;
    const { is_edit_mode_stage_1 } = getState().stage1ProductionTxGuideReducer;

    if (selected_production_tx_guide_tab === 'segmentation' && is_edit_mode_segmentation) {
      dispatch(onEditSegmentationTxGuideCancel());
    }
    if (selected_production_tx_guide_tab === 'smile_design' && is_edit_mode_smile_design) {
      dispatch(onEditSmileDesignTxGuideCancel());
    }
    if (selected_production_tx_guide_tab === 'stage_1' && is_edit_mode_stage_1) {
      dispatch(onEditStage1TxGuideCancel());
    }
    dispatch({
      type: SET_SELECTED_PRODUCTION_TX_GUIDE_TAB,
      selected_production_tx_guide_tab: tab,
    });
  };
}

/**
 * Determines default production tx guide tab selection and tabs to display based on case status
 * @function
 * @param {String} case_status - Case status
 * @param {String} cad_status - CAD status
 * @return {Object} Action type
 */
export function setProductionTxGuideTabConfig(case_status, cad_status, cases, skip_production_tx_guide = null) {
  if (skip_production_tx_guide === null) {
    skip_production_tx_guide = cases.skip_production_tx_guide;
  }
  return {
    type: SET_PRODUCTION_TX_GUIDE_TAB_CONFIG,
    allowed_production_tx_guide_tabs: skip_production_tx_guide ? [] : getAllowedTabs(case_status, cad_status, cases),
    selected_production_tx_guide_tab: skip_production_tx_guide ? 'treatment_plan' : getDefaultProductionTxGuideTab(case_status, cad_status),
  };
}

/**
 * Gets default production tx guide tab selection
 * @function
 * @param {String} case_status - Case status
 * @param {String} cad_status - CAD status
 * @return {String} Default tab name
 */
function getDefaultProductionTxGuideTab(case_status, cad_status) {
  let defaults = {
    segmentation: ['STATUS_INBRACE_CHECK', 'STATUS_INBRACE_SETUP', 'Revise Setup'],
    smile_design: [
      'Setup Review',
      'Setup Ready for Upload',
      'Setup Conversion Process',
      'Setup Conversion Failed',
      'Setup Ready for Release',
      'STATUS_DOCTOR_APPROVAL',
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
    ],
    stage_1: ['CAD Process', 'Quality Control Review'],
    production_tx_guide: ['STATUS_MANUFACTURE', 'STATUS_SHIP'],
  };

  if (cad_status === 'Stage I') {
    defaults.smile_design.push('CAD Process');
    defaults.stage_1 = defaults.stage_1.filter((status) => status !== 'CAD Process');
  }

  for (const tab in defaults) {
    if (defaults[tab].indexOf(case_status) !== -1) {
      return tab;
    }
  }
  return 'treatment_plan';
}

/**
 * Get production tx guide tabs to be displayed based on case status
 * @function
 * @param {String} case_status - Case status
 * @param {String} cad_status - CAD status
 * @return {Array} List of tabs to display
 */
function getAllowedTabs(case_status, cad_status, cases) {
  const last_status = getLastStatusBySelectedCase(cases);
  let allowed_tabs = [];
  const allowed_tabs_config = {
    segmentation: [
      'STATUS_INBRACE_CHECK',
      'STATUS_INBRACE_SETUP',
      'Setup Review',
      'Setup Ready for Upload',
      'Setup Conversion Process',
      'Setup Conversion Failed',
      'Setup Ready for Release',
      'STATUS_DOCTOR_APPROVAL',
      'CAD Process',
      'Quality Control Review',
      'Revise Setup',
      'Doctor Approve or Revise Setup',
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
    ],
    smile_design: [
      'Setup Review',
      'Setup Ready for Upload',
      'Setup Conversion Process',
      'Setup Conversion Failed',
      'Setup Ready for Release',
      'STATUS_DOCTOR_APPROVAL',
      'CAD Process',
      'Quality Control Review',
      'Doctor Approve or Revise Setup',
      'Provider Edit Review',
      'Final Design Upload',
      'Final Design in Conversion',
      'Final Design Conversion Failed',
      'Final Design Ready for Release',
    ],
    stage_1: cad_status === 'Stage I' ? ['Quality Control Review'] : ['CAD Process', 'Quality Control Review'],
    production_tx_guide: ['STATUS_MANUFACTURE', 'STATUS_SHIP', 'INBRACE Manufacturing Case'],
  };

  const case_on_hold = case_status !== 'STATUS_CANCEL' && isCaseBlocked(case_status);

  for (const tab in allowed_tabs_config) {
    if (
      allowed_tabs_config[tab].indexOf(case_status) !== -1 ||
      (case_status === 'STATUS_CANCEL' &&
        allowed_tabs_config.production_tx_guide.indexOf(last_status) !== -1 &&
        allowed_tabs_config[tab].indexOf(last_status) !== -1) ||
      (case_on_hold && allowed_tabs_config[tab].indexOf(last_status) !== -1)
    ) {
      allowed_tabs.push(tab);
    }
  }
  return allowed_tabs;
}
