/**
 * File:quantity.js - Component to input item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';

// nomenclature
import { getLookup } from '../../common/nomenclature';
import Button from '../components/buttons/button';
import AlertContainer from '../components/container/alert_container';
import CardContainer from '../components/container/card_container';

const categories = [
  {
    category: 'smartwire',
    label: 'Smartwires',
  },
  {
    category: 'idb',
    label: 'IDB Trays',
  },
  {
    category: 'bracket',
    label: 'Brackets',
  },
];

const categories_gen2 = [
  {
    category: 'smartwire',
    label: 'Smartwires',
  },
  {
    category: 'idb',
    label: 'IDB Trays',
  },
  {
    category: 'bracket',
    label: 'Brackets',
  },
];

class ItemQuantity extends Component {
  /**
   * Displays IR submission category
   *
   * @function
   * @param {object} category - IR submission category
   * @returns {JSX} - JSX for IR submission category
   */
  displayItemCategory(category) {
    const case_id = this.props.case_id;
    const isDE = case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);
    const label = `${category.category === 'idb' ? (isDE ? 'Digital Enhancement ' : this.props.gen_2 ? 'Initial ' : '  ') : ''}${category.label}`;
    return this.props.gen_2 && category.category === 'bracket' ? (
      this.displayStorefront(category)
    ) : (
      <CardContainer type="top-border" className="item-request-container" key={category.category}>
        <table className="item-request-table">
          <thead>
            <tr>
              <th>{label}</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {this.props.item_parts_quantity
              .filter((ip) => {
                return !this.props.gen_2 || (this.props.case_id.includes('-DE') ? ip.is_de : !ip.is_de);
              })
              .filter((item) => !item.description.includes('Single-Tooth'))
              .map((item_part) => item_part.current_ind && item_part.part_category === category.category && this.displayItemRow(item_part))}
          </tbody>
        </table>
      </CardContainer>
    );
  }

  displayItemRow(item_part) {
    const ITEM_PART_LOOKUP = getLookup(this.props.case_id); // nomenclature lookup
    return (
      <tr key={item_part.id}>
        <td>{ITEM_PART_LOOKUP[item_part.part_id]}</td>
        <td>
          {this.displayMinus(item_part)}
          <span className="item-request-quantity">{item_part.quantity}</span>
          {this.displayPlus(item_part)}
          {item_part.quantity >= item_part.max_qty && <AlertContainer theme="secondary">Max quantity reached</AlertContainer>}
        </td>
      </tr>
    );
  }

  displayMinus(item_part) {
    let className = 'fa fa-minus-square item-request-control';
    if (item_part.quantity === 0) className += '--disabled';
    return <i className={className} data-id={item_part.id} onClick={this.props.decreaseQuantity} />;
  }

  displayPlus(item_part) {
    let className = 'fa fa-plus-square item-request-control';
    if (item_part.quantity >= item_part.max_qty) className += '--disabled';
    return <i className={className} data-id={item_part.id} onClick={this.props.increaseQuantity} />;
  }

  /**
   * Displays InBrace Shop section
   *
   * @function
   * @param {object} category - IR submission category
   * @returns {JSX} - JSX for InBrace Shop section
   */
  displayStorefront = (category) => {
    return (
      <CardContainer type="top-border" className="item-request-container" key={category.category}>
        <table className="item-request-table gen2-brackets">
          <thead>
            <tr>
              <th>{category.label}</th>
            </tr>
          </thead>
          <tbody>
            <AlertContainer>
              To order replacement brackets please visit the {/* eslint-disable-next-line */}
              <a className="midnight" onClick={this.props.openStorefrontLink}>
                InBrace Shop
              </a>
            </AlertContainer>
          </tbody>
        </table>
      </CardContainer>
    );
  };

  render() {
    const ir_categories = this.props.gen_2 ? categories_gen2 : categories;
    return this.props.item_parts_quantity && this.props.item_parts_quantity.length > 0 ? (
      <>
        <h3>Request Items</h3>
        <div className="title emphasis">Please select the items you would like to request:</div>
        {ir_categories.map((category) => this.displayItemCategory(category))}
      </>
    ) : (
      <>
        <div>
          Sorry, an unexpected error occured. <br /> Please contact support at (877) 258-8677 or <br />
          support@inbrace.com
        </div>
        <Button onClick={this.props.goBack}>Go Back</Button>
      </>
    );
  }
}
export default ItemQuantity;
