const PUBLIC_URL = process.env.PUBLIC_URL;
const scripts = [
  PUBLIC_URL + '/static/TPS/setup_script.js',
  PUBLIC_URL + '/static/TPS/popups.js',
  PUBLIC_URL + '/static/TPS/io_files.js',
  PUBLIC_URL + '/static/TPS/ios_detect.js',
  PUBLIC_URL + '/static/TPS/open_link.js',
  PUBLIC_URL + '/static/TPS/reset_events.js',
  PUBLIC_URL + '/static/TPS/resize.js',
  PUBLIC_URL + '/static/TPS/safe_decoder.js',
  PUBLIC_URL + '/static/TPS/color_theme.js',
  PUBLIC_URL + '/static/TPS/version.js',
  PUBLIC_URL + '/static/TPS/segmentor_api.js',
  PUBLIC_URL + '/static/TPS/web_request.js',
  PUBLIC_URL + '/static/TPS/TPSetupWeb.js',
];

function addScript(url, async = false) {
  const script = document.createElement('script');
  script.src = url;
  script.async = async;
  document.body.appendChild(script);
  return script;
}

function loadTPS() {
  const scriptElements = [];
  scripts.forEach((url) => scriptElements.push(addScript(url)));
  return scriptElements;
}

function removeScripts(elements) {
  elements.forEach((e) => document.body.removeChild(e));
}

export { loadTPS, removeScripts };
