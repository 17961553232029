import React from 'react';
import './account_activation.scss';

/**
 * Used to select addresses to reactivate when enabling an account. *
 *
 * @component
 * @alias AccountActivation
 * @category BPP
 */
function AccountActivation(props) {
  return (
    <div className="account-activation form-group">
      <div className="p-slim grey-text">To enable this account, please select at least one practice to reactivate:</div>
      <br />
      <div>
        {props.shipping_addresses.map((address, index) => {
          return (
            <div className="address-option" key={index}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={props.selected_address_ids.indexOf(address.id) !== -1}
                onChange={(event) => props.onAccountActivationAddressChange(address.id, event)}
              />
              <label>
                {address.address_name ? (
                  <React.Fragment>
                    <span className="bold-text">{address.address_name}</span>
                    <br />
                  </React.Fragment>
                ) : null}
                <span>
                  {address.address_line_1 && address.address_line_2 ? address.address_line_1 + ' ' + address.address_line_2 : address.address_line_1}
                  {', ' + address.city + ', ' + address.state + ' ' + address.zip + ', ' + address.country}
                </span>
              </label>
              <br />
              <br />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AccountActivation;
