import '../account_settings/account_settings.scss';

import React, { Component } from 'react';
import Content from './content';
import { Helmet } from 'react-helmet';

class EditAccount extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Account Details | InBrace Smile Design Studio™</title>
        </Helmet>
        <Content />
      </div>
    );
  }
}

export default EditAccount;
