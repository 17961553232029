import './scans_upload_selector.scss';

import React from 'react';

const UploadMethodSelector = ({ disabledEdit, onChange, uploadMethod, title = 'Select upload method' }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="upload-method-header">
      <span className="title">{title}</span>
      <div className="upload-method-field">
        <div className="ib-radio">
          <label className={'form-radio-label'}>
            <input
              className="form-radio-input"
              type="radio"
              name={'uploadMethod'}
              value={'itero'}
              checked={uploadMethod === 'itero'}
              onChange={handleChange}
              disabled={disabledEdit}
            />{' '}
            {'iTero Scanner'}
          </label>
        </div>
        <div className="ib-radio">
          <label className={'form-radio-label'}>
            <input
              className="form-radio-input"
              type="radio"
              name={'uploadMethod'}
              value={'manual'}
              checked={uploadMethod === 'manual'}
              onChange={handleChange}
              disabled={disabledEdit}
            />{' '}
            {'Manual'}
          </label>
        </div>
      </div>
    </div>
  );
};

export default UploadMethodSelector;
