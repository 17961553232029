export const CLOSE_HELP_SIDEBAR = 'CLOSE_HELP_SIDEBAR';
export const OPEN_HELP_SIDEBAR = 'OPEN_HELP_SIDEBAR';
export const TOGGLE_HELP_SIDEBAR = 'TOGGLE_HELP_SIDEBAR';

export function openHelpSidebar() {
  return {
    type: OPEN_HELP_SIDEBAR,
  };
}

export function closeHelpSidebar() {
  return {
    type: CLOSE_HELP_SIDEBAR,
  };
}

export function toggleHelpSidebar() {
  return {
    type: TOGGLE_HELP_SIDEBAR,
  };
}
