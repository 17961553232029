export const SegmentationQuestions = {
  Extraction: {
    Maintain: {
      is_selected: false,
      modal_message: 'Specify any extractions to be maintained.',
      selected_teeth: [],
      translated_name: 'extraction_maintain',
    },
    'Open space for future implant': {
      is_selected: false,
      modal_message: 'Specify any extractions needed to open space for a future implant.',
      selected_teeth: [],
      translated_name: 'extraction_open_space',
    },
    'Close space': {
      is_selected: false,
      modal_message: 'Specify any extractions needed to close space.',
      selected_teeth: [],
      translated_name: 'extraction_close_space',
    },
  },
  'Non-erupted/Partially-erupted': {
    Bond: {
      is_selected: false,
      modal_message: 'Specify any non-erupted/partially-erupted teeth to be bonded.',
      selected_teeth: [],
      translated_name: 'erupted_bond',
    },
    'Do not treat': {
      is_selected: false,
      modal_message: 'Specify any non-erupted/partially-erupted teeth that won’t be treated.',
      selected_teeth: [],
      translated_name: 'erupted_do_not_treat',
    },
  },
  Implant: {
    Bond: {
      is_selected: false,
      modal_message: 'Specify any implants to be bonded.',
      selected_teeth: [],
      translated_name: 'implant_bond',
    },
    'Do not treat': {
      is_selected: false,
      modal_message: 'Specify any implants that won’t be treated.',
      selected_teeth: [],
      translated_name: 'implant_do_not_treat',
    },
  },
  'Baby Tooth': {
    Bond: {
      is_selected: false,
      modal_message: 'Specify any baby teeth to be bonded.',
      selected_teeth: [],
      translated_name: 'baby_tooth_bond',
    },
    'Do not treat': {
      is_selected: false,
      modal_message: 'Specify any baby teeth that won’t be treated.',
      selected_teeth: [],
      translated_name: 'baby_tooth_do_not_treat',
    },
  },
};
