import React from 'react';
/**
 * Link in new tab menu item for context menu.
 * @property url
 * @property linkText - Defaults to 'Open in New Tab' if nothing is passed.
 */

const NewTabLink = (props) => {
  return props.onCaseTrClick ? (
    /* eslint-disable-next-line */
    <a
      className="context-menu-item new-tab-link"
      onClick={props.onCaseTrClick}
      {...props.metaData}
      data-status={props.status}
      target="_blank"
      rel="nolink noreferrer"
    >
      {props.linkText || 'Open in New Tab'}
    </a>
  ) : (
    /* eslint-disable-next-line */
    <a className="context-menu-item new-tab-link" href={props.url} target="_blank" rel="nolink noreferrer">
      {props.linkText || 'Open in New Tab'}
    </a>
  );
};

export default NewTabLink;
