/**
 * File: checklist.js - A component that display the checklist for a given case
 * Copyright: (c) Copyright June 2021 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './checklist.scss';
// External Libs
import _ from 'lodash';
import React, { Component } from 'react';

/**
 * Displays the Checklist within a modal
 * @component
 * @alias Checklist
 * @category BPP
 */
class Checklist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checklist_tab: '',
    };
  }

  createChecklistTabs = () => {
    const { content } = { ...this.props };
    const tabs_raw = _.uniqBy(content.checklist, (question) => {
      return question.group_name;
    });
    return tabs_raw;
  };

  isSelectedTab = (tab_name, index) => {
    const { checklist_tab } = { ...this.state };
    return (checklist_tab === '' && index === 0) || checklist_tab === tab_name;
  };

  displayChecklistTable = (tab_name, table_index) => {
    const { content } = { ...this.props };
    const checklist = content.checklist.filter((list) => {
      return list.group_name === tab_name;
    });
    const headings = [
      {
        label: 'Inspection Instructions',
        width: '70%',
      },
      {
        label: 'Procedure/Spec Reference',
        width: 'auto',
      },
      {
        label: 'Pass',
        width: '5%',
      },
      {
        label: 'N/A',
        width: '5%',
      },
    ];

    return (
      <table className="checklist-table" key={table_index}>
        <thead>
          <tr>
            {headings.map((heading, index) => {
              return (
                <th style={{ width: heading.width }} key={index}>
                  {heading.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {checklist.map((list) => {
            return (
              <tr key={list.group_name + list.id}>
                {list.checklist_question__question !== 'Comments' ? (
                  <>
                    <td>
                      <div>{list.checklist_question__question}</div>
                      {list.checklist_question__note ? (
                        <div>
                          <span className="bold-text font-size-small">Note: </span>
                          <span className="font-size-small">{list.checklist_question__note}</span>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <div>{list.checklist_question__reference}</div>
                    </td>
                    <td className="center-text">
                      <label>
                        <input
                          type="checkbox"
                          data-id={list.id}
                          data-group_name={list.group_name}
                          data-checklist_question__id={list.checklist_question__id}
                          value="Pass"
                          checked={list.checklist_response_temp === 'Pass'}
                          onChange={this.props.onClickCheckbox}
                        />
                      </label>
                    </td>
                    <td className="center-text">
                      <label>
                        <input
                          type="checkbox"
                          data-id={list.id}
                          data-group_name={list.group_name}
                          data-checklist_question__id={list.checklist_question__id}
                          value="N/A"
                          checked={list.checklist_response_temp === 'N/A'}
                          onChange={this.props.onClickCheckbox}
                        />
                      </label>
                    </td>
                  </>
                ) : (
                  <>
                    <td colSpan="4">
                      <div className="bold-text">Comments:</div>
                      <textarea
                        className="form-control bpp-item-update-comment"
                        rows="2"
                        maxLength="1000"
                        data-id={list.id}
                        data-group_name={list.group_name}
                        data-checklist_question__id={list.checklist_question__id}
                        value={list.checklist_response_temp}
                        onChange={this.props.onChangeComment}
                      />
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  displayTabs = () => {
    const tabs_raw = this.createChecklistTabs();

    return (
      <div className="checklist">
        <div className="checklist-tabs">
          {tabs_raw.map((tab, index) => {
            return (
              <span
                className={`business-dark-heading treatment-tab ${this.isSelectedTab(tab.group_name, index) ? 'treatment-tab__active' : ''}`}
                key={tab.group_name}
                onClick={() => {
                  this.setState({
                    checklist_tab: tab.group_name,
                  });
                }}
              >
                {tab.group_name === 'Tx Instructions' && this.props.case_id.includes('-DE') ? 'DE Instructions' : tab.group_name}
              </span>
            );
          })}
        </div>
        {tabs_raw.map((tab, index) => {
          return this.isSelectedTab(tab.group_name, index) ? this.displayChecklistTable(tab.group_name, index) : null;
        })}
      </div>
    );
  };

  render() {
    return <div>{this.displayTabs()}</div>;
  }
}

export default Checklist;
