import React from 'react';

import SimpleModal from '../components/modals/simple-modal';

export const SubmitErrorModal = ({
  show,
  onClose,
  onClickConfirm,
}) => {
  return (
    <SimpleModal show={show} containerclassName="smile-design-modal" theme="error">
      <SimpleModal.Header title={'Oops! Something went wrong'} onClose={onClose} />
      <SimpleModal.ContentBody>
        <p>Please try again. If the error persists contact support.</p>
      </SimpleModal.ContentBody>
      <SimpleModal.FooterWithButtons
        theme="error"
        onClickConfirm={onClickConfirm}
        showCancel={false}
        confirmButtonText={'Ok'}
      />
    </SimpleModal>
  );
};
