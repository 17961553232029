import * as THREE from 'three';
import { StepMeshGroup } from './StepMeshGroup';
import { matTeeth, matOver, getMat } from '../common/mesh';
import { clamp } from '../../components/common/functions';
import { iprStepNameTranslate, setMaterialOpacity } from '../common/functions';

const teethWhiteColor = new THREE.Color('#fffcfb');
const teethMaloColor = new THREE.Color('#a293ff');
const teethSetupColor = new THREE.Color('#78adeb');

/**
 * Class that manages the different steps in a single setup
 */
class SetupManager {
  constructor() {
    this.meshGroup = new THREE.Group();
    this.setupNum = null;
    this.steps = {};
    this.currentStep = 'last';

    this.matTeethUpper = matTeeth.clone();
    this.matTeethLower = matTeeth.clone();

    this.matTeeth = matTeeth.clone();
    this.matOver = matOver.clone();
  }

  /**
   * Changes to a different step
   * @function
   * @param {String} newStep - The new step
   */
  changeStep(newStep) {
    this.currentStep = newStep;
    for (const step of Object.keys(this.steps)) {
      this.steps[step].show(step === this.currentStep);
    }
  }

  /**
   * Adds this setup's meshes to the scene
   * @function
   * @param {THREE.Scene} scene - The scene to contain the setup's meshes
   */
  addToScene(scene) {
    scene.add(this.meshGroup);
  }

  /**
   * Removes this setup's meshes from the scene
   * @function
   * @param {THREE.Scene} scene - The scene that contains the setup's meshes
   */
  removeFromScene(scene) {
    scene.remove(this.meshGroup);
  }

  /**
   * Clears this setup's meshes
   * @function
   */
  clear() {
    this.meshGroup.remove(...this.meshGroup.children);
  }

  /**
   * Cleans up this setup's meshes from memory
   * @function
   */
  dispose() {
    for (const step of Object.values(this.steps)) {
      step.dispose();
    }
    this.matTeethUpper.dispose();
    this.matTeethLower.dispose();
    this.matTeeth.dispose();
    this.matOver.dispose();
  }

  /**
   * Loads in the setup's meshes
   * @function
   * @param {String} setupNum - The setup's number
   * @param {Object} setupMeshes - The setup's meshes
   * @param {Object} iprJSON - The ipr json
   */
  loadStepMeshes(setupNum, setupMeshes, iprJSON) {
    if (this.setupNum === setupNum) return;

    const version = iprJSON.version;
    this.setupNum = setupNum;
    this.clear();
    this.setMat(version);
    for (const step of Object.keys(setupMeshes)) {
      this.steps[step] = new StepMeshGroup();
      this.steps[step].setMeshes(setupMeshes[step], version);
      this.steps[step].show(step === this.currentStep);
      this.steps[step].addToScene(this.meshGroup);
    }
    this.generateIPRMeshes(iprJSON);
  }

  /**
   * Generates ipr json for each step
   * @function
   * @param {Object} iprJSON - The ipr json
   */
  generateIPRMeshes(iprJSON) {
    for (const iprStep of Object.keys(iprJSON)) {
      const step = iprStepNameTranslate(iprStep);
      if (step in this.steps) {
        this.steps[step].generateIPRMeshes(iprJSON[iprStep]);
      }
    }
  }

  /**
   * Set the materials base on version
   * @function
   * @param {String} version - The ipr json's version
   */
  setMat(version) {
    const mat = getMat(version);
    this.matTeeth = mat.matTeeth.clone();
    this.matTeethUpper = mat.matTeeth.clone();
    this.matTeethLower = mat.matTeeth.clone();
  }

  /**
   * Sets the setup to the view options
   * @function
   * @param {Object} viewOptions - The view options
   */
  setViewOptions(viewOptions) {
    const { step } = viewOptions;
    this.steps[step].setStepViewOptions(viewOptions);
    this.changeStep(step);
    this.setTeethViewOptions(viewOptions);
  }

  /**
   * Sets the teeth's opacity and superimpose
   * @function
   * @param {Object} viewOptions - The view options
   */
  setTeethViewOptions(viewOptions) {
    if (viewOptions.superimpose) {
      this.setToSuperimpose(viewOptions);
    } else {
      this.setToTeeth(viewOptions);
    }
  }

  /**
   * Sets to superimpose view
   * @function
   * @param {Object} viewOptions - The view options
   */
  setToSuperimpose(viewOptions) {
    setMaterialOpacity(this.matOver, this.matTeeth, viewOptions.superimpose_opacity);
    this.setSuperimposeColor(viewOptions.superimpose_opacity);

    this.steps['first'].setTeethMaterial(this.matOver);
    this.steps['first'].showTeeth(viewOptions);
    this.steps['last'].setTeethMaterial(this.matTeeth);
  }

  /**
   * Sets to teeth opacity view
   * @function
   * @param {Object} viewOptions - The view options
   */
  setToTeeth(viewOptions) {
    setMaterialOpacity(this.matTeethUpper, this.matTeethLower, viewOptions.teeth_opacity);

    this.steps['first'].setUpperTeethMaterial(this.matTeethUpper);
    this.steps['first'].setLowerTeethMaterial(this.matTeethLower);
    this.steps['last'].setUpperTeethMaterial(this.matTeethUpper);
    this.steps['last'].setLowerTeethMaterial(this.matTeethLower);
  }

  /**
   * Sets the superimpose teeth opacity and color
   * @function
   * @param {Number} opacity - The superimpose opacity
   */
  setSuperimposeColor(opacity) {
    const alpha = this.computeSuperimposeColorAlpha(opacity);

    const teethColor = teethWhiteColor.clone();
    teethColor.lerp(teethMaloColor, alpha);
    this.matTeeth.color = teethColor;

    const overColor = teethSetupColor.clone();
    overColor.lerp(teethWhiteColor, alpha);
    this.matOver.color = overColor;
  }

  /**
   * Computes the superimpose color alpha base on opacity
   * @function
   * @param {Number} opacity - The superimpose opacity
   */
  computeSuperimposeColorAlpha(opacity) {
    return clamp(-2.5 * opacity + 1.75, 0, 1);
  }

  /**
   * Updates all steps' IPR
   * @function
   * @param {Number} textSize - The text size
   * @param {THREE.Quaternion} quat - The camera's quaternion
   */
  updateIPR(textSize, quat) {
    if (this.currentStep in this.steps) {
      this.steps[this.currentStep].updateIPR(textSize, quat);
    }
  }
}

export { SetupManager };
