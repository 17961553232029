// External
import Axios from 'axios';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

// Internal
import { getDoctorsIdFromRoute } from '../../../common/route';
import SimpleModal from './simple-modal';
import { MaximumIPRModal } from '../../../components/workflow/setup/setup_helper';
import SessionExpire from './session_expire';

// Redux
import { setSmileDesignPreferencesModal } from '../../../redux/actions/ipp/feature_announcement';

/**
 * Displays smile design preferences login modal
 * @component
 * @category IPP
 */
const SmileDesignPreferencesModal = ({ setSmileDesignPreferencesModal }) => {
  const doctor_id = getDoctorsIdFromRoute();
  const [preferences, setPreferences] = React.useState([]);
  const [ipr_modal, setIprModal] = React.useState(false);
  const [ipr_modal_preference, setIprModalPreference] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    Axios.get(`/apiv3/smiledesignpreference/${doctor_id}`).then(function (res) {
      if (res.data) {
        setPreferences(res.data.preferences);
      }
    });
  }, [doctor_id]);

  /**
   * Retrieves doctor preference response
   * @function
   * @param {object} preference - Response info
   * @return {string} - Response
   */
  const getResponse = (preference) => {
    switch (preference.question_type) {
      case 'text':
        return preference.response_text ? `"${preference.response_text}"` : null;
      case 'tooth_chart_selection':
        return displayViewIprModalLink(preference);
      default:
        return getSelectedChoice(preference);
    }
  };

  /**
   * Retrieves radio/dropdown selection from doctor
   * @function
   * @param {object} preference - Response info
   * @return {JSX} - Selected choice
   */
  const getSelectedChoice = (preference) => {
    const choices = preference.choices;
    const selected_choice = preference.selected_choice;
    if (choices && selected_choice) {
      if (preference.question_type === 'checkbox') {
        const selected_choices = choices.map((choice, index) => {
          const answer = preference.selected_choice.includes(Number(choice.id)) ? 'Yes' : 'No';
          return (
            <div key={index} className="checkbox-response">
              {choice.choice} ({answer})
            </div>
          );
        });
        return selected_choices;
      }

      const selection = choices.find((choice) => choice.id.toString() === selected_choice.toString());
      const response_text = preference.response_text ? ` - "${preference.response_text}"` : '';
      return (
        <span>
          {selection.choice}
          {response_text}
        </span>
      );
    }
    return <span></span>;
  };

  /**
   * Updates doctor's smile_design_preference_accepted indicator
   * @function
   */
  const onButtonClick = () => {
    Axios.put(`/apiv3/smiledesignpreference/accept/${doctor_id}`)
      .then(function (res) {
        setSmileDesignPreferencesModal(false);
      })
      .catch(function (err) {
        if (err && err.response && err.response.status === 409) {
          setRefresh(true);
        }
      });
  };

  /**
   * Displays link to view IPR values
   * @function
   * @param {object} preference - Preference info
   * @return {JSX} - JSX for view link
   */
  const displayViewIprModalLink = (preference) => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <a
          onClick={() => {
            setIprModalPreference(preference);
            setIprModal(true);
          }}
        >
          View
        </a>
      </>
    );
  };

  /**
   * Displays IPR modal
   * @function
   * @return {JSX} - JSX for IPR modal
   */
  const displayIprModal = () => {
    return (
      <div className="smile-design-preferences-ipr">
        <MaximumIPRModal
          preference={ipr_modal_preference}
          onClose={() => {
            setIprModalPreference(null);
            setIprModal(false);
          }}
          close_btn_text="Close"
          classNames={{
            dialogClassName: 'ipp-smile-design-preferences-ipr',
            modalFooterClassName: 'ipp-smile-design-preferences-ipr',
          }}
        />
      </div>
    );
  };

  return (
    <>
      <SimpleModal show={true} containerclassName="smile-design-preferences-modal">
        <SimpleModal.Header title="Customize Your Smile Design Preferences" onClose={() => setSmileDesignPreferencesModal(false)} />
        <SimpleModal.ContentBody>
          <div>Below are the default Smile Design Preferences for your review and approval.</div>
          <div className="preferences-content">
            <ol>
              {preferences.map((preference, index) => {
                return (
                  <li key={index}>
                    {preference.question}: {getResponse(preference)}
                  </li>
                );
              })}
            </ol>
          </div>
        </SimpleModal.ContentBody>
        {preferences && (
          <SimpleModal.FooterWithButtons
            onClickConfirm={onButtonClick}
            onClickCancel={onButtonClick}
            confirmButtonText="Accept"
            cancelButtonText="Edit Preferences"
            rerouteLink={`/portal/${doctor_id}/account/smile-design-preferences`}
          />
        )}
      </SimpleModal>
      {ipr_modal && displayIprModal()}
      {refresh && <SessionExpire />}
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSmileDesignPreferencesModal: setSmileDesignPreferencesModal,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SmileDesignPreferencesModal);
