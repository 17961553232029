import { ApiServiceInstance } from '../../../common/api_service';

export const FETCH_CASE_DETAILS_START = 'FETCH_CASE_DETAILS_START';
export const FETCH_CASE_DETAILS_SUCCESS = 'FETCH_CASE_DETAILS_SUCCESS';
export const FETCH_CASE_DETAILS_ERROR = 'FETCH_CASE_DETAILS_ERROR';

export const CLEAR_DRAFT_SAVES = 'CLEAR_DRAFT_SAVES';

export function fetchCaseDetails(case_id, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch(fetchCaseDetailsStart());
    return ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        dispatch(fetchCaseDetailsSuccess(res.data));
        if (onSuccess) {
          onSuccess(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        dispatch(fetchCaseDetailsError(err))
        if (onError) {
          onError(err);
        }
      });
  };
}

export function fetchCaseDetailsSilently(case_id) {
  return (dispatch) => {
    return ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        dispatch(fetchCaseDetailsSuccess(res.data));
        return res.data;
      })
      .catch((err) => err);
  };
}

// Case Details

export function fetchCaseDetailsStart() {
  return {
    type: FETCH_CASE_DETAILS_START,
  };
}

export function fetchCaseDetailsSuccess(case_details) {
  return {
    type: FETCH_CASE_DETAILS_SUCCESS,
    case_details: case_details,
  };
}

export function fetchCaseDetailsError(error) {
  return {
    type: FETCH_CASE_DETAILS_ERROR,
    error: error,
  };
}

export function clearDraftSaves(caseId) {
  return {
    type: CLEAR_DRAFT_SAVES,
    payload: { caseId },
  };
}
