import React from 'react';
import { userHasPermission } from '../../../../common/permission';
import { UserPermissionsContext } from '../../../../context/user_permission';

/**
 * Displays Single-Tooth IDB Tray generation in progress component
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - JSX for component
 */
function SingleToothIdbGeneration(props) {
  return (
    <>
      <div className="single-tooth-idb__generation">Single-Tooth IDB Tray generation in progress...</div>
      <i className="fa fa-refresh font-size-xlg rotate margin-top-10" aria-hidden="true" />
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const idb_gen_type = props.id.includes('-IR') ? 'IREQ' : 'CASE';
          const has_bypass_permissions = userHasPermission(`${idb_gen_type}_IDB_BYPASS`, user_roles_and_permissions.permissions);
          return has_bypass_permissions ? (
            <div className="button-panel center-text margin-top-10">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  props.setSingleToothIdbModal('mark_success');
                }}
              >
                Mark as Success
              </button>

              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  props.setSingleToothIdbModal('mark_failed');
                }}
              >
                Mark as Failed
              </button>
            </div>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    </>
  );
}

export default SingleToothIdbGeneration;
