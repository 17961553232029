import React from 'react';

/**
 * Displays locked icon
 * @component
 */
function LockedIcon() {
  return <i className="fa fa-lock lock_icon"></i>;
}

export default LockedIcon;
