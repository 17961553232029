import { resetTokenHeader } from './functions';

const handleHttpRequestError = (err, that, logout = false, role = 'Doctor') => {
  // Handles Request Error correctly
  if (err && err.response && err.response.status === 401) {
    // 401 error -> Redirect user to the main screen
    if (logout) {
      resetTokenHeader();
    }

    if(that.props && that.props.history){
      if (role === 'Business') {
        return that.props.history.push('/business/portal');
      } else {
        return that.props.history.push('/?returnurl=' + that.props.location.pathname);
      }
    }
  } else if (err && err.response && err.response.status === 403) {
    // 403 error -> Forbidden, must agree to terms
    that.setState({ terms: true });

    // Redirect to main page if trying to route from a different location than the main page
    if (that.props.history && that.props.history.location && that.props.history.location.pathname) {
      const match = that.props.history.location.pathname.match(/portal\/\d+\//);
      if (match && match.length > 0) {
        return that.props.history.replace('/');
      }
    }
  } else if (err && err.response && err.response.status === 404) {
    // 404 error -> Show 404 error page
    that.setState({ error: true, status: 'error' });
  } else if (err && err.response && err.response.status === 409) {
    // 409 error -> Force user to refresh page
    that.setState({ refresh: true });
  } else {
    console.log(`Error: ${err}`);
    console.log(err.response);
  }
};

export { handleHttpRequestError };
