import React from 'react';
import { setManualWireWire } from '../../../../redux/actions/manual_wire/manual_wire';
import ManualWireDropdown from './manual_wire_dropdown/manual_wire_dropdown';
import { connect } from 'react-redux';
import { wireOptions } from './manual_wire_options';

const ManualWireSmartwire = ({ smartwireOptions, arch, wire, fetchManualWireData, disableAllFields }) => {
  const onChangeProgram = (event) => {
    const wireValue = event.target.value;
    fetchManualWireData(arch, wireValue, true);
  };

  const options = wireOptions.filter(option => smartwireOptions.includes(option.value));

  return (
    <ManualWireDropdown
      options={options}
      value={wire}
      setValue={onChangeProgram}
      label="Arch"
      id="wire-select-label"
      style={{ height: '30px' }}
    />
  );
};

const mapStateToProps = (state) => {
  const wire = state.manualWireReducer.wire;
  const arch = state.manualWireReducer.arch;

  return {
    wire,
    arch,
  };
};

export default connect(mapStateToProps)(ManualWireSmartwire);
