import React from 'react';
import ProgressBar from '../../doctor/components/progress_bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './file_upload_progress.scss';
import Badge from '../../doctor/components/badge';

const UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS';

const COMPLETE_STATUS_ICON = {
  UPLOAD_SUCCESS: <FontAwesomeIcon icon={faCircleCheck} color="#2DA4AD" size="lg" />,
  UPLOAD_ERROR: <FontAwesomeIcon icon={faCircleExclamation} color="#e34558" size="lg" />,
};

function humanFileSize(size) {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(0) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

function FileUploadProgress(props) {
  const { file_statuses, onAbortUpload } = props;

  return Object.keys(file_statuses).map((filename) => {
    const status = file_statuses[filename];
    const is_in_progress = status.state === UPLOAD_IN_PROGRESS;
    const percent = status.progress.toFixed(2);
    return (
      <div key={filename} className="fileupload-progress">
        {status.state in COMPLETE_STATUS_ICON && <div>{COMPLETE_STATUS_ICON[status.state]}</div>}
        <div className="fileupload-progress__main">
          <div>
            <div className="emphasis">
              {filename} <Badge>{humanFileSize(status.size)}</Badge>
            </div>
            {status.message && <div>{status.message}</div>}
          </div>
          {is_in_progress && (
            <div>
              <span>{percent}%</span>
              <span>
                <FontAwesomeIcon className="fileupload-abort" icon={faCircleXmark} size="lg" onClick={() => onAbortUpload(filename)} />
              </span>
            </div>
          )}
        </div>
        {is_in_progress && <ProgressBar curr={status.progress} />}
      </div>
    );
  });
}

export default FileUploadProgress;
