import './radiographs.scss';
import FileUpload from '../../components/file_upload/file_upload';
import React from 'react';
import AlertDraftReadOnly from '../components/alerts/alert_draft_read_only';
import FileUploadProgress from '../../components/file_upload/file_upload_progress';
import SimpleFileUpload from '../../components/file_upload/simple_file_upload';
import RecordViewer from '../components/record_viewer/record_viewer';
import { onImageToolAction } from '../../common/functions';
import { isS3FileService } from '../../common/helpers';

function Xrays(props) {
  const isS3 = isS3FileService();

  return (
    <form className="case-form-container">
      <h3>Upload Radiographs</h3>
      {props.isDso && props.disabledEdit ? <AlertDraftReadOnly /> : null}
      {isS3 ? (
        <div className="fileUpload-container">
          <div>
            <SimpleFileUpload
              fileStatus={props.upload_state}
              setFileStatus={props.onUpload}
              onCompleteUpload={() => {}}
              multiple
              uploadParams={{ folder: 'photos', id: props.id }}
              type="image"
              disabled={props.editDisabled}
            />
            <div className="fileupload-progress-container scrollbar--dark">
              <FileUploadProgress file_statuses={props.xrayUpload} onAbortUpload={() => {}} />
            </div>
          </div>
          <RecordViewer
            records={props.record_states}
            onLoad={props.onLoadImageRecord}
            onRemove={props.onRemove}
            removeAllowed={props.removeAllowed}
            disabled={props.disabled}
            onClick={(id, action) => {
              onImageToolAction(id, action, props);
            }}
          />
        </div>
      ) : (
        <FileUpload
          id={props.id}
          filesUploaded={props.xrayUpload}
          onUpload={props.onUpload}
          onRemove={props.onRemove}
          onIncompleteSave={props.onIncompleteSave}
          multiple={true}
          location={props.location ? props.location : 'incomplete'}
          folder="xrays"
          type="image"
          upload_state={props.upload_state}
          upload_content={props.upload_content}
          show_warning={props.show_warning}
          hide_warning={props.hide_warning}
          removeAllowed={true}
          disabled={props.disabledEdit}
          updateRecordState={props.updateRecordState}
        />
      )}
    </form>
  );
}

export default Xrays;
