import React from 'react';
import PropTypes from 'prop-types';

import './circle_loader.scss';

/**
 * Displays a spinner with circles
 * @component
 */
function CircleLoader({ id, size, theme, fullscreen, text, withSpacing, withBackground }) {
  const wrapperClassNames = []
  const spinnerContainerclassNames = ['spinner-container'];

  if (size === 'small') spinnerContainerclassNames.push('spinner-container--small');
  if (theme === 'dark') spinnerContainerclassNames.push('spinner-container--dark');
  if (fullscreen) {
    spinnerContainerclassNames.push('spinner-container--fullscreen')
    wrapperClassNames.push('fullview')
  };
  if (withSpacing) wrapperClassNames.push('loader-spacing')
  if (withBackground) wrapperClassNames.push('with-background')

  return (
    <>
      <div className={wrapperClassNames.join(' ')}>
        <div id={id} className={spinnerContainerclassNames.join(' ')}>
          <div className="circle1 circle" />
          <div className="circle2 circle" />
          <div className="circle3 circle" />
          <div className="circle4 circle" />
          <div className="circle5 circle" />
          <div className="circle6 circle" />
          <div className="circle7 circle" />
          <div className="circle8 circle" />
        </div>
      </div>
      {text ? text : null}
    </>
  );
}

CircleLoader.propTypes = {
  withSpacing: PropTypes.bool,
  withBackground: PropTypes.bool,
}

CircleLoader.defaultProps = {
  withSpacing: true,
  withBackground: false,
}

export default CircleLoader;
