import React from 'react';
import Button from './button';

/**
 * Displays the retry button for the setup viewer
 * @component
 * @alias SetupViewerRetryButton
 */
export default function RetryButton(props) {
  return (
    <div className={props.className}>
      <div>{props.message}</div>
      <Button theme="secondary" onClick={props.onRetry}>
        Retry
      </Button>
    </div>
  );
}
