/**
 * File:pr_list.js - List all the progress records
 * Copyright: (c) Copyright March 2020 by InBrace
 * Authors: Katie Chen
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './pr_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Internal Components
import PrContent from './pr_content';

class PrList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return <PrContent />;
  }
}
export default withRouter(PrList);
