import { FETCH_TOUR_PENDING, FETCH_TOUR_SUCCESS, FETCH_TOUR_ERROR } from '../../actions/product_tour/product_tour';

const initialState = {
  tours: [],
  pending: true,
  error: false,
};

export function productTourReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOUR_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_TOUR_SUCCESS:
      return {
        ...state,
        pending: false,
        tours: [
          ...state.tours,
          {
            tour_slug: action.tour_slug,
            tour_completed: action.tour_completed,
            tour_phase: action.tour_phase,
          },
        ],
      };

    case FETCH_TOUR_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    default:
      return state;
  }
}

export const getProductTours = (state) => state.productTourReducer.tours;
export const getPending = (state) => state.productTourReducer.pending;
export const getError = (state) => state.productTourReducer.error;
