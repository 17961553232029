// External
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Internal
import SimpleModal from './simple-modal';
import SmileDesignPreferencesModal from './smile_design_preferences';

// Redux
import { getFeatureAnnouncementModal, getSmileDesignPreferencesModal } from '../../../redux/reducers/ipp/feature_announcement';
import { setFeatureAnnouncementModal, setSmileDesignPreferencesModal } from '../../../redux/actions/ipp/feature_announcement';

/**
 * Displays new feature announcement modal
 * @component
 * @category IPP
 */
const FeatureAnnouncement = ({ feature, announcement_modal, smile_design_preferences_modal, setFeatureAnnouncementModal, setSmileDesignPreferencesModal }) => {
  const features = {
    smile_design_preferences: {
      follow_up_modal: smile_design_preferences_modal,
      follow_up_modal_component: <SmileDesignPreferencesModal />,
    },
  };

  const onNextClick = () => {
    setFeatureAnnouncementModal(false);
    setSmileDesignPreferencesModal(true);
  };

  return (
    <>
      <SimpleModal show={announcement_modal} containerclassName="feature-announcement-modal">
        <SimpleModal.Header title="Feature Announcement" onClose={() => setFeatureAnnouncementModal(false)} />
        <SimpleModal.ContentBody id="announcement-image" />
        <SimpleModal.FooterWithButtons onClickConfirm={onNextClick} confirmButtonText="Next" showCancel={false} />
      </SimpleModal>
      {features[feature].follow_up_modal && features[feature].follow_up_modal_component}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    announcement_modal: getFeatureAnnouncementModal(state),
    smile_design_preferences_modal: getSmileDesignPreferencesModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFeatureAnnouncementModal: setFeatureAnnouncementModal,
      setSmileDesignPreferencesModal: setSmileDesignPreferencesModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FeatureAnnouncement);
