import React, { useState } from 'react';
import { useRef } from 'react';
import SortHeader from '../../components/layout/sort_header';
import CaseListExpediteIcon from './case_list_expedite_icon';
import { getTableCellRender } from './case_list_table_cell';
import { getCaseListTableHeader, getColumnIndexByTitle } from './case_list_table_header';
import CaseListTableLoadingPlaceholder from './case_list_table_loading_placeholder';

import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';

/**
 * Display the case list table
 * @component
 * @category BPP
 */
function CaseListTable(props) {
  const { onClickSort, sort_method, sort_ascending, filter, isLoading, tableData, onClickRow, rowSize } = props;
  const tableHeader = getCaseListTableHeader(filter);
  const caseTableRef = useRef(null);
  const [newTabURL, setNewTabURL] = useState('');

  const hasTableData = tableData?.length > 0;
  const onRowRightClick = (e) => setNewTabURL(`/business/portal/case/${e.currentTarget.dataset.caseid}`);
  const fs_exclude_index = getColumnIndexByTitle(tableHeader, 'Patient');

  return (
    <div className="table-case-view context-menu-container context-menu-offset">
      <ContextMenu container={caseTableRef}>
        <NewTabLink url={newTabURL} />
      </ContextMenu>
      <table className="table table-condensed table-industry">
        <thead className="table-inbrace-dark-industry">
          <tr>
            {tableHeader.map((col) => (
              <th key={col.category} className={col.className} onClick={() => onClickSort(col.category)}>
                <SortHeader title={col.title} category={col.category} sort_method={sort_method} ascending={sort_ascending} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table-inbrace-dark-industry" ref={caseTableRef}>
          {hasTableData &&
            tableData.map((cases) => (
              <tr className="pointer" key={cases.case_id} data-caseid={cases.case_id} onClick={onClickRow} onContextMenu={onRowRightClick}>
                {tableHeader.map((col, i) => (
                  <td key={col.category} className={`${col.className}${i === fs_exclude_index ? ' fs-exclude' : ''}`}>
                    {getTableCellRender(col.category)(cases)}
                    {i === tableHeader.length - 1 && cases.expedite_ind && <CaseListExpediteIcon />}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
        {!hasTableData && isLoading && <CaseListTableLoadingPlaceholder header={tableHeader} rowSize={rowSize} />}
      </table>
    </div>
  );
}

export default CaseListTable;
