import React from 'react';
import { setManualWireAuto, saveDraft } from '../../../../redux/actions/manual_wire/manual_wire';
import { connect } from 'react-redux';

const ManualWireAuto = ({ auto, setAuto, saveDraft, disableAllFields }) => {
  const onChangeAuto = (event) => {
    const auto = event.target.checked;
    saveDraft(setAuto, auto);
  };

  return (
    <input
      type="checkbox"
      id="auto"
      onChange={onChangeAuto}
      checked={auto}
      disabled={disableAllFields}
    />
  );
};

const mapStateToProps = (state) => {
  const auto = state.manualWireReducer.auto;
  
  return {
    auto,
  };
};

const mapDispatchToProps = {
  setAuto: setManualWireAuto,
  saveDraft: saveDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireAuto);
