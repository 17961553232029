import React from 'react';
import WasmMovementTableView from './movement_table/wasm_movement_table_view';
import WasmIPR from './ipr/wasm_ipr';
import { Verbiage } from './verbiage.constants';

function WasmChangesSummary(props) {
  const { movement, ipr_change, revision_note } = props;
  return (
    <div className="wasm-change-summary-container">
      <div>
        <div className="wasm-view-change-header">{Verbiage.ChangesSummary.Title}</div>
        {revision_note ? (
          <div className="wasm-view-change-content wasm-view-change-revision-notes scrollbar--dark">{revision_note}</div>
        ) : (
          <div className="wasm-view-change--no-change">{Verbiage.ChangesSummary.NoChange}</div>
        )}
      </div>
      <div>
        <div className="wasm-view-change-header">{Verbiage.ChangesSummary.TmtTitle}</div>
        <div className="wasm-view-change-content">
          <WasmMovementTableView movementData={movement} />
        </div>
      </div>
      <div>
        <div className="wasm-view-change-header">{Verbiage.ChangesSummary.IprTitle}</div>
        <div className="wasm-view-change-content">
          <WasmIPR ipr={ipr_change} />
        </div>
      </div>
    </div>
  );
}

export default WasmChangesSummary;
