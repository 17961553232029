import Axios from 'axios';
import { drawLogoPicture } from '../../../common/functions';

// Action Types
export const FETCH_LOGO_PICTURE_SUCCESS = 'FETCH_LOGO_PICTURE_SUCCESS';
export const FETCH_LOGO_PICTURE_DSO_DOCTOR_SUCCESS = 'FETCH_LOGO_PICTURE_DSO_DOCTOR_SUCCESS';
export const FETCH_LOGO_PICTURE_PENDING = 'FETCH_LOGO_PICTURE_PENDING';
export const FETCH_LOGO_PICTURE_ERROR = 'FETCH_LOGO_PICTURE_ERROR';
export const SET_LOGO_METADATA_EDIT = 'SET_LOGO_METADATA_EDIT';
export const SET_LOGO_IMAGE = 'SET_LOGO_IMAGE;';
export const OPEN_UPLOAD_LOGO_MODAL = 'OPEN_UPLOAD_LOGO_MODAL';
export const CLOSE_UPLOAD_LOGO_MODAL = 'CLOSE_UPLOAD_LOGO_MODAL';
export const SAVE_LOGO_PICTURE_REPOSITION_PENDING = 'SAVE_LOGO_PICTURE_REPOSITION_PENDING';
export const SAVE_LOGO_PICTURE_REPOSITION_SUCCESS = 'SAVE_LOGO_PICTURE_REPOSITION_SUCCESS';
export const SAVE_LOGO_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS = 'SAVE_LOGO_PICTURE_DSO_DOCTOR_REPOSITION_SUCCESS';
export const SAVE_LOGO_PICTURE_REPOSITION_ERROR = 'SAVE_LOGO_PICTURE_REPOSITION_ERROR';
export const OPEN_REPOSITION_LOGO_PICTURE_MODAL = 'OPEN_REPOSITION_LOGO_PICTURE_MODAL';
export const OPEN_REPOSITION_LOGO_PICTURE_DSO_DOCTOR_MODAL = 'OPEN_REPOSITION_LOGO_PICTURE_DSO_DOCTOR_MODAL';
export const CLOSE_REPOSITION_LOGO_PICTURE_MODAL = 'CLOSE_REPOSITION_LOGO_PICTURE_MODAL';
export const OPEN_LOGO_PICTURE_MENU = 'OPEN_LOGO_PICTURE_MENU';
export const CLOSE_LOGO_PICTURE_MENU = 'CLOSE_LOGO_PICTURE_MENU';
export const OPEN_DELETE_LOGO_MODAL = 'OPEN_DELETE_LOGO_MODAL';
export const CLOSE_DELETE_LOGO_MODAL = 'CLOSE_DELETE_LOGO_MODAL';
export const DELETE_LOGO_PICTURE_PENDING = 'DELETE_LOGO_PICTURE_PENDING';
export const DELETE_LOGO_PICTURE_SUCCESS = 'DELETE_LOGO_PICTURE_SUCCESS';
export const DELETE_LOGO_PICTURE_ERROR = 'DELETE_LOGO_PICTURE_ERROR';
export const UPLOAD_LOGO_PICTURE_PENDING = 'UPLOAD_LOGO_PICTURE_PENDING';
export const UPLOAD_LOGO_PICTURE_SUCCESS = 'UPLOAD_LOGO_PICTURE_SUCCESS';
export const UPLOAD_LOGO_PICTURE_ERROR = 'UPLOAD_LOGO_PICTURE_ERROR';
export const INVALID_LOGO_PICTURE_SIZE = 'INVALID_LOGO_PICTURE_SIZE';
export const INVALID_LOGO_PICTURE_FORMAT_ERROR = 'INVALID_LOGO_PICTURE_FORMAT_ERROR';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Retrieves doctor's profile picture info
 *
 * @function
 * @param {number} doctor_id - Doctor id
 * @param {boolean} open_reposition_modal - Indicating if the reposition modal should be opened after fetching profile picture
 * @param {object} that - Instance of class
 */
export function fetchLogoPicture(address_id, that) {
  return (dispatch) => {
    dispatch(fetchLogoPending());

    Axios.get(`/apiv3/practiceinfo/${address_id}/practicelogo`)
      .then(function (result) {
        dispatch(fetchLogoSuccess(result.data.s3_url_raw));

        drawLogoPicture(that);
      })
      .catch(function (err) {
        dispatch(fetchLogoPictureError());
      });
  };
}

export function fetchLogoPending() {
  return {
    type: FETCH_LOGO_PICTURE_PENDING,
  };
}

export function fetchLogoSuccess(s3_url_raw) {
  return {
    type: FETCH_LOGO_PICTURE_SUCCESS,
    logo_image: s3_url_raw,
    s3_url_raw: s3_url_raw,
  };
}

export function fetchLogoPictureError() {
  return {
    type: FETCH_LOGO_PICTURE_ERROR,
  };
}

export function setLogoImage(logo_image) {
  return {
    type: SET_LOGO_IMAGE,
    logo_image: logo_image,
  };
}

/**
 * Handles when user deletes their logo
 *
 * @function
 */
export function onDeleteLogoPicture() {
  return (dispatch) => {
    dispatch(deleteLogoPictureSuccess());
  };
}

export function deleteLogoPicturePending() {
  return {
    type: DELETE_LOGO_PICTURE_PENDING,
  };
}

export function deleteLogoPictureSuccess() {
  return {
    type: DELETE_LOGO_PICTURE_SUCCESS,
    s3_url_raw: '',
  };
}

export function deleteLogoPictureError() {
  return {
    type: DELETE_LOGO_PICTURE_ERROR,
  };
}

/**
 * Handles event when user selects a profile picture to upload
 *
 * @function
 * @param {object} e - Event object
 * @param {number} practice_info_id - Doctor id
 */
export function onLogoPictureUpload(e, practice_info_id, that) {
  return (dispatch) => {
    dispatch(uploadLogoPicturePending());
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (event) {
        let img = new Image();
        img.onload = function () {
          Axios.post(`/apiv3/practiceinfo/${practice_info_id}/practicelogo`, formData)
            .then((res) => {
              dispatch(uploadLogoPictureSuccess(res.data.s3_url_raw));
              that.inputReference.current.value = '';
            })
            .catch(function (err) {
              dispatch(uploadLogoPictureError(err));
              that.inputReference.current.value = '';
            });
        };
        if (
          reader.result.includes('image/jpg') ||
          reader.result.includes('image/jpeg') ||
          reader.result.includes('image/png') ||
          reader.result.includes('image/webp') ||
          reader.result.includes('image/svg')
        ) {
          img.src = reader.result;
        } else {
          dispatch(unSupportedFileTypeError());
          that.inputReference.current.value = '';
        }
      };
    }
  };
}

export function uploadLogoPicturePending() {
  return {
    type: UPLOAD_LOGO_PICTURE_PENDING,
  };
}

export function uploadLogoPictureSuccess(s3_url_raw) {
  return {
    type: UPLOAD_LOGO_PICTURE_SUCCESS,
    logo_image_edit: s3_url_raw,
    s3_url_raw: s3_url_raw,
  };
}
export function unSupportedFileTypeError() {
  return {
    type: INVALID_LOGO_PICTURE_FORMAT_ERROR,
  };
}

export function uploadLogoPictureError(err) {
  if (err.response.status === 400 && err.response.data === 'Invalid format') {
    return {
      type: INVALID_LOGO_PICTURE_FORMAT_ERROR,
    };
  }
  return {
    type: UPLOAD_LOGO_PICTURE_ERROR,
  };
}
/**
 * Handles event when user selects a profile picture to upload which is smaller than required dimension
 *
 * @function
 * @return {object} - Returns redux action type
 */
export function uploadLogoPictureErrorSize() {
  return {
    type: INVALID_LOGO_PICTURE_SIZE,
  };
}

// Modals and Menus
export function openUploadPictureModal() {
  return {
    type: OPEN_UPLOAD_LOGO_MODAL,
  };
}

export function closeUploadPictureModal() {
  return {
    type: CLOSE_UPLOAD_LOGO_MODAL,
  };
}

export function openLogoPictureMenu() {
  return {
    type: OPEN_LOGO_PICTURE_MENU,
  };
}

export function closeLogoPictureMenu() {
  return {
    type: CLOSE_LOGO_PICTURE_MENU,
  };
}

export function openDeletePictureModal(that) {
  return (dispatch) => {
    dispatch({
      type: OPEN_DELETE_LOGO_MODAL,
    });
    drawLogoPicture(that);
  };
}

export function closeDeletePictureModal() {
  return {
    type: CLOSE_DELETE_LOGO_MODAL,
  };
}
