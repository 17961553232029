import './prospect_list.scss';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// Internal Components
import AdditionalActionsDropdown from '../case_list/additional_actions_dropdown';
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import DivTable from '../components/tables/div_table';
import Dropdown from '../components/inputs/dropdown';
import TextBox from '../components/inputs/text_box';
import ProspectFilter from './prospect_filter';
import NoRecords from '../case_list/no_records';
import NoResult from '../case_list/no_result';
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermissions, userHasPermission } from '../../common/permission';

// Internal Functions
// eslint-disable-next-line
import { setTokenHeader, convertDate, getRootPath, textFieldCheck, removeEmoji } from '../../common/functions';
import {
  getDoctorsIdFromRoute,
  getSearchValueFromRoute,
  getSearchFilterValueFromRoute,
  getSelectionValueFromRoute,
  getStatusFilterValueFromRoute,
} from '../../common/route';
import { getSubmissionDateDefaultSort, convertHtmlToPlainText, truncateTextByLimit } from '../../common/helpers';
import { tableRowLink } from '../../common/table';
import { displayPSTDateTime } from '../../common/date';
import { setOverflowTooltip } from '../../common/tooltip';

//Redux
// Redux Reducers
import { getProspects, getError, getPending, getStatusCount } from '../../redux/reducers/prospects/prospects';
import { getDoctorRole, getAccountLinkId, getAdditionalActionsModal, getNoteModal } from '../../redux/reducers/ipp/case_list';

// Redux Actions
import { clearProspectsList, fetchProspectsList, updateProspectNote } from '../../redux/actions/prospects/prospects';
import { setNoteModal } from '../../redux/actions/ipp/case_list';

/**
 * Used to displayed all prospects in a list format on the IPP.
 *
 * The prospect list page is where the users can click on a particular prospect to
 * get details on a prospect.
 * @component
 * @alias DoctorProspectList
 * @category IPP
 */
class ProspectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctor_id: '',
      search: '',
      search_phrase: '',
      search_filter: '',
      filter: '',
      cases_raw: [],
      prospect_sort_method: 'created_date',
      prospect_sort_order_ascending: { prospect_name: true, created_date: false, city: true, prospect_status: true },
      is_bottom: false,
      prospect_raw: [],
      prospect_view: [],
      prospect_active_page: 1,
      prospect_page_range: 5,
      prospect_length: 1,
      prospect_row_size: 15,
      showCancelCase: false,
      selected_prospect_id: '',
      selected_first_name: '',
      selected_last_name: '',
      selection: '',
      status_friendly_name: {
        Active: 'Schedule Consultation',
        'Complete Consultation': 'Complete Consultation',
        'Convert to Case': 'Begin Submission',
        Hold: 'On Hold',
        'Converted to Draft': 'Resume Submission',
        'Converted to Case': 'Submitted',
        'Prospect Lost': 'Prospect Lost',
      },
      search_filter_name: {
        schedule: 'Schedule Consultation',
        consult: 'Complete Consultation',
        begin_submission: 'Begin Submission',
        hold: 'On Hold',
        submitted: 'Submitted',
        lost: 'Prospect Lost',
      },
      status_filter: '',
      statuses: {
        all: 0,
        action: 0,
        hold: 0,
        consult: 0,
        schedule: 0,
        begin_submission: 0,
        // draft: 0,
        submitted: 0,
        lost: 0,
      },
      note_updated: false,
    };

    this.displaySearchBar.bind(this);
    this.onChangeSearchPatient.bind(this);
    this.onChangeRowSize = this.onChangeRowSize.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSearchFilterSelection = this.onSearchFilterSelection.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    this.setLocalState();

    //Enable tooltip
    setTimeout(function () {
      this.$('[data-toggle="tooltip"]').tooltip('fixTitle');
    }, 0);
    window.$('.sub-item').click(() => this.onPageChange(1));
    this.setupToolTip();
  }

  componentDidUpdate() {
    if (this.props.prospect_list.length > 0 && (this.state.prospect_raw.length === 0 || this.props.prospect_list.length !== this.state.prospect_raw.length)) {
      this.setLocalState();
    }
    this.setupToolTip();
    const that = this;
    const state_search = this.state.search;
    const state_search_filter = this.state.search_filter;
    const state_status_filter = this.state.status_filter;
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();
    const sort_method = this.state.prospect_sort_method;
    const prospect_sort_order_ascending = this.state.prospect_sort_order_ascending;
    const active_page = this.state.note_updated ? this.state.prospect_active_page : 1;
    const row_size = this.state.prospect_row_size;
    let prospect_view = [];

    if (state_search !== search || state_search_filter !== search_filter || state_status_filter !== status_filter || this.state.note_updated) {
      if (search_filter !== '' || status_filter !== '') {
        const filter = search_filter || status_filter;
        prospect_view = that.setOrderByMethod(
          that.getProspectListFromSelection(that.getProspectListFromFilter(that.getProspectListFromSearch(that.state.prospect_raw, search), filter), selection),
          sort_method,
          prospect_sort_order_ascending[sort_method]
        );
      } else {
        prospect_view = that.setOrderByMethod(
          that.getProspectListFromSearch(that.state.prospect_raw, search),
          sort_method,
          prospect_sort_order_ascending[sort_method]
        );
      }

      this.setState({
        prospect_active_page: active_page,
        prospect_length: prospect_view.length,
        prospect_view: this.getProspectListFromRow(prospect_view, active_page, row_size),
        search: search,
        search_filter: search_filter,
        search_phrase: search,
        filter: '',
        selection: selection,
        status_filter: status_filter,
        note_updated: false,
      });
    } else if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.reset_list &&
      this.props.history.location.state.reset_list === 'true'
    ) {
      this.props.history.replace({ search: this.props.history.location.search, state: { reset_list: 'false', filter: 'prospect_list' } });
      const prospect_raw = that.state.prospect_raw;
      const prospect_view = that.setOrderByMethod(
        that.getProspectListFromSelection(that.getProspectListFromFilter(that.getProspectListFromSearch(prospect_raw, ''), ''), ''),
        'created_date',
        false
      );

      that.setState({
        prospect_view: that.getProspectListFromRow(prospect_view, active_page, row_size),
        prospect_active_page: active_page,
        prospect_sort_method: 'created_date',
        prospect_sort_order_ascending: { prospect_name: true, created_date: false, city: true, prospect_status: true },
        search_phrase: '',
      });
    }
  }

  /**
   * Sets Local State
   * @function
   */
  setLocalState() {
    const doctor_id = this.props.doctor_role.includes('DSO') ? this.props.account_link_id : getDoctorsIdFromRoute();
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const status_filter = getStatusFilterValueFromRoute();
    const filter = status_filter || search_filter;
    const selection = getSelectionValueFromRoute();
    this.props.fetchProspectsList(doctor_id);
    const prospect_raw = this.props.prospect_list;
    const prospect_view = this.orderProspectListByDefault(
      this.getProspectListFromSelection(this.getProspectListFromFilter(this.getProspectListFromSearch(prospect_raw, search), filter), selection)
    );

    this.setState({
      loading: this.props.prospect_list_pending,
      doctor_id: doctor_id,
      search: search,
      search_phrase: search,
      search_filter: search_filter,
      status_filter: status_filter,
      filter: filter,
      prospect_length: prospect_view.length,
      prospect_list: this.props.prospect_list,
      prospect_raw: prospect_raw,
      prospect_view: this.getProspectListFromRow(prospect_view, this.state.prospect_active_page, this.state.prospect_row_size),
      selection: selection,
      statuses: this.props.status_count,
    });
  }

  componentWillUnmount() {
    // clean up jquery
    window.$('.sub-item').off('click');
    this.props.setNoteModal(false);
  }

  /**
   * Tooltip setup
   * @function
   */
  setupToolTip = () => {
    try {
      window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
      window.$('[data-toggle="tooltip"]').on('mouseleave', function () {
        window.$('[data-toggle="tooltip"]').tooltip('destroy');
      });
      window.$('[data-toggle="tooltip"]').on('click', function () {
        window.$('[data-toggle="tooltip"]').tooltip('destroy');
      });
    } catch {}
  };

  /**
   * Changes search phrase once typed in new one in textbox
   * @param {Object} event - Input event listener
   * @function
   */
  onChangeSearchPatient = (event) => {
    let text = textFieldCheck(removeEmoji(event.target.value));
    this.setState({ search_phrase: text });
  };

  /**
   * Sets route with search phrase
   * @param {String} text - Search text
   * @function
   */
  setRouteBySearch(text) {
    const search_filter = this.state.search_filter;

    this.setRoute(text, search_filter);
    this.setState({ search_phrase: text });
  }

  /**
   * Sets route with filter terms
   * @param {String} filter - Search term using filter
   * @function
   */
  setRouteBySearchFilter(filter) {
    const search_phrase = this.state.search_phrase;

    this.setRoute(search_phrase, filter);
  }

  /**
   * Sets route with all the right filters and search terms
   * @param {String} search_phrase - Search term using filter
   * @param {String} search_filter - Search term using filter
   * @function
   */
  setRoute(search_phrase, search_filter) {
    this.props.fetchProspectsList(this.state.doctor_id);
    const selection_value = getSelectionValueFromRoute();

    if (selection_value.includes('doctor') || selection_value.includes('location') || selection_value === 'all_cases') {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=prospect_list`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=prospect_list&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(
          `/portal/${this.state.doctor_id}?selection=${selection_value}&filter=prospect_list&search=${search_phrase}&search_filter=${search_filter}`
        );
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?selection=${selection_value}&filter=prospect_list&search=${search_phrase}`);
      }
    } else {
      if (search_filter === '' && search_phrase === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=prospect_list`);
      } else if (search_phrase === '' && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=prospect_list&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter) {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=prospect_list&search=${search_phrase}&search_filter=${search_filter}`);
      } else if (search_phrase && search_filter === '') {
        this.props.history.replace(`/portal/${this.state.doctor_id}?filter=prospect_list&search=${search_phrase}`);
      }
    }
  }

  /**
   * Sets search phrase and detects keys like enter
   * @param {Object} event - Key press event listener
   * @function
   */
  onKeyPressSearchPatient = (event) => {
    let text = textFieldCheck(event.target.value);

    if (event.key === 'Enter') {
      this.setRouteBySearch(text);
    }
  };

  /**
   * Search button click event
   * @param {Object} event - Button click event listener
   * @function
   */
  onSearchButtonClick = (event) => {
    this.setRouteBySearch(this.state.search_phrase);
  };

  /**
   * Changes row size and setup new states
   * @param {Object} event - Button click event
   * @function
   */
  onChangeRowSize(event) {
    const active_page = 1;
    const filter = this.state.search_filter;
    const selection = this.state.selection;
    const prospect_view = this.setOrderByMethod(
      this.getProspectListFromSelection(
        this.getProspectListFromFilter(this.getProspectListFromSearch(this.state.prospect_raw, this.state.search), filter),
        selection
      ),
      this.state.prospect_sort_method,
      this.state.prospect_sort_order_ascending[this.state.prospect_sort_method]
    );

    this.setState({
      prospect_row_size: event.target.value,
      prospect_active_page: active_page,
      prospect_view: this.getProspectListFromRow(prospect_view, active_page, event.target.value),
      prospect_length: prospect_view.length,
    });
  }

  /**
   * Retrieves new entries and page changes
   * @param {BigInteger} active_page - Page number
   * @function
   */
  onPageChange(active_page) {
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    this.setState({
      prospect_active_page: active_page,
      prospect_view: this.getProspectListFromRow(
        this.setOrderByMethod(
          this.getProspectListFromSelection(
            this.getProspectListFromFilter(this.getProspectListFromSearch(this.state.prospect_raw, this.state.search), filter),
            selection
          ),
          this.state.prospect_sort_method,
          this.state.prospect_sort_order_ascending[this.state.prospect_sort_method]
        ),
        active_page,
        this.state.prospect_row_size
      ),
    });
  }

  /**
   * Gets prospect list from row
   * @param {List} prospect_view - List array of all the prospects
   * @param {Int} page - Page number
   * @param {Int} row_size - Row size per page
   * @function
   */
  getProspectListFromRow(prospect_view, page, row_size) {
    const start_index = (Number(page) - 1) * Number(row_size);
    const end_index = start_index + Number(row_size);

    return prospect_view.slice(start_index, end_index);
  }

  /**
   * Gets prospect list from search phrase
   * @param {List} prospects - List array of all the prospects
   * @param {String} search_phrase - Search phrase
   * @function
   */
  getProspectListFromSearch(prospects, search_phrase) {
    if (search_phrase === '') {
      return prospects;
    }
    search_phrase = search_phrase.toLowerCase();

    const space_index = search_phrase.lastIndexOf(' ');
    const inital_index = 0;

    let prospect = [];
    let search_city = '';
    let search_first_name = '';
    let search_last_name = '';

    let phrase_one = search_phrase;
    let phrase_two = '';

    if (space_index >= 0) {
      phrase_one = search_phrase.substring(inital_index, space_index);
      phrase_two = search_phrase.substring(space_index + 1, search_phrase.length);
    }

    for (let i = 0; i < prospects.length; i++) {
      search_first_name = prospects[i].first_name ? prospects[i].first_name.toLowerCase() : '';
      search_last_name = prospects[i].last_name ? prospects[i].last_name.toLowerCase() : '';
      search_city = prospects[i].city ? prospects[i].city.toLowerCase() : '';

      if (
        (search_first_name.indexOf(phrase_one) >= 0 && search_last_name.indexOf(phrase_two) >= 0) ||
        (search_first_name.indexOf(phrase_two) >= 0 && search_last_name.indexOf(phrase_one) >= 0)
      ) {
        prospect.push(prospects[i]);
      } else {
        if (search_first_name.indexOf(search_phrase) >= 0 || search_last_name.indexOf(search_phrase) >= 0 || search_city.indexOf(search_phrase) >= 0) {
          prospect.push(prospects[i]);
        }
      }
    }

    return prospect;
  }

  /**
   * Gets page start range using array length and page size
   * @function
   */
  getStartRange() {
    return this.state.prospect_active_page * this.state.prospect_row_size - this.state.prospect_row_size + 1;
  }

  /**
   * Gets page end range using array length and page size
   * @function
   */
  getEndRange() {
    return this.state.prospect_active_page * this.state.prospect_row_size > this.state.prospect_length
      ? this.state.prospect_length
      : this.state.prospect_active_page * this.state.prospect_row_size;
  }

  /**
   * Sorts the prospect list based on event listener and value
   * @param {Object} event - Button event listener
   * @function
   */
  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const filter = this.state.search_filter || this.state.status_filter;
    const selection = this.state.selection;
    let prospect_sort_order_ascending = this.state.prospect_sort_order_ascending;
    if (sort_method === this.state.prospect_sort_method) {
      //Flip order
      prospect_sort_order_ascending[sort_method] = !prospect_sort_order_ascending[sort_method];
    }

    let prospect_view = this.getProspectListFromRow(
      this.setOrderByMethod(
        this.getProspectListFromSelection(
          this.getProspectListFromFilter(this.getProspectListFromSearch(this.state.prospect_raw, this.state.search), filter),
          selection
        ),
        sort_method,
        prospect_sort_order_ascending[sort_method]
      ),

      this.state.prospect_active_page,
      this.state.prospect_row_size
    );

    this.setState({
      prospect_view: prospect_view,
      prospect_sort_method: sort_method,
      prospect_sort_order_ascending: prospect_sort_order_ascending,
    });
  };

  /**
   * Sorts the prospect list based sort type method
   * @param {List} prospect_view - List array of all the prospects
   * @param {String} sort_method - Sort method
   * @param {String} sort - Sort type
   * @function
   */
  setOrderByMethod(prospect_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'prospect_name':
        prospect_view = this.orderProspectListByName(prospect_view, sort_value);
        break;

      case 'created_date':
        prospect_view = this.orderProspectListBySubmissionDate(prospect_view, sort_value);
        break;

      case 'city':
        prospect_view = this.orderProspectListByCity(prospect_view, sort_value);
        break;

      case 'prospect_status':
        prospect_view = this.orderProspectListByStatus(prospect_view, sort_value);
        break;
      default:
    }

    return prospect_view;
  }

  /**
   * Returns sort type
   * @param {String} sort - Sort type
   * @function
   */
  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  /**
   * Returns ordered list of prospects
   * @param {List} prospect_list - List of prospects
   * @function
   */
  orderProspectListByDefault(prospect_list) {
    const sort = this.getSortValue(this.state.prospect_sort_order_ascending['created_date']);
    return this.orderProspectListBySubmissionDate(prospect_list, sort);
  }
  /**
   * Returns ordered list of prospects by name
   * @param {List} prospect_list - List of prospects
   * @param {String} sort - Sort type
   * @function
   */
  orderProspectListByName(prospect_list, sort) {
    return _.orderBy(prospect_list, ['first_name', 'last_name', 'created_date'], [sort, sort, getSubmissionDateDefaultSort()]);
  }
  /**
   * Returns ordered list of prospects by submission date
   * @param {List} prospect_list - List of prospects
   * @param {String} sort - Sort type
   * @function
   */
  orderProspectListBySubmissionDate(prospect_list, sort) {
    return _.orderBy(prospect_list, ['created_date', this.getStatusSortFunction()], [sort]);
  }

  /**
   * Returns ordered list of prospects by city name
   * @param {List} prospect_list - List of prospects
   * @param {String} sort - Sort type
   * @function
   */
  orderProspectListByCity(prospect_list, sort) {
    return _.orderBy(prospect_list, ['city', 'created_date'], [sort, sort]);
  }

  /**
   * Returns ordered list of prospects by status
   * @param {List} prospect_list - List of prospects
   * @param {String} sort - Sort type
   * @function
   */
  orderProspectListByStatus(prospect_list, sort) {
    return _.orderBy(prospect_list, [this.getStatusSortFunction(), 'created_date'], [sort, sort]);
  }

  /**
   * Returns function for sorting by prospect status
   * @returns {function}  - Sorting function
   * @function
   */
  getStatusSortFunction = () => {
    const that = this;
    return function (p) {
      return p.prospect_status in that.state.status_friendly_name ? that.state.status_friendly_name[p.prospect_status] : p.prospect_status;
    };
  };

  /**
   * Returns ordered list of prospects using filters
   * @param {List} prospect - List of prospects
   * @param {String} filter_phrase - Phrase for the filter
   * @function
   */
  getProspectListFromFilter(prospect, filter_phrase) {
    if (filter_phrase === '') {
      return prospect;
    }
    let prospect_list = [];
    for (let i = 0; i < prospect.length; i++) {
      if (_.intersection(prospect[i].filter_codes, [filter_phrase]).length > 0) {
        prospect_list.push(prospect[i]);
      }
    }

    return prospect_list;
  }

  /**
   * Returns ordered list of prospects using selections
   * @param {List} prospect - List of prospects
   * @param {String} selection_phrase - Phrase for the selection
   * @function
   */
  getProspectListFromSelection(prospect, selection_phrase) {
    return prospect;
  }

  /**
   * Returns prospect details
   * @param {Object} event - Click event
   * @function
   */
  onCaseTrClick = (event) => {
    if (event.target.dataset.additionalactions || this.props.additional_actions_modal || this.props.note_modal) return;

    const prospect_id = event.currentTarget.dataset.id;
    let rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/prospect/edit/${prospect_id}`;
    tableRowLink(event, pathname, this.props, '', {});
    this.props.clearProspectsList();
  };

  /**
   * Sets search filter selection
   * @param {Object} option - Selection option of the dropdown
   * @function
   */
  onSearchFilterSelection(option) {
    this.setRouteBySearchFilter(option.value);
  }
  /**
   * Converts filter name to a readable title
   * @function
   * @return {String} String version of filter
   */
  filterToTitle(filter) {
    const titles = {
      action: 'Action Required',
      schedule: 'Schedule Consultation',
      consult: 'Complete Consultation',
      begin_submission: 'Begin Submission',
      hold: 'On Hold',
      submitted: 'Submitted',
      lost: 'Prospect Lost',
    };

    if (titles[filter]) {
      return titles[filter];
    }

    return filter;
  }
  /**
   * Displays search bar
   * @function
   */
  displaySearchBar() {
    const options = [
      { label: 'All Status', value: '' },
      { label: 'Schedule Consultation', value: 'schedule' },
      { label: 'Complete Consultation', value: 'consult' },
      { label: 'Begin Submission', value: 'begin_submission' },
      { label: 'On Hold', value: 'hold' },
      { label: 'Submitted', value: 'submitted' },
      { label: 'Prospect Lost', value: 'lost' },
    ];

    const getDisplayLabel = (value) => {
      const selected_option = options.filter((option) => {
        return option.value === value;
      });

      return selected_option && selected_option.length > 0 ? selected_option : options[0];
    };

    return (
      <div className="search-container">
        <div className="search">
          <TextBox
            placeholder="Search"
            onChange={this.onChangeSearchPatient}
            onKeyPress={this.onKeyPressSearchPatient}
            value={this.state.search_phrase}
            icon={'fa fa-search'}
            onIconClick={this.onSearchButtonClick}
          ></TextBox>
        </div>
        <Dropdown value={getDisplayLabel(this.state.search_filter)} onChange={this.onSearchFilterSelection} options={options} />
      </div>
    );
  }

  /**
   * Redirect to create prospect page on click of the anchor
   * @function
   */
  redirectToAddProspectPage = () => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/prospect/add`;
    this.props.history.push(pathname);
  };

  /**
   * Displays add new prospect link
   * @function
   */
  displayAddProspect = () => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) ? (
            <Button className="float--right" onClick={this.redirectToAddProspectPage}>
              <i className="fa fa-plus-square" aria-hidden="true" /> Create New Prospect
            </Button>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  getSubmissionDate = (props, data) => {
    return <div className="div-table--item-restrict-text">{convertDate(data.created_date) ? convertDate(data.created_date) : '- - - - -'}</div>;
  };

  /**
   * Displays prospect status
   * @function
   * @param {object} props - Props object
   * @param {object} data - Prospect object
   * @param {list} fields - List of prospect fields
   * @param {boolean} is_mobile - Mobile view indicator
   * @returns {JSX} - JSX for status
   */
  getStatus = (props, data, fields, is_mobile = false) => {
    const field_values = fields.map((field) => {
      return data[field] ? data[field] : field.includes('status') ? '- - - - -' : false;
    });
    const status = field_values[0];
    const badge_class = `badge--status badge--${field_values[2] ? 'primary' : 'default'}`;

    return (
      <div className={`div-table--item-restrict-text div-table--item-restrict-text--${field_values[2] ? 'primary' : 'default'}`}>
        {status === 'Hold' || status === 'Prospect Lost' ? (
          <span className={badge_class} data-toggle="tooltip" title={truncateTextByLimit(field_values[1], 200)}>
            {status in this.state.status_friendly_name ? this.state.status_friendly_name[status] : status}{' '}
            {is_mobile && `${field_values[1] !== 'Data Migration' ? '- "' + field_values[1] + '"' : ''}`}
          </span>
        ) : (
          <span className={badge_class}>{status in this.state.status_friendly_name ? this.state.status_friendly_name[status] : status}</span>
        )}
      </div>
    );
  };

  /**
   * Retrieves most recent patient note to display for prospect
   * @function
   * @param {object} props - Props object
   * @param {object} data - Prospect object
   * @returns {JSX} - JSX for patient note
   */
  getNote = (props, data) => {
    let note = { id: '', subject: '', text: '', date: '', created_by_id: '' };
    if (data.latest_note_info) {
      note = JSON.parse(data.latest_note_info);
      note.text = data.latest_note_text;
      note.subject = data.latest_note_subject;
    }
    const note_text = note.id && note.text ? convertHtmlToPlainText(note.text) : '';

    return (
      <div className="div-table--item-notes">
        <div className={`div-table--item-notes-content${!note?.id ? ' div-table--item-notes-content--not-applicable' : ''}`}>
          {note?.id && (
            <div className="note-tooltip" id={data.id} data-toggle="tooltip" data-placement="top">
              <div className="restrict-text-single-row row-label">
                {displayPSTDateTime(note.date)}
                {note.subject ? ` - ${note.subject.toUpperCase()}` : ''}
              </div>
              <div className="restrict-text" ref={(ref) => setOverflowTooltip(ref, data.id, note_text)}>
                {note_text}
              </div>
            </div>
          )}
        </div>
        {this.displayAdditionalActions(data, note)}
      </div>
    );
  };

  /**
   * Displays additional actions menu
   * @function
   * @param {object} data - Selected prospect
   * @param {object} note - Most recent prospect note
   * @returns {JSX} - JSX for additional actions
   */
  displayAdditionalActions = (data, note) => {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <AdditionalActionsDropdown
              id={data.id.toString()}
              note={note}
              patient_name={`${data.first_name} ${data.last_name}`}
              is_prospect={true}
              refreshList={this.updateProspectListWithNote}
              updateNote={this.props.updateProspectNote}
              user_roles_and_permissions={user_roles_and_permissions}
              can_view_plan={false}
              viewAllNotesRedirect={this.viewAllNotesRedirect}
            />
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Updates prospect list with new note
   * @function
   * @param {string} prospect_id - Prospect id
   * @param {object} note - Updated note
   */
  updateProspectListWithNote = (prospect_id, note) => {
    let prospect_updates = _.cloneDeep(this.state.prospect_raw);
    let prospect = prospect_updates.find((p) => p.id.toString() === prospect_id.toString());
    if (prospect) {
      prospect.latest_note_info = JSON.stringify({
        date: note.modified_date,
        id: note.id,
        created_by_id: note.created_by_id,
      });
      prospect.latest_note_subject = note.subject;
      prospect.latest_note_text = note.note_text;
      this.setState({ prospect_raw: prospect_updates, note_updated: true });
    }
  };

  /**
   * Enables tooltip on hover
   * @function
   */
  onHover = () => {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
  };

  /**
   * Redirects to prospect details from additional actions menu
   * @function
   * @param {string} prospect_id - Prospect id
   */
  viewAllNotesRedirect = (prospect_id) => {
    let rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/prospect/edit/${prospect_id}`;
    this.props.history.push(pathname);
    this.props.clearProspectsList();
  };

  /**
   * Retrieves class name for table cell
   * @function
   * @param {object} data - Prospect object
   * @param {heading} heading - Header object
   * @returns {string} - Class name
   */
  getClassName = (data, heading) => {
    if (heading.id === 'notes') {
      return 'div-table--item-multiple-rows';
    }
    return 'div-table--item-single-row';
  };

  render() {
    const that = this;
    const meta_data = ['id', 'first_name', 'last_name', 'created_date', 'prospect_status'];
    const table_data = [
      {
        id: 'prospect_name',
        label: 'Prospect',
        col_width: '20%',
        display: true,
        can_sort: true,
        data_fields: ['first_name', 'last_name'],
        get_class_name: that.getClassName,
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? data[field] : '';
          });
          return <div className="div-table--item-restrict-text">{field_values.join(' ')}</div>;
        },
      },
      {
        id: 'created_date',
        label: 'Created',
        col_width: '13%',
        display: true,
        can_sort: true,
        data_fields: ['created_date'],
        get_class_name: that.getClassName,
        data_function: (props, data, fields) => {
          return that.getSubmissionDate(props, data);
        },
      },
      {
        id: 'city',
        label: 'City',
        col_width: '16%',
        display: true,
        can_sort: true,
        data_fields: ['city', 'action_required', 'filter_codes'],
        get_class_name: that.getClassName,
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? data[field] : false;
          });
          return (
            <>
              <div className="div-table--item-restrict-text">{field_values[0]}</div>
            </>
          );
        },
      },
      {
        id: 'prospect_status',
        label: 'Status',
        col_width: '20%',
        display: true,
        can_sort: true,
        data_fields: ['prospect_status', 'prospectstatus__status_comment', 'action_required'],
        get_class_name: that.getClassName,
        data_function: that.getStatus,
      },
      {
        id: 'notes',
        label: 'Notes',
        col_width: '29%',
        display: true,
        can_sort: false,
        data_fields: [],
        get_class_name: that.getClassName,
        data_function: that.getNote,
      },
    ];

    const mobile_data = [
      {
        id: 'prospect_name_created_date',
        data_fields: ['first_name', 'last_name'],
        data_function: (props, data, fields) => {
          let field_values = fields.map((field, index) => {
            return data[field];
          });

          field_values.splice(0, 0, '<span class="emphasis">');
          field_values.splice(3, 0, '</span>');

          return <div dangerouslySetInnerHTML={{ __html: field_values.join(' ') }} />;
        },
      },
      {
        id: 'city',
        data_fields: ['city'],
      },
      {
        id: 'prospect_status',
        data_fields: ['prospect_status', 'prospectstatus__status_comment', 'action_required'],
        data_function: (props, data, fields) => that.getStatus(props, data, fields, true),
      },
    ];

    if (
      (this.state.prospect_view.length === 0 && this.state.search && this.state.search !== '') ||
      (this.state.prospect_view.length === 0 && this.state.search_filter && this.state.search_filter !== '') ||
      (this.state.prospect_view.length === 0 && this.state.status_filter && this.state.status_filter !== '')
    ) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['IPP_PROSPECT'], user_roles_and_permissions.permissions) ? (
              <>
                <Helmet>
                  <title>
                    Prospect List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <ContentHeader title="Prospect List">{this.displayAddProspect()}</ContentHeader>
                <div className="list-content">
                  <ProspectFilter search={this.displaySearchBar()} statuses={this.state.statuses} />
                  <div className="statuses-result-container">
                    <div className="statuses-result">
                      {this.state.search || this.state.search_filter ? (
                        <NoResult
                          query={this.state.search}
                          filter={
                            this.state.search_filter
                              ? this.state.search_filter_name[this.state.search_filter]
                              : this.state.status_filter
                              ? this.state.search_filter_name[this.state.status_filter]
                              : 'All Prospects'
                          }
                        />
                      ) : (
                        <NoRecords />
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    } else if (
      this.state.prospect_view.length === 0 &&
      (this.state.search === '' || this.state.search === undefined) &&
      this.state.filter === '' &&
      this.state.status_filter === ''
    ) {
      return this.props.prospect_list_pending ? (
        <CircleLoader fullscreen={true} />
      ) : (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['IPP_PROSPECT'], user_roles_and_permissions.permissions) ? (
              <>
                <Helmet>
                  <title>
                    Prospect List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <CardContainer type="message">
                  <h3>No prospect found</h3>
                  <p>You can start by creating your first prospect</p>
                  <div className="block--right">
                    <Button onClick={this.redirectToAddProspectPage}>Create Prospect</Button>
                  </div>
                </CardContainer>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    } else {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return userHasPermissions(['IPP_PROSPECT'], user_roles_and_permissions.permissions) ? (
              <>
                <Helmet>
                  <title>
                    Prospect List
                    {this.state.search ? ' - ' + this.state.search : this.state.status_filter ? ' - ' + this.filterToTitle(this.state.status_filter) : ''} |
                    InBrace Smile Design Studio™
                  </title>
                </Helmet>
                <ContentHeader title="Prospect List">{this.displayAddProspect()}</ContentHeader>
                <div className="list-content">
                  <ProspectFilter search={this.displaySearchBar()} statuses={this.state.statuses} />
                  <div className="statuses-result-container">
                    <div className="statuses-result">
                      <CardContainer type="top-border" className="hide-on-mobile--top-border">
                        <DivTable
                          meta_data={meta_data}
                          table_data={table_data}
                          mobile_data={mobile_data}
                          field_data={this.state.prospect_view}
                          sort_method={this.state.prospect_sort_method}
                          sort_order_ascending={this.state.prospect_sort_order_ascending}
                          showCancelCase={this.state.showCancelCase}
                          selected_case_id={this.state.selected_prospect_id}
                          onSortClick={this.onSortClick}
                          onCaseTrClick={this.onCaseTrClick}
                          onMouseOver={this.onHover}
                        />
                      </CardContainer>
                    </div>
                    <div className="pagination__container">
                      <div className="pagination__number">
                        {this.getStartRange()}-{this.getEndRange()} of {this.state.prospect_length} Prospects
                      </div>
                      <Pagination
                        className="pagination"
                        activePage={this.state.prospect_active_page}
                        itemsCountPerPage={this.state.prospect_row_size}
                        totalItemsCount={this.state.prospect_length}
                        pageRangeDisplayed={this.state.prospect_page_range}
                        onChange={this.onPageChange}
                        prevPageText={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                        nextPageText={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                        firstPageText={<i className="fa fa-angle-double-left" aria-hidden="true"></i>}
                        lastPageText={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null;
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

ProspectList.propTypes = {
  doctor_role: PropTypes.string.isRequired,
  account_link_id: PropTypes.number.isRequired,
  prospect_list: PropTypes.array.isRequired,
  status_count: PropTypes.object.isRequired,
  prospect_list_pending: PropTypes.bool.isRequired,
  prospect_list_error: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    doctor_role: getDoctorRole(state),
    account_link_id: getAccountLinkId(state),
    prospect_list: getProspects(state),
    status_count: getStatusCount(state),
    prospect_list_pending: getPending(state),
    prospect_list_error: getError(state),
    additional_actions_modal: getAdditionalActionsModal(state),
    note_modal: getNoteModal(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProspectsList: fetchProspectsList,
      clearProspectsList: clearProspectsList,
      updateProspectNote: updateProspectNote,
      setNoteModal: setNoteModal,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProspectList));
