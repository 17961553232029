import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays clinical condition buttons
 * @component
 */
function ClinicalConditions(props) {
  return (
    <div className="clinical-conditions">
      {Object.entries(props.conditions).map(([key, item]) => {
        return (
          <button
            key={key}
            type="button"
            className={'btn-ib' + (item.active ? ' btn-ib--active' : '')}
            onClick={() => props.setConditionState(key, { active: !item.active })}
            disabled={props.disabled}
          >
            {item.title}
          </button>
        );
      })}
    </div>
  );
}

ClinicalConditions.propTypes = {
  conditions: PropTypes.object.isRequired,
  setConditionState: PropTypes.func.isRequired,
};

export default ClinicalConditions;
