import { PROGRAM_LEVELS } from '../constants/programs';

/**
 * Checks if a program is in full release.
 * @param {Object} program - The program object to check.
 * @returns {boolean} - Returns true if the program is in full release, false otherwise.
 */
export const isProgramInFullRelease = (program) => {
  if (!program) return false;

  return program.levels?.current ? program.levels.current === PROGRAM_LEVELS.FULL_RELEASE : true;
};
