import React from 'react';

/**
 * Displays the bpp case list sidebar top dropdown
 * @component
 * @category BPP
 */
function CaseListDropdown(props) {
  const { selected, dropdown_list } = props;

  const selected_dropdown = dropdown_list.find((ele) => ele.key === selected);

  return (
    <div className="dropdown">
      <div className="sidebar__case_select" data-toggle="dropdown" aria-haspopup aria-expanded="false" type="button">
        {selected_dropdown.text} <i className="fa fa-chevron-down pull-right" aria-hidden="false" />
      </div>
      <ul className="dropdown-menu bpp-dropdown-menu-round">
        {dropdown_list.map((item) => (
          <li key={item.key} onClick={item.onClick}>
            <span>{item.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CaseListDropdown;
