import Axios from 'axios';

export const FETCH_CASE_FILES_START = 'FETCH_CASE_FILES_START';
export const FETCH_CASE_FILES_SUCCESS = 'FETCH_CASE_FILES_SUCCESS';
export const FETCH_CASE_FILES_ERROR = 'FETCH_CASE_FILES_ERROR';

export function fetchCaseFileData(case_id, all_cases, temp_link = false) {
  return (dispatch) => {
    dispatch(fetchCaseFilesStart());
    const case_type = all_cases || false;

    Axios.get(`/apiv3/casefiledetails/${case_id}?all_cases=${case_type}${temp_link ? '&temp_link=true' : ''}`)
      .then((res) => {
        dispatch(fetchCaseFilesSuccess(res.data));
      })
      .catch((err) => dispatch(fetchCaseFilesError(err)));
  };
}

export function fetchCaseFilesStart() {
  return {
    type: FETCH_CASE_FILES_START,
  };
}

export function fetchCaseFilesSuccess(result) {
  return {
    type: FETCH_CASE_FILES_SUCCESS,
    result: result,
  };
}

export function fetchCaseFilesError(error) {
  return {
    type: FETCH_CASE_FILES_ERROR,
    error: error,
  };
}
