// Action Types
export const SET_FEATURE_ANNOUNCEMENT_MODAL = 'SET_FEATURE_ANNOUNCEMENT_MODAL';
export const SET_SMILE_DESIGN_PREFERENCES_MODAL = 'SET_SMILE_DESIGN_PREFERENCES_MODAL';

// Action Creators
// -----------------------------------------------------------------------------
export function setFeatureAnnouncementModal(show) {
  return {
    type: SET_FEATURE_ANNOUNCEMENT_MODAL,
    feature_announcement_modal: show,
  };
}

export function setSmileDesignPreferencesModal(show) {
  return {
    type: SET_SMILE_DESIGN_PREFERENCES_MODAL,
    smile_design_preferences_modal: show,
  };
}
