import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a single IPR bubble
 * @component
 */
function IPRBubble(props) {
  const invisible = props.value === '';

  return <div className={`ipr-bubble ${props.className} ${invisible ? 'invisible' : ''}`}>{props.value}</div>;
}

IPRBubble.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default IPRBubble;
