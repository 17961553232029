import './active_cases.scss';
import React, { Component } from 'react';
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../common/functions';
import CardContainer from '../../doctor/components/container/card_container';
import { Exc } from '../../doctor/assets/icons';

/**
 * Displays active cases and item requests when a user attempts to disable an account or practice. *
 *
 * @component
 * @alias ActiveCases
 * @category BPP
 */
class ActiveCases extends Component {
  componentDidMount() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }
  render() {
    return (
      <div className="active-cases">
        {this.props.is_edit_address ? (
          <div className={'p-slim grey-text'}>
            Unable to deactivate this practice because it is linked to active cases below. Please reassign cases below to another practice in the Case Details
            page before deactivating this practice.
          </div>
        ) : (
          <div className={'p-slim grey-text'}>Unable to deactivate this account because it is linked to active cases below.</div>
        )}
        <CardContainer type="top-border" className="case-table">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Patient</th>
                {this.props.is_dso && <th>Assigned To</th>}
              </tr>
            </thead>
            <tbody>
              {this.props.active_cases.map((cases, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span>
                        <span>{removeCaseIdInitialNumber(removeIreqIdInitialNumber(cases.id))}</span>
                        {cases.shipping_approved_ind && (
                          <span data-toggle="tooltip" data-placement="top" title={`This case has been approved for shipping to: ${cases.shipping_address}`}>
                            <Exc className="alert--icon" />
                          </span>
                        )}
                      </span>
                    </td>
                    <td>
                      {cases.first_name} {cases.last_name}
                    </td>
                    {this.props.is_dso && <td>{cases.assigned_to}</td>}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardContainer>
      </div>
    );
  }
}

export default ActiveCases;
