/**
 * File:quantity.js - Component to input item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileUploadUrlInterface from '../../components/file_upload/file_upload_url_interface';

class PrUpload extends Component {
  render() {
    return (
      <div className="bpp-portal-view">
        <div className="ir-submission-heading">
          <h4 className="bold-text">Upload Progress Record - Step 1 of 3</h4>
        </div>

        <form>
          <FileUploadUrlInterface
            id={this.props.id}
            filesUploaded={this.props.photoUpload}
            onUpload={this.props.onUpload}
            onRemove={this.props.onRemove}
            onIncompleteSave={this.props.onIncompleteSave}
            onEmptyFileError={this.props.onEmptyFileError}
            multiple={true}
            location="progress_record"
            folder="progress_records"
            type="progress_records"
            upload_state={this.props.upload_state}
            upload_content={this.props.upload_content}
            show_warning={this.props.show_warning}
            hide_warning={this.props.hide_warning}
            mode={this.props.mode}
            loader_type={'bpp'}
            theme={'bpp'}
            removeAllowed={true}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(PrUpload);
