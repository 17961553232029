/**
 * File: add_account.js - Ability to add a new doctor or bpp account
 * Copyright: (c) Copyright December 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel, Ravi Gosai
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './add_account.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import Moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { Helmet } from 'react-helmet';
import { parsePhoneNumber } from 'libphonenumber-js';
import { withRouter } from 'react-router-dom';

// Internal Components
import AddAddress from './add_address';
import AddEmail from './add_email';
import AddTelephoneNumber from './add_telephone_number';
import Datalist from '../../components/form/list/datalist';
import Modal from '../../components/modal/modal';
import TextInput from '../../components/form/text/text_input';
// Internal Functions
import ValidationUtils from '../../common/validation/validation-utils';
import { firstLetterCapital, formatState, npiNumberChar, removeSpaces, isValidEmail, removeEmoji } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import Programs from './programs';
// Internal Constants
import { UNITED_STATES } from '../../common/constants/countries';

// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

// Redux
import { filterUserRoles } from '../../redux/actions/bpp/account/add_account';

/**
 * Used to account details and also perform actions like add edit accounts on the BPP.
 * *
 * @component
 * @alias BusinessAccountDetails
 * @category BPP
 */
class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_email: '',
      current_user: true,
      edit_id: '',
      doctor_data: null,
      first_name_error: false,
      last_name_error: false,
      first_name_limit_error: false,
      last_name_limit_error: false,
      missing_email_error: false,
      missing_npi_error: false,
      invalid_email_error: false,
      account_error: false,
      wrongPasswordError: '',
      min_email_error: false,
      already_existing_email_error: false,
      dso_name_exists_error: false,
      email_limit_error: false,
      address_error: false,
      bpp_creation_error: false,
      support_error: false,
      erp_error: false,
      erp_error_info: null,
      doctor_programs: [],
      modals: {
        showAccountConfirmationModal: false,
        accountCreationPendingModal: false,
      },
      user_id: '',
      user: {
        first_name: '',
        last_name: '',
        account_email: '',
        use_as_email_notification: true,
        current_user: false,
        provider: true,
        loginEmail: '',
        notificationEmailList: [],
        notificationAddressList: [],
        phoneNumberList: [],
      },
      selectedEmail: null,
      selectedPhoneNumber: null,
      selectedAddress: null,
      loaded: true,
      account_type: '',
      profile: {
        account_id: '',
        first_name: '',
        last_name: '',
        role: '',
        account_email: '',
        notification_emails: [],
        phone_numbers: [],
        shipping_addresses: [],
        cases: [],
        gen2_flag: false,
        referral_flag: false,
        referral_email: '',
        smile_flag: false,
      },
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
      new_profile: {
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: UNITED_STATES,
        country_code: '1',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
      isReferralChecked: false,
      isSmileChecked: false,
      isGen2Checked: false,
      selectedPhone: null,
      user_roles: [],
      selected_user_roles: [],
      selected_user_roles_error: false,
      user_types: [],
      selected_user_types: [],
      permission_list: [],
      all_rae: [],
      selected_rae: '',
      all_cst: [],
      selected_cst: '',
      all_css: [],
      selected_css: '',
      bpp_click: false,
      doctor_click: false,
      dso_click: false,
      referral_email: '',
      referral_email_error: false,
    };
    this.submitform = this.submitform.bind(this);
    this.onfnChange = this.onfnChange.bind(this);
    this.onlnChange = this.onlnChange.bind(this);
    this.onInputKeyUp = this.onInputKeyUp.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
    this.onAddProfileItemClick = this.onAddProfileItemClick.bind(this);
    this.onEditProfileToggleAccount = this.onEditProfileToggleAccount.bind(this);
    this.emailBlur = this.emailBlur.bind(this);
    this.onNameBlur = this.onNameBlur.bind(this);
    this.onFeatureChange = this.onFeatureChange.bind(this);
    this.onFeatureConfirm = this.onFeatureConfirm.bind(this);
    this.onProgramConfirm = this.onProgramConfirm.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onChangeReferralEmail = this.onChangeReferralEmail.bind(this);
    this.onChangeReferralEmailKeyPress = this.onChangeReferralEmailKeyPress.bind(this);
    this.updateReferralEmail = this.updateReferralEmail.bind(this);
  }

  onEditProfileToggleAccount(event) {
    this.setState({
      current_user: event.target.checked,
    });
  }

  getLatestNumberId() {
    let id = '';

    // Data pass from backend is sorted by created date
    if (this.state.profile && this.state.profile.phone_numbers && this.state.profile.phone_numbers.length > 0) {
      id = this.state.profile.phone_numbers[this.state.profile.phone_numbers.length - 1].id;
    }

    return id;
  }

  isNewPhoneNo(value, id = '') {
    const phones = this.state.profile.phone_numbers;
    let is_new = true;

    for (let i = 0; i < phones.length; i++) {
      if (phones[i].value === value) {
        if (phones[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  isNewEmail(value, id = '') {
    const emails = this.state.profile.notification_emails;
    let is_new = true;

    for (let i = 0; i < emails.length; i++) {
      if (emails[i].value === value) {
        if (emails[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  isNewAddress(profile, id = '') {
    const address = this.state.profile.shipping_addresses;
    let is_new = true;

    for (let i = 0; i < address.length; i++) {
      if (
        address[i].address_line_1 === profile.address_line_1 &&
        address[i].address_line_2 === profile.address_line_2 &&
        address[i].city === profile.city &&
        address[i].zip === profile.zip
      ) {
        if (address[i].id === id) {
          //Do nothing
        } else {
          is_new = false;
          break;
        }
      }
    }

    return is_new;
  }

  formatValueByMode(mode, value) {
    let new_value = value;

    switch (mode) {
      case 'add_number':
      case 'edit_number':
        const usa_number = parsePhoneNumber(value, 'US');
        new_value = usa_number.format('NATIONAL');
        break;
      default:
    }

    return new_value;
  }

  getAddActionByMode(mode) {
    let action = mode;

    switch (mode) {
      case 'add_number':
      case 'add_email':
        action = mode;
        break;
      default:
    }

    return action;
  }

  isValidPhoneNo(value) {
    let is_valid = false;

    try {
      const usa_number = parsePhoneNumber(value, 'US');
      const valid_number = ValidationUtils.isValidPhoneNo(value);

      if (valid_number && usa_number.isValid()) {
        is_valid = true;
      }
    } catch (error) {
      // Do Nothing
    }

    return is_valid;
  }

  onChangeAccountType = (event) => {
    if (event.target.value) {
      this.setState({ account_error: false });
    }
    this.setState({ account_type: event.target.value });
  };

  onNewProfileCancel = (event) => {
    this.setState({
      new_profile: {
        active: false,
        error: false,
        mode: '',
        notification: false,
        type: '',
        value: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: UNITED_STATES,
        country_code: '1',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  onEditProfileCancel = (event) => {
    const doctor_programs = _.cloneDeep(this.state.doctor_programs);
    doctor_programs.forEach((program) => {
      program.checked = program.enrolled;
      if (program.levels) {
        program.levels.checked = program.levels.enrolled;
      }
    });
    this.setState({
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
      doctor_programs: doctor_programs,
      isReferralChecked: this.state.profile.referral_flag,
      isGen2Checked: this.state.profile.gen2_flag,
      referral_email: this.state.profile.referral_email,
      referral_email_error: false,
      isSmileChecked: this.state.profile.smile_flag,
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  onAddProfileItemClick = (event) => {
    let address_error = this.state.address_error;
    if (event.target.dataset.mode === 'add_address') {
      address_error = false;
    }
    if (!this.state.edit_profile.active && !this.state.new_profile.active) {
      this.setState(
        {
          address_error: address_error,
          new_profile: {
            active: true,
            error: false,
            mode: event.target.dataset.mode,
            notification: true,
            type: 'office',
            value: '',
            address_line_1: '',
            address_line_2: '',
            address_telephone: '',
            city: '',
            state: '',
            zip: '',
            country: UNITED_STATES,
            country_code: '1',
            website: '',
            facebook_url: '',
            instagram_url: '',
            address_email: '',
            is_active: true,
            shipping_only: false,
            is_doctor_loc: false,
          },
          erp_error: false,
        },
        function () {
          const new_address = document.querySelector('#new_address');
          if (new_address) {
            new_address.scrollIntoView();
          }
        }
      );
    }
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  onFeatureConfirm(event) {
    const referral = this.state.isReferralChecked;
    const gen2 = this.state.isGen2Checked;
    const referral_email = referral ? this.state.referral_email : '';
    const smile = this.state.isSmileChecked;

    if (isValidEmail(referral_email) || !referral) {
      this.setState((prevState) => ({
        profile: {
          ...prevState.profile,
          referral_flag: referral,
          gen2_flag: gen2,
          referral_email: referral_email,
          smile_flag: smile,
        },
        referral_email: referral_email,
      }));
      this.setState({
        edit_profile: {
          id: '',
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
        },
      });
      window.$('[data-toggle="tooltip"]').tooltip('hide');
    } else {
      this.setState({
        referral_email_error: true,
      });
    }
  }

  onFeatureChange(event) {
    if (event.target.name === 'referral') {
      this.setState({ isReferralChecked: event.target.checked, referral_email_error: false });
    } else if (event.target.name === 'smile') {
      this.setState({ isSmileChecked: event.target.checked });
    } else {
      this.setState({ isGen2Checked: event.target.checked });
    }
  }

  onEditClick(event) {
    const id = parseInt(event.currentTarget.dataset.id);
    const mode = event.currentTarget.dataset && event.currentTarget.dataset.mode ? event.currentTarget.dataset.mode : '';
    const type = event.currentTarget.dataset && event.currentTarget.dataset.type ? event.currentTarget.dataset.type.toLowerCase() : '';
    const value = event.currentTarget.dataset.value;
    const notification = event.currentTarget.dataset.notification === 'true';
    const permission_roles = this.state.profile.permission_roles;
    const types = this.state.profile.types;
    if (!this.state.edit_profile.active && !this.state.new_profile.active && mode) {
      this.setState({
        edit_profile: {
          active: true,
          error: false,
          id: id,
          mode: mode,
          notification: notification,
          type: type,
          value: value,
          permission_roles: permission_roles,
          types: types,
          referral: this.state.profile.referral,
        },
        name_error: false,
        in_progress: false,
        referral_email: this.state.profile.referral_email,
      });
    }
  }

  onChangeReferralEmail = (event) => {
    this.setState({
      referral_email_error: false,
      referral_email: removeEmoji(event.target.value),
    });
  };

  onChangeReferralEmailKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13) {
      let confirm = document.getElementById('referral-confirm');

      if (confirm) {
        confirm.click();
      }
    }
  }

  onChangeNewProfileType = (event) => {
    const type = event.target.value;

    this.setState({
      new_profile: {
        active: true,
        error: false,
        mode: this.state.new_profile.mode,
        notification: this.state.new_profile.notification,
        type: type,
        value: this.state.new_profile.value,
        address_line_1: this.state.new_profile.address_line_1,
        address_line_2: this.state.new_profile.address_line_2,
        address_telephone: this.state.new_profile.address_telephone,
        city: this.state.new_profile.city,
        state: this.state.new_profile.state,
        zip: this.state.new_profile.zip,
        country: this.state.new_profile.country,
        website: this.state.new_profile.website,
        facebook_url: this.state.new_profile.facebook_url,
        instagram_url: this.state.new_profile.instagram_url,
        address_email: this.state.new_profile.address_email,
        is_active: this.state.new_profile.is_active,
        shipping_only: this.state.new_profile.shipping_only,
        is_doctor_loc: this.state.new_profile.is_doctor_loc,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  changeRoute() {
    if (this.props.history.state) {
      this.props.history.push(this.props.location.state.previousUrl);
    } else {
      this.props.history.push('/business/portal/accounts');
    }
  }

  /**
   * Queries database and checks if user email already exists
   * @function
   * @param {string}
   * @return {boolean}
   */
  isEmailExist(email) {
    var _this = this;
    Axios.get(`/apiV2/createaccount?email=${email.toLowerCase()}`).then((res) => {
      if (res.data.already_existing_email_error) {
        _this.setState({ already_existing_email_error: true });
        return true;
      } else {
        return false;
      }
    });
  }

  isDsoNameExist(dso_name) {
    var _this = this;
    Axios.get(`/apiV2/createaccount?dso_name=${dso_name}`).then((res) => {
      if (res.data.dso_name_exists_error) {
        _this.setState({ dso_name_exists_error: true });
        return true;
      } else {
        _this.setState({ dso_name_exists_error: false });
        return false;
      }
    });
  }

  /**
   * update referral email in db
   * @function
   */
  updateReferralEmail(doctor_id) {
    const that = this;
    const opt_in = that.state.profile.referral_flag;
    const referral_email = that.state.profile.referral_email;

    if (isValidEmail(referral_email) && opt_in) {
      const action = 'update_email';
      const data = { referral_email: referral_email };
      Axios.put(`/apiv3/doctor/${doctor_id}/referral/${action}`, data).catch(function (err) {
        handleHttpRequestError(err, that);
      });
    }
  }

  /**
   * Submits the data to api to create or edit the account details
   * @function
   */
  submitform() {
    var errors = false;
    let last_name = '';
    if (!this.firstName.value) {
      this.setState({ first_name_error: true });
      errors = true;
    }
    if (this.state.account_type === 'DSO' && this.state.dso_name_exists_error) {
      return false;
    }
    if (this.state.account_type !== 'DSO') {
      if (!this.lastName.value) {
        this.setState({ last_name_error: true });
        errors = true;
      } else {
        last_name = this.lastName.value;
      }
    } else {
      last_name = 'Admin';
    }

    if (!this.email.value) {
      this.setState({ missing_email_error: true });
      errors = true;
    } else if (this.state.invalid_email_error || this.state.already_existing_email_error) {
      errors = true;
    }

    if (this.state.account_type === 'Doctor') {
      if (!this.npi_number.value) {
        this.setState({ missing_npi_error: true });
        errors = true;
      }
    }

    if (this.state.facebook_error || this.state.instagram_error) {
      errors = true;
    }

    // Update to only true for account_type business because there should
    if (this.state.selected_user_roles.length === 0 && this.state.account_type === 'Business') {
      this.setState({ selected_user_roles_error: true });
      errors = true;
    } else if (this.state.account_type === 'Doctor' || this.state.account_type === 'DSO') {
      if (this.state.user.notificationAddressList.length < 1) {
        this.setState({ address_error: true });
        errors = true;
      } else {
        var s_addresses = this.state.user.notificationAddressList;
        var e = true;
        for (var i = 0; i < s_addresses.length; i++) {
          let add = s_addresses[i];
          if (add['current_ind'] === true) {
            e = false;
          }
        }
        if (e) {
          errors = true;
          this.setState({ address_error: true });
        }
      }
    }

    let creation_error = false;

    if (this.state.account_type === 'Business') {
      // Check if user has ACCOUNT_BPP_CREATE
      creation_error = _.intersection(['ACCOUNT_BPP_CREATE'], this.state.permission_list).length >= 1 ? false : true;
      this.setState({ bpp_creation_error: creation_error });
    }

    if (this.state.account_type === 'Doctor') {
      creation_error = _.intersection(['ACCOUNT_IPP_CREATE'], this.state.permission_list).length >= 1 ? false : true;

      // Using the same error message for bpp/ipp
      this.setState({ bpp_creation_error: creation_error });
    }

    if (this.state.account_type === 'DSO') {
      creation_error = _.intersection(['ACCOUNT_DSO_CREATE'], this.state.permission_list).length >= 1 ? false : true;

      // Using the same error message for bpp/ipp
      this.setState({ bpp_creation_error: creation_error });
    }

    if (this.state.account_type === 'Doctor' || this.state.account_type === 'DSO') {
      if (this.state.selected_rae === '' || this.state.selected_rae.value === 0 || this.state.selected_cst === '' || this.state.selected_cst.value === 0) {
        this.setState({ support_error: true });
        errors = true;
      } else {
        this.setState({ support_error: false });
      }
    }

    if (errors) {
      return false;
    }

    let email_user_name = this.email.value.toLowerCase().trim();

    let npi_number = this.npi_number ? this.npi_number.value : 9999999999;

    var user_data = {
      first_name: firstLetterCapital(this.firstName.value),
      last_name: firstLetterCapital(last_name),
      email: email_user_name,
      usenotify: true,
      currentuser: this.state.current_user,
      provider: true,
      notification_ind: true,
      npi_number: npi_number,
      facebook_url: this.facebook && this.facebook.value ? this.facebook.value : null,
      instagram_url: this.instagram && this.instagram.value ? this.instagram.value : null,
      referral: this.state.profile.referral_flag,
      gen_2: this.state.profile.gen2_flag,
      smile: this.state.profile.smile_flag,
      doctor_programs: this.state.doctor_programs,
    };

    var email = this.state.user.notificationEmailList;
    var address = this.state.user.notificationAddressList;

    var _this = this;
    address.forEach(function (ad) {
      if (!ad.current_ind) {
        ad.current_ind = false;
      }
      if (!ad.shipping_only) {
        ad.shipping_only = false;
      }
    });

    var phone = this.state.user.phoneNumberList;
    var assigned_user_roles = this.state.selected_user_roles;
    var assigned_user_types = this.state.selected_user_types;
    var account_type = this.state.account_type;
    var assigned_usergroupmembers = [];

    if (this.state.selected_rae !== '' && this.state.selected_rae.value !== 0) {
      assigned_usergroupmembers.push(this.state.selected_rae);
    }
    if (this.state.selected_cst !== '' && this.state.selected_cst.value !== 0) {
      assigned_usergroupmembers.push(this.state.selected_cst);
    }
    if (this.state.selected_css !== '' && this.state.selected_css.value !== 0) {
      assigned_usergroupmembers.push(this.state.selected_css);
    }

    if (this.state.account_type === 'Doctor') {
      this.setState({
        modals: { ...this.state.modals, accountCreationPendingModal: true },
      });
    }

    var data = {
      user_data,
      email,
      address,
      phone,
      account_type,
      assigned_user_roles,
      assigned_user_types,
      assigned_usergroupmembers,
    };

    this.setState({ doctor_data: data });
    // eslint-disable-next-line
    var e = false;
    // eslint-disable-next-line
    Axios.post('/apiV2/createaccount', (data = data))
      .then(function (res) {
        if (res.data.already_existing_email_error) {
          _this.setState({ already_existing_email_error: true });
          return false;
        }
        if (res.data.erp_error_info) {
          _this.setState({ erp_error: true, erp_error_info: res.data.erp_error_info, modals: { ..._this.state.modals, accountCreationPendingModal: false } });
          return false;
        }
        if (e) {
          return false;
        }

        _this.firstName.value = '';
        if (_this.state.account_type !== 'DSO') {
          _this.lastName.value = '';
        }
        _this.email.value = '';
        if (_this.npi_number) {
          _this.npi_number.value = null;
        }

        _this.setState({
          edit_id: res.data.data.doctorId,
          address_error: false,
          wrongPasswordError: '',
          modals: {
            showAccountConfirmationModal: true,
          },
          user: {
            first_name: '',
            last_name: '',
            account_email: '',
            use_as_email_notification: true,
            current_user: false,
            provider: true,
            loginEmail: '',
            notificationEmailList: [],
            notificationAddressList: [],
            phoneNumberList: [],
          },
          selectedEmail: null,
          selectedPhoneNumber: null,
          selectedAddress: null,
          loaded: true,
          accountCreationPendingModal: false,
        });

        if (res && res.data && res.data.data) {
          _this.updateReferralEmail(res.data.data.doctorId);
          if (_this.state.account_type === 'Business') {
            Axios.post(`/api/email/?slug=welcome-bpp&doctorId=${res.data.data.doctorId}&method=standard&provider=${window.location.origin}`);
          } else {
            Axios.post(`/api/email/?slug=welcome-to-inbrace-nation&doctorId=${res.data.data.doctorId}&method=standard&provider=${window.location.origin}`);
          }

          if (_this.state.account_type === 'DSO') {
            Axios.post(
              `/api/email/?slug=target-date-setting-created&provider_tier_id=${res.data.data.provider_tier_id}&method=standard&provider=${window.location.origin}`
            );
          }
        }
      })
      .catch(function (err) {
        _this.setState({
          bpp_creation_error: true,
          modals: { ..._this.state.modals, accountCreationPendingModal: false },
        });
      });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
    if (this.props.location.state && this.props.location.state.accountType) {
      if (
        this.props.location.state.accountType !== this.state.account_type ||
        (this.state.bpp_click !== this.props.location.state.bpp_click && this.state.account_type === 'Business') ||
        (this.state.doctor_click !== this.props.location.state.doctor_click && this.state.account_type === 'Doctor') ||
        (this.state.dso_click !== this.props.location.state.dso_click && this.state.account_type === 'DSO')
      ) {
        this.firstName.focus();

        //Clear out fields
        if (this.firstName) {
          this.firstName.value = '';
        }
        if (this.lastName) {
          this.lastName.value = '';
        }
        if (this.npi_number) {
          this.npi_number.value = '';
        }

        let user = this.state.user;
        user.phoneNumberList = [];
        user.notificationEmailList = [];
        user.notificationAddressList = [];

        this.clearDatalist();
        this.clearFeatureData();

        let edit_profile = this.state.edit_profile;
        let new_profile = this.state.new_profile;

        edit_profile['mode'] = '';
        edit_profile['active'] = false;
        new_profile['mode'] = '';
        new_profile['active'] = false;

        this.setState({
          account_type: this.props.location.state.accountType,
          first_name_error: false,
          last_name_error: false,
          missing_email_error: false,
          missing_npi_error: false,
          selected_user_roles_error: false,
          first_name_limit_error: false,
          last_name_limit_error: false,
          email_limit_error: false,
          already_existing_email_error: false,
          dso_name_exists_error: false,
          invalid_email_error: false,
          account_email: '',
          selected_user_roles: [],
          selected_user_types: [],
          selected_rae: '',
          selected_cst: '',
          selected_css: '',
          user: user,
          bpp_click: this.props.location.state.bpp_click,
          doctor_click: this.props.location.state.doctor_click,
          dso_click: this.props.location.state.dso_click,
          address_error: '',
        });
      }
    }
  }

  componentDidMount() {
    const that = this;

    Axios.get(`/apiV2/role`).then(function (res) {
      const user_roles = filterUserRoles(res.data.user_roles);

      if (user_roles) {
        that.setState({ user_roles: user_roles });
      }
    });

    Axios.get(`/apiV2/permission`).then(function (res) {
      const permission_list = res.data.permission_list_raw;

      if (permission_list) {
        that.setState({ permission_list: permission_list });
      }
    });

    Axios.get(`/apiV2/usergroup/get_list`).then(function (res) {
      var user_types_data = res.data.data;

      if (user_types_data) {
        that.setState({ user_types: user_types_data });
      }
    });

    //Load customer support information dropdown values
    Axios.get(`/apiV2/usergroup/get_members/RAE`).then(function (res) {
      if (res.data.data) {
        let all_rae = res.data.data;
        all_rae = _.orderBy(all_rae, ['label']);
        all_rae.unshift({
          label: 'Select User',
          value: 0,
        });

        that.setState({ all_rae: all_rae });
      }
    });

    Axios.get(`/apiV2/usergroup/get_members/CST`).then(function (res) {
      if (res.data.data) {
        let all_cst = res.data.data;
        all_cst = _.orderBy(all_cst, ['label']);
        all_cst.unshift({
          label: 'Select User',
          value: 0,
        });

        that.setState({ all_cst: all_cst });
      }
    });

    Axios.get(`/apiV2/usergroup/get_members/CSS`).then(function (res) {
      if (res.data.data) {
        let all_css = res.data.data;
        all_css = _.orderBy(all_css, ['label']);
        all_css.unshift({
          label: 'Select User',
          value: 0,
        });

        that.setState({ all_css: all_css });
      }
    });

    Axios.get(`/apiv3/program`)
      .then((res) => {
        const doctor_programs = res.data.doctor_programs
          .filter((program) => (program?.levels?.current !== 'full release' || program?.program_id === 'epp') && program?.current_ind === true)
          .map((program) => {
            return {
              ...program,
              checked: program.auto_enroll_doctor,
              enrolled: program.auto_enroll_doctor,
            };
          });
        this.setState({ doctor_programs: doctor_programs });
      })
      .catch((e) => console.log('error: ', e));

    this.setGen2Indicator();

    this.refs.currentuser.checked = true;
    if (this.props.location.state && this.props.location.state.accountType) {
      this.setState({
        account_type: this.props.location.state.accountType,
      });
    } else if (this.props.location.state && this.props.location.state.previousUrl) {
      this.props.history.push(this.props.location.state.previousUrl);
    } else {
      this.props.history.push('/business/portal/accounts');
    }
  }
  /**
   * Set Gen 2.0 indicator
   * @function
   */
  setGen2Indicator = () => {
    const that = this;
    const default_activate_date = '01-01-2022';

    Axios.get(`/apiv3/keyval?key=gen2_activate_date`)
      .then(function (res) {
        const activate_date = res && res.data && res.data.data && res.data.data[0].value ? res.data.data[0].value : default_activate_date;
        that.setGen2IndicatorByActivateDate(activate_date);
      })
      .catch(function (err) {
        that.setGen2IndicatorByActivateDate(default_activate_date);
      });
  };
  /**
   * Set Gen 2.0 indicator by a specific date
   * @function
   * @param {String} date - Contains the string date of the activate date
   */
  setGen2IndicatorByActivateDate = (date) => {
    const activate_date = Moment(date).startOf('day').valueOf();
    const today = Moment().startOf('day').valueOf();
    const flag_value = today >= activate_date;

    let profile = this.state.profile;
    profile.gen2_flag = flag_value;

    this.setState({
      isGen2Checked: flag_value,
      profile: profile,
    });
  };
  /**
   * When keypress is lifted, check if there are any errors
   * @function
   * @param {Object} e - The event handler for the input fields
   * @param {String} field_name - Contains the field name value with respect to the input field
   */
  onInputKeyUp(e, field_name) {
    if (e.target.value.length >= e.target.maxLength) {
      if (field_name === 'first_name') {
        this.setState({
          first_name_limit_error: true,
        });
      } else if (field_name === 'last_name') {
        this.setState({
          last_name_limit_error: true,
        });
      } else if (field_name === 'email') {
        this.setState({
          email_limit_error: true,
        });
      } else if (field_name === 'facebook') {
        this.setState({
          facebook_limit_error: true,
        });
      } else if (field_name === 'instagram') {
        this.setState({
          instagram_limit_error: true,
        });
      }
    } else {
      if (field_name === 'first_name') {
        this.setState({
          first_name_limit_error: false,
        });
      } else if (field_name === 'last_name') {
        this.setState({
          last_name_limit_error: false,
        });
      } else if (field_name === 'email') {
        this.setState({
          email_limit_error: false,
        });
      } else if (field_name === 'facebook') {
        this.setState({
          facebook_limit_error: false,
        });
      } else if (field_name === 'instagram') {
        this.setState({
          instagram_limit_error: false,
        });
      }
    }
  }

  onNameBlur(e) {
    e.target.value = firstLetterCapital(
      e.target.value
        .replace(/[^a-z\d\s-]+/gi, '')
        .replace(/\s\s+/g, ' ')
        .trimStart()
        .trimEnd()
        .replace(/^[-\d\s]*/, '')
    );
    if (this.state.account_type === 'DSO' && e.target.value) {
      if (this.isDsoNameExist(e.target.value)) {
      }
    }

    this.setState({
      first_name_limit_error: false,
      last_name_limit_error: false,
    });
  }

  onfnChange(e) {
    e.target.value = removeEmoji(e.target.value);
    this.setState({ first_name_error: false });
  }

  onlnChange(e) {
    e.target.value = removeEmoji(e.target.value);
    this.setState({ last_name_error: false });
  }
  /**
   * Handle user email input - Update account_email state value
   * @function
   */
  emailChange(e) {
    e.target.value = removeEmoji(e.target.value.toLowerCase().trim());
    this.setState({
      account_email: e.target.value,
    });
  }
  /**
   * Clear facebook url error upon input changes
   * @function
   */
  onFacebookChange = () => {
    if (this.facebook) {
      this.facebook.value = removeSpaces(this.facebook.value);
    }

    this.setState({
      facebook_error: false,
      facebook_limit_error: false,
    });
  };
  /**
   * Clear instagram url error upon input changes
   * @function
   */
  onInstagramChange = () => {
    if (this.instagram) {
      this.instagram.value = removeSpaces(this.instagram.value);
    }

    this.setState({
      instagram_error: false,
      instagram_limit_error: false,
    });
  };

  /**
   * Checks and validates account email when user removes focus from email input in
   * add account section. Email format is validated as well as checked against database for existing.
   * Email is then auto populated into contact details.
   * @function
   */
  emailBlur(e) {
    e.target.value = e.target.value.trimEnd();
    this.setState({ account_email: e.target.value });
    this.setState({
      already_existing_email_error: false,
      invalid_email_error: false,
      email_limit_error: false,
    });
    if (e.target.value) {
      this.setState({
        missing_email_error: false,
      });
    }

    if (ValidationUtils.isValidEmail(e.target.value) && !this.isEmailExist(e.target.value)) {
      var emailList = this.state.user.notificationEmailList;

      var valid = true;
      var emailId = 0;
      var old_emailid = 0;
      var email_exist = false;
      try {
        var email_out = e.target.value.trim();
        for (var i = 0; i < emailList.length; i++) {
          if (emailList[i].accountemail === 'true') {
            valid = false;
            emailId = i;
          }
        }

        // eslint-disable-next-line
        for (var i = 0; i < emailList.length; i++) {
          if (emailList[i].email === email_out) {
            email_exist = true;
            old_emailid = emailId;
            emailId = i;
          }
        }
      } catch (error) {}
      if (valid && emailId === 0 && !email_exist) {
        this.onAddNewAccountEmail('Office', e.target.value, true);
      } else {
        if (
          this.state.edit_profile &&
          this.state.edit_profile.active &&
          this.state.edit_profile.mode === 'edit_email' &&
          this.state.edit_profile.accountemail === 'true'
        ) {
          this.onUpdateEmail(e.target.value.toLowerCase(), this.state.edit_profile.type, this.state.edit_profile.notification, emailId);
        } else {
          let userInfo = this.state.user;
          userInfo.notificationEmailList[emailId].accountemail = 'true';
          userInfo.notificationEmailList[emailId].email = e.target.value.toLowerCase();
          userInfo.notificationEmailList[emailId].type = emailList[emailId].type;
          userInfo.notificationEmailList[emailId].notification = emailList[emailId].notification;

          if (email_exist && !valid && emailId !== old_emailid) {
            userInfo.notificationEmailList[old_emailid].accountemail = 'false';
          }
          this.setState({
            user: userInfo,
          });
        }
      }
    } else if (e.target.value && !ValidationUtils.isValidEmail(e.target.value)) {
      this.setState({ invalid_email_error: true });
    }
  }

  /**
   * On social media blur, check if there are any error
   * @param {Object} e - The event handler for the social media url
   */
  onSocialBlur = (e) => {
    const social_media = 'https://www.' + e.target.name + '.com/';
    const social_media_min = social_media + 'a';
    const has_minimum_length = e.target.value.length >= social_media_min.length;
    const starts_with_social_media = e.target.value.substring(0, social_media.length) === social_media;

    let new_state = {};
    new_state[`${e.target.name}_error`] = e.target.value && (!has_minimum_length || !starts_with_social_media);

    this.setState(new_state);
  };
  usenotifyChange(e) {
    this.setState({ min_email_error: false });
  }

  //To show modal, pass the name of the modal as in state config
  showModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = true;
    this.setState({ modals: modals });
  };

  //To hide modal, pass the name of the modal as in state config
  hideModal = (modal) => {
    let modals = this.state.modals;
    modals[modal] = false;
    this.setState({ modals: modals });
    if (modal === 'showAccountConfirmationModal') {
      const previousUrl = this.props.history.location.pathname;
      this.props.history.push({ pathname: '/business/portal/accounts/edit/' + this.state.edit_id, state: { previousUrl: previousUrl } });
    }
  };

  //Called when either edit or delete is clicked on a row item in Address list
  // setSelectedAddress = (data, index) => {
  //   this.setState({ selectedAddress: { data: data, index: index } });
  // };

  //Called when phone number starts to change on edit phone number
  onChangeEditPhoneNumber = (index, phone) => {
    let editProfileInfo = this.state.edit_profile;
    editProfileInfo.value = phone;
    editProfileInfo.id = index;
    this.setState({ edit_profile: editProfileInfo });
  };

  onChangeEditEmail = (index, email) => {
    let editProfileInfo = this.state.edit_profile;
    editProfileInfo.value = email.trim();
    editProfileInfo.id = index;
    this.setState({ edit_profile: editProfileInfo });
  };

  onChangeEditEmailType = (index, type) => {
    let editProfileInfo = this.state.edit_profile;
    editProfileInfo.type = type;
    editProfileInfo.id = index;
    this.setState({ edit_profile: editProfileInfo });
  };

  onChangeEditEmailNotification = (index, notification) => {
    let editProfileInfo = this.state.edit_profile;
    editProfileInfo.notification = notification;
    editProfileInfo.id = index;
    this.setState({ edit_profile: editProfileInfo });
  };

  onChangeEditPhoneType = (index, type) => {
    let editProfileInfo = this.state.edit_profile;
    editProfileInfo.type = type;
    editProfileInfo.id = index;
    this.setState({ edit_profile: editProfileInfo });
  };

  clearDatalist = () => {
    let elems = document.getElementsByClassName('btn dropdown-toggle datalist-select-option');
    let inner_elems = document.getElementsByClassName('filter-option-inner-inner');

    for (let inner_elem of inner_elems) {
      inner_elem.innerHTML = 'Nothing selected';
    }
    for (let elem of elems) {
      elem.title = 'Nothing selected';
    }
  };

  /**
   * Clear feature data{referral flag, gen_2 flag, and referral email filed} when component update
   * @function
   */
  clearFeatureData = () => {
    const that = this;

    this.setState(
      (prevState) => ({
        profile: {
          ...prevState.profile,
          referral_flag: false,
          gen2_flag: false,
          referral_email: '',
        },
        referral_email: '',
        isReferralChecked: false,
        isGen2Checked: false,
      }),
      that.setGen2Indicator()
    );
  };

  onChangeList = (event, field) => {
    if (field === 'rae') {
      this.setState({ selected_rae: event });
    } else if (field === 'cst') {
      this.setState({ selected_cst: event });
    } else if (field === 'css') {
      this.setState({ selected_css: event });
    } else {
      let current_selected = [];

      if (event.target.selectedIndex > -1 && event.target.selectedOptions && event.target.selectedOptions.length > 0) {
        for (var i = 0; i < event.target.selectedOptions.length; i++) {
          const selected_option = event.target.selectedOptions[i];
          if (selected_option && selected_option.value) {
            current_selected.push(selected_option.value);
          }
        }
      }

      if (field === 'roles') {
        this.setState({ selected_user_roles: current_selected, selected_user_roles_error: false });
      } else if (field === 'types') {
        this.setState({ selected_user_types: current_selected });
      }
    }
  };

  //Called when either edit or delete is clicked on a row item in phone no list
  setSelectedPhone = (phone, type, index) => {
    //selectedPhone: { type: type, value: phone, index: index },
    this.setState({
      edit_profile: {
        id: index,
        active: true,
        mode: 'edit_number',
        value: phone,
        type: type,
      },
    });
  };

  setSelectedEmail = (email, type, notification, index) => {
    //selectedPhone: { type: type, value: phone, index: index },
    this.setState({
      edit_profile: {
        id: index,
        active: true,
        mode: 'edit_email',
        value: email,
        type: type,
        notification: notification === 'true',
      },
    });
  };

  setSelectedAddress = (
    name,
    address1,
    address2,
    address_telephone,
    city,
    state,
    zip,
    country,
    website,
    shipping_only,
    active,
    is_doctor_loc,
    index,
    facebook_url,
    instagram_url,
    address_email
  ) => {
    this.setState({
      edit_profile: {
        id: index,
        active: true,
        mode: 'edit_address',
        value: name,
        is_active: active,
        shipping_only: shipping_only,
        is_doctor_loc: is_doctor_loc,
        address_line_1: address1,
        address_line_2: address2,
        address_telephone: address_telephone,
        city: city,
        state: state,
        zip: zip,
        country: country,
        website: website,
        facebook_url: facebook_url,
        instagram_url: instagram_url,
        address_email: address_email,
      },
      erp_error: false,
    });
  };

  editSelectedAddress = (edit_profile) => {
    //selectedPhone: { type: type, value: phone, index: index },
    this.setState({
      edit_profile: edit_profile,
    });
  };

  //Called when user clicks on delete email in delete email modal
  deleteSelectedEmail = (email, index) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.splice(index, 1);
    this.setState({ user: userInfo });
    this.hideModal('showDeleteEmailModal');
  };

  //Called when user clicks on delete email in delete Address modal
  deleteSelectedAddress = (data, index) => {
    let userInfo = this.state.user;
    userInfo.notificationAddressList.splice(index, 1);
    this.setState({ user: userInfo });
    this.hideModal('showDeleteAddressModal');
  };

  //Called when user clicks on delete phone no in delete phone number modal
  deleteSelectedPhone = (phone, index) => {
    let userInfo = this.state.user;
    userInfo.phoneNumberList.splice(index, 1);
    this.setState({
      user: userInfo,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  onDeleteSelectedEmail = (email, index) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.splice(index, 1);
    this.setState({
      user: userInfo,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  onEditProfileDelete = (index) => {
    let userInfo = this.state.user;
    userInfo.notificationAddressList.splice(index, 1);
    this.setState({
      user: userInfo,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  //Called when user adds new email from add email modal
  addNewEmail = (email, location) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.push({ type: location, email: email });
    this.setState({ user: userInfo });
    this.hideModal('showAddEmailModal');
  };

  addNewAddress = (data) => {
    this.setState({ address_error: false });
    let userInfo = this.state.user;
    userInfo.notificationAddressList.push(data);
    this.setState({ user: userInfo });
    this.hideModal('showAddAddressModal');
  };

  //Called when user adds new phone number from add phone no modal
  addNewTelephone = (type, phone) => {
    let userInfo = this.state.user;
    userInfo.phoneNumberList.push({ type: type, number: phone });
    this.setState({
      user: userInfo,
      new_profile: {
        active: false,
        error: false,
        mode: '',
        notification: false,
        type: '',
        value: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
  };

  onAddNewEmail = (type, email, notification) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.push({ type: type, email: email, notification: notification, accountemail: 'false' });
    this.setState({
      user: userInfo,
      new_profile: {
        active: false,
        error: false,
        mode: '',
        notification: false,
        type: '',
        value: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
  };

  onAddNewAddress = (data) => {
    let userInfo = this.state.user;
    userInfo.notificationAddressList.push(data);

    this.setState({
      user: userInfo,
      address_error: false,
      new_profile: {
        active: false,
        error: false,
        mode: '',
        notification: false,
        type: '',
        value: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
  };

  onAddNewAccountEmail = (type, email, notification) => {
    let userInfo = this.state.user;
    userInfo.notificationEmailList.push({ type: type, email: email, notification: notification, accountemail: 'true' });
    this.setState({
      user: userInfo,
    });
  };

  //Called when user updates an existing phone no or type from edit phone no modal
  updatePhoneNo = (phoneNumber, type, index) => {
    let userInfo = this.state.user;
    // eslint-disable-next-line
    var oldphoneNumber = userInfo.phoneNumberList[index].number;
    userInfo.phoneNumberList[index].number = phoneNumber;
    userInfo.phoneNumberList[index].type = type;
    this.setState({
      user: userInfo,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  //Called when user updates an existing email from edit email modal
  updateEmail = (email, type, notification, index) => {
    let userInfo = this.state.user;
    // eslint-disable-next-line
    var oldEmail = userInfo.notificationEmailList[index].email;
    userInfo.notificationEmailList[index].email = email;
    userInfo.notificationEmailList[index].type = type;
    userInfo.notificationEmailList[index].notification = notification;
    if (userInfo.notificationEmailList[index].accountemail === 'true') {
      this.setState({
        account_email: email,
        user: userInfo,
        edit_profile: {
          id: '',
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
        },
      });
    } else {
      this.setState({
        user: userInfo,
        edit_profile: {
          id: '',
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
        },
      });
    }
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Updates an already inputted address
   * @function
   * @param {String} name - Address name
   * @param {String} address1 - Address line 1
   * @param {String} address2 - Address line 2
   * @param {String} address_telephone - Address telephone number
   * @param {String} city - Address city
   * @param {String} state - Address state
   * @param {String} zip - Address zip code
   * @param {String} website - Address website
   * @param {Boolean} shipping_only - Address is practice
   * @param {Boolean} active - Address is active
   * @param {Boolean} locator - Doctor locator
   * @param {Number} index - Index of address in the list
   * @param {String} geo_lat - Latitude coordinate
   * @param {String} geo_long - Longitude coordinate
   * @param {String} facebook_url - Address facebook url
   * @param {String} instagram_url - Address instagram url
   * @param {String} address_email - Address email
   */
  onUpdateAddress = (
    name,
    address1,
    address2,
    address_telephone,
    city,
    state,
    zip,
    country,
    website,
    shipping_only,
    active,
    locator,
    index,
    geo_lat = '',
    geo_long = '',
    facebook_url = '',
    instagram_url = '',
    address_email = ''
  ) => {
    let userInfo = this.state.user;
    userInfo.notificationAddressList[index].address_name = firstLetterCapital(name);
    userInfo.notificationAddressList[index].address_line_1 = firstLetterCapital(address1);
    userInfo.notificationAddressList[index].address_line_2 = firstLetterCapital(address2);
    userInfo.notificationAddressList[index].address_telephone = address_telephone;
    userInfo.notificationAddressList[index].city = firstLetterCapital(city);
    userInfo.notificationAddressList[index].zip = zip;
    userInfo.notificationAddressList[index].country = country;
    userInfo.notificationAddressList[index].state = formatState(state, country);
    userInfo.notificationAddressList[index].website = website;
    userInfo.notificationAddressList[index].facebook_url = facebook_url;
    userInfo.notificationAddressList[index].instagram_url = instagram_url;
    userInfo.notificationAddressList[index].address_email = address_email;
    userInfo.notificationAddressList[index].shipping_only = shipping_only;
    userInfo.notificationAddressList[index].current_ind = active;
    userInfo.notificationAddressList[index].doc_locator_ind = locator;
    userInfo.notificationAddressList[index].geo_lat = geo_lat;
    userInfo.notificationAddressList[index].geo_long = geo_long;

    let addressError = this.state.address_error;

    if (active && this.state.address_error) {
      addressError = false;
    }

    this.setState({
      user: userInfo,
      address_error: addressError,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });

    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Updates an already entered "Contact Email"
   * @function
   * @param {String} Email Address
   */
  onUpdateEmail = (email, type, notification, index) => {
    let userInfo = this.state.user;
    // eslint-disable-next-line
    var oldEmail = userInfo.notificationEmailList[index].email;
    userInfo.notificationEmailList[index].email = email.toLowerCase();
    userInfo.notificationEmailList[index].type = type;
    userInfo.notificationEmailList[index].notification = notification;
    if (userInfo.notificationEmailList[index].accountemail === 'true') {
      this.setState({
        account_email: email,
        user: userInfo,
        edit_profile: {
          id: '',
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
        },
      });
    } else {
      this.setState({
        user: userInfo,
        edit_profile: {
          id: '',
          active: false,
          mode: '',
          value: '',
          type: '',
          notification: false,
          error: false,
          address_line_1: '',
          address_line_2: '',
          address_telephone: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          website: '',
          facebook_url: '',
          instagram_url: '',
          address_email: '',
          is_active: true,
          shipping_only: false,
          is_doctor_loc: false,
        },
      });
    }
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  //Called when user updates an existing Address from edit Address modal
  updateAddress = (data, index) => {
    let userInfo = this.state.user;
    // eslint-disable-next-line
    var oldData = userInfo.notificationAddressList[index];
    userInfo.notificationAddressList[index] = data;
    this.setState({ user: userInfo });
    this.hideModal('showEditAddressModal');
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Displays referral email UI"
   * @function
   * @param {Boolean} referral_checked referral opted in
   * @param {Boolean} edit referral feature in edit mode
   * @return {JSX} returns UI to view and edit referral email
   */
  displayReferralEmail = (referral_checked, edit) => {
    return referral_checked ? (
      edit ? (
        <tr className="pointer opted_in_email_tr referal-mail">
          <td className="account-table-start type-width-tb account-table-first vertical-align-middle opted_in_email_td" colSpan="2">
            <div className="feature-item">Email:</div>
          </td>
          <td className="account-table-end type-width-tb vertical-align-middle bold-text referral_email_input_td" colSpan="4">
            <TextInput
              name="referral_email"
              className={
                this.state.referral_email_error
                  ? 'form-control business-dark-theme-input referral_email_input input-error'
                  : 'form-control business-dark-theme-input referral_email_input'
              }
              value={this.state.referral_email}
              change={this.onChangeReferralEmail}
              keyDown={this.onChangeReferralEmailKeyPress}
              item="referral_email"
              placeholder="Email (Required)"
              maxLength={100}
            />
          </td>
        </tr>
      ) : (
        <tr className="pointer opted_in_email_tr_with_icon">
          <td>
            <i className="fa fa-envelope" aria-hidden="true" />
            <span className="account-plain-text" id="referral_email">
              {this.state.referral_email ? (
                <a className="sub-item-0 support-link" href={'mailto:' + this.state.referral_email + '?Subject=Hello%20Inbrace'} target="_top">
                  {this.state.referral_email}
                </a>
              ) : (
                'N/A'
              )}
            </span>
          </td>
        </tr>
      )
    ) : null;
  };

  /**
   * Displays list of features an account can have"
   * @function
   * @param {Object} user_permissions Roles and permissions of logged in user
   * @return {JSX} returns UI to view and edit features
   */
  displayFeatures = (user_permissions) => {
    const features = [
      {
        display: this.state.account_type && !this.state.account_type.includes('DSO'),
        name: 'referral',
        title: 'Referral Program',
        feature_checked: this.state.isReferralChecked,
        permission: 'DOCTOR_REFERRAL_PROGRAM',
        error: this.state.referral_email_error ? 'Please enter a valid email.' : '',
        children: this.displayReferralEmail(this.state.isReferralChecked, this.state.edit_profile.mode === 'referral'),
      },
      {
        display: true,
        name: 'gen2',
        title: 'Gen 2.0',
        feature_checked: this.state.isGen2Checked,
        permission: 'DOCTOR_GEN2_FLAG',
        disabled: true,
      },
      {
        display: this.state.account_type && this.state.account_type.includes('DSO'),
        name: 'smile',
        title: 'Smile Simulation',
        feature_checked: this.state.isSmileChecked,
        permission: 'SMILE_SIMULATION_PROGRAM',
      },
    ];
    return (
      <table className="table feature-table">
        <tbody className="table-inbrace-dark-industry-2">
          {features.map((feature, index) => {
            return (
              <React.Fragment key={index}>
                {feature.display ? (
                  this.state.edit_profile.mode === feature.name ? (
                    <>
                      <tr className={feature.children && feature.feature_checked ? 'opted_in_tr pointer' : 'pointer'}>
                        <td className="account-table-start type-width-tb account-table-first vertical-align-middle bold-text" colSpan="2">
                          {feature.title}
                        </td>
                        <td className="account-table-end type-width-tb vertical-align-middle account-table-dark">
                          Opted In:
                          <input
                            className="checkbox-button feature__checkbox"
                            type="checkbox"
                            name={feature.name}
                            checked={feature.feature_checked}
                            onChange={this.onFeatureChange}
                            readOnly={feature.disabled}
                          />
                        </td>
                        <td className="type-width-tb">
                          <div id="edit-confirm" className="btn-clear " onClick={this.onFeatureConfirm} data-toggle="tooltip" data-placement="top" title="Save">
                            <i className="fa fa-check" aria-hidden="true" />
                          </div>
                          <div
                            id="edit-discard"
                            className={'btn-clear ' + (this.state.in_progress ? 'btn-disabled' : '')}
                            onClick={this.onEditProfileCancel}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Cancel"
                          >
                            <i className="fa fa-times" aria-hidden="true" />
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr className={feature.children && feature.feature_checked ? 'opted_in_tr pointer' : 'pointer'}>
                        <td
                          className="account-table-start type-width-tb account-table-first vertical-align-middle"
                          colSpan="2"
                          data-mode={feature.name}
                          onClick={userHasPermission(feature.permission, user_permissions) && !feature.disabled ? this.onEditClick : null}
                        >
                          <div className="feature-item">{feature.title}</div>
                        </td>
                        <td className="account-table-end type-width-tb vertical-align-middle account-table-dark" style={{ verticalAlign: 'inherit' }}>
                          Opted In:
                          <input
                            className="checkbox-button feature__checkbox"
                            type="checkbox"
                            name={feature.name}
                            readOnly={true}
                            checked={feature.feature_checked}
                          />
                        </td>
                        <td className="type-width-tb"></td>
                      </tr>
                    </>
                  )
                ) : null}
                {feature.children ? feature.children : null}
                {feature.error ? (
                  <tr className="pointer">
                    <td className="type-width-tb" colSpan="4">
                      <span className="general-error">{feature.error}</span>
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    );
  };

  /**
   * Handles Program Edit Confirmation
   */
  onProgramConfirm = () => {
    const doctor_programs = _.cloneDeep(this.state.doctor_programs);
    doctor_programs.forEach((program) => {
      program.enrolled = program.checked;
      if (program.levels) {
        program.levels.enrolled = program.levels.checked;
      }
    });
    this.setState({
      doctor_programs: doctor_programs,
      edit_profile: {
        id: '',
        active: false,
        mode: '',
        value: '',
        type: '',
        notification: false,
        error: false,
        address_line_1: '',
        address_line_2: '',
        address_telephone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        website: '',
        facebook_url: '',
        instagram_url: '',
        address_email: '',
        is_active: true,
        shipping_only: false,
        is_doctor_loc: false,
      },
    });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
  };

  /**
   * Sets doctor programs
   * @function
   * @param {programs} list - List of updated programs
   */
  setDoctorPrograms = (programs) => {
    this.setState({ doctor_programs: programs });
  };

  render() {
    // eslint-disable-next-line
    let incomplete_error =
      this.state.first_name_error ||
      this.state.last_name_error ||
      this.state.missing_email_error ||
      (this.state.account_type === 'Business' ? this.state.selected_user_roles_error : false)
        ? true
        : false;
    let limit_error = this.state.first_name_limit_error || this.state.last_name_limit_error || this.state.email_limit_error ? true : false;

    let user_error = this.state.dso_name_exists_error ? (
      <span className="profile-error">Please enter a nonexistent dso name</span>
    ) : this.state.already_existing_email_error && !this.state.invalid_email_error && !limit_error ? (
      <span className="profile-error">Please enter a nonexistent account email</span>
    ) : !this.state.already_existing_email_error && this.state.invalid_email_error && !limit_error ? (
      <span className="profile-error">Please enter a valid email</span>
    ) : !this.state.already_existing_email_error && !this.state.invalid_email_error && limit_error ? (
      <span className="profile-error">Please ensure all fields are within character limit</span>
    ) : !this.state.already_existing_email_error && this.state.invalid_email_error && limit_error ? (
      <span className="profile-error">Please ensure all fields are within character limit and email is valid</span>
    ) : this.state.already_existing_email_error && !this.state.invalid_email_error && limit_error ? (
      <span className="profile-error">Please ensure all fields are within character limit and email is is not linked to an existing account</span>
    ) : null;

    if (this.state.account_type === 'Business') {
      var currentUserRolesList = [...this.state.user_roles];
      var doctorIndex = currentUserRolesList.indexOf('Doctor');

      if (doctorIndex > -1) {
        currentUserRolesList.splice(doctorIndex, 1);
        this.setState({ user_roles: currentUserRolesList });
      }
    }

    return (
      <div className="main-content">
        {this.state.loaded === false ? (
          ''
        ) : (
          <div className="dark-options dark-options-offset">
            <Helmet>
              <title>Account Creation | InBrace Business Portal</title>
            </Helmet>
            <div className="page-heading page-heading-solo">
              {this.state.account_type === 'Doctor' ? 'Add Doctor' : this.state.account_type === 'DSO' ? 'Add DSO' : 'Add Business User'}
            </div>
            <br />
            <div className="page-tab-container">
              <div className="page-tab page-active">User Details</div>
              <div className="page-tab-content">
                <div className="page-top-content page-top-content-bottom-space">
                  <span className="profile-heading profile-w-button">Account Enabled</span>
                  <span className="pull-right profile-icons" />
                  <span className="profile-info">
                    <label className="switch">
                      <input ref="currentuser" type="checkbox" onChange={this.onEditProfileToggleAccount} disabled={true} />
                      <span className="slider round disabled" />
                    </label>
                    {this.state.current_user ? <span className="switch-label disabled">On</span> : null}
                  </span>
                </div>
                <div className="page-tab-content-section">
                  <div className="table-info-view">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="account-heading">{this.state.account_type === 'DSO' ? 'DSO Information' : 'User Information'}</div>
                        <div className="account-body">
                          <table>
                            <tbody>
                              {this.state.account_type !== 'DSO' ? (
                                <React.Fragment>
                                  <tr>
                                    <td>
                                      <div className="profile-edit-container">
                                        <div className="profile-edit-section">
                                          <span className="profile-heading">First Name:</span>
                                        </div>
                                        <div className="profile-edit-section">
                                          <input
                                            name="first_name"
                                            id="first_name"
                                            className={
                                              'form-control ' +
                                              (this.state.first_name_error || this.state.first_name_limit_error ? 'profile-edit-input-error' : '')
                                            }
                                            onChange={this.onfnChange}
                                            onBlur={this.onNameBlur}
                                            ref={(input) => (this.firstName = input)}
                                            disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                            maxLength="30"
                                            onKeyUp={(e) => this.onInputKeyUp(e, 'first_name')}
                                            placeholder="Enter First Name"
                                            autoFocus
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="profile-edit-container">
                                        <div className="profile-edit-section">
                                          <span className="profile-heading">Last Name:</span>
                                        </div>
                                        <div className="profile-edit-section">
                                          <input
                                            name="last_name"
                                            className={
                                              'form-control ' +
                                              (this.state.last_name_error || this.state.last_name_limit_error ? 'profile-edit-input-error' : '')
                                            }
                                            onChange={this.onlnChange}
                                            onBlur={this.onNameBlur}
                                            disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                            ref={(input) => (this.lastName = input)}
                                            maxLength="70"
                                            onKeyUp={(e) => this.onInputKeyUp(e, 'last_name')}
                                            placeholder="Enter Last Name"
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ) : (
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">DSO Name:</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <input
                                          name="first_name"
                                          id="first_name"
                                          className={
                                            'form-control ' +
                                            (this.state.first_name_error || this.state.first_name_limit_error || this.state.dso_name_exists_error
                                              ? 'profile-edit-input-error'
                                              : '')
                                          }
                                          onChange={this.onfnChange}
                                          onBlur={this.onNameBlur}
                                          ref={(input) => (this.firstName = input)}
                                          disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                          maxLength="30"
                                          onKeyUp={(e) => this.onInputKeyUp(e, 'first_name')}
                                          placeholder="Enter DSO Name"
                                          autoFocus
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {this.state.account_type === 'Doctor' && (
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">NPI Number:</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <input
                                          name="npi_number"
                                          className={'form-control ' + (this.state.missing_npi_error ? 'profile-edit-input-error' : '')}
                                          disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                          ref={(input) => (this.npi_number = input)}
                                          onChange={(e) => {
                                            this.setState({ missing_npi_error: false });
                                            e.target.value = npiNumberChar(removeEmoji(e.target.value));
                                          }}
                                          placeholder="Enter NPI Number"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}

                              {this.state.account_type === 'Business' && this.state.user_types && this.state.user_types.length > 0 && (
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">User Type(s):</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <Datalist
                                          borderClassName="select-border"
                                          className="form-control datalist-select-option-medium"
                                          hideDefaultOption={true}
                                          multiple={true}
                                          sortBy="asc"
                                          name="user_types"
                                          value={this.state.selected_user_types}
                                          change={(e) => this.onChangeList(e, 'types')}
                                          item="user_types"
                                          list={this.state.user_types}
                                          disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {this.state.account_type === 'Business' && this.state.user_roles && this.state.user_roles.length > 0 && (
                                <tr className="pointer">
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">User Role(s):</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <Datalist
                                          borderClassName="select-border"
                                          className="form-control datalist-select-option-medium"
                                          error={this.state.selected_user_roles_error}
                                          hideDefaultOption={true}
                                          multiple={true}
                                          name="user_roles"
                                          value={this.state.selected_user_roles}
                                          change={(e) => this.onChangeList(e, 'roles')}
                                          item="user_roles"
                                          list={this.state.user_roles}
                                          disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                  <div className="profile-edit-container">
                                    <div className="profile-edit-section">
                                      <span className="profile-heading">Account Email:</span>
                                    </div>
                                    <div className="profile-edit-section">
                                      <input
                                        value={this.state.account_email}
                                        name="account_email"
                                        className={
                                          'form-control ' +
                                          (this.state.missing_email_error ||
                                          this.state.invalid_email_error ||
                                          this.state.already_existing_email_error ||
                                          this.state.email_limit_error
                                            ? 'profile-edit-input-error'
                                            : '')
                                        }
                                        onChange={this.emailChange}
                                        onBlur={this.emailBlur}
                                        disabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        ref={(input) => (this.email = input)}
                                        maxLength="100"
                                        onKeyUp={(e) => this.onInputKeyUp(e, 'email')}
                                        placeholder="Enter Account Email"
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? (
                        <div className="col-lg-6">
                          <div className="account-heading">Support Information</div>
                          <div className="account-body">
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">Business Growth Consultant:</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <Select
                                          name="rae_list"
                                          placeholder="Select User"
                                          className={
                                            (this.state.selected_rae === '' || this.state.selected_rae.value === 0) && this.state.support_error
                                              ? 'select-edit-input-error'
                                              : ''
                                          }
                                          value={this.state.selected_rae}
                                          options={this.state.all_rae}
                                          onChange={(e) => this.onChangeList(e, 'rae')}
                                          isDisabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">Technology Integration Specialist:</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <Select
                                          name="cst_list"
                                          placeholder="Select User"
                                          className={
                                            (this.state.selected_cst === '' || this.state.selected_cst.value === 0) && this.state.support_error
                                              ? 'select-edit-input-error'
                                              : ''
                                          }
                                          value={this.state.selected_cst}
                                          options={this.state.all_cst}
                                          onChange={(e) => this.onChangeList(e, 'cst')}
                                          isDisabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="profile-edit-container">
                                      <div className="profile-edit-section">
                                        <span className="profile-heading">InBrace Concierge:</span>
                                      </div>
                                      <div className="profile-edit-section">
                                        <Select
                                          name="css_list"
                                          placeholder="Select User"
                                          value={this.state.selected_css}
                                          options={this.state.all_css}
                                          onChange={(e) => this.onChangeList(e, 'css')}
                                          isDisabled={this.state.new_profile.active || this.state.edit_profile.active}
                                        />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className={this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? 'account-error' : ''}>{user_error}</div>
                </div>

                {this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? (
                  <div className="page-tab-content-section">
                    <div className="table-info-view">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="account-heading">Features</div>
                          <UserPermissionsContext.Consumer>
                            {(user_roles_and_permissions) => {
                              return this.displayFeatures(user_roles_and_permissions.permissions);
                            }}
                          </UserPermissionsContext.Consumer>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? (
                  <UserPermissionsContext.Consumer>
                    {(user_roles_and_permissions) => {
                      return (
                        <Programs
                          doctor_programs={this.state.doctor_programs}
                          user_permissions={user_roles_and_permissions.permissions}
                          edit_mode={this.state.edit_profile.mode}
                          in_progress={this.state.in_progress}
                          onProgramConfirm={this.onProgramConfirm}
                          onEditProfileCancel={this.onEditProfileCancel}
                          onEditClick={this.onEditClick}
                          setDoctorPrograms={this.setDoctorPrograms}
                        />
                      );
                    }}
                  </UserPermissionsContext.Consumer>
                ) : null}
                <div className="page-tab-content-section-contact">
                  <div className="table-info-view">
                    <div className="row">
                      {this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? (
                        <AddEmail
                          accountEmail={this.state.account_email}
                          emailList={this.state.user.notificationEmailList}
                          profile={this.state.profile}
                          new_profile={this.state.new_profile}
                          edit_profile={this.state.edit_profile}
                          onAddProfileItemClick={this.onAddProfileItemClick}
                          onNewProfileCancel={this.onNewProfileCancel}
                          onEditProfileCancel={this.onEditProfileCancel}
                          onChangeEditEmail={(index, email) => {
                            this.onChangeEditEmail(index, email);
                          }}
                          onChangeEditEmailType={(index, emailType) => {
                            this.onChangeEditEmailType(index, emailType);
                          }}
                          onChangeEditEmailNotification={(index, notification) => {
                            this.onChangeEditEmailNotification(index, notification);
                          }}
                          onAddNewEmail={(type, email, notification) => {
                            this.onAddNewEmail(type, email, notification);
                          }}
                          onDeleteEmail={(email, index) => {
                            this.onDeleteSelectedEmail(email, index);
                          }}
                          onUpdateEmail={(email, type, notification, index) => {
                            this.onUpdateEmail(email, type, notification, index);
                          }}
                          setSelectedEmail={(email, type, notification, index) => {
                            this.setSelectedEmail(email, type, notification, index);
                          }}
                        />
                      ) : null}

                      {this.state.account_type ? (
                        <AddTelephoneNumber
                          profile={this.state.profile}
                          new_profile={this.state.new_profile}
                          edit_profile={this.state.edit_profile}
                          phoneNumberList={this.state.user.phoneNumberList}
                          onAddProfileItemClick={this.onAddProfileItemClick}
                          onNewPhoneNumberCancel={this.onNewProfileCancel}
                          onEditProfileCancel={this.onEditProfileCancel}
                          onChangeEditPhoneNumber={(index, phoneNumber) => {
                            this.onChangeEditPhoneNumber(index, phoneNumber);
                          }}
                          onChangeEditPhoneType={(index, phoneType) => {
                            this.onChangeEditPhoneType(index, phoneType);
                          }}
                          onAddNewPhone={(type, phoneNumber) => {
                            this.addNewTelephone(type, phoneNumber);
                          }}
                          onDeletePhone={(phoneNumber, index) => {
                            this.deleteSelectedPhone(phoneNumber, index);
                          }}
                          onUpdatePhoneNo={(phoneNumber, type, index) => {
                            this.updatePhoneNo(phoneNumber, type, index);
                          }}
                          setSelectedPhone={(phone, type, index) => {
                            this.setSelectedPhone(phone, type, index);
                          }}
                        />
                      ) : null}

                      {this.state.account_type === 'Doctor' || this.state.account_type === 'DSO' ? (
                        <AddAddress
                          address_error={this.state.address_error}
                          addressList={this.state.user.notificationAddressList}
                          profile={this.state.profile}
                          new_profile={this.state.new_profile}
                          edit_profile={this.state.edit_profile}
                          onAddProfileItemClick={this.onAddProfileItemClick}
                          onNewProfileCancel={this.onNewProfileCancel}
                          onEditProfileCancel={this.onEditProfileCancel}
                          onEditProfileDelete={(index) => {
                            this.onEditProfileDelete(index);
                          }}
                          onAddNewAddress={(data) => {
                            this.onAddNewAddress(data);
                          }}
                          onUpdateAddress={(
                            name,
                            address1,
                            address2,
                            address_telephone,
                            city,
                            state,
                            zip,
                            country,
                            website,
                            practice,
                            active,
                            locator,
                            index,
                            geo_lat,
                            geo_long,
                            facebook_url,
                            instagram_url,
                            address_email
                          ) => {
                            this.onUpdateAddress(
                              name,
                              address1,
                              address2,
                              address_telephone,
                              city,
                              state,
                              zip,
                              country,
                              website,
                              practice,
                              active,
                              locator,
                              index,
                              geo_lat,
                              geo_long,
                              facebook_url,
                              instagram_url,
                              address_email
                            );
                          }}
                          setSelectedAddress={(
                            name,
                            address1,
                            address2,
                            address_telephone,
                            city,
                            state,
                            zip,
                            country,
                            website,
                            practice,
                            active,
                            locator,
                            index,
                            facebook_url,
                            instagram_url,
                            address_email
                          ) => {
                            this.setSelectedAddress(
                              name,
                              address1,
                              address2,
                              address_telephone,
                              city,
                              state,
                              zip,
                              country,
                              website,
                              practice,
                              active,
                              locator,
                              index,
                              facebook_url,
                              instagram_url,
                              address_email
                            );
                          }}
                          editSelectedAddress={(edit_profile) => {
                            this.editSelectedAddress(edit_profile);
                          }}
                          erp_error={this.state.erp_error}
                          erp_error_info={this.state.erp_error_info}
                        />
                      ) : null}

                      {this.state.modals.showAccountConfirmationModal ? (
                        <Modal
                          preset="confirmation"
                          message_text={
                            <React.Fragment>
                              <p className="grey-text text-center">
                                <i className="fa fa-user-circle account-logo" aria-hidden="true" />
                              </p>
                              <p className="grey-text text-center break-word">
                                Account Successfully Created <br />
                                An email will be sent to {this.state.account_email} <br />
                                to create new password
                              </p>
                              <div className="col text-center">
                                <button
                                  type="button"
                                  className="btn btn-light btn-spacing pull-center"
                                  data-dismiss="modal"
                                  onClick={() => {
                                    this.hideModal('showAccountConfirmationModal');
                                  }}
                                >
                                  Go to Account Profile
                                </button>
                              </div>
                            </React.Fragment>
                          }
                        />
                      ) : null}
                      {this.state.modals.accountCreationPendingModal ? (
                        <Modal
                          preset="erp"
                          header_text="Account Creation"
                          theme="bpp"
                          in_progress={true}
                          in_progress_text="Sending data to ERP..."
                          confirm_btn={false}
                          close_btn={false}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <br />
                <div className="profile-edit-container" id="profilebtnsubmit">
                  <div className="profile-edit">
                    <button
                      onClick={this.submitform}
                      disabled={this.state.new_profile.active || this.state.edit_profile.active}
                      className="btn btn-light"
                      type="submit"
                    >
                      Create
                    </button>
                  </div>
                  <div className="profile-edit" id="profilebtncancel">
                    <button
                      onClick={this.changeRoute}
                      disabled={this.state.new_profile.active || this.state.edit_profile.active}
                      className="btn btn-light btn-link-light-style"
                      type="submit"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                {this.state.bpp_creation_error ? <div className="warning-creation">Unable to create user, please see admin for help</div> : null}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AccountDetails);
