import {
  OPEN_BPG_FILE_MODAL,
  CLOSE_BPG_FILE_MODAL,
  OPEN_TX_INSTRUCTION_FILE_MODAL,
  CLOSE_TX_INSTRUCTION_FILE_MODAL,
  OPEN_REMOVE_BPG_FILE_MODAL,
  CLOSE_REMOVE_BPG_FILE_MODAL,
  OPEN_REMOVE_TX_INSTRUCTION_FILE_MODAL,
  CLOSE_REMOVE_TX_INSTRUCTION_FILE_MODAL,
  REMOVE_TX_FILE_PENDING,
  REMOVE_TX_FILE_SUCCESS,
  REMOVE_TX_FILE_ERROR,
  REMOVE_BPG_FILE_PENDING,
  REMOVE_BPG_FILE_SUCCESS,
  REMOVE_BPG_FILE_ERROR,
  RETURN_TO_IFU_PENDING,
  RETURN_TO_IFU_SUCCESS,
  RETURN_TO_IFU_ERROR,
  OPEN_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL,
  CLOSE_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL,
  OPEN_BPG_MANUAL_UPLOAD_MODAL,
  CLOSE_BPG_MANUAL_UPLOAD_MODAL,
  MANUAL_UPLOAD_PENDING,
  MANUAL_UPLOAD_SUCCESS,
  MANUAL_UPLOAD_ERROR,
} from '../../actions/ifu/ifu_result';

const initialState = {
  error: null,
  pending: false,
  tx_instruction_file_modal: false,
  bpg_file_modal: false,
  remove_tx_instruction_file_modal: false,
  remove_bpg_file_modal: false,
  txinstruction_manual_upload_modal: false,
  bpg_manual_upload_modal: false,
  session_error: false,
};

export function ifuResultReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL:
      return {
        ...state,
        txinstruction_manual_upload_modal: true,
      };
    case CLOSE_TX_INSTRUCTION_MANUAL_UPLOAD_MODAL:
      return {
        ...state,
        txinstruction_manual_upload_modal: false,
      };
    case OPEN_BPG_MANUAL_UPLOAD_MODAL:
      return {
        ...state,
        bpg_manual_upload_modal: true,
      };
    case CLOSE_BPG_MANUAL_UPLOAD_MODAL:
      return {
        ...state,
        bpg_manual_upload_modal: false,
      };

    // Axios

    case OPEN_BPG_FILE_MODAL:
      return {
        ...state,
        bpg_file_modal: true,
      };
    case CLOSE_BPG_FILE_MODAL:
      return {
        ...state,
        bpg_file_modal: false,
      };
    case OPEN_TX_INSTRUCTION_FILE_MODAL:
      return {
        ...state,
        tx_instruction_file_modal: true,
      };
    case CLOSE_TX_INSTRUCTION_FILE_MODAL:
      return {
        ...state,
        tx_instruction_file_modal: false,
      };

    case OPEN_REMOVE_BPG_FILE_MODAL:
      return {
        ...state,
        remove_bpg_file_modal: true,
      };
    case CLOSE_REMOVE_BPG_FILE_MODAL:
      return {
        ...state,
        remove_bpg_file_modal: false,
      };
    case OPEN_REMOVE_TX_INSTRUCTION_FILE_MODAL:
      return {
        ...state,
        remove_tx_instruction_file_modal: true,
      };
    case CLOSE_REMOVE_TX_INSTRUCTION_FILE_MODAL:
      return {
        ...state,
        remove_tx_instruction_file_modal: false,
      };
    case REMOVE_TX_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };
    case REMOVE_TX_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case REMOVE_TX_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error
      };
    case REMOVE_BPG_FILE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case REMOVE_BPG_FILE_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case REMOVE_BPG_FILE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error
      };

    case RETURN_TO_IFU_PENDING:
      return {
        ...state,
        pending: true,
      };

    case RETURN_TO_IFU_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case RETURN_TO_IFU_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error
      };
    case MANUAL_UPLOAD_PENDING:
      return {
        ...state,
        pending: true,
      };

    case MANUAL_UPLOAD_SUCCESS:
      return {
        ...state,
        pending: false,
      };

    case MANUAL_UPLOAD_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        session_error: action.session_error
      };
    default:
      return state;
  }
}

export const getTxInstructionFileModal = (state) => state.ifuResultReducer.tx_instruction_file_modal;
export const getBpgFileModal = (state) => state.ifuResultReducer.bpg_file_modal;
export const getRemoveTxInstructionFileModal = (state) => state.ifuResultReducer.remove_tx_instruction_file_modal;
export const getRemoveBpgFileModal = (state) => state.ifuResultReducer.remove_bpg_file_modal;
export const getTxInstructionManualUploadModal = (state) => state.ifuResultReducer.txinstruction_manual_upload_modal;
export const getBpgManualUploadModal = (state) => state.ifuResultReducer.bpg_manual_upload_modal;
export const getPending = (state) => state.ifuResultReducer.pending;
export const getIFUResultSessionError = (state) => state.ifuResultReducer.session_error;
