import React from 'react';
import PaneViewerRecords from './records/pane_viewer_records';
import ViewerManager from './viewer_manager';

import { isSetup, isRecords } from '../../common/functions';

/**
 * Displays the content of a single viewer
 * @component
 */
export default function PaneViewer(props) {
  const is_records = isRecords(props);
  const is_setup = isSetup(props);
  const is_simulation = props.isSmileSimulationSetup;

  const displayPane = props.pane === 1 || props.split;
  return (
    <div id={is_simulation ? 'smile-' + props.id : props.id} className="pane-viewer" style={{ border: is_simulation ? 'solid 1px #2DA4AD' : '' }}>
      {is_simulation && displayPane && <ViewerManager {...props} />}
      {displayPane && is_setup && <ViewerManager {...props} />}
      {is_records && <PaneViewerRecords {...props} />}
    </div>
  );
}
