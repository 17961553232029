/**
 * File: content.js - Display the list of cases in the case list view
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './content.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Internal Components
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import DivTable from '../components/tables/div_table';
import Modal from '../../components/modal/modal';
import NoResult from './no_result';
import Welcome from './welcome';
import NoRecords from './no_records';
import DoctorCaseListSearch from './case_list_search';
import PaginationContainer from '../../components/pagination/pagination_container';
import CaseListFilter from './case_list_filter';
import CaseListDropdown from './case_list_dropdown';
import AdditionalActionsDropdown from './additional_actions_dropdown';
import { UserPermissionsContext } from '../../context/user_permission';
import TreatmentPlan from '../case_details/treatment_plan';
import InstructionViewer from '../case_details/instruction_viewer';
import VBPCViewer from '../case_details/vbpc_viewer';

// Internal Functions
import {
  convertDate,
  formatCaseId,
  getBaseCaseId,
  getRefinementSuffix,
  getRootPath,
  removeCaseIdInitialNumber,
  removeIreqIdInitialNumber,
  setTokenHeader,
} from '../../common/functions';
import {
  getDoctorsIdFromRoute,
  getFilterValueFromRoute,
  getSearchFilterValueFromRoute,
  getSearchValueFromRoute,
  getSelectionValueFromRoute,
  submitIreqRedirect,
  viewAllNotesRedirect,
} from '../../common/route';
import { getPDFNameTemplate, getStatusAlertDefinition, isAfterAFGLaunch, truncateTextByLimit, convertHtmlToPlainText } from '../../common/helpers';
import { tableRowLink } from '../../common/table';
import { onChangeSearch } from '../../common/search';
import { SDSFilterToTitle } from '../../common/url_filter';
import { displayPSTDateTime } from '../../common/date';
import { setOverflowTooltip } from '../../common/tooltip';
import { handleHttpRequestError } from '../../common/error';
import { userHasPermission } from '../../common/permission';
import { isTxPlanRevision2Or3 } from '../../components/setup_viewer/components/common/functions';
import { metaData, colWidth, dsoColWidth, dashboardColWidth, dsoDashboardColWidth } from './constants';

//Redux
import {
  getDoctorRole,
  getAccountLinkId,
  getAdditionalActionsModal,
  getNoteModal,
  getViewPlanModal,
  getViewIfuModal,
  getViewIfuModalType,
  getDeleteNoteModal,
} from '../../redux/reducers/ipp/case_list';
import { getDoctorCaseListTableData, getDoctorCaseListTableLoading, getDoctorCaseListTableError } from '../../redux/reducers/ipp/case_list/case_list_table';
import { fetchDoctorCaseListTable, fetchDoctorCaseListTableError } from '../../redux/actions/ipp/case_list/case_list_table';
import { fetchDoctorCaseFilterCount, fetchLoggedDoctorCaseCount } from '../../redux/actions/ipp/case_list/case_list_filter';
import { getDoctorCaseListFilterCount, getDoctorCaseListFilterCountLoading } from '../../redux/reducers/ipp/case_list/case_list_filter';
import { setViewPlanModal, updateNote, setNoteModal, setViewIfuModal } from '../../redux/actions/ipp/case_list';
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../common/storage';
/**
 * Used to as a container to hold the case list component with search capabilities
 * @component
 * @alias DoctorContent
 * @category IPP
 */
class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor_id: '',
      search: '',
      search_phrase: '',
      search_filter: '',
      filter: '',
      filter_key: '',
      selection: '',
      cases_sort_method: 'default',
      cases_sort_order_ascending: { case_id: true, patient_name: true, submission_date: false, treatment: true, status: true, assigned_to: true },
      cases_row_size: this.props.rowSize ? this.props.rowSize : 15,
      cases_active_page: 1,
      cases_page_range: 5,
      cases_length: 1,
      showCancelCase: false,
      selected_case_id: '',
      selected_draft_id: '',
      selected_first_name: '',
      selected_last_name: '',
      is_searching: false,
      case_selection_counts: {},
      is_filter_count_loading: true,
    };

    this.getRefinementSuffix = getRefinementSuffix;
  }
  /**
   * Fetch the case list page
   * @function
   * @param {Number} [page=1] - The page to fetch
   */
  fetchCaseListPage(page = 1) {
    const { filter, search, search_filter, selection, cases_row_size, doctor_id } = this.state;
    const search_in_route = getSearchValueFromRoute();

    const config = {
      key: this.getFilterKey(),
      type: selection,
      status: this.props.dashboardList ? 'all_action' : search_filter ? search_filter : filter,
      page: page,
      row_size: cases_row_size,
      search: search === search_in_route ? search_in_route : '',
      doctor_id: doctor_id,
      ...this.getSecondarySortMethodAndOrder(),
    };
    this.props.fetchDoctorCaseFilterCount(config);
    this.props.fetchDoctorCaseListTable(config);
  }
  /**
   * Process the filters to get the filter key
   * @function
   * @returns {String} The filter key
   */
  getFilterKey() {
    const { filter, search, search_filter, selection, cases_sort_method, cases_sort_order_ascending } = this.state;

    const filters = [selection, cases_sort_method, cases_sort_order_ascending[cases_sort_method]];
    if (this.state.search) {
      filters.push(search, search_filter);
    } else {
      filters.push(filter);
    }
    return filters.join('-');
  }
  /**
   * Updates `filter_key` when any filter is changed
   * @function
   */
  onChangeFilter = () => {
    const active_page = this.state.cases_active_page;
    this.setState({ filter_key: this.getFilterKey() });
    this.fetchCaseListPage(active_page);
  };
  /**
   * Updates the search on user input
   * @function
   * @param {Object} event - The event Object
   */
  onChangeSearchPhrase = (event) => {
    onChangeSearch(
      this,
      event,
      event.target.value === ''
        ? function () {
            this.setURLRoute();
            this.setState({ cases_active_page: 1 });
          }
        : null
    );
  };

  /**
   * Returns the list data to display
   * @function
   * @returns {List} The case list table data
   */
  getCaseListTablePageData() {
    const { filter_key, cases_active_page } = this.state;
    const { case_list_data } = this.props;

    if (!(filter_key in case_list_data)) {
      return null;
    }

    let list = case_list_data[filter_key][cases_active_page];
    if (this.props.dashboardList && this.props.actionRequiredStatus) {
      list = list.filter((c) => {
        if (c.rebranded_status?.includes('On Hold')) {
          c.status = 'Hold';
        }
        if (c.case_id?.includes('-')) {
          c.case_id = removeIreqIdInitialNumber(c.case_id);
        }
        return this.props.actionRequiredStatus.includes(c.rebranded_status) || c.status === 'Hold';
      });
      this.props.updateCaseList(list);
    }

    return list;
  }

  /**
   * Handles changing to different page
   * @function
   * @param {Number} new_page - The new page to change to
   */
  onChangePage = (new_page) => {
    const { filter_key } = this.state;
    const { case_list_data } = this.props;
    const isPageLoaded = filter_key in case_list_data && case_list_data[filter_key][new_page];
    if (!isPageLoaded) {
      this.fetchCaseListPage(new_page);
    }
    this.setState({ cases_active_page: new_page });
  };
  /**
   * Checks if role is part of a DSO
   * @function
   */
  includesDso = () => {
    return this.props.doctor_role && this.props.doctor_role.includes('DSO');
  };
  /**
   * Retrieves all the parameters from the user selection or search
   * @function
   * @param {Object} params - contains the configuration for the params
   */
  getParams = () => {
    const { search, search_filter, filter, selection } = this.state;
    const params = {};
    if (selection && this.includesDso()) params['selection'] = selection;
    if (search) params['search'] = search;
    if (search_filter) params['search_filter'] = search_filter;
    if (!search_filter && filter) params['filter'] = filter;
    return params;
  };
  /**
   * Construct new route base on params
   * @function
   * @return {String} new_route - contains the configuration for the params
   */
  getNewRoute = () => {
    const params = this.getParams();
    const paramsString = new URLSearchParams(params).toString();
    const route_base = this.props.history.location.pathname;
    const new_route = `${route_base}?${paramsString}`;
    return new_route;
  };
  /**
   * Construct new route base on params
   * @function
   * @param {Object} new_state - the new state to be set
   */
  setURLRoute = (new_state = {}) => {
    this.props.history.replace(this.getNewRoute());
    this.setState(new_state, this.onChangeFilter);
  };
  /**
   * Upon update state upon search button click
   * @function
   */
  onSearchButtonClick = (event) => {
    this.setURLRoute({ cases_active_page: 1, filter: '' });
  };
  /**
   * Handles the action upon dropdown change of filter status
   * @function
   * @param {Object} selection - contains the data for the selection
   */
  onSearchFilterSelection = (selection) => {
    const state = { filter: '' };

    this.setState({ search_filter: selection.value, cases_active_page: 1 }, function () {
      this.setURLRoute(state);
    });
  };
  /**
   * Handles the event when user update a filter value
   * @function
   * @param {Number} new_page - The new page to change to
   */
  onFilterClick = (filter_value) => {
    this.setState({ filter: filter_value, search: '', search_filter: '', cases_active_page: 1 }, function () {
      this.setURLRoute();
    });
  };
  /**
   * Handles the event of the dropdown click
   * @function
   * @param {Object} option - contains the data related to the options
   */
  onSelectionClick = (option) => {
    const invalid_options = ['by_doctor', 'by_location'];
    const value = option.id && option.id.toString() && option.id.toString() === this.state.doctor_id ? '' : option.value;

    if (_.intersection(invalid_options, [value]).length > 0) {
      return;
    }

    this.setState({ selection: value, filter: '', search: '', search_filter: '', cases_active_page: 1 }, function () {
      this.setURLRoute();
    });
  };
  /**
   * Handles the when page is change
   * @function
   * @param {String} active_page - contains the page number to move to
   */
  onPageChange = (active_page) => {
    this.setState({
      cases_active_page: active_page,
    });
    this.fetchCaseListPage(active_page);
  };
  /**
   * Computes the starting range of numbers of page
   * @function
   * @return {Int} The starting number of the page count
   */
  getStartRange() {
    return this.state.cases_active_page * this.state.cases_row_size - this.state.cases_row_size + 1;
  }
  /**
   * Computes the ending range of numbers of page
   * @function
   * @return {Int} The ending number of the page count
   */
  getEndRange() {
    const cases_length = this.getCasesLength();
    const endRange = this.state.cases_active_page * this.state.cases_row_size;
    return Math.min(cases_length, endRange);
  }
  /**
   * Closes the modal
   * @function
   */
  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };
  /**
   * Resulting action of the confirmation of deleting a draft
   * @function
   */
  onModalAccept = () => {
    let that = this;
    if (this.state.selected_case_id && this.state.selected_case_id !== '- - - - -') {
      Axios.delete(`/api/digital/?id=${this.state.selected_case_id}`)
        .then(function (result) {
          that.reloadCases();
          // updates navbar case count
          const doctorId = getDoctorsIdFromRoute();
          that.props.fetchLoggedDoctorCaseCount({ doctorId });
        })
        .finally(() => {
          sendRefreshCaseListMessage();
          sendRefreshDashboardMessage();
        });
    } else if (this.state.selected_draft_id) {
      Axios.delete(`/api/incomplete/?id=${this.state.selected_draft_id}`)
        .then(function (result) {
          that.reloadCases();
          // updates navbar case count
          const doctorId = getDoctorsIdFromRoute();
          that.props.fetchLoggedDoctorCaseCount({ doctorId });
        })
        .finally(() => {
          sendRefreshCaseListMessage();
          sendRefreshDashboardMessage();
        });
    }
  };

  onSortClick = (event) => {
    const { cases_sort_order_ascending: sort_order, cases_sort_method: sort_method } = this.state;
    const new_sort_method = event.currentTarget.dataset.method;

    const is_same_sort_method = sort_method === new_sort_method;
    const new_sort_order = is_same_sort_method
      ? {
          ...sort_order,
          [new_sort_method]: !sort_order[sort_method],
        }
      : sort_order;

    this.setState(
      {
        cases_sort_method: new_sort_method,
        cases_sort_order_ascending: new_sort_order,
      },
      this.onChangeFilter
    );
  };
  /**
   * Returns sort order precedence based on filter
   * @function
   * @returns {Dictionary} filter and sort order
   */
  getSecondarySortMethodAndOrder() {
    const { cases_sort_method, cases_sort_order_ascending } = this.state;
    const sort_order = this.getSortValue(cases_sort_order_ascending[cases_sort_method]);

    switch (cases_sort_method) {
      case 'status':
        return {
          sort_method: ['rebranded_status', 'submission_date'],
          sort_asc: [sort_order, sort_order],
        };
      default:
        return {
          sort_method: [cases_sort_method],
          sort_asc: [sort_order],
        };
    }
  }

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  translateCaseStatusAlert(status) {
    const alert_definition = getStatusAlertDefinition();
    return alert_definition[status] ? alert_definition[status] : false;
  }

  reloadCases = () => {
    this.fetchCaseListPage(this.state.cases_active_page);
    this.fetchCaseTypeFilterCounts();
    this.setState({ showCancelCase: false });
  };

  componentDidMount() {
    setTokenHeader();
    window.$('[data-toggle="tooltip"]').tooltip();

    this.initStateFromRoute();
    this.fetchCaseTypeFilterCounts();
    window.$('.sub-item').click(() => this.onPageChange(1));
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');
    const is_refresh = this.props.location.state?.reset_list;
    const filter = this.props.location.state?.filter;

    if (is_refresh === 'true' && !filter) {
      this.initStateFromRoute();
      this.fetchCaseTypeFilterCounts();
      this.props.history.replace({ state: { reset_list: 'false' } });
    }
  }

  fetchCaseTypeFilterCounts() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    Axios.get(`/apiv3/doctor/${doctor_id}/dso`)
      .then(function (dso_res) {
        that.setState({ case_selection_counts: dso_res.data, is_filter_count_loading: false });
      })
      .catch(function (err) {
        that.setState({ is_filter_count_loading: false });
      });
  }

  /**
   * Initialize state base on route
   * @function
   */
  initStateFromRoute() {
    const doctor_id = getDoctorsIdFromRoute();
    const search = getSearchValueFromRoute();
    const search_filter = getSearchFilterValueFromRoute();
    const filter = getFilterValueFromRoute();
    const selection = getSelectionValueFromRoute();

    const is_searching = Boolean(search || search_filter);
    const { cases_sort_method, cases_sort_order_ascending } = this.getDefaultOrderBaseonFilter(is_searching ? search_filter : filter);

    this.setState(
      {
        doctor_id: doctor_id,
        filter: is_searching ? '' : filter,
        search: search,
        search_filter: search_filter,
        selection: selection,
        is_searching: is_searching,
        cases_sort_method: cases_sort_method,
        cases_sort_order_ascending: cases_sort_order_ascending,
      },
      this.onChangeFilter
    );
  }

  /**
   * Get the default sorting method and ascending base on filter
   * @function
   * @param {String} filter - The filter for default ordering
   * @returns {Object} Object containing the sort method and ascending
   */
  getDefaultOrderBaseonFilter = (filter) => {
    let sort_method = this.state.cases_sort_method;
    let sort_asc = true;
    switch (filter) {
      case 'case_id':
        sort_method = filter;
        sort_asc = true;
        break;
      case 'patient_name':
        sort_method = filter;
        sort_asc = true;
        break;
      case 'submission_date':
        sort_method = filter;
        sort_asc = false;
        break;
      case 'treatment':
        sort_method = filter;
        sort_asc = true;
        break;
      case 'status':
        sort_method = filter;
        sort_asc = true;
        break;
      case 'assigned_to':
        sort_method = filter;
        sort_asc = true;
        break;

      default:
    }
    const new_sort_ascending = {
      ...this.state.cases_sort_order_ascending,
      [sort_method]: sort_asc,
    };
    return { cases_sort_method: sort_method, cases_sort_order_ascending: new_sort_ascending };
  };

  /**
   * Obtains the current list's number of cases
   * @function
   * @returns {Number} The length of the current list
   */
  getCasesLength() {
    const { filter, filter_key, search_filter } = this.state;
    const { case_filter_count, case_list_data } = this.props;

    if (search_filter) {
      const filterData = case_list_data[filter_key];
      return filterData ? filterData[1].length : 0;
    }

    const filterCount = case_filter_count[filter];

    if (Number.isInteger(filterCount)) {
      return filterCount;
    }

    const allFilterCount = case_filter_count['all'];

    if (Number.isInteger(allFilterCount)) {
      return allFilterCount;
    }

    return 0;
  }

  componentWillUnmount() {
    window.$('.sub-item').off('click');
    this.props.setViewPlanModal(false);
    this.props.setNoteModal(false);
    this.props.setViewIfuModal(false);
  }

  /**
   *Handles on action required status clicked
   * @function
   * @param {string} status - Clicked on case status
   * @param {string} caseId - Case id
   * @param {boolean} isWasmEnabled - If case is wasm viewer enabled
   * @param {boolean} block - If click is diabled
   */
  onActionRequiredClick = (status, caseId, isWasmEnabled, block) => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const setupURL = isWasmEnabled === 'true' ? `/smile_design/${caseId}` : `/setup/${caseId}`;
    const openNewWindow = (url) => {
      window.open(url);
    };

    if (!block) {
      switch (status) {
        case 'Review Smile Design':
          openNewWindow(setupURL);
          break;

        case 'Pending Doctor Clarification':
          openNewWindow(`${rootPath}/case/${caseId}`);
          break;

        case 'Upload New Records':
          openNewWindow(`${rootPath}/upload/${caseId}`);
          break;

        case 'On Hold':
          if (caseId?.includes('-IR')) {
            openNewWindow(`${rootPath}/item/${getBaseCaseId(caseId)}`);
          }
          openNewWindow(`${rootPath}/case/${caseId}`);
          break;

        case 'Draft Digital Enhancement':
          openNewWindow(`${rootPath}/digital/overview?caseid=${caseId}&doctorid=${this.state.doctor_id}`);
          break;

        default:
          openNewWindow(`${rootPath}/case/${caseId}`);
          break;
      }
    }
  };

  onCaseTrClick = (event) => {
    const status = event.currentTarget.dataset.status;
    const caseId = formatCaseId(event.currentTarget.dataset.case_id);
    const text = event.target.innerText;
    const doctorId = getDoctorsIdFromRoute();
    const draftCaseId = event.currentTarget.dataset.draft_case_id;
    const draftDoctorId = event.currentTarget.dataset.draft_doctor_id;
    const draftDoctorRole = event.currentTarget.dataset.draft_doctor_role;
    const isWasmEnabled = event.currentTarget.dataset.is_wasm_viewer_enabled;
    const draft = 'Draft Submission';
    const block = event?.target?.className?.indexOf('block-tr') >= 0 || event.target.className.includes('disabled');
    const openInNewTab = true;
    let rootPath = getRootPath(this.props.history.location.pathname);
    let pathname = '';
    let that = this;

    if (
      text === 'Tracking Info' ||
      event.target.dataset.additionalactions ||
      this.props.additional_actions_modal ||
      this.props.note_modal ||
      this.props.delete_note_modal ||
      this.props.view_plan_modal
    ) {
      return;
    }

    if (event.target.className.includes('action-required') && !text?.includes(draft)) {
      this.onActionRequiredClick(text, caseId, isWasmEnabled, block);
      return;
    }

    if (event.target && event.target.parentElement && event.target.parentElement.parentElement && !event.target.parentElement.parentElement.className.animVal) {
      if (!block) {
        if (status !== draft) {
          pathname = caseId?.includes('-IR') ? `${rootPath}/item/${getBaseCaseId(caseId)}` : `${rootPath}/case/${formatCaseId(caseId)}`;

          tableRowLink(event, pathname, that.props, false, '', openInNewTab);
        } else {
          if (draftDoctorRole && draftDoctorRole.includes('DSO') && draftDoctorId !== doctorId) {
            pathname = `${rootPath}/submission/patient?case_id=${draftCaseId}&doctor_id=${draftDoctorId}`;
          } else {
            pathname = `${rootPath}/submission/patient?case_id=${draftCaseId}`;
          }

          tableRowLink(event, pathname, that.props, `?case_id=${draftCaseId}`, '', openInNewTab);
        }
      }
    }
  };
  /**
   * Displays search bar and dropdown status menu
   * @function
   * @return {JSX} JSX element for search bar and dropdown
   */
  displaySearchFilters = () => {
    const selection_counts = this.state.case_selection_counts;
    const statuses = this.props.case_filter_count[this.state.selection];
    const has_case_list_data = this.hasCaseListData();

    if (!this.props.dashboardList)
      return (
        <div className="statuses">
          <DoctorCaseListSearch
            search={this.state.search}
            search_filter={this.state.search_filter}
            onChangeSearch={this.onChangeSearchPhrase}
            onSearchButtonClick={this.onSearchButtonClick}
            onSearchFilterSelection={this.onSearchFilterSelection}
          />
          {!_.isEmpty(selection_counts) && (
            <CaseListDropdown case_selection_counts={selection_counts} selection={this.state.selection} onSelectionClick={this.onSelectionClick} />
          )}

          {this.props.case_filter_count && has_case_list_data && (
            <CaseListFilter
              filter={this.state.filter}
              statuses={statuses}
              loading={this.props.case_filter_count_loading}
              is_dso={this.includesDso()}
              onFilterClick={this.onFilterClick}
            />
          )}
        </div>
      );
  };

  /**
   * Determines if the action functionality is allowed
   *
   * @param {Number} doctor_id - The id associated with the draft
   * @return {Boolean} Returns allowable role to perform action
   */
  doctorCanEdit = (doctor_id) => {
    const doctorRole = this.props.doctor_role ? this.props.doctor_role : this.props.doctorInfo?.role;
    return (
      doctorRole === 'DSO' ||
      doctorRole === 'DSO_Doctor_Override' ||
      doctorRole === 'Doctor' ||
      (doctorRole === 'DSO_Doctor' && this.state.doctor_id && doctor_id && doctor_id.toString() === this.state.doctor_id.toString())
    );
  };

  getSubmissionDate = (props, cases, isMobile = false) => {
    const is_draft = cases.status.includes('Draft');
    return (
      <div className={`div-table--item-restrict-text ${isMobile ? 'case-list-mobile-submission-date' : ''}`}>
        {convertDate(cases.submission_date) && !is_draft ? convertDate(cases.submission_date) : '- - - - -'}
      </div>
    );
  };
  /**
   * Get the status badge for a case
   *
   * @param {object} props - Component props
   * @param {object} cases - Case data
   * @param {object} fields - Case fields
   * @param {boolean} is_mobile - Whether the app is running on a mobile device
   * @returns {JSX.Element} - Status badge JSX element
   */
  getStatus = (props, cases, fields, is_mobile = false) => {
    const caseStatus = cases.rebranded_status;
    const tooltipStatuses = ['On Hold', 'Cancelled', 'Fabricating Case'];
    const statusesToHideDetails = ['On Hold', 'Cancelled'];
    const shouldDisplayTooltip = tooltipStatuses.some((status) => caseStatus.includes(status));

    const isActionRequired = this.translateCaseStatusAlert(cases.status);
    const baseBadgeClass = 'badge--status badge--';
    let badgeClass = isActionRequired ? `${baseBadgeClass}primary action-required` : `${baseBadgeClass}default`;

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          badgeClass = this.checkStatuPermission(user_roles_and_permissions.permissions, cases, badgeClass);

          return (
            <div
              className={`div-table--item-restrict-text div-table--item-restrict-text--${isActionRequired ? 'primary' : 'default'} ${
                isActionRequired ? 'case-list-action-button' : ''
              }`}
            >
              {shouldDisplayTooltip ? (
                <span
                  className={badgeClass}
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title={truncateTextByLimit(caseStatus.substring(caseStatus.indexOf('-') + 1), 200)}
                >
                  {caseStatus.includes('Fabricating Case') || (is_mobile && !statusesToHideDetails.some((term) => caseStatus.includes(term)))
                    ? caseStatus
                    : caseStatus.split(' -')[0]}
                </span>
              ) : caseStatus === 'Shipped' ? (
                <span className={badgeClass}>
                  {caseStatus}{' '}
                  {cases.hand_delivery_ind ? (
                    <span>- Hand Delivered</span>
                  ) : cases.tracking_info && cases.tracking_info !== 'NA' && cases.tracking_info !== 'N/A' ? (
                    <span>
                      <span>- </span>
                      <a className="underlined" href={cases.tracking_url} target="_blank" rel="noopener noreferrer">
                        Tracking Info
                      </a>
                    </span>
                  ) : null}
                </span>
              ) : (
                <span className={badgeClass}>{caseStatus}</span>
              )}
              {cases.clinical_review && caseStatus !== 'Cancelled' && (
                <div className="clinical--icon" data-toggle="tooltip" data-placement="top" data-original-title="Clinical Review">
                  CR
                </div>
              )}
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Check if user has permission to action on case status
   * @function
   * @param {array} permissions - Props object
   * @param {object} cases - Case object
   * @param {string} badgeClass - status class
   * @returns {string} - Updated case status class
   */
  checkStatuPermission = (permissions, cases, badgeClass) => {
    let doctorId = '-1';
    if (!!cases?.dso_doctor_id) {
      doctorId = cases.dso_doctor_id;
    } else if (!!cases?.draft_doctor_id) {
      doctorId = cases.draft_doctor_id
    }
    const isAllowedToUploadNewRecords = this.doctorCanEdit(doctorId);
    const hasIPPEditPermission = userHasPermission('IPP_EDIT', permissions);
    const disabledClass = ' disabled';
    const disabledStatuses = ['Upload New Records', 'Draft Submission', 'Draft Digital Enhancement'];
    const isDisabledStatus = disabledStatuses.includes(cases?.rebranded_status);

    if ((!hasIPPEditPermission || !isAllowedToUploadNewRecords) && isDisabledStatus) {
      return badgeClass + disabledClass;
    }
    return badgeClass;
  };

  /**
   * Retrieves most recent patient note to display for case
   * @function
   * @param {object} props - Props object
   * @param {object} cases - Case object
   * @returns {JSX} - JSX for patient note
   */
  getNote = (props, cases) => {
    let note = { id: '', subject: '', text: '', date: '', created_by_id: '' };
    if (cases.patient_note_info) {
      note = JSON.parse(cases.patient_note_info);
      note.text = cases.patient_note_text;
      note.subject = cases.patient_note_subject;
    }
    const note_text = note.id && note.text ? convertHtmlToPlainText(note.text) : '';
    const id = cases.draft_case_id ? cases.draft_case_id : cases.case_id;

    return (
      <div className="div-table--item-notes">
        <div className={`div-table--item-notes-content${!note?.id ? ' div-table--item-notes-content--not-applicable' : ''}`}>
          {note?.id && (
            <div className="note-tooltip" id={id} data-toggle="tooltip" data-placement="top">
              <div className="restrict-text-single-row row-label">
                {displayPSTDateTime(note.date)}
                {note.subject ? ` - ${note.subject.toUpperCase()}` : ''}
              </div>
              <div className="restrict-text" ref={(ref) => setOverflowTooltip(ref, id, note_text)}>
                {note_text}
              </div>
            </div>
          )}
        </div>
        {this.displayAdditionalActions(cases, note)}
      </div>
    );
  };

  /**
   * Displays additional actions menu
   * @function
   * @param {object} cases - Selected case
   * @param {object} note - Most recent patient note
   * @returns {JSX} - JSX for additional actions
   */
  displayAdditionalActions = (cases, note) => {
    const tx_plan = cases.tx_plan ? JSON.parse(cases.tx_plan) : null;
    const doctor_info = cases.doctor_info ? JSON.parse(cases.doctor_info) : null;
    const case_doctor_id = cases.draft_doctor_id ? cases.draft_doctor_id.toString() : cases.dso_doctor_id ? cases.dso_doctor_id.toString() : doctor_info?.id;
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const can_delete_draft = cases.status.includes('Draft')
            ? userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions) && this.doctorCanEdit(cases.draft_doctor_id)
            : false;
          return (
            <AdditionalActionsDropdown
              id={cases.case_id ? cases.case_id : cases.draft_case_id.toString()}
              case_status={cases.rebranded_status}
              note={note}
              patient_name={cases.patient_name}
              updateNote={this.props.updateNote}
              user_roles_and_permissions={user_roles_and_permissions}
              displayForm={this.displayTxPlan}
              can_view_plan={!cases.historical && tx_plan?.revision !== 'TX0.0'}
              submitIreqRedirect={this.submitIreqRedirect}
              viewAllNotesRedirect={(case_id) => viewAllNotesRedirect(case_id, this.state.doctor_id, this.props)}
              instructions_file={cases.instructions_file ? JSON.parse(cases.instructions_file) : null}
              bpg_file={cases.bpg_file ? JSON.parse(cases.bpg_file) : null}
              afg_file={cases.afg_file ? JSON.parse(cases.afg_file) : null}
              displayIfuForm={this.displayIfuForm}
              gen_2={cases.gen_2}
              can_delete_draft={can_delete_draft}
              openDeleteCaseModal={this.openDeleteCaseModal}
              case_doctor_id={case_doctor_id}
            />
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Retrieves class name for table cell
   * @function
   * @param {object} cases - Case object
   * @param {heading} heading - Header object
   * @returns {string} - Class name
   */
  getClassName = (cases, heading) => {
    if (heading.id === 'notes') {
      return 'div-table--item-multiple-rows';
    } else if (!cases.case_id) {
      return 'div-table--item-single-row';
    }
    return '';
  };

  displayNoResult = () => {
    return (
      <div className="statuses-result-container">
        <div className="statuses-result">
          <NoRecords />
        </div>
      </div>
    );
  };
  /**
   * Checks to see if a case list has data
   * @function
   * @returns {Boolean} true if the object is not empty
   */
  hasCaseListData = () => {
    return !(Object.keys(this.props.case_list_data).length === 0);
  };

  /**
   * Checks to see if a case list has data other than default filter
   * @function
   * @returns {Boolean} true if the object is not empty
   */
  hasDefaultCaseListData = () => {
    const all_cases = this.state.case_selection_counts.dso_statuses?.all_cases > 0;
    const my_cases = this.props.case_filter_count['']?.all;
    return my_cases || all_cases;
  };

  /**
   * Displays tx/de plan pdf in a modal
   * @function
   * @param {string} case_id - Case id
   * @returns {JSX} - JSX for tx plan
   */
  displayTxPlan = (case_id) => {
    const cases = this.getCaseListTablePageData().find((c) => case_id === c.case_id);
    if (cases) {
      const patient_info = cases.patient_info ? JSON.parse(cases.patient_info) : null;
      const doctor_info = cases.doctor_info ? JSON.parse(cases.doctor_info) : null;
      const tx_plan = cases.tx_plan ? JSON.parse(cases.tx_plan) : null;
      const txplan_data = cases.tx_plan_data ? JSON.parse(cases.tx_plan_data) : null;
      const patient_name = `${patient_info?.first_name} ${patient_info?.last_name}`;

      if (isTxPlanRevision2Or3(tx_plan?.revision)) {
        const plan_type = tx_plan.revision.includes('TX') ? 'Treatment Plan' : 'Digital Enhancement';
        return (
          <Modal
            preset="pdf_viewer"
            header_text={`${plan_type}${patient_name ? ` - ${patient_name}` : ''}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={tx_plan?.file}
            onCloseButtonClick={() => this.props.setViewPlanModal(false)}
            original_filename={getPDFNameTemplate(case_id, patient_info, `${plan_type.replace(' ', '_')}.pdf`)}
          />
        );
      }
      return (
        <TreatmentPlan
          selectedPatient={patient_info}
          caseData={txplan_data}
          caseId={case_id}
          arch={tx_plan?.arch}
          doctorfirstName={doctor_info?.first_name}
          doctorlastName={doctor_info?.last_name}
          onModalDismiss={() => this.props.setViewPlanModal(false)}
          revision={tx_plan?.revision}
        />
      );
    }
    return null;
  };

  /**
   * Displays IFU form pdf in a modal
   * @function
   * @param {string} case_id - Case id
   * @returns {JSX} - JSX for IFU form
   */
  displayIfuForm = (case_id) => {
    const cases = this.getCaseListTablePageData().find((c) => case_id === c.case_id);
    switch (this.props.view_ifu_modal_type) {
      case 'instructions':
        return this.displayInstructions(cases);
      case 'afg':
        return this.displayAfg(cases, 'afg');
      case 'bpg':
        return this.displayAfg(cases, 'bpg');
      default:
        return null;
    }
  };

  /**
   * Displays tx/de instructions pdf in a modal
   * @function
   * @param {object} cases - Selected case
   * @returns {JSX} - JSX for instructions
   */
  displayInstructions = (cases) => {
    if (cases && cases.instructions_file) {
      const file_info = JSON.parse(cases.instructions_file);
      const patient_info = cases.patient_info ? JSON.parse(cases.patient_info) : null;
      return (
        <InstructionViewer
          patientName={cases.patient_name}
          onButtonClick={() => this.props.setViewIfuModal(false)}
          photoUpload={[file_info]}
          case_id={cases.case_id}
          patient_info={patient_info}
        />
      );
    }
    return null;
  };

  /**
   * Displays AFG pdf in a modal
   * @function
   * @param {object} cases - Selected case
   * @param {string} file_type - File type (afg, or bpg for gen 1 cases)
   * @returns {JSX} - JSX for AFG
   */
  displayAfg = (cases, file_type) => {
    if (cases) {
      const files = {
        afg: cases.afg_file,
        bpg: cases.bpg_file,
      };
      if (files[file_type]) {
        const patient_info = cases.patient_info ? JSON.parse(cases.patient_info) : null;
        let file_info = JSON.parse(files[file_type]);
        file_info.gen_2 = cases.gen_2;
        file_info.post_afg = isAfterAFGLaunch(file_info.created_date) && file_info.file_type.indexOf('bpg_manual') >= 0;
        return (
          <VBPCViewer
            patientName={cases.patient_name}
            onButtonClick={() => this.props.setViewIfuModal(false)}
            photoUpload={[file_info]}
            case_id={cases.case_id}
            patient_info={patient_info}
          />
        );
      }
    }
    return null;
  };

  /**
   * Redirects page to item request submission
   * @function
   * @param {string} case_id - Case id
   */
  submitIreqRedirect = (case_id) => {
    const cases = this.getCaseListTablePageData().find((c) => case_id === c.case_id);
    if (cases) {
      const is_active_de = case_id.includes('-DE') && cases.status !== 'Case Shipped';
      if (is_active_de) {
        const that = this;
        Axios.get(`/apiv3/case/${case_id}`)
          .then(function (result) {
            if (result?.data?.last_shipped_case_id) {
              submitIreqRedirect(result.data.last_shipped_case_id, that.props);
            }
          })
          .catch(function (err) {
            handleHttpRequestError(err, that);
          });
      } else {
        submitIreqRedirect(case_id, this.props);
      }
    }
  };

  /**
   * Opens modal for deleting draft
   * @function
   * @param {string} id - Draft id
   */
  openDeleteCaseModal = (id) => {
    const cases = this.getCaseListTablePageData().find((c) => (c.draft_case_id ? id.toString() === c.draft_case_id.toString() : id === c.case_id));
    this.setState({
      showCancelCase: true,
      selected_case_id: cases.case_id,
      selected_draft_id: cases.draft_case_id,
    });
  };

  /**
   * Get case list colum width by condition
   * @function
   * @param {string} col - Collum name
   * @param {boolean} showAssignedTo - should show assigned to collum
   * @return {string} collum width percentage
   */
  getColWidth = (col, showAssignedTo) => {
    if (showAssignedTo && this.props.dashboardList) return dsoDashboardColWidth[col];
    else if (showAssignedTo) return dsoColWidth[col];
    else if (this.props.dashboardList) return dashboardColWidth[col];
    else return colWidth[col];
  };

  /**
   * Display the action required count
   * @function
   * @param {object} caselistData - case list data
   * @return {JSX} collum width percentage
   */
  displayActionRequiredCount = (caselistData) => {
    if (this.props.dashboardList && !this.props.case_list_table_loading && !this.state.is_filter_count_loading && !this.props.doctorInfoLoading)
      return (
        <div className="dashboard-title">
          <h3> Action Required</h3>
          <span className="badge badge--action">{caselistData?.length}</span>
        </div>
      );
  };

  render() {
    const that = this;
    const tableData = this.getCaseListTablePageData();
    const has_case_list_data = this.hasDefaultCaseListData();

    const hasTableData = tableData?.length > 0;
    const doctor_role = this.props.doctor_role;
    const sub_dso_doctor_role = doctor_role && (doctor_role.includes('DSO_Doctor') || doctor_role.includes('DSO_Doctor_Override'));
    const canSort = !this.props.dashboardList;
    const caselistData = this.getCaseListTablePageData();
    const showAssignedTo =
      doctor_role === 'DSO' ||
      this.props.showAssignedTo ||
      (doctor_role?.includes('DSO') && (this.state.selection === 'all_cases' || this.state.selection?.includes('location')));

    if (this.props.case_list_table_error) {
      //OB-2391 requested by QA: display 404 error for an invalid URL
      this.props.fetchDoctorCaseListTableError(null); //clear error
      this.props.history.push('/404');
    }

    const table_data = [
      {
        id: 'patient_name',
        label: 'Patient',
        col_width: this.getColWidth('patient_name', showAssignedTo),
        display: true,
        data_fields: ['patient_name'],
        can_sort: canSort,
        get_class_name: that.getClassName,
        data_function: (props, data) => (
          <>
            {data['case_id'] && (
              <div className="fs-exclude">
                <span className="case-id-label row-label div-table--item-restrict-text">{removeCaseIdInitialNumber(data['case_id'])}</span>
                {data['gen_2'] && <span className="case-gen2-text div-table--item-restrict-text">(Gen 2.0)</span>}
              </div>
            )}
            <div className="fs-exclude div-table--item-restrict-text">
              {data['patient_name'] && data['patient_name'] !== ' ' ? data['patient_name'] : '- - - - -'}
            </div>
          </>
        ),
      },
      {
        id: 'submission_date',
        label: 'Submitted',
        col_width: this.getColWidth('submission_date', showAssignedTo),
        display: true,
        data_fields: ['submission_date'],
        can_sort: canSort,
        get_class_name: that.getClassName,
        data_function: (props, data) => that.getSubmissionDate(props, data, false),
      },
      {
        id: 'status',
        label: 'Status',
        col_width: this.getColWidth('status', showAssignedTo),
        display: true,
        data_fields: [],
        can_sort: canSort,
        get_class_name: that.getClassName,
        data_function: that.getStatus,
      },
      {
        id: 'doctor_name',
        label: 'Assigned To',
        col_width: this.getColWidth('doctor_name', showAssignedTo),
        display: showAssignedTo,
        data_fields: [],
        can_sort: canSort,
        get_class_name: that.getClassName,
        data_function: (props, data, fields) => {
          return (
            <div className="div-table--item-restrict-text">
              <span>{data.status.includes('Draft') && data.draft_doctor_role === 'DSO' ? '- - - - -' : data.doctor_name}</span>
            </div>
          );
        },
      },
      {
        id: 'notes',
        label: 'Notes',
        col_width: this.getColWidth('notes', showAssignedTo),
        display: canSort,
        data_fields: [],
        can_sort: false,
        get_class_name: that.getClassName,
        data_function: that.getNote,
      },
    ];

    const mobile_data = [
      {
        id: 'patient_name',
        label: 'Patient',
        col_width: this.getColWidth('patient_name', showAssignedTo),
        display: true,
        data_fields: ['patient_name'],
        can_sort: canSort,
        get_class_name: that.getClassName,
        data_function: (props, data) => {
          return (
            <>
              {data['case_id'] ? (
                <div className="fs-exclude">
                  <span className="row-label div-table--item-restrict-text">{removeCaseIdInitialNumber(data['case_id'])}</span>{' '}
                  {data['gen_2'] ? <span className="case-gen2-text div-table--item-restrict-text">(Gen 2.0)</span> : null}
                </div>
              ) : null}
              <div className="fs-exclude div-table--item-restrict-text">
                {data['patient_name'] && data['patient_name'] !== ' ' ? data['patient_name'] : '- - - - -'}
              </div>
              {showAssignedTo ? (
                <div className="fs-exclude div-table--item-restrict-text doctor-name">
                  {data.status.includes('Draft') && data.draft_doctor_role === 'DSO' ? '- - - - -' : data.doctor_name}
                </div>
              ) : null}
            </>
          );
        },
      },
      {
        id: 'submission_date',
        data_fields: ['submission_date'],
        tableItemStyle: { alignSelf: 'baseline' },
        data_function: (props, data) => that.getSubmissionDate(props, data, true),
      },
      {
        id: 'status',
        new_row: true,
        data_fields: [],
        data_function: (props, cases, fields) => that.getStatus(props, cases, fields, true),
      },
    ];

    if (
      !this.props.case_list_table_loading &&
      ((!hasTableData && this.state.search && this.state.search !== '') || (!hasTableData && this.state.search_filter && this.state.search_filter !== ''))
    ) {
      return (
        <>
          <Helmet>
            <title>
              Case List{this.state.search ? ' - ' + this.state.search : this.state.filter ? ' - ' + SDSFilterToTitle(this.state.filter) : ''} | InBrace Smile
              Design Studio™
            </title>
          </Helmet>
          <ContentHeader title="Case List" />
          <div className="list-content">
            {this.displaySearchFilters()}
            <div className="statuses-result-container">
              <div className="statuses-result">
                <NoResult query={this.state.search} filter={this.state.search_filter} />
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      !this.state.selection.includes('doctor') &&
      !this.state.selection.includes('location') &&
      !hasTableData &&
      !has_case_list_data &&
      (this.state.search === '' || this.state.search === undefined) &&
      this.state.filter === '' &&
      !this.props.dashboardList
    ) {
      return this.props.case_list_table_loading || this.state.is_filter_count_loading ? (
        <CircleLoader fullscreen={true} />
      ) : !hasTableData && sub_dso_doctor_role && has_case_list_data ? (
        <>
          <Helmet>
            <title>
              Case List{this.state.search ? ' - ' + this.state.search : this.state.filter ? ' - ' + SDSFilterToTitle(this.state.filter) : ''} | InBrace Smile
              Design Studio™
            </title>
          </Helmet>
          <ContentHeader title="Case List" />
          <div className="list-content">
            {this.displaySearchFilters()}
            {this.displayNoResult()}
          </div>
        </>
      ) : (
        <Welcome
          gen_2={
            this.state.case_selection_counts && this.state.case_selection_counts.doctor && this.state.case_selection_counts.doctor[0]
              ? this.state.case_selection_counts.doctor[0].gen_2
              : false
          }
          doctor_id={this.state.doctor_id}
        />
      );
    } else {
      const dashboardClass = this.props.dashboardList ? 'statuses-result--dashboard scrollbar--dark' : '';
      const emptyClass = !caselistData || caselistData.length === 0 ? 'statuses-result--empty' : '';
      const classNames = `statuses-result ${dashboardClass} ${emptyClass}`;

      return (
        <>
          <Helmet>
            <title>
              {this.props.dashboardList ? 'Dashboard' : 'Case List'}
              {this.state.search ? ' - ' + this.state.search : this.state.filter ? ' - ' + SDSFilterToTitle(this.state.filter) : ''} | InBrace Smile Design
              Studio™
            </title>
          </Helmet>

          <ContentHeader title="Case List" />
          <div className="list-content">
            {this.displaySearchFilters()}

            {!this.props.case_list_table_loading &&
            (((this.state.filter || sub_dso_doctor_role) && !hasTableData) ||
              (((this.state.selection && this.state.selection.includes('doctor')) || (this.state.selection && this.state.selection.includes('location'))) &&
                !hasTableData)) ? (
              this.displayNoResult()
            ) : (
              <div className="statuses-result-container">
                {this.displayActionRequiredCount(caselistData)}
                <div className={classNames}>
                  <CardContainer type="top-border" className="hide-on-mobile--top-border">
                    <DivTable
                      meta_data={metaData}
                      table_data={table_data}
                      mobile_data={mobile_data}
                      field_data={caselistData}
                      sort_method={this.state.cases_sort_method}
                      sort_order_ascending={this.state.cases_sort_order_ascending}
                      showCancelCase={this.state.showCancelCase}
                      hideColumnName={this.props.hideColumnName ? true : false}
                      selected_id={this.state.selected_case_id}
                      selected_draft_id={this.state.selected_draft_id}
                      onSortClick={this.onSortClick}
                      onCaseTrClick={this.onCaseTrClick}
                      getRefinementSuffix={this.getRefinementSuffix}
                      showAssignedTo={showAssignedTo}
                      isLoading={this.props.case_list_table_loading}
                    />
                  </CardContainer>
                </div>
                <div className="pagination__container">
                  <PaginationContainer
                    hideDisabled={this.props.dashboardList}
                    theme="ipp"
                    type="Cases"
                    activePage={this.state.cases_active_page}
                    itemsCountPerPage={this.state.cases_row_size}
                    totalItemsCount={this.getCasesLength()}
                    pageRangeDisplayed={this.state.cases_page_range}
                    onChange={this.onChangePage}
                    startRange={this.getStartRange()}
                    endRange={this.getEndRange()}
                  />
                </div>
              </div>
            )}
          </div>

          {this.state.showCancelCase === true ? (
            <Modal
              preset="decision"
              header_text={
                this.state.selected_first_name && this.state.selected_last_name
                  ? 'Delete Draft - ' + this.state.selected_first_name + ' ' + this.state.selected_last_name
                  : 'Delete Draft'
              }
              modal_class="modal-content-warning"
              message_text={
                <span>
                  Are you sure you want to delete this draft{' '}
                  {this.state.selected_case_id && this.state.selected_case_id !== '- - - - -' ? 'digital enhancement' : 'submission'}?
                  <br />
                  You will not be able to undo this action if you proceed.
                </span>
              }
              confirm_btn_text="Delete"
              close_btn_text="Cancel"
              after_confirm={true}
              after_confirm_text="Deleting Draft..."
              onCloseButtonClick={this.onModalDismiss}
              onConfirmButtonClick={this.onModalAccept}
            />
          ) : null}
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    doctor_role: getDoctorRole(state),
    account_link_id: getAccountLinkId(state),
    case_filter_count: getDoctorCaseListFilterCount(state),
    case_filter_count_loading: getDoctorCaseListFilterCountLoading(state),
    case_list_data: getDoctorCaseListTableData(state),
    case_list_table_loading: getDoctorCaseListTableLoading(state),
    case_list_table_error: getDoctorCaseListTableError(state),
    additional_actions_modal: getAdditionalActionsModal(state),
    note_modal: getNoteModal(state),
    delete_note_modal: getDeleteNoteModal(state),
    view_plan_modal: getViewPlanModal(state),
    view_ifu_modal: getViewIfuModal(state),
    view_ifu_modal_type: getViewIfuModalType(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchLoggedDoctorCaseCount,
      fetchDoctorCaseFilterCount: fetchDoctorCaseFilterCount,
      fetchDoctorCaseListTable: fetchDoctorCaseListTable,
      fetchDoctorCaseListTableError: fetchDoctorCaseListTableError,
      updateNote: updateNote,
      setViewPlanModal: setViewPlanModal,
      setViewIfuModal: setViewIfuModal,
      setNoteModal: setNoteModal,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
