/**
 * File:quantity.js - Component to input item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FileUpload from '../../components/file_upload/file_upload';

class PrUpload extends Component {
  render() {
    return (
      <div className="bpp-portal-view">
        <div className="ir-submission-heading">
          Upload Progress Record - Step{' '}
          {this.props.history &&
          this.props.history.location &&
          this.props.history.location.pathname &&
          this.props.history.location.pathname.includes('csqsubmit')
            ? '2 of 3'
            : '1 of 2'}
        </div>
        <div>
          <span className="bpp-pr-upload-date-label">Please specify date the records were taken:</span>
          <input
            type="date"
            className={
              this.props.error && !this.props.upload_date
                ? 'form-control patient-field pr-upload-date warning-border'
                : 'form-control pr-upload-date patient-field'
            }
            placeholder={'mm/dd/yyyy'}
            id="pr_date_taken"
            data-id="pr_date_taken"
            data-name="pr_date_taken"
            value={this.props.upload_date}
            onChange={this.props.onTextChange}
            onBlur={this.props.onBlur}
          />
          <div className="extra-padding-headline" />
        </div>
        <form>
          <FileUpload
            id={this.props.id}
            filesUploaded={this.props.photoUpload}
            onUpload={this.props.onUpload}
            onRemove={this.props.onRemove}
            onIncompleteSave={this.props.onIncompleteSave}
            multiple={true}
            location="progress_record"
            folder="progress_records"
            type="progress_records"
            upload_state={this.props.upload_state}
            upload_content={this.props.upload_content}
            show_warning={this.props.show_warning}
            hide_warning={this.props.hide_warning}
            mode={this.props.mode}
            loader_type={'bpp'}
            theme={'bpp'}
            removeAllowed={true}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(PrUpload);
