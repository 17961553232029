import './account_settings.scss';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDoctorsIdFromRoute } from '../../common/route';
import { Helmet } from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import React, { Component } from 'react';

import Axios from 'axios';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import Content from './content';
import ContentHeader from '../components/content_header';
import CustomerAgreement from '../customer_agreement/customer_agreement';
import { ReactComponent as InLogo } from './icons/in.svg';
import NotFound from '../404/not_found';
// Redux
import { fetchAccountSettings } from '../../redux/actions/ipp/account_settings';
import { getAccountSetting } from '../../redux/reducers/ipp/account_settings';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'settings',
      loading: true,
      terms: false,
      isEnrolledToiTeroIntegration: false,
    };
  }

  componentDidMount() {
    const doctor_id = getDoctorsIdFromRoute();
    this.setPage(doctor_id);
    this.props.fetchAccountSettings(doctor_id);

    let that = this;

    Axios.get(`/apiV2/agreement`)
      .catch(function (err) {
        that.setState({
          loading: false,
          terms: true,
        });
      });
  }

  componentDidUpdate(prevProps) {
    const doctor_id = getDoctorsIdFromRoute();
    this.setPage(doctor_id);

    if (prevProps.account !== this.props.account) {
      this.setState({
        loading: false,
        isEnrolledToiTeroIntegration: this.props.account?.program_enrollment?.includes('itero_integration') || false,
      });
    }
  }

  setPage(doctor_id) {
    let path = this.props.history.location.pathname;
    const page = path.split('/').pop();
    if (page !== this.state.page) {
      this.setState({
        page: page,
      });
    }
    if (page === 'account') {
      this.props.history.push(`/portal/${doctor_id}/account/settings`);
    }
  }

  /**
   * Check if url is valid
   * @function
   * @returns {Boolean} True if url access is valid
   */
  hasError = () => {
    let path = this.props.history.location.pathname;
    const page = path.split('/').pop();
    if (page === 'locator' && this.props.account && this.props.account.role && this.props.account.role !== 'Doctor' && this.props.account.role !== 'DSO') {
      return true;
    }
    return false;
  };

  render() {
    if (this.state.loading) {
      return <CircleLoader fullscreen={true} />;
    } else if (this.hasError()) {
      return <NotFound />;
    } else if (this.state.terms) {
      return <CustomerAgreement />;
    } else {
      return (
        <>
          <Helmet>
            <title>Account Settings | InBrace Smile Design Studio™</title>
          </Helmet>
          <ContentHeader title={'Account Settings - ' + this.props.account.first_name + ' ' + this.props.account.last_name} />
          <div className="content-body">
            <div className="account-summary-container">
              <CardContainer className="pad-md">
                <div className="case-tab-container">
                  <div className="case-tab__item-container">
                    <Link className={this.state.page === 'settings' ? 'case-tab__item case-tab__item--active' : 'case-tab__item'} to={'settings'}>
                      <FontAwesomeIcon icon={['fas', 'plus-square']} /> General Settings
                    </Link>
                  </div>
                  {this.state.isEnrolledToiTeroIntegration ? (
                    <div className="case-tab__item-container">
                      <Link className={this.state.page === 'itero' ? 'case-tab__item case-tab__item--active' : 'case-tab__item'} to={'itero'}>
                        <FontAwesomeIcon icon={['fas', 'plus-square']} /> iTero Scanner Integration
                      </Link>
                    </div>
                  ) : null}
                  {this.props.account.role === 'Doctor' || this.props.account.role === 'DSO' ? (
                    <div className="case-tab__item-container">
                      <Link className={this.state.page === 'locator' ? 'case-tab__item case-tab__item--active' : 'case-tab__item'} to={'locator'}>
                        <FontAwesomeIcon icon={['fas', 'plus-square']} /> Provider Locator Info
                      </Link>
                    </div>
                  ) : null}
                </div>
              </CardContainer>
            </div>
            <div className="account-detail-container ">
              <Content />
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccountSetting(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccountSettings: fetchAccountSettings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
