import { wasmControllerExecute, wasmControllerGetRepresentation } from '../wasm_controller';
import { PROCESSOR } from '../wasm_controller_const';

export const EPopupMenuAction = {
  Movable: 'Movable',
  Fixed: 'Fixed',
  RestorePosition: 'Restore position',
};

export class ToothPopupMenuProcessor {
  /**
   * Retrieves the state of ToothPopupMenu Processor
   * @return {{
   *    fdis: number[],
   *    items: string[],
   *    x: number,
   *    y: number,
   *  } | null}
   */
  static getState() {
    const state = wasmControllerGetRepresentation(PROCESSOR.ToothPopupMenu);
    if (state.status === 404) {
      return null;
    }
    return {
      fdis: state.Fdis,
      x: state.MenuPos.X,
      y: state.MenuPos.Y,
      items: state.MenuItems,
    };
  }

  /**
   * Executes the specified tooth popup menu action
   * @param fdis {number[]}
   * @param action {EPopupMenuAction}
   * @return {any}
   */
  static handleToothAction(fdis, action) {
    const req = {
      Fdis: fdis,
      Action: action,
      Processor: PROCESSOR.ToothPopupMenu,
    };
    return wasmControllerExecute(req);
  }
}
