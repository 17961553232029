import React, { useEffect } from 'react';
import _ from 'lodash';
import { Box, TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import { setManualWireOffsetX, setManualWireOffsetY, setTeethError, removeTeethError, saveDraft } from '../../../../../../redux/actions/manual_wire/manual_wire';
import { TEETHS } from '../../manual_wire_utils';
import { isNumber, getOnly2DecimalPlaces } from '../../manual_wire_utils'

const ManualWireTableOffsetValues = ({
    offset,
    defaultOffset,
    teethLabel,
    disabled,
    error,
    errorMessage,
    setOffsetX,
    setOffsetY,
    setTeethError,
    removeTeethError,
    arch,
    index,
    disableAllFields,
    saveDraft,
}) => {    
    const onOffsetXBlur = (event) => {
        let textFieldValue = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');
    
        if (!isNumber(textFieldValue)) {
          textFieldValue = '0'
        }
    
        const parsedValue = parseFloat(textFieldValue).toFixed(2);
        saveDraft(setOffsetX, {offsetX: parsedValue, arch, teethLabel});
    };

    const onChangeOffsetX = (event) => {
        let offsetX = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');

        if (isNumber(offsetX)) {
            offsetX = getOnly2DecimalPlaces(offsetX);
            saveDraft(setOffsetX, {offsetX, arch, teethLabel});
        } else {
            setOffsetX({offsetX, arch, teethLabel});
        }
    }
    
    const onOffsetYBlur = (event) => {
        let textFieldValue = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');
    
        if (!isNumber(textFieldValue)) {
          textFieldValue = '0'
        }
    
        const parsedValue = parseFloat(textFieldValue).toFixed(2);
        saveDraft(setOffsetY, {offsetY: parsedValue, arch, teethLabel});
    };

    const onChangeOffsetY = (event) => {
        const offsetY = event.target.value.replace(',', '.').replace(/[^0-9.-]/g, '');
        
        if (isNumber(offsetY)) {
            saveDraft(setOffsetY, {offsetY, arch, teethLabel});
        } else {
            setOffsetY({offsetY, arch, teethLabel});
        }
    }

    useEffect(() => {
        let newErrorMessage = [...errorMessage];

        const rangeYErrorMessage = `Error on teeth ${teethLabel}: Offset Y value must be between -15.00 and 15.00.`;
        const blankYErrorMessage = `Error on teeth ${teethLabel}: Offset Y can't be blank`;
        newErrorMessage = newErrorMessage.filter((msg) => msg !== rangeYErrorMessage && msg !== blankYErrorMessage);
        if (offset.y < -15 || offset.y > 15) newErrorMessage.push(rangeYErrorMessage);
        else if (offset.y === '') newErrorMessage.push(blankYErrorMessage);

        const rangeXErrorMessage = `Error on teeth ${teethLabel}: Offset X value must be between 0.00 and 20.00.`;
        const blankXErrorMessage = `Error on teeth ${teethLabel}: Offset X can't be blank`;
        newErrorMessage = newErrorMessage.filter((msg) => msg !== rangeXErrorMessage && msg !== blankXErrorMessage);
        if (offset.x < 0 || offset.x > 20) newErrorMessage.push(rangeXErrorMessage);
        else if (offset.x === '') newErrorMessage.push(blankXErrorMessage);
   
        const removedElements = errorMessage.filter(item => !newErrorMessage.includes(item));
        const addedElements = newErrorMessage.filter(item => !errorMessage.includes(item));
    
        if (addedElements.length) setTeethError(addedElements, teethLabel);
        if (removedElements.length) removeTeethError(removedElements, teethLabel);
    }, [offset]);

    /**
     * Determines whether to show the label based on the offset value.
     * @returns {boolean} True if the default offset is not equal to the offset, false otherwise.
     */
    const showLabel = () => {
        const offsetX = isNumber(offset.x) ? offset.x : 0;
        const offsetY = isNumber(offset.y) ? offset.y : 0;
        const defaultOffsetX = isNumber(defaultOffset.x) ? defaultOffset.x : 0;
        const defaultOffsetY = isNumber(defaultOffset.y) ? defaultOffset.y : 0;

        return offsetX != defaultOffsetX || offsetY != defaultOffsetY;
    };

    /**
     * Generates offset textfields based on the given arch.
     * @param {string} arch - The arch type ('upper' or 'lower').
     * @returns {Array} An array of two TextFields representing the X and Y offset values.
     */
    const generateOffsetFields = (arch) => {
        const textFieldX = (
            <TextField
            className='offset-x'
            type='text'
            value={offset.x}
            onChange={onChangeOffsetX}
            onBlur={onOffsetXBlur}
            placeholder={disabled ? '' : '0.00'}
            InputProps={{
                disableUnderline: true,
                classes: { input: disabled ? 'input-number disabled' : 'input-number' },
            }}
            disabled={disabled || disableAllFields}
            label={showLabel() ? `[ ${defaultOffset.x}` : ''}
            />
        );
        
        const textFieldY = (
            <TextField
            className='offset-y'
            type='text'
            value={offset.y}
            onChange={onChangeOffsetY}
            onBlur={onOffsetYBlur}
            placeholder={disabled ? '' : '0.00'}
            InputProps={{
                disableUnderline: true,
                classes: { input: disabled ? 'input-number disabled' : 'input-number' },
            }}
            disabled={disabled || disableAllFields}
            label={showLabel() ? `, ${defaultOffset.y} ]` : ''}
            />
        );
        
        return [textFieldX, textFieldY];
    };

    const [offsetText1, offsetText2] = generateOffsetFields(arch);

    return (
        <Box 
            className={error ? 'manual-wire-table-offset-element error' : 'manual-wire-table-offset-element'}
            key={`manual-wire-offset-element${index}`}
            component="div"
        >
            <span className={showLabel() ? 'open-bracket show-label' : 'open-bracket'}>[</span>
            {offsetText1}
            <span className={showLabel() ? 'separator show-label' : 'separator'}>,</span>
            {offsetText2}
            <span className={showLabel() ? 'close-bracket show-label' : 'close-bracket'}>]</span>
        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {
    const teethLabel = TEETHS[ownProps.arch][ownProps.index - 1];
    const offset = state.manualWireReducer[ownProps.arch][teethLabel].offset;
    const defaultOffset = {};
    const defaultX = state.manualWireReducer.defaultCaseData[teethLabel]?.offset[0]
    defaultOffset.x = isNumber(defaultX) ? parseFloat(defaultX).toFixed(2) : defaultX;
    const defaultY = state.manualWireReducer.defaultCaseData[teethLabel]?.offset[1]
    defaultOffset.y = isNumber(defaultY) ? parseFloat(defaultY).toFixed(2) : defaultY;
    const disabled = state.manualWireReducer[ownProps.arch][teethLabel].disabled;
    const error = state.manualWireReducer[ownProps.arch][teethLabel].error;
    const errorMessage = state.manualWireReducer.errorMessage;
    return {
        offset,
        defaultOffset,
        teethLabel,
        disabled,
        error,
        errorMessage,
    }
}

const mapDispatchToProps = {
    setOffsetX: setManualWireOffsetX,
    setOffsetY: setManualWireOffsetY,
    setTeethError: setTeethError,
    removeTeethError: removeTeethError,
    saveDraft: saveDraft,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireTableOffsetValues);
