/**
 * File:shippo_reports_data.js - Component to view Shippo Extract Report
 * Copyright: (c) Copyright July 2020 by InBrace
 * Authors: Katie Chen
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import { UserPermissionsContext } from '../../context/user_permission';

// Internal Functions
import { getReportFileName } from '../../common/functions';
import { userHasPermission } from '../../common/permission';
import NotAvailable from '../not_available/not_available';

// Redux Reducers
import { getShippoReport, getPending, getError, getDateError, getDateErrorMessage, getStartDate, getEndDate } from '../../redux/reducers/shippo/shippo';

// Redux Actions
import { fetchShippoReport, onStartDateSelect, onEndDateSelect } from '../../redux/actions/shippo/shippo';

class Shippo_Report_Data extends Component {
  componentDidMount() {
    this.props.fetchShippoReport();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    if (this.props.shippo_report_error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (!this.props.shippo_report || this.props.shippo_report_pending) {
      return (
        <div className="fullview">
          <Helmet>
            <title>Shippo Extract | InBrace Business Portal</title>
          </Helmet>
          <div className="main-content reports-page">
            <div className="dark-options reports-data-heading">
              <div className="page-heading">Shippo Extract</div>
            </div>
            <div className="table-case-view">
              <div className="fullview">
                <Loader />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.props.shippo_report) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return (
              <div className="fullview">
                <Helmet>
                  <title>Shippo Extract | InBrace Business Portal</title>
                </Helmet>
                {userHasPermission('REPORTS_VIEW', user_roles_and_permissions.permissions) ? (
                  <div className="main-content reports-page">
                    <div className="dark-options reports-data-heading">
                      <div className="page-heading">
                        Shippo Extract
                        <CSVLink
                          filename={getReportFileName('shippo_extract')}
                          className="csv-download-link"
                          headers={this.props.shippo_report && this.props.shippo_report.csv_columns ? this.props.shippo_report.csv_columns : []}
                          data={this.props.shippo_report.reports}
                        >
                          <i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="Download CSV" />
                        </CSVLink>
                        <div className="select-container-date">
                          <span className="text-center light-gray-text date-label">Ship Date Between</span>
                          <span>
                            <input
                              className={'select-date' + (this.props.date_error ? ' error' : '')}
                              type="date"
                              name="start"
                              value={this.props.start_date}
                              onChange={this.props.onStartDateSelect}
                            />
                            <input
                              className={'select-date' + (this.props.date_error ? ' error' : '')}
                              type="date"
                              name="end"
                              value={this.props.end_date}
                              onChange={this.props.onEndDateSelect}
                            />
                          </span>
                          <button className="btn btn-light-3" onClick={this.props.fetchShippoReport}>
                            <i className="fa fa-search" aria-hidden="true" />
                          </button>
                          {this.props.date_error ? <span className="error-message">{this.props.date_error_message}</span> : null}
                        </div>
                      </div>
                    </div>
                    <div className="table-case-view">
                      <ReactTabulator
                        className="report-data-table fs-exclude"
                        columns={this.props.shippo_report.columns}
                        data={this.props.shippo_report.report_data}
                        options={this.props.shippo_report.options}
                      />
                    </div>
                  </div>
                ) : (
                  <NotAvailable message="No Report Available" />
                )}
              </div>
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    shippo_report: getShippoReport(state),
    shippo_report_pending: getPending(state),
    shippo_report_error: getError(state),
    start_date: getStartDate(state),
    end_date: getEndDate(state),
    date_error: getDateError(state),
    date_error_message: getDateErrorMessage(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchShippoReport: fetchShippoReport,
      onStartDateSelect: onStartDateSelect,
      onEndDateSelect: onEndDateSelect,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Shippo_Report_Data));
