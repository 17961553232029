import '../account_settings/account_settings.scss';

import React, { Component } from 'react';
import Content from './content';
import { Helmet } from 'react-helmet';

import CardContainer from '../components/container/card_container';
import ContentHeader from '../components/content_header';

class AccountAdd extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Create New Account | InBrace Smile Design Studio™</title>
        </Helmet>
        <ContentHeader title="Create New Account" />
        <CardContainer className="pad-md pad-md--no-top">
          <Content />
        </CardContainer>
      </div>
    );
  }
}

export default AccountAdd;
