import React from 'react';
import { Box } from '@material-ui/core';

import ManualWireTableLoopSelect from './manual_wire_table_loop_select'
import ManualWireTableEmptyElement from '../manual_wire_table_empty_element/manual_wire_table_empty_element';
import { getFilteredIndexes } from '../../manual_wire_utils';

import './manual_wire_table_loop.scss';

const ManualWireTableLoop = ({ arch, missingTeeths, range, disableAllFields }) => {
    const filteredIndexes = getFilteredIndexes(arch, missingTeeths, range, 15);
    return (
        <Box display="flex" flexDirection="row" className='manual-wire-loop' component="div">
            <ManualWireTableEmptyElement />
            {filteredIndexes.map((teethIndex, index) => (
                <Box className='manual-wire-loop-element' key={`manual-wire-loop-element-${index}`} component="div">
                    <ManualWireTableLoopSelect
                        arch={arch}
                        index={teethIndex}
                        filteredIndexes={filteredIndexes}
                        disableAllFields={disableAllFields}
                    />
                </Box>
            ))}
            <ManualWireTableEmptyElement />
        </Box>
    )
};

export default ManualWireTableLoop;
