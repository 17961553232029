import {
  OPEN_INVALID_ADDRESS_MODAL,
  CLOSE_INVALID_ADDRESS_MODAL,
  RESET_INVALID_ADDRESS_MODAL,
  SET_SELECTED_ADDRESS_ID,
  SET_ORIGINAL_ADDRESS_SELECTED,
  OPEN_SMARTY_STREETS_ERROR_MODAL,
  OPEN_REACTIVATION_MODAL,
  OPEN_DEACTIVATION_MODAL,
  CLOSE_PRACTICE_ACTIVATION_MODAL,
} from '../../../actions/bpp/account/add_account';

const initialState = {
  invalid_address_modal: false,
  original_address: {},
  address_suggestions: [],
  selected_address_id: '',
  original_address_selected: false,
  edit_address: false,
  smartystreets_error: false,
  reactivation_modal: false,
  deactivation_modal: false,
};

/**
 * This reducer is used for the account creation page on the BPP
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function addAccountReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_INVALID_ADDRESS_MODAL:
      return {
        ...state,
        original_address: action.original_address,
        address_suggestions: action.address_suggestions,
        invalid_address_modal: true,
        edit_address: action.edit_address,
      };

    case CLOSE_INVALID_ADDRESS_MODAL:
      return {
        ...state,
        invalid_address_modal: false,
      };

    case RESET_INVALID_ADDRESS_MODAL:
      return {
        ...state,
        original_address: '',
        address_suggestions: [],
        selected_address_id: '',
        edit_address: false,
        original_address_selected: false,
        smartystreets_error: false,
      };

    case OPEN_SMARTY_STREETS_ERROR_MODAL:
      return {
        ...state,
        invalid_address_modal: true,
        smartystreets_error: true,
      };

    case SET_SELECTED_ADDRESS_ID:
      return {
        ...state,
        selected_address_id: action.selected_address_id,
        original_address_selected: false,
      };

    case SET_ORIGINAL_ADDRESS_SELECTED:
      return {
        ...state,
        original_address_selected: true,
        selected_address_id: '',
      };

    case OPEN_REACTIVATION_MODAL:
      return {
        ...state,
        reactivation_modal: true,
      };

    case OPEN_DEACTIVATION_MODAL:
      return {
        ...state,
        deactivation_modal: true,
      };

    case CLOSE_PRACTICE_ACTIVATION_MODAL:
      return {
        ...state,
        reactivation_modal: false,
        deactivation_modal: false,
      };

    default:
      return state;
  }
}

export const getInvalidAddressModal = (state) => state.addAccountReducer.invalid_address_modal;
export const getOriginalAddress = (state) => state.addAccountReducer.original_address;
export const getAddressSuggestions = (state) => state.addAccountReducer.address_suggestions;
export const getSelectedAddressId = (state) => state.addAccountReducer.selected_address_id;
export const getOriginalAddressSelected = (state) => state.addAccountReducer.original_address_selected;
export const getEditAddress = (state) => state.addAccountReducer.edit_address;
export const getSmartyStreetsError = (state) => state.addAccountReducer.smartystreets_error;
export const getReactivationModal = (state) => state.addAccountReducer.reactivation_modal;
export const getDeactivationModal = (state) => state.addAccountReducer.deactivation_modal;
