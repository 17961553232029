import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Detail from './content_detail';
import { withRouter } from 'react-router-dom';
import NotFound from '../404/not_found';
import { getDoctorsIdFromRoute } from '../../common/route';
import CircleLoader from '../../components/loader/circle_loader';

// Redux
import { fetchAccount } from '../../redux/actions/ipp/add_account';
import { getAccount, getAccountError, getAccountPending } from '../../redux/reducers/ipp/add_account';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { content: null };
  }

  componentDidMount() {
    this.props.fetchAccount(getDoctorsIdFromRoute());

    this.setState({
      content: <Detail />
    });
  }

  render() {
    if (this.props.account_error || this.props.not_found_error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.props.account_pending) {
      return <CircleLoader fullscreen withBackground />;
    } else {
      return this.state.content;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    account_error: getAccountError(state),
    account_pending: getAccountPending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAccount: fetchAccount,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
