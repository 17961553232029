import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import './manual_wire_dropdown.scss';

const ManualWireDropdown = ({ options, value, setValue, label, id, className, style, disableAllFields }) => {
    return (
        <FormControl className={`manual-wire-option-form-control ${className}`} variant="outlined" style={style}>
            <InputLabel id={id} style={{ marginTop: '-10px' }}>{label}</InputLabel>
            <Select
                style={{ height: '30px' }}
                labelId={id}
                value={options.find(opt => opt.value === value) ? value : ''}
                onChange={setValue}
                label={label}
                disabled={disableAllFields}
            >
                {options.map(option => (
                    <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ManualWireDropdown;
