// ---------------------------------- Imports ----------------------------------
// Css
import './mark_shipped.scss';
// External Libs
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

//Internal Libs
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber, isValidMarkShippedDate } from '../../common/functions';

// Redux
import {
  getSelectedShipDate,
  getMarkShippedCases,
  getMarkShippedModal,
  getError,
  getShipDateError,
  getBatchMarkShippedError,
  getBatchFailedIds,
  getBatchAllFailedMarkShippedError,
  getOnlyNonAutomaticCasesSucceeded,
  getShippingServices,
} from '../../redux/reducers/bpp/shipping/shipping';

import {
  onShipDateChange,
  setHandDeliveryFlagChange,
  setShippoObjectId,
  setTrackingNumber,
  setOptionsMenuFlag,
  setShippingMethod,
  setShippoSelection,
  setShippoSelectionMenu,
  setShippingService,
} from '../../redux/actions/bpp/shipping/shipping';

const CASES_THRESHOLD_OVERFLOW = 5;

/**
 * Contains modal content for marking a case as shipped
 * @component
 * @category Modals
 */
class MarkShipped extends Component {
  constructor(props) {
    super(props);

    this.state = {
      method: '',
      options: false,
      shippo_selection: '',
      shippo_selection_menu: false,
      shipping_method: 'manual_shipping',
      tracking_number: '',
    };
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
    });
  }

  /**
   * Show the options UI element
   * @function
   */
  onClickOptions = (id) => {
    this.props.setOptionsMenuFlag(id, true);
  };
  /**
   * Turn off the options window when leaving the shipping method "option" element
   * @function
   */
  leaveOptions = (id) => {
    this.props.setOptionsMenuFlag(id, false);
  };
  /**
   * Update the shipping method
   * @function
   * @param e {Object} - Event Object related to changing the shipping method dropdown
   * @param cases {Object} - Case or Ireq object related to the mark shipped window
   */
  setShippingMethod = (e, cases) => {
    this.props.setHandDeliveryFlagChange(cases.id, e.target.dataset.value === 'hand_delivery');
    this.props.setShippingMethod(cases.id, e.target.dataset.value);
    this.props.setOptionsMenuFlag(cases.id, false);
  };
  /**
   * Updates the tracking number in redux
   * @function
   * @param e {Object} - Event Object related to changing the tracking number input field
   * @param id {String} - Case Id or Ireq Id related to the mark shipped window
   */
  onChangeTrackingNumber = (e, id) => {
    this.props.setTrackingNumber(id, e.target.value);
  };
  /**
   * Gets the message to display at the top of the modal
   * @function
   * @return {String} Modal message
   */

  /**
   * Updates the tracking service for populating the tracking URL in redux and on state
   * @function
   * @param e {Object} - Event Object related to changing the tracking service input field
   * @param id {String} - Case Id or Ireq Id related to the mark shipped window
   */
  onChangeShippingService = (e, id) => {
    this.props.setShippingService(id, e.target.value);
  };

  getMessage() {
    if (this.props.mark_shipped_cases.length > 0) {
      return 'Are you sure you want to mark these as shipped?';
    }
    return `Are you sure you want to mark this ${this.props.mark_shipped_cases[0].id.includes('-IR') ? 'item request' : 'case'} as shipped?`;
  }

  /**
   * Gets doctor name and patient name based on the case or item request
   * @function
   * @param id {String} - case or item request id
   * @return {Object} Object with doctor name and patient name, empty strings if case/ireq is not found
   */
  getDoctorAndPatientName(id) {
    for (const cases of this.props.mark_shipped_cases) {
      if (cases.id === id) {
        return {
          doctor_name: `${cases.doctor.first_name} ${cases.doctor.last_name}`,
          patient_name: `${cases.first_name} ${cases.last_name}`,
        };
      }
    }
    return {
      doctor_name: '',
      patient_name: '',
    };
  }
  /**
   * Show the dropdown menu of all the shipping options
   * @function
   */
  expandSelectionMenu = (id) => {
    this.props.setShippoSelectionMenu(id, true);
  };
  /**
   * Hide the dropdown menu of all the shipping options
   * @function
   */
  collapseSelectionMenu = (id) => {
    this.props.setShippoSelectionMenu(id, false);
  };
  /**
   * Handles the event when selecting a new shipping service
   *
   * @function
   * @param e {Object} - Event Object related to selecting a new value for shipping service
   * @param id {String} - Case Id or Ireq Id related current mark shipped window
   */
  onSelectService = (e, id) => {
    this.props.setShippoObjectId(id, e.currentTarget.dataset.object_id, e.currentTarget.dataset.est_arrival_date);
    this.props.setShippoSelectionMenu(id, false);
    this.props.setShippoSelection(id, parseInt(e.currentTarget.dataset.value));
  };
  /**
   * Makes incoming value a DOM allowed data value
   *
   * @function
   * @param name {String} - Name of the shipping method
   * @return {String} Return a new string value of the DOM friendly name
   */
  sanitizeShippingMethodName = (name) => {
    if (name) {
      name = name.toLowerCase().replace(' ', '_');
    }

    return name;
  };
  /**
   * Reverts the DOM friendly name to the original human readable format
   *
   * @function
   * @param name {String} - Name of the shipping method
   * @return {String} Return a original name
   */
  getShippingMethodName = (name) => {
    if (name) {
      let each_word = name.split('_');
      each_word = each_word.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      name = each_word.join('');
    }

    return name;
  };
  /**
   * check if shippo rate is there
   *
   * @function
   * @param name {String} - Name of the shipping method
   * @return {Object} Return a original name
   */
  hasShippoRates = (cases) => {
    return cases?.shippo_rates.length !== 0;
  };
  /**
   * Gets estimated days message
   *
   * @function
   * @param estimated_days {number} - Estimated number of days for shipment
   * @return {string} Message string
   */
  getEstimatedDaysMessage = (estimated_days) => {
    return `Estimated delivery duration ${estimated_days} days`;
  };

  /**
   * Display the UI that for the type of shipping method
   *
   * @function
   * @param cases {Object} - The case details that are passed to the mark shipped modal
   * @return {JSX} Returns the correct UI display by shipping method
   */
  displayShippingMethodUI = (cases) => {
    const method = cases.shipping_method;
    const UIFunction = `display${this.getShippingMethodName(method)}UI`;

    return this[UIFunction](cases);
  };
  /**
   * Display the UI for Automated Shipping method
   *
   * @function
   * @param cases {Object} - The case details that are passed to the mark shipped modal
   * @return {JSX} Returns UI of Automated Shipping method
   */
  displayAutomatedShippingUI = (cases) => {
    const index = cases?.shippo_selection;

    if (cases.shippo_rates.length === 0) {
      return <div className="delivery-selection-window">No Shipping Info</div>;
    }

    const selection = cases && cases.shippo_rates && index !== '' ? cases.shippo_rates[index] : cases.shippo_rates[0];
    const badges = this.getBadges(selection.attributes, selection.preferred_method);

    return (
      <div className="delivery-selection-window">
        <div className="delivery-selection" onClick={() => this.expandSelectionMenu(cases.id)} onMouseLeave={() => this.collapseSelectionMenu(cases.id)}>
          <div className="delivery-item">
            <span className="bold-text">Service: </span> {selection.provider}{' '}
            {selection.servicelevel && selection.servicelevel.name ? selection.servicelevel.name : ''}
            {this.displayBadges(badges)}
          </div>
          <div className="delivery-item">
            <span className="bold-text">Est. Arrival Date: </span>
            {selection.estimated_arrival_date ? selection.estimated_arrival_date : 'N/A'}
            {selection.is_past_target_arrival_date !== null && this.displayDeliveryDateClock(selection)}
            <div>
              {selection.duration_terms
                ? selection.duration_terms
                : selection.estimated_days
                ? this.getEstimatedDaysMessage(selection.estimated_days)
                : 'Information not available'}
            </div>
          </div>
          <div className="delivery-item">
            <span className="bold-text">Shipping Price: </span> ${selection.amount}
          </div>
          <div className="delivery-expander">
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </div>
        </div>
        <div
          className={cases.shippo_selection_menu ? 'delivery-selection-options-window' : 'delivery-selection-options-window hide'}
          onMouseEnter={() => this.expandSelectionMenu(cases.id)}
          onMouseLeave={() => this.collapseSelectionMenu(cases.id)}
        >
          {cases.shippo_rates.map((shippo, index) => {
            const badges = this.getBadges(shippo.attributes, shippo.preferred_method);
            return (
              <div
                key={index}
                className="delivery-selection-options"
                data-value={index}
                onClick={(e) => {
                  this.onSelectService(e, cases.id);
                }}
                data-object_id={shippo.object_id}
                data-est_arrival_date={shippo.estimated_arrival_date}
              >
                <div className="delivery-item">
                  <span className="bold-text">Service: </span> {shippo.provider}{' '}
                  {shippo.servicelevel && shippo.servicelevel.name ? shippo.servicelevel.name : ''}
                  {this.displayBadges(badges)}
                </div>
                <div className="delivery-item">
                  <span className="bold-text">Est. Arrival Date:</span>
                  {shippo.estimated_arrival_date ? shippo.estimated_arrival_date : 'N/A'}
                  {shippo.is_past_target_arrival_date !== null && this.displayDeliveryDateClock(shippo)}
                  <div>
                    {shippo.duration_terms
                      ? shippo.duration_terms
                      : shippo.estimated_days
                      ? this.getEstimatedDaysMessage(shippo.estimated_days)
                      : 'Information not available'}
                  </div>
                </div>
                <div className="delivery-item">
                  <span className="bold-text">Shipping Price: </span> ${shippo.amount}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  /**
   * Display the UI for Manual Shipping method
   *
   * @function
   * @param cases {Object} - The case details that are passed to the mark shipped modal
   * @return {JSX} Returns UI of Manual Shipping method
   */
  displayManualShippingUI = (cases) => {
    return (
      <div className="delivery-selection delivery-selection--borderless">
        <div className="delivery-item">Shipping</div>
        <div className="delivery-item--full">
          <span className="bold-text" htmlFor="tracking_number">
            Tracking Number:
          </span>
          <input
            name="tracking_number"
            type="text"
            className="form-control tracking-input"
            onChange={(e) => {
              this.onChangeTrackingNumber(e, cases.id);
            }}
            value={cases.tracking_number}
          />
          <span className="bold-text tracking-url-label" htmlFor="tracking_url">
            Shipping Service:
          </span>
          <select
            className="form-control tracking-input tracking-url-input"
            value={cases.shipping_service}
            onChange={(e) => this.onChangeShippingService(e, cases.id)}
          >
            <option disabled value="" hidden>
              - Select Option -
            </option>
            {this.props.shipping_services.map((service, index) => {
              return (
                <option key={index} value={service}>
                  {service}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };
  /**
   * Display the UI for Hand Delivery method
   *
   * @function
   * @param cases {Object} - The case details that are passed to the mark shipped modal
   * @return {JSX} Returns UI of Hand Delivery method
   */
  displayHandDeliveryUI = (cases) => {
    return (
      <div className="delivery-selection delivery-selection--borderless">
        <div className="delivery-item">Hand Delivery</div>
      </div>
    );
  };

  /**
   * Orders cases by doctor's name
   * @function
   * @param cases {Array} - List of cases to sort
   * @return {Array} - Sorted array of cases
   */
  orderCasesByDoctorName = (cases) => {
    return _.orderBy(cases, ['doctor.first_name', 'shipping_address'], ['asc', 'asc']);
  };

  /**
   * Gets the classname to style error for each row
   * @function
   * @returns {String} Classname for each row
   */
  getRowClassName(cases) {
    if (this.props.ship_date_error && !isValidMarkShippedDate([cases], this.props.selected_ship_date)) {
      return 'ship-date-error';
    }
    return '';
  }
  /**
   * Retrieves list of badges to display describing the shipping rate type
   * @function
   * @param {list} attributes - List of shipping rate attributes
   * @param {boolean} preferred_method - Indicator if shipping rate is of the preferred shipping method
   * @returns {list} - List of badges
   */
  getBadges = (attributes, preferred_method) => {
    let badges = [];
    const has_fastest_value = attributes.indexOf('FASTEST') !== -1;
    const has_best_value = attributes.indexOf('BESTVALUE') !== -1;
    const has_cheapest_value = attributes.indexOf('CHEAPEST') !== -1;

    if (preferred_method) {
      badges.push('Preferred Method');
    }
    if (has_fastest_value) {
      badges.push('Fastest');
    }
    if (has_best_value && has_cheapest_value) {
      badges.push('Best Value, Cheapest');
    } else if (has_best_value) {
      badges.push('Best Value');
    } else if (has_cheapest_value) {
      badges.push('Cheapest');
    }
    return badges;
  };

  /**
   * Displays badge icon
   * @function
   * @param {string} badge_type - Badge type
   * @param {number} index - Index of badge in list
   * @returns {JSX} - JSX for badge icon
   */
  displayBadgeIcon = (badge_type, index) => {
    const badge_classes = {
      'Preferred Method': 'preferred',
      Fastest: 'fastest',
      'Best Value, Cheapest': 'best-value-cheapest',
      'Best Value': 'best-value',
      Cheapest: 'cheapest',
    };
    return (
      <div key={index} className={`shipping-badge shipping-badge__${badge_classes[badge_type]}`}>
        {badge_type}
      </div>
    );
  };

  /**
   * Displays badges for shipping rate
   * @function
   * @param {list} badges - List of badge types
   * @returns {JSX} - JSX for badges
   */
  displayBadges = (badges) => {
    return (
      <>
        {badges.map((badge, index) => {
          return this.displayBadgeIcon(badge, index);
        })}
      </>
    );
  };

  /**
   * Displays clock determining if estimated date is before or after target arrival date
   * @function
   * @param {object} rate - Rate information
   * @returns {JSX} JSX for clock
   */
  displayDeliveryDateClock = (rate) => {
    return (
      <i
        className={`fa fa-clock-o clock clock__${rate.is_past_target_arrival_date ? 'after' : 'before'}-date`}
        aria-hidden={true}
        data-toggle="tooltip"
        data-placement="right"
        title={rate.is_past_target_arrival_date ? 'Late: May arrive later than target date' : 'On Time: May arrive within target'}
      />
    );
  };
  /**
   * Displays clock determining if estimated date is before or after target arrival date
   * @function
   * @param {object} rate - Rate information
   * @returns {JSX} JSX for clock
   */
  getMethods = (cases, shipping_methods) => {
    let methods = _.cloneDeep(shipping_methods);

    if (!this.hasShippoRates(cases)) {
      methods.shift();
    }

    return methods;
  };

  render() {
    // Automated Shipping is disabled. comment from Katie: It’s because they are not using shippo API anymore to ship cases, they are shipping them externally through netsuite instead I think. So they only need a way to record the tracking number on BPP now
    const shipping_methods = [/*'Automated Shipping', */ 'Manual Shipping', 'Hand Delivery'];
    const orderedCases = this.orderCasesByDoctorName(this.props.mark_shipped_cases);

    return (
      <div className="mark-shipped">
        {this.props.batch_mark_shipped_error ? (
          <div className="p-slim grey-text">
            The following were not successfully mark shipped. Please try again later.
            {!this.props.only_non_automatic_cases_succeeded && (
              <React.Fragment>
                <br />
                Click <b>Continue</b> to view the successfully mark shipped cases and item requests.
              </React.Fragment>
            )}
            <table className="table-failed">
              <thead>
                <tr>
                  <th className="id-width">ID</th>
                  <th>Doctor</th>
                  <th>Patient</th>
                </tr>
              </thead>
              <tbody>
                {this.props.batch_failed_ids.map((id, index) => {
                  const doctor_patient_info = this.getDoctorAndPatientName(id);
                  return (
                    <tr key={index}>
                      <td>{id.includes('-IR') ? removeIreqIdInitialNumber(id) : removeCaseIdInitialNumber(id)}</td>

                      <td>{doctor_patient_info.doctor_name}</td>
                      <td>{doctor_patient_info.patient_name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : this.props.batch_all_failed_mark_shipped_error ? (
          <div className="p-slim grey-text">
            Was not able to successfully mark shipped for the following reason:
            <br />
            <b>Shippo Service Error</b>
            <br />
            Please try again later.
          </div>
        ) : this.props.error ? (
          <div className="p-slim grey-text">
            The {this.props.case_mark_shipped_modal ? 'case' : 'item request'} was not successfully mark shipped for the following reason:
            <br />
            <b>Shippo Service Error</b>
            <br />
            Please try again later.
          </div>
        ) : (
          <React.Fragment>
            <div className="p-slim grey-text">{this.getMessage()}</div>
            <div className="p-slim grey-text ship-date">
              <span className="ship-date-label">Enter Ship Date:</span>
              <div className="inline-input">
                <input required type="date" className="form-control" value={this.props.selected_ship_date} onChange={this.props.onShipDateChange} />
              </div>
            </div>

            <div className={`form-group ${orderedCases.length > CASES_THRESHOLD_OVERFLOW ? 'many-items' : ''}`}>
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Delivery Method</th>
                  </tr>
                </thead>
                <tbody>
                  {orderedCases.map((cases, index) => {
                    let methods = this.getMethods(cases, shipping_methods);

                    return (
                      <tr className={this.getRowClassName(cases)} key={index}>
                        <td>{cases.id.includes('-IR') ? removeIreqIdInitialNumber(cases.id) : removeCaseIdInitialNumber(cases.id)}</td>
                        <td>
                          <div className="delivery-window">
                            <div className="delivery-options" onMouseLeave={() => this.leaveOptions(cases.id)}>
                              <div className={'delivery-options-menu'} onClick={() => this.onClickOptions(cases.id)}>
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </div>

                              <div className={cases.options_menu ? 'delivery-options-list' : 'delivery-options-list hide'}>
                                {methods.map((method, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="delivery-options-list-item"
                                      onClick={(e) => {
                                        this.setShippingMethod(e, cases);
                                      }}
                                      data-value={this.sanitizeShippingMethodName(method)}
                                    >
                                      {method}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            {this.displayShippingMethodUI(cases)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

MarkShipped.propTypes = {
  selected_ship_date: PropTypes.string.isRequired,
  mark_shipped_cases: PropTypes.array.isRequired,
  case_mark_shipped_modal: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  ship_date_error: PropTypes.bool.isRequired,
  batch_mark_shipped_error: PropTypes.bool.isRequired,
  batch_all_failed_mark_shipped_error: PropTypes.bool.isRequired,
  batch_failed_ids: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selected_ship_date: getSelectedShipDate(state),
    mark_shipped_cases: getMarkShippedCases(state),
    case_mark_shipped_modal: getMarkShippedModal(state),
    error: getError(state),
    ship_date_error: getShipDateError(state),
    batch_mark_shipped_error: getBatchMarkShippedError(state),
    batch_failed_ids: getBatchFailedIds(state),
    batch_all_failed_mark_shipped_error: getBatchAllFailedMarkShippedError(state),
    only_non_automatic_cases_succeeded: getOnlyNonAutomaticCasesSucceeded(state),
    shipping_services: getShippingServices(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onShipDateChange: onShipDateChange,
      setHandDeliveryFlagChange: setHandDeliveryFlagChange,
      setShippoObjectId: setShippoObjectId,
      setTrackingNumber: setTrackingNumber,
      setOptionsMenuFlag: setOptionsMenuFlag,
      setShippingMethod: setShippingMethod,
      setShippoSelection: setShippoSelection,
      setShippoSelectionMenu: setShippoSelectionMenu,
      setShippingService: setShippingService,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarkShipped);
