/**
 * File:review.js - Component to review item request submission parts on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Externa Libs
import React, { Component } from 'react';

//Internal Functions
import { getShippingAddress } from '../../common/helpers';
import { getShippingAddressName } from '../../common/helpers';
import { firstLetterCapital } from '../../common/functions';
import Prop65Warning from '../layout/prop65_warning';

// nomenclature
import { getLookup } from '../../common/nomenclature';
import CardContainer from '../components/container/card_container';

class ItemReview extends Component {
  displayShippingAddress() {
    return (
      <>
        <h3>Shipping Address</h3>
        <form>
          {this.props.addresses && this.props.addresses.length > 0 ? (
            <div className="address-container">
              <div className="emphasis">Select shipping address:</div>
              <div className="address-list">
                {this.props.addresses.map((address) =>
                  address.is_active ? (
                    <label key={address.id}>
                      <input
                        type="radio"
                        name="address"
                        defaultChecked={address.id === this.props.address_id}
                        onChange={this.props.onInputChange}
                        defaultValue={address.id}
                        data-id={address.id}
                      />
                      <div className="address-text">
                        <div className="emphasis">{firstLetterCapital(getShippingAddressName(address))}</div>
                        <div>{getShippingAddress(address)}</div>
                      </div>
                    </label>
                  ) : null
                )}
              </div>
            </div>
          ) : (
            <div>No addresses found for this users account. Please contact your InBrace Rep.</div>
          )}
        </form>
      </>
    );
  }

  displayItemRequestSummary() {
    const ITEM_PART_LOOKUP = getLookup(this.props.case_id); // nomenclature lookup
    return (
      <>
        <div className="header-edit-container">
          <h3 className="header-edit__heading">Item Request Summary </h3>
          <span className="header-edit__button" data-action="item_parts" onClick={this.props.onEditClick}>
            Edit
          </span>
        </div>
        <Prop65Warning />
        <CardContainer type="top-border" className="item-request-container">
          <table className="item-request-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {this.props.item_parts_quantity.map((item_part) =>
                item_part.current_ind && item_part.quantity > 0 ? (
                  <tr key={item_part.id}>
                    <td>{ITEM_PART_LOOKUP[item_part.part_id]}</td>
                    <td>{item_part.quantity}</td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </CardContainer>
      </>
    );
  }

  displayRequestReason() {
    const case_id = this.props.case_id;
    const hasQuantity = {
      smartwire: this.props.smartwire_has_quantity,
      idb: this.props.idb_has_quantity,
      idb_required: this.props.idb_has_quantity,
      brackets: this.props.brackets_has_quantity,
    };
    const isDE = case_id && (case_id.indexOf('-DE') >= 0 || case_id.indexOf('-R') >= 0);
    let arch_segments_options = [];
    this.props.request_reasons.forEach((reason) => {
      if (reason.arch_segments_options) {
        reason.arch_segments_options.forEach((arch_segments_option) => {
          if (arch_segments_option.active) arch_segments_options.push(arch_segments_option.title);
        });
      }
    });
    return (
      <>
        <div className="header-edit-container">
          <h3 className="header-edit__heading">Reason for Request </h3>
          <span className="header-edit__button" data-action="item_reason" onClick={this.props.onEditClick}>
            Edit
          </span>
        </div>
        <ul>
          {this.props.request_reasons.map((reason) =>
            reason.booleanValue && hasQuantity[reason.category] ? (
              <React.Fragment key={reason.id}>
                {reason.category === 'idb_required' && reason.arch_segment_selection && arch_segments_options.length ? (
                  <li>
                    {`${reason.name_label}: ${reason.arch_segment_selection_text}`} {' (' + arch_segments_options.join(', ') + ')'}
                  </li>
                ) : null}
                {reason.category === 'idb_required' && reason.single_tooth_selection && reason.selectedTeeth.length ? (
                  <li>
                    {`${reason.name_label}: ${this.props.gen_2 ? reason.single_tooth_selection_text : reason.segment_preference_text}`}{' '}
                    {' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')'}
                  </li>
                ) : null}
                {reason.category !== 'idb_required' && (
                  <li>
                    {reason.name_label}:{' '}
                    {reason.segment_preference_text
                      ? reason.segment_preference_text.replace(':', '')
                      : isDE && reason.label_de
                      ? reason.label_de.replace(':', '')
                      : reason.label.replace(':', '')}
                    {reason.selectedTeeth && reason.selectedTeeth.length > 0 ? ' (' + reason.selectedTeeth.sort().reverse().join(', ') + ')' : null}
                    {reason.value ? ` - "${reason.value}"` : null}
                  </li>
                )}
              </React.Fragment>
            ) : null
          )}
        </ul>
      </>
    );
  }

  render() {
    return (
      <div className="case-form-container item-review-container">
        {this.displayShippingAddress()}
        {this.displayItemRequestSummary()}
        {this.displayRequestReason()}
      </div>
    );
  }
}

export default ItemReview;
