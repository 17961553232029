import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a progress ring
 * @component
 */
function ProgressRing(props) {
  const [offset, setOffset] = useState(0);
  const { size, progress, strokeWidth, text } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = circumference - (progress / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, progress, circumference, offset]);

  return (
    <svg className="progress-ring" width={size} height={size}>
      <circle className="progress-ring__circle-background" cx={center} cy={center} r={radius} strokeWidth={strokeWidth} />
      <circle
        className="progress-ring__circle"
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <text x={center} y={center} dominantBaseline="middle" className="progress-ring__text">
        {text}
      </text>
    </svg>
  );
}

ProgressRing.propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
};

export default ProgressRing;
