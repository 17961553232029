import React from 'react';
import { setManualWireProgram, saveDraft } from '../../../../redux/actions/manual_wire/manual_wire';
import ManualWireDropdown from './manual_wire_dropdown/manual_wire_dropdown';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { programOptions } from './manual_wire_options';

const ManualWireProgram = ({ program, setProgram, saveDraft, disableAllFields }) => {
  const onChangeProgram = (event) => {
    const programValue = event.target.value;
    saveDraft(setProgram, programValue);
  };

  return (
    <Grid item className="manual-wire-option">
      <Typography className="manual-wire-option-label" variant="h6">
        Programs
      </Typography>
      <ManualWireDropdown
        options={programOptions}
        value={program}
        setValue={onChangeProgram}
        label="Programs"
        id="program-select-label"
        className="manual-wire-programs"
        style={{ height: '30px' }}
        disableAllFields={disableAllFields}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const program = state.manualWireReducer.program;
  return {
    program,
  };
};

const mapDispatchToProps = {
  setProgram: setManualWireProgram,
  saveDraft: saveDraft,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualWireProgram);
