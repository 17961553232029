import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays the selection bar left arrow
 * @component
 */
function SelectionBarPageLeft(props) {
  const exist = props.scroll_area > 0;

  return (
    <div
      className={exist ? 'selection-bar-top-page-left fade-in' : 'selection-bar-top-page-left fade-out'}
      onClick={exist ? props.onClick : null}
      onDoubleClick={exist ? props.onClick : null}
    >
      <i className={!exist ? 'invisible' : 'fa fa-chevron-left'} aria-hidden="true" />
    </div>
  );
}

SelectionBarPageLeft.propTypes = {
  scroll_area: PropTypes.number.isRequired,
};

export default SelectionBarPageLeft;
