import _ from 'lodash';

export const EUserPermission = {
  AdjSetupCancel: 'ADJUSTED_SETUP_CANCEL',
  AdjSetupRemove: 'ADJUSTED_SETUP_REMOVE',
  AdjSetupConvertFail: 'ADJUSTED_SETUP_CONVERT_FAIL',
  AdjSetupTrigger: 'ADJUSTED_SETUP_TRIGGER',
  AdjSetupConvert: 'ADJUSTED_SETUP_CONVERT',
  AdjSetupBypass: 'BYPASS_ADJUSTED_SETUP_CONVERSION',
  PostApprovalSmileDesign: 'POST_APPROVAL_SMILE_DESIGN',
  PostApprovalBypass: 'BYPASS_POST_APPROVAL_PROCESS',
  DigiBPBypass: 'BYPASS_DIGIBP_CONVERSION',
  DigiBPOverride: 'OVERRIDE_DIGIBP_CONVERSION',
  SetupAssign: 'SETUP_ASSIGN',
  SetupCommentEdit: 'SETUP_COMMENT_EDIT',
  CaseDetailsView: 'CASE_DETAILS_VIEW',
  SmileDesignApprove: 'IPP_SETUP_APPROVAL',
  SmileDesignRevise: 'IPP_SETUP_REVISION',
  CompleteCAD: 'CAD_COMPLETE',
  CompleteApplianceDesignStage2: 'APPLIANCE_DESIGN_STAGE_2_COMPLETE',
};

export const EHasPermissionMode = {
  All: 'ALL',
  Some: 'Some',
};

const userHasPermission = (permission_name, permission_list) => {
  const match = _.intersection([permission_name], permission_list);
  return match && match.length > 0;
};

const userHasPermissions = (permission_names, permission_list, mode = EHasPermissionMode.All) => {
  const permissionCheck = (permission) => {
    return userHasPermission(permission, permission_list);
  };
  return mode === EHasPermissionMode.All ? permission_names.every(permissionCheck) : permission_names.some(permissionCheck);
};

const userHasPermissionByCategory = (permission_category, permissions) => {
  var indices = [];
  var filterByCategoryName = function (permission) {
    var index = permission.indexOf(permission_category);
    var is_present = index > -1;
    if (is_present) {
      indices.push(index);
    }

    return is_present;
  };

  permissions.every(filterByCategoryName);

  return indices.length > 1;
};

export { userHasPermission, userHasPermissions, userHasPermissionByCategory };