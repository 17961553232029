// External Libs
import React, { Component } from 'react';

import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermissions } from '../../common/permission';

/**
 * This permission check component will render components only if the right roles and permissions are present
 */
class SecurityClearance extends Component {
  render = () => {
    const action = this.props.action || 'HIDE';
    return (
      <UserPermissionsContext.Consumer>
        {user_permissions => {
          return userHasPermissions(this.props.permission_list, user_permissions.permissions, this.props.mode) && action === 'HIDE'
            ? this.props.children
            : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };
}

export default SecurityClearance;
