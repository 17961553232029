import React, { Component } from 'react';
import _ from 'lodash';
import Axios from 'axios';
import PropTypes from 'prop-types';

import { textFieldLimited } from '../../common/functions';
import { isValidDate } from '../../common/helpers';
import { splitStrToArray } from './edit_tx_de_plan';
import DEForm2 from '../../doctor/de_submission/de_form2';
import ErrorMessage from '../../doctor/components/container/error_message';

/**
 * Displays the DE plan editing form
 * @component
 * @alias EditDEPlan
 * @category BPP
 */
class EditDEPlan extends Component {
  state = {
    ...this.props.treatment_plan,
    de2_teeth_restriction: splitStrToArray(this.props.treatment_plan.de2_teeth_restriction, { keepEmptyStrings: true }),
    de2_bracket_remove: splitStrToArray(this.props.treatment_plan.de2_bracket_remove, { keepEmptyStrings: true }),
    de2_bracket_add: splitStrToArray(this.props.treatment_plan.de2_bracket_add, { keepEmptyStrings: true }),
    de2_spacing_lower: splitStrToArray(this.props.treatment_plan.de2_spacing_lower, { keepEmptyStrings: true }),
    de2_spacing_upper: splitStrToArray(this.props.treatment_plan.de2_spacing_upper, { keepEmptyStrings: true }),
    de2_prev_case_info: JSON.parse(this.props.treatment_plan.de2_prev_case_info),
    de2_initial_date_warning: false,
    de2_intra_date_warning: false,
    de2_upper_smartwire_date_warning: false,
    de2_lower_smartwire_date_warning: false,
  };

  componentDidUpdate() {
    if (this.props.save_tx_plan_changes && this.props.treatment_plan) {
      // check for changes, completeness
      if (this.isDEFormComplete() && this.isDEFormChanged()) {
        this.postTXPlan();
      } else {
        this.props.changeSaveValue();
      }
    }
  }

  /**
   * Performs a POST request to save the form and POST request to send email
   * @function
   */
  postTXPlan() {
    const newTxPlan = {
      description: 'Treatment Plan Edited - ' + this.props.description.trim(),

      ...this.state,
      de2_prev_case_info: this.props.treatment_plan.de2_prev_case_info,
      de2_teeth_restriction: this.state.de2_teeth_restriction.toString(),
      de2_bracket_remove: this.state.de2_bracket_remove.toString(),
      de2_bracket_add: this.state.de2_bracket_add.toString(),
      de2_spacing_lower: this.state.de2_spacing_lower.toString(),
      de2_spacing_upper: this.state.de2_spacing_upper.toString(),
      de2_setup_instruction: this.state.de2_setup_instruction?.trim() ?? '',
      de2_ipr_instruction: this.state.de2_ipr_instruction?.trim() ?? '',
      de2_spacing_instruction: this.state.de2_spacing_instruction?.trim() ?? '',
      de2_special_instruction: this.state.de2_special_instruction?.trim() ?? '',
      de2_reason: this.state.de2_reason.trim(),
    };

    this.props.setStatus({ loading: true, submit: true });
    Axios.post(`/apiV2/treatmentplan/${this.props.case_id}`, newTxPlan)
      .then((resp) => {
        // sends email regarding treatment plan changes
        Axios.post(`/api/email/?slug=treatment-form-change&caseId=${this.props.case_id}&method=standard&provider=${window.location.origin}`);

        this.props.reloadInformation();
        this.props.setStatus({ loading: false });
      })
      .catch((err) => {
        this.props.setStatus({ loading: false, error: true });
      });
  }

  /**
   * Returns whether the de form has changed
   * @function
   * @return {Boolean} Whether de form has changed
   */
  isDEFormChanged() {
    // check against the props.treatment_plan to see if there are any changes
    for (const key in this.state) {
      if (key === 'de2_prev_case_info' || key === 'warning') {
        continue;
      }
      // these values are array
      else if (
        key === 'de2_teeth_restriction' ||
        key === 'de2_bracket_remove' ||
        key === 'de2_bracket_add' ||
        key === 'de2_spacing_lower' ||
        key === 'de2_spacing_upper'
      ) {
        const prev_value = splitStrToArray(this.props.treatment_plan[key]).filter(String);
        const state_value = this.state[key].filter(String);

        if (!(prev_value.every((elem) => state_value.includes(elem)) && state_value.every((elem) => prev_value.includes(elem)))) {
          return true;
        }
      } else {
        if (this.props.treatment_plan[key] !== this.state[key] && !key.includes('date_warning')) {
          return true;
        }
      }
    }

    const warning = '<li>No changes have been made. Please update the Digital Enhancement form to save.</li>';
    this.props.showWarning(warning);

    return false;
  }

  /**
   * Returns whether de form's required fields are completed
   * @function
   * @return {Boolean} Whether completed de form's required fields
   */
  isDEFormComplete() {
    if (
      this.state.de2_intra_date === '' ||
      this.state.de2_reason.trim() === '' ||
      this.state.de2_initial_date === '' ||
      this.props.description.trim() === '' ||
      this.state.de2_initial_date_warning ||
      this.state.de2_intra_date_warning ||
      this.state.de2_upper_smartwire_date_warning ||
      this.state.de2_lower_smartwire_date_warning
    ) {
      let errors = '';

      if (this.props.description.trim() === '') {
        if (document.querySelector('#tx_edit_desrcription_title')) {
          document.querySelector('#tx_edit_desrcription_title').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_heading')) {
          document.querySelector('#tx_edit_desrcription_heading').classList.add('warning-text');
        }
        if (document.querySelector('#tx_edit_desrcription_textarea')) {
          document.querySelector('#tx_edit_desrcription_textarea').classList.add('warning-border');
        }
        errors = errors + '<li><a href="#tx_edit_desrcription_heading">Description of Changes</a></li>';
      }

      if (this.state.de2_reason.trim() === '') {
        if (document.querySelector('#de2_reason_textarea')) {
          document.querySelector('#de2_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de2_reason')) {
          document.querySelector('#de2_reason').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de2_reason">Reason</a></li>';
      }

      if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
        if (document.querySelector('#de2_initial_date_input')) {
          document.querySelector('#de2_initial_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_initial_date')) {
          document.querySelector('#de2_initial_date').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de2_initial_date">Bond Date</a></li>';
      }

      if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
        if (document.querySelector('#de2_intra_date_input')) {
          document.querySelector('#de2_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_intra_date')) {
          document.querySelector('#de2_intra_date').classList.add('warning-text');
        }
        errors = errors + '<li><a href="#de2_intra_date">Scan Date</a></li>';
      }

      this.props.showWarning(errors);
      return false;
    }

    return true;
  }

  /**
   * Clears the spacing value of a particular index
   * @function
   * @param {Number} index - The index of spacing value to clear
   * @param {String} state - Change upper or lower spacing
   */
  clearSpacingValue = (index, state) => {
    let { de2_spacing_upper, de2_spacing_lower } = this.state;

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = '';
        break;
      case 'de2_spacing_lower':
        de2_spacing_lower[index] = '';
        break;

      default:
    }

    this.setState({
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };

  /**
   * Formats the value to have 0 after decimal point
   * @function
   * @param {String} value - The value to format
   * @return {String} The formatted value
   */
  formatMMFromBlur = (value) => {
    let formattedValue = value;

    if (formattedValue.length < 3) {
      if (value.indexOf('.') === value.length - 1 && value !== '') {
        formattedValue = formattedValue + '0';
      }
    }

    return formattedValue;
  };

  /**
   * Changes the spacing value of a particular index and upper or lower
   * @function
   * @param {Number} index - The index of spacing value to change
   * @param {String} state - The spacing to change
   */
  onChangeSpacingOnBlur = (index, state) => {
    let { de2_spacing_upper, de2_spacing_lower } = this.state;

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = this.formatMMFromBlur(de2_spacing_upper[index]);
        break;

      case 'de2_spacing_lower':
        de2_spacing_lower[index] = this.formatMMFromBlur(de2_spacing_lower[index]);
        break;

      default:
    }

    this.setState({
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };

  /**
   * Handles all input changes on the form
   * @function
   * @param {Object} event - The event object on change
   */
  onInputChange_de2 = (event) => {
    let de2_reason = this.state.de2_reason;
    let de2_initial_date = this.state.de2_initial_date;
    let de2_intra_date = this.state.de2_intra_date;
    let de2_upper_smartwire = this.state.de2_upper_smartwire;
    let de2_upper_smartwire_date = this.state.de2_upper_smartwire_date;
    let de2_lower_smartwire = this.state.de2_lower_smartwire;
    let de2_lower_smartwire_date = this.state.de2_lower_smartwire_date;
    let de2_arch_to_treat = this.state.de2_arch_to_treat;
    let de2_arch_opposing_treat = this.state.de2_arch_opposing_treat;
    let de2_setup = this.state.de2_setup;
    let de2_setup_instruction = this.state.de2_setup_instruction;
    let de2_setup_additional_instruction = this.state.de2_setup_additional_instruction;
    let de2_bracket_instruction = this.state.de2_bracket_instruction;
    let de2_teeth_restriction = this.state.de2_teeth_restriction;
    let de2_bracket_remove = this.state.de2_bracket_remove;
    let de2_bracket_add = this.state.de2_bracket_add;
    let de2_ipr = this.state.de2_ipr;
    let de2_ipr_instruction = this.state.de2_ipr_instruction;
    let de2_spacing = this.state.de2_spacing;
    let de2_spacing_upper = this.state.de2_spacing_upper;
    let de2_spacing_lower = this.state.de2_spacing_lower;
    let de2_spacing_instruction = this.state.de2_spacing_instruction;
    let de2_special_instruction = this.state.de2_special_instruction;
    let de2_initial_date_warning = this.state.de2_initial_date_warning;
    let de2_intra_date_warning = this.state.de2_intra_date_warning;
    let de2_upper_smartwire_date_warning = this.state.de2_upper_smartwire_date_warning;
    let de2_lower_smartwire_date_warning = this.state.de2_lower_smartwire_date_warning;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';
    let index = 0;

    switch (event_type) {
      case 'de2_reason':
        de2_reason = textFieldLimited(event.target.value);
        break;

      case 'de2_initial_date':
        de2_initial_date = event.target.value;
        de2_initial_date_warning = !isValidDate(de2_initial_date);
        break;

      case 'de2_intra_date':
        de2_intra_date = event.target.value;
        de2_intra_date_warning = !isValidDate(de2_intra_date);
        break;

      case 'de2_upper_smartwire':
        de2_upper_smartwire = event.target.value;

        if (de2_upper_smartwire === 'none') {
          de2_upper_smartwire_date = '';
        }
        break;

      case 'de2_upper_smartwire_date':
        de2_upper_smartwire_date = event.target.value;
        de2_upper_smartwire_date_warning = !isValidDate(de2_upper_smartwire_date);
        break;

      case 'de2_lower_smartwire':
        de2_lower_smartwire = event.target.value;

        if (de2_lower_smartwire === 'none') {
          de2_lower_smartwire_date = '';
        }
        break;

      case 'de2_lower_smartwire_date':
        de2_lower_smartwire_date = event.target.value;
        de2_lower_smartwire_date_warning = !isValidDate(de2_lower_smartwire_date);
        break;

      case 'de2_arch_to_treat':
        if (de2_arch_to_treat !== event.target.value) {
          de2_arch_opposing_treat = 'current';
        }

        de2_arch_to_treat = event.target.value;
        break;

      case 'de2_arch_opposing_treat':
        de2_arch_opposing_treat = event.target.value;
        break;

      case 'de2_setup':
        de2_setup = event.target.value;
        break;

      case 'de2_setup_instruction':
        de2_setup_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_setup_additional_instruction':
        de2_setup_additional_instruction = event.target.value;
        break;

      case 'de2_bracket_instruction':
        de2_bracket_instruction = event.target.value;
        break;

      case 'de2_teeth_restriction':
        if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
          de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
        } else {
          de2_teeth_restriction.push(event.target.value);
        }
        break;

      case 'de2_bracket_remove':
        if (_.intersection(de2_bracket_remove, [event.target.value]).length > 0) {
          de2_bracket_remove = _.difference(de2_bracket_remove, [event.target.value]);

          if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
            de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
          }
        } else {
          de2_bracket_remove.push(event.target.value);
          de2_teeth_restriction.push(event.target.value);
          de2_teeth_restriction = _.uniq(de2_teeth_restriction);
        }
        break;

      case 'de2_bracket_add':
        if (_.intersection(de2_bracket_add, [event.target.value]).length > 0) {
          de2_bracket_add = _.difference(de2_bracket_add, [event.target.value]);
        } else {
          de2_bracket_add.push(event.target.value);
        }
        break;

      case 'de2_ipr':
        de2_ipr = event.target.value;
        break;

      case 'de2_ipr_instruction':
        de2_ipr_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_spacing':
        de2_spacing = event.target.value;
        break;

      case 'de2_spacing_upper':
        index = parseInt(event.target.dataset.index);
        de2_spacing_upper[index] = this.formatMMEntry(de2_spacing_upper, event.target.value, index);
        break;

      case 'de2_spacing_lower':
        index = parseInt(event.target.dataset.index);
        de2_spacing_lower[index] = this.formatMMEntry(de2_spacing_lower, event.target.value, index);
        break;

      case 'de2_spacing_instruction':
        de2_spacing_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_special_instruction':
        de2_special_instruction = textFieldLimited(event.target.value);
        break;

      default:
    }

    this.setState({
      de2_reason: de2_reason,
      de2_initial_date: de2_initial_date,
      de2_intra_date: de2_intra_date,
      de2_upper_smartwire: de2_upper_smartwire,
      de2_upper_smartwire_date: de2_upper_smartwire_date,
      de2_lower_smartwire: de2_lower_smartwire,
      de2_lower_smartwire_date: de2_lower_smartwire_date,
      de2_arch_to_treat: de2_arch_to_treat,
      de2_arch_opposing_treat: de2_arch_opposing_treat,
      de2_setup: de2_setup,
      de2_setup_instruction: de2_setup_instruction,
      de2_setup_additional_instruction: de2_setup_additional_instruction,
      de2_bracket_instruction: de2_bracket_instruction,
      de2_teeth_restriction: de2_teeth_restriction,
      de2_bracket_remove: de2_bracket_remove,
      de2_bracket_add: de2_bracket_add,
      de2_ipr: de2_ipr,
      de2_ipr_instruction: de2_ipr_instruction,
      de2_spacing: de2_spacing,
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
      de2_spacing_instruction: de2_spacing_instruction,
      de2_special_instruction: de2_special_instruction,
      de2_initial_date_warning: de2_initial_date_warning,
      de2_intra_date_warning: de2_intra_date_warning,
      de2_upper_smartwire_date_warning: de2_upper_smartwire_date_warning,
      de2_lower_smartwire_date_warning: de2_lower_smartwire_date_warning,
    });
  };

  /**
   * Formats value to have a format of `X.X`
   *
   * @function
   * @param {Object} state - The state to change
   * @param {String} value - The value to format
   * @param {Number} index - The index in the state
   * @return {String} The formatted value
   */
  formatMMEntry = (state, value, index) => {
    // Value should be X.X
    const prevCond = state[index];

    let formattedValue = value
      .replace(/[^0-9.]/g, '')
      .replace('..', '.')
      .replace('00', '0');

    //Enforce .
    if (formattedValue.length === 1 && prevCond === '' && formattedValue !== '.') {
      formattedValue += '.';
    } else if (formattedValue.length === 1 && prevCond === '' && formattedValue === '.') {
      formattedValue = '0.';
    } else if ((value.length > 2 && prevCond === '0') || (value.length === 2 && parseInt(prevCond) <= 9 && value[1] !== '.' && value[0] !== '.')) {
      formattedValue = value[0] + '.' + value[1];
    } else if (formattedValue.length === 1 && prevCond === '.') {
      formattedValue = '';
    }

    //if . is detected than only allow one digit after
    const decimalPos = formattedValue.indexOf('.');
    if (decimalPos >= 0 && formattedValue.length >= decimalPos + 2) {
      formattedValue = formattedValue.slice(0, decimalPos + 2);
    }

    //trim off leading 0 conditions
    if (decimalPos !== 1 && value[0] === '0') {
      formattedValue = formattedValue.slice(1);
    }

    //max character limit
    if (value.length >= 4) {
      formattedValue = prevCond;

      if (parseFloat(value) > 9.9) {
        formattedValue = '9.9';
      }
    } else if (formattedValue.length === 3 && formattedValue[2] === '.') {
      formattedValue = '9.9';
    }

    return formattedValue;
  };

  render() {
    return (
      <>
        <DEForm2
          onInputChange_de2={this.onInputChange_de2}
          clearSpacingValue={this.clearSpacingValue}
          onChangeSpacingOnBlur={this.onChangeSpacingOnBlur}
          onFocus={this.props.clearWarning}
          getDeHold={() => {}}
          photoUpload={this.props.photoUpload}
          {...this.state}
          gen_2={this.props.gen_2}
        />
        <ErrorMessage
          title="Incomplete Fields"
          onClose={this.props.clearWarning}
          className={this.props.warning ? 'error-message-container' : 'error-message-container hide'}
        >
          <div id="warning-submit">Warning</div>
        </ErrorMessage>
      </>
    );
  }
}

EditDEPlan.propTypes = {
  /**
   * Case ID
   */
  case_id: PropTypes.string.isRequired,
  /**
   * Raw treatment plan with each value as string
   */
  treatment_plan: PropTypes.object.isRequired,
  /**
   * Whether the save button had been clicked
   */
  save_tx_plan_changes: PropTypes.bool.isRequired,
  /**
   * To change save_tx_plan_changes value to false
   */
  changeSaveValue: PropTypes.func.isRequired,
  /**
   * To reload the case details
   */
  reloadInformation: PropTypes.func.isRequired,
  /**
   * To close the modal after successful saving
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Description of changes
   */
  description: PropTypes.string.isRequired,
  /**
   * To show warnings
   */
  showWarning: PropTypes.func.isRequired,
  /**
   * To display warnings or not
   */
  warning: PropTypes.bool.isRequired,
  /**
   * To clear warnings
   */
  clearWarning: PropTypes.func.isRequired,
  /**
   * To set submit, warning, loading, and error status
   */
  setStatus: PropTypes.func.isRequired,
};

export default EditDEPlan;
