import Moment from 'moment';
import { parsePhoneNumber } from 'libphonenumber-js';
import ValidationUtils from '../../common/validation/validation-utils';
import { isValidDate } from '../../common/helpers';

const today_date = Moment().format('YYYY-MM-DD');
const min_date = '1900-01-01';
const min_date_edit_section_fields = '2010-01-01';
const max_date = Moment().add(10, 'years').format('YYYY-MM-DD');

/**
 * Check if phone number is valid
 * @function
 */
const isValidPhoneNo = (value) => {
  let is_valid = false;

  if (value === '') {
    return true;
  }

  try {
    const usa_number = parsePhoneNumber(value, 'US');
    const valid_number = ValidationUtils.isValidPhoneNo(value);

    if (valid_number && usa_number.isValid()) {
      is_valid = true;
    }
  } catch (error) {
    // Do Nothing
  }

  return is_valid;
};
/**
 * Check if email is valid
 * @function
 */
const isValidEmail = (value) => {
  return ValidationUtils.isValidEmail(value) || value === '';
};
/**
 * Check to see is not empty
 * @function
 */
const isNotEmpty = (value) => {
  return value && value !== null && value !== '';
};

const validDate = (value) => {
  return isValidDate(value, min_date);
};

export const prospect_questions = [
  {
    unique_id: 1,
    sort_order: 1,
    group: 1,
    label: 'First Name*',
    field_mapping: 'first_name',
    input_type: 'text',
    input_capitalize: true,
    value_org: null,
    value: null,
    max: 30,
    min: null,
    editable: true,
    validation: isNotEmpty,
    error: false,
    editable_after_converted: false,
    hide_on_detail: true,
    required: true,
    tabIndex: 1,
  },
  {
    unique_id: 2,
    sort_order: 2,
    group: 1,
    label: 'Last Name*',
    field_mapping: 'last_name',
    input_type: 'text',
    input_capitalize: true,
    value_org: null,
    value: null,
    max: 30,
    min: null,
    editable: true,
    validation: isNotEmpty,
    error: false,
    editable_after_converted: false,
    hide_on_detail: true,
    required: true,
    tabIndex: 2,
  },
  {
    unique_id: 3,
    sort_order: 3,
    group: 1,
    label: 'Date of Birth*',
    field_mapping: 'dob',
    input_type: 'date',
    value_org: null,
    value: null,
    max: today_date,
    min: min_date,
    editable: true,
    error: false,
    error_message: 'Invalid date',
    validation: validDate,
    required: true,
    editable_after_converted: false,
    tabIndex: 3,
  },
  {
    unique_id: 4,
    sort_order: 4,
    group: 1,
    label: 'Gender',
    field_mapping: 'sex',
    input_type: 'radio',
    value_org: null,
    value: 'U',
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: false,
    tabIndex: 4,
    input_list: [
      {
        label: 'Female',
        value: 'F',
        tabIndex: 5,
      },
      {
        label: 'Male',
        value: 'M',
        tabIndex: 6,
      },
      {
        label: 'Non-Binary',
        value: 'N',
        tabIndex: 7,
      },
      {
        label: 'Undisclosed',
        value: 'U',
        tabIndex: 8,
      },
    ],
  },
  {
    unique_id: 5,
    sort_order: 5,
    group: 2,
    label: 'Parent Submitting for Child',
    field_mapping: 'parent_submitting_for_child',
    input_type: 'checkbox',
    value_org: false,
    value: false,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 9,
  },
  {
    unique_id: 7,
    sort_order: 7,
    group: 3,
    label: 'Parent First Name*',
    field_mapping: 'parent_first_name',
    input_type: 'text',
    input_capitalize: true,
    value_org: null,
    value: null,
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    dependency_unique_id: 5,
    required: true,
    tabIndex: 10,
  },

  {
    unique_id: 8,
    sort_order: 8,
    group: 3,
    label: 'Parent Last Name*',
    field_mapping: 'parent_last_name',
    input_capitalize: true,
    input_type: 'text',
    value_org: null,
    value: null,
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    dependency_unique_id: 5,
    required: true,
    tabIndex: 11,
  },
  {
    unique_id: 9,
    sort_order: 9,
    group: 4,
    label: 'Zip Code*',
    field_mapping: 'postal_code',
    input_type: 'text',
    value_org: null,
    value: null,
    max: 20,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    required: true,
    tabIndex: 12,
  },
  {
    unique_id: 10,
    sort_order: 10,
    group: 4,
    label: 'Phone Service Carrier',
    field_mapping: 'phone_service_carrier',
    input_type: 'text',
    value_org: null,
    value: null,
    max: 30,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 13,
  },
  {
    unique_id: 11,
    sort_order: 11,
    group: 4,
    label: 'Phone Number*',
    field_mapping: 'phone',
    input_type: 'text',
    value_org: null,
    value: null,
    max: 100,
    min: null,
    editable: true,
    validation: isValidPhoneNo,
    error: false,
    error_message: 'Invalid phone number',
    editable_after_converted: true,
    required: true,
    tabIndex: 14,
  },
  {
    unique_id: 12,
    sort_order: 12,
    group: 4,
    label: 'Receive SMS Marketing Communication',
    field_mapping: 'sms_comms',
    input_type: 'checkbox',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 15,
  },
  {
    unique_id: 13,
    sort_order: 13,
    group: 4,
    label: 'Email Address*',
    field_mapping: 'email',
    input_type: 'text',
    value_org: '',
    value: '',
    max: 255,
    min: null,
    editable: true,
    validation: (value) => {
      return isValidEmail(value) && isNotEmpty(value);
    },
    error: false,
    error_message: 'Invalid email address',
    editable_after_created: false,
    editable_after_converted: false,
    required: true,
    tabIndex: 16,
  },

  {
    unique_id: 14,
    sort_order: 14,
    group: 4,
    label: 'Receive Email Marketing Notifications',
    field_mapping: 'email_comms',
    input_type: 'checkbox',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 17,
  },

  {
    unique_id: 15,
    sort_order: 15,
    group: 4,
    label: 'How Did Patient Hear About Us',
    field_mapping: 'advertising_source',
    input_type: 'multi-checkbox-other',
    value_org: null,
    value: null,
    value_other_org: '',
    value_other: '',
    value_referral: '',
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 18,
    input_list: [
      {
        label: 'Blog/Publication/News Article',
        value: 'Blog/Publication/News Article',
        tabIndex: 19,
      },
      {
        label: 'Doctor',
        value: 'Doctor',
        tabIndex: 20,
      },
      {
        label: 'Friend',
        value: 'Friend',
        tabIndex: 21,
      },
      {
        label: 'Facebook',
        value: 'Facebook',
        tabIndex: 22,
      },
      {
        label: 'Instagram',
        value: 'Instagram',
        tabIndex: 23,
      },
      {
        label: 'Internet Ad',
        value: 'Internet Ad',
        tabIndex: 24,
      },
      {
        label: 'Internet Search',
        value: 'Internet Search',
        tabIndex: 25,
      },
      {
        label: 'Rating and Review Site (Yelp, Real Self, etc.)',
        value: 'Rating and Review Site',
        tabIndex: 26,
      },
      {
        label: 'Referral',
        value: 'Referral',
        tabIndex: 27,
      },
      {
        label: 'Other',
        value: 'Other',
        tabIndex: 28,
      },
    ],
  },

  {
    unique_id: 14,
    sort_order: 14,
    group: 5,
    label: 'Date Prospect Transferred to Ortho',
    field_mapping: 'date_prospect_transferred',
    input_type: 'date',
    error_message: 'Invalid date',
    value_org: null,
    value: null,
    max: today_date,
    min: min_date,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },

  {
    unique_id: 15,
    sort_order: 15,
    group: 5,
    label: 'Referred by (Name)',
    field_mapping: 'referral_name',
    input_type: 'text',
    value_org: null,
    value: null,
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 16,
    sort_order: 16,
    group: 5,
    label: 'Received Previous Orthodontic Treatment',
    field_mapping: 'previous_ortho_tx',
    input_type: 'checkbox',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 17,
    sort_order: 17,
    group: 5,
    label: 'Previous Orthodontic Treatment',
    field_mapping: 'previous_tx_details',
    input_type: 'multi-checkbox-other',
    value_org: null,
    value: null,
    value_other_org: '',
    value_other: '',
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    dependency_unique_id: 17,
    input_list: [
      {
        label: 'At home aligners',
        value: 'At home aligners',
      },
      {
        label: 'Professional aligners',
        value: 'Professional aligners',
      },
      {
        label: 'Traditional braces',
        value: 'Traditional braces',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  {
    unique_id: 18,
    sort_order: 18,
    group: 5,
    label: 'Received In-Office Consultation*',
    field_mapping: 'office_consultation',
    input_type: 'checkbox',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 19,
    sort_order: 19,
    group: 5,
    label: 'In-Office Consultation Date',
    field_mapping: 'office_consultation_date',
    input_type: 'date',
    value_org: null,
    value: null,
    max: max_date,
    min: min_date_edit_section_fields,
    editable: true,
    validation: null,
    error: false,
    error_message: 'Invalid date',
    editable_after_converted: true,
  },
  {
    unique_id: 20,
    sort_order: 20,
    group: 5,
    label: 'Prospect Lost Reason',
    field_mapping: 'referred_lost_reason',
    input_type: 'text',
    value_org: null,
    value: null,
    max: 245,
    min: null,
    editable: false,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 21,
    sort_order: 21,
    group: 5,
    label: 'GP Treatment Needed Prior to Start',
    field_mapping: 'gp_tx_needed',
    input_type: 'checkbox',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 22,
    sort_order: 22,
    group: 5,
    label: 'Other Treatment Needed',
    field_mapping: 'ortho_tx_needed',
    input_type: 'multi-checkbox-other',
    value_org: null,
    value: null,
    value_other_org: '',
    value_other: '',
    max: 255,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    input_list: [
      {
        label: 'Extractions',
        value: 'Extractions',
      },
      {
        label: 'Periodontal Work',
        value: 'Periodontal Work',
      },
      {
        label: 'Restorative Work',
        value: 'Restorative Work',
      },
      {
        label: 'Standard Hygiene Check',
        value: 'Standard Hygiene Check',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },
  {
    unique_id: 24,
    sort_order: 24,
    group: 5,
    label: 'Insurance Submitted',
    field_mapping: 'insurance_submitted',
    input_type: 'dropdown',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    input_list: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
      {
        label: 'Patient does not have insurance',
        value: 'Patient does not have insurance',
      },
    ],
    input_data_type: 'boolean',
  },
  {
    unique_id: 16,
    sort_order: 16,
    group: 4,
    label: 'Dental Insurance Information',
    field_mapping: 'dental_insurance_information',
    input_type: 'textarea',
    value_org: null,
    value: null,
    max: 750,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    tabIndex: 29,
  },
  {
    unique_id: 26,
    sort_order: 26,
    group: 5,
    label: 'Scan Date*',
    field_mapping: 'records_submitted',
    input_type: 'radio',
    value_org: null,
    value: 'Scheduled',
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    required: false,
    editable_after_converted: true,
    input_list: [
      {
        label: 'Scheduled',
        value: 'Scheduled',
        show_field: 'anticipated_scan_date',
      },
      {
        label: 'To be scheduled',
        value: 'To be scheduled',
      },
    ],
  },

  {
    unique_id: 27,
    sort_order: 27,
    group: 5,
    label: 'Anticipated Scan Date',
    field_mapping: 'anticipated_scan_date',
    input_type: 'date',
    value_org: null,
    value: null,
    max: max_date,
    min: min_date_edit_section_fields,
    editable: true,
    validation: null,
    error: false,
    error_message: 'Invalid date',
    editable_after_converted: true,
    dependency_unique_id: 26,
    dependency_value: 'Scan Scheduled',
  },
  {
    unique_id: 28,
    sort_order: 28,
    group: 5,
    label: 'Scans/Images Submitted Date',
    field_mapping: 'records_submitted_date',
    input_type: 'date',
    value_org: null,
    value: null,
    max: today_date,
    min: min_date,
    editable: true,
    validation: null,
    error: false,
    error_message: 'Invalid date',
    editable_after_converted: true,
    dependency_unique_id: 26,
    dependency_value: 'Scans Submitted',
  },
  {
    unique_id: 29,
    sort_order: 29,
    group: 5,
    label: 'Bonded',
    field_mapping: 'bonded',
    input_type: 'dropdown',
    value_org: null,
    value: null,
    max: null,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
    input_list: [
      {
        label: 'Yes',
        value: 'Yes',
      },
      {
        label: 'No',
        value: 'No',
      },
    ],
  },
  {
    unique_id: 30,
    sort_order: 30,
    group: 5,
    label: 'Bond Date',
    field_mapping: 'bonded_date',
    input_type: 'date',
    error_message: 'Invalid date',
    value_org: null,
    value: null,
    max: max_date,
    min: min_date,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 31,
    sort_order: 31,
    group: 5,
    label: 'Provider Notes',
    field_mapping: 'provider_notes',
    input_type: 'textarea',
    value_org: null,
    value: null,
    max: 30000,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
  {
    unique_id: 32,
    sort_order: 32,
    group: 5,
    label: 'InBrace Notes',
    field_mapping: 'inbrace_notes',
    input_type: 'textarea',
    value_org: null,
    value: null,
    max: 30000,
    min: null,
    editable: true,
    validation: null,
    error: false,
    editable_after_converted: true,
  },
];
