import { v4 as uuidv4 } from 'uuid';

export const BROWSER_TAB_ID_KEY = 'browserTabId';

/**
 * Object containing message commands and their corresponding functions.
 * @typedef {Object} MessageCommands
 * @property {Function} refresh - Function that reloads the current window.
 */
const messageCommands = {
  refreshCaseListPage: () => {
    const caseListPathnameRegex = new RegExp('^/portal/[0-9]+$');
    if (caseListPathnameRegex.test(window.location.pathname)) {
      window.location.reload();
    }
  },
  refreshDashboardPage: () => {
    const dashboardPathnameRegex = new RegExp('^/portal/[0-9]+/dashboard$');
    if (dashboardPathnameRegex.test(window.location.pathname)) {
      window.location.reload();
    }
  },
};

/**
 * Creates a message object with a command and data.
 * @param {string} command - The command to be executed.
 * @param {object} data - The data to be passed along with the command.
 * @returns {object} - The message object with the command and data.
 */
export const createMessage = (command, data = {}) => {
  return { command, data };
};

/**
 * Stores a message object in local storage and immediately removes it.
 * @param {object} message - The message object to be stored.
 */
export const messageBroadcast = (message) => {
  localStorage.setItem('crossTabMessage', JSON.stringify(message));
  localStorage.removeItem('crossTabMessage');
};

/**
 * Handles the message received from the storage event.
 * @param {StorageEvent} event - The storage event object.
 */
export const storageMessageHandler = (event) => {
  if (event.key !== 'crossTabMessage') return; // ignore other keys
  const message = JSON.parse(event.newValue);
  if (!message) return; // ignore empty msg or msg reset

  if (message.command in messageCommands) {
    messageCommands[message.command](message.data);
  }
};

/**
 * Sets a unique tab ID in the session storage.
 */
export const setBrowserTabId = () => {
  if (typeof Storage !== 'undefined') {
    sessionStorage.setItem(BROWSER_TAB_ID_KEY, uuidv4());
  }
};

/**
 * Retrieves the tab ID from the session storage.
 * @returns {string|null} The tab ID if it exists, otherwise null.
 */
export const getBrowserTabId = () => {
  if (typeof Storage !== 'undefined') {
    return sessionStorage.getItem(BROWSER_TAB_ID_KEY);
  }
};

/**
 * Sends a refresh case list message.
 */
export const sendRefreshCaseListMessage = () => {
  messageBroadcast(createMessage('refreshCaseListPage'));
};

/**
 * Sends a refresh dashboard message.
 */
export const sendRefreshDashboardMessage = () => {
  messageBroadcast(createMessage('refreshDashboardPage'));
};
