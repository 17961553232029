import { makePresets } from '../../../common/helpers';

import {
  SET_TREATMENT_PLAN_REVISION,
  SET_SELECTED_TEETH,
  SET_MESSAGE,
  SET_TEXT_BODY,
  SET_SHOW_ARCH_SELECTION,
  SET_SHOW_TEXT_BOX,
  SET_TEETH_SELECTOR_LABEL,
  SET_TREATMENT_ARCH,
  SET_OPPOSING_TREATMENT_ARCH,
  SET_UPPER_OPPOSING_TREATMENT_ARCH,
  SET_LOWER_OPPOSING_TREATMENT_ARCH,
  SET_TEXT_BOX_LABEL,
  SET_TEXT_BOX_PLACEHOLDER,
  SET_TEXT_BOX_NOTE,
  SET_ERROR,
  CLEAR_TEETH_CHART_INFORMATION,
  SET_DISABLED_TEETH,
  SET_SHOW_PRESETS,
  SET_PRESET_SELECTION,
  SET_ARCH_DISABLED,
} from '../../actions/common/teeth_chart';

const initialState = {
  treatment_plan_revision: null,
  selected_teeth: [],
  message: '',
  text_body: null,
  show_arch_selection: false,
  show_text_box: false,
  text_box_label: '',
  text_box_placeholder: '',
  text_box_note: '',
  teeth_selector_label: '',
  treatment_arch: 'both',
  opposing_treatment_arch: '',
  upper_opposing_treatment_arch: '',
  lower_opposing_treatment_arch: '',
  arch_disabled: false,
  error: {
    is_error: false,
    message: '',
    type: '',
  },
  disabled_teeth: [],
  show_presets: false,
  preset_selection: null,
  presets: makePresets(5, 7),
};

/**
 * This reducer is used for the teeth chart selector
 * @function
 * @param {Object} state - Initial state
 * @param {Object} action - The action that contains the values to update the store with
 */
export function teethChartReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TREATMENT_PLAN_REVISION:
      return {
        ...state,
        treatment_plan_revision: action.treatment_plan_revision,
      };

    case SET_SELECTED_TEETH:
      return {
        ...state,
        selected_teeth: action.selected_teeth,
      };

    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };

    case SET_TEXT_BODY:
      return {
        ...state,
        text_body: action.text_body,
      };

    case SET_SHOW_ARCH_SELECTION:
      return {
        ...state,
        show_arch_selection: action.show_arch_selection,
      };

    case SET_ARCH_DISABLED:
      return {
        ...state,
        arch_disabled: action.arch_disabled,
      };

    case SET_SHOW_TEXT_BOX:
      return {
        ...state,
        show_text_box: action.show_text_box,
      };

    case SET_TEETH_SELECTOR_LABEL:
      return {
        ...state,
        teeth_selector_label: action.teeth_selector_label,
      };

    case SET_TREATMENT_ARCH:
      return {
        ...state,
        treatment_arch: action.treatment_arch,
      };

    case SET_OPPOSING_TREATMENT_ARCH:
      return {
        ...state,
        opposing_treatment_arch: action.opposing_treatment_arch,
      };

    case SET_UPPER_OPPOSING_TREATMENT_ARCH:
      return {
        ...state,
        upper_opposing_treatment_arch: action.upper_opposing_treatment_arch,
        opposing_treatment_arch: action.upper_opposing_treatment_arch,
      };

    case SET_LOWER_OPPOSING_TREATMENT_ARCH:
      return {
        ...state,
        lower_opposing_treatment_arch: action.lower_opposing_treatment_arch,
        opposing_treatment_arch: action.lower_opposing_treatment_arch,
      };

    case SET_TEXT_BOX_LABEL:
      return {
        ...state,
        text_box_label: action.text_box_label,
      };

    case SET_TEXT_BOX_PLACEHOLDER:
      return {
        ...state,
        text_box_placeholder: action.text_box_placeholder,
      };

    case SET_TEXT_BOX_NOTE:
      return {
        ...state,
        text_box_note: action.text_box_note,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case CLEAR_TEETH_CHART_INFORMATION:
      return {
        ...state,
        error: {
          is_error: false,
          message: '',
          type: '',
        },
        teeth_selector_label: '',
        show_arch_selection: false,
        show_text_box: false,
        text_box_label: '',
        text_box_placeholder: '',
        text_body: null,
        treatment_arch: 'both',
        opposing_treatment_arch: '',
        selected_teeth: [],
        text_box_note: '',
        disabled_teeth: [],
        preset_selection: null,
        show_presets: false,
        arch_disabled: false,
      };

    case SET_DISABLED_TEETH:
      return {
        ...state,
        disabled_teeth: action.disabled_teeth,
      };

    case SET_SHOW_PRESETS:
      return {
        ...state,
        show_presets: true,
      };

    case SET_PRESET_SELECTION:
      return {
        ...state,
        preset_selection: action.preset_selection,
      };

    default:
      return state;
  }
}

export const getTreatmentPlanRevision = (state) => state.teethChartReducer.treatment_plan_revision;
export const getSelectedTeeth = (state) => state.teethChartReducer.selected_teeth;
export const getMessage = (state) => state.teethChartReducer.message;
export const getTextBody = (state) => state.teethChartReducer.text_body;
export const getShowArchSelection = (state) => state.teethChartReducer.show_arch_selection;
export const getTeethSelectorLabel = (state) => state.teethChartReducer.teeth_selector_label;
export const getTreatmentArch = (state) => state.teethChartReducer.treatment_arch;
export const getOpposingTreatmentArch = (state) => state.teethChartReducer.opposing_treatment_arch;
export const getUpperOpposingTreatmentArch = (state) => state.teethChartReducer.upper_opposing_treatment_arch;
export const getLowerOpposingTreatmentArch = (state) => state.teethChartReducer.lower_opposing_treatment_arch;
export const getShowTextBox = (state) => state.teethChartReducer.show_text_box;
export const getTextBoxLabel = (state) => state.teethChartReducer.text_box_label;
export const getTextBoxPlaceholder = (state) => state.teethChartReducer.text_box_placeholder;
export const getTextBoxNote = (state) => state.teethChartReducer.text_box_note;
export const getError = (state) => state.teethChartReducer.error;
export const getDisabledTeeth = (state) => state.teethChartReducer.disabled_teeth;
export const getShowPresets = (state) => state.teethChartReducer.show_presets;
export const getPresetSelection = (state) => state.teethChartReducer.preset_selection;
export const getArchDisabled = (state) => state.teethChartReducer.arch_disabled;
