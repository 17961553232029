import React from 'react';
import { Link } from 'react-router-dom';

import { Close } from '../../assets/icons';

const Header = ({ title, onClose, className, iconClassName, titleClassName }) => {
  const getPropsClassName = () => {
    return className ? className : '';
  };

  const getPropsIconClassName = () => {
    return iconClassName ? iconClassName : '';
  };

  const getPropsTitleClassName = () => {
    return titleClassName ? titleClassName : '';
  };

  const handleCloseButtonClick = () => {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '0px';
    if (onClose) onClose();
  };
  return (
    <div className={`modal-header ${getPropsClassName()}`}>
      <button type="button" className={`close ${getPropsIconClassName()}`} aria-label="Close" onClick={handleCloseButtonClick}>
        <Close />
      </button>
      {title ? <h4 className={`modal-title ${getPropsTitleClassName()}`}>{title}</h4> : null}
    </div>
  );
};

const ContentBody = ({ children, className, id }) => {
  const getPropsClassName = () => {
    return className ? className : '';
  };

  return (
    <div className={`modal-content-body ${getPropsClassName()}`} id={id}>
      {children}
    </div>
  );
};

const Footer = ({ children, className }) => {
  const getPropsClassName = () => {
    return className ? className : '';
  };
  return <div className={`modal-footer ${getPropsClassName()}`}>{children}</div>;
};

const FooterWithButtons = ({
  onClickConfirm,
  onClickCancel,
  footerClassName,
  confirmButtonClassName,
  cancelButtonClassName,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  confirmDisabled = false,
  cancelDisabled = false,
  showConfirm = true,
  showCancel = true,
  rerouteLink = null,
}) => {
  const getPropsConfirmButtonClassName = () => {
    return confirmButtonClassName ? confirmButtonClassName : '';
  };

  const getPropsCancelButtonClassName = () => {
    return cancelButtonClassName ? cancelButtonClassName : '';
  };

  const handleConfirmButtonClick = () => {
    if (onClickConfirm) onClickConfirm();
  };

  const handleCancelButtonClick = () => {
    document.body.classList.remove('modal-open');
    document.body.style.paddingRight = '0px';
    if (onClickCancel) onClickCancel();
  };

  const renderCancelButton = () => {
    const renderButton = () => (
      <button
        id="modal_close_btn"
        type="button"
        className={`btn ${getPropsCancelButtonClassName()}`}
        onClick={handleCancelButtonClick}
        disabled={cancelDisabled}
      >
        {cancelButtonText}
      </button>
    );

    return rerouteLink ? (
      <Link to={rerouteLink} onClick={handleCancelButtonClick}>
        {renderButton()}
      </Link>
    ) : (
      renderButton()
    );
  };

  return (
    <Footer className={footerClassName}>
      {showConfirm ? (
        <button
          id="modal_confirm_btn"
          type="button"
          className={`btn btn-spacing ${getPropsConfirmButtonClassName()}`}
          onClick={handleConfirmButtonClick}
          disabled={confirmDisabled}
        >
          {confirmButtonText}
        </button>
      ) : null}
      {showCancel ? renderCancelButton() : null}
    </Footer>
  );
};

const SimpleModal = ({
  show,
  children,
  containerclassName,
  dialogClassName,
  contentClassName,
  bodyClassName,
  size,
  stopClickPropagation = true,
  theme = 'primary',
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const getPropsContainerClassName = () => {
    return containerclassName ? containerclassName : '';
  };

  const getPropsDialogClassName = () => {
    return dialogClassName ? dialogClassName : '';
  };

  const getPropsContentClassName = () => {
    return contentClassName ? contentClassName : '';
  };

  const getPropsBodyClassName = () => {
    return bodyClassName ? bodyClassName : '';
  };

  const handleModalAreaClick = (e) => {
    if (stopClickPropagation) {
      e.stopPropagation();
    }
  };

  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        document.body.classList.add('modal-open');
        document.body.style.paddingRight = '17px';
        setIsModalOpen(true);
      }, 0);
    }

    return () => {
      setIsModalOpen(false);
      document.body.classList.remove('modal-open');
      document.body.style.paddingRight = '0px';
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={`modal base-modal simple-modal ${getPropsContainerClassName()}`}
      role="dialog"
      onClick={handleModalAreaClick}
      style={{
        transform: isModalOpen ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: isModalOpen ? '1' : '0',
      }}
    >
      <div
        className={`modal-dialog ${getPropsDialogClassName()} ${size ? 'size-' + size : null}`}
        style={{
          transform: isModalOpen ? 'translateY(0)' : 'translateY(-100vh)',
          opacity: isModalOpen ? '1' : '0',
        }}
      >
        <div className={`modal-content ${theme ? theme + '-theme' : null} ${getPropsContentClassName()}`}>
          <div className={`modal-body ${getPropsBodyClassName()}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

SimpleModal.Header = Header;
SimpleModal.ContentBody = ContentBody;
SimpleModal.Footer = Footer;
SimpleModal.FooterWithButtons = FooterWithButtons;

export default SimpleModal;
