import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import Button from './button';
import { getCaseIdFromUrl } from '../../../../common/case/case_id';
import Modal from '../modal/modal';
import NewViewerConfirmation from '../modal/new_viewer_confirmation';
import { ApiServiceInstance } from '../../../../common/api_service';
import { UserPermissionsContext } from '../../../../context/user_permission';
import { userHasPermission } from '../../../../common/permission';
import { setTokenHeader } from '../../../../common/functions';
import { fetchCaseDetails } from '../../../../redux/actions/common/common_case_details';
import SessionExpiredModal from '../../../../doctor/components/modals/session_expire';

const KEY_PROVIDER_EDIT_RELEASE_DATE = 'provider_edit_release_date';

/**
 * Creates a beta button to activate the wasm viewer
 * @component
 * @alias SetupWasmViewerBtn
 */
function WasmBtn(props) {
  const { case_details } = props;
  const caseId = getCaseIdFromUrl(document.URL);
  const [isApproveShown, setIsApproveShown] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [isFullReleased, setIsFullReleased] = useState(false);

  useEffect(() => {
    const checkFullRelease = async () => {
      const providerEditReleaseDate = await getProviderEditReleaseDate();
      const caseSubmissionDate = getCaseSubmissionDate();
      setIsFullReleased(providerEditReleaseDate && caseSubmissionDate && caseSubmissionDate > providerEditReleaseDate);
    };
    checkFullRelease();
  }, []);

  /**
   * Retrieves the latest case status of current case
   * @function
   * @returns {string} - Case status
   */
  const getCurrentCaseStatus = () => {
    if (caseId) {
      const current_case = case_details.cases.find((c) => c.case_id === caseId);
      const status = current_case?.status_code;
      return status;
    }
    return '';
  };

  /**
   * Check if case is gen2
   * @function
   * @returns {boolean} - True if is gen2 case
   */
  const isGen2Case = () => {
    const urlCase = case_details?.cases?.filter((c) => {
      return c.case_id === caseId && c.gen_2;
    });
    return urlCase?.length > 0;
  };

  /**
   * Checks if a doctor is enrolled in the 'provider_edit' program and if the case submission date is after the enrollment date.
   *
   * @returns {boolean} Returns true if the conditions are met; otherwise, false.
   */
  const checkEnrollment = () => {
    const isDoctorEnrolled = isEnrolled('provider_edit');

    if (!isDoctorEnrolled) {
      return false;
    }

    const enrollmentDate = getEnrollmentDate('provider_edit');
    const caseSubmissionDate = getCaseSubmissionDate();

    return caseSubmissionDate > enrollmentDate;
  };

  /**
   * Checks if the doctor is enrolled in a specific program.
   *
   * @param {string} program - The program to check enrollment for.
   * @returns {boolean} Returns true if enrolled; otherwise, false.
   */
  const isEnrolled = (program) => {
    return case_details.doctor?.program_enrollment?.includes(program);
  };

  /**
   * Retrieves the enrollment date for a specific program.
   *
   * @param {string} program - The program to retrieve enrollment date for.
   * @returns {Date} Returns the enrollment date as a Date object; otherwise, null.
   */
  const getEnrollmentDate = (program) => {
    return new Date(case_details.doctor?.program_enrollment_dates?.[program]);
  };

  /**
   * Retrieves the case submission date either from the specified case or the first case if caseId is not provided.
   *
   * @returns {Date} Returns the case submission date as a Date object.
   */
  const getCaseSubmissionDate = () => {
    let caseSubmissionDate;

    if (caseId) {
      const currentCase = getCaseById(caseId);
      caseSubmissionDate = new Date(currentCase?.submission_date);
    } else {
      caseSubmissionDate = new Date(case_details?.cases[0]?.submission_date);
    }

    return caseSubmissionDate;
  };

  /**
   * Retrieves a case by its ID from the case_details.
   *
   * @param {string} id - The ID of the case to retrieve.
   * @returns {Object|null} Returns the case object if found; otherwise, null.
   */
  const getCaseById = (id) => {
    return case_details.cases.find((c) => c.case_id === id);
  };
  /**
   * Retrieves the provider edit release date
   * @function
   * @returns {Date} - Release date of provider edit
   */
  const getProviderEditReleaseDate = async () => {
    try {
      setTokenHeader();
      const response = await axios.get(`/apiv3/keyval?key=${KEY_PROVIDER_EDIT_RELEASE_DATE}`);
      const date = response && response.data && response.data.data && response.data.data[0].value ? response.data.data[0].value : null;
      return date ? new Date(date) : false;
    } catch (e) {
      console.error('Error fetching provider edit release date', e);
      return false;
    }
  };

  /**
   * Determines if user has access to the activation btn
   * True if current case is waiting for doctor approval and doctor is enrolled in provider edit program
   * @function
   * @param {object} user_roles_and_permissions - Current user's roles/permissions
   * @returns {boolean} - True if user has access
   */
  const checkUserAccess = (user_roles_and_permissions) => {
    const { user_roles, user_id } = user_roles_and_permissions;
    const case_status = getCurrentCaseStatus();
    const is_doctor_approval_status = case_status === 'STATUS_DOCTOR_APPROVAL';
    const user_is_assigned_doctor = user_id === case_details?.doctor?.user_id;
    const enrolled_and_submitted_after_enrollment = checkEnrollment();
    const user_has_access = enrolled_and_submitted_after_enrollment && user_is_assigned_doctor;
    const DSO_supervisor_roles = ['DSO Doctor Override', 'DSO Admin', 'Administrator', 'Software'];
    const user_is_DSO_supervisor = _.intersection(DSO_supervisor_roles, user_roles).length > 0;
    const isGen2 = isGen2Case();

    return (isFullReleased || user_has_access || (user_is_DSO_supervisor && enrolled_and_submitted_after_enrollment)) && is_doctor_approval_status && isGen2;
  };

  /**
   * Display the wasm viewer activation btn
   * @function
   * @param {object} user_roles_and_permissions - Current user's roles/permissions
   * @returns {JSX} - JSX of the btn
   */
  const displayWasmBtn = (user_roles_and_permissions) => {
    const permissions = user_roles_and_permissions.permissions;
    const has_permissions = userHasPermission('ACTIVATE_PROVIDER_EDIT', permissions);
    const hasAccess = checkUserAccess(user_roles_and_permissions);
    if (has_permissions && hasAccess)
      return (
        <div className="redirect-btn">
          <Button theme="secondary" onClick={() => setIsApproveShown(true)}>
            New Viewer
          </Button>
        </div>
      );
  };

  /**
   * Activate WASM setup viewer, will deactivate the old viewer
   * @function
   */
  const handleConfirm = async () => {
    try {
      await ApiServiceInstance.tpsEnableWasm(caseId);
      setIsApproveShown(false);
      props.fetchCaseDetails(caseId);
      props.history.replace(`/smile_design/${caseId}`);
    } catch (e) {
      if (e.response?.status === 409) {
        setIsApproveShown(false);
        setIsSessionExpired(true);
      }
      console.error(`Error updating case ${caseId}`, e);
    }
  };

  return (
    <>
      <UserPermissionsContext.Consumer>{(user_roles_and_permissions) => displayWasmBtn(user_roles_and_permissions)}</UserPermissionsContext.Consumer>

      <Modal show_close_button show={isApproveShown} onCloseModal={() => setIsApproveShown(false)}>
        {isApproveShown && <NewViewerConfirmation caseId={caseId} onConfirm={handleConfirm} onCancel={() => setIsApproveShown(false)} />}
      </Modal>
      {isSessionExpired && <SessionExpiredModal />}
    </>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCaseDetails: fetchCaseDetails,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(WasmBtn);
