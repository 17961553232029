import React from 'react';
import { firstLetterCapital } from '../../common/functions';

/**
 * Contains the address display
 * @component
 * @alias DoctorAccountAddress
 */
export default function AccountAddress(props) {
  const active_address = props.account && props.account.shipping_addresses ? props.account.shipping_addresses.filter((address) => address.is_active) : [];

  return (
    <div className="account__section">
      <h3>Shipping Address</h3>
      {active_address.length > 0 ? (
        active_address.map((address, index) => {
          return (
            <div className="address" key={index}>
              <div className="emphasis address_line">
                {address.address_name ? firstLetterCapital(address.address_name) : firstLetterCapital(address.city) + ' Practice'}
              </div>
              <div className="address_line">{address.address_line_1 ? firstLetterCapital(address.address_line_1) : null}</div>
              <div className="address_line">{address.address_line_2 ? firstLetterCapital(address.address_line_2) : null}</div>
              <div className="address_line">{address.city ? firstLetterCapital(address.city) + ', ' + address.state + ' ' + address.zip : null}</div>
              <div className="address_line">{address.country ? firstLetterCapital(address.country) : null}</div>
            </div>
          );
        })
      ) : (
        <div>No address found</div>
      )}
      <div>*To update your shipping address, please contact support at (877) 258-8677</div>
    </div>
  );
}
