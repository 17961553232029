import Axios from 'axios';

//Internal Libs
import { textFieldCheck } from '../../../../common/functions';
import { ApiServiceInstance } from '../../../../common/api_service';

// Action Types
export const SET_EDIT_SMILE_DESIGN_TARGET_DATE = 'SET_EDIT_SMILE_DESIGN_TARGET_DATE';
export const SET_SMILE_DESIGN_TARGET_DATE = 'SET_SMILE_DESIGN_TARGET_DATE';
export const SET_EXPEDITE_DETAILS_SESSION_EXPIRED = 'SET_EXPEDITE_DETAILS_SESSION_EXPIRED';
export const SET_APPLIANCE_DESIGN_TARGET_DATE_INPUT = 'SET_APPLIANCE_DESIGN_TARGET_DATE_INPUT';
export const SET_PREFERRED_SHIPPING_INPUT = 'SET_PREFERRED_SHIPPING_INPUT';

// Action Creators
// -----------------------------------------------------------------------------

/**
 * Turns on edit mode for smile design target date
 * @function
 * @param {string} target_date - Smile design target date for case
 * @returns {object} - Action object with type
 */
export function editSmileDesignTargetDate(target_date) {
  return {
    type: SET_EDIT_SMILE_DESIGN_TARGET_DATE,
    smile_design_target_date_input: target_date,
    edit_smile_design_target_date: true,
  };
}

/**
 * Turns off edit mode for smile design target date
 * @function
 * @returns {object} - Action object with type
 */
export function cancelEditSmileDesignTargetDate() {
  return {
    type: SET_EDIT_SMILE_DESIGN_TARGET_DATE,
    edit_smile_design_target_date: false,
    smile_design_target_date_input: '',
  };
}

/**
 * Set smile design target date input
 * @function
 * @param {string} target_date - Target date input
 * @returns {object} - Action object with type
 */
export function setSmileDesignTargetDateInput(target_date) {
  return {
    type: SET_SMILE_DESIGN_TARGET_DATE,
    smile_design_target_date_input: textFieldCheck(target_date),
  };
}

/**
 * Handles event when user confirms update for smile design target date
 * @function
 * @param {string} id - Case or ireq id
 * @param {function} reload_information - Reload information function from case_details
 * @returns {object} - Action object with type
 */
export function onSaveSmileDesignTargetDate(id, reload_information) {
  return (dispatch, getState) => {
    const { smile_design_target_date_input } = getState().expediteDetailsReducer;
    const data = {
      smile_design_target_date: smile_design_target_date_input,
    };
    ApiServiceInstance.updateCase(id, data)
      .then(function (res) {
        reload_information();
        dispatch(cancelEditSmileDesignTargetDate());
      })
      .catch(function (err) {
        if (err.response.status === 409) {
          dispatch({
            type: SET_EXPEDITE_DETAILS_SESSION_EXPIRED,
            session_expired: true,
          });
        }
      });
  };
}

/**
 * Sets appliance design target date input
 * @function
 * @param {string} target_date - Appliance design target date
 * @returns {object} - Action object with type
 */
export function setApplianceDesignTargetDateInput(target_date) {
  return {
    type: SET_APPLIANCE_DESIGN_TARGET_DATE_INPUT,
    appliance_design_target_date_input: target_date,
  };
}

/**
 * Sets preferred shipping method input
 * @function
 * @param {string} preferred_shipping - Preferred shipping method
 * @returns {object} - Action object with type
 */
export function setPreferredShippingInput(preferred_shipping) {
  return {
    type: SET_PREFERRED_SHIPPING_INPUT,
    preferred_shipping_input: preferred_shipping,
  };
}

/**
 * Clears all inputs
 * @function
 * @returns {object} - Action object with type
 */
export function clearTargetDateInputs() {
  return (dispatch) => {
    dispatch(cancelEditSmileDesignTargetDate());
    dispatch(setApplianceDesignTargetDateInput(''));
    dispatch(setPreferredShippingInput(''));
  };
}
