import Axios from 'axios';

export const SET_DOCTOR_STATS = 'SET_DOCTOR_STATS';

export const SET_PROVIDER_TIER = 'SET_PROVIDER_TIER';
export const SET_PROVIDER_TIER_LOADING = 'SET_PROVIDER_TIER_LOADING';

export const FETCH_DOCTOR_INFO_START = 'FETCH_DOCTOR_INFO_START';
export const FETCH_DOCTOR_INFO_SUCCESS = 'FETCH_DOCTOR_INFO_SUCCESS';
export const FETCH_DOCTOR_INFO_ERROR = 'FETCH_DOCTOR_INFO_ERROR';

export const FETCH_ACTION_REQUIRED_START = 'FETCH_ACTION_REQUIRED_START';
export const FETCH_ACTION_REQUIRED_SUCCESS = 'FETCH_ACTION_REQUIRED_SUCCESS';
export const FETCH_ACTION_REQUIRED_ERROR = 'FETCH_ACTION_REQUIRED_ERROR';

export const FETCH_ACTIVITY_START = 'FETCH_ACTIVITY_START';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_ERROR = 'FETCH_ACTIVITY_ERROR';

export function fetchDoctorStats(doctor_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/doctorstats/${doctor_id}`)
      .then((res) => dispatch(setDoctorStats(res.data)))
      .catch((err) => console.log(err));
  };
}

function setDoctorStats(stats) {
  return {
    type: SET_DOCTOR_STATS,
    stats: stats,
  };
}

export function fetchDoctorInfo(doctor_id) {
  return (dispatch) => {
    dispatch(fetchDoctorInfoStart());
    Axios.get(`/apiV2/account_light/${doctor_id}`)
      .then((res) => dispatch(fetchDoctorInfoSuccess(res.data)))
      .catch((err) => dispatch(fetchDoctorInfoError()));
  };
}

export function fetchProviderTier(doctor_id) {
  return (dispatch) => {
    dispatch(setProviderTierLoading(true));
    Axios.get(`/apiv3/providertier/${doctor_id}`)
      .then((res) => dispatch(setProviderTier(res.data)))
      .catch((err) => dispatch(setProviderTierLoading(false)));
  };
}

function setProviderTier(info) {
  return {
    type: SET_PROVIDER_TIER,
    info: info,
  };
}

function setProviderTierLoading(loading) {
  return {
    type: SET_PROVIDER_TIER_LOADING,
    loading: loading,
  };
}

export function fetchActionRequired(doctor_id, offset = 0, total = 0) {
  return (dispatch) => {
    dispatch(fetchActionRequiredStart(doctor_id));
    const params = `?offset=${offset}&limit=10&total=${total}`;
    const is_reload = offset === 0;

    Axios.get(`/apiv3/actionrequired/${doctor_id}${params}`)
      .then((res) => dispatch(fetchActionRequiredSuccess(res.data, is_reload)))
      .catch((err) => dispatch(fetchActionRequiredError()));
  };
}

export function fetchActivity(doctor_id, offset = 0) {
  return (dispatch) => {
    dispatch(fetchActivityStart(doctor_id));
    const params = `?offset=${offset}&limit=10`;
    const is_reload = offset === 0;

    Axios.get(`/apiv3/activity/${doctor_id}${params}`)
      .then((res) => dispatch(fetchActivitySuccess(res.data, is_reload)))
      .catch((err) => dispatch(fetchActivityError()));
  };
}

function fetchDoctorInfoStart() {
  return { type: FETCH_DOCTOR_INFO_START };
}
function fetchDoctorInfoSuccess(data) {
  return { type: FETCH_DOCTOR_INFO_SUCCESS, data: data };
}
function fetchDoctorInfoError() {
  return { type: FETCH_DOCTOR_INFO_ERROR };
}

function fetchActivityStart(doctor_id) {
  return { type: FETCH_ACTIVITY_START, doctor_id: doctor_id };
}
function fetchActivitySuccess(data, reload) {
  return {
    type: FETCH_ACTIVITY_SUCCESS,
    data: data,
    reload: reload,
  };
}
function fetchActivityError() {
  return { type: FETCH_ACTIVITY_ERROR };
}

function fetchActionRequiredStart(doctor_id) {
  return { type: FETCH_ACTION_REQUIRED_START, doctor_id: doctor_id };
}
function fetchActionRequiredSuccess(data, reload) {
  return {
    type: FETCH_ACTION_REQUIRED_SUCCESS,
    data: data,
    reload: reload,
  };
}
function fetchActionRequiredError() {
  return { type: FETCH_ACTION_REQUIRED_ERROR };
}
