import {
  CLOSE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  OPEN_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  RESTART_SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_ERROR,
  SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_PENDING,
  SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_SUCCESS,
  TOGGLE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
} from '../../actions/bpp/feedback_rating_form';

const initialState = {
  show_feedback_rating_form_post_smile_design: false,
  submit_feedback_rating_form_post_smile_design_pending: false,
  submit_feedback_rating_form_post_smile_design_success: false,
  submit_feedback_rating_form_post_smile_design_error: null,
};

export function feedbackRatingFormReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN:
      return {
        ...state,
        show_feedback_rating_form_post_smile_design: false,
      };

    case OPEN_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN:
      return {
        ...state,
        show_feedback_rating_form_post_smile_design: true,
      };

    case TOGGLE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN:
      return {
        ...state,
        show_feedback_rating_form_post_smile_design: !state.show_feedback_rating_form_post_smile_design,
      };

    case SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_PENDING:
      return {
        ...state,
        submit_feedback_rating_form_post_smile_design_pending: true,
        submit_feedback_rating_form_post_smile_design_success: false,
        submit_feedback_rating_form_post_smile_design_error: null,
      };

    case SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_SUCCESS:
      return {
        ...state,
        submit_feedback_rating_form_post_smile_design_pending: false,
        submit_feedback_rating_form_post_smile_design_success: true,
        submit_feedback_rating_form_post_smile_design_error: null,
      };

    case SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_ERROR:
      return {
        ...state,
        submit_feedback_rating_form_post_smile_design_pending: false,
        submit_feedback_rating_form_post_smile_design_success: false,
        submit_feedback_rating_form_post_smile_design_error: action.error,
      };

    case RESTART_SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN:
      return initialState;

    default:
      return state;
  }
}

export const getShowFeedbackRatingFormPostSmileDesign = state => state.feedbackRatingFormReducer.show_feedback_rating_form_post_smile_design;
export const getSubmitFeedbackRatingFormPostSmileDesignPending = state => state.feedbackRatingFormReducer.submit_feedback_rating_form_post_smile_design_pending;
export const getSubmitFeedbackRatingFormPostSmileDesignSuccess = state => state.feedbackRatingFormReducer.submit_feedback_rating_form_post_smile_design_success;
export const getSubmitFeedbackRatingFormPostSmileDesignError = state => state.feedbackRatingFormReducer.submit_feedback_rating_form_post_smile_design_error;
