import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '../components/container/card_container';
import { getProviderTierInfo, hasProviderTier } from './dashboard_helpers';
import ProviderTierStatus from './provider_tier_status';

function ProviderTierProgress(props) {
  const { provider_tier, doctor_id } = props;

  useEffect(() => {
    window.$('[data-toggle="tooltip"]').tooltip({
      html: true,
      container: 'main',
    });
  }, [provider_tier]);

  if (!hasProviderTier(provider_tier, doctor_id)) {
    return null;
  }

  const { current, next, note } = getProviderTierInfo(props.provider_tier);
  const approvalsNeed = next.count - Number(provider_tier.case_approvals) > 0 ? next.count - Number(provider_tier.case_approvals) : 0;
  const value = (provider_tier.case_approvals / next.count) * 100;

  /**
   * Creates progress bar JSX element
   * @param {Object} current Dashboard Helper Current Object
   * @param {Object} next Dashboard Helper Next Object
   * @param {String} note Dashboard Helper Note
   * @returns {JSX} Progress bar Element
   */
  const displayProgressBar = (current, next, note) => {
    return (
      <div className="provider-tier-progress-bar">
        <progress className="gradient-progress" max="100" value={value || 3} />
        <div className="provider-tier-progress-bar__item" data-toggle="tooltip" title={note !== 'Maintain' ? current.tooltip : ''}>
          {current.logo && note !== 'Maintain' ? (
            <img className="provider-tier-logo--sm" src={current.logo} alt={current.name} />
          ) : (
            <div className="provider-tier-logo-placeholder"></div>
          )}
          <div>{note === 'Maintain' ? 0 : current.required}</div>
        </div>
        {current.rank_number > 2000 ? (
          <>
            <div className="provider-tier-progress-bar__item">
              <div className="progress-divider"></div>
            </div>
            <div className="provider-tier-progress-bar__item">
              <div className="progress-divider"></div>
            </div>
          </>
        ) : (
          <>
            <div className="provider-tier-progress-bar__item"></div>
            <div className="provider-tier-progress-bar__item"></div>
          </>
        )}
        <div className="provider-tier-progress-bar__item" data-toggle="tooltip" title={next.tooltip}>
          {next.logo && <img className="provider-tier-logo--sm" src={next.logo} alt={next.name} />}
          <div>{next.required}</div>
        </div>
      </div>
    );
  };

  return (
    <CardContainer className="provider-tier-full-progress pad-md">
      <>
        <ProviderTierStatus note={note} name={next.name} approvalsNeed={approvalsNeed} />
        <div className="progress-approval-count">
          {provider_tier.case_approvals}/{next.count} Approved Smile Designs
        </div>
        {displayProgressBar(current, next, note)}
      </>
    </CardContainer>
  );
}

ProviderTierProgress.propTypes = {
  doctor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  provider_tier: PropTypes.object.isRequired,
};

export default ProviderTierProgress;
