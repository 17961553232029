/**
 * File:pr_submission.js - Component to submit progress record/clinical questionaire  on IPP side.
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './pr_submission.scss';
// External Libs
import Axios from 'axios';
import { cloneDeep, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// Internal Components
import CsqData from '../../csq.json';
import NextButton from '../../components/button/bpp_next_button';
import BackButton from '../../components/button/bpp_back_button';
import Loader from '../../components/loader/loader';
import Modal from '../../components/modal/modal';
import NotFound from '../../doctor/404/not_found';
import Upload from './pr_upload';
import Csq from './csq';
import Thumbnail from '../../components/thumbnail/thumbnail';
import SubmitterLoader from './submitting_loader';
// Internal Functions
import { handleHttpRequestError } from '../../common/error';
import { setTokenHeader, configLightBox, convertSex, convertInputDate, modalClear, textFieldLimited, checkToken } from '../../common/functions';
import { getDoctorAssignedNameFromCaseDetails } from '../../common/dso';
import { getTempLink } from '../../common/dropbox';
import { ApiServiceInstance } from '../../common/api_service';
class PrSubmission extends Component {
  constructor(props) {
    super(props);
    const csq_data = cloneDeep(CsqData);
    this.state = {
      case_id: '',
      doctor_id: '',
      doctor_first_name: '',
      doctor_last_name: '',
      showCancelCase: false,
      loading: true,
      patient_first_name: '',
      patient_last_name: '',
      title: 'Progress Records',
      step: 'pr_csq',
      mode: 'csq_submit',
      total_steps: '3',
      current_step: '1',
      buttons: <NextButton position={'next'} buttonClass={'bpp-submit-next'} description={'Parts'} onButtonClick={this.onButtonClick} />,
      warning: false,
      error: false,
      error_type: '',
      csq_data: csq_data,
      //Uploads
      fileUpload: [],
      //Upload in progress
      files_uploading: [],
      file_location: '',
      upload_date: '',
      file_removing: false,
      remove_confirm: false,
      remove_file_data: [],
      remove_file: '',
      edit_comment: false,
      edit_status_commentedit_status_comment: '',
      status_comment: '',
      pr_exists: true,
    };

    this.goBack = this.goBack.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.validateUpdates = this.validateUpdates.bind(this);
    this.onEditStatusKeyPress = this.onEditStatusKeyPress.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    let csq_data = '';
    let mode = 'pr_submit';
    let title = 'Progress Records';
    if (this.props.progress_record.csq_data) {
      csq_data = JSON.parse(cloneDeep(this.props.progress_record.csq_data), (k, v) => (v === 'true' ? true : v === 'false' ? false : v));
      mode = 'csq_submit';
      title = 'Clinical Support Questionnaire';
    }
    let date_taken = convertInputDate(this.props.progress_record.date_taken);
    const that = this;
    const status_comment = this.props.status_comment;
    let case_id = this.props.case_id;
    let fileUpload = this.props.new_files;
    let step = this.state.step;
    let total_steps = this.state.total_steps;
    if (mode === 'pr_submit') {
      step = 'pr_upload';
      total_steps = '2';
    }
    ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        if (csq_data) {
          csq_data.case.doctor_name = getDoctorAssignedNameFromCaseDetails(res.data);
          csq_data.case.patient_name = res.data.patient.first_name + ' ' + res.data.patient.last_name;
          csq_data.case.sex = convertSex(res.data.patient.sex);
          csq_data.case.dob = res.data.patient.dob;
        }
        this.setState({
          case_id: case_id,
          mode: mode,
          title: title,
          step: step,
          total_steps: total_steps,
          loading: false,
          patient_first_name: res.data.patient.first_name,
          patient_last_name: res.data.patient.last_name,
          address: res.data.cases[0].shipping_address,
          csq_data: csq_data,
          fileUpload: fileUpload,
          upload_date: date_taken,
          status_comment: status_comment,
        });
      })
      .catch(function (err) {
        console.log(err);
        that.setState({
          loading: false,
          error: true,
        });
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    //Check if user is still logged in
    const isAllowed = checkToken();
    if (!isAllowed) {
      this.props.history.push('/');
    }
  }

  redirectUserToCaseDetail() {
    this.props.ReturnBack();
  }

  onEditClick = (event) => {
    this.setState({
      edit_comment: true,
      edit_status_comment: this.state.status_comment,
    });
  };
  onEditStatusDiscard = (event) => {
    this.setState({
      edit_comment: false,
    });
  };

  onEditStatusConfirm = (event) => {
    if (this.state.edit_status_comment) {
      this.setState({
        edit_comment: false,
        status_comment: this.state.edit_status_comment.trim(),
      });
    }
    //this.props.confirmStatuUpdate(this.state.edit_status_comment);
  };

  onTextChange = (event) => {
    this.setState({
      edit_status_comment: event.target.value,
    });
  };

  onBlur = (event) => {
    this.setState({
      edit_status_comment: event.target.value.trim(),
    });
  };
  onEditStatusKeyPress(event) {
    if (event.keyCode === 27) {
      let discard = document.getElementById('edit-discard');

      if (discard) {
        discard.click();
      }
    } else if (event.keyCode === 13 && this.state.edit_status_comment) {
      let confirm = document.getElementById('edit-confirm');
      if (confirm) {
        confirm.click();
      }
    }
  }

  getAnswerFromQuestionnaire = (questions, target_question, target_subquestion = '') => {
    let result = 'N/A';

    for (let i = 0; i < questions.length; i++) {
      if (questions[i].question_text === target_question) {
        result = target_subquestion ? this.getAnswerFromQuestionnaire(questions[i].multiple_question, target_subquestion) : questions[i].answer_text;
        break;
      }
    }

    return result;
  };

  onSubmitButtonClick = (event) => {
    if (this.validateUpload() && this.validateUpdates() && this.state.status_comment && !this.state.edit_comment) {
      const case_id = this.state.case_id;
      let data = '';
      const files = JSON.stringify(this.state.fileUpload);
      const date_taken = this.state.upload_date;
      const status_comment = this.state.status_comment;

      if (this.props.progress_record.csq_data) {
        const csq_data = this.state.csq_data;
        const appointment_date = this.getAnswerFromQuestionnaire(csq_data.questions, 'When is the next planned appointment?');
        const csq = JSON.stringify(this.state.csq_data);
        const record_type = 'CSQ';
        data = {
          case_id,
          csq_data,
          csq,
          date_taken,
          files,
          record_type,
          status_comment,
          appointment_date,
        };
      } else {
        const record_type = 'Progress Record';
        data = {
          case_id,
          date_taken,
          files,
          record_type,
          status_comment,
        };
      }
      let that = this;
      this.setState({
        step: 'in_progress',
        buttons: <div />,
      });

      Axios.post(`/apiV2/pr_update/${this.props.progress_record.progress_id}`, data)
        .then(function (res) {
          that.setState({
            step: 'thank_you',
          });
          // if (data.record_type === 'CSQ') {
          //   Axios.post(`/api/email/?slug=csq-submission&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${window.location.origin}&method=standard`);
          // } else if (data.record_type === 'Progress Record') {
          //   Axios.post(
          //     `/api/email/?slug=progress-records-submission&caseId=${removeCaseIdInitialNumber(case_id)}&provider=${window.location.origin}&method=standard`
          //   );
          // }
        })
        .catch(function (err) {
          that.setState({
            error: true,
          });
        });
      // this.props.history.push({ state: { refreshInfo: 'true' } });
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  getPosition() {
    const position = [{ name: 'pr_upload' }, { name: 'pr_csq' }, { name: 'pr_review' }];

    return position;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  hasOnePhoto = () => {
    if (this.state.fileUpload.length === 0) {
      return false;
    }
    return true;
  };

  isProgressRecordDeleted = () => {
    setTokenHeader();
    let that = this;
    const case_id = this.props.case_id;
    const progress_id = this.props.progress_record.progress_id;
    return ApiServiceInstance.fetchCaseDetails(case_id)
      .then((res) => {
        const pr = res.data.progress_record.filter(function (q) {
          return q.progress_id === parseInt(progress_id);
        });
        let exists = true;
        if (pr.length === 0) {
          exists = false;
        }
        that.setState({
          pr_exists: exists,
        });
      })
      .catch(function (err) {
        console.log(err);
        return false;
      });
  };
  validateUpdates = (event) => {
    let different_upload = false;
    let different_csq = false;
    let that = this;
    if (convertInputDate(this.props.progress_record.date_taken) !== this.state.upload_date) {
      different_upload = true;
    }
    if (!different_upload) {
      if (this.props.progress_record.files.length !== that.state.fileUpload.length) {
        different_upload = true;
      } else {
        let orig_file_name = [];
        let new_file_name = [];
        this.props.progress_record.files.forEach(function (file) {
          orig_file_name.push(file.original_filename);
        });
        this.state.fileUpload.forEach(function (file) {
          new_file_name.push(file.original_filename);
        });
        if (orig_file_name.sort().join('') !== new_file_name.sort().join('')) {
          different_upload = true;
        }
      }
    }
    if (this.state.mode === 'csq_submit') {
      if (
        !isEqual(
          JSON.parse(cloneDeep(this.props.progress_record.csq_data), (k, v) => (v === 'true' ? true : v === 'false' ? false : v)),
          this.state.csq_data
        )
      ) {
        different_csq = true;
      }
    }

    const deleted_pr = this.state.pr_exists;

    if (!deleted_pr) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        let warning_text = '<ul class="wizard-error-text"><li>Submission deleted.</li></ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Update Submission',
        });
      }
      return false;
    }

    if (different_upload || different_csq) {
      return true;
    } else {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        let warning_text = '<ul class="wizard-error-text"><li>No updates have been made.</li></ul>';
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Update Submission',
        });
      }
      return false;
    }
  };

  validateUpload = (event) => {
    let warning = document.querySelector('#warning-submit');
    let warning_text = '';
    if (this.hasOnePhoto() && this.state.upload_date && !this.state.file_removing && this.state.files_uploading.length === 0) {
      return true;
    } else {
      warning_text = '<ul class="wizard-error-text">';
      if (!this.state.upload_date) {
        warning_text = warning_text + '<li>Specify date taken</li>';
      }
      if (!this.hasOnePhoto()) {
        warning_text = warning_text + '<li>At least 1 file required</li>';
      }

      if (this.state.files_uploading.length > 0) {
        warning_text = warning_text + '<li>File upload in progress</li>';
      }

      if (this.state.file_removing) {
        warning_text = warning_text + '<li>File removal in progress</li>';
      }
      warning_text = warning_text + '</ul>';
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          warning: true,
          error_type: 'Upload Progress Records',
        });
      }

      return false;
    }
  };

  validateCsq = (event) => {
    //Fix: const vs. let
    let csq_data = this.state.csq_data;
    let warning_text = '';
    let error = false;
    let none_selected = false;
    let warning = document.querySelector('#warning-submit');
    csq_data.questions.forEach((csq) => {
      if ((csq.input_type === 'text_area' || csq.input_type === 'text' || csq.input_type === 'date') && !csq.optional && !csq.answer_text) {
        error = true;
        csq.error = true;
      } else if (csq.input_type === 'radio_button' && !csq.optional) {
        let rb_selected =
          csq.radio_button.filter(function (rb) {
            return rb.radio_checked === true;
          }).length > 0
            ? true
            : false;
        if (!rb_selected) {
          error = true;
          csq.error = true;
        }
        const selected_rb = csq.radio_button.filter(function (rb) {
          return rb.radio_checked === true;
        })[0];
        if (csq.toothSelector === true && csq.answer_text === 'Yes') {
          if (selected_rb.selectedTeeth.length === 0) {
            error = true;
            csq.error = true;
          }
        }
      } else if (csq.input_type === 'multiple_question' && !csq.optional) {
        let rb_none_selected =
          csq.multiple_question.filter(function (m) {
            return m.input_type === 'radio_button' && m.answer_text === 'None';
          }).length > 1
            ? true
            : false;
        if (rb_none_selected) {
          error = true;
          csq.error = true;
          none_selected = true;
        }
        csq.multiple_question.forEach((mq) => {
          if ((mq.input_type === 'text_area' || mq.input_type === 'text' || mq.input_type === 'date') && !mq.answer_text) {
            if (mq.related_question_id && mq.related_mq_id) {
              if (
                csq_data.questions
                  .filter(function (q) {
                    return q.id === parseInt(mq.related_question_id);
                  })[0]
                  .multiple_question.filter(function (m) {
                    return m.id === parseInt(mq.related_mq_id);
                  })[0].answer_text !== 'None'
              ) {
                error = true;
                csq.error = true;
                mq.error = true;
              }
            } else {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          } else if (mq.input_type === 'radio_button') {
            let rb_selected =
              mq.radio_button.filter(function (rb) {
                return rb.radio_checked === true;
              }).length > 0
                ? true
                : false;
            if (!rb_selected) {
              error = true;
              csq.error = true;
              mq.error = true;
            }
          }
        });
      }
    });
    if (error) {
      if (!warning_text) {
        if (none_selected) {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li><li>Patient must be wearing a Smartwire</li></ul>';
        } else {
          warning_text = '<ul class="wizard-error-text"><li>Incomplete Fields</li></ul>';
        }
      } else {
        warning_text = warning_text + '</ul>';
      }

      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = warning_text;
        this.setState({
          csq_data: csq_data,
          warning: true,
          error_type: 'Clinical Support Questionnaire',
        });
      }
      return false;
    }
    return true;
  };

  onhandleTeethClick(rb, id_string) {
    let csq_data = this.state.csq_data;
    const selected_teeth = rb.selectedTeeth;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].selected_teeth = selected_teeth;
    } else {
      if (
        csq_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].radio_button
      ) {
        csq_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .radio_button.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      } else if (
        csq_data.questions.filter(function (csq) {
          return csq.id === id;
        })[0].check_box
      ) {
        csq_data.questions
          .filter(function (csq) {
            return csq.id === id;
          })[0]
          .check_box.filter(function (rb) {
            return rb.id === rb_id;
          })[0].selected_teeth = selected_teeth;
      }
    }
    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  }

  onButtonClick = (event) => {
    event.preventDefault();
    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    if (this.state.step === 'pr_csq') {
      if (!this.validateCsq()) {
        return;
      } else {
        this.hide_warning();
      }
      this.setState({
        step: 'pr_upload',
        buttons: (
          <div>
            <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
            <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
          </div>
        ),
      });
    } else if (this.state.step === 'pr_upload') {
      if (event.currentTarget.dataset.position === 'next') {
        if (!this.validateUpload() || this.state.file_removing || this.state.files_uploading.length > 0) {
          return;
        } else {
          this.hide_warning();
        }
        this.isProgressRecordDeleted();
        this.setState({
          step: 'pr_review',
          buttons: (
            <div>
              <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
            </div>
          ),
        });
      } else {
        this.hide_warning();
        this.setState({
          step: 'pr_csq',
          buttons: (
            <div>
              <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
            </div>
          ),
        });
      }
    } else if (this.state.step === 'pr_review') {
      if (event.currentTarget.dataset.position === 'prev') {
        this.hide_warning();
        if (this.state.mode === 'csq_submit') {
          this.setState({
            step: 'pr_upload',
            buttons: (
              <div>
                <BackButton position={'prev'} description={prevStep} onButtonClick={this.onButtonClick} />
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          });
        } else {
          this.setState({
            step: 'pr_upload',
            buttons: (
              <div>
                {' '}
                <NextButton position={'next'} description={nextStep} onButtonClick={this.onButtonClick} />
              </div>
            ),
          });
        }
      }
    }
  };

  hide_warning = () => {
    let csq_data = this.state.csq_data;
    if (csq_data) {
      csq_data.questions.forEach((csq) => {
        csq.error = false;
        if (csq.input_type === 'multiple_question') {
          csq.multiple_question.forEach((mq) => {
            mq.error = false;
          });
        }
      });
    }
    this.setState({
      csq_data: csq_data,
      warning: false,
    });
  };

  goBack() {
    this.props.ReturnBack();
  }

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  onDeleteModalDismiss = () => {
    this.setState({
      remove_confirm: false,
    });
    modalClear();
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  onCheckboxClicked = (event) => {
    let id = parseInt(event.target.dataset.id);
    let request_reasons = this.state.request_reasons;
    let request_boolVal = request_reasons[id].booleanValue;
    request_reasons[id].booleanValue = !request_boolVal;
    this.setState({
      request_reasons: request_reasons,
    });
    this.hide_warning();
  };

  onCsqTextFocus = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let csq_data = this.state.csq_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = textFieldLimited(event.target.value.trim());
    } else {
      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = textFieldLimited(event.target.value.trim());
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  // onEditClick = event => {
  //   let action = event.target.dataset.action;
  //   this.setSonEditClicktate({
  //     step: action
  //   });
  // };

  onRemoveDraft = (event) => {
    this.props.CancelUpdate();
  };

  onCsqCbChange = (event) => {
    let csq_data = this.state.csq_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked
    ) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = false;
    } else {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .check_box.filter(function (rb) {
          return rb.id === rb_id;
        })[0].checked = true;
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  onCsqRbChange = (event) => {
    let csq_data = this.state.csq_data;
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let rb_id = 0;
    let mq_id = 0;
    id = parseInt(id_array[1]);
    if (id_array.length === 4) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
    } else if (id_array.length === 3) {
      rb_id = parseInt(id_array[2]);
    }
    if (mq_id !== 0) {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    } else {
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_checked = true;

      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].radio_label;

      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.forEach((rb) => {
          if (rb.id !== rb_id) {
            rb.radio_checked = false;
          }
        });
    }
    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  onUploadTextChange = (event) => {
    const upload_date = textFieldLimited(event.target.value);
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onUploadTextBlur = (event) => {
    const upload_date = textFieldLimited(event.target.value.trim());
    this.setState({
      upload_date: upload_date,
    });
    this.hide_warning();
  };

  onCsqTextChange = (event) => {
    const id_string = event.target.dataset.id;
    const id_array = id_string.split('-');
    let id = 0;
    let mq_id = 0;
    let rb_id = 0;
    let csq_data = this.state.csq_data;
    id = parseInt(id_array[1]);
    if (id_array.length === 3) {
      mq_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0].answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 4) {
      rb_id = parseInt(id_array[2]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].text_area.answer_text = textFieldLimited(event.target.value);
    } else if (id_array.length === 5) {
      mq_id = parseInt(id_array[2]);
      rb_id = parseInt(id_array[3]);
      csq_data.questions
        .filter(function (csq) {
          return csq.id === id;
        })[0]
        .multiple_question.filter(function (mq) {
          return mq.id === mq_id;
        })[0]
        .radio_button.filter(function (rb) {
          return rb.id === rb_id;
        })[0].date.answer_text = textFieldLimited(event.target.value);
    } else {
      csq_data.questions.filter(function (csq) {
        return csq.id === id;
      })[0].answer_text = textFieldLimited(event.target.value);
    }

    this.setState({
      csq_data: csq_data,
    });
    this.hide_warning();
  };

  files_upload = (filenames) => {
    this.setState({
      files_uploading: filenames,
    });
  };

  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    for (let index = 0; index < data.length; index++) {
      const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
      data[index]['file_url'] = file_url;
    }
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    this.setState({
      warning: false,
    });

    if (data && data[0].folder) {
      let uploadData = [];

      uploadData = this.state.fileUpload;
      uploadData = uploadData.concat(data);

      this.setState({
        fileUpload: uploadData,
        files_uploading: [],
      });
    }
  };

  onRemove = (event) => {
    // setTokenHeader();
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('/');
    let that = this;

    if (startingPath >= 0 && endPath >= 0) {
      let files = href.substring(startingPath, href.length);

      const data = {
        files,
      };

      that.setState({
        remove_confirm: true,
        remove_file_data: data,
        remove_file: files,
        // ,file_removing: true
      });
      //return true;

      // Axios.post(`/apiV2/pr_remove_upload`, data).then(function(res) {
      //   //Update state that removed incomplete file
      //   //

      //   if (res.data) {
      //     let uploadData = that.state.fileUpload;
      //     let newFileUpload = [];

      //     for (let i = 0; i < uploadData.length; i++) {
      //       if (uploadData[i].upload_data !== files.substring(1)) {
      //         newFileUpload.push(uploadData[i]);
      //       }
      //     }

      //     that.setState({
      //       fileUpload: newFileUpload,
      //       file_removing: false
      //     });
      //   }
      // });
    }
  };

  removeFile = (event) => {
    let that = this;
    let files = this.state.remove_file;
    setTokenHeader();
    event.preventDefault();
    that.setState({
      file_removing: true,
    });
    Axios.post(`/apiV2/pr_remove_upload`, that.state.remove_file_data)
      .then(function (res) {
        //Update state that removed incomplete file
        //

        if (res.data) {
          let uploadData = that.state.fileUpload;
          let newFileUpload = [];

          for (let i = 0; i < uploadData.length; i++) {
            if (uploadData[i].upload_data !== files.substring(1)) {
              newFileUpload.push(uploadData[i]);
            }
          }

          that.setState({
            fileUpload: newFileUpload,
            remove_confirm: false,
            file_removing: false,
          });
        }
      })
      .catch(function (err) {
        let uploadData = that.state.fileUpload;
        let newFileUpload = [];

        for (let i = 0; i < uploadData.length; i++) {
          if (uploadData[i].upload_data !== files.substring(1)) {
            newFileUpload.push(uploadData[i]);
          }
        }
        that.setState({
          fileUpload: newFileUpload,
          remove_confirm: false,
          file_removing: false,
        });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="page-tab-content">
          <Loader windowSize="ir" />
        </div>
      );
    } else if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else {
      return (
        <div className="page-tab-content  bpp-ir-submission">
          <Helmet>
            <title>{this.state.title} Submission | InBrace Smile Design Studio™</title>
          </Helmet>
          <div className="row">
            <div className="col-lg-6" />
            <div className="col-lg-6">
              <div className="pull-right" />
            </div>
          </div>

          {/* <div className="page__heading page__heading--long">
            <div className="page__name page__name--default">
              {' '}
              {this.state.title} - {this.state.patient_first_name} {this.state.patient_last_name}
            </div>
          </div> */}
          <div className="">
            <div className="">
              {this.state.step === 'pr_upload' ? (
                <Upload
                  id={this.props.id}
                  goBack={this.onRemoveDraft}
                  photoUpload={this.state.fileUpload}
                  onUpload={this.onUpload}
                  onRemove={this.onRemove}
                  upload_state={this.files_upload}
                  upload_content={this.state.files_uploading}
                  show_warning={this.show_warning}
                  hide_warning={this.hide_warning}
                  location={this.state.file_location}
                  upload_date={this.state.upload_date}
                  onTextChange={this.onUploadTextChange}
                  onBlur={this.onUploadTextBlur}
                  error={this.state.warning}
                  mode="update"
                />
              ) : null}
              {this.state.step === 'pr_csq' ? (
                <Csq
                  csq_data={this.state.csq_data}
                  onCheckboxClicked={this.onCheckboxClicked}
                  onTextChange={this.onCsqTextChange}
                  onRbChange={this.onCsqRbChange}
                  onCbChange={this.onCsqCbChange}
                  onFocus={this.onCsqTextFocus}
                  error={this.state.warning}
                  onhandleTeethClick={(rb, id_string) => {
                    this.onhandleTeethClick(rb, id_string);
                  }}
                />
              ) : null}
              {this.state.step === 'thank_you' ? (
                <div className="loader-container-2">
                  <div className="bpp-loader-header">
                    <p className="text-normal">
                      You have successfully updated this {this.props.progress_record.csq_data ? 'Clinical Support Questionnaire' : 'Progress Record'}
                    </p>
                    <button className="btn btn-light" onClick={this.redirectUserToCaseDetail}>
                      Go to {this.props.progress_record.csq_data ? 'Clinical Support Questionnaire' : 'Progress Record'} Details
                    </button>
                  </div>
                </div>
              ) : null}
              {this.state.step === 'in_progress' ? <SubmitterLoader /> : null}
              {this.state.step === 'pr_review' ? (
                <div className="bpp-portal-view">
                  <div className="ir-submission-heading">
                    {this.props.history &&
                    this.props.history.location &&
                    this.props.history.location.pathname &&
                    this.props.history.location.pathname.includes('csqsubmit')
                      ? 'Update Clinical Support Questionnaire - Step 3 of 3'
                      : 'Update Progress Records - Step 2 of 2'}
                  </div>
                  <div>
                    <span className="bpp-pr-upload-date-label">Click "Update" to complete the update process</span>
                    <div className="extra-padding-headline" />
                  </div>
                  <Thumbnail files={this.state.fileUpload} images={configLightBox(this.state.fileUpload)} theme="dark" multinail={`_${this.state.case_id}`} />

                  {this.props.mode === 'update' && this.state.status_comment ? (
                    <div>
                      <div className="bpp-review__line-divison" />
                      <div className="review-headers review-heading bold-text">
                        <h4>Reason for Update:</h4>
                      </div>
                      <div className="bpp-heading-edit-button" data-action="update_reason" onClick={this.onEditClick}>
                        Edit
                      </div>
                      {this.state.edit_comment ? (
                        <div>
                          <textarea
                            className={!this.state.edit_status_comment ? 'form-control bpp-pr-reason-comment-warning' : 'form-control bpp-item-update-comment'}
                            data-id="6"
                            rows="2"
                            value={this.state.edit_status_comment}
                            onChange={this.onTextChange}
                            onBlur={this.onBlur}
                            onKeyDown={this.onEditStatusKeyPress}
                          />
                          <div className="ir-profile-edit">
                            <div
                              id="edit-confirm"
                              className="btn btn-light btn-next"
                              onClick={this.onEditStatusConfirm}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Save"
                            >
                              <i className="fa fa-check" aria-hidden="true" />
                            </div>
                            <div
                              id="edit-discard"
                              className="btn btn-light btn-next"
                              onClick={this.onEditStatusDiscard}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Cancel"
                            >
                              <i className="fa fa-times" aria-hidden="true" />
                            </div>
                            {this.state.name_error ? <span className="name-warning-text">Please enter valid comment</span> : null}
                          </div>
                        </div>
                      ) : (
                        <div>{this.state.status_comment}</div>
                      )}
                    </div>
                  ) : null}

                  <button
                    className="btn btn-light btn-bpp-pr"
                    type="button"
                    onClick={this.onSubmitButtonClick}
                    data-case_id={this.state.case_id}
                    disabled={this.state.edit_comment}
                  >
                    Update
                  </button>
                </div>
              ) : null}
            </div>
            {this.state.hide ? null : this.state.buttons}
            <div id="wizard-warning" className={this.state.warning ? 'wizard-error noselect' : 'wizard-error wizard-hidden'}>
              <div className="wizard-error-title">
                <span id="wizard-heading" className="wizard-error-title-main">
                  {this.state.error_type}
                </span>{' '}
                <i className="fa fa-times pull-right pointer" aria-hidden="true" onClick={this.removeWizardErrorMsg} />
              </div>
              <div id="warning-submit">Warning</div>
            </div>
            {this.state.step !== 'in_progress' && this.state.step !== 'thank_you' ? (
              <div>
                <span className="bpp-ir-cancel-text bpp-ir-cancel-text-icon" onClick={this.onModalShow}>
                  <i className="fa fa-times" aria-hidden="true" />
                </span>
                <span className="bpp-ir-cancel-text" onClick={this.onModalShow}>
                  Cancel
                </span>
              </div>
            ) : null}

            {this.state.remove_confirm === true ? (
              <Modal
                preset="decision"
                header_text="Delete"
                message_text={
                  <span>
                    Are you sure you want to delete this file?
                    <br />
                    You will not be able to undo this action if you proceed.
                  </span>
                }
                close_btn_text="Cancel"
                confirm_btn_text="Delete File"
                onCloseButtonClick={this.onDeleteModalDismiss}
                onConfirmButtonClick={this.removeFile}
                theme="bpp"
              />
            ) : null}
            {this.state.showCancelCase === true ? (
              <Modal
                preset="decision"
                header_text="Cancel"
                message_text={
                  <span>
                    Are you sure you want to cancel this process?
                    <br />
                    You will not be able to undo this action if you proceed.
                  </span>
                }
                close_btn_text="Do Not Cancel"
                confirm_btn_text="Cancel Request"
                onCloseButtonClick={this.onModalDismiss}
                onConfirmButtonClick={this.onRemoveDraft}
                theme="bpp"
              />
            ) : null}
          </div>
        </div>
      );
    }
  }
}
export default withRouter(PrSubmission);
