// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

// Internal Components
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import CustomerAgreement from '../../doctor/customer_agreement/customer_agreement';
import Error from '../../doctor/404/not_found';
import ErrorMessage from '../components/container/error_message';
import PatientInfoFields from '../../doctor/case_submission/patient_info_fields';
import Photos from '../../doctor/case_submission/photos';
import Scans from '../../doctor/case_submission/scans';
import ShippingInfo from '../../doctor/case_submission/shipping_info';
import SubmitterLoader from '../../doctor/case_submission/submitting_loader';
import Dropdown from '../../doctor/components/inputs/dropdown';

// Internal Functions
import { patientChartTextConstraint, validDOB, removeEmoji } from '../../common/functions';
import { getDoctorsIdFromRoute } from '../../common/route';
import { handleHttpRequestError } from '../../common/error';
import { scrollToTop } from '../../common/scroll';
import { getTempLink } from '../../common/dropbox';

// Modals
import EmptyFileErrorModal from '../../components/modal/empty_file';

class SmileSimulationSubmission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      doctor_id: '',
      doctor_account_link_id: '',
      doctor_role: '',
      doctor_addresses: [],
      dso_doctors: [],
      smile_doctors: [],
      assigned_doctor: null,
      smile_id: '',
      smile_first_name: '',
      smile_last_name: '',
      smile_dob: '',
      smile_sex: 'U',
      smile_ref: '',
      smile_location: '',
      smile_correction: 'Yes',
      smile_spaces: 'Yes',
      smile_diastema: 'Yes',
      smile_special_instructions: '',
      smile_photos: [],
      smile_photos_uploading: [],
      smile_scans: [],
      smile_scans_uploading: [],
      smile_error: [],
      status: 'loading',
      terms: false,
      agreement: false,
      showEmptyFileModal: false,
    };
  }
  componentDidMount() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();

    Axios.get(`/apiV2/agreement`).catch(function () {
      that.setState({
        terms: true,
      });
    });

    Axios.get(`/apiv3/doctor/${doctor_id}/profile`)
      .then(function (res) {
        let state = {
          doctor_id: doctor_id,
          doctor_account_link_id: res.data.doctor_profile[0].account_link_id,
          doctor_role: res.data.doctor_profile[0].role,
          assigned_doctor: res.data.doctor_profile[0].role.includes('Doctor') ? doctor_id : null,
          agreement: res.data.doctor_profile[0].agreement,
        };
        return state;
      })
      .then(function (local_state) {
        if (local_state.doctor_role === 'DSO' || local_state.doctor_role === 'DSO_Doctor_Override') {
          Axios.get(`/apiv3/doctor/${doctor_id}/dso`).then((res) => {
            local_state.dso_doctors = res.data.dso_doctors;
            that.setState({ dso_doctors: res.data.dso_doctors });
            local_state.smile_doctors = res.data.smile_doctors.filter((doctor) => {
              return doctor.user__is_active === true;
            });
            that.getSmileSimulationTemp(local_state, doctor_id);
          });
        } else {
          that.getSmileSimulationTemp(local_state, doctor_id);
        }
      });
  }

  /**
   * Smile Simulation scan temp api
   * @function
   * @param {object} local_state - State object
   * @param {number} doctor_id - Doctor id
   */
  getSmileSimulationTemp = (local_state, doctor_id) => {
    const that = this;
    Axios.post(`/apiv3/smilesimulation/${doctor_id}/submission/temp`)
      .then(function (ss_res) {
        const address__doctor_id = `?id=${local_state.doctor_role.includes('DSO') ? local_state.doctor_account_link_id : local_state.doctor_id}`;
        Axios.get(`/api/practiceIdAddress/${address__doctor_id}`).then(function (res) {
          if (res.data.data && res.data.data.length > 0) {
            local_state.doctor_addresses = res.data.data.filter((address) => {
              return address.current_ind;
            });
            local_state.smile_location = local_state.doctor_addresses && local_state.doctor_addresses.length > 0 ? local_state.doctor_addresses[0].id : '';
            local_state.status = 'ready';
            local_state.smile_id = ss_res.data.smile_id;
            that.updateRouteAndState(local_state.smile_id, local_state);
          }
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  updateRouteAndState = (smile_id, local_state) => {
    let pathname = this.props.history.location.pathname.match(/\/smile\/submission(.+)*/);
    let pathname_update = '';

    if (pathname && pathname[0]) {
      let path = this.props.history.location.pathname;
      if (pathname[1]) {
        path = path.replace(pathname[1], '');
      }

      pathname_update = `/smile/submission/${smile_id}`;
      const new_history = path.replace('/smile/submission', pathname_update);
      this.props.history.replace(new_history);
    }

    this.setState(local_state);
  };
  /**
   * On Change Event for all input on this page
   * @function
   */
  onChange = (type, event) => {
    let state = _.cloneDeep(this.state);

    switch (type) {
      case 'smile_first_name':
      case 'smile_last_name':
      case 'smile_ref':
        state[type] = patientChartTextConstraint(event.target.value);
        break;
      case 'smile_dob':
      case 'smile_sex':
        state[type] = event.target.value;
        break;
      case 'smile_location':
        state[type] = parseInt(event.target.dataset.id);
        break;
      default:
        break;
    }

    this.setState(state);
  };
  /**
   * On Focus Event for all input on this page
   * @function
   */
  onFocus = (e) => {
    let ids = [
      '#patientFirstName',
      '#patientFirstNameLabel',
      '#patientLastName',
      '#patientLastNameLabel',
      '#patientDOB',
      '#patientDOBLabel',
      '#patientSex',
      '#patientRef',
      '#patientRefLabel',
      '#photoLabel',
      '#scanLabel',
      '#assignedDoctor',
      '#assignedDoctorLabel',
    ];

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }
    });

    this.setState({
      smile_error: [],
    });
  };
  /**
   * Determines if there are any errors and saves it to a list
   * @function
   */
  hasError = () => {
    let error_list = [];
    for (const [key, value] of Object.entries(this.state)) {
      switch (key) {
        case 'smile_first_name':
        case 'smile_last_name':
        case 'smile_sex':
        case 'smile_ref':
          if (value === '') {
            error_list.push(key);
          }
          break;
        case 'smile_dob':
          if (value === '') {
            error_list.push('smile_dob_empty');
          } else if (!validDOB(value)) {
            error_list.push(key);
          }
          break;

        case 'smile_photos':
          if (value.length === 0) {
            error_list.push(key);
          }
          break;

        case 'smile_scans':
          if (value.length !== 2) {
            error_list.push(key);
          }

          break;

        case 'smile_photos_uploading':
        case 'smile_scans_uploading':
          if (value.length !== 0) {
            error_list.push(key);
          }
          break;

        case 'assigned_doctor':
          if (!value && (this.state.doctor_role === 'DSO' || this.state.doctor_role === 'DSO_Doctor_Override') && this.state.smile_doctors.length > 0) {
            error_list.push(key);
          }
          break;

        default:
          break;
      }
    }
    return error_list;
  };
  /**
   * Display the errors
   * @function
   */
  displayError = (error_list) => {
    let ids = [];

    error_list.forEach((type) => {
      switch (type) {
        case 'smile_first_name':
          ids.push('#patientFirstName');
          ids.push('#patientFirstNameLabel');
          break;

        case 'smile_last_name':
          ids.push('#patientLastName');
          ids.push('#patientLastNameLabel');
          break;

        case 'smile_dob':
        case 'smile_dob_empty':
          ids.push('#patientDOB');
          ids.push('#patientDOBLabel');
          break;

        case 'smile_sex':
          ids.push('#patientSex');
          ids.push('#patientSexLabel');
          break;

        case 'smile_ref':
          ids.push('#patientRef');
          ids.push('#patientRefLabel');
          break;

        case 'smile_photos':
        case 'smile_photos_uploading':
          ids.push('#photoLabel');
          break;

        case 'smile_scans':
        case 'smile_scans_uploading':
          ids.push('#scanLabel');
          break;

        case 'duplicate_file_name':
          ids.push('#photoLabel');
          break;

        case 'assigned_doctor':
          ids.push('#assignedDoctor');
          ids.push('#assignedDoctorLabel');
          break;

        default:
          break;
      }

      return null;
    });

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element && (id.indexOf('Label') > 0 || id.indexOf('patientSex') > 0)) {
        element.classList.add('warning-text');
      } else if (element) {
        element.classList.add('warning-border');
      }
    });
  };
  /**
   * On Focus Event for all input on this page
   * @function
   */
  onCreate = () => {
    let that = this;
    let smile_error = this.hasError();

    if (smile_error.length > 0) {
      this.setState({ smile_error: smile_error });
      this.displayError(smile_error);
      return;
    }

    this.setState(
      { status: 'submit' },
      () => {
        Axios.put(`/apiv3/smilesimulation/${that.state.doctor_id}/submission/submit`, that.state)
          .then(function (res) {
            that.setState({ status: 'complete' });
            window.scrollTo({ top: 0, behavior: 'smooth' });
          })
          .catch(function (err) {
            console.log(err);
            that.setState({ status: 'error' });
          });
      },
      scrollToTop
    );
  };
  /**
   * Returns the title of smile simulation display
   * @function
   * @return {String} The title of smile simulation submission
   */
  displayTitle() {
    if (this.state.smile_first_name && this.state.smile_last_name) {
      return `Request Smile Simulation - ${this.state.smile_first_name} ${this.state.smile_last_name}`;
    } else {
      return 'Request Smile Simulation';
    }
  }
  /**
   * Returns the patient info for the smile simulation section
   * @function
   * @return {JSX} Returns the Patient Info fields to be filled out
   */
  displayPersonalDetails() {
    return (
      <PatientInfoFields
        sidebyside={'true'}
        onInputChange_firstName={(e) => this.onChange('smile_first_name', e)}
        onInputChange_lastName={(e) => this.onChange('smile_last_name', e)}
        onInputChange_dob={(e) => this.onChange('smile_dob', e)}
        onInputChange_sex={(e) => this.onChange('smile_sex', e)}
        onInputChange_patientRef={(e) => this.onChange('smile_ref', e)}
        patientFirstName={this.state.smile_first_name}
        patientLastName={this.state.smile_last_name}
        patientDOB={this.state.smile_dob}
        gender={this.state.smile_sex}
        patientRef={this.state.smile_ref}
        onFocus={this.onFocus}
        patientChartNoRequired={true}
      />
    );
  }

  /**
   * Display duplicate file name warning
   * @function
   * @param {object} data - file object
   */
  displaySmileErrors = () => {
    let smile_error = [''];
    this.setState({ smile_error: smile_error });
    this.displayError(smile_error);
  };
  /**
   * Show Empty File Modal
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Hide Empty File Modal
   * @param {Hide } event
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };
  /**
   * File upload processing
   * @function
   * @param {object} data - file object
   */
  onUpload = async (data) => {
    let { smile_photos, smile_scans } = { ...this.state };

    this.onFocus();

    for (let index = 0; index < data.length; index++) {
      if (data[index].folder === 'scans') {
        const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
        data[index]['file_url'] = file_url;
      }
    }

    if (data && data[0].folder && data[0].folder === 'photos') {
      smile_photos = smile_photos.concat(data);
      this.setState({
        smile_photos: smile_photos,
        smile_photos_uploading: [],
      });
    } else if (data && data[0].folder && (data[0].folder === 'upperscans' || data[0].folder === 'lowerscans' || data[0].folder === 'scans')) {
      smile_scans = smile_scans.concat(data);
      this.setState({
        smile_scans: smile_scans,
        smile_scans_uploading: [],
      });
    }
  };
  /**
   * On removing file
   * @function
   * @param {object} event - returns click event of hitting the remove button
   */
  onRemove = (event) => {
    event.preventDefault();
    this.onFocus();

    const tag = event.currentTarget.dataset.href.substring(1);
    let { smile_photos, smile_scans } = { ...this.state };

    smile_photos = smile_photos.filter((file) => {
      return file.upload_data !== tag;
    });

    smile_scans = smile_scans.filter((file) => {
      return file.upload_data !== tag;
    });

    this.setState({
      smile_photos: smile_photos,
      smile_scans: smile_scans,
    });
  };
  /**
   * Redirects user to the detail page
   * @function
   */
  onRedirectSmileDetail = () => {
    const smile_id = this.state.smile_id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(`submission/${smile_id}`, `detail/${smile_id}`);
    this.props.history.push(new_path);
  };
  /**
   * Redirects user to the detail page
   * @function
   */
  onRedirectSmileList = () => {
    const smile_id = this.state.smile_id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(`/smile/submission/${smile_id}`, `?filter=smile_simulation`);
    this.props.history.push(new_path);
    scrollToTop();
  };
  /**
   * On uploading update state
   * @function
   * @param {object} event - returns click event of hitting the remove button
   */
  smile_photos_uploading = (filenames) => {
    this.onFocus();

    this.setState({
      smile_photos_uploading: filenames,
    });
  };
  smile_scans_uploading = (filenames) => {
    this.onFocus();

    this.setState({
      smile_scans_uploading: filenames,
    });
  };
  /**
   * Returns the photos section
   * @function
   * @return {JSX} Returns the Photos and viewer
   */
  displayPhotos() {
    return (
      <Photos
        id={this.state.smile_id}
        photoUpload={this.state.smile_photos.concat(this.state.smile_scans)}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        location={'smilesimulation'}
        upload_state={this.smile_photos_uploading}
        upload_content={this.state.smile_photos_uploading}
        show_warning={this.displaySmileErrors}
        updateRecordState={this.updateRecordState}
      />
    );
  }
  /**
   * Returns the scan section
   * @function
   * @return {JSX} Returns the Scan and viewer
   */
  displayScans() {
    return (
      <Scans
        id={this.state.smile_id}
        scanUpload={this.state.smile_photos.concat(this.state.smile_scans)}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        onEmptyFileError={this.onEmptyFileError}
        location={'smilesimulation'}
        upload_state={this.smile_scans_uploading}
        upload_content={this.state.smile_scans_uploading}
        show_warning={this.displaySmileErrors}
        updateRecordState={this.updateRecordState}
      />
    );
  }
  /**
   * Returns the break border
   * @function
   * @return {JSX} Returns the break border
   */
  displaySectionBreak() {
    return <div className="row border--top"></div>;
  }
  /**
   * Returns the break border
   * @function
   * @return {JSX} Returns the break border
   */
  displayQuestions = () => {
    const that = this;
    const questions = [
      {
        id: 'anterior_posterior_correction',
        heading: 'Anterior-Posterior Correction',
        sub_groups: [
          {
            id: 'correction',
            sub_heading: '',
            state_name: 'smile_correction',
            value: this.state.smile_correction,
          },
        ],
      },
      {
        id: 'residual_spaces',
        heading: 'Residual Spaces',
        sub_groups: [
          {
            id: 'spaces',
            sub_heading: 'Maintain Space for Restoration (i.e Implant)',
            state_name: 'smile_spaces',
            value: this.state.smile_spaces,
          },
          {
            id: 'diastema',
            sub_heading: 'Maintain Diastema',
            state_name: 'smile_diastema',
            value: this.state.smile_diastema,
          },
        ],
      },
      {
        id: 'special_instructions',
        heading: 'Special Instructions',
        value: this.state.smile_special_instructions,
        state_name: 'smile_special_instructions',
        placeholder: 'Describe any special instructions or conditions',
      },
    ];

    const onChangeResponse = (e) => {
      const state_name = e.target.dataset.state_name;
      const value = removeEmoji(e.target.value);

      let new_state = {};
      new_state[state_name] = value;

      that.setState(new_state);
      return;
    };

    return (
      <div>
        <h3>Smile Simulation Preferences</h3>

        {questions.map((question, q_idx) => {
          const responses = ['Yes', 'No'];
          const sub_groups = question.sub_groups;

          if (question.id === 'special_instructions') {
            return (
              <div className="row" key={question.id}>
                <div className="col-md-6">
                  <div className="smile-radio-heading">{question.heading}</div>
                  <label className="form-check-label">
                    <textarea
                      className="form-control"
                      placeholder={question.placeholder ? question.placeholder : null}
                      rows={3}
                      maxLength={750}
                      name={question.id}
                      data-state_name={question.state_name}
                      onChange={onChangeResponse}
                      value={question.value}
                      style={{ width: '600px' }}
                    />
                  </label>
                </div>
              </div>
            );
          } else {
            return (
              <div className="row" key={question.id}>
                <div className="col-md-6">
                  <div className="smile-radio-heading">{question.heading}</div>
                  <div className="smile-radio-response">
                    {sub_groups.map((group) => {
                      return (
                        <React.Fragment key={group.id}>
                          <div className="smile-radio-subheading">{group.sub_heading}</div>
                          {responses.map((response, r_idx) => {
                            return (
                              <div key={response} className="ib-radio smile-radio-item">
                                <label className="form-check-label">
                                  <input
                                    id={group.id + r_idx}
                                    className="form-check-input"
                                    type="radio"
                                    name={group.id}
                                    data-state_name={group.state_name}
                                    onChange={onChangeResponse}
                                    value={response}
                                    checked={group.value === response}
                                  />{' '}
                                  {response}
                                </label>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  };

  /**
   * Determines whether doctor is eligible for smile simulation assignment
   * @return {bool}: Smile Assignable status
   */
  isSmileAssignable = (doctor) => {
    if (doctor.role !== 'DSO' && doctor.user__is_active && doctor.smile) {
      return true;
    }
    return false;
  };

  /**
   * Create properties for dropdown object
   * @returns Object {options, value}
   */
  getAssignedDoctorOptionValue() {
    const options = this.getAssignedDoctorOptions();

    const value = options.find((option) => parseInt(option.value) === parseInt(this.state.assigned_doctor));

    return { options, value };
  }

  /**
   * Retrieves assignable DSO doctor options
   * @function
   * @return {list} List of assignable DSO doctors
   */
  getAssignedDoctorOptions = () => {
    return this.state.dso_doctors
      .filter((doctor) => {
        return this.isSmileAssignable(doctor);
      })
      .map((doctor) => {
        return {
          label: doctor.user__first_name + ' ' + doctor.user__last_name,
          value: doctor.id,
        };
      });
  };

  /**
   * Returns the assigned doctor dropdown
   * @function
   * @return {JSX} doctor dropdown
   */
  displayAssignment() {
    const details = {
      main_heading: 'Assigned Doctor',
      sub_heading: 'Assigned Doctor ',
      tool_tip: 'If no doctor is found, create a doctor through the accounts page.',
    };
    const options = this.getAssignedDoctorOptions();

    const handleAssignmentSelect = (e) => {
      const value = options.find((option) => parseInt(option.value) === parseInt(e.value));
      this.setState({ assigned_doctor: value.value });
    };

    return (
      <div>
        <h3>{details.main_heading}</h3>
        <div className="row">
          <div className="col-md-6">
            <label id="assignedDoctorLabel">
              {details.sub_heading}
              <span data-toggle="tooltip" title={details.tool_tip}>
                <i className="fa fa-info-circle" />
              </span>
            </label>
            <Dropdown
              id="assignedDoctor"
              onFocus={this.onFocus}
              placeholder="Assign Doctor..."
              onChange={handleAssignmentSelect}
              {...this.getAssignedDoctorOptionValue()}
            />
          </div>
        </div>
      </div>
    );
  }
  /**
   * Returns the location addresses related to the account
   * @function
   * @return {JSX} Returns the locations
   */
  displayLocations() {
    return (
      <ShippingInfo
        location={'true'}
        onInputChange={(e) => this.onChange('smile_location', e)}
        address={this.state.smile_location}
        addresses={this.state.doctor_addresses}
        onFocus={this.onFocus}
      />
    );
  }

  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    const file_type = folder && folder === 'photos' ? 'smile_photos' : 'smile_scans';
    let new_file_upload = this.state[file_type];
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);
    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ [file_type]: new_file_upload });
    }
  };

  render() {
    if (this.state.terms) return <CustomerAgreement />;
    if (this.state.status === 'loading') return <CircleLoader fullscreen={true} />;
    if (this.state.status === 'error')
      return (
        <div className="fullview">
          <Error />
        </div>
      );
    const incomplete_patient_fields =
      _.intersection(['smile_first_name', 'smile_last_name', 'smile_dob_empty', 'smile_ref'], this.state.smile_error).length > 0;
    const invalid_dob = _.intersection(['smile_dob'], this.state.smile_error).length > 0 && this.state.smile_dob !== '';
    const incomplete_scans = _.intersection(['smile_scans'], this.state.smile_error).length > 0;
    const incomplete_photos = _.intersection(['smile_photos'], this.state.smile_error).length > 0;
    const upload_in_progress = _.intersection(['smile_scans_uploading', 'smile_photos_uploading'], this.state.smile_error).length > 0;
    const duplicate_file_name = _.intersection(['duplicate_file_name'], this.state.smile_error).length > 0;
    const incomplete_assigned_doctor = _.intersection(['assigned_doctor'], this.state.smile_error).length > 0;
    return (
      <div>
        <Helmet>
          <title>Smile Simulation Submission | InBrace Smile Design Studio™</title>
        </Helmet>

        <h1 className="content__header fs-exclude">{this.displayTitle()}</h1>

        {this.state.status === 'submit' ? (
          <CardContainer className="case-card-container mt-sm pad-lg card--smile">
            <SubmitterLoader />
          </CardContainer>
        ) : this.state.status === 'complete' ? (
          <CardContainer className="case-card-container mt-sm pad-lg card--smile">
            <div className="submission-loader-container">
              <p>Thank you for submitting your Smile Simulation request to InBrace.</p>
              <Button onClick={this.onRedirectSmileDetail}>Go to Smile Simulation Details</Button>
            </div>
          </CardContainer>
        ) : (
          <>
            <CardContainer className="case-card-container mt-sm pad-lg card--smile">
              <div className="row">
                <div className="col-md-12">
                  <h3>Personal Details</h3>
                </div>
                {this.displayPersonalDetails()}
              </div>
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayPhotos()}</div>
              </div>
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayScans()}</div>
              </div>
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayQuestions()}</div>
              </div>
              {this.state.doctor_role === 'DSO' || this.state.doctor_role === 'DSO_Doctor_Override' ? (
                <>
                  {this.displaySectionBreak()}
                  <div className="row">
                    <div className="col-md-12">{this.displayAssignment()}</div>
                  </div>
                </>
              ) : null}
              {this.displaySectionBreak()}
              <div className="row">
                <div className="col-md-12">{this.displayLocations()}</div>
              </div>
            </CardContainer>

            {this.state.showEmptyFileModal && <EmptyFileErrorModal theme="ipp" onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} />}

            <ErrorMessage
              className={this.state.smile_error.length > 0 ? 'error-message-container' : 'error-message-container hide'}
              title="Incomplete Fields"
              onClose={this.onFocus}
            >
              <div className="warning-display">
                <ul className="wizard-error-text">
                  {incomplete_patient_fields ? <li>Patient Fields</li> : null}
                  {invalid_dob ? <li>Invalid Date</li> : null}
                  {incomplete_photos ? <li>Photos</li> : null}
                  {incomplete_scans ? <li>Toothprints</li> : null}
                  {upload_in_progress ? <li>Upload In Progress</li> : null}
                  {duplicate_file_name ? <li>Duplicate File Name Found</li> : null}
                  {incomplete_assigned_doctor ? <li>Assigned Doctor</li> : null}
                </ul>
              </div>
              <div id="warning-submit" />
            </ErrorMessage>

            <div className="case-form-controls">
              <Button data-position={'next'} onClick={this.onCreate} disabled={!this.state.agreement}>
                Submit
              </Button>
              <Button theme="revise" onClick={this.onRedirectSmileList} data-redirect="true">
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(SmileSimulationSubmission);
