import moment from 'moment';
import React from 'react';

import TextBox from '../components/inputs/text_box';

import { firstLetterCapital } from '../../common/functions';

/**
 * Displays the PatientInfo for Submissions
 * @component
 * @alias DoctorPatientInfoFields
 */
export default function PatientInfoFields(props) {
  const onInputBlur_FirstName = (event) => {
    event.target.value = firstLetterCapital(event.target.value)
      .replace(/[^a-z\d\s-]+/gi, '')
      .replace(/\s\s+/g, ' ')
      .trimStart()
      .trimEnd();
    props.onInputChange_firstName(event);
  };

  const onInputBlur_LastName = (event) => {
    event.target.value = firstLetterCapital(event.target.value)
      .replace(/[^a-z\d\s-]+/gi, '')
      .replace(/\s\s+/g, ' ')
      .trimStart()
      .trimEnd();
    props.onInputChange_lastName(event);
  };

  const onInputBlur_patientRef = (event) => {
    event.target.value = event.target.value.trimStart().trimEnd();
    props.onInputChange_patientRef(event);
  };

  const sidebyside = props.sidebyside;

  return (
    <>
      <div className={sidebyside ? 'col-md-6' : 'form-group'}>
        <label className="emphasis" htmlFor="patientFirstName" id="patientFirstNameLabel">
          First Name*
        </label>
        <div className="inline-input">
          <TextBox
            id="patientFirstName"
            type="text"
            className="form-control patient-field"
            onChange={props.onInputChange_firstName}
            value={props.patientFirstName}
            onFocus={props.onFocus}
            onBlur={onInputBlur_FirstName}
            maxLength="30"
            disabled={props.disabledEdit}
          />
        </div>
      </div>

      <div className={sidebyside ? 'col-md-6' : 'form-group'}>
        <label className="emphasis" htmlFor="patientLastName" id="patientLastNameLabel">
          Last Name*
        </label>
        <div className="inline-input">
          <TextBox
            id="patientLastName"
            type="text"
            className="form-control patient-field"
            onChange={props.onInputChange_lastName}
            value={props.patientLastName}
            onFocus={props.onFocus}
            onBlur={onInputBlur_LastName}
            maxLength="30"
            disabled={props.disabledEdit}
          />
        </div>
      </div>

      <div className={sidebyside ? 'col-md-6' : 'form-group'}>
        <label className="emphasis" htmlFor="patientDOB" id="patientDOBLabel">
          Date of Birth*
        </label>
        <div className="inline-input">
          <TextBox
            id="patientDOB"
            type="date"
            className="form-control patient-field"
            placeholder="Date of Birth:"
            onChange={props.onInputChange_dob}
            defaultValue={props.patientDOB}
            onFocus={props.onFocus}
            max={moment().format('YYYY-MM-DD')}
            min={moment('1900-01-01').format('YYYY-MM-DD')}
            disabled={props.disabledEdit}
          />
        </div>
      </div>

      <div className={sidebyside ? 'col-md-6' : 'form-group'}>
        <label className="emphasis" id="patientSex" htmlFor="sex">
          Sex
        </label>
        <div className="inline-input">
          <span className="radio-spacing">
            <label>
              <TextBox
                type="radio"
                name="sex"
                value="F"
                onChange={props.onInputChange_sex}
                checked={props.gender === 'F'}
                onFocus={props.onFocus}
                disabled={props.disabledEdit}
              />
              Female
            </label>
          </span>
          <span className="radio-spacing">
            <label>
              <TextBox
                type="radio"
                name="sex"
                value="M"
                onChange={props.onInputChange_sex}
                checked={props.gender === 'M'}
                onFocus={props.onFocus}
                disabled={props.disabledEdit}
              />
              Male
            </label>
          </span>
          <span className="radio-spacing">
            <label>
              <TextBox
                type="radio"
                name="sex"
                value="N"
                onChange={props.onInputChange_sex}
                checked={props.gender === 'N'}
                onFocus={props.onFocus}
                disabled={props.disabledEdit}
              />
              Non-Binary
            </label>
          </span>
          <span className="radio-spacing">
            <label>
              <TextBox
                type="radio"
                name="sex"
                value="U"
                onChange={props.onInputChange_sex}
                checked={props.gender === 'U'}
                onFocus={props.onFocus}
                disabled={props.disabledEdit}
              />
              Undisclosed
            </label>
          </span>
        </div>
      </div>
      <div className={sidebyside ? 'col-md-6' : 'form-group'}>
        <label className="emphasis" htmlFor="patientRef" id="patientRefLabel">
          {`Patient Chart No.${props.patientChartNoRequired ? '*' : ''} `}
          <span data-toggle="tooltip" title="This field is for your own reference only. It will not be used by InBrace.">
            <i className="fa fa-info-circle" />
          </span>
        </label>
        <div className="inline-input">
          <TextBox
            id="patientRef"
            type="text"
            className="form-control patient-field"
            onChange={props.onInputChange_patientRef}
            value={props.patientRef}
            onFocus={props.onFocus}
            onBlur={onInputBlur_patientRef}
            maxLength="30"
            disabled={props.disabledEdit}
            placeholder={props.patientChartNoRequired ? '' : 'Optional'}
          />
        </div>
      </div>
    </>
  );
}
