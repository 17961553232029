import React, { Component } from 'react';
import ProfilePicture from './profile_picture';

/**
 * Shows basic user details with profile picture in account settings page on IPP
 *
 * @component
 * @alias DoctorUserDetails
 * @category IPP
 */
class UserDetails extends Component {
  render() {
    return (
      <div className="account__data">
        <ProfilePicture edit_picture={this.props.edit_picture ? (this.props.edit_picture) : true} {...this.props}></ProfilePicture>
        <div className="account__info">
          <h3>{this.props.name}</h3>
          <div className="account__email fs-exclude">{this.props.loginEmail}</div>
          {this.props.displayAccountEnabled ? this.props.displayAccountEnabled : null}
        </div>
      </div>
    );
  }
}

export default UserDetails;
