import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';

const zoomBounds = {
  minZoom: 0.5,
  maxZoom: 10.0,
};
const zoomSpeed = 1.5; // default is 1

/**
 * Creates the controls to the camera
 * @function
 * @param {THREE.Camera} camera - The camera
 * @param {Canvas} canvas - The canvas
 * @return {THREE.OrbitControls} The controls
 */
function createControls(camera, canvas) {
  const controls = new OrbitControls(camera, canvas);
  initControls(controls);
  return controls;
}

/**
 * Initialize the controls with properties
 * @function
 * @param {THREE.OrbitControls} controls - The controls
 */
function initControls(controls) {
  controls.minZoom = zoomBounds.minZoom;
  controls.maxZoom = zoomBounds.maxZoom;
  controls.enableKeys = false;
  controls.screenSpacePanning = true;
  controls.mouseButtons = {
    LEFT: THREE.MOUSE.LEFT,
    RIGHT: THREE.MOUSE.RIGHT,
  };
  controls.zoomSpeed = zoomSpeed;
}

export { createControls };
