import React from 'react';
import Logo from './logo';
import SmilePatientInfo from './smile_patient_info';
import MenuAccordianBtn from '../setup_viewer/components/panels/menu/menu_accordian_btn';

/**
 * The container of the side bar known as the panel menu
 * @component
 * @alias SmilePanelMenu
 */
export default function SmilePanelMenu(props) {
  return (
    <div className={props.collapse ? 'panel-menu--dark collapse' : 'panel-menu--dark'}>
      <div className="panel-menu-surrounding">
        <Logo className="panel-menu-logo" collapse={props.collapse} onClick={props.onLogoClick} />
        <SmilePatientInfo collapse={props.collapse} smile_details={props.smile_details} />
      </div>
      <MenuAccordianBtn collapse={props.collapse} toggleCollapse={props.toggleCollapse} />
    </div>
  );
}
