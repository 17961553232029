import {
  FETCH_LOGO_PICTURE_PENDING,
  FETCH_LOGO_PICTURE_SUCCESS,
  FETCH_LOGO_PICTURE_ERROR,
  SET_LOGO_IMAGE,
  OPEN_UPLOAD_LOGO_MODAL,
  CLOSE_UPLOAD_LOGO_MODAL,
  OPEN_LOGO_PICTURE_MENU,
  CLOSE_LOGO_PICTURE_MENU,
  OPEN_DELETE_LOGO_MODAL,
  CLOSE_DELETE_LOGO_MODAL,
  DELETE_LOGO_PICTURE_PENDING,
  DELETE_LOGO_PICTURE_SUCCESS,
  DELETE_LOGO_PICTURE_ERROR,
  UPLOAD_LOGO_PICTURE_PENDING,
  UPLOAD_LOGO_PICTURE_SUCCESS,
  UPLOAD_LOGO_PICTURE_ERROR,
  INVALID_LOGO_PICTURE_SIZE,
  INVALID_LOGO_PICTURE_FORMAT_ERROR,
} from '../../actions/ipp/practice_logo';

const initialState = {
  pending: false,
  error: false,
  logo_image: '',
  logo_image_edit: '',
  upload_logo_modal: false,
  save_logo_picture_pending: false,
  save_logo_picture_error: false,
  logo_picture_menu: false,
  delete_logo_modal: false,
  delete_picture_pending: false,
  upload_picture_pending: false,
  upload_picture_error: false,
  invalid_logo_picture_format_error: false,
  invalid_logo_picture_size: false,
  s3_url_raw: '',
};

export function practiceLogoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGO_PICTURE_PENDING:
      return {
        ...state,
        pending: true,
      };

    case FETCH_LOGO_PICTURE_SUCCESS:
      return {
        ...state,
        pending: false,
        logo_image: action.logo_image,
        s3_url_raw: action.s3_url_raw,
      };

    case FETCH_LOGO_PICTURE_ERROR:
      return {
        ...state,
        pending: false,
        error: true,
      };

    case OPEN_UPLOAD_LOGO_MODAL:
      return {
        ...state,
        upload_logo_modal: true,
      };

    case CLOSE_UPLOAD_LOGO_MODAL:
      return {
        ...state,
        upload_logo_modal: false,
        upload_picture_error: false,
        invalid_LOGO_picture_format_error: false,
        invalid_LOGO_picture_size: false,
      };

    case SET_LOGO_IMAGE:
      return {
        ...state,
        logo_image: action.logo_image,
      };

    case OPEN_LOGO_PICTURE_MENU:
      return {
        ...state,
        logo_picture_menu: true,
      };

    case CLOSE_LOGO_PICTURE_MENU:
      return {
        ...state,
        logo_picture_menu: false,
      };

    case OPEN_DELETE_LOGO_MODAL:
      return {
        ...state,
        delete_logo_modal: true,
        logo_picture_menu: false,
      };

    case CLOSE_DELETE_LOGO_MODAL:
      return {
        ...state,
        delete_logo_modal: false,
      };

    case DELETE_LOGO_PICTURE_PENDING:
      return {
        ...state,
        delete_picture_pending: true,
      };

    case DELETE_LOGO_PICTURE_SUCCESS:
      return {
        ...state,
        delete_picture_pending: false,
        delete_logo_modal: false,
        logo_image: '',
        s3_url_raw: '',
      };

    case DELETE_LOGO_PICTURE_ERROR:
      return {
        ...state,
        delete_picture_pending: false,
      };

    case UPLOAD_LOGO_PICTURE_PENDING:
      return {
        ...state,
        upload_picture_pending: true,
        upload_logo_modal: true,
      };

    case UPLOAD_LOGO_PICTURE_SUCCESS:
      return {
        ...state,
        upload_logo_modal: false,
        upload_picture_pending: false,
        s3_url_raw: action.s3_url_raw,
        logo_image: action.s3_url_raw,
      };

    case UPLOAD_LOGO_PICTURE_ERROR:
      return {
        ...state,
        upload_picture_error: true,
        upload_picture_pending: false,
      };

    case INVALID_LOGO_PICTURE_FORMAT_ERROR:
      return {
        ...state,
        invalid_logo_picture_format_error: true,
        upload_picture_pending: false,
      };

    case INVALID_LOGO_PICTURE_SIZE:
      return {
        ...state,
        invalid_logo_picture_size: true,
        upload_picture_pending: false,
      };

    default:
      return state;
  }
}

export const getLogoImage = (state) => state.practiceLogoReducer.logo_image;
export const getUploadPictureModal = (state) => state.practiceLogoReducer.upload_logo_modal;
export const getUploadPicturePending = (state) => state.practiceLogoReducer.upload_picture_pending;
export const getUploadPictureError = (state) => state.practiceLogoReducer.upload_picture_error;
export const getInvalidLogoPictureFormatError = (state) => state.practiceLogoReducer.invalid_logo_picture_format_error;
export const getInvalidLogoPictureSize = (state) => state.practiceLogoReducer.invalid_logo_picture_size;
export const getLogoPictureMenu = (state) => state.practiceLogoReducer.logo_picture_menu;
export const getDeletePictureModal = (state) => state.practiceLogoReducer.delete_logo_modal;
export const getLogoImageEdit = (state) => state.practiceLogoReducer.logo_image_edit;
