import Axios from 'axios';

// External Libs
import React, { Component } from 'react';

/**
 * Generic Process Class
 */
class ProcessComponent extends Component {
  enterNextStage = (stage) => {
    return Axios.post(`/apiV2/processtracker`, {
      actions: ['enter_next_stage'],
      enter_next_stage: { stage: stage, case_id: this.props.case_id },
    }).catch((err) => {
      console.error(`Error Entering Next Stage: ${stage}`, err);
    });
  };

  updateCaseAssignment = (user_id) => {
    return Axios.post(`/apiV2/processtracker`, {
      actions: ['update_case_assignment'],
      update_case_assignment: { case_id: this.props.case_id, user_id: user_id },
    }).catch((err) => {
      console.error(`Error Updating Case Assignment for Case: ${this.props.case_id}`, err);
    });
  };

  taskRunner = (task, params) => {
    if (typeof this.props.project[task] !== 'function') {
      console.warn(`${task} is not a function`);
      return false;
    }
    return params ? this.props.project[task](params) : this.props.project[task]();
  };

  render() {
    return <p>You need to implement the render function of this process</p>;
  }
}

export default ProcessComponent;
