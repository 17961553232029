export class ColorConverter {
    static numToHex(n) {
        const hex = n.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }

    static toHex(r, g, b) {
        return `#${this.numToHex(r)}${this.numToHex(g)}${this.numToHex(b)}`;
    }

    static toRGB(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            R: parseInt(result[1], 16),
            G: parseInt(result[2], 16),
            B: parseInt(result[3], 16)
        } : null;
    }
}
