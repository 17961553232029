// External Libs
import React, { Component } from 'react';
import _ from 'lodash';

// Internal Libs
import Modal from '../../modal/modal';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';
import { removeCaseIdInitialNumber, modalClear } from '../../../common/functions';
import { getPDFNameTemplate } from '../../../common/helpers';

const LABELS = {
  smartwire: { header: 'Smartwire and BFL', folder: 'smartwire_label', file_name: 'Label_Smartwire_Manual.pdf' },
  idb: { header: 'IDB Tray', folder: 'idbtray_label', file_name: 'Label_IDB_Manual.pdf' },
  bracket: { header: 'Brackets', folder: 'smallbracket_label', file_name: 'Label_Brackets_Manual.pdf' },
  outer_patient: { header: 'Outer Patient Label', folder: 'outerpatient_label', file_name: 'Label_Outer_Patient_Manual.pdf' },
  single_sheet: { header: 'Single Sheet', folder: 'singlesheet_label', file_name: 'Label_Single_Sheet_Manual.pdf' },
};

/**
 * Displays label generation success step
 * @component
 * @alias LabelSuccess
 * @category BPP
 */
class LabelSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pdf_modal: false,
      label_inner_packaging_modal: false,
      label_contents_modal: false,
      smartwire_modal: false,
      outer_modal: false,
      idb_modal: false,
      bracket_modal: false,
      single_sheet_modal: false,
      return_clicked: false,
      delete_inner_packaging_modal: false,
      remove_inner_packaging_in_progress: false,
      delete_inner_contents_modal: false,
      remove_inner_contents_in_progress: false,
      upload_inner_packaging_modal: false,
      upload_contents_modal: false,
      udi_label_modal: false,
      content_file: [],
      inner_packaging_file: [],
      smartwire_file: [],
      outerlabel_file: [],
      idb_file: [],
      bracket_file: [],
      singlesheetlabel_file: [],
      delete_gen_2_label_modal: false,
      selected_gen_2_label: '',
      remove_gen_2_label_in_progress: false,
      upload_gen_2_label_modal: false,
    };
  }

  componentDidUpdate() {
    if (
      this.state.delete_inner_packaging_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('inner_packaging');
      }).length === 0
    ) {
      this.setState({
        delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal,
        remove_inner_packaging_in_progress: !this.state.remove_inner_packaging_in_progress,
      });
    }

    if (
      this.state.delete_inner_contents_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('contents');
      }).length === 0
    ) {
      this.setState({
        delete_inner_contents_modal: !this.state.delete_inner_contents_modal,
        remove_inner_contents_in_progress: !this.state.remove_inner_contents_in_progress,
      });
    }

    const that = this;
    if (
      this.state.delete_gen_2_label_modal &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes(LABELS[that.state.selected_gen_2_label].folder);
      }).length === 0
    ) {
      this.setState({
        delete_gen_2_label_modal: !this.state.delete_gen_2_label_modal,
        remove_gen_2_label_in_progress: !this.state.remove_gen_2_label_in_progress,
        selected_gen_2_label: '',
      });
    }
  }

  onGen2LabelModalClick = (event) => {
    switch (event.target.dataset.value) {
      case 'smartwire':
        this.setState({ smartwire_modal: true });
        break;
      case 'idb':
        this.setState({ idb_modal: true });
        break;
      case 'bracket':
        this.setState({ bracket_modal: true });
        break;
      case 'outer_patient':
        this.setState({ outer_modal: true });
        break;
      case 'single_sheet':
        this.setState({ single_sheet_modal: true });
        break;
      default:
    }
  };

  onSmartWireModalDismiss = (event) => {
    this.setState({ smartwire_modal: false });
    modalClear();
  };

  onOutelLabelModalDismiss = (event) => {
    this.setState({ outer_modal: false });
    modalClear();
  };

  onIdbModalDismiss = (event) => {
    this.setState({ idb_modal: false });
    modalClear();
  };

  onBracketModalDismiss = (event) => {
    this.setState({ bracket_modal: false });
    modalClear();
  };

  /**
   * Closes modal for single sheet label
   * @function
   */
  onSingleSheetModalDismiss = () => {
    this.setState({ single_sheet_modal: false });
    modalClear();
  };

  onInnerPackagingModalClick = (event) => {
    this.setState({ label_inner_packaging_modal: true });
  };

  onInnerPackagingModalDismiss = (event) => {
    this.setState({ label_inner_packaging_modal: false });
    modalClear();
  };

  onLabelContentsModalClick = (event) => {
    this.setState({ label_contents_modal: true });
  };

  onLabelContentsModalDismiss = (event) => {
    this.setState({ label_contents_modal: false });
    modalClear();
  };

  /**
   * Closes UDI Label Modal
   * @function
   */
  onUdiLabelModalDismiss = (event) => {
    this.setState({ udi_label_modal: false });
    modalClear();
  };

  restartLabelGeneration = (event) => {
    this.setState({
      return_clicked: true,
    });
    this.props.restartLabelGeneration();
  };

  onDeleteInnerPackagingModalClick = () => {
    this.setState({ delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal });
  };

  onDeleteInnerPackagingModalDismiss = () => {
    this.setState({ delete_inner_packaging_modal: !this.state.delete_inner_packaging_modal });
  };

  onRemoveInnerPackagingButtonClick = () => {
    this.setState({ remove_inner_packaging_in_progress: !this.state.remove_inner_packaging_in_progress });
    this.props.removeInnerPackageFile();
  };

  onDeleteInnerContentsModalClick = () => {
    this.setState({ delete_inner_contents_modal: !this.state.delete_inner_contents_modal });
  };

  onDeleteInnerContentsModalDismiss = () => {
    this.setState({ delete_inner_contents_modal: !this.state.delete_inner_contents_modal });
  };

  onRemoveInnerContentsButtonClick = () => {
    this.setState({ remove_inner_contents_in_progress: !this.state.remove_inner_contents_in_progress });
    this.props.removeInnerContentsFile();
  };

  onUploadInnerPackagingModalClick = () => {
    this.setState({ upload_inner_packaging_modal: !this.state.upload_inner_packaging_modal });
  };

  onUploadContentsModalClick = () => {
    this.setState({ upload_contents_modal: !this.state.upload_contents_modal });
  };

  onDeleteGen2LabelModalClick = (label) => {
    this.setState({ delete_gen_2_label_modal: true, selected_gen_2_label: label });
  };

  onDeleteGen2LabelModalDismiss = () => {
    this.setState({ delete_gen_2_label_modal: false, selected_gen_2_label: '' });
  };

  onRemoveGen2LabelButtonClick = () => {
    this.setState({ remove_gen_2_label_in_progress: !this.state.remove_gen_2_label_in_progress });
    this.props.removeGen2LabelFile(this.state.selected_gen_2_label);
  };

  onUploadGen2LabelModalClick = (label) => {
    this.setState({ upload_gen_2_label_modal: true, selected_gen_2_label: label });
  };

  /**
   * Displays UDI Label Modal
   * @function
   */
  onUdiLabelModalClick = () => {
    this.setState({ udi_label_modal: !this.state.udi_label_modal });
  };

  onUploadInnerPackagingModalDismiss = () => {
    this.setState({ upload_inner_packaging_modal: !this.state.upload_inner_packaging_modal, inner_packaging_file: [] });
  };

  onUploadInnerPackaging = (data) => {
    this.setState({
      inner_packaging_file: data,
    });
    this.props.onUpload(data);
  };

  onUploadContent = (data) => {
    this.setState({
      content_file: data,
    });
    this.props.onUpload(data);
  };

  onUploadGen2Label = (data) => {
    switch (this.state.selected_gen_2_label) {
      case 'smartwire':
        this.setState({
          smartwire_file: data,
        });
        break;
      case 'idb':
        this.setState({
          idb_file: data,
        });
        break;
      case 'bracket':
        this.setState({
          bracket_file: data,
        });
        break;
      case 'outer_patient':
        this.setState({
          outerlabel_file: data,
        });
        break;
      case 'single_sheet':
        this.setState({
          singlesheetlabel_file: data,
        });
        break;
      default:
    }
    this.props.onUpload(data);
  };

  onUploadContentModalDismiss = () => {
    this.setState({ upload_contents_modal: !this.state.upload_contents_modal, content_file: [] });
  };

  onUploadGen2LabelModalDismiss = () => {
    this.setState({ upload_gen_2_label_modal: false });
    switch (this.state.selected_gen_2_label) {
      case 'smartwire':
        this.setState({ smartwire_file: [] });
        break;
      case 'idb':
        this.setState({ idb_file: [] });
        break;
      case 'bracket':
        this.setState({ bracket_file: [] });
        break;
      case 'outer_patient':
        this.setState({ outerlabel_file: [] });
        break;
      case 'single_sheet':
        this.setState({ singlesheetlabel_file: [] });
        break;
      default:
    }
    this.setState({ selected_gen_2_label: '' });
  };

  /**
   * Displays link for gen 2 label
   * @function
   * @param {object} labels - Label const
   * @param {string} label_type - Label type
   * @param {object} label_file_info - Label information
   * @param {number} index - Index of label
   * @param {boolean} is_wip - Indicator if case went through old label process
   * @returns {JSX} - Label
   */
  displayGen2Label = (labels, label_type, label_file_info, index, is_wip) => {
    const file = label_file_info.file;
    const file_exists = label_file_info.exists;
    return (
      <div className={`label-output${is_wip ? ' col-sm-6' : ''}`} key={index}>
        <div className="bold-text center-text">{labels[label_type].header}</div>
        {file_exists ? (
          <React.Fragment>
            <div className="center-text">{file.file_type === 'labels_manual' ? 'Manually Uploaded' : 'Generation successful'}</div>
            <div className="underline-text viewable-text center-text" data-value={label_type} onClick={this.onGen2LabelModalClick}>
              Label, {labels[label_type].header} <i className="fa fa-file-pdf-o" aria-hidden="true" />
            </div>
            <UserPermissionsContext.Consumer>
              {(user_roles_and_permissions) => {
                return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) && this.props.stage !== 'Labels Complete' ? (
                  <div className="delete-package" onClick={() => this.onDeleteGen2LabelModalClick(label_type)}>
                    <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                  </div>
                ) : null;
              }}
            </UserPermissionsContext.Consumer>
          </React.Fragment>
        ) : (
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                <React.Fragment>
                  <div className="case-upload-button">
                    <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={() => this.onUploadGen2LabelModalClick(label_type)}>
                      Upload
                    </button>
                  </div>
                </React.Fragment>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        )}
      </div>
    );
  };

  /**
   * Determines if gen 2 case has gone through old label generation process
   * @function
   * @param {object} gen_2_label_info - Label files
   * @returns {boolean} - True or false
   */
  isGen2WipCase = (gen_2_label_info) => {
    if (this.props.gen_2) {
      const labels = Object.keys(gen_2_label_info).filter((l) => l !== 'single_sheet');
      for (const label of labels) {
        if (gen_2_label_info[label].exists) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Displays links for gen 2 labels
   * @function
   * @param {object} gen_2_label_info - Label information
   * @returns {JSX} - Labels
   */
  displayGen2LabelOutputs = (gen_2_label_info) => {
    const is_wip = this.isGen2WipCase(gen_2_label_info);
    let labels = _.cloneDeep(LABELS);

    if (is_wip) {
      delete labels.single_sheet;
    } else {
      delete labels.smartwire;
      delete labels.idb;
      delete labels.bracket;
      delete labels.outer_patient;
    }

    return (
      <>
        {Object.keys(labels).map((label_type, index) => {
          if (!is_wip || this.isLabelSelected(label_type)) {
            return this.displayGen2Label(labels, label_type, gen_2_label_info[label_type], index, is_wip);
          }
          return null;
        })}
      </>
    );
  };

  isLabelSelected(label_type) {
    let logs = this.props.status_log.filter((log) => {
      return log.status_code === 'Label Generation Process';
    });

    if (logs.length > 0 && logs[logs.length - 1].text) {
      let status_comment = JSON.parse(logs[logs.length - 1].text);
      status_comment = status_comment.manifest;
      return (
        status_comment.filter((label) => {
          return label.includes(label_type);
        }).length > 0
      );
    }
    return false;
  }

  getGen2LabelFile(label) {
    const label_files = {
      smartwire: this.state.smartwire_file,
      idb: this.state.idb_file,
      bracket: this.state.bracket_file,
      outer_patient: this.state.outerlabel_file,
      single_sheet: this.state.singlesheetlabel_file,
    };
    let label_file_exists = false;
    let label_file = label_files[label];

    const file = this.props.files ? this.props.files.find((f) => f.original_filename.includes(LABELS[label].folder)) : null;
    if (file) {
      label_file_exists = true;
      label_file = label_file.length > 0 ? label_file : file;
    }
    return [label_file_exists, label_file];
  }

  getGen2LabelFileInfo() {
    const smartwire_file_info = this.getGen2LabelFile('smartwire');
    const smartwire_file_exists = smartwire_file_info[0];
    const smartwire_file = smartwire_file_info[1];

    const idb_file_info = this.getGen2LabelFile('idb');
    const idb_file_exists = idb_file_info[0];
    const idb_file = idb_file_info[1];

    const bracket_file_info = this.getGen2LabelFile('bracket');
    const bracket_file_exists = bracket_file_info[0];
    const bracket_file = bracket_file_info[1];

    const outerlabel_file_info = this.getGen2LabelFile('outer_patient');
    const outerlabel_file_exists = outerlabel_file_info[0];
    const outerlabel_file = outerlabel_file_info[1];

    const singlesheetlabel_file_info = this.getGen2LabelFile('single_sheet');
    const singlesheetlabel_file_exists = singlesheetlabel_file_info[0];
    const singlesheetlabel_file = singlesheetlabel_file_info[1];

    return {
      smartwire: {
        exists: smartwire_file_exists,
        file: smartwire_file,
      },
      idb: {
        exists: idb_file_exists,
        file: idb_file,
      },
      bracket: {
        exists: bracket_file_exists,
        file: bracket_file,
      },
      outer_patient: {
        exists: outerlabel_file_exists,
        file: outerlabel_file,
      },
      single_sheet: {
        exists: singlesheetlabel_file_exists,
        file: singlesheetlabel_file,
      },
    };
  }

  render() {
    const label_inner_packaging_file = this.props.getLabelFilePath('inner', this.props.files);
    const label_contents_file = this.props.getLabelFilePath('contents', this.props.files);
    let udi_label_file = this.props.getLabelFilePath('udi', this.props.files);

    let show_label_inner = false;
    let show_label_contents = false;
    let show_udi_label = false;

    const isInitialOrDE = this.props.case_id.indexOf('-') === -1 || this.props.case_id.includes('-DE');

    if (isInitialOrDE) {
      if (this.props.label_options.indexOf('content_labels') !== -1 && !this.props.mark_as_success) {
        show_label_contents = true;
      } else if (this.props.label_options.length === 0 && label_contents_file) {
        show_label_contents = true;
      }
    }
    let inner_packaging_file_exists = false;
    let inner_packaging_file = this.state.inner_packaging_file;
    if (
      this.props.files &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('inner_packaging');
      })[0]
    ) {
      inner_packaging_file_exists = true;
      inner_packaging_file =
        inner_packaging_file && inner_packaging_file.length > 0
          ? inner_packaging_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes('inner_packaging');
            })[0];
    }
    let contents_file_exists = false;
    let content_file = this.state.content_file;
    if (
      this.props.files &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('label_contents');
      })[0]
    ) {
      show_label_contents = true;
      contents_file_exists = true;
      content_file =
        content_file && content_file.length > 0
          ? content_file
          : this.props.files.filter(function (a) {
              return a.original_filename.includes('label_contents');
            })[0];
    }

    if (this.props.label_options.length > 0 && !this.props.mark_as_success) {
      show_label_inner = true;
    }
    if (this.props.label_options.length === 0 && label_inner_packaging_file) {
      show_label_inner = true;
    }

    if (
      this.props.files &&
      this.props.files.filter(function (a) {
        return a.original_filename.includes('udi_label');
      })[0]
    ) {
      show_udi_label = true;
      udi_label_file = this.props.files.filter(function (a) {
        return a.original_filename.includes('udi_label');
      })[0];
    }

    const gen_2_label_info = this.getGen2LabelFileInfo();
    const is_gen_2_wip = this.isGen2WipCase(gen_2_label_info);

    return (
      <React.Fragment>
        <div className="process-box-intro">
          <div className="process-box-title">Label Generation</div>
          <div>
            Please review the label{!this.props.gen_2 || is_gen_2_wip ? 's' : ''} below. Return to label selection and make adjustments if required. Or to
            manually upload a document, remove the automated version first.
          </div>

          <div className="process-box-label-section">
            <div className="row">
              {this.props.gen_2 ? (
                <>{this.displayGen2LabelOutputs(gen_2_label_info)}</>
              ) : (
                <>
                  {show_label_inner ? (
                    <div className={isInitialOrDE ? 'col-sm-6' : ''}>
                      <>
                        <div className="bold-text center-text">Inner Packaging</div>
                        {inner_packaging_file_exists ? (
                          <React.Fragment>
                            <div className="center-text">
                              {inner_packaging_file.file_type === 'labels_manual' ? 'Manually Uploaded' : 'Generation successful'}
                            </div>
                            <div className="underline-text viewable-text center-text" onClick={this.onInnerPackagingModalClick}>
                              Label, Inner Packaging <i className="fa fa-file-pdf-o" aria-hidden="true" />
                            </div>
                            <UserPermissionsContext.Consumer>
                              {(user_roles_and_permissions) => {
                                return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                                  <div className="delete-package" onClick={this.onDeleteInnerPackagingModalClick}>
                                    <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                                  </div>
                                ) : null;
                              }}
                            </UserPermissionsContext.Consumer>
                          </React.Fragment>
                        ) : (
                          <UserPermissionsContext.Consumer>
                            {(user_roles_and_permissions) => {
                              return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                                <React.Fragment>
                                  <div className="case-upload-button">
                                    <button
                                      type="button"
                                      className="btn btn-bpp-upload btn-shipped view-upload"
                                      onClick={this.onUploadInnerPackagingModalClick}
                                    >
                                      Upload
                                    </button>
                                  </div>
                                </React.Fragment>
                              ) : null;
                            }}
                          </UserPermissionsContext.Consumer>
                        )}
                      </>
                    </div>
                  ) : (
                    <div className={isInitialOrDE ? 'col-sm-6' : ''}>
                      <div className="bold-text center-text">Inner Packaging</div>
                      <UserPermissionsContext.Consumer>
                        {(user_roles_and_permissions) => {
                          return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                            <div className="case-upload-button">
                              <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadInnerPackagingModalClick}>
                                Upload
                              </button>
                            </div>
                          ) : null;
                        }}
                      </UserPermissionsContext.Consumer>
                    </div>
                  )}
                  {isInitialOrDE ? (
                    <React.Fragment>
                      {show_label_contents ? (
                        <div className="col-sm-6">
                          <div className="bold-text center-text">Contents</div>
                          {contents_file_exists ? (
                            <React.Fragment>
                              <div className="center-text">{content_file.file_type === 'labels_manual' ? 'Manually Uploaded' : 'Generation successful'}</div>
                              <div className="underline-text viewable-text center-text" onClick={this.onLabelContentsModalClick}>
                                Label, Contents <i className="fa fa-file-pdf-o" aria-hidden="true" />
                              </div>
                              <UserPermissionsContext.Consumer>
                                {(user_roles_and_permissions) => {
                                  return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                                    <div className="delete-package" onClick={this.onDeleteInnerContentsModalClick}>
                                      <i className="fa fa-trash-o text-padding-r3 blue-tooltip " aria-hidden="true" />
                                    </div>
                                  ) : null;
                                }}
                              </UserPermissionsContext.Consumer>
                            </React.Fragment>
                          ) : (
                            <UserPermissionsContext.Consumer>
                              {(user_roles_and_permissions) => {
                                return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                                  <React.Fragment>
                                    <div className="case-upload-button">
                                      <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadContentsModalClick}>
                                        Upload
                                      </button>
                                    </div>
                                  </React.Fragment>
                                ) : null;
                              }}
                            </UserPermissionsContext.Consumer>
                          )}
                        </div>
                      ) : (
                        <div className="col-sm-6 margin-bottom-15">
                          <div className="bold-text center-text">Contents</div>
                          <UserPermissionsContext.Consumer>
                            {(user_roles_and_permissions) => {
                              return userHasPermission('CASE_LABEL_MANUAL_UPLOAD', user_roles_and_permissions.permissions) ? (
                                <div className="case-upload-button">
                                  <button type="button" className="btn btn-bpp-upload btn-shipped view-upload" onClick={this.onUploadContentsModalClick}>
                                    Upload
                                  </button>
                                </div>
                              ) : null;
                            }}
                          </UserPermissionsContext.Consumer>
                        </div>
                      )}
                    </React.Fragment>
                  ) : null}
                </>
              )}
              {!this.props.gen_2 || is_gen_2_wip ? (
                show_udi_label ? (
                  <div className={isInitialOrDE ? 'col-sm-6 margin-top-10' : ''}>
                    <div className="bold-text center-text">UDI Label</div>
                    {show_udi_label ? (
                      <React.Fragment>
                        <div className="center-text">{'Generation successful'}</div>
                        <div className="underline-text viewable-text center-text" onClick={this.onUdiLabelModalClick}>
                          UDI Label <i className="fa fa-file-pdf-o" aria-hidden="true" />
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                ) : !show_udi_label && this.props.label_options.includes('udi_label') ? (
                  <div className={isInitialOrDE ? 'col-sm-6 margin-top-10' : ''}>
                    <div className="bold-text center-text">UDI Label</div>
                    <div className="center-text">{'Generation failed'}</div>
                  </div>
                ) : (
                  <div className={isInitialOrDE ? 'col-sm-6 margin-top-10' : ''}>
                    <div className="bold-text center-text">UDI Label</div>
                    <div className="center-text">{'Not generated'}</div>
                  </div>
                )
              ) : null}
            </div>
          </div>

          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
                <span>
                  {/* eslint-disable-next-line */}
                  <a className={this.state.return_clicked ? 'return-disabled' : ''} onClick={this.restartLabelGeneration}>
                    <i className="fa fa-long-arrow-left" aria-hidden="true" /> Return to label selection
                  </a>
                </span>
              ) : null;
            }}
          </UserPermissionsContext.Consumer>
        </div>
        {this.state.label_inner_packaging_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Label Inner Packaging - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={label_inner_packaging_file.upload_data}
            pdf_date={label_inner_packaging_file.created_date}
            onCloseButtonClick={this.onInnerPackagingModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Inner_Packaging${inner_packaging_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.label_contents_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Label Contents - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={label_contents_file.upload_data}
            pdf_date={label_contents_file.created_date}
            onCloseButtonClick={this.onLabelContentsModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Contents${content_file.file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.udi_label_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`UDI Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={udi_label_file.upload_data}
            pdf_date={udi_label_file.created_date}
            onCloseButtonClick={this.onUdiLabelModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, 'Label_UDI.pdf')}
          />
        ) : null}
        {this.state.smartwire_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Smartwire and BFL Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={gen_2_label_info.smartwire.file.upload_data}
            pdf_date={gen_2_label_info.smartwire.file.created_date}
            onCloseButtonClick={this.onSmartWireModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Smartwire${this.getGen2LabelFile('smartwire')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.outer_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Outer Patient Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={gen_2_label_info.outer_patient.file.upload_data}
            pdf_date={gen_2_label_info.outer_patient.file.created_date}
            onCloseButtonClick={this.onOutelLabelModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Outer_Patient${this.getGen2LabelFile('outer_patient')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.idb_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`IDB Tray Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={gen_2_label_info.idb.file.upload_data}
            pdf_date={gen_2_label_info.idb.file.created_date}
            onCloseButtonClick={this.onIdbModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_IDB${this.getGen2LabelFile('idb')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.bracket_modal ? (
          <Modal
            preset="pdf_viewer"
            header_text={`Bracket Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={gen_2_label_info.bracket.file.upload_data}
            pdf_date={gen_2_label_info.bracket.file.created_date}
            onCloseButtonClick={this.onBracketModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Brackets${this.getGen2LabelFile('bracket')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        ) : null}
        {this.state.single_sheet_modal && (
          <Modal
            preset="pdf_viewer"
            header_text={`Single Sheet Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf"
            pdf_url={gen_2_label_info.single_sheet.file.upload_data}
            pdf_date={gen_2_label_info.single_sheet.file.created_date}
            onCloseButtonClick={this.onSingleSheetModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(
              this.props.case_id,
              this.props.patient_info,
              `Label_Single_Sheet${this.getGen2LabelFile('single_sheet')[1].file_type === 'labels_manual' ? '_Manual' : ''}.pdf`
            )}
          />
        )}
        {this.state.delete_inner_packaging_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Inner Packaging Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            message_text={'Are you sure you want to remove the Inner Packaging Label?'}
            in_progress={this.state.remove_inner_packaging_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveInnerPackagingButtonClick}
            onCloseButtonClick={this.onDeleteInnerPackagingModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.delete_inner_contents_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={'Remove Inner Contents Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            message_text={'Are you sure you want to remove the Inner Contents Label?'}
            in_progress={this.state.remove_inner_contents_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveInnerContentsButtonClick}
            onCloseButtonClick={this.onRemoveInnerContentsButtonClick}
            theme="bpp"
          />
        ) : null}
        {this.state.upload_inner_packaging_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload Inner Packaging Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder="inner_package_label"
            type="additional"
            onUpload={this.onUploadInnerPackaging}
            upload_content={inner_packaging_file}
            onCloseButtonClick={this.onUploadInnerPackagingModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, 'Label_Inner_Packaging_Manual.pdf')}
          />
        ) : null}
        {this.state.upload_contents_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={'Upload Contents Label - ' + removeCaseIdInitialNumber(this.props.case_id)}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder="contents_labels"
            type="additional"
            onUpload={this.onUploadContent}
            upload_content={content_file}
            onCloseButtonClick={this.onUploadContentModalDismiss}
            onRemoveButtonClick={this.onRemove}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, 'Label_Contents_Manual.pdf')}
          />
        ) : null}
        {this.state.delete_gen_2_label_modal ? (
          <Modal
            preset="decision-ifu"
            header_text={`Remove ${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label].header
            } Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            message_text={`Are you sure you want to remove the ${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label].header
            } Label?`}
            in_progress={this.state.remove_gen_2_label_in_progress}
            in_progress_text={'Removing...'}
            confirm_btn_text="Remove"
            close_btn_text="Cancel"
            onConfirmButtonClick={this.onRemoveGen2LabelButtonClick}
            onCloseButtonClick={this.onDeleteGen2LabelModalDismiss}
            theme="bpp"
          />
        ) : null}
        {this.state.upload_gen_2_label_modal ? (
          <Modal
            preset="upload-pdf"
            header_text={`Upload ${
              this.state.selected_gen_2_label === 'outer_patient' ? 'Outer Patient' : LABELS[this.state.selected_gen_2_label].header
            } Label - ${removeCaseIdInitialNumber(this.props.case_id)}`}
            modal_size_class="modal-lg modal-lg-pdf"
            modal_body_class="modal-pdf smartwire-label"
            case_id={this.props.case_id}
            folder={LABELS[this.state.selected_gen_2_label].folder}
            type="additional"
            onUpload={this.onUploadGen2Label}
            upload_content={
              this.state.selected_gen_2_label === 'smartwire'
                ? gen_2_label_info.smartwire.file
                : this.state.selected_gen_2_label === 'idb'
                ? gen_2_label_info.idb.file
                : this.state.selected_gen_2_label === 'bracket'
                ? gen_2_label_info.bracket.file
                : this.state.selected_gen_2_label === 'single_sheet'
                ? gen_2_label_info.single_sheet.file
                : gen_2_label_info.outer_patient.file
            }
            onCloseButtonClick={this.onUploadGen2LabelModalDismiss}
            theme="bpp"
            original_filename={getPDFNameTemplate(this.props.case_id, this.props.patient_info, ` ${LABELS[this.state.selected_gen_2_label].file_name}`)}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default LabelSuccess;
