import React, { useContext } from 'react';
import { UserPermissionsContext } from '../../../../../context/user_permission';
import { LogoWhite, SDVText } from '../../../../../doctor/assets/logos';

/**
 * Contains the logo of the new inbrace brand for the setup viewer
 * @component
 * @alias SetupViewerLogo
 */
export default function Logo(props) {
  const { doctor_id } = useContext(UserPermissionsContext);
  const containerClassName = props.collapse ? 'logo-container small' : 'logo-container';

  return (
    <div className={containerClassName}>
      <LogoWhite onClick={() => props.onClick(doctor_id)}/>
      {props.collapse ? null : <SDVText /> }
    </div>
  );
}
