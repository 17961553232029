import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as ViewIcons from '../../../icons';
import SelectionViewControl from './selection_view_control';

/**
 * Displays the different view controls
 *
 * @component
 */
class SelectionViewControls extends Component {
  componentDidMount() {
    window.$('[data-toggle="tooltip-delay"]').tooltip({
      trigger: 'hover',
      delay: {
        show: 50,
        hide: 50,
      },
    });
  }

  render() {
    return (
      <div className="view-selection">
        <table className="nav-table">
          <tbody>
            <tr>
              <td />
              <td>
                <div data-toggle="tooltip-delay" data-placement="top" title="Top View (T)">
                  <SelectionViewControl name="top" view={this.props.view} onChangeView={this.props.onChangeView}>
                    <ViewIcons.ArchViewIcon />
                  </SelectionViewControl>
                </div>
              </td>
              <td />
            </tr>
            <tr>
              <td rowSpan="1">
                <div data-toggle="tooltip-delay" data-placement="top" title="Right View (R)">
                  <SelectionViewControl name="right" view={this.props.view} onChangeView={this.props.onChangeView}>
                    <ViewIcons.SideViewIcon className="flip_l_r" />
                  </SelectionViewControl>
                </div>
              </td>
              <td>
                <div data-toggle="tooltip-delay" data-placement="top" title="Front / Back View (F)">
                  <SelectionViewControl name="front" names={['front', 'back']} view={this.props.view} onChangeView={this.props.onChangeView}>
                    <ViewIcons.FrontViewIcon />
                  </SelectionViewControl>
                </div>
              </td>
              <td rowSpan="1">
                <div data-toggle="tooltip-delay" data-placement="top" title="Left View (L)">
                  <SelectionViewControl name="left" view={this.props.view} onChangeView={this.props.onChangeView}>
                    <ViewIcons.SideViewIcon />
                  </SelectionViewControl>
                </div>
              </td>
            </tr>
            <tr>
              <td />
              <td>
                <div data-toggle="tooltip-delay" data-placement="bottom" title="Bottom View (B)">
                  <SelectionViewControl name="bottom" view={this.props.view} onChangeView={this.props.onChangeView}>
                    <ViewIcons.ArchViewIcon className="flip_u_d" />
                  </SelectionViewControl>
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <div className="jaw-view">
          <div
            data-toggle="tooltip-delay"
            data-placement="top"
            title="Toggle Upper (P)"
            className={`view-btn${this.props.jaw === 'lower' ? '' : ' active'}`}
            id="show-upper"
            onClick={() => this.props.onChangeJaw('lower')}
          >
            <div>U</div>
          </div>
          <div
            data-toggle="tooltip-delay"
            data-placement="bottom"
            title="Toggle Lower (O)"
            className={`view-btn${this.props.jaw === 'upper' ? '' : ' active'}`}
            id="show-lower"
            onClick={() => this.props.onChangeJaw('upper')}
          >
            <div>L</div>
          </div>
        </div>
      </div>
    );
  }
}

SelectionViewControls.propTypes = {
  view: PropTypes.string.isRequired,
  jaw: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  onChangeJaw: PropTypes.func.isRequired,
};

export default SelectionViewControls;
