// Css
import './process_tracker.scss';

import React, { Component } from 'react';

// Internal
import { isCancelOrHoldNextStatus } from '../../common/helpers';
// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { EHasPermissionMode, EUserPermission, userHasPermission, userHasPermissions } from '../../common/permission';
import { isCaseBlocked } from '../../common/helpers';

/**
 * Process Tracker shows the progress of the digital workflow states
 */
export class ProcessTracker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_mounted: false,
      active: null,
      workflow: null,
      stage_component: null,
    };
    this.refreshData();
  }

  /**
   * Determines if user is allowed to toggle the provided stage
   * @function
   * @param {string} stage_name - Stage name
   * @returns {boolean} - True or false
   */
  canToggleStage = (stage_name) => {
    const process = this.props.process;
    let stages_toggle_allowed = [];
    switch (process) {
      case 'cad':
        stages_toggle_allowed = ['Stage II', 'Stage III'];
        return stages_toggle_allowed.includes(stage_name) && stages_toggle_allowed.includes(this.props.current_cad_stage);
      case 'production':
        stages_toggle_allowed = ['Label', 'Wire', 'WFR'];
        return stages_toggle_allowed.some((stage) => {
          return stage_name.includes(stage);
        });
      default:
        return false;
    }
  };

  createListItem = (stage, index) => {
    stage = this.finalizeStage(stage, index);

    return (
      <UserPermissionsContext.Consumer key={index}>
        {(user_roles_and_permissions) => {
          const can_toggle =
            this.canToggleStage(stage.description) &&
            (this.props.process === 'cad'
              ? userHasPermissions([EUserPermission.CompleteCAD, EUserPermission.CompleteApplianceDesignStage2], user_roles_and_permissions.permissions, EHasPermissionMode.Some)
              : true);

          const is_case_blocked = this.props.cases && isCaseBlocked(this.props.cases.status_code);
          return (
            <li
              className={`${stage.state}${can_toggle ? ' process-toggle' : ''}${
                this.props.stage_disabled || is_case_blocked ? ' process-toggle__disabled' : ''
              }`}
              onClick={can_toggle ? () => this.props.onStageChange(stage.description) : null}
            >
              <p
                className={`${this.props.process === 'production' && index === 1 ? 'production' : ''}${
                  can_toggle && !this.isCurrentStage(stage) ? ' font-weight-normal' : ''
                }${can_toggle ? ' process-toggle__label' : ''}`}
              >
                {stage.description === 'Wire Fabrication Review (optional)' && this.props.is_wfr_initiated
                  ? stage.description.replace(' (optional)', '')
                  : stage.description}
              </p>
            </li>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  finalizeStage = (stage, index) => {
    const resultStage = stage.result_stages ? stage.result_stages.find(this.findCurrentStage) : null;
    stage = resultStage || stage;

    if (stage.description === 'Wire Selection Review' && this.props.ws_status === 'WS Review') {
      stage.state = this.isStageFailed(stage) ? 'process-failure' : this.isStageSuccessful(stage) ? 'process-done' : 'process-in-progress';
      return stage;
    }

    if (
      (this.isCurrentStage(stage) && !resultStage) ||
      (this.isCurrentStage(stage) &&
        resultStage &&
        resultStage.state &&
        resultStage.state === 'process' &&
        !isCancelOrHoldNextStatus(this.props.last_stage, this.props.stage))
    ) {
      stage.state = 'process-in-progress';
    } else if (this.props.ifu_status === 'in-progress' && stage.name === 'Stage III' && !this.isCurrentStage(stage)) {
      stage.state = this.props.stage_disabled ? 'process-disabled' : 'process-next';
    } else if (!resultStage) {
      stage.state = this.canToggleStage(stage.description)
        ? this.isStageFailed(stage)
          ? 'process-failure'
          : this.isStageSuccessful(stage)
          ? 'process-done'
          : this.props.stage_disabled
          ? 'process-disabled'
          : 'process-next'
        : this.currentIndex > index
        ? 'process-done'
        : this.props.stage_disabled
        ? 'process-disabled'
        : 'process-next';
    } else if (this.canToggleStage(stage.description)) {
      const add_in_progress = this.props.process === 'cad' ? this.props.selected_cad_stage === stage.description : this.props.stage === stage.name;
      if (add_in_progress) {
        if (!stage.state.includes('process-in-progress')) {
          stage.state += ' process-in-progress';
        }
      } else {
        stage.state = stage.state.replace('process-in-progress', '');
      }
    }
    return stage;
  };

  /**
   * Determines if stage is successful
   * @function
   * @param {object} stage - Stage object
   * @returns {boolean} - True or false
   */
  isStageSuccessful = (stage) => {
    switch (stage.description) {
      case 'Stage II':
        return (this.props.ddm_successful && this.props.current_cad_stage === 'Stage III') || this.props.ddm_status === 'ddm-wip';
      case 'Stage III':
        return this.props.ifu_successful && this.props.current_cad_stage === 'Quality Control Review';
      case 'Labels':
        return this.props.label_generation_status === 'Labels Complete';
      case 'Wire Fabrication Review (optional)':
        return this.props.wire_fabrication_review_status === 'WFR Complete';

      default:
    }
    return false;
  };

  /**
   * Determines if stage has failed
   * @function
   * @param {object} stage - Stage object
   * @returns {boolean} - True or false
   */
  isStageFailed = (stage) => {
    switch (stage.description) {
      case 'Stage II':
        return this.props.ddm_failed;
      case 'Stage III':
        return this.props.ifu_failed;
      case 'Wire Selection Review':
        return !this.props.has_wsg && this.props.ws_status_comment && this.props.ws_status_comment !== 'Success';
      case 'Labels':
        return this.props.label_generation_status === 'Label Generation Failed';
      case 'Wire Fabrication Review (optional)':
        return this.props.wire_fabrication_review_status === 'WFR Rework';

      default:
    }
    return false;
  };

  isCurrentStage = (stage) => {
    let result = false;
    let stage_name = stage.name;
    let last_stage = this.props.last_stage;

    if (this.canToggleStage(stage.description)) {
      const is_current_stage_blocked = this.props.cases && isCaseBlocked(this.props.cases.status_code) && this.props.selected_cad_stage === stage.description;
      if (is_current_stage_blocked) {
        const statuses = {
          'Stage II': this.props.ddm_status,
          'Stage III': this.props.ifu_status,
        };
        last_stage = statuses[stage.description];
      }
    }

    if (!last_stage) {
      result = stage_name === this.props.stage;
    } else {
      result = stage_name === last_stage;
    }
    return result;
  };

  findCurrentStage = (stage) => {
    // check if the current stage is part of a result state set
    if (stage.result_stages && stage.result_stages.length) {
      const tempStage = stage.result_stages.find(this.isCurrentStage);
      stage = tempStage || stage;
    }
    return this.isCurrentStage(stage);
  };

  loadComponent = () => {
    if (this.state.active) {
      const StageComponent = this.state.stage_component;
      const stage = this.props.process === 'cad' ? this.props.selected_cad_stage : this.props.stage;
      return (
        <div className="process_tracker">
          <div className={this.props.hideRuler ? 'margin-top-20' : null}>
            <hr className={this.props.hideRuler ? 'hidden' : null} />
            <ul className={this.props.process.includes('cad') || this.props.process === 'production' ? 'workflow center-text' : 'workflow'}>
              {this.state.workflow}
            </ul>
            {this.canToggleStage(stage) && this.displayToggleBox()}
            {StageComponent ? <StageComponent {...this.props} /> : null}
          </div>
        </div>
      );
    }
    return null;
  };

  /**
   * Displays box surrounding toggleable stages
   * @function
   * @returns {JSX} - JSX for toggle box
   */
  displayToggleBox = () => {
    let opposing_stage = '';
    if (this.props.process === 'cad') {
      opposing_stage = this.props.selected_cad_stage === 'Stage II' ? 'Stage III' : 'Stage II';
    } else {
      opposing_stage = this.props.selected_production_stage === 'labels' ? 'Wire Fabrication Review' : 'Labels';
    }

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          const has_toggle_permission =
            this.props.process === 'cad'
              ? userHasPermissions([EUserPermission.CompleteCAD, EUserPermission.CompleteApplianceDesignStage2], user_roles_and_permissions.permissions, EHasPermissionMode.Some)
              : true;
          const has_return_stage_1_permission = this.props.process === 'cad' && userHasPermission('RETURN_STAGE_I', user_roles_and_permissions.permissions);
          const has_wire_fabrication_review_permissions = userHasPermission('CASE_WIRE_FABRICATION_REVIEW', user_roles_and_permissions.permissions);

          const show_initiate_button =
            this.props.process === 'production' &&
            this.props.stage_disabled &&
            has_wire_fabrication_review_permissions &&
            this.props.wire_selection_status === 'WS Complete' &&
            (!this.props.wire_manufacturing_status || this.props.wire_manufacturing_status === 'WM Complete');

          return has_toggle_permission || has_return_stage_1_permission ? (
            <div className={`toggle__${this.props.process}${isCaseBlocked(this.props.cases.status_code) ? ' toggle__hide' : ''}`}>
              <div className={`toggle__${this.props.process}__box`}>
                {has_toggle_permission && (
                  <i
                    className={`fa fa-exchange${this.props.stage_disabled ? ' toggle-disabled' : ''}`}
                    aria-hidden={true}
                    onClick={() => this.props.onStageChange(opposing_stage)}
                  />
                )}
                {has_return_stage_1_permission && (
                  <button
                    className={`${this.props.upload_pending ? 'disabled' : ''}${has_toggle_permission ? '' : ' toggle__cad__box__no-toggle'}`}
                    onClick={this.props.returnToStageI}
                  >
                    Return to Stage I
                  </button>
                )}
                {show_initiate_button && (
                  <div>
                    <button onClick={this.props.onButtonClick}>Initiate</button>
                  </div>
                )}
              </div>
            </div>
          ) : null;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  loadProcess = () => {
    return import(`./${this.props.process}/constants.js`).then((data) => {
      this.workflow = data.workflow;
      if (this.props.filter) {
        this.workflow = this.workflow.filter((stage) => {
          return !stage.filter?.includes(this.props.filter);
        });
      }
      this.active = this.currentStage = this.workflow ? this.workflow.find(this.findCurrentStage) : null;

      this.currentIndex = this.workflow.indexOf(this.currentStage);
      this.workflow = this.active ? this.workflow.map((stage, index) => this.createListItem(stage, index)) : null;
    });
  };

  loadStageComponent = () => {
    if (this.active) {
      const componentName = this.currentStage ? this.currentStage.component : 'component_not_found';

      return import(`./${this.props.process}/${componentName}.js`).then(
        (component) => (this.stageComponent = component.default),
        (error) => console.warn(`No stage component found for stage '${this.props.stage}'`)
      );
    }
  };

  finalizeState = () => {
    this.active && this.state.is_mounted ? this.updateState() : this.unsetState();
  };

  updateState = () => {
    this.setState({
      active: this.active,
      workflow: this.workflow,
      stage_component: this.stageComponent,
    });
  };

  unsetState = () => {
    this.setState({
      active: null,
      workflow: null,
      stage_component: null,
    });
    this.refreshData();
  };

  refreshData = () => {
    this.currentStage = null;
    this.currentIndex = null;
    this.active = null;
    this.workflow = null;
    this.stageComponent = null;
  };

  loadInformation = () => {
    if (this.state.is_mounted) {
      return this.loadProcess().then(this.loadStageComponent).then(this.finalizeState);
    }
  };

  componentDidMount() {
    if (!this.props.stage) {
      this.setState({ is_mounted: true });
    } else {
      this.setState({ is_mounted: true }, this.loadInformation);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.stage !== this.props.stage ||
      (this.props.cases && this.props.cases.status_code !== prevProps.cases.status_code) ||
      this.props.ddm_status !== prevProps.ddm_status ||
      this.props.ifu_status !== prevProps.ifu_status ||
      this.props.has_wsg !== prevProps.has_wsg ||
      this.props.ws_status_comment !== prevProps.ws_status_comment ||
      this.props.stage_disabled !== prevProps.stage_disabled ||
      this.props.label_generation_status !== prevProps.label_generation_status ||
      this.props.is_wfr_initiated !== prevProps.is_wfr_initiated ||
      this.props.wire_manufacturing_status !== prevProps.wire_manufacturing_status
    ) {
      this.loadInformation();
    }
  }

  render() {
    return this.loadComponent();
  }
}

export default ProcessTracker;
