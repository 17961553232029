import Axios from 'axios';

function sendApprovalRequest(case_details) {
  const { case_id, cases } = case_details;
  const inbrace_ifs_required = cases[cases.length - 1]?.production_tx_guide?.inbrace_ifs_required;
  const action = inbrace_ifs_required ? 'approve_with_inbrace_ifs' : 'approve';
  return Axios.post(`/apiV2/caseaction/${case_id}/${action}`).then(() => {
    sendApprovalEmail(case_id);
  });
}

function sendApprovalEmail(case_id) {
  if (case_id.includes('-DE')) {
    Axios.post(`/api/email/?slug=setup-approval-email-de-1&caseId=${case_id}&method=standard&provider=${window.location.origin}`);
  } else {
    Axios.post(`/api/email/?slug=setup-approval-email-case-1&caseId=${case_id}&method=standard&provider=${window.location.origin}`);
  }
}

/**
 * Sends a revision request for a case.
 * @param {string} case_id - The ID of the case.
 * @param {string} revise_reason - The reason for the revision request.
 * @param {boolean} hasModelChange - Indicates if there is a model change.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
function sendRevisionRequest(case_id, revise_reason, hasModelChange) {
  const formData = { status_comment: revise_reason, has_model_change: hasModelChange };
  const action = revise_reason ? 'revision' : 'revision_with_provider_edit';
  return Axios.post(`/apiV2/caseaction/${case_id}/${action}`, formData);
}

export { sendApprovalRequest, sendApprovalEmail, sendRevisionRequest };
