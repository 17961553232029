import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '../components/container/card_container';

/**
 * Displays a case list status count
 * @component
 */
function CaseListStats(props) {
  const [shouldRender, setShouldRender] = useState(false);
  const { caseList, history } = props;

  useEffect(() => {
    // Delay the initial rendering by 0.5 seconds for caselist loading
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  /**
   * Get status count by status name
   * @function
   * @param {status} status  status name
   * @returns {string} status count or 0
   */
  const getStatusCount = (status) => {
    return caseList && shouldRender ? caseList.filter((c) => c.rebranded_status?.includes(status))?.length : 0;
  };

  /**
   * Redirect to the case detail page with optional filter query parameter
   *
   * @param {object} params - Parameters for the redirection
   * @param {string} params.filter - Optional filter for the case detail
   */
  const redirectToCaseDetail = (params) => {
    const { filter } = params;
    const currentURL = history.location.pathname;

    // Remove '/dashboard' from the current URL
    const updatedURL = currentURL.replace(/\/dashboard/, '');

    // Build the new URL with or without the filter parameter
    let redirectURL = updatedURL;
    if (filter) {
      redirectURL += `?filter=${filter}`;
    }

    // Use the history object to navigate to the new URL
    history.push(redirectURL);
  };

  const statsList = [
    {
      title: 'Review \n Smile Design',
      count: getStatusCount('Review Smile Design'),
      params: { filter: 'setup' },
    },
    {
      title: 'Upload \n New Records',
      count: getStatusCount('Upload New Records'),
      params: { filter: 'new' },
    },
    {
      title: 'Pending Clarification',
      count: getStatusCount('Clarification'),
      params: { filter: 'clarification' },
    },
    {
      title: 'On Hold',
      count: getStatusCount('Hold'),
      params: { filter: 'hold' },
    },
    {
      title: 'Drafts',
      count: getStatusCount('Draft'),
      params: { filter: 'draft' },
    },
  ];

  return (
    <CardContainer className="pad-sm mt-none dashboard__stats">
      {statsList.map((item) => (
        <div className="dashboard__stats__col" onClick={() => redirectToCaseDetail(item.params)} key={item.title}>
          <div className="stats__title">
            <h3>{item.title}</h3>
          </div>
          <h3>{item.count}</h3>
        </div>
      ))}
      <div className="dashboard__stats__horizontal-divider" />
    </CardContainer>
  );
}

CaseListStats.propTypes = {
  caseList: PropTypes.arrayOf(
    PropTypes.shape({
      rebranded_status: PropTypes.string,
    })
  ),
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default CaseListStats;
