import { Vector2 } from 'three';

/**
 * Compares two version numbers
 *
 * Returns the following values
 * - `0` if identical
 * - `-1` if v1 < v2
 * - `1` if v1 > v2
 *
 * @example
 *
 * compareVersion("1.8.0", "1.9.1"); // -1
 * compareVersion("1.10.0", "1.9.1"); // 1
 *
 * @function
 * @param {String} v1 - Version 1
 * @param {String} v2 - Version 2
 * @return {Number} The number result from comparing the versions
 */
function compareVersion(v1, v2) {
  const v1parts = v1.split('.');
  const v2parts = v2.split('.');
  const maxLen = Math.max(v1parts.length, v2parts.length);

  for (let i = 0; i < maxLen; ++i) {
    const p1 = parseInt(v1parts[i], 10) || 0;
    const p2 = parseInt(v2parts[i], 10) || 0;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
  }

  return 0;
}

/**
 * Returns whether v1 >= v2
 *
 * @function
 * @param {String} v1 - Version 1
 * @param {String} v2 - Version 2
 * @return {Boolean} Whether v1 >= v2
 */
function versionGte(v1, v2) {
  return compareVersion(v1, v2) >= 0;
}

/**
 * Returns the screen scale
 * @function
 * @param {THREE.Camera} camera - The camera
 * @param {THREE.WebGLRenderer} renderer - The renderer
 * @return {Number} The screen scale
 */
function getScreenScale(camera, renderer) {
  const pxSz = 15;
  const size = renderer.getSize(new Vector2());
  const widthPx = size.width;
  const widthMM = (camera.right - camera.left) / camera.zoom;
  const tsz = (pxSz / widthPx) * widthMM;
  return tsz;
}

/**
 * Returns whether the tooth number is mandibular
 * @function
 * @param {Number} teeth_number - The tooth number
 * @return {Boolean} Whether mandibular or not
 */
function isMan(teeth_number) {
  return teeth_number >= 17;
}

/**
 * Returns the translated step
 * @function
 * @param {String} step - The ipr step to translate
 * @return {String} The translated step
 */
function iprStepNameTranslate(step) {
  if (step === 'start') return 'first';
  if (step === 'end') return 'last';
  return step;
}

/**
 * Sets the opacity of the two material linearly
 * @function
 * @param {THREE.Material} material_1 - The first material
 * @param {THREE.Material} material_2 - The second material
 * @param {Number} opacity - The opacity to set
 */
function setMaterialOpacity(material_1, material_2, opacity) {
  if (opacity >= 0.0 && opacity <= 1.0) {
    if (opacity >= 0.5) {
      material_1.transparent = true;
      material_2.transparent = false;
      material_1.opacity = 1.5 - opacity;
    } else {
      material_1.transparent = false;
      material_2.transparent = true;
      material_2.opacity = opacity + 0.5;
    }
  }
}

export { compareVersion, versionGte, getScreenScale, isMan, iprStepNameTranslate, setMaterialOpacity };
