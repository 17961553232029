import React, { useState } from 'react';
import { BusinessTooltip } from '../../common/tooltip';
import './clipboard.scss';

/**
 * Adds copy to clipboard icon and saves the text to clipboard
 * @component
 * @category BPP
 */
function ClipboardCopy({ copy_text, theme }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copy_text)
      .then(() => {
        setIsCopied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reset_title = () => {
    setTimeout(() => {
      setIsCopied(false);
    }, 100);
  };

  return (
    <BusinessTooltip title={isCopied ? 'Copied' : 'Copy'} placement="top" onClose={reset_title} arrow>
      <div className={`copy-to-clipboard ${theme}`} onClick={handleCopyClick}>
        <i className="fa fa-clone" aria-hidden="true"></i>
      </div>
    </BusinessTooltip>
  );
}

export default ClipboardCopy;
