import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './styles.scss';
import { WasmBoltonTable } from './wasm-bolton-table';
import { getBoltonAnalysisValues } from '../../wasm_controller/wasm_controller';
import { getWasmLoading } from '../../../../redux/selectors/wasm_viewer';


const WasmBoltonDump = ({ isWasmLoading }) => {
  const [boltonValues, setBoltonValues] = useState(null);

  useEffect(() => {
    if (!isWasmLoading) setBoltonValues(getBoltonAnalysisValues());
  }, [isWasmLoading]);

  if (!boltonValues) {
    return (
      <div className="wasm-bolton-loading">
        <i className="fa fa-spinner fa-spin" aria-hidden="true" />
      </div>
    );
  }

  return <WasmBoltonTable boltonValues={boltonValues} />;
};

WasmBoltonDump.propTypes = {
  isWasmLoading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isWasmLoading: getWasmLoading(state),
  };
};

export const WasmBolton = connect(mapStateToProps)(WasmBoltonDump);
