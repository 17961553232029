import React from 'react';
import CheckBox from '../checkbox/checkbox';
import './ipr_teech_chart.scss';
import { v4 as uuidv4 } from 'uuid';

const TEETH = [
  ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'],
  ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'],
  ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'],
  ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'],
];

const TEETH_2_UNIVERSAL = {
  'UR8': 1,'UR7': 2,'UR6': 3,'UR5': 4,'UR4': 5, 'UR3': 6, 'UR2': 7, 'UR1': 8,
  'UL1': 9,'UL2': 10,'UL3': 11,'UL4': 12,'UL5': 13, 'UL6': 14, 'UL7': 15, 'UL8': 16,
  'LR8': 32,'LR7': 31,'LR6': 30,'LR5': 29,'LR4': 28, 'LR3': 27, 'LR2': 26, 'LR1': 25,
  'LL1': 24,'LL2': 23,'LL3': 22,'LL4': 21,'LL5': 20, 'LL6': 19, 'LL7': 18, 'LL8': 17,
};

let count = 1;

/**
 * Function increasing the globle variable count
 * @function
 */
function addCount() {
  count++;
}

/**
 * Component displaying the ipr chart upper bubble
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart upper bubble
 */
function getUpperBubble(props) {
  var labels = [];
  var label = '';
  var index = count - 2;

  if (props && props.ipr && props.ipr.end && props.ipr.end.max) {
    labels = props.ipr.end.max;
  }
  if (labels !== [] && labels[index] && labels[index][2]) {
    label = labels[index][2];
  }
  if (count > 1 && count < 17 && label !== '') {
    return (
      <div className={index === 7 || index === 24 ? 'ipr_teeth_chart_bubble left_margin_offset' : 'ipr_teeth_chart_bubble'}>
        <CheckBox
          id={index}
          className=" ipr_teeth_chart_checkbox left_margin_offset"
          label={label}
          label_className="ipr_teeth_chart_checkbox_label"
          toggleChange={() => props.onSelectionChange(index)}
          isChecked={props.ipr_checked ? props.ipr_checked.includes(index.toString()) : false}
        />

        <div className="vl"></div>
      </div>
    );
  }
}

/**
 * Component displaying the ipr chart lower bubble
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart lower bubble
 */
function getLowerBubble(props) {
  var labels = [];
  var label = '';
  var index = count - 2;

  if (props && props.ipr && props.ipr.end && props.ipr.end.man) {
    labels = props.ipr.end.man;
  }
  if (labels !== [] && labels[index] && labels[index][2]) {
    label = labels[index][2];
  }
  if (count > 18 && count < 34 && label !== '') {
    return (
      <div className={index === 7 || index === 24 ? 'ipr_teeth_chart_bubble left_margin_offset' : 'ipr_teeth_chart_bubble'}>
        <div className="vl"></div>
        <label className="ipr_teeth_chart_checkbox_label" htmlFor={index}>
          {' '}
          {label}
        </label>
        <CheckBox
          disabled={props.disabledEdit}
          id={index}
          className=" ipr_teeth_chart_checkbox left_margin_offset"
          toggleChange={() => props.onSelectionChange(index)}
          isChecked={props.ipr_checked ? props.ipr_checked.includes(index.toString()) : false}
        />
      </div>
    );
  }
}

/**
 * Component displaying the ipr chart note
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart note
 */
function getToothChart(props) {
  let missing_teeth = [];
  if (props && props.ipr && props.ipr.end && props.ipr.end.missing_teeth) {
    missing_teeth = props.ipr.end.missing_teeth;
  }
  count = 1;
  return (
    <div className="ipr_teeth_chart">
      {' '}
      {TEETH.map((arch) => (
        <div className={arch[0] + '_row ipr_teeth_row'} key={uuidv4()}>

          {arch.map((tooth) => (
            <span key={uuidv4()}>
              <div style={{ display: 'inline-block' }} key={uuidv4()}>
                {getUpperBubble(props)}
                <p className={missing_teeth.includes(TEETH_2_UNIVERSAL[tooth]) ? 'ipr_char_tooth ipr_char_tooth_missing' : 'ipr_char_tooth'}>{tooth}</p> {addCount()}
                {getLowerBubble(props)}
              </div>
            </span>
          ))}{' '}
        </div>
      ))}
    </div>
  );
}

/**
 * Component displaying the ipr chart note
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for ipr chart note
 */
function getNotes(props) {
  return (
    <div className="ipr_teeth_chart">
      <div className="input_description p-slim grey-text">Notes</div>
      <textarea className="ipr_notes" disabled={props.disabledEdit} maxLength={999} value={props.ipr_note} onChange={(e) => props.onTextChange(e)}></textarea>
    </div>
  );
}

/**
 * Component displaying the Ipr teeth chart
 * @function
 * @param {Object} props - Object containing information of case
 * @return {JSX} JSX element for Ipr teeth chart
 */
function IprTeethChart(props) {
  return (
    <div>
      {getToothChart(props)} {getNotes(props)}
    </div>
  );
}

export default IprTeethChart;
