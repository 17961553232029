import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ContainerDiv = styled.div`
  height: 8px;
  border-radius: 3px;
  margin-bottom: 0px;
`;

const ProgressDiv = styled.div`
  background-color: #2da4ad;
  border-radius: 3px;
`;

function ProgressBar(props) {
  const { min, max, curr } = props;
  return (
    <ContainerDiv className="progress">
      <ProgressDiv className="progress-bar" role="progressbar" aria-valuemin={min} aria-valuemax={max} aria-valuenow={curr} style={{ width: `${curr}%` }} />
    </ContainerDiv>
  );
}

ProgressBar.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  curr: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  min: 0,
  max: 100,
};

export default ProgressBar;
