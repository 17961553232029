import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRootPath } from '../../common/functions';
import AlertContainer from '../components/container/alert_container';
class Overview extends Component {
  getItemRequestLink() {
    const path = this.props.history.location.pathname;
    const case_id = this.props.id;
    const redirectPath = getRootPath(path);

    return `${redirectPath}/itemrequest?caseid=${case_id}`;
  }

  render() {
    return (
      <form className="case-form-container">
        <>
            <AlertContainer>
              Note: For replacement Smartwires<span className="trademark">&reg;</span> or additional items, please submit an{' '}
              <a className="midnight" href={this.getItemRequestLink()}>
                Item Request
              </a>
            </AlertContainer>
            <h3>Digital Enhancement Overview</h3>
            <p>Please ensure the following has been performed prior to submission:</p>
            <ul>
              {this.props && this.props.questions
                ? this.props.questions.map((arr, ind) => {
                    return <li key={ind}>{arr.question}</li>;
                  })
                : null}
            </ul>
            {!this.props.gen_2 && (
              <React.Fragment>
                <p>
                  <span className="emphasis">Please note:</span> Digital Enhancement will not solve vertical and Class II issues without the use of auxiliaries.
                </p>
                <p>
                  If any of the above have not been performed, the case may not be ready for a Digital Enhancement. For further details, please reference the{' '}
                  <a
                    className="midnight"
                    href="/portal/knowledgebase/gen_1_resources/04%20gen%201%20resources/digital%20enhancement%20guide%20(gen%201)%20sm-023%20rev%20f.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digital Enhancement Guide
                  </a>
                  .
                </p>
              </React.Fragment>
            )}
        </>
      </form>
    );
  }
}

export default withRouter(Overview);
