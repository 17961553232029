import React, { Component } from 'react';

import Button from '../components/buttons/button';

class CaseSubmitted extends Component {
  componentDidMount() {
    if (this.props.refreshInfo) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  render() {
    return (
      <div className="submission-loader-container">
        <p>Thank you for submitting your {this.props.placeholder_text ? this.props.placeholder_text : 'case'} to InBrace.</p>
        {this.props.csr_requested ? (
          <p>A Technology Integration Specialist will contact you shortly regarding your Clinical Support Request.</p>
        ) : (
          <p>You will receive an email to confirm your submission.</p>
        )}

        <Button onClick={this.props.onButtonClick}>Go to Case Details</Button>
      </div>
    );
  }
}

export default CaseSubmitted;
