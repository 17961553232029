import Axios from 'axios';

export const OPEN_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN = 'OPEN_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN';
export const CLOSE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN = 'CLOSE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN';
export const TOGGLE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN = 'TOGGLE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN';
export const SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN = 'SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN';
export const RESTART_SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN = 'SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN';
export const SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_PENDING = 'SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_PENDING';
export const SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_SUCCESS = 'SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_SUCCESS';
export const SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_ERROR = 'SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_ERROR';

export function openFeedbackRatingFormPostSmileDesign() {
  return {
    type: OPEN_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  };
}

export function closeFeedbackRatingFormPostSmileDesign() {
  return {
    type: CLOSE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  };
}

export function toggleFeedbackRatingFormPostSmileDesign() {
  return {
    type: TOGGLE_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  };
}

export function submitFeedbackRatingFormPostSmileDesignPending() {
  return {
    type: SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_PENDING,
  };
}

export function submitFeedbackRatingFormPostSmileDesignSuccess() {
  return {
    type: SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_SUCCESS,
  };
}

export function submitFeedbackRatingFormPostSmileDesignError(error) {
  return {
    type: SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN_ERROR,
    error,
  };
}

export function restartFeedbackRatingFormPostSmileDesign() {
  return {
    type: RESTART_SUBMIT_FEEDBACK_RATING_FORM_POST_SMILE_DESIGN,
  };
}

export function submitFeedbackRatingFormPostSmileDesign(doctorId, data) {
  return (dispatch) => {
    dispatch(submitFeedbackRatingFormPostSmileDesignPending());
    Axios.post(`/apiv3/feedback/${doctorId}`, data)
      .then(() => {
        dispatch(submitFeedbackRatingFormPostSmileDesignSuccess());
        sendFeedbackRatingEmail(doctorId);
      })
      .catch((error) => {
        dispatch(submitFeedbackRatingFormPostSmileDesignError(error));
      });
  };
}

/**
 * Sends support email upon submitting rating/feedback
 * @function
 */
export function sendFeedbackRatingEmail(doctorId) {
  Axios.post(`/api/email/?slug=rating-feedback-submitted&doctorId=${doctorId}&method=standard&provider=${window.location.origin}`);
}
