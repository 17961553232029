/**
 * File: de_submission.js - Contains the logic for submitting a digital
 * enhancenment
 * Copyright: (c) Copyright November 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import onClickOutside from 'react-onclickoutside';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Internal Components
import DeForm2 from './de_form2';
import DeForm3 from './de_form3';
import SubmissionSummary from './de_form3_submission_summary';
import Modal from '../../components/modal/modal';
import NotFound from '../404/not_found';
import Overview from './overview';
import Photos from '../case_submission/photos';
import Radiographs from '../case_submission/radiographs';
import Scans from '../case_submission/scans';
import ShippingInfo from '../case_submission/shipping_info';
import Stepper from '../components/stepper';
import SubmissionConfirmation from './submission_confirmation';
import SubmitterLoader from '../case_submission/submitting_loader';
import Terms from '../case_submission/submit';
// Internal Functions
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../common/storage';
import {
  getRootPath,
  removeCaseIdInitialNumber,
  setTokenHeader,
  textFieldLimited,
  getNextActiveAddressId,
  isActiveAddressById,
  removeEmoji,
  removeOpposingTeeth,
} from '../../common/functions';
import { hasTwoScansHelper, getIteroInitialState, WithIteroScansSelection, getMappedLicenseNumbersHelper } from '../../common/itero_utils';
import {
  defaultTeethReference as teethReference,
  isBracketTeethValid as isBracketTeethValidHelper,
  isSetupInstructionValid as isSetupInstructionValidHelper,
} from '../../common/tx_dx_form_utils';
import { getCaseIdFromSubmissionRoute } from '../../common/route';
import { isValidDate, makePresets } from '../../common/helpers';
import { getTempLink } from '../../common/dropbox';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';
import { isDateInBetween } from '../../common/date';

// Context
import { UserPermissionsContext } from '../../context/user_permission';
import { userHasPermission } from '../../common/permission';

import * as nomenclature from '../../common/nomenclature';
import CircleLoader from '../../components/loader/circle_loader';
import CardContainer from '../components/container/card_container';
import Button from '../components/buttons/button';
import ErrorMessage from '../components/container/error_message';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import {
  updateCaseIteroFiles,
  fetchIteroScans,
  addIteroPhotosToDE,
  deleteIteroPhotosFromDE,
  fetchIteroPhotos,
} from '../../redux/actions/ipp/itero/itero_files';
import { getAddIteroPhotosToDEPending, getDeleteIteroPhotosFromDEPending, getFetchIteroPhotosPending } from '../../redux/reducers/ipp/itero/itero_files';
import { fetchLicenseNumbers } from '../../redux/actions/ipp/itero/itero_license_numbers';
import { getLicenseNumbers } from '../../redux/reducers/ipp/itero/itero_license_numbers';
import { buildRecordStates } from '../../redux/actions/record_viewer/record_viewer';
// Modals
import EmptyFileErrorModal from '../../components/modal/empty_file';

/**
 * This component is used to allow a user to submit a digital enhancement
 * @component
 * @alias DoctorDigitalEnhancementSubmission
 * @category IPP
 */
class DigitalEnhancement extends WithIteroScansSelection {
  static contextType = UserPermissionsContext;

  constructor(props) {
    super(props);

    // Stepper Color Decipher
    // 0 - Grey (disable, 'empty')
    // 1 - Green (completed)
    // 2 - light-blue (available)
    // 3 - dark-blue (active)

    // 1 - Blue
    // 2 - White

    this.minPreset = 5;
    this.maxPreset = 7;
    this.presets = makePresets(this.minPreset, this.maxPreset);

    this.state = {
      id: '',
      error: false,
      doctorId: '',
      dsoDoctorId: '',
      doctorfirstName: '',
      doctorlastName: '',
      doctorRole: '',
      doctorAccountLinkId: '',
      digital_id: '',
      name: 'Overview',
      stepper: [1, 2, 2, 1, 2, 2, 2],
      active: 0,
      component: <Overview />,
      buttons: (
        <Button data-position={'next'} data-description={'Overview'} onClick={this.onButtonClick}>
          Next
        </Button>
      ),
      showCancelCase: false,
      hide: false,
      showEmptyFileModal: false,

      //Upload in progress
      photos_uploading: [],
      radiographs_uploading: [],
      scans_uploading: [],

      // iTero integration - initial state
      ...getIteroInitialState({ iteroFileCaseType: 'draft_de', shouldFetchIteroPhotos: false, shouldAllowToRemoveAllIteroPhotos: true }),

      //Doctors Info
      address: '',
      addresses: [],
      //Refinement Form
      scanDate: '',
      reason: '',
      upperSmartWire: '',
      lowerSmartWire: '',
      setup: '',
      restrictions: '',
      restrictionsTeeth: [],
      ipr: '',
      iprDialog: '',
      residual: '',
      upper: '',
      lower: '',
      //Old stuff below
      showUploadSubmitted: false,
      photoUpload: [],
      xrayUpload: [],
      scanUpload: [],
      message: '',
      patient: {},
      warning: false,
      warning_title: 'Incomplete Fields',
      allow_error_msg: true,
      loading: true,
      showSaveModal: false,

      form: 'DE3.0',

      //New Stuff DE2.0
      overview_questions: [
        {
          question: 'All prescribed IPR has been performed.',
          value: false,
        },
        {
          question: 'All required bracket repositioning/bonding has been performed.',
          value: false,
        },
        {
          // question: 'The .016 Stage II Smartwire(s) are fully engaged.',
          question: `${nomenclature.SMARTWIRE_2} and ${nomenclature.TWOA} Locking Loops are engaged in all brackets.`,
          value: false,
        },
        {
          question: 'All bite turbos have been removed.',
          value: false,
        },
        {
          question: 'There has been no observed tooth movement for the last 4-8 weeks.',
          value: false,
        },
      ],
      gen_2_overview_questions: [
        {
          question: 'All required bracket repositioning/bonding has been performed.',
          value: false,
        },
        {
          question: 'All prescribed IPR has been performed.',
          value: false,
        },
        {
          question: 'All necessary inter-arch mechanics have been completed or is near completion.',
          value: false,
        },
        {
          question: 'All bite turbos have been removed or reduced sufficiently for the teeth to be in occlusion.',
          value: false,
        },
      ],
      // Seperated out for performance reason
      de2_reason: '',
      de2_initial_date: '',
      de2_intra_date: '',
      de2_upper_smartwire: 'none',
      de2_upper_smartwire_date: '',
      de2_lower_smartwire: 'none',
      de2_lower_smartwire_date: '',
      de2_arch_to_treat: 'both',
      de2_arch_opposing_treat: '',
      de2_setup: 'same',
      de2_setup_instruction: '',
      de2_setup_additional_instruction: 'yes',
      de2_bracket_instruction: '',
      de2_teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      de2_bracket_remove: [],
      de2_bracket_add: [],
      de2_ipr: 'allow',
      de2_ipr_instruction: '',
      de2_spacing: 'close',
      de2_spacing_upper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      de2_spacing_lower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      de2_spacing_instruction: '',
      de2_special_instruction: '',
      de2_prev_case_info: {
        exist: false,
        case_id: '',
        arch_to_treat: 'both',
        teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      },
      de_hold: false,
      caselist_filter: '',
      de2_initial_date_warning: false,
      de2_intra_date_warning: false,
      de2_upper_smartwire_date_warning: false,
      de2_lower_smartwire_date_warning: false,
      gen_2: false,

      // DE3.0 state
      de3_reason: '',
      de3_next_appointment_date: '',
      de3_next_appointment_date_warning: false,
      de3_intra_date: '',
      de3_intra_date_warning: false,
      de3_upper_smartwire: 'none',
      de3_upper_smartwire_date: '',
      de3_upper_smartwire_date_warning: false,
      de3_lower_smartwire: 'none',
      de3_lower_smartwire_date: '',
      de3_lower_smartwire_date_warning: false,
      de3_arch_to_treat: 'both',
      de3_arch_opposing_treat: 'current',
      de3_upper_arch_opposing_treat: 'current',
      de3_lower_arch_opposing_treat: 'current',
      de3_prev_case_info: {
        exist: false,
        case_id: '',
        arch_to_treat: 'both',
        bracket_teeth: [],
        missing_teeth: [],
        extract_teeth: [],
        teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      },
      de3_setup: 'accept_current_improve_detailing',
      de3_setup_instruction: '',
      de3_setup_additional_instruction: 'yes',
      de3_bracket_teeth: [],
      de3_missing_teeth: [],
      de3_extract_teeth: [],
      de3_teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      de3_anterior_posterior_r: 'current',
      de3_anterior_posterior_l: 'current',
      de3_anterior_posterior_corrections: {
        class_II_or_III_correction_simulation: false,
        posterior_IPR: false,
      },
      de3_anterior_posterior_notes: '',
      de3_midlines: 'show_resulting_after_aligment',
      de3_auxiliaries: 'No',
      de3_auxiliaries_notes: '',
    };

    this.onStepperClick.bind(this);

    this.isStepperDone.bind(this);
    this.updateStepperState.bind(this);

    this.onUpload.bind(this);
    this.onRemove.bind(this);

    this.onInputChange_de2.bind(this);
    this.onIncompleteSave.bind(this);

    this.isDEFormComplete.bind(this);
    this.isDE2FormComplete.bind(this);
    this.isDE3FormComplete.bind(this);

    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);

    this.urlContainsWord = this.urlContainsWord.bind(this);
    this.photos_upload = this.photos_upload.bind(this);
    this.radiographs_upload = this.radiographs_upload.bind(this);
    this.scans_upload = this.scans_upload.bind(this);
    this.hide_warning = this.hide_warning.bind(this);
    this.show_warning = this.show_warning.bind(this);

    this.isUploadInProgress = this.isUploadInProgress.bind(this);
    this.showMoreTwoScansWarning = this.showMoreTwoScansWarning.bind(this);

    this.onOverviewCheckboxClick = this.onOverviewCheckboxClick.bind(this);

    this.getDeHold = this.getDeHold.bind(this);
  }

  /**
   * Redirects the user back to the case list.
   *
   * If there are any filter that were applied before, it will redirect the page
   * with the filter.
   * @function
   */
  onRedirectManageCases = () => {
    let that = this;
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let caselist_filter = this.state.caselist_filter ? this.state.caselist_filter : '';
    caselist_filter = caselist_filter.replace(/\?search=\w+/, '');

    if (redirectPath) {
      this.props.history.push(redirectPath + caselist_filter);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}`);
        }
      });
    }
  };

  onRedirecCaseDetail = (latest_case_id = '') => {
    let path = this.props.history.location.pathname;

    if (latest_case_id) {
      this.props.history.push(`/portal/${this.state.doctorId}/case/${latest_case_id}`);
    } else if (this.state.digital_id) {
      this.props.history.push(`/portal/${this.state.doctorId}/case/${this.state.digital_id}`);
    } else if (this.state.id) {
      this.props.history.push(`/portal/${this.state.doctorId}/case/${this.state.id}`);
    } else {
      let redirectPath = getRootPath(path);
      let that = this;
      if (redirectPath) {
        this.props.history.push(redirectPath);
      } else {
        Axios.get('/apiv3/doctor').then(function (result) {
          if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
            that.props.history.push(`/portal/${result.data.doctor[0].id}`);
          }
        });
      }
    }
  };

  /**
   * Set 0 data file error state
   */
  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  /**
   * Remove 0 data file error state
   */
  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };

  /**
   * iTero integration
   *
   * Reloads the current component
   */
  reloadCurrentComponent = () => {
    let component = this.state.component;
    switch (this.state.active) {
      case this.getActivePositionByStepperName('Overview'):
        component = this.getComponentByStepperName('Overview');
        break;
      case this.getActivePositionByStepperName('Impressions'):
        component = this.getComponentByStepperName('Impressions');
        break;
      case this.getActivePositionByStepperName('Photos'):
        component = this.getComponentByStepperName('Photos');
        break;
      case this.getActivePositionByStepperName('X-Rays'):
        component = this.getComponentByStepperName('X-Rays');
        break;
      case this.getActivePositionByStepperName('Refinement Request Form'):
        component = this.getComponentByStepperName('Refinement Request Form');
        break;
      case this.getActivePositionByStepperName('Doctor Information'):
        component = this.getComponentByStepperName('Doctor Information');
        break;
      case this.getActivePositionByStepperName('Submit'):
        component = this.getComponentByStepperName('Submit');
        break;
      default:
        break;
    }
    this.setState({
      component: component,
    });
  };

  /**
   * iTero integration
   *
   * Update photo stepper state
   * @param {Array} photoUpload - photo upload array
   * @param {Array} stepper - stepper array
   * @returns {Array} new stepper array
   */
  updatePhotoStepperState = (photoUpload, stepper) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    const newStepper = [...stepper] || [...this.state.stepper];
    if (this.isPhotoUploadLengthValid(currentPhotoUpload)) {
      newStepper[this.getActivePositionByStepperName('Photos')] = 1;
    } else {
      newStepper[this.getActivePositionByStepperName('Photos')] = 2;
    }
    return newStepper;
  };

  /**
   * iTero integration
   *
   * Get case id for iTero scans
   */
  getCaseIdForIteroScans = () => {
    return this.state.digital_id;
  };

  /**
   * iTero integration
   *
   * Get state key for iTero photos
   */
  getIteroPhotosStateKey() {
    return 'photoUpload';
  }

  /**
   * iTero integration
   *
   * Helper that returns true if should build itero record viewer
   * @function
   * @returns {Boolean} true if should build itero record viewer
   * @returns {Boolean} false if should not build itero record viewer
   */
  shouldBuildIteroRecordViewer = () => {
    return this.state.active === this.getActivePositionByStepperName('Impressions') && this.state.uploadMethod === 'itero';
  };

  /**
   * iTero integration
   *
   * On Selected iTero files change
   */
  onSelectedIteroScanIdsChange = () => {
    this.updateCaseIteroFilesHelper();

    // update stepper state
    this.setState((prevState) => {
      let newStepper = prevState.stepper;

      if (this.state.selectedIteroScanIds.length === 2) {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
      } else {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
      }

      return { stepper: newStepper };
    });

    // clear warning
    this.onFocus_clearWarning();
  };

  /**
   * iTero integration
   *
   * Get uploaded photos without itero photos
   */
  getUploadedPhotosWithouthIteroPhotos = (photoUpload) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    return currentPhotoUpload.filter((file) => !file.itero_id);
  };

  /**
   * iTero integration
   *
   * Handles the change event of the upload method dropdown.
   * If there is an existing case ID, it calls onIncompleteSave() before proceeding.
   * Updates the stepper state based on the selected upload method and the number of uploaded photos.
   * If the upload method is 'manual', it updates the stepper based on the number of impressions.
   * If the upload method is 'itero', it updates the stepper based on the number of selected Itero scan IDs and photos.
   * If shouldFetchIteroPhotos is true, it adds the selected Itero photo records to the photoUpload array.
   * Calls updatePhotoStepperState() to update the photo stepper state based on the photoUpload array.
   * @function
   * @returns {void}
   */
  onUploadMethodChange = () => {
    if (this.state.digital_id) {
      this.onIncompleteSave();
    }

    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      let newStepper = prevState.stepper;
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      let photoUpload = filteredPrevStatePhotoUpload;

      if (prevState.uploadMethod === 'manual') {
        if (prevState.scanUpload.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
      } else if (prevState.uploadMethod === 'itero') {
        if (prevState.selectedIteroScanIds.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
        if (this.state.shouldFetchIteroPhotos) {
          photoUpload = [...photoUpload, ...prevState.selectedIteroPhotoRecords];
        }
      }

      newStepper = this.updatePhotoStepperState(photoUpload, newStepper);

      return {
        stepper: newStepper,
        [photoUploadStateKey]: photoUpload,
      };
    });
  };

  /**
   * iTero integration
   *
   * On show already uploaded scans change
   */
  onShowAlreadyUploadedScansChange = () => {
    if (this.state.digital_id) {
      this.onIncompleteSave();
    }
  };

  /**
   * iTero integration
   *
   * On should fetch itero photos change
   */
  onShouldFetchIteroPhotosChange = () => {
    if (this.state.digital_id) {
      this.onIncompleteSave();
    }

    if (this.state.shouldFetchIteroPhotos && this.state.selectedIteroId) {
      if (this.hasIteroPhotos()) {
        const oldIteroId = this.getCurrentUploadedPhotosIteroId();
        if (oldIteroId !== this.state.selectedIteroId) {
          this.updateIteroPhotos(oldIteroId, this.state.selectedIteroId);
        }
      } else {
        this.fetchIteroPhotos(this.state.selectedIteroId);
      }
    }
  };

  handleClickOutside = (evt) => {
    // ..handling code goes here...
    this.onIncompleteSave(this.convertStepperName('', ''));
  };

  componentDidUpdate(prevProps, prevState) {
    // iTero integration - component with iTero integration did update helper
    this.withIteroScansSelectionComponentDidUpdate(prevProps, prevState);

    // iTero integration - show already uploaded scans change
    if (this.state.showAlreadyUploadedScans !== prevState.showAlreadyUploadedScans) {
      this.onShowAlreadyUploadedScansChange();
    }

    // iTero integration - reload current component when itero state changes
    const stateThatTriggersScansReload = Object.keys(getIteroInitialState());
    const propsThatTriggersScansReload = ['licenseNumbers', 'isFetchIteroPhotosPending', 'isDeleteIteroPhotosFromDEPending', 'isAddIteroPhotosToDEPending'];
    stateThatTriggersScansReload.forEach((stateKey) => {
      if (this.state[stateKey] !== prevState[stateKey]) {
        this.reloadCurrentComponent();
      }
    });
    propsThatTriggersScansReload.forEach((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.reloadCurrentComponent();
      }
    });

    // iTero integration - on active change
    if (this.state.active !== prevState.active) {
      this.buildIteroRecords();
    }

    if (this.state.photoUpload !== prevState.photoUpload || this.state.xrayUpload !== prevState.xrayUpload || this.state.scanUpload !== prevState.scanUpload) {
      this.reloadCurrentComponent();
    }
  }

  /**
   * iTero integration
   *
   * function to check if the itero photos are loading
   * @function
   * @return {Boolean} Returns true if itero photos are loading
   */
  isLoadingIteroPhotos = () => {
    if (!this.state.shouldFetchIteroPhotos) {
      return false;
    }
    return this.props.isFetchIteroPhotosPending || this.props.isDeleteIteroPhotosFromDEPending || this.props.isAddIteroPhotosToDEPending;
  };

  /**
   * iTero integration
   *
   * function to check if itero photos was loaded
   * @function
   * @return {Boolean} Returns true if itero photos was loaded
   */
  hasIteroPhotos = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    return this.state[photoUploadStateKey].some((file) => file.itero_id);
  };

  /**
   * iTero integration
   *
   * function to check if user is allowed to remove all itero photos
   * @function
   * @param {Object} ctx - context
   * @return {Boolean} Returns true if user is allowed to remove all itero photos
   */
  shouldShowRemoveIteroPhotos = () => {
    return this.state.isEnrolledToiTeroPhotosIntegration && this.state.shouldAllowToRemoveAllIteroPhotos && this.hasIteroPhotos();
  };

  /**
   * iTero integration
   *
   * Gets the current uploaded photos iteroid
   * @function
   * @return {String} Returns the current uploaded photos iteroid
   * @return {null} Returns null if there are no itero photos
   */
  getCurrentUploadedPhotosIteroId = () => {
    if (this.hasIteroPhotos()) {
      const firstUploadedIteroPhoto = this.state[this.getIteroPhotosStateKey()].filter((file) => file.itero_id)[0];
      return firstUploadedIteroPhoto.itero_id;
    }
    return null;
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  removeAllIteroPhotosFromState = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      const newStepper = this.updatePhotoStepperState(filteredPrevStatePhotoUpload, prevState.stepper);
      return {
        warning: false,
        [photoUploadStateKey]: filteredPrevStatePhotoUpload,
        selectedIteroPhotoRecords: [],
        stepper: newStepper,
      };
    });
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  handleRemoveIteroPhotosClick = () => {
    this.handleShouldFetchIteroPhotosChange(false);
    this.removeAllIteroPhotosFromState();
    this.props.deleteIteroPhotosFromDE(
      {
        digitalId: this.state.digital_id,
        iteroId: this.getCurrentUploadedPhotosIteroId(),
      },
      {
        onSuccess: () => {
          this.reloadCurrentComponent();
        },
      }
    );
  };

  /**
   * iTero integration
   *
   * function to check if the itero photos are being deleted
   * @function
   * @return {Boolean} Returns true if itero photos are being deleted
   */
  isDeletingIteroPhotos = () => {
    return this.props.isDeleteIteroPhotosFromDEPending;
  };

  componentDidMount() {
    const isEnrolledToVersion3 = this.context.doctor_program_enrollment.includes('dx/tx_plan_3.0');
    const isEnrolledToiTeroIntegration = this.context.doctor_program_enrollment.includes('itero_integration');
    const isEnrolledToiTeroPhotosIntegration = this.context.doctor_program_enrollment.includes('itero_photos_integration');
    this.setState({
      isEnrolledToiTeroIntegration,
      isEnrolledToiTeroPhotosIntegration,
      uploadMethod: isEnrolledToiTeroIntegration ? 'itero' : this.state.uploadMethod,
    });
    this.withIteroSelectionComponentDidMount();

    if (!this.props.fetchIteroPhotos) {
      throw new Error('fetchIteroPhotos redux action method is not in props');
    }
    if (!this.props.addIteroPhotosToDE) {
      throw new Error('addIteroPhotosToDE redux action method is not in props');
    }
    if (!this.props.deleteIteroPhotosFromDE) {
      throw new Error('deleteIteroPhotosFromDE redux action method is not in props');
    }

    let that = this;
    setTokenHeader();

    let caseid = null;
    if (that.props.history && that.props.history && that.props.history.location.search) {
      if (that.props.history.location.search.includes('doctorid')) {
        caseid = that.props.history.location.search.substring(
          that.props.history.location.search.indexOf('?caseid=') + 8,
          that.props.history.location.search.indexOf('&')
        );
        that.setState({
          dsoDoctorId: that.props.history.location.search.split('&doctorid=')[1],
        });
      } else {
        caseid = that.props.history.location.search.replace('?caseid=', '');
      }

      that.setState({
        caselist_filter:
          that.props.history.location && that.props.history.location.state && that.props.history.location.state.caselist_filter
            ? that.props.history.location.state.caselist_filter
            : '',
      });
    }

    //Load Photos from case
    let validurl = false;
    let loadedState = {};

    Axios.get('/apiv3/doctor')
      .then(function (result) {
        let doctor = result.data.doctor[0];
        let rootPath = getRootPath(that.props.history.location.pathname);
        let routeId = rootPath.match(/portal\/(\w+)/);

        if ((doctor.role === 'Doctor' && routeId && doctor.id.toString() !== routeId[1]) || routeId === null) {
          that.setState({
            error: true,
          });
          return;
        }

        let getAddressId = '';

        if (doctor.role !== 'Doctor') {
          getAddressId = `?id=${routeId[1]}`;
        }

        that.setState({
          role: doctor.role,
        });

        return { id: routeId[1], role: doctor.role, addressId: getAddressId, doctor: doctor };
      })
      .then(function (res) {
        if (res) {
          const doctorId = res.id;
          Axios.get(`/apiv3/doctor/${doctorId}/profile`).then(function (doctorDetails) {
            that.setState({
              doctorRole: doctorDetails.data.doctor_profile[0].role,
              doctorAccountLinkId: doctorDetails.data.doctor_profile[0].account_link_id,
            });

            let licenseNumbers = [];
            let fetchedIteroScans = [];
            let fetchedIteroScansNextUrlCursor = '';
            that.props.fetchLicenseNumbers(doctorId, {
              onSuccess: (_licenseNumbers) => {
                licenseNumbers = _licenseNumbers;
                that.props
                  .fetchIteroScans({ licenseNumbers: getMappedLicenseNumbersHelper(licenseNumbers) })
                  .then((data) => {
                    if (data.next) {
                      const fetchedIteroScansNextUrl = new URL(data.next);
                      fetchedIteroScansNextUrlCursor = fetchedIteroScansNextUrl.searchParams.get('cursor');
                    }
                    fetchedIteroScans = data.results;
                  })
                  .finally(() => {
                    Axios.get(`/api/casedetails/?id=${caseid}&digital=true`)
                      .then(async function (result) {
                        if (result.data.data && result.data.data.caseDetail && result.data.data.caseDetail[0]) {
                          // Loading the data on click
                          validurl = true;

                          loadedState = {
                            id: result.data.data.caseDetail[0].case_id,
                            address: result.data.data.caseDetail[0].shipping_address_id,
                            patient: result.data.data.patient[0],
                            stepper: that.state.stepper,
                            gen_2: result.data.data.gen_2,
                            fetchedIteroScans,
                            fetchedIteroScansNextUrlCursor,
                          };

                          if (!result.data.data.gen_2) {
                            loadedState['form'] = 'DE2.0';
                          }

                          if (
                            result.data.data.caseDetail[0].case_status !== 'Case Shipped' &&
                            result.data.data.caseDetail[0].case_status !== 'Cancelled' &&
                            result.data.data.caseDetail[0].case_id.indexOf('-') >= 0
                          ) {
                            const isD3Version = isEnrolledToVersion3 && result.data.data.gen_2;

                            const currentCasePlan = result.data.data.plans.filter((plan) => {
                              return plan.case_id === result.data.data.caseDetail[0].case_id;
                            })[0];

                            const currentCasePlanTxData = JSON.parse(currentCasePlan.txplan_data);

                            if (
                              ((currentCasePlanTxData && !_.isEmpty(currentCasePlanTxData)) || (result.data.data.files && result.data.data.files.length > 0)) &&
                              result.data.data.caseDetail[0].case_status === 'Draft Digital Enhancement'
                            ) {
                              if (isD3Version) {
                                // DE3.0 load state
                                loadedState['form'] = 'DE3.0';
                                loadedState.stepper = that.state.stepper;

                                if (
                                  currentCasePlanTxData.de3_reason &&
                                  currentCasePlanTxData.de3_reason !== '' &&
                                  currentCasePlanTxData.de3_next_appointment &&
                                  currentCasePlanTxData.de3_next_appointment !== '' &&
                                  currentCasePlanTxData.de3_intra_date &&
                                  currentCasePlanTxData.de3_intra_date !== '' &&
                                  isValidDate(currentCasePlanTxData.de3_next_appointment) &&
                                  isValidDate(currentCasePlanTxData.de3_intra_date) &&
                                  isValidDate(currentCasePlanTxData.de3_upper_smartwire_date) &&
                                  isValidDate(currentCasePlanTxData.de3_lower_smartwire_date)
                                ) {
                                  loadedState.stepper[that.getActivePositionByStepperName('Refinement Request Form')] = 1;
                                }

                                if (!_.isEmpty(currentCasePlanTxData)) {
                                  //load in the data
                                  for (let data in currentCasePlanTxData) {
                                    // Loading array type fields
                                    if (['de3_bracket_teeth', 'de3_missing_teeth', 'de3_extract_teeth', 'de3_teeth_restriction'].includes(data)) {
                                      loadedState[data] = currentCasePlanTxData[data].split(',').filter((item) => teethReference.All.includes(item));
                                    } else if (['de3_prev_case_info', 'de3_anterior_posterior_corrections'].includes(data)) {
                                      loadedState[data] = JSON.parse(currentCasePlanTxData[data]);
                                    } else if (['de3_arch_opposing_treat'].includes(data)) {
                                      if (currentCasePlanTxData['de3_arch_to_treat'] === 'upper') {
                                        loadedState['de3_upper_arch_opposing_treat'] = currentCasePlanTxData['de3_arch_opposing_treat'];
                                      } else if (currentCasePlanTxData['de3_arch_to_treat'] === 'lower') {
                                        loadedState['de3_lower_arch_opposing_treat'] = currentCasePlanTxData['de3_arch_opposing_treat'];
                                      }
                                      loadedState['de3_arch_opposing_treat'] = currentCasePlanTxData['de3_arch_opposing_treat'];
                                    } else {
                                      loadedState[data] = currentCasePlanTxData[data];
                                    }

                                    if (data === 'de3_next_appointment') {
                                      loadedState['de3_next_appointment_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de3_intra_date') {
                                      loadedState['de3_intra_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de3_upper_smartwire_date') {
                                      loadedState['de3_upper_smartwire_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de3_lower_smartwire_date') {
                                      loadedState['de3_lower_smartwire_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    }
                                  }
                                }
                              } else {
                                if (
                                  (currentCasePlanTxData.scanDate &&
                                    currentCasePlanTxData.scanDate !== '' &&
                                    currentCasePlanTxData.reason &&
                                    currentCasePlanTxData.reason !== '' &&
                                    currentCasePlanTxData.setup &&
                                    currentCasePlanTxData.setup !== '') ||
                                  (currentCasePlanTxData.de2_reason &&
                                    currentCasePlanTxData.de2_reason !== '' &&
                                    currentCasePlanTxData.de2_initial_date &&
                                    currentCasePlanTxData.de2_initial_date !== '' &&
                                    currentCasePlanTxData.de2_intra_date &&
                                    currentCasePlanTxData.de2_intra_date !== '' &&
                                    isValidDate(currentCasePlanTxData.de2_initial_date) &&
                                    isValidDate(currentCasePlanTxData.de2_intra_date) &&
                                    isValidDate(currentCasePlanTxData.de2_upper_smartwire_date) &&
                                    isValidDate(currentCasePlanTxData.de2_lower_smartwire_date))
                                ) {
                                  loadedState.stepper[that.getActivePositionByStepperName('Refinement Request Form')] = 1;
                                }

                                if (!_.isEmpty(currentCasePlanTxData)) {
                                  //load in the data
                                  for (let data in currentCasePlanTxData) {
                                    // Loading array type fields
                                    if (
                                      data === 'restrictionsTeeth' ||
                                      data === 'de2_teeth_restriction' ||
                                      data === 'de2_bracket_remove' ||
                                      data === 'de2_bracket_add' ||
                                      data === 'de2_spacing_upper' ||
                                      data === 'de2_spacing_lower'
                                    ) {
                                      loadedState[data] = currentCasePlanTxData[data].split(',');
                                    } else if (data === 'de2_prev_case_info') {
                                      //Do something here for this part of the code
                                      loadedState[data] = JSON.parse(currentCasePlanTxData[data]);
                                    } else {
                                      loadedState[data] = currentCasePlanTxData[data];
                                    }

                                    if (data === 'de2_initial_date') {
                                      loadedState['de2_initial_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de2_intra_date') {
                                      loadedState['de2_intra_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de2_upper_smartwire_date') {
                                      loadedState['de2_upper_smartwire_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    } else if (data === 'de2_lower_smartwire_date') {
                                      loadedState['de2_lower_smartwire_date_warning'] = !isValidDate(currentCasePlanTxData[data]);
                                    }
                                  }
                                }
                              }
                            } else {
                              that.onRedirectManageCases();
                            }

                            if (caseid.indexOf('-R') >= 0 || caseid.indexOf('-DE') >= 0) {
                              loadedState.digital_id = caseid;
                              let photos = [];
                              let xrays = [];
                              let scans = [];

                              if (result.data.data.files && result.data.data.files.length > 0) {
                                result.data.data.files.map(function (file) {
                                  switch (file.file_type) {
                                    case 'upperscans':
                                    case 'lowerscans':
                                    case 'scans':
                                      if (loadedState.digital_id === file.case_id) {
                                        scans.push(file);
                                      }
                                      break;

                                    case 'photos':
                                      if (loadedState.digital_id === file.case_id) {
                                        photos.push(file);
                                      }
                                      break;

                                    case 'xrays':
                                      if (loadedState.digital_id === file.case_id) {
                                        xrays.push(file);
                                      }
                                      break;

                                    default:
                                  }

                                  return '';
                                });
                              }

                              //Load Photos
                              if (photos.length > 0) {
                                loadedState.photoUpload = photos;

                                if (that.isPhotoUploadLengthValid(photos)) {
                                  loadedState.stepper[that.getActivePositionByStepperName('Photos')] = 1;
                                }

                                // iTero integration - load previously selected iteroPhotoRecords
                                loadedState.selectedIteroPhotoRecords = photos.filter((photo) => photo.itero_id);
                              }

                              //Load Xrays
                              if (xrays.length > 0) {
                                loadedState.xrayUpload = xrays;
                                loadedState.stepper[that.getActivePositionByStepperName('X-Rays')] = 1;
                              }

                              //Load Scans
                              if (scans.length > 0) {
                                loadedState.scanUpload = scans;
                              }

                              if (isEnrolledToiTeroIntegration) {
                                // iTero integration - load previously selected iteroFileIds
                                if (result.data.data.draftDeIteroScanIds && result.data.data.draftDeIteroScanIds.length > 0) {
                                  const iteroScansLoadedState = await that.getIteroScansLoadedState({
                                    selectedIteroScanIds: result.data.data.draftDeIteroScanIds,
                                    fetchedIteroScans,
                                    fetchedIteroScansNextUrlCursor,
                                    licenseNumbers,
                                  });
                                  loadedState = { ...loadedState, ...iteroScansLoadedState };
                                }

                                // iTero integration - load iTero data
                                if (result.data.data.file_upload_settings) {
                                  const file_upload_settings = JSON.parse(result.data.data.file_upload_settings);

                                  // iTero integration - load uploadMethod, showAlreadyUploadedScans, shouldFetchIteroPhotos
                                  for (let setting in file_upload_settings) {
                                    if (file_upload_settings[setting] === 'false') {
                                      loadedState[setting] = false;
                                    } else if (file_upload_settings[setting] === 'true') {
                                      loadedState[setting] = true;
                                    } else {
                                      loadedState[setting] = file_upload_settings[setting];
                                    }
                                  }
                                }
                              } else {
                                loadedState.uploadMethod = 'manual';
                              }

                              const isScansLengthValid = that.isScansLengthValid({ state: loadedState });

                              if (isScansLengthValid) {
                                loadedState.stepper[that.getActivePositionByStepperName('Impressions')] = 1;
                              }

                              if (!isD3Version) {
                                if (
                                  (result.data.data.txplan_data.scanDate &&
                                    result.data.data.txplan_data.scanDate !== '' &&
                                    result.data.data.txplan_data.reason &&
                                    result.data.data.txplan_data.reason !== '' &&
                                    result.data.data.txplan_data.setup &&
                                    result.data.data.txplan_data.setup !== '' &&
                                    that.isPhotoUploadLengthValid(photos) &&
                                    isScansLengthValid) ||
                                  (result.data.data.txplan_data.de2_reason &&
                                    result.data.data.txplan_data.de2_reason !== '' &&
                                    result.data.data.txplan_data.de2_initial_date &&
                                    result.data.data.txplan_data.de2_initial_date !== '' &&
                                    result.data.data.txplan_data.de2_intra_date &&
                                    result.data.data.txplan_data.de2_intra_date !== '' &&
                                    isValidDate(result.data.data.txplan_data.de2_initial_date) &&
                                    isValidDate(result.data.data.txplan_data.de2_intra_date) &&
                                    that.isPhotoUploadLengthValid(photos) &&
                                    isScansLengthValid)
                                ) {
                                  loadedState.stepper[6] = 2;
                                }
                              }
                            }
                          } else if (['Case Shipped', 'Cancelled'].includes(result.data.data.caseDetail[0].case_status)) {
                            // Only load for case_status === 'Case Shipped' because de hasn't had any thing filled out so adjust it
                            const current_plan = result.data.data.plans.filter((plan) => {
                              return plan.case_id === result.data.data.caseDetail[0].case_id;
                            });

                            if (isEnrolledToVersion3 && result.data.data.gen_2) {
                              // DE3.0 load state when case shipped
                              loadedState['form'] = 'DE3.0';
                              let prev_case = null;

                              if (result.data.data.cases && result.data.data.cases.length >= 1) {
                                for (let i = 0; i < result.data.data.cases.length; i++) {
                                  if (result.data.data.cases[i].case_status !== 'Cancelled') {
                                    prev_case = result.data.data.cases[i];
                                    break;
                                  }
                                }
                              }

                              if (prev_case) {
                                const prev_plan = result.data.data.plans.filter((plan) => {
                                  return plan.case_id === prev_case.case_id;
                                });
                                if (prev_plan.length) {
                                  const prev_arch = prev_plan[0].txplan_arch.toLowerCase();
                                  const loaded_txplan_data = JSON.parse(prev_plan[0].txplan_data);
                                  const prev_opposing_arch = loaded_txplan_data['opposingArch'];

                                  let bracket_teeth = that.state.de3_bracket_teeth;
                                  let missing_teeth = that.state.de3_missing_teeth;
                                  let extract_teeth = that.state.de3_extract_teeth;
                                  let teeth_restriction = that.state.de3_teeth_restriction;
                                  let arch_to_treat = prev_arch;
                                  let prev_case_info = that.state.de3_prev_case_info;
                                  let prev_extract_teeth = [];

                                  const getTeethArray = (teethKey) => {
                                    const loadedTeethData = loaded_txplan_data[teethKey];
                                    if (!loadedTeethData) {
                                      return [];
                                    }
                                    if (loadedTeethData.length) {
                                      return loadedTeethData.split(',');
                                    }
                                    return [];
                                  };

                                  switch (prev_plan[0].revision_id) {
                                    case 'TX2.0':
                                      prev_extract_teeth = getTeethArray('extractTeeth');
                                      bracket_teeth = getTeethArray('bracketTeeth').filter((tooth) => !prev_extract_teeth.includes(tooth));
                                      missing_teeth = getTeethArray('missingTeeth');
                                      teeth_restriction = getTeethArray('restrictTeeth');
                                      break;
                                    case 'TX3.0':
                                      if (prev_arch !== 'both' && prev_opposing_arch === 'digital') {
                                        arch_to_treat = 'both';
                                      }
                                      prev_extract_teeth = getTeethArray('extractTeeth');
                                      bracket_teeth = getTeethArray('bracketTeeth').filter((tooth) => !prev_extract_teeth.includes(tooth));
                                      missing_teeth = getTeethArray('missingTeeth');
                                      teeth_restriction = getTeethArray('restrictTeeth');
                                      break;
                                    case 'DE2.0':
                                      teeth_restriction = getTeethArray('de2_teeth_restriction');
                                      break;
                                    case 'DE3.0':
                                      bracket_teeth = getTeethArray('de3_bracket_teeth');
                                      missing_teeth = getTeethArray('de3_missing_teeth');
                                      extract_teeth = getTeethArray('de3_extract_teeth');
                                      teeth_restriction = getTeethArray('de3_teeth_restriction');
                                  }

                                  if (!prev_case.historical) {
                                    prev_case_info = {
                                      exist: true,
                                      case_id: prev_case.case_id,
                                      arch_to_treat: prev_arch,
                                      bracket_teeth: bracket_teeth,
                                      missing_teeth: missing_teeth,
                                      extract_teeth: extract_teeth,
                                      teeth_restriction: teeth_restriction,
                                    };
                                  }

                                  loadedState['de3_arch_to_treat'] = arch_to_treat;
                                  loadedState['de3_bracket_teeth'] = bracket_teeth;
                                  loadedState['de3_missing_teeth'] = missing_teeth;
                                  loadedState['de3_extract_teeth'] = extract_teeth;
                                  loadedState['de3_teeth_restriction'] = teeth_restriction;
                                  loadedState['de3_prev_case_info'] = prev_case_info;

                                  if (loadedState['de3_arch_to_treat'] !== 'both' && loadedState['de3_arch_to_treat'] !== '') {
                                    loadedState['de3_arch_opposing_treat'] = 'current';
                                    loadedState['de3_upper_arch_opposing_treat'] = 'current';
                                    loadedState['de3_lower_arch_opposing_treat'] = 'current';
                                  }
                                }
                              }
                            } else {
                              const prev_case = result.data.data.cases && result.data.data.cases.length >= 1 ? result.data.data.cases[0] : '';

                              if (prev_case) {
                                const prev_plan = result.data.data.plans.filter((plan) => {
                                  return plan.case_id === prev_case.case_id;
                                });

                                const loaded_txplan_data = JSON.parse(prev_plan[0].txplan_data);
                                const bracket =
                                  current_plan[0].revision_id === 'TX2.0'
                                    ? loaded_txplan_data['restrictTeeth'].split(',')
                                    : current_plan[0].revision_id === 'DE2.0'
                                    ? loaded_txplan_data['de2_teeth_restriction'].split(',')
                                    : [];

                                if (prev_case && !prev_case.historical) {
                                  let prev_case_info = {
                                    exist: true,
                                    case_id: prev_case.case_id,
                                    arch_to_treat: prev_plan[0].txplan_arch.toLowerCase(),
                                    teeth_restriction: bracket.slice(),
                                  };

                                  loadedState['de2_arch_to_treat'] = prev_plan[0].txplan_arch.toLowerCase();
                                  loadedState['de2_teeth_restriction'] = that.state.de2_teeth_restriction;
                                  loadedState['de2_prev_case_info'] = prev_case_info;

                                  if (loadedState['de2_arch_to_treat'] !== 'both' && loadedState['de2_arch_to_treat'] !== '') {
                                    loadedState['de2_arch_opposing_treat'] = 'current';
                                  }
                                }
                              }
                            }
                          }

                          that.setState(loadedState);

                          return loadedState;
                        } else {
                          that.freshRoute();
                        }
                      })
                      .then(function (loadedState) {
                        const address_id = that.state.doctorRole.includes('DSO') ? `?id=${that.state.doctorAccountLinkId}` : res.addressId;

                        Axios.get(`/api/practiceIdAddress/${address_id}`).then(function (result) {
                          if (result.data.data && result.data.data.length > 0 && that.state.active === 0) {
                            const addresses = result.data.data;
                            let address = that.state.address;
                            let active = 0;
                            const stepper = that.state.stepper;

                            if (that.state.address === '' || !isActiveAddressById(addresses, that.state.address)) {
                              address = getNextActiveAddressId(addresses) ? getNextActiveAddressId(addresses) : result.data.data[0].id;
                            }

                            let component = (
                              <Overview
                                id={that.state.id}
                                form={that.state.form}
                                questions={that.state.gen_2 ? that.state.gen_2_overview_questions : that.state.overview_questions}
                                onOverviewCheckboxClick={that.onOverviewCheckboxClick}
                                gen_2={that.state.gen_2}
                              />
                            );

                            let buttons = (
                              <>
                                <Button data-position={'next'} data-description={'Overview'} onClick={that.onButtonClick}>
                                  Next
                                </Button>
                              </>
                            );

                            if (that.props.history.location.pathname.indexOf('overview') >= 0 && loadedState.stepper[0] !== 0) {
                              component = (
                                <Overview
                                  id={that.state.id}
                                  form={that.state.form}
                                  questions={that.state.gen_2 ? that.state.gen_2_overview_questions : that.state.overview_questions}
                                  onOverviewCheckboxClick={that.onOverviewCheckboxClick}
                                  gen_2={that.state.gen_2}
                                />
                              );

                              buttons = (
                                <>
                                  <Button data-position={'next'} data-description={'Overview'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Overview');
                            } else if (that.props.history.location.pathname.indexOf('doctor') >= 0 && loadedState.stepper[5] !== 0) {
                              if (address !== '') {
                                stepper[5] = 1;
                              }

                              component = (
                                <ShippingInfo
                                  onInputChange={that.onInputChange_address}
                                  address={address}
                                  addresses={result.data.data}
                                  onFocus={that.onFocus_clearWarning}
                                  firstName={doctorDetails.data.doctor_profile[0].user__first_name}
                                  lastName={doctorDetails.data.doctor_profile[0].user__last_name}
                                  isDigital="true"
                                />
                              );

                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'Doctor Information'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                  <Button data-position={'next'} data-description={'Doctor Information'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Doctor Information');
                            } else if (that.props.history.location.pathname.indexOf('enhancement') >= 0 && loadedState.stepper[1] !== 0) {
                              if (that.state.form === 'DE2.0') {
                                component = that.getDE2Form({
                                  scanDate: that.state.scanDate,
                                  reason: that.state.reason,
                                  upperSmartWire: that.state.upperSmartWire,
                                  lowerSmartWire: that.state.lowerSmartWire,
                                  setup: that.state.setup,
                                  restrictions: that.state.restrictions,
                                  restrictionsTeeth: that.state.restrictionsTeeth,
                                  ipr: that.state.ipr,
                                  iprDialog: that.state.iprDialog,
                                  residual: that.state.residual,
                                  upper: that.state.upper,
                                  lower: that.state.lower,
                                  de2_reason: that.state.de2_reason,
                                  de2_initial_date: that.state.de2_initial_date,
                                  de2_intra_date: that.state.de2_intra_date,
                                  de2_upper_smartwire: that.state.de2_upper_smartwire,
                                  de2_upper_smartwire_date: that.state.de2_upper_smartwire_date,
                                  de2_lower_smartwire: that.state.de2_lower_smartwire,
                                  de2_lower_smartwire_date: that.state.de2_lower_smartwire_date,
                                  de2_arch_to_treat: that.state.de2_arch_to_treat,
                                  de2_arch_opposing_treat: that.state.de2_arch_opposing_treat,
                                  de2_setup: that.state.de2_setup,
                                  de2_setup_instruction: that.state.de2_setup_instruction,
                                  de2_setup_additional_instruction: that.state.de2_setup_additional_instruction,
                                  de2_bracket_instruction: that.state.de2_bracket_instruction,
                                  de2_teeth_restriction: that.state.de2_teeth_restriction,
                                  de2_bracket_remove: that.state.de2_bracket_remove,
                                  de2_bracket_add: that.state.de2_bracket_add,
                                  de2_ipr: that.state.de2_ipr,
                                  de2_ipr_instruction: that.state.de2_ipr_instruction,
                                  de2_spacing: that.state.de2_spacing,
                                  de2_spacing_upper: that.state.de2_spacing_upper,
                                  de2_spacing_lower: that.state.de2_spacing_lower,
                                  de2_spacing_instruction: that.state.de2_spacing_instruction,
                                  de2_special_instruction: that.state.de2_special_instruction,
                                  de2_prev_case_info: that.state.de2_prev_case_info,
                                  de2_initial_date_warning: that.state.de2_initial_date_warning,
                                  de2_intra_date_warning: that.state.de2_intra_date_warning,
                                });
                              }

                              // DE3.0 set initial component
                              if (that.state.form === 'DE3.0') {
                                component = that.getDE3Form({
                                  de3_reason: that.state.de3_reason,
                                  de3_next_appointment_date: that.state.de3_next_appointment_date,
                                  de3_intra_date: that.state.de3_intra_date,
                                  de3_upper_smartwire: that.state.de3_upper_smartwire,
                                  de3_upper_smartwire_date: that.state.de3_upper_smartwire_date,
                                  de3_lower_smartwire: that.state.de3_lower_smartwire,
                                  de3_lower_smartwire_date: that.state.de3_lower_smartwire_date,
                                  de3_arch_to_treat: that.state.de3_arch_to_treat,
                                  de3_arch_opposing_treat: that.state.de3_arch_opposing_treat,
                                  de3_upper_arch_opposing_treat: that.state.de3_upper_arch_opposing_treat,
                                  de3_lower_arch_opposing_treat: that.state.de3_lower_arch_opposing_treat,
                                  de3_setup: that.state.de3_setup,
                                  de3_setup_instruction: that.state.de3_setup_instruction,
                                  de3_setup_additional_instruction: that.state.de3_setup_additional_instruction,
                                  de3_bracket_teeth: that.state.de3_bracket_teeth,
                                  de3_missing_teeth: that.state.de3_missing_teeth,
                                  de3_extract_teeth: that.state.de3_extract_teeth,
                                  de3_teeth_restriction: that.state.de3_teeth_restriction,
                                  de3_prev_case_info: that.state.de3_prev_case_info,
                                  de3_next_appointment_date_warning: that.state.de3_next_appointment_date_warning,
                                  de3_intra_date_warning: that.state.de3_intra_date_warning,
                                  de3_anterior_posterior_r: that.state.de3_anterior_posterior_r,
                                  de3_anterior_posterior_l: that.state.de3_anterior_posterior_l,
                                  de3_anterior_posterior_corrections: that.state.de3_anterior_posterior_corrections,
                                  de3_anterior_posterior_notes: that.state.de3_anterior_posterior_notes,
                                  de3_midlines: that.state.de3_midlines,
                                  de3_auxiliaries: that.state.de3_auxiliaries,
                                  de3_auxiliaries_notes: that.state.de3_auxiliaries_notes,
                                });

                                if (that.isDE3FormComplete(false, component)) {
                                  stepper[that.getActivePositionByStepperName('Refinement Request Form')] = 1;
                                } else {
                                  stepper[that.getActivePositionByStepperName('Refinement Request Form')] = 0;
                                }
                              }

                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'Refinement Request Form'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                  <Button data-position={'next'} data-description={'Refinement Request Form'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Refinement Request Form');
                            } else if (that.props.history.location.pathname.indexOf('photos') >= 0 && loadedState.stepper[3] !== 0) {
                              component = (
                                <Photos
                                  id={that.state.digital_id}
                                  photoUpload={that.state.photoUpload}
                                  onUpload={that.onUpload}
                                  onRemove={that.onRemove}
                                  onIncompleteSave={that.onIncompleteSave}
                                  location="incomplete"
                                  upload_state={that.photos_upload}
                                  upload_content={that.state.photos_uploading}
                                  show_warning={that.show_warning}
                                  hide_warning={that.hide_warning}
                                  updateRecordState={that.updateRecordState}
                                  isLoadingIteroPhotos={that.isLoadingIteroPhotos()}
                                  isDeletingIteroPhotos={that.isDeletingIteroPhotos()}
                                  showRemoveIteroPhotos={that.shouldShowRemoveIteroPhotos()}
                                  onRemoveIteroPhotosClick={that.handleRemoveIteroPhotosClick}
                                  iteroPhotosDropzoneMessage={that.hasIteroPhotos() ? that.getIteroPhotosDropzoneMessage() : null}
                                />
                              );

                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'Photos'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                  <Button data-position={'next'} data-description={'Photos'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Photos');
                            } else if (that.props.history.location.pathname.indexOf('radiographs') >= 0 && loadedState.stepper[4] !== 0) {
                              component = (
                                <Radiographs
                                  id={that.state.digital_id}
                                  xrayUpload={that.state.xrayUpload}
                                  onUpload={that.onUpload}
                                  onRemove={that.onRemove}
                                  onIncompleteSave={that.onIncompleteSave}
                                  location="incomplete"
                                  upload_state={that.radiographs_upload}
                                  upload_content={that.state.radiographs_uploading}
                                  show_warning={that.show_warning}
                                  hide_warning={that.hide_warning}
                                  updateRecordState={that.updateRecordState}
                                />
                              );

                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'X-Rays'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                  <Button data-position={'next'} data-description={'X-Rays'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('X-Rays');
                            } else if (that.props.history.location.pathname.indexOf('scans') >= 0 && loadedState.stepper[2] !== 0) {
                              component = (
                                <Scans
                                  id={that.state.digital_id}
                                  scanUpload={that.state.scanUpload}
                                  onUpload={that.onUpload}
                                  onRemove={that.onRemove}
                                  onEmptyFileError={that.onEmptyFileError}
                                  location="incomplete"
                                  text="de"
                                  upload_state={that.scans_upload}
                                  upload_content={that.state.scans_uploading}
                                  show_warning={that.show_warning}
                                  hide_warning={that.hide_warning}
                                  // iTero integration - props to be passed to the scans component
                                  uploadMethod={that.state.uploadMethod}
                                  onScansUploadMethodChange={that.handleScansUploadMethodChange}
                                  iteroSelectionProps={that.getIteroSelectionProps(that)}
                                  recordViewerSubtitle={that.getRecordViewerSubtitle(true, that.isLoadingIteroPhotos())}
                                  isLoadingIteroPhotos={that.state.uploadMethod === 'itero' && that.isLoadingIteroPhotos()}
                                />
                              );

                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'Impressions'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                  <Button data-position={'next'} data-description={'Impressions'} onClick={that.onButtonClick}>
                                    Next
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Impressions');
                              that.showMoreTwoScansWarning(that.state.scanUpload);
                            } else if (that.props.history.location.pathname.indexOf('submit') >= 0 && loadedState.stepper[6] !== 0) {
                              component = that.getTermsComponent();
                              buttons = (
                                <>
                                  <Button theme="secondary" data-position={'prev'} data-description={'Submit'} onClick={that.onButtonClick}>
                                    Back
                                  </Button>
                                </>
                              );

                              active = that.getActivePositionByStepperName('Submit');
                            } else {
                              that.appendRoute(caseid, 'overview');
                            }

                            if (address !== '') {
                              stepper[5] = 1;
                            }

                            that.setState({
                              stepper: stepper,
                              active: active,
                              component: component,
                              buttons: buttons,
                              address: address,
                              addresses: result.data.data,
                              doctorfirstName: doctorDetails.data.doctor_profile[0].user__first_name,
                              doctorlastName: doctorDetails.data.doctor_profile[0].user__last_name,
                              doctorId: doctorDetails.data.doctor_profile[0].id,
                              loading: false,
                            });
                          }
                        });

                        if (!validurl) {
                          that.onRedirectManageCases();
                        }
                      })
                      .catch(function (err) {
                        console.error(err);
                        that.onRedirectManageCases();
                      });
                  });
              },
            });
          });
        }
      });
  }

  freshRoute = () => {
    let that = this;

    Axios.get('/apiv3/doctor').then(function (result) {
      if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
        that.props.history.push(`/portal/${result.data.doctor[0].id}`);
      }
    });
  };

  /* Doctors Info */
  onInputChange_address = (event) => {
    const stepper = this.state.stepper;
    if (event.currentTarget.dataset.id !== '') {
      stepper[this.getActivePositionByStepperName('Doctor Information')] = 1;
    }

    this.setState({
      stepper: stepper,
      component: (
        <ShippingInfo
          onInputChange={this.onInputChange_address}
          address={parseInt(event.currentTarget.dataset.id)}
          addresses={this.state.addresses}
          onFocus={this.onFocus_clearWarning}
          firstName={this.state.doctorfirstName}
          lastName={this.state.doctorlastName}
          isDigital="true"
        />
      ),
      address: parseInt(event.currentTarget.dataset.id),
    });
  };

  /**
   * Handles all input changes on the form
   * @function
   * @param {Object} event - The event object on change
   */
  onInputChange_de2 = (event) => {
    let de2_reason = this.state.de2_reason;
    let de2_initial_date = this.state.de2_initial_date;
    let de2_intra_date = this.state.de2_intra_date;
    let de2_upper_smartwire = this.state.de2_upper_smartwire;
    let de2_upper_smartwire_date = this.state.de2_upper_smartwire_date;
    let de2_lower_smartwire = this.state.de2_lower_smartwire;
    let de2_lower_smartwire_date = this.state.de2_lower_smartwire_date;
    let de2_arch_to_treat = this.state.de2_arch_to_treat;
    let de2_arch_opposing_treat = this.state.de2_arch_opposing_treat;
    let de2_setup = this.state.de2_setup;
    let de2_setup_instruction = this.state.de2_setup_instruction;
    let de2_setup_additional_instruction = this.state.de2_setup_additional_instruction;
    let de2_bracket_instruction = this.state.de2_bracket_instruction;
    let de2_teeth_restriction = this.state.de2_teeth_restriction;
    let de2_bracket_remove = this.state.de2_bracket_remove;
    let de2_bracket_add = this.state.de2_bracket_add;
    let de2_ipr = this.state.de2_ipr;
    let de2_ipr_instruction = this.state.de2_ipr_instruction;
    let de2_spacing = this.state.de2_spacing;
    let de2_spacing_upper = this.state.de2_spacing_upper;
    let de2_spacing_lower = this.state.de2_spacing_lower;
    let de2_spacing_instruction = this.state.de2_spacing_instruction;
    let de2_special_instruction = this.state.de2_special_instruction;
    let de2_prev_case_info = this.state.de2_prev_case_info;
    let stepper = this.state.stepper;
    let de2_initial_date_warning = this.state.de2_initial_date_warning;
    let de2_intra_date_warning = this.state.de2_intra_date_warning;
    let de2_upper_smartwire_date_warning = this.state.de2_upper_smartwire_date_warning;
    let de2_lower_smartwire_date_warning = this.state.de2_lower_smartwire_date_warning;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';
    let index = 0;

    switch (event_type) {
      case 'de2_reason':
        de2_reason = removeEmoji(textFieldLimited(event.target.value));

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.state.xrayUpload.length > 0 &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[6] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_initial_date':
        de2_initial_date = event.target.value;
        de2_initial_date_warning = !isValidDate(de2_initial_date);

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.state.xrayUpload.length > 0 &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[6] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
          this.onFocus_clearWarning();
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_intra_date':
        de2_intra_date = event.target.value;
        de2_intra_date_warning = !isValidDate(de2_intra_date);

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.state.xrayUpload.length > 0 &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[6] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
          this.onFocus_clearWarning();
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_upper_smartwire':
        de2_upper_smartwire = event.target.value;

        if (de2_upper_smartwire === 'none') {
          de2_upper_smartwire_date = '';
        }

        break;

      case 'de2_upper_smartwire_date':
        de2_upper_smartwire_date = event.target.value;
        de2_upper_smartwire_date_warning = !isValidDate(de2_upper_smartwire_date);
        if (de2_upper_smartwire_date_warning) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
          this.onFocus_clearWarning();
        }
        break;

      case 'de2_lower_smartwire':
        de2_lower_smartwire = event.target.value;

        if (de2_lower_smartwire === 'none') {
          de2_lower_smartwire_date = '';
        }

        break;

      case 'de2_lower_smartwire_date':
        de2_lower_smartwire_date = event.target.value;
        de2_lower_smartwire_date_warning = !isValidDate(de2_lower_smartwire_date);
        if (de2_lower_smartwire_date_warning) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
          this.onFocus_clearWarning();
        }

        break;

      case 'de2_arch_to_treat':
        if (de2_arch_to_treat !== event.target.value) {
          de2_arch_opposing_treat = 'current';
        }

        de2_arch_to_treat = event.target.value;
        break;

      case 'de2_arch_opposing_treat':
        de2_arch_opposing_treat = event.target.value;
        break;

      case 'de2_setup':
        de2_setup = event.target.value;
        break;

      case 'de2_setup_instruction':
        de2_setup_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de2_setup_additional_instruction':
        de2_setup_additional_instruction = event.target.value;
        break;

      case 'de2_bracket_instruction':
        de2_bracket_instruction = event.target.value;
        break;

      case 'de2_teeth_restriction':
        if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
          de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
        } else {
          de2_teeth_restriction.push(event.target.value);
        }

        break;

      case 'de2_bracket_remove':
        if (_.intersection(de2_bracket_remove, [event.target.value]).length > 0) {
          de2_bracket_remove = _.difference(de2_bracket_remove, [event.target.value]);

          if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
            de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
          }
        } else {
          de2_bracket_remove.push(event.target.value);
          de2_teeth_restriction.push(event.target.value);
          de2_teeth_restriction = _.uniq(de2_teeth_restriction);
        }

        break;

      case 'de2_bracket_add':
        if (_.intersection(de2_bracket_add, [event.target.value]).length > 0) {
          de2_bracket_add = _.difference(de2_bracket_add, [event.target.value]);
        } else {
          de2_bracket_add.push(event.target.value);
        }

        break;

      case 'de2_ipr':
        de2_ipr = event.target.value;
        break;

      case 'de2_ipr_instruction':
        de2_ipr_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de2_spacing':
        de2_spacing = event.target.value;
        break;

      case 'de2_spacing_upper':
        index = parseInt(event.target.dataset.index);
        de2_spacing_upper[index] = this.formatMMEntry(de2_spacing_upper, event.target.value, index);
        break;

      case 'de2_spacing_lower':
        index = parseInt(event.target.dataset.index);
        de2_spacing_lower[index] = this.formatMMEntry(de2_spacing_lower, event.target.value, index);
        break;

      case 'de2_spacing_instruction':
        de2_spacing_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de2_special_instruction':
        de2_special_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      default:
    }

    this.setState({
      component: this.getDE2Form({
        de2_reason: de2_reason,
        de2_initial_date: de2_initial_date,
        de2_intra_date: de2_intra_date,
        de2_upper_smartwire: de2_upper_smartwire,
        de2_upper_smartwire_date: de2_upper_smartwire_date,
        de2_lower_smartwire: de2_lower_smartwire,
        de2_lower_smartwire_date: de2_lower_smartwire_date,
        de2_arch_to_treat: de2_arch_to_treat,
        de2_arch_opposing_treat: de2_arch_opposing_treat,
        de2_setup: de2_setup,
        de2_setup_instruction: de2_setup_instruction,
        de2_setup_additional_instruction: de2_setup_additional_instruction,
        de2_bracket_instruction: de2_bracket_instruction,
        de2_teeth_restriction: de2_teeth_restriction,
        de2_bracket_remove: de2_bracket_remove,
        de2_bracket_add: de2_bracket_add,
        de2_ipr: de2_ipr,
        de2_ipr_instruction: de2_ipr_instruction,
        de2_spacing: de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: de2_spacing_instruction,
        de2_special_instruction: de2_special_instruction,
        de2_prev_case_info: de2_prev_case_info,
        de2_initial_date_warning: de2_initial_date_warning,
        de2_intra_date_warning: de2_intra_date_warning,
      }),
      de2_reason: de2_reason,
      de2_initial_date: de2_initial_date,
      de2_intra_date: de2_intra_date,
      de2_upper_smartwire: de2_upper_smartwire,
      de2_upper_smartwire_date: de2_upper_smartwire_date,
      de2_lower_smartwire: de2_lower_smartwire,
      de2_lower_smartwire_date: de2_lower_smartwire_date,
      de2_arch_to_treat: de2_arch_to_treat,
      de2_arch_opposing_treat: de2_arch_opposing_treat,
      de2_setup: de2_setup,
      de2_setup_instruction: de2_setup_instruction,
      de2_setup_additional_instruction: de2_setup_additional_instruction,
      de2_bracket_instruction: de2_bracket_instruction,
      de2_teeth_restriction: de2_teeth_restriction,
      de2_bracket_remove: de2_bracket_remove,
      de2_bracket_add: de2_bracket_add,
      de2_ipr: de2_ipr,
      de2_ipr_instruction: de2_ipr_instruction,
      de2_spacing: de2_spacing,
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
      de2_spacing_instruction: de2_spacing_instruction,
      de2_special_instruction: de2_special_instruction,
      de2_initial_date_warning: de2_initial_date_warning,
      de2_intra_date_warning: de2_intra_date_warning,
      de2_upper_smartwire_date_warning: de2_upper_smartwire_date_warning,
      de2_lower_smartwire_date_warning: de2_lower_smartwire_date_warning,
    });
  };

  /**
   * DE3.0 input change handler
   * Handles all input changes on the form
   * @function
   * @param {Object} event - The event object on change
   */
  onInputChange_de3 = (event) => {
    let de3_reason = this.state.de3_reason;
    let de3_next_appointment_date = this.state.de3_next_appointment_date;
    let de3_intra_date = this.state.de3_intra_date;
    let de3_upper_smartwire = this.state.de3_upper_smartwire;
    let de3_upper_smartwire_date = this.state.de3_upper_smartwire_date;
    let de3_lower_smartwire = this.state.de3_lower_smartwire;
    let de3_lower_smartwire_date = this.state.de3_lower_smartwire_date;
    let de3_arch_to_treat = this.state.de3_arch_to_treat;
    let de3_arch_opposing_treat = this.state.de3_arch_opposing_treat;
    let de3_upper_arch_opposing_treat = this.state.de3_upper_arch_opposing_treat;
    let de3_lower_arch_opposing_treat = this.state.de3_lower_arch_opposing_treat;
    let de3_setup = this.state.de3_setup;
    let de3_setup_instruction = this.state.de3_setup_instruction;
    let de3_setup_additional_instruction = this.state.de3_setup_additional_instruction;
    let de3_bracket_teeth = this.state.de3_bracket_teeth;
    let de3_missing_teeth = this.state.de3_missing_teeth;
    let de3_extract_teeth = this.state.de3_extract_teeth;
    let de3_teeth_restriction = this.state.de3_teeth_restriction;
    let de3_prev_case_info = this.state.de3_prev_case_info;
    let de3_next_appointment_date_warning = this.state.de3_next_appointment_date_warning;
    let de3_intra_date_warning = this.state.de3_intra_date_warning;
    let de3_upper_smartwire_date_warning = this.state.de3_upper_smartwire_date_warning;
    let de3_lower_smartwire_date_warning = this.state.de3_lower_smartwire_date_warning;
    let de3_anterior_posterior_r = this.state.de3_anterior_posterior_r;
    let de3_anterior_posterior_l = this.state.de3_anterior_posterior_l;
    let de3_anterior_posterior_corrections = this.state.de3_anterior_posterior_corrections;
    let de3_anterior_posterior_notes = this.state.de3_anterior_posterior_notes;
    let de3_midlines = this.state.de3_midlines;
    let de3_auxiliaries = this.state.de3_auxiliaries;
    let de3_auxiliaries_notes = this.state.de3_auxiliaries_notes;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';

    switch (event_type) {
      case 'de3_reason':
        de3_reason = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_next_appointment_date':
        de3_next_appointment_date = event.target.value;
        de3_next_appointment_date_warning = !isDateInBetween(de3_next_appointment_date, new Date(), '3000-01-01');
        break;

      case 'de3_intra_date':
        de3_intra_date = event.target.value;
        de3_intra_date_warning = !isValidDate(de3_intra_date);
        break;

      case 'de3_upper_smartwire':
        de3_upper_smartwire = event.target.value;

        if (de3_upper_smartwire === 'none') {
          de3_upper_smartwire_date = '';
        }
        break;

      case 'de3_upper_smartwire_date':
        de3_upper_smartwire_date = event.target.value;
        de3_upper_smartwire_date_warning = !isValidDate(de3_upper_smartwire_date);
        break;

      case 'de3_lower_smartwire':
        de3_lower_smartwire = event.target.value;
        if (de3_lower_smartwire === 'none') {
          de3_lower_smartwire_date = '';
        }
        break;

      case 'de3_lower_smartwire_date':
        de3_lower_smartwire_date = event.target.value;
        de3_lower_smartwire_date_warning = !isValidDate(de3_lower_smartwire_date);
        break;

      case 'de3_arch_to_treat':
        const de3_arch_to_treat_value = event.target.value;
        if (de3_arch_to_treat_value === 'upper') {
          de3_arch_opposing_treat = de3_upper_arch_opposing_treat;
        } else if (de3_arch_to_treat_value === 'lower') {
          de3_arch_opposing_treat = de3_lower_arch_opposing_treat;
        }
        de3_arch_to_treat = de3_arch_to_treat_value;
        break;

      case 'de3_arch_opposing_treat':
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_upper_arch_opposing_treat':
        de3_upper_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_lower_arch_opposing_treat':
        de3_lower_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_setup':
        de3_setup = event.target.value;
        break;

      case 'de3_setup_instruction':
        de3_setup_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_setup_additional_instruction':
        de3_setup_additional_instruction = event.target.value;
        break;

      case 'de3_bracket_teeth':
        if (_.intersection(de3_bracket_teeth, [event.target.value]).length > 0) {
          de3_bracket_teeth = _.difference(de3_bracket_teeth, [event.target.value]);
        } else {
          de3_bracket_teeth.push(event.target.value);
        }
        break;

      case 'de3_missing_teeth':
        if (_.intersection(de3_missing_teeth, [event.target.value]).length > 0) {
          de3_missing_teeth = _.difference(de3_missing_teeth, [event.target.value]);
        } else {
          de3_missing_teeth.push(event.target.value);
        }
        break;

      case 'de3_extract_teeth':
        if (_.intersection(de3_extract_teeth, [event.target.value]).length > 0) {
          de3_extract_teeth = _.difference(de3_extract_teeth, [event.target.value]);
        } else {
          de3_extract_teeth.push(event.target.value);
        }
        break;

      case 'de3_teeth_restriction':
        if (_.intersection(de3_teeth_restriction, [event.target.value]).length > 0) {
          de3_teeth_restriction = _.difference(de3_teeth_restriction, [event.target.value]);
        } else {
          de3_teeth_restriction.push(event.target.value);
        }
        break;

      case 'de3_anterior_posterior_r':
        de3_anterior_posterior_r = event.target.value;
        break;

      case 'de3_anterior_posterior_l':
        de3_anterior_posterior_l = event.target.value;
        break;

      case 'de3_anterior_posterior_corrections':
        const { name, checked } = event.target;
        de3_anterior_posterior_corrections = {
          ...de3_anterior_posterior_corrections,
          [name]: checked,
        };
        break;

      case 'de3_anterior_posterior_notes':
        de3_anterior_posterior_notes = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_midlines':
        de3_midlines = event.target.value;
        break;

      case 'de3_auxiliaries':
        de3_auxiliaries = event.target.value;
        break;

      case 'de3_auxiliaries_notes':
        de3_auxiliaries_notes = removeEmoji(textFieldLimited(event.target.value));
        break;

      default:
    }

    const ns = {
      de3_reason,
      de3_next_appointment_date,
      de3_intra_date,
      de3_upper_smartwire,
      de3_upper_smartwire_date,
      de3_lower_smartwire,
      de3_lower_smartwire_date,
      de3_arch_to_treat,
      de3_arch_opposing_treat,
      de3_upper_arch_opposing_treat,
      de3_lower_arch_opposing_treat,
      de3_setup,
      de3_setup_instruction,
      de3_setup_additional_instruction,
      de3_bracket_teeth,
      de3_missing_teeth,
      de3_extract_teeth,
      de3_teeth_restriction,
      de3_prev_case_info,
      de3_next_appointment_date_warning,
      de3_intra_date_warning,
      de3_anterior_posterior_r,
      de3_anterior_posterior_l,
      de3_anterior_posterior_corrections,
      de3_anterior_posterior_notes,
      de3_midlines,
      de3_auxiliaries,
      de3_auxiliaries_notes,
      de3_upper_smartwire_date_warning,
      de3_lower_smartwire_date_warning,
    };

    this.setState({
      ...ns,
      component: this.getDE3Form(ns),
    });

    const stepper = this.state.stepper;

    if (this.isDE3FormComplete(true, ns)) {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
    } else {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 0;
    }
    this.setState({ stepper: stepper });
    this.onFocus_clearWarning();
  };

  formatMMEntry = (state, value, index) => {
    // Value should be X.X
    const prevCond = state[index];

    let formattedValue = value
      .replace(/[^0-9.]/g, '')
      .replace('..', '.')
      .replace('00', '0');

    //Enforce .
    if (formattedValue.length === 1 && prevCond === '' && formattedValue !== '.') {
      formattedValue += '.';
    } else if (formattedValue.length === 1 && prevCond === '' && formattedValue === '.') {
      formattedValue = '0.';
    } else if ((value.length > 2 && prevCond === '0') || (value.length === 2 && parseInt(prevCond) <= 9 && value[1] !== '.' && value[0] !== '.')) {
      formattedValue = value[0] + '.' + value[1];
    } else if (formattedValue.length === 1 && prevCond === '.') {
      formattedValue = '';
    }

    //if . is detected than only allow one digit after
    const decimalPos = formattedValue.indexOf('.');
    if (decimalPos >= 0 && formattedValue.length >= decimalPos + 2) {
      formattedValue = formattedValue.slice(0, decimalPos + 2);
    }

    //trim off leading 0 conditions
    if (decimalPos !== 1 && value[0] === '0') {
      formattedValue = formattedValue.slice(1);
    }

    //max character limit
    if (value.length >= 4) {
      formattedValue = prevCond;

      if (parseFloat(value) > 9.9) {
        formattedValue = '9.9';
      }
    } else if (formattedValue.length === 3 && formattedValue[2] === '.') {
      formattedValue = '9.9';
    }

    return formattedValue;
  };

  formatMMFromBlur = (value) => {
    let formattedValue = value;

    if (formattedValue.length < 3) {
      if (value.indexOf('.') === value.length - 1 && value !== '') {
        formattedValue = formattedValue + '0';
      }
    }

    return formattedValue;
  };

  clearSpacingValueDE2 = (index, state) => {
    let de2_spacing_upper = this.state.de2_spacing_upper;
    let de2_spacing_lower = this.state.de2_spacing_lower;

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = '';
        break;

      case 'de2_spacing_lower':
        de2_spacing_lower[index] = '';

        break;
      default:
    }

    this.setState({
      component: this.getDE2Form({
        de2_reason: this.state.de2_reason,
        de2_initial_date: this.state.de2_initial_date,
        de2_intra_date: this.state.de2_intra_date,
        de2_upper_smartwire: this.state.de2_upper_smartwire,
        de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
        de2_lower_smartwire: this.state.de2_lower_smartwire,
        de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
        de2_arch_to_treat: this.state.de2_arch_to_treat,
        de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
        de2_setup: this.state.de2_setup,
        de2_setup_instruction: this.state.de2_setup_instruction,
        de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
        de2_bracket_instruction: this.state.de2_bracket_instruction,
        de2_teeth_restriction: this.state.de2_teeth_restriction,
        de2_bracket_remove: this.state.de2_bracket_remove,
        de2_bracket_add: this.state.de2_bracket_add,
        de2_ipr: this.state.de2_ipr,
        de2_ipr_instruction: this.state.de2_ipr_instruction,
        de2_spacing: this.state.de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: this.state.de2_spacing_instruction,
        de2_special_instruction: this.state.de2_special_instruction,
        de2_prev_case_info: this.state.de2_prev_case_info,
        de2_initial_date_warning: this.state.de2_initial_date_warning,
        de2_intra_date_warning: this.state.de2_intra_date_warning,
      }),
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };

  onChangeSpacingOnBlurDE2 = (index, state) => {
    let de2_spacing_upper = this.state.de2_spacing_upper;
    let de2_spacing_lower = this.state.de2_spacing_lower;

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = this.formatMMFromBlur(de2_spacing_upper[index]);
        break;

      case 'de2_spacing_lower':
        de2_spacing_lower[index] = this.formatMMFromBlur(de2_spacing_lower[index]);

        break;
      default:
    }

    this.setState({
      component: this.getDE2Form({
        de2_reason: this.state.de2_reason,
        de2_initial_date: this.state.de2_initial_date,
        de2_intra_date: this.state.de2_intra_date,
        de2_upper_smartwire: this.state.de2_upper_smartwire,
        de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
        de2_lower_smartwire: this.state.de2_lower_smartwire,
        de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
        de2_arch_to_treat: this.state.de2_arch_to_treat,
        de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
        de2_setup: this.state.de2_setup,
        de2_setup_instruction: this.state.de2_setup_instruction,
        de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
        de2_bracket_instruction: this.state.de2_bracket_instruction,
        de2_teeth_restriction: this.state.de2_teeth_restriction,
        de2_bracket_remove: this.state.de2_bracket_remove,
        de2_bracket_add: this.state.de2_bracket_add,
        de2_ipr: this.state.de2_ipr,
        de2_ipr_instruction: this.state.de2_ipr_instruction,
        de2_spacing: this.state.de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: this.state.de2_spacing_instruction,
        de2_special_instruction: this.state.de2_special_instruction,
        de2_prev_case_info: this.state.de2_prev_case_info,
        de2_initial_date_warning: this.state.de2_initial_date_warning,
        de2_intra_date_warning: this.state.de2_intra_date_warning,
      }),
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };

  photos_upload = (filenames) => {
    this.setState({
      photos_uploading: filenames,
    });
  };

  radiographs_upload = (filenames) => {
    this.setState({
      radiographs_uploading: filenames,
    });
  };

  scans_upload = (filenames) => {
    this.setState({
      scans_uploading: filenames,
    });
  };

  hide_warning = () => {
    this.setState({
      warning: false,
      warning_title: 'Incomplete Fields',
    });
  };

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  /**
   * Clear warning on focus on input
   * @function
   * @param {event} event - click event
   */
  onFocus_clearWarning = (event) => {
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }
    let terms = document.querySelector('#terms');

    let filteredSelectors = [];

    if (this.state.form === 'DE2.0') {
      const allD2Ids = [
        '#scanDate',
        '#intraOral',
        '#reasonSelection',
        '#reason',
        '#setupSelection',
        '#setup',
        '#de2_initial_date',
        '#de2_initial_date_input',
        '#de2_reason_textarea',
        '#de2_reason',
        '#de2_intra_date',
        '#de2_intra_date_input',
      ];

      filteredSelectors = allD2Ids.filter((id) => {
        return !(
          ((id === '#de2_initial_date' || id === '#de2_initial_date_input') && this.state.de2_initial_date_warning) ||
          ((id === '#de2_intra_date' || id === '#de2_intra_date_input') && this.state.de2_intra_date_warning)
        );
      });
    }

    // DE3.0 add ids
    if (this.state.form === 'DE3.0') {
      const allD3Selectors = [
        '#de3_reason_textarea',
        '#de3_reason',
        '#de3_intra_date',
        '#de3_intra_date_input',
        '#de3_next_appointment_date',
        '#de3_next_appointment_date_input',
        '#de3_auxiliaries',
        '#de3_auxiliaries_notes',
        '#de3_anterior_posterior',
        '#de3_anterior_posterior_notes',
        '#de3_bracket_teeth',
        '#de3_setup',
        '#de3_setup_instruction',
        'textarea[data-field="de3_setup_instruction"]',
      ];

      filteredSelectors = allD3Selectors.filter((id) => {
        return !(
          ((id === '#de3_intra_date' || id === '#de3_intra_date_input') && this.state.de3_intra_date_warning) ||
          ((id === '#de3_next_appointment_date' || id === '#de3_next_appointment_date_input') && this.state.de3_next_appointment_date_warning)
        );
      });

      handleAnteriorPosteriorCheckboxErrors(false);
    }

    const stepper = this.state.stepper;
    if (terms && terms.checked) {
      stepper[6] = 1;
    } else {
      stepper[6] = 2;
    }

    this.setState({
      stepper,
    });

    this.hide_warning();

    _.each(filteredSelectors, (id) => {
      let element = document.querySelector(id);
      if (element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }
    });
  };

  getCurrentData = () => {
    let that = this;
    let formData = new FormData();

    if (that.state.dsoDoctorId) {
      formData.append('doctorId', that.state.dsoDoctorId);
    } else {
      formData.append('doctorId', that.state.doctorId);
    }
    formData.append('id', that.state.id);
    formData.append('digital_id', that.state.digital_id);
    formData.append('stepper', that.state.stepper);
    formData.append('address', that.state.address);
    formData.append('form', that.state.form);

    if (that.state.form === 'DE2.0') {
      const de2_states = [
        'de2_reason',
        'de2_initial_date',
        'de2_intra_date',
        'de2_upper_smartwire',
        'de2_upper_smartwire_date',
        'de2_lower_smartwire',
        'de2_lower_smartwire_date',
        'de2_arch_to_treat',
        'de2_arch_opposing_treat',
        'de2_setup',
        'de2_setup_instruction',
        'de2_setup_additional_instruction',
        'de2_bracket_instruction',
        'de2_teeth_restriction',
        'de2_bracket_remove',
        'de2_bracket_add',
        'de2_ipr',
        'de2_ipr_instruction',
        'de2_spacing',
        'de2_spacing_upper',
        'de2_spacing_lower',
        'de2_spacing_instruction',
        'de2_special_instruction',
        'de2_prev_case_info',
      ];

      de2_states.forEach((state) => {
        if (state === 'de2_prev_case_info') {
          formData.append(state, JSON.stringify(that.state[state]));
        } else {
          formData.append(state, that.state[state]);
        }
      });
    }

    // DE3.0 add form data
    if (that.state.form === 'DE3.0') {
      const de3_states = [
        'de3_reason',
        'de3_next_appointment_date',
        'de3_intra_date',
        'de3_upper_smartwire',
        'de3_upper_smartwire_date',
        'de3_lower_smartwire',
        'de3_lower_smartwire_date',
        'de3_arch_to_treat',
        'de3_arch_opposing_treat',
        'de3_setup',
        'de3_setup_instruction',
        'de3_setup_additional_instruction',
        'de3_missing_teeth',
        'de3_extract_teeth',
        'de3_teeth_restriction',
        'de3_prev_case_info',
        'de3_anterior_posterior_r',
        'de3_anterior_posterior_l',
        'de3_midlines',
        'de3_auxiliaries',
      ];

      de3_states.forEach((state) => {
        if (['de3_prev_case_info'].includes(state)) {
          formData.append(state, JSON.stringify(that.state[state]));
        } else {
          formData.append(state, that.state[state]);
        }
      });

      const de3_bracket_teeth = removeOpposingTeeth(that.state.de3_arch_to_treat, that.state.de3_bracket_teeth);
      formData.append('de3_bracket_teeth', de3_bracket_teeth);

      let de3_anterior_posterior_corrections = that.state.de3_anterior_posterior_corrections;
      if (that.state.de3_anterior_posterior_r === 'current' && that.state.de3_anterior_posterior_l === 'current') {
        de3_anterior_posterior_corrections = {
          class_II_or_III_correction_simulation: false,
          posterior_IPR: false,
        };
      }
      formData.append('de3_anterior_posterior_corrections', JSON.stringify(de3_anterior_posterior_corrections));

      let de3_anterior_posterior_notes = that.state.de3_anterior_posterior_notes;
      if (that.state.de3_anterior_posterior_r !== 'other' && that.state.de3_anterior_posterior_l !== 'other') {
        de3_anterior_posterior_notes = '';
      }
      formData.append('de3_anterior_posterior_notes', de3_anterior_posterior_notes);

      let de3_auxiliaries_notes = this.state.de3_auxiliaries_notes;
      if (that.state.de3_auxiliaries === 'No') {
        de3_auxiliaries_notes = '';
      }
      formData.append('de3_auxiliaries_notes', de3_auxiliaries_notes);
    }

    formData.append('photos', JSON.stringify(that.state.photoUpload));
    formData.append('xrays', JSON.stringify(that.state.xrayUpload));
    formData.append('scans', JSON.stringify(that.state.scanUpload));

    // iTero integration - add iTero file data to formData
    formData.append('uploadMethod', this.state.uploadMethod);
    formData.append('showAlreadyUploadedScans', this.state.showAlreadyUploadedScans);
    formData.append('shouldFetchIteroPhotos', this.state.shouldFetchIteroPhotos);
    formData.append('selected_itero_file_ids', JSON.stringify(this.state.selectedIteroScanIds));

    return formData;
  };

  /**
   * Appends `record_state` field to each photo/xray records
   * @function
   * @param {Array} uploads - list of uploaded files
   * @param {Number} index - The index to start
   * @returns {String} The resulting json string
   */
  getUploadJson = (uploads, index) => {
    const hasRecordStates = this.props.record_states && this.props.record_states.length >= uploads.length + index;
    if (!hasRecordStates) return;
    const result = uploads.map((photo, i) => ({ ...photo, record_state: this.props.record_states[i + index].state }));
    return JSON.stringify(result);
  };

  preservedIdOnIncompleteSavesPhoto = (raw_photos, photoUpload) => {
    let preservedIdPhotos = photoUpload;

    if (raw_photos && photoUpload) {
      for (let i = 0; i < raw_photos.length; i++) {
        for (let j = 0; j < photoUpload.length; j++) {
          if (raw_photos[i]['upload_data'] === photoUpload[j]['upload_data'] && !photoUpload[j].id) {
            photoUpload[j]['id'] = raw_photos[i]['id'];
          }
        }
      }
    }

    return preservedIdPhotos;
  };

  /**
   * Handles the incomplete save functionality.
   *
   * @param {Event|String} event - The event object or the route name.
   * @param {String} [photo_type=''] - The type of photo.
   * @param {Object} [options={}] - Additional options.
   * @param {Boolean} [options.refreshCaseList=false] - Refresh the case list.
   * @param {Boolean} [options.refreshDashboard=false] - Refresh the dashboard.
   */
  onIncompleteSave = (event, photo_type = '', options = {}) => {
    let that = this;
    let redirect = 'false';
    let routename = '';

    if (event && event.currentTarget) {
      redirect = event.currentTarget.dataset.redirect;
    } else if (typeof event === 'string') {
      routename = event;
    }

    let formData = this.getCurrentData();
    Axios.post('/api/digital/', formData).then(function (result) {
      let photoUpload = result.data.data.photos !== '' && photo_type === 'photos' ? JSON.parse(result.data.data.photos) : that.state.photoUpload;
      let xrayUpload = result.data.data.xrays !== '' && photo_type === 'xrays' ? JSON.parse(result.data.data.xrays) : that.state.xrayUpload;
      let scanUpload = result.data.data.scans !== '' && photo_type === 'scans' ? JSON.parse(result.data.data.scans) : that.state.scanUpload;

      if (!that.state.digital_id) {
        that.appendRoute(result.data.data.digital_id, routename);
      }

      if (result.data.data.photos !== '' && photo_type !== 'photos') {
        photoUpload = that.preservedIdOnIncompleteSavesPhoto(JSON.parse(result.data.data.photos), that.state.photoUpload);
      }

      if (result.data.data.xrays !== '' && photo_type !== 'xrays') {
        xrayUpload = that.preservedIdOnIncompleteSavesPhoto(JSON.parse(result.data.data.xrays), that.state.xrayUpload);
      }

      that.setState({
        digital_id: result.data.data.digital_id,
        photoUpload: photoUpload,
        xrayUpload: xrayUpload,
        scanUpload: scanUpload,
        showSaveModal: redirect && redirect === 'true',
      });
    });

    if (this.state.digital_id) {
      that.appendRoute(this.state.digital_id, routename);
    }

    if (options.refreshCaseList) {
      sendRefreshCaseListMessage();
    }
    if (options.refreshDashboard) {
      sendRefreshDashboardMessage();
    }
  };

  appendRoute = (case_id, routename = '') => {
    if (case_id) {
      if (routename !== '') {
        let pathname = this.props.history.location.pathname.match(/\/digital\/([A-Za-z]+)/);
        let newpath = '';
        if (pathname && pathname[1]) {
          newpath = this.props.history.location.pathname.replace(pathname[1], routename);
        }

        if (this.props.history.location.search.includes('doctorid')) {
          this.props.history.replace(`${newpath}?caseid=${case_id}&doctorid=${this.state.dsoDoctorId}`);
        } else {
          this.props.history.replace(`${newpath}?caseid=${case_id}`);
        }
      } else {
        if (this.props.history.location.search.includes('doctorid')) {
          this.props.history.replace(`${this.props.history.location.pathname}?caseid=${case_id}&doctorid=${this.state.dsoDoctorId}`);
        } else {
          this.props.history.replace(`${this.props.history.location.pathname}?caseid=${case_id}`);
        }
      }
    }
  };

  onRemoveDraft = (event) => {
    let url = `/api/digital/?id=${this.state.digital_id}`;
    let that = this;
    Axios({
      url: url,
      method: 'DELETE',
    })
      .then((response) => {
        let latest_case_id = response && response.data && response.data.latest_case_id ? response.data.latest_case_id : '';
        that.onRedirecCaseDetail(latest_case_id);
      })
      .finally(() => {
        sendRefreshCaseListMessage();
        sendRefreshDashboardMessage();
      });
  };

  urlContainsWord = (word) => {
    let has_word = false;

    if (this.props.history && this.props.history.location && this.props.history.location.pathname.indexOf(word) >= 0) {
      has_word = true;
    }

    return has_word;
  };

  onOverviewCheckboxClick = (e) => {
    const index = e.target && e.target.dataset && e.target.dataset.index ? e.target.dataset.index : '';
    let overview_questions = [...this.state.overview_questions];

    if (index) {
      overview_questions[parseInt(index)].value = !overview_questions[parseInt(index)].value;

      this.setState({ overview_questions: overview_questions });
    }
  };

  /**
   * iTero integration
   *
   * Fetch iTero photos
   * @param {String} iteroId - itero id
   * @function
   */
  fetchIteroPhotos = (iteroId) => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.props.fetchIteroPhotos(iteroId, {
      onSuccess: (iteroPhotos) => {
        const iteroPhotoIds = iteroPhotos.map((file) => file.id);
        this.props.addIteroPhotosToDE(
          {
            digitalId: `${this.state.digital_id}`,
            iteroFileIds: iteroPhotoIds,
            iteroId: iteroId,
          },
          {
            onSuccess: (data) => {
              const { success } = data;
              const uploadedIteroPhotos = Object.values(success);
              this.setState((prevState) => {
                let newStepper = [...prevState.stepper];

                // remove old itero photos from state
                const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);

                const newPhotoUploadData = [...filteredPrevStatePhotoUpload, ...uploadedIteroPhotos];

                newStepper = this.updatePhotoStepperState(newPhotoUploadData, prevState.stepper);

                if (
                  this.isPhotoUploadLengthValid(newPhotoUploadData) &&
                  this.state.xrayUpload.length > 0 &&
                  this.isScansLengthValid() &&
                  this.isDEFormComplete(false) &&
                  newStepper[this.getActivePositionByStepperName('Submit')] === 0
                ) {
                  newStepper[6] = 2;
                }

                return {
                  [photoUploadStateKey]: newPhotoUploadData,
                  selectedIteroPhotoRecords: uploadedIteroPhotos,
                  stepper: newStepper,
                };
              });
            },
          }
        );
      },
    });
  };

  /**
   * iTero integration
   *
   * Removes all Itero photos from the state and updates the selected Itero photo.
   * @param {string} oldIteroId - The ID of the old Itero photo.
   * @param {string} newSelectedIteroId - The ID of the new selected Itero photo.
   * @returns {void}
   */
  updateIteroPhotos = (oldIteroId, newSelectedIteroId) => {
    this.removeAllIteroPhotosFromState();
    const that = this;
    this.props.deleteIteroPhotosFromDE(
      {
        digitalId: this.state.digital_id,
        iteroId: oldIteroId,
      },
      {
        onSuccess: () => {
          if (that.state.shouldFetchIteroPhotos) {
            that.fetchIteroPhotos(newSelectedIteroId);
          }
        },
      }
    );
  };

  onUpload = async (data) => {
    if (data && data[0].folder) {
      let uploadData = [];
      let stepper = this.state.stepper;

      for (let index = 0; index < data.length; index++) {
        if (data[index].folder === 'scans') {
          const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
          data[index]['file_url'] = file_url;
        }
      }

      if (data[0].folder === 'photos') {
        uploadData = this.state.photoUpload;
        uploadData = uploadData.concat(data);

        if (this.isPhotoUploadLengthValid(uploadData)) {
          stepper[this.getActivePositionByStepperName('Photos')] = 1;
        }

        if (this.isPhotoUploadLengthValid(uploadData) && this.state.xrayUpload.length > 0 && this.isScansLengthValid() && this.isDEFormComplete(false)) {
          if (stepper[this.getActivePositionByStepperName('Submit')] === 0) {
            stepper[6] = 2;
          }
        }

        if (this.state.active === this.getActivePositionByStepperName('Photos')) {
          this.setState({
            component: (
              <Photos
                id={this.state.digital_id}
                photoUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                onIncompleteSave={this.onIncompleteSave}
                location="incomplete"
                upload_state={this.photos_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
                isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
                isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
                showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
                onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
                iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
              />
            ),
            active: this.getActivePositionByStepperName('Photos'),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={'Photos'} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={'Photos'} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          });
        }

        this.setState({
          photoUpload: uploadData,
          stepper: stepper,
          photos_uploading: [],
        });
      } else if (data[0].folder === 'xrays') {
        uploadData = this.state.xrayUpload;
        uploadData = uploadData.concat(data);

        if (uploadData.length > 0) {
          stepper[this.getActivePositionByStepperName('X-Rays')] = 1;
        }

        if (this.isDEFormComplete(false)) {
          if (stepper[this.getActivePositionByStepperName('Submit')] === 0) {
            stepper[6] = 2;
          }
        }

        if (this.state.active === this.getActivePositionByStepperName('X-Rays')) {
          this.setState({
            component: (
              <Radiographs
                id={this.state.digital_id}
                xrayUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                onIncompleteSave={this.onIncompleteSave}
                location="incomplete"
                upload_state={this.radiographs_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
              />
            ),
            active: this.getActivePositionByStepperName('X-Rays'),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={'X-Rays'} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={'X-Rays'} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          });
        }

        this.setState({
          xrayUpload: uploadData,
          stepper: stepper,
          radiographs_uploading: [],
        });
      } else if (data[0].folder === 'upperscans' || data[0].folder === 'lowerscans' || data[0].folder === 'scans') {
        uploadData = this.state.scanUpload;
        uploadData = uploadData.concat(data);

        if (uploadData.length === 2) {
          stepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }

        if (this.isDEFormComplete(false)) {
          if (stepper[this.getActivePositionByStepperName('Submit')] === 0) {
            stepper[6] = 2;
          }
        }

        if (this.state.active === this.getActivePositionByStepperName('Impressions')) {
          this.setState({
            component: (
              <Scans
                id={this.state.digital_id}
                scanUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                onEmptyFileError={this.onEmptyFileError}
                location="incomplete"
                text="de"
                upload_state={this.scans_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                // iTero integration - props to be passed to the scans component
                uploadMethod={this.state.uploadMethod}
                onScansUploadMethodChange={this.handleScansUploadMethodChange}
                iteroSelectionProps={this.getIteroSelectionProps()}
                recordViewerSubtitle={this.getRecordViewerSubtitle(true, this.isLoadingIteroPhotos())}
                isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
              />
            ),
            active: this.getActivePositionByStepperName('Impressions'),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={'Impressions'} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={'Impressions'} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          });

          this.showMoreTwoScansWarning(uploadData);
        }

        this.setState(
          {
            scanUpload: uploadData,
            stepper: stepper,
            scans_uploading: [],
          },
          () => {
            this.onIncompleteSave('', 'scans');
          }
        );
      }
    }
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();

    //Perform State Deletion
    const file_id = event.currentTarget.dataset.file_id;
    let id = this.state.digital_id.indexOf('-') >= 0 ? this.state.digital_id : getCaseIdFromSubmissionRoute();
    let href = event.currentTarget.href;
    let removeType = event.currentTarget.dataset.type;
    let startingPath = href.indexOf('/', 9);
    let newRef = decodeURI(href.substring(startingPath + 1, href.length));
    let uploadData = [];

    Axios({
      url: `/api/remove/?id=${id}&file=${newRef}&complete=true&doctor_id=${this.state.dsoDoctorId ? this.state.dsoDoctorId : ''}`,
      method: 'POST',
    }).then((response) => {
      let incomplete_case_file_id = parseInt(response.data.data.incomplete_case_file_id || file_id);

      if (response.data.data && incomplete_case_file_id) {
        let newPhotoUpload = [];
        let newXrayUpload = [];
        let newScanUpload = [];
        let stepper = this.state.stepper;

        switch (removeType) {
          case 'photos':
            uploadData = this.state.photoUpload;

            for (let i = 0; i < uploadData.length; i++) {
              if (uploadData[i].upload_data !== newRef) {
                newPhotoUpload.push(uploadData[i]);
              }
            }

            if (this.getPhotoUploadLength(newPhotoUpload) === 0) {
              stepper[this.getActivePositionByStepperName('Photos')] = 2;
              stepper[6] = 2;
            }

            if (this.isPhotoUploadLengthValid(newPhotoUpload)) {
              stepper[this.getActivePositionByStepperName('Photos')] = 1;
            }

            if (this.state.active === this.getActivePositionByStepperName('Photos')) {
              this.setState({
                component: (
                  <Photos
                    id={this.state.digital_id}
                    photoUpload={newPhotoUpload}
                    onUpload={this.onUpload}
                    onRemove={this.onRemove}
                    onIncompleteSave={this.onIncompleteSave}
                    location="incomplete"
                    upload_state={this.photos_upload}
                    upload_content={this.state.photos_uploading}
                    show_warning={this.show_warning}
                    hide_warning={this.hide_warning}
                    updateRecordState={this.updateRecordState}
                    isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
                    isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
                    showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
                    onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
                    iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
                  />
                ),
              });
            }

            this.setState({
              photoUpload: newPhotoUpload,
              stepper: stepper,
            });

            break;

          case 'xrays':
            uploadData = this.state.xrayUpload;
            for (let i = 0; i < uploadData.length; i++) {
              if (uploadData[i].upload_data !== newRef) {
                newXrayUpload.push(uploadData[i]);
              }
            }

            if (newXrayUpload.length === 0) {
              // stepper[this.getActivePositionByStepperName('X-Rays')] = 2;
              // stepper[6] = 2;
            }

            if (newXrayUpload.length > 0) {
              stepper[this.getActivePositionByStepperName('X-Rays')] = 1;
            }

            if (this.state.active === this.getActivePositionByStepperName('X-Rays')) {
              this.setState({
                component: (
                  <Radiographs
                    id={this.state.digital_id}
                    xrayUpload={newXrayUpload}
                    onUpload={this.onUpload}
                    onRemove={this.onRemove}
                    onIncompleteSave={this.onIncompleteSave}
                    location="incomplete"
                    upload_state={this.radiographs_upload}
                    upload_content={this.state.radiographs_uploading}
                    show_warning={this.show_warning}
                    hide_warning={this.hide_warning}
                    updateRecordState={this.updateRecordState}
                  />
                ),
              });
            }

            this.setState({
              xrayUpload: newXrayUpload,
              stepper: stepper,
            });

            break;

          case 'upperscans':
          case 'lowerscans':
          case 'scans':
            uploadData = this.state.scanUpload;

            for (let i = 0; i < uploadData.length; i++) {
              if (uploadData[i].upload_data !== decodeURI(newRef)) {
                newScanUpload.push(uploadData[i]);
              }
            }

            if (newScanUpload.length !== 2) {
              stepper[this.getActivePositionByStepperName('Impressions')] = 2;
              stepper[6] = 2;
            }

            if (newScanUpload.length === 2) {
              stepper[this.getActivePositionByStepperName('Impressions')] = 1;
            }

            if (this.state.active === this.getActivePositionByStepperName('Impressions')) {
              this.setState({
                component: (
                  <Scans
                    id={this.state.digital_id}
                    scanUpload={newScanUpload}
                    onUpload={this.onUpload}
                    onRemove={this.onRemove}
                    onEmptyFileError={this.onEmptyFileError}
                    location="incomplete"
                    text="de"
                    upload_state={this.scans_upload}
                    upload_content={this.state.scans_uploading}
                    show_warning={this.show_warning}
                    hide_warning={this.hide_warning}
                    // iTero integration - props to be passed to the scans component
                    uploadMethod={this.state.uploadMethod}
                    onScansUploadMethodChange={this.handleScansUploadMethodChange}
                    iteroSelectionProps={this.getIteroSelectionProps()}
                    recordViewerSubtitle={this.getRecordViewerSubtitle(true, this.isLoadingIteroPhotos())}
                    isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
                  />
                ),
              });

              if (newScanUpload.length === 2) {
                this.onFocus_clearWarning();
              }
            }

            this.setState({
              scanUpload: newScanUpload,
              stepper: stepper,
            });

            break;
          default:
        }
      }
    });
  };

  isStepperDone = (stepper) => {
    let result = true;

    for (let i = 0; i < stepper.length - 1; i++) {
      if (stepper[i] === 0 || stepper[i] === 2) {
        result = false;
        break;
      }
    }

    return result;
  };

  hasOnePhoto = () => {
    if (this.getPhotoUploadLength() === 0 && this.state.photos_uploading.length === 0) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');
      warning.innerHTML = `<ul><li>${this.getPhotoSectionErrorMessage()}</li></ul>`;

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  hasOneXray = () => {
    if (this.state.xrayUpload.length === 0 && this.state.radiographs_uploading.length === 0) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');
      warning.innerHTML = '<ul><li>Radiographs</li></ul>';

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  showMoreTwoScansWarning(scans) {
    if (scans.length > 2) {
      let warning = document.querySelector('#warning-submit');
      warning.classList.add('warning-display');

      warning.innerHTML = '<ul><li>More than 2 STL Files</li></ul>';

      this.setState({
        warning: true,
      });
    }
  }

  /**
   * iTero integration
   *
   * Validates that the user has selected two scans
   * @function
   * @returns {boolean} - True or false
   */
  hasTwoScans = () => {
    const _hasTwoScans = hasTwoScansHelper({
      uploadMethod: this.state.uploadMethod,
      scanUpload: this.state.scanUpload,
      scans_uploading: this.state.scans_uploading,
      selectedIteroScans: this.state.selectedIteroScans,
    });

    if (!_hasTwoScans) {
      this.setState({
        warning: true,
      });
    }

    return _hasTwoScans;
  };

  isDE2FormComplete = (warning = true) => {
    let result = false;

    if (
      this.state.de2_intra_date !== '' &&
      this.state.de2_reason !== '' &&
      this.state.de2_initial_date !== '' &&
      !this.state.de2_initial_date_warning &&
      !this.state.de2_intra_date_warning &&
      !this.state.de2_upper_smartwire_date_warning &&
      !this.state.de2_lower_smartwire_date_warning
    ) {
      result = true;
    }

    if (!result && warning) {
      if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
        if (document.querySelector('#de2_initial_date_input')) {
          document.querySelector('#de2_initial_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_initial_date')) {
          document.querySelector('#de2_initial_date').classList.add('warning-text');
        }
      }

      if (this.state.de2_reason === '') {
        if (document.querySelector('#de2_reason_textarea')) {
          document.querySelector('#de2_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de2_reason')) {
          document.querySelector('#de2_reason').classList.add('warning-text');
        }
      }

      if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
        if (document.querySelector('#de2_intra_date_input')) {
          document.querySelector('#de2_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_intra_date')) {
          document.querySelector('#de2_intra_date').classList.add('warning-text');
        }
      }
    }

    return result;
  };

  /**
   * Determines if DE3.0 anterior-posterior field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorFieldValid = ({ de3_anterior_posterior_l, de3_anterior_posterior_r, de3_anterior_posterior_corrections } = {}) => {
    const currentAnteriorPosteriorL = de3_anterior_posterior_l || this.state.de3_anterior_posterior_l;
    const currentAnteriorPosteriorR = de3_anterior_posterior_r || this.state.de3_anterior_posterior_r;
    const currentAnteriorPosteriorCorrections = de3_anterior_posterior_corrections || this.state.de3_anterior_posterior_corrections;

    const correctionIsSelected = [currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('correction');

    if (correctionIsSelected) {
      return Object.values(currentAnteriorPosteriorCorrections).some((value) => value);
    }

    return true;
  };

  /**
   * Determines if DE3.0 anterior-posterior notes field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorNotesFieldValid = ({ de3_anterior_posterior_l, de3_anterior_posterior_r, de3_anterior_posterior_notes } = {}) => {
    const currentAnteriorPosteriorL = de3_anterior_posterior_l || this.state.de3_anterior_posterior_l;
    const currentAnteriorPosteriorR = de3_anterior_posterior_r || this.state.de3_anterior_posterior_r;

    const anteriorPosteriorNotes =
      de3_anterior_posterior_notes !== undefined && de3_anterior_posterior_notes !== null
        ? de3_anterior_posterior_notes
        : this.state.de3_anterior_posterior_notes;

    if ([currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('other')) {
      return anteriorPosteriorNotes !== '';
    }

    return true;
  };

  /**
   * Checks if bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns {boolean} - True or false
   */
  isBracketTeethValid = ({ de3_bracket_teeth, de3_missing_teeth, de3_extract_teeth, de3_arch_to_treat } = {}) => {
    const currentBracketTeeth = de3_bracket_teeth || this.state.de3_bracket_teeth;
    const currentMissingTeeth = de3_missing_teeth || this.state.de3_missing_teeth;
    const currentExtractTeeth = de3_extract_teeth || this.state.de3_extract_teeth;
    const currentArchToTreat = de3_arch_to_treat || this.state.de3_arch_to_treat;

    return isBracketTeethValidHelper({
      bracketTeeth: currentBracketTeeth,
      missingTeeth: currentMissingTeeth,
      extractTeeth: currentExtractTeeth,
      archToTreat: currentArchToTreat,
    });
  };

  /**
   * Checks if auxiliariesNotes is valid based on selected auxiliaries
   * @param {object} data object with auxiliaries and auxiliariesNotes
   * @param {string} data.auxiliaries - Auxiliaries
   * @param {string} data.auxiliariesNotes - Auxiliaries Notes
   * @returns {boolean} - True or false
   */
  isAuxiliariesFieldValid = ({ de3_auxiliaries, de3_auxiliaries_notes } = {}) => {
    const currentAuxiliaries = de3_auxiliaries !== undefined && de3_auxiliaries !== null ? de3_auxiliaries : this.state.de3_auxiliaries;
    const currentAuxiliariesNotes =
      de3_auxiliaries_notes !== undefined && de3_auxiliaries_notes !== null ? de3_auxiliaries_notes : this.state.de3_auxiliaries_notes;

    if (currentAuxiliaries === 'Yes') {
      return currentAuxiliariesNotes !== '';
    }

    return true;
  };

  /**
   * Check if setupInstruction is valid based on selected setup
   * @param {object} data object with setupInstruction and setup
   * @param {string} data.setupInstruction - Setup Instruction
   * @param {string} data.setup - Setup
   * @returns {boolean} - True or false
   */
  isSetupInstructionValid = ({ de3_setup_instruction, de3_setup } = {}) => {
    const currentSetupInstruction =
      de3_setup_instruction !== undefined && de3_setup_instruction !== null ? de3_setup_instruction : this.state.de3_setup_instruction;
    const currentSetup = de3_setup || this.state.de3_setup;

    return isSetupInstructionValidHelper({
      setupInstruction: currentSetupInstruction,
      setup: currentSetup,
    });
  };

  /**
   * Returns whether DE3.0 form's required fields are completed, and if not, shows warning
   *
   * @param {boolean} warning - Whether to show warning or not
   * @param {object} values - Values to check
   * @function
   * @return {boolean} - True or false
   */
  isDE3FormComplete = (warning = true, values = null) => {
    const curentValues = values || this.state;
    let result = false;
    const isAuxiliariesFieldValid = this.isAuxiliariesFieldValid(curentValues);
    const isAnteriorPosteriorFieldValid = this.isAnteriorPosteriorFieldValid(curentValues);
    const isAnteriorPosteriorNotesFieldValid = this.isAnteriorPosteriorNotesFieldValid(curentValues);
    const isBracketTeethValid = this.isBracketTeethValid(curentValues);
    const isSetupInstructionValid = this.isSetupInstructionValid(curentValues);

    if (
      curentValues.de3_reason !== '' &&
      !curentValues.de3_next_appointment_date_warning &&
      curentValues.de3_intra_date !== '' &&
      !curentValues.de3_intra_date_warning &&
      !curentValues.de3_upper_smartwire_date_warning &&
      !curentValues.de3_lower_smartwire_date_warning &&
      isBracketTeethValid &&
      isAuxiliariesFieldValid &&
      isAnteriorPosteriorFieldValid &&
      isAnteriorPosteriorNotesFieldValid &&
      isSetupInstructionValid
    ) {
      result = true;
    }

    if (!result && warning) {
      if (curentValues.de3_next_appointment_date_warning) {
        if (document.querySelector('#de3_next_appointment_date_input')) {
          document.querySelector('#de3_next_appointment_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_next_appointment_date')) {
          document.querySelector('#de3_next_appointment_date').classList.add('warning-text');
        }
      }

      if (curentValues.de3_reason === '') {
        if (document.querySelector('#de3_reason_textarea')) {
          document.querySelector('#de3_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de3_reason')) {
          document.querySelector('#de3_reason').classList.add('warning-text');
        }
      }

      if (curentValues.de3_intra_date === '' || curentValues.de3_intra_date_warning) {
        if (document.querySelector('#de3_intra_date_input')) {
          document.querySelector('#de3_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_intra_date')) {
          document.querySelector('#de3_intra_date').classList.add('warning-text');
        }
      }

      if (!isBracketTeethValid) {
        if (document.querySelector('#de3_bracket_teeth')) {
          document.querySelector('#de3_bracket_teeth').classList.add('warning-text');
        }
      }

      if (!isAuxiliariesFieldValid) {
        if (document.querySelector('#de3_auxiliaries_notes')) {
          document.querySelector('#de3_auxiliaries_notes').classList.add('warning-border');
        }
        if (document.querySelector('#de3_auxiliaries')) {
          document.querySelector('#de3_auxiliaries').classList.add('warning-text');
        }
      }

      if (!isAnteriorPosteriorFieldValid) {
        if (document.querySelector('#de3_anterior_posterior')) {
          document.querySelector('#de3_anterior_posterior').classList.add('warning-text');
          handleAnteriorPosteriorCheckboxErrors(true);
        }
      }

      if (!isAnteriorPosteriorNotesFieldValid) {
        if (document.querySelector('#de3_anterior_posterior')) {
          document.querySelector('#de3_anterior_posterior').classList.add('warning-text');
          if (document.querySelector('#de3_anterior_posterior_notes')) {
            document.querySelector('#de3_anterior_posterior_notes').classList.add('warning-border');
          }
        }
      }

      if (!isSetupInstructionValid) {
        if (document.querySelector('#de3_setup_instruction')) {
          document.querySelector('#de3_setup_instruction').classList.add('warning-text');
        }
        if (document.querySelector('textarea[data-field="de3_setup_instruction"]')) {
          document.querySelector('textarea[data-field="de3_setup_instruction"]').classList.add('warning-border');
        }
        if (document.querySelector('#de3_setup')) {
          document.querySelector('#de3_setup').classList.add('warning-text');
        }
      }
    }

    return result;
  };

  isDEFormComplete = (warning = true, values = null) => {
    if (this.state.form === 'DE2.0') {
      return this.isDE2FormComplete(warning);
    }

    if (this.state.form === 'DE3.0') {
      return this.isDE3FormComplete(warning, values);
    }

    return false;
  };

  getDE2Form = (values) => {
    const input = _.cloneDeep(values);
    return (
      <DeForm2
        onInputChange_de2={this.onInputChange_de2}
        clearSpacingValue={this.clearSpacingValueDE2}
        onChangeSpacingOnBlur={this.onChangeSpacingOnBlurDE2}
        onFocus={this.onFocus_clearWarning}
        getDeHold={this.getDeHold}
        de2_reason={input.de2_reason}
        de2_initial_date={input.de2_initial_date}
        de2_intra_date={input.de2_intra_date}
        de2_upper_smartwire={input.de2_upper_smartwire}
        de2_upper_smartwire_date={input.de2_upper_smartwire_date}
        de2_lower_smartwire={input.de2_lower_smartwire}
        de2_lower_smartwire_date={input.de2_lower_smartwire_date}
        de2_arch_to_treat={input.de2_arch_to_treat}
        de2_arch_opposing_treat={input.de2_arch_opposing_treat}
        de2_setup={input.de2_setup}
        de2_setup_instruction={input.de2_setup_instruction}
        de2_setup_additional_instruction={input.de2_setup_additional_instruction}
        de2_bracket_instruction={input.de2_bracket_instruction}
        de2_teeth_restriction={input.de2_teeth_restriction}
        de2_bracket_remove={input.de2_bracket_remove}
        de2_bracket_add={input.de2_bracket_add}
        de2_ipr={input.de2_ipr}
        de2_ipr_instruction={input.de2_ipr_instruction}
        de2_spacing={input.de2_spacing}
        de2_spacing_upper={input.de2_spacing_upper}
        de2_spacing_lower={input.de2_spacing_lower}
        de2_spacing_instruction={input.de2_spacing_instruction}
        de2_special_instruction={input.de2_special_instruction}
        de2_prev_case_info={input.de2_prev_case_info}
        reupload={false}
        de2_initial_date_warning={input.de2_initial_date_warning}
        de2_intra_date_warning={input.de2_intra_date_warning}
        photoUpload={this.state.photoUpload}
        xrayUpload={this.state.xrayUpload}
        scanUpload={this.state.scanUpload}
        gen_2={this.state.gen_2}
        updateRecordState={this.updateRecordState}
        // iTero integration - props for DE2.0
        selectedIteroScanRecords={this.state.selectedIteroScanRecords}
        uploadMethod={this.state.uploadMethod}
      />
    );
  };

  /**
   * Determines if DE3.0 has lower arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasLower = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'lower';
  };

  /**
   * Determines if DE3.0 has upper arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasUpper = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'upper';
  };

  /**
   * Retrieves teeth sets for DE3.0
   * @function
   * @returns {object} - Teeth sets
   */
  getDE3ImpliedTeethSets = () => {
    const opposingTeeth = [];
    const hasUpper = this.de3HasUpper();
    const hasLower = this.de3HasLower();
    const setupIdeal = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'ideal';
    const setupCurrent = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'current';

    if (!hasUpper) {
      opposingTeeth.push(...teethReference.UR);
      opposingTeeth.push(...teethReference.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...teethReference.LR);
      opposingTeeth.push(...teethReference.LL);
    }

    const missingTeeth = this.state.de3_missing_teeth;
    const extractTeeth = _.difference(this.state.de3_extract_teeth, missingTeeth);
    const extractDisabled = missingTeeth;
    const extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    const bracketTeeth = _.difference(this.state.de3_bracket_teeth, bracketDisabled);

    let restrictImplied = _.difference(teethReference.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.de3_teeth_restriction, restrictImplied);
    if (setupCurrent) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    return {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };
  };

  /**
   * Retrieves form component for DE3.0
   * @function
   * @returns {object} - Form component
   */
  getDE3Form = (values) => {
    const input = _.cloneDeep(values);
    return (
      <DeForm3
        onInputChange_de3={this.onInputChange_de3}
        onFocus={this.onFocus_clearWarning}
        getDeHold={this.getDeHold}
        photoUpload={this.state.photoUpload}
        xrayUpload={this.state.xrayUpload}
        scanUpload={this.state.scanUpload}
        gen_2={this.state.gen_2}
        updateRecordState={this.updateRecordState}
        reupload={false}
        hasUpper={this.de3HasUpper}
        hasLower={this.de3HasLower}
        teethReference={teethReference}
        getImpliedTeethSets={this.getDE3ImpliedTeethSets}
        de3_reason={input.de3_reason}
        de3_next_appointment_date={input.de3_next_appointment_date}
        de3_intra_date={input.de3_intra_date}
        de3_upper_smartwire={input.de3_upper_smartwire}
        de3_upper_smartwire_date={input.de3_upper_smartwire_date}
        de3_lower_smartwire={input.de3_lower_smartwire}
        de3_lower_smartwire_date={input.de3_lower_smartwire_date}
        de3_arch_to_treat={input.de3_arch_to_treat}
        de3_arch_opposing_treat={input.de3_arch_opposing_treat}
        de3_upper_arch_opposing_treat={input.de3_upper_arch_opposing_treat}
        de3_lower_arch_opposing_treat={input.de3_lower_arch_opposing_treat}
        de3_setup={input.de3_setup}
        de3_setup_instruction={input.de3_setup_instruction}
        de3_setup_additional_instruction={input.de3_setup_additional_instruction}
        de3_bracket_teeth={input.de3_bracket_teeth}
        de3_missing_teeth={input.de3_missing_teeth}
        de3_extract_teeth={input.de3_extract_teeth}
        de3_teeth_restriction={input.de3_teeth_restriction}
        de3_prev_case_info={input.de3_prev_case_info}
        de3_anterior_posterior_r={input.de3_anterior_posterior_r}
        de3_anterior_posterior_l={input.de3_anterior_posterior_l}
        de3_anterior_posterior_corrections={input.de3_anterior_posterior_corrections}
        de3_anterior_posterior_notes={input.de3_anterior_posterior_notes}
        de3_midlines={input.de3_midlines}
        de3_auxiliaries={input.de3_auxiliaries}
        de3_auxiliaries_notes={input.de3_auxiliaries_notes}
        de3_next_appointment_date_warning={input.de3_next_appointment_date_warning}
        de3_intra_date_warning={input.de3_intra_date_warning}
        // iTero integration - props for DE3.0
        selectedIteroScanRecords={this.state.selectedIteroScanRecords}
        uploadMethod={this.state.uploadMethod}
        scansUploadMethod={this.state.scansUploadMethod}
        presets={this.presets}
        minPreset={this.minPreset}
        maxPreset={this.maxPreset}
        setBracketTeeth={this.setBracketTeeth}
      />
    );
  };

  getTermsComponent = () => {
    const defaultProps = {
      onButtonClick: this.onFocus_clearWarning,
      onSubmitClick: this.onButtonClick,
      agreement: true,
    };
    if (this.state.form === 'DE2.0') {
      return <Terms {...defaultProps} />;
    }

    if (this.state.form === 'DE3.0') {
      return (
        <Terms
          {...defaultProps}
          summaryComponent={SubmissionSummary}
          summaryProps={{
            de3_reason: this.state.de3_reason,
            de3_next_appointment_date: this.state.de3_next_appointment_date,
            de3_intra_date: this.state.de3_intra_date,
            de3_upper_smartwire: this.state.de3_upper_smartwire,
            de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
            de3_lower_smartwire: this.state.de3_lower_smartwire,
            de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
            de3_arch_to_treat: this.state.de3_arch_to_treat,
            de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
            de3_bracket_teeth: this.state.de3_bracket_teeth,
            de3_missing_teeth: this.state.de3_missing_teeth,
            de3_extract_teeth: this.state.de3_extract_teeth,
            de3_teeth_restriction: this.state.de3_teeth_restriction,
            de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
            de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
            de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
            de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
            de3_midlines: this.state.de3_midlines,
            de3_auxiliaries: this.state.de3_auxiliaries,
            de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
            de3_setup: this.state.de3_setup,
            de3_setup_instruction: this.state.de3_setup_instruction,
            de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
            getImpliedTeethSets: this.getDE3ImpliedTeethSets,
            photoUpload: this.state.photoUpload,
            xrayUpload: this.state.xrayUpload,
            scanUpload: this.state.scanUpload,
            // iTero integration - props to be passed to the summary component
            selectedIteroScanRecords: this.state.selectedIteroScanRecords,
            uploadMethod: this.state.uploadMethod,
          }}
        />
      );
    }
  };

  getPosition() {
    const position = [
      { name: 'Overview' },
      { name: 'Impressions' },
      { name: 'Photos' },
      { name: 'X-Rays' },
      { name: 'Refinement Request Form' },
      { name: 'Doctor Information' },
      { name: 'Submit' },
    ];

    return position;
  }

  getActivePositionByStepperName(stepper_name) {
    const position = this.getPosition();
    let active_index = 0;

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        active_index = i;
        break;
      }
    }

    return active_index;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getComponentByStepperName(stepper_name) {
    let component = (
      <Overview
        id={this.state.id}
        form={this.state.form}
        questions={this.state.gen_2 ? this.state.gen_2_overview_questions : this.state.overview_questions}
        onOverviewCheckboxClick={this.onOverviewCheckboxClick}
        gen_2={this.state.gen_2}
      />
    );

    switch (stepper_name) {
      case 'Overview':
        component = (
          <Overview
            id={this.state.id}
            form={this.state.form}
            questions={this.state.gen_2 ? this.state.gen_2_overview_questions : this.state.overview_questions}
            onOverviewCheckboxClick={this.onOverviewCheckboxClick}
            gen_2={this.state.gen_2}
          />
        );
        break;

      case 'Photos':
        component = (
          <Photos
            id={this.state.digital_id}
            photoUpload={this.state.photoUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onIncompleteSave={this.onIncompleteSave}
            location="incomplete"
            upload_state={this.photos_upload}
            upload_content={this.state.photos_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            updateRecordState={this.updateRecordState}
            isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
            isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
            showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
            onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
            iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
          />
        );
        break;
      case 'X-Rays':
        component = (
          <Radiographs
            id={this.state.digital_id}
            xrayUpload={this.state.xrayUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onIncompleteSave={this.onIncompleteSave}
            location="incomplete"
            upload_state={this.radiographs_upload}
            upload_content={this.state.radiographs_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            updateRecordState={this.updateRecordState}
          />
        );
        break;
      case 'Impressions':
        component = (
          <Scans
            id={this.state.digital_id}
            scanUpload={this.state.scanUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onEmptyFileError={this.onEmptyFileError}
            location="incomplete"
            text="de"
            upload_state={this.scans_upload}
            upload_content={this.state.scans_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            // iTero integration - props to be passed to the scans component
            uploadMethod={this.state.uploadMethod}
            onScansUploadMethodChange={this.handleScansUploadMethodChange}
            iteroSelectionProps={this.getIteroSelectionProps()}
            recordViewerSubtitle={this.getRecordViewerSubtitle(true, this.isLoadingIteroPhotos())}
            isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
          />
        );
        break;
      case 'Refinement Request Form':
        if (this.state.form === 'DE2.0') {
          component = this.getDE2Form({
            scanDate: this.state.scanDate,
            reason: this.state.reason,
            upperSmartWire: this.state.upperSmartWire,
            lowerSmartWire: this.state.lowerSmartWire,
            setup: this.state.setup,
            restrictions: this.state.restrictions,
            restrictionsTeeth: this.state.restrictionsTeeth,
            ipr: this.state.ipr,
            iprDialog: this.state.iprDialog,
            residual: this.state.residual,
            upper: this.state.upper,
            lower: this.state.lower,
            de2_reason: this.state.de2_reason,
            de2_initial_date: this.state.de2_initial_date,
            de2_intra_date: this.state.de2_intra_date,
            de2_upper_smartwire: this.state.de2_upper_smartwire,
            de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
            de2_lower_smartwire: this.state.de2_lower_smartwire,
            de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
            de2_arch_to_treat: this.state.de2_arch_to_treat,
            de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
            de2_setup: this.state.de2_setup,
            de2_setup_instruction: this.state.de2_setup_instruction,
            de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
            de2_bracket_instruction: this.state.de2_bracket_instruction,
            de2_teeth_restriction: this.state.de2_teeth_restriction,
            de2_bracket_remove: this.state.de2_bracket_remove,
            de2_bracket_add: this.state.de2_bracket_add,
            de2_ipr: this.state.de2_ipr,
            de2_ipr_instruction: this.state.de2_ipr_instruction,
            de2_spacing: this.state.de2_spacing,
            de2_spacing_upper: this.state.de2_spacing_upper,
            de2_spacing_lower: this.state.de2_spacing_lower,
            de2_spacing_instruction: this.state.de2_spacing_instruction,
            de2_special_instruction: this.state.de2_special_instruction,
            de2_prev_case_info: this.state.de2_prev_case_info,
            de2_initial_date_warning: this.state.de2_initial_date_warning,
            de2_intra_date_warning: this.state.de2_intra_date_warning,
          });
        } else if (this.state.form === 'DE3.0') {
          // DE3.0 set component on stepper change
          component = this.getDE3Form({
            de3_reason: this.state.de3_reason,
            de3_next_appointment_date: this.state.de3_next_appointment_date,
            de3_intra_date: this.state.de3_intra_date,
            de3_upper_smartwire: this.state.de3_upper_smartwire,
            de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
            de3_lower_smartwire: this.state.de3_lower_smartwire,
            de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
            de3_arch_to_treat: this.state.de3_arch_to_treat,
            de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
            de3_upper_arch_opposing_treat: this.state.de3_upper_arch_opposing_treat,
            de3_lower_arch_opposing_treat: this.state.de3_lower_arch_opposing_treat,
            de3_setup: this.state.de3_setup,
            de3_setup_instruction: this.state.de3_setup_instruction,
            de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
            de3_bracket_teeth: this.state.de3_bracket_teeth,
            de3_missing_teeth: this.state.de3_missing_teeth,
            de3_extract_teeth: this.state.de3_extract_teeth,
            de3_teeth_restriction: this.state.de3_teeth_restriction,
            de3_prev_case_info: this.state.de3_prev_case_info,
            de3_next_appointment_date_warning: this.state.de3_next_appointment_date_warning,
            de3_intra_date_warning: this.state.de3_intra_date_warning,
            de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
            de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
            de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
            de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
            de3_midlines: this.state.de3_midlines,
            de3_auxiliaries: this.state.de3_auxiliaries,
            de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
          });
        }
        break;
      case 'Doctor Information':
        component = (
          <ShippingInfo
            onInputChange={this.onInputChange_address}
            address={this.state.address}
            addresses={this.state.addresses}
            onFocus={this.onFocus_clearWarning}
            firstName={this.state.doctorfirstName}
            lastName={this.state.doctorlastName}
            isDigital="true"
          />
        );
        break;

      case 'Submit':
        component = this.getTermsComponent();
        break;
      default:
    }

    return component;
  }

  redirectUserToCaseDetail() {
    const case_id = this.state.digital_id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(/digital\/[A-Za-z0-9\-\/\?=]+/, `case/${case_id}`);

    this.props.history.push(new_path);
  }

  isUploadInProgress() {
    return (
      (this.state.photos_uploading && this.state.photos_uploading.length > 0) ||
      (this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0) ||
      (this.state.scans_uploading && this.state.scans_uploading.length > 0) ||
      this.isLoadingIteroPhotos()
    );
  }
  /**
   * Get Base ID of the case for db item check
   * @function
   */
  createNavigatorTableRowForDE = () => {
    let case_id = this.state.digital_id;
    Axios.get(`/apiv3/case/${case_id}/singlecasedetailitem/${'link_id'}`)
      .then((res) => {
        case_id = res.data.item;
        this.checkIfCaseOptedInNavigator(case_id);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Check if case is opted in treatment workflow
   * @function
   * @param {String} returns Case ID
   */
  checkIfCaseOptedInNavigator = (case_id) => {
    if (case_id) {
      Axios.get(`/apiv3/case/${case_id}/singlecasedetailitem/${'treatment_workflow'}`)
        .then((res) => {
          if (res.data && res.data.item === true) {
            this.createNavigatorRow(this.state.digital_id, case_id);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  /**
   * Post a new treatment workflow row for the DE case
   * @function
   * @param {String} returns Case ID
   */
  createNavigatorRow = (case_id, base_id) => {
    let fdata = new FormData();
    fdata.append('treatment_workflow', 'opt_in');
    Axios.post(`/apiV2/caseaction/${case_id}/treatment_workflow_action`, fdata)
      .then(() => {
        Axios.put(`/apiv3/case/${case_id}/navigator/opt_in`, { case_ids: case_id }).catch(function (err) {
          console.log(err);
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  onButtonClick = (event) => {
    event.preventDefault();
    this.onFocus_clearWarning();

    let that = this;

    let newState = {
      name: '',
      stepper: this.state.stepper,
      active: 0,
      component: '',
      buttons: '',
    };

    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);

    switch (event.currentTarget.dataset.description) {
      case 'Overview':
        if (event.currentTarget.dataset.position === 'next') {
          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'Photos':
        if (event.currentTarget.dataset.position === 'next') {
          if (!this.hasOnePhoto() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'X-Rays':
        if (event.currentTarget.dataset.position === 'next') {
          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'Impressions':
        if (event.currentTarget.dataset.position === 'next') {
          if (!this.hasTwoScans() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'Refinement Request Form':
        if (event.currentTarget.dataset.position === 'next') {
          if (this.state.form === 'DE2.0' && !this.isDE2FormComplete()) {
            let warning = document.querySelector('#warning-submit');
            let error = '';

            warning.classList.add('warning-display');

            if (this.state.de2_reason === '') {
              error = error + '<li><a href="#de-stepper">Reason</a></li>';
            }

            if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
              error = error + '<li><a href="#de2_reason">Bond Date</a></li>';
            }

            if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
              error = error + '<li><a href="#de2_reason">Scan Date</a></li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
            });

            return;
          }

          // DE3.0 form validation stepper next button
          if (this.state.form === 'DE3.0' && !this.isDE3FormComplete()) {
            let warning = document.querySelector('#warning-submit');
            let error = '';

            warning.classList.add('warning-display');

            if (this.state.de3_reason === '') {
              error = error + '<li><a href="#de-stepper">Reason</a></li>';
            }

            if (this.state.de3_next_appointment_date_warning) {
              error = error + '<li><a href="#de3_next_appointment_date">Appointment Date</a></li>';
            }

            if (this.state.de3_intra_date === '' || this.state.de3_intra_date_warning) {
              error = error + '<li><a href="#de3_next_appointment_date">Scan Date</a></li>';
            }

            if (!this.isBracketTeethValid()) {
              error = error + '<li><a href="#de3_bracket_teeth">Bracket Teeth</a></li>';
            }

            if (!this.isAuxiliariesFieldValid()) {
              error = error + '<li><a href="#de3_auxiliaries">Auxiliaries</a></li>';
            }

            if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
              error = error + '<li><a href="#de3_teeth_restriction">Anterior-Posterior</a></li>';
            }

            if (!this.isSetupInstructionValid()) {
              error = error + '<li><a href="#de3_setup_instruction">Additional Instructions</a></li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
            });

            return;
          }
          const stepper = this.state.stepper;
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;

          newState = {
            name: nextStep,
            stepper: stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }
        break;

      case 'Doctor Information':
        if (event.currentTarget.dataset.position === 'next') {
          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'Submit':
        if (event.currentTarget.dataset.position === 'submit') {
          if (!this.isDEFormComplete() || !this.isPhotoUploadLengthValid() || !this.isScansLengthValid()) {
            let warning = document.querySelector('#warning-submit');
            let error = '';

            warning.classList.add('warning-display');

            if (!this.isPhotoUploadLengthValid()) {
              error = error + `<li>${this.getPhotoSectionErrorMessage()}</li>`;
            }

            if (!this.isScansLengthValid()) {
              if (this.getScansLength() > 2) {
                error = error + '<li>More than 2 STL Files</li>';
              } else {
                error = error + '<li>Toothprints</li>';
              }
            }

            if (!this.isDEFormComplete()) {
              error = error + '<li>Digital Enhancement Form</li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
            });

            return;
          }

          //Although all criteria is might, upload may still be in-progress
          if (this.isUploadInProgress()) {
            let warning = document.querySelector('#warning-submit');

            if (warning) {
              warning.innerHTML = '<ul><li>Upload in Progress</li></ul>';
            }

            this.setState({
              warning: true,
              warning_title: 'Submission Error',
            });

            return;
          }

          let terms = document.querySelector('#terms');

          if (!(terms && terms.checked)) {
            let warning = document.querySelector('#warning-submit');
            warning.classList.add('warning-display');
            warning.innerHTML = '<ul><li>Terms & Conditions</li></ul>';

            this.setState({
              warning: true,
            });

            return;
          }

          this.setState({
            component: <SubmitterLoader />,
            buttons: <div />,
            hide: true,
          });

          let formData = this.getCurrentData();

          // iTero integration - delete itero photos if upload method is manual
          if (formData.get('uploadMethod') === 'manual' && this.state.selectedIteroId) {
            this.props.deleteIteroPhotosFromDE(
              {
                digitalId: this.state.digital_id,
                iteroId: this.state.selectedIteroId,
              },
              {
                onSuccess: () => {
                  this.lastSave(formData, that);
                },
              }
            );
          } else {
            this.lastSave(formData, that);
          }
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }
        break;

      default:
      //Do Nothing
    }

    if (newState.name) {
      //Only update if cases match
      this.setState(newState);

      this.onIncompleteSave(this.convertStepperName(event.currentTarget.dataset.description, event.currentTarget.dataset.position));
    }
  };

  lastSave = (formData, ctx) => {
    const currentCtx = ctx || this;
    Axios.post('/api/digital/', formData).then(function (result) {
      // Submit
      Axios.post(`/api/submitdigital/?id=${currentCtx.state.digital_id}&de_hold=${currentCtx.state.de_hold}`)
        .then(function (result) {
          if (result.data.message === 'submitted') {
            currentCtx.createNavigatorTableRowForDE();
            currentCtx.setState({
              component: <SubmissionConfirmation onButtonClick={currentCtx.redirectUserToCaseDetail} />,
            });

            //Email here
            if (!currentCtx.state.de_hold) {
              Axios.post(
                `/api/email/?slug=digital-enhancement-submission-confirmation-1&doctorId=${currentCtx.state.doctorId}&caseId=${removeCaseIdInitialNumber(
                  currentCtx.state.digital_id
                )}&method=standard`
              );
            } else {
              Axios.post(
                `/api/email/?slug=de-submission-clinical-review-initiated&caseId=${currentCtx.state.digital_id}&method=standard&provider=${window.location.origin}`
              );
            }
          }
        })
        .finally(() => {
          sendRefreshCaseListMessage();
          sendRefreshDashboardMessage();
        });
    });
  };

  updateStepperState = (newState, activeState) => {
    if (activeState === this.getActivePositionByStepperName('Submit')) {
      newState.stepper[this.getActivePositionByStepperName('Submit')] = 2;
    }

    if (this.isDEFormComplete()) {
      newState.stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
    } else {
      newState.stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 0;
    }

    switch (activeState) {
      case 1:
        if (this.isScansLengthValid()) {
          newState.stepper[this.getActivePositionByStepperName('Impressions')] = 1;
        }
        break;

      case 2:
        if (this.isPhotoUploadLengthValid()) {
          newState.stepper[this.getActivePositionByStepperName('Photos')] = 1;
        }
        break;

      case 3:
        if (this.state.xrayUpload.length >= 1) {
          newState.stepper[this.getActivePositionByStepperName('X-Rays')] = 1;
        }
        break;

      default:
    }

    return newState;
  };

  onModalShow = () => {
    this.setState({
      showCancelCase: true,
    });
  };

  onModalDismiss = () => {
    this.setState({
      showCancelCase: false,
    });
  };

  convertStepperName = (name, movement = '') => {
    let result = '';

    switch (name) {
      case 'Overview':
        if (movement === 'prev') {
          result = 'overview';
        } else if (movement === 'next') {
          result = 'scans';
        } else {
          result = 'overview';
        }

        break;

      case 'Impressions':
        if (movement === 'prev') {
          result = 'overview';
        } else if (movement === 'next') {
          result = 'photos';
        } else {
          result = 'scans';
        }

        break;

      case 'Photos':
        if (movement === 'prev') {
          result = 'scans';
        } else if (movement === 'next') {
          result = 'radiographs';
        } else {
          result = 'photos';
        }

        break;
      case 'X-Rays':
        if (movement === 'prev') {
          result = 'photos';
        } else if (movement === 'next') {
          result = 'enhancement';
        } else {
          result = 'radiographs';
        }

        break;

      case 'Refinement Request Form':
        if (movement === 'prev') {
          result = 'radiographs';
        } else if (movement === 'next') {
          result = 'doctor';
        } else {
          result = 'enhancement';
        }

        break;

      case 'Doctor Information':
        if (movement === 'prev') {
          result = 'enhancement';
        } else if (movement === 'next') {
          result = 'submit';
        } else {
          result = 'doctor';
        }

        break;

      case 'Submit':
        if (movement === 'prev') {
          result = 'doctor';
        } else if (movement === 'next') {
          result = 'submit';
        } else {
          result = 'submit';
        }

        break;
      default:
    }

    return result;
  };

  onStepperClick = (event) => {
    //Check if any of these conditions are false before allow switching
    let activeState = this.state.active;

    let newState = {
      name: event.currentTarget.dataset.description,
      stepper: this.state.stepper,
      active: this.getActivePositionByStepperName(event.currentTarget.dataset.description),
      component: this.getComponentByStepperName(event.currentTarget.dataset.description),
      buttons: (
        <>
          <Button theme="secondary" data-position={'prev'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Back
          </Button>
          <Button data-position={'next'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Next
          </Button>
        </>
      ),
    };

    switch (event.currentTarget.dataset.description) {
      case 'Overview':
        newState.buttons = (
          <>
            <Button data-position={'next'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
              Next
            </Button>
          </>
        );

        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Photos':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'X-Rays':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Impressions':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Refinement Request Form':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Doctor Information':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Submit':
        newState.buttons = (
          <>
            <Button theme="secondary" data-position={'prev'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
              Back
            </Button>
          </>
        );

        newState = this.updateStepperState(newState, activeState);

        break;

      default:
      //Do Nothing
    }

    if (newState.name) {
      this.onFocus_clearWarning();
      this.setState(newState);
      this.onIncompleteSave(this.convertStepperName(event.currentTarget.dataset.description));
      if (event.currentTarget.dataset.description === 'Impressions') {
        this.showMoreTwoScansWarning(this.state.scanUpload);
      }
    }
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  getDeHold(de_hold) {
    this.setState({
      de_hold: de_hold,
    });
  }

  /**
   * Checks whether the de submission is DE 3 or more
   * @function
   * @return {Boolean} Whether DE 3+ or not
   */
  isDE3Plus() {
    const de_split = this.state.digital_id.split('-DE');
    if (de_split.length === 2) {
      return parseInt(de_split[1]) >= 3;
    }
    return false;
  }

  /**
   * Returns the title of de submission
   * @function
   * @return {String} The title of de submission
   */
  getTitle() {
    if (this.state.patient && this.state.patient.first_name && this.state.patient.last_name) {
      return `Digital Enhancement - ${this.state.patient.first_name} ${this.state.patient.last_name}`;
    } else {
      return 'Digital Enhancement';
    }
  }

  /**
   * Retuns the content of the stepper
   * @function
   * @return {Array} The content list
   */
  getStepperContent() {
    return [
      {
        description: 'Overview',
        label: 'Overview',
        active: this.state.active === 0,
        completed: this.state.stepper[0] === 1,
      },
      {
        description: 'Impressions',
        label: 'Toothprints',
        active: this.state.active === 1,
        completed: this.state.stepper[1] === 1,
        uploading: this.state.uploadMethod === 'manual' && this.state.scans_uploading && this.state.scans_uploading.length > 0,
      },
      {
        description: 'Photos',
        label: 'Photos',
        active: this.state.active === 2,
        completed: this.state.stepper[2] === 1,
        uploading: (this.state.photos_uploading && this.state.photos_uploading.length > 0) || this.isLoadingIteroPhotos(),
      },
      {
        description: 'X-Rays',
        label: 'Radiographs (Optional)',
        active: this.state.active === 3,
        completed: this.state.stepper[3] === 1,
        uploading: this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0,
      },
      {
        description: 'Refinement Request Form',
        label: 'DE Form',
        active: this.state.active === 4,
        completed: this.state.stepper[4] === 1,
      },
      {
        description: 'Doctor Information',
        label: 'Shipping Info',
        active: this.state.active === 5,
        completed: this.state.stepper[5] === 1,
      },
      {
        description: 'Submit',
        label: 'Submit',
        active: this.state.active === 6,
        completed: this.state.stepper[6] === 1,
      },
    ];
  }
  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    const file_type = folder && folder === 'photos' ? 'photoUpload' : 'xrayUpload';
    let new_file_upload = this.state[file_type];
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);
    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ [file_type]: new_file_upload });
    }
  };

  /**
   * Sets bracket teeth
   * @function
   * @param {list} teeth - Bracket teeth
   */
  setBracketTeeth = (teeth) => {
    const bracket_teeth = teeth !== undefined ? teeth : this.state.de3_bracket_teeth;
    const ns = {
      de3_reason: this.state.de3_reason,
      de3_next_appointment_date: this.state.de3_next_appointment_date,
      de3_intra_date: this.state.de3_intra_date,
      de3_upper_smartwire: this.state.de3_upper_smartwire,
      de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
      de3_lower_smartwire: this.state.de3_lower_smartwire,
      de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
      de3_arch_to_treat: this.state.de3_arch_to_treat,
      de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
      de3_upper_arch_opposing_treat: this.state.de3_upper_arch_opposing_treat,
      de3_lower_arch_opposing_treat: this.state.de3_lower_arch_opposing_treat,
      de3_setup: this.state.de3_setup,
      de3_setup_instruction: this.state.de3_setup_instruction,
      de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
      de3_bracket_teeth: bracket_teeth,
      de3_missing_teeth: this.state.de3_missing_teeth,
      de3_extract_teeth: this.state.de3_extract_teeth,
      de3_teeth_restriction: this.state.de3_teeth_restriction,
      de3_prev_case_info: this.state.de3_prev_case_info,
      de3_next_appointment_date_warning: this.state.de3_next_appointment_date_warning,
      de3_intra_date_warning: this.state.de3_intra_date_warning,
      de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
      de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
      de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
      de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
      de3_midlines: this.state.de3_midlines,
      de3_auxiliaries: this.state.de3_auxiliaries,
      de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
    };
    this.setState({ ...ns, component: this.getDE3Form(ns) });

    const stepper = this.state.stepper;

    if (this.isDE3FormComplete(true, ns)) {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
    } else {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 0;
    }
    this.setState({ stepper: stepper });
    this.onFocus_clearWarning();
  };

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <CircleLoader fullscreen />;
    } else {
      return (
        <div>
          <Helmet>
            <title>DE Submission | InBrace Smile Design Studio™</title>
          </Helmet>
          <div className="title-height content__header">
            {!this.state.hide ? (
              <Button
                disabled={this.isUploadInProgress()}
                onClick={(e) => {
                  this.onIncompleteSave(e, '', {
                    refreshCaseList: true,
                    refreshDashboard: true,
                  });
                }}
                data-redirect="true"
                data-position="save"
              >
                Save
              </Button>
            ) : null}
            <h1 className="fs-exclude">{this.getTitle()}</h1>
          </div>
          <div>
            {this.state.hide ? null : (
              <div id="de-stepper">
                <CardContainer>
                  <Stepper activeStepper={this.state.active} stepperContent={this.getStepperContent()} onStepperClick={this.onStepperClick} />
                </CardContainer>
              </div>
            )}
            <CardContainer className="case-card-container mt-sm pad-lg">
              {this.state.component}
              {!this.state.hide && (
                <ErrorMessage
                  className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
                  title={this.state.warning_title}
                  onClose={this.removeWizardErrorMsg}
                >
                  <div id="warning-submit" />
                </ErrorMessage>
              )}
            </CardContainer>

            {!this.state.hide && (
              <div className="case-form-controls">
                {this.state.buttons}
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    const disabled = !userHasPermission('IPP_EDIT', user_roles_and_permissions.permissions);
                    return (
                      <Button theme="revise" onClick={this.onModalShow} disabled={disabled} data-redirect="true">
                        Delete
                      </Button>
                    );
                  }}
                </UserPermissionsContext.Consumer>
              </div>
            )}
          </div>

          {this.state.showEmptyFileModal ? <EmptyFileErrorModal onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} theme="ipp" /> : null}

          {this.state.showCancelCase === true ? (
            <Modal
              preset="decision"
              header_text="Delete Draft"
              modal_class="modal-content-warning"
              message_text={
                <span>
                  Are you sure you want to delete this draft digital enhancement
                  <br />
                  You will not be able to undo this action if you proceed.
                </span>
              }
              confirm_btn_text="Delete"
              close_btn_text="Cancel"
              after_confirm={true}
              after_confirm_text="Deleting Draft..."
              onCloseButtonClick={this.onModalDismiss}
              onConfirmButtonClick={this.onRemoveDraft}
            />
          ) : null}

          {this.state.showSaveModal === true ? (
            <Modal
              preset="decision"
              header_text="Draft Saved"
              message_text={
                <span>
                  Your draft has been successfully saved! Click <span className="emphasis">Continue</span> to resume your submission
                </span>
              }
              confirm_btn_text="Continue"
              close_btn_text="Return To Case List"
              onCloseButtonClick={this.onRedirectManageCases}
              onConfirmButtonClick={() => {
                this.setState({ showSaveModal: false });
              }}
            />
          ) : null}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
    // iTero integration - redux state
    licenseNumbers: getLicenseNumbers(state),
    isFetchIteroPhotosPending: getFetchIteroPhotosPending(state),
    isAddIteroPhotosToDEPending: getAddIteroPhotosToDEPending(state),
    isDeleteIteroPhotosFromDEPending: getDeleteIteroPhotosFromDEPending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // iTero integration - redux actions
      fetchLicenseNumbers,
      updateCaseIteroFiles,
      buildRecordStates,
      fetchIteroScans,
      addIteroPhotosToDE,
      deleteIteroPhotosFromDE,
      fetchIteroPhotos,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(DigitalEnhancement));
