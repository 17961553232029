import React from 'react';
import PropTypes from 'prop-types';

const themes = ['primary', 'limited', 'provider'];

/**
 * Displays a basic line division
 * @component
 */
function LineDivision({ theme, className, ...props }) {
  const themeClassName = themes.includes(theme) ? theme : themes[0];
  const classNames = `${className ? className : ''} line-division line-division--${themeClassName}`;
  return <div className={classNames} {...props} />;
}

LineDivision.propTypes = {
  theme: PropTypes.oneOf(themes),
};

LineDivision.defaultProps = {
  theme: themes[0],
};

export default LineDivision;
