/**
 * File: record_reupload.js - Contains logic that allow reupload to happen
 * Copyright: (c) Copyright December 2018 by InBrace
 * Authors: David Vu, Dhruvang Patel
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Internal Components
import DeForm from '../de_submission/de_form';
import DeForm2 from '../de_submission/de_form2';
import DeForm3 from '../de_submission/de_form3';
import DxtxPlan from '../case_submission/dxtx_plan';
import Loader from '../../components/loader/loading';
import Photos from '../case_submission/photos';
import Radiographs from '../case_submission/radiographs';
import Scans from '../case_submission/scans';
import SubmissionConfirmation from './submission_confirmation';
import Submit from './submit';
import SubmitterLoader from '../case_submission/submitting_loader';
import TxPlan from '../case_submission/tx_plan';
import TxPlan3 from '../case_submission/tx_plan_3';
import CaseSubmissionSummary from '../case_submission/submission_summary';
import DESubmissionSummary from '../de_submission/de_form3_submission_summary';
// Internal Functions
import { setTokenHeader, getRootPath, textFieldCheck, textFieldLimited, removeEmoji, getDoctorIdFromPath, removeOpposingTeeth } from '../../common/functions';
import { isSetupInstructionValid as isSetupInstructionValidHelper } from '../../common/tx_dx_form_utils';
import { isValidDate } from '../../common/helpers';
import { scrollToTop } from '../../common/scroll';
import { getTempLink } from '../../common/dropbox';
import { isBracketTeethValid as isBracketTeethValidHelper } from '../../common/tx_dx_form_utils';
import { handleAnteriorPosteriorCheckboxErrors } from '../../common/case/case_submission';
import { hasTwoScansHelper, getIteroInitialState, WithIteroScansSelection, getMappedLicenseNumbersHelper } from '../../common/itero_utils';
import { isDateInBetween } from '../../common/date';
import { sendRefreshCaseListMessage, sendRefreshDashboardMessage } from '../../common/storage';

// nomenclature
import * as nomenclature from '../../common/nomenclature';
import CircleLoader from '../../components/loader/circle_loader';
import CardContainer from '../components/container/card_container';
import Stepper from '../components/stepper';
import Button from '../components/buttons/button';
import ErrorMessage from '../components/container/error_message';
import AlertContainer from '../components/container/alert_container';

// Context
import { UserPermissionsContext } from '../../context/user_permission';

// Modals
import EmptyFileErrorModal from '../../components/modal/empty_file';

// Redux
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { fetchIteroScans, fetchIteroPhotos, getIteroPhotoRecordsForRecordsReupload } from '../../redux/actions/ipp/itero/itero_files';
import { getFetchIteroPhotosPending, getGetIteroPhotoRecordsForRecordsReuploadPending } from '../../redux/reducers/ipp/itero/itero_files';
import { fetchLicenseNumbers } from '../../redux/actions/ipp/itero/itero_license_numbers';
import { getLicenseNumbers } from '../../redux/reducers/ipp/itero/itero_license_numbers';
import { buildRecordStates } from '../../redux/actions/record_viewer/record_viewer';

class Teeth {
  static UR = _.range(8, 0, -1).map((item) => 'UR' + item);
  static UL = _.range(1, 9).map((item) => 'UL' + item);
  static LR = _.range(8, 0, -1).map((item) => 'LR' + item);
  static LL = _.range(1, 9).map((item) => 'LL' + item);
}

Teeth.All = [].concat(Teeth.UR).concat(Teeth.UL).concat(Teeth.LR).concat(Teeth.LL);

class RecordReUpload extends WithIteroScansSelection {
  static contextType = UserPermissionsContext;

  constructor(props) {
    super(props);

    // Stepper Color Decipher
    // 0 - Grey (disable, 'empty')
    // 1 - Green (completed)
    // 2 - light-blue (available)
    // 3 - dark-blue (active)
    let isDigitalEnhancement = false;

    if (this.props.location.pathname.indexOf('-R') >= 0 || this.props.location.pathname.indexOf('-DE') >= 0) {
      isDigitalEnhancement = true;
    }

    this.minPreset = 5;
    this.maxPreset = 7;
    this.presets = RecordReUpload.makePresets(this.minPreset, this.maxPreset);

    this.state = {
      id: '',
      comment: '',
      allow_error_msg: true,
      name: 'Dx/Tx Plan',
      stepper: [1, 1, 1, 1, 2],
      active: 0,
      component: <Loader />,
      buttons: (
        <Button data-position={'next'} data-description={'Impressions'} onClick={this.onButtonClick}>
          Next
        </Button>
      ),
      showCancelCase: false,
      showUploadSubmitted: false,
      hide: false,
      default_plan_version: 'TX2.0',
      loading: true,
      warning: false,
      warning_title: 'Incomplete Fields',
      showEmptyFileModal: false,

      //Upload in progress
      photos_uploading: [],
      radiographs_uploading: [],
      scans_uploading: [],

      // iTero integration - initial state
      ...getIteroInitialState({
        orgSelectedIteroScanIds: [],
        orgFetchedIteroScansNextUrlCursor: null,
        orgSelectedIteroId: '',
        orgFetchedIteroScans: [],
        shouldFetchIteroPhotos: false,
        shouldAllowToRemoveAllIteroPhotos: true,
      }),

      isDigitalEnhancement: isDigitalEnhancement,
      //Dxtx Plan
      //------------------------ Section 0 ------------------------
      dxtxSection0_chiefComplaint: '',
      //------------------------ Section 1 ------------------------
      dxtxSection1_mx: '',
      dxtxSection1_gingival: '',
      dxtxSection1_upperMidline2Facial: '',
      dxtxSection1_upperMidline2FacialRight: '',
      dxtxSection1_upperMidline2FacialLeft: '',
      dxtxSection1_rightMolars: '',
      dxtxSection1_rightCanine: '',
      dxtxSection1_leftMolars: '',
      dxtxSection1_leftCanine: '',
      dxtxSection1_overjet: false,
      dxtxSection1_anterior: false,
      dxtxSection1_deep: false,
      dxtxSection1_narrow: false,
      dxtxSection1_posterior: false,
      dxtxSection1_class: false,
      dxtxSection1_crowding: false,
      dxtxSection1_spaces: false,
      dxtxSection1_flared: false,
      dxtxSection1_notes: '',
      //------------------------ Section 2 ------------------------
      dxtxSection2_arch: '',
      dxtxSection2_teeth: '',
      dxtxSection2_teethCustom: '',
      dxtxSection2_anteriorMaintainLeft: false,
      dxtxSection2_anteriorMaintainRight: false,
      dxtxSection2_anteriorImproveLeft: false,
      dxtxSection2_anteriorImproveRight: false,
      dxtxSection2_anteriorCorrectLeft: false,
      dxtxSection2_anteriorCorrectRight: false,
      dxtxSection2_toothMovement: '',
      dxtxSection2_toothMovementSelection: [],
      dxtxSection2_toothExtractionSelection: [],
      // Expose tooth levels based on arch selection
      dxtxSection2_toothUpper: true,
      dxtxSection2_toothLower: true,
      //
      dxtxSection2_overjet: '',
      dxtxSection2_overbiteOpenExtrudeAnteriorUpper: false,
      dxtxSection2_overbiteOpenExtrudeAnteriorLower: false,
      dxtxSection2_overbiteOpenIntrudePosteriorUpper: false,
      dxtxSection2_overbiteOpenIntrudePosteriorLower: false,
      dxtxSection2_overbiteDeepIntrudeAnteriorUpper: false,
      dxtxSection2_overbiteDeepIntrudeAnteriorLower: false,
      dxtxSection2_overbiteDeepExtrudePosteriorUpper: false,
      dxtxSection2_overbiteDeepExtrudePosteriorLower: false,
      dxtxSection2_midlineUpper: '',
      dxtxSection2_midlineUpperRight: '',
      dxtxSection2_midlineUpperLeft: '',
      dxtxSection2_midlineLower: '',
      dxtxSection2_midlineLowerRight: '',
      dxtxSection2_midlineLowerLeft: '',
      dxtxSection2_posterior: '',
      dxtxSection2_spacing: '',
      dxtxSection2_spacingValue: '',
      dxtxSection2_resolveUpperExpand: false,
      dxtxSection2_resolveUpperProcline: false,
      dxtxSection2_resolveUpperIPRAnterior: false,
      dxtxSection2_resolveUpperIPRPosteriorRight: false,
      dxtxSection2_resolveUpperIPRPosteriorLeft: false,
      dxtxSection2_resolveUpperExtraction: false,
      dxtxSection2_resolveLowerExpand: false,
      dxtxSection2_resolveLowerProcline: false,
      dxtxSection2_resolveLowerIPRAnterior: false,
      dxtxSection2_resolveLowerIPRPosteriorRight: false,
      dxtxSection2_resolveLowerIPRPosteriorLeft: false,
      dxtxSection2_resolveLowerExtraction: false,
      dxtxSection2_special: '',
      // Digital Enhancement code below
      //Doctors Info
      address: '',
      addresses: [],
      //Refinement Form
      scanDate: '',
      reason: '',
      upperSmartWire: '',
      lowerSmartWire: '',
      setup: '',
      restrictions: '',
      restrictionsTeeth: [],
      ipr: '',
      iprDialog: '',
      residual: '',
      upper: '',
      lower: '',
      //
      photoUpload: [],
      xrayUpload: [],
      scanUpload: [],
      orgPhotoUpload: [],
      orgXrayUpload: [],
      orgScanUpload: [],
      message: '',
      patient: {},
      loadedEnhancement: '',

      //TX2.0
      chief_complaint: '',
      midlines: 'Show resulting midlines after alignment',
      auxiliariesWillBeUsed: 'No',
      auxiliariesNotes: '',

      // Top down flow
      missingTeeth: ['UL8', 'UR8', 'LL8', 'LR8'],
      extractTeeth: [],
      bracketTeeth: this.presets[7],
      restrictTeeth: [],
      restrictReason: '',
      // Arch
      archToTreat: 'both',
      opposingArch: 'current',
      // Special Notes/Spacing Notes
      notes: '',
      notes_spacing: '',

      // TODO: TX3.0 state
      anteriorPosteriorR: 'current',
      anteriorPosteriorL: 'current',
      anteriorPosteriorCorrections: {
        classIIOrIIICorrectionSimulation: false,
        posteriorIPR: false,
      },
      anteriorPosteriorNotes: '',
      opposingLowerArch: 'current',
      opposingUpperArch: 'current',
      // Conditions
      conditions: {
        ap_relationship: {
          active: false,
          title: 'AP Relationship',
          info: [
            'InBrace is suited for cases that present Class II discrepancies ',
            'up to 1.5mm or Class III discrepancies up to 1mm. If larger discrepancies ',
            'exist, use sagittal auxiliaries before or in tandem with InBrace to ',
            'help achieve treatment goals.',
          ],
          ap_improvement: 'maintain',
          aux_note: '',
        },
        midlines: {
          active: false,
          title: 'Midlines',
          upper_mid_improve: 'maintain',
          upper_mid_improve_right_by: '',
          upper_mid_improve_left_by: '',
          lower_mid_improve: 'maintain',
          lower_mid_improve_right_by: '',
          lower_mid_improve_left_by: '',
        },
        crowding: {
          active: false,
          title: 'Crowding',
          info_title: true,
          upper_expand: 'as_needed',
          upper_ipr_ant: 'as_needed',
          upper_ipr_right: 'as_needed',
          upper_ipr_left: 'as_needed',
          upper_procline: 'as_needed',
          lower_expand: 'as_needed',
          lower_ipr_ant: 'as_needed',
          lower_ipr_right: 'as_needed',
          lower_ipr_left: 'as_needed',
          lower_procline: 'as_needed',
        },
        spacing: {
          active: false,
          title: 'Spacing',
          info_title: true,
          spaces: 'close',
          spaceTeeth: [],
          spacingUpper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          spacingLower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
          leave_spaces_text: '',
        },
        overjet: {
          active: false,
          title: 'Overjet',
          improve: 'inbrace',
          improve_ipr: false,
          procline: false,
          aux_note: '',
        },
        deepbite: {
          active: false,
          title: 'Deepbite',
          info: [
            'InBrace is suited for cases that present 6mm of deepbite or less. ',
            'If deepbite greater than 6mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          correct: 'inbrace',
          aux_note: '',
        },
        openbite: {
          active: false,
          title: 'Openbite',
          title_alt: 'Anterior Openbite',
          info: [
            'InBrace is suited for cases that present 3mm of openbite or less. ',
            'If openbite greater than 3mm exists, auxiliary devices will help achieve ',
            'treatment goals in tandem with InBrace.',
          ],
          improve_amt: 'maintain',
        },
        crossbite: {
          active: false,
          title: 'Crossbite/Transverse',
          info: ['InBrace can moderately expand/restrict archforms.'],
          anterior_advance_upper: false,
          anterior_retract_lower: false,
          posterior_expand_upper: false,
          posterior_narrow_upper: false,
          posterior_expand_lower: false,
          posterior_narrow_lower: false,
        },
      },
      overview_questions: [
        {
          question: 'All bite turbos have been removed.',
          value: false,
        },
        {
          question: 'All required bracket repositioning/bonding has been performed.',
          value: false,
        },
        {
          // question: 'The .016 Stage II Smartwire(s) are fully engaged.',
          question: `${nomenclature.SMARTWIRE_2} and ${nomenclature.TWOA} Locking Loops are engaged in all brackets.`,
          value: false,
        },
        {
          question: 'All prescribed IPR has been performed.',
          value: false,
        },
        {
          question: 'There has been no observed tooth movement for the last 4-8 weeks.',
          value: false,
        },
      ],
      // Seperated out for performance reason
      de2_reason: '',
      de2_initial_date: '',
      de2_intra_date: '',
      de2_upper_smartwire: 'none',
      de2_upper_smartwire_date: '',
      de2_lower_smartwire: 'none',
      de2_lower_smartwire_date: '',
      de2_arch_to_treat: 'both',
      de2_arch_opposing_treat: '',
      de2_setup: 'same',
      de2_setup_instruction: '',
      de2_setup_additional_instruction: 'yes',
      de2_bracket_instruction: '',
      de2_teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      de2_bracket_remove: [],
      de2_bracket_add: [],
      de2_ipr: 'allow',
      de2_ipr_instruction: '',
      de2_spacing: 'close',
      de2_spacing_upper: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      de2_spacing_lower: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      de2_spacing_instruction: '',
      de2_special_instruction: '',
      de2_prev_case_info: {
        exist: false,
        case_id: '',
        arch_to_treat: 'both',
        teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      },
      de2_initial_date_warning: false,
      de2_intra_date_warning: false,
      de2_upper_smartwire_date_warning: false,
      de2_lower_smartwire_date_warning: false,
      gen_2: false,
      // DE3.0 state
      de3_reason: '',
      de3_next_appointment_date: '',
      de3_next_appointment_date_warning: false,
      de3_intra_date: '',
      de3_intra_date_warning: false,
      de3_upper_smartwire: 'none',
      de3_upper_smartwire_date: '',
      de3_upper_smartwire_date_warning: false,
      de3_lower_smartwire: 'none',
      de3_lower_smartwire_date: '',
      de3_lower_smartwire_date_warning: false,
      de3_arch_to_treat: 'both',
      de3_arch_opposing_treat: 'current',
      de3_upper_arch_opposing_treat: 'current',
      de3_lower_arch_opposing_treat: 'current',
      de3_prev_case_info: {
        exist: false,
        case_id: '',
        arch_to_treat: 'both',
        bracket_teeth: [],
        missing_teeth: [],
        extract_teeth: [],
        teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      },
      de3_setup: 'accept_current_improve_detailing',
      de3_setup_instruction: '',
      de3_setup_additional_instruction: 'yes',
      de3_bracket_teeth: [],
      de3_missing_teeth: [],
      de3_extract_teeth: [],
      de3_teeth_restriction: ['UR8', 'UL8', 'LR8', 'LL8'],
      de3_anterior_posterior_r: 'current',
      de3_anterior_posterior_l: 'current',
      de3_anterior_posterior_corrections: {
        class_II_or_III_correction_simulation: false,
        posterior_IPR: false,
      },
      de3_anterior_posterior_notes: '',
      de3_midlines: 'show_resulting_after_aligment',
      de3_auxiliaries: 'No',
      de3_auxiliaries_notes: '',
    };

    this.isSubmitAvailable.bind(this);
    this.isStepperDone.bind(this);
    this.updateStepperState.bind(this);

    this.onUpload.bind(this);
    this.onRemove.bind(this);

    this.hasNewPhotos.bind(this);
    this.hasNewXrays.bind(this);
    this.hasNewScans.bind(this);
    this.isEnhancementChanged.bind(this);

    this.getBooleanResult.bind(this);
    this.redirectUserToCaseDetail = this.redirectUserToCaseDetail.bind(this);
    this.removeWizardErrorMsg = this.removeWizardErrorMsg.bind(this);

    this.urlContainsWord = this.urlContainsWord.bind(this);
    this.photos_upload = this.photos_upload.bind(this);
    this.radiographs_upload = this.radiographs_upload.bind(this);
    this.scans_upload = this.scans_upload.bind(this);
    this.hide_warning = this.hide_warning.bind(this);
    this.show_warning = this.show_warning.bind(this);

    this.isUploadInProgress = this.isUploadInProgress.bind(this);
    this.showMoreTwoScansWarning = this.showMoreTwoScansWarning.bind(this);

    //TX2.0 Functionalities
    this.getImpliedTeethSets = this.getImpliedTeethSets.bind(this);
    this.getPlanByVersion = this.getPlanByVersion.bind(this);
    this.handleTeethClick = this.handleTeethClick.bind(this);
    this.setPlanConditionState = this.setPlanConditionState.bind(this);
    this.setPlanState = this.setPlanState.bind(this);
    this.metAllPlanRequiredField = this.metAllPlanRequiredField.bind(this);
    this.hasConditionsChange = this.hasConditionsChange.bind(this);
  }

  getCaseIdFromUrl() {
    let pathname = this.props.history.location.pathname;
    let caseid = pathname.match(/\/upload\/([A-Za-z0-9-]+)/);
    return caseid && caseid[1] ? caseid[1] : '';
  }

  getBooleanResult(data) {
    if (typeof data === 'boolean') {
      return data;
    } else {
      return '';
    }
  }

  // TX2.0 Static methods
  static makePreset = (n) => {
    var teeth = [];
    _.range(1, n + 1).forEach((x) => {
      teeth.push('UL' + x, 'UR' + x, 'LL' + x, 'LR' + x);
    });
    return teeth;
  };

  /**
   * iTero integration
   *
   * Reloads the current component
   */
  reloadCurrentComponent = () => {
    let component = this.state.component;
    switch (this.state.active) {
      case this.getActivePositionByStepperName('Impressions'):
        component = this.getComponentByStepperName('Impressions');
        break;
      case this.getActivePositionByStepperName('Photos'):
        component = this.getComponentByStepperName('Photos');
        break;
      case this.getActivePositionByStepperName('X-Rays'):
        component = this.getComponentByStepperName('X-Rays');
        break;
      case this.getActivePositionByStepperName('Dx/Tx Plan'):
        component = this.getComponentByStepperName('Dx/Tx Plan');
        break;
      case this.getActivePositionByStepperName('Refinement Request Form'):
        component = this.getComponentByStepperName('Refinement Request Form');
        break;
      case this.getActivePositionByStepperName('Submit'):
        component = this.getComponentByStepperName('Submit');
        break;
      default:
        break;
    }
    this.setState({
      component: component,
    });
  };

  /**
   * iTero integration
   *
   * Update photo stepper state
   * @param {Array} photoUpload - photo upload array
   * @param {Array} stepper - stepper array
   * @returns {Array} new stepper array
   */
  updatePhotoStepperState = (photoUpload, stepper) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    const newStepper = [...stepper] || [...this.state.stepper];
    if (this.isPhotoUploadLengthValid(currentPhotoUpload)) {
      newStepper[this.getActivePositionByStepperName('Photos')] = 1;
    } else {
      newStepper[this.getActivePositionByStepperName('Photos')] = 2;
    }
    return newStepper;
  };

  /**
   * iTero integration
   *
   * Get case id for iTero scans
   */
  getCaseIdForIteroScans = () => {
    return this.state.id;
  };

  /**
   * iTero integration
   *
   * Get state key for iTero photos
   */
  getIteroPhotosStateKey() {
    return 'photoUpload';
  }

  /**
   * iTero integration
   *
   * Helper that returns true if should build itero record viewer
   * @function
   * @returns {Boolean} true if should build itero record viewer
   * @returns {Boolean} false if should not build itero record viewer
   */
  shouldBuildIteroRecordViewer = () => {
    return this.state.active === this.getActivePositionByStepperName('Impressions') && this.state.uploadMethod === 'itero';
  };

  /**
   * iTero integration
   *
   * On Selected iTero files change
   */
  onSelectedIteroScanIdsChange = () => {
    // update stepper state
    this.setState((prevState) => {
      let newStepper = prevState.stepper;

      if (this.state.selectedIteroScanIds.length === 2) {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
      } else {
        newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
      }

      return { stepper: newStepper };
    });

    // clear warning
    this.onFocus_clearWarning();
  };

  /**
   * iTero integration
   *
   * Get uploaded photos without itero photos
   */
  getUploadedPhotosWithouthIteroPhotos = (photoUpload) => {
    const currentPhotoUpload = photoUpload || this.state[this.getIteroPhotosStateKey()];
    return currentPhotoUpload.filter((file) => !file.itero_id);
  };

  /**
   * iTero integration
   *
   * Handles the change event of the upload method dropdown.
   * If there is an existing case ID, it calls onIncompleteSave() before proceeding.
   * Updates the stepper state based on the selected upload method and the number of uploaded photos.
   * If the upload method is 'manual', it updates the stepper based on the number of impressions.
   * If the upload method is 'itero', it updates the stepper based on the number of selected Itero scan IDs and photos.
   * If shouldFetchIteroPhotos is true, it adds the selected Itero photo records to the photoUpload array.
   * Calls updatePhotoStepperState() to update the photo stepper state based on the photoUpload array.
   * @function
   * @returns {void}
   */
  onUploadMethodChange = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      let newStepper = prevState.stepper;
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      let photoUpload = filteredPrevStatePhotoUpload;

      if (prevState.uploadMethod === 'manual') {
        if (prevState.scanUpload.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
      } else if (prevState.uploadMethod === 'itero') {
        if (prevState.selectedIteroScanIds.length === 2) {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          newStepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }
        if (this.state.shouldFetchIteroPhotos) {
          photoUpload = [...photoUpload, ...prevState.selectedIteroPhotoRecords];
        }
      }

      newStepper = this.updatePhotoStepperState(photoUpload, newStepper);

      return { stepper: newStepper, [photoUploadStateKey]: photoUpload };
    });
  };

  static makePresets = (first, last) => {
    var presets = {};
    _.range(first, last + 1).forEach((x) => {
      presets[x] = RecordReUpload.makePreset(x);
    });
    return presets;
  };

  /**
   * iTero integration
   *
   * On should fetch itero photos change
   */
  onShouldFetchIteroPhotosChange = () => {
    if (this.state.shouldFetchIteroPhotos && this.state.selectedIteroId) {
      if (this.hasIteroPhotos()) {
        const oldIteroId = this.getCurrentUploadedPhotosIteroId();
        if (oldIteroId !== this.state.selectedIteroId) {
          this.updateIteroPhotos(oldIteroId, this.state.selectedIteroId);
        }
      } else {
        this.fetchIteroPhotos(this.state.selectedIteroId);
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // iTero integration - component with iTero integration did update helper
    this.withIteroScansSelectionComponentDidUpdate(prevProps, prevState);

    // iTero integration - reload current component when itero state changes
    const stateThatTriggersScansReload = Object.keys(getIteroInitialState());
    const propsThatTriggersScansReload = ['licenseNumbers', 'isFetchIteroPhotosPending', 'isGetIteroPhotoRecordsForRecordsReuploadPending'];
    stateThatTriggersScansReload.forEach((stateKey) => {
      if (this.state[stateKey] !== prevState[stateKey]) {
        this.reloadCurrentComponent();
      }
    });
    propsThatTriggersScansReload.forEach((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.reloadCurrentComponent();
      }
    });
    propsThatTriggersScansReload.forEach((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.reloadCurrentComponent();
      }
    });

    // iTero integration - on active change
    if (this.state.active !== prevState.active) {
      this.buildIteroRecords();
    }

    if (this.state.photoUpload !== prevState.photoUpload || this.state.xrayUpload !== prevState.xrayUpload || this.state.scanUpload !== prevState.scanUpload) {
      this.reloadCurrentComponent();
    }
  }

  /**
   * iTero integration
   *
   * function to check if the itero photos are loading
   * @function
   * @return {Boolean} Returns true if itero photos are loading
   */
  isLoadingIteroPhotos = () => {
    if (!this.state.shouldFetchIteroPhotos) {
      return false;
    }
    return this.props.isFetchIteroPhotosPending || this.props.isGetIteroPhotoRecordsForRecordsReuploadPending;
  };

  /**
   * iTero integration
   *
   * function to check if itero photos was loaded
   * @function
   * @return {Boolean} Returns true if itero photos was loaded
   */
  hasIteroPhotos = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    return this.state[photoUploadStateKey].some((file) => file.itero_id);
  };

  /**
   * iTero integration
   *
   * function to check if user is allowed to remove all itero photos
   * @function
   * @param {Object} ctx - context
   * @return {Boolean} Returns true if user is allowed to remove all itero photos
   */
  shouldShowRemoveIteroPhotos = () => {
    return this.state.isEnrolledToiTeroPhotosIntegration && this.state.shouldAllowToRemoveAllIteroPhotos && this.hasIteroPhotos();
  };

  /**
   * iTero integration
   *
   * Gets the current uploaded photos iteroid
   * @function
   * @return {String} Returns the current uploaded photos iteroid
   * @return {null} Returns null if there are no itero photos
   */
  getCurrentUploadedPhotosIteroId = () => {
    if (this.hasIteroPhotos()) {
      const firstUploadedIteroPhoto = this.state[this.getIteroPhotosStateKey()].filter((file) => file.itero_id)[0];
      return firstUploadedIteroPhoto.itero_id;
    }
    return null;
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  removeAllIteroPhotosFromState = () => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.setState((prevState) => {
      const filteredPrevStatePhotoUpload = this.getUploadedPhotosWithouthIteroPhotos(prevState[photoUploadStateKey]);
      const newStepper = this.updatePhotoStepperState(filteredPrevStatePhotoUpload, prevState.stepper);
      return {
        warning: false,
        [photoUploadStateKey]: filteredPrevStatePhotoUpload,
        selectedIteroPhotoRecords: [],
        stepper: newStepper,
      };
    });
  };

  /**
   * iTero integration
   *
   * Removes all itero photos from state and calls the delete itero photos API
   * @function
   * @return {void}
   */
  handleRemoveIteroPhotosClick = () => {
    this.handleShouldFetchIteroPhotosChange(false);
    this.removeAllIteroPhotosFromState();
    this.reloadCurrentComponent();
  };

  /**
   * iTero integration
   *
   * function to check if the itero photos are being deleted
   * @function
   * @return {Boolean} Returns true if itero photos are being deleted
   */
  isDeletingIteroPhotos = () => {
    return this.props.isDeleteIteroPhotosFromDEPending;
  };

  componentDidMount() {
    const isEnrolledToiTeroIntegration = this.context.doctor_program_enrollment.includes('itero_integration');
    const isEnrolledToiTeroPhotosIntegration = this.context.doctor_program_enrollment.includes('itero_photos_integration');
    this.setState({
      isEnrolledToiTeroIntegration,
      isEnrolledToiTeroPhotosIntegration,
    });
    this.withIteroSelectionComponentDidMount();

    if (!this.props.fetchIteroPhotos) {
      throw new Error('fetchIteroPhotos redux action method is not in props');
    }
    if (!this.props.getIteroPhotoRecordsForRecordsReupload) {
      throw new Error('getIteroPhotoRecordsForRecordsReupload redux action method is not in props');
    }

    const that = this;
    let validurl = false;
    setTokenHeader();

    //Load Photos from case

    if (this.props.history && this.props.history.location) {
      let caseid = this.getCaseIdFromUrl();
      const doctorId = getDoctorIdFromPath(that.props.history.location.pathname);

      if (caseid) {
        validurl = true;
        let licenseNumbers = [];
        let fetchedIteroScans = [];
        let fetchedIteroScansNextUrlCursor = '';
        this.props.fetchLicenseNumbers(doctorId, {
          onSuccess: async (_licenseNumbers) => {
            licenseNumbers = _licenseNumbers;
            that.props
              .fetchIteroScans({ licenseNumbers: getMappedLicenseNumbersHelper(licenseNumbers) })
              .then((data) => {
                if (data.next) {
                  const fetchedIteroScansNextUrl = new URL(data.next);
                  fetchedIteroScansNextUrlCursor = fetchedIteroScansNextUrl.searchParams.get('cursor');
                }
                fetchedIteroScans = data.results;
              })
              .finally(() => {
                Axios.get(`/api/casedetails/?id=${caseid}`)
                  .then(async function (result) {
                    if (result.data.data && result.data.data.caseDetail[0] && result.data.data.caseDetail[0].case_status === 'Doctor Upload New Files') {
                      const isGen2 = result.data.data.gen_2;

                      // Loading the data on click
                      let loadedState = {
                        id: result.data.data.caseDetail[0].case_id,
                        patient: result.data.data.patient[0],
                        comment: result.data.data.caseDetail[0].case_comment,
                        gen_2: isGen2,
                        fetchedIteroScans,
                        fetchedIteroScansNextUrlCursor,
                      };

                      let org = {};

                      let photos = [];
                      let xrays = [];
                      let scans = [];

                      if (result.data.data.files && result.data.data.files.length > 0) {
                        result.data.data.files.map(function (file) {
                          if (file.case_id === result.data.data.caseDetail[0].case_id) {
                            switch (file.file_type) {
                              case 'upperscans':
                              case 'lowerscans':
                              case 'scans':
                                scans.push(file);
                                break;

                              case 'photos':
                                photos.push(file);
                                break;

                              case 'xrays':
                                xrays.push(file);
                                break;

                              default:
                            }
                          }

                          return '';
                        });
                      }

                      if (result.data.data.plans && result.data.data.plans.length > 0) {
                        for (let i = 0; i < result.data.data.plans.length; i++) {
                          if (
                            result.data.data.plans[i].case_id === result.data.data.caseDetail[0].case_id &&
                            result.data.data.plans[i].revision_id === 'TX2.0'
                          ) {
                            if (result.data.data.plans[i].txplan_arch) {
                              loadedState.archToTreat = result.data.data.plans[i].txplan_arch;
                              org['archToTreat'] = result.data.data.plans[i].txplan_arch;
                            }

                            if (result.data.data.plans[i].revision_id === 'TX2.0') {
                              loadedState['default_plan_version'] = result.data.data.plans[i].revision_id;
                            }

                            let txplan_data = JSON.parse(result.data.data.plans[i].txplan_data);

                            for (let data in txplan_data) {
                              //Convert type
                              if (txplan_data[data] === 'false') {
                                loadedState[data] = false;
                                org[data] = false;
                              } else if (txplan_data[data] === 'true') {
                                loadedState[data] = true;
                                org[data] = true;
                              } else if (data === 'missingTeeth' || data === 'extractTeeth' || data === 'bracketTeeth' || data === 'restrictTeeth') {
                                loadedState[data] = txplan_data[data].split(',').filter((item) => item !== '');
                                org[data] = txplan_data[data].split(',').filter((item) => item !== '');
                              } else if (data === 'conditions') {
                                loadedState[data] = JSON.parse(txplan_data[data]);
                                org[data] = JSON.parse(txplan_data[data]);
                              } else {
                                loadedState[data] = txplan_data[data];
                                org[data] = txplan_data[data];
                              }
                            }
                          } else if (
                            result.data.data.plans[i].case_id === result.data.data.caseDetail[0].case_id &&
                            result.data.data.plans[i].revision_id === 'TX3.0'
                          ) {
                            // TX3.0 load txplan_data from response
                            loadedState['default_plan_version'] = 'TX3.0';

                            if (result.data.data.plans[i].txplan_arch) {
                              loadedState.archToTreat = result.data.data.plans[i].txplan_arch;
                              org['archToTreat'] = result.data.data.plans[i].txplan_arch;
                            }

                            const txplan_data = JSON.parse(result.data.data.plans[i].txplan_data);

                            for (let data in txplan_data) {
                              //Convert type
                              if (['missingTeeth', 'extractTeeth', 'bracketTeeth', 'restrictTeeth'].includes(data)) {
                                const loadedStateArrayTeeth = txplan_data[data].split(',').filter((item) => Teeth.All.includes(item));
                                loadedState[data] = loadedStateArrayTeeth;
                                org[data] = loadedStateArrayTeeth;
                              } else if (['anteriorPosteriorCorrections', 'impliedTeethSets'].includes(data)) {
                                loadedState[data] = JSON.parse(txplan_data[data]);
                                org[data] = JSON.parse(txplan_data[data]);
                              } else if (['opposingArch'].includes(data)) {
                                if (loadedState?.archToTreat === 'upper') {
                                  loadedState['opposingUpperArch'] = txplan_data['opposingArch'];
                                } else if (loadedState?.archToTreat === 'lower') {
                                  loadedState['opposingLowerArch'] = txplan_data['opposingArch'];
                                }
                                loadedState['opposingArch'] = txplan_data['opposingArch'];
                                org['opposingArch'] = txplan_data['opposingArch'];
                              } else if (txplan_data[data] === 'false') {
                                loadedState[data] = false;
                                org[data] = false;
                              } else if (txplan_data[data] === 'true') {
                                loadedState[data] = true;
                                org[data] = true;
                              } else {
                                loadedState[data] = txplan_data[data];
                                org[data] = txplan_data[data];
                              }
                            }
                          } else if (
                            result.data.data.plans[i].case_id === result.data.data.caseDetail[0].case_id &&
                            result.data.data.plans[i].revision_id === 'DE2.0'
                          ) {
                            //DE2.0 plan
                            loadedState['default_plan_version'] = result.data.data.plans[i].revision_id;

                            let txplan_data = JSON.parse(result.data.data.plans[i].txplan_data);

                            for (let data in txplan_data) {
                              // Loading array type fields
                              if (
                                data === 'restrictionsTeeth' ||
                                data === 'de2_teeth_restriction' ||
                                data === 'de2_bracket_remove' ||
                                data === 'de2_bracket_add' ||
                                data === 'de2_spacing_upper' ||
                                data === 'de2_spacing_lower'
                              ) {
                                loadedState[data] = txplan_data[data].split(',');
                              } else if (data === 'de2_prev_case_info') {
                                //Do something here for this part of the code
                                loadedState[data] = JSON.parse(txplan_data[data]);
                              } else {
                                loadedState[data] = txplan_data[data];
                              }
                            }
                          } else if (
                            result.data.data.plans[i].case_id === result.data.data.caseDetail[0].case_id &&
                            result.data.data.plans[i].revision_id === 'DE3.0'
                          ) {
                            // DE3.0 load state
                            loadedState['default_plan_version'] = result.data.data.plans[i].revision_id;

                            const txplan_data = JSON.parse(result.data.data.plans[i].txplan_data);

                            for (let data in txplan_data) {
                              // Loading array type fields
                              if (['de3_bracket_teeth', 'de3_missing_teeth', 'de3_extract_teeth', 'de3_teeth_restriction'].includes(data)) {
                                loadedState[data] = txplan_data[data].split(',').filter((item) => Teeth.All.includes(item));
                              } else if (['de3_prev_case_info', 'de3_anterior_posterior_corrections'].includes(data)) {
                                loadedState[data] = JSON.parse(txplan_data[data]);
                              } else if (['de3_arch_opposing_treat'].includes(data)) {
                                if (txplan_data['de3_arch_to_treat'] === 'upper') {
                                  loadedState['de3_upper_arch_opposing_treat'] = txplan_data['de3_arch_opposing_treat'];
                                } else if (txplan_data['de3_arch_to_treat'] === 'lower') {
                                  loadedState['de3_lower_arch_opposing_treat'] = txplan_data['de3_arch_opposing_treat'];
                                }
                                loadedState['de3_arch_opposing_treat'] = txplan_data['de3_arch_opposing_treat'];
                              } else {
                                loadedState[data] = txplan_data[data];
                              }

                              if (data === 'de3_next_appointment') {
                                loadedState['de3_next_appointment_warning'] = !isValidDate(txplan_data[data]);
                              } else if (data === 'de3_intra_date') {
                                loadedState['de3_intra_date_warning'] = !isValidDate(txplan_data[data]);
                              } else if (data === 'de3_upper_smartwire_date') {
                                loadedState['de3_upper_smartwire_date_warning'] = !isValidDate(txplan_data[data]);
                              } else if (data === 'de3_lower_smartwire_date') {
                                loadedState['de3_lower_smartwire_date_warning'] = !isValidDate(txplan_data[data]);
                              }
                            }
                          }
                        }
                      }

                      //Load Photos
                      if (photos.length > 0) {
                        loadedState.photoUpload = photos;
                        loadedState.orgPhotoUpload = photos;

                        // iTero integration - load previously selected iteroPhotoRecords
                        loadedState.selectedIteroPhotoRecords = photos.filter((photo) => photo.itero_id);
                        loadedState.orgSelectedIteroPhotoRecords = photos.filter((photo) => photo.itero_id);
                      }

                      //Load Xrays
                      if (xrays.length > 0) {
                        loadedState.xrayUpload = xrays;
                        loadedState.orgXrayUpload = xrays;
                      }

                      let loadScans = false;
                      if (that.state.isEnrolledToiTeroIntegration && (that.state.isDigitalEnhancement || isGen2)) {
                        // iTero integration - load iTero data
                        if (result.data.data.file_upload_settings) {
                          const file_upload_settings = JSON.parse(result.data.data.file_upload_settings);

                          // iTero integration - load uploadMethod, showAlreadyUploadedScans, shouldFetchIteroPhotos
                          for (let setting in file_upload_settings) {
                            if (file_upload_settings[setting] === 'false') {
                              loadedState[setting] = false;
                            } else if (file_upload_settings[setting] === 'true') {
                              loadedState[setting] = true;
                            } else {
                              loadedState[setting] = file_upload_settings[setting];
                            }
                          }

                          if (file_upload_settings.uploadMethod === 'itero') {
                            // iTero integration - load previously selected iteroScanIds
                            if (result.data.data.iteroScanIds && result.data.data.iteroScanIds.length > 0) {
                              const iteroScansLoadedState = await that.getIteroScansLoadedState({
                                selectedIteroScanIds: result.data.data.iteroScanIds,
                                fetchedIteroScans,
                                fetchedIteroScansNextUrlCursor,
                                licenseNumbers,
                              });
                              loadedState = { ...loadedState, ...iteroScansLoadedState };
                              loadedState.orgSelectedIteroScanIds = iteroScansLoadedState.selectedIteroScanIds;
                              loadedState.orgFetchedIteroScans = iteroScansLoadedState.fetchedIteroScans;
                              loadedState.orgSelectedIteroId = iteroScansLoadedState.selectedIteroId;
                              loadedState.orgFetchedIteroScansNextUrlCursor = iteroScansLoadedState.fetchedIteroScansNextUrlCursor;
                            }
                          } else {
                            loadScans = true;
                          }
                        } else {
                          loadScans = true;
                        }
                      } else {
                        loadScans = true;
                      }

                      if (loadScans && scans.length > 0) {
                        loadedState.uploadMethod = 'manual';
                        loadedState.scanUpload = scans;
                        loadedState.orgScanUpload = scans;
                      }

                      if (that.state.isDigitalEnhancement) {
                        //Digital Enhancement

                        if (loadedState['default_plan_version'] === 'DE2.0') {
                          loadedState.loadedEnhancement = {
                            de2_reason: loadedState['de2_reason'] ? loadedState['de2_reason'] : '',
                            de2_initial_date: loadedState['de2_initial_date'] ? loadedState['de2_initial_date'] : '',
                            de2_intra_date: loadedState['de2_intra_date'] ? loadedState['de2_intra_date'] : '',
                            de2_upper_smartwire: loadedState['de2_upper_smartwire'] ? loadedState['de2_upper_smartwire'] : '',
                            de2_upper_smartwire_date: loadedState['de2_upper_smartwire_date'] ? loadedState['de2_upper_smartwire_date'] : '',
                            de2_lower_smartwire: loadedState['de2_lower_smartwire'] ? loadedState['de2_lower_smartwire'] : '',
                            de2_lower_smartwire_date: loadedState['de2_lower_smartwire_date'] ? loadedState['de2_lower_smartwire_date'] : '',
                            de2_arch_to_treat: loadedState['de2_arch_to_treat'] ? loadedState['de2_arch_to_treat'] : '',
                            de2_arch_opposing_treat: loadedState['de2_arch_opposing_treat'] ? loadedState['de2_arch_opposing_treat'] : '',
                            de2_setup: loadedState['de2_setup'] ? loadedState['de2_setup'] : '',
                            de2_setup_instruction: loadedState['de2_setup_instruction'] ? loadedState['de2_setup_instruction'] : '',
                            de2_setup_additional_instruction: loadedState['de2_setup_additional_instruction']
                              ? loadedState['de2_setup_additional_instruction']
                              : '',
                            de2_bracket_instruction: loadedState['de2_bracket_instruction'] ? loadedState['de2_bracket_instruction'] : '',
                            de2_teeth_restriction: loadedState['de2_teeth_restriction'] ? loadedState['de2_teeth_restriction'].slice() : [],
                            de2_bracket_remove: loadedState['de2_bracket_remove'] ? loadedState['de2_bracket_remove'].slice() : [],
                            de2_bracket_add: loadedState['de2_bracket_add'] ? loadedState['de2_bracket_add'].slice() : [],
                            de2_ipr: loadedState['de2_ipr'] ? loadedState['de2_ipr'] : '',
                            de2_ipr_instruction: loadedState['de2_ipr_instruction'] ? loadedState['de2_ipr_instruction'] : '',
                            de2_spacing: loadedState['de2_spacing'] ? loadedState['de2_spacing'] : '',
                            de2_spacing_upper: loadedState['de2_spacing_upper'] ? loadedState['de2_spacing_upper'].slice() : [],
                            de2_spacing_lower: loadedState['de2_spacing_lower'] ? loadedState['de2_spacing_lower'].slice() : [],
                            de2_spacing_instruction: loadedState['de2_spacing_instruction'] ? loadedState['de2_spacing_instruction'] : '',
                            de2_special_instruction: loadedState['de2_special_instruction'] ? loadedState['de2_special_instruction'] : '',
                            de2_prev_case_info: loadedState['de2_prev_case_info'] ? loadedState['de2_prev_case_info'] : {},
                          };
                        } else if (loadedState['default_plan_version'] === 'DE3.0') {
                          loadedState.loadedEnhancement = {
                            de3_reason: loadedState['de3_reason'] ? loadedState['de3_reason'] : '',
                            de3_next_appointment_date: loadedState['de3_next_appointment_date'] ? loadedState['de3_next_appointment_date'] : '',
                            de3_intra_date: loadedState['de3_intra_date'] ? loadedState['de3_intra_date'] : '',
                            de3_upper_smartwire: loadedState['de3_upper_smartwire'] ? loadedState['de3_upper_smartwire'] : '',
                            de3_upper_smartwire_date: loadedState['de3_upper_smartwire_date'] ? loadedState['de3_upper_smartwire_date'] : '',
                            de3_lower_smartwire: loadedState['de3_lower_smartwire'] ? loadedState['de3_lower_smartwire'] : '',
                            de3_lower_smartwire_date: loadedState['de3_lower_smartwire_date'] ? loadedState['de3_lower_smartwire_date'] : '',
                            de3_arch_to_treat: loadedState['de3_arch_to_treat'] ? loadedState['de3_arch_to_treat'] : '',
                            de3_arch_opposing_treat: loadedState['de3_arch_opposing_treat'] ? loadedState['de3_arch_opposing_treat'] : '',
                            de3_prev_case_info: loadedState['de3_prev_case_info'] ? loadedState['de3_prev_case_info'] : {},
                            de3_setup: loadedState['de3_setup'] ? loadedState['de3_setup'] : '',
                            de3_setup_instruction: loadedState['de3_setup_instruction'] ? loadedState['de3_setup_instruction'] : '',
                            de3_setup_additional_instruction: loadedState['de3_setup_additional_instruction']
                              ? loadedState['de3_setup_additional_instruction']
                              : '',
                            de3_bracket_teeth: loadedState['de3_bracket_teeth'] ? loadedState['de3_bracket_teeth'].slice() : [],
                            de3_missing_teeth: loadedState['de3_missing_teeth'] ? loadedState['de3_missing_teeth'].slice() : [],
                            de3_extract_teeth: loadedState['de3_extract_teeth'] ? loadedState['de3_extract_teeth'].slice() : [],
                            de3_teeth_restriction: loadedState['de3_teeth_restriction'] ? loadedState['de3_teeth_restriction'].slice() : [],
                            de3_anterior_posterior_r: loadedState['de3_anterior_posterior_r'] ? loadedState['de3_anterior_posterior_r'] : '',
                            de3_anterior_posterior_l: loadedState['de3_anterior_posterior_l'] ? loadedState['de3_anterior_posterior_l'] : '',
                            de3_anterior_posterior_corrections: loadedState['de3_anterior_posterior_corrections']
                              ? loadedState['de3_anterior_posterior_corrections']
                              : {},
                            de3_anterior_posterior_notes: loadedState['de3_anterior_posterior_notes'] ? loadedState['de3_anterior_posterior_notes'] : '',
                            de3_midlines: loadedState['de3_midlines'] ? loadedState['de3_midlines'] : '',
                            de3_auxiliaries: loadedState['de3_auxiliaries'] ? loadedState['de3_auxiliaries'] : '',
                            de3_auxiliaries_notes: loadedState['de3_auxiliaries_notes'] ? loadedState['de3_auxiliaries_notes'] : '',
                          };
                        } else {
                          loadedState.loadedEnhancement = {
                            scanDate: loadedState['scanDate'] ? loadedState['scanDate'] : '',
                            reason: loadedState['reason'] ? loadedState['reason'] : '',
                            upperSmartWire: loadedState['upperSmartWire'] ? loadedState['upperSmartWire'] : '',
                            lowerSmartWire: loadedState['lowerSmartWire'] ? loadedState['lowerSmartWire'] : '',
                            setup: loadedState['setup'] ? loadedState['setup'] : '',
                            restrictions: loadedState['restrictions'] ? loadedState['restrictions'] : '',
                            restrictionsTeeth: loadedState['restrictionsTeeth'] ? loadedState['restrictionsTeeth'].slice() : [],
                            ipr: loadedState['ipr'] ? loadedState['ipr'] : '',
                            iprDialog: loadedState['iprDialog'] ? loadedState['iprDialog'] : '',
                            residual: loadedState['residual'] ? loadedState['residual'] : '',
                            upper: loadedState['upper'] ? loadedState['upper'] : '',
                            lower: loadedState['lower'] ? loadedState['lower'] : '',
                          };
                        }

                        loadedState.component = (
                          <Photos
                            id={result.data.data.caseDetail[0].case_id}
                            photoUpload={loadedState.photoUpload}
                            onUpload={that.onUpload}
                            onRemove={that.onRemove}
                            location="complete"
                            upload_state={that.photos_upload}
                            upload_content={that.state.photos_uploading}
                            show_warning={that.show_warning}
                            hide_warning={that.hide_warning}
                            updateRecordState={that.updateRecordState}
                            isLoadingIteroPhotos={that.isLoadingIteroPhotos()}
                            isDeletingIteroPhotos={that.isDeletingIteroPhotos()}
                            showRemoveIteroPhotos={that.shouldShowRemoveIteroPhotos()}
                            onRemoveIteroPhotosClick={that.handleRemoveIteroPhotosClick}
                            iteroPhotosDropzoneMessage={that.hasIteroPhotos() ? that.getIteroPhotosDropzoneMessage() : null}
                          />
                        );
                      } else {
                        //DXTX PLAN
                        loadedState.component = (
                          <Photos
                            id={result.data.data.caseDetail[0].case_id}
                            photoUpload={loadedState.photoUpload}
                            onUpload={that.onUpload}
                            onRemove={that.onRemove}
                            location="complete"
                            upload_state={that.photos_upload}
                            upload_content={that.state.photos_uploading}
                            show_warning={that.show_warning}
                            hide_warning={that.hide_warning}
                            updateRecordState={that.updateRecordState}
                            isLoadingIteroPhotos={that.isLoadingIteroPhotos()}
                            isDeletingIteroPhotos={that.isDeletingIteroPhotos()}
                            showRemoveIteroPhotos={that.shouldShowRemoveIteroPhotos()}
                            onRemoveIteroPhotosClick={that.handleRemoveIteroPhotosClick}
                            iteroPhotosDropzoneMessage={that.hasIteroPhotos() ? that.getIteroPhotosDropzoneMessage() : null}
                          />
                        );
                      }

                      if (loadedState['default_plan_version'] === 'TX2.0') {
                        loadedState.loaded_data = {
                          chief_complaint: org['chief_complaint'],
                          missingTeeth: org['missingTeeth'],
                          extractTeeth: org['extractTeeth'],
                          bracketTeeth: org['bracketTeeth'],
                          archToTreat: org['archToTreat'],
                          restrictTeeth: org['restrictTeeth'],
                          opposingArch: org['opposingArch'],
                          notes: org['notes'],
                          notes_spacing: org['notes_spacing'],
                          conditions: org['conditions'],
                        };
                      } else if (loadedState['default_plan_version'] === 'TX3.0') {
                        // TX3.0 loaded data
                        loadedState.loaded_data = {
                          missingTeeth: org['missingTeeth'],
                          extractTeeth: org['extractTeeth'],
                          bracketTeeth: org['bracketTeeth'],
                          restrictTeeth: org['restrictTeeth'],
                          archToTreat: org['archToTreat'],
                          opposingArch: org['opposingArch'],
                          anteriorPosteriorR: org['anteriorPosteriorR'],
                          anteriorPosteriorL: org['anteriorPosteriorL'],
                          anteriorPosteriorCorrections: org['anteriorPosteriorCorrections'],
                          anteriorPosteriorNotes: org['anteriorPosteriorNotes'],
                          midlines: org['midlines'],
                          auxiliariesWillBeUsed: org['auxiliariesWillBeUsed'],
                          auxiliariesNotes: org['auxiliariesNotes'],
                          notes: org['notes'],
                        };
                      }

                      loadedState.loading = false;

                      that.setState(loadedState);
                    } else {
                      that.onRedirectManageCases();
                    }
                  })
                  .catch(function (err) {
                    console.log(err);
                    that.onRedirectManageCases();
                  });
              });
          },
        });
      }
    }

    if (!validurl) {
      this.onRedirectManageCases();
    }
  }

  photos_upload = (filenames) => {
    this.setState({
      photos_uploading: filenames,
    });
  };

  radiographs_upload = (filenames) => {
    this.setState({
      radiographs_uploading: filenames,
    });
  };

  scans_upload = (filenames) => {
    this.setState({
      scans_uploading: filenames,
    });
  };

  hide_warning = () => {
    this.setState({
      warning: false,
      warning_title: 'Incomplete Fields',
    });
  };

  show_warning = () => {
    this.setState({
      warning: true,
    });
  };

  /**
   * Clear warning on focus on input
   * @function
   * @param {event} event - click event
   */
  onFocus_clearWarning = (event) => {
    if (document.querySelector('#warning-submit')) {
      document.querySelector('#warning-submit').classList.remove('warning-display');
    }

    let ids = [
      '#scanDate',
      '#intraOral',
      '#reasonSelection',
      '#reason',
      '#setupSelection',
      '#setup',
      '#de2_initial_date',
      '#de2_initial_date_input',
      '#de2_reason_textarea',
      '#de2_reason',
      '#de2_intra_date',
      '#de2_intra_date_input',
      '#de3_reason_textarea',
      '#de3_reason',
      '#de3_intra_date',
      '#de3_intra_date_input',
      '#de3_next_appointment_date',
      '#de3_next_appointment_date_input',
      '#de3_auxiliaries',
      '#de3_auxiliaries_notes',
      '#de3_anterior_posterior',
      '#de3_anterior_posterior_notes',
      '#de3_bracket_teeth',
      '#de3_setup',
      '#de3_setup_instruction',
      'textarea[data-field="de3_setup_instruction"]',
      '#patientChiefComplaint',
      '#patientChiefComplaintLabel',
      '#archToTreat',
      '#archToTreatLabel',
      '#teethToTreat',
      '#teethToTreatLabel',
      '#chief_complaint',
      '#chief_complaint_title',
      '#arch_to_treat',
      '#bracket_teeth',
      '#clinical_condition',
      '#clinical_condition',
      '#ap_relationship_title',
      '#ap_relationship_box',
      '#crowding_title',
      '#spacing_title',
      '#overjet_title',
      '#overjet_box',
      '#deep_bite_title',
      '#deep_bite_box',
      '#crossbite_transverse_title',
      '#anterior_posterior',
      '#anteriorPosteriorNotes',
      '#tx_midlines',
      '#tx_auxiliaries',
      '#auxiliariesNotes',
    ];

    ids = ids.filter((id) => {
      return !(
        ((id === '#de2_initial_date' || id === '#de2_initial_date_input') && this.state.de2_initial_date_warning) ||
        ((id === '#de2_intra_date' || id === '#de2_intra_date_input') && this.state.de2_intra_date_warning) ||
        ((id === '#de3_intra_date' || id === '#de3_intra_date_input') && this.state.de3_intra_date_warning) ||
        ((id === '#de3_next_appointment_date' || id === '#de3_next_appointment_date_input') && this.state.de3_next_appointment_date_warning)
      );
    });

    this.hide_warning();

    _.each(ids, (id) => {
      let element = document.querySelector(id);
      if (element) {
        element.classList.remove('warning-border');
        element.classList.remove('warning-text');
      }
    });

    handleAnteriorPosteriorCheckboxErrors(false);
  };

  urlContainsWord = (word) => {
    let has_word = false;

    if (this.props.history && this.props.history.location && this.props.history.location.pathname.indexOf(word) >= 0) {
      has_word = true;
    }

    return has_word;
  };

  /**
   * Update file record state on user action
   * @function
   * @param {string} id - File id
   * @param {Object} record_states - Updated record_state
   * @param {folder} folder - folder of the file edited
   */
  updateRecordState = (id, record_states, folder) => {
    const no_id = id && typeof id === 'string' ? id.includes('-') : false;
    const file_type = folder && folder === 'photos' ? 'photoUpload' : 'xrayUpload';
    let new_file_upload = this.state[file_type];
    let index = no_id ? id.split('-').pop() : new_file_upload?.findIndex((file) => file.incomplete_case_file_id === id || file.id === id);

    if (index >= 0) {
      new_file_upload[index].state = record_states;
      new_file_upload[index].record_state = JSON.stringify(record_states);
      this.setState({ [file_type]: new_file_upload });
    }
  };

  getSubmitComponent = () => {
    const defaultProps = {
      onButtonClick: this.onFocus_clearWarning,
      case_id: this.state.id,
      onSubmitClick: this.onButtonClick,
      photoUpload: this.state.photoUpload,
      xrayUpload: this.state.xrayUpload,
      scanUpload: this.state.scanUpload,
      // iTero integration - props to be passed to submit component
      selectedIteroScanRecords: this.state.selectedIteroScanRecords,
      uploadMethod: this.state.uploadMethod,
    };
    if (this.state.default_plan_version === 'TX3.0') {
      return (
        <Submit
          {...defaultProps}
          summaryComponent={CaseSubmissionSummary}
          summaryProps={{
            getImpliedTeethSets: this.getImpliedTeethSets,
            missingTeeth: this.state.missingTeeth,
            extractTeeth: this.state.extractTeeth,
            archToTreat: this.state.archToTreat,
            opposingArch: this.state.opposingArch,
            bracketTeeth: this.state.bracketTeeth,
            restrictTeeth: this.state.restrictTeeth,
            notes: this.state.notes,
            anteriorPosteriorR: this.state.anteriorPosteriorR,
            anteriorPosteriorL: this.state.anteriorPosteriorL,
            anteriorPosteriorCorrections: this.state.anteriorPosteriorCorrections,
            anteriorPosteriorNotes: this.state.anteriorPosteriorNotes,
            midlines: this.state.midlines,
            auxiliariesWillBeUsed: this.state.auxiliariesWillBeUsed,
            auxiliariesNotes: this.state.auxiliariesNotes,
            revision_id: this.state.default_plan_version,
          }}
        />
      );
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return (
        <Submit
          {...defaultProps}
          summaryComponent={DESubmissionSummary}
          summaryProps={{
            de3_reason: this.state.de3_reason,
            de3_next_appointment_date: this.state.de3_next_appointment_date,
            de3_intra_date: this.state.de3_intra_date,
            de3_upper_smartwire: this.state.de3_upper_smartwire,
            de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
            de3_lower_smartwire: this.state.de3_lower_smartwire,
            de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
            de3_arch_to_treat: this.state.de3_arch_to_treat,
            de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
            de3_bracket_teeth: this.state.de3_bracket_teeth,
            de3_missing_teeth: this.state.de3_missing_teeth,
            de3_extract_teeth: this.state.de3_extract_teeth,
            de3_teeth_restriction: this.state.de3_teeth_restriction,
            de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
            de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
            de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
            de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
            de3_midlines: this.state.de3_midlines,
            de3_auxiliaries: this.state.de3_auxiliaries,
            de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
            de3_setup: this.state.de3_setup,
            de3_setup_instruction: this.state.de3_setup_instruction,
            de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
            getImpliedTeethSets: this.getDE3ImpliedTeethSets,
          }}
        />
      );
    }
    return <Submit {...defaultProps} />;
  };

  /**
   * iTero integration
   *
   * Fetch iTero photos
   * @param {String} iteroId - itero id
   * @function
   */
  fetchIteroPhotos = (iteroId) => {
    const photoUploadStateKey = this.getIteroPhotosStateKey();
    this.props.fetchIteroPhotos(iteroId, {
      onSuccess: (iteroPhotos) => {
        const iteroPhotoIds = iteroPhotos.map((file) => file.id);
        this.props.getIteroPhotoRecordsForRecordsReupload(
          {
            caseId: this.state.id,
            iteroFileIds: iteroPhotoIds,
            iteroId: iteroId,
          },
          {
            onSuccess: (data) => {
              const { success } = data;
              const uploadedIteroPhotos = Object.values(success);
              this.setState((prevState) => {
                // remove old itero photos from state
                const filteredPrevStatePhotoUpload = prevState[photoUploadStateKey].filter((file) => !file.is_itero_photo);

                const newPhotoUploadData = [...filteredPrevStatePhotoUpload, ...uploadedIteroPhotos];

                const newStepper = this.updatePhotoStepperState(newPhotoUploadData, prevState.stepper);

                return {
                  [photoUploadStateKey]: newPhotoUploadData,
                  selectedIteroPhotoRecords: uploadedIteroPhotos,
                  stepper: newStepper,
                };
              });
            },
          }
        );
      },
    });
  };

  /**
   * iTero integration
   *
   * Updates the Itero photos by removing all the photos from the state and fetching the photos for the new selected Itero ID.
   * @param {string} oldIteroId - The ID of the old Itero.
   * @param {string} newSelectedIteroId - The ID of the new selected Itero.
   */
  updateIteroPhotos = (oldIteroId, newSelectedIteroId) => {
    this.removeAllIteroPhotosFromState();
    if (this.state.shouldFetchIteroPhotos) {
      this.fetchIteroPhotos(newSelectedIteroId);
    }
  };

  /**
   * iTero integration
   *
   * Get iTero selection props
   * @param {Object} ctx - context
   * @returns {Object} - props
   */
  getIteroSelectionProps = (ctx) => {
    const currentCtx = ctx || this;
    return super.getIteroSelectionProps(currentCtx, {
      enableIteroSelection: this.state.isEnrolledToiTeroIntegration && (this.state.gen_2 || this.state.isDigitalEnhancement),
    });
  };

  onUpload = async (data) => {
    if (data && data[0].folder) {
      let uploadData = [];
      let stepper = this.state.stepper;
      let files = [];

      for (let index = 0; index < data.length; index++) {
        if (data[index].folder === 'scans') {
          const file_url = await getTempLink(data[index].original_filename, data[index].upload_data);
          data[index]['file_url'] = file_url;
        }
      }

      if (data[0].folder === 'photos') {
        uploadData = this.state.photoUpload;
        uploadData = uploadData.concat(data);

        if (this.runIdCompare(uploadData, this.state.orgPhotoUpload)) {
          stepper[this.getActivePositionByStepperName('Photos')] = 1;
        }

        files = uploadData.slice();

        if (this.state.xrayUpload && this.state.xrayUpload.length > 0) {
          files = files.concat(this.state.xrayUpload);
        }

        if (this.state.scanUpload && this.getScansLength() > 0) {
          files = files.concat(this.state.scanUpload);
        }

        if (this.state.active === this.getActivePositionByStepperName('Photos')) {
          this.setState({
            component: (
              <Photos
                id={this.state.id}
                photoUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                location="complete"
                upload_state={this.photos_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
                isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
                isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
                showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
                onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
                iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
              />
            ),
            active: this.getActivePositionByStepperName('Photos'),
            buttons: (
              <Button data-position={'next'} data-description={'Photos'} onClick={this.onButtonClick}>
                Next
              </Button>
            ),
          });
        } else if (this.state.active === this.getActivePositionByStepperName('Submit')) {
          this.setState({
            component: this.getSubmitComponent(files),
            active: this.getActivePositionByStepperName('Submit'),
            buttons: (
              <Button theme="secondary" data-position={'prev'} data-description={'Submit'} onClick={this.onButtonClick}>
                Back
              </Button>
            ),
          });
        }

        this.setState({
          photoUpload: uploadData,
          stepper: stepper,
          photos_uploading: [],
        });
      } else if (data[0].folder === 'xrays') {
        uploadData = this.state.xrayUpload;
        uploadData = uploadData.concat(data);

        if (this.runIdCompare(uploadData, this.state.orgXrayUpload)) {
          stepper[this.getActivePositionByStepperName('X-Rays')] = 1;
        }

        if (this.state.photoUpload && this.isPhotoUploadLengthValid()) {
          files = this.state.photoUpload;
        }

        files = files.concat(uploadData);

        if (this.state.scanUpload && this.getScansLength() > 0) {
          files = files.concat(this.state.scanUpload);
        }

        if (this.state.active === this.getActivePositionByStepperName('X-Rays')) {
          this.setState({
            component: (
              <Radiographs
                id={this.state.id}
                xrayUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                location="complete"
                upload_state={this.radiographs_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
              />
            ),
            active: this.getActivePositionByStepperName('X-Rays'),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={'X-Rays'} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={'X-Rays'} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          });
        } else if (this.state.active === this.getActivePositionByStepperName('Submit')) {
          this.setState({
            component: this.getSubmitComponent(files),
            active: this.getActivePositionByStepperName('Submit'),
            buttons: (
              <Button theme="secondary" data-position={'prev'} data-description={'Submit'} onClick={this.onButtonClick}>
                Back
              </Button>
            ),
          });
        }

        this.setState({
          xrayUpload: uploadData,
          stepper: stepper,
          radiographs_uploading: [],
        });
      } else if (data[0].folder === 'upperscans' || data[0].folder === 'lowerscans' || data[0].folder === 'scans') {
        uploadData = this.state.scanUpload;
        uploadData = uploadData.concat(data);

        if (this.runIdCompare(uploadData, this.state.orgScanUpload) && uploadData.length === 2) {
          stepper[this.getActivePositionByStepperName('Impressions')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Impressions')] = 2;
        }

        if (this.state.photoUpload && this.isPhotoUploadLengthValid()) {
          files = this.state.photoUpload;
        }

        if (this.state.xrayUpload && this.state.xrayUpload.length > 0) {
          files = files.concat(this.state.xrayUpload);
        }

        files = files.concat(uploadData);

        if (this.state.active === this.getActivePositionByStepperName('Impressions')) {
          this.setState({
            component: (
              <Scans
                id={this.state.id}
                scanUpload={uploadData}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                onEmptyFileError={this.onEmptyFileError}
                location="complete"
                text={this.state.isDigitalEnhancement ? 'de' : ''}
                upload_state={this.scans_upload}
                upload_content={[]}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                // iTero integration - props to be passed to the scans component
                uploadMethod={this.state.uploadMethod}
                onScansUploadMethodChange={this.handleScansUploadMethodChange}
                iteroSelectionProps={this.getIteroSelectionProps()}
                recordViewerSubtitle={this.getRecordViewerSubtitle()}
                isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
              />
            ),
            active: this.getActivePositionByStepperName('Impressions'),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={'Impressions'} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={'Impressions'} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          });

          this.showMoreTwoScansWarning(uploadData);
        } else if (this.state.active === this.getActivePositionByStepperName('Submit')) {
          this.setState({
            component: this.getSubmitComponent(files),
            active: this.getActivePositionByStepperName('Submit'),
            buttons: (
              <Button theme="secondary" data-position={'prev'} data-description={'Submit'} onClick={this.onButtonClick}>
                Back
              </Button>
            ),
          });
        }

        this.setState({
          scanUpload: uploadData,
          stepper: stepper,
          scans_uploading: [],
        });
      }
    }
  };

  onRemove = (event) => {
    setTokenHeader();
    event.preventDefault();

    //Perform State Deletion
    let href = event.currentTarget.href;
    let removeType = event.currentTarget.dataset.type;
    let startingPath = href.indexOf('/', 9);
    let newRef = decodeURI(href.substring(startingPath + 1, href.length));
    let uploadData = [];
    let newPhotoUpload = [];
    let newXrayUpload = [];
    let newScanUpload = [];
    let stepper = this.state.stepper;

    switch (removeType) {
      case 'photos':
        uploadData = this.state.photoUpload;

        for (let i = 0; i < uploadData.length; i++) {
          if (uploadData[i].upload_data !== newRef) {
            newPhotoUpload.push(uploadData[i]);
          }
        }

        if (this.getPhotoUploadLength(newPhotoUpload) === 0) {
          stepper[this.getActivePositionByStepperName('Photos')] = 2;
        }

        if (this.state.active === this.getActivePositionByStepperName('Photos')) {
          this.setState({
            component: (
              <Photos
                id={this.state.id}
                photoUpload={newPhotoUpload}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                location="complete"
                upload_state={this.photos_upload}
                upload_content={this.state.photos_uploading}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
                isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
                isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
                showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
                onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
                iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
              />
            ),
          });
        }

        this.setState({
          photoUpload: newPhotoUpload,
          stepper: stepper,
        });

        break;

      case 'xrays':
        uploadData = this.state.xrayUpload;
        for (let i = 0; i < uploadData.length; i++) {
          if (uploadData[i].upload_data !== newRef) {
            newXrayUpload.push(uploadData[i]);
          }
        }

        if (newXrayUpload.length === 0 && !this.state.isDigitalEnhancement) {
          stepper[this.getActivePositionByStepperName('X-Rays')] = 2;
        }

        if (this.state.active === this.getActivePositionByStepperName('X-Rays')) {
          this.setState({
            component: (
              <Radiographs
                id={this.state.id}
                xrayUpload={newXrayUpload}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                location="complete"
                upload_state={this.radiographs_upload}
                upload_content={this.state.radiographs_uploading}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                updateRecordState={this.updateRecordState}
              />
            ),
          });
        }

        this.setState({
          xrayUpload: newXrayUpload,
          stepper: stepper,
        });

        break;

      case 'upperscans':
      case 'lowerscans':
      case 'scans':
        uploadData = this.state.scanUpload;

        for (let i = 0; i < uploadData.length; i++) {
          if (uploadData[i].upload_data !== decodeURI(newRef)) {
            newScanUpload.push(uploadData[i]);
          }
        }

        if (newScanUpload.length !== 2) {
          stepper[this.getActivePositionByStepperName('Impressions')] = 2;
        } else if (newScanUpload.length === 2) {
          stepper[this.getActivePositionByStepperName('Impressions')] = 1;
        }

        if (this.state.active === this.getActivePositionByStepperName('Impressions')) {
          this.setState({
            component: (
              <Scans
                id={this.state.id}
                scanUpload={newScanUpload}
                onUpload={this.onUpload}
                onRemove={this.onRemove}
                onEmptyFileError={this.onEmptyFileError}
                location="complete"
                text={this.state.isDigitalEnhancement ? 'de' : ''}
                upload_state={this.scans_upload}
                upload_content={this.state.scans_uploading}
                show_warning={this.show_warning}
                hide_warning={this.hide_warning}
                // iTero integration - props to be passed to the scans component
                uploadMethod={this.state.uploadMethod}
                onScansUploadMethodChange={this.handleScansUploadMethodChange}
                iteroSelectionProps={this.getIteroSelectionProps()}
                recordViewerSubtitle={this.getRecordViewerSubtitle()}
              />
            ),
          });

          if (newScanUpload.length === 2) {
            this.onFocus_clearWarning();
          }
        }

        this.setState({
          scanUpload: newScanUpload,
          stepper: stepper,
        });
        break;
      default:
    }
  };

  isEnhancementChanged = () => {
    const loaded = this.state.loadedEnhancement;

    for (const key of Object.keys(loaded)) {
      if (
        [
          'restrictionsTeeth',
          'de2_teeth_restriction',
          'de2_bracket_remove',
          'de2_bracket_add',
          'de2_spacing_upper',
          'de2_spacing_lower',
          'de3_bracket_teeth',
          'de3_missing_teeth',
          'de3_extract_teeth',
          'de3_teeth_restriction',
        ].includes(key)
      ) {
        // Becuase 'restrictionsTeeth' is an array so we dually checked both arrays
        if (!(loaded[key].every((elem) => this.state[key].includes(elem)) && this.state[key].every((elem) => loaded[key].includes(elem)))) {
          return true;
        }
      } else if (['de3_anterior_posterior_corrections'].includes(key)) {
        // check if the two objects are equal
        if (!_.isEqual(loaded[key], this.state[key])) {
          return true;
        }
      } else if (loaded[key] !== this.state[key]) {
        return true;
      }
    }

    return false;
  };

  hasConditionsChange = () => {
    let result = false;
    const save_conditions = this.state.loaded_data.conditions;
    const state_conditions = this.state.conditions;
    const condition_names = ['ap_relationship', 'midlines', 'crowding', 'spacing', 'overject', 'deepbite', 'openbite', 'crossbite'];

    for (let i = 0; i < condition_names.length; i++) {
      if (!result) {
        for (let key in save_conditions[condition_names[i]]) {
          if (key !== 'info' && key !== 'title' && key !== 'infoEl' && save_conditions[condition_names[i]][key] !== state_conditions[condition_names[i]][key]) {
            //Array Comparision
            if (key === 'spaceTeeth') {
              result =
                _.intersection(save_conditions[condition_names[i]][key], state_conditions[condition_names[i]][key]).length !==
                save_conditions[condition_names[i]][key].length;
            } else {
              result = true;
            }

            break;
          }
        }
      } else {
        break;
      }
    }

    return result;
  };

  isTxPlanUnChange = () => {
    //Do check for tx_plan diff

    if (this.state.default_plan_version === 'TX2.0') {
      return (
        this.state.loaded_data &&
        this.state.loaded_data.chief_complaint === this.state.chief_complaint &&
        this.state.loaded_data.archToTreat === this.state.archToTreat &&
        this.state.loaded_data.opposingArch === this.state.opposingArch &&
        this.state.loaded_data.notes === this.state.notes &&
        this.state.loaded_data.notes_spacing === this.state.notes_spacing &&
        _.intersection(this.state.loaded_data.extractTeeth, this.state.extractTeeth).length === this.state.loaded_data.extractTeeth.length &&
        _.intersection(this.state.loaded_data.restrictTeeth, this.state.restrictTeeth).length === this.state.loaded_data.restrictTeeth.length &&
        _.intersection(this.state.loaded_data.missingTeeth, this.state.missingTeeth).length === this.state.loaded_data.missingTeeth.length &&
        _.intersection(this.state.loaded_data.bracketTeeth, this.state.bracketTeeth).length === this.state.loaded_data.bracketTeeth.length &&
        !this.hasConditionsChange() &&
        this.state.loaded_data.missingTeeth.sort().join('') === this.state.missingTeeth.sort().join('') &&
        this.state.loaded_data.extractTeeth.sort().join('') === this.state.extractTeeth.sort().join('') &&
        this.state.loaded_data.restrictTeeth.sort().join('') === this.state.restrictTeeth.sort().join('') &&
        this.state.loaded_data.bracketTeeth.sort().join('') === this.state.bracketTeeth.sort().join('')
      );
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TODO: TX3.0 isTxPlanUnChange
      return (
        this.state.loaded_data &&
        this.state.loaded_data.archToTreat === this.state.archToTreat &&
        this.state.loaded_data.opposingArch === this.state.opposingArch &&
        this.state.loaded_data.notes === this.state.notes &&
        this.state.loaded_data.anteriorPosteriorR === this.state.anteriorPosteriorR &&
        this.state.loaded_data.anteriorPosteriorL === this.state.anteriorPosteriorL &&
        JSON.stringify(this.state.loaded_data.anteriorPosteriorCorrections) === JSON.stringify(this.state.anteriorPosteriorCorrections) &&
        this.state.loaded_data.anteriorPosteriorNotes === this.state.anteriorPosteriorNotes &&
        this.state.loaded_data.midlines === this.state.midlines &&
        this.state.loaded_data.auxiliariesWillBeUsed === this.state.auxiliariesWillBeUsed &&
        this.state.loaded_data.auxiliariesNotes === this.state.auxiliariesNotes &&
        _.intersection(this.state.loaded_data.extractTeeth, this.state.extractTeeth).length === this.state.loaded_data.extractTeeth.length &&
        _.intersection(this.state.loaded_data.restrictTeeth, this.state.restrictTeeth).length === this.state.loaded_data.restrictTeeth.length &&
        _.intersection(this.state.loaded_data.missingTeeth, this.state.missingTeeth).length === this.state.loaded_data.missingTeeth.length &&
        _.intersection(this.state.loaded_data.bracketTeeth, this.state.bracketTeeth).length === this.state.loaded_data.bracketTeeth.length &&
        this.state.loaded_data.missingTeeth.sort().join('') === this.state.missingTeeth.sort().join('') &&
        this.state.loaded_data.extractTeeth.sort().join('') === this.state.extractTeeth.sort().join('') &&
        this.state.loaded_data.restrictTeeth.sort().join('') === this.state.restrictTeeth.sort().join('') &&
        this.state.loaded_data.bracketTeeth.sort().join('') === this.state.bracketTeeth.sort().join('')
      );
    }

    return false;
  };

  isDxTxPlanUnChange = () => {
    let result = false;

    if (
      this.state.loaded_data.dxtxSection0_chiefComplaint === this.state.dxtxSection0_chiefComplaint &&
      this.state.loaded_data.dxtxSection1_mx === this.state.dxtxSection1_mx &&
      this.state.loaded_data.dxtxSection1_gingival === this.state.dxtxSection1_gingival &&
      this.state.loaded_data.dxtxSection1_upperMidline2Facial === this.state.dxtxSection1_upperMidline2Facial &&
      this.state.loaded_data.dxtxSection1_upperMidline2FacialRight === this.state.dxtxSection1_upperMidline2FacialRight &&
      this.state.loaded_data.dxtxSection1_upperMidline2FacialLeft === this.state.dxtxSection1_upperMidline2FacialLeft &&
      this.state.loaded_data.dxtxSection1_rightMolars === this.state.dxtxSection1_rightMolars &&
      this.state.loaded_data.dxtxSection1_rightCanine === this.state.dxtxSection1_rightCanine &&
      this.state.loaded_data.dxtxSection1_leftMolars === this.state.dxtxSection1_leftMolars &&
      this.state.loaded_data.dxtxSection1_leftCanine === this.state.dxtxSection1_leftCanine &&
      this.state.loaded_data.dxtxSection1_overjet === this.state.dxtxSection1_overjet &&
      this.state.loaded_data.dxtxSection1_anterior === this.state.dxtxSection1_anterior &&
      this.state.loaded_data.dxtxSection1_deep === this.state.dxtxSection1_deep &&
      this.state.loaded_data.dxtxSection1_narrow === this.state.dxtxSection1_narrow &&
      this.state.loaded_data.dxtxSection1_posterior === this.state.dxtxSection1_posterior &&
      this.state.loaded_data.dxtxSection1_class === this.state.dxtxSection1_class &&
      this.state.loaded_data.dxtxSection1_crowding === this.state.dxtxSection1_crowding &&
      this.state.loaded_data.dxtxSection1_spaces === this.state.dxtxSection1_spaces &&
      this.state.loaded_data.dxtxSection1_flared === this.state.dxtxSection1_flared &&
      this.state.loaded_data.dxtxSection1_notes === this.state.dxtxSection1_notes &&
      this.state.loaded_data.dxtxSection2_arch === this.state.dxtxSection2_arch &&
      this.state.loaded_data.dxtxSection2_teeth === this.state.dxtxSection2_teeth &&
      this.state.loaded_data.dxtxSection2_teethCustom === this.state.dxtxSection2_teethCustom &&
      this.state.loaded_data.dxtxSection2_anteriorMaintainLeft === this.state.dxtxSection2_anteriorMaintainLeft &&
      this.state.loaded_data.dxtxSection2_anteriorMaintainRight === this.state.dxtxSection2_anteriorMaintainRight &&
      this.state.loaded_data.dxtxSection2_anteriorImproveLeft === this.state.dxtxSection2_anteriorImproveLeft &&
      this.state.loaded_data.dxtxSection2_anteriorImproveRight === this.state.dxtxSection2_anteriorImproveRight &&
      this.state.loaded_data.dxtxSection2_anteriorCorrectLeft === this.state.dxtxSection2_anteriorCorrectLeft &&
      this.state.loaded_data.dxtxSection2_anteriorCorrectRight === this.state.dxtxSection2_anteriorCorrectRight &&
      this.state.loaded_data.dxtxSection2_toothMovement === this.state.dxtxSection2_toothMovement &&
      this.state.loaded_data.dxtxSection2_toothMovementSelection.toString() === this.state.dxtxSection2_toothMovementSelection.toString() &&
      this.state.loaded_data.dxtxSection2_toothExtractionSelection.toString() === this.state.dxtxSection2_toothExtractionSelection.toString() &&
      this.state.loaded_data.dxtxSection2_toothUpper === this.state.dxtxSection2_toothUpper &&
      this.state.loaded_data.dxtxSection2_toothLower === this.state.dxtxSection2_toothLower &&
      this.state.loaded_data.dxtxSection2_overjet === this.state.dxtxSection2_overjet &&
      this.state.loaded_data.dxtxSection2_overbite === this.state.dxtxSection2_overbite &&
      this.state.loaded_data.dxtxSection2_overbiteOpenExtrudeAnteriorUpper === this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper &&
      this.state.loaded_data.dxtxSection2_overbiteOpenExtrudeAnteriorLower === this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower &&
      this.state.loaded_data.dxtxSection2_overbiteOpenIntrudePosteriorUpper === this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper &&
      this.state.loaded_data.dxtxSection2_overbiteOpenIntrudePosteriorLower === this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower &&
      this.state.loaded_data.dxtxSection2_overbiteDeepIntrudeAnteriorUpper === this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper &&
      this.state.loaded_data.dxtxSection2_overbiteDeepIntrudeAnteriorLower === this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower &&
      this.state.loaded_data.dxtxSection2_overbiteDeepExtrudePosteriorUpper === this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper &&
      this.state.loaded_data.dxtxSection2_overbiteDeepExtrudePosteriorLower === this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower &&
      this.state.loaded_data.dxtxSection2_midlineUpper === this.state.dxtxSection2_midlineUpper &&
      this.state.loaded_data.dxtxSection2_midlineUpperRight === this.state.dxtxSection2_midlineUpperRight &&
      this.state.loaded_data.dxtxSection2_midlineUpperLeft === this.state.dxtxSection2_midlineUpperLeft &&
      this.state.loaded_data.dxtxSection2_midlineLower === this.state.dxtxSection2_midlineLower &&
      this.state.loaded_data.dxtxSection2_midlineLowerRight === this.state.dxtxSection2_midlineLowerRight &&
      this.state.loaded_data.dxtxSection2_midlineLowerLeft === this.state.dxtxSection2_midlineLowerLeft &&
      this.state.loaded_data.dxtxSection2_posterior === this.state.dxtxSection2_posterior &&
      this.state.loaded_data.dxtxSection2_spacing === this.state.dxtxSection2_spacing &&
      this.state.loaded_data.dxtxSection2_spacingValue === this.state.dxtxSection2_spacingValue &&
      this.state.loaded_data.dxtxSection2_resolveUpperExpand === this.state.dxtxSection2_resolveUpperExpand &&
      this.state.loaded_data.dxtxSection2_resolveUpperProcline === this.state.dxtxSection2_resolveUpperProcline &&
      this.state.loaded_data.dxtxSection2_resolveUpperIPRAnterior === this.state.dxtxSection2_resolveUpperIPRAnterior &&
      this.state.loaded_data.dxtxSection2_resolveUpperIPRPosteriorRight === this.state.dxtxSection2_resolveUpperIPRPosteriorRight &&
      this.state.loaded_data.dxtxSection2_resolveUpperIPRPosteriorLeft === this.state.dxtxSection2_resolveUpperIPRPosteriorLeft &&
      this.state.loaded_data.dxtxSection2_resolveUpperExtraction === this.state.dxtxSection2_resolveUpperExtraction &&
      this.state.loaded_data.dxtxSection2_resolveLowerExpand === this.state.dxtxSection2_resolveLowerExpand &&
      this.state.loaded_data.dxtxSection2_resolveLowerProcline === this.state.dxtxSection2_resolveLowerProcline &&
      this.state.loaded_data.dxtxSection2_resolveLowerIPRAnterior === this.state.dxtxSection2_resolveLowerIPRAnterior &&
      this.state.loaded_data.dxtxSection2_resolveLowerIPRPosteriorRight === this.state.dxtxSection2_resolveLowerIPRPosteriorRight &&
      this.state.loaded_data.dxtxSection2_resolveLowerIPRPosteriorLeft === this.state.dxtxSection2_resolveLowerIPRPosteriorLeft &&
      this.state.loaded_data.dxtxSection2_resolveLowerExtraction === this.state.dxtxSection2_resolveLowerExtraction &&
      this.state.loaded_data.dxtxSection2_special === this.state.dxtxSection2_special
    ) {
      result = true;
    }

    return result;
  };

  // TODO: TX3.0 - add validation for anterior-posterior field
  isTxAnteriorPosteriorFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorCorrections } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.anteriorPosteriorL;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.anteriorPosteriorR;
    const currentAnteriorPosteriorCorrections = anteriorPosteriorCorrections || this.state.anteriorPosteriorCorrections;

    const correctionIsSelected = [currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('correction');

    if (correctionIsSelected) {
      return Object.values(currentAnteriorPosteriorCorrections).some((value) => value);
    }

    return true;
  };

  // DE3.0 validation for anterior-posterior field
  isDeAnteriorPosteriorFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorCorrections } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.de3_anterior_posterior_l;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.de3_anterior_posterior_r;
    const currentAnteriorPosteriorCorrections = anteriorPosteriorCorrections || this.state.de3_anterior_posterior_corrections;

    const correctionIsSelected = [currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('correction');

    if (correctionIsSelected) {
      return Object.values(currentAnteriorPosteriorCorrections).some((value) => value);
    }

    return true;
  };

  isTxAnteriorPosteriorNotesFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorNotes } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.anteriorPosteriorL;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.anteriorPosteriorR;
    const currentAnteriorPosteriorNotes = anteriorPosteriorNotes || this.state.anteriorPosteriorNotes;

    if ([currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('other')) {
      return currentAnteriorPosteriorNotes !== '';
    }

    return true;
  };

  // DE3.0 validation for anterior-posterior notes field
  isDeAnteriorPosteriorNotesFieldValid = ({ anteriorPosteriorL, anteriorPosteriorR, anteriorPosteriorNotes } = {}) => {
    const currentAnteriorPosteriorL = anteriorPosteriorL || this.state.de3_anterior_posterior_l;
    const currentAnteriorPosteriorR = anteriorPosteriorR || this.state.de3_anterior_posterior_r;
    const currentAnteriorPosteriorNotes = anteriorPosteriorNotes || this.state.de3_anterior_posterior_notes;

    if ([currentAnteriorPosteriorL, currentAnteriorPosteriorR].includes('other')) {
      return currentAnteriorPosteriorNotes !== '';
    }

    return true;
  };

  /**
   * Checks if TX bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns boolean
   */
  isTxBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat } = {}) => {
    const currentBracketTeeth = bracketTeeth || this.state.bracketTeeth;
    const currentMissingTeeth = missingTeeth || this.state.missingTeeth;
    const currentExtractTeeth = extractTeeth || this.state.extractTeeth;
    const currentArchToTreat = archToTreat || this.state.archToTreat;

    return isBracketTeethValidHelper(
      {
        bracketTeeth: currentBracketTeeth,
        missingTeeth: currentMissingTeeth,
        extractTeeth: currentExtractTeeth,
        archToTreat: currentArchToTreat,
      },
      Teeth
    );
  };

  /**
   * Checks if DE3.0 bracketTeeth is valid based on selected missingTeeth, extractTeeth and archToTreat
   * @param {object} data object with bracketTeeth, missingTeeth, extractTeeth, and archToTreat
   * @returns {boolean} - True or false
   */
  isDeBracketTeethValid = ({ bracketTeeth, missingTeeth, extractTeeth, archToTreat } = {}) => {
    const currentBracketTeeth = bracketTeeth || this.state.de3_bracket_teeth;
    const currentMissingTeeth = missingTeeth || this.state.de3_missing_teeth;
    const currentExtractTeeth = extractTeeth || this.state.de3_extract_teeth;
    const currentArchToTreat = archToTreat || this.state.de3_arch_to_treat;

    return isBracketTeethValidHelper(
      {
        bracketTeeth: currentBracketTeeth,
        missingTeeth: currentMissingTeeth,
        extractTeeth: currentExtractTeeth,
        archToTreat: currentArchToTreat,
      },
      Teeth
    );
  };

  // TODO: TX3.0 - add validation for auxiliaries
  isTxAuxiliariesFieldValid = ({ auxiliariesWillBeUsed, auxiliariesNotes } = {}) => {
    const currentAuxiliariesWillBeUsed = auxiliariesWillBeUsed || this.state.auxiliariesWillBeUsed;
    const currentAuxiliariesNotes = auxiliariesNotes || this.state.auxiliariesNotes;

    if (currentAuxiliariesWillBeUsed === 'Yes') {
      return currentAuxiliariesNotes !== '';
    }

    return true;
  };

  // DE3.0 auxiliaries field validation
  isDeAuxiliariesFieldValid = ({ auxiliaries, auxiliariesNotes } = {}) => {
    const currentAuxiliaries = auxiliaries || this.state.de3_auxiliaries;
    const currentAuxiliariesNotes = auxiliariesNotes || this.state.de3_auxiliaries_notes;

    if (currentAuxiliaries === 'Yes') {
      return currentAuxiliariesNotes !== '';
    }

    return true;
  };

  /**
   * Determines if anterior-posterior field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorFieldValid = (data = {}) => {
    if (this.state.default_plan_version === 'TX3.0') {
      return this.isTxAnteriorPosteriorFieldValid(data);
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return this.isDeAnteriorPosteriorFieldValid(data);
    }

    return true;
  };

  /**
   * Determines if anterior-posterior notes field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAnteriorPosteriorNotesFieldValid = (data = {}) => {
    if (this.state.default_plan_version === 'TX3.0') {
      return this.isTxAnteriorPosteriorNotesFieldValid(data);
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return this.isDeAnteriorPosteriorNotesFieldValid(data);
    }

    return true;
  };

  /**
   * Checks if bracketTeeth is valid based on selected missingTeeth, extractTeeth, and archToTreat
   *
   * @param {} data object with bracketTeeth, missingTeeth, extractTeeth, archToTreat
   *
   * @returns boolean
   */
  isBracketTeethValid = (data = {}) => {
    if (this.state.default_plan_version === 'TX3.0') {
      return this.isTxBracketTeethValid(data);
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return this.isDeBracketTeethValid(data);
    }

    return true;
  };

  /**
   * Determines if auxiliaries field is valid
   * @function
   * @returns {boolean} - True or false
   */
  isAuxiliariesFieldValid = (data = {}) => {
    if (this.state.default_plan_version === 'TX3.0') {
      return this.isTxAuxiliariesFieldValid(data);
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return this.isDeAuxiliariesFieldValid(data);
    }

    return true;
  };

  /**
   * Check if setupInstruction is valid based on selected setup
   * @param {object} data object with setupInstruction and setup
   * @param {string} data.setupInstruction - Setup Instruction
   * @param {string} data.setup - Setup
   * @returns {boolean} - True or false
   */
  isSetupInstructionValid = ({ setupInstruction, setup } = {}) => {
    const currentSetupInstruction = setupInstruction || this.state.de3_setup_instruction;
    const currentSetup = setup || this.state.de3_setup;

    return isSetupInstructionValidHelper({
      setupInstruction: currentSetupInstruction,
      setup: currentSetup,
    });
  };

  deFormIsValid = (values = null) => {
    const curentValues = values || this.state;
    if (this.state.default_plan_version === 'DE3.0') {
      const isAuxiliariesFieldValid = this.isAuxiliariesFieldValid();
      const isAnteriorPosteriorFieldValid = this.isAnteriorPosteriorFieldValid();
      const isAnteriorPosteriorNotesFieldValid = this.isAnteriorPosteriorNotesFieldValid();
      const isBracketTeethValid = this.isBracketTeethValid();
      const isSetupInstructionValid = this.isSetupInstructionValid();
      return (
        curentValues.de3_reason !== '' &&
        curentValues.de3_intra_date !== '' &&
        !curentValues.de3_next_appointment_date_warning &&
        !curentValues.de3_intra_date_warning &&
        !curentValues.de3_upper_smartwire_date_warning &&
        !curentValues.de3_lower_smartwire_date_warning &&
        isBracketTeethValid &&
        isAuxiliariesFieldValid &&
        isAnteriorPosteriorFieldValid &&
        isAnteriorPosteriorNotesFieldValid &&
        isSetupInstructionValid
      );
    }

    return false;
  };

  isSubmitAvailable = () => {
    if (this.state.default_plan_version === 'DE2.0') {
      return (
        this.state.de2_reason &&
        this.state.de2_intra_date &&
        this.state.de2_initial_date &&
        this.isPhotoUploadLengthValid() &&
        this.isScansLengthValid() &&
        !this.state.de2_initial_date_warning &&
        !this.state.de2_intra_date_warning &&
        !this.state.de2_upper_smartwire_date_warning &&
        !this.state.de2_lower_smartwire_date_warning
      );
    }

    if (this.state.default_plan_version === 'DE3.0') {
      return this.isPhotoUploadLengthValid() && this.isScansLengthValid() && this.deFormIsValid();
    }

    if (this.state.default_plan_version === 'TX2.0') {
      return (
        this.isPhotoUploadLengthValid() &&
        this.state.xrayUpload.length >= 1 &&
        this.isScansLengthValid() &&
        this.state.chief_complaint &&
        this.state.archToTreat &&
        this.state.bracketTeeth &&
        this.state.bracketTeeth.length > 0 &&
        this.hasOneClinicalCondition() &&
        this.hasClinicalConditionRequireFieldMet()
      );
    }

    // TODO: TX3.0 isSubmitAvailable
    if (this.state.default_plan_version === 'TX3.0') {
      return this.isPhotoUploadLengthValid() && this.state.xrayUpload.length >= 1 && this.isScansLengthValid() && this.metAllPlanRequiredField();
    }

    return false;
  };

  isStepperDone = (stepper) => {
    let result = true;

    for (let i = 0; i < stepper.length - 1; i++) {
      if (stepper[i] === 0 || stepper[i] === 2) {
        result = false;
        break;
      }
    }

    return result;
  };

  hasNewPhotos = () => {
    return this.runIdCompare(this.state.photoUpload, this.state.orgPhotoUpload);
  };

  hasNewXrays = () => {
    return this.runIdCompare(this.state.xrayUpload, this.state.orgXrayUpload);
  };

  hasNewScans = () => {
    if (this.state.uploadMethod === 'manual') {
      return this.runIdCompare(this.state.scanUpload, this.state.orgScanUpload);
    }
    if (this.state.uploadMethod === 'itero') {
      return this.selectedIteroScanIdsChanged(this.state.orgSelectedIteroScanIds);
    }
    return false;
  };

  hasPhotosChange = () => {
    return this.hasNewPhotos() || this.state.photoUpload.length !== this.state.orgPhotoUpload.length;
  };

  hasXraysChange = () => {
    return this.hasNewXrays() || this.state.xrayUpload.length !== this.state.orgXrayUpload.length;
  };

  getOrgScanUploadLength = () => {
    if (this.state.uploadMethod === 'manual') {
      return this.state.orgScanUpload.length || 0;
    }
    if (this.state.uploadMethod === 'itero') {
      return this.state.orgSelectedIteroScanIds.length || 0;
    }
    return 0;
  };

  hasScansChange = () => {
    return this.hasNewScans() || this.getScansLength() !== this.getOrgScanUploadLength();
  };

  hasOnePhoto = () => {
    if (this.getPhotoUploadLength() === 0 && this.state.photos_uploading.length === 0) {
      let warning = document.querySelector('#warning-submit');

      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = `<ul><li>${this.getPhotoSectionErrorMessage()}</li></ul>`;

        this.setState({
          warning: true,
          warning_title: 'Incomplete Fields',
        });
      }

      return false;
    }

    return true;
  };

  hasOneXray = () => {
    if (this.state.xrayUpload.length === 0 && this.state.radiographs_uploading.length === 0 && !this.state.isDigitalEnhancement) {
      let warning = document.querySelector('#warning-submit');
      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul><li>Radiographs</li></ul>';

        this.setState({
          warning: true,
          warning_title: 'Incomplete Fields',
        });
      }

      return false;
    }

    return true;
  };

  showMoreTwoScansWarning(scans) {
    if (scans.length > 2) {
      let warning = document.querySelector('#warning-submit');

      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul><li>More than 2 STL Files</li></ul>';

        this.setState({
          warning: true,
        });
      }
    }
  }

  hasTwoScans = () => {
    const _hasTwoScans = hasTwoScansHelper({
      uploadMethod: this.state.uploadMethod,
      scanUpload: this.state.scanUpload,
      scans_uploading: this.state.scans_uploading,
      selectedIteroScans: this.state.selectedIteroScans,
    });

    if (!_hasTwoScans) {
      this.setState({
        warning: true,
      });
    }

    return _hasTwoScans;
  };

  hasOneClinicalCondition = () => {
    return (
      this.state.conditions.ap_relationship.active ||
      this.state.conditions.midlines.active ||
      this.state.conditions.crowding.active ||
      this.state.conditions.spacing.active ||
      this.state.conditions.overjet.active ||
      this.state.conditions.deepbite.active ||
      this.state.conditions.openbite.active ||
      this.state.conditions.crossbite.active
    );
  };

  crowdingHasOneValidSelection = () => {
    let that = this;
    const fields = [
      'lower_expand',
      'lower_ipr_ant',
      'lower_ipr_left',
      'lower_ipr_right',
      'lower_procline',
      'upper_expand',
      'upper_ipr_ant',
      'upper_ipr_left',
      'upper_ipr_right',
      'upper_procline',
    ];

    const result = fields.filter((field) => that.state.conditions.crowding[field] !== 'none');

    return result.length > 0;
  };

  hasSpacingValue = (conditions = this.state.conditions) => {
    const spacing = conditions.spacing.spacingUpper.concat(conditions.spacing.spacingLower);
    const result = spacing.filter((space_value) => space_value !== '');

    return result.length > 0 || conditions.spacing.spaces === 'close';
  };

  hasClinicalConditionRequireFieldMet = (
    conditions = this.state.conditions,
    notes_spacing = this.state.notes_spacing,
    arch_to_treat = this.state.archToTreat,
    opposingArch = this.state.opposingArch
  ) => {
    let hasMet = true;

    if (conditions.ap_relationship.active && conditions.ap_relationship.ap_improvement === 'auxiliaries' && conditions.ap_relationship.aux_note === '') {
      hasMet = false;
    } else if (conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
      hasMet = false;
    } else if (conditions.spacing.active && !this.hasSpacingValue(conditions) && notes_spacing === '') {
      hasMet = false;
    } else if (conditions.overjet.active && conditions.overjet.improve === 'auxiliaries' && conditions.overjet.aux_note === '') {
      hasMet = false;
    } else if (conditions.deepbite.active && conditions.deepbite.correct === 'auxiliaries' && conditions.deepbite.aux_note === '') {
      hasMet = false;
    } else if (
      (conditions.crossbite.active &&
        (arch_to_treat === 'both' || (arch_to_treat === 'upper' && opposingArch === 'ideal') || (arch_to_treat === 'lower' && opposingArch === 'ideal')) &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_lower &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'upper' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_advance_upper &&
        !conditions.crossbite.posterior_expand_upper &&
        !conditions.crossbite.posterior_narrow_upper) ||
      (conditions.crossbite.active &&
        arch_to_treat === 'lower' &&
        opposingArch === 'current' &&
        !conditions.crossbite.anterior_retract_lower &&
        !conditions.crossbite.posterior_expand_lower &&
        !conditions.crossbite.posterior_narrow_lower)
    ) {
      hasMet = false;
    }

    return hasMet;
  };

  /**
   * Returns whether all required fields are filled out and displays warning messages
   * @function
   * @returns {boolean} - True or false
   */
  hasTxPlanField = () => {
    if (
      this.state.default_plan_version === 'TX2.0' &&
      (this.state.chief_complaint === '' ||
        this.state.archToTreat === '' ||
        (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) ||
        !this.hasOneClinicalCondition() ||
        !this.hasClinicalConditionRequireFieldMet())
    ) {
      let warning = document.querySelector('#warning-submit');
      let warnings = '';

      warning.classList.add('warning-display');

      if (this.state.chief_complaint === '') {
        document.querySelector('#chief_complaint').classList.add('warning-border');
        document.querySelector('#chief_complaint_title').classList.add('warning-text');
        warnings = '<li><a href="#chief_complaint_title">Patient Chief Complaint</a></li>';
      }

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.hasOneClinicalCondition()) {
        document.querySelector('#clinical_condition').classList.add('warning-text');
        warnings = warnings + '<li><a href="#clinical_condition">Missing Clinical Condition</a></li>';
      }

      if (
        this.state.conditions.ap_relationship.active &&
        this.state.conditions.ap_relationship.ap_improvement === 'auxiliaries' &&
        this.state.conditions.ap_relationship.aux_note === ''
      ) {
        document.querySelector('#ap_relationship_title').classList.add('warning-text');
        document.querySelector('#ap_relationship_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#ap_relationship_title">AP Relationship</a></li>';
      }

      if (this.state.conditions.crowding.active && !this.crowdingHasOneValidSelection()) {
        document.querySelector('#crowding_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crowding_title">Crowding</a></li>';
      }

      if (this.state.conditions.spacing.active && !this.hasSpacingValue() && this.state.notes_spacing === '') {
        document.querySelector('#spacing_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#spacing_title">Spacing</a></li>';
      }

      if (this.state.conditions.overjet.active && this.state.conditions.overjet.improve === 'auxiliaries' && this.state.conditions.overjet.aux_note === '') {
        document.querySelector('#overjet_title').classList.add('warning-text');
        document.querySelector('#overjet_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#overjet_title">Overjet</a></li>';
      }

      if (this.state.conditions.deepbite.active && this.state.conditions.deepbite.correct === 'auxiliaries' && this.state.conditions.deepbite.aux_note === '') {
        document.querySelector('#deep_bite_title').classList.add('warning-text');
        document.querySelector('#deep_bite_box').classList.add('warning-border');
        warnings = warnings + '<li><a href="#deep_bite_title">Deepbite</a></li>';
      }

      if (
        (this.state.conditions.crossbite.active &&
          (this.state.archToTreat === 'both' ||
            (this.state.archToTreat === 'upper' && this.state.opposingArch === 'ideal') ||
            (this.state.archToTreat === 'lower' && this.state.opposingArch === 'ideal')) &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_lower &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'upper' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_advance_upper &&
          !this.state.conditions.crossbite.posterior_expand_upper &&
          !this.state.conditions.crossbite.posterior_narrow_upper) ||
        (this.state.conditions.crossbite.active &&
          this.state.archToTreat === 'lower' &&
          this.state.opposingArch === 'current' &&
          !this.state.conditions.crossbite.anterior_retract_lower &&
          !this.state.conditions.crossbite.posterior_expand_lower &&
          !this.state.conditions.crossbite.posterior_narrow_lower)
      ) {
        document.querySelector('#crossbite_transverse_title').classList.add('warning-text');
        warnings = warnings + '<li><a href="#crossbite_transverse_title">Crossbite/Transverse</a></li>';
      }

      warning.innerHTML = `<ul>${warnings}</ul>`;

      this.setState({
        warning: true,
      });

      return false;
    } else if (this.state.default_plan_version === 'TX3.0' && !this.metAllPlanRequiredField()) {
      // TODO: TX3.0 - Add validation errors for TX3.0 fields
      let warning = document.querySelector('#warning-submit');
      let warnings = '';

      warning.classList.add('warning-display');

      if (this.state.archToTreat === '') {
        document.querySelector('#arch_to_treat').classList.add('warning-text');
        warnings = warnings + '<li><a href="#arch_to_treat">Arch to Treat</a></li>';
      }

      if (!this.isBracketTeethValid()) {
        document.querySelector('#bracket_teeth').classList.add('warning-text');
        warnings = warnings + '<li><a href="#bracket_teeth">Bracket Teeth</a></li>';
      }

      if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
        document.querySelector('#anterior_posterior').classList.add('warning-text');
        if (!this.isAnteriorPosteriorFieldValid()) {
          handleAnteriorPosteriorCheckboxErrors(true);
        }
        if (!this.isAnteriorPosteriorNotesFieldValid()) {
          document.querySelector('#anteriorPosteriorNotes').classList.add('warning-border');
        }
        warnings = warnings + '<li><a href="#anterior_posterior">Anterior-Posterior</a></li>';
      }

      if (!this.state.midlines) {
        document.querySelector('#tx_midlines').classList.add('warning-text');
        warnings = warnings + '<li><a href="#tx_midlines">Midlines</a></li>';
      }

      if (!this.isAuxiliariesFieldValid()) {
        document.querySelector('#tx_auxiliaries').classList.add('warning-text');

        document.querySelector('#auxiliariesNotes').classList.add('warning-border');

        warnings = warnings + '<li><a href="#tx_auxiliaries">Auxiliaries</a></li>';
      }

      warning.innerHTML = `<ul>${warnings}</ul>`;

      this.setState({
        warning: true,
      });

      return false;
    }

    return true;
  };

  getAllFiles = () => {
    let files = [];

    if (this.state.photoUpload && this.isPhotoUploadLengthValid()) {
      files = this.state.photoUpload;
    }
    if (this.state.xrayUpload && this.state.xrayUpload.length > 0) {
      files = files.concat(this.state.xrayUpload);
    }
    if (this.state.scanUpload && this.getScansLength() > 0) {
      files = files.concat(this.state.scanUpload);
    }

    return files;
  };

  runIdCompare = (a, b) => {
    //a = currentFiles
    //b = orgFiles
    let res = false;
    let cnt = 0;

    for (let i = 0; i < a.length; i++) {
      cnt = 0;

      for (let j = 0; j < b.length; j++) {
        if (a[i].id === b[j].id) {
          cnt = 0;
          break;
        }
        cnt++;
      }

      if (cnt >= b.length) {
        //No match, so therefore this file is new
        res = true;
        break;
      }
    }

    return res;
  };

  isRefinementFormComplete = () => {
    let result = false;

    if (this.state.scanDate !== '' && this.state.reason !== '' && this.state.setup !== '') {
      result = true;
    }

    if (!result) {
      if (this.state.scanDate === '') {
        if (document.querySelector('#scanDate')) {
          document.querySelector('#scanDate').classList.add('warning-border');
        }

        if (document.querySelector('#intraOral')) {
          document.querySelector('#intraOral').classList.add('warning-text');
        }
      }

      if (this.state.reason === '') {
        if (document.querySelector('#reasonSelection')) {
          document.querySelector('#reasonSelection').classList.add('warning-border');
        }
        if (document.querySelector('#reason')) {
          document.querySelector('#reason').classList.add('warning-text');
        }
      }

      if (this.state.setup === '') {
        if (document.querySelector('#setupSelection')) {
          document.querySelector('#setupSelection').classList.add('warning-border');
        }
        if (document.querySelector('#setup')) {
          document.querySelector('#setup').classList.add('warning-text');
        }
      }
    }

    return result;
  };

  isDE2FormComplete = (warning = true) => {
    let result = false;

    if (
      this.state.de2_intra_date !== '' &&
      this.state.de2_reason !== '' &&
      this.state.de2_initial_date !== '' &&
      !this.state.de2_initial_date_warning &&
      !this.state.de2_intra_date_warning &&
      !this.state.de2_upper_smartwire_date_warning &&
      !this.state.de2_lower_smartwire_date_warning
    ) {
      result = true;
    }

    if (!result && warning) {
      if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
        if (document.querySelector('#de2_initial_date_input')) {
          document.querySelector('#de2_initial_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_initial_date')) {
          document.querySelector('#de2_initial_date').classList.add('warning-text');
        }
      }

      if (this.state.de2_reason === '') {
        if (document.querySelector('#de2_reason_textarea')) {
          document.querySelector('#de2_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de2_reason')) {
          document.querySelector('#de2_reason').classList.add('warning-text');
        }
      }

      if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
        if (document.querySelector('#de2_intra_date_input')) {
          document.querySelector('#de2_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de2_intra_date')) {
          document.querySelector('#de2_intra_date').classList.add('warning-text');
        }
      }
    }

    return result;
  };

  /**
   * Check if DE3.0 form is complete
   * @param {boolean} warning add warning class to fields if true
   * @param {object} values values to check, if null, use state
   * @returns
   */
  isDE3FormComplete = (warning = true, values = null) => {
    const curentValues = values || this.state;
    let result = false;
    const isAuxiliariesFieldValid = this.isAuxiliariesFieldValid();
    const isAnteriorPosteriorFieldValid = this.isAnteriorPosteriorFieldValid();
    const isAnteriorPosteriorNotesFieldValid = this.isAnteriorPosteriorNotesFieldValid();
    const isBracketTeethValid = this.isBracketTeethValid();
    const isSetupInstructionValid = this.isSetupInstructionValid();

    if (this.deFormIsValid(curentValues)) {
      result = true;
    }

    if (!result && warning) {
      if (curentValues.de3_next_appointment_date_warning) {
        if (document.querySelector('#de3_next_appointment_date_input')) {
          document.querySelector('#de3_next_appointment_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_next_appointment_date')) {
          document.querySelector('#de3_next_appointment_date').classList.add('warning-text');
        }
      }

      if (curentValues.de3_reason === '') {
        if (document.querySelector('#de3_reason_textarea')) {
          document.querySelector('#de3_reason_textarea').classList.add('warning-border');
        }
        if (document.querySelector('#de3_reason')) {
          document.querySelector('#de3_reason').classList.add('warning-text');
        }
      }

      if (curentValues.de3_intra_date === '' || curentValues.de3_intra_date_warning) {
        if (document.querySelector('#de3_intra_date_input')) {
          document.querySelector('#de3_intra_date_input').classList.add('warning-border');
        }
        if (document.querySelector('#de3_intra_date')) {
          document.querySelector('#de3_intra_date').classList.add('warning-text');
        }
      }

      if (!isBracketTeethValid) {
        if (document.querySelector('#de3_bracket_teeth')) {
          document.querySelector('#de3_bracket_teeth').classList.add('warning-text');
        }
      }

      if (!isAuxiliariesFieldValid) {
        if (document.querySelector('#de3_auxiliaries_notes')) {
          document.querySelector('#de3_auxiliaries_notes').classList.add('warning-border');
        }
        if (document.querySelector('#de3_auxiliaries')) {
          document.querySelector('#de3_auxiliaries').classList.add('warning-text');
        }
      }

      if (!isAnteriorPosteriorFieldValid) {
        if (document.querySelector('#de3_anterior_posterior')) {
          document.querySelector('#de3_anterior_posterior').classList.add('warning-text');
          handleAnteriorPosteriorCheckboxErrors(true);
        }
      }

      if (!isAnteriorPosteriorNotesFieldValid) {
        if (document.querySelector('#de3_anterior_posterior')) {
          document.querySelector('#de3_anterior_posterior').classList.add('warning-text');
        }
        if (document.querySelector('#de3_anterior_posterior_notes')) {
          document.querySelector('#de3_anterior_posterior_notes').classList.add('warning-border');
        }
      }

      if (!isSetupInstructionValid) {
        if (document.querySelector('#de3_setup_instruction')) {
          document.querySelector('#de3_setup_instruction').classList.add('warning-text');
        }
        if (document.querySelector('textarea[data-field="de3_setup_instruction"]')) {
          document.querySelector('textarea[data-field="de3_setup_instruction"]').classList.add('warning-border');
        }
        if (document.querySelector('#de3_setup')) {
          document.querySelector('#de3_setup').classList.add('warning-text');
        }
      }
    }

    return result;
  };

  getPosition() {
    const position = [
      { name: 'Impressions' },
      { name: 'Photos' },
      { name: 'X-Rays' },
      { name: this.state.isDigitalEnhancement ? 'Refinement Request Form' : 'Dx/Tx Plan' },
      { name: 'Submit' },
    ];

    return position;
  }

  getActivePositionByStepperName(stepper_name) {
    const position = this.getPosition();
    let active_index = 0;

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        active_index = i;
        break;
      }
    }

    return active_index;
  }

  getNextStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (position.length - 1 !== i) {
          name = position[i + 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getBackStepperNameByStepperName(stepper_name) {
    const position = this.getPosition();
    let name = '';

    for (let i = 0; i < position.length; i++) {
      if (position[i].name === stepper_name) {
        if (i > 0) {
          name = position[i - 1].name;
        } else {
          name = position[i].name;
        }
        break;
      }
    }

    return name;
  }

  getComponentByStepperName(stepper_name) {
    let component = (
      <Scans
        id={this.state.id}
        scanUpload={this.state.scanUpload}
        onUpload={this.onUpload}
        onRemove={this.onRemove}
        onEmptyFileError={this.onEmptyFileError}
        location="complete"
        text={this.state.isDigitalEnhancement ? 'de' : ''}
        upload_state={this.scans_upload}
        upload_content={this.state.scans_uploading}
        show_warning={this.show_warning}
        hide_warning={this.hide_warning}
        // iTero integration - props to be passed to the scans component
        uploadMethod={this.state.uploadMethod}
        onScansUploadMethodChange={this.handleScansUploadMethodChange}
        iteroSelectionProps={this.getIteroSelectionProps()}
        recordViewerSubtitle={this.getRecordViewerSubtitle()}
        isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
      />
    );

    switch (stepper_name) {
      case 'Photos':
        component = (
          <Photos
            id={this.state.id}
            photoUpload={this.state.photoUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            location="complete"
            upload_state={this.photos_upload}
            upload_content={this.state.photos_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            updateRecordState={this.updateRecordState}
            isLoadingIteroPhotos={this.isLoadingIteroPhotos()}
            isDeletingIteroPhotos={this.isDeletingIteroPhotos()}
            showRemoveIteroPhotos={this.shouldShowRemoveIteroPhotos()}
            onRemoveIteroPhotosClick={this.handleRemoveIteroPhotosClick}
            iteroPhotosDropzoneMessage={this.hasIteroPhotos() ? this.getIteroPhotosDropzoneMessage() : null}
          />
        );
        break;
      case 'X-Rays':
        component = (
          <Radiographs
            id={this.state.id}
            xrayUpload={this.state.xrayUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            location="complete"
            upload_state={this.radiographs_upload}
            upload_content={this.state.radiographs_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            updateRecordState={this.updateRecordState}
          />
        );
        break;
      case 'Impressions':
        component = (
          <Scans
            id={this.state.id}
            scanUpload={this.state.scanUpload}
            onUpload={this.onUpload}
            onRemove={this.onRemove}
            onEmptyFileError={this.onEmptyFileError}
            location="complete"
            text={this.state.isDigitalEnhancement ? 'de' : ''}
            upload_state={this.scans_upload}
            upload_content={this.state.scans_uploading}
            show_warning={this.show_warning}
            hide_warning={this.hide_warning}
            // iTero integration - props to be passed to the scans component
            uploadMethod={this.state.uploadMethod}
            onScansUploadMethodChange={this.handleScansUploadMethodChange}
            iteroSelectionProps={this.getIteroSelectionProps()}
            recordViewerSubtitle={this.getRecordViewerSubtitle()}
            isLoadingIteroPhotos={this.state.uploadMethod === 'itero' && this.isLoadingIteroPhotos()}
          />
        );
        break;
      case 'Dx/Tx Plan':
        component = this.getPlanByVersion();
        break;
      case 'Refinement Request Form':
        if (this.state.default_plan_version === 'DE2.0') {
          component = this.getDEForm({
            scanDate: this.state.scanDate,
            reason: this.state.reason,
            upperSmartWire: this.state.upperSmartWire,
            lowerSmartWire: this.state.lowerSmartWire,
            setup: this.state.setup,
            restrictions: this.state.restrictions,
            restrictionsTeeth: this.state.restrictionsTeeth,
            ipr: this.state.ipr,
            iprDialog: this.state.iprDialog,
            residual: this.state.residual,
            upper: this.state.upper,
            lower: this.state.lower,
            de2_reason: this.state.de2_reason,
            de2_initial_date: this.state.de2_initial_date,
            de2_intra_date: this.state.de2_intra_date,
            de2_upper_smartwire: this.state.de2_upper_smartwire,
            de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
            de2_lower_smartwire: this.state.de2_lower_smartwire,
            de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
            de2_arch_to_treat: this.state.de2_arch_to_treat,
            de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
            de2_setup: this.state.de2_setup,
            de2_setup_instruction: this.state.de2_setup_instruction,
            de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
            de2_bracket_instruction: this.state.de2_bracket_instruction,
            de2_teeth_restriction: this.state.de2_teeth_restriction,
            de2_bracket_remove: this.state.de2_bracket_remove,
            de2_bracket_add: this.state.de2_bracket_add,
            de2_ipr: this.state.de2_ipr,
            de2_ipr_instruction: this.state.de2_ipr_instruction,
            de2_spacing: this.state.de2_spacing,
            de2_spacing_upper: this.state.de2_spacing_upper,
            de2_spacing_lower: this.state.de2_spacing_lower,
            de2_spacing_instruction: this.state.de2_spacing_instruction,
            de2_special_instruction: this.state.de2_special_instruction,
            de2_prev_case_info: this.state.de2_prev_case_info,
            de2_initial_date_warning: this.state.de2_initial_date_warning,
            de2_intra_date_warning: this.state.de2_intra_date_warning,
          });
        } else if (this.state.default_plan_version === 'DE3.0') {
          // DE3.0 set component on stepper change
          component = this.getDE3Form({
            de3_reason: this.state.de3_reason,
            de3_next_appointment_date: this.state.de3_next_appointment_date,
            de3_intra_date: this.state.de3_intra_date,
            de3_upper_smartwire: this.state.de3_upper_smartwire,
            de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
            de3_lower_smartwire: this.state.de3_lower_smartwire,
            de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
            de3_arch_to_treat: this.state.de3_arch_to_treat,
            de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
            de3_upper_arch_opposing_treat: this.state.de3_upper_arch_opposing_treat,
            de3_lower_arch_opposing_treat: this.state.de3_lower_arch_opposing_treat,
            de3_setup: this.state.de3_setup,
            de3_setup_instruction: this.state.de3_setup_instruction,
            de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
            de3_bracket_teeth: this.state.de3_bracket_teeth,
            de3_missing_teeth: this.state.de3_missing_teeth,
            de3_extract_teeth: this.state.de3_extract_teeth,
            de3_teeth_restriction: this.state.de3_teeth_restriction,
            de3_prev_case_info: this.state.de3_prev_case_info,
            de3_next_appointment_date_warning: this.state.de3_next_appointment_date_warning,
            de3_intra_date_warning: this.state.de3_intra_date_warning,
            de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
            de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
            de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
            de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
            de3_midlines: this.state.de3_midlines,
            de3_auxiliaries: this.state.de3_auxiliaries,
            de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
          });
        }
        break;

      case 'Submit':
        component = this.getSubmitComponent();
        break;
      default:
    }

    return component;
  }

  getDEForm = (values) => {
    const input = values;
    return (
      <DeForm2
        onInputChange_de2={this.onInputChange_de2}
        clearSpacingValue={this.clearSpacingValue}
        onChangeSpacingOnBlur={this.onChangeSpacingOnBlur}
        onFocus={this.onFocus_clearWarning}
        de2_reason={input.de2_reason}
        de2_initial_date={input.de2_initial_date}
        de2_intra_date={input.de2_intra_date}
        de2_upper_smartwire={input.de2_upper_smartwire}
        de2_upper_smartwire_date={input.de2_upper_smartwire_date}
        de2_lower_smartwire={input.de2_lower_smartwire}
        de2_lower_smartwire_date={input.de2_lower_smartwire_date}
        de2_arch_to_treat={input.de2_arch_to_treat}
        de2_arch_opposing_treat={input.de2_arch_opposing_treat}
        de2_setup={input.de2_setup}
        de2_setup_instruction={input.de2_setup_instruction}
        de2_setup_additional_instruction={input.de2_setup_additional_instruction}
        de2_bracket_instruction={input.de2_bracket_instruction}
        de2_teeth_restriction={input.de2_teeth_restriction}
        de2_bracket_remove={input.de2_bracket_remove}
        de2_bracket_add={input.de2_bracket_add}
        de2_ipr={input.de2_ipr}
        de2_ipr_instruction={input.de2_ipr_instruction}
        de2_spacing={input.de2_spacing}
        de2_spacing_upper={input.de2_spacing_upper}
        de2_spacing_lower={input.de2_spacing_lower}
        de2_spacing_instruction={input.de2_spacing_instruction}
        de2_special_instruction={input.de2_special_instruction}
        de2_prev_case_info={input.de2_prev_case_info}
        reupload={true}
        de2_initial_date_warning={input.de2_initial_date_warning}
        de2_intra_date_warning={input.de2_intra_date_warning}
        photoUpload={this.state.photoUpload}
        xrayUpload={this.state.xrayUpload}
        scanUpload={this.state.scanUpload}
        gen_2={this.state.gen_2}
        // iTero integration - props for DE2.0
        selectedIteroScanRecords={this.state.selectedIteroScanRecords}
        uploadMethod={this.state.uploadMethod}
      />
    );
  };

  /**
   * Retrieves form component for DE3.0
   * @function
   * @returns {object} - Form component
   */
  getDE3Form = (values) => {
    const input = _.cloneDeep(values);
    return (
      <DeForm3
        onInputChange_de3={this.onInputChange_de3}
        onFocus={this.onFocus_clearWarning}
        getDeHold={this.getDeHold}
        photoUpload={this.state.photoUpload}
        xrayUpload={this.state.xrayUpload}
        scanUpload={this.state.scanUpload}
        gen_2={this.state.gen_2}
        updateRecordState={this.updateRecordState}
        reupload={true}
        hasUpper={this.de3HasUpper}
        hasLower={this.de3HasLower}
        teethReference={Teeth}
        getImpliedTeethSets={this.getDE3ImpliedTeethSets}
        de3_reason={input.de3_reason}
        de3_next_appointment_date={input.de3_next_appointment_date}
        de3_intra_date={input.de3_intra_date}
        de3_upper_smartwire={input.de3_upper_smartwire}
        de3_upper_smartwire_date={input.de3_upper_smartwire_date}
        de3_lower_smartwire={input.de3_lower_smartwire}
        de3_lower_smartwire_date={input.de3_lower_smartwire_date}
        de3_arch_to_treat={input.de3_arch_to_treat}
        de3_arch_opposing_treat={input.de3_arch_opposing_treat}
        de3_upper_arch_opposing_treat={input.de3_upper_arch_opposing_treat}
        de3_lower_arch_opposing_treat={input.de3_lower_arch_opposing_treat}
        de3_setup={input.de3_setup}
        de3_setup_instruction={input.de3_setup_instruction}
        de3_setup_additional_instruction={input.de3_setup_additional_instruction}
        de3_bracket_teeth={input.de3_bracket_teeth}
        de3_missing_teeth={input.de3_missing_teeth}
        de3_extract_teeth={input.de3_extract_teeth}
        de3_teeth_restriction={input.de3_teeth_restriction}
        de3_prev_case_info={input.de2_prev_case_info}
        de3_anterior_posterior_r={input.de3_anterior_posterior_r}
        de3_anterior_posterior_l={input.de3_anterior_posterior_l}
        de3_anterior_posterior_corrections={input.de3_anterior_posterior_corrections}
        de3_anterior_posterior_notes={input.de3_anterior_posterior_notes}
        de3_midlines={input.de3_midlines}
        de3_auxiliaries={input.de3_auxiliaries}
        de3_auxiliaries_notes={input.de3_auxiliaries_notes}
        de3_next_appointment_date_warning={input.de3_next_appointment_date_warning}
        de3_intra_date_warning={input.de3_intra_date_warning}
        // iTero integration - props for DE3.0
        selectedIteroScanRecords={this.state.selectedIteroScanRecords}
        uploadMethod={this.state.uploadMethod}
        minPreset={this.minPreset}
        maxPreset={this.maxPreset}
        presets={this.presets}
        setBracketTeeth={this.setBracketTeeth}
      />
    );
  };

  /**
   * Sets the state as the new state
   * @function
   * @param {object} ns - The new state
   */
  setPlanState(ns) {
    // TxPlan 2.0 State
    if (this.state.default_plan_version === 'TX2.0') {
      const chief_complaint = ns['chief_complaint'] !== undefined ? textFieldLimited(ns['chief_complaint']) : this.state.chief_complaint;
      const missingTeeth = ns['missingTeeth'] !== undefined ? ns['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = ns['extractTeeth'] !== undefined ? ns['extractTeeth'] : this.state.extractTeeth;
      const archToTreat = ns['archToTreat'] !== undefined ? ns['archToTreat'] : this.state.archToTreat;
      const opposingArch = ns['opposingArch'] !== undefined ? ns['opposingArch'] : this.state.opposingArch;
      const bracketTeeth = ns['bracketTeeth'] !== undefined ? ns['bracketTeeth'] : this.state.bracketTeeth;
      const restrictTeeth = ns['restrictTeeth'] !== undefined ? ns['restrictTeeth'] : this.state.restrictTeeth;
      const notes = ns['notes'] !== undefined ? textFieldLimited(ns['notes']) : this.state.notes;
      const notes_spacing = ns['notes_spacing'] !== undefined ? textFieldLimited(ns['notes_spacing']) : this.state.notes_spacing;

      this.setState({
        chief_complaint: chief_complaint,
        archToTreat: archToTreat,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
        opposingArch: opposingArch,
        bracketTeeth: bracketTeeth,
        restrictTeeth: restrictTeeth,
        notes: notes,
        notes_spacing: notes_spacing,
        component: (
          <TxPlan
            setPlanState={this.setPlanState}
            setConditionState={this.setConditionState}
            setPlanConditionState={this.setPlanConditionState}
            getImpliedTeethSets={this.getImpliedTeethSets}
            handleTeethClick={this.handleTeethClick}
            chief_complaint={chief_complaint}
            missingTeeth={missingTeeth}
            extractTeeth={extractTeeth}
            archToTreat={archToTreat}
            opposingArch={opposingArch}
            bracketTeeth={bracketTeeth}
            restrictTeeth={restrictTeeth}
            conditions={this.state.conditions}
            notes={notes}
            notes_spacing={notes_spacing}
            warning={this.state.warning}
            onFocus={this.onFocus_clearWarning}
            metAllPlanRequiredField={this.metAllPlanRequiredField}
            photoUpload={this.state.photoUpload}
            xrayUpload={this.state.xrayUpload}
            scanUpload={this.state.scanUpload}
            // iTero integration - props for TxPlan
            selectedIteroScanRecords={this.state.selectedIteroScanRecords}
            uploadMethod={this.state.uploadMethod}
          />
        ),
      });

      this.metAllPlanRequiredField({ archToTreat: archToTreat, notes_spacing: notes_spacing, chief_complaint: chief_complaint, opposingArch: opposingArch });
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TODO: TX3.0 - set state for TX3.0
      const missingTeeth = ns['missingTeeth'] !== undefined ? ns['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = ns['extractTeeth'] !== undefined ? ns['extractTeeth'] : this.state.extractTeeth;
      const archToTreat = ns['archToTreat'] !== undefined ? ns['archToTreat'] : this.state.archToTreat;
      const opposingUpperArch = ns['opposingUpperArch'] !== undefined ? ns['opposingUpperArch'] : this.state.opposingUpperArch;
      const opposingLowerArch = ns['opposingLowerArch'] !== undefined ? ns['opposingLowerArch'] : this.state.opposingLowerArch;
      const bracketTeeth = ns['bracketTeeth'] !== undefined ? ns['bracketTeeth'] : this.state.bracketTeeth;
      const restrictTeeth = ns['restrictTeeth'] !== undefined ? ns['restrictTeeth'] : this.state.restrictTeeth;
      const anteriorPosteriorR = ns['anteriorPosteriorR'] !== undefined ? ns['anteriorPosteriorR'] : this.state.anteriorPosteriorR;
      const anteriorPosteriorL = ns['anteriorPosteriorL'] !== undefined ? ns['anteriorPosteriorL'] : this.state.anteriorPosteriorL;
      const anteriorPosteriorNotes =
        ns['anteriorPosteriorNotes'] !== undefined ? removeEmoji(textFieldLimited(ns['anteriorPosteriorNotes'])) : this.state.anteriorPosteriorNotes;
      const midlines = ns['midlines'] !== undefined ? ns['midlines'] : this.state.midlines;
      const auxiliariesWillBeUsed = ns['auxiliariesWillBeUsed'] !== undefined ? ns['auxiliariesWillBeUsed'] : this.state.auxiliariesWillBeUsed;
      const auxiliariesNotes = ns['auxiliariesNotes'] !== undefined ? removeEmoji(textFieldLimited(ns['auxiliariesNotes'])) : this.state.auxiliariesNotes;
      const notes = ns['notes'] !== undefined ? removeEmoji(textFieldLimited(ns['notes'])) : this.state.notes;

      let anteriorPosteriorCorrections = _.cloneDeep(this.state.anteriorPosteriorCorrections);

      if (ns['anteriorPosteriorCorrections'] !== undefined) {
        anteriorPosteriorCorrections = {
          ...anteriorPosteriorCorrections,
          ...ns['anteriorPosteriorCorrections'],
        };
      }

      let opposingArch;

      if (archToTreat === 'upper') {
        opposingArch = opposingUpperArch;
      } else if (archToTreat === 'lower') {
        opposingArch = opposingLowerArch;
      } else {
        opposingArch = this.state.opposingArch;
      }

      const newState = {
        archToTreat: archToTreat,
        bracketTeeth: bracketTeeth,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
        opposingArch: opposingArch,
        opposingUpperArch: opposingUpperArch,
        opposingLowerArch: opposingLowerArch,
        restrictTeeth: restrictTeeth,
        anteriorPosteriorR: anteriorPosteriorR,
        anteriorPosteriorL: anteriorPosteriorL,
        anteriorPosteriorCorrections: anteriorPosteriorCorrections,
        anteriorPosteriorNotes: anteriorPosteriorNotes,
        midlines: midlines,
        auxiliariesWillBeUsed: auxiliariesWillBeUsed,
        auxiliariesNotes: auxiliariesNotes,
        notes: notes,
      };
      this.setState({
        ...newState,
        component: this.getPlanByVersion('TX3.0', newState),
      });

      this.metAllPlanRequiredField({
        archToTreat: archToTreat,
        opposingArch: opposingArch,
        anteriorPosteriorR: anteriorPosteriorR,
        anteriorPosteriorL: anteriorPosteriorL,
        anteriorPosteriorCorrections: anteriorPosteriorCorrections,
        anteriorPosteriorNotes: anteriorPosteriorNotes,
        midlines: midlines,
        auxiliariesWillBeUsed: auxiliariesWillBeUsed,
        auxiliariesNotes: auxiliariesNotes,
        bracketTeeth: bracketTeeth,
        missingTeeth: missingTeeth,
        extractTeeth: extractTeeth,
      });
    }
    this.onFocus_clearWarning();
  }

  getConditionState = (cnd) => {
    return { ...this.state.conditions[cnd] };
  };

  setPlanConditionState = (cnd, newState) => {
    let conds = { ...this.state.conditions };
    let oldState = this.getConditionState(cnd);

    Object.assign(oldState, newState);

    conds[cnd] = oldState;

    // TxPlan 2.0 Condition State
    this.setConditionState(conds);
    this.metAllPlanRequiredField({ conditions: conds });
  };

  setConditionState = (conds) => {
    this.setState({
      conditions: conds,
      component: (
        <TxPlan
          setPlanState={this.setPlanState}
          setConditionState={this.setConditionState}
          setPlanConditionState={this.setPlanConditionState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          chief_complaint={this.state.chief_complaint}
          missingTeeth={this.state.missingTeeth}
          extractTeeth={this.state.extractTeeth}
          archToTreat={this.state.archToTreat}
          opposingArch={this.state.opposingArch}
          bracketTeeth={this.state.bracketTeeth}
          restrictTeeth={this.state.restrictTeeth}
          conditions={conds}
          notes={this.state.notes}
          notes_spacing={this.state.notes_spacing}
          warning={this.state.warning}
          onFocus={this.onFocus_clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          photoUpload={this.state.photoUpload}
          xrayUpload={this.state.xrayUpload}
          scanUpload={this.state.scanUpload}
          // iTero integration - props for TxPlan
          selectedIteroScanRecords={this.state.selectedIteroScanRecords}
          uploadMethod={this.state.uploadMethod}
        />
      ),
    });

    this.onFocus_clearWarning();
  };

  getImpliedTeethSets = () => {
    let opposingTeeth = [];
    let hasUpper = this.state.archToTreat !== 'lower';
    let hasLower = this.state.archToTreat !== 'upper';
    let setupIdeal = this.state.archToTreat !== 'both' && this.state.opposingArch === 'ideal';
    let setupCurrent = this.state.archToTreat !== 'both' && this.state.opposingArch === 'current';
    let setupDigital = this.state.archToTreat !== 'both' && this.state.opposingArch === 'digital';

    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    let missingTeeth = this.state.missingTeeth;
    let extractTeeth = _.difference(this.state.extractTeeth, missingTeeth);
    let extractDisabled = missingTeeth;
    let extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal || setupDigital) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    let bracketTeeth = _.difference(this.state.bracketTeeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.restrictTeeth, restrictImplied);
    if (setupCurrent || setupDigital) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    let tset = {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };

    return tset;
  };

  /**
   * Determines if DE3.0 has lower arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasLower = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'lower';
  };

  /**
   * Determines if DE3.0 has upper arch
   * @function
   * @returns {boolean} - True or false
   */
  de3HasUpper = () => {
    return this.state.de3_arch_to_treat === 'both' || this.state.de3_arch_to_treat === 'upper';
  };

  /**
   * Retrieves teeth sets for DE3.0
   * @function
   * @returns {object} - Teeth sets
   */
  getDE3ImpliedTeethSets = () => {
    const opposingTeeth = [];
    const hasUpper = this.de3HasUpper();
    const hasLower = this.de3HasLower();
    const setupIdeal = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'ideal';
    const setupCurrent = this.state.de3_arch_to_treat !== 'both' && this.state.de3_arch_opposing_treat === 'current';

    if (!hasUpper) {
      opposingTeeth.push(...Teeth.UR);
      opposingTeeth.push(...Teeth.UL);
    }

    if (!hasLower) {
      opposingTeeth.push(...Teeth.LR);
      opposingTeeth.push(...Teeth.LL);
    }

    const missingTeeth = this.state.de3_missing_teeth;
    const extractTeeth = _.difference(this.state.de3_extract_teeth, missingTeeth);
    const extractDisabled = missingTeeth;
    const extractTeethAll = _.union(extractTeeth, missingTeeth);

    let bracketDisabled = extractTeethAll;
    if (setupCurrent || setupIdeal) bracketDisabled = _.union(bracketDisabled, opposingTeeth);
    const bracketTeeth = _.difference(this.state.de3_bracket_teeth, bracketDisabled);

    let restrictImplied = _.difference(Teeth.All, bracketTeeth);
    if (setupIdeal) restrictImplied = _.difference(restrictImplied, opposingTeeth);
    restrictImplied = _.union(restrictImplied, extractTeethAll);
    let restrictTeeth = _.union(this.state.de3_teeth_restriction, restrictImplied);
    if (setupCurrent) restrictTeeth = _.union(restrictTeeth, opposingTeeth);

    return {
      hasUpper,
      hasLower,
      setupIdeal,
      setupCurrent,
      missingTeeth,
      extractTeeth,
      extractDisabled,
      extractTeethAll,
      opposingTeeth,
      bracketTeeth,
      bracketDisabled,
      restrictTeeth,
      restrictImplied,
    };
  };

  handleTeethClick = (teethSet) => {
    let func = (tooth, isSelected) => {
      let sel = new Set(this.state[teethSet]);
      if (isSelected) {
        sel.add(tooth);
      } else {
        sel.delete(tooth);
      }
      let ns = {};
      ns[teethSet] = Array.from(sel);

      this.setPlanState(ns);

      if (teethSet === 'bracketTeeth') {
        this.metAllPlanRequiredField({ bracketTeeth: ns[teethSet] });
      }

      this.onFocus_clearWarning();
    };
    return func;
  };

  metAllPlanRequiredField = (values = {}) => {
    let hasMet = false;

    if (this.state.default_plan_version === 'TX2.0') {
      const chief_complaint = values['chief_complaint'] !== undefined ? values['chief_complaint'] : this.state.chief_complaint;
      const notes_spacing = values['notes_spacing'] !== undefined ? values['notes_spacing'] : this.state.notes_spacing;
      const arch_to_treat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
      const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
      const conditions = values['conditions'] ? values['conditions'] : this.state.conditions;
      const opposingArch = values['opposingArch'] ? values['opposingArch'] : this.state.opposingArch;

      hasMet =
        chief_complaint &&
        arch_to_treat &&
        bracketTeeth &&
        bracketTeeth.length > 0 &&
        (conditions.ap_relationship.active ||
          conditions.midlines.active ||
          conditions.crowding.active ||
          conditions.spacing.active ||
          conditions.overjet.active ||
          conditions.deepbite.active ||
          conditions.openbite.active ||
          conditions.crossbite.active) &&
        this.hasClinicalConditionRequireFieldMet(conditions, notes_spacing, arch_to_treat, opposingArch) &&
        (!conditions.spacing.active ||
          (conditions.spacing.active &&
            (notes_spacing !== '' || conditions.spacing.spacingUpper.join().length > 0 || conditions.spacing.spacingLower.join().length > 0)) ||
          (conditions.spacing.active && conditions.spacing.spaces === 'close'));
    } else if (this.state.default_plan_version === 'TX3.0') {
      // TODO: TX3.0 Met Required Fields
      const archToTreat = values['archToTreat'] !== undefined ? values['archToTreat'] : this.state.archToTreat;
      const bracketTeeth = values['bracketTeeth'] !== undefined ? values['bracketTeeth'] : this.state.bracketTeeth;
      const missingTeeth = values['missingTeeth'] !== undefined ? values['missingTeeth'] : this.state.missingTeeth;
      const extractTeeth = values['extractTeeth'] !== undefined ? values['extractTeeth'] : this.state.extractTeeth;
      const anteriorPosteriorR = values['anteriorPosteriorR'] !== undefined ? values['anteriorPosteriorR'] : this.state.anteriorPosteriorR;
      const anteriorPosteriorL = values['anteriorPosteriorL'] !== undefined ? values['anteriorPosteriorL'] : this.state.anteriorPosteriorL;
      const anteriorPosteriorCorrections =
        values['anteriorPosteriorCorrections'] !== undefined ? values['anteriorPosteriorCorrections'] : this.state.anteriorPosteriorCorrections;
      const anteriorPosteriorNotes = values['anteriorPosteriorNotes'] !== undefined ? values['anteriorPosteriorNotes'] : this.state.anteriorPosteriorNotes;
      const midlines = values['midlines'] !== undefined ? values['midlines'] : this.state.midlines;
      const auxiliariesWillBeUsed = values['auxiliariesWillBeUsed'] !== undefined ? values['auxiliariesWillBeUsed'] : this.state.auxiliariesWillBeUsed;
      const auxiliariesNotes = values['auxiliariesNotes'] !== undefined ? values['auxiliariesNotes'] : this.state.auxiliariesNotes;

      hasMet =
        archToTreat &&
        midlines &&
        this.isBracketTeethValid({ bracketTeeth, missingTeeth, extractTeeth, archToTreat }) &&
        this.isAnteriorPosteriorFieldValid({ anteriorPosteriorR, anteriorPosteriorL, anteriorPosteriorCorrections }) &&
        this.isAnteriorPosteriorNotesFieldValid({ anteriorPosteriorR, anteriorPosteriorL, anteriorPosteriorNotes }) &&
        this.isAuxiliariesFieldValid({ auxiliariesWillBeUsed, auxiliariesNotes });
    }
    const active = this.getActivePositionByStepperName('Dx/Tx Plan');
    let stepper = this.state.stepper;

    stepper[active] = hasMet ? 1 : 2;

    this.setState({ stepper: stepper });

    return hasMet;
  };

  getPlanByVersion(version = '', values = null) {
    const revision = version || this.state.default_plan_version;
    const state = values || this.state;
    if (revision === 'TX2.0') {
      return (
        <TxPlan
          setPlanState={this.setPlanState}
          setConditionState={this.setConditionState}
          setPlanConditionState={this.setPlanConditionState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          chief_complaint={this.state.chief_complaint}
          missingTeeth={this.state.missingTeeth}
          extractTeeth={this.state.extractTeeth}
          archToTreat={this.state.archToTreat}
          opposingArch={this.state.opposingArch}
          bracketTeeth={this.state.bracketTeeth}
          restrictTeeth={this.state.restrictTeeth}
          conditions={this.state.conditions}
          notes={this.state.notes}
          notes_spacing={this.state.notes_spacing}
          warning={this.state.warning}
          onFocus={this.onFocus_clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          photoUpload={this.state.photoUpload}
          xrayUpload={this.state.xrayUpload}
          scanUpload={this.state.scanUpload}
          // iTero integration - props for TxPlan
          selectedIteroScanRecords={this.state.selectedIteroScanRecords}
          uploadMethod={this.state.uploadMethod}
        />
      );
    } else if (revision === 'TX3.0') {
      // TX3.0 render tx plan 3
      return (
        <TxPlan3
          setPlanState={this.setPlanState}
          getImpliedTeethSets={this.getImpliedTeethSets}
          handleTeethClick={this.handleTeethClick}
          warning={this.state.warning}
          onFocus={this.onFocus_clearWarning}
          metAllPlanRequiredField={this.metAllPlanRequiredField}
          disabledEdit={false}
          photoUpload={this.state.photoUpload}
          xrayUpload={this.state.xrayUpload}
          scanUpload={this.state.scanUpload}
          updateRecordState={this.updateRecordState}
          missingTeeth={state.missingTeeth}
          extractTeeth={state.extractTeeth}
          archToTreat={state.archToTreat}
          opposingArch={state.opposingArch}
          opposingLowerArch={state.opposingLowerArch}
          opposingUpperArch={state.opposingUpperArch}
          bracketTeeth={state.bracketTeeth}
          restrictTeeth={state.restrictTeeth}
          notes={state.notes}
          anteriorPosteriorR={state.anteriorPosteriorR}
          anteriorPosteriorL={state.anteriorPosteriorL}
          anteriorPosteriorCorrections={state.anteriorPosteriorCorrections}
          anteriorPosteriorNotes={state.anteriorPosteriorNotes}
          midlines={state.midlines}
          auxiliariesWillBeUsed={state.auxiliariesWillBeUsed}
          auxiliariesNotes={state.auxiliariesNotes}
          // iTero integration - props for TxPlan3
          selectedIteroScanRecords={this.state.selectedIteroScanRecords}
          uploadMethod={this.state.uploadMethod}
        />
      );
    }
  }

  redirectUserToCaseDetail() {
    const case_id = this.state.id;
    let path = this.props.history.location.pathname;
    // eslint-disable-next-line
    let new_path = path.replace(/upload\/[A-Za-z0-9\-]+/, `case/${case_id}`);

    this.props.history.push(new_path);
  }

  isUploadInProgress() {
    return (
      (this.state.photos_uploading && this.state.photos_uploading.length > 0) ||
      this.isLoadingIteroPhotos() ||
      (this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0) ||
      (this.state.scans_uploading && this.state.scans_uploading.length > 0)
    );
  }

  onEmptyFileError = () => {
    this.setState({
      showEmptyFileModal: true,
    });
  };

  onEmptyFileErrorDismiss = () => {
    this.setState({
      showEmptyFileModal: false,
    });
  };

  onButtonClick = (event) => {
    event.preventDefault();
    this.onFocus_clearWarning();

    let that = this;
    let newState = {
      name: '',
      stepper: this.state.stepper,
      active: 0,
      component: '',
      buttons: '',
    };

    let nextStep = this.getNextStepperNameByStepperName(event.currentTarget.dataset.description);
    let prevStep = this.getBackStepperNameByStepperName(event.currentTarget.dataset.description);
    let current_position = this.getActivePositionByStepperName(event.currentTarget.dataset.description);

    switch (event.currentTarget.dataset.description) {
      case 'Impressions':
        if (event.currentTarget.dataset.position === 'next') {
          if (!this.hasTwoScans() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        if (this.hasNewScans()) {
          newState.stepper[4] = 2;
          newState.stepper[current_position] = 1;
        }

        break;

      case 'Photos':
        if (event.currentTarget.dataset.position === 'next') {
          if (!this.hasOnePhoto() && this.state.allow_error_msg) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        if (this.hasNewPhotos()) {
          newState.stepper[4] = 2;
          newState.stepper[current_position] = 1;
        }
        break;

      case 'X-Rays':
        if (event.currentTarget.dataset.position === 'next') {
          if (!this.hasOneXray() && this.state.allow_error_msg && !this.state.isDigitalEnhancement) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={nextStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        if (this.hasNewXrays()) {
          newState.stepper[4] = 2;
          newState.stepper[current_position] = 1;
        }

        break;

      case 'Refinement Request Form':
      case 'Dx/Tx Plan':
        if (event.currentTarget.dataset.position === 'next') {
          if (this.state.default_plan_version === 'DE2.0' && this.state.isDigitalEnhancement && !this.isDE2FormComplete()) {
            let warning = document.querySelector('#warning-submit');
            let error = '';

            warning.classList.add('warning-display');

            if (this.state.de2_reason === '') {
              error = error + '<li><a href="#de2_reason">Reason</a></li>';
            }

            if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
              error = error + '<li><a href="#de2_reason">Bond Date</a></li>';
            }

            if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
              error = error + '<li><a href="#de2_reason">Scan Date</a></li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
            });

            return;
          } else if (this.state.default_plan_version === 'DE3.0' && this.state.isDigitalEnhancement && !this.isDE3FormComplete()) {
            // DE3.0 form validation stepper next button
            let warning = document.querySelector('#warning-submit');
            let error = '';

            warning.classList.add('warning-display');

            if (this.state.de3_reason === '') {
              error = error + '<li><a href="#de3_reason">Reason</a></li>';
            }

            if (this.state.de3_next_appointment_date_warning) {
              error = error + '<li><a href="#de3_next_appointment_date">Appointment Date</a></li>';
            }

            if (this.state.de3_intra_date === '' || this.state.de3_intra_date_warning) {
              error = error + '<li><a href="#de3_intra_date">Scan Date</a></li>';
            }

            if (!this.isBracketTeethValid()) {
              error = error + '<li><a href="#de3_bracket_teeth">Bracket Teeth</a></li>';
            }

            if (!this.isAuxiliariesFieldValid()) {
              error = error + '<li><a href="#de3_auxiliaries">Auxiliaries</a></li>';
            }

            if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
              error = error + '<li><a href="#de3_anterior_posterior">Anterior-Posterior</a></li>';
            }

            if (!this.isSetupInstructionValid()) {
              error = error + '<li><a href="#de3_setup_instruction">Additional Instructions</a></li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
            });

            return;
          } else if (
            this.state.isDigitalEnhancement &&
            !this.isEnhancementChanged() &&
            !this.hasPhotosChange() &&
            !this.hasXraysChange() &&
            !this.hasScansChange()
          ) {
            let warning = document.querySelector('#warning-submit');
            warning.classList.add('warning-display');
            warning.innerHTML = '<ul><li>No changes have been made. Please update the Enhancement Form or upload new records to submit.</li></ul>';

            this.setState({
              warning: true,
            });

            return;
          } else if (
            this.state.stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] === 1 &&
            ['TX2.0', 'TX3.0'].includes(this.state.default_plan_version) &&
            !this.hasPhotosChange() &&
            !this.hasXraysChange() &&
            !this.hasScansChange() &&
            this.isTxPlanUnChange()
          ) {
            let warning = document.querySelector('#warning-submit');
            let plan = 'treatment';
            warning.classList.add('warning-display');
            warning.innerHTML = `<ul><li>No changes have been made. Please update the ${plan} or upload new records to submit.</li></ul>`;

            this.setState({
              warning: true,
            });

            return;
          } else if (!this.state.isDigitalEnhancement && !this.hasTxPlanField() && ['TX2.0', 'TX3.0'].includes(this.state.default_plan_version)) {
            return;
          }

          newState = {
            name: nextStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(nextStep),
            component: this.getComponentByStepperName(nextStep),
            buttons: (
              <Button theme="secondary" data-position={'prev'} data-description={nextStep} onClick={this.onButtonClick}>
                Back
              </Button>
            ),
          };
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      case 'Submit':
        if (event.currentTarget.dataset.position === 'submit') {
          if (
            this.state.isDigitalEnhancement &&
            (!this.isSubmitAvailable() || (!this.isEnhancementChanged() && !this.hasXraysChange() && !this.hasPhotosChange() && !this.hasScansChange()))
          ) {
            let warning = document.querySelector('#warning-submit');
            let error = '';
            let heading = '';

            warning.classList.add('warning-display');

            if (!this.isPhotoUploadLengthValid()) {
              error = error + `<li>${this.getPhotoSectionErrorMessage()}</li>`;
              heading = 'Incomplete Fields';
            }

            if (!this.isScansLengthValid()) {
              if (this.getScansLength() > 2) {
                error = error + '<li>More than 2 STL Files</li>';
              } else {
                error = error + '<li>Toothprints</li>';
              }
              heading = 'Incomplete Fields';
            }

            if (this.state.default_plan_version === 'DE2.0') {
              if (this.state.de2_initial_date === '' || this.state.de2_initial_date_warning) {
                error = error + '<li><a href="#de2_reason">Bond Date</a></li>';
                heading = 'Incomplete Fields';
              }

              if (this.state.de2_intra_date === '' || this.state.de2_intra_date_warning) {
                error = error + '<li><a href="#de2_reason">Scan Date</a></li>';
                heading = 'Incomplete Fields';
              }
            }

            if (this.state.default_plan_version === 'DE3.0') {
              if (this.state.de3_reason === '') {
                error = error + '<li>Reason</li>';
              }

              if (this.state.de3_next_appointment_date_warning) {
                error = error + '<li>Appointment Date</li>';
              }

              if (this.state.de3_intra_date === '' || this.state.de3_intra_date_warning) {
                error = error + '<li>Scan Date</li>';
              }

              if (!this.isAuxiliariesFieldValid()) {
                error = error + '<li>Auxiliaries</li>';
              }

              if (!this.isAnteriorPosteriorFieldValid() || !this.isAnteriorPosteriorNotesFieldValid()) {
                error = error + '<li>Anterior-Posterior</li>';
              }
              heading = 'DE Form Incomplete Fields';
            }

            if (error === '') {
              error = '<li>No changes have been made. Please update the DE plan or upload new records to submit.</li>';
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
              warning_title: heading,
            });

            return;
          } else if (
            !this.state.isDigitalEnhancement &&
            (!this.isSubmitAvailable() ||
              (['TX2.0', 'TX3.0'].includes(this.state.default_plan_version) &&
                this.isTxPlanUnChange() &&
                !this.hasPhotosChange() &&
                !this.hasXraysChange() &&
                !this.hasScansChange()))
          ) {
            let warning = document.querySelector('#warning-submit');
            let error = '';
            let heading = '';

            warning.classList.add('warning-display');

            if (!this.isPhotoUploadLengthValid()) {
              error = error + `<li>${this.getPhotoSectionErrorMessage()}</li>`;
              heading = 'Incomplete Fields';
            }

            if (this.state.xrayUpload.length < 1) {
              error = error + '<li>Radiographs</li>';
              heading = 'Incomplete Fields';
            }

            if (!this.isScansLengthValid()) {
              error = error + '<li>Toothprints</li>';
              heading = 'Incomplete Fields';
            }

            if (
              (this.state.default_plan_version === 'TX2.0' &&
                (this.state.chief_complaint === '' ||
                  this.state.archToTreat === '' ||
                  (this.state.bracketTeeth && this.state.bracketTeeth.length === 0) ||
                  !this.hasOneClinicalCondition() ||
                  !this.hasClinicalConditionRequireFieldMet())) ||
              (this.state.default_plan_version === 'TX3.0' && !this.metAllPlanRequiredField())
            ) {
              error = error + '<li>Dx/Tx Plan</li>';
              heading = 'Incomplete Fields';
            }

            const plan = ['TX2.0', 'TX3.0'].includes(this.state.default_plan_version) ? 'treatment' : 'Dx/Tx';

            if (error === '') {
              error = `<li>No changes have been made. Please update the ${plan} plan or upload new records to submit.</li>`;
            }

            warning.innerHTML = `<ul>${error}</ul>`;

            this.setState({
              warning: true,
              warning_title: heading,
            });

            return;
          }

          if (this.isUploadInProgress()) {
            let warning = document.querySelector('#warning-submit');
            if (warning) {
              warning.innerHTML = '<ul><li>Upload in Progress</li></ul>';
            }

            this.setState({
              warning: true,
              warning_title: 'Submission Error',
            });

            return;
          }

          //apply API
          let formData = new FormData();
          let files = this.getAllFiles();
          formData.append('files', JSON.stringify(files));
          formData.append('uploadMethod', this.state.uploadMethod);
          formData.append('showAlreadyUploadedScans', this.state.showAlreadyUploadedScans);
          formData.append('shouldFetchIteroPhotos', this.state.shouldFetchIteroPhotos);
          formData.append('selected_itero_file_ids', JSON.stringify(this.state.selectedIteroScanIds));

          if (this.state.isDigitalEnhancement && this.state.default_plan_version === 'DE2.0') {
            const de2_states = [
              'de2_reason',
              'de2_initial_date',
              'de2_intra_date',
              'de2_upper_smartwire',
              'de2_upper_smartwire_date',
              'de2_lower_smartwire',
              'de2_lower_smartwire_date',
              'de2_arch_to_treat',
              'de2_arch_opposing_treat',
              'de2_setup',
              'de2_setup_instruction',
              'de2_setup_additional_instruction',
              'de2_bracket_instruction',
              'de2_teeth_restriction',
              'de2_bracket_remove',
              'de2_bracket_add',
              'de2_ipr',
              'de2_ipr_instruction',
              'de2_spacing',
              'de2_spacing_upper',
              'de2_spacing_lower',
              'de2_spacing_instruction',
              'de2_special_instruction',
              'de2_prev_case_info',
            ];

            de2_states.forEach((state) => {
              if (state === 'de2_prev_case_info') {
                formData.append(state, JSON.stringify(that.state[state]));
              } else {
                if (
                  (state === 'de2_upper_smartwire_date' && that.state['de2_upper_smartwire'] === 'none') ||
                  (state === 'de2_lower_smartwire_date' && that.state['de2_lower_smartwire'] === 'none')
                ) {
                  formData.append(state, '');
                } else {
                  formData.append(state, that.state[state]);
                }
              }
            });

            formData.append('revision', 'DE2.0');
          } else if (this.state.isDigitalEnhancement && this.state.default_plan_version === 'DE3.0') {
            // DE3.0 form data
            formData.append('revision', 'DE3.0');
            const de3_states = [
              'de3_reason',
              'de3_next_appointment_date',
              'de3_intra_date',
              'de3_upper_smartwire',
              'de3_upper_smartwire_date',
              'de3_lower_smartwire',
              'de3_lower_smartwire_date',
              'de3_arch_to_treat',
              'de3_arch_opposing_treat',
              'de3_setup',
              'de3_setup_instruction',
              'de3_setup_additional_instruction',
              'de3_bracket_teeth',
              'de3_missing_teeth',
              'de3_extract_teeth',
              'de3_teeth_restriction',
              'de3_prev_case_info',
              'de3_anterior_posterior_r',
              'de3_anterior_posterior_l',
              'de3_midlines',
              'de3_auxiliaries',
            ];

            de3_states.forEach((state) => {
              if (['de3_prev_case_info'].includes(state)) {
                formData.append(state, JSON.stringify(that.state[state]));
              } else {
                formData.append(state, that.state[state]);
              }
            });

            let de3_anterior_posterior_corrections = that.state.de3_anterior_posterior_corrections;
            if (that.state.de3_anterior_posterior_r === 'current' && that.state.de3_anterior_posterior_l === 'current') {
              de3_anterior_posterior_corrections = {
                class_II_or_III_correction_simulation: false,
                posterior_IPR: false,
              };
            }
            formData.append('de3_anterior_posterior_corrections', JSON.stringify(de3_anterior_posterior_corrections));

            let de3_anterior_posterior_notes = that.state.de3_anterior_posterior_notes;
            if (that.state.de3_anterior_posterior_r !== 'other' && that.state.de3_anterior_posterior_l !== 'other') {
              de3_anterior_posterior_notes = '';
            }
            formData.append('de3_anterior_posterior_notes', de3_anterior_posterior_notes);

            let de3_auxiliaries_notes = this.state.de3_auxiliaries_notes;
            if (that.state.de3_auxiliaries === 'No') {
              de3_auxiliaries_notes = '';
            }
            formData.append('de3_auxiliaries_notes', de3_auxiliaries_notes);
          } else if (this.state.default_plan_version === 'TX2.0') {
            formData.append('chief_complaint', this.state.chief_complaint);
            formData.append('missingTeeth', this.state.missingTeeth);
            formData.append('extractTeeth', this.state.extractTeeth);
            formData.append('bracketTeeth', this.state.bracketTeeth);
            formData.append('restrictTeeth', this.state.restrictTeeth);
            formData.append('archToTreat', this.state.archToTreat);
            formData.append('opposingArch', this.state.opposingArch);
            formData.append('notes', this.state.notes);
            formData.append('notes_spacing', this.state.notes_spacing);
            formData.append('conditions', JSON.stringify(this.state.conditions));
            formData.append('impliedTeethSets', JSON.stringify(this.getImpliedTeethSets()));
            formData.append('revision', 'TX2.0');
          } else if (this.state.default_plan_version === 'TX3.0') {
            // TODO: TX3.0 append plan data to form
            formData.append('revision', 'TX3.0');
            formData.append('archToTreat', this.state.archToTreat);
            formData.append('missingTeeth', this.state.missingTeeth);
            formData.append('extractTeeth', this.state.extractTeeth);
            formData.append('opposingArch', this.state.opposingArch);
            formData.append('bracketTeeth', this.state.bracketTeeth);
            formData.append('restrictTeeth', this.state.restrictTeeth);
            formData.append('notes', this.state.notes);
            formData.append('impliedTeethSets', JSON.stringify(this.getImpliedTeethSets()));

            formData.append('anteriorPosteriorR', this.state.anteriorPosteriorR);
            formData.append('anteriorPosteriorL', this.state.anteriorPosteriorL);
            let anteriorPosteriorCorrections = this.state.anteriorPosteriorCorrections;
            if (this.state.anteriorPosteriorR === 'current' && this.state.anteriorPosteriorL === 'current') {
              anteriorPosteriorCorrections = {
                classIIOrIIICorrectionSimulation: false,
                posteriorIPR: false,
              };
            }
            formData.append('anteriorPosteriorCorrections', JSON.stringify(anteriorPosteriorCorrections));
            let anteriorPosteriorNotes = this.state.anteriorPosteriorNotes;
            if (this.state.anteriorPosteriorR !== 'other' && this.state.anteriorPosteriorL !== 'other') {
              anteriorPosteriorNotes = '';
            }
            formData.append('anteriorPosteriorNotes', anteriorPosteriorNotes);

            formData.append('midlines', this.state.midlines);

            formData.append('auxiliariesWillBeUsed', this.state.auxiliariesWillBeUsed);
            let auxiliariesNotes = this.state.auxiliariesNotes;
            if (this.state.auxiliariesWillBeUsed === 'No') {
              auxiliariesNotes = '';
            }
            formData.append('auxiliariesNotes', auxiliariesNotes);
          }

          this.setState({
            component: <SubmitterLoader />,
            buttons: <div />,
            hide: true,
          });

          Axios.post(`/api/casefiles/?id=${this.state.id}`, formData)
            .then(function (result) {
              // eslint-disable-next-line
              let message = '';

              if (result.data.message === 'File Upload Failed') {
                message = 'Dx/Tx Plan and files has been submitted from a previous event.';
              }

              that.setState({
                component: <SubmissionConfirmation refreshInfo onButtonClick={that.redirectUserToCaseDetail} />,
              });

              //For updating navigation menu alerts
              that.props.history.push({ state: { refreshInfo: 'true' } });
            })
            .finally(() => {
              sendRefreshCaseListMessage();
              sendRefreshDashboardMessage();
            });
        } else if (event.currentTarget.dataset.position === 'prev') {
          newState = {
            name: prevStep,
            stepper: this.state.stepper,
            active: this.getActivePositionByStepperName(prevStep),
            component: this.getComponentByStepperName(prevStep),
            buttons: (
              <>
                <Button theme="secondary" data-position={'prev'} data-description={prevStep} onClick={this.onButtonClick}>
                  Back
                </Button>
                <Button data-position={'next'} data-description={prevStep} onClick={this.onButtonClick}>
                  Next
                </Button>
              </>
            ),
          };
        }

        break;

      default:
      //Do Nothing
    }

    if (newState.name) {
      //Only update if cases match
      this.setState((state, props) => {
        return newState;
      }, scrollToTop);
    }
  };

  updateStepperState = (newState, activeState) => {
    switch (activeState) {
      case 0:
        if (this.hasNewScans() && this.isScansLengthValid()) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 1:
        if (this.hasNewPhotos()) {
          newState.stepper[activeState] = 1;
        }
        break;

      case 2:
        if (this.hasNewXrays()) {
          newState.stepper[activeState] = 1;
        }
        break;

      default:
    }

    return newState;
  };

  onStepperClick = (event) => {
    //Check if any of these conditions are false before allow switching
    let activeState = this.state.active;

    let newState = {
      name: event.currentTarget.dataset.description,
      stepper: this.state.stepper,
      active: this.getActivePositionByStepperName(event.currentTarget.dataset.description),
      component: this.getComponentByStepperName(event.currentTarget.dataset.description),
      buttons: (
        <>
          <Button theme="secondary" data-position={'prev'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Back
          </Button>
          <Button data-position={'next'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Next
          </Button>
        </>
      ),
    };

    switch (event.currentTarget.dataset.description) {
      case 'Impressions':
        newState.buttons = (
          <Button data-position={'next'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Next
          </Button>
        );

        newState = this.updateStepperState(newState, activeState);

        break;
      case 'Photos':
        newState = this.updateStepperState(newState, activeState);

        break;
      case 'X-Rays':
        newState = this.updateStepperState(newState, activeState);

        break;

      case 'Dx/Tx Plan':
        break;

      case 'Refinement Request Form':
        if (this.state.stepper[0] === 0) {
          return;
        }

        break;

      case 'Submit':
        newState.buttons = (
          <Button theme="secondary" data-position={'prev'} data-description={event.currentTarget.dataset.description} onClick={this.onButtonClick}>
            Back
          </Button>
        );

        newState = this.updateStepperState(newState, activeState);

        break;

      default:
      //Do Nothing
    }

    if (newState.name) {
      this.onFocus_clearWarning();
      this.setState(newState);
      if (event.currentTarget.dataset.description === 'Impressions') {
        this.showMoreTwoScansWarning(this.state.scanUpload);
      }
    }
  };

  onRedirectManageCases = () => {
    let path = this.props.history.location.pathname;
    let redirectPath = getRootPath(path);
    let that = this;

    if (redirectPath) {
      this.props.history.push(redirectPath);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}`);
        }
      });
    }
  };

  onRedirectCaseDetails = () => {
    let path = this.props.history.location.pathname;
    let caseid = this.state.id;
    let redirectPath = getRootPath(path);
    let that = this;

    if (redirectPath) {
      this.props.history.push(`${redirectPath}/case/${caseid}`);
    } else {
      Axios.get('/apiv3/doctor').then(function (result) {
        if (result && result.data && result.data.doctor && result.data.doctor[0] && result.data.doctor[0].id) {
          that.props.history.push(`/portal/${result.data.doctor[0].id}/case/${caseid}`);
        }
      });
    }
  };

  /* Digital Enhancement */
  onInputChange_refinement = (event) => {
    let newScanDate = this.state.scanDate;
    let newReason = this.state.reason;
    let newUpperSmartWire = this.state.upperSmartWire;
    let newLowerSmartWire = this.state.lowerSmartWire;
    let newSetup = this.state.setup;
    let newRestrictions = this.state.restrictions;
    let newRestrictionsTeeth = this.state.restrictionsTeeth;
    let newIpr = this.state.ipr;
    let newIprDialog = this.state.iprDialog;
    let newResidual = this.state.residual;
    let newUpper = this.state.upper;
    let newLower = this.state.lower;
    let stepper = this.state.stepper;

    switch (event.target.dataset.field) {
      case 'scanDate':
        newScanDate = event.target.value;
        break;
      case 'reason':
        newReason = event.target.value;
        break;
      case 'upperSmartWire':
        newUpperSmartWire = event.target.value;
        break;
      case 'lowerSmartWire':
        newLowerSmartWire = event.target.value;
        break;
      case 'setup':
        newSetup = event.target.value;
        break;
      case 'restrictions':
        newRestrictions = event.target.value;
        if (newRestrictions === 'None') {
          newRestrictionsTeeth = [];
        }
        break;
      case 'restrictionsTeeth':
        let result = [];
        let hasValue = _.intersection(this.state.restrictionsTeeth, [event.target.innerHTML]);

        if (hasValue && hasValue.length > 0) {
          //if exist already than remove
          result = _.remove(this.state.restrictionsTeeth, (selection) => {
            return selection !== event.target.innerHTML;
          });
        } else {
          this.state.restrictionsTeeth.push(event.target.innerHTML);
          result = this.state.restrictionsTeeth;
        }

        newRestrictionsTeeth = result;
        break;
      case 'ipr':
        newIpr = event.target.value;
        if (newIpr !== 'Special IPR requirements') {
          newIprDialog = '';
        }
        break;
      case 'iprDialog':
        newIprDialog = textFieldCheck(event.target.value);
        break;
      case 'residual':
        newResidual = textFieldCheck(event.target.value);
        break;
      case 'upper':
        newUpper = textFieldCheck(event.target.value);
        break;
      case 'lower':
        newLower = textFieldCheck(event.target.value);
        break;
      default:
    }

    if (
      newScanDate &&
      newScanDate !== '' &&
      newReason &&
      newReason !== '' &&
      newSetup &&
      newSetup !== '' &&
      this.isPhotoUploadLengthValid() &&
      this.state.xrayUpload.length > 0 &&
      this.isScansLengthValid()
    ) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
    } else {
      stepper[4] = 0;
    }
    if (newScanDate && newScanDate !== '' && newReason && newReason !== '' && newSetup && newSetup !== '') {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
    } else {
      stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
    }

    this.setState({
      component: (
        <DeForm
          onInputChange_refinement={this.onInputChange_refinement}
          scanDate={newScanDate}
          reason={newReason}
          upperSmartWire={newUpperSmartWire}
          lowerSmartWire={newLowerSmartWire}
          setup={newSetup}
          restrictions={newRestrictions}
          restrictionsTeeth={newRestrictionsTeeth}
          ipr={newIpr}
          iprDialog={newIprDialog}
          residual={newResidual}
          upper={newUpper}
          lower={newLower}
          onFocus={this.onFocus_clearWarning}
        />
      ),
      scanDate: newScanDate,
      reason: newReason,
      upperSmartWire: newUpperSmartWire,
      lowerSmartWire: newLowerSmartWire,
      setup: newSetup,
      restrictions: newRestrictions,
      restrictionsTeeth: newRestrictionsTeeth,
      ipr: newIpr,
      iprDialog: newIprDialog,
      residual: newResidual,
      upper: newUpper,
      lower: newLower,
      stepper: stepper,
    });
  };

  /* Dxtx Plan */
  onInputChange_dxtxSection0_chiefComplaint = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (text !== '' && this.state.dxtxSection2_arch !== '' && this.state.dxtxSection2_teeth !== '') {
      if (
        this.state.dxtxSection2_teeth !== 'customxcustom' ||
        (this.state.dxtxSection2_teeth === 'customxcustom' && this.state.dxtxSection2_teethCustom !== '')
      ) {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
      }
    }

    if (this.isSubmitAvailable() || this.isStepperDone(stepper)) {
      stepper[4] = 2;
    }

    if (text === '') {
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 2;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={text}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection0_chiefComplaint: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_mx = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={text}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_mx: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_gingival = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={text}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_gingival: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_upperMidline2Facial = (event) => {
    let stepper = this.state.stepper;
    let dxtxSection1_upperMidline2FacialRight = this.state.dxtxSection1_upperMidline2FacialRight;
    let dxtxSection1_upperMidline2FacialLeft = this.state.dxtxSection1_upperMidline2FacialLeft;

    if (event.target.value === 'correct') {
      dxtxSection1_upperMidline2FacialRight = '';
      dxtxSection1_upperMidline2FacialLeft = '';
    } else if (event.target.value === 'midline-right') {
      dxtxSection1_upperMidline2FacialLeft = '';
    } else if (event.target.value === 'midline-left') {
      dxtxSection1_upperMidline2FacialRight = '';
    }

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={event.target.value}
          dxtxSection1_upperMidline2FacialRight={dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_upperMidline2Facial: event.target.value,
      dxtxSection1_upperMidline2FacialRight: dxtxSection1_upperMidline2FacialRight,
      dxtxSection1_upperMidline2FacialLeft: dxtxSection1_upperMidline2FacialLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_upperMidline2FacialRight = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={text}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_upperMidline2FacialRight: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_upperMidline2FacialLeft = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={text}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_upperMidline2FacialLeft: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_rightMolars = (event) => {
    let stepper = this.state.stepper;
    let result = '';

    if (this.state.dxtxSection1_rightMolars !== event.target.innerHTML) {
      result = event.target.innerHTML;
    }

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={result}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_rightMolars: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_rightCanine = (event) => {
    let stepper = this.state.stepper;
    let result = '';

    if (this.state.dxtxSection1_rightCanine !== event.target.innerHTML) {
      result = event.target.innerHTML;
    }

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={result}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_rightCanine: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_leftMolars = (event) => {
    let stepper = this.state.stepper;
    let result = '';

    if (this.state.dxtxSection1_leftMolars !== event.target.innerHTML) {
      result = event.target.innerHTML;
    }

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={result}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_leftMolars: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_leftCanine = (event) => {
    let stepper = this.state.stepper;
    let result = '';

    if (this.state.dxtxSection1_leftCanine !== event.target.innerHTML) {
      result = event.target.innerHTML;
    }

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={result}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_leftCanine: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_overjet = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_overjet;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={result}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_overjet: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_anterior = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_anterior;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={result}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_anterior: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_deep = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_deep;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={result}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_deep: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_narrow = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_narrow;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={result}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_narrow: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_posterior = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_posterior;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={result}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_posterior: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_class = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_class;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={result}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_class: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_crowding = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_crowding;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={result}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_crowding: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_spaces = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_spaces;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={result}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_spaces: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_flared = (event) => {
    let stepper = this.state.stepper;
    let result = !this.state.dxtxSection1_flared;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={result}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_flared: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection1_notes = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={text}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection1_notes: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_arch = (event) => {
    let result = {
      upper: this.state.dxtxSection2_toothUpper,
      lower: this.state.dxtxSection2_toothLower,
    };

    //////////////////////////////////////////////////////////////////////////////
    //  Remove smart logic on section II-1
    //////////////////////////////////////////////////////////////////////////////
    //
    // switch (event.target.value) {
    //   case 'Both':
    //     result.upper = true;
    //     result.lower = true;
    //     break;
    //   case 'Upper':
    //     result.upper = true;
    //     result.lower = false;
    //     break;
    //   case 'Lower':
    //     result.upper = false;
    //     result.lower = true;
    //     break;
    //   default:
    // }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
    }

    if (this.state.dxtxSection0_chiefComplaint !== '' && event.target.value !== '' && this.state.dxtxSection2_teeth !== '') {
      if (
        this.state.dxtxSection2_teeth !== 'customxcustom' ||
        (this.state.dxtxSection2_teeth === 'customxcustom' && this.state.dxtxSection2_teethCustom !== '')
      ) {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;

        if (this.isStepperDone(stepper)) {
          stepper[4] = 2;
        }
      }
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={event.target.value}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={result.upper}
          dxtxSection2_toothLower={result.lower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_arch: event.target.value,
      dxtxSection2_toothUpper: result.upper,
      dxtxSection2_toothLower: result.lower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_teeth = (event) => {
    let stepper = this.state.stepper;
    let dxtxSection2_teethCustom = this.state.dxtxSection2_teethCustom;

    if (event.target.value !== 'customxcustom') {
      dxtxSection2_teethCustom = '';
    }

    if (this.isSubmitAvailable() && dxtxSection2_teethCustom !== '') {
      stepper[4] = 2;
    } else {
      stepper[4] = 0;
    }

    if (this.state.dxtxSection0_chiefComplaint !== '' && this.state.dxtxSection2_arch !== '' && event.target.value !== '') {
      if (event.target.value !== 'customxcustom' || (event.target.value === 'customxcustom' && this.state.dxtxSection2_teethCustom !== '')) {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;

        if (this.isStepperDone(stepper)) {
          stepper[4] = 2;
        }
      } else {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 2;
      }
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={event.target.value}
          dxtxSection2_teethCustom={dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_teeth: event.target.value,
      dxtxSection2_teethCustom: dxtxSection2_teethCustom,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_teethCustom = (event) => {
    let stepper = this.state.stepper;
    let text = textFieldCheck(event.target.value);

    if (this.isSubmitAvailable() && text !== '') {
      stepper[4] = 2;
    } else {
      stepper[4] = 0;
    }

    if (this.state.dxtxSection0_chiefComplaint !== '' && this.state.dxtxSection2_arch !== '' && this.state.dxtxSection2_teeth !== '') {
      if (this.state.dxtxSection2_teeth !== 'customxcustom' || (this.state.dxtxSection2_teeth === 'customxcustom' && text !== '')) {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;

        if (this.isStepperDone(stepper)) {
          stepper[4] = 2;
        }
      } else {
        stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 2;
      }
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={text}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_teethCustom: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorMaintainLeft = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={!this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorMaintainLeft: !this.state.dxtxSection2_anteriorMaintainLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorMaintainRight = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={!this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorMaintainRight: !this.state.dxtxSection2_anteriorMaintainRight,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorImproveLeft = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={!this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorImproveLeft: !this.state.dxtxSection2_anteriorImproveLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorImproveRight = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={!this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorImproveRight: !this.state.dxtxSection2_anteriorImproveRight,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorCorrectLeft = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={!this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorCorrectLeft: !this.state.dxtxSection2_anteriorCorrectLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_anteriorCorrectRight = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={!this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_anteriorCorrectRight: !this.state.dxtxSection2_anteriorCorrectRight,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_toothMovement = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    let dxtxSection2_toothMovementSelection = this.state.dxtxSection2_toothMovementSelection;

    if (event.target.value === 'None (Move all the teeth)') {
      dxtxSection2_toothMovementSelection = [];
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={event.target.value}
          dxtxSection2_toothMovementSelection={dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_toothMovement: event.target.value,
      dxtxSection2_toothMovementSelection: dxtxSection2_toothMovementSelection,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_toothMovementSelection = (event) => {
    let result = [];
    let hasValue = _.intersection(this.state.dxtxSection2_toothMovementSelection, [event.target.innerHTML]);

    if (hasValue && hasValue.length > 0) {
      //if exist already than remove
      result = _.remove(this.state.dxtxSection2_toothMovementSelection, (selection) => {
        return selection !== event.target.innerHTML;
      });
    } else {
      this.state.dxtxSection2_toothMovementSelection.push(event.target.innerHTML);
      result = this.state.dxtxSection2_toothMovementSelection;
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={result}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_toothMovementSelection: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_toothExtractionSelection = (event) => {
    let result = [];
    let hasValue = _.intersection(this.state.dxtxSection2_toothExtractionSelection, [event.target.innerHTML]);

    if (hasValue && hasValue.length > 0) {
      //if exist already than remove
      result = _.remove(this.state.dxtxSection2_toothExtractionSelection, (selection) => {
        return selection !== event.target.innerHTML;
      });
    } else {
      this.state.dxtxSection2_toothExtractionSelection.push(event.target.innerHTML);
      result = this.state.dxtxSection2_toothExtractionSelection;
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={result}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_toothExtractionSelection: result,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overjet = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={event.target.value}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overjet: event.target.value,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbite = (event) => {
    let overbiteOpenExtrudeAnteriorUpper = this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper;
    let overbiteOpenExtrudeAnteriorLower = this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower;
    let overbiteOpenIntrudePosteriorUpper = this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper;
    let overbiteOpenIntrudePosteriorLower = this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower;
    let overbiteDeepIntrudeAnteriorUpper = this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper;
    let overbiteDeepIntrudeAnteriorLower = this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower;
    let overbiteDeepExtrudePosteriorUpper = this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper;
    let overbiteDeepExtrudePosteriorLower = this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower;

    if (event.target.value === 'Maintain initial overbite') {
      overbiteOpenExtrudeAnteriorUpper = false;
      overbiteOpenExtrudeAnteriorLower = false;
      overbiteOpenIntrudePosteriorUpper = false;
      overbiteOpenIntrudePosteriorLower = false;
      overbiteDeepIntrudeAnteriorUpper = false;
      overbiteDeepIntrudeAnteriorLower = false;
      overbiteDeepExtrudePosteriorUpper = false;
      overbiteDeepExtrudePosteriorLower = false;
    } else if (event.target.value === 'Correct open bite') {
      overbiteDeepIntrudeAnteriorUpper = false;
      overbiteDeepIntrudeAnteriorLower = false;
      overbiteDeepExtrudePosteriorUpper = false;
      overbiteDeepExtrudePosteriorLower = false;
    } else if (event.target.value === 'Correct deep bite') {
      overbiteOpenExtrudeAnteriorUpper = false;
      overbiteOpenExtrudeAnteriorLower = false;
      overbiteOpenIntrudePosteriorUpper = false;
      overbiteOpenIntrudePosteriorLower = false;
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={event.target.value}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbite: event.target.value,
      dxtxSection2_overbiteOpenExtrudeAnteriorUpper: overbiteOpenExtrudeAnteriorUpper,
      dxtxSection2_overbiteOpenExtrudeAnteriorLower: overbiteOpenExtrudeAnteriorLower,
      dxtxSection2_overbiteOpenIntrudePosteriorUpper: overbiteOpenIntrudePosteriorUpper,
      dxtxSection2_overbiteOpenIntrudePosteriorLower: overbiteOpenIntrudePosteriorLower,
      dxtxSection2_overbiteDeepIntrudeAnteriorUpper: overbiteDeepIntrudeAnteriorUpper,
      dxtxSection2_overbiteDeepIntrudeAnteriorLower: overbiteDeepIntrudeAnteriorLower,
      dxtxSection2_overbiteDeepExtrudePosteriorUpper: overbiteDeepExtrudePosteriorUpper,
      dxtxSection2_overbiteDeepExtrudePosteriorLower: overbiteDeepExtrudePosteriorLower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={!this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteOpenExtrudeAnteriorUpper: !this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={!this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteOpenExtrudeAnteriorLower: !this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={!this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteOpenIntrudePosteriorUpper: !this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={!this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteOpenIntrudePosteriorLower: !this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={!this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteDeepIntrudeAnteriorUpper: !this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={!this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteDeepIntrudeAnteriorLower: !this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={!this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteDeepExtrudePosteriorUpper: !this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={!this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_overbiteDeepExtrudePosteriorLower: !this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineUpper = (event) => {
    let dxtxSection2_midlineUpperRight = this.state.dxtxSection2_midlineUpperRight;
    let dxtxSection2_midlineUpperLeft = this.state.dxtxSection2_midlineUpperLeft;

    if (event.target.value === 'Maintain') {
      dxtxSection2_midlineUpperRight = '';
      dxtxSection2_midlineUpperLeft = '';
    } else if (event.target.value === 'Move right') {
      dxtxSection2_midlineUpperLeft = '';
    } else if (event.target.value === 'Move left') {
      dxtxSection2_midlineUpperRight = '';
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={event.target.value}
          dxtxSection2_midlineUpperRight={dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineUpper: event.target.value,
      dxtxSection2_midlineUpperRight: dxtxSection2_midlineUpperRight,
      dxtxSection2_midlineUpperLeft: dxtxSection2_midlineUpperLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineUpperRight = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={text}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineUpperRight: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineUpperLeft = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={text}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineUpperLeft: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineLower = (event) => {
    let dxtxSection2_midlineLowerRight = this.state.dxtxSection2_midlineLowerRight;
    let dxtxSection2_midlineLowerLeft = this.state.dxtxSection2_midlineLowerLeft;

    if (event.target.value === 'Maintain') {
      dxtxSection2_midlineLowerRight = '';
      dxtxSection2_midlineLowerLeft = '';
    } else if (event.target.value === 'Move right') {
      dxtxSection2_midlineLowerLeft = '';
    } else if (event.target.value === 'Move left') {
      dxtxSection2_midlineLowerRight = '';
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={event.target.value}
          dxtxSection2_midlineLowerRight={dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineLower: event.target.value,
      dxtxSection2_midlineLowerRight: dxtxSection2_midlineLowerRight,
      dxtxSection2_midlineLowerLeft: dxtxSection2_midlineLowerLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineLowerRight = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={text}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineLowerRight: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_midlineLowerLeft = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={text}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_midlineLowerLeft: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_posterior = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={event.target.value}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_posterior: event.target.value,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_spacing = (event) => {
    let dxtxSection2_spacingValue = this.state.dxtxSection2_spacingValue;

    if (event.target.value === 'Close all the spaces') {
      dxtxSection2_spacingValue = '';
    }

    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={event.target.value}
          dxtxSection2_spacingValue={dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_spacing: event.target.value,
      dxtxSection2_spacingValue: dxtxSection2_spacingValue,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_spacingValue = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={text}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_spacingValue: text,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperExpand = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={!this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperExpand: !this.state.dxtxSection2_resolveUpperExpand,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperProcline = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={!this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperProcline: !this.state.dxtxSection2_resolveUpperProcline,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperIPRAnterior = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={!this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperIPRAnterior: !this.state.dxtxSection2_resolveUpperIPRAnterior,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={!this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperIPRPosteriorRight: !this.state.dxtxSection2_resolveUpperIPRPosteriorRight,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={!this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperIPRPosteriorLeft: !this.state.dxtxSection2_resolveUpperIPRPosteriorLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveUpperExtraction = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={!this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveUpperExtraction: !this.state.dxtxSection2_resolveUpperExtraction,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerExpand = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={!this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerExpand: !this.state.dxtxSection2_resolveLowerExpand,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerProcline = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={!this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerProcline: !this.state.dxtxSection2_resolveLowerProcline,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerIPRAnterior = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={!this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerIPRAnterior: !this.state.dxtxSection2_resolveLowerIPRAnterior,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={!this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerIPRPosteriorRight: !this.state.dxtxSection2_resolveLowerIPRPosteriorRight,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={!this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerIPRPosteriorLeft: !this.state.dxtxSection2_resolveLowerIPRPosteriorLeft,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_resolveLowerExtraction = (event) => {
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={!this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={this.state.dxtxSection2_special}
        />
      ),
      dxtxSection2_resolveLowerExtraction: !this.state.dxtxSection2_resolveLowerExtraction,
      stepper: stepper,
    });
  };

  onInputChange_dxtxSection2_special = (event) => {
    let text = textFieldCheck(event.target.value);
    let stepper = this.state.stepper;

    if (this.isSubmitAvailable()) {
      stepper[4] = 2;
      stepper[this.getActivePositionByStepperName('Dx/Tx Plan')] = 1;
    }

    this.setState({
      component: (
        <DxtxPlan
          // Events
          onInputChange_dxtxSection0_chiefComplaint={this.onInputChange_dxtxSection0_chiefComplaint}
          onInputChange_dxtxSection1_mx={this.onInputChange_dxtxSection1_mx}
          onInputChange_dxtxSection1_gingival={this.onInputChange_dxtxSection1_gingival}
          onInputChange_dxtxSection1_upperMidline2Facial={this.onInputChange_dxtxSection1_upperMidline2Facial}
          onInputChange_dxtxSection1_upperMidline2FacialRight={this.onInputChange_dxtxSection1_upperMidline2FacialRight}
          onInputChange_dxtxSection1_upperMidline2FacialLeft={this.onInputChange_dxtxSection1_upperMidline2FacialLeft}
          onInputChange_dxtxSection1_rightMolars={this.onInputChange_dxtxSection1_rightMolars}
          onInputChange_dxtxSection1_rightCanine={this.onInputChange_dxtxSection1_rightCanine}
          onInputChange_dxtxSection1_leftMolars={this.onInputChange_dxtxSection1_leftMolars}
          onInputChange_dxtxSection1_leftCanine={this.onInputChange_dxtxSection1_leftCanine}
          onInputChange_dxtxSection1_overjet={this.onInputChange_dxtxSection1_overjet}
          onInputChange_dxtxSection1_anterior={this.onInputChange_dxtxSection1_anterior}
          onInputChange_dxtxSection1_deep={this.onInputChange_dxtxSection1_deep}
          onInputChange_dxtxSection1_narrow={this.onInputChange_dxtxSection1_narrow}
          onInputChange_dxtxSection1_posterior={this.onInputChange_dxtxSection1_posterior}
          onInputChange_dxtxSection1_class={this.onInputChange_dxtxSection1_class}
          onInputChange_dxtxSection1_crowding={this.onInputChange_dxtxSection1_crowding}
          onInputChange_dxtxSection1_spaces={this.onInputChange_dxtxSection1_spaces}
          onInputChange_dxtxSection1_flared={this.onInputChange_dxtxSection1_flared}
          onInputChange_dxtxSection1_notes={this.onInputChange_dxtxSection1_notes}
          onInputChange_dxtxSection2_arch={this.onInputChange_dxtxSection2_arch}
          onInputChange_dxtxSection2_teeth={this.onInputChange_dxtxSection2_teeth}
          onInputChange_dxtxSection2_teethCustom={this.onInputChange_dxtxSection2_teethCustom}
          onInputChange_dxtxSection2_anteriorMaintainLeft={this.onInputChange_dxtxSection2_anteriorMaintainLeft}
          onInputChange_dxtxSection2_anteriorMaintainRight={this.onInputChange_dxtxSection2_anteriorMaintainRight}
          onInputChange_dxtxSection2_anteriorImproveLeft={this.onInputChange_dxtxSection2_anteriorImproveLeft}
          onInputChange_dxtxSection2_anteriorImproveRight={this.onInputChange_dxtxSection2_anteriorImproveRight}
          onInputChange_dxtxSection2_anteriorCorrectLeft={this.onInputChange_dxtxSection2_anteriorCorrectLeft}
          onInputChange_dxtxSection2_anteriorCorrectRight={this.onInputChange_dxtxSection2_anteriorCorrectRight}
          onInputChange_dxtxSection2_toothMovement={this.onInputChange_dxtxSection2_toothMovement}
          onInputChange_dxtxSection2_toothMovementSelection={this.onInputChange_dxtxSection2_toothMovementSelection}
          onInputChange_dxtxSection2_toothExtractionSelection={this.onInputChange_dxtxSection2_toothExtractionSelection}
          onInputChange_dxtxSection2_overjet={this.onInputChange_dxtxSection2_overjet}
          onInputChange_dxtxSection2_overbite={this.onInputChange_dxtxSection2_overbite}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteOpenIntrudePosteriorLower}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.onInputChange_dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower={this.onInputChange_dxtxSection2_overbiteDeepExtrudePosteriorLower}
          onInputChange_dxtxSection2_midlineUpper={this.onInputChange_dxtxSection2_midlineUpper}
          onInputChange_dxtxSection2_midlineUpperRight={this.onInputChange_dxtxSection2_midlineUpperRight}
          onInputChange_dxtxSection2_midlineUpperLeft={this.onInputChange_dxtxSection2_midlineUpperLeft}
          onInputChange_dxtxSection2_midlineLower={this.onInputChange_dxtxSection2_midlineLower}
          onInputChange_dxtxSection2_midlineLowerRight={this.onInputChange_dxtxSection2_midlineLowerRight}
          onInputChange_dxtxSection2_midlineLowerLeft={this.onInputChange_dxtxSection2_midlineLowerLeft}
          onInputChange_dxtxSection2_posterior={this.onInputChange_dxtxSection2_posterior}
          onInputChange_dxtxSection2_spacing={this.onInputChange_dxtxSection2_spacing}
          onInputChange_dxtxSection2_spacingValue={this.onInputChange_dxtxSection2_spacingValue}
          onInputChange_dxtxSection2_resolveUpperExpand={this.onInputChange_dxtxSection2_resolveUpperExpand}
          onInputChange_dxtxSection2_resolveUpperProcline={this.onInputChange_dxtxSection2_resolveUpperProcline}
          onInputChange_dxtxSection2_resolveUpperIPRAnterior={this.onInputChange_dxtxSection2_resolveUpperIPRAnterior}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveUpperIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveUpperExtraction={this.onInputChange_dxtxSection2_resolveUpperExtraction}
          onInputChange_dxtxSection2_resolveLowerExpand={this.onInputChange_dxtxSection2_resolveLowerExpand}
          onInputChange_dxtxSection2_resolveLowerProcline={this.onInputChange_dxtxSection2_resolveLowerProcline}
          onInputChange_dxtxSection2_resolveLowerIPRAnterior={this.onInputChange_dxtxSection2_resolveLowerIPRAnterior}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorRight}
          onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft={this.onInputChange_dxtxSection2_resolveLowerIPRPosteriorLeft}
          onInputChange_dxtxSection2_resolveLowerExtraction={this.onInputChange_dxtxSection2_resolveLowerExtraction}
          onInputChange_dxtxSection2_special={this.onInputChange_dxtxSection2_special}
          onFocus={this.onFocus_clearWarning}
          // Values
          dxtxSection0_chiefComplaint={this.state.dxtxSection0_chiefComplaint}
          dxtxSection1_mx={this.state.dxtxSection1_mx}
          dxtxSection1_gingival={this.state.dxtxSection1_gingival}
          dxtxSection1_upperMidline2Facial={this.state.dxtxSection1_upperMidline2Facial}
          dxtxSection1_upperMidline2FacialRight={this.state.dxtxSection1_upperMidline2FacialRight}
          dxtxSection1_upperMidline2FacialLeft={this.state.dxtxSection1_upperMidline2FacialLeft}
          dxtxSection1_rightMolars={this.state.dxtxSection1_rightMolars}
          dxtxSection1_rightCanine={this.state.dxtxSection1_rightCanine}
          dxtxSection1_leftMolars={this.state.dxtxSection1_leftMolars}
          dxtxSection1_leftCanine={this.state.dxtxSection1_leftCanine}
          dxtxSection1_overjet={this.state.dxtxSection1_overjet}
          dxtxSection1_anterior={this.state.dxtxSection1_anterior}
          dxtxSection1_deep={this.state.dxtxSection1_deep}
          dxtxSection1_narrow={this.state.dxtxSection1_narrow}
          dxtxSection1_posterior={this.state.dxtxSection1_posterior}
          dxtxSection1_class={this.state.dxtxSection1_class}
          dxtxSection1_crowding={this.state.dxtxSection1_crowding}
          dxtxSection1_spaces={this.state.dxtxSection1_spaces}
          dxtxSection1_flared={this.state.dxtxSection1_flared}
          dxtxSection1_notes={this.state.dxtxSection1_notes}
          dxtxSection2_arch={this.state.dxtxSection2_arch}
          dxtxSection2_teeth={this.state.dxtxSection2_teeth}
          dxtxSection2_teethCustom={this.state.dxtxSection2_teethCustom}
          dxtxSection2_anteriorMaintainLeft={this.state.dxtxSection2_anteriorMaintainLeft}
          dxtxSection2_anteriorMaintainRight={this.state.dxtxSection2_anteriorMaintainRight}
          dxtxSection2_anteriorImproveLeft={this.state.dxtxSection2_anteriorImproveLeft}
          dxtxSection2_anteriorImproveRight={this.state.dxtxSection2_anteriorImproveRight}
          dxtxSection2_anteriorCorrectLeft={this.state.dxtxSection2_anteriorCorrectLeft}
          dxtxSection2_anteriorCorrectRight={this.state.dxtxSection2_anteriorCorrectRight}
          dxtxSection2_toothMovement={this.state.dxtxSection2_toothMovement}
          dxtxSection2_toothMovementSelection={this.state.dxtxSection2_toothMovementSelection}
          dxtxSection2_toothExtractionSelection={this.state.dxtxSection2_toothExtractionSelection}
          dxtxSection2_toothUpper={this.state.dxtxSection2_toothUpper}
          dxtxSection2_toothLower={this.state.dxtxSection2_toothLower}
          dxtxSection2_overjet={this.state.dxtxSection2_overjet}
          dxtxSection2_overbite={this.state.dxtxSection2_overbite}
          dxtxSection2_overbiteOpenExtrudeAnteriorUpper={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorUpper}
          dxtxSection2_overbiteOpenExtrudeAnteriorLower={this.state.dxtxSection2_overbiteOpenExtrudeAnteriorLower}
          dxtxSection2_overbiteOpenIntrudePosteriorUpper={this.state.dxtxSection2_overbiteOpenIntrudePosteriorUpper}
          dxtxSection2_overbiteOpenIntrudePosteriorLower={this.state.dxtxSection2_overbiteOpenIntrudePosteriorLower}
          dxtxSection2_overbiteDeepIntrudeAnteriorUpper={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorUpper}
          dxtxSection2_overbiteDeepIntrudeAnteriorLower={this.state.dxtxSection2_overbiteDeepIntrudeAnteriorLower}
          dxtxSection2_overbiteDeepExtrudePosteriorUpper={this.state.dxtxSection2_overbiteDeepExtrudePosteriorUpper}
          dxtxSection2_overbiteDeepExtrudePosteriorLower={this.state.dxtxSection2_overbiteDeepExtrudePosteriorLower}
          dxtxSection2_midlineUpper={this.state.dxtxSection2_midlineUpper}
          dxtxSection2_midlineUpperRight={this.state.dxtxSection2_midlineUpperRight}
          dxtxSection2_midlineUpperLeft={this.state.dxtxSection2_midlineUpperLeft}
          dxtxSection2_midlineLower={this.state.dxtxSection2_midlineLower}
          dxtxSection2_midlineLowerRight={this.state.dxtxSection2_midlineLowerRight}
          dxtxSection2_midlineLowerLeft={this.state.dxtxSection2_midlineLowerLeft}
          dxtxSection2_posterior={this.state.dxtxSection2_posterior}
          dxtxSection2_spacing={this.state.dxtxSection2_spacing}
          dxtxSection2_spacingValue={this.state.dxtxSection2_spacingValue}
          dxtxSection2_resolveUpperExpand={this.state.dxtxSection2_resolveUpperExpand}
          dxtxSection2_resolveUpperProcline={this.state.dxtxSection2_resolveUpperProcline}
          dxtxSection2_resolveUpperIPRAnterior={this.state.dxtxSection2_resolveUpperIPRAnterior}
          dxtxSection2_resolveUpperIPRPosteriorRight={this.state.dxtxSection2_resolveUpperIPRPosteriorRight}
          dxtxSection2_resolveUpperIPRPosteriorLeft={this.state.dxtxSection2_resolveUpperIPRPosteriorLeft}
          dxtxSection2_resolveUpperExtraction={this.state.dxtxSection2_resolveUpperExtraction}
          dxtxSection2_resolveLowerExpand={this.state.dxtxSection2_resolveLowerExpand}
          dxtxSection2_resolveLowerProcline={this.state.dxtxSection2_resolveLowerProcline}
          dxtxSection2_resolveLowerIPRAnterior={this.state.dxtxSection2_resolveLowerIPRAnterior}
          dxtxSection2_resolveLowerIPRPosteriorRight={this.state.dxtxSection2_resolveLowerIPRPosteriorRight}
          dxtxSection2_resolveLowerIPRPosteriorLeft={this.state.dxtxSection2_resolveLowerIPRPosteriorLeft}
          dxtxSection2_resolveLowerExtraction={!this.state.dxtxSection2_resolveLowerExtraction}
          dxtxSection2_special={text}
        />
      ),
      dxtxSection2_special: text,
      stepper: stepper,
    });
  };

  /**
   * Handles latest DE2 form changes
   *
   * @function
   * @param {Object} event - Contains the event object for a given action
   */
  onInputChange_de2 = (event) => {
    let de2_reason = this.state.de2_reason;
    let de2_initial_date = this.state.de2_initial_date;
    let de2_intra_date = this.state.de2_intra_date;
    let de2_upper_smartwire = this.state.de2_upper_smartwire;
    let de2_upper_smartwire_date = this.state.de2_upper_smartwire_date;
    let de2_lower_smartwire = this.state.de2_lower_smartwire;
    let de2_lower_smartwire_date = this.state.de2_lower_smartwire_date;
    let de2_arch_to_treat = this.state.de2_arch_to_treat;
    let de2_arch_opposing_treat = this.state.de2_arch_opposing_treat;
    let de2_setup = this.state.de2_setup;
    let de2_setup_instruction = this.state.de2_setup_instruction;
    let de2_setup_additional_instruction = this.state.de2_setup_additional_instruction;
    let de2_bracket_instruction = this.state.de2_bracket_instruction;
    let de2_teeth_restriction = _.cloneDeep(this.state.de2_teeth_restriction);
    let de2_bracket_remove = _.cloneDeep(this.state.de2_bracket_remove);
    let de2_bracket_add = _.cloneDeep(this.state.de2_bracket_add);
    let de2_ipr = this.state.de2_ipr;
    let de2_ipr_instruction = this.state.de2_ipr_instruction;
    let de2_spacing = this.state.de2_spacing;
    let de2_spacing_upper = _.cloneDeep(this.state.de2_spacing_upper);
    let de2_spacing_lower = _.cloneDeep(this.state.de2_spacing_lower);
    let de2_spacing_instruction = this.state.de2_spacing_instruction;
    let de2_special_instruction = this.state.de2_special_instruction;
    let de2_prev_case_info = this.state.de2_prev_case_info;
    let stepper = this.state.stepper;
    let de2_initial_date_warning = this.state.de2_initial_date_warning;
    let de2_intra_date_warning = this.state.de2_intra_date_warning;
    let de2_upper_smartwire_date_warning = this.state.de2_upper_smartwire_date_warning;
    let de2_lower_smartwire_date_warning = this.state.de2_lower_smartwire_date_warning;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';
    let index = 0;

    switch (event_type) {
      case 'de2_reason':
        de2_reason = textFieldLimited(event.target.value);

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[4] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_initial_date':
        de2_initial_date = event.target.value;
        de2_initial_date_warning = !isValidDate(de2_initial_date);

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[4] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_intra_date':
        de2_intra_date = event.target.value;
        de2_intra_date_warning = !isValidDate(de2_intra_date);

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          this.isPhotoUploadLengthValid() &&
          this.isScansLengthValid() &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[4] = 2;
        }

        if (
          de2_reason &&
          de2_reason !== '' &&
          de2_intra_date &&
          de2_intra_date !== '' &&
          de2_initial_date &&
          de2_initial_date !== '' &&
          !de2_initial_date_warning &&
          !de2_intra_date_warning &&
          !de2_upper_smartwire_date_warning &&
          !de2_lower_smartwire_date_warning
        ) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        }

        break;

      case 'de2_upper_smartwire':
        de2_upper_smartwire = event.target.value;

        if (de2_upper_smartwire === 'none') {
          de2_upper_smartwire_date = '';
        }

        break;

      case 'de2_upper_smartwire_date':
        de2_upper_smartwire_date = event.target.value;
        de2_upper_smartwire_date_warning = !isValidDate(de2_upper_smartwire_date);
        if (de2_upper_smartwire_date_warning) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        }
        break;

      case 'de2_lower_smartwire':
        de2_lower_smartwire = event.target.value;

        if (de2_lower_smartwire === 'none') {
          de2_lower_smartwire_date = '';
        }

        break;

      case 'de2_lower_smartwire_date':
        de2_lower_smartwire_date = event.target.value;
        de2_lower_smartwire_date_warning = !isValidDate(de2_lower_smartwire_date);
        if (de2_lower_smartwire_date_warning) {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 2;
        } else {
          stepper[this.getActivePositionByStepperName('Refinement Request Form')] = 1;
        }
        break;

      case 'de2_arch_to_treat':
        if (de2_arch_to_treat !== event.target.value) {
          de2_arch_opposing_treat = 'current';
        }

        de2_arch_to_treat = event.target.value;
        break;

      case 'de2_arch_opposing_treat':
        de2_arch_opposing_treat = event.target.value;
        break;

      case 'de2_setup':
        de2_setup = event.target.value;
        break;

      case 'de2_setup_instruction':
        de2_setup_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_setup_additional_instruction':
        de2_setup_additional_instruction = event.target.value;
        break;

      case 'de2_bracket_instruction':
        de2_bracket_instruction = event.target.value;
        break;

      case 'de2_teeth_restriction':
        if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
          de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
        } else {
          de2_teeth_restriction.push(event.target.value);
        }

        break;

      case 'de2_bracket_remove':
        if (_.intersection(de2_bracket_remove, [event.target.value]).length > 0) {
          de2_bracket_remove = _.difference(de2_bracket_remove, [event.target.value]);

          if (_.intersection(de2_teeth_restriction, [event.target.value]).length > 0) {
            de2_teeth_restriction = _.difference(de2_teeth_restriction, [event.target.value]);
          }
        } else {
          de2_bracket_remove.push(event.target.value);
          de2_teeth_restriction.push(event.target.value);
          de2_teeth_restriction = _.uniq(de2_teeth_restriction);
        }

        break;

      case 'de2_bracket_add':
        if (_.intersection(de2_bracket_add, [event.target.value]).length > 0) {
          de2_bracket_add = _.difference(de2_bracket_add, [event.target.value]);
        } else {
          de2_bracket_add.push(event.target.value);
        }

        break;

      case 'de2_ipr':
        de2_ipr = event.target.value;
        break;

      case 'de2_ipr_instruction':
        de2_ipr_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_spacing':
        de2_spacing = event.target.value;
        break;

      case 'de2_spacing_upper':
        index = parseInt(event.target.dataset.index);
        de2_spacing_upper[index] = this.formatMMEntry(de2_spacing_upper, event.target.value, index);
        break;

      case 'de2_spacing_lower':
        index = parseInt(event.target.dataset.index);
        de2_spacing_lower[index] = this.formatMMEntry(de2_spacing_lower, event.target.value, index);
        break;

      case 'de2_spacing_instruction':
        de2_spacing_instruction = textFieldLimited(event.target.value);
        break;

      case 'de2_special_instruction':
        de2_special_instruction = textFieldLimited(event.target.value);
        break;

      default:
    }

    this.setState({
      component: this.getDEForm({
        de2_reason: de2_reason,
        de2_initial_date: de2_initial_date,
        de2_intra_date: de2_intra_date,
        de2_upper_smartwire: de2_upper_smartwire,
        de2_upper_smartwire_date: de2_upper_smartwire_date,
        de2_lower_smartwire: de2_lower_smartwire,
        de2_lower_smartwire_date: de2_lower_smartwire_date,
        de2_arch_to_treat: de2_arch_to_treat,
        de2_arch_opposing_treat: de2_arch_opposing_treat,
        de2_setup: de2_setup,
        de2_setup_instruction: de2_setup_instruction,
        de2_setup_additional_instruction: de2_setup_additional_instruction,
        de2_bracket_instruction: de2_bracket_instruction,
        de2_teeth_restriction: de2_teeth_restriction,
        de2_bracket_remove: de2_bracket_remove,
        de2_bracket_add: de2_bracket_add,
        de2_ipr: de2_ipr,
        de2_ipr_instruction: de2_ipr_instruction,
        de2_spacing: de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: de2_spacing_instruction,
        de2_special_instruction: de2_special_instruction,
        de2_prev_case_info: de2_prev_case_info,
        de2_initial_date_warning: de2_initial_date_warning,
        de2_intra_date_warning: de2_intra_date_warning,
      }),
      de2_reason: de2_reason,
      de2_initial_date: de2_initial_date,
      de2_intra_date: de2_intra_date,
      de2_upper_smartwire: de2_upper_smartwire,
      de2_upper_smartwire_date: de2_upper_smartwire_date,
      de2_lower_smartwire: de2_lower_smartwire,
      de2_lower_smartwire_date: de2_lower_smartwire_date,
      de2_arch_to_treat: de2_arch_to_treat,
      de2_arch_opposing_treat: de2_arch_opposing_treat,
      de2_setup: de2_setup,
      de2_setup_instruction: de2_setup_instruction,
      de2_setup_additional_instruction: de2_setup_additional_instruction,
      de2_bracket_instruction: de2_bracket_instruction,
      de2_teeth_restriction: de2_teeth_restriction,
      de2_bracket_remove: de2_bracket_remove,
      de2_bracket_add: de2_bracket_add,
      de2_ipr: de2_ipr,
      de2_ipr_instruction: de2_ipr_instruction,
      de2_spacing: de2_spacing,
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
      de2_spacing_instruction: de2_spacing_instruction,
      de2_special_instruction: de2_special_instruction,
      de2_initial_date_warning: de2_initial_date_warning,
      de2_intra_date_warning: de2_intra_date_warning,
      de2_upper_smartwire_date_warning: de2_upper_smartwire_date_warning,
      de2_lower_smartwire_date_warning: de2_lower_smartwire_date_warning,
    });
  };

  /**
   * DE3.0 input change handler
   * Handles all input changes on the form
   * @function
   * @param {Object} event - The event object on change
   */
  onInputChange_de3 = (event) => {
    let de3_reason = this.state.de3_reason;
    let de3_next_appointment_date = this.state.de3_next_appointment_date;
    let de3_intra_date = this.state.de3_intra_date;
    let de3_upper_smartwire = this.state.de3_upper_smartwire;
    let de3_upper_smartwire_date = this.state.de3_upper_smartwire_date;
    let de3_lower_smartwire = this.state.de3_lower_smartwire;
    let de3_lower_smartwire_date = this.state.de3_lower_smartwire_date;
    let de3_arch_to_treat = this.state.de3_arch_to_treat;
    let de3_arch_opposing_treat = this.state.de3_arch_opposing_treat;
    let de3_upper_arch_opposing_treat = this.state.de3_upper_arch_opposing_treat;
    let de3_lower_arch_opposing_treat = this.state.de3_lower_arch_opposing_treat;
    let de3_setup = this.state.de3_setup;
    let de3_setup_instruction = this.state.de3_setup_instruction;
    let de3_setup_additional_instruction = this.state.de3_setup_additional_instruction;
    let de3_bracket_teeth = this.state.de3_bracket_teeth;
    let de3_missing_teeth = this.state.de3_missing_teeth;
    let de3_extract_teeth = this.state.de3_extract_teeth;
    let de3_teeth_restriction = this.state.de3_teeth_restriction;
    let de3_prev_case_info = this.state.de3_prev_case_info;
    let de3_next_appointment_date_warning = this.state.de3_next_appointment_date_warning;
    let de3_intra_date_warning = this.state.de3_intra_date_warning;
    let de3_upper_smartwire_date_warning = this.state.de3_upper_smartwire_date_warning;
    let de3_lower_smartwire_date_warning = this.state.de3_lower_smartwire_date_warning;
    let de3_anterior_posterior_r = this.state.de3_anterior_posterior_r;
    let de3_anterior_posterior_l = this.state.de3_anterior_posterior_l;
    let de3_anterior_posterior_corrections = this.state.de3_anterior_posterior_corrections;
    let de3_anterior_posterior_notes = this.state.de3_anterior_posterior_notes;
    let de3_midlines = this.state.de3_midlines;
    let de3_auxiliaries = this.state.de3_auxiliaries;
    let de3_auxiliaries_notes = this.state.de3_auxiliaries_notes;

    const event_type = event && event.target && event.target.dataset && event.target.dataset.field ? event.target.dataset.field : '';

    switch (event_type) {
      case 'de3_reason':
        de3_reason = removeEmoji(textFieldLimited(event.target.value));

        break;

      case 'de3_next_appointment_date':
        de3_next_appointment_date = event.target.value;
        de3_next_appointment_date_warning = !isDateInBetween(de3_next_appointment_date, new Date(), '3000-01-01');
        break;

      case 'de3_intra_date':
        de3_intra_date = event.target.value;
        de3_intra_date_warning = !isValidDate(de3_intra_date);
        break;

      case 'de3_upper_smartwire':
        de3_upper_smartwire = event.target.value;

        if (de3_upper_smartwire === 'none') {
          de3_upper_smartwire_date = '';
        }
        break;

      case 'de3_upper_smartwire_date':
        de3_upper_smartwire_date = event.target.value;
        de3_upper_smartwire_date_warning = !isValidDate(de3_upper_smartwire_date);
        break;

      case 'de3_lower_smartwire':
        de3_lower_smartwire = event.target.value;
        if (de3_lower_smartwire === 'none') {
          de3_lower_smartwire_date = '';
        }
        break;

      case 'de3_lower_smartwire_date':
        de3_lower_smartwire_date = event.target.value;
        de3_lower_smartwire_date_warning = !isValidDate(de3_lower_smartwire_date);
        break;

      case 'de3_arch_to_treat':
        const de3_arch_to_treat_value = event.target.value;
        if (de3_arch_to_treat_value === 'upper') {
          de3_arch_opposing_treat = de3_upper_arch_opposing_treat;
        } else if (de3_arch_to_treat_value === 'lower') {
          de3_arch_opposing_treat = de3_lower_arch_opposing_treat;
        }
        de3_arch_to_treat = de3_arch_to_treat_value;
        break;

      case 'de3_arch_opposing_treat':
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_upper_arch_opposing_treat':
        de3_upper_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_lower_arch_opposing_treat':
        de3_lower_arch_opposing_treat = event.target.value;
        de3_arch_opposing_treat = event.target.value;
        break;

      case 'de3_setup':
        de3_setup = event.target.value;
        break;

      case 'de3_setup_instruction':
        de3_setup_instruction = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_setup_additional_instruction':
        de3_setup_additional_instruction = event.target.value;
        break;

      case 'de3_bracket_teeth':
        if (_.intersection(de3_bracket_teeth, [event.target.value]).length > 0) {
          de3_bracket_teeth = _.difference(de3_bracket_teeth, [event.target.value]);
        } else {
          de3_bracket_teeth.push(event.target.value);
        }
        break;

      case 'de3_missing_teeth':
        if (_.intersection(de3_missing_teeth, [event.target.value]).length > 0) {
          de3_missing_teeth = _.difference(de3_missing_teeth, [event.target.value]);
        } else {
          de3_missing_teeth.push(event.target.value);
        }
        break;

      case 'de3_extract_teeth':
        if (_.intersection(de3_extract_teeth, [event.target.value]).length > 0) {
          de3_extract_teeth = _.difference(de3_extract_teeth, [event.target.value]);
        } else {
          de3_extract_teeth.push(event.target.value);
        }
        break;

      case 'de3_teeth_restriction':
        if (_.intersection(de3_teeth_restriction, [event.target.value]).length > 0) {
          de3_teeth_restriction = _.difference(de3_teeth_restriction, [event.target.value]);
        } else {
          de3_teeth_restriction.push(event.target.value);
        }
        break;

      case 'de3_anterior_posterior_r':
        de3_anterior_posterior_r = event.target.value;
        break;

      case 'de3_anterior_posterior_l':
        de3_anterior_posterior_l = event.target.value;
        break;

      case 'de3_anterior_posterior_corrections':
        const { name, checked } = event.target;
        de3_anterior_posterior_corrections = {
          ...de3_anterior_posterior_corrections,
          [name]: checked,
        };
        break;

      case 'de3_anterior_posterior_notes':
        de3_anterior_posterior_notes = removeEmoji(textFieldLimited(event.target.value));
        break;

      case 'de3_midlines':
        de3_midlines = event.target.value;
        break;

      case 'de3_auxiliaries':
        de3_auxiliaries = event.target.value;
        break;

      case 'de3_auxiliaries_notes':
        de3_auxiliaries_notes = removeEmoji(textFieldLimited(event.target.value));
        break;

      default:
    }

    const ns = {
      de3_reason,
      de3_next_appointment_date,
      de3_intra_date,
      de3_upper_smartwire,
      de3_upper_smartwire_date,
      de3_lower_smartwire,
      de3_lower_smartwire_date,
      de3_arch_to_treat,
      de3_arch_opposing_treat,
      de3_upper_arch_opposing_treat,
      de3_lower_arch_opposing_treat,
      de3_setup,
      de3_setup_instruction,
      de3_setup_additional_instruction,
      de3_bracket_teeth,
      de3_missing_teeth,
      de3_extract_teeth,
      de3_teeth_restriction,
      de3_prev_case_info,
      de3_next_appointment_date_warning,
      de3_intra_date_warning,
      de3_anterior_posterior_r,
      de3_anterior_posterior_l,
      de3_anterior_posterior_corrections,
      de3_anterior_posterior_notes,
      de3_midlines,
      de3_auxiliaries,
      de3_auxiliaries_notes,
      de3_upper_smartwire_date_warning,
      de3_lower_smartwire_date_warning,
    };

    this.setState({
      ...ns,
      component: this.getDE3Form(ns),
    });

    this.onFocus_clearWarning();
  };

  formatMMEntry = (state, value, index) => {
    // Value should be X.X
    const prevCond = state[index];

    let formattedValue = value
      .replace(/[^0-9.]/g, '')
      .replace('..', '.')
      .replace('00', '0');

    //Enforce .
    if (formattedValue.length === 1 && prevCond === '' && formattedValue !== '.') {
      formattedValue += '.';
    } else if (formattedValue.length === 1 && prevCond === '' && formattedValue === '.') {
      formattedValue = '0.';
    } else if ((value.length > 2 && prevCond === '0') || (value.length === 2 && parseInt(prevCond) <= 9 && value[1] !== '.' && value[0] !== '.')) {
      formattedValue = value[0] + '.' + value[1];
    } else if (formattedValue.length === 1 && prevCond === '.') {
      formattedValue = '';
    }

    //if . is detected than only allow one digit after
    const decimalPos = formattedValue.indexOf('.');
    if (decimalPos >= 0 && formattedValue.length >= decimalPos + 2) {
      formattedValue = formattedValue.slice(0, decimalPos + 2);
    }

    //trim off leading 0 conditions
    if (decimalPos !== 1 && value[0] === '0') {
      formattedValue = formattedValue.slice(1);
    }

    //max character limit
    if (value.length >= 4) {
      formattedValue = prevCond;

      if (parseFloat(value) > 9.9) {
        formattedValue = '9.9';
      }
    } else if (formattedValue.length === 3 && formattedValue[2] === '.') {
      formattedValue = '9.9';
    }

    return formattedValue;
  };

  formatMMFromBlur = (value) => {
    let formattedValue = value;

    if (formattedValue.length < 3) {
      if (value.indexOf('.') === value.length - 1 && value !== '') {
        formattedValue = formattedValue + '0';
      }
    }

    return formattedValue;
  };
  /**
   * Handles when the user clicks the x on the corner of each spacing chart box
   *
   * @function
   * @param {Object} index - Index of where the value should be
   * @param {Object} state - Value in that position
   *
   */
  clearSpacingValue = (index, state) => {
    let de2_spacing_upper = _.cloneDeep(this.state.de2_spacing_upper);
    let de2_spacing_lower = _.cloneDeep(this.state.de2_spacing_lower);

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = '';
        break;

      case 'de2_spacing_lower':
        de2_spacing_lower[index] = '';

        break;
      default:
    }

    this.setState({
      component: this.getDEForm({
        de2_reason: this.state.de2_reason,
        de2_initial_date: this.state.de2_initial_date,
        de2_intra_date: this.state.de2_intra_date,
        de2_upper_smartwire: this.state.de2_upper_smartwire,
        de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
        de2_lower_smartwire: this.state.de2_lower_smartwire,
        de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
        de2_arch_to_treat: this.state.de2_arch_to_treat,
        de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
        de2_setup: this.state.de2_setup,
        de2_setup_instruction: this.state.de2_setup_instruction,
        de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
        de2_bracket_instruction: this.state.de2_bracket_instruction,
        de2_teeth_restriction: this.state.de2_teeth_restriction,
        de2_bracket_remove: this.state.de2_bracket_remove,
        de2_bracket_add: this.state.de2_bracket_add,
        de2_ipr: this.state.de2_ipr,
        de2_ipr_instruction: this.state.de2_ipr_instruction,
        de2_spacing: this.state.de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: this.state.de2_spacing_instruction,
        de2_special_instruction: this.state.de2_special_instruction,
        de2_prev_case_info: this.state.de2_prev_case_info,
        de2_initial_date_warning: this.state.de2_initial_date_warning,
        de2_intra_date_warning: this.state.de2_intra_date_warning,
      }),
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };
  /**
   * Handles value when on Blur event
   *
   * @function
   * @param {Object} index - Index of where the value should be
   * @param {Object} state - Value in that position
   *
   */
  onChangeSpacingOnBlur = (index, state) => {
    let de2_spacing_upper = _.cloneDeep(this.state.de2_spacing_upper);
    let de2_spacing_lower = _.cloneDeep(this.state.de2_spacing_lower);

    switch (state) {
      case 'de2_spacing_upper':
        de2_spacing_upper[index] = this.formatMMFromBlur(de2_spacing_upper[index]);
        break;

      case 'de2_spacing_lower':
        de2_spacing_lower[index] = this.formatMMFromBlur(de2_spacing_lower[index]);

        break;
      default:
    }

    this.setState({
      component: this.getDEForm({
        de2_reason: this.state.de2_reason,
        de2_initial_date: this.state.de2_initial_date,
        de2_intra_date: this.state.de2_intra_date,
        de2_upper_smartwire: this.state.de2_upper_smartwire,
        de2_upper_smartwire_date: this.state.de2_upper_smartwire_date,
        de2_lower_smartwire: this.state.de2_lower_smartwire,
        de2_lower_smartwire_date: this.state.de2_lower_smartwire_date,
        de2_arch_to_treat: this.state.de2_arch_to_treat,
        de2_arch_opposing_treat: this.state.de2_arch_opposing_treat,
        de2_setup: this.state.de2_setup,
        de2_setup_instruction: this.state.de2_setup_instruction,
        de2_setup_additional_instruction: this.state.de2_setup_additional_instruction,
        de2_bracket_instruction: this.state.de2_bracket_instruction,
        de2_teeth_restriction: this.state.de2_teeth_restriction,
        de2_bracket_remove: this.state.de2_bracket_remove,
        de2_bracket_add: this.state.de2_bracket_add,
        de2_ipr: this.state.de2_ipr,
        de2_ipr_instruction: this.state.de2_ipr_instruction,
        de2_spacing: this.state.de2_spacing,
        de2_spacing_upper: de2_spacing_upper,
        de2_spacing_lower: de2_spacing_lower,
        de2_spacing_instruction: this.state.de2_spacing_instruction,
        de2_special_instruction: this.state.de2_special_instruction,
        de2_prev_case_info: this.state.de2_prev_case_info,
        de2_initial_date_warning: this.state.de2_initial_date_warning,
        de2_intra_date_warning: this.state.de2_intra_date_warning,
      }),
      de2_spacing_upper: de2_spacing_upper,
      de2_spacing_lower: de2_spacing_lower,
    });
  };

  removeWizardErrorMsg() {
    this.setState({
      warning: false,
    });
  }

  /**
   * Retuns the content of the stepper
   * @function
   * @return {Array} The content list
   */
  getStepperContent() {
    return [
      {
        description: 'Impressions',
        label: 'Toothprints',
        active: this.state.active === 0,
        completed: this.state.stepper[0] === 1,
        uploading: this.state.uploadMethod === 'manual' && this.state.scans_uploading && this.state.scans_uploading.length > 0,
      },
      {
        description: 'Photos',
        label: 'Photos',
        active: this.state.active === 1,
        completed: this.state.stepper[1] === 1,
        uploading: (this.state.photos_uploading && this.state.photos_uploading.length > 0) || this.isLoadingIteroPhotos(),
      },
      {
        description: 'X-Rays',
        label: this.state.isDigitalEnhancement ? 'Radiographs (Optional)' : 'Radiographs',
        active: this.state.active === 2,
        completed: this.state.stepper[2] === 1,
        uploading: this.state.radiographs_uploading && this.state.radiographs_uploading.length > 0,
      },
      {
        description: this.state.isDigitalEnhancement ? 'Refinement Request Form' : 'Dx/Tx Plan',
        label: this.state.isDigitalEnhancement ? 'DE Form' : 'Treatment Plan',
        active: this.state.active === 3,
        completed: this.state.stepper[3] === 1,
      },
      {
        description: 'Submit',
        label: 'Submit',
        active: this.state.active === 4,
        completed: this.state.stepper[4] === 1,
      },
    ];
  }

  /**
   * Retrieves the title of record reupload
   * @function
   * @return {String} The title of record reupload
   */
  getTitle() {
    if (this.state.patient && this.state.patient.first_name && this.state.patient.last_name) {
      return `Upload New Records - ${this.state.patient.first_name} ${this.state.patient.last_name}`;
    } else {
      return 'Upload New Records';
    }
  }

  /**
   * Sets bracket teeth
   * @function
   * @param {list} teeth - Bracket teeth
   */
  setBracketTeeth = (teeth) => {
    const bracket_teeth =
      teeth !== undefined
        ? removeOpposingTeeth(this.state.de3_arch_to_treat, teeth)
        : removeOpposingTeeth(this.state.de3_arch_to_treat, this.state.de3_bracket_teeth);
    const ns = {
      de3_reason: this.state.de3_reason,
      de3_next_appointment_date: this.state.de3_next_appointment_date,
      de3_intra_date: this.state.de3_intra_date,
      de3_upper_smartwire: this.state.de3_upper_smartwire,
      de3_upper_smartwire_date: this.state.de3_upper_smartwire_date,
      de3_lower_smartwire: this.state.de3_lower_smartwire,
      de3_lower_smartwire_date: this.state.de3_lower_smartwire_date,
      de3_arch_to_treat: this.state.de3_arch_to_treat,
      de3_arch_opposing_treat: this.state.de3_arch_opposing_treat,
      de3_upper_arch_opposing_treat: this.state.de3_upper_arch_opposing_treat,
      de3_lower_arch_opposing_treat: this.state.de3_lower_arch_opposing_treat,
      de3_setup: this.state.de3_setup,
      de3_setup_instruction: this.state.de3_setup_instruction,
      de3_setup_additional_instruction: this.state.de3_setup_additional_instruction,
      de3_bracket_teeth: bracket_teeth,
      de3_missing_teeth: this.state.de3_missing_teeth,
      de3_extract_teeth: this.state.de3_extract_teeth,
      de3_teeth_restriction: this.state.de3_teeth_restriction,
      de3_prev_case_info: this.state.de3_prev_case_info,
      de3_next_appointment_date_warning: this.state.de3_next_appointment_date_warning,
      de3_intra_date_warning: this.state.de3_intra_date_warning,
      de3_anterior_posterior_r: this.state.de3_anterior_posterior_r,
      de3_anterior_posterior_l: this.state.de3_anterior_posterior_l,
      de3_anterior_posterior_corrections: this.state.de3_anterior_posterior_corrections,
      de3_anterior_posterior_notes: this.state.de3_anterior_posterior_notes,
      de3_midlines: this.state.de3_midlines,
      de3_auxiliaries: this.state.de3_auxiliaries,
      de3_auxiliaries_notes: this.state.de3_auxiliaries_notes,
    };
    this.setState({ ...ns, component: this.getDE3Form(ns) });
  };

  render() {
    if (this.state.loading) {
      return <CircleLoader fullscreen />;
    } else {
      return (
        <div>
          <Helmet>
            <title>Case Record Reupload | InBrace Smile Design Studio™</title>
          </Helmet>
          <h1 className="content__header fs-exclude">{this.getTitle()}</h1>
          {!this.state.hide && (
            <CardContainer>
              <Stepper onStepperClick={this.onStepperClick} stepperContent={this.getStepperContent()} activeStepper={this.state.active} />
            </CardContainer>
          )}

          <CardContainer className="case-card-container mt-sm pad-lg clear-after">
            {this.state.active !== this.getActivePositionByStepperName('Submit') && !this.state.hide && <AlertContainer>{this.state.comment}</AlertContainer>}
            {this.state.component}
            <ErrorMessage
              className={this.state.warning ? 'error-message-container' : 'error-message-container hide'}
              title={this.state.warning_title}
              onClose={this.removeWizardErrorMsg}
            >
              <div id="warning-submit" />
            </ErrorMessage>
          </CardContainer>

          {this.state.showEmptyFileModal ? <EmptyFileErrorModal onEmptyFileErrorDismiss={this.onEmptyFileErrorDismiss} /> : null}

          {!this.state.hide && (
            <div className="case-form-controls">
              {this.state.buttons}
              <Button theme="revise" onClick={this.onRedirectCaseDetails} data-redirect="true">
                Cancel
              </Button>
            </div>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    // iTero integration - redux state
    record_states: getRecordStates(state),
    licenseNumbers: getLicenseNumbers(state),
    isFetchIteroPhotosPending: getFetchIteroPhotosPending(state),
    isGetIteroPhotoRecordsForRecordsReuploadPending: getGetIteroPhotoRecordsForRecordsReuploadPending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // iTero integration - redux actions
      fetchLicenseNumbers,
      buildRecordStates,
      fetchIteroScans,
      fetchIteroPhotos,
      getIteroPhotoRecordsForRecordsReupload,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecordReUpload));
