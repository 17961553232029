import React, { Component } from 'react';
class Checkbox extends Component {
  render() {
    return (
      <React.Fragment>
        <input
          type="checkbox"
          className={this.props.className}
          id={this.props.id}
          ref={this.props.id}
          checked={this.props.isChecked}
          onChange={this.props.toggleChange}
          disabled={this.props.disabled}
        />
        <label className={this.props.label_className} htmlFor={this.props.id}>
          {' '}
          {this.props.label}
        </label>
      </React.Fragment>
    );
  }
}

export default Checkbox;
