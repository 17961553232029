import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CardContainer from '../components/container/card_container';
import { connect } from 'react-redux';
import { getDoctorStats, getProviderTier } from '../../redux/reducers/ipp/dashboard';
import { fetchDoctorStats } from '../../redux/actions/ipp/dashboard';
import { bindActionCreators } from 'redux';

function DoctorStats(props) {
  const { doctor_id, stats, provider_tier, fetchDoctorStats } = props;

  useEffect(() => {
    fetchDoctorStats(doctor_id);
  }, [fetchDoctorStats, doctor_id]);

  const approvals = 'case_approvals' in provider_tier ? provider_tier.case_approvals : stats.approved;
  const statsList = [
    {
      title: 'Lifetime Cases',
      count: stats.lifetime,
    },
    {
      title: 'In Transit',
      count: stats.transit,
    },
    {
      title: 'Approvals This Quarter',
      count: approvals,
    },
  ];

  return (
    <div className="dashboard-list-container card card--regular dashboard-stats">
      {statsList.map((item) => (
        <div className="dashboard-stats__stats__col" key={item.title}>
          <div className="stats__title">{item.title}</div>
          {item.count}
        </div>
      ))}
    </div>
  );
}

DoctorStats.propTypes = {
  doctor_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  stats: PropTypes.object.isRequired,
  provider_tier: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    stats: getDoctorStats(state),
    provider_tier: getProviderTier(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDoctorStats: fetchDoctorStats,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DoctorStats);
