import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchDoctorInfo } from '../../redux/actions/ipp/dashboard';
import { getDoctorInfo, getDoctorInfoError, getDoctorInfoLoading } from '../../redux/reducers/ipp/dashboard';
import { getDoctorsIdFromRoute } from '../../common/route';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';

import Activity from './activity';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import CustomerAgreement from '../customer_agreement/customer_agreement';
import DoctorStats from './doctor_stats';
import NotFound from '../404/not_found';
import ProviderTierCard from './provider_tier_card';
import Content from '../case_list/content';
import CaseListStats from './caselist_stats';
import CardContainer from '../components/container/card_container';


function getContentURL() {
  const url = process.env.REACT_APP_DASHBOARD_CONTENT_URL;
  const backup = 'https://www.inbrace.com';
  return url ? url : backup;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      caseList: [],
    };
  }

  componentDidMount() {
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();

    if (doctor_id && (!this.props.doctor_info || this.props.doctor_info.account_id !== Number(doctor_id))) {
      this.props.fetchDoctorInfo(doctor_id);
    }

    Axios.get(`/apiV2/agreement`)
      .then(function (res) {
        that.setState({
          loading: false,
        });
      })
      .catch(function (err) {
        that.setState({
          loading: false,
          terms: true,
        });
      });
  }

  componentDidUpdate() {
    const doctor_id = getDoctorsIdFromRoute();

    if (this.props.doctor_info && Number(doctor_id) !== this.props.doctor_info.account_id) {
      this.props.fetchDoctorInfo(doctor_id);
    }
  }

  getDoctorId() {
    const doctor_id = getDoctorsIdFromRoute();
    return doctor_id ? doctor_id : this.props.doctor_info.account_id;
  }

  /**
   * Get Dashboard welcome text
   * @function
   * @returns {string} Welcome text
   */
  getHeaderTitle = () => {
    if (this.props.doctor_info && !this.props.doctor_info_loading) {
      const { first_name, last_name, role, permission_roles } = this.props.doctor_info;
      const isDsoAdmin = role === 'DSO' && permission_roles.includes('DSO Admin');
      const dsoHeaderTitle = 'Welcome back, ' + first_name + '!';
      const headerTitle = 'Welcome back, Dr. ' + last_name + '!';

      return isDsoAdmin ? dsoHeaderTitle : headerTitle;
    }
  };

  /**
   * Updates dashboard case list
   * @function
   * @returns {string} Welcome text
   */
  updateCaseList = (caseList) => {
    if (JSON.stringify(caseList) != JSON.stringify(this.state.caseList)) this.setState({ caseList: caseList });
  };

  /**
   * Display empty list msg
   * @function
   * @returns {JSX} Empty list msg
   */
  displayEmptyListMsg = () => {
    if (this.state.caseList?.length === 0) {
      return <p className="empty-dashboar-list-msg">Looks like you don't have any Action Items!</p>;
    }
  };

  render() {
    const doctor_id = this.getDoctorId();
    const isLoading =
      this.props.doctor_info_loading || this.state.loading || (this.props.doctor_info && Number(doctor_id) !== this.props.doctor_info.account_id);
    const hasError = this.props.doctor_info_error || !this.props.doctor_info;
    const headerTitle = this.getHeaderTitle();
    const showAssignedTo = this.props.doctor_info?.role === 'DSO';
    const actionRequiredStatus = ['Draft Digital Enhancement', 'Draft Submission', 'Review Smile Design', 'Pending Doctor Clarification', 'Upload New Records'];

    if (isLoading) return <CircleLoader fullscreen />;
    if (hasError) return <NotFound />;
    if (this.state.terms) return <CustomerAgreement />;
    return (
      <div>
        <Helmet>
          <title>Dashboard | InBrace Smile Design Studio™</title>
        </Helmet>
        <ContentHeader title={headerTitle} />
        <div className="dashboard-container">
          <CaseListStats caseList={this.state.caseList} history={this.props.history} />
          <div className="dashboard-side">
            <ProviderTierCard doctor_id={doctor_id} />
            <DoctorStats doctor_id={doctor_id} />
            <CardContainer id="dashboard-announcement" className="dashboard-list-container">
              <h3>Announcements</h3>
              <iframe src={getContentURL()} />
            </CardContainer>
            <Activity doctor_id={doctor_id} />
          </div>
          <div className="dashboard-list scrollbar--dark dashboard-case-container" style={{ overflow: this.state.caseList?.length > 6 ? 'auto' : 'hidden' }}>
            <Content
              dashboardList={true}
              hideColumnName={this.state.caseList?.length === 0}
              actionRequiredStatus={actionRequiredStatus}
              rowSize={1000}
              showAssignedTo={showAssignedTo}
              updateCaseList={this.updateCaseList}
              doctorInfo={this.props.doctor_info}
              doctorInfoLoading={isLoading}
            />
            {this.displayEmptyListMsg()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    doctor_info: getDoctorInfo(state),
    doctor_info_loading: getDoctorInfoLoading(state),
    doctor_info_error: getDoctorInfoError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDoctorInfo: fetchDoctorInfo,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
