import React from 'react';
import PropTypes from 'prop-types';

import IPRChartItem from './ipr_chart_item';
import IPRBubble from './ipr_bubble';
import TeethUtils from '../../../../../../common/teeth_utils';

/**
 * Displays the IPR chart
 * @component
 */
function IPRChart(props) {
  const teeth_set = TeethUtils.teethSetInPalmerBySection();
  const missing_teeth = TeethUtils.convertListFromUniversalToPalmer(props.ipr_info.start.missing_teeth ? props.ipr_info.start.missing_teeth : []);
  const iprs = TeethUtils.convertIPRInfoToBubblePosition(props.ipr_info);

  return (
    <div className="ipr-chart">
      <div className="ipr-bubble-area">
        {iprs.upper.map(ipr => (
          <IPRBubble 
            key={`upper_${ipr['x-pos']}_${ipr['y-pos']}`} 
            className={`${ipr['x-pos']} ${ipr['y-pos']}`} 
            value={ipr.value}
          />
        ))}
      </div>

      <table>
        <tbody>
          <tr>
            {teeth_set.upper.map(teeth => (
              <IPRChartItem
                key={teeth}
                baseClass="ipr-chart-top-teeth"
                teeth={teeth}
                missing_teeth={missing_teeth}
              />
            ))}
          </tr>
          <tr>
            {teeth_set.lower.map(teeth => (
              <IPRChartItem
                key={teeth}
                baseClass="ipr-chart-bottom-teeth"
                teeth={teeth}
                missing_teeth={missing_teeth}
              />
            ))}
          </tr>
        </tbody>
      </table>

      <div className="ipr-bubble-area">
        {iprs.lower.map(ipr => (
          <IPRBubble 
            key={`lower_${ipr['x-pos']}_${ipr['y-pos']}`} 
            className={`${ipr['x-pos']} ${ipr['y-pos']}`} 
            value={ipr.value}
          />
        ))}
      </div>
    </div>
  );
}

IPRChart.propTypes = {
  ipr_info: PropTypes.object.isRequired,
};

export default IPRChart;