/**
 * File: content.js - Display the list of accounts in the account list view
 * Copyright: (c) Copyright August 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './dso_account_list.scss';
import '../case_list/content.scss';
// External Libs
import _ from 'lodash';
import Axios from 'axios';
import Pagination from 'react-js-pagination';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Internal Components
import Button from '../components/buttons/button';
import CardContainer from '../components/container/card_container';
import CircleLoader from '../../components/loader/circle_loader';
import ContentHeader from '../components/content_header';
import DivTable from '../components/tables/div_table';
import NoResult from '../case_list/no_result';
import TextBox from '../components/inputs/text_box';

// Internal Functions
import { getRootPath, setTokenHeader, textFieldCheck } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import { getDoctorsIdFromRoute, getSearchValueFromRoute } from '../../common/route';

import { tableRowLink } from '../../common/table';

//Redux
import { getDoctorRole, getAccountLinkId } from '../../redux/reducers/ipp/case_list';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctor_id: '',
      search: '',
      search_phrase: '',
      accounts_raw: [],
      accounts_view: [],
      accounts_sort_method: 'user__first_name',
      accounts_sort_order_ascending: { user__first_name: true, user__last_name: true, user__email: true, user__is_active: true },
      accounts_row_size: 15,
      accounts_active_page: 1,
      accounts_page_range: 5,
      accounts_length: 1,
      is_bottom: false,
    };

    this.displaySearchBar.bind(this);
    this.onChangeSearchAccount.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
  }

  onChangeSearchAccount = (event) => {
    let text = textFieldCheck(event.target.value);
    this.setState({ search_phrase: text });
  };

  setRouteBySearch(text) {
    this.setRoute(text);
    this.setState({ search_phrase: text });
  }

  setRoute(search_phrase) {
    if (search_phrase === '') {
      this.props.history.replace(`/portal/${this.state.doctor_id}/accounts`);
    } else {
      this.props.history.replace(`/portal/${this.state.doctor_id}/accounts?search=${search_phrase}`);
    }
  }

  onKeyPressSearchAccount = (event) => {
    let text = textFieldCheck(event.target.value);

    if (event.key === 'Enter') {
      this.setRouteBySearch(text);
    }
  };

  onSearchButtonClick = (event) => {
    this.setRouteBySearch(this.state.search_phrase);
  };

  onPageChange(active_page) {
    this.setState({
      accounts_active_page: active_page,
      accounts_view: this.getAccountsFromRow(
        this.setOrderByMethod(
          this.getAccountsFromSearch(this.state.accounts_raw, this.state.search),
          this.state.accounts_sort_method,
          this.state.accounts_sort_order_ascending[this.state.accounts_sort_method]
        ),
        active_page,
        this.state.accounts_row_size
      ),
    });
  }

  getAccountsFromRow(accounts_view, page, row_size) {
    const start_index = (Number(page) - 1) * Number(row_size);
    const end_index = start_index + Number(row_size);

    return accounts_view.slice(start_index, end_index);
  }

  getRecentlyShipDaysInMilliseconds() {
    const milli = 1000;
    const seconds = 60;
    const mins_in_hour = 60;
    const hour_in_day = 24;
    const recently_days = 14;

    return new Date().getTime() - milli * seconds * mins_in_hour * hour_in_day * recently_days;
  }

  getAccountsFromSearch(accounts, search_phrase) {
    if (search_phrase === '') {
      return accounts;
    }

    search_phrase = search_phrase.toLowerCase();

    const space_index = search_phrase.lastIndexOf(' ');
    const inital_index = 0;

    let accounts_list = [];
    let search_account_email = '';
    let search_first_name = '';
    let search_last_name = '';

    let phrase_one = search_phrase;
    let phrase_two = '';

    if (space_index >= 0) {
      phrase_one = search_phrase.substring(inital_index, space_index);
      phrase_two = search_phrase.substring(space_index + 1, search_phrase.length);
    }

    for (let i = 0; i < accounts.length; i++) {
      search_first_name = accounts[i].user__first_name.toLowerCase();
      search_last_name = accounts[i].user__last_name.toLowerCase();
      search_account_email = accounts[i].user__email.toLowerCase();

      if (
        (search_first_name.indexOf(phrase_one) >= 0 && search_last_name.indexOf(phrase_two) >= 0) ||
        (search_first_name.indexOf(phrase_two) >= 0 && search_last_name.indexOf(phrase_one) >= 0)
      ) {
        accounts_list.push(accounts[i]);
      } else {
        if (search_first_name.indexOf(search_phrase) >= 0 || search_last_name.indexOf(search_phrase) >= 0 || search_account_email.indexOf(search_phrase) >= 0) {
          accounts_list.push(accounts[i]);
        }
      }
    }

    return accounts_list;
  }

  getStartRange() {
    return this.state.accounts_active_page * this.state.accounts_row_size - this.state.accounts_row_size + 1;
  }

  getEndRange() {
    return this.state.accounts_active_page * this.state.accounts_row_size > this.state.accounts_length
      ? this.state.accounts_length
      : this.state.accounts_active_page * this.state.accounts_row_size;
  }

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    let accounts_sort_order_ascending = this.state.accounts_sort_order_ascending;
    if (sort_method === this.state.accounts_sort_method) {
      //Flip order
      accounts_sort_order_ascending[sort_method] = !accounts_sort_order_ascending[sort_method];
    }
    let accounts_view = this.getAccountsFromRow(
      this.setOrderByMethod(this.getAccountsFromSearch(this.state.accounts_raw, this.state.search), sort_method, accounts_sort_order_ascending[sort_method]),
      this.state.accounts_active_page,
      this.state.accounts_row_size
    );

    this.setState({
      accounts_view: accounts_view,
      accounts_sort_method: sort_method,
      accounts_sort_order_ascending: accounts_sort_order_ascending,
    });
  };

  setOrderByMethod(accounts_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'user__first_name':
        accounts_view = this.orderAccountsByFirstName(accounts_view, sort_value);
        break;

      case 'user__last_name':
        accounts_view = this.orderAccountsByLastName(accounts_view, sort_value);
        break;

      case 'user__email':
        accounts_view = this.orderAccountsByAccountEmail(accounts_view, sort_value);
        break;

      case 'user__is_active':
        accounts_view = this.orderAccountsByEnabled(accounts_view, sort_value);
        break;

      default:
    }

    return accounts_view;
  }

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  orderAccountsByDefault(accounts) {
    const sort = this.getSortValue(this.state.accounts_sort_order_ascending['user__first_name']);
    return this.orderAccountsByFirstName(accounts, sort);
  }

  orderAccountsByFirstName(accounts, sort) {
    return _.orderBy(accounts, ['user__first_name'], [sort]);
  }
  orderAccountsByLastName(accounts, sort) {
    return _.orderBy(accounts, ['user__last_name'], [sort]);
  }
  orderAccountsByAccountEmail(accounts, sort) {
    return _.orderBy(accounts, ['user__email'], [sort]);
  }
  orderAccountsByEnabled(accounts, sort) {
    return _.orderBy(accounts, ['user__is_active'], [sort]);
  }

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const doctor_id = getDoctorsIdFromRoute();
    const search = getSearchValueFromRoute();

    Axios.get(`/apiv3/doctor/${doctor_id}/dso`)
      .then(function (res) {
        let accounts_raw = res.data.dso_doctors;
        accounts_raw = accounts_raw.filter(function (account) {
          return account.role !== 'DSO';
        });

        const accounts_view = that.orderAccountsByDefault(that.getAccountsFromSearch(accounts_raw, search));

        that.setState({
          loading: false,
          doctor_id: doctor_id,
          search: search,
          search_phrase: search,
          accounts_raw: accounts_raw,
          accounts_view: that.getAccountsFromRow(accounts_view, that.state.accounts_active_page, that.state.accounts_row_size),
          accounts_length: accounts_view.length,
        });
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  }

  componentDidUpdate() {
    const that = this;
    const state_search = this.state.search;
    const search = getSearchValueFromRoute();
    const sort_method = that.state.accounts_sort_method;
    const accounts_sort_order_ascending = this.state.accounts_sort_order_ascending;
    const active_page = 1;
    const row_size = this.state.accounts_row_size;
    let accounts_view = [];

    if (state_search !== search) {
      accounts_view = that.setOrderByMethod(
        that.getAccountsFromSearch(this.state.accounts_raw, search),
        sort_method,
        accounts_sort_order_ascending[sort_method]
      );

      that.setState({
        accounts_view: that.getAccountsFromRow(accounts_view, active_page, row_size),
        accounts_active_page: active_page,
        accounts_length: accounts_view.length,
        search: search,
        search_phrase: search,
      });
    }
  }

  onAccountTrClick = (event) => {
    const doctor_id = event.currentTarget.dataset.id;
    let that = this;

    const block = event.target && event.target.className && event.target.className.indexOf('block-tr') >= 0;

    if (!block) {
      const rootPath = getRootPath(this.props.history.location.pathname);
      const pathname = `${rootPath}/accounts/edit/${doctor_id}`;

      tableRowLink(event, pathname, that.props, false);
    }
  };

  redirectToAddAccount = (event) => {
    const rootPath = getRootPath(this.props.history.location.pathname);
    const pathname = `${rootPath}/accounts/add`;

    this.props.history.push(`${pathname}`);
  };

  displaySearchBar() {
    return (
      <div className="search">
        <TextBox
          placeholder="Search"
          onChange={this.onChangeSearchAccount}
          onKeyPress={this.onKeyPressSearchAccount}
          value={this.state.search_phrase}
          icon={'fa fa-search'}
          onIconClick={this.onSearchButtonClick}
        ></TextBox>
      </div>
    );
  }

  render() {
    const meta_data = ['id', 'user__first_name', 'user__last_name', 'user__email', 'user__is_active'];
    const table_data = [
      {
        id: 'user__first_name',
        label: 'First Name',
        col_width: '25%',
        display: true,
        can_sort: true,
        data_fields: ['user__first_name'],
      },
      {
        id: 'user__last_name',
        label: 'Last Name',
        col_width: '25%',
        display: true,
        can_sort: true,
        data_fields: ['user__last_name'],
      },
      {
        id: 'user__email',
        label: 'Account Email',
        col_width: '35%',
        display: true,
        data_fields: ['user__email'],
        can_sort: true,
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field];
          });

          return <span className="fs-exclude">{field_values[0]}</span>;
        },
      },
      {
        id: 'user__is_active',
        label: 'Enabled',
        col_width: '15%',
        display: true,
        can_sort: true,
        data_fields: ['user__is_active'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? 'Yes' : 'No';
          });

          return field_values[0];
        },
      },
    ];

    const mobile_data = [
      {
        id: 'user_name',
        data_fields: ['user__first_name', 'user__last_name'],
        data_function: (props, data, fields) => {
          let field_values = fields.map((field, index) => {
            return data[field];
          });

          return field_values.join(' ');
        },
      },
      {
        id: 'user__email',
        new_row: true,
        data_fields: ['user__email'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field];
          });

          return <span className="fs-exclude">{field_values[0]}</span>;
        },
      },
      {
        id: 'user__is_active',
        new_row: true,
        data_fields: ['user__is_active'],
        data_function: (props, data, fields) => {
          const field_values = fields.map((field) => {
            return data[field] ? 'Account Enabled' : 'Account Disabled';
          });

          return field_values[0];
        },
      },
    ];

    if (this.state.loading) {
      return <CircleLoader fullscreen={true} />;
    } else if (this.state.accounts_view.length === 0 && this.state.search && this.state.search !== '') {
      return (
        <>
          <Helmet>
            <title>Accounts | InBrace Smile Design Studio™</title>
          </Helmet>
          <ContentHeader title="Accounts">
            <Button className="float--right" onClick={this.redirectToAddAccount}>
              <i className="fa fa-plus-square" aria-hidden="true" /> Create New Account
            </Button>
          </ContentHeader>
          <div className="list-content">
            <div className="statuses">{this.displaySearchBar()}</div>
            <div className="statuses-result-container">
              <div className="statuses-result">
                <NoResult query={this.state.search} filter={this.state.search_filter} type="accounts" />
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>Accounts{this.state.search ? ' - ' + this.state.search : ''} | InBrace Smile Design Studio™</title>
          </Helmet>
          <ContentHeader title="Accounts">
            <Button className="float--right" onClick={this.redirectToAddAccount}>
              <i className="fa fa-plus-square" aria-hidden="true" /> Create New Account
            </Button>
          </ContentHeader>
          <div className="list-content">
            <div className="statuses">{this.displaySearchBar()}</div>

            <div className="statuses-result-container">
              {this.state.accounts_view.length === 0 ? (
                <div className="statuses-result">
                  <CardContainer type="top-border" className="hide-on-mobile--top-border">
                    <div className="div-table--heading-container">No accounts found</div>
                  </CardContainer>
                </div>
              ) : (
                <>
                  <div className="statuses-result">
                    <CardContainer type="top-border" className="hide-on-mobile--top-border">
                      <DivTable
                        meta_data={meta_data}
                        table_data={table_data}
                        mobile_data={mobile_data}
                        field_data={this.state.accounts_view}
                        sort_method={this.state.accounts_sort_method}
                        sort_order_ascending={this.state.accounts_sort_order_ascending}
                        onSortClick={this.onSortClick}
                        onCaseTrClick={this.onAccountTrClick}
                        less_space={true}
                      />
                    </CardContainer>
                  </div>
                  <div className="pagination__container">
                    <div className="pagination__number">
                      {this.getStartRange()}-{this.getEndRange()} of {this.state.accounts_length} Accounts
                    </div>
                    <Pagination
                      className={'pagination'}
                      activePage={this.state.accounts_active_page}
                      itemsCountPerPage={this.state.accounts_row_size}
                      totalItemsCount={this.state.accounts_length}
                      pageRangeDisplayed={this.state.accounts_page_range}
                      onChange={this.onPageChange}
                      prevPageText={<i className="fa fa-angle-left" aria-hidden="true"></i>}
                      nextPageText={<i className="fa fa-angle-right" aria-hidden="true"></i>}
                      firstPageText={<i className="fa fa-angle-double-left" aria-hidden="true"></i>}
                      lastPageText={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    doctor_role: getDoctorRole(state),
    account_link_id: getAccountLinkId(state),
  };
};

export default withRouter(connect(mapStateToProps, null)(Content));
