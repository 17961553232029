/**
 * File: teeth_selection.js - A component contains modal content for selecting teeth
 * Copyright: (c) Copyright June 2020 by InBrace
 * Authors: Katie Chen
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './teeth_chart.scss';
// External Libs
import _ from 'lodash';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ArchSelection from './arch_selection';

// Redux Actions
import { onToothSelectAwb } from '../../../redux/actions/awb';
import { onToothSelect, onTextBoxNoteChange, onPresetSelect } from '../../../redux/actions/common/teeth_chart';

// Redux Reducers
import {
  getAwbMissingTeethMeasurementsModalSelectedTeeth,
  getAwbMissingTeethMeasurementsModalHeading,
  getAwbMissingTeethMeasurementsModal,
  getAwb,
} from '../../../redux/reducers/awb';
import {
  getMessage,
  getSelectedTeeth,
  getTextBody,
  getShowArchSelection,
  getTeethSelectorLabel,
  getTreatmentArch,
  getShowTextBox,
  getTextBoxLabel,
  getTextBoxPlaceholder,
  getTextBoxNote,
  getError,
  getDisabledTeeth,
  getShowPresets,
  getPresetSelection,
  getArchDisabled,
} from '../../../redux/reducers/common/teeth_chart';

function TeethArea(props) {
  const isInArray = (teeth, teethSet) => {
    return _.intersection(teethSet, teeth).length > 0;
  };

  const UR = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'];
  const UL = ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'];
  const LR = ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'];
  const LL = ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'];
  const eights = ['UR8', 'UL8', 'LR8', 'LL8'];

  let disabledTeeth = [];

  if (props.disabled_teeth) {
    disabledTeeth = props.disabled_teeth;
  } else {
    if (props.awb_modal) {
      if (props.smartwires.hasUpper && !props.smartwires.hasLower && !props.smartwires.hasLowerST) {
        disabledTeeth = ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1', 'LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8', 'UR8', 'UL8'];
      } else if (!props.smartwires.hasUpper && (props.smartwires.hasLower || props.smartwires.hasLowerST)) {
        disabledTeeth = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1', 'UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8', 'LR8', 'LL8'];
      } else {
        disabledTeeth = eights;
      }
    }

    if (props.enable_8) {
      disabledTeeth = _.difference(disabledTeeth, eights);
    }
  }

  /**
   * Determines if arch should be shown in teeth selector
   * @function
   * @param {String} arch - Upper lower or both
   * @return {Boolean} True or False
   */
  const showArch = (arch) => {
    return !props.treatment_arch || props.treatment_arch === 'both' || props.treatment_arch === arch;
  };

  const teethArray = (teethSet) => {
    return teethSet.map((teeth, ind) => {
      if (isInArray([teeth], disabledTeeth)) {
        return (
          <button key={ind} type="button" className="btn-ib disabled">
            {teeth}
          </button>
        );
      }
      return (
        <button
          key={ind}
          type="button"
          className={isInArray([teeth], props.teeth) ? 'btn-ib active' : 'btn-ib'}
          onClick={(e) => props.onClick(e, ind)}
          value={teeth}
        >
          {teeth}
        </button>
      );
    });
  };

  /**
   * Displays teeth array for upper or lower
   * @function
   * @param {String} arch - Upper or lower
   * @return {JSX} JSX for text box
   */
  const displayTeethArray = (arch) => {
    return (
      <div>
        <div className="teeth-group-inline">{teethArray(arch === 'upper' ? UR : LR)}</div>
        <div className="teeth-group-inline">{teethArray(arch === 'upper' ? UL : LL)}</div>
      </div>
    );
  };

  /**
   * Displays text box
   * @function
   * @param {Object} props - Passed props
   * @return {JSX} JSX for text box
   */
  const displayTextBox = (props) => {
    return (
      <div className="teeth-container__row">
        <div className="bold-text grey-text">{props.text_box_label}</div>
        <textarea
          className={`form-control teeth-container__note${props.error.is_error && props.error.type === 'text_box' ? ' teeth-container__note__error' : ''}`}
          placeholder={props.text_box_placeholder}
          rows={3}
          maxLength={750}
          onChange={props.onTextBoxNoteChange}
          value={props.text_box_note}
        />
      </div>
    );
  };

  /**
   * Displays single preset button
   * @function
   * @param {Number} n - Number of teeth from preset
   * @param {Object} props - Passed props
   * @return {JSX} JSX preset button
   */
  const makePresetButton = (n, props) => {
    return (
      <button key={n} type="button" onClick={() => props.onPresetSelect(n)} className={'btn-ib' + (n === props.preset_selection ? ' btn-ib--active ' : '')}>
        {n}x{n}
      </button>
    );
  };

  /**
   * Displays presets for teeth selection
   * @function
   * @param {Object} props - Passed props
   * @return {JSX} JSX for Presets
   */
  const displayPresets = (props) => {
    return (
      <div className="teeth-container__row teeth-container__presets">
        <span className="grey-text">Presets:</span>
        <div className="btn-bar" role="toolbar">
          {_.range(5, 8)
            .reverse()
            .map((n) => makePresetButton(n, props))}
        </div>
      </div>
    );
  };

  return (
    <div className="teeth-container">
      <span className="title grey-text">
        {props.heading} Click <b>Apply</b> to save your selections.
      </span>
      {props.text_body}
      {props.show_arch_selection && <ArchSelection arch_disabled={props.arch_disabled} />}
      {props.awb_modal ? (
        <React.Fragment>
          <div className="extra-padding-headline-bpp" />
          <div className="missing-teeth-measurements-label">Missing Teeth</div>
        </React.Fragment>
      ) : props.teeth_selector_label ? (
        <div
          className={`teeth-container__row less-margin grey-text bold-text${
            props.error.is_error && props.error.type === 'teeth_selection' ? ' teeth-container__error' : ''
          }`}
        >
          {props.teeth_selector_label}
        </div>
      ) : null}
      {props.show_presets && displayPresets(props)}
      <div className={'selection' + (props.awb_modal ? ' awb' : '')}>
        <div className="teeth-selector">
          {showArch('upper') && displayTeethArray('upper')}
          {showArch('lower') && displayTeethArray('lower')}
        </div>
      </div>
      {props.show_text_box && displayTextBox(props)}
      {props.error.is_error && <div className="teeth-container__row teeth-container__error teeth-container__error__message">{props.error.message}</div>}
    </div>
  );
}

class TeethChart extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  getSmartwires() {
    const smartwires = this.props.awb.ireq_parts;
    let hasUpper = false;
    let hasLower = false;
    let hasLowerST = false;

    for (let smartwire of smartwires) {
      if (smartwire.description.includes('Upper')) {
        hasUpper = true;
      } else if (smartwire.description.includes('Lower') && !smartwire.description.includes('IIa')) {
        hasLower = true;
      } else if (smartwire.description.includes('Lower') && smartwire.description.includes('IIa')) {
        hasLowerST = true;
      }
    }

    return {
      hasUpper: hasUpper,
      hasLower: hasLower,
      hasLowerST: hasLowerST,
    };
  }

  render() {
    return (
      <div className="col-md-12">
        <TeethArea
          awb_modal={this.props.awb_modal}
          heading={this.props.awb_modal ? this.props.awb_heading : this.props.message}
          teeth={this.props.awb_modal ? this.props.awb_selected_teeth : this.props.selected_teeth}
          onClick={this.props.awb_modal ? (e, ind) => this.props.onToothSelectAwb(e.target.value, ind) : (e) => this.props.onToothSelect(e.target.value)}
          smartwires={this.props.awb_modal ? this.getSmartwires() : null}
          enable_8={this.props.enable_8}
          text_body={this.props.text_body}
          treatment_arch={this.props.treatment_arch}
          show_arch_selection={this.props.show_arch_selection}
          arch_disabled={this.props.arch_disabled}
          teeth_selector_label={this.props.teeth_selector_label}
          show_text_box={this.props.show_text_box}
          text_box_label={this.props.text_box_label}
          text_box_placeholder={this.props.text_box_placeholder}
          text_box_note={this.props.text_box_note}
          onTextBoxNoteChange={this.props.onTextBoxNoteChange}
          error={this.props.error}
          disabled_teeth={this.props.disabled_teeth}
          show_presets={this.props.show_presets}
          onPresetSelect={this.props.onPresetSelect}
          preset_selection={this.props.preset_selection}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    awb_modal: getAwbMissingTeethMeasurementsModal(state),
    awb_selected_teeth: getAwbMissingTeethMeasurementsModalSelectedTeeth(state),
    awb_heading: getAwbMissingTeethMeasurementsModalHeading(state),
    awb: getAwb(state),
    selected_teeth: getSelectedTeeth(state),
    message: getMessage(state),
    text_body: getTextBody(state),
    treatment_arch: getTreatmentArch(state),
    show_arch_selection: getShowArchSelection(state),
    arch_disabled: getArchDisabled(state),
    show_text_box: getShowTextBox(state),
    teeth_selector_label: getTeethSelectorLabel(state),
    text_box_label: getTextBoxLabel(state),
    text_box_placeholder: getTextBoxPlaceholder(state),
    text_box_note: getTextBoxNote(state),
    error: getError(state),
    disabled_teeth: getDisabledTeeth(state),
    show_presets: getShowPresets(state),
    preset_selection: getPresetSelection(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onToothSelectAwb: onToothSelectAwb,
      onToothSelect: onToothSelect,
      onTextBoxNoteChange: onTextBoxNoteChange,
      onPresetSelect: onPresetSelect,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TeethChart);
