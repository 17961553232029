import Axios from 'axios';

//Action Imports
import { fetchDdmState } from './ddm_state';
export const MARK_SUCCESS_PENDING = 'MARK_SUCCESS_PENDING';
export const MARK_SUCCESS_SUCCESS = 'MARK_SUCCESS_SUCCESS';
export const MARK_SUCCESS_ERROR = 'MARK_SUCCESS_ERROR';
export const MARK_FAILED_PENDING = 'MARK_FAILED_PENDING';
export const MARK_FAILED_SUCCESS = 'MARK_FAILED_SUCCESS';
export const MARK_FAILED_ERROR = 'MARK_FAILED_ERROR';
export const OPEN_MARK_SUCCESS_MODAL = 'OPEN_MARK_SUCCESS_MODAL';
export const OPEN_MARK_FAILED_MODAL = 'OPEN_MARK_FAILED_MODAL';
export const CLOSE_MARK_SUCCESS_MODAL = 'CLOSE_MARK_SUCCESS_MODAL';
export const CLOSE_MARK_FAILED_MODAL = 'CLOSE_MARK_FAILED_MODAL';

export function openMarkSuccessModal() {
  return {
    type: OPEN_MARK_SUCCESS_MODAL,
  };
}
export function openMarkFailedModal() {
  return {
    type: OPEN_MARK_FAILED_MODAL,
  };
}
export function closeMarkSuccessModal() {
  return {
    type: CLOSE_MARK_SUCCESS_MODAL,
  };
}
export function closeMarkFailedModal() {
  return {
    type: CLOSE_MARK_FAILED_MODAL,
  };
}

export function onConfirmMarkSuccess() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(markAsSuccessPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/force_success`)
      .then((res) => {
        dispatch(markAsSuccessSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(closeMarkSuccessModal());
      })
      .catch((error) => {
        dispatch(markAsSuccessError(error));
        dispatch(closeMarkSuccessModal());
      });
  };
}

export function markAsSuccessPending() {
  return {
    type: MARK_SUCCESS_PENDING,
  };
}
export function markAsSuccessSuccess() {
  return {
    type: MARK_SUCCESS_SUCCESS,
  };
}
export function markAsSuccessError(error) {
  return {
    type: MARK_SUCCESS_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}

export function onConfirmMarkFailed() {
  return (dispatch, getState) => {
    const { ddm_state } = getState().ddmStateReducer;
    const case_id = ddm_state.case_id;

    dispatch(markAsFailedPending());

    Axios.post(`/apiV2/cadaction/${case_id}/ddm/force_failure`)
      .then((res) => {
        dispatch(markAsFailedSuccess());
        dispatch(fetchDdmState(case_id));
        dispatch(closeMarkFailedModal());
      })
      .catch((error) => {
        dispatch(markAsFailedError(error));
        dispatch(closeMarkFailedModal());
      });
  };
}

export function markAsFailedPending() {
  return {
    type: MARK_FAILED_PENDING,
  };
}
export function markAsFailedSuccess() {
  return {
    type: MARK_FAILED_SUCCESS,
  };
}
export function markAsFailedError(error) {
  return {
    type: MARK_FAILED_ERROR,
    error: error,
    session_error: error && error.response && error.response.status === 409,
  };
}
