/**
 * File:reports.js - Component to list all the reports available
 * Copyright: (c) Copyright September 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import '../../components/loader/loader.scss';
import './reports.scss';
// External Libs
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// Internal Components
import NotAvailable from '../not_available/not_available';
import { UserPermissionsContext } from '../../context/user_permission';
// Internal Functions
import { setTokenHeader, getReportFileName } from '../../common/functions';
import { userHasPermission } from '../../common/permission';

// Redux Reducers
import { getShippoReport, getPending } from '../../redux/reducers/shippo/shippo';

// Redux Actions
import { fetchShippoReport } from '../../redux/actions/shippo/shippo';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    setTokenHeader();
    this.props.fetchShippoReport();
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  onRowClick = (event, report_type) => {
    const block = event.target && event.target.className && event.target.className.indexOf('fa fa-download') >= 0;
    const download_block = event.target && event.target.className && event.target.className.indexOf('pull-right csv-download-link') >= 0;
    if (!block && !download_block) {
      if (report_type === 'prpl_report') {
        window.open('/business/portal/production-planning-report', '_blank');
      } else if (report_type === 'shippo_extract') {
        window.open('/business/portal/production-shippo-report', '_blank');
      }
    }
  };

  render() {
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div className="">
              <Helmet>
                <title>Report List | InBrace Business Portal</title>
              </Helmet>
              <div className="main-content reports-page">
                <div className="dark-options reports-heading">
                  <div className="page-heading">Reports</div>
                </div>
                {userHasPermission('REPORTS_VIEW', user_roles_and_permissions.permissions) ? (
                  <div className="table-case-view">
                    <table className="table table-condensed table-striped table-industry">
                      <thead className="table-inbrace-dark-industry">
                        <tr>
                          <th className="caseid-width">Report Name </th>
                          <th className="name-width">Report Type </th>
                        </tr>
                      </thead>
                      <tbody className="table-inbrace-dark-industry">
                        {userHasPermission('REPORTS_SHIPPO_EXTRACT', user_roles_and_permissions.permissions) ? (
                          <tr className="pointer" onClick={(e) => this.onRowClick(e, 'shippo_extract')}>
                            <td className="td-industry-offset caseid-width">Shippo Extract</td>
                            <td>
                              Production
                              {!this.props.shippo_report_pending ? (
                                <CSVLink
                                  filename={getReportFileName('shippo_extract')}
                                  className="pull-right csv-download-link"
                                  headers={this.props.shippo_report.csv_columns}
                                  data={this.props.shippo_report.reports}
                                >
                                  <i className="fa fa-download" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download CSV" />
                                </CSVLink>
                              ) : (
                                <i
                                  className="fa fa-spinner fa-spin pull-right csv-download-link spinner"
                                  aria-hidden="true"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Download CSV"
                                />
                              )}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NotAvailable message="No Reports Available" />
                )}
              </div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shippo_report: getShippoReport(state),
    shippo_report_pending: getPending(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchShippoReport: fetchShippoReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reports));
