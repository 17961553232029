import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CardContainer from '../components/container/card_container';
import ProviderTierProgress from './provider_tier_progress';
import { getDoctorsIdFromRoute } from '../../common/route';
import ProviderTierDetailsItem from './provider_tier_details_item';
import { PROVIDER_TIERS_DETAIL } from './dashboard_helpers';
import NotFound from '../404/not_found';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { hasProviderTier } from './dashboard_helpers';
import { getProviderTier, getProviderTierLoading } from '../../redux/reducers/ipp/dashboard';
import { fetchProviderTier } from '../../redux/actions/ipp/dashboard';
import CircleLoader from '../../components/loader/circle_loader';

function ProviderTierDetails(props) {
  const doctor_id = getDoctorsIdFromRoute();
  let { provider_tier, fetchProviderTier, provider_tier_loading } = props;

  useEffect(() => {
    fetchProviderTier(doctor_id);
  }, [fetchProviderTier, doctor_id]);

  return provider_tier_loading ? (
    <CircleLoader fullscreen={true} />
  ) : hasProviderTier(provider_tier, doctor_id) ? (
    <div>
      <Helmet>
        <title>Provider Tiers and Value | InBrace Smile Design Studio™</title>
      </Helmet>
      <h1 className="content__header">Provider Tiers and Value</h1>
      <ProviderTierProgress provider_tier={provider_tier} doctor_id={doctor_id} />
      <CardContainer className="pad-md provider-cards-container">
        {PROVIDER_TIERS_DETAIL.map((item) => (
          <ProviderTierDetailsItem provider_tier={provider_tier} key={item.name} item={item} />
        ))}
      </CardContainer>
    </div>
  ) : (
    <NotFound />
  );
}

const mapStateToProps = (state) => {
  return {
    provider_tier: getProviderTier(state),
    provider_tier_loading: getProviderTierLoading(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProviderTier: fetchProviderTier,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProviderTierDetails);
