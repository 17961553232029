// External Libs
import React, { Component } from 'react';
/**
 * Displays Automated wirebending override step
 * @component
 * @alias IpOverride
 * @category BPP
 */
class IpOverride extends Component {
  render() {
    return (
      <div className="process_tracker">
        <div className="margin-top-40">
          <hr className="hidden" />
          <div>Automated wire bending process has been overridden...</div>
        </div>
      </div>
    );
  }
}

export default IpOverride;
