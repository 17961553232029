export const TOUR_STEPS = [
  {
    title: 'Welcome to the new Smile Design Viewer!',
    intro: `Get ready for a guided tour to explore the key features and functionalities together. Click 'Next' to get started!`,
    tooltipClass: 'custom-tooltip step-1',
    phase: 1,
  },
  {
    element: '.wasm-dropdown-header',
    title: 'Smile Design Dropdowns',
    intro: 'Use the dropdowns to switch and compare Smile designs.',
    tooltipClass: 'custom-tooltip step-2',
    phase: 1,
  },
  {
    element: '.view-controls-btns',
    title: 'Review Tools',
    intro: 'New and exciting additions like IPR editing, Occlusogram and Tooth Movement Table (TMT) are now available!',
    tooltipClass: 'custom-tooltip step-3',
    phase: 1,
  },
  {
    element: '.wasm-view-controls',
    title: 'View Controls',
    intro: 'Adjust view angles, transparency, and arch visibility for a better design visualization.',
    tooltipClass: 'custom-tooltip step-4',
    phase: 1,
  },
  {
    element: '.panel-menu-accordian-btn',
    title: 'Collapsible Sidebar',
    intro: 'Adjust arrow to optimize space when reviewing Smile Designs.',
    tooltipClass: 'custom-tooltip step-5',
    phase: 1,
  },
  {
    element: '.wasm-help-icon',
    title: 'Help',
    intro: 'Click here to see the tour and hints again.',
    tooltipClass: 'custom-tooltip step-6',
    phase: 1,
  },
  {
    title: 'You have now completed the virtual tour!',
    intro: 'To revisit any part of the tour, simply click the “Help” icon at the bottom right corner of this page.',
    tooltipClass: 'custom-tooltip step-7',
    phase: 1,
  },
  {
    title: '🎉 New Features Now Available!',
    intro: 'We have added new features to enhance your design process. Discover helpful hints for the latest updates.',
    tooltipClass: 'custom-tooltip step-8',
    phase: 2,
  },
];

export const FIRST_HINTS = [
  {
    element: '.photo-icon',
    hint: `<h3>Records</h3>Click to view your patient’s photos and Smile Design side-by-side. Tip: Photos can also be viewed in a pop-up window.`,
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '.revise-btn',
    hint: 'Select “Revise” to access new 3D editing controls and improved Smile Design modification capabilities.',
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '#tmt',
    hint: '<h3>Movements Table</h3>Numerical display of movements from malocclusion to final Smile Design.',
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '#bolton',
    hint: '<h3>Bolton Analysis</h3>Use the Bolton Analysis Tool to measure tooth size discrepancies.',
    hintPosition: 'top-right',
    phase: 2,
  },
  {
    element: '#bite',
    hint: '<h3>Bite Visualization</h3>Visualize the design with or without bite adjustment.',
    hintPosition: 'top-right',
    phase: 2,
  },
  {
    element: '.wasm-view-initial-stage',
    hint: 'Hover to view Initial Model.',
    hintPosition: 'top-right',
    phase: 2,
  },
];

export const SECOND_HINTS = [
  {
    element: '.photo-icon',
    hint: `<h3>Records</h3>Click to view your patient’s photos and Smile Design side-by-side. Tip: Photos can also be viewed in a pop-up window.`,
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '#ipr',
    hint: '<h3>IPR/Spacing</h3>Click to view and modify IPR/Spacing values.',
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '.wasm-inc-movement-title-bar.header',
    hint: 'Click “+” icon, select a tooth on 3D model and modify values for precise tooth movement.',
    hintPosition: 'top-left',
    phase: 1,
  },
  {
    element: '.wasm-sidebar-approval-buttons .button--danger',
    hint: 'Click here to review your changes.',
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '#reset',
    hint: '<h3>Reset</h3>Reset or control changes to your modifications.',
    hintPosition: 'top-right',
    phase: 1,
  },
  {
    element: '#save-draft',
    hint: '<h3>Auto Save</h3>Your progress is automatically saved. You can still save manually if needed.',
    hintPosition: 'top-right',
    phase: 2,
  },
  {
    element: '.emscripten',
    hint: `<h3>Group, Lock, Reset</h3>
    <ul>
      <li>Hold the (Ctrl) key for multiple selection.</li>
      <li>Right-click to lock or reset teeth to malocclusion.</li>
    </ul>
    View video below to learn more!
    <video autoplay disablepictureinpicture loop muted crossorigin playsinline width="260" preload="metadata" disableremoteplayback x-webkit-airplay="deny">
      <source src="https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/videos/product-tour-tooth-func.mp4" type="video/mp4">
    </video>`,
    hintPosition: 'middle-middle',
    phase: 2,
  },
  {
    element: '#ipr',
    hint: '<h3>New IPR Feature</h3>New feature to remove existing IPR.',
    hintPosition: 'top-right',
    phase: 2,
  },
];

export const INCREMENTAL_MOVEMENT_HINTS = [
  {
    element: '#occlusal',
    hint: `<h3>Single-Axis Controls</h3>
    <ul class="upper-greek-list">
      <li>Click on the tooth</li>
      <li>Click on Incremental Movement Icon to activate single-axis</li>
    </ul>
    <video autoplay disablepictureinpicture loop muted crossorigin playsinline width="260" preload="metadata" disableremoteplayback x-webkit-airplay="deny">
      <source src="https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/videos/product-tour-single-axis.mp4" type="video/mp4">
    </video>`,
    hintPosition: 'top-right',
    phase: 2,
  },
  {
    element: '#mesialhinge',
    hint: `<h3>Hinge Movements</h3>Use hinge movements for enhanced precision.
    <video autoplay disablepictureinpicture loop muted crossorigin playsinline width="260" preload="metadata" disableremoteplayback x-webkit-airplay="deny">
      <source src="https://${process.env.REACT_APP_S3_BUCKET}.s3.us-west-2.amazonaws.com/videos/product-tour-hinge.mp4" type="video/mp4">
    </video>`,
    hintPosition: 'top-right',
    phase: 2,
  },
];

export const EXTRA_HINTS = [
  {
    element: '.wasm-bottom-container-title',
    hint: 'Select a cell in the table and directly modify values.',
    hintPosition: 'top-right',
    phase: 1,
  },
];
