import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Displays expedite icon
 * @component
 */
function CaseListExpediteIcon() {
  return (
    <span className="expedited-icon">
      <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
    </span>
  );
}

export default CaseListExpediteIcon;
