import React from 'react';
import PropTypes from 'prop-types';
import ImageViewer from '../../../../../../doctor/components/record_viewer/image_viewer';

/**
 * Displays the records image
 * @component
 */
function PaneViewerRecordsImage(props) {
  const { record, pane, position, setPosition, zoomIn, zoomOut } = props;
  const image_props = record[`pane_${pane}`];
  const src = record['src'];

  return (
    <div className="records-image">
      <ImageViewer src={src} position={position} setPosition={setPosition} zoomIn={zoomIn} zoomOut={zoomOut} {...image_props} />
    </div>
  );
}

PaneViewerRecordsImage.propTypes = {
  record: PropTypes.object.isRequired,
  pane: PropTypes.number.isRequired,
};

export default PaneViewerRecordsImage;
