import { MeshStandardMaterial, MeshPhysicalMaterial, LessEqualDepth, LessDepth, TextureLoader } from 'three';
import { versionGte } from './functions';

const gumTexture = new TextureLoader().load('/static/img/recolor_gum_texture_f2.png');
const teethTexture = new TextureLoader().load('/static/img/recolor_tooth_texture_reduced.png');

const matGums = new MeshPhysicalMaterial({ color: 0xd37575, roughness: 0.1, reflectivity: 0.1 });
const matTeeth = new MeshStandardMaterial({ color: 0xfffcfb, roughness: 0.05, depthFunc: LessEqualDepth });

const matGumsWTexture = new MeshPhysicalMaterial({ color: 0xffd5de, roughness: 0.1, reflectivity: 0.05, map: gumTexture });
const matTeethWTexture = new MeshStandardMaterial({ color: 0xfffcfb, roughness: 0.05, map: teethTexture, depthFunc: LessEqualDepth });

const matOver = new MeshStandardMaterial({ color: 0x78adeb, roughness: 0.05, map: teethTexture, depthFunc: LessDepth });

/**
 * Retrieves appropriate mesh material base on version
 * @function
 * @param {String} version - The version from ipr
 * @return {Object} Materials
 */
function getMat(version) {
  version = version ? version : '';
  const hasUVMapping = versionGte(version, '0.9.0');
  if (hasUVMapping) {
    return { matGums: matGumsWTexture, matTeeth: matTeethWTexture };
  } else {
    return { matGums, matTeeth };
  }
}

export { getMat, matGums, matTeeth, matOver, matGumsWTexture, matTeethWTexture };
