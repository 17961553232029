// External Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Viewer } from '../../../viewer/Viewer';
import StepSelection from './viewer_controls/step_selection';
import SelectionViewControls from './viewer_controls/selection_view_controls';
import SelectionBarBottom from './selection_bar/selection_bar_bottom';
import IPRWindow from './ipr/ipr_window';
import ViewerOpacityController from './viewer_controls/viewer_opacity_controller';

import { getViewerOptions } from '../../../../../redux/reducers/setup_viewer/setup_viewer';
import { onChangeJaw, onChangeStep, onChangeView, onToggleOption, resetView } from '../../../../../redux/actions/setup_viewer/setup_viewer';

import { clamp, getArchOpacityTooltip } from '../../common/functions';

class Viewer3D extends Component {
  state = {
    teeth_opacity_val: 50,
    teeth_opacity_name: `opacity-${this.props.pane}`,

    super_imposition_val: 80,
    super_imposition_rate: 100, // milliseconds
    super_imposition_timeout: undefined,
  };

  componentDidMount() {
    this.viewer = new Viewer(this.container, this.props.pane, this.props.resetView);
    this.viewer.start();
    this.changeSceneMeshes();
    this.applyNewViewOptions();
  }

  componentDidUpdate(prevProps) {
    const modelChanged = prevProps.steps !== this.props.steps;
    const iprChanged = prevProps.ipr !== this.props.ipr;
    const splitControlChanged = prevProps.split !== this.props.split;
    const collapseTriggered = prevProps.side_collapse !== this.props.side_collapse;
    const viewOptionsChanged = prevProps.viewOptions !== this.props.viewOptions;

    if (modelChanged || iprChanged) {
      this.changeSceneMeshes();
      this.applyNewViewOptions();
    }

    if (splitControlChanged || collapseTriggered) {
      this.viewer.updateViewerSizeToWindowSize();
    }

    if (viewOptionsChanged) {
      this.applyNewViewOptions();
    }
  }

  componentWillUnmount() {
    this.viewer.dispose();
  }

  /**
   * Loads a setup's meshes into the viewer
   * @function
   */
  changeSceneMeshes = () => {
    const { steps, ipr, setup_num } = this.props;
    const { step } = this.props.viewOptions[this.props.pane];
    if (step !== 'first' && step !== 'last') return;

    const setupMeshes = {};
    for (const meshStep of Object.keys(steps)) {
      setupMeshes[meshStep] = {
        maxGum: steps[meshStep].max.gums,
        maxTeeth: steps[meshStep].max.teeth,
        manTeeth: steps[meshStep].man.teeth,
        manGum: steps[meshStep].man.gums,
      };
    }

    this.viewer.start();
    this.viewer.loadSetupMeshes(setup_num, setupMeshes, ipr);
  };

  /**
   * Handles clicking on steps
   * @function
   * @param {String} step - Step to change to
   */
  onChangeStep = (step) => {
    if (this.props.isSmileSimulationSetup) this.props.onSmileChangeStep(step, this.props.pane);
    else this.props.onChangeStep(step, this.props.pane);
  };

  /**
   * Handles clicking on views
   * @function
   * @param {String} newView - View to change to
   */
  onChangeView = (newView) => {
    this.props.onChangeView(newView, this.props.pane);
  };

  /**
   * Handles clicking on jaws
   * @function
   * @param {String} newJaw - Jaw to change to
   */
  onChangeJaw = (newJaw) => {
    this.props.onChangeJaw(newJaw, this.props.pane);
  };

  /**
   * Handles toggle options
   * @function
   * @param {String} option - Option to toggle
   */
  onToggleOption = (option) => {
    this.props.onToggleOption(option, this.props.pane);
  };

  /**
   * Applies the new view options to the viewer
   * @function
   */
  applyNewViewOptions = () => {
    const viewer_options = {
      ...this.props.viewOptions[this.props.pane],
      superimpose_opacity: this.state.super_imposition_val / 100,
      teeth_opacity: this.state.teeth_opacity_val / 100,
    };
    this.viewer.setViewOptions(viewer_options);
  };

  // Super Imposition Controller
  //----------------------------------------------------------------------------

  /**
   * Cleans up tooltip by hiding
   * @function
   */
  tooltipCleanUp = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    window.$(`[data-toggle="${this.state.teeth_opacity_name}"]`).tooltip('hide');
    window.document.activeElement.blur();
  };

  /**
   * Cleans up timeout on superimposition
   * @function
   */
  timeoutCleanUp = () => {
    clearTimeout(this.state.super_imposition_timeout);
  };

  /**
   * Sets the superimposition opacity value
   * @function
   * @param {Number} val - The value to set to
   */
  setSuperImpositionValue = (val) => {
    val = clamp(val, 0, 100);
    this.setState({ super_imposition_val: val }, this.applyNewViewOptions);
  };

  /**
   * Handles incrementing superimpose opacity
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionPlus = (e) => {
    this.setSuperImpositionValue(this.state.super_imposition_val + 1);
  };

  /**
   * Handles incrementing superimpose opacity
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionMinus = (e) => {
    this.setSuperImpositionValue(this.state.super_imposition_val - 1);
  };

  /**
   * Handles pressing down plus button in superimposition
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionPlusMouseDown = (e) => {
    this.onSuperImpositionPlus();
    this.setState({
      super_imposition_timeout: setTimeout(this.onSuperImpositionPlusMouseDown, this.state.super_imposition_rate),
      super_imposition_rate: this.state.super_imposition_rate / 2,
    });
  };

  /**
   * Handles pressing down minus button in superimposition
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionMinusMouseDown = (e) => {
    this.onSuperImpositionMinus();
    this.setState({
      super_imposition_timeout: setTimeout(this.onSuperImpositionMinusMouseDown, this.state.super_imposition_rate),
      super_imposition_rate: this.state.super_imposition_rate / 2,
    });
  };

  /**
   * Handles releasing buttons in superimposition
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionMouseUp = (e) => {
    this.timeoutCleanUp();
    this.tooltipCleanUp();

    this.setState({
      super_imposition_rate: 100,
    });
  };

  /**
   * Handles changing superimpose opacity with slider
   * @function
   * @param {Object} e - The click event
   */
  onSuperImpositionChange = (e) => {
    this.setSuperImpositionValue(e && e.target && e.target.value ? parseInt(e.target.value) : 100);
    this.timeoutCleanUp();
    this.tooltipCleanUp();
  };

  // Teeth Opacity Controller
  //----------------------------------------------------------------------------

  /**
   * Handles changing teeth arch opacity with slider
   * @function
   * @param {Object} e - The click event
   */
  onTeethOpacityChange = (e) => {
    let value = e && e.target && e.target.value ? parseInt(e.target.value) : 50;
    value = clamp(value, 0, 100);
    this.setState({ teeth_opacity_val: value }, this.applyNewViewOptions);

    const msg = getArchOpacityTooltip(value);
    window.$(`[data-toggle="${this.state.teeth_opacity_name}"]`).attr('data-original-title', msg).tooltip('show');
  };

  /**
   * Shows the opacity tooltip
   * @function
   */
  opacityTooltipShow = () => {
    window.$(`[data-toggle="${this.state.teeth_opacity_name}"]`).tooltip('show');
  };

  render() {
    const viewOptions = this.props.viewOptions[this.props.pane];
    const availableSteps = Object.keys(this.props.steps);
    return (
      <>
        <div className="viewer-controls-container">
          <SelectionViewControls view={viewOptions.view} jaw={viewOptions.jaw} onChangeView={this.onChangeView} onChangeJaw={this.onChangeJaw} />
          <ViewerOpacityController
            name={this.state.teeth_opacity_name}
            value={this.state.teeth_opacity_val}
            title={getArchOpacityTooltip(this.state.teeth_opacity_val)}
            onTeethOpacityChange={this.onTeethOpacityChange}
            tooltipCleanUp={this.tooltipCleanUp}
            showTooltip={this.opacityTooltipShow}
            disabled={viewOptions.superimpose}
          />
          <div className="viewer-controls-lower-container">
            <StepSelection
              steps={availableSteps}
              active={viewOptions.step}
              superimpose={viewOptions.superimpose}
              opacity={this.state.super_imposition_val}
              onClick={this.onChangeStep}
              isSmileSimulationSetup={this.props.isSmileSimulationSetup}
              split={this.props.split}
            />
          </div>
        </div>

        <div id={`viewer-${this.props.pane}`} className="viewer-3d" style={{ width: '100%', height: '100%' }} ref={(ref) => (this.container = ref)} />
        <div id={`grid-size-${this.props.pane}`} className="grid-size" style={{ visibility: viewOptions.grid ? 'visible' : 'hidden' }}>
          0 mm
        </div>
        <IPRWindow {...this.props} showIPR={viewOptions.ipr} />

        <SelectionBarBottom
          {...viewOptions}
          onToggleOption={this.onToggleOption}
          super_imposition_val={this.state.super_imposition_val}
          onSuperImpositionPlusMouseDown={this.onSuperImpositionPlusMouseDown}
          onSuperImpositionMinusMouseDown={this.onSuperImpositionMinusMouseDown}
          onSuperImpositionMouseUp={this.onSuperImpositionMouseUp}
          onSuperImpositionChange={this.onSuperImpositionChange}
          isSmileSimulationSetup={this.props.isSmileSimulationSetup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    viewOptions: getViewerOptions(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onChangeStep: onChangeStep,
      onChangeView: onChangeView,
      onChangeJaw: onChangeJaw,
      onToggleOption: onToggleOption,
      resetView: resetView,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Viewer3D);
