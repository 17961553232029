import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../../components/modal/modal';
import SecurityClearance from '../../components/security/security_clearance';

// Redux
import {
  fetchProfilePicture,
  openUploadPictureModal,
  closeUploadPictureModal,
  saveProfilePictureReposition,
  openRepositionProfilePictureModal,
  openRepositionProfilePictureDsoDoctorModal,
  closeRepositionProfilePictureModal,
  openProfilePictureMenu,
  closeProfilePictureMenu,
  openDeletePictureModal,
  closeDeletePictureModal,
  setProfileImage,
  onDeleteProfilePicture,
  onProfilePictureUpload,
} from '../../redux/actions/ipp/account_settings';
import {
  getProfileImage,
  getProfileMetadata,
  getUploadPictureModal,
  getRepositionProfilePictureModal,
  getProfilePictureMenu,
  getDeletePictureModal,
  getUploadPicturePending,
  getUploadPictureError,
  getInvalidProfilePictureFormatError,
  getInvalidProfilePictureSize,
  getProfileImageDsoDoctor,
  getProfileMetadataDsoDoctor,
} from '../../redux/reducers/ipp/account_settings';

/**
 * Shows profile picture in account page on IPP
 *
 * @component
 * @alias DoctorProfilePicture
 * @category IPP
 */
class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile_picture_width_in_px: 100,
    };

    this.inputReference = React.createRef();
    this.getInitialsFromName.bind(this);
    this.onReupload = this.onReupload.bind(this);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    const open_reposition_modal = false;
    this.props.fetchProfilePicture(this.props.doctor_id, this.props.is_edit_dso_doctor_account_page, open_reposition_modal, this);
  }

  /**
   * Gets the doctor's initials
   *
   * @function
   * @return {string} - Doctor's initials
   */
  getInitialsFromName() {
    let initials = '';

    if (this.props.name) {
      const fullname = this.props.name.split(' ');

      if (fullname.length > 1) {
        initials = fullname[0][0].toUpperCase() + fullname[fullname.length - 1][0].toUpperCase();
      }
    }

    return initials;
  }
  /**
   * Handles event when user clicks on camera icon to upload a profile picture
   *
   * @function
   */
  fileUploadAction = () => {
    this.inputReference.current.click();
  };

  /**
   * Handles when user chooses to reupload their profile picture
   *
   * @function
   */
  onReupload() {
    this.fileUploadAction();
    this.props.closeProfilePictureMenu();
  }

  /**
   * Retrieves error message for modal when the user uploads an invalid file type
   *
   * @function
   * @return {JSX} - Returns JSX element with error message
   */
  getInvalidProfilePictureFormatErrorMessage() {
    return (
      <div>
        The file type is not supported.
        <br />
        Please upload a JPG or PNG file and try again.
      </div>
    );
  }

  render() {
    return (
      <>
        {(this.props.is_edit_dso_doctor_account_page && this.props.profile_image_dso_doctor) ||
        (!this.props.is_edit_dso_doctor_account_page && this.props.profile_image) ? (
          <canvas width={this.state.profile_picture_width_in_px} height={this.state.profile_picture_width_in_px} ref={this.canvasRef}></canvas>
        ) : (
          <div className="account__initials">{this.getInitialsFromName()}</div>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={this.inputReference}
          onChange={(e) => this.props.onProfilePictureUpload(e, this.props.doctor_id, this)}
          accept="image/jpg,image/jpeg,image/png"
        />
        {this.props.edit_picture ? (
          <>
            <SecurityClearance mode="ALL" permission_list={['IPP_EDIT']}>
              <div
                className="account__camera"
                onClick={
                  (!this.props.is_edit_dso_doctor_account_page && this.props.profile_image) ||
                  (this.props.is_edit_dso_doctor_account_page && this.props.profile_image_dso_doctor)
                    ? this.props.profile_picture_menu
                      ? this.props.closeProfilePictureMenu
                      : this.props.openProfilePictureMenu
                    : this.fileUploadAction
                }
              >
                <i className="fa fa-camera" aria-hidden="true"></i>
              </div>
            </SecurityClearance>
            {this.props.profile_picture_menu && (
              <div className="profile-picture-menu">
                <ul>
                  <li onClick={this.onReupload}>
                    <i className="fa fa-upload" />
                    Upload
                  </li>
                  <li
                    onClick={
                      this.props.is_edit_dso_doctor_account_page
                        ? this.props.openRepositionProfilePictureDsoDoctorModal
                        : this.props.openRepositionProfilePictureModal
                    }
                  >
                    <i className="fa fa-arrows" />
                    Reposition
                  </li>
                  <li onClick={() => this.props.openDeletePictureModal(this)}>
                    <i className="fa fa-trash" />
                    Delete
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : null}

        {this.props.upload_picture_modal ? (
          <Modal
            preset="profile-picture"
            theme="ipp"
            header={this.props.upload_picture_error || this.props.invalid_profile_picture_format_error || this.props.invalid_profile_picture_size}
            header_text={
              this.props.upload_picture_error ? 'Upload Failed' : this.props.invalid_profile_picture_format_error ? 'Unsupported File Type' : 'Image too small'
            }
            message_text_class={'center-text'}
            message_text={
              this.props.upload_picture_error
                ? 'There was an upload error. Please try again.'
                : this.props.invalid_profile_picture_format_error
                ? this.getInvalidProfilePictureFormatErrorMessage()
                : this.props.invalid_profile_picture_size
                ? 'Unable to upload image. Please upload a larger image.'
                : 'Uploading...'
            }
            in_progress={this.props.upload_picture_pending}
            in_progress_text="Uploading..."
            close_btn={false}
            confirm_btn={this.props.upload_picture_error || this.props.invalid_profile_picture_format_error || this.props.invalid_profile_picture_size}
            confirm_btn_text="OK"
            onConfirmButtonClick={() => this.props.closeUploadPictureModal(this)}
          />
        ) : null}
        {this.props.delete_picture_modal ? (
          <Modal
            preset="delete-profile-picture"
            theme="ipp"
            header_text="Delete Profile Picture"
            confirm_btn_text="Delete"
            close_btn_text="Cancel"
            onConfirmButtonClick={() => this.props.onDeleteProfilePicture(this.props.doctor_id, this)}
            onCloseButtonClick={this.props.closeDeletePictureModal}
            is_edit_dso_doctor_account_page={this.props.is_edit_dso_doctor_account_page}
          />
        ) : null}
        {this.props.reposition_profile_picture_modal ? (
          <Modal
            preset="reposition-profile-picture"
            modal_size_class="modal-lg"
            theme="ipp"
            header_text="Profile Picture"
            confirm_btn_text="Save"
            close_btn_text="Cancel"
            onConfirmButtonClick={() => this.props.saveProfilePictureReposition(this.props.doctor_id, this)}
            onCloseButtonClick={() => this.props.closeRepositionProfilePictureModal(this.props.doctor_id, this)}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile_image: getProfileImage(state),
    profile_image_dso_doctor: getProfileImageDsoDoctor(state),
    profile_metadata: getProfileMetadata(state),
    profile_metadata_dso_doctor: getProfileMetadataDsoDoctor(state),
    upload_picture_modal: getUploadPictureModal(state),
    reposition_profile_picture_modal: getRepositionProfilePictureModal(state),
    profile_picture_menu: getProfilePictureMenu(state),
    delete_picture_modal: getDeletePictureModal(state),
    upload_picture_error: getUploadPictureError(state),
    upload_picture_pending: getUploadPicturePending(state),
    invalid_profile_picture_format_error: getInvalidProfilePictureFormatError(state),
    invalid_profile_picture_size: getInvalidProfilePictureSize(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchProfilePicture: fetchProfilePicture,
      openUploadPictureModal: openUploadPictureModal,
      closeUploadPictureModal: closeUploadPictureModal,
      saveProfilePictureReposition: saveProfilePictureReposition,
      openRepositionProfilePictureModal: openRepositionProfilePictureModal,
      openRepositionProfilePictureDsoDoctorModal: openRepositionProfilePictureDsoDoctorModal,
      closeRepositionProfilePictureModal: closeRepositionProfilePictureModal,
      openProfilePictureMenu: openProfilePictureMenu,
      closeProfilePictureMenu: closeProfilePictureMenu,
      openDeletePictureModal: openDeletePictureModal,
      closeDeletePictureModal: closeDeletePictureModal,
      setProfileImage: setProfileImage,
      onDeleteProfilePicture: onDeleteProfilePicture,
      onProfilePictureUpload: onProfilePictureUpload,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
