export const imageTypes = {
  jpeg: {
    format: 'image/jpeg',
    extension: 'jpeg'
  }
}

export default class ImageTransformer {

  /**
   * Updates the file extension of a given fileName to the target extension.
   *
   * @function updateFileExtension
   * @param {string} fileName - The name of the file including its current extension.
   * @param {string} [target='jpeg'] - The target extension to update to.
   * @returns {string} The fileName with the updated extension.
   */
  updateFileExtension = (fileName, target='jpeg') => {
    const splitName = fileName.split('.')
    splitName.pop();
    splitName.push(target)
    return splitName.join('.')
  }

  /**
   * Transforms an image to JPEG format.
   *
   * @function transformImageToJPEG
   * @param {File} image - The image file to be transformed.
   * @param {{ format, extension }} targetType - The image file to be transformed.
   * @returns {Promise<File>} A Promise that resolves to the transformed image file in JPEG format.
   */
  transformImage = (image, targetType = imageTypes.jpeg) => {
    const newName = this.updateFileExtension(image.name, targetType.extension)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          // Create an empty canvas with the same dimensions as the image
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image onto the empty canvas
          ctx.drawImage(img, 0, 0);

          // Convert canvas content to JPEG
          canvas.toBlob((blob) => {
            const convertedImage = new File([blob], newName, { type: targetType.format });
            resolve(convertedImage);
          }, targetType.format, 1);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(image);
    });
  };

  /**
   * Transforms images with specific extensions to JPEG format.
   *
   * @async
   * @function transformImagesToCorrectType
   * @param {File[]} files - An array of image files to be transformed.
   * @param {string[]} extensionsToConvert - An array of strings representing the extensions that should be converted
   * @returns {Promise<File[]>} A Promise that resolves to an array of transformed image files in JPEG format.
   */
  transformImagesToJPEG = async (files, extensionsToConvert = ['jfif', 'jpe']) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const extension = file.name.split('.').pop().toLowerCase();
      if (extensionsToConvert.includes(extension)) {
        files[i] = await this.transformImage(file)
      }
    }

    return files;
  }
}