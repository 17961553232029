import _ from 'lodash';

/**
 * Checks if user has a required Role
 * @param {Array<Int} required_roles Required Job Role IDs
 * @param {Array<Int>} user_roles Users Job Role IDs
 * @param {boolean} all Optional Param - user must have all roles to return true
 * @returns {boolean} Role Status
 */
const hasRole = (required_roles, user_roles, all = false) => {
  const matched_roles = _.intersection(required_roles, user_roles);
  if (all && matched_roles.length === required_roles.length) {
    return true;
  } else if (!all && matched_roles.length) {
    return true;
  }
  return false;
};

const ROLE = {
  Doctor: 'Doctor',
  DSODoctor: 'DSO_Doctor',
  Business: 'Business',
  Admin: 'Admin',
  BusinessManager: 'Business Manager',
  AccountManager: 'Account Manager',
  Administrator: 'Administrator',
};

export { hasRole, ROLE };
