import React from 'react';
import PropTypes from 'prop-types';

/**
 * Displays a basic input text box
 * @component
 */
function TextBox({ className, icon, onIconClick, ...props }) {
  return (
    <div className="input-container">
      <input className={`${className ? className : ''} text-input`} {...props} />
      {icon && <i className={'input-icon ' + icon} aria-hidden onClick={onIconClick} />}
    </div>
  );
}

TextBox.propTypes = {
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
};

export default TextBox;
