import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';

/**
 * Display a single list filter item
 *  - Recursively calls itself if there is submenu
 * @component
 * @category BPP
 */
function CaseListFilterItem(props) {
  const { count, text, active, submenu, onClick, hide, initialCollapse } = props;
  const [isOpen, setIsOpen] = useState(initialCollapse);

  const className = ['case-filter-item'];
  if (active) className.push('active');
  const caret = isOpen ? <i className="fa fa-caret-up icon-size-md" aria-hidden /> : <i className="fa fa-caret-down icon-size-md" aria-hidden />;

  if (hide) return null;
  return (
    <>
      <div className={className.join(' ')} onClick={onClick}>
        <span className="badge-container">
          <span className="badge badge-dark">{count}</span>
        </span>
        {text}
        {submenu && (
          <span className="collapse-caret" onClick={() => setIsOpen(!isOpen)}>
            {caret}
          </span>
        )}
      </div>
      {submenu && (
        <Collapse className="case-filter-submenu" in={isOpen}>
          {submenu.map((item) => (
            <CaseListFilterItem key={item.filter} {...item} />
          ))}
        </Collapse>
      )}
    </>
  );
}

export default CaseListFilterItem;
