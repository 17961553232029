import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { checkJWTToken } from './token';
import AutoRedirectInactivity from '../components/auto_redirect_inactivity/auto_redirect_inactivity';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkJWTToken() === true ? (
        <React.Fragment>
          <AutoRedirectInactivity />
          <Component {...props} />
        </React.Fragment>
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
export default PrivateRoute;
